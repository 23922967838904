import React, { useContext, useEffect, useState } from "react";
import { StatefulPopover } from "baseui/popover";
import { useNavigate, useParams } from "react-router-dom";

import { Typography, Icon, RDText } from "../../components_v2";

import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";
import _ from "lodash";
import NumberFormtRB from "../../containers/RevenueBillingContainer/RevenueBillingTabel/NumberFormtRB";
import { StatefulTooltip } from "baseui/tooltip";
import { EDIT_GROUPING_DRAWER, setDrawerState } from "../../redux/actions";
import { useDispatch } from "react-redux";
import useFormat from "../../hooks/useFormat";

const GroupTile = ({ totalSize = 0, index, ...item }) => {
  return (
    <div className="group-tile">
      <div className="group-tile_timeline">
        {/* <Icon icon="dot" size="6px" /> */}
        <div className="grouping-invoices-circle"></div>
        {totalSize - 1 === index ? (
          <></>
        ) : (
          <div className="group-tile_timeline_line"></div>
        )}
      </div>
      <div className="group-tile_content">
        <div className="group-tile_content_header">
          <div style={{ display: "flex", gap: "2px" }}>
            <Typography type="p">
              <RDText
                rdName="business_unit_list"
                id={_.get(item, "business_unit", "-")}
              />
            </Typography>
            {_.get(item, "ipgm", false) && (
              <Icon
                icon="master_group"
                className="mx-icon--path-fill-primary"
                size={14}
              />
            )}
          </div>

          <Typography type="p" subType="regular">
            <span className="text-secondary">DSC -</span>{" "}
            {_.get(item, "days_since_created", "-")}
          </Typography>
        </div>
        <div className="group-tile_content_info">
          <div>
            <Typography
              type="p"
              subType="small"
              className="text-secondary group-tile_content_info_text"
            >
              {item?.invoice?.invoice_number}
            </Typography>
          </div>
          <Icon icon="dot" size={4} color="#ADADAD" />
          <Typography
            type="p"
            subType="small"
            className="text-secondary"
            style={{ display: "flex", gap: "2px" }}
          >
            <span>
              {_.get(item, "invoice.not_paid_amount.currency", " - ")}
            </span>{" "}
            <NumberFormtRB
              value={`${_.get(item, "invoice.not_paid_amount.value", "-")}`}
            />
          </Typography>
          {item?.invoice?.client_reference ? (
            <>
              <Icon icon="dot" size={4} color="#ADADAD" />
              <Typography
                type="p"
                subType="small"
                className="text-secondary group-tile_content_info_text "
              >
                {_.get(item, "invoice.client_reference", "-")}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

const GroupSelect = ({ getGroupingDataList }) => {
  const { customerId } = useParams();

  const dispatch = useDispatch();
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const [label, setLabel] = useState("");
  const [otherProcessCount, setOtherProcessCount] = useState(null);
  const navigator = useNavigate();
  const [masterChildCount, setMasterChildCount] = useState(null);

  const format = useFormat();
  const grouping = _.get(getGroupingDataList, "data.data.docs", []);
  useEffect(() => {
    const fetchData = async () => {
      if (grouping && Array.isArray(grouping) && grouping.length > 0) {
        const processId = _.get(
          customer,
          "data.doc.pgid",
          _.get(customer, "data.doc._id", "")
        );
        const processGroup = grouping.find((e) => e?._id === processId);

        const processMaster =
          _.get(processGroup, "customers", []) &&
          Array.isArray(_.get(processGroup, "customers", []))
            ? _.get(processGroup, "customers", []).find((e) => e.ipgm)
            : null;
        const MasterChildCount = _.get(processGroup, "customers", []).filter(
          (b) => b.business_unit === processMaster?.business_unit
        );
        const processChildCount = _.get(processGroup, "customers", []).filter(
          (b) => b.business_unit !== _.get(processMaster, "business_unit", "")
        );
        const otherProcessCount = grouping.length - 1;
        setOtherProcessCount(otherProcessCount);
        const masterChildCount =
          MasterChildCount.length > 1 ? `+${MasterChildCount.length - 1}` : "";
        setMasterChildCount(masterChildCount);
        const label = `${
          _.get(processGroup, "customers", []).length
        } inv / ${_.get(
          processMaster,
          "total_outstanding_invoice_amount.currency",
          _.get(
            customer,
            "data.doc.total_outstanding_invoice_amount.currency",
            0
          )
        )} ${format.currency({
          amount: _.get(
            processMaster,
            "total_outstanding_invoice_amount.value",
            _.get(
              customer,
              "data.doc.total_outstanding_invoice_amount.value",
              0
            )
          ),
        })}
/ ${format.rd({
          id: processMaster?.business_unit
            ? processMaster?.business_unit
            : _.get(customer, "data.doc.business_unit", " "),
          name: "business_unit_list",
        })}`;
        setLabel(label);
      }
    };

    fetchData();
  }, [customerId, grouping.length]);

  return (
    <div className="group-selection-list-container">
      <StatefulPopover
        content={() => (
          <div className="group-selection-list-sub-container ">
            <div className="group-list-header-container">
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  // style={{ fontSize: "12px" }}
                  type="p"
                  subType="regular"
                  className="text-secondary"
                >
                  Client hierarchy
                </Typography>
              </div>
              <div
                onClick={() => {
                  setDrawer(EDIT_GROUPING_DRAWER);
                }}
                className="edit-group-icon"
              >
                <div
                // onClick={() => {
                //   setDrawer(EDIT_GROUPING_DRAWER);
                // }}
                >
                  <Icon
                    icon="edit"
                    size="16"
                    color="#516BEB"
                    isPressable
                  ></Icon>
                </div>

                <div>
                  <Typography type="a" subType="medium">
                    Edit
                  </Typography>
                </div>
              </div>
            </div>
            <div
              style={{
                overflowY: "scroll",
                width: "100%",
                height: "100vh",
              }}
            >
              {grouping.map((group, index) => {
                const isMasterGroup =
                  group?._id ===
                  _.get(
                    customer,
                    "data.doc.pgid",
                    customer &&
                      customer?.data &&
                      customer?.data?.doc &&
                      customer?.data?.doc?._id
                  );
                return (
                  <div
                    className={`customer-hierary-popper ${
                      isMasterGroup ? "initial-highlight" : ""
                    }`}
                    onClick={() => {
                      navigator(
                        "/customers/" +
                          (_.get(group, "pgmc", "")
                            ? _.get(group, "pgmc", "")
                            : group?._id)
                      );
                    }}
                  >
                    {group?.customers.map((item, index) => {
                      return (
                        <GroupTile
                          totalSize={group?.customers.length}
                          index={index}
                          {...item}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        returnFocus
        autoFocus
      >
        {grouping && Array.isArray(grouping) && grouping.length > 0 ? (
          <div className="group-selection">
            <Typography type="p" subType="regular" className="truncate-label">
              {label}
            </Typography>
            <Typography type="p" subType="regular">
              {masterChildCount}
            </Typography>
            {otherProcessCount > 0 && (
              <div
                style={{
                  backgroundColor: "#F0F5FF",
                }}
              >
                <Typography type="p" subType="regular" className="mx-chip">
                  {`+${otherProcessCount}`}
                </Typography>
              </div>
            )}
            <div>
              <Icon icon="down_arrow"></Icon>
            </div>
          </div>
        ) : (
          <></>
        )}
      </StatefulPopover>
    </div>
  );
};
export default GroupSelect;
