import React, { forwardRef } from "react";

const Typography = forwardRef(function Typography(props, ref) {
  const {
    type = "p",
    subType = null,
    className = "",
    style = {},
    children,
    ...rest
  } = props;

  const Tag = `${type}`;

  return (
    <Tag
      ref={ref}
      style={{
        ...(style && typeof style === "object" ? style : {}),
      }}
      className={`mx-${type} ${
        subType ? `mx-${type}--${subType}` : ""
      } ${className}`}
      {...rest}
    >
      {children}
    </Tag>
  );
});

export default Typography;
