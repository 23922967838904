import React from "react";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";
import CancelCircle from "../../../assets/img/svg/CancelCircle";
import TickCircle from "../../../assets/img/svg/TickCircle";

function Instruction(props) {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;

  value = _.get(row.original, accessor, "");

  return value && value?.length > 0 ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ minWidth: "auto", maxWidth: "200px" }}>{value}</div>
      }
    >
      <div
        style={{
          textAlign: alignment,
          flex: "1",
        }}
      >
        <TickCircle />
      </div>
    </StatefulTooltip>
  ) : (
    <div
      style={{
        textAlign: alignment,
        flex: "1",
      }}
    >
      <CancelCircle />
    </div>
  );
}
export default Instruction;
