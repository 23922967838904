function LegalLetter() {
  return (
    <>
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.17 0H2C0.9 0 0 1.05 0 2.33333V18.6667C0 19.95 0.9 21 2 21H16C17.1 21 18 19.95 18 18.6667V7.96833C18 7.35 17.79 6.755 17.41 6.32333L12.58 0.688333C12.21 0.245 11.7 0 11.17 0ZM5 14H13C13.55 14 14 14.525 14 15.1667C14 15.8083 13.55 16.3333 13 16.3333H5C4.45 16.3333 4 15.8083 4 15.1667C4 14.525 4.45 14 5 14ZM5 9.33333H13C13.55 9.33333 14 9.85833 14 10.5C14 11.1417 13.55 11.6667 13 11.6667H5C4.45 11.6667 4 11.1417 4 10.5C4 9.85833 4.45 9.33333 5 9.33333ZM5 4.66667H10C10.55 4.66667 11 5.19167 11 5.83333C11 6.475 10.55 7 10 7H5C4.45 7 4 6.475 4 5.83333C4 5.19167 4.45 4.66667 5 4.66667Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default LegalLetter;
