import React, { useRef } from "react";
import { Button, KIND, SIZE } from "baseui/button";

function IconButton({
  children,
  kind = KIND.primary,
  size = SIZE.compact,
  fullWidth = false,
  ...props
}) {
  let ref = useRef();
  return (
    <Button
      ref={ref}
      className={`icon-button ${kind}`}
      $style={(styleProps) => {
        const CompactButtonStyles = {
          paddingTop: "6px",
          paddingRight: "6px",
          paddingBottom: "6px",
          paddingLeft: "6px",
        };
        const SecondaryButtonStyles = {
          paddingTop: "6px",
          paddingRight: "6px",
          paddingBottom: "6px",
          paddingLeft: "6px",
        };

        const { $kind, $theme } = styleProps;

        switch ($kind) {
          case KIND.primary:
            return {
              backgroundColor: $theme.colors.buttonIconPrimaryFill,
              borderRadius: "5px",
              border: "1px solid #CDCED9",
              boxShadow: "0px 4px 4px 0px #eeeeee",
              ...(size == SIZE.compact ? CompactButtonStyles : {}),
              ":hover": {
                backgroundColor: $theme.colors.buttonIconPrimaryHover,
                boxShadow: "inset 0px 4px 4px 0px #00000025",
                color: "#FFFFFF",
              },
              color: "#333860",
            };

          case KIND.secondary:
            return {
              borderRadius: "5px",
              background: "#ffffff",
              color: "#333860",
              border: "1px solid #CDCED9",
              ...(size == SIZE.secondary ? SecondaryButtonStyles : {}),
              ":hover": {
                backgroundColor: "#EEEEEE35",
                color: "#333860",
              },
            };

          case KIND.tertiary:
            return {
              // color: "#323232",
              borderRadius: "5px",
            };

          case KIND.minimal:
            return {
              borderRadius: "5px",
              background: "#EEEEEE35",
              // background: "#000000",

              color: "#EEEEEE35",
              // border: "1px solid #CDCED9",
              ...(size == SIZE.secondary ? SecondaryButtonStyles : {}),
              ":hover": {
                backgroundColor: "#ffffff",
                // color: "#333860",
              },
            };

          case KIND.quaternary:
            return {
              backgroundColor: "#eeeeee",
              borderRadius: "5px",
              border: "1px solid #CDCED9",
              width: fullWidth ? "100%" : "auto",
              boxShadow: "0px 4px 4px 0px #eeeeee",
              ...(size == SIZE.compact ? CompactButtonStyles : {}),
              ":hover": {
                boxShadow: "inset 0px 4px 4px 0px #00000025",
                backgroundColor: "#516BEB",
              },
            };

          case KIND.errors:
            return {
              color: $theme.colors.buttonTertiaryText,
              borderRadius: "5px",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                background: "#FFDFDC",
                // color: $theme.colors.buttonTertiaryTextHover,
              },
            };

          default:
            return {
              backgroundColor: "#eeeeee",
              borderRadius: "5px",
              border: "1px solid #CDCED9",
              boxShadow: "0px 4px 4px 0px #eeeeee",
              ...(size == SIZE.compact ? CompactButtonStyles : {}),
              ":hover": {
                boxShadow: "inset 0px 4px 4px 0px #00000025",
                backgroundColor: "#516BEB",
              },
            };
        }
      }}
      kind={kind}
      size={size}
      {...props}
    >
      {children}
    </Button>
  );
}

export default IconButton;
