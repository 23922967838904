import React from 'react'

const UserOutline = ({size=19,color="#333860"}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.24998 8.76948C8.52812 8.76948 7.91016 8.51245 7.39609 7.9984C6.88203 7.48434 6.625 6.86638 6.625 6.14451C6.625 5.42264 6.88203 4.80468 7.39609 4.29063C7.91016 3.77656 8.52812 3.51953 9.24998 3.51953C9.97184 3.51953 10.5898 3.77656 11.1039 4.29063C11.6179 4.80468 11.875 5.42264 11.875 6.14451C11.875 6.86638 11.6179 7.48434 11.1039 7.9984C10.5898 8.51245 9.97184 8.76948 9.24998 8.76948ZM3.625 14.481V12.8137C3.625 12.4464 3.72476 12.1063 3.92429 11.7933C4.1238 11.4803 4.39038 11.2397 4.72403 11.0714C5.46538 10.708 6.21322 10.4354 6.96754 10.2537C7.72187 10.0719 8.48268 9.98106 9.24998 9.98106C10.0173 9.98106 10.7781 10.0719 11.5324 10.2537C12.2867 10.4354 13.0346 10.708 13.7759 11.0714C14.1096 11.2397 14.3762 11.4803 14.5757 11.7933C14.7752 12.1063 14.875 12.4464 14.875 12.8137V14.481H3.625ZM4.74998 13.356H13.75V12.8137C13.75 12.6618 13.706 12.5212 13.618 12.3918C13.53 12.2625 13.4106 12.157 13.2596 12.0753C12.6134 11.757 11.9546 11.5159 11.283 11.3519C10.6114 11.188 9.93376 11.106 9.24998 11.106C8.56621 11.106 7.88853 11.188 7.21696 11.3519C6.54538 11.5159 5.88652 11.757 5.24037 12.0753C5.08941 12.157 4.96994 12.2625 4.88196 12.3918C4.79397 12.5212 4.74998 12.6618 4.74998 12.8137V13.356ZM9.24998 7.64451C9.66248 7.64451 10.0156 7.49764 10.3094 7.20389C10.6031 6.91014 10.75 6.55701 10.75 6.14451C10.75 5.73201 10.6031 5.37889 10.3094 5.08514C10.0156 4.79139 9.66248 4.64451 9.24998 4.64451C8.83748 4.64451 8.48436 4.79139 8.19061 5.08514C7.89686 5.37889 7.74998 5.73201 7.74998 6.14451C7.74998 6.55701 7.89686 6.91014 8.19061 7.20389C8.48436 7.49764 8.83748 7.64451 9.24998 7.64451Z" fill={color} />
    </svg>



  )
}

export default UserOutline