import React from "react";
import { StyledSvg } from "baseui/icon";

function OnReleasePopOverSvg({ size = "25px" }) {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
        <g clip-path="url(#clip0_1423_8101)">
          <path
            d="M4.59978 9.58595L11.5548 6.80525L6.03226 11.0314L4.59978 9.58595V9.58595ZM15.1133 10.3767L12.3326 17.3317L10.8936 15.8928L15.1133 10.3767V10.3767ZM1.33943 8.91832L5.87021 13.4621L16.8893 5.03572L8.46294 16.0548L13.0067 20.5856L20.7784 1.14663L1.33943 8.91832Z"
            fill="#516BEB"
          />
        </g>
        <defs>
          <clipPath id="clip0_1423_8101">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default OnReleasePopOverSvg;
