import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from "baseui/checkbox";
import React, { useContext } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { refetchUser } from "../../../redux/users/actions";
import { twoFAUsersData } from "../../../services/users";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { ENABLE_DISABLE_USER_2FA } from "../../../providers/RBACProvider/permissionList";

const TwoFASetup = (props) => {
  let { currentOrganization, currencyFormat, userInfo } =
    useContext(MaxyfiContext);
  let dispatch = useDispatch();

  const twoFAUserEnable = useMutation(
    (data) =>
      twoFAUsersData({ organization: currentOrganization, userId: data }),

    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context, error) => {
        dispatch(refetchUser());
      },
    }
  );

  return (
    <div style={{ display: "flex", justifyContent: "right", flex: "1" }}>
      <RBACWrapper role={ENABLE_DISABLE_USER_2FA} type="PERMISSION">
        <Checkbox
          checked={props.row.original.is_2FA_Enable}
          checkmarkType={STYLE_TYPE.toggle_round}
          onChange={(e) => twoFAUserEnable.mutateAsync(props.row.original._id)}
          labelPlacement={LABEL_PLACEMENT.right}
        />
      </RBACWrapper>
    </div>
  );
};

export default TwoFASetup;
