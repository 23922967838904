function Remove({ size = 18, color = "#adadad" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M9.00011 9.79072L5.19529 13.5955C5.09145 13.6994 4.96092 13.7525 4.80371 13.7549C4.64651 13.7573 4.51359 13.7042 4.40494 13.5955C4.29627 13.4869 4.24194 13.3552 4.24194 13.2004C4.24194 13.0456 4.29627 12.9138 4.40494 12.8052L8.20974 9.00036L4.40494 5.19553C4.30109 5.09169 4.24796 4.96117 4.24556 4.80396C4.24315 4.64676 4.29627 4.51383 4.40494 4.40518C4.51359 4.29652 4.64531 4.24219 4.80011 4.24219C4.95491 4.24219 5.08664 4.29652 5.19529 4.40518L9.00011 8.20999L12.8049 4.40518C12.9088 4.30133 13.0393 4.24821 13.1965 4.24581C13.3537 4.24339 13.4866 4.29652 13.5953 4.40518C13.7039 4.51383 13.7583 4.64556 13.7583 4.80036C13.7583 4.95516 13.7039 5.08688 13.5953 5.19553L9.79048 9.00036L13.5953 12.8052C13.6991 12.909 13.7523 13.0395 13.7547 13.1968C13.7571 13.354 13.7039 13.4869 13.5953 13.5955C13.4866 13.7042 13.3549 13.7585 13.2001 13.7585C13.0453 13.7585 12.9136 13.7042 12.8049 13.5955L9.00011 9.79072Z"
        fill={color}
      />
    </svg>
  );
}

export default Remove;
