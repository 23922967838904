import { EMAIL } from "../../../constants";
import {
  LETTER_ATTACHMENT,
  REMOVE_LETTER_ATTACHMENT,
  UPDATE_LETTER_ATTACHMENT_FROM_DESKTOP,
  LETTER_CUSTOMER_DRIVE_OPEN,
  ADD_LETTER_ATTACHMENT_FROM_DESKTOP,
  ONSUCCESS_LETTER_STATE,
  DOCUMENT_LETTER_DRIVE_DATA,
  LETTER_DOCUMENT_DRAWER_STATE,
  LETTER_DOCUMENT_DRAWER_STATE_CLOSE,
  LETTER_UNSELECTED_DOCUMENT_DATA,
} from "./action";

const initialState = {
  letterDrawerStatus: false,
  selectedDocumentLetterStatusData: [],
  letterAuditStatus: false,
  letterAttachment: [],
  drawerState: false,
  drawerType: "",
};

const letterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LETTER_UNSELECTED_DOCUMENT_DATA: {
      let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.letterAttachment.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        letterAttachment: filteredData,
      };
    }

    case LETTER_DOCUMENT_DRAWER_STATE_CLOSE: {
      return {
        ...state,
        letterDrawerStatus: false,
      };
    }
    case LETTER_DOCUMENT_DRAWER_STATE: {
      return {
        ...state,
        letterDrawerStatus: payload,
        drawerType: payload ? EMAIL : "",
      };
    }
    case DOCUMENT_LETTER_DRIVE_DATA:
      let attachData = [...state.letterAttachment];
      attachData.push(...payload);

      return {
        ...state,
        letterAttachment: attachData,
      };

    case ONSUCCESS_LETTER_STATE: {
      return {
        letterAttachment: [],
      };
    }

    case REMOVE_LETTER_ATTACHMENT: {
      let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.letterAttachment.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        letterAttachment: filteredData,
      };
    }

    case ADD_LETTER_ATTACHMENT_FROM_DESKTOP: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.letterAttachment;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;

        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        letterAttachment: [...currentFiles, ...files],
      };
    }

    case UPDATE_LETTER_ATTACHMENT_FROM_DESKTOP: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.letterAttachment.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let letterAttachment = state.letterAttachment;
        let currentFile = letterAttachment[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        letterAttachment[fileIndex] = currentFile;
        return { ...state, letterAttachment: letterAttachment };
      }

      return state;
    }

    case LETTER_CUSTOMER_DRIVE_OPEN:
      return {
        ...state,
        drawerState: payload,
        drawerType: EMAIL,
      };

    default:
      return initialState;
  }
};

export default letterReducer;
