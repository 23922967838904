import React from "react";

const SuccessTick = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4ZM11.104 5.032L6.4 9.736L4.896 8.232C4.584 7.92 4.08 7.92 3.768 8.232C3.456 8.544 3.456 9.048 3.768 9.36L5.84 11.432C6.152 11.744 6.656 11.744 6.968 11.432L12.24 6.16C12.552 5.848 12.552 5.344 12.24 5.032C11.928 4.72 11.416 4.72 11.104 5.032Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default SuccessTick;
