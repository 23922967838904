import React from 'react'

const CloseIcon = ({ widht='12',Height='12',color='#333860'}) => {
    return (
        <svg width={widht} height={Height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.98125 6.96231L1.16875 11.7748C1.03125 11.9123 0.870833 11.9811 0.6875 11.9811C0.504167 11.9811 0.34375 11.9123 0.20625 11.7748C0.06875 11.6373 0 11.4769 0 11.2936C0 11.1102 0.06875 10.9498 0.20625 10.8123L5.01875 5.9998L0.20625 1.1873C0.06875 1.0498 0 0.889388 0 0.706055C0 0.522721 0.06875 0.362305 0.20625 0.224805C0.34375 0.0873047 0.504167 0.0185547 0.6875 0.0185547C0.870833 0.0185547 1.03125 0.0873047 1.16875 0.224805L5.98125 5.0373L10.7937 0.224805C10.9313 0.0873047 11.0917 0.0185547 11.275 0.0185547C11.4583 0.0185547 11.6188 0.0873047 11.7563 0.224805C11.8938 0.362305 11.9625 0.522721 11.9625 0.706055C11.9625 0.889388 11.8938 1.0498 11.7563 1.1873L6.94375 5.9998L11.7563 10.8123C11.8938 10.9498 11.9625 11.1102 11.9625 11.2936C11.9625 11.4769 11.8938 11.6373 11.7563 11.7748C11.6188 11.9123 11.4583 11.9811 11.275 11.9811C11.0917 11.9811 10.9313 11.9123 10.7937 11.7748L5.98125 6.96231Z" fill={color} />
        </svg>

    )
}
export default CloseIcon