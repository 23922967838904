import React from "react";

const DeletedIcon = ({size = 22}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.83553 2.70625L6.93092 3.99531H3.1557C2.8626 3.99531 2.625 4.24925 2.625 4.5625C2.625 4.87575 2.8626 5.12969 3.1557 5.12969H4.2373C4.2391 5.14841 4.24187 5.16726 4.24566 5.18616L6.93363 18.5924C6.9813 18.8302 7.17788 19 7.40538 19H14.3446C14.5721 19 14.7687 18.8302 14.8164 18.5924L17.5043 5.18616C17.5081 5.16726 17.5109 5.14841 17.5127 5.12969H18.5943C18.8874 5.12969 19.125 4.87575 19.125 4.5625C19.125 4.24925 18.8874 3.99531 18.5943 3.99531H14.8191L13.9145 2.70625C13.8234 2.57641 13.6804 2.5 13.5285 2.5H8.22149C8.06964 2.5 7.92664 2.57641 7.83553 2.70625ZM9.47588 8.6875C9.47588 8.37425 9.23827 8.12031 8.94517 8.12031C8.65208 8.12031 8.41447 8.37425 8.41447 8.6875V13.8438C8.41447 14.157 8.65208 14.4109 8.94517 14.4109C9.23827 14.4109 9.47588 14.157 9.47588 13.8438V8.6875ZM13.3355 8.6875C13.3355 8.37425 13.0979 8.12031 12.8048 8.12031C12.5117 8.12031 12.2741 8.37425 12.2741 8.6875V13.8438C12.2741 14.157 12.5117 14.4109 12.8048 14.4109C13.0979 14.4109 13.3355 14.157 13.3355 13.8438V8.6875Z"
        fill="#FD372A"
      />
    </svg>
  );
};

export default DeletedIcon;
