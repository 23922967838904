import { EDIT, NEW, VIEW } from "../../../constants";
import {
  ADD_PAYMENT_MODAL,
  CLOSE_PAYMENT_MODAL,
  EDIT_PAYMENT_MODAL,
  VIEW_PAYMENT_MODAL,
  ALLOCATED_AMOUNT,
  PAYMENT_AMOUNT,
  INITIAL_EDIT_PAYMENT_DATA,
  EMPTY_EDIT_DATA,
  VIEW_DATA,
  PAYMENT_ACTION,
  RESET_PAYMENT_STATE,
  CHANGING_ALLOCATED_AMOUNT,
  GETTING_NEGATIVE_DUE_AMOUNT,
  REFETCH_PAYMENT_ACTIONS,
} from "./action";

const init = {
  date: null,
  amount: {
    value: "",
  },
  method: [],
  refrence: "",
  status: [],
  expected_date: null,
  comment: "",
};

const initialState = {
  type: "",
  isOpen: false,
  defaultPaymentData: { ...init },
  actionType: NEW,
  paymentAmount: 0,
  pendingAllocationAmount: 0,
  editingData: [],
  viewOnly: false,
  allocatedAmount: [],
  dueAmount: 0,
  refetchPaymentActions: 0,
};

const customerPaymentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_PAYMENT_STATE: {
      return initialState;
    }
    case REFETCH_PAYMENT_ACTIONS: {
      const { refetchPaymentActions } = state;
      return {
        ...state,
        refetchPaymentActions: refetchPaymentActions + 1,
      };
    }

    case GETTING_NEGATIVE_DUE_AMOUNT: {
      return {
        ...state,
        dueAmount: payload,
      };
    }

    case CHANGING_ALLOCATED_AMOUNT: {
      let allocatedAmount = [...state.allocatedAmount];
      let changingAllocatedAmount = allocatedAmount.findIndex(
        (i) => i.invoice_id === payload.invoice_id
      );
      if (payload.amount) {
        if (changingAllocatedAmount === -1) {
          allocatedAmount.push(payload);
        } else {
          allocatedAmount[changingAllocatedAmount] = payload;
        }
      } else {
        if (changingAllocatedAmount !== -1) {
          allocatedAmount.splice(changingAllocatedAmount, 1);
        }
      }

      return { ...state, allocatedAmount };
    }
    case PAYMENT_ACTION: {
      return { ...state, actionType: NEW, editingData: [] };
    }

    case INITIAL_EDIT_PAYMENT_DATA: {
      return { ...state, editingData: payload, actionType: EDIT };
    }

    case VIEW_DATA: {
      return { ...state, viewOnly: payload, actionType: VIEW };
    }

    case EMPTY_EDIT_DATA: {
      return {
        editingData: [],
      };
    }

    case ADD_PAYMENT_MODAL: {
      return {
        ...state,
        // isOpen: true,
        // actionType: NEW,
      };
    }

    case PAYMENT_AMOUNT: {
      return {
        ...state,
        paymentAmount: payload,
      };
    }

    case CLOSE_PAYMENT_MODAL: {
      return {
        ...state,
        isOpen: false,
        defaultPaymentData: { ...init },
        actionType: NEW,
      };
    }

    case EDIT_PAYMENT_MODAL: {
      return {
        ...state,
        isOpen: true,
        actionType: EDIT,
        defaultPaymentData: { ...payload },
      };
    }

    case VIEW_PAYMENT_MODAL: {
      return {
        ...state,
        isOpen: true,
        actionType: VIEW,
        defaultPaymentData: { ...payload },
      };
    }

    case ALLOCATED_AMOUNT: {
      let gettingDatas = payload;
      // let changedDatas = [];
      // let changingDatas = Object.keys(gettingDatas).map((e) => {
      //   changedDatas.push({ [e]: gettingDatas[e] });
      // });

      // let totalAmount = state.paymentAmount;

      //

      let totalAllocatedAmount = Object.values(payload).reduce(
        (preVal, curVal) => {
          if (curVal && !isNaN(curVal)) {
            return Number(preVal) + Number(curVal);
          }

          return Number(preVal);
        },
        0
      );

      let updateData = {};

      let currentPaymentAmount =
        state.paymentAmount && !isNaN(state.paymentAmount)
          ? state.paymentAmount
          : 0;
      let pendingAllocationAmount = currentPaymentAmount - totalAllocatedAmount;

      if (pendingAllocationAmount !== state.pendingAllocationAmount) {
        updateData = {
          pendingAllocationAmount,
        };
      }

      return {
        ...state,
        ...updateData,
        // pendingAllocationAmount,
      };
    }

    default:
      return state;
  }
};

export default customerPaymentReducer;
