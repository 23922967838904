import React from "react";

const Switch = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.19237 16.8037C6.94888 20.5602 13.0477 20.5602 16.8042 16.8037C20.5607 13.0472 20.5607 6.94841 16.8042 3.19191C13.0477 -0.564595 6.94887 -0.564595 3.19237 3.19191C-0.564126 6.94841 -0.564125 13.0472 3.19237 16.8037ZM6.84369 7.92328L9.09388 5.67309C9.36201 5.40497 9.36201 4.96909 9.09388 4.70097C8.82576 4.43284 8.38988 4.43284 8.12176 4.70097C8.12176 4.70097 5.92381 6.89891 5.05 7.77272C4.79425 8.02847 4.71795 8.41278 4.85613 8.74691C4.99432 9.08034 5.32019 9.29828 5.68181 9.29828L14.8091 9.29828C15.1886 9.29828 15.4966 8.99028 15.4966 8.61078C15.4966 8.23128 15.1886 7.92328 14.8091 7.92328L6.84369 7.92328ZM13.5104 12.0758L11.5379 14.3631C11.2904 14.6505 11.3228 15.085 11.6101 15.3325C11.8975 15.5807 12.332 15.5483 12.5795 15.261L15.2374 12.1782C15.4656 11.9135 15.5186 11.5395 15.3728 11.2219C15.2271 10.9043 14.9101 10.7008 14.5602 10.7008L5.18406 10.7008C4.80456 10.7008 4.49656 11.0088 4.49656 11.3883C4.49656 11.7678 4.80456 12.0758 5.18406 12.0758L13.5104 12.0758Z"
        fill="#0FB158"
      />
    </svg>
  );
};

export default Switch;
