import { ADDING_SELECT, SELECTED_VALUE } from "./action";

const newDefaultValue = {
  select: {
    filter: "",
    type: "",
  },
};
const initialFormValues = {
  select: [
    {
      filter: "",
      type: "",
    },
  ],

  _id: "",
};

// const

const initialState = {
  defaultData: { ...initialFormValues },
  selectedValue: "",
};

const filterTabsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADDING_SELECT: {
      let newDetails = [
        ...state.defaultData[payload],
        newDefaultValue[payload],
      ];

      return {
        ...state,
        defaultData: {
          ...state.defaultData,
          [payload]: newDetails,
        },
      };
    }

    default:
      return state;
  }
};

export default filterTabsReducer;
