import React, { useContext, useMemo, useState, useEffect } from "react";
import { Icon, RDText, Typography } from "../../../../components_v2";
import defendant from "./../../../../assets/img/defendant.png";
import { KIND, TextButton } from "../../../../components/TextButton";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { useForm, Controller } from "react-hook-form";
import { Input } from "baseui/input";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { customerInvoiceOverview } from "../../../../services";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import {
  getCustomerDetails,
  postDefendantsData,
  getLegalInfo,
} from "../../../../services";
import _ from "lodash";
import DefendantCard from "./DefendantCard";
import queryClient from "../../../../providers/queryClient";
import { LEGAL_ACTION_INFO_, CUSTOMER_OVERVIEW_ } from "../../../../constants";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { ADD_REMOVED_DEFENDANTS_LEGAL } from "../../../../providers/RBACProvider/permissionList";

const AddDefendant = ({
  defendantList = [],
  setDefendantList,
  isEditableDebDpt,
}) => {
  const { customerId } = useParams();
  const { referenceData } = useContext(MaxyfiContext);
  const [searchList, setSearchList] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );

  const contacts = useMemo(() => {
    const search = new RegExp(searchList, "i");
    const defendantIds = [
      ..._.get(legalInfo, "data.doc.def", []),
      ...defendantList,
    ];

    return _.get(customerData, "data.doc.contacts", [])
      .map((contact) => {
        const {
          _id,
          first_name = "",
          last_name = "",
          relation,
          address = [],
        } = contact;

        const validAddress =
          address && Array.isArray(address)
            ? address.filter((e) => e.address_status === "VALID")
            : [];
        const firstAddress =
          validAddress && validAddress[0] ? validAddress[0] : null;

        const country =
          referenceData &&
          referenceData["country"] &&
          firstAddress &&
          firstAddress.country
            ? referenceData["country"].find(
                (e) => e.id === firstAddress.country
              )
            : null;

        const name = `${first_name ? _.capitalize(first_name) : ""}${
          last_name ? ` ${_.capitalize(last_name)}` : ""
        }`;

        return {
          name,
          label: `${name}${relation ? ` / ${_.capitalize(relation)}` : ""}${
            firstAddress && firstAddress.line_1
              ? `, ${firstAddress.line_1}`
              : ""
          }${
            firstAddress && firstAddress.line_2
              ? `, ${firstAddress.line_2}`
              : ""
          }${
            firstAddress && firstAddress.city ? `, ${firstAddress.city}` : ""
          }${
            firstAddress && firstAddress.state ? `, ${firstAddress.state}` : ""
          }${country && country.label ? `, ${country.label}` : ""}`,
          id: _id,
        };
      })
      .sort((a, b) => a?.name.localeCompare(b?.name))
      .filter(
        ({ name, id }) =>
          (searchList && searchList.length > 0 ? search.test(name) : true) &&
          !defendantIds.includes(id)
      );
  }, [customerData?.data, searchList, defendantList]);

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setSelectedContacts([...selectedContacts, item]);
    } else {
      setSelectedContacts(selectedContacts.filter((e) => e !== item));
    }
  };

  return (
    <StatefulPopover
      placement={PLACEMENT.bottom}
      overrides={{
        Body: {
          style: ({ $theme }) => ({
            border: "1px solid #CDCED9",
            borderRadius: "5px",
            backgroundColor: "#FFFFFF",
          }),
        },
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: "#FFFFFF",
            borderRadius: "5px",
          }),
        },
      }}
      onClose={() => {
        setSelectedContacts([]);
      }}
      content={({ close }) => (
        <div className="debt-legal-invoice-container">
          <div>
            <Input
              startEnhancer={
                <Icon icon="search_outline" size={16} color="#ADADAD" />
              }
              onChange={(e) => {
                let values = e.target.value;
                setSearchList(values);
              }}
              overrides={{
                Root: {
                  style: ({ $isFocused }) => ({
                    borderRadius: "5px",
                    borderWidth: "1px",
                    borderColor: $isFocused ? "#516BEB" : "#CDCED9",
                    backgroundColor: "#ffffff",
                    paddingRight: "0px",
                    padding: "0px",
                    paddingLeft: "0px",
                  }),
                },
                InputContainer: {
                  style: ({ $isFocused }) => ({
                    backgroundColor: "#ffffff",
                    paddingLeft: "5px",
                    "::placeholder": {
                      color: $isFocused ? "#cbd5e0" : "transparent",
                      transition: "color 0.4s ease",
                    },
                  }),
                },
                StartEnhancer: {
                  style: ({ $theme }) => ({
                    paddingLeft: "5px",
                    paddingRight: "0px",
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                  }),
                },
              }}
              autoComplete={false}
              autoFill={false}
              size="mini"
              placeholder="Search Contact Name"
            />
          </div>

          <div className="debt-legal-invoice_check">
            {contacts
              .map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={item.id}
                  >
                    <CheckBoxBaseweb
                      checked={selectedContacts.some(
                        (allow) => allow === item.id
                      )}
                      onChange={(e) => {
                        handleCheckboxChange(e, item.id);
                      }}
                    ></CheckBoxBaseweb>
                    <Typography type="p" className="regular">
                      {item.label}
                    </Typography>
                  </div>
                );
              })
              .sort((a, b) => a?.label?.localeCompare(b?.label))}
          </div>
          <div className="debt-legal-invoices_buttons">
            <TextButton
              size="mini"
              kind={KIND.tertiary}
              onClick={() => {
                close();
                setSelectedContacts([]);
              }}
            >
              Cancel
            </TextButton>
            <TextButton
              size="mini"
              kind={KIND.primary}
              onClick={() => {
                setDefendantList(selectedContacts);
                setSelectedContacts([]);
                close();
              }}
            >
              Add
            </TextButton>
          </div>
        </div>
      )}
      returnFocus
      autoFocus
    >
      <div>
        <RBACWrapper role={ADD_REMOVED_DEFENDANTS_LEGAL} type="PERMISSION">
          {isEditableDebDpt &&
          isEditableDebDpt?.idde &&
          _.get(isEditableDebDpt, "Complaint", false) &&
          _.get(isEditableDebDpt, "Supplement", false) ? (
            <></>
          ) : (
            <div className="debt-add-debt_button">
              <Icon icon="plus" color="#516BEB" size={20}></Icon>
              <Typography type="p" style={{ color: "#516BEB" }}>
                Add Defendants
              </Typography>
              <Icon icon="chevron_down" size={14} color="#516BEB" />
            </div>
          )}
        </RBACWrapper>
      </div>
    </StatefulPopover>
  );
};

const DefendantList = ({ isEditableDebDpt }) => {
  const { customerId } = useParams();
  let { currentOrganization } = useContext(MaxyfiContext);

  const [defendantList, setDefendantList] = useState([]);
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );
  const fetchInfo = () => {
    const defendantIds = _.get(legalInfo, "data.doc.def", []);

    setDefendantList(defendantIds);
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleRemoveDefandant = (id) => {
    setDefendantList(defendantList.filter((e) => e != id));
  };

  const postDefendantList = useMutation(
    (id_list) =>
      postDefendantsData({
        organization: currentOrganization,
        customerId: customerId,
        id_list,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          // toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
      },
    }
  );

  return (
    <div className="defendants-initial-container">
      <div className="defendants-header">
        <Typography type="h4">Choose your Defendants</Typography>
        <AddDefendant
          isEditableDebDpt={isEditableDebDpt}
          defendantList={defendantList}
          setDefendantList={(newDefendents = []) =>
            setDefendantList([...defendantList, ...newDefendents])
          }
        />
      </div>
      {defendantList.length > 0 ||
      _.get(legalInfo, "data.doc.def", []).length > 0 ? (
        <>
          {defendantList.map((e) => (
            <DefendantCard
              isEditableDebDpt={isEditableDebDpt}
              id={e}
              handleRemoveDefandant={() => handleRemoveDefandant(e)}
            />
          ))}
          <div
            className="row gap-10 mt-10"
            style={{ justifyContent: "flex-end" }}
          >
            <TextButton
              size="mini"
              kind={KIND.tertiary}
              onClick={() => {
                setDefendantList([]);
              }}
            >
              Cancel
            </TextButton>
            <TextButton
              size="mini"
              kind={KIND.primary}
              onClick={async () => {
                await postDefendantList.mutateAsync(defendantList);
              }}
              isLoading={postDefendantList.isLoading}
              disabled={postDefendantList.isLoading}
            >
              Save
            </TextButton>
          </div>
        </>
      ) : (
        <div className="defendants-not-available">
          <img src={defendant} alt="" width={107} height={85} />
          <Typography type="p" className="text-secondary">
            No Defendants available! Add Defendants Above
          </Typography>
        </div>
      )}
    </div>
  );
};

export default DefendantList;
