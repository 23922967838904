import React from "react";

const StickyNote = ({ size = "22", color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6626 3.375C11.6626 3.99632 11.1589 4.5 10.5376 4.5C10.4746 4.5 10.4128 4.49483 10.3527 4.48488L9.76628 5.46223C9.64907 5.65758 9.39568 5.72093 9.20033 5.60372C9.00498 5.4865 8.94164 5.23312 9.05885 5.03777L9.64532 4.06031C9.49935 3.87056 9.41256 3.63291 9.41256 3.375C9.41256 2.75368 9.91624 2.25 10.5376 2.25C11.1589 2.25 11.6626 2.75368 11.6626 3.375ZM5.25 7.0875C5.02218 7.0875 4.8375 7.27218 4.8375 7.5C4.8375 7.72782 5.02218 7.9125 5.25 7.9125H12C12.2278 7.9125 12.4125 7.72782 12.4125 7.5C12.4125 7.27218 12.2278 7.0875 12 7.0875H5.25ZM5.25 9.3375C5.02218 9.3375 4.8375 9.52218 4.8375 9.75C4.8375 9.97782 5.02218 10.1625 5.25 10.1625H10.5C10.7278 10.1625 10.9125 9.97782 10.9125 9.75C10.9125 9.52218 10.7278 9.3375 10.5 9.3375H5.25ZM13.875 4.575H12.0586C11.9508 4.575 11.8775 4.40598 11.9054 4.30188C11.9244 4.2312 11.9331 4.15513 11.9424 4.07391C11.9512 3.99765 11.9604 3.91685 11.9792 3.83172C11.9899 3.78291 12.0344 3.75 12.0843 3.75H13.875C14.4963 3.75 15 4.25368 15 4.875V12.75L11.25 15.75H4.125C3.50368 15.75 3 15.2463 3 14.625V4.875C3 4.25368 3.50368 3.75 4.125 3.75H8.99106C9.0306 3.75 9.06687 3.771 9.0895 3.80343C9.14256 3.87948 9.21471 3.96133 9.31247 4.0617C9.34884 4.09905 9.35569 4.15633 9.32834 4.2007L9.13058 4.52153C9.1101 4.55476 9.07385 4.575 9.03481 4.575H4.125C3.95931 4.575 3.825 4.70931 3.825 4.875V14.625C3.825 14.7907 3.95931 14.925 4.125 14.925H10.8375V12.825C10.8375 12.5558 11.0558 12.3375 11.325 12.3375H14.175V4.875C14.175 4.70931 14.0407 4.575 13.875 4.575ZM13.1637 13.1625L11.6625 14.3635V13.1625H13.1637Z"
        fill={color}
      />
    </svg>
  );
};

export default StickyNote;
