import {
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import React, { useState, useEffect, useContext, useRef } from "react";
import { IconButton } from "../IconButton";

// Constants
import { EDIT, NEW } from "../../constants";

// Api from Service
// import { postDairyNote } from "../../../services/customerOverview";
import { deleteDairyNote, getDairyNotes } from "../../services";

import { TextButton, KIND, SIZE } from "../TextButton";
// svg
import Warning from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Warning";
import InvoiceUser from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/InvoiceUser";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import Info from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import warningImage from "../../assets/img/warning.png";
// Card Collapse
import CollapseDownArrow from "../../assets/img/svg/CollapseDownArrow";
import CollapseUpArrow from "../../assets/img/svg/CollapseUpArrow";
import { useIntl } from "react-intl";
// React Router
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Formatted Date
import { FormattedDate } from "react-intl";
import { refetchActions } from "../../redux/actions";
import { refetchInvoiceActions } from "../../redux/invoiceOverview/action";
import {
  DELETE_CUSTOMER_NOTES,
  EDIT_CUSTOMER_NOTES,
} from "../../providers/RBACProvider/permissionList";
// Redux

import {
  gettingId,
  editDataForm,
  refetchDiaryNotes,
} from "../../redux/customerDairyNote/action";
import { useDispatch, useSelector } from "react-redux";

// Modal
import { ModalContext } from "../../providers/ModalProvider";

// import EditeSvgN from "../../../assets/img/svg/EditeSvgN";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import CriticalTimeLineSvg from "../../assets/img/svg/CriticalTimlelineSvg";
// import DairyNotes from "./DairyNotes";
import DairyNoteEdit from "../../assets/img/svg/CustomerOverViewIcon/DairyNoteEdit";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import moment from "moment";
import { array } from "yup";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";

const DairyNoteCards = (props) => {
  let { data, findFullWidth, editNotePermission, deleteNotePermission } = props;

  // let data = {
  //   notes:
  //     '<div><span class="quill-bolt" data-title="Praveen " data-user-id="6410010139103fcf5dafab5f">﻿<span contenteditable="false">@Praveen </span>﻿</span> heyu <span class="quill-bolt" data-title="AB AB" data-user-id="6391a8d5333d8ae2acd9008d">﻿<span contenteditable="false">@AB AB</span>﻿</span> </div>',
  //   is_enable_alert_and_follow_up: false,
  //   category: "GEN",
  //   type: "INFORMATIONAL",
  // };

  //

  let contentVal = "";

  if (data.notes.length > 150) {
    contentVal = data.notes?.replace(/(<([^>]+)>)/gi, "").slice(0, 150);
  }

  const { id } = useParams();

  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  let {
    referenceData,
    currentOrganization,
    users,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const categoryFilter = referenceData["notes_category"].find(
    ({ id }) => id === data.category
  );

  //  Card Collapse
  const [expand, setExpand] = React.useState(false);
  const expanToggle = () => {
    setExpand(!expand);
  };

  // Switch Component for Switching the SVG
  const SwitchSvg = ({ type, dateSvg }) => {
    switch (type) {
      case "INFORMATIONAL":
        return <Info />;

      case "CRITICAL":
        return <CriticalTimeLineSvg />;

      case "ALERT":
        return <Warning />;

      default:
        return null;
    }
  };

  // let dateFormate = currentDefaultFormatDetails.date_format;

  //   Switch Component Text
  const SwitchStatus = ({ type, dateData }) => {
    let isDate = dateData ? true : false;
    // const localDate = moment
    //   .utc(dateData)
    //   .format(currentDefaultFormatDetails.date_format);
    let localDate =
      isDate &&
      utcTimstampToLocalDate(dateData, currentDefaultFormatDetails.date_format);

    switch (type) {
      case "INFORMATIONAL":
        return (
          <div className="notes_card_category_contain">
            <div className="notes_category_alert_info">
              <LabelMedium
                $style={{
                  fontWeight: "500",
                  color: "#08083A",
                }}
              >
                {categoryFilter?.label}
              </LabelMedium>
              <HeadingXSmall>Informational</HeadingXSmall>
            </div>
            <HeadingXSmall $style={{ color: "#3787878" }}>
              {isDate ? "on " : ""}
              {isDate ? (
                moment(localDate).format(
                  currentDefaultFormatDetails.date_format
                )
              ) : (
                <></>
              )}
            </HeadingXSmall>
          </div>
        );

      case "ALERT":
        return (
          <div className="notes_card_category_contain">
            <div className="notes_category_alert_alert">
              <LabelMedium
                $style={{
                  fontWeight: "500",
                  color: "#08083A",
                }}
              >
                {categoryFilter?.label}
              </LabelMedium>
              <HeadingXSmall>Alert</HeadingXSmall>
            </div>
            <HeadingXSmall $style={{ color: "#3787878" }}>
              {" "}
              {isDate ? "on " : ""}
              {isDate ? (
                moment(localDate).format(
                  currentDefaultFormatDetails.date_format
                )
              ) : (
                <></>
              )}
            </HeadingXSmall>
          </div>
        );

      case "CRITICAL":
        return (
          <div className="notes_card_category_contain">
            <div className="notes_category_alert_critical">
              <LabelMedium
                $style={{
                  fontWeight: "500",
                  color: "#08083A",
                }}
              >
                {categoryFilter?.label}
              </LabelMedium>
              <HeadingXSmall>Critical</HeadingXSmall>
            </div>
            <HeadingXSmall $style={{ color: "#3787878" }}>
              {isDate ? "on " : ""}
              {isDate ? (
                moment(localDate).format(
                  currentDefaultFormatDetails.date_format
                )
              ) : (
                <></>
              )}
            </HeadingXSmall>
          </div>
        );

      default:
        return null;
    }
  };

  let noteDate = data.modified_at ? data.modified_at : data.created_at;

  // let noteLocalDate = moment
  //   .utc(noteDate)
  //   .format(currentDefaultFormatDetails.date_format);

  let noteLocalDate =
    noteDate &&
    utcTimstampToLocalDate(noteDate, currentDefaultFormatDetails.date_format);

  let noteUser = data.modified_by ? data.modified_by : data.created_by;
  let noteUserRef = users.find((e) => e.id === noteUser);

  return (
    <>
      <div
        className="dairy-ovr"
        style={{
          width: "100%",
          border: "1px solid #CDCED9",
          borderRadius: "5px ",
          padding: "13px 6px 10px 6px",
          marginBottom: "15px",
          boxShadow: "1px 2px 4px #33386025",
          userSelect: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            // alignItems: "center",
          }}
        >
          <>
            {/* Child One */}
            <div>{/* <SwitchSvg type={data.type} /> */}</div>
            {/* Child Two */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "3px",
                width: "100%",
              }}
            >
              {/* Right Side BOX DIV First */}
              <div>
                {expand ? (
                  <>
                    <ParagraphMedium className="on-expand">
                      {/* - {data.notes} */}
                      <SwitchStatus
                        type={data.type}
                        dateData={data.follow_up_date}
                      />

                      {/* {data.notes} */}

                      {data.notes?.replace(/(<([^>]+)>)/gi, "")}

                      <span
                        style={{ color: "#516beb", cursor: "pointer" }}
                        onClick={() => expanToggle()}
                      >
                        {" "}
                        see less
                      </span>
                    </ParagraphMedium>
                  </>
                ) : (
                  <>
                    <SwitchStatus
                      type={data.type}
                      dateData={data.follow_up_date}
                    />
                    <ParagraphMedium
                      // className="word-space"
                      className="word-space"
                      $style={{
                        wordBreak: "break-word",
                        color: "#333860",
                      }}
                    >
                      {/* - {data.notes} */}
                      {data.notes.length > 150
                        ? contentVal
                        : data.notes?.replace(/(<([^>]+)>)/gi, "")}
                      {/* {data.notes.replace(/(<([^>]+)>)/gi, "")} */}

                      <span
                        style={{ color: "#516beb", cursor: "pointer" }}
                        onClick={() => expanToggle()}
                      >
                        {data.notes?.replace(/(<([^>]+)>)/gi, "").length >
                          150 && "see more"}
                      </span>
                    </ParagraphMedium>
                  </>
                )}
              </div>
              {/* Right Side BOX DIV Second */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                  borderTop: "1px solid #c7c7c7",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "8px",
                  }}
                >
                  <div style={{ paddingTop: "2px" }}>
                    <InvoiceUser />
                  </div>
                  <div
                    style={{
                      paddingLeft: "10px",
                    }}
                  >
                    <div>
                      <ParagraphMedium
                        $style={{
                          color: "#333860",
                          paddingRight: "5px",
                          lineHeight: "16px",
                        }}
                      >
                        {noteUserRef && noteUserRef.displayName
                          ? noteUserRef.displayName
                          : data?.created_by_name}
                      </ParagraphMedium>
                      <HeadingXSmall
                        $style={{
                          fontSize: "10px",
                          color: "#ADADAD",
                          lineHeight: "16px",
                        }}
                      >
                        {moment(noteLocalDate).format(
                          currentDefaultFormatDetails.date_format
                        )}
                      </HeadingXSmall>
                    </div>
                  </div>
                </div>
                <div
                  className="cp-card-hiper-link"
                  style={{
                    marginTop: "0px",
                  }}
                >
                  {/* <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div> */}
                  <RBACWrapper
                    role={editNotePermission}
                    // role={props}
                    type={"PERMISSION"}
                  >
                    <IconButton
                      style={{ width: "40px", height: "40px" }}
                      kind={KIND.tertiary}
                      onClick={() => {
                        if (findFullWidth && findFullWidth.current) {
                          findFullWidth.current.scrollTop = 50;
                        }
                        dispatch(editDataForm(data));
                        dispatch(gettingId(data._id));
                      }}
                    >
                      <DairyNoteEdit />
                    </IconButton>
                  </RBACWrapper>

                  <RBACWrapper role={deleteNotePermission} type={"PERMISSION"}>
                    <IconButton
                      style={{ width: "40px", height: "40px" }}
                      kind={KIND.tertiary}
                      onClick={() => {
                        dispatch(gettingId(data._id));
                        handleModal({
                          title: "You are about to delete a Dairy Note",
                          content:
                            "This will delete your Diary Note. Are you sure?",
                          successCallback: () => {
                            props.deleteDairyData.mutate({
                              customerId: props.getClientId,
                              _id: data._id,
                            });
                          },
                          formContent: ({ handleModal }) => {
                            return <></>;
                          },
                          cancelCallback: () => {},
                          isChildren: false,
                          image: warningImage,
                          buttonText: "Delete",
                          closeButtonText: "Cancel",
                          isCloseAble: true,
                        });
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </RBACWrapper>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default DairyNoteCards;
