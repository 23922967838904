// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const APP_CODE = process.env.REACT_APP_APP_CODE;
const HOST = process.env.REACT_APP_HOST;

export const getInvoiceAction = async ({ invoiceId, organization = null }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoices/${invoiceId}/actions`
  );
};

export const contactUserSelection = async ({
  organization = null,
  data,
  customerId,
  contactId,
  userData,
  // action,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/contact/${contactId}/invoice-contact`,
    userData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const postInvoiceAction = async ({
  clientCode,
  invoiceId,
  organization = null,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/invoices/${invoiceId}/actions`,
    { ...rest }
  );
};

export const postNewInvoice = async ({ organization = null, ...rest }) => {
  return await axiosWithAuth.post(`${HOST}${organization}/invoices/add`, {
    ...rest,
  });
};
export const putInvoice = async ({
  organization = null,
  invoiceId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/invoices/${invoiceId}`,
    {
      ...rest,
    }
  );
};
export const postNewRecurringInvoice = async ({
  organization = null,
  customerId,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/recurring-invoice`,
    {
      ...rest,
    }
  );
};
// post /api/63e1f8f83ed6743e298b9dcf/customer/63e1fd51a054b19989810229/recurring-invoice'

export const postPaymentDetailsInvoiceOverview = async ({
  clientCode,
  invoiceId,
  organization = null,
  data,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/invoices/${invoiceId}/payments`,
    { ...data }
  );
};

export const getInvoicePayment = async ({
  invoicePaymentId,
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoices/${invoicePaymentId}/payments`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getInvoiceData = async ({ invoiceId, organization = null }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoices/${invoiceId}`
  );
};

export const editInvoiceDairyNote = async ({
  id,
  noteId,
  organization = null,
  entity,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/${entity}/${id}/notes/${noteId}`,
    data
  );
};

export const deleteInvoiceDairyNote = async ({
  invoiceId,
  _id,
  organization = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/invoices/${invoiceId}/notes/${_id}`
  );
};

export const addInvoiceDiaryNotesData = async ({
  invoiceId,
  organization = null,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/invoices/${invoiceId}/notes`,
    data
  );
};

export const getInvoiceDiaryNotesData = async ({
  invoiceId,
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoices/${invoiceId}/notes`
  );
};
