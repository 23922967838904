function LeftSmallArrow() {
  return (
    <>
      <svg
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L4.29289 9.70711ZM1 5L0.292893 4.29289C-0.097631 4.68342 -0.097631 5.31658 0.292893 5.70711L1 5ZM5.70711 1.70711C6.09763 1.31658 6.09763 0.683417 5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L5.70711 1.70711ZM5.70711 8.29289L1.70711 4.29289L0.292893 5.70711L4.29289 9.70711L5.70711 8.29289ZM1.70711 5.70711L5.70711 1.70711L4.29289 0.292893L0.292893 4.29289L1.70711 5.70711Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default LeftSmallArrow;
