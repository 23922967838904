import React from 'react'

const Forward = () => {
  return (
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4702 7.93748L11.442 4.90934C11.3319 4.79925 11.2769 4.67414 11.2769 4.534C11.2769 4.39385 11.3288 4.26873 11.4327 4.15865C11.5428 4.0524 11.6691 4.00047 11.8116 4.00287C11.9542 4.00528 12.0762 4.05721 12.1776 4.15865L15.5028 7.48388C15.5668 7.55023 15.6147 7.62079 15.6467 7.69554C15.6787 7.7703 15.6947 7.85095 15.6947 7.93748C15.6947 8.02402 15.6787 8.10467 15.6467 8.17943C15.6147 8.25418 15.5668 8.32353 15.5028 8.38748L12.1776 11.7127C12.0675 11.8228 11.9434 11.8775 11.8051 11.8768C11.6669 11.8761 11.5428 11.8214 11.4327 11.7127C11.3288 11.605 11.2756 11.4818 11.2729 11.3431C11.2703 11.2044 11.3228 11.0824 11.4305 10.9772L14.4702 7.93748ZM10.7469 8.46031H5.48677C4.81369 8.46031 4.23917 8.69829 3.76321 9.17425C3.28724 9.65022 3.04925 10.2247 3.04925 10.8978V12.8846C3.04925 13.0303 2.99793 13.1544 2.89529 13.257C2.79266 13.3597 2.6697 13.411 2.52643 13.411C2.38076 13.411 2.25661 13.3597 2.15397 13.257C2.05132 13.1544 2 13.0303 2 12.8846V10.8978C2 9.93725 2.33942 9.11598 3.01827 8.434C3.69711 7.75204 4.51994 7.41106 5.48677 7.41106H10.7469L8.23582 4.89997C8.12573 4.79228 8.07069 4.66837 8.07069 4.52823C8.07069 4.38809 8.12573 4.26297 8.23582 4.15287C8.34351 4.05047 8.46766 3.99952 8.60829 4C8.74891 4.00048 8.86994 4.05144 8.97138 4.15287L12.306 7.48388C12.3699 7.55023 12.4179 7.62079 12.4499 7.69554C12.4818 7.7703 12.4978 7.85095 12.4978 7.93748C12.4978 8.02402 12.4818 8.10467 12.4499 8.17943C12.4179 8.25418 12.3699 8.32353 12.306 8.38748L8.98076 11.7127C8.87066 11.8228 8.7459 11.8775 8.60649 11.8768C8.46706 11.8761 8.34351 11.8245 8.23582 11.7221C8.12573 11.612 8.07092 11.4866 8.0714 11.346C8.07187 11.2054 8.12476 11.0824 8.23004 10.9772L10.7469 8.46031Z" fill="#787878"/>
</svg>

  )
}

export default Forward