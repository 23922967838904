import { StatefulTooltip } from "baseui/tooltip";
import React, { useContext } from "react";
import { Icon, Typography } from "../../components_v2";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";
import { useParams } from "react-router-dom";
import { REVIEW_GROUPING_DRAWER, setDrawerState } from "../../redux/actions";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { useQuery } from "react-query";
import { getGroupingList } from "../../services/grouping";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { MERGE_CONSUMER_RECORDS } from "../../providers/RBACProvider/permissionList";
const ReviewButton = () => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { currentOrganization } = useContext(MaxyfiContext);
  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  return (
    <div>
      <StatefulTooltip
        showArrow
        overrides={{
          Arrow: {
            style: ({ $theme }) => ({
              outline: `#16164B solid`,
              backgroundColor: "#16164B",
            }),
          },
          Inner: {
            style: ({ $theme }) => ({
              outline: `#16164B solid`,
              backgroundColor: "#16164B",
            }),
          },
        }}
        content={() => (
          <div style={{ backgroundColor: "#16164B" }}>
            <Typography type="p" subType="regular" style={{ color: "white" }}>
              Review and Confirm grouping({customer?.data?.doc?.grc})
            </Typography>
          </div>
        )}
        returnFocus
        autoFocus
      >
        <RBACWrapper role={MERGE_CONSUMER_RECORDS} type="PERMISSION">
          <div
            className="review-header-count"
            onClick={() => {
              setDrawer(REVIEW_GROUPING_DRAWER);
            }}
          >
            <Icon size="17" icon="question_circle" color="#FD372A" />
            <Typography type="p" subType="regular" style={{ color: "#FD372A" }}>
              {_.get(customer, "data.doc.grc", 0)}
            </Typography>
          </div>
        </RBACWrapper>
      </StatefulTooltip>
    </div>
  );
};

export default ReviewButton;
