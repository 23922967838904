import * as React from "react";
import { Card, StyledBody, StyledAction } from "baseui/card";
import "../card.css";
import CardLayout from "../Card";
const CardWidgetOne = () => {
  return (
    <CardLayout>
      <StyledBody style={{ lineHeight: "10px", marginBottom: "5px" }}>
        <div
          style={{
            color: "#666666",
            textAlign: "center",
            fontWeight: "700",
            fontSize: "13px",
          }}
        >
          Delinquency
        </div>
        <div className="wrapper">
          <div className="main">
            <div className="cir">
              {/* <div class="numone">10</div> */}
              <div className="cirnum-one" style={{ color: "#666666" }}>
                0
              </div>
            </div>
            <div className="cir">
              <div className="cirnum-two" style={{ color: "#666666" }}>
                30
              </div>

              {/* <div class="numtwo">30</div> */}
            </div>
          </div>
          <div className="line"></div>
        </div>
      </StyledBody>
      <StyledAction style={{ margin: "0px" }}>
        <div>
          <div
            style={{
              fontWeight: "900",
              fontSize: "25px",
              color: "#666666",
              textAlign: "center",
            }}
          >
            15
            {/* <sub
              style={{ fontSize: "6px", fontWeight: "bold", color: "#516BEB" }}
            >
              inv
            </sub> */}
          </div>
          <div
            style={{
              fontSize: "10px",
              textAlign: "center",
              fontWeight: "900",
              color: "#516BEB",
            }}
          >
            Invoices
          </div>
        </div>
        <div
          style={{
            fontSize: "10px",
            fontWeight: "bold",
            color: "#666666",
            textAlign: "center",
            // lineHeight: "15px",
            paddingTop: "1px",
          }}
        >
          $10,000
        </div>
      </StyledAction>
    </CardLayout>
  );
};
export default CardWidgetOne;
