export default {
  role_maintenance_form: "إضافة صيانة دور جديد",
  edit_role_maintenance_form: "تحرير صيانة الدور",
  view_role_maintenance_form: "مشاهدة ملف صيانة الدور",
  added_permissions: "الإذن المضاف",
  removed_permissions: "الإذن الذي تمت إزالته",
  menu_access: "الوصول إلى القائمة",
  linked_user_count: "عدد المستخدمين المرتبطين",
  role_description: "وصف الدور",
  role_name: "اسم الدور",
  add_role_maintenance: "إضافة دور صيانة",
  edit_role_maintenance: "تحرير صيانة الدور",
  role_maintenance: "صيانة الدور",
  slab_name: "اسم بلاطة",
  slab_based_on: "معلمة النطاق",
  contact_type: "نوع الاتصال",
  payment_allocation_type: "نوع تخصيص الدفع",
  debtor_payment_instruction_2: "تعليمات سداد المدين 2",
  portal_payment_type: "نوع دفع البوابة",
  debtor_payment_instruction_1: "تعليمات سداد المدين 1",
  relation_manager: "مدير العلاقات",
  rel_manager_address: "عنوان مدير العلاقات",
  rel_manager_phone: "هاتف مدير العلاقات",
  business_unit_name: "وحدة عمل",
  business_unit_code: "رمز وحدة الأعمال",
  business_registration_number: "رقم التسجيل التجاري",
  commission_type: "نوع العمولة",
  flat_fee_percentage: "نسبة الرسوم الثابتة",
  commission_slab: "لوح اللجنة",
  invoice_type: "نوع الفاتورة",
  invoice_start_date: "تاريخ بدء الفاتورة",
  next_billing_date: "موعد المحاسبة التالي",
  invoice_frequency: "تردد الفاتورة",
  invoice_payment_instruction_1: "تعليمات دفع الفاتورة 1",
  invoice_payment_instruction_2: "تعليمات دفع الفاتورة 2",
  invoice_terms_and_condition: "شروط وأحكام الفاتورة",
  branding: "العلامة التجارية",
  invoice_taxes: "ضرائب الفاتورة",
  add_business_unit: "إضافة وحدة الأعمال",
  business_unit_maintenance: "صيانة وحدة الأعمال",
  delivered_list: "قائمة تسليمها",
  bounced_list: "قائمة مرتدة",
  last_read_at: "آخر قراءة في",
  customer_categorization: "تصنيف العملاء",
  paid_to: "المدفوعة لل",
  paid_amount: "المبلغ المدفوع",
  choose_template: "اختيار القالب",
  type_review: "يكتب",
  release_promise_date: "تحرير الوعد تعليق",
  business_unit: "وحدة عمل",
  business_location: "موقع العمل",
  business: "وحدة الأعمال والموقع",
  customer_status: "حالة العميل",
  customer_comment: "تعليق الزبون",
  api_token: "رمز Api",
  sub_domain: "المجال الفرعي",
  checkin_report: "تقرير تسجيل الوصول",
  recurring_report: "تقرير متكرر",
  edit_invoice: "تحرير الفاتورة",
  edit_recurring: "تحرير الفاتورة المتكررة",
  add_recurring_invoice: "أضف الفاتورة المتكررة",
  add_invoice: "أضف الفاتورة",
  invoice_notes: "ملاحظات الفاتورة",
  invoice_terms: "شروط الفاتورة",
  invoice_footer: "تذييل الفاتورة",
  frequency: "تكرار",
  select_tax: "حدد الضرائب",
  select_product: "حدد المنتج",
  next_execution_date: "تاريخ التنفيذ القادم",
  recover_from_customer: "التعافي من",
  billing_address: "عنوان وصول الفواتير",
  shipping_address: "عنوان الشحن",
  payment_term: "شروط الدفع",
  advance_amount: "المبلغ المقدم",
  po_number: "PO عدد",
  discount: "تخفيض",
  other_information: "معلومات أخرى",
  subtotal: "المجموع الفرعي",
  paid: "مدفوع",
  balance_due: "الرصيد المستحق",
  internal_notes: "ملاحظات",
  two_tax_rate: "اثنان معدل الضريبة",
  one_tax_rate: "معدل ضريبة واحد",
  three_tax_rate: "ثلاثة معدل الضريبة",
  invoicing: "الفواتير",
  measure: "يقيس",
  invoice_item_maintenance: "صيانة بند الفاتورة",
  invoice_level_tax_rate: "معدلات ضريبة مستوى الفاتورة",
  invoice_item_level_tax_rate: "معدلات الضريبة على مستوى عنصر الفاتورة",
  rate: "معدل",
  nature_of_tax: "طبيعة الضريبة",
  subscription_id: "معرف الإشتراك",
  customer_notes: "ملاحظات الزبون",
  item_amount: "كمية المادة",
  end_date: "تاريخ الانتهاء",
  fixed_amount: "مبلغ ثابت",
  usage_amount: "الكمية المستخدمة",
  adhoc_amount: "المبلغ المخصص",
  api_key: "مفتاح API",
  api_base_url: "عنوان url الأساسي لواجهة برمجة التطبيقات",
  allocation_invoice: "فاتورة التخصيص",
  sender_id: "هوية المرسل",
  number: "رقم",
  min_call: "الحد الأدنى من المكالمات",
  max_call: "دعوة Maxmium",
  allocated_amount_pending_allocation: "التخصيص / التخصيص المعلق",
  credit_limit_breach_perc: "نسبة حد الائتمان",
  credit_limit_status: "حالة حد الائتمان",
  search: "يبحث",
  source: "مصدر",
  key_id: "معرف المفتاح",
  key_secret: "السر الرئيسي",
  last_action: "أخر فعل",
  next_action: "الحدث التالي",
  due: "حق",
  overdue: "متأخر",
  total: "المجموع",
  sync_status: "حالة المزامنة",
  credit_limit: "الحد الائتماني",
  call_unsuc_is_send_confirmation_message: "إرسال رسالة تأكيد",
  call_un_is_hold_workflow: "وقفة سير العمل حتى موعد المتابعة",
  call_unsuccessfull_call: "إيقاف سير العمل مؤقتًا حتى تاريخ معاودة الاتصال",
  is_send_confirmation_message_ptp: "إرسال رسالة تأكيد",
  is_hold_workflow_ptp: "إيقاف سير العمل مؤقتًا حتى تاريخ الوعد",
  is_send_confirmation_message_cbl: "إرسال رسالة تأكيد",
  is_hold_workflow_cbl: "إيقاف سير العمل مؤقتًا حتى تاريخ معاودة الاتصال",
  send_promise_follow_up_message_cbl: "إرسال رسالة متابعة معاودة الاتصال",
  type_limit: "اكتب الحد",
  call_unsuccessful_till: "استدعاء غير ناجح حتى",
  call_unsuccessful_till_date: "إرسال رسالة تأكيد",
  pause_workflow_till_call_back_date:
    "إيقاف سير العمل مؤقتًا حتى تاريخ معاودة الاتصال",
  pause_workflow_till_promise_date: "إيقاف سير العمل مؤقتًا حتى تاريخ الوعد",
  promise_to_pay_confirmation: "وعد بدفع التأكيد",
  pause_workflow_till_call_back_confirmation_message: "إرسال رسالة تأكيد",
  total_inv_count_: "إجمالي الفاتورة #",
  rel_manager_email: "مدير العلاقات (عنوان البريد الإلكتروني)",
  customer_id: "هوية الزبون",
  workflow_start_date: "تاريخ بدء سير العمل",
  total_invoice_amount: "المبلغ الإجمالي للفاتورة",
  enter_your_timezone: "أدخل منطقتك الزمنية",
  pause_workflow_till_follow_up_message: "إرسال رسالة متابعة الوعد",
  timezone: "المنطقة الزمنية الافتراضية",
  currency_cur: "العملة الافتراضية",
  enter_your_currency: "أدخل عملتك",
  enter_your_company_name: "أدخل اسم شركتك",
  enter_your_country: "أدخل بلدك",
  confirm_password: "تأكيد كلمة المرور",
  enter_password: "أدخل كلمة المرور",
  enter_confirm_password: "أدخل كلمة المرور المؤكدة",
  business_email_account: "حساب البريد الإلكتروني للأعمال",
  enter_your_first_name: "أدخل اسمك الأول",
  UNREAD_EMAILS: "رسائل البريد الإلكتروني غير المقروءة",
  select_filter: "حدد تصفية",
  search_by_cus_name: "البحث عن طريق اسم العميل",
  ein_gi: "رقم التسجيل التجاري",
  email_id: "عنوان البريد الإلكتروني",
  address_line_1_floor_number_door_street_details: "العنوان سطر 1",
  action_on_hold: "العمل معلق",
  cus_max_delay_days: "ماكس أيام التأخير",
  message_medium: "وسيط الرسالة",
  hold_workflow_till_dispute_date: "إيقاف سير العمل مؤقتًا حتى تاريخ النزاع",
  sb: "موضوع",
  bcc_e: "نسخة مخفية الوجهة",
  cc_e: "نسخة",
  content_body: "نص المحتوى",
  date_and_time: "التاريخ و الوقت",
  save_mapping_for_future_reference: "حفظ الخرائط للرجوع إليها في المستقبل:",
  attachments: "المرفقات",
  partial_amount: "المبلغ الجزئي",
  full_amount: "المبلغ الكامل",
  PARTIAL: "جزئي",
  summary_view_of_invoice_download: "أرقام الفواتير المرتبطة",
  hold_workflow_till_review_date: "وقفة سير العمل حتى تاريخ المراجعة",
  BROKEN: "مكسور",
  KEPT: "أبقى",
  IN_PROGRESS: "في تَقَدم",
  mandatory_system_fields: "حقول النظام الإلزامية",
  select_template: "تطبيق التعيين",
  invoice_amount: "قيمة الفاتورة",
  ccy: "CCY",
  issued_date: "تاريخ الإصدار",
  allow_invoice_download: "السماح بتنزيل الفاتورة",
  display_paid_invoices: "عرض الفواتير المدفوعة",
  adjusted_credit_note: "عرض إشعارات الائتمان المعدلة بالكامل",
  historical_chat: "الدردشة التاريخية",
  files_transfered: "نقل الملفات",
  amnt_restriction: "تقييد مبلغ الدفع",
  restrict_date: "تمديد الحد الأقصى للدفع",
  percentage: "نسبة السداد الدنيا",
  duration: "مدة معاودة الاتصال",
  start: "وقت البدء",
  end: "وقت النهاية",
  send_promise_follow_up_message: "إرسال رسالة متابعة الوعد",
  CUSTOMER: "مستوى العميل",
  INVOICE: "مستوى الفاتورة",
  ORGANIZATION: "مستوى المنظمة",
  BUSINESS_UNIT: "مستوى وحدة الأعمال",
  reason_to_skip: "سبب التخطي",
  audit: "مراجعة",
  issued_amount: "المبلغ الصادر",
  linked_invoices: "الفواتير المرتبطة",
  linked_amount: "المبلغ المرتبط",
  available_amount: "المبلغ المتاح",
  audit_user: "مستخدم التدقيق",
  dispute_detailed_text: "نزاع نص مفصل",
  dispute_category: "فئة النزاع",
  hold_till_date: "وقفة حتى التاريخ",
  notes_category: "فئة الملاحظات",
  date: "تاريخ",
  method: "طريقة",
  expected_date: "التاريخ المتوقع",
  created_by: "انشأ من قبل",
  comment: "تعليق",
  expected_payment_date: "تاريخ الدفع المتوقع",
  invoice_number: "رقم الفاتورة",
  invoice_date: "تاريخ الفاتورة",
  invoice_due_date: "تاريخ استحقاق الفاتورة",
  currency_code: "رمز العملة",
  received_amount: "المبلغ الذي تسلمه",
  capture_by: "التقاط بواسطة",
  capture_details: "تفاصيل الالتقاط",
  details: "تفاصيل",
  disputes: "النزاعات",
  review_date: "تاريخ المراجعة",
  dispute_status: "مراجعة الحالة",
  captured_by: "التقطت بواسطه",
  date_time: "التاريخ والوقت",
  activity: "نشاط",
  skip_workflow_action_till_next_action_date:
    "تخطي إجراء سير العمل حتى تاريخ الإجراء التالي؟",
  reciever: "المتلقي",
  next_action_type: "نوع الإجراء التالي",
  hold_workflow_till_promise_date: "إيقاف سير العمل مؤقتًا حتى تاريخ الوعد",
  user_comments: "تعليقات المستخدم",
  invoice_total_amount: "كمية",
  select_orientation: "حدد الاتجاه",
  choose_header: "اختر العنوان",
  choose_header_premium: "اختر العنوان (ميزة مميزة)",
  choose_footer_premium: "اختر التذييل (ميزة مميزة)",
  is_skip_workflow_action: "هو تخطي إجراء سير العمل",
  choose_footer: "اختر التذييل",
  top_margin: "الهامش العلوي",
  bottom_margin: "الهامش السفلي",
  right_margin: "الهامش الأيمن",
  left_margin: "الهامش الأيسر",
  choose_action: "اختر الإجراء",
  action_date: "تاريخ العمل",
  override_workflow: "تجاوز سير العمل",
  account_name: "إسم الحساب",
  routing_number: "رقم التوصيل",
  action_type: "يكتب",
  account_number: "رقم حساب",
  code: "شفرة",
  phone_number: "رقم التليفون",
  choose_relation_manager: "اختر مدير العلاقات",
  choose_workflow: "اختر سير العمل",
  org_name: "اسم المنظمة",
  ein: "عين",
  ein_placeholder: "XX-XXXXXXX",
  email_value: "قيمة البريد الإلكتروني",
  address_line_1: "العنوان سطر 1",
  address_line_2: "سطر العنوان 2",
  city: "مدينة",
  state: "ولاية",
  country: "دولة",
  zip_code: "الرمز البريدي",
  start_time: "وقت البدء",
  end_time: "وقت النهاية",
  time_zone: "وحدة زمنية",
  industry_sector: "قطاع الصناعة",
  org_url: "عنوان URL للمؤسسة",
  dashboard: "لوحة القيادة",
  default_currency: "العملة الافتراضية",
  organization_settings: "إعدادات المنظمة",
  organizations: "المنظمات",
  department: "قسم",
  authentication_type: "نوع المصادقة",
  created_on: "تم إنشاؤها على",
  template_id: "اختيار القالب",
  is_credit_note: "اشعار دائن",
  is_authentication: "المصادقة",
  is_invoice: "فاتورة",
  is_payment: "قسط",
  is_message: "رسالة",
  is_callback: "أيام معاودة الاتصال",
  is_request_back: "طلب وقت الدفع",
  is_allow_invoice_download: "تنزيل الفاتورة",
  cus: "ارتباط بوابة العميل",
  display_invoice: "عرض الفاتورة",
  display_fully_adjusted_credit_notes: "عرض إشعارات الائتمان المعدلة بالكامل",
  pay_all_dues: "دفع جميع المستحقات",
  search_invoice: "البحث في الفاتورة",
  total_amount: "المبلغ الإجمالي",
  invoice_enable: "تمكين الفاتورة",
  authentication: "المصادقة",
  allocated_amount: "المبلغ المخصص",
  add_credit_notes: "إضافة ملاحظات الائتمان",
  edit_credit_notes: "تحرير اشعارات الائتمان",
  pay_overdues: "دفع المتأخر",
  adhoc_payment: "الدفع المخصص",
  display_historical_chat: "عرض الدردشة التاريخية",
  allow_transfered: "السماح بنقل الملفات",
  payment: "قسط",
  message: "رسالة",
  call_back: "أتصل مرة أخرى",
  request_back: "طلب العودة",
  remarks: "ملاحظات",
  amount: "كمية",
  issue_date: "تاريخ الإصدار",
  customer: "عميل",
  credit_note_id: "معرف مذكرة الائتمان",
  credit_note: "اشعار دائن",
  currency: "عملة",
  type: "أنواع الملاحظات",
  is_enable_alert_and_follow_up: "",
  domain_name: "اسم النطاق",
  user_name: "اسم المستخدم",
  host_name: "اسم المضيف",
  port: "ميناء",
  password: "كلمة المرور",
  organisation_name: "اسم المنظمة",
  follow_up_date: "متابعة التاريخ",
  notes: "ملاحظات المستخدم",
  customer_setting: "إعداد العميل",
  customer_name: "اسم الزبون",
  first_name: "الاسم الأول",
  second_name: "الاسم الثاني",
  last_name: "اسم العائلة",
  role: "دور",
  phone: "هاتف",
  add_contact_details: "أضف تفاصيل الاتصال",
  select: "الرجاء التحديد",
  email: "بريد إلكتروني",
  enter_number: "أدخل رقمك",
  enter_email: "أدخل بريدك الإلكتروني",
  most_used_suggestion: "الأكثر استخداما والاقتراح",
  address: "عنوان",
  zipCode: "الرمز البريدي",
  inv_number: "رقم الفاتورة",
  inv_date: "تاريخ الفاتورة",
  due_date: "تاريخ الاستحقاق",
  due_days: "أيام الاستحقاق",
  all_actions: "كل الإجراءات",
  co_inv_status: "حالة",
  co_inv_amount: "كمية",
  co_inv_ccy: "CCY",
  customer_summary: "ملخص العميل",
  customer_overview: "نظرة عامة على العميل",
  adhoc_upload: "Adhoc Data Upload. تحميل البيانات المخصص",
  communication_template: "نموذج الاتصال",
  workflow_maintenance: "صيانة سير العمل",
  cus_name: "اسم",
  display_name: "اسم",
  due_inv_count: "#حق",
  due_inv_amount: "المبلغ المستحق",
  overdue_inv_count: "# متأخر",
  overdue_inv_amount: "المبلغ المتأخر",
  total_inv_count: "#المجموع",
  total_inv_amount: "المبلغ الإجمالي",
  last_action_date: "تاريخ آخر إجراء",
  last_action_name: "اسم الإجراء الأخير",
  last_action_comment: "تعليق الإجراء الأخير",
  next_action_date: "تاريخ الإجراء التالي",
  sugg_next_action: "الإجراء التالي المقترح",
  workflow: "سير العمل",
  rel_manager: "مدير العلاقات",
  max_payment_delay: "الحد الأقصى لتأخير السداد",
  avg_payment_delay: "متوسط تأخير الدفع",
  last_response_date: "تاريخ آخر رد",
  days_after_last_response: "أيام بعد آخر رد",
  save_as_new_view: "حفظ كعرض جديد",
  new_view: "عرض جديد",
  create: "يخلق",
  default_view: "طريقة العرض الافتراضية",
  enable_for_organization_users: "تمكين لمستخدمي المؤسسة",
  description: "وصف",
  name: "اسم",
  required: "مطلوب",
  view_name: "عرض الاسم",
  template_type: "نوع النموذج",
  template_sub_type: "النوع الفرعي للقالب",
  view_description: "عرض الوصف",
  template_name: "اسم القالب",
  template_description: "وصف النموذج",
  template_organization: "تنظيم القالب",
  template_languages: "لغات النموذج",
  workflow_name: "اسم سير العمل",
  workflow_description: "وصف سير العمل",
  max_contact_in_month: "ماكس الاتصال في الشهر",
  workflow_average_delay: "متوسط التأخير",
  workflow_enter_a_tag: "اضف اشارة",
  add_multiple_tags_by_pressing_enter: "اضغط على Enter بعد كل # علامات",
  action_name: "اسم العمل",
  wf_max_contact_in_month: "اتصل في الشهر",
  action_description: "وصف الإجراء",
  recipient_type: "اختر نوع المستلم",
  choose_sms_template: "اختر قالب الرسائل القصيرة",
  choose_letter_template: "اختر قالب الرسالة",
  choose_email_template: "اختر قالب البريد الإلكتروني",
  sms_content_preview: "معاينة محتوى الرسائل القصيرة",
  subject_preview: "معاينة الموضوع",
  email_content_preview: "معاينة محتوى البريد الإلكتروني",
  instruction_to_user: "تعليمات للمستخدم",
  postal_service: "خدمه بريديه",
  postal_type: "النوع البريدي",
  letter_preview: "معاينة محتوى الرسالة",
  last_modified: "آخر تعديل",
  most_used: "الأكثر استخداما",
  all: "الجميع",
  sms: "رسالة قصيرة",
  letter: "خطاب",
  search_templates: "البحث بالاسم أو الوصف",
  add_template: "أضف قالبًا",
  rows_per_page: "عدد الصفوف في الصفحة",
  prev: "السابق",
  next: "التالي",
  min: "دقيقة",
  max: "الأعلى",
  search_here: "ابحث هنا",
  date_month_year: "MM / DD / YYYY - MM / DD / YYYY",
  select_view: "حدد عرض",
  capture_call_details: "التقاط تفاصيل المكالمة",
  reciever_place: "المتلقي",
  call_outcome: "نتيجة المكالمة",
  promise_to_pay: "وعد بالدفع",
  PROMISE_TO_PAY: "وعد بالدفع",
  CALL_BACK_LATER: "اتصل لاحقًا",
  MEETING_REQUEST: "طلب اجتماع",
  RECORD_DISPUTE: "سجل الخلاف",
  UNSUCCESSFUL: "غير ناجح",
  call_back_later: "عاود الاتصال لاحقًا",
  meeting_request: "طلب اجتماع",
  promise: "يعد",
  callback: "أيام معاودة الاتصال",
  dispute: "ينازع",
  unsuccessful: "غير ناجح",
  promise_amount: "وعد المبلغ",
  hold_workflow_review_promise_date: "إيقاف مؤقت لمراجعة سير العمل تاريخ الوعد",
  send_confirmation_message: "إرسال رسالة تأكيد",
  callback_confirmation: "تأكيد معاودة الاتصال",
  meeting_confirmation: "تأكيد الاجتماع",
  add_notes_for_internal_reference_here:
    "أضف ملاحظات كمرجع داخلي هنا (اختياري) ...",
  comments: "التعليقات أو الملاحظات",
  comm_type: "يكتب",
  call_back_time: "وقت معاودة الاتصال",
  meeting_time: "وقت الاجتماع",
  send_callback_follow_up_message: "إرسال رسالة متابعة معاودة الاتصال",
  send_meeting_follow_up_message: "إرسال رسالة متابعة الاجتماع",
  recipients: "المستلمون",
  sms_content: "محتوى الرسائل القصيرة",
  review_and_send_sms: "مراجعة وإرسال الرسائل القصيرة",
  review_and_send_letter: "مراجعة وإرسال الرسالة",
  letter_remainder: "بقايا الرسالة",
  review_and_send_email: "مراجعة وإرسال البريد الإلكتروني",
  cc1: "نسخة",
  bcc1: "نسخة مخفية الوجهة",
  cc: "اضغط على Enter بعد كل بريد إلكتروني",
  bcc: "اضغط على Enter بعد كل بريد إلكتروني",
  subject: "موضوع",
  select_date: "حدد تاريخ",
  promise_type: "نوع الوعد",
  promise_date: "تاريخ الوعد",
  planned_payment_amount: "مبلغ الدفع المخطط",
  planned_installation_amount: "مبلغ التثبيت المخطط",
  planned_start_date: "تاريخ البدء المخطط",
  planned_frequency: "التردد المخطط",
  promise_to_follow_up_message: "وعد بمتابعة الرسالة",
  callback_follow_up_message: "رسالة متابعة معاودة الاتصال",
  meeting_follow_up_message: "رسالة متابعة الاجتماع",
  call_back_date: "تاريخ معاودة الاتصال",
  meeting_date: "تاريخ الاجتماع",
  hold_workflow_till_call_back_date:
    "إيقاف سير العمل مؤقتًا حتى تاريخ معاودة الاتصال",
  hold_workflow_till_meeting_date: "إيقاف سير العمل مؤقتًا حتى تاريخ الاجتماع",
  call_back_confirmation: "تأكيد معاودة الاتصال",
  call_back_reminder_message: "رسالة تذكير بمعاودة الاتصال",
  user_comments_here: "تعليقات المستخدم هنا",
  meeting_venue: "مكان إجتماع",
  meeting_value: "قيمة الاجتماع",
  service_incomplete: "الخدمة غير مكتملة",
  open: "يفتح",
  date_picker: "منتقي التاريخ",
  follow_up_call: "متابعة الدعوة",
  unsuccessful_reason: "سبب غير ناجح",
  contact_unsuccessful: "الاتصال غير ناجح",
  invoice_summary: "ملخص الفاتورة",
  invoice_overview: "نظرة عامة على الفاتورة",
  user: "تحميل المستخدم",
  add_user: "إضافة مستخدم",
  payment_date: "تاريخ الدفع",
  payment_amount: "مبلغ الدفع",
  payment_method: "طريقة الدفع او السداد",
  payment_reference: "إشارة دفع",
  payment_status: "حالة السداد",
  expect_payment_status_date: "توقع تاريخ حالة الدفع",
  user_notes: "ملاحظات المستخدم",
  add_payments: "أضف المدفوعات",
  receiver: "المتلقي",
  phone_type: "نوع الهاتف",
  country_code: "الرقم الدولي",
  email_type: "نوع البريد الإلكتروني",
  record_dispute: "نزاع السجل",
  start_workflow_from: "بدء سير العمل من",
  note: "ملحوظة",
  take_a_note: "سجل ملاحظة",
  customer_workflow: "سير عمل العميل",
  all_customers: "كل العملاء",
  customers: "عملاء",
  invoices: "القيمة الافتراضية",
  amount_to_Pay: "المبلغ للدفع",
  day_1: "اليوم 1",
  day_5: "يوم 5",
  day_14: "اليوم الرابع عشر",
  day_19: "اليوم التاسع عشر",
  day_25: "اليوم 25",
  upload_file_columns: "تحميل أعمدة الملف",
  first_row_data: "بيانات الصف الأول",
  data_format: "تنسيق البيانات",
  mapping_with_system_fields: "رسم الخرائط باستخدام حقول النظام",
  to: "ل",
  hold_reason: "وقفة السبب",
  hold_action_till: "وقفة العمل حتى",
  followup_date: "متابعة التاريخ",
  capture_dispute_details: "القبض على تفاصيل النزاع",
  dispute_type: "نوع النزاع",
  dispute_review_date: "تاريخ مراجعة النزاع",
  upload_field: "تحميل أعمدة الملف",
  format: "تنسيق البيانات",
  system_field: "رسم الخرائط باستخدام حقول النظام",
  default_column: "العمود الافتراضي",
  purpose: "غاية",
  default: "تقصير",
  organization_name: "اسم المنظمة",
  file_name: "اسم الملف",
  uploaded_by: "تم الرفع بواسطة",
  uploaded_at: "تم الرفع في",
  stats: "حالة التحميل",
  status: "حالة",
  user_profile: "ملف تعريفي للمستخدم",
  upload: "معرف التحميل",
  organization: "اسم المنظمة",
  z_name: "اسم الملف",
  upload_from_system: "تحميل من النظام",
  upload_attachments: "تحميل المرفقات",
  upload_drop_zone_caption: "انقر هنا للتحميل أو السحب والإفلات",
  copy_template: "نسخ القالب",
  edit_template: "تحرير القالب",
  view_template: "عرض النموذج",
  delete_template: "حذف القالب",
  template_disabled: "القالب معطل",
  template_enabled: "تم تمكين النموذج",
  workflow_disabled: "سير العمل معطل",
  workflow_enabled: "تم تمكين سير العمل",
  phone_code: "كود الهاتف",
  start_date: "تاريخ البدء",
  log_in_time: "تسجيل الدخول في الوقت المناسب",
  log_out_time: "وقت تسجيل الخروج",
  ip_address: "عنوان IP",
  eg_password: "على سبيل المثال: كلمة المرور @ 123",
  account_currency: "عملة الحساب",
  user_maintenance: "صيانة المستخدم",
  account_type: "نوع الحساب",
  account_routing_no: "توجيه الحساب لا",
  account_no: "رقم الحساب",
  detail: "التفاصيل",
  contact_person_name: "اسم شخص الاتصال",
  business_email: "بريد العمل",
  next_action_name: "اسم الإجراء التالي",
  unsucessful_reason: "سبب غير ناجح",
  reference: "مرجع",
  due_amount: "مبلغ مستحق",
  company_name: "اسم الشركة",
  cus_currency: "عملة العميل",
  promise_comment: "وعد التعليق",
  address_line_2_area_and_locality_details: "سطر العنوان 2",
  hold_workflow_till_follow_up_date: "وقفة سير العمل حتى موعد المتابعة",
  view_contact_details: "عرض تفاصيل الاتصال",
  edit_contact_details: "تحرير تفاصيل الاتصال",
  allocated_invoice_numbers: "أرقام الفواتير المخصصة",
  pending_allocation: "التخصيص المعلق",
  edit_user: "تحرير العضو",
  CUSTOMER_WITH_NO_CONTACTS: "عميل ليس لديه جهات اتصال",
  RELATION_MANAGER_NOT_ASSIGNED: "مدير العلاقات غير معين",
  WORKFLOW_NOT_ASSIGNED: "لم يتم تعيين سير العمل",
  ON_HOLD: "مراجعة على وقفة",
  UNASSIGNED_CREDIT_NOTE: "مذكرة ائتمان غير مخصصة",
  EXCESS_PAYMENTS: "المدفوعات الزائدة",
  CALL_FOLLOWUP: "متابعة المكالمات",
  EMAIL_FOLLOWUP: "متابعة البريد الإلكتروني",
  SMS_FOLLOWUP: "متابعة الرسائل القصيرة",
  PENDING_PROMISE_REVIEWS: "وعد بدفع المراجعة",
  PENDING_DISPUTE_REVIEWS: "مراجعة النزاع",
  CREDIT_LIMIT_BREACHED: "تم خرق حد الائتمان",
  CREDIT_LIMIT_WARNING: "تحذير حد الائتمان",
  is_active: "نشيط",
  is_2fa_setup: "إعداد 2FA",
  settled: "تسوية",
  promise_in_place: "الوعد في المكان",
  active_disputes: "المنازعات النشطة",
  followup_in_place: "المتابعة في المكان",
  followup_delayed: "المتابعة متأخرة",
  workflow_closed: "سير العمل مغلق",
  adhoc_follow_up: "المتابعة المخصصة",
  skip_trace: "تخطي التتبع",
  yet_to_initiate: "لم تبدأ بعد",
  account_sid: "SID الحساب",
  auth_token: "رمز المصادقة",
  provider: "مزود",
  from: "من",
  date_format: "صيغة التاريخ",
  amount_format: "تنسيق المبلغ",
  currency_format: "تنسيق العملة",
  amount__format: "حدد تنسيق المبلغ الخاص بك",
  date__format: "حدد تنسيق التاريخ الخاص بك",
  stop_customer: "أوقف عمليات متابعة العملاء في حالة عدم وجود فاتورة متأخرة",
  total_outstanding:
    "ابدأ متابعة العملاء عندما يتجاوز حد الائتمان إجمالي المبلغ المعلق",
  choose_a_plan_tailored_to_your_needs: "اختر خطة مصممة خصيصا لاحتياجاتك",
  ready_to_get_started: "على استعداد للبدء؟",
  back_to_ricing: "العودة إلى التسعير",
  annual_plan: "الخطة السنوية",
  monthly_plan: "خطة شهرية",
  skip_for_free_trail: "تخطي للدرب المجاني",
  upgrade_to_continue: "قم بالترقية للمتابعة",
  free_trial_expired: "انتهت الفترة التجريبية المجانية",
  search_by_inv_number: "البحث عن طريق رقم الفاتورة",
  select_the_method: "حدد الطريقة",
  secret_key: "المفتاح السري",
  online: "متصل",
  offline: "غير متصل على الانترنت",
  audit_by: "تدقيق بقلم",
  ref_name: "إسم الحساب",
  help_text: "نص المساعدة",
  payment_mode: "طريقة الدفع",
  payment_gateway: "بوابة الدفع",
  audit_on: "التدقيق قيد التشغيل",
  logo: "شعار",
  instruction: "تعليمات",
  report_analytics: "تقرير تحليلات",
  customer_currency: "عملة العميل",
  max_delay: "ماكس تأخير",
  overdue_amount: "المبلغ المتأخر",
  captured_on: "تم القبض عليه",
  action_comment: "تعليق العمل",
  template: "نموذج",
  action_outcome: "نتيجة العمل",
  promise_currency: "الوعد العملة",
  promise_status: "الوعد الوضع",
  disute_review_date: "تاريخ مراجعة النزاع",
  dispute_details: "تفاصيل النزاع",
  callback_date: "تاريخ رد الاتصال",
  dispute_invoices: "الاعتراض على الفواتير",
  dispute_comment: "تعليق النزاع",
  payment_currency: "عملة الدفع",
  note_category: "فئة الملاحظة",
  note_type: "نوع الملاحظة",
  select_user: "اختر المستخدم",
  select_report_by: "تقرير تصفية حسب",
  allocated_to: "المخصصة ل",
  credit_limit_utilization: "استخدام حد الائتمان",
  currentDue: "موعد الاستحقاق الحالي",
  b1: "1-30 يوم",
  b2: "31-60 يومًا",
  b3: "61-90 يوم",
  b4: "91 يومًا وأكثر",
  action_due_date: "تاريخ استحقاق الإجراء",
  ageing_summary_report: "تقرير ملخص الشيخوخة",
  aging_summary_report: "تقرير ملخص الشيخوخة",
  activity_report: "تقرير النشاط",
  promise_to_pay_report: "وعد بالدفع تقرير",
  dispute_report: "تقرير النزاع",
  payment_report: "تقرير الدفع",
  note_report: "تقرير الملاحظات",
  credit_limit_report: "تقرير حد الائتمان",
  needs_action_report: "تقرير يحتاج إلى إجراء",
  category: "فئة",
  invoice: "فاتورة",
  sub_category: "تصنيف فرعي",
  primary_contact: "اتصال رئيسي",
  call_to_action: "دعوة للعمل",
  footer: "تذييل",
  phone_number_id: "معرف رقم الهاتف",
  whatsapp_buisness_account_id: "معرف حساب الأعمال في Whatsapp",
  user_access_token: "رمز وصول المستخدم",
  review_and_send_whatsapp: "مراجعة وإرسال Whatsapp",
  header_height: "ارتفاع الرأس",
  header_zoom: "تكبير الرأس",
  footer_height: "ارتفاع التذييل",
  footer_zoom: "تكبير التذييل",
  date_of_communication: "تاريخ الاتصال",
  delivery_method: "طريقة التوصيل",
  tracking_url: "تتبع URL",
  delivery_status: "حالة التسليم",
  audit_at: "التدقيق في",
  is_disabled: "حالة",
  refetch_template: "حالة الجلب",
  pricing_type: "نوع التسعير",
  price_currency: "سعر العملة",
  unit: "وحدة",
  price: "سعر",
  product_type: "يكتب",
  audit_date: "تاريخ مراجعة",
  tax: "ضريبة",
  checkin_date: "تاريخ الوصول",
  item: "غرض",
  checkin_time: "وقت تسجيل الوصول",
  location: "موقع",
  role_department: "الدور والقسم",
  auth_type: "نوع التفويض",
  checkin_line_item: "بند تسجيل الوصول",
  checkin_amount: "مبلغ تسجيل الوصول",
  checkin_currency: "تسجيل الوصول CCY",
  checkin_location: "تسجيل الوصول الموقع",
  checkin_comment: "تعليقات تسجيل الوصول",
  invoice_status: "حالة الفاتورة",
  manual_status_user: "مستخدم الحالة اليدوية",
  recurring_invoice_currency: "عملة الفاتورة المتكررة",
  invoice_name: "اسم الفاتورة المتكررة",
  skip_duration: "مدة التخطي",
  total_line_item: "إجمالي البند",
  sub_total: "المجموع الفرعي",
  discount_amount: "تخفيض",
  tax_amount: "ضريبة",
  paused: "متوقف مؤقتًا",
  paused_on: "تم الإيقاف مؤقتًا",
  paused_by: "أوقفه المستخدم مؤقتًا",
  select_workflow: "حدد سير العمل",
  select_language: "اختار اللغة",
  letter_name: "اسم الرسالة",
  mapping_name: "اسم التعيين",
  is_enabled: "تم التمكين",
  is_deleted: "يتم حذف",
  flat_fee_amount: "مبلغ الرسوم الثابتة",
  admin_name: "اسم المسؤول",
  auto_generate_invoice: "إنشاء الفاتورة تلقائيًا",
  last_billed_date: "تاريخ آخر فاتورة",
  last_billed_value: "آخر قيمة في الفاتورة",
  add_revenue_and_billing: "إضافة الإيرادات والفواتير",
  revenue_and_billing: "الإيرادات والفواتير",
  pause_workflow_for_manual_recon: "وقفة سير العمل للاسترداد اليدوي",
  user_location: "موقع المستخدم",
  manager: "مدير",
  business_unit_location: "موقع العمل",
  days_since_created:"أيام منذ الإنشاء",
  is_quick_action: "متوقف مؤقتًا",
  hold_quick_action_reason: "وقفة السبب",
  hold_quick_action_till: "وقفة حتى التاريخ",
  filters: "المرشحات",
  pause_reason: "وقفة السبب",
  pause_action_on_this_customer_till: "وقفة الإجراء على هذا العميل حتى",
  flat_fee: "رسم موحد",
  last_billed_start_date: "تاريخ بدء آخر فاتورة",
  last_billed_end_date: "تاريخ انتهاء آخر فاتورة",
  last_billed_due_amount: "آخر مبلغ مستحق الدفع",
  last_billed_refund_amount: "آخر مبلغ تم استرداد فاتورة به",
  last_billed_client_payment: "آخر مدفوعات العميل المفوترة",
  last_billed_trust_payment: "آخر دفع أمانة مفوتر",
  last_billed_by: "آخر فاتورة من",
  commission_percentage: "نسبة العمولة",
  created_file_id: "تم إنشاء معرف الملف",
  updated_file_id: "معرف الملف المحدث",
  customer_dob: "العميل DOB",
  insurance: "تأمين",
  employer: "صاحب العمل",
  client_reference: "مرجع العميل",
  invoice_description: "وصف الفاتورة",
  capture_notes_at: "التقاط الملاحظات في",
  map_based_on: "الخريطة على أساس",
  landline: "هاتف ارضي",
  landline_type: "نوع الخط الأرضي",
  landline_code: "رمز الخط الأرضي",
  type_contact: "يكتب",
  mobile: "متحرك",
  search_anything: "ابحث عن أي شيء",
  search_by_customer_name: "البحث عن طريق اسم Cus",
  pause_workflow_till_follow_up_date: "وقفة سير العمل حتى موعد المتابعة",
  formula: "معادلة",
  enter_formula: "أدخل الصيغة",
  revenue_dashboard: "لوحة تحكم الإيرادات",
  customer_stage: "مرحلة العميل",
  allow_payment_receipt_download: "السماح بتنزيل إيصال الدفع",
  portal_visit: "زيارة البوابة",
  portal_payment: "دفع البوابة",
  portal_promise: "وعد البوابة",
  portal_callback: "عودة الاتصال بالبوابة",
  sms_reply: "الرد عبر الرسائل القصيرة للعملاء",
  email_reply: "رد البريد الإلكتروني للعميل",
  whatsapp_reply: "استجابة WhatsApp",
  app_payment: "دفع التطبيق",
  customer_action_analysis: "تحليل إجراءات العملاء",
  is_display_invoice_description: "عرض وصف الفاتورة",
  days_since_created: "الأيام منذ الإنشاء",
  sms_status: "حالة الرسائل القصيرة",
  last_action_date_dynamic: "تاريخ آخر إجراء ديناميكي",
  next_action_date_dynamic: "تاريخ الإجراء التالي ديناميكي",
  last_customer_action: "آخر إجراء من جانب العميل",
  last_customer_action_date: "تاريخ آخر إجراء من قبل العميل",
  last_customer_action_date_dynamic: "تاريخ آخر إجراء للعميل ديناميكي",
  invoice_date_dynamic: "تاريخ الفاتورة ديناميكي",
  due_date_dynamic: "تاريخ استحقاق الفاتورة ديناميكي",
  last_customer_action_name: "اسم إجراء العميل الأخير",
  created_on_dynamic: "تم إنشاؤه في ديناميكي",
  my_team: "فريقي",
  self: "الذات",
  add_customer: "أضف الزبون",
  download_customer_data: "تنزيل بيانات العميل",
  download_workflow_list_data: "تنزيل بيانات قائمة سير العمل",
  download_relation_manager_list: "تنزيل قائمة مدير العلاقات",
  upload_data: "تحميل البيانات",
  list_data: "قائمة البيانات",
  dialer_data: "بيانات المسجل",
  needs_action: "يحتاج إلى عمل",
  clear_filters: "مسح عوامل التصفية",
  apply: "يتقدم",
  plus_add_filter: "+ إضافة عامل تصفية",
  quick_actions: "إجراءات سريعة",
  hold_action_applied_till: "استمر تطبيق الإجراء حتى",
  due_to: "بسبب",
  manual_call: "مكالمة يدوية",
  Call_Follow_up_capture_discussion_outcome:
    "متابعة المكالمات والتقاط نتائج المناقشة",
  SMS_Reminders_with_without_an_actionable_link:
    "تذكير الرسائل القصيرة مع / بدون ارتباط قابل للتنفيذ",
  Email_follow_up_with_Customer: "المتابعة بالبريد الإلكتروني مع العميل",
  Capture_your_planned_Next_action: "التقط الإجراء التالي المخطط له",
  Communicate_and_connect_via_WhatsApp: "تواصل وتواصل عبر WhatsApp",
  Review_and_Update_Dispute_Status: "مراجعة حالة النزاع وتحديثها.",
  pause_action: "وقفة العمل",
  Pause_Actions_and_Customer: "وقفة الإجراءات والعملاء",
  Capture_Payments_made_by_the_Customer:
    "القبض على المدفوعات التي قام بها العميل",
  Review_the_promise_provided_by_the_Customer: "راجع الوعد المقدم من العميل",
  Proceed_your_letter_Action_on_your_Own: "المضي قدما في خطاب العمل الخاص بك",
  Change_your_status_of_updated_Letter: "تغيير حالة الخطاب المحدث الخاص بك",
  inv: "الفاتورة",
  unapplied_credit: "رصيد غير مطبق",
  in: "في",
  promise_reviews: "وعد المراجعات",
  dispute_review: "مراجعة النزاع",
  workFlow: "سير العمل",
  assign_workFlow: "تعيين WorkFlow",
  workFlow_on_hold: "WorkFlow قيد الانتظار",
  Business_unit_location: "وحدة العمل والموقع",
  Tags: "العلامات",
  Customer_Status: "حالة_العميل",
  OverDue: "متأخر",
  timeline: "الجدول الزمني",
  workflow_action: "إجراء سير العمل",
  More_Info: "مزيد من المعلومات",
  Search_Invoices: "البحث في الفواتير",
  Invoice: "فاتورة",
  Recurring: "يتكرر",
  NEXT_ACTION: "الحدث التالي",
  hold_action: "عقد العمل",
  action_skipped: "تخطي الإجراء",
  whatsapp: "ال WhatsApp",
  INVOICE_UPDATE: "تحديث الفاتورة",
  Partially_Delivered_and_Read: "سلمت جزئيا وقراءتها",
  Partially_Delivered: "سلمت جزئيا",
  Bounced: "ارتد",
  Delivered: "تم التوصيل",
  Read: "يقرأ",
  User_Comments: "تعليقات المستخدم",
  Promise_Amount: "وعد المبلغ",
  Call_Unsuccessful: "اتصال: غير ناجح",
  portal: "منفذ",
  Call_Back_Later: "معاودة الاتصال لاحقًا",
  logout: "تسجيل خروج",
  user_information: "معلومات المستخدم",
  password_setting: "ضبط كلمة المرور",
  contact_phone: "هاتف الاتصال",
  save_changes: "حفظ التغييرات",
  change_password_info:
    "يساعد تغيير كلمة المرور الخاصة بك كل 90 يومًا على أن تكون آمنة",
  reset_2fa_info: "أعد تعيين 2FA عندما تكون في حاجة",
  invalid_report_type: "نوع تقرير غير صالح",
  today: "اليوم",
  week_to_date: "من الأسبوع حتى تاريخه",
  month_to_date: "بقى شهر حتى المواعدة",
  quarterly_to_date: "ربع سنوي حتى تاريخه",
  half_yearly_to_date: "نصف سنوي حتى تاريخه",
  custom_date: "تاريخ مخصص",
  general_and_application_preference: "عام وتفضيل التطبيق",
  general_and_application_preference_description:
    "معلومات عامة عن المنظمة والتفضيل الجانبي للتطبيق",
  contact_details: "بيانات المتصل",
  contact_details_description: "تفاصيل الاتصال بالمنظمة",
  payment_details: "بيانات الدفع",
  payment_details_description: "إعداد معلومات الدفع للمؤسسة",
  accounting_books: "كتب المحاسبة",
  accounting_books_description:
    "Premium - احصل على رقم تسليم SMS شخصي وخيار قراءة استجابة SMS",
  sms_gateway: "بوابة الرسائل القصيرة",
  sms_gateway_description:
    "تكوين مفاتيح بوابة الرسائل القصيرة لإجراء عمليات المتابعة القائمة على الرسائل القصيرة",
  outbound_email: "البريد الإلكتروني الصادر",
  outbound_email_description: "",
  cloud_telephony: "المهاتفة السحابية",
  cloud_telephony_description: "",
  customer_portal: "بوابة العملاء",
  customer_portal_description:
    "قم بتخصيص المعلومات التي يمكن لعميلك عرضها في البوابة والرد",
  automation_settings: "إعدادات الأتمتة",
  default_configuration: "التكوين الافتراضي",
  tax_rate_maintenance: "الحفاظ على معدل الضريبة",
  organization_details: "تفاصيل المنظمة",
  edit: "يحرر",
  delete: "يمسح",
  application_preference: "تفضيل تطبيق",
  add_payment_method: "إضافة طريقة دفع",
  payment_setting: "إعداد الدفع",
  payment_setting_add_payment: "إضافة الدفع",
  payment_setting_add_payment_description:
    "سيقوم التطبيق بإجراء مزامنة تلقائية للمدفوعات المضافة من البوابة أو الوكيل مع كتب المحاسبة عند التمكين",
  payment_setting_auto_sync_payments: "مدفوعات المزامنة التلقائية",
  payment_setting_auto_sync_payments_description: "عند التمكين",
  payment_setting_update_due_amount: "تحديث المبلغ المستحق",
  payment_setting_update_due_amount_description:
    "ستنعكس المدفوعات التي يتم تسجيلها على المبلغ المستحق تلقائيًا عند التمكين",
  payment_setting_auto_compute_promise_status: "حساب الوعد التلقائي",
  payment_setting_auto_compute_promise_status_description:
    "سيتم احتساب حالة الوعد تلقائيًا بناءً على الحالة أدناه عند التمكين",
  assign_data: "تعيين البيانات",
  payments: "المدفوعات",
  custom: "مخصص",
  review_promise: "وعد المراجعة",
  letter_status: "حالة الرسالة",
};
