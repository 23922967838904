import React, { useState, useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { SIZE, SelectBox } from "../../../../components/SelectBox";
import { KIND, TextButton } from "../../../../components/TextButton";
import { Icon, Typography } from "../../../../components_v2";
import UploadedCard from "./UploadedCard";
import DocumentUploadPage from "./DocumentUploadPage";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import DocumentCard from "../../../../components/DocumentCard/legalDoumentCard";
import {
  deleteDocumentCard,
  getDocumentData,
} from "../../../../services/documentSystem";

const DocumentListPage = ({ result, isSectionActive, state }) => {
  const { customerId } = useParams();
  let intl = useIntl();
  const [openDocs, setOpenDoc] = useState(false);
  const [documentSelectedValues, setDocumentSelectedValues] = useState([]);
  const [sortName, setSort] = useState("");

  const { referenceData, currentOrganization, users, userInfo, space } =
    useContext(MaxyfiContext);

  let { refetchDocument } = useSelector((doc) => doc.customerDocumentReducer);
  let { refetchInvoiceDocumentData } = useSelector(
    (s) => s.invoiceDocumentReducer
  );

  let entity = "customer";

  let refetchingData =
    entity === "invoices" ? refetchInvoiceDocumentData : refetchDocument;

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `document-management-system-${customerId}-${refetchingData}`,
      {
        filter: {
          lty:
            documentSelectedValues &&
            documentSelectedValues[0] &&
            documentSelectedValues[0].id,
        },
      },
    ],
    async ({ queryKey }) => {
      let { sort, filter } = queryKey[1];
      if (customerId) {
        return await getDocumentData({
          organization: currentOrganization,
          customerId: customerId,
          filter,
        });
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data && data.data && data.data.docs && data.data.docs.length === 0) {
      setOpenDoc(true);
    }
  }, []);

  useEffect(() => {
    if (result && result[isSectionActive] && result[isSectionActive]?.pat) {
      setDocumentSelectedValues([{ id: result[isSectionActive]?.pat }]);
    }
  }, [result, isSectionActive]);
  const deleteDocumentData = useMutation(
    (id) => deleteDocumentCard({ ...id, organization: currentOrganization }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          // toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
      },
    }
  );

  const legalInfoNotes = useMemo(() => {
    referenceData &&
      referenceData["legal_action_fields"] &&
      referenceData["legal_action_fields"]?.map((item) => {
        if (item?.label === state.filter) {
          setDocumentSelectedValues([item]);
        }
      });
  }, [state]);

  return (
    <>
      {openDocs ? (
        <DocumentUploadPage
          setOpenDoc={setOpenDoc}
          entity={"customer"}
          refetch={refetch}
          result={result}
          isSectionActive={isSectionActive}
          setDocumentSelectedValues={setDocumentSelectedValues}
        />
      ) : (
        <div className="docs-list-container">
          <div className="docs-list-header">
            <div style={{ width: "129px" }}>
              <SelectBox
                placeholder="Select"
                value={documentSelectedValues}
                onChange={(e) => {
                  setDocumentSelectedValues(e.value);
                }}
                clearable={false}
                options={referenceData["legal_action_fields"].sort((a, b) =>
                  a.label.localeCompare(b.label)
                )}
                size={"mini"}
              />
              {/* <Icon icon="chevron_down" size={14} color="#516BEB" /> */}
            </div>
            <div className="drawer_add_btn">
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                type="button"
                onClick={() => setOpenDoc(!openDocs)}
              >
                <Icon icon="plus" color="#516BEB" />
                Add Document
              </TextButton>
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: "10px" }}>
        {data &&
          data.data &&
          data.data.docs &&
          data.data.docs.map((file, i) => (
            <DocumentCard
              res={file}
              deleteMethod={deleteDocumentData}
              resourceId={customerId}
              entity={entity}
              legaAction={"legalAction"}
              setOpenDoc={setOpenDoc}
            />
          ))}
      </div>
    </>
  );
};

export default DocumentListPage;
