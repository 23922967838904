import React from "react";

const Workflow = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5813 10.2525V11.7409C8.5813 12.1852 8.75767 12.6109 9.07172 12.9249C9.38576 13.239 9.81144 13.4153 10.2557 13.4153H11.7441C12.1884 13.4153 12.614 13.239 12.9281 12.9249C13.2421 12.6109 13.4185 12.1852 13.4185 11.7409V10.2525C13.4185 9.80826 13.2421 9.38259 12.9281 9.06854C12.614 8.7545 12.1884 8.57812 11.7441 8.57812H10.2557C9.81144 8.57812 9.38576 8.7545 9.07172 9.06854C8.75767 9.38259 8.5813 9.80826 8.5813 10.2525Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 4.67442V6.16279C3 6.60707 3.17637 7.03274 3.49042 7.34679C3.80447 7.66084 4.23014 7.83721 4.67442 7.83721H6.16279C6.60707 7.83721 7.03274 7.66084 7.34679 7.34679C7.66084 7.03274 7.83721 6.60707 7.83721 6.16279V4.67442C7.83721 4.23014 7.66084 3.80447 7.34679 3.49042C7.03274 3.17637 6.60707 3 6.16279 3H4.67442C4.23014 3 3.80447 3.17637 3.49042 3.49042C3.17637 3.80447 3 4.23014 3 4.67442Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1628 15.8385V17.3269C14.1628 17.7711 14.3392 18.1968 14.6533 18.5109C14.9673 18.8249 15.393 19.0013 15.8373 19.0013H17.3256C17.7699 19.0013 18.1956 18.8249 18.5096 18.5109C18.8237 18.1968 19.0001 17.7711 19.0001 17.3269V15.8385C19.0001 15.3942 18.8237 14.9685 18.5096 14.6545C18.1956 14.3404 17.7699 14.1641 17.3256 14.1641H15.8373C15.393 14.1641 14.9673 14.3404 14.6533 14.6545C14.3392 14.9685 14.1628 15.3942 14.1628 15.8385Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.27909 5.97565H16.9535C17.2006 5.97565 17.4365 6.07389 17.6114 6.24803C17.7855 6.42291 17.8837 6.65882 17.8837 6.90589V9.51054C17.8837 9.75761 17.7855 9.99351 17.6114 10.1684C17.4365 10.3425 17.2006 10.4408 16.9535 10.4408H14.7209C14.4129 10.4408 14.1628 10.6908 14.1628 10.9989C14.1628 11.307 14.4129 11.557 14.7209 11.557H16.9535C17.496 11.557 18.0169 11.3412 18.4009 10.958C18.7842 10.574 19 10.053 19 9.51054C19 8.70458 19 7.71184 19 6.90589C19 6.36338 18.7842 5.84244 18.4009 5.45844C18.0169 5.07519 17.496 4.85938 16.9535 4.85938C13.9142 4.85938 7.27909 4.85938 7.27909 4.85938C6.97099 4.85938 6.72095 5.10942 6.72095 5.41751C6.72095 5.72561 6.97099 5.97565 7.27909 5.97565Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.7442 16.0267H5.04651C4.79944 16.0267 4.56353 15.9285 4.38865 15.7543C4.21451 15.5795 4.11628 15.3435 4.11628 15.0965C4.11628 14.2905 4.11628 13.2978 4.11628 12.4918C4.11628 12.2448 4.21451 12.0088 4.38865 11.834C4.56353 11.6598 4.79944 11.5616 5.04651 11.5616H9.13954C9.44763 11.5616 9.69767 11.3115 9.69767 11.0035C9.69767 10.6954 9.44763 10.4453 9.13954 10.4453H5.04651C4.504 10.4453 3.98307 10.6611 3.59907 11.0444C3.21581 11.4284 3 11.9493 3 12.4918V15.0965C3 15.639 3.21581 16.1599 3.59907 16.5439C3.98307 16.9272 4.504 17.143 5.04651 17.143H11.7442C12.0523 17.143 12.3023 16.8929 12.3023 16.5848C12.3023 16.2768 12.0523 16.0267 11.7442 16.0267Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6038 12.0934L15.5106 10.9995L16.6038 9.9055C16.8219 9.68819 16.8219 9.33396 16.6038 9.11666C16.3865 8.89861 16.0323 8.89861 15.815 9.11666L14.3266 10.605C14.1086 10.8231 14.1086 11.1758 14.3266 11.3939L15.815 12.8822C16.0323 13.1003 16.3865 13.1003 16.6038 12.8822C16.8219 12.6649 16.8219 12.3107 16.6038 12.0934Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3945 18.4604L12.8829 16.972C13.1009 16.7539 13.1009 16.4012 12.8829 16.1832L11.3945 14.6948C11.1772 14.4767 10.823 14.4767 10.6057 14.6948C10.3876 14.9121 10.3876 15.2663 10.6057 15.4836L11.6989 16.5776L10.6057 17.6715C10.3876 17.8888 10.3876 18.2431 10.6057 18.4604C10.823 18.6784 11.1772 18.6784 11.3945 18.4604Z"
        fill="#516beb"
      />
    </svg>
  );
};

export default Workflow;
