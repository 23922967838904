import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import moment from "moment";
import { StatefulTooltip } from "baseui/tooltip";
import { Paragraph2 } from "baseui/typography";
import _ from "lodash";

function DateMonth(props) {
  let { value, column, alignment, accessor, row, ...rest } = props;
  value = _.get(row.original, accessor, null);

  const {
    referenceData,
    currentOrganization,
    dateFormat,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  // const changedData = value == null ? new Date() : new Date(value);

  // let date = utcTimstampToLocalDate(value);
  // const dateValue = value && value.value;
  let date = value
    ? `${moment(value).format("MMM")} - ${moment(value).format("YYYY")}`
    : "-";
  return visible ? (
    <StatefulTooltip accessibilityType={"tooltip"} content={date}>
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          // margin: "5px",
        }}
      >
        <div
          className="elip"
          ref={ref}
          style={{
            lineHeight: "20px",
            color: rest && rest?.color ? rest?.color : "",
          }}
        >
          {date}
        </div>

        {/* <FormattedDate
        value={date}
        year="numeric"
        month="2-digit"
        day="2-digit"
      /> */}
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        width: "100%",
        textAlign: alignment,
        // margin: "5px",
        whiteSpace: "nowrap",
        lineHeight: "20px",
        color: rest && rest?.color ? rest?.color : "",
      }}
    >
      {date}

      {/* <FormattedDate
        value={date}
        year="numeric"
        month="2-digit"
        day="2-digit"
      /> */}
    </Paragraph2>
  );
}
export default DateMonth;
