import React from "react";

const Dispute = ({ width = 23, height = 23,color='#516BEB' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.73583 17.5832H12.0942C12.4989 17.5832 12.83 17.902 12.83 18.2916C12.83 18.6812 12.4989 19 12.0942 19H4.73583C4.33113 19 4 18.6812 4 18.2916C4 17.902 4.33113 17.5832 4.73583 17.5832ZM7.11994 8.42322L9.20236 6.41839L18.5695 15.4367C19.1435 15.9892 19.1435 16.8889 18.5695 17.4414C17.9956 17.994 17.0611 17.994 16.4872 17.4414L7.11994 8.42322ZM13.3672 4.41355L15.4496 6.41839C16.0236 6.97096 16.0236 7.87065 15.4496 8.42322L14.4047 9.42919L10.2473 5.41951L11.2848 4.42063C11.8587 3.86098 12.7932 3.86098 13.3672 4.41355ZM6.08241 9.4221L10.2473 13.4318L9.20972 14.4307C8.63577 14.9832 7.70126 14.9832 7.1273 14.4307L5.04488 12.4258C4.47093 11.8732 4.47093 10.9736 5.04488 10.421L6.08241 9.4221Z"
        fill={color}
      />
    </svg>
  );
};

export default Dispute;
