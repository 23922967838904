import { ParagraphMedium } from "baseui/typography";
import React from "react";
import _ from "lodash";
export const StatusType = ({ type }) => {
  switch (type) {
    case "RETURNED":
    case "FAILED":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>{type}</ParagraphMedium>
        </div>
      );
    case "TRANSIT":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FBBD04",
            }}
          ></div>
          <ParagraphMedium>Transit</ParagraphMedium>
        </div>
      );
    case "DELIVERED":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#0FB158",
            }}
          ></div>
          <ParagraphMedium>Delivered</ParagraphMedium>
        </div>
      );
    case "CANCELLED":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "flex-start",
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FBBD04",
            }}
          ></div>
          <ParagraphMedium>Cancelled</ParagraphMedium>
        </div>
      );
    default:
      return null;
  }
};
const LetterDeliveryStatus = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  return <StatusType type={value} />;
};
export default LetterDeliveryStatus;
