import DayCurrency from "./DayCurrency/DayCurrency";
import HeaderCustomerWorkflow from "./HeaderCustomerWorkflow/HeaderCustomerWorkflow";
import HeaderSort from "./HeaderSort/HeaderSort";
import TotalCustomer from "./TotalCustomer/TotalCustomer";

const headerType = ({ headerFilterType, alignment, cellType, isCurrency }) => {
  return (props) =>
    HeaderSort({
      ...props,
      alignment,
      headerFilterType,
      cellType,
      isCurrency,
    });
};

export default headerType;
