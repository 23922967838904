import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  ACT_FIELD_VISIT,
  ACT_RETURN_VISIT,
  ACT_RETURN_FIELD_VISIT,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";

const ReturnVisitForm = (props) => {
  const {
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isIncrementor = true,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();

  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const [location] = watch(["location"]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const orgUsers = useMemo(() => {
    let orgBusiness = referenceData["users"].filter((e) => {
      return e?.organization?.includes(currentOrganization) && e && e.active;
    });
    return orgBusiness || [];
  }, []);

  const locations = useMemo(() => {
    const addressContacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = address
          .map(({ status, call_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name ? curr?.first_name : ""} - ${
                nRest.line_1
              }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
                nRest.city
              }-${nRest.zip_code} ${designation?.label}`,
              id: nRest._id,
              contactId: curr?._id,
              designation: designation.id,
              status,
            };
          })
          .filter((e) => e.status === "VALID" || e.status === "LEAD");
        let locationOption = [...prev, ...nestedContacts];
        if (locationOption && locationOption.length > 0) {
          setValue("location", [locationOption && locationOption[0]]);
        }
        return locationOption;
      },
      []
    );

    return [
      ...addressContacts,
      { id: "organization", label: "Organization Address" },
      { id: "other", label: "Other Location" },
    ];
  }, []);

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    const {
      return_visit_date,
      return_visit_time,
      location,
      custom_location = "",
      assigned_to,
    } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));
    let values = {
      ...formValues,
      ...(isIncrementor ? { content: "-" } : {}),
      return_visit_date: moment
        .tz(
          `${return_visit_date.getFullYear()}-${
            return_visit_date.getMonth() + 1
          }-${return_visit_date.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      assigned_to: getSelectValues(assigned_to),
      return_visit_time: getSelectValues(return_visit_time),
      ...(location && location[0] && location[0].id === "other"
        ? { custom_location }
        : {}),
      return_visit_contact:
        location && location[0] && location[0].id && location[0].contactId
          ? `${location[0].contactId}/${location[0].id}`
          : getSelectValues(location),
      ...getActionReminder(data, {
        is_consent_enabled: isIncrementor,
        is_calendar: false,
        is_incrementor: isIncrementor,
      }),
      mention_users: uniqueMentionedUsers, // TODO
    };

    await serviceMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "5px", marginTop: "20px" }}>
        <div style={{ width: "150px" }}>
          <Controller
            name="return_visit_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                label={intl.formatMessage({
                  id: "return_visit_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div style={{ width: "150px" }}>
          <Controller
            name="return_visit_time"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "return_visit_time",
                })}
                placeholder={intl.formatMessage({
                  id: "return_visit_time",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["time"] || []}
              />
            )}
          />
        </div>
        <div style={{ width: "200px" }}>
          <Controller
            name="assigned_to"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "assigned_to",
                })}
                placeholder={intl.formatMessage({
                  id: "assigned_to",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={orgUsers}
              />
            )}
          />
        </div>
        <div style={{ width: "350px" }}>
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "location",
                })}
                placeholder={intl.formatMessage({
                  id: "location",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={locations}
              />
            )}
          />
        </div>
      </div>

      {location && getSelectValues(location) === "other" ? (
        <Controller
          name="custom_location"
          control={control}
          render={({ field }) => (
            <TextBox
              size={SIZE.compact}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "type_your_location",
              })}
              placeholder={intl.formatMessage({
                id: "type_your_location",
              })}
              value={field.value}
            />
          )}
        />
      ) : (
        <></>
      )}

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={
          formValues.action_type === "FIELD_VISIT"
            ? ACT_RETURN_FIELD_VISIT
            : ACT_FIELD_VISIT
        }
        outcome={ACT_FIELD_VISIT}
        is_calendar={false}
        is_consent_enabled={false}
        is_incrementor={props.isIncrementor}
        is_consent={props.isConsent}
        is_valid_from_contact={
          formValues.action_type === "FIELD_VISIT" ? true : false
        }
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Submit
        </TextButton>
      </div>
    </form>
  );
};

export default ReturnVisitForm;
