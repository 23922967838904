import React, { useContext, useRef, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient, useQuery } from "react-query";
import _ from "lodash";
import moment from "moment";
import { Icon, Typography } from "../../components_v2";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  deleteStickyDairyNote,
  postDairyNote,
  postStickyDairyNote,
} from "../../services";
import { CO_TIMELINE_, CUSTOMER_OVERVIEW_ } from "../../constants";
import NotesSelect from "../../components_v2/NotesSelect";
import { DatePicker } from "baseui/datepicker";
import {
  ADD_CUSTOMER_NOTES,
  ADD_LIST_VALUES,
  DISABLE_NOTES,
  MAINTAIN_STICKY_NOTES,
} from "../../providers/RBACProvider/permissionList";
import useFormat from "../../hooks/useFormat";
import { NOTE_LIST_DRAWER, setDrawerState } from "../../redux/actions";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";

export default function NotesComp({
  setDrawer,
  customer,
  isSticky,
  setIsSticky,
}) {
  const editorRef = useRef(null);
  const queryClient = useQueryClient();
  const { customerId } = useParams();
  const format = useFormat();
  const BlockEmbed = ReactQuill.Quill.import("blots/embed");

  let typeOption = [
    {
      label: <Icon icon="flag" color="#516beb" size={18} />,
      id: "INFORMATIONAL",
    },
    { label: <Icon icon="flag" color="#FBBD04" size={18} />, id: "ALERT" },
    { label: <Icon icon="flag" color="#FD372A" size={18} />, id: "CRITICAL" },
  ];

  const {
    referenceData,
    currentOrganization,
    users,
    userInfo,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      type: [{ id: "INFORMATIONAL" }],
      category: [{ id: "general", label: "General" }],
      lty: [{ id: "cmpt" }],
    },
  });

  let { category } = watch();

  const addNote = useMutation(
    (values) =>
      postDairyNote({
        organization: currentOrganization,
        customerId,
        ...values,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (category && category[0] && category[0].isCreatable) {
          maxyfiContextValue.refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        setValue("notes", "");
        queryClient.refetchQueries(`${CO_TIMELINE_}${customerId}`);
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
      },
    }
  );

  const onSubmit = async () => {
    const data = getValues();
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.notes, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let value = {
      notes: data.notes,
      category:
        data && data.category && data.category.map((val) => val.id).toString(),
      follow_up_date:
        data && data.follow_up_date && data.follow_up_date.date
          ? moment(data.follow_up_date.date).utc().valueOf()
          : data.follow_up_date
          ? moment(data.follow_up_date).utc().valueOf()
          : undefined,
      lty: data && data.lty && data.lty.map((val) => val.id).toString(),
      is_enable_alert_and_follow_up: false,
      mention_users: uniqueMentionedUsers,
      type: data && data.type && data.type.map((val) => val.id).toString(),
    };

    await addNote.mutateAsync({
      ...value,
    });
  };
  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  let dateFormat =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails?.date_format &&
    currentDefaultFormatDetails?.date_format.replace(/dd|yyyy/gi, function (x) {
      return x.toLowerCase();
    });

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <form className="co_sticky_note">
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ width: "70px" }}>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <NotesSelect
                {...field}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                clearable={false}
                options={typeOption}
                size={"mini"}
              />
            )}
          />
        </div>
        <div style={{ flex: "1" }}>
          <Controller
            name="category"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <NotesSelect
                {...field}
                value={field.value}
                creatable={creatablePermission ? true : false}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                clearable={false}
                options={referenceData && referenceData["notes_category"]}
                size={"mini"}
              />
            )}
          />
        </div>
        <div style={{ flex: "1" }}>
          <Controller
            name="follow_up_date"
            control={control}
            render={({ field }) => (
              // <div style={{ marginBottom: "0px" }}>
              <DatePicker
                overrides={{
                  Input: {
                    props: {
                      overrides: {
                        Root: {
                          style: () => ({
                            marginLeft: "0",
                            marginTop: "0",
                            border: "none",
                          }),
                        },
                        Input: {
                          style: () => ({
                            outline: `0px`,
                            backgroundColor: "#ffffff",
                            "::placeholder": {
                              color: "#333860",
                              fontSze: "12px",
                              lineHeight: "14.4px",
                            },
                          }),
                        },
                      },
                    },
                  },
                }}
                placeholder="Follow-up"
                size={"mini"}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                formatString={dateFormat}
              />
            )}
          />
        </div>
      </div>
      <div className="legal-notes-section">
        <Controller
          name="notes"
          rules={{ required: "Required" }}
          control={control}
          render={({ field }) => (
            <ReactQuill
              {...field}
              name={field.name}
              id={"editor"}
              ref={editorRef}
              className="note-ql-container"
              theme="snow"
              value={field.value}
              modules={modules}
              style={{ height: "134px" }}
              placeholder="Type Notes"
            />
          )}
        />
        {space && !space?.role_permissions?.includes(DISABLE_NOTES) && (
          <RBACWrapper role={ADD_CUSTOMER_NOTES} type={"PERMISSION"}>
            <div className="co_note_action_icn">
              <Icon
                icon="enlarge"
                size={18}
                isPressable
                color={"#ADADAD"}
                onClick={() => {
                  reset();
                  setDrawer(NOTE_LIST_DRAWER);
                }}
              />
            </div>
          </RBACWrapper>
        )}
      </div>
      <RBACWrapper role={MAINTAIN_STICKY_NOTES} type="PERMISSION">
        <div className={"co_sticky_note_action_icn_note "}>
          <Icon
            icon={"sticky_note"}
            size={18}
            isPressable
            onClick={() => {
              reset();
              setIsSticky(true);
            }}
          />
        </div>
      </RBACWrapper>
      <div className={"co_sticky-notes-actions"}>
        {space && !space?.role_permissions?.includes(DISABLE_NOTES) && (
          <RBACWrapper role={ADD_CUSTOMER_NOTES} type={"PERMISSION"}>
            <Icon
              icon="tick"
              size={18}
              isPressable
              color="#0FB158"
              onClick={async () => {
                await onSubmit();
              }}
            />
          </RBACWrapper>
        )}
      </div>
    </form>
  );
}
