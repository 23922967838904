import React from "react";

const SuitecaseSvg = ({size=19,color="#333860"}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.48078 15.3749C3.10193 15.3749 2.78125 15.2437 2.51875 14.9812C2.25625 14.7187 2.125 14.398 2.125 14.0191V6.23074C2.125 5.85189 2.25625 5.53121 2.51875 5.26871C2.78125 5.00621 3.10193 4.87496 3.48078 4.87496H6.625V3.60576C6.625 3.22692 6.75625 2.90625 7.01875 2.64375C7.28125 2.38125 7.60192 2.25 7.98077 2.25H10.5192C10.898 2.25 11.2187 2.38125 11.4812 2.64375C11.7437 2.90625 11.875 3.22692 11.875 3.60576V4.87496H15.0192C15.398 4.87496 15.7187 5.00621 15.9812 5.26871C16.2437 5.53121 16.375 5.85189 16.375 6.23074V14.0191C16.375 14.398 16.2437 14.7187 15.9812 14.9812C15.7187 15.2437 15.398 15.3749 15.0192 15.3749H3.48078ZM7.74998 4.87496H10.75V3.60576C10.75 3.54806 10.7259 3.49517 10.6779 3.44709C10.6298 3.39901 10.5769 3.37496 10.5192 3.37496H7.98077C7.92307 3.37496 7.87018 3.39901 7.82209 3.44709C7.77402 3.49517 7.74998 3.54806 7.74998 3.60576V4.87496ZM15.25 11.0624H11.125V12.3749H7.375V11.0624H3.24998V14.0191C3.24998 14.0768 3.27402 14.1297 3.32209 14.1778C3.37018 14.2259 3.42308 14.2499 3.48078 14.2499H15.0192C15.0769 14.2499 15.1298 14.2259 15.1779 14.1778C15.2259 14.1297 15.25 14.0768 15.25 14.0191V11.0624ZM8.49998 11.2499H9.99998V9.74994H8.49998V11.2499ZM3.24998 9.93746H7.375V8.62496H11.125V9.93746H15.25V6.23074C15.25 6.17304 15.2259 6.12014 15.1779 6.07206C15.1298 6.02398 15.0769 5.99994 15.0192 5.99994H3.48078C3.42308 5.99994 3.37018 6.02398 3.32209 6.07206C3.27402 6.12014 3.24998 6.17304 3.24998 6.23074V9.93746Z" fill={color} />
    </svg>

  );
};

export default SuitecaseSvg;
