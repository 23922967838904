import { alphabetize } from "../../utils/alphabetize";
import { tokenize } from "excel-formula-tokenizer";
import { Parser } from "hot-formula-parser";
import {
  ADD_ADHOC_UPLOAD_DATA,
  UPDATE_MAP_FIELDS,
  UPDATE_FORMAT_DATA,
  UPDATE_DEFAULT_DATA,
  UPDATE_SYSTEM_FIELDS,
  UPDATE_TEMPLATE_DATA_LIST,
  INITIALSTATE_STATE,
  ADHOC_REFETCH,
  SET_AU_FILTER,
  ADHOC_FILTER,
  VALIDATION_ADHOC_FILTER,
  BUSINESS_UNIT_DATA,
  FORMULA_FIELDS,
  DELETE_FORMULA_FIELDS,
  UPDATE_FORMULA_FIELDS,
  UPDATE_CONTACT_INDEX,
} from "./action";

import moment from "moment";
import _ from "lodash";
import { formulas } from "../../utils/formulaObj";

const invAmount = [
  "client_charges",
  "client_intrest",
  "invoice_total_amount",
  "principal_amount",
  "due_amount",
  "collection_fee",
];

const conditions = [
  {
    trigger: "contact_first_name",
    toRemove: ["customer_name"],
  },
  {
    trigger: "customer_name",
    toRemove: ["contact_first_name"],
  },
  {
    trigger: "collection_fee",
    toRemove: [
      "client_charges",
      "client_intrest",
      "invoice_total_amount",
      "principal_amount",
      "due_amount",
    ],
  },
  {
    trigger: "client_charges",
    toRemove: [
      "collection_fee",
      "client_intrest",
      "invoice_total_amount",
      "principal_amount",
      "due_amount",
    ],
  },
  {
    trigger: "invoice_total_amount",
    toRemove: [
      "collection_fee",
      "client_intrest",
      "client_charges",
      "principal_amount",
      "due_amount",
    ],
  },
  {
    trigger: "client_intrest",
    toRemove: [
      "collection_fee",
      "client_charges",
      "invoice_total_amount",
      "principal_amount",
      "due_amount",
    ],
  },
  {
    trigger: "principal_amount",
    toRemove: [
      "collection_fee",
      "client_intrest",
      "invoice_total_amount",
      "client_charges",
      "due_amount",
    ],
  },
  {
    trigger: "due_amount",
    toRemove: [
      "collection_fee",
      "client_intrest",
      "invoice_total_amount",
      "principal_amount",
      "client_charges",
    ],
  },
];

function updateRequiredFields(mappedField, requiredField) {
  const mappedFieldsSet = new Set(
    mappedField.map((field) => field.system_field)
  );
  const requiredFieldsSet = new Set(requiredField.map((field) => field.id));

  const invAmountMap = new Map(
    invAmount.map((e) => [e, _.startCase(e.replaceAll("_", " "))])
  );

  function addField(system_column, id, isMapped = false) {
    requiredField.push({ system_column, id, isMapped });
  }

  function removeFieldById(id) {
    const index = requiredField.findIndex(({ id: fieldId }) => fieldId === id);
    if (index !== -1) requiredField.splice(index, 1);
  }

  function handleClientNameFields(
    primary,
    secondary,
    primaryName,
    secondaryName
  ) {
    if (requiredFieldsSet.has(primary) && !requiredFieldsSet.has(secondary)) {
      removeFieldById(primary);
      if (mappedFieldsSet.has(secondary)) {
        addField(secondaryName, secondary, true);
      } else {
        addField(primaryName, primary);
        addField(secondaryName, secondary);
      }
    }
  }

  function handleInvAmountFields(trigger, ...fieldsToRemove) {
    if (
      requiredFieldsSet.has(trigger) &&
      fieldsToRemove.every((field) => !requiredFieldsSet.has(field))
    ) {
      removeFieldById(trigger);
      const mappedInvAmount = invAmount.filter(
        (e) => e !== trigger && mappedFieldsSet.has(e)
      );
      if (mappedInvAmount.length) {
        addField(
          invAmountMap.get(mappedInvAmount[0]),
          mappedInvAmount[0],
          true
        );
      } else {
        invAmount.forEach((field) => addField(invAmountMap.get(field), field));
      }
    }
  }

  handleClientNameFields(
    "contact_first_name",
    "customer_name",
    "Contact First Name",
    "Customer Name"
  );
  handleClientNameFields(
    "customer_name",
    "contact_first_name",
    "Customer Name",
    "Contact First Name"
  );
  handleInvAmountFields(
    "invoice_total_amount",
    "due_amount",
    "client_charges",
    "client_intrest",
    "principal_amount",
    "collection_fee"
  );
  handleInvAmountFields(
    "due_amount",
    "client_charges",
    "client_intrest",
    "principal_amount",
    "collection_fee",
    "invoice_total_amount"
  );
  handleInvAmountFields(
    "client_charges",
    "due_amount",
    "client_intrest",
    "principal_amount",
    "collection_fee",
    "invoice_total_amount"
  );
  handleInvAmountFields(
    "client_intrest",
    "due_amount",
    "client_charges",
    "principal_amount",
    "collection_fee",
    "invoice_total_amount"
  );
  handleInvAmountFields(
    "principal_amount",
    "due_amount",
    "client_charges",
    "client_intrest",
    "collection_fee",
    "invoice_total_amount"
  );
  handleInvAmountFields(
    "collection_fee",
    "due_amount",
    "client_charges",
    "client_intrest",
    "principal_amount",
    "invoice_total_amount"
  );

  requiredField.forEach((field) => {
    if (mappedFieldsSet.has(field.id)) field.isMapped = true;
  });

  conditions.forEach(({ trigger, toRemove }) => {
    if (mappedFieldsSet.has(trigger)) {
      toRemove.forEach((idToRemove) => {
        const index = requiredField.findIndex(
          (field) => field.id === idToRemove && !field.isMapped
        );
        if (index !== -1) requiredField.splice(index, 1);
      });
    }
  });
}

const initialState = {
  mappingFieldList: [],
  defaultSystemFields: [],
  defaultFields: [],
  requiredFields: [],
  systemFields: [],
  requiredDefaultFields: [],
  requiredFormatFields: [],
  adhocRefetchActions: 0,
  validDefaultFields: [],
  tableCurrentState: {
    filters: {},
    formFilters: [
      {
        id: [],
      },
    ],
  },
  adhocFilter: false,
  defaultRequiredFields: [],
  fieldMessage: [],
  businessUnitRd: [],
  formulaVariables: [],
};

const adhocDataReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ADHOC_FILTER: {
      return {
        ...state,
        adhocFilter: payload,
      };
    }
    case ADD_ADHOC_UPLOAD_DATA:
      /* getting the mapping system fields value */
      if (payload.systemFields) {
        const requiredFields = [];
        payload.systemFields.map(({ required, system_column, id }) => {
          if (required == true) {
            requiredFields.push({ system_column, id, isMapped: false });
          }
        });
        payload.requiredFields = [...requiredFields];

        payload.defaultSystemFields = payload.systemFields;
      }
      /* set value of unmapped default fieds  */
      if (payload.defaultFields) {
        payload.requiredDefaultFields = [...payload.defaultFields];
      }

      if (payload.mappingFieldList) {
        payload.mappingFieldList = payload.mappingFieldList.map((e, i) => {
          return {
            index: alphabetize[i],
            upload_field: e.upload_field,
            first_row_data: e.first_row_data,
            format: e.format,
            system_field: e.system_field,
            contact_index: e.contact_index,
            module: e.module,
          };
        });
      }

      return {
        ...state,
        ...payload,
      };

    case FORMULA_FIELDS: {
      let formulaFieldStrucher;
      if (payload?.formulaFields) {
        formulaFieldStrucher = {
          ...payload.formulaFields,
          index: alphabetize[state.mappingFieldList.length],
        };
      }
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList, formulaFieldStrucher],
        formulaVariables: [
          ...state.formulaVariables,
          ..._.get(payload, "formulaFields.variableUsed", []),
        ],
      };
    }

    case DELETE_FORMULA_FIELDS: {
      let formulMapdata = state.mappingFieldList;
      let deleteFormulaFields = formulMapdata.splice(payload, 1);
      let reMapFieldData = formulMapdata.map((e, i) => {
        return {
          ...e,
          index: alphabetize[i],
        };
      });

      let vaValues = new Set();
      state.mappingFieldList.forEach((obj) =>
        obj?.variableUsed?.forEach((value) => vaValues.add(value))
      );

      let filteredY = state.formulaVariables.filter((value) =>
        vaValues.has(value)
      );

      return {
        ...state,
        mappingFieldList: [...reMapFieldData],
        formulaVariables: [...filteredY],
      };
    }

    case UPDATE_FORMULA_FIELDS: {
      state.mappingFieldList[payload.formulaFields.rowIndex] = {
        ...state.mappingFieldList[payload.formulaFields.rowIndex],
        upload_field: payload.formulaFields.upload_field,
        first_row_data: payload.formulaFields.first_row_data,
        isValid: payload.formulaFields.isValid,
        variableUsed: [...payload.formulaFields.variableUsed],
      };

      let vaValues = new Set();
      state.mappingFieldList.forEach((obj) => {
        obj?.variableUsed?.forEach((value) => vaValues.add(value));
      });

      let filteredY = state.formulaVariables.filter((value) =>
        vaValues.has(value)
      );

      state.mappingFieldList.forEach((obj) => {
        obj?.variableUsed?.forEach((value) => {
          if (!filteredY.includes(value)) {
            filteredY.push(value);
          }
        });
      });
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        formulaVariables: [...filteredY],
      };
    }

    //   state.systemColumn[payload.index].mapped_field = payload.mapField;
    //   state.systemColumn[payload.index].data =
    //     state.firstRowData[payload.mapField];
    //   const systemColumn = [...state.systemColumn];
    //   return {
    //     ...state,
    //     systemColumn: [...systemColumn],
    //   };

    case UPDATE_FORMAT_DATA:
      /* formate value  */
      if (!state.mappingFieldList[payload.index].format) {
        const formatSelectIndex = state.requiredFormatFields.findIndex(
          ({ id, isSelected }) =>
            id == state.mappingFieldList[payload.index].system_field &&
            isSelected == false
        );
        state.requiredFormatFields[formatSelectIndex].isSelected = true;
      }

      state.mappingFieldList[payload.index].format = payload.formatData;

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
      };

    case UPDATE_CONTACT_INDEX: {
      state.mappingFieldList[payload.index].contact_index =
        payload.contactIndex;
      if (payload && payload.module) {
        state.mappingFieldList[payload.index].module = payload.module;
      }
      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
      };
    }

    case UPDATE_SYSTEM_FIELDS: {
      const invAmount = [
        "client_charges",
        "client_intrest",
        "invoice_total_amount",
        "principal_amount",
        "due_amount",
        "collection_fee",
      ];
      const prevSystemField =
        state.mappingFieldList[payload.index].system_field;
      if (prevSystemField === payload.systemField) {
        return {
          ...state,
        };
      }

      let newRequiredFields = [...state.requiredFields];

      let checkDueReqFields = state.requiredFields.find(
        ({ id }) => id == "due_amount"
      );
      let checkInvDueReqFields = state.requiredFields.find(
        ({ id }) => id == "invoice_total_amount"
      );
      let findClientCharges = state.requiredFields.find(
        ({ id }) => id == "client_charges"
      );
      let findClientIntrest = state.requiredFields.find(
        ({ id }) => id == "client_intrest"
      );
      let findPrincipleAmt = state.requiredFields.find(
        ({ id }) => id == "principal_amount"
      );

      let findCollectionFee = state.requiredFields.find(
        ({ id }) => id == "collection_fee"
      );

      let customerName = state.requiredFields.find(
        ({ id }) => id == "customer_name"
      );
      let contactFirstName = state.requiredFields.find(
        ({ id }) => id == "contact_first_name"
      );

      if (
        !customerName &&
        contactFirstName &&
        state.mappingFieldList[payload.index].system_field ==
          "contact_first_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "contact_first_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "customer_name"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Contact First Name",
            id: "contact_first_name",
            isMapped: false,
          });
        }
      }

      if (
        !contactFirstName &&
        customerName &&
        state.mappingFieldList[payload.index].system_field == "customer_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "customer_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "contact_first_name"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Contact First Name",
            id: "contact_first_name",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Contact First Name",
            id: "contact_first_name",
            isMapped: false,
          });
        }
      }

      if (
        !checkDueReqFields &&
        !findClientCharges &&
        !findClientIntrest &&
        !findPrincipleAmt &&
        !findCollectionFee &&
        checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field ==
          "invoice_total_amount"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "invoice_total_amount"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let fiterInvAmt = invAmount.filter((e) => e !== "invoice_total_amount");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column:
              findMapInvAmt && findMapInvAmt[0] === "invoice_total_amount"
                ? "Invoice Amount"
                : _.startCase(
                    findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
                  ),
            id: "due_amount",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Charges",
            id: "client_charges",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Intrest",
            id: "client_intrest",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Principal Amount",
            id: "principal_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Collection Fee",
            id: "collection_fee",
            isMapped: false,
          });
        }
      }

      if (
        checkDueReqFields &&
        !checkInvDueReqFields &&
        !findClientCharges &&
        !findClientIntrest &&
        !findPrincipleAmt &&
        !findCollectionFee &&
        state.mappingFieldList[payload.index].system_field == "due_amount"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "due_amount"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let fiterInvAmt = invAmount.filter((e) => e !== "due_amount");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column:
              findMapInvAmt && findMapInvAmt[0] === "invoice_total_amount"
                ? "Invoice Amount"
                : _.startCase(
                    findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
                  ),
            id: findMapInvAmt && findMapInvAmt[0],
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Charges",
            id: "client_charges",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Intrest",
            id: "client_intrest",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Principal Amount",
            id: "principal_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Collection Fee",
            id: "collection_fee",
            isMapped: false,
          });
        }
      }

      if (
        findClientCharges &&
        !checkInvDueReqFields &&
        !checkDueReqFields &&
        !findClientIntrest &&
        !findPrincipleAmt &&
        !findCollectionFee &&
        state.mappingFieldList[payload.index].system_field == "client_charges"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "client_charges"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let fiterInvAmt = invAmount.filter((e) => e !== "client_charges");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column:
              findMapInvAmt && findMapInvAmt[0] === "invoice_total_amount"
                ? "Invoice Amount"
                : _.startCase(
                    findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
                  ),
            id: findMapInvAmt && findMapInvAmt[0],
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Charges",
            id: "client_charges",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Intrest",
            id: "client_intrest",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Principal Amount",
            id: "principal_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Collection Fee",
            id: "collection_fee",
            isMapped: false,
          });
        }
      }

      if (
        findClientIntrest &&
        !checkInvDueReqFields &&
        !checkDueReqFields &&
        !findClientCharges &&
        !findPrincipleAmt &&
        !findCollectionFee &&
        state.mappingFieldList[payload.index].system_field == "client_intrest"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "client_intrest"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let fiterInvAmt = invAmount.filter((e) => e !== "client_intrest");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column:
              findMapInvAmt && findMapInvAmt[0] === "invoice_total_amount"
                ? "Invoice Amount"
                : _.startCase(
                    findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
                  ),
            id: findMapInvAmt && findMapInvAmt[0],
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Charges",
            id: "client_charges",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Intrest",
            id: "client_intrest",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Principal Amount",
            id: "principal_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Collection Fee",
            id: "collection_fee",
            isMapped: false,
          });
        }
      }

      if (
        findPrincipleAmt &&
        !checkInvDueReqFields &&
        !checkDueReqFields &&
        !findClientCharges &&
        !findClientIntrest &&
        !findCollectionFee &&
        state.mappingFieldList[payload.index].system_field == "principal_amount"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "principal_amount"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let fiterInvAmt = invAmount.filter((e) => e !== "principal_amount");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column:
              findMapInvAmt && findMapInvAmt[0] === "invoice_total_amount"
                ? "Invoice Amount"
                : _.startCase(
                    findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
                  ),
            id: findMapInvAmt && findMapInvAmt[0],
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Charges",
            id: "client_charges",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Intrest",
            id: "client_intrest",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Principal Amount",
            id: "principal_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Collection Fee",
            id: "collection_fee",
            isMapped: false,
          });
        }
      }

      if (
        findCollectionFee &&
        !checkInvDueReqFields &&
        !checkDueReqFields &&
        !findClientCharges &&
        !findClientIntrest &&
        !findPrincipleAmt &&
        state.mappingFieldList[payload.index].system_field == "collection_fee"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "collection_fee"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let fiterInvAmt = invAmount.filter((e) => e !== "collection_fee");

        let findMapInvAmt = fiterInvAmt.filter((value) =>
          state.mappingFieldList.some((item) => item.system_field === value)
        );

        if (findMapInvAmt.length > 0) {
          state.requiredFields.push({
            system_column:
              findMapInvAmt && findMapInvAmt[0] === "invoice_total_amount"
                ? "Invoice Amount"
                : _.startCase(
                    findMapInvAmt && findMapInvAmt[0].replaceAll("_", " ")
                  ),
            id: findMapInvAmt && findMapInvAmt[0],
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Charges",
            id: "client_charges",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Client Intrest",
            id: "client_intrest",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Principal Amount",
            id: "principal_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Collection Fee",
            id: "collection_fee",
            isMapped: false,
          });
        }
      }
      // }

      if (prevSystemField) {
        const preSystemFieldIndex = state.systemFields.findIndex(
          (sys) => prevSystemField == sys.id
        );
        const preSystemFieldConfig = state.systemFields[preSystemFieldIndex];
        if (preSystemFieldConfig && preSystemFieldConfig.required) {
          // state.requiredFields.push(preSystemFieldConfig);

          let requiredFieldIndex = state.requiredFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFields[requiredFieldIndex].isMapped = false;
          }
        }

        if (preSystemFieldConfig && preSystemFieldConfig.format) {
          let requiredFieldIndex = state.requiredFormatFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFormatFields.splice(requiredFieldIndex, 1);
          }
        }

        if (
          !state.mappingFieldList.find(
            ({ system_field }) =>
              system_field === state.systemFields[preSystemFieldIndex].id
          )
        ) {
          state.systemFields[preSystemFieldIndex].isSelected = false;
        }
      }

      state.mappingFieldList[payload.index].system_field = payload.systemField;
      state.mappingFieldList[payload.index].format = null;
      if (payload && payload.module) {
        state.mappingFieldList[payload.index].module = payload.module;
      } else {
        delete state.mappingFieldList[payload.index].module;
        state.mappingFieldList[payload.index].contact_index = null;
      }

      const requiredFieldIndex = state.requiredFields.findIndex((req) => {
        return req.id == payload.systemField;
      });
      if (requiredFieldIndex != -1) {
        state.requiredFields[requiredFieldIndex].isMapped = true;
      }

      const selectSystemField =
        state &&
        state.systemFields &&
        state.systemFields.findIndex(
          (select) => select.id == payload.systemField
        );

      state.systemFields[selectSystemField].isSelected = true;

      if (
        state.mappingFieldList[payload.index].system_field === "customer_name"
      ) {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "contact_first_name" || isMapped
        );
      }

      if (
        state.mappingFieldList[payload.index].system_field ===
        "contact_first_name"
      ) {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "customer_name" || isMapped
        );
      }

      if (
        checkDueReqFields &&
        state.mappingFieldList[payload.index].system_field === "due_amount"
      ) {
        let filterInvAmt = invAmount.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterInvAmt.includes(item.id)
        );
      }

      if (
        checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field ===
          "invoice_total_amount"
      ) {
        let filterInvAmt = invAmount.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterInvAmt.includes(item.id)
        );
      }
      // }

      if (
        findClientCharges &&
        state.mappingFieldList[payload.index].system_field === "client_charges"
      ) {
        let filterInvAmt = invAmount.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterInvAmt.includes(item.id)
        );
      }

      if (
        findClientIntrest &&
        state.mappingFieldList[payload.index].system_field === "client_intrest"
      ) {
        let filterInvAmt = invAmount.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterInvAmt.includes(item.id)
        );
      }

      if (
        findPrincipleAmt &&
        state.mappingFieldList[payload.index].system_field ===
          "principal_amount"
      ) {
        let filterInvAmt = invAmount.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterInvAmt.includes(item.id)
        );
      }

      if (
        findCollectionFee &&
        state.mappingFieldList[payload.index].system_field === "collection_fee"
      ) {
        let filterInvAmt = invAmount.filter(
          (e) => e != state.mappingFieldList[payload.index].system_field
        );
        newRequiredFields = state.requiredFields.filter(
          (item) => !filterInvAmt.includes(item.id)
        );
      }

      if (state.systemFields[selectSystemField].format?.reference) {
        const { system_column, id } = state.systemFields[selectSystemField];
        {
          state.requiredFormatFields.push({
            system_column,
            id,
            isSelected: false,
            index_of: payload.index,
          });
        }
      }

      // if (payload.type == "clear") {
      //   state.mappingFieldList[payload.index].system_field = null;
      //   state.mappingFieldList[payload.index].format = null;
      //   if (payload && payload.index) {
      //     state.requiredFormatFields.filter((e) => e.index_of != payload.index);
      //
      //   }
      // }

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        requiredFields: [...newRequiredFields],
        requiredFormatFields: [...state.requiredFormatFields],
      };
    }

    case UPDATE_DEFAULT_DATA: {
      // if (!state.validDefaultFields[payload.index].default.value) {
      //   const requiredDefaultFieldsIndex =
      //     state.requiredDefaultFields.findIndex(
      //       ({ id }) => id === state.validDefaultFields[payload.index].id
      //     );
      //   state.requiredDefaultFields.splice(requiredDefaultFieldsIndex, 1);
      // }

      let newValidDefaultFields = [...state.validDefaultFields];
      let newReqFields = [...state.fieldMessage];

      newValidDefaultFields[payload.index].default.value = payload.defaultData;

      if (
        state.validDefaultFields[payload.index].id == "invoice_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_date",
            message: `Invoice Date is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date");
        }
      }

      if (
        state.validDefaultFields[payload.index].id == "invoice_due_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_due_date",
            message: `Due Date entered is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_due_date");
        }
      }

      let findInvDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_date"
      );

      let findDueDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_due_date"
      );

      if (
        findInvDateFields &&
        findInvDateFields.id &&
        !findInvDateFields.default.value.startsWith("{{") &&
        !findInvDateFields.default.value.endsWith("}}") &&
        findDueDateFields &&
        findDueDateFields.id &&
        !findDueDateFields.default.value.startsWith("{{") &&
        !findDueDateFields.default.value.endsWith("}}") &&
        newReqFields.filter((nr) =>
          ["invoice_date", "invoice_due_date"].includes(nr.id)
        ).length === 0
      ) {
        let invDate = moment(
          findInvDateFields.default.value,
          state.organizationDtl.date_format
        );
        let dueDate = moment(
          findDueDateFields.default.value,
          state.organizationDtl.date_format
        );

        if (invDate.diff(dueDate, "days") > 0) {
          newReqFields.push({
            id: "invoice_date_gr",
            message: `Invoice Date has to be lesser than or equal to Due Date`,
          });
        } else {
          newReqFields = newReqFields.filter(
            (e) => e.id != "invoice_due_date_gr"
          );
        }

        if (dueDate.diff(invDate, "days") < 0) {
          newReqFields.push({
            id: "invoice_due_date_gr",
            message: `Due Date has to be greater than or equal to Invoice Date`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date_gr");
        }
      } else {
        newReqFields = newReqFields.filter(
          (e) => e.id != "invoice_due_date_gr" || e.id != "invoice_date_gr"
        );
      }

      return {
        ...state,
        validDefaultFields: [...state.validDefaultFields],
        fieldMessage: [...newReqFields],
      };

      break;
    }

    case UPDATE_TEMPLATE_DATA_LIST:
      // let invAmount = [
      //   "client_charges",
      //   "client_intrest",
      //   "invoice_total_amount",
      //   "principal_amount",
      //   "due_amount",
      //   "collection_fee",
      // ];
      let formulaVar = [];
      let newRequiredFields = state.requiredFields;
      let newFormatFields = [];

      state.mappingFieldList = state.mappingFieldList.map((e) => ({
        ...e,
        format: null,
        system_field: null,
        contact_index: null,
        module: null,
      }));

      let filterFormulaData = payload.mappingFields.filter(
        ({ is_formula }) => is_formula
      );

      if (filterFormulaData && filterFormulaData.length > 0) {
        let filterPreFormaul = state.mappingFieldList.filter(
          ({ is_formula }) => !is_formula
        );
        let mergeFormuaData = [...filterPreFormaul, ...filterFormulaData];
        state.mappingFieldList = [...mergeFormuaData].map((e, i) => {
          return {
            ...e,
            index: alphabetize[i],
          };
        });
      } else {
        let filterPreFormaul = state.mappingFieldList.filter(
          ({ is_formula }) => !is_formula
        );
        state.mappingFieldList = [...filterPreFormaul].map((e, i) => {
          return {
            ...e,
            index: alphabetize[i],
          };
        });
      }

      let validatingData = state.mappingFieldList.reduce((acc, cur) => {
        acc[cur.index] = cur.first_row_data;
        return acc;
      }, {});

      payload?.mappingFields?.map((e, i) => {
        if (e.is_formula) {
          const tokens = tokenize(e.upload_field);
          let parser = new Parser();
          let isVariable = [];
          for (const { value, type, subtype } of tokens) {
            if (type == "operand" && subtype == "range") {
              isVariable.push(value);
              parser.setVariable(value, validatingData[value]);
            }
          }

          for (const formulaName of Object.keys(formulas)) {
            parser.setFunction(formulaName, formulas[formulaName]);
          }

          // parser.setFunction('VALUE', function (params) {
          //   let removeChr = params && params[0] ? String(params[0]).split(",").join("").replace(/[^0-9-^.]/g, "") : 0
          //   return Number(removeChr).toFixed(2)
          // });

          let { result, error } = parser.parse(e.upload_field);
          let findIndexOfFormula = state.mappingFieldList.findIndex(
            ({ _id }) => _id == e._id
          );
          state.mappingFieldList[findIndexOfFormula] = {
            ...state.mappingFieldList[findIndexOfFormula],
            first_row_data: result,
            variableUsed: [...isVariable],
          };
          validatingData[e.index] = result;
        }

        // let uniqueArr = [...new Set(combinedArr)];

        const initialMappingFieldIndex = state.mappingFieldList.findIndex(
          ({ index, upload_field, is_formula }) => {
            if (is_formula && e.is_formula && !e.index) {
              return upload_field == e.upload_field;
            } else if (e.is_formula && is_formula) {
              return index == e.index;
            } else {
              return upload_field == e.upload_field;
            }
          }
        );
        const systemFieldIndex = state.systemFields.findIndex(
          ({ id }) => e.system_field === id
        );

        let isUploadField = state.mappingFieldList.find(
          ({ index, upload_field, is_formula }) => {
            if (is_formula && e.is_formula && !e.index) {
              return upload_field == e.upload_field;
            } else if (is_formula && e.is_formula) {
              return index == e.index;
            } else {
              return upload_field == e.upload_field;
            }
          }
        );

        const selectTick = state.systemFields.findIndex(
          ({ id }) => id === e.system_field
        );

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.required
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          const requiredFieldIndex = newRequiredFields.findIndex(
            (e) => e.id == id
          );

          if (requiredFieldIndex != -1) {
            newRequiredFields[requiredFieldIndex].isMapped = true;
          }
        }

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.format &&
          isUploadField
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          newFormatFields.push({ system_column, id, isSelected: true });
        }

        if (state.systemFields[selectTick]) {
          const { module } = state.systemFields[selectTick];
          if (module === "CON") {
            state.mappingFieldList[initialMappingFieldIndex] = {
              ...state.mappingFieldList[initialMappingFieldIndex],
              module: "CON",
            };
          }
        }

        if (state.systemFields[selectTick] && isUploadField) {
          state.systemFields[selectTick].isSelected = true;
          state.mappingFieldList[initialMappingFieldIndex] = {
            ...state.mappingFieldList[initialMappingFieldIndex],
            format: e.format,
            system_field: e.system_field,
            contact_index: e.contact_index,
            ...(e.contact_index
              ? {
                  module: "CON",
                }
              : {}),
          };
        }

        // if (e.system_field == "contact_first_name") {
        //   newRequiredFields = newRequiredFields.filter(
        //     ({ id, isMapped }) => id != "customer_name" || isMapped
        //   );
        // } else if (e.system_field == "customer_name") {
        //   newRequiredFields = newRequiredFields.filter(
        //     ({ id, isMapped }) => id != "contact_first_name" || isMapped
        //   );
        // }
        updateRequiredFields(state.mappingFieldList, newRequiredFields);
      });

      let mappingState = [...state.mappingFieldList];

      if (filterFormulaData && filterFormulaData.length > 0) {
        formulaVar = mappingState.reduce(
          (acc, curr) => acc.concat(curr.variableUsed),
          []
        );
      } else {
        formulaVar = [...initialState.formulaVariables];
      }

      // for (let i = 0; i < payload?.mappingFields?.length; i++) {
      //   let yItem = payload?.mappingFields[i];

      //   let xIndex = state.mappingFieldList.findIndex(
      //     (xItem) => xItem.system_field === yItem.system_field
      //   );

      //   if (xIndex !== -1) {
      //     let invIndex = invAmount.indexOf(yItem.system_field);
      //     if (invIndex !== -1) {
      //       invAmount.splice(invIndex, 1);
      //       break;
      //     }
      //   }
      // }

      // if (invAmount && invAmount.length < 6) {
      //   newRequiredFields = newRequiredFields.filter(
      //     (item) => !invAmount.includes(item.id)
      //   );
      // }

      let defaultData =
        payload &&
        payload.defaultFields.map((e) => {
          const initialDefaultFieldIndex = state?.defaultFields?.findIndex(
            ({ id }) => id == e.id
          );
          if (
            state &&
            state.defaultFields &&
            state.defaultFields[initialDefaultFieldIndex] &&
            state.defaultFields[initialDefaultFieldIndex].default
          ) {
            state.defaultFields[initialDefaultFieldIndex].default.value =
              e.value;
          }
        });

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        systemFields: [...state.systemFields],
        requiredFields: [...newRequiredFields],
        requiredFormatFields: [...newFormatFields],
        formulaVariables: [...formulaVar],
      };

    case INITIALSTATE_STATE: {
      return {
        ...state,
        ...initialState,
        requiredFormatFields: [],
      };
    }

    case ADHOC_REFETCH: {
      const { adhocRefetchActions } = state;
      return {
        ...state,
        adhocRefetchActions: adhocRefetchActions + 1,
      };
    }

    case SET_AU_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case VALIDATION_ADHOC_FILTER: {
      // for default fields start
      const invAmount = [
        "client_charges",
        "client_intrest",
        "principal_amount",
        "collection_fee",
      ];

      let defaultFields = [];

      state.defaultFields.map((e) => {
        let findMappedFields = state.mappingFieldList.find(
          ({ system_field }) => system_field == e.id
        );

        if (
          !findMappedFields ||
          !findMappedFields.system_field ||
          e.id === "phone_code"
        ) {
          if (e.id != "currency") {
            defaultFields.push(e);
          }
        }
      });

      let removeIndex = ["invoice_total_amount", "due_amount"];
      state.mappingFieldList.map(({ system_field }) => {
        if (invAmount.includes(system_field)) {
          defaultFields = defaultFields.filter(
            (item) => !removeIndex.includes(item.id)
          );
        }
      });

      let customerCurrency = state.mappingFieldList.find(
        ({ system_field }) => system_field == "customer_currency"
      );
      if (!customerCurrency?.upload_field) {
        let deleteCustomerCurrency = state.defaultFields?.find(
          (e) => e.id == "currency"
        );
        defaultFields.push({
          ...deleteCustomerCurrency,
          is_input: true,
          default: {
            ...deleteCustomerCurrency.default,
            reference: "currency",
            value: deleteCustomerCurrency?.default?.value
              ? deleteCustomerCurrency.default.value
              : state.organizationDtl.default_currency,
          },
        });
      }
      let newDefaultFields = defaultFields.map((e) => {
        if (e.id === "currency") {
          //check if currency is mapped
          return {
            ...e,
            default: {
              ...e.default,
              value: e?.default?.value
                ? e?.default?.value
                : state?.organizationDtl?.default_currency,
            },
          };
        }

        if (e.id === "invoice_date") {
          //check if due date id mapped
          let dueDate = defaultFields.find(
            ({ id }) => id === "invoice_due_date"
          );
          if (!dueDate) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value ? e?.default?.value : "{{Due_Date}}",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value ? e?.default?.value : "{{Today}}",
                tags: ["{{Today}}"],
              },
              is_create: true,
            };
          }
        }

        if (e.id === "business_location") {
          //check if due date id mapped
          let businessLocation = defaultFields.find(
            ({ id }) => id === "business_location"
          );
          if (!businessLocation) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value ? e?.default?.value : "ALL",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value ? e?.default?.value : "ALL",
              },
              is_create: true,
            };
          }
        }

        if (e.id === "business_unit") {
          //check if due date id mapped
          let businessUnit = defaultFields.find(
            ({ id }) => id === "business_unit"
          );
          let findBusinessUnit =
            state && state.businessUnitRd.find(({ label }) => label == "ALL");
          if (!businessUnit) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value
                  ? e?.default?.value
                  : findBusinessUnit?.id,
              },
              is_create: false,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value
                  ? e?.default?.value
                  : findBusinessUnit?.id,
              },
              is_create: false,
            };
          }
        }

        if (e.id === "contact_relation") {
          //check if due date id mapped
          let businessUnit = defaultFields.find(
            ({ id }) => id === "contact_relation"
          );
          let findBusinessUnit =
            state && state.businessUnitRd.find(({ label }) => label == "ALL");
          if (!businessUnit) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value,
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value,
              },
              is_create: true,
            };
          }
        }

        if (e.id === "invoice_due_date") {
          //check if inv due date id mapped
          let invDate = defaultFields.find(({ id }) => id === "invoice_date");
          if (!invDate) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value
                  ? e?.default?.value
                  : "{{Invoice_Date}}",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value ? e.default.value : "{{Today}}",
                tags: ["{{Today}}"],
              },
              is_create: true,
            };
          }
        }

        if (e.id === "due_amount") {
          //check if inv total amount id mapped
          let dueAmount = state.mappingFieldList.find(
            ({ system_field }) => system_field === "invoice_total_amount"
          );
          if (dueAmount) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value
                  ? e.default.value
                  : "{{Invoice_Amount}}",
              },
              is_field_disabled: true,
            };
          }
        }

        if (e.id === "customer_name") {
          //check if inv total amount id mapped
          let customerName = state.mappingFieldList.find(
            ({ system_field }) => system_field === "contact_first_name"
          );
          if (customerName) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value
                  ? e.default.value
                  : "{{First_name+Last_name}}",
              },
            };
          }
        }

        if (e.id === "invoice_total_amount") {
          //check if due amount id mapped
          let invTotalAmount = state.mappingFieldList.find(
            ({ system_field }) => system_field === "due_amount"
          );
          if (invTotalAmount) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value ? e.default.value : "{{Due_Amount}}",
              },
              is_field_disabled: true,
            };
          }
        }

        if (e.id === "invoice_number") {
          //check if customer id mapped
          let customerId = state.mappingFieldList.find(
            ({ system_field }) => system_field === "customer_id"
          );
          if (customerId) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value ? e.default.value : "{{Customer_ID}}",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e?.default?.value
                  ? e.default.value
                  : "{{Invoice_Num_Auto}}",
                tags: ["{{Invoice_Num_Auto}}", "{{Invoice_Num_From_CustName}}"],
              },
              is_create: true,
            };
          }
        }

        return e;
      });

      return {
        ...state,
        validDefaultFields: [...newDefaultFields],
      };
    }

    case BUSINESS_UNIT_DATA: {
      return {
        ...state,
        businessUnitRd: [...payload],
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default adhocDataReducer;
