import React from "react";

const Auto = ({ size = 18, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.9747 4.14209H2.02529C0.895812 4.14209 0 5.05874 0 6.16734V15.8325C0 16.9638 0.918586 17.8577 2.02529 17.8577H19.9747C21.1042 17.8577 22 16.9411 22 15.8325V6.16734C22 5.03601 21.0814 4.14209 19.9747 4.14209ZM6.12163 13.071C6.12163 13.427 5.83305 13.7155 5.4771 13.7155C5.12114 13.7155 4.83257 13.427 4.83257 13.071V12.3348H3.36016V13.071C3.36016 13.427 3.07158 13.7155 2.71562 13.7155C2.35967 13.7155 2.07109 13.427 2.07109 13.071V10.1362C2.07109 9.01616 2.97773 8.11167 4.09565 8.11167C5.21305 8.11167 6.12159 9.02025 6.12159 10.137L6.12163 13.071ZM10.9542 11.8628C10.9542 12.9796 10.0456 13.8881 8.92891 13.8881C7.80832 13.8881 6.90366 12.9819 6.90366 11.8636V8.92881C6.90366 8.57286 7.19224 8.28428 7.54819 8.28428C7.90414 8.28428 8.19272 8.57286 8.19272 8.92881V11.8636C8.19272 12.2712 8.52281 12.5991 8.92822 12.5991C9.33483 12.5991 9.66509 12.2688 9.66509 11.8628V8.92881C9.66509 8.57286 9.95367 8.28428 10.3096 8.28428C10.6656 8.28428 10.9542 8.57286 10.9542 8.92881L10.9542 11.8628ZM14.797 9.57334H14.0608V13.071C14.0608 13.427 13.7722 13.7155 13.4163 13.7155C13.0603 13.7155 12.7717 13.427 12.7717 13.071V9.57334H12.0355C11.6796 9.57334 11.391 9.28476 11.391 8.92881C11.391 8.57286 11.6796 8.28428 12.0355 8.28428H14.797C15.153 8.28428 15.4416 8.57286 15.4416 8.92881C15.4416 9.28476 15.1529 9.57334 14.797 9.57334ZM19.9289 11.8628C19.9289 12.9924 19.0123 13.8881 17.9037 13.8881C16.7831 13.8881 15.8784 12.9819 15.8784 11.8636V10.1362C15.8784 9.01616 16.7851 8.11167 17.9029 8.11167C19.0278 8.11167 19.9289 9.02317 19.9289 10.137V11.8628ZM17.9039 9.40099C17.498 9.40099 17.1684 9.72944 17.1684 10.1365V11.8638C17.1684 12.2714 17.4984 12.5993 17.9039 12.5993C18.3153 12.5993 18.6408 12.2661 18.6408 11.8631V10.1372C18.6408 9.72918 18.3095 9.40099 17.9039 9.40099ZM3.36111 10.1365C3.36111 9.73073 3.68909 9.40099 4.09729 9.40099C4.50322 9.40099 4.83352 9.73129 4.83352 10.1372V11.046H3.36111V10.1365Z"
        fill={color}
      />
    </svg>
  );
};

export default Auto;
