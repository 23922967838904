import React from "react";

const Weblink = () => {
  return (
    <>
      {/* <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 20010904//EN"
 "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd"> */}
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="18"
        viewBox="2 0 531 504"
        // width="19px"
        // height="20px"
        // viewBox="0 0 802 502"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#516BEB"
          stroke="none"
        >
          <path
            d="M2944 5104 c-49 -17 -106 -71 -130 -123 -10 -21 -17 -59 -17 -91 0
-98 48 -174 133 -214 44 -21 58 -21 718 -26 l672 -5 -1206 -1205 c-722 -722
-1214 -1221 -1227 -1245 -42 -79 -25 -187 42 -258 66 -72 184 -94 266 -50 24
13 523 505 1245 1227 l1205 1206 5 -672 c5 -621 6 -676 23 -713 39 -87 120
-138 217 -138 65 0 114 21 164 70 70 70 67 19 64 1109 l-3 981 -30 49 c-19 30
-49 60 -79 79 l-49 30 -986 2 c-846 1 -992 0 -1027 -13z"
          />
          <path
            d="M158 4175 c-55 -20 -97 -55 -126 -105 l-27 -45 0 -1931 0 -1931 30
-49 c19 -30 49 -60 79 -79 l49 -30 1931 0 1931 0 46 27 c51 30 92 84 109 143
8 29 10 371 8 1245 l-3 1205 -22 40 c-27 51 -57 79 -113 104 -136 62 -292 -21
-320 -170 -6 -32 -10 -452 -10 -1090 l0 -1039 -1625 0 -1625 0 0 1625 0 1625
1039 0 c638 0 1058 4 1090 10 149 28 232 184 170 320 -25 56 -53 86 -104 113
l-40 22 -1215 2 c-1006 1 -1221 -1 -1252 -12z"
          />
        </g>
      </svg>
    </>
  );
};

export default Weblink;
