import React from "react";

const Plus = ({ fill = "#FFFFFF", size = 22 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      // fill="#666666"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="11.4922"
        y1="5"
        x2="11.4922"
        y2="16.5111"
        // stroke="#333860"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="16.8184"
        y1="10.79"
        x2="6.00012"
        y2="10.79"
        // stroke="#333860"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Plus;
