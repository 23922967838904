import React, { useRef, useContext, useMemo, useState, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import ReactQuill from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import moment from "moment";

import {
  ACT_CALL_BACK_LATER,
  ACT_PROMISE_TO_PAY,
  ACT_UNSUCCESSFUL,
  CUSTOMER_OVERVIEW_,
  ACT_RECORD_DISPUTE,
  FIELD_VISIT,
} from "../constants";
import CheckBoxBaseweb from "../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { SelectBox } from "../components/SelectBox";
import { TextArea } from "../components/TextArea";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import { getCommunicationTemplateList } from "../services";
import queryClient from "../providers/queryClient";
import { useParams } from "react-router-dom";
import _ from "lodash";
import setSelectValues from "../utils/setSelectValues";

import { Icon, Typography } from "./../components_v2";
import useFormat from "../hooks/useFormat";

const config = {
  call_PROMISE_TO_PAY: { path: "promise_to_pay", tags: ["PROMISE"] },
  call_CALL_BACK_LATER: { path: "call_back_later", tags: ["CALL_BACK"] },
  call_PAYMENT_PLAN: {
    path: "user_captured_payment_plan",
    tags: ["PAYMENT_PLAN"],
  },
  call_ADPP: {
    path: "user_captured_payment_plan",
    tags: ["PAYMENT_PLAN"],
  },
  call_FIELD_VISIT: {
    path: "call_field_visit",
    tags: [
      "FIELD_VISIT",
      // "NEXT_ACTION_FIELD_VISIT",
      // "VISIT_UNSUCESSFULL",
      // "RETURN_VISIT",
    ],
  },
  call_UNSUCCESSFUL: {
    path: "call_unsuccessful",
    tags: ["CONTACT_UNSUCCESSFUL"],
  },
  ACTION_CAPTURE_PROMISE_TO_PAY: { path: "promise_to_pay", tags: ["PROMISE"] },
  ACTION_CAPTURE_CALL_BACK_LATER: {
    path: "call_back_later",
    tags: ["CALL_BACK"],
  },
  ACTION_CAPTURE_PAYMENT_PLAN: {
    path: "user_captured_payment_plan",
    tags: ["PAYMENT_PLAN"],
  },
  ACTION_CAPTURE_ADPP: {
    path: "user_captured_payment_plan",
    tags: ["PAYMENT_PLAN"],
  },
  ACTION_CAPTURE_FIELD_VISIT: {
    path: "call_field_visit",
    tags: [
      "FIELD_VISIT",
      // "NEXT_ACTION_FIELD_VISIT",
      // "VISIT_UNSUCESSFULL",
      // "RETURN_VISIT",
    ],
  },
  ACTION_CAPTURE_UNSUCCESSFUL: {
    path: "call_unsuccessful",
    tags: ["CONTACT_UNSUCCESSFUL"],
  },
  FIELD_VISIT_PROMISE_TO_PAY: {
    path: "field_visit_promise_to_pay",
    tags: ["PROMISE"],
  },
  FIELD_VISIT_CALL_BACK_LATER: {
    path: "field_visit_call_back_later",
    tags: ["CALL_BACK"],
  },
  FIELD_VISIT_PAYMENT_PLAN: {
    path: "field_visit_payment_plan",
    tags: ["PAYMENT_PLAN"],
  },
  FIELD_VISIT_FIELD_VISIT: {
    path: "return_field_visit",
    tags: [
      "FIELD_VISIT",
      "NEXT_ACTION_FIELD_VISIT",
      "VISIT_UNSUCESSFULL",
      "RETURN_VISIT",
    ],
  },
  RETURN_FIELD_VISIT: {
    path: "return_field_visit",
    tags: [
      "FIELD_VISIT",
      // "NEXT_ACTION_FIELD_VISIT",
      // "VISIT_UNSUCESSFULL",
      "RETURN_VISIT",
    ],
  },
  FIELD_VISIT_UNSUCCESSFULL: {
    path: "field_visit_unsuccessfull",
    tags: [
      "FIELD_VISIT",
      "NEXT_ACTION_FIELD_VISIT",
      "VISIT_UNSUCESSFULL",
      "RETURN_VISIT",
    ],
  },
  NEXT_ACTION_FIELD_VISIT: {
    path: "next_action_field_visit",
    tags: ["FIELD_VISIT", "VISIT_UNSUCESSFULL", "RETURN_VISIT"],
  },
};

const ActionReminders = ({
  is_hold = true,
  is_confirmation = true,
  is_followup = true,
  is_calendar = true,
  is_consent_enabled = true,
  is_incrementor = true,
  is_comment = true,
  is_first_installment = false,
  watch,
  control,
  errors,
  setValue,
  contacts = [],
  recipient_id,
  actionType = "",
  outcome = "",
  consentDate,
  callValidationDuration,
  timeZone,
  is_inbound_call,
  is_valid_from_contact = false,
}) => {
  const editorRef = useRef(null);
  const intl = useIntl();
  const format = useFormat();
  const { customerId } = useParams();
  const [state, setState] = useState({
    followUpType: null,
    confirmationType: null,
  });
  const {
    referenceData,
    currentDefaultFormatDetails,
    currentOrganization,
    users,
    userInfo,
  } = useContext(MaxyfiContext);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const pathConfig = _.get(config, `${actionType}_${outcome}`, { path: null });

  const ConfiglevelIsHoldWorkFlow = _.get(
    currentDefaultFormatDetails,
    `${pathConfig.path}.is_hold_workflow`,
    false
  );
  let [
    is_send_confirmation_message,
    is_send_action_follow_up,
    is_enable_calendar_alert,
    confirmation_message_type,
    action_follow_up_type,
  ] = useWatch({
    control,
    name: [
      "is_send_confirmation_message",
      "is_send_action_follow_up",
      "is_enable_calendar_alert",
      "confirmation_message_type",
      "action_follow_up_type",
    ],
  });

  useEffect(() => {
    if (
      currentDefaultFormatDetails &&
      actionType &&
      outcome &&
      _.get(currentDefaultFormatDetails, `${pathConfig.path}.messages`, [])
    ) {
      let EmailUser = _.get(customerData, "data.doc.contacts", []).some(
        (e) => e.is_valid_email === true
      );
      let PhoneUser = _.get(customerData, "data.doc.contacts", []).some(
        (e) => e.is_valid_phone === true
      );

      _.get(currentDefaultFormatDetails, `${pathConfig.path}.messages`, []).map(
        (e) => {
          if (
            (e.type === "email" && EmailUser) ||
            (e.type === "sms" && PhoneUser) ||
            (e.type === "whatsapp" && PhoneUser)
          ) {
            if (e.confirmation_template.length > 0) {
              setValue("is_send_confirmation_message", true);
              setValue("confirmation_message_type", setSelectValues(e.type));
              setValue(
                "confirmation_message_template_id",
                setSelectValues(e.confirmation_template)
              );
            }
            if (e.follow_up_template.length > 0) {
              setValue("is_send_action_follow_up", true);
              setValue("action_follow_up_type", setSelectValues(e.type));
              setValue(
                "action_follow_up_template_id",
                setSelectValues(e.follow_up_template)
              );
            }
          }
        }
      );
    }

    if (
      (outcome === ACT_PROMISE_TO_PAY ||
        outcome === ACT_CALL_BACK_LATER ||
        outcome === ACT_UNSUCCESSFUL) &&
      currentDefaultFormatDetails[config[`${actionType}_${outcome}`].path]
    ) {
      const defaultValue =
        currentDefaultFormatDetails[config[`${actionType}_${outcome}`].path];

      let validation = {
        sms: {
          has_valid_contact: false,
        },
        email: {
          has_valid_contact: false,
        },
        whatsapp: {
          has_valid_contact: false,
        },
      };

      if (recipient_id && Array.isArray(contacts) && contacts.length > 0) {
        const { is_valid_email, is_valid_sms, is_valid_whatsapp } =
          contacts && contacts[0];
        validation.email.has_valid_contact = is_valid_email;
        validation.sms.has_valid_contact = is_valid_sms;
        validation.whatsapp.has_valid_contact = is_valid_whatsapp;
        // }
      }

      let fieldKey = Object.keys(defaultValue);

      fieldKey.map((field) => {
        if (
          field == "action_follow_up_template_id" ||
          field == "confirmation_message_template_id" ||
          field == "action_follow_up_type" ||
          field == "confirmation_message_type"
        ) {
          const value = _.get(defaultValue, field);
          if (
            validation &&
            validation[value] &&
            validation[value].has_valid_contact
          ) {
            setValue(field, [{ id: value }]);
          } else {
            setValue(field, [{ id: value }]);
          }
        } else {
          setValue(field, _.get(defaultValue, field, false));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (is_inbound_call === true) {
      setValue("is_increment_follow_up", false);
    } else if (is_inbound_call === false && outcome !== ACT_UNSUCCESSFUL) {
      setValue("is_increment_follow_up", true);
    }
  }, [is_inbound_call]);

  const { path, tags } = config[`${actionType}_${outcome}`]
    ? config[`${actionType}_${outcome}`]
    : { tags: [] };

  const messageTypes = useMemo(() => {
    let types =
      referenceData && referenceData["template_type"]
        ? referenceData["template_type"]
        : [];
    let validation = {
      sms: {
        has_valid_contact: false,
        is_configured: currentDefaultFormatDetails.is_sms_configured,
      },
      email: {
        has_valid_contact: false,
      },
      whatsapp: {
        has_valid_contact: false,
        is_configured: currentDefaultFormatDetails.is_whatsapp_configured,
      },
    };

    let emailUser = _.get(customerData, "data.doc.contacts", []).some(
      (e) => e.is_valid_email === true
    );

    let phoneUser = _.get(customerData, "data.doc.contacts", []).some(
      (e) => e.is_valid_phone === true
    );

    let actionMessageTypes = [];

    if (is_valid_from_contact) {
      validation.email.has_valid_contact = emailUser;
      validation.sms.has_valid_contact = phoneUser;
      validation.whatsapp.has_valid_contact = phoneUser;
      actionMessageTypes = types.filter(
        ({ id }) =>
          validation?.[id] &&
          !Object.values(validation[id]).includes(false) &&
          id !== "letter"
      );
    } else {
      if (recipient_id && Array.isArray(contacts) && contacts.length > 0) {
        const { is_valid_email, is_valid_sms, is_valid_whatsapp } =
          contacts && contacts[0];
        validation.email.has_valid_contact = is_valid_email;
        validation.sms.has_valid_contact = is_valid_sms;
        validation.whatsapp.has_valid_contact = is_valid_whatsapp;
        // }
      }

      actionMessageTypes = types.filter(
        ({ id }) =>
          validation?.[id] &&
          !Object.values(validation[id]).includes(false) &&
          id !== "letter"
      );
    }

    return actionMessageTypes;
  }, [recipient_id]);

  // const messageTypes = useMemo(() => {
  //   let types =
  //     referenceData && referenceData["template_type"]
  //       ? referenceData["template_type"]
  //       : [];
  //   let validation = {
  //     sms: {
  //       has_valid_contact: false,
  //       is_configured: currentDefaultFormatDetails.is_sms_configured,
  //     },
  //     email: {
  //       has_valid_contact: false,
  //     },
  //     whatsapp: {
  //       has_valid_contact: false,
  //       is_configured: currentDefaultFormatDetails.is_whatsapp_configured,
  //     },
  //   };

  //   if (recipient_id && Array.isArray(contacts) && contacts.length > 0) {
  //     const { is_valid_email, is_valid_sms, is_valid_whatsapp } =
  //       contacts && contacts[0];
  //     validation.email.has_valid_contact = is_valid_email;
  //     validation.sms.has_valid_contact = is_valid_sms;
  //     validation.whatsapp.has_valid_contact = is_valid_whatsapp;
  //     // }
  //   }

  //   return types.filter(
  //     ({ id }) =>
  //       validation?.[id] &&
  //       !Object.values(validation[id]).includes(false) &&
  //       id !== "letter"
  //   );
  // }, [recipient_id]);

  const { data } = useQuery(
    [`DEFAULT_TEMPLATES_${actionType}_${outcome}`],
    async () => {
      return await getCommunicationTemplateList({
        filters: { tags },
        organization: currentOrganization,
        clientCode: [currentOrganization],
      });
    },
    {
      staleTime: Infinity,
      disabled: tags.length === 0,
    }
  );

  const { confirmationTemplates, followupTemplates } = useMemo(() => {
    let confirmationTemplates = [];
    let followupTemplates = [];

    if (data && data.data && data.data.docs && Array.isArray(data.data.docs)) {
      const toLabel = (e) => ({
        id: e._id,
        label: `${e.name} (${
          e.language ? e.language.toLocaleUpperCase() : "en".toLocaleUpperCase()
        })`,
        disabled: e.disable,
      });

      confirmationTemplates = confirmation_message_type?.[0]?.id
        ? data.data.docs
            .filter((e) => e.type === confirmation_message_type?.[0]?.id)
            .map(toLabel)
        : [];
      followupTemplates = action_follow_up_type?.[0]?.id
        ? data.data.docs
            .filter((e) => e.type === action_follow_up_type?.[0]?.id)
            .map(toLabel)
        : [];

      return { confirmationTemplates, followupTemplates };
    }

    return { confirmationTemplates, followupTemplates };
  }, [
    confirmation_message_type?.[0]?.id,
    action_follow_up_type?.[0]?.id,
    data,
  ]);

  const BlockEmbed = ReactQuill.Quill.import("blots/embed");
  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  //TODO: SET DEFAULT VALUES

  const { enableConsent, inDays } = useMemo(() => {
    let result = { enableConsent: false, inDays: 0 };

    if (consentDate && consentDate?.getFullYear && callValidationDuration > 0) {
      const dateString = `${consentDate.getFullYear()}-${
        consentDate.getMonth() + 1
      }-${consentDate.getDate()}`;

      const dayDiff = moment
        .tz(dateString, "YYYY-MM-DD", timeZone)
        .startOf("day")
        .diff(moment.tz(timeZone).startOf("day"), "days");

      return {
        enableConsent: dayDiff < callValidationDuration,
        inDays: dayDiff + 1,
      };
    }

    return result;
  }, [consentDate, callValidationDuration]);

  consentDate = moment
    .utc(consentDate)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  useEffect(() => {
    setValue(
      "is_hold_workflow",
      ConfiglevelIsHoldWorkFlow &&
        _.get(customerData, "data.doc.is_hold", false)
        ? false
        : _.get(customerData, "data.doc.is_hold", false)
        ? false
        : ConfiglevelIsHoldWorkFlow
        ? ConfiglevelIsHoldWorkFlow
        : false
    );
  }, [
    ConfiglevelIsHoldWorkFlow,
    _.get(customerData, "data.doc.is_hold", false),
  ]);

  return (
    <>
      {is_consent_enabled && enableConsent ? (
        <div
          style={{
            padding: "5px",
            marginBottom: "5px",
            borderRadius: "5px",
            backgroundColor: "#FFF0F0",
          }}
        >
          <Controller
            name="is_consent"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
                error
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon icon={"alert"} color={"red"} size={"18"} />
                  <FormattedMessage
                    id={
                      outcome === ACT_PROMISE_TO_PAY
                        ? "received_consent"
                        : outcome === ACT_CALL_BACK_LATER
                        ? "call_back_consent"
                        : outcome === ACT_RECORD_DISPUTE
                        ? "dispute_consent"
                        : "received_consent"
                    }
                    values={{
                      reminderOn: consentDate,
                      withIn: callValidationDuration,
                    }}
                  />
                </div>
              </CheckBoxBaseweb>
            )}
          />
        </div>
      ) : (
        <></>
      )}

      {is_first_installment ? (
        <div style={{}}>
          <Controller
            defaultValues={false}
            name="is_debit_first_instalment"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                <FormattedMessage id={`debit_first_installment_now`}>
                  hold_workflow_till_promise_date
                </FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
      ) : (
        <></>
      )}
      {_.get(customerData, "data.doc.is_hold", false) && is_hold ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            paddingBottom: "12px",
          }}
        >
          <Controller
            defaultValues={false}
            name="is_hold_workflow"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                <FormattedMessage id={`remove_hold_till_${outcome}`}>
                  {/* Remove Current hold & Apply new hold till {outcome} */}
                </FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
          <div
            style={{
              display: "flex",
              backgroundColor: "#FFF4F3",
              padding: "5px",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Icon icon="terms_outline" color="#FD372A" size={15} />
            <Typography type="p" className="text-error">
              On Hold{" "}
              {_.get(customerData, "data.doc.hold_till", null) ? "till" : ""}{" "}
              {_.get(customerData, "data.doc.hold_till", null)
                ? format.date({
                    value: _.get(customerData, "data.doc.hold_till", null),
                  })
                : ""}{" "}
              for reason {_.get(customerData, "data.doc.hold_reason", "")}
            </Typography>
          </div>
        </div>
      ) : (
        <>
          {is_hold ? (
            <div style={{}}>
              <Controller
                defaultValues={false}
                name="is_hold_workflow"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    checked={field.value}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <FormattedMessage id={`hold_workflow_till_${outcome}`}>
                      hold_workflow_till_promise_date
                    </FormattedMessage>
                  </CheckBoxBaseweb>
                )}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {is_calendar ? (
        <div className="cp-action-message-confirmation-calendar">
          <div className="cp-message-confirmation-checkbox">
            <Controller
              defaultValues={false}
              name="is_enable_calendar_alert"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="enable_calendar_alert">
                    Enable Calendar Alert
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
          </div>

          {is_enable_calendar_alert ? (
            <>
              <div
                className="cp-message-confirmation-type"
                style={{ width: "120px" }}
              >
                <Controller
                  defaultValues={[]}
                  name="calendar_alert_time"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      clearable={false}
                      name={field.name}
                      size="mini"
                      fullWidth
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "time",
                      })}
                      placeholder={intl.formatMessage({
                        id: "time",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["time"]}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <></>
      )}

      {is_confirmation ? (
        <div className="cp-action-message-confirmation">
          <div className="cp-message-confirmation-checkbox">
            <Controller
              defaultValues={false}
              name="is_send_confirmation_message"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="send_confirmation_message">
                    send_confirmation_message
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
          </div>

          {is_send_confirmation_message ? (
            <div className="cp-action-message-confirmation">
              <>
                <div className="cp-message-confirmation-type">
                  <Controller
                    defaultValues={[]}
                    name="confirmation_message_type"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size="mini"
                        fullWidth
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "comm_type",
                        })}
                        placeholder={intl.formatMessage({
                          id: "comm_type",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                            setValue("confirmation_message_template_id", []);
                          }

                          if (e && e.value && e.value[0] && e.value[0].id) {
                            setState({
                              ...state,
                              confirmationType: e.value[0].id,
                            });
                          }

                          field.onChange(e.value);
                        }}
                        options={messageTypes}
                      />
                    )}
                  />
                </div>
                <div style={{ paddingRight: "20px" }}>
                  <div className="cp-action-promise-pay-con">
                    <Controller
                      name="confirmation_message_template_id"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          size="mini"
                          fullWidth
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "confirmation_message",
                          })}
                          placeholder={intl.formatMessage({
                            id: "confirmation_message",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={confirmationTemplates}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {is_followup ? (
        <div className="cp-action-message-confirmation">
          <div className="cp-message-confirmation-checkbox">
            <Controller
              defaultValues={false}
              name="is_send_action_follow_up"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                  // disabled={!EmailUser === true && !PhoneUser === true}
                >
                  <FormattedMessage id="send_follow_up_message">
                    send_follow_up_message
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
          </div>

          {is_send_action_follow_up ? (
            <div className="cp-action-message-confirmation">
              <>
                <div className="cp-message-confirmation-type">
                  <Controller
                    name="action_follow_up_type"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        fullWidth
                        size="mini"
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "comm_type",
                        })}
                        placeholder={intl.formatMessage({
                          id: "comm_type",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                            setValue("action_follow_up_template_id", []);
                          }

                          if (e && e.value && e.value[0] && e.value[0].id) {
                            setState({ ...state, followUpType: e.value[0].id });
                          }

                          field.onChange(e.value);
                        }}
                        options={messageTypes}
                      />
                    )}
                  />
                </div>
                <div style={{ paddingRight: "20px" }}>
                  <div className="cp-action-promise-pay-con">
                    <Controller
                      defaultValues={[]}
                      name="action_follow_up_template_id"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          fullWidth
                          size="mini"
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "follow_up_message",
                          })}
                          placeholder={intl.formatMessage({
                            id: "follow_up_message",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={followupTemplates}
                        />
                      )}
                    />
                  </div>
                </div>
              </>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {is_comment ? (
        <div className="rq-quill" style={{ marginBottom: "10px" }}>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <ReactQuill
                {...field}
                name={field.name}
                id={"user-comment-editor"}
                ref={editorRef}
                theme="snow"
                placeholder="User Comments"
                value={field.value}
                modules={modules}
                style={{ height: "80px" }}
              />
            )}
          />
        </div>
      ) : (
        <></>
      )}

      {is_incrementor ? (
        <div className="cp-action-message-confirmation">
          <div className="cp-message-confirmation-checkbox">
            <Controller
              name="is_increment_follow_up"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checkmarkType="toggle_round"
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="increment_count">
                    increment_count
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActionReminders;
