import React from "react";

const DueSvg = () => {
  return (
    <svg
      width="38"
      height="36"
      viewBox="0 0 38 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8257 0.616211C10.7017 0.616211 3.30469 8.15854 3.30469 17.4617C3.31319 17.8622 3.47517 18.2433 3.75593 18.5234C4.03668 18.8036 4.41387 18.9604 4.80669 18.9604C5.19951 18.9604 5.5767 18.8036 5.85746 18.5234C6.13821 18.2433 6.30019 17.8622 6.3087 17.4617C6.34671 9.85264 12.4294 3.71081 19.8961 3.75101C27.3628 3.79121 33.3821 9.9919 33.3427 17.6053C33.3033 25.2187 27.2213 31.3533 19.7553 31.316C19.3226 31.316 18.8903 31.2921 18.4585 31.2442C18.0619 31.2063 17.6668 31.3304 17.36 31.5894C17.0532 31.8484 16.8598 32.221 16.8224 32.6254C16.8224 32.6297 16.8224 32.634 16.8224 32.6376C16.7852 33.0419 16.9069 33.4448 17.1609 33.7576C17.4149 34.0704 17.7804 34.2676 18.1769 34.3058H18.1896C18.7302 34.3058 19.2709 34.3819 19.8264 34.3819C28.9715 34.3819 36.3847 26.8231 36.3847 17.4983C36.3847 8.17362 28.9708 0.616211 19.8257 0.616211Z"
        fill="#516BEB"
      />
      <path
        d="M19.8254 6.74219C19.4271 6.74219 19.0452 6.9035 18.7636 7.19065C18.4819 7.4778 18.3237 7.86725 18.3237 8.27334V17.4617C18.3237 17.8678 18.4819 18.2572 18.7636 18.5444C19.0452 18.8315 19.4271 18.9928 19.8254 18.9928H25.8327C26.231 18.9928 26.6129 18.8315 26.8945 18.5444C27.1761 18.2572 27.3344 17.8678 27.3344 17.4617C27.3344 17.0556 27.1761 16.6661 26.8945 16.379C26.6129 16.0918 26.231 15.9305 25.8327 15.9305H21.327V8.27334C21.327 7.86725 21.1688 7.4778 20.8872 7.19065C20.6056 6.9035 20.2236 6.74219 19.8254 6.74219Z"
        fill="#516BEB"
      />
      <path
        d="M9.84988 27.6045C9.65271 27.6045 9.46361 27.6844 9.32419 27.8265C9.18477 27.9687 9.10645 28.1615 9.10645 28.3625C9.10645 28.5636 9.18477 28.7564 9.32419 28.8985C9.46361 29.0407 9.65271 29.1206 9.84988 29.1206C10.2399 29.1206 10.5933 28.7746 10.5757 28.3805C10.5805 28.2803 10.5652 28.1802 10.5309 28.0862C10.4966 27.9922 10.4439 27.9064 10.376 27.8338C10.3081 27.7612 10.2265 27.7035 10.136 27.6641C10.0456 27.6246 9.94823 27.6044 9.84988 27.6045Z"
        fill="#516BEB"
      />
      <path
        d="M14.4943 32.05C14.9439 32.0518 15.3854 31.9227 15.7737 31.6765C16.1619 31.4304 16.4827 31.0761 16.7036 30.6506C16.9295 30.2314 17.049 29.7543 17.05 29.2683C17.051 28.7822 16.9334 28.3045 16.7091 27.8842C16.709 27.8842 16.709 27.8841 16.709 27.8841L12.2029 19.3465C11.982 18.9234 11.6625 18.571 11.2763 18.3256C10.89 18.0801 10.4509 17.9504 10.0035 17.95C9.55607 17.9496 9.11679 18.0786 8.73018 18.3234C8.34363 18.5682 8.02362 18.92 7.80212 19.3427L3.28768 27.8902L3.28763 27.8903C3.06589 28.3121 2.94954 28.7899 2.95 29.2758C2.95046 29.7618 3.0677 30.2394 3.29025 30.6606C3.5128 31.0819 3.83299 31.4323 4.21916 31.6764C4.60538 31.9205 5.04386 32.0495 5.49062 32.05H5.49068H14.4943ZM14.4943 32.05L14.4945 32V32.05H14.4943ZM14.492 30.7128H14.4918L5.48795 30.7128L5.48752 30.7128C5.25933 30.7148 5.03446 30.6504 4.83591 30.5258C4.63731 30.4012 4.47202 30.2208 4.35743 30.0025L4.35729 30.0022C4.23983 29.7818 4.1778 29.5309 4.1778 29.2752C4.1778 29.0195 4.23983 28.7687 4.35729 28.5482L4.35737 28.5481L8.86848 20.0034L8.8686 20.0032C8.98322 19.7835 9.14864 19.6015 9.34762 19.4751C9.54655 19.3487 9.77211 19.2824 10.0014 19.2824C10.2308 19.2824 10.4563 19.3487 10.6553 19.4751C10.8543 19.6015 11.0197 19.7835 11.1343 20.0032L11.1344 20.0034L15.6448 28.5481L15.6448 28.5481C15.7604 28.7667 15.8207 29.0153 15.8193 29.2683C15.8179 29.5214 15.7548 29.7692 15.6369 29.9862L15.6809 30.0101L15.6365 29.987C15.5209 30.2089 15.3538 30.3928 15.1527 30.5201C14.9516 30.6474 14.7236 30.7137 14.492 30.7128Z"
        fill="#516BEB"
        stroke="#516BEB"
        stroke-width="0.1"
      />
      <path
        d="M9.66508 22.283C9.31307 22.3863 9.09131 22.7137 9.09131 23.1106C9.10891 23.3504 9.12369 23.593 9.142 23.8328C9.19269 24.7451 9.24267 25.6396 9.29336 26.5527C9.29905 26.6981 9.3605 26.8355 9.46451 26.9353C9.56852 27.035 9.70678 27.0891 9.84953 27.086C9.99444 27.0863 10.1337 27.0287 10.2376 26.9257C10.3415 26.8227 10.4018 26.6824 10.4057 26.5347C10.4057 26.3466 10.4057 26.1758 10.4233 25.9827C10.4564 25.3969 10.4937 24.8119 10.5247 24.2269C10.5423 23.8478 10.5754 23.4688 10.5951 23.0898C10.5978 22.9598 10.5738 22.8308 10.5247 22.7108C10.4533 22.548 10.3282 22.416 10.171 22.3378C10.0139 22.2596 9.83482 22.2402 9.66508 22.283Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default DueSvg;
