function Clock() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle style={{ fill: "#516BEB" }} cx="10" cy="10" r="10" />
        <path
          style={{ fill: "#FFFFFF" }}
          // class="st1"
          d="M13.6,13.8l-4.1-2.5C9.2,11.1,9,10.8,9,10.4V5.8C9,5.3,9.3,5,9.8,5s0.8,0.3,0.8,0.8v4.4l3.8,2.3
		c0.4,0.2,0.5,0.7,0.3,1.1C14.4,13.9,13.9,14,13.6,13.8z"
        />
      </svg>
    </>
  );
}

export default Clock;
