import React from "react";

const Cancel = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7116 0.315463C12.3271 -0.0912493 11.7059 -0.0912493 11.3214 0.315463L6.5 5.40458L1.67861 0.305034C1.29408 -0.101678 0.672924 -0.101678 0.288396 0.305034C-0.096132 0.711746 -0.096132 1.36874 0.288396 1.77546L5.10978 6.875L0.288396 11.9745C-0.096132 12.3813 -0.096132 13.0383 0.288396 13.445C0.672924 13.8517 1.29408 13.8517 1.67861 13.445L6.5 8.34542L11.3214 13.445C11.7059 13.8517 12.3271 13.8517 12.7116 13.445C13.0961 13.0383 13.0961 12.3813 12.7116 11.9745L7.89022 6.875L12.7116 1.77546C13.0863 1.37917 13.0863 0.711747 12.7116 0.315463Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default Cancel;
