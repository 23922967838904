import React, { useState, useEffect, useContext, useMemo } from "react";
import moment from "moment";
import { Icon, Typography } from "../../../components_v2";
import { useMutation } from "react-query";
import { addLegalTimer } from "../../../services";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import queryClient from "../../../providers/queryClient";
import { LEGAL_ACTION_INFO_ } from "../../../constants";
import _ from "lodash";
const TimerComponent = ({ initialStartTime }) => {
  const [isRunning, setIsRunning] = useState(initialStartTime ? true : false);
  const [startTime, setStartTime] = useState(initialStartTime);
  const [elapsedTime, setElapsedTime] = useState(0);
  const { customerId } = useParams();
  let {
    currentOrganization,
    currentDefaultFormatDetails,
    referenceData,
    refetch,
  } = useContext(MaxyfiContext);
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        const now = moment();
        const elapsed = now.diff(moment(startTime));
        setElapsedTime(elapsed);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning, startTime]);

  const submitTimer = useMutation(
    (data) =>
      addLegalTimer({
        ...data,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
        // setOpen(false);
        // refetch({
        //   ...maxyfiContextValue,
        //   setLoader: false,
        //   currentOrganization: currentOrganization,
        // });
      },
    }
  );
  const handleStartStopClick = async () => {
    if (isRunning) {
      setIsRunning(false);
      await submitTimer.mutateAsync({});
    } else {
      setIsRunning(true);
      setStartTime(moment());
      await submitTimer.mutateAsync({});
    }
  };

  const formatTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");
    const seconds = duration.seconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {isRunning && (
        <div style={{ display: "flex", gap: "5px" }}>
          <Icon icon="clock" size={18} />
          {formatTime(elapsedTime)}
        </div>
      )}

      <div className="timer-button" onClick={handleStartStopClick}>
        {isRunning ? (
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: "red",
              borderRadius: "3px",
              textAlign: "center",
            }}
          ></div>
        ) : (
          <Icon icon="play_svg" />
        )}
        {isRunning ? (
          <Typography
            type="p"
            className="regular"
            style={{ paddingRight: "20px" }}
          >
            {" "}
            Stop Timer
          </Typography>
        ) : (
          <Typography
            type="p"
            className="regular"
            style={{ paddingRight: "20px" }}
          >
            {" "}
            Start Timer
          </Typography>
        )}
      </div>
    </div>
  );
};

export default TimerComponent;
