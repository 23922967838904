// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const validateSMTPDetails = async ({
  organization = null,
  ...values
}) => {
  return await axiosWithAuth.post(
    `${HOST}communication/${organization}/check-smtp`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
