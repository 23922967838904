import React, { useContext, useState } from "react";
import { StatefulPopover } from "baseui/popover";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import Plus from "../../assets/img/svg/Upload/Plus";
import Cancel from "../../assets/img/svg/Template/Cancel";
import { LabelLarge, ParagraphMedium, LabelSmall } from "baseui/typography";
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import Loader from "../../components/Loader";
import { DOC_QUICK_VIEW_LIST, EDIT, NEW } from "../../constants";
import {
  deleteDocumentCard,
  getDocumentData,
} from "../../services/documentSystem";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  addInvoiceDocumentForm,
  invoiceDrawerClose,
} from "../../redux/invoiceOverview/DMSystemINV/action";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { ADD_DOCUMENTS } from "../../providers/RBACProvider/permissionList";
import {
  addDocumentForm,
  documentDrawerClose,
} from "../../redux/customerOverview/DMSystem/action";
import AddDMSystem from "../../containers/CustomerOverView/AddDMSystem";
import queryClient from "../../providers/queryClient";

let initialState = { isType: "ADD", documentData: {} };

const DMSystem_V2 = ({
  resourceId,
  entity,
  createDocument,
  drawerOpen,
  documentDrawertype,
  closeDocmentDrawer,
  documentDrive,
}) => {
  const [isDocument, setIsDocument] = useState({ ...initialState });

  let dispatch = useDispatch();
  let { drawerState, drawerType, refetchDocument } = useSelector(
    (doc) => doc.customerDocumentReducer
  );
  let { drawerInvoiceState, drawerInvoiceType, refetchInvoiceDocumentData } =
    useSelector((s) => s.invoiceDocumentReducer);

  let { invoiceAddingDocumentReducer } = useSelector(
    (s) => s.invoiceAddingDocumentReducer
  );

  // want to change
  // let type = entity === "invoices" ? drawerInvoiceType : drawerType;
  let type = documentDrawertype;

  let refetchingData =
    entity === "invoices" ? refetchInvoiceDocumentData : refetchDocument;
  // const [isSelect, setIsSelect] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [unSelectedData, setUnSelectedData] = useState([]);
  const [sortName, setSort] = useState("");

  const { currentOrganization, referenceData, defaultData } =
    useContext(MaxyfiContext);

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `document-management-system-${resourceId}-${refetchingData}`,
      {
        sort: sortName,
      },
    ],
    async ({ queryKey }) => {
      let { sort } = queryKey[1];

      if (resourceId) {
        return await getDocumentData({
          organization: currentOrganization,
          customerId: resourceId,
          sort,
        });
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const deleteDocumentData = useMutation(
    (id) => deleteDocumentCard({ ...id, organization: currentOrganization }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
        queryClient.invalidateQueries(`${DOC_QUICK_VIEW_LIST}-${resourceId}`);
      },
    }
  );

  const onFileSelectChange = (file) => {
    let newSelectedFiles = selectedData;

    let isSelected = newSelectedFiles.find((fl) => fl.fileRef === file.fileRef);

    if (isSelected) {
      newSelectedFiles = newSelectedFiles.filter(
        (fl) => fl.fileRef !== file.fileRef
      );
    } else {
      newSelectedFiles.push(file);
    }
    setSelectedData(newSelectedFiles);
  };

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            width: "450px",
          }}
        >
          {/* ONE STARTS*/}
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "6px" }}>
              <LabelLarge>
                Documents{" "}
                <span
                  style={{
                    backgroundColor: "#e2e7fb",
                    color: "#516beb",
                    padding: "3px 7px",
                    borderRadius: "5px",
                  }}
                >
                  {data && data.data && data.data.docs && data.data.docs.length}
                </span>
              </LabelLarge>
              <div>
                <StatefulPopover
                  content={() => (
                    <div className="cp_email_attachement">
                      {referenceData &&
                        referenceData["DOCUMENT_SORTINGS"].map((e) => (
                          <ParagraphMedium
                            onClick={() => {
                              setSort(e.id);
                            }}
                          >
                            {e.label}
                          </ParagraphMedium>
                        ))}
                    </div>
                  )}
                  returnFocus
                  autoFocus
                >
                  <div>
                    <TextButton kind={KIND.tertiary} size={SIZE.mini}>
                      Sort
                    </TextButton>
                  </div>
                </StatefulPopover>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              {type === "email" ? (
                <></>
              ) : (
                <>
                  {data &&
                    data.data &&
                    data.data.docs &&
                    data.data.docs.length > 0 && (
                      <div className="drawer_add_btn">
                        <RBACWrapper role={ADD_DOCUMENTS} type={"PERMISSION"}>
                          <TextButton
                            kind={KIND.tertiary}
                            size={SIZE.mini}
                            onClick={() => {
                              if (entity === "invoices") {
                                dispatch(addInvoiceDocumentForm());
                              } else {
                                dispatch(addDocumentForm());
                              }
                            }}
                          >
                            <Plus />
                            Add Documents
                          </TextButton>
                        </RBACWrapper>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>

          {type === "email" ? (
            <></>
          ) : (
            <>
              {((data &&
                data.data &&
                data.data.docs &&
                data.data.docs.length == 0) ||
                type === EDIT ||
                type === NEW) && (
                <RBACWrapper role={ADD_DOCUMENTS} type={"PERMISSION"}>
                  <AddDMSystem
                    createDocument={createDocument}
                    resourceId={resourceId}
                    refetch={refetch}
                    entity={entity}
                  />
                </RBACWrapper>
              )}
            </>
          )}

          {/* TWO STARTS */}

          <div
            style={{
              // height: "calc(100% - 85px)",
              // paddingBottom: "5%",
              height: "100%",
              overflow: "scroll",
            }}
          >
            {isFetching ? (
              <Loader />
            ) : (
              <>
                {data &&
                  data.data &&
                  data.data.docs &&
                  data.data.docs.map((file, i) => {
                    let isSelected = selectedData.find((fl) => {
                      return fl.fileRef === file._id;
                    });
                    return (
                      <DocumentCard
                        res={file}
                        deleteMethod={deleteDocumentData}
                        resourceId={resourceId}
                        isSelected={isSelected ? true : false}
                        onFileSelectChange={onFileSelectChange}
                        entity={entity}
                        // need to change
                        type={type}
                      />
                    );
                  })}
              </>
            )}
          </div>
          {/* TWO ENDS */}

          {type === "email" &&
          data &&
          data.data &&
          data.data.docs &&
          data.data.docs.length !== 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5px",
                  paddingTop: "12px",
                }}
              >
                <div style={{ width: "70px" }}>
                  <TextButton
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    fullWidth
                    onClick={() => {
                      if (entity === "invoices") {
                        dispatch(invoiceDrawerClose(false));
                        setSelectedData([]);
                      } else {
                        dispatch(documentDrawerClose(false));
                        setSelectedData([]);
                      }
                      // dispatch(documentDrawerClose(false));
                      // setSelectedData([]);
                      // dispatch(initialState());
                      // isSelected={isSelected ? true : false}
                    }}
                  >
                    Close
                  </TextButton>
                </div>
                <div style={{ width: "70px" }}>
                  <TextButton
                    // kind={KIND.}
                    size={SIZE.mini}
                    fullWidth
                    onClick={() => {
                      // if (entity === "invoices") {
                      //
                      //   dispatch(documentDriveInv(selectedData));
                      // } else {
                      //   dispatch(documentDrive(selectedData));
                      // }
                      // need to change
                      dispatch(documentDrive(selectedData));
                      dispatch(closeDocmentDrawer(false));
                      // dispatch(openingDrawerState(false));
                      // if (entity === "invoices") {
                      //   dispatch(invoiceDrawerClose(false));
                      //   setSelectedData([]);
                      // } else {
                      //   dispatch(documentDrawerClose(false));
                      //   setSelectedData([]);
                      // }
                      setSelectedData([]);
                    }}
                  >
                    Add
                  </TextButton>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div style={{ height: "40px" }}></div>
        </div>
      </>
    </div>
  );
};

export default DMSystem_V2;
