export default function Payment({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_363_4806)">
        <path
          d="M8.46875 6.90607V6.12482H3.78125C3.35078 6.12482 3 6.4756 3 6.90607V8.46857H8.63359C8.525 7.99122 8.46875 7.47716 8.46875 6.90607Z"
          fill={color}
        />
        <path
          d="M15.468 10.0311C14.9352 10.8522 14.1227 11.5506 12.9586 12.2193C12.7797 12.3217 12.5781 12.3748 12.375 12.3748C12.1719 12.3748 11.9703 12.3217 11.7937 12.2209C10.6297 11.5506 9.81719 10.8514 9.28359 10.0311H3V13.9373C3 14.3686 3.35078 14.7186 3.78125 14.7186H14.7188C15.15 14.7186 15.5 14.3686 15.5 13.9373V10.0311H15.468ZM6.51562 12.3748H4.95312C4.7375 12.3748 4.5625 12.1998 4.5625 11.9842C4.5625 11.7686 4.7375 11.5936 4.95312 11.5936H6.51562C6.73125 11.5936 6.90625 11.7686 6.90625 11.9842C6.90625 12.1998 6.73125 12.3748 6.51562 12.3748Z"
          fill={color}
        />
        <path
          d="M15.2633 4.98495L12.5289 3.81307C12.4297 3.77089 12.3195 3.77089 12.2203 3.81307L9.48594 4.98495C9.34297 5.04589 9.25 5.18729 9.25 5.34354V6.90604C9.25 9.05526 10.0445 10.3115 12.1805 11.542C12.2406 11.5764 12.3078 11.5935 12.375 11.5935C12.4422 11.5935 12.5094 11.5764 12.5695 11.542C14.7055 10.3146 15.5 9.05839 15.5 6.90604V5.34354C15.5 5.18729 15.407 5.04589 15.2633 4.98495ZM13.8523 6.75995L12.2898 8.71307C12.2148 8.80526 12.1023 8.85917 11.9844 8.85917C11.9789 8.85917 11.9727 8.85917 11.968 8.85917C11.8438 8.8537 11.7289 8.78964 11.6594 8.68573L10.8781 7.51386C10.7586 7.33417 10.807 7.09198 10.9867 6.97245C11.1648 6.8537 11.4078 6.90057 11.5281 7.08104L12.0133 7.80839L13.2414 6.27245C13.3766 6.10448 13.6227 6.07792 13.7906 6.21151C13.9594 6.34511 13.9859 6.5912 13.8523 6.75995Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_363_4806">
          <rect
            width={size * 0.695}
            height={size * 0.695}
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
