import React, { useState } from "react";
import { StyledList, StyledListItem } from "baseui/menu";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import Edit from "../../assets/img/svg/EditSvg";
import useHover from "../../utils/HoverSvg/useHover";
import "../../../src/assets/css/table.css";
import { Label2 } from "baseui/typography";
import { IconButton, SIZE } from "../IconButton";
import { FormattedMessage } from "react-intl";
import TagTick from "../../assets/img/svg/CustomerOverViewIcon/TagTick";

const ChipFilterNew = ({ filters, setFilter }) => {
  const [activeFilters, setActiveFilters] = useState([]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {filters.map((fil) => {
        let isActive = activeFilters.find(
          (actFil) => actFil.value === fil.value
        );

        return (
          <div
            className={`mx-chip-filter_new ${
              isActive ? "mx-chip-filter_new--active" : ""
            }`}
            onClick={() => {
              if (isActive) {
                let updatedActiveFilters = activeFilters.filter(
                  (actFil) => actFil.value !== fil.value
                );
                setActiveFilters(updatedActiveFilters);
                if (setFilter) {
                  setFilter(updatedActiveFilters.map((e) => e.value));
                }
              } else {
                let updatedActiveFilters = [...activeFilters, { ...fil }];
                setActiveFilters(updatedActiveFilters);
                if (setFilter) {
                  setFilter(updatedActiveFilters.map((e) => e.value));
                }
              }
            }}
          >
            {isActive ? (
              <div className="mx-chip-filter__start-enhancer">
                <TagTick />
              </div>
            ) : (
              <></>
            )}
            <FormattedMessage id={fil.value}>{fil.value}</FormattedMessage>
          </div>
        );
      })}
    </div>
  );
};

export default ChipFilterNew;
