import {
  HeadingXLarge,
  HeadingXSmall,
  LabelLarge,
  ParagraphMedium,
} from "baseui/typography";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Pie } from "react-chartjs-2";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { IconButton, KIND } from "../../IconButton";
import DownArrow from "../../../utils/DownArrow";
import DownloadSvg from "../../../assets/img/svg/DownloadSvg";
import { invoiceDownload } from "../../../services";
import { StatefulTooltip } from "baseui/tooltip";
import { useNavigate } from "react-router-dom";
import { isOpenModal } from "../../../redux/actions";
import { useDispatch } from "react-redux";

const OverviewPieCharts = ({ data, getParams }) => {
  let dispatch = useDispatch();
  let {
    currentOrganization,
    referenceData,
    currencyFormat,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);
  let navigate = useNavigate();

  const timeZone = currentDefaultFormatDetails.time_zone;

  const [visible, setVisible] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const invoiceDate = moment
    .utc(data && data.invoice_date)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  const dueDate = moment
    .utc(data && data.invoice_due_date)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  const customerNameRef = useRef(null);

  useEffect(() => {
    if (customerNameRef.current && customerNameRef.current.offsetWidth) {
      if (165 < customerNameRef.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [getParams, visible]);

  return (
    <div
      style={{
        padding: "20px 15px ",
        // width: "420px",
        height: "150px",
        backgroundColor: "#F0F5FF",
        display: "flex",
        flexDirection: "column",
        minWidth: "400px",
        gap: "20%",
        boxShadow: "1px 2px 4px 0px #33386025",
        border: "0.5px solid #cdced9",
        borderRadius: "5px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Download Starts */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {visible ? (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={data?.customer_name}
            >
              <LabelLarge
                ref={customerNameRef}
                $style={{
                  whiteSpace: "nowrap",
                  minWidth: "125px",
                  maxWidth: "165px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  color: "#516beb",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    `/customers/${
                      data && data.pgmc ? data.pgmc : data.customer_id
                    }`
                  );
                  dispatch(isOpenModal(false));
                }}
              >
                {data?.customer_name}
              </LabelLarge>
            </StatefulTooltip>
          ) : (
            <LabelLarge
              ref={customerNameRef}
              $style={{
                whiteSpace: "nowrap",
                // minWidth: "125px",
                // maxWidth: "165px",
                // textOverflow: "ellipsis",
                // overflow: "hidden",
                color: "#516beb",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(isOpenModal(false));
                navigate(
                  `/customers/${
                    data && data.pgmc ? data.pgmc : data.customer_id
                  }`
                );
              }}
            >
              {data?.customer_name}
            </LabelLarge>
          )}

          {(currentDefaultFormatDetails.accounting_book == "CSV" &&
            data &&
            data.is_system_generated) ||
          (currentDefaultFormatDetails.accounting_book == "CSV" &&
            data &&
            data.invoive_file_doc_id) ||
          (currentDefaultFormatDetails?.accounting_book != "CSV" &&
            currentDefaultFormatDetails?.accounting_book !== "ROTESSA") ? (
            <IconButton
              kind={KIND.tertiary}
              isLoading={isLoading}
              disabled={isLoading}
              onClick={async () => {
                await setIsLoading(true);
                invoiceDownload({
                  invoiceId: getParams,
                  organization: currentOrganization,
                }).then(() => setIsLoading(false));
              }}
            >
              <DownloadSvg />
            </IconButton>
          ) : (
            <></>
          )}
        </div>
        {/* Download Ends */}

        {/* Due Amount Starts */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <HeadingXSmall style={{ lineHeight: "22px" }}>
            Due Amount
          </HeadingXSmall>
          <div>
            {/* <ParagraphMedium>USD</ParagraphMedium> */}
            {/* <LabelLarge>
              <sup style={{ paddingRight: "3px" }}>USD</sup>
              2,300.00

              
            </LabelLarge> */}

            <div className="cp-card__pie-chart-inv-amount">
              {/* <ParagraphLarge $style={{ color: "#333860" }}> */}
              <sup>{data.not_paid_amount && data.not_paid_amount.currency}</sup>
              <NumericFormat
                // type="text"
                displayType="text"
                value={
                  data.not_paid_amount && data.not_paid_amount.value
                    ? Number(data?.not_paid_amount.value)
                    : 0
                }
                thousandsGroupStyle={currencyGroupStyle}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={currencySeparator}
                decimalSeparator={currencyDecimalSeparator}
                renderText={(value) => (
                  <HeadingXLarge style={{ lineHeight: "24px" }}>
                    {value}
                  </HeadingXLarge>
                )}
              />

              {/* <FormattedNumber
                      value={
                        customerDetails.total_outstanding_invoice_amount &&
                        customerDetails.total_outstanding_invoice_amount.value
                          ? customerDetails.total_outstanding_invoice_amount
                              .value
                          : 0
                      }
                      style="currency"
                      currency={
                        customerDetails.total_inv_amount &&
                        customerDetails.total_inv_amount.currency
                      }
                    /> */}
              {/* </ParagraphLarge> */}
            </div>
          </div>
        </div>
        {/* Due Amount Ends */}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Invoice Amount Starts */}
        <div>
          <HeadingXSmall style={{ lineHeight: "21px" }}>
            Invoice Amount
          </HeadingXSmall>
          <div>
            {/* <LabelLarge>
              <sup style={{ paddingRight: "3px" }}>USD</sup>
              2,300.00
            </LabelLarge> */}

            <div className="cp-card__pie-chart-inv-amount">
              {/* <ParagraphLarge $style={{ color: "#333860" }}> */}
              <sup>
                {data.invoice_total_amount &&
                  data.invoice_total_amount.currency}
              </sup>
              <NumericFormat
                // type="text"
                displayType="text"
                value={
                  data.invoice_total_amount && data.invoice_total_amount.value
                    ? Number(data?.invoice_total_amount.value)
                    : 0
                }
                thousandsGroupStyle={currencyGroupStyle}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={currencySeparator}
                decimalSeparator={currencyDecimalSeparator}
                renderText={(value) => <LabelLarge>{value}</LabelLarge>}
              />

              {/* <FormattedNumber
                      value={
                        customerDetails.total_outstanding_invoice_amount &&
                        customerDetails.total_outstanding_invoice_amount.value
                          ? customerDetails.total_outstanding_invoice_amount
                              .value
                          : 0
                      }
                      style="currency"
                      currency={
                        customerDetails.total_inv_amount &&
                        customerDetails.total_inv_amount.currency
                      }
                    /> */}
              {/* </ParagraphLarge> */}
            </div>
          </div>
        </div>
        {/* Invoice Amount Ends */}

        {/* Invoice Date Starts*/}
        <div>
          <HeadingXSmall style={{ lineHeight: "22px" }}>
            Invoice Date
          </HeadingXSmall>
          <LabelLarge>{invoiceDate}</LabelLarge>
        </div>
        {/* Invoice Date Ends*/}
        {/* Due Date Starts */}
        <div>
          <HeadingXSmall style={{ lineHeight: "22px" }}>Due Date</HeadingXSmall>
          <LabelLarge>{dueDate}</LabelLarge>
        </div>
        {/* Due Date Ends */}
      </div>
    </div>
  );
};

export default OverviewPieCharts;
