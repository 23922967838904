import React from "react";

const ReplyAll = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75951 14.8958L2.31367 10.4729C2.23728 10.3965 2.18381 10.3201 2.15326 10.2437C2.1227 10.1674 2.10742 10.0833 2.10742 9.99166C2.10742 9.9 2.1227 9.81597 2.15326 9.73958C2.18381 9.66319 2.23728 9.5868 2.31367 9.51041L6.75951 5.06458C6.88173 4.94236 7.04214 4.87743 7.24076 4.86979C7.43937 4.86215 7.60742 4.92708 7.74492 5.06458C7.88242 5.20208 7.95117 5.36632 7.95117 5.55729C7.95117 5.74826 7.88242 5.9125 7.74492 6.05L3.80326 9.99166L7.74492 13.9333C7.86714 14.0556 7.93208 14.2121 7.93971 14.4031C7.94735 14.5941 7.88242 14.7583 7.74492 14.8958C7.60742 15.0333 7.44319 15.1021 7.25221 15.1021C7.06124 15.1021 6.89701 15.0333 6.75951 14.8958ZM19.4553 17.4167C19.2567 17.4167 19.0925 17.3517 18.9626 17.2219C18.8328 17.092 18.7678 16.9278 18.7678 16.7292V13.8417C18.7678 12.925 18.4699 12.1687 17.8741 11.5729C17.2783 10.9771 16.522 10.6792 15.6053 10.6792H8.61575L11.8699 13.9333C11.9921 14.0556 12.0533 14.2121 12.0533 14.4031C12.0533 14.5941 11.9845 14.7583 11.847 14.8958C11.7095 15.0333 11.5453 15.1021 11.3543 15.1021C11.1633 15.1021 10.9991 15.0333 10.8616 14.8958L6.43867 10.4729C6.36228 10.3965 6.30881 10.3201 6.27826 10.2437C6.2477 10.1674 6.23242 10.0833 6.23242 9.99166C6.23242 9.9 6.2477 9.81597 6.27826 9.73958C6.30881 9.66319 6.36228 9.5868 6.43867 9.51041L10.8845 5.06458C11.0067 4.94236 11.1671 4.87743 11.3658 4.86979C11.5644 4.86215 11.7324 4.92708 11.8699 5.06458C12.0074 5.20208 12.0762 5.36632 12.0762 5.55729C12.0762 5.74826 12.0074 5.9125 11.8699 6.05L8.61575 9.30416H15.6053C16.9039 9.30416 17.9849 9.73576 18.848 10.599C19.7112 11.4622 20.1428 12.5431 20.1428 13.8417V16.7292C20.1428 16.9278 20.0779 17.092 19.948 17.2219C19.8182 17.3517 19.654 17.4167 19.4553 17.4167Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default ReplyAll;
