import React, { useState } from "react";
import { Select } from "baseui/select";
import UpArrow from "../../assets/img/svg/UpArrow";
import DownArrow from "../../assets/img/svg/DownArrow";

export const CellRefSelectBox = (props) => {
  return (
    <>
      <Select
        // clearable={false}
        id={props.name}
        autoComplete="off"
        autoFill="off"
        searchable={true}
        $isOpen
        overrides={{
          ControlContainer: {
            style: ({ $theme }) => ({
              outline: "none",
              backgroundColor: "none",
              border: "none",
              width: "90%",
              position: "relative",
            }),
          },
          DropdownContainer: {
            style: ({ $theme }) => ({
              top: "100px",
              maxWidth: "220px",
              boxShadow: "0px 4px 4px 0px #00000025",
            }),
          },
          Dropdown: {
            style: ({ $theme }) => ({
              borderRadius: "5px",
              maxHeight: "300px",
            }),
          },
          DropdownListItem: {
            style: ({ $isHighlighted, $isFocused, $isSelected }) => ({
              color: $isHighlighted ? "#516BEB" : "#000000",
              backgroundColor: $isHighlighted ? "#F5F7FF" : "",
            }),
          },
          Placeholder: {
            style: ({ $theme }) => ({
              color: "#adadad",
              fontWeight: "400",
              fontSize: "16px",
            }),
          },
          // IconsContainer: (props) => {
          //   return (
          //     <div className="filter__select">
          //       {props.$isOpen ? <UpArrow /> : <DownArrow />}
          //     </div>
          //   );
          // },
          ValueContainer: {
            style: ({ $theme }) => ({
              color: "#adadad",
              fontWeight: "400",
              fontSize: "16px",
            }),
          },
          // Popover: {
          //   props: {
          //     overrides: {
          //       Body: {
          //         style: ({ $theme }) => ({
          //           top: "100px",
          //         }),
          //       },
          //     },
          //   },
          // },
        }}
        {...props}
      />
    </>
  );
};
