import { MINI_MIRANDA_DATA } from "./action";

const initialState = {
  listData: {},
};

export const miniMirandaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MINI_MIRANDA_DATA:
      return { ...state, listData: payload };
    default:
      return { initialState };
  }
};
