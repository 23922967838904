import React, { useRef, useState } from "react";
import { Textarea } from "baseui/textarea";
import { FormControl } from "baseui/form-control";

export const WhatsAppInput = (props) => {
  const {
    error = "",
    children,
    value = "",
    label = "",
    requiredAstric = false,
    height = false,
  } = props;

  const [isFocused, setFocused] = useState(false);

  //  const textAreaRef = useRef(null);

  // const useAutosizeTextArea = (textAreaRef, value) => {
  //   useEffect(() => {
  //     if (textAreaRef) {
  //       // We need to reset the height momentarily to get the correct scrollHeight for the textarea
  //       textAreaRef.style.height = "0px";
  //       const scrollHeight = textAreaRef.scrollHeight;

  //       // We then set the height directly, outside of the render loop
  //       // Trying to set this with state or a ref will product an incorrect value.
  //       textAreaRef.style.height = scrollHeight + "px";
  //     }
  //   }, [textAreaRef, value]);
  // };

  //  const handleChange = (evt) => {
  //    const val = evt.target?.value;

  //    setValue(val);
  //  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <div className="mx-input">
      <label
        htmlFor={props.name}
        className={`ta-input__label ${
          isFocused ? "ta-input__label--active text-primary" : ""
        } ${!isFocused && value?.length > 0 ? "ta-input__label--active" : ""} ${
          error ? "text-error" : ""
        }`}
      >
        {label}{" "}
        {requiredAstric ? (
          <>
            <span style={{ color: "red" }}>*</span>
          </>
        ) : (
          <></>
        )}
      </label>
      <Textarea
        id={props.name}
        autoComplete="off"
        overrides={{
          Root: {
            style: ({ $isFocused, $error }) => ({
              borderRadius: "5px",
              borderWidth: "1px",
              borderColor: $isFocused
                ? $error
                  ? "#FD372A"
                  : "#516BEB"
                : $error
                ? "#FD372A"
                : "#CDCED9",
              backgroundColor: "#ffffff",
              maxHeight: isFocused ? "90px" : "47px",
              width: "100%",
            }),
          },
          Input: {
            style: ({ $isFocused }) => ({
              backgroundColor: "#ffffff",
              "::placeholder": {
                color: $isFocused ? "#cbd5e0" : "transparent",
                transition: "color 0.4s ease",
              },
            }),
          },
        }}
        {...props}
        onFocus={() => {
          if (props.onFocus) {
            props.onFocus();
          }
          onFocus();
        }}
        onBlur={() => {
          if (props.onBlur) {
            props.onBlur();
          }
          onBlur();
        }}
        // ref={textAreaRef}
      >
        {children}
      </Textarea>
    </div>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
