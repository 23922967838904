import React from "react";

const DragDrop = (props) => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="1.99995"
        cy="1.71871"
        rx="1.99995"
        ry="1.71871"
        fill="#D6D6D6"
      />
      <ellipse
        cx="7.99916"
        cy="1.71883"
        rx="1.99995"
        ry="1.71871"
        fill="#D6D6D6"
      />
      <ellipse
        cx="1.99995"
        cy="6.87484"
        rx="1.99995"
        ry="1.71871"
        fill="#D6D6D6"
      />
      <ellipse
        cx="7.99916"
        cy="6.87477"
        rx="1.99995"
        ry="1.71871"
        fill="#D6D6D6"
      />
      <ellipse
        cx="1.99995"
        cy="12.031"
        rx="1.99995"
        ry="1.71871"
        fill="#D6D6D6"
      />
      <ellipse
        cx="7.99916"
        cy="12.031"
        rx="1.99995"
        ry="1.71871"
        fill="#D6D6D6"
      />
    </svg>
  );
};

export default DragDrop;
