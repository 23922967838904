import { NEW, PAYMENTDETAILS, COPY } from "../../constants";
import {
  DELETE_ACTION,
  DELETE_INVOICE_ACTION,
  UPDATE_WF,
  ADD_NEW_ACTION,
  SET_CURRENT_ACTION,
  SET_INVOICE_CURRENT_ACTION,
  SAVE_CURRENT_ACTION,
  CHANGE_DATE_ACTION,
  CURRENT_TAB_ACTION,
  FORM_UPDATE_ACTION,
  CHANGE_DUE_DATE,
  SET_WORKFLOW_VIEW_ONLY,
  SET_WORKFLOW_TYPE,
  INITIAL_STATE_ON_CANCEL,
  DRAG_AND_DROP,
  INITIAL_VALUE_STATE,
  WORKFLOW_INITIAL_STATE,
  IS_MODAL,
  IS_LOADER_MODAL,
  UPDATE_ATTACHMENTS,
  REMOVE_ATTACHED_FILES,
  INITIALSTATE_STATE,
  RESET_WORKFLOW,
  SELECTED_DAYS_AFTER,
  DAYS_FROM,
  INVOICE_DUE_DAYS_AFTER,
  INVOICE_ISSUED_DAYS_AFTER,
  INVOICE_FLOW,
  UPDATE_ACTIONS,
  SAVE_ACTIONS,
  SAVE_INVOICE_CURRENT_ACTION,
  SET_INVOICE_GENERAL_DETAILS,
  CHANGE_TIME_ACTION,
  CHANGE_INVOICE_TIME_ACTION,
  DRAG_AND_DROP_INVOICE,
  SAVE_PORTAL_ACTION,
  OPEN_PORTAL_DRAWER,
} from "./actions";
import setSelectValues from "../../utils/setSelectValues";

const emptyActionStructure = {
  days_after: 0,
  time: "",
  // issued_days_after: 0,
  action_name: "",
  action_description: "",
  action_type: [],
  // recipient_type: [],
  template_id: [],
  is_automatic_action: true,
  is_promise_to_pay: true,
  is_callback_later: true,
  is_meeting_request: true,
  is_dispute: true,
  days_from: "ISSUED",
  attachments: [],
};

const initialState = {
  view: true,
  generalDetails: {
    avg_delay: 1,
  },
  currentAction: {},
  customer_flow: [],
  invoice_flow: [],
  current_tab: "0", // 0 is Customer , 1 is Invoice
  is_edited: false,
  actionType: NEW,
  isModal: false,
  isLoaderModal: false,
  attachments: [],
  initialDayAfterdays: 0,

  isLoading: true,

  mappingDueDaysAfter: [
    ...[-10, -9, -8, -7, -6, -5, -4, -3, -2, -1].map((e, i) => {
      return {
        label: `${"Day"} ${e}`,
        id: e,
        info: false,
      };
    }),
    ...Array.from({ length: 101 }).map((e, i) => {
      return {
        label: `${"Day"} ${i}`,
        id: i,
        info: false,
      };
    }),
  ],
  mappingIssuedDaysAfter: Array.from({ length: 101 }).map((e, i) => {
    return {
      label: `${"Day"} ${i}`,
      id: i,
      info: false,
    };
  }),
  invDueDaysAfter: [],
  invIssueDaysAfter: [],
  days_from: "ISSUED",
  invActiveAction: 0,
  time: "",
  portalModal: false,
  portalData: { isActive: true },
};

export const createWorkFlowReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case INVOICE_FLOW: {
      return {
        ...state,
      };
    }
    case SET_INVOICE_GENERAL_DETAILS: {
      return {
        ...state,
        generalDetails: payload,
      };
    }
    case INVOICE_ISSUED_DAYS_AFTER: {
      return {
        ...state,
        invIssueDaysAfter: [...state.invIssueDaysAfter, payload],
      };
    }
    case INVOICE_DUE_DAYS_AFTER: {
      return {
        ...state,
        invDueDaysAfter: [...state.invDueDaysAfter, payload],
      };
    }

    case DAYS_FROM: {
      let currentState = { ...state };

      let { type, index } = payload;

      let currentAction = state.invoice_flow[index];

      if (currentAction) {
        currentState.invoice_flow[index] = {
          ...currentAction,
          days_from: type,
        };
        currentState.days_from = type;
      }

      return {
        ...currentState,
      };
    }

    case SELECTED_DAYS_AFTER: {
      let currentState = { ...state };

      let { days_after, index } = payload;

      let currentAction = state.invoice_flow[index];

      if (currentAction) {
        currentState.invoice_flow[index] = {
          ...currentAction,
          days_after: days_after,
        };

        let currentSelectedIssuedDays = currentState.invoice_flow
          .filter((iv) => iv.days_from === "ISSUED")
          .map((iv) => iv.days_after);
        let currentSelectedDueDays = currentState.invoice_flow
          .filter((iv) => iv.days_from === "DUE")
          .map((iv) => iv.days_after);

        currentState.mappingIssuedDaysAfter = state.mappingIssuedDaysAfter.map(
          (mdd) => ({
            ...mdd,
            info: currentSelectedIssuedDays.includes(mdd.id),
          })
        );
        currentState.mappingDueDaysAfter = state.mappingDueDaysAfter.map(
          (mdd) => ({ ...mdd, info: currentSelectedDueDays.includes(mdd.id) })
        );
      }

      return {
        ...currentState,
      };
    }

    case RESET_WORKFLOW: {
      return {
        ...state,
        ...initialState,
      };
    }

    case INITIALSTATE_STATE: {
      return initialState;
    }
    case IS_LOADER_MODAL: {
      return {
        ...state,
        isLoaderModal: payload,
      };
    }
    case IS_MODAL: {
      return {
        ...state,
        isModal: payload,
      };
    }

    case WORKFLOW_INITIAL_STATE: {
      return {
        ...state,
        is_edited: payload,
      };
    }
    case ADD_NEW_ACTION: {
      let flowName =
        state.current_tab == "0" ? "customer_flow" : "invoice_flow";

      let days_after = state[flowName][state[flowName].length - 1]
        ? state[flowName][state[flowName].length - 1].days_after +
          state.generalDetails.avg_delay
        : 1;

      let newAction = {
        ...emptyActionStructure,
        _id: `${state[flowName].length + 1}-temp`,
        days_after,
      };

      if (flowName == "invoice_flow") {
        let currentSelectedIssuedDays = state.invoice_flow
          .filter((iv) => iv.days_from === "ISSUED")
          .map((iv) => iv.days_after);
        let currentSelectedDueDays = state.invoice_flow
          .filter((iv) => iv.days_from === "DUE")
          .map((iv) => iv.days_after);

        let days_from =
          state.invoice_flow[state.invoice_flow.length - 1] &&
          state.invoice_flow[state.invoice_flow.length - 1].days_from == "DUE"
            ? "DUE"
            : "ISSUED";

        let lastDaysAfter =
          days_from === "DUE"
            ? currentSelectedDueDays[currentSelectedDueDays.length - 1]
            : currentSelectedIssuedDays[currentSelectedIssuedDays.length - 1];

        newAction.days_from = days_from;

        if (lastDaysAfter) {
          newAction.days_after = lastDaysAfter + state.generalDetails.avg_delay;
        } else {
          newAction.days_after = 1;
        }

        let mappingIssuedDay = {
          id: state.mappingIssuedDaysAfter.length,
          label: `Day ${state.mappingIssuedDaysAfter.length}`,
          info: false,
        };

        state.mappingIssuedDaysAfter.push(mappingIssuedDay);

        let mappingDueDays = {
          id: state.mappingDueDaysAfter.length - 10,
          label: `Day ${state.mappingDueDaysAfter.length - 10}`,
          info: false,
        };

        state.mappingDueDaysAfter.push(mappingDueDays);

        let mappingIssuedDaysAfter = [
          // ...state.mappingIssuedDaysAfter,
          ...(days_from === "ISSUED" ? state.mappingIssuedDaysAfter : []),
        ].map((mdd) => ({
          ...mdd,
          id: mdd.id + 1,
          label: `Day ${mdd.id + 1}`,
          info: currentSelectedIssuedDays.includes(mdd.id),
        }));

        let mappingDueDaysAfter = [
          // ...state.mappingDueDaysAfter,
          ...(days_from === "DUE" ? state.mappingDueDaysAfter : []),
        ].map((mdd) => ({
          ...mdd,
          id: mdd.id + 1,
          label: `Day ${mdd.id + 1}`,
          info: currentSelectedDueDays.includes(mdd.id),
        }));

        let newState = {
          ...state,
          [flowName]: [...state[flowName], newAction],
          currentAction: newAction,
          is_edited: false,
          // mappingIssuedDaysAfter,
          // mappingDueDaysAfter,
          invActiveAction: [...state[flowName], newAction].length - 1,
        };

        return newState;
      }

      let newState = {
        ...state,
        [flowName]: [...state[flowName], newAction],
        currentAction: newAction,
        is_edited: false,
        portalData: { isActive: true },
      };

      return newState;
    }
    // Set Current Action
    case SET_CURRENT_ACTION: {
      return {
        ...state,
        currentAction: { ...payload },
        // attachments: [...payload.attachments],
        portalData: { isActive: true },
      };
    }

    case SET_INVOICE_CURRENT_ACTION: {
      return {
        ...state,
        invActiveAction: payload,
        currentAction: state.invoice_flow[payload],
        // attachments: [...payload.attachments],
      };
    }

    case SAVE_CURRENT_ACTION: {
      let flowName =
        state.current_tab == "0" ? "customer_flow" : "invoice_flow";
      let currentFlow = [...state[flowName]];
      const currentActionIndex = currentFlow.findIndex((v) => {
        return v._id == payload._id;
      });
      let setPortalData = state.portalData;

      delete payload.time;
      delete payload.days_after;
      currentFlow[currentActionIndex] = {
        ...currentFlow[currentActionIndex],
        ...payload,
        ...setPortalData,
        attachments: state.attachments || [],
      };

      if (setPortalData && setPortalData.isActive) {
        return {
          ...state,
          [flowName]: currentFlow,
          currentAction: { ...payload },
          is_edited: true,
          portalData: { isActive: true },
        };
      } else {
        return {
          ...state,
          [flowName]: currentFlow,
          currentAction: { ...payload, ...setPortalData },
          is_edited: true,
          portalData: { isActive: true },
        };
      }
    }

    case SAVE_PORTAL_ACTION: {
      // let flowName =
      //   state.current_tab == "0" ? "customer_flow" : "invoice_flow";
      // let currentFlow = [...state[flowName]];
      // let portalInset = { ...state[flowName]["portal_settings"] };
      // const currentActionIndex = currentFlow.findIndex((v) => {
      //   return v._id == payload.portal_settings._id;
      // });

      // delete payload.time;
      // delete payload.days_after;
      // currentFlow[currentActionIndex]["portal_settings"] = {
      //   ...currentFlow[currentActionIndex]["portal_settings"],
      //   ...payload,
      //   attachments: state.attachments || [],
      // };

      return {
        ...state,
        portalData: { ...payload },
        is_edited: false,
      };
    }

    case SAVE_INVOICE_CURRENT_ACTION: {
      let currentFlow = [...state["invoice_flow"]];

      if (currentFlow[state.invActiveAction]) {
        let { days_after, days_from, time, ...rest } = payload;
        currentFlow[state.invActiveAction] = {
          ...currentFlow[state.invActiveAction],
          ...rest,
        };
      }

      return {
        ...state,
        invoice_flow: currentFlow,
        is_edited: true,
      };
    }

    //Naming needs to changed
    case UPDATE_ACTIONS: {
      let generalDetails = { ...state.generalDetails, ...payload };

      let displayView = { ...state };

      // let { customer_flow, ...generalDetails } = payload;

      let flowName =
        state.current_tab == "0" ? "customer_flow" : "invoice_flow";

      let {
        customer_flow,
        invoice_flow,
        draft_customer_flow,
        draft_invoice_flow,
      } = payload;

      let flow;
      let customerFlow = [];
      let invoiceFlow = [];

      if (
        generalDetails.is_repeat_last_action === false &&
        displayView.view === true
      ) {
        customerFlow = customer_flow;
        invoiceFlow = invoice_flow;
      } else {
        customerFlow = draft_customer_flow?.length
          ? draft_customer_flow
          : customer_flow;
        invoiceFlow = draft_invoice_flow?.length
          ? draft_invoice_flow
          : invoice_flow;
      }
      customerFlow =
        customerFlow.length > 0
          ? customerFlow.map((e) => {
              let newValues = {
                ...e,
                action_type: setSelectValues(e.action_type),
                recipient_type: setSelectValues(e.recipient_type, true),
                template_id: setSelectValues(e.template_id),
              };
              if (e.action_type === "letter") {
                newValues.postal_type = setSelectValues(e.postal_type);
                // newValues.postal_service = setSelectValues(e.postal_service);
                newValues.delivery_method = setSelectValues(e.delivery_method);
              }
              if (e.action_type === "FIELD_VISIT") {
                newValues.department = setSelectValues(e.department);
              }

              return newValues;
            })
          : [
              {
                ...emptyActionStructure,
                _id: `${customerFlow.length}-temp`,
                days_after:
                  emptyActionStructure.days_after === ""
                    ? (emptyActionStructure.days_after = 1)
                    : 1,
              },
            ];

      invoiceFlow =
        invoiceFlow.length > 0
          ? invoiceFlow.map((e) => {
              let newValues = {
                ...e,
                action_type: setSelectValues(e.action_type),
                recipient_type: setSelectValues(e.recipient_type, true),
                template_id: setSelectValues(e.template_id),
                department: setSelectValues(e.department),
              };
              if (e.action_type === "letter") {
                newValues.postal_type = setSelectValues(e.postal_type);
                // newValues.postal_service = setSelectValues(e.postal_service);
                newValues.delivery_method = setSelectValues(e.delivery_method);
              }
              return newValues;
            })
          : [
              {
                ...emptyActionStructure,
                _id: `${invoiceFlow.length}-temp`,
                days_after:
                  emptyActionStructure.days_after === ""
                    ? (emptyActionStructure.days_after = 1)
                    : 1,
              },
            ];

      let currentAction = state.currentAction || {};

      if (flowName === "customer_flow" && customerFlow.length > 0) {
        currentAction = customerFlow[0];
      }
      //  else if (flowName === "customer_flow") {
      //   let days_after =
      //     emptyActionStructure.days_after === ""
      //       ? (emptyActionStructure.days_after = 1)
      //       : 1;
      //   let newAction = {
      //     ...emptyActionStructure,
      //     _id: `${customerFlow.length}-temp`,
      //     days_after,
      //   };
      //   customerFlow = [newAction];
      //   currentAction = newAction;
      // }

      if (flowName === "invoice_flow" && invoiceFlow.length > 0) {
        currentAction = invoiceFlow[0];
      }

      // else if (flowName === "invoice_flow") {
      //   let days_after =
      //     emptyActionStructure.days_after === ""
      //       ? (emptyActionStructure.days_after = 1)
      //       : 1;
      //   let newAction = {
      //     ...emptyActionStructure,
      //     _id: `${invoiceFlow.length}-temp`,
      //     days_after,
      //   };
      //   invoiceFlow = [newAction];
      //   currentAction = newAction;
      // }

      return {
        ...state,
        generalDetails,
        customer_flow: customerFlow,
        invoice_flow: invoiceFlow,
        currentAction,
        attachments: currentAction.attachments,
        isLoading: false,
      };
    }

    case UPDATE_WF: {
      if (state.customer_flow) {
        state.customer_flow.push(payload);
      } else {
        state.customer_flow = [payload];
      }
      return {
        ...state,
        // customer_flow: [...state.customer_flow, payload],
      };
    }

    case SAVE_ACTIONS: {
      state.customer_flow[payload.index][payload.ref] = payload.data;
      return {
        ...state,
      };
    }

    case DELETE_ACTION: {
      let flowName =
        state.current_tab == "0" ? "customer_flow" : "invoice_flow";
      // const DeleteElemenet = state.customer_flow.findIndex(
      //   (v) => v._id == payload
      // );
      const DeleteElemenet = state[flowName].findIndex((v) => v._id == payload);
      // state.customer_flow.splice(DeleteElemenet, 1);
      state[flowName].splice(DeleteElemenet, 1);
      state.currentAction = {
        ...state[flowName][0],
        // ...state.invoice_flow[0],
      };
      // state.currentAction = { ...state.customer_flow[DeleteElemenet] };
      return {
        ...state,
        // currentAction: { ...state.customer_flow[DeleteElemenet] },
        // currentAction: state,
        is_edited: true,
      };
    }

    case DELETE_INVOICE_ACTION: {
      let flowName =
        state.current_tab == "0" ? "customer_flow" : "invoice_flow";

      // const DeleteElemenet = state[flowName].findIndex((v) => v == payload);

      let invCurrentState = state.invoice_flow[payload];

      if (invCurrentState) {
        let filteredInvData = state.invoice_flow.filter(
          (i) => i._id !== invCurrentState._id
        );

        // inv

        return {
          ...state,
          currentAction: {
            ...filteredInvData[0],
          },
          invActiveAction: 0,
          invoice_flow: filteredInvData,
          is_edited: true,
        };
      }

      return {
        ...state,
      };
    }

    case CHANGE_DATE_ACTION: {
      let currentState = { ...state };
      let flowName =
        currentState.current_tab == "0" ? "customer_flow" : "invoice_flow";
      const index = currentState[flowName].findIndex((v) => {
        return v._id == payload._id;
      });

      currentState[flowName][index].days_after = payload.days_after;
      // state[flowName].days_after = payload.days_after;

      return {
        ...currentState,
        // currentAction: {
        //   ...state.currentAction,
        days_after: payload.days_after,
        // },
        // payload,
        is_edited: true,
      };
    }

    case CHANGE_INVOICE_TIME_ACTION: {
      let currentState = { ...state };
      let flowName =
        currentState.current_tab == "0" ? "customer_flow" : "invoice_flow";
      const index = currentState[flowName].findIndex((v) => {
        return v._id == payload._id;
      });
      currentState[flowName][index].time = payload.time;
      return {
        ...currentState,
        // ...state.currentAction,
        time: payload.time,
        // },
        // payload,
        is_edited: true,
      };
    }

    case CHANGE_TIME_ACTION: {
      let currentState = { ...state };
      let flowName =
        currentState.current_tab == "0" ? "customer_flow" : "invoice_flow";
      const index = currentState[flowName].findIndex((v) => {
        return v._id == payload._id;
      });

      currentState[flowName][index].time = payload.time;
      // state[flowName].days_after = payload.days_after;

      return {
        ...currentState,
        // currentAction: {
        //   ...currentState.currentAction,
        time: payload.time,
        // },
        // payload,
        is_edited: true,
      };
    }

    case CHANGE_DUE_DATE: {
      const index = state.invoice_flow.findIndex((v) => {
        return v._id == payload._id;
      });
      // return { ...state };
      state.invoice_flow[index].days_from = payload.days_from;

      return {
        ...state,
        // payload,
        is_edited: false,
      };
    }
    case CURRENT_TAB_ACTION: {
      let flowName = payload == "0" ? "customer_flow" : "invoice_flow";
      // let customerFlow = [];
      // let invoiceFlow = [];
      // let currentAction = state.currentAction || {};

      // if (flowName === "customer_flow" && customerFlow.length > 0) {
      //   currentAction = customerFlow[0];
      // } else if (flowName === "customer_flow") {
      //   let days_after =
      //     emptyActionStructure.days_after === ""
      //       ? (emptyActionStructure.days_after = 1)
      //       : 1;
      //   let newAction = {
      //     ...emptyActionStructure,
      //     _id: `${customerFlow.length}-temp`,
      //     days_after,
      //   };
      //   customerFlow = [newAction];
      //   currentAction = newAction;
      // }
      //
      // if (flowName === "invoice_flow" && invoiceFlow.length > 0) {
      //   currentAction = invoiceFlow[0];
      // } else if (flowName === "invoice_flow") {
      //   let days_after =
      //     emptyActionStructure.days_after === ""
      //       ? (emptyActionStructure.days_after = 1)
      //       : 1;
      //   let newAction = {
      //     ...emptyActionStructure,
      //     _id: `${invoiceFlow.length}-temp`,
      //     days_after,
      //   };
      //   invoiceFlow = [newAction];
      //   currentAction = newAction;
      // }

      return {
        ...state,
        current_tab: payload,
        // customer_flow: customerFlow,
        // invoice_flow: invoiceFlow,
        currentAction: state[flowName].length > 0 ? state[flowName][0] : {},
      };
    }
    case SET_WORKFLOW_VIEW_ONLY: {
      // let generalDetails = { ...state.generalDetails, ...payload };
      //
      // let flowName =
      //   state.current_tab == "0" ? "customer_flow" : "invoice_flow";

      // let { customer_flow, invoice_flow } = payload;

      // const flow = flowName == "customer_flow" ? customer_flow : invoice_flow;
      // ? draft_customer_flow?.length
      //   ? draft_customer_flow
      //   : customer_flow
      // : draft_invoice_flow?.length
      // ? draft_invoice_flow
      // : invoice_flow;
      // let newCustomerFlow =
      //   // customer_flow
      //   flow
      //     ? flow
      //         // customer_flow
      //         .map((e) => {
      //           let newValues = {
      //             ...e,
      //             action_type: setSelectValues(e.action_type),
      //             recipient_type: setSelectValues(e.recipient_type, true),
      //             template_id: setSelectValues(e.template_id),
      //           };
      //           if (e.action_type === "letter") {
      //             newValues.postal_type = setSelectValues(e.postal_type);
      //             newValues.postal_service = setSelectValues(e.postal_service);
      //           }
      //           return newValues;
      //         })
      //     : [];
      // let currentAction = state.currentAction || {};
      // if (newCustomerFlow.length > 0) {
      //   currentAction = newCustomerFlow[0];
      // } else {
      //   let days_after =
      //     emptyActionStructure.days_after === ""
      //       ? (emptyActionStructure.days_after = 1)
      //       : 1;
      //   let newAction = {
      //     ...emptyActionStructure,
      //     _id: `${state.customer_flow.length}-temp`,
      //     days_after,
      //   };
      //   newCustomerFlow = [newAction];
      //   currentAction = newAction;
      // }

      return {
        ...state,
        // generalDetails,
        // [flowName]: newCustomerFlow,
        view: payload,
      };
    }
    case SET_WORKFLOW_TYPE: {
      return { ...state, actionType: payload };
    }
    case FORM_UPDATE_ACTION: {
      return {
        ...state,
        generalDetails: { ...state.generalDetails, ...payload },
      };
    }
    case INITIAL_STATE_ON_CANCEL: {
      return {
        view: false,
        generalDetails: {
          avg_delay: 1,
        },
        currentAction: {},
        customer_flow: [],
        invoice_flow: [],
        current_tab: "0", // 0 is Customer , 1 is Invoice
        is_edited: false,
        actionType: NEW,
      };
    }
    case DRAG_AND_DROP: {
      return {
        ...state,
        customer_flow: payload,
        // invoice_flow: payload,
        is_edited: true,
      };
    }
    case DRAG_AND_DROP_INVOICE: {
      return {
        ...state,
        // customer_flow: payload,
        invoice_flow: payload,
        is_edited: true,
      };
    }
    case INITIAL_VALUE_STATE: {
      return {
        ...state,
        currentAction: payload,
      };
    }

    case UPDATE_ATTACHMENTS: {
      return {
        ...state,
        attachments: [...state.attachments, payload],
      };
    }

    case REMOVE_ATTACHED_FILES: {
      state.attachments.splice(payload, 1);
      return {
        ...state,
        attachments: [...state.attachments],
      };
    }

    case OPEN_PORTAL_DRAWER: {
      return {
        ...state,
        portalModal: payload,
      };
    }

    default:
      return state;
  }
};
