import React from "react";

const DateOfBirth = ({ size = 18, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.27403 16.124C3.09038 16.124 2.9363 16.0594 2.81179 15.93C2.68726 15.8007 2.625 15.6442 2.625 15.4606V11.6221C2.625 11.3008 2.74019 11.025 2.97056 10.7946C3.20092 10.5642 3.47676 10.449 3.79807 10.449H4.18847V7.19327C4.18847 6.86947 4.30365 6.593 4.53401 6.36388C4.76439 6.13475 5.04023 6.02019 5.36154 6.02019H8.52885V4.86251C8.2984 4.70706 8.1145 4.53269 7.97715 4.3394C7.83981 4.14612 7.77114 3.91558 7.77114 3.64781C7.77114 3.48165 7.80256 3.32243 7.86538 3.17015C7.92819 3.01788 8.01826 2.88328 8.13557 2.76637L8.787 2.09475C8.80206 2.08288 8.87402 2.05195 9.00287 2.00195C9.02594 2.00195 9.09598 2.03288 9.21296 2.09475L9.86439 2.76637C9.98171 2.88328 10.0738 3.01788 10.1408 3.17015C10.2078 3.32243 10.2413 3.48165 10.2413 3.64781C10.2413 3.91558 10.1706 4.14612 10.0291 4.3394C9.88754 4.53269 9.70156 4.70706 9.47111 4.86251V6.02019H12.6384C12.9597 6.02019 13.2356 6.13475 13.4659 6.36388C13.6963 6.593 13.8115 6.86947 13.8115 7.19327V10.449H14.2019C14.5232 10.449 14.799 10.5642 15.0294 10.7946C15.2598 11.025 15.375 11.3008 15.375 11.6221V15.4606C15.375 15.6442 15.3103 15.8007 15.181 15.93C15.0516 16.0594 14.8951 16.124 14.7115 16.124H3.27403ZM5.13075 10.449H12.8692V7.19327C12.8692 7.12595 12.8468 7.07066 12.8019 7.02738C12.757 6.98411 12.7025 6.96247 12.6384 6.96247H5.36154C5.29743 6.96247 5.24294 6.98411 5.19806 7.02738C5.15319 7.07066 5.13075 7.12595 5.13075 7.19327V10.449ZM3.56728 15.1817H14.4327V11.6221C14.4327 11.558 14.411 11.5035 14.3678 11.4586C14.3245 11.4138 14.2692 11.3913 14.2019 11.3913H3.79807C3.73076 11.3913 3.67547 11.4138 3.63219 11.4586C3.58892 11.5035 3.56728 11.558 3.56728 11.6221V15.1817Z"
        fill={color}
      />
    </svg>
  );
};

export default DateOfBirth;
