import {
  TIMELINE_WHATSAPP_DRAWER,
  TIMELINE_WHATSAPP_DRAWER_CLOSE,
  TIMELINE_REFETCH_CHAT,
} from "./action";

const initialState = {
  isOpen: false,
  phoneNumber: {},
  chatRefetch: 0,
};

const timelineReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TIMELINE_WHATSAPP_DRAWER:
      let splictPhoneNumber = payload.split(" ");
      let regCode = splictPhoneNumber?.[0]?.replace("+", "");
      return {
        ...state,
        isOpen: true,
        phoneNumber: {
          phone_code: regCode,
          phone_number: splictPhoneNumber[1],
        },
      };

    case TIMELINE_WHATSAPP_DRAWER_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    case TIMELINE_REFETCH_CHAT:
      const { chatRefetch } = state;
      return {
        ...state,
        chatRefetch: chatRefetch + 1,
      };

    default:
      return state;
  }
};

export default timelineReducer;
