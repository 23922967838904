import React, { useEffect, useContext } from "react";
import { Button, KIND } from "baseui/button";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { IconButton, SIZE } from "../../../../components/IconButton";
import CaptureHold from "../../../../assets/img/svg/NestedRibbons/CaptureHold";
import { HeadingXLarge, ParagraphLarge } from "baseui/typography";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { SelectBox } from "../../../../components/SelectBox";
import { TextArea } from "../../../../components/TextArea";
import { useForm, Controller, set } from "react-hook-form";
import { useIntl } from "react-intl";
import getSelectValues from "../../../../utils/getSelectValues";
import { TextButton } from "../../../../components/TextButton";
import moment from "moment";
import OnHoldModalSvg from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/OnHoldModal";
import ReleaseOnHold from "../../../../assets/img/svg/NestedRibbons/ReleaseOnHold";
import { useSelector, useDispatch } from "react-redux";
import { holdActionModal, refetchActions } from "../../../../redux/actions";
import {
  getHolidayBlackoutHolidayDate,
  updateCustomerActionWorkflowStatus,
} from "../../../../services";
import { useParams, useSearchParams } from "react-router-dom";
import { ON_HOLD } from "../../../../constants";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { refetchInvoiceActions } from "../../../../redux/invoiceOverview/action";
import { ADD_LIST_VALUES } from "../../../../providers/RBACProvider/permissionList";
import { Icon, Typography } from "../../../../components_v2";
import _ from "lodash";

// TODO API INTERGRATION
const OnHoldModal = ({ entity, getParams }) => {
  // const { id } = useParams();
  const [isOpen, setIsOpen] = React.useState(false);
  function close() {
    setIsOpen(false);
  }
  let maxyfiContextValue = useContext(MaxyfiContext);
  const {
    referenceData,
    userInfo,
    currentOrganization,
    refetch,
    space,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const entityName = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_entity
  );

  const { inv_cus_id } = useSelector((s) => s.invoiceOverviewReducer);

  // const getParams = getSearchParams.get("customers");

  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {},
  });

  let { hold_reason, hold_action_till } = watch();
  useEffect(() => {
    // setValue("hold_action_till", new Date());
  }, []);

  const blackoutHolidayData = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: hold_action_till
            ? [
                moment(hold_action_till)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: inv_cus_id,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: hold_action_till ? true : false,
    }
  );

  const holdActionMutation = useMutation(
    (holdActionData) =>
      updateCustomerActionWorkflowStatus({
        ...holdActionData,
        id: getParams,
        organization: currentOrganization,
        actionStatus: ON_HOLD,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        dispatch(holdActionModal(false));
        if (entityName === "INVOICE") {
          dispatch(refetchInvoiceActions());
        } else {
          dispatch(refetchActions());
        }
        if (hold_reason && hold_reason[0] && hold_reason[0].isCreatable) {
          refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        // close();
      },
    }
  );

  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let values = {
      ...data,
      hold_reason: getSelectValues(data.hold_reason),
      ...(data.hold_action_till
        ? {
            hold_action_till: moment
              .tz(
                `${data.hold_action_till.getFullYear()}-${
                  data.hold_action_till.getMonth() + 1
                }-${data.hold_action_till.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
    };

    await holdActionMutation.mutateAsync(values);
  };

  const modalStatus = useSelector(
    (s) => s.customerOverviewReducer.holdActionIsModal
  );

  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      {/* <Button>Open Modal</Button> */}
      {/* <IconButton
        onClick={() => setIsOpen(true)}
        size={SIZE.compact}
        style={{ borderRadius: "20px" }}
        type="button"
      >
        <CaptureHold />
      </IconButton> */}
      <Modal
        // onClose={close}
        isOpen={modalStatus}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "30px",
              width: "600px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              // boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {/* Heading */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <OnHoldModalSvg />
              <HeadingXLarge>Pause Action</HeadingXLarge>
            </div>

            {/* Section One */}
            <div style={{ paddingTop: "30px " }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ParagraphLarge
                  style={{ color: "#333860", paddingBottom: "30px" }}
                >
                  Pause Reason
                  <sup style={{ color: "#FD372A", paddingLeft: "5px" }}>*</sup>
                </ParagraphLarge>
                <div style={{ width: "270px" }}>
                  <Controller
                    defaultValues={[]}
                    name="hold_reason"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        // requiredAstric={true}
                        creatable={creatablePermission ? true : false}
                        error={errors[field.name] && errors[field.name].message}
                        // label={intl.formatMessage({
                        //   id: "hold_reason",
                        // })}
                        placeholder={intl.formatMessage({
                          id: "hold_reason",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        // options={[
                        //   { label: "Thilash", id: "thilash" },
                        //   { label: "Maxyfi", id: "maxyfi" },
                        // ]}
                        options={referenceData["hold_action_reason"]}
                      />
                    )}
                  />
                </div>
              </div>
              {/* Section Two */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
              >
                <ParagraphLarge
                  style={{ color: "#333860", paddingBottom: "30px" }}
                >
                  Pause Action on this invoice till
                  {/* <sup style={{ color: "#FD372A", paddingLeft: "5px" }}>*</sup> */}
                </ParagraphLarge>
                <div style={{ width: "270px" }}>
                  <Controller
                    name="hold_action_till"
                    control={control}
                    render={({ field }) => (
                      <DateRangeSelect
                        size={SIZE.default}
                        {...field}
                        name={field.name}
                        // requiredAstric={true}
                        error={errors[field.name] && errors[field.name].message}
                        // label={intl.formatMessage({
                        //   id: "hold_action_till",
                        // })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                        minDate={new Date()}
                      />
                    )}
                  />
                </div>
              </div>
              <>
                {_.get(blackoutHolidayData, "data.data.doc.is_holiday") && (
                  <>
                    <div className="alert-holiday-section">
                      <Icon icon="alert_circle" color="#516BEB" />
                      <Typography
                        type="p"
                        style={{
                          fontWeight: "400",
                        }}
                      >
                        The Date Selected{" "}
                        {_.get(
                          blackoutHolidayData,
                          "data.data.doc.holidays",
                          []
                        ).map((val) => {
                          return (
                            <>
                              {moment(val).format(
                                currentDefaultFormatDetails.date_format
                              )}
                            </>
                          );
                        })}{" "}
                        is a <b>“Holiday”</b>
                      </Typography>
                    </div>
                  </>
                )}
              </>
              <div style={{ paddingTop: "15px" }}>
                <Controller
                  name="comment"
                  control={control}
                  // rules={{ required: "Required" }}
                  render={({ field }) => (
                    <>
                      <TextArea
                        {...field}
                        // type="number"
                        fullWidth
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "user_comments",
                        })}
                        placeholder={intl.formatMessage({
                          id: "user_comments",
                        })}
                        value={field.value}
                      />
                    </>
                  )}
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div style={{ width: "126px" }}>
                <TextButton
                  type="button"
                  size={SIZE.compact}
                  onClick={() => dispatch(holdActionModal(false))}
                  fullWidth
                  kind={KIND.tertiary}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "126px" }}>
                <TextButton
                  type="submit"
                  size={SIZE.compact}
                  fullWidth
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  // onClick={() => dispatch(holdActionModal(false))}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

export default OnHoldModal;
