import { useContext } from "react";
import { NEW } from "../../constants";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import queryClient from "../../providers/queryClient";
import setSelectValues from "../../utils/setSelectValues";
import {
  ADD_STRATEGY_NEW_ACTION,
  DELETE_STRATEGY_ACTION,
  DRAG_AND_DROP_STRATEGY_ACTION,
  REFETCH_STRATEGY,
  RESET_STRATEGY,
  SAVE_STRATEGY_CURRENT_ACTION,
  SET_STRATEGY_ACTION_TYPE,
  SET_STRATEGY_CURRENT_ACTION,
  SET_STRATEGY_DETAILS,
  SET_STRATEGY_LIST,
  SET_STRATEGY_VIEW_ONLY,
  STRATEGY_FORM_UPDATE_ACTION,
  STRATEGY_INITIAL_STATE,
  UPDATE_SRATEGY_ACTIONS,
  UPDATE_ST,
} from "./action";
import { useQuery } from "react-query";

const emptyActionStructure = {
  // issued_days_after: 0,
  nam: "",
  des: "",
  wf: [],
  wq: [],
  rid: [],
};

const initialState = {
  view: false,
  generalDetails: {
    avg_delay: 1,
  },
  strategic_flow: [],
  currentAction: { _id: "0-temp" },
  is_edited: false,
  actionType: NEW,
  isModal: false,
  isLoaderModal: false,

  isLoading: true,
};

const strategyMaintainenceReducers = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    //   case CURRENT_SELECTED_LANGUAGE: {
    //     return {
    //       ...state,
    //       generalDetails: { ...state.generalDetails, language: payload },
    //     };
    //   }

    case REFETCH_STRATEGY: {
      return {
        ...state,
        strategyFetch: state.strategyFetch + 1,
      };
    }
    case RESET_STRATEGY: {
      return { ...state, ...initialState };
    }
    case SET_STRATEGY_DETAILS: {
      let generalDetails = { ...state.generalDetails, ...payload };

      generalDetails.isValid = false;

      if (!Object.values(generalDetails).every((x) => x === null || x === "")) {
        generalDetails.isValid = true;
      }

      let currentLanguage = "en";

      let content = {
        ["en"]: {},
      };

      content = payload.status ? payload?.content : payload?.draftContent;

      // if (
      //   state.content &&
      //   Object.keys(state.content).length > 0 &&
      //   !payload.content
      // ) {
      //   content = { ...state.content };
      // }

      return {
        ...state,
        generalDetails,
        currentLanguage,
        content,
        eventTags: payload.tags,
      };
    }
    case SET_STRATEGY_ACTION_TYPE: {
      return { ...state, actionType: payload };
    }
    case SET_STRATEGY_VIEW_ONLY: {
      return { ...state, viewOnly: payload };
    }
    case UPDATE_ST: {
      if (state.customer_flow) {
        state.customer_flow.push(payload);
      } else {
        state.customer_flow = [payload];
      }
      return {
        ...state,
        // customer_flow: [...state.customer_flow, payload],
      };
    }
    case SET_STRATEGY_CURRENT_ACTION: {
      return {
        ...state,
        currentAction: {
          ...payload,
        },

        portalData: { isActive: true },
      };
    }

    case UPDATE_SRATEGY_ACTIONS: {
      let generalDetails = { ...state.generalDetails, ...payload };

      let { cond } = payload;

      let flowName = "strategic_flow";
      let strategicFlow = [];
      strategicFlow = cond;

      strategicFlow =
        strategicFlow && strategicFlow?.length > 0
          ? strategicFlow.map((e) => {
              let newValues = {
                ...e,
                nam: e.nam,
                des: e.des,
                wf: e.wf,
                wq: e.wq,
                rid: e.rid,
              };

              return newValues;
            })
          : [
              {
                ...emptyActionStructure,
                _id: `${cond.length}-temp`,
              },
            ];

      let currentAction = state.currentAction || {};

      if (flowName === "strategic_flow" && cond.length > 0) {
        currentAction = cond[0];
      }
      return {
        ...state,
        generalDetails,
        strategic_flow: strategicFlow,
        currentAction,
        isLoading: false,
      };
    }
    case SAVE_STRATEGY_CURRENT_ACTION: {
      let flowName = "strategic_flow";
      let strategicFlow = [...state[flowName]];

      const currentActionIndex = strategicFlow.findIndex((v) => {
        return v._id === payload._id;
      });

      strategicFlow[currentActionIndex] = {
        ...payload,
      };

      return {
        ...state,
        [flowName]: strategicFlow,
        currentAction: { ...payload },
        is_edited: true,
      };
    }
    case DRAG_AND_DROP_STRATEGY_ACTION: {
      return {
        ...state,
        strategic_flow: payload,
        // invoice_flow: payload,
        is_edited: true,
      };
    }

    case SET_STRATEGY_LIST: {
      return {
        ...state,
        strategic_flow: payload,
      };
    }

    case STRATEGY_INITIAL_STATE: {
      return {
        ...state,
        is_edited: payload,
      };
    }

    case ADD_STRATEGY_NEW_ACTION: {
      let flowName = "strategic_flow";
      let newAction = {
        ...emptyActionStructure,
        _id: `${state[flowName].length + 1}-temp`,
      };

      let newState = {
        ...state,
        [flowName]: [...state[flowName], newAction],
        currentAction: newAction,
        is_edited: false,
        // mappingIssuedDaysAfter,
        portalData: { isActive: true },
      };

      return newState;
    }

    case DELETE_STRATEGY_ACTION: {
      const DeleteElemenet = state["strategic_flow"].findIndex(
        (v) => v._id == payload
      );
      // state.customer_flow.splice(DeleteElemenet, 1);
      state["strategic_flow"].splice(DeleteElemenet, 1);
      state.currentAction = {
        ...state["strategic_flow"][0],
      };

      return {
        ...state,

        is_edited: true,
      };
    }

    case STRATEGY_FORM_UPDATE_ACTION: {
      return {
        ...state,
        generalDetails: payload?.generalDetails,
      };
    }

    default:
      return state;
  }
};

export default strategyMaintainenceReducers;
