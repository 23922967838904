// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import { rest } from "lodash";
import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";
const HOST = process.env.REACT_APP_HOST;
// const HOST = `http://192.168.1.56:3030/api/`;
const APP_CODE = process.env.REACT_APP_APP_CODE;
const HOSTSPACE = process.env.REACT_APP_HOST;

export const postFeeSubscription = async ({ data, organization }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/fee-subscription`,
    { ...data },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
export const putFeeSubscription = async ({
  data,
  organization,
  feeSubscribtionId,
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/fee-subscription/${feeSubscribtionId}`,
    { ...data },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getFeeSubscription = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/fee-subscription`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const removeFeeSubscription = async ({
  organization,
  feeSubscriptionId,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}organization/${organization}/fee-subscription/${feeSubscriptionId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const patchEnableDisableFeeSub = async ({
  organization,
  feeSubscribtionId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/fee-subscription/${feeSubscribtionId}`
  );
};

export const postOrganization = async ({ values, organization, DataType }) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/me?action=${DataType}`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

//TODO:
export const checkSMTPSetting = async ({ organization, values }) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/me`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const organizationList = async ({ organization, values }) => {
  return await axiosWithAuth.put(
    `${HOST}organization${organization}/`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getOrganization = async ({ organization }) => {
  return await axiosWithAuth.get(`${HOST}organization/${organization}/me`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getStripeConfigurationUrl = async ({
  organization,
  action,
  filters = {},
}) => {
  return await axiosWithAuth.get(`${HOST}organization/${organization}/me`, {
    params: {
      action: action,
      ...filters,
    },
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const contactStatusSettings = async ({
  formData,
  organization,
  action,
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/me`,
    formData,
    {
      params: {
        action: action,
      },
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getSubscriptionPlans = async () => {
  return await axiosWithAuth.get(`${HOST}subscription-plan`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const createSubscription = async ({
  organization,
  planId,
  referral_id,
}) => {
  return await axiosWithAuth.post(
    `${HOSTSPACE}subscription-plan/subscribe`,
    {
      plan_id: planId,
      referral_id,
    },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const trigger = async ({ organizationId }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organizationId}/sync`,
    {
      sync_organization_id: organizationId,
      organizationId: organizationId,
      is_full_sync: true,
    }
  );
};

export const disconnectBook = async ({ organizationId }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organizationId}/disconnect`
  );
};

export const addCreditAmount = async ({ organization, amount }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/credit-amount`,
    {
      credit_amount: amount,
    },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const addOrganization = async ({ organization, values }) => {
  return await axiosWithAuth.post(`${HOSTSPACE}organization`, values, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const uploadLogoOrganization = async ({ organization, formData }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/upload-logo`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const removeLogoOrganization = async ({ organization }) => {
  return await axiosWithAuth.delete(
    `${HOST}organization/${organization}/remove-logo`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getProviders = async ({ organization }) => {
  return await axiosWithAuth.get(`${HOST}provider/SMS`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getCloudTelePhonyProviders = async ({ organization }) => {
  return await axiosWithAuth.get(`${HOST}provider/CALL`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getWhatsAppProviders = async ({ organization }) => {
  return await axiosWithAuth.get(`${HOST}provider/WHATSAPP`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getOutboundEmailSettings = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/outbound-email-settings`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const createOutboundEmailSettings = async ({
  organization,
  ...data
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/outbound-email-settings`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getOutboundEmailProviders = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/outbound-email-providers`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const disableOutboundEmailSettings = async ({
  organization,
  providerId,
  is_enabled,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/outbound-email-settings/${providerId}`,
    {
      is_enabled,
    }
  );
};

export const deleteOutboundEmailSettings = async ({
  organization,
  providerId,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}organization/${organization}/outbound-email-settings/${providerId}`
  );
};

export const defaultOutboundEmailSettings = async ({
  organization,
  providerId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/outbound-email-settings/${providerId}/mark-as-default`
  );
};

export const verifyDNSProvider = async ({ organization, providerId }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/outbound-email-settings/${providerId}/verify`
  );
};

export const paymentProvider = async ({ organization, providerId }) => {
  return await axiosWithAuth.get(`${HOST}provider/PAYMENT`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const createPaymentDtl = async ({ organization, ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/payment`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const updatePaymentDtl = async ({
  organization,
  paymentId,
  ...data
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/payment/${paymentId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const deletePayment = async ({ organization, paymentId }) => {
  return await axiosWithAuth.delete(
    `${HOST}organization/${organization}/payment/${paymentId}`
  );
};

export const paymentAudit = async ({ organization, paymentId }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/payment/${paymentId}/audit`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const paymentActive = async ({ organization, paymentId }) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/set-payment/${paymentId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const uploadPaymentLogoOrganization = async ({
  organization,
  formData,
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/payment/logo`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const postOrganizationData = async ({
  organization,
  DataType,
  values,
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/me?action=${DataType}`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const putCustomizeLable = async ({ organization, values, DataType }) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/me?action=${DataType}`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const customerPortalLogo = async ({ organization, formData }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/customer-ui-logo`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const deleteCustomerLogo = async ({ organization, paymentId }) => {
  return await axiosWithAuth.delete(
    `${HOST}organization/${organization}/customer-ui-logo`
  );
};

// export const colorCustomize = async ({ organization, paymentId }) => {
//   return await axiosWithAuth.put(
//     `${HOST}organization/${organization}/set-payment/${paymentId}`,
//     {
//       headers: {
//         appCode: APP_CODE,
//       },
//     }
//   );
// };

export const invoiceTaxRate = async ({ organization, ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/invoice-tax-rate`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const updateInvoiceTaxRate = async ({
  invoiceTaxId,
  organization,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/invoice-tax-rate/${invoiceTaxId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const deleteInvoiceTaxRate = async ({ invoiceTaxId, organization }) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/invoice-tax-rate/${invoiceTaxId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const invTaxRateMaintenanceList = async ({
  name,
  organization = null,
  filters = {},
  sortBy,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/invoice-tax-rate`,
    {
      params: {
        name: name,
        sort: sortBy,
        ...filters,
      },
    },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const invNatureOfTax = async ({ name, organization = null }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/invoice-nature-of-tax`
  );
};

export const auditInvoiceTaxRate = async ({ id, organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/invoice-tax-rate/${id}/audits`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getContactStatusSettings = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/contact-status-settings`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const smsGatewayAdd = async ({ data, organization }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/sms-configuration`,
    data
  );
};
export const smsGatewayedit = async ({ data, organization, smsConfigId }) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/sms-configuration/${data?.id}`,
    data?.value
  );
};
export const smsGatewayretrive = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/sms-configuration`
  );
};
export const smsGatewayenabledefault = async ({
  organization,
  smsConfigId,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/sms-configuration/${smsConfigId}`
  );
};
export const letterStatusDownload = async ({
  organization,
  entity,
  filters = {},
  customerFilter = {},
}) => {
  return await axiosWithAuth
    .post(
      `${HOST}organization/${organization}/letter-status/download/${entity}`,
      customerFilter,
      {
        headers: {
          appCode: APP_CODE,
        },
        params: {
          ...filters,
        },
      }
    )
    .then((blob) => {
      download({ fileName: "Letter Status.csv", fileData: blob.data });
    });
};

export const letterStatusList = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize = 20,
  filters = {},
  customerFilter = {},
  entity,
}) => {
  return await axiosWithAuth.post(
    `${process.env.REACT_APP_HOST}organization/${organization}/letter-status/${entity}`,
    customerFilter,
    {
      params: {
        page: pageIndex,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },
    }
  );
};

export const skipTracedefault = async ({ organization, traceConfigId }) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/skip-trace-configuration/${traceConfigId}`
  );
};

export const getSkipTraceProvider = async () => {
  return await axiosWithAuth.get(`${HOST}provider/SKIP_TRACE`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const postSkipTraceProvider = async ({ organization, ...data }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/skip-trace-configuration`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const LetterUpdateStates = async ({ data, organization, entity }) => {
  return await axiosWithAuth.put(
    `${process.env.REACT_APP_HOST}organization/${organization}/letter-status/multi-update/${entity}`,
    data
  );
};

export const LetterDownloadFile = async ({
  update_letters,
  organization,
  entity,
  delivery_method,
}) => {
  return await axiosWithAuth.post(
    `${process.env.REACT_APP_HOST}organization/${organization}/letter-status/email-download-link/${entity}`,
    { update_letters: update_letters, delivery_method: delivery_method }
  );
};

export const putSkipTraceProvider = async ({
  organization,
  skipId,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/skip-trace-configuration/${skipId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getSkipTraceList = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/skip-trace-configuration`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const postPaymentPriorityBilling = async ({ organization, values }) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/payment-priority`,
    values,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getPaymentPriorityBilling = async ({ organization }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/payment-priority`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const letterVendor = async ({ organization, formData }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/letter-vendor`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const editLetterVendor = async ({
  organization,
  vendorId,
  formData,
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/letter-vendor/${vendorId}`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const testLetterVendorFn = async ({ organization, formData }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/letter-vendor/verify`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getLetterProviders = async ({ organization }) => {
  return await axiosWithAuth.get(`${HOST}provider/LETTER`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};
