function CallUnsuccessfull() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6268 13.9889L15.2985 13.7231C14.7393 13.6589 14.1893 13.8514 13.7951 14.2456L12.1085 15.9323C9.51431 14.6123 7.38764 12.4948 6.06764 9.89143L7.76347 8.1956C8.15764 7.80143 8.35014 7.25143 8.28597 6.69227L8.02014 4.38227C7.91014 3.45643 7.13097 2.75977 6.19597 2.75977H4.61014C3.57431 2.75977 2.71264 3.62143 2.7768 4.65727C3.26264 12.4856 9.52347 18.7373 17.3426 19.2231C18.3785 19.2873 19.2401 18.4256 19.2401 17.3898V15.8039C19.2493 14.8781 18.5526 14.0989 17.6268 13.9889V13.9889Z"
          style={{ fill: "#516BEB" }}
        />
        <g clip-path="url(#clip0_4045_47825)">
          <path
            d="M17.3221 4.66941C17.2372 4.58415 17.1363 4.51649 17.0251 4.47032C16.914 4.42415 16.7949 4.40039 16.6746 4.40039C16.5542 4.40039 16.4351 4.42415 16.324 4.47032C16.2129 4.51649 16.112 4.58415 16.0271 4.66941L14.2996 6.40528L12.5612 4.66941C12.4762 4.58438 12.3752 4.51693 12.2642 4.47092C12.1531 4.4249 12.034 4.40121 11.9137 4.40121C11.7935 4.40121 11.6744 4.4249 11.5633 4.47092C11.4522 4.51693 11.3513 4.58438 11.2662 4.66941C11.1812 4.75444 11.1138 4.85538 11.0677 4.96648C11.0217 5.07758 10.998 5.19665 10.998 5.3169C10.998 5.43715 11.0217 5.55622 11.0677 5.66732C11.1138 5.77841 11.1812 5.87936 11.2662 5.96439L13.0021 7.70109L11.2662 9.43696C11.1812 9.52199 11.1138 9.62294 11.0677 9.73403C11.0217 9.84513 10.998 9.9642 10.998 10.0845C10.998 10.2047 11.0217 10.3238 11.0677 10.4349C11.1138 10.546 11.1812 10.6469 11.2662 10.7319C11.3513 10.817 11.4522 10.8844 11.5633 10.9304C11.6744 10.9765 11.7935 11.0001 11.9137 11.0001C12.034 11.0001 12.1531 10.9765 12.2642 10.9304C12.3752 10.8844 12.4762 10.817 12.5612 10.7319L14.2971 8.99607L16.0338 10.7319C16.1188 10.817 16.2198 10.8844 16.3309 10.9304C16.442 10.9765 16.561 11.0001 16.6813 11.0001C16.8015 11.0001 16.9206 10.9765 17.0317 10.9304C17.1428 10.8844 17.2437 10.817 17.3288 10.7319C17.4138 10.6469 17.4813 10.546 17.5273 10.4349C17.5733 10.3238 17.597 10.2047 17.597 10.0845C17.597 9.9642 17.5733 9.84513 17.5273 9.73403C17.4813 9.62294 17.4138 9.52199 17.3288 9.43696L15.5954 7.70109L17.3313 5.96439C17.4998 5.79042 17.5933 5.55722 17.5916 5.31501C17.5898 5.0728 17.4931 4.84095 17.3221 4.66941V4.66941Z"
            style={{ fill: "#516BEB" }}
          />
        </g>
        <defs>
          <clipPath id="clip0_4045_47825">
            <rect
              width="6.6"
              height="6.6"
              fill="white"
              transform="translate(11 4.40039)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default CallUnsuccessfull;
