import React from 'react'

const CallOutLine = ({size=19,color="#333860"}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.6948 15.2244C13.3319 15.2244 11.9548 14.9018 10.5637 14.2566C9.17264 13.6114 7.89236 12.7004 6.72289 11.5235C5.54717 10.3528 4.63731 9.07311 3.99332 7.68441C3.34934 6.29572 3.02734 4.91869 3.02734 3.55331C3.02734 3.33388 3.1009 3.14969 3.24801 3.00075C3.39514 2.85181 3.57904 2.77734 3.79971 2.77734H6.13981C6.32923 2.77734 6.49281 2.83504 6.63055 2.95043C6.7683 3.06581 6.85881 3.21749 6.90207 3.40547L7.33188 5.55159C7.36169 5.72997 7.35381 5.89146 7.30825 6.03608C7.26269 6.18068 7.18805 6.30321 7.08434 6.40367L5.39269 8.06049C5.67539 8.57107 5.99215 9.052 6.34296 9.50329C6.69378 9.95458 7.07317 10.3833 7.48116 10.7894C7.88261 11.1932 8.31291 11.571 8.77204 11.9226C9.23118 12.2743 9.7307 12.6052 10.2706 12.9153L11.9692 11.2177C12.0801 11.1019 12.2038 11.0239 12.3403 10.9837C12.4769 10.9436 12.6281 10.9341 12.7939 10.9552L14.8301 11.3637C15.0202 11.4077 15.1751 11.5023 15.2949 11.6475C15.4146 11.7927 15.4744 11.9574 15.4744 12.1415V14.4521C15.4744 14.6727 15.4002 14.8566 15.2517 15.0038C15.1032 15.1509 14.9176 15.2244 14.6948 15.2244ZM4.89079 7.07181L6.22926 5.79103C6.2509 5.77181 6.26532 5.74536 6.27252 5.7117C6.27973 5.67805 6.27973 5.6468 6.27252 5.61795L5.93719 3.942C5.92758 3.90354 5.91075 3.87469 5.8867 3.85545C5.86266 3.83621 5.83141 3.82659 5.79295 3.82659H4.19849C4.16724 3.82659 4.14259 3.83621 4.12456 3.85545C4.10653 3.87469 4.09752 3.89872 4.09752 3.92756C4.12588 4.42997 4.20665 4.94813 4.33982 5.48201C4.473 6.01591 4.65666 6.54584 4.89079 7.07181ZM11.2643 13.4021C11.7528 13.6338 12.2674 13.811 12.8081 13.9336C13.3488 14.0562 13.8542 14.129 14.3242 14.1521C14.353 14.1521 14.3771 14.1425 14.3963 14.1232C14.4156 14.104 14.4252 14.08 14.4252 14.0511V12.4732C14.4252 12.4348 14.4156 12.4035 14.3963 12.3795C14.3771 12.3554 14.3482 12.3386 14.3098 12.329L12.7651 11.9994C12.7362 11.9922 12.711 11.9922 12.6894 11.9994C12.6677 12.0066 12.6449 12.0211 12.6208 12.0427L11.2643 13.4021Z" fill={color} />
    </svg>


  )
}

export default CallOutLine