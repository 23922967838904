import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

function UserAndConsumer(props) {
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  value = _.get(original, accessor, "");

  return (
    <div
      style={{
        // display: "flex",
        // flexDirection: "row",
        // gap: "5px",
        // flex: "1",
        // justifyContent: alignment,
        // alignItems: "center",
        textAlign: alignment,
        width: "100%",
      }}
    >
      {!value ? "Consumer" : value}
    </div>
  );
}

export default UserAndConsumer;
