import React, { useState, useMemo, useEffect, useContext } from "react";
import "../../assets/css/customer-overview.css";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import Invoice from "./COTabContainers/Invoice";
import Actions from "./COTabContainers/Actions/Actions";
import Timeline from "./COTabContainers/Timeline";
import DairyNotes from "./COTabContainers/DairyNotes";
import Payment from "./COTabContainers/Payment";
import CustomerPortal from "./COTabContainers/CustomerPortal";
import { useSelector, useDispatch } from "react-redux";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { tabMotion, resetCustomerOverviewState } from "../../redux/actions";
import { resetPaymentState } from "../../redux/customerOverview/payment/action";
import { LabelSmall } from "baseui/typography";
import ContactContainer from "./ContactContainer";
import DairyNotesAlert from "./COTabContainers/DairyNotesAlert";
import { editDairyNote } from "../../services";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  CUSTOMER_WF_ACTION,
  DISMISS_CUSTOMER_NOTES,
} from "../../providers/RBACProvider/permissionList";
import MoreInfo from "./COTabContainers/MoreInfo";
import { useIntl } from "react-intl";

// Table
const CustomerOverviewCardTable = ({ setSelectedInvoiceId, getParams }) => {
  let { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);

  const intl = useIntl();

  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const downArrowClicked = useSelector(
    (s) => s.customerOverviewReducer.noteAlert
  );
  const tabStatus = useSelector((s) => s.customerOverviewReducer.tabMotion);
  const dispatch = useDispatch();

  //TODO: COMMENTED FOR TESTING - REVERT ON PROD
  // useEffect(() => {
  //   const setReduxToInitial = () => {
  //     dispatch(resetCustomerOverviewState());
  //   };

  //   return setReduxToInitial;
  // }, []);

  // useEffect(() => {
  //   const setPaymentReduxToInitial = () => {
  //     dispatch(resetPaymentState());
  //   };

  //   return setPaymentReduxToInitial;
  // }, []);

  const alertPopUpDatas = useSelector(
    (s) => s.customerOverviewReducer.customerDetails.alert_notes
  );

  const workflowActions =
    space && space?.role_permissions?.includes("customer_wf_action");
  // Table
  return (
    <div className="customer-profile-tab">
      <div className="cp__tab__container">
        <Tabs
          fill={FILL.fixed}
          overrides={{
            Root: {
              style: ({ $isActive }) => ({
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }),
            },
            TabBorder: {
              style: ({ $isActive }) => ({
                height: "2px",
              }),
            },
            TabList: {
              style: ({ $theme }) => ({ height: "50px" }),
            },
            TabHighlight: {
              style: ({}) => ({
                borderRadius: "3px",
                backgroundColor: "#516BEB",
              }),
            },
          }}
          activeKey={tabStatus}
          onChange={({ activeKey }) => {
            dispatch(tabMotion(activeKey));
          }}
          // onChange={()=>{
          //   dispatch(tabMotion(0))
          // }}

          activateOnFocus
        >
          <Tab
            title={intl.formatMessage({ id: "invoices" })}
            overrides={{
              Root: {
                style: () => ({}),
              },
              TabPanel: {
                style: () => ({
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  // height: "100%",
                  overflow: "hidden",
                }),
              },
            }}
          >
            <Invoice
              getParams={getParams}
              setSelectedInvoiceId={setSelectedInvoiceId}
            />
          </Tab>
          <Tab
            title={intl.formatMessage({ id: "timeline" })}
            overrides={{
              TabPanel: {
                style: () => ({
                  height: "100%",
                  borderTop: "0.1px solid #E2E2E2",
                }),
              },
            }}
          >
            <Timeline getParams={getParams} customerId={getParams} />
          </Tab>
          {/* <RBACWrapper role={CUSTOMER_WF_ACTION} type="PERMISSON"> */}

          <Tab
            // title="Actions"
            title={intl.formatMessage({ id: "workflow_action" })}
            overrides={{
              TabPanel: {
                style: () => ({
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  overflow: "hidden",
                }),
              },
            }}
            disabled={!workflowActions}
          >
            <Actions getParams={getParams} />
          </Tab>

          {/* </RBACWrapper> */}
          {/* <Tab
            title="Notes"
            overrides={{
              TabPanel: {
                style: () => ({
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  overflow: "hidden",
                  height: "100%",
                }),
              },
            }}
          >
            <DairyNotes getParams={props.getParams} />
          </Tab> */}

          <Tab
            title={intl.formatMessage({ id: "payments" })}
            overrides={{
              TabPanel: {
                style: () => ({
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  overflow: "hidden",
                  height: "100%",
                }),
              },
            }}
          >
            <Payment getParams={getParams} />
          </Tab>

          {/* More Start */}
          <Tab
            title={intl.formatMessage({ id: "More_Info" })}
            overrides={{
              TabPanel: {
                style: () => ({
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  overflow: "hidden",
                  height: "100%",
                }),
              },
            }}
          >
            <MoreInfo getParams={getParams} />
          </Tab>
        </Tabs>
      </div>

      <div style={{ display: "flex", flexDirection: "column", width: "216px" }}>
        {downArrowClicked ? (
          <>
            <div
              style={{
                // position: "absolute",
                // zIndex: "1",
                right: "0%",
                height: "85%",
                // height: "12%",
                overflow: "scroll",
              }}
            >
              <DairyNotesAlert
                alertPopUpDatas={alertPopUpDatas}
                editDairyNote={editDairyNote}
                entity={"customer"}
                getParams={getParams}
                dismissExit={DISMISS_CUSTOMER_NOTES}
              />
            </div>
          </>
        ) : (
          <>
            <div
              style={
                {
                  // position: "absolute",
                  // zIndex: "1",
                  // right: "0%",
                  // height: "82%",
                  // height: "13%",
                  // overflow: "scroll",
                  // paddingRight: "6px",
                }
              }
            >
              <DairyNotesAlert
                alertPopUpDatas={alertPopUpDatas}
                editDairyNote={editDairyNote}
                entity={"customer"}
                getParams={getParams}
                dismissExit={DISMISS_CUSTOMER_NOTES}
              />
            </div>
          </>
        )}

        <div className="cp__contact__container_right">
          <div className="contact__details__wraper">
            <ContactContainer getParams={getParams} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOverviewCardTable;
