import { Drawer } from "baseui/drawer";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import RequestInformation_V2 from "../../containers_v2/CustomerOverview/RequestInformation_V2";
import Dispute from "../../containers_v2/CustomerOverview/Dispute";
import AddPayment from "../CustomerOverView/COTabContainers/AddPayment";

const LIST = "LIST";
const ADD = "ADD";
const EDIT = "EDIT";
const VIEW = "VIEW";
const initialState = {
  type: LIST,
  resourceId: null,
  resourceName: "",
  isDispute: false,
};

function RequestDrawer({
  setIsRequest,
  active,
  resourceId,
  resourceName,
  isDispute = false,
  isAddPayment = false,
}) {
  // const [getSearchParams, setGetSearchParams] = useSearchParams();
  // const customerId = getSearchParams.get("customers");
  // const [state, setState] = useState(initialState);

  // const setAdd = (isAdd = true) => {
  //   setState({ ...state, type: isAdd ? ADD : LIST });
  // };

  // const setResource = (id, name, isEdit = false) => {
  //   setState({
  //     ...state,
  //     type: isEdit ? EDIT : VIEW,
  //     resourceId: id,
  //     resourceName: name,
  //   });
  // };

  // const reset = () => {
  //   if (resourceId && resourceName) {
  //     setIsRequest();
  //   } else {
  //     setState({ ...initialState });
  //   }
  // };

  // useEffect(() => {
  //   if (resourceId && resourceName) {
  //     setResource(resourceId, resourceName, true);
  //   }

  //   return () => {
  //     if (resourceId && resourceName) {
  //       getSearchParams.delete("customers");
  //       setGetSearchParams(getSearchParams.toString());
  //     }
  //   };
  // }, []);

  return (
    <>
      <Drawer
        autoFocus
        onClose={() => {
          setIsRequest(false);
        }}
        size="auto"
        isOpen={active}
        onBackdropClick={() => {}}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "15px",
              marginRight: "0px",
              marginBottom: "15px",
              marginTop: "0px",
              paddingRight: "15px",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
        }}
      >
        {isDispute ? (
          <Dispute
            setIsRequest={setIsRequest}
            active={active}
            resourceId={resourceId}
            resourceName={resourceName}
          />
        ) : (
          <RequestInformation_V2
            setIsRequest={setIsRequest}
            active={active}
            resourceId={resourceId}
            resourceName={resourceName}
          />
        )}
        {isAddPayment ? (
          <AddPayment
            setIsRequest={setIsRequest}
            active={active}
            resourceId={resourceId}
            resourceName={resourceName}
          />
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
}

export default RequestDrawer;
