import React from "react";
const PpKept = ({ width = "17", height = "15", color = "#0FB158" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.42497 14.7499C5.07913 14.7499 4.78434 14.628 4.54061 14.3843C4.29686 14.1405 4.17498 13.8457 4.17498 13.4999V5.60614C4.17498 5.44781 4.20727 5.29261 4.27184 5.14052C4.33643 4.98845 4.42498 4.85616 4.53748 4.74366L8.48122 0.668645C8.66038 0.493645 8.87393 0.387395 9.12185 0.349895C9.36976 0.312395 9.60414 0.356145 9.82498 0.481145C10.0458 0.606145 10.2093 0.781145 10.3156 1.00614C10.4218 1.23114 10.4479 1.46239 10.3937 1.69989L9.63748 5.09989H15C15.325 5.09989 15.6146 5.22698 15.8688 5.48115C16.1229 5.73531 16.25 6.0249 16.25 6.3499V7.87489C16.25 7.99573 16.2365 8.13323 16.2094 8.28739C16.1823 8.44156 16.1417 8.57907 16.0875 8.69991L13.8875 13.8124C13.775 14.0707 13.5896 14.2916 13.3312 14.4749C13.0729 14.6582 12.8 14.7499 12.5125 14.7499H5.42497ZM1.71249 14.7499C1.38333 14.7499 1.09895 14.6301 0.859363 14.3905C0.619788 14.1509 0.5 13.8666 0.5 13.5374V6.3124C0.5 5.98323 0.619788 5.69886 0.859363 5.45927C1.09895 5.21968 1.38333 5.09989 1.71249 5.09989C2.04165 5.09989 2.32603 5.21968 2.56561 5.45927C2.80519 5.69886 2.92498 5.98323 2.92498 6.3124V13.5374C2.92498 13.8666 2.80519 14.1509 2.56561 14.3905C2.32603 14.6301 2.04165 14.7499 1.71249 14.7499Z"
        fill={color}
      />
    </svg>
  );
};
export default PpKept;
