import React from "react";

const Warning = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.000244141C2.912 0.000244141 0 2.91224 0 6.50024C0 10.0882 2.912 13.0002 6.5 13.0002C10.088 13.0002 13 10.0882 13 6.50024C13 2.91224 10.088 0.000244141 6.5 0.000244141ZM6.5 7.15024C6.1425 7.15024 5.85 6.85774 5.85 6.50024V3.90024C5.85 3.54274 6.1425 3.25024 6.5 3.25024C6.8575 3.25024 7.15 3.54274 7.15 3.90024V6.50024C7.15 6.85774 6.8575 7.15024 6.5 7.15024ZM7.15 9.75024H5.85V8.45024H7.15V9.75024Z"
        fill="#FD372A"
      />
    </svg>
  );
};

export default Warning;
