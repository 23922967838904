import { faSlash } from "@fortawesome/free-solid-svg-icons";
import { NEW } from "../../../constants";
import {
  GET_DISPUTE_DATA,
  CHANGED_DISPUTE,
  NEW_DISPUTE,
  SELECTED_INVOICE,
  SWITCH_BACK_EMPTY_STATE,
  DELETE_ROW_INVOICE,
  RESET_DISPUTE_STATE,
  selectedInvoice,
  SELECT_DISPUTE_INVOICE_ISOPEN,
} from "./action";

const initialState = {
  disputeData: [],
  currentDisputeData: {},
  disputeType: null,
  selectedInvoice: [],
  deleteRowData: null,
  isOpen: false,
};

const customerDisputeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DISPUTE_DATA: {
      const lastElement = payload?.length - 1;
      return {
        ...state,
        disputeData: payload,
        disputeType: payload?.length === 0 ? "NEW" : null,
        currentDisputeData: { ...payload[lastElement] },
      };
    }

    case RESET_DISPUTE_STATE: {
      return initialState;
    }
    case SELECT_DISPUTE_INVOICE_ISOPEN: {
      return {
        ...state,
        isOpen: payload,
      };
    }

    case CHANGED_DISPUTE: {
      const findingVal = state.disputeData.find((i) => i._id === payload);

      return {
        ...state,
        currentDisputeData: findingVal,
        // disputeData: payload,
      };
    }

    case NEW_DISPUTE: {
      return {
        ...initialState,
        disputeType: payload,
        currentDisputeData: {
          invoices: [],
        },
      };
    }

    case SELECTED_INVOICE: {
      let rowSelected = payload.map((i) => i.original._id);
      // let rowSelected = payload.map((i) => i._id);
      // const finalDatas = state.currentDisputeData.invoices.filter(
      //   (i) => i != payload
      // );

      let isSelectOpen =
        payload.length > 0 ? (state.isOpen = false) : (state.isOpen = true);

      const invoices = state.currentDisputeData.invoices || [];

      return {
        ...state,
        currentDisputeData: {
          ...state.currentDisputeData,
          // invoices: [...state.currentDisputeData.invoices, ...rowSelected],
          invoices: [...invoices, ...rowSelected],
        },
        isOpen: isSelectOpen,
      };
    }

    case DELETE_ROW_INVOICE: {
      const finalDatas = state.currentDisputeData.invoices.filter(
        (i) => i != payload.original._id
      );

      return {
        ...state,
        currentDisputeData: {
          ...state.currentDisputeData,
          invoices: [...finalDatas],
        },
      };
    }
    case SWITCH_BACK_EMPTY_STATE: {
      return {
        ...state,
        disputeData: null,
        currentDisputeData: null,
        disputeType: null,
        selectedInvoice: [],
      };
    }
    default:
      return state;
  }
};

export default customerDisputeReducer;
