import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  ACT_CALL_BACK_LATER,
  CO_INVOICES_,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import {
  getCallbackList,
  getHolidayBlackoutHolidayDate,
} from "../../../../services";
import { useDebouncedCallback } from "use-debounce";
import { Icon, Typography } from "../../../../components_v2";
import PromiseStatusTypes from "../PromiseStatusTypes";
import CallBackStatusTypes from "../CallBackStatusTypes";
import useFormat from "../../../../hooks/useFormat";

const CallBackLaterForm = (props) => {
  const {
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isIncrementor = true,
    isContent = true,
    is_inbound_call = false,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();
  const [text, setAlertPromise] = useState(false);
  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      callback_date: new Date(moment().add(1, "days")),
    },
  });
  const [callBackStatus, setCallBackStatus] = useState(false);
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);
  const consumerGroupPromiseText = _.get(invoicesData, "data.docs", []).some(
    (obj) => obj.hasOwnProperty("pgid")
  );
  const [callbackDate] = watch(["callback_date"]);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: [
            moment(callbackDate)
              .tz(currentDefaultFormatDetails.time_zone)
              .startOf("day")
              .valueOf(),
          ],
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: callbackDate ? true : false,
    }
  );
  const useCallbackListQuery = (organization, customerId) => {
    // Define the fetch function
    const fetchCallbackList = async () => {
      return await getCallbackList({ organization, customerId });
    };

    // Check if data is already in cache
    const queryKey = [`GET_CALL_BACK_LIST_${organization}_${customerId}`];
    const cachedData = queryClient.getQueryData(queryKey);

    // Use useQuery to fetch data if it's not already cached
    return useQuery(queryKey, fetchCallbackList, {
      initialData: cachedData,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!organization && !!customerId, // Only run if organization and customerId are provided
    });
  };
  const {
    data: callBackData,
    isFetched: isFetchCalback,
    isError: isErrorCallback,
    isLoading: isLoadCallBack,
  } = useCallbackListQuery(currentOrganization, customerId);

  const format = useFormat();
  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(
          `GET_CALL_BACK_LIST_${currentOrganization}_${customerId}`
        );
        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    const { callback_date, callback_time } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...formValues,
      ...(isContent ? { content: "-" } : {}),
      callback_date: moment
        .tz(
          `${callback_date.getFullYear()}-${
            callback_date.getMonth() + 1
          }-${callback_date.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      callback_time: getSelectValues(callback_time),
      ...getActionReminder(data, {
        is_calendar: false,
        is_consent_enabled: props.isConsent,
        is_incrementor: props.isIncrementor,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };
  let timeSolt = _.get(
    callBackData,
    "data.doc.callback_data.callback_time",
    null
  );
  let slicedVal = timeSolt?.replace("_", ":");
  let slicedFinalVal = slicedVal?.replace("_", " ");
  return (
    <>
      {_.get(callBackData, "data.doc.is_callback_pending", "") &&
      !callBackStatus ? (
        <CallBackStatusTypes
          setCallBackStatus={setCallBackStatus}
          alertCallBack={
            "*The Consumer have already chooses call back later option"
          }
          textType={`Call Back Later on ${format.date({
            value: _.get(
              callBackData,
              "data.doc.callback_data.callback_date",
              null
            ),
          })}  ${slicedFinalVal ? "at" : ""} ${
            slicedFinalVal ? slicedFinalVal : ""
          }`}
          monthCallBackData={_.get(callBackData, "data.doc.callback_data", "")}
          setAlertPromise={text}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: "flex",
              gap: "5px",
              marginTop: "20px",
              width: "300px",
            }}
          >
            <Controller
              name="callback_date"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <DateRangeSelect
                  {...field}
                  name={field.name}
                  size={SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  requiredAstric={true}
                  label={intl.formatMessage({
                    id: "call_back_date",
                  })}
                  placeholder={intl.formatMessage({
                    id: `${currentDefaultFormatDetails.date_format}`,
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.date);
                  }}
                  minDate={new Date()}
                />
              )}
            />

            <Controller
              name="callback_time"
              control={control}
              render={({ field }) => (
                <SelectBox
                  clearable={false}
                  style={{ marginTop: "0px" }}
                  {...field}
                  name={field.name}
                  size={SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "call_back_time",
                  })}
                  placeholder={intl.formatMessage({
                    id: "call_back_time",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["time"] || []}
                />
              )}
            />
          </div>

          {_.get(data, "data.doc.is_holiday") && (
            <>
              <div className="alert-holiday-section">
                <Icon icon="alert_circle" color="#516BEB" />
                <Typography
                  type="p"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  The Date Selected{" "}
                  {_.get(data, "data.doc.holidays", []).map((val) => {
                    return (
                      <>
                        {moment(val).format(
                          currentDefaultFormatDetails.date_format
                        )}
                      </>
                    );
                  })}{" "}
                  is a <b>“Holiday”</b>
                </Typography>
              </div>
            </>
          )}

          <ActionReminders
            {...props}
            watch={watch}
            control={control}
            errors={errors}
            setValue={setValue}
            actionType={formValues.action_type}
            is_inbound_call={
              formValues.is_inbound_call ? true : false || is_inbound_call
            }
            outcome={ACT_CALL_BACK_LATER}
            is_calendar={false}
            consentDate={callbackDate}
            callValidationDuration={_.get(
              customerData,
              "data.doc.mini_miranda.call_validation_duration"
            )}
            timeZone={_.get(customerData, "data.doc.timezone")}
            is_incrementor={props.isIncrementor}
            is_consent_enabled={props.isConsent}
            is_valid_from_contact={
              formValues.action_type === "FIELD_VISIT" ||
              formValues.action_type === "ACTION_CAPTURE"
                ? true
                : false
            }
          />

          <div
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <TextButton
              kind="tertiary"
              size="mini"
              type="button"
              disabled={serviceMutation.isLoading}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </TextButton>
            <TextButton
              size="mini"
              disabled={serviceMutation.isLoading}
              isLoading={serviceMutation.isLoading}
            >
              Save
            </TextButton>
          </div>
        </form>
      )}
    </>
  );
};

export default CallBackLaterForm;
