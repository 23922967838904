import React from "react";

const CustomerNameSvg = ({ size = "20" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3636 10.2333C12.1623 10.1748 11.9645 10.1232 11.7701 10.0716C10.7809 9.82391 9.78478 9.69662 8.77823 9.69662C7.77167 9.69662 6.77206 9.81703 5.77592 10.0578C4.77978 10.2986 3.73852 10.6599 2.65214 11.1415C2.16621 11.3651 1.77053 11.6953 1.46163 12.1288C1.15272 12.5622 1 13.0851 1 13.694V14.5128C1 14.9153 1.14231 15.2559 1.42345 15.5379C1.70806 15.82 2.05167 15.9576 2.45777 15.9576H10.3471C10.2082 15.4898 10.1319 14.9944 10.1319 14.4784C10.1319 12.7274 11.0135 11.1724 12.3636 10.2333ZM11.4057 2.0114C10.7254 1.33713 9.85073 1 8.77823 1C7.7092 1 6.83454 1.33713 6.15425 2.0114C5.47396 2.68566 5.13381 3.55257 5.13381 4.61557C5.13381 5.67512 5.47396 6.54203 6.15425 7.2163C6.83454 7.89056 7.7092 8.22769 8.77823 8.22769C9.84726 8.22769 10.7219 7.89056 11.4057 7.2163C12.086 6.54203 12.4261 5.67512 12.4261 4.61557C12.4261 3.55257 12.086 2.68566 11.4057 2.0114ZM12.9433 14.4165C13.0439 14.3029 12.9606 14.1275 12.8114 14.1275H12.3567C12.3532 14.0828 12.3532 14.0381 12.3532 13.9933C12.3532 12.8719 13.2765 11.9568 14.408 11.9568C14.9529 11.9568 15.4492 12.1666 15.8172 12.5106L16.4593 11.7676C15.897 11.2584 15.175 10.9798 14.4115 10.9798C13.5993 10.9798 12.8357 11.2928 12.263 11.8605C11.6903 12.4281 11.3744 13.1849 11.3744 13.9899C11.3744 14.0346 11.3744 14.0793 11.3779 14.1241H10.9961C10.8434 14.1241 10.7636 14.2995 10.8642 14.413L11.7285 15.3969L11.909 15.6033L12.5615 14.8637L12.9433 14.4165ZM17.9552 13.842L17.3096 13.1092L16.907 12.6517L16.3378 13.3053L15.8657 13.842C15.7651 13.9555 15.8484 14.1309 15.9976 14.1309H16.4523C16.3794 15.1871 15.4874 16.023 14.4045 16.023C13.9325 16.023 13.4951 15.8648 13.1481 15.5964L12.5059 16.3395C13.0439 16.7661 13.7069 17 14.4045 17C15.2167 17 15.9803 16.6869 16.553 16.1193C17.0944 15.5827 17.4033 14.8843 17.4415 14.1309H17.8233C17.976 14.1344 18.0559 13.9555 17.9552 13.842Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default CustomerNameSvg;
