import React from "react";

const UnPin = ({ size = 22 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3719 8.15029L13.6594 4.43779C13.0442 3.83662 12.1055 3.87178 11.5395 4.43779C10.9735 5.00381 10.9348 5.94951 11.5395 6.55772L11.8032 6.82139L10.8856 7.74248L11.4165 8.27334L12.6012 7.08858C12.7559 6.93389 12.7348 6.69483 12.6012 6.55772L12.0704 6.02686C11.768 5.71397 11.7926 5.24287 12.0704 4.96514C12.3622 4.67334 12.8368 4.67334 13.1321 4.96514L16.8446 8.67764C17.1504 8.99404 17.1223 9.46162 16.8446 9.73936C16.5528 10.0312 16.0782 10.0312 15.7829 9.73936L15.252 9.21201C15.0903 9.05381 14.8583 9.07842 14.7211 9.21201L13.5364 10.3968L14.0672 10.9276L14.9848 10.0065L15.2485 10.2702C15.8637 10.8784 16.8024 10.8362 17.3684 10.2702C17.959 9.68662 17.959 8.73389 17.3719 8.15029ZM12.7735 12.2179L12.8368 12.1546L12.8684 12.123L12.334 11.5956L12.0669 11.8628C11.986 11.9437 11.9157 12.1054 11.986 12.2706C12.5977 13.6944 12.3622 15.614 11.2583 16.8901L4.91959 10.548C6.28365 9.39131 8.06607 9.21553 9.53912 9.82373C9.67974 9.87998 9.84146 9.84834 9.94693 9.74287L10.2141 9.47569L9.68326 8.94483L9.58834 9.03975C7.68638 8.34717 5.5313 8.85342 4.11099 10.2737C3.94927 10.4355 3.9774 10.6675 4.11099 10.8046L7.29263 13.9862L4.90552 16.3733C4.74732 16.5351 4.76841 16.7671 4.90552 16.9042C5.05318 17.0519 5.28873 17.0519 5.43638 16.9042L7.82349 14.5171L11.0051 17.6987C11.1387 17.8323 11.3778 17.8534 11.536 17.6987C12.9915 16.2573 13.4415 14.0284 12.7735 12.2179ZM16.1942 14.5171L15.927 14.7843C15.8075 14.9038 15.6141 14.9038 15.4946 14.7843L6.73716 6.02334C6.61763 5.90381 6.61763 5.71045 6.73716 5.59092L7.00435 5.32373C7.12388 5.2042 7.31724 5.2042 7.43677 5.32373L16.1977 14.0847C16.3137 14.2042 16.3137 14.3976 16.1942 14.5171Z"
        fill="#333860"
      />
    </svg>
  );
};

export default UnPin;
