function Next() {
  return (
    <>
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.10783 9.075L6.83466 5.68333C7.29342 5.35 7.29342 4.65 6.83466 4.325L2.10783 0.925C1.55896 0.541667 0.813477 0.933333 0.813477 1.60833V8.39167C0.813477 9.06667 1.55896 9.45833 2.10783 9.075ZM9.00557 0.833333V9.16667C9.00557 9.625 9.37421 10 9.82478 10C10.2753 10 10.644 9.625 10.644 9.16667V0.833333C10.644 0.375 10.2753 0 9.82478 0C9.37421 0 9.00557 0.375 9.00557 0.833333Z"
          // fill="#333860"
          fill="#516Beb"
        />
      </svg>
    </>
  );
}

export default Next;
