import React, { useContext, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  useForm,
  Controller,
  useFieldArray,
  get,
  useWatch,
} from "react-hook-form";
import {
  SelectBox,
  SIZE,
  KIND,
} from "../../../../../../../components/SelectBox";
import { TextBox } from "../../../../../../../components/TextBox";
import CheckBoxBaseweb from "../../../../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { TextArea } from "../../../../../../../components/TextArea";
import getSelectValues from "../../../../../../../utils/getSelectValues";
import { useQuery, useMutation } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { getCustomerContactDetail } from "../../../../../../../services";
import { getOrganization } from "../../../../../../../services";
import { MaxyfiContext } from "../../../../../../../providers/MaxyfiProvider";
import DateRangeSelect from "../../../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { getCommunicationTemplateList } from "../../../../../../../services";
import { useSelector } from "react-redux";
import _ from "lodash";
import setSelectValues from "../../../../../../../utils/setSelectValues";

const ReturnVisit = ({
  errors,
  control,
  watch,
  // customerContactsData,
  setValue,
  entity,
  // settingData,
  actionFrom,
  recipients,
  callOutcome,
  contactsData,
  defaultConfiguration,
}) => {
  const [settingData, setSettingData] = useState(null);

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  let [confirmationTemplates, setConfirmationTemplates] = useState([]);
  let [followUpTemplates, setFollowUpTemplates] = useState([]);

  const getParams = getSearchParams.get("customers");
  const [customerContactsData, setCustomerContactsData] = useState([]);
  const [autoLocation, setAutoLocation] = useState([]);
  const {
    referenceData,
    currentOrganization,
    userInfo,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const refetchAction = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  let intl = useIntl();

  let {
    is_send_confirmation_message,
    send_field_visit_follow_up_message,
    location,
    is_send_action_follow_up,
  } = watch();

  let [confirmationMessageType, followUpType] = watch([
    "confirmation_message_type",
    "action_follow_up_type",
  ]);

  const { customerConatactDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags: [entity === "CALL" ? "CALL_FIELD_VISIT" : "RETURN_VISIT"],
        type,
      },
      organization: currentOrganization,

      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: ["NEXT_ACTION_FIELD_VISIT"],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  useEffect(() => {
    if (followUpType && followUpType[0] && followUpType[0].id) {
      setFollowUpTemplates([]);
      getTemplates({
        tags: ["NEXT_ACTION_FIELD_VISIT"],
        type: followUpType[0].id,
        setter: setFollowUpTemplates,
      });
    }
  }, [followUpType]);

  useEffect(async () => {
    await getOrganization({ organization: currentOrganization }).then((res) => {
      setSettingData(res.data.doc || {});
    });
    //TODO: PRAVEEN FAILURE ERROR HANDLING
  }, []);

  let type = referenceData["template_type"];

  let EmailUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_email === true);
  let PhoneUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_phone === true);

  let mappinTypeValue =
    referenceData && referenceData["template_type"]
      ? referenceData["template_type"]
          .filter(({ id }) => id != "letter")
          .map((e) => {
            if (e.id == "email" && !EmailUser) {
              return { ...e, disabled: true };
            } else if (e.id === "sms" && !PhoneUser) {
              return { ...e, disabled: true };
            } else if (
              e.id === "sms" &&
              !currentDefaultFormatDetails.is_sms_configured
            ) {
              return { ...e, disabled: true };
            } else if (
              e.id === "whatsapp" &&
              !currentDefaultFormatDetails?.is_whatsapp_configured
            ) {
              return { ...e, disabled: true };
            }
            return e;
          })
      : [];
  useEffect(() => {
    let customerContact = [];
    if (customerConatactDetails) {
      let custContact = customerConatactDetails;
      customerContact = custContact.filter(
        (e) => e && e?.phone && e?.phone?.length > 0
      );
    }
    setCustomerContactsData(customerContact);
  }, [customerConatactDetails]);

  useEffect(() => {
    if (customerConatactDetails) {
      let addersData = customerConatactDetails;

      addersData.reduce((prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = address
          .map(({ status, call_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name ? curr?.first_name : ""} - ${
                nRest.line_1
              }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
                nRest.city
              }-${nRest.zip_code} ${designation?.label}`,
              id: curr?._id,
              designation: designation.id,
              status,
            };
          })
          .filter((e) => e.status === "VALID" || e.status === "LEAD");
        setAutoLocation([
          ...prev,
          ...nestedContacts,
          { id: "organization", label: "Organization Address" },
          { id: "other", label: "Other Location" },
        ]);
        return [...prev, ...nestedContacts];
      }, []);

      let findDesignation =
        autoLocation.length > 0
          ? autoLocation.find((e) => e.designation == "primary_contact")
          : [];

      if (findDesignation && findDesignation.length > 0) {
        setValue(
          "location",
          setSelectValues(findDesignation && findDesignation.id)
        );
      } else {
        setValue(
          "location",
          setSelectValues(
            autoLocation && autoLocation[0] && autoLocation?.[0].id
          )
        );
      }

      // if (customerContactsData) {

      //   let differentLocations = [];
      //   customerContactsData
      //     .filter((k) => k.address && k.address.length > 0)
      //     .map((e, i) => {
      //       let filteredAddress = e && e.address && e.address[0];
      //       let lineOne = filteredAddress ? filteredAddress.line_1 : "";
      //       let lineTwo = filteredAddress ? filteredAddress.line_2 : "";
      //       let city = filteredAddress ? filteredAddress.city : "";
      //       let state = filteredAddress ? filteredAddress.state : "";
      //       let country = filteredAddress ? filteredAddress.country : "";
      //       let zipCode = filteredAddress ? filteredAddress.zip_code : "";

      //

      //       differentLocations.push({
      //         id: e._id,
      //         label: `${e.first_name} ${lineOne} ${lineTwo} ${city} ${state} ${country} ${zipCode}`,
      //       });

      //       // setAutoLocation([
      //       //   {
      //       //     id: e._id,
      //       //     label: `${e.first_name} ${lineOne} ${lineTwo} ${city}`,
      //       //   },
      //       // ]);
      //       // return {
      //       //   label: `${e.first_name} ${lineOne} ${lineTwo} ${city}`,
      //       //   id: e._id,
      //       // };
      //     });
      //
      //   differentLocations.push(
      //     { id: "organization", label: "Organization Address" },
      //     { id: "other", label: "Other Location" }
      //   );
      // }

      // setAutoLocation();

      //   let escalationContact =
      //     customerContactsData &&
      //     customerContactsData?.filter(
      //       (e) => e?.designation === "escalation_contact"
      //     );
      //   let primaryContact =
      //     customerContactsData &&
      //     customerContactsData?.filter(
      //       (e) => e?.designation === "primary_contact"
      //     );

      //   if (
      //     primaryContact &&
      //     Array.isArray(primaryContact) &&
      //     primaryContact?.length > 0
      //   ) {
      //     let primaryFirstContactFilter =
      //       primaryContact &&
      //       primaryContact.filter(
      //         (e) => e && e?.address && e?.address?.length > 0
      //       );
      //
      //     if (primaryContact && primaryFirstContactFilter[0]) {
      //       let primaryFirstContact =
      //         primaryFirstContactFilter && primaryFirstContactFilter[0]
      //           ? primaryFirstContactFilter[0]
      //           : {};

      //       let primaryContactAddress =
      //         primaryFirstContact?.address && primaryFirstContact?.address[0]
      //           ? primaryFirstContact?.address[0]
      //           : [];

      //       let addressLineOne = primaryContactAddress?.line_1
      //         ? primaryContactAddress?.line_1
      //         : "";

      //       let addressLineTwo = primaryContactAddress.line_2
      //         ? primaryContactAddress.line_2
      //         : "";

      //       let city = primaryContactAddress && primaryContactAddress?.city;
      //       let state = primaryContactAddress && primaryContactAddress?.state;
      //       let country = primaryContactAddress && primaryContactAddress?.country;
      //       let zipCode =
      //         primaryContactAddress && primaryContactAddress?.zip_code;

      //       let primaryContactLabel = `${primaryFirstContact?.first_name} ${
      //         addressLineOne ? addressLineOne : ""
      //       } ${addressLineTwo ? addressLineTwo : ""} ${city ? city : ""} ${
      //         state ? state : ""
      //       }  ${country ? country : ""}
      //       ${zipCode ? zipCode : ""}
      //       `;

      //

      //       setValue("location", [
      //         {
      //           id: primaryFirstContact._id,
      //           label: `${primaryContactLabel}`,
      //         },
      //       ]);
      //       setValue("return_visit_contact", [
      //         {
      //           id: primaryFirstContact?._id,
      //           label: `${primaryContactLabel}`,
      //         },
      //       ]);
      //     }
      //   } else if (
      //     escalationContact &&
      //     Array.isArray(escalationContact) &&
      //     escalationContact?.length > 0
      //   ) {
      //     let escalationFirstContactFilter =
      //       escalationContact &&
      //       escalationContact.filter(
      //         (e) => e && e?.address && e?.address?.length > 0
      //       );
      //     //

      //     if (escalationContact && escalationFirstContactFilter[0]) {
      //       let primaryFirstContact =
      //         escalationFirstContactFilter && escalationFirstContactFilter[0]
      //           ? escalationFirstContactFilter[0]
      //           : {};

      //       let primaryContactAddress =
      //         primaryFirstContact?.address && primaryFirstContact?.address[0]
      //           ? primaryFirstContact?.address[0]
      //           : [];

      //       let addressLineOne = primaryContactAddress?.line_1
      //         ? primaryContactAddress?.line_1
      //         : "";

      //       let addressLineTwo = primaryContactAddress?.line_2
      //         ? primaryContactAddress?.line_2
      //         : "";

      //       let city = primaryContactAddress && primaryContactAddress?.city;
      //       let state = primaryContactAddress && primaryContactAddress?.state;
      //       let country = primaryContactAddress && primaryContactAddress?.country;
      //       let zipCode =
      //         primaryContactAddress && primaryContactAddress?.zip_code;

      //       let primaryContactLabel = `${primaryFirstContact?.first_name} ${
      //         addressLineOne ? addressLineOne : ""
      //       } ${addressLineTwo ? addressLineTwo : ""} ${city ? city : ""} ${
      //         state ? state : ""
      //       }  ${country ? country : ""}
      //       ${zipCode ? zipCode : ""}
      //       `;

      //

      //       setValue("location", [
      //         {
      //           id: primaryFirstContact._id,
      //           label: `${primaryContactLabel}`,
      //         },
      //       ]);

      //       setValue("return_visit_contact", [
      //         {
      //           id: primaryFirstContact?._id,
      //           label: `${primaryContactLabel}`,
      //         },
      //       ]);
      //     }
      //   } else {
      //     setValue("location", []);
      //   }
      // }
    }
  }, [customerContactsData, customerConatactDetails]);

  useEffect(() => {
    let findCurrentRecipient =
      recipients && recipients.recipient_id
        ? contactsData?.find((fr) => fr._id === recipients.recipient_id)
        : [];

    const paymentPlanConfig =
      defaultConfiguration && defaultConfiguration?.messages;

    // _.get(
    //   settingData,
    //   "default_configuration.call_field_visit.messages",
    //   []
    // );

    //
    setValue(
      "is_hold_workflow",
      defaultConfiguration && defaultConfiguration.is_hold_workflow
    );
    paymentPlanConfig &&
      Array.isArray(paymentPlanConfig) &&
      paymentPlanConfig?.reverse()?.map((e) => {
        if (
          (e.type === "email" &&
            findCurrentRecipient &&
            findCurrentRecipient.is_valid_email) ||
          (e.type === "sms" &&
            findCurrentRecipient &&
            findCurrentRecipient.is_valid_sms) ||
          (e.type === "whatsapp" &&
            findCurrentRecipient &&
            findCurrentRecipient.is_valid_whatsapp)
        ) {
          if (e?.confirmation_template?.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e?.follow_up_template?.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
  }, [
    callOutcome,
    recipients?.recipient_id,
    settingData,
    defaultConfiguration,
  ]);

  return (
    <div
      style={{
        paddingTop: "25px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "220px" }}>
          <Controller
            name="return_visit_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                size={SIZE.compact}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                label={intl.formatMessage({
                  id: "return_visit_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div style={{ width: "220px" }}>
          <Controller
            name="return_visit_time"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.compact}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "return_visit_time",
                })}
                placeholder={intl.formatMessage({
                  id: "return_visit_time",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["time"]}
              />
            )}
          />
        </div>

        <div style={{ width: "320px" }}>
          <Controller
            name="location"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.compact}
                {...field}
                name={field.name}
                // requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "location",
                })}
                placeholder={intl.formatMessage({
                  id: "location",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={autoLocation}
              />
            )}
          />
        </div>
        <div style={{ width: "95px" }}></div>
      </div>
      {/* {entity === "FIELD_VISIT" ? (
        <Controller
          name="return_visit_contact"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size={SIZE.default}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              requiredAstric={true}
              label={intl.formatMessage({
                id: "contact_for_notifications",
              })}
              placeholder={intl.formatMessage({
                id: "contact_for_notifications",
              })}
              clearable={false}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={customerContactsData
                ?.sort((a, b) => a?.first_name.localeCompare(b?.first_name))
                ?.map((e) => {
                  let designation = referenceData["recipient_type"].find(
                    (rf) => rf.id === e.designation
                  );

                  let label = "";
                  label =
                    designation && designation.label ? designation.label : "";

                  let phone = "";

                  if (
                    e.phone &&
                    e.phone[0] &&
                    e.phone[0].value &&
                    e.phone[0].country_code
                  ) {
                    phone = `${e.phone[0].country_code} ${e.phone[0].value}`;
                  }

                  return {
                    id: e._id,
                    label: `${e.first_name || ""} (${phone}) - ${label}`,
                    number: phone,
                  };
                })}
            />
          )}
        />
      ) : (
        ""
      )} */}
      {location && getSelectValues(location) === "other" ? (
        <Controller
          name="custom_location"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              size={SIZE.compact}
              {...field}
              name={field.name}
              // requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "type_your_location",
              })}
              placeholder={intl.formatMessage({
                id: "type_your_location",
              })}
              value={field.value}
            />
          )}
        />
      ) : (
        ""
      )}
      <Controller
        defaultValues={false}
        name="is_hold_workflow"
        control={control}
        render={({ field }) => (
          <CheckBoxBaseweb
            {...field}
            // disabled={true}
            checked={field.value}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            <FormattedMessage
              id="hold_workflow_till_field_visit_date"
              // id="hold_workflow_till_call_back_date"
            >
              hold_workflow_till_field_visit_date
            </FormattedMessage>
          </CheckBoxBaseweb>
        )}
      />
      <div className="cp-action-message-confirmation">
        <div className="cp-message-confirmation-checkbox">
          <Controller
            defaultValues={false}
            name="is_send_confirmation_message"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
                // disabled={!EmailUser && !PhoneUser}
              >
                <FormattedMessage id="send_confirmation_message">
                  send_confirmation_message
                </FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
        {is_send_confirmation_message ? (
          <>
            <div className="cp-message-confirmation-type">
              <Controller
                defaultValues={[]}
                name="confirmation_message_type"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.compact}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comm_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comm_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                        setValue("confirmation_message_template_id", []);
                      }
                      field.onChange(e.value);
                    }}
                    // options={
                    //   referenceData["template_type"].filter(
                    //     (e) => e.id !== LETTER
                    //   ) || []
                    // }
                    options={mappinTypeValue}
                  />
                )}
              />
            </div>
            {/* TODO: TEMPLATE TYPE */}
            <div className="cp-action-promise-pay-con">
              <Controller
                name="confirmation_message_template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.compact}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_visit_confirmation",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_visit_confirmation",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={confirmationTemplates || []}
                  />
                )}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="cp-action-message-confirmation">
        <div className="cp-message-confirmation-checkbox">
          <Controller
            defaultValues={false}
            name="is_send_action_follow_up"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
                // disabled={!EmailUser && !PhoneUser}
              >
                <FormattedMessage id="send_field_visit_promise_follow_up_message">
                  {/* Send Field Visit Follow up Message */}
                  send_field_visit_promise_follow_up_message
                </FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
        {is_send_action_follow_up ? (
          <>
            <div className="cp-message-confirmation-type">
              <Controller
                name="action_follow_up_type"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.compact}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comm_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comm_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                        setValue("action_follow_up_template_id", []);
                      }
                      field.onChange(e.value);
                    }}
                    // options={
                    //   referenceData["template_type"].filter(
                    //     (e) => e.id !== LETTER
                    //   ) || []
                    // }
                    options={mappinTypeValue}
                  />
                )}
              />
            </div>
            <div className="cp-action-promise-pay-con">
              <Controller
                defaultValues={[]}
                name="action_follow_up_template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.compact}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_visit_follow_up_message",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_visit_follow_up_message",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={followUpTemplates || []}
                  />
                )}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Controller
          name="comment"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              clearable={false}
              error={errors[field.name] && errors[field.name].message}
              name={field.name}
              label={intl.formatMessage({
                id: "user_comments",
              })}
              placeholder={intl.formatMessage({
                id: "user_comments",
              })}
              value={field.value}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ReturnVisit;
