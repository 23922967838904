import React from "react";

function BlupIcon({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="22" height="22" fill="#f8f8fb" />
      <path
        d="M10.8906 10.9482C11.3135 10.9482 11.6562 10.6055 11.6562 10.1826C11.6562 9.75977 11.3135 9.41699 10.8906 9.41699C10.4678 9.41699 10.125 9.75977 10.125 10.1826C10.125 10.6055 10.4678 10.9482 10.8906 10.9482Z"
        fill={color}
      />
      <path
        d="M11.645 1.03707C7.64109 0.61458 4 3.8602 4 7.88545C4 9.51502 4.57871 11.0941 5.62546 12.3282C6.23781 13.0583 6.66025 13.9375 6.88157 14.9406C6.95857 15.2916 7.26961 15.5417 7.62924 15.5417H14.152C14.5101 15.5417 14.8204 15.2938 14.8989 14.9447C15.121 13.9585 15.5696 13.0396 16.1962 12.2867C17.4687 10.7524 18.0123 8.73481 17.687 6.7542C17.2085 3.76012 14.6672 1.35596 11.645 1.03707ZM15.0178 11.3083C14.3666 12.0911 13.8709 13.0157 13.5643 14.0104H8.2214C7.92159 13.0089 7.44456 12.114 6.79632 11.3408C5.98061 10.3797 5.53126 9.15241 5.53125 7.88548C5.53123 5.43904 7.37834 3.1284 9.79055 2.66544C11.8434 2.27145 14.0696 3.12793 15.2902 4.83353C15.7494 5.47517 16.0513 6.22032 16.176 6.99907C16.4332 8.5677 16.022 10.0982 15.0178 11.3083Z"
        fill={color}
      />
      <path
        d="M10.8906 4.82324C10.4674 4.82324 10.125 5.16605 10.125 5.58887V7.88574C10.125 8.30856 10.4674 8.65137 10.8906 8.65137C11.3138 8.65137 11.6562 8.30856 11.6562 7.88574V5.58887C11.6562 5.16605 11.3138 4.82324 10.8906 4.82324Z"
        fill={color}
      />
      <path
        d="M13.9531 16.3076H7.82812C7.40494 16.3076 7.0625 16.6504 7.0625 17.0732V18.6045C7.0625 19.8711 8.0928 20.9014 9.35938 20.9014H12.4219C13.6884 20.9014 14.7188 19.8711 14.7188 18.6045V17.0732C14.7188 16.6504 14.3763 16.3076 13.9531 16.3076ZM12.4219 19.3701H9.35938C8.93693 19.3701 8.59375 19.0266 8.59375 18.6045V17.8389H13.1875V18.6045C13.1875 19.0266 12.8443 19.3701 12.4219 19.3701Z"
        fill={color}
      />
    </svg>
  );
}

export default BlupIcon;
