import React, { useEffect, useContext, useMemo, useState } from "react";
import { NEW } from "../../../../constants";
import { linkedInvoiceModalData } from "../../../../services/creditNote";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { FormattedDate, FormattedNumber } from "react-intl";
import { AllocatedInputBox } from "../../../../components/AllocatedInputBox";
import { useForm, Controller, set } from "react-hook-form";
import columnMapper from "../../../../utils/mapper";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { customerInvoiceOverview } from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import {
  allocatedAmount,
  changingAllocatedAmount,
  gettingDueAmount,
} from "../../../../redux/customerOverview/payment/action";
import { useSelector, useDispatch } from "react-redux";
import { TextBox } from "../../../../components/TextBox";
import { HeadingXSmall, Paragraph2, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import { NumericFormat } from "react-number-format";
const PaymentTable = ({
  control,
  errors,
  watch,
  setValue,
  setError,
  selectedCurrency,
  reset,
  pendingAllocationValue,
  blur,
  invoicesData,
}) => {
  let {
    currentOrganization,
    dateFormat,
    currencyFormat,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    referenceData,
    appAccessRest,
  } = useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  let { payment_status, ...rest } = watch();

  const timeZone = orgRef?.time_zone;

  let selectedCurr =
    selectedCurrency && selectedCurrency[0] && selectedCurrency[0].id;

  const viewOnly = useSelector((s) => s.customerPaymentReducer.viewOnly);
  let dispatch = useDispatch();
  const { id } = useParams();

  const editingDatas = useSelector(
    (s) => s.customerPaymentReducer.editingData.allocated_to
  );

  const paymentId = useSelector(
    (s) => s.customerPaymentReducer.editingData._id
  );

  const actionType = useSelector((s) => s.customerPaymentReducer.actionType);
  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("customers");

  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(0);
  const [querySortBy, setQuerySortBy] = useState("due_date");
  const [queryFilter, setQueryFilter] = useState({});
  //   useEffect(() => {
  //     if (Object.values(watchFieldsValues).some((val) => val)) {
  //
  //       //   dispatch(allocatedAmount(watchFieldsValues));
  //     }
  //   }, [watchFieldsValues]);
  //   const findingKey = Object.keys(allocatedValues);

  //

  // API is passing in useQuery
  // const { data, isFetched, isError } = useQuery(
  //   [
  //     `PENDING_PAYMENT_INVOICES_${id}`,
  //     {
  //       // pageIndex: queryPageIndex,
  //       // pageSize: queryPageSize,
  //       // sortBy: querySortBy,
  //       // filters: queryFilter,
  //     },
  //   ],
  //   async ({ queryKey }) => {
  //     let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
  //     filters = {
  //       ...filters,
  //       status: ["due", "overdue"],
  //       // customer_id: id,
  //     };
  //     return await linkedInvoiceModalData({
  //       params: { paymentId: id },
  //       organization: currentOrganization,
  //     });
  //   }
  // );

  const { data, isFetched, isError } = useQuery(
    [
      `CUSTOMER_INVOICE_OVERVIEW-${getParams}-${actionType} ${selectedCurr}`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: querySortBy,
        filters: queryFilter,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      filters = {
        ...filters,
      };

      if (actionType === "NEW") {
        return await customerInvoiceOverview({
          pageIndex,
          sortBy,
          pageSize,
          customer_id: getParams,
          filters: {
            invoice_currency: selectedCurr,
            status: ["due", "overdue"],
            sort: "invoice_due_date",
            // ...appAccessRest,
          },
          organization: currentOrganization,
        });
      } else {
        return await linkedInvoiceModalData({
          // params,
          pageIndex,
          sortBy,
          pageSize,
          filters: {
            invoice_currency: selectedCurr,
            customer_id: getParams,
            // ...appAccessRest,
          },
          paymentId: paymentId,
          organization: currentOrganization,
        });
      }
    }
  );

  // useEffect(() => {
  //   if (
  //     payment_status &&
  //     payment_status[0] &&
  //     payment_status[0].id != "success"
  //   ) {
  //     reset();
  //   }
  // }, [payment_status]);

  useEffect(() => {
    if (data && data.data && data.data.docs) {
      data.data.docs.map((e, i) => {
        setValue(`invoices[${i}]._id`, e._id);
        // setValue(`invoices[${i}].allocatedAmount`, 0);
        setValue(`invoices[${i}].dueAmount`, e.not_paid_amount.value || 0);
        setValue(`invoices[${i}].dueDate`, e.invoice_due_date);
        if (actionType != NEW) {
          let changedDataValue = editingDatas?.find(
            (i) => i.invoice_id === e._id
          );
          setValue(`invoices[${i}].allocatedAmount`, changedDataValue?.amount);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (invoicesData && Array.isArray(invoicesData) && actionType === "NEW") {
      let value = pendingAllocationValue;
      let sortingDueDate = invoicesData;

      if (sortingDueDate && Array.isArray(sortingDueDate)) {
        sortingDueDate.map((e, i) => {
          if (value >= 0 && value !== 0) {
            let dueAmount = e?.dueAmount || 0;
            // setValue(`invoices[${i}].allocatedAmount`, value);

            if (value < e.dueAmount) {
              setValue(`invoices[${i}].allocatedAmount`, value);
              value = 0;
            } else {
              //
              setValue(`invoices[${i}].allocatedAmount`, e.dueAmount);
              let remainingAmount = Number(value) - Number(dueAmount);
              value = remainingAmount;
            }
          }
        });
      }
    }
  }, [blur]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "invoice_number",
        alignment: "right",
        width: 90,
      },
      {
        Header: "Invoice Date",
        accessor: "invoice_date",
        width: 90,

        Cell: (props) => (
          <>
            {moment
              .utc(props.value)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            {/* <FormattedDate value={props.value} /> */}
          </>
        ),
      },
      {
        Header: "Due Date",
        accessor: "invoice_due_date",
        width: 90,

        Cell: (props) => (
          <>
            {moment
              .utc(props.value)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
          </>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        width: 90,

        Cell: (props) => {
          const StatusType = ({ type }) => {
            switch (type) {
              case "overdue":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FD372A",
                      }}
                    ></div>
                    <ParagraphMedium>Overdue</ParagraphMedium>
                  </div>
                );
              case "due":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FBBD04",
                      }}
                    ></div>
                    <ParagraphMedium>Due</ParagraphMedium>
                  </div>
                );
              case "paid":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FE7B72",
                      }}
                    ></div>
                    <ParagraphMedium>Paid</ParagraphMedium>
                  </div>
                );
              default:
                return null;
            }
          };

          return <StatusType type={props.value} />;
        },
      },
      {
        Header: "Due Amount",
        accessor: "not_paid_amount",
        width: 90,
        Cell: (props) => {
          let allocatedAmount =
            watch(`invoices[${props.row.index}].allocatedAmount`) || 0;

          let dueAmount = props?.row?.original?.not_paid_amount?.value || 0;

          let linkedAmount = props?.row?.original?.linked_amount?.value;

          if (!linkedAmount && isNaN(linkedAmount)) {
            linkedAmount = 0;
          }

          let remainingAmount =
            Number(dueAmount) + Number(linkedAmount) - Number(allocatedAmount);

          return (
            <div className="dashboard__currency__wraper --cus__currency">
              <div className="dashboard__currency">
                <HeadingXSmall $style={{ fontSize: "9px" }}>
                  {props.value.currency ? props.value.currency : " "}
                </HeadingXSmall>
              </div>
              <NumericFormat
                displayType="text"
                value={remainingAmount}
                thousandsGroupStyle={currencyGroupStyle}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={currencySeparator}
                decimalSeparator={currencyDecimalSeparator}
                renderText={(value) => (
                  <Paragraph2
                    $style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                      margin: "5px",
                    }}
                  >
                    {value}
                  </Paragraph2>
                )}
              />
              {/* <FormattedNumber
                value={remainingAmount}
                style="currency"
                currency={props.value.currency}
              /> */}
            </div>
          );
        },
      },
      {
        Header: "Allocated Amount",
        accessor: "allocated_amt",
        Cell: (props) => {
          let changedData = editingDatas?.find(
            (i) => i.invoice_id === props?.row?.original?._id
          );

          // let dueAmount = props.row.original.not_paid_amount.value || 0;

          let allocatedAmount =
            watch(`invoices[${props.row.index}].allocatedAmount`) || 0;

          let dueAmount = props?.row?.original?.not_paid_amount?.value || 0;
          let linkedAmount = props?.row?.original?.linked_amount?.value;

          if (!linkedAmount && isNaN(linkedAmount)) {
            linkedAmount = 0;
          }

          let remainingAmount =
            Number(dueAmount) + Number(linkedAmount) - Number(allocatedAmount);

          useEffect(() => {
            dispatch(gettingDueAmount(remainingAmount));
          }, [remainingAmount]);

          return (
            <div>
              {/* <Controller
                name={`invoices[${props.row.index}]._id`}
                control={control}
                render={({ field }) => (
                  <input hidden value={props.row.original._id} {...field} />
                )}
              /> */}
              <Controller
                name={`invoices[${props.row.index}].allocatedAmount`}
                // rules={{ required: remainingAmount < 0 ? "Helo" : "" }}
                control={control}
                render={({ field }) => {
                  return (
                    <AllocatedInputBox
                      disabled={
                        payment_status &&
                        payment_status[0] &&
                        payment_status[0].id != "success"
                          ? true
                          : viewOnly
                          ? true
                          : currentDefaultFormatDetails.accounting_book ===
                            "QUICKBOOKS"
                          ? true
                          : false
                      }
                      type="number"
                      clearable={false}
                      {...field}
                      fullWidth
                      name={field.name}
                      error={remainingAmount < 0}
                      value={field.value}
                      onFocus={(e) => {
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        );
                      }}
                    />
                  );
                }}
              />
              {remainingAmount < 0 ? (
                <div style={{ color: "red" }}>Greater than Due Amount</div>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    [payment_status]
  );

  const columnData = useMemo(() => columns, [columns]);

  const tableData = useMemo(
    () => (isFetched && !isError && data.data.docs ? data.data.docs : []),
    [isFetched, data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
    selectedFlatRows,
  } = useTable(
    {
      columns: columnData,
      data: tableData,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  return (
    <>
      <div
        overrides={{
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
      >
        <div style={{ width: "", background: "#FFFFFF", height: "" }}>
          <div>
            <div
              className="customer-profile-tab__table"
              style={{
                gridColumn: "1/6",
                gridRow: "2/13",
                overflowY: "unset",
                // height: "445px",
              }}
            >
              <div className="cp-tab__table-wrapper" {...getTableProps()}>
                <div className="cp-tab__table-header">
                  {headerGroups.map((headerGroup) => (
                    <>
                      <div
                        className="cp-tab__table-header-wrapper-dispute_table"
                        style={{
                          backgroundColor: "#F9F9F9",
                          border: "1px solid #CDCED9",
                          // borderStartEndRadius: "5px",
                        }}
                        {...headerGroup.getHeaderGroupProps()}
                      >
                        <div
                          className="cp-tab__table-header-content"
                          style={{
                            // height: "60px",
                            // width: "50%",
                            minWidth: "50%",
                            maxWidth: "100%",
                          }}
                        >
                          {headerGroup.headers.map((column, index) => (
                            <div className="cp-tab__table-header-cell-content-payment">
                              <div
                                className="cp-tab__table-header-cell-payment"
                                // style={{ fontSize: "16px", fontWeight: "bold" }}
                                {...column.getHeaderProps()}
                              >
                                {column.render("Header")}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  ))}
                </div>

                <div
                  className="cp-tab__table-body"
                  style={{ height: "100%" }}
                  {...getTableBodyProps()}
                >
                  {page.map((row) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();
                    return (
                      <React.Fragment key={rowProps.key}>
                        <div
                          className="cp-tab__table-body-content-dispute"
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <div
                                className="cp-tab__table-body-cell"
                                style={{ height: "200px" }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTable;
