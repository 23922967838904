import { Search } from "baseui/icon";
import {
  HeadingXLarge,
  HeadingXSmall,
  LabelSmall,
  ParagraphMedium,
} from "baseui/typography";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
// import { TextBox, SIZE } from "../../components/TextBox";
import { TextBox, SIZE, inputOverrides } from "../../../components/TextBox";
import { FormattedDate, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { StatefulTooltip, TRIGGER_TYPE } from "baseui/tooltip";
import { getActionData, isOpenModal } from "../../../redux/actions";
import { Input } from "baseui/input";
import {
  setInvoiceSummaryGridStructure,
  setInvoiceSummaryTableView,
  setInvoiceSummaryGridAndView,
  setInvoiceSummarySort,
  setInvoiceSummaryPageSize,
  setInvoiceFilter,
  setInvoiceSummaryFilter,
  updateTableInvoiceSummaryDraftState,
  setInvoiceSummaryPageIndex,
  setInvoiceGlobalFilter,
  setNeedsActionFilterInv,
} from "../../../redux/invoiceSummary/action";
// import AllFilters from "../../components/AllFilters/AllFilters";
import BackArrowCustomer from "../../../assets/img/svg/BackArrowCustomer";
import { useParams, useSearchParams } from "react-router-dom";
import millify from "millify";
// import Call from "../../assets/img/svg/CpCard/Call";
// import Message from "../../assets/img/svg/Workflow/Message";
// import Dispute from "../CustomerOverView/COTabContainers/Actions/Dispute";
// import Email from "../../assets/img/svg/CpCard/Email";
// import PromiseToPay from "../../../assets/img/svg/PromiseToPay";
import setTableGridStructure from "../../../utils/setTableGridStructure";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import moment from "moment";
import "moment-timezone";
// import ReviewPromiseToPay from "../../../../assets/img/svg/ReviewPromiseToPay";
import DisputeSvg from "../../../assets/img/svg/CusOverviewActionsIcon/DisputeSvg";
import PromiseToPayN from "../../../assets/img/svg/CusOverviewActionsIcon/PromiseToPayN";
import MessageSvg from "../../../assets/img/svg/CusOverviewActionsIcon/MessageSvg";
import EmailSvgN from "../../../assets/img/svg/CusOverviewActionsIcon/EmailSvgN";
import CallSvg from "../../../assets/img/svg/CusOverviewActionsIcon/CallSvg";
import ReviewPromiseSvg from "../../../assets/img/svg/CusOverviewActionsIcon/ReviewPromiseSvg";
import SmsSvgN from "../../../assets/img/svg/CusOverviewActionsIcon/SmsSvgN";
import AllFilters from "../../../components/AllFilters/AllFilters";
import { invoiceSummaryData } from "../../../services/invoiceSummary";
import { useInfiniteQuery, useQuery } from "react-query";
import { INVOICE_OVERVIEW } from "../../../constants";
import { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

const ActionTypes = ({ type }) => {
  switch (type) {
    case "call":
      return <CallSvg />;

    case "sms":
      return <SmsSvgN />;

    case "dispute":
      return <DisputeSvg />;

    case "email":
      return <EmailSvgN />;

    case "promise":
      return <PromiseToPayN />;

    case "review_promise":
      return <ReviewPromiseSvg />;

    case "review_dispute":
      return <DisputeSvg />;

    default:
      return null;
  }
};

const InvoiceOverviewListTable = ({ tableData, setQueryFilter, getParams }) => {
  let intl = useIntl();
  const ref = useRef(null);

  const [invoiceAllData, setInvoiceData] = useState([]);

  let dispatch = useDispatch();
  let { viewName, gridAndViewLoaded, tableCurrentState } = useSelector(
    (state) => state.invoiceSummaryReducer
  );
  const [filterSearch, setFilterSearch] = useState(
    tableCurrentState.globalFilters
  );

  // FILTERS STARTS

  // Customer Filter
  const filterReduxData = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.formFilters
  );

  // Customer Stage Filter
  const customerStageFilters = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.customer_stage
  );
  // Needs Action Filter

  const NeedsActionData = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.needs_action
  );
  // FILTERS ENDS

  // TO FIND LENGTH STARTS
  const InvoiceFilter = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.formFilters
  );

  // TO FIND LENGTH ENDS

  let {
    currentOrganization,
    userPersonalization,
    dateFormat,
    referenceData,
    currentDefaultFormatDetails,
    appAccessRest,
  } = useContext(MaxyfiContext);

  // GRID STRUCTURE FOR INVOICE

  useEffect(() => {
    const menu = "invoice_summary";

    if (!viewName && !gridAndViewLoaded) {
      setTableGridStructure({
        menu,
        currentOrganization,
        setGridStructureAction: setInvoiceSummaryGridStructure,
        setViewAction: setInvoiceSummaryTableView,
        setGridAndViewAction: setInvoiceSummaryGridAndView,
        userPersonalization,
        dispatch,
        isSetGridAndView: true,
      });
    }
  }, []);

  const gridStructureValues = useSelector(
    (s) => s.invoiceSummaryReducer.gridStructure.table
  );

  const [addSearchParams, setAddSearchParams] = useSearchParams();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const debounceFilter = useDebouncedCallback((filter) => {
    setFilterSearch(filter);
    dispatch(setInvoiceSummaryPageIndex(0));
    dispatch(setInvoiceGlobalFilter(filter));
  }, 1000);
  const [isval, setIsVal] = useState(tableCurrentState.globalFilters);
  return (
    <>
      <div className="cp__customerm__tabel-container">
        <div
          className="cp__back_btn_wraper"
          onClick={() => setAddSearchParams({})}
        >
          <div>
            <BackArrowCustomer />
          </div>
          <HeadingXLarge $style={{ color: "#516beb" }}>Invoices </HeadingXLarge>
        </div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            marginLeft: "5px",
            marginBottom: "5px",
          }}
        >
          <Input
            clearable
            name="display_name"
            label={intl.formatMessage({
              id: "search_by_inv_number",
            })}
            placeholder={intl.formatMessage({
              id: "search_by_inv_number",
            })}
            overrides={{ ...inputOverrides }}
            endEnhancer={<Search size="18px" />}
            onChange={(val) => {
              let value = val.target.value;
              debounceFilter(value);
              setIsVal(value);
              // debounceFilter(value);
              //
            }}
            size={SIZE.compact}
            value={isval}
          />
          <AllFilters
            setQueryFilter={setQueryFilter}
            // setPageIndex={gotoPage}
            filterReduxData={filterReduxData}
            stageFilter={customerStageFilters}
            NeedsAction={NeedsActionData}
            name={"Invoice"}
            id={"invoice"}
            title={"invoice"}
            gridStructureValues={gridStructureValues}
            mainFilter={(data) => dispatch(setInvoiceSummaryFilter(data))}
            needsActionFilter={setNeedsActionFilterInv}
            pageIndex={setInvoiceSummaryPageIndex}
            mainFilterDataLength={InvoiceFilter}
          />
        </div>

        <div className="cp__customer__list-wraper" ref={ref}>
          {tableData?.map((e) => (
            //
            <div
              className={`cp__customer__container --inv__modifier ${
                e._id === getParams ? "custmer__active" : " "
              }`}
              onClick={() => {
                setAddSearchParams({ inv: `${e._id}` });
                dispatch(isOpenModal(false));
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <ParagraphMedium
                    $style={{
                      maxWidth: "150px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    INV-{e.invoice_number}
                  </ParagraphMedium>

                  <ParagraphMedium
                    $style={{
                      maxWidth: "150px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >{`${"["}${e.customer_name}${"]"}
                        `}</ParagraphMedium>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      color: "#ADADAD",
                    }}
                    className="cp-left__customer__list"
                  >
                    <ActionTypes type={e.next_workflow_action_type} />

                    {e.next_workflow_action_date
                      ? moment
                          .utc(e.next_workflow_action_date)
                          .tz(currentDefaultFormatDetails.time_zone)
                          .format(currentDefaultFormatDetails.date_format)
                      : " "}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "3px",
                  }}
                >
                  <span className="cp_currency__style">
                    {e.not_paid_amount?.currency}{" "}
                  </span>
                  <LabelSmall
                    $style={{
                      fontWeight: "700",
                      textAlign: "right",

                      lineHeight: "17px",
                    }}
                  >
                    {millify(
                      e && e.not_paid_amount && e.not_paid_amount?.value
                        ? e.not_paid_amount?.value
                        : 0,
                      {}
                    )}
                    {/* {e.total_inv_amount.value ? e.total_inv_amount.value : 0} */}
                  </LabelSmall>
                </div>

                {/* <HeadingXSmall>{e.next_action_name}</HeadingXSmall> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InvoiceOverviewListTable;
