import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { first, isLength } from "lodash";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setDashBoardDashboardCustomerActionStage } from "../../redux/customerSummary/action";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { getOrganizationDashboard } from "../../services/organization";
import { useQuery } from "react-query";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DisputeHorizntalBar = ({ value, isDate }) => {
  // API Start
  let {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);
  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `dashboard-disputer-states-${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
          start_date: isDate[0],
          end_date: isDate[1],
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "DISPUTE_STATS",
        filters,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
  // API End
  const [islenght, setIslenght] = useState();

  let subbar = { height: "121px" };
  let disputeGH = { height: "300px" };
  const containerHeight =
    data?.data && data?.data?.doc && data?.data?.doc?.length * 75;
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const handleClick = (event) => {
    dispatch(setDashBoardDashboardCustomerActionStage("active_disputes"));
    navigate(`/customers`);
  };
  //
  let labels = [];
  let In_Progress = [];
  let In_Open = [];
  let In_Close = [];

  let labelKey = [];

  data &&
    data?.data &&
    data.data?.doc &&
    data?.data?.doc?.customers.map((e) => {
      if (e.status !== null && e.status !== "") {
        labelKey.push({ ...e });
      }
    });
  labelKey.sort((a, b) => {
    let fa = a.type,
      fb = b.type;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  if (labelKey !== undefined) {
    for (let data of labelKey) {
      if (!labels.includes(data.type)) {
        labels.push(data.type);
      }
    }
    for (let labe of labels) {
      let Datas = labelKey.filter((ele) => ele.type == labe);
      let Opening = Datas.find((ele) => ele.status == "OPEN");
      let Closeing = Datas.find((ele) => ele.status == "CLOSED");
      let Progressing = Datas.find((ele) => ele.status == "IN_PROGRESS");

      if (value && value[0]?.id == "status") {
        In_Open.push(Opening?.count || 0);
        In_Close.push(Closeing?.count || 0);
        In_Progress.push(Progressing?.count || 0);
      } else if (value && value[0].id == "amount") {
        In_Open.push(Opening?.amount || 0);
        In_Close.push(Closeing?.amount || 0);
        In_Progress.push(Progressing?.amount || 0);
      }
    }
  }
  //

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
            width: "94%",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                background: "#FFDD76",
                height: "15px",
                width: "15px",
                borderRadius: "5%",
              }}
            ></div>
            <span>In Open</span>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                background: "#516BEB",
                height: "15px",
                width: "15px",
                borderRadius: "5%",
              }}
            ></div>
            <span>In Progress</span>
          </div>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                background: "#82B073",
                height: "15px",
                width: "15px",
                borderRadius: "5%",
              }}
            ></div>
            <span>Close</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            flex: "1",
          }}
        >
          <div
            style={{
              // height:
              //   data && data.data && data.data.doc.length > 2
              //     ? "270px"
              //     : "auto",
              height:
                data && data.data && data.data.doc && data.data.doc.length > 4
                  ? "250px"
                  : "auto",
              padding: "0px 9px",
              marginTop: "10px",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            <div style={{ height: `${containerHeight}px` }}>
              <Bar
                options={{
                  indexAxis: "y",
                  responsive: true,
                  maintainAspectRatio: false,

                  plugins: {
                    legend: {
                      display: false,
                      position: "top",
                    },
                    title: {
                      display: false,
                      text: "Chart.js Horizontal Bar Chart",
                    },
                  },
                  layout: {
                    padding: {
                      right: 8,
                    },
                  },

                  scales: {
                    x: {
                      display: false,
                      beginAtZero: true,
                      grid: {
                        drawTick: false,
                        drawBorder: false,
                      },
                      ticks: {
                        display: false,
                      },
                      stacked: true,
                      // position: "top",
                      afterFit: (ctx) => {
                        ctx.height = 0;
                      },
                    },
                    y: {
                      stacked: true,
                      beginAtZero: true,
                      afterFit: (ctx) => {
                        setIslenght(() => ctx?.chart?.chartArea?.left);
                      },
                    },
                    xAxes: [
                      {
                        stacked: true,
                        gridLines: {
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "probability",
                        },
                        stacked: true,

                        type: "linear",
                      },
                    ],
                  },
                  onClick: handleClick,
                }}
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Open",
                      backgroundColor: "#FFDD76",
                      data: In_Open,
                      borderRadius: 15,
                    },

                    {
                      label: "In-Progress",
                      backgroundColor: "#516BEB",
                      data: In_Progress,
                      borderRadius: 15,
                    },
                    {
                      label: "Close",
                      backgroundColor: "#82B073",
                      borderRadius: 15,
                      data: In_Close,
                    },
                  ],
                }}
                id="chart"
              />
            </div>
          </div>
          <div
            style={{
              height: "73px",
              padding: "0px 9px",
              maxHeight: "300px",
            }}
          >
            <Bar
              // options={{
              //   indexAxis: "y",
              //   responsive: true,
              //   maintainAspectRatio: false,
              //   plugins: {
              //     legend: {
              //       display: false,
              //       position: "top",
              //     },
              //     title: {
              //       display: false,
              //       text: "Chart.js Horizontal Bar Chart",
              //     },
              //   },
              //   scales: {
              //     x: {
              //       stacked: true,
              //       // position: "top",
              //       afterFit: (ctx) => {
              //         ctx.height = 300;
              //       },
              //     },

              //     y: {
              //       stacked: true,
              //       beginAtZero: true,
              //       afterFit: (ctx) => {
              //         ctx.width = islenght || 60;
              //       },
              //       grid: {
              //         drawTick: false,
              //       },
              //     },
              //     xAxes: [
              //       {
              //         stacked: true,
              //         gridLines: {
              //           display: false,
              //         },
              //       },
              //     ],
              //     yAxes: [
              //       {
              //         scaleLabel: {
              //           display: true,
              //           labelString: "probability",
              //         },
              //         stacked: true,

              //         type: "linear",
              //       },
              //     ],
              //   },
              //   onClick: handleClick
              // }}
              // data={{
              //   // labels: label,
              //   // datasets: [
              //   //   {
              //   //     data: [
              //   //       // ...emailValue,
              //   //       // ...smsValue,
              //   //       // ...letterValue,
              //   //       // ...whatsappValue,
              //   //       ...totalCount,
              //   //       // 1000,
              //   //     ],
              //   //   },
              //   // ],
              //   datasets: [
              //     {
              //
              //
              //     },

              //     {

              //     },
              //     {
              //      ,

              //       data: closed,
              //     },
              //   ],
              // }}
              options={{
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                    position: "top",
                  },
                  title: {
                    display: false,
                    text: "Chart.js Horizontal Bar Chart",
                  },
                },
                scales: {
                  x: {
                    stacked: true,
                    // position: "top",
                    afterFit: (ctx) => {
                      ctx.height = 300;
                    },
                  },

                  y: {
                    stacked: true,
                    beginAtZero: true,
                    afterFit: (ctx) => {
                      ctx.width = islenght || 360;
                    },
                    grid: {
                      drawTick: false,
                    },
                  },
                  xAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "probability",
                      },
                      stacked: true,

                      type: "linear",
                    },
                  ],
                },
              }}
              data={{
                // labels: labels,
                datasets: [
                  {
                    label: "Open",
                    backgroundColor: "#FFDD76",
                    data: In_Open,
                    borderRadius: 15,
                  },

                  {
                    label: "In-Progress",
                    backgroundColor: "#516BEB",
                    data: In_Progress,
                    borderRadius: 15,
                  },
                  {
                    label: "Close",
                    backgroundColor: "#82B073",
                    borderRadius: 15,
                    data: In_Close,
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
//
export default DisputeHorizntalBar;
