export const ADD_PREDEFINED_PAYMENT_ADHOC_UPLOAD =
  "ADD_PREDEFINED_PAYMENT_ADHOC_UPLOAD";
export const UPDATE_MAP_FIELDS = "UPDATE_MAP_FIELDS";
export const UPDATE_FORMAT_DATA = "UPDATE_FORMAT_DATA";
export const PREDEFINED_PAYMENT_DEFAULT_DATA =
  "PREDEFINED_PAYMENT_DEFAULT_DATA";
export const UPDATE_SYSTEM_FIELDS = "UPDATE_SYSTEM_FIELDS";
export const UPDATE_TEMPLATE_DATA_LIST = "UPDATE_TEMPLATE_DATA_LIST";
export const PREDEFINED_PAYMENT_INITIALSTATE_STATE =
  "PREDEFINED_PAYMENT_INITIALSTATE_STATE";
export const ADHOC_PRE_PAYMENT_REFETCH = "ADHOC_PRE_PAYMENT_REFETCH";
export const SET_AU_FILTER = "SET_AU_FILTER";
export const ADHOC_FILTER = "ADHOC_FILTER";
export const PREDEFINED_PAYMENT_VALIDATION_ADHOC_FILTER =
  "PREDEFINED_PAYMENT_VALIDATION_ADHOC_FILTER";
export const PREDEFINED_PAYMENT_BUSINESS_UNIT =
  "PREDEFINED_PAYMENT_BUSINESS_UNIT";
export const FORMULA_FIELDS = "FORMULA_FIELDS";
export const DELETE_FORMULA_FIELDS = "DELETE_FORMULA_FIELDS";
export const UPDATE_FORMULA_FIELDS = "UPDATE_FORMULA_FIELDS";
export const UPDATE_PRE_PAYMENT_MAPPING_FIELDS =
  "UPDATE_PRE_PAYMENT_MAPPING_FIELDS";

export const setPredefinedPaymentAdhocUpload = (payload) => {
  return {
    type: ADD_PREDEFINED_PAYMENT_ADHOC_UPLOAD,
    payload,
  };
};

export const setPredefinedPaymentInitialAdhocState = () => {
  return {
    type: PREDEFINED_PAYMENT_INITIALSTATE_STATE,
  };
};

export const predefinedPaymentDefaultData = ({
  index,
  defaultData,
  referance,
}) => {
  return {
    type: PREDEFINED_PAYMENT_DEFAULT_DATA,
    payload: { index, defaultData, referance },
  };
};

export const predefindPaymentValidationFields = () => {
  return {
    type: PREDEFINED_PAYMENT_VALIDATION_ADHOC_FILTER,
  };
};

export const predefinedPaymentBusinessUnit = ({ rdBussinessUnit }) => {
  return {
    type: PREDEFINED_PAYMENT_BUSINESS_UNIT,
    payload: rdBussinessUnit,
  };
};

export const setAdhocFilter = (data) => {
  return {
    type: ADHOC_FILTER,
    payload: data,
  };
};

export const setAdhocUploadFilter = (data) => {
  return {
    type: SET_AU_FILTER,
    payload: data,
  };
};

export const predefindPaymentUploadMappingData = (payload) => {
  return {
    type: UPDATE_PRE_PAYMENT_MAPPING_FIELDS,
    payload,
  };
};

export const updateSystemField = ({ index, systemField, type }) => {
  return {
    type: UPDATE_SYSTEM_FIELDS,
    payload: { index, systemField, type },
  };
};

export const updateFormatData = ({ index, formatData }) => {
  return {
    type: UPDATE_FORMAT_DATA,
    payload: { index, formatData },
  };
};

export const updateTemplateDataList = (templateData) => {
  return {
    type: UPDATE_TEMPLATE_DATA_LIST,
    payload: { ...templateData },
  };
};

export const adhocPrePaymentRefetchTable = () => {
  return {
    type: ADHOC_PRE_PAYMENT_REFETCH,
  };
};

export const formulaDispachFn = (payload) => {
  return {
    type: FORMULA_FIELDS,
    payload,
  };
};

export const deleteFormulaField = (payload) => {
  return {
    type: DELETE_FORMULA_FIELDS,
    payload,
  };
};

export const updateFormulaField = (payload) => {
  return {
    type: UPDATE_FORMULA_FIELDS,
    payload,
  };
};
