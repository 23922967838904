import React from "react";

const FullScreen = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 11.5H0.25V17.75H6.5V15.25H2.75V11.5ZM0.25 6.5H2.75V2.75H6.5V0.25H0.25V6.5ZM15.25 15.25H11.5V17.75H17.75V11.5H15.25V15.25ZM11.5 0.25V2.75H15.25V6.5H17.75V0.25H11.5Z"
        fill="#333860"
      />
    </svg>
  );
};

export default FullScreen;
