import React from "react";

const CustomerStatus = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9999 8.00008C10.1726 8.00008 9.49988 7.32732 9.49988 6.50005V4.50002C9.49988 3.67276 10.1726 3 10.9999 3C11.8272 3 12.4999 3.67276 12.4999 4.50002V6.50005C12.4999 7.32732 11.8269 8.00008 10.9999 8.00008ZM10.9999 4.00002C10.7241 4.00002 10.4999 4.22452 10.4999 4.50002V6.50005C10.4999 6.77556 10.7241 7.00006 10.9999 7.00006C11.2757 7.00006 11.4999 6.77556 11.4999 6.50005V4.50002C11.4999 4.22452 11.2757 4.00002 10.9999 4.00002Z"
        fill="#516beb"
      />
      <path
        d="M10.9999 19.0001C10.1726 19.0001 9.49988 18.3273 9.49988 17.5001V15.5C9.49988 14.6728 10.1726 14 10.9999 14C11.8272 14 12.4999 14.6728 12.4999 15.5V17.5001C12.4999 18.3273 11.8269 19.0001 10.9999 19.0001ZM10.9999 15C10.7241 15 10.4999 15.2243 10.4999 15.5V17.5001C10.4999 17.7758 10.7241 18.0001 10.9999 18.0001C11.2757 18.0001 11.4999 17.7758 11.4999 17.5001V15.5C11.4999 15.2243 11.2757 15 10.9999 15Z"
        fill="#516beb"
      />
      <path
        d="M7.81778 9.31251C7.41703 9.31251 7.04027 9.1565 6.75702 8.873L5.34274 7.45898C4.75799 6.87372 4.75799 5.9222 5.34274 5.33769C5.626 5.05444 6.00275 4.89844 6.40351 4.89844C6.80427 4.89844 7.18102 5.05444 7.46428 5.33794L8.87855 6.75197C9.46331 7.33698 9.46331 8.28874 8.87855 8.87325C8.59504 9.1565 8.21854 9.31251 7.81778 9.31251ZM6.40376 5.89845C6.27001 5.89845 6.14451 5.95045 6.05001 6.04496C5.9555 6.13946 5.90375 6.26496 5.90375 6.39821C5.90375 6.53171 5.95575 6.65747 6.05026 6.75197L7.46453 8.16599C7.65353 8.35499 7.98304 8.35499 8.17204 8.16599C8.36679 7.97124 8.36679 7.65398 8.17179 7.45898L6.75752 6.04496C6.66276 5.95045 6.53726 5.89845 6.40376 5.89845Z"
        fill="#516beb"
      />
      <path
        d="M15.5962 17.1016C15.1954 17.1016 14.8189 16.9456 14.5354 16.6621L13.1214 15.248C12.8379 14.9648 12.6819 14.5878 12.6819 14.1873C12.6819 13.7865 12.8381 13.41 13.1214 13.1265C13.4044 12.8435 13.7812 12.6875 14.1819 12.6875C14.5827 12.6875 14.9592 12.8435 15.2424 13.1268L16.6567 14.541C17.2415 15.1258 17.2415 16.0776 16.6564 16.6621C16.3734 16.9456 15.9967 17.1016 15.5962 17.1016ZM14.1819 13.6875C14.0484 13.6875 13.9227 13.7395 13.8284 13.8338C13.7339 13.9283 13.6819 14.0538 13.6819 14.1873C13.6819 14.3208 13.7339 14.4465 13.8284 14.541L15.2424 15.9551C15.4312 16.1438 15.7604 16.1441 15.9494 15.9551C16.1444 15.76 16.1447 15.4428 15.9497 15.248L14.5354 13.8338C14.4412 13.7395 14.3154 13.6875 14.1819 13.6875Z"
        fill="#516beb"
      />
      <path
        d="M6.4998 12.5003H4.49977C3.67226 12.4998 2.99975 11.827 3 11.0003C3.00025 10.1733 3.67301 9.5005 4.49952 9.5005L6.4998 9.5C7.32732 9.50075 8.00033 10.1738 8.00033 11.0003C7.99958 11.8278 7.32632 12.5003 6.4998 12.5003ZM6.49955 10.5L4.50002 10.5005C4.22427 10.5005 4.00002 10.7248 4.00002 11.0008C4.00002 11.2763 4.22402 11.5003 4.50002 11.5003H6.4998C6.77556 11.5003 7.00006 11.2758 7.00031 10.9998C7.00031 10.7245 6.77556 10.5003 6.49955 10.5Z"
        fill="#516beb"
      />
      <path
        d="M17.4999 12.4998H15.5004H15.4999C15.0991 12.4998 14.7226 12.3438 14.4391 12.0603C14.1559 11.7768 13.9999 11.4 14.0004 10.9993C14.0004 10.5993 14.1561 10.2225 14.4394 9.93926C14.7226 9.656 15.0994 9.5 15.5001 9.5H17.5002C18.3272 9.5 18.9999 10.1728 18.9999 11C19.0002 11.4008 18.8442 11.7773 18.5609 12.0605C18.2774 12.3438 17.9007 12.4998 17.4999 12.4998ZM15.4999 11.4998H17.4999C17.6337 11.4998 17.7594 11.4478 17.8539 11.3533C17.9479 11.259 17.9999 11.1338 17.9999 11.0003C17.9999 10.7243 17.7757 10.5 17.5002 10.5H15.5001C15.3666 10.5 15.2411 10.552 15.1466 10.6465C15.0524 10.7408 15.0004 10.8663 15.0004 10.9995C15.0004 11.1335 15.0521 11.2588 15.1464 11.3533C15.2406 11.4478 15.3664 11.4998 15.4999 11.4998Z"
        fill="#516beb"
      />
      <path
        d="M6.40322 17.0943C6.00271 17.0943 5.62596 16.9383 5.34295 16.655C4.75844 16.0692 4.75869 15.118 5.34295 14.5335L6.75672 13.1189C7.04048 12.8357 7.41723 12.6797 7.81799 12.6797C8.21875 12.6797 8.5955 12.8357 8.87851 13.1189C9.46277 13.7047 9.46277 14.6562 8.87826 15.2405L7.46398 16.655C7.18048 16.9383 6.80397 17.0943 6.40322 17.0943ZM7.81799 13.6797C7.68424 13.6797 7.55849 13.732 7.46373 13.8265L6.05021 15.2405C5.85521 15.4352 5.85546 15.753 6.05046 15.9482C6.23847 16.1367 6.56772 16.1365 6.75697 15.9477L8.171 14.5335C8.366 14.3387 8.36575 14.021 8.17075 13.8255C8.07724 13.7317 7.95174 13.6797 7.81799 13.6797Z"
        fill="#516beb"
      />
      <path
        d="M14.1819 9.31251C13.7809 9.31251 13.4041 9.15625 13.1209 8.8725C12.8381 8.58975 12.6819 8.21299 12.6819 7.81248C12.6819 7.41173 12.8379 7.03522 13.1211 6.75197L14.5354 5.33769C14.8187 5.05444 15.1952 4.89844 15.5957 4.89844C15.9964 4.89844 16.3732 5.05469 16.6567 5.33819C16.94 5.6212 17.0962 5.9977 17.0962 6.39846C17.0962 6.79897 16.94 7.17547 16.6567 7.45923L15.2427 8.87275C14.9597 9.15625 14.5829 9.31251 14.1819 9.31251ZM15.5959 5.8982C15.4624 5.8982 15.3372 5.9502 15.2427 6.04446L13.8284 7.45873C13.7342 7.55323 13.6822 7.67873 13.6822 7.81223C13.6822 7.94574 13.7342 8.07099 13.8284 8.16524C14.0179 8.35524 14.3469 8.35474 14.5357 8.16574L15.9497 6.75197C16.0442 6.65722 16.0964 6.53146 16.0964 6.39796C16.0964 6.26471 16.0444 6.13946 15.9502 6.04521C15.8549 5.9502 15.7294 5.8982 15.5959 5.8982Z"
        fill="#516beb"
      />
    </svg>
  );
};

export default CustomerStatus;
