export default function Payment({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M11.8624 8.75626C11.8624 8.83959 11.8489 8.91667 11.8218 8.9875C11.7947 9.05834 11.7478 9.12709 11.6812 9.19375L8.05615 12.8188C7.93533 12.9396 7.7895 13 7.61866 13C7.44782 13 7.30199 12.9396 7.18116 12.8188C7.06033 12.6979 6.99991 12.55 6.99991 12.375C6.99991 12.2 7.06033 12.0521 7.18116 11.9313L10.3562 8.75626L7.18116 5.58124C7.06033 5.46042 6.99991 5.31459 6.99991 5.14375C6.99991 4.97292 7.06033 4.82709 7.18116 4.70626C7.30199 4.58542 7.4499 4.525 7.6249 4.525C7.7999 4.525 7.94782 4.58542 8.06866 4.70626L11.6812 8.31876C11.7478 8.38542 11.7947 8.45417 11.8218 8.52501C11.8489 8.59584 11.8624 8.67292 11.8624 8.75626Z"
        fill={color}
      />
    </svg>
  );
}
