import { NEW, EDIT } from "../../constants";
import { ADD_STATE } from "./action";
import {
  EDIT_DATA,
  OPEN_MODAL,
  CLOSE_MODAL,
  DAIRY_NOTE_ID,
  RESET_DIARY_NOTE_STATE,
  REFETCH_DIARY_NOTES,
  ACTION_TYPE,
  CANCEL_NOTES_FORM,
} from "./action";

const formInitialState = {
  notes: "",
  is_enable_alert_and_follow_up: false,
  category: [{ id: "general", label: "General" }],
  type: "INFORMATIONAL",
};

const initialState = {
  formData: { ...formInitialState },
  editData: {},
  isOpen: false,
  actionType: "",
  noteId: "",
  refetchDiaryNotes: 0,
};

export const customerDairyNoteReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CANCEL_NOTES_FORM: {
      return {
        initialState,
        isOpen: true,
      };
    }

    case RESET_DIARY_NOTE_STATE: {
      return initialState;
    }

    case REFETCH_DIARY_NOTES: {
      return {
        ...state,
        refetchDiaryNotes: state.refetchDiaryNotes + 1,
        actionType: "",
      };
    }

    case DAIRY_NOTE_ID: {
      return {
        ...state,
        noteId: payload,
      };
    }
    case ADD_STATE: {
      return { ...state, actionType: NEW };
    }
    case EDIT_DATA: {
      return {
        ...state,
        formData: { ...payload },
        actionType: EDIT,
        isOpen: true,
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
        formData: { ...formInitialState },
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false,
        formData: { ...formInitialState },
      };
    }
    case ACTION_TYPE: {
      return {
        ...state,
        actionType: NEW,
        formData: { ...formInitialState },
      };
    }
    default:
      return state;
  }
};
