import React, { useEffect, useContext, useMemo, useState, useRef } from "react";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { customerInvoiceOverview } from "../../../../services";
import { Button } from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { HeadingXLarge, ParagraphLarge } from "baseui/typography";
// import { TextButton, KIND, SIZE } from "../../components/TextButton";
import { TextButton, KIND, SIZE } from "../../../../components/TextButton";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import CardMerge from "../../../../components/Card";
import getSelectValues from "../../../../utils/getSelectValues";
import { IconButton } from "../../../../components/IconButton";
import { SelectBox } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import { useIntl } from "react-intl";
import { useForm, Controller, set } from "react-hook-form";
import moment from "moment";
// import DownArrow from "../../../../utils/UpArrow";
import UpArrow from "../../../../assets/img/svg/UpArrow";
import DownArrow from "../../../../assets/img/svg/DownArrow";
import { gettingDisputeTableData } from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { getGridStructure } from "../../../../services";
import columnMapper from "../../../../utils/mapper";
import Cancel from "../../../../assets/img/svg/Template/Cancel";
import TableDeleteButton from "../../../../assets/img/svg/TableDeleteButton";
import { useSelector, useDispatch } from "react-redux";
import { deleteRowData } from "../../../../redux/customerOverview/dispute/action";
import { CONNECTING } from "ws";

export const UpdatePromiseStatus = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  function close() {
    setIsOpen(false);
  }
  const intl = useIntl();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {},
  });
  const onSubmit = (data) => {
    let values = {
      ...data,
      payment_status: getSelectValues(data.payment_status),
    };
  };
  return (
    <>
      {/* <Button>Open Modal</Button> */}
      {/* <IconButton
        onClick={() => setIsOpen(true)}
        size={SIZE.compact}
        style={{ borderRadius: "20px" }}
        type="button"
      >
        <DownArrow />
        <UpArrow />
      </IconButton> */}
      <IconButton
        onClick={() => setIsOpen(true)}
        size={SIZE.default}
        type="button"
        kind={KIND.tertiary}
      >
        <DownArrow />
        {/* <DownArrow /> */}
      </IconButton>

      <Modal
        onClose={close}
        isOpen={isOpen}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "30px",
              width: "325px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              // boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {/* Heading */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <OnHoldModalSvg /> */}
              <HeadingXLarge>Update Promise Status</HeadingXLarge>
            </div>

            {/* Section One */}
            <div style={{ paddingTop: "15px " }}>
              <div>
                <div style={{ width: "" }}>
                  <Controller
                    defaultValues={[]}
                    name="payment_status"
                    control={control}
                    // rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "payment_status",
                        })}
                        placeholder={intl.formatMessage({
                          id: "payment_status",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={[
                          { label: "Thilash", id: "thilash" },
                          { label: "Maxyfi", id: "maxyfi" },
                        ]}
                      />
                    )}
                  />
                </div>
              </div>
              {/* Section Two */}
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Controller
                defaultValue={""}
                name="received_amount"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <TextBox
                      {...field}
                      // type="number"
                      fullWidth
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "received_amount",
                      })}
                      placeholder={intl.formatMessage({
                        id: "received_amount",
                      })}
                      value={field.value}
                    />
                  </>
                )}
              />
            </div>
            <div
              style={{
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div style={{ width: "126px" }}>
                <TextButton
                  type="button"
                  onClick={() => close()}
                  fullWidth
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "126px" }}>
                <TextButton type="submit" fullWidth size={SIZE.compact}>
                  Save
                </TextButton>
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

export const COLUMNDATA = [
  {
    invoice_number: "1",
    invoice_due_date: "31/01/2022",
    status: "in-Progress",
    due_days: "23",
    invoice_total_amount: "123",
  },
  {
    invoice_number: "2",
    invoice_date: "01-Feb-2022",
    invoice_due_date: "31-Mar-2022",
    status: "Broken",
    due_days: "23",
    invoice_total_amount: "123",
  },
  {
    invoice_number: "3",
    invoice_date: "01-Feb-2022",
    invoice_due_date: "31-Mar-2022",
    status: "Partial",
    due_days: "23",
    invoice_total_amount: "123",
  },
];

const DisputeTable = ({ getParams }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [table, setTable] = React.useState([]);
  const [columnData, setColumnData] = React.useState([]);

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  let ref = useRef();

  // const getParams = getSearchParams.get("customers");

  let { currentOrganization } = useContext(MaxyfiContext);
  const { id } = useParams();
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});
  const [deleteId, setDeleteId] = useState();
  const [width, setWidth] = useState(0);

  function close() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();
  const { data, isFetched, isError } = useQuery(
    [
      `INVOICE_DISPUTE_DATA${getParams}`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: querySortBy,
        filters: queryFilter,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      filters = {
        ...filters,
      };
      return await customerInvoiceOverview({
        pageIndex,
        sortBy,
        pageSize,
        filters,
        organization: currentOrganization,
        customer_id: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const disputeInvoiceSelectedRowData = useSelector(
    (s) => s.customerDisputeReducer.selectedInvoice
  );

  const currentDisputeData = useSelector(
    (s) => s.customerDisputeReducer.currentDisputeData
  );

  const invoiceFullData = (data && data.data.docs) || [];

  const tableData = React.useMemo(() => {
    let fullTableDatas = [];
    if (currentDisputeData && currentDisputeData.invoices) {
      fullTableDatas =
        invoiceFullData &&
        invoiceFullData?.filter(
          (e) =>
            currentDisputeData && currentDisputeData?.invoices?.includes(e._id)
        );
    }
    return fullTableDatas;
  }, [data, currentDisputeData]);

  // const finalData = tableData.splice(deleteId, 1);
  //
  // tableData = [...tableData];

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "co_action_dispute_invoice",
      });

      setColumnData(userListVal.data.doc.table);
    };
    userListApi();
  }, []);
  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);
  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: false,
      isExpandedHeader: false,
      width: width,
    });
    return columns;
  }, [columnData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: tableData,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  //   useEffect(() => {
  //     if (pageIndex == queryPageIndex) {
  //       gotoPage(0);
  //     }
  //     setQueryPageIndex(pageIndex);
  //     setQueryPageSize(pageSize);
  //     setQuerySortBy(
  //       sortBy[0]
  //         ? sortBy[0].desc
  //           ? "-" + sortBy[0].id
  //           : "" + sortBy[0].id
  //         : "-_id"
  //     );
  //     let filterObj = {};
  //     filters.map((e) => {
  //       if (e.value) {
  //         if (Array.isArray(e.value)) {
  //           filterObj[e.id] = e.value.filter((val) => val);
  //         } else {
  //           filterObj[e.id] = e.value;
  //         }
  //       }
  //     });
  //     setQueryFilter(filterObj);
  //   }, [pageIndex, pageSize, sortBy, filters]);

  // TODO TO CHANGE MIN AND MAX WIDTH

  // {currentDisputeData?.invoices?.length > 0 || !undefined ? (

  return (
    <div>
      <div
        overrides={{
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
      >
        <div style={{ width: "", background: "#FFFFFF", height: "" }}>
          <div>
            <div
              className="customer-profile-tab__table"
              style={{
                gridColumn: "1/6",
                gridRow: "2/13",
                overflowY: "unset",
                // height: "445px",
              }}
            >
              <div
                className="cp-tab__table-wrapper"
                {...getTableProps()}
                ref={ref}
              >
                {currentDisputeData &&
                currentDisputeData.invoices &&
                currentDisputeData.invoices.length > 0 ? (
                  <div className="cp-tab__table-header">
                    {headerGroups.map((headerGroup) => (
                      <>
                        <div
                          className="cp-tab__table-header-wrapper-dispute_table"
                          style={{
                            backgroundColor: "#F9F9F9",
                            border: "1px solid #CDCED9",
                          }}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          <div
                            className="cp-tab__table-header-content"
                            style={{
                              minWidth: "50%",
                              maxWidth: "100%",
                            }}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <div
                                className="cp-tab__table-header-cell-content"
                                style={{
                                  flex: "1",
                                }}
                              >
                                <div
                                  className="cp-tab__table-header-cell-review-promise"
                                  {...column.getHeaderProps()}
                                >
                                  {column.render("Header")}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                ) : (
                  <div></div>
                )}

                <div
                  className="cp-tab__table-body cp-tab__table-body--no-scroll"
                  style={{ height: "100%" }}
                  {...getTableBodyProps()}
                >
                  {page.map((row) => {
                    prepareRow(row);
                    const rowProps = row.getRowProps();
                    return (
                      <React.Fragment key={rowProps.key}>
                        <div
                          className="cp-tab__table-body-content-dispute"
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <div
                                className="cp-tab__table-body-cell"
                                style={{ height: "200px" }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </div>
                            );
                          })}
                          <div
                            className="cp-tab__table-body-content-dispute__remove"
                            onClick={() => {
                              setDeleteId(row.id);
                              dispatch(deleteRowData(row));
                            }}
                          >
                            {/* <UpdatePromiseStatus />
                             */}
                            {/* <Cancel /> */}
                            <TableDeleteButton />
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeTable;
