function ThreeLine() {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 11C16 11.5523 15.5523 12 15 12H5C4.44771 12 4 11.5523 4 11C4 10.4477 4.44772 10 5 10H15C15.5523 10 16 10.4477 16 11ZM2 11C2 11.5523 1.55228 12 1 12C0.447715 12 0 11.5523 0 11C0 10.4477 0.447715 10 1 10C1.55228 10 2 10.4477 2 11ZM16 6C16 6.55228 15.5523 7 15 7H5C4.44771 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5H15C15.5523 5 16 5.44772 16 6ZM2 6C2 6.55228 1.55228 7 1 7C0.447715 7 0 6.55228 0 6C0 5.44772 0.447715 5 1 5C1.55228 5 2 5.44772 2 6ZM16 1C16 1.55228 15.5523 2 15 2H5.023C4.47072 2 4.023 1.55228 4.023 1C4.023 0.447715 4.47072 0 5.023 0H15C15.5523 0 16 0.447715 16 1ZM2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default ThreeLine;
