import {
  ADD_SMS_ATTACHMENT,
  SMS_UNSELECTED_DOCUMENT_DATA,
  UPDATE_SMS_ATTACHMENT,
  SMS_DOCUMENT_DRIVE_DATA,
  SMS_ACTION_INITAL,
} from "./actions";

const initialState = {
  SMSAttachment: [],
  qaInvoiceState: false,
  qaInvoiceType: "",
  isActionFrom: "",
};

const SMSActionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_SMS_ATTACHMENT: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.SMSAttachment;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;

        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        SMSAttachment: [...currentFiles, ...files],
      };
    }

    case UPDATE_SMS_ATTACHMENT: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.SMSAttachment.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let SMSAttachment = state.SMSAttachment;
        let currentFile = SMSAttachment[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        SMSAttachment[fileIndex] = currentFile;

        return { ...state, SMSAttachment: SMSAttachment };
      }

      return state;
    }

    case SMS_UNSELECTED_DOCUMENT_DATA: {
      let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.SMSAttachment.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        SMSAttachment: filteredData,
      };
    }

    case SMS_DOCUMENT_DRIVE_DATA:
      let attachData = [...state.SMSAttachment];
      attachData.push(...payload);

      return {
        ...state,
        SMSAttachment: attachData,
      };

    case SMS_ACTION_INITAL:
      return {
        ...initialState,
        SMSAttachment: initialState.SMSAttachment,
      };
    // case UPDATE_EMAIL_QUICK_ACTION_INVOICE_ATTACHMENT_FROM_DESKTOP: {
    //   let { tempId, ...updateObj } = payload;

    //   let fileIndex = state.SMSAttachment.findIndex(
    //     (fl) => fl.tempId === tempId
    //   );

    //   if (fileIndex >= 0) {
    //     let SMSAttachment = state.SMSAttachment;
    //     let currentFile = SMSAttachment[fileIndex];
    //     currentFile = { ...currentFile, ...updateObj };
    //     SMSAttachment[fileIndex] = currentFile;

    //     return {
    //       ...state,
    //       SMSAttachment: SMSAttachment,
    //     };
    //   }

    //   return state;
    // }

    // case REMOVE_EMAIL_ATTACHMENT_INVOICE_QUICK_ACTION: {
    //   let attachData = { ...state };
    //   let filteredData = [];
    //   let unSelectedData = [...payload];

    //   if (unSelectedData && unSelectedData.length > 0) {
    //     let filteringUnselected = state.SMSAttachment.filter(
    //       (e) => !payload.some((i) => e.fileRef === i.fileRef)
    //     );

    //     filteredData.push(...filteringUnselected);
    //   }
    //   return {
    //     ...state,
    //     SMSAttachment: filteredData,
    //   };
    // }

    default:
      return initialState;
  }
};

export default SMSActionReducer;
