import { DRAWER_CHECK_IN } from "./action";

const initialState = {
  checkInIsOpen: false,
};

const checkInReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DRAWER_CHECK_IN: {
      return {
        ...state,
        checkInIsOpen: payload,
      };
    }

    default:
      return state;
  }
};

export default checkInReducer;
