export const LETTER_GET_CS_GRID_STRUCTURE = "LETTER_GET_CS_GRID_STRUCTURE";
export const LETTER_UPDATE_CS_TABLE_DRAFT_STATE =
  "LETTER_UPDATE_CS_TABLE_DRAFT_STATE";
export const LETTER_SET_CS_VIEW = "LETTER_SET_CS_VIEW";
export const LETTER_NESTED_CS_ACTION_TYPE = "LETTER_NESTED_CS_ACTION_TYPE";
export const LETTER_RESET_INITIAL_STATE = "LETTER_RESET_INITIAL_STATE";
export const LETTER_RESET_CUSTOMER_NAVLINK = "LETTER_RESET_CUSTOMER_NAVLINK";
export const SET_CS_GRID_AND_VIEW_LETTER = "SET_CS_GRID_AND_VIEW_LETTER";
export const LETTER_SET_CS_SORT = "LETTER_SET_CS_SORT";
export const LETTER_SET_CS_PAGE_INDEX = "LETTER_SET_CS_PAGE_INDEX";
export const LETTER_SET_CS_PAGE_SIZE = "LETTER_SET_CS_PAGE_SIZE";
export const LETTER_SET_CS_FILTER = "LETTER_SET_CS_FILTER";
export const LETTER_SET_GLOBAL_FILTER = "LETTER_SET_GLOBAL_FILTER";
export const LETTER_NEEDS_ACTION_FILTERS = "LETTER_NEEDS_ACTION_FILTERS";
export const LETTER_CUSTOMER_STAGE_FILTERS = "LETTER_CUSTOMER_STAGE_FILTERS";
export const LETTER_DASHBOARD_NEED_ACTION_FILTER =
  "LETTER_DASHBOARD_NEED_ACTION_FILTER";
export const LETTER_DASHBOARD_CUSTOMER_ACTION_STAGE =
  "LETTER_DASHBOARD_CUSTOMER_ACTION_STAGE";
export const TABLE_COLUMN_ORDER = "TABLE_COLUMN_ORDER";
export const COLUMN_WIDTH = "COLUMN_WIDTH";
export const SET_CS_PINNED_VIEW = "SET_CS_PINNED_VIEW";

export const setLetterPinnedView = (payload = []) => {
  return {
    type: SET_CS_PINNED_VIEW,
    payload,
  };
};

export const setTableWidth = (width) => {
  return {
    type: COLUMN_WIDTH,
    payload: width,
  };
};

export const setNavlinkReset = () => {
  return {
    type: LETTER_RESET_CUSTOMER_NAVLINK,
  };
};

export const setTableHeaderOrder = (tabHead) => {
  return {
    type: TABLE_COLUMN_ORDER,
    payload: tabHead,
  };
};

export const setDashBoardDashboardLetterActionStage = (customerStage) => {
  return {
    type: LETTER_DASHBOARD_CUSTOMER_ACTION_STAGE,
    payload: customerStage,
  };
};

export const setDashBoardNeedActionFilter = (dashFilter) => {
  return {
    type: LETTER_DASHBOARD_NEED_ACTION_FILTER,
    payload: dashFilter,
  };
};

export const setLetterStageFilter = (cusStage) => {
  return {
    type: LETTER_CUSTOMER_STAGE_FILTERS,
    payload: cusStage,
  };
};

export const setNeedsActionFilter = (selectedNeedsFilter) => {
  return {
    type: LETTER_NEEDS_ACTION_FILTERS,
    payload: selectedNeedsFilter,
  };
};

export const setGlobalFilter = (cusName) => {
  return {
    type: LETTER_SET_GLOBAL_FILTER,
    payload: cusName,
  };
};

export const setLetterStatesMaintenanceFilter = (filterData) => {
  return {
    type: LETTER_SET_CS_FILTER,
    payload: filterData,
  };
};

export const getInitialCustomerSummary = () => {
  return {
    type: LETTER_RESET_INITIAL_STATE,
  };
};

export const getNestedActionType = (typeData) => {
  return {
    type: LETTER_NESTED_CS_ACTION_TYPE,
    payload: typeData,
  };
};

export const getLetterStatesMaintenanceGridStructure = (gridStructure) => {
  return {
    type: LETTER_GET_CS_GRID_STRUCTURE,
    payload: gridStructure,
  };
};

//TODO: AB REMOVE
export const updateTableDraftState = (tableState) => {
  return {
    type: LETTER_UPDATE_CS_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const updateTableLetterStatusDraftState = (tableState) => {
  return {
    type: LETTER_UPDATE_CS_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

//TODO: AB REMOVE
export const setTableView = (viewData) => {
  return {
    type: LETTER_SET_CS_VIEW,
    payload: viewData,
  };
};

export const setLetterStatusTableView = (viewData) => {
  return {
    type: LETTER_SET_CS_VIEW,
    payload: viewData,
  };
};

export const setLetterStatesMaintenanceGridAndView = (viewData) => {
  return {
    type: SET_CS_GRID_AND_VIEW_LETTER,
    payload: viewData,
  };
};

export const setLetterStatusSort = (sort) => {
  return {
    type: LETTER_SET_CS_SORT,
    payload: sort,
  };
};

export const setLetterStatesMaintenancePageIndex = (pageIndex) => {
  return {
    type: LETTER_SET_CS_PAGE_INDEX,
    payload: pageIndex,
  };
};

export const setLetterStatusPageSize = (pageSize) => {
  return {
    type: LETTER_SET_CS_PAGE_SIZE,
    payload: pageSize,
  };
};
