import React from "react";

const FormulaIndicatorSvg = () => {
  return (
    <svg
      width="24"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="21" rx="2.5" fill="#F0F5FF" />
      <g clip-path="url(#clip0_161_72565)">
        <g clip-path="url(#clip1_161_72565)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.6239 2.30321C11.5719 1.56089 12.7934 1.33625 14.3573 1.61687L15.2429 1.77581L14.9252 3.54755L14.0392 3.38861C12.6672 3.14273 12.0659 3.46007 11.7332 3.72053C11.0003 4.29437 10.4961 5.58011 10.1852 7.69493H13.4V9.49493H9.98308C9.96202 9.74711 9.94222 10.005 9.92476 10.2741C9.66142 14.3297 9.31168 17.538 7.44508 18.8747C6.86116 19.2929 6.17788 19.5011 5.3794 19.5011C4.88962 19.5011 4.35646 19.4226 3.77578 19.2664L2.90674 19.0324L3.37474 17.2943L4.24378 17.5283C5.21686 17.7902 5.92102 17.7521 6.39712 17.4113C7.61698 16.5376 7.92154 13.3487 8.12872 10.1575C8.14348 9.93053 8.15968 9.71075 8.17678 9.49493H5.65696V7.69493H8.3647C8.74594 4.85345 9.44416 3.22679 10.6239 2.30321ZM11.4449 18.0666L11.844 18.8732L13.4573 18.0749L13.0583 17.2683C12.2258 15.5851 12.2238 13.448 13.0532 11.8242L13.4625 11.0227L11.8595 10.204L11.4501 11.0056C10.3541 13.1513 10.3521 15.8571 11.4449 18.0666ZM19.7676 18.8784L18.1645 18.0595L18.5739 17.258C19.4033 15.6342 19.4013 13.4971 18.5688 11.8139L18.1698 11.0073L19.7831 10.2092L20.1822 11.0158C21.2749 13.2251 21.2731 15.9309 20.1771 18.0768L19.7676 18.8784ZM18.3585 12.2774L18.363 12.2803L16.869 14.5363L18.6364 16.9574H16.4078L15.809 16.137L15.2657 16.9574H13.1131L13.1086 16.9546L14.6769 14.5861L12.9918 12.2774H15.2204L15.7371 12.9854L16.2059 12.2774H18.3585Z"
            fill="#516BEB"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_161_72565">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 1.5)"
          />
        </clipPath>
        <clipPath id="clip1_161_72565">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(3 1.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FormulaIndicatorSvg;
