import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextBox } from "../components/TextBox";
import { Modal, SIZE, ROLE } from "baseui/modal";
import { useIntl } from "react-intl";
import { isModalOpen } from "../redux/switchOrganization/action";
import { useDispatch, useSelector } from "react-redux";
import { HeadingXLarge } from "baseui/typography";
import { TextButton } from "../components/TextButton";
import * as ModalButton from "../components/TextButton";
import { isModalClose } from "../redux/switchOrganization/action";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { MaxyfiContext } from "./MaxyfiProvider";
import {
  einRegex,
  unallowedSpecialCharacter,
  newEinRegex,
} from "../utils/regexConfig";
import { isValidPhoneNumber } from "libphonenumber-js";
import { SelectBox } from "../components/SelectBox";
import getSelectValues from "../utils/getSelectValues";
import { useMutation } from "react-query";
import { addOrganization } from "../services";
import { toast } from "react-toastify";
import { AuthContext } from "./AuthProvider";
import { alloweSpecialCharacter } from "../utils/regexConfig";
import { useLocation, useNavigate } from "react-router-dom";

const SwitchOrganizationProvider = () => {
  const intl = useIntl();
  const switchOrganizationModalOpen = {};
  const dispatch = useDispatch();
  const isSwitchOpen = useSelector((e) => e.switchOrganization.isOpen);
  let location = useLocation();
  let navigate = useNavigate();

  let maxyfiContextValue = useContext(MaxyfiContext);
  const {
    currentOrganization,
    referenceData,
    refetch,
    switchCurrentOrganization,
  } = maxyfiContextValue;

  //TODO
  const onTokenExpired = () => {};
  const refreshToken = "";

  const validationSchema = Yup.object({
    gendral_information: Yup.object({
      org_name: Yup.string()
        .required("Required")
        .matches(unallowedSpecialCharacter, "Enter vaild Name"),
      // ein: Yup.string()
      //   .required("Required")
      //   .matches(einRegex, "Enter vaild EIN"),

      ein: Yup.string().optional().matches(newEinRegex, {
        message: "Only Alphanumeric and - is allowed",
        excludeEmptyString: true,
      }),

      default_currency: Yup.array().min(1, "Required"),
    }),
    contact_details: Yup.object({
      time_zone: Yup.array().min(1, "Required"),
      address: Yup.object({
        line_1: Yup.string()
          .required("Required")
          .matches(alloweSpecialCharacter, "Enter vaild Address"),
        city: Yup.string()
          .required("Required")
          .matches(alloweSpecialCharacter, "Enter vaild City"),
        state: Yup.string()
          .required("Required")
          .matches(alloweSpecialCharacter, "Enter vaild State"),
        zip_code: Yup.string()
          .required("Required")
          .matches(alloweSpecialCharacter, "Enter vaild Zip Code"),
        country: Yup.array().min(1, "Required"),
      }),
    }),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      gendral_information: {
        org_name: "",
        ein: "",
        default_currency: [],
      },
      contact_details: {
        time_zone: [],
        address: {
          line_1: "",
          line_2: "",
          city: "",
          state: "",
          country: [],
          zip_code: "",
        },
      },
    },
  });

  const addOrganizationData = useMutation(
    (data) => addOrganization({ ...data, organization: currentOrganization }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          // let errorData = "";

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(data.message);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        const resOrgId = data.data.doc._id;
        reset();

        refetch({
          ...maxyfiContextValue,
          setLoader: true,
          currentOrganization: currentOrganization,
        });
        switchCurrentOrganization({
          ...maxyfiContextValue,
        });
        onTokenExpired(refreshToken);
        dispatch(isModalClose());

        let destination = location.pathname.split("/");

        destination =
          destination &&
          destination[1] &&
          !["credit-notes", "uploads"].includes(destination[1])
            ? `/${destination[1]}`
            : "/customers";
        navigate(destination);
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      general_information: {
        org_name: data.gendral_information.org_name,
        ein: data.gendral_information.ein,
        default_currency: getSelectValues(
          data.gendral_information.default_currency
        ),
      },
      contact_detail: {
        time_zone: getSelectValues(data.contact_details.time_zone),
        address: {
          line_1: data.contact_details.address.line_1,
          line_2: data.contact_details.address.line_2,
          city: data.contact_details.address.city,
          state: data.contact_details.address.state,
          country: getSelectValues(data.contact_details.address.country),
          zip_code: data.contact_details.address.zip_code,
        },
      },
    };

    await addOrganizationData.mutateAsync({ values });
  };

  return (
    <div>
      <Modal
        onClose={() => {
          dispatch(isModalClose());
        }}
        isOpen={isSwitchOpen}
        animate
        autoFocus
        size={SIZE.auto}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: ({ $theme }) => ({
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              marginBottom: "0px",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ width: "850px", textAlign: "center", padding: "20px" }}>
            <HeadingXLarge>Add Organization</HeadingXLarge>
            <div style={{ display: "flex", gap: "20px" }}>
              <Controller
                name="gendral_information.org_name"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.gendral_information &&
                      errors.gendral_information.org_name &&
                      errors.gendral_information.org_name.message
                    }
                    //   error={call.errors[field.name] && call.errors[field.name].message}
                    label={intl.formatMessage({
                      id: "company_name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "company_name",
                    })}
                    value={field.value}
                  />
                )}
              />
              <Controller
                name="gendral_information.ein"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    // requiredAstric={true}
                    error={
                      errors &&
                      errors.gendral_information &&
                      errors.gendral_information.ein &&
                      errors.gendral_information.ein.message
                    }
                    //   error={call.errors[field.name] && call.errors[field.name].message}
                    label={intl.formatMessage({
                      id: "ein_gi",
                    })}
                    placeholder={intl.formatMessage({
                      id: "ein_gi",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <Controller
                name="gendral_information.default_currency"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.gendral_information &&
                      errors.gendral_information.default_currency &&
                      errors.gendral_information.default_currency.message
                    }
                    // error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "default_currency",
                    })}
                    placeholder={intl.formatMessage({
                      id: "default_currency",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["currency"]}
                  />
                )}
              />
              <Controller
                name="contact_details.time_zone"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.contact_details &&
                      errors.contact_details.time_zone &&
                      errors.contact_details.time_zone.message
                    }
                    label={intl.formatMessage({
                      id: "time_zone",
                    })}
                    placeholder={intl.formatMessage({
                      id: "time_zone",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["time_zone"]}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <Controller
                name="contact_details.address.line_1"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.contact_details &&
                      errors.contact_details.address.line_1 &&
                      errors.contact_details.address.line_1.message
                    }
                    //   error={call.errors[field.name] && call.errors[field.name].message}
                    label={intl.formatMessage({
                      id: "address_line_1_floor_number_door_street_details",
                    })}
                    placeholder={intl.formatMessage({
                      id: "address_line_1_floor_number_door_street_details",
                    })}
                    value={field.value}
                  />
                )}
              />
              <Controller
                name="contact_details.address.line_2"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    //   error={call.errors[field.name] && call.errors[field.name].message}
                    label={intl.formatMessage({
                      id: "address_line_2_area_and_locality_details",
                    })}
                    placeholder={intl.formatMessage({
                      id: "address_line_2_area_and_locality_details",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <Controller
                name="contact_details.address.city"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.contact_details &&
                      errors.contact_details.address.city &&
                      errors.contact_details.address.city.message
                    }
                    //   error={call.errors[field.name] && call.errors[field.name].message}
                    label={intl.formatMessage({
                      id: "city",
                    })}
                    placeholder={intl.formatMessage({
                      id: "city",
                    })}
                    value={field.value}
                  />
                )}
              />
              <Controller
                name="contact_details.address.state"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.contact_details &&
                      errors.contact_details.address.state &&
                      errors.contact_details.address.state.message
                    }
                    //   error={call.errors[field.name] && call.errors[field.name].message}
                    label={intl.formatMessage({
                      id: "state",
                    })}
                    placeholder={intl.formatMessage({
                      id: "state",
                    })}
                    value={field.value}
                  />
                )}
              />
              <Controller
                name="contact_details.address.country"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.contact_details &&
                      errors.contact_details.address.country &&
                      errors.contact_details.address.country.message
                    }
                    label={intl.formatMessage({
                      id: "country",
                    })}
                    placeholder={intl.formatMessage({
                      id: "country",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["country"]}
                  />
                )}
              />
              <Controller
                name="contact_details.address.zip_code"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    requiredAstric={true}
                    error={
                      errors &&
                      errors.contact_details &&
                      errors.contact_details.address.zip_code &&
                      errors.contact_details.address.zip_code.message
                    }
                    //   error={call.errors[field.name] && call.errors[field.name].message}
                    label={intl.formatMessage({
                      id: "zipcode",
                    })}
                    placeholder={intl.formatMessage({
                      id: "zipcode",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                marginTop: "15px",
                marginButton: "15px",
              }}
            >
              <div style={{ width: "180px" }}>
                <TextButton
                  fullWidth
                  size={ModalButton.SIZE.compact}
                  kind={ModalButton.KIND.tertiary}
                  onClick={() => {
                    dispatch(isModalClose());
                    reset();
                  }}
                  type="button"
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "180px" }}>
                <TextButton
                  fullWidth
                  size={ModalButton.SIZE.compact}
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  // onClick={() => {
                  //   let destination = location.pathname.split("/");

                  //   destination =
                  //     destination &&
                  //     destination[1] &&
                  //     !["credit-notes", "uploads"].includes(destination[1])
                  //       ? `/${destination[1]}`
                  //       : "/customers";
                  //   navigate(destination);
                  // }}
                >
                  Submit
                </TextButton>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default SwitchOrganizationProvider;
