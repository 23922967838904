import React from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import _, { values } from "lodash";

function CustomerPortalLink(props) {
  let { value, column, alignment, accessor, row, ...rest } = props;

  value = _.get(row.original, accessor, "");

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ minWidth: "auto", maxWidth: "200px" }}>{value}</div>
      }
    >
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
          width: "100%",
          textAlign: alignment,
          margin: "5px",
          color: "#516beb",
          textDecoration: "underline",
        }}
        onClick={() => {
          if (value && value.toString().length) {
            window.open(
              `${value}?is_internal_call=true`,
              "_blank",
              "noopener,noreferrer"
            );
          }
        }}
      >
        <div className="elip" ref={ref}>
          {value && value.toString().length ? <>Portal Link</> : " "}
        </div>
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
        width: "100%",
        textAlign: alignment,
        margin: "5px",
        color: "#516beb",
        textDecoration: "underline",
      }}
      onClick={() => {
        if (value && value.toString().length) {
          window.open(
            `${value}?is_internal_call=true`,
            "_blank",
            "noopener,noreferrer"
          );
        }
      }}
    >
      {value && value.toString().length ? <>Portal Link</> : " "}
    </Paragraph2>
  );
}
export default CustomerPortalLink;
