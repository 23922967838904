import React from "react";
const WorkFlowSVG = ({ width = 23, height = 23, color = '#333860"' }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.4182 9.44946H11.5856C10.804 9.44946 10.1666 10.0814 10.1666 10.8685V11.7222H10.1721V12.0104H6.92935C6.40829 12.0104 5.99255 11.5891 5.99255 11.0736V8.71222V8.57364V7.82531H6.21982V7.83085H7.13445C7.91604 7.83085 8.55351 7.19893 8.55351 6.41179V3.57922C8.55351 2.79762 7.92158 2.16016 7.13445 2.16016H4.30187C3.52028 2.16016 2.88281 2.79208 2.88281 3.57922V6.41179C2.88281 7.19338 3.51474 7.83085 4.30187 7.83085H4.91162V11.0681C4.91162 12.1878 5.82071 13.0913 6.93489 13.0913H10.1721V13.7011C10.1721 14.4827 10.804 15.1202 11.5912 15.1202H14.4238C15.2053 15.1202 15.8428 14.4882 15.8428 13.7011V10.8685C15.8373 10.0869 15.1998 9.44946 14.4182 9.44946ZM4.17992 6.74438C4.05797 6.74438 3.96374 6.6446 3.96374 6.5282V3.46281C3.96374 3.34086 4.06351 3.24662 4.17992 3.24662H7.23977C7.36172 3.24662 7.45595 3.3464 7.45595 3.46281V6.5282C7.45595 6.65015 7.35617 6.74438 7.23977 6.74438H4.17992ZM14.7563 13.8175C14.7563 13.9395 14.6566 14.0337 14.5402 14.0337H11.4748C11.3528 14.0337 11.2586 13.9339 11.2586 13.8175V10.7521C11.2586 10.6302 11.3584 10.5359 11.4748 10.5359H14.5402C14.6621 10.5359 14.7563 10.6357 14.7563 10.7521V13.8175Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export default WorkFlowSVG;
