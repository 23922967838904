import React from "react";
import { TextButton } from "../../../../components/TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { TextBox, SIZE, KIND } from "../../../../components/TextBox";
import { SelectBox } from "../../../../components/SelectBox";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { FormattedMessage } from "react-intl";
import { LabelLarge, ParagraphLarge } from "baseui/typography";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import getSelectValues from "../../../../utils/getSelectValues";
import moment from "moment";

const NexActionTrigger = ({ setState, setNextState }) => {
  // Form

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      choose_action: "",
      action_date: "",
      check: false,
    },
  });

  const onSubmit = (data) => {
    let values = {
      ...data,
      choose_action: getSelectValues(data.choose_action),
      action_date: moment(data.action_date).utc().valueOf(),
    };
    setNextState(true);
  };

  const intl = useIntl();

  return (
    <>
      <form
        style={{
          boxShadow: "0px 2px 4px 2px #d6d6d6",
          background: "#FFFFFF",
          border: "0.5px solid #CDCED925",

          borderRadius: "5px",
          width: "205px",
          height: "277px",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <LabelLarge
          style={{
            paddingTop: "16px",
            textAlign: "center",
            color: "#516BEB",
          }}
        >
          Capture Next Action
        </LabelLarge>
        <div style={{ paddingTop: "10px" }}>
          <Controller
            defaultValues={[]}
            name="choose_action"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.default}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "choose_action",
                })}
                placeholder={intl.formatMessage({
                  id: "choose_action",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={[
                  { label: "Org 1 (Dummy)", id: "org_1" },
                  { label: "Org 2 (Dummy)", id: "org_2" },
                ]}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="action_date"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                size={SIZE.default}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "action_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                // selected={field.value}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="check"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                checkmarkType={STYLE_TYPE.default}
              >
                <ParagraphLarge
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormattedMessage id="override_workflow">
                    check
                  </FormattedMessage>
                </ParagraphLarge>
              </CheckBoxBaseweb>
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            // gap: "5px",
            justifyContent: "space-between",
            // alignItems: "center",
            paddingTop: "20px",
            // justifyContent: "center",
          }}
        >
          <div style={{ width: "80px" }}>
            <TextButton
              size={SIZE.compact}
              onClick={() => {
                setState(false);
              }}
              fullWidth
            >
              Back
            </TextButton>
          </div>
          <div style={{ width: "80px" }}>
            <TextButton size={SIZE.compact} type="submit" fullWidth>
              Submit
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default NexActionTrigger;
