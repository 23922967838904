import React, { useEffect, useMemo, useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { EMAIL, LETTER, SMS } from "../../../../constants";
import { TextArea } from "../../../../components/TextArea";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import { getCustomerSummaryGridStructure } from "../../../../redux/customerSummary/action";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SortDown from "../../../../assets/img/svg/SortDown";
import SortUp from "../../../../assets/img/svg/SortUp";
import { Label2 } from "baseui/typography";
import { useSelector } from "react-redux";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import DisputeModal from "../../../../components/DisputeModal/DisputeModal";
import { getDisputeTableData } from "../../../../services";
import { Controller } from "react-hook-form";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { ADD_LIST_VALUES } from "../../../../providers/RBACProvider/permissionList";

const Dispute = (call) => {
  // table code start here

  const { referenceData, space } = useContext(MaxyfiContext);

  /**
   * tabel code is removed from here on 02-08-2023
   */

  // table code end here

  let intl = useIntl();
  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  let { Controller } = call;
  return (
    <>
      <div style={{ paddingTop: "" }}>
        <Controller
          name="dispute_details"
          control={call.control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              requiredAstric={true}
              clearable={false}
              name={field.name}
              label={intl.formatMessage({
                id: "capture_dispute_details",
              })}
              // placeholder={intl.formatMessage({
              //   id: "capture_dispute_details",
              // })}
              size={SIZE.mini}
              error={call.errors[field.name] && call.errors[field.name].message}
              value={field.value}
            />
          )}
        />
      </div>
      <div className="cp-action-message-confirmation modifi--select-status">
        <div className="cp-dispute__select-status">
          <Controller
            name="dispute_type"
            control={call.control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                requiredAstric={true}
                {...field}
                clearable={false}
                name={field.name}
                creatable={creatablePermission ? true : false}
                error={
                  call.errors[field.name] && call.errors[field.name].message
                }
                label={intl.formatMessage({
                  id: "dispute_type",
                })}
                placeholder={intl.formatMessage({
                  id: "dispute_type",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                size={SIZE.mini}
                options={referenceData["dispute_type"]}
              />
            )}
          />
        </div>
        <div className="cp-dispute__select-status">
          <Controller
            defaultValues={[]}
            name="dispute_review_date"
            control={call.control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                requiredAstric={true}
                {...field}
                name={field.name}
                error={
                  call.errors[field.name] && call.errors[field.name].message
                }
                label={intl.formatMessage({
                  id: "dispute_review_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
      </div>
      <div>
        <Controller
          defaultValues={false}
          name="is_hold_workflow"
          control={call.control}
          render={({ field }) => (
            <CheckBoxBaseweb
              {...field}
              checked={field.value}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <FormattedMessage id="hold_workflow_till_dispute_date">
                hold_workflow_till_dispute_date
              </FormattedMessage>
            </CheckBoxBaseweb>
          )}
        />
      </div>
      <div className="cp-action-promise_test-area">
        <Controller
          name="comment"
          control={call.control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              clearable={false}
              name={field.name}
              error={call.errors[field.name] && call.errors[field.name].message}
              label={intl.formatMessage({
                id: "user_comments",
              })}
              placeholder={intl.formatMessage({
                id: "user_comments",
              })}
              size={SIZE.mini}
              value={field.value}
            />
          )}
        />
      </div>
    </>
  );
};

export default Dispute;
