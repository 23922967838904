import { ParagraphLarge, ParagraphMedium } from "baseui/typography";
import EditContact from "../../../../../../assets/img/svg/EditContact";
import EditSvg from "../../../../../../assets/img/svg/EditSvg";
import Edit from "../../../../../../assets/img/svg/Template/Edit";
import User from "../../../../../../assets/img/svg/Upload/User";
import {
  IconButton,
  SIZE,
  KIND,
} from "../../../../../../components/IconButton";
import { useDispatch } from "react-redux";
import {
  letterActionEditForm,
  letterAuditDrawer,
  letterStatusDrawer,
} from "../../../../../../redux/customerOverview/letterStatus/action";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import { useContext } from "react";
import moment from "moment";
// import { letterActionEditForm } from "../../../../../../redux/customerOverview/letter/action";

const LetterCardStatus = ({ type }) => {
  switch (type) {
    case "TRANSIT":
      return (
        <div
          style={{
            color: "#FBBD04",
            background: "#FFF8E4",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Transit
        </div>
      );
    case "RETURNED":
      return (
        <div
          style={{
            color: "#FD372A",
            background: "#FFE7E5",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Returned
        </div>
      );

    case "DELIVERED":
      return (
        <div
          style={{
            color: "#0FB158",
            background: "#F1FFF7",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Delivered
        </div>
      );

    case "FAILED":
      return (
        <div
          style={{
            color: "#FD372A",
            background: "#FFE7E5",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Failed
        </div>
      );

    default:
      return null;
  }
};

const LetterStatusCard = ({ data }) => {
  let dispatch = useDispatch();
  const { currentDefaultFormatDetails, users } = useContext(MaxyfiContext);

  let findCreatedBy =
    data && data.updated_by
      ? users.find(({ id }) => id == data.updated_by)
      : users.find(({ id }) => id == data.created_by);

  //
  return (
    <div style={{ margin: "0px 20px" }}>
      {/* CARD STARTS */}
      <div
        style={{
          width: "100%",
          //   height: "100px",
          height: "93px",
          border: "1px solid #cdced9",
          padding: "10px 15px",
          borderRadius: "5px",
          boxShadow: "#33386024 1px 2px 4px 0px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          {/* LETTER LEFT DATA STARTS*/}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {/* NAME SECTION */}
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <ParagraphLarge style={{ fontWeight: "500" }}>
                {data.letter_name}
              </ParagraphLarge>
              <LetterCardStatus type={data.delivery_status} />
              <div>
                <IconButton
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  onClick={() => {
                    dispatch(letterStatusDrawer(true));
                    dispatch(letterActionEditForm(data));
                    // dispatch(letterActionEditForm(data));
                  }}
                >
                  {/* <EditSvg />
                   */}
                  <i className="mx-icon-Vector-7" />
                </IconButton>
              </div>
            </div>
            {/* NAME SECTION */}

            {/* COURIER TYPE */}
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <ParagraphMedium style={{ color: "#787878" }}>
                {moment
                  .utc(data.date_of_communication)
                  .format(currentDefaultFormatDetails?.date_format)}
              </ParagraphMedium>

              {data && data.delivery_method ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "4px",
                        width: "4px",
                        // background: "#787878",
                        borderRadius: "50%",
                        marginBottom: "3px",
                        background: "#787878",
                      }}
                    ></div>
                    <ParagraphMedium style={{ color: "#787878" }}>
                      {data.delivery_method}
                    </ParagraphMedium>
                  </div>
                </>
              ) : (
                <></>
              )}

              {data && data.attachments && data.attachments.length > 0 && (
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <div
                    style={{
                      height: "4px",
                      width: "4px",
                      background: "#787878",
                      borderRadius: "50%",
                      marginBottom: "3px",
                    }}
                  ></div>
                  <ParagraphMedium
                    style={{ color: "#787878", display: "flex", gap: "5px" }}
                  >
                    Attachment{" "}
                    <div
                      style={{
                        height: "22px",
                        width: "22px",
                        background: "#EAEDFF",
                        color: "#516beb",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // border: "1px solid ",
                        borderRadius: "5px",
                      }}
                    >
                      {data.attachments.length}
                    </div>{" "}
                  </ParagraphMedium>
                </div>
              )}
            </div>
            {/* COURIER TYPE */}
          </div>
          {/* LETTER LEFT DATA ENDS*/}
          {/* LETTER RIGHT DATA STARTS*/}
          <div
            style={{
              display: "flex",
              alignItems: "end",
              //   justifyContent: "flex-end",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <div
              style={{ color: "#516beb", cursor: "pointer" }}
              onClick={() => {
                dispatch(letterAuditDrawer(true));
                dispatch(letterActionEditForm(data));
              }}
            >
              {data && data.audit && data.audit.length} Audit
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <ParagraphMedium style={{ color: "#787878" }}>
                Last Updated on
              </ParagraphMedium>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <div style={{ marginBottom: "2px" }}>
                  <User />
                </div>

                <ParagraphMedium>
                  {findCreatedBy && findCreatedBy.displayName}
                </ParagraphMedium>

                <ParagraphMedium>
                  {data && data.updated_on
                    ? moment
                        .utc(data.updated_on)
                        .format(currentDefaultFormatDetails?.date_format)
                    : moment
                        .utc(data.created_on)
                        .format(currentDefaultFormatDetails?.date_format)}
                </ParagraphMedium>
              </div>
            </div>
          </div>
          {/* LETTER RIGHT DATA ENDS*/}
        </div>
      </div>
      {/* CARD ENDS */}
    </div>
  );
};

export default LetterStatusCard;
