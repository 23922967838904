import React, { useContext } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _, { values } from "lodash";
import { useMemo } from "react";

function PerformanceIndication(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  value = _.get(row.original, accessor, "");

  const [visible, setVisible] = React.useState(false);
  let { referenceData } = useContext(MaxyfiContext);

  let { lightColor, findPersent } = useMemo(() => {
    let findPersent;
    let color = "#516beb";
    let lightColor;

    if (value && _.get(row, `original.${accessor}_max_value`)) {
      findPersent =
        100 - (value / _.get(row, `original.${accessor}_max_value`)) * 100;

      if (findPersent) {
        // Remove the '#' symbol and parse the hexadecimal color value
        color = color.slice(1);
        const num = parseInt(color, 16);

        // Calculate the red, green, and blue components
        let r = (num >> 16) + findPersent;
        let g = ((num >> 8) & 0x00ff) + findPersent;
        let b = (num & 0x0000ff) + findPersent;

        // Ensure values are within valid range (0-255)
        r = Math.min(Math.max(r, 0), 255);
        g = Math.min(Math.max(g, 0), 255);
        b = Math.min(Math.max(b, 0), 255);

        // Convert back to hexadecimal and return the new color
        lightColor =
          "#" + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
      }

      // if () {

      // }

      return { lightColor, findPersent };
    }
    return { lightColor, findPersent };
  }, [value]);

  const textColor = useMemo(() => {
    if (lightColor) {
      const r = parseInt(lightColor.substr(1, 2), 16);
      const g = parseInt(lightColor.substr(3, 2), 16);
      const b = parseInt(lightColor.substr(5, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    }
  }, []);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ minWidth: "auto", maxWidth: "200px" }}>{value}</div>
      }
    >
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          // margin: "5px",
          lineHeight: "18px",
          color:
            _.get(row, `original.${accessor}_max_value`) === false
              ? "#ffffff"
              : textColor
              ? textColor
              : "#333860",
          backgroundColor:
            _.get(row, `original.${accessor}_max_value`) === false
              ? "#561beb"
              : lightColor
              ? lightColor
              : "",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div
          className="elip"
          ref={ref}
          style={{ color: `${!suffix ? "" : "#757575"}`, lineHeight: "18px" }}
        >
          {value}
        </div>
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: alignment,
        lineHeight: "18px",
        // margin: "5px",
        color:
          _.get(row, `original.${accessor}_max_value`) === false
            ? "#ffffff"
            : textColor
            ? textColor
            : "#333860",
        backgroundColor:
          _.get(row, `original.${accessor}_max_value`) === false
            ? "#561beb"
            : lightColor
            ? lightColor
            : "",
        paddingTop: "10px",
        paddingBottom: "10px",
      }}
      style={{ color: `${!suffix ? "" : "#757575"}` }}
    >
      {value}
    </Paragraph2>
  );
}
export default PerformanceIndication;
