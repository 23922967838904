import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  ACT_AUTO_PAYMENT_PLAN,
  CO_INVOICES_,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { Typography, Icon } from "../../../../components_v2";
import useFormat from "../../../../hooks/useFormat";
import PromiseStatusTypes from "../PromiseStatusTypes";
import { getHolidayBlackoutHolidayDate } from "../../../../services";
import { useSelector } from "react-redux";

const freqMoment = {
  WEEKLY: {
    type: "day",
    count: 7,
  },
  FORTNIGHTLY: {
    type: "day",
    count: 14,
  },
  MONTHLY: {
    type: "month",
    count: 1,
  },
  QUARTERLY: {
    type: "month",
    count: 3,
  },
};

const PaymentPlanList = ({
  amount,
  currency,
  frequency,
  count,
  startDate = null,
  installments = [],
}) => {
  const [expand, setExpand] = useState(false);
  const format = useFormat();
  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  if (amount && currency && frequency && count) {
    return (
      <div
        style={{
          backgroundColor: "#F0F5FF",
          padding: "10px",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>
            {format.rd({ id: frequency, name: "payment_plan_frequency" })}{" "}
            Payment plan with installment amount of {currency}{" "}
            {format.currency({ amount })} starting from{" "}
            {format.date({ value: startDate })} till{" "}
            {installments && installments[installments.length - 1]
              ? format.date({
                  value: installments[installments.length - 1].date,
                })
              : format.date({ value: startDate })}
          </Typography>

          <Icon
            icon={expand ? "chevron_up" : "chevron_down"}
            size={18}
            isPressable
            onClick={() => {
              setExpand(!expand);
            }}
          />
        </div>
        {installments && installments.length > 0 && expand ? (
          <div style={{ marginTop: "5px" }}>
            {installments.map((e, i) => {
              return (
                <Typography subType="regular">
                  Installment {i + 1} - {currency}{" "}
                  {format.currency({ amount: e.amount })} on{" "}
                  {moment(e?.date).format(
                    currentDefaultFormatDetails.date_format
                  )}
                </Typography>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <></>;
};

const AutoPaymentPlanForm = (props) => {
  const format = useFormat();
  const {
    isInvoice,
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    // isIncrementor = true,
    isContent = true,
    selectedInvoices = {},
    totalAmount = 0,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();

  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  let { validationField } = useSelector((e) => e.customerOverviewDrawer);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      frequency: [{ id: "WEEKLY" }],
      start_date: new Date(),
    },
  });

  const [
    startDate,
    isHold,
    amount,
    currency,
    frequency,
    count,
    paymentPlan,
    instalationAmount,
  ] = watch([
    "start_date",
    "is_hold_workflow",
    "amount",
    "currency",
    "frequency",
    "instalation_count",
    "payment_gateway_id",
    "instalation_amount",
  ]);

  const installments = useMemo(() => {
    let installments = [];
    let countInc = 0;

    if (amount && currency && frequency && count && startDate) {
      const freq =
        freqMoment[frequency && frequency[0] ? frequency[0].id : null];

      installments = Array.from({ length: count }).map((e, i) => {
        const installmentDate = moment(startDate)
          .tz(currentDefaultFormatDetails?.time_zone)
          .startOf("day")
          .add(countInc, freq.type)
          .utc()
          .valueOf();

        countInc += freq.count;

        return {
          date: installmentDate,
          amount: (Number(amount) / Number(count)).toFixed(2),
        };
      });
    }

    setValue(
      "instalation_amount",
      installments && installments[0] && installments[0].amount
    );

    return installments;
  }, [amount, currency, frequency, count, startDate]);
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  useEffect(() => {
    if (Number(instalationAmount) > Number(amount)) {
      setError("instalation_amount", {
        type: "custom",
        message: "Instalation Amount is Greater than Total Amount",
      });
    } else if (
      _.get(validationField, "minPayment") &&
      Number(amount) < _.get(validationField, "minPayment")
    ) {
      setError("amount", {
        type: "custom",
        message: `*Minimum Payment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      clearErrors("instalation_amount");
      clearErrors("amount");
    }

    if (Number(count) > _.get(validationField, "maxInstalmentCount")) {
      setError("instalation_count", {
        type: "custom",
        message: `* Maximum Allowed installments is 
         ${_.get(validationField, "maxInstalmentCount", 0)}`,
      });
    } else {
      clearErrors("instalation_count");
    }
    if (Number(instalationAmount) < _.get(validationField, "minPayment")) {
      setError("instalation_amount", {
        type: "custom",
        message: `*Minimum Installment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      clearErrors("instalation_amount");
    }
  }, [amount, instalationAmount, count]);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: { date: installments.map((e) => e.date) },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: installments && installments.length > 0 ? true : false,
    }
  );

  const { currencies, outstanding_amount } = useMemo(() => {
    const currencies = _.get(
      customerData,
      "data.doc.invoice_currencies",
      []
    ).map((e) => ({ id: e, label: e }));
    const outstanding_amount = _.get(
      customerData,
      "data.doc.total_outstanding_invoice_amount.value",
      0
    );

    setValue("currency", currencies[0] ? [currencies[0]] : []);
    setValue("amount", outstanding_amount);

    return { currencies, outstanding_amount };
  }, [customerData?.data]);

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: (data) => {
        if (data && data.data && data.data.redirect_url) {
          window.open(data.data.redirect_url);
        }

        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    const {
      frequency,
      start_date,
      amount,
      currency,
      comment,
      instalation_count,
      payment_gateway_id,
    } = data;

    if (isInvoice.length < 1) {
      return;
    }
    if (+amount > +outstanding_amount) {
      return setError("amount", {
        type: "custom",
        message: "Amount greater than due amount",
      });
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...selectedInvoices,
      ...formValues,
      ...(isContent ? { content: "-" } : {}),

      method: "PORTAL",
      comment,
      instalation_count,
      frequency: getSelectValues(frequency),
      payment_gateway_id: getSelectValues(payment_gateway_id),
      start_date: moment(start_date)
        .tz(currentDefaultFormatDetails?.time_zone)
        .startOf("day")
        .utc()
        .valueOf(),

      amount: {
        value: amount,
        currency: getSelectValues(currency),
      },
      ...getActionReminder(data, {
        is_consent_enabled: props.isConsent,
        is_incrementor: props.isIncrementor,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  useEffect(() => {
    if (
      referenceData["auto_debit_payment_method"] &&
      Array.isArray(referenceData["auto_debit_payment_method"]) &&
      referenceData["auto_debit_payment_method"][0]
    ) {
      setValue("payment_gateway_id", [
        referenceData["auto_debit_payment_method"][0],
      ]);
    }
  }, []);

  useEffect(() => {
    if (
      (paymentPlan &&
        paymentPlan[0] &&
        paymentPlan[0].gateway === "ACCEPTBLUE") ||
      paymentPlan?.[0]?.gateway === "REPAY_CHANNEL"
    ) {
      let day = new Date();
      let nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);

      setValue(
        "start_date",
        moment()
          .tz(currentDefaultFormatDetails?.time_zone)
          .add(1, "day")
          .toDate()
      );
    } else {
      setValue("start_date", new Date());
    }
  }, [paymentPlan]);

  useEffect(() => {
    if (totalAmount > 0) {
      setValue("amount", totalAmount);
    } else {
      setValue("amount", outstanding_amount);
    }
  }, [totalAmount]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "5px", marginTop: "20px" }}>
        <div>
          <Controller
            name="currency"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                clearable={false}
                size={SIZE.mini}
                label={intl.formatMessage({
                  id: "cur",
                })}
                placeholder={intl.formatMessage({
                  id: "cur",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={currencies}
                requiredAstric={true}
              />
            )}
          />
        </div>

        <Controller
          name="amount"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              hidden={true}
              type="number"
              name={field.name}
              size={SIZE.mini}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "amount",
              })}
              placeholder={intl.formatMessage({
                id: "amount",
              })}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
                setValue("instalation_count", "");
                setValue("instalation_amount", "");
              }}
            />
          )}
        />

        <Controller
          name="instalation_count"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              hidden={true}
              type="number"
              name={field.name}
              size={SIZE.mini}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "no_of_installment",
              })}
              placeholder={intl.formatMessage({
                id: "no_of_installment",
              })}
              value={field.value}
            />
          )}
        />

        <Controller
          name="instalation_amount"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              hidden={true}
              type="number"
              name={field.name}
              size={SIZE.mini}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "instalation_amount",
              })}
              placeholder={intl.formatMessage({
                id: "instalation_amount",
              })}
              value={field.value}
              onBlur={() => {
                if (amount > 0 && instalationAmount > 0) {
                  let countVl = Math.floor(amount / instalationAmount);
                  setValue("instalation_count", countVl);
                }
              }}
            />
          )}
        />

        <Controller
          name="frequency"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              clearable={false}
              size={SIZE.mini}
              label={intl.formatMessage({
                id: "frequency",
              })}
              placeholder={intl.formatMessage({
                id: "frequency",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={referenceData["payment_plan_frequency"] || []}
            />
          )}
        />
      </div>

      <div style={{ display: "flex", gap: "5px" }}>
        <Controller
          name="start_date"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <DateRangeSelect
              {...field}
              name={field.name}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              requiredAstric={true}
              label={intl.formatMessage({
                id: "start_date",
              })}
              placeholder={intl.formatMessage({
                id: `${currentDefaultFormatDetails.date_format}`,
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={
                (paymentPlan &&
                  paymentPlan[0] &&
                  paymentPlan[0].gateway === "ACCEPTBLUE") ||
                paymentPlan?.[0]?.gateway === "REPAY_CHANNEL"
                  ? moment()
                      .tz(currentDefaultFormatDetails?.time_zone)
                      .add(1, "day")
                      .toDate()
                  : new Date()
              }
            />
          )}
        />

        <Controller
          name="payment_gateway_id"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              clearable={false}
              size={SIZE.mini}
              label={intl.formatMessage({
                id: "payment_method",
              })}
              placeholder={intl.formatMessage({
                id: "payment_method",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                referenceData["auto_debit_payment_method"] &&
                Array.isArray(referenceData["auto_debit_payment_method"])
                  ? referenceData["auto_debit_payment_method"]
                  : []
              }
            />
          )}
        />
      </div>

      {Array.isArray(installments) && installments.length > 0 && (
        <div className="ins_suggestion">
          <Typography>
            Installment amount changed to{" "}
            {format.currency({
              amount: installments && installments[0] && installments[0].amount,
            })}
          </Typography>
        </div>
      )}

      <PaymentPlanList
        amount={amount}
        currency={currency && currency[0] ? currency[0].id : null}
        frequency={frequency && frequency[0] ? frequency[0].id : null}
        count={count}
        startDate={startDate}
        installments={installments}
      />

      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section" style={{ marginTop: "10px" }}>
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              One or more schedule falls on a Holiday -{" "}
              {_.get(data, "data.doc.holidays", []).map((val, i) => {
                return (
                  <b>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                    {_.get(data, "data.doc.holidays", []).length - 1 === i
                      ? "."
                      : ","}{" "}
                  </b>
                );
              })}
            </Typography>
          </div>
        </>
      )}

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={formValues.is_inbound_call ? true : false}
        outcome={ACT_AUTO_PAYMENT_PLAN}
        consentDate={startDate}
        callValidationDuration={_.get(
          customerData,
          "data.doc.mini_miranda.call_validation_duration"
        )}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_incrementor={props.isIncrementor}
        is_consent_enabled={props.isConsent}
        isHold={isHold}
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

const AutoPaymentPlan = (props) => {
  const { customerId } = useParams();

  const [text, setAlertPromise] = useState(false);
  const [isInvoice, setIsInvoice] = useState([]);
  const [currentInvoice, setCurrentInvoice] = useState([]);
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const [isPromiseExist, setIsPromiseExist] = useState(false);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const format = useFormat();
  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);

  const consumerGroupPromiseText = _.get(invoicesData, "data.docs", []).some(
    (obj) => obj.hasOwnProperty("pgid")
  );

  const invOption = useMemo(() => {
    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
        label: `${format.rd({
          id: _.get(e, "business_unit", ""),
          name: "business_unit_list",
        })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
          e,
          "invoice_number",
          ""
        )}${_.get(e, "invoice_number", "") ? " / " : ""}
      ${_.get(e, "client_reference", "")}${
          _.get(e, "client_reference", "") ? " / " : ""
        }
      ${moment
        .utc(e.invoice_date)
        .tz(currentDefaultFormatDetails.time_zone)
        .format(currentDefaultFormatDetails.date_format)}${
          _.get(not_paid_amount, "currency", "") ? " / " : ""
        }${_.get(not_paid_amount, "currency", "")}${
          _.get(not_paid_amount, "value", "") ? " " : ""
        }${_.get(not_paid_amount, "value", "")}`,
      }));
    setIsInvoice([...invIds]);
    setCurrentInvoice([...invIds]);
    return invIds;
  }, [invoicesData]);

  // const invoices = useMemo(() => {
  //   let findpgid = _.get(invoicesData, "data.docs", []).filter(
  //     ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
  //   );

  //   let invIds = findpgid.map(({ _id, not_paid_amount }) => ({
  //     id: _id,
  //     outstanding: not_paid_amount,
  //   }));
  //   setIsInvoice([...invIds]);
  //   return _.get(invoicesData, "data.docs", []).map((e) => ({
  //     id: e._id,
  //     outstanding: e.not_paid_amount,
  //     label: `${format.rd({
  //       id: e?.business_unit,
  //       name: "business_unit_list",
  //     })} / ${e.client_reference ? e.client_reference : ""} / ${
  //       e.invoice_number
  //     } / ${moment
  //       .utc(e.invoice_due_date)
  //       .tz(currentDefaultFormatDetails.time_zone)
  //       .format(currentDefaultFormatDetails.date_format)} / ${
  //       e.not_paid_amount && e.not_paid_amount.currency
  //     } ${e.not_paid_amount && e.not_paid_amount.value}`,
  //   }));
  // }, [invoicesData]);

  const promiseStatus = useMemo(() => {
    let matchingIds = [];
    for (
      let i = 0;
      i < _.get(customerData, "data.doc.promise_to_pay", []).length;
      i++
    ) {
      let invArray =
        customerData && customerData?.data?.doc?.promise_to_pay?.[i];
      for (let j = 0; j < invArray?.inv?.length; j++) {
        if (isInvoice.some((item) => item.id === invArray?.inv?.[j])) {
          if (invArray && invArray.status === "IN_PROGRESS") {
            matchingIds.push(invArray);
          }
        }
      }
    }
    return matchingIds;
  }, [isInvoice]);

  useEffect(() => {
    if (promiseStatus.length > 0) {
      setIsPromiseExist(true);
    }
  }, [isInvoice]);

  return (
    <div style={{ marginTop: "15px" }}>
      <div style={{ width: "350px" }}>
        <SelectBox
          requiredAstric={true}
          value={isInvoice}
          getValueLabel={({ option }) => {
            const { label } = option;
            const value = label ? label.split("/") : [];
            return <>{value && value[1] ? value[1] : value[0]}</>;
          }}
          onChange={(e) => {
            setIsInvoice(e.value);
            setAlertPromise(e.value.length < currentInvoice.length);
          }}
          multi
          options={invOption}
          size={"mini"}
        />
        {isInvoice.length < 1 && (
          <span style={{ color: "red" }}>Invioces is required</span>
        )}
      </div>
      {!isPromiseExist &&
        text &&
        _.get(customerData, "data.doc.gid") &&
        consumerGroupPromiseText && (
          <Typography
            className="co_action_exist"
            style={{ textAlign: "start" }}
          >
            * New Group will be created for the selected records.
          </Typography>
        )}
      {isPromiseExist ? (
        <PromiseStatusTypes
          groupedPromise={_.get(customerData, "data.doc.gid")}
          setAlertPromise={text}
          setIsPromiseExist={setIsPromiseExist}
          type={_.get(promiseStatus, "[0].status", "")}
          promiseStatus={_.get(promiseStatus, "[0]", {})}
        />
      ) : (
        <AutoPaymentPlanForm
          isInvoice={isInvoice}
          selectedInvoices={{ invoices: isInvoice?.map(({ id }) => id) }}
          totalAmount={isInvoice
            .reduce((sum, item) => +sum + +item?.outstanding?.value, 0)
            .toFixed(2)}
          {...props}
        />
      )}
    </div>
  );
};

export default AutoPaymentPlan;
