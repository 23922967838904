import React, { useContext } from "react";
import { StatefulPopover, PLACEMENT } from "baseui/popover";

import { FormattedMessage, useIntl } from "react-intl";
import _ from "lodash";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_CHARGES,
  ADD_INVOICE,
  ADD_RECURRING_INVOICE,
} from "../../providers/RBACProvider/permissionList";
import { IconButton, KIND } from "../IconButton";
import { ParagraphSmall } from "baseui/typography";
import DownArrow from "../../utils/DownArrow";
import { Icon, Typography } from "../../components_v2";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../constants";
import { encrypt } from "n-krypta";
import { useDispatch } from "react-redux";
import {
  ITEMIZATION_ENHANCEMENT_DRAWER,
  setDrawerState,
} from "../../redux/actions";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

const TypesOfInvoice = ({ getParams, keyValue, hello }) => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { currentDefaultFormatDetails, referenceData } =
    useContext(MaxyfiContext);

  let clientSector =
    referenceData &&
    referenceData["industry_type"].find(
      (e) => e.id === currentDefaultFormatDetails?.industry_sector
    );

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let navigate = useNavigate();

  let listOfInvoiceType = [
    {
      label: "add_charge",
      role: ADD_CHARGES,
      invType: "ADD-CHARGE",
      invForm: "charge-form",
      customerId: customerId,
      cusName: _.get(customerData, "data.doc.display_name", ""),
    },
    {
      label: "invoice",
      role: ADD_INVOICE,
      invType: "ADD-INV",
      invForm: "invoice-form",
      customerId: customerId,
      cusName: _.get(customerData, "data.doc.display_name", ""),
    },
    {
      label: "recurring",
      role: ADD_RECURRING_INVOICE,
      invType: "ADD-REC",
      invForm: "recurring-form",
      customerId: customerId,
      cusName: _.get(customerData, "data.doc.display_name", ""),
    },
  ];

  if (clientSector && clientSector?.id === "INSE297") {
    listOfInvoiceType.push({
      label: "itemization",
      role: ADD_RECURRING_INVOICE,
      invType: "ADD-REC",
      invForm: "recurring-form",
      customerId: customerId,
      cusName: _.get(customerData, "data.doc.display_name", ""),
    });
  }

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  // Workflow Data Ends

  return (
    <>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              borderRadius: "5px",
              overflow: "hidden",
              width: "131px",
              background: "#ffffff",
              zIndex: "1",
            }),
          },
          Inner: {
            style: ({ $theme }) => ({
              backgroundColor: "#ffffff",
              padding: "10px",
            }),
          },
        }}
        content={({ close }) => (
          <div>
            {listOfInvoiceType.map((e) => {
              const sercretKey = "max-key-sk";

              return (
                <RBACWrapper role={e.role} type="PERMISSION">
                  <div
                    style={{
                      padding: "5px 0px",
                      borderBottom: "0.5px solid #ceded9",
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (e.label === "itemization") {
                        close();
                        setDrawer(ITEMIZATION_ENHANCEMENT_DRAWER, customerData);
                      } else {
                        navigate(
                          encodeURI(
                            `/customers?customer=${e.customerId}&invoice=${e.invForm}&type=${e.invType}&dl=${e.cusName}
                            `
                          )
                        );
                      }
                    }}
                  >
                    <FormattedMessage id={e.label}>{e.label}</FormattedMessage>
                  </div>
                </RBACWrapper>
              );
            })}
          </div>
        )}
      >
        <div className="co_inv_types_btn">
          <Typography type="p">Add Invoice</Typography>
          <Icon icon="chevron_down" color="#516beb" />
        </div>
      </StatefulPopover>
    </>
  );
};

export default TypesOfInvoice;
