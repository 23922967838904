export const GET_INVOICE_GRID_STRUCTURE = "GET_INVOICE_GRID_STRUCTURE";
export const UPDATE_INVOICE_TABLE_DRAFT_STATE =
  "UPDATE_INVOICE_TABLE_DRAFT_STATE";
export const SET_INVOICE_VIEW = "SET_INVOICE_VIEW";
export const NESTED_CS_ACTION_TYPE = "NESTED_CS_ACTION_TYPE";
export const INITIAL_INVOICE_SUMMARY = "INITIAL_INVOICE_SUMMARY";
export const TABLE_WIDTH = "TABLE_WIDTH";

export const SET_INV_GRID_AND_VIEW = "SET_INV_GRID_AND_VIEW";

export const SET_INV_SORT = "SET_INV_SORT";
export const SET_INV_PAGE_INDEX = "SET_INV_PAGE_INDEX";
export const SET_INV_PAGE_SIZE = "SET_INV_PAGE_SIZE";
export const INVOICE_FILTER = "INVOICE_FILTER";

export const SET_INV_FILTER = "SET_INV_FILTER";
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";
export const RESET_INVOICE_NAVLINK = "RESET_CUSTOMER_NAVLINK";
export const RESET_FORM_DATA = "RESET_FORM_DATA";
export const SET_GLOBAL_INVOICE_FILTER = "SET_GLOBAL_INVOICE_FILTER";

export const INV_DASHBOARD_NEED_ACTION_FILTER =
  "INV_DASHBOARD_NEED_ACTION_FILTER";
export const INV_NEEDS_ACTION_FILTERS = "INV_NEEDS_ACTION_FILTERS";
export const SET_INV_PINNED_VIEW = "SET_INV_PINNED_VIEW";
export const INVOICE_DASHBOARD_FILTER = "INVOICE_DASHBOARD_FILTER";

export const setInvoicePinnedView = (payload = []) => {
  return {
    type: SET_INV_PINNED_VIEW,
    payload,
  };
};

export const setTableWidthInv = (width) => {
  return {
    type: TABLE_WIDTH,
    payload: width,
  };
};

export const setInvoiceGlobalFilter = (cusName) => {
  return {
    type: SET_GLOBAL_INVOICE_FILTER,
    payload: cusName,
  };
};

export const setInitialFormData = () => {
  return {
    type: RESET_FORM_DATA,
  };
};

export const setInvoiceSummaryFilter = (filterData) => {
  return {
    type: SET_INV_FILTER,
    payload: filterData,
  };
};

export const setInvoiceFilter = (data) => {
  return {
    type: INVOICE_FILTER,
    payload: data,
  };
};

export const setInvoiceDashboardFilter = (data) => {
  return {
    type: INVOICE_DASHBOARD_FILTER,
    payload: data,
  };
};

export const setInitialInvoiceSummary = () => {
  return {
    type: INITIAL_INVOICE_SUMMARY,
  };
};

export const setInvoiceSummaryGridStructure = (gridStructureIS) => {
  return {
    type: GET_INVOICE_GRID_STRUCTURE,
    payload: gridStructureIS,
  };
};

export const updateTableInvoiceSummaryDraftState = (tableState) => {
  return {
    type: UPDATE_INVOICE_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const setInvoiceSummaryTableView = (viewData) => {
  return {
    type: SET_INVOICE_VIEW,
    payload: viewData,
  };
};

export const setInvoiceSummaryGridAndView = (viewData) => {
  return {
    type: SET_INV_GRID_AND_VIEW,
    payload: viewData,
  };
};

export const setInvoiceSummarySort = (sort) => {
  return {
    type: SET_INV_SORT,
    payload: sort,
  };
};

export const setInvoiceSummaryPageIndex = (pageIndex) => {
  return {
    type: SET_INV_PAGE_INDEX,
    payload: pageIndex,
  };
};

export const setInvoiceSummaryPageSize = (pageSize) => {
  return {
    type: SET_INV_PAGE_SIZE,
    payload: pageSize,
  };
};

export const setDashBoardNeedActionFilterInvoice = (dashFilter) => {
  return {
    type: INV_DASHBOARD_NEED_ACTION_FILTER,
    payload: dashFilter,
  };
};

export const setNeedsActionFilterInv = (selectedNeedsFilter) => {
  return {
    type: INV_NEEDS_ACTION_FILTERS,
    payload: selectedNeedsFilter,
  };
};
