import React from "react";

function FaildArrowAdhoc() {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.42552 0.158437C8.21427 -0.0528125 7.87302 -0.0528125 7.66177 0.158437L1.76302 6.05177V3.02385C1.76302 2.72594 1.51927 2.48219 1.22135 2.48219C0.923438 2.48219 0.679688 2.72594 0.679688 3.02385V7.35719C0.679688 7.6551 0.923438 7.89885 1.22135 7.89885H5.55469C5.8526 7.89885 6.09635 7.6551 6.09635 7.35719C6.09635 7.05927 5.8526 6.81552 5.55469 6.81552H2.52677L8.42552 0.916771C8.63135 0.710938 8.63135 0.364271 8.42552 0.158437Z"
        fill="#FD372A"
      />
    </svg>
  );
}

export default FaildArrowAdhoc;
