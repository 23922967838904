import React from "react";

const LegalAction = ({ size = "18", color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7461 19.4375C15.7461 18.5638 15.0378 17.8555 14.1641 17.8555H7.83594C6.9622 17.8555 6.25391 18.5638 6.25391 19.4375V20H15.7461V19.4375Z"
        fill={color}
      />
      <path
        d="M18.9453 6.25391V5.19922H12.4849C12.3256 4.75245 11.9741 4.401 11.5273 4.24167V2H10.4727V4.24167C10.0259 4.401 9.67443 4.75245 9.51511 5.19922H3.05469V6.25391H4.37319L2 12.0547C2 13.5085 3.1829 14.6914 4.63672 14.6914H5.69141C7.14522 14.6914 8.32812 13.5085 8.32812 12.0547L5.94421 6.25391H9.51514C9.67447 6.70067 10.0259 7.05213 10.4727 7.21146V14.7492C9.44209 14.9601 8.62681 15.7698 8.41668 16.8008H13.5834C13.3733 15.7698 12.558 14.9601 11.5274 14.7492V7.21146C11.9741 7.05213 12.3256 6.70067 12.4849 6.25391H16.0451L13.6719 12.0547C13.6719 13.5085 14.8548 14.6914 16.3086 14.6914H17.3633C18.8171 14.6914 20 13.5085 20 12.0547L17.6161 6.25391H18.9453ZM7.00963 11.5273H3.3185L5.16406 7.09745L7.00963 11.5273ZM18.6815 11.5273H14.9904L16.8359 7.09745L18.6815 11.5273Z"
        fill={color}
      />
    </svg>
  );
};

export default LegalAction;
