import React from "react";

const CollapseUpArrow = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.29289 11.2929C4.90237 11.6834 4.90237 12.3166 5.29289 12.7071C5.68342 13.0976 6.31658 13.0976 6.70711 12.7071L5.29289 11.2929ZM10 8L10.7071 7.29289C10.3166 6.90237 9.68342 6.90237 9.29289 7.29289L10 8ZM13.2929 12.7071C13.6834 13.0976 14.3166 13.0976 14.7071 12.7071C15.0976 12.3166 15.0976 11.6834 14.7071 11.2929L13.2929 12.7071ZM6.70711 12.7071L10.7071 8.70711L9.29289 7.29289L5.29289 11.2929L6.70711 12.7071ZM9.29289 8.70711L13.2929 12.7071L14.7071 11.2929L10.7071 7.29289L9.29289 8.70711Z"
        fill="#333860"
      />
    </svg>
  );
};

export default CollapseUpArrow;
