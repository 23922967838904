export default function Call({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M14.2752 11.5241L12.5078 11.3224C12.0833 11.2736 11.6658 11.4198 11.3666 11.719L10.0863 12.9993C8.11702 11.9973 6.50266 10.3899 5.50064 8.4137L6.78795 7.12638C7.08717 6.82717 7.23329 6.40966 7.18458 5.98519L6.98279 4.23165C6.89929 3.52885 6.30782 3 5.59806 3H4.39425C3.60794 3 2.95385 3.6541 3.00256 4.44041C3.37135 10.383 8.12398 15.1286 14.0595 15.4974C14.8458 15.5462 15.4999 14.8921 15.4999 14.1057V12.9019C15.5069 12.1991 14.978 11.6076 14.2752 11.5241Z"
        fill={color}
      />
    </svg>
  );
}
