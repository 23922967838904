export const ADD_BUSINESS_CONTACT = "ADD_BUSINESS_CONTACT";
export const BUSINESS_CONTACT_TYPE = "BUSINESS_CONTACT_TYPE";
export const ADD_ADMIN_CONTACT = "ADD_ADMIN_CONTACT";
export const BUSINESS_ADMIN_CONTACT_TYPE = "BUSINESS_ADMIN_CONTACT_TYPE";
export const BUSINESS_UNIT_REFETCH = "BUSINESS_UNIT_REFETCH";
export const SET_CL_SORT = "SET_CL_SORT";
export const SET_CL_GRID_AND_VIEW = "SET_CL_GRID_AND_VIEW";
export const SET_CL_VIEW = "SET_CL_VIEW";
export const GET_CL_GRID_STRUCTURE = "GET_CL_GRID_STRUCTURE";
export const SET_CL_PAGE_INDEX = " SET_CL_PAGE_INDEX";
export const SET_CL_PAGE_SIZE = "SET_CL_PAGE_SIZE";
export const TABLE_COLUMN_ORDER_CLIENT = "TABLE_COLUMN_ORDER_CLIENT";
export const SET_CL_PINNED_VIEW = "SET_CL_PINNED_VIEW";
export const SET_CL_FILTER = "SET_CL_FILTER";
export const CLIENT_STAGE_FILTERS = "CLIENT_STAGE_FILTERS";
export const UPDATE_CL_TABLE_DRAFT_STATE = "UPDATE_CL_TABLE_DRAFT_STATE";
export const COLUMN_CLIENT_WIDTH = "COLUMN_CLIENT_WIDTH";
export const businessContactType = (data) => {
  return {
    type: BUSINESS_CONTACT_TYPE,
    payload: data,
  };
};
export const setClientPinnedView = (payload = []) => {
  return {
    type: SET_CL_PINNED_VIEW,
    payload,
  };
};
export const setClientSummaryFilter = (filterData) => {
  return {
    type: SET_CL_FILTER,
    payload: filterData,
  };
};
export const updateTableClientSummaryDraftState = (tableState) => {
  return {
    type: UPDATE_CL_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const businessAdminContactType = (data) => {
  return {
    type: BUSINESS_ADMIN_CONTACT_TYPE,
    payload: data,
  };
};
export const getClientGridStructure = (gridStructure) => {
  return {
    type: GET_CL_GRID_STRUCTURE,
    payload: gridStructure,
  };
};
export const setTableHeaderOrderClient = (tabHead) => {
  return {
    type: TABLE_COLUMN_ORDER_CLIENT,
    payload: tabHead,
  };
};
export const setClientGridAndView = (viewData) => {
  return {
    type: SET_CL_GRID_AND_VIEW,
    payload: viewData,
  };
};
export const setTableClientWidth = (width) => {
  return {
    type: COLUMN_CLIENT_WIDTH,
    payload: width,
  };
};
export const setClientTableView = (viewData) => {
  return {
    type: SET_CL_VIEW,
    payload: viewData,
  };
};

export const setClientSort = (sort) => {
  return {
    type: SET_CL_SORT,
    payload: sort,
  };
};

export const setClientStageFilter = (cusStage) => {
  return {
    type: CLIENT_STAGE_FILTERS,
    payload: cusStage,
  };
};

export const addBusinessContact = (data) => {
  return {
    type: ADD_BUSINESS_CONTACT,
    payload: data,
  };
};

export const addBusinessAdminContact = (data) => {
  return {
    type: ADD_ADMIN_CONTACT,
    payload: data,
  };
};

export const refetchBusinessUnitList = () => {
  return {
    type: BUSINESS_UNIT_REFETCH,
  };
};

export const setClientPageIndex = (pageIndex) => {
  return {
    type: SET_CL_PAGE_INDEX,
    payload: pageIndex,
  };
};

export const setClientPageSize = (pageSize) => {
  return {
    type: SET_CL_PAGE_SIZE,
    payload: pageSize,
  };
};
