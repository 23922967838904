import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";
import { useQuery } from "react-query";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const createDocument = async ({
  customerId,
  organization = null,
  data,
  onUploadProgress,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/document`,
    data,
    {
      onUploadProgress,
    }
  );
};

export const createSpaceLevelDocument = async ({
  spaceId,
  data,
  onUploadProgress,
}) => {
  return await axiosWithAuth.post(`${HOST}space/${spaceId}/upload-file`, data, {
    onUploadProgress,
  });
};

export const getSubCategory = async ({ organization = null }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/document-sub-category`
  );
};

export const getDocumentData = async ({
  customerId,
  organization = null,
  sort,
  filter,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/document`,
    {
      params: {
        sort: sort,
        ...filter,
      },
    }
  );
};

export const scanningAttachment = async ({
  customerId,
  organization = null,
  documentId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/document/${documentId}/scan-status`
  );
};

export const scanSpaceLevelAttachments = async ({ spaceId, documentId }) => {
  return await axiosWithAuth.get(
    `${HOST}space/${spaceId}/scan-status/${documentId}`
  );
};

//TODO: DIY
// export const useAttachmentFileScanStatus = () => {
//   return useQuery()
// }

export const deleteDocumentCard = async ({
  customerId,
  organization = null,
  documentId,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/document/${documentId}`
  );
};

export const downloadDocumentData = async ({
  customerId,
  organization = null,
  documentId,
  fileName,
  type,
  viewOnly = false,
}) => {
  return await axiosWithAuth
    .get(
      `${HOST}${organization}/customer/${customerId}/document/${documentId}/download`,
      {
        responseType: "arraybuffer",
      }
    )
    .then((blob) => {
      download({
        fileName: fileName,
        fileData: blob.data,
        fileOptions: {
          type: type,
        },
        viewOnly,
      });
    });
};

export const updateDocument = async ({
  customerId,
  organization = null,
  documentId,
  data,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/document/${documentId}`,
    data
  );
};

export const creatCreditReports = async ({
  customerId,
  organization = null,
  data,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/conf-credit-reports`,
    data
  );
};

export const getGroupingCustomers = async ({
  organization = null,
  gid = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/consumer-group/${gid}`
  );
};
