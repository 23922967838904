import React, { useEffect, useState, useRef, useContext } from "react";
import { useForm, Controller, useWatch, useFieldArray } from "react-hook-form";
import moment from "moment";
import { TextBox } from "../../../components/TextBox";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import { FormattedMessage, useIntl } from "react-intl";
import { TextButton, SIZE } from "../../../components/TextButton";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import CustomerPortalSettingLabel from "../../../components/CustomerPortalSettingLabel/CustomerPortalSettingLabel";
import {
  ParagraphSmall,
  Label2,
  LabelLarge,
  ParagraphLarge,
  HeadingXLarge,
  LabelXSmall,
  HeadingXSmall,
} from "baseui/typography";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import { SelectBox } from "../../../components/SelectBox";
import CheckBox from "../../../components/CheckBox/CheckBox";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import InfoPortal from "../../../assets/img/svg/InfoPortal";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import { useParams } from "react-router-dom";
import setSelectValues from "../../../utils/setSelectValues";
import { useMutation, useQuery } from "react-query";
import { updateCreditPortal } from "../../../services/creditPortal";
import { toast } from "react-toastify";
import getSelectValues from "../../../utils/getSelectValues";
import { IconButton, KIND } from "../../../components/IconButton";
import CopyToClipboard from "react-copy-to-clipboard";
import ClipBoard from "../../../assets/img/svg/ClipBoard";
import { getCustomerPortal } from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { TimePickerAction } from "../../../components/TimePicker/TimePicker";
import { CP_AUTHENTICATION } from "../../../providers/RBACProvider/permissionList";
import { useSelector } from "react-redux";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import Plus from "../../../assets/img/svg/Upload/Plus";
import { Input } from "baseui/input";
import { COMMISSION_SLAB, NEW, VIEW } from "../../../constants";
import {
  getCommissionSlab,
  getDiscountSlabFn,
} from "../../../services/organization";
import { Icon, Typography } from "../../../components_v2";
import DiscountSlabModal from "../../Setting/DiscountSlab/DiscountSlabModal";
import _ from "lodash";

const CustomerPortal = ({ getParams, setIsOpen }) => {
  const intl = useIntl();
  const { id } = useParams();
  const [customerData, setCustomerData] = useState(null);
  const notify = () => toast.success("Linked Copied Successfully");
  const [isModalState, setisModalState] = useState({
    isOpen: false,
    data: {},
    type: NEW,
  });

  const { customerDetails, smsGateWay } = useSelector(
    (state) => state.customerOverviewReducer
  );
  let { currentOrganization, referenceData } = useContext(MaxyfiContext);
  //

  // Start Time

  var minStart = moment();
  minStart.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

  var maxStart = moment();
  maxStart.set({ hour: 17, minute: 0, second: 0, millisecond: 0 });

  const [copied, setCopied] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    // mode: "onChange",
    defaultValues: {
      pre_defined_payment_plan: [
        { payment_of: "", leadtime: "", frequency: "", discount: "" },
      ],
    },
  });

  const CommissionSlab = useQuery(
    [`DISCOUNT_SLAB-${currentOrganization}`],
    async ({ queryKey }) => {
      // let { filters } = queryKey[1];
      return await getDiscountSlabFn({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  let paymentAmountRestriction = getSelectValues(
    watch("request_back.restrict_date")
  );

  useEffect(() => {
    if (paymentAmountRestriction !== "no_validation") {
      setValue("request_back.amount_percentage", 100);
    } else {
      setValue("request_back.amount_percentage", 0);
    }
  }, []);

  let customerValidData = customerData;
  useEffect(() => {
    if (customerValidData) {
      setValue("is_authentication", customerValidData.is_authentication);
      setValue("is_invoice", customerValidData.is_invoice);
      setValue("is_credit_note", customerValidData.is_credit_note);
      setValue("is_payment", customerValidData.is_payment);
      setValue("is_message", customerValidData.is_message);
      setValue("is_callback", customerValidData.is_callback);
      setValue("is_request_back", customerValidData.is_request_back);
      setValue(
        "is_request_payment_plan",
        customerValidData.is_request_payment_plan
      );
      setValue(
        "invoice.is_allow_invoice_download",
        customerValidData.invoice.is_allow_invoice_download
      );
      setValue(
        "invoice.is_display_paid_invoices",
        customerValidData.invoice.is_display_paid_invoices
      );
      setValue(
        "invoice.is_allow_payment_receipt_download",
        customerValidData.invoice.is_allow_payment_receipt_download
      );
      setValue(
        "invoice.is_display_invoice_description",
        customerValidData.invoice.is_display_invoice_description
      );
      setValue(
        "credit_note.is_display_adjusted_credit_note",
        customerValidData.credit_note.is_display_adjusted_credit_note
      );
      setValue(
        "payment.is_adhoc_payment",
        customerValidData.payment.is_adhoc_payment
      );
      setValue(
        "payment.is_pay_all_dues",
        customerValidData.payment.is_pay_all_dues
      );
      setValue(
        "payment.is_pay_overdues",
        customerValidData.payment.is_pay_overdues
      );
      setValue(
        "message.is_display_historical_chat",
        customerValidData.message.is_display_historical_chat
      );
      setValue(
        "message.is_allow_files_transfered",
        customerValidData.message.is_allow_files_transfered
      );
      setValue(
        "callback.callback_days",
        setSelectValues(customerValidData.callback.callback_days, true)
      );
      setValue(
        "callback.callback_duration",
        setSelectValues(customerValidData.callback.callback_duration)
      );

      let startLocalDate = utcTimstampToLocalDate(
        customerValidData.callback.start_time
      );
      let endLocalDate = utcTimstampToLocalDate(
        customerValidData.callback.end_time
      );

      setValue(
        "callback.start_time",
        setSelectValues(customerValidData.callback.start_time)
      );
      setValue(
        "callback.end_time",
        setSelectValues(customerValidData.callback.end_time)
      );
      setValue(
        "request_back.amount_percentage",
        customerValidData.request_back.amount_percentage
      );
      setValue(
        "request_back.amount_restriction",
        setSelectValues(customerValidData?.request_back?.amount_restriction)
      );
      setValue(
        "request_back.restrict_date",
        setSelectValues(customerValidData.request_back.restrict_date)
      );
      setValue(
        "payment_plan.instalmentextension",
        setSelectValues(
          customerValidData?.request_payment_plan?.maximum_instalment_extention
        )
      );
      setValue(
        "payment_plan.frequency",
        setSelectValues(
          customerValidData?.request_payment_plan?.payment_plan_freequency,
          true
        )
      );
      setValue(
        "payment_plan.residual",
        setSelectValues(
          customerValidData?.request_payment_plan?.payment_plan_residual
        )
      );

      let slabData = _.get(CommissionSlab, "data.data.docs", []);
      let legCommissionSlab = _.get(
        customerValidData,
        "request_payment_plan.disc_slb",
        ""
      );
      if (legCommissionSlab && slabData) {
        let findCommissionSlab = slabData.find(
          ({ _id }) => _id == legCommissionSlab
        );
        let findata = [
          {
            id: findCommissionSlab?._id,
            label: findCommissionSlab?.slab_name,
            ...findCommissionSlab,
          },
        ];

        setValue("payment_plan.disc_slb", findata);
      }
      // setValue(
      //   "payment_plan.disc_slb",
      //   setSelectValues(
      //     customerValidData?.request_payment_plan?.disc_slb
      //   )
      // );
      setValue(
        "is_pre_defined_payment_plan",
        customerValidData?.is_pre_defined_payment_plan
      );

      setValue("pre_defined_payment_plan");
      customerValidData &&
        customerValidData?.pre_defined_payment_plan &&
        customerValidData?.pre_defined_payment_plan.map((e, i) => {
          update(i, {
            payment_of: e?.no_of_payments,
            leadtime: e?.max_lead_time,
            discount: e?.discount,
            frequency: setSelectValues(e?.payment_plan_frequency),
          });
        });
    }
  }, [customerValidData, CommissionSlab.isFetching]);
  // const watchValues = watch();
  let installment = [
    {
      id: "ONE_WEEK",
      label: "1 Week",
    },
    { id: "TWO_WEEKS", label: "2 Week" },
    { id: "THREE_WEEKS", label: "3 Week" },
    { id: "ONE_MONTH", label: "1 Month" },
    { id: "TWO_MONTHS", label: "2 Months" },
    { id: "THREE_MONTHS", label: "3 Months" },
    { id: "NO_VALIDATION", label: "No Validation" },
  ];
  let Frequency = [
    { id: "WEEKLY", label: "Weekly" },
    { id: "FORTNIGHTLY", label: "Fortnightly" },
    { id: "MONTHLY", label: "Monthly" },
    { id: "QUARTERLY", label: "Quarterly" },
  ];
  let Residual = [
    { id: "FIRST_INSTALMENT", label: "FirstInstallment" },
    { id: "LAST_INSTALMENT", label: "LastInstallment" },
    { id: "KEEP_LAST_INSTALMENT", label: "Keep as Last Installment" },
  ];
  const { payment_plan } = watch();
  // useEffect(() => {}, [watchValues]);
  const [ispre, setIsPre] = useState(1);
  useEffect(() => {
    const getCustomerPortalDatas = async () => {
      let data = await getCustomerPortal({
        customerPortalId: getParams,
        // clientCode: ["org_1"],
        organization: currentOrganization,
      });
      setCustomerData(data.data.doc);
    };

    getCustomerPortalDatas();
  }, []);

  const updateCustomerPortal = useMutation(
    (data) =>
      updateCreditPortal({
        ...data,
        organization: currentOrganization,
        id: getParams,
      }),

    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);

          // toast.error(data.message);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {},
    }
  );

  const onSubmit = async (data) => {
    let phoneList =
      data &&
      data.pre_defined_payment_plan &&
      data.pre_defined_payment_plan
        .map((value) => {
          let newValue = {};
          newValue.discount = Number(value?.discount);
          newValue.payment_plan_frequency = getSelectValues(value?.frequency);
          newValue.max_lead_time = Number(value?.leadtime);
          newValue.no_of_payments = Number(value?.payment_of);
          return newValue;
        })
        .filter((obj) => obj?.no_of_payments);

    let newValues = {
      ...data,
      request_back: {
        ...data.request_back,
        amount_restriction: getSelectValues(
          data.request_back.amount_restriction
        ),
        restrict_date: getSelectValues(data.request_back.restrict_date),
      },
      request_payment_plan: {
        maximum_instalment_extention: getSelectValues(
          data.payment_plan.instalmentextension
        ),
        payment_plan_freequency: getSelectValues(
          data.payment_plan.frequency,
          true
        ),
        payment_plan_residual: getSelectValues(data.payment_plan.residual),
        disc_slb: getSelectValues(data.payment_plan.disc_slb),
      },
      callback: {
        callback_days: getSelectValues(data.callback.callback_days, true),
        callback_duration: getSelectValues(data.callback.callback_duration),
        start_time: getSelectValues(data.callback.start_time),
        end_time: getSelectValues(data.callback.end_time),
      },
      is_pre_defined_payment_plan: data.is_pre_defined_payment_plan,
      pre_defined_payment_plan: phoneList,
    };
    delete newValues.payment_plan;
    // delete newValues?.pre_defined_payment_plan;
    await updateCustomerPortal.mutateAsync({ newValues });
  };
  const { fields, append, update, remove, swap, move, insert } = useFieldArray({
    control,
    name: "pre_defined_payment_plan",
  });

  return (
    <>
      <div
        style={{
          height: "100%",
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          // onChange={handleSubmit(onChange)}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Customer Portal Link */}
          <div
            style={{
              height: "100%",
              overflow: "scroll",
              // paddingTop: "20px",
              // paddingBottom: "20px",
              paddingLeft: "35px",
              paddingRight: "35px",
            }}
          >
            {/* Customer Poratl Setting */}
            <div style={{ margin: "10px 0px", display: "flex" }}>
              <LabelLarge>
                {/* <span style={{ color: "#516beb", fontSize: "16px" }}>
                  {customerDetails.display_name}
                </span>{" "} */}
                Portal Settings
              </LabelLarge>
              <StatefulTooltip
                content={() => (
                  <LabelXSmall>
                    Control what information your consumer can view and options
                    they can respond from Consumer Portal
                  </LabelXSmall>
                )}
                placement={PLACEMENT.right}
                showArrow
                returnFocus
                autoFocus
                overrides={{
                  Inner: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#FBFBFB",
                    }),
                  },
                  Arrow: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#FBFBFB",
                    }),
                  },

                  Body: {
                    style: ({ $theme }) => ({
                      boxShadow: "0px 4px 4px 0px  #333860",
                      backgroundColor: "#FBFBFB",
                      width: "180px",
                    }),
                  },
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <InfoPortal />
                </span>
              </StatefulTooltip>
            </div>

            {/* Authentication */}
            {/* <RBACWrapper role={CP_AUTHENTICATION}>
              <CustomerPortalSettingLabel
                label={"is_authentication"}
                isActive={watch("is_authentication")}
                setActive={(data) => setValue("is_authentication", data)}
                children={""}
                caption={"Secure Portal Access via OTP Authentication"}
              />
            </RBACWrapper> */}

            {/* Invoices */}
            <div style={{ marginBottom: "20px" }}>
              <CustomerPortalSettingLabel
                label={"is_invoice"}
                isActive={watch("is_invoice")}
                setActive={(data) => setValue("is_invoice", data)}
                caption={"Display Invoice information in the Portal"}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>
                          <Controller
                            name="invoice.is_allow_invoice_download"
                            control={control}
                            render={({ field }) => (
                              <CheckBoxBaseweb
                                {...field}
                                checked={field.value}
                                checkmarkType={STYLE_TYPE.default}
                                // disabled={true}
                              >
                                <HeadingXSmall>
                                  <FormattedMessage id="allow_invoice_download">
                                    allow_invoice_download
                                  </FormattedMessage>
                                </HeadingXSmall>
                              </CheckBoxBaseweb>
                            )}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>
                          <Controller
                            name="invoice.is_display_paid_invoices"
                            control={control}
                            render={({ field }) => (
                              <CheckBoxBaseweb
                                {...field}
                                checked={field.value}
                                checkmarkType={STYLE_TYPE.default}
                                // checked={true}
                              >
                                <HeadingXSmall>
                                  <FormattedMessage id="display_paid_invoices">
                                    display_paid_invoices
                                  </FormattedMessage>
                                </HeadingXSmall>
                              </CheckBoxBaseweb>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "row",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <Controller
                          name="invoice.is_allow_payment_receipt_download"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                              // checked={true}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="allow_payment_receipt_download">
                                  Allow_payment_receipt_download
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      <div>
                        {/* <Controller
                          name="invoice.is_display_invoice_description"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                              // checked={true}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="is_display_invoice_description">
                                  Allow_payment_receipt_download
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        /> */}
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* Credit Note */}
            {/* Payment */}
            <div style={{ marginBottom: "" }}>
              <CustomerPortalSettingLabel
                label={"is_payment"}
                isActive={watch("is_payment")}
                setActive={(data) => setValue("is_payment", data)}
                disable={
                  smsGateWay &&
                  smsGateWay.customer_portal &&
                  !smsGateWay.customer_portal.is_payment
                }
                caption={
                  customerData?.is_configured
                    ? ""
                    : "Get Paid Online or Payment Confirmation"
                }
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Controller
                          name="payment.is_pay_all_dues"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="pay_all_dues">
                                  pay_all_dues
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <Controller
                          name="payment.is_pay_overdues"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="pay_overdues">
                                  pay_overdues
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <Controller
                          name="payment.is_adhoc_payment"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              checkmarkType={STYLE_TYPE.default}
                            >
                              <HeadingXSmall>
                                <FormattedMessage id="adhoc_payment">
                                  adhoc_payment
                                </FormattedMessage>
                              </HeadingXSmall>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* Message */}
            {/* Call Back */}
            <div style={{ marginBottom: "20px" }}>
              <CustomerPortalSettingLabel
                label={"is_callback"}
                isActive={watch("is_callback")}
                setActive={(data) => setValue("is_callback", data)}
                caption={"Consumers can request Call Back"}
                checkBox={true}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="callback.callback_days"
                          control={control}
                          render={({ field }) => (
                            <MultiSelect
                              {...field}
                              multi
                              name={field.name}
                              // label={intl.formatMessage({
                              //   id: "callback",
                              // })}
                              placeholder={intl.formatMessage({
                                id: "callback",
                              })}
                              value={field.value}
                              options={referenceData["week_days"]}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              size={SIZE.mini}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="callback.callback_duration"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              {...field}
                              name={field.name}
                              label={intl.formatMessage({
                                id: "duration",
                              })}
                              placeholder={intl.formatMessage({
                                id: "duration",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                // onChange();
                              }}
                              // options={[
                              //   {
                              //     label: "1 Day",
                              //     id: "1_day",
                              //   },
                              //   { label: "2 Days", id: "2_days" },
                              //   { label: "1 Week", id: "1_week" },
                              //   { label: "2 Weeks", id: "2_weeks" },
                              //   { label: "No Validation", id: "no_validaion" },
                              // ]}
                              options={referenceData["call_back_duration"]}
                              size={SIZE.mini}
                            />
                          )}
                        />
                      </div>
                      <div style={{ display: "flex", gap: "30px" }}>
                        <div style={{ width: "100px" }}>
                          {/* <Controller
                            name="start_time"
                            control={control}
                            render={({ field }) => (
                              <TimePickerAction
                                {...field}
                                clearable={false}
                                name={field.name}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                label={intl.formatMessage({
                                  id: "start",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "start",
                                })}
                                minTime={new Date(minStart.toISOString())}
                                maxTime={new Date(maxStart.toISOString())}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                              />
                            )}
                          /> */}
                          <Controller
                            name="callback.start_time"
                            control={control}
                            render={({ field }) => (
                              <SelectBox
                                clearable={false}
                                style={{ marginTop: "0px" }}
                                {...field}
                                name={field.name}
                                label={intl.formatMessage({
                                  id: "start",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "start",
                                })}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={referenceData["org_time"]}
                                size={SIZE.mini}
                              />
                            )}
                          />
                        </div>
                        <div style={{ width: "100px" }}>
                          <Controller
                            defaultValues={""}
                            name="callback.end_time"
                            control={control}
                            render={({ field }) => (
                              <SelectBox
                                clearable={false}
                                style={{ marginTop: "0px" }}
                                {...field}
                                size={SIZE.mini}
                                name={field.name}
                                label={intl.formatMessage({
                                  id: "end",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "end",
                                })}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={referenceData["org_time"]}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* Request Back */}
            <div style={{ marginBottom: "" }}>
              <CustomerPortalSettingLabel
                label={"is_request_back"}
                isActive={watch("is_request_back")}
                checkBox={true}
                setActive={(data) => setValue("is_request_back", data)}
                caption={"Consumers can request additional Payment Time"}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="request_back.amount_restriction"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              clearable={false}
                              label={intl.formatMessage({
                                id: "amnt_restriction",
                              })}
                              placeholder={intl.formatMessage({
                                id: "amnt_restriction",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                // onChange();
                              }}
                              options={
                                referenceData["payment_amount_restriction"]
                              }
                              size={SIZE.mini}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="request_back.restrict_date"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              label={intl.formatMessage({
                                id: "restrict_date",
                              })}
                              placeholder={intl.formatMessage({
                                id: "restrict_date",
                              })}
                              size={SIZE.mini}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                //
                                // (e.value && e.value[0].id === "full_amount") ||
                                // (e.value && e.value[0].id === "due_amount") ? (
                                //   <>
                                //     {setValue(
                                //       "request_back.amount_percentage",
                                //       100
                                //     )}
                                //   </>
                                // ) : e.value &&
                                //   e.value[0].id === "no_validation" ? (
                                //   <>
                                //     {setValue(
                                //       "request_back.amount_percentage",
                                //       0
                                //     )}
                                //   </>
                                // ) : (
                                //   <></>
                                // );
                                // onChange();
                              }}
                              options={
                                referenceData["payment_extension_duration"]
                              }
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="request_back.amount_percentage"
                          control={control}
                          rules={{
                            max: {
                              value: 100,
                              message: "Enter percentage between 0 to 100", // JS only: <p>error message</p> TS only support string
                            },
                          }}
                          render={({ field }) => (
                            <>
                              <TextBox
                                {...field}
                                fullWidth
                                disabled={
                                  paymentAmountRestriction === "no_validation"
                                }
                                name={field.name}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                label={intl.formatMessage({
                                  id: "percentage",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "percentage",
                                })}
                                value={field.value}
                                size={SIZE.mini}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* PaymentPlan */}
            <div style={{ marginBottom: "" }}>
              <CustomerPortalSettingLabel
                label={"is_payment_plan"}
                isActive={watch("is_request_payment_plan")}
                checkBox={true}
                setActive={(data) => setValue("is_request_payment_plan", data)}
                caption={"Consumers can request Payment Plan"}
                children={
                  <>
                    <div
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="payment_plan.instalmentextension"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              clearable={false}
                              label={intl.formatMessage({
                                id: "instalment_extension",
                              })}
                              placeholder={intl.formatMessage({
                                id: "instalment_extension",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                // onChange();
                              }}
                              options={
                                referenceData["payment_plan_maximum_instalment"]
                              }
                              size={SIZE.mini}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="payment_plan.frequency"
                          control={control}
                          render={({ field }) => (
                            <MultiSelect
                              {...field}
                              multi
                              name={field.name}
                              placeholder={intl.formatMessage({
                                id: "payment_frequency",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              size={SIZE.mini}
                              options={referenceData["payment_plan_frequency"]}
                            />
                          )}
                        />
                      </div>
                      <div style={{ width: "260px" }}>
                        <Controller
                          name="payment_plan.residual"
                          control={control}
                          rules={{
                            max: {
                              value: 100,
                              message: "Enter percentage between 0 to 100", // JS only: <p>error message</p> TS only support string
                            },
                          }}
                          render={({ field }) => (
                            <SelectBox
                              clearable={false}
                              style={{ marginTop: "0px" }}
                              {...field}
                              name={field.name}
                              label={intl.formatMessage({
                                id: "payment_residual",
                              })}
                              placeholder={intl.formatMessage({
                                id: "payment_residual",
                              })}
                              size={SIZE.default}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              disabled={
                                payment_plan?.instalmentextension[0]?.label ==
                                "No Validation"
                              }
                              options={referenceData["payment_plan_residual"]}
                            />
                          )}
                        />
                      </div>
                      <div
                        style={{
                          width: "300px",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div style={{ position: "relative", width: "260px" }}>
                          <Controller
                            name="payment_plan.disc_slb"
                            control={control}
                            rules={{
                              max: {
                                value: 100,
                                message: "Enter percentage between 0 to 100", // JS only: <p>error message</p> TS only support string
                              },
                            }}
                            render={({ field }) => (
                              <SelectBox
                                clearable={false}
                                style={{ marginTop: "0px" }}
                                {...field}
                                name={field.name}
                                label={intl.formatMessage({
                                  id: "discount_slab",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "discount_slab",
                                })}
                                size={SIZE.default}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                disabled={
                                  payment_plan?.instalmentextension[0]?.label ==
                                  "No Validation"
                                }
                                options={
                                  CommissionSlab &&
                                  CommissionSlab.data &&
                                  CommissionSlab.data.data &&
                                  CommissionSlab.data.data.docs &&
                                  Array.isArray(
                                    CommissionSlab.data.data.docs
                                  ) &&
                                  CommissionSlab.data.data.docs
                                    .map((e) => {
                                      let max =
                                        e?.slab_range_list?.[0]?.percentage;
                                      let min =
                                        e?.slab_range_list?.[0]?.percentage;
                                      for (
                                        let i = 1;
                                        i < e?.slab_range_list?.length;
                                        i++
                                      ) {
                                        if (
                                          e?.slab_range_list?.[i].percentage >
                                          max
                                        ) {
                                          max =
                                            e?.slab_range_list?.[i].percentage;
                                        }
                                        if (
                                          e?.slab_range_list?.[i].percentage <
                                          min
                                        ) {
                                          min =
                                            e?.slab_range_list?.[i].percentage;
                                        }
                                      }
                                      return {
                                        id: e._id,
                                        label: `${e.slab_name} (${min}% - ${max}%)`,
                                        ...e,
                                      };
                                    })
                                    .sort((a, b) =>
                                      a?.label?.localeCompare(b?.label)
                                    )
                                }
                              />
                            )}
                          />
                          <div
                            className="add_discont_slan"
                            onClick={() => {
                              setisModalState({
                                isOpen: true,
                                data: {},
                              });
                            }}
                          >
                            <Icon icon="plus" color="#516beb" />
                            <Typography> Add Discount Slab</Typography>
                          </div>
                        </div>
                        {payment_plan &&
                          payment_plan.discount_slab &&
                          payment_plan.discount_slab[0] && (
                            <div style={{ marginTop: "5px" }}>
                              <IconButton
                                type="button"
                                kind={KIND.primary}
                                onClick={() => {
                                  setisModalState({
                                    type: VIEW,
                                    isOpen: true,
                                    data:
                                      payment_plan &&
                                      payment_plan.discount_slab,
                                  });
                                }}
                              >
                                <Icon icon="view_icon" />
                              </IconButton>
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                }
              />
            </div>
            {/* is_per_payment_plan */}
            <div style={{ marginBottom: "" }}>
              <CustomerPortalSettingLabel
                label={"is_per_payment_plan"}
                isActive={watch("is_pre_defined_payment_plan")}
                checkBox={true}
                setActive={(data) =>
                  setValue("is_pre_defined_payment_plan", data)
                }
                caption={"Consumers can request Payment Plan"}
                children={
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      {fields &&
                        fields.map((e, i) => {
                          return (
                            <>
                              <div
                                key={e.id}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}
                              >
                                <div style={{ flex: "1" }}>
                                  <Controller
                                    name={`pre_defined_payment_plan.${i}.payment_of`}
                                    control={control}
                                    rules={{
                                      max: {
                                        value: 999,
                                        message:
                                          "Enter percentage between 0 to 999", // JS only: <p>error message</p> TS only support string
                                      },
                                      required: "Required",
                                    }}
                                    render={({ field }) => (
                                      <TextBox
                                        type="number"
                                        {...field}
                                        //   disabled={isDisable}
                                        fullWidth
                                        name={field.name}
                                        size={SIZE.default}
                                        error={
                                          errors &&
                                          errors["pre_defined_payment_plan"] &&
                                          errors["pre_defined_payment_plan"][
                                            i
                                          ] &&
                                          errors["pre_defined_payment_plan"][i][
                                            "payment_of"
                                          ] &&
                                          errors["pre_defined_payment_plan"][i][
                                            "payment_of"
                                          ].message
                                        }
                                        label={intl.formatMessage({
                                          id: "of_pay",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "of_pay",
                                        })}
                                        // value={field.value}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key == "ArrowUp" ||
                                            e.key == "ArrowDown"
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div style={{ flex: "1" }}>
                                  <Controller
                                    name={`pre_defined_payment_plan.${i}.frequency`}
                                    control={control}
                                    rules={{ required: "Required" }}
                                    render={({ field }) => (
                                      <>
                                        <SelectBox
                                          clearable={false}
                                          style={{ marginTop: "0px" }}
                                          {...field}
                                          name={field.name}
                                          label={intl.formatMessage({
                                            id: "frequency",
                                          })}
                                          placeholder={intl.formatMessage({
                                            id: "frequency",
                                          })}
                                          size={SIZE.default}
                                          value={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.value);
                                          }}
                                          error={
                                            errors &&
                                            errors[
                                              "pre_defined_payment_plan"
                                            ] &&
                                            errors["pre_defined_payment_plan"][
                                              i
                                            ] &&
                                            errors["pre_defined_payment_plan"][
                                              i
                                            ]["frequency"] &&
                                            errors["pre_defined_payment_plan"][
                                              i
                                            ]["frequency"].message
                                          }
                                          disabled={
                                            payment_plan?.instalmentextension[0]
                                              ?.label == "No Validation"
                                          }
                                          options={
                                            referenceData[
                                              "pre_defined_payment_plan_frequency"
                                            ]
                                          }
                                        />
                                      </>
                                    )}
                                  />
                                </div>
                                {/* </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            > */}
                                <div style={{ flex: "1" }}>
                                  <Controller
                                    name={`pre_defined_payment_plan.${i}.leadtime`}
                                    control={control}
                                    rules={{
                                      max: {
                                        value: 999,
                                        message:
                                          "Enter percentage between 0 to 999", // JS only: <p>error message</p> TS only support string
                                      },
                                      required: "Required",
                                    }}
                                    render={({ field }) => (
                                      <TextBox
                                        {...field}
                                        //   disabled={isDisable}
                                        fullWidth
                                        name={field.name}
                                        size={SIZE.default}
                                        label={intl.formatMessage({
                                          id: "max_lead_time",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "max_lead_time",
                                        })}
                                        error={
                                          errors &&
                                          errors["pre_defined_payment_plan"] &&
                                          errors["pre_defined_payment_plan"][
                                            i
                                          ] &&
                                          errors["pre_defined_payment_plan"][i][
                                            "leadtime"
                                          ] &&
                                          errors["pre_defined_payment_plan"][i][
                                            "leadtime"
                                          ].message
                                        }
                                        value={field.value}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key == "ArrowUp" ||
                                            e.key == "ArrowDown"
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div style={{ flex: "1" }}>
                                  <Controller
                                    name={`pre_defined_payment_plan.${i}.discount`}
                                    control={control}
                                    rules={{
                                      max: {
                                        value: 100,
                                        message:
                                          "Enter percentage between 0 to 100", // JS only: <p>error message</p> TS only support string
                                      },
                                    }}
                                    render={({ field }) => (
                                      <TextBox
                                        {...field}
                                        fullWidth
                                        name={field.name}
                                        size={SIZE.default}
                                        error={
                                          errors[field.name] &&
                                          errors[field.name].message
                                        }
                                        label={intl.formatMessage({
                                          id: "discount",
                                        })}
                                        placeholder={intl.formatMessage({
                                          id: "discount",
                                        })}
                                        value={field.value}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key == "ArrowUp" ||
                                            e.key == "ArrowDown"
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                                <div style={{ paddingBottom: "15px" }}>
                                  {i > 0 ? (
                                    <TextButton
                                      type="button"
                                      kind={KIND.tertiary}
                                      size={SIZE.default}
                                      onClick={() => remove(i)}
                                    >
                                      <Delete color="#FD372A" />
                                    </TextButton>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div style={{ widows: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <TextButton
                          type="button"
                          kind={KIND.tertiary}
                          size={SIZE.mini}
                          onClick={() =>
                            append({
                              payment_of: "",
                              leadtime: "",
                              frequency: "",
                              discount: "",
                            })
                          }
                        >
                          <Plus fill={"#516BEB"} />
                          <span className="new_payment_plan">
                            Add New Schedule
                          </span>
                        </TextButton>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          </div>
          <div style={{ padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ width: "50px", marginRight: "15px" }}>
                <TextButton
                  type="button"
                  size={SIZE.mini}
                  fullWidth
                  disabled={isSubmitting}
                  kind={KIND.tertiary}
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </TextButton>
              </div>

              <div style={{ width: "50px", marginRight: "15px" }}>
                <TextButton
                  type="submit"
                  size={SIZE.mini}
                  fullWidth
                  disabled={isSubmitting}
                >
                  Apply
                </TextButton>
              </div>
            </div>
          </div>
        </form>
      </div>
      <DiscountSlabModal
        isModalState={isModalState}
        setisModalState={setisModalState}
        refetch={CommissionSlab.refetch}
      />
    </>
  );
};

export default CustomerPortal;
