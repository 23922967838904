import {
  SET_GENERAL_DETAILS,
  SET_TEMPLATE_LANGUAGE,
  SET_TEMPLATE_CONTENT,
  RESET_TEMPLATE,
  SET_TEMPLATE_VIEW_ONLY,
  SET_ACTION_TYPE,
  IS_MODAL,
  AT_DATA,
  UPDATE_EVENT_TAG,
  ADD_TEMPLATE_ATTACHMENT,
  REMOVE_TEMPLATE_ATTACHMENT,
  REFETCH_TEMPLATE,
  CURRENT_SELECTED_LANGUAGE,
  COMMUNTICATION_EMAIL_ATTACHMENT_FILE,
  COMMUNTICATION_EMAIL_ATTACHMENT_FILE_REMOVE,
  COMMUNTICATION_TEMPLATE_UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP,
  COMMUNTICATION_TEMPLATE_ADD_EMAIL_ATTACHMENT_FROM_DESKTOP,
  COMMUNTICATION_TEMPLATE_DOCUMENT_DRIVE_DATA,
  COMMUNTICATION_TEMPLATE_UNSELECTED_DOCUMENT_DATA,
} from "./action";
import { EN, EMAIL, NEW, COPY } from "../../constants";

const initialState = {
  viewOnly: true,
  generalDetails: {
    type: "",
    name: "",
    description: "",
    clientCode: [],
    language: "en",
    isValid: false,
  },
  layout: {},
  content: {}, //{[lang]: {value: "", subject: ""}}
  currentLanguage: "en",
  actionType: NEW,
  isModal: false,
  atData: [],
  eventTags: [],
  templateFetch: 0,
  selectedLanguage: "en",
  emailFileAttachment: [],
  emailAttachment: [],
};

const communicationTemplateReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case CURRENT_SELECTED_LANGUAGE: {
      return {
        ...state,
        generalDetails: { ...state.generalDetails, language: payload },
      };
    }

    case REFETCH_TEMPLATE: {
      return {
        ...state,
        templateFetch: state.templateFetch + 1,
      };
    }
    case AT_DATA: {
      return { ...state, atData: payload };
    }

    case IS_MODAL: {
      return {
        ...state,
        isModal: payload,
      };
    }
    case SET_GENERAL_DETAILS: {
      let generalDetails = { ...state.generalDetails, ...payload };

      generalDetails.isValid = false;

      if (!Object.values(generalDetails).every((x) => x === null || x === "")) {
        generalDetails.isValid = true;
      }

      if (generalDetails && generalDetails.attachments) {
        let setAttachment = generalDetails.attachments.map((e) => {
          return {
            ...e,
            fileRef: e.id,
            name: e.name,
            isTemplate: e.type === "TEMPLATE" ? true : false,
          };
        });
        state.emailAttachment.push(...setAttachment);
      }

      let currentLanguage = "en";

      let content = {
        ["en"]: {},
      };

      content = payload.status ? payload?.content : payload?.draftContent;

      // if (
      //   state.content &&
      //   Object.keys(state.content).length > 0 &&
      //   !payload.content
      // ) {
      //   content = { ...state.content };
      // }

      return {
        ...state,
        generalDetails,
        currentLanguage,
        content,
        eventTags: payload.tags,
      };
    }

    case SET_TEMPLATE_LANGUAGE: {
      let content = {
        ...state.content,
        ["en"]: { ...state.content["en"] },
      };
      return { ...state, currentLanguage: payload, content };
    }

    case SET_TEMPLATE_CONTENT: {
      return {
        ...state,
        content: {
          ...state.content,
          ["en"]: {
            ...(state.content && state.content["en"]
              ? state.content["en"]
              : {}),
            ...payload,
          },
        },
      };
    }

    case RESET_TEMPLATE: {
      return { ...state, ...initialState, emailAttachment: [] };
    }

    case SET_TEMPLATE_VIEW_ONLY: {
      return { ...state, viewOnly: payload };
    }

    case SET_ACTION_TYPE: {
      return { ...state, actionType: payload };
    }

    case UPDATE_EVENT_TAG: {
      let eventTags = [...initialState.eventTags, ...payload];
      eventTags = eventTags.filter((v, i, a) => a.indexOf(v) === i);
      return { ...state, eventTags };
    }

    case ADD_TEMPLATE_ATTACHMENT: {
      let currentAttachments =
        state.content &&
        state.content[state.currentLanguage] &&
        state.content[state.currentLanguage].attachments
          ? state.content[state.currentLanguage].attachments
          : [];

      return {
        ...state,
        content: {
          ...state.content,
          [state.currentLanguage]: {
            ...(state.content && state.content[state.currentLanguage]
              ? state.content[state.currentLanguage]
              : {}),
            attachments: [...currentAttachments, payload],
          },
        },
      };
    }

    case REMOVE_TEMPLATE_ATTACHMENT: {
      let currentAttachments =
        state.content &&
        state.content[state.currentLanguage] &&
        state.content[state.currentLanguage].attachments
          ? state.content[state.currentLanguage].attachments
          : [];

      currentAttachments = currentAttachments.filter(
        (e) => e.ref === payload.ref
      );

      return {
        ...state,
        content: {
          ...state.content,
          [state.currentLanguage]: {
            ...(state.content && state.content[state.currentLanguage]
              ? state.content[state.currentLanguage]
              : {}),
            attachments: [...currentAttachments],
          },
        },
      };
    }

    case COMMUNTICATION_EMAIL_ATTACHMENT_FILE: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.emailFileAttachment;

      let currentFileLength = currentFiles.length;
      let files = payload.map((file) => {
        currentFileLength++;
        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        emailAttachment: [...currentFiles, ...files],
      };
    }
    case COMMUNTICATION_EMAIL_ATTACHMENT_FILE_REMOVE: {
      let emailAttachment = state.emailFileAttachment.filter(
        (e) => e.tempId !== payload
      );
      return {
        ...state,
        emailAttachment: emailAttachment,
      };
    }

    case COMMUNTICATION_TEMPLATE_DOCUMENT_DRIVE_DATA:
      let attachData = [...state.emailAttachment];
      attachData.push({ ...payload });

      return {
        ...state,
        emailAttachment: attachData,
      };

    case COMMUNTICATION_TEMPLATE_UNSELECTED_DOCUMENT_DATA: {
      let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.emailAttachment.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        emailAttachment: filteredData,
      };
    }

    case COMMUNTICATION_TEMPLATE_ADD_EMAIL_ATTACHMENT_FROM_DESKTOP: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.emailAttachment;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;
        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        emailAttachment: [...currentFiles, ...files],
      };
    }

    case COMMUNTICATION_TEMPLATE_UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.emailAttachment.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let emailAttachment = state.emailAttachment;
        let currentFile = emailAttachment[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        emailAttachment[fileIndex] = currentFile;

        return { ...state, emailAttachment: emailAttachment };
      }

      return state;
    }

    default:
      return state;
  }
};

export default communicationTemplateReducer;
