export default function Help({ size = 22, color = "#F8F8FB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5367 5.79752C11.1042 5.71872 10.6596 5.73594 10.2345 5.84795C9.80941 5.95997 9.41411 6.16405 9.0766 6.44575C8.73909 6.72745 8.46762 7.07989 8.28139 7.47812C8.09516 7.87635 7.99874 8.31065 7.99893 8.75027C7.99893 8.94918 8.07795 9.13995 8.2186 9.2806C8.35926 9.42125 8.55002 9.50027 8.74893 9.50027C8.94785 9.50027 9.13861 9.42125 9.27926 9.2806C9.41992 9.13995 9.49893 8.94918 9.49893 8.75027C9.49874 8.5296 9.54725 8.31161 9.64098 8.11184C9.73471 7.91207 9.87136 7.73544 10.0412 7.59454C10.211 7.45365 10.4099 7.35196 10.6235 7.29673C10.8371 7.2415 11.0603 7.23409 11.2772 7.27502C11.5735 7.33254 11.8459 7.47706 12.0596 7.69014C12.2734 7.90322 12.4187 8.17517 12.4772 8.47127C12.5362 8.78207 12.4955 9.10358 12.3607 9.38983C12.226 9.67607 12.0043 9.9124 11.7272 10.065C11.2683 10.3309 10.8891 10.7149 10.6291 11.1771C10.3691 11.6393 10.2378 12.1628 10.2489 12.693V13.2503C10.2489 13.4492 10.328 13.6399 10.4686 13.7806C10.6093 13.9213 10.8 14.0003 10.9989 14.0003C11.1978 14.0003 11.3886 13.9213 11.5293 13.7806C11.6699 13.6399 11.7489 13.4492 11.7489 13.2503V12.693C11.7395 12.432 11.799 12.1732 11.9215 11.9425C12.0439 11.7118 12.225 11.5175 12.4464 11.379C12.9898 11.0806 13.4274 10.6211 13.699 10.0638C13.9706 9.50654 14.0629 8.87879 13.9631 8.26692C13.8634 7.65504 13.5766 7.08909 13.1421 6.64689C12.7075 6.2047 12.1467 5.90797 11.5367 5.79752Z"
        fill={color}
      />
      <path
        d="M10.9989 14.7489C11.4131 14.7489 11.7489 15.0847 11.7489 15.4989C11.7489 15.9131 11.4131 16.2489 10.9989 16.2489C10.5847 16.2489 10.2489 15.9131 10.2489 15.4989C10.2489 15.0847 10.5847 14.7489 10.9989 14.7489Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 2C9.21997 2 7.47991 2.52784 5.99987 3.51677C4.51983 4.50571 3.36628 5.91131 2.68509 7.55585C2.0039 9.20038 1.82567 11.01 2.17294 12.7558C2.5202 14.5016 3.37737 16.1053 4.63604 17.364C5.89472 18.6226 7.49836 19.4798 9.24419 19.8271C10.99 20.1743 12.7996 19.9961 14.4442 19.3149C16.0887 18.6337 17.4943 17.4802 18.4832 16.0001C19.4722 14.5201 20 12.78 20 11C19.9974 8.61384 19.0484 6.32616 17.3611 4.63889C15.6738 2.95162 13.3862 2.00258 11 2ZM11 18.5C9.51664 18.5 8.06659 18.0601 6.83323 17.236C5.59986 16.4119 4.63856 15.2406 4.07091 13.8701C3.50325 12.4997 3.35473 10.9917 3.64411 9.53682C3.9335 8.08196 4.64781 6.74559 5.6967 5.6967C6.7456 4.6478 8.08197 3.9335 9.53683 3.64411C10.9917 3.35472 12.4997 3.50325 13.8701 4.0709C15.2406 4.63856 16.4119 5.59985 17.236 6.83322C18.0601 8.06659 18.5 9.51664 18.5 11C18.4978 12.9885 17.7069 14.8948 16.3009 16.3009C14.8948 17.7069 12.9885 18.4978 11 18.5Z"
        fill={color}
      />
    </svg>
  );
}
