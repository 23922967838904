import React from "react";

const Alert = ({ width = 15, height = 19, color = "#FD372A" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 15 19`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.991667 2.73542C0.391667 3.00208 0 3.60208 0 4.26042V8.17708C0 12.8021 3.2 17.1271 7.5 18.1771C11.8 17.1271 15 12.8021 15 8.17708V4.26042C15 3.60208 14.6083 3.00208 14.0083 2.73542L8.175 0.14375C7.74167 -0.0479167 7.25 -0.0479167 6.825 0.14375L0.991667 2.73542ZM7.5 4.84375C7.95833 4.84375 8.33333 5.21875 8.33333 5.67708C8.33333 6.13542 7.95833 6.51042 7.5 6.51042C7.04167 6.51042 6.66667 6.13542 6.66667 5.67708C6.66667 5.21875 7.04167 4.84375 7.5 4.84375ZM7.5 8.17708C7.95833 8.17708 8.33333 8.55208 8.33333 9.01042V12.3437C8.33333 12.8021 7.95833 13.1771 7.5 13.1771C7.04167 13.1771 6.66667 12.8021 6.66667 12.3437V9.01042C6.66667 8.55208 7.04167 8.17708 7.5 8.17708Z"
        fill={color}
      />
    </svg>
  );
};

export default Alert;
