import React from "react";

const SuitcaseOutlineSvg = ({ size = "18", color = "#ADADAD" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.23081 15.875C2.85194 15.875 2.53125 15.7438 2.26875 15.4813C2.00625 15.2188 1.875 14.8981 1.875 14.5192V6.73081C1.875 6.35194 2.00625 6.03125 2.26875 5.76875C2.53125 5.50625 2.85194 5.375 3.23081 5.375H6.375V4.10581C6.375 3.72694 6.50625 3.40625 6.76875 3.14375C7.03125 2.88125 7.35194 2.75 7.73081 2.75H10.2692C10.6481 2.75 10.9688 2.88125 11.2313 3.14375C11.4938 3.40625 11.625 3.72694 11.625 4.10581V5.375H14.7692C15.1481 5.375 15.4688 5.50625 15.7313 5.76875C15.9938 6.03125 16.125 6.35194 16.125 6.73081V14.5192C16.125 14.8981 15.9938 15.2188 15.7313 15.4813C15.4688 15.7438 15.1481 15.875 14.7692 15.875H3.23081ZM3.23081 14.75H14.7692C14.8269 14.75 14.8798 14.7259 14.9278 14.6778C14.9759 14.6298 15 14.5769 15 14.5192V6.73081C15 6.67306 14.9759 6.62019 14.9278 6.57219C14.8798 6.52406 14.8269 6.5 14.7692 6.5H3.23081C3.17306 6.5 3.12019 6.52406 3.07219 6.57219C3.02406 6.62019 3 6.67306 3 6.73081V14.5192C3 14.5769 3.02406 14.6298 3.07219 14.6778C3.12019 14.7259 3.17306 14.75 3.23081 14.75ZM7.5 5.375H10.5V4.10581C10.5 4.04806 10.4759 3.99519 10.4278 3.94719C10.3798 3.89906 10.3269 3.875 10.2692 3.875H7.73081C7.67306 3.875 7.62019 3.89906 7.57219 3.94719C7.52406 3.99519 7.5 4.04806 7.5 4.10581V5.375Z"
        fill={color}
      />
    </svg>
  );
};

export default SuitcaseOutlineSvg;
