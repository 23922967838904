import React from "react";

function Premium() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.0034663 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87904 15.1538 3.84566 13.6541 2.34591C12.1543 0.84616 10.121 0.00250216 8 0ZM8 13.0909C7.85616 13.0909 7.71555 13.0483 7.59595 12.9683C7.47635 12.8884 7.38313 12.7748 7.32809 12.6419C7.27304 12.5091 7.25864 12.3628 7.2867 12.2218C7.31476 12.0807 7.38403 11.9511 7.48574 11.8494C7.58745 11.7477 7.71704 11.6784 7.85812 11.6503C7.99919 11.6223 8.14542 11.6367 8.27832 11.6917C8.41121 11.7468 8.52479 11.84 8.60471 11.9596C8.68462 12.0792 8.72727 12.2198 8.72727 12.3636C8.72727 12.5565 8.65065 12.7415 8.51426 12.8779C8.37787 13.0143 8.19289 13.0909 8 13.0909ZM9.37018 4.43636L8.72291 10.2589C8.70332 10.4369 8.61872 10.6015 8.48532 10.721C8.35192 10.8405 8.17911 10.9066 8 10.9066C7.82089 10.9066 7.64808 10.8405 7.51468 10.721C7.38128 10.6015 7.29668 10.4369 7.27709 10.2589L6.62982 4.43636C6.60881 4.24366 6.62858 4.04869 6.68785 3.86413C6.74711 3.67957 6.84455 3.50954 6.97382 3.36509C7.10647 3.22663 7.26577 3.11644 7.44213 3.04116C7.61848 2.96589 7.80825 2.92708 8 2.92708C8.19175 2.92708 8.38152 2.96589 8.55788 3.04116C8.73423 3.11644 8.89353 3.22663 9.02618 3.36509C9.15545 3.50954 9.25289 3.67957 9.31216 3.86413C9.37142 4.04869 9.39119 4.24366 9.37018 4.43636Z"
          fill="#FD372A"
        />
      </svg>
    </>
  );
}

export default Premium;
