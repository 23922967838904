export default function ExpandLeft({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12.5069 11.1674V6.83355C12.5069 6.65298 12.4272 6.52937 12.2678 6.46272C12.1084 6.39609 11.9642 6.42733 11.8351 6.55644L9.93711 8.4544C9.78217 8.60934 9.7047 8.7912 9.7047 9C9.7047 9.2088 9.783 9.39149 9.9396 9.54809L11.8353 11.4438C11.9643 11.5728 12.1084 11.6046 12.2678 11.5394C12.4272 11.4741 12.5069 11.3501 12.5069 11.1674ZM3.2024 16.3636C2.77174 16.3636 2.40307 16.2103 2.0964 15.9036C1.78971 15.5969 1.63636 15.2283 1.63636 14.7976V3.2024C1.63636 2.77174 1.78971 2.40307 2.0964 2.0964C2.40307 1.78971 2.77174 1.63636 3.2024 1.63636H14.7976C15.2283 1.63636 15.5969 1.78971 15.9036 2.0964C16.2103 2.40307 16.3636 2.77174 16.3636 3.2024V14.7976C16.3636 15.2283 16.2103 15.5969 15.9036 15.9036C15.5969 16.2103 15.2283 16.3636 14.7976 16.3636H3.2024ZM6.8342 15.0642H14.7976C14.8642 15.0642 14.9253 15.0364 14.9809 14.9809C15.0364 14.9253 15.0642 14.8643 15.0642 14.7976V3.2024C15.0642 3.13575 15.0364 3.07465 14.9809 3.01911C14.9253 2.96358 14.8642 2.93581 14.7976 2.93581H6.8342V15.0642Z"
        fill={color}
      />
    </svg>
  );
}
