export default function EmailStatus({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M3.30496 14.4887C2.95055 14.4887 2.64886 14.3642 2.39991 14.1153C2.15096 13.8663 2.02649 13.5646 2.02649 13.2101V4.78995C2.02649 4.43544 2.15096 4.13371 2.39991 3.88476C2.64886 3.63582 2.95055 3.51135 3.30496 3.51135H14.6951C15.0495 3.51135 15.3512 3.63582 15.6001 3.88476C15.8491 4.13371 15.9736 4.43544 15.9736 4.78995V13.2101C15.9736 13.5646 15.8491 13.8663 15.6001 14.1153C15.3512 14.3642 15.0495 14.4887 14.6951 14.4887H3.30496ZM14.9243 5.56658L9.34401 9.14927C9.28633 9.17812 9.2296 9.20192 9.17382 9.22067C9.11806 9.23942 9.06013 9.2488 9.00003 9.2488C8.93993 9.2488 8.882 9.23942 8.82623 9.22067C8.77046 9.20192 8.71493 9.17812 8.65964 9.14927L3.07574 5.56658V13.2087C3.07574 13.276 3.09738 13.3313 3.14065 13.3745C3.18393 13.4178 3.23922 13.4394 3.30653 13.4394H14.6935C14.7608 13.4394 14.8161 13.4178 14.8594 13.3745C14.9027 13.3313 14.9243 13.276 14.9243 13.2087V5.56658ZM9.00003 8.26517L14.7938 4.5606H3.20628L9.00003 8.26517ZM3.07574 5.73028V4.93135V4.94921V4.5606V4.95V4.92621V5.73028Z"
        fill={color}
      />
    </svg>
  );
}
