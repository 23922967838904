import React, { useContext, useEffect, useState } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { ACTIVE } from "../../constants";
import CustomerOverviewCard from "../../containers/CustomerOverView/CustomerOverviewCard";
import CustomerOverviewCardTable from "../../containers/CustomerOverView/CustomerOverviewCardTable";
import { useParams } from "react-router-dom";
import OnHoldModal from "../../containers/CustomerOverView/COTabContainers/Actions/OnHoldModal";
import AddPayment from "../../containers/CustomerOverView/COTabContainers/AddPayment";
import OnSkipModal from "../../containers/CustomerOverView/COTabContainers/Actions/OnSkipModal";
import OnReleasePopOver from "../../containers/CustomerOverView/COTabContainers/Actions/OnReleasePopOver";
import { UpdatePromiseStatus } from "../../containers/CustomerOverView/COTabContainers/Actions/ReviewPromiseTable";
import CpWorkflowModal from "../../components/CpWorkflowModal/CpWorkflowModal";
import DairyNotesAlert from "../../containers/CustomerOverView/COTabContainers/DairyNotesAlert";
import { useSelector, useDispatch } from "react-redux";
import DairyNotesAlertCard from "../../containers/CustomerOverView/COTabContainers/Actions/DairyNoteAlertCard";
import {
  H4,
  HeadingXLarge,
  HeadingXSmall,
  LabelMedium,
} from "baseui/typography";
import { NavLink } from "react-router-dom";
import BackArrow from "../../assets/img/svg/BackArrow";
import CustomerPortalLink from "../../containers/CustomerOverView/CustomerPortalLink";
import { ON_HOLD } from "../../constants";
import { TextButton } from "../../components/TextButton";
import {
  addContactModal,
  holdActionModal,
  refetchActions,
  releaseActionModal,
} from "../../redux/actions";
import PauseSvg from "../../assets/img/svg/PauseSvg";
import * as ModalButton from "../../components/TextButton";
import AllActionPopOver from "../../containers/CustomerOverView/COTabContainers/Actions/AllActionPopOver";
import PauseSvgError from "../../assets/img/svg/PauseSvgError";
import { CLOSED } from "ws";
import { invoiceEntity } from "../../redux/invoiceOverview/action";
import { useMutation, useQuery } from "react-query";
import {
  editDairyNote,
  editeCustomerName,
  getCustomerActions,
  postDairyNote,
} from "../../services";
import { WORKERS_SUPPORTED } from "papaparse";
import { IconButton } from "../../components/IconButton";
import { openModal } from "../../redux/customerDairyNote/action";
import DairyNoteForm from "../../components/DairyNoteForm/DairyNoteForm";
import Note from "../../assets/img/svg/Note";
import Letter from "../../assets/img/svg/CustomerTemplate/Letter";
import DocumentSvg from "../../assets/img/svg/DocumentSvg";
import {
  documentDrawerOpen,
  typeToInitial,
  addDocumentForm,
  documentDrawerClose,
  documentDrive,
} from "../../redux/customerOverview/DMSystem/action";
import DMSystem from "../../containers/CustomerOverView/DMSystem";
import CNoteSvg from "../../assets/img/svg/CNoteSvg";
import { createDocument } from "../../services/documentSystem";
import WhatsAppChat from "../../components/WhatsAppChat/WhatsAppChat";
import CheckInDrawer from "../../containers/CustomerOverView/CheckInDrawer/CheckInDrawer";
import { checkInDrawerOpen } from "../../redux/customerOverview/checkIn/action";
import SubscriptionDrawer from "../../containers/CustomerOverView/Subscriptions/SubscriptionDrawer";
import CheckInSvg from "../../assets/img/svg/CheckInSvg";
import {
  addingInvoiceDocumentData,
  documentInvoiceAddingDrawerState,
} from "../../redux/invoiceAdding/dmsInvoiceAdding/action";
import LetterStatusDrawer from "../../containers/CustomerOverView/COTabContainers/Actions/AdhocActionPopup/LetterStatus/LetterStatusDrawer";
import LetterAudit from "../../containers/CustomerOverView/COTabContainers/Actions/AdhocActionPopup/LetterStatus/LetterAudit";
// import letterReducer from "../../redux/customerOverview/letter/reducer";
import letterStatusReducer from "../../redux/customerOverview/letterStatus/reducer";
import {
  CUSTOMER_MANUAL_CALL,
  CUSTOMER_SMS,
  CUSTOMER_MANUAL_EMAIL,
  CUSTOMER_NEXT_ACTION,
  CUSTOMER_DISPUTE,
  CUSTOMER_PAUSE,
  CUSTOMER_WHATSAPP,
  CUSTOMER_PROMISE_REVIEW,
  CUSTOMER_LETTER,
  CUSTOMER_LETTER_STATUS,
  ADD_PAYMENTS,
  ADD_CUSTOMER_NOTES,
  EDIT_CUSTOMER_NOTES,
  DELETE_CUSTOMER_NOTES,
  ADD_DOCUMENTS,
  VIEW_DOWNLOAD_DOCUMENTS,
  CUSTOMER_NAME_EDIT,
  CUSTOMER_FIELD_VISIT,
  REQUEST_INFORMATION,
  CUSTOMER_REQUEST_INFORMATION,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Button } from "baseui/button";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { TextBox } from "../../components/TextBox";
import CloseIcon from "../../assets/img/svg/Close";
import Tick from "../../assets/img/svg/CustomerOverViewIcon/Tick";
import EditPencil from "../../utils/EditPencil";
import { Input } from "baseui/input";
import TickSvg from "../../assets/img/svg/TickSvg";
import CloseSvg from "../../assets/img/svg/CloseSvg";
import ActionRemove from "../../assets/img/svg/ActionRemove";
import {
  alloweSpecialCharacter,
  unallowedSpecialCharacter,
} from "../../utils/regexConfig";
import PaymentCardDrawer from "../../containers/CustomerOverView/COTabContainers/Actions/AdhocActionPopup/PaymentCardDrawer";
import RequestDrawer from "../../containers/Request/RequestDrawer";
import DisputeDrawer from "../../containers/DisputeDrawer/DisputeDrawer";
import RequestInformation from "../../assets/img/svg/RequestInformation";

function CustomerOverview({
  setGetSearchParams,
  getParams,
  setSelectedInvoiceId,
  cusSummaryrefetch,
}) {
  let dispatch = useDispatch();
  const intl = useIntl();

  let {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    space,
  } = useContext(MaxyfiContext);

  const resumePermission =
    space && space?.role_permissions?.includes("customer_resume");

  const nameEditPermission =
    space && space?.role_permissions?.includes(CUSTOMER_NAME_EDIT);

  const { drawerType, drawerState } = useSelector(
    (s) => s.customerDocumentReducer
  );

  const isNotesModalOpen = useSelector(
    (s) => s.customerDairyNoteReducer.isOpen
  );

  const { isLoading, customerDetails, subscriptionsDrawerIsOpen } = useSelector(
    (state) => state.customerOverviewReducer
  );
  const { checkInIsOpen } = useSelector((s) => s.checkInReducer);

  const { isOpen } = useSelector((e) => e.timelineReducer);

  let quickActionEnabel = customerDetails?.is_quick_action;
  const { modal } = useSelector((e) => e.callAction);

  const paymentConfig =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails.is_payment_configured;

  const allActionData = [
    {
      id: "0",
      actionName: "Manual Call",
      type: "call",
      content: "Call Follow-up & capture discussion outcome",
      permissions: CUSTOMER_MANUAL_CALL,
      actionTitle: intl.formatMessage({
        id: "manual_call",
      }),
      contentIntl: intl.formatMessage({
        id: "Call_Follow_up_capture_discussion_outcome",
      }),
    },
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      permissions: CUSTOMER_SMS,
      actionTitle: intl.formatMessage({
        id: "sms",
      }),
      contentIntl: intl.formatMessage({
        id: "SMS_Reminders_with_without_an_actionable_link",
      }),
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      permissions: CUSTOMER_MANUAL_EMAIL,
      actionTitle: intl.formatMessage({
        id: "email",
      }),
      contentIntl: intl.formatMessage({
        id: "Email_follow_up_with_Customer",
      }),
    },
    {
      id: "3",
      actionName: "Next Action",
      type: "next",
      content: "Capture your planned Next action",
      permissions: CUSTOMER_NEXT_ACTION,
      actionTitle: intl.formatMessage({
        id: "next_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Capture_your_planned_Next_action",
      }),
    },
    {
      id: "9",
      actionName: "WhatsApp",
      type: "whatsapp",
      content: "Communicate and connect via WhatsApp",
      permissions: CUSTOMER_WHATSAPP,
      actionTitle: intl.formatMessage({
        id: "whatsapp",
      }),
      contentIntl: intl.formatMessage({
        id: "Communicate_and_connect_via_WhatsApp",
      }),
    },
    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      permissions: CUSTOMER_DISPUTE,
      actionTitle: intl.formatMessage({
        id: "dispute",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_and_Update_Dispute_Status",
      }),
    },
    // {
    //   id: "5",
    //   actionName: "Letter",
    //   type: "letter",
    //   content: "Generate and Send Physical letters",
    // },
    {
      id: "6",
      actionName: "Pause Action",
      type: "hold",
      content: "Pause Actions and Customer",
      permissions: CUSTOMER_PAUSE,
      actionTitle: intl.formatMessage({
        id: "pause_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Pause_Actions_and_Customer",
      }),
    },
    ...(currentDefaultFormatDetails.is_add_payment
      ? [
          {
            id: "7",
            actionName: "Payments",
            type: "payments",
            content: "Capture Payments made by the Customer",
            permissions: ADD_PAYMENTS,
            actionTitle: intl.formatMessage({
              id: "payments",
            }),
            contentIntl: intl.formatMessage({
              id: "Capture_Payments_made_by_the_Customer",
            }),
          },
        ]
      : []),
    {
      id: "8",
      actionName: "Review Promise",
      type: "review",
      content: "Review the promise provided by the Customer",
      permissions: CUSTOMER_PROMISE_REVIEW,
      actionTitle: intl.formatMessage({
        id: "review_promise",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_the_promise_provided_by_the_Customer",
      }),
    },
    {
      id: "10",
      actionName: "Letter",
      type: "letter",
      content: "Proceed your letter Action on your Own",
      permissions: CUSTOMER_LETTER,
      actionTitle: intl.formatMessage({
        id: "letter",
      }),
      contentIntl: intl.formatMessage({
        id: "Proceed_your_letter_Action_on_your_Own",
      }),
    },
    {
      id: "11",
      actionName: "Letter Status",
      type: "letter_status",
      content: "Change your status of updated Letter",
      permissions: CUSTOMER_LETTER_STATUS,
      actionTitle: intl.formatMessage({
        id: "letter_status",
      }),
      contentIntl: intl.formatMessage({
        id: "Change_your_status_of_updated_Letter",
      }),
    },
    {
      id: "10",
      actionName: "Field Visit",
      type: "field_visit",
      content: "Capture Action Based on Field Visit",
      permissions: CUSTOMER_FIELD_VISIT,
      actionTitle: intl.formatMessage({
        id: "field_visit",
      }),
      contentIntl: intl.formatMessage({
        id: "capture_action_based_on_field_visit",
      }),
    },
  ];

  const allWfActionData = [
    {
      id: "0",
      actionName: "Manual Call",
      type: "call",
      content: "Call Follow-up & capture discussion outcome",
      permissions: CUSTOMER_MANUAL_CALL,
      actionTitle: intl.formatMessage({
        id: "manual_call",
      }),
      contentIntl: intl.formatMessage({
        id: "Call_Follow_up_capture_discussion_outcome",
      }),
    },
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      permissions: CUSTOMER_SMS,
      actionTitle: intl.formatMessage({
        id: "sms",
      }),
      contentIntl: intl.formatMessage({
        id: "SMS_Reminders_with_without_an_actionable_link",
      }),
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      permissions: CUSTOMER_MANUAL_EMAIL,
      actionTitle: intl.formatMessage({
        id: "email",
      }),
      contentIntl: intl.formatMessage({
        id: "Email_follow_up_with_Customer",
      }),
    },

    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      permissions: CUSTOMER_DISPUTE,
      actionTitle: intl.formatMessage({
        id: "dispute",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_and_Update_Dispute_Status",
      }),
    },
    // {
    //   id: "5",
    //   actionName: "Letter",
    //   type: "letter",
    //   content: "Generate and Send Physical letters",
    // },
    {
      id: "9",
      actionName: "WhatsApp",
      type: "whatsapp",
      content: "Communicate and connect via WhatsApp",
      permissions: CUSTOMER_WHATSAPP,
      actionTitle: intl.formatMessage({
        id: "whatsapp",
      }),
      contentIntl: intl.formatMessage({
        id: "Communicate_and_connect_via_WhatsApp",
      }),
    },
    {
      id: "6",
      actionName: "Pause Action",
      type: "hold",
      content: "Pause Actions and Customer",
      permissions: CUSTOMER_PAUSE,
      actionTitle: intl.formatMessage({
        id: "pause_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Pause_Actions_and_Customer",
      }),
    },
    ...(currentDefaultFormatDetails.is_add_payment
      ? [
          {
            id: "7",
            actionName: "Payments",
            type: "payments",
            content: "Capture Payments made by the Customer",
            permissions: ADD_PAYMENTS,
            actionTitle: intl.formatMessage({
              id: "payments",
            }),
            contentIntl: intl.formatMessage({
              id: "Capture_Payments_made_by_the_Customer",
            }),
          },
        ]
      : []),
    {
      id: "8",
      actionName: "Review Promise",
      type: "review",
      content: "Review the promise provided by the Customer",
      permissions: CUSTOMER_PROMISE_REVIEW,
      actionTitle: intl.formatMessage({
        id: "review_promise",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_the_promise_provided_by_the_Customer",
      }),
    },
    {
      id: "10",
      actionName: "Letter",
      type: "letter",
      content: "Proceed your letter Action on your Own",
      permissions: CUSTOMER_LETTER,
      actionTitle: intl.formatMessage({
        id: "letter",
      }),
      contentIntl: intl.formatMessage({
        id: "Proceed_your_letter_Action_on_your_Own",
      }),
    },
    {
      id: "11",
      actionName: "Letter Status",
      type: "letter_status",
      content: "Change your status of updated Letter",
      permissions: CUSTOMER_LETTER_STATUS,
      actionTitle: intl.formatMessage({
        id: "letter_status",
      }),
      contentIntl: intl.formatMessage({
        id: "Change_your_status_of_updated_Letter",
      }),
    },
    {
      id: "10",
      actionName: "Field Visit",
      type: "field_visit",
      content: "Capture Action Based on Field Visit",
      permissions: CUSTOMER_FIELD_VISIT,
      actionTitle: intl.formatMessage({
        id: "field_visit",
      }),
      contentIntl: intl.formatMessage({
        id: "capture_action_based_on_field_visit",
      }),
    },
  ];

  const quickActionData = [
    {
      id: "0",
      actionName: "Manual Call",
      type: "call",
      content: "Call Follow-up & capture discussion outcome",
      permissions: CUSTOMER_MANUAL_CALL,
      actionTitle: intl.formatMessage({
        id: "manual_call",
      }),
      contentIntl: intl.formatMessage({
        id: "Call_Follow_up_capture_discussion_outcome",
      }),
    },
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      permissions: CUSTOMER_SMS,
      actionTitle: intl.formatMessage({
        id: "sms",
      }),
      contentIntl: intl.formatMessage({
        id: "SMS_Reminders_with_without_an_actionable_link",
      }),
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      permissions: CUSTOMER_MANUAL_EMAIL,
      actionTitle: intl.formatMessage({
        id: "email",
      }),
      contentIntl: intl.formatMessage({
        id: "Email_follow_up_with_Customer",
      }),
    },
    {
      id: "3",
      actionName: "Next Action",
      type: "next",
      content: "Capture your planned Next action",
      permissions: CUSTOMER_NEXT_ACTION,
      actionTitle: intl.formatMessage({
        id: "next_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Capture_your_planned_Next_action",
      }),
    },
    {
      id: "9",
      actionName: "WhatsApp",
      type: "whatsapp",
      content: "Communicate and connect via WhatsApp",
      permissions: CUSTOMER_WHATSAPP,
      actionTitle: intl.formatMessage({
        id: "whatsapp",
      }),
      contentIntl: intl.formatMessage({
        id: "Communicate_and_connect_via_WhatsApp",
      }),
    },
    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      permissions: CUSTOMER_DISPUTE,
      actionTitle: intl.formatMessage({
        id: "dispute",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_and_Update_Dispute_Status",
      }),
    },
    // {
    //   id: "5",
    //   actionName: "Letter",
    //   type: "letter",
    //   content: "Generate and Send Physical letters",
    // },
    {
      id: "6",
      actionName: "Pause Action",
      type: "hold",
      content: "Pause Actions and Customer",
      permissions: CUSTOMER_PAUSE,
      actionTitle: intl.formatMessage({
        id: "pause_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Pause_Actions_and_Customer",
      }),
    },

    {
      id: "8",
      actionName: "Review Promise",
      type: "review",
      content: "Review the promise provided by the Customer",
      permissions: CUSTOMER_PROMISE_REVIEW,
      actionTitle: intl.formatMessage({
        id: "review_promise",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_the_promise_provided_by_the_Customer",
      }),
    },
    {
      id: "10",
      actionName: "Letter",
      type: "letter",
      content: "Proceed your letter Action on your Own",
      permissions: CUSTOMER_LETTER,
      actionTitle: intl.formatMessage({
        id: "letter",
      }),
      contentIntl: intl.formatMessage({
        id: "Proceed_your_letter_Action_on_your_Own",
      }),
    },
    {
      id: "10",
      actionName: "Field Visit",
      type: "field_visit",
      content: "Capture Action Based on Field Visit",
      permissions: CUSTOMER_FIELD_VISIT,
      actionTitle: intl.formatMessage({
        id: "field_visit",
      }),
      contentIntl: intl.formatMessage({
        id: "capture_action_based_on_field_visit",
      }),
    },
    {
      id: "11",
      actionName: "Letter Status",
      type: "letter_status",
      content: "Change your status of updated Letter",
      permissions: CUSTOMER_LETTER_STATUS,
      actionTitle: intl.formatMessage({
        id: "letter_status",
      }),
      contentIntl: intl.formatMessage({
        id: "Change_your_status_of_updated_Letter",
      }),
    },
    ...(currentDefaultFormatDetails.is_add_payment
      ? [
          {
            id: "7",
            actionName: "Payments",
            type: "payments",
            content: "Capture Payments made by the Customer",
            permissions: ADD_PAYMENTS,
            actionTitle: intl.formatMessage({
              id: "payments",
            }),
            contentIntl: intl.formatMessage({
              id: "Capture_Payments_made_by_the_Customer",
            }),
          },
        ]
      : []),
  ];

  const quickActionWfData = [
    {
      id: "0",
      actionName: "Manual Call",
      type: "call",
      content: "Call Follow-up & capture discussion outcome",
      permissions: CUSTOMER_MANUAL_CALL,
      actionTitle: intl.formatMessage({
        id: "manual_call",
      }),
      contentIntl: intl.formatMessage({
        id: "Call_Follow_up_capture_discussion_outcome",
      }),
    },
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      permissions: CUSTOMER_SMS,
      actionTitle: intl.formatMessage({
        id: "sms",
      }),
      contentIntl: intl.formatMessage({
        id: "SMS_Reminders_with_without_an_actionable_link",
      }),
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      permissions: CUSTOMER_MANUAL_EMAIL,
      actionTitle: intl.formatMessage({
        id: "email",
      }),
      contentIntl: intl.formatMessage({
        id: "Email_follow_up_with_Customer",
      }),
    },

    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      permissions: CUSTOMER_DISPUTE,
      actionTitle: intl.formatMessage({
        id: "dispute",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_and_Update_Dispute_Status",
      }),
    },
    // {
    //   id: "5",
    //   actionName: "Letter",
    //   type: "letter",
    //   content: "Generate and Send Physical letters",
    // },
    {
      id: "6",
      actionName: "Pause Action",
      type: "hold",
      content: "Pause Actions and Customer",
      permissions: CUSTOMER_PAUSE,
      actionTitle: intl.formatMessage({
        id: "pause_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Pause_Actions_and_Customer",
      }),
    },
    {
      id: "9",
      actionName: "WhatsApp",
      type: "whatsapp",
      content: "Communicate and connect via WhatsApp",
      permissions: CUSTOMER_WHATSAPP,
      actionTitle: intl.formatMessage({
        id: "whatsapp",
      }),
      contentIntl: intl.formatMessage({
        id: "Communicate_and_connect_via_WhatsApp",
      }),
    },
    {
      id: "8",
      actionName: "Review Promise",
      type: "review",
      content: "Review the promise provided by the Customer",
      permissions: CUSTOMER_PROMISE_REVIEW,
      actionTitle: intl.formatMessage({
        id: "review_promise",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_the_promise_provided_by_the_Customer",
      }),
    },
    {
      id: "10",
      actionName: "Letter",
      type: "letter",
      content: "Proceed your letter Action on your Own",
      permissions: CUSTOMER_LETTER,
      actionTitle: intl.formatMessage({
        id: "letter",
      }),
      contentIntl: intl.formatMessage({
        id: "Proceed_your_letter_Action_on_your_Own",
      }),
    },
    {
      id: "11",
      actionName: "Letter Status",
      type: "letter_status",
      content: "Change your status of updated Letter",
      permissions: CUSTOMER_LETTER_STATUS,
      actionTitle: intl.formatMessage({
        id: "letter_status",
      }),
      contentIntl: intl.formatMessage({
        id: "Change_your_status_of_updated_Letter",
      }),
    },
    {
      id: "10",
      actionName: "Field Visit",
      type: "field_visit",
      content: "Capture Action Based on Field Visit",
      permissions: CUSTOMER_FIELD_VISIT,
      actionTitle: intl.formatMessage({
        id: "field_visit",
      }),
      contentIntl: intl.formatMessage({
        id: "capture_action_based_on_field_visit",
      }),
    },
    ...(currentDefaultFormatDetails.is_add_payment
      ? [
          {
            id: "7",
            actionName: "Payments",
            type: "payments",
            content: "Capture Payments made by the Customer",
            permissions: ADD_PAYMENTS,
            actionTitle: intl.formatMessage({
              id: "payments",
            }),
            contentIntl: intl.formatMessage({
              id: "Capture_Payments_made_by_the_Customer",
            }),
          },
        ]
      : []),
  ];

  // QUICK ACTION REQUIRED DATA START
  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  const workFlowStatus = useSelector(
    (s) => s.customerOverviewReducer.activeWorkflow?.status
  );

  let isWorkflowActive =
    workFlowStatus && workFlowStatus === ACTIVE ? true : false;

  let isWorkflow = workFlowStatus && workFlowStatus !== CLOSED ? true : false;

  let isWorkflowOnHold =
    workFlowStatus && workFlowStatus === ON_HOLD ? true : false;

  const workflowDatas = useSelector(
    (s) => s.customerOverviewReducer.onLoadCustomerActions
  );

  let filteringActionStatus = workflowDatas.filter(
    (i) => i.action_status === "ACTIVE"
  );
  let currentActioDate =
    filteringActionStatus &&
    filteringActionStatus[0] &&
    filteringActionStatus[0].planned_on;

  let plannedOnDate =
    filteringActionStatus &&
    filteringActionStatus[0] &&
    filteringActionStatus[0].planned_on;

  let isNextAction = filteringActionStatus.length ? true : false;

  let today = moment()?.utc()?.tz(timeZone)?.startOf("d")?.valueOf();

  // let plannedOn = moment
  //   .utc(plannedOnDate)
  //   .tz(timeZone)
  //   .startOf("d")
  //   .format(currentDefaultFormatDetails.date_format);
  //

  let dayDifference = moment
    ?.utc(plannedOnDate)
    ?.tz(timeZone)
    ?.startOf("d")
    ?.diff(today, "d");

  let dayDifferenceValue = dayDifference;
  const smsGateWayIsConfgured = currentDefaultFormatDetails?.is_sms_configured;
  const whatsAppIsConfigured =
    currentDefaultFormatDetails?.is_whatsapp_configured;

  const refetchActionsWf = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  const { letterEditFormData } = useSelector((s) => s.letterStatusReducer);

  const { isError, data, refetch, isFetching } = useQuery(
    [`customer-actions-${getParams}`],
    async () => {
      return await getCustomerActions({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  let wf = data && data.data.doc && data.data.doc.workflow;

  let workflowActionLength =
    data && data.data.doc && data.data.doc?.customer_actions.length;

  useEffect(() => {
    refetch();
  }, [refetchActionsWf]);

  let HoldDatas = data && data.data.doc && data.data.doc.on_hold;

  let holdDateFormate = moment
    ?.utc(HoldDatas?.content?.hold_action_till)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  let noWorkflowActionDate = moment(
    customerDetails && customerDetails.hold_quick_action_till
  )
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  let reason = referenceData["hold_action_reason"];
  let holdReason = reason.filter(
    (i) => i.id === HoldDatas?.content?.hold_reason
  );
  let noWorkflowActionReason = reason.filter(
    (i) => i.id === customerDetails.hold_quick_action_reason
  );

  let workflowActionData = data && data.data.doc && data.data.doc.workflow;

  let findAdhocPauseReson =
    customerDetails && customerDetails.hold_quick_action_reason
      ? reason.find((e) => e.id === customerDetails.hold_quick_action_reason)
      : [];

  const { activeWorkflow } = useSelector((s) => s.customerOverviewReducer);

  let findWorkflowPauseReson =
    activeWorkflow && activeWorkflow.hold_reason
      ? reason.find((e) => e.id === activeWorkflow.hold_reason)
      : [];

  const [isEditName, setIsEditName] = useState(false);
  const [isCustomerName, setIsCustomerName] = useState("");

  useEffect(() => {
    if (customerDetails && customerDetails?.display_name) {
      setIsCustomerName(customerDetails && customerDetails?.display_name);
    }
  }, [getParams, customerDetails?.display_name, isEditName]);

  const editCustomerOverView = useMutation(
    (data) =>
      editeCustomerName({
        data,
        organization: currentOrganization,
        customerId: getParams,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        setIsEditName(false);
        dispatch(refetchActions());
        cusSummaryrefetch();
      },
    }
  );

  const customerName = async (data) => {
    let splCharacter = data.match(alloweSpecialCharacter);
    if (splCharacter) {
      let value = {
        display_name: data,
      };
      await editCustomerOverView.mutateAsync(value);
    } else {
      toast.error("Invalid input - Only Alphabets ' , . - and _ allowed.");
    }
  };

  // QUICK ACTION REQUIRED DATA ENDS
  const [isrequest, setIsRequest] = useState(false);

  return (
    <>
      {/* <div>{id}</div> */}
      <div
        className="content-container --cp__customer__overview"
        style={{
          padding: "0px",
          position: "relative",
        }}
      >
        <div
          className="content-header --cp_overview__head"
          style={{ paddingBottom: "0px" }}
        >
          <div
            className="content-header__title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                // paddingTop: "10px",
                // paddingLeft: "25px",
                display: "flex",
                justifyContent: "space-between",
                flex: "1",
                // paddingRight: "30px",
                position: "relative",
                padding: "0px 10px",
              }}
            >
              {" "}
              <div
                className={
                  customerDetails &&
                  customerDetails.display_name &&
                  customerDetails?.display_name?.length > 25
                    ? "content-header__back --customer_overview_head_modifer"
                    : "content-header__back"
                }
              >
                {isEditName ? (
                  <>
                    <Input
                      onChange={(e) => setIsCustomerName(e.target.value)}
                      onKeyPress={(res) => {
                        if (res.key == "Enter") {
                          customerName(isCustomerName);
                        }
                      }}
                      value={isCustomerName}
                      overrides={{
                        MaskToggleButton: {
                          style: () => ({
                            // backgroundColor: "#fff",
                            background: "#ffffff",
                            cursor: "pointer",
                          }),
                        },
                        Root: {
                          style: ({ $theme, $isFocused, $error }) => ({
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderColor: $isFocused
                              ? $error
                                ? "#FD372A"
                                : "#516BEB"
                              : $error
                              ? "#FD372A"
                              : "#CDCED9",
                            backgroundColor: "#ffffff",
                            paddingRight: "0px",
                            padding: "0px",
                            paddingLeft: "0px",
                          }),
                        },
                        Input: {
                          style: ({ $theme, $isFocused }) => ({
                            backgroundColor: "#ffffff",
                            "::placeholder": {
                              color: $isFocused ? "#cbd5e0" : "transparent",
                              transition: "color 0.4s ease",
                            },
                          }),
                        },

                        EndEnhancer: {
                          style: ({ $theme }) => ({
                            paddingTop: "5px",
                            backgroundColor: "#ffffff",
                            display: "none",
                            width: "",
                          }),
                        },
                        StartEnhancer: {
                          style: ({ $theme }) => ({
                            // outline: `${$theme.colors.warning200} solid`,
                            backgroundColor: "#EEEEEE",
                            display: "none",
                          }),
                        },
                      }}
                      size="mini"
                    />
                    {/* <TextBox
                      onChange={(e) => setIsCustomerName(e.target.value)}
                      value={isCustomerName}
                    /> */}
                    <div className="cp_edit_ic">
                      <div className="tick_cp_icon">
                        <TextButton
                          size={ModalButton.SIZE.mini}
                          kind={ModalButton.KIND.tertiary}
                          onClick={() => customerName(isCustomerName)}
                        >
                          <TickSvg />
                        </TextButton>
                      </div>
                      <div className="close_cp_icon">
                        <TextButton
                          size={ModalButton.SIZE.mini}
                          kind={ModalButton.KIND.tertiary}
                          onClick={() => setIsEditName(false)}
                        >
                          <ActionRemove />
                        </TextButton>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <HeadingXLarge
                      $style={{ fontSize: "25px" }}
                      title={customerDetails && customerDetails.display_name}
                    >
                      {customerDetails.display_name}
                    </HeadingXLarge>
                    {nameEditPermission && (
                      <div className="cp_pencil_icon">
                        <IconButton
                          size={ModalButton.SIZE.mini}
                          kind={ModalButton.KIND.tertiary}
                          onClick={() => setIsEditName(true)}
                        >
                          <EditPencil size={"12px"} />
                        </IconButton>
                      </div>
                    )}
                  </>
                )}

                {isrequest ? (
                  <RequestDrawer
                    active={isrequest}
                    setIsRequest={() => {
                      setIsRequest(false);
                    }}
                    businessUnit={
                      customerDetails && customerDetails.business_unit
                    }
                  />
                ) : (
                  <></>
                )}

                {/* <DisputeDrawer
                  isrequest={isrequest}
                  setIsRequest={setIsRequest}
                /> */}

                <CustomerPortalLink getParams={getParams} />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div className="cp__resume__btn">
                  {workFlowStatus === ON_HOLD || !quickActionEnabel ? (
                    <div
                      className="cp_resume__action-btn"
                      title={
                        resumePermission
                          ? ""
                          : "Resume access unavailable, contact admin"
                      }
                    >
                      <StatefulPopover
                        showArrow
                        overrides={{
                          Arrow: {
                            style: ({ $theme }) => ({
                              backgroundColor: "16164B",
                            }),
                          },
                        }}
                        content={() => (
                          <div
                            style={{
                              backgroundColor: "#16164B",
                              padding: "5px",
                              borderRadius: "5px",
                              maxWidth: "250px",
                              color: "#ffffff",
                            }}
                          >
                            {!quickActionEnabel ? (
                              <div>
                                <HeadingXSmall
                                  $style={{
                                    fontSize: "11px",
                                    fontWeight: "500",
                                    color: "#ffffff",
                                  }}
                                >
                                  <FormattedMessage id="reason">
                                    reason
                                  </FormattedMessage>
                                  :{" "}
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "400",
                                      color: "#ffffff",
                                    }}
                                  >
                                    {findAdhocPauseReson &&
                                    findAdhocPauseReson.label
                                      ? findAdhocPauseReson.label
                                      : "-"}{" "}
                                    {customerDetails.hold_quick_action_till
                                      ? moment
                                          .tz(
                                            customerDetails.hold_quick_action_till,
                                            currentDefaultFormatDetails.time_zone
                                          )
                                          .format(
                                            currentDefaultFormatDetails.date_format
                                          )
                                      : ""}
                                  </span>
                                </HeadingXSmall>
                              </div>
                            ) : workFlowStatus === ON_HOLD ? (
                              <>
                                <HeadingXSmall
                                  $style={{
                                    fontSize: "11px",
                                    fontWeight: "500",
                                    color: "#ffffff",
                                  }}
                                >
                                  Reason:{" "}
                                  <span
                                    style={{
                                      fontSize: "11px",
                                      fontWeight: "400",
                                      color: "#ffffff",
                                    }}
                                  >
                                    {findWorkflowPauseReson &&
                                    findWorkflowPauseReson.label
                                      ? findWorkflowPauseReson.label
                                      : "-"}{" "}
                                    {activeWorkflow.hold_workflow_till
                                      ? moment
                                          .tz(
                                            activeWorkflow.hold_workflow_till,
                                            currentDefaultFormatDetails.time_zone
                                          )
                                          .format(
                                            currentDefaultFormatDetails.date_format
                                          )
                                      : "-"}
                                  </span>
                                </HeadingXSmall>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                        triggerType={TRIGGER_TYPE.hover}
                        returnFocus
                        autoFocus
                      >
                        <Button
                          size={ModalButton.SIZE.mini}
                          $style={{
                            color: "#FD372A",
                            borderRadius: "5px",
                            border: "1px solid #FD372A",
                            background: "none",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            width: "auto",
                            ":hover": {
                              background: "none",
                              border: "1px solid #516BEB",
                              color: "#516BEB",
                            },
                          }}
                          onClick={() => dispatch(releaseActionModal(true))}
                          disabled={!resumePermission}
                        >
                          <div className="resume_and_pause_container">
                            <div className="pause_action_hide">
                              <div className="pause_action_hover">
                                <div
                                  style={{
                                    marginTop: "5px",
                                    marginRight: "5px",
                                  }}
                                >
                                  <PauseSvgError />
                                </div>{" "}
                                <LabelMedium $style={{ color: "#fd372a" }}>
                                  {findAdhocPauseReson &&
                                  findAdhocPauseReson.label
                                    ? findAdhocPauseReson.label
                                    : "-"}{" "}
                                  -
                                  <FormattedMessage id="paused">
                                    paused
                                  </FormattedMessage>
                                </LabelMedium>
                              </div>
                            </div>
                            <div className="resume-hide">
                              <div className="resume_hover">
                                {" "}
                                <div
                                  style={{
                                    marginTop: "5px",
                                    marginRight: "5px",
                                  }}
                                >
                                  <PauseSvgError />
                                </div>{" "}
                                <LabelMedium $style={{ color: "#516beb" }}>
                                  {findAdhocPauseReson &&
                                  findAdhocPauseReson.label
                                    ? findAdhocPauseReson.label
                                    : "-"}{" "}
                                  -
                                  <FormattedMessage id="resume">
                                    resume
                                  </FormattedMessage>
                                </LabelMedium>
                              </div>
                            </div>
                          </div>
                        </Button>
                      </StatefulPopover>
                    </div>
                  ) : (
                    <RBACWrapper role={CUSTOMER_PAUSE} type="PERMISSION">
                      <TextButton
                        size={ModalButton.SIZE.mini}
                        kind={ModalButton.KIND.secondary}
                        style={{ width: "100%" }}
                        type="button"
                        startEnhancer={() => <PauseSvg />}
                        onClick={() => dispatch(holdActionModal(true))}
                      >
                        <FormattedMessage id="pause_action">
                          pause_action
                        </FormattedMessage>
                      </TextButton>
                    </RBACWrapper>
                  )}
                </div>
                <div
                  onClick={() => {
                    dispatch(invoiceEntity("CUSTOMER"));
                  }}
                >
                  <AllActionPopOver
                    isQuickAction={quickActionEnabel}
                    isWorkflowActive={isWorkflowActive}
                    isWorkflow={isWorkflow}
                    workFlowStatus={workFlowStatus}
                    allActionData={!wf ? allActionData : allWfActionData}
                    quickActionData={!wf ? quickActionData : quickActionWfData}
                    dayDifferenceValue={dayDifferenceValue}
                    dayDifference={dayDifference}
                    isNextAction={isNextAction}
                    smsGateWayIsConfgured={smsGateWayIsConfgured}
                    holdDateFormate={
                      !wf ? noWorkflowActionDate : holdDateFormate
                    }
                    holdReason={!wf ? noWorkflowActionReason : holdReason}
                    workflowActionLength={workflowActionLength}
                    workflowActionData={!workflowActionData}
                    whatsAppIsConfigured={whatsAppIsConfigured}
                    paymentConfig={paymentConfig}
                  />
                </div>
                <div>
                  {/* <div> */}
                  <IconButton
                    onClick={() => dispatch(openModal())}
                    kind={ModalButton.KIND.tertiary}
                    size={ModalButton.SIZE.mini}
                  >
                    <CNoteSvg />
                  </IconButton>
                  {/* </div> */}
                  {/* <div> */}
                  {/* <RBACWrapper role={VIEW_DOWNLOAD_DOCUMENTS} type="PERMISSION"> */}
                  <IconButton
                    onClick={() => {
                      dispatch(documentDrawerOpen(true));
                      // dispatch(addDocumentForm());
                      // dispatch(typeToInitial());
                    }}
                    kind={ModalButton.KIND.tertiary}
                    size={ModalButton.SIZE.mini}
                  >
                    <DocumentSvg />
                  </IconButton>
                  <RBACWrapper
                    role={CUSTOMER_REQUEST_INFORMATION}
                    type="PERMISSION"
                  >
                    <IconButton
                      onClick={() => {
                        setIsRequest({
                          active: true,
                          businessUnit:
                            customerDetails && customerDetails.business_unit,
                        });
                      }}
                      kind={ModalButton.KIND.tertiary}
                      size={ModalButton.SIZE.mini}
                    >
                      <RequestInformation />
                    </IconButton>
                  </RBACWrapper>
                  {customerDetails &&
                    customerDetails.is_usage_based_recurring_invoice && (
                      <IconButton
                        onClick={() => {
                          dispatch(checkInDrawerOpen(true));
                        }}
                        kind={ModalButton.KIND.tertiary}
                        size={ModalButton.SIZE.mini}
                      >
                        <CheckInSvg />
                      </IconButton>
                    )}

                  {/* </div> */}
                </div>
              </div>
              {/* {workFlowStatus === ON_HOLD || !quickActionEnabel ? (
                <div className="cp_customer__action__pause">
                  <HeadingXSmall $style={{ color: "#ffffff" }}>
                    Customer Action is Paused
                  </HeadingXSmall>
                </div>
              ) : (
                <></>
              )} */}
            </div>
          </div>
        </div>

        <div className="content-body content-body--customer-overview --cp-change__direction customer-overview_padding">
          <CustomerOverviewCard getParams={getParams} />
          <CustomerOverviewCardTable
            getParams={getParams}
            setSelectedInvoiceId={setSelectedInvoiceId}
          />
          <OnHoldModal getParams={getParams} entity={"customer"} />
          <AddPayment />
          <OnSkipModal getParams={getParams} entity={"customer"} />
          <OnReleasePopOver getParams={getParams} entity={"customer"} />
          <UpdatePromiseStatus />
          <CpWorkflowModal />
        </div>

        {/* <DairyNoteForm
          resourceName="customerId"
          resourceId={getParams}
          entity={"customer"}
          createDiaryNoteService={postDairyNote}
          editDairyNote={editDairyNote}
          addNotePermission={ADD_CUSTOMER_NOTES}
          editNotePermission={EDIT_CUSTOMER_NOTES}
          deleteNotePermission={DELETE_CUSTOMER_NOTES}
        /> */}
        {isNotesModalOpen ? (
          // <DairyNoteForm
          //   resourceName="customerId"
          //   resourceId={getParams}
          //   entity={"customer"}
          //   createDiaryNoteService={postDairyNote}
          //   editDairyNote={editDairyNote}
          // />
          <DairyNoteForm
            resourceName="customerId"
            resourceId={getParams}
            entity={"customer"}
            createDiaryNoteService={postDairyNote}
            editDairyNote={editDairyNote}
            addNotePermission={ADD_CUSTOMER_NOTES}
            editNotePermission={EDIT_CUSTOMER_NOTES}
            deleteNotePermission={DELETE_CUSTOMER_NOTES}
          />
        ) : (
          <></>
        )}
        {drawerState ? (
          <DMSystem
            resourceName="customerId"
            resourceId={getParams}
            documentData={""}
            createDocument={createDocument}
            deleteDocumentMethod={""}
            editDocumentMethod={""}
            entity={"customer"}
            drawerOpen={drawerState}
            documentDrawertype={drawerType}
            addingSelectedDocument={addingInvoiceDocumentData}
            // openingDrawerState={documentInvoiceAddingDrawerState}
            closeDocmentDrawer={documentDrawerClose}
            documentDrive={documentDrive}
          />
        ) : (
          <></>
        )}

        {checkInIsOpen && <CheckInDrawer />}
        {subscriptionsDrawerIsOpen && (
          <SubscriptionDrawer getParams={getParams} />
        )}

        {isOpen && <WhatsAppChat getParams={getParams} />}

        <LetterStatusDrawer getParams={getParams} data={letterEditFormData} />

        <LetterAudit data={letterEditFormData} />
        {modal && <PaymentCardDrawer />}

        {/* {downArrowClicked ? (
          <>
            <div
              style={{
                position: "absolute",
                // zIndex: "1",
                right: "0%",
                height: "85%",
                // height: "12%",
                overflow: "scroll",
              }}
            >
              <DairyNotesAlert />
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                position: "absolute",
                zIndex: "1",
                right: "0%",
                // height: "82%",
                // height: "13%",
                // overflow: "scroll",
                paddingRight: "6px",
              }}
            >
              <DairyNotesAlert />
            </div>
          </>
        )} */}
      </div>
    </>
  );
}

export default CustomerOverview;
