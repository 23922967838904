import React from "react";

const DocRigntSvg = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.11246 16.4974C7.99024 16.3446 7.92531 16.1766 7.91767 15.9933C7.91003 15.8099 7.97496 15.6495 8.11246 15.512L12.1458 11.4787L8.08954 7.42242C7.96732 7.3002 7.91003 7.13596 7.91767 6.92971C7.92531 6.72346 7.99024 6.55923 8.11246 6.437C8.26524 6.28423 8.42947 6.21166 8.60517 6.2193C8.78086 6.22693 8.93746 6.2995 9.07496 6.437L13.6354 10.9974C13.7118 11.0738 13.7652 11.1502 13.7958 11.2266C13.8263 11.303 13.8416 11.387 13.8416 11.4787C13.8416 11.5703 13.8263 11.6544 13.7958 11.7308C13.7652 11.8071 13.7118 11.8835 13.6354 11.9599L9.09787 16.4974C8.96037 16.6349 8.79996 16.6999 8.61662 16.6922C8.43329 16.6846 8.26524 16.6196 8.11246 16.4974V16.4974Z"
        fill="#333860"
      />
    </svg>
  );
};

export default DocRigntSvg;
