function ActionRemove() {
    return (
      <>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.25899 12.826L7.00078 8.0842L11.7426 12.826C11.8976 12.981 12.0829 13.0615 12.2945 13.0615C12.5061 13.0615 12.6915 12.981 12.8465 12.826C13.0015 12.671 13.082 12.4856 13.082 12.274C13.082 12.0624 13.0015 11.8771 12.8465 11.7221L8.1047 6.98027L12.8465 2.23848C13.0015 2.08349 13.082 1.89811 13.082 1.68652C13.082 1.47493 13.0015 1.28955 12.8465 1.13456C12.6915 0.979572 12.5061 0.899023 12.2945 0.899023C12.0829 0.899023 11.8976 0.979572 11.7426 1.13456L7.00078 5.87635L2.25899 1.13456C2.104 0.979572 1.91862 0.899023 1.70703 0.899023C1.49544 0.899023 1.31006 0.979572 1.15507 1.13456C1.00008 1.28955 0.919531 1.47493 0.919531 1.68652C0.919531 1.89811 1.00008 2.08349 1.15507 2.23848L5.89686 6.98027L1.15507 11.7221C1.00008 11.8771 0.919531 12.0624 0.919531 12.274C0.919531 12.4856 1.00008 12.671 1.15507 12.826C1.31006 12.981 1.49544 13.0615 1.70703 13.0615C1.91862 13.0615 2.104 12.981 2.25899 12.826Z" fill="#ADADAD" stroke="#ADADAD" stroke-width="0.2"/>
</svg>

      </>
    );
  }
  
  export default ActionRemove;