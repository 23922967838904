import React from "react";

const PromiseToPayCall = ({ color = "#516BEB", width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5369 11.8292C17.5285 11.4638 16.4098 11.545 15.464 12.0515L13.922 12.7451C13.7975 12.0349 13.252 11.5013 12.5788 11.4808C12.5749 11.4807 10.2669 11.4546 10.2669 11.4546C8.41961 10.9479 7.2193 11.599 6.53457 12.2407C6.39454 12.3719 6.27138 12.5071 6.163 12.6403C5.97823 12.4413 5.65207 12.3882 5.41423 12.5189L3.31919 13.6706C3.04746 13.8199 2.92923 14.1477 3.04319 14.4357L5.09065 19.6116C5.22519 19.9517 5.63865 20.102 5.96127 19.9246L8.0563 18.773C8.25269 18.665 8.3683 18.4639 8.37465 18.2519H11.5015C12.0115 18.2519 12.5151 18.118 12.9577 17.8645C12.9577 17.8645 18.8384 14.4911 18.8716 14.461C19.6952 13.7118 19.7206 12.2581 18.5369 11.8292C17.5285 11.4638 19.0091 12.0003 18.5369 11.8292V11.8292ZM5.98 18.5092L4.38908 14.4875L5.39538 13.9344L6.9863 17.9561L5.98 18.5092ZM18.0847 13.5094L12.3441 16.7968C12.088 16.9435 11.7965 17.0211 11.5014 17.0211H7.94188L6.74673 13.9998C6.84911 13.7912 7.05077 13.445 7.37827 13.1381C8.049 12.5096 8.93307 12.3487 10.0061 12.6601C10.0598 12.6756 10.1153 12.6838 10.1711 12.6845L12.5436 12.7112C12.6042 12.7164 12.716 12.8511 12.716 13.0552C12.716 13.2654 12.6005 13.3994 12.5401 13.3994H10.2091V14.6302H12.5401C12.8289 14.6302 13.0976 14.5326 13.3213 14.3652L15.9906 13.1646C16.0047 13.1582 16.0186 13.1513 16.0322 13.1439C16.6725 12.7957 17.4322 12.7382 18.1163 12.9862C18.3871 13.0843 18.1915 13.401 18.0847 13.5094V13.5094Z"
        fill={color}
      />
      <path
        d="M14.3807 10.8686C11.9359 10.8686 9.94678 8.87935 9.94678 6.43427C9.94678 3.98923 11.9358 2 14.3807 2C16.8257 2 18.8147 3.98923 18.8147 6.43427C18.8147 8.87935 16.8256 10.8686 14.3807 10.8686ZM14.3807 3.23085C12.6156 3.23085 11.1796 4.66789 11.1796 6.43427C11.1796 8.20065 12.6157 9.63769 14.3807 9.63769C16.1459 9.63769 17.5819 8.20062 17.5819 6.43427C17.5819 4.66792 16.1459 3.23085 14.3807 3.23085Z"
        fill={color}
      />
      <path
        d="M14.6669 5.92832C14.1767 5.72701 14.1388 5.64051 14.1388 5.54024C14.1388 5.4762 14.1706 5.32732 14.465 5.32732C14.7343 5.32732 15.07 5.47851 15.3066 5.63432L15.6359 4.77124C15.4008 4.62159 15.1151 4.49301 14.848 4.45189V3.89062H13.9701V4.50651C13.4486 4.67089 13.1272 5.08782 13.1272 5.60682C13.1272 6.2942 13.6771 6.60108 14.2143 6.81028C14.6434 6.98293 14.6794 7.11508 14.6794 7.22543C14.6794 7.39516 14.523 7.50482 14.2809 7.50482C13.9652 7.50482 13.5982 7.32274 13.3412 7.12351L13.0244 8.00043C13.2864 8.20358 13.5897 8.33766 13.931 8.3877V8.96928H14.8144V8.3362C15.3522 8.16493 15.6965 7.71935 15.6965 7.18658C15.6965 6.44082 15.1463 6.12243 14.6669 5.92832V5.92832Z"
        fill={color}
      />
    </svg>
  );
};

export default PromiseToPayCall;
