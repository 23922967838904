import React, { useContext } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _, { values } from "lodash";

function AlphaNumeric(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  value = _.get(row.original, accessor, "");

  const [visible, setVisible] = React.useState(false);
  let referenceData = useContext(MaxyfiContext);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ minWidth: "auto", maxWidth: "200px" }}>{value}</div>
      }
    >
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          // margin: "5px",
          lineHeight: "18px",
          color: color ? color : "#333860",
        }}
      >
        <div
          className="elip"
          ref={ref}
          style={{ color: `${!suffix ? "" : "#757575"}`, lineHeight: "18px" }}
        >
          {value && value.toString().length ? (
            <>
              {value} {!suffix ? "" : suffix}
            </>
          ) : (
            "-"
          )}
        </div>
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: alignment,
        lineHeight: "18px",
        // margin: "5px",
        color: color ? color : "#333860",
      }}
      style={{ color: `${!suffix ? "" : "#757575"}` }}
    >
      {(value && value.toString().length) || value == 0 ? (
        <>
          {value} {!suffix ? "" : suffix}
        </>
      ) : (
        "-"
      )}
    </Paragraph2>
  );
}
export default AlphaNumeric;
