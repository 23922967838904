import React from "react";

const ValidWhatsappSvg = ({
  width = "24",
  height = "24",
  color = "#FD372A",
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6688 6.33638C16.1648 4.83054 14.1645 4.00088 12.0335 4C7.64233 4 4.06859 7.57364 4.06682 11.966C4.06623 13.37 4.43303 14.7407 5.13021 15.9488L4 20.0769L8.22324 18.9691C9.38691 19.6039 10.697 19.9384 12.0302 19.9388H12.0336C16.4242 19.9388 19.9983 16.3648 20 11.9723C20.0009 9.84359 19.173 7.84212 17.6688 6.33638ZM12.0335 18.5934H12.0307C10.8426 18.5929 9.67736 18.2736 8.66058 17.6704L8.4189 17.5268L5.91277 18.1843L6.58169 15.7408L6.4242 15.4903C5.76136 14.4361 5.41134 13.2175 5.41193 11.9664C5.41331 8.31567 8.38377 5.3455 12.0361 5.3455C13.8047 5.34609 15.4673 6.03572 16.7174 7.28731C17.9675 8.53891 18.6556 10.2025 18.655 11.9718C18.6534 15.6229 15.6832 18.5934 12.0335 18.5934ZM15.6655 13.6342C15.4665 13.5345 14.4878 13.0531 14.3053 12.9866C14.123 12.9201 13.9901 12.8871 13.8575 13.0863C13.7248 13.2854 13.3433 13.7339 13.2272 13.8666C13.111 13.9995 12.995 14.0162 12.7959 13.9165C12.5968 13.8169 11.9555 13.6066 11.1951 12.9285C10.6034 12.4006 10.2039 11.7488 10.0877 11.5496C9.97174 11.3502 10.0867 11.2529 10.1751 11.1434C10.3905 10.8758 10.6063 10.5952 10.6727 10.4625C10.7391 10.3296 10.7058 10.2133 10.656 10.1137C10.6063 10.0141 10.2082 9.03434 10.0424 8.63566C9.88068 8.24767 9.71671 8.30007 9.59445 8.29398C9.47846 8.2882 9.3457 8.28702 9.21293 8.28702C9.08027 8.28702 8.86459 8.33677 8.68207 8.53616C8.49966 8.73545 7.98548 9.21696 7.98548 10.1967C7.98548 11.1765 8.69875 12.123 8.79825 12.2559C8.89775 12.3888 10.2019 14.3994 12.1987 15.2615C12.6736 15.4668 13.0444 15.5891 13.3335 15.6809C13.8104 15.8324 14.2442 15.811 14.5873 15.7598C14.9698 15.7026 15.7649 15.2782 15.9309 14.8133C16.0968 14.3482 16.0968 13.9498 16.0469 13.8666C15.9973 13.7836 15.8645 13.7339 15.6655 13.6342Z"
        fill={color}
      />
    </svg>
  );
};

export default ValidWhatsappSvg;
