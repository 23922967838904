import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useMutation, useQuery } from "react-query";
import { HeadingXLarge, LabelLarge, ParagraphLarge } from "baseui/typography";

import { TextButton, SIZE, KIND } from "../../../components/TextButton";
import {
  isOpenModal,
  refetchTimeline,
  setDrawerState,
} from "../../../redux/actions";
import { SelectBox } from "../../../components/SelectBox";
import WhatsApp from "../../../assets/img/svg/Template/WhatsApp";
import getSelectValues from "../../../utils/getSelectValues";
import {
  executeAdhocCustomerAction,
  executeCustomerAction,
  getCommunicationTemplateFinalContent,
  getCommunicationTemplateList,
  getCustomerContactDetail,
} from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  COMMUNICATION_TEMPLATE,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW_,
} from "../../../constants";
import { refetchInvoiceActions } from "../../../redux/invoiceOverview/action";
import ActionHeader from "../Actions/ActionHeader";
import { useMemo } from "react";
import _ from "lodash";
import { useParams } from "react-router";
import queryClient from "../../../providers/queryClient";

const WhatsAppAction = ({ id, entity, tags, contact, executeAction }) => {
  let dispatch = useDispatch();
  const intl = useIntl();
  const [preview, setPreview] = useState([]);
  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const drawerData = useSelector((state) => state.customerOverviewDrawer);

  const { referenceData, currentOrganization, userInfo } =
    useContext(MaxyfiContext);

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-ADHOC-${currentOrganization}`,
      {
        filters: {
          disable: false,
          type: "whatsapp",
          master_tag: "CUSTOMER",
          tags: ["PROMISE", "CALL_BACK", "PAYMENT_PLAN", "FIELD_VISIT"],
        },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  const executeActionMutation = useMutation(
    (formValues) =>
      !drawerData?.data || !drawerData?.data?.isWorkflow
        ? executeAction({
            ...formValues,
            id: id,
            organization: currentOrganization,
            entity: entity,
          })
        : executeCustomerAction({
            ...formValues,
            id: id,
            organization: currentOrganization,
            entity: entity,
          }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toast.success(data.data.message);
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${id}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`${CO_TIMELINE_}${id}`],
        });
        closeDrawer();
        reset();
      },
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let template_id = watch("template_id");

  const onSubmit = async (data) => {
    let { value, button_type, ...rest } = preview;

    let values = {
      message_type: "template",
      template_id: getSelectValues(data.template_id),
      recipients: [
        {
          id: data?.contact?.[0]?.id,
          recipient_id: data?.contact?.[0]?.recipient_id,
        },
      ],
      action_type: "whatsapp",
      content: value,
      ...rest,
    };
    await executeActionMutation.mutateAsync(values);
  };

  useEffect(() => {
    const getTemplateContent = async () => {
      let templateContent = await getCommunicationTemplateFinalContent({
        id: id,
        organization: currentOrganization,
        templateId: template_id[0].id,
        entity: entity,
      });

      if (templateContent.data && templateContent.data.doc) {
        let { value, subject } = templateContent.data.doc;

        setPreview(templateContent.data.doc);
      }
    };
    if (template_id && template_id[0] && template_id[0].id) {
      getTemplateContent();
    }
  }, [template_id]);

  const contacts = useMemo(() => {
    let hasPrimaryContact = false;
    const contacts =
      contact &&
      contact?.reduce((prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = [...phone, ...(landline ? landline : [])]
          .map(({ status, call_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              call_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.call_status === "VALID" &&
              ((!hasPrimaryContact && e.designation === "primary_contact") ||
                (hasPrimaryContact && e.primary_invoices.includes(id)))
          );

        return [...prev, ...nestedContacts];
      }, []);

    if (drawerData && drawerData.data && drawerData.data.isWorkflow) {
      if (
        drawerData &&
        drawerData.data.recipientTypes &&
        Array.isArray(drawerData.data.recipientTypes)
      ) {
        const selectedContacts = contacts.filter((e) =>
          drawerData.data.recipientTypes.includes(e.designation)
        );
        setValue("contact", selectedContacts);
      }
      setPreview(
        drawerData && drawerData?.data && drawerData?.data?.whatsappTemCon
      );
    } else {
      const selectedContacts = contacts.filter((e) =>
        ["primary_contact"].includes(e.designation)
      );
      setValue("contact", selectedContacts);
    }

    return contacts;
  }, [id, contact]);

  return (
    <>
      <form
        style={{
          gridColumn: "2/5",
          width: "500px",
          height: "100%",
          overflow: "hidden",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ActionHeader name="WhatsApp" icon="whatsapp" />
        {!drawerData?.data || !drawerData?.data?.isWorkflow ? (
          <Controller
            name="template_id"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.default}
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "template",
                })}
                placeholder={intl.formatMessage({
                  id: "template",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={
                  data &&
                  data.data.docs.map((i) => {
                    return { label: i.name, id: i._id };
                  })
                }
              />
            )}
          />
        ) : (
          <></>
        )}

        <Controller
          name="contact"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size={SIZE.default}
              {...field}
              name={field.name}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "primary_contact",
              })}
              placeholder={intl.formatMessage({
                id: "primary_contact",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={contacts}
            />
          )}
        />

        {preview.value && (
          <div
            style={{
              border: "1px solid #ceced9",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <LabelLarge $style={{ marginBottom: "5px" }}>
              {preview.subject ? preview.subject : ""}
            </LabelLarge>

            <ParagraphLarge $style={{ marginBottom: "5px" }}>
              {preview.value ? preview.value : ""}
            </ParagraphLarge>

            {preview?.button_type == "call_to_action" ? (
              <>
                <ParagraphLarge
                  $style={{
                    color: "#516beb",
                    textDecoration: "underline",
                  }}
                >
                  {preview?.buttons?.[1]?.url}{" "}
                </ParagraphLarge>
                <ParagraphLarge $style={{ color: "#516beb" }}>
                  {preview?.buttons?.[0]?.phone_number?.code && "+"}
                  {preview?.buttons?.[0]?.phone_number?.code}{" "}
                  {preview?.buttons?.[0]?.phone_number?.value}
                </ParagraphLarge>
              </>
            ) : preview?.button_type == "quick_reply" ? (
              preview?.buttons?.map((qic) => (
                <ParagraphLarge $style={{ color: "#516beb" }}>
                  {qic.text}{" "}
                </ParagraphLarge>
              ))
            ) : (
              ""
            )}
          </div>
        )}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ width: "125px", height: "36px" }}>
            <TextButton
              size={"mini"}
              kind={KIND.tertiary}
              fullWidth
              type="button"
              onClick={() => {
                closeDrawer();
              }}
            >
              Cancel
            </TextButton>
          </div>

          <div style={{ width: "75px", height: "36px" }}>
            <TextButton
              size={"mini"}
              fullWidth
              type="submit"
              isLoading={executeActionMutation.isLoading}
              disabled={executeActionMutation.isLoading}
            >
              Send
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

const WhatsAppDrawer = () => {
  const { customerId } = useParams();
  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  return (
    <WhatsAppAction
      contact={_.get(customer, "data.doc.contacts")}
      entity={"customer"}
      id={customerId}
      executeAction={executeAdhocCustomerAction}
      tags={"CUSTOMER"}
    />
  );
};

export default WhatsAppDrawer;
