export const ADDING_SELECT = "ADDING_SELECT";
export const SELECTED_VALUE = "SELECT_VALUE";

export const addingSelectFilter = (data) => {
  return {
    type: ADDING_SELECT,
    payload: data,
  };
};
export const selectedValue = (select) => {
  return {
    type: SELECTED_VALUE,
    payload: select,
  };
};
