// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST_BS = process.env.REACT_APP_HOST;
const HOST = process.env.REACT_APP_HOST_TEMPLATE;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const createCommunicationTemplate = async ({
  organization = null,
  clientCode,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}templates/${organization}`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};

export const copyCommunicationTemplate = async ({
  organization = null,
  clientCode,
  templateId,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}templates/${organization}/copy/${templateId}`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};

export const getCommunicationTemplateList = async ({
  organization = null,
  clientCode = [],
  page = 1,
  page_size = 100,
  sort = "name",
  filters,
}) => {
  return await axiosWithAuth.get(`${HOST}templates/${organization}`, {
    headers: {
      appCode: APP_CODE,
    },
    params: {
      clientCode,
      page,
      page_size,
      sort,
      ...filters,
    },
  });
};

export const getCommunicationTemplate = async ({
  templateId,
  clientCode,
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}templates/${organization}/${templateId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};

export const getCommunicationTemplateFinalContent = async ({
  templateId,
  id,
  entity,
  organization = null,
  paymentId = null,
  disputeId = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST_BS}${organization}/${entity}/${id}/adhoc-action/${templateId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        ...(paymentId ? { paymentId } : {}),
        ...(disputeId ? { disputeId } : {}),
      },
    }
  );
};

export const updateCommunicationTemplate = async ({
  organization = null,
  templateId,
  clientCode,
  data,
}) => {
  data.status = "published";
  return await axiosWithAuth.put(
    `${HOST}templates/${organization}/${templateId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};
export const draftCommunicationTemplate = async ({
  organization = null,
  templateId,
  clientCode,
  data,
}) => {
  data.status = "draft";
  return await axiosWithAuth.put(
    `${HOST}templates/${organization}/${templateId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};

export const deleteCommunicationTemplate = async ({
  templateId,
  organization = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}templates/${organization}/${templateId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const refetchCommunicationTemplate = async ({
  templateId,
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}templates/${organization}/${templateId}/refetch-whatsapp-status`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getTemplateFormatters = async ({
  organization = null,
  clientCode,
}) => {
  return await axiosWithAuth.get(
    `${HOST}templates/${organization}/formatters`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};

export const getTemplateVariables = async ({
  organization,
  clientCode,
  ...rest
}) => {
  //Need to change route to fetch template variables

  return await axiosWithAuth.get(
    `${HOST_BS}organization/${organization}/template-variables`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
        ...rest,
      },
    }
  );
};

export const disabledCommunicationTemplateList = async ({
  isDisabled,
  templateId,
  organization = null,
  clientCode = [],
}) => {
  return axiosWithAuth.patch(
    `${HOST}templates/${organization}/${templateId}`,
    {
      disable: isDisabled,
    },
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};
