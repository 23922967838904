import React from "react";

const Reply = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5629 17.4167C18.3643 17.4167 18.2 17.3517 18.0702 17.2219C17.9403 17.092 17.8754 16.9278 17.8754 16.7292V13.4292C17.8754 12.6042 17.5851 11.9014 17.0046 11.3208C16.424 10.7403 15.7212 10.45 14.8962 10.45H5.36289L8.43372 13.5208C8.55595 13.6431 8.61706 13.7958 8.61706 13.9792C8.61706 14.1625 8.54831 14.3229 8.41081 14.4604C8.27331 14.5979 8.11289 14.6667 7.92956 14.6667C7.74622 14.6667 7.58581 14.5979 7.44831 14.4604L3.23164 10.2438C3.15525 10.1674 3.10178 10.091 3.07122 10.0146C3.04067 9.93819 3.02539 9.85417 3.02539 9.7625C3.02539 9.67083 3.04067 9.58681 3.07122 9.51042C3.10178 9.43403 3.15525 9.35764 3.23164 9.28125L7.47122 5.04167C7.59345 4.91944 7.74622 4.85833 7.92956 4.85833C8.11289 4.85833 8.27331 4.92708 8.41081 5.06458C8.54831 5.20208 8.61706 5.3625 8.61706 5.54583C8.61706 5.72917 8.54831 5.88958 8.41081 6.02708L5.36289 9.075H14.8962C16.0879 9.075 17.1115 9.49896 17.9671 10.3469C18.8226 11.1948 19.2504 12.2222 19.2504 13.4292V16.7292C19.2504 16.9278 19.1855 17.092 19.0556 17.2219C18.9257 17.3517 18.7615 17.4167 18.5629 17.4167Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default Reply;
