import React from "react";

const Files = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25 13.4H13.75V14.6H9.25V13.4ZM9.25 11H13.75V12.2H9.25V11ZM12.625 5H8.125C7.50625 5 7 5.54 7 6.2V15.8C7 16.46 7.50062 17 8.11937 17H14.875C15.4938 17 16 16.46 16 15.8V8.6L12.625 5ZM14.875 15.8H8.125V6.2H12.0625V9.2H14.875V15.8Z"
        fill="#333860"
      />
    </svg>
  );
};

export default Files;
