import {
  GET_INVOICE_CARD_DATA,
  INVOICE_NOTE_ALERT,
  REFETCH_INVOICE_ACTIONS,
  INVOICE_PAYMENT_ACTIONS,
  INITIAL_INVOICE_STATE,
  INVOICE_CUSTOMER_ID,
  INVOICE_ENTITY,
  INVOICE_TAB_MOTION,
  ACTIVE_INVOICE_DATA,
  SMS_GATEWAY_DATA,
  INVOICE_ACTION_DATAS,
  INVOICE_WORKFLOW_ACTION_DATAS,
  REFETCH_INV_TIMELINE,
} from "./action";

const initialInvoiceDetails = {
  invoice_total_amount: {
    value: 0,
    currency: "",
  },
  not_paid_amount: {
    value: 0,
    currency: "",
  },
  invoice_due_date: 0,
  invoice_generation_date: 0,
  status: "",
  invoice_number: 0,
  customer_name: "",
};

const initialState = {
  noteAlert: false,
  refetchActions: 0,
  invoiceActions: [],
  invoiceDetails: {
    ...initialInvoiceDetails,
  },

  onLoadInvoiceActions: [],
  isLoading: true,
  noteAlert: false,
  invoicePaymentform: false,
  inv_cus_id: null,
  invoice_tab_motion: 0,
  invoice_entity: "",
  activeIvoiceWorkflow: {},
  smsGateway: {},
  invoiceWorkflow: [],
  refetchTimeline: 0,
};

const invoiceOverviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INVOICE_WORKFLOW_ACTION_DATAS: {
      return {
        ...state,
        invoiceWorkflow: payload,
      };
    }
    case INVOICE_ACTION_DATAS: {
      return {
        ...state,
        onLoadInvoiceActions: payload,
      };
    }

    case SMS_GATEWAY_DATA: {
      return {
        ...state,
        smsGateway: payload,
      };
    }
    case ACTIVE_INVOICE_DATA: {
      return {
        ...state,
        activeIvoiceWorkflow: payload,
      };
    }

    case INVOICE_CUSTOMER_ID: {
      return {
        ...state,
        inv_cus_id: payload,
      };
    }
    case INVOICE_ENTITY: {
      return {
        ...state,
        invoice_entity: payload,
      };
    }
    case INVOICE_TAB_MOTION: {
      return {
        ...state,
        invoice_tab_motion: payload,
      };
    }
    case INITIAL_INVOICE_STATE:
      return {
        ...state,
        ...initialState,
      };
    case INVOICE_PAYMENT_ACTIONS:
      return {
        ...state,
        invoicePaymentform: payload,
      };
    case GET_INVOICE_CARD_DATA:
      const { ...invoiceDetails } = payload;
      return {
        ...state,
        invoiceDetails,
        isLoading: false,
        noteAlert:
          invoiceDetails.alert_notes.length <= 1 && state.noteAlert
            ? false
            : state.noteAlert,
      };
    case INVOICE_NOTE_ALERT: {
      return {
        ...state,
        noteAlert: payload,
      };
    }

    case REFETCH_INVOICE_ACTIONS: {
      const { refetchActions } = state;
      return {
        ...state,
        refetchActions: refetchActions + 1,
      };
    }

    case REFETCH_INV_TIMELINE: {
      return {
        ...state,
        refetchTimeline: state.refetchTimeline + 1,
      };
    }

    default:
      return state;
  }
};

export default invoiceOverviewReducer;
