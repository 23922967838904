import React, { useState } from "react";
import { FormControl } from "baseui/form-control";
import { useStyletron } from "baseui";
import { Input, StyledInput } from "baseui/input";
import { Tag, VARIANT as TAG_VARIANT } from "baseui/tag";
import { useSelector } from "react-redux";

const InputReplacement = React.forwardRef(
  ({ tags, removeTag, ...restProps }, ref) => {
    const [css] = useStyletron();
    // const disableContent = useSelector((s) => s.createWorkFlowReducer.view);

    return (
      <div
        className={css({
          flex: "1 1 0%",
          flexWrap: "wrap",
          display: "flex",
          alignItems: "center",
        })}
      >
        {tags &&
          tags.map((tag, index) => (
            <Tag
              // disabled={disableContent}
              variant={TAG_VARIANT.solid}
              onActionClick={() => removeTag(tag)}
              s
              key={index}
            >
              {tag}
            </Tag>
          ))}
        <StyledInput ref={ref} {...restProps} />
      </div>
    );
  }
);

export const TextBoxTag = (props) => {
  const {
    error = "",
    children,
    values = "",
    label = "",
    name,
    validation = false,
    validationFunc = () => {},
    setError = () => {},
    clearErrors = () => {},
    caption = "",
  } = props;

  const [value, setValue] = React.useState("");

  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <FormControl
      error={error}
      caption={() => `${caption ? caption : ""}`}
      overrides={{
        Caption: {
          style: ({ $theme }) => ({
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "5px",
            textAlign: "left",
          }),
        },
      }}
    >
      <div className="mx-input">
        <label
          htmlFor={props.name}
          className={`mx-input__label ${
            isFocused ? "mx-input__label--active text-primary" : ""
          } ${
            !isFocused && props.value?.length > 0
              ? "mx-input__label--active"
              : ""
          } ${error ? "text-error" : ""}`}
        >
          {label}
        </label>
        <Input
          {...props}
          id={props.name}
          value={value}
          autoComplete="off"
          onChange={(e) => setValue(e.target.value)}
          overrides={{
            Root: {
              style: ({ $theme, $isFocused, $error }) => ({
                borderRadius: "5px",
                borderWidth: "1px",
                borderColor: $isFocused
                  ? $error
                    ? "#FD372A"
                    : "#516BEB"
                  : $error
                  ? "#FD372A"
                  : "#CDCED9",
                backgroundColor: "#ffffff",
              }),
            },
            InputContainer: {
              style: () => ({
                backgroundColor: "#fff",
              }),
            },
            Input: {
              component: InputReplacement,
              props: {
                tags: props.value,
                removeTag: (tag) => {
                  let filteredTag = props.value.filter((t) => t !== tag);
                  props.onChange(filteredTag);
                },
                onKeyDown: (event) => {
                  switch (event.keyCode) {
                    // Enter
                    case 13: {
                      if (!value) return;
                      if (validation && !validationFunc(value)) {
                        setError();
                        return;
                      }
                      clearErrors();
                      props.onChange([...props.value, value]);
                      setValue("");
                      return;
                    }
                    // Backspace
                    case 8: {
                      if (value || !props.value.length) {
                        return;
                      } else {
                        props.onChange(
                          props.value.slice(0, props.value.length - 1)
                        );
                        return;
                      }
                    }
                  }
                },
              },
              style: ({ $theme, $isFocused }) => ({
                width: "auto",
                flexGrow: 1,
                backgroundColor: "#ffffff",
                "::placeholder": {
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                },
              }),
            },
          }}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
            onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
            onBlur();
          }}
        >
          {children}
        </Input>
      </div>
    </FormControl>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
