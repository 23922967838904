import React from "react";
import CellRefInputBox from "../../CellRefInputBox/CellRefInputBox";
import { useDispatch, useSelector } from "react-redux";
import { updateContactIndex } from "../../../redux/adhocUploadData/action";
import _ from "lodash";

function GroupContact(props) {
  const dispatch = useDispatch();

  let { value, column, alignment, accessor, row, ...rest } = props;

  value = _.get(row.original, accessor, "");

  const { systemFields } = useSelector((e) => e.adhocDataReducer);

  const systemFieldConfig = systemFields.find((systemField) => {
    return systemField.id == props.row.original.system_field;
  });

  return (
    <>
      <CellRefInputBox
        value={value ? value : ""}
        onChange={(e) => {
          dispatch(
            updateContactIndex({
              index: props.row.index,
              contactIndex: e.target.value,
              module: systemFieldConfig?.module,
            })
          );
        }}
        clearOnEscape
        disabled={
          systemFieldConfig && systemFieldConfig.module === "CON" ? false : true
        }
        type="number"
        mockData={systemFieldConfig}
        min="0"
        onKeyDown={(e) => {
          if (e.key == "ArrowUp" || e.key == "ArrowDown") {
            e.preventDefault();
          }
        }}
        onFocus={(e) => {
          if (e && e?.target) {
            e.target.addEventListener(
              "wheel",
              function (e) {
                e.preventDefault();
              },
              { passive: false }
            );
          }
        }}
      />
    </>
  );
}

export default GroupContact;
