export const OPEN_SWITCH_ORGANIZATION_MODAL = "OPEN_SWITCH_ORGANIZATION_MODAL";
export const CLOSE_SWITCH_ORGANIZATION_MODAL =
  "CLOSE_SWITCH_ORGANIZATION_MODAL";

export const isModalOpen = () => {
  return { type: OPEN_SWITCH_ORGANIZATION_MODAL };
};

export const isModalClose = () => {
  return { type: CLOSE_SWITCH_ORGANIZATION_MODAL };
};
