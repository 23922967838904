// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import { useMutation, useQueryClient } from "react-query";
import { CUSTOMER_OVERVIEW_ } from "../constants";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const defaultContactData = async ({
  customerId,
  data,
  clientCode,
  contactId,
  organization = null,
}) => {
  return axiosWithAuth.patch(
    `${HOST}${organization}/customer/${customerId}/contact/${contactId}`,
    data,
    { headers: { appCode: APP_CODE }, params: { clientCode } }
  );
};

export const createContactDetailsCustomerOverview = async ({
  customerId,
  organization = null,
  ...contactData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/contact`,
    contactData
  );
};

export const deleteContactDetailsCustomerOverview = async ({
  clientCode,
  contactId,
  customerId,
  organization = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/contact/${contactId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};

export const updateContactDetailsCustomerOverview = async ({
  clientCode,
  contactId,
  data,
  customerId,
  organization = null,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/contact/${contactId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        clientCode,
      },
    }
  );
};

// customer overview V2 services
export const createCustomerContactFn = async ({
  customerId,
  organization = null,
  values,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/customer/${customerId}/contact`,
    values
  );
};

export const useCustomerContact = (props) => {
  const client = useQueryClient();
  return useMutation(createCustomerContactFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const editCustomerContactFn = async ({
  clientCode,
  contactId,
  values,
  customerId,
  organization = null,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/customer/${customerId}/contact/${contactId}`,
    values
  );
};

export const useUpdateCustomerContact = (props) => {
  const client = useQueryClient();
  return useMutation(editCustomerContactFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const deleteCustomerContactFn = async ({
  contactId,
  customerId,
  organization = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/customer/${customerId}/contact/${contactId}`
  );
};

export const useDeleteCustomerContact = (props) => {
  const client = useQueryClient();
  return useMutation(deleteCustomerContactFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};

export const editCustomFieldsFn = async ({
  values,
  customerId,
  organization = null,
  entity = "",
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/${entity}/${customerId}/custom-fields`,
    values
  );
};

export const useUpdateCustomFields = (props) => {
  const client = useQueryClient();
  return useMutation(editCustomFieldsFn, {
    onSuccess: () => {
      client.invalidateQueries({
        predicate: (query) => {
          if (
            query &&
            query.queryKey[0] &&
            query.queryKey[0].startsWith(`${CUSTOMER_OVERVIEW_}`)
          ) {
            return true;
          }
          return false;
        },
      });
    },
    onError: (err) => {},
  });
};
