import React from "react";
import CardLayout from "../Card";
import { StyledBody, StyledAction } from "baseui/card";
import ReactCircle from "../ReactCircle/ReactCircle";
import SuccessArrow from "../../../assets/img/svg/SuccessArrow";
import SuccessSheild from "../../../assets/img/svg/SuccessSheild";
import FaildSheild from "../../../assets/img/svg/FaildSheild";
import { Paragraph2 } from "baseui/typography";

const PromiseSuccess = () => {
  return (
    <div>
      <CardLayout>
        <div>
          <Paragraph2
            style={{
              fontSize: "10px",
              lineHeight: "12px",
              textAlign: "center",
              fontWeight: "700",
              textAlign: "center",
              marginTop: "-6px",
              color: "#666666",
            }}
          >
            Promise Success{" "}
          </Paragraph2>
        </div>
        <StyledAction>
          <div style={{ padding: "10px" }}>
            <ReactCircle percentage={60} />
          </div>
          <Paragraph2
            style={{
              fontSize: "10px",
              lineHeight: "12px",
              textAlign: "center",
              fontWeight: "700",
              marginBottom: "5px",
              color: "#666",
              marginTop: "1px",
            }}
          >
            Last Status
          </Paragraph2>
          <div className="card-head">
            <SuccessSheild />
            <SuccessSheild />
            <FaildSheild />
          </div>
        </StyledAction>
      </CardLayout>
    </div>
  );
};

export default PromiseSuccess;
