import React from "react";

const RuleIcon = ({ color = "" }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9761 13.6657C10.9761 14.6436 11.7689 15.4364 12.7468 15.4364C13.7247 15.4364 14.5175 14.6436 14.5175 13.6657C14.5175 12.6878 13.7247 11.895 12.7468 11.895C11.7689 11.895 10.9761 12.6878 10.9761 13.6657ZM10.9761 13.6657H9.00033C6.79126 13.6657 5.00044 11.8749 5.00033 9.66588L5 3M10.6441 6.5829H5M14.5175 6.5829C14.5175 7.56083 13.7247 8.35359 12.7468 8.35359C11.7689 8.35359 10.9761 7.56083 10.9761 6.5829C10.9761 5.60497 11.7689 4.8122 12.7468 4.8122C13.7247 4.8122 14.5175 5.60497 14.5175 6.5829Z"
        stroke="#333860"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default RuleIcon;
