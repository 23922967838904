import React from "react";

const PromiseToPayInfo = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9662_81487)">
        <path
          d="M19.6281 12.8078C18.7427 12.4874 17.7598 12.5614 16.9313 13.0109C16.9205 13.0163 13.6873 14.4775 13.6873 14.4775C13.4576 14.6495 13.1911 14.7404 12.9168 14.7404H10.8825C10.6298 14.7404 10.425 14.5358 10.425 14.2834C10.425 14.031 10.6298 13.8264 10.8825 13.8264H12.9168C13.5527 13.8264 13.5666 12.5181 12.9438 12.4663L10.1085 12.4337C9.60814 12.4282 9.10741 12.1066 8.58003 12.0945C8.04326 12.0822 7.53545 12.2191 7.08418 12.5118C6.4558 12.9194 6.0268 13.5671 5.73926 14.2462L7.27326 18.12H11.6841C12.0821 18.12 12.4753 18.0155 12.8208 17.8178L19.6661 13.9019C19.8742 13.7254 20.035 13.5194 19.9643 13.2029C19.9246 13.025 19.7969 12.869 19.6281 12.8078V12.8078Z"
          fill="black"
        />
        <path
          d="M2.90039 14.7202L4.99093 19.9995L6.66897 19.0781L4.57839 13.7988L2.90039 14.7202Z"
          fill="black"
        />
        <path
          d="M15.4975 2C13.2531 2 11.4336 3.84412 11.4336 6.11892C11.4336 8.39373 13.2531 10.2378 15.4975 10.2378C17.7419 10.2378 19.5614 8.39373 19.5614 6.11892C19.5615 3.84412 17.742 2 15.4975 2V2ZM15.9593 8.17496V8.85777H15.0189V8.2305C14.6556 8.1765 14.3327 8.03192 14.0539 7.81277L14.3911 6.86696C14.6646 7.08185 15.0554 7.27819 15.3914 7.27819C15.6491 7.27819 15.8156 7.15992 15.8156 6.97685C15.8156 6.85785 15.7773 6.71527 15.3205 6.52908C14.7486 6.30342 14.1632 5.97242 14.1632 5.23104C14.1632 4.67131 14.5053 4.22162 15.0606 4.04431V3.38004H15.9951V3.98542C16.2795 4.02981 16.5836 4.16846 16.8339 4.32988L16.4833 5.26077C16.2314 5.09273 15.8741 4.92965 15.5874 4.92965C15.2739 4.92965 15.2401 5.09027 15.2401 5.15931C15.2401 5.26742 15.2804 5.36073 15.8023 5.57785C16.3126 5.78719 16.8983 6.13062 16.8983 6.93496C16.8983 7.50962 16.5319 7.99023 15.9593 8.17496V8.17496Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_9662_81487">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PromiseToPayInfo;
