import React from "react";

const SmsContactSvg = ({ width = "24", height = "24", color = "#FD372A" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5938 6H5.40625C4.62928 6 4 6.63319 4 7.40625V15.8438C4 16.6214 4.63391 17.25 5.40625 17.25H18.5938C19.3642 17.25 20 16.6241 20 15.8438V7.40625C20 6.63456 19.3732 6 18.5938 6ZM18.3968 6.9375C18.1095 7.22328 13.1651 12.1417 12.9944 12.3115C12.7288 12.5771 12.3756 12.7233 12 12.7233C11.6244 12.7233 11.2712 12.5771 11.0047 12.3106C10.8899 12.1964 6.00009 7.33231 5.60319 6.9375H18.3968ZM4.9375 15.6529V7.59766L8.98869 11.6275L4.9375 15.6529ZM5.60378 16.3125L9.65338 12.2887L10.3427 12.9744C10.7854 13.4171 11.374 13.6608 12 13.6608C12.626 13.6608 13.2146 13.4171 13.6564 12.9753L14.3466 12.2887L18.3962 16.3125H5.60378ZM19.0625 15.6529L15.0113 11.6275L19.0625 7.59766V15.6529Z"
        fill={color}
      />
    </svg>
  );
};

export default SmsContactSvg;
