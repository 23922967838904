import React from "react";

const Notification = (props) => {
  return (
    <div
      style={{
        height: "15px",
        width: "15px",
        borderRadius: "100px",
        backgroundColor: "red",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ color: "#ffffff", margin: "5px", fontSize: "10px" }}>
        {props.count}
      </span>
    </div>
  );
};
export default Notification;
