import React, { useContext } from "react";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
function CurrencyFormate(amount) {
  const { currencyDecimalSeparator, currencyGroupStyle, currencySeparator } =
    useContext(MaxyfiContext);

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  let value = numberFormat.format(amount ? `${amount}` : "0");

  return value;
}

export default CurrencyFormate;
