const LetterStatusSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.498 5.76171C15.4262 5.59542 15.3344 5.45751 15.2226 5.33989L11.3353 1.38937C11.2235 1.27175 11.0838 1.17846 10.9202 1.10951C10.7565 1.0365 10.5929 1 10.4213 1H3.27716C2.93792 1 2.63858 1.12979 2.38315 1.38937C2.12772 1.64896 2 1.9491 2 2.29791V16.9846C2 17.3293 2.12772 17.6335 2.38315 17.8931C2.63858 18.1527 2.93792 18.2825 3.27716 18.2825H10.1499C10.0501 17.8404 9.99823 17.378 9.99823 16.9035C9.99823 16.0963 10.1499 15.3216 10.4253 14.6118H6.01907C5.83548 14.6118 5.68381 14.551 5.56408 14.4293C5.44435 14.3076 5.38448 14.1535 5.38448 13.9629C5.38448 13.7723 5.44435 13.6222 5.56408 13.4965C5.68381 13.3748 5.83548 13.3139 6.01907 13.3139H11.1157C12.1295 11.8457 13.7499 10.8438 15.6058 10.6776V6.26871C15.6058 6.0943 15.5698 5.92801 15.498 5.76171ZM12.0457 10.7506C11.9259 10.8722 11.7743 10.9331 11.5867 10.9331H6.01907C5.83548 10.9331 5.68381 10.8722 5.56408 10.7506C5.44435 10.6289 5.38448 10.4748 5.38448 10.2882C5.38448 10.1016 5.44435 9.94748 5.56408 9.82174C5.68381 9.70006 5.83548 9.63922 6.01907 9.63922H11.5867C11.7703 9.63922 11.922 9.70006 12.0457 9.82174C12.1654 9.94342 12.2253 10.0975 12.2253 10.2882C12.2253 10.4748 12.1654 10.6289 12.0457 10.7506ZM10.9481 6.31332C10.7645 6.31332 10.6129 6.25248 10.4891 6.13081C10.3694 6.00913 10.3095 5.855 10.3095 5.66842V2.29791L14.3246 6.31332H10.9481ZM14.6599 18.2825C13.1792 18.2825 11.9898 17.0738 11.9898 15.5731C11.9898 14.0683 13.1792 12.8597 14.6599 12.8597C16.1406 12.8597 17.3259 14.0683 17.3259 15.5731C17.3259 17.0738 16.1366 18.2825 14.6599 18.2825ZM17.4656 17.7836C17.9326 17.1712 18.216 16.4046 18.216 15.5731C18.216 13.5816 16.6195 11.9552 14.6599 11.9552C12.7002 11.9552 11.0998 13.5816 11.0998 15.5731C11.0998 17.5646 12.7002 19.187 14.6599 19.187C15.478 19.187 16.2324 18.899 16.835 18.4245L19.2377 20.8662C19.3215 20.9513 19.4333 21 19.553 21C19.7965 21 20 20.7972 20 20.5457C20 20.4241 19.9521 20.3105 19.8683 20.2253L17.4656 17.7836Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default LetterStatusSvg;
