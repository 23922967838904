export const DOCUMENT_DRIVE_DATA_INVOICE = "DOCUMENT_DRIVE_DATA_INVOICE";
export const DOCUMENT_DRIVE_DATA_INVOICE_SET_EMAIL_ATTACHMENT =
  "DOCUMENT_DRIVE_DATA_INVOICE_SET_EMAIL_ATTACHMENT";
export const INVOICE_DRIVE_OPEN = "INVOICE_DRIVE_OPEN";
export const DOCUMENT_INVOICE_DRAWER_CLOSE = "DOCUMENT_INVOICE_DRAWER_CLOSE";
export const INVOICE_DOCUMENT_DRAWER_OPEN = "INVOICE_DOCUMENT_DRAWER_OPEN";
export const UNSELECTED_INVOICE_DOCUMENT_DATA =
  "UNSELECTED_INVOICE_DOCUMENT_DATA";
export const ADD_EMAIL_ATTACHMENT_INVOICE_FROM_DESKTOP =
  "ADD_EMAIL_ATTACHMENT_INVOICE_FROM_DESKTOP";
export const UPDATE_EMAIL_INVOICE_ATTACHMENT_FROM_DESKTOP =
  "UPDATE_EMAIL_INVOICE_ATTACHMENT_FROM_DESKTOP";
export const ADD_INVOICE_DOCUMENT_FORM = "ADD_INVOICE_DOCUMENT_FORM";
export const EDIT_DOCUMENT_INVOICE_FORM = "EDIT_DOCUMENT_INVOICE_FORM";
export const INVOICE_TYPE_FORM_INITIAL = "INVOICE_TYPE_FORM_INITIAL";
export const REFETCH_INVOICE_DOCUMENT_DATA = "REFETCH_INVOICE_DOCUMENT_DATA";

export const refetchInvoiceDocumentData = () => {
  return {
    type: REFETCH_INVOICE_DOCUMENT_DATA,
  };
};

export const invoiceTypeForm = () => {
  return {
    type: INVOICE_TYPE_FORM_INITIAL,
  };
};

export const editInvoiceDocumentForm = (data) => {
  return {
    type: EDIT_DOCUMENT_INVOICE_FORM,
    payload: data,
  };
};

export const addInvoiceDocumentForm = (data) => {
  return {
    type: ADD_INVOICE_DOCUMENT_FORM,
    payload: data,
  };
};

export const documentInvoiceDrawerOpen = (data) => {
  return {
    type: INVOICE_DOCUMENT_DRAWER_OPEN,
    payload: data,
  };
};

export const updateEmailInvoiceAttachmentFromDesktop = (payload) => {
  return {
    type: UPDATE_EMAIL_INVOICE_ATTACHMENT_FROM_DESKTOP,
    payload,
  };
};

export const addEmailInvoiceAttachmentFromDesktop = (payload) => {
  return {
    type: ADD_EMAIL_ATTACHMENT_INVOICE_FROM_DESKTOP,
    payload,
  };
};

export const documentDriveInv = (data) => {
  return {
    type: DOCUMENT_DRIVE_DATA_INVOICE,
    payload: data,
  };
};

export const documentDriveInvEmailAttachment = (data) => {
  return {
    type: DOCUMENT_DRIVE_DATA_INVOICE_SET_EMAIL_ATTACHMENT,
    payload: data,
  };
};

export const invoiceDriveOpen = (data) => {
  return {
    type: INVOICE_DRIVE_OPEN,
    payload: data,
  };
};
export const invoiceDrawerClose = (data) => {
  return {
    type: DOCUMENT_INVOICE_DRAWER_CLOSE,
    payload: data,
  };
};

export const unSelectedInvoiceDocumentData = (data) => {
  return {
    type: UNSELECTED_INVOICE_DOCUMENT_DATA,
    payload: data,
  };
};
