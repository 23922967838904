import React from "react";
function CreditNotes({ size = "25", color = "#787878" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6749_81011)">
        <path
          d="M14.2992 10.8998C14.4992 10.8998 14.5992 10.7998 14.5992 10.5998C14.5992 10.3998 14.4992 10.2998 14.2992 10.2998H6.49922C6.29922 10.2998 6.19922 10.3998 6.19922 10.5998C6.19922 10.7998 6.29922 10.8998 6.49922 10.8998H14.2992Z"
          fill={color}
          stroke={color}
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M6.60004 14.1996H11.9C12.1 14.1996 12.2 14.0996 12.2 13.8996C12.2 13.6996 12 13.5996 11.9 13.5996H6.60004C6.40004 13.5996 6.30004 13.6996 6.30004 13.8996C6.20004 13.9996 6.40004 14.1996 6.60004 14.1996Z"
          fill={color}
          stroke={color}
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M14.2992 7.8002C14.4992 7.8002 14.5992 7.7002 14.5992 7.5002C14.5992 7.3002 14.4992 7.2002 14.2992 7.2002H6.49922C6.29922 7.2002 6.19922 7.3002 6.19922 7.5002C6.19922 7.7002 6.29922 7.8002 6.49922 7.8002H14.2992Z"
          fill={color}
          stroke={color}
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
        <path
          d="M16.9008 12.7002C14.3008 12.7002 12.3008 14.9002 12.3008 17.6002C12.3008 20.3002 14.4008 22.5002 16.9008 22.5002C19.4008 22.5002 21.5008 20.3002 21.5008 17.6002C21.5008 14.9002 19.4008 12.7002 16.9008 12.7002ZM16.9008 21.9002C14.7008 21.9002 12.9008 20.0002 12.9008 17.6002C12.9008 15.2002 14.7008 13.3002 16.9008 13.3002C19.1008 13.3002 20.9008 15.2002 20.9008 17.6002C20.9008 20.0002 19.1008 21.9002 16.9008 21.9002Z"
          fill={color}
          stroke={color}
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M18 19.2996C18.2 18.9996 18.3 18.6996 18.2 18.2996C18.1 17.8996 17.9 17.5996 17.6 17.3996C17.4 17.2996 17.2 17.1996 17 17.0996H16.9C16.7 16.9996 16.6 16.8996 16.5 16.7996C16.3 16.6996 16.3 16.4996 16.3 16.2996C16.3 16.0996 16.4 15.8996 16.6 15.8996C16.7 15.7996 16.8 15.7996 16.9 15.7996C17.2 15.7996 17.6 15.7996 17.9 15.9996C17.9 15.8996 18 15.6996 18 15.5996V15.4996C17.8 15.3996 17.6 15.3996 17.5 15.2996C17.2 15.1996 17.1 15.1996 17.1 14.7996C17.1 14.6996 17.1 14.5996 17.1 14.5996C17.1 14.5996 17 14.5996 16.9 14.5996C16.9 14.5996 16.9 14.5996 16.8 14.5996H16.7C16.7 14.6996 16.7 14.6996 16.7 14.7996V14.8996C16.7 15.1996 16.7 15.2996 16.4 15.3996C15.9 15.5996 15.6 15.8996 15.6 16.4996C15.6 16.8996 15.8 17.2996 16.2 17.4996C16.4 17.5996 16.6 17.6996 16.7 17.7996C16.8 17.7996 16.9 17.8996 17 17.8996C17.1 17.9996 17.3 17.9996 17.3 18.0996C17.5 18.1996 17.6 18.4996 17.5 18.6996C17.5 18.8996 17.3 19.0996 17.1 19.1996C16.9 19.2996 16.7 19.2996 16.5 19.2996C16.2 19.2996 15.9 19.1996 15.6 18.9996C15.6 19.0996 15.5 19.2996 15.5 19.3996C15.5 19.4996 15.5 19.4996 15.5 19.4996C15.5 19.4996 15.5 19.4996 15.6 19.4996C15.8 19.5996 16.1 19.6996 16.3 19.6996C16.6 19.6996 16.6 19.7996 16.6 20.0996C16.6 20.1996 16.6 20.1996 16.6 20.1996V20.2996C16.7 20.2996 16.9 20.2996 17 20.2996C17 20.2996 17 20.1996 17 20.0996C17 19.9996 17 19.8996 17 19.8996C17 19.7996 17 19.5996 17.3 19.4996C17.6 19.6996 17.8 19.4996 18 19.2996Z"
          fill={color}
          stroke={color}
          stroke-width="0.25"
          stroke-miterlimit="10"
        />
        <path
          d="M15.1 2.5H6.1C4.7 2.5 3.5 3.7 3.5 5.1V18.8C3.5 20.3 4.7 21.4 6.1 21.4H9.5H10.3H11.3C11.5 21.4 11.6 21.3 11.6 21.1C11.6 20.9 11.5 20.8 11.3 20.8H9.5H6.1C5 20.8 4.2 19.9 4.2 18.8V5.1C4.2 4 5.1 3.1 6.1 3.1H15.2C16.3 3.1 17.1 4 17.1 5.1L17.2 9.4V11C17.2 11.2 17.3 11.3 17.5 11.3C17.7 11.3 17.8 11.2 17.8 11V9.9V9V5.1C17.6 3.7 16.5 2.5 15.1 2.5Z"
          fill={color}
          stroke={color}
          stroke-width="0.75"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_6749_81011">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CreditNotes;
