import { HeadingXLarge } from "baseui/typography";
import React from "react";
import { TextButton } from "../../../components/TextButton";
import NoTimeline from "../../../assets/img/no_timeline.png";
import { useDispatch, useSelector } from "react-redux";
import { tabMotion } from "../../../redux/actions";
import { invoiceTabMotion } from "../../../redux/invoiceOverview/action";

const TimelineCreateAction = () => {
  const dispatch = useDispatch();
  const entity = useSelector((s) => s.invoiceOverviewReducer.invoice_entity);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            height: "200px",
            width: "200px",
          }}
        >
          <img src={NoTimeline} alt="" />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "15px",
        }}
      >
        <>
          <HeadingXLarge>
            There are no actions captured for this customer
          </HeadingXLarge>
        </>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "15px",
        }}
        onClick={() => {
          if (entity === "INVOICE") {
            dispatch(invoiceTabMotion(1));
          } else {
            dispatch(tabMotion(2));
          }
        }}
      >
        <div style={{ width: "190px" }}>
          <TextButton fullWidth>Capture Action</TextButton>
        </div>
      </div>
    </div>
  );
};

export default TimelineCreateAction;
