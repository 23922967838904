export const ADD_STATE = "ADD_STATE";
export const EDIT_DATA = "EDIT_DATA";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const DAIRY_NOTE_ID = "DAIRY_NOTE_ID";
export const RESET_DIARY_NOTE_STATE = "RESET_DIARY_NOTE_STATE";
export const REFETCH_DIARY_NOTES = "REFETCH_DIARY_NOTES";
export const ACTION_TYPE = "ACTION_TYPE";
export const CANCEL_NOTES_FORM = "CANCEL_NOTES_FORM";

// Action

export const cancelNoteForm = () => {
  return {
    type: CANCEL_NOTES_FORM,
  };
};

export const gettingId = (id) => {
  return {
    type: DAIRY_NOTE_ID,
    payload: id,
  };
};

export const resetDiaryNoteReduxState = (id) => {
  return {
    type: RESET_DIARY_NOTE_STATE,
  };
};

export const refetchDiaryNotes = (id) => {
  return {
    type: REFETCH_DIARY_NOTES,
  };
};

export const addData = (data) => {
  return {
    type: ADD_STATE,
    payload: data,
  };
};

export const editDataForm = (data) => {
  return {
    type: EDIT_DATA,
    payload: data,
  };
};

export const openModal = (data) => {
  return {
    type: OPEN_MODAL,
    payload: data,
  };
};

export const closeModal = (data) => {
  return {
    type: CLOSE_MODAL,
    payload: data,
  };
};

export const changeActionType = () => {
  return {
    type: ACTION_TYPE,
  };
};
