import React, { useContext } from "react";

import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  assignMulInvoicePauseAction,
  assignMulPauseAction,
  assignMultBatch,
  checkActionFn,
} from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { TextArea } from "../../components/TextArea";
import { Typography } from "../../components_v2";
import queryClient from "../../providers/queryClient";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";

const GenerateNACHA = ({
  handleModal,
  checkActionDataAsync = () => {},
  batchId = null,
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let { currentOrganization, referenceData, currentDefaultFormatDetails } =
    maxyfiContextValue;

  const checkActionData = useMutation(
    (data) =>
      checkActionFn({
        ...data,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`BATCH_SUMMERY-${currentOrganization}`);
        handleModal();
      },
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let values = {
      ...(data.effective_entry_date
        ? {
            effective_entry_date: moment
              .tz(
                `${data.effective_entry_date.getFullYear()}-${
                  data.effective_entry_date.getMonth() + 1
                }-${data.effective_entry_date.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.comp_descriptive_date
        ? {
            comp_descriptive_date: moment
              .tz(
                `${data.comp_descriptive_date.getFullYear()}-${
                  data.comp_descriptive_date.getMonth() + 1
                }-${data.comp_descriptive_date.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
    };

    await checkActionData.mutateAsync({
      ...values,
      batchId: batchId,
      action_type: "GENERATED",
    });
  };

  const intl = useIntl();
  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography type="h3" style={{ marginBottom: "10px" }}>
          Generate NACHA File
        </Typography>

        <div style={{ width: "100%" }}>
          <Controller
            name="comp_descriptive_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                // disabled={isDisabled}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "comp_descriptive_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                size={SIZE.mini}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
                // minDate={new Date()}
                // range
              />
            )}
          />
        </div>

        <div style={{ width: "100%" }}>
          <Controller
            name="effective_entry_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                // disabled={isDisabled}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "effective_entry_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                size={SIZE.mini}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
                // minDate={new Date()}
                // range
              />
            )}
          />
        </div>

        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Submit
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default GenerateNACHA;
