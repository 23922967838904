import React from "react";
import { StyledSvg } from "baseui/icon";

const EditSvg = ({ size = "22px" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M2.75 15.8125V19.25H6.1875L16.3258 9.11167L12.8883 5.67417L2.75 15.8125ZM5.42667 17.4167H4.58333V16.5733L12.8883 8.26833L13.7317 9.11167L5.42667 17.4167ZM18.9842 5.16083L16.8392 3.01583C16.6558 2.8325 16.4267 2.75 16.1883 2.75C15.95 2.75 15.7208 2.84167 15.5467 3.01583L13.8692 4.69333L17.3067 8.13083L18.9842 6.45333C19.3417 6.09583 19.3417 5.51833 18.9842 5.16083V5.16083Z"
        fill="#333860"
      />
    </svg>
  );
};

export default EditSvg;
