import * as React from "react";
import { useContext, useState } from "react";
import { Drawer } from "baseui/drawer";
import { useSelector, useDispatch } from "react-redux";
import {
  instantPaymentDrawer,
  updateInstantPaymentAmount,
} from "../../../../../redux/customerOverview/callAction/action";
import { Avatar } from "baseui/avatar";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";

import { Controller, useForm } from "react-hook-form";

import { ALIGN, Radio, RadioGroup } from "baseui/radio";

import { Plus } from "baseui/icon";
import { HeadingXSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import Lock from "../../../../../assets/img/svg/Lock";
import EditPencil from "../../../../../utils/EditPencil";
import { IconButton } from "../../../../../components/IconButton";
import { ModalButton } from "baseui/modal";
import { useNumericFormat } from "react-number-format";
import { makeInstantPayment } from "../../../../../services";
import { useSearchParams } from "react-router-dom";
import { TextButton, KIND, SIZE } from "../../../../../components/TextButton";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Input } from "baseui/input";

import TickSvg from "../../../../../assets/img/svg/TickSvg";
import ActionRemove from "../../../../../assets/img/svg/ActionRemove";

const PaymentCardDrawer = () => {
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const getParams = getSearchParams.get("customers");
  const [card, setCard] = useState({
    isCard: false,
    cardId: null,
    paymentMethodId: null,
  });
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: "",
  });

  const {
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    currentOrganization,
    referenceData,
  } = useContext(MaxyfiContext);

  const { modal, amount, currency, installment_id } = useSelector(
    (e) => e.callAction
  );
  const { customerDetails } = useSelector((e) => e.customerOverviewReducer);
  const dispatch = useDispatch();

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  const makeInstantPaymentMutation = useMutation(
    (data) =>
      makeInstantPayment({
        data,
        organization: currentOrganization,
        customerId: getParams,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (
          data &&
          data.data &&
          data.data.doc &&
          data.data.doc.payment_initiate_url
        ) {
          window.open(
            data &&
              data.data &&
              data.data.doc &&
              data.data.doc.payment_initiate_url
          );
        } else if (data && data.data && data.data.payment_status_fetch_url) {
          window.open(data && data.data && data.data.payment_status_fetch_url);
        }

        dispatch(
          instantPaymentDrawer({
            modal: false,
          })
        );
      },
    }
  );

  return (
    <Drawer
      isOpen={modal}
      autoFocus
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
        DrawerBody: {
          style: ({ $theme }) => ({
            marginLeft: "15px",
            marginRight: "15px",
            marginBottom: "15px",
            marginTop: "17px",
            padding: "15px",
            overflowY: "hidden",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
      }}
      onClose={() => {
        dispatch(
          instantPaymentDrawer({
            modal: false,
          })
        );
      }}
    >
      <div style={{ overflow: "scroll", height: "calc(100vh - 45px)" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            paddingRight: "15px",
          }}
        >
          <div>
            <LabelMedium>Payment Amount</LabelMedium>
            <HeadingXSmall>
              To maintain a "Kept" status, full payment is required
            </HeadingXSmall>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!isEdit.state ? (
              <>
                <span style={{ fontSize: "10px" }}>{currency}</span>&nbsp;
                <ParagraphMedium>
                  {numberFormat.format(amount ? `${amount}` : "-")}
                </ParagraphMedium>
                <div className="cp_pencil_icon">
                  <IconButton
                    size="mini"
                    kind="tertiary"
                    onClick={() => setIsEdit({ state: true, data: amount })}
                  >
                    <EditPencil size={"12px"} />
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <Input
                  onChange={(e) => {
                    setIsEdit({
                      state: true,
                      data: e.target.value,
                    });
                  }}
                  type="number"
                  value={isEdit.data}
                  overrides={{
                    MaskToggleButton: {
                      style: () => ({
                        // backgroundColor: "#fff",
                        background: "#ffffff",
                        cursor: "pointer",
                      }),
                    },
                    Root: {
                      style: ({ $theme, $isFocused, $error }) => ({
                        borderRadius: "5px",
                        borderWidth: "1px",
                        borderColor: $isFocused
                          ? $error
                            ? "#FD372A"
                            : "#516BEB"
                          : $error
                          ? "#FD372A"
                          : "#CDCED9",
                        backgroundColor: "#ffffff",
                        paddingRight: "0px",
                        padding: "0px",
                        paddingLeft: "0px",
                      }),
                    },
                    Input: {
                      style: ({ $theme, $isFocused }) => ({
                        backgroundColor: "#ffffff",
                        "::placeholder": {
                          color: $isFocused ? "#cbd5e0" : "transparent",
                          transition: "color 0.4s ease",
                        },
                      }),
                    },

                    EndEnhancer: {
                      style: ({ $theme }) => ({
                        paddingTop: "5px",
                        backgroundColor: "#ffffff",
                        display: "none",
                        width: "",
                      }),
                    },
                    StartEnhancer: {
                      style: ({ $theme }) => ({
                        // outline: `${$theme.colors.warning200} solid`,
                        backgroundColor: "#EEEEEE",
                        display: "none",
                      }),
                    },
                  }}
                  size="mini"
                />
                <div className="cp_edit_ic">
                  <div className="tick_cp_icon">
                    <TextButton
                      size="mini"
                      kind="tertiary"
                      disabled={
                        isEdit.data <= 0 ||
                        isEdit.data >
                          customerDetails?.total_outstanding_invoice_amount
                            ?.value
                      }
                      onClick={() => {
                        dispatch(updateInstantPaymentAmount(isEdit.data));

                        setIsEdit({
                          state: false,
                          data: 0,
                        });
                      }}
                    >
                      <TickSvg />
                    </TextButton>
                  </div>
                  <div className="close_cp_icon">
                    <TextButton
                      size="mini"
                      kind="tertiary"
                      onClick={() => {
                        setIsEdit({
                          state: false,
                          data: 0,
                        });
                      }}
                    >
                      <ActionRemove />
                    </TextButton>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {customerDetails &&
        customerDetails.transaction_cards &&
        Array.isArray(customerDetails.transaction_cards) &&
        customerDetails.transaction_cards[0] ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "15px",
                marginTop: "30px",
              }}
            >
              <div>
                <LabelMedium>Saved Cards</LabelMedium>
                <HeadingXSmall>
                  You've utilized these cards for payments previously
                </HeadingXSmall>
              </div>
            </div>

            {customerDetails.transaction_cards.map((e) => {
              return (
                <div
                  className="paymentplancard"
                  onClick={() =>
                    setCard({
                      isCard: true,
                      cardId: e.id,
                      paymentMethodId: e.payment_config_id,
                    })
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Avatar
                      name="AS"
                      src={`https://www.google.com/search?q=visa+card+image&tbm=isch&ved=2ahUKEwiJwtn3tMiBAxV-s2MGHW3VD3IQ2-cCegQIABAA&oq=visa+card+image&gs_lcp=CgNpbWcQAzIFCAAQgAQyBQgAEIAEMgYIABAHEB4yBggAEAUQHjIGCAAQBRAeMgYIABAFEB4yBggAEAUQHjIGCAAQBRAeMgYIABAIEB4yBggAEAgQHjoECCMQJzoICAAQCBAHEB46CAgAEAUQBxAeUJcIWMUgYLUjaABwAHgAgAFziAGGCZIBBDEyLjKYAQCgAQGqAQtnd3Mtd2l6LWltZ8ABAQ&sclient=img&ei=0eASZcmVJf7mjuMP7aq_kAc&bih=606&biw=1272&hl=en#imgrc=9W_dnm08jQfJyM`}
                    />

                    <div>
                      <HeadingXSmall $style={{ color: "#787878" }}>
                        Card Details
                      </HeadingXSmall>

                      <LabelMedium
                        $style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        xxxx{" "}
                        <span>
                          <div className="bot_card_details"></div>
                        </span>
                        xxxx
                        <span>
                          <div className="bot_card_details"></div>
                        </span>{" "}
                        xxxx{" "}
                        <span>
                          <div className="bot_card_details"></div>
                        </span>{" "}
                        {e.last4 ? e.last4 : ""}
                      </LabelMedium>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Lock color="#516BEB" />

                        <HeadingXSmall $style={{ color: "#516BEB" }}>
                          Information Securely Encrypted
                        </HeadingXSmall>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Radio checked={card && card.cardId === e.id}></Radio>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <></>
        )}

        {referenceData &&
        referenceData["online_payment_method"] &&
        Array.isArray(referenceData["online_payment_method"]) ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "15px",
                marginTop: "30px",
              }}
            >
              <div>
                <LabelMedium>Add New Card</LabelMedium>
                <HeadingXSmall>
                  You've following choices for adding a new debit/credit card
                  for payment
                </HeadingXSmall>
              </div>
            </div>
            {referenceData["online_payment_method"].map((e) => {
              return (
                <div
                  className="paymentplancard"
                  onClick={() =>
                    setCard({
                      isCard: false,
                      cardId: null,
                      paymentMethodId: e.id,
                    })
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <Avatar
                      name="AS"
                      src={`https://www.google.com/search?q=visa+card+image&tbm=isch&ved=2ahUKEwiJwtn3tMiBAxV-s2MGHW3VD3IQ2-cCegQIABAA&oq=visa+card+image&gs_lcp=CgNpbWcQAzIFCAAQgAQyBQgAEIAEMgYIABAHEB4yBggAEAUQHjIGCAAQBRAeMgYIABAFEB4yBggAEAUQHjIGCAAQBRAeMgYIABAIEB4yBggAEAgQHjoECCMQJzoICAAQCBAHEB46CAgAEAUQBxAeUJcIWMUgYLUjaABwAHgAgAFziAGGCZIBBDEyLjKYAQCgAQGqAQtnd3Mtd2l6LWltZ8ABAQ&sclient=img&ei=0eASZcmVJf7mjuMP7aq_kAc&bih=606&biw=1272&hl=en#imgrc=9W_dnm08jQfJyM`}
                    />

                    <div>
                      <HeadingXSmall $style={{ color: "#787878" }}>
                        Card Details
                      </HeadingXSmall>

                      <LabelMedium
                        $style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {e.label}
                      </LabelMedium>
                    </div>
                  </div>

                  <div>
                    <Radio
                      checked={
                        card && card.paymentMethodId === e.id && !card.isCard
                      }
                    ></Radio>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <HeadingXSmall>No Payment Methods Available</HeadingXSmall>
        )}

        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
            paddingTop: "20px",
          }}
        >
          <TextButton
            type="button"
            onClick={() => {
              dispatch(
                instantPaymentDrawer({
                  modal: false,
                })
              );
            }}
            kind={KIND.tertiary}
            size={SIZE.mini}
          >
            Cancel
          </TextButton>
          <TextButton
            type="submit"
            size={SIZE.mini}
            onClick={async () => {
              await makeInstantPaymentMutation.mutateAsync({
                payment_amount: { value: amount, currency },
                ...(card.isCard
                  ? {
                      card_id: card.cardId,
                      payment_method: card.paymentMethodId,
                    }
                  : {}),
                ...(!card.isCard
                  ? { payment_method: card.paymentMethodId }
                  : {}),
                ...(installment_id ? { installment_id } : {}),
              });
            }}
            disabled={
              makeInstantPaymentMutation.isLoading || !card.paymentMethodId
            }
            isLoading={makeInstantPaymentMutation.isLoading}
          >
            Continue
          </TextButton>
        </div>
      </div>
    </Drawer>
  );
};

export default PaymentCardDrawer;
