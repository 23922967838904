import React, { useEffect, useState, useContext } from "react";
import CpContactCard from "./CpContactCard";
import { useParams } from "react-router-dom";
import { getCustomerContactDetail } from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

const ContactDetailsCard = ({
  data,
  setPrimaryContact,
  primaryContact,
  entity,
  ...rest
}) => {
  return (
    <>
      {data &&
        data.map((contact) => (
          <CpContactCard
            {...contact}
            {...rest}
            key={contact._id}
            entity={entity}
            setPrimaryContact={setPrimaryContact}
            primaryContact={primaryContact}
          />
        ))}
    </>
  );
};

export default ContactDetailsCard;
