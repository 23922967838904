function Email() {
  return (
    <>
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2 0H1.8C0.81 0 0.00899999 0.84375 0.00899999 1.875L0 13.125C0 14.1562 0.81 15 1.8 15H16.2C17.19 15 18 14.1562 18 13.125V1.875C18 0.84375 17.19 0 16.2 0ZM15.84 3.98438L9.477 8.12813C9.189 8.31563 8.811 8.31563 8.523 8.12813L2.16 3.98438C1.935 3.83438 1.8 3.58125 1.8 3.30938C1.8 2.68125 2.457 2.30625 2.97 2.63438L9 6.5625L15.03 2.63438C15.543 2.30625 16.2 2.68125 16.2 3.30938C16.2 3.58125 16.065 3.83438 15.84 3.98438Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default Email;
