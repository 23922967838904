import React, { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Icon, Typography } from "../../../../components_v2";
import moment from "moment";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { KIND, TextButton } from "../../../../components/TextButton";
import _ from "lodash";
import { StatefulTooltip } from "baseui/tooltip";
import { SIZE, SelectBox } from "../../../../components/SelectBox";
import { Controller, useForm } from "react-hook-form";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { useMutation, useQuery } from "react-query";
import { editTimeLegal, getGridStructure } from "../../../../services";
import columnMapper_V2 from "../../../../utils/mapper_v2";
import queryClient from "../../../../providers/queryClient";
import { LEGAL_ACTION_INFO_ } from "../../../../constants";
import { utcTimstampToLocalDate } from "../../../../utils/utcTimstampToLocalDate";
import { FormControl } from "baseui/form-control";
import useFormat from "../../../../hooks/useFormat";
import { TimePicker } from "baseui/timepicker";
import { toast } from "react-toastify";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { UPDATE_TIMESHEET_LEGAL } from "../../../../providers/RBACProvider/permissionList";
const Header = ({ label = "" }) => {
  const intl = useIntl();
  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

const LegalTimeTable = (props) => {
  let intl = useIntl();
  const { customerId } = useParams();
  const [width, setWidth] = useState(0);
  let ref = React.useRef();
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );
  const gridStructure = useQuery(
    [`GRID_STRUCTURE_LEGAL_TIMESHEET`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "time_sheet_legal_table",
      });
    },
    {
      cacheTime: 86400000,
    }
  );

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({});
  const [expanded, setExpanded] = React.useState({});
  const format = useFormat();

  const [twelveHourTime, setTwelveHourTime] = useState(null);
  const [changeDate, setChangeDate] = React.useState(null);
  const [timeSheetid, setTimeSheetId] = useState("");
  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);

  const data = useMemo(() => {
    const timeSheet = _.get(legalInfo, "data.doc.tms", []);
    return timeSheet;
  }, [legalInfo?.data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const editTime = useMutation(
    (debtData) => {
      editTimeLegal({
        organization: currentOrganization,
        customerId: customerId,
        id: timeSheetid,
        ...debtData,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
        reset();
      },
    }
  );
  const onLegalSubmit = async () => {
    await editTime.mutateAsync({
      ent: moment(
        moment(changeDate[0]).format("MMMM DD YYYY") +
          " " +
          moment(twelveHourTime).format("hh:mm A"),
        "MMMM DD YYYY hh:mm A"
      ).valueOf(),
    });
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flex: "1 0 0px",
        overflow: "hidden",
        flexDirection: "column",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="co_altc_payment-table_wrapper">
        <div className="co_altc_payment_table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* Table Body */}
        <div className="co-table_body">
          {/* TABLE ROW */}

          {table.getRowModel().rows.map((row) => (
            <div key={row.id} className="co-table_body-group">
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                  className="co-table_body-cell"
                >
                  {cell.column.id === "ent" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4px",
                        }}
                      >
                        {new Date(
                          utcTimstampToLocalDate(_.get(row, "original.ent"))
                        ) ? (
                          <StatefulTooltip
                            placement={PLACEMENT.leftBottom}
                            showArrow
                            overrides={{
                              Arrow: {
                                style: ({ $theme }) => ({
                                  outline: `#16164B solid`,
                                  backgroundColor: "#16164B",
                                }),
                              },
                              Inner: {
                                style: ({ $theme }) => ({
                                  outline: `#16164B solid`,
                                  backgroundColor: "#16164B",
                                }),
                              },
                            }}
                            content={() => (
                              <div style={{ backgroundColor: "#16164B" }}>
                                <Typography
                                  type="p"
                                  subType="regular"
                                  style={{ color: "white" }}
                                >
                                  Last Updated:
                                </Typography>
                                <Typography
                                  type="p"
                                  subType="regular"
                                  style={{ color: "white" }}
                                >
                                  {format.rd({
                                    id: _.get(row, "original.usi", ""),
                                    name: "users",
                                  })}{" "}
                                  on{" "}
                                  {intl.formatTime(
                                    _.get(row, "original.uat", "")
                                  )}
                                </Typography>
                              </div>
                            )}
                            returnFocus
                            autoFocus
                          >
                            <Icon
                              icon="updated_clock"
                              size={18}
                              color="#516BEB"
                            />
                          </StatefulTooltip>
                        ) : (
                          <></>
                        )}

                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                        {new Date(
                          utcTimstampToLocalDate(_.get(row, "original.ent"))
                        ) ? (
                          <StatefulPopover
                            placement={PLACEMENT.bottom}
                            onOpen={() => {
                              setTimeSheetId(_.get(row, "original._id", ""));
                              setTwelveHourTime(_.get(row, "original.ent", ""));
                              setChangeDate(_.get(row, "original.ent", ""));
                            }}
                            overrides={{
                              Arrow: {
                                style: ({ $theme }) => ({
                                  outline: `##CDCED9 solid`,
                                  backgroundColor: "#16164B",
                                }),
                              },
                              Inner: {
                                style: ({ $theme }) => ({
                                  outline: `#CDCED9 solid`,
                                  backgroundColor: "#FFFFFF",
                                }),
                              },
                            }}
                            content={({ close }) => (
                              <div
                                style={{
                                  width: "232px",
                                  borderRadius: "5px",
                                  backgroundColor: "#FFFFF",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "15px",
                                  padding: "10px",
                                }}
                              >
                                <DateRangeSelect
                                  clearable={false}
                                  size={SIZE.mini}
                                  // {...field}
                                  // name={field.name}
                                  // error={errors[field.name] && errors[field.name].message}
                                  label={intl.formatMessage({
                                    id: "Change Date",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "Change Date",
                                  })}
                                  value={changeDate}
                                  onChange={({ date }) =>
                                    setChangeDate(
                                      Array.isArray(date) ? date : [date]
                                    )
                                  }
                                  minDate={new Date()}
                                />
                                <Typography className="regular">
                                  Change Time
                                </Typography>
                                <TimePicker
                                  value={twelveHourTime}
                                  onChange={(date) => setTwelveHourTime(date)}
                                  minTime={new Date()}
                                  overrides={{
                                    Select: {
                                      props: {
                                        overrides: {
                                          ControlContainer: {
                                            style: ({ $theme }) => ({
                                              height: "30px",
                                              padding: "3px",
                                              backgroundColor: "#FFFFFF",
                                              borderRadius: "5px",
                                              border: "2px solid #EEEEEEEE",
                                            }),
                                          },
                                          ValueContainer: {
                                            style: ({ $theme }) => ({
                                              padding: "0 0 0 0px",
                                              paddingLeft: "5px",
                                            }),
                                          },
                                        },
                                      },
                                    },
                                  }}
                                />

                                <div
                                  style={{ border: "1px solid #EEEEEEEE" }}
                                ></div>
                                <div className="debt-legal-invoices_buttons">
                                  <TextButton
                                    size="mini"
                                    kind={KIND.tertiary}
                                    onClick={() => {
                                      close();
                                      // setSelectedAllowAccess([]);
                                    }}
                                  >
                                    Cancel
                                  </TextButton>
                                  <TextButton
                                    size="mini"
                                    kind={KIND.primary}
                                    onClick={() => {
                                      onLegalSubmit();
                                      close();
                                    }}
                                  >
                                    Save
                                  </TextButton>
                                </div>
                              </div>
                            )}
                          >
                            <div>
                              <RBACWrapper
                                role={UPDATE_TIMESHEET_LEGAL}
                                type="PERMISSION"
                              >
                                <div>
                                  <Icon
                                    icon="edit_outline"
                                    size={18}
                                    ispressable
                                  />
                                </div>
                              </RBACWrapper>
                            </div>
                          </StatefulPopover>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </div>
              ))}
            </div>
          ))}
          {/* TABLE ROW */}
        </div>
      </div>
    </div>
  );
};

export default LegalTimeTable;
