import {
  ADD_CONTACT_MODAL,
  CLOSE_CONTACT_MODAL,
  ADD_EDIT_MODAL,
  ADD_FIELDS,
  VIEW_CONTACT_MODAL,
  GET_ACTION_DATA,
  GET_CUSTOMER_DETAILS,
  REMOVE_FIELD,
  MODAL_ISOPEN,
  HOLD_ACTION_ISOPEN,
  SKIP_ACTION_MODAL,
  RELEASE_ACTION_MODAL,
  REFETCH_ACTIONS,
  REFETCH_TIMELINE,
  REVIEW_PROMISE_TABLE_BUTTON,
  REVIEW_PROMISE_TABLE_POP_UP,
  WORKFLOW_ADD_MODAL,
  UPDATE_ATTACHMENTS,
  REMOVE_ATTACHED_FILES,
  TAB_MOTION,
  RESET_STATE,
  REMOVE_TAG,
  NOTE_ALERT,
  CO_ADD_ATTACHED_FILES,
  CO_REMOVE_ATTACHED_FILES,
  CO_ADHOC_REMOVE_ATTACHED_FILES,
  CO_ADHOC_ADD_ATTACHED_FILES,
  ACTIVE_WORKFLOW_DATA,
  CUSTOMER_ACTION_DATAS,
  UNASSIGN_WORKFLOW,
  SMS_GATEWAY_DATA,
  DRAWER_OPEN,
  DRAWER_CLOSE,
  EMAIL_DATA_TO_DRAWER,
  EMAIL_DRAWER_INITIAL,
  CUSTOMER_CONTACT_DATA,
  PROMISE_TO_PAY_DATA,
  DISPUTE_DATA,
  ADD_INVOICE_OPEN_MODAL,
  SUBSCRIPTIONS_DRAWER,
  ORGANATION_DTL,
} from "./action";
import { NEW, EDIT, VIEW, REPLY, REPLY_ALL, FORWARD } from "../../constants";
import moment from "moment";
import "moment-timezone";

// const newContactDefaultValue = {
//   phone: {
//     value: "",
//     type: "PERSONAL_MOBILE",
//     country_code: "",
//     status: "VALID",
//   },
//   email: {
//     value: "",
//     type: "PERSONAL_EMAIL",
//     status: "VALID",
//   },
//   address: {
//     line_1: "",
//     line_2: "",
//     state: "",
//     zip_code: "",
//     country: "US",
//     city: "",
//   },
//   landline: {
//     country_code: "",
//     type: "OFFICE_LANDLINE",
//     value: "",
//     status: "VALID",
//   },
// };

const initialContactFormValues = {
  first_name: "",
  last_name: "",
  designation: "",
  phone: [
    {
      value: "",
      type: "work",
      country_code: "+1",
      status: "",
    },
  ],
  email: [
    {
      value: "",
      type: "",
      status: "",
    },
  ],
  landline: [
    {
      country_code: "",
      type: "",
      value: "",
      status: "",
    },
  ],
  address: [
    {
      line_1: "",
      line_2: "",
      state: "",
      zip_code: "",
      country: "",
      city: "",
    },
  ],
  _id: "",
};

const initialCustomerDetails = {
  company_name: "",
  due_inv_amount: {
    value: 0,
    currrency: "",
  },
  overdue_inv_amount: {
    value: 0,
    currrency: "",
  },
  total_inv_count: 0,
  total_inv_amount: {
    value: 0,
    currrency: "",
  },
  workflow: {
    name: "",
  },
  alert_notes: [],
};

const initialEmailDrawer = {
  drawerType: "",
  emailType: "",
  emailFrom: [],
  emailTo: [],
  emailSubject: [],
  emailBody: "",
  emailCc: [],
  emailBcc: [],
};

const initialState = {
  type: "",
  isOpen: false,
  defaultContactData: { ...initialContactFormValues },
  editContactData: {},
  actionType: NEW,
  customerDetails: initialCustomerDetails,
  customerActions: [],
  isLoading: true,
  modalIsOpen: false,
  holdActionIsModal: false,
  skipActionIsModal: false,
  releaseActionIsModal: false,
  refetchActions: 0,
  refetchTimeline: 0,
  reviewModalData: [],
  reviewModalPopUp: false,
  addWorkflowIsModal: false,
  attachments: [],
  adhocAttachments: [],
  tabMotion: 0,
  noteAlert: false,
  activeWorkflow: {},
  onLoadCustomerActions: [],
  unAssign: 0,
  smsGateWay: {},
  emailDrawer: initialEmailDrawer,
  isDrawerOpen: false,
  emailType: "",
  emailFrom: [],
  emailTo: [],
  emailSubject: [],
  emailBody: "",
  emailCc: [],
  emailBcc: [],
  isReceiver: false,
  customerConatactDetails: [],
  isAddInvoiceModal: false,
  subscriptionsDrawerIsOpen: false,
  contactFieldData: {},
  orgDtl: {},
};

const customerOverviewReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SUBSCRIPTIONS_DRAWER: {
      return {
        ...state,
        subscriptionsDrawerIsOpen: payload,
      };
    }
    case ADD_INVOICE_OPEN_MODAL: {
      return {
        ...state,
        isAddInvoiceModal: payload,
      };
    }
    case SMS_GATEWAY_DATA: {
      return {
        ...state,
        smsGateWay: payload,
      };
    }

    case CUSTOMER_ACTION_DATAS: {
      return {
        ...state,
        onLoadCustomerActions: payload,
      };
    }

    case ACTIVE_WORKFLOW_DATA: {
      return {
        ...state,
        activeWorkflow: payload,
      };
    }

    case RESET_STATE: {
      return initialState;
    }

    case NOTE_ALERT: {
      return {
        ...state,
        noteAlert: payload,
      };
    }

    case CO_ADD_ATTACHED_FILES: {
      let attachments = [...state.attachments];

      if (Array.isArray(payload)) {
        attachments = [...attachments, ...payload];
      } else {
        attachments = [...attachments, payload];
      }

      attachments = [
        ...new Map(attachments.map((item) => [item.ref, item])).values(),
      ];

      return {
        ...state,
        attachments,
      };
    }

    case CO_REMOVE_ATTACHED_FILES: {
      let attachments = state.attachments.filter(
        (att) => att.ref == payload.ref
      );

      return {
        ...state,
        attachments,
      };
    }

    case CO_ADHOC_ADD_ATTACHED_FILES: {
      let attachments = [...state.adhocAttachments];

      if (Array.isArray(payload)) {
        attachments = [...attachments, ...payload];
      } else {
        attachments = [...attachments, payload];
      }

      attachments = [
        ...new Map(attachments.map((item) => [item.ref, item])).values(),
      ];

      return {
        ...state,
        adhocAttachments: attachments,
      };
    }

    case CO_ADHOC_REMOVE_ATTACHED_FILES: {
      let attachments = state.adhocAttachments.filter(
        (att) => att.ref == payload.ref
      );

      return {
        ...state,
        adhocAttachments: attachments,
      };
    }

    case REMOVE_TAG: {
      const tagDatas = state.customerDetails.tags;

      const filteredData = tagDatas.filter((i) => i != payload);

      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          tags: [...filteredData],
        },

        // tags:[...filteredData]
      };
    }

    case TAB_MOTION: {
      return {
        ...state,
        tabMotion: payload,
      };
    }

    case GET_CUSTOMER_DETAILS: {
      const { customer_actions, ...customerDetails } = payload;

      return {
        ...state,
        customerDetails,
        customerActions: customer_actions || [],
        isLoading: false,
        noteAlert:
          customerDetails.alert_notes.length <= 1 && state.noteAlert
            ? false
            : state.noteAlert,
      };
    }

    // UnAssignWorkflow
    case UNASSIGN_WORKFLOW: {
      const { unAssign } = state;
      return {
        ...state,
        unAssign: unAssign + 1,
      };
    }

    case REFETCH_ACTIONS: {
      const { refetchActions } = state;
      return {
        ...state,
        refetchActions: refetchActions + 1,
      };
    }

    case REFETCH_TIMELINE: {
      return {
        ...state,
        refetchTimeline: state.refetchTimeline + 1,
      };
    }

    //
    case WORKFLOW_ADD_MODAL: {
      return {
        ...state,
        addWorkflowIsModal: payload,
      };
    }

    case MODAL_ISOPEN: {
      return {
        ...state,
        modalIsOpen: payload,
      };
    }
    //CONTACT STARTS
    case ADD_CONTACT_MODAL: {
      return {
        ...state,
        isOpen: true,
        actionType: NEW,
      };
    }

    case CLOSE_CONTACT_MODAL: {
      return {
        ...state,
        defaultContactData: { ...initialContactFormValues },
        isOpen: false,
        actionType: VIEW,
      };
    }

    case ADD_EDIT_MODAL: {
      let editData = { ...payload };
      // if (editData.phone.length == 0) {
      //   editData.phone = [{}];
      // }
      // if (editData.email.length == 0) {
      //   editData.email = [{}];
      // }
      // if (editData.address.length == 0) {
      //   editData.address = [{}];
      // }
      // if (!editData?.landline?.length) {
      //   editData.landline = [{}];
      // }
      return {
        ...state,
        isOpen: true,
        actionType: EDIT,
        defaultContactData: { ...editData },
        contactFieldData: { ...editData },
      };
    }

    // case VIEW_CONTACT_MODAL: {
    //   let viewData = { ...payload };
    // if (viewData.phone.length == 0) {
    //   viewData.phone = [{}];
    // }
    // if (viewData.email.length == 0) {
    //   viewData.email = [{}];
    // }
    // if (viewData.address.length == 0) {
    //   viewData.address = [{}];
    // }
    //   return {
    //     ...state,
    //     isOpen: true,
    //     actionType: VIEW,
    //     defaultContactData: { ...viewData },
    //   };
    // }

    // case ADD_FIELDS: {
    //   // let newDetails = [
    //   //   ...state.defaultContactData[payload.type],
    //   //   payload.value,
    //   // ];
    //   let newDetails = [
    //     ...state.defaultContactData[payload],
    //     newContactDefaultValue[payload],
    //   ];

    //   return {
    //     ...state,
    //     // actionType: EDIT,
    //     defaultContactData: {
    //       ...state.defaultContactData,
    //       [payload]: newDetails,
    //     },
    //   };
    // }

    // case REMOVE_FIELD: {
    //   const newDetails = state.defaultContactData[payload.field].splice(
    //     payload.index,
    //     1
    //   );
    //   return {
    //     ...state,
    //     defaultContactData: {
    //       ...state.defaultContactData,
    //       [payload]: newDetails,
    //     },
    //   };
    // }

    case GET_ACTION_DATA:
      return {
        ...state,
        type: payload,
      };

    //CONTACT ENDS
    case HOLD_ACTION_ISOPEN:
      return {
        ...state,
        holdActionIsModal: payload,
      };
    // SkipAction Modal
    case SKIP_ACTION_MODAL:
      return {
        ...state,
        skipActionIsModal: payload,
      };
    // RelaseAction Modal
    case RELEASE_ACTION_MODAL:
      return {
        ...state,
        releaseActionIsModal: payload,
      };
    case REVIEW_PROMISE_TABLE_BUTTON:
      return {
        ...state,
        reviewModalData: payload,
      };
    case REVIEW_PROMISE_TABLE_POP_UP:
      return {
        ...state,
        reviewModalPopUp: payload,
      };

    case DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: true,
      };

    case DRAWER_CLOSE:
      return {
        ...state,
        isDrawerOpen: false,
      };

    case EMAIL_DATA_TO_DRAWER:
      let fromEmail = [];
      let toEmail = [];
      let bccEmail = [];
      let ccEmail = [];
      let subjectEmail = "";
      let bodyEmail = "";
      let receiver = false;

      if (payload.emailType == REPLY) {
        if (payload.isReceiver) {
          payload.emailData.to.map((e) => fromEmail.push(e.value));
          // fromEmail = payload.emailData.from[0].value;
          toEmail = [];
          subjectEmail = payload.emailData.subject;
          bodyEmail = `----------- <br/> ${payload.emailData.body}`;
          bccEmail = [];
          ccEmail = [];
          receiver = true;
        } else {
          payload.emailData.to.map((e) => fromEmail.push(e.value));
          toEmail = [];
          bccEmail = [];
          ccEmail = [];
          subjectEmail = payload.emailData.subject;
          bodyEmail = `----------- <br/> ${payload.emailData.body}`;
          receiver = false;
        }
      }
      if (payload.emailType == REPLY_ALL) {
        if (payload.isReceiver) {
          payload.emailData.to.map((e) => fromEmail.push(e.value));
          // fromEmail = payload.emailData.from;
          toEmail = [];
          subjectEmail = payload.emailData.subject;
          bodyEmail = `----------- <br/> ${payload.emailData.body}`;
          bccEmail = payload.emailData.bcc;
          ccEmail = payload.emailData.cc;
          receiver = true;
        } else {
          payload.emailData.to.map((e) => fromEmail.push(e.value));
          bccEmail = payload.emailData.bcc;
          ccEmail = payload.emailData.cc;
          toEmail = [];
          subjectEmail = payload.emailData.subject;
          bodyEmail = `----------- <br/> ${payload.emailData.body}`;
          receiver = false;
        }
      }
      if (payload.emailType == FORWARD) {
        fromEmail = [];
        toEmail = [];
        bccEmail = [];
        ccEmail = [];
        subjectEmail = payload.emailData.subject;
        bodyEmail = `----------- <br/> ${payload.emailData.body}`;
        receiver = false;
      }
      return {
        ...state,
        drawerType: "email",
        emailType: payload.emailType,
        emailFrom: fromEmail,
        emailTo: toEmail,
        emailSubject: subjectEmail,
        emailBody: bodyEmail,
        emailBcc: bccEmail,
        emailCc: ccEmail,
        isReceiver: receiver,
      };

    case EMAIL_DRAWER_INITIAL:
      return {
        ...state,
        ...initialEmailDrawer,
      };

    case PROMISE_TO_PAY_DATA:
      let promiseSubjectEmail = "";
      let promiseBodyEmail = "";
      let promiseReceiver = false;

      if (payload.emailType == FORWARD) {
        promiseSubjectEmail = payload.promiseSubject;
        promiseBodyEmail = payload.promiseBody;
        promiseReceiver = false;
      }
      return {
        ...state,
        drawerType: payload.drawerType,
        emailType: payload?.emailType,
        emailSubject: promiseSubjectEmail,
        emailBody: promiseBodyEmail,
        isReceiver: promiseReceiver,
      };

    //     case DISPUTE_DATA:
    //       let disputeSubjectEmail = "";
    //       let disputeBodyEmail = "";
    //       let disputeReceiver = false;

    //

    //       if (payload.emailType == FORWARD) {
    //         disputeSubjectEmail = `${payload.customerName} : Dispute : ${payload.emailData.name} on ${payload.promiseDate}`;

    //         disputeBodyEmail = `--------- <br />
    //         Hi,<br />
    //         <br />

    // Find below the details of the Dispute Raised by ${payload.customerName}<br />
    // Dispute Type: ${payload.emailData.content.dispute_type}<br />
    // Dispute Details: ${payload.emailData.content.dispute_details}<br />
    // Disputed Invoice Numbers: ${payload.invNumber}<br />
    // <br />
    // Dispute Status : ${payload.dispudeStatus}<br />
    // Dispute Review Date : ${payload.promiseDate}<br />
    // User Comments :  ${payload.emailData.comment}<br />
    // <br />
    // Regards<br />
    // ${payload.rmanager}`;
    //         disputeReceiver = false;
    //       }
    //       return {
    //         ...state,
    //         emailType: payload?.emailType,
    //         emailSubject: disputeSubjectEmail,
    //         emailBody: disputeBodyEmail,
    //         isReceiver: disputeReceiver,
    //       };

    case CUSTOMER_CONTACT_DATA:
      return {
        ...state,
        customerConatactDetails: payload.conatctData,
      };
    case ORGANATION_DTL:
      return {
        ...state,
        orgDtl: payload.orgDtl,
      };
    default:
      return state;
  }
};

export default customerOverviewReducer;
