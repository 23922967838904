import React from "react";

function SetWorkFlow({ size = "22px" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <g clip-path="url(#clip0_1414_8138)">
        <path
          d="M5.50002 7.33301L1.83335 10.9997L5.50002 14.6663L5.50002 11.9163L10.1384 11.9163C10.45 14.758 12.21 17.1597 14.6667 18.3788C14.7034 19.8822 15.9134 21.083 17.4167 21.083C18.9384 21.083 20.1667 19.8547 20.1667 18.333C20.1667 16.8113 18.9384 15.583 17.4167 15.583C16.5459 15.583 15.785 15.9955 15.2809 16.628C13.5392 15.6747 12.2834 13.9605 11.99 11.9163L14.8317 11.9163C15.2167 12.9797 16.225 13.7497 17.4167 13.7497C18.9384 13.7497 20.1667 12.5213 20.1667 10.9997C20.1667 9.47801 18.9384 8.24967 17.4167 8.24967C16.225 8.24967 15.2167 9.01967 14.8317 10.083L11.99 10.083C12.2834 8.03884 13.5392 6.32468 15.2717 5.37134C15.785 6.00384 16.5459 6.41634 17.4167 6.41634C18.9384 6.41634 20.1667 5.18801 20.1667 3.66634C20.1667 2.14468 18.9384 0.916342 17.4167 0.916342C15.9134 0.916342 14.7034 2.11718 14.6759 3.62051C12.2192 4.83968 10.4592 7.24134 10.1475 10.083L5.50002 10.083L5.50002 7.33301Z"
          fill="#333860"
        />
      </g>
      <defs>
        <clipPath id="clip0_1414_8138">
          <path
            d="M22 17C22 19.7614 19.7614 22 17 22L5 22C2.23858 22 1.95703e-07 19.7614 4.37114e-07 17L1.48619e-06 5C1.7276e-06 2.23857 2.23858 -1.7276e-06 5 -1.48619e-06L17 -4.37114e-07C19.7614 -1.95703e-07 22 2.23858 22 5L22 17Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SetWorkFlow;
