import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SIZE, SelectBox } from "../../../../components/SelectBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextBox } from "../../../../components/TextBox";
import ActionReminders from "../../../../components_v2/ActionReminders";
import {
  ACT_FIELD_VISIT,
  CUSTOMER_OVERVIEW_,
  FIELD_VISIT,
  GETTING_FIELD_VISIT_,
  NEW,
} from "../../../../constants";
import queryClient from "../../../../providers/queryClient";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useMemo } from "react";
import { KIND, TextButton } from "../../../../components/TextButton";
import getSelectValues from "../../../../utils/getSelectValues";
import moment from "moment";
import { useMutation } from "react-query";
import { editFieldVisit } from "../../../../services";
import setSelectValues from "../../../../utils/setSelectValues";

const FieldVisitEditForm = (props) => {
  let { setState, state, useFieldVistForm } = props;

  const intl = useIntl();
  const { customerId } = useParams();
  const { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const [isAutoLocation, setAutoLocation] = useState([]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const orgUsers = useMemo(() => {
    let orgBusiness = referenceData["users"].filter((e) => {
      return e?.organization?.includes(currentOrganization) && e && e.active;
    });
    return orgBusiness || [];
  }, []);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useFieldVistForm;

  let { address_ref } = watch();

  const locationOptions = useMemo(() => {
    _.get(customerData, "data.doc.contacts", []).reduce((prev, curr) => {
      let { phone, landline, email, address, ...rest } = curr;
      let nestedContacts = address
        .map(({ status, call_status, ...nRest }) => {
          let designation =
            referenceData && referenceData["recipient_type"]
              ? referenceData["recipient_type"].find(
                  (rf) => rf?.id === curr?.designation
                )
              : "";

          return {
            label: `${curr?.first_name ? curr?.first_name : ""} - ${
              nRest.line_1
            }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
              nRest.city
            }-${nRest.zip_code} ${designation?.label}`,
            id: nRest._id,
            contactId: curr?._id,
            designation: designation.id,
            status,
          };
        })
        .filter((e) => e.status === "VALID" || e.status === "LEAD");
      setAutoLocation([
        ...prev,
        ...nestedContacts,
        ...[
          { id: "organization", label: "Organization Address" },
          { id: "other", label: "Other Location" },
        ],
      ]);
      nestedContacts = [...prev, ...nestedContacts];
      return nestedContacts;
    }, []);
  }, [customerData]);

  useEffect(() => {
    if (state && state.captureData && state.captureData.address_ref) {
      let splitAddress = state.captureData.address_ref.split("/");
      if (splitAddress[0] && splitAddress[1]) {
        setValue("address_ref", [
          { id: splitAddress[1], contactId: splitAddress[0] },
        ]);
      }
    }
  }, []);

  const putFieldVisitCard = useMutation(
    (data) =>
      editFieldVisit({
        organization: currentOrganization,
        customerId: customerId,
        fieldVisitId: state.captureData._id,
        ...data,
      }),
    {
      onSuccess: (data, variables, context) => {
        reset();
        setState({ type: NEW });
        queryClient.refetchQueries([`${GETTING_FIELD_VISIT_}-${customerId}`]);
      },
    }
  );

  const onSubmit = async (data) => {
    let visitDate = moment(data.visit_date);
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));
    let values = {
      visit_date: moment
        .tz(
          `${visitDate.year()}-${visitDate.month() + 1}-${visitDate.date()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      visit_time: getSelectValues(data.visit_time),
      // address_ref: getSelectValues(data.address_ref),
      address_ref:
        data &&
        data.address_ref &&
        data.address_ref[0] &&
        data.address_ref[0].id &&
        data.address_ref[0].contactId
          ? `${data.address_ref[0].contactId}/${data.address_ref[0].id}`
          : getSelectValues(data.address_ref),
      ...(data &&
      data.custom_location &&
      data &&
      data.address_ref &&
      data.address_ref[0] &&
      data.address_ref[0].id === "other"
        ? { custom_location: data.custom_location }
        : {
            custom_location: "",
          }),
      assigned_to: getSelectValues(data.assigned_to),
      is_hold_workflow: data.is_hold_workflow,
      is_send_confirmation_message: data.is_send_confirmation_message,
      is_send_action_follow_up: data.is_send_action_follow_up,
      is_increment_follow_up: data.is_increment_follow_up,
      ...(data.is_send_confirmation_message
        ? {
            confirmation_message_type: getSelectValues(
              data.confirmation_message_type
            ),
            confirmation_message_template_id: getSelectValues(
              data.confirmation_message_template_id
            ),
          }
        : {}),
      ...(data.is_send_action_follow_up
        ? {
            action_follow_up_type: getSelectValues(data.action_follow_up_type),
            action_follow_up_template_id: getSelectValues(
              data.action_follow_up_template_id
            ),
          }
        : {}),
      mention_users: uniqueMentionedUsers,
    };
    await putFieldVisitCard.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          gap: "10px",
          // justifyContent: "space-between",
        }}
      >
        <div style={{ width: "150px" }}>
          <Controller
            defaultValues={[]}
            name="visit_time"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.compact}
                {...field}
                name={field.name}
                label={intl.formatMessage({
                  id: "visit_time",
                })}
                placeholder={intl.formatMessage({
                  id: "visit_time",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={
                  referenceData && referenceData["time"]
                    ? referenceData["time"]
                    : []
                }
              />
            )}
          />
        </div>

        <div style={{ width: "150px" }}>
          <Controller
            name="visit_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                clearable={false}
                size={SIZE.compact}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "visit_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>

        <div style={{ width: "200px" }}>
          <Controller
            name="assigned_to"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "assigned_to",
                })}
                placeholder={intl.formatMessage({
                  id: "assigned_to",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={orgUsers}
              />
            )}
          />
        </div>

        <div style={{ width: "310px" }}>
          <Controller
            name="address_ref"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.compact}
                {...field}
                name={field.name}
                // requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "location",
                })}
                placeholder={intl.formatMessage({
                  id: "location",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={isAutoLocation}
              />
            )}
          />
        </div>
      </div>

      {address_ref && address_ref[0] && address_ref[0].id === "other" ? (
        <>
          <Controller
            defaultValue={""}
            name="custom_location"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <>
                <TextBox
                  {...field}
                  // type="number"
                  size={SIZE.compact}
                  fullWidth
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "your_location",
                  })}
                  placeholder={intl.formatMessage({
                    id: "your_location",
                  })}
                  value={field.value}
                />
              </>
            )}
          />
        </>
      ) : (
        <></>
      )}

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={"call"}
        outcome={ACT_FIELD_VISIT}
        is_consent_enabled={false}
        is_calendar={false}
        is_valid_from_contact={true}
        is_inbound_call={true}
        is_comment={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // paddingTop: "25px",
        }}
      >
        <div
          style={{
            width: "80px",
            paddingRight: "10px",
          }}
        >
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              setState({ type: NEW });
            }}
          >
            Cancel
          </TextButton>
        </div>
        <div style={{ width: "80px" }}>
          <TextButton
            kind={KIND.primary}
            size={SIZE.mini}
            isLoading={isSubmitting}
            fullWidth
            type="submit"
          >
            Save
          </TextButton>
        </div>
      </div>
    </form>
  );
};

const FieldVistEditDataLayer = (props) => {
  let { state } = props;
  let {
    is_hold_workflow,
    address_ref,
    visit_time,
    is_send_action_follow_up,
    is_send_confirmation_message,
    visit_date,
    custom_location,
    confirmation_message_type,
    confirmation_message_template_id,
    action_follow_up_type,
    action_follow_up_template_id,
    comment,
    assigned_to,
  } = state.captureData;

  const useFieldVistForm = useForm({
    defaultValues: {
      ...(state &&
        state.captureData && {
          address_ref: [{ id: address_ref }],
          visit_time: [{ id: visit_time }],
          assigned_to: [{ id: assigned_to }],
          visit_date,
          custom_location,
          comment,
          is_hold_workflow,
          is_send_action_follow_up,
          is_send_confirmation_message,
          confirmation_message_type: [{ id: confirmation_message_type }],
          confirmation_message_template_id: setSelectValues(
            confirmation_message_template_id
          ),
          action_follow_up_type: [{ id: action_follow_up_type }],
          action_follow_up_template_id: setSelectValues(
            action_follow_up_template_id
          ),
        }),
    },
  });

  return (
    state &&
    state.captureData && (
      <FieldVisitEditForm {...props} useFieldVistForm={useFieldVistForm} />
    )
  );
};

export default FieldVistEditDataLayer;
