import React from "react";

const WhatsApp = ({ width = 22, height = 22, color = "#4CAF50" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0027 0H10.9973C4.93213 0 0 4.9335 0 11C0 13.4062 0.7755 15.6365 2.09413 17.4474L0.72325 21.5339L4.95138 20.1823C6.69075 21.3345 8.76562 22 11.0027 22C17.0679 22 22 17.0651 22 11C22 4.93488 17.0679 0 11.0027 0ZM17.4034 15.5334C17.138 16.2827 16.0847 16.9042 15.2446 17.0857C14.6699 17.2081 13.9191 17.3058 11.3919 16.258C8.15925 14.9188 6.0775 11.6339 5.91525 11.4207C5.75988 11.2076 4.609 9.68138 4.609 8.10288C4.609 6.52438 5.41063 5.75575 5.73375 5.42575C5.99913 5.15488 6.43775 5.03112 6.8585 5.03112C6.99462 5.03112 7.117 5.038 7.227 5.0435C7.55013 5.05725 7.71237 5.0765 7.9255 5.58663C8.19087 6.226 8.83712 7.8045 8.91412 7.96675C8.9925 8.129 9.07088 8.349 8.96088 8.56213C8.85775 8.78213 8.767 8.87975 8.60475 9.06675C8.4425 9.25375 8.2885 9.39675 8.12625 9.5975C7.97775 9.77213 7.81 9.95913 7.997 10.2823C8.184 10.5985 8.83025 11.6531 9.78175 12.5001C11.0096 13.5932 12.0051 13.9425 12.3612 14.091C12.6266 14.201 12.9429 14.1749 13.1367 13.9686C13.3829 13.7033 13.6867 13.2633 13.9961 12.8301C14.2161 12.5194 14.4939 12.4809 14.7854 12.5909C15.0824 12.694 16.654 13.4709 16.9771 13.6317C17.3003 13.794 17.5134 13.871 17.5917 14.0071C17.6687 14.1433 17.6687 14.7826 17.4034 15.5334Z"
        fill={color}
      />
    </svg>
  );
};

export default WhatsApp;
