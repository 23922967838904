import React from "react";

const Edit = ({ width = 13, height = 16, fill = "#333860" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12.5601V15.8654H2.70796L10.6946 6.11699L7.98667 2.8117L0 12.5601ZM2.1086 14.1026H1.44424V13.2917L7.98667 5.30609L8.65102 6.11699L2.1086 14.1026ZM12.7888 2.31811L11.099 0.255609C10.9546 0.0793269 10.7741 0 10.5863 0C10.3986 0 10.218 0.088141 10.0808 0.255609L8.75934 1.86859L11.4673 5.17388L12.7888 3.5609C13.0704 3.21715 13.0704 2.66186 12.7888 2.31811V2.31811Z"
        fill={fill}
      />
    </svg>
  );
};

export default Edit;
