import React from "react";

const DisputeSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.883 18.2998H11.713C12.1987 18.2998 12.596 18.6824 12.596 19.1498C12.596 19.6175 12.1987 20 11.713 20H2.883C2.39735 20 2 19.6175 2 19.1498C2 18.6824 2.39735 18.2998 2.883 18.2998ZM5.74393 7.30785L8.24283 4.90205L19.4834 15.724C20.1722 16.387 20.1722 17.4666 19.4834 18.1297C18.7946 18.7927 17.6734 18.7927 16.9846 18.1297L5.74393 7.30785ZM13.2406 2.49626L15.7396 4.90205C16.4283 5.56514 16.4283 6.64478 15.7396 7.30785L14.4857 8.51501L9.4967 3.7034L10.7417 2.50475C11.4304 1.83317 12.5519 1.83317 13.2406 2.49626ZM4.49889 8.5065L9.4967 13.3182L8.25166 14.5167C7.56291 15.1799 6.4415 15.1799 5.75276 14.5167L3.25386 12.1109C2.56512 11.4478 2.56512 10.3683 3.25386 9.70516L4.49889 8.5065Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default DisputeSvg;
