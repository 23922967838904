import React from "react";

const CheckTick = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.93556 11.1096L6.19324 9.36731C6.0894 9.26346 5.95888 9.21033 5.80166 9.20793C5.64445 9.20552 5.51152 9.25864 5.40287 9.36731C5.29422 9.47596 5.23989 9.60768 5.23989 9.76248C5.23989 9.91728 5.29422 10.049 5.40287 10.1577L7.46104 12.2158C7.59661 12.3514 7.75479 12.4192 7.93556 12.4192C8.11632 12.4192 8.27449 12.3514 8.41007 12.2158L12.5827 8.04324C12.6865 7.9394 12.7396 7.80888 12.742 7.65166C12.7444 7.49445 12.6913 7.36152 12.5827 7.25287C12.474 7.14422 12.3423 7.08989 12.1875 7.08989C12.0327 7.08989 11.901 7.14422 11.7923 7.25287L7.93556 11.1096ZM9.00124 16.625C8.01579 16.625 7.08951 16.438 6.22241 16.064C5.3553 15.69 4.60104 15.1824 3.95963 14.5413C3.3182 13.9001 2.81041 13.1462 2.43624 12.2795C2.06208 11.4128 1.875 10.4867 1.875 9.50124C1.875 8.51579 2.062 7.58951 2.436 6.72241C2.81 5.8553 3.31756 5.10104 3.95869 4.45963C4.59983 3.8182 5.35376 3.31041 6.22048 2.93624C7.08719 2.56208 8.01328 2.375 8.99873 2.375C9.98418 2.375 10.9105 2.562 11.7776 2.936C12.6447 3.31 13.3989 3.81756 14.0403 4.45869C14.6818 5.09983 15.1896 5.85376 15.5637 6.72048C15.9379 7.58719 16.125 8.51328 16.125 9.49873C16.125 10.4842 15.938 11.4104 15.564 12.2775C15.19 13.1447 14.6824 13.8989 14.0413 14.5403C13.4001 15.1818 12.6462 15.6896 11.7795 16.0637C10.9128 16.4379 9.98669 16.625 9.00124 16.625ZM8.99998 15.5C10.675 15.5 12.0937 14.9187 13.2562 13.7562C14.4187 12.5937 15 11.175 15 9.49998C15 7.82498 14.4187 6.40623 13.2562 5.24373C12.0937 4.08123 10.675 3.49998 8.99998 3.49998C7.32498 3.49998 5.90623 4.08123 4.74373 5.24373C3.58123 6.40623 2.99998 7.82498 2.99998 9.49998C2.99998 11.175 3.58123 12.5937 4.74373 13.7562C5.90623 14.9187 7.32498 15.5 8.99998 15.5Z"
        fill="#0FB158"
      />
    </svg>
  );
};

export default CheckTick;
