import moment from "moment";
import {
  BUSINESS_UNIT,
  CALL,
  CALLBACKLATER,
  CALLUNSUCCESSFUL,
  CALL_CALL_BACK_LATER,
  CALL_DISPUTE,
  CALL_UNSUCCESSFUL,
  CONTACTS,
  CUSTOMERPORTAL,
  CUSTOMERT_STATUS,
  DISPUTE,
  EMAIL,
  HOLDACTION,
  INVOICE,
  INVOICE_UPDATE,
  LETTER,
  NEXTACTION,
  NOTES,
  PROMISETOPAY,
  RELEASED,
  RM,
  SKIPPEDTIMELINE,
  SMS,
  WHATSAPP,
  WORKFLOW,
  PORTAL_PAYMENT,
  APP_PAYMENT,
  PAYMENT_PLAN,
  CALL_RECORDING,
  INSTANT_PAYMENT,
  PAYMENT,
  CUSTOMER_NAME_UPDATE,
  SKIP_TRACE,
} from "../../../constants";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { StatefulTooltip } from "baseui/tooltip";
import PromiseToPay from "../../../assets/img/svg/PromiseToPay";
import { NumericFormat } from "react-number-format";
import Comment from "../../../assets/img/svg/Comment";
import TimelineIcon from "./TimeLineIcon";
import PaymentProof from "../../../assets/img/svg/PaymentProof";
import { FormattedMessage } from "react-intl";
import { LabelLarge, LabelMedium, ParagraphSmall } from "baseui/typography";
import { useDispatch } from "react-redux";
import { ParagraphMedium } from "baseui/typography";
import _ from "lodash";

const CallOutcome = ({ data, callOutcome }) => {
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    userInfo,
  } = useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  const emailExecutedDate = moment
    .utc(data?.executed_on)
    .format(currentDefaultFormatDetails.date_format);
  let userRd = referenceData["users"];
  let userValue = userRd?.find((i) => i?.id === data?.executed_by);
  switch (callOutcome) {
    case PROMISETOPAY:
      const callPromiseToPayExecutedDate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      let promiseStatus =
        data && data?.content && data?.content?.promise_status;

      let promiseCurrency =
        data && data?.content && data?.content?.promise_amount?.currency;

      let promiseAmount =
        data && data?.content && data?.content?.promise_amount?.value;

      let disputeUserRd = referenceData["users"];
      let disputeUserValue = disputeUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      const disputeByDate = moment
        .utc(data?.content?.promise_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {callPromiseToPayExecutedDate}
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <PromiseToPay className="svg-primary-fill" />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {promiseStatus === "IN_PROGRESS" ? (
              <div
                style={{
                  color: "#8b8a8a",
                }}
              >
                Promise - InProgress
              </div>
            ) : promiseStatus === "PARTIAL" ? (
              <div
                style={{
                  color: "#FFBB00",
                }}
              >
                Promise - Partial
              </div>
            ) : promiseStatus === "BROKEN" ? (
              <div
                style={{
                  color: "#E90E0E",
                }}
              >
                Promise - Broken
              </div>
            ) : promiseStatus === "KEPT" ? (
              <div
                style={{
                  color: "#48B24F",
                }}
              >
                Promise - Kept
              </div>
            ) : promiseStatus === "CANCELED" ? (
              <div
                style={{
                  color: "#E90E0E",
                }}
              >
                Promise - Cancelled
              </div>
            ) : (
              <div
                style={{
                  color: "#48B24F",
                }}
              >
                Promise
              </div>
            )}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
              // paddingRight: "5px",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Promised Payment of
                  <span style={{ padding: "0px 3px" }}>
                    {promiseCurrency}
                  </span>{" "}
                  <NumericFormat
                    displayType="text"
                    value={promiseAmount ? Number(promiseAmount) : 0}
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => <div>{value}</div>}
                  />{" "}
                  <span style={{ padding: "0px 3px" }}>by</span> {disputeByDate}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                Promised Payment of
                <span style={{ padding: "0px 3px" }}>
                  {promiseCurrency}
                </span>{" "}
                <NumericFormat
                  displayType="text"
                  value={promiseAmount ? Number(promiseAmount) : 0}
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(value) => <div>{value}</div>}
                />{" "}
                <span style={{ padding: "0px 3px" }}>by</span> {disputeByDate}
              </div>
            </StatefulTooltip>
          </div>

          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
              width: "130px",
            }}
          >
            {data && data?.executed_by
              ? disputeUserValue && disputeUserValue?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );

    case CALL_UNSUCCESSFUL:
      const callUnsuccessfulExecutedDate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      const callUnsuccessfullFollowUpDate = moment
        .utc(data?.content?.followup_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      let callUnsuccessFullUserRd = referenceData["users"];
      let callUnsucessFullUser = callUnsuccessFullUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      let callUnsuccessReason = data?.content?.call_unsuccessful_reason
        ? referenceData["call_unsuccessful_reason"].find(
            ({ id }) => id == data?.content?.call_unsuccessful_reason
          )
        : [];

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {callUnsuccessfulExecutedDate}
          </div>

          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
            // style={{ flex: "1", textAlign: "center" }}
          >
            <TimelineIcon type={CALLUNSUCCESSFUL} />
          </div>
          <div
            // style={{ flex: "1", color: "#E90E0E" }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            Unsuccessfull
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {data && data?.content && data?.content?.followup_date ? (
                    <div>Follow-up Call on {callUnsuccessfullFollowUpDate}</div>
                  ) : (
                    <div>
                      Reason -{" "}
                      {callUnsuccessReason?.label
                        ? callUnsuccessReason?.label
                        : data &&
                          data?.content &&
                          data?.content?.call_unsuccessful_reason}
                    </div>
                  )}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.content && data?.content?.followup_date ? (
                  <div>Follow-up Call on {callUnsuccessfullFollowUpDate}</div>
                ) : (
                  <div>
                    Reason -{" "}
                    {callUnsuccessReason
                      ? callUnsuccessReason?.label
                      : data &&
                        data?.content &&
                        data?.content?.call_unsuccessful_reason}
                  </div>
                )}
              </div>
            </StatefulTooltip>
          </div>

          <div style={{ textAlign: "center" }}>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div style={{ width: "130px", textAlign: "center" }}>
            {data && data.executed_by
              ? callUnsucessFullUser && callUnsucessFullUser?.label
              : "EXECUTED"}
          </div>
        </div>
      );

    case CALL_DISPUTE:
      const callDisputeExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const callDisputeContent = data && data?.content;

      const callDisputeReviewStatus = moment
        .utc(callDisputeContent?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      let callDisputeUserRd = referenceData["users"];
      let callDisputeUserValue = callDisputeUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      // const findingValue = refType?.find(
      //   (i) => i.id === disputeVal.content.dispute_type
      // );
      //

      const findingValue =
        callDisputeContent && callDisputeContent.dispute_type
          ? referenceData["dispute_type"]?.find(
              (i) => i.id === callDisputeContent.dispute_type
            )
          : [];

      //
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {callDisputeExecutedOn}
          </div>
          <div
            //  style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={DISPUTE} />
          </div>
          <div
            // style={{
            //   flex: "1",
            // }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {callDisputeContent.dispute_status === "CLOSED" ? (
              <div
                style={{
                  color: "#333860",
                }}
              >
                Dispute - Closed
              </div>
            ) : callDisputeContent.dispute_status === "OPEN" ? (
              <div
                style={{
                  // color: "#333860",
                  paddingLeft: "2px",
                }}
              >
                Dispute - Open
              </div>
            ) : (
              <div
                style={{
                  color: "#333860",
                }}
              >
                Dispute - In Progress
              </div>
            )}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {" "}
                  Dispute Reason -{" "}
                  {findingValue?.label ? findingValue?.label : "-"}
                  and Review Status by {callDisputeReviewStatus} <span>(</span>
                  {callDisputeContent &&
                    callDisputeContent?.dispute_invoices &&
                    callDisputeContent?.dispute_invoices?.length}
                  <span> invoices )</span>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Dispute Reason -{" "}
                {findingValue?.label ? findingValue?.label : "-"}&nbsp; and
                Review Status by {callDisputeReviewStatus}
                <span>(</span>
                {callDisputeContent &&
                  callDisputeContent?.dispute_invoices &&
                  callDisputeContent?.dispute_invoices?.length}
                <span> invoices )</span>
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
              width: "130px",
            }}
          >
            {data && data?.executed_by
              ? callDisputeUserValue && callDisputeUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case CALL_CALL_BACK_LATER:
      const callBackLaterExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let callBackTime =
        referenceData["time"] &&
        referenceData["time"].find(
          (e) => e.id === data?.content?.callback_time
        );

      const callBackDate = moment
        .utc(data?.content?.callback_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      let callBackUserRd = referenceData["users"];
      let callBackUserValue = callBackUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}

            style={{ paddingRight: "14px" }}
          >
            {callBackLaterExecutedOn}
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={CALLBACKLATER} />
          </div>
          <div
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
              color: "#48B24F",
            }}

            // style={{ flex: "1", color: "#48B24F" }}
          >
            Call Back Later
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Call Back Later{" "}
                  <span
                  // className="cp-discretion__text-content__weight"
                  >
                    on {callBackDate}{" "}
                    {callBackTime && callBackTime.label
                      ? `at ${callBackTime.label}`
                      : ""}{" "}
                  </span>{" "}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {/* {data.is_receiver ? "Portal" : "Call"}: */}
                Call Back Later{" "}
                <span
                // className="cp-discretion__text-content__weight"
                >
                  on {callBackDate}{" "}
                  {callBackTime && callBackTime.label
                    ? `at ${callBackTime.label}`
                    : ""}{" "}
                </span>{" "}
              </div>
            </StatefulTooltip>
          </div>

          <div
            //  style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              //  flex: "1", textAlign: "center"
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? callBackUserValue && callBackUserValue?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );

    case PAYMENT_PLAN:
      const callPaymentPlan = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let paymentPlanUser = referenceData["users"];
      let customerLabel = paymentPlanUser?.find(
        (i) => i?.id === data?.executed_by
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}

            style={{ paddingRight: "14px" }}
          >
            {callPaymentPlan}
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <PromiseToPay className="svg-primary-fill" />
          </div>
          <div
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "121px",
              color: "#000",
            }}

            // style={{ flex: "1", color: "#48B24F" }}
          >
            Payment Plan
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "1",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data.content.payment_plan_frequency
                ? data.content.payment_plan_frequency
                : "-"}
              &nbsp;Payment Plan with Installment amount{" "}
              {data?.content?.payment_plan_instalation_amount?.currency
                ? data?.content?.payment_plan_instalation_amount?.currency
                : "-"}
              &nbsp;
              {data?.content?.payment_plan_instalation_amount?.value ? (
                <NumericFormat
                  displayType="text"
                  value={
                    data?.content?.payment_plan_instalation_amount?.value
                      ? Number(
                          data?.content?.payment_plan_instalation_amount?.value
                        )
                      : 0
                  }
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(value) => <>{value}</>}
                />
              ) : (
                "-"
              )}
              &nbsp;starting from{" "}
              {moment(data?.content?.payment_plan_start_date).format(
                "Do-MMM-yyyy"
              )}{" "}
              till{" "}
              {moment(data?.content?.payment_plan_end_date).format(
                "Do-MMM-yyyy"
              )}
            </div>
          </div>

          <div
            //  style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            {data?.comment ? (
              <StatefulTooltip
                content={() => (
                  <div
                    style={{
                      maxWidth: "220px",
                    }}
                  >
                    {data?.comment ? data.comment : ""}
                  </div>
                )}
                returnFocus
                autoFocus
              >
                <div>
                  <Comment />
                </div>
              </StatefulTooltip>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              //  flex: "1", textAlign: "center"
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? customerLabel && customerLabel?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );

    case PAYMENT: {
      const instantPaymentExecutedOn = moment
        .utc(data?.content?.portal_payment_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      const instantPaymentContent = data && data?.content;
      let instantPaymentUserRd = referenceData["users"];
      let instantPaymentUserValue = instantPaymentUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {instantPaymentExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={APP_PAYMENT} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: data && data.action_taken == "Success" ? "#12b112" : "Red",
            }}
          >
            Payment {data && data.action_taken}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Call -{" "}
                  {data &&
                  data.content &&
                  data.content.portal_payment_gateway_name
                    ? data.content.portal_payment_gateway_name
                    : "-"}{" "}
                  Amount:{" "}
                  {data && data?.content?.portal_payment_amount?.currency}{" "}
                  {data && data?.content?.portal_payment_amount?.value}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                Call -{" "}
                {data &&
                data.content &&
                data.content.portal_payment_gateway_name
                  ? data.content.portal_payment_gateway_name
                  : "-"}{" "}
                Amount: {data && data?.content?.portal_payment_amount?.currency}{" "}
                {data && data?.content?.portal_payment_amount?.value}
                {/* Payment Via - - {data?.action_taken} */}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? instantPaymentUserValue && instantPaymentUserValue?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );
    }
    // case PAYMENT: {
    case "PROTALPAYMENT": {
      const instantPaymentExecutedOn = moment
        .utc(data?.content?.portal_payment_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      const instantPaymentContent = data && data?.content;
      let instantPaymentUserRd = referenceData["users"];
      let instantPaymentUserValue = instantPaymentUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {instantPaymentExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={APP_PAYMENT} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: data && data.action_taken == "Success" ? "#12b112" : "Red",
            }}
          >
            Payment {data && data.action_taken}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Call -{" "}
                  {data &&
                  data.content &&
                  data.content.portal_payment_gateway_name
                    ? data.content.portal_payment_gateway_name
                    : "-"}{" "}
                  Amount:{" "}
                  {data && data?.content?.portal_payment_amount?.currency}{" "}
                  {data && data?.content?.portal_payment_amount?.value}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                Call -{" "}
                {data &&
                data.content &&
                data.content.portal_payment_gateway_name
                  ? data.content.portal_payment_gateway_name
                  : "-"}{" "}
                Amount: {data && data?.content?.portal_payment_amount?.currency}{" "}
                {data && data?.content?.portal_payment_amount?.value}
                {/* Payment Via - - {data?.action_taken} */}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? instantPaymentUserValue && instantPaymentUserValue?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );
    }
    case "FIELD_VISIT":
      let rdTime = referenceData && referenceData["time"];

      let visitedTime = rdTime?.find(
        (e) => e?.id === data?.content?.visit_time
      );
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div
              //  style={{ flex: "1" }}
              style={{ paddingRight: "14px" }}
            >
              {emailExecutedDate}
            </div>
            <div
              style={{
                //  flex: "1",
                textAlign: "center",
              }}
            >
              <TimelineIcon type={"FIELD_VISIT"} />
            </div>
            <div
              style={{
                //  flex: "1"
                paddingLeft: "7px",
                width: "130px",
              }}
            >
              Field Visit
            </div>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  Field Visit on{" "}
                  {moment(data?.content?.visit_date)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)}{" "}
                  {visitedTime && visitedTime.label ? visitedTime.label : ""}
                  &nbsp;
                  {data?.content?.visit_address?.line_1}&nbsp;
                  {data?.content?.visit_address?.line_2}&nbsp;
                  {data?.content?.visit_address?.city},
                  {data?.content?.visit_address?.state}&nbsp;
                  {data?.content?.visit_address?.country}
                  {data?.content?.custom_location}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: "2",
                }}
              >
                <ParagraphSmall className="fielvisitaddress">
                  Field Visit on{" "}
                  {moment(data?.content?.visit_date)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)}{" "}
                  {visitedTime && visitedTime.label ? visitedTime.label : ""}
                  &nbsp;
                  {data?.content?.visit_address?.line_1}&nbsp;
                  {data?.content?.visit_address?.line_2}&nbsp;
                  {data?.content?.visit_address?.city},
                  {data?.content?.visit_address?.state}&nbsp;
                  {data?.content?.visit_address?.country}
                  {data?.content?.custom_location}
                </ParagraphSmall>
              </div>
            </StatefulTooltip>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? userValue && userValue?.label
                : "SYSTEM"}
            </div>
          </div>
        </>
      );

    default:
      return null;
  }
};

const DeliveryStatus = ({ type }) => {
  const commonStyles = {
    fontSize: "12px",
    padding: "5px",
    borderBottomLeftRadius: "5px",
    borderTopRightRadius: "5px",
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: 0,
    background: "#FFFBE980",
    border: "1px solid #FFD93D",
    color: "#FFD93D",
    display: "flex",
    justifyContent: "center",
    gap: 3,
  };

  switch (type) {
    case "PARTIALLY_DELIVERED_AND_READ":
      return (
        <div style={{}}>
          Partially Delivered and Read
          {/* <InfoIcon color="#FFD93D" size={15} /> */}
        </div>
      );

    case "PARTIALLY_DELIVERED":
      return (
        <div style={{}}>
          Partially Delivered
          {/* <InfoIcon color="#FFD93D" size={15} /> */}
        </div>
      );

    case "BOUNCED":
      return (
        <div
          style={{
            // background: "#FFEBEB",
            // border: "1px solid #E90E0E",
            color: "#E90E0E",
          }}
        >
          Bounced
          {/* <InfoIcon color="#E90E0E" size={15} /> */}
        </div>
      );

    case "FAILED":
      return (
        <div
          style={{
            // background: "#FFEBEB",
            // border: "1px solid #E90E0E",
            color: "#E90E0E",
          }}
        >
          Failed
          <span style={{ color: "#ffffff" }}>.....</span>
          {/* <InfoIcon color="#E90E0E" size={15} /> */}
        </div>
      );

    case "FAILURE":
      return (
        <div
          style={{
            // background: "#FFEBEB",
            // border: "1px solid #E90E0E",
            color: "#E90E0E",
          }}
        >
          Failed
          <span style={{ color: "#ffffff" }}>.....</span>
          {/* <InfoIcon color="#E90E0E" size={15} /> */}
        </div>
      );

    case "DELIVERED":
      return (
        <div
          style={{
            // background: "#F0FFF1",
            // border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          Delivered
          {/* <InfoIcon color="#48B24F" size={15} /> */}
        </div>
      );

    case "SUCCESS":
      return (
        <div
          style={{
            // background: "#F0FFF1",
            // border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          Delivered
          {/* <InfoIcon color="#48B24F" size={15} /> */}
        </div>
      );

    case "IN_PROGRESS":
      return (
        <div
          style={{
            // background: "#F0FFF1",
            // border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          In Progress
          {/* <InfoIcon color="#48B24F" size={15} /> */}
        </div>
      );

    case "READ":
      return (
        <div
          style={{
            // background: "#F0FFF1",
            // border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          Read
        </div>
      );
    case "SENT":
      return (
        <div
          style={{
            // background: "#F0FFF1",
            // border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          Sent
          {/* <InfoIcon color="#48B24F" size={15} /> */}
        </div>
      );

    default:
      return null;
  }
};
const FieldVisit = ({ data, type }) => {
  let {
    currentOrganization,
    currentDefaultFormatDetails,
    referenceData,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const emailExecutedDate = moment
    .utc(data?.executed_on)
    .format(currentDefaultFormatDetails.date_format);
  let userRd = referenceData["users"];
  let userValue = userRd?.find((i) => i?.id === data?.executed_by);
  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;
  switch (type) {
    case "RETURN_VISIT":
      let returnVisit =
        referenceData &&
        referenceData["time"]?.find(
          (e) => e?.id === data?.content?.return_visit_time
        );
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div
              //  style={{ flex: "1" }}
              style={{ paddingRight: "14px" }}
            >
              {emailExecutedDate}
            </div>
            <div
              style={{
                //  flex: "1",
                textAlign: "center",
              }}
            >
              <TimelineIcon type={"FIELD_VISIT"} />
            </div>
            <div
              style={{
                //  flex: "1"
                paddingLeft: "7px",
                width: "130px",
              }}
            >
              {data?.name}
            </div>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  Field Visit Date on{" "}
                  {moment(data?.content?.return_visit_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                  &nbsp;{returnVisit.label}&nbsp;
                  {data?.content?.visit_address?.line_1}&nbsp;
                  {data?.content?.visit_address?.line_2}&nbsp;
                  {data?.content?.visit_address?.city},
                  {data?.content?.visit_address?.state}&nbsp;
                  {data?.content?.visit_address?.country}
                  {data?.content?.custom_location}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: "2",
                }}
              >
                <ParagraphSmall className="fielvisitaddress">
                  Field Visit Date on{" "}
                  {moment(data?.content?.return_visit_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                  &nbsp;{returnVisit.label}&nbsp;
                  {data?.content?.visit_address?.line_1}&nbsp;
                  {data?.content?.visit_address?.line_2}&nbsp;
                  {data?.content?.visit_address?.city},
                  {data?.content?.visit_address?.state}&nbsp;
                  {data?.content?.visit_address?.country}
                  {data?.content?.custom_location}
                </ParagraphSmall>
              </div>
            </StatefulTooltip>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>

            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? userValue && userValue?.label
                : "SYSTEM"}
            </div>
          </div>
        </>
      );

    case "PROMISE_TO_PAY":
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div
              //  style={{ flex: "1" }}
              style={{ paddingRight: "14px" }}
            >
              {emailExecutedDate}
            </div>
            <div
              style={{
                //  flex: "1",
                textAlign: "center",
              }}
            >
              <PromiseToPay className="svg-primary-fill" />
            </div>
            <div
              style={{
                //  flex: "1"
                paddingLeft: "4px",
                width: "130px",
              }}
            >
              Promise -{" "}
              {data?.content?.promise_status ? (
                <FormattedMessage id={data?.content?.promise_status}>
                  {data?.content?.promise_status}
                </FormattedMessage>
              ) : (
                ""
              )}
            </div>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  FV - Promise Payment of{" "}
                  {data?.content?.promise_amount?.currency}&nbsp;
                  {data?.content?.promise_amount?.value} by&nbsp;
                  {moment(data?.content?.promise_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: "2",
                }}
              >
                <ParagraphSmall className="fielvisitaddress">
                  FV - Promise Payment of{" "}
                  {data?.content?.promise_amount?.currency}&nbsp;
                  {data?.content?.promise_amount?.value} by&nbsp;
                  {moment(data?.content?.visit_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                </ParagraphSmall>
              </div>
            </StatefulTooltip>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? userValue && userValue?.label
                : "SYSTEM"}
            </div>
          </div>
        </>
      );

    case "CALL_BACK_LATER":
      let visitedTime =
        referenceData &&
        referenceData["time"]?.find(
          (e) => e?.id === data?.content?.callback_time
        );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {emailExecutedDate}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={CALLBACKLATER} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {data?.name}
          </div>
          <StatefulTooltip
            content={() => (
              <div
                style={{
                  maxWidth: "220px",
                }}
              >
                FV - Call Back Later on&nbsp;
                {moment(data?.content?.callback_date)
                  .tz(timeZone)
                  .format(currentDefaultFormatDetails.date_format)}
                &nbsp;at&nbsp;
                {visitedTime && visitedTime.label ? visitedTime.label : ""}
              </div>
            )}
            returnFocus
            autoFocus
          >
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: "2",
              }}
            >
              <ParagraphSmall className="fielvisitaddress">
                FV - Call Back Later on&nbsp;
                {moment(data?.content?.callback_date)
                  .tz(timeZone)
                  .format(currentDefaultFormatDetails.date_format)}
                &nbsp;at&nbsp;
                {visitedTime && visitedTime.label ? visitedTime.label : ""}
              </ParagraphSmall>
            </div>
          </StatefulTooltip>
          <StatefulTooltip
            content={() => (
              <div
                style={{
                  maxWidth: "220px",
                }}
              >
                {data?.comment ? data?.comment : ""}
              </div>
            )}
            returnFocus
            autoFocus
          >
            {data?.comment ? (
              <div>
                <Comment />
              </div>
            ) : (
              ""
            )}
          </StatefulTooltip>
          <div
            style={{
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? userValue && userValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case "RECORD_DISPUTE":
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div
              //  style={{ flex: "1" }}
              style={{ paddingRight: "14px" }}
            >
              {emailExecutedDate}
            </div>
            <div
              style={{
                //  flex: "1",
                textAlign: "center",
              }}
            >
              <TimelineIcon type={DISPUTE} />
            </div>
            <div
              style={{
                //  flex: "1"
                paddingLeft: "7px",
                width: "130px",
              }}
            >
              Dispute -
              {data?.content?.dispute_status ? (
                <FormattedMessage id={data?.content?.dispute_status}>
                  {data?.content?.dispute_status}
                </FormattedMessage>
              ) : (
                <></>
              )}
            </div>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  FV - Dispute Reason - {data?.content?.dispute_type} and Review
                  Status&nbsp;
                  {moment(data?.content?.dispute_review_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: "2",
                }}
              >
                <ParagraphSmall className="fielvisitaddress">
                  FV - Dispute Reason - {data?.content?.dispute_type} and Review
                  Status&nbsp;
                  {moment(data?.content?.dispute_review_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                </ParagraphSmall>
              </div>
            </StatefulTooltip>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? userValue && userValue?.label
                : "SYSTEM"}
            </div>
          </div>
        </>
      );

    case "PAYMENT":
      let labelpay = referenceData["payment_method"];
      let method = labelpay.find(
        (i) => i.id == data?.content?.portal_payment_method
      );

      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div style={{ paddingRight: "14px" }}>{emailExecutedDate}</div>
            <div
              style={{
                // flex: "1",
                textAlign: "center",
              }}
            >
              <TimelineIcon type={"FIELD_VISIT"} />
            </div>

            <div
              style={{
                //  flex: "1"
                paddingLeft: "7px",
                width: "130px",
              }}
            >
              Payment {data && data.action_taken}
            </div>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  FV - {method && method.label ? method.label : ""} :{" "}
                  {data?.content?.portal_payment_amount?.currency}&nbsp;
                  {data?.content?.portal_payment_amount?.value}{" "}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: "2",
                }}
              >
                <ParagraphSmall className="fielvisitaddress">
                  FV - {method && method.label ? method.label : ""} :{" "}
                  {data?.content?.portal_payment_amount?.currency}&nbsp;
                  {data?.content?.portal_payment_amount?.value}
                </ParagraphSmall>
              </div>
            </StatefulTooltip>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? userValue && userValue?.label
                : "SYSTEM"}
            </div>
          </div>{" "}
        </>
      );

    case "PAYMENT_PLAN":
      let labelpaymentplan = referenceData["payment_method"];
      let methodId = labelpaymentplan.find(
        (i) => i.id == data?.content?.portal_payment_method
      );
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div style={{ paddingRight: "14px" }}>{emailExecutedDate}</div>
            <div
              style={{
                // flex: "1",
                textAlign: "center",
              }}
            >
              <PromiseToPay className="svg-primary-fill" />
            </div>

            <div
              style={{
                //  flex: "1"
                paddingLeft: "7px",
                width: "130px",
              }}
            >
              Payment Plan
            </div>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  FV -{" "}
                  {data.content.payment_plan_frequency
                    ? data.content.payment_plan_frequency
                    : "-"}
                  &nbsp;Payment Plan with Installment amount{" "}
                  {data?.content?.payment_plan_instalation_amount?.currency
                    ? data?.content?.payment_plan_instalation_amount?.currency
                    : "-"}
                  &nbsp;
                  {data?.content?.payment_plan_instalation_amount?.value ? (
                    <NumericFormat
                      displayType="text"
                      value={
                        data?.content?.payment_plan_instalation_amount?.value
                          ? Number(
                              data?.content?.payment_plan_instalation_amount
                                ?.value
                            )
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => <>{value}</>}
                    />
                  ) : (
                    "-"
                  )}
                  &nbsp;starting from{" "}
                  {moment(data?.content?.payment_plan_start_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}{" "}
                  till{" "}
                  {moment(data?.content?.payment_plan_end_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flex: "2",
                }}
              >
                <ParagraphSmall className="fielvisitaddress">
                  FV -{" "}
                  {data.content.payment_plan_frequency
                    ? data.content.payment_plan_frequency
                    : "-"}
                  &nbsp;Payment Plan with Installment amount{" "}
                  {data?.content?.payment_plan_instalation_amount?.currency
                    ? data?.content?.payment_plan_instalation_amount?.currency
                    : "-"}
                  &nbsp;
                  {data?.content?.payment_plan_instalation_amount?.value ? (
                    <NumericFormat
                      displayType="text"
                      value={
                        data?.content?.payment_plan_instalation_amount?.value
                          ? Number(
                              data?.content?.payment_plan_instalation_amount
                                ?.value
                            )
                          : 0
                      }
                      thousandsGroupStyle={currencyGroupStyle}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={currencySeparator}
                      decimalSeparator={currencyDecimalSeparator}
                      renderText={(value) => <>{value}</>}
                    />
                  ) : (
                    "-"
                  )}
                  &nbsp;starting from{" "}
                  {moment(data?.content?.payment_plan_start_date).format(
                    "Do-MMM-yyyy"
                  )}{" "}
                  till{" "}
                  {moment(data?.content?.payment_plan_end_date).format(
                    "Do-MMM-yyyy"
                  )}
                </ParagraphSmall>
              </div>
            </StatefulTooltip>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? userValue && userValue?.label
                : "SYSTEM"}
            </div>
          </div>{" "}
        </>
      );

    case "UNSUCCESSFULL":
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {emailExecutedDate}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={"FIELD_VISIT"} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: data && data.action_taken == "Success" ? "#12b112" : "Red",
            }}
          >
            {data?.name}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <ParagraphSmall className="fielvisitaddress">
              Field Visit Date on{" "}
              {moment(data?.content?.visit_date)
                .tz(timeZone)
                .format(currentDefaultFormatDetails.date_format)}
              &nbsp;
              {data?.content?.visit_address?.line_1}&nbsp;
              {data?.content?.visit_address?.line_2}&nbsp;
              {data?.content?.visit_address?.city},
              {data?.content?.visit_address?.state}&nbsp;
              {data?.content?.visit_address?.country}
              {data?.content?.custom_location}
            </ParagraphSmall>
          </div>
          <div
            style={{
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? userValue && userValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    default:
      return null;
  }
};
const TimelineTableMap = ({ data, getParams, customerId, key }) => {
  let {
    currentOrganization,
    currentDefaultFormatDetails,
    referenceData,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    userInfo,
  } = useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let type = data && data?.type;

  switch (type) {
    case EMAIL:
      const emailExecutedDate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      let userRd = referenceData["users"];
      let userValue = userRd?.find((i) => i?.id === data?.executed_by);
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div style={{ paddingRight: "14px" }}>{emailExecutedDate}</div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={EMAIL} />
          </div>

          <div
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {data && data?.is_sender ? (
              <>
                <DeliveryStatus type={data?.deliverablity_status} />
              </>
            ) : (
              <>
                <div
                  style={{
                    color: "#48B24F",
                  }}
                >
                  Email Reply
                </div>
              </>
            )}
          </div>
          <div style={{ flex: "2" }}>{data?.name}</div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              //  flex: "1",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? userValue && userValue?.label
              : "SYSTEM"}
            {/* {userValue?.label} */}
          </div>
        </div>
      );

    case SMS:
      const smsExecutedDate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      const smsContent = data && data?.content;
      let recepients = smsContent?.to?.map((e) => e?.status);
      let receientsFailed = recepients?.includes("FAILURE");

      let smsUserRd = referenceData["users"];
      let smsUserValue = smsUserRd?.find((i) => i?.id === data?.executed_by);
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {smsExecutedDate}
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={SMS} />
          </div>
          <div
            // style={{ flex: "1" }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {/* {receientsFailed ? (
              <DeliveryStatus type={"FAILED"} />
            ) : ( */}
            {data.is_receiver ? (
              "SMS Reply"
            ) : (
              <DeliveryStatus type={recepients && recepients[0]} />
            )}

            {/* )} */}
          </div>
          {/* <div style={{ flex: "2" }}>
          {data.is_receiver}
            {data?.name}</div> */}
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {data?.is_receiver
                    ? data && data?.content && data?.content?.body
                    : data && data?.name && data?.name}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  gap: "10px",
                }}
              >
                {data?.is_receiver
                  ? data && data?.content && data?.content?.body
                  : data && data?.name && data?.name}
                {data?.is_receiver && data.is_updated_contact_invalid && (
                  <div
                    style={{
                      backgroundColor:
                        data && data.update_contact_status_type === "VALID"
                          ? "#4CAF5050"
                          : "#ffefee",
                      color:
                        data && data.update_contact_status_type === "VALID"
                          ? "#4CAF50"
                          : "#FD372A",
                      fontSize: "10px",
                      padding: "2px 5px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                  >
                    {data && data.update_contact_status_type
                      ? data.update_contact_status_type
                      : "-"}
                  </div>
                )}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1", textAlign: "center"
              //  flex: "1",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? smsUserValue && smsUserValue?.label
              : "SYSTEM"}
            {/* {smsUserValue?.label} */}
          </div>{" "}
        </div>
      );

    case HOLDACTION:
      const holdActionExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const holdActionContent = data && data?.content;

      const holdActionTill = moment
        .utc(holdActionContent?.hold_action_till)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      let holdActionUserRd = referenceData["users"];
      let holdActionUserValue = holdActionUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      let reason = referenceData["hold_action_reason"];
      let filteringValue = reason?.filter(
        (i) => i.id === holdActionContent.hold_reason
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            // height: "50px",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {holdActionExecutedOn}
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={HOLDACTION} />
          </div>
          <div
            // style={{ flex: "1", color: "#E90E0E" }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
              color: "#E90E0E",
            }}
          >
            Hold Action
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Hold Action Till {holdActionTill} - for Reason{" "}
                  {filteringValue[0]?.label}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Hold Action Till {holdActionTill} - for Reason{" "}
                {filteringValue && filteringValue[0]?.label}
              </div>
            </StatefulTooltip>
          </div>
          {/* <div style={{ flex: "2" }}>
      
          </div> */}
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              width: "130px",
              // flex: "1",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? holdActionUserValue && holdActionUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case RELEASED:
      const releasedExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let releaseActionUserRd = referenceData["users"];
      let releaseActionUserValue = releaseActionUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {releasedExecutedOn}
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={RELEASED} />
          </div>
          <div
            //  style={{ flex: "1", color: "#48B24F" }}

            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
              color: "#48B24F",
            }}
          >
            Resume Action
          </div>
          <div style={{ flex: "2" }}>Resume Consumer Action</div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? releaseActionUserValue && releaseActionUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case INVOICE_UPDATE:
      const invoiceUpdate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      const invoiceContent = data && data?.content;

      let invoiceUpdateUserRd = referenceData["users"];
      let invoiceUpdateUserValue = invoiceUpdateUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div style={{ paddingRight: "14px" }}>{invoiceUpdate}</div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={INVOICE} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {invoiceContent && invoiceContent.is_cancelled ? (
              <div
                style={{
                  color: "#E90E0E",
                }}
              >
                Cancelled
              </div>
            ) : (
              <div>Invoice Edit</div>
            )}
          </div>
          {/* <div style={{ flex: "2" }}>
            Invoice # -
            {invoiceContent?.invoice_number
              ? invoiceContent?.invoice_number
              : "--"}
          </div> */}

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Invoice # -
                  {invoiceContent?.invoice_number
                    ? invoiceContent?.invoice_number
                    : "--"}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {invoiceContent?.invoice_number
                  ? invoiceContent?.invoice_number
                  : "--"}
              </div>
            </StatefulTooltip>
          </div>

          <div style={{ flex: "1", textAlign: "center" }}>
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  {/* Comment */}
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div style={{ width: "130px", textAlign: "center" }}>
            {data && data?.executed_by
              ? invoiceUpdateUserValue && invoiceUpdateUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case LETTER:
      const letterExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const letterContent = data && data?.content;

      let letterUserRd = referenceData["users"];
      let letterUserValue = letterUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {letterExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={LETTER} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {letterContent && letterContent.delivery_status === "TRANSIT" ? (
              <div>In Transit</div>
            ) : letterContent &&
              letterContent.delivery_status === "RETURNED" ? (
              <div
                style={{
                  color: "#E90E0E",
                }}
              >
                Returned
              </div>
            ) : letterContent && letterContent.delivery_status === "FAILED" ? (
              <div
                style={{
                  color: "#E90E0E",
                }}
              >
                Failed
              </div>
            ) : (
              <div
                style={{
                  color: "#48B24F",
                }}
              >
                Delivered
              </div>
            )}
          </div>
          {/* <div style={{ flex: "2" }}>{data && data?.name}</div> */}

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => <div>{data && data?.name}</div>}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.name}
              </div>
            </StatefulTooltip>
          </div>

          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",

              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? letterUserValue && letterUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case DISPUTE:
      const disputeExecutedOn = moment
        .utc(data.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const disputeContent = data && data?.content;

      const disputeReviewStatus = moment
        .utc(disputeContent?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      let disputeUserRd = referenceData["users"];
      let disputeUserValue = disputeUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      let refType = referenceData["dispute_type"];

      const findingValue = refType?.find(
        (i) => i.id == disputeContent?.dispute_type
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {disputeExecutedOn}
          </div>
          <div
            //  style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={DISPUTE} />
          </div>
          <div
            // style={{
            //   flex: "1",
            // }}

            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {disputeContent.dispute_status === "CLOSED" ? (
              <div
                style={{
                  color: "#333860",
                }}
              >
                Dispute - Closed
              </div>
            ) : disputeContent.dispute_status === "OPEN" ? (
              <div
                style={{
                  color: "#333860",
                  paddingLeft: "2px",
                }}
              >
                Dispute - Open
              </div>
            ) : (
              <div
                style={{
                  color: "#333860",
                }}
              >
                Dispute - In Progress
              </div>
            )}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {" "}
                  Dispute Reason - {findingValue && findingValue?.label} and
                  Review Status by {disputeReviewStatus} <span>(</span>
                  {disputeContent &&
                    disputeContent?.dispute_invoices &&
                    disputeContent?.dispute_invoices?.length}
                  <span> invoices )</span>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Dispute Reason - {findingValue && findingValue?.label} and
                Review Status by {disputeReviewStatus} <span>(</span>
                {disputeContent &&
                  disputeContent?.dispute_invoices &&
                  disputeContent?.dispute_invoices?.length}
                <span> invoices )</span>
              </div>
            </StatefulTooltip>
          </div>

          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1", textAlign: "center"

              //  flex: "1",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? disputeUserValue && disputeUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case CALL:
      let callOutcome = data && data?.content?.call_outcome;
      return <CallOutcome data={data} callOutcome={callOutcome} />;

    case WHATSAPP:
      const whatsAppExecutedDate = moment
        .utc(data.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      let whatsUpUserRd = referenceData["users"];
      let whatsUpUserValue = whatsUpUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {whatsAppExecutedDate}
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={WHATSAPP} />
          </div>

          <div
            // style={{ flex: "1" }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {data && data?.is_sender ? (
              <div>Read</div>
            ) : (
              <div>WhatsApp - Reply</div>
            )}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div style={{ width: "250px" }}>
                  {data?.is_adhoc_action ? "(QA) " : ""}{" "}
                  {data && data?.content && data?.content?.body}{" "}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // display: "flex",
                  maxWidth: "350px",
                  whiteSpace: "nowrap",
                }}
              >
                {data?.is_adhoc_action ? "(QA) " : ""}{" "}
                {data && data?.content && data?.content?.body}{" "}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1"
              // ,
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => <div>{data?.comment ? data?.comment : ""}</div>}
              returnFocus
              autoFocus
            >
              {data?.comment ? <div>Comment</div> : ""}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
              width: "130px",
            }}
          >
            {data && data?.executed_by
              ? whatsUpUserValue && whatsUpUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case NEXTACTION:
      const nextActionPlannedOn = moment
        .utc(data?.content?.planned_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const nextActionExecutedDate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let nextActionUserRd = referenceData["users"];
      let nextActionUserValue = nextActionUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {nextActionPlannedOn}
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={NEXTACTION} />
          </div>
          <div
            // style={{ flex: "1" }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
              color: "#48B24F",
            }}
          >
            Next Action{" "}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Next Action -{" "}
                  <FormattedMessage
                    id={
                      data && data?.content && data?.content?.next_action_type
                    }
                  />
                  &nbsp; an on {nextActionPlannedOn}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Next Action -{" "}
                <FormattedMessage
                  id={data && data?.content && data?.content?.next_action_type}
                />
                &nbsp; an on {nextActionPlannedOn}
              </div>
            </StatefulTooltip>
          </div>

          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1", textAlign: "center"

              //  flex: "1",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? nextActionUserValue && nextActionUserValue?.label
              : ""}
          </div>
        </div>
      );

    case CONTACTS:
      const catExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const catContent = data && data?.content;

      let catUserRd = referenceData["users"];
      let catUserValue = catUserRd?.find((i) => i?.id === data?.executed_by);

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {catExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={CONTACTS} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color:
                data.action_taken == "Updated"
                  ? "#e5690d"
                  : data.action_taken == "Added"
                  ? "#12b112"
                  : "red",
            }}
          >
            <p>Contact {data && data.action_taken}</p>
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data && data?.name}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.name} - Contact {data && data.action_taken}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",

              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? catUserValue && catUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case CUSTOMERT_STATUS:
      const csExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const csContent = data && data?.content;

      let csUserRd = referenceData["users"];
      let csUserValue = csUserRd?.find((i) => i?.id === data?.executed_by);

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {csExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={CUSTOMERT_STATUS} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: "#48b24f",
            }}
          >
            Consumer Status
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => <div>{data && data?.name}</div>}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.name} - {data && data.comment}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",

              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? csUserValue && csUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case NOTES:
      const ntExecutedOn = moment(data?.executed_on).format(
        currentDefaultFormatDetails.date_format
      );

      const ntContent = data && data?.content;

      let ntUserRd = referenceData["users"];
      let ntUserValue = data?.executed_by
        ? ntUserRd?.find((i) => i?.id === data?.executed_by)
        : [];

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {ntExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={NOTES} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color:
                data.action_taken == "Updated"
                  ? "#e5690d"
                  : data.action_taken == "Added"
                  ? "#12b112"
                  : "red",
            }}
          >
            Notes {data && data.action_taken}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data && data?.name}- {data && data.comment}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  width: "450px",
                }}
              >
                {data && data?.name} - {data && data.comment}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",

              width: "130px",
              textAlign: "center",
            }}
          >
            {ntUserValue && ntUserValue?.label
              ? ntUserValue && ntUserValue?.label
              : data && data?.executed_by
              ? data && data?.executed_by
              : "SYSTEM"}
          </div>
        </div>
      );

    case BUSINESS_UNIT:
      const buExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const buContent = data && data?.content;

      let buUserRd = referenceData["users"];
      let buUserValue = buUserRd?.find((i) => i?.id === data?.executed_by);

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {buExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={BUSINESS_UNIT} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: "#48b24f",
            }}
          >
            Client
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => <div>{data && data?.name}</div>}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.name} - Assigned as Client{" "}
                {data && data.content && data.content.business_unit_location
                  ? `& ${data.content.business_unit_location} - Location`
                  : ""}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",

              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? buUserValue && buUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case "SKIP_TRACE":
      const skipExecutedOn = moment
        .utc(data && data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      // const rmContent = data && data?.content;

      let skipUserRd = referenceData["users"];
      let skipUserValue = data?.executed_by
        ? skipUserRd?.find((i) => i?.id === data?.executed_by)
        : [];

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {skipExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={"SKIPT_TRACE"} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "157px",
              color: "#333860",
            }}
          >
            <LabelMedium
              $style={{
                color: "#333860",
                fontWeight: 400,
              }}
            >
              Skip Trace -{" "}
              <span
                style={{
                  color: `${
                    data?.content?.skip_trace_status == "FAILED"
                      ? "#FD372A"
                      : "#333860"
                  }`,
                  fontWeight: 400,
                  fontSize: "13px",
                }}
              >
                <FormattedMessage
                  id={data && data?.content && data?.content?.skip_trace_status}
                ></FormattedMessage>
              </span>
            </LabelMedium>
            {/* {data && data.is_assigned ? "Assigned" : "Unassigned"} */}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Skip Trace{" "}
                  <FormattedMessage
                    id={
                      data &&
                      data?.content &&
                      data?.content?.skip_trace_status &&
                      data?.content?.skip_trace_status
                    }
                  ></FormattedMessage>{" "}
                  on{" "}
                  {moment
                    .utc(data && data?.executed_on && data?.executed_on)
                    .format(currentDefaultFormatDetails.date_format)}{" "}
                  for{" "}
                  {data &&
                    data?.content &&
                    data?.content?.skip_trace_request_fields &&
                    data?.content?.skip_trace_request_fields[0] &&
                    data?.content?.skip_trace_request_fields[0].value}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                Skip Trace{" "}
                <FormattedMessage
                  id={
                    data &&
                    data?.content &&
                    data?.content?.skip_trace_status &&
                    data?.content?.skip_trace_status
                  }
                ></FormattedMessage>{" "}
                on{" "}
                {moment
                  .utc(data && data?.executed_on && data?.executed_on)
                  .format(currentDefaultFormatDetails.date_format)}{" "}
                for{" "}
                {data &&
                  data?.content &&
                  data?.content?.skip_trace_request_fields &&
                  data?.content?.skip_trace_request_fields[0] &&
                  data?.content?.skip_trace_request_fields[0].value}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              paddingLeft: "20px",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",

              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? skipUserValue && skipUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case RM:
      const rmExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const rmContent = data && data?.content;

      let rmUserRd = referenceData["users"];
      let rmUserValue = rmUserRd?.find((i) => i?.id === data?.executed_by);

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {rmExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={RM} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: "#48b24f",
            }}
          >
            RM {data && data.is_assigned ? "Assigned" : "Unassigned"}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {data && data?.name} -{" "}
                  {data && data.is_assigned ? "Assigned as RM" : "Unassigned"}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.name} -{" "}
                {data && data.is_assigned ? "Assigned as RM" : "Unassigned"}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",

              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? rmUserValue && rmUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case WORKFLOW:
      const workflowExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      const workflowContent = data && data?.content;
      const timezoneBasedDate = moment
        .utc(data?.content?.workflow_start_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      let workflowUserRd = referenceData["users"];
      let workflowUserValue = workflowUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {workflowExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={WORKFLOW} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: data.is_assigned ? "#12b112" : "Red",
            }}
          >
            WF {data && data.is_assigned ? "Assigned" : "Unassigned"}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {data && data?.name} -{" "}
                  {data && data.is_assigned
                    ? `Assigned with Start Date ${
                        data?.content?.workflow_start_date && timezoneBasedDate
                      }`
                    : "Unassigned"}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.name} -{" "}
                {data && data.is_assigned
                  ? `Assigned with Start Date ${
                      data?.content?.workflow_start_date && timezoneBasedDate
                    }`
                  : "Unassigned"}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? workflowUserValue && workflowUserValue?.label
              : "SYSTEM"}
          </div>
        </div>
      );

    case CUSTOMERPORTAL:
      const cpExecutedOn = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      const cpContent = data && data?.content;
      let cpUserRd = referenceData["users"];
      let cpUserValue = cpUserRd?.find((i) => i?.id === data?.executed_by);
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {cpExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            {/* {data.content.attachments.length !== 0
              ?
              <PaymentProof className="svg-primary-fill" width={18} height={18} />
              : */}
            <TimelineIcon type={CUSTOMERPORTAL} />
            {/* } */}
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: "#48b24f",
            }}
          >
            Portal Visit
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  sytle={{ maxWidth: "220px" }}
                >{`The Consumer Visited the Portal and performed Action - ${
                  data &&
                  data?.content &&
                  data?.content?.portal_actions.length > 1
                    ? data?.content?.portal_actions.map((ac) => ac?.toString())
                    : "NIL"
                }`}</div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {`The Consumer Visited the Portal and performed Action - ${
                  data &&
                  data?.content &&
                  data?.content?.portal_actions.length > 1
                    ? data?.content?.portal_actions.map((ac) => ac?.toString())
                    : "NIL"
                }`}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by}
          </div>
        </div>
      );

    case SKIPPEDTIMELINE:
      const skipActionPlannedOn = moment(data?.executed_on)?.format(
        currentDefaultFormatDetails.date_format
      );
      let skipActionUserRd = referenceData["users"];
      let skipActionUserValue = skipActionUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {skipActionPlannedOn}
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={SKIPPEDTIMELINE} />
          </div>
          <div
            // style={{ flex: "1" }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
              color: "#48B24F",
            }}
          >
            Skip Action{" "}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => <div>{data && data?.name && data?.name}</div>}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data && data?.name && data?.name}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data?.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1", textAlign: "center"
              //  flex: "1",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? skipActionUserValue && skipActionUserValue?.label
              : ""}
          </div>
        </div>
      );

    case PORTAL_PAYMENT:
      const potralPaymentExecutedOn = moment
        .utc(data?.content?.portal_payment_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      const potralPaymentContent = data && data?.content;
      let potralPaymentUserRd = referenceData["users"];
      let potralPaymentUserValue = potralPaymentUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {potralPaymentExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={APP_PAYMENT} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: data && data.action_taken == "Success" ? "#12b112" : "Red",
            }}
          >
            Payment {data && data.action_taken}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {data &&
                    data?.content?.portal_payment_amount?.currency + data &&
                    data?.content?.portal_payment_amount?.value}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.content?.portal_payment_amount?.currency}{" "}
                {data && data?.content?.portal_payment_amount?.value} Payment
                Via - {data?.content?.portal_payment_method} -{" "}
                {data?.action_taken}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",
              width: "130px",
              textAlign: "center",
            }}
          >
            Customer
          </div>
        </div>
      );

    case APP_PAYMENT:
      const appPaymentExecutedOn = moment
        .utc(data?.content?.portal_payment_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      const appPaymentContent = data && data?.content;
      let appPaymentUserRd = referenceData["users"];
      let appPaymentUserValue = appPaymentUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {appPaymentExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={PORTAL_PAYMENT} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: data && data.action_taken == "Success" ? "#12b112" : "Red",
            }}
          >
            Payment {data && data.action_taken}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {data && data?.content?.portal_payment_amount?.currency}{" "}
                  {data && data?.content?.portal_payment_amount?.value} Payment
                  Via - {data?.content?.portal_payment_method} -{" "}
                  {data?.action_taken}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.content?.portal_payment_amount?.currency}{" "}
                {data && data?.content?.portal_payment_amount?.value} Payment
                Via - {data?.content?.portal_payment_method} -{" "}
                {data?.action_taken}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",
              width: "130px",
              textAlign: "center",
            }}
          >
            {appPaymentUserValue?.label}
          </div>
        </div>
      );

    case CALL_RECORDING:
      const callRecordExecutedOn = moment(data?.executed_on).format(
        currentDefaultFormatDetails.date_format
      );
      const callRecordingContent = data && data?.content;
      let callRecordingUserRd = referenceData["users"];
      let callRecordingUserValue = callRecordingUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {callRecordExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={CALL_RECORDING} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: "#333860",
            }}
          >
            Call Recording
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {data?.content?.to?.[0]?.value
                ? data?.content?.to?.[0]?.value
                : ""}
              <audio
                style={{ height: "25px" }}
                controls
                controlsList="nodownload noplaybackrate"
              >
                <source
                  src={data?.content?.call_detail?.recording_url}
                  type="audio/mpeg"
                />
              </audio>{" "}
              {data?.content?.call_detail?.from_number
                ? `(From: ${data?.content?.call_detail?.from_number})`
                : ""}
            </div>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",
              width: "130px",
              textAlign: "center",
            }}
          >
            {callRecordingUserValue?.label}
          </div>
        </div>
      );

    case "payment":
      const callPromiseToPayExecutedDate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      let promiseStatus = data?.content?.promise_status;

      let promiseCurrency =
        data && data?.content && data?.content?.promise_amount?.currency;

      let promiseAmount =
        data && data?.content && data?.content?.promise_amount?.value;

      let disputeUserRds = referenceData["users"];
      let disputeUserValues = disputeUserRds?.find(
        (i) => i?.id === data?.executed_by
      );

      const disputeByDate = moment
        .utc(data?.content?.promise_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {callPromiseToPayExecutedDate}
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <PromiseToPay className="svg-primary-fill" />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {promiseStatus === "IN_PROGRESS" ? (
              <div
                style={{
                  color: "#8b8a8a",
                }}
              >
                Promise - InProgress
              </div>
            ) : promiseStatus === "PARTIAL" ? (
              <div
                style={{
                  color: "#FFBB00",
                }}
              >
                Promise - Partial
              </div>
            ) : promiseStatus === "BROKEN" ? (
              <div
                style={{
                  color: "#E90E0E",
                }}
              >
                Promise - Broken
              </div>
            ) : promiseStatus === "KEPT" ? (
              <div
                style={{
                  color: "#48B24F",
                }}
              >
                Promise - Kept
              </div>
            ) : promiseStatus === "CANCELED" ? (
              <div
                style={{
                  color: "#E90E0E",
                }}
              >
                Promise - Cancelled
              </div>
            ) : (
              <div
                style={{
                  color: "#48B24F",
                }}
              >
                Promise
              </div>
            )}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
              // paddingRight: "5px",
            }}
          >
            <StatefulTooltip returnFocus autoFocus>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                Promised Payment of
                <span style={{ padding: "0px 3px" }}>
                  {promiseCurrency}
                </span>{" "}
                <NumericFormat
                  displayType="text"
                  value={promiseAmount ? Number(promiseAmount) : 0}
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(value) => <div>{value}</div>}
                />{" "}
                <span style={{ padding: "0px 3px" }}>by</span> {disputeByDate}
              </div>
            </StatefulTooltip>
          </div>

          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
              width: "130px",
            }}
          >
            {data && data?.executed_by
              ? disputeUserValues && disputeUserValues?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );

    case "UPDATE_PROMISE_DATE":
    case "UPDATE_PAYMENT_PLAN_DATE": {
      const callPromiseToPayExecutedDate = moment
        .utc(data?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let disputeUserRd = referenceData["users"];
      let disputeUserValue = disputeUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      const fromDate = moment
        .utc(data?.content?.planned_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      const toDate = moment
        .utc(data?.content?.revised_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {callPromiseToPayExecutedDate}
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <PromiseToPay className="svg-primary-fill" />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            <div
              style={{
                color: "#333860",
              }}
            >
              {data.type === "UPDATE_PAYMENT_PLAN_DATE"
                ? "Payment Plan"
                : "Promise"}{" "}
              Edit
            </div>
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
              // paddingRight: "5px",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {" "}
                  Promised Date Changed from {fromDate} to&nbsp;
                  <span style={{ fontWeight: 700 }}> {toDate}</span>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                Promised Payment of from {fromDate} to&nbsp;
                <span style={{ fontWeight: 700 }}> {toDate}</span>
              </div>
            </StatefulTooltip>
          </div>

          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment ? data.comment : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
              width: "130px",
            }}
          >
            {data && data?.executed_by
              ? disputeUserValue && disputeUserValue?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );
    }

    case "PORTAL_PAYMENT_PLAN":
      const callPaymentPlan = moment
        .utc(data?.content?.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}

            style={{ paddingRight: "14px" }}
          >
            {callPaymentPlan}
          </div>
          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <PromiseToPay className="svg-primary-fill" />
          </div>
          <div
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "121px",
              color: "#000",
            }}

            // style={{ flex: "1", color: "#48B24F" }}
          >
            Portal - PPL
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "1",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {data.content.payment_plan_frequency
                ? data.content.payment_plan_frequency
                : ""}
              &nbsp;Payment Plan with Installment amount{" "}
              {data?.content?.payment_plan_instalation_amount?.currency
                ? data?.content?.payment_plan_instalation_amount?.currency
                : "-"}
              &nbsp;
              {data?.content?.payment_plan_instalation_amount?.value ? (
                <NumericFormat
                  displayType="text"
                  value={
                    data?.content?.payment_plan_instalation_amount?.value
                      ? Number(
                          data?.content?.payment_plan_instalation_amount?.value
                        )
                      : 0
                  }
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(value) => <>{value}</>}
                />
              ) : (
                "-"
              )}
              &nbsp;starting from{" "}
              {moment(data?.content?.payment_plan_start_date).format(
                currentDefaultFormatDetails?.date_format
              )}{" "}
              till{" "}
              {moment(data?.content?.payment_plan_end_date).format(
                currentDefaultFormatDetails?.date_format
              )}
            </div>
          </div>

          <div
            //  style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            {data?.comment ? (
              <StatefulTooltip
                content={() => (
                  <div
                    style={{
                      maxWidth: "220px",
                    }}
                  >
                    {data?.comment ? data.comment : ""}
                  </div>
                )}
                returnFocus
                autoFocus
              >
                <div>
                  <Comment />
                </div>
              </StatefulTooltip>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              //  flex: "1", textAlign: "center"
              width: "130px",
              textAlign: "center",
            }}
          >
            {data?.is_receiver ? "Portal" : "SYSTEM"}
          </div>
        </div>
      );

    case CUSTOMER_NAME_UPDATE:
      const customerNameExecutedOn = data?.executed_on
        ? moment
            .utc(data?.executed_on)
            .tz(timeZone)
            .format(currentDefaultFormatDetails.date_format)
        : "-";
      const customerNameContent = data && data?.content;
      let customerNameUserRd = referenceData["users"];
      let customerNameUserValue = customerNameUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {customerNameExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={CUSTOMER_NAME_UPDATE} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: "#333860",
            }}
          >
            Consumer Updated
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Consumer Name Changed to{" "}
              {data?.content?.customer_new_name
                ? data?.content?.customer_new_name
                : ""}
            </div>
          </div>
          <div
            style={{
              // flex: "1",
              // textAlign: "center",
              width: "130px",
              textAlign: "center",
            }}
          >
            {customerNameUserValue?.label}
          </div>
        </div>
      );

    case PAYMENT: {
      const instantPaymentExecutedOn = moment
        .utc(data?.content?.portal_payment_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      const instantPaymentContent = data && data?.content;
      let instantPaymentUserRd = referenceData["users"];
      let instantPaymentUserValue = instantPaymentUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {instantPaymentExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={APP_PAYMENT} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
              color: data && data.action_taken == "Success" ? "#12b112" : "Red",
            }}
          >
            Payment {data && data.action_taken}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  Call -{" "}
                  {data &&
                  data.content &&
                  data.content.portal_payment_gateway_name
                    ? data.content.portal_payment_gateway_name
                    : "-"}{" "}
                  Amount:{" "}
                  {data && data?.content?.portal_payment_amount?.currency}{" "}
                  {data && data?.content?.portal_payment_amount?.value}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {data && data?.content?.portal_payment_amount?.currency}{" "}
                {data && data?.content?.portal_payment_amount?.value} Payment
                Via -{" "}
                {data &&
                data.content &&
                data.content.portal_payment_gateway_name
                  ? data.content.portal_payment_gateway_name
                  : "-"}{" "}
                {data?.action_taken}
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? instantPaymentUserValue && instantPaymentUserValue?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );
    }
    case "FIELD_VISIT": {
      let fieldType =
        data.content?.visit_status === "UNSUCCESSFULL"
          ? "UNSUCCESSFULL"
          : data.content?.visit_outcome;
      return <FieldVisit data={data} type={fieldType} />;
    }
    case "CANCELED_FIELD_VISIT":
      const customerNameExecutedOn_Time = data?.executed_on
        ? moment
            .utc(data?.executed_on)
            .tz(timeZone)
            .format(currentDefaultFormatDetails.date_format)
        : "-";
      let instantPaymentUserRd = referenceData["users"];
      let instantPaymentUserValue = instantPaymentUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      let userValueID = instantPaymentUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div
              //  style={{ flex: "1" }}
              style={{ paddingRight: "14px" }}
            >
              {customerNameExecutedOn_Time}
            </div>
            <div
              style={{
                //  flex: "1",
                textAlign: "center",
              }}
            >
              <TimelineIcon type={"FIELD_VISIT"} />
            </div>
            <div
              style={{
                //  flex: "1"
                paddingLeft: "7px",
                width: "130px",
                color:
                  data && data.action_taken == "Success" ? "#12b112" : "Red",
              }}
            >
              {data?.name}
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                flex: "2",
              }}
            >
              <ParagraphSmall className="fielvisitaddress">
                Field Visit Date on{" "}
                {moment(data?.content?.visit_date)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .format(currentDefaultFormatDetails.date_format)}
              </ParagraphSmall>
            </div>
            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? userValueID && userValueID?.label
                : "SYSTEM"}
            </div>
          </div>
        </>
      );

    case "EDIT_FIELD_VISIT":
      const editFieldVisitExecutedOn = data?.executed_on
        ? moment
            .utc(data?.executed_on)
            .tz(timeZone)
            .format(currentDefaultFormatDetails.date_format)
        : "-";
      let editFieldVisitUserRd = referenceData["users"];
      let editFieldVisitUserValue = editFieldVisitUserRd?.find(
        (i) => i?.id === data?.executed_by
      );
      // let editFieldVisitUserValueID = instantPaymentUserRd?.find(
      //   (i) => i?.id === data?.executed_by
      // );

      let findtimeFieldVisit = data?.content?.visit_time
        ? referenceData["time"].find((e) => e.id == data?.content?.visit_time)
        : [];
      return (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "30px",
              backgroundColor: "#fff",
              borderBottom: "0.5px solid #cdced9",
            }}
          >
            <div
              //  style={{ flex: "1" }}
              style={{ paddingRight: "14px" }}
            >
              {editFieldVisitExecutedOn}
            </div>
            <div
              style={{
                //  flex: "1",
                textAlign: "center",
              }}
            >
              <TimelineIcon type={"FIELD_VISIT"} />
            </div>
            <div
              style={{
                //  flex: "1"
                paddingLeft: "7px",
                width: "130px",
              }}
            >
              {data?.name}
            </div>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                // textOverflow: "ellipsis",
                flex: "2",
              }}
            >
              <ParagraphSmall className="fielvisitaddress">
                {/* <FormattedMessage id={data.type} /> */}
                Field Visit on{" "}
                {moment
                  .utc(data?.content?.visit_date)
                  .format(currentDefaultFormatDetails.date_format)}
                ,{findtimeFieldVisit?.label}{" "}
                {data?.content?.visit_address
                  ? Object.values(data?.content?.visit_address)?.toString()
                  : ""}
              </ParagraphSmall>
            </div>
            <div
              style={{
                width: "130px",
                textAlign: "center",
              }}
            >
              {data && data?.executed_by
                ? editFieldVisitUserValue && editFieldVisitUserValue?.label
                : "SYSTEM"}
            </div>
          </div>
        </>
      );

    case "RESCHEDULE_NEXT_ACTION_DATE":
      const nextActionExecutedOn = moment(data?.executed_on).format(
        currentDefaultFormatDetails.date_format
      );
      const nextActionPaymentContent = data && data?.content;
      let nextActionRd = referenceData["users"];
      let inextActionUserValue = nextActionRd?.find(
        (i) => i?.id === data?.executed_by
      );
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            //  style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {nextActionExecutedOn}
          </div>
          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={"NEXTACTIONRESCHEDULA"} />
          </div>
          <div
            style={{
              //  flex: "1"
              paddingLeft: "7px",
              width: "130px",
            }}
          >
            {data?.content?.reschedule_action_source
              ? data?.content?.reschedule_action_source
              : "WF Reschedule"}
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <ParagraphMedium>
                  Reschedule Date:&nbsp;
                  {moment
                    .utc(
                      data && data?.content && data?.content?.reschedule_date
                    )
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                </ParagraphMedium>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "flex",
                  color: "#333860",
                }}
              >
                <ParagraphMedium>
                  Reschedule Date:&nbsp;
                  {moment
                    .utc(
                      data && data?.content && data?.content?.reschedule_date
                    )
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}
                </ParagraphMedium>
              </div>
            </StatefulTooltip>
          </div>
          <div
            style={{
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? inextActionUserValue && inextActionUserValue?.label
              : data && data?.is_receiver
              ? "Portal"
              : "SYSTEM"}
          </div>
        </div>
      );

    case "CLARIFICATION_REQUEST_INFO":
    case "DOCUMENT_REQUEST_INFO":
      const requestInformationPlannedOn = moment(data?.executed_on).format(
        currentDefaultFormatDetails.date_format
      );

      let requestInformationUserRd = referenceData["users"];
      let requestInformationUserValue = requestInformationUserRd?.find(
        (i) => i?.id === data?.executed_by
      );

      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            height: "30px",
            backgroundColor: "#fff",
            borderBottom: "0.5px solid #cdced9",
          }}
        >
          <div
            // style={{ flex: "1" }}
            style={{ paddingRight: "14px" }}
          >
            {requestInformationPlannedOn}
          </div>
          <div
            style={{
              // flex: "1",
              textAlign: "center",
            }}
          >
            <TimelineIcon type={"REQUESTINFORMATION"} />
          </div>
          <div
            // style={{ flex: "1" }}
            style={{
              //  flex: "1",
              paddingLeft: "7px",
              width: "130px",
              color: "#333860",
            }}
          >
            {data.name}
          </div>

          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              // textOverflow: "ellipsis",
              flex: "2",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div>
                  {data.type === "CLARIFICATION_REQUEST_INFO"
                    ? "Clarification Requested"
                    : "Document Requested"}
                  &nbsp; (
                  {_.capitalize(
                    _.replace(
                      _.get(data, "content.req_info_type", ""),
                      "_",
                      " "
                    )
                  )}
                  ) -{" "}
                  {data && data?.content && data?.content?.req_info_status ? (
                    <FormattedMessage
                      id={
                        data && data?.content && data?.content?.req_info_status
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data.type === "CLARIFICATION_REQUEST_INFO"
                  ? "Clarification Requested"
                  : "Document Requested"}
                &nbsp; (
                {_.capitalize(
                  _.replace(_.get(data, "content.req_info_type", ""), "_", " ")
                )}
                ) -{" "}
                {data && data?.content && data?.content?.req_info_status ? (
                  <FormattedMessage
                    id={data && data?.content && data?.content?.req_info_status}
                  />
                ) : (
                  <></>
                )}
              </div>
            </StatefulTooltip>
          </div>

          <div
            style={{
              //  flex: "1",
              textAlign: "center",
            }}
          >
            <StatefulTooltip
              content={() => (
                <div
                  style={{
                    maxWidth: "220px",
                  }}
                >
                  {data?.comment
                    ? data?.comment?.replace(/(<([^>]+)>)/gi, "")
                    : ""}
                </div>
              )}
              returnFocus
              autoFocus
            >
              {data?.comment ? (
                <div>
                  <Comment />
                </div>
              ) : (
                ""
              )}
            </StatefulTooltip>
          </div>

          <div
            // style={{ flex: "1", textAlign: "center" }}
            style={{
              // flex: "1", textAlign: "center"

              //  flex: "1",
              width: "130px",
              textAlign: "center",
            }}
          >
            {data && data?.executed_by
              ? requestInformationUserValue &&
                requestInformationUserValue?.label
              : ""}
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default TimelineTableMap;
