import React from "react";

const ClipBoard = () => {
  return (
    <>
      <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_9475_85254)">
          <g clip-path="url(#clip1_9475_85254)">
            <path
              d="M10.9637 4.83594H4.24146C3.62526 4.83594 3.12109 5.3401 3.12109 5.95631V13.7989H4.24146V5.95631H10.9637V4.83594ZM12.6442 7.07668H6.48221C5.866 7.07668 5.36183 7.58085 5.36183 8.19705V16.0396C5.36183 16.6559 5.866 17.16 6.48221 17.16H12.6442C13.2604 17.16 13.7646 16.6559 13.7646 16.0396V8.19705C13.7646 7.58085 13.2604 7.07668 12.6442 7.07668ZM12.6442 16.0396H6.48221V8.19705H12.6442V16.0396Z"
              fill="#4B4F72"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_9475_85254">
            <rect width="16" height="22" rx="5" fill="white" />
          </clipPath>
          <clipPath id="clip1_9475_85254">
            <rect
              width="13.4444"
              height="13.4444"
              fill="white"
              transform="translate(2 4.27734)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ClipBoard;
