import React, { useState } from "react";
import setSelectValues from "../../../utils/setSelectValues";
import { useSelector, useDispatch } from "react-redux";
import { CellRefSelectBox } from "../../CellRefSelectBox";
import TagTick from "../../../assets/img/svg/CustomerOverViewIcon/TagTick";
import _ from "lodash";
import { updateSystemFieldCustomerConatct } from "../../../redux/CustomerContactUpload/action";

const ContactSystemField = (props) => {
  //
  let { value, column, alignment, accessor, row, ...rest } = props;

  const dispatch = useDispatch();
  const { headers, systemFields } = useSelector(
    (e) => e.customerContactUploadReducer
  );

  const uploadFieldOptions = systemFields.map((systemColumn) => ({
    label: (
      <>
        {systemColumn.system_column}{" "}
        {systemColumn.required && <span style={{ color: "#fd372a" }}>*</span>}
        {systemColumn?.isSelected && <TagTick />}
      </>
    ),
    id: systemColumn.id,
    module: systemColumn.module,
  }));
  value = _.get(row.original, accessor, "");

  return (
    <CellRefSelectBox
      // clearable={false}
      placeholder=""
      value={setSelectValues(value)}
      onChange={(params) => {
        dispatch(
          updateSystemFieldCustomerConatct({
            index: row.index,
            systemField: params?.value[0]?.id,
            type: params?.type,
            module: params?.value[0]?.module,
            
          })
        );
      }}
      options={uploadFieldOptions}
    />
  );
};

export default ContactSystemField;
