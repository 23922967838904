import React, { useState, useEffect, useContext } from "react";
import { Modal } from "baseui/modal";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { TextButton, KIND, SIZE } from "../../../components/TextButton";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { HeadingXLarge } from "baseui/typography";
import { TextBox } from "../../../components/TextBox";
import { useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useOrgDiscountSlab, useOrgSlab } from "../../../services/organization";
import { VIEW } from "../../../constants";
import { Icon, Typography } from "../../../components_v2";
import _ from "lodash";
import queryClient from "../../../providers/queryClient";

const DiscountSlabModal = ({
  isModalState = {},
  setisModalState = () => {},
  refetch = () => {},
}) => {
  let intl = useIntl();
  const { currentOrganization } = useContext(MaxyfiContext);

  const schema = Yup.object().shape({
    slab_name: Yup.string().required("Required"),
    slab_range_list: Yup.array(
      Yup.object({
        to: Yup.number()
          .max(999999999, "Shouldn't be greater than 999999999")
          .required("Shouldn't be greater than 999999999")
          .transform((value) => (isNaN(value) ? undefined : value)),

        from: Yup.number()
          .test("to", "Inconsistent Range between records", (from, to) => {
            let firstPath = to.path.toString();
            let pathSplit = firstPath?.split("[");
            let secondPath = pathSplit && pathSplit[1]?.split("]");
            let currentIndex = secondPath && secondPath[0];
            let lastArrayData =
              to &&
              to?.from &&
              to?.from[1] &&
              to?.from[1]?.value &&
              to?.from[1]?.value?.slab_range_list[currentIndex - 1];
            let minusValue = from - Number(lastArrayData?.to);

            if (from == 1 && currentIndex == 0) {
              return true;
            } else if (minusValue === 1 && currentIndex >= 1) {
              return true;
            } else {
              return false;
            }
          })
          .transform((value) => (isNaN(value) ? undefined : value)),

        percentage: Yup.string()
          .optional()
          .test(
            "prc",
            "{{max_settlement_rate}} tag is Required",
            (prc, res) => {
              if (
                prc.length == 0 ||
                Number(prc) ||
                (!Number(prc) && prc.includes("{{max_settlement_rate}}"))
              ) {
                return true;
              }
            }
          ),
      })
    ),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      slab_range_list: [{ from: 1, to: 999999999, percentage: "10" }],
    },
  });

  const { fields, append, remove, update, insert } = useFieldArray({
    control,
    name: "slab_range_list",
  });
  let { slab_range_list } = watch();

  useEffect(() => {
    if (
      isModalState?.type == VIEW &&
      isModalState.data &&
      isModalState.data[0]
    ) {
      _.get(isModalState, "data[0].slab_range_list", []).map(
        ({ from, to, percentage }, i) => {
          update(i, { from, to, percentage });
        }
      );
    }
    if (_.get(isModalState, "data[0].slab_name", "")) {
      setValue("slab_name", _.get(isModalState, "data[0].slab_name", ""));
    }
  }, [isModalState.type, isModalState.isOpen]);

  const { mutateAsync, isLoading, error } = useOrgDiscountSlab();

  const onSubmit = async (data) => {
    let values = {
      ...data,
      organization: currentOrganization,
    };

    await mutateAsync(values, {
      onSuccess: (data) => {
        reset();
        setValue("slab_name", "");
        setisModalState({ data: {}, isOpen: false });
        queryClient.invalidateQueries(`DISCOUNT_SLAB-${currentOrganization}`);
        refetch();
      },
      onError: (err) => {},
    });
  };

  return (
    <Modal
      isOpen={isModalState && isModalState.isOpen}
      overrides={{
        Dialog: {
          style: ({ $theme }) => ({
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "0px",
            marginBottom: "0px",
            padding: "15px 20px",
            width: "739px",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            textAlign: "center",
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "0px",
            marginBottom: "0px",
          }}
        >
          {isModalState && isModalState.type == VIEW ? (
            <HeadingXLarge $style={{ color: "#516BEB" }}>
              View Discount Slab
            </HeadingXLarge>
          ) : (
            <HeadingXLarge $style={{ color: "#516BEB" }}>
              Add Discount Slab
            </HeadingXLarge>
          )}
        </div>

        <div
          style={
            {
              // width: "530px",
              // height: "calc(100vh - 200px)",
              // overflow: "scroll",
            }
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
            }}
          >
            <div style={{ width: "250px" }}>
              <Controller
                name="slab_name"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <TextBox
                      {...field}
                      requiredAstric={true}
                      fullWidth
                      name={field.name}
                      size="mini"
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "discount_slab_name",
                      })}
                      placeholder={intl.formatMessage({
                        id: "discount_slab_name",
                      })}
                      disabled={
                        isModalState && isModalState.type == VIEW ? true : false
                      }
                      value={field.value}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <Typography>Select Discount Slabs</Typography>
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "20px",
              marginTop: "10px",
            }}
          >
            {fields.map((e, i) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Controller
                    name={`slab_range_list[${i}].from`}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        disabled={
                          isModalState && isModalState.type == VIEW
                            ? true
                            : false
                        }
                        fullWidth
                        name={field.name}
                        size={SIZE.mini}
                        error={
                          errors &&
                          errors.slab_range_list &&
                          errors.slab_range_list[i] &&
                          errors.slab_range_list[i].from &&
                          errors.slab_range_list[i].from?.message
                        }
                        label={intl.formatMessage({
                          id: "start_duration_in_days",
                        })}
                        placeholder={intl.formatMessage({
                          id: "start_duration_in_days",
                        })}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={`slab_range_list[${i}].to`}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        disabled={
                          isModalState && isModalState.type == VIEW
                            ? true
                            : false
                        }
                        fullWidth
                        name={field.name}
                        size={SIZE.mini}
                        error={
                          errors &&
                          errors.slab_range_list &&
                          errors.slab_range_list[i] &&
                          errors.slab_range_list[i].to &&
                          errors.slab_range_list[i].to?.message
                        }
                        label={intl.formatMessage({
                          id: "end_duration_in_days",
                        })}
                        placeholder={intl.formatMessage({
                          id: "end_duration_in_days",
                        })}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                  <Controller
                    name={`slab_range_list[${i}].percentage`}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        disabled={
                          isModalState && isModalState.type == VIEW
                            ? true
                            : false
                        }
                        fullWidth
                        name={field.name}
                        size={SIZE.mini}
                        error={
                          errors &&
                          errors.slab_range_list &&
                          errors.slab_range_list[i] &&
                          errors.slab_range_list[i].percentage &&
                          errors.slab_range_list[i].percentage?.message
                        }
                        label={intl.formatMessage({
                          id: "p_discount",
                        })}
                        placeholder={intl.formatMessage({
                          id: "p_iscount",
                        })}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                </div>
              );
            })}

            <div
              style={{
                paddingTop: "10px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              title="Enabled when End Duration is Lesser than 999999999"
            >
              <TextButton
                kind={KIND.secondary}
                size={SIZE.mini}
                type="button"
                onClick={() => {
                  let lastIndex = slab_range_list && slab_range_list.length - 1;

                  let lastIndexData =
                    slab_range_list && slab_range_list[lastIndex];

                  append({
                    from: Number(lastIndexData.to) + 1,
                    to: 999999999,
                    percentage: "25",
                  });
                }}
                disabled={
                  slab_range_list &&
                  slab_range_list[slab_range_list.length - 1] &&
                  slab_range_list[slab_range_list.length - 1].to >= 999999999
                    ? true
                    : false
                }
                style={{ marginBottom: "15px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#516beb",
                  }}
                >
                  <span>
                    <Icon icon="plus" color={"#516beb"} />
                  </span>
                  Add Instalment Discount
                </div>
              </TextButton>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "5px",
            marginTop: "5px",
          }}
        >
          {isModalState && isModalState.type == VIEW ? (
            <>
              <div style={{ width: "125px" }}>
                <TextButton
                  type="button"
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                  fullWidth
                  onClick={() => {
                    //   reset();
                    //   setContactModal(false);
                    // dispatch(closeContactModal());3
                    reset();
                    setValue("slab_name", []);
                    setisModalState({
                      type: "",
                      data: [],
                    });
                  }}
                >
                  Close
                </TextButton>
              </div>
            </>
          ) : (
            <>
              <div style={{ width: "125px" }}>
                <TextButton
                  type="button"
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                  fullWidth
                  onClick={() => {
                    setisModalState({ data: {}, isOpen: false });
                  }}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "125px" }}>
                <TextButton
                  type="submit"
                  kind={KIND.primary}
                  size={SIZE.compact}
                  fullWidth
                  disabled={
                    isSubmitting || (isModalState && isModalState.type == VIEW)
                      ? true
                      : false
                  }
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default DiscountSlabModal;
