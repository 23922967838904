const LocationSvg = ({ size = 22, color = "#516beb" }) => {
  return (
    <>
      {/* <svg
        clip-rule="evenodd"
        fill-rule="evenodd"
        height="22"
        stroke-linejoin="round"
        stroke-miterlimit="2"
        viewBox="0 0 24 24"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon">
          <path d="m12 1.25c-3.723 0-6.75 3.054-6.75 6.821 0 1.276.562 2.859 1.38 4.438 1.801 3.477 4.801 6.979 4.801 6.979.142.166.35.262.569.262s.427-.096.569-.262c0 0 3-3.502 4.801-6.979.818-1.579 1.38-3.162 1.38-4.438 0-3.767-3.027-6.821-6.75-6.821zm0 4c-1.518 0-2.75 1.232-2.75 2.75s1.232 2.75 2.75 2.75 2.75-1.232 2.75-2.75-1.232-2.75-2.75-2.75z" />
          <path d="m16.784 17.377c.813.244 1.483.546 1.946.9.3.228.52.451.52.723 0 .16-.091.305-.219.45-.212.239-.527.454-.917.654-1.378.705-3.606 1.146-6.114 1.146s-4.736-.441-6.114-1.146c-.39-.2-.705-.415-.917-.654-.128-.145-.219-.29-.219-.45 0-.272.22-.495.52-.723.463-.354 1.133-.656 1.946-.9.396-.119.621-.538.502-.934-.119-.397-.538-.622-.934-.503-1.238.373-2.2.884-2.778 1.449-.508.495-.756 1.049-.756 1.611 0 .702.397 1.402 1.204 1.986 1.416 1.024 4.26 1.764 7.546 1.764s6.13-.74 7.546-1.764c.807-.584 1.204-1.284 1.204-1.986 0-.562-.248-1.116-.756-1.611-.578-.565-1.54-1.076-2.778-1.449-.396-.119-.815.106-.934.503-.119.396.106.815.502.934z" />
        </g>
      </svg> */}

      <svg
        clip-rule="evenodd"
        fill-rule="evenodd"
        height={size}
        stroke-linejoin="round"
        stroke-miterlimit="2"
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon">
          <path
            d="m12 1.25c-3.723 0-6.75 3.054-6.75 6.821 0 1.276.562 2.859 1.38 4.438 1.801 3.477 4.801 6.979 4.801 6.979.142.166.35.262.569.262s.427-.096.569-.262c0 0 3-3.502 4.801-6.979.818-1.579 1.38-3.162 1.38-4.438 0-3.767-3.027-6.821-6.75-6.821zm0 4c-1.518 0-2.75 1.232-2.75 2.75s1.232 2.75 2.75 2.75 2.75-1.232 2.75-2.75-1.232-2.75-2.75-2.75z"
            fill={color}
          />
          <path
            d="m16.784 17.377c.813.244 1.483.546 1.946.9.3.228.52.451.52.723 0 .16-.091.305-.219.45-.212.239-.527.454-.917.654-1.378.705-3.606 1.146-6.114 1.146s-4.736-.441-6.114-1.146c-.39-.2-.705-.415-.917-.654-.128-.145-.219-.29-.219-.45 0-.272.22-.495.52-.723.463-.354 1.133-.656 1.946-.9.396-.119.621-.538.502-.934-.119-.397-.538-.622-.934-.503-1.238.373-2.2.884-2.778 1.449-.508.495-.756 1.049-.756 1.611 0 .702.397 1.402 1.204 1.986 1.416 1.024 4.26 1.764 7.546 1.764s6.13-.74 7.546-1.764c.807-.584 1.204-1.284 1.204-1.986 0-.562-.248-1.116-.756-1.611-.578-.565-1.54-1.076-2.778-1.449-.396-.119-.815.106-.934.503-.119.396.106.815.502.934z"
            fill={color}
          />
        </g>
      </svg>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        // xmlns="http://www.w3.org/1999/xlink"
        // xmlns:svgjs="http://svgjs.com/svgjs"
        width="22"
        height="22"
        x="0"
        y="0"
        viewBox="0 0 24 24"
        style="enable-background:new 0 0 512 512"
        xml="preserve"
        class=""
      >
        <g>
          <path
            d="M341.476 338.285c54.483-85.493 47.634-74.827 49.204-77.056C410.516 233.251 421 200.322 421 166 421 74.98 347.139 0 256 0 165.158 0 91 74.832 91 166c0 34.3 10.704 68.091 31.19 96.446l48.332 75.84C118.847 346.227 31 369.892 31 422c0 18.995 12.398 46.065 71.462 67.159C143.704 503.888 198.231 512 256 512c108.025 0 225-30.472 225-90 0-52.117-87.744-75.757-139.524-83.715zm-194.227-92.34a15.57 15.57 0 0 0-.517-.758C129.685 221.735 121 193.941 121 166c0-75.018 60.406-136 135-136 74.439 0 135 61.009 135 136 0 27.986-8.521 54.837-24.646 77.671-1.445 1.906 6.094-9.806-110.354 172.918L147.249 245.945zM256 482c-117.994 0-195-34.683-195-60 0-17.016 39.568-44.995 127.248-55.901l55.102 86.463a14.998 14.998 0 0 0 25.298 0l55.101-86.463C411.431 377.005 451 404.984 451 422c0 25.102-76.313 60-195 60z"
            fill={color}
            // data-original="#000000"
          ></path>
          <path
            d="M256 91c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75-33.645-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z"
            fill={color}
            // data-original="#000000"
          ></path>
        </g>
      </svg> */}
    </>
  );
};

export default LocationSvg;
