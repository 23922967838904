// Action Creator
export const FETCH_SELECT_VIEW_NAME = "FETCH_SELECT_VIEW_NAME";

// Action

export const fetchSelectViewName = (name) => {
  return {
    type: FETCH_SELECT_VIEW_NAME,
    payload: name,
  };
};
