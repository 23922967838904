import React from "react";

const Swap = ({ size = 22, fill = "#516BEB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1797 3L17.0888 5.90909L14.1797 8.81818"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 10.2728V8.81827C4 8.04673 4.30649 7.30679 4.85205 6.76123C5.39761 6.21567 6.13755 5.90918 6.90909 5.90918H17.0909"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.90909 18.9998L4 16.0907L6.90909 13.1816"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0909 11.7275V13.1821C17.0909 13.9536 16.7844 14.6936 16.2389 15.2391C15.6933 15.7847 14.9534 16.0912 14.1818 16.0912H4"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Swap;
