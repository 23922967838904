import React, { useEffect, useState } from "react";
import CardWidgetOne from "./CardWidgetOne/CardWidgetOne";
import CardWidgetTwo from "./CardWidgetTwo/CardWidgetTwo";
import CardWidgetThree from "./CardWidgetThree/CardWidgetThree";
import CardWidgetFour from "./CardWidgetFour/CardWidgetFour";
import CardWidgetFive from "./CardWidgetFive/CardWidgetFive";
import CardWidgetSix from "./CardWidgetSix/CardWidgetSix";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Paichart from "./Paichart/Paichart";
import PromiseSuccess from "./PromiseSuccess/PromiseSuccess";
import PromiseKept from "./PromiseKept/PromiseKept";
import LastPromise from "./LastPromise/LastPromise";
import AddWidgets from "./AddWidgets/AddWidgets";

const CardMerge = (props) => {
  let items = [
    <CardWidgetOne />,
    <CardWidgetTwo />,
    <CardWidgetThree />,
    <CardWidgetFour />,
    <CardWidgetFive />,
    <CardWidgetSix />,
    <Paichart />,
    <PromiseSuccess />,
    <PromiseKept />,
    <LastPromise />,
    <AddWidgets />,
  ];

  const [widget, setWidget] = useState(items);
  const reOrder = (widget, startIndex, endIndex) => {
    const result = Array.from(widget);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onEnd = (result) => {
    setWidget(reOrder(widget, result.source.index, result.destination.index));
  };
  return (
    <>
      <DragDropContext onDragEnd={onEnd}>
        <Droppable droppableId="droppable-widget" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "flex",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              {widget.map((item, index) => (
                <Draggable
                  key={`w- ${index}`}
                  draggableId={`w- ${index}`}
                  //   draggableId={}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default CardMerge;
