const EmailRepliesInfo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_991_16799)">
        <path
          d="M29.1923 5.62275C28.7308 5.15569 28.2692 5 27.6538 5H3.65385C3.03846 5 2.57692 5.15569 2.11538 5.62275C1.65385 5.93413 1.5 6.55689 1.5 7.02395V25.7066C1.5 26.3293 1.65385 26.7964 2.11538 27.2635C2.57692 27.7305 3.03846 27.8862 3.65385 27.8862H17.9615C17.6538 26.9521 17.5 26.018 17.5 25.0838C17.5 20.1018 21.5 16.0539 26.4231 16.0539C27.6538 16.0539 28.7308 16.3653 29.8077 16.6766V7.02395C29.9615 6.55689 29.6538 5.93413 29.1923 5.62275ZM27.8077 9.0479L15.8077 16.988L3.65385 9.0479V7.02395L15.6538 14.8084L27.6538 7.02395V9.0479H27.8077ZM25.6538 20.4132V17.4551C25.6538 17.2994 25.5 16.988 25.3462 16.988C25.1923 16.8323 24.8846 16.988 24.7308 17.1437L18.7308 22.9042C18.5769 23.0599 18.5769 23.2156 18.5769 23.2156C18.5769 23.3713 18.5769 23.5269 18.7308 23.5269L24.7308 29.2874C24.8846 29.4431 25.0385 29.4431 25.3462 29.4431C25.5 29.2874 25.6538 29.1317 25.6538 28.9761V26.018H26.5769C29.8077 26.018 32.7308 27.7305 34.4231 30.6886C34.5769 30.8443 34.7308 31 34.8846 31H35.0385C35.3462 31 35.5 30.6886 35.5 30.5329C35.3462 24.9281 31.0385 20.4132 25.6538 20.4132Z"
          fill="#516BEB"
        />
      </g>
      <defs>
        <clipPath id="clip0_991_16799">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailRepliesInfo;
