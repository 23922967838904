export const GET_INVOICE_CARD_DATA = "GET_INVOICE_CARD_DATA";
export const INVOICE_NOTE_ALERT = "INVOICE_NOTE_ALERT";
export const REFETCH_INVOICE_ACTIONS = "REFETCH_INVOICE_ACTIONS";
export const INVOICE_PAYMENT_ACTIONS = "INVOICE_PAYMENT_ACTIONS";
export const INITIAL_INVOICE_STATE = "INITIAL_INVOICE_STATE";
export const INVOICE_CUSTOMER_ID = "INVOICE_CUSTOMER_ID";
export const INVOICE_TAB_MOTION = "INVOICE_TAB_MOTION";
export const INVOICE_ENTITY = "INVOICE_ENTITY";
export const ACTIVE_INVOICE_DATA = "ACTIVE_INVOICE_DATA";
export const SMS_GATEWAY_DATA = "SMS_GATEWAY_DATA";
export const INVOICE_ACTION_DATAS = "INVOICE_ACTION_DATAS";
export const INVOICE_WORKFLOW_ACTION_DATAS = "INVOICE_WORKFLOW_ACTION_DATAS";
export const REFETCH_INV_TIMELINE = "REFETCH_INV_TIMELINE";

export const invoiceWorkflowActionDatas = (data) => {
  return {
    type: INVOICE_WORKFLOW_ACTION_DATAS,
    payload: data,
  };
};

export const invoiceActionDatas = (data) => {
  return {
    type: INVOICE_ACTION_DATAS,
    payload: data,
  };
};

export const smsInvoiceGateway = (smsData) => {
  return {
    type: SMS_GATEWAY_DATA,
    payload: smsData,
  };
};

export const invoiceEntity = (name) => {
  return {
    type: INVOICE_ENTITY,
    payload: name,
  };
};

export const activeInvoiceData = (data) => {
  return {
    type: ACTIVE_INVOICE_DATA,
    payload: data,
  };
};

export const invoiceTabMotion = (num) => {
  return {
    type: INVOICE_TAB_MOTION,
    payload: num,
  };
};

export const invoiceCustomerId = (id) => {
  return {
    type: INVOICE_CUSTOMER_ID,
    payload: id,
  };
};

export const initialInvoiceState = () => {
  return {
    type: INITIAL_INVOICE_STATE,
  };
};

export const invoicePaymentForm = (invForm) => {
  return {
    type: INVOICE_PAYMENT_ACTIONS,
    payload: invForm,
  };
};

export const getInvoiceCardData = (data) => {
  return {
    type: GET_INVOICE_CARD_DATA,
    payload: data,
  };
};

export const expandButtonClick = (data) => {
  return {
    type: INVOICE_NOTE_ALERT,
    payload: data,
  };
};

export const refetchInvoiceActions = () => {
  return {
    type: REFETCH_INVOICE_ACTIONS,
  };
};

export const refetchInvTimeline = () => {
  return {
    type: REFETCH_INV_TIMELINE,
  };
};
