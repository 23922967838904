import React, { useContext, useEffect, useState } from "react";
import { TextButton, SIZE, KIND } from "../../../../../components/TextButton";
import { useDispatch } from "react-redux";
import { isOpenModal, refetchTimeline } from "../../../../../redux/actions";
import { useForm, Controller } from "react-hook-form";
import { HeadingXLarge, LabelLarge, ParagraphLarge } from "baseui/typography";
import WhatsApp from "../../../../../assets/img/svg/Template/WhatsApp";
import { SelectBox } from "../../../../../components/SelectBox";
import { useIntl } from "react-intl";
import getSelectValues from "../../../../../utils/getSelectValues";
import {
  executeAdhocCustomerAction,
  getCommunicationTemplateFinalContent,
  getCommunicationTemplateList,
  getCustomerContactDetail,
} from "../../../../../services";
import { useMutation, useQuery } from "react-query";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { COMMUNICATION_TEMPLATE } from "../../../../../constants";
import { refetchInvoiceActions } from "../../../../../redux/invoiceOverview/action";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const WhatsAppAction = ({ getParams, id, entity, tags }) => {
  let dispatch = useDispatch();
  const [customerWhatsAppData, setCustomerWhatsAppData] = useState([]);
  const [whatsAppOptions, setWhatsAppOptions] = useState([]);
  const intl = useIntl();

  const [preview, setPreview] = useState([]);

  const entityName = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_entity
  );

  const { referenceData, currentOrganization, userInfo } =
    useContext(MaxyfiContext);

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-ADHOC-${currentOrganization}`,
      {
        filters: { disable: false, type: "whatsapp", tags: `${tags}` },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  const executeActionMutation = useMutation(
    (formValues) =>
      executeAdhocCustomerAction({
        ...formValues,
        id: id,
        organization: currentOrganization,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        dispatch(refetchInvoiceActions());
        dispatch(refetchTimeline());
        dispatch(isOpenModal(false));
      },
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let template_id = watch("template_id");

  const onSubmit = async (data) => {
    let { value, button_type, ...rest } = preview;

    let values = {
      message_type: "template",
      template_id: getSelectValues(data.template_id),
      recipients: [
        {
          id: data?.contact?.[0]?.id,
          recipient_id: data?.contact?.[0]?.recipient_id,
        },
      ],
      action_type: "whatsapp",
      content: value,
      ...rest,
    };
    await executeActionMutation.mutateAsync(values);
  };

  useEffect(() => {
    const getTemplateContent = async () => {
      let templateContent = await getCommunicationTemplateFinalContent({
        id: id,
        organization: currentOrganization,
        templateId: template_id[0].id,
        entity: entity,
      });

      if (templateContent.data && templateContent.data.doc) {
        let { value, subject } = templateContent.data.doc;

        setPreview(templateContent.data.doc);
      }
    };
    if (template_id && template_id[0] && template_id[0].id) {
      getTemplateContent();
    }
  }, [template_id]);

  const customerContacts = useQuery(
    `customer-contacts-${getParams}`,
    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    let allPhoneNumber = [];
    let allLandLink = [];
    if (customerWhatsAppData && Array.isArray(customerWhatsAppData)) {
      for (let i of customerWhatsAppData?.filter(
        (e) => e?.designation === "primary_contact" || "escalation_contact"
      )) {
        for (let j of i?.phone?.filter(
          (e) => e?.status === "VALID" || e?.status == "LEAD"
        )) {
          allPhoneNumber.push({
            phone_id: j?._id,
            value: j?.value,
            recipient_id: i?._id,
            country_code: j?.country_code,
            name: i?.first_name,
            type: i?.designation,
            basicType: "Phone",
          });
        }
      }
    }

    // if(allPhoneNumber && Array.isArray(allPhoneNumber)){
    //   allPhoneNumber.map
    // }
    // setContactOptions(allPhoneNumber);
    let combinedPhone = [...allPhoneNumber];

    setWhatsAppOptions(combinedPhone);
  }, [customerWhatsAppData]);

  useEffect(() => {
    if (
      customerContacts &&
      customerContacts.data &&
      customerContacts.data.data &&
      customerContacts.data.data.docs &&
      Array.isArray(customerContacts.data.data.docs)
    ) {
      let hasPrimaryContact = false;
      let primaryContacts = customerContacts.data.data.docs.reduce(
        (prev, curr) => {
          let { phone, landline, email, address, ...rest } = curr;
          let nestedContacts = phone
            .map(({ status, whatsapp_status, ...nRest }) => {
              if (
                entityName === "INVOICE" &&
                curr.primary_invoices &&
                Array.isArray(curr.primary_invoices) &&
                curr.primary_invoices.includes(id)
              ) {
                hasPrimaryContact = true;
              }

              return {
                id: nRest?._id,
                recipient_id: curr?._id,
                whatsapp_status,
                status,
                designation: curr.designation,
                primary_invoices: curr.primary_invoices,
              };
            })
            .filter(
              (e) =>
                (e.status === "VALID" || e.status === "LEAD") &&
                e.whatsapp_status === "VALID" &&
                ((!hasPrimaryContact && e.designation === "primary_contact") ||
                  (hasPrimaryContact && e.primary_invoices.includes(id)))
            );

          return [...prev, ...nestedContacts];
        },
        []
      );

      setValue("recipients", primaryContacts);
    }
  }, [customerContacts, customerContacts.isLoading]);

  return (
    <>
      <form
        style={{
          gridColumn: "2/5",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              // display: "grid",
              // gridTemplateRows: "repeat(12,10fr)",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div style={{ padding: "15px 35px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: "5px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <WhatsApp />
                  <HeadingXLarge>WhatsApp</HeadingXLarge>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "0px 35px",
                height: "39vh",
                overflow: "scroll",
                paddingTop: "5px",
              }}
            >
              <Controller
                name="template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "template",
                    })}
                    placeholder={intl.formatMessage({
                      id: "template",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      data &&
                      data.data.docs.map((i) => {
                        return { label: i.name, id: i._id };
                      })
                    }
                  />
                )}
              />
              <Controller
                name="contact"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "primary_contact",
                    })}
                    placeholder={intl.formatMessage({
                      id: "primary_contact",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      customerContacts &&
                      customerContacts.data &&
                      customerContacts.data.data &&
                      customerContacts.data.data.docs &&
                      Array.isArray(customerContacts.data.data.docs)
                        ? customerContacts.data.data.docs.reduce(
                            (prev, curr) => {
                              let { phone, landline, email, address, ...rest } =
                                curr;
                              let nestedContacts = phone
                                .map(
                                  ({ status, whatsapp_status, ...nRest }) => {
                                    let designation =
                                      referenceData &&
                                      referenceData["recipient_type"]
                                        ? referenceData["recipient_type"].find(
                                            (rf) => rf?.id === curr?.designation
                                          )
                                        : "";

                                    return {
                                      label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
                                      id: nRest?._id,
                                      recipient_id: curr?._id,
                                      whatsapp_status,
                                      status,
                                    };
                                  }
                                )
                                .filter(
                                  (e) =>
                                    (e.status === "VALID" ||
                                      e.status === "LEAD") &&
                                    e.whatsapp_status === "VALID"
                                );

                              return [...prev, ...nestedContacts];
                            },
                            []
                          )
                        : []
                    }
                  />
                )}
              />

              {template_id && (
                <div
                  style={{
                    border: "1px solid #ceced9",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  <LabelLarge $style={{ marginBottom: "5px" }}>
                    {preview.subject ? preview.subject : ""}
                  </LabelLarge>

                  <ParagraphLarge $style={{ marginBottom: "5px" }}>
                    {preview.value ? preview.value : ""}
                  </ParagraphLarge>

                  {preview?.button_type == "call_to_action" ? (
                    <>
                      <ParagraphLarge
                        $style={{
                          color: "#516beb",
                          textDecoration: "underline",
                        }}
                      >
                        {preview?.buttons?.[1]?.url}{" "}
                      </ParagraphLarge>
                      <ParagraphLarge $style={{ color: "#516beb" }}>
                        {preview?.buttons?.[0]?.phone_number?.code && "+"}
                        {preview?.buttons?.[0]?.phone_number?.code}{" "}
                        {preview?.buttons?.[0]?.phone_number?.value}
                      </ParagraphLarge>
                    </>
                  ) : preview?.button_type == "quick_reply" ? (
                    preview?.buttons?.map((qic) => (
                      <ParagraphLarge $style={{ color: "#516beb" }}>
                        {qic.text}{" "}
                      </ParagraphLarge>
                    ))
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                background: "#F9F9F9",
                // width: "100%",
                height: "70px",
                padding: "15px",
                borderTop: "1px solid #CDCED9",
                gridRow: "12/13",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      kind={KIND.tertiary}
                      fullWidth
                      type="button"
                      onClick={() => {
                        dispatch(isOpenModal(false));
                      }}
                    >
                      Cancel
                    </TextButton>
                  </div>

                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      type="submit"
                      isLoading={executeActionMutation.isLoading}
                      disabled={executeActionMutation.isLoading}
                    >
                      Send
                    </TextButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default WhatsAppAction;
