import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer, SIZE } from "baseui/drawer";
import { useParams } from "react-router";

import AddContact from "./AddContact";
import CallAction from "./Actions/CallAction";
import EmailAction from "./Actions/EmailAction";
import SMSAction from "./Actions/SMSAction";
import NoteList from "./NoteList";
import ManualCall from "../../containers/CustomerOverView/COTabContainers/Actions/AdhocActionPopup/ManualCall";

import {
  setDrawerState,
  ADD_CONTACT_DRAWER,
  CALL_DRAWER,
  EMAIL_DRAWER,
  NOTE_LIST_DRAWER,
  SMS_DRAWER,
  PORTAL_SETTINGS_DRAWER,
  EDIT_CONTACT,
  ADD_PAYMENT,
  PAYMENT_DRAWER,
  DMS_DRAWER,
  REQUEST_INFORMATION,
  NEXT_ACTION_DRAWER,
  LEGAL_LETTER_DRAWER,
  FIELD_VISIT_DRAWER,
  GENERIC_ACTION_DRAWER,
  CUSTOM_FIELDS_DRAWER,
  DISPUTE_DRAWER,
  TIMELINE_DRAWER,
  REVIEW_PROMISE,
  PAYMENT_CARD_DRAWER,
  PAYMENT_EMAIL_DRAWER,
  EMAIL_TIMELINE_LAYOUT,
  REVIEW_GROUPING_DRAWER,
  EDIT_GROUPING_DRAWER,
  WHATSAPP_DRAWER,
  LETTER_STATUS_EDIT,
  REPLAY_SMS_DRAWER,
  LETTER_STATUS_AUDIT,
  LEGAL_ACTION_DRAWER,
  BANKRUPTCY_FORM,
  MANUAL_GROUPING,
  ITEMIZATION_ENHANCEMENT_DRAWER,
  CREDIT_REPORT,
  WHATSAPP_CHAT,
  ADJUSTMENT_DRAWER,
  ADD_EDIT_RULE_DRAWER,
  // REPORT_DOWNLOA_SETTINGS_DRAWER,
} from "../../redux/customerOverviewDrawer/actions";
import CustomerPortalSettings from "./CustomerPortalSettings";
import NoteContainer from "./Notes/NoteContainer";
import PaymentTable from "./Payment/PaymentTable";
import AddPayment from "./Payment/AddPayment";
import DMSystem_V2 from "./DMSystem_V2";
import { createDocument } from "../../services/documentSystem";
import { addingInvoiceDocumentData } from "../../redux/invoiceAdding/dmsInvoiceAdding/action";
import {
  documentDrawerClose,
  documentDrive,
  documentDriveQuickAction,
  unSelectedDocumentData,
  updateEmailAttachmentFromDesktop,
} from "../../redux/customerOverview/DMSystem/action";
import RequestInformation_V2 from "./RequestInformation_V2";
import Dispute from "./Dispute";
import NextAction from "./NextAction";
import PaymentDataLayer from "./Payment/PaymentDataLayer";
import LegalLetterDataLayer from "./LegalLetter/LegalLetterDataLayer";
import FieldVisitCard from "./Actions/FieldVisit";
import FieldVisitList from "./Actions/FieldVisit";
import GenericAction from "./Actions/GenericAction";
import CustomFields from "./CustomFields/CustomFields";
import TimelineDrawer from "./TimelineDrawer";
import ReviewPromise from "./Actions/ReviewPromise";
import PaymentCard_V2 from "./Actions/PaymentCardDrawer_V2";
import NewEmailAction from "./Actions/NewEmailAction";
import LegalActionDrawer from "./LegalAction/LegalActionDrawer";
import ReviewGroupAction from "./Actions/ReviewGroupAction";
import EditGroupAction from "./Actions/EditGroupAction";
import WhatsAppAction from "./WhatsApp/WhatsAppAction";
import WhatsAppDrawer from "./WhatsApp/WhatsAppAction";
import LetterStatus from "./LetterStatus/LetterStatus";
import { LETTER_STATUS, WHATSAPP } from "../../constants";
import LetterStatusEdit from "./LetterStatus/LetterStatusEdit";
import {
  addSMSAttachment,
  smsDocumentDrive,
  smsUnSelectedDocumentData,
  updateSMSAttachment,
} from "../../redux/customerOverviewDrawer/SMSAction/actions";
import ReplaySMSAction from "./ReplaySMSAction"; //TODO: Karthick Change Component Name
import LetterAudit_V2 from "./LetterStatus/LetterAudit";
import BankruptcyDrawer from "./Actions/Bankruptcy/BankruptcyDrawer";
import ManualGrouping from "./Actions/Grouping/ManualGrouping/ManualGrouping";
import ItemizationDataLayer from "./Itemization/ItemizationDataLayer";
import CreditReportsDrawer from "./CreditReportDrawer";
import WhatsAppChatV2 from "../../components/WhatsAppChat/WhatsAppChatV2";
import Adjustment from "./Adjustment/Adjustment";
import StateRuleDrawer from "./Actions/StateDisclosureRule/StateRuleDrawer";
import ReportSettingDrawer from "./Actions/ReportSettingDrawer";

const DrawerBody = ({ type, ...rest }) => {
  const { customerId } = useParams();

  // DMS redux state
  const { drawerType, drawerState } = useSelector(
    (s) => s.customerDocumentReducer
  );

  const { SMSAttachment, quickActionsTempData } = useSelector(
    (s) => s.SMSAction
  );

  switch (type) {
    case ADD_CONTACT_DRAWER: {
      return <AddContact {...rest} />;
    }

    case EDIT_CONTACT: {
      return <AddContact {...rest} />;
    }

    case CALL_DRAWER: {
      return <CallAction {...rest} />;
    }

    case EMAIL_DRAWER: {
      return (
        <EmailAction
          {...rest}
          entity={"customer"}
          tags={["PROMISE", "CALL_BACK", "PAYMENT_PLAN", "FIELD_VISIT"]}
          master_tag="CUSTOMER"
          removeAttchFile={unSelectedDocumentData}
          updatingDeskUpload={updateEmailAttachmentFromDesktop}
          setAttachment={documentDriveQuickAction}
          id={customerId}
        />
      );
    }
    case EMAIL_TIMELINE_LAYOUT: {
      //TODO: Karthick Change Type
      return <NewEmailAction {...rest} />; //TODO: Karthick Change Component Name
    }

    case ADD_EDIT_RULE_DRAWER: {
      return <StateRuleDrawer {...rest} />;
    }

    case NOTE_LIST_DRAWER: {
      return <NoteList {...rest} />;
    }

    case SMS_DRAWER: {
      return (
        <SMSAction
          {...rest}
          tags={["PROMISE", "CALL_BACK", "PAYMENT_PLAN", "FIELD_VISIT"]}
          master_tag="CUSTOMER"
          fileObject={addSMSAttachment}
          removeAttchFile={smsUnSelectedDocumentData}
          updatingDeskUpload={updateSMSAttachment}
          setAttachment={smsDocumentDrive}
        />
      );
    }

    case REPLAY_SMS_DRAWER: {
      return <ReplaySMSAction {...rest} />;
    }

    case PORTAL_SETTINGS_DRAWER: {
      return <CustomerPortalSettings {...rest} />;
    }
    // case REPORT_DOWNLOA_SETTINGS_DRAWER: {
    //   return <ReportSettingDrawer {...rest} />;
    // }

    case REVIEW_GROUPING_DRAWER: {
      return <ReviewGroupAction {...rest} />;
    }

    case EDIT_GROUPING_DRAWER: {
      return <EditGroupAction {...rest} />;
    }

    case PAYMENT_DRAWER: {
      return <PaymentTable {...rest} />;
    }

    case ADD_PAYMENT: {
      return <PaymentDataLayer {...rest} />;
    }

    case DMS_DRAWER: {
      return (
        <DMSystem_V2
          {...rest}
          entity={"customer"}
          resourceId={customerId}
          createDocument={createDocument}
          addingSelectedDocument={addingInvoiceDocumentData}
          closeDocmentDrawer={documentDrawerClose}
          documentDrive={documentDrive}
          documentDrawertype={drawerType}
        />
      );
    }

    case CREDIT_REPORT: {
      return <CreditReportsDrawer />;
    }

    case DISPUTE_DRAWER: {
      return <Dispute />;
    }

    case REQUEST_INFORMATION: {
      return <RequestInformation_V2 />;
    }

    case NEXT_ACTION_DRAWER: {
      return <NextAction />;
    }

    case LEGAL_LETTER_DRAWER: {
      return <LegalLetterDataLayer />;
    }

    case FIELD_VISIT_DRAWER: {
      return <FieldVisitList />;
    }

    case GENERIC_ACTION_DRAWER: {
      return <GenericAction />;
    }

    case CUSTOM_FIELDS_DRAWER: {
      return <CustomFields />;
    }

    case TIMELINE_DRAWER: {
      return <TimelineDrawer />;
    }

    case REVIEW_PROMISE: {
      return <ReviewPromise />;
    }

    case PAYMENT_CARD_DRAWER: {
      return <PaymentCard_V2 />;
    }

    case PAYMENT_EMAIL_DRAWER: {
      return (
        <EmailAction
          {...rest}
          entity={"customer"}
          tags={"PAYMENT"}
          removeAttchFile={unSelectedDocumentData}
          updatingDeskUpload={updateEmailAttachmentFromDesktop}
          setAttachment={documentDriveQuickAction}
          id={customerId}
          action="PAYMENT"
        />
      );
    }

    case LEGAL_ACTION_DRAWER: {
      return <LegalActionDrawer />;
    }
    case WHATSAPP_DRAWER:
      return <WhatsAppDrawer />;

    case LETTER_STATUS:
      return <LetterStatus />;

    case LETTER_STATUS_EDIT:
      return <LetterStatusEdit />;

    case LETTER_STATUS_AUDIT:
      return <LetterAudit_V2 />;

    case BANKRUPTCY_FORM:
      return <BankruptcyDrawer closeDrawer={rest.closeDrawer} />;
    case MANUAL_GROUPING:
      return <ManualGrouping />;

    case ITEMIZATION_ENHANCEMENT_DRAWER:
      return <ItemizationDataLayer closeDrawer={rest.closeDrawer} />;

    case WHATSAPP_CHAT:
      return <WhatsAppChatV2 />;
    case ADJUSTMENT_DRAWER:
      return <Adjustment />;

    default: {
      return <></>;
    }
  }
};

const CODrawer = () => {
  const dispatch = useDispatch();

  const { active, type } = useSelector((state) => state.customerOverviewDrawer);

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  return (
    <Drawer
      isOpen={active}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
        DrawerBody: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
            padding: "15px",
            overflowY: "scroll",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
      }}
      size={SIZE.auto}
      autoFocus={false}
      onClose={() => {
        closeDrawer();
      }}
      closeable
      onBackdropClick={() => {
        closeDrawer();
      }}
    >
      {active && <DrawerBody type={type} closeDrawer={closeDrawer} />}
    </Drawer>
  );
};

export default CODrawer;
