export default function HoldAction({ size = 22, color = "#000000" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.625 13.875C11.3183 13.875 11.0541 13.7642 10.8324 13.5425C10.6108 13.3209 10.5 13.0567 10.5 12.75V5.25C10.5 4.94327 10.6108 4.6791 10.8324 4.45747C11.0541 4.23583 11.3183 4.12502 11.625 4.12502H12.1875C12.4943 4.12502 12.7584 4.23583 12.9801 4.45747C13.2017 4.6791 13.3125 4.94327 13.3125 5.25V12.75C13.3125 13.0567 13.2017 13.3209 12.9801 13.5425C12.7584 13.7642 12.4943 13.875 12.1875 13.875H11.625ZM5.81249 13.875C5.50577 13.875 5.24159 13.7642 5.01997 13.5425C4.79834 13.3209 4.68753 13.0567 4.68753 12.75V5.25C4.68753 4.94327 4.79834 4.6791 5.01997 4.45747C5.24159 4.23583 5.50577 4.12502 5.81249 4.12502H6.37503C6.68176 4.12502 6.94594 4.23583 7.16757 4.45747C7.3892 4.6791 7.50001 4.94327 7.50001 5.25V12.75C7.50001 13.0567 7.3892 13.3209 7.16757 13.5425C6.94594 13.7642 6.68176 13.875 6.37503 13.875H5.81249Z"
        fill={color}
      />
    </svg>
  );
}
