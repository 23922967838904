import React from "react";

const ContactTimelineSvg = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.96296 10.6296C5.96296 11.0388 5.63142 11.3704 5.22222 11.3704H3.74074C3.33155 11.3704 3 11.0388 3 10.6296C3 10.2204 3.33155 9.88889 3.74074 9.88889H5.22222C5.63146 9.88889 5.96296 10.2204 5.96296 10.6296ZM5.96296 5.88889C5.96296 5.47969 5.63142 5.14815 5.22222 5.14815H3.74074C3.33155 5.14815 3 5.47969 3 5.88889C3 6.29808 3.33155 6.62963 3.74074 6.62963H5.22222C5.63146 6.62963 5.96296 6.29808 5.96296 5.88889ZM5.96296 15.3704C5.96296 14.9611 5.63142 14.6297 5.22222 14.6297H3.74074C3.33155 14.6297 3 14.9611 3 15.3704C3 15.7796 3.33155 16.1112 3.74074 16.1112H5.22222C5.63146 16.1112 5.96296 15.7796 5.96296 15.3704ZM19 3.37037V17.8889C19 19.198 17.9387 20.2593 16.6297 20.2593H6.25935C4.95033 20.2593 3.88898 19.198 3.88898 17.8889V16.7037H5.22231C5.95743 16.7037 6.55564 16.1054 6.55564 15.3704C6.55564 14.6352 5.95743 14.0369 5.22231 14.0369H3.88889V11.9629H5.22222C5.95734 11.9629 6.55556 11.3647 6.55556 10.6295C6.55556 9.89442 5.95734 9.29621 5.22222 9.29621H3.88889V7.22213H5.22222C5.95734 7.22213 6.55556 6.62392 6.55556 5.8888C6.55556 5.15368 5.95734 4.55547 5.22222 4.55547H3.88889V3.37037C3.88889 2.06135 4.95024 1 6.25926 1H16.6296C17.9386 1 19 2.06131 19 3.37037ZM9.60327 8.02636C9.60327 9.28858 10.6267 10.312 11.8889 10.312C13.1512 10.312 14.1745 9.28858 14.1745 8.02636C14.1745 6.76414 13.1512 5.7407 11.8889 5.7407C10.6267 5.74074 9.60327 6.76414 9.60327 8.02636ZM15.4444 13.6136C15.4444 12.2112 14.3073 11.074 12.9049 11.074H10.8732C9.47054 11.074 8.33333 12.2113 8.33333 13.6136V14.6296H15.4444V13.6136Z"
        fill="#516beb"
      />
    </svg>
  );
};

export default ContactTimelineSvg;
