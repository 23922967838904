import React from "react";
import { DatePicker } from "baseui/datepicker";
import moment from "moment";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

const ReportDatepicker = (props) => {
  let {
    value = " ",
    setValue,
    setIsActive = () => {},
    count = 0,
    setCount = () => {},
  } = props;

  const { currentDefaultFormatDetails } = React.useContext(MaxyfiContext);

  let dateFormat =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails?.date_format &&
    currentDefaultFormatDetails?.date_format.replace(/dd|yyyy/gi, function (x) {
      return x.toLowerCase();
    });

  return (
    <DatePicker
      overrides={{
        Input: {
          props: {
            overrides: {
              Root: {
                style: ({ $theme }) => ({
                  outline: "0px",
                  backgroundColor: "#ffffff",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  border: "1px solid #c7c7c7",
                }),
              },
              Input: {
                style: ({ $theme }) => ({
                  outline: `0px`,
                  backgroundColor: "#ffffff",
                }),
              },
            },
          },
        },
      }}
      value={value}
      onChange={({ date }) => {
        setValue(date);
        setIsActive("Custom Date");
        setCount(count + 1);
      }}
      formatString={dateFormat}
      {...props}
    />
  );
};

export default ReportDatepicker;
