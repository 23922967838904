import React, { useContext } from "react";
import { MaxyfiContext } from "../providers/MaxyfiProvider";

function RDRapper(rdName, id) {
  let { referenceData } = useContext(MaxyfiContext);

  // if (
  //   !rdName ||
  //   !id ||
  //   !(
  //     referenceData &&
  //     referenceData[rdName] &&
  //     Array.isArray(referenceData[rdName])
  //   )
  // ) {
  //   return null;
  // }
  let rdValue = "";

  if (rdName && id) {
    rdValue = referenceData && referenceData[rdName].find((e) => e.id === id);
  } else {
    rdValue = "-";
  }

  return rdValue?.label ? rdValue?.label : "-";
}

export default RDRapper;
