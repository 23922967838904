export const ADD_ADHOC_UPLOAD_DATA = "ADD_ADHOC_UPLOAD_DATA";
export const UPDATE_MAP_FIELDS = "UPDATE_MAP_FIELDS";
export const UPDATE_FORMAT_DATA = "UPDATE_FORMAT_DATA";
export const UPDATE_DEFAULT_DATA = "UPDATE_DEFAULT_DATA";
export const UPDATE_SYSTEM_FIELDS = "UPDATE_SYSTEM_FIELDS";
export const UPDATE_TEMPLATE_DATA_LIST = "UPDATE_TEMPLATE_DATA_LIST";
export const INITIALSTATE_STATE = "INITIALSTATE_STATE";
export const ADHOC_REFETCH = "ADHOC_REFETCH";
export const SET_AU_FILTER = "SET_AU_FILTER";
export const ADHOC_FILTER = "ADHOC_FILTER";
export const VALIDATION_ADHOC_FILTER = "VALIDATION_ADHOC_FILTER";
export const BUSINESS_UNIT_DATA = "BUSINESS_UNIT_DATA";
export const FORMULA_FIELDS = "FORMULA_FIELDS";
export const DELETE_FORMULA_FIELDS = "DELETE_FORMULA_FIELDS";
export const UPDATE_FORMULA_FIELDS = "UPDATE_FORMULA_FIELDS";
export const UPDATE_CONTACT_INDEX = "UPDATE_CONTACT_INDEX";
export const UPDATE_SYSTEM_FIELDS_CLEAR = "UPDATE_SYSTEM_FIELDS_CLEAR";

export const setAdhocFilter = (data) => {
  return {
    type: ADHOC_FILTER,
    payload: data,
  };
};

export const setAdhocUploadFilter = (data) => {
  return {
    type: SET_AU_FILTER,
    payload: data,
  };
};

export const setInitialAdhocState = () => {
  return {
    type: INITIALSTATE_STATE,
  };
};

export const setAdhocUploadData = (payload) => {
  return {
    type: ADD_ADHOC_UPLOAD_DATA,
    payload,
  };
};

export const updateSystemField = ({ index, systemField, type, module }) => {
  return {
    type: UPDATE_SYSTEM_FIELDS,
    payload: { index, systemField, type, module },
  };
};

export const updateSystemFieldOnClear = ({
  index,
  systemField,
  type,
  module,
}) => {
  return {
    type: UPDATE_SYSTEM_FIELDS,
    payload: { index, systemField, type, module },
  };
};

export const updateFormatData = ({ index, formatData }) => {
  return {
    type: UPDATE_FORMAT_DATA,
    payload: { index, formatData },
  };
};

export const updateContactIndex = ({ index, contactIndex, module }) => {
  return {
    type: UPDATE_CONTACT_INDEX,
    payload: { index, contactIndex, module },
  };
};

export const updateDefaultData = ({ index, defaultData, referance }) => {
  return {
    type: UPDATE_DEFAULT_DATA,
    payload: { index, defaultData, referance },
  };
};

export const updateTemplateDataList = (templateData) => {
  return {
    type: UPDATE_TEMPLATE_DATA_LIST,
    payload: { ...templateData },
  };
};

export const adhocRefetchTable = () => {
  return {
    type: ADHOC_REFETCH,
  };
};

export const validationFields = () => {
  return {
    type: VALIDATION_ADHOC_FILTER,
  };
};

export const businessUnitData = ({ rdBussinessUnit }) => {
  return {
    type: BUSINESS_UNIT_DATA,
    payload: rdBussinessUnit,
  };
};

export const formulaDispachFn = (payload) => {
  return {
    type: FORMULA_FIELDS,
    payload,
  };
};

export const deleteFormulaField = (payload) => {
  return {
    type: DELETE_FORMULA_FIELDS,
    payload,
  };
};

export const updateFormulaField = (payload) => {
  return {
    type: UPDATE_FORMULA_FIELDS,
    payload,
  };
};
