import React, { useContext, useEffect, useMemo, useState } from "react";
import { Icon, Typography } from "../../../../components_v2";
import law from "./../../../../assets/img/law.png";
import { SIZE, Select, Value } from "baseui/select";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { size } from "lodash";
import { SelectBox } from "../../../../components/SelectBox";
import { KIND, TextButton } from "../../../../components/TextButton";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import Motiontable from "./Motiontable";
import queryClient from "../../../../providers/queryClient";
import { LEGAL_ACTION_INFO_ } from "../../../../constants";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  addEditMotionLegal,
  addMotionLegal,
  deletelegalMotion,
} from "../../../../services";
import getSelectValues from "../../../../utils/getSelectValues";
import moment from "moment";
import { utcTimstampToLocalDate } from "../../../../utils/utcTimstampToLocalDate";
import setSelectValues from "../../../../utils/setSelectValues";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { ADD_EDIT_MOTIONS_COUNSEL_LEGAL } from "../../../../providers/RBACProvider/permissionList";

const MotionForm = ({
  control,
  errors,
  handleSubmit,
  setOpen,
  setEditEnable,
  selectedMotion,
  setValue,
}) => {
  const intl = useIntl();
  let { referenceData } = useContext(MaxyfiContext);
  return (
    <form onSubmit={handleSubmit}>
      <div
        className="motion-selection-container"
        style={{ paddingTop: "10px" }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <Controller
            name="motion_type"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                creatable
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "motion_type",
                })}
                placeholder={intl.formatMessage({
                  id: "motion_type",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["legal_motion_type_list"] || []}
              />
            )}
          />
          <Controller
            name="start_date"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                clearable={false}
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "start_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
          <Controller
            name="hearing_date"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                clearable={false}
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "hearing_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div className="motion-add_button">
          <TextButton
            size="mini"
            kind={KIND.tertiary}
            type="button"
            onClick={() => {
              setOpen(false);
              setEditEnable(false);
            }}
          >
            Cancel
          </TextButton>
          <TextButton size="mini" kind={KIND.primary} type="submit">
            save
          </TextButton>
        </div>
      </div>
    </form>
  );
};

const Motion = () => {
  const { customerId } = useParams();
  const [open, setOpen] = useState(false);
  const [selectedMotion, setSelectedMotion] = React.useState({});
  const [deletedMotion, setDeletedMotion] = useState("");
  const [editEnable, setEditEnable] = React.useState(false);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const schema = yup.object().shape({
    motion_type: yup
      .array()
      .typeError("Start Date is required")
      .min(1, "Type isRequired")
      .required("Type is Mandatory"),

    start_date: yup
      .date()
      .typeError("Start Date is required")
      .required("Start Date is required"),
    hearing_date: yup
      .date()
      .typeError("Hearing Date is required")
      .required("Hearing Date is required"),
    // .when("start_date", (startDate, schema) => {
    //   // Make hearing_date dependent on start_date
    //   return startDate
    //     ? schema.min(startDate, "Hearing Date must be later than Start Date")
    //     : schema;
    // }),
  });

  let { currentOrganization, currentDefaultFormatDetails, refetch } =
    useContext(MaxyfiContext);
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );
  const legalMotionlist = useMemo(() => {
    const legalStatuslist = _.get(legalInfo, "data.doc.mot", []);

    return legalStatuslist;
  }, [legalInfo?.data]);

  let startDate = utcTimstampToLocalDate(_.get(selectedMotion, "std"));
  let hearingDate = utcTimstampToLocalDate(_.get(selectedMotion, "hrd"));

  useEffect(() => {
    if (editEnable && Object.keys(selectedMotion)) {
      setOpen(true);
      setValue("motion_type", setSelectValues(_.get(selectedMotion, "typ")));
      setValue("start_date", new Date(startDate));
      setValue("hearing_date", new Date(hearingDate));
    }
  }, [selectedMotion, editEnable]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const submitMotion = useMutation(
    (data) =>
      addMotionLegal({
        ...data,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries([`${LEGAL_ACTION_INFO_}${customerId}`]);
        setOpen(false);
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
      },
    }
  );
  const submitEditMotion = useMutation(
    (data) =>
      addEditMotionLegal({
        ...data,
        customerId: customerId,
        id: _.get(selectedMotion, "_id", ""),
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries([`${LEGAL_ACTION_INFO_}${customerId}`]);
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
        setOpen(false);
        setEditEnable(false);
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {};

    const motionType = getSelectValues(_.get(data, "motion_type", ""));
    if (motionType) {
      values.typ = motionType;
    }

    const startDate = _.get(data, "start_date", "");
    if (startDate) {
      const formattedStartDate = moment
        .tz(
          `${startDate.getFullYear()}-${
            startDate.getMonth() + 1
          }-${startDate.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf();
      values.std = formattedStartDate;
    }

    const hearingDate = _.get(data, "hearing_date", "");
    if (hearingDate) {
      const formattedHearingDate = moment
        .tz(
          `${hearingDate.getFullYear()}-${
            hearingDate.getMonth() + 1
          }-${hearingDate.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf();
      values.hrd = formattedHearingDate;
    }

    if (editEnable) {
      await submitEditMotion.mutateAsync(values);
    } else {
      await submitMotion.mutateAsync(values);
    }
  };

  const handleOpenMotion = () => {
    setOpen(true);
    setValue("motion_type", []);
    setValue("start_date", "");
    setValue("hearing_date", "");
  };

  return (
    <div className="motion-landing-container">
      <RBACWrapper role={ADD_EDIT_MOTIONS_COUNSEL_LEGAL} type="PERMISSION">
        <div className="legal-add-motion_button" onClick={handleOpenMotion}>
          <Icon icon="plus" color="#516BEB" />
          <Typography type="p" subType="regular" className="text-primary">
            Add Motion
          </Typography>
        </div>
      </RBACWrapper>
      {open || editEnable ? (
        <MotionForm
          control={control}
          errors={errors}
          handleSubmit={handleSubmit(onSubmit)}
          setOpen={setOpen}
          setEditEnable={setEditEnable}
          selectedMotion={selectedMotion}
          setValue={setValue}
        />
      ) : legalMotionlist.length > 0 ? (
        <Motiontable
          selectedMotion={setSelectedMotion}
          setEditEnable={setEditEnable}
          setDeletedMotion={setDeletedMotion}
        />
      ) : (
        <div className="legal-law_image">
          <img src={law} alt="" width={94} height={94} />
          <Typography type="p" className="text-secondary">
            Add Motions above
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Motion;
