import React from "react";

const CardAlert = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.6364 12.0859C16.6227 12.0859 14.8045 11.2313 13.5364 9.87024H0V17.8286C0 19.0269 0.977273 19.9991 2.18182 19.9991H18.1818C19.3864 19.9991 20.3636 19.0269 20.3636 17.8286V11.8689C19.8091 12.0091 19.2318 12.0859 18.6364 12.0859ZM16 16.3817H11.6364C11.2364 16.3817 10.9091 16.0561 10.9091 15.6582C10.9091 15.2602 11.2364 14.9347 11.6364 14.9347H16C16.4 14.9347 16.7273 15.2602 16.7273 15.6582C16.7273 16.0561 16.4 16.3817 16 16.3817ZM11.6909 5.5293H2.18182C0.977273 5.5293 0 6.50149 0 7.69977V8.42326H12.5C12.0318 7.55507 11.7455 6.56932 11.6909 5.5293Z"
        fill="#333860"
      />
      <path
        d="M18.4822 6.18132C18.6004 6.18132 18.7004 6.14063 18.7822 6.06376C18.8594 5.98689 18.9004 5.88741 18.9004 5.76532V3.08388C18.9004 2.97536 18.8594 2.8804 18.7731 2.79901C18.6913 2.72213 18.5913 2.68144 18.4822 2.68144C18.364 2.68144 18.264 2.72213 18.1822 2.79901C18.1049 2.87588 18.064 2.97536 18.064 3.09745V5.77888C18.064 5.88741 18.1049 5.98237 18.1913 6.06376C18.2731 6.14063 18.3685 6.18132 18.4822 6.18132ZM18.4413 8.29301C18.5731 8.29301 18.6776 8.2478 18.7685 8.16188C18.8594 8.07597 18.9004 7.96744 18.9004 7.83631C18.9004 7.70518 18.8549 7.60118 18.7685 7.51074C18.6822 7.42482 18.5731 7.37961 18.4413 7.37961C18.3094 7.37961 18.2049 7.42482 18.114 7.51074C18.0276 7.59665 17.9822 7.70518 17.9822 7.83631C17.9822 7.96744 18.0276 8.07144 18.114 8.16188C18.2004 8.25232 18.3094 8.29301 18.4413 8.29301ZM18.4413 11.0558C17.6549 11.0558 16.9231 10.9157 16.2458 10.6353C15.5685 10.355 14.9822 9.96609 14.4822 9.46869C13.9822 8.97129 13.5913 8.38797 13.3094 7.71422C13.0276 7.04047 12.8867 6.31246 12.8867 5.53018C12.8867 4.75695 13.0276 4.03346 13.3094 3.35971C13.5913 2.68596 13.9822 2.10265 14.4822 1.60525C14.9822 1.10785 15.5685 0.714447 16.2458 0.429573C16.9231 0.144698 17.6549 0 18.4413 0C19.2185 0 19.9458 0.144698 20.6231 0.429573C21.3004 0.714447 21.8867 1.10785 22.3867 1.60525C22.8867 2.10265 23.2822 2.68596 23.5685 3.35971C23.8549 4.03346 24.0004 4.75695 24.0004 5.53018C24.0004 6.31246 23.8549 7.04047 23.5685 7.71422C23.2822 8.38797 22.8867 8.97129 22.3867 9.46869C21.8867 9.96609 21.3004 10.355 20.6231 10.6353C19.9458 10.9157 19.2185 11.0558 18.4413 11.0558Z"
        fill="#FD372A"
      />
    </svg>
  );
};

export default CardAlert;
