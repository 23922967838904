import { Drawer } from "baseui/drawer";
import { LabelLarge } from "baseui/typography";
import { useForm, Controller } from "react-hook-form";
import DateRangeSelect from "../../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import {
  TextButton,
  SIZE,
  KIND,
} from "../../../../../../components/TextButton";
import { SelectBox } from "../../../../../../components/SelectBox";
import { useIntl } from "react-intl";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  editFieldVisit,
  getCustomerContactDetail,
} from "../../../../../../services";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import CheckBoxBaseweb from "../../../../../../components/CheckBoxBaseweb";
import { FormattedMessage } from "react-intl";
import { TextBox } from "../../../../../../components/TextBox";
import getSelectValues from "../../../../../../utils/getSelectValues";
import setSelectValues from "../../../../../../utils/setSelectValues";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { refetchActions } from "../../../../../../redux/actions";
import { getOrganization } from "../../../../../../services";
import { getCommunicationTemplateList } from "../../../../../../services";

const FieldVisitDrawer = ({ state, setState, editData }) => {
  let dispatch = useDispatch();
  const { referenceData, currentOrganization, userInfo } =
    useContext(MaxyfiContext);
  const intl = useIntl();

  const orgRef = referenceData.organizations.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef.time_zone;
  const [customerContactsData, setCustomerContactsData] = useState([]);
  const [autoLocation, setAutoLocation] = useState([]);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [settingData, setSettingData] = useState(null);
  let [confirmationTemplates, setConfirmationTemplates] = useState([]);
  let [followUpTemplates, setFollowUpTemplates] = useState([]);

  const getParams = getSearchParams.get("customers");

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      pause_workflow_till_field_visit_date: false,
      is_send_confirmation_message: false,
      send_field_visit_follow_up_message: false,
    },
  });

  let {
    location,
    is_send_confirmation_message,
    send_field_visit_follow_up_message,
  } = watch();

  let [confirmationMessageType, followUpType] = watch([
    "confirmation_message_type",
    "action_follow_up_type",
  ]);

  let selectedId = getSelectValues(location);

  const { data, isFetched } = useQuery(
    `customer-contacts-${getParams}`,
    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags: [
          "CALL_FIELD_VISIT",
          "NEXT_ACTION_FIELD_VISIT",
          "VISIT_UNSUCESSFULL",
          "RETURN_VISIT",
        ],
        type,
      },
      organization: currentOrganization,

      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (data) {
      setCustomerContactsData(data && data?.data && data?.data?.docs);
    }
  }, [data]);

  useEffect(() => {
    if (customerContactsData && Array.isArray(customerContactsData)) {
      if (customerContactsData) {
        customerContactsData.reduce((prev, curr) => {
          let { phone, landline, email, address, ...rest } = curr;
          let nestedContacts = address
            .map(({ status, call_status, ...nRest }) => {
              let designation =
                referenceData && referenceData["recipient_type"]
                  ? referenceData["recipient_type"].find(
                      (rf) => rf?.id === curr?.designation
                    )
                  : "";

              return {
                label: `${curr?.first_name ? curr?.first_name : ""} - ${
                  nRest.line_1
                }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
                  nRest.city
                }-${nRest.zip_code} ${designation?.label}`,
                id: curr?._id,
                status,
              };
            })
            .filter((e) => e.status === "VALID" || e.status === "LEAD");
          setAutoLocation([
            ...prev,
            ...nestedContacts,
            { id: "organization", label: "Organization Address" },
            { id: "other", label: "Other Location" },
          ]);
          return [...prev, ...nestedContacts];
        }, []);
      }
    }
  }, [customerContactsData]);

  const putFieldVisitCard = useMutation(
    (data) =>
      editFieldVisit({
        organization: currentOrganization,
        customerId: getParams,
        // checkinId: editingDatas._id,
        fieldVisitId: editData && editData._id,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        // setCapture(false);
        //
        dispatch(refetchActions());
        // setIsAddNew(false);
        // setRefetchCheckIn(refetchCheckIn + 1);
      },
    }
  );

  useEffect(() => {
    if (editData) {
      let selectedTime =
        referenceData["time"] &&
        referenceData["time"]?.find((e) => e?.id === editData?.visit_time);

      setValue(
        "visit_time",
        selectedTime ? [selectedTime] : []
        // setSelectValues(editData?.visit_time)
      );
      setValue("visit_date", editData?.visit_date);

      setValue(
        "pause_workflow_till_field_visit_date",
        editData?.is_hold_workflow
      );

      setValue(
        "is_send_confirmation_message",
        editData?.is_send_confirmation_message
      );
      setValue(
        "send_field_visit_follow_up_message",
        editData?.is_send_action_follow_up
      );

      setValue(
        "confirmation_message_type",
        setSelectValues(editData?.confirmation_message_type)
      );
      setValue(
        "confirmation_message_template_id",
        setSelectValues(editData?.confirmation_message_template_id)
      );
      setValue(
        "action_follow_up_type",
        setSelectValues(editData?.action_follow_up_type)
      );
      setValue(
        "action_follow_up_template_id",
        setSelectValues(editData?.action_follow_up_template_id)
      );

      if (
        editData &&
        editData?.custom_location &&
        editData?.custom_location?.length > 0
      ) {
        setValue("your_location", editData && editData.custom_location);
        setValue("location", [{ id: "other", label: "Other Location" }]);
      } else {
        setValue("location", [
          {
            id: editData && editData.address_ref,
          },
        ]);
      }
    }
  }, [editData]);

  const onSubmit = async (data) => {
    let visitDate = moment(data.visit_date);

    let values = {
      visit_date: moment
        .tz(
          `${visitDate.year()}-${visitDate.month() + 1}-${visitDate.date()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .utc()
        .valueOf(),
      visit_time: getSelectValues(data.visit_time),
      address_ref: getSelectValues(data.location),
      // address_ref: editData && editData.address_ref,
      // recipient_ref: editData && editData.recipient_ref,
      ...(data.your_location && selectedId === "other"
        ? { custom_location: data.your_location }
        : {
            custom_location: "",
          }),
      // custom_location: ,
      is_hold_workflow: data.pause_workflow_till_field_visit_date,
      is_send_confirmation_message: data.is_send_confirmation_message,
      is_send_action_follow_up: data.send_field_visit_follow_up_message,
      ...(data.is_send_confirmation_message
        ? {
            confirmation_message_type: getSelectValues(
              data.confirmation_message_type
            ),
            confirmation_message_template_id: getSelectValues(
              data.confirmation_message_template_id
            ),
          }
        : {}),
      ...(data.send_field_visit_follow_up_message
        ? {
            action_follow_up_type: getSelectValues(data.action_follow_up_type),
            action_follow_up_template_id: getSelectValues(
              data.action_follow_up_template_id
            ),
          }
        : {}),
    };

    await putFieldVisitCard.mutateAsync(values);
  };

  let type = referenceData["template_type"];

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: [
          "CALL_FIELD_VISIT",
          "NEXT_ACTION_FIELD_VISIT",
          "VISIT_UNSUCESSFULL",
          "RETURN_VISIT",
        ],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  useEffect(() => {
    if (followUpType && followUpType[0] && followUpType[0].id) {
      setFollowUpTemplates([]);
      getTemplates({
        tags: [
          "CALL_FIELD_VISIT",
          "NEXT_ACTION_FIELD_VISIT",
          "VISIT_UNSUCESSFULL",
          "RETURN_VISIT",
        ],
        type: followUpType[0].id,
        setter: setFollowUpTemplates,
      });
    }
  }, [followUpType]);

  useEffect(async () => {
    await getOrganization({ organization: currentOrganization }).then((res) => {
      setSettingData(res.data.doc || {});
    });
    //TODO: PRAVEEN FAILURE ERROR HANDLING
  }, []);

  let mappinTypeValue = type.map((e) => {
    if (e.id == "email") {
      return { ...e };
    } else if (e.id === "sms") {
      return { ...e };
    } else if (e.id === "sms" && !settingData?.sms_details?.is_configured) {
      return { ...e, disabled: true };
    }
    return e;
  });

  return (
    <>
      <Drawer
        isOpen={state}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              marginTop: "0px",
              padding: "15px",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
        onClose={() => {
          // setState(false);
          //   dispatch(closeModal());
          //   dispatch(resetDiaryNoteReduxState());
        }}
        // ref={findFullWidth}
      >
        <div
        //   style={{
        //     marginBottom: "15px",
        //     display: "flex",
        //     justifyContent: "space-between",
        //     alignItems: "center",
        //   }}
        >
          <LabelLarge>Field visit </LabelLarge>

          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingTop: "20px" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ width: "175px" }}>
                <Controller
                  defaultValues={[]}
                  name="visit_time"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.compact}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "visit_time",
                      })}
                      placeholder={intl.formatMessage({
                        id: "visit_time",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={
                        referenceData && referenceData["time"]
                          ? referenceData["time"]
                          : []
                      }
                      // options={[{ id: "field_visit", label: "Field Visit" }]}
                      // requiredAstric={true}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="visit_date"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <DateRangeSelect
                      clearable={false}
                      size={SIZE.compact}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "visit_date",
                      })}
                      placeholder={intl.formatMessage({
                        id: " ",
                      })}
                      value={field.value}
                      onChange={(e) => field.onChange(e.date)}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
            </div>

            <div style={{ width: "100%", paddingTop: "5px" }}>
              <Controller
                name="location"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.compact}
                    {...field}
                    name={field.name}
                    // requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "location",
                    })}
                    placeholder={intl.formatMessage({
                      id: "location",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={autoLocation}
                  />
                )}
              />
            </div>

            {selectedId === "other" ? (
              <>
                <Controller
                  defaultValue={""}
                  name="your_location"
                  control={control}
                  // rules={{ required: "Required" }}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        // type="number"
                        size={SIZE.compact}
                        fullWidth
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "your_location",
                        })}
                        placeholder={intl.formatMessage({
                          id: "your_location",
                        })}
                        value={field.value}
                      />
                    </>
                  )}
                />
              </>
            ) : (
              <></>
            )}

            <div style={{ padding: "10px 0px" }}>
              <Controller
                defaultValues={false}
                name="pause_workflow_till_field_visit_date"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    // disabled={true}
                    checked={field.value}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <FormattedMessage
                      id="pause_workflow_till_field_visit_date"
                      // id="hold_workflow_till_call_back_date"
                    >
                      pause_workflow_till_field_visit_date
                    </FormattedMessage>
                  </CheckBoxBaseweb>
                )}
              />
            </div>

            <div style={{ padding: "10px 0px" }}>
              <div>
                <Controller
                  defaultValues={false}
                  name="is_send_confirmation_message"
                  control={control}
                  render={({ field }) => (
                    <CheckBoxBaseweb
                      {...field}
                      checked={field.value}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      <FormattedMessage id="send_confirmation_message">
                        send_confirmation_message
                      </FormattedMessage>
                    </CheckBoxBaseweb>
                  )}
                />
              </div>
              {is_send_confirmation_message ? (
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="cp-message-confirmation-type">
                    <Controller
                      defaultValues={[]}
                      name="confirmation_message_type"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          fullWidth
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "comm_type",
                          })}
                          placeholder={intl.formatMessage({
                            id: "comm_type",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                              setValue("confirmation_message_template_id", []);
                            }
                            field.onChange(e.value);
                          }}
                          options={mappinTypeValue}
                        />
                      )}
                    />
                  </div>
                  <div className="cp-action-promise-pay-con">
                    <Controller
                      name="confirmation_message_template_id"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          fullWidth
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "field_visit_confirmation",
                          })}
                          placeholder={intl.formatMessage({
                            id: "field_visit_confirmation",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={confirmationTemplates || []}
                        />
                      )}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div style={{ margin: "7px 0px" }}>
              <div>
                <Controller
                  defaultValues={false}
                  name="send_field_visit_follow_up_message"
                  control={control}
                  render={({ field }) => (
                    <CheckBoxBaseweb
                      {...field}
                      checked={field.value}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      <FormattedMessage id="send_field_visit_follow_up_message">
                        send_field_visit_follow_up_message
                      </FormattedMessage>
                    </CheckBoxBaseweb>
                  )}
                />
              </div>
              {send_field_visit_follow_up_message ? (
                <div style={{ display: "flex", gap: "10px" }}>
                  <div className="cp-message-confirmation-type">
                    <Controller
                      name="action_follow_up_type"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          fullWidth
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "comm_type",
                          })}
                          placeholder={intl.formatMessage({
                            id: "comm_type",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                              setValue("action_follow_up_template_id", []);
                            }
                            field.onChange(e.value);
                          }}
                          options={mappinTypeValue}
                        />
                      )}
                    />
                  </div>
                  <div className="cp-action-promise-pay-con">
                    <Controller
                      defaultValues={[]}
                      name="action_follow_up_template_id"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          fullWidth
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "field_visit_follow_up_message",
                          })}
                          placeholder={intl.formatMessage({
                            id: "field_visit_follow_up_message",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={followUpTemplates}
                        />
                      )}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "end",
                justifyContent: "end",
                width: "100%",
                gap: "10px",
              }}
            >
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.compact}
                type="button"
                onClick={() => {
                  setState(false);
                }}
              >
                Cancel
              </TextButton>
              <TextButton kind={KIND.primary} size={SIZE.compact} type="submit">
                Save
              </TextButton>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default FieldVisitDrawer;
