function InfoPortal() {
  return (
    <>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.33398 3.66732H7.66732V5.00065H6.33398V3.66732ZM7.00065 10.334C7.36732 10.334 7.66732 10.034 7.66732 9.66732V7.00065C7.66732 6.63398 7.36732 6.33398 7.00065 6.33398C6.63398 6.33398 6.33398 6.63398 6.33398 7.00065V9.66732C6.33398 10.034 6.63398 10.334 7.00065 10.334ZM7.00065 0.333984C3.32065 0.333984 0.333984 3.32065 0.333984 7.00065C0.333984 10.6807 3.32065 13.6673 7.00065 13.6673C10.6807 13.6673 13.6673 10.6807 13.6673 7.00065C13.6673 3.32065 10.6807 0.333984 7.00065 0.333984ZM7.00065 12.334C4.06065 12.334 1.66732 9.94065 1.66732 7.00065C1.66732 4.06065 4.06065 1.66732 7.00065 1.66732C9.94065 1.66732 12.334 4.06065 12.334 7.00065C12.334 9.94065 9.94065 12.334 7.00065 12.334Z"
          fill="#ADADAD"
        />
      </svg>
    </>
  );
}

export default InfoPortal;
