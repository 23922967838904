import React, { useContext, useRef, useMemo } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import _ from "lodash";

import { Icon, Typography } from "../../components_v2";
import { TextButton } from "../../components/TextButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

import { NOTE_LIST_DRAWER } from "../../redux/actions";
import { postDairyNote } from "../../services";
import { CO_TIMELINE_ } from "../../constants";
import {
  ADD_CUSTOMER_NOTES,
  ADD_DOCUMENTS,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";

export default function COQuickNote({
  setDrawer,
  openDrawer,
  type,
  consumerId,
}) {
  const editorRef = useRef(null);
  const queryClient = useQueryClient();
  const { customerId } = useParams();
  const BlockEmbed = ReactQuill.Quill.import("blots/embed");

  const { referenceData, currentOrganization, users, userInfo, space } =
    useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  const addNote = useMutation(
    (values) =>
      postDairyNote({
        organization: currentOrganization,
        customerId: consumerId ? consumerId : customerId,
        ...values,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(
          `customer-notes-${consumerId ? consumerId : customerId}`
        );

        reset();
        queryClient.refetchQueries(
          `${CO_TIMELINE_}${consumerId ? consumerId : customerId}`
        );
      },
    }
  );

  const onSubmit = async (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.notes, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    await addNote.mutateAsync({
      ...data,
      category: "general",
      is_enable_alert_and_follow_up: false,
      mention_users: uniqueMentionedUsers,
      type: "INFORMATIONAL",
    });
  };

  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  return (
    <form
      className="co-quick-notes_wrapper"
      style={{ height: type === "client" ? "100px" : "178px" }}
    >
      <Controller
        name="notes"
        control={control}
        render={({ field }) => (
          <ReactQuill
            {...field}
            name={field.name}
            id={"editor"}
            ref={editorRef}
            theme="snow"
            value={field.value}
            modules={modules}
            style={{ height: "160px" }}
            placeholder="Type your notes..."
          />
        )}
      />

      {type === "client" ? (
        <>
          <div className="co-quick-notes_actions co-quick-notes_actions--enlarge">
            <Icon
              icon="enlarge"
              size={18}
              isPressable
              onClick={() => {
                openDrawer(true);
              }}
            />
          </div>
        </>
      ) : (
        <div className="co-quick-notes_actions co-quick-notes_actions--enlarge">
          <Icon
            icon="enlarge"
            size={18}
            isPressable
            onClick={() => {
              setDrawer(NOTE_LIST_DRAWER);
            }}
          />
        </div>
      )}

      <div className="co-quick-notes_actions">
        <RBACWrapper role={ADD_CUSTOMER_NOTES} type="PERMISSION">
          <TextButton
            kind="tertiary"
            size="mini"
            type="button"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Capture
          </TextButton>
        </RBACWrapper>
      </div>
    </form>
  );
}
