import React from "react";

const Pause = ({ size = 22, color = "#516BEB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74063 11.9375C7.90007 11.9375 8.03363 11.8836 8.14132 11.7757C8.24902 11.6679 8.30287 11.5344 8.30287 11.375V6.87498C8.30287 6.71561 8.24894 6.58201 8.14107 6.4742C8.03321 6.3664 7.89956 6.3125 7.74012 6.3125C7.58067 6.3125 7.44711 6.3664 7.33942 6.4742C7.23173 6.58201 7.17789 6.71561 7.17789 6.87498V11.375C7.17789 11.5344 7.23182 11.6679 7.33968 11.7757C7.44753 11.8836 7.58118 11.9375 7.74063 11.9375ZM10.5098 11.9375C10.6693 11.9375 10.8029 11.8836 10.9105 11.7757C11.0182 11.6679 11.0721 11.5344 11.0721 11.375V6.87498C11.0721 6.71561 11.0181 6.58201 10.9103 6.4742C10.8024 6.3664 10.6688 6.3125 10.5093 6.3125C10.3499 6.3125 10.2163 6.3664 10.1086 6.4742C10.0009 6.58201 9.94709 6.71561 9.94709 6.87498V11.375C9.94709 11.5344 10.001 11.6679 10.1089 11.7757C10.2167 11.8836 10.3504 11.9375 10.5098 11.9375ZM9.12624 16.25C8.14079 16.25 7.21451 16.063 6.34741 15.689C5.4803 15.315 4.72604 14.8074 4.08463 14.1663C3.4432 13.5251 2.93541 12.7712 2.56124 11.9045C2.18708 11.0378 2 10.1117 2 9.12624C2 8.14079 2.187 7.21451 2.561 6.34741C2.935 5.4803 3.44256 4.72604 4.08369 4.08463C4.72483 3.4432 5.47876 2.93541 6.34548 2.56124C7.21219 2.18708 8.13828 2 9.12373 2C10.1092 2 11.0355 2.187 11.9026 2.561C12.7697 2.935 13.5239 3.44256 14.1653 4.08369C14.8068 4.72483 15.3146 5.47876 15.6887 6.34548C16.0629 7.21219 16.25 8.13828 16.25 9.12373C16.25 10.1092 16.063 11.0355 15.689 11.9026C15.315 12.7697 14.8074 13.5239 14.1663 14.1653C13.5251 14.8068 12.7712 15.3146 11.9045 15.6887C11.0378 16.0629 10.1117 16.25 9.12624 16.25ZM9.12498 15.125C10.8 15.125 12.2187 14.5437 13.3812 13.3812C14.5437 12.2187 15.125 10.8 15.125 9.12498C15.125 7.44998 14.5437 6.03123 13.3812 4.86873C12.2187 3.70623 10.8 3.12498 9.12498 3.12498C7.44998 3.12498 6.03123 3.70623 4.86873 4.86873C3.70623 6.03123 3.12498 7.44998 3.12498 9.12498C3.12498 10.8 3.70623 12.2187 4.86873 13.3812C6.03123 14.5437 7.44998 15.125 9.12498 15.125Z"
        fill={color}
      />
    </svg>
  );
};

export default Pause;
