import React, { useEffect, useContext, useState } from "react";
import {
  HeadingXSmall,
  LabelMedium,
  LabelXSmall,
  ParagraphMedium,
} from "baseui/typography";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import { getOrganization } from "../../services";
import { useSelector, useDispatch } from "react-redux";
import { holdActionModal, releaseActionModal } from "../../redux/actions";
import { ACTIVE } from "../../constants";
import { CLOSED } from "ws";
import { useQuery } from "react-query";
import {
  addInvoiceDiaryNotesData,
  getInvoiceAction,
} from "../../services/invoiceOverview";
import CustomerOverviewCard from "../../containers/CustomerOverView/CustomerOverviewCard";
import CustomerOverviewCardTable from "../../containers/CustomerOverView/CustomerOverviewCardTable";
import { useParams, useSearchParams } from "react-router-dom";
import InvoiceOverviewCard from "../../containers/InvoiceOverview/InvoiceOverviewCard";
import InvoiceOverviewTab from "../../containers/InvoiceOverview/InvoiceOverviewTab";
import InvoiceDairyNoteAlertCard from "../../containers/InvoiceOverview/InvoiceDairyNoteAlert/InvoiceDairyNoteAlertCard";
import InvoiceDairyNoteAlert from "../../containers/InvoiceOverview/InvoiceDairyNoteAlert/InvoiceDairyNoteAlert";
import {
  initialInvoiceState,
  invoiceActionDatas,
  invoiceCustomerId,
  smsInvoiceGateway,
} from "../../redux/invoiceOverview/action";
import { getInvoiceCardData } from "../../redux/invoiceOverview/action";
import OverviewPieCharts from "../../components/OverviewComponents/OverviewPieChart/OverviewPieCharts";
import OverviewActions from "../../components/OverviewComponents/OverviewActions/OverviewActions";
import ContactContainer from "../../containers/CustomerOverView/ContactContainer";
import {
  editInvoiceDairyNote,
  getInvoiceData,
} from "../../services/invoiceOverview";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { HeadingXLarge } from "baseui/typography";
import { TextButton } from "../../components/TextButton";
import { ON_HOLD } from "../../constants";
import * as ModalButton from "../../components/TextButton";
import { invoiceEntity } from "../../redux/invoiceOverview/action";
import PauseSvg from "../../assets/img/svg/PauseSvg";
import PauseSvgError from "../../assets/img/svg/PauseSvgError";
import { activeInvoiceData } from "../../redux/invoiceOverview/action";
import moment from "moment";
import AllActionPopOver from "../../containers/CustomerOverView/COTabContainers/Actions/AllActionPopOver";
import InvoiceOverviewListTable from "../../containers/InvoiceOverview/InvoiceOverviewListTable/InvoiceOverviewListTable";
import DairyNotesAlert from "../../containers/CustomerOverView/COTabContainers/DairyNotesAlert";
import OnHoldModal from "../../containers/CustomerOverView/COTabContainers/Actions/OnHoldModal";
import OnReleasePopOver from "../../containers/CustomerOverView/COTabContainers/Actions/OnReleasePopOver";
import OnSkipModal from "../../containers/CustomerOverView/COTabContainers/Actions/OnSkipModal";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import { IconButton } from "../../components/IconButton";
import { openModal } from "../../redux/customerDairyNote/action";
import DairyNoteForm from "../../components/DairyNoteForm/DairyNoteForm";
import Note from "../../assets/img/svg/Note";
import Letter from "../../assets/img/svg/CustomerTemplate/Letter";
import DMSystem from "../../containers/CustomerOverView/DMSystem";
import { documentDrawerOpen } from "../../redux/customerOverview/DMSystem/action";
import DocumentSvg from "../../assets/img/svg/DocumentSvg";
import { createDocument } from "../../services/documentSystem";
import {
  documentDriveInv,
  documentInvoiceDrawerOpen,
  invoiceDrawerClose,
} from "../../redux/invoiceOverview/DMSystemINV/action";
import CNoteSvg from "../../assets/img/svg/CNoteSvg";
import { addingInvoiceDocumentData } from "../../redux/invoiceAdding/dmsInvoiceAdding/action";
import {
  ADD_INVOICE_NOTES,
  DELETE_INVOICE_NOTES,
  DISMISS_INVOICE_NOTES,
  EDIT_INVOICE_NOTES,
  INVOICE_DISPUTE,
  INVOICE_MANUAL_EMAIL,
  INVOICE_PAUSE,
  INVOICE_SMS,
  INVOICE_WHATSAPP,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import CustomerPortalLink from "../../containers/CustomerOverView/CustomerPortalLink";
import InvCustomerPortalLink from "../../containers/CustomerOverView/InvCustomerPortalLink";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Button } from "baseui/button";
import { useIntl } from "react-intl";
import { documentDriveQuickActionInv } from "../../redux/invoiceOverview/invoiceQA/action";
import WhatsAppChat from "../../components/WhatsAppChat/WhatsAppChat";
function InvoiceOverview({ getParams, tableData }) {
  // const downArrowClicked = useSelector(
  //   (s) => s.InvoiceOverviewReducer.noteAlert
  // );
  const intl = useIntl();
  const [wrkData, setWrkData] = useState([]);
  const [invData, setinvData] = useState({});
  const refetchingInvoice = useSelector(
    (s) => s.invoiceOverviewReducer.refetchActions
  );
  const { isOpen } = useSelector((e) => e.timelineReducer);

  let {
    currentOrganization,
    referenceData,
    users,
    dateFormat,
    currencyFormat,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    space,
  } = useContext(MaxyfiContext);
  let dispatch = useDispatch();

  const resumePermission =
    space && space?.role_permissions?.includes("invoice_resume");

  let quickActionEnabel = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails.is_quick_action
  );

  const whatsAppIsConfigured =
    currentDefaultFormatDetails?.is_whatsapp_configured;

  let { drawerInvoiceState, drawerInvoiceType } = useSelector(
    (s) => s.invoiceDocumentReducer
  );

  useEffect(() => {
    const setInivoiceReduxToInitialState = () => {
      dispatch(initialInvoiceState());
    };
    return setInivoiceReduxToInitialState;
  }, []);
  const collapseButton = useSelector((s) => s.invoiceOverviewReducer.noteAlert);

  useEffect(() => {
    const getInvoiceDataVal = async () => {
      // dispatch(resetDiaryNoteReduxState());
      await getInvoiceData({
        invoiceId: getParams,
        organization: currentOrganization,
      })
        .then((res) => {
          dispatch(getInvoiceCardData(res.data.docs));
          //
          dispatch(activeInvoiceData(res.data.docs.workflow));

          dispatch(invoiceActionDatas(res.data.docs.invoice_actions));

          setinvData(res.data.docs);
        })
        .catch((err) => {
          if (err.response) {
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
          } else {
            // anything else
          }
        });
    };
    getInvoiceDataVal();
  }, [getParams, refetchingInvoice]);

  const initialData = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails
  );

  useEffect(() => {
    if (invData) {
      dispatch(invoiceCustomerId(invData?.customer_id));
      dispatch(invoiceEntity("INVOICE"));
    }
  }, [invData]);

  const allActionData = [
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      permissions: INVOICE_SMS,
      actionTitle: intl.formatMessage({
        id: "sms",
      }),
      contentIntl: intl.formatMessage({
        id: "SMS_Reminders_with_without_an_actionable_link",
      }),
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      permissions: INVOICE_MANUAL_EMAIL,
      actionTitle: intl.formatMessage({
        id: "email",
      }),
      contentIntl: intl.formatMessage({
        id: "Email_follow_up_with_Customer",
      }),
    },

    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      permissions: INVOICE_DISPUTE,
      actionTitle: intl.formatMessage({
        id: "dispute",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_and_Update_Dispute_Status",
      }),
    },
    // {
    //   id: "5",
    //   actionName: "Letter",
    //   type: "letter",
    //   content: "Generate and Send Physical letters",
    // },
    {
      id: "6",
      actionName: "Pause Action",
      type: "hold",
      content: "Pause Actions and Customer",
      permissions: INVOICE_PAUSE,
      actionTitle: intl.formatMessage({
        id: "pause_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Pause_Actions_and_Customer",
      }),
    },
    {
      id: "9",
      actionName: "WhatsApp",
      type: "whatsapp",
      content: "Communicate and connect via WhatsApp",
      permissions: INVOICE_WHATSAPP,
      actionTitle: intl.formatMessage({
        id: "whatsapp",
      }),
      contentIntl: intl.formatMessage({
        id: "Communicate_and_connect_via_WhatsApp",
      }),
    },
  ];

  const quickActionData = [
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      permissions: INVOICE_SMS,
      actionTitle: intl.formatMessage({
        id: "sms",
      }),
      contentIntl: intl.formatMessage({
        id: "SMS_Reminders_with_without_an_actionable_link",
      }),
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      permissions: INVOICE_MANUAL_EMAIL,
      actionTitle: intl.formatMessage({
        id: "email",
      }),
      contentIntl: intl.formatMessage({
        id: "Email_follow_up_with_Customer",
      }),
    },

    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      permissions: INVOICE_DISPUTE,
      actionTitle: intl.formatMessage({
        id: "dispute",
      }),
      contentIntl: intl.formatMessage({
        id: "Review_and_Update_Dispute_Status",
      }),
    },
    // {
    //   id: "5",
    //   actionName: "Letter",
    //   type: "letter",
    //   content: "Generate and Send Physical letters",
    //   permissions: INVOICE_PAUSE,
    //   actionTitle: intl.formatMessage({
    //     id: "letter",
    //   }),
    //   contentIntl: intl.formatMessage({
    //     id: "letter",
    //   }),
    // },
    {
      id: "6",
      actionName: "Pause Action",
      type: "hold",
      content: "Pause Actions and Customer",
      permissions: INVOICE_PAUSE,
      actionTitle: intl.formatMessage({
        id: "pause_action",
      }),
      contentIntl: intl.formatMessage({
        id: "Pause_Actions_and_Customer",
      }),
    },
    {
      id: "9",
      actionName: "WhatsApp",
      type: "whatsapp",
      content: "Communicate and connect via WhatsApp",
      permissions: INVOICE_WHATSAPP,
      actionTitle: intl.formatMessage({
        id: "whatsapp",
      }),
      contentIntl: intl.formatMessage({
        id: "Communicate_and_connect_via_WhatsApp",
      }),
    },
  ];
  const alertPopUpDatas = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails.alert_notes
  );

  // QUICK ACTION REQUIRED DATA STARTS

  // useEffect(async () => {
  //   await getOrganization({ organization: currentOrganization }).then((res) => {
  //     // setSettingData(res?.data?.doc.sms_details || {});
  //     // dispatch(smsGateway(res?.data?.doc));
  //     dispatch(smsInvoiceGateway(res?.data?.doc));
  //   });
  //   //TODO: PRAVEEN FAILURE ERROR HANDLING
  // }, [getParams, invData]);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  const timeZone = orgRef?.time_zone;

  const workFlowStatus = useSelector(
    (s) => s.invoiceOverviewReducer.activeIvoiceWorkflow?.status
  );

  let isWorkflowActive =
    workFlowStatus && workFlowStatus === ACTIVE ? true : false;

  let isWorkflow = workFlowStatus && workFlowStatus !== CLOSED ? true : false;

  const smsGateWayIsConfgured = currentDefaultFormatDetails?.is_sms_configured;

  const workflowDatas = useSelector(
    (s) => s.invoiceOverviewReducer.onLoadInvoiceActions
  );

  let filteringActionStatus =
    wrkData && wrkData?.filter((i) => i.action_status === "ACTIVE");
  let plannedOnDate =
    filteringActionStatus &&
    filteringActionStatus[0] &&
    filteringActionStatus[0].planned_on;

  let isNextAction =
    filteringActionStatus && filteringActionStatus?.length ? true : false;

  // let plannedOn = moment
  //   .utc(plannedOnDate)
  //   .tz(timeZone)
  //   .startOf("d")
  //   .format(currentDefaultFormatDetails.date_format);

  //

  let today = moment().utc()?.tz(timeZone)?.startOf("d")?.valueOf();

  let dayDifference = moment
    .utc(plannedOnDate)
    ?.tz(timeZone)
    ?.startOf("d")
    ?.diff(today, "d");
  let dayDifferenceValue = dayDifference;

  const { isLoading, data, refetch, isFetching, isError } = useQuery(
    [`invoice-actions-${getParams}`],
    async () => {
      return await getInvoiceAction({
        organization: currentOrganization,
        invoiceId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  useEffect(() => {
    setWrkData(data && data.data.doc && data.data.doc.customer_actions);
  }, [data]);

  let HoldDatas = data && data.data.doc && data.data.doc.on_hold;

  let workflowActionLength =
    data && data.data.doc && data.data.doc?.customer_actions.length;
  // QUICK ACTION REQUIRED DATA ENDS
  let workflowActionData = data && data.data.doc && data.data.doc.workflow;

  let invoiceWorkflowAction = invData.workflow;

  let reason = referenceData["hold_action_reason"];
  let holdReason = reason.filter(
    (i) => i.id === invoiceWorkflowAction?.hold_reason
  );

  let noWorkflowActionReason = reason.filter(
    (i) => i.id === initialData.hold_quick_action_reason
  );

  let holdDateFormate = moment
    .utc(invoiceWorkflowAction?.hold_action_till)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  let noWorkflowActionDate = moment(
    initialData && initialData?.hold_quick_action_till
  )
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  let NextActionDaysDiff = () => {
    let daysLeft = initialData?.due_days;

    if (daysLeft > 1) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "#FD372A",
              borderRadius: "50px",
            }}
          ></div>
          <ParagraphMedium $style={{ color: "#FD372A" }}>
            (Overdue {daysLeft} days)
          </ParagraphMedium>
        </div>
      );
    }

    if (daysLeft === 1) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "#FD372A",
              borderRadius: "50px",
            }}
          ></div>
          <ParagraphMedium $style={{ color: "#FD372A" }}>
            (Overdue {daysLeft} day)
          </ParagraphMedium>
        </div>
      );
    }

    if (daysLeft === 0) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "#FD372A",
              borderRadius: "50px",
            }}
          ></div>
          <ParagraphMedium $style={{ color: "#FD372A" }}>
            (Due Today)
          </ParagraphMedium>
        </div>
      );
    }

    if (daysLeft === -1) {
      daysLeft = daysLeft * -1;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "#0FB158",
              borderRadius: "50px",
            }}
          ></div>
          <ParagraphMedium $style={{ color: "#0FB158" }}>
            (Due in {daysLeft} day)
          </ParagraphMedium>
        </div>
      );
    }

    if (daysLeft < -1) {
      daysLeft = daysLeft * -1;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              width: "8px",
              height: "8px",
              backgroundColor: "#0FB158",
              borderRadius: "50px",
            }}
          ></div>
          <ParagraphMedium $style={{ color: "#0FB158" }}>
            (Due in {daysLeft} days)
          </ParagraphMedium>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <div
        className="content-container"
        style={{
          padding: "0px",
          position: "relative",
        }}
      >
        <div
          className="content-body"
          // invoice-body--customer-overview invoice-overview__hight-fix
          style={{
            padding: "0px 10px 10px 0px",
            flexDirection: "row",
            gap: "1%",
            // overflow: "scroll",
          }}
        >
          <div style={{ height: "100%" }}>
            {/* <InvoiceOverviewCard /> */}
            <InvoiceOverviewListTable
              getParams={getParams}
              tableData={tableData}
            />
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              // gap: "1.5%",
            }}
          >
            <div
              className="content-header --cp_overview__head"
              style={{ paddingBottom: "0px" }}
            >
              <div
                className="content-header__title"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    // paddingTop: "10px",
                    // paddingLeft: "25px",
                    display: "flex",
                    justifyContent: "space-between",
                    flex: "1",
                    // paddingRight: "30px",
                    position: "relative",
                    padding: "0px 10px",
                    // paddingTop: "10px",
                  }}
                >
                  {" "}
                  <div className="content-header__back">
                    <HeadingXLarge $style={{ fontSize: "25px" }}>
                      {initialData.invoice_number}
                    </HeadingXLarge>
                    <NextActionDaysDiff />
                    <InvCustomerPortalLink getParams={getParams} />
                    {/* <CustomerPortalLink /> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* <div className="cp__resume__btn"> */}
                    <div>
                      {workFlowStatus === ON_HOLD || !quickActionEnabel ? (
                        <>
                          <StatefulPopover
                            showArrow
                            overrides={{
                              Arrow: {
                                style: ({ $theme }) => ({
                                  backgroundColor: "16164B",
                                }),
                              },
                            }}
                            content={
                              invData &&
                              invData?.action_hold_by === "CUSTOMER" ? (
                                <div
                                  style={{
                                    backgroundColor: "#16164B",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    maxWidth: "250px",
                                    color: "#ffffff",
                                  }}
                                >
                                  <HeadingXSmall
                                    $style={{
                                      fontSize: "11px",
                                      fontWeight: "500",
                                      color: "#ffffff",
                                      lineHeight: "16px",
                                    }}
                                  >
                                    Consumer Level Hold - Resume Option
                                    available at Consumer Level
                                  </HeadingXSmall>
                                </div>
                              ) : (
                                ""
                              )
                            }
                            triggerType={TRIGGER_TYPE.hover}
                            returnFocus
                            autoFocus
                          >
                            <div>
                              <Button
                                size={ModalButton.SIZE.mini}
                                $style={{
                                  color: "#FD372A",
                                  borderRadius: "5px",
                                  border: "1px solid #FD372A",
                                  background: "none",
                                  padding: "5px",
                                  width: "260px",
                                  ":hover": {
                                    background: "none",
                                    border: "1px solid #516BEB",
                                    color: "#516BEB",
                                  },
                                }}
                                onClick={() => {
                                  invData &&
                                  invData?.action_hold_by === "CUSTOMER"
                                    ? dispatch()
                                    : dispatch(releaseActionModal(true));
                                }}
                                disabled={
                                  invData &&
                                  invData?.action_hold_by === "CUSTOMER"
                                    ? true
                                    : false || !resumePermission
                                }
                              >
                                <span style={{ marginRight: "4px" }}>
                                  <PauseSvgError />
                                </span>
                                <span>Invoice Action is paused </span>
                                <span
                                  style={{
                                    color: "#516beb",
                                    marginLeft: "4px",
                                    // fontWeight: "400",
                                  }}
                                >
                                  {" "}
                                  Resume now
                                </span>
                              </Button>
                            </div>
                          </StatefulPopover>
                        </>
                      ) : (
                        <RBACWrapper role={INVOICE_PAUSE} type="PERMISSION">
                          <TextButton
                            size={ModalButton.SIZE.mini}
                            kind={ModalButton.KIND.secondary}
                            style={{ width: "100%" }}
                            type="button"
                            startEnhancer={() => <PauseSvg />}
                            onClick={() => dispatch(holdActionModal(true))}
                          >
                            Pause Action
                          </TextButton>
                        </RBACWrapper>
                      )}
                    </div>
                    {/* </div> */}
                    <div
                      onClick={() => {
                        dispatch(invoiceEntity("INVOICE"));
                      }}
                    >
                      <AllActionPopOver
                        isQuickAction={quickActionEnabel}
                        allActionData={allActionData}
                        quickActionData={quickActionData}
                        workFlowStatus={workFlowStatus}
                        smsGateWayIsConfgured={smsGateWayIsConfgured}
                        isWorkflowActive={isWorkflowActive}
                        dayDifference={dayDifference}
                        isNextAction={isNextAction}
                        dayDifferenceValue={dayDifferenceValue}
                        holdDateFormate={
                          !workflowActionData
                            ? holdDateFormate
                            : noWorkflowActionDate
                        }
                        holdReason={
                          !workflowActionData
                            ? noWorkflowActionReason
                            : holdReason
                        }
                        workflowActionLength={workflowActionLength}
                        workflowActionData={!workflowActionData}
                        whatsAppIsConfigured={whatsAppIsConfigured}
                      />
                    </div>
                    <IconButton
                      onClick={() => dispatch(openModal())}
                      kind={ModalButton.KIND.tertiary}
                    >
                      <CNoteSvg />
                    </IconButton>
                    <IconButton
                      onClick={() => dispatch(documentInvoiceDrawerOpen(true))}
                      kind={ModalButton.KIND.tertiary}
                      size={ModalButton.SIZE.mini}
                    >
                      <DocumentSvg />
                    </IconButton>
                  </div>
                  {/* {workFlowStatus === ON_HOLD || !quickActionEnabel ? (
                <div className="cp_customer__action__pause">
                  <HeadingXSmall $style={{ color: "#ffffff" }}>
                    Customer Action is Paused
                  </HeadingXSmall>
                </div>
              ) : (
                <></>
              )} */}
                </div>
              </div>
            </div>

            {/* For Pie Chart and Actions STARTS */}
            <div style={{ display: "flex", gap: "1%" }}>
              <OverviewPieCharts data={initialData} getParams={getParams} />
              <OverviewActions data={initialData} />
            </div>
            {/* For Pie Chart and Actions ENDS*/}

            {/* Tabs and Contacts Sections STARTS*/}
            <div
              className="customer-profile-tab"
              style={{ paddingTop: "10px" }}
            >
              <div className="cp__tab__container">
                <InvoiceOverviewTab
                  getInvoiceData={initialData}
                  getParams={getParams}
                  invData={invData}
                />
                <OnHoldModal getParams={getParams} entity={"invoices"} />
                <OnReleasePopOver getParams={getParams} entity={"invoices"} />
                <OnSkipModal getParams={getParams} entity={"invoices"} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "216px",
                }}
              >
                <div
                  style={
                    {
                      // padding: "18px 7px",
                    }
                  }
                >
                  <DairyNotesAlert
                    alertPopUpDatas={alertPopUpDatas}
                    editDairyNote={editInvoiceDairyNote}
                    getParams={getParams}
                    entity={"invoices"}
                    dismissExit={DISMISS_INVOICE_NOTES}
                  />
                </div>
                <div className="cp__contact__container_right">
                  <div className="contact__details__wraper">
                    {initialData && initialData.customer_id && (
                      <ContactContainer
                        getParams={initialData.customer_id}
                        entity={"INVOICE"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DairyNoteForm
          resourceName="invoiceId"
          resourceId={getParams}
          createDiaryNoteService={addInvoiceDiaryNotesData}
          editDairyNote={editInvoiceDairyNote}
          entity={"invoices"}
          addNotePermission={ADD_INVOICE_NOTES}
          editNotePermission={EDIT_INVOICE_NOTES}
          deleteNotePermission={DELETE_INVOICE_NOTES}
          // refetchAction={dispatch(refetchInvoiceActions())}s
        />
        {drawerInvoiceState && (
          <DMSystem
            resourceName="invoiceId"
            resourceId={invData && invData.customer_id}
            documentData={""}
            createDocument={createDocument}
            deleteDocumentMethod={""}
            editDocumentMethod={""}
            entity={"invoices"}
            drawerOpen={drawerInvoiceState}
            documentDrawertype={drawerInvoiceType}
            addingSelectedDocument={addingInvoiceDocumentData}
            // openingDrawerState={documentInvoiceAddingDrawerState}
            closeDocmentDrawer={invoiceDrawerClose}
            documentDrive={documentDriveQuickActionInv}
          />
        )}

        {isOpen && (
          <WhatsAppChat
            getParams={initialData && initialData?.customer_id}
            entity={"invoices"}
            resourceName="invoiceId"
            overViewData={initialData}
          />
        )}
      </div>
    </>
  );
}

export default InvoiceOverview;
