import {
  GET_SETTING_DATA,
  GET_ALL_SETTING_DATA,
  REFETCH_SETTING,
  INITIAL_SETTING_DATA,
} from "./actions";

// Initial State

const initialState = {
  type: "general_information",
  settingData: [],
  refetchSetting: 0,
};

const settingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INITIAL_SETTING_DATA: {
      return {
        ...state,
        ...initialState,
      };
    }
    case GET_SETTING_DATA:
      return {
        ...state,
        type: payload,
      };
    case GET_ALL_SETTING_DATA: {
      return {
        ...state,
        settingData: payload,
      };
    }
    case REFETCH_SETTING: {
      const { refetchSetting } = state;
      return {
        ...state,
        refetchSetting: refetchSetting + 1,
      };
    }

    default:
      return state;
  }
};

export default settingReducer;
