import React from "react";

const MeetingRequest = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.125 14.6954H11.9186V20.2498H11.125V14.6954Z"
        fill="#516BEB"
      />
      <path
        d="M5.57031 13.1086H17.4723V13.902H5.57031V13.1086Z"
        fill="#516BEB"
      />
      <path
        d="M19.0599 7.95099C17.9661 7.95099 17.0762 7.0611 17.0762 5.96737C17.0762 4.8735 17.9661 3.98361 19.0599 3.98361C20.1537 3.98361 21.0435 4.8735 21.0435 5.96737C21.0435 7.0611 20.1537 7.95099 19.0599 7.95099V7.95099ZM19.0599 4.77717C18.4037 4.77717 17.8697 5.31111 17.8697 5.96737C17.8697 6.62349 18.4037 7.15757 19.0599 7.15757C19.7161 7.15757 20.2501 6.62349 20.2501 5.96737C20.2501 5.31111 19.7161 4.77717 19.0599 4.77717Z"
        fill="#516BEB"
      />
      <path
        d="M15.091 20.2498C15.0387 20.2498 14.9856 20.2397 14.9347 20.2175C14.7332 20.1311 14.6399 19.8979 14.7265 19.6966L15.9167 16.9195C15.9789 16.7736 16.1226 16.6792 16.2812 16.6792H19.0584C19.7145 16.6792 20.2486 16.1451 20.2486 15.489V10.3314C20.2486 9.89379 19.8927 9.53798 19.455 9.53798H19.0584C18.7799 9.53798 18.5264 9.72206 18.1848 10.1743C17.8015 10.6805 16.6384 12.1087 16.5891 12.169C16.5133 12.2615 16.4007 12.315 16.2812 12.315H14.2976C14.0785 12.315 13.9008 12.4928 13.9008 12.7118V13.5052C13.9008 13.7246 13.7234 13.902 13.5041 13.902C13.2847 13.902 13.1074 13.7246 13.1074 13.5052V12.7118C13.1074 12.0555 13.6414 11.5216 14.2976 11.5216H16.0924C16.405 11.1367 17.2477 10.0973 17.5516 9.69547C17.8868 9.25263 18.3604 8.74442 19.0584 8.74442H19.455C20.3303 8.74442 21.042 9.45618 21.042 10.3314V15.489C21.042 16.5827 20.1521 17.4726 19.0584 17.4726H16.5428L15.4556 20.0093C15.3913 20.1597 15.2449 20.2498 15.091 20.2498Z"
        fill="#516BEB"
      />
      <path
        d="M13.5059 20.2498C13.4646 20.2498 13.4222 20.2429 13.3805 20.2291C13.173 20.1601 13.0604 19.9355 13.1299 19.7272L14.4455 15.7801C14.6608 15.1319 15.2662 14.6954 15.9507 14.6954H17.0765V13.5052C17.0765 13.2858 17.2539 13.1086 17.4733 13.1086C17.6927 13.1086 17.8701 13.2858 17.8701 13.5052V15.0922C17.8701 15.3116 17.6927 15.489 17.4733 15.489H15.9507C15.6087 15.489 15.3059 15.7068 15.1984 16.0309L13.8825 19.9784C13.8268 20.1446 13.6721 20.2498 13.5059 20.2498V20.2498Z"
        fill="#516BEB"
      />
      <path
        d="M17.4726 13.902C17.3898 13.902 17.3064 13.8763 17.2351 13.8227C17.0597 13.6913 17.024 13.4426 17.1556 13.2677L18.3458 11.6807C18.4771 11.5053 18.7259 11.47 18.9009 11.6013C19.0763 11.7327 19.1119 11.9814 18.9803 12.1563L17.7901 13.7433C17.7126 13.8472 17.5936 13.902 17.4726 13.902Z"
        fill="#516BEB"
      />
      <path
        d="M3.98361 7.95099C2.88989 7.95099 2 7.0611 2 5.96737C2 4.8735 2.88989 3.98361 3.98361 3.98361C5.07749 3.98361 5.96737 4.8735 5.96737 5.96737C5.96737 7.0611 5.07749 7.95099 3.98361 7.95099ZM3.98361 4.77717C3.32749 4.77717 2.79342 5.31111 2.79342 5.96737C2.79342 6.62349 3.32749 7.15757 3.98361 7.15757C4.63988 7.15757 5.17381 6.62349 5.17381 5.96737C5.17381 5.31111 4.63988 4.77717 3.98361 4.77717Z"
        fill="#516BEB"
      />
      <path
        d="M7.95099 20.2498C7.79713 20.2498 7.65068 20.1597 7.58646 20.0093L6.49927 17.4726H3.98361C2.88989 17.4726 2 16.5827 2 15.489V10.3314C2 9.45618 2.71176 8.74442 3.58698 8.74442H3.98361C4.68158 8.74442 5.15565 9.25263 5.4901 9.69547C5.79434 10.0973 6.63657 11.1367 6.94923 11.5216H8.7444C9.40067 11.5216 9.9346 12.0555 9.9346 12.7118V13.5052C9.9346 13.7246 9.75692 13.902 9.53797 13.902C9.31887 13.902 9.14119 13.7246 9.14119 13.5052V12.7118C9.14119 12.4928 8.96306 12.315 8.7444 12.315H6.76079C6.64136 12.315 6.52877 12.2615 6.45336 12.169C6.40411 12.1087 5.2405 10.6805 4.85767 10.1739C4.51566 9.72206 4.26257 9.53798 3.98361 9.53798H3.58698C3.14937 9.53798 2.79342 9.89379 2.79342 10.3314V15.489C2.79342 16.1451 3.32749 16.6792 3.98361 16.6792H6.76079C6.91944 16.6792 7.06313 16.7736 7.12532 16.9195L8.31552 19.6966C8.40211 19.8979 8.3084 20.1311 8.10732 20.2175C8.05647 20.2397 8.00329 20.2498 7.95099 20.2498V20.2498Z"
        fill="#516BEB"
      />
      <path
        d="M9.53798 20.2498C9.37177 20.2498 9.21704 20.1446 9.1614 19.9784L7.84582 16.0312C7.73801 15.7072 7.43523 15.489 7.09322 15.489H5.57061C5.35166 15.489 5.17383 15.3116 5.17383 15.0922V13.5052C5.17383 13.2858 5.35166 13.1086 5.57061 13.1086C5.78956 13.1086 5.96739 13.2858 5.96739 13.5052V14.6954H7.09322C7.77768 14.6954 8.38265 15.1314 8.59884 15.7801L9.91442 19.7272C9.98387 19.9351 9.87156 20.1601 9.66366 20.2291C9.62167 20.2429 9.57924 20.2498 9.53798 20.2498Z"
        fill="#516BEB"
      />
      <path
        d="M5.5709 13.902C5.45031 13.902 5.33118 13.8472 5.25301 13.7433L4.06282 12.1563C3.93118 11.9814 3.96693 11.7327 4.14229 11.6013C4.31838 11.47 4.56667 11.5053 4.69772 11.6807L5.88792 13.2677C6.01955 13.4426 5.98381 13.6913 5.80845 13.8227C5.73711 13.8763 5.65343 13.902 5.5709 13.902V13.902Z"
        fill="#516BEB"
      />
      <path
        d="M14.6956 12.315C14.4762 12.315 14.2989 12.1377 14.2989 11.9183V9.14117C14.2989 8.26595 13.5872 7.55419 12.712 7.55419H10.3316C9.45635 7.55419 8.74459 8.26595 8.74459 9.14117V10.3314C8.74459 10.5503 8.5669 10.7281 8.34795 10.7281C8.12886 10.7281 7.95117 10.5503 7.95117 10.3314V9.14117C7.95117 7.82835 9.01875 6.76077 10.3316 6.76077H12.712C14.0248 6.76077 15.0924 7.82835 15.0924 9.14117V11.9183C15.0924 12.1377 14.915 12.315 14.6956 12.315V12.315Z"
        fill="#516BEB"
      />
      <path
        d="M11.5207 5.96737C10.427 5.96737 9.53711 5.07749 9.53711 3.98361C9.53711 2.88989 10.427 2 11.5207 2C12.6144 2 13.5043 2.88989 13.5043 3.98361C13.5043 5.07749 12.6144 5.96737 11.5207 5.96737ZM11.5207 2.79342C10.8645 2.79342 10.3305 3.32749 10.3305 3.98361C10.3305 4.63988 10.8645 5.17381 11.5207 5.17381C12.177 5.17381 12.7109 4.63988 12.7109 3.98361C12.7109 3.32749 12.177 2.79342 11.5207 2.79342Z"
        fill="#516BEB"
      />
      <path d="M11.125 8.34778H11.9186V9.1412H11.125V8.34778Z" fill="#516BEB" />
    </svg>
  );
};

export default MeetingRequest;
