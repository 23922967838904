import React, { useEffect, useRef } from "react";

const RichTextEditorEmail = ({
  editorRef,
  onChange = () => {},
  textToInsert,
  setTextToInsert,
  isHtml = false,
  isDisabled = false,
  ...rest
}) => {
  const editorInstance = useRef(null);

  useEffect(() => {
    if (!editorRef.current) return;

    // Initialize the editor
    // eslint-disable-next-line no-undef
    editorInstance.current = new RichTextEditor("#rich-editor", {
      editorResizeMode: "none",
      showTagList: false,
      showSelectedBlock: false,
      skin: "rounded-corner",
      toolbar: "basic",
    });
    const editor = editorInstance.current;

    const handleChange = () => {
      const text = editor.getPlainText().trim();
      if (text) {
        const text1 = editor.getHTMLCode();
        onChange(text1.trim());
      } else {
        onChange("");
      }
    };
    editor.attachEvent("change", handleChange);

    // Handle enabling/disabling the editor
    if (isDisabled) {
      editor.setReadOnly(true); // Make the editor read-only
      // editor.container.style.pointerEvents = "none"; // Disable mouse events

      // Add the readOnly attribute to relevant buttons
      const toolbarButtons = editor.container.querySelectorAll(
        ".rte-toolbar-button"
      );
      toolbarButtons.forEach((button) => {
        button.setAttribute("readOnly", "true");
      });
    } else {
      editor.setReadOnly(false); // Enable editing
      // editor.container.style.pointerEvents = "auto"; // Enable mouse events

      // Remove the readOnly attribute from relevant buttons
      const toolbarButtons = editor.container.querySelectorAll(
        ".rte-toolbar-button"
      );
      toolbarButtons.forEach((button) => {
        button.removeAttribute("readOnly");
      });
    }

    return () => {
      editor.detachEvent("change", handleChange);
    };
  }, []);

  useEffect(() => {
    if (textToInsert) {
      insertTextAtCursor(textToInsert);
      setTextToInsert(null); // Reset the state after insertion
    }
  }, [textToInsert, setTextToInsert]);

  const insertTextAtCursor = (text) => {
    const editor = editorInstance.current;
    if (!editor) return;
    editor.selectDoc();
    editor.delete();
    editor.insertHTML(text);
  };

  return (
    <div>
      <textarea
        {...rest}
        id="rich-editor"
        ref={editorRef}
        style={{ width: "100%", height: "390px" }}
      />
    </div>
  );
};

export default RichTextEditorEmail;
