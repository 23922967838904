import React from "react";

const UpArrow = ({ color = "#333860" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071L6.29289 12.2929ZM11 9L11.7071 8.29289C11.3166 7.90237 10.6834 7.90237 10.2929 8.29289L11 9ZM14.2929 13.7071C14.6834 14.0976 15.3166 14.0976 15.7071 13.7071C16.0976 13.3166 16.0976 12.6834 15.7071 12.2929L14.2929 13.7071ZM7.70711 13.7071L11.7071 9.70711L10.2929 8.29289L6.29289 12.2929L7.70711 13.7071ZM10.2929 9.70711L14.2929 13.7071L15.7071 12.2929L11.7071 8.29289L10.2929 9.70711Z"
        fill={color}
      />
    </svg>
  );
};

export default UpArrow;
