export default function Email({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M3.30653 14.4887C2.95028 14.4887 2.64788 14.3644 2.39933 14.1159C2.15077 13.8673 2.02649 13.5649 2.02649 13.2087V4.79138C2.02649 4.43513 2.15077 4.13273 2.39933 3.88418C2.64788 3.63563 2.95028 3.51135 3.30653 3.51135H14.6935C15.0498 3.51135 15.3522 3.63563 15.6007 3.88418C15.8493 4.13273 15.9736 4.43513 15.9736 4.79138V13.2087C15.9736 13.5649 15.8493 13.8673 15.6007 14.1159C15.3522 14.3644 15.0498 14.4887 14.6935 14.4887H3.30653ZM9.00003 9.2488C9.06013 9.2488 9.11806 9.23942 9.17382 9.22067C9.2296 9.20192 9.28633 9.17812 9.34401 9.14927L14.6683 5.73028C14.7462 5.6774 14.8023 5.61297 14.8367 5.53701C14.8711 5.46105 14.881 5.37836 14.8666 5.28895C14.857 5.13221 14.7784 5.01274 14.6308 4.93054C14.4832 4.84833 14.3344 4.85482 14.1844 4.95L9.00003 8.26517L3.81925 4.95C3.67694 4.86492 3.53066 4.85698 3.38043 4.92621C3.23019 4.99543 3.14786 5.11394 3.13345 5.28173C3.11903 5.37259 3.13056 5.45877 3.16806 5.54025C3.20555 5.62174 3.26011 5.68508 3.33175 5.73028L8.65964 9.14927C8.71493 9.17812 8.77046 9.20192 8.82623 9.22067C8.882 9.23942 8.93993 9.2488 9.00003 9.2488Z"
        fill={color}
      />
    </svg>
  );
}
