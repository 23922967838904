import { EMAIL } from "../../../constants";
import {
  ADD_EMAIL_ATTACHMENT_QUICK_ACTION_INVOICE_FROM_DESKTOP,
  DOCUMENT_DRIVE_QUICK_ACTION_INVOICE,
  REMOVE_EMAIL_ATTACHMENT_INVOICE_QUICK_ACTION,
  UPDATE_EMAIL_QUICK_ACTION_INVOICE_ATTACHMENT_FROM_DESKTOP,
  INVOICE_DRIVE_OPEN_QUICK_QCTION,
  INVOICE_QUICK_ACTION_INITIAL,
} from "./action";

const initialState = {
  emailAttachmentQuickActionInvoice: [],
  qaInvoiceState: false,
  qaInvoiceType: "",
  isActionFrom: "",
};

const invoiceQAReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DOCUMENT_DRIVE_QUICK_ACTION_INVOICE:
      return {
        ...state,
        emailAttachmentQuickActionInvoice: [
          ...state.emailAttachmentQuickActionInvoice,
          ...payload,
        ],
      };

    case ADD_EMAIL_ATTACHMENT_QUICK_ACTION_INVOICE_FROM_DESKTOP: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.emailAttachmentQuickActionInvoice;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;

        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        emailAttachmentQuickActionInvoice: [...currentFiles, ...files],
      };
    }

    case UPDATE_EMAIL_QUICK_ACTION_INVOICE_ATTACHMENT_FROM_DESKTOP: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.emailAttachmentQuickActionInvoice.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let emailAttachmentQuickActionInvoice =
          state.emailAttachmentQuickActionInvoice;
        let currentFile = emailAttachmentQuickActionInvoice[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        emailAttachmentQuickActionInvoice[fileIndex] = currentFile;

        return {
          ...state,
          emailAttachmentQuickActionInvoice: emailAttachmentQuickActionInvoice,
        };
      }

      return state;
    }

    case REMOVE_EMAIL_ATTACHMENT_INVOICE_QUICK_ACTION: {
      let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected =
          state.emailAttachmentQuickActionInvoice.filter(
            (e) => !payload.some((i) => e.fileRef === i.fileRef)
          );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        emailAttachmentQuickActionInvoice: filteredData,
      };
    }

    case INVOICE_DRIVE_OPEN_QUICK_QCTION:
      return {
        ...state,
        qaInvoiceState: payload.isOpen,
        qaInvoiceType: EMAIL,
        isActionFrom: payload.isActionFrom,
      };

    case INVOICE_QUICK_ACTION_INITIAL:
      return {
        emailAttachmentQuickActionInvoice:
          initialState.emailAttachmentQuickActionInvoice,
      };

    default:
      return { ...state };
  }
};

export default invoiceQAReducer;
