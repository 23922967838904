import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const getLocationTracking = async ({
  resourceType,
  organization = null,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/location-tracking/${resourceType}`,
    {
      params: filters,
    }
  );
};
