import React, { useState } from "react";
import { HeadingXSmall, LabelLarge, LabelSmall } from "baseui/typography";
import { useForm, Controller, useWatch } from "react-hook-form";
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from "baseui/checkbox";
import { FormattedMessage } from "react-intl";

const CustomerPortalSettingLabel = ({
  children,
  checkBox = false,
  ...props
}) => {
  const {
    label,
    isActive,
    setActive,
    disable,
    caption = "",
    error = "",
    endCaption = "",
  } = props;
  //  lo const [checked, setChecked] = useState(isActive);

  return (
    <div style={{ marginBottom: "20px" }}>
      {/* <div
        // className={isActive == true ? "after" : "before"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      > */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <LabelSmall style={{ color: "#333860" }}>
            {/* Invoices */}
            <FormattedMessage id={label}>{label}</FormattedMessage>
          </LabelSmall>
          <HeadingXSmall>{caption}</HeadingXSmall>
          <HeadingXSmall $style={{ fontSize: "10px", color: "#FD372A" }}>
            {error}
          </HeadingXSmall>
        </div>
        <div style={{ paddingLeft: "10px" }}>
          {checkBox ? (
            <>
              <Checkbox
                disabled={disable}
                checked={isActive}
                checkmarkType={STYLE_TYPE.toggle_round}
                onChange={(e) => setActive(e.target.checked)}
                labelPlacement={LABEL_PLACEMENT.right}
              ></Checkbox>
            </>
          ) : (
            <></>
          )}
          <LabelLarge>{endCaption}</LabelLarge>
        </div>
      </div>
      {/* <div>
          <LabelLarge
            style={{
              paddingLeft: "20px",
              color: "#333860",
              paddingRight: "20px",
            }}
          >
            {caption}
          </LabelLarge>
        </div> */}
      {/* </div> */}
      {isActive ? <>{children}</> : <></>}
    </div>
  );
};

export default CustomerPortalSettingLabel;
