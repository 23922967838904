import {
  HeadingXLarge,
  ParagraphSmall,
  LabelMedium,
  HeadingXSmall,
  LabelLarge,
} from "baseui/typography";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal } from "baseui/modal";
import { TextArea } from "../TextArea";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { TextButton, KIND, SIZE } from "../TextButton";
import { refetchActions } from "../../redux/actions";
import { SelectBox } from "../SelectBox";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { useIntl } from "react-intl";
import { deleteDairyNote, getDairyNotes } from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { postDairyNote } from "../../services";
import {
  openModal,
  closeModal,
  addData,
  resetDiaryNoteReduxState,
  refetchDiaryNotes,
  changeActionType,
} from "../../redux/customerDairyNote/action";
import { useForm, Controller } from "react-hook-form";
import getSelectValues from "../../utils/getSelectValues";
import moment from "moment";
import { EDIT, NEW } from "../../constants";
import InfoTag from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import AlertTag from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/AlertTag";
import CricticalSvg from "../../assets/img/svg/CricticalSvg";
import setSelectValues from "../../utils/setSelectValues";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import { refetchInvoiceActions } from "../../redux/invoiceOverview/action";
import { Drawer } from "baseui/drawer";
import DairyNoteFormContainer from "./DairyNoteFormContainer";
import DairyNoteCards from "../DairyNoteCards/DairyNoteCards";
import Plus from "../../assets/img/svg/Upload/Plus";
import Cancel from "../../assets/img/svg/Template/Cancel";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
// import { ADD_CUSTOMER_NOTES } from "../../providers/RBACProvider/permissionList";
import "quill-mention";
const DairyNoteForm = ({
  resourceName,
  resourceId,
  entity,
  createDiaryNoteService,
  editDairyNote,
  addNotePermission,
  editNotePermission,
  deleteNotePermission,
}) => {
  const dispatch = useDispatch();
  const isModelOpen = useSelector((s) => s.customerDairyNoteReducer.isOpen);
  let { referenceData, currentOrganization } = useContext(MaxyfiContext);

  const findFullWidth = useRef(null);

  let { actionType } = useSelector((e) => e.customerDairyNoteReducer);

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `customer-notes-${resourceId}`,
      {
        refetchDiaryNotes,
      },
    ],
    async () => {
      return await getDairyNotes({
        organization: currentOrganization,
        customerId: resourceId,
        entity: entity,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const deleteDairyData = useMutation(
    (id) =>
      deleteDairyNote({
        ...id,
        organization: currentOrganization,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
        // dispatch(refetchActions());
        dispatch(refetchInvoiceActions());
      },
    }
  );

  return (
    <>
      <Drawer
        isOpen={isModelOpen}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              marginTop: "0px",
              padding: "15px",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
        onClose={() => {
          dispatch(closeModal());
          dispatch(resetDiaryNoteReduxState());
        }}
        ref={findFullWidth}
      >
        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LabelLarge>
            Notes{" "}
            {data &&
              data.data &&
              data.data.docs &&
              data.data.docs.length > 0 && (
                <span
                  style={{
                    backgroundColor: "#e2e7fb",
                    color: "#516beb",
                    padding: "3px 7px",
                    borderRadius: "5px",
                  }}
                >
                  {data && data.data && data.data.docs && data.data.docs.length}
                </span>
              )}
          </LabelLarge>
          <div style={{ display: "flex" }}>
            {/* <RBACWrapper role={addNotePermission} type="PERMISSION"> */}
            {data &&
              data.data &&
              data.data.docs &&
              data.data.docs.length > 0 && (
                <div className="drawer_add_btn">
                  <RBACWrapper role={addNotePermission} type="PERMISSION">
                    <TextButton
                      kind={KIND.tertiary}
                      size={SIZE.mini}
                      onClick={() => dispatch(changeActionType())}
                    >
                      <Plus />
                      Add note
                    </TextButton>
                  </RBACWrapper>
                </div>
              )}
            {/* </RBACWrapper> */}
            <div className="drawer_close_btn">
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => {
                  dispatch(closeModal());
                  dispatch(resetDiaryNoteReduxState());
                }}
              >
                <Cancel />
                Close
              </TextButton>
            </div>
          </div>
        </div>
        <RBACWrapper role={addNotePermission} type="PERMISSION">
          {((data &&
            data.data &&
            data.data.docs &&
            data.data.docs.length == 0) ||
            actionType === EDIT) && (
            <DairyNoteFormContainer
              resourceName={resourceName}
              resourceId={resourceId}
              createDiaryNoteService={createDiaryNoteService}
              editDairyNote={editDairyNote}
              entity={entity}
              refetch={refetch}
            />
          )}
          {actionType === NEW && (
            <DairyNoteFormContainer
              resourceName={resourceName}
              resourceId={resourceId}
              createDiaryNoteService={createDiaryNoteService}
              editDairyNote={editDairyNote}
              entity={entity}
              refetch={refetch}
            />
          )}
        </RBACWrapper>

        <div style={{ marginTop: "12px" }}>
          {data &&
            data.data &&
            data.data.docs &&
            data.data.docs.map((data) => (
              <>
                <DairyNoteCards
                  data={data}
                  refetch={refetch}
                  deleteDairyData={deleteDairyData}
                  getClientId={resourceId}
                  findFullWidth={findFullWidth}
                  editNotePermission={editNotePermission}
                  deleteNotePermission={deleteNotePermission}
                />
              </>
            ))}
        </div>
      </Drawer>
    </>
  );
};
export default DairyNoteForm;
