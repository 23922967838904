function ReleaseTimeLineCard({ height = 21, width = 21 }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.47449 9.18022L10.8716 6.22277L4.99803 10.7175L3.47449 9.18022V9.18022ZM14.6563 10.0213L11.6988 17.4184L10.1684 15.8879L14.6563 10.0213V10.0213ZM0.00689383 8.47016L4.82568 13.3027L16.5452 4.34075L7.58322 16.0603L12.4158 20.8791L20.6815 0.20445L0.00689383 8.47016Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default ReleaseTimeLineCard;
