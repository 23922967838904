import React from "react";

const CheckInSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0877 11.5969C10.1899 11.5969 10.2855 11.5788 10.3745 11.5426C10.4635 11.5064 10.55 11.4466 10.6341 11.3632L13.9565 8.04083C14.0823 7.91494 14.1453 7.74864 14.1453 7.54193C14.1453 7.33522 14.0823 7.16217 13.9562 7.02276C13.8168 6.89673 13.6438 6.83372 13.4371 6.83372C13.2303 6.83372 13.064 6.89665 12.9382 7.02253L10.0816 9.87913L9.08966 8.88723C8.96378 8.75771 8.79881 8.69294 8.59473 8.69294C8.39063 8.69294 8.22572 8.75684 8.1 8.88464C7.9587 9.02824 7.88805 9.2019 7.88805 9.40563C7.88805 9.60937 7.95862 9.77601 8.09977 9.90553L9.53454 11.3632C9.61886 11.4466 9.70657 11.5064 9.79767 11.5426C9.88877 11.5788 9.98543 11.5969 10.0877 11.5969ZM11.0002 18.2303C13.0006 16.406 14.4782 14.7514 15.4331 13.2664C16.388 11.7814 16.8654 10.4754 16.8654 9.34829C16.8654 7.57334 16.2978 6.1218 15.1625 4.99369C14.0273 3.86559 12.6402 3.30155 11.0015 3.30155C9.36267 3.30155 7.97524 3.86571 6.83915 4.99403C5.70308 6.12236 5.13504 7.57418 5.13504 9.34948C5.13504 10.4774 5.62399 11.783 6.60187 13.2661C7.57974 14.7493 9.04586 16.404 11.0002 18.2303ZM10.9988 19.963C10.8898 19.963 10.7813 19.945 10.6732 19.9089C10.565 19.8729 10.4673 19.8188 10.38 19.7468C8.09994 17.7346 6.39555 15.8702 5.26681 14.1535C4.13809 12.4368 3.57373 10.8357 3.57373 9.35006C3.57373 7.02999 4.3202 5.18167 5.81312 3.80509C7.30605 2.42852 9.03492 1.74023 10.9997 1.74023C12.9645 1.74023 14.6935 2.42852 16.1868 3.80509C17.6801 5.18167 18.4267 7.02999 18.4267 9.35006C18.4267 10.8357 17.8623 12.4368 16.7336 14.1535C15.6049 15.8702 13.9005 17.7346 11.6205 19.7468C11.5331 19.8188 11.4388 19.8729 11.3374 19.9089C11.236 19.945 11.1231 19.963 10.9988 19.963Z"
        fill="#333860"
      ></path>
    </svg>
  );
};

export default CheckInSvg;
