import { useContext } from "react";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import { getLetters } from "../../../../../../services";
import LetterStatusCard from "./LetterStatusCard";
import Letter from "../../../../../../assets/img/svg/CustomerTemplate/Letter";
import { HeadingXLarge, ParagraphLarge } from "baseui/typography";
import LetterStatusSvg from "../../../../../../assets/img/svg/LetterStatusSvg";
import {
  TextButton,
  KIND,
  SIZE,
} from "../../../../../../components/TextButton";
import { useDispatch, useSelector } from "react-redux";
import { isOpenModal } from "../../../../../../redux/actions";

const LetterStatus = ({ getParams }) => {
  let dispatch = useDispatch();
  const { currentOrganization } = useContext(MaxyfiContext);
  const { refetchLetterStatusActions } = useSelector(
    (s) => s.letterStatusReducer
  );

  const { data, refetch } = useQuery(
    `letter-${getParams}${refetchLetterStatusActions}`,
    async () => {
      return await getLetters({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          padding: "15px 40px",
        }}
      >
        <LetterStatusSvg />
        <HeadingXLarge>Letter Status</HeadingXLarge>
      </div>
      <div
        style={{
          paddingTop: "20px",
          height: "100%",
          overflow: "scroll",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        {data &&
        data?.data &&
        data?.data?.docs &&
        data?.data?.docs?.length === 0 ? (
          <ParagraphLarge style={{ display: "flex", justifyContent: "center" }}>
            No Records
          </ParagraphLarge>
        ) : (
          <>
            {data &&
              data?.data &&
              data?.data?.docs?.map((e) => {
                return <LetterStatusCard data={e} />;
              })}
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "end",
          background: "#F9F9F9",
          // width: "100%",
          height: "70px",
          padding: "15px",
          borderTop: "1px solid #CDCED9",
        }}
      >
        <div style={{ width: "125px", height: "36px" }}>
          <TextButton
            size={SIZE.compact}
            fullWidth
            type="button"
            // isLoading={isSubmitting}
            // disabled={isSubmitting}
            kind={KIND.tertiary}
            onClick={() => {
              dispatch(isOpenModal(false));
            }}
          >
            Cancel
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default LetterStatus;
