import {
  INSTANT_PAYMENT_DRAWER,
  UPDATE_INSTANT_PAYMENT_AMOUNT,
} from "./action";

const initialState = {
  modal: false,
  amount: 0,
  currency: "",
  installment_id: null,
};
const callAction = (state = initialState, { type, payload }) => {
  switch (type) {
    case INSTANT_PAYMENT_DRAWER: {
      const {
        modal = false,
        amount = 0,
        currency = "",
        installment_id = null,
      } = payload;
      return {
        ...state,
        modal,
        amount,
        currency,
        installment_id,
      };
    }

    case UPDATE_INSTANT_PAYMENT_AMOUNT: {
      return {
        ...state,
        amount: payload,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default callAction;
