import React from "react";
import { TextButton } from "../../../../components/TextButton";

const Tags = ({ setState }) => {
  return (
    <>
      <div
        style={{
          boxShadow: "0px 2px 4px 2px #d6d6d6",
          background: "#FFFFFF",
          border: "0.5px solid #CDCED925",

          borderRadius: "5px",
          width: "205px",
          height: "277px",
        }}
      >
        Tags
        <TextButton
          onClick={() => {
            setState(false);
          }}
        >
          Back
        </TextButton>
      </div>
    </>
  );
};

export default Tags;
