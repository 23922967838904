function Flag({ size = 18, color = "#adadad" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <path
        d="M4.677 11.9692V19.1693C4.677 19.4046 4.5966 19.6019 4.43581 19.7612C4.27504 19.9204 4.07581 20 3.83812 20C3.60045 20 3.40135 19.9204 3.24082 19.7612C3.08027 19.6019 3 19.4046 3 19.1693V3.00119C3 2.71753 3.09685 2.47975 3.29054 2.28786C3.48422 2.09595 3.72421 2 4.01052 2H11.2152C11.4538 2 11.6668 2.07421 11.8542 2.22262C12.0417 2.37101 12.1598 2.56379 12.2085 2.80095L12.4988 4.21539H17.6417C17.928 4.21539 18.1679 4.31094 18.3616 4.50204C18.5553 4.69311 18.6522 4.92988 18.6522 5.21235V13.1879C18.6522 13.4703 18.5553 13.7071 18.3616 13.8981C18.1679 14.0891 17.928 14.1846 17.6417 14.1846H12.673C12.4344 14.1846 12.2214 14.1104 12.034 13.962C11.8465 13.8136 11.7284 13.6208 11.6797 13.3836L11.3894 11.9692H4.677Z"
        fill={color}
      />
    </svg>
  );
}

export default Flag;
