export const TIMELINE_WHATSAPP_DRAWER = "TIMELINE_WHATSAPP_DRAWER";
export const TIMELINE_WHATSAPP_DRAWER_CLOSE = "TIMELINE_WHATSAPP_DRAWER_CLOSE";
export const TIMELINE_REFETCH_CHAT = "TIMELINE_REFETCH_CHAT";

export const timelineWhatsappDrawer = ({ phoneNumber }) => {
  return {
    type: TIMELINE_WHATSAPP_DRAWER,
    payload: phoneNumber,
  };
};

export const whatsappDrawerClose = () => {
  return {
    type: TIMELINE_WHATSAPP_DRAWER_CLOSE,
  };
};

export const timelineChatRefetch = () => {
  return {
    type: TIMELINE_REFETCH_CHAT,
  };
};
