import HeaderSort_V2 from "./HeaderSort_V2";

const headerType_V2 = ({
  headerFilterType,
  alignment,
  cellType,
  isCurrency,
  literal_id,
}) => {
  return (props) =>
    HeaderSort_V2({
      ...props,
      alignment,
      headerFilterType,
      cellType,
      isCurrency,
      literal_id,
    });
};

export default headerType_V2;
