import moment from "moment";
import "moment-timezone";
import { utcTimstampToLocalDate } from "./utcTimstampToLocalDate";

function generateInDaysMessage(planned_on, timeZone) {
  let message = {
    value: "",
    isAlert: false,
  };

  let diff = moment
    ?.utc(planned_on)
    ?.tz(timeZone)
    ?.diff(moment.tz(timeZone), "days", true);

  diff = Math.ceil(diff);

  if (diff == 0) {
    message.value = "Due Today";
    message.isAlert = true;
  } else if (diff > 0) {
    message.value = `In ${diff} Day${diff > 1 ? "s" : ""}`;
  } else {
    // message.value = "Over Due";
    message.value = `${"Over Due by"}${" "}${Math.abs(diff)} Day${
      Math.abs(diff) > 1 ? "s" : ""
    }`;

    message.isAlert = true;
  }

  return message;
}

export default generateInDaysMessage;
