function RightArrow() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.2 11C2.2 6.149 6.149 2.2 11 2.2C15.851 2.2 19.8 6.149 19.8 11C19.8 15.851 15.851 19.8 11 19.8C6.149 19.8 2.2 15.851 2.2 11ZM1.31174e-07 11C5.87658e-08 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11C22 4.928 17.072 2.03582e-07 11 1.31174e-07C4.928 5.87658e-08 2.03582e-07 4.928 1.31174e-07 11ZM11 9.9L7.7 9.9C7.095 9.9 6.6 10.395 6.6 11C6.6 11.605 7.095 12.1 7.7 12.1L11 12.1L11 14.069C11 14.564 11.594 14.806 11.935 14.454L15.004 11.385C15.224 11.165 15.224 10.824 15.004 10.604L11.935 7.535C11.594 7.194 11 7.436 11 7.92L11 9.9Z"
          fill="#ADADAD"
        />
      </svg>
    </>
  );
}

export default RightArrow;
