// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const createWorkflow = async ({
  organization = null,
  ...workflowData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/workflow`,
    workflowData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        // organization_id,
      },
    }
  );
};

export const getWorkflowMaintenance = async ({
  organization = null,
  workflowId,
  clientCode,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/workflow/${workflowId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
      // params: {
      //   clientCode,
      // },
    }
  );
};

export const copyWorkflow = async ({
  organization = null,
  organization_id,
  workflowId,
  ...workflowData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/workflow/copy/${workflowId}`,
    workflowData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        organization_id,
      },
    }
  );
};

export const deleteWorkflow = async ({ workflowId, organization = null }) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/workflow/${workflowId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
export const assignedWorkflow = async ({ workflowId, organization = null }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/workflow/${workflowId}/assigned`
  );
};

// Draft Workflow
export const draftWorkflow = async ({
  organization_id,
  organization = null,
  _id,
  workflowId,
  ...workflowData
}) => {
  workflowData.status = false;
  return await axiosWithAuth.put(
    `${HOST}${organization}/workflow/${workflowId}`,
    workflowData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        organization_id,
      },
    }
  );
};

export const updateWorkflow = async ({
  organization_id,
  organization = null,
  _id,
  workflowId,
  ...workflowData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/workflow/${workflowId}`,
    workflowData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        organization_id,
      },
    }
  );
};

export const getWorkflowlist = async ({
  organization_id = [],
  organization = null,
  page = 1,
  page_size = 10,
  sort = "-_id",
  filters = {},
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/workflow`, {
    headers: {
      appCode: APP_CODE,
    },
    params: {
      // organization_id,
      page,
      page_size,
      sort,
      ...filters,
    },
  });
};

export const defaultWorkflowList = async ({
  isDisabled,
  workflowId,
  organization = null,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/workflow/${workflowId}`,
    {
      is_disabled: isDisabled,
    }
  );
};

export const getWorkflow = async ({
  workflowId,
  organization_id,
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/workflow/${workflowId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        organization_id,
      },
    }
  );
};

export const uploadAttachment = async ({
  workflowId,
  organization_id,
  organization = null,
  letterFile,
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/workflow/upload-attachment`,
    letterFile,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        organization_id,
      },
    }
  );
};
//TODO: PRAVEEN WHAT IS BUTTON SAVE?
// export const buttonSave = (id, data) => {
//   return axiosWithAuth.put(`${process.env.REACT_APP_HOST}workflow/${id}`, data);
// };
