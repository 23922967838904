import { Drawer } from "baseui/drawer";
import { useSelector, useDispatch } from "react-redux";
import { letterAuditDrawer } from "../../../../../../redux/customerOverview/letterStatus/action";
import {
  HeadingXLarge,
  LabelMedium,
  ParagraphMedium,
  LabelSmall,
} from "baseui/typography";
import moment from "moment";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const LetterAudit = ({ data }) => {
  let intl = useIntl();

  const { letterAuditStatus } = useSelector((s) => s.letterStatusReducer);
  let dispatch = useDispatch();

  const { currentOrganization, referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  return (
    <>
      <Drawer
        isOpen={letterAuditStatus}
        autoFocus
        overrides={{
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
              width: "440px",
            }),
          },
        }}
        onClose={() => {
          dispatch(letterAuditDrawer(false));
          //   setIsAddNew(false);
        }}
      >
        <HeadingXLarge>Audit</HeadingXLarge>
        {/* <div style={{ display: "flex", gap: "40px", paddingTop: "10px" }}>
          LEFT CARD STARTS
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <ParagraphMedium>Delivery Method</ParagraphMedium>
            <ParagraphMedium>Tracking URL</ParagraphMedium>
            <ParagraphMedium>Delivery Status</ParagraphMedium>
            <ParagraphMedium>User Comments</ParagraphMedium>
          </div>
          LEFT CARD ENDS
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "9px",
            }}
          >
            <LabelMedium>Hello</LabelMedium>
            <LabelMedium>Trrack</LabelMedium>
            <LabelMedium>Del</LabelMedium>
            <LabelMedium>Nothing</LabelMedium>
          </div>
        </div> */}
        {letterAuditStatus &&
          data &&
          data?.audit?.map((apIn) => {
            let { data, ...rest } = apIn;
            let ap = { ...rest, ...data };

            let normalizedObject = Object.keys(ap).reduce((prev, curr) => {
              let result = { ...prev };

              if (Array.isArray(ap[curr])) {
                let innerObject = {};

                ap[curr].map((cr) => {
                  if (cr.label && cr.value) {
                    innerObject[cr.label] = cr.value;
                  }
                });

                result = { ...prev, ...innerObject };

                return result;
              } else if (typeof ap[curr] === "object") {
                result = { ...prev, ...ap[curr] };
              } else {
                result[curr] = ap[curr];
              }

              return result;
            }, {});

            return (
              <>
                {Object.keys(normalizedObject).map((e, i) => {
                  let resultLabel = "";
                  let resultValue = "";

                  let referenceKeys = [
                    { key: "audit_by", rdName: "users" },
                    {
                      key: "delivery_status",
                      rdName: "letter_delivery_status",
                    },
                    { key: "payment_mode", rdName: "payment_mode" },
                  ];

                  let exculdeKeys = ["_id", "logo"];
                  let dateKeys = ["audit_on"];

                  if (exculdeKeys.includes(e)) {
                    return null;
                  }

                  if (Array.isArray(normalizedObject[e])) {
                    return null;
                  }

                  if (referenceKeys.find((rk) => rk.key === e)) {
                    let refKey = referenceKeys.find((rk) => rk.key === e);
                    let rdData = referenceData[refKey.rdName]
                      ? referenceData[refKey.rdName].find(
                          (rd) => rd.id === normalizedObject[e]
                        )
                      : null;

                    if (rdData && rdData.id) {
                      resultLabel = e;
                      resultValue = rdData.label;
                    }
                  } else {
                    resultLabel = e;
                    resultValue = normalizedObject[e];
                  }

                  if (dateKeys.includes(e)) {
                    resultValue =
                      moment
                        .utc(resultValue)
                        .format(currentDefaultFormatDetails.date_format) +
                      " " +
                      intl.formatTime(resultValue);
                  }

                  return (
                    <div>
                      <div className="payment_audit_dtl">
                        <ParagraphMedium
                          $style={{ flex: "1", color: "#ADADAD" }}
                        >
                          <FormattedMessage id={resultLabel}>
                            {resultLabel}
                          </FormattedMessage>
                        </ParagraphMedium>
                        <LabelSmall
                          $style={{
                            flex: "2",
                          }}
                        >
                          {resultValue}
                        </LabelSmall>
                      </div>
                    </div>
                  );
                })}{" "}
                <hr style={{ marginTop: "10px" }} />{" "}
              </>
            );
          })}
      </Drawer>
    </>
  );
};

export default LetterAudit;
