import {
  CO_DRAWER,
  MINIMIZE_ACTION_DELETE,
  MINIMIZE_ALL_REMOVE_DATA,
  SMS_MINIMIZE_ACTION,
  BUSINESS_UNIT_MAX_VALIDATION,
} from "./actions";

const initialState = {
  active: false,
  type: null,
  quickActionsTempData: [],
  count: 0,
  validationField: {},
};

const customerOverviewDrawer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CO_DRAWER: {
      return { ...state, ...payload };
    }
    case SMS_MINIMIZE_ACTION:
      let smsAction = [...state.quickActionsTempData];
      smsAction.push(payload);
      return {
        ...state,
        quickActionsTempData: smsAction,
        count: Number(payload?.id) + 1,
      };

    case MINIMIZE_ACTION_DELETE:
      let removeAction = [
        ...state?.quickActionsTempData.filter((item) => item?.id !== payload),
      ];
      return {
        ...state,
        quickActionsTempData: removeAction,
      };

    case MINIMIZE_ALL_REMOVE_DATA:
      let removeActionAll = [];
      return {
        ...state,
        quickActionsTempData: removeActionAll,
      };
    case BUSINESS_UNIT_MAX_VALIDATION:
      return {
        ...state,
        validationField: {
          ...payload,
        },
      };

    default:
      return state;
  }
};

export default customerOverviewDrawer;
