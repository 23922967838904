export default function Plus({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_252_2629)">
        <path
          d="M4.12498 14.4591H5.05237L12.6043 6.91869L12.1327 6.43986L11.6654 5.97977L4.12498 13.5317V14.4591ZM3.67789 15.5841C3.48462 15.5841 3.32333 15.5194 3.19401 15.3901C3.06467 15.2607 3 15.0995 3 14.9062V13.6139C3 13.4332 3.0351 13.2594 3.1053 13.0925C3.17549 12.9257 3.27212 12.7808 3.39519 12.6577L12.7341 3.33028C12.8428 3.22163 12.968 3.13942 13.1099 3.08366C13.2517 3.02789 13.3961 3 13.5432 3C13.6952 3 13.8389 3.02789 13.9745 3.08366C14.1101 3.13942 14.236 3.22548 14.3524 3.34183L15.2538 4.25479C15.3701 4.36345 15.4543 4.48556 15.5062 4.62112C15.5581 4.7567 15.5841 4.90045 15.5841 5.05237C15.5841 5.19179 15.5581 5.33241 15.5062 5.47423C15.4543 5.61606 15.3701 5.74514 15.2538 5.86149L5.92641 15.1889C5.80333 15.312 5.65838 15.4086 5.49156 15.4788C5.32472 15.549 5.15092 15.5841 4.97016 15.5841H3.67789ZM12.6043 6.91869L12.1327 6.43986L11.6654 5.97977L12.6043 6.91869Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_252_2629">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
