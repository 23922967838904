import React from "react";
import { StyledSvg } from "baseui/icon";

function TriggerAction({ size = "22px" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M16.6742 11.4037L13.7042 9.91866C14.8867 9.00199 15.6475 7.57199 15.6475 5.95866C15.6475 3.18116 13.3834 0.916992 10.6059 0.916992C7.82835 0.916992 5.56419 3.18116 5.56419 5.95866C5.56419 7.91116 6.68252 9.60699 8.31419 10.4412V13.4295C6.34335 13.0078 6.46252 13.0262 6.24252 13.0262C5.75669 13.0262 5.29835 13.2187 4.95002 13.567L3.66669 14.8687L8.33252 19.5345C8.72669 19.9378 9.27669 20.167 9.84502 20.167H15.62C16.5184 20.167 17.2792 19.5253 17.4259 18.6362L18.1592 14.3187C18.3609 13.127 17.765 11.9537 16.6742 11.4037ZM16.3534 14.0162L15.62 18.3337H9.84502C9.76252 18.3337 9.68919 18.297 9.62502 18.242L6.25169 14.8687L10.1475 15.6845V5.95866C10.1475 5.70199 10.3492 5.50033 10.6059 5.50033C10.8625 5.50033 11.0642 5.70199 11.0642 5.95866V11.4587H12.6775L15.8492 13.0445C16.2159 13.2278 16.4175 13.622 16.3534 14.0162ZM7.39752 5.95866C7.39752 4.18949 8.83669 2.75033 10.6059 2.75033C12.375 2.75033 13.8142 4.18949 13.8142 5.95866C13.8142 6.82949 13.4659 7.61783 12.8975 8.19533V5.95866C12.8975 4.69366 11.8709 3.66699 10.6059 3.66699C9.34085 3.66699 8.31419 4.69366 8.31419 5.95866V8.19533C7.74585 7.61783 7.39752 6.82949 7.39752 5.95866Z"
        fill="#333860"
      />
    </svg>
  );
}

export default TriggerAction;
