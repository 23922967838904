import React from "react";

const Sum = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0H1.56C0.7 0 0 0.7 0 1.56C0 1.84 0.12 2.11 0.32 2.3L6.5 8L0.32 13.7C0.12 13.89 0 14.16 0 14.44C0 15.3 0.7 16 1.56 16H10.5C11.33 16 12 15.33 12 14.5C12 13.67 11.33 13 10.5 13H5L8.59 9.41C9.37 8.63 9.37 7.36 8.59 6.58L5 3H10.5C11.33 3 12 2.33 12 1.5C12 0.67 11.33 0 10.5 0Z"
        fill="#333860"
      />
    </svg>
  );
};

export default Sum;
