import React, { useContext, useRef, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _, { filter } from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import ReactQuill from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  ACT_FIELD_VISIT,
  ACT_NOTES,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { ADD_LIST_VALUES } from "../../../../providers/RBACProvider/permissionList";
import { useDebouncedCallback } from "use-debounce";
import { getHolidayBlackoutHolidayDate } from "../../../../services";
import { Icon, Typography } from "../../../../components_v2";

const NoteForm = (props) => {
  const {
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isIncrementor = true,
    isContent = true,
    is_inbound_call = false,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();
  const editorRef = useRef(null);

  let {
    referenceData,
    currentDefaultFormatDetails,
    space,
    currentOrganization,
    users,
    userInfo,
  } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      type: [{ id: "INFORMATIONAL" }],
      category: [{ id: "general", label: "General" }],
    },
  });

  const [followUpDate] = watch(["follow_up_date"]);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: followUpDate
            ? [
                moment(followUpDate)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: followUpDate ? true : false,
    }
  );

  const BlockEmbed = ReactQuill.Quill.import("blots/embed");
  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    const {
      notes,
      category,
      follow_up_date,
      type,
      is_hold_workflow = false,
    } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(notes, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...formValues,
      ...(isContent ? { content: "-" } : {}),
      notes,
      category: getSelectValues(category),
      type: getSelectValues(type),
      is_enable_alert_and_follow_up: follow_up_date ? true : false,
      ...(follow_up_date
        ? {
            follow_up_date: moment
              .tz(
                `${follow_up_date.getFullYear()}-${
                  follow_up_date.getMonth() + 1
                }-${follow_up_date.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...getActionReminder(data, {
        is_consent_enabled: false,
        is_calendar: false,
        is_confirmation: false,
        is_followup: false,
        is_hold_workflow,
        is_incrementor: props.isIncrementor,
        is_comment: false,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="rq-quill"
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <ReactQuill
              {...field}
              name={field.name}
              id={"notes-content-editor"}
              ref={editorRef}
              theme="snow"
              placeholder="Capture Notes..."
              value={field.value}
              modules={modules}
              style={{ height: "150px" }}
            />
          )}
        />
      </div>
      <div style={{ display: "flex", gap: "5px" }}>
        <div style={{ width: "150px" }}>
          <Controller
            name="category"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                creatable={creatablePermission ? true : false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "category",
                })}
                requiredAstric={true}
                placeholder={intl.formatMessage({
                  id: "category",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["notes_category"] || []}
              />
            )}
          />
        </div>
        <div style={{ width: "150px" }}>
          <Controller
            name="follow_up_date"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "follow_up_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div style={{ width: "150px" }}>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                clearable={false}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "type",
                })}
                placeholder={intl.formatMessage({
                  id: "type",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["notes_type"] || []}
              />
            )}
          />
        </div>
      </div>
      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section">
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              The Date Selected{" "}
              {_.get(data, "data.doc.holidays", []).map((val) => {
                return (
                  <>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                  </>
                );
              })}{" "}
              is a <b>“Holiday”</b>
            </Typography>
          </div>
        </>
      )}

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={
          formValues.is_inbound_call ? true : false || is_inbound_call
        }
        outcome={ACT_NOTES}
        is_calendar={false}
        is_consent_enabled={false}
        is_hold={true}
        is_confirmation={false}
        is_followup={false}
        is_incrementor={props.isIncrementor}
        is_consent={props.isConsent}
        is_comment={false}
        is_valid_from_contact={
          formValues.action_type === "FIELD_VISIT" ? true : false
        }
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

export default NoteForm;
