import React from "react";

const EmailInfo = () => {
  return (
    <svg
      width="32"
      height="30"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.9 0 0.00999999 0.95625 0.00999999 2.125L0 14.875C0 16.0438 0.9 17 2 17H18C19.1 17 20 16.0438 20 14.875V2.125C20 0.95625 19.1 0 18 0ZM17.6 4.51562L10.53 9.21187C10.21 9.42438 9.79 9.42438 9.47 9.21187L2.4 4.51562C2.15 4.34562 2 4.05875 2 3.75063C2 3.03875 2.73 2.61375 3.3 2.98563L10 7.4375L16.7 2.98563C17.27 2.61375 18 3.03875 18 3.75063C18 4.05875 17.85 4.34562 17.6 4.51562Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default EmailInfo;
