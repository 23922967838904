import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST_BS = process.env.REACT_APP_HOST;
const HOST = process.env.REACT_APP_HOST_TEMPLATE;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const createStrategyService = async ({
  organization = null,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/strategy`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getStrategiclist = async ({
  organization_id = [],
  organization = null,
  page = 1,
  page_size = 10,
  sort = "-_id",
  filters = {},
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/strategy`, {
    headers: {
      appCode: APP_CODE,
    },
    params: {
      // organization_id,
      page,
      page_size,
      sort,
      ...filters,
    },
  });
};

export const getSingleStrategyData = async ({
  organization = null,
  strategyId,
  clientCode,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/strategy/${strategyId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const updateStrategy = async ({
  organization_id,
  organization = null,
  _id,
  strategyId,
  ...strategyData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/strategy/${strategyId}`,
    strategyData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        organization_id,
      },
    }
  );
};

export const deleteStrategyFn = async ({ strategyId, organization = null }) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/strategy/${strategyId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const copyStrategyFn = async ({
  organization = null,
  clientCode,
  strategyId,
  ...templateData
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/strategy/${strategyId}/copy`,
    templateData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const refetchStrategyFn = async ({
  strategyId,
  organization = null,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/strategy/${strategyId.strategyId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

//report Maintainanace
export const getReportlist = async ({
  organization = null,
  page = 1,
  page_size = 10,
  sort = "-_id",
  filters = {},
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/report-maintenance`, {
    headers: {
      appCode: APP_CODE,
    },
    params: {
      // organization_id,
      page,
      page_size,
      sort,
      ...filters,
    },
  });
};

export const getSingleReportData = async ({
  organization = null,
  reportId,
  clientCode,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/report-maintenance/${reportId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const deleteReportMaintain = async ({
  reportId,
  organization = null,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}${organization}/report-maintenance/${reportId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const refetchReportFn = async ({ reportId, organization = null }) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/report-maintenance/${reportId?.reportId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const updateReportDetails = async ({
  organization_id,
  organization = null,
  _id,
  reportId,
  ...reportData
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/report-maintenance/${reportId}`,
    reportData,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        organization_id,
      },
    }
  );
};
