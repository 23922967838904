import moment from "moment";
import React from "react";
import { useContext } from "react";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import { DATE, DATE_ORG, DATE_TIME } from "../constants";
import { FormattedTime } from "react-intl";

const DateTypes = ({ type, value }) => {
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  switch (type) {
    case DATE:
      return moment(value).format(currentDefaultFormatDetails.date_format);
    case DATE_TIME:
      return (
        <>
          {moment
            .tz(value, currentDefaultFormatDetails.time_zone)
            .format(currentDefaultFormatDetails.date_format)}{" "}
          <FormattedTime value={value} />
        </>
      );
    case DATE_ORG:
      return moment
        .tz(value, currentDefaultFormatDetails.time_zone)
        .format(currentDefaultFormatDetails.date_format);
    default:
      return null;
  }
};

const DateComponent = ({ type = "", value = "" }) => {
  return <DateTypes type={type} value={value} />;
};

export default DateComponent;
