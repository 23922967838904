function PlusContact() {
  return (
    <>
      <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.73711 2.75C5.44606 2.75 5.20793 2.9975 5.20793 3.3V4.95H3.62039C3.32934 4.95 3.09121 5.1975 3.09121 5.5C3.09121 5.8025 3.32934 6.05 3.62039 6.05H5.20793V7.7C5.20793 8.0025 5.44606 8.25 5.73711 8.25C6.02816 8.25 6.26629 8.0025 6.26629 7.7V6.05H7.85382C8.14487 6.05 8.383 5.8025 8.383 5.5C8.383 5.1975 8.14487 4.95 7.85382 4.95H6.26629V3.3C6.26629 2.9975 6.02816 2.75 5.73711 2.75ZM5.73711 0C2.81604 0 0.445312 2.464 0.445312 5.5C0.445312 8.536 2.81604 11 5.73711 11C8.65818 11 11.0289 8.536 11.0289 5.5C11.0289 2.464 8.65818 0 5.73711 0ZM5.73711 9.9C3.40343 9.9 1.50367 7.9255 1.50367 5.5C1.50367 3.0745 3.40343 1.1 5.73711 1.1C8.07079 1.1 9.97054 3.0745 9.97054 5.5C9.97054 7.9255 8.07079 9.9 5.73711 9.9Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default PlusContact;
