import React, { useContext } from "react";
import ToolTip from "../ToolTip/ToolTip";
import { FormattedDate } from "react-intl";
import { HeadingXSmall, Paragraph2, ParagraphMedium } from "baseui/typography";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import moment from "moment";

const NameDate = (props) => {
  // const changedData = value == null ? new Date() : new Date(value);
  let { referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  let { value, column, alignment, accessor, row, ...rest } = props;

  const date = moment
    .utc(props?.row?.original?.captured_on)
    .format(currentDefaultFormatDetails.date_format);

  const user = referenceData?.users?.find(({ id }) => {
    return id == props?.row?.original?.captured_by;
  });

  //   let date = utcTimstampToLocalDate(value);
  //   const dateValue = value && value.value; captured_by , captured_by

  return (
    <>
      {/* <p>{value}</p> */}
      <div>
        <ParagraphMedium>
          {user ? user.label : "Consumer via Portal"}
        </ParagraphMedium>
        <HeadingXSmall $style={{ color: "#ADADAD" }}>{date}</HeadingXSmall>
      </div>
    </>
  );
};
export default NameDate;
