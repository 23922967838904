import React, { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Icon, Typography } from "../../../../components_v2";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import moment from "moment";
import _ from "lodash";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import { KIND, TextButton } from "../../../../components/TextButton";
import { useMutation, useQuery } from "react-query";
import { deletelegalMotion, getGridStructure } from "../../../../services";
import columnMapper_V2 from "../../../../utils/mapper_v2";
import queryClient from "../../../../providers/queryClient";
import { LEGAL_ACTION_INFO_ } from "../../../../constants";
import { toast } from "react-toastify";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import {
  ADD_EDIT_MOTIONS_COUNSEL_LEGAL,
  ADD_REMOVED_COUNSEL_LEGAL,
  DELETE_MOTIONS_LEGAL,
} from "../../../../providers/RBACProvider/permissionList";
const Header = ({ label = "" }) => {
  const intl = useIntl();
  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

const DeletePopper = ({ row, setDeletedMotion }) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  const { currentOrganization, currentDefaultFormatDetails, refetch } =
    useContext(MaxyfiContext);
  const { customerId } = useParams();
  const deleteMotion = useMutation(
    ({ motionId }) =>
      deletelegalMotion({
        organization: currentOrganization,
        customerId: customerId,
        id: motionId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
        queryClient.refetchQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
      },
    }
  );
  const handleDeleteMotion = async (motionId) => {
    setDeletedMotion(motionId);
    await deleteMotion.mutateAsync({ motionId });
  };
  return (
    <StatefulPopover
      triggerType={TRIGGER_TYPE.click}
      content={({ close }) => (
        <div className="co_groping_delete_popover">
          <div className="co_groping_delete_popover_body">
            <Icon icon="alert" color="#FD372A" />
            <Typography type="p">
              This will remove the Motions Record, Do you want to continue?
            </Typography>
          </div>
          <div className="co_groping_delete_popover_footer">
            <TextButton
              size="mini"
              kind={KIND.tertiary}
              type="button"
              onClick={() => close()}
            >
              <Typography type="h5" style={{ color: "#FD372A" }}>
                Cancel
              </Typography>
            </TextButton>
            <TextButton
              size="mini"
              kind={KIND.secondary}
              onClick={() => {
                handleDeleteMotion(_.get(row, "_id"));
                close();
              }} // Pass function reference
              type="error"
            >
              Delete
            </TextButton>
          </div>
        </div>
      )}
    >
      <div>
        <Icon icon="delete_outline" color="#ADADAD" isPressable />
      </div>
    </StatefulPopover>
  );
};

const Motiontable = ({ selectedMotion, setEditEnable, setDeletedMotion }) => {
  const [width, setWidth] = useState(0);
  let ref = React.useRef();
  const { customerId } = useParams();
  const gridStructure = useQuery(
    [`GRID_STRUCTURE_LEGAL_MOTION`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "motion_list_table",
      });
    },
    {
      cacheTime: 86400000,
    }
  );
  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );
  const [hoveredRow, setHoveredRow] = useState(null);
  const [expanded, setExpanded] = React.useState({});
  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);
  const data = useMemo(() => {
    const data = _.get(legalInfo, "data.doc.mot", []);
    return data;
  }, [legalInfo?.data]);

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const handleEditMotion = (motionItem) => {
    setEditEnable(true);
    selectedMotion(motionItem);
  };

  return (
    <div className="co-invoice-section">
      <div className="co_altc_payment-table_wrapper">
        <div className="co_altc_payment_table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* Table Body */}
        <div className="co-table_body">
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row) => (
            <div
              key={row.id}
              className={`co-table_body-group ${
                _.get(row, "original.idl", false) ? "modifi_row" : ""
              }`}
              style={{ position: "relative" }}
              onMouseEnter={() => setHoveredRow(row.id)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                  }}
                  className="co-table_body-cell"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
              <div
                className="co-inv-table-edit"
                style={{ display: "flex", right: "2%" }}
              >
                <RBACWrapper
                  role={ADD_EDIT_MOTIONS_COUNSEL_LEGAL}
                  type="PERMISSION"
                >
                  {hoveredRow === row.id &&
                    !_.get(row, "original.idl", false) && (
                      <Icon
                        icon="edit_outline"
                        color="#ADADAD"
                        isPressable
                        onClick={() =>
                          handleEditMotion(_.get(row, "original", ""))
                        }
                      />
                    )}
                </RBACWrapper>
                <RBACWrapper role={DELETE_MOTIONS_LEGAL} type="PERMISSION">
                  {row &&
                    row.original &&
                    !_.get(row, "original.idl", false) &&
                    hoveredRow === row.id && (
                      <DeletePopper
                        row={row.original}
                        setDeletedMotion={setDeletedMotion}
                      />
                    )}
                </RBACWrapper>
              </div>
            </div>
          ))}
        </div>
        {/* {table.getRowModel().rows.map((row) => (
         
        ))} */}
      </div>
    </div>
  );
};

export default Motiontable;
