import React, { useEffect, useRef, useState } from "react";
import { KIND, SIZE, TextButton } from "../TextButton";
import { Drawer } from "baseui/drawer";
import { HeadingXLarge, LabelLarge, LabelXSmall } from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { SelectBox } from "../SelectBox";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useContext } from "react";
import { TextBox } from "../TextBox";
import getSelectValues from "../../utils/getSelectValues";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import * as TextBoxSize from "../../components/TextBox";
import {
  address,
  cityAndState,
  constactEmailRegex,
  customerName,
  emailRegex,
  nameRegex,
  unallowedSpecialCharacter,
} from "../../utils/regexConfig";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { createCustomerContact } from "../../services/customerSummary";
import setSelectValues from "../../utils/setSelectValues";
import Plus from "../../assets/img/svg/Upload/Plus";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import PlusContact from "../../assets/img/svg/CustomerOverViewIcon/PlusContact";
import PhoneInputFields from "../../containers/CustomerOverView/PhoneInputFields";
import { useSelector } from "react-redux";
import { Icon, Typography } from "../../components_v2";
import { TextArea } from "../TextArea";
import useFormat from "../../hooks/useFormat";
import ValidCallSvg from "../../assets/img/svg/ValidCallSvg";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import ConatctEmailFields from "../../containers/CustomerOverView/ConatctEmailFields";
import ContactLocation from "../../assets/img/svg/ContactLocation";
import CustomFieldsTab from "../../containers_v2/CustomerOverview/CustomFields/CustomFieldsTab";
import moment from "moment";
import CheckBoxBaseweb from "../CheckBoxBaseweb";

const AddCustomer = () => {
  let intl = useIntl();
  const format = useFormat();
  let {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    defaultData,
    customContact,
    customCustomer,
    space,
  } = useContext(MaxyfiContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [addressComment, setAddressCommentOpen] = useState({});
  const [textAddressCommentOpen, setTextAddressCommentOpen] = useState(false);
  const [textLandline, setTextLandline] = useState({});
  const [textCommentOpen, setTextCommentOpen] = useState(false);
  const [commentOpen, setCommentOpen] = useState({});
  const { type, data } = useSelector((e) => e.customerOverviewDrawer);
  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  const findFullWidth = useRef(null);
  const schema = Yup.object().shape(
    {
      display_name: Yup.string()
        .required("Consumer Name is Mandatory")
        .max(100, "Character Length can't be more than 100")
        .matches(
          customerName,
          "Invalid input - Only Alphabets ' , . - and _ allowed."
        ),
      first_name: Yup.string()
        .required("First Name is Mandatory")
        .max(100, "Character Length can't be more than 100")
        .matches(
          nameRegex,
          "Invalid input - Only Alphabets ' , . - and _ allowed."
        ),
      business_unit: Yup.array()
        .min(1, "Required")
        .required("Client is Mandatory"),
      last_name: Yup.string()
        .nullable()
        .max(100, "Character Length can't be more than 100")
        .matches(nameRegex, {
          message: "Invalid input - Only Alphabets ' , . - and _ allowed.",
          excludeEmptyString: true,
        }),
      designation: Yup.array().min(1, "Required").required("Role is Mandatory"),
      phone: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .test("phone", "Invaild Phone Number", (value, ctr) => {
                if (
                  ctr.parent.country_code &&
                  ctr.parent.country_code[0] &&
                  ctr.parent.country_code[0].id &&
                  ctr?.parent?.status?.[0]?.id == "VALID"
                ) {
                  let NumberValid = isValidPhoneNumber(
                    `${ctr.parent.country_code[0].id}${value}`
                  );

                  return NumberValid;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              })
              .nullable()
              .optional(),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),

            country_code: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (phone) => {
                  //
                  return phone && phone?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["country_code", "status", "value"]
        )
      ),

      email: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .optional()
              .test("email", "Invalid Email ID", (value, ctr) => {
                if (value && ctr?.parent?.status?.[0]?.id == "VALID") {
                  let emailValue = value?.match(constactEmailRegex);
                  return emailValue;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              }),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["status", "value"]
        )
      ),

      landline: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .test("phone", "Invaild Phone Number", (value, ctr) => {
                if (
                  ctr.parent.country_code &&
                  ctr.parent.country_code[0] &&
                  ctr.parent.country_code[0].id &&
                  ctr?.parent?.status?.[0]?.id == "VALID"
                ) {
                  let NumberValid = isValidPhoneNumber(
                    `${ctr.parent.country_code[0].id}${value}`
                  );

                  return NumberValid;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              })
              .nullable()
              .optional(),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),

            country_code: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (phone) => {
                  //
                  return phone && phone?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["country_code", "status", "value"]
        )
      ),
    },
    [["email", "phone"]]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: [
        {
          country_code: setSelectValues(defaultData?.phone_code),
          type: [{ id: "PERSONAL_MOBILE", label: "Personal" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      landline: [
        {
          country_code: setSelectValues(defaultData?.phone_code),
          type: [{ id: "OFFICE_LANDLINE", label: "Office" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      email: [
        {
          type: [{ id: "PERSONAL_EMAIL", label: "Personal" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      address: [
        {
          type: [{ id: "OFFICE_ADDRESS", label: "Office" }],
          line_1: "",
          line_2: "",
          country: "",
          state: [],
          city: "",
          zip_code: "",
          status: [{ id: "VALID", label: "Valid" }],
        },
      ],
    },
  });
  const {
    consumer_details,
    capture_add_details,
    is_contact_custom,
    display_name,
  } = watch();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "phone",
  });

  const landlineArray = useFieldArray({
    control,
    name: "landline",
  });

  const emailArray = useFieldArray({
    control,
    name: "email",
  });

  const addressArray = useFieldArray({
    control,
    name: "address",
  });

  let phoneFileds = fields;

  const addCustomerContact = useMutation(
    (contactData) =>
      createCustomerContact({
        ...contactData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        setIsOpen(false);
      },
    }
  );

  const onSubmit = async (data) => {
    let finalValues = { ...data };

    //Remove the phone abby and aat
    finalValues.phone = finalValues.phone.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });
    //Remove the Email abby and aat
    finalValues.email = finalValues.email.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });
    //Remove the Address abby and aat
    finalValues.address = finalValues.address.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });
    //Remove the Landline abby and aat
    finalValues.landline = finalValues.landline.map((phoneObj) => {
      const { aby, aat, ...rest } = phoneObj;
      return rest;
    });
    let customFieldData = {};
    let customFieldDataCustomer = {};
    if (customContact.length > 1) {
      //custom Contact
      for (let i = 0; i < customContact.length; i++) {
        let path = customContact?.[i]?.path;
        let dataType = customContact?.[i]?.data_type;
        let isVisible = customContact?.[i]?.is_hidden;
        let keys = path.split(".");
        let current = data;
        for (let j = 0; j < keys.length; j++) {
          if (current.hasOwnProperty(keys[j])) {
            current = current[keys[j]];
          }
        }
        if (is_contact_custom) {
          if (!isVisible) {
            if (dataType === "DATE") {
              if (current) {
                customFieldData[path] = moment
                  .tz(
                    `${current?.getFullYear()}-${
                      current?.getMonth() + 1
                    }-${current?.getDate()}`,
                    "YYYY-MM-DD",
                    currentDefaultFormatDetails.time_zone
                  )
                  .utc()
                  .valueOf();
              }
            } else if (dataType === "AMOUNT") {
              if (current.value) {
                customFieldData[path] = current;
              }
            } else if (
              dataType === "TEXT" &&
              customContact?.[i]?.options?.length > 0 &&
              current
            ) {
              customFieldData[path] = current && getSelectValues(current);
            } else {
              if (current) {
                customFieldData[path] = current;
              }
            }
          }
        }
      }
    }
    if (customCustomer.length > 1) {
      //custom Customer
      for (let it = 0; it < customCustomer.length; it++) {
        let pathCustomer = customCustomer?.[it]?.path;
        let dataTypeCustomer = customCustomer?.[it]?.data_type;
        let isVisibleCustomer = customCustomer?.[it]?.is_hidden;
        let keysCustomer = pathCustomer.split(".");
        let currentCustomer = data;
        for (let jt = 0; jt < keysCustomer.length; jt++) {
          if (currentCustomer.hasOwnProperty(keysCustomer[jt])) {
            currentCustomer = currentCustomer[keysCustomer[jt]];
          }
        }
        if (consumer_details) {
          if (!isVisibleCustomer) {
            if (dataTypeCustomer === "DATE") {
              if (currentCustomer) {
                customFieldDataCustomer[pathCustomer] = moment
                  .tz(
                    `${currentCustomer?.getFullYear()}-${
                      currentCustomer?.getMonth() + 1
                    }-${currentCustomer?.getDate()}`,
                    "YYYY-MM-DD",
                    currentDefaultFormatDetails.time_zone
                  )
                  .utc()
                  .valueOf();
              }
            } else if (dataTypeCustomer === "AMOUNT") {
              if (currentCustomer.value) {
                customFieldDataCustomer[pathCustomer] = currentCustomer;
              }
            } else if (
              dataTypeCustomer === "TEXT" &&
              customCustomer?.[it]?.options?.length > 0 &&
              currentCustomer
            ) {
              customFieldDataCustomer[pathCustomer] =
                currentCustomer && getSelectValues(currentCustomer);
            } else {
              if (currentCustomer) {
                customFieldDataCustomer[pathCustomer] = currentCustomer;
              }
            }
          }
        }
      }
    }
    let phoneList = finalValues?.phone
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value?.type, false);
        newValue.country_code = getSelectValues(value?.country_code, false);
        newValue.status = getSelectValues(value?.status, false);

        newValue.comment = value?.comment ? value?.comment : "";

        return newValue;
      })
      .filter(({ value }) => value);

    let landlineList = finalValues?.landline
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value.type, false);
        newValue.country_code = getSelectValues(value.country_code, false);
        newValue.status = getSelectValues(value.status, false);

        newValue.comment = value?.comment ? value?.comment : "";

        return newValue;
      })
      .filter(({ value }) => value);

    let emailList = finalValues?.email
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value.type, false);
        newValue.status = getSelectValues(value.status, false);

        newValue.comment = value?.comment ? value?.comment : "";

        return newValue;
      })
      .filter(({ value }) => value);

    const addressList = finalValues?.address
      ?.map((value) => {
        let newValue = { ...value };
        newValue.country = getSelectValues(value?.country);
        newValue.status = getSelectValues(value?.status);
        newValue.type = getSelectValues(value?.type);
        newValue.state = getSelectValues(value?.state);
        newValue.zip_code = value?.zip_code?.trim();
        newValue.comment = value?.comment ? value?.comment.trim() : "";

        return newValue;
      })
      .filter(
        ({ city, line_1, line_2, state, zip_code }) =>
          city || line_1 || line_2 || state || zip_code
      );

    let value = {
      display_name: data.display_name,
      default_currency: getSelectValues(data.default_currency),
      business_unit: getSelectValues(data.business_unit),
      contacts: [
        {
          first_name: finalValues.first_name,
          ...(finalValues.last_name
            ? {
                last_name: finalValues?.last_name,
              }
            : {}),
          designation: getSelectValues(finalValues?.designation, false),
          ...(finalValues.relation
            ? {
                relation:
                  finalValues?.relation?.length > 0
                    ? getSelectValues(finalValues?.relation, false)
                    : "",
              }
            : {}),
          ...(phoneList?.length === 0 ? {} : { phone: phoneList }),
          ...(landlineList?.length === 0 ? {} : { landline: landlineList }),
          ...(emailList?.length === 0 ? {} : { email: emailList }),
          ...(addressList?.length === 0 ? {} : { address: addressList }),
          ...(is_contact_custom
            ? { custom_field: { ...customFieldData } }
            : {}),
        },
      ],
      ...(finalValues?.customer_id
        ? { customer_id: finalValues?.customer_id }
        : {}),

      ...(consumer_details
        ? { custom_field: { ...customFieldDataCustomer } }
        : {}),
    };
    await addCustomerContact.mutateAsync({
      ...value,
    });
  };

  const toggleCommentOpen = (index) => {
    setCommentOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };
  const toggleAddressCommentOpen = (index) => {
    setAddressCommentOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      <div className="drawer_add_btn">
        <TextButton
          kind={KIND.secondary}
          size={SIZE.mini}
          onClick={() => setIsOpen(true)}
        >
          <Plus />
          <FormattedMessage id="add_customer"> add_customer</FormattedMessage>
        </TextButton>
      </div>

      <Drawer
        isOpen={isOpen}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              marginTop: "0px",
              padding: "15px",

              // overflowY: "hidden",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
              width: "606px !important",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
        autoFocus
        onClose={() => {
          reset();
          setIsOpen(false);
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <HeadingXLarge $style={{ color: "#516beb" }}>
            Add Consumer
          </HeadingXLarge>
          <Controller
            name={`display_name`}
            control={control}
            render={({ field }) => (
              <>
                <TextBox
                  {...field}
                  fullWidth
                  name={field.name}
                  size={TextBoxSize.SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "customer_name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "customer_name",
                  })}
                  value={field.value}
                  onKeyDown={(e) => {
                    if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onBlur={() => {
                    if (
                      (display_name && display_name?.trim() !== null) ||
                      undefined
                    ) {
                      const [firstName, ...lastName] = display_name
                        ? display_name?.trim()?.split(" ")
                        : "";
                      setValue("first_name", firstName || "");
                      setValue("last_name", lastName.join(" ") || "");
                    }
                  }}
                />
              </>
            )}
          />

          <div style={{ display: "flex", gap: "30px" }}>
            <div style={{ width: "260px" }}>
              <Controller
                name="business_unit"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <SelectBox
                      {...field}
                      //
                      name={field.name}
                      requiredAstric={true}
                      size={TextBoxSize.SIZE.mini}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: `client`,
                      })}
                      placeholder={intl.formatMessage({
                        id: "client",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={
                        referenceData && referenceData["business_unit_list"]
                          ? referenceData["business_unit_list"]
                          : []
                      }
                    />
                  </>
                )}
              />
            </div>
            <div style={{ width: "260px" }}>
              <Controller
                name={"default_currency"}
                control={control}
                defaultValue={[
                  {
                    id: currentDefaultFormatDetails.default_currency,
                    label: currentDefaultFormatDetails.default_currency,
                  },
                ]}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <SelectBox
                      {...field}
                      clearable={false}
                      requiredAstric={true}
                      name={field.name}
                      size={TextBoxSize.SIZE.mini}
                      creatable={creatablePermission ? true : false}
                      // error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: `currency`,
                      })}
                      placeholder={intl.formatMessage({
                        id: "currency",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["currency"]}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div style={{ width: "260px" }}>
            <Controller
              name="customer_id"
              control={control}
              // rules={{ required: "Required" }}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    fullWidth
                    name={field.name}
                    size={TextBoxSize.SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "customer_id",
                    })}
                    placeholder={intl.formatMessage({
                      id: "customer_id",
                    })}
                    value={field.value}
                    // onChange={(e) => {
                    //   updateLocalState("first_name", e.target.value);
                    // }}
                  />
                </>
              )}
            />
          </div>
          {customCustomer.length > 0 && (
            <div style={{ marginBottom: "15px", width: "260px" }}>
              <Controller
                name="consumer_details"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckBoxBaseweb {...field} checked={field.value}>
                    <Typography type="p" style={{ marginTop: "4px" }}>
                      Capture Additional Consumer Details
                    </Typography>
                  </CheckBoxBaseweb>
                )}
              />
            </div>
          )}

          {consumer_details ? (
            <>
              <LabelLarge $style={{ marginBottom: "10px" }}>
                <FormattedMessage
                  id="custom_fields"
                  style={{ color: "#333860" }}
                >
                  custom_fields
                </FormattedMessage>
              </LabelLarge>

              <CustomFieldsTab
                customFields={data}
                fieldLevel={customCustomer}
                width={"530px"}
                wrapWidth={"200px"}
                control={control}
                setValue={setValue}
                reSet={reset}
              />
            </>
          ) : (
            <></>
          )}

          <LabelLarge $style={{ marginBottom: "10px" }}>
            Contact Details
          </LabelLarge>

          <div style={{ display: "flex", gap: "30px" }}>
            <Controller
              name="first_name"
              control={control}
              // rules={{ required: "Required" }}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    requiredAstric={true}
                    fullWidth
                    //
                    name={field.name}
                    size={TextBoxSize.SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "first_name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "first_name",
                    })}
                    value={field.value}

                    // onChange={(e) => {
                    //   updateLocalState("first_name", e.target.value);
                    // }}
                  />
                </>
              )}
            />
            <Controller
              name="last_name"
              control={control}
              // rules={{
              //   pattern: {
              //     message:
              //       "Invalid input - Only Alphabets ' , . - and _ allowed.",
              //     value: nameRegex,
              //   },
              // }}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    //
                    fullWidth
                    name={field.name}
                    size={TextBoxSize.SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "last_name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "last_name",
                    })}
                    value={field.value}
                    // onChange={(e) => {
                    //   updateLocalState("last_name", e.target.value);
                    // }}
                  />
                </>
              )}
            />
          </div>
          {/* Role  */}
          <div style={{ display: "flex", gap: "30px" }}>
            <div style={{ width: "260px" }}>
              <Controller
                name="designation"
                control={control}
                defaultValue={[
                  { label: "Primary Contact", id: "primary_contact" },
                ]}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <SelectBox
                      {...field}
                      //
                      name={field.name}
                      requiredAstric={true}
                      size={TextBoxSize.SIZE.mini}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: `role`,
                      })}
                      placeholder={intl.formatMessage({
                        id: "role",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={
                        referenceData &&
                        referenceData?.recipient_type?.map((i) => {
                          return { label: i.label, id: i.id };
                        })
                      }
                    />
                  </>
                )}
              />
            </div>
            <div style={{ width: "260px" }}>
              <Controller
                name="relation"
                control={control}
                render={({ field }) => (
                  <>
                    <SelectBox
                      {...field}
                      name={field.name}
                      size={TextBoxSize.SIZE.mini}
                      creatable={creatablePermission ? true : false}
                      // error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: `relation`,
                      })}
                      placeholder={intl.formatMessage({
                        id: "relation",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={
                        referenceData &&
                        referenceData["customer_contact_relation_list"]
                      }
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div style={{ width: "205px" }}></div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <LabelLarge>
              <FormattedMessage id="mobile" style={{ color: "#333860" }}>
                mobile
              </FormattedMessage>
            </LabelLarge>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "row-reverse",
              }}
            >
              <TextButton
                kind={KIND.tertiary}
                size={TextBoxSize.SIZE.mini}
                type="button"
                style={{
                  fontSize: "12px",
                  fontWeight: "400px",
                  gap: "2px",
                  display: "flex",
                  // textAlign: "center",
                  justifyContent: "center",
                  alignItem: "center",
                }}
                onClick={() =>
                  append({
                    country_code: setSelectValues(defaultData.phone_code),
                    type: [{ id: "PERSONAL_MOBILE", label: "Personal" }],
                    status: [{ id: "VALID", label: "Valid" }],
                    value: "",
                  })
                }
              >
                <PlusContact /> Add Another Number
              </TextButton>
            </div>
          </div>

          {/* Phone Inputs */}
          {phoneFileds &&
            phoneFileds.map((e, i) => {
              return (
                <PhoneInputFields
                  key={e.id}
                  errors={errors}
                  e={e}
                  i={i}
                  control={control}
                  setValue={setValue}
                  // isDisable={isDisable}
                  findFullWidth={findFullWidth}
                  actionType={type}
                  remove={remove}
                  fields={fields}
                />
              );
            })}
          {/* landline start */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <LabelLarge>
              <FormattedMessage id="landline" style={{ color: "#333860" }}>
                landline
              </FormattedMessage>
            </LabelLarge>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "row-reverse",
              }}
            >
              <TextButton
                kind={KIND.tertiary}
                size={TextBoxSize.SIZE.mini}
                type="button"
                style={{
                  fontSize: "12px",
                  fontWeight: "400px",
                  gap: "2px",
                  display: "flex",
                  // textAlign: "center",
                  justifyContent: "center",
                  alignItem: "center",
                }}
                // {
                //         type: "phone",
                //         value: { country_code: defaultData.phone_code,value:"",type:"work"},
                //       }
                onClick={() => {
                  landlineArray.append({
                    country_code: setSelectValues(defaultData.phone_code),
                    type: [{ id: "OFFICE_LANDLINE", label: "Office" }],
                    status: [{ id: "VALID", label: "Valid" }],
                    value: "",
                  });
                }}
              >
                <PlusContact /> Add Another Landline
              </TextButton>
            </div>
          </div>

          {/* Phone Inputs */}
          {landlineArray?.fields?.map((e, i) => (
            <>
              {/* {data.landline.length > 1 ? (
                    <LabelXSmall $style={{ marginBottom: "12px" }}>
                      Landline {[i + 1]}
                    </LabelXSmall>
                  ) : null} */}

              <div style={{ display: "flex", gap: "10px" }} key={e.id}>
                {/* <div style={{ width: "180px" }}> */}
                <Controller
                  name={`landline[${i}].type`}
                  control={control}
                  defaultValue={[{ id: "OFFICE_LANDLINE", label: "Office" }]}
                  render={({ field }) => (
                    <div style={{ width: "130px" }}>
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["landline"] &&
                          errors["landline"][i] &&
                          errors["landline"][i].type &&
                          errors["landline"][i].type.message
                        }
                        label={intl.formatMessage({
                          id: `type_contact`,
                        })}
                        placeholder={intl.formatMessage({
                          id: "type_contact",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["contact_landline_type"]}
                      />
                    </div>
                  )}
                />
                {/* </div> */}

                <Controller
                  name={`landline[${i}].country_code`}
                  control={control}
                  defaultValue={
                    setSelectValues(defaultData.phone_code)
                    // : actionType === EDIT
                    // ? setSelectValues(defaultData.phone_code)
                    // setSelectValues(defaultData.phone_code)
                  }
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <div
                      style={{ width: "100px" }}
                      onClick={() => {
                        if (findFullWidth && findFullWidth.current) {
                          findFullWidth.current.scrollTop = 50;
                        }
                      }}
                    >
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["landline"] &&
                          errors["landline"][i] &&
                          errors["landline"][i].country_code &&
                          errors["landline"][i].country_code.message
                        }
                        label={intl.formatMessage({
                          id: "code",
                        })}
                        placeholder={intl.formatMessage({
                          id: "code",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["contact_phone_code"]}
                      />
                    </div>
                  )}
                />
                {/* </div> */}
                <Controller
                  name={`landline[${i}].value`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        type="number"
                        {...field}
                        fullWidth
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["landline"] &&
                          errors["landline"][i] &&
                          errors["landline"][i].value &&
                          errors["landline"][i].value.message
                        }
                        label={intl.formatMessage({
                          id: "number",
                        })}
                        placeholder={intl.formatMessage({
                          id: "enter_number",
                        })}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </>
                  )}
                />
                <div style={{ width: "180px" }}>
                  <Controller
                    name={`landline[${i}].status`}
                    control={control}
                    defaultValue={[{ id: "VALID", label: "Valid" }]}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["landline"] &&
                          errors["landline"][i] &&
                          errors["landline"][i].status &&
                          errors["landline"][i].status.message
                        }
                        label={intl.formatMessage({
                          id: `status`,
                        })}
                        placeholder={intl.formatMessage({
                          id: "status",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["contact_status"]}
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex", marginTop: "3px", gap: "5px" }}>
                  {data?.landline?.[i]?.call_status && (
                    <>
                      <ValidCallSvg
                        color={
                          data?.landline?.[i]?.call_status === "VALID"
                            ? "#12b112"
                            : "#FD372A"
                        }
                      />
                    </>
                  )}
                  <StatefulPopover
                    content={({ close }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        {textLandline[i] !== undefined ? (
                          <></>
                        ) : (
                          <>
                            {!e.comment && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                  gap: "4px",
                                  margin: "4px 0px",
                                }}
                              >
                                <div style={{ paddingLeft: "5px" }}>
                                  <Icon icon="contact_chat_icon" />
                                </div>

                                <Typography
                                  type="p"
                                  subType="medium"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    toggleCommentOpen(i);
                                    close();
                                  }}
                                >
                                  {" "}
                                  Add Comment
                                </Typography>
                              </div>
                            )}
                          </>
                        )}

                        {landlineArray?.fields?.length > 1 && (
                          <div
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              margin: "4px 0px",
                              ":hover": {
                                background: "#ffdfdc",
                              },
                            }}
                            onClick={() => landlineArray.remove(i)}
                          >
                            <div style={{ paddingLeft: "5px" }}>
                              <Icon
                                icon="delete_outline"
                                isPressable
                                color="#FD372A"
                              />
                            </div>

                            <Typography
                              type="p"
                              subType="medium"
                              style={{ color: "#FD372A" }}
                            >
                              Delete
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}
                    returnFocus
                    autoFocus
                    overrides={{
                      Arrow: {
                        style: ({ $theme }) => ({
                          outline: `#CDCED9 solid`,
                          backgroundColor: "#16164B",
                        }),
                      },
                      Inner: {
                        style: ({ $theme }) => ({
                          width: "120px",
                          // height: "70px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }),
                      },
                    }}
                    placement={PLACEMENT.leftBottom}
                    triggerType={TRIGGER_TYPE.hover}
                  >
                    <Icon icon="triple_dot" isPressable />
                  </StatefulPopover>
                </div>
              </div>
              <>
                {commentOpen[i] && (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Icon icon="comment_sep" />
                    <Controller
                      name={`landline[${i}].comment`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextArea
                            onFocus={false}
                            {...field}
                            name={field.name}
                            // disabled={}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "Comments",
                            })}
                            placeholder={intl.formatMessage({
                              id: "Landline Comments",
                            })}
                            size={SIZE.compact}
                            value={field.value}

                            // maxLength={200}
                          />
                        </>
                      )}
                    />
                  </div>
                )}
                {commentOpen[i] && (
                  <div
                    className="comment_cancel_button_container"
                    onClick={() => {
                      setTextCommentOpen(false);
                      setCommentOpen((prev) => ({
                        ...prev,
                        [i]: false,
                      }));
                      setValue(`landline[${i}].comment`, "");
                    }}
                  >
                    <Icon icon="crosss" isPressable />
                    <Typography
                      type="p"
                      className="text-secondary"
                      subType="medium"
                    >
                      {" "}
                      Cancel
                    </Typography>
                  </div>
                )}
              </>
            </>
          ))}

          {/* Email Header */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <LabelLarge>
              <FormattedMessage id="email" style={{ color: "#333860" }}>
                email
              </FormattedMessage>
            </LabelLarge>
            <TextButton
              kind={KIND.tertiary}
              size={TextBoxSize.SIZE.mini}
              type="button"
              style={{
                fontSize: "12px",
                fontWeight: "400px",
                gap: "2px",
                display: "flex",
                // textAlign: "center",
                justifyContent: "center",
                alignItem: "center",
              }}
              onClick={() =>
                emailArray.append({
                  type: [{ id: "PERSONAL_EMAIL", label: "Personal" }],
                  status: [{ id: "VALID", label: "Valid" }],
                  value: "",
                })
              }
            >
              <PlusContact /> Add Another Email
            </TextButton>
          </div>

          {/* Email Inputs */}

          {emailArray.fields.map((e, i) => (
            <>
              <ConatctEmailFields
                key={e.id}
                errors={errors}
                e={e}
                i={i}
                setValue={setValue}
                control={control}
                findFullWidth={findFullWidth}
                actionType={type}
                remove={emailArray.remove}
                fields={emailArray.fields}
              />
            </>
          ))}

          {/* Address Heading */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <LabelLarge>
              <FormattedMessage id="address" style={{ color: "#333860" }}>
                address
              </FormattedMessage>
            </LabelLarge>
            <TextButton
              kind={KIND.tertiary}
              size={TextBoxSize.SIZE.mini}
              type="button"
              style={{
                fontSize: "12px",
                fontWeight: "400px",
                gap: "2px",
                display: "flex",
                // textAlign: "center",
                justifyContent: "center",
                alignItem: "center",
              }}
              onClick={() =>
                addressArray.append({
                  type: [{ id: "OFFICE_ADDRESS", label: "Office" }],
                  status: [{ id: "VALID", label: "Valid" }],
                  line_1: "",
                  line_2: "",
                  country: setSelectValues(defaultData.country),
                  state: [],
                  city: "",
                  zip_code: "",
                })
              }
            >
              <PlusContact /> Add Another Address
            </TextButton>
          </div>
          {/* Address Inputs */}
          {addressArray.fields.map((e, i) => (
            <div key={e.id}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", gap: "10px", width: "30%" }}>
                  <Controller
                    name={`address[${i}].type`}
                    defaultValue={[{ id: "OFFICE_ADDRESS", label: "Office" }]}
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        clearable={false}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].type &&
                          errors["address"][i].type.message
                        }
                        label={intl.formatMessage({
                          id: "type_contact",
                        })}
                        placeholder={intl.formatMessage({
                          id: "type_contact",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["contact_address_type"]}
                      />
                    )}
                  />
                </div>

                <StatefulPopover
                  content={({ close }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      {!e.comment && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            gap: "4px",
                            margin: "4px 0px",
                            ":hover": {
                              background: "#ffdfdc",
                            },
                          }}
                        >
                          <div style={{ paddingLeft: "5px" }}>
                            <Icon icon="contact_chat_icon" />
                          </div>

                          <Typography
                            type="p"
                            subType="medium"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggleAddressCommentOpen(i);
                              close();
                            }}
                          >
                            {" "}
                            Add Comment
                          </Typography>
                        </div>
                      )}

                      {addressArray.fields.length > 1 && (
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            margin: "4px 0px",
                          }}
                          onClick={() => addressArray.remove(i)}
                        >
                          <div style={{ paddingLeft: "5px" }}>
                            <Icon
                              icon="delete_outline"
                              isPressable
                              color="#FD372A"
                            />
                          </div>

                          <Typography
                            type="p"
                            subType="medium"
                            style={{ color: "#FD372A" }}
                          >
                            Delete
                          </Typography>
                        </div>
                      )}
                    </div>
                  )}
                  returnFocus
                  autoFocus
                  overrides={{
                    Arrow: {
                      style: ({ $theme }) => ({
                        outline: `#CDCED9 solid`,
                        backgroundColor: "#16164B",
                      }),
                    },
                    Inner: {
                      style: ({ $theme }) => ({
                        width: "120px",
                        // height: "70px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "5px",
                      }),
                    },
                  }}
                  placement={PLACEMENT.leftBottom}
                  triggerType={TRIGGER_TYPE.hover}
                >
                  <Icon icon="triple_dot" isPressable />
                </StatefulPopover>
              </div>
              <div key={e.id}>
                <Controller
                  name={`address[${i}].line_1`}
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      name={field.name}
                      size={TextBoxSize.SIZE.mini}
                      error={
                        errors &&
                        errors["address"] &&
                        errors["address"][i] &&
                        errors["address"][i].line_1 &&
                        errors["address"][i].line_1.message
                      }
                      label={intl.formatMessage({
                        id: "address_line_1",
                      })}
                      placeholder={intl.formatMessage({
                        id: "address_line_1",
                      })}
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name={`address[${i}].line_2`}
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      name={field.name}
                      size={TextBoxSize.SIZE.mini}
                      error={
                        errors &&
                        errors["address"] &&
                        errors["address"][i] &&
                        errors["address"][i].line_2 &&
                        errors["address"][i].line_2.message
                      }
                      label={intl.formatMessage({
                        id: "address_line_2",
                      })}
                      placeholder={intl.formatMessage({
                        id: "address_line_2",
                      })}
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Controller
                  name={`address[${i}].city`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].city &&
                          errors["address"][i].city.message
                        }
                        label={intl.formatMessage({
                          id: "city",
                        })}
                        placeholder={intl.formatMessage({
                          id: "city",
                        })}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`address[${i}].state`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        {...field}
                        name={field.name}
                        clearable={false}
                        creatable={creatablePermission ? true : false}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].state &&
                          errors["address"][i].state.message
                        }
                        label={intl.formatMessage({
                          id: "state",
                        })}
                        placeholder={intl.formatMessage({
                          id: "state",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["state_list"]}
                      />
                    </>
                  )}
                />
                <Controller
                  name={`address[${i}].zip_code`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].zip_code &&
                          errors["address"][i].zip_code.message
                        }
                        label={intl.formatMessage({
                          id: "zipCode",
                        })}
                        placeholder={intl.formatMessage({
                          id: "zipCode",
                        })}
                        value={field.value}
                        onKeyDown={(e) => {
                          if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                            e.preventDefault();
                          }
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "32%" }}>
                  <Controller
                    name={`address[${i}].country`}
                    defaultValue={setSelectValues(defaultData.country)}
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        clearable={false}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].country &&
                          errors["address"][i].country.message
                        }
                        label={intl.formatMessage({
                          id: "country",
                        })}
                        placeholder={intl.formatMessage({
                          id: "country",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["country"]}
                      />
                    )}
                  />
                </div>
                <div style={{ width: "32.5%" }}>
                  <Controller
                    name={`address[${i}].status`}
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        clearable={false}
                        size={TextBoxSize.SIZE.mini}
                        error={
                          errors &&
                          errors["address"] &&
                          errors["address"][i] &&
                          errors["address"][i].status &&
                          errors["address"][i].status.message
                        }
                        label={intl.formatMessage({
                          id: "status",
                        })}
                        placeholder={intl.formatMessage({
                          id: "status",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["contact_status"]}
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "13px",
                    gap: "5px",
                  }}
                >
                  {data &&
                  data?.address?.[i] &&
                  data?.address?.[i]?.address_status ? (
                    <ContactLocation
                      color={
                        data?.address?.[i]?.status === "VALID" ||
                        data?.address?.[i]?.status === "LEAD"
                          ? "#0fB158"
                          : data?.address?.[i]?.status === "DNC"
                          ? "#ba332b"
                          : data?.address?.[i]?.status === "INVALID"
                          ? "#E5AC16"
                          : "#FD372A"
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <>
                {addressComment[i] && (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "flex-start",
                    }}
                  >
                    <Controller
                      name={`address[${i}].comment`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextArea
                            onFocus={false}
                            {...field}
                            name={field.name}
                            // disabled={}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "Comments",
                            })}
                            placeholder={intl.formatMessage({
                              id: "Address Comments",
                            })}
                            size={SIZE.compact}
                            value={field.value}

                            // maxLength={200}
                          />
                        </>
                      )}
                    />
                  </div>
                )}

                {addressComment[i] && (
                  <div
                    className="comment_cancel_button_container"
                    onClick={() => {
                      setTextAddressCommentOpen(false);
                      setAddressCommentOpen((prev) => ({
                        ...prev,
                        [i]: false,
                      }));
                      setValue(`address[${i}].comment`, "");
                    }}
                  >
                    <Icon icon="crosss" isPressable />
                    <Typography
                      type="p"
                      className="text-secondary"
                      subType="medium"
                    >
                      {" "}
                      Cancel
                    </Typography>
                  </div>
                )}
              </>
            </div>
          ))}
          {customContact.length > 0 && (
            <Controller
              name="is_contact_custom"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <CheckBoxBaseweb {...field} checked={field.value}>
                  <Typography type="p" style={{ marginTop: "4px" }}>
                    Capture additional contact details
                  </Typography>
                </CheckBoxBaseweb>
              )}
            />
          )}

          {is_contact_custom ? (
            <>
              {" "}
              <LabelLarge $style={{ marginBottom: "10px" }}>
                <FormattedMessage
                  id="custom_fields"
                  style={{ color: "#333860" }}
                >
                  custom_fields
                </FormattedMessage>
              </LabelLarge>
              <CustomFieldsTab
                customFields={data}
                fieldLevel={customContact}
                width={"530px"}
                wrapWidth={"200px"}
                control={control}
                setValue={setValue}
                reSet={reset}
              />{" "}
            </>
          ) : (
            <></>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              marginTop: "5px",
            }}
          >
            {/* <ModalButton kind="tertiary" onClick={close}>
            Discard
          </ModalButton> */}
            <div style={{ width: "125px" }}>
              <TextButton
                type="button"
                kind={KIND.tertiary}
                size={SIZE.compact}
                fullWidth
                onClick={() => {
                  reset();
                  setIsOpen(false);
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ width: "125px" }}>
              <TextButton
                type="submit"
                kind={KIND.primary}
                size={SIZE.compact}
                fullWidth
                // disabled={isDisable || isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </TextButton>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default AddCustomer;
