export default function WhatsAppStatus({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0528 3.88922C12.8366 2.67158 11.2191 2.00071 9.49595 2C5.94523 2 3.05546 4.88969 3.05403 8.44136C3.05356 9.57672 3.35015 10.685 3.9139 11.6619L3 15L6.41496 14.1042C7.35592 14.6175 8.41526 14.888 9.49333 14.8883H9.49603C13.0464 14.8883 15.9364 11.9984 15.9378 8.44652C15.9385 6.72519 15.2691 5.10678 14.0528 3.88922ZM9.49595 13.8004H9.49373C8.53301 13.8 7.59078 13.5418 6.7686 13.054L6.57317 12.938L4.54669 13.4696L5.08759 11.4938L4.96024 11.2912C4.42426 10.4387 4.14123 9.45342 4.14171 8.44176C4.14282 5.4897 6.54477 3.08799 9.4981 3.08799C10.9282 3.08846 12.2726 3.64611 13.2834 4.65816C14.2943 5.67021 14.8507 7.01544 14.8502 8.44612C14.8489 11.3984 12.4471 13.8004 9.49595 13.8004ZM12.4329 9.79032C12.2719 9.7097 11.4805 9.32043 11.333 9.26664C11.1855 9.21292 11.0781 9.18618 10.9709 9.34725C10.8636 9.50833 10.5551 9.87093 10.4612 9.97829C10.3672 10.0857 10.2735 10.0992 10.1125 10.0186C9.95148 9.93806 9.43287 9.76802 8.81802 9.21967C8.33957 8.79286 8.01655 8.26577 7.92261 8.1047C7.82882 7.94347 7.92181 7.86476 7.99323 7.77621C8.16747 7.55983 8.34195 7.33298 8.39559 7.22563C8.4493 7.11819 8.42241 7.02417 8.3821 6.94363C8.34195 6.8631 8.02004 6.07083 7.88595 5.74845C7.75519 5.43472 7.6226 5.47709 7.52374 5.47217C7.42995 5.46749 7.3226 5.46653 7.21524 5.46653C7.10796 5.46653 6.93356 5.50676 6.78598 5.66799C6.63848 5.82914 6.2227 6.21849 6.2227 7.01076C6.2227 7.80303 6.79947 8.5684 6.87993 8.67583C6.96038 8.78326 8.01497 10.4091 9.62957 11.1062C10.0136 11.2722 10.3134 11.3711 10.5472 11.4453C10.9328 11.5678 11.2836 11.5505 11.561 11.5091C11.8703 11.4628 12.5132 11.1197 12.6475 10.7437C12.7816 10.3677 12.7816 10.0455 12.7413 9.97829C12.7011 9.91116 12.5938 9.87093 12.4329 9.79032Z"
        fill={color}
      />
    </svg>
  );
}
