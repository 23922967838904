import React from "react";

const FieldVisitSvg = ({ size = 22, color = "#516BEB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6154 17.175C10.5037 17.175 10.3981 17.1625 10.2988 17.1375C10.1995 17.1125 10.109 17.0667 10.0274 17C7.98556 15.3833 6.47056 13.8292 5.48234 12.3375C4.49411 10.8458 4 9.36667 4 7.9C4 6.7632 4.20009 5.7665 4.60028 4.9099C5.00047 4.0533 5.51909 3.33333 6.15613 2.75C6.79316 2.16667 7.50354 1.72917 8.28726 1.4375C9.07098 1.14583 9.84686 1 10.6149 1C11.3829 1 12.159 1.14583 12.943 1.4375C13.7271 1.72917 14.4376 2.16667 15.0746 2.75C15.7117 3.33333 16.2303 4.0533 16.6305 4.9099C17.0307 5.7665 17.2308 6.7632 17.2308 7.9C17.2308 9.36667 16.7367 10.8458 15.7484 12.3375C14.7602 13.8292 13.2452 15.3833 11.2034 17C11.1217 17.0667 11.0302 17.1125 10.9287 17.1375C10.8273 17.1625 10.7228 17.175 10.6154 17.175ZM10.6176 9.5C11.0898 9.5 11.4934 9.32843 11.8282 8.98528C12.1631 8.64214 12.3305 8.22964 12.3305 7.74778C12.3305 7.26593 12.1623 6.85417 11.826 6.5125C11.4897 6.17083 11.0855 6 10.6132 6C10.141 6 9.73742 6.17157 9.40256 6.51472C9.06771 6.85786 8.90028 7.27036 8.90028 7.75222C8.90028 8.23407 9.06844 8.64583 9.40474 8.9875C9.74103 9.32917 10.1453 9.5 10.6176 9.5ZM4.73504 21C4.52678 21 4.35221 20.9277 4.21132 20.7831C4.07044 20.6385 4 20.4594 4 20.2456C4 20.0319 4.07044 19.8542 4.21132 19.7125C4.35221 19.5708 4.52678 19.5 4.73504 19.5H16.4957C16.704 19.5 16.8786 19.5723 17.0194 19.7169C17.1603 19.8615 17.2308 20.0406 17.2308 20.2544C17.2308 20.4681 17.1603 20.6458 17.0194 20.7875C16.8786 20.9292 16.704 21 16.4957 21H4.73504Z"
        fill={color}
      />
    </svg>
  );
};

export default FieldVisitSvg;
