import React from "react";
import { TextButton, SIZE } from "../../../../components/TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { LabelLarge, ParagraphLarge } from "baseui/typography";
import getSelectValues from "../../../../utils/getSelectValues";
import moment from "moment";
import { SelectBox } from "../../../../components/SelectBox";
import { TextArea } from "../../../../components/TextArea";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
const SuspendWorkFlow = ({ setState, toggleAllRowsSelected }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = (data) => {
    let values = {
      ...data,
      till_date: moment(data.till_date).utc().valueOf(),
      reason: getSelectValues(data.reason),
      reason_onHold: data.reason_onHold,
    };
    toggleAllRowsSelected(false);
  };

  const intl = useIntl();
  return (
    <>
      <form
        style={{
          boxShadow: "0px 2px 4px 2px #d6d6d6",
          background: "#FFFFFF",
          border: "0.5px solid #CDCED925",

          borderRadius: "5px",
          width: "205px",
          height: "400px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <LabelLarge
          style={{
            paddingTop: "16px",
            textAlign: "center",
            color: "#516BEB",
          }}
        >
          SuspendWorkFlow
        </LabelLarge>

        <div
          style={{
            paddingTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            <Controller
              name="till_date"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <DateRangeSelect
                  size={SIZE.default}
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "till_date",
                  })}
                  placeholder={intl.formatMessage({
                    id: " ",
                  })}
                  value={field.value}
                  onChange={(e) => field.onChange(e.date)}
                  // selected={field.value}
                />
              )}
            />
          </div>
          <div style={{ paddingTop: "10px" }}>
            <Controller
              defaultValues={[]}
              name="reason"
              control={control}
              render={({ field }) => (
                <SelectBox
                  size={SIZE.default}
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "reason",
                  })}
                  placeholder={intl.formatMessage({
                    id: "reason",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={[
                    { label: "HelpFull", id: "org_1" },
                    { label: "Others", id: "org_2" },
                  ]}
                />
              )}
            />
          </div>
          <div style={{ paddingTop: "10px" }}>
            <Controller
              name="reason_onHold"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "reason_onHold",
                  })}
                  placeholder={intl.formatMessage({
                    id: "reason_onHold",
                  })}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            // gap: "5px",
            justifyContent: "space-between",
            // alignItems: "center",
            paddingTop: "20px",
            paddingLeft: "10px",
            paddingRight: "10px",
            // justifyContent: "center",
          }}
        >
          <div style={{ width: "80px" }}>
            <TextButton
              size={SIZE.compact}
              onClick={() => {
                setState(false);
              }}
              fullWidth
            >
              Back
            </TextButton>
          </div>
          <div style={{ width: "80px" }}>
            <TextButton size={SIZE.compact} type="submit" fullWidth>
              Submit
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default SuspendWorkFlow;
