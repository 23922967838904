import React, { useContext, useMemo } from "react";
import { Icon, Typography } from "../../../../components_v2";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import _ from "lodash";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { TextButton, KIND } from "../../../../components/TextButton";
const AddedCounselCard = ({ counselId, removeCounsel, editCounsel }) => {
  let { currentOrganization, currentDefaultFormatDetails, referenceData } =
    useContext(MaxyfiContext);

  const cardData = useMemo(() => {
    const councilCardData = _.get(
      currentDefaultFormatDetails,
      "atrl",
      []
    ).filter((item) => item._id === counselId);
    return councilCardData[0];
  }, [
    currentDefaultFormatDetails,
    currentDefaultFormatDetails?.atrl.length,
    counselId,
  ]);

  const country =
    referenceData && referenceData["country"] && _.get(cardData, "addr.cou", "")
      ? referenceData["country"].find(
          (e) => e.id === _.get(cardData, "addr.cou", "")
        )
      : null;
  return (
    <div className="added-counsel-card-container">
      <div className="added-counsel-card_header">
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Typography type="h4"> {_.get(cardData, "atrn", "")} </Typography>
          <Icon icon="dot" color="#D3D3D7" size={5}></Icon>
          <Typography type="p" subType="regular" className="text-secondary">
            {_.get(cardData, "firn", "")}
          </Typography>
        </div>
        <div
          style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
        >
          <Icon
            icon="edit_outline"
            color="#ADADAD"
            size={16}
            isPressable
            onClick={() => {
              editCounsel(counselId);
            }}
          />
          <StatefulPopover
            triggerType={TRIGGER_TYPE.click}
            content={({ close }) => (
              <div className="co_groping_delete_popover">
                <div className="co_groping_delete_popover_body">
                  <Icon icon="alert" color="#FD372A" />
                  <Typography type="p">
                    This will remove the Counsel from this Legal Proceedings, Do
                    you want to continue?
                  </Typography>
                </div>
                <div className="co_groping_delete_popover_footer">
                  <TextButton
                    size="mini"
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => close()}
                  >
                    <Typography type="h5" style={{ color: "#FD372A" }}>
                      Cancel
                    </Typography>
                  </TextButton>
                  <TextButton
                    size="mini"
                    kind={KIND.secondary}
                    onClick={() => {
                      removeCounsel();
                    }}
                    type="error"
                  >
                    Confirm
                  </TextButton>
                </div>
              </div>
            )}
          >
            <div style={{ cursor: "pointer" }}>
              {" "}
              <Icon
                icon="delete_outline"
                color="#ADADAD"
                size={16}
                isPressable
              />
            </div>
          </StatefulPopover>
        </div>
      </div>
      <Typography type="p" subType="regular" className="regular">
        {_.get(cardData, "ph.coc", "")} {_.get(cardData, "ph.val", "")}
      </Typography>
      <Typography type="p" subType="regular" className="regular">
        {_.get(cardData, "em.val", "")}{" "}
      </Typography>
      <Typography type="p" subType="regular" className="regular">
        {" "}
        {_.get(cardData, "addr.li1", "")} {_.get(cardData, "addr.li2", "")}{" "}
        {_.get(cardData, "addr.cit", "")} {_.get(cardData, "addr.sta", "")}{" "}
        {_.get(country, "id", "")} {_.get(cardData, "addr.zip", "")}
      </Typography>
    </div>
  );
};

export default AddedCounselCard;
