export const ADD_PAYMENT_MODAL = "ADD_PAYMENT_MODAL";
export const CLOSE_PAYMENT_MODAL = "CLOSE_PAYMENT_MODAL";
export const EDIT_PAYMENT_MODAL = "EDIT_PAYMENT_MODAL";
export const VIEW_PAYMENT_MODAL = "VIEW_PAYMENT_MODAL";
// Adhoc Action Payment
export const ALLOCATED_AMOUNT = "ALLOCATED_AMOUNT";
export const PAYMENT_AMOUNT = "PAYMENT_AMOUNT";
export const INITIAL_EDIT_PAYMENT_DATA = "INITIAL_EDIT_PAYMENT_DATA";
export const EMPTY_EDIT_DATA = "EMPTY_EDIT_DATA";
export const VIEW_DATA = "VIEW_DATA";
export const PAYMENT_ACTION = "PAYMENT_ACTION";
export const RESET_PAYMENT_STATE = "RESET_PAYMENT_STATE";
export const CHANGING_ALLOCATED_AMOUNT = "CHANGING_ALLOCATED_AMOUNT";
export const GETTING_NEGATIVE_DUE_AMOUNT = "GETTING_NEGATIVE_DUE_AMOUNT";
export const REFETCH_PAYMENT_ACTIONS = "REFETCH_PAYMENT_ACTIONS";

// NEW CALC
export const refetchPaymentActions = () => {
  return {
    type: REFETCH_PAYMENT_ACTIONS,
  };
};
export const gettingDueAmount = (ngtData) => {
  return {
    type: GETTING_NEGATIVE_DUE_AMOUNT,
    payload: ngtData,
  };
};

export const resetPaymentState = () => {
  return {
    type: RESET_PAYMENT_STATE,
  };
};

export const changingAllocatedAmount = (amt) => {
  return {
    type: CHANGING_ALLOCATED_AMOUNT,
    payload: amt,
  };
};

export const paymentAction = (actionType) => {
  return {
    type: PAYMENT_ACTION,
    payload: actionType,
  };
};

export const viewPaymentDetails = (viewData) => {
  return {
    type: VIEW_DATA,
    payload: viewData,
  };
};

export const initialEditPaymentData = (paymentData) => {
  return {
    type: INITIAL_EDIT_PAYMENT_DATA,
    payload: paymentData,
  };
};

export const emptyEditData = () => {
  return {
    type: EMPTY_EDIT_DATA,
  };
};

export const paymentAmount = (paymentAmount) => {
  return {
    type: PAYMENT_AMOUNT,
    payload: paymentAmount,
  };
};

export const allocatedAmount = (amount) => {
  return {
    type: ALLOCATED_AMOUNT,
    payload: amount,
  };
};

export const addPaymentModal = () => {
  return {
    type: ADD_PAYMENT_MODAL,
  };
};

export const closePaymentModal = () => {
  return {
    type: CLOSE_PAYMENT_MODAL,
  };
};

export const editPaymentModal = (paymentData) => {
  return {
    type: EDIT_PAYMENT_MODAL,
    payload: paymentData,
  };
};

export const viewPaymentModal = (paymentData) => {
  return {
    type: VIEW_PAYMENT_MODAL,
    payload: paymentData,
  };
};
