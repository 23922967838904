import { Drawer } from "baseui/drawer";
import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkInDrawerOpen } from "../../../redux/customerOverview/checkIn/action";
import CheckInCard from "./CheckInCard";
import { TextButton, SIZE, KIND } from "../../../components/TextButton";
import { LabelLarge } from "baseui/typography";
import Plus from "../../../assets/img/svg/Upload/Plus";
import Cancel from "../../../assets/img/svg/Template/Cancel";
import CheckInForm from "./CheckInForm";
import { getCheckIn, getRecurringInvoiceItem } from "../../../services";
import { useSearchParams } from "react-router-dom";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { NEW } from "../../../constants";

const CheckInDrawer = () => {
  let dispatch = useDispatch();
  const { checkInIsOpen } = useSelector((s) => s.checkInReducer);
  const [isAddNew, setIsAddNew] = useState(false);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [refetchCheckIn, setRefetchCheckIn] = useState(0);

  const [editingData, setEditingData] = useState({ type: "", data: [] });
  const getParams = getSearchParams.get("customers");
  const { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);

  const { data, isFetched, isError, refetch } = useQuery(
    [`CHECK_IN--${currentOrganization}-${getParams}-${refetchCheckIn}`],
    async ({ queryKey }) => {
      return await getCheckIn({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const getItems = useQuery(
    [`CHECK_IN_ITEM_LIST-${currentOrganization}-${getParams}`],
    async ({ queryKey }) => {
      return await getRecurringInvoiceItem({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Drawer
      isOpen={checkInIsOpen}
      autoFocus
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
        DrawerBody: {
          style: ({ $theme }) => ({
            marginLeft: "15px",
            marginRight: "15px",
            marginBottom: "15px",
            marginTop: "0px",
            padding: "15px",
            overflowY: "hidden",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
      }}
      onClose={() => {
        dispatch(checkInDrawerOpen(false));
        setEditingData({});
        setIsAddNew(false);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >
        {/* ONE STARTS*/}
        <div
          style={{
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: "6px" }}>
            <LabelLarge>CheckIn</LabelLarge>
            <div></div>
          </div>

          <div style={{ display: "flex" }}>
            <div className="drawer_add_btn">
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => {
                  setEditingData({});
                  setIsAddNew(true);
                }}
              >
                <Plus />
                Add CheckIn
              </TextButton>
            </div>

            <div className="drawer_close_btn">
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => {
                  setIsAddNew(false);
                  setEditingData({});
                  dispatch(checkInDrawerOpen(false));
                }}
              >
                <Cancel />
                Close
              </TextButton>
            </div>
          </div>
        </div>
        {/* ONE ENDS */}

        {/* FORM STARTS */}
        {isAddNew ||
        (data &&
          data.data &&
          data.data.docs &&
          data.data.docs.docs.length === 0) ? (
          <CheckInForm
            setIsAddNew={setIsAddNew}
            editingData={editingData}
            setRefetchCheckIn={setRefetchCheckIn}
            refetchCheckIn={refetchCheckIn}
            getParams={getParams}
            getItems={getItems}
            setEditingData={setEditingData}
          />
        ) : (
          <></>
        )}
        {/* FORM ENDS */}

        {/* CARD STARTS */}
        <div
          style={{
            // height: "calc(100% - 85px)",
            // paddingBottom: "5%",
            height: "100%",
            overflow: "scroll",
          }}
        >
          {data &&
            data.data &&
            data.data.docs &&
            data.data.docs.docs.map((i) => {
              return (
                <CheckInCard
                  i={i}
                  setIsAddNew={setIsAddNew}
                  setEditingData={setEditingData}
                  setRefetchCheckIn={setRefetchCheckIn}
                  refetchCheckIn={refetchCheckIn}
                  getItems={getItems}
                />
              );
            })}
        </div>

        {/* CARD ENDS */}
        <div style={{ height: "40px" }}></div>
      </div>
    </Drawer>
  );
};

export default CheckInDrawer;
