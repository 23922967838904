function QuickActionDanger() {
  return (
    <>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.10911 17.2499C0.849392 17.2499 0.650781 17.1353 0.513281 16.9062C0.375781 16.677 0.375781 16.4478 0.513281 16.2187L9.40495 0.864502C9.54245 0.635335 9.74106 0.520752 10.0008 0.520752C10.2605 0.520752 10.4591 0.635335 10.5966 0.864502L19.4883 16.2187C19.6258 16.4478 19.6258 16.677 19.4883 16.9062C19.3508 17.1353 19.1522 17.2499 18.8924 17.2499H1.10911ZM10.0924 6.89158C9.89384 6.89158 9.7296 6.95652 9.59974 7.08638C9.46988 7.21624 9.40495 7.38047 9.40495 7.57908V11.3374C9.40495 11.536 9.46988 11.7003 9.59974 11.8301C9.7296 11.96 9.89384 12.0249 10.0924 12.0249C10.2911 12.0249 10.4553 11.96 10.5852 11.8301C10.715 11.7003 10.7799 11.536 10.7799 11.3374V7.57908C10.7799 7.38047 10.715 7.21624 10.5852 7.08638C10.4553 6.95652 10.2911 6.89158 10.0924 6.89158ZM10.0924 14.5687C10.2911 14.5687 10.4553 14.5037 10.5852 14.3739C10.715 14.244 10.7799 14.0798 10.7799 13.8812C10.7799 13.6826 10.715 13.5183 10.5852 13.3885C10.4553 13.2586 10.2911 13.1937 10.0924 13.1937C9.89384 13.1937 9.7296 13.2586 9.59974 13.3885C9.46988 13.5183 9.40495 13.6826 9.40495 13.8812C9.40495 14.0798 9.46988 14.244 9.59974 14.3739C9.7296 14.5037 9.89384 14.5687 10.0924 14.5687Z"
          fill="#FD372A"
        />
      </svg>
    </>
  );
}

export default QuickActionDanger;
