import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getGridStructure } from "../../services";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateTableDraftState } from "../../redux/customerSummary/action";
import { KPI_MAINTENANCE } from "../../constants";
import columnMapper from "../../utils/mapper";
import { Input } from "baseui/input";
import { Search } from "baseui/icon";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { SIZE } from "../../components/IconButton";
import Loader from "../../components/Loader";
import {
  getKpiMaintenanceList,
  getKpiMaintenanceAudit,
} from "../../services/revenueBilling";
import TaxRateAudit from "../Setting/TypeContainer/TaxRateMaintenance/TaxRateAudit";
import PaginationWraper from "../../components/Pagination/Pagination";
import { Select } from "baseui/select";
import { getRoleMaintenance } from "../../services/users";
import { ModalContext } from "../../providers/ModalProvider";
import AddKpiForm from "../../components/FormModal/AddKpiForm";
import RemoveKpiForm from "../../components/FormModal/RemoveKpiForm";
import addKpiImg from "../../../src/assets/img/addKpi.png";
import removeKpiImg from "../../../src/assets/img/removeKpiImg.png";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import TripleDot from "../../assets_v2/icons/TripleDot";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { AUDIT_KPI_MAINTENANCE } from "../../providers/RBACProvider/permissionList";
import { Icon, Typography } from "../../components_v2";

const TableBody = ({
  page,
  prepareRow,
  getTableBodyProps,
  setIsOpen,
  setIsAudit,
}) => {
  return (
    <>
      <div className="customer__tabel__body" {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            <React.Fragment key={rowProps.key}>
              <div
                key={index}
                className={`table-main__body-row toggle-btn__relate ${
                  row.isExpanded ? "expanded" : ""
                }`}
                {...row.getRowProps()}
                // style={{ gap: "15px" }}
              >
                {row.cells.map((cell) => {
                  return (
                    <div
                      className="table-main__body-cell"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}

                <RBACWrapper type="PERMISSION" role={AUDIT_KPI_MAINTENANCE}>
                  <StatefulPopover
                    content={
                      <div className="user-popover__btn">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setIsOpen(true);
                            setIsAudit(row && row.original._id);
                          }}
                        >
                          <Icon icon="edit" size={18} />
                          <Typography
                            style={{
                              padding: "5px 5px",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            Audit
                          </Typography>
                        </div>
                      </div>
                    }
                    accessibilityType={"tooltip"}
                    triggerType={TRIGGER_TYPE.hover}
                    placement={PLACEMENT.bottomRight}
                    overrides={{
                      Inner: {
                        style: () => ({
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                          background: "#ffffff",
                          border: "0.5px solid #CDCED9",
                        }),
                      },
                      Body: {
                        style: () => ({
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                          top: "-10px",
                        }),
                      },
                    }}
                  >
                    <div
                      className="user-triple_btn"
                      style={{ padding: "10px 10px" }}
                    >
                      <TripleDot />
                    </div>
                  </StatefulPopover>
                </RBACWrapper>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const KpiMaintenanceTable = ({ refetchingList }) => {
  const [filterSearch, setFilterSearch] = useState("");
  const [roleFilterValue, setRoleFilterValue] = useState({});
  const [isOpen, setIsOpen] = React.useState(false);
  const [isAudit, setIsAudit] = useState({});
  const { currentOrganization } = useContext(MaxyfiContext);
  const [columnData, setColumnData] = useState([]);
  const expandedRowId = useRef(null);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});
  const [width, setWidth] = useState(0);

  let ref = useRef();

  const dispatch = useDispatch();
  // TODO: VIGNESH
  let reduxData = useSelector((state) => state.customerSummary.tableState);
  let refetchUser = useSelector((state) => state.userReducer.isFetched);

  const { handleModal } = useContext(ModalContext);

  let globalFilter = {
    display_name: filterSearch,
    role: roleFilterValue && roleFilterValue[0] && roleFilterValue[0]._id,
  };

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `${KPI_MAINTENANCE}_${currentOrganization}`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: querySortBy,
        filters: {
          ...globalFilter,
        },
        refetchUser,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      return await getKpiMaintenanceList({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "kip_maintenance_grid",
      });
      setColumnData(userListVal?.data?.doc?.table);
    };
    userListApi();
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
    }
  }, [ref]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: true,
      isExpandedHeader: false,
      width: width - 150,
    });
    return columns;
  }, [columnData]);

  const tabledata = useMemo(
    () => (isFetched && !isError && data?.data?.docs ? data.data.docs : []),
    [data, isFetched]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    setHiddenColumns,
    selectedFlatRows,
    setColumnOrder,
    allColumns,
    gotoPage,
    setSortBy,
    toggleRowExpanded,
    isAllRowsSelected,
    toggleAllRowsSelected,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        isFetched && !isError && data?.data ? data?.data?.totalPages : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const currentColOrder = React.useRef();
  const isColumnResizing = !!columnResizing.isResizingColumn;

  useEffect(() => {
    if (reduxData.sort) {
      let isAsc = reduxData.sort.startsWith("-");
      setSortBy([{ id: reduxData.sort.replace("-", ""), desc: !isAsc }]);
    }
  }, [reduxData.sort]);

  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);

  useEffect(() => {
    if (!isColumnResizing) {
      dispatch(updateTableDraftState(allColumns));
    }
  }, [isColumnResizing, sortBy, filters]);

  useEffect(() => {
    setHiddenColumns(reduxData.hiddenColumns);
  }, [reduxData.hiddenColumns]);

  useEffect(() => {
    if (pageIndex == queryPageIndex) {
      gotoPage(0);
    }
    setQueryPageIndex(pageIndex);
    setQueryPageSize(pageSize);
    setQuerySortBy(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "-_id"
    );
    let filterObj = {};
    filters.map((e) => {
      if (e.value) {
        if (Array.isArray(e.value)) {
          filterObj[e.id] = e.value.filter((val) => val);
        } else {
          filterObj[e.id] = e.value;
        }
      }
    });
    setQueryFilter(filterObj);
  }, [pageIndex, pageSize, sortBy, filters]);

  const roleMainData = useQuery(
    [`ROLEMAINDATA-${currentOrganization}`],
    async ({ queryKey }) => {
      return await getRoleMaintenance({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  let selectedCustomerData = [
    ...(selectedFlatRows ? selectedFlatRows : []),
  ].map((i) => i.original._id);

  return (
    <>
      <div
        className="table-main user-table__main"
        {...getTableProps()}
        style={{ overflow: "hidden" }}
      >
        <div className="table-main-container" ref={ref}>
          <div className="table-main__head">
            {headerGroups.map((headerGroup) => (
              <DragDropContext
                onDragStart={() => {
                  currentColOrder.current = allColumns.map((i) => i.id);
                }}
                onDragUpdate={(dragUpdateObj, b) => {
                  const colOrder = [...currentColOrder.current];
                  const sIndex = dragUpdateObj.source.index;
                  const dIndex =
                    dragUpdateObj.destination &&
                    dragUpdateObj.destination.index;

                  if (
                    typeof sIndex === "number" &&
                    typeof dIndex === "number"
                  ) {
                    colOrder.splice(sIndex, 1);
                    colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                    setColumnOrder(colOrder);
                  }
                }}
              >
                <Droppable droppableId="droppable" direction="horizontal">
                  {(droppableProvided, snapshot) => (
                    <>
                      <div
                        className="table-main__head-container"
                        {...headerGroup.getHeaderGroupProps()}
                        ref={droppableProvided.innerRef}
                      >
                        {selectedCustomerData.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                padding: "5px 10px",
                              }}
                            >
                              <div
                                style={{
                                  width: "110px",
                                  height: "36px",
                                  background: "#DDE3FF",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleModal({
                                    title: "",
                                    content: "",
                                    isChildren: true,
                                    successCallback: () => {},
                                    formContent: ({ handleModal }) => {
                                      return (
                                        <>
                                          <AddKpiForm
                                            selectedCustomer={
                                              selectedCustomerData
                                            }
                                            handleModal={handleModal}
                                            toggleAllRowsSelected={
                                              toggleAllRowsSelected
                                            }
                                            customerRefetch={refetch}
                                            isAllRowsSelected={
                                              isAllRowsSelected
                                            }
                                          />
                                        </>
                                      );
                                    },
                                    cancelCallback: () => {},
                                    image: addKpiImg,
                                    buttonText: "",
                                    closeButtonText: "",
                                    isCloseAble: false,
                                  });
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "6px",
                                    alignItems: "center",
                                  }}
                                >
                                  <i className="mx-icon-add" />
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "#333860",
                                      fontWeight: "450",
                                    }}
                                  >
                                    Add KPI
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  width: "110px",
                                  height: "36px",
                                  background: "#DDE3FF",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleModal({
                                    title: "",
                                    content: "",
                                    isChildren: true,

                                    successCallback: () => {},
                                    formContent: ({ handleModal }) => {
                                      return (
                                        <>
                                          <RemoveKpiForm
                                            selectedCustomer={
                                              selectedCustomerData
                                            }
                                            handleModal={handleModal}
                                            toggleAllRowsSelected={
                                              toggleAllRowsSelected
                                            }
                                            customerRefetch={refetch}
                                            isAllRowsSelected={
                                              isAllRowsSelected
                                            }
                                          />
                                        </>
                                      );
                                    },
                                    cancelCallback: () => {},
                                    image: removeKpiImg,
                                    buttonText: "",
                                    closeButtonText: "",
                                    isCloseAble: false,
                                  });
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "6px",
                                    alignItems: "center",
                                  }}
                                >
                                  <i className="mx-icon-add" />
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: "#333860",
                                      fontWeight: "450",
                                    }}
                                  >
                                    Remove KPI
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="table-main__head-row"
                              style={{ justifyContent: "flex-start" }}
                            >
                              <div
                                style={{
                                  width: "250px",
                                  paddingLeft: "10px",
                                  // marginRight: "0.5%"
                                }}
                              >
                                <Input
                                  overrides={{
                                    Root: {
                                      style: () => ({
                                        borderLeftColor: "#ceced9",
                                        borderRightColor: "#cecde9",
                                        borderTopColor: "#cecde9",
                                        borderBottomColor: "#cecde9",
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                        borderTopWidth: "1px",
                                        borderBottomWidth: "1px",
                                        borderLeftWidth: "1px",
                                        borderRightWidth: "1px",
                                        background: "white",
                                      }),
                                    },
                                    InputContainer: {
                                      style: () => ({
                                        background: "white",
                                      }),
                                    },
                                    EndEnhancer: {
                                      style: ({ $theme }) => ({
                                        padding: "0px",
                                        background: "white",
                                      }),
                                    },
                                  }}
                                  size={SIZE.compact}
                                  endEnhancer={<Search />}
                                  placeholder="Search by User Name"
                                  onChange={(val) => {
                                    let value = val.target.value;
                                    setFilterSearch(value);
                                  }}
                                  value={filterSearch}
                                />
                              </div>
                              <div
                                style={{
                                  width: "250px",
                                  paddingLeft: "10px",
                                }}
                              >
                                <Select
                                  overrides={{
                                    Root: {
                                      style: () => ({
                                        borderLeftColor: "#ceced9",
                                        borderRightColor: "#cecde9",
                                        borderTopColor: "#cecde9",
                                        borderBottomColor: "#cecde9",
                                        borderBottomLeftRadius: "5px",
                                        borderBottomRightRadius: "5px",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                        borderTopWidth: "1px",
                                        borderBottomWidth: "1px",
                                        borderLeftWidth: "1px",
                                        borderRightWidth: "1px",
                                        color: "black",
                                      }),
                                    },
                                    ControlContainer: {
                                      style: ({
                                        $theme,
                                        $isFocused,
                                        $error,
                                      }) => ({
                                        backgroundColor: $isFocused
                                          ? "#F5F6FA"
                                          : "#ffffff",
                                        borderColor: "#CDCED9",
                                        borderWidth: "0.5px",
                                        borderStyle: "solid",
                                        paddingRight: "0px",
                                        backgroundColor: "#ffffff",
                                        borderRadius: "5px",
                                        borderWidth: "1px",
                                        borderColor: $isFocused
                                          ? $error
                                            ? "#FD372A"
                                            : "#516BEB"
                                          : $error
                                          ? "#FD372A"
                                          : "#CDCED9",
                                        color: "black",
                                        height: "35px",
                                      }),
                                    },
                                    ValueContainer: {
                                      style: ({ $theme }) => ({
                                        paddingTop: "5px",
                                      }),
                                    },
                                    Placeholder: {
                                      style: ({ $theme }) => ({
                                        paddingTop: "5px",
                                      }),
                                    },
                                  }}
                                  options={
                                    roleMainData &&
                                    roleMainData.data &&
                                    roleMainData.data.data &&
                                    roleMainData.data.data.doc &&
                                    roleMainData.data.data.doc.map((bsu) => {
                                      return {
                                        label: bsu.role_name,
                                        id: bsu.role_name,
                                        ...bsu,
                                      };
                                    })
                                  }
                                  value={roleFilterValue}
                                  placeholder="Role"
                                  onChange={(params) => {
                                    setRoleFilterValue(params.value);
                                  }}
                                  clearable={false}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="table-main__head-row">
                          {headerGroup.headers.map((column, index) => (
                            <Draggable
                              key={column.id}
                              draggableId={column.id}
                              index={index}
                              isDragDisabled={!column.accessor}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    className="table-main__head-cell-wrapper"
                                  >
                                    <div
                                      className="table-main__head-cell"
                                      {...column.getHeaderProps()}
                                    >
                                      {column.render("Header")}
                                      <div
                                        // className="table-main__head-cell-drag-n-drop"
                                        {...provided.dragHandleProps}
                                      >
                                        {/* {column.disableDND === true ? null : (
                                          <DragDropHorizontal />
                                        )} */}
                                      </div>

                                      {column.canResize && (
                                        <a
                                          className="table-main__head-cell-resizer"
                                          {...column.getResizerProps()}
                                        ></a>
                                      )}
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </Droppable>
              </DragDropContext>
            ))}
          </div>
          {isLoading ? (
            <Loader />
          ) : tabledata && tabledata.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <TableBodyMemo
              isColumnResizing={isColumnResizing}
              page={page}
              prepareRow={prepareRow}
              getTableBodyProps={getTableBodyProps}
              visibleColumns={visibleColumns}
              setIsOpen={setIsOpen}
              setIsAudit={setIsAudit}
            />
          )}

          {/* <CustomerAction
            className="nested-ribbon"
            selectedFlatRows={selectedFlatRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
          /> */}
        </div>
      </div>
      <PaginationWraper
        pageSize={pageSize}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
      />
      {isOpen && (
        <TaxRateAudit
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={isAudit}
          service={getKpiMaintenanceAudit}
        />
      )}
    </>
  );
};

export default KpiMaintenanceTable;
