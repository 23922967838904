export const CO_DRAWER = "CO_DRAWER";

export const ADD_CONTACT_DRAWER = "ADD_CONTACT_DRAWER";
export const CALL_DRAWER = "CALL_DRAWER";
export const EMAIL_DRAWER = "EMAIL_DRAWER";
export const NOTE_LIST_DRAWER = "NOTE_LIST_DRAWER";
export const SMS_DRAWER = "SMS_DRAWER";
export const PAYMENT_DRAWER = "PAYMENT_DRAWER";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const PORTAL_SETTINGS_DRAWER = "PORTAL_SETTINGS_DRAWER";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const HOLD_ACTION = "HOLD_ACTION";
export const DMS_DRAWER = "DMS_DRAWER";
export const CREDIT_REPORT = "CREDIT_REPORT";
export const REQUEST_INFORMATION = "REQUEST_INFORMATION";
export const DISPUTE_DRAWER = "DISPUTE_DRAWER";
export const NEXT_ACTION_DRAWER = "NEXT_ACTION_DRAWER";
export const LEGAL_LETTER_DRAWER = "LEGAL_LETTER_DRAWER";
export const FIELD_VISIT_DRAWER = "FIELD_VISIT_DRAWER";
export const GENERIC_ACTION_DRAWER = "GENERIC_ACTION_DRAWER";
export const CUSTOM_FIELDS_DRAWER = "CUSTOM_FIELDS_DRAWER";
export const TIMELINE_DRAWER = "TIMELINE_DRAWER";
export const REVIEW_PROMISE = "REVIEW_PROMISE";
export const PAYMENT_CARD_DRAWER = "PAYMENT_CARD_DRAWER";
export const PAYMENT_EMAIL_DRAWER = "PAYMENT_EMAIL_DRAWER";
export const EMAIL_TIMELINE_LAYOUT = "EMAIL_TIMELINE_LAYOUT";
export const LEGAL_ACTION_DRAWER = "LEGAL_ACTION_DRAWER";
export const ADD_EDIT_RULE_DRAWER = "ADD_EDIT_RULE_DRAWER";
export const REVIEW_GROUPING_DRAWER = "REVIEW_GROUPING_DRAWER";
export const EDIT_GROUPING_DRAWER = "EDIT_GROUPING_DRAWER";
export const WHATSAPP_DRAWER = "WHATSAPP_DRAWER";
export const LETTER_STATUS = "LETTER_STATUS";
export const LETTER_STATUS_EDIT = "LETTER_STATUS_EDIT";
export const LETTER_STATUS_AUDIT = "LETTER_STATUS_AUDIT";
export const REPLAY_SMS_DRAWER = "REPLAY_SMS_DRAWER";
export const BANKRUPTCY_FORM = "BANKRUPTCY_FORM";
export const SMS_MINIMIZE_ACTION = "SMS_MINIMIZE_ACTION";
export const MINIMIZE_ACTION_DELETE = "MINIMIZE_ACTION_DELETE";
export const MINIMIZE_ALL_REMOVE_DATA = "MINIMIZE_ALL_REMOVE_DATA";

export const MANUAL_GROUPING = "MANUAL_GROUPING";
export const ITEMIZATION_ENHANCEMENT_DRAWER = "ITEMIZATION_ENHANCEMENT_DRAWER";
export const WHATSAPP_CHAT = "WHATSAPP_CHAT";
export const ADJUSTMENT_DRAWER = "ADJUSTMENT_DRAWER";
export const BUSINESS_UNIT_MAX_VALIDATION = "BUSINESS_UNIT_MAX_VALIDATION";

export const minimizeSMSActionData = (payload) => {
  return {
    type: SMS_MINIMIZE_ACTION,
    payload,
  };
};

export const minimizeDeleteAction = (payload) => {
  return {
    type: MINIMIZE_ACTION_DELETE,
    payload,
  };
};

export const minimizeAllAction = (payload) => {
  return {
    type: MINIMIZE_ALL_REMOVE_DATA,
    payload,
  };
};

export const setDrawerState = ({ active = false, type = null, data = {} }) => {
  return {
    type: CO_DRAWER,
    payload: {
      active,
      type,
      data,
    },
  };
};

export const maxValidationFields = ({
  minPayment,
  maxInstalmentCount,
  isClientPortalRedirect,
  agentInstruction,
  isDisplayOnLoad,
}) => {
  return {
    type: BUSINESS_UNIT_MAX_VALIDATION,
    payload: {
      minPayment,
      maxInstalmentCount,
      isClientPortalRedirect,
      agentInstruction,
      isDisplayOnLoad,
    },
  };
};
