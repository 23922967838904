export default function Notification({ size = 22, color = "#F8F8FB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8651 12.2436L17.4676 7.11722C17.058 5.61539 16.1677 4.29626 14.9383 3.3695C13.7089 2.44274 12.2109 1.96155 10.6825 2.0024C9.15408 2.04326 7.6829 2.60382 6.50283 3.59498C5.32276 4.58614 4.50151 5.95103 4.16963 7.47268L3.08763 12.4334C2.96812 12.9815 2.97088 13.55 3.09572 14.0969C3.22056 14.6438 3.46428 15.1551 3.8089 15.5932C4.15352 16.0312 4.59023 16.3847 5.08679 16.6276C5.58335 16.8705 6.12708 16.9966 6.67784 16.9966H7.49724C7.66606 17.8442 8.11711 18.6063 8.77397 19.1536C9.43082 19.701 10.2531 20 11.1014 20C11.9498 20 12.772 19.701 13.4289 19.1536C14.0857 18.6063 14.5368 17.8442 14.7056 16.9966H15.322C15.889 16.9966 16.4483 16.863 16.9562 16.6062C17.4642 16.3494 17.907 15.9763 18.2501 15.5162C18.5932 15.056 18.8273 14.5212 18.9341 13.9535C19.041 13.3858 19.0169 12.8006 18.8651 12.2436ZM11.1014 18.4964C10.6467 18.4945 10.2036 18.3494 9.83283 18.0809C9.46206 17.8124 9.18171 17.4337 9.03013 16.9966H13.1727C13.0211 17.4337 12.7408 17.8124 12.37 18.0809C11.9993 18.3494 11.5562 18.4945 11.1014 18.4964ZM17.0785 14.6081C16.8735 14.8853 16.608 15.11 16.3029 15.2643C15.9979 15.4185 15.6618 15.4981 15.3213 15.4968H6.67784C6.34742 15.4967 6.02124 15.421 5.72335 15.2752C5.42547 15.1294 5.1635 14.9173 4.95678 14.6545C4.75006 14.3917 4.60386 14.0849 4.52899 13.7568C4.45411 13.4287 4.45246 13.0877 4.52416 12.7588L5.60542 7.79739C5.86606 6.60218 6.5111 5.5301 7.438 4.75157C8.3649 3.97305 9.52046 3.53276 10.721 3.50069C11.9215 3.46863 13.0981 3.84663 14.0637 4.57461C15.0293 5.30259 15.7285 6.33877 16.0502 7.51842L17.4477 12.6448C17.5401 12.9788 17.5548 13.3302 17.4907 13.671C17.4266 14.0118 17.2855 14.3326 17.0785 14.6081Z"
        fill={color}
      />
    </svg>
  );
}
