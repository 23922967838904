import React from "react";
import AddPayment from "./AddPayment";
import { useDispatch, useSelector } from "react-redux";
import {
  CO_INVOICES_,
  CO_PAYMENT_,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW_,
  EDIT,
  NEW,
  REVERSE_PAYMENT,
  VIEW,
} from "../../../constants";
import { useParams } from "react-router-dom";
import queryClient from "../../../providers/queryClient";
import {
  createPaymentDetailsCustomerOverview,
  updatePaymentDetailsCustomerOverview,
} from "../../../services";
import { PAYMENT_DRAWER, setDrawerState } from "../../../redux/actions";
import { Icon, Typography } from "../../../components_v2";
import {
  addSMSAttachment,
  smsDocumentDrive,
  smsUnSelectedDocumentData,
  updateSMSAttachment,
} from "../../../redux/customerOverviewDrawer/SMSAction/actions";
import _ from "lodash";
const PaymentDataLayer = (props) => {
  let { data } = useSelector((e) => e.customerOverviewDrawer);

  let dispatch = useDispatch();

  const { customerId } = useParams();

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    queryClient.refetchQueries([`${CO_INVOICES_}${customerId}`]);
    queryClient.invalidateQueries([`${CO_PAYMENT_}${customerId}`]);
    setDrawer(PAYMENT_DRAWER);
  };

  return (
    <div className="co-add-payment-container">
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div>
          <Icon
            icon="back_arrow"
            size={18}
            isPressable
            onClick={() => {
              if (props && props.type === NEW) {
                props.closeDrawer();
              } else {
                setDrawer(PAYMENT_DRAWER, { actionType: NEW });
              }
            }}
          />
        </div>

        {data && data?.actionType === REVERSE_PAYMENT ? (
          <Typography type="h2" subType="medium">
            Reverse Payments
          </Typography>
        ) : (
          <Typography type="h2" subType="medium">
            Payments & Adjustments
          </Typography>
        )}
      </div>
      {data && data.actionType === NEW && (
        <AddPayment
          type={data.actionType}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          services={createPaymentDetailsCustomerOverview}
          fileObject={addSMSAttachment}
          removeAttchFile={smsUnSelectedDocumentData}
          updatingDeskUpload={updateSMSAttachment}
          setAttachment={smsDocumentDrive}
          {...props}
        />
      )}

      {((data && data.actionType === VIEW) ||
        (data && data.actionType === REVERSE_PAYMENT) ||
        (data && data.actionType === EDIT)) && (
        <AddPayment
          type={data.actionType}
          editData={data.editData}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          services={
            _.get(data, "actionType", "") === REVERSE_PAYMENT
              ? createPaymentDetailsCustomerOverview
              : updatePaymentDetailsCustomerOverview
          }
          fileObject={addSMSAttachment}
          removeAttchFile={smsUnSelectedDocumentData}
          updatingDeskUpload={updateSMSAttachment}
          setAttachment={smsDocumentDrive}
          {...props}
        />
      )}
    </div>
  );
};

export default PaymentDataLayer;
