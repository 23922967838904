import React, { useContext } from "react";
import ToolTip from "../ToolTip/ToolTip";
import { FormattedDate } from "react-intl";
import { Paragraph2 } from "baseui/typography";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import {
  MaxyfiProvider,
  MaxyfiContext,
} from "../../../providers/MaxyfiProvider";
import moment from "moment";
import "moment-timezone";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";

function DateFormat(props) {
  let { value, column, alignment, accessor, row, color, ...rest } = props;

  value = _.get(row.original, accessor, null);

  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  let date = value
    ? moment(value)
        ?.tz(currentDefaultFormatDetails.time_zone)
        ?.format(currentDefaultFormatDetails.date_format)
    : "-";

  return visible ? (
    <StatefulTooltip accessibilityType={"tooltip"} content={date}>
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          // margin: "5px",
        }}
      >
        <div
          className="elip"
          ref={ref}
          style={{ lineHeight: "20px", color: color ? color : "#333860" }}
        >
          {date}
        </div>

        {/* <FormattedDate
        value={date}
        year="numeric"
        month="2-digit"
        day="2-digit"
      /> */}
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        width: "100%",
        textAlign: alignment,
        // margin: "5px",
        whiteSpace: "nowrap",
        lineHeight: "20px",
        color: color ? color : "#333860",
      }}
    >
      {date}

      {/* <FormattedDate
        value={date}
        year="numeric"
        month="2-digit"
        day="2-digit"
      /> */}
    </Paragraph2>
  );
}
export default DateFormat;
