// Action Creator
export const MINI_MIRANDA_DATA = "MINI_MIRANDA_DATA";

// Action

export const miniMirandaData = (data) => {
  return {
    type: MINI_MIRANDA_DATA,
    payload: data,
  };
};
