import React from "react";

const Email = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0.429688H2C0.9 0.429688 0.00999999 1.38594 0.00999999 2.55469L0 15.3047C0 16.4734 0.9 17.4297 2 17.4297H18C19.1 17.4297 20 16.4734 20 15.3047V2.55469C20 1.38594 19.1 0.429688 18 0.429688ZM17.6 4.94531L10.53 9.64156C10.21 9.85406 9.79 9.85406 9.47 9.64156L2.4 4.94531C2.15 4.77531 2 4.48844 2 4.18031C2 3.46844 2.73 3.04344 3.3 3.41531L10 7.86719L16.7 3.41531C17.27 3.04344 18 3.46844 18 4.18031C18 4.48844 17.85 4.77531 17.6 4.94531Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Email;
