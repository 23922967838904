const PtpKept = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0013 0.666992C4.4013 0.666992 0.667969 4.40032 0.667969 9.00032C0.667969 13.6003 4.4013 17.3337 9.0013 17.3337C13.6013 17.3337 17.3346 13.6003 17.3346 9.00032C17.3346 4.40032 13.6013 0.666992 9.0013 0.666992ZM6.74297 12.5753L3.7513 9.58366C3.4263 9.25866 3.4263 8.73366 3.7513 8.40866C4.0763 8.08366 4.6013 8.08366 4.9263 8.40866L7.33463 10.8087L13.068 5.07532C13.393 4.75032 13.918 4.75032 14.243 5.07532C14.568 5.40032 14.568 5.92532 14.243 6.25032L7.91797 12.5753C7.6013 12.9003 7.06797 12.9003 6.74297 12.5753Z"
        fill="#0FB158"
      />
    </svg>
  );
};
export default PtpKept;
