import React from "react";
import { Checkbox } from "baseui/checkbox";

const CheckBox = React.forwardRef(
  ({ indeterminate, disabled, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        disabled={disabled}
        overrides={{
          Checkmark: {
            style: ({ $theme, $checked }) => ({
              height: "18px",
              width: "18px",
              backgroundColor: $checked ? "#516BEB" : null,
              borderRadius: $theme.borders.radius200,
              borderTopColor: $checked ? `#516BEB` : `#CDCED9`,
              borderLeftColor: $checked ? `#516BEB` : `#CDCED9`,
              borderRightColor: $checked ? `#516BEB` : `#CDCED9`,
              borderBottomColor: $checked ? `#516BEB` : `#CDCED9`,

              // borderColor: $checked ? `#516BEB` : `#CDCED9`,
              borderWidth: "0.5px",
              borderStyle: "solid",
              backgroundImage: $checked
                ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                : "none",
              backgroundSize: "auto",
            }),
          },
        }}
        ref={resolvedRef}
        {...rest}
      />
    );
  }
);

export default CheckBox;
