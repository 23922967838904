import React, { useContext, useEffect } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { Typography } from "../../../components_v2";
import InputTypes from "./InputTypes";
import setSelectValues from "../../../utils/setSelectValues";

function ItemizationCustomField({ control, setValue, customFields }) {
  const { customInvoice, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  let result = {};
  for (let obj of customInvoice) {
    let section = obj.section;
    if (result.hasOwnProperty(section)) {
      result[section].push({ ...obj });
    } else {
      result[section] = [{ ...obj }];
    }
  }

  useEffect(() => {
    if (
      customFields &&
      customFields?.data &&
      customFields?.data?.custom_field
    ) {
      for (let i = 0; i < customInvoice.length; i++) {
        let path = customInvoice[i].path;
        if (
          customFields &&
          customFields?.data &&
          customFields?.data?.custom_field?.hasOwnProperty(path)
        ) {
          if (customInvoice?.[i]?.data_type === "DATE") {
            if (
              customFields &&
              customFields?.data &&
              customFields?.data?.custom_field?.[path]
            ) {
              setValue(
                path,
                new Date(
                  customFields && customFields?.data?.custom_field?.[path]
                ) || null
              );
            }
          } else if (
            customInvoice?.[i]?.data_type === "TEXT" &&
            customInvoice?.[i]?.options?.length > 0
          ) {
            if (
              path &&
              customFields &&
              customFields?.data &&
              customFields?.data?.custom_field?.[path]
            ) {
              setValue(
                path,
                setSelectValues(
                  (customFields && customFields?.data?.custom_field?.[path]) ||
                    []
                )
              );
            }
          } else if (customInvoice?.[i]?.data_type === "AMOUNT") {
            if (
              path &&
              customFields &&
              customFields?.data &&
              customFields?.data?.custom_field?.[path]?.value
            ) {
              setValue(
                `${path}.value`,
                customFields && customFields?.data?.custom_field?.[path]?.value
              );
              setValue(
                `${path}.currency`,
                customFields?.data?.custom_field?.[path]?.currency
              );
            }
          } else {
            setValue(path, customFields?.data?.custom_field?.[path]);
          }
        }
      }
    }
  }, [customFields?.data?.custom_field, customInvoice]);

  return (
    <div>
      {" "}
      {Object.keys(result)?.map((cus, i) => {
        return (
          <>
            <Typography type="h4" className="mb-12">
              {cus}
            </Typography>
            <div className="itemization-custom-field">
              {result &&
                result[cus].map((e, i) => {
                  return (
                    !e.is_hidden && (
                      <div style={{ width: "215px" }}>
                        <InputTypes
                          name={e.name}
                          control={control}
                          currentDefaultFormatDetails={
                            currentDefaultFormatDetails
                          }
                          type={e.data_type}
                          path={e.path}
                          index={i}
                          arrayKey={cus}
                          options={e.options}
                        />
                      </div>
                    )
                  );
                })}
            </div>
          </>
        );
      })}
    </div>
  );
}

export default ItemizationCustomField;
