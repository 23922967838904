import React from "react";
import { Input } from "baseui/input";

function CellRefInputBox(props) {
  return (
    <Input
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            width: "50px",
            height: "30px",
            borderRadius: "5px",
            border: "1px solid #CDCED9",
          }),
        },
        Input: {
          style: ({ $theme }) => ({
            width: "50px",
            borderRadius: "5px",
            backgroundColor: `${
              props && props.mockData && props.mockData.module === "CON"
                ? "#FFFFFF"
                : "#EEEEEE"
            }`,
          }),
        },
        InputContainer: {
          style: ({ $theme }) => ({
            width: "50px",
            borderRadius: "5px",
          }),
        },
      }}
      {...props}
    />
  );
}

export default CellRefInputBox;
