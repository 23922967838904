import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import { getToken } from "../services";

import { TextButton, SIZE, KIND } from "../components/TextButton";

import { Label1, Paragraph1 } from "baseui/typography";
import { Modal } from "baseui/modal";

let initial = {
  title: "",
  content: "",
  isChildren: false,
  successCallback: () => {},
  cancelCallback: () => {},
  formContent: () => {},
  image: "",
  successButtonType: "error",
  kind: "secondary",
  // buttonName: "Delete",
  buttonText: "",
  closeButtonText: "",
  isCloseAble: false,
};

const useModal = () => {
  let [modal, setModal] = useState(false);
  let [modalContent, setModalContent] = useState(initial);

  let handleModal = (props = {}) => {
    setModal(!modal);
    if (props) {
      setModalContent({ ...modalContent, ...props });
    }
    if (modal) {
      setModalContent({ ...initial });
    }
  };

  return { modal, handleModal, modalContent };
};

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

const ModalContainer = () => {
  let { modal, handleModal, modalContent } = useContext(ModalContext);

  return (
    <Modal
      //  onClose={handleModal}
      isOpen={modal}
      size="auto"
      closeable={modalContent.isCloseAble ? true : false}
      onClose={() => {
        if (modalContent.isCloseAble) {
          handleModal();
        }
      }}
    >
      <div style={{ width: "420px" }}>
        {modalContent.image ? (
          <div
            style={{
              backgroundColor: "#516BEB30",
              // textAlign: "center",
              borderRadius: "10px 10px 50px 50px",
              height: "245px",
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img src={modalContent.image} alt="" />
          </div>
        ) : (
          <></>
        )}

        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Label1
            $style={{
              fontSize: "24px",
              padding: "0px 10px",
              lineHeight: "30px",
            }}
          >
            {modalContent.title}
          </Label1>
          <Paragraph1
            $style={{
              color: "#CDCED9",
              marginTop: "15px",
              padding: "0px 10px",
            }}
          >
            {modalContent.content}
          </Paragraph1>
        </div>
        {modalContent.formContent && modalContent.isChildren && (
          <div>{modalContent.formContent({ handleModal })}</div>
        )}

        {modalContent.buttonText || modalContent.closeButtonText ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "15px",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              <div style={{ width: "125px" }}>
                <TextButton
                  fullWidth
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  onClick={() => {
                    modalContent.cancelCallback();
                    handleModal();
                  }}
                >
                  {modalContent.closeButtonText}
                  {/* Cancel */}
                </TextButton>
              </div>
              <div style={{ width: "125px" }}>
                <TextButton
                  fullWidth
                  size={SIZE.compact}
                  kind={KIND.secondary}
                  // kind={KIND.minimal}
                  // type="error"
                  type={modalContent.successButtonType}
                  onClick={() => {
                    modalContent.successCallback();
                    handleModal();
                  }}
                >
                  {/* Delete */}
                  {modalContent.buttonText}
                </TextButton>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

const ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent } = useModal();

  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <ModalContainer />
      {children}
    </Provider>
  );
};

export { ModalProvider, ModalContext, useModal };
