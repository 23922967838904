import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";

import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { customerInvoiceOverview, getGridStructure } from "../../../services";
import CurrencyFormate from "../../../components_v2/CurrencyFormate";
import { Typography } from "../../../components_v2";
import { AllocatedInputBox } from "../../../components/AllocatedInputBox";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import _ from "lodash";
import {
  CUSTOMER_OVERVIEW_,
  NEW,
  REVERSE_PAYMENT,
  VIEW,
} from "../../../constants";
import { linkedInvoiceModalData } from "../../../services/creditNote";
import Loader from "../../../components/Loader";
import queryClient from "../../../providers/queryClient";

const Header = ({ label = "" }) => {
  const intl = useIntl();

  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

export default function AllocatePaymentTable(props) {
  const { customerId } = useParams();
  let {
    watch,
    control,
    setValue,
    type,
    editData,
    paymentStatus,
    setIsDueAmount,
    paymentAllocation,
    paymentMethod,
    setIsInvData = () => {},
    isSingleAllocate = false,
    debtCom = {},
    debounceCmAmt,
  } = props;
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const [isAllocateDisable, setIsAllocateDisable] = useState(false);

  let { cur, invoices, cm_amt } = watch();

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const paymentTableData = useQuery(
    [
      `CUSTOMER_INVOICE_OVERVIEW-${customerId}}`,
      //   need to add
      {
        pageIndex: 0,
        pageSize: 0,
        sortBy: "due_date",
        filters: {
          invoice_currency: _.get(cur, "[0].id"),
          status: ["due", "overdue"],
          sort: "invoice_due_date",
          gid: _.get(customerData, "data.doc.gid"),
        },
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      filters = {
        ...filters,
      };

      if (type === NEW) {
        return await customerInvoiceOverview({
          pageIndex,
          sortBy,
          pageSize,
          customer_id: customerId,
          filters,
          organization: currentOrganization,
        });
      } else {
        return await linkedInvoiceModalData({
          pageIndex,
          sortBy,
          pageSize,
          filters: {
            invoice_currency: _.get(cur, "[0].id"),
            customer_id: editData && editData.customer_id,
          },
          paymentId: editData && editData._id,
          organization: currentOrganization,
        });
      }
    }
  );

  const { isGid, isNotGid, data } = useMemo(() => {
    let isGid;
    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      isGid = _.get(paymentTableData, "data.data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    let isNotGid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      isNotGid = _.get(paymentTableData, "data.data.docs", []).filter(
        ({ pgid }) => pgid !== _.get(customerData, "data.doc.pgid")
      );
    }
    const allocatedTo = _.get(editData, "allocated_to", []);
    let data;
    data =
      paymentTableData.data &&
      paymentTableData.data.data &&
      paymentTableData.data.data.docs &&
      Array.isArray(paymentTableData.data.data.docs) &&
      type !== REVERSE_PAYMENT
        ? paymentTableData?.data?.data?.docs
        : paymentTableData.data &&
          paymentTableData.data.data &&
          paymentTableData.data.data.docs &&
          Array.isArray(paymentTableData.data.data.docs) &&
          type === REVERSE_PAYMENT
        ? _.get(paymentTableData, "data.data.docs", []).filter((item) =>
            allocatedTo.some(
              (allocatedItem) => allocatedItem.invoice_id === item._id
            )
          )
        : [];

    // if (
    //   debtCom.commission_method !== "DC" &&
    //   data &&
    //   data.length > 0 &&
    //   cm_amt?.toString()?.length > 0
    // ) {
    //   let alloInv = data;
    //   data = alloInv.filter((itemX) =>
    //     _.get(paymentAllocation, "data.data.doc.allocations").some(
    //       (itemY) => itemY.invoice_id === itemX._id
    //     )
    //   );
    // }

    // inv data parent level
    setIsInvData({
      isLoading: paymentTableData.isLoading,
      isFetched: paymentTableData.isFetching,
    });
    return { isGid, isNotGid, data };
  }, [
    paymentTableData.isLoading,
    paymentTableData.isFetched,
    paymentTableData.isRefetching,
  ]);

  // useEffect(() => {
  //   if (
  //     debtCom.commission_method !== "DC" &&
  //     invoices &&
  //     invoices.length > 0 &&
  //     debounceCmAmt?.toString()?.length > 0
  //   ) {
  //     setIsSingleAllocate(invoices.some((e) => e.allocatedAmount !== ""));
  //   }
  // }, [invoices && invoices.map((e) => e?.allocatedAmount)]);

  useEffect(() => {
    if (data) {
      data.map((e, i) => {
        // if (_.get(e, "pgid") === _.get(customerData, "data.doc.pgid")) {
        setValue(`invoices[${i}]._id`, e._id);
        setValue(`invoices[${i}].dueAmount`, e.not_paid_amount.value || 0);
        setValue(`invoices[${i}].dueDate`, e.invoice_due_date);
        setValue(
          `invoices[${i}].allocatedAmount`,
          type === REVERSE_PAYMENT
            ? `-${_.get(e, "linked_amount.value", "")}`
            : _.get(e, "linked_amount.value", "")
        );
        if (type != NEW && editData && editData.allocated_to) {
          let changedDataValue = editData?.allocated_to?.find(
            (i) => i.invoice_id === e._id
          );
          setValue(
            `invoices[${i}].allocatedAmount`,
            type === REVERSE_PAYMENT
              ? `-${_.get(changedDataValue, "amount", "")}`
              : _.get(changedDataValue, "amount", "")
          );
        }
        // }
      });
    } else {
      _.get(paymentTableData, "data.data.docs", []).map((e, i) => {
        setValue(`invoices[${i}]._id`, e._id);
        setValue(`invoices[${i}].dueAmount`, e.not_paid_amount.value || 0);
        setValue(`invoices[${i}].dueDate`, e.invoice_due_date);
        if (type != NEW && editData && editData.allocated_to) {
          let changedDataValue = editData?.allocated_to?.find(
            (i) => i.invoice_id === e._id
          );
          setValue(
            `invoices[${i}].allocatedAmount`,
            type === REVERSE_PAYMENT
              ? `-${_.get(changedDataValue, "amount", "")}`
              : _.get(changedDataValue, "amount", "")
          );
        }
      });
    }
  }, [data, paymentAllocation.isFetching]);

  useEffect(() => {
    if (_.get(paymentAllocation, "data.data.doc.allocations")) {
      _.get(paymentTableData, "data.data.docs", []).map((e, i) => {
        let findInv = _.get(
          paymentAllocation,
          "data.data.doc.allocations"
        ).find((fd) => fd.invoice_id == e._id);

        if (findInv) {
          setValue(
            `invoices[${i}].allocatedAmount`,
            type === REVERSE_PAYMENT
              ? `-${_.get(findInv, "amount", "")}`
              : _.get(findInv, "amount", "")
          );
        }
      });
    }
  }, [paymentAllocation.isLoading, paymentAllocation.isFetching]);

  // need to check this by vignesh

  // useEffect(() => {
  //   if (type === VIEW || type === REVERSE_PAYMENT || paymentStatus === "failed" ||
  // currentDefaultFormatDetails.accounting_book === "QUICKBOOKS" ||
  // isSingleAllocate ||
  // (paymentStatus !== "success" &&
  //   (paymentMethod !== "PA_NACHA" || paymentMethod !== "DC_NACHA")) ||
  // (editData && editData.source === "INSTANT") ||
  // (editData && editData.source === "portal")) {
  //     setIsAllocateDisable(true);
  //   }
  // }, [
  //   type,
  //   paymentStatus,
  //   paymentMethod,
  //   currentDefaultFormatDetails.accounting_book,
  //   editData && editData.source,
  //   isSingleAllocate,
  // ]);

  const columns = useMemo(
    () => [
      {
        header: () => <Header label={"invoice_number"} />,
        accessorKey: "invoice_number",
      },
      {
        header: () => <Header label={"invoice_date"} />,
        accessorKey: "invoice_date",
        cell: (props) => {
          const value = props?.getValue();
          return (
            <Typography type="p" subType="regular" className="mx-table-header">
              {value
                ? moment
                    .utc(value)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)
                : "-"}
            </Typography>
          );
        },
      },
      {
        header: () => <Header label={"due_date"} />,
        accessorKey: "invoice_due_date",
        cell: (props) => {
          const value = props?.getValue();
          return (
            <Typography type="p" subType="regular" className="mx-table-header">
              {value
                ? moment
                    .utc(value)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)
                : "-"}
            </Typography>
          );
        },
      },
      {
        header: () => <Header label={"status"} />,
        accessorKey: "status",
        cell: (props) => {
          const StatusType = ({ type }) => {
            switch (type) {
              case "overdue":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "left",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FD372A",
                      }}
                    ></div>
                    <Typography>Overdue</Typography>
                  </div>
                );
              case "due":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "left",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FBBD04",
                      }}
                    ></div>
                    <Typography>Due</Typography>
                  </div>
                );
              case "paid":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "left",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FE7B72",
                      }}
                    ></div>
                    <Typography>Paid</Typography>
                  </div>
                );
              default:
                return null;
            }
          };

          return <StatusType type={props && props.getValue()} />;
        },
      },

      {
        header: () => <Header label={"due_amount"} />,
        accessorKey: "not_paid_amount",
        cell: (props) => {
          let allocatedAmount =
            watch(`invoices[${props.row.index}].allocatedAmount`) || 0;

          let dueAmount =
            _.get(props, "row.original.not_paid_amount.value", 0) || 0;

          let linkedAmount = _.get(
            props,
            "row.original.linked_amount.value",
            0
          );

          if (!linkedAmount && isNaN(linkedAmount)) {
            linkedAmount = 0;
          }
          let adjustedAllocatedAmount =
            type === REVERSE_PAYMENT ? 0 : Number(allocatedAmount);

          let remainingAmount =
            Number(dueAmount) + Number(linkedAmount) - adjustedAllocatedAmount;

          // let remainingAmount =
          //   Number(dueAmount) + Number(linkedAmount) - Number(allocatedAmount);

          return (
            <div className="dashboard__currency__wraper --cus__currency">
              <Typography
                type="p"
                subType="regular"
                className="mx-table-value"
                style={{ textAlign: "right", paddingRight: "5px" }}
              >
                <sup className="mx-text-currency">
                  {_.get(props, "row.original.not_paid_amount.currency", "")}
                </sup>
                {CurrencyFormate(remainingAmount)}
              </Typography>
            </div>
          );
        },
      },
      {
        header: () => <Header label={"allocated_amount"} />,
        accessorKey: "allocated_amt",
        cell: (props) => {
          let allocatedAmount =
            watch(`invoices[${props.row.index}].allocatedAmount`) || 0;

          let dueAmount =
            _.get(props, "row.original.not_paid_amount.value", 0) || 0;
          let linkedAmount = _.get(
            props,
            "row.original.linked_amount.value",
            0
          );

          if (!linkedAmount && isNaN(linkedAmount)) {
            linkedAmount = 0;
          }

          let remainingAmount =
            Number(dueAmount) + Number(linkedAmount) - Number(allocatedAmount);

          setIsDueAmount(remainingAmount);

          return (
            <Controller
              name={`invoices[${props.row.index}].allocatedAmount`}
              // rules={{ required: remainingAmount < 0 ? "Helo" : "" }}
              control={control}
              render={({ field }) => {
                return (
                  <div>
                    <AllocatedInputBox
                      disabled={
                        // isAllocateDisable
                        // !field.value
                        //   ? isAllocateDisable
                        //   :
                        type === VIEW || type === REVERSE_PAYMENT
                          ? true
                          : (editData && editData.source === "INSTANT") ||
                            (editData && editData.source === "portal")
                          ? true
                          : paymentStatus === "initiated" &&
                            (paymentMethod === "PA_NACHA" ||
                              paymentMethod === "DC_NACHA")
                          ? false
                          : paymentStatus !== "success" &&
                            (paymentMethod !== "PA_NACHA" ||
                              paymentMethod !== "DC_NACHA")
                          ? true
                          : paymentStatus === "failed"
                          ? true
                          : currentDefaultFormatDetails.accounting_book ===
                            "QUICKBOOKS"
                          ? true
                          : !field.value && isSingleAllocate && cm_amt
                          ? isSingleAllocate
                          : false

                        // type === VIEW ||
                        // (editData && editData.source === "INSTANT") ||
                        // (editData && editData.source === "portal") ||
                        // ((paymentStatus !== "initiated" ||
                        //   paymentStatus !== "success") &&
                        //   (paymentMethod === "PA_NACHA" ||
                        //     paymentMethod === "DC_NACHA")) ||
                        // (paymentStatus !== "success" &&
                        //   (paymentMethod !== "PA_NACHA" ||
                        //     paymentMethod !== "DC_NACHA")) ||
                        // currentDefaultFormatDetails.accounting_book ===
                        //   "QUICKBOOKS"
                        //   ? true
                        //   : false
                      }
                      type="number"
                      clearable={false}
                      {...field}
                      fullWidth
                      name={field.name}
                      size={"mini"}
                      // error={remainingAmount < 0}
                      value={field.value}
                      autoFocus={field.value ? true : false}
                      onFocus={(e) => {
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        );
                      }}
                    />
                    {remainingAmount < 0 ? (
                      <div style={{ color: "red", fontSize: "11px" }}>
                        Greater than Due Amount
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }}
            />
          );
        },
      },
    ],
    [paymentStatus, paymentMethod, isSingleAllocate, debounceCmAmt]
  );

  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  if (paymentTableData.isLoading) {
    return <Loader />;
  }

  return (
    <div className="co-invoice-section">
      <div className="co_altc_payment-table_wrapper">
        {/* TABLE HEADER */}
        <div className="co_altc_payment_table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* TABLE HEADER */}

        {/* TABLE BODY */}

        <div className="co-table_body">
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row, i) => {
            if (
              _.get(row, "original.pgid") ===
              _.get(customerData, "data.doc.pgid")
            )
              return (
                <div key={row.id} className="co-table_body-group">
                  {row.getVisibleCells().map((cell) => (
                    <div
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                      className="co-table_body-cell"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  ))}
                </div>
              );
          })}
          {table.getRowModel().rows.map((row, i) => {
            if (
              _.get(row, "original.pgid") !==
              _.get(customerData, "data.doc.pgid")
            )
              return (
                <div key={row.id} className="co-table_body-group">
                  {row.getVisibleCells().map((cell) => (
                    <div
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                      className="co-table_body-cell"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  ))}
                </div>
              );
          })}
          {/* TABLE ROW */}
        </div>
        {/* TABLE BODY */}
      </div>
    </div>
  );
}
