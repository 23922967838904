import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useRef } from "react";
import { setDashBoardCustomActionFilter } from "../../redux/customerSummary/action";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setInvoiceDashboardFilter } from "../../redux/invoiceSummary/action";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["Due", "1-30", "31-60", "61-90", ">90"];

function BarChart({ data = [], selectValue = "customer" }) {
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const chartRef = useRef(null);
  const onClick = (event) => {
    let filteringSelectedCustomerActionStage =
      labels[getElementAtEvent(chartRef.current, event)[0].index].split("-");

    let findLabel = labels[getElementAtEvent(chartRef.current, event)[0].index];

    let firstValue;
    let secondValue;
    let formula;

    if (findLabel === "1-30") {
      firstValue = Number(filteringSelectedCustomerActionStage?.[0]);
      secondValue = Number(filteringSelectedCustomerActionStage?.[1]);
      formula = "between";
    } else if (findLabel === "Due") {
      firstValue = "-99999,1";
      formula = "between";
    } else if (findLabel === ">90") {
      firstValue = "90,99999";
      formula = "between";
    } else {
      firstValue = Number(filteringSelectedCustomerActionStage?.[0]) - 1;
      secondValue = Number(filteringSelectedCustomerActionStage?.[1]) + 1;
      formula = "between";
    }

    if ((firstValue && formula) || firstValue == 0) {
      let dataFilter = {
        combinator: "and",
        rules: [
          {
            id: "5655d1f5-f446-41b6-bce8-35e9f1ec0bd0",
            field: selectValue === "customer" ? "cus_max_delay" : "due_days",
            operator: formula,
            valueSource: "value",
            value:
              (firstValue && secondValue) || firstValue === 0
                ? `${firstValue + "," + secondValue}`
                : `${firstValue}`,
          },
        ],
      };

      let dataFilters = {
        path: selectValue === "customer" ? "CUSTOMER" : "INVOICE",
        filters: {
          ...dataFilter,
        },
        formFilters: {
          ...dataFilter,
        },
      };

      if (selectValue === "customer") {
        dispatch(setDashBoardCustomActionFilter(dataFilters));
        navigate(`/customers`);
      } else {
        dispatch(setInvoiceDashboardFilter(dataFilters));
        navigate(`/invoices`);
      }
    }
  };

  return (
    <div style={{ height: "350px" }}>
      <Bar
        ref={chartRef}
        onClick={onClick}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            // yAxes: [
            //   {
            //     title: {
            //       display: true,
            //       text: "xAxisTitle",
            //       font: {
            //         size: 15,
            //       },
            //     },
            //     ticks: {
            //       beginAtZero: true,
            //       callback: function (value, index, values) {
            //         if (parseInt(value) >= 1000) {
            //           return (
            //             "$" +
            //             value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            //           );
            //         } else {
            //           return "$" + value;
            //         }
            //       },
            //     },
            //   },
            // ],
            xAxes: {
              title: {
                display: true,
                text: "Days",
                font: {
                  size: 15,
                  weight: 700,
                },
              },
            },
            yAxes: {
              title: {
                display: true,
                text: "$ (thousands)",
                font: {
                  size: 15,
                  weight: 700,
                },
              },
            },
          },
          plugins: {
            // legend: {
            //   position: "top",
            // },
            // title: {
            //   display: true,
            //   text: "Chart.js Bar Chart",
            // },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: "",
              data: [...data],
              backgroundColor: [
                "#FFEF82",
                "#FEB139",
                "#FDCAC6",
                "#FF7B72",
                "#FD372A",
              ],
            },
          ],
        }}
      />
    </div>
  );
}

export default BarChart;
