export default function Copy({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M7.42144 13.3033C7.0895 13.3033 6.8024 13.1811 6.56013 12.9368C6.31786 12.6924 6.19673 12.4064 6.19673 12.0786V4.22473C6.19673 3.89692 6.31786 3.61085 6.56013 3.36651C6.8024 3.12217 7.0895 3 7.42144 3H13.5257C13.8535 3 14.1395 3.12217 14.3839 3.36651C14.6282 3.61085 14.7504 3.89692 14.7504 4.22473V12.0786C14.7504 12.4064 14.6282 12.6924 14.3839 12.9368C14.1395 13.1811 13.8535 13.3033 13.5257 13.3033H7.42144ZM7.42144 12.0786H13.5257V4.22473H7.42144V12.0786ZM5.22471 15.5C4.89278 15.5 4.60568 15.3778 4.36341 15.1335C4.12114 14.8892 4 14.6031 4 14.2753V5.80422C4 5.6372 4.05964 5.49419 4.17893 5.3752C4.29821 5.25622 4.44158 5.19673 4.60904 5.19673C4.7765 5.19673 4.92098 5.25622 5.04248 5.3752C5.16397 5.49419 5.22471 5.6372 5.22471 5.80422V14.2753H11.9364C12.1062 14.2753 12.2515 14.335 12.3724 14.4544C12.4932 14.5738 12.5537 14.7182 12.5537 14.8875C12.5537 15.0569 12.4932 15.2014 12.3724 15.3208C12.2515 15.4403 12.1062 15.5 11.9364 15.5H5.22471Z"
        fill={color}
      />
    </svg>
  );
}
