import React, { useContext } from "react";
import setSelectValues from "../../../utils/setSelectValues";
import { useDispatch, useSelector } from "react-redux";
import { updateDefaultData } from "../../../redux/adhocUploadData/action";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { CellRefSelectBox } from "../../CellRefSelectBox";
import _ from "lodash";
import { Input } from "baseui/input";
import getSelectValues from "../../../utils/getSelectValues";
import { predefinedDefaultData } from "../../../redux/adhocUploadData/predefindUpload/action";
import { DatePicker } from "baseui/datepicker";

const PredefinedDefaultField = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;

  const dispatch = useDispatch();
  const { referenceData } = useContext(MaxyfiContext);
  value = _.get(row.original, accessor, "");

  return row.original.type === "DATE_RANGE" ? (
    <DatePicker
      placeholder="Select"
      value={value.value}
      size="mini"
      onChange={(params) => {
        dispatch(
          predefinedDefaultData({
            index: row.index,
            referance: value.reference,
            defaultData: params.date,
          })
        );
      }}
      range
      maxDate={new Date()}
      // formatString={dateFormat}
      overrides={{
        Input: {
          props: {
            id: props.name,
            overrides: {
              Root: {
                style: () => ({
                  borderRadius: "5px",
                  border: "0px solid #cdced9",
                  width: "100%",
                  // height: "20px",
                  flexShrink: 0,
                  backgroundColor: "#FFF",
                }),
              },
              Input: {
                style: ({ $theme, $isFocused }) => ({
                  backgroundColor: "#ffffff",
                  // "::placeholder": {
                  //   color: $isFocused ? "#cbd5e0" : "transparent",
                  //   transition: "color 0.4s ease",
                  // },
                }),
              },
              InputContainer: {
                style: () => ({
                  backgroundColor: "#fffff",
                }),
              },
            },
          },
        },
      }}
    />
  ) : (
    <CellRefSelectBox
      placeholder="Select"
      clearable={false}
      backspaceRemoves={true}
      creatable={row && row.original && row.original.is_create ? true : false}
      value={setSelectValues(value.value)}
      onChange={(params) => {
        dispatch(
          predefinedDefaultData({
            index: row.index,
            referance: value.reference,
            defaultData: params.value[0].id,
          })
        );
      }}
      //   options={uploadFieldOptions}
      options={
        value.reference && referenceData[value.reference]
          ? referenceData[value.reference]
          : setSelectValues(value.tags, true)
      }
      searchable={true}
      disabled={
        row && row.original && row.original.is_field_disabled ? true : false
      }
    />
  );
};

export default PredefinedDefaultField;
