import { ParagraphMedium } from "baseui/typography";
import React, { useContext } from "react";
import _ from "lodash";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

export const StatusType = ({ type, alignment }) => {
  switch (type) {
    case "overdue":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>Overdue</ParagraphMedium>
        </div>
      );
    case "due":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FBBD04",
            }}
          ></div>
          <ParagraphMedium>Due</ParagraphMedium>
        </div>
      );
    case "paid":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#14ae5c",
            }}
          ></div>
          <ParagraphMedium>Paid</ParagraphMedium>
        </div>
      );

    case "cancelled":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FBBD04",
            }}
          ></div>
          <ParagraphMedium>Cancelled</ParagraphMedium>
        </div>
      );
    case "in_progress":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#adadad",
            }}
          ></div>
          <ParagraphMedium>In Progress</ParagraphMedium>
        </div>
      );
    case "PRF":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>Pending Refund</ParagraphMedium>
        </div>
      );
    case "PRT":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>Printed</ParagraphMedium>
        </div>
      );
    case "initiated":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#ADADAD",
            }}
          ></div>
          <ParagraphMedium>Initiated</ParagraphMedium>
        </div>
      );

    case "success":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#14ae5c",
            }}
          ></div>
          <ParagraphMedium>Success</ParagraphMedium>
        </div>
      );
    case "failed":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>Failed</ParagraphMedium>
        </div>
      );
    case "CLR":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>Cleared</ParagraphMedium>
        </div>
      );
    case "stl":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment,
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#14ae5c",
            }}
          ></div>
          <ParagraphMedium>Settled</ParagraphMedium>
        </div>
      );
    default:
      return null;
  }
};

const InvoiceStatus = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  return <StatusType type={value} alignment={alignment} />;
};

export default InvoiceStatus;
