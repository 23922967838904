export const DOCUMENT_DRAWER_OPEN = "DOCUMENT_DRAWER_OPEN";
export const DOCUMENT_DRAWER_CLOSE = "DOCUMENT_DRAWER_CLOSE";
export const CUSTOMER_DRIVE_OPEN = "CUSTOMER_DRIVE_OPEN";
export const ADD_DOCUMENT_FORM = "ADD_DOCUMENT_FORM";
export const INITIALSTATE = "INITIALSTATE";
export const DOCUMENT_DRIVE_DATA = "DOCUMENT_DRIVE_DATA";
export const DOCUMENT_DRIVE_DATA_QUICK_ACTION =
  "DOCUMENT_DRIVE_DATA_QUICK_ACTION";
export const EDIT_DOCUMENT_FORM = "EDIT_DOCUMENT_FORM";
export const TYPE_FORM_INITIAL = "TYPE_FORM_INITIAL";
export const EMAIL_ATTACHED_FILE_ALL_DATA = "EMAIL_ATTACHED_FILE_ALL_DATA";
export const EMAIL_ATTACHMENT_STATUS = "EMAIL_ATTACHMENT_STATUS";
export const EMAIL_ATTACHMENT_ID = "EMAIL_ATTACHMENT_ID";
export const EMAIL_FINAL_ATTACHMENT_DATA = "EMAIL_FINAL_ATTACHMENT_DATA";
export const UNSELECTED_DOCUMENT_DATA = "UNSELECTED_DOCUMENT_DATA";
export const REFETCH_DOCUMENT_DATA = "REFETCH_DOCUMENT_DATA";
export const FORM_INITIAL = "FORM_INITIAL";
export const CREDIT_DRAWER_CLOSE = "CREDIT_DRAWER_CLOSE";
export const ADD_EMAIL_ATTACHMENT_FROM_DESKTOP =
  "ADD_EMAIL_ATTACHMENT_FROM_DESKTOP";

export const UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP =
  "UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP";

export const refetchDocumentData = () => {
  return {
    type: REFETCH_DOCUMENT_DATA,
  };
};

export const unSelectedDocumentData = (data) => {
  return {
    type: UNSELECTED_DOCUMENT_DATA,
    payload: data,
  };
};

export const addEmailAttachmentFromDesktop = (payload) => {
  return {
    type: ADD_EMAIL_ATTACHMENT_FROM_DESKTOP,
    payload,
  };
};

export const updateEmailAttachmentFromDesktop = (payload) => {
  return {
    type: UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP,
    payload,
  };
};

export const emailFinalAttachmentData = () => {
  return {
    type: EMAIL_FINAL_ATTACHMENT_DATA,
  };
};

export const emailAttachmentId = (id) => {
  return {
    type: EMAIL_ATTACHMENT_ID,
    payload: id,
  };
};

export const emailAttachmentStatus = (data) => {
  return {
    type: EMAIL_ATTACHMENT_STATUS,
    payload: data,
  };
};

export const emailAttachedFile = (data) => {
  return {
    type: EMAIL_ATTACHED_FILE_ALL_DATA,
    payload: data,
  };
};

export const documentDrawerOpen = (data) => {
  return {
    type: DOCUMENT_DRAWER_OPEN,
    payload: data,
  };
};

export const documentDrawerClose = (data) => {
  return {
    type: DOCUMENT_DRAWER_CLOSE,
    payload: data,
  };
};

export const customerDriveOpen = (data) => {
  return {
    type: CUSTOMER_DRIVE_OPEN,
    payload: data,
  };
};

export const addDocumentForm = (data) => {
  return {
    type: ADD_DOCUMENT_FORM,
    payload: data,
  };
};

export const documentDrive = (data) => {
  return {
    type: DOCUMENT_DRIVE_DATA,
    payload: data,
  };
};

export const documentDriveQuickAction = (data) => {
  return {
    type: DOCUMENT_DRIVE_DATA_QUICK_ACTION,
    payload: data,
  };
};

export const initialState = () => {
  return {
    type: INITIALSTATE,
  };
};

export const editDocumentForm = (data) => {
  return {
    type: EDIT_DOCUMENT_FORM,
    payload: data,
  };
};

export const typeToInitial = () => {
  return {
    type: TYPE_FORM_INITIAL,
  };
};

export const docInitial = () => {
  return {
    type: FORM_INITIAL,
  };
};
