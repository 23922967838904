import React from "react";

export default function CallUnsuccessfull({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.717 15.5C13.336 15.5 11.9408 15.1726 10.5456 14.5319C9.15034 13.8912 7.85478 12.9801 6.68736 11.7984C5.51993 10.631 4.60877 9.33542 3.96811 7.95444C3.32745 6.55923 3 5.16401 3 3.78303C3 3.56948 3.07118 3.3844 3.21355 3.22779C3.35592 3.08542 3.541 3 3.76879 3H6.16059C6.34567 3 6.51651 3.05695 6.64465 3.18508C6.78702 3.29897 6.87244 3.45558 6.91515 3.64066L7.34225 5.80467C7.37073 6.00399 7.37073 6.17483 7.32802 6.3172C7.28531 6.45957 7.21412 6.5877 7.10023 6.67312L5.42027 8.3246C5.69077 8.82289 6.00399 9.29271 6.35991 9.74829C6.71583 10.1896 7.08599 10.6167 7.49886 11.0296C7.89749 11.4282 8.3246 11.8126 8.78018 12.1543C9.23576 12.496 9.71982 12.8235 10.2466 13.1224L11.8981 11.4567C12.012 11.3428 12.1543 11.2574 12.3109 11.2005C12.4675 11.1577 12.6384 11.1435 12.8235 11.172L14.8593 11.5849C15.0444 11.6276 15.201 11.7272 15.3149 11.8696C15.4288 12.012 15.4858 12.1686 15.4858 12.3536V14.7312C15.4858 14.9448 15.4146 15.1298 15.258 15.2864C15.1156 15.4146 14.9305 15.5 14.717 15.5ZM12.5672 6.30296L14.3895 4.48064C14.5888 4.28132 14.5888 3.95387 14.3895 3.75456L14.1475 3.51253C13.9482 3.31321 13.6207 3.31321 13.4214 3.51253L11.5849 5.32061L9.76253 3.49829C9.56321 3.29898 9.23576 3.29898 9.03645 3.49829L8.79442 3.74032C8.5951 3.93964 8.5951 4.26708 8.79442 4.4664L10.6167 6.28872L8.79442 8.12528C8.5951 8.3246 8.5951 8.65205 8.79442 8.85137L9.03645 9.09339C9.23576 9.29271 9.56321 9.29271 9.76253 9.09339L11.5849 7.27107L13.4072 9.09339C13.6065 9.29271 13.9339 9.29271 14.1333 9.09339L14.3753 8.85137C14.5746 8.65205 14.5746 8.3246 14.3753 8.12528L12.5672 6.30296Z"
        fill={color}
      />
    </svg>
  );
}
