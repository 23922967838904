import React from "react";

export default function Workflow({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3465 14.4691V13.3487H9.70898C9.36665 13.3487 9.07431 13.2276 8.83197 12.9852C8.5896 12.7428 8.46842 12.4505 8.46842 12.1082V6.16814H6.60719V7.28851C6.60719 7.57492 6.50698 7.81834 6.30656 8.01876C6.10614 8.21918 5.86273 8.31939 5.57633 8.31939H3.03088C2.74447 8.31939 2.50105 8.21918 2.30063 8.01876C2.10021 7.81834 2 7.57492 2 7.28851V4.03088C2 3.74448 2.10021 3.50107 2.30063 3.30065C2.50105 3.10022 2.74447 3 3.03088 3H5.57633C5.86273 3 6.10614 3.10022 6.30656 3.30065C6.50698 3.50107 6.60719 3.74448 6.60719 4.03088V5.15125H11.3465V4.03088C11.3465 3.74448 11.4467 3.50107 11.6472 3.30065C11.8476 3.10022 12.091 3 12.3774 3H14.9229C15.2093 3 15.4527 3.10022 15.6531 3.30065C15.8535 3.50107 15.9537 3.74448 15.9537 4.03088V7.28851C15.9537 7.57492 15.8535 7.81834 15.6531 8.01876C15.4527 8.21918 15.2093 8.31939 14.9229 8.31939H12.3774C12.091 8.31939 11.8476 8.21918 11.6472 8.01876C11.4467 7.81834 11.3465 7.57492 11.3465 7.28851V6.16814H9.48531V12.1082C9.48531 12.1734 9.50628 12.227 9.54822 12.2689C9.59015 12.3109 9.64374 12.3319 9.70898 12.3319H11.3465V11.2115C11.3465 10.9251 11.4467 10.6817 11.6472 10.4812C11.8476 10.2808 12.091 10.1806 12.3774 10.1806H14.9229C15.2093 10.1806 15.4527 10.2808 15.6531 10.4812C15.8535 10.6817 15.9537 10.9251 15.9537 11.2115V14.4691C15.9537 14.7555 15.8535 14.9989 15.6531 15.1994C15.4527 15.3998 15.2093 15.5 14.9229 15.5H12.3774C12.091 15.5 11.8476 15.3998 11.6472 15.1994C11.4467 14.9989 11.3465 14.7555 11.3465 14.4691Z"
        fill={color}
      />
    </svg>
  );
}
