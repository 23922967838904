import React from 'react'

const UpdateAgo = ({size=22,color='#333860'}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7 5.50001V7.00001C18.8 5.60001 17.5 4.60001 15.9 3.90001C13.9 3.00001 11.7 2.80001 9.6 3.40001C7.5 4.00001 5.7 5.20001 4.5 7.00001C3.3 8.8 2.8 11 3.1 13.1C3.4 15.2 4.4 17.2 6 18.6C7.6 20 9.7 20.9 11.9 21C14.1 21.1 16.2 20.4 17.9 19C19.4 17.8 20.5 16.1 21 14.2C21.1 13.9 20.9 13.6 20.5 13.5C20.2 13.4 19.9 13.6 19.8 14C19.4 15.6 18.4 17 17.1 18.1C15.6 19.3 13.8 19.9 11.9 19.8C10 19.7 8.2 19 6.8 17.8C5.4 16.5 4.5 14.8 4.3 13C4 11.1 4.5 9.20001 5.5 7.70001C6.6 6.10001 8.1 5.00001 10 4.50001C11.8 4.00001 13.8 4.10001 15.5 4.90001C16.9 5.50001 18 6.50001 18.8 7.70001L17.3 7.50001C17 7.50001 16.7 7.70001 16.7 8.00001C16.6 8.30001 16.9 8.60001 17.2 8.60001L20.9 9.20001V5.50001C20.9 5.20001 20.7 4.90001 20.3 4.90001C20 4.90001 19.7 5.20001 19.7 5.50001ZM12.6 7.00001C12.6 6.70001 12.4 6.40001 12 6.40001C11.6 6.40001 11.4 6.70001 11.4 7.00001V11.7C11.4 12.1 11.6 12.5 12 12.6L16.7 15C17 15.1 17.3 15 17.4 14.8C17.5 14.5 17.4 14.2 17.2 14.1L12.5 11.8V7.00001H12.6Z" fill={color}/>
    </svg>
    
  )
}

export default UpdateAgo