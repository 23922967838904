import React, { useState, useEffect, useContext } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import KpiMaintenanceTable from "../../containers/KipMaintenance/KpiMaintenanceTable";

const KpiMaintenance = () => {
  const [addItemModal, setAddItemModal] = useState(false);
  const [refetchingList, setRefetchingList] = useState(1);

  return (
    <Layout>
      <>
        <div className="content-container">
          <div className="content-header">
            <div className="content-header__title">
              <PageTitle id="kpi_maintenance" />
            </div>
          </div>
          <div className="content-body">
            <KpiMaintenanceTable
              addItemModal={addItemModal}
              setAddItemModal={setAddItemModal}
              // setEditingInvoiceItem={setEditingInvoiceItem}
              refetchingList={refetchingList}
              setRefetchingList={setRefetchingList}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};

export default KpiMaintenance;
