export default function PhoneStatus({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill={color}>
      <path
        d="M14.444 15.2236C13.081 15.2236 11.704 14.901 10.3129 14.2558C8.92178 13.6106 7.6415 12.6995 6.47204 11.5226C5.29631 10.3519 4.38646 9.07225 3.74247 7.68355C3.09848 6.29486 2.77649 4.91783 2.77649 3.55246C2.77649 3.33302 2.85005 3.14883 2.99716 2.9999C3.14428 2.85096 3.32818 2.77649 3.54886 2.77649H5.88895C6.07838 2.77649 6.24196 2.83418 6.3797 2.94957C6.51745 3.06496 6.60795 3.21664 6.65121 3.40461L7.08102 5.55074C7.11083 5.72911 7.10296 5.89061 7.0574 6.03522C7.01183 6.17982 6.9372 6.30235 6.83348 6.40281L5.14184 8.05964C5.42454 8.57021 5.7413 9.05115 6.09211 9.50243C6.44292 9.95372 6.82232 10.3824 7.23031 10.7885C7.63176 11.1923 8.06205 11.5701 8.52119 11.9218C8.98033 12.2735 9.47985 12.6044 10.0197 12.9145L11.7184 11.2169C11.8292 11.101 11.9529 11.023 12.0895 10.9829C12.226 10.9427 12.3772 10.9332 12.5431 10.9544L14.5793 11.3628C14.7694 11.4069 14.9243 11.5015 15.044 11.6467C15.1637 11.7919 15.2236 11.9565 15.2236 12.1407V14.4512C15.2236 14.6719 15.1493 14.8558 15.0008 15.0029C14.8523 15.15 14.6667 15.2236 14.444 15.2236ZM4.63994 7.07095L5.97841 5.79018C6.00005 5.77095 6.01446 5.74451 6.02166 5.71085C6.02888 5.6772 6.02888 5.64595 6.02166 5.6171L5.68634 3.94115C5.67673 3.90268 5.6599 3.87383 5.63585 3.8546C5.61181 3.83536 5.58056 3.82574 5.5421 3.82574H3.94763C3.91638 3.82574 3.89174 3.83536 3.8737 3.8546C3.85568 3.87383 3.84666 3.89787 3.84666 3.92671C3.87503 4.42912 3.9558 4.94727 4.08897 5.48116C4.22215 6.01506 4.4058 6.54499 4.63994 7.07095ZM11.0135 13.4012C11.5019 13.633 12.0165 13.8101 12.5572 13.9327C13.0979 14.0553 13.6033 14.1282 14.0733 14.1512C14.1022 14.1512 14.1262 14.1416 14.1455 14.1224C14.1647 14.1032 14.1743 14.0791 14.1743 14.0503V12.4724C14.1743 12.4339 14.1647 12.4027 14.1455 12.3786C14.1262 12.3546 14.0974 12.3378 14.0589 12.3281L12.5142 11.9986C12.4854 11.9914 12.4601 11.9914 12.4385 11.9986C12.4169 12.0058 12.394 12.0202 12.37 12.0419L11.0135 13.4012Z"
        fill={color}
      />
    </svg>
  );
}
