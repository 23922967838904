import React, { useEffect, useContext, useMemo, useState } from "react";
import { Button } from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { FormattedNumber } from "react-intl";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useQuery } from "react-query";
import {
  HeadingMedium,
  HeadingXLarge,
  ParagraphLarge,
} from "baseui/typography";
// import { TextButton, KIND, SIZE } from "../../components/TextButton";+
import Loader from "../../../../components/Loader";
import { TextButton, KIND, SIZE } from "../../../../components/TextButton";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { tab } from "@testing-library/user-event/dist/tab";
import CardMerge from "../../../../components/Card";
import getSelectValues from "../../../../utils/getSelectValues";
import { IconButton } from "../../../../components/IconButton";
import { SelectBox } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import { useIntl } from "react-intl";
import { useForm, Controller, set } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import moment from "moment";
// import DownArrow from "../../../../utils/UpArrow";
import UpArrow from "../../../../assets/img/svg/UpArrow";
import DownArrow from "../../../../assets/img/svg/DownArrow";
import {
  postPopUpData,
  updatePromiseDate,
  getReviewPromiseDatas,
  gettingDisputeTableData,
  getCustomerContactDetail,
} from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { getGridStructure } from "../../../../services";
import columnMapper from "../../../../utils/mapper";
import Cancel from "../../../../assets/img/svg/Template/Cancel";
import { useParams, useSearchParams } from "react-router-dom";
import { PROMISE_TO_PAY } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";

import TableDeleteButton from "../../../../assets/img/svg/TableDeleteButton";
import ReviewPromiseAction from "./AdhocActionPopup/ReviewPromiseAction";
import {
  refetchActions,
  reviewPromiseAction,
  reviewTablePopUp,
} from "../../../../redux/actions";
import { instantPaymentDrawer } from "../../../../redux/customerOverview/callAction/action";
import setSelectValues from "../../../../utils/setSelectValues";
import { LabelLarge } from "baseui/typography";
import { getCommunicationTemplateList } from "../../../../services";
import { ChevronDown, ChevronRight } from "baseui/icon";
import Edit from "../../../../assets/img/svg/Template/Edit";
import EditSvg from "../../../../assets/img/svg/EditSvg";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../../../components/TextArea";

export const UpdatePromiseStatus = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [popUpData, setPopUpData] = useState(null);
  const [modalFormData, setModalFormData] = useState();
  const { id } = useParams();

  const { customerDetails, customerConatactDetails } = useSelector(
    (e) => e.customerOverviewReducer
  );

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const getParams = getSearchParams.get("customers");
  let {
    currentOrganization,
    referenceData,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  let [templates, setTemplates] = useState([]);

  let isValidPhone = customerConatactDetails?.some(
    (e) => e?.is_valid_phone === true
  );

  let isEmailValid = customerConatactDetails?.some(
    (e) => e?.is_valid_email === true
  );

  let type = referenceData["template_type"];

  let mappinTypeValue = type
    .filter((i) => i.id !== "letter")
    .map((e) => {
      if (e.id === "sms" && !currentDefaultFormatDetails.is_sms_configured) {
        return {
          ...e,
          disabled: true,
        };
      }

      if (e.id === "sms" && !isValidPhone) {
        return {
          ...e,
          disabled: true,
        };
      }

      if (e.id === "whatsapp" && !isValidPhone) {
        return {
          ...e,
          disabled: true,
        };
      }

      if (e.id === "email" && !isEmailValid) {
        return {
          ...e,
          disabled: true,
        };
      }

      return e;
    });

  const isModal = useSelector(
    (s) => s.customerOverviewReducer.reviewModalPopUp
  );

  const modalData = useSelector(
    (s) => s.customerOverviewReducer.reviewModalData
  );

  const schema = Yup.object({
    received_amount: Yup.string().test(
      "maxDigitsAfterDecimal",
      "Max 2 decimals only allowed",
      (value) => /^\d+(\.\d{1,2})?$/.test(value)
    ),
  });

  const postOrganizationSetting = useMutation(
    (data) =>
      postPopUpData({
        data,
        organization: currentOrganization,
        promiseId: getParams,
        popId: modalData._id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // reset();
        dispatch(reviewTablePopUp(false));
        dispatch(refetchActions());
        setValue("notify_template", []);
        setValue("notify_customer_via", []);
      },
    }
  );

  // useEffect(async () => {
  //   const popUpValue = await getPopUpData({
  //     organization: currentOrganization,
  //     promiseId: id,
  //     popId: modalData._id,
  //   }).data.doc;
  //
  //   setModalFormData(popUpValue);
  //   //TODO: PRAVEEN FAILURE ERROR HANDLING
  // }, []);

  function close() {
    setIsOpen(false);
  }
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm(
    {
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    },
    {
      shouldUnregister: true,
      defaultValues: {
        notify_customer_via: [],
        notify_template: [],
      },
    }
  );

  let { notify_customer_via, notify_template } = watch();

  const getTemplates = async () => {
    return await getCommunicationTemplateList({
      filters: {
        tags: ["PROMISE", "CALL_BACK", "CONTACT_UNSUCCESSFUL", "PAYMENT_PLAN"],
      },
      organization: currentOrganization,

      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            tags: e.tags || [],
            type: e.type,
          }));
          setTemplates(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (modalData) {
      setValue("payment_status", setSelectValues(modalData.status));
      setValue(
        "received_amount",
        modalData &&
          modalData.promise_amount &&
          modalData.promise_amount?.value &&
          !isNaN(modalData.promise_amount?.value)
          ? Number(modalData.promise_amount?.value).toFixed(2)
          : Number(0).toFixed(2)
      );
    } else {
      setValue("payment_status", "");
      setValue("received_amount", "");
    }
  }, [modalData]);

  useEffect(() => {
    if (
      // notify_customer_via &&
      // notify_customer_via?.length > 0 &&
      notify_template &&
      notify_template?.length === 0
    ) {
      setError("notify_template", {
        message: "Required",
      });
    } else {
      clearErrors("notify_template");
    }
  }, [notify_customer_via, notify_template]);

  const onSubmit = async (data) => {
    let values = {
      // ...data,
      payment_status: getSelectValues(data.payment_status),
      ...(data?.notify_customer_via && data?.notify_customer_via?.length > 0
        ? {
            notify_customer_via: getSelectValues(data.notify_customer_via),
          }
        : {}),
      ...(data?.notify_template && data?.notify_template?.length > 0
        ? {
            notify_template: getSelectValues(data.notify_template),
          }
        : {}),
      // notify_template: getSelectValues(data.notify_template),
      received_amount: data.received_amount,
    };
    //
    await postOrganizationSetting.mutateAsync({ ...values });
  };

  return (
    <>
      <Modal
        onClose={close}
        isOpen={isModal}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "30px",
              width: "325px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              // boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {/* Heading */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <OnHoldModalSvg /> */}
              <LabelLarge>
                {intl.formatMessage({
                  id: `update_${modalData.type}_status`,
                  // defaultMessage: "update_{modalData}_status",
                })}
              </LabelLarge>
            </div>

            {/* Section One */}
            <div style={{ paddingTop: "15px " }}>
              <div>
                <div style={{ width: "" }}>
                  <Controller
                    defaultValues={[]}
                    name="payment_status"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        requiredAstric={true}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "promise_status",
                        })}
                        placeholder={intl.formatMessage({
                          id: "promise_status",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["promise_status"]}
                      />
                    )}
                  />
                </div>
              </div>
              {/* Section Two */}
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Controller
                defaultValue={""}
                name="received_amount"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <TextBox
                      {...field}
                      // type="number"
                      fullWidth
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "received_amount",
                      })}
                      placeholder={intl.formatMessage({
                        id: "received_amount",
                      })}
                      value={field.value}
                    />
                  </>
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <div style={{ paddingBottom: "15px" }}>Notify Customer</div>

              <div style={{ width: "130px" }}>
                <Controller
                  name="notify_customer_via"
                  control={control}
                  // rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      // requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "type_review",
                      })}
                      placeholder={intl.formatMessage({
                        id: "type_review",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                        if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                          setValue(`notify_template`, []);
                        }

                        // if (
                        //   notify_customer_via &&
                        //   notify_customer_via.length > 0
                        // ) {
                        // setError("notify_template", {
                        //   message: "Required",
                        // });
                        // }
                      }}
                      // options={referenceData["promise_status"]}
                      options={mappinTypeValue}
                    />
                  )}
                />
              </div>
            </div>
            {notify_customer_via && notify_customer_via?.length > 0 ? (
              <>
                <div>
                  <Controller
                    name="notify_template"
                    control={control}
                    // rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        // requiredAstric={true}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "choose_template",
                        })}
                        placeholder={intl.formatMessage({
                          id: "choose_template",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          templates
                            .filter((e) =>
                              e.tags.includes(
                                modalData && modalData.type === "PROMISE_TO_PAY"
                                  ? "PROMISE"
                                  : modalData.type
                              )
                            )
                            .filter((k) => {
                              if (
                                notify_customer_via &&
                                notify_customer_via[0] &&
                                notify_customer_via[0]?.id === k?.type
                              ) {
                                return true;
                              }
                              return false;
                            }) || []
                        }
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              style={{
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div style={{ width: "126px" }}>
                <TextButton
                  type="button"
                  onClick={() => {
                    dispatch(reviewTablePopUp(false));
                    reset();
                  }}
                  fullWidth
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "126px" }}>
                <TextButton
                  type="submit"
                  fullWidth
                  size={SIZE.compact}
                  disabled={
                    (notify_customer_via &&
                      notify_customer_via.length > 0 &&
                      notify_template &&
                      notify_template.length === 0) ||
                    isSubmitting
                  }
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

export const UpdatePromiseDetails = ({
  formModal,
  formData,
  resetEditDetailsModal,
}) => {
  const { promise_date, promise_amount, _id, type } = formData;

  const schema = Yup.object({
    promise_date: Yup.date().required(),
  });
  const intl = useIntl();

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const getParams = getSearchParams.get("customers");

  let { currentOrganization, referenceData } = useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  const updatePromiseDetails = useMutation(
    (data) =>
      updatePromiseDate({
        organization: currentOrganization,
        customerId: getParams,
        data,
        promiseId: _id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          } else {
            errorData = data.message;
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        resetEditDetailsModal();
      },
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      promise_date: new Date(),
      comment: "",
      ...(promise_date
        ? { promise_date: moment.utc(promise_date).tz(timeZone).toDate() }
        : {}),
    },
  });

  const onSubmit = async ({ promise_date, comment = "" }) => {
    let values = {
      promise_date: moment
        .tz(
          `${promise_date.getFullYear()}-${
            promise_date.getMonth() + 1
          }-${promise_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .utc()
        .valueOf(),
      comment,
    };

    await updatePromiseDetails.mutateAsync({ ...values });
  };

  return (
    <>
      <Modal
        onClose={resetEditDetailsModal}
        isOpen={formModal}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "30px",
              width: "325px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <LabelLarge>
                {intl.formatMessage({
                  id: `update_${type}_details`,
                })}
              </LabelLarge>
            </div>
            <div style={{ paddingTop: "15px" }}>
              <TextBox
                type="number"
                size={SIZE.mini}
                label={intl.formatMessage({
                  id: "promise_amount",
                })}
                value={
                  promise_amount && promise_amount.value
                    ? promise_amount.value
                    : 0
                }
                disabled
              />
            </div>
            <div style={{}}>
              <Controller
                name="promise_date"
                control={control}
                rules={{ required: "Required" }}
                fo
                render={({ field }) => (
                  <DateRangeSelect
                    {...field}
                    name={field.name}
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    requiredAstric={true}
                    label={intl.formatMessage({
                      id: "promise_date",
                    })}
                    value={field.value}
                    onChange={(e) => field.onChange(e.date)}
                    minDate={new Date()}
                  />
                )}
              />
            </div>

            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  clearable={false}
                  name={field.name}
                  size={SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "user_comments",
                  })}
                  placeholder={intl.formatMessage({
                    id: "user_comments",
                  })}
                  value={field.value}
                />
              )}
            />

            <div
              style={{
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div style={{ width: "126px" }}>
                <TextButton
                  type="button"
                  onClick={() => {
                    resetEditDetailsModal();
                    reset();
                  }}
                  fullWidth
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "126px" }}>
                <TextButton
                  type="submit"
                  fullWidth
                  size={SIZE.compact}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

// export const COLUMNS = [
//   {
//     Header: "Promsie Type",
//     accessor: "promise_type",
//   },
//   {
//     Header: "Promise Amount",
//     accessor: "promise_amount",
//   },
//   {
//     Header: "Promise Date",
//     accessor: "promise_date",
//   },
//   {
//     Header: "Capture By",
//     accessor: "capture_by",
//   },
//   {
//     Header: "Capture On",
//     accessor: "capture_on",
//   },

//   {
//     Header: "Status",
//     accessor: "status",
//   },
// ];

const ReviewPromiseTable = (call) => {
  const initalEditDetailModal = {
    formModal: false,
    formData: null,
  };
  const [editDetailModal, setEditDetailModal] = React.useState(
    initalEditDetailModal
  );

  // const [tableData, setTableData] = React.useState([]);
  const [columnData, setColumnData] = React.useState([]);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const getParams = getSearchParams.get("customers");

  let { currentOrganization } = useContext(MaxyfiContext);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  // const [querySortBy, setQuerySortBy] = useState("-_id");
  const [queryFilter, setQueryFilter] = useState({});
  const dispatch = useDispatch();
  const statusChanged = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );
  const [width, setWidth] = useState(0);

  let ref = React.useRef();
  const { id } = useParams();

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      PROMISE_TO_PAY,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        // sortBy: querySortBy,
        filters: queryFilter,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, pageSize, filters } = queryKey[1];
      return await getReviewPromiseDatas({
        organization: currentOrganization,
        promiseId: getParams,
        pageIndex,
        pageSize,
        filters,
      });
    }
  );

  const setEditDetailModalData = (formData) => {
    setEditDetailModal({
      formModal: true,
      formData,
    });
  };

  const resetEditDetailsModal = () => {
    setEditDetailModal({
      formModal: false,
      formData: null,
    });
    refetch();
  };

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "co_action_review_promise_to_pay",
      });

      setColumnData(userListVal.data.doc.table);
    };
    userListApi();
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth - 200));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: false,
      isExpandedHeader: false,
      width: width,
    });
    return columns;
  }, [columnData]);

  // const columns = useMemo(() => columnData, [columnData]);

  const tableData = useMemo(
    () => (isFetched && !isError && data && data.data.doc ? data.data.doc : []),
    [isFetched, data, statusChanged]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  // TODO TO CHANGE MIN AND MAX WIDTH
  return (
    <div style={{ padding: "0px 10px 10px 10px" }}>
      <div
        className="customer-profile-tab__table"
        style={{
          gridColumn: "1/6",
          gridRow: "2/13",
          overflowY: "unset",
          // height: "445px",
        }}
        ref={ref}
      >
        <div className="cp-tab__table-wrapper" {...getTableProps()}>
          <div className="cp-tab__table-header">
            {headerGroups.map((headerGroup) => (
              <>
                <div
                  className="cp-tab__table-header-wrapper-dispute_table"
                  style={{
                    backgroundColor: "#F9F9F9",
                    border: "1px solid #CDCED9",
                    display: "flex",
                    flex: "1 0 auto",
                    minWidth: "0px",
                    // borderStartEndRadius: "5px",
                  }}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  <div
                    className="cp-tab__table-header-content"
                    style={
                      {
                        // height: "60px",
                        // width: "50%",
                        // minWidth: "50%",
                        // maxWidth: "100%",
                      }
                    }
                  >
                    {headerGroup.headers.map((column, index) => (
                      <div
                        className="cp-tab__table-header-cell-content"
                        style={{
                          boxSizing: "border-box",
                          flex: "150 0 auto",
                          minWidth: "0px",
                          width: "150px",
                          position: "relative",
                        }}
                      >
                        <div
                          className="cp-tab__table-header-cell-review-promise"
                          // style={{ fontSize: "16px", fontWeight: "bold" }}
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </div>
                      </div>
                    ))}
                    <div
                      className="cp-tab__table-header-cell-content"
                      style={{
                        boxSizing: "border-box",
                        flex: "150 0 auto",
                        minWidth: "0px",
                        width: "150px",
                        position: "relative",
                      }}
                    >
                      <div
                        className="cp-tab__table-header-cell-review-promise"
                        // style={{ fontSize: "16px", fontWeight: "bold" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          {isLoading ? (
            <Loader />
          ) : tableData && tableData.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <div
              className="cp-tab__table-body"
              style={{ overflow: "none", height: "calc(100vh - 450px)" }}
              {...getTableBodyProps()}
            >
              {page.map((row) => {
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  <React.Fragment key={rowProps.key}>
                    <div
                      className="cp-tab__table-body-content-dispute cp-tab__table-body-content"
                      style={{ height: "60px" }}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <>
                            <div
                              className="cp-tab__table-body-cell"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                            {cell &&
                            cell.column &&
                            cell.column.id === "promise_amount" ? (
                              <div style={{ paddingLeft: "5px" }}>
                                <TextButton
                                  size="mini"
                                  type="button"
                                  kind={KIND.tertiary}
                                  onClick={() => {
                                    setEditDetailModalData(tableData[row.id]);
                                    // dispatch(reviewTablePopUp(true));
                                    // dispatch(
                                    //   reviewPromiseAction(tableData[row.id])
                                    // );
                                  }}
                                >
                                  <EditSvg size="16" />
                                </TextButton>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}

                      <div
                        className="cp-tab__table-body-cell"
                        style={{
                          width: "150px",
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "5px",
                        }}
                        role="cell"
                      >
                        <div>
                          <TextButton
                            size="mini"
                            type="button"
                            kind={KIND.tertiary}
                            onClick={() => {
                              dispatch(reviewTablePopUp(true));
                              dispatch(reviewPromiseAction(tableData[row.id]));
                            }}
                          >
                            <ChevronDown color="#516BEB" />
                          </TextButton>
                        </div>
                        {tableData[row.id] &&
                        tableData[row.id].promise_amount &&
                        tableData[row.id].promise_amount.value &&
                        tableData[row.id].status === "IN_PROGRESS" ? (
                          <div>
                            <TextButton
                              size="mini"
                              type="button"
                              kind={KIND.secondary}
                              onClick={() => {
                                const { _id, promise_amount } =
                                  tableData[row.id];

                                if (
                                  promise_amount &&
                                  promise_amount.value > 0 &&
                                  promise_amount.currency
                                ) {
                                  dispatch(
                                    instantPaymentDrawer({
                                      modal: true,
                                      amount: promise_amount.value,
                                      currency: promise_amount.currency,
                                      installment_id: _id,
                                    })
                                  );
                                }
                              }}
                            >
                              Pay Now <ChevronRight color="#516BEB" />
                            </TextButton>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {editDetailModal && editDetailModal.formModal ? (
        <UpdatePromiseDetails
          {...editDetailModal}
          setEditDetailModalData={setEditDetailModalData}
          resetEditDetailsModal={resetEditDetailsModal}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReviewPromiseTable;
