function OnSkipModalSvg() {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.37 16.335L11.025 10.23C11.865 9.63 11.865 8.37 11.025 7.785L2.37 1.665C1.365 0.975 0 1.68 0 2.895V15.105C0 16.32 1.365 17.025 2.37 16.335ZM15 1.5V16.5C15 17.325 15.675 18 16.5 18C17.325 18 18 17.325 18 16.5V1.5C18 0.675 17.325 0 16.5 0C15.675 0 15 0.675 15 1.5Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default OnSkipModalSvg;
