import React from "react";

const SuccessArrowAdhoc = () => {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.16729 0.541667C3.16729 0.845 3.41104 1.08333 3.70896 1.08333H6.73687L0.838125 6.98208C0.626875 7.19333 0.626875 7.53458 0.838125 7.74583C1.04937 7.95708 1.39063 7.95708 1.60188 7.74583L7.50062 1.84708V4.875C7.50062 5.17292 7.74437 5.41667 8.04229 5.41667C8.34021 5.41667 8.58396 5.17292 8.58396 4.875V0.541667C8.58396 0.24375 8.34021 0 8.04229 0H3.70896C3.41104 0 3.16729 0.24375 3.16729 0.541667Z"
        fill="#0FB158"
      />
    </svg>
  );
};

export default SuccessArrowAdhoc;
