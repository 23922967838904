import React from "react";

const CustomizeColumn = ({ size = "22", color = "" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.72266 15.3006V6.69812C3.72266 6.24238 3.88491 5.85227 4.20941 5.52777C4.53391 5.20327 4.92395 5.04102 5.37953 5.04102H17.6347C18.0904 5.04102 18.4805 5.20327 18.805 5.52777C19.1295 5.85227 19.2918 6.24238 19.2918 6.69812V15.3006C19.2918 15.7563 19.1295 16.1464 18.805 16.4709C18.4805 16.7954 18.0904 16.9577 17.6347 16.9577H5.37953C4.92395 16.9577 4.53391 16.7954 4.20941 16.4709C3.88491 16.1464 3.72266 15.7563 3.72266 15.3006ZM5.36555 15.5827H8.43982V6.41602H5.36555C5.29497 6.41602 5.23034 6.44542 5.17168 6.50424C5.11286 6.56291 5.08345 6.62754 5.08345 6.69812V15.3006C5.08345 15.3712 5.11286 15.4358 5.17168 15.4945C5.23034 15.5533 5.29497 15.5827 5.36555 15.5827ZM9.81482 15.5827H13.1712V6.41602H9.81482V15.5827ZM14.5462 15.5827H17.6207C17.6911 15.5827 17.7558 15.5533 17.8146 15.4945C17.8732 15.4358 17.9026 15.3712 17.9026 15.3006V6.69812C17.9026 6.62754 17.8732 6.56291 17.8146 6.50424C17.7558 6.44542 17.6911 6.41602 17.6207 6.41602H14.5462V15.5827Z"
        fill={color}
      />
    </svg>
  );
};

export default CustomizeColumn;
