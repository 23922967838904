import React, { useContext, useState } from "react";
import { updateHideupdatedTime, useCustomerNameEdit } from "../../services";
import { alloweSpecialCharacter } from "../../utils/regexConfig";
import { Icon, Typography } from "../../components_v2";
import { toast } from "react-toastify";
import { TextBox } from "../../components/TextBox";
import { useParams } from "react-router";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../../constants";
import { useMutation } from "react-query";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { CUSTOMER_NAME_EDIT } from "../../providers/RBACProvider/permissionList";
import queryClient from "../../providers/queryClient";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import formatRecentTime from "../../utils_v2/FormatTimeInDays";
import _ from "lodash";
import useFormat from "../../hooks/useFormat";
const CustomerNameEdit = ({ name = "" }) => {
  const { customerId } = useParams();
  const [isEditName, setIsEditName] = useState(false);
  const [isCustomerName, setIsCustomerName] = useState(name);
  const format = useFormat();
  let { currentOrganization } = useContext(MaxyfiContext);
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const { mutateAsync } = useCustomerNameEdit();

  const customerName = async (data) => {
    let splCharacter = data.match(alloweSpecialCharacter);
    if (splCharacter) {
      let value = {
        display_name: data,
      };
      await mutateAsync(
        { value, organization: currentOrganization, customerId },
        {
          onSuccess: (data) => {
            setIsEditName(false);
            queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
            queryClient.invalidateQueries(`${CO_TIMELINE_}${customerId}`);
          },
          onError: (errorData) => {
            toast.error(errorData.message);
          },
        }
      );
    } else {
      toast.error("Invalid input - Only Alphabets ' , . - and _ allowed.");
    }
  };
  const updateTagCustomer = useMutation(
    (data) => {
      return updateHideupdatedTime({
        customerId: customerId,
        organization: currentOrganization,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
      },
    }
  );

  return (
    <>
      {isEditName ? (
        <>
          <div className="mt-12">
            <TextBox
              onKeyPress={(res) => {
                if (res.key == "Enter") {
                  customerName(isCustomerName);
                }
              }}
              value={isCustomerName}
              onChange={(e) => setIsCustomerName(e.target.value)}
              size="mini"
            />
          </div>
          <Icon
            icon="tick"
            isPressable
            onClick={() => {
              customerName(isCustomerName);
            }}
          />
          <Icon
            icon="remove"
            isPressable
            size={14}
            onClick={() => {
              setIsEditName(false);
            }}
          />
        </>
      ) : (
        <>
          <Typography type="h2" className="co-customer-name">
            {name}
          </Typography>

          {_.get(customerData, "data.doc.is_upd_wrn", false) &&
            _.get(customerData, "data.doc.updated_at", null) && (
              <StatefulTooltip
                placement={PLACEMENT.bottom}
                showArrow
                overrides={{
                  Arrow: {
                    style: ({ $theme }) => ({
                      outline: `#16164B solid`,
                      backgroundColor: "#16164B",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      outline: `#16164B solid`,
                      backgroundColor: "#16164B",
                    }),
                  },
                }}
                content={() => (
                  <div
                    style={{
                      backgroundColor: "#16164B",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Typography
                      type="p"
                      subType="regular"
                      style={{ color: "white" }}
                    >
                      Consumer details Updated{" "}
                      <span
                        style={{
                          color: "white",
                          fontWeight: "500",
                        }}
                      >
                        {formatRecentTime(
                          _.get(customerData, "data.doc.updated_at", null)
                        )}{" "}
                        {_.get(customerData, "data.doc.updated_by", "")
                          ? "by"
                          : ""}{" "}
                        {_.get(customerData, "data.doc.is_adc_upd", false) &&
                        _.get(customerData, "data.doc.updated_by", "")
                          ? `Upload (${format.rd({
                              id: _.get(
                                customerData,
                                "data.doc.updated_by",
                                ""
                              ),
                              name: "users",
                            })})`
                          : format.rd({
                              id: _.get(
                                customerData,
                                "data.doc.updated_by",
                                ""
                              ),
                              name: "users",
                            })}
                      </span>
                    </Typography>
                    <div
                      style={{ border: "1px solid #787878", width: "100%" }}
                    ></div>
                    <Typography
                      type="p"
                      className="text-error"
                      subType="regular"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        await updateTagCustomer.mutateAsync({});
                      }}
                    >
                      Don't Show again
                    </Typography>
                  </div>
                )}
                returnFocus
                autoFocus
              >
                <Icon icon="updated_clock" size={18} color="#787878" />
              </StatefulTooltip>
            )}
          <RBACWrapper role={CUSTOMER_NAME_EDIT} type="PERMISSION">
            <Icon
              icon="edit"
              isPressable
              onClick={() => {
                setIsEditName(true);
              }}
            />
          </RBACWrapper>
        </>
      )}
    </>
  );
};

export default CustomerNameEdit;
