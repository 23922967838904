import React, { useCallback, useContext, useEffect } from "react";
import TitleHead from "../../../../../components_v2/TitleHead";
import { SelectBox } from "../../../../../components/SelectBox";
import { TextBox } from "../../../../../components/TextBox";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { useParams } from "react-router-dom";
import _, { debounce } from "lodash";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { manualGroupingCat, manualGroupingLink } from "../../../../../services";
import useFormat from "../../../../../hooks/useFormat";
import ManualGroupingCard from "./ManualGroupingCard";
import { KIND, TextButton } from "../../../../../components/TextButton";
import { useDispatch } from "react-redux";
import { setDrawerState } from "../../../../../redux/actions";
import noRecord from "../../../../../assets/img/commandcenter.png";
import { Icon, Typography } from "../../../../../components_v2";
import queryClient from "../../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  GET_GROUPING_LIST_DATA,
} from "../../../../../constants";
import Loader from "../../../../../components/Loader";
import getSelectValues from "../../../../../utils/getSelectValues";

const ManualGrouping = () => {
  const { customerId } = useParams();
  let dispatch = useDispatch();
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);
  const [isCategory, setIsCategory] = React.useState([]);
  const [isPreview, setIsPreview] = React.useState("");
  const [textValue, setTextValue] = React.useState("");
  const CUSTOMER_UID = "CUSTOMER_UID"; // Assuming this is the constant for comparison

  const [isSuccessData, setIsSuccessData] = React.useState({
    isValue: [],
    isGroup: [],
  });

  const [isGrouping, setIsGrouping] = React.useState([]);
  const format = useFormat();

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const addToGrouping = useMutation(
    (workflowData) =>
      manualGroupingLink({
        groupId: isGrouping,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        queryClient.refetchQueries([`${GET_GROUPING_LIST_DATA}-${customerId}`]);
        closeDrawer();
      },
    }
  );

  const useManualGrouping = useMutation(
    (workflowData) =>
      manualGroupingCat({
        ...workflowData,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        setIsSuccessData({
          isValue: data?.data?.doc?.value,
          isGroup: data?.data?.doc?.data,
        });
      },
    }
  );
  let regexPattern = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
  // Debounce function for TextBox
  const handleTextBoxChange = useCallback(
    debounce(async (value) => {
      if (
        _.get(isCategory, "[0].id", "") === CUSTOMER_UID &&
        regexPattern.test(value)
      ) {
        // Make API call only when category is CUSTOMER_UID
        await useManualGrouping.mutateAsync({
          val: value, // Pass the TextBox value
          category: isCategory[0]?.id, // Pass the selected category ID
        });
      }
    }, 800),
    [isCategory]
  );

  useEffect(() => {
    if (isSuccessData?.isValue?.length) {
      if (isCategory?.[0]?.id === "ADDRESS") {
        let previewValue = isSuccessData?.isValue?.map((e) =>
          Object.values(e).toString()
        );
        setIsPreview(previewValue);
      } else if (isCategory?.[0]?.id === "DOB") {
        let setDate = isSuccessData?.isValue?.map((e) =>
          format.date({ value: e })
        );
        setIsPreview(setDate);
      } else {
        setIsPreview(isSuccessData?.isValue);
      }
    } else {
      if (getSelectValues(isCategory) === "CUSTOMER_UID") {
        setIsPreview("");
      }
      setIsSuccessData({ ...isSuccessData, isValue: [] });
      setIsPreview("");
    }
  }, [useManualGrouping.isLoading, useManualGrouping.isSuccess]);

  const handleTextBoxInput = (event) => {
    const value = event.target.value;
    setTextValue(value);
    handleTextBoxChange(value); // Call debounce function
  };

  return (
    <div style={{ width: "540px" }}>
      <TitleHead title="Add Consumer for Grouping" icon="manual_group" />

      <div className="mg_textbox_container">
        <div style={{ width: "250px" }}>
          <SelectBox
            size={"mini"}
            value={isCategory}
            options={referenceData["manual_grouping_categories"]}
            onChange={async (params) => {
              setIsCategory(params.value);
              if (_.get(params, "value", [])[0]?.id !== "CUSTOMER_UID") {
                await useManualGrouping.mutateAsync({
                  category: _.get(params, "value.[0].id", ""),
                });
              }
            }}
            clearable={false}
            startEnhancer={<Icon icon="search" />}
            label="Category"
          />
        </div>

        {_.get(isCategory, "[0].id", "") === "CUSTOMER_UID" ? (
          <TextBox
            size={"mini"}
            value={textValue} // Controlled TextBox value
            onChange={handleTextBoxInput} // Handle input change with debounce
          />
        ) : (
          <TextBox
            size={"mini"}
            value={isPreview.toString()}
            onChange={handleTextBoxInput}
          />
        )}
      </div>

      {isSuccessData?.isGroup?.map((e) => {
        return (
          <ManualGroupingCard
            {...e}
            isGrouping={isGrouping}
            setIsGrouping={setIsGrouping}
            useManualGrouping={useManualGrouping}
          />
        );
      })}

      {isSuccessData?.isGroup?.length > 0 ? (
        <div style={{ textAlign: "end", marginTop: "10px" }}>
          <TextButton
            size="mini"
            kind={KIND.tertiary}
            onClick={() => closeDrawer()}
          >
            Cancel
          </TextButton>
          <TextButton
            size="mini"
            onClick={async () => {
              await addToGrouping.mutateAsync();
            }}
            disabled={isGrouping?.length > 0 ? false : true}
          >
            Add Grouping
          </TextButton>
        </div>
      ) : useManualGrouping.isLoading ? (
        <Loader />
      ) : isCategory &&
        isCategory.length > 0 &&
        isSuccessData?.isGroup?.length === 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ marginTop: "40px", color: "#ADADAD" }}
              type="h3"
            >
              No Records Found
            </Typography>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            flexDirection: "column",
          }}
        >
          <img src={noRecord} width={"30%"} />
          <Typography style={{ marginTop: "40px", color: "#ADADAD" }}>
            Choose required category to see records ...
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ManualGrouping;
