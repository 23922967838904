import {
  HeadingXSmall,
  LabelLarge,
  LabelXSmall,
  ParagraphMedium,
} from "baseui/typography";
import React, { useContext, useEffect, useState } from "react";
import Call from "../../assets/img/svg/CpCard/Call";
import Email from "../../assets/img/svg/CpCard/Email";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { StyledLink } from "baseui/link";
import Edit from "../../assets/img/svg/Template/Edit";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import { StatefulTooltip } from "baseui/tooltip";
import { useSelector, useDispatch } from "react-redux";
import {
  editContactModal,
  refetchActions,
  viewContactData,
} from "../../redux/customerOverview/action";
import // defaultContactData,
// deleteContactDetailsCustomerOverview,
"../../services";
import {
  deleteContactDetailsCustomerOverview,
  defaultContactData,
} from "../../services/customerOverviewContacts";
import { useParams } from "react-router-dom";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import { ModalContext } from "../../providers/ModalProvider";
import Warning from "../../assets/img/warning.png";
import { Checkbox } from "baseui/checkbox";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import AlertTag from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/AlertTag";
import ContactNoCode from "../../assets/img/svg/ContactNoCode";
import EditContact from "../../assets/img/svg/EditContact";
import DeletContact from "../../assets/img/svg/DeletContact";
import ContactSvg from "../../assets/img/svg/ContactSvg";
import User from "../../assets/img/svg/Upload/User";
import PrimaryContactUser from "../../assets/img/svg/PrimaryContactUser";
import EscalationContactUser from "../../assets/img/svg/EscalationContactUser";
import { contactUserSelection } from "../../services/invoiceOverview";
import { useSearchParams } from "react-router-dom";
import PrimaryUser from "../../assets/img/svg/PrimaryUser";
import EscalationUser from "../../assets/img/svg/EscalationUser";
import InvoiceUser from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/InvoiceUser";
import PrimaryContactActive from "../../assets/img/svg/PrimaryContactActive";
import EscalationContactActive from "../../assets/img/svg/EscalationContactActive";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  EDIT_CONTACTS,
  DELETE_CONTACTS,
  INVOICE_PRIMARY_ESCALATION,
} from "../../providers/RBACProvider/permissionList";
import { refetchInvoiceActions } from "../../redux/invoiceOverview/action";

const CpContactCard = (props) => {
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("inv");

  let { currentOrganization, referenceData } = useContext(MaxyfiContext);

  const deleteContactData = useMutation(
    (contactData) =>
      deleteContactDetailsCustomerOverview({
        ...contactData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        dispatch(refetchActions());
        props.refetch();
      },
    }
  );

  let {
    address,
    designation,
    email,
    last_name,
    phone = [],
    landline = [],
    is_default,
    country_code,
    customerId,
    first_name,
    primaryContact,
    setPrimaryContact,
  } = props;

  let designationValue = referenceData?.recipient_type?.find(
    ({ id }) => id == designation
  );

  let combinPhoneLandline =
    phone || landline
      ? [...phone, ...landline].filter(
          (e) =>
            (e.status === "VALID" || e.status === "LEAD") &&
            e.call_status === "VALID"
        )
      : [];

  let valiedEmail = email.filter(
    (e) =>
      (e.status === "VALID" || e.status === "LEAD") &&
      e.email_status === "VALID"
  );

  const userSelection = useMutation(
    (data) => {
      let userData = { contact_type: data.contact_type, invoice_id: getParams };
      return contactUserSelection({
        organization: currentOrganization,
        customerId: data.customerId,
        contactId: data.contact_id,
        userData,
      });
    },

    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        dispatch(refetchActions());
      },
    }
  );

  return (
    <div className="cp-card-conatiner" style={{ position: "relative" }}>
      <div
        className="cp-card__contact-head"
        // style={{ paddingTop: "7px" }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <ParagraphMedium
            $style={{ color: "#333860" }}
            className="contact-name"
            // className={props.entity === "INVOICE" ? "contact-name" : ""}
            // style={
            //   props.entity === "INVOICE"
            //     ? { color: "#333860" }
            //     : {
            //         paddingTop: "12px",
            //         paddingBottom: "5px",
            //         color: "#333860",
            //       }
            // }
          >
            {first_name}
          </ParagraphMedium>

          {props.entity === "INVOICE" ? (
            <>
              {props.is_primary_contact ? (
                <>
                  <div
                    className="contact-user"
                    onClick={() => {
                      dispatch(refetchInvoiceActions());
                    }}
                  >
                    <PrimaryUser />
                  </div>
                </>
              ) : (
                <></>
              )}

              {props.is_esclation_contact ? (
                <>
                  <div
                    className="contact-user"
                    onClick={() => {
                      dispatch(refetchInvoiceActions());
                    }}
                  >
                    <EscalationUser />
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="contact-btn">
          {props?.entity === "INVOICE" ? (
            <>
              <RBACWrapper role={INVOICE_PRIMARY_ESCALATION} type="PERMISSION">
                <div title="Mark as Primary Contact for this Invoice">
                  <IconButton
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    onClick={async () => {
                      // setCancelLoading(true);
                      await userSelection.mutateAsync({
                        customerId: props.customerId,
                        contact_type: "primary",
                        contact_id: props._id,
                      });
                    }}
                  >
                    {props?.is_primary_contact ? (
                      <>
                        <PrimaryContactActive />
                      </>
                    ) : (
                      <>
                        <PrimaryContactUser />
                      </>
                    )}
                  </IconButton>
                </div>
              </RBACWrapper>
              <RBACWrapper role={INVOICE_PRIMARY_ESCALATION} type="PERMISSION">
                <div title="Mark as Escalation Contact for this Invoice">
                  <IconButton
                    kind={KIND.tertiary}
                    size={SIZE.compact}
                    onClick={async () => {
                      await userSelection.mutateAsync({
                        customerId: props.customerId,
                        contact_type: "esclation",
                        contact_id: props._id,
                      });
                    }}
                  >
                    {props?.is_esclation_contact ? (
                      <>
                        <EscalationContactActive />
                      </>
                    ) : (
                      <>
                        <EscalationContactUser />
                      </>
                    )}
                  </IconButton>
                </div>
              </RBACWrapper>
            </>
          ) : (
            <></>
          )}
          <RBACWrapper role={EDIT_CONTACTS} type={"PERMISSION"}>
            <IconButton
              kind={KIND.tertiary}
              size={SIZE.compact}
              onClick={() => dispatch(editContactModal(props))}
            >
              <EditContact />
            </IconButton>
          </RBACWrapper>
          <RBACWrapper role={DELETE_CONTACTS} type={"PERMISSION"}>
            <IconButton
              onClick={() => {
                handleModal({
                  title: "",
                  content: "Are you sure, you want to delete this Contact?",
                  successCallback: () => {
                    deleteContactData.mutateAsync({
                      clientCode: "",
                      contactId: props._id,
                      customerId: customerId,
                    });
                  },
                  formContent: () => {
                    return <></>;
                  },
                  cancelCallback: () => {},
                  isChildren: false,
                  image: Warning,
                  successButtonType: "error",
                  buttonText: "Delete",
                  closeButtonText: "Cancel",
                  isCloseAble: false,
                });
              }}
              kind={KIND.tertiary}
              size={SIZE.compact}
            >
              <DeletContact />
            </IconButton>
          </RBACWrapper>
        </div>
      </div>
      <ParagraphMedium $style={{ color: "#ADADAD", lineHeight: "8px" }}>
        {designationValue?.label}
      </ParagraphMedium>
      <div className="cp-contact__d-flex">
        <>
          <div>
            <ContactSvg />
          </div>
          <ParagraphMedium $style={{ color: "333860", marginLeft: "10px" }}>
            {valiedEmail && valiedEmail[0] && valiedEmail[0].value
              ? valiedEmail[0].value
              : ""}
          </ParagraphMedium>
          {valiedEmail.length > 1 ? (
            <div className="cp-card__total-mail">
              <HeadingXSmall $style={{ color: "#516beb", lineHeight: "15px" }}>
                + {valiedEmail.length ? valiedEmail.length - 1 : 0}
              </HeadingXSmall>{" "}
            </div>
          ) : (
            ""
          )}
        </>
      </div>
      <div className="cp-contact__d-flex">
        {/* {phone.map((phones, i) => ( */}
        <>
          <div style={{ display: "flex" }}>
            <Call />
            <div style={{ display: "flex" }}>
              <ParagraphMedium $style={{ color: "333860", marginLeft: "10px" }}>
                {combinPhoneLandline &&
                combinPhoneLandline[0] &&
                combinPhoneLandline[0].country_code
                  ? combinPhoneLandline[0].country_code
                  : " "}
                {" - "}
                {combinPhoneLandline &&
                combinPhoneLandline[0] &&
                combinPhoneLandline[0].value
                  ? combinPhoneLandline[0].value
                  : ""}
              </ParagraphMedium>
              {(combinPhoneLandline &&
                combinPhoneLandline[0] &&
                combinPhoneLandline[0].country_code) ||
              !combinPhoneLandline[0] ? (
                ""
              ) : (
                <StatefulTooltip
                  content={() => <div>Country Code not available</div>}
                  returnFocus
                  autoFocus
                >
                  <div className="cp_alert__color">
                    <ContactNoCode />
                  </div>
                </StatefulTooltip>
              )}
            </div>
          </div>
          {combinPhoneLandline.length > 1 ? (
            <div className="cp-card__total-mail">
              <HeadingXSmall $style={{ color: "#516beb", lineHeight: "15px" }}>
                +{" "}
                {combinPhoneLandline.length
                  ? combinPhoneLandline.length - 1
                  : null}{" "}
                {(combinPhoneLandline &&
                  combinPhoneLandline[0] &&
                  combinPhoneLandline[0].country_code) ||
                !combinPhoneLandline[0] ? (
                  ""
                ) : (
                  // <div title="No Phone Code Available">
                  //   <ContactNoCode />
                  // </div>
                  <StatefulTooltip
                    content={() => <div>Country Code not available</div>}
                    returnFocus
                    autoFocus
                  >
                    <div className="cp_alert__color">
                      <ContactNoCode />
                    </div>
                  </StatefulTooltip>
                )}
              </HeadingXSmall>
            </div>
          ) : (
            <></>
          )}{" "}
        </>
      </div>
    </div>
  );
};

export default CpContactCard;
