import React from "react";

const RequiredIndicator = (props) => {
  return (
    <>
      {props.row.original.required ? (
        <div>{props.value} *</div>
      ) : (
        <div>{props.value}</div>
      )}
    </>
  );
};

export default RequiredIndicator;
