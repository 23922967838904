import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { TextBox } from "../../components/TextBox";
import { TextButton, KIND } from "../../components/TextButton";
import { useIntl } from "react-intl";
import { SelectBox, SIZE } from "../../components/SelectBox";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../components/TextArea";
import { ParagraphMedium, Label1, LabelSmall } from "baseui/typography";
import { useDropzone } from "react-dropzone";
import UploadFile from "../../assets/img/svg/UploadFile";
import UploadCustomerInvoice from "../../assets/img/svg/UploadCustomerIvoice";
import DeletePdf from "../../assets/img/svg/DeletePdf";
import { Input } from "baseui/input";
import getSelectValues from "../../utils/getSelectValues";
import moment from "moment";
import { Button } from "baseui/button";
import { useDispatch } from "react-redux";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { getDocumentData } from "../../services/documentSystem";
import {
  customerInvoiceOverview,
  editSubscription,
  getOrganization,
  getPaymentPriorityBilling,
  getSubscription,
  getSubscriptionSingleData,
} from "../../services";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { IconButton } from "../../components/IconButton";
import { getCommunicationTemplateList } from "../../services";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import Plus from "../../assets/img/svg/Upload/Plus";
import { refetchActions, subscriptionsDrawer } from "../../redux/actions";
import { getInvoiceData, putInvoice } from "../../services/invoiceOverview";
import {
  addingInvoiceDocumentFromDesk,
  addingInvoiceDrawerType,
  documentInvoiceAddingDrawerState,
  updatingInvoiceDeskDocument,
  unSelectedDocumentsAddingInvoice,
  addingInvoiceDocumentData,
} from "../../redux/invoiceAdding/dmsInvoiceAdding/action";
import { CUSTOMERPORTAL, EDIT, EMAIL } from "../../constants";
import EmailAttachment from "../../components/EmailAttachment/EmailAttachment";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import { invTaxRateMaintenanceList } from "../../services";
import { useQuery } from "react-query";
import { getInvoiceList } from "../../services/invoiceItem";
import setSelectValues from "../../utils/setSelectValues";
import { number } from "yup";
import { getCustomerContactDetail } from "../../services";
import {
  postNewInvoice,
  postNewRecurringInvoice,
} from "../../services/invoiceOverview";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Navigate, useSearchParams, useLocation } from "react-router-dom";
import { useNumericFormat } from "react-number-format";
import { customerData } from "../../services/customerSummary";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import Switch from "../../assets/img/svg/Switch";
import { useDebouncedCallback } from "use-debounce";
import { Spinner } from "baseui/icon";
import { decrypt } from "n-krypta";
import _ from "lodash";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
// need to decrypt name max-key-sk

// import { KIND } from "baseui/tag";

const AddNewInvoicingForm = ({
  selectedInvoiceId,
  setSelectedCustomerId,
  setSelectedInvCustomerId,
}) => {
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const getInvoiceParams = getSearchParams.get("invoice");
  const getParams = getSearchParams.get("customer");
  const getInvoice = getSearchParams.get("inv");
  const getSubscription = getSearchParams.get("subscription");
  const getType = getSearchParams.get("type");
  const getCustomerName = getSearchParams.get("dl");
  const [addNotes, setAddNotes] = useState(false);
  const [addNotesFocus, setAddNotesFocus] = useState(true);

  const [customerDatas, setCustomerData] = useState([]);
  const [addTerms, setAddTerms] = useState(false);
  const [addTermsFocus, setAddTermsFocus] = useState(true);

  const [addFooter, setAddFooter] = useState(false);
  const [addFooterFocus, setAddFooterFocus] = useState(true);

  const [acceptedFileValues, setAcceptedFileValue] = useState([]);

  const [invoiceSetting, setInvoiceSetting] = useState();
  const [taxRd, setTaxRd] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [changingIndex, setChangingIndex] = useState(null);
  const [changing, setChanging] = useState(1);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  const [overAllMonths, setOverAllMonths] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [documentGetData, setDocumentGetData] = useState([]);

  const navigate = useNavigate();

  // DOCUMENT ATTACHMENT STARTS
  const { addingInvoiceDrawerState, selectedDocumentData } = useSelector(
    (s) => s.invoiceAddingDocumentReducer
  );

  const { customerDetails } = useSelector((s) => s.customerOverviewReducer);

  const [isDebouncedValue, setDebouncedValue] = useState("");

  const debounceFilter = useDebouncedCallback((filter) => {
    setDebouncedValue(filter);
  }, 1000);

  let scanFinalStatus =
    selectedDocumentData &&
    selectedDocumentData.some((e) => e.scanStatus !== "SUCCESS");

  const selectedDocument =
    selectedDocumentData &&
    selectedDocumentData.map((e) => {
      return e.fileRef;
    });

  const {
    currentOrganization,
    referenceData,
    space,
    users,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    appAccessRest,
  } = useContext(MaxyfiContext);

  // const [typeOfNotes, setTypeOfNotes] = useState([]);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const currentOrgIsconfigured =
    space && space.organizations.find(({ id }) => id === currentOrganization);

  const timeZone = orgRef?.time_zone;

  // startEnhancer={`${
  //   currentDefaultFormatDetails &&
  //   currentDefaultFormatDetails.default_currency
  // }`}
  let dispatch = useDispatch();
  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  // const schema = Yup.object().shape({
  //   discount: Yup.number().when("total", {
  //     is: (val) => {
  //
  //       // return val && val[0] && val[0]?.id === "partial_amount" ? true : false;
  //     },
  //     then: (schema) =>
  //       Yup.number().max(discount.value, "Greater than Total Amount1"),
  //     otherwise: (schema) => schema.optional(),
  //   }),
  // });

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });

        dispatch(addingInvoiceDocumentFromDesk(arrObj));
      },
      // validator: uploadLogoValidation,
    });

  const fileSizeRejectionItems = fileRejections.map(({ file, errors }) => (
    <ul>
      {errors.map((e) => (
        <LabelSmall key={e.code} $style={{ color: "#FD372A" }}>
          {e.message}
        </LabelSmall>
      ))}
    </ul>
  ));
  // DOCUMENT ATTACHMENT STARTS

  const schema = Yup.object().shape({
    item: Yup.array(
      Yup.object({
        // description: Yup.string().required("Red"),
        product: Yup.array().min(1, "required").required("Required"),
        quantity: Yup.number()
          .min(1, "Quantity can't be 0")
          .required("Quantity can't be 0")
          .transform((value) => (isNaN(value) ? undefined : value)),
        cost: Yup.number()
          .min(1, "Cost can't be 0")
          .required("Cost can't be 0")
          .transform((value) => (isNaN(value) ? undefined : value)),
      })
    ),

    // .of()
    advance_amount: Yup.number()
      .max(Yup.ref("total"), "Shouldn't be Greater than Total Amount")
      .transform((value) => (isNaN(value) ? undefined : value)),

    // product: Yup.array().min(1, "required").required("Required"),
    discount: Yup.number()
      .test(
        ["discount", "total"],
        "Shouldn't be Greater than Total Amount",
        (discount, total) => {
          if (discount && total.parent) {
            let total_amt = total && total.parent && total.parent.total;
            let calculatedValue = Number(discount) + Number(total_amt);

            return discount <= calculatedValue;
          }
          return true;
        }
      )
      .transform((value) => (isNaN(value) ? undefined : value)),

    // discount: Yup.number()
    //   .max(Yup.ref("total"), "Shouldn't be Greater than Total Amount")
    //   .transform((value) => (isNaN(value) ? undefined : value)),
    invoice_due_date: Yup.date().min(
      Yup.ref("invoice_date"),
      "Should't less than Due date"
    ),
    customer: Yup.array().min(1, "Required").required("Required"),
    recover_from_customer: Yup.array().min(1, "Required").required("Required"),
    invoice_date: Yup.date().required("Required").nullable(),
    payment_term: Yup.array().min(1, "Required").required("Required"),
    // linked_invoices: Yup.array().min(1, "Required").required("Required"),
    invoice_due_date: Yup.date().required("Required").nullable(),
    ...(getInvoiceParams === "charge-form"
      ? {
          charge_type: Yup.array().min(1, "Required").required("Required"),
        }
      : {}),

    ...(getInvoiceParams === "recurring-form"
      ? {
          // next_execution_date: Yup.date().required("Required").nullable(),
          name: Yup.string().required(),
          frequency: Yup.array()
            .min(1, "Required")
            .required("Required")
            .nullable(),
          start_date: Yup.date().required("Required").nullable(),
          next_execution_date: Yup.date()
            // .when(["start_date", "end_date"], {
            //   is: (val) => {
            //
            //   },
            //   then: (schema) => Yup.object({}),
            // }),
            .min(Yup.ref("start_date"), "Should't less than Start date")
            .test(
              "end_date",
              "Shouldn't be Greater than End Date",
              (val, parent) => {
                // let endDate = Yup.ref("end_date").getter();
                let endDate = parent && parent.parent && parent.parent.end_date;
                let NextExecution = moment(val).isValid();
                let validEndDate =
                  endDate && moment(endDate).isValid() ? true : false;

                if ((endDate && validEndDate && endDate > val) || !endDate) {
                  return true;
                }
                return false;
              }
            )
            // .max(Yup.ref("end_date"), "Should't Greater than End date")
            .required("Required")
            .nullable(),
          // payment_term: Yup.array().required("Required"),
        }
      : {}),
    // payment_term: Yup.array().required("Required"),
  });

  let intl = useIntl();
  const {
    control,
    register,
    handleSubmit,
    unregister,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    resolver: yupResolver(schema),

    defaultValues: {
      invoice_terms: "",
      invoice_notes: "",
      invoice_footer: "",
      internal_notes: "",
      ...(getInvoice || getSubscription
        ? {}
        : {
            item: [{ item: "", total: 0, quantity: 1, cost: 0 }],
          }),
      type_of_notes: [{ id: "", label: "None" }],
      recover_from_customer: [
        ...(getType == "ADD-CHARGE"
          ? [{ id: "CLIENT_AND_CUSTOMER", label: "Client & Customer" }]
          : [{ id: "CUSTOMER", label: "Customer" }]),
      ],
      frequency: [{ id: "MONTH", label: "Month" }],
      start_date: new Date(),
      invoice_date: new Date(),
      payment_term: [
        ...(getType == "ADD-CHARGE"
          ? [{ id: "DUE_ON_RECEIPT", label: "Due on Receipt" }]
          : []),
      ],
      // next_execution_date: new Date(),
    },
  });

  let {
    item,
    subtotal,
    start_date,
    payment_term,
    invoice_date,
    total,
    paid,
    balance_due,
    customer,
    type_of_notes,
    discount,
    charge_type,
  } = watch();

  //

  let type = getSelectValues(type_of_notes);

  const invoiceDataMock = useQuery(
    [
      `CUSTOMER_CHARGE_INVOICE-${customer && customer[0] && customer[0].id}`,
      {
        pageIndex: 0,
        pageSize: 0,
        sortBy: "-_id",
        exclude: "ALL",
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, exclude } = queryKey[1];
      if (customer && customer[0] && customer[0].id) {
        return await customerInvoiceOverview({
          pageIndex,
          sortBy,
          pageSize,
          exclude,
          organization: currentOrganization,
          customer_id: customer && customer[0] && customer[0].id,
        });
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  // useEffect(()=>{

  // },[type])

  let invoiceDate = invoice_date;

  let customerId = getSelectValues(customer);

  const createInvoice = useMutation(
    (data) =>
      postNewInvoice({
        ...data,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();

        // navigate(`${"/customers"}`);
        setValue("item", []);
        // setAddSearchParams({ customers: `${getParams}` });
        // if (getType === "ADD-INV") {
        //   setAddSearchParams({ customers: `${getParams}` });
        // }
        // if (getType === "ADD-CHARGE") {
        //   setAddSearchParams({ customers: `${getParams}` });
        // }
        // if (getType === "INV-ADD-INV") {
        //   navigate("/invoices");
        // }
        navigate(-1);
        dispatch(refetchActions());
        // getParams
        //   ? setAddSearchParams({ customers: `${getParams}` })
        //   : navigate("/invoices");
      },
    }
  );

  const editInvoice = useMutation(
    (data) =>
      putInvoice({
        ...data,
        organization: currentOrganization,
        invoiceId: getInvoice,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        // if (getType === "EDIT-INV" || getType == "EDIT-CHARGE") {
        //   setAddSearchParams({ customers: `${getParams}` });
        // }
        navigate(-1);
        // navigate(`${"/customers"}`);
        setValue("item", []);
        dispatch(refetchActions());

        // setAddSearchParams({ customers: `${getParams}` });
        // getParams
        //   ? setAddSearchParams({ customers: `${getParams}` })
        //   : navigate("/invoices");
      },
    }
  );

  const editSubscriptionData = useMutation(
    (data) =>
      editSubscription({
        ...data,
        organization: currentOrganization,
        customerId: getParams,
        subscriptionId: getSubscription,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        // navigate(`${"/customers"}`);
        setValue("item", []);
        // setAddSearchParams({ customers: `${getParams}` });
        // getParams
        // ?
        navigate(-1);
        // setAddSearchParams({ customers: `${getParams}` });
        dispatch(refetchActions());

        //   : navigate("/invoices");
      },
    }
  );

  const postRecurringInvoices = useMutation(
    (data) =>
      postNewRecurringInvoice({
        organization: currentOrganization,
        // customerId: getParams,
        customerId: customerId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        setValue("item", []);
        navigate(-1);
        // if (getParams) {
        //   dispatch(subscriptionsDrawer(true));
        //   setAddSearchParams({ customers: `${getParams}` });
        // } else {
        //   navigate(-1);
        // }
        dispatch(refetchActions());

        // setAddSearchParams({ customers: `${getParams}` });
      },
    }
  );

  const { fields, append, remove, update, insert } = useFieldArray({
    control,
    name: "item",
  });

  const skipData = useFieldArray({
    control,
    name: "skip",
  });

  let invoiceTitle;
  if (getType === "ADD-INV") {
    invoiceTitle = "DEPT";
  }
  if (getType === "INV-ADD-INV") {
    invoiceTitle = "DEPT";
  }
  if (getType === "ADD-CHARGE") {
    invoiceTitle = "CHARGE";
  }
  // if (getType === "ADD-REC") {
  //   invoiceTitle = "add_recurring_invoice";
  // }
  // if (getType === "EDIT-INV") {
  //   invoiceTitle = "edit_invoice";
  // }
  // if (getType === "EDIT-SUB") {
  //   invoiceTitle = "edit_recurring";
  // }

  // SUBMIT
  const onSubmit = async (data) => {
    let skipDuration = [];
    if (start_date) {
      for (let i of data.skip) {
        let year = i && i[0] && i[0].year;
        let session = i && i[0] && i[0].session;
        if (year && session) {
          skipDuration.push({ year: year, month: session });
        }
      }
    }

    //

    let lineItem = [];
    if (data && data.item) {
      for (let i of data.item) {
        // let taxes = getSelectValues(i);

        const product = i.product && i.product[0];

        if (product) {
          //
          lineItem.push({
            item_id: product._id,
            name: product.name,
            description: i.description,
            unit_cost: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(i.cost),
            },
            quantity: Number(i.quantity),
            taxes: i.taxes?.reduce((e, i) => {
              if (i && i[0] && i[0].id) {
                // return [...e, i[0].id];
                return [
                  ...e,
                  {
                    tax_id: i && i[0].id,
                    name: i && i[0].tax_name,
                    percentage: i && i[0].value,
                    // value: i && i[0].value,
                  },
                ];
              }
              return e;
            }, []),

            tax_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: isNaN(i.line_item_tax) ? 0 : Number(i.line_item_tax),
            },
            line_total_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(i.total),
            },
          });
        }
      }
    }

    let billingAddress =
      data && data.billing_address && data.billing_address[0];
    if (billingAddress) {
      delete billingAddress.id;
      delete billingAddress.label;
    }

    let shippingAddress =
      data && data.shipping_address && data.shipping_address[0];
    if (shippingAddress) {
      delete shippingAddress.id;
      delete shippingAddress.label;
    }
    // if (invoiceData) {
    //
    //   delete data.frequency;
    //   delete data.next_execution_date;
    //   delete data.start_date;
    // }
    let values = {
      type: invoiceTitle,
      ...(selectedDocument && selectedDocument.length > 0
        ? {
            attachments: selectedDocument,
          }
        : {}),
      ...(getInvoice
        ? {}
        : {
            customer_id: customerId,
          }),

      recover_from: getSelectValues(data.recover_from_customer),

      ...(billingAddress
        ? {
            billing_address: billingAddress,
          }
        : {}),
      ...(shippingAddress
        ? {
            shipping_address: shippingAddress,
          }
        : {}),
      ...(getInvoice
        ? {}
        : {
            invoice_currency: currentDefaultFormatDetails.default_currency,
          }),

      invoice_date: moment(data.invoice_date).startOf("day").utc().valueOf(),
      payment_term: getSelectValues(data.payment_term),
      invoice_due_date: moment(data.invoice_due_date)
        .startOf("day")
        .utc()
        .valueOf(),
      ...(data.advance_amount
        ? {
            advance_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.advance_amount),
            },
          }
        : {}),
      po_number: data.po_number,

      ...(data.discount
        ? {
            discount_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.discount),
            },
          }
        : {}),
      other_information: data.other_information,
      line_items: lineItem,
      ...(data.subtotal
        ? {
            sub_total_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.subtotal),
            },
          }
        : {}),
      ...(data.tax
        ? {
            taxes: data.tax?.reduce((e, i) => {
              if (i && i[0] && i[0].id) {
                // return [...e, i[0].id];
                return [
                  ...e,
                  {
                    tax_id: i && i[0].id,
                    name: i && i[0].name,
                    percentage: i && i[0].value,
                  },
                ];
              }
              return e;
            }, []),
          }
        : {}),
      ...(data.totalTax
        ? {
            tax_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: isNaN(data.totalTax) ? 0 : Number(data.totalTax),
            },
          }
        : {}),
      ...(data.total
        ? {
            total_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.total),
            },
          }
        : {}),
      invoice_terms: data.invoice_terms,
      invoice_notes: data.invoice_notes,
      invoice_footer: data.invoice_footer,
      ...(data.type_of_notes &&
      data.type_of_notes[0] &&
      !data.type_of_notes[0].id
        ? {
            notify_customer_via: "",
          }
        : {
            notify_customer_via: getSelectValues(data.type_of_notes),
          }),
      ...(data.template
        ? {
            notify_customer_via_template: getSelectValues(data.template),
          }
        : {}),
      internal_notes: data.internal_notes,
      conversion_rate: 1,
    };

    let recurring = {
      ...(skipDuration && skipDuration.length > 0
        ? { skip_durations: skipDuration }
        : {}),

      ...(selectedDocument && selectedDocument.length > 0
        ? {
            attachments: selectedDocument,
          }
        : {}),
      name: data.name,
      recover_from: getSelectValues(data.recover_from_customer),
      // notify_customer_via: "",
      ...(billingAddress
        ? {
            billing_address: billingAddress,
          }
        : {}),
      ...(shippingAddress
        ? {
            shipping_address: shippingAddress,
          }
        : {}),
      invoice_currency: currentDefaultFormatDetails.default_currency,
      frequency: getSelectValues(data.frequency),
      ...(getSubscription
        ? {
            next_execution_date: moment(data.next_execution_date)
              ?.tz(timeZone)
              ?.startOf("day")
              .utc()
              .valueOf(),
          }
        : {
            start_date: moment(data.start_date)
              ?.tz(timeZone)
              ?.startOf("day")
              .utc()
              .valueOf(),
          }),
      next_execution_date: moment(data.next_execution_date)
        ?.tz(timeZone)
        ?.startOf("day")
        .utc()
        .valueOf(),

      ...(data.end_date
        ? // && getType !== "EDIT-SUB"
          {
            end_date: moment(data.end_date)
              .startOf("day")
              .utc()
              ?.tz(timeZone)
              ?.valueOf(),
          }
        : {}),
      // ...(!data.end_date && getType === "EDIT-SUB"
      //   ? {
      //       // end_date: moment(data.end_date).startOf().utc().valueOf(),
      //       end_date: undefined,
      //     }
      //   : {
      //       end_date: undefined,
      //     }),
      payment_term: getSelectValues(data.payment_term),
      po_number: data.po_number,

      ...(data.discount
        ? {
            discount_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.discount),
            },
          }
        : {}),
      other_information: data.other_information,
      line_items: lineItem,

      ...(data.subtotal
        ? {
            sub_total_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.subtotal),
            },
          }
        : {}),
      ...(data.tax
        ? {
            taxes: data.tax?.reduce((e, i) => {
              if (i && i[0] && i[0].id) {
                // return [...e, i[0].id];
                return [
                  ...e,
                  {
                    tax_id: i && i[0].id,
                    name: i && i[0].name,
                    percentage: i && i[0].value,
                  },
                ];
              }
              return e;
            }, []),
          }
        : {}),
      ...(data.totalTax
        ? {
            tax_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: isNaN(data.totalTax) ? 0 : Number(data.totalTax),
            },
          }
        : {}),
      ...(data.total
        ? {
            total_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.total),
            },
          }
        : {}),
      invoice_terms: data.invoice_terms,
      invoice_notes: data.invoice_notes,
      invoice_footer: data.invoice_footer,
      // notify_customer_via: getSelectValues(data.type_of_notes),
      ...(data.type_of_notes &&
      data.type_of_notes[0] &&
      !data.type_of_notes[0].id
        ? {
            notify_customer_via: "",
          }
        : {
            notify_customer_via: getSelectValues(data.type_of_notes),
          }),

      ...(data.template
        ? {
            notify_customer_via_template: getSelectValues(data.template),
          }
        : {}),
      internal_notes: data.internal_notes,
      conversion_rate: 1,
    };

    let chargevalues = {
      type: invoiceTitle,
      ...(data && data.charge_type
        ? { charge_type: getSelectValues(data.charge_type) }
        : {}),
      ...(selectedDocument && selectedDocument.length > 0
        ? {
            attachments: selectedDocument,
          }
        : {}),
      ...(getInvoice
        ? {}
        : {
            customer_id: customerId,
          }),

      recover_from: getSelectValues(data.recover_from_customer),

      ...(billingAddress
        ? {
            billing_address: billingAddress,
          }
        : {}),
      ...(shippingAddress
        ? {
            shipping_address: shippingAddress,
          }
        : {}),
      ...(getInvoice
        ? {}
        : {
            invoice_currency: currentDefaultFormatDetails.default_currency,
          }),

      ...(data && data.linked_invoices
        ? { linked_invoices: getSelectValues(data.linked_invoices, true) }
        : {}),

      ...(data && data.linked_invoices
        ? { linked_invoice_numbers: data.linked_invoices.map((e) => e.label) }
        : {}),

      invoice_date: moment(data.invoice_date).startOf("day").utc().valueOf(),
      payment_term: getSelectValues(data.payment_term),
      invoice_due_date: moment(data.invoice_due_date)
        .startOf("day")
        .utc()
        .valueOf(),
      ...(data.advance_amount
        ? {
            advance_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.advance_amount),
            },
          }
        : {}),
      po_number: data.po_number,

      ...(data.discount
        ? {
            discount_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.discount),
            },
          }
        : {}),
      other_information: data.other_information,
      line_items: lineItem,
      ...(data.subtotal
        ? {
            sub_total_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.subtotal),
            },
          }
        : {}),
      ...(data.tax
        ? {
            taxes: data.tax?.reduce((e, i) => {
              if (i && i[0] && i[0].id) {
                // return [...e, i[0].id];
                return [
                  ...e,
                  {
                    tax_id: i && i[0].id,
                    name: i && i[0].name,
                    percentage: i && i[0].value,
                  },
                ];
              }
              return e;
            }, []),
          }
        : {}),
      ...(data.totalTax
        ? {
            tax_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: isNaN(data.totalTax) ? 0 : Number(data.totalTax),
            },
          }
        : {}),
      ...(data.total
        ? {
            total_amount: {
              currency: currentDefaultFormatDetails.default_currency,
              value: Number(data.total),
            },
          }
        : {}),
      invoice_terms: data.invoice_terms,
      invoice_notes: data.invoice_notes,
      invoice_footer: data.invoice_footer,
      ...(data.type_of_notes &&
      data.type_of_notes[0] &&
      !data.type_of_notes[0].id
        ? {
            notify_customer_via: "",
          }
        : {
            notify_customer_via: getSelectValues(data.type_of_notes),
          }),
      ...(data.template
        ? {
            notify_customer_via_template: getSelectValues(data.template),
          }
        : {}),
      internal_notes: data.internal_notes,
      conversion_rate: 1,
    };

    if (getType === "ADD-CHARGE") {
      return await createInvoice.mutateAsync({ ...chargevalues });
    }

    if (getType === "ADD-INV" || getType === "INV-ADD-INV") {
      return await createInvoice.mutateAsync({ ...values });
    }
    if (
      getType === "ADD-REC" ||
      getType === "INV-ADD-SUB" ||
      getType === "INV-ADD-REC"
    ) {
      await postRecurringInvoices.mutateAsync({ ...recurring });
    }

    if (
      getType === "EDIT-INV" ||
      getType === "INV-EDIT-INV" ||
      getType == "EDIT-CHARGE"
    ) {
      let { ...editValues } = values;
      await editInvoice.mutateAsync({ ...editValues });
    }

    if (getType === "EDIT-SUB") {
      await editSubscriptionData.mutateAsync({ ...recurring });
    }

    // switch (getType) {
    //   case "ADD-INV":
    //     return await createInvoice.mutateAsync({ ...values });
    //     break;

    //   default:
    //     null;
    // }

    // await editInvoice.mutateAsync({ ...values });
    // await editSubscriptionData.mutateAsync({ ...recurring });

    // {
    //   getInvoiceParams === "invoice-form"
    //     ? await createInvoice.mutateAsync({ ...values })
    //     : await postRecurringInvoices.mutateAsync({ ...recurring });
    // }
  };
  const slicedValue = acceptedFileValues.slice(0, 4);

  const communicationList = useQuery(
    [
      `COMMUNICATION_TEMPLATE-${currentOrganization}`,
      {
        filters: { type: type, disable: false, tags: `INVOICE` },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
        sort: "name",
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  // let communicationListData =
  //   communicationList &&
  //   communicationList.data &&
  //   communicationList.data.data.docs;
  //
  useEffect(() => {
    getData();
    //TODO: PRAVEEN FAILURE ERROR HANDLING
  }, []);
  // API CALLS
  const getData = async () => {
    try {
      await getOrganization({ organization: currentOrganization }).then(
        (res) => {
          setInvoiceSetting(
            (res.data &&
              res.data.doc &&
              res.data.doc?.invoice_setting &&
              res.data.doc?.invoice_setting?.invoice_tax_setting) ||
              {}
          );
        }
      );
    } catch (err) {}
    try {
      await invTaxRateMaintenanceList({
        organization: currentOrganization,
        sortBy: "name",
      }).then((res) => {
        setTaxRd((res.data && res.data?.docs) || {});
      });
    } catch (err) {}

    try {
      await getInvoiceList({
        organization: currentOrganization,
        sortBy: "name",
      }).then((res) => {
        setInvoiceList(res && res.data && res.data.docs);
      });
    } catch (err) {}
  };

  // QUERY

  // const documentData = useQuery(
  //   [`document-management-system-${getParams}`],
  //   async ({ queryKey }) => {
  //     let { sort } = queryKey[1];
  //
  //     return await getDocumentData({
  //       organization: currentOrganization,
  //       customerId: getParams,
  //       sort,
  //     });
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );
  //

  const paymentPriority = useQuery(
    [`PAYMENT-PRIORITY-BILLING-${currentOrganization}`],
    async () => {
      return await getPaymentPriorityBilling({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      charge_type &&
      charge_type[0] &&
      charge_type[0].id &&
      _.get(paymentPriority, "data.data.docs", [])?.length > 0
    ) {
      let findSubType = _.get(paymentPriority, "data.data.docs", []).find(
        (e) => e.typ === "CHARGE" && e.styp === charge_type[0].id
      );

      if (findSubType && findSubType.styp) {
        setValue("recover_from_customer", [
          { id: findSubType && findSubType.rf },
        ]);
      }
    }
  }, [charge_type && charge_type[0] && charge_type[0].id]);

  const [isSearchBy, setIsSearchBy] = useState("");

  useEffect(() => {
    if (getParams && customerDetails && customerDetails.display_name) {
      debounceFilter(customerDetails.customer_id);
      // setIsSearchBy(customerDetails.customer_id);
    }
  }, [getParams]);

  const customerListData = useQuery(
    [
      `${"CUSTOMER_OVERVIEW_LIST_CUSTOMER"}-${currentOrganization}`,
      {
        pageSize: 25,
        isDebouncedValue,
        filters: {
          exclude: "ALL",
          sort: "display_name",
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, isDebouncedValue } =
        queryKey[1];
      let newFilter = {
        combinator: "and",
        ...(getParams
          ? {
              rules: [
                {
                  operator: "contains",
                  value: isDebouncedValue,
                  id: "30596e01-22eb-4af0-a122-53f35ce3676f",
                  ...(isSearchBy && isSearchBy.length > 0
                    ? { field: "display_name" }
                    : { field: "customer_id" }),
                  valueSource: "value",
                },
              ],
            }
          : isSearchBy && isSearchBy.length > 0
          ? {
              rules: [
                {
                  operator: "contains",
                  value: isDebouncedValue,
                  id: "8fd3ed39-52ec-4e5e-986e-6829a8d0d047",
                  field: "display_name",
                  valueSource: "value",
                },
              ],
            }
          : { rules: [] }),
      };
      return await customerData({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
        customerFilter: newFilter,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      getType === "ADD-INV" ||
      getType === "ADD-REC" ||
      getType === "INV-EDIT-INV" ||
      getType === "ADD-CHARGE"
    ) {
      setAddNotes(false);
      setAddTerms(false);
      setAddFooter(false);
    }
  }, [getType]);

  useEffect(() => {
    let customerListDatas =
      customerListData &&
      customerListData.data &&
      customerListData.data.data &&
      customerListData.data.data.docs;

    if (
      customerListData &&
      customerListData.data &&
      customerListData.data.data &&
      customerListData.data.data.docs
    ) {
      let cusData =
        customerListData &&
        customerListData.data &&
        customerListData.data.data &&
        customerListData.data.data.docs.find((e) => {
          return e?._id === getParams;
        });

      if (
        getParams
          ? setValue("customer", [
              { id: cusData?._id, label: cusData?.display_name },
            ])
          : []
      );
      if (getParams && getCustomerName) {
        setValue("customer", [{ id: getParams, label: getCustomerName }]);
      }

      setCustomerData(customerListDatas || {});

      if (cusData) {
        setSelectedCustomerId(cusData && cusData._id);
      }
    }
  }, [customerListData?.data, getType, getParams]);

  //

  const { isLoading, data, refetch, isFetching } = useQuery(
    [`customer-contacts-${customer && customer[0] && customer[0].id}`],
    async () => {
      if (customer) {
        return await getCustomerContactDetail({
          organization: currentOrganization,
          customerId: customer && customer[0] && customer[0].id,
        });
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      //
      let customerDatas = data && data.data && data.data.docs;

      let cusAddress = [];
      let AddressDatas =
        customerDatas &&
        customerDatas.map((e, i) => {
          return (
            e &&
            e.address.map((a) => {
              return cusAddress.push({
                name: e.first_name,
                line_1: a?.line_1 ? a.line_1 : "",
                line_2: a?.line_2 ? a.line_2 : "",
                city: a.city,
                state: a.state,
                country: a.country,
                zip_code: `${a.zip_code}`,
              });
            })
          );
        });
      setCustomerAddress(cusAddress);

      // setCustomerAddress(data && data.data && data.data.docs);
    }
  }, [data]);

  useEffect(() => {
    let months = [];
    if (start_date) {
      let toDate = moment(start_date);
      Array.from({
        length:
          // fee_subscription_id &&
          // fee_subscription_id[0].frequency === "QUARTERLY"
          //   ? 4
          //   :

          12,
      }).map((e, i) => {
        months.push({
          label: `${toDate.add(1, "month").format("MMM")} ${toDate.get(
            "year"
          )}`,
          id: toDate.get("month"),
          session: toDate.get("month") + 1,
          year: toDate.get("year"),
        });
      });
    }
    setOverAllMonths(months);
  }, [start_date]);

  useEffect(() => {
    if (
      start_date &&
      !["EDIT-INV", "EDIT-SUB", "INV-EDIT-INV"].includes(getType)
    ) {
      setValue("next_execution_date", moment(start_date).toDate());
    }
  }, [start_date]);

  useEffect(() => {
    if (
      (customer && getType === "INV-ADD-INV") ||
      getType === "INV-ADD-REC" ||
      getType === "INV-EDIT-INV"
    ) {
      setSelectedInvCustomerId(customerId);
    } else {
      if (customer) {
        setSelectedCustomerId(customerId);
      }
    }
  }, [customerId]);

  // INVOICE API
  useEffect(() => {
    const getInvoiceDataVal = async () => {
      // dispatch(resetDiaryNoteReduxState());
      if (getInvoice) {
        await getInvoiceData({
          invoiceId: getInvoice,
          organization: currentOrganization,
        }).then((res) => {
          setInvoiceData(res && res.data && res.data.docs);
          // dispatch(getInvoiceCardData(res.data.docs));
        });
      }
    };
    getInvoiceDataVal();
  }, [getInvoice]);

  // SUBSCRIPTION API
  useEffect(() => {
    const getSubscriptionData = async () => {
      // dispatch(resetDiaryNoteReduxState());
      if (getSubscription) {
        await getSubscriptionSingleData({
          // invoiceId: getInvoice,
          customerId: getParams,
          organization: currentOrganization,
          subscriptionId: getSubscription,
        }).then((res) => {
          setSubscriptionData(res && res.data && res.data.doc);
          // dispatch(getInvoiceCardData(res.data.docs));
        });
      }
    };
    getSubscriptionData();
  }, [getSubscription]);

  useEffect(() => {
    if (invoiceDate && payment_term && payment_term[0]) {
      let paymentTerm = getSelectValues(payment_term);
      let invoiceDate = invoice_date;

      switch (paymentTerm) {
        case "DUE_ON_RECEIPT":
          setValue("invoice_due_date", moment(invoiceDate).toDate());
          break;
        case "DUE_END_OF_MONTH": {
          setValue(
            "invoice_due_date",
            moment(invoiceDate).endOf("month").toDate()
          );
          break;
        }
        case "DUE_END_OF_NEXT_MONTH": {
          setValue(
            "invoice_due_date",
            moment(invoiceDate).add(1, "month").endOf("month").toDate()
          );
          break;
        }

        case "NET_FIFTEEN": {
          setValue(
            "invoice_due_date",
            moment(invoiceDate).add(15, "days").toDate()
          );

          // dueDate = invoiceDateObj.add(15, "days");
          break;
        }
        case "NET_THIRTY": {
          setValue(
            "invoice_due_date",
            moment(invoiceDate).add(30, "days").toDate()
          );
          break;
        }
        case "NET_FOURTY_FIVE": {
          setValue(
            "invoice_due_date",
            moment(invoiceDate).add(45, "days").toDate()
          );
          break;
        }
        case "NET_SIXTY": {
          setValue(
            "invoice_due_date",
            moment(invoiceDate).add(60, "days").toDate()
          );
          break;
        }

        default: {
          setValue(
            "invoice_due_date",
            moment(invoiceDate).add(paymentTerm, "days").toDate()
          );
          break;
        }
      }

      // return moment(invoiceDate).valueOf();
    }
  }, [payment_term]);

  const computeTotal = () => {
    const {
      subtotal = 0,
      totalTax = 0,
      discount = 0,
      advance_amount = 0,
    } = getValues();
    const { invoice_total_amount, not_paid_amount } = invoiceData;

    const totalInvoiceAmount =
      Number(subtotal) - Number(discount) + Number(totalTax);

    let balanceDue = totalInvoiceAmount - advance_amount;

    if (invoice_total_amount && not_paid_amount) {
      let invoiceTotal =
        invoice_total_amount.value && !isNaN(invoice_total_amount.value)
          ? invoice_total_amount.value
          : 0;
      let notPaidTotal =
        not_paid_amount.value && !isNaN(not_paid_amount.value)
          ? not_paid_amount.value
          : 0;
      balanceDue = balanceDue - (Number(invoiceTotal) - Number(notPaidTotal));
    }

    setValue("total", Number(totalInvoiceAmount).toFixed(2));
    if (!["EDIT-INV", "EDIT-SUB", "INV-EDIT-INV"].includes(getType)) {
      setValue("paid", Number(advance_amount).toFixed(2));
    }
    setValue("balance_due", Number(balanceDue).toFixed(2));
  };

  const computeInvoiceTaxAmount = () => {
    const {
      subtotal = 0,
      discount = 0,
      tax = [],
      total_line_item_tax = 0,
    } = getValues();

    const totalTaxPercentage = tax?.reduce((e, i) => {
      if (i && i[0] && i[0].value) {
        return e + i[0].value;
      }
      return e;
    }, 0);

    const totalInvoiceTaxAmount =
      ((subtotal - Number(discount)) / 100) * totalTaxPercentage;

    setValue("totalInvoiceTax", Number(totalTaxPercentage).toFixed(2));
    setValue(
      "totalTax",
      Number(
        Number(totalInvoiceTaxAmount) + Number(total_line_item_tax)
      ).toFixed(2)
    );

    computeTotal();
  };

  const computeSubTotal = () => {
    const items = getValues("item");
    let subTotal = 0;
    let totalLineItemTax = 0;

    items.map((curr) => {
      if (curr.total && !isNaN(curr.total)) {
        subTotal += Number(curr.total);
      }

      if (curr.line_item_tax && !isNaN(curr.line_item_tax)) {
        totalLineItemTax += Number(curr.line_item_tax);
      }
    }, 0);

    setValue("subtotal", Number(subTotal).toFixed(2));
    setValue("total_line_item_tax", Number(totalLineItemTax).toFixed(2));

    computeInvoiceTaxAmount();
  };

  const computeLineItemTotal = (index = 0) => {
    const { cost = "0", quantity = 1, ...item } = getValues(`item[${index}]`);

    const totalTaxPercentage = item.taxes?.reduce((prev, curr) => {
      if (curr && curr[0] && curr[0].value && !isNaN(curr[0].value)) {
        return prev + curr[0].value;
      }

      return prev;
    }, 0);

    const itemTotal = Number(cost) * Number(quantity);

    setValue(`item[${index}].total`, Number(itemTotal).toFixed(2));
    setValue(
      `item[${index}].line_item_tax`,
      Number((Number(itemTotal) / 100) * totalTaxPercentage).toFixed(2)
    );

    //
    computeSubTotal();
  };

  const computeLineItemTaxAmount = (index = 0) => {
    const {
      cost = "0",
      quantity = 1,
      total,
      ...item
    } = getValues(`item[${index}]`);

    const totalTaxPercentage = item.taxes?.reduce((prev, curr) => {
      if (curr && curr[0] && curr[0].value && !isNaN(curr[0].value)) {
        return prev + curr[0].value;
      }
      return prev;
    }, 0);

    setValue(
      `item[${index}].line_item_tax`,
      Number((Number(total) / 100) * totalTaxPercentage).toFixed(2)
    );
    computeSubTotal();
    // computeInvoiceTaxAmount();
  };

  // SETVALUE ON EDIT FOR INVOICE

  useEffect(() => {
    if (
      (invoiceData && getType === "EDIT-INV") ||
      (invoiceData && getType === "INV-EDIT-INV") ||
      (invoiceData && getType == "EDIT-CHARGE")
    ) {
      setAddFooterFocus(false);
      setAddNotesFocus(false);
      setAddTermsFocus(false);

      setAddNotes(true);
      setAddTerms(true);
      setAddFooter(true);
      setValue(
        "customer",
        setSelectValues(invoiceData && invoiceData.customer_id)
      );

      setValue(
        "recover_from_customer",
        setSelectValues(invoiceData && invoiceData.recover_from)
      );
      if (
        invoiceData &&
        invoiceData.shipping_address &&
        Object.keys(invoiceData.shipping_address).length > 0
      ) {
        setValue("shipping_address", [
          {
            id:
              invoiceData &&
              invoiceData.shipping_address &&
              invoiceData.shipping_address._id,
            label: invoiceData && invoiceData.shipping_address?.line_1,
            line_1: invoiceData && invoiceData.shipping_address?.line_1,
            line_2: invoiceData && invoiceData.shipping_address?.line_2,
            city: invoiceData && invoiceData.shipping_address?.city,
            state: invoiceData && invoiceData.shipping_address?.state,
            country: invoiceData && invoiceData.shipping_address?.country,
            zip_code: invoiceData && invoiceData.shipping_address?.zip_code,
          },
        ]);
      }

      if (
        invoiceData &&
        invoiceData.billing_address &&
        Object.keys(invoiceData.billing_address).length > 0
      ) {
        setValue("billing_address", [
          {
            id:
              invoiceData &&
              invoiceData.billing_address &&
              invoiceData.billing_address._id,
            label: invoiceData && invoiceData.billing_address?.line_1,

            line_1: invoiceData && invoiceData.billing_address?.line_1,
            line_2: invoiceData && invoiceData.billing_address?.line_2,
            city: invoiceData && invoiceData.billing_address?.city,
            state: invoiceData && invoiceData.billing_address?.state,
            country: invoiceData && invoiceData.billing_address?.country,
            zip_code: invoiceData && invoiceData.billing_address?.zip_code,
          },
        ]);
      }
      setValue(
        "payment_term",
        setSelectValues(invoiceData && invoiceData.payment_term)
      );
      setValue("po_number", invoiceData && invoiceData.po_number);
      setValue(
        "invoice_date",
        moment(invoiceData && invoiceData.invoice_date).toDate()
      );
      setValue(
        "invoice_due_date",
        moment(invoiceData && invoiceData.invoice_due_date).toDate()
      );

      if (
        (invoiceData &&
          invoiceData.invoice_deposit_amount &&
          invoiceData.invoice_deposit_amount?.value === 0) ||
        !invoiceData.invoice_deposit_amount
      ) {
        setValue("advance_amount", "0");
      } else {
        setValue(
          "advance_amount",
          Number(invoiceData && invoiceData.invoice_deposit_amount?.value)
        );
      }
      if (
        invoiceData &&
        invoiceData.discount_amount &&
        invoiceData.discount_amount.value === 0
      ) {
        setValue("discount", "0");
      } else {
        setValue("discount", invoiceData && invoiceData.discount_amount?.value);
      }

      setValue(
        "other_information",
        invoiceData && invoiceData.other_information
      );

      invoiceData &&
        invoiceData?.line_items?.map((e, i) => {
          append({ item: `${i}` });

          setValue(`item[${i}].product`, [
            { label: e.name, id: e.item_id, _id: e.item_id, name: e.name },
          ]);
          setValue(`item[${i}].description`, e.description);
          setValue(`item[${i}].cost`, e.unit_cost.value);
          setValue(`item[${i}].quantity`, e.quantity);

          // setValue(`item[${i}]`, e._id);
          let lineItemValue =
            e.tax_amount && e.tax_amount.value && !isNaN(e.tax_amount.value)
              ? Number(e.tax_amount.value)
              : 0;
          setValue(
            `item[${i}].line_item_tax`,
            lineItemValue ? lineItemValue : 0
          );
          e &&
            e.taxes.map((t, k) => {
              setValue(`item[${i}].taxes[${k}]`, [
                {
                  id: t._id,
                  label: `${t.name} ${t.percentage}%`,
                  value: t.percentage,
                  tax_name: t.name,
                },
              ]);
            });
          // setValue(`item[${i}].total`, Number(e && e.line_total_amount?.value));
          setValue(`item[${i}].total`, Number(e && e.line_total_amount?.value));
        });
      setValue(
        "other_information",
        invoiceData && invoiceData.other_information
      );
      setValue("subtotal", invoiceData && invoiceData.sub_total_amount?.value);
      invoiceData &&
        invoiceData?.taxes?.map((e, i) => {
          setValue(`tax[${i}]`, [
            {
              id: e._id,
              label: `${e.name}${e.percentage}%`,
              value: e.percentage,
              name: e.name,
            },
          ]);
        });

      if (
        invoiceData &&
        invoiceData.notify_customer_via === ""
        // &&
        // invoiceData.notify_customer_via?.length === 0
        // invoiceData && invoiceData.notify_customer_via?.length
      ) {
        setValue("type_of_notes", [{ id: "", label: "None" }]);
      } else {
        setValue(
          "type_of_notes",
          setSelectValues(invoiceData && invoiceData?.notify_customer_via)
        );
      }
      // setValue("type_of_notes", [{ id: "", label: "None" }]);

      setValue(
        "template",
        setSelectValues(
          invoiceData && invoiceData?.notify_customer_via_template
        )
      );

      setValue("total", invoiceData && invoiceData.invoice_total_amount?.value);
      setValue("invoice_notes", invoiceData && invoiceData.invoice_notes);
      setValue("invoice_terms", invoiceData && invoiceData.invoice_terms);
      setValue("invoice_footer", invoiceData && invoiceData.invoice_footer);
      setValue("internal_notes", invoiceData && invoiceData.internal_notes);

      let paidValue =
        Number(invoiceData?.invoice_total_amount?.value) -
        Number(invoiceData?.not_paid_amount?.value);

      if (paidValue === 0) {
        setValue("paid", "0");
      } else {
        setValue("paid", Number(paidValue));
      }

      setValue("balance_due", invoiceData?.not_paid_amount?.value);

      // for (let data of invoiceData && invoiceData?.line_items) {
      //
      // }
      // computeLineItemTotal();

      let lineItems = invoiceData && invoiceData?.line_items;

      if (lineItems) {
        for (let index of lineItems.keys()) {
          computeLineItemTotal(index);
        }
      }
      if (invoiceData && invoiceData.attachments) {
        let invoiceChangedAttachment = [];

        invoiceData.attachments?.map((e, i) => {
          let invoiceDocumentType = e.mime_type && e.mime_type?.split("/");

          let obj = {
            name: e.file_name,
            fileRef: e._id,
            type: invoiceDocumentType && invoiceDocumentType[1],
            isNewDoc: false,
            scanStatus: "SUCCESS",
          };

          invoiceChangedAttachment.push(obj);
        });

        dispatch(addingInvoiceDocumentData(invoiceChangedAttachment));
        // setAcceptedFileValue(invoiceData.attachments);
        // addingInvoiceDocumentData
        // dispatch(addingInvoiceDrawerType("EDIT_INVOICE"));
      }
    }

    if (getType === "EDIT-CHARGE") {
      setValue("charge_type", setSelectValues(invoiceData.charge_type));
      setValue(
        "linked_invoices",
        setSelectValues(invoiceData.linked_invoices, true)
      );
    }
  }, [invoiceData]);

  // SETVALUE ON EDIT FOR SUBSCRIBE

  useEffect(() => {
    if (subscriptionData && getType === "EDIT-SUB") {
      setAddFooterFocus(false);
      setAddNotesFocus(false);
      setAddTermsFocus(false);
      setAddNotes(true);
      setAddTerms(true);
      setAddFooter(true);

      let subscribeData = subscriptionData;

      subscribeData &&
        subscribeData.skip_durations?.map((e, i) => {
          skipData.append({ skip: `${i}` });

          setValue(`skip[${i}]`, [
            {
              label: `${moment(`${e.month}`, "M").format("MMMM")} ${e.year}`,
              id: e._id,
              session: e.month,
              year: e.year,
            },
          ]);
        });

      setValue("customer", setSelectValues(subscribeData.customer_id));

      setValue(
        "recover_from_customer",
        setSelectValues(subscribeData?.recover_from)
      );
      if (
        subscribeData &&
        subscribeData.shipping_address &&
        Object.keys(subscribeData.shipping_address).length > 0
      ) {
        setValue("shipping_address", [
          {
            id:
              subscribeData.shipping_address &&
              subscribeData.shipping_address._id,
            label: subscribeData && subscribeData.shipping_address?.line_1,
            line_1: subscribeData && subscribeData.shipping_address?.line_1,
            line_2: subscribeData && subscribeData.shipping_address?.line_2,
            city: subscribeData && subscribeData.shipping_address?.city,
            state: subscribeData && subscribeData.shipping_address?.state,
            country: subscribeData && subscribeData.shipping_address?.country,
            zip_code: subscribeData && subscribeData.shipping_address?.zip_code,
          },
        ]);
      }
      if (
        subscribeData &&
        subscribeData.billing_address &&
        Object.keys(subscribeData.billing_address).length > 0
      ) {
        setValue("billing_address", [
          {
            id:
              subscribeData.billing_address &&
              subscribeData.billing_address._id,
            label: subscribeData && subscribeData.billing_address?.line_1,

            line_1: subscribeData && subscribeData.billing_address?.line_1,
            line_2: subscribeData && subscribeData.billing_address?.line_2,
            city: subscribeData && subscribeData.billing_address?.city,
            state: subscribeData && subscribeData.billing_address?.state,
            country: subscribeData && subscribeData.billing_address?.country,
            zip_code: subscribeData && subscribeData.billing_address?.zip_code,
          },
        ]);
      }
      setValue(
        "payment_term",
        setSelectValues(subscribeData && subscribeData.payment_term)
      );
      setValue("po_number", subscribeData && subscribeData.po_number);
      setValue(
        "start_date",
        moment
          .utc(subscribeData && subscribeData.start_date)
          ?.tz(timeZone)
          .toDate()
      );
      setValue(
        "next_execution_date",
        moment
          .utc(subscribeData && subscribeData.next_execution_date)
          ?.tz(timeZone)
          .toDate()
      );

      if (subscribeData && subscribeData.end_date) {
        setValue(
          "end_date",
          moment
            .utc(subscribeData && subscribeData.end_date)
            ?.tz(timeZone)
            .toDate()
        );
      }

      setValue("name", subscribeData.name);

      // setValue(
      //   "advance_amount",
      //   invoiceData && invoiceData.advance_amount?.value
      // );

      // if (
      //   subscribeData &&
      //   subscribeData.advance_amount &&
      //   subscribeData.advance_amount?.value === 0
      // ) {
      //   setValue("advance_amount", "0");
      // } else {
      //   setValue(
      //     "advance_amount",
      //     subscribeData && subscribeData.advance_amount?.value
      //   );
      // }

      if (
        subscribeData &&
        subscribeData.discount_amount &&
        subscribeData.discount_amount.value === 0
      ) {
        setValue("discount", "0");
      } else {
        setValue(
          "discount",
          subscribeData && subscribeData.discount_amount?.value
        );
      }
      // setValue(
      //   "discount",
      //   subscribeData && subscribeData.discount_amount?.value
      // );

      setValue(
        "other_information",
        subscribeData && subscribeData.other_information
      );

      subscribeData &&
        subscribeData?.line_items?.map((e, i) => {
          append({ item: `${i}` });

          setValue(
            `item[${i}].product`,

            [{ label: e.name, id: e.item_id, _id: e.item_id, name: e.name }]
          );
          setValue(`item[${i}].description`, e.description);
          setValue(`item[${i}].cost`, e.unit_cost.value);
          setValue(`item[${i}].quantity`, e.quantity);

          // setValue(`item[${i}]`, e._id);
          setValue(`item[${i}].line_item_tax`, Number(e.tax_amount.value));
          e &&
            e.taxes.map((t, k) => {
              setValue(`item[${i}].taxes[${k}]`, [
                {
                  id: t._id,
                  label: `${t.name} ${t.percentage}%`,
                  value: t.percentage,
                  tax_name: t.name,
                },
              ]);
            });
          // setValue(`item[${i}].total`, Number(e && e.line_total_amount?.value));
          setValue(`item[${i}].total`, Number(e && e.line_total_amount?.value));
        });
      // setValue(
      //   "other_information",
      //   invoiceData && invoiceData.other_information
      // );
      setValue(
        "subtotal",
        subscribeData && subscribeData.sub_total_amount?.value
      );
      subscribeData &&
        subscribeData?.taxes?.map((e, i) => {
          setValue(`tax[${i}]`, [
            {
              id: e._id,
              label: `${e.name}${e.percentage}%`,
              value: e.percentage,
              name: e.name,
            },
          ]);
        });
      // // setValue("total", invoiceData && invoiceData.invoice_total_amount?.value);
      // setValue(
      //   "paid",
      //   subscribeData?.invoice_total_amount?.value -
      //     subscribeData.not_paid_amount?.value
      // );
      setValue(
        "balance_due",
        subscribeData && subscribeData.not_paid_amount?.value
      );
      if (
        subscribeData &&
        subscribeData.notify_customer_via === ""
        //  &&
        // subscribeData.notify_customer_via?.length === 0
      ) {
        setValue("type_of_notes", [{ id: "", label: "None" }]);
      } else {
        setValue(
          "type_of_notes",
          setSelectValues(subscribeData && subscribeData?.notify_customer_via)
        );
      }
      // setValue(
      //   "type_of_notes",
      //   setSelectValues(subscribeData && subscribeData.notify_customer_via)
      //   // getSelectValues(subscribeData && subscribeData?.notify_customer_via)
      // );
      setValue(
        "template",
        setSelectValues(
          subscribeData && subscribeData?.notify_customer_via_template
        )
      );
      setValue("total", subscribeData && subscribeData.total_amount?.value);

      setValue("invoice_notes", subscribeData && subscribeData.invoice_notes);
      setValue("invoice_terms", subscribeData && subscribeData.invoice_terms);
      setValue("invoice_footer", subscribeData && subscribeData.invoice_footer);
      setValue("internal_notes", subscribeData && subscribeData.internal_notes);

      let lineItems = subscribeData && subscribeData?.line_items;

      if (lineItems) {
        for (let index of lineItems.keys()) {
          computeLineItemTotal(index);
        }
      }
      if (subscribeData && subscribeData.attachments) {
        let invoiceChangedAttachment = [];

        subscribeData.attachments?.map((e, i) => {
          let invoiceDocumentType = e.mime_type && e.mime_type?.split("/");

          let obj = {
            name: e.file_name,
            fileRef: e._id,
            type: invoiceDocumentType && invoiceDocumentType[1],
            isNewDoc: false,
            scanStatus: "SUCCESS",
          };

          invoiceChangedAttachment.push(obj);
        });

        dispatch(addingInvoiceDocumentData(invoiceChangedAttachment));
        // setAcceptedFileValue(invoiceData.attachments);
        // addingInvoiceDocumentData
        // dispatch(addingInvoiceDrawerType("EDIT_INVOICE"));
      }
    }
  }, [subscriptionData]);

  const addValuePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  // useEffect(() => {
  //   if (type) {
  //     if (
  //       (type && getType !== "EDIT-INV") ||
  //       getType !== "EDIT-SUB" ||
  //       getType !== "INV-EDIT-INV"
  //     ) {
  //       setValue("template", []);
  //     }
  //   }
  // }, [type]);

  useEffect(() => {
    if (type_of_notes && type_of_notes[0] && type_of_notes[0].id === "") {
      unregister("template");
    }
  }, [type_of_notes]);

  // if (customerListData && customerListData.isLoading) {
  //   return <Loader />;
  // }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* FORM BODY STARTS */}
        <div
          style={{
            background: "#ffffff",
            padding: "20px",
            height: "calc(100vh - 200px)",
            // height: "calc(100vh - 120px)",
            overflow: "scroll",
          }}
        >
          {/* TOP SECTION STARTS */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* LEFT FORM STARTS */}
            <div>
              <div style={{ width: "350px" }}>
                <Controller
                  name="customer"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        {...field}
                        requiredAstric={true}
                        clearable={false}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: `customer`,
                        })}
                        placeholder={intl.formatMessage({
                          id: "customer",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        onInputChange={(ic) => {
                          setIsSearchBy(ic?.target?.value);
                          debounceFilter(ic?.target?.value);
                        }}
                        disabled={
                          getType === "EDIT-INV" ||
                          getType === "EDIT-SUB" ||
                          getType === "INV-EDIT-INV" ||
                          getType == "EDIT-CHARGE"
                            ? true
                            : false
                        }
                        isLoading={customerListData.isLoading}
                        options={
                          customerListData &&
                          customerListData.data &&
                          customerListData.data.data &&
                          customerListData.data.data.docs.map((e) => {
                            return {
                              id: e?._id,
                              label: e?.display_name,
                              disabled: !e?.is_active,
                            };
                          })
                        }
                        size={SIZE.default}
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ width: "350px" }}>
                <Controller
                  name="recover_from_customer"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        {...field}
                        name={field.name}
                        requiredAstric={true}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: `recover_from_customer`,
                        })}
                        placeholder={intl.formatMessage({
                          id: "recover_from_customer",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          referenceData && referenceData["recover_from"]
                            ? referenceData["recover_from"]
                            : []
                        }
                        size={SIZE.default}
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ width: "350px" }}>
                {/* <Controller
                  name="billing_address"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "billing_address",
                        })}
                        placeholder={intl.formatMessage({
                          id: "billing_address",
                        })}
                        value={field.value}
                        size={SIZE.default}
                      />
                    </>
                  )}
                /> */}
                <div style={{ width: "350px" }}>
                  <Controller
                    name="billing_address"
                    control={control}
                    render={({ field }) => (
                      <>
                        <SelectBox
                          {...field}
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: `billing_address`,
                          })}
                          placeholder={intl.formatMessage({
                            id: "billing_address",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            customerAddress &&
                            customerAddress.map((e) => {
                              return {
                                line_1: e.line_1,
                                line_2: e.line_2,
                                city: e.city,
                                state: e.state,
                                country: e.country,
                                zip_code: `${e.zip_code}`,
                                id: e.name,
                                label: e.name,
                              };
                            })
                          }
                          size={SIZE.default}
                        />
                      </>
                    )}
                  />
                </div>
              </div>{" "}
              <div style={{ width: "350px" }}>
                <Controller
                  name="shipping_address"
                  control={control}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: `shipping_address`,
                        })}
                        placeholder={intl.formatMessage({
                          id: "shipping_address",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          customerAddress &&
                          customerAddress.map((e) => {
                            return {
                              line_1: e.line_1,
                              line_2: e.line_2,
                              city: e.city,
                              state: e.state,
                              country: e.country,
                              zip_code: `${e.zip_code}`,
                              id: e.name,
                              label: e.name,
                            };
                          })
                        }
                        size={SIZE.default}
                      />
                    </>
                  )}
                />
              </div>
              {getType == "ADD-CHARGE" || getType == "EDIT-CHARGE" ? (
                <>
                  <div style={{ width: "350px" }}>
                    <Controller
                      name="charge_type"
                      control={control}
                      render={({ field }) => (
                        <>
                          <SelectBox
                            {...field}
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            creatable={addValuePermission ? true : false}
                            label={intl.formatMessage({
                              id: `charge_type`,
                            })}
                            placeholder={intl.formatMessage({
                              id: "charge_type",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            disabled={getType == "EDIT-CHARGE" ? true : false}
                            requiredAstric={true}
                            options={
                              referenceData && referenceData["charge_type_list"]
                                ? referenceData["charge_type_list"]
                                : []
                            }
                            size={SIZE.default}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div style={{ width: "350px" }}>
                    <Controller
                      name="linked_invoices"
                      control={control}
                      shouldUnregister
                      render={({ field }) => (
                        <>
                          <SelectBox
                            {...field}
                            name={field.name}
                            multi
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: `invoices`,
                            })}
                            placeholder={intl.formatMessage({
                              id: "invoices",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            requiredAstric={true}
                            options={
                              invoiceDataMock &&
                              invoiceDataMock.data &&
                              invoiceDataMock.data.data &&
                              Array.isArray(invoiceDataMock.data.data.docs) &&
                              invoiceDataMock.data.data.docs.map((e) => {
                                return { id: e._id, label: e.invoice_number };
                              })
                            }
                            size={SIZE.default}
                          />
                        </>
                      )}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* LEFT FORM ENDS */}

            {/* RIGHT TOP FORM STARTS*/}

            <div style={{ display: "flex", gap: "35px" }}>
              {getInvoiceParams === "invoice-form" ||
              getInvoiceParams === "charge-form" ? (
                <>
                  <div>
                    <div style={{ width: "350px" }}>
                      <Controller
                        name="invoice_date"
                        control={control}
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <DateRangeSelect
                            {...field}
                            name={field.name}
                            requiredAstric={true}
                            // disabled={isDisabled}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "invoice_date",
                            })}
                            placeholder={intl.formatMessage({
                              id: " ",
                            })}
                            value={field.value}
                            onChange={(e) => field.onChange(e.date)}
                            // range
                            size={SIZE.default}
                            minDate={getType !== "ADD-CHARGE" && new Date()}
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: "350px" }}>
                      {/* <Controller
                    name="payment_term"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          fullWidth
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "payment_term",
                          })}
                          placeholder={intl.formatMessage({
                            id: "payment_term",
                          })}
                          value={field.value}
                          size={SIZE.default}
                        />
                      </>
                    )}
                  /> */}
                      <Controller
                        name="payment_term"
                        control={control}
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <SelectBox
                            {...field}
                            name={field.name}
                            requiredAstric={true}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "payment_term",
                            })}
                            placeholder={intl.formatMessage({
                              id: "payment_term",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={
                              referenceData && referenceData["payment_terms"]
                                ? referenceData["payment_terms"]
                                : []
                            }
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: "350px" }}>
                      <Controller
                        name="invoice_due_date"
                        control={control}
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <DateRangeSelect
                            {...field}
                            name={field.name}
                            // disabled={isDisabled}
                            requiredAstric={true}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "invoice_due_date",
                            })}
                            placeholder={intl.formatMessage({
                              id: " ",
                            })}
                            value={field.value}
                            onChange={(e) => field.onChange(e.date)}
                            // range
                            size={SIZE.default}
                            minDate={new Date()}
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: "350px" }}>
                      <Controller
                        name="advance_amount"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextBox
                              {...field}
                              type="number"
                              fullWidth
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "advance_amount",
                              })}
                              placeholder={intl.formatMessage({
                                id: "advance_amount",
                              })}
                              // disabled={
                              //   getType === "EDIT-INV" ||
                              //   getType === "INV-EDIT-INV"
                              //     ? true
                              //     : false
                              // }
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                computeTotal();
                                // setChanging(changing + 1);
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key == "ArrowUp" ||
                                  e.key == "ArrowDown" ||
                                  e.key == "-" ||
                                  e.key == "+"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              value={field.value}
                              size={SIZE.default}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div
                // style={{
                //   display: "flex",
                //   // gap: "35px",
                //   flexDirection: "column",
                // }}
                >
                  <div style={{ width: "350px" }}>
                    <Controller
                      name="frequency"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <>
                          <SelectBox
                            {...field}
                            name={field.name}
                            requiredAstric={true}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: `frequency`,
                            })}
                            placeholder={intl.formatMessage({
                              id: "frequency",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            // options={[{ id: "1", label: "one" }]}
                            options={
                              referenceData &&
                              referenceData["invoice_frequency"]
                                ? referenceData["invoice_frequency"]
                                : []
                            }
                            size={SIZE.default}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div style={{ width: "350px" }}>
                    <Controller
                      name="start_date"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <DateRangeSelect
                          {...field}
                          name={field.name}
                          // disabled={isDisabled}
                          requiredAstric={true}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "start_date",
                          })}
                          placeholder={intl.formatMessage({
                            id: " ",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.date);
                            setValue(
                              "next_execution_date",
                              moment(start_date).toDate()
                            );
                          }}
                          // range
                          disabled={getSubscription ? true : false}
                          minDate={new Date()}
                          size={SIZE.default}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "350px" }}>
                    <Controller
                      name="next_execution_date"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <DateRangeSelect
                          {...field}
                          name={field.name}
                          // disabled={isDisabled}
                          requiredAstric={true}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "next_execution_date",
                          })}
                          placeholder={intl.formatMessage({
                            id: " ",
                          })}
                          value={field.value}
                          onChange={(e) => field.onChange(e.date)}
                          // range
                          disabled={getSubscription ? false : true}
                          size={SIZE.default}
                          minDate={new Date()}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "350px" }}>
                    <Controller
                      name="end_date"
                      control={control}
                      render={({ field }) => (
                        <DateRangeSelect
                          {...field}
                          name={field.name}
                          clearable={true}
                          // disabled={isDisabled}
                          //                   error={
                          // payment_term                    errors[field.name] && errors[field.name].message
                          //                   }
                          label={intl.formatMessage({
                            id: "end_date",
                          })}
                          placeholder={intl.formatMessage({
                            id: " ",
                          })}
                          value={field.value}
                          onChange={(e) => field.onChange(e.date)}
                          // range
                          size={SIZE.default}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "350px" }}>
                    <Controller
                      name="payment_term"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <>
                          <SelectBox
                            {...field}
                            name={field.name}
                            requiredAstric={true}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: `payment_term`,
                            })}
                            placeholder={intl.formatMessage({
                              id: "payment_term",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            // options={[
                            //   { id: "DUE_ON_RECEIPT", label: "DUE_ON_RECEIPT" },
                            // ]}
                            options={
                              referenceData && referenceData["payment_terms"]
                                ? referenceData["payment_terms"]
                                : []
                            }
                            size={SIZE.default}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // gap: "7%",
                      // paddingTop: "5px",
                      // alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <TextButton
                        kind={KIND.secondary}
                        size={SIZE.mini}
                        type="button"
                        onClick={() => {
                          skipData.append({ name: "skip" });
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          <Plus fill={"#516beb"} />
                          <div>Skip Duration</div>
                        </div>
                      </TextButton>
                    </div>
                    <div
                      style={{
                        paddingTop: "10px",
                        minHeight: "0px",
                        maxHeight: "210px",
                        overflow: "scroll",
                      }}
                    >
                      {skipData.fields.map((e, i) => (
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <div style={{ width: "130px" }}>
                            <Controller
                              name={`skip[${i}]`}
                              // name={`select`}
                              control={control}
                              // shouldUnregister={true}
                              render={({ field }) => {
                                return (
                                  <SelectBox
                                    {...field}
                                    name={field.name}
                                    // disabled={isDisabled}
                                    clearable={false}
                                    value={watch(field.name)}
                                    label={intl.formatMessage({
                                      id: "select_filter",
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "select_filter",
                                    })}
                                    size={SIZE.mini}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                    }}
                                    options={overAllMonths}
                                  />
                                );
                              }}
                            />
                          </div>
                          <div>
                            <IconButton
                              kind={KIND.tertiary}
                              size={SIZE.mini}
                              onClick={
                                () => skipData.remove(i)
                                // remove(i)
                              }
                              type="button"
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <div style={{ paddingTop: "", paddingBottom: "5px" }}>
                    Add Skip Duration
                  </div> */}
                    {/* <div style={{ width: "170px", paddingTop: "10px" }}>
                    <Controller
                      name="callback.callback_days"
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          {...field}
                          multi
                          height={"30px"}
                          name={field.name}
                          // label={intl.formatMessage({
                          //   id: "callback",
                          // })}
                          placeholder={intl.formatMessage({
                            id: "callback",
                          })}
                          value={field.value}
                          // options={referenceData["week_days"]}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          size={SIZE.mini}
                        />
                      )}
                    />
                  </div> */}
                  </div>
                </div>
              )}

              <div>
                {/* <div style={{ width: "350px" }}>
                  <Controller
                    name="invoice_amount"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          fullWidth
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "invoice_amount",
                          })}
                          placeholder={intl.formatMessage({
                            id: "invoice_amount",
                          })}
                          value={field.value}
                          size={SIZE.default}
                        />
                      </>
                    )}
                  />
                </div> */}
                {getInvoiceParams === "invoice-form" ||
                getInvoiceParams === "charge-form" ? (
                  <></>
                ) : (
                  <>
                    <div style={{ width: "350px" }}>
                      <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <>
                            <TextBox
                              {...field}
                              fullWidth
                              requiredAstric={true}
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "name",
                              })}
                              placeholder={intl.formatMessage({
                                id: "name",
                              })}
                              value={field.value}
                              size={SIZE.default}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                // setChanging(changing + 1);
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                  </>
                )}

                <div style={{ width: "350px" }}>
                  <Controller
                    name="po_number"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          fullWidth
                          // type="number"
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "po_number",
                          })}
                          placeholder={intl.formatMessage({
                            id: "po_number",
                          })}
                          value={field.value}
                          size={SIZE.default}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            // setChanging(changing + 1);
                          }}
                          // onKeyDown={(e) => {
                          //   if (
                          //     e.key == "ArrowUp" ||
                          //     e.key == "ArrowDown" ||
                          //     e.key == "-" ||
                          //     e.key == "+"
                          //   ) {
                          //     e.preventDefault();
                          //   }
                          // }}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "350px" }}>
                  <Controller
                    name="discount"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          fullWidth
                          type="number"
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "discount",
                          })}
                          // placeholder={intl.formatMessage({
                          //   id: "discount",
                          // })}
                          placeholder={intl.formatMessage({
                            id: discount === 0 ? " " : "discount",
                          })}
                          value={field.value}
                          size={SIZE.default}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            computeInvoiceTaxAmount();
                            // setChanging(changing + 1);
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key == "ArrowUp" ||
                              e.key == "ArrowDown" ||
                              e.key == "-" ||
                              e.key == "+"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "350px" }}>
                  <Controller
                    name="other_information"
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextArea
                          {...field}
                          fullWidth
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "other_information",
                          })}
                          placeholder={intl.formatMessage({
                            id: "other_information",
                          })}
                          value={field.value}
                          size={SIZE.mini}
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            {/* <div style={{ display: "flex", gap: "35px" }}></div> */}
            {/* RIGHT TOP FORM STARTS*/}
          </div>
          {/* TOP SECTION ENDS */}

          {/* MEDIAL SECTION TABLE STARTS */}
          <div style={{ paddingTop: "10px", paddingBottom: "20px" }}>
            <table>
              <tr style={{ backgroundColor: "#F5F6FA" }}>
                <th>Item Details</th>
                <th>Description</th>
                <th>Unit Cost</th>
                <th>Quantity</th>
                <th>Tax</th>
                <th>Tax</th>
                <th>Tax</th>
                <th>Line Total</th>
              </tr>

              {fields.map((e, i) => (
                <tr>
                  {/* // PRODUCT */}
                  <td>
                    <div style={{ width: "120px", marginTop: "15px" }}>
                      <Controller
                        name={`item[${i}].product`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <SelectBox
                              {...field}
                              name={field.name}
                              value={watch(field.name)}
                              error={
                                errors &&
                                errors.item &&
                                // errors.item.map((e, i) => {
                                //   e?.product?.message;
                                // })
                                errors.item[i] &&
                                errors.item[i].product &&
                                errors.item[i].product?.message
                              }
                              // label={intl.formatMessage({
                              //   id: "select_product",
                              // })}
                              // placeholder={intl.formatMessage({
                              //   id: "select_product",
                              // })}
                              size={SIZE.mini}
                              onChange={(e) => {
                                setValue(`item[${i}]`, {
                                  description: "",
                                  cost: "",
                                  quantity: "",
                                  total: undefined,
                                  line_item_tax: undefined,
                                  taxes: [],
                                });
                                computeSubTotal();

                                const product = e.value && e.value[0];
                                field.onChange(e.value);

                                if (product) {
                                  const unitCost =
                                    product?.price && product?.price.value
                                      ? product.price.value
                                      : "0";
                                  const quantity = 1;
                                  const totalTaxPercentage =
                                    product.taxes?.reduce(
                                      (prev, curr, taxIndex) => {
                                        let taxValue = taxRd.find(
                                          (tx) => tx._id === curr
                                        );
                                        if (taxValue && taxValue.rate) {
                                          setValue(
                                            `item[${i}].taxes[${taxIndex}]`,
                                            [
                                              // {
                                              //   id: taxValue._id,
                                              //   label: taxValue.name,
                                              //   value: taxValue.rate,
                                              //   name:taxValue.name
                                              // },
                                              {
                                                id: taxValue._id,
                                                label: `${taxValue.name} ${taxValue.rate}%`,
                                                value: taxValue.rate,
                                                tax_name: taxValue.name,
                                                disabled: taxValue.is_disabled,
                                              },
                                            ]
                                          );
                                          return prev + taxValue.rate;
                                        }

                                        return prev;
                                      },
                                      0
                                    );

                                  const itemTotal =
                                    Number(unitCost) * Number(quantity);

                                  setValue(
                                    `item[${i}].description`,
                                    product.description
                                      ? product.description
                                      : ""
                                  );
                                  setValue(`item[${i}].cost`, unitCost);
                                  setValue(`item[${i}].quantity`, quantity);
                                  setValue(
                                    `item[${i}].total`,
                                    Number(itemTotal).toFixed(2)
                                  );
                                  setValue(
                                    `item[${i}].line_item_tax`,
                                    Number(
                                      (Number(itemTotal) / 100) *
                                        totalTaxPercentage
                                    ).toFixed(2)
                                  );

                                  computeSubTotal();
                                }
                              }}
                              options={
                                invoiceList &&
                                invoiceList.map((e) => {
                                  //
                                  return {
                                    ...e,
                                    label: e.name,
                                    id: e._id,
                                    disabled: e.is_disabled,
                                  };
                                })
                              }
                            />
                          );
                        }}
                      />
                    </div>
                  </td>
                  {/* // DESCRIPTION */}
                  <td>
                    <div style={{ width: "120px" }}>
                      <Controller
                        name={`item[${i}].description`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              {...field}
                              overrides={{
                                Root: {
                                  style: ({ $theme, $isFocused, $error }) => ({
                                    borderRadius: "5px",
                                    borderWidth: "1px",
                                    borderColor: $isFocused
                                      ? errors && errors.quantity
                                        ? "#FD372A"
                                        : "#516BEB"
                                      : errors && errors.quantity
                                      ? "#FD372A"
                                      : "#CDCED9",
                                    backgroundColor: "#ffffff",
                                    paddingRight: "0px",
                                    height: "30px",
                                    // width: "85px",
                                  }),
                                },
                                Input: {
                                  style: ({ $theme, $isFocused }) => ({
                                    paddingRight: "5px",
                                    backgroundColor: $isFocused
                                      ? "#516BEB10"
                                      : "#F5F6FA",
                                  }),
                                },
                                ClearIconContainer: {
                                  style: ({ $theme, $isFocused }) => ({
                                    paddingRight: "0",
                                    paddingLeft: "0",
                                    backgroundColor: $isFocused
                                      ? "#516BEB10"
                                      : "#F5F6FA",
                                  }),
                                },
                              }}
                              fullWidth
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              value={field.value}
                              size={SIZE.mini}
                            />
                          </>
                        )}
                      />
                    </div>
                  </td>
                  {/* // COST */}
                  <td>
                    <div style={{ width: "120px" }}>
                      <Controller
                        name={`item[${i}].cost`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              {...field}
                              type="number"
                              overrides={{
                                Root: {
                                  style: ({ $theme, $isFocused, $error }) => ({
                                    borderRadius: "5px",
                                    borderWidth: "1px",
                                    borderColor: $isFocused
                                      ? errors && errors.quantity
                                        ? "#FD372A"
                                        : "#516BEB"
                                      : errors && errors.quantity
                                      ? "#FD372A"
                                      : "#CDCED9",
                                    backgroundColor: "#ffffff",
                                    paddingRight: "0px",
                                    height: "30px",
                                    // width: "85px",
                                  }),
                                },
                                Input: {
                                  style: ({ $theme, $isFocused }) => ({
                                    paddingRight: "5px",
                                    backgroundColor: $isFocused
                                      ? "#516BEB10"
                                      : "#F5F6FA",
                                  }),
                                },
                                ClearIconContainer: {
                                  style: ({ $theme, $isFocused }) => ({
                                    paddingRight: "0",
                                    paddingLeft: "0",
                                    backgroundColor: $isFocused
                                      ? "#516BEB10"
                                      : "#F5F6FA",
                                  }),
                                },
                              }}
                              fullWidth
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                computeLineItemTotal(i);
                                // setChangingIndex(i);
                                // setChanging(changing + 1);
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key == "ArrowUp" ||
                                  e.key == "ArrowDown" ||
                                  e.key == "-" ||
                                  e.key == "+"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              value={field.value}
                              size={SIZE.mini}
                            />
                          </>
                        )}
                      />
                    </div>
                    <span style={{ fontSize: "11px", color: "red" }}>
                      {errors &&
                        errors.item &&
                        // errors.item.map((e, i) => {
                        //   e?.product?.message;
                        // })
                        errors.item[i] &&
                        errors.item[i].cost &&
                        errors.item[i].cost?.message}
                    </span>
                  </td>
                  {/* // QUANTITY */}
                  <td>
                    <div style={{ width: "120px" }}>
                      <Controller
                        name={`item[${i}].quantity`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              {...field}
                              type="number"
                              overrides={{
                                Root: {
                                  style: ({ $theme, $isFocused, $error }) => ({
                                    borderRadius: "5px",
                                    borderWidth: "1px",
                                    borderColor: $isFocused
                                      ? errors && errors.quantity
                                        ? "#FD372A"
                                        : "#516BEB"
                                      : errors && errors.quantity
                                      ? "#FD372A"
                                      : "#CDCED9",
                                    backgroundColor: "#ffffff",
                                    paddingRight: "0px",
                                    height: "30px",
                                    // width: "85px",
                                  }),
                                },
                                Input: {
                                  style: ({ $theme, $isFocused }) => ({
                                    paddingRight: "5px",
                                    backgroundColor: $isFocused
                                      ? "#516BEB10"
                                      : "#F5F6FA",
                                  }),
                                },
                                ClearIconContainer: {
                                  style: ({ $theme, $isFocused }) => ({
                                    paddingRight: "0",
                                    paddingLeft: "0",
                                    backgroundColor: $isFocused
                                      ? "#516BEB10"
                                      : "#F5F6FA",
                                  }),
                                },
                              }}
                              fullWidth
                              name={field.name}
                              // error={
                              //   errors[field.name] && errors[field.name].message
                              // }
                              error={
                                errors &&
                                errors.item &&
                                // errors.item.map((e, i) => {
                                //   e?.product?.message;
                                // })
                                errors.item[i] &&
                                errors.item[i].quantity &&
                                errors.item[i].quantity?.message
                              }
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                computeLineItemTotal(i);
                                // setChangingIndex(i);
                                // setChanging(changing + 1);
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key == "ArrowUp" ||
                                  e.key == "ArrowDown" ||
                                  e.key == "-" ||
                                  e.key == "+"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              value={field.value}
                              size={SIZE.mini}
                            />
                          </>
                        )}
                      />
                      <span style={{ fontSize: "11px", color: "red" }}>
                        {errors &&
                          errors.item &&
                          // errors.item.map((e, i) => {
                          //   e?.product?.message;
                          // })
                          errors.item[i] &&
                          errors.item[i].quantity &&
                          errors.item[i].quantity?.message}
                      </span>
                    </div>
                  </td>
                  {/* // Tax 1*/}
                  {Array.from({ length: 3 }).map((e, index) => {
                    return (
                      <>
                        <td>
                          <div style={{ width: "120px", marginTop: "15px" }}>
                            <Controller
                              name={`item[${i}].taxes[${index}]`}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <SelectBox
                                    {...field}
                                    name={field.name}
                                    value={watch(field.name)}
                                    // label={intl.formatMessage({
                                    //   id: "select_tax",
                                    // })}
                                    // placeholder={intl.formatMessage({
                                    //   id: "select_tax",
                                    // })}
                                    size={SIZE.mini}
                                    onChange={(e) => {
                                      field.onChange(e.value);
                                      // setChanging(changing + 1);
                                      computeLineItemTaxAmount(i);
                                    }}
                                    options={taxRd.map((e) => {
                                      return {
                                        id: e._id,
                                        label: `${e.name} ${e.rate}%`,
                                        value: e.rate,
                                        tax_name: e.name,
                                        disabled: e.is_disabled,
                                      };
                                    })}

                                    // options={overAllMonths}
                                  />
                                );
                              }}
                            />
                          </div>
                        </td>{" "}
                      </>
                    );
                  })}

                  {/* // PRODUCT */}
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "200px" }}>
                        <Controller
                          name={`item[${i}].total`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <Input
                                {...field}
                                type="number"
                                overrides={{
                                  Root: {
                                    style: ({
                                      $theme,
                                      $isFocused,
                                      $error,
                                    }) => ({
                                      borderRadius: "5px",
                                      borderWidth: "1px",
                                      borderColor: $isFocused
                                        ? errors && errors.quantity
                                          ? "#FD372A"
                                          : "#516BEB"
                                        : errors && errors.quantity
                                        ? "#FD372A"
                                        : "#CDCED9",
                                      backgroundColor: "#ffffff",
                                      paddingRight: "0px",
                                      height: "30px",
                                      // width: "85px",
                                    }),
                                  },
                                  Input: {
                                    style: ({ $theme, $isFocused }) => ({
                                      paddingRight: "5px",
                                      backgroundColor: $isFocused
                                        ? "#516BEB10"
                                        : "#F5F6FA",
                                    }),
                                  },
                                  ClearIconContainer: {
                                    style: ({ $theme, $isFocused }) => ({
                                      paddingRight: "0",
                                      paddingLeft: "0",
                                      backgroundColor: $isFocused
                                        ? "#516BEB10"
                                        : "#F5F6FA",
                                    }),
                                  },
                                }}
                                fullWidth
                                name={field.name}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                onFocus={(e) =>
                                  e.target.addEventListener(
                                    "wheel",
                                    function (e) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  )
                                }
                                disabled={true}
                                value={field.value}
                                size={SIZE.mini}
                                onChange={() => {}}
                              />
                            </>
                          )}
                        />
                      </div>
                      {/* <div>X</div> */}

                      <IconButton
                        kind={KIND.tertiary}
                        onClick={() => {
                          remove(i);
                          computeSubTotal();
                        }}
                        type="button"
                      >
                        X
                      </IconButton>
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <TextButton
            kind={KIND.secondary}
            size={SIZE.mini}
            type="button"
            onClick={() => {
              append({ item: "", total: 0, quantity: 1, cost: 0 });
            }}
            style={{ marginBottom: "15px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <div>Add Row</div>
            </div>
          </TextButton>
          {/* MEDIAL SECTION TABLE ENDS */}

          {/* TOTAL SECTION STARTS */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
            }}
          >
            <div style={{ flex: "1" }}>
              {addNotes ? (
                <></>
              ) : (
                <>
                  <div
                    className="cp__customer_portal"
                    style={{ padding: "15px 0px" }}
                    onClick={() => {
                      setAddNotes(!addNotes);
                    }}
                  >
                    Add Notes
                  </div>
                </>
              )}

              {addNotes ? (
                <>
                  <div>
                    <Controller
                      name="invoice_notes"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextArea
                            {...field}
                            height="200px"
                            // $isFocused={true}
                            autoFocus={addNotesFocus}
                            fullWidth
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "invoice_notes",
                            })}
                            placeholder={intl.formatMessage({
                              id: "invoice_notes",
                            })}
                            value={field.value}
                            size={SIZE.mini}
                          />
                        </>
                      )}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              {addTerms ? (
                <></>
              ) : (
                <>
                  <div
                    className="cp__customer_portal"
                    onClick={() => {
                      setAddTerms(!addTerms);
                    }}
                    style={{ padding: "15px 0px" }}
                  >
                    Add Terms
                  </div>
                </>
              )}

              {addTerms ? (
                <>
                  <div>
                    <Controller
                      name="invoice_terms"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextArea
                            height="200px"
                            {...field}
                            fullWidth
                            name={field.name}
                            autoFocus={addTermsFocus}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "invoice_terms",
                            })}
                            placeholder={intl.formatMessage({
                              id: "invoice_terms",
                            })}
                            value={field.value}
                            size={SIZE.mini}
                          />
                        </>
                      )}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div style={{ width: "350px" }}>
                <Controller
                  name="subtotal"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "subtotal",
                        })}
                        // placeholder={intl.formatMessage({
                        //   id: "subtotal",
                        // })}

                        placeholder={intl.formatMessage({
                          id: !subtotal ? " " : "subtotal",
                        })}
                        disabled={true}
                        value={field.value}
                        size={SIZE.default}
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ width: "350px" }}>
                <Controller
                  name="discount"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        type="number"
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "discount",
                        })}
                        placeholder={intl.formatMessage({
                          id: "discount",
                        })}
                        // placeholder={intl.formatMessage({
                        //   id: discount === 0 ? " " : "discount",
                        // })}
                        value={field.value}
                        size={SIZE.default}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          computeTotal();
                          // setChanging(changing + 1);
                        }}
                        onKeyDown={(e) => {
                          if (
                            e.key == "ArrowUp" ||
                            e.key == "ArrowDown" ||
                            e.key == "-" ||
                            e.key == "+"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </>
                  )}
                />
              </div>
              {Array.from({
                length:
                  invoiceSetting && invoiceSetting.invoice_level_tax_count,
              }).map((e, index) => {
                return (
                  <>
                    <div style={{ width: "350px" }}>
                      <Controller
                        name={`tax[${index}]`}
                        control={control}
                        render={({ field }) => (
                          <>
                            <SelectBox
                              {...field}
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: `tax`,
                              })}
                              placeholder={intl.formatMessage({
                                id: "tax",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                                computeInvoiceTaxAmount();
                                // setChanging(changing + 1);
                              }}
                              options={taxRd.map((e) => {
                                return {
                                  id: e._id,
                                  label: `${e.name} ${e.rate}%`,
                                  value: e.rate,
                                  name: e.name,
                                  disabled: e.is_disabled,
                                };
                              })}
                              size={SIZE.default}
                            />
                          </>
                        )}
                      />
                    </div>
                  </>
                );
              })}

              <div style={{ width: "350px" }}>
                <Controller
                  name="total"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "total",
                        })}
                        placeholder={intl.formatMessage({
                          id: !total ? " " : "total",
                        })}
                        disabled={true}
                        value={field.value}
                        size={SIZE.default}
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ width: "350px" }}>
                <Controller
                  name="paid"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "paid",
                        })}
                        placeholder={intl.formatMessage({
                          id: !paid ? " " : "paid",
                        })}
                        disabled={true}
                        value={field.value}
                        size={SIZE.default}
                      />
                    </>
                  )}
                />
              </div>

              <div style={{ width: "350px" }}>
                <Controller
                  name="balance_due"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        fullWidth
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "balance_due",
                        })}
                        placeholder={intl.formatMessage({
                          id: !balance_due ? " " : "balance_due",
                        })}
                        disabled={true}
                        value={field.value}
                        size={SIZE.default}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          {/* TOTAL SECTION ENDS */}

          {/* FOOTER SECTION */}
          <div>
            {addFooter ? (
              <></>
            ) : (
              <>
                <div
                  className="cp__customer_portal"
                  style={{ padding: "15px 0px" }}
                  onClick={() => {
                    setAddFooter(!addFooter);
                  }}
                >
                  Add Footer
                </div>
              </>
            )}
            {addFooter ? (
              <>
                <div>
                  <Controller
                    name="invoice_footer"
                    control={control}
                    render={({ field }) => (
                      <>
                        {/* <TextBox
                          {...field}
                          fullWidth
                          name={field.name}
                          autoFocus={addFooterFocus}
                          // autoFocus={true}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "invoice_footer",
                          })}
                          placeholder={intl.formatMessage({
                            id: "invoice_footer",
                          })}
                          value={field.value}
                          size={SIZE.default}
                        /> */}
                        <div>
                          <Controller
                            name="invoice_footer"
                            control={control}
                            render={({ field }) => (
                              <>
                                <TextArea
                                  height="100px"
                                  {...field}
                                  fullWidth
                                  name={field.name}
                                  autoFocus={addFooterFocus}
                                  error={
                                    errors[field.name] &&
                                    errors[field.name].message
                                  }
                                  label={intl.formatMessage({
                                    id: "invoice_footer",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "invoice_footer",
                                  })}
                                  value={field.value}
                                  size={SIZE.mini}
                                />
                              </>
                            )}
                          />
                        </div>
                      </>
                    )}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {/* FOOTER SECTION */}

          {/* UPLOAD SECTION STARTS */}
          <div
            style={{
              display: "flex",
              gap: "15px",
              padding: "15px 0px",
              flexDirection: "column",
            }}
          >
            <div>
              <StatefulPopover
                content={({ close }) => (
                  <div className="cp_email_attachement">
                    {/* [{filename:"From Desktop",}] */}
                    <ParagraphMedium
                      $style={{}}
                      onClick={() => {
                        open();
                        close();
                      }}
                    >
                      From Desktop
                    </ParagraphMedium>
                    <ParagraphMedium
                      onClick={() => {
                        dispatch(documentInvoiceAddingDrawerState(true));
                        dispatch(addingInvoiceDrawerType(EMAIL));
                      }}
                    >
                      From Consumer Drive
                    </ParagraphMedium>
                  </div>
                )}
                returnFocus
                autoFocus
              >
                <Button
                  type="button"
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  overrides={{
                    BaseButton: {
                      style: ({ $theme }) => ({
                        border: "1px solid #CDCED9",
                        borderRadius: "5px",
                      }),
                    },
                  }}
                >
                  <LabelSmall>Add Attachments</LabelSmall>
                </Button>
              </StatefulPopover>
            </div>
            <div
              style={{
                display: "flex",
                gap: "",
                flexDirection: "column",
                marginBottom: "10px",
              }}
            >
              <Label1 style={{ display: "flex", gap: "15px" }}>
                {[
                  ...(acceptedFileValues && acceptedFileValues.length > 4
                    ? slicedValue
                    : acceptedFileValues),
                ].map((file, i) => (
                  <>
                    <div
                      // key={file.path}
                      key={file.name}
                      style={{
                        height: "40px",
                        border: "0.5px solid #CDCED9",
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        borderRadius: "5px",
                        width: "200px",
                        gap: "5px",
                        justifyContent: "space-between",
                        padding: "7px",
                      }}
                    >
                      <div style={{}}>
                        <UploadCustomerInvoice />
                      </div>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          userSelect: "none",
                        }}
                      >
                        {file.name ? file.name : file.file_name}
                        {/* {file.name}||{file.file_name} */}
                      </div>
                      {/* - {file.size} bytes */}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let fl = acceptedFileValues.filter(
                            (e) => e.name !== file.name
                          );
                          setAcceptedFileValue(fl);
                        }}
                      >
                        <DeletePdf />
                      </div>
                    </div>
                  </>
                ))}
              </Label1>

              {selectedDocumentData?.map((attachment, index) => {
                let { tempId } = attachment;
                return (
                  <EmailAttachment
                    key={tempId}
                    {...attachment}
                    id={customerId}
                    currentOrganization={currentOrganization}
                    entity={"customer"}
                    updatingDeskUpload={updatingInvoiceDeskDocument}
                    removeAttchFile={unSelectedDocumentsAddingInvoice}
                  />
                );
              })}

              {/* <div>{fileSizeRejectionItems}</div> */}
            </div>
          </div>
          {/* UPLOAD SECTION ENDS*/}

          {/* LAST SECTION  STARTS*/}
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ paddingTop: "8px" }}>
              <ParagraphMedium style={{ userSelect: "none" }}>
                Notify Consumer Via
              </ParagraphMedium>
            </div>

            <div style={{ width: "350px" }}>
              <Controller
                name="type_of_notes"
                control={control}
                render={({ field }) => (
                  <>
                    <SelectBox
                      clearable={false}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: `type`,
                      })}
                      placeholder={intl.formatMessage({
                        id: "type",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                        setValue("template", []);
                      }}
                      // options={data.map((i) => {
                      //   return {
                      //     label: i.name,
                      //     id: i._id,
                      //     frequency: i.frequency,
                      //   };
                      // })}
                      // options={[{ id: "1", label: "one" }]}
                      options={
                        // referenceData && referenceData["notice_customer_via"]
                        //   ? referenceData["notice_customer_via"]
                        //   : []
                        referenceData["notice_customer_via"].map((e, i) => {
                          return {
                            label: e.label,
                            id: e.id,
                            disabled:
                              e.id === "sms"
                                ? currentOrgIsconfigured &&
                                  currentOrgIsconfigured.is_sms_configured
                                  ? false
                                  : true
                                : e.id === "whatsapp"
                                ? currentOrgIsconfigured &&
                                  currentOrgIsconfigured.is_whatsapp_configured
                                  ? false
                                  : true
                                : false,
                          };
                        })
                      }
                      size={SIZE.default}
                    />
                  </>
                )}
              />
            </div>
            {type_of_notes && type_of_notes[0] && type_of_notes[0].id === "" ? (
              <></>
            ) : (
              <>
                <div style={{ width: "550px" }}>
                  <Controller
                    name="template"
                    control={control}
                    render={({ field }) => (
                      <>
                        <SelectBox
                          {...field}
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: `template`,
                          })}
                          placeholder={intl.formatMessage({
                            id: "template",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            communicationList &&
                            communicationList.data &&
                            communicationList.data.data &&
                            communicationList.data.data.docs.map((e) => {
                              return {
                                id: e._id,
                                label: e.name,
                              };
                            })
                          }
                          size={SIZE.default}
                        />
                      </>
                    )}
                  />
                </div>
              </>
            )}
          </div>
          {/* LAST SECTION ENDS */}

          {/* FINAL SECTION */}
          <div>
            <Controller
              name="internal_notes"
              control={control}
              render={({ field }) => (
                <>
                  <TextArea
                    {...field}
                    fullWidth
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "user_comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "user_comments",
                    })}
                    value={field.value}
                    size={SIZE.mini}
                  />
                </>
              )}
            />
          </div>
          {/* FINAL SECTION */}
        </div>
        {/* FORM BODY ENDS */}

        <div
          style={{
            height: "70px",
            width: "100%",
            background: "#F9F9F9",
            padding: "15px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
            <div style={{ width: "125px" }}>
              <TextButton
                fullWidth
                size={SIZE.compact}
                kind={KIND.tertiary}
                type="button"
                onClick={() => {
                  navigate(`/customers/${getParams}?inv=true`);
                  // if (getType === "ADD-INV") {
                  //   setAddSearchParams({ customers: `${getParams}` });
                  // }
                  // if (getType === "EDIT-INV" || getType == "EDIT-CHARGE") {
                  //   setAddSearchParams({ customers: `${getParams}` });
                  // }
                  // if (getType === "ADD-REC") {
                  //   setAddSearchParams({ customers: `${getParams}` });
                  // }
                  // if (getType === "EDIT-SUB") {
                  //   setAddSearchParams({ customers: `${getParams}` });
                  // }
                  // if (getType === "INV-ADD-INV") {
                  //   navigate("/invoices");
                  // }
                  // if (getType === "INV-ADD-REC") {
                  //   navigate("/invoices");
                  // }
                  // if (getType === "INV-EDIT-INV") {
                  //   navigate("/invoices");
                  // }
                  // if (getType === "ADD-CHARGE") {
                  //   setAddSearchParams({ customers: `${getParams}` });
                  // }
                  // getParams
                  //   ? setAddSearchParams({ customers: `${getParams}` })
                  //   : navigate("/invoices");
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ width: "125px" }}>
              <TextButton
                fullWidth
                size={SIZE.compact}
                type="submit"
                disabled={isSubmitting || scanFinalStatus}
                isLoading={isSubmitting}
              >
                Save
              </TextButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewInvoicingForm;
