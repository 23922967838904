import React, { useContext, useEffect, useMemo, useRef } from "react";
import { Drawer } from "baseui/drawer";
import { useSelector, useDispatch } from "react-redux";
import { drawerClose, emailInitinalState } from "../../../redux/actions";
import Reply from "../../../assets/img/svg/Reply";
import Email from "../../../assets/img/svg/CpCard/Email";
import { useForm, Controller } from "react-hook-form";
import { TextBoxTag } from "../../../components/TextBoxTag/TextBoxTag";
import { useIntl } from "react-intl";
import { HeadingXLarge, ParagraphMedium } from "baseui/typography";
import ReactQuill from "react-quill";
import { TextButton, KIND, SIZE } from "../../../components/TextButton";
import getSelectValues from "../../../utils/getSelectValues";
import { FORWARD, REPLY, REPLY_ALL } from "../../../constants";
import { TextBox } from "../../../components/TextBox";
import setSelectValues from "../../../utils/setSelectValues";
import { Select } from "baseui/select";
import { SelectBox } from "../../../components/SelectBox";
import ReplyAll from "../../../assets/img/svg/ReplyAll";
import Forward from "../../../assets/img/svg/Forward";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  executeAdhocCustomerAction,
  getCommunicationTemplateList,
  getCustomerContactDetail,
} from "../../../services";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { ADD_LIST_VALUES } from "../../../providers/RBACProvider/permissionList";

const EmailType = (props) => {
  switch (props.type) {
    case REPLY:
      return (
        <div style={{ display: "flex", gap: "5px" }}>
          <Reply />
          <ParagraphMedium>Reply</ParagraphMedium>
        </div>
      );
    case REPLY_ALL:
      return (
        <div style={{ display: "flex", gap: "5px" }}>
          <ReplyAll />
          <ParagraphMedium>Reply All</ParagraphMedium>
        </div>
      );

    case FORWARD:
      return (
        <>
          {props.drawerType == "email" ? (
            <div style={{ display: "flex", gap: "5px" }}>
              <Forward />
              <ParagraphMedium>Forward</ParagraphMedium>
            </div>
          ) : (
            ""
          )}
        </>
      );
    default:
      return null;
  }
};

const DrawerCustom = () => {
  let dispatch = useDispatch();
  const intl = useIntl();

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [isEmailOption, setIsEmailOption] = useSearchParams();

  const getParams = getSearchParams.get("customers");
  const getInv = getSearchParams.get("inv");

  const quillRef = useRef(null);

  const openDrawer = useSelector((e) => e.customerOverviewReducer.isDrawerOpen);
  const customerContacts = useSelector(
    (e) => e.customerOverviewReducer.customerConatactDetails
  );

  const { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);

  // const customerContacts = useQuery(
  //   `customer-contacts-${getParams}`,
  //   async () => {
  //     return await getCustomerContactDetail({
  //       organization: currentOrganization,
  //       customerId: getParams,
  //     });
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const executeActionMutation = useMutation(
    (formValues) =>
      executeAdhocCustomerAction({
        ...formValues,
        id: getParams ? getParams : getInv,
        organization: currentOrganization,
        entity: getParams ? "customer" : "invoices",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        dispatch(drawerClose());
      },
    }
  );

  const {
    emailType,
    emailFrom,
    emailTo,
    emailSubject,
    emailBody,
    emailCc,
    emailBcc,
    isReceiver,
    drawerType,
  } = useSelector((e) => e.customerOverviewReducer);

  const emailData = useSelector((e) => e.customerOverviewReducer);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      from: [],
      recipients: [],
      cc: [],
      bcc: [],
      subject: "",
      content: "",
    },
  });

  // let watchIn = watch("recipients");

  // let emailOptions = customerContacts.reduce((prev, curr) => {
  //
  //   let { phone, landline, email, address, ...rest } = curr;
  //   let nestedContacts = email
  //     .map(({ status, email_status, ...nRest }) => {
  //       return {
  //         label: `${nRest?.value}`,
  //         id: nRest?._id,
  //         recipient_id: curr?._id,
  //         email_status,
  //         status,
  //       };
  //     })
  //     .filter(
  //       (e) =>
  //         (e.status === "VALID" || e.status === "LEAD") &&
  //         e.email_status === "VALID"
  //     );

  //   return [...prev, ...nestedContacts];
  // }, []);

  useEffect(() => {
    if (openDrawer) {
      if (emailType == FORWARD) {
        setValue(
          "subject",
          drawerType == "email" ? "Fwd: " + emailSubject : emailSubject
        );
        setValue("content", drawerType != "email" && emailBody);
      } else if (emailType == REPLY) {
        // let findValueOfemail = emailFrom?.map((e) =>
        //   e ? emailOptions?.find((i) => i?.label == e) : []
        // );
        let findValueOfemail = emailFrom?.map((e) => {
          return {
            label: e,
            id: e,
          };
        });
        setValue("recipients", findValueOfemail ? findValueOfemail : []);
        setValue("subject", "Re: " + emailSubject);
      } else if (emailType == REPLY_ALL) {
        // let findValueOfemail = emailFrom?.map((e) =>
        //   e ? emailOptions?.find((i) => i?.label == e) : []
        // );

        let findValueOfemail = emailFrom?.map((e) => {
          return {
            label: e,
            id: e,
          };
        });
        setValue("recipients", findValueOfemail ? findValueOfemail : []);
        setValue("bcc", emailBcc);
        setValue("cc", emailCc);
        setValue("subject", "Re: " + emailSubject);
      }
    }
  }, [emailType, openDrawer]);

  let Block = ReactQuill.Quill.import("blots/block");
  Block.tagName = "DIV";
  ReactQuill.Quill.register(Block, true);

  const onSubmit = async (data) => {
    let toData = data.recipients.map((e) => {
      return {
        value: e.label,
      };
    });

    let { from, to, ...rest } = data;

    let values = {
      ...rest,
      action_type: "email",
      recipients: toData,
      cc: data.cc,
      bcc: data.bcc,
      attachments: [],
      content:
        data.content && drawerType == "email"
          ? data.content + emailBody
          : emailBody,
    };

    await executeActionMutation.mutateAsync(values);
  };

  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);
  return (
    <>
      {emailType ? (
        <Drawer
          isOpen={openDrawer}
          autoFocus
          onClose={() => {
            // dispatch(emailInitinalState())
            reset();
            dispatch(drawerClose());
          }}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "0px",
                marginTop: "0px",
              }),
            },
            DrawerBody: {
              style: ({ $theme }) => ({
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "0px",
                marginTop: "0px",
              }),
            },
            DrawerContainer: {
              style: ({ $theme }) => ({
                marginLeft: "0px",
                marginRight: "0px",
                marginBottom: "0px",
                marginTop: "0px",
              }),
            },
          }}
        >
          <form
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ margin: "10px 30px" }}>
              <EmailType type={emailType} drawerType={drawerType} />

              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Email />
                <HeadingXLarge>Email</HeadingXLarge>
              </div>

              <div>
                <Controller
                  name="recipients"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        {...field}
                        name={field.name}
                        creatable={creatablePermission ? true : false}
                        multi
                        requiredAstric={true}
                        noResultMessage={`${"Email ID is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
                        error={errors[field.name] && errors[field.name].message}
                        options={
                          customerContacts && Array.isArray(customerContacts)
                            ? customerContacts?.reduce((prev, curr) => {
                                let {
                                  phone,
                                  landline,
                                  email,
                                  address,
                                  ...rest
                                } = curr;
                                let nestedContacts =
                                  email &&
                                  email
                                    ?.map(
                                      ({ status, email_status, ...nRest }) => {
                                        return {
                                          label: `${nRest?.value}`,
                                          id: nRest?._id,
                                          recipient_id: curr?._id,
                                          email_status,
                                          status,
                                        };
                                      }
                                    )
                                    .filter(
                                      (e) =>
                                        (e.status === "VALID" ||
                                          e.status === "LEAD") &&
                                        e.email_status === "VALID"
                                    );

                                return [...prev, ...nestedContacts];
                              }, [])
                            : []

                          // customerContacts &&
                          // customerContacts
                          //   .map((e) => {
                          //     let designation = referenceData[
                          //       "recipient_type"
                          //     ].find((rf) => rf.id === e.designation);

                          //     if (
                          //       designation &&
                          //       e.email &&
                          //       e.email[0] &&
                          //       e.email[0].value
                          //     ) {
                          //       let label = designation.label || "";

                          //       return {
                          //         id: e._id,
                          //         // label: `${e.first_name || ""} - ${label}`,
                          //         label: `${e.first_name || ""} - ${label} - ${
                          //           e?.email[0]?.value || ""
                          //         }`,
                          //       };
                          //     }

                          //     return null;
                          //   })
                          //   .filter((con) => con)
                        }
                        label={intl.formatMessage({
                          id: "to",
                        })}
                        placeholder={intl.formatMessage({
                          id: "bcc",
                        })}
                        labelKey="label"
                        valueKey="id"
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        value={field.value}
                        validation={true}
                        validationFunc={(value) => {
                          let rg = new RegExp(
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                          );
                          return rg.test(value);
                        }}
                        setError={() =>
                          setError(field.name, {
                            type: String,
                            message: "Invalid Email ID",
                          })
                        }
                        clearErrors={() => {
                          clearErrors(field.name, {
                            message: "",
                          });
                        }}
                      />
                    </>
                  )}
                />
                <Controller
                  name="cc"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBoxTag
                        // disabled={disableContent}
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "cc_e",
                        })}
                        placeholder={intl.formatMessage({
                          id: "bcc",
                        })}
                        value={field.value}
                        validation={true}
                        validationFunc={(value) => {
                          let rg = new RegExp(
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                          );
                          return rg.test(value);
                        }}
                        setError={() =>
                          setError(field.name, {
                            type: String,
                            message: "Email is Not Valid",
                          })
                        }
                        clearErrors={() => {
                          clearErrors(field.name, {
                            message: "",
                          });
                        }}
                      />
                    </>
                  )}
                />
                <Controller
                  name="bcc"
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBoxTag
                        // disabled={disableContent}
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "bcc1",
                        })}
                        placeholder={intl.formatMessage({
                          id: "bcc",
                        })}
                        value={field.value}
                        validation={true}
                        validationFunc={(value) => {
                          let rg = new RegExp(
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                          );
                          return rg.test(value);
                        }}
                        setError={() =>
                          setError(field.name, {
                            type: String,
                            message: "Email is Not Valid",
                          })
                        }
                        clearErrors={() => {
                          clearErrors(field.name, {
                            message: "",
                          });
                        }}
                      />
                    </>
                  )}
                />
                <Controller
                  name="subject"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "subject",
                      })}
                      placeholder={intl.formatMessage({
                        id: "subject",
                      })}
                      value={field.value}
                    />
                  )}
                />
                {drawerType && (
                  <div className="cp-quill --cp-cnt-quill">
                    <Controller
                      name="content"
                      control={control}
                      render={({ field }) => (
                        <ReactQuill
                          ref={quillRef}
                          theme="snow"
                          value={field.value}
                          onChange={(content) => {
                            field.onChange(content);
                          }}
                          // modules={modal}
                          placeholder={"Write something awesome..."}
                          formats={[
                            "header",
                            "font",
                            "background",
                            "color",
                            "code",
                            "size",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "indent",
                            "script",
                            "align",
                            "direction",
                            "link",
                            "image",
                            "code-block",
                            "table",
                          ]}
                          style={{ height: "fit-content" }}
                        />
                      )}
                    />
                  </div>
                )}

                <div style={{ flex: "1" }}>
                  {drawerType == "email" && (
                    <iframe
                      style={{
                        height: "100%",
                        width: "100%",
                        padding: "10px 0px 10px 10px",
                      }}
                      className="scroll"
                      srcDoc={emailBody}
                      // src="demo_iframe_srcdoc.htm"
                    ></iframe>
                  )}
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#F9F9F9",
                padding: "15px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <div style={{ width: "100px" }}>
                <TextButton
                  size={SIZE.mini}
                  fullWidth
                  type="button"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={async () => {
                    const result = await trigger();

                    if (result) {
                      const values = getValues();
                      onSubmit(values);
                    }
                  }}
                >
                  Send
                </TextButton>
              </div>
            </div>
          </form>
        </Drawer>
      ) : (
        <></>
      )}
    </>
  );
};

export default DrawerCustom;
