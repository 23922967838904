function InfoTag() {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 13.5C8.505 13.5 8.1 13.095 8.1 12.6V9C8.1 8.505 8.505 8.1 9 8.1C9.495 8.1 9.9 8.505 9.9 9V12.6C9.9 13.095 9.495 13.5 9 13.5ZM9.9 6.3H8.1V4.5H9.9V6.3Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default InfoTag;
