function SkippedTimeLineCard({ height = 18, width = 18, color = "#FD372A" }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.37041 16.3352L11.0275 10.2293C11.2224 10.091 11.3815 9.90743 11.4909 9.69498C11.6007 9.48208 11.6577 9.24657 11.6577 9.00745C11.6577 8.76788 11.6007 8.53238 11.4909 8.31948C11.3815 8.10703 11.2224 7.92396 11.0275 7.78518L2.37041 1.6639C2.14485 1.50659 1.88041 1.41393 1.60604 1.39584C1.33121 1.37822 1.05728 1.43563 0.813185 1.56264C0.569093 1.68921 0.364328 1.88042 0.220585 2.11502C0.0772937 2.35007 0.00135607 2.61947 0 2.89475V15.1043C0.00135607 15.3792 0.0772937 15.6486 0.220585 15.8836C0.364328 16.1182 0.569093 16.3094 0.813185 16.4364C1.05728 16.563 1.33121 16.6209 1.60604 16.6028C1.88041 16.5852 2.14485 16.4925 2.37041 16.3352ZM15.0004 1.50026V16.5002C15.0004 16.898 15.1581 17.2795 15.4397 17.5611C15.7209 17.8422 16.1024 18.0004 16.5002 18.0004C16.898 18.0004 17.2795 17.8422 17.5611 17.5611C17.8422 17.2795 18 16.898 18 16.5002V1.50026C18 1.10248 17.8422 0.720974 17.5611 0.439364C17.2795 0.158205 16.898 0 16.5002 0C16.1024 0 15.7209 0.158205 15.4397 0.439364C15.1581 0.720974 15.0004 1.10248 15.0004 1.50026Z"
          fill={color}
        />
      </svg>
    </>
  );
}

export default SkippedTimeLineCard;
