import React from "react";

const UploadFile = ({ size = 75, height = 50 }) => {
  return (
    <svg
      width={size}
      height={height}
      viewBox="0 0 75 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.4688 18.8373C58.3438 8.07759 48.875 0 37.5 0C28.4688 0 20.625 5.11477 16.7188 12.5998C7.3125 13.5978 0 21.5506 0 31.1876C0 41.5107 8.40625 49.9002 18.75 49.9002H59.375C68 49.9002 75 42.9142 75 34.3064C75 26.0729 68.5938 19.3987 60.4688 18.8373ZM59.375 43.6627H18.75C11.8438 43.6627 6.25 38.0801 6.25 31.1876C6.25 24.7942 11.0312 19.4611 17.375 18.8061L20.7188 18.4631L22.2812 15.5003C25.25 9.79292 31.0625 6.23752 37.5 6.23752C45.6875 6.23752 52.75 12.0384 54.3438 20.0536L55.2812 24.7318L60.0625 25.0749C64.9375 25.3867 68.75 29.4723 68.75 34.3064C68.75 39.4523 64.5312 43.6627 59.375 43.6627ZM25 28.0689H32.9688V37.4251H42.0312V28.0689H50L37.5 15.5938L25 28.0689Z"
        fill="#333860"
      />
    </svg>
  );
};

export default UploadFile;
