import React, { useContext, useEffect } from "react";
import {
  getWhatsAppCus,
  getInformation,
  getNotificationCalendar,
  informationMarkAllRead,
} from "../../services";
import WhatsApp from "../../assets/img/svg/Template/WhatsApp";
import { useDispatch, useSelector } from "react-redux";
import {
  HeadingXSmall,
  LabelMedium,
  ParagraphLarge,
  HeadingXLarge,
} from "baseui/typography";
import { Tab, Tabs } from "baseui/tabs";
import { useState } from "react";
import { Drawer, ANCHOR, SIZE } from "baseui/drawer";
import { Avatar } from "baseui/avatar";
import { FormattedTime, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import WhatsappNotification from "./WhatsappNotification";
import moment from "moment";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { CUSTOMER_OVERVIEW } from "../../constants";
import {
  useInfiniteQuery,
  useQuery,
  useIsFetching,
  useMutation,
} from "react-query";
import Information from "./Information/Information";
import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from "baseui/checkbox";
import Calendar from "./Calendar/Calendar";
import { functions } from "lodash";
import { SelectBox } from "../SelectBox";
import * as FilterObj from "../SelectBox";
import { IconButton, KIND } from "../IconButton";
import { StatefulPopover } from "baseui/popover";
import AllFiltersIcon from "../../assets/img/svg/AllFiltersIcon";
import { Button, SHAPE } from "baseui/button";
import { TextButton } from "../TextButton";
import { ModalContext } from "../../providers/ModalProvider";
import warningImage from "../../assets/img/warning.png";
import { toast } from "react-toastify";
import { Select } from "baseui/select";
import { notificationStatus } from "../../services/users";
import CalenderTab from "./CalenderTab";
import { CLIENT_PORTAL_ACCESS } from "../../providers/RBACProvider/permissionList";
import _ from "lodash";
import {
  setNotificationInfoUnreadOnly,
  setNotificationInfoType,
  setNotificationTab,
} from "../../redux/notification/action";
import { Typography } from "../../components_v2";
import useFormat from "../../hooks/useFormat";

function NotificationDrawer_V2(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { referenceData, currentOrganization, appAccessRest, space } =
    useContext(MaxyfiContext);
  const { activeKey, information } = useSelector((s) => s.notification);
  const format = useFormat();
  const isClientAccess =
    space && space?.role_permissions?.includes(CLIENT_PORTAL_ACCESS);

  const [activeKeydd, setActiveKey] = React.useState("0");
  const [SelectedChat, setSelectedChat] = useState({});
  const [login, setLogin] = useState(false);
  const [count, setCount] = useState();
  const [infoCount, setInfoCount] = useState(1);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    if (isClientAccess) {
      dispatch(setNotificationInfoType([{ label: "Mention", id: "MENTION" }]));
    }
  }, [isClientAccess]);

  const ref = React.useRef(null);

  const { isLoading, data, isFetching, refetch } = useQuery(
    [
      `${CUSTOMER_OVERVIEW}-WHATSAPP-CHATS_${props.isCount}_${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getWhatsAppCus({
        organization: currentOrganization,
        filters,
      });
    },
    {
      // refetchOnMount: false,
      // refetchOnWindowFocus: false,
    }
  );

  const markAllAsReadFn = useMutation(
    (data) =>
      informationMarkAllRead({
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data?.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        setInfoCount(infoCount + 1);
      },
    }
  );

  const notification = useInfiniteQuery(
    [
      `NOTIFICATION_${currentOrganization}`,
      {
        filter: {
          ...(information.unreadOnly ? { notification_status: "UNREAD" } : {}),
          ...(information.type &&
          information.type[0] &&
          information.type[0].id &&
          information.type[0].id !== "ALL"
            ? {
                notification_type: information.type[0].id,
              }
            : {}),
          ...appAccessRest,
        },
      },
    ],
    async ({ pageParam = 1, queryKey }) => {
      let { filter } = queryKey[1];
      return await getInformation({
        organization: currentOrganization,
        filter,
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        if (allPages.length < maxPages) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const tabCountNotification = useQuery(
    [
      `tab_notification_count-${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
          statusType: "drawer",
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await notificationStatus({
        org_id: currentOrganization,
        filters,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchIntervalInBackground: true,
      refetchInterval: 120000,
    }
  );

  const onScroll = async (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (
      !notification.isFetchingNextPage &&
      scrollHeight - scrollTop <= clientHeight * 1.5
    ) {
      // fetching = true;
      if (notification.hasNextPage)
        await notification.fetchNextPage({ cancelRefetch: false });
      // fetching = false;
    }
  };

  useEffect(() => {
    const dev = ref.current;
    if (ref.current) {
      dev.addEventListener("scroll", onScroll);
    }
    return () => {
      ref?.current?.removeEventListener("scroll", onScroll);
    };
  }, [ref, ref.current, onScroll]);

  // SETVALUE FOR INFORMATION DATA

  useEffect(() => {
    if (notification && notification.data && notification.data.data) {
      setNotificationData(
        notification &&
          notification.data &&
          notification.data.data &&
          notification.data.data.docs
      );
    }
  }, [notification]);

  // SETVALUE FOR CALENDAR DATA

  const navigate = useNavigate();

  // INFORMATION NOTICATION COUNT STARTS

  let informationNotificationLength;
  if (notificationData) {
    informationNotificationLength =
      notificationData &&
      notificationData?.filter((e) => e.notification_status === "UNREAD")
        ?.length;
  }

  // INFORMATION NOTICATION COUNT ENDS

  // CALENDAR NOTIFICATION STARTS

  // CALENDAR NOTIFICATION ENDS

  return (
    <div style={{ width: "400px" }}>
      <div
        style={{
          // width: "26%",
          backgroundColor: "#fff",
          gridColumnStart: "2",
          top: "0",
          height: "calc(100vh - 26px)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 9px",
          }}
        >
          <HeadingXLarge
            style={{ color: "#333860", fontVariantLigatures: "none" }}
          >
            Notifications
          </HeadingXLarge>
        </div>

        {activeKey == 0 ? (
          <div style={{ position: "absolute", right: "8px", marginTop: "8px" }}>
            <TextButton
              kind={KIND.tertiary}
              size={FilterObj.SIZE.mini}
              onClick={async () => {
                let markAllAsRead = {
                  ...(information.type
                    ? {
                        notification_type:
                          information.type &&
                          information.type[0] &&
                          information.type[0].id,
                      }
                    : {}),
                  organization_id: currentOrganization,
                  appAccessRest: appAccessRest,
                };
                await markAllAsReadFn.mutateAsync({ ...markAllAsRead });
              }}
            >
              Mark all as Read
            </TextButton>
          </div>
        ) : (
          <></>
        )}
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => {
            dispatch(setNotificationTab(activeKey));
          }}
          overrides={{
            Root: {
              style: ({ $theme }) => ({
                paddingLeft: "0",
                paddingRight: "0",
                outline: "none",
              }),
            },
            TabBar: {
              style: ({ $theme }) => ({
                backgroundColor: "#fff",
                outline: "none",
                paddingLeft: "0",
                paddingRight: "0",
                paddingTop: "0px",
                marginLeft: "6px",
              }),
            },
            TabContent: {
              style: ({ $theme }) => ({
                paddingLeft: "0",
                paddingRight: "0",
                paddingTop: "0px",
                paddingBottom: "1px",
                outline: "none",
              }),
            },
            Tab: {
              style: ({ $theme }) => ({
                backgroundColor: "#fff",
                paddingLeft: "0",
                paddingRight: "0",
                paddingTop: "3px",
                paddingBottom: "1px",
                outline: "none",
                fontSize: "16px",
                fontWeight: "500px",
                height: "100%",
                fontWeight: "500",
              }),
            },
            Tabpanel: {
              style: ({ $theme }) => ({
                height: "100vh",
              }),
            },
            TabHighlight: {
              style: ({ $theme }) => ({
                display: "none",
              }),
            },
          }}
        >
          <Tab
            title={
              <div
                className={activeKey === "0" ? "tab-active" : "tab-isactive"}
                style={{ position: "relative" }}
                onClick={() => {
                  setLogin(false);
                  setCount();
                }}
              >
                {tabCountNotification &&
                tabCountNotification.data &&
                tabCountNotification.data.data &&
                tabCountNotification.data.data.notifications ? (
                  <>
                    {" "}
                    <div
                      style={{
                        position: "absolute",
                        height: "13px",
                        width: "13px",
                        backgroundColor: "#FD372A",
                        // textAlign: "center",
                        borderRadius: "50%",
                        right: "-4px",
                        top: "-5px",
                        color: "#ffffff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    ></div>
                  </>
                ) : (
                  <></>
                )}

                <div
                  className={activeKey === "1" ? "noti" : "noti-isactive"}
                  style={{ paddingTop: "4px" }}
                >
                  <i className="mx-icon-info" />
                </div>
              </div>
            }
            overrides={{
              Tab: {
                style: ({ $theme }) => ({
                  borderBottom: "0px",
                }),
              },
            }}
          >
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "15px 10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <Checkbox
                    checked={information.unreadOnly}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    onChange={(e) => {
                      // setChecked(e.target.checked);
                      dispatch(
                        setNotificationInfoUnreadOnly(!information.unreadOnly)
                      );
                    }}
                    labelPlacement={LABEL_PLACEMENT.right}
                    overrides={{
                      Checkmark: {
                        style: ({ $theme, $checked }) => ({
                          height: "18px",
                          width: "18px",
                          backgroundColor: $checked ? "#516BEB" : null,
                          borderRadius: $theme.borders.radius200,
                          borderBottomColor: $checked
                            ? $theme.colors.primary
                            : `#CDCED9`,
                          borderTopColor: $checked
                            ? $theme.colors.primary
                            : `#CDCED9`,
                          borderRightColor: $checked
                            ? $theme.colors.primary
                            : `#CDCED9`,
                          borderLeftColor: $checked
                            ? $theme.colors.primary
                            : `#CDCED9`,
                          borderBottomWidth: "0.5px",
                          borderTopWidth: "0.5px",
                          borderRightWidth: "0.5px",
                          borderLeftWidth: "0.5px",
                          borderStyle: "solid",
                          backgroundImage: $checked
                            ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                            : "none",
                          backgroundSize: "auto",
                        }),
                      },
                      Label: {
                        style: ({ $theme }) => ({
                          paddingLeft: "3px",
                        }),
                      },
                    }}
                  >
                    <ParagraphLarge>Unread Only</ParagraphLarge>
                  </Checkbox>
                </div>
                <div style={{ width: "170px" }}>
                  <Select
                    size="mini"
                    backspaceRemoves={false}
                    clearable={false}
                    // disabled={isClientAccess ? true : false}
                    overrides={{
                      Tag: {
                        props: {
                          overrides: {
                            Text: {
                              style: ({ $theme }) => ({
                                maxWidth: "500px",
                              }),
                            },
                          },
                        },
                      },
                      Popover: {
                        props: {
                          overrides: {
                            Body: {
                              style: ({ $theme }) => ({
                                zIndex: "99",
                              }),
                            },
                          },
                        },
                      },
                      // Placeholder: {
                      //   style: ({ $theme, $isFocused }) => ({
                      //     color: $isFocused ? "#cbd5e0" : "transparent",
                      //     transition: "color 0.4s ease",
                      //   }),
                      // },
                      ControlContainer: {
                        style: ({ $theme, $isFocused, $error }) => ({
                          borderRadius: "5px",
                          backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                          borderRadius: "3px",
                          borderColor: "#CDCED9",
                          borderWidth: "0.5px",
                          borderStyle: "solid",
                          paddingRight: "0px",
                          backgroundColor: "#ffffff",
                          borderRadius: "5px",
                          borderWidth: "1px",
                          borderColor: $isFocused
                            ? $error
                              ? "#FD372A"
                              : "#516BEB"
                            : $error
                            ? "#FD372A"
                            : "#CDCED9",
                        }),
                      },
                    }}
                    options={
                      isClientAccess
                        ? [
                            { label: "Mention", id: "MENTION" },
                            {
                              label: "Request Information",
                              id: "REQUEST_INFO",
                            },
                          ]
                        : referenceData["notification_type_list"]
                    }
                    value={information.type}
                    placeholder="Filters"
                    onChange={(e) => {
                      // setIsInfoFilters(e.value);
                      dispatch(setNotificationInfoType(e.value));
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  overflow: "scroll",
                  height: "calc(100vh - 180px)",
                  paddingBottom: "3px",
                }}
                ref={ref}
              >
                {notification &&
                  notification.data &&
                  Array.isArray(notification.data.pages) &&
                  notification.data.pages.map((option) => {
                    if (
                      option &&
                      option.data &&
                      option.data.docs &&
                      Array.isArray(option.data.docs)
                    ) {
                      return (
                        option &&
                        option.data &&
                        option.data.docs.map((e, i) => (
                          <Information
                            key={i}
                            data={e}
                            setInfoCount={setInfoCount}
                            infoCount={infoCount}
                            checked={information.unreadOnly}
                            close={props}
                          />
                        ))
                      );
                    }
                    return <></>;
                  })}
              </div>
            </>
          </Tab>
          {!isClientAccess && (
            <Tab
              title={
                <div
                  className={activeKey === "1" ? "tab-active" : "tab-isactive"}
                  style={{ position: "relative" }}
                  onClick={() => {
                    setLogin(false);
                    setCount();
                  }}
                >
                  {tabCountNotification &&
                  tabCountNotification.data &&
                  tabCountNotification.data.data &&
                  tabCountNotification.data.data.calendar_event ? (
                    <>
                      {" "}
                      <div
                        style={{
                          position: "absolute",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#FD372A",
                          // textAlign: "center",
                          borderRadius: "50%",
                          right: "-4px",
                          top: "-5px",
                          color: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {props &&
                          props.tabCounts &&
                          props.tabCounts.calendar_event_count}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div
                    className={activeKey === "2" ? "noti" : "noti-isactive"}
                    style={{ paddingTop: "4px" }}
                  >
                    <i className="mx-icon-calendar" />
                  </div>
                </div>
              }
              overrides={{
                Tab: {
                  style: ({ $theme }) => ({
                    borderBottom: "0px",
                  }),
                },
              }}
              // title="Information"
            >
              <CalenderTab {...props} />
            </Tab>
          )}
          {!isClientAccess && (
            <Tab
              title={
                <div
                  className={activeKey === "2" ? "tab-active" : "tab-isactive"}
                  style={{ position: "relative" }}
                  onClick={() => {
                    setLogin(false);
                    setCount();
                  }}
                >
                  {tabCountNotification &&
                  tabCountNotification.data &&
                  tabCountNotification.data.data &&
                  tabCountNotification.data.data.conversations ? (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          height: "13px",
                          width: "13px",
                          backgroundColor: "#FD372A",
                          // textAlign: "center",
                          borderRadius: "50%",
                          right: "-5px",
                          top: "-9px",
                          color: "#ffffff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></div>
                    </>
                  ) : (
                    <></>
                  )}
                  <WhatsApp />
                </div>
              }
              overrides={{
                Tab: {
                  style: ({ $theme }) => ({
                    borderBottom: "0px",
                  }),
                },
              }}
            >
              <>
                {/* search box start here */}

                <div
                  style={{
                    overflow: "scroll",
                    height: "calc(100vh - 135px)",
                    paddingBottom: "3px",
                    paddingTop: "10px",
                  }}
                >
                  {_.get(data, "data.docs.docs", []).length > 0 &&
                    _.get(data, "data.docs.docs", []).map((item, index) => {
                      return (
                        <div
                          className={`header_card ${
                            index == count ? "active" : "header_card"
                          }`}
                          onClick={() => {
                            setSelectedChat({
                              customer_id: _.get(item, "customer_id", ""),
                              phone_code: _.get(item, "phone.code", ""),

                              phone_number: _.get(item, "phone.value", ""),
                              ...(_.get(item, "dn", "")
                                ? {
                                    name: _.get(item, "dn", ""),
                                  }
                                : {}),
                            });
                            setLogin(true);
                            setCount(index);
                          }}
                        >
                          <div>
                            {_.get(item, "dn", "") ? (
                              <Avatar
                                name={_.get(item, "dn", "")}
                                size="scale950"
                                overrides={{
                                  Root: {
                                    style: ({ $theme }) => ({
                                      backgroundColor: "#4BC4ED",
                                      borderRadius: "50%",
                                    }),
                                  },
                                  Avatar: {
                                    style: ({ $theme }) => ({
                                      backgroundColor: "#4BC4ED",
                                      borderRadius: "50%",
                                    }),
                                  },
                                  Initials: {
                                    style: ({ $theme }) => ({
                                      backgroundColor: "#4BC4ED",
                                      borderRadius: "50%",
                                    }),
                                  },
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flex: "1",
                              gap: "10px",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                gap: "9px",
                                width: "100%",
                              }}
                            >
                              <Typography
                                type="h4"
                                className="regular"
                                // onClick={() =>
                                //   navigate(
                                //     `/customers/${_.get(
                                //       item,
                                //       "customer_id",
                                //       ""
                                //     )}`
                                //   )
                                // }
                              >
                                {_.get(item, "dn", "")}
                              </Typography>
                              <Typography
                                type="p"
                                className="text-secondary"
                                subType="regular"
                              >
                                {_.get(item, "last_message_make_at", null)
                                  ? format.date({
                                      value: _.get(
                                        item,
                                        "last_message_make_at",
                                        null
                                      ),
                                    })
                                  : ""}
                              </Typography>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="whatsapp_sub-text">
                                <LabelMedium
                                  style={{
                                    width: "190px",
                                    fontSize: "14px",
                                    fontWeight:
                                      _.get(item, "unread_message_count", 0) > 0
                                        ? "500"
                                        : "400",
                                  }}
                                >
                                  {_.get(item, "last_message_content.body", "")}
                                </LabelMedium>
                              </div>
                              {_.get(item, "unread_message_count", "") > 0 && (
                                <div
                                  style={{
                                    background: "#4CAF50",
                                    borderRadius: "50%",
                                    width: "23px",
                                    height: "23px",
                                    color: "#FFFFFF",
                                    textAlign: "center",
                                  }}
                                >
                                  {_.get(item, "unread_message_count", "")}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* w */}
              </>
            </Tab>
          )}
        </Tabs>
      </div>
      {login ? (
        <div
          style={{
            backgroundColor: "#F8F8FB",
            height: "100vh",
            flex: "1",
          }}
        >
          <WhatsappNotification
            data={SelectedChat}
            whatsappListRefetch={refetch}
            setIsOpen={props.setIsOpen}
            isRefetch={props.refetch}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default NotificationDrawer_V2;
