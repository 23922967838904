import React from "react";

function ReleaseAction({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.64484 13.9114C3.43221 14.0019 3.22926 13.9852 3.03599 13.8612C2.84272 13.7373 2.74609 13.5601 2.74609 13.3296V10.1941L7.75662 8.99047L2.74609 7.79049V4.65493C2.74609 4.42448 2.84272 4.24728 3.03599 4.12333C3.22926 3.99937 3.43221 3.98145 3.64484 4.06957L13.8907 8.40129C14.1494 8.51611 14.2787 8.71356 14.2787 8.99364C14.2787 9.27374 14.1494 9.47028 13.8907 9.58326L3.64484 13.9114Z"
        fill={color}
      />
    </svg>
  );
}

export default ReleaseAction;
