import React from "react";
import { Card, StyledBody, StyledAction } from "baseui/card";
import CardLayout from "../Card";

const CardWidgetSix = () => {
  return (
    <CardLayout>
      <StyledBody style={{ lineHeight: "10px" }}>
        <div
          style={{
            color: "#666666",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "13px",
            fontWeight: "700",
            lineHeight: "15px",
          }}
        >
          Contact in months
        </div>
      </StyledBody>
      <StyledAction>
        <div
          style={{
            fontSize: "48px",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: "10px",
            color: "#666666",
          }}
        >
          12
        </div>
      </StyledAction>
    </CardLayout>
  );
};
export default CardWidgetSix;
