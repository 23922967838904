import { ParagraphMedium } from "baseui/typography";
import moment from "moment";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import React, { useContext, useState, useEffect } from "react";
import {
  TextButton,
  SIZE,
  KIND,
} from "../../../../../../components/TextButton";
import { IconButton } from "../../../../../../components/IconButton";
import LocationFL from "../../../../../../assets/img/svg/LocationFL";
import FieldVisitAudit from "./FieldVisitAudit";
import { ModalContext } from "../../../../../../providers/ModalProvider";
import Warning from "../../../../../../assets/img/warning.png";
import { useDispatch } from "react-redux";
import Cancel from "../../../../../../assets/img/svg/Template/Cancel";
import User from "../../../../../../assets/img/svg/Upload/User";
import FieldVisitCancel from "./FieldVisitCancel";

const FieldVisitCard = ({
  data,
  setCapture,
  setCaptureData,
  setFieldVisitDrawer,
  getParams,
  setFieldVisitEditData,
}) => {
  const [userName, setUserName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { handleModal } = useContext(ModalContext);
  const [dueDate, setDueDate] = useState("");

  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    users,
  } = useContext(MaxyfiContext);

  const orgRef = referenceData.organizations.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef.time_zone;
  //   const [capture, setCapture] = useState(false);

  useEffect(() => {
    if (data) {
      let user = "";
      users.filter((e) => {
        if (e.id === data?.created_by) {
          user = e.displayName;
        }
      });
      setUserName(user);
    }
  }, [data]);

  let selectedTime =
    referenceData["time"] &&
    referenceData["time"]?.find((e) => e?.id === data?.visit_time);

  let msg;

  useEffect(() => {
    let dayDiff = moment
      .utc(data.visit_date)
      .tz(timeZone)
      .diff(moment.tz(timeZone), "days", true);

    dayDiff = Math.ceil(dayDiff);

    if (dayDiff == 0) {
      setDueDate("Due Today");
      // actionData.isAlert = true;
    } else if (dayDiff > 0) {
      setDueDate(`In ${dayDiff} Day${dayDiff > 1 ? "s" : ""}`);
      // msg = ;
    } else {
      // msg = "Over Due";
      setDueDate("Over Due");
      // actionData.isAlert = true;
    }
  }, [data]);

  return (
    <>
      <div
        style={{
          paddingTop: "25px",
          paddingLeft: "25px",
          paddingRight: "25px",
          paddingBottom: "3px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "93px",
            border: "0.5px solid #cdced9",
            borderRadius: "5px",
            // boxShadow: "",
            boxShadow: "0px 2px 2px 0px #00000025",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              padding: "10px",
            }}
          >
            {/* FV TOP */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                <ParagraphMedium style={{ color: "#787878" }}>
                  Visit Time :{" "}
                </ParagraphMedium>
                <ParagraphMedium style={{ color: "#333860" }}>
                  {moment
                    .utc(data?.visit_date)
                    .tz(timeZone)
                    .format(currentDefaultFormatDetails.date_format)}{" "}
                  {selectedTime?.label}
                </ParagraphMedium>

                <ParagraphMedium
                  style={{
                    color: "#787878",
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  {dueDate.startsWith("D") ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      ({" "}
                      <div
                        style={{
                          height: "8px",
                          width: "8px",
                          borderRadius: "50px",
                          backgroundColor: "#FBBD04",
                        }}
                      ></div>
                      <ParagraphMedium>{dueDate}</ParagraphMedium> )
                    </div>
                  ) : dueDate.startsWith("O") ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        (
                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            borderRadius: "50px",
                            backgroundColor: "#FD372A",
                          }}
                        ></div>
                        <ParagraphMedium>{dueDate}</ParagraphMedium>
                      </div>{" "}
                      ){" "}
                    </>
                  ) : (
                    <>
                      <ParagraphMedium>( {dueDate} )</ParagraphMedium>
                    </>
                  )}
                </ParagraphMedium>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <TextButton
                  onClick={() => {
                    setCapture(true);
                    setCaptureData([data]);
                    // setFieldVisitDrawer(true);
                  }}
                  type="button"
                  kind={KIND.secondary}
                  size={SIZE.mini}
                >
                  Capture
                </TextButton>

                <IconButton
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  type="button"
                  onClick={() => {
                    setFieldVisitDrawer(true);
                    setFieldVisitEditData(data);
                  }}
                >
                  <i className="mx-icon-Vector-7 font_size_icon color_gr" />
                </IconButton>

                <IconButton
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  type="button"
                  onClick={() => {
                    handleModal({
                      title: "",
                      content: "Would you like to Cancel the Field Visit?",
                      successCallback: () => {
                        // deleteTemplate.mutate(_id);
                      },
                      formContent: ({ handleModal }) => {
                        return (
                          <>
                            <FieldVisitCancel
                              handleModal={handleModal}
                              getParams={getParams}
                              fieldId={data._id}
                            />
                          </>
                        );
                      },
                      cancelCallback: () => {},
                      isChildren: true,
                      image: Warning,
                      buttonText: "",
                      closeButtonText: "",
                      isCloseAble: false,
                    });
                  }}
                >
                  <Cancel />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                <LocationFL />
                <ParagraphMedium style={{ color: "#333860" }}>
                  {data?.custom_location && data?.custom_location?.length > 0
                    ? data.custom_location
                    : data?.address
                    ? `${data && data?.contact && data?.contact?.name} ${
                        data.address.line_1 ? data.address.line_1 : ""
                      } ${data.address.line_2 ? data.address.line_2 : ""} ${
                        data.address.city ? data.address.city : ""
                      }
                                   ${
                                     data.address.state
                                       ? data.address.state
                                       : ""
                                   }
                                   ${
                                     data.address.country
                                       ? data.address.country
                                       : ""
                                   }
                                   ${
                                     data.address.zip_code
                                       ? data.address.zip_code
                                       : ""
                                   }.

                                  `
                    : data && data.custom_location}
                </ParagraphMedium>
              </div>
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <User />
                <ParagraphMedium style={{ color: "#333860" }}>
                  {/* {e.userName} {e.user} */}
                  {userName}{" "}
                  {moment
                    .utc(data?.created_at)
                    .format(currentDefaultFormatDetails.date_format)}{" "}
                </ParagraphMedium>
              </div>
            </div>
            {/* FV BOTTOM */}
          </div>
        </div>
      </div>
      <FieldVisitAudit
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        audit={data && data.audits}
      />
    </>
  );
};

export default FieldVisitCard;
