export const ADD_SMS_ATTACHMENT = "ADD_SMS_ATTACHMENT";
export const SMS_UNSELECTED_DOCUMENT_DATA = "SMS_UNSELECTED_DOCUMENT_DATA";
export const UPDATE_SMS_ATTACHMENT = "UPDATE_SMS_ATTACHMENT";
export const SMS_DOCUMENT_DRIVE_DATA = "SMS_DOCUMENT_DRIVE_DATA";
export const SMS_ACTION_INITAL = "SMS_ACTION_INITAL";

export const addSMSAttachment = (payload) => {
  return {
    type: ADD_SMS_ATTACHMENT,
    payload,
  };
};

export const smsUnSelectedDocumentData = (data) => {
  return {
    type: SMS_UNSELECTED_DOCUMENT_DATA,
    payload: data,
  };
};

export const updateSMSAttachment = (payload) => {
  return {
    type: UPDATE_SMS_ATTACHMENT,
    payload,
  };
};

export const smsDocumentDrive = (data) => {
  return {
    type: SMS_DOCUMENT_DRIVE_DATA,
    payload: data,
  };
};

export const smsActionInitial = () => {
  return {
    type: SMS_ACTION_INITAL,
  };
};
