import React from "react";

function SetWorkFlow({ size = "22px" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M15.5833 14.6667L11.9167 11V8.085C12.98 7.7 13.75 6.69167 13.75 5.5C13.75 3.97833 12.5217 2.75 11 2.75C9.47833 2.75 8.25 3.97833 8.25 5.5C8.25 6.69167 9.02 7.7 10.0833 8.085V11L6.41667 14.6667H2.75V19.25H7.33333V16.4542L11 12.6042L14.6667 16.4542V19.25H19.25V14.6667H15.5833Z"
        fill="#333860"
      />
    </svg>
  );
}

export default SetWorkFlow;
