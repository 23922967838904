import { LabelLarge } from "baseui/typography";
import React, { useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import {
  deleteDairyNote,
  getDairyNotes,
  useDeleteDairyNote,
  usedeleteDairyNote,
} from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  closeModal,
  resetDiaryNoteReduxState,
  refetchDiaryNotes,
  changeActionType,
} from "../../../redux/customerDairyNote/action";
import { EDIT, NEW } from "../../../constants";
import { refetchInvoiceActions } from "../../../redux/invoiceOverview/action";
import Plus from "../../../assets/img/svg/Upload/Plus";
import Cancel from "../../../assets/img/svg/Template/Cancel";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import NoteCard from "./NoteCard";
import NoteFormContainer from "./NoteFormContainer";
import { TextButton, KIND, SIZE } from "../../../components/TextButton";
import Loader from "../../../components/Loader";

const NoteContainer = (props) => {
  const {
    resourceName,
    resourceId,
    entity,
    createDiaryNoteService,
    editDairyNote,
    addNotePermission,
    editNotePermission,
    deleteNotePermission,
  } = props;

  const dispatch = useDispatch();
  let { referenceData, currentOrganization } = useContext(MaxyfiContext);

  const findFullWidth = useRef(null);

  let { actionType } = useSelector((e) => e.customerDairyNoteReducer);

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `customer-notes-${resourceId}`,
      {
        refetchDiaryNotes,
      },
    ],
    async () => {
      return await getDairyNotes({
        organization: currentOrganization,
        customerId: resourceId,
        entity: entity,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const deleteDairyData = useMutation(
    (id) =>
      deleteDairyNote({
        ...id,
        organization: currentOrganization,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
        // dispatch(refetchActions());
        dispatch(refetchInvoiceActions());
      },
    }
  );

  const { mutateAsync } = useDeleteDairyNote();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <LabelLarge>
              Notes{" "}
              {data &&
                data.data &&
                data.data.docs &&
                data.data.docs.length > 0 && (
                  <span
                    style={{
                      backgroundColor: "#e2e7fb",
                      color: "#516beb",
                      padding: "3px 7px",
                      borderRadius: "5px",
                    }}
                  >
                    {data &&
                      data.data &&
                      data.data.docs &&
                      data.data.docs.length}
                  </span>
                )}
            </LabelLarge>
            <div style={{ display: "flex" }}>
              {data &&
                data.data &&
                data.data.docs &&
                data.data.docs.length > 0 && (
                  <div className="drawer_add_btn">
                    <RBACWrapper role={addNotePermission} type="PERMISSION">
                      <TextButton
                        kind={KIND.tertiary}
                        size={SIZE.mini}
                        onClick={() => dispatch(changeActionType())}
                      >
                        <Plus />
                        Add note
                      </TextButton>
                    </RBACWrapper>
                  </div>
                )}
              {/* <div className="drawer_close_btn">
            <TextButton
              kind={KIND.tertiary}
              size={SIZE.mini}
              onClick={() => {
                dispatch(closeModal());
                dispatch(resetDiaryNoteReduxState());
              }}
            >
              <Cancel />
              Close
            </TextButton>
          </div> */}
            </div>
          </div>
          <RBACWrapper role={addNotePermission} type="PERMISSION">
            {((data &&
              data.data &&
              data.data.docs &&
              data.data.docs.length == 0) ||
              actionType === EDIT ||
              actionType === NEW) && (
              <NoteFormContainer
                resourceName={resourceName}
                resourceId={resourceId}
                createDiaryNoteService={createDiaryNoteService}
                editDairyNote={editDairyNote}
                entity={entity}
                refetch={refetch}
              />
            )}
            {/* {actionType === NEW && (
          <NoteFormContainer
            resourceName={resourceName}
            resourceId={resourceId}
            createDiaryNoteService={createDiaryNoteService}
            editDairyNote={editDairyNote}
            entity={entity}
            refetch={refetch}
          />
        )} */}
          </RBACWrapper>

          <div className="notes_list_container">
            {data &&
              data.data &&
              data.data.docs &&
              data.data.docs.map((data) => (
                <>
                  <NoteCard
                    data={data}
                    refetch={refetch}
                    deleteDairyData={mutateAsync}
                    getClientId={resourceId}
                    findFullWidth={findFullWidth}
                    editNotePermission={editNotePermission}
                    deleteNotePermission={deleteNotePermission}
                    entity={entity}
                  />
                </>
              ))}
          </div>
        </>
      )}
    </>
  );
};
export default NoteContainer;
