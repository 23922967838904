import React from "react";

const Call = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4323 11.3406L12.17 11.0823C11.6267 11.0199 11.0923 11.207 10.7093 11.59L9.07041 13.2289C6.54978 11.9463 4.4834 9.88878 3.20082 7.35923L4.84858 5.71146C5.23157 5.32846 5.41862 4.79405 5.35627 4.25073L5.09797 2.0062C4.99109 1.10661 4.23401 0.429688 3.32551 0.429688H1.78464C0.778165 0.429688 -0.0590758 1.26693 0.0032719 2.27341C0.475333 9.87987 6.55869 15.9544 14.1562 16.4264C15.1627 16.4888 15.9999 15.6515 15.9999 14.645V13.1042C16.0088 12.2046 15.3319 11.4475 14.4323 11.3406Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Call;
