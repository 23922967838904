// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST_AUTH;

const HOSTSPACE = process.env.REACT_APP_HOST;

export const getSpace = async () => {
  return await axiosWithAuth.get(`${HOSTSPACE}space`).catch((err) => {
    if (err.response) {
      //Need to handle error
    }

    if (err.request) {
      //Need to handle error
    }
  });
};

export const getUserProfile = async () => {
  return await axiosWithAuth.get(`${HOSTSPACE}user/me`).catch((err) => {
    if (err.response) {
      //Need to handle error
    }

    if (err.request) {
      //Need to handle error
    }
  });
};

export const resetUserProfile = async () => {
  return await axiosWithAuth
    .patch(`${HOSTSPACE}user/me/reset-2FA`)
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};

export const resetPasswordUserProfile = async () => {
  return await axiosWithAuth.patch(`${HOSTSPACE}user/me`).catch((err) => {
    if (err.response) {
      //Need to handle error
    }

    if (err.request) {
      //Need to handle error
    }
  });
};

export const getOrganizationUsers = async () => {
  return await axiosWithAuth
    .get(`${HOSTSPACE}user?page_size=0`)
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};

export const editeUserProfile = async ({ organization, data }) => {
  return await axiosWithAuth.put(`${HOSTSPACE}user/me`, data);
};

export const changeUserPassword = async ({ organization, data }) => {
  return await axiosWithAuth
    .patch(`${HOST}api/${organization}/user/me`, data)
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};

export const userProfileForgotPassword = async ({ organization }) => {
  return await axiosWithAuth
    .patch(`${HOST}api/${organization}/user/me/forgot-password`)
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};

export const gettingAccountingData = async ({ org_id }) => {
  return await axiosWithAuth
    .get(
      `${HOSTSPACE}organization/${org_id}/accountBook
    `
    )
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};

export const setCsv = async ({ org_id }) => {
  return await axiosWithAuth.patch(
    `${HOSTSPACE}organization/${org_id}/set-csv`
  );
};

export const startPackagePricing = async ({ plan }) => {
  return await axiosWithAuth.post(`${HOSTSPACE}space/subscribe-trial/${plan}`);
};

export const getRoleUsers = async () => {
  return await axiosWithAuth
    .get(`${HOSTSPACE}role?exclude=all`)
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};
