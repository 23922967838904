export default function ExpandRight({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <path
        d="M11.4143 8.70661V13.2924C11.4143 13.4835 11.4986 13.6143 11.6672 13.6848C11.8359 13.7553 11.9885 13.7222 12.1251 13.5856L14.1334 11.5773C14.2973 11.4134 14.3793 11.221 14.3793 11C14.3793 10.7791 14.2965 10.5858 14.1308 10.4201L12.1249 8.41417C11.9884 8.27773 11.8359 8.24402 11.6672 8.31306C11.4986 8.3821 11.4143 8.51329 11.4143 8.70661ZM4.86543 18.7917C4.40974 18.7917 4.01965 18.6294 3.69515 18.3049C3.37063 17.9804 3.20837 17.5903 3.20837 17.1346V4.86543C3.20837 4.40974 3.37063 4.01965 3.69515 3.69515C4.01965 3.37063 4.40974 3.20837 4.86543 3.20837H17.1346C17.5903 3.20837 17.9804 3.37063 18.3049 3.69515C18.6294 4.01965 18.7917 4.40974 18.7917 4.86543V17.1346C18.7917 17.5903 18.6294 17.9804 18.3049 18.3049C17.9804 18.6294 17.5903 18.7917 17.1346 18.7917H4.86543ZM8.70833 17.4167H17.1346C17.2051 17.4167 17.2698 17.3873 17.3285 17.3285C17.3873 17.2698 17.4167 17.2051 17.4167 17.1346V4.86543C17.4167 4.79491 17.3873 4.73026 17.3285 4.67149C17.2698 4.61273 17.2051 4.58335 17.1346 4.58335H8.70833V17.4167Z"
        fill={color}
      />
    </svg>
  );
}
