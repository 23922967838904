import React from "react";
const ContactLocation = ({ color = "#0fB158", width = 14, height = 17 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.78135 8.47669C7.71379 8.47669 8.47669 7.71379 8.47669 6.78135C8.47669 5.84891 7.71379 5.08601 6.78135 5.08601C5.84891 5.08601 5.08601 5.84891 5.08601 6.78135C5.08601 7.71379 5.84891 8.47669 6.78135 8.47669ZM6.78135 0C10.3416 0 13.5627 2.72949 13.5627 6.95088C13.5627 9.64647 11.4859 12.8168 7.34081 16.4702C7.0187 16.7499 6.53553 16.7499 6.21341 16.4702C2.07679 12.8168 0 9.64647 0 6.95088C0 2.72949 3.22114 0 6.78135 0Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export default ContactLocation;
