import React from "react";

const DeliverTick = () => {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.228 0.239459C12.9148 -0.0702177 12.4089 -0.0702177 12.0957 0.239459L7.56658 4.71787L8.69886 5.83747L13.228 1.35112C13.5331 1.04938 13.5331 0.541196 13.228 0.239459ZM16.6328 0.231519L8.69886 8.07667L5.90431 5.32134C5.59112 5.01166 5.08521 5.01166 4.77203 5.32134C4.45884 5.63102 4.45884 6.13127 4.77203 6.44094L8.12871 9.76005C8.44189 10.0697 8.9478 10.0697 9.26099 9.76005L17.7651 1.35906C18.0783 1.04938 18.0783 0.549137 17.7651 0.239459H17.7571C17.4519 -0.0781582 16.946 -0.0781583 16.6328 0.231519ZM0.234887 6.44888L3.59157 9.76799C3.90475 10.0777 4.41066 10.0777 4.72385 9.76799L5.28597 9.21216L1.36716 5.32134C1.05398 5.01166 0.54807 5.01166 0.234887 5.32134C-0.0782958 5.63102 -0.0782958 6.13921 0.234887 6.44888Z"
        fill="#7E92F3"
      />
    </svg>
  );
};

export default DeliverTick;
