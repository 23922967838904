import { HeadingXSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import React from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import "moment-timezone";
import _ from "lodash";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import CustomFields from "../../../components/CustomFields/CustomFields";
import { useState } from "react";
import InvLevelCustomFieldsTab from "../../../containers_v2/CustomerOverview/CustomFields/InvLevelCustomFieldsTab";

export default function InvoiceMore({ getParams }) {
  const { invoiceDetails } = useSelector(
    (state) => state.invoiceOverviewReducer
  );
  const { customInvoice } = useContext(MaxyfiContext);
  const [isCard, setIsCard] = useState(false);

  return (
    <>
      <div
        style={{
          padding: "20px",
          overflow: "scroll",
          height: "calc(100vh - 285px)",
        }}
      >
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Invoice Description :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.invoice_description}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Client Reference :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.client_reference}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Patient Name:</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.patient_name}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Employer:</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.employer}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Location of Service:</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.service_location}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Insurance Info:</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.insurance}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Consumer ID :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.customer_id}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Create File Id :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.created_file_id}
          </ParagraphMedium>
        </div>
        <div style={{ display: "flex", gap: "2px" }}>
          <ParagraphMedium>Update File Id :</ParagraphMedium>

          <ParagraphMedium $style={{ fontWeight: 700 }}>
            {invoiceDetails?.updated_file_id}
          </ParagraphMedium>
        </div>
        <InvLevelCustomFieldsTab
          customFields={invoiceDetails}
          fieldLevel={customInvoice}
          invId={getParams}
          isSave={true}
        />
      </div>
    </>
  );
}
