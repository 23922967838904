import React from "react";
import CardLayout from "../Card";
import { StyledBody, StyledAction } from "baseui/card";
import { Paragraph2 } from "baseui/typography";
import { Button, SHAPE } from "baseui/button";
import Plus from "baseui/icon/plus";

const AddWidgets = () => {
  return (
    <div>
      <CardLayout style={{ backgroundColor: "#F5F6FA" }}>
        <div className="add-btn">
          <Paragraph2
            style={{
              fontSize: "10px",
              lineHeight: "12px",
              textAlign: "center",
              fontWeight: "700",
              color: "#666666",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Add Widgets
          </Paragraph2>
          <Button
            shape={SHAPE.circle}
            $style={{
              backgroundColor: "#516BEB",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px #00000025",
              ":hover": {
                boxShadow: "inset 0px 4px 4px 0px #00000025",
                backgroundColor: "#0348C0",
              },
            }}
          >
            <Plus />
          </Button>
        </div>
      </CardLayout>
    </div>
  );
};

export default AddWidgets;
