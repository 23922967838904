export default function Dispute({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M3.58092 14.3193H9.39018C9.70965 14.3193 9.97106 14.585 9.97106 14.9096C9.97106 15.2343 9.70965 15.5 9.39018 15.5H3.58092C3.26142 15.5 3 15.2343 3 14.9096C3 14.585 3.26142 14.3193 3.58092 14.3193ZM5.46311 6.68602L7.10713 5.01532L14.5023 12.5305C14.9554 12.991 14.9554 13.7407 14.5023 14.2012C14.0491 14.6617 13.3114 14.6617 12.8583 14.2012L5.46311 6.68602ZM10.3952 3.34462L12.0392 5.01532C12.4923 5.4758 12.4923 6.22554 12.0392 6.68602L11.2143 7.52432L7.93204 4.18293L8.75114 3.35053C9.20425 2.88415 9.94204 2.88415 10.3952 3.34462ZM4.64401 7.51842L7.93204 10.8598L7.11294 11.6922C6.65982 12.1527 5.92204 12.1527 5.46892 11.6922L3.82491 10.0215C3.37179 9.56102 3.37179 8.81129 3.82491 8.35082L4.64401 7.51842Z"
        fill={color}
      />
    </svg>
  );
}
