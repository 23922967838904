import React from "react";
import { Typography } from "../../../../components_v2";
import { VIEW } from "../../../../constants";

const CustomCombinator = ({
  value,
  options,
  className,
  handleOnChange,
  type,
}) => {
  return (
    <div
      className="and_or_rule_disclosure"
      style={{ display: "flex", alignItems: "center" }}
    >
      {options.map((option) => (
        <Typography
          key={option.name}
          type="p"
          className="regular"
          style={{
            textAlign: "center",
            padding: "7px",
            width: "75px",
            height: "28px",
            fontWeight:
              type === VIEW ? (option.name === value ? "600" : "400") : "500",
            color:
              type === VIEW
                ? "#333860"
                : option.name === value
                ? "#516BEB"
                : "#000000",
            backgroundColor:
              type === VIEW
                ? option.name === value
                  ? "#FAFAFA"
                  : "#EEEEEE"
                : option.name === value
                ? "#E7EBFC"
                : "#FFFFFF",
            border: `1px solid ${
              type === VIEW
                ? "#CDCED9"
                : option.name === value
                ? "#516BEB"
                : "#CDCED9"
            }`,
            cursor: "pointer",
          }}
          onClick={() => {
            if (type !== VIEW) {
              handleOnChange(option.name);
            }
          }}
        >
          {option.label}
        </Typography>
      ))}
    </div>
  );
};

export default CustomCombinator;
