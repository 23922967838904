import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ outstandingAmount, totalAmount }) {
  let overduePerc = (outstandingAmount / totalAmount) * 100;

  return (
    <Pie
      data={{
        datasets: [
          {
            label: "",
            data: [100 - overduePerc, overduePerc],
            backgroundColor: ["#dde3ff", "#fdcac6"],
          },
        ],
      }}
    />
  );
}

export default PieChart;
