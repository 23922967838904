import arMessages from "../locales/ar_SA";

const arLang = {
  messages: {
    ...arMessages,
  },
  locale: "ar-SA",
};

export default arLang;
