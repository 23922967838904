function Executed() {
  return (
    <>
      {/* <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z"
          fill="#ADADAD"
        />
      </svg> */}

      <svg
        // version="1.1"
        // id="Layer_1"
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        // x="0px"
        // y="0px"
        // viewBox="0 0 22 22"
        // style="enable-background:new 0 0 22 22;"
        // xml:space="preserve"
        width="21"
        height="20"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "#ADADAD" }}
          d="M11,1C5.5,1,1,5.5,1,11s4.5,10,10,10s10-4.5,10-10S16.5,1,11,1z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M8.3,15.5l-3.6-3.6c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L9,13.4l6.9-6.9c0.4-0.4,1-0.4,1.4,0
		c0.4,0.4,0.4,1,0,1.4l-7.6,7.6C9.3,15.9,8.7,15.9,8.3,15.5z"
        />
      </svg>
    </>
  );
}

export default Executed;
