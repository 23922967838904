import React from "react";

function SearchIcon(props) {
  const { fill } = props;
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.1625 12.4609C4.51003 12.4609 3.11149 11.888 1.96689 10.7421C0.822298 9.59629 0.25 8.21365 0.25 6.59421C0.25 4.97476 0.822917 3.59212 1.96875 2.44629C3.11458 1.30046 4.50104 0.727539 6.12813 0.727539C7.75521 0.727539 9.13785 1.30046 10.276 2.44629C11.4142 3.59212 11.9833 4.97591 11.9833 6.59764C11.9833 7.2523 11.8764 7.88518 11.6625 8.49629C11.4486 9.1074 11.1278 9.68032 10.7 10.215L16.2458 15.715C16.3833 15.8458 16.4521 16.0121 16.4521 16.2141C16.4521 16.4161 16.3833 16.5859 16.2458 16.7234C16.1083 16.8609 15.9386 16.9296 15.7366 16.9296C15.5346 16.9296 15.3682 16.8609 15.2375 16.7234L9.71458 11.2005C9.25625 11.5977 8.72184 11.9071 8.11136 12.1286C7.50087 12.3501 6.85125 12.4609 6.1625 12.4609ZM6.13958 11.0859C7.3809 11.0859 8.43602 10.6466 9.30495 9.76816C10.1739 8.88969 10.6083 7.83171 10.6083 6.59421C10.6083 5.35671 10.1739 4.29872 9.30495 3.42025C8.43602 2.54178 7.3809 2.10254 6.13958 2.10254C4.88554 2.10254 3.81959 2.54178 2.94175 3.42025C2.06392 4.29872 1.625 5.35671 1.625 6.59421C1.625 7.83171 2.06392 8.88969 2.94175 9.76816C3.81959 10.6466 4.88554 11.0859 6.13958 11.0859Z"
        fill={fill}
      />
    </svg>
  );
}

export default SearchIcon;
