import React from "react";
import EmailAction from "./Actions/AdhocActionPopup/EmailAction";
import { Drawer } from "baseui/drawer";
import {
  documentDriveQuickAction,
  unSelectedDocumentData,
  updateEmailAttachmentFromDesktop,
} from "../../../redux/customerOverview/DMSystem/action";
import { initialQAReducer } from "../../../redux/invoiceOverview/invoiceQA/action";
import { useDispatch } from "react-redux";

const SendEmailDrawer = ({
  isSendEmailOpen,
  setIsSendEmailOpen,
  getParams,
}) => {
  let dispatch = useDispatch();
  return (
    <Drawer
      isOpen={isSendEmailOpen?.isOpen}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
        DrawerBody: {
          style: ({ $theme }) => ({
            marginLeft: "15px",
            marginRight: "15px",
            marginBottom: "15px",
            marginTop: "0px",
            padding: "5px",
            // overflowY: "hidden",
          }),
        },
        DrawerContainer: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
      }}
      autoFocus
      onClose={() => {
        setIsSendEmailOpen({ id: "", isOpen: false });
        dispatch(initialQAReducer());
      }}
    >
      <EmailAction
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"PAYMENT"}
        removeAttchFile={unSelectedDocumentData}
        updatingDeskUpload={updateEmailAttachmentFromDesktop}
        setAttachment={documentDriveQuickAction}
        action={"PAYMENT"}
        setIsSendEmailOpen={setIsSendEmailOpen}
        isSendEmailOpen={isSendEmailOpen}
      />
    </Drawer>
  );
};

export default SendEmailDrawer;
