import React from "react";

const Forward = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4375 17.4167C3.23889 17.4167 3.07465 17.3517 2.94479 17.2219C2.81493 17.092 2.75 16.9278 2.75 16.7292V13.4292C2.75 12.2222 3.17778 11.1948 4.03333 10.3469C4.88889 9.49896 5.9125 9.075 7.10417 9.075H16.6375L13.5667 6.00417C13.4444 5.88194 13.3833 5.72917 13.3833 5.54583C13.3833 5.3625 13.4521 5.20208 13.5896 5.06458C13.7271 4.92708 13.8875 4.85833 14.0708 4.85833C14.2542 4.85833 14.4146 4.92708 14.5521 5.06458L18.7687 9.28125C18.8451 9.35764 18.8986 9.43403 18.9292 9.51042C18.9597 9.58681 18.975 9.67083 18.975 9.7625C18.975 9.85417 18.9597 9.93819 18.9292 10.0146C18.8986 10.091 18.8451 10.1674 18.7687 10.2438L14.5292 14.4833C14.4069 14.6056 14.2542 14.6667 14.0708 14.6667C13.8875 14.6667 13.7271 14.5979 13.5896 14.4604C13.4521 14.3229 13.3833 14.1625 13.3833 13.9792C13.3833 13.7958 13.4521 13.6354 13.5896 13.4979L16.6375 10.45H7.10417C6.27917 10.45 5.57639 10.7403 4.99583 11.3208C4.41528 11.9014 4.125 12.6042 4.125 13.4292V16.7292C4.125 16.9278 4.06007 17.092 3.93021 17.2219C3.80035 17.3517 3.63611 17.4167 3.4375 17.4167Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default Forward;
