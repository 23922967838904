import React, { useContext } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _, { values } from "lodash";
import { useMemo } from "react";
import { currencyList } from "../../../utils_v2/CurrencyToSymbol";
import useFormat from "../../../hooks/useFormat";

function PaymentCollected(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  const format = useFormat();

  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  value = _.get(row.original, accessor, "");

  const backGroundColor = useMemo(() => {
    if (row?.original?.clper > 100) {
      return "#CFF7D3"; // Hex for color if comp > 100
    } else if (row?.original?.clper >= 75 && row?.original?.clper <= 99) {
      return "#FFE28A"; // Hex for Amber color if comp is between 75% to 99%
    } else {
      return "#FFBBB7"; // Hex for red color if comp < 75%
    }
  }, [value]);

  return (
    <div style={{ backgroundColor: backGroundColor, padding: "3px" }}>
      <Paragraph2
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          color: "#333860",
          lineHeight: "16px",
        }}
      >
        {value ? currencyList[row?.original?.curr] : ""}
        {format.currency({
          amount: value,
        })}{" "}
        <span style={{ color: "#ADADAD" }}>
          {" "}
          {row?.original?.clper ? `(${row?.original?.clper})%` : ""}
        </span>
      </Paragraph2>
      <Paragraph2
        $style={{
          width: "100%",
          textAlign: "end",
          color: "#787878",
          lineHeight: "16px",
        }}
      >
        {row?.original?.fppa ? currencyList[row?.original?.curr] : ""}
        {format.currency({
          amount: row?.original?.fppa,
        })}{" "}
      </Paragraph2>
    </div>
  );
}
export default PaymentCollected;
