const DairyNoteEdit = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6563_79654)">
        <path
          d="M4 15.875V19H7.12457L16.34 9.78333L13.2154 6.65833L4 15.875ZM6.433 17.3333H5.66644V16.5667L13.2154 9.01667L13.9819 9.78333L6.433 17.3333ZM18.7563 6.19167L16.8066 4.24167C16.6399 4.075 16.4316 4 16.215 4C15.9983 4 15.79 4.08333 15.6317 4.24167L14.1069 5.76667L17.2315 8.89167L18.7563 7.36667C19.0812 7.04167 19.0812 6.51667 18.7563 6.19167V6.19167Z"
          fill="#ADADAD"
        />
      </g>
      <defs>
        <clipPath id="clip0_6563_79654">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DairyNoteEdit;
