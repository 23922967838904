import React from "react";
import { ParagraphMedium } from "baseui/typography";
import _ from "lodash";

const BoolYesNo = (props) => {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  value = _.get(row.original, accessor, "");
  return (
    <ParagraphMedium
      $style={{
        color: "#333860",
        marginLeft: "5px",
        lineHeight: "19px",
      }}
    >
      {value == true ? "Yes" : value == false ? "No" : "-"}
    </ParagraphMedium>
  );
};

export default BoolYesNo;
