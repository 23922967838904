import React, { useContext } from "react";
import { MaxyfiContext } from "../providers/MaxyfiProvider";

function RDText({ rdName, id }) {
  let { referenceData } = useContext(MaxyfiContext);
  if (
    !rdName ||
    !id ||
    !(
      referenceData &&
      referenceData[rdName] &&
      Array.isArray(referenceData[rdName])
    )
  ) {
    return <></>;
  }

  let rdValue = referenceData && referenceData[rdName].find((e) => e.id == id);

  return <>{rdValue && rdValue.label ? rdValue.label : id}</>;
}

export default RDText;
