import React from "react";

export default function SkippedTimeLine({ size = 22, color = "#516BEB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6258 7.875C14.6258 4.15311 11.5977 1.125 7.87579 1.125C4.1539 1.125 1.12579 4.15311 1.12579 7.875C1.12579 11.5969 4.1539 14.625 7.87579 14.625C11.5977 14.625 14.6258 11.5969 14.6258 7.875ZM7.87579 13.5C4.77408 13.5 2.25079 10.9767 2.25079 7.875C2.25079 4.77328 4.77408 2.25 7.87579 2.25C10.9775 2.25 13.5008 4.77328 13.5008 7.875C13.5008 10.9767 10.9775 13.5 7.87579 13.5Z"
        fill={color}
      />
      <path
        d="M16.3616 13.878L14.461 11.9774C14.3394 11.8558 14.1778 11.8104 14.02 11.8256C13.4532 12.7042 12.7049 13.4525 11.8263 14.0193C11.8111 14.1771 11.8565 14.3387 11.9781 14.4603L13.8787 16.3609C14.2102 16.6924 14.6511 16.8751 15.1202 16.8751C15.589 16.8751 16.0299 16.6924 16.3619 16.3609C17.0461 15.6762 17.0461 14.5625 16.3616 13.878Z"
        fill={color}
      />
      <path
        d="M12.3743 5.62085L8.15625 7.49556V12.3752L12.0339 10.6421C12.241 10.5537 12.375 10.3502 12.375 10.1252V5.62521C12.375 5.6237 12.3743 5.62236 12.3743 5.62085Z"
        fill={color}
      />
      <path
        d="M8.09637 3.42073C7.95575 3.35976 7.79425 3.35976 7.65363 3.42073C7.65363 3.42073 3.68643 5.12756 3.67067 5.13618L7.875 7.00475L12.0793 5.13618C12.0636 5.12756 8.09637 3.42073 8.09637 3.42073Z"
        fill={color}
      />
      <path
        d="M3.375 5.62521V10.1252C3.375 10.3502 3.50903 10.5537 3.71613 10.6421L7.59375 12.3752V7.49556L3.37565 5.62085C3.37565 5.62236 3.375 5.6237 3.375 5.62521Z"
        fill={color}
      />
    </svg>
  );
}
