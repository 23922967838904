import { HeadingXLarge, ParagraphLarge, LabelLarge } from "baseui/typography";
import InfoPortal from "../../../../../assets/img/svg/InfoPortal";
import AllActionPopOver from "../AllActionPopOver";
import { isOpenModal } from "../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../components/TextBox";
import { TextButton, SIZE, KIND } from "../../../../../components/TextButton";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { TextArea } from "../../../../../components/TextArea";
import Sms from "../../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import { SelectBox } from "../../../../../components/SelectBox";
import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import React, { useContext, useEffect, useState } from "react";
import { COMMUNICATION_TEMPLATE } from "../../../../../constants";
import { useQuery, useMutation } from "react-query";
import {
  getCommunicationTemplateList,
  getCommunicationTemplateFinalContent,
  executeAdhocCustomerAction,
  getCustomerContactDetail,
} from "../../../../../services";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import getSelectValues from "../../../../../utils/getSelectValues";
import { toast } from "react-toastify";
import { refetchInvoiceActions } from "../../../../../redux/invoiceOverview/action";

const SmsAction = ({ getParams, id, entity, tags, invoiceId }) => {
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);
  const { refetchActions } = useSelector((s) => s.customerOverviewReducer);
  const intl = useIntl();
  const dispatch = useDispatch();

  const entityName = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_entity
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let template_id = watch("template_id");

  useEffect(() => {
    const getTemplateContent = async () => {
      let templateContent = await getCommunicationTemplateFinalContent({
        organization: currentOrganization,
        templateId: template_id[0].id,
        entity: entity,
        id: id,
      });

      if (templateContent.data && templateContent.data.doc) {
        let { value } = templateContent.data.doc;
        setValue("content", value);
      }
    };

    if (template_id && template_id[0] && template_id[0].id) {
      getTemplateContent();
    }
  }, [template_id]);

  const templateId = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-SMS-${currentOrganization}`,
      {
        filters: { disable: false, type: "sms", tags: `${tags}` },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  let validTem =
    templateId.data &&
    templateId.data.data &&
    templateId.data.data.docs.filter((item) => {
      if (
        item &&
        item.tags &&
        item.tags.length == 1 &&
        item?.tags?.[0] == tags
      ) {
        return item;
      }
    });

  const customerContacts = useQuery(
    [`customer-contacts-${getParams}-${id}-${refetchActions}`],
    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: getParams,
        ...(entityName === "INVOICE" ? {} : { invoiceId: id }),
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const executeActionMutation = useMutation(
    (formValues) =>
      executeAdhocCustomerAction({
        ...formValues,
        id: id,
        organization: currentOrganization,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        dispatch(isOpenModal(false));
        if (entityName === "INVOICE") {
          dispatch(refetchInvoiceActions());
        } else {
          dispatch(refetchActions());
        }
      },
    }
  );

  const onSubmit = async (data) => {
    let recipients = [];
    if (data && data.recipients) {
      data &&
        data?.recipients?.map((e) => {
          recipients?.push({ id: e?.id, recipient_id: e?.recipient_id });
        });
    }

    let values = {
      ...data,
      action_type: "sms",
      template_id: getSelectValues(data.template_id),
      recipients: recipients,
    };

    await executeActionMutation.mutateAsync(values);
  };

  useEffect(() => {
    if (
      customerContacts &&
      customerContacts.data &&
      customerContacts.data.data &&
      customerContacts.data.data.docs &&
      Array.isArray(customerContacts.data.data.docs)
    ) {
      let hasPrimaryContact = false;
      let primaryContacts = customerContacts.data.data.docs.reduce(
        (prev, curr) => {
          let { phone, landline, email, address, ...rest } = curr;
          let nestedContacts = phone
            .map(({ status, sms_status, ...nRest }) => {
              if (
                entityName === "INVOICE" &&
                curr.primary_invoices &&
                Array.isArray(curr.primary_invoices) &&
                curr.primary_invoices.includes(id)
              ) {
                hasPrimaryContact = true;
              }

              return {
                id: nRest?._id,
                recipient_id: curr?._id,
                sms_status,
                status,
                designation: curr.designation,
                primary_invoices: curr.primary_invoices,
              };
            })
            .filter(
              (e) =>
                (e.status === "VALID" || e.status === "LEAD") &&
                e.sms_status === "VALID" &&
                ((!hasPrimaryContact && e.designation === "primary_contact") ||
                  (hasPrimaryContact && e.primary_invoices.includes(id)))
            );

          return [...prev, ...nestedContacts];
        },
        []
      );

      setValue("recipients", primaryContacts);
    }
  }, [customerContacts, customerContacts.isLoading]);

  return (
    <form
      style={{
        gridColumn: "2/5",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            // display: "grid",
            // gridTemplateRows: "repeat(12,10fr)",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div style={{ padding: "15px 35px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "5px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Sms />
                <HeadingXLarge>SMS</HeadingXLarge>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "0px 35px",
              height: "39vh",
              overflow: "scroll",
            }}
          >
            <Controller
              name="template_id"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  size={SIZE.default}
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "template_id",
                  })}
                  placeholder={intl.formatMessage({
                    id: "template_id",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={
                    validTem &&
                    validTem.map((i) => {
                      return {
                        label: `${i.name} (${
                          i.language
                            ? i.language.toLocaleUpperCase()
                            : "en".toLocaleUpperCase()
                        })`,
                        id: i._id,
                      };
                    })
                  }
                />
              )}
            />
            <Controller
              name="recipients"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  size={SIZE.default}
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  noResultMessage={`${"Phone Number is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "recipients",
                  })}
                  placeholder={intl.formatMessage({
                    id: "recipients",
                  })}
                  value={field.value}
                  multi
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={
                    customerContacts &&
                    customerContacts.data &&
                    customerContacts.data.data &&
                    customerContacts.data.data.docs &&
                    Array.isArray(customerContacts.data.data.docs)
                      ? customerContacts.data.data.docs.reduce((prev, curr) => {
                          let { phone, landline, email, address, ...rest } =
                            curr;
                          let nestedContacts = phone
                            .map(({ status, sms_status, ...nRest }) => {
                              let designation =
                                referenceData && referenceData["recipient_type"]
                                  ? referenceData["recipient_type"].find(
                                      (rf) => rf?.id === curr?.designation
                                    )
                                  : "";

                              return {
                                label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
                                id: nRest?._id,
                                recipient_id: curr?._id,
                                sms_status,
                                status,
                              };
                            })
                            .filter(
                              (e) =>
                                (e.status === "VALID" || e.status === "LEAD") &&
                                e.sms_status === "VALID"
                            );

                          return [...prev, ...nestedContacts];
                        }, [])
                      : []
                  }
                />
              )}
            />

            <Controller
              name="content"
              control={control}
              // rules={{ required: "Required" }}
              render={({ field }) => (
                <TextArea
                  size={SIZE.default}
                  {...field}
                  name={field.name}
                  // requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "sms_content",
                  })}
                  placeholder={intl.formatMessage({
                    id: "sms_content",
                  })}
                  value={field.value}
                />
              )}
            />
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <TextArea
                  size={SIZE.default}
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "user_comments",
                  })}
                  placeholder={intl.formatMessage({
                    id: "user_comments",
                  })}
                  value={field.value}
                />
              )}
            />
          </div>
          <div
            style={{
              background: "#F9F9F9",
              // width: "100%",
              height: "70px",
              padding: "15px",
              borderTop: "1px solid #CDCED9",
              gridRow: "12/13",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <div style={{ width: "125px", height: "36px" }}>
                  <TextButton
                    size={SIZE.compact}
                    kind={KIND.tertiary}
                    fullWidth
                    type="button"
                    onClick={() => {
                      dispatch(isOpenModal(false));
                    }}
                  >
                    Cancel
                  </TextButton>
                </div>

                <div style={{ width: "125px", height: "36px" }}>
                  <TextButton
                    size={SIZE.compact}
                    fullWidth
                    type="submit"
                    isLoading={executeActionMutation.isLoading}
                    disabled={executeActionMutation.isLoading}
                  >
                    Send
                  </TextButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default React.memo(SmsAction);
