import React from "react";

const CheckInInvSvg = () => {
  return (
    <svg
      height="18"
      viewBox="0 0 24 24"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m19 1h-14a1 1 0 0 0 -.65.25 4 4 0 0 1 2.65 3.35 1.75 1.75 0 0 1 0 .4v17a1 1 0 0 0 .58.91 1 1 0 0 0 1.07-.15l2.85-2.44 2.85 2.44a1 1 0 0 0 1.3 0l2.85-2.44 2.85 2.44a1 1 0 0 0 .65.24 1.06 1.06 0 0 0 .42-.09 1 1 0 0 0 .58-.91v-17a4 4 0 0 0 -4-4zm-1 14h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm0-4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm0-4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm-13-2v8h-3a1 1 0 0 1 -1-1v-7c0-.14 0-.27 0-.4a2 2 0 0 1 4 .4z" />
    </svg>
  );
};

export default CheckInInvSvg;
