import React from "react";

const Pin = ({ size = 22 }) => {
  return (
    <svg
      className="pinned-icon"
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.536 4.59053C12.1022 4.02427 13.0426 3.98936 13.6573 4.59053L17.3696 8.30284C17.9554 8.88863 17.9554 9.83838 17.3696 10.4242C16.8033 10.9904 15.863 11.032 15.2483 10.4242L14.9832 10.1589L12.7695 12.3725C13.4373 14.1799 12.9902 16.4103 11.536 17.8488C11.379 18.0041 11.1374 17.9839 11.0056 17.8488L7.82352 14.6666L5.43718 17.0533C5.29073 17.1997 5.0533 17.1997 4.90685 17.0533C4.77167 16.9181 4.74791 16.6832 4.90685 16.523L7.29327 14.1363L4.11135 10.9545C3.97617 10.8193 3.95016 10.5854 4.11135 10.4242C5.53023 9.00529 7.68537 8.49745 9.58728 9.19024L11.8009 6.97662L11.536 6.71185C10.9315 6.1043 10.9697 5.15679 11.536 4.59053Z"
        fill="#333860"
      />
    </svg>
  );
};

export default Pin;
