import React from "react";

const BusinessUnit = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6666 6.00003H14.3333V5.33334C14.3333 4.598 13.7354 4 13 4H8.99999C8.26465 4 7.66665 4.59797 7.66665 5.33334V6H4.33334C3.59797 6.00003 3 6.59799 3 7.33334V9.33333C3 10.0687 3.59797 10.6667 4.33334 10.6667H9.66667V10.3333C9.66667 10.1491 9.81577 9.99999 10 9.99999H12C12.1843 9.99999 12.3334 10.1491 12.3334 10.3333V10.6667H17.6667C18.402 10.6667 19 10.0687 19 9.33333V7.33334C19 6.59799 18.402 6.00003 17.6666 6.00003ZM13 6.00003H8.99999V5.33334H13V6.00003Z"
        fill="#516beb"
      />
      <path
        d="M18.8151 11.0274C18.7015 10.9711 18.5657 10.9841 18.4654 11.0603C18.2285 11.2396 17.9524 11.3344 17.6666 11.3344H12.3333V12.3344C12.3333 12.5186 12.1842 12.6677 12 12.6677H9.99999C9.81574 12.6677 9.66664 12.5186 9.66664 12.3344V11.3344H4.33334C4.04753 11.3344 3.7715 11.2396 3.5345 11.0603C3.43391 10.9835 3.2985 10.9704 3.18487 11.0274C3.07162 11.0837 3 11.1993 3 11.3259V16.001C3 16.7364 3.59797 17.3344 4.33334 17.3344H17.6667C18.402 17.3344 19 16.7364 19 16.001V11.3259C19 11.1993 18.9283 11.0837 18.8151 11.0274Z"
        fill="#516beb"
      />
    </svg>
  );
};

export default BusinessUnit;
