import React from "react";

const ChavronSvg = ({ width = 22, height = 23, color = "#FD372A" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow_drop_up_FILL1_wght400_GRAD0_opsz20 1">
        <path
          id="Vector"
          d="M5.59669 15C5.41768 15 5.27348 14.9403 5.16409 14.821C5.0547 14.7017 5 14.5624 5 14.4033C5 14.3812 5.06498 14.2401 5.19495 13.98L9.90608 9.26519C9.99447 9.1768 10.0939 9.1105 10.2044 9.0663C10.3149 9.0221 10.4254 9 10.5359 9C10.6464 9 10.7569 9.0221 10.8674 9.0663C10.9779 9.1105 11.0773 9.1768 11.1657 9.26519L15.8769 13.9788C15.9419 14.0438 15.9906 14.1142 16.0231 14.1901C16.0556 14.2659 16.0718 14.3425 16.0718 14.4199C16.0718 14.5746 16.0171 14.7099 15.9077 14.826C15.7983 14.942 15.6541 15 15.4751 15H5.59669Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ChavronSvg;
