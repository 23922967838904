import React, { useEffect, useState } from "react";
import {
  Label1,
  Paragraph2,
  LabelMedium,
  ParagraphMedium,
  LabelXSmall,
} from "baseui/typography";
import CollapseUpArrow from "../../../../assets/img/svg/CollapseUpArrow";
import CollapseDownArrow from "../../../../assets/img/svg/CollapseDownArrow";
import { IconButton, KIND } from "../../../../components/IconButton";
import { utcTimstampToLocalDate } from "../../../../utils/utcTimstampToLocalDate";
import Warning from "../../../../assets/img/svg/Warning";
import DairyNoteAlertWarning from "../../../../assets/img/svg/CustomerOverViewIcon/DairyNoteAlertWarning";
import DairyNoteInfo from "../../../../assets/img/svg/DairyNoteInfo";
import DairyNoteDanger from "../../../../assets/img/svg/DairyNoteDanger";
import { useDispatch, useSelector } from "react-redux";
import { expandClick, tabMotion } from "../../../../redux/actions";
import { editDairyNote } from "../../../../services";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { FormattedDate } from "react-intl";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { refetchActions } from "../../../../redux/actions";
import Info from "../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import CriticalTimeLineSvg from "../../../../assets/img/svg/CriticalTimlelineSvg";
import { SIZE } from "baseui/input";
import TagExit from "../../../../assets/img/svg/TagExit";
import PopupExit from "../../../../assets/img/svg/PopupExit";
import { ModalContext } from "../../../../providers/ModalProvider";
// import warningImage from "../../assets/img/warning.png";
import warningImage from "../../../../assets/img/warning.png";

import CollapseUpArrowPopup from "../../../../assets/img/svg/CollaspseUpArrowPopup";
import CollapseDownArrowPopup from "../../../../assets/img/svg/CollapseDownArrowPopup";
import moment from "moment";
import {
  invoiceTabMotion,
  refetchInvoiceActions,
} from "../../../../redux/invoiceOverview/action";
import { openModal } from "../../../../redux/customerDairyNote/action";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { DISMISS_CUSTOMER_NOTES } from "../../../../providers/RBACProvider/permissionList";

const DairyNotesAlertCard = (props) => {
  const { handleModal } = useContext(ModalContext);

  let { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const { id } = useParams();

  const [customerId, setCustomerId] = useSearchParams();

  // const getCustomerId = customerId.get("customers");
  const getParams = props.getParams;

  const { e, i } = props;

  const entity = props.entity;

  let lengthOfNotes = e.notes.length;

  let bodyValue = e && e.notes && e.notes.replace(/(<([^>]+)>)/gi, " ");
  let finelBodyValue = bodyValue?.replace(/&nbsp; | &amp; /gi, "");

  let slicingNotesValue = finelBodyValue.slice(0, 115);

  // useSearchParams;

  const [expand, setExpand] = useState(false);
  const [info, setInfo] = useState(false);
  const [danger, setDanger] = useState(false);
  const [four, setFour] = useState(false);
  //   const [downArrow, setDownArrow] = useState(false);
  //
  let valId = id;

  let noteIdVal = e._id;
  //
  const expanToggle = () => {
    setExpand(!expand);
  };
  const categoryData = referenceData["notes_category"];

  const categoryFilter = e.category
    ? categoryData?.find(({ id }) => id === e?.category)
    : [];

  // const alertPopUpDatas = useSelector(
  //   (s) => s.customerOverviewReducer.customerDetails.alert_notes
  // );

  // const alertPopUpInvoceDatas = useSelector(
  //   (s) => s.invoiceOverviewReducer.invoiceDetails.alert_notes
  // );
  //

  // useEffect(()=>{
  //   if(alertPopUpDatas.length<=0 && collapseButton){

  //   }
  // },[alertPopUpDatas])

  const collapseButton = useSelector(
    (s) => s.customerOverviewReducer.noteAlert
  );
  // const entity = useSelector((s)=>s.invoiceOverviewReducer)s

  //
  const updateDiaryNoteMutation = useMutation(
    (data) => {
      let { category, is_enable_alert_and_follow_up, notes, type } = e;

      return props.editDairyNote({
        category,
        is_enable_alert_and_follow_up,
        notes,
        type,
        action: "DISMISS_NOTE",
        entity: entity,
        organization: currentOrganization,
        // [resourceName]: resourceId,
        id: getParams,
        noteId: e._id,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        dispatch(refetchActions());
        dispatch(refetchInvoiceActions());
      },
    }
  );

  const dispatch = useDispatch();

  const SwitchStatus = ({ type, dateData, children }) => {
    let isDate = dateData ? true : false;
    let localDate = isDate && utcTimstampToLocalDate(dateData);

    switch (type) {
      case "INFORMATIONAL":
        return (
          <>
            <span
              style={{
                color: "#516beb",
                fontWeight: "bold",
                fontSize: "12px",
                paddingRight: "5px",
              }}
            >
              {/* Follow-Up Needed (<FormattedDate value={dateData} />) */}
              {categoryFilter?.label} {isDate ? "(" : ""}
              {isDate ? (
                moment(localDate).format(
                  currentDefaultFormatDetails.date_format
                )
              ) : (
                <></>
              )}
              {isDate ? ")" : ""}
              {children}
            </span>
          </>
        );
      case "CRITICAL":
        return (
          <>
            <span
              style={{
                color: "#FD372A",
                fontWeight: "bold",
                fontSize: "12px",
                paddingRight: "5px",
              }}
            >
              {/* Follow-Up Needed (<FormattedDate value={dateData} />) */}
              {categoryFilter && categoryFilter?.label
                ? categoryFilter?.label
                : ""}{" "}
              {isDate ? "(" : ""}
              {isDate ? (
                moment(localDate).format(
                  currentDefaultFormatDetails.date_format
                )
              ) : (
                <></>
              )}
              {isDate ? ")" : ""}
              {/* (<FormattedDate value={dateData} />) */}
              {children}
            </span>
          </>
        );
      case "ALERT":
        return (
          <>
            <span
              style={{
                color: "#FBBD04",
                fontWeight: "bold",
                fontSize: "12px",
                paddingRight: "5px",
              }}
            >
              {/* Follow-Up Needed (<FormattedDate value={dateData} />) */}
              {categoryFilter?.label} {isDate ? "(" : ""}
              {isDate ? (
                moment(localDate).format(
                  currentDefaultFormatDetails.date_format
                )
              ) : (
                <></>
              )}
              {isDate ? ")" : ""}
              {/* (<FormattedDate value={dateData} />) */}
              {children}
            </span>
          </>
        );

      default:
        return null;
    }
  };
  const LeftClr = ({ type }) => {
    switch (type) {
      case "INFORMATIONAL":
        return (
          <div
            style={{
              background: "#516beb",
              height: "7px",
              width: "100%",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          ></div>
        );

      case "CRITICAL":
        return (
          <div
            style={{
              background: "#FD372A",
              height: "7px",
              width: "100%",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          ></div>
        );

      case "ALERT":
        return (
          <div
            style={{
              background: "#FBBD04",
              height: "7px",
              width: "100%",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          ></div>
        );

      default:
        return null;
    }
  };

  const SwitchSvg = ({ type }) => {
    switch (type) {
      case "INFORMATIONAL":
        return (
          <>
            <Info />
          </>
        );

      case "CRITICAL":
        return (
          <>
            <DairyNoteDanger />
          </>
        );

      case "ALERT":
        return (
          <>
            <DairyNoteAlertWarning />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div
        style={{
          paddingBottom: "10px",
          paddingTop: "7px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "213px",
            border: "0.5px solid #CDCED9",
            boxShadow: "1px 2px 4px 0px #33386025",
            borderRadius: "5px",
          }}
        >
          {/* <div
              style={{
                background: "#FBBD04",
                // height: "100px",
                width: "7px",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
            ></div> */}
          <LeftClr type={e.type} />

          <div className="dairy-note-alert">
            <div
              // className={`cp-status-view__discretion`}
              style={{
                paddingTop: "2px",
                paddingLeft: "10px",
                paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {/* {type ? <TypeName /> : " "} -{" "} */}
                      <span className="cp-discretion__text-content__weight">
                        {/* {name ? name : " "} */}
                      </span>{" "}
                    </Label1>
                    {/* <DeliveredStatusSend /> */}
                  </div>
                  <Paragraph2>
                    {/* {description ? description : ""} */}
                  </Paragraph2>

                  {expand ? (
                    <div style={{ paddingTop: "5px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <SwitchSvg type={e.type} />
                          </div>

                          <LabelXSmall>
                            {/* {e.category} (31-02-2023) */}
                            <p style={{ lineHeight: "25px" }}>
                              {/* <SwitchStatus
                                type={e.type}
                                // dateData={e.created_at}
                                dateData={e.follow_up_date}
                              /> */}
                              <span
                                // style={{ paddingLeft: "10px" }}
                                style={{
                                  color: "#333860",
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  paddingLeft: "10px",
                                  // fontWeight: "500px",
                                  width: "250px",
                                }}
                              >
                                {e.notes}
                                <span
                                  style={{
                                    color: "#516beb",
                                    cursor: "pointer",
                                    paddingLeft: "10px",
                                  }}
                                  onClick={() => setExpand(false)}
                                >
                                  see Less
                                </span>
                              </span>
                            </p>
                          </LabelXSmall>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        paddingTop: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div className="diary__notes__alert-svg">
                          {/* <DairyNoteAlertWarning />
                           */}
                          <SwitchSvg type={e.type} />
                        </div>
                        <SwitchStatus
                          type={e.type}
                          // dateData={e.created_at}
                          dateData={e.follow_up_date}
                        />
                      </div>
                      <LabelXSmall
                        style={{
                          color: "#333860",
                          fontSize: "13px",
                          fontWeight: "400",
                          paddingBottom: "10px",
                          // fontWeight: "500px",
                        }}
                      >
                        {/* {e.category} (31-02-2023) */}
                        <p
                          style={{
                            lineHeight: "20px",
                            fontSize: "12px",
                            wordBreak: "break-word",
                          }}
                        >
                          {slicingNotesValue}

                          {lengthOfNotes > 100 ? (
                            <>
                              <span
                                style={{
                                  color: "#516beb",
                                  cursor: "pointer",
                                  paddingLeft: "10px",
                                }}
                                onClick={() => {
                                  // if (entity === "invoices") {
                                  //   dispatch(invoiceTabMotion(3));
                                  // } else {
                                  //   dispatch(tabMotion(3));
                                  // }
                                  dispatch(openModal());
                                }}
                              >
                                see more
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                      </LabelXSmall>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    paddingRight: "15px",
                    gap: "10px",
                  }}
                >
                  <RBACWrapper role={props.dismissExit} type="PERMISSION">
                    <div
                      style={{
                        color: "#ADADAD",
                        // width: "51px",
                        paddingTop: "10px",
                        cursor: "pointer",
                        // paddingLeft: "15px",
                        display: "flex",
                      }}
                      onClick={() => {
                        handleModal({
                          title: " You are about to dismiss the highlight.",
                          content:
                            "This will not appear in the header anymore.",
                          successCallback: () => {
                            // props.deleteDairyData.mutate({
                            //   customerId: id,
                            //   _id: data._id,
                            // });
                            updateDiaryNoteMutation.mutate();
                          },
                          formContent: () => {
                            return <></>;
                          },
                          cancelCallback: () => {},
                          isChildren: false,
                          image: warningImage,
                          buttonText: "Confirm",
                          closeButtonText: "Cancel",
                          isCloseAble: false,
                        });
                      }}
                    >
                      <PopupExit />
                    </div>
                  </RBACWrapper>

                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {props.alertPopUpDatas?.length === 1 ? (
                        <></>
                      ) : (
                        <>
                          {collapseButton ? (
                            <></>
                          ) : (
                            <>
                              {i === 0 ? (
                                <>
                                  <div
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      backgroundColor: "#EAEEFF",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50px",
                                      cursor: "pointer",
                                      border: "1px solid #516beb",
                                    }}
                                    // onClick={() => dispatch(tabMotion(3))}

                                    onClick={() => {
                                      // if (entity === "invoices") {
                                      //   dispatch(invoiceTabMotion(3));
                                      // } else {
                                      //   dispatch(tabMotion(3));
                                      // }
                                      dispatch(openModal());
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                        color: "#516BEB",
                                      }}
                                    >
                                      +{props.alertPopUpDatas?.length - 1}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <div></div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DairyNotesAlertCard;
