import { HeadingXSmall, LabelSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import React, { useContext, useState } from "react";
import { STYLE_TYPE } from "baseui/checkbox";
import { useDispatch, useSelector } from "react-redux";

import InvoiceUser from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InvoiceUser";
import DairyNoteEdit from "../../../assets/img/svg/CustomerOverViewIcon/DairyNoteEdit";
import DocDownArSvg from "../../../assets/img/svg/DocDownArSvg";
import DocRigntSvg from "../../../assets/img/svg/DocRigntSvg";
import DownloadSvg from "../../../assets/img/svg/DownloadSvg";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";

import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import DocFileType from "../../../assets/img/svg/DocFileType";
import { EMAIL } from "../../../constants";
import { editDocumentForm } from "../../../redux/customerOverview/DMSystem/action";
import { downloadDocumentData } from "../../../services/documentSystem";
import { ModalContext } from "../../../providers/ModalProvider";
import Warning from "../../../assets/img/warning.png";
import { editInvoiceDocumentForm } from "../../../redux/invoiceOverview/DMSystemINV/action";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  VIEW_DOWNLOAD_DOCUMENTS,
  EDIT_DOCUMENTS,
  DELETE_DOCUMENTS,
} from "../../../providers/RBACProvider/permissionList";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { IconButton } from "../../../components/IconButton";
import { TextButton, KIND, SIZE } from "../../../components/TextButton";

const DocumentType = ({ type }) => {
  switch (type) {
    case "pdf":
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
      return (
        <div className="file_wraper_icon --doc_purple">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "csv":
    case "xls":
    case "xlsx":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "doc":
    case "docx":
      return (
        <div className="file_wraper_icon --doc_blue">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "txt":
    case "plain":
      return (
        <div className="file_wraper_icon --doc_grey">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingLeft: "2px" }}
          >
            {type === "plain" ? "TXT" : type.toUpperCase()}
            {/* {type.toUpperCase()} */}
          </p>
        </div>
      );

    default:
      return (
        <div className="file_wraper_icon --doc_yellow">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type?.length >= 4 && "--doc_font_size"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );
  }
};

const SelectDoc = (props) => {
  let {
    res,
    resourceId,
    deleteMethod,
    isSelected = false,
    onFileSelectChange,
    entity,
    type,
  } = props;

  const { handleModal } = useContext(ModalContext);

  const [isExpand, setIsExpand] = useState(false);
  // const [isSelect, setIsSelect] = useState(isSelected);
  const [isLoading, setIsLoading] = useState(false);

  let dispatch = useDispatch();

  let { drawerType } = useSelector((doc) => doc.customerDocumentReducer);
  let { drawerInvoiceState, drawerInvoiceType } = useSelector(
    (s) => s.invoiceDocumentReducer
  );
  let drawerState = entity === "invoices" ? drawerInvoiceType : drawerType;

  function epandCard() {
    setIsExpand(!isExpand);
  }

  let splitType = res && res.mime_type && res.mime_type.split("/");

  let { currentDefaultFormatDetails, currentOrganization, users } =
    useContext(MaxyfiContext);

  let noteLocalDate =
    res.audit_info &&
    res.audit_info.audit_on &&
    utcTimstampToLocalDate(
      res.audit_info.audit_on,
      currentDefaultFormatDetails.date_format
    );

  let created =
    res &&
    res.created_at &&
    utcTimstampToLocalDate(
      res.created_at,
      currentDefaultFormatDetails.date_format
    );

  let noteUser = res.modified_by ? res.modified_by : res.created_by;

  let noteUserRef = users.find((e) => e.id === noteUser);
  let splitingType = res && res.mime_type && res.mime_type.split("/");

  return (
    <div
      className={
        isExpand ? "dms_card_container --dms_card_pt" : "dms_card_container"
      }
    >
      <div className="dms_card_head">
        <div className="dms_card_doc_type_content">
          <div>
            <DocumentType
              type={
                res.tab === "timeline"
                  ? res.mime_type
                  : splitType[1]
                  ? splitType[1]
                  : ""
              }
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            {isExpand ? (
              <LabelSmall
                title={res.file_name}
                $style={{
                  lineHeight: "16px",
                  color: "#516beb",
                  paddingBottom: "4px",
                  // whiteSpace: "nowrap",
                  // overflow: "hidden",
                  // minWidth: "100px",
                  // maxWidth: "200px",
                  // textOverflow: "ellipsis",
                }}
              >
                {res.file_name}
              </LabelSmall>
            ) : (
              <LabelSmall
                title={res.file_name}
                $style={{
                  lineHeight: "15px",
                  color: "#516beb",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  minWidth: "100px",
                  maxWidth: "200px",
                  textOverflow: "ellipsis",
                }}
              >
                {res.file_name}
              </LabelSmall>
            )}

            <HeadingXSmall $style={{ lineHeight: "15px", color: "#787878" }}>
              {res?.category}
              {res && res.tab === "timeline" ? (
                <></>
              ) : (
                <>
                  {res?.category === "CUSTOMER" ? (
                    <></>
                  ) : (
                    <>/ {res?.invoice_number}</>
                  )}
                  / {res.sub_category}
                </>
              )}
            </HeadingXSmall>
          </div>
        </div>
        <CheckBoxBaseweb
          checked={isSelected}
          checkmarkType={STYLE_TYPE.default}
          onChange={() => {
            onFileSelectChange({
              name: res.file_name,
              type: splitingType[1] ? splitingType[1] : "",
              fileRef: res._id,
              isNewDoc: false,
              scanStatus: "SUCCESS",
            });
            // setIsSelect(!isSelected);
          }}
        />
      </div>
    </div>
  );
};

export default SelectDoc;
