import React, { useState } from "react";
import { useQuery } from "react-query";
import { useDropzone } from "react-dropzone";
import { Icon, Typography } from "../../../../components_v2";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { COMMUNICATION_TEMPLATE } from "../../../../constants";
import { getCommunicationTemplateList } from "../../../../services";
import { useDispatch } from "react-redux";
import _ from "lodash";

const fileSize = 10000000;

function uploadLogoValidation(file) {
  if (file.size > fileSize) {
    return {
      code: "file is too-large",
      message: `File Size is Largen than 1MB`,
    };
  }
}
const DocumentUploadPoper = ({
  setFiles,
  files,
  backgroundColour,
  iconColor,
  iconBg,
  currentOrganization,
}) => {
  const [isTags, setIsTags] = useState(["CUSTOMER"]);
  const { getRootProps, getInputProps, acceptedFiles, fileRejections, open } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/*": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
      },
      validator: uploadLogoValidation,
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const communicationTemplateData = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-LETTER-${currentOrganization}`,
      {
        filters: {
          disable: false,
          type: "letter",
          sub_type: "letter_content",
          tags: `CUSTOMER`,
        },
      },
    ],
    async ({ queryKey }) => {
      let { page, page_size, sortBy, filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  return (
    <div>
      <StatefulPopover
        overrides={{
          Arrow: {
            style: ({ $theme }) => ({
              outline: `#CDCED9 solid`,
              backgroundColor: "#16164B",
            }),
          },
          Inner: {
            style: ({ $theme }) => ({
              width: "143px",
              backgroundColor: "#FFFFFF",
            }),
          },
        }}
        content={(main) => (
          <div className="poper-category-docs">
            <div style={{ padding: "4px" }}>
              <div>
                <Typography
                  // {...getRootProps({ className: "dropzone" })}
                  className="regular"
                  type="p"
                  style={{ padding: "10px" }}
                  onClick={() => {
                    open();
                    main.close();
                  }}
                >
                  {/* <input {...getInputProps()} /> */}
                  From Computer
                </Typography>
              </div>

              <StatefulPopover
                placement={PLACEMENT.rightTop}
                overrides={{
                  Arrow: {
                    style: ({ $theme }) => ({
                      outline: `##CDCED9 solid`,
                      backgroundColor: "#16164B",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      padding: "10px",
                      width: "200px",
                      backgroundColor: "#FFFFFF",
                    }),
                  },
                }}
                content={(close) => (
                  <div className="poper-category-docs">
                    {" "}
                    <div padding={"20px"}>
                      {communicationTemplateData &&
                      communicationTemplateData.data &&
                      communicationTemplateData.data.data &&
                      communicationTemplateData.data.data.docs ? (
                        communicationTemplateData.data.data.docs
                          .filter((e) => {
                            const [tagType, tagEvent] = isTags;
                            const [type, event] = e.tags;
                            if (
                              (tagEvent && event && tagEvent === event) ||
                              (e.tags.length == 1 &&
                                tagType &&
                                type &&
                                tagType === type) ||
                              (e.tags.length == 1 &&
                                tagType &&
                                type &&
                                tagType !== type)
                            ) {
                              return true;
                            } else if (
                              (tagEvent && event && tagEvent !== event) ||
                              (e.tags.length == 1 &&
                                tagType &&
                                type &&
                                tagType !== type)
                            ) {
                              return false;
                            }
                          })
                          .map((e, i) => {
                            return (
                              <div
                                className="filedesktop"
                                onClick={async () => {
                                  setFiles([
                                    {
                                      label: e.name,
                                      fileRef: e._id,
                                      name: e.name,
                                      type: "pdf",
                                      file_type: "pdf",
                                      isTemplate: true,
                                      path: e.name,
                                      id: e._id,
                                    },
                                  ]);
                                }}
                              >
                                <Typography
                                  $style={{
                                    color: "#333860",
                                  }}
                                  onClick={() => main.close()}
                                >
                                  {e.name}
                                </Typography>
                              </div>
                            );
                          })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
                returnFocus
                autoFocus
              >
                <Typography
                  className="regular"
                  type="p"
                  style={{ padding: "10px" }}
                >
                  {" "}
                  From Template
                </Typography>
              </StatefulPopover>
            </div>
          </div>
        )}
        returnFocus
        autoFocus
      >
        <div
          className="upload-document_div"
          style={{ backgroundColor: backgroundColour }}
        >
          <Icon
            icon="download_outline"
            color={iconColor}
            style={{
              border: "50%",
              backgroundColor: iconBg,
              padding: "8px",
              borderRadius: "50%",
            }}
          />

          <Typography className="regular" type="p">
            {" "}
            Drag 'n' Drop some files here, or click to select files
          </Typography>
        </div>
      </StatefulPopover>
    </div>
  );
};

export default DocumentUploadPoper;
