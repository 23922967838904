import React from "react";

function ArrowRight({ size = 18, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10.5C1.72386 10.5 1.5 10.7239 1.5 11C1.5 11.2761 1.72386 11.5 2 11.5V10.5ZM19.3536 11.3536C19.5488 11.1583 19.5488 10.8417 19.3536 10.6464L16.1716 7.46447C15.9763 7.2692 15.6597 7.2692 15.4645 7.46447C15.2692 7.65973 15.2692 7.97631 15.4645 8.17157L18.2929 11L15.4645 13.8284C15.2692 14.0237 15.2692 14.3403 15.4645 14.5355C15.6597 14.7308 15.9763 14.7308 16.1716 14.5355L19.3536 11.3536ZM2 11.5H19V10.5H2V11.5Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowRight;
