export const ADDING_INVOICE_DRAWER_OPEN = "ADDING_INVOICE_DRAWER_OPEN";
export const ADDING_INVOICE_DRAWER_TYPE = "ADDING_INVOICE_DRAWER_TYPE";
export const ADDING_INVOICE_SELECTED_DOCUMENT_DATA =
  "ADDING_INVOICE_SELECTED_DOCUMENT_DATA";
export const ADDING_DOCUMENT_FROM_DESKTOP = "ADDING_DOCUMENT_FROM_DESKTOP";
export const UPDATING_ADDING_INVOICE_ATTACHMENT_FROM_DESKTOP =
  "UPDATING_ADDING_INVOICE_ATTACHMENT_FROM_DESKTOP";

export const UNSELECTED_DOCUMENT_ADDING_INVOICE =
  "UNSELECTED_DOCUMENT_ADDING_INVOICE";

export const EDITING_DOCUMENT_ADDING_INVOICE =
  "EDITING_DOCUMENT_ADDING_INVOICE";

export const editingDocumentAddingInvoice = (editingData) => {
  return {
    type: EDITING_DOCUMENT_ADDING_INVOICE,
    payload: editingData,
  };
};

export const unSelectedDocumentsAddingInvoice = (data) => {
  return {
    type: UNSELECTED_DOCUMENT_ADDING_INVOICE,
    payload: data,
  };
};

export const updatingInvoiceDeskDocument = (data) => {
  return {
    type: UPDATING_ADDING_INVOICE_ATTACHMENT_FROM_DESKTOP,
    payload: data,
  };
};

export const addingInvoiceDocumentFromDesk = (data) => {
  return {
    type: ADDING_DOCUMENT_FROM_DESKTOP,
    payload: data,
  };
};

export const addingInvoiceDocumentData = (data) => {
  return {
    type: ADDING_INVOICE_SELECTED_DOCUMENT_DATA,
    payload: data,
  };
};

export const documentInvoiceAddingDrawerState = (data) => {
  return {
    type: ADDING_INVOICE_DRAWER_OPEN,
    payload: data,
  };
};

export const addingInvoiceDrawerType = (data) => {
  return {
    type: ADDING_INVOICE_DRAWER_TYPE,
    payload: data,
  };
};
