import React from "react";

const AlertInfo = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.52939 0.333984C3.06586 0.333984 0.254883 3.32065 0.254883 7.00065C0.254883 10.6807 3.06586 13.6673 6.52939 13.6673C9.99292 13.6673 12.8039 10.6807 12.8039 7.00065C12.8039 3.32065 9.99292 0.333984 6.52939 0.333984ZM6.52939 10.334C6.18429 10.334 5.90194 10.034 5.90194 9.66732V7.00065C5.90194 6.63398 6.18429 6.33398 6.52939 6.33398C6.87449 6.33398 7.15684 6.63398 7.15684 7.00065V9.66732C7.15684 10.034 6.87449 10.334 6.52939 10.334ZM7.15684 5.00065H5.90194V3.66732H7.15684V5.00065Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default AlertInfo;
