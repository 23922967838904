import React from "react";

const MainDefaultAdhocSvg = ({ size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.25007 5.89364V12.1064C5.54202 12.4409 6.50007 13.6039 6.50007 15.0001C6.50007 16.6568 5.15662 18 3.49993 18C1.84323 18 0.5 16.6568 0.5 14.9999C0.5 13.6037 1.45805 12.4407 2.75 12.1061V5.89364C1.45805 5.55908 0.5 4.39607 0.5 2.99993C0.5 1.34323 1.84323 0 3.49993 0C5.15662 0 6.50007 1.34323 6.50007 2.99993C6.50007 4.39603 5.54202 5.55908 4.25007 5.89364ZM3.49993 1.50007C2.67147 1.50007 2.00007 2.17147 2.00007 2.99993C2.00007 3.82838 2.67147 4.5 3.49993 4.5C4.32838 4.5 5 3.82838 5 2.99993C5 2.17147 4.32838 1.50007 3.49993 1.50007ZM2.00007 15.0001C2.00007 15.8285 2.67147 16.4999 3.49993 16.4999C4.32838 16.4999 5 15.8285 5 15.0001C5 14.1716 4.32838 13.5 3.49993 13.5C2.67147 13.5 2.00007 14.1716 2.00007 15.0001ZM16.25 5.24993V12.1064C17.5413 12.4409 18.5 13.6039 18.5 15.0001C18.5 16.6568 17.1567 18 15.5001 18C13.8434 18 12.4999 16.6568 12.4999 15.0001C12.4999 13.604 13.4589 12.4409 14.7499 12.1064V5.24993C14.7499 4.42239 14.0792 3.75007 13.2507 3.75007H10.5606L11.75 4.93941L10.6895 6.00029L8.2197 3.53048C7.92676 3.23728 7.92676 2.76209 8.2197 2.46981L10.6886 0L11.7493 1.06066L10.5606 2.25H13.2507C14.9074 2.25 16.25 3.59416 16.25 5.24993ZM14 15.0001C14 15.8285 14.6716 16.4999 15.5 16.4999C16.3285 16.4999 16.9999 15.8285 16.9999 15.0001C16.9999 14.1716 16.3285 13.5 15.5 13.5C14.6716 13.5 14 14.1716 14 15.0001Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default MainDefaultAdhocSvg;
