import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import CheckBoxBaseweb from "../../CheckBoxBaseweb";
import { ParagraphSmall } from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import { TextButton, KIND, SIZE } from "../../TextButton";
import { useDispatch, useSelector } from "react-redux";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  setCustomerSummaryFilter,
  setNeedsActionFilter,
  setCustomerStageFilter,
} from "../../../redux/customerSummary/action";
const CustomerStageFilters = (props) => {
  const dispatch = useDispatch();
  let { referenceData } = useContext(MaxyfiContext);
  const CustomerStageFilterRd = referenceData?.customer_stage_filter;

  let { control, watch, setValue, resetForm } = props;

  return (
    <>
      {CustomerStageFilterRd.sort((a, b) => a.id.localeCompare(b.id)).map(
        (i) => (
          <div style={{ paddingTop: "2px" }}>
            {
              <Controller
                name={`stage.${i.id}`}
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb {...field} checked={field.value}>
                    <ParagraphSmall style={{ color: "#333860" }}>
                      <FormattedMessage id={i.id}>{i.id}</FormattedMessage>
                    </ParagraphSmall>
                  </CheckBoxBaseweb>
                )}
              />
            }
          </div>
        )
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: "10px",
          borderTop: "0.5px solid #CDCED9",
          paddingTop: "10px",
          marginTop: "10px",
        }}
      >
        <div style={{ width: "100px" }}>
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              // CustomerStageFilterRd.forEach((e) => {
              //   setValue(e.id, false);
              // });
              // dispatch(setCustomerSummaryFilter({ formFilters: [{ id: [] }] }));
              // dispatch(setNeedsActionFilter({}));
              // dispatch(setCustomerStageFilter({}));
              resetForm();
            }}
          >
            <FormattedMessage id="clear_filters">
              clear_filters
            </FormattedMessage>
          </TextButton>
        </div>
        <div style={{ width: "100px" }}>
          <TextButton
            type="submit"
            kind={KIND.primary}
            size={SIZE.mini}
            fullWidth
          >
            <FormattedMessage id="apply">apply</FormattedMessage>
          </TextButton>
        </div>
      </div>
    </>
  );
};

export default CustomerStageFilters;
