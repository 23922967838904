import React, { useContext, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { SIZE } from "baseui/input";
import { SelectBox } from "../../../../components/SelectBox";
import { EMAIL, LETTER, SMS } from "../../../../constants";
import { TextArea } from "../../../../components/TextArea";
import { LabelLarge } from "baseui/typography";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { Controller } from "react-hook-form";
import moment from "moment";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getCustomerContactDetail,
  getCommunicationTemplateList,
} from "../../../../services";
import { ADD_LIST_VALUES } from "../../../../providers/RBACProvider/permissionList";
import { useSelector } from "react-redux";

const UnsuccessfulForm = (call) => {
  let {
    referenceData,
    currentOrganization,
    userInfo,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  let intl = useIntl();
  let { id } = useParams();

  let { control, errors, actioWatch, setValue, watch, settingData } = call;
  let [is_send_confirmation_message] = actioWatch;
  const [today, setToday] = useState(false);
  const [tomorrow, setTomorrow] = useState(false);
  const { customerConatactDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  let [confirmationTemplates, setConfirmationTemplates] = useState([]);

  let recipientId = actioWatch[5];

  let recipientData =
    recipientId && recipientId[0] && recipientId[0].recipient_id;

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("customers");

  // Finding Email Value Length and Phone Code

  let EmailUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_email === true);
  let PhoneUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_phone === true);

  let type = referenceData["template_type"];

  let mappinTypeValue =
    referenceData && referenceData["template_type"]
      ? referenceData["template_type"]
          .filter(({ id }) => id != "letter")
          .map((e) => {
            if (e.id == "email" && !EmailUser) {
              return { ...e, disabled: true };
            } else if (e.id === "sms" && !PhoneUser) {
              return { ...e, disabled: true };
            } else if (
              e.id === "sms" &&
              !currentDefaultFormatDetails.is_sms_configured
            ) {
              return { ...e, disabled: true };
            } else if (
              e.id === "whatsapp" &&
              !currentDefaultFormatDetails?.is_whatsapp_configured
            ) {
              return { ...e, disabled: true };
            }
            return e;
          })
      : [];

  let [confirmationMessageType] = watch(["confirmation_message_type"]);

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags,
        type,
      },
      organization: currentOrganization,
      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: ["CONTACT_UNSUCCESSFUL"],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      <div>
        <LabelLarge>
          <FormattedMessage id="follow_up_call">
            follow_up_call
          </FormattedMessage>{" "}
          :
        </LabelLarge>
      </div>
      <div className="cp-action-promise-amount_input">
        <div
          onClick={() => {
            setValue("followup_date", moment().startOf("day").toDate());
            setToday(true);
            setTomorrow(false);
          }}
        >
          <div
            // className="action-promise-amount--input"
            className={
              today === true
                ? "cp-action-call-unsuccessful--active"
                : "cp-action-call-unsuccessful"
            }
          >
            <div
            // className="cp-action_unsuccess--setdate"
            // onClick={() => {
            //   setValue("followup_date", moment().startOf("day").toDate());
            //   setToday(true);
            //   setTomorrow(false);
            // }}
            >
              Today
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setTomorrow(true);
            setToday(false);
            setValue(
              "followup_date",
              moment().add(1, "days").startOf("day").toDate()
            );
          }}
        >
          <div
            className={
              tomorrow === true
                ? "cp-action-call-unsuccessful--active"
                : "cp-action-call-unsuccessful"
            }
            // className="action-promise-amount--input"
          >
            <div
            // className="cp-action_unsuccess--setdate"
            // onClick={() => {
            //   setTomorrow(true);
            //   setToday(false);
            //   setValue(
            //     "followup_date",
            //     moment().add(1, "days").startOf("day").toDate()
            //   );
            // }}
            >
              Tomorrow
            </div>
          </div>
        </div>

        <div
          // className="action-promise-amount--input"
          style={{ width: "136px", paddingTop: "10px" }}
        >
          <Controller
            name="followup_date"
            control={call.control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                disabled={true}
                name={field.name}
                size={SIZE.compact}
                error={
                  call.errors[field.name] && call.errors[field.name].message
                }
                // label={intl.formatMessage({
                //   id: "followup_date",
                // })}
                // placeholder={intl.formatMessage({
                //   id: " ",
                // })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.date);
                }}
              />
            )}
          />
        </div>
      </div>
      <div style={{ width: "260px", paddingTop: "20px" }}>
        <Controller
          name="call_unsuccessful_reason"
          control={call.control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              requiredAstric={true}
              {...field}
              clearable={false}
              name={field.name}
              size={SIZE.mini}
              creatable={creatablePermission ? true : false}
              error={call.errors[field.name] && call.errors[field.name].message}
              label={intl.formatMessage({
                id: "unsuccessful_reason",
              })}
              placeholder={intl.formatMessage({
                id: "unsuccessful_reason",
              })}
              // size={SIZE.compact}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={referenceData["call_unsuccessful_reason"]}
            />
          )}
        />
      </div>
      <div style={{ paddingTop: "15px" }}>
        <Controller
          defaultValues={false}
          name="is_hold_workflow"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb
              {...field}
              // disabled={true}
              checked={field.value}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <FormattedMessage id="hold_workflow_till_follow_up_date">
                hold_workflow_till_follow_up_date
              </FormattedMessage>
            </CheckBoxBaseweb>
          )}
        />
      </div>

      <div className="cp-message-confirmation-checkbox">
        <Controller
          defaultValues={false}
          name="is_send_confirmation_message"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb
              {...field}
              checked={field.value}
              labelPlacement={LABEL_PLACEMENT.right}
              disabled={!EmailUser && !PhoneUser}
            >
              <FormattedMessage id="send_confirmation_message">
                send_confirmation_message
              </FormattedMessage>
            </CheckBoxBaseweb>
          )}
        />
      </div>

      {is_send_confirmation_message ? (
        <div
          className="cp-action-message-confirmation"
          style={{ paddingTop: "20px" }}
        >
          <>
            <div className="cp-message-confirmation-type">
              <Controller
                defaultValues={[]}
                name="confirmation_message_type"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comm_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comm_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                        setValue("confirmation_message_template_id", []);
                      }

                      field.onChange(e.value);
                    }}
                    // options={
                    //   referenceData["template_type"].filter(
                    //     (e) => e.id !== LETTER
                    //   ) || []
                    // }
                    options={mappinTypeValue}
                  />
                )}
              />
            </div>
            {/* TODO: TEMPLATE TYPE */}
            <div className="cp-action-promise-pay-con">
              <Controller
                name="confirmation_message_template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "Call_Successful_Confirmation",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Call_Successful_Confirmation",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={confirmationTemplates || []}
                  />
                )}
              />
            </div>
          </>
        </div>
      ) : (
        ""
      )}
      <div
        className="cp-action-promise_test-area"
        style={{ paddingTop: "20px" }}
      >
        <Controller
          name="comment"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              clearable={false}
              error={errors[field.name] && errors[field.name].message}
              name={field.name}
              label={intl.formatMessage({
                id: "user_comments",
              })}
              placeholder={intl.formatMessage({
                id: "user_comments",
              })}
              value={field.value}
            />
          )}
        />
      </div>
    </>
  );
};

export default UnsuccessfulForm;
