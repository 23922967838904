export const ADD_PREDEFINED_ADHOC_UPLOAD_DATA =
  "ADD_PREDEFINED_ADHOC_UPLOAD_DATA";
export const UPDATE_MAP_FIELDS = "UPDATE_MAP_FIELDS";
export const UPDATE_FORMAT_DATA = "UPDATE_FORMAT_DATA";
export const PREDEFINED_DEFAULT_DATA = "PREDEFINED_DEFAULT_DATA";
export const UPDATE_SYSTEM_FIELDS = "UPDATE_SYSTEM_FIELDS";
export const UPDATE_TEMPLATE_DATA_LIST = "UPDATE_TEMPLATE_DATA_LIST";
export const PREDEFINED_INITIALSTATE_STATE = "PREDEFINED_INITIALSTATE_STATE";
export const ADHOC_REFETCH = "ADHOC_REFETCH";
export const SET_AU_FILTER = "SET_AU_FILTER";
export const ADHOC_FILTER = "ADHOC_FILTER";
export const PREDEFINED_VALIDATION_ADHOC_FILTER =
  "PREDEFINED_VALIDATION_ADHOC_FILTER";
export const PREDEFINED_BUSINESS_UNIT_DATA = "PREDEFINED_BUSINESS_UNIT_DATA";
export const FORMULA_FIELDS = "FORMULA_FIELDS";
export const DELETE_FORMULA_FIELDS = "DELETE_FORMULA_FIELDS";
export const UPDATE_FORMULA_FIELDS = "UPDATE_FORMULA_FIELDS";
export const UPDATE_MAPPING_FIELDS = "UPDATE_MAPPING_FIELDS";

export const setAdhocFilter = (data) => {
  return {
    type: ADHOC_FILTER,
    payload: data,
  };
};

export const setAdhocUploadFilter = (data) => {
  return {
    type: SET_AU_FILTER,
    payload: data,
  };
};

export const setPredefinedInitialAdhocState = () => {
  return {
    type: PREDEFINED_INITIALSTATE_STATE,
  };
};

export const setPredefinedAdhocUploadData = (payload) => {
  return {
    type: ADD_PREDEFINED_ADHOC_UPLOAD_DATA,
    payload,
  };
};

export const updateSystemField = ({ index, systemField, type }) => {
  return {
    type: UPDATE_SYSTEM_FIELDS,
    payload: { index, systemField, type },
  };
};

export const updateFormatData = ({ index, formatData }) => {
  return {
    type: UPDATE_FORMAT_DATA,
    payload: { index, formatData },
  };
};

export const predefinedDefaultData = ({ index, defaultData, referance }) => {
  return {
    type: PREDEFINED_DEFAULT_DATA,
    payload: { index, defaultData, referance },
  };
};

export const updateTemplateDataList = (templateData) => {
  return {
    type: UPDATE_TEMPLATE_DATA_LIST,
    payload: { ...templateData },
  };
};

export const adhocRefetchTable = () => {
  return {
    type: ADHOC_REFETCH,
  };
};

export const predefindValidationFields = () => {
  return {
    type: PREDEFINED_VALIDATION_ADHOC_FILTER,
  };
};

export const predefinedBusinessUnitData = ({ rdBussinessUnit }) => {
  return {
    type: PREDEFINED_BUSINESS_UNIT_DATA,
    payload: rdBussinessUnit,
  };
};

export const formulaDispachFn = (payload) => {
  return {
    type: FORMULA_FIELDS,
    payload,
  };
};

export const deleteFormulaField = (payload) => {
  return {
    type: DELETE_FORMULA_FIELDS,
    payload,
  };
};

export const updateFormulaField = (payload) => {
  return {
    type: UPDATE_FORMULA_FIELDS,
    payload,
  };
};

export const predefindUploadMappingData = (payload) => {
  return {
    type: UPDATE_MAPPING_FIELDS,
    payload,
  };
};
