import React from "react";

function Minimize({ color = "#FD372A", size = 18 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.87482 10.9082L3.04595 15.7371C2.94207 15.8408 2.81276 15.8915 2.65801 15.8891C2.50326 15.8867 2.37395 15.8336 2.27007 15.7297C2.1662 15.6259 2.11426 15.4949 2.11426 15.3369C2.11426 15.1789 2.1662 15.0488 2.27007 14.9466L7.09163 10.125H3.93732C3.77795 10.125 3.64438 10.071 3.53663 9.96317C3.42876 9.8553 3.37482 9.72167 3.37482 9.5623C3.37482 9.4028 3.42876 9.26924 3.53663 9.16161C3.64438 9.05386 3.77795 8.99999 3.93732 8.99999H8.32201C8.51401 8.99999 8.67501 9.06493 8.80501 9.1948C8.93488 9.3248 8.99982 9.4858 8.99982 9.6778V14.0625C8.99982 14.2219 8.94588 14.3554 8.83801 14.4632C8.73013 14.571 8.59651 14.625 8.43713 14.625C8.27763 14.625 8.14407 14.571 8.03645 14.4632C7.9287 14.3554 7.87482 14.2219 7.87482 14.0625V10.9082ZM10.908 7.87499H14.0623C14.2217 7.87499 14.3553 7.92892 14.463 8.0368C14.5709 8.14467 14.6248 8.2783 14.6248 8.43767C14.6248 8.59717 14.5709 8.73074 14.463 8.83836C14.3553 8.94611 14.2217 8.99999 14.0623 8.99999H9.67763C9.48563 8.99999 9.32463 8.93505 9.19463 8.80517C9.06476 8.67517 8.99982 8.51417 8.99982 8.32217V3.93749C8.99982 3.77811 9.05376 3.64455 9.16163 3.5368C9.26951 3.42892 9.40313 3.37499 9.56251 3.37499C9.72201 3.37499 9.85557 3.42892 9.9632 3.5368C10.0709 3.64455 10.1248 3.77811 10.1248 3.93749V7.0918L14.9537 2.26292C15.0559 2.15917 15.1861 2.1073 15.3441 2.1073C15.5021 2.1073 15.633 2.15917 15.7369 2.26292C15.8406 2.3668 15.8925 2.49774 15.8925 2.65574C15.8925 2.81374 15.8406 2.94386 15.7369 3.04611L10.908 7.87499Z"
        fill={color}
      />
    </svg>
  );
}

export default Minimize;
