import React, { useState, useEffect, useContext } from "react";
import ActionEdit from "../../assets/img/svg/ActionEdit";
import { TextButton } from "../../components/TextButton";
import { ModalContext } from "../../providers/ModalProvider";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import generateInDaysMessage from "../../utils/generateInDaysMessage";
import { putBusinessStatus, putCustomerStatus } from "../../services";
import { CUSTOMER_OVERVIEW } from "../../constants";
import {
  assignWorkflow,
  getCustomerActions,
  putCreditLimitData,
} from "../../services";
import ClipBoard from "../../assets/img/svg/ClipBoard";
import CopyToClipboard from "react-copy-to-clipboard";
import { getOrganization } from "../../services";
import {
  holdActionModal,
  releaseActionModal,
  smsGateway,
  unAssignWorkflow,
  refetchActions,
  subscriptionsDrawer,
} from "../../redux/customerOverview/action";
import {
  HeadingXSmall,
  Label1,
  LabelLarge,
  LabelMedium,
  LabelXSmall,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import { toast } from "react-toastify";
import { FormControl } from "baseui/form-control";
import { useForm, Controller, set, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import moment from "moment";
import {
  getActionData,
  tabMotion,
  workflowAddModal,
} from "../../redux/actions";
import { Label2 } from "baseui/typography";
import PlusOver from "../../assets/img/svg/CustomerOverViewIcon/PlusOver";
import Tick from "../../assets/img/svg/CustomerOverViewIcon/Tick";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { Tag } from "baseui/tag";
import Calendar from "../../assets/img/svg/CustomerOverViewIcon/Calendar";
import Error from "../../assets/img/svg/CustomerOverViewIcon/Error";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Select } from "baseui/select";
import Edit from "../../assets/img/svg/Template/Edit";
import { useParams } from "react-router-dom";
import CpContactCard from "./CpContactCard";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import Plus from "../../assets/img/svg/Upload/Plus";
import AddContactDetailsModal from "./AddContactDetailsModal";
import ContactDetailsCard from "./ContactDetailsCard";
import { useQuery, useQueryClient } from "react-query";
// import { getCustomerOverviewData } from "../../services";
import CpWorkflowModal from "../../components/CpWorkflowModal/CpWorkflowModal";
import { FormattedDate, FormattedNumber } from "react-intl";
import { StatefulTooltip, TRIGGER_TYPE, PLACEMENT } from "baseui/tooltip";
import { Block } from "baseui/block";
import { Accordion, Panel } from "baseui/accordion";
import UpArrow from "../../assets/img/svg/UpArrow";
import DownArrow from "../../utils/DownArrow";
import { getCustomerDetails, putRelData, putTagsData } from "../../services";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerDetailsAction } from "../../redux/actions";
import Loader from "../../components/Loader";
import { getCustomerContactDetail } from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import TagsAdd from "../../assets/img/svg/TagsAdd";
import { TextBox } from "../../components/TextBox";
import { FilterInputBox } from "../../components/FilterInputBox";
import { Input } from "baseui/input";
import TagExit from "../../assets/img/svg/TagExit";
import { SelectBox } from "../../components/SelectBox";
import getSelectValues from "../../utils/getSelectValues";
import setSelectValues from "../../utils/setSelectValues";
import { useMutation } from "react-query";
import { tagDelete } from "../../redux/actions";
import AllActionPopOver from "./COTabContainers/Actions/AllActionPopOver";
import { openModal } from "../../redux/customerDairyNote/action";
import {
  activeWorkflowData,
  addContactModal,
  customerActionDatas,
  isOpenModal,
} from "../../redux/customerOverview/action";
import { actionWorkFlow } from "../../redux/createWorkFlow/actions";
import PlusOne from "../../assets/img/svg/Plus";
import { Button } from "baseui/button";
import ReleaseOnHold from "../../assets/img/svg/NestedRibbons/ReleaseOnHold";
import { ON_HOLD, ACTIVE } from "../../constants";
import CaptureHold from "../../assets/img/svg/NestedRibbons/CaptureHold";
import * as ModalButton from "../../components/TextButton";

import PauseSvg from "../../assets/img/svg/PauseSvg";
import PauseSvgError from "../../assets/img/svg/PauseSvgError";
import UparrowCredit from "../../assets/img/svg/UparrowCredit";
import CardAlert from "../../assets/img/svg/CardAlert";
import Call from "../../assets/img/svg/CpCard/Call";
import Message from "../../assets/img/svg/Workflow/Message";
import Dispute from "../../assets/img/svg/Dispute";
import Email from "../../assets/img/svg/CpCard/Email";
import PromiseToPay from "../../assets/img/svg/PromiseToPay";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { NumericFormat } from "react-number-format";
import ActionRemove from "../../assets/img/svg/ActionRemove";
import WarningImage from "../../assets/img/warning.png";
import NextActionForward from "../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import ReviewPromise from "../../assets/img/svg/CustomerOverViewIcon/ReviewPromise";
import ReviewPromiseToPay from "../../assets/img/svg/ReviewPromiseToPay";
import CallSvg from "../../assets/img/svg/CusOverviewActionsIcon/CallSvg";
import MessageSvg from "../../assets/img/svg/CusOverviewActionsIcon/MessageSvg";
import DisputeSvg from "../../assets/img/svg/CusOverviewActionsIcon/DisputeSvg";
import ReviewPromiseSvg from "../../assets/img/svg/CusOverviewActionsIcon/ReviewPromiseSvg";
import PromiseToPayN from "../../assets/img/svg/CusOverviewActionsIcon/PromiseToPayN";
import EmailSvgN from "../../assets/img/svg/CusOverviewActionsIcon/EmailSvgN";
import SmsSvgN from "../../assets/img/svg/CusOverviewActionsIcon/SmsSvgN";
import WhatsAppIcon from "../../assets/img/svg/CusOverviewActionsIcon/WhatsAppIcon";
import CustomerStatusModal from "../../components/CustomerStatusModal/CustomerStatusModal";
import BusinessStatus from "../../components/BusinessStatus/BusinessStatus";
import {
  ASSIGN_CREDIT_LIMIT,
  ASSIGN_RM,
  ASSIGN_WORKFLOW,
  ASSIGN_BUSSINESS_LOCATION,
  ASSIGN_STATUS,
} from "../../providers/RBACProvider/permissionList";
import RelationManagerSVG from "../../assets/img/svg/RelationManagerSVG";
import PromiseToPayCall from "../../assets/img/svg/PromiseToPayCall";
import WorkFlowSVG from "../../assets/img/svg/WorkflowSvg";
import { CollectionAgencies } from "../../constants/IndustrialSector";
// ChartJS.register(ArcElement, Tooltip, Legend);

const CustomerInvoiceStats = ({ isQuickAction, getParams }) => {
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const {
    last_action_name,
    last_action_date,
    next_action_name,
    next_action_date,
    next_action_type,
    last_action_type,
  } = useSelector((state) => state.customerOverviewReducer.customerDetails);

  let lastActionFinalValues = moment
    .utc(last_action_date)
    .diff(moment().utc(), "days", true);
  lastActionFinalValues = Number(lastActionFinalValues).toFixed();
  let lastActionDaysLeftMessages = "";
  if (Math.abs(lastActionFinalValues) <= 0) {
    lastActionDaysLeftMessages = "(Today)";
  } else {
    lastActionDaysLeftMessages = `(${Math.abs(
      lastActionFinalValues
    )} days ago)`;
  }
  const [pen, setPen] = useState(false);
  const [focus, setFocus] = useState(false);

  const [creditLimitData, setCreditLimitData] = useState(
    customerDetails?.credit_limit?.value
  );

  const [creditData, setCreditData] = useState();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  const watchFields = watch();

  const percentage = customerDetails
    ? ((customerDetails.due_inv_amount && customerDetails.due_inv_amount.value
        ? customerDetails.due_inv_amount.value
        : 0) /
        (customerDetails.total_inv_amount &&
        customerDetails.total_inv_amount.value
          ? customerDetails.total_inv_amount.value
          : 0)) *
      100
    : 0;

  // const PieChart = () => (
  //   <div>
  //     <CircularProgressbar
  //       value={percentage}
  //       strokeWidth={50}
  //       styles={buildStyles({
  //         strokeLinecap: "butt",
  //         pathColor: "#E5EAFF",
  //         trailColor: "#516BEB",
  //       })}
  //     />
  //   </div>
  // );

  let {
    currentOrganization,

    referenceData,
    currencyFormat,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);
  const dispatch = useDispatch();

  const PaymentDatas = useSelector(
    (s) => s.customerOverviewReducer.customerDetails.promise_to_pay
  );

  let DisputeDatas = useSelector(
    (s) => s.customerOverviewReducer.customerDetails.disputes
  );

  let workFlowStatus = customerDetails?.workflow?.status;
  let quickActionEnabel = customerDetails?.is_quick_action;

  let filteredPromiseData = PaymentDatas?.filter(
    (i) => i.status === "IN_PROGRESS"
  );
  let inProgressPromiseToPay = PaymentDatas?.filter(
    (i) => i.status === "IN_PROGRESS"
  );
  let notClosedDispute = DisputeDatas?.filter((i) => i.status !== "CLOSED");

  let filteredDisputeData = DisputeDatas?.filter((i) => i.status !== "CLOSED");

  let sortedData = filteredDisputeData?.sort((a, b) => {
    return a.review_date - b.review_date;
  });
  let DisputeReviewDate = sortedData ? sortedData[0] : {};

  let disputeReviewDate = DisputeReviewDate?.review_date;

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  let promiseDataSort = filteredPromiseData?.sort((a, b) => {
    return a.promise_date - b.promise_date;
  });

  let lengthPayment = promiseDataSort ? promiseDataSort[0] : {};

  let paymentReviewDate = lengthPayment?.promise_date;

  const timeZone = orgRef?.time_zone;

  let DueInfo = ({ date }) => {
    let info = generateInDaysMessage(date, timeZone);
    return (
      <ParagraphMedium style={{ color: info.isAlert ? "red" : "green" }}>
        ({info.value})
      </ParagraphMedium>
    );
  };

  let PaymentDueInfo = ({ date }) => {
    let paymentInfo = generateInDaysMessage(date, timeZone);

    return (
      <ParagraphMedium style={{ color: paymentInfo.isAlert ? "red" : "green" }}>
        ({paymentInfo.value})
      </ParagraphMedium>
    );
  };

  let creditLimitValue =
    customerDetails &&
    customerDetails.credit_limit &&
    customerDetails.credit_limit?.value;
  const putCreditLimit = useMutation(
    (data) =>
      putCreditLimitData({
        ...data,
        customerId: getParams,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        let creditData =
          data &&
          data.data &&
          data.data.doc &&
          data.data.doc.credit_limit &&
          data.data.doc.credit_limit.value;
        // reset();
        // dispatch(closePaymentModal());
        dispatch(refetchActions());

        setCreditLimitData(creditData);
        setCreditData(
          data && data.data && data.data.doc && data.data.doc.credit_limit
        );
      },
    }
  );
  // useEffect(() => {
  //   setCreditLimitData(watchFields.credit_limit);
  // }, [watchFields]);
  let data = creditLimitData;

  const onBlur = async () => {
    await putCreditLimit.mutateAsync({
      credit_limit: parseInt(data),
    });
    // setCreditLimitData(data);
  };
  useEffect(() => {
    // onBlur();
    // setValue("credit_limit", customerDetails?.credit_limit?.value);
    setPen(false);
    setCreditLimitData(creditLimitValue);
    setCreditData(customerDetails && customerDetails.credit_limit);
  }, [customerDetails]);

  const clickRef = React.useRef(null);

  return (
    <>
      <div className="cp__top__section__wraper">
        {/* left card with chart start here */}
        <div className="cp__chart__wraper" style={{ position: "relative" }}>
          <div
            className="cp__details__amt"
            style={{ justifyContent: "space-between", gap: "7%" }}
          >
            <div className="cp-total__amount__container">
              <div className="cp-card__pie-chart">
                <Pie
                  data={{
                    datasets: [
                      {
                        label: "",
                        data: [100 - percentage, percentage],
                        backgroundColor: ["#fe9b95", "#a8b5f5"],
                      },
                    ],
                  }}
                />
              </div>
              <div>
                <div className="cp-total__amt-head">
                  <ParagraphMedium $style={{ color: "#333860" }}>
                    <FormattedMessage id="total_amount">
                      total_amount
                    </FormattedMessage>
                  </ParagraphMedium>
                  <div className="cp-card__pie-chart-inv-count">
                    <HeadingXSmall
                      $style={{ color: "#333860", lineHeight: "17px" }}
                    >
                      {customerDetails &&
                        customerDetails.total_outstanding_invoice_count}{" "}
                      <FormattedMessage id="inv">inv</FormattedMessage>
                    </HeadingXSmall>
                  </div>
                </div>
                <div className="cp-card__pie-chart-inv-amount">
                  {/* <ParagraphLarge $style={{ color: "#333860" }}> */}
                  {/* <sup>
                    {customerDetails.total_inv_amount &&
                      customerDetails.total_inv_amount.currency}
                  </sup> */}
                  <NumericFormat
                    // type="text"
                    displayType="text"
                    value={
                      customerDetails.total_outstanding_invoice_amount &&
                      customerDetails.total_outstanding_invoice_amount.value
                        ? Number(
                            customerDetails?.total_outstanding_invoice_amount
                              .value
                          )
                        : 0
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => <LabelLarge>{value}</LabelLarge>}
                  />

                  {/* <FormattedNumber
                      value={
                        customerDetails.total_outstanding_invoice_amount &&
                        customerDetails.total_outstanding_invoice_amount.value
                          ? customerDetails.total_outstanding_invoice_amount
                              .value
                          : 0
                      }
                      style="currency"
                      currency={
                        customerDetails.total_inv_amount &&
                        customerDetails.total_inv_amount.currency
                      }
                    /> */}
                  {/* </ParagraphLarge> */}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                paddingTop: "3px",
                flex: "1",
                flexDirection: "column",
              }}
            >
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <ParagraphMedium
                  $style={{ color: "#333860", whiteSpace: "nowrap" }}
                >
                  <FormattedMessage id="credit_limit">
                    credit_limit
                  </FormattedMessage>
                </ParagraphMedium>
                <div style={{ whiteSpace: "nowrap" }}>
                  {customerDetails?.credit_limit_status ? (
                    <>
                      {customerDetails &&
                      customerDetails?.credit_limit_breach_perc ? (
                        <>
                          <span
                            style={{
                              fontSize: "11px",
                              backgroundColor: `${
                                customerDetails?.credit_limit_status ===
                                "WARNING"
                                  ? "#FDF6EB"
                                  : customerDetails?.credit_limit_status ===
                                    "GOOD"
                                  ? "#D9FCE8"
                                  : "#FFD9D7"
                              }`,

                              // color: "#B79C15",
                              color: `${
                                customerDetails?.credit_limit_status ===
                                "WARNING"
                                  ? "#B79C15"
                                  : customerDetails?.credit_limit_status ===
                                    "GOOD"
                                  ? "#0FB158"
                                  : "#FD372A"
                              }`,
                              padding: "3px 5px",
                              borderRadius: "5px",
                            }}
                          >
                            {`${
                              customerDetails &&
                              customerDetails?.credit_limit_breach_perc
                            }${"%"} ${
                              customerDetails?.credit_limit_status
                                ? customerDetails?.credit_limit_status
                                : ""
                            }`}
                          </span>
                        </>
                      ) : (
                        <div></div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  // paddingTop: "5px",
                }}
              >
                {pen ? (
                  <div
                    style={{
                      width: "167px",
                      height: "24px",
                    }}
                  >
                    <TextBox
                      value={creditLimitData}
                      type="number"
                      onChange={(e) => {
                        setCreditLimitData(e.target.value);
                      }}
                      onBlur={(e) => {
                        // onBlur(e.value);
                        setPen(false);
                        onBlur();
                      }}
                      overrides={{
                        Root: {
                          style: ({ $theme, $isFocused, $error }) => ({
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderColor: $isFocused
                              ? $error
                                ? "#FD372A"
                                : "#516BEB"
                              : $error
                              ? "#FD372A"
                              : "#CDCED9",
                            backgroundColor: "#ffffff",
                            paddingRight: "0px",
                            padding: "0px",
                            height: "25px",
                          }),
                        },
                        Input: {
                          style: ({ $theme, $isFocused }) => ({
                            backgroundColor: "#ffffff",
                            "::placeholder": {
                              color: $isFocused ? "#cbd5e0" : "transparent",
                              transition: "color 0.4s ease",
                            },
                          }),
                        },
                      }}
                      autoFocus={focus}
                    />
                  </div>
                ) : (
                  <>
                    {!creditData ? (
                      <div
                        className="cp-card__overdue-total-inv-amt"
                        // style={{ height: "10px" }}
                        style={{
                          paddingTop: "0px",
                          marginBottom: "2px",
                          paddingLeft: "0px",
                        }}
                      >
                        --
                      </div>
                    ) : (
                      <>
                        <div
                          className="cp-card__overdue-total-inv-amt"
                          style={{ paddingTop: "0px", paddingLeft: "0px" }}
                        >
                          {/* <sup>{creditData?.currency}</sup> */}
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              !creditData ? "" : Number(creditData?.value)
                              // !creditLimitValue ? "" : Number(creditLimitValue)
                              // customerDetails &&
                              // customerDetails.credit_limit &&
                              // customerDetails.credit_limit?.value
                              //   ? Number(customerDetails.credit_limit?.value)
                              //   : 1
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => (
                              <ParagraphLarge style={{ fontWeight: "500" }}>
                                {value}
                              </ParagraphLarge>
                            )}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {pen ? (
                  <></>
                ) : (
                  <>
                    <RBACWrapper role={ASSIGN_CREDIT_LIMIT} type={"PERMISSION"}>
                      <div
                        ref={clickRef}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setPen(!pen);
                          setFocus(true);
                        }}
                      >
                        {/* <IconButton size={SIZE.mini} kind={KIND.tertiary}> */}
                        <div>
                          <i className="mx-icon-Vector-7" />
                        </div>
                        {/* </IconButton> */}
                      </div>
                    </RBACWrapper>
                  </>
                )}
              </div>
              {/* <ParagraphLarge $style={{ color: "#333860" }}>--</ParagraphLarge> */}
            </div>
          </div>

          <div className="cp-card__action__amount">
            <div>
              <div className="cp-card__total-due">
                <div className="cp-card__total-due-level--due"></div>
                <ParagraphMedium
                  $style={{ color: "#333860", marginRight: "10px" }}
                >
                  <FormattedMessage id="due">due</FormattedMessage>
                </ParagraphMedium>
                <div className="cp-card__pie-chart-inv-count">
                  <HeadingXSmall
                    $style={{ color: "#333860", lineHeight: "17px" }}
                  >
                    {customerDetails.due_inv_count}{" "}
                    <FormattedMessage id="inv">inv</FormattedMessage>
                  </HeadingXSmall>
                </div>
              </div>
              <div className="cp-card__overdue-total-inv-amt">
                {/* <sup>
                  {customerDetails.due_inv_amount &&
                    customerDetails.due_inv_amount.currency}
                </sup> */}
                <NumericFormat
                  // type="text"
                  displayType="text"
                  value={
                    customerDetails.due_inv_amount &&
                    customerDetails.due_inv_amount.value
                      ? Number(customerDetails.due_inv_amount.value)
                      : 0
                  }
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(value) => (
                    <ParagraphLarge style={{ fontWeight: "500" }}>
                      {value}
                    </ParagraphLarge>
                  )}
                />
                {/* <FormattedNumber
                    value={
                      customerDetails.due_inv_amount &&
                      customerDetails.due_inv_amount.value
                        ? customerDetails.due_inv_amount.value
                        : 0
                    }
                    style="currency"
                    currency={
                      customerDetails.due_inv_amount &&
                      customerDetails.due_inv_amount.currency
                    }
                  /> */}
              </div>
            </div>
            <div>
              <div className="cp-card__total-overdue">
                <div className="cp-card__total-due-level"></div>
                <ParagraphMedium
                  $style={{ color: "#333860", marginRight: "10px" }}
                >
                  <FormattedMessage id="OverDue">OverDue</FormattedMessage>
                </ParagraphMedium>
                <div className="cp-card__pie-chart-inv-count">
                  <HeadingXSmall
                    $style={{ color: "#333860", lineHeight: "17px" }}
                  >
                    {customerDetails.overdue_inv_count}{" "}
                    <FormattedMessage id="inv">inv</FormattedMessage>
                  </HeadingXSmall>
                </div>
              </div>
              <div className="cp-card__overdue-total-inv-amt">
                {/* <sup>
                  {customerDetails.overdue_inv_amount &&
                    customerDetails.overdue_inv_amount.currency}
                </sup> */}
                <NumericFormat
                  // type="text"
                  displayType="text"
                  value={
                    customerDetails.overdue_inv_amount &&
                    customerDetails.overdue_inv_amount.value
                      ? Number(customerDetails.overdue_inv_amount.value)
                      : 0
                  }
                  thousandsGroupStyle={currencyGroupStyle}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={currencySeparator}
                  decimalSeparator={currencyDecimalSeparator}
                  renderText={(value) => (
                    <ParagraphLarge style={{ fontWeight: "500" }}>
                      {value}
                    </ParagraphLarge>
                  )}
                />
                {/* <FormattedNumber
                    value={
                      customerDetails.overdue_inv_amount &&
                      customerDetails.overdue_inv_amount.value
                        ? customerDetails.overdue_inv_amount.value
                        : 0
                    }
                    style="currency"
                    currency={
                      customerDetails.overdue_inv_amount &&
                      customerDetails.overdue_inv_amount.currency
                    }
                  /> */}
              </div>
            </div>
            {/* {customerDetails &&
          customerDetails.total_unallocated_amount &&
          customerDetails.total_unallocated_amount.value > 0 ? ( */}
            <div className="cp-card__relation-action --modifier__border">
              <ParagraphMedium $style={{ color: "#333860" }}>
                <FormattedMessage id="unapplied_credit">
                  unapplied_credit
                </FormattedMessage>{" "}
                {customerDetails?.total_unallocated_amount?.value &&
                customerDetails?.total_unallocated_amount?.currency &&
                customerDetails?.total_unallocated_amount?.value > 0 ? (
                  <span style={{ marginLeft: "5px" }}>
                    <CardAlert />
                  </span>
                ) : (
                  " "
                )}
              </ParagraphMedium>
              <div
                style={{
                  width: "130px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: "12px",
                }}
              >
                {customerDetails?.total_unallocated_amount?.value &&
                customerDetails?.total_unallocated_amount?.currency ? (
                  <NumericFormat
                    // type="text"
                    displayType="text"
                    value={
                      customerDetails &&
                      customerDetails.total_unallocated_amount
                        ? Number(customerDetails.total_unallocated_amount.value)
                        : 0
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => (
                      <ParagraphLarge style={{ fontWeight: "500" }}>
                        {value}
                      </ParagraphLarge>
                    )}
                  />
                ) : (
                  "--"
                )}{" "}
                {/* <UparrowCredit /> */}
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              height: "40px",
              width: "55px",
              backgroundColor: "#333860",
              color: "#ffffff",
              // borderRadius: "5px",
              textAlign: "center",
              right: "0%",
              bottom: "0%",
              borderTopLeftRadius: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "10px",
              paddingBottom: "5px",
              paddingLeft: "7px",
            }}
          >
            {/* <div> */}
            <span style={{ color: "#ceded9", paddingRight: "3px" }}>
              <FormattedMessage id="in">in</FormattedMessage>{" "}
            </span>{" "}
            {customerDetails.default_currency}
            {/* </div> */}
          </div>
        </div>

        {/* left card with chart end here */}

        {/* top section right card start here */}

        <div className="cp_right__card__wraper">
          <div
            className="right__card__top_conatiner"
            style={{ padding: "0px 9px" }}
          >
            <CustomerActionStatus />
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <PromiseToPayCall width={18} height={18} color={"#333860"} />
                {inProgressPromiseToPay?.length === 0 ? (
                  <div
                    style={{
                      color: "#333860",
                      flex: "1",
                      // textAlign: "center",
                      // borderRight: "0.5px solid #c7c7c7",
                    }}
                  >
                    <HeadingXSmall>--</HeadingXSmall>
                  </div>
                ) : (
                  <>
                    <div
                      // style={{
                      //   display: "flex",
                      //   flex: "1",
                      //   cursor: "pointer",
                      //   // justifyContent: "center",
                      //   height: "55px",
                      // }}
                      onClick={() => {
                        dispatch(tabMotion(2));
                        dispatch(getActionData("review"));
                        dispatch(isOpenModal(true));
                      }}
                    >
                      {/* Promise Review ({inProgressPromiseToPay?.length})
                <PaymentDueInfo date={paymentReviewDate} /> */}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            paddinghRight: "10px",
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                          }}
                        >
                          <PaymentDueInfo date={paymentReviewDate} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Dispute color={"#333860"} width={18} height={18} />

                {notClosedDispute?.length === 0 ? (
                  <>
                    <div
                    // style={{
                    //   // paddingLeft: "10px",
                    //   color: "#333860",
                    //   flex: "1",
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   justifyContent: "center",
                    //   // borderRight: "0.5px solid #c7c7c7",
                    //   // textAlign: "center",
                    // }}
                    >
                      {/* <HeadingXSmall $style={{ color: "#ADADAD" }}>
                    <FormattedMessage id="dispute_review">
                      dispute_review
                    </FormattedMessage>
                  </HeadingXSmall> */}
                      <HeadingXSmall>--</HeadingXSmall>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flex: "1",
                        cursor: "pointer",

                        // justifyContent: "center",
                      }}
                      onClick={() => {
                        dispatch(tabMotion(2));
                        dispatch(getActionData("dispute"));
                        dispatch(isOpenModal(true));
                      }}
                    >
                      {/* <div style={{ color: "#ADADAD" }}>
                          <FormattedMessage id="dispute_review">
                        dispute_review
                      </FormattedMessage>{" "}
                          ({notClosedDispute?.length})
                        </div> */}

                      <DueInfo date={disputeReviewDate} />

                      {/* </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="right__card__bottom_conatiner">
            <CustomerDetailCardActions
              isQuickAction={isQuickAction}
              getParams={getParams}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              // gap
              // padding: "3px 0px",
              height: "25px",
              borderTop: "0.5px solid #CDCED9",
              background: "#F9F9F9",
              gap: "19px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                fontSize: "12px",
                gap: "3px",
                fontWeight: "400",
                color: "#ADADAD",
                alignItems: "center",
                padding: "5px 0px",
                // paddingBottom: "10px",
              }}
            >
              {CollectionAgencies ===
              currentDefaultFormatDetails?.industry_sector ? (
                <>
                  <FormattedMessage id="days_since_created">
                    days_since_created
                  </FormattedMessage>{" "}
                  :
                  <span
                    style={{
                      color: "#333860",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "15px",
                      fontSize: "12px",
                    }}
                  >
                    {customerDetails?.days_since_created == ""
                      ? "--"
                      : customerDetails && customerDetails?.days_since_created}
                  </span>
                </>
              ) : (
                <>
                  <FormattedMessage id="cus_max_delay_days">
                    cus_max_delay_days
                  </FormattedMessage>{" "}
                  :
                  <span
                    style={{
                      color: "#333860",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "15px",
                      fontSize: "12px",
                    }}
                  >
                    {customerDetails?.cus_max_delay !== ""
                      ? customerDetails && customerDetails?.cus_max_delay
                      : "--"}
                  </span>
                </>
              )}
            </div>
            <div
              style={{
                background: "#787878",
                width: "4.5px",
                height: "4.5px",
                borderRadius: "50%",
              }}
            >
              {" "}
            </div>

            <div
              style={{
                display: "flex",
                fontSize: "12px",
                gap: "3px",
                fontWeight: "400",
                color: "#ADADAD",
                padding: "5px 0px",
                alignItems: "center",
                // paddingBottom: "10px",
              }}
            >
              <FormattedMessage id="action_stage">
                action_stage
              </FormattedMessage>{" "}
              :
              <span
                style={{
                  color: "#333860",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "15px",
                  fontSize: "12px",
                }}
              >
                {customerDetails?.customer_auto_status ? (
                  <>
                    <FormattedMessage
                      id={customerDetails?.customer_auto_status}
                    >
                      {customerDetails?.customer_auto_status}
                    </FormattedMessage>
                  </>
                ) : (
                  "--"
                )}
              </span>
            </div>
            <div
              style={{
                background: "#787878",
                width: "4.5px",
                height: "4.5px",
                borderRadius: "50%",
              }}
            >
              {" "}
            </div>

            <div
              style={{
                display: "flex",
                fontSize: "12px",
                gap: "3px",
                fontWeight: "400",
                color: "#ADADAD",
                alignItems: "center",
                padding: "5px 0px",
                // paddingBottom: "10px",
              }}
            >
              <FormattedMessage id="last_customer_action">
                last_customer_action
              </FormattedMessage>{" "}
              :{/* {last_action_name && last_action_date ? ( */}
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <span
                  style={{
                    color: "#333860",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "15px",
                    fontSize: "12px",
                  }}
                >
                  {customerDetails?.last_customer_action ? (
                    <FormattedMessage id={customerDetails.last_customer_action}>
                      {customerDetails.last_customer_action}
                    </FormattedMessage>
                  ) : (
                    "--"
                  )}
                </span>
                <span className="last_customer_action-card-date">
                  {customerDetails && customerDetails?.last_customer_action_date
                    ? moment(customerDetails?.last_customer_action_date).format(
                        currentDefaultFormatDetails?.date_format
                      )
                    : "-"}
                </span>
              </div>
              {/* ) : (
                " "
              )} */}
            </div>
          </div>
        </div>
        {/* top section right card end here */}
      </div>

      {/* <div className="cp-card__action_wraper">
        <div className="cp-card__customer_action">
          <div style={{ textAlign: "end" }}>
            <CustomerPortalLink />
          </div>
        </div>
        
      </div> */}
    </>
  );
};

const CustomerDetailCardActions = ({ getParams, isQuickAction }) => {
  const queryClient = useQueryClient();

  const { id } = useParams();
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const dispatch = useDispatch();
  const [relManager, setRelManager] = useState([]);
  const [isCount, setIsCount] = useState(true);
  const [customerStatus, setCustomerStatus] = useState(false);
  const [businessStatus, setBusinessStatus] = useState(false);
  const [businessData, setBusinessData] = useState({});
  const [customerStatusData, setCustomerStatusData] = useState({});

  let maxyfiContextValue = useContext(MaxyfiContext);

  let {
    currentOrganization,
    users,
    referenceData,
    restrictions,
    space,
    refetch,
  } = maxyfiContextValue;

  let findingUser = users?.filter((i) =>
    i.organization?.includes(currentOrganization)
  );

  useEffect(() => {
    setRelManager(setSelectValues(customerDetails?.rel_manager));
  }, [customerDetails]);

  // const workFlowCount = useSelector(
  //   (s) => s.customerOverviewReducer.smsGateWay.workflow_asigned_customer
  // );

  const { refetchActions } = useSelector((e) => e.customerOverviewReducer);

  // useEffect(async () => {
  //   await getOrganization({ organization: currentOrganization }).then((res) => {
  //     setSettingData(res.data.doc || {});
  //     // rdRefetch({ ...maxyfiContextValue, setRdLoader: true });
  //   });
  //   //TODO: PRAVEEN FAILURE ERROR HANDLING
  // }, [refetchActions]);

  useEffect(() => {
    if (
      space &&
      space.workflow_asigned_customer >= restrictions &&
      restrictions?.MAXAUTOCUST
    ) {
      setIsCount(false);
    }
  }, [space]);

  const { handleModal } = useContext(ModalContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,

    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const putRel = useMutation(
    (data) =>
      putRelData({
        ...data,
        customerId: getParams,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        // reset();
        // dispatch(closePaymentModal());
        queryClient.invalidateQueries(
          `${CUSTOMER_OVERVIEW}-${currentOrganization}`
        );
      },
    }
  );

  //   useEffect(() => {
  //   const fetchOrganization = async () => {
  //     await getOrganization({ organization: currentOrganization }).then(
  //       (res) => {
  //         setOrganizationDetails(res.data.doc || {});
  //       }
  //     );
  //   };

  //   fetchOrganization();
  // }, []);

  const onChange = async (data) => {
    setRelManager(data);
    await putRel.mutateAsync({ relManager: getSelectValues(data) });
  };

  const unassignWorkflowMutation = useMutation(
    () =>
      assignWorkflow({
        customerId: getParams,
        organization: currentOrganization,
        action: "UNASSIGN",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        setTimeout(() => {
          // dispatch(refetchActions());
          dispatch(unAssignWorkflow());
        }, 5000);
        // dispatch(refetchActions());
      },
    }
  );

  const businessUnitData = referenceData["business_unit_list"]?.find(
    (e) => e?.id === customerDetails?.business_unit
  );
  const businessLocation = referenceData["business_location_list"]?.find(
    (e) => e?.id === customerDetails?.business_location
  );

  const customerStatusList = referenceData["customer_status_list"]?.find(
    (e) => e?.id === customerDetails?.customer_status
  );

  let customerOneStatus = customerDetails?.customer_status?.split("(");
  let customerTwoStatus = customerOneStatus && customerOneStatus[1]?.split(")");

  const rmPermission = space && space.role_permissions?.includes(ASSIGN_RM);

  return (
    <div className="customer-profile__card-due-content --card__modifier">
      {/* <div
        // className="cp-card__actions"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: "10px",
          flexWrap: "wrap",
          height: "82px",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      > */}
      {/* <RBACWrapper role={ASSIGN_RM} type={"PERMISSION"}> */}
      <div className="cp-card__customer-previous-status-overview-bottom">
        <div
          style={{
            width: "150px",
            // flex: "5",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "8px",
          }}
        >
          {/* <HeadingXSmall $style={{ color: "#ADADAD", flex: "1" }}>
              <FormattedMessage id="relation_manager">
                relation_manager
              </FormattedMessage>
            </HeadingXSmall> */}
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              flex: "1.3",
            }}
          >
            <RelationManagerSVG width={22} height={22} />
            <Select
              clearable={false}
              // size={SIZE.compact}
              value={relManager}
              onChange={(e) => {
                onChange(e.value);
              }}
              disabled={!rmPermission ? true : false}
              // options={users.map((i) => {
              //   return { label: i.displayName, id: i.id };
              // })}
              options={findingUser.map((i) => {
                return { label: i.displayName, id: i.id };
              })}
              overrides={{
                ControlContainer: {
                  style: ({ $theme }) => ({
                    outline: "0px",
                    backgroundColor: "#ffffff00",
                    borderRight: "0px",
                    borderBottom: "0px",
                    borderLeft: "0px",
                    borderTop: "0px",
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }),
                },
                ValueContainer: {
                  style: ({ $theme }) => ({
                    paddingLeft: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }),
                },

                SelectArrow: () => (
                  <div>
                    <i className="mx-icon-Vector-7" />
                  </div>
                ),
              }}
            />
          </div>
          <div className="cp-card__workflow-edit">
            <div className="cp-card__workflow-edit-text">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <WorkFlowSVG color="#333860" width={16} height={16} />
                <StatefulTooltip
                  content={() => (
                    <Block padding={"5px"}>
                      <LabelMedium $style={{ color: "#ffffff" }}>
                        {customerDetails.workflow &&
                        customerDetails.workflow.name ? (
                          customerDetails.workflow.name
                        ) : (
                          <FormattedMessage id="assign_workFlow">
                            assign_workFlow
                          </FormattedMessage>
                        )}
                      </LabelMedium>
                    </Block>
                  )}
                  returnFocus
                  autoFocus
                >
                  <ParagraphMedium>
                    {customerDetails.workflow &&
                    customerDetails.workflow.name ? (
                      customerDetails.workflow.name
                    ) : (
                      <FormattedMessage id="assign_workFlow">
                        assign_workFlow
                      </FormattedMessage>
                    )}
                  </ParagraphMedium>
                </StatefulTooltip>
              </div>
            </div>
            <div className="cp-card__workflow-edit-icon">
              <CpWorkflowModal />

              {/* <RBACWrapper
                  type="LICENSE"
                  enabled={isCount}
                  placement="bottom"
                > */}
              <StatefulTooltip
                content={() =>
                  isQuickAction ? null : (
                    <Block padding={"5px"}>
                      <LabelMedium $style={{ color: "#ffffff" }}>
                        <FormattedMessage id="workFlow_on_hold">
                          workFlow_on_hold
                        </FormattedMessage>{" "}
                      </LabelMedium>
                    </Block>
                  )
                }
                returnFocus
                autoFocus
              >
                <RBACWrapper role={ASSIGN_WORKFLOW} type="PERMISSION">
                  <IconButton
                    onClick={() => dispatch(workflowAddModal(true))}
                    kind={KIND.tertiary}
                    type="button"
                    disabled={isQuickAction ? false : true}
                    size={SIZE.mini}
                  >
                    <ActionEdit width={18} height={18} />
                  </IconButton>
                </RBACWrapper>
              </StatefulTooltip>
              {/* </RBACWrapper> */}
              <RBACWrapper role={ASSIGN_WORKFLOW} type="PERMISSION">
                {customerDetails.workflow && customerDetails.workflow.name ? (
                  <IconButton
                    onClick={() => {
                      handleModal({
                        title: "You are about to Unassign a Workflow",
                        content:
                          "This will Unassign your Workflow. Are you sure?",
                        successCallback: async () => {
                          await unassignWorkflowMutation.mutateAsync();
                        },
                        formContent: () => {
                          return <></>;
                        },
                        cancelCallback: () => {},
                        isChildren: false,
                        image: WarningImage,
                        buttonText: "Unassign",
                        closeButtonText: "Cancel",
                        isCloseAble: false,
                      });
                    }}
                    kind={KIND.tertiary}
                    type="button"
                    isLoading={unassignWorkflowMutation.isLoading}
                    size={SIZE.mini}
                  >
                    <ActionRemove />
                    {/* <Edit /> */}
                  </IconButton>
                ) : (
                  <></>
                )}
              </RBACWrapper>
            </div>

            {/* <div
                style={{
                  color: "#516beb",
                  textDecoration: "underline",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(subscriptionsDrawer(true));
                }}
              >
                2 Subscriptions
              </div> */}
          </div>
        </div>
      </div>
      {/* </RBACWrapper> */}
      {/* <RBACWrapper role={ASSIGN_WORKFLOW} type={"PERMISSION"}> */}
      {/* <div className="cp-card__workflow-action" style={{ flex: "1" }}>
          <div
            style={{
              // width: "170px",
              // width: "150px",

              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <HeadingXSmall $style={{ color: "#ADADAD", flex: "1" }}>
              <FormattedMessage id="workFlow">workFlow</FormattedMessage>
            </HeadingXSmall>
            <div className="cp-card__workflow-edit">
              <div className="cp-card__workflow-edit-text">
                <StatefulTooltip
                  content={() => (
                    <Block padding={"5px"}>
                      <LabelMedium $style={{ color: "#ffffff" }}>
                        {customerDetails.workflow &&
                        customerDetails.workflow.name ? (
                          customerDetails.workflow.name
                        ) : (
                          <FormattedMessage id="assign_workFlow">
                            assign_workFlow
                          </FormattedMessage>
                        )}
                      </LabelMedium>
                    </Block>
                  )}
                  returnFocus
                  autoFocus
                >
                  <ParagraphMedium>
                    {customerDetails.workflow &&
                    customerDetails.workflow.name ? (
                      customerDetails.workflow.name
                    ) : (
                      <FormattedMessage id="assign_workFlow">
                        assign_workFlow
                      </FormattedMessage>
                    )}
                  </ParagraphMedium>
                </StatefulTooltip>
              </div>
              <div className="cp-card__workflow-edit-icon">
                <CpWorkflowModal />

          
                <StatefulTooltip
                  content={() =>
                    isQuickAction ? null : (
                      <Block padding={"5px"}>
                        <LabelMedium $style={{ color: "#ffffff" }}>
                          <FormattedMessage id="workFlow_on_hold">
                            workFlow_on_hold
                          </FormattedMessage>{" "}
                        </LabelMedium>
                      </Block>
                    )
                  }
                  returnFocus
                  autoFocus
                >
                  <RBACWrapper role={ASSIGN_WORKFLOW} type="PERMISSION">
                    <IconButton
                      onClick={() => dispatch(workflowAddModal(true))}
                      kind={KIND.tertiary}
                      type="button"
                      disabled={isQuickAction ? false : true}
                      size={SIZE.mini}
                    >
                      <ActionEdit />
                    </IconButton>
                  </RBACWrapper>
                </StatefulTooltip>
                <RBACWrapper role={ASSIGN_WORKFLOW} type="PERMISSION">
                  {customerDetails.workflow && customerDetails.workflow.name ? (
                    <IconButton
                      onClick={() => {
                        handleModal({
                          title: "You are about to Unassign a Workflow",
                          content:
                            "This will Unassign your Workflow. Are you sure?",
                          successCallback: async () => {
                            await unassignWorkflowMutation.mutateAsync();
                          },
                          formContent: () => {
                            return <></>;
                          },
                          cancelCallback: () => {},
                          isChildren: false,
                          image: WarningImage,
                          buttonText: "Unassign",
                          closeButtonText: "Cancel",
                          isCloseAble: false,
                        });
                      }}
                      kind={KIND.tertiary}
                      type="button"
                      isLoading={unassignWorkflowMutation.isLoading}
                      size={SIZE.mini}
                    >
                      <ActionRemove />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </RBACWrapper>
              </div>

         
            </div>
          </div>
        </div> */}
      {/* </RBACWrapper> */}
      {/* BUSINESS UNIT */}
      <div className="cp-card__workflow-action-overview">
        <div
          style={{
            // width: "170px",
            // width: "150px",

            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            // gap: "5px",
          }}
        >
          {/* <HeadingXSmall $style={{ color: "#ADADAD", flex: "1" }}> */}
          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#ADADAD",
              paddingTop: "5px",
              // paddingBottom: "10px",
            }}
          >
            <FormattedMessage id="Business_unit_location">
              Business_unit_location
            </FormattedMessage>
          </div>
          {/* </HeadingXSmall> */}
          <div className="cp-card__workflow-edit">
            <div
              className="cp-card__workflow-edit-text"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <StatefulTooltip
                content={() => (
                  <Block padding={"5px"}>
                    <LabelMedium $style={{ color: "#ffffff" }}>
                      {businessUnitData?.label ? businessUnitData?.label : ""}
                      {/* customer status */}
                    </LabelMedium>
                  </Block>
                )}
                returnFocus
                autoFocus
              >
                <ParagraphMedium>
                  {/* {customerDetails.workflow && customerDetails.workflow.name
                      ? customerDetails.workflow.name
                      : "Assign Workflow"} */}
                  {/* Business status */}
                  {businessUnitData?.label ? businessUnitData?.label : ""}
                </ParagraphMedium>
              </StatefulTooltip>
              <StatefulTooltip
                content={() => (
                  <Block padding={"5px"}>
                    <LabelMedium $style={{ color: "#ffffff" }}>
                      {businessLocation?.label ? businessLocation?.label : ""}
                      {/* customer status */}
                    </LabelMedium>
                  </Block>
                )}
                returnFocus
                autoFocus
              >
                <ParagraphMedium>
                  {/* {customerDetails.workflow && customerDetails.workflow.name
                      ? customerDetails.workflow.name
                      : "Assign Workflow"} */}
                  {/* Business status */}
                  {businessLocation?.label ? businessLocation?.label : ""}
                </ParagraphMedium>
              </StatefulTooltip>
            </div>
            <div className="cp-card__workflow-edit-icon">
              {/* CUSTOMER STATUS MODAL */}
              <BusinessStatus
                businessStatus={businessStatus}
                setBusinessStatus={setBusinessStatus}
                service={putBusinessStatus}
                currentOrganization={currentOrganization}
                getParams={getParams}
                businessData={businessData}
                // businessUnitData={customerDetails?.business_unit}
                // businessUnitLocation={customerDetails?.business_location}
              />
              {/* CUSTOMER STATUS MODAL */}
              {/* <StatefulTooltip
                  content={() =>
                    isQuickAction ? null : (
                      <Block padding={"5px"}>
                        <LabelMedium $style={{ color: "#ffffff" }}>
                          WorkFlow on Hold{" "}
                        </LabelMedium>
                      </Block>
                    )
                  }
                  returnFocus
                  autoFocus
                > */}

              <RBACWrapper role={ASSIGN_BUSSINESS_LOCATION} type={"PERMISSION"}>
                <IconButton
                  onClick={() => {
                    setBusinessData({
                      businessUnit: customerDetails?.business_unit,
                      businessLocation: customerDetails?.business_location,
                    });
                    setBusinessStatus(true);
                  }}
                  kind={KIND.tertiary}
                  type="button"
                  size={SIZE.mini}
                  // disabled={isQuickAction ? false : true}
                >
                  <ActionEdit />
                </IconButton>
              </RBACWrapper>

              {/* </StatefulTooltip> */}
            </div>
          </div>
        </div>
      </div>
      {/* CUSTOMER STATUS */}
      <div className="cp-card__workflow-action-overview">
        <div
          style={{
            // width: "150px",

            // width: "170px",
            display: "flex",
            flexDirection: "column",
            // gap: "5px",
          }}
        >
          {/* <HeadingXSmall $style={{ color: "#ADADAD", flex: "1" }}>
              <FormattedMessage >
                Customer_Status
              </FormattedMessage>
            </HeadingXSmall> */}

          <div
            style={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#ADADAD",
              paddingTop: "5px",
              // paddingBottom: "10px",
            }}
          >
            <FormattedMessage id="customer_status">
              Customer_Status
            </FormattedMessage>
          </div>
          <div className="cp-card__workflow-edit">
            <div
              className="cp-card__workflow-edit-text"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <StatefulTooltip
                content={() => (
                  <Block padding={"5px"}>
                    <LabelMedium $style={{ color: "#ffffff" }}>
                      {customerTwoStatus && customerTwoStatus[0] === "AUTO"
                        ? customerDetails && customerDetails.customer_status
                        : customerStatusList?.label}
                    </LabelMedium>
                  </Block>
                )}
                returnFocus
                autoFocus
              >
                <ParagraphMedium $style={{ lineHeight: "20px" }}>
                  {customerTwoStatus && customerTwoStatus[0] === "AUTO"
                    ? customerDetails && customerDetails.customer_status
                    : customerStatusList?.label}
                </ParagraphMedium>
              </StatefulTooltip>
              <StatefulTooltip
                content={() => (
                  <Block padding={"5px"}>
                    <LabelMedium $style={{ color: "#ffffff" }}>
                      {customerDetails && customerDetails?.customer_comment
                        ? customerDetails?.customer_comment
                        : ""}
                    </LabelMedium>
                  </Block>
                )}
                returnFocus
                autoFocus
              >
                <ParagraphMedium>
                  {customerDetails && customerDetails?.customer_comment
                    ? customerDetails?.customer_comment
                    : ""}
                </ParagraphMedium>
              </StatefulTooltip>
            </div>
            <div className="cp-card__workflow-edit-icon">
              {/* CUSTOMER STATUS MODAL */}
              <CustomerStatusModal
                customerStatus={customerStatus}
                setCustomerStatus={setCustomerStatus}
                service={putCustomerStatus}
                currentOrganization={currentOrganization}
                getParams={getParams}
                customerStatusData={customerStatusData}
              />
              {/* CUSTOMER STATUS MODAL */}
              {/* <StatefulTooltip
                  content={() =>
                    isQuickAction ? null : (
                      <Block padding={"5px"}>
                        <LabelMedium $style={{ color: "#ffffff" }}>
                          WorkFlow on Hold{" "}
                        </LabelMedium>
                      </Block>
                    )
                  }
                  returnFocus
                  autoFocus
                > */}
              <RBACWrapper role={ASSIGN_STATUS} type={"PERMISSION"}>
                <IconButton
                  onClick={() => {
                    setCustomerStatus(true);
                    setCustomerStatusData({
                      customerStatus: customerDetails?.customer_status,
                      customerStatusComment: customerDetails?.customer_comment,
                    });
                    //
                    // refetch({ value: maxyfiContextValue, setRdLoader: true });
                  }}
                  kind={KIND.tertiary}
                  type="button"
                  size={SIZE.mini}
                  // disabled={isQuickAction ? false : true}
                >
                  <ActionEdit />
                </IconButton>
              </RBACWrapper>

              {/* </StatefulTooltip> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

const CustomerPortalLink = () => {
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );
  // const NEW_WINDOW =  {customerDetails customerDetails.customer_portal_url
  //   ? customerDetails.customer_portal_url
  //   : ""}
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const notify = () => toast.success("Linked Copied Successfully");
  return (
    <div
      style={{
        padding: "0px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        // gap: "10px",
        justifyContent: "end",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            color: "#516BEB",
            textDecoration: "underline",
            cursor: "pointer",
            width: "150px",
            // overflow: "hidden",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          onClick={() =>
            openInNewTab(
              `${
                customerDetails && customerDetails.customer_portal_url
                  ? customerDetails.customer_portal_url
                  : ""
              }`
            )
          }
        >
          {customerDetails && customerDetails.customer_portal_url
            ? customerDetails.customer_portal_url
            : ""}
        </div>
        <CopyToClipboard
          text={
            customerDetails && customerDetails.customer_portal_url
              ? customerDetails.customer_portal_url
              : ""
          }
          // onCopy={() => setCopied(true)}
        >
          <IconButton
            kind={KIND.tertiary}
            onClick={(e) => {
              e.preventDefault();
              notify();
            }}
          >
            <ClipBoard />
          </IconButton>
        </CopyToClipboard>
      </div>
    </div>
  );
};

const LastActionTypes = ({ type }) => {
  switch (type) {
    case "call":
      return <CallSvg />;

    case "sms":
      return <MessageSvg />;

    case "dispute":
      return <DisputeSvg />;

    case "email":
      return <EmailSvgN />;

    case "promise":
      return <PromiseToPayN />;

    case "review_promise":
      return <PromiseToPayN />;

    case "review_dispute":
      return <DisputeSvg />;

    case "NEXT_ACTION":
      return <NextActionForward />;

    case "whatsapp":
      return <WhatsAppIcon />;

    default:
      return null;
  }
};

const ActionTypes = ({ type }) => {
  switch (type) {
    case "call":
      return <CallSvg />;

    case "sms":
      return <SmsSvgN />;

    case "dispute":
      return <DisputeSvg />;

    case "email":
      return <EmailSvgN />;

    case "promise":
      return <PromiseToPayN />;

    case "review_promise":
      return <ReviewPromiseSvg />;

    case "review_dispute":
      return <DisputeSvg />;
    case "whatsapp":
      return <WhatsAppIcon />;

    default:
      return null;
  }
};

const CustomerActionStatus = () => {
  const {
    last_action_name,
    last_action_date,
    active_next_action_name,
    next_action_date,
    next_action_type,
    last_action_type,
    active_next_action_type,
    active_next_action_date,
  } = useSelector((state) => state.customerOverviewReducer.customerDetails);

  let { currentOrganization, referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let isShowNextAction = false;

  if (active_next_action_name && active_next_action_date) {
    isShowNextAction = true;
  }

  let lastActionFinalValues = moment
    .utc(last_action_date)
    .diff(moment().utc(), "days", true);
  lastActionFinalValues = Number(lastActionFinalValues).toFixed();

  let lastActionDaysLeftMessage = "";
  if (Math.abs(lastActionFinalValues) <= 0) {
    lastActionDaysLeftMessage = "(Today)";
  } else {
    lastActionDaysLeftMessage = `(${Math.abs(lastActionFinalValues)} days ago)`;
  }

  let NextActionDaysDiff = () => {
    let daysLeft = isShowNextAction
      ? moment
          ?.utc(active_next_action_date)
          ?.tz(timeZone)
          ?.diff(moment.tz(timeZone), "days", true)
      : 0;

    daysLeft = Math.ceil(daysLeft);

    if (daysLeft == 0) {
      return (
        <span
          style={{
            color: "#FD372A",
            fontSize: "11px",
            fontWeight: "400",
            backgroundColor: "#FFF5F4",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          Due Today
        </span>
      );
    } else if (daysLeft > 0) {
      return (
        <span style={{ color: "#ADADAD", fontSize: "11px", fontWeight: "400" }}>
          (In {daysLeft} day{daysLeft > 1 ? "s" : ""})
        </span>
      );
    } else {
      return (
        <span
          style={{
            color: "#FD372A",
            fontSize: "11px",
            fontWeight: "400",
            backgroundColor: "#FFF5F4",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          OverDue
        </span>
      );
    }
  };

  return (
    <>
      <div className="cp-card__customer-previous-status-overview">
        <div className="cp-card__customer-previous-status-icn">
          {/* <Tick /> */}
          <div
            className="cp-card__customer-previous-status-header"
            style={{ whiteSpace: "nowrap", paddingRight: "10px" }}
          >
            <HeadingXSmall $style={{ color: "#ADADAD", textAlign: "center" }}>
              <FormattedMessage id="last_action">last_action</FormattedMessage>

              <StatefulTooltip
                content={() => (
                  <Block padding={"5px"}>
                    <LabelMedium $style={{ color: "#ffffff" }}>
                      {last_action_name && last_action_date
                        ? last_action_name &&
                          moment
                            .utc(last_action_date)
                            .format(currentDefaultFormatDetails.date_format)
                        : ""}
                      {/* customer status */}
                    </LabelMedium>
                  </Block>
                )}
                returnFocus
                autoFocus
              >
                {last_action_name && last_action_date ? (
                  <span className="cp-card__customer-previous-status-days">
                    <span
                      style={{
                        color: "#ADADAD",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {lastActionDaysLeftMessage}{" "}
                    </span>
                    {/* {lastActionDays} days */}
                  </span>
                ) : (
                  " "
                )}
              </StatefulTooltip>
            </HeadingXSmall>
          </div>
        </div>
        <div className="cp-card__customer-previous-status-content">
          <div className="cp-card__customer-previous-status-letter --next_action">
            {last_action_name && last_action_date ? (
              <>
                <LastActionTypes type={last_action_type} />

                <ParagraphMedium
                  $style={{
                    whiteSpace: "nowrap",
                    maxWidth: "155px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {last_action_name}
                </ParagraphMedium>
              </>
            ) : (
              "--"
            )}
          </div>
          {/* <div className="cp-card__customer-status-content-icn">
              <ParagraphMedium
                $style={{ marginLeft: "5px", paddingTop: "3px" }}
              >
                <FormattedDate value={last_action_date} />
              </ParagraphMedium>
            </div> */}
        </div>
      </div>

      <div className="cp-card__customer-previous-status-overview --center">
        <div
          className="cp-card__customer-next-status-icn-center"
          style={{ whiteSpace: "nowrap", alignItems: "center" }}
        >
          {/* <Error /> */}
          <HeadingXSmall $style={{ color: "#adadad", textAlign: "center" }}>
            <FormattedMessage id="next_action">next_action</FormattedMessage>

            {isShowNextAction ? (
              <span className="cp-card__customer-next-status-due">
                <NextActionDaysDiff />
              </span>
            ) : (
              " "
            )}
          </HeadingXSmall>
        </div>
        <div className="cp-card__customer-next-status-content cp-card__customer-next-status-content-text ">
          <StatefulTooltip
            content={() => (
              <Block padding={"5px"}>
                <LabelMedium $style={{ color: "#ffffff" }}>
                  {isShowNextAction ? (
                    <div className="cp-card__customer-next-status-letter --next_action">
                      <>
                        <ParagraphMedium>
                          {active_next_action_name}
                        </ParagraphMedium>
                      </>
                    </div>
                  ) : (
                    "--"
                  )}
                </LabelMedium>
              </Block>
            )}
            returnFocus
            autoFocus
          >
            <ParagraphMedium>
              {isShowNextAction ? (
                <div className="cp-card__customer-next-status-letter --next_action">
                  <>
                    <ActionTypes type={active_next_action_type} />
                    <ParagraphMedium>{active_next_action_name}</ParagraphMedium>
                  </>
                </div>
              ) : (
                "--"
              )}
            </ParagraphMedium>
          </StatefulTooltip>

          {/* <div className="cp-card__customer-status-content-icn">
              <ParagraphMedium
                $style={{ marginLeft: "5px", paddingTop: "3px" }}
              >
                <FormattedDate
                  value={moment
                    .utc(next_action_date)
                    .tz(timeZone)
                    .format("MM/DD/YYYY")}
                />
              </ParagraphMedium>
            </div> */}
        </div>
      </div>
    </>
  );
};

const CustomerTags = () => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      tag_val: "",
    },
  });

  let { currentOrganization } = useContext(MaxyfiContext);

  const { id } = useParams();

  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  // const [value, setValue] = React.useState("");
  const [plus, setPlus] = useState(false);

  const [tagsData, setTagsData] = useState(customerDetails?.tags);

  //
  //

  const [removedData, setRemovedData] = useState(null);

  // const allData = tagsData.splice(removedData,1)

  const putTag = useMutation(
    (data) =>
      putTagsData({
        ...data,
        customerId: id,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        // reset();
        // dispatch(closePaymentModal());
      },
    }
  );

  //   const onSubmit = (data, e) => {
  //     const temTagData=[...tagsData];
  // //   temTagData.splice(ind,1)
  //   temTagData.push({name:data.tag_val})
  //   setTagsData(temTagData)
  //   setPlus(false)
  //   };

  const onSubmit = async (data) => {
    let values = {
      add: data.tag_val,
    };

    await putTag.mutateAsync({ ...values });
  };

  const onDelete = async (data) => {
    let values = {
      remove: data,
    };

    await putTag.mutateAsync({ ...values });
  };

  const dispatch = useDispatch();

  return (
    <div className="customer-profile__card-due-content">
      <div className="cp-card__tags">
        <Accordion
          onChange={({ expanded }) => expanded}
          accordion
          overrides={{
            Content: {
              style: ({ $theme }) => ({
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
                borderBottomWidth: "0px",
              }),
            },
            Header: {
              style: ({ $theme }) => ({
                paddingRight: "0px",
                paddingLeft: "0px",
                borderBottomWidth: "0px",
              }),
            },
            PanelContainer: {
              style: ({ $theme }) => ({
                borderBottomWidth: "0px",
              }),
            },
            ToggleIcon: ({ $expanded }) => {
              return $expanded ? <UpArrow /> : <DownArrow />;
            },
          }}
        >
          <Panel
            title={
              <div className="cp-card__tag-holder">
                <LabelLarge $style={{ marginRight: "10px" }}>
                  <FormattedMessage id="Tags">Tags</FormattedMessage>
                </LabelLarge>
                <div className="cp-total-tag">
                  <LabelLarge $style={{ color: "#516BEB" }}>
                    {/* {data && data.tags ? data.tags.length : 0}
                    {data.contacts.length} */}
                    {customerDetails.tags.length}
                  </LabelLarge>{" "}
                </div>
              </div>
            }
          >
            {/* {data.tags.map((i) => (
          <Tag
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  height: "30px",
                }),
              },
            }}
          >
            {i}
          </Tag>
        ))} */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {customerDetails?.tags.map((i, ind) => (
                <Tag
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        borderBottomLeftRadius: "5px",
                        borderBottomRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        height: "30px",
                        backgroundColor: "#EEEEEE",
                        border: "0px solid #000",
                        color: "#333860",
                      }),
                    },

                    Action: (props) => {
                      return (
                        <div>
                          {props.$isclosable ? (
                            <div></div>
                          ) : (
                            <div
                              style={{ paddingLeft: "5px", cursor: "pointer" }}
                              onClick={() => {
                                onDelete(i);
                                dispatch(tagDelete(i));
                                //const temTagData=[...tagsData];
                                // temTagData.splice(ind,1)
                                // setTagsData(temTagData)
                              }}
                            >
                              <TagExit />
                            </div>
                          )}
                        </div>
                      );
                    },
                  }}
                  // onClick={()=>{const temTagData=[...tagsData];
                  //   temTagData.splice(ind,1)
                  //   setTagsData(temTagData)
                  // }}
                >
                  {i}
                </Tag>
              ))}

              {plus === false && tagsData.length != 0 ? (
                <>
                  <span
                    style={{ cursor: "pointer", paddingTop: "5px" }}
                    onClick={() => {
                      //   const tempData=[...tagsData];
                      // tempData.push({id:7,name:"Seven"})
                      // setTagsData(tempData)
                      setPlus(true);
                    }}
                  >
                    <TagsAdd />
                  </span>
                </>
              ) : (
                <>
                  {/* {tagsData.map((i,ind) => ( */}

                  <Tag
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          borderBottomLeftRadius: "5px",
                          borderBottomRightRadius: "5px",
                          borderTopLeftRadius: "5px",
                          borderTopRightRadius: "5px",
                          height: "30px",
                          backgroundColor: "#EEEEEE",
                          border: "0px solid #000",
                          color: "#333860",
                        }),
                      },

                      Action: (props) => {
                        return (
                          <div>
                            {props.$isclosable ? (
                              <div>op</div>
                            ) : (
                              <div
                                style={{
                                  paddingLeft: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setPlus(false);
                                }}
                              >
                                <TagExit />
                              </div>
                            )}
                          </div>
                        );
                      },
                    }}
                    // onClick={()=>{const temTagData=[...tagsData];
                    //   temTagData.splice(ind,1)
                    //   setTagsData(temTagData)
                    // }}
                  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Controller
                        name="tag_val"
                        control={control}
                        render={({ field }) => (
                          // <TextBox
                          // overrides={{
                          //   Root: {
                          //     style: ({ $theme,$isFocused }) => ({
                          //       backgroundColor: $theme.colors.warning200,
                          //       height:"30px",
                          //     borderLeft:"0px",
                          //     borderRight:"0px",
                          //     borderTop:"0px",
                          //     borderBottom:"0px",

                          //     })
                          //   },
                          // }}

                          //   {...field}
                          //   name={field.name}
                          //   error={
                          //     errors[field.name] && errors[field.name].message
                          //   }
                          //   // label={intl.formatMessage({
                          //   //   id: "action_name",
                          //   // })}
                          //   // placeholder={intl.formatMessage({
                          //   //   id: "action_name",
                          //   //   // kind: "teritary",
                          //   // })}
                          //   value={field.value}
                          // />
                          <Input
                            {...field}
                            name={field.name}
                            placeholder="Type here"
                            overrides={{
                              Root: {
                                style: ({ $theme, $isFocused }) => ({
                                  backgroundColor: $theme.colors.warning200,
                                  height: "30px",
                                  borderLeft: "0px",
                                  borderRight: "0px",
                                  borderTop: "0px",
                                  borderBottom: "0px",
                                  width: "120px",
                                }),
                              },
                              Input: {
                                style: ({ $theme, $isFocused }) => ({
                                  backgroundColor: $isFocused
                                    ? "#EEEEEE"
                                    : "#EEEEEE",
                                }),
                              },
                            }}
                          />
                        )}
                      />
                    </form>
                  </Tag>
                </>
              )}
            </div>
          </Panel>
        </Accordion>
      </div>
    </div>
  );
};

const CustomerOverviewCard = ({ getParams }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  let { currentOrganization, referenceData } = useContext(MaxyfiContext);
  const { isLoading, customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const [isQuickAction, setIsQuickAction] = useState("");
  const refetchActions = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  const unAssignWorkflow = useSelector(
    (s) => s.customerOverviewReducer.unAssign
  );

  useEffect(() => {
    const fetchCustomer = async () => {
      await getCustomerDetails({
        customerId: getParams,
        organization: currentOrganization,
      })
        .then((res) => {
          dispatch(getCustomerDetailsAction(res.data.doc));
          dispatch(activeWorkflowData(res.data.doc.workflow));
          setIsQuickAction(res.data.doc.is_quick_action);
        })
        .catch((err) => {
          if (err.response) {
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
          } else {
            // anything else
          }
        });
    };

    fetchCustomer();
  }, [refetchActions, unAssignWorkflow, getParams]);

  // Customer Actions

  // workflow action api is removed 12-10-23

  // useEffect(() => {
  //   const CustomerActions = async () => {
  //     await getCustomerActions({
  //       customerId: getParams,
  //       organization: currentOrganization,
  //     })
  //       .then((res) => {
  //         dispatch(customerActionDatas(res.data.doc.customer_actions));
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           // client received an error response (5xx, 4xx)
  //         } else if (err.request) {
  //           // client never received a response, or request never left
  //         } else {
  //           // anything else
  //         }
  //       });
  //   };

  //   CustomerActions();
  // }, [unAssignWorkflow, getParams]);

  // useEffect(async () => {
  //   await getOrganization({ organization: currentOrganization }).then((res) => {
  //     // setSettingData(res?.data?.doc.sms_details || {});
  //     dispatch(smsGateway(res?.data?.doc));
  //   });
  //   //TODO: PRAVEEN FAILURE ERROR HANDLING
  // }, [unAssignWorkflow, getParams]);

  if (isLoading) {
    return (
      <div className="customer-profile-card">
        <Loader />
      </div>
    );
  }

  return (
    <div className="customer-profile-card">
      <div className="customer-profile__card-content">
        <CustomerInvoiceStats
          isQuickAction={isQuickAction}
          getParams={getParams}
        />
      </div>
    </div>
  );
};

export default CustomerOverviewCard;
