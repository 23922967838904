import React, { useContext } from "react";
import setSelectValues from "../../../utils/setSelectValues";
import { useDispatch, useSelector } from "react-redux";
// import { updateDefaultData } from "../../../redux/adhocUploadData/action";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { CellRefSelectBox } from "../../CellRefSelectBox";
import _ from "lodash";
import { updatePaymentDefaultData } from "../../../redux/adhocPaymentUpload/action";

const PaymentDefaultField = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;

  const dispatch = useDispatch();
  const { referenceData, space, currentOrganization } =
    useContext(MaxyfiContext);
  value = _.get(row.original, accessor, "");

  const { mappingFieldList, defaultFields, validDefaultFields } = useSelector(
    (e) => e.adhocPaymentUpload
  );

  let defaultValue = space.organizations?.find(
    ({ id }) => id == currentOrganization
  );

  let unMappedTable = mappingFieldList.findIndex(
    ({ system_field }) => system_field == "customer_currency"
  );

  return (
    <CellRefSelectBox
      placeholder="Select"
      clearable={false}
      backspaceRemoves={true}
      creatable={row && row.original && row.original.is_create ? true : false}
      value={
        validDefaultFields[row.index] &&
        validDefaultFields[row.index]?.default.value === "" &&
        value.reference == "currency"
          ? setSelectValues(defaultValue.default_currency)
          : setSelectValues(value.value)
      }
      onChange={(params) => {
        dispatch(
          updatePaymentDefaultData({
            index: row.index,
            referance: value.reference,
            defaultData: params.value[0].id,
          })
        );
      }}
      //   options={uploadFieldOptions}
      options={
        value.reference && referenceData[value.reference]
          ? referenceData[value.reference]
          : setSelectValues(value.tags, true)
      }
      searchable={true}
      disabled={
        row && row.original && row.original.is_field_disabled ? true : false
      }
    />
  );
};

export default PaymentDefaultField;
