export const NOTES_ADD_ADHOC_UPLOAD_DATA = "NOTES_ADD_ADHOC_UPLOAD_DATA";
export const NOTES_UPDATE_MAP_FIELDS = "NOTES_UPDATE_MAP_FIELDS";
export const NOTES_UPDATE_FORMAT_DATA = "NOTES_UPDATE_FORMAT_DATA";
export const NOTES_UPDATE_DEFAULT_DATA = "NOTES_UPDATE_DEFAULT_DATA";
export const NOTES_UPDATE_SYSTEM_FIELDS = "NOTES_UPDATE_SYSTEM_FIELDS";
export const NOTES_UPDATE_TEMPLATE_DATA_LIST =
  "NOTES_UPDATE_TEMPLATE_DATA_LIST";
export const NOTES_INITIALSTATE_STATE = "NOTES_INITIALSTATE_STATE";
export const NOTES_ADHOC_REFETCH = "NOTES_ADHOC_REFETCH";
export const NOTES_SET_AU_FILTER = "NOTES_SET_AU_FILTER";
export const NOTES_ADHOC_FILTER = "NOTES_ADHOC_FILTER";
export const NOTES_VALIDATION_ADHOC_FILTER = "NOTES_VALIDATION_ADHOC_FILTER";
export const NOTES_MAP_BASED_ON = "NOTES_MAP_BASED_ON";
export const NOTES_BUSINESS_UNIT_DATA = "NOTES_BUSINESS_UNIT_DATA";

export const setAdhocFilter = (data) => {
  return {
    type: NOTES_ADHOC_FILTER,
    payload: data,
  };
};

export const setAdhocUploadFilter = (data) => {
  return {
    type: NOTES_SET_AU_FILTER,
    payload: data,
  };
};

export const setInitialAdhocState = () => {
  return {
    type: NOTES_INITIALSTATE_STATE,
  };
};

export const setAdhocUploadData = (payload) => {
  return {
    type: NOTES_ADD_ADHOC_UPLOAD_DATA,
    payload,
  };
};

export const updateSystemField = ({ index, systemField, type }) => {
  return {
    type: NOTES_UPDATE_SYSTEM_FIELDS,
    payload: { index, systemField, type },
  };
};

export const updateFormatData = ({ index, formatData }) => {
  return {
    type: NOTES_UPDATE_FORMAT_DATA,
    payload: { index, formatData },
  };
};

export const updateDefaultData = ({ index, defaultData, referance }) => {
  return {
    type: NOTES_UPDATE_DEFAULT_DATA,
    payload: { index, defaultData, referance },
  };
};

export const updateTemplateDataList = (templateData) => {
  return {
    type: NOTES_UPDATE_TEMPLATE_DATA_LIST,
    payload: { ...templateData },
  };
};

export const adhocRefetchTable = () => {
  return {
    type: NOTES_ADHOC_REFETCH,
  };
};

export const validationFields = () => {
  return {
    type: NOTES_VALIDATION_ADHOC_FILTER,
  };
};

export const mapBasedOnFn = (mapData) => {
  return {
    type: NOTES_MAP_BASED_ON,
    payload: mapData,
  };
};

export const businessUnitData = ({ rdBussinessUnit }) => {
  return {
    type: NOTES_BUSINESS_UNIT_DATA,
    payload: rdBussinessUnit,
  };
};
