import React from "react";

const ReviewPromiseSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8114 7.68794C18.4263 7.30248 17.8139 7.27825 17.3998 7.61566C17.34 7.66446 17.3863 7.62146 16.4746 8.53319L18.8413 10.8999L19.6918 10.0599C20.103 9.6487 20.103 8.97958 19.6918 8.56831L18.8114 7.68794Z"
        fill="black"
      />
      <path
        d="M11.6194 13.4313L10.2272 16.4145C10.1335 16.6155 10.1754 16.8537 10.3322 17.0105C10.489 17.1673 10.7272 17.2092 10.9281 17.1154L13.9113 15.7232C14.0294 15.6682 13.6721 16.0026 18.0888 11.641L15.7268 9.27899C11.3477 13.6581 11.6756 13.311 11.6194 13.4313V13.4313Z"
        fill="black"
      />
      <path
        d="M14.8016 16.3713C14.6724 16.4988 14.5236 16.6023 14.3591 16.6791L11.3759 18.0713C10.7893 18.345 10.0726 18.2408 9.58824 17.7563C9.11955 17.2877 8.99295 16.5692 9.27323 15.9685L10.6654 12.9852C10.7436 12.8177 10.8496 12.6665 10.9803 12.5357L15.4652 8.05077V3.58208C15.4652 2.70972 14.7555 2 13.8832 2H3.58208C2.70972 2 2 2.70972 2 3.58208V18.4184C2 19.2908 2.70972 20.0005 3.58208 20.0005H13.8832C14.7555 20.0005 15.4652 19.2908 15.4652 18.4184V15.7159L14.8016 16.3713ZM4.81262 5.41025H12.6878C12.9791 5.41025 13.2152 5.64637 13.2152 5.93761C13.2152 6.22885 12.9791 6.46497 12.6878 6.46497H4.81262C4.52137 6.46497 4.28526 6.22885 4.28526 5.93761C4.28526 5.64637 4.52137 5.41025 4.81262 5.41025ZM4.81262 8.22283H11.0003C11.2915 8.22283 11.5276 8.45895 11.5276 8.75019C11.5276 9.04143 11.2915 9.27755 11.0003 9.27755H4.81262C4.52137 9.27755 4.28526 9.04143 4.28526 8.75019C4.28526 8.45895 4.52137 8.22283 4.81262 8.22283ZM4.28526 11.5628C4.28526 11.2715 4.52137 11.0354 4.81262 11.0354H9.31274C9.60399 11.0354 9.8401 11.2715 9.8401 11.5628C9.8401 11.854 9.60399 12.0901 9.31274 12.0901H4.81262C4.52137 12.0901 4.28526 11.854 4.28526 11.5628Z"
        fill="black"
      />
    </svg>
  );
};

export default ReviewPromiseSvg;
