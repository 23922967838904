export default function Download({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M8.99826 11.9492C8.91261 11.9492 8.8329 11.9351 8.75912 11.9069C8.68533 11.8787 8.61372 11.8299 8.54428 11.7604L5.64062 8.85678C5.51042 8.7242 5.44857 8.56952 5.45508 8.39275C5.46159 8.216 5.52608 8.06251 5.64855 7.9323C5.78216 7.8021 5.93902 7.7359 6.11914 7.73373C6.29926 7.73156 6.45442 7.79557 6.58463 7.92578L8.34896 9.70314V3.40104C8.34896 3.21658 8.41158 3.06196 8.5368 2.93717C8.66202 2.81239 8.81718 2.75 9.00229 2.75C9.18739 2.75 9.34179 2.81239 9.46549 2.93717C9.58919 3.06196 9.65104 3.21658 9.65104 3.40104V9.70314L11.4284 7.92578C11.5569 7.79557 11.7094 7.73372 11.8861 7.74023C12.0627 7.74674 12.2178 7.8151 12.3514 7.94531C12.4739 8.07552 12.5362 8.2296 12.5384 8.40756C12.5406 8.58551 12.4766 8.73959 12.3463 8.86979L9.45572 11.7604C9.38628 11.8299 9.31409 11.8787 9.23914 11.9069C9.16421 11.9351 9.08391 11.9492 8.99826 11.9492ZM4.05207 15.25C3.70051 15.25 3.39561 15.1209 3.13736 14.8626C2.87912 14.6044 2.75 14.2995 2.75 13.9479V11.9557C2.75 11.7713 2.81261 11.6166 2.93783 11.4919C3.06305 11.3671 3.21822 11.3047 3.40332 11.3047C3.58842 11.3047 3.74283 11.3671 3.86652 11.4919C3.99022 11.6166 4.05207 11.7713 4.05207 11.9557V13.9479H13.9479V11.9557C13.9479 11.7713 14.0105 11.6166 14.1358 11.4919C14.261 11.3671 14.4161 11.3047 14.6012 11.3047C14.7864 11.3047 14.9408 11.3671 15.0645 11.4919C15.1882 11.6166 15.25 11.7713 15.25 11.9557V13.9479C15.25 14.2995 15.1209 14.6044 14.8626 14.8626C14.6044 15.1209 14.2995 15.25 13.9479 15.25H4.05207Z"
        fill={color}
      />
    </svg>
  );
}
