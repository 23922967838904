const OnHoldModalSvg = () => {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.757 25H10.8091C9.66345 25 8.53906 24.5311 7.74349 23.7182L0 15.7878L2.19576 13.8808C2.85342 13.3076 3.81871 13.193 4.59306 13.5994L7.14947 14.9333V4.98124C7.14947 3.54314 8.33751 2.37599 9.80135 2.37599C9.98168 2.37599 10.162 2.39683 10.3423 2.4281C10.4378 1.07336 11.5834 0 12.9836 0C13.8959 0 14.6914 0.448103 15.1688 1.13589C15.4764 1.01084 15.8158 0.948312 16.1659 0.948312C17.6297 0.948312 18.8177 2.11546 18.8177 3.55356V3.84535C18.9875 3.81409 19.1678 3.79325 19.3481 3.79325C20.812 3.79325 22 4.9604 22 6.3985V20.8316C22 23.1346 20.1013 25 17.757 25ZM3.05497 15.9129L9.27097 22.2697C9.67406 22.6761 10.2257 22.9158 10.7985 22.9158H17.757C18.9238 22.9158 19.8785 21.9779 19.8785 20.8316V6.3985C19.8785 6.10671 19.6451 5.87745 19.3481 5.87745C19.0511 5.87745 18.8177 6.10671 18.8177 6.3985V12.4948H16.6962V3.55356C16.6962 3.26178 16.4629 3.03251 16.1659 3.03251C15.8689 3.03251 15.6355 3.26178 15.6355 3.55356V12.4948H13.514V2.60525C13.514 2.31346 13.2806 2.0842 12.9836 2.0842C12.6866 2.0842 12.4532 2.31346 12.4532 2.60525V12.4948H10.3317V4.98124C10.3317 4.68945 10.0984 4.46019 9.80135 4.46019C9.50434 4.46019 9.27097 4.69987 9.27097 4.98124V18.3931L3.59595 15.4439L3.05497 15.9129Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default OnHoldModalSvg;
