import { Label2, HeadingXSmall } from "baseui/typography";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import SortDown from "../../../assets/img/svg/SortDown";
import SortUp from "../../../assets/img/svg/SortUp";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

const HeaderSort_V2 = (props) => {
  let { column, cellType, isCurrency, literal_id } = props;
  let { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  if (!props.column) {
    return null;
  }

  return (
    <Label2
      // {...props.column.getSortByToggleProps()}
      className={`co_table_head_cell_aligment co_table_head_cell_aligment--${props.alignment}-align`}
      // $style={{ textAlign: `${props.alignment}` }}
      {...{
        // className: header.column.getCanSort()
        //   ? "cursor-pointer select-none"
        //   : "",
        onClick: column.getToggleSortingHandler(),
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <FormattedMessage id={literal_id}>{literal_id}</FormattedMessage>
        </div>

        {(cellType === "CURRENCY" ||
          cellType === "CURRENCY_NEW" ||
          cellType === "CURRENCYFEEAMOUNT") &&
        isCurrency ? (
          <HeadingXSmall
            style={{ color: "#ADADAD", lineHeight: "10px" }}
          >{`(in ${currentDefaultFormatDetails.default_currency})`}</HeadingXSmall>
        ) : (
          ""
        )}
      </div>

      {/* {props.column.canSort ? (
        <div className="table-main__head-sort">
          <SortUp
            className={
              props.column.isSorted && !props.column.isSortedDesc
                ? "active"
                : ""
            }
          />
          <SortDown
            className={
              props.column.isSorted && props.column.isSortedDesc ? "active" : ""
            }
          />
        </div>
      ) : (
        <></>
      )} */}
    </Label2>
  );
};

export default HeaderSort_V2;
