import { HeadingXSmall, ParagraphMedium } from "baseui/typography";
import React from "react";
import CallBackLater from "../../../assets/img/svg/CallBackLater";
import Dispute from "../../../assets/img/svg/Dispute";
import HoldAction from "../../../assets/img/svg/HoldAction";
import MeetingRequest from "../../../assets/img/svg/MeetingRequest";
import PromiseToPay from "../../../assets/img/svg/PromiseToPay";
import Skipped from "../../../assets/img/svg/Skipped";
import SuccessTick from "../../../assets/img/svg/SuccessTick";
import WarningAlert from "../../../assets/img/svg/WarningAlert";

const CustomerWorkflowCellType = (props) => {
  switch (props.row.original.type) {
    case "success":
      return (
        <div className="cwf-body__cell-type">
          <SuccessTick />
          <HeadingXSmall $style={{ color: "#ADADAD", marginLeft: "5px" }}>
            {props.value}
          </HeadingXSmall>
        </div>
      );
    case "skipped":
      return (
        <div className="cwf-body__cell-type">
          <Skipped />
          <HeadingXSmall $style={{ color: "#ADADAD", marginLeft: "5px" }}>
            {props.value}
          </HeadingXSmall>
        </div>
      );
    case "overdue":
      return (
        <div className="cwf-body__cell-type">
          <WarningAlert />
          {/* <HeadingXSmall $style={{ color: "#ADADAD", marginLeft: "5px" }}>
            {props.value}
          </HeadingXSmall> */}
        </div>
      );
    case "duetoday":
      return (
        <div className="cwf-body__cell-type cwf-alert__icon">
          <WarningAlert />
          {/* <HeadingXSmall $style={{ color: "#ADADAD", marginLeft: "5px" }}>
            {props.value}
          </HeadingXSmall> */}
        </div>
      );
    case "on-hold":
      return (
        <div className="cwf-table-cell__content">
          <HoldAction />
          <div>
            <ParagraphMedium
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </ParagraphMedium>
            <HeadingXSmall
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </HeadingXSmall>
          </div>
        </div>
      );
    case "promise-to-pay":
      return (
        <div className="cwf-table-cell__content">
          <PromiseToPay />
          <div>
            <ParagraphMedium
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </ParagraphMedium>
            <HeadingXSmall
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </HeadingXSmall>
          </div>
        </div>
      );
    case "call-back-later":
      return (
        <div className="cwf-table-cell__content">
          <CallBackLater />
          <div>
            <ParagraphMedium
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </ParagraphMedium>
            <HeadingXSmall
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </HeadingXSmall>
          </div>
        </div>
      );
    case "meeting-request":
      return (
        <div className="cwf-table-cell__content">
          <MeetingRequest />
          <div>
            <ParagraphMedium
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </ParagraphMedium>
            <HeadingXSmall
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </HeadingXSmall>
          </div>
        </div>
      );
    case "dispute":
      return (
        <div className="cwf-table-cell__content">
          <Dispute />
          <div>
            <ParagraphMedium
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </ParagraphMedium>
            <HeadingXSmall
              $style={{
                color: "#333860",
                marginLeft: "5px",
                lineHeight: "19px",
              }}
            >
              {props.value}
            </HeadingXSmall>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default CustomerWorkflowCellType;
