import { HeadingXSmall, LabelSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import React, { useContext, useState } from "react";
import InvoiceUser from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/InvoiceUser";
import DairyNoteEdit from "../../assets/img/svg/CustomerOverViewIcon/DairyNoteEdit";
import DeletePdf from "../../assets/img/svg/DeletePdf";
import DocDownArSvg from "../../assets/img/svg/DocDownArSvg";
import DocRigntSvg from "../../assets/img/svg/DocRigntSvg";
import DownloadSvg from "../../assets/img/svg/DownloadSvg";
import ViewIcon from "../../assets/img/svg/ViewIcon";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { utcTimstampToLocalDate } from "../../utils/utcTimstampToLocalDate";
import { IconButton } from "../IconButton";
import { KIND, SIZE, TextButton } from "../TextButton";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import DocFileType from "../../assets/img/svg/DocFileType";
import DocImgSvg from "../../assets/img/svg/DocImgSvg";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL } from "../../constants";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { STYLE_TYPE } from "baseui/checkbox";
import {
  documentDrive,
  editDocumentForm,
  unSelectedDocumentData,
} from "../../redux/customerOverview/DMSystem/action";
import {
  deleteDocumentCard,
  downloadDocumentData,
} from "../../services/documentSystem";
import { ModalContext } from "../../providers/ModalProvider";
import Warning from "../../assets/img/warning.png";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { einRegex } from "../../utils/regexConfig";
import { editInvoiceDocumentForm } from "../../redux/invoiceOverview/DMSystemINV/action";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  VIEW_DOWNLOAD_DOCUMENTS,
  EDIT_DOCUMENTS,
  DELETE_DOCUMENTS,
} from "../../providers/RBACProvider/permissionList";
import ViewIconOutline from "../../assets_v2/icons/ViewIconOutline";

const DocumentType = ({ type }) => {
  switch (type) {
    case "pdf":
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
      return (
        <div className="file_wraper_icon --doc_purple">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "csv":
    case "xls":
    case "xlsx":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "doc":
    case "docx":
      return (
        <div className="file_wraper_icon --doc_blue">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "txt":
    case "plain":
      return (
        <div className="file_wraper_icon --doc_grey">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingLeft: "2px" }}
          >
            {type === "plain" ? "TXT" : type.toUpperCase()}
            {/* {type.toUpperCase()} */}
          </p>
        </div>
      );

    default:
      return (
        <div className="file_wraper_icon --doc_yellow">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type?.length >= 4 && "--doc_font_size"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );
  }
};

const DocumentCard = (props) => {
  let {
    res,
    resourceId,
    deleteMethod,
    isSelected = false,
    onFileSelectChange,
    entity,
    type,
  } = props;

  const { handleModal } = useContext(ModalContext);

  const [isExpand, setIsExpand] = useState(false);
  const [isSelect, setIsSelect] = useState(isSelected);
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let dispatch = useDispatch();

  let { drawerType } = useSelector((doc) => doc.customerDocumentReducer);
  let { drawerInvoiceState, drawerInvoiceType } = useSelector(
    (s) => s.invoiceDocumentReducer
  );
  let drawerState = entity === "invoices" ? drawerInvoiceType : drawerType;

  function epandCard() {
    setIsExpand(!isExpand);
  }

  let splitType = res && res.mime_type && res.mime_type.split("/");

  let {
    currentDefaultFormatDetails,
    currentOrganization,
    users,
    referenceData,
  } = useContext(MaxyfiContext);

  // const legalFields = referenceData["legal_action_fields"]?.find(
  //   ({ id }) => id === res.lty
  // );

  let noteLocalDate =
    res.audit_info &&
    res.audit_info.audit_on &&
    utcTimstampToLocalDate(
      res.audit_info.audit_on,
      currentDefaultFormatDetails.date_format
    );

  let created =
    res &&
    res.created_at &&
    utcTimstampToLocalDate(
      res.created_at,
      currentDefaultFormatDetails.date_format
    );

  let noteUser = res.modified_by ? res.modified_by : res.created_by;

  let noteUserRef = users.find((e) => e.id === noteUser);
  let splitingType = res && res.mime_type && res.mime_type.split("/");

  const checkMimeTypeForPreview = (type) => {
    if (type === "image/png") {
      return true;
    } else if (type === "image/jpeg") {
      return true;
    } else if (type === "image/gif") {
      return true;
    } else if (type === "application/pdf") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className={
        isExpand ? "dms_card_container --dms_card_pt" : "dms_card_container"
      }
    >
      <div className="dms_card_head">
        <div className="dms_card_doc_type_content">
          <div>
            {
              // need to change
              // drawerState
              type == EMAIL ? (
                <CheckBoxBaseweb
                  checked={isSelect}
                  checkmarkType={STYLE_TYPE.default}
                  onChange={() => {
                    onFileSelectChange({
                      name: res.file_name,
                      type: splitingType[1] ? splitingType[1] : "",
                      fileRef: res._id,
                      isNewDoc: false,
                      scanStatus: "SUCCESS",
                    });
                    setIsSelect(!isSelect);
                  }}
                />
              ) : (
                <>
                  {res && res.tab === "timeline" ? (
                    <></>
                  ) : (
                    <>
                      <IconButton
                        kind={KIND.tertiary}
                        size={SIZE.mini}
                        onClick={() => epandCard()}
                      >
                        {isExpand ? <DocDownArSvg /> : <DocRigntSvg />}
                      </IconButton>
                    </>
                  )}
                </>
              )
            }
          </div>
          <div>
            <DocumentType
              type={
                res.tab === "timeline"
                  ? res.mime_type
                  : splitType[1]
                  ? splitType[1]
                  : ""
              }
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            {isExpand ? (
              <LabelSmall
                title={res.file_name}
                $style={{
                  lineHeight: "16px",
                  color: "#333860",
                  paddingBottom: "4px",
                  // whiteSpace: "nowrap",
                  // overflow: "hidden",
                  // minWidth: "100px",
                  // maxWidth: "200px",
                  // textOverflow: "ellipsis",
                }}
              >
                {res.file_name}
              </LabelSmall>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <LabelSmall
                  title={res.file_name}
                  $style={{
                    lineHeight: "15.6px",
                    color: "#333860",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    minWidth: "100px",
                    maxWidth: "200px",
                    textOverflow: "ellipsis",
                  }}
                >
                  {res.file_name}
                </LabelSmall>
              </div>
            )}

            <HeadingXSmall $style={{ lineHeight: "15px", color: "#787878" }}>
              {res?.category}
              {res && res.tab === "timeline" ? (
                <></>
              ) : (
                <>
                  {res?.category === "CUSTOMER" ? (
                    <></>
                  ) : (
                    <>/ {res?.invoice_number}</>
                  )}
                  / {res.sub_category}
                </>
              )}
            </HeadingXSmall>
            {res.is_share_portal && !res.ldt && (
              <HeadingXSmall $style={{ lineHeight: "15px", color: "#787878" }}>
                Download Available
              </HeadingXSmall>
            )}
            {res.ldt && (
              <HeadingXSmall $style={{ lineHeight: "15px", color: "#787878" }}>
                Consumer Downloaded on{" "}
                {moment(res.ldt).format(
                  currentDefaultFormatDetails.date_format
                )}
              </HeadingXSmall>
            )}
          </div>
        </div>
        {res.isDownload ? (
          <></>
        ) : (
          <div>
            <RBACWrapper role={VIEW_DOWNLOAD_DOCUMENTS} type={"PERMISSION"}>
              {checkMimeTypeForPreview(res && res?.mime_type) ? (
                <TextButton
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  isLoading={previewIsLoading}
                  disabled={previewIsLoading}
                  onClick={() => {
                    setPreviewIsLoading(true);
                    downloadDocumentData({
                      documentId: res._id,
                      customerId:
                        res.tab === "timeline" ? res.customerId : resourceId,
                      organization: currentOrganization,
                      fileName: res.file_name,
                      type: res.mime_type,
                      viewOnly: true,
                    }).then(() => setPreviewIsLoading(false));
                  }}
                >
                  <ViewIconOutline color="#516BEB" size={18} />
                </TextButton>
              ) : (
                <></>
              )}

              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                isLoading={isLoading}
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  downloadDocumentData({
                    documentId: res._id,
                    customerId:
                      res.tab === "timeline" ? res.customerId : resourceId,
                    organization: currentOrganization,
                    fileName: res.file_name,
                    type: res.mime_type,
                  }).then(() => setIsLoading(false));
                }}
              >
                <DownloadSvg />
              </TextButton>
            </RBACWrapper>
          </div>
        )}
      </div>
      {isExpand && (
        <div style={{ paddingLeft: "50px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <HeadingXSmall $style={{ color: "#ADADAD" }}>
              Upload on:
            </HeadingXSmall>
            <LabelSmall $style={{ color: "#333860" }}>
              {moment(created).format(currentDefaultFormatDetails.date_format)}
            </LabelSmall>
          </div>

          <ParagraphMedium>
            {res && res.remarks
              ? res.remarks.replace(/(<([^>]+)>)/gi, "").replace("&amp;", "")
              : ""}
          </ParagraphMedium>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
                borderTop: "1px solid #c7c7c7",
                marginTop: "10px",
                marginRight: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "8px",
                }}
              >
                <div style={{ paddingTop: "2px" }}>
                  <InvoiceUser />
                </div>
                <div
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  <div>
                    <ParagraphMedium
                      $style={{
                        color: "#333860",
                        paddingRight: "5px",
                        lineHeight: "16px",
                      }}
                    >
                      {noteUserRef && noteUserRef.displayName
                        ? noteUserRef.displayName
                        : "Portal"}
                    </ParagraphMedium>
                    <HeadingXSmall
                      $style={{
                        fontSize: "10px",
                        color: "#ADADAD",
                        lineHeight: "16px",
                      }}
                    >
                      {moment(noteLocalDate).format(
                        currentDefaultFormatDetails.date_format
                      )}
                    </HeadingXSmall>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <RBACWrapper role={EDIT_DOCUMENTS} type={"PERMISSION"}>
                  <IconButton
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    onClick={() => {
                      if (entity === "invoices") {
                        dispatch(editInvoiceDocumentForm(res));
                      } else {
                        dispatch(editDocumentForm(res));
                      }
                    }}
                  >
                    <DairyNoteEdit />
                  </IconButton>
                </RBACWrapper>
                <RBACWrapper role={DELETE_DOCUMENTS} type={"PERMISSION"}>
                  <IconButton
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    onClick={() => {
                      handleModal({
                        title: "",
                        content:
                          "Are you sure, you want to delete this Document?",
                        successCallback: () => {
                          deleteMethod.mutateAsync({
                            documentId: res._id,
                            customerId: resourceId,
                          });
                        },
                        formContent: () => {
                          return <></>;
                        },
                        cancelCallback: () => {},
                        isChildren: false,
                        image: Warning,
                        successButtonType: "error",
                        buttonText: "Delete",
                        closeButtonText: "Cancel",
                        isCloseAble: false,
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </RBACWrapper>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentCard;
