import React from "react";
import { useState } from "react";
import ribbon from "../../../assets/img/ribbon.png";
import { Label1 } from "baseui/typography";
import { KIND } from "baseui/button";
import TriggerAction from "../../../assets/img/svg/NestedRibbons/TriggerAction";
import RelationManager from "../../../assets/img/svg/NestedRibbons/RelationManager";
import SetWorkFlow from "../../../assets/img/svg/NestedRibbons/SetWorkFlow";
import CaptureHold from "../../../assets/img/svg/NestedRibbons/CaptureHold";
import ReleaseOnHold from "../../../assets/img/svg/NestedRibbons/ReleaseOnHold";
import SuspendWorkFlow from "../../../assets/img/svg/NestedRibbons/SuspendWorkFlow";
import Tags from "../../../assets/img/svg/NestedRibbons/Tags";
import { Select } from "baseui/select";
import { SIZE } from "baseui/input";
import { DatePicker } from "baseui/datepicker";
import { ThemeProvider, createTheme, lightThemePrimitives } from "baseui";
import { useForm, Controller } from "react-hook-form";
import { Checkbox, STATE_TYPE } from "baseui/checkbox";
import IconButton from "../../../components/IconButton/IconButton";
import { useDispatch } from "react-redux";

import TextButton from "../../../components/TextButton/TextButton";
import { getNestedActionType } from "../../../redux/customerSummary/action";
import { StatefulTooltip } from "baseui/tooltip";
import SwitchAction from "./SwitchAction";

const InsideComp = ({ toggleAllRowsSelected }) => {
  const [state, setState] = useState(false);
  const [third, setThird] = useState(false);
  // const [value, setValue] = React.useState([]);

  // React Hook Form
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      select: {},
      checkbox: false,
      datePicker: new Date(),
    },
  });
  const watchFields = watch();
  const onSubmit = (data) => {
    setState(true);
    setThird(false);
  };
  return (
    <>
      {state ? (
        // Pop up Three
        <div>
          <div
            style={{
              width: "200px",
              height: "277px",
              backgroundColor: "#FFFFFF",
              border: "0.5px solid #CDCED925",
              borderRadius: "5px",
              boxShadow: "0px 2px 4px 2px #d6d6d6",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
                paddingTop: "25px",
                color: "#516BEB",
              }}
            >
              Capture Next Action
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={ribbon} alt="" width={107} height={85} />
            </div>
            <div
              style={{
                width: "100%",
                height: "50px",
                textAlign: "center",
                padding: "15px 20px",
                color: "#333860",
                fontSize: "13px",
                fontWeight: "500",
              }}
            >
              YAY!! The selected Action is captured sucessfully for the records
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "28px",
              }}
            >
              <TextButton
                kind={KIND.secondary}
                size={SIZE.compact}
                onClick={() => {
                  toggleAllRowsSelected(false);
                }}
              >
                <span style={{ foneSize: "14px" }}> Close</span>
              </TextButton>
            </div>
          </div>
        </div>
      ) : third ? (
        <SwitchAction
          setState={setThird}
          setNextState={setState}
          toggleAllRowsSelected={toggleAllRowsSelected}
        />
      ) : (
        // <div>
        //   <form onSubmit={handleSubmit(onSubmit)}>
        //     <div
        //       style={{
        //         boxShadow: "0px 2px 4px 2px #d6d6d6",
        //         background: "#FFFFFF",
        //         border: "0.5px solid #CDCED925",

        //         borderRadius: "5px",
        //         width: "205px",
        //         height: "277px",
        //       }}
        //     >
        //       <div
        //         style={{
        //           fontWeight: "bold",
        //           fontSize: "16px",
        //           paddingTop: "16px",
        //           textAlign: "center",
        //           color: "#516BEB",
        //         }}
        //       >
        //         Capture Next Action
        //       </div>
        //       <Controller
        //         name="select"
        //         control={control}
        //         render={({ field }) => (
        //           <Select
        //             {...field}
        //             options={[
        //               { label: "AliceBlue", id: "#F0F8FF" },
        //               { label: "AntiqueWhite", id: "#FAEBD7" },
        //               { label: "Aqua", id: "#00FFFF" },
        //             ]}
        //             overrides={{
        //               ControlContainer: {
        //                 style: () => ({
        //                   border: "0.5px solid #CDCDD9",
        //                   backgroundColor: "#EEEEEE",
        //                   borderRadius: "5px",
        //                   height: "41px",
        //                   width: "180px",
        //                   marginLeft: "10px",
        //                   marginTop: "16px",
        //                 }),
        //               },

        //               Popover: {
        //                 props: {
        //                   overrides: {
        //                     Inner: {
        //                       style: () => ({
        //                         // zIndex: "99",
        //                       }),
        //                     },
        //                     Body: {
        //                       style: () => ({
        //                         // zIndex: "99",
        //                       }),
        //                     },
        //                   },
        //                 },
        //               },
        //             }}
        //             placeholder="Select Actions"
        //           />
        //         )}
        //       />

        //       <Controller
        //         control={control}
        //         name="datePicker"
        //         render={({ field }) => (
        //           <ThemeProvider
        //             theme={createTheme(lightThemePrimitives, {
        //               colors: {
        //                 calendarDayBackgroundSelectedHighlighted: "#516BEB",
        //               },
        //             })}
        //           >
        //             <DatePicker
        //               onChange={(e) => field.onChange(e)}
        //               selected={field.value}
        //               overrides={{
        //                 Input: {
        //                   props: {
        //                     overrides: {
        //                       Root: {
        //                         style: () => ({
        //                           borderRadius: "5px",
        //                           height: "41px",
        //                           width: "180px",
        //                           marginLeft: "10px",
        //                           marginTop: "18px",
        //                           border: "0.5px solid #CDCDD9",
        //                         }),
        //                       },
        //                       Input: {
        //                         style: () => ({
        //                           fontSize: "13px",
        //                         }),
        //                       },
        //                       InputContainer: {
        //                         style: () => ({
        //                           backgroundColor: "#EEEEEE",
        //                         }),
        //                       },
        //                     },
        //                   },
        //                 },
        //                 Popover: {
        //                   props: {
        //                     overrides: {
        //                       Body: {
        //                         style: () => ({
        //                           // zIndex: "99",
        //                         }),
        //                       },
        //                     },
        //                   },
        //                 },
        //               }}
        //               placeholder="Select Action Date"
        //             />
        //           </ThemeProvider>
        //         )}
        //       />

        //       <div
        //         style={{
        //           paddingTop: "25px",
        //           paddingLeft: "9px",
        //         }}
        //       >
        //         <div
        //           style={{
        //             fontSixe: "16px",
        //             fontWeight: "bold",
        //             color: "#666666",
        //             display: "flex",
        //           }}
        //         >
        //           <Controller
        //             name="checkbox"
        //             control={control}
        //             render={({ field }) => (
        //               <Checkbox
        //                 {...field}
        //                 checked={watchFields.checkbox || false}
        //                 overrides={{
        //                   Checkmark: {
        //                     style: ({ $theme, $checked }) => ({
        //                       height: "18px",
        //                       width: "18px",
        //                       backgroundColor: $checked ? "#516BEB" : null,
        //                       borderRadius: $theme.borders.radius200,
        //                       borderColor: $checked ? `#516BEB` : `#CDCED9`,
        //                       borderWidth: "1px",
        //                       borderStyle: "solid",
        //                       backgroundImage: $checked
        //                         ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
        //                         : "none",
        //                       backgroundSize: "auto",
        //                     }),
        //                   },
        //                   Label: {
        //                     style: ({ $theme }) => ({
        //                       fontSize: "14px",
        //                       fontWeight: "500",
        //                       color: "#333860",
        //                     }),
        //                   },
        //                 }}
        //               >
        //                 Override Workflow Action
        //               </Checkbox>
        //             )}
        //           />
        //         </div>
        //       </div>
        //       <div
        //         style={{
        //           display: "flex",
        //           justifyContent: "space-around",
        //           marginTop: "23px",
        //         }}
        //       >
        //         <TextButton
        //           kind={KIND.tertiary}
        //           size={SIZE.compact}
        //           onClick={() => {
        //             // setState(true);
        //             setThird(false);
        //           }}
        //         >
        //           <span className="btn-cancel"> Cancel</span>
        //         </TextButton>
        //         <TextButton kind={KIND.primary} size={SIZE.compact}>
        //           <span type="submit" style={{ fontSize: "14px" }}>
        //             Submit
        //           </span>
        //         </TextButton>
        //       </div>
        //     </div>
        //   </form>
        // </div>
        <ActionIconList setState={setThird} />
      )}
    </>
  );
};

const ActionIconList = ({ setState }) => {
  const dispatch = useDispatch();
  return (
    <div className="action-container">
      {/** NEED TO CHANGE LABEL with font-size 16px **/}
      <Label1 className="text-primary pb-10">Select Action</Label1>
      <div className="action-container-body">
        <StatefulTooltip
          content={() => <div style={{}}>Next Action</div>}
          returnFocus
          autoFocus
        >
          <div>
            <IconButton
              kind={KIND.primary}
              size={SIZE.compact}
              onClick={() => {
                dispatch(getNestedActionType("next_action"));
                setState(true);
              }}
            >
              <TriggerAction />
            </IconButton>
          </div>
        </StatefulTooltip>

        <StatefulTooltip
          content={() => <div>Assign Relation Manager</div>}
          returnFocus
          autoFocus
        >
          <div>
            <IconButton
              size={SIZE.compact}
              kind={KIND.primary}
              onClick={() => {
                dispatch(getNestedActionType("assign_relation"));
                setState(true);
              }}
            >
              <RelationManager />
            </IconButton>
          </div>
        </StatefulTooltip>

        <StatefulTooltip
          content={() => <div>Set Workflow</div>}
          returnFocus
          autoFocus
        >
          <div>
            <IconButton
              size={SIZE.compact}
              onClick={() => {
                dispatch(getNestedActionType("set_work"));
                setState(true);
              }}
            >
              <SetWorkFlow />
            </IconButton>
          </div>
        </StatefulTooltip>

        <StatefulTooltip
          content={() => <div>Suspend Workflow</div>}
          returnFocus
          autoFocus
        >
          <div>
            <IconButton
              size={SIZE.compact}
              onClick={() => {
                dispatch(getNestedActionType("suspend_workflow"));
                setState(true);
              }}
            >
              <SuspendWorkFlow />
            </IconButton>
          </div>
        </StatefulTooltip>

        <StatefulTooltip
          content={() => <div>Capture onHold</div>}
          returnFocus
          autoFocus
        >
          <div>
            <IconButton
              size={SIZE.compact}
              onClick={() => {
                dispatch(getNestedActionType("capture_onhold"));
                setState(true);
              }}
            >
              <CaptureHold />
            </IconButton>
          </div>
        </StatefulTooltip>
        <StatefulTooltip
          content={() => <div>Release onHold</div>}
          returnFocus
          autoFocus
        >
          <div>
            <IconButton
              size={SIZE.compact}
              onClick={() => {
                dispatch(getNestedActionType("release_onhold"));
                setState(true);
              }}
            >
              <ReleaseOnHold />
            </IconButton>
          </div>
        </StatefulTooltip>
        <StatefulTooltip content={() => <div>Tags</div>} returnFocus autoFocus>
          <div>
            <IconButton
              size={SIZE.compact}
              onClick={() => {
                dispatch(getNestedActionType("tags"));
                setState(true);
              }}
            >
              <Tags />
            </IconButton>
          </div>
        </StatefulTooltip>
      </div>
    </div>
  );
};

const CustomerAction = ({ selectedFlatRows = [], toggleAllRowsSelected }) => {
  return selectedFlatRows.length > 0 ? (
    <div
      style={{
        position: "absolute",
        right: "31px", // ---- 6px scrollbra width container padding is 25px ----
        top: "28%",
      }}
    >
      <InsideComp toggleAllRowsSelected={toggleAllRowsSelected} />
    </div>
  ) : (
    <></>
  );
};
export default CustomerAction;
