import React, { useContext, useEffect, useState } from "react";
import { IconButton, SIZE, KIND } from "../../components/IconButton";
import Download from "../../assets/img/svg/Download";
import { useRBAC, permission } from "../../providers/RBACProvider";
import { downloadAdhocTemplateData } from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { CUSTOMER_OVERVIEW } from "../../constants";
import { useQuery } from "react-query";
import { customerData } from "../../services/customerSummary";
import { useSelector } from "react-redux";
import { array, object } from "yup";
import _ from "lodash";
import { workflowAddModal } from "../../redux/customerOverview/action";
import { FormattedDate } from "react-intl";
import { useIntl } from "react-intl";
// import CsvDownload from "react-json-to-csv";
import csvDownload from "json-to-csv-export";
import { TextButton } from "../TextButton";

import moment from "moment";
import axios from "axios";
import axiosWithAuth from "../../providers/AxiosInterceptor";
import { useNumericFormat } from "react-number-format";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import useFormat from "../../hooks/useFormat";

function DownloadInvoiceSummary({ queryFilter, ...props }) {
  const { isAllowed } = useRBAC();
  let {
    currentOrganization,
    referenceData,
    users,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    appAccessRest,
  } = useContext(MaxyfiContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const intl = useIntl();

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  let gridStructureData = props.gridStructure?.table;
  const format = useFormat();
  const { tableCurrentState } = useSelector(
    (state) => state.invoiceSummaryReducer
  );

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let filteredColumns = props.filteredColumns;

  let finalDatas = filteredColumns.reduce((prev, curr) => {
    let gridColumn = gridStructureData.find((i) => i.accessor === curr.id);

    if (gridColumn && gridColumn.nested && Array.isArray(gridColumn.nested)) {
      return [...prev, ...gridColumn.nested];
    }
    return [...prev, curr];
  }, []);

  // .reduce((prev, curr) => {}, []);

  // if (gridStructureData) {
  //   // const currentFilter = newState.tableState.filters;

  //   let flatGridStructure = gridStructureData.reduce((prev, curr) => {
  //     if (curr.nested) {
  //       return [...prev, ...(curr?.nested ? curr?.nested : [])];
  //     }
  //     return [...prev, curr];
  //   }, []);

  //

  //   let formFilters = [];
  //   let filters = filteredColumns.reduce((prev, curr) => {
  //     //{curr ==== {col_id, filter.value}

  //     let currGSColumn = flatGridStructure.find((i) => i.accessor === curr.id);

  //

  //     if (currGSColumn) {
  //       formFilters.push({
  //         id: currGSColumn.literal_id,
  //         type: currGSColumn.type,
  //         cellType: currGSColumn.cell_type,
  //         accessor: currGSColumn.accessor,
  //         value: curr.value,
  //       });
  //     }

  //     return { ...prev, [curr.id]: curr.value };
  //   }, {});
  //
  //   // newState.tableCurrentState.formFilters = formFilters;
  //   // newState.tableCurrentState.filters = filters;
  // }

  // let flatedRow = filteredColumns.map((e) => {
  //   let gridStructureData = props.gridStructure.table.find(
  //     (i) => i.accessor === e.col_id
  //   );

  //
  // });
  // let colExist =
  //   filteredColumns && filteredColumns.find((i) => i.accessor === e.col_id);
  // let currGSColumn = gridStructure.table.find((i) => i.accessor === e.col_id);

  // query start here

  let newFilter = dynamicQueryFilter({
    gridStructure: props && props.gridStructure,
    // customerQueryFilter: queryFilter && queryFilter.queryFilter,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: tableCurrentState.filters,
  });

  // query end here

  return (
    <>
      {isAllowed(permission.DOWNLOAD_VIEW_DATA) ? (
        <>
          <TextButton
            size={SIZE.mini}
            kind={KIND.primary}
            onClick={async () => {
              setLoading(true);

              let data = await props
                .service({
                  organization: currentOrganization,
                  pageIndex: 1,
                  sortBy: props.querySortBy,
                  pageSize: 0,
                  customerFilter: newFilter,
                  filters: {
                    needs_actions: [...NeedsActionFilter],
                    ...appAccessRest,
                    ...(queryFilter &&
                    queryFilter.queryFilter &&
                    queryFilter &&
                    queryFilter.queryFilter.length > 0
                      ? { search_value: queryFilter && queryFilter.queryFilter }
                      : {}),
                  },
                })
                .then((res) => {
                  setData(res?.data?.docs);
                  setLoading(false);
                  return res.data.docs;
                })
                .catch((error) => {
                  return [];
                });

              let mockData = data?.map((row) => {
                let rsObj = {};

                finalDatas?.forEach((k) => {
                  let key = k.literal_id;
                  key = intl.formatMessage({
                    id: key,
                  });
                  if (props.defaultCurrency && k.cell_type === "CURRENCY") {
                    key = `${key} ${""} (in ${props.defaultCurrency})`;
                  }

                  let value = _.get(row, k.accessor || k.path, "");

                  switch (k.cell_type) {
                    case "CURRENCY_NEW": {
                      if (value) {
                        //   value = `${"$"}${new Intl.NumberFormat().format(value?.value)}`;
                        value = `${format.currency({ amount: value })} ${_.get(
                          currentDefaultFormatDetails,
                          "default_currency",
                          ""
                        )}`;
                      }
                      break;
                    }
                    // Next Action Date
                    case "DATEORGTZ": {
                      const orgRef = referenceData?.organizations?.find(
                        ({ id }) => id === currentOrganization
                      );
                      const timeZone = orgRef?.time_zone;
                      let date = moment
                        ?.utc(value)
                        ?.tz(timeZone)
                        ?.format(currentDefaultFormatDetails?.date_format);
                      if (value) {
                        value = date;
                      }
                      break;
                    }
                    // value && value ? (
                    //   (value = intl.formatDate(date, {
                    //     year: "numeric",
                    //     month: "numeric",
                    //     day: "numeric",
                    //   }))
                    // ) : (
                    //   <></>
                    // );

                    case "DATE": {
                      let dateOn = moment
                        .utc(value)
                        .format(currentDefaultFormatDetails?.date_format);
                      if (value) {
                        value = dateOn;
                      }
                      // value && value ? (
                      //   (value = intl.formatDate(dateOn, {
                      //     year: "numeric",
                      //     month: "numeric",
                      //     day: "numeric",
                      //   }))
                      // ) : (
                      //   <></>
                      // );
                      break;
                    }

                    case "REFERENCE_VALUE": {
                      const rfValue = referenceData[
                        k?.rd_name || k?.rdName
                      ]?.find(({ id }) => {
                        return id === value;
                      });

                      if (
                        (k && k.rdName === "business_unit_list") ||
                        k?.rd_name === "business_unit_list"
                      ) {
                        value = rfValue && rfValue.client_name;
                      } else if (value) {
                        value = rfValue?.label;
                      }
                      // value && value ? (value = user?.displayName) : <></>;

                      break;
                      //
                    }

                    case "USER": {
                      const user = users.find(({ id }) => {
                        return id === value;
                      });

                      if (value) {
                        value = user?.displayName;
                      }
                      // value && value ? (value = user?.displayName) : <></>;

                      break;
                    }

                    case "CURRENCY": {
                      if (value) {
                        //   value = `${"$"}${new Intl.NumberFormat().format(value?.value)}`;
                        value = `${numberFormat.format(`${value?.value}`)} ${
                          props.defaultCurrency ? "" : value?.currency
                        }`;
                      }
                      break;
                    }
                  }
                  rsObj[key] = value;
                });

                return rsObj;
              });

              csvDownload(
                mockData,
                `${`${props.fileName} ${moment().format(
                  currentDefaultFormatDetails?.date_format
                )}${" "}${moment().format("hh:mm:ss a")}.csv`}`
                // (moment().format("MM-DD-YYYY"), moment().format("hh:mm:ss a"))

                // ((__filename = "customerSummary.csv"))
              );
            }}
            isLoading={loading}
            disabled={loading}
            overrides={{
              LoadingSpinner: {
                style: ({ $theme }) => ({
                  height: "18px",
                  width: "18px",
                }),
              },
            }}
          >
            {/* <CsvDownload data={mockData}> */}
            <Download />
            {/* </CsvDownload> */}
          </TextButton>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default DownloadInvoiceSummary;
