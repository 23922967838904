import React from "react";

const NextAction = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2524 10.4533L4.52775 5.11227C4.20147 4.94792 3.81205 4.96573 3.50115 5.15437C3.18864 5.34221 3 5.67739 3 6.03686V16.7222C3 17.0808 3.18864 17.4152 3.50115 17.6038C3.66955 17.7059 3.86062 17.7577 4.05412 17.7577C4.21685 17.7577 4.37878 17.7204 4.52856 17.6459L11.2532 12.3033C11.5771 11.9989 11.8329 11.7706 11.8329 11.3779C11.8329 10.9852 11.6151 10.7739 11.2524 10.4533Z"
        fill="#516BEB"
      />
      <path
        d="M17.7294 10.4533L11.0048 5.11227C10.6785 4.94792 10.2882 4.96573 9.97735 5.15437C9.66484 5.34221 9.4762 5.67739 9.4762 6.03686V16.7222C9.4762 17.0808 9.66484 17.4152 9.97735 17.6038C10.1457 17.7059 10.3368 17.7577 10.5303 17.7577C10.693 17.7577 10.855 17.7204 11.0048 17.6459L17.7294 12.3033C18.0533 11.9989 18.3091 11.7706 18.3091 11.3779C18.3091 10.9852 18.0921 10.7739 17.7294 10.4533Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default NextAction;
