import { ParagraphSmall, HeadingXSmall } from "baseui/typography";
import moment from "moment";
import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";

import { refetchActions } from "../../../redux/actions";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  refetchDiaryNotes,
  cancelNoteForm,
} from "../../../redux/customerDairyNote/action";
import getSelectValues from "../../../utils/getSelectValues";
import { EDIT } from "../../../constants";
import InfoTag from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import AlertTag from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/AlertTag";
import CricticalSvg from "../../../assets/img/svg/CricticalSvg";
import setSelectValues from "../../../utils/setSelectValues";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import { refetchInvoiceActions } from "../../../redux/invoiceOverview/action";
import { ADD_LIST_VALUES } from "../../../providers/RBACProvider/permissionList";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { TextButton, KIND, SIZE } from "../../../components/TextButton";
import { SelectBox } from "../../../components/SelectBox";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../../../constants";
import queryClient from "../../../providers/queryClient";

const NoteFormContainer = (props) => {
  const { customerId } = useParams();
  const {
    createDiaryNoteService,
    updateDiaryNoteService,
    resourceName,
    resourceId,
    editDairyNote,
    getParams,
    entity,
    // refetchActions,
    refetch,
  } = props;
  // const BlockEmbed = ReactQuill.Quill.import("blots/block/embed");
  // const BlockEmbed = ReactQuill.Quill.import("blots/block");
  const BlockEmbed = ReactQuill.Quill.import("blots/embed");

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [currentEditor, setCurrentEditor] = useState("body-editor");

  const editorRef = useRef(null);
  const subEditorRef = useRef(null);

  const intl = useIntl();
  const dispatch = useDispatch();
  // const [value, setValue] = React.useState("");

  // useEffect(() => {
  //   const resetReduxState = () => {
  //     dispatch(resetDiaryNoteReduxState());
  //   };

  //   return resetReduxState;
  // }, []);

  let maxyfiContextValue = useContext(MaxyfiContext);

  let { referenceData, currentOrganization, users, userInfo, space } =
    maxyfiContextValue;

  // let atValues = ;

  const reduxFormData = useSelector((s) => s.customerDairyNoteReducer.formData);
  const noteId = useSelector((s) => s.customerDairyNoteReducer.noteId);

  const actionValueType = useSelector(
    (s) => s.customerDairyNoteReducer.actionType
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...reduxFormData,
    },
  });
  let notesData = watch("notes");
  let { category, is_enable_alert_and_follow_up } = watch();

  // let { notesData } = watch();

  const createDiaryNoteMutation = useMutation(
    (data) =>
      createDiaryNoteService({
        ...data,
        organization: currentOrganization,
        [resourceName]: resourceId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (category && category[0] && category[0].isCreatable) {
          maxyfiContextValue.refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        refetch();
        // dispatch(refetchDiaryNotes());
        refetchActions();

        queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
        queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);

        // dispatch(closeModal());
        // if (resourceName === "customerId") {
        //   dispatch(refetchActions());
        // } else {
        //   dispatch(refetchInvoiceActions());
        // }
        // dispatch(refetchActions());
        // dispatch(refetchInvoiceActions());
      },
    }
  );

  const updateDiaryNoteMutation = useMutation(
    (data) =>
      editDairyNote({
        ...data,
        organization: currentOrganization,
        // [resourceName]: resourceId,
        entity: entity,
        id: resourceId,
        noteId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (category && category[0] && category[0].isCreatable) {
          maxyfiContextValue.refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        refetch();
        dispatch(refetchDiaryNotes());
        // dispatch(closeModal());
        // refetchActions();
        dispatch(refetchActions());
        dispatch(refetchInvoiceActions());
      },
    }
  );

  let isAlertEnable = watch("is_enable_alert_and_follow_up");

  useEffect(() => {
    if (actionValueType === EDIT) {
      setValue("notes", reduxFormData.notes);
      setValue(
        "is_enable_alert_and_follow_up",
        reduxFormData.is_enable_alert_and_follow_up
      );

      if (reduxFormData.category) {
        setValue("category", setSelectValues(reduxFormData.category));
        setValue("type", reduxFormData.type);

        let localDate = utcTimstampToLocalDate(reduxFormData.follow_up_date);
        setValue("follow_up_date", new Date(localDate));
      }
    }
  }, [reduxFormData]);

  const onSubmit = async (data) => {
    delete data.created_at;
    delete data.created_by;
    delete data.is_deleted;
    delete data.is_dismissed;
    delete data._id;
    delete data.modified_at;
    delete data.modified_by;
    delete data.invoiceId;

    let mentionUsers = [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.notes, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    // ...data,
    let values = {
      ...(data.is_enable_alert_and_follow_up
        ? {
            notes: data.notes,
            category:
              actionValueType == EDIT && !data.is_enable_alert_and_follow_up
                ? data.category
                : getSelectValues(data.category),
            mention_users: uniqueMentionedUsers,
            type: data.type,
            is_enable_alert_and_follow_up: data.is_enable_alert_and_follow_up
              ? data.is_enable_alert_and_follow_up
              : false,
            follow_up_date: moment(data.follow_up_date).utc().valueOf(),
          }
        : {
            notes: data.notes,
            category:
              actionValueType == EDIT && !data.is_enable_alert_and_follow_up
                ? data.category
                : getSelectValues(data.category),
            mention_users: uniqueMentionedUsers,
            type: data.type,
            is_enable_alert_and_follow_up: data.is_enable_alert_and_follow_up,
          }),
    };

    if (values.category.length <= 0 || Array.isArray(values.category)) {
      values.category = "general";
    }

    if (actionValueType === EDIT) {
      await updateDiaryNoteMutation.mutateAsync({ ...values });
    } else {
      await createDiaryNoteMutation.mutateAsync({ ...values });
    }
  };

  // AT MENTION STARTS

  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          // let atValues = users.filter((e) => {
          //   e.id !== userInfo._id;
          // });
          //
          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  // AT MENTION ENDS

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingRight: "20px" }}>
        <div style={{ marginBottom: "10px" }}>
          <Controller
            name="notes"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <ReactQuill
                {...field}
                name={field.name}
                id={"editor"}
                ref={editorRef}
                theme="snow"
                // theme="bubble"
                // readOnly={viewOnly}
                value={field.value}
                modules={modules}
                style={{ height: "135px" }}
              />
            )}
          />
        </div>
        <div
          style={{
            // paddingTop: "15px",
            paddingBottom: "20px",
          }}
        >
          <Controller
            name="is_enable_alert_and_follow_up"
            defaultValue={false}
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Enable Alert and Follow-up
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        </div>
        {isAlertEnable ? (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ width: "230px", paddingRight: "30px" }}>
                <Controller
                  control={control}
                  name="category"
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      creatable={creatablePermission ? true : false}
                      clearable={false}
                      requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "notes_category",
                      })}
                      placeholder={intl.formatMessage({
                        id: "notes_category",
                      })}
                      size={SIZE.mini}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      // options={[{ label: "Dispute", id: "dispute" }]}
                      options={referenceData["notes_category"].sort((a, b) =>
                        a.label.localeCompare(b.label)
                      )}
                    />
                  )}
                />
              </div>
              <div style={{ width: "230px" }}>
                <Controller
                  control={control}
                  name="follow_up_date"
                  render={({ field }) => (
                    <DateRangeSelect
                      size={SIZE.mini}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "follow_up_date",
                      })}
                      placeholder={intl.formatMessage({
                        id: " ",
                      })}
                      value={field.value}
                      onChange={(e) => field.onChange(e.date)}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
            </div>
            {/* Types of Notes */}
            <ParagraphSmall
              style={{
                color: "#ADADAD",
                // paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Type of Notes
            </ParagraphSmall>

            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <div style={{ display: "flex", paddingBottom: "20px" }}>
                  {referenceData["notes_type"] &&
                    referenceData["notes_type"].map((i) => {
                      const typeRef = {
                        INFORMATIONAL: {
                          class: "add_note_alert_btn--active",
                          Icon: InfoTag,
                        },
                        ALERT: {
                          class: "cp-action-call-outcome--yellow",
                          Icon: AlertTag,
                        },
                        CRITICAL: {
                          class: "cp-action-call-outcome--red",
                          Icon: CricticalSvg,
                        },
                      };

                      let Icon = typeRef[i.id] ? typeRef[i.id].Icon : null;

                      return (
                        <div
                          {...field}
                          name={field.name}
                          style={{ marginRight: "5px" }}
                          value={field.value}
                          onClick={() => {
                            field.onChange(i.id);
                          }}
                          className={`${
                            field.value === i.id && typeRef[field.value]
                              ? typeRef[field.value].class
                              : "add_note_alert_btn"
                          }`}
                        >
                          <div className="add_diary_alert">
                            <Icon />
                          </div>
                          <HeadingXSmall>{i.label}</HeadingXSmall>
                        </div>
                      );
                    })}
                </div>
              )}
            />
          </>
        ) : (
          <></>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            // paddingTop: "25px",
          }}
        >
          <div
            style={{
              width: "80px",
              paddingRight: "10px",
            }}
          >
            <TextButton
              type="button"
              kind={KIND.tertiary}
              size={SIZE.mini}
              fullWidth
              onClick={() => {
                // setSecondCompo(false);
                // dispatch(closeModal());
                // dispatch(resetDiaryNoteReduxState());
                dispatch(cancelNoteForm());
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ width: "80px" }}>
            <TextButton
              kind={KIND.primary}
              size={SIZE.mini}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              fullWidth
              onClick={() => {
                // setFirstCompo(true);
                // dispatch(closeModal());
              }}
              type="submit"
            >
              Capture
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};
export default NoteFormContainer;
