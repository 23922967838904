import React from "react";

export default function PromisePay({ size = 22, color = "#516BEB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 5.25C12.9 5.175 12.75 5.175 12.75 4.95C12.75 4.725 12.9 4.65 13.125 4.575V5.325V5.25ZM13.425 6.525V5.775C13.65 5.85 13.875 5.925 13.875 6.15C13.875 6.3 13.8 6.525 13.425 6.525ZM16.5 5.55C16.5 7.35 15 8.85 13.2 8.85C11.4 8.85 9.9 7.35 9.9 5.55C9.9 3.75 11.4 2.25 13.2 2.25C15 2.25 16.5 3.75 16.5 5.55ZM13.425 5.4V4.65C13.575 4.65 13.725 4.725 13.875 4.875H13.95C14.025 4.875 14.1 4.8 14.1 4.725C14.1 4.65 14.1 4.65 14.025 4.575C13.875 4.425 13.575 4.35 13.35 4.275V4.05C13.35 3.975 13.275 3.9 13.2 3.9C13.125 3.9 13.05 3.975 13.05 4.05V4.275C12.6 4.275 12.225 4.575 12.225 5.025C12.225 5.475 12.6 5.625 13.05 5.775V6.6C12.825 6.6 12.675 6.525 12.525 6.375C12.525 6.375 12.525 6.375 12.45 6.3C12.375 6.3 12.3 6.375 12.3 6.45C12.3 6.525 12.3 6.525 12.375 6.6C12.6 6.825 12.9 6.9 13.125 6.9V7.125C13.125 7.2 13.2 7.275 13.275 7.275C13.35 7.275 13.425 7.2 13.425 7.125V6.9C14.025 6.825 14.25 6.525 14.25 6.15C14.25 5.625 13.875 5.475 13.425 5.4ZM15.45 11.1C15.375 10.875 15.225 10.65 15.075 10.575L11.1611 12.1696C11.0712 12.2062 10.9751 12.225 10.8781 12.225H7.65C7.425 12.225 7.2 12 7.2 11.775C7.2 11.55 7.425 11.325 7.65 11.325H10.875C11.6116 11.325 11.475 10.65 11.475 10.575C11.475 10.275 11.1493 10.275 11.025 10.275C9.3 10.275 7.8 10.275 6.825 9.15C6.675 9 6.525 8.925 6.45 8.925H3.975V13.425H4.875C5.175 13.425 5.4 13.5 5.625 13.65L8.325 15.675C8.4 15.75 8.55 15.75 8.625 15.75C9.225 15.45 9.975 14.925 10.875 14.4C11.1 14.25 11.325 14.1 11.625 13.95C12.4957 13.4276 13.3664 12.8602 14.1676 12.4566C14.6111 12.2331 15.1529 12.0692 15.375 11.625V11.625C15.525 11.325 15.525 11.25 15.45 11.1ZM2.7 7.65H1.875C1.65 7.65 1.5 7.8 1.5 8.025V13.875C1.5 14.1 1.65 14.25 1.875 14.25H2.7C2.925 14.25 3.075 14.1 3.075 13.875V8.025C3.075 7.8 2.925 7.65 2.7 7.65Z"
        fill={color}
      />
    </svg>
  );
}
