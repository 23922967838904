import React from "react";

const EmailOutine = ({ size = 19, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55484 14.4891C3.20042 14.4891 2.89874 14.3646 2.64979 14.1157C2.40084 13.8667 2.27637 13.565 2.27637 13.2105V4.79032C2.27637 4.43581 2.40084 4.13407 2.64979 3.88512C2.89874 3.63619 3.20042 3.51172 3.55484 3.51172H14.945C15.2994 3.51172 15.6011 3.63619 15.85 3.88512C16.099 4.13407 16.2234 4.43581 16.2234 4.79032V13.2105C16.2234 13.565 16.099 13.8667 15.85 14.1157C15.6011 14.3646 15.2994 14.4891 14.945 14.4891H3.55484ZM15.1742 5.56694L9.59389 9.14964C9.53621 9.17849 9.47947 9.20229 9.4237 9.22104C9.36794 9.23979 9.31 9.24916 9.24991 9.24916C9.18981 9.24916 9.13187 9.23979 9.07611 9.22104C9.02034 9.20229 8.96481 9.17849 8.90952 9.14964L3.32562 5.56694V13.209C3.32562 13.2763 3.34725 13.3316 3.39053 13.3749C3.4338 13.4182 3.4891 13.4398 3.55641 13.4398H14.9434C15.0107 13.4398 15.066 13.4182 15.1093 13.3749C15.1526 13.3316 15.1742 13.2763 15.1742 13.209V5.56694ZM9.24991 8.26554L15.0437 4.56097H3.45615L9.24991 8.26554ZM3.32562 5.73065V4.93171V4.94958V4.56097V4.95037V4.92657V5.73065Z"
        fill={color}
      />
    </svg>
  );
};

export default EmailOutine;
