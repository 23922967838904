import * as React from "react";
import { Button, KIND } from "baseui/button";
import { TextButton, SIZE } from "../../../../../../components/TextButton";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { useMemo, useEffect, useContext } from "react";
import { getGridStructure } from "../../../../../../services";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import columnMapper from "../../../../../../utils/mapper";
import { HeadingXLarge } from "baseui/typography";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { customerInvoiceOverview } from "../../../../../../services";
import {
  selectedInvoice,
  isOpenSelectedDispute,
} from "../../../../../../redux/customerOverview/dispute/action";
import { useDispatch } from "react-redux";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { useSelector } from "react-redux";

const SelectDisputePopUp = ({ getParams }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});
  const [width, setWidth] = useState(0);

  let ref = React.useRef();

  const isOpenSelected = useSelector((s) => s.customerDisputeReducer.isOpen);

  const close = () => {
    setIsOpen(false);
  };
  const [columnData, setColumnData] = React.useState([]);
  const dispatch = useDispatch();
  let { currentOrganization } = useContext(MaxyfiContext);
  const { id } = useParams();

  // const [getSearchParams, setGetSearchParams] = useSearchParams();

  // const getParams = getSearchParams.get("customers");

  const [deSelect, setDeselect] = useState(false);

  // setStoreSelectedValue(selectedFlatRows);

  // Form

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "co_action_dispute_select_invoice",
      });

      setColumnData(userListVal.data.doc.table);
    };
    userListApi();
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);
  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: true,
      isExpandedHeader: false,
      // width: width,
      width: 500,
    });
    return columns;
  }, [columnData]);

  // API is passing in useQuery
  const { data, isFetched, isError } = useQuery(
    [
      `SELECT_DISPUTE_INVOICE${getParams}`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: querySortBy,
        filters: queryFilter,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      filters = {
        ...filters,
      };
      return await customerInvoiceOverview({
        pageIndex,
        sortBy,
        pageSize,
        filters,
        organization: currentOrganization,
        customer_id: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const tabledata = useMemo(
    () => (isFetched && !isError && data.data.docs ? data.data.docs : []),
    [isFetched, data]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    prepareRow,
    setColumnOrder,
    allColumns,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  return (
    <React.Fragment>
      <div style={{ width: "180px" }}>
        <TextButton
          size={SIZE.default}
          kind={KIND.secondary}
          fullWidth
          // onClick={() => setIsOpen(true)}
          onClick={() => dispatch(isOpenSelectedDispute(true))}
          type="button"
        >
          Select Dispute Invoice
        </TextButton>
      </div>
      {/* <Button onClick={() => setIsOpen(true)}>Open Modal</Button> */}
      <Modal
        // onClose={close}
        // isOpen={isOpen}
        closeable={false}
        isOpen={isOpenSelected}
        overrides={{
          Root: {
            style: ({ $theme }) => ({}),
          },
          Dialog: {
            style: ({ $theme }) => ({
              width: "700px",
            }),
          },
          DialogContainer: {
            style: ({ $theme }) => ({}),
          },
        }}
      >
        <div ref={ref}>
          <HeadingXLarge style={{ textAlign: "center", padding: "35px 0px" }}>
            Select Dispute Invoice
          </HeadingXLarge>
          <div
            className="customer-profile-tab__table"
            style={{ gridColumn: "1/6", gridRow: "2/12" }}
            // ref={ref}
          >
            <div className="cp-tab__table-wrapper" {...getTableProps()}>
              <div className="cp-tab__table-header">
                {headerGroups.map((headerGroup) => (
                  <>
                    <div
                      // className="cp-tab__table-header-wrapper"
                      className="cp-tab__table-header-wrapper-dispute_table"
                      style={{
                        backgroundColor: "#F9F9F9",
                        border: "1px solid #CDCED9",
                        display: "flex",
                        flex: "1 0 auto",
                        minWidth: "0px",
                      }}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      <div
                        className="cp-tab__table-header-content"
                        style={{ height: "" }}
                      >
                        {headerGroup.headers.map((column, index) => (
                          <div
                            className="cp-tab__table-header-cell-dispute"
                            // style={{
                            //   boxSizing: "border-box",
                            //   flex: "150 0 auto",
                            //   minWidth: "0px",
                            //   width: "150px",
                            //   position: "relative",
                            // }}
                          >
                            <div
                              className="cp-tab__table-header-cell-review-promise"
                              //   style={{ width: "0px" }}
                              {...column.getHeaderProps()}
                            >
                              {column.render("Header")}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ))}
              </div>

              <div className="cp-tab__table-body" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();

                  return (
                    <React.Fragment key={rowProps.key}>
                      <div
                        className="cp-tab__table-body-content"
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <div
                              name="rows"
                              className="cp-tab__table-body-cell"
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <ModalFooter>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              justifyContent: "center",
              paddingBottom: "25px",
            }}
          >
            <div style={{ width: "125px", marginRight: "20px" }}>
              <TextButton
                fullWidth
                kind="tertiary"
                onClick={
                  // () => setIsOpen(false)
                  () => {
                    dispatch(isOpenSelectedDispute(false));
                  }
                  // close()
                }
                size={SIZE.compact}
                type="button"
              >
                Cancel
              </TextButton>
            </div>
            <div
              style={{ width: "125px" }}
              onClick={() => {
                dispatch(selectedInvoice(selectedFlatRows));
                // close();
                // setIsOpen(false);
              }}
            >
              <TextButton fullWidth size={SIZE.compact} type="submit">
                Add
              </TextButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default SelectDisputePopUp;
