import React from "react";

const PauseSvgError = () => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.88717 11.6834C1.65801 11.8362 1.42502 11.8438 1.18822 11.7063C0.95141 11.5688 0.833008 11.3625 0.833008 11.0875V0.775041C0.833008 0.500041 0.95141 0.293791 1.18822 0.156291C1.42502 0.0187911 1.65801 0.02643 1.88717 0.179208L9.99967 5.35837C10.2136 5.49587 10.3205 5.68685 10.3205 5.93129C10.3205 6.17574 10.2136 6.36671 9.99967 6.50421L1.88717 11.6834Z"
        fill="#FD372A"
      />
    </svg>
  );
};

export default PauseSvgError;
