import { HeadingXLarge, ParagraphLarge, LabelLarge } from "baseui/typography";
import Email from "../../../../../assets/img/svg/CustomerTemplate/Email";
import Info from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import InfoTag from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import InfoPortal from "../../../../../assets/img/svg/InfoPortal";
import AllActionPopOver from "../AllActionPopOver";
import { isOpenModal } from "../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../components/TextBox";
import { TextButton, SIZE, KIND } from "../../../../../components/TextButton";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { TextArea } from "../../../../../components/TextArea";
import Sms from "../../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import { SelectBox } from "../../../../../components/SelectBox";
import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import React, { useContext, useState } from "react";
import { COMMUNICATION_TEMPLATE } from "../../../../../constants";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getCommunicationTemplateList,
  getCustomerContactDetail,
  getOrganization,
} from "../../../../../services";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import getSelectValues from "../../../../../utils/getSelectValues";
import NextActionForward from "../../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import DateRangeSelect from "../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import CheckBox from "../../../../../components/CheckBox/CheckBox";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { STYLE_TYPE } from "baseui/checkbox";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import Letter from "../../../../../assets/img/svg/CustomerTemplate/Letter";
import CheckBoxBaseweb from "../../../../../components/CheckBoxBaseweb";

import { updateCustomerAction } from "../../../../../services";
import { refetchActions } from "../../../../../redux/actions";
import { useParams } from "react-router-dom";
import { CUSTOMER_OVERVIEW } from "../../../../../constants";
import { ModalContext } from "../../../../../providers/ModalProvider";
import { useEffect } from "react";
import setSelectValues from "../../../../../utils/setSelectValues";
import Warning from "../../../../../assets/img/warning.png";
import _ from "lodash";

const NextAction = () => {
  const {
    referenceData,
    currentOrganization,
    userInfo,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const [settingData, setSettingData] = useState(null);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [customerContactsData, setCustomerContactsData] = useState([]);
  const [autoLocation, setAutoLocation] = useState([]);
  let [confirmationTemplates, setConfirmationTemplates] = useState([]);
  let [followUpTemplates, setFollowUpTemplates] = useState([]);
  const getParams = getSearchParams.get("customers");
  const queryClient = useQueryClient();
  const refetchAction = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const { handleModal } = useContext(ModalContext);

  let {
    next_action_type,
    is_send_confirmation_message,
    is_send_action_follow_up,
    location,
  } = watch();

  let selectedLocation = getSelectValues(location);

  let [confirmationMessageType, followUpType] = watch([
    "confirmation_message_type",
    "action_follow_up_type",
  ]);

  let selectId = getSelectValues(next_action_type);

  let { recipient_ref } = watch();

  let filteringPromiseUsers = customerContactsData?.filter(
    (i) => i._id === getSelectValues(recipient_ref)
  );

  // let EmailUser =
  //   filteringPromiseUsers && filteringPromiseUsers[0]
  //     ? filteringPromiseUsers[0].email[0]?.value?.length
  //     : null;

  // let PhoneUser =
  //   filteringPromiseUsers && filteringPromiseUsers[0]
  //     ? filteringPromiseUsers[0].phone[0]?.country_code?.length &&
  //       filteringPromiseUsers[0].phone[0]?.value?.toString().length
  //     : null;

  const addNextAction = useMutation(
    (formValues) =>
      updateCustomerAction({
        ...formValues,
        customerId: getParams,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        dispatch(refetchActions());
        reset();
        dispatch(isOpenModal(false));
        queryClient.invalidateQueries(
          `${CUSTOMER_OVERVIEW}-${currentOrganization}`
        );
      },
    }
  );

  const { data, isFetched } = useQuery(
    `customer-contacts-${getParams}_${refetchAction}`,
    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let values;
    if (selectId === "FIELD_VISIT") {
      values = {
        recipient_ref: getSelectValues(data.recipient_ref),
        ...(data.location && data.location[0] && data.location[0].id === "other"
          ? { custom_location: data.custom_location }
          : {}),
        visit_address: getSelectValues(data.location),
        next_action_type: getSelectValues(data.next_action_type),

        next_action_time: getSelectValues(data.next_action_time),
        next_action_date: moment(data.next_action_date)
          .startOf("day")
          .utc()
          .valueOf(),
        is_hold_workflow: data?.is_hold_workflow,
        is_send_confirmation_message: data?.is_send_confirmation_message,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    if (selectId !== "FIELD_VISIT") {
      values = {
        // ...data,
        next_action_type: getSelectValues(data.next_action_type),
        next_action_date: moment
          .tz(
            `${data.next_action_date?.getFullYear()}-${
              data.next_action_date?.getMonth() + 1
            }-${data.next_action_date?.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        comment: data.comment,
      };
    }
    await addNextAction.mutateAsync(values);
  };

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags,
        type,
      },
      organization: currentOrganization,

      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: ["NEXT_ACTION_FIELD_VISIT"],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  useEffect(() => {
    if (followUpType && followUpType[0] && followUpType[0].id) {
      setFollowUpTemplates([]);
      getTemplates({
        tags: ["NEXT_ACTION_FIELD_VISIT"],
        type: followUpType[0].id,
        setter: setFollowUpTemplates,
      });
    }
  }, [followUpType]);

  useEffect(() => {
    let customerContact = [];
    if (data) {
      let custContact = data && data?.data && data?.data?.docs;
      customerContact = custContact.filter(
        (e) => e && e?.phone && e?.phone?.length > 0
      );
    }
    setCustomerContactsData(customerContact);
  }, [data]);

  useEffect(async () => {
    await getOrganization({ organization: currentOrganization }).then((res) => {
      setSettingData(res.data.doc || {});
    });
  }, []);

  useEffect(() => {
    if (customerContactsData && Array.isArray(customerContactsData)) {
      if (customerContactsData) {
        let differentLocations = [];
        customerContactsData
          .filter((k) => k.address && k.address.length > 0)
          .map((e, i) => {
            let filteredAddress = e && e.address && e.address[0];
            let lineOne = filteredAddress ? filteredAddress.line_1 : "";
            let lineTwo = filteredAddress ? filteredAddress.line_2 : "";
            let city = filteredAddress ? filteredAddress.city : "";
            let state = filteredAddress ? filteredAddress.state : "";
            let country = filteredAddress ? filteredAddress.country : "";
            let zipCode = filteredAddress ? filteredAddress.zip_code : "";

            differentLocations.push({
              id: e._id,
              label: `${e.first_name} ${lineOne} ${lineTwo} ${city} ${state} ${country} ${zipCode}`,
            });
          });
        differentLocations.push(
          { id: "organization", label: "Organization Address" },
          { id: "other", label: "Other Location" }
        );
        setAutoLocation(differentLocations);
      }

      // setAutoLocation();

      let escalationContact =
        customerContactsData &&
        customerContactsData?.filter(
          (e) => e?.designation === "escalation_contact"
        );
      let primaryContact =
        customerContactsData &&
        customerContactsData?.filter(
          (e) => e?.designation === "primary_contact"
        );

      if (
        primaryContact &&
        Array.isArray(primaryContact) &&
        primaryContact?.length > 0
      ) {
        let primaryFirstContactFilter =
          primaryContact &&
          primaryContact.filter(
            (e) => e && e?.address && e?.address?.length > 0
          );
        if (primaryContact && primaryFirstContactFilter[0]) {
          let primaryFirstContact =
            primaryFirstContactFilter && primaryFirstContactFilter[0]
              ? primaryFirstContactFilter[0]
              : {};

          let primaryContactAddress =
            primaryFirstContact?.address && primaryFirstContact?.address[0]
              ? primaryFirstContact?.address[0]
              : [];

          let addressLineOne = primaryContactAddress?.line_1
            ? primaryContactAddress?.line_1
            : "";

          let addressLineTwo = primaryContactAddress.line_2
            ? primaryContactAddress.line_2
            : "";

          let city = primaryContactAddress && primaryContactAddress?.city;
          let state = primaryContactAddress && primaryContactAddress?.state;
          let country = primaryContactAddress && primaryContactAddress?.country;
          let zipCode =
            primaryContactAddress && primaryContactAddress?.zip_code;

          let primaryContactLabel = `${primaryFirstContact?.first_name} ${
            addressLineOne ? addressLineOne : ""
          } ${addressLineTwo ? addressLineTwo : ""} ${city ? city : ""} ${
            state ? state : ""
          }  ${country ? country : ""}
          ${zipCode ? zipCode : ""}
          `;

          setValue("location", [
            {
              id: primaryFirstContact?._id,
              label: `${primaryContactLabel}`,
            },
          ]);

          setValue("recipient_ref", [
            {
              id: primaryFirstContact?._id,
              label: `${primaryContactLabel}`,
            },
          ]);
        }
      } else if (
        escalationContact &&
        Array.isArray(escalationContact) &&
        escalationContact?.length > 0
      ) {
        let escalationFirstContactFilter =
          escalationContact &&
          escalationContact.filter(
            (e) => e && e?.address && e?.address?.length > 0
          );

        if (escalationContact && escalationFirstContactFilter[0]) {
          let primaryFirstContact =
            escalationFirstContactFilter && escalationFirstContactFilter[0]
              ? escalationFirstContactFilter[0]
              : {};

          let primaryContactAddress =
            primaryFirstContact?.address && primaryFirstContact?.address[0]
              ? primaryFirstContact?.address[0]
              : [];

          let addressLineOne = primaryContactAddress?.line_1
            ? primaryContactAddress?.line_1
            : "";

          let addressLineTwo = primaryContactAddress?.line_2
            ? primaryContactAddress?.line_2
            : "";

          let city = primaryContactAddress && primaryContactAddress?.city;
          let state = primaryContactAddress && primaryContactAddress?.state;
          let country = primaryContactAddress && primaryContactAddress?.country;
          let zipCode =
            primaryContactAddress && primaryContactAddress?.zip_code;

          let primaryContactLabel = `${primaryFirstContact?.first_name} ${
            addressLineOne ? addressLineOne : ""
          } ${addressLineTwo ? addressLineTwo : ""} ${city ? city : ""} ${
            state ? state : ""
          }  ${country ? country : ""}
          ${zipCode ? zipCode : ""}
          `;

          setValue("location", [
            {
              id: primaryFirstContact._id,
              label: `${primaryContactLabel}`,
            },
          ]);

          setValue("recipient_ref", [
            {
              id: primaryFirstContact?._id,
              label: `${primaryContactLabel}`,
            },
          ]);
        }
      } else {
        setValue("location", []);
      }
    }
  }, [customerContactsData]);

  let type = referenceData["template_type"];

  useEffect(() => {
    if (
      settingData &&
      settingData.default_configuration &&
      settingData.default_configuration?.next_action_field_visit
    ) {
      if (selectId === "FIELD_VISIT") {
        let fieldKey = Object.keys(
          settingData.default_configuration?.next_action_field_visit
        );

        // let setData =
        //   settingData &&
        //   settingData?.default_configuration &&
        //   settingData?.default_configuration?.next_action_field_visit;

        // setValue("is_hold_workflow", setData.is_hold_workflow);
        // setValue(
        //   "is_send_confirmation_message",
        //   setData.is_send_confirmation_message
        // );
        // setValue("is_send_action_follow_up", setData.is_send_action_follow_up);
        // setValue(
        //   "confirmation_message_type",
        //   setSelectValues(setData.confirmation_message_type)
        // );
        // setValue(
        //   "confirmation_message_template_id",
        //   setSelectValues(setData.confirmation_message_template_id)
        // );
        // setValue(
        //   "action_follow_up_type",
        //   setSelectValues(setData.action_follow_up_type)
        // );
        // setValue(
        //   "action_follow_up_template_id",
        //   setSelectValues(setData.action_follow_up_template_id)
        // );

        //
        fieldKey.map((field) => {
          if (
            field == "action_follow_up_template_id" ||
            field == "action_follow_up_type" ||
            field == "confirmation_message_type" ||
            field == "confirmation_message_template_id"
          ) {
            setValue(
              field,
              setSelectValues(
                settingData.default_configuration.next_action_field_visit[field]
                  ? settingData.default_configuration.next_action_field_visit[
                      field
                    ]
                  : " "
              )
            );
          } else {
            setValue(
              field,
              settingData.default_configuration.next_action_field_visit[field]
            );
          }
        });
      }
    }
  }, [next_action_type]);

  let EmailUser =
    data &&
    data?.data &&
    Array.isArray(data?.data?.docs) &&
    data?.data?.docs.some((e) => e.is_valid_email === true);
  let PhoneUser =
    data &&
    data?.data &&
    Array.isArray(data?.data?.docs) &&
    data?.data?.docs.some((e) => e.is_valid_phone === true);

  let mappinTypeValue =
    referenceData && referenceData["template_type"]
      ? referenceData["template_type"]
          .filter(({ id }) => id != "letter")
          .map((e) => {
            if (e.id == "email" && !EmailUser) {
              return { ...e, disabled: true };
            } else if (e.id === "sms" && !PhoneUser) {
              return { ...e, disabled: true };
            } else if (
              e.id === "sms" &&
              !currentDefaultFormatDetails.is_sms_configured
            ) {
              return { ...e, disabled: true };
            } else if (
              e.id === "whatsapp" &&
              !currentDefaultFormatDetails?.is_whatsapp_configured
            ) {
              return { ...e, disabled: true };
            }
            return e;
          })
      : [];

  let otherOptionLocation = [
    { id: "organization", label: "Organization Address" },
    { id: "other", label: "Other Location" },
  ];

  useEffect(() => {
    let findCurrentRecipient =
      recipient_ref && recipient_ref[0] && recipient_ref[0].id
        ? data &&
          data?.data &&
          Array.isArray(data?.data?.docs) &&
          data?.data?.docs.find((fr) => fr._id === recipient_ref[0].id)
        : [];

    const paymentPlanConfig = _.get(
      settingData,
      "default_configuration.next_action_field_visit.messages",
      []
    );

    const isConfigNextAction = _.get(
      settingData,
      "default_configuration.next_action_field_visit.is_hold_workflow",
      false
    );

    if (isConfigNextAction) {
      setValue("is_hold_workflow", true);
    }
    paymentPlanConfig?.reverse()?.map((e) => {
      if (
        (e.type === "email" &&
          findCurrentRecipient &&
          findCurrentRecipient.is_valid_email) ||
        (e.type === "sms" &&
          findCurrentRecipient &&
          findCurrentRecipient.is_valid_sms) ||
        (e.type === "whatsapp" &&
          findCurrentRecipient &&
          findCurrentRecipient.is_valid_whatsapp)
      ) {
        if (e.confirmation_template.length > 0) {
          setValue("is_send_confirmation_message", true);
          setValue("confirmation_message_type", setSelectValues(e.type));
          setValue(
            "confirmation_message_template_id",
            setSelectValues(e.confirmation_template)
          );
        }
        if (e.follow_up_template.length > 0) {
          setValue("is_send_action_follow_up", true);
          setValue("action_follow_up_type", setSelectValues(e.type));
          setValue(
            "action_follow_up_template_id",
            setSelectValues(e.follow_up_template)
          );
        }
      }
    });
  }, [recipient_ref, settingData]);

  return (
    <form
      style={{
        gridColumn: "2/5",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <div
          // onSubmit={handleSubmit(onSubmit)}
          style={{
            // display: "grid",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // gridTemplateColumns: "repeat(10,50fr)",
            // gridTemplateRows: "repeat(12,10fr)",
            height: "100%",
          }}
        >
          <div style={{ padding: "15px 35px" }}>
            {/* Section 1 */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "5px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <NextActionForward />
                <HeadingXLarge>Next Action</HeadingXLarge>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "0px 35px",
              height: "1000%",
              overflow: "scroll",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <ParagraphLarge style={{ paddingBottom: "15px" }}>
                Choose the next action you wish to perform{" "}
                <sup style={{ color: "#FD372A" }}>*</sup>
              </ParagraphLarge>
              <div style={{ width: "270px" }}>
                <Controller
                  defaultValues={[]}
                  name="next_action_type"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "next_action_type",
                      })}
                      placeholder={intl.formatMessage({
                        id: "next_action_type",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["workflow_type"]}
                      // requiredAstric={true}
                    />
                  )}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: "120px" }}
            >
              <ParagraphLarge style={{ paddingBottom: "15px" }}>
                Choose the future action date{" "}
                <sup style={{ color: "#FD372A" }}>*</sup>
              </ParagraphLarge>
              <div style={{ width: "270px" }}>
                <Controller
                  name="next_action_date"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <DateRangeSelect
                      clearable={false}
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "next_action_date",
                      })}
                      placeholder={intl.formatMessage({
                        id: " ",
                      })}
                      value={field.value}
                      onChange={(e) => field.onChange(e.date)}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
            </div>

            {selectId === "FIELD_VISIT" ? (
              <>
                <div style={{ width: "270px" }}>
                  <Controller
                    defaultValues={[]}
                    name="next_action_time"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        size={SIZE.default}
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "next_action_time",
                        })}
                        placeholder={intl.formatMessage({
                          id: "next_action_time",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["time"]}
                        // options={[
                        //   { id: "field_visit", label: "Field Visit" },
                        // ]}
                        // requiredAstric={true}
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            {selectId === "FIELD_VISIT" ? (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    // justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "300px", paddingTop: "15px" }}>
                    <Controller
                      name="location"
                      control={control}
                      // rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          size={SIZE.default}
                          {...field}
                          name={field.name}
                          // requiredAstric={true}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "location",
                          })}
                          placeholder={intl.formatMessage({
                            id: "location",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          // options={autoLocation}
                          options={
                            data &&
                            data.data &&
                            data.data.docs &&
                            Array.isArray(data.data.docs)
                              ? data.data.docs.reduce((prev, curr) => {
                                  let {
                                    phone,
                                    landline,
                                    email,
                                    address,
                                    ...rest
                                  } = curr;
                                  let nestedContacts = [...address]
                                    .map(({ status, ...nRest }) => {
                                      // let designation =
                                      //   referenceData &&
                                      //   referenceData["recipient_type"]
                                      //     ? referenceData["recipient_type"].find(
                                      //         (rf) => rf?.id === curr?.designation
                                      //       )
                                      //     : "";

                                      return {
                                        label: `${
                                          curr.first_name ? curr.first_name : ""
                                        } ${
                                          nRest?.line_1 ? nRest?.line_1 : ""
                                        } ${
                                          nRest?.line_2 ? nRest?.line_2 : ""
                                        } ${nRest?.city ? nRest?.city : ""} ${
                                          nRest?.state ? nRest?.state : ""
                                        } ${
                                          nRest?.country ? nRest?.country : ""
                                        } ${
                                          nRest?.zip_code ? nRest?.zip_code : ""
                                        }`,
                                        id: nRest?._id,
                                        recipient_id: curr?._id,
                                        status,
                                      };
                                    })
                                    .filter(
                                      (e) =>
                                        e.status === "VALID" ||
                                        e.status === "LEAD"
                                    );

                                  return [
                                    ...prev,
                                    ...nestedContacts,
                                    ...otherOptionLocation,
                                  ];
                                }, [])
                              : []
                          }
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "300px", paddingTop: "15px" }}>
                    <Controller
                      name="recipient_ref"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          size={SIZE.default}
                          {...field}
                          name={field.name}
                          // requiredAstric={true}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "contact_for_notifications",
                          })}
                          placeholder={intl.formatMessage({
                            id: "contact_for_notifications",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={customerContactsData
                            ?.sort((a, b) =>
                              a?.first_name.localeCompare(b?.first_name)
                            )
                            ?.map((e) => {
                              let designation = referenceData[
                                "recipient_type"
                              ].find((rf) => rf.id === e.designation);

                              let label = "";
                              label =
                                designation && designation.label
                                  ? designation.label
                                  : "";

                              let phone = "";

                              if (
                                e.phone &&
                                e.phone[0] &&
                                e.phone[0].value &&
                                e.phone[0].country_code
                              ) {
                                phone = `${e.phone[0].country_code} ${e.phone[0].value}`;
                              }

                              return {
                                id: e._id,
                                label: `${
                                  e.first_name || ""
                                } (${phone}) - ${label}`,
                                number: phone,
                              };
                            })}
                        />
                      )}
                    />
                  </div>
                </div>

                {selectedLocation === "other" ? (
                  <>
                    <div>
                      <Controller
                        name="custom_location"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextBox
                              {...field}
                              requiredAstric={true}
                              //   disabled={isDisable}
                              fullWidth
                              name={field.name}
                              size={SIZE.default}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "custom_location",
                              })}
                              placeholder={intl.formatMessage({
                                id: "custom_location",
                              })}
                              value={field.value}
                            />
                          </>
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div style={{ padding: "10px 0px" }}>
                  <Controller
                    defaultValues={false}
                    name="is_hold_workflow"
                    control={control}
                    render={({ field }) => (
                      <CheckBoxBaseweb
                        {...field}
                        // disabled={true}
                        checked={field.value}
                        labelPlacement={LABEL_PLACEMENT.right}
                      >
                        <FormattedMessage
                          id="hold_workflow_till_field_visit_date"
                          // id="hold_workflow_till_call_back_date"
                        >
                          hold_workflow_till_field_visit_date
                        </FormattedMessage>
                      </CheckBoxBaseweb>
                    )}
                  />
                </div>

                <div
                  className="cp-action-message-confirmation"
                  style={{ margin: "7px 0px" }}
                >
                  <div className="cp-message-confirmation-checkbox">
                    <Controller
                      defaultValues={false}
                      name="is_send_confirmation_message"
                      control={control}
                      render={({ field }) => (
                        <CheckBoxBaseweb
                          {...field}
                          checked={field.value}
                          labelPlacement={LABEL_PLACEMENT.right}
                          // disabled={!EmailUser && !PhoneUser}
                        >
                          <FormattedMessage id="send_confirmation_message">
                            send_confirmation_message
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      )}
                    />
                  </div>
                  {is_send_confirmation_message ? (
                    <>
                      <div className="cp-message-confirmation-type">
                        <Controller
                          defaultValues={[]}
                          name="confirmation_message_type"
                          control={control}
                          // rules={{ required: "Required" }}
                          render={({ field }) => (
                            <SelectBox
                              {...field}
                              clearable={false}
                              name={field.name}
                              fullWidth
                              size={SIZE.compact}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "comm_type",
                              })}
                              placeholder={intl.formatMessage({
                                id: "comm_type",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                if (
                                  field?.value?.[0]?.id != e?.value?.[0]?.id
                                ) {
                                  setValue(
                                    "confirmation_message_template_id",
                                    []
                                  );
                                }
                                field.onChange(e.value);
                              }}
                              // options={
                              //   referenceData["template_type"].filter(
                              //     (e) => e.id !== LETTER
                              //   ) || []
                              // }
                              options={mappinTypeValue}
                            />
                          )}
                        />
                      </div>
                      {/* TODO: TEMPLATE TYPE */}
                      <div className="cp-action-promise-pay-con">
                        <Controller
                          name="confirmation_message_template_id"
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <SelectBox
                              {...field}
                              clearable={false}
                              name={field.name}
                              fullWidth
                              size={SIZE.compact}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "field_visit_confirmation",
                              })}
                              placeholder={intl.formatMessage({
                                id: "field_visit_confirmation",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              options={confirmationTemplates || []}
                            />
                          )}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div
                  className="cp-action-message-confirmation"
                  style={{ margin: "7px 0px" }}
                >
                  <div className="cp-message-confirmation-checkbox">
                    <Controller
                      defaultValues={false}
                      name="is_send_action_follow_up"
                      control={control}
                      render={({ field }) => (
                        <CheckBoxBaseweb
                          {...field}
                          checked={field.value}
                          labelPlacement={LABEL_PLACEMENT.right}
                          // disabled={!EmailUser && !PhoneUser}
                        >
                          <FormattedMessage id="send_field_visit_promise_follow_up_message">
                            {/* Send Field Visit Follow up Message */}
                            send_field_visit_promise_follow_up_message
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      )}
                    />
                  </div>
                  {is_send_action_follow_up ? (
                    <>
                      <div className="cp-message-confirmation-type">
                        <Controller
                          name="action_follow_up_type"
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <SelectBox
                              {...field}
                              clearable={false}
                              name={field.name}
                              fullWidth
                              size={SIZE.compact}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "comm_type",
                              })}
                              placeholder={intl.formatMessage({
                                id: "comm_type",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                if (
                                  field?.value?.[0]?.id != e?.value?.[0]?.id
                                ) {
                                  setValue("action_follow_up_template_id", []);
                                }
                                field.onChange(e.value);
                              }}
                              // options={
                              //   referenceData["template_type"].filter(
                              //     (e) => e.id !== LETTER
                              //   ) || []
                              // }
                              options={mappinTypeValue}
                            />
                          )}
                        />
                      </div>
                      <div className="cp-action-promise-pay-con">
                        <Controller
                          defaultValues={[]}
                          name="action_follow_up_template_id"
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <SelectBox
                              {...field}
                              clearable={false}
                              name={field.name}
                              fullWidth
                              size={SIZE.compact}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "field_visit_follow_up_message",
                              })}
                              placeholder={intl.formatMessage({
                                id: "field_visit_follow_up_message",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              options={followUpTemplates || []}
                            />
                          )}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <></>
            )}

            <div style={{ paddingTop: "15px" }}>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextArea
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "user_comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "user_comments",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
          <div
            style={{
              background: "#F9F9F9",
              // width: "100%",
              height: "70px",
              padding: "15px",
              borderTop: "1px solid #CDCED9",
              gridRow: "12/13",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ width: "125px", height: "36px" }}>
                  <TextButton
                    size={SIZE.compact}
                    fullWidth
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => {
                      // dispatch(emptyState());
                      // dispatch(isOpenModal(false));
                      handleModal({
                        title: "",
                        content: "Would you like to cancel the operation?",
                        successCallback: () => {
                          dispatch(isOpenModal(false));
                        },
                        formContent: () => {
                          return <></>;
                        },
                        cancelCallback: () => {
                          // dispatch(isOpenModal(false));
                        },
                        kind: "primary",
                        isChildren: false,
                        image: Warning,
                        successButtonType: "error",
                        buttonText: "Yes",
                        closeButtonText: "Cancel",
                        isCloseAble: false,
                      });
                    }}
                  >
                    Cancel
                  </TextButton>
                </div>{" "}
                <div style={{ width: "125px", height: "36px" }}>
                  <TextButton
                    size={SIZE.compact}
                    fullWidth
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Save
                  </TextButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </form>
  );
};

export default NextAction;
