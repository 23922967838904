import { HeadingXSmall, ParagraphMedium } from "baseui/typography";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import moment from "moment";
import CallSvg from "../../../assets/img/svg/CusOverviewActionsIcon/CallSvg";
import MessageSvg from "../../../assets/img/svg/CusOverviewActionsIcon/MessageSvg";
import DisputeSvg from "../../../assets/img/svg/CusOverviewActionsIcon/DisputeSvg";
import ReviewPromiseSvg from "../../../assets/img/svg/CusOverviewActionsIcon/ReviewPromiseSvg";
import PromiseToPayN from "../../../assets/img/svg/CusOverviewActionsIcon/PromiseToPayN";
import EmailSvgN from "../../../assets/img/svg/CusOverviewActionsIcon/EmailSvgN";
import SmsSvgN from "../../../assets/img/svg/CusOverviewActionsIcon/SmsSvgN";
import NextActionForward from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import generateInDaysMessage from "../../../utils/generateInDaysMessage";
import { useSelector, useDispatch } from "react-redux";
import { getActionData } from "../../../redux/actions";
import { isOpenModal } from "../../../redux/actions";
import { invoiceTabMotion } from "../../../redux/invoiceOverview/action";

const OverviewActions = ({ data }) => {
  const dispatch = useDispatch();

  //
  let {
    last_action_name,
    last_action_date,
    last_action_type,
    next_action_name,
    next_action_date,
    next_action_type,
  } = data;
  let { currentOrganization, referenceData } = useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;
  const PaymentDatas = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails.promise_to_pay
  );

  let lastActionFinalValues =
    moment.utc(data?.last_action_date).diff(moment().utc(), "days", true) || 0;

  lastActionFinalValues = Math.ceil(lastActionFinalValues);

  let lastActionDaysLeftMessage = "";

  let filteredPromiseData = PaymentDatas?.filter(
    (i) => i.status === "IN_PROGRESS"
  );
  let inProgressPromiseToPay = PaymentDatas?.filter(
    (i) => i.status === "IN_PROGRESS"
  );

  if (lastActionFinalValues == 0) {
    lastActionDaysLeftMessage = "(Today)";
  } else {
    lastActionDaysLeftMessage = `(${Math.abs(lastActionFinalValues)} days ago)`;
  }

  let isShowNextAction = false;

  if (next_action_name && next_action_date) {
    isShowNextAction = true;
  }

  let promiseDataSort = filteredPromiseData?.sort((a, b) => {
    return a.promise_date - b.promise_date;
  });

  let lengthPayment = promiseDataSort ? promiseDataSort[0] : {};

  let DisputeDatas = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails.disputes
  );

  let notClosedDispute = DisputeDatas?.filter((i) => i.status !== "CLOSED");

  let filteredDisputeData = DisputeDatas?.filter((i) => i.status !== "CLOSED");
  let sortedData = filteredDisputeData?.sort((a, b) => {
    return a.review_date - b.review_date;
  });
  let DisputeReviewDate = sortedData ? sortedData[0] : {};

  let disputeReviewDate = DisputeReviewDate?.review_date;
  let paymentReviewDate = lengthPayment?.promise_date;
  let NextActionDaysDiff = () => {
    let daysLeft = isShowNextAction
      ? moment
          .utc(next_action_date)
          ?.tz(timeZone)
          ?.diff(moment.tz(timeZone), "days", true)
      : 0;

    daysLeft = Math.ceil(daysLeft);

    if (daysLeft == 0) {
      return (
        <span
          style={{
            color: "#FD372A",
            fontSize: "11px",
            fontWeight: "400",
            backgroundColor: "#FFF5F4",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          Due Today
        </span>
      );
    } else if (daysLeft > 0) {
      return (
        <span style={{ color: "#ADADAD", fontSize: "11px", fontWeight: "400" }}>
          (In {daysLeft} day{daysLeft > 1 ? "s" : ""})
        </span>
      );
    } else {
      return (
        <span
          style={{
            color: "#FD372A",
            fontSize: "11px",
            fontWeight: "400",
            backgroundColor: "#FFF5F4",
            padding: "3px",
            borderRadius: "5px",
          }}
        >
          OverDue
        </span>
      );
    }
  };

  const LastActionTypes = ({ type }) => {
    switch (type) {
      case "call":
        return <CallSvg />;

      case "sms":
        return <MessageSvg />;

      case "dispute":
        return <DisputeSvg />;

      case "email":
        return <EmailSvgN />;

      case "promise":
        return <PromiseToPayN />;

      case "review_promise":
        return <PromiseToPayN />;

      case "review_dispute":
        return <DisputeSvg />;

      case "NEXT_ACTION":
        return <NextActionForward />;

      default:
        return null;
    }
  };
  const ActionTypes = ({ type }) => {
    switch (type) {
      case "call":
        return <CallSvg />;

      case "sms":
        return <SmsSvgN />;

      case "dispute":
        return <DisputeSvg />;

      case "email":
        return <EmailSvgN />;

      case "promise":
        return <PromiseToPayN />;

      case "review_promise":
        return <ReviewPromiseSvg />;

      case "review_dispute":
        return <DisputeSvg />;

      default:
        return null;
    }
  };

  let DueInfo = ({ date }) => {
    let info = generateInDaysMessage(date, timeZone);
    return (
      <ParagraphMedium style={{ color: info.isAlert ? "red" : "green" }}>
        ({info.value})
      </ParagraphMedium>
    );
  };

  let PaymentDueInfo = ({ date }) => {
    let paymentInfo = generateInDaysMessage(date, timeZone);

    return (
      <ParagraphMedium style={{ color: paymentInfo.isAlert ? "red" : "green" }}>
        ({paymentInfo.value})
      </ParagraphMedium>
    );
  };

  let { users, restrictions, space } = useContext(MaxyfiContext);

  let findingUser = users?.filter((i) =>
    i.organization?.includes(currentOrganization)
  );

  const RelatioManager = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails?.rel_manager
  );
  const Find = findingUser.find((i) => i.id === RelatioManager);

  const workflow = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails.workflow_name
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        justifyContent: "space-evenly",
        backgroundColor: "#ffffff",
        borderRadius: "5px",
        border: "0.5px solid #cdced9",
        padding: "0px 15px",
        boxShadow: "1px 2px 4px 0px #33386025",
      }}
    >
      <div style={{ display: "flex" }}>
        <div className="cp-card__customer-previous-status">
          <div className="cp-card__customer-previous-status-icn">
            {/* <Tick /> */}
            <div
              className="cp-card__customer-previous-status-header"
              style={{ whiteSpace: "nowrap", paddingRight: "8px" }}
            >
              <HeadingXSmall $style={{ color: "#ADADAD", textAlign: "center" }}>
                Last Action
                {last_action_name && last_action_date ? (
                  <span className="cp-card__customer-previous-status-days">
                    <span
                      style={{
                        color: "#ADADAD",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      {lastActionDaysLeftMessage}{" "}
                    </span>
                    {/* {lastActionDays} days */}
                  </span>
                ) : (
                  " "
                )}
              </HeadingXSmall>
            </div>
          </div>
          <div className="cp-card__customer-previous-status-content">
            <div className="cp-card__customer-previous-status-letter --next_action">
              {last_action_name && last_action_date ? (
                <>
                  <LastActionTypes type={last_action_type} />

                  <ParagraphMedium
                    $style={{
                      whiteSpace: "nowrap",
                      maxWidth: "155px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {last_action_name}
                  </ParagraphMedium>
                </>
              ) : (
                "--"
              )}
            </div>
            {/* <div className="cp-card__customer-status-content-icn">
              <ParagraphMedium
                $style={{ marginLeft: "5px", paddingTop: "3px" }}
              >
                <FormattedDate value={last_action_date} />
              </ParagraphMedium>
            </div> */}
          </div>
        </div>

        <div className="cp-card__customer-next-status">
          <div className="cp-card__customer-next-status-icn">
            {/* <Error /> */}
            <HeadingXSmall $style={{ color: "#adadad", textAlign: "center" }}>
              Next Action{" "}
              {isShowNextAction ? (
                <span className="cp-card__customer-next-status-due">
                  <NextActionDaysDiff />
                </span>
              ) : (
                " "
              )}
            </HeadingXSmall>
          </div>
          <div className="cp-card__customer-next-status-content">
            <div className="cp-card__customer-next-status-letter --next_action">
              {isShowNextAction ? (
                <>
                  <ActionTypes type={next_action_type} />
                  <ParagraphMedium>{next_action_name}</ParagraphMedium>
                </>
              ) : (
                "--"
              )}
            </div>
            {/* <div className="cp-card__customer-status-content-icn">
              <ParagraphMedium
                $style={{ marginLeft: "5px", paddingTop: "3px" }}
              >
                <FormattedDate
                  value={moment
                    .utc(next_action_date)
                    .tz(timeZone)
                    .format("MM/DD/YYYY")}
                />
              </ParagraphMedium>
            </div> */}
          </div>
        </div>

        {inProgressPromiseToPay?.length === 0 ? (
          <div
            style={{
              color: "#333860",
              flex: "1",
              // textAlign: "center",
              // borderRight: "0.5px solid #c7c7c7",
            }}
          >
            <HeadingXSmall $style={{ color: "#ADADAD" }}>
              Promise Reviews
            </HeadingXSmall>
            <HeadingXSmall>--</HeadingXSmall>
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flex: "1",
                cursor: "pointer",
                // justifyContent: "center",
                height: "43px",
              }}
              onClick={() => {
                // dispatch(tabMotion(1));
                // dispatch(invoiceTabMotion(1));
                // dispatch(getActionData("review"));
                dispatch(isOpenModal(true));
              }}
            >
              {/* Promise Review ({inProgressPromiseToPay?.length})
                <PaymentDueInfo date={paymentReviewDate} /> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <HeadingXSmall
                  style={{ color: "#ADADAD", textAlign: "center" }}
                >
                  Promise Review ({inProgressPromiseToPay?.length})
                </HeadingXSmall>
                <div style={{ paddingRight: "6px", whiteSpace: "nowrap" }}>
                  <PaymentDueInfo date={paymentReviewDate} />
                </div>
              </div>
            </div>
          </>
        )}

        {notClosedDispute?.length === 0 ? (
          <>
            <div
              style={{
                // paddingLeft: "10px",
                color: "#333860",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // borderRight: "0.5px solid #c7c7c7",
                // textAlign: "center",
              }}
            >
              <HeadingXSmall $style={{ color: "#ADADAD" }}>
                Dispute Review
              </HeadingXSmall>
              <HeadingXSmall>--</HeadingXSmall>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flex: "1",
                cursor: "pointer",
                height: "43px",
                // justifyContent: "center",
              }}
              onClick={() => {
                // dispatch(tabMotion(2));
                // dispatch(invoiceTabMotion(1));
                // dispatch(getActionData("dispute"));
                dispatch(isOpenModal(true));
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ color: "#ADADAD" }}>
                  Dispute Review ({notClosedDispute?.length})
                </div>
                <div>
                  <DueInfo date={disputeReviewDate} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/*  */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ flex: "1" }}>
          <HeadingXSmall $style={{ color: "#ADADAD" }}>
            Relation Manager
          </HeadingXSmall>
          <HeadingXSmall>{Find ? Find.displayName : "--"}</HeadingXSmall>
        </div>

        <div style={{ flex: "3" }}>
          <HeadingXSmall $style={{ color: "#ADADAD" }}>WorkFlow</HeadingXSmall>
          <HeadingXSmall>{workflow ? workflow : "--"}</HeadingXSmall>
        </div>
      </div>
    </div>
  );
};

export default OverviewActions;
