import React, { useEffect, useContext, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { IconButton, KIND } from "../../components/IconButton";
import { TextBox } from "../../components/TextBox";
import * as TextBoxSize from "../../components/TextBox";
import { FormattedMessage, useIntl } from "react-intl";
import { ModalBody, ModalFooter, SIZE } from "baseui/modal";
import { HeadingXLarge, LabelLarge } from "baseui/typography";
import { TextButton, KIND as kinds } from "../../components/TextButton";
import { SelectBox } from "../../components/SelectBox";
import PlusContact from "../../assets/img/svg/CustomerOverViewIcon/PlusContact";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import getSelectValues from "../../utils/getSelectValues";

import {
  createContactDetailsCustomerOverview,
  updateContactDetailsCustomerOverview,
} from "../../services/customerOverviewContacts";
import {
  closeContactModal,
  refetchActions,
} from "../../redux/customerOverview/action";
import setSelectValues from "../../utils/setSelectValues";
import { NEW, EDIT, VIEW } from "../../constants";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
// import { useSelector } from "react-redux";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  alloweSpecialCharacter,
  constactEmailRegex,
  nameRegex,
} from "../../utils/regexConfig";
import { isValidPhoneNumber } from "libphonenumber-js";
import { Drawer } from "baseui/drawer";
import SmsContactSvg from "../../assets/img/svg/SmsContactSvg";
import ValidCallSvg from "../../assets/img/svg/ValidCallSvg";
import PhoneInputFields from "./PhoneInputFields";
import _ from "lodash";
import { useNumericFormat } from "react-number-format";
import moment from "moment";
import CustomFieldsTab from "../../components/CustomFields/CustomFieldsTab";
import Swap from "../../assets/img/svg/Swap";
import Location from "../../assets/img/svg/Information/Location";
import ContactLocation from "../../assets/img/svg/ContactLocation";
import DeleteIcon from "../../assets/img/svg/DeleteIcon";
import { Button } from "baseui/button";
import { unallowedSpecialCharacter } from "../../utils/regexConfig";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import ConatctEmailFields from "./ConatctEmailFields";

const AddContactDetailsModal = (props) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  const {
    referenceData,
    defaultData,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    currentOrganization,
    customContact,
    space,
    refetch,
  } = maxyfiContextValue; //TODO: VIGNESH designation reference data

  const dispatch = useDispatch();
  const isModalOpen = useSelector((e) => e.customerOverviewReducer.isOpen);
  const contactFormDefaultValue = useSelector(
    (e) => e.customerOverviewReducer.defaultContactData
  );

  const { contactFieldData } = useSelector((e) => e.customerOverviewReducer);

  const actionType = useSelector((e) => e.customerOverviewReducer.actionType);

  const intl = useIntl();

  const findFullWidth = useRef(null);

  if (findFullWidth && findFullWidth.current) {
  }

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  let result = {};
  for (let obj of customContact) {
    let section = obj.section;
    if (result.hasOwnProperty(section)) {
      result[section].push({ ...obj });
    } else {
      result[section] = [{ ...obj }];
    }
  }

  let getObjectKey = Object.keys(result);

  const [isSectionActive, setIsSectionActive] = useState(
    getObjectKey && getObjectKey[0]
  );

  const schema = Yup.object().shape(
    {
      first_name: Yup.string()
        .required("First Name is Mandatory")
        .max(100, "Character Length can't be more than 100")
        .matches(
          alloweSpecialCharacter,
          "Invalid input - Only Alphabets ' , . - and _ allowed."
        ),
      last_name: Yup.string()
        .nullable()
        .max(100, "Character Length can't be more than 100")
        .matches(alloweSpecialCharacter, {
          message: "Invalid input - Only Alphabets ' , . - and _ allowed.",
          excludeEmptyString: true,
        }),
      designation: Yup.array().min(1, "Required").required("Role is Mandatory"),
      phone: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .test("phone", "Invaild Phone Number", (value, ctr) => {
                if (
                  ctr.parent.country_code &&
                  ctr.parent.country_code[0] &&
                  ctr.parent.country_code[0].id &&
                  ctr?.parent?.status?.[0]?.id == "VALID"
                ) {
                  let NumberValid = isValidPhoneNumber(
                    `${ctr.parent.country_code[0].id}${value}`
                  );

                  return NumberValid;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              })
              .nullable()
              .optional(),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),

            country_code: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (phone) => {
                  //
                  return phone && phone?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["country_code", "status", "value"]
        )
      ),

      email: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .optional()
              .test("email", "Invalid Email ID", (value, ctr) => {
                if (value && ctr?.parent?.status?.[0]?.id == "VALID") {
                  let emailValue = value?.match(constactEmailRegex);
                  return emailValue;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              }),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["status", "value"]
        )
      ),

      landline: Yup.array().of(
        Yup.object(
          {
            value: Yup.string()
              .test("phone", "Invaild Phone Number", (value, ctr) => {
                if (
                  ctr.parent.country_code &&
                  ctr.parent.country_code[0] &&
                  ctr.parent.country_code[0].id &&
                  ctr?.parent?.status?.[0]?.id == "VALID"
                ) {
                  let NumberValid = isValidPhoneNumber(
                    `${ctr.parent.country_code[0].id}${value}`
                  );

                  return NumberValid;
                } else if (value && ctr?.parent?.status?.[0]?.id == "INVALID") {
                  return true;
                } else {
                  return true;
                }
              })
              .nullable()
              .optional(),

            status: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (value) => {
                  return value && value?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),

            country_code: Yup.array()
              .typeError("Required")
              .when("value", {
                is: (phone) => {
                  //
                  return phone && phone?.toString().length > 0 ? true : false;
                },
                then: (schema) =>
                  schema
                    .ensure("Required")
                    .typeError("Required")
                    .min(1, "Required")
                    .required("Required"),
                otherwise: (schema) => schema.optional(),
              }),
          },
          ["country_code", "status", "value"]
        )
      ),

      // address: Yup.array().of(
      //   Yup.object(
      //     {
      //     line_1:Yup.string()
      //     .matches(unallowedSpecialCharacter, {
      //       excludeEmptyString: true,
      //       message: `Enter vaild `,
      //     })
      //     .optional()
      //     .notRequired()
      //     .nullable();
      //     },
      //     ["country_code", "status", "value"]
      //   )
      // ),
    },
    [["email", "phone"]]
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      phone: [
        {
          country_code: setSelectValues(defaultData?.phone_code),
          type: [{ id: "PERSONAL_MOBILE", label: "Personal" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      landline: [
        {
          country_code: setSelectValues(defaultData?.phone_code),
          type: [{ id: "OFFICE_LANDLINE", label: "Office" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      email: [
        {
          type: [{ id: "PERSONAL_EMAIL", label: "Personal" }],
          status: [{ id: "VALID", label: "Valid" }],
          value: "",
        },
      ],
      address: [
        {
          type: [{ id: "OFFICE_ADDRESS", label: "Office" }],
          line_1: "",
          line_2: "",
          country: "",
          state: [],
          city: "",
          zip_code: "",
          status: [{ id: "VALID", label: "Valid" }],
        },
      ],
    },
  });

  let { relation } = watch();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "phone",
  });

  const landlineArray = useFieldArray({
    control,
    name: "landline",
  });

  const emailArray = useFieldArray({
    control,
    name: "email",
  });

  const addressArray = useFieldArray({
    control,
    name: "address",
  });

  let phoneFileds = fields;

  const isDisable = actionType === VIEW ? true : false;

  useEffect(() => {
    if (actionType === EDIT || actionType === VIEW) {
      const fieldsMain = Object.keys(contactFieldData);

      fieldsMain.map((field) => {
        if (field === "designation" || field === "relation") {
          setValue(field, setSelectValues(contactFieldData[field]));
        } else if (typeof contactFieldData[field] == "string") {
          setValue(field, contactFieldData[field]);
        } else {
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      (contactFieldData && actionType === EDIT) ||
      (contactFieldData && actionType === VIEW)
    ) {
      contactFieldData.phone.map((ph, i) => {
        update(i, {
          type: setSelectValues(ph?.type),
          value: ph?.value,
          country_code: setSelectValues(ph?.country_code),
          status: setSelectValues(ph?.status),
          sms_outcome: ph?.sms_outcome,
          sms_status: ph?.sms_status,
          sms_status_updated_at: ph?.sms_status_updated_at,
          sms_success_percentage: ph?.sms_success_percentage,
          call_outcome: ph?.call_outcome,
          call_status: setSelectValues(ph?.call_status),
          call_status_updated_at: ph?.call_status_updated_at,
          call_success_percentage: ph?.call_success_percentage,
          whatsapp_outcome: ph?.whatsapp_outcome,
          whatsapp_status: setSelectValues(ph?.whatsapp_status),
          whatsapp_status_updated_at: ph?.whatsapp_status_updated_at,
          whatsapp_success_percentage: ph?.whatsapp_success_percentage,
          sms_status: setSelectValues(ph.sms_status),
        });
      });
      contactFieldData.landline.map((ld, i) => {
        landlineArray.update(i, {
          type: setSelectValues(ld?.type),
          value: ld?.value,
          country_code: setSelectValues(ld?.country_code),
          status: setSelectValues(ld?.status),
          call_outcome: ld?.call_outcome,
          call_status: ld?.call_status,
          call_status_updated_at: ld?.call_status_updated_at,
          call_success_percentage: ld?.call_success_percentage,
        });
      });
      contactFieldData.email.map((em, i) => {
        emailArray.update(i, {
          type: setSelectValues(em?.type),
          value: em?.value,
          status: setSelectValues(em?.status),
          email_status: setSelectValues(em?.email_status),
          email_outcome: em?.email_outcome,
          email_status_updated_at: em?.email_status_updated_at,
          email_success_percentage: em?.email_success_percentage,
        });
      });
      contactFieldData.address.map((em, i) => {
        addressArray.update(i, {
          type: em?.type
            ? setSelectValues(em?.type)
            : setSelectValues("OFFICE_ADDRESS"),
          country: setSelectValues(em?.country),
          line_1: em?.line_1,
          line_2: em?.line_2,
          state: setSelectValues(em?.state),
          city: em?.city,
          zip_code: em?.zip_code,
          status: em?.status
            ? setSelectValues(em?.status)
            : setSelectValues("VALID"),
        });
      });
    }
  }, []);

  const createTemplate = useMutation(
    (contactData) =>
      createContactDetailsCustomerOverview({
        ...contactData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (relation && relation[0] && relation[0].isCreatable) {
          refetch({
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        // close();
        dispatch(refetchActions());
        dispatch(closeContactModal());
        // navigate(data.data.docs._id);
        props.refetch();
      },
    }
  );

  const updateContactData = useMutation(
    (contactData) =>
      updateContactDetailsCustomerOverview({
        ...contactData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        if (relation && relation[0] && relation[0].isCreatable) {
          refetch({
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        dispatch(refetchActions());
        dispatch(closeContactModal());
        props.refetch();
      },
    }
  );

  const onSubmit = async (data) => {
    let finalValues = { ...data };

    let phoneList = finalValues?.phone
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value?.type, false);
        newValue.country_code = getSelectValues(value?.country_code, false);
        newValue.status = getSelectValues(value?.status, false);
        newValue.sms_status = getSelectValues(value?.sms_status, false);
        newValue.call_status = getSelectValues(value?.call_status, false);
        newValue.whatsapp_status = getSelectValues(
          value?.whatsapp_status,
          false
        );
        return newValue;
      })
      .filter(({ value }) => value);

    let landlineList = finalValues?.landline
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value.type, false);
        newValue.country_code = getSelectValues(value.country_code, false);
        newValue.status = getSelectValues(value.status, false);
        return newValue;
      })
      .filter(({ value }) => value);

    let emailList = finalValues?.email
      ?.map((value) => {
        let newValue = { ...value };
        newValue.type = getSelectValues(value.type, false);
        newValue.status = getSelectValues(value.status, false);
        newValue.email_status = getSelectValues(value.email_status, false);
        return newValue;
      })
      .filter(({ value }) => value);

    const addressList = finalValues?.address
      ?.map((value) => {
        let newValue = { ...value };
        newValue.country = getSelectValues(value?.country);
        newValue.status = getSelectValues(value?.status);
        newValue.type = getSelectValues(value?.type);
        newValue.state = getSelectValues(value?.state);
        newValue.zip_code = value?.zip_code?.trim();
        return newValue;
      })
      .filter(
        ({ city, line_1, line_2, state, zip_code }) =>
          city || line_1 || line_2 || state || zip_code
      );

    let values = {
      first_name: finalValues.first_name,
      // ...(finalValues.last_name ? {
      last_name: finalValues?.last_name,
      // } : {}),
      designation: getSelectValues(finalValues?.designation, false),
      // ...(finalValues.relation
      //   ? {
      relation:
        finalValues?.relation?.length > 0
          ? getSelectValues(finalValues?.relation, false)
          : "",
      // }
      // : {}),
      ...(phoneList?.length === 0 ? {} : { phone: phoneList }),
      ...(landlineList?.length === 0 ? {} : { landline: landlineList }),
      ...(emailList?.length === 0 ? {} : { email: emailList }),
      ...(addressList?.length === 0 ? {} : { address: addressList }),
    };

    let contactId = contactFieldData._id;
    delete values._id;
    delete values.entity;
    delete values.primary_invoices;
    delete values.esclation_invoices;

    // let phoneValue = values?.phone?.map((i) => !i?.value);

    if (actionType === EDIT) {
      delete values.customerId;
      delete values.created_file_id;
      delete values.updated_file_id;
      values.phone = values.phone?.filter((i) => i.value);
      values.landline = values.landline?.filter((i) => i.value);
      values.email = values.email?.filter((i) => i.value);
      values.address?.map((val, i) => {
        delete values.address[i]._id;
      });
      values.phone?.map((val, i) => {
        return delete values.phone[i]._id;
      });
      values?.landline?.map((val, i) => {
        return delete values?.landline[i]?._id;
      });
      values?.email?.map((val, i) => {
        return delete values?.email?.[i]?._id;
      });

      // values.phone.map((val, i) => delete values.phone[i]._id);
      await updateContactData.mutateAsync({
        contactId,
        data: values,
        customerId: props.customerId,
      });
    } else {
      await createTemplate.mutateAsync({
        ...values,
        // data: values,
        customerId: props.customerId,
      });
    }
  };

  return (
    <React.Fragment>
      <Drawer
        autoFocus
        onClose={() => {
          if (actionType === VIEW) {
            reset();
            dispatch(closeContactModal());
          }
        }}
        isOpen={isModalOpen}
        size={SIZE.auto}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              marginTop: "0px",
              // padding: "15px",
              overflowY: "hidden",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              textAlign: "center",
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "0px",
              marginBottom: "0px",
            }}
          >
            <HeadingXLarge $style={{ color: "#516BEB" }}>
              {actionType === NEW ? (
                <FormattedMessage id="add_contact_details">
                  add_contact_details
                </FormattedMessage>
              ) : actionType === VIEW ? (
                <FormattedMessage id="view_contact_details">
                  view_contact_details
                </FormattedMessage>
              ) : actionType === EDIT ? (
                <FormattedMessage id="edit_contact_details">
                  edit_contact_details
                </FormattedMessage>
              ) : (
                <></>
              )}
            </HeadingXLarge>
          </div>
          <ModalBody
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "13px",
              marginBottom: "0px",
              height: "calc(100vh - 140px)",
              overflow: "scroll",
              padding: "12px 12px",
            }}
            ref={findFullWidth}
          >
            <div style={{ width: "530px" }}>
              {/* First Name */}
              <div style={{ display: "flex", gap: "5px" }}>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        requiredAstric={true}
                        fullWidth
                        disabled={isDisable}
                        size={TextBoxSize.SIZE.mini}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "first_name",
                        })}
                        placeholder={intl.formatMessage({
                          id: "first_name",
                        })}
                        value={field.value}
                        // onChange={(e) => {
                        //   updateLocalState("first_name", e.target.value);
                        // }}
                      />
                    </>
                  )}
                />
                <div>
                  <IconButton
                    size="mini"
                    kind="tertiary"
                    type="button"
                    onClick={() => {
                      const [firstName = "", lastName = ""] = getValues([
                        "first_name",
                        "last_name",
                      ]);

                      setValue("first_name", lastName);
                      setValue("last_name", firstName);
                    }}
                  >
                    <Swap size={16} />
                  </IconButton>
                </div>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{
                    pattern: {
                      message:
                        "Invalid input - Only Alphabets ' , . - and _ allowed.",
                      value: nameRegex,
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <TextBox
                        {...field}
                        disabled={isDisable}
                        fullWidth
                        name={field.name}
                        size={TextBoxSize.SIZE.mini}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "last_name",
                        })}
                        placeholder={intl.formatMessage({
                          id: "last_name",
                        })}
                        value={field.value}
                        // onChange={(e) => {
                        //   updateLocalState("last_name", e.target.value);
                        // }}
                      />
                    </>
                  )}
                />
              </div>
              {/* Role  */}
              <div style={{ display: "flex", gap: "40px" }}>
                <div style={{ width: "248px" }}>
                  <Controller
                    name="designation"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <SelectBox
                          {...field}
                          disabled={isDisable}
                          name={field.name}
                          requiredAstric={true}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: `role`,
                          })}
                          placeholder={intl.formatMessage({
                            id: "role",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            referenceData &&
                            referenceData?.recipient_type?.map((i) => {
                              return { label: i.label, id: i.id };
                            })
                          }
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "248px" }}>
                  <Controller
                    name="relation"
                    control={control}
                    render={({ field }) => (
                      <>
                        <SelectBox
                          {...field}
                          disabled={isDisable}
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          creatable={creatablePermission ? true : false}
                          // error={errors[field.name] && errors[field.name].message}
                          label={intl.formatMessage({
                            id: `relation`,
                          })}
                          placeholder={intl.formatMessage({
                            id: "relation",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            referenceData &&
                            referenceData["customer_contact_relation_list"]
                          }
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              {/* Phone Heading and Add Phone No. */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <LabelLarge>
                  <FormattedMessage id="mobile" style={{ color: "#333860" }}>
                    mobile
                  </FormattedMessage>
                </LabelLarge>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextButton
                    disabled={isDisable}
                    kind={KIND.tertiary}
                    size={TextBoxSize.SIZE.mini}
                    type="button"
                    style={{
                      fontSize: "12px",
                      fontWeight: "400px",
                      gap: "2px",
                      display: "flex",
                      // textAlign: "center",
                      justifyContent: "center",
                      alignItem: "center",
                    }}
                    // {
                    //         type: "phone",
                    //         value: { country_code: defaultData.phone_code,value:"",type:"work"},
                    //       }
                    onClick={() =>
                      append({
                        country_code: setSelectValues(defaultData.phone_code),
                        type: [{ id: "PERSONAL_MOBILE", label: "Personal" }],
                        status: [{ id: "VALID", label: "Valid" }],
                        value: "",
                      })
                    }
                  >
                    <PlusContact /> Add Another Number
                  </TextButton>
                </div>
              </div>

              {/* Phone Inputs */}
              {phoneFileds &&
                phoneFileds.map((e, i) => {
                  return (
                    <PhoneInputFields
                      key={e.id}
                      errors={errors}
                      e={e}
                      i={i}
                      control={control}
                      isDisable={isDisable}
                      findFullWidth={findFullWidth}
                      actionType={actionType}
                      remove={remove}
                      fields={fields}
                    />
                  );
                })}

              {/* landline start */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <LabelLarge>
                  <FormattedMessage id="landline" style={{ color: "#333860" }}>
                    landline
                  </FormattedMessage>
                </LabelLarge>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "row-reverse",
                  }}
                >
                  <TextButton
                    disabled={isDisable}
                    kind={KIND.tertiary}
                    size={TextBoxSize.SIZE.mini}
                    type="button"
                    style={{
                      fontSize: "12px",
                      fontWeight: "400px",
                      gap: "2px",
                      display: "flex",
                      // textAlign: "center",
                      justifyContent: "center",
                      alignItem: "center",
                    }}
                    // {
                    //         type: "phone",
                    //         value: { country_code: defaultData.phone_code,value:"",type:"work"},
                    //       }
                    onClick={() => {
                      landlineArray.append({
                        country_code: setSelectValues(defaultData.phone_code),
                        type: [{ id: "OFFICE_LANDLINE", label: "Office" }],
                        status: [{ id: "VALID", label: "Valid" }],
                        value: "",
                      });
                    }}
                  >
                    <PlusContact /> Add Another Landline
                  </TextButton>
                </div>
              </div>

              {/* Phone Inputs */}
              {landlineArray?.fields?.map((e, i) => (
                <>
                  {/* {contactFormDefaultValue.landline.length > 1 ? (
                    <LabelXSmall $style={{ marginBottom: "12px" }}>
                      Landline {[i + 1]}
                    </LabelXSmall>
                  ) : null} */}

                  <div style={{ display: "flex", gap: "10px" }} key={e.id}>
                    {/* <div style={{ width: "180px" }}> */}
                    <Controller
                      name={`landline[${i}].type`}
                      control={control}
                      defaultValue={[
                        { id: "OFFICE_LANDLINE", label: "Office" },
                      ]}
                      render={({ field }) => (
                        <div style={{ width: "130px" }}>
                          <SelectBox
                            {...field}
                            disabled={isDisable}
                            clearable={false}
                            name={field.name}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["landline"] &&
                              errors["landline"][i] &&
                              errors["landline"][i].type &&
                              errors["landline"][i].type.message
                            }
                            label={intl.formatMessage({
                              id: `type_contact`,
                            })}
                            placeholder={intl.formatMessage({
                              id: "type_contact",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={referenceData["contact_landline_type"]}
                          />
                        </div>
                      )}
                    />
                    {/* </div> */}

                    <Controller
                      name={`landline[${i}].country_code`}
                      control={control}
                      defaultValue={
                        actionType === EDIT && !e.phone_code && e.value
                          ? ""
                          : setSelectValues(defaultData.phone_code)
                        // : actionType === EDIT
                        // ? setSelectValues(defaultData.phone_code)
                        // setSelectValues(defaultData.phone_code)
                      }
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <div
                          style={{ width: "100px" }}
                          onClick={() => {
                            if (findFullWidth && findFullWidth.current) {
                              findFullWidth.current.scrollTop = 50;
                            }
                          }}
                        >
                          <SelectBox
                            {...field}
                            disabled={isDisable}
                            clearable={false}
                            name={field.name}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["landline"] &&
                              errors["landline"][i] &&
                              errors["landline"][i].country_code &&
                              errors["landline"][i].country_code.message
                            }
                            label={intl.formatMessage({
                              id: "code",
                            })}
                            placeholder={intl.formatMessage({
                              id: "code",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={referenceData["contact_phone_code"]}
                          />
                        </div>
                      )}
                    />
                    {/* </div> */}
                    <Controller
                      name={`landline[${i}].value`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextBox
                            type="number"
                            {...field}
                            disabled={isDisable}
                            fullWidth
                            name={field.name}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["landline"] &&
                              errors["landline"][i] &&
                              errors["landline"][i].value &&
                              errors["landline"][i].value.message
                            }
                            label={intl.formatMessage({
                              id: "number",
                            })}
                            placeholder={intl.formatMessage({
                              id: "enter_number",
                            })}
                            value={field.value}
                            onKeyDown={(e) => {
                              if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </>
                      )}
                    />
                    <div style={{ width: "180px" }}>
                      <Controller
                        name={`landline[${i}].status`}
                        control={control}
                        defaultValue={[{ id: "VALID", label: "Valid" }]}
                        render={({ field }) => (
                          <SelectBox
                            {...field}
                            disabled={isDisable}
                            clearable={false}
                            name={field.name}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["landline"] &&
                              errors["landline"][i] &&
                              errors["landline"][i].status &&
                              errors["landline"][i].status.message
                            }
                            label={intl.formatMessage({
                              id: `status`,
                            })}
                            placeholder={intl.formatMessage({
                              id: "status",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={referenceData["contact_status"]}
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{ display: "flex", marginTop: "3px", gap: "5px" }}
                    >
                      {contactFormDefaultValue?.landline?.[i]?.call_status && (
                        <ValidCallSvg
                          color={
                            contactFormDefaultValue?.landline?.[i]
                              ?.call_status === "VALID"
                              ? "#12b112"
                              : "#FD372A"
                          }
                        />
                      )}
                    </div>
                    {landlineArray?.fields?.length > 1 ? (
                      <div>
                        <TextButton
                          type="button"
                          kind={KIND.tertiary}
                          size={TextBoxSize.SIZE.mini}
                          onClick={() => landlineArray.remove(i)}
                          $style={{
                            borderRadius: "5px",
                            ":hover": {
                              background: "#ffdfdc",
                            },
                          }}
                          className="errors"
                        >
                          <DeleteIcon color="#FD372A" />
                        </TextButton>
                      </div>
                    ) : null}
                  </div>
                </>
              ))}
              {/* landline end */}

              {/* Email Header */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <LabelLarge>
                  <FormattedMessage id="email" style={{ color: "#333860" }}>
                    email
                  </FormattedMessage>
                </LabelLarge>
                <TextButton
                  disabled={isDisable}
                  kind={KIND.tertiary}
                  size={TextBoxSize.SIZE.mini}
                  type="button"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400px",
                    gap: "2px",
                    display: "flex",
                    // textAlign: "center",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                  onClick={() =>
                    emailArray.append({
                      type: [{ id: "PERSONAL_EMAIL", label: "Personal" }],
                      status: [{ id: "VALID", label: "Valid" }],
                      value: "",
                    })
                  }
                >
                  <PlusContact /> Add Another Email
                </TextButton>
              </div>

              {/* Email Inputs */}

              {emailArray.fields.map((e, i) => (
                <>
                  {/* {contactFormDefaultValue.email.length > 1 ? (
                    <LabelXSmall $style={{ marginBottom: "12px" }}>
                      Email {[i + 1]}
                    </LabelXSmall>
                  ) : null} */}

                  <ConatctEmailFields
                    key={e.id}
                    errors={errors}
                    e={e}
                    i={i}
                    control={control}
                    isDisable={isDisable}
                    findFullWidth={findFullWidth}
                    actionType={actionType}
                    remove={emailArray.remove}
                    fields={emailArray.fields}
                  />
                </>
              ))}

              {/* Address Heading */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <LabelLarge>
                  <FormattedMessage id="address" style={{ color: "#333860" }}>
                    address
                  </FormattedMessage>
                </LabelLarge>
                <TextButton
                  disabled={isDisable}
                  kind={KIND.tertiary}
                  size={TextBoxSize.SIZE.mini}
                  type="button"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400px",
                    gap: "2px",
                    display: "flex",
                    // textAlign: "center",
                    justifyContent: "center",
                    alignItem: "center",
                  }}
                  onClick={() =>
                    addressArray.append({
                      type: [{ id: "OFFICE_ADDRESS", label: "Office" }],
                      status: [{ id: "VALID", label: "Valid" }],
                      line_1: "",
                      line_2: "",
                      country: setSelectValues(defaultData.country),
                      state: "",
                      city: "",
                      zip_code: "",
                    })
                  }
                >
                  <PlusContact /> Add Another Address
                </TextButton>
              </div>
              {/* Address Inputs */}
              {addressArray.fields.map((e, i) => (
                <div key={e.id}>
                  {/* {contactFormDefaultValue.address.length > 1 ? (
                    <LabelXSmall $style={{ marginBottom: "12px" }}>
                      Address {[i + 1]}
                    </LabelXSmall>
                  ) : null} */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: "10px", width: "30%" }}>
                      <Controller
                        name={`address[${i}].type`}
                        defaultValue={[
                          { id: "OFFICE_ADDRESS", label: "Office" },
                        ]}
                        control={control}
                        disabled={isDisable}
                        render={({ field }) => (
                          <SelectBox
                            {...field}
                            disabled={isDisable}
                            name={field.name}
                            clearable={false}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["address"] &&
                              errors["address"][i] &&
                              errors["address"][i].type &&
                              errors["address"][i].type.message
                            }
                            label={intl.formatMessage({
                              id: "type_contact",
                            })}
                            placeholder={intl.formatMessage({
                              id: "type_contact",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={referenceData["contact_address_type"]}
                          />
                        )}
                      />
                    </div>
                    {addressArray.fields.length > 1 ? (
                      <div>
                        <TextButton
                          type="button"
                          className="errors"
                          kind={KIND.tertiary}
                          size={TextBoxSize.SIZE.mini}
                          $style={{
                            borderRadius: "5px",
                            ":hover": {
                              background: "#ffdfdc",
                            },
                          }}
                          onClick={() => addressArray.remove(i)}
                        >
                          <DeleteIcon color="#FD372A" />
                        </TextButton>
                      </div>
                    ) : null}
                  </div>
                  <div key={e.id}>
                    <Controller
                      name={`address[${i}].line_1`}
                      control={control}
                      render={({ field }) => (
                        <TextBox
                          disabled={isDisable}
                          {...field}
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].line_1 &&
                            errors["address"][i].line_1.message
                          }
                          label={intl.formatMessage({
                            id: "address_line_1",
                          })}
                          placeholder={intl.formatMessage({
                            id: "address_line_1",
                          })}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name={`address[${i}].line_2`}
                      control={control}
                      render={({ field }) => (
                        <TextBox
                          disabled={isDisable}
                          {...field}
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].line_2 &&
                            errors["address"][i].line_2.message
                          }
                          label={intl.formatMessage({
                            id: "address_line_2",
                          })}
                          placeholder={intl.formatMessage({
                            id: "address_line_2",
                          })}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Controller
                      name={`address[${i}].city`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextBox
                            {...field}
                            fullWidth
                            name={field.name}
                            disabled={isDisable}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["address"] &&
                              errors["address"][i] &&
                              errors["address"][i].city &&
                              errors["address"][i].city.message
                            }
                            label={intl.formatMessage({
                              id: "city",
                            })}
                            placeholder={intl.formatMessage({
                              id: "city",
                            })}
                            value={field.value}
                            onKeyDown={(e) => {
                              if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </>
                      )}
                    />

                    <Controller
                      name={`address[${i}].state`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <SelectBox
                            {...field}
                            disabled={isDisable}
                            name={field.name}
                            clearable={false}
                            creatable={creatablePermission ? true : false}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["address"] &&
                              errors["address"][i] &&
                              errors["address"][i].state &&
                              errors["address"][i].state.message
                            }
                            label={intl.formatMessage({
                              id: "state",
                            })}
                            placeholder={intl.formatMessage({
                              id: "state",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={referenceData["state_list"]}
                          />
                          {/* <TextBox
                          {...field}
                          disabled={isDisable}
                          name={field.name}
                          size={TextBoxSize.SIZE.mini}
                          error={
                            errors &&
                            errors["address"] &&
                            errors["address"][i] &&
                            errors["address"][i].state &&
                            errors["address"][i].state.message
                          }
                          label={intl.formatMessage({
                            id: "state",
                          })}
                          placeholder={intl.formatMessage({
                            id: "state",
                          })}
                          value={field.value}
                        /> */}
                        </>
                      )}
                    />
                    <Controller
                      name={`address[${i}].zip_code`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextBox
                            {...field}
                            fullWidth
                            name={field.name}
                            disabled={isDisable}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["address"] &&
                              errors["address"][i] &&
                              errors["address"][i].zip_code &&
                              errors["address"][i].zip_code.message
                            }
                            label={intl.formatMessage({
                              id: "zipCode",
                            })}
                            placeholder={intl.formatMessage({
                              id: "zipCode",
                            })}
                            value={field.value}
                            onKeyDown={(e) => {
                              if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "32%" }}>
                      <Controller
                        name={`address[${i}].country`}
                        defaultValue={setSelectValues(defaultData.country)}
                        control={control}
                        disabled={isDisable}
                        render={({ field }) => (
                          <SelectBox
                            {...field}
                            disabled={isDisable}
                            name={field.name}
                            clearable={false}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["address"] &&
                              errors["address"][i] &&
                              errors["address"][i].country &&
                              errors["address"][i].country.message
                            }
                            label={intl.formatMessage({
                              id: "country",
                            })}
                            placeholder={intl.formatMessage({
                              id: "country",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={referenceData["country"]}
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: "32.5%" }}>
                      <Controller
                        name={`address[${i}].status`}
                        // defaultValue={setSelectValues(defaultData.country)}
                        control={control}
                        disabled={isDisable}
                        render={({ field }) => (
                          <SelectBox
                            {...field}
                            disabled={isDisable}
                            name={field.name}
                            clearable={false}
                            size={TextBoxSize.SIZE.mini}
                            error={
                              errors &&
                              errors["address"] &&
                              errors["address"][i] &&
                              errors["address"][i].status &&
                              errors["address"][i].status.message
                            }
                            label={intl.formatMessage({
                              id: "status",
                            })}
                            placeholder={intl.formatMessage({
                              id: "status",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={referenceData["contact_status"]}
                          />
                        )}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "13px",
                        gap: "5px",
                      }}
                    >
                      {contactFormDefaultValue &&
                      contactFormDefaultValue?.address?.[i] &&
                      contactFormDefaultValue?.address?.[i]?.address_status ? (
                        <ContactLocation
                          color={
                            contactFormDefaultValue?.address?.[i]?.status ===
                              "VALID" ||
                            contactFormDefaultValue?.address?.[i]?.status ===
                              "LEAD"
                              ? "#0fB158"
                              : contactFormDefaultValue?.address?.[i]
                                  ?.status === "DNC"
                              ? "#ba332b"
                              : contactFormDefaultValue?.address?.[i]
                                  ?.status === "INVALID"
                              ? "#E5AC16"
                              : "#FD372A"
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <LabelLarge $style={{ marginBottom: "10px" }}>
              <FormattedMessage id="custom_fields" style={{ color: "#333860" }}>
                custom_fields
              </FormattedMessage>
            </LabelLarge>
            {actionType === EDIT && (
              <CustomFieldsTab
                customFields={contactFieldData}
                fieldLevel={customContact}
                width={"530px"}
                wrapWidth={"200px"}
              />
            )}
          </ModalBody>
          <ModalFooter
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              marginTop: "5px",
            }}
          >
            {/* <ModalButton kind="tertiary" onClick={close}>
            Discard
          </ModalButton> */}
            <div style={{ width: "125px" }}>
              <TextButton
                type="button"
                kind={KIND.tertiary}
                size={TextBoxSize.SIZE.mini}
                fullWidth
                onClick={() => {
                  reset();
                  dispatch(closeContactModal());
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ width: "125px" }}>
              <TextButton
                type="submit"
                kind={KIND.primary}
                size={TextBoxSize.SIZE.mini}
                fullWidth
                disabled={isDisable || isSubmitting}
                isLoading={isSubmitting}
              >
                Save
              </TextButton>
            </div>
          </ModalFooter>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default AddContactDetailsModal;
