export const ADD_MODAL = "ADD_MODAL";
export const EDIT_MODAL = "EDIT_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_CREDIT_NOTES = "SET_CREDIT_NOTES";
export const SET_CREDIT_GRID_STRUCTURE = "SET_CREDIT_GRID_STRUCTURE";
export const GET_TABLE_DATA = "GET_TABLE_DATA";
export const LINKED_INVOICE_MODAL_DATA = "LINKED_INVOICE_MODAL_DATA";
export const CREDIT_NOTE_DATA = "CREDIT_NOTE_DATA ";
export const LINKED_ISMODAL = "LINKED_ISMODAL";
export const LINKED_CLOSE_MODAL = "LINKED_CLOSE_MODAL";
export const SET_VIEW = "SET_VIEW";

export const GET_CREDITNOTE_GRID_STRUCTURE = "GET_CREDITNOTE_GRID_STRUCTURE";
export const UPDATE_CREDITNOTE_TABLE_DRAFT_STATE =
  "UPDATE_CREDITNOTE_TABLE_DRAFT_STATE";
export const SET_CREDITNOTE_VIEW = "SET_CREDITNOTE_VIEW";
export const CREDIT_UPDATE_ATTACHMENTS = "CREDIT_UPDATE_ATTACHMENTS";
export const REMOVE_CREDIT_ATTACHED_FILES = "REMOVE_CREDIT_ATTACHED_FILES";
export const REFETCH_CREDITS = "REFETCH_CREDITS";
export const INITIALSTATE_CREDIT_STATE = "INITIALSTATE_CREDIT_STATE";
export const CHANGING_DUE_AMOUNT = "CHANGING_DUE_AMOUNT";
export const CHANGING_ALLOCATED_AMOUNT = "CHANGING_ALLOCATED_AMOUNT";
export const LINKED_AMOUNT = "LINKED_AMOUNT";
export const CREDIT_NOTE_WIDTH = "CREDIT_NOTE_WIDTH";
export const CREDIT_NOTE_GRID_AND_VIEW = "CREDIT_NOTE_GRID_AND_VIEW";
export const CREDIT_NOTE_FILTER = "CREDIT_NOTE_FILTER";
export const NAV_LINK_CREDIT_NOTE_RESET = "NAV_LINK_CREDIT_NOTE_RESET";

export const resetCreditNote = () => {
  return {
    type: NAV_LINK_CREDIT_NOTE_RESET,
  };
};

export const setCreditNoteFilter = (data) => {
  return {
    type: CREDIT_NOTE_FILTER,
    payload: data,
  };
};
export const setCreditNoteGridView = (data) => {
  return {
    type: CREDIT_NOTE_GRID_AND_VIEW,
    payload: data,
  };
};

export const setCreditNoteWidth = (width) => {
  return {
    type: CREDIT_NOTE_WIDTH,
    payload: width,
  };
};

export const linkedAmountValues = (lnkAmt) => {
  return {
    type: LINKED_AMOUNT,
    payload: lnkAmt,
  };
};

export const changingDueAmount = (changingData) => {
  return {
    type: CHANGING_DUE_AMOUNT,
    payload: changingData,
  };
};

export const changingAllocatedAmount = (allocatedAmount) => {
  return {
    type: CHANGING_ALLOCATED_AMOUNT,
    payload: allocatedAmount,
  };
};

export const initialStateCredit = () => {
  return {
    type: INITIALSTATE_CREDIT_STATE,
  };
};
export const refetchCredit = () => {
  return {
    type: REFETCH_CREDITS,
  };
};
export const creditAttachment = (data) => {
  return {
    type: CREDIT_UPDATE_ATTACHMENTS,
    payload: data,
  };
};
export const removeCreditAttachedFile = (remove) => {
  return {
    type: REMOVE_CREDIT_ATTACHED_FILES,
    payload: remove,
  };
};

export const getCreditNoteGridStructure = (gridStructure) => {
  return {
    type: GET_CREDITNOTE_GRID_STRUCTURE,
    payload: gridStructure,
  };
};

export const updateTableCreditNoteDraftState = (tableState) => {
  return {
    type: UPDATE_CREDITNOTE_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const setCreditNoteTableView = (viewData) => {
  return {
    type: SET_CREDITNOTE_VIEW,
    payload: viewData,
  };
};

// Action
export const linkedIsClose = () => {
  return { type: LINKED_CLOSE_MODAL };
};
export const linkedIsModal = (data) => {
  return {
    type: LINKED_ISMODAL,
    payload: data,
  };
};

export const creditNoteData = (data) => {
  return {
    type: CREDIT_NOTE_DATA,
    payload: data,
  };
};

export const linkedInvoiceData = (LinkedInvoiceData) => {
  return {
    type: LINKED_INVOICE_MODAL_DATA,
    payload: LinkedInvoiceData,
  };
};

export const getTableData = (tabledata) => {
  return {
    type: GET_TABLE_DATA,
    payload: tabledata,
  };
};

export const addModal = (value) => {
  return {
    type: ADD_MODAL,
  };
};

export const editModal = (value) => {
  return {
    type: EDIT_MODAL,
    payload: value,
  };
};

export const closeModal = (value) => {
  return {
    type: CLOSE_MODAL,
  };
};

export const setCreditNotes = (data) => {
  return {
    type: SET_CREDIT_NOTES,
    payload: data,
  };
};

export const setCreditNotesTableView = (viewData) => {
  return {
    type: SET_VIEW,
    payload: viewData,
  };
};
