import React from "react";

const GroupStarSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 10.0827L9.96584 11.5711C10.1014 11.6663 10.2382 11.6694 10.3762 11.5805C10.5142 11.4916 10.5567 11.3668 10.5038 11.2062L9.75959 8.74421L11.6799 7.3787C11.8208 7.27366 11.8637 7.14278 11.8084 6.98604C11.7531 6.82931 11.643 6.75095 11.4781 6.75095H9.12787L8.32737 4.16491C8.26998 4.00433 8.16092 3.92403 8.00021 3.92403C7.83948 3.92403 7.73028 4.00433 7.67259 4.16491L6.87209 6.75095H4.52191C4.35699 6.75095 4.24688 6.82931 4.19159 6.98604C4.13631 7.14278 4.17913 7.27366 4.32007 7.3787L6.24037 8.74421L5.49614 11.2062C5.44326 11.3668 5.48581 11.4916 5.62379 11.5805C5.76177 11.6694 5.89854 11.6663 6.03412 11.5711L7.99998 10.0827ZM8.00124 15.125C7.01579 15.125 6.08951 14.938 5.22241 14.564C4.3553 14.19 3.60104 13.6824 2.95963 13.0413C2.3182 12.4001 1.81041 11.6462 1.43624 10.7795C1.06208 9.91277 0.875 8.98669 0.875 8.00124C0.875 7.01579 1.062 6.08951 1.436 5.22241C1.81 4.3553 2.31756 3.60104 2.95869 2.95963C3.59983 2.3182 4.35376 1.81041 5.22048 1.43624C6.08719 1.06208 7.01328 0.875 7.99873 0.875C8.98418 0.875 9.91045 1.062 10.7776 1.436C11.6447 1.81 12.3989 2.31756 13.0403 2.95869C13.6818 3.59983 14.1896 4.35376 14.5637 5.22048C14.9379 6.08719 15.125 7.01328 15.125 7.99873C15.125 8.98418 14.938 9.91045 14.564 10.7775C14.19 11.6447 13.6824 12.3989 13.0413 13.0403C12.4001 13.6818 11.6462 14.1896 10.7795 14.5637C9.91277 14.9379 8.98669 15.125 8.00124 15.125ZM7.99998 14C9.66248 14 11.0781 13.4156 12.2469 12.2469C13.4156 11.0781 14 9.66248 14 7.99998C14 6.33748 13.4156 4.92186 12.2469 3.75311C11.0781 2.58436 9.66248 1.99998 7.99998 1.99998C6.33748 1.99998 4.92186 2.58436 3.75311 3.75311C2.58436 4.92186 1.99998 6.33748 1.99998 7.99998C1.99998 9.66248 2.58436 11.0781 3.75311 12.2469C4.92186 13.4156 6.33748 14 7.99998 14Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default GroupStarSvg;
