import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import _ from "lodash";
import moment from "moment";

import { Icon, RDText, Typography } from "../../../../components_v2";
import { KIND, TextButton } from "../../../../components/TextButton";
import Warning from "../../../../assets/img/warning.png";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import {
  NEW,
  CAPTURE,
  EDIT,
  GETTING_FIELD_VISIT_,
} from "../../../../constants";
import { getFieldVisit } from "../../../../services";
import TitleHead from "../../../../components_v2/TitleHead";
import FieldVisitFormTypes from "./FieldVisitFormTypes";
import { ModalContext } from "../../../../providers/ModalProvider";
import FieldVisitCancel from "../../../../containers/CustomerOverView/COTabContainers/Actions/AdhocActionPopup/FieldVisit/FieldVisitCancel";

const FieldVisitCard = (props) => {
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  const { handleModal } = useContext(ModalContext);
  const { customerId } = useParams();
  const fieldVisitDiff =
    props.visit_date &&
    moment(props.visit_date)
      ?.tz(currentDefaultFormatDetails.date_format)
      ?.diff(moment.tz(currentDefaultFormatDetails.date_format), "days", true);

  return (
    <>
      <div className="co_fv_card_container">
        <div className="co_fv_card_content_container">
          <Typography type="h4" style={{ marginBottom: "5px" }}>
            Field Visit scheduled on{" "}
            {props && props.visit_date
              ? moment(props.visit_date).format(
                  currentDefaultFormatDetails.date_format
                )
              : "-"}{" "}
            <RDText id={props.visit_time} rdName={"time"} />{" "}
            {fieldVisitDiff > 0 ? (
              <span
                style={{
                  color: "#FBBD04",
                  backgroundColor: "#FFF8E3",
                  borderRadius: "5px",
                  padding: "3px",
                }}
              >
                Due in {Math.ceil(fieldVisitDiff)} Days
              </span>
            ) : Math.abs(Math.ceil(fieldVisitDiff)) === 0 ? (
              <span
                style={{
                  color: "#0AC963",
                  backgroundColor: "#0AC96320",
                  borderRadius: "5px",
                  padding: "3px",
                }}
              >
                Due Today
              </span>
            ) : Math.abs(Math.ceil(fieldVisitDiff)) < 0 ? (
              <span
                style={{
                  color: "#FD372A",
                  backgroundColor: "#FFF3F2",
                  borderRadius: "5px",
                  padding: "3px",
                }}
              >
                Overdue {Math.abs(Math.ceil(+fieldVisitDiff))} Days
              </span>
            ) : (
              <></>
            )}
          </Typography>
          <Typography
            type="p"
            subType="regular"
            style={{ color: "#787878" }}
            className="co_fv_dtl_container"
          >
            {props &&
            props?.custom_location &&
            props?.custom_location?.length > 0
              ? props.custom_location
              : props?.address
              ? `${props && props?.contact && props?.contact?.name} ${
                  props.address.line_1 ? props.address.line_1 : ""
                } ${props.address.line_2 ? props.address.line_2 : ""} ${
                  props.address.city ? props.address.city : ""
                }
                                   ${
                                     props.address.state
                                       ? props.address.state
                                       : ""
                                   }
                                   ${
                                     props.address.country
                                       ? props.address.country
                                       : ""
                                   }
                                   ${
                                     props.address.zip_code
                                       ? props.address.zip_code
                                       : ""
                                   }.

                                  `
              : props && props.custom_location}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <div className="df_align_c mr-1">
            <Icon icon="rel_manager" color="#787878" />
            <Typography type="p" subType="regular" style={{ color: "#787878" }}>
              {props && props.created_by ? (
                <RDText id={props.created_by} rdName={"users"} />
              ) : (
                "Workflow"
              )}
              ,{" "}
              {props && props.created_at
                ? moment
                    .tz(props.created_at, currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)
                : "-"}
            </Typography>
          </div>
          <div className="df_align_c --g1">
            {props && props.state && props.state.type === NEW && (
              <>
                <TextButton
                  size="mini"
                  kind={KIND.secondary}
                  onClick={() => {
                    props.setState({
                      type: CAPTURE,
                      captureData: { ...props },
                    });
                  }}
                >
                  Capture
                </TextButton>
                <Icon
                  icon="edit"
                  isPressable
                  size={18}
                  color={"#ADADAD"}
                  onClick={() => {
                    props.setState({
                      type: EDIT,
                      captureData: { ...props },
                    });
                  }}
                />
                <Icon
                  icon="remove"
                  isPressable
                  size={18}
                  color={"#ADADAD"}
                  onClick={() => {
                    handleModal({
                      title: "",
                      content: "Would you like to Cancel the Field Visit?",
                      successCallback: () => {
                        // deleteTemplate.mutate(_id);
                      },
                      formContent: ({ handleModal }) => {
                        return (
                          <>
                            <FieldVisitCancel
                              handleModal={handleModal}
                              getParams={customerId}
                              fieldId={props._id}
                            />
                          </>
                        );
                      },
                      cancelCallback: () => {},
                      isChildren: true,
                      image: Warning,
                      buttonText: "",
                      closeButtonText: "",
                      isCloseAble: false,
                    });
                  }}
                />
              </>
            )}
          </div>
        </div>
        <FieldVisitFormTypes
          {...props}
          type={props && props.state && props.state.type}
        />
      </div>
    </>
  );
};

const FieldVisitList = () => {
  const { customerId } = useParams();
  const { currentOrganization } = useContext(MaxyfiContext);

  const { data } = useQuery(
    [`${GETTING_FIELD_VISIT_}-${customerId}`],
    async ({ queryKey }) => {
      return await getFieldVisit({
        organization: currentOrganization,
        customerId: customerId,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const [state, setState] = useState({
    type: NEW,
    captureData: {},
  });

  return (
    <div style={{ width: "775px" }}>
      <TitleHead icon="field_visit" title="Field Visit" />
      {state.type === NEW &&
        _.get(data, "data.docs", []).map((fv) => {
          return <FieldVisitCard {...fv} setState={setState} state={state} />;
        })}

      {state.type === CAPTURE && (
        <FieldVisitCard
          {...state.captureData}
          setState={setState}
          state={state}
        />
      )}

      {state.type === EDIT && (
        <FieldVisitCard
          {...state.captureData}
          setState={setState}
          state={state}
        />
      )}
    </div>
  );
};

export default FieldVisitList;
