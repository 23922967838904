import React from "react";

const DocFileType = ({ size = 26, color = "white" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66214 0H15.3548L22.7221 7.67917V22.6152C22.7221 24.4863 21.2084 26 19.3438 26H6.66214C4.79108 26 3.27734 24.4863 3.27734 22.6152V3.3848C3.27731 1.51374 4.79105 0 6.66214 0V0Z"
        fill="#E5252A"
      />
      <path
        opacity="0.302"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.3486 0V7.62069H22.7225L15.3486 0Z"
        fill="white"
      />
    </svg>
  );
};

export default DocFileType;
