export const GET_CHECK_MT_GRID_STRUCTURE = "GET_CHECK_MT_GRID_STRUCTURE";
export const UPDATE_CHECK_TABLE_DRAFT_STATE = "UPDATE_CHECK_TABLE_DRAFT_STATE";
export const SET_CHECK_VIEW = "SET_CHECK_VIEW";
export const INITIAL_CHECK_MT = "INITIAL_CHECK_MT";
export const TABLE_WIDTH_CHECK_MT = "TABLE_WIDTH_CHECK_MT";

export const SET_CHECK_MT_GRID_AND_VIEW = "SET_CHECK_MT_GRID_AND_VIEW";

export const SET_CHECK_MT_SORT = "SET_CHECK_MT_SORT";
export const SET_CHECK_MT_PAGE_INDEX = "SET_CHECK_MT_PAGE_INDEX";
export const SET_CHECK_MT_PAGE_SIZE = "SET_CHECK_MT_PAGE_SIZE";
export const CHECK_MT_FILTER = "CHECK_MT_FILTER";

export const SET_CHECK_MT_FILTER = "SET_CHECK_MT_FILTER";
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";
export const RESET_INVOICE_NAVLINK = "RESET_CUSTOMER_NAVLINK";
export const RESET_FORM_DATA_CHECK_MT = "RESET_FORM_DATA_CHECK_MT";
export const SET_GLOBAL_CHECK_MT_FILTER = "SET_GLOBAL_CHECK_MT_FILTER";

export const CHECK_MT_DASHBOARD_NEED_ACTION_FILTER =
  "CHECK_MT_DASHBOARD_NEED_ACTION_FILTER";
export const CHECK_MT_NEEDS_ACTION_FILTERS = "CHECK_MT_NEEDS_ACTION_FILTERS";
export const SET_CHECK_MT_PINNED_VIEW = "SET_CHECK_MT_PINNED_VIEW";
export const CHECK_MT_DASHBOARD_FILTER = "CHECK_MT_DASHBOARD_FILTER";
export const CHECK_MANI_TABLE_COLUMN_ORDER = "CHECK_MANI_TABLE_COLUMN_ORDER";

export const setCheckMtPinnedView = (payload = []) => {
  return {
    type: SET_CHECK_MT_PINNED_VIEW,
    payload,
  };
};

export const setTableWidthCheckMt = (width) => {
  return {
    type: TABLE_WIDTH_CHECK_MT,
    payload: width,
  };
};

export const setCheckMtGlobalFilter = (cusName) => {
  return {
    type: SET_GLOBAL_CHECK_MT_FILTER,
    payload: cusName,
  };
};

export const setInitialCheckMtFormData = () => {
  return {
    type: RESET_FORM_DATA_CHECK_MT,
  };
};

export const setCheckMtFilter = (filterData) => {
  return {
    type: SET_CHECK_MT_FILTER,
    payload: filterData,
  };
};

// export const setCheckMtFilter = (data) => {
//   return {
//     type: CHECK_MT_FILTER,
//     payload: data,
//   };
// };

export const setInitialCheckMt = () => {
  return {
    type: INITIAL_CHECK_MT,
  };
};

export const setCheckMtGridStructure = (gridStructureIS) => {
  return {
    type: GET_CHECK_MT_GRID_STRUCTURE,
    payload: gridStructureIS,
  };
};

export const updateTableCheckMtDraftState = (tableState) => {
  return {
    type: UPDATE_CHECK_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const setCheckMtTableView = (viewData) => {
  return {
    type: SET_CHECK_VIEW,
    payload: viewData,
  };
};

export const setCheckMtGridAndView = (viewData) => {
  return {
    type: SET_CHECK_MT_GRID_AND_VIEW,
    payload: viewData,
  };
};

export const setCheckMtSort = (sort) => {
  return {
    type: SET_CHECK_MT_SORT,
    payload: sort,
  };
};

export const setCheckMtPageIndex = (pageIndex) => {
  return {
    type: SET_CHECK_MT_PAGE_INDEX,
    payload: pageIndex,
  };
};

export const setCheckMtPageSize = (pageSize) => {
  return {
    type: SET_CHECK_MT_PAGE_SIZE,
    payload: pageSize,
  };
};

export const setDashBoardNeedActionFilterCheckMt = (dashFilter) => {
  return {
    type: CHECK_MT_DASHBOARD_NEED_ACTION_FILTER,
    payload: dashFilter,
  };
};

export const setNeedsActionFilterCheckMt = (selectedNeedsFilter) => {
  return {
    type: CHECK_MT_NEEDS_ACTION_FILTERS,
    payload: selectedNeedsFilter,
  };
};

export const setCheckMtDashboardFilter = (data) => {
  return {
    type: CHECK_MT_DASHBOARD_FILTER,
    payload: data,
  };
};

export const setCheckManiTableHeaderOrder = (tabHead) => {
  return {
    type: CHECK_MANI_TABLE_COLUMN_ORDER,
    payload: tabHead,
  };
};
