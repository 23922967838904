export default {
  role_maintenance_form: "Add New Role Maintenance",
  edit_role_maintenance_form: "Edit Role Maintenance",
  view_role_maintenance_form: "View Role Maintenance",
  added_permissions: "Added Permission",
  removed_permissions: "Removed Permission",
  menu_access: "Menu Access",
  linked_user_count: "Linked user count",
  role_description: "Role Description",
  role_name: "Role Name",
  add_role_maintenance: "Add Role Maintenance",
  edit_role_maintenance: "Edit Role Maintenance",
  role_maintenance: "Role Maintenance",
  slab_name: "Slab Name",
  slab_based_on: "Range Parameter",
  contact_type: "Contact Type",
  payment_allocation_type: "Payment Allocation Type",
  debtor_payment_instruction_2: "Debtor Payment Instruction 2",
  portal_payment_type: "Portal Payment Type",
  debtor_payment_instruction_1: "Debtor Payment Instruction 1",
  relation_manager: "Relation Manager",
  rel_manager_address: "Relation Manager Address",
  rel_manager_phone: "Relation Manager Phone",
  business_unit_name: "Business Unit Name",
  business_unit_code: "Business Unit Code",
  business_registration_number: "Business Registeration Number",
  commission_type: "Commission Type",
  flat_fee_percentage: "Flat Fee Percentage",
  commission_slab: "Commission Slab",
  invoice_type: "Invoice Type",
  invoice_start_date: "Invoice Start Date",
  next_billing_date: "Next Billing Date",
  invoice_frequency: "Invoice Frequency",
  invoice_payment_instruction_1: "Invoice Payment Instruction 1",
  invoice_payment_instruction_2: "Invoice Payment Instruction 2",
  invoice_terms_and_condition: "Invoice Terms and Condition",
  branding: "Branding",
  invoice_taxes: "Invoice Taxes",
  add_business_unit: "Add Business Unit",
  business_unit_maintenance: "Business Unit Maintenance",
  delivered_list: "Delivered List",
  bounced_list: "Bounced List",
  last_read_at: "Last Read At",
  customer_categorization: "Customer Categorization",
  paid_to: "Paid To",
  paid_amount: "Paid Amount",
  choose_template: "Choose Template",
  type_review: "Type",
  release_promise_date: "Release Promise Hold",
  business_unit: "Business Unit",
  business_location: "Business Location",
  business: "Business Unit & Location",
  customer_status: "Customer Status",
  customer_comment: "Customer Comment",
  api_token: "Api Token",
  sub_domain: "Sub Domain",
  checkin_report: "Checkin Report",
  recurring_report: "Recurring Report",
  edit_invoice: "Edit Invoice",
  edit_recurring: "Edit Recurring Invoice",
  add_recurring_invoice: "Add Recurring Invoice",
  add_invoice: "Add Invoice",
  invoice_notes: "Invoice Notes",
  invoice_terms: "Invoice Terms",
  invoice_footer: "Invoice Footer",
  frequency: "Frequency",
  select_tax: "Select Tax",
  select_product: "Select Product",
  next_execution_date: "Next Execution Date",
  recover_from_customer: "Recover From",
  billing_address: "Billing Address",
  shipping_address: "Shipping Address",
  payment_term: "Payment Term",
  advance_amount: "Advance Amount",
  po_number: "PO Number",
  discount: "Discount",
  other_information: "Other Information",
  subtotal: "Subtotal",
  paid: "Paid",
  balance_due: "Balance Due",
  internal_notes: "Remarks",
  two_tax_rate: "Two Tax Rate",
  one_tax_rate: "One Tax Rate",
  three_tax_rate: "Three Tax Rate",
  invoicing: "Invoicing",
  measure: "Measure",
  invoice_item_maintenance: "Invoice Item Maintenance",
  invoice_level_tax_rate: "Invoice Level Tax Rates",
  invoice_item_level_tax_rate: "Invoice Item Level Tax Rates",
  rate: "Rate",
  nature_of_tax: "Nature of Tax",
  subscription_id: "Subscription Id",
  customer_notes: "Customer Notes",
  item_amount: "Item Amount",
  end_date: "End Date",
  fixed_amount: "Fixed Amount",
  usage_amount: "Usage Amount",
  adhoc_amount: "Adhoc Amount",
  api_key: "Api key",
  api_base_url: "Api base url",
  allocation_invoice: "Allocation Invoice",
  sender_id: "Sender Id",
  number: "Number",
  min_call: "Minimum Call",
  max_call: "Maxmium Call",
  allocated_amount_pending_allocation: "Allocation/Pending Allocate",
  credit_limit_breach_perc: "Credit Limit Percentage",
  credit_limit_status: "Credit Limit Status",
  search: "Search",
  source: "Source",
  key_id: "Key Id",
  key_secret: "Key Secret",
  last_action: "Last Action",
  next_action: "Next Action",
  due: "Due",
  overdue: "OverDue",
  total: "Total",
  sync_status: "Sync Status",
  credit_limit: "Credit Limit",
  call_unsuc_is_send_confirmation_message: "Send Confirmation  Message",
  call_un_is_hold_workflow: "Pause workflow till follow up date",
  call_unsuccessfull_call: "Pause workflow till Call Back Date",
  is_send_confirmation_message_ptp: "Send Confirmation  Message",
  is_hold_workflow_ptp: "Pause workflow till Promise Date",
  is_send_confirmation_message_cbl: "Send Confirmation Message",
  is_hold_workflow_cbl: "Pause workflow till Call Back Date",
  send_promise_follow_up_message_cbl: "Send Call Back Follow up Message",
  type_limit: "Type Limit",
  call_unsuccessful_till: "Call Unsuccessful Till",
  call_unsuccessful_till_date: "Send Confirmation Message",
  pause_workflow_till_call_back_date: "Pause workflow till Call Back Date",
  pause_workflow_till_promise_date: "Pause workflow till Promise Date",
  promise_to_pay_confirmation: "Promise to Pay Confirmation  Message",
  pause_workflow_till_call_back_confirmation_message:
    "Send Confirmation Message",
  total_inv_count_: "Total Invoice #",
  rel_manager_email: "Relation Manager (Email Address)",
  customer_id: "Customer Id",
  workflow_start_date: "Workflow Start Date",
  total_invoice_amount: "Total Invoice Amount",
  enter_your_timezone: "Enter Your Timezone",
  pause_workflow_till_follow_up_message: "Send Promise Follow Up Message",
  timezone: "Default Timezone",
  currency_cur: "Default Currency",
  enter_your_currency: "Enter Your Currency",
  enter_your_company_name: "Enter Your Company Name",
  enter_your_country: "Enter Your Country",
  confirm_password: "Confirm Password",
  enter_password: "Enter Password",
  enter_confirm_password: "Enter Confirmed Password",
  business_email_account: "Business Email Account",
  enter_your_first_name: "Enter Your First Name",
  UNREAD_EMAILS: "Unread Emails",
  select_filter: "Select Filter",
  search_by_cus_name: "Search By Customer Name",
  ein_gi: "Business Registration Number",
  email_id: "Email Address",
  address_line_1_floor_number_door_street_details: "Address Line 1",
  action_on_hold: "Action on hold",
  cus_max_delay_days: "Max Delay Days",
  message_medium: "Message Medium",
  hold_workflow_till_dispute_date: "Pause Workflow till Dispute date",
  sb: "Subject",
  bcc_e: "BCC",
  cc_e: "CC",
  content_body: "Content Body",
  date_and_time: "Date and Time",
  save_mapping_for_future_reference: "Save mapping for future reference",
  attachments: "Attachments",
  partial_amount: "Partial Amount",
  full_amount: "Full Amount",
  PARTIAL: "Partial",
  summary_view_of_invoice_download: "Linked Invoice Numbers",
  hold_workflow_till_review_date: "Pause workflow till review date",
  BROKEN: "Broken",
  KEPT: "Kept",
  IN_PROGRESS: "In Progress",
  mandatory_system_fields: "Mandatory System Fields",
  select_template: "Apply Mapping",
  invoice_amount: "Invoice Amount",
  ccy: "CCY",
  issued_date: "Issued Date",
  allow_invoice_download: "Allow Invoice Download",
  display_paid_invoices: "Display Paid Invoices",
  adjusted_credit_note: "Display Fully Adjusted Credit Notes",
  historical_chat: "Historical Chat",
  files_transfered: "Files Transfered",
  amnt_restriction: "Payment Amount Restriction",
  restrict_date: "Maximum Payment Extension",
  percentage: "Min Payment Percentage",
  duration: "Call back Duration",
  start: "Start Time",
  end: "End Time",
  send_promise_follow_up_message: "Send Promise Follow Up Message",
  CUSTOMER: "Customer Level",
  INVOICE: "Invoice Level",
  ORGANIZATION: "Organization Level",
  BUSINESS_UNIT: "Business Unit Level",
  reason_to_skip: "Reason To Skip",
  audit: "Audit",
  issued_amount: "Issued Amount",
  linked_invoices: "Linked Invoices",
  linked_amount: "Linked Amount",
  available_amount: "Available Amount",
  audit_user: "Audit User",
  dispute_detailed_text: "Dispute Detailed Text",
  dispute_category: "Dispute Category",
  hold_till_date: "Pause Till Date",
  notes_category: "Notes Category",
  date: "Date",
  method: "Method",
  expected_date: "Expected Date",
  created_by: "Created By",
  comment: "Comment",
  expected_payment_date: "Expected Payment Date",
  invoice_number: "Invoice Number",
  invoice_date: "Invoice Date",
  invoice_due_date: "Invoice Due Date",
  currency_code: "Currency Code",
  received_amount: "Received Amount",
  capture_by: "Capture By",
  capture_details: "Capture Details",
  details: "Details",
  disputes: "Disputes",
  review_date: "Review Date",
  dispute_status: "Review Status",
  captured_by: "Captured By",
  date_time: "Date & Time",
  activity: "Activity",
  skip_workflow_action_till_next_action_date:
    "Skip Workflow Action till Next action date ?",
  reciever: "Reciever",
  next_action_type: "Next Action Type",
  hold_workflow_till_promise_date: "Pause workflow till Promise Date",
  user_comments: "User Comments",
  invoice_total_amount: "Amount",
  user_comments: "User Comments",
  select_orientation: "Select Orientation",
  choose_header: "Choose Header",
  choose_header_premium: "Choose Header (premium Feature)",
  choose_footer_premium: "Choose Footer (premium Feature)",
  is_skip_workflow_action: "Is Skip Workflow Action",
  choose_footer: "Choose Footer",
  top_margin: "Top Margin",
  bottom_margin: "Bottom Margin",
  right_margin: "Right Margin",
  left_margin: "Left Margin",
  choose_action: "Choose Action",
  action_date: "Action Date",
  override_workflow: "Override Workflow",
  account_name: "Account Name",
  routing_number: "Routing Number",
  action_type: "Action Type",
  account_number: "Account Number",
  code: "Code",
  phone_number: "Phone Number",
  choose_relation_manager: "Choose Relation Manager",
  choose_workflow: "Choose Workflow",
  org_name: "Organization Name",
  ein: "EIN",
  ein_placeholder: "XX-XXXXXXX",
  email_value: "Email Value",
  address_line_1: "Address Line 1",
  address_line_2: "Address Line 2",
  city: "City",
  state: "State",
  country: "Country",
  zip_code: "Zip Code",
  start_time: "Start Time",
  end_time: "End Time",
  time_zone: "Time Zone",
  industry_sector: "Industry Sector",
  org_url: "Organization URL",
  dashboard: "Dashboard",
  default_currency: "Default Currency",
  organization_settings: "Organization Settings",
  organizations: "Organizations",
  department: "Department",
  authentication_type: "Authentication Type",
  created_on: "Created On",
  template_id: "Choose Template",
  is_credit_note: "Credit Note",
  is_authentication: "Authentication",
  is_invoice: "Invoice",
  is_payment: "Payment",
  is_message: "Message",
  is_callback: "Call Back Days",
  is_request_back: "Request Payment Time",
  is_allow_invoice_download: "Invoice Download",
  cus: "customer Portal Link",
  allow_invoice_download: "Allow Invoice Download",
  display_invoice: "Display Invoice",
  display_fully_adjusted_credit_notes: "  Display Fully Adjusted Credit Notes",
  pay_all_dues: "Pay All Dues",
  search_invoice: "Search Invoice",
  total_amount: "Total Amount",
  invoice_enable: "Invoice Enable",
  authentication: "Authentication",
  allocated_amount: "Allocated Amount",
  add_credit_notes: "Add Credit Notes",
  edit_credit_notes: "Edit Credit Notes",
  pay_overdues: "Pay Overdue",
  full_amount: "Full Amount",
  adhoc_payment: "Adhoc Payment",
  display_historical_chat: "Display Historical Chat",
  allow_transfered: " Allow Files To be Transfered",
  payment: "Payment",
  message: "Message",
  call_back: "Call Back",
  request_back: "Request Back",
  remarks: "Remarks",
  amount: "Amount",
  issue_date: "Issue Date",
  customer: "Customer",
  credit_note_id: "Credit Note Id",
  credit_note: "Credit Note",
  currency: "Currency",
  type: "Types of Notes",
  is_enable_alert_and_follow_up: "",
  domain_name: "Domain Name",
  user_name: "User Name",
  host_name: "Host Name",
  port: "Port",
  password: "Password",
  organisation_name: "Organisation Name",
  follow_up_date: "Follow up Date",
  notes: "User Notes",
  customer_setting: "Customer Setting",
  customer_name: "Customer Name",
  first_name: "First Name",
  second_name: "Second Name",
  last_name: "Last Name",
  role: "Role",
  phone: "Phone",
  add_contact_details: "   Add Contact Details",
  select: "Please Select",
  email: "Email",
  enter_number: "Enter Your Number",
  enter_email: "Enter Your Email",
  most_used_suggestion: "Most Used & Suggestion",
  address: "Address",
  zipCode: "Zip Code",
  state: "State",
  country: "Country",
  inv_number: "Invoice Number",
  inv_date: "Invoice Date",
  due_date: "Due Date",
  due_days: "Due Days",
  all_actions: "All Actions",
  co_inv_status: "Status",
  co_inv_amount: "Amount",
  co_inv_ccy: "CCY",
  customer_summary: "Customer Summary",
  customer_overview: "Customer Overview",
  adhoc_upload: "Adhoc Data Upload",
  communication_template: "Communication Template",
  workflow_maintenance: "Workflow Maintenance",
  cus_name: "Name",
  display_name: "Name",
  due_inv_count: "#Due",
  due_inv_amount: "Due Amt",
  overdue_inv_count: "#Overdue",
  overdue_inv_amount: "Overdue Amt",
  total_inv_count: "#Total",
  total_inv_amount: "Total Amt",
  last_action_date: "Last Action Date",
  last_action_name: "Last Action Name",
  last_action_comment: "Last Action Comment",
  next_action_date: "Next Action Date",
  sugg_next_action: "Suggested Next Action",
  workflow: "Workflow",
  rel_manager: "Relation Manager",
  max_payment_delay: "Max Payment Delay",
  avg_payment_delay: "Avg Payment Delay",
  last_response_date: "Last Response Date",
  days_after_last_response: "Days After Last Response",
  save_as_new_view: "Save As New View",
  add_view: "Add View",
  new_view: "New View",
  create: "Create",
  default_view: "Default View",
  enable_for_organization_users: "Enable for organization users",
  description: "Description",
  name: "Name",
  required: "Required",
  view_name: "View Name",
  template_type: "Template Type",
  template_sub_type: "Template Sub Type",
  view_description: "View Description",
  template_name: "Template Name",
  template_description: "Template Description",
  template_organization: "Template Organization",
  template_languages: "Template Languages",
  workflow_name: "Workflow Name",
  workflow_description: "Workflow Description",
  max_contact_in_month: "Max Contact in Month",
  workflow_average_delay: "Avg Delay",
  workflow_enter_a_tag: "Add #Tags",
  add_multiple_tags_by_pressing_enter: "Press enter after each # Tags",
  action_name: "Action Name",
  wf_max_contact_in_month: "Contact in Month",
  action_description: "Action Description",
  action_type: "Type",
  recipient_type: "Choose Recipient Type",
  choose_sms_template: "Choose Sms Template",
  choose_letter_template: "Choose Letter Template",
  choose_email_template: "Choose Email Template",
  sms_content_preview: "SMS Content Preview",
  subject_preview: "Subject Preview",
  email_content_preview: "Email Content Preview",
  instruction_to_user: "Instruction To User",
  postal_service: "Postal Service",
  postal_type: "Postal Type",
  letter_preview: "Letter Content Preview",
  last_modified: "Last Modified",
  most_used: "Most Used",
  all: "All",
  sms: "SMS",
  letter: "Letter",
  email: "Email",
  search_templates: "Search by Name or Description",
  add_template: "Add Template",
  rows_per_page: "Rows per page",
  prev: "Prev",
  next: "Next",
  min: "Min",
  max: "Max",
  search_here: "Search Here",
  date_month_year: "MM/DD/YYYY - MM/DD/YYYY",
  select_view: "Select View",
  // cp-action lang
  capture_call_details: "Capture Call Details",
  reciever_place: "Reciever",
  call_outcome: "Call Outcome",
  promise_to_pay: "Promise to Pay",
  PROMISE_TO_PAY: "PROMISE TO PAY",
  CALL_BACK_LATER: "CALL BACK LATER",
  MEETING_REQUEST: "MEETING REQUEST",
  RECORD_DISPUTE: "RECORD DISPUTE",
  UNSUCCESSFUL: "UNSUCCESSFUL",
  call_back_later: "Call back Later",
  meeting_request: " Meeting Request",
  promise: "Promise",
  callback: "Call back Days",
  dispute: "Dispute",
  unsuccessful: "Unsuccessful",
  promise_amount: "Promise Amount",
  hold_workflow_review_promise_date: "Pause Workflow review Promise Date",
  send_confirmation_message: "Send Confirmation Message",

  promise_to_pay_confirmation: "Promise to Pay Confirmation",
  callback_confirmation: "Call Back Confirmation",
  meeting_confirmation: "Meeting Confirmation",
  add_notes_for_internal_reference_here:
    "Add notes for internal reference here (optional)... ",
  comments: "Comments or Remarks",
  comm_type: "Type",
  call_back_time: "Call Back Time",
  meeting_time: "Meeting Time",
  send_promise_follow_up_message: "Send Promise Follow up Message",
  send_callback_follow_up_message: "Send Call Back Follow up Message",
  send_meeting_follow_up_message: "Send Meeting Follow up Message",
  recipients: "Recipients",
  sms_content: "SMS Content",
  review_and_send_sms: "Review and Send SMS",
  review_and_send_letter: "Review and Send Letter",
  letter_remainder: "Letter Remainder",
  review_and_send_email: "Review and Send Email",
  cc1: "CC",
  bcc1: "BCC",
  cc: "Press enter after each Email",
  bcc: "Press enter after each Email",
  subject: "Subject",
  select_date: "Select Date",
  full_amount: "Full Amount",
  promise_type: "Promise Type",
  promise_date: "Promise Date",
  planned_payment_amount: "Planned Payment Amount",
  planned_installation_amount: "Planned Installation Amount",

  planned_start_date: "Planned Start Date",
  planned_frequency: "Planned Frequency",
  promise_to_follow_up_message: "Promise to Follow-up Message ",
  callback_follow_up_message: "Call Back Follow-up Message ",
  meeting_follow_up_message: "Meeting Follow-up Message ",
  call_back_date: "Call Back Date",
  meeting_date: "Meeting Date",
  hold_workflow_till_call_back_date: "Pause workflow till Call Back Date",
  hold_workflow_till_meeting_date: "Pause workflow till Meeting Date",
  call_back_confirmation: "Call Back Confirmation",
  call_back_reminder_message: "Call Back reminder Message",
  user_comments_here: "User Comments Here",
  meeting_venue: "Meeting Venue",
  meeting_value: "Meeting Value",
  meeting_date: "Meeting Date",
  meeting_time: "Meeting Time",
  service_incomplete: "Service Incomplete",
  open: "Open",
  date_picker: "Date Picker",
  follow_up_call: "Follow-up Call",
  unsuccessful_reason: "Unsuccessful Reason",
  contact_unsuccessful: "Contact Unsuccessful",
  invoice_summary: "Invoice Summary",
  invoice_overview: "Invoice Overview",
  user: "Users",
  add_user: "Add User",
  payment_date: "Payment Date",
  payment_amount: "Payment Amount",
  payment_method: "Payment Method",
  payment_reference: "Payment Reference",
  payment_status: "Payment Status",
  expect_payment_status_date: "Expect Payment Status Date",
  user_notes: "User Notes",
  add_payments: "Add Payments",
  receiver: "Receiver",
  meeting_venue: "Meeting Venue",
  phone_type: "Phone Type",
  country_code: "Country Code",
  email_type: "Email Type",
  record_dispute: "Record Dispute",
  start_workflow_from: "Start Workflow From",
  note: "Note",
  take_a_note: "Take a Note",
  customer_workflow: "Customer Workflow",
  all_customers: "All Customers",
  customers: "Customers",
  invoices: "Invoices",
  amount_to_Pay: "Amount To Pay",
  day_1: "Day 1",
  day_5: "Day 5",
  day_14: "Day 14",
  day_19: "Day 19",
  day_25: "Day 25",
  upload_file_columns: "Upload File Columns",
  first_row_data: "First Row Data",
  data_format: "Data Format",
  save_mapping_for_future_reference: "Save Mapping for Future Reference",
  mapping_with_system_fields: "Mapping with System Fields",
  to: "To",
  hold_reason: "Pause Reason",
  hold_action_till: "Pause Action Till",
  followup_date: "Follow Up Date",
  capture_dispute_details: "Capture Dispute Details",
  dispute_type: "Dispute Type",
  dispute_review_date: "Dispute Review Date",
  upload_field: "Upload File Columns",
  format: "Data Format",
  system_field: "Mapping with System Fields",
  default_column: "Default Column",
  purpose: "Purpose",
  default: "default",
  save_mapping_for_future_reference: "Save Mapping For Future Reference:",
  organization_name: "Organization Name",
  file_name: "File Name",
  uploaded_by: "Uploaded By",
  uploaded_at: "Uploaded at",
  stats: "Stats",
  status: "Status",
  user_profile: "User Profile",
  invoices: "Default Value",
  upload: "Upload ID",
  organization: "Organization Name",
  z_name: "File Name",
  user: "Upload User",
  // date_&_time:"Date and Time"
  stats: "Upload Status",
  upload_from_system: "Upload From System",
  upload_attachments: "Upload Attachments",
  upload_drop_zone_caption: "Click here to upload or drag & drop",
  copy_template: "Copy Template",
  edit_template: "Edit Template",
  view_template: "View Template",
  delete_template: "Delete Template",
  template_disabled: "Template Disabled",
  template_enabled: "Template Enabled",
  workflow_disabled: "Workflow Disabled",
  workflow_enabled: "Workflow Enabled",
  phone_code: "Phone Code",
  start_date: "Start Date",
  log_in_time: "Log in Time",
  log_out_time: "Log out Time",
  ip_address: "IP Address",
  eg_password: "eg:password@123",
  account_currency: "Account Currency",
  user_maintenance: "User Maintenance",
  account_type: "Account Type",
  account_routing_no: "Account Routing No",
  account_no: "Account No",
  detail: "Detail",
  contact_person_name: "Contact Person Name",
  business_email: "Business Email ",
  next_action_name: "Next Action Name",
  unsucessful_reason: "Unsuccessful Reason",
  reference: "Reference",
  due_amount: "Due Amount",
  company_name: "Company Name",
  cus_currency: "Customer Currency",
  promise_comment: "Promise Comment",

  address_line_2_area_and_locality_details: "Address Line 2",
  hold_workflow_till_follow_up_date: "Pause workflow till follow up date",
  view_contact_details: "View Contact Details",
  edit_contact_details: "Edit Contact Details",
  allocated_invoice_numbers: "Allocated Invoice Numbers",
  pending_allocation: "Pending Allocation",
  edit_user: "Edit User",

  /** NEEDS ACTION LABELS **/
  CUSTOMER_WITH_NO_CONTACTS: "Customer with No Contacts",
  RELATION_MANAGER_NOT_ASSIGNED: "Relation Manager Not Assigned",
  WORKFLOW_NOT_ASSIGNED: "Workflow Not Assigned",
  ON_HOLD: "On-Pause Review",
  UNASSIGNED_CREDIT_NOTE: "Unassigned Credit Note",
  EXCESS_PAYMENTS: "Excess Payments",
  CALL_FOLLOWUP: "Call Follow-up",
  EMAIL_FOLLOWUP: "Email Follow-Up",
  SMS_FOLLOWUP: "SMS Follow-up",
  PENDING_PROMISE_REVIEWS: "Promise To Pay Review",
  PENDING_DISPUTE_REVIEWS: "Dispute Review",
  CREDIT_LIMIT_BREACHED: "Credit Limit Breached",
  CREDIT_LIMIT_WARNING: "Credit Limit Warning",
  is_active: "Active",
  is_2fa_setup: "2FA Setup",

  /** CUSTOMER ACTION STAGE LABELS **/
  settled: "Settled",
  promise_in_place: "Promise In Place",
  active_disputes: "Active Disputes",
  followup_in_place: "Follow Up In Place",
  followup_delayed: "Follow Up Delayed",
  workflow_closed: "Workflow Closed",
  adhoc_follow_up: "Adhoc Follow Up",
  skip_trace: "Skip Trace",
  yet_to_initiate: "Yet To Initiate",

  // sms gateway
  account_sid: "Account SID",
  auth_token: "Auth Token",
  phone_number: "Phone Number",
  provider: "Provider",

  from: "From",

  // org settings
  date_format: "Date Format",
  amount_format: "Amount Format",
  currency_format: "Currency Format",
  amount__format: "Select Your Amount Format",
  date__format: "Select Your Date Format",
  stop_customer: "Stop Customer Follow-ups when there is no Overdue Invoice",
  total_outstanding:
    "Start Customer Follow-ups when Credit Limit Exceeds Total Outstanding",

  // pricing page lang
  choose_a_plan_tailored_to_your_needs: "Choose a Plan tailored to your needs",
  ready_to_get_started: "Ready To Get Started?",
  back_to_ricing: "Back To Pricing",
  annual_plan: "Annual Plan",
  monthly_plan: "Monthly Plan",
  skip_for_free_trail: "Skip for free trail",
  upgrade_to_continue: "Upgrade to Continue",
  free_trial_expired: "Free Trial Expired",
  search_by_inv_number: "Search by Invoice Number",
  select_the_method: "Select The Method",
  secret_key: "Secret Key",
  online: "Online",
  offline: "Offline",
  audit_by: "Audit By",
  ref_name: "Account Name",
  help_text: "Help Text",
  payment_mode: "Payment Mode",
  payment_gateway: "Payment Gateway",
  secret_key: "Secret Key",
  audit_on: "Audit On",
  logo: "Logo",
  instruction: "Instruction",
  report_analytics: "Report Analytics",
  customer_currency: "Customer Currency",
  max_delay: "Max Delay",
  overdue_amount: "Overdue Amount",
  captured_on: "Captured On",
  action_comment: "Action Comment",
  template: "Template",
  action_outcome: "Action Outcome",
  promise_currency: "Promise Currency",
  promise_status: "Promise Status",
  disute_review_date: "Dispute Review Date",
  dispute_details: "Dispute Details",
  callback_date: "Callback Date",
  dispute_invoices: "Dispute Invoices",
  dispute_comment: "Dispute Comment",
  payment_currency: "Payment Currency",
  note_category: "Note Category",
  note_type: "Note Type",
  select_user: "Select User",
  select_report_by: "Filter Report By",
  allocated_to: "Allocated To",
  credit_limit_utilization: "Credit Limit Utilization",
  credit_limit_status: "Credit Limit Status",
  currentDue: "Current Due",
  b1: "1-30 Days",
  b2: "31-60 Days",
  b3: "61-90 Days",
  b4: "91 Days and Over",
  action_due_date: "Action Due Date",
  ageing_summary_report: "Ageing Summary Report",
  aging_summary_report: "Aging Summary Report",
  activity_report: "Activity Report",
  promise_to_pay_report: "Promise To Pay Report",
  dispute_report: "Dispute Report",
  payment_report: "Payment Report",
  note_report: "Notes Report",
  credit_limit_report: "Credit Limit Report",
  needs_action_report: "Needs Action Report",
  category: "Category",
  invoice: "Invoice",
  sub_category: "Sub Category",
  primary_contact: "Primary Contact",
  call_to_action: "Call To Action",
  footer: "Footer",
  phone_number_id: "Phone Number ID ",
  whatsapp_buisness_account_id: "Whatsapp Buisness Account ID",
  user_access_token: "User Access Token",
  review_and_send_whatsapp: "Review & Send Whatsapp",
  search_by_inv_number: "Search by inv Number",

  header_height: "Header Height",
  header_zoom: "Header Zoom",
  footer_height: "Footer Height",
  footer_zoom: "Footer Zoom",
  date_of_communication: "Date of Communication",
  delivery_method: "Delivery Method",
  tracking_url: "Tracking URL",
  delivery_status: "Delivery Status",
  audit_at: "Audit at",
  is_disabled: "Status",
  refetch_template: "Refetch Status",
  pricing_type: "Pricing Type",
  price_currency: "Price Currency",
  unit: "Unit",
  price: "Price",
  product_type: "Type",
  audit_date: "Audit Date",
  tax: "Tax",
  checkin_date: "Checkin Date",
  item: "Item",
  checkin_time: "Checkin Time",
  location: "Location",
  role_department: "Role & Dept",
  auth_type: "Auth Type",
  checkin_line_item: "Checkin Line Item",
  checkin_amount: "Checkin Amount",
  checkin_currency: "Checkin CCY",
  checkin_location: "Checkin Location",
  checkin_comment: "Checkin Comments",
  invoice_status: "Invoice Status",
  manual_status_user: "Manual Status User",
  recurring_invoice_currency: "Recurring Invoice Currency",
  invoice_name: "Recurring Invoice Name",
  skip_duration: "Skip Duration",
  total_line_item: "Total Line Item",
  sub_total: "Sub Total",
  discount_amount: "Discount",
  tax_amount: "Tax",
  paused: "Paused",
  paused_on: "Paused On",
  paused_by: "Paused By User",
  select_workflow: "Select Workflow",
  select_language: "Select Language",
  letter_name: "Letter Name",
  mapping_name: "Mapping Name",
  is_enabled: "Is Enabled",
  is_deleted: "Is Deleted",
  flat_fee_amount: "Flat fee Amount",
  admin_name: "Admin Name",
  auto_generate_invoice: "Auto Generate Invoice",
  last_billed_date: "Last Billed Date",
  last_billed_value: "Last Billed Value",
  add_revenue_and_billing: "Add Revenue & Billing",
  revenue_and_billing: "Revenue & Billing",
  pause_workflow_for_manual_recon: "Pause workflow for manual recon",
  user_location: "User Location",
  manager: "Manager",
  business_unit_name: "Client",
  business_unit_location: "Business Location",
  is_quick_action: "Is Paused",
  hold_quick_action_reason: "Pause Reason",
  hold_quick_action_till: "Pause Till Date",
  filters: "Filters",
  pause_reason: "Pause Reason",
  pause_action_on_this_customer_till: "Pause Action on this customer till",
  flat_fee: "Flat Fee",
  last_billed_start_date: "Last Billed Start Date",
  last_billed_end_date: "Last Billed End Date",
  last_billed_due_amount: "Last Billed Due Amount",
  last_billed_refund_amount: "Last Billed Refund Amount",
  last_billed_client_payment: "Last Billed Client Payment",
  last_billed_trust_payment: "Last Billed Trust Payment",
  last_billed_by: "Last Billed By",
  commission_percentage: "Commission Percentage",
  created_file_id: "Created File ID",
  updated_file_id: "Updated File ID",
  customer_dob: "Customer DOB",
  insurance: "Insurance",
  employer: "Employer",
  client_reference: "Client Reference",
  invoice_description: "Invoice Description",
  capture_notes_at: "Capture Notes at",
  map_based_on: "Map Based on",
  landline: "Landline",
  landline_type: "Landline Type",
  landline_code: "Landline Code",
  type_contact: "Type",
  mobile: "Mobile",
  search_anything: "Search Anything",
  search_by_customer_name: "Search by Cus Name",
  pause_workflow_till_follow_up_date: "Pause Workflow till follow-up date",
  follow_up_call: "Follow-Up Call",
  unsuccessful_reason: "Unsuccessful Reason",
  formula: "Formula",
  enter_formula: "Enter Formula",
  revenue_dashboard: "Revenue Dashboard",
  customer_stage: "Customer Stage",
  allow_payment_receipt_download: "Allow Payment Receipt Download",
  portal_visit: "Portal Visit",
  portal_payment: "Portal Payment",
  portal_promise: "Portal Promise",
  portal_callback: "Portal Call Back",
  sms_reply: "Customer SMS Reply",
  email_reply: "Customer Email Reply",
  whatsapp_reply: "WhatsApp Response",
  app_payment: "App Payment",
  customer_action_analysis: "Customer Action Analysis",
  is_display_invoice_description: "Display Invoice Description",
  days_since_created: "Days Since Created",
  sms_status: "SMS Status",
  last_action_date_dynamic: "Last Action Date Dynamic",
  next_action_date_dynamic: "Next Action Date Dynamic",
  last_customer_action: "Last Customer Action",
  last_customer_action_date: "Last Customer Action Date",
  last_customer_action_date_dynamic: "Last Customer Action Date Dynamic",
  invoice_date_dynamic: "Invoice Date Dynamic",
  due_date_dynamic: "Invoice Due Date Dynamic",
  last_customer_action_name: "Last Customer Action Name",
  created_on_dynamic: "Created on Dynamic",
  logout: "Logout",
  user_information: "User Information",
  password_setting: "Password Setting",
  contact_phone: "Contact Phone",
  save_changes: "Save Changes",
  change_password_info:
    "Changing your password every 90 days helps it to be secure",
  reset_2fa_info: "Reset 2FA when you are in need",
  invalid_report_type: "Invalid Report Type",
  today: "Today",
  week_to_date: "Week to Date",
  month_to_date: "Month to Date",
  quarterly_to_date: "Quarterly to Date",
  half_yearly_to_date: "Half Yearly to Date",
  custom_date: "Custom Date",
  general_and_application_preference: "General and Application Preference",
  general_and_application_preference_description:
    "General information of the Organization and Application side Preference",
  contact_details: "Contact Details",
  contact_details_description: "Organization contact details",
  payment_details: "Payment Details",
  payment_details_description: "Setup Payment information for the Organization",
  accounting_books: "Accounting Books",
  accounting_books_description:
    "Premium - Get personalized SMS delivery number andb SMS response read option",
  sms_gateway: "SMS Gateway",
  sms_gateway_description:
    "Configure SMS Gateway Keys to perform SMS - based Follow-ups",
  outbound_email: "Outbound Email",
  outbound_email_description: "",
  cloud_telephony: "Cloud Telephony",
  cloud_telephony_description: "",
  whatsapp: "WhatsApp",
  customer_portal: "Customer Portal",
  customer_portal_description:
    "Customize what information your customer can view in the portal and respond",
  automation_settings: "Automation Settings",
  default_configuration: "Default Configuration",
  tax_rate_maintenance: "Tax Rate Maintenance",
  organization_details: "Organization Details",
  edit: "Edit",
  delete: "Delete",
  application_preference: "Application Preference",
  payment_details: "Payment Details",
  payment_details_description: "Setup Payment information for the Organization",
  add_payment_method: "Add Payment Method",
  payment_setting: "Payment Setting",
  payment_setting_add_payment: "Add Payment",
  payment_setting_add_payment_description:
    "When enabled user can also do add payment within the Customer Payment option",
  payment_setting_auto_sync_payments: "Auto Sync Payments",
  payment_setting_auto_sync_payments_description:
    "When enabled, application will do auto sync of Payments added from Portal or Agent with Accounting Books",
  payment_setting_update_due_amount: "Update Due Amount",
  payment_setting_update_due_amount_description:
    "When enabled, Payments captured will reflect on the due amount automatically",
  payment_setting_auto_compute_promise_status: "Auto Compute Promise Status",
  payment_setting_auto_compute_promise_status_description:
    "When enabled,Promise Status will be computed automatically based on below status",
  county: "County",
};
