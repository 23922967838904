import {
  HeadingXLarge,
  ParagraphLarge,
  LabelLarge,
  ParagraphMedium,
} from "baseui/typography";

import {
  isOpenModal,
  refetchActions,
} from "../../../../../../../redux/actions";

import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../../../components/TextBox";
import {
  TextButton,
  SIZE,
  KIND,
} from "../../../../../../../components/TextButton";

import { useForm, Controller, useFieldArray, get } from "react-hook-form";
// import { TextArea } from "../../../../../components/TextArea";
import { TextArea } from "../../../../../../../components/TextArea";
import { SelectBox } from "../../../../../../../components/SelectBox";
// import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import React, { useContext, useEffect, useState } from "react";
import { NEW } from "../../../../../../../constants";
// import { getPaymentDatas } from "../../../../../services";
// import { useQuery } from "react-query";
// import {
//   createPaymentDetailsCustomerOverview,
//   getCommunicationTemplateList,
//   updatePaymentDetailsCustomerOverview,
// } from "../../../../../services";
import {
  createPaymentDetailsCustomerOverview,
  updatePaymentDetailsCustomerOverview,
} from "../../../../../../../services";

import { MaxyfiContext } from "../../../../../../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import getSelectValues from "../../../../../../../utils/getSelectValues";
import DateRangeSelect from "../../../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import Payment from "../../../../../../../assets/img/svg/CustomerOverViewIcon/Payment";
import { Input } from "baseui/input";
import PaymentTable from "../../../PaymentTable";

import {
  paymentAmount,
  allocatedAmount,
  emptyEditData,
  refetchPaymentActions,
} from "../../../../../../../redux/customerOverview/payment/action";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { tabMotion } from "../../../../../../../redux/actions";
// import { getActionData } from "../../../../../redux/actions";
import moment from "moment";
import { useMutation } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import setSelectValues from "../../../../../../../utils/setSelectValues";
import { utcTimstampToLocalDate } from "../../../../../../../utils/utcTimstampToLocalDate";

const FieldVisitPayment = ({
  setValue,
  watch,
  reset,
  control,
  setError,
  errors,
}) => {
  const {
    referenceData,
    currentOrganization,
    userInfo,
    defaultData,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const [settingData, setSettingData] = useState(null);
  const { id } = useParams();
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [blur, setBlur] = useState(1);

  const getParams = getSearchParams.get("customers");
  const intl = useIntl();
  const DataType = useSelector((s) => s.settingReducer.type);
  const [paymentData, setPaymentData] = useState();
  const dueAmount = useSelector((s) => s.customerPaymentReducer.dueAmount);

  const { customerDetails } = useSelector((s) => s.customerOverviewReducer);

  // OVERALL AMT
  const pendingAllocatedValue = useSelector(
    (s) => s.customerPaymentReducer.pendingAllocationAmount
  );

  let invoicesData = watch("invoices");

  // const schema = Yup.object().shape({
  //   payment_amount: Yup.number().max(),
  // });

  // const schema = Yup.object({
  //   payment_amount: Yup.number()
  //     .test(
  //       "maxDigitsAfterDecimal",
  //       "number field must have 2 digits after decimal or less",
  //       (payment_amount) => /^\d+(\.\d{1,2})?$/.test(payment_amount)
  //     )
  //     .transform((value) => (isNaN(value) ? undefined : value)),

  //   date: Yup.date().required("Payment Date is Required"),
  //   // payment_status: Yup.array().required("Status is Required"),
  //   payment_status: Yup.array()
  //     .min(1, "Required")
  //     .required("Status is Required"),
  // });

  // CHANGING AMT

  const changingAllocatedAmount = useSelector(
    (s) => s.customerPaymentReducer.allocatedAmount
  );

  // const addedallocatedAmountValue = changingAllocatedAmount.reduce(
  //   (previousValue, currentValue) => previousValue + currentValue.amount,
  //   0
  // );
  // useEffect(() => {
  //   if (pendingAllocatedValue) {
  //     setChangingAmt(pendingAllocatedValue - changingAllocatedAmount);

  //   } else {
  //     setChangingAmt(changingAmt - changingAllocatedAmount);

  //   }
  // }, [changingAllocatedAmount]);

  const actionType = useSelector((s) => s.customerPaymentReducer.actionType);

  const viewOnly = useSelector((s) => s.customerPaymentReducer.viewOnly);

  const editingDatas = useSelector((s) => s.customerPaymentReducer.editingData);

  const dispatch = useDispatch();

  const defaultValuesStatus = referenceData["payment_status"].filter(
    (i) => i.id === "CRD"
  );

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   reset,
  //   watch,
  //   setValue,
  //   setError,
  //   formState: { errors, isSubmitting },
  // } = useForm(
  //   {
  //     reValidateMode: "onChange",
  //     resolver: yupResolver(schema),
  //     defaultValues: {
  //       // payment_status: setSelectValues(defaultValuesStatus[0].id),
  //       cur: setSelectValues(customerDetails.default_currency),
  //     },
  //   }
  //   // {
  //   //   defaultValues: {
  //   //     // payment_status: defaultValuesStatus[0].id,
  //   //     payment_status: setSelectValues(defaultValuesStatus.id),
  //   //     // cur:
  //   //     //   referenceData["currency"] && referenceData["currency"][0]
  //   //     //     ? [referenceData["currency"][0]]
  //   //     //     : [],
  //   //     cur: setSelectValues(defaultData.currency),
  //   //   },
  //   // }
  // );

  //
  let selectedCurrency = watch("cur");

  // let paymentStatus = watch("payment_status");
  // let invoices = watch("invoices");

  // const getPayment = async () => {
  //   let data = await getPaymentDatas({
  //     paymentId: id,
  //     organization: currentOrganization,
  //   });

  //   // setDairyNotes(data.data.docs);
  // };

  // useEffect(()=>{

  // },[paymentData])

  // useEffect(() => {
  //   if (["RVS", "DEL", "BNC"].includes(paymentStatus)) {
  //     invoices.map((inv, i) => {
  //       setValue(`invoices[${i}].allocatedAmount`, 0);
  //     });
  //   }
  // }, [paymentStatus]);

  let formValues = watch();

  let onlyChangedValue = formValues?.invoices?.filter(
    (i) => i.allocatedAmount != undefined
  );
  let changedKeyValues = onlyChangedValue?.map((i) => {
    return { invoice_id: i._id, amount: Number(i.allocatedAmount) };
  });

  let allocatedMappingVales = formValues.invoices?.map((i) =>
    Number(i.allocatedAmount)
  );

  const quickSum = (allocatedMappingVales) => {
    const sum = allocatedMappingVales?.reduce((acc, val) => {
      return acc + (val || 0);
    }, 0);

    return sum;
  };
  let allocatedValue = quickSum(allocatedMappingVales);

  let pendingAllocation = pendingAllocatedValue - allocatedValue;

  useEffect(() => {
    let allocatedInvoicesKeys = Object.keys(formValues).filter((key) =>
      key.startsWith("allocated_amount")
    );
    let allocatedInvoices = allocatedInvoicesKeys.reduce((preVal, curVal) => {
      return { ...preVal, [curVal]: formValues[curVal] };
    }, {});

    dispatch(paymentAmount(formValues.payment_amount));

    dispatch(allocatedAmount(allocatedInvoices));
  }, [formValues.payment_amount]);

  const postPaymentValues = useMutation(
    (data) =>
      createPaymentDetailsCustomerOverview({
        organization: currentOrganization,
        customerId: getParams,
        data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();

        dispatch(isOpenModal(false));
        dispatch(tabMotion(3));
        dispatch(refetchActions());
        dispatch(refetchPaymentActions());
      },
    }
  );

  const updatePaymentValues = useMutation(
    (data) =>
      updatePaymentDetailsCustomerOverview({
        organization: currentOrganization,
        customerId: getParams,
        data,
        contactId: editingDatas._id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();

        dispatch(isOpenModal(false));
        dispatch(tabMotion(3));
        dispatch(emptyEditData());
        dispatch(refetchActions());
        dispatch(refetchPaymentActions());
      },
    }
  );

  // const onSubmit = async (data) => {
  //   const orgRef = referenceData.organizations.find(
  //     ({ id }) => id === currentOrganization
  //   );

  //   const timeZone = orgRef.time_zone;

  //   let allocatedTo = { ...data };
  //   let findingData = Object.keys(allocatedTo);
  //   let filteringKeys = findingData.filter((i) =>
  //     i.startsWith("allocated_amount")
  //   );

  //   const removingAllocated = filteringKeys.map((a) => {
  //     return a.replace("allocated_amount-", "");
  //   });

  //   let changedData = removingAllocated.reduce((prev, curr) => {
  //     if (allocatedTo[`allocated_amount-${curr}`]) {
  //       return [
  //         ...prev,
  //         {
  //           invoice_id: curr,
  //           amount: Number(allocatedTo[`allocated_amount-${curr}`]),
  //         },
  //       ];
  //     }
  //     return [...prev];
  //   }, []);

  //   // HERE;

  //   if (editingDatas?.length == 0) {
  //     let allocatedTo = { ...data };

  //     // let findingData = Object.keys(allocatedTo);
  //     // let filteringKeys = findingData.filter((i) =>
  //     //   i.startsWith("allocated_amount")
  //     // );

  //     // const removingAllocated = filteringKeys.map((a) => {
  //     //   return a.replace("allocated_amount-", "");
  //     // });

  //     // let changedData = removingAllocated.reduce((prev, curr) => {
  //     //   if (allocatedTo[`allocated_amount-${curr}`]) {
  //     //     return [
  //     //       ...prev,
  //     //       {
  //     //         invoice_id: curr,
  //     //         amount: Number(allocatedTo[`allocated_amount-${curr}`] || 0),
  //     //       },
  //     //     ];
  //     //   }
  //     //   return [...prev];
  //     // }, []);

  //     let values = {
  //       date: moment
  //         .tz(
  //           `${data.date.getFullYear()}-${
  //             data.date.getMonth() + 1
  //           }-${data.date.getDate()}`,
  //           "YYYY-MM-DD",
  //           timeZone
  //         )
  //         .utc()
  //         .valueOf(),
  //       // amount: {
  //       //   value: data.payment_amount,
  //       //   currency: getSelectValues(data.cur),
  //       // },
  //       amount: {
  //         value: data.payment_amount,
  //         currency: getSelectValues(data.cur),
  //       },
  //       payment_currency: getSelectValues(data.cur),
  //       method: getSelectValues(data.payment_method),
  //       reference: data.payment_reference,
  //       status: getSelectValues(data.payment_status),
  //       ...(data.expected_payment_date &&
  //       data.expected_payment_date?.getFullYear()
  //         ? {
  //             expected_date: moment
  //               .tz(
  //                 `${data.expected_payment_date?.getFullYear()}-${
  //                   data.expected_payment_date?.getMonth() + 1
  //                 }-${data.expected_payment_date?.getDate()}`,
  //                 "YYYY-MM-DD",
  //                 timeZone
  //               )
  //               .utc()
  //               .valueOf(),
  //           }
  //         : { expected_date: 1 }),
  //       comment: data.comment,
  //       ...(paymentStatus &&
  //       paymentStatus[0] &&
  //       paymentStatus[0].id != "success"
  //         ? {}
  //         : { allocated_to: changedKeyValues }),
  //     };

  //     await postPaymentValues.mutateAsync(values);
  //   } else {
  //     // let allocatedTo = { ...data };
  //     // let findingData = Object.keys(allocatedTo);
  //     // let filteringKeys = findingData.filter((i) =>
  //     //   i.startsWith("allocated_amount")
  //     // );

  //     // const removingAllocated = filteringKeys.map((a) => {
  //     //   return a.replace("allocated_amount-", "");
  //     // });

  //     // let changedData = removingAllocated.reduce((prev, curr) => {
  //     //   if (allocatedTo[`allocated_amount-${curr}`]) {
  //     //     return [
  //     //       ...prev,
  //     //       {
  //     //         invoice_id: curr,
  //     //         amount: Number(allocatedTo[`allocated_amount-${curr}`] || 0),
  //     //       },
  //     //     ];
  //     //   }
  //     //   return [...prev];
  //     // }, []);

  //     let values = {
  //       date: moment(data.date).utc().valueOf(),
  //       amount: {
  //         value: data.payment_amount,
  //         currency: getSelectValues(data.cur),
  //       },
  //       payment_currency: getSelectValues(data.cur),

  //       method: getSelectValues(data.payment_method),
  //       reference: data.payment_reference,
  //       status: getSelectValues(data.payment_status),
  //       ...(data.expected_payment_date &&
  //       data.expected_payment_date.getFullYear()
  //         ? { expected_date: 1 }
  //         : {
  //             expected_date: moment
  //               .tz(
  //                 `${data.expected_payment_date.getFullYear()}-${
  //                   data.expected_payment_date.getMonth() + 1
  //                 }-${data.expected_payment_date.getDate()}`,
  //                 "YYYY-MM-DD",
  //                 timeZone
  //               )
  //               .utc()
  //               .valueOf(),
  //           }),
  //       comment: data.comment,
  //       ...(paymentStatus &&
  //       paymentStatus[0] &&
  //       paymentStatus[0].id != "success"
  //         ? {}
  //         : { allocated_to: changedKeyValues }),
  //     };

  //     await updatePaymentValues.mutateAsync(values);
  //   }
  // };

  let rdData = referenceData["currency"];

  let customerCurrency = customerDetails.invoice_currencies;

  let filteringCurrency = rdData.filter(
    (i) => customerCurrency && customerCurrency.includes(i.id)
  );

  // rdData.filter((e, i) => e.id === customerDetails.invoice_currencies[i])

  return (
    <div
      style={{
        gridColumn: "2/5",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      // onSubmit={handleSubmit(onSubmit)}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <div
          // onSubmit={handleSubmit(onSubmit)}
          style={
            {
              // display: "grid",
              // gridTemplateColumns: "repeat(10,50fr)",
              // gridTemplateRows: "repeat(12,10fr)",
              // height: "100%",
            }
          }
        >
          {/* <form
          style={{ padding: "15px 35px", height: "100%", overflow: "scroll" }}
        > */}
          <div
            style={
              {
                //   height: "1000%",
                //   overflow: "scroll",
                // padding: "15px 35px",
              }
            }
          >
            <div
            // style={{ padding: "15px 35px" }}
            >
              <div
                style={{
                  display: "flex",
                  //   gap: "20px",
                  justifyContent: "space-between",
                  //   alignItems: "center",
                }}
              >
                <div style={{ width: "300px" }}>
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <DateRangeSelect
                        disabled={viewOnly}
                        clearable={false}
                        requiredAstric={true}
                        size={SIZE.default}
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "payment_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                        // selected={field.value}
                      />
                    )}
                  />
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <div style={{ width: "75px" }}>
                    <Controller
                      name="cur"
                      defaultValues={[]}
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          disabled={viewOnly}
                          size={SIZE.default}
                          {...field}
                          name={field.name}
                          clearable={false}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "ccy",
                          })}
                          placeholder={intl.formatMessage({
                            id: "ccy",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={filteringCurrency}
                        />
                      )}
                    />
                  </div>
                  <div style={{ width: "238px" }}>
                    <Controller
                      name="payment_amount"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <TextBox
                          requiredAstric={true}
                          disabled={viewOnly}
                          size={SIZE.default}
                          {...field}
                          name={field.name}
                          type="number"
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "payment_amount",
                          })}
                          placeholder={intl.formatMessage({
                            id: "payment_amount",
                          })}
                          // value={paymentAmount}
                          onBlur={() => {
                            if (
                              currentDefaultFormatDetails.accounting_book !==
                              "QUICKBOOKS"
                            ) {
                              setBlur(blur + 1);
                            }
                          }}
                          onKeyDown={(e) => {
                            if (
                              e.key == "ArrowUp" ||
                              e.key == "ArrowDown" ||
                              e.key == "-"
                            ) {
                              e.preventDefault();
                            }
                          }}
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
                <div style={{ width: "300px" }}>
                  <Controller
                    defaultValues={[]}
                    name="payment_method"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        disabled={viewOnly}
                        size={SIZE.default}
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "payment_method",
                        })}
                        placeholder={intl.formatMessage({
                          id: "payment_method",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["payment_method"]}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                pattingTop: "15px",
                // paddingLeft: "35px",
                // paddingRight: "35px",
                gap: "10px",
              }}
            >
              <div style={{ width: "300px" }}>
                <Controller
                  name="payment_reference"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      disabled={viewOnly}
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "payment_reference",
                      })}
                      placeholder={intl.formatMessage({
                        id: "payment_reference",
                      })}
                      value={field.value}
                    />
                  )}
                />
              </div>
              <div style={{ width: "326px" }}>
                <Controller
                  defaultValues={[]}
                  name="payment_status"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      requiredAstric={true}
                      disabled={viewOnly}
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      clearable={false}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "payment_status",
                      })}
                      placeholder={intl.formatMessage({
                        id: "payment_status",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["payment_status"]}

                      //   options={referenceData["dispute_status"]}
                    />
                  )}
                />
              </div>
              {/* {formValues &&
                formValues.payment_status &&
                formValues.payment_status[0].id === "DEP" ? (
                  <> */}
              {/* <div style={{ width: "300px" }}>
                  <Controller
                    name="expected_payment_date"
                    control={control}
                    render={({ field }) => (
                      <DateRangeSelect
                        disabled={viewOnly}
                        clearable={false}
                        size={SIZE.default}
                        {...field}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "expected_payment_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                        // selected={field.value}
                      />
                    )}
                  />
                </div> */}
              {/* </>
                ) : (
                  <div style={{ width: "300px" }}></div>
                )} */}
              <div style={{ width: "300px" }}></div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "19px",
                // paddingLeft: "35px",
                // paddingRight: "35px",
              }}
            >
              <div>
                <LabelLarge>Allocate to invoice</LabelLarge>
                {/* <ParagraphMedium style={{ color: "#ADADAD" }}>
                    Track your Invoices details and status
                  </ParagraphMedium> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  //   justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <ParagraphMedium style={{ color: "#ADADAD" }}>
                  Pending Allocation:
                </ParagraphMedium>
                <div style={{ width: "95px" }}>
                  <Input
                    disabled={true}
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          borderRadius: "10px",
                        }),
                      },
                    }}
                    value={pendingAllocatedValue - allocatedValue}
                  />
                </div>
              </div>
            </div>
            {pendingAllocatedValue - allocatedValue < 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "19px",
                    paddingLeft: "35px",
                    paddingRight: "35px",
                    color: "red",
                  }}
                >
                  Allocation is greater than Payment Amount
                </div>
              </>
            ) : (
              <></>
            )}
            <div
              style={{
                paddingTop: "20px",
                // paddingLeft: "35px",
                // paddingRight: "35px",
              }}
            >
              <PaymentTable
                setValue={setValue}
                watch={watch}
                reset={reset}
                control={control}
                errors={errors}
                setError={setError}
                selectedCurrency={selectedCurrency}
                blur={blur}
                invoicesData={invoicesData}
                pendingAllocationValue={pendingAllocation}
              />
              {/* <DisputeTable /> */}
            </div>
            <div
              style={{
                paddingTop: "20px",
                // paddingLeft: "35px",
                // paddingRight: "35px",
              }}
            >
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextArea
                    disabled={viewOnly}
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comments",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>

          {/* <div
            style={{
              background: "#F9F9F9",
              // width: "100%",
              height: "70px",
              padding: "15px",
              borderTop: "1px solid #CDCED9",
              gridRow: "12/13",
            }}
          >
            {pendingAllocatedValue === 0 ||
            pendingAllocatedValue - allocatedValue < 0 ||
            dueAmount < 0 ? (
              <>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        disabled={viewOnly}
                        size={SIZE.compact}
                        kind={KIND.tertiary}
                        fullWidth
                        type="button"
                        onClick={() => {
                          dispatch(tabMotion(3));
                          dispatch(isOpenModal(false));
                        }}
                      >
                        Cancel
                      </TextButton>
                    </div>

                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        type="submit"
                        isLoading={isSubmitting}
                        // disabled={isSubmitting}
                        disabled={true}
                      >
                        Save
                      </TextButton>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        // disabled={viewOnly}
                        size={SIZE.compact}
                        kind={KIND.tertiary}
                        fullWidth
                        type="button"
                        onClick={() => {
                          dispatch(tabMotion(3));
                          dispatch(isOpenModal(false));
                        }}
                      >
                        Cancel
                      </TextButton>
                    </div>

                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        disabled={viewOnly}
                        size={SIZE.compact}
                        fullWidth
                        type="submit"
                        isLoading={isSubmitting}
                        // disabled={isSubmitting}
                      >
                        Save
                      </TextButton>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FieldVisitPayment;
