import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
} from "baseui/modal";
import React, { useState } from "react";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import UserLocked from "../../assets/img/lockuser.png";
import { Typography } from "../../components_v2";
import useFormat from "../../hooks/useFormat";
const AgentLocked = ({ vistedUser }) => {
  const format = useFormat();
  const [open, setOpen] = useState(true);
  return (
    <Modal
      // onClose={() => setOpen(false)}
      isOpen={open}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Close: {
          style: () => ({
            display: "none",
          }),
        },
        Dialog: {
          style: () => ({
            width: "347px",
            height: "251px",
            // padding: "25px 0px 25px",
            backgroundColor: "#FFFF",
          }),
        },
      }}
    >
      <div className="userlock-main-popover-container">
        <div className="userlock-sub-container">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <img src={UserLocked} alt="user locked" width={49} height={49} />
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography type="p" className="text-secondary">
              Consumer Locked by the User
            </Typography>
            <Typography type="h3">
              {format.rd({ id: vistedUser, name: "users" })}
            </Typography>
          </div>

          <TextButton
            kind={KIND.secondary}
            size={SIZE.compact}
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </TextButton>
        </div>
      </div>
    </Modal>
  );
};

export default AgentLocked;
