import { ParagraphMedium } from "baseui/typography";
import React, { useContext, useState } from "react";
import DownloadSvg from "../../../assets/img/svg/DownloadSvg";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { invoiceDownload } from "../../../services";
import { IconButton, KIND, SIZE } from "../../IconButton";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { StatefulTooltip } from "baseui/tooltip";
import Comment from "../../../assets/img/svg/Comment";
import { Icon } from "../../../components_v2";

const InvoiceDownload = (props) => {
  let { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;

  let navigate = useNavigate();

  value = _.get(original, accessor, "");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        gap: "5px",
        flex: "1",
        alignItems: "center",
      }}
    >
      <ParagraphMedium
        $style={{ color: "#516beb", cursor: "pointer" }}
        onClick={() => {
          navigate(`/invoices?inv=${props.row.original._id}`);
        }}
      >
        {value}
      </ParagraphMedium>
      {original?.is_hold && <Icon icon={"pause"} color={"#FD372A"} size={18} />}

      {/* {(currentDefaultFormatDetails.accounting_book == "CSV" &&
        original?.is_system_generated) ||
      (currentDefaultFormatDetails.accounting_book == "CSV" &&
        original?.invoive_file_doc_id) ||
      (currentDefaultFormatDetails?.accounting_book !== "CSV" &&
        currentDefaultFormatDetails?.accounting_book !== "ROTESSA") ? (
       
      ) : (
        <></>
      )} */}
    </div>
  );
};

export default InvoiceDownload;
