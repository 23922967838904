import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SelectBox, SIZE } from "../../components/SelectBox";
import { useIntl } from "react-intl";
import { KIND, TextButton } from "../../components/TextButton";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";

import getSelectValues from "../../utils/getSelectValues";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  CO_INVOICES_,
  CUSTOMER_OVERVIEW_,
  DOC_QUICK_VIEW_LIST,
  EDIT,
  NEW,
} from "../../constants";
import setSelectValues from "../../utils/setSelectValues";
import { creatCreditReports } from "../../services/documentSystem";

import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";

import _, { values } from "lodash";
import queryClient from "../../providers/queryClient";
import { TextBox } from "../../components/TextBox";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import moment from "moment";
import useFormat from "../../hooks/useFormat";
import { setDrawerState } from "../../redux/actions";
const AddCreditReport = () => {
  let intl = useIntl();
  let dispatch = useDispatch();
  const editorRef = useRef(null);
  let { drawerType, documentdetails } = useSelector(
    (e) => e.customerDocumentReducer
  );
  let { drawerInvoiceType, documentInvoicedetails } = useSelector(
    (s) => s.invoiceDocumentReducer
  );
  const { customerId } = useParams();
  const {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    space,
    users,
    defaultData,
    appAccessRest,
  } = useContext(MaxyfiContext);

  const [files, setFiles] = useState([]);
  const format = useFormat();

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    userInfo,
    formState: { errors, isSubmitting },
  } = useForm({});
  const [checked, setChecked] = React.useState(false);
  const BlockEmbed = ReactQuill.Quill.import("blots/embed");
  const [isOpenContactInfo, setOpenContactInfo] = useState(false);
  const checked1 = watch("is_sup_crd_rep", false); // watching the checkbox state
  const checked2 = watch("is_del_crd_rep", false); // watching the checkbox state
  const debtorNumber = watch("dbt_no", []);
  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  // useEffect(() => {
  //   if (
  //     debtorNumber.length > 0 &&
  //     debtorNumber[0].createdAt &&
  //     !_.get(customerData, "data.doc.crd_rep_conf.bru_date", null)
  //   ) {
  //     // Parse the createdAt date and set it as the default value for bru_date
  //     const [day, month, year] = debtorNumber[0].createdAt.split("-");
  //     const createdAtDate = new Date(`${year}-${month}-${day}`);
  //     setValue("bru_date", createdAtDate);
  //   }
  // }, [debtorNumber, setValue]);
  // AT MENTION STARTS

  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          // let atValues = users.filter((e) => {
          //   e.id !== userInfo._id;
          // });
          //
          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );
  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);
  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  const contactsOptions = useMemo(() => {
    const contacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = [...address]
          .map(({ status, address_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name ? curr?.first_name : ""} - ${
                nRest.line_1
              }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
                nRest.city
              }-${nRest.zip_code} ${designation?.label}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              address_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.address_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      },
      []
    );
    return contacts;
  }, [customerData?.data, customerId]);

  const createCreditMutation = useMutation(
    (data) =>
      creatCreditReports({
        customerId: customerId,
        organization: currentOrganization,
        data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        queryClient.invalidateQueries(`CREDIT_REPORT-${customerId}`);

        closeDrawer();
        reset();
      },
    }
  );

  useEffect(() => {
    if (
      typeof _.get(customerData, "data.doc.crd_rep_conf", {}) === "object" &&
      Object.keys(_.get(customerData, "data.doc.crd_rep_conf", {})).length > 0
    ) {
      setValue(
        "dbt_no",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.dbt_no", []),
          true
        )
      );

      setValue(
        "is_sup_crd_rep",

        _.get(customerData, "data.doc.crd_rep_conf.is_sup_crd_rep", false)
      );

      setValue(
        "is_del_crd_rep",
        _.get(customerData, "data.doc.crd_rep_conf.is_del_crd_rep", false)
      );
      setValue(
        "status",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.status", ""),
          false
        )
      );
      const bruDate = _.get(
        customerData,
        "data.doc.crd_rep_conf.bru_date",
        null
      );
      setValue(
        "bru_date",
        bruDate
          ? moment
              .tz(
                moment(bruDate).isValid() ? bruDate : new Date(),
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf()
          : null
      );
      setValue(
        "bru_spl_comt",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.bru_spl_comt", ""),
          false
        )
      );
      setValue(
        "bru_cmplnc_cde",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.bru_cmplnc_cde", ""),
          false
        )
      );
      setValue("notes", _.get(customerData, "data.doc.crd_rep_conf.notes", ""));
      setValue(
        "dbtr_nme",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.dbtr_nme", ""),
          false
        )
      );
      setValue(
        "ecoa",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.ecoa", ""),
          false
        )
      );
      setValue(
        "bnk_sts",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.bnk_sts", ""),
          false
        )
      );
    }
    const adCoSnr = _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr", {});
    if (
      typeof adCoSnr === "object" &&
      Object.keys(adCoSnr).length > 0 &&
      ["dbtr_nme", "asoctn", "ecoa", "prcntge", "amt"].some(
        (key) => adCoSnr[key]
      )
    ) {
      setOpenContactInfo(true);
      setValue(
        "ad_co_snr.dbtr_nme",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr.dbtr_nme", ""),
          false
        )
      );
      setValue(
        "ad_co_snr.asoctn",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr.asoctn", ""),
          false
        )
      );
      setValue(
        "ad_co_snr.ecoa",
        setSelectValues(
          _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr.ecoa", ""),
          false
        )
      );
      setValue(
        "ad_co_snr.prcntge",
        _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr.prcntge", null)
          ? Number(
              _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr.prcntge", 0)
            )
          : null
      );
      setValue(
        "ad_co_snr.amt",
        _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr.amt", null)
          ? Number(
              _.get(customerData, "data.doc.crd_rep_conf.ad_co_snr.amt", 0)
            )
          : null
      );
    }
  }, [
    customerData &&
      customerData?.data &&
      customerData?.data?.doc &&
      customerData?.data?.doc?.crd_rep_conf,
  ]);

  const onSubmit = async (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(_.get(data, "notes", ""), "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));
    const values = {
      dbt_no: getSelectValues(_.get(data, "dbt_no", []), true),
      is_sup_crd_rep: _.get(data, "is_sup_crd_rep", false),
      is_del_crd_rep: _.get(data, "is_del_crd_rep", false),
      status: getSelectValues(_.get(data, "status", ""), false),
      ...(_.get(data, "bru_date", null)
        ? {
            bru_date: moment(_.get(data, "bru_date", null)).utc().valueOf(),
          }
        : {}),
      bru_spl_comt: getSelectValues(_.get(data, "bru_spl_comt", ""), false),
      bru_cmplnc_cde: getSelectValues(_.get(data, "bru_cmplnc_cde", ""), false),
      notes: _.get(data, "notes", ""),
      dbtr_nme: getSelectValues(_.get(data, "dbtr_nme", ""), false),
      ecoa: getSelectValues(_.get(data, "ecoa", ""), false),
      bnk_sts: getSelectValues(_.get(data, "bnk_sts", ""), false),
      ad_co_snr: {
        ...(_.get(data, "ad_co_snr.dbtr_nme", [])
          ? {
              dbtr_nme: getSelectValues(
                _.get(data, "ad_co_snr.dbtr_nme", []),
                false
              ),
            }
          : {}),
        ...(_.get(data, "ad_co_snr.asoctn", [])
          ? {
              asoctn: getSelectValues(
                _.get(data, "ad_co_snr.asoctn", []),
                false
              ),
            }
          : {}),

        ...(_.get(data, "ad_co_snr.ecoa", [])
          ? {
              ecoa: getSelectValues(_.get(data, "ad_co_snr.ecoa", []), false),
            }
          : {}),
        ...(_.get(data, "ad_co_snr.prcntge", null)
          ? { prcntge: Number(_.get(data, "ad_co_snr.prcntge", 0)) }
          : {}),
        ...(_.get(data, "ad_co_snr.amt", null)
          ? { amt: Number(_.get(data, "ad_co_snr.amt", 0)) }
          : {}),
      },
    };

    await createCreditMutation.mutateAsync(values);
  };

  const invOption = useMemo(() => {
    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
        createdAt: moment
          .utc(e.created_at)
          .tz(currentDefaultFormatDetails.time_zone)
          .format(currentDefaultFormatDetails.date_format),
        label: `${format.rd({
          id: _.get(e, "business_unit", ""),
          name: "business_unit_list",
        })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
          e,
          "invoice_number",
          ""
        )}${_.get(e, "invoice_number", "") ? " / " : ""}
      ${_.get(e, "client_reference", "")}${
          _.get(e, "client_reference", "") ? " / " : ""
        }
      ${moment
        .utc(e.invoice_date)
        .tz(currentDefaultFormatDetails.time_zone)
        .format(currentDefaultFormatDetails.date_format)}${
          _.get(not_paid_amount, "currency", "") ? " / " : ""
        }${_.get(not_paid_amount, "currency", "")}${
          _.get(not_paid_amount, "value", "") ? " " : ""
        }${_.get(not_paid_amount, "value", "")}`,
      }));
    setValue("invoices", [...invIds]);
    // setCurrentInvoice([...invIds]);
    return invIds;
  }, [invoicesData]);

  return (
    <form style={{ marginBottom: "15px" }} onSubmit={handleSubmit(onSubmit)}>
      <div className="dm_select_container">
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="dbt_no"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                requiredAstric={true}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "Debt Number",
                })}
                placeholder={intl.formatMessage({
                  id: "Debt Number",
                })}
                getValueLabel={({ option }) => {
                  const { label } = option;
                  const value = label ? label.split("/") : [];
                  return <>{value && value[1] ? value[1] : value[0]}</>;
                }}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                multi
                options={invOption}
              />
            )}
          />
        </div>
      </div>
      <div style={{ width: "60%", paddingBottom: "10px" }}>
        <Controller
          control={control}
          name="is_sup_crd_rep"
          render={({ field }) => (
            <Checkbox
              checked={checked1}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              labelPlacement={LABEL_PLACEMENT.right}
              overrides={{
                Checkmark: {
                  style: ({ $theme, $checked }) => ({
                    border: "0.1px solid #CDCED9",
                    borderRadius: "5px",
                    backgroundColor: $checked ? "#516BEB" : "#FFFFFF",
                  }),
                },
                Label: {
                  style: ({ $theme }) => ({
                    paddingLeft: "3px",
                    fontWeight: "normal",
                  }),
                },
              }}
            >
              Suppress from Credit Reporting
            </Checkbox>
          )}
        />
      </div>
      <div style={{ width: "60%", paddingBottom: "10px" }}>
        <Controller
          control={control}
          name="is_del_crd_rep"
          render={({ field }) => (
            <Checkbox
              checked={checked2}
              onChange={(e) => {
                field.onChange(e.target.checked);
              }}
              labelPlacement={LABEL_PLACEMENT.right}
              overrides={{
                Checkmark: {
                  style: ({ $theme, $checked }) => ({
                    border: "0.1px solid #CDCED9",
                    borderRadius: "5px",
                    backgroundColor: $checked ? "#516BEB" : "#FFFFFF",
                  }),
                },
                Label: {
                  style: ({ $theme }) => ({
                    paddingLeft: "3px",
                    fontWeight: "normal",
                  }),
                },
              }}
            >
              Delete from Credit Reporting
            </Checkbox>
          )}
        />
      </div>
      <div className="dm_select_container">
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="status"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "Status ",
                })}
                placeholder={intl.formatMessage({
                  id: "Status ",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["credit_report_conf_status"]}
              />
            )}
          />
        </div>
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="bru_date"
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                size={SIZE.mini}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "bru_date",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                // minDate={new Date()}
              />
            )}
          />
        </div>
      </div>
      <div className="dm_select_container">
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="bru_spl_comt"
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "Bureau Special Comments ",
                })}
                placeholder={intl.formatMessage({
                  id: "Bureau Special Comments ",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                // options={invoiceData}
                // options={[
                //   { label: "INVOICE", id: "6398254bd254066155ad9de2" },
                // ]}
                options={referenceData["credit_report_bureau_spl_cmt"].sort(
                  (a, b) => a.label.localeCompare(b.label)
                )}
              />
            )}
          />
        </div>
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="bru_cmplnc_cde"
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                creatable={creatablePermission ? true : false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "Condition Code ",
                })}
                placeholder={intl.formatMessage({
                  id: "Condition Code ",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                // options={
                //   subCategoryData &&
                //   subCategoryData.map((i) => {
                //     return {
                //       label: i.label,
                //       id: i.id,
                //     };
                //   })
                // }
                options={referenceData["credit_report_bureau_cmplnc_cde"].sort(
                  (a, b) => a.label.localeCompare(b.label)
                )}
              />
            )}
          />
        </div>
      </div>
      <div style={{ width: "100%", marginTop: "10px", marginBottom: "20px" }}>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <ReactQuill
              {...field}
              name={field.name}
              id={"user-comment-editor"}
              ref={editorRef}
              theme="snow"
              placeholder="User Comments"
              value={field.value}
              modules={modules}
              style={{ height: "80px" }}
            />
          )}
        />
      </div>
      <div className="dm_select_container">
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="dbtr_nme"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "Debtor Name",
                })}
                placeholder={intl.formatMessage({
                  id: "Debtor Name",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={contactsOptions}
              />
            )}
          />
        </div>
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="ecoa"
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "ECOA",
                })}
                placeholder={intl.formatMessage({
                  id: "ECOA",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["credit_report_ecoa"]}
              />
            )}
          />
        </div>
      </div>

      <div style={{ width: "48%" }}>
        <Controller
          control={control}
          name="bnk_sts"
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "Bankruptcy Status",
              })}
              placeholder={intl.formatMessage({
                id: "Bankruptcy Status",
              })}
              size={SIZE.mini}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={referenceData["credit_report_bankruptcy_status"]}
            />
          )}
        />
      </div>

      <div style={{ width: "60%", paddingBottom: "10px" }}>
        <TextButton
          type="button"
          size={SIZE.mini}
          kind={KIND.secondary}
          onClick={() => {
            setOpenContactInfo(true);
          }}
        >
          Add Co-Signer
        </TextButton>
      </div>
      {isOpenContactInfo && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="dm_select_container">
            <div style={{ width: "48%" }}>
              <Controller
                control={control}
                name="ad_co_snr.dbtr_nme"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "Debtor Name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Debtor Name",
                    })}
                    size={SIZE.mini}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={contactsOptions}
                  />
                )}
              />
            </div>
            <div style={{ width: "48%" }}>
              <Controller
                control={control}
                name="ad_co_snr.asoctn"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "Association",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Association",
                    })}
                    size={SIZE.mini}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["document_category"]}
                  />
                )}
              />
            </div>
          </div>
          <div className="dm_select_container">
            <div style={{ width: "48%" }}>
              <Controller
                control={control}
                name="ad_co_snr.ecoa"
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "ECOA",
                    })}
                    placeholder={intl.formatMessage({
                      id: "ECOA",
                    })}
                    size={SIZE.mini}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["credit_report_ecoa"]}
                  />
                )}
              />
            </div>
            <div style={{ width: "48%" }}>
              <Controller
                control={control}
                name="ad_co_snr.prcntge"
                render={({ field }) => (
                  <TextBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "Percentage",
                    })}
                    placeholder={intl.formatMessage({
                      id: "Percentage",
                    })}
                    size={SIZE.mini}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e?.target?.value);
                    }}
                    type="number"
                  />
                )}
              />
            </div>
          </div>
          <div style={{ width: "48%" }}>
            <Controller
              control={control}
              name="ad_co_snr.amt"
              render={({ field }) => (
                <TextBox
                  {...field}
                  name={field.name}
                  type="number"
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "Amount",
                  })}
                  placeholder={intl.formatMessage({
                    id: "Amount",
                  })}
                  size={SIZE.mini}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e?.target?.value);
                  }}
                />
              )}
            />
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // paddingTop: "25px",
        }}
      >
        <div
          style={{
            width: "80px",
            paddingRight: "10px",
          }}
        >
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={closeDrawer}
          >
            Cancel
          </TextButton>
        </div>
        <div style={{ width: "80px" }}>
          <TextButton
            kind={KIND.primary}
            size={SIZE.mini}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            fullWidth
            type="submit"
          >
            Capture
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default AddCreditReport;
