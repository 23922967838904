function getUserManualPath(destination) {
  let dest = destination;

  switch (destination) {
    case "": {
      dest = "dashboard";
      break;
    }
    case "report": {
      dest = "reports";
      break;
    }
    case "client-uploads": {
      dest = "uploads";
      break;
    }

    default: {
      break;
    }
  }
  return dest;
}

export default getUserManualPath;
