import React, { useContext, useEffect, useRef, useState } from "react";
import { Tag } from "baseui/tag";
import TagTick from "../../../assets/img/svg/CustomerOverViewIcon/TagTick";
import IconButton from "../../../components/IconButton/IconButton";
import TripleDot from "../../../assets/img/svg/TripleDot";
import Descending from "../../../assets/img/svg/Descending";
import { SIZE } from "baseui/input";
import { KIND } from "baseui/button";
import Letter from "../../../assets/img/svg/CustomerTemplate/Letter";
import { Label1, Label2, LabelMedium, Paragraph2 } from "baseui/typography";
import DeliverTick from "../../../assets/img/svg/DeliverTick";
import ProfileIcon from "../../../assets/img/svg/ProfileIcon";
import { TextArea } from "../../../components/TextArea";
import ThreeLine from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/ThreeLine";
import NineDots from "../../../assets/img/svg/CustomerOverViewIcon/NineDots";
import {
  CALLBACKLATER,
  CUSTOMER_OVERVIEW,
  MEETINGREQUEST,
  PROMISE,
  HOLDACTION,
  CALLUNSUCCESSFUL,
  DISPUTE,
  NEXTACTION,
  REVIEWPROMISE,
} from "../../../constants";
import CollapseUpArrow from "../../../assets/img/svg/CollapseUpArrow";
import CollapseDownArrow from "../../../assets/img/svg/CollapseDownArrow";
import {
  getCustomerOverviewTimelineList,
  getTimelineDownload,
  invoiceDownload,
} from "../../../services/customerOverview";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import TimelineMap from "./TimelineMap";
import TemplateIcon from "../../communicationTemplate/TemplateIcon";
import { NOTE } from "../../../constants";
import Alert from "../../../assets/img/svg/Alert";
import { SelectBox } from "../../../components/SelectBox";
import { TextBox } from "../../../components/TextBox";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { TextButton } from "../../../components/TextButton";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import ChipFilter from "../../../components/ChipFilter";
import { useParams } from "react-router-dom";
import TimelineCreateAction from "./TimelineCreateAction";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import Ascending from "../../../assets/img/svg/Ascending";
import TimelineTableMap from "./TimelineTableMap";
import _ from "lodash";
import moment from "moment";
import EmailIcon from "../../../assets/img/svg/CustomerTemplate/Email";
import { StatefulTooltip } from "baseui/tooltip";
import Download from "../../../assets/img/svg/Download";
import DownloadSvg from "../../../assets/img/svg/DownloadSvg";
import { toast } from "react-toastify";

const Timeline = ({ getParams, customerId }) => {
  const { id } = useParams();
  // const timelineContainer = useRef(null);
  const ref = useRef(null);

  const intl = useIntl();
  let { currentOrganization, currentDefaultFormatDetails, referenceData } =
    useContext(MaxyfiContext);
  let [querySort, setQuerySort] = useState("-executed_on");
  let [queryFilter, setQueryFilter] = useState({});
  const [timelineData, setTimeLineData] = useState([]);

  const [tableView, setTableView] = useState(false);

  const refetchTimeline = useSelector(
    (s) => s.customerOverviewReducer.refetchTimeline
  );

  // const timeLineListData = useQuery(
  //   [
  //     `${CUSTOMER_OVERVIEW}-TIMELINE-${getParams}`,
  //     {
  //       refetchCount: refetchTimeline,
  //       sort: querySort,
  //       filters: queryFilter,
  //     },
  //   ],
  //   async ({ queryKey }) => {
  //
  //     let { page, page_size, type, sort, filters } = queryKey[1];
  //     return await getCustomerOverviewTimelineList({
  //       page,
  //       page_size,
  //       type,
  //       sort,
  //       organization: currentOrganization,
  //       id: getParams,
  //       entity: "customer",
  //       ...filters,
  //     });
  //   }
  // );

  const timelineDownload = useMutation(
    (data) =>
      getTimelineDownload({
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {},
    }
  );

  const timeLineListData = useInfiniteQuery(
    // `${CUSTOMER_OVERVIEW}-TIMELINE-${getParams}`,
    [
      `TIMELINE_${CUSTOMER_OVERVIEW}_${getParams}`,
      {
        // pageSize: 0,
        // refetchCount: refetchTimeline,
        sort: querySort,
        filters: queryFilter,
      },
    ],
    async ({ queryKey, pageParam = 1 }) => {
      // let { filters, pageParam = 1 } = queryKey[1];
      let { pageIndex, sort, pageSize, filters, page } = queryKey[1];
      //

      return await getCustomerOverviewTimelineList({
        page: pageParam,
        organization: currentOrganization,
        id: getParams,
        sort,
        entity: "customer",
        ...filters,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        if (allPages.length < maxPages) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );

  const onScroll = async (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (
      !timeLineListData.isFetchingNextPage &&
      scrollHeight - scrollTop <= clientHeight * 1.5
    ) {
      if (timeLineListData.hasNextPage)
        await timeLineListData.fetchNextPage({ cancelRefetch: false });
    }
  };

  useEffect(() => {
    const dev = ref.current;
    if (ref.current) {
      dev.addEventListener("scroll", onScroll);
    }
    return () => {
      ref?.current?.removeEventListener("scroll", onScroll);
    };
  }, [ref, ref.current, onScroll]);

  // for infineate scroll get the width

  // useEffect(() => {
  //   let fetching = false;
  //   const onScroll = (event) => {
  //     const { scrollHeight, scrollTop, clientHeight } =
  //       event.target.scrollingElement;
  //     if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
  //       fetching = true;
  //       fetching = false;
  //     }
  //   };

  //   document.addEventListener("scroll", onScroll);
  //   return () => {
  //     document.removeEventListener("scroll", onScroll);
  //   };
  // }, []);

  // filters for timeline handled here

  const updateQueryFilter = (activeFilters) => {
    if (activeFilters.length > 0) {
      setQueryFilter({
        type: [...activeFilters],
      });
    } else {
      setQueryFilter({});
    }
  };

  useEffect(() => {
    if (timeLineListData && timeLineListData.data) {
      let allData = [];

      timeLineListData &&
        timeLineListData.data &&
        timeLineListData.data.pages.map((e) => {
          allData.push(...(e && e.data && e.data.docs));
        });

      setTimeLineData(allData);
    }
  }, [timeLineListData && timeLineListData.data]);

  let NeededTableData = {
    email: [
      "executed_on",
      "type",
      "deliverablity_status",
      "name",
      "comment",
      "executed_by",
    ],
    sms: ["executed_on", "type", "content.body", "comment", "executed_by"],
    // promise_to_pay: [
    //   "executed_on",
    //   "content.call_outcome",
    //   "content.promise_amount.currency",
    //   "content.promise_amount.value",
    //   "call.content.promise_date",
    //   "comment",
    //   "executed_by",
    // ],
    // call_back_later: [
    //   "executed_on",
    //   "content.call_outcome",
    //   "content.callback_date",
    //   "content.callback_time",
    //   "comment",
    //   "executed_by",
    // ],
    // dispute: [
    //   "executed_on",
    //   "type",
    //   "content.dispute_details",
    //   "content.dispute_review_date",
    //   "content.dispute_review_date",
    //   "content.dispute_invoices",
    // ],
    // unsuccessful: [
    //   "executed_on",
    //   "content.follow",
    //   "content.call_unsuccessful_reason",
    //   "content.followup_date",
    // ],
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="cp-timeline-content cp-timeline-content--no-margin">
        <div className="cp-timeline__filters">
          <ChipFilter
            filters={[
              { label: "SMS", value: "sms" },
              { label: "Email", value: "email" },
              // { label: "Letter", value: "letter" },
              { label: "Call", value: "call" },
              // { label: "Invoice", value: "invoice" },
              // { label: "Payment", value: "payment" },
              { label: "Next Action", value: "NEXT_ACTION" },
              { label: "Dispute", value: "dispute" },
              { label: "Hold Action", value: "hold_action" },
              { label: "Skip Action", value: "action_skipped" },
              { label: "WhatsApp", value: "whatsapp" },
              { label: "Letter", value: "letter" },
              { label: "Invoice Update", value: "INVOICE_UPDATE" },
              // { label: "Review Promise", value: "reviewpromise" },
            ]}
            setFilter={updateQueryFilter}
          />
        </div>

        <div style={{ display: "flex", gap: "5px" }}>
          <div
            style={{
              // paddingRight: "10px",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            {tableView ? (
              <div
                onClick={() => {
                  setTableView(false);
                }}
                style={{ height: "30px", width: "30px" }}
              >
                <IconButton
                  // onClick={() => setList(true)}
                  kind={KIND.primary}
                  size={SIZE.compact}
                  fullWidth
                >
                  <ThreeLine />
                </IconButton>
              </div>
            ) : (
              <div
                onClick={() => {
                  setTableView(true);
                }}
                style={{ height: "30px", width: "30px" }}
              >
                <IconButton kind={KIND.primary} size={SIZE.compact} fullWidth>
                  <NineDots />
                </IconButton>
                {/* List */}
              </div>
            )}
            {/* <div>Grid</div>
          <div>Normal</div> */}
          </div>
          {querySort !== "-executed_on" ? (
            <IconButton
              kind={KIND.primary}
              size={SIZE.compact}
              onClick={() => setQuerySort("-executed_on")}
            >
              <Ascending />
            </IconButton>
          ) : (
            <IconButton
              kind={KIND.primary}
              size={SIZE.compact}
              onClick={() => setQuerySort("executed_on")}
            >
              <Descending />
            </IconButton>
          )}
          <IconButton
            kind={KIND.primary}
            size={SIZE.compact}
            disabled={timelineDownload.isLoading}
            isLoading={timelineDownload.isLoading}
            onClick={async () => {
              await timelineDownload.mutateAsync({
                customerId: getParams,
                organization: currentOrganization,
              });
              //   invoiceDownload({
              //   customerId: getParams,
              //   organization: currentOrganization,
              // }).then(() => setIsLoading(false));
            }}
          >
            <DownloadSvg />
          </IconButton>
        </div>
      </div>
      {/* <div ref={ref}>Heloo</div> */}

      <>
        {!timeLineListData.isLoading ? (
          <>
            {timelineData && timelineData?.length > 0 ? (
              <div ref={ref} className="cp-timeline-container__status">
                {tableView ? (
                  <TimelineMap
                    data={timelineData || []}
                    getParams={getParams}
                    customerId={customerId}
                    entity="customer"
                  />
                ) : (
                  <>
                    {timelineData.map((e) => {
                      return (
                        <div
                          ref={ref}
                          style={{
                            display: "flex",
                            flex: "1",
                          }}
                        >
                          <TimelineTableMap
                            data={e}
                            key={e.type}
                            getParams={getParams}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            ) : (
              <>
                <div>
                  <TimelineCreateAction />
                </div>
              </>
            )}
            {/* {timeLineListData.data &&
            Array.isArray(timeLineListData.data.pages) &&
            timeLineListData.data.pages.map((e) => {
              
              let allData = [...e.data.docs];

              

              if (
                e &&
                e.data &&
                e.data.docs &&
                Array.isArray(e.data.docs) &&
                e.data.docs.length !== 0
              ) {
                
                return (
                  <div ref={ref} className="cp-timeline-container__status">
                    <TimelineMap
                      data={(e && e.data && e.data.docs) || []}
                      getParams={getParams}
                      customerId={customerId}
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <TimelineCreateAction />
                  </div>
                );
              }
            })} */}
          </>
        ) : (
          <Loader />
        )}
      </>

      {/* {!timeLineListData.isLoading ? (
        <>
          {timeLineListData.data &&
          // isFetched &&
          timeLineListData.data.data &&
          timeLineListData.data?.data?.docs &&
          timeLineListData.data.data.docs.length !== 0 ? (
            <div ref={ref} className="cp-timeline-container__status">
              <TimelineMap
                data={timeLineListData.data.data.docs || []}
                getParams={getParams}
                customerId={customerId}
              />
            </div>
          ) : (
            <div>
              <TimelineCreateAction />
            </div>
          )}
          <div className="dummy-height__overflow-bottom"></div>
        </>
      ) : (
        <Loader />
      )} */}
    </div>
  );
};

export default Timeline;
