function ReviewPromise() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7519 5.08128L12.9708 3.38895C12.8024 3.22932 12.5953 3.1353 12.377 3.07422V5.81571H15.1605C15.1023 5.5372 14.9604 5.2792 14.7519 5.08128Z"
          style={{ fill: "#516beb" }}
        />
        <path
          d="M18.5051 8.9263C17.9223 8.43636 17.0307 8.49472 16.5247 9.098L16.2207 9.45771L18.3758 11.2659L18.6661 10.9075C19.1654 10.3136 19.1023 9.42667 18.5051 8.9263Z"
          style={{ fill: "#516beb" }}
        />
        <path
          d="M12.4204 13.957L12.26 14.1468C12.1208 14.3135 12.0205 14.5127 11.9702 14.7238L11.5343 16.5479C11.4931 16.7205 11.5531 16.9018 11.689 17.0159C11.8214 17.1271 12.0081 17.1594 12.1767 17.0873L13.8978 16.341C14.097 16.2544 14.2756 16.1212 14.4148 15.9559L14.5757 15.7655L12.4204 13.957Z"
          style={{ fill: "#516beb" }}
        />
        <path
          d="M15.6174 10.1738L13.0254 13.2406L15.1806 15.0491L17.7724 11.9821L15.6174 10.1738Z"
          style={{ fill: "#516beb" }}
        />
        <path
          d="M11.0568 14.5057C11.1406 14.1549 11.3077 13.823 11.5394 13.5455L15.1905 9.22525V6.75092H11.9084C11.6493 6.75092 11.4396 6.54121 11.4396 6.28206V3H4.4066C3.63097 3 3 3.63097 3 4.4066V17.5973C3 18.373 3.63097 19.0039 4.4066 19.0039H13.7839C14.5595 19.0039 15.1905 18.373 15.1905 17.5973V16.489L15.1296 16.561C14.8993 16.8353 14.6021 17.0565 14.2711 17.2007L12.5486 17.9475C12.0631 18.1572 11.5011 18.0829 11.0852 17.7346C10.6745 17.3898 10.4964 16.8518 10.6209 16.3298L11.0568 14.5057ZM5.34433 5.81319H10.033C10.2921 5.81319 10.5018 6.0229 10.5018 6.28206C10.5018 6.54121 10.2921 6.75092 10.033 6.75092H5.34433C5.08517 6.75092 4.87546 6.54121 4.87546 6.28206C4.87546 6.0229 5.08517 5.81319 5.34433 5.81319ZM5.34433 8.65764H12.8462C13.1053 8.65764 13.315 8.86735 13.315 9.12651C13.315 9.38566 13.1053 9.59537 12.8462 9.59537H5.34433C5.08517 9.59537 4.87546 9.38566 4.87546 9.12651C4.87546 8.86735 5.08517 8.65764 5.34433 8.65764ZM9.09525 15.2218H5.34433C5.08517 15.2218 4.87546 15.012 4.87546 14.7529C4.87546 14.4937 5.08517 14.284 5.34433 14.284H9.09525C9.35441 14.284 9.56412 14.4937 9.56412 14.7529C9.56412 15.012 9.35441 15.2218 9.09525 15.2218ZM5.34433 12.4086C5.08517 12.4086 4.87546 12.1989 4.87546 11.9397C4.87546 11.6805 5.08517 11.4708 5.34433 11.4708H10.9707C11.2299 11.4708 11.4396 11.6805 11.4396 11.9397C11.4396 12.1989 11.2299 12.4086 10.9707 12.4086H5.34433Z"
          style={{ fill: "#516beb" }}
        />
      </svg>
    </>
  );
}

export default ReviewPromise;
