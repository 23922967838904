import { Label2, HeadingXSmall } from "baseui/typography";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import SortDown from "../../../assets/img/svg/SortDown";
import SortUp from "../../../assets/img/svg/SortUp";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { Icon, Typography } from "../../../components_v2";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";

const HeaderSort = (props) => {
  let { column, cellType, isCurrency } = props;
  let { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  if (!props.column) {
    return null;
  }

  return (
    <Label2
      {...props.column.getSortByToggleProps()}
      className={`table-main__head-cell_content table-main__head-cell_content--${props.alignment}-align`}
      // $style={{ textAlign: `${props.alignment}` }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          {column.literal_id === "group_contact" ? (
            <>
              <StatefulTooltip
                accessibilityType={"tooltip"}
                content={
                  <div>
                    <Typography type="p" className="typo-group-contact">
                      Group Contacts using this column{" "}
                    </Typography>
                    <Typography type="p" className="typo-group-contact">
                      by selecting a constant value across{" "}
                    </Typography>
                    <Typography type="p" className="typo-group-contact">
                      contact information that represents{" "}
                    </Typography>
                    <Typography type="p" className="typo-group-contact">
                      one contact. When left blank indicates{" "}
                    </Typography>
                    <Typography type="p" className="typo-group-contact">
                      all the contact information is for one{" "}
                    </Typography>
                    <Typography type="p" className="typo-group-contact">
                      single contact{" "}
                    </Typography>
                  </div>
                }
                placement={PLACEMENT.bottom}
                returnFocus
                autoFocus
                overrides={{
                  Inner: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#16164B",
                    }),
                  },
                }}
              >
                <Icon icon="info_icon_filled" />
              </StatefulTooltip>
            </>
          ) : (
            <>
              {" "}
              <FormattedMessage id={column.literal_id}>
                {column.literal_id}
              </FormattedMessage>
            </>
          )}
        </div>

        {(cellType === "CURRENCY" ||
          cellType === "CURRENCY_NEW" ||
          cellType === "CURRENCYFEEAMOUNT") &&
        isCurrency ? (
          <HeadingXSmall
            style={{ color: "#ADADAD", lineHeight: "10px" }}
          >{`(in ${currentDefaultFormatDetails.default_currency})`}</HeadingXSmall>
        ) : (
          ""
        )}
      </div>

      {props.column.canSort ? (
        <div className="table-main__head-sort">
          <SortUp
            className={
              props.column.isSorted && !props.column.isSortedDesc
                ? "active"
                : ""
            }
          />
          <SortDown
            className={
              props.column.isSorted && props.column.isSortedDesc ? "active" : ""
            }
          />
        </div>
      ) : (
        <></>
      )}
    </Label2>
  );
};

export default HeaderSort;
