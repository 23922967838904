import React from "react";

const Message = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8 0.400391H1.2C0.54 0.400391 0.00599999 0.971544 0.00599999 1.66962L0 13.0927L2.4 10.5542H10.8C11.46 10.5542 12 9.98308 12 9.28501V1.66962C12 0.971544 11.46 0.400391 10.8 0.400391ZM3 4.8427H9C9.33 4.8427 9.6 5.12828 9.6 5.47731C9.6 5.82635 9.33 6.11193 9 6.11193H3C2.67 6.11193 2.4 5.82635 2.4 5.47731C2.4 5.12828 2.67 4.8427 3 4.8427ZM6.6 8.01578H3C2.67 8.01578 2.4 7.7302 2.4 7.38116C2.4 7.03212 2.67 6.74654 3 6.74654H6.6C6.93 6.74654 7.2 7.03212 7.2 7.38116C7.2 7.7302 6.93 8.01578 6.6 8.01578ZM9 4.20808H3C2.67 4.20808 2.4 3.92251 2.4 3.57347C2.4 3.22443 2.67 2.93885 3 2.93885H9C9.33 2.93885 9.6 3.22443 9.6 3.57347C9.6 3.92251 9.33 4.20808 9 4.20808Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Message;
