import React, { useContext, useEffect, useMemo, useState } from "react";
import { KIND, TextButton } from "../../../../components/TextButton";
import { Icon, RDText, Typography } from "../../../../components_v2";
import queryClient from "../../../../providers/queryClient";
import {
  CO_INVOICES_,
  CUSTOMER_OVERVIEW_,
  LEGAL_ACTION_INFO_,
  LEGAL_DEBT_INVOICES_,
} from "../../../../constants";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  customerInvoiceOverview,
  debtsInvoicesOverview,
  getLegalInfo,
} from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import DebtListTable from "./DebtListTable";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { Input } from "baseui/input";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import moment from "moment";
import _, { add } from "lodash";
import { TextBox } from "../../../../components/TextBox";
import { useIntl } from "react-intl";
import useFormat from "../../../../hooks/useFormat";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { ADD_REMOVED_DEBTS_LEGAL } from "../../../../providers/RBACProvider/permissionList";

const AddDebtPopover = ({ setAddInvoices, isEditableDebDpt }) => {
  const { customerId } = useParams();
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  // const [addedinvoice, setAddInvoice] = useState([]);
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );
  const invoices = queryClient.getQueryData(
    `${LEGAL_DEBT_INVOICES_}${customerId}`
  );
  const unselectedInvoices = useMemo(() => {
    const debtInvoiceIds = _.get(legalInfo, "data.doc.dep", []);
    return _.get(invoices, "data.docs", []).filter(
      (item) => !debtInvoiceIds.includes(item._id)
    );
  }, [legalInfo?.data, invoices?.data]);
  const format = useFormat();

  const filteredInvoices = useMemo(() => {
    return unselectedInvoices.filter((item) => {
      const searchTerm = searchQuery.toLowerCase();
      const formatedlabel = `${format.rd({
        id: item.business_unit,
        name: "business_unit_list",
      })} / ${item?.invoice_number} /
  ${moment
    .utc(item?.invoice_due_date)
    ?.tz(currentDefaultFormatDetails.time_zone)
    ?.format(currentDefaultFormatDetails.date_format)}{" "}
  / ${item?.invoice_total_amount?.currency}{" "}
  ${item?.invoice_total_amount?.value}`;
      if (searchTerm) {
        return formatedlabel.toLowerCase().includes(searchTerm);
      }
      return unselectedInvoices;
    });
  }, [searchQuery, unselectedInvoices]);
  const handleCheckboxChange = (e, invoiceId, item) => {
    if (e.target.checked) {
      setSelectedInvoices((prevState) => [...prevState, item]);
    } else {
      setSelectedInvoices((prevState) =>
        prevState.filter((invoice) => invoice._id !== item._id)
      );
    }
  };

  const handleAddButtonClick = (close) => {
    setAddInvoices(selectedInvoices);
  };
  const intl = useIntl();
  return (
    <div>
      {isEditableDebDpt &&
      isEditableDebDpt?.idde &&
      _.get(isEditableDebDpt, "Complaint", false) &&
      _.get(isEditableDebDpt, "Supplement", false) ? (
        <></>
      ) : (
        <StatefulPopover
          placement={PLACEMENT.bottom}
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                border: "1px solid #CDCED9",
                borderRadius: "5px",
                backgroundColor: "#FFFFFF",
              }),
            },
            Inner: {
              style: ({ $theme }) => ({
                backgroundColor: "#FFFFFF",
                borderRadius: "5px",
              }),
            },
          }}
          content={({ close }) => (
            <div>
              <div className="debt-legal-invoice-container">
                <Input
                  startEnhancer={
                    <Icon icon="search_outline" size={16} color="#ADADAD" />
                  }
                  onChange={(e) => setSearchQuery(e.target.value)}
                  overrides={{
                    Root: {
                      style: ({ $isFocused }) => ({
                        borderRadius: "5px",
                        borderWidth: "1px",
                        borderColor: $isFocused ? "#516BEB" : "#CDCED9",
                        backgroundColor: "#ffffff",
                        paddingRight: "0px",
                        padding: "0px",
                        paddingLeft: "0px",
                      }),
                    },
                    InputContainer: {
                      style: ({ $isFocused }) => ({
                        backgroundColor: "#ffffff",
                        paddingLeft: "5px",
                        "::placeholder": {
                          color: $isFocused ? "#cbd5e0" : "transparent",
                          transition: "color 0.4s ease",
                        },
                      }),
                    },
                    StartEnhancer: {
                      style: ({ $theme }) => ({
                        paddingLeft: "5px",
                        paddingRight: "0px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                      }),
                    },
                  }}
                  autoComplete={false}
                  autoFill={false}
                  size="mini"
                  placeholder="Search Inv No, Client Name"
                  value={searchQuery}
                />

                <div className="debt-legal-invoice_check">
                  {filteredInvoices.map((item) => (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={item?._id}
                    >
                      <CheckBoxBaseweb
                        checked={selectedInvoices.some(
                          (invoice) => invoice._id === item._id
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(e, item._id, item)
                        }
                      ></CheckBoxBaseweb>
                      <div className="select-debts-invoice_input">
                        <Typography type="p" className="regular">
                          {_.get(item, "business_unit", "") && (
                            <>
                              <RDText
                                rdName={"business_unit_list"}
                                id={_.get(item, "business_unit", "")}
                              />
                              {" / "}
                            </>
                          )}
                          {_.get(item, "invoice_number", "") && (
                            <>
                              {_.get(item, "invoice_number", "")}
                              {" / "}
                            </>
                          )}
                          {_.get(item, "invoice_due_date", "") && (
                            <>
                              {format.date({
                                value: _.get(item, "invoice_due_date", ""),
                              })}
                              {" / "}
                            </>
                          )}
                          {_.get(item, "invoice_total_amount.currency", "") && (
                            <>
                              {_.get(item, "invoice_total_amount.currency", "")}{" "}
                            </>
                          )}
                          {_.get(item, "invoice_total_amount.value", "") && (
                            <>
                              {format.currency({
                                amount: _.get(
                                  item,
                                  "invoice_total_amount.value",
                                  ""
                                ),
                              })}
                            </>
                          )}
                        </Typography>
                      </div>
                    </div>
                  ))}
                  <div className="debt-legal-invoice-line"></div>
                </div>

                <div className="debt-legal-invoices_buttons">
                  <TextButton
                    size="mini"
                    kind={KIND.tertiary}
                    onClick={() => {
                      setSearchQuery("");
                      setSelectedInvoices([]);
                    }}
                  >
                    Cancel
                  </TextButton>
                  <TextButton
                    size="mini"
                    kind={KIND.primary}
                    onClick={() => {
                      handleAddButtonClick();
                      close();
                    }}
                  >
                    Add
                  </TextButton>
                </div>
              </div>
            </div>
          )}
          returnFocus
          autoFocus
        >
          <div>
            <RBACWrapper role={ADD_REMOVED_DEBTS_LEGAL} type="PERMISSION">
              <div className="debt-add-debt_button">
                <Icon icon="plus" color="#516BEB"></Icon>
                <Typography type="p" style={{ color: "#516BEB" }}>
                  {" "}
                  Add Debt
                </Typography>
                <Icon icon="chevron_down" size={14} color="#516BEB" />
              </div>
            </RBACWrapper>
          </div>
        </StatefulPopover>
      )}
    </div>
  );
};

const DebtCountContainer = ({ isEditableDebDpt }) => {
  const { customerId } = useParams();
  let { currentOrganization } = useContext(MaxyfiContext);
  const [addedinvoice, setAddInvoice] = useState([]);
  const [deleteInvoice, setDeleteInvoice] = useState([]);
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );

  const invoices = useQuery(
    [`${LEGAL_DEBT_INVOICES_}${customerId}`],
    async () => {
      return await debtsInvoicesOverview({
        organization: currentOrganization,
        customer_id: customerId,
        is_with_mini_lic: true,
      });
    },
    {
      placeholderData: {
        data: {
          docs: [], // Provide an empty array as placeholder data
        },
      },
    }
  );

  const { selectedInvoices, clientsCount, totalDebts, totalInvoiceAmount } =
    useMemo(() => {
      const debtInvoiceIds = _.get(legalInfo, "data.doc.dep", []);
      const selectedInvoices = [
        ..._.get(invoices, "data.data.docs", []).filter((item) =>
          debtInvoiceIds.includes(item._id)
        ),
        ...addedinvoice,
      ].filter((item) => {
        return !deleteInvoice.includes(item._id);
      });
      const clientsCount = new Set(
        selectedInvoices.map((invoices) => invoices.business_unit)
      ).size;
      const totalDebts = selectedInvoices.length;
      const totalInvoiceAmount = selectedInvoices.reduce((acc, invoice) => {
        return acc + invoice.invoice_total_amount.value;
      }, 0);
      return {
        selectedInvoices,
        clientsCount,
        totalDebts,
        totalInvoiceAmount,
      };
    }, [
      legalInfo?.data,
      invoices?.data,
      addedinvoice?.length,
      deleteInvoice.length,
    ]);

  const handleDeleteInvoice = (invoiceId) => {
    const updatedInvoices = addedinvoice.filter(
      (invoice) => invoice._id !== invoiceId
    );
    setAddInvoice(updatedInvoices);
  };

  return (
    <>
      <div className="debt-counts-container">
        <div className="debts-counts-details">
          <Typography type="h5"> Total Clients ({clientsCount})</Typography>
          <Icon icon="dot" color="#D3D3D7" size={5}></Icon>
          <Typography type="h5"> Total Debts ({totalDebts})</Typography>
          <Icon icon="dot" color="#D3D3D7" size={5}></Icon>
          <Typography type="h5"> Total Amount {totalInvoiceAmount}</Typography>
        </div>
        <AddDebtPopover
          setAddInvoices={setAddInvoice}
          isEditableDebDpt={isEditableDebDpt}
        />
      </div>
      {/* ||
      _.get(legalInfo, "data.doc.dep", []).length > 0 */}
      {selectedInvoices.length > 0 ||
      addedinvoice?.length > 0 ||
      deleteInvoice.length > 0 ? (
        <DebtListTable
          isEditableDebDpt={isEditableDebDpt}
          selectedInvoices={selectedInvoices}
          enableSave={addedinvoice?.length > 0 || deleteInvoice.length > 0}
          setDeleteInvoice={(invId) =>
            setDeleteInvoice([...deleteInvoice, invId])
          }
          onDeleteInvoice={handleDeleteInvoice}
          reset={() => {
            setDeleteInvoice([]);
            setAddInvoice([]);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DebtCountContainer;
