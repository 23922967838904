function WorkflowHold() {
  return (
    <>
      {/* <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0V0ZM8.30909 13.5875C8.30909 13.7813 8.23211 13.9671 8.09508 14.1042C7.95805 14.2412 7.7722 14.3182 7.57841 14.3182H7.09432C6.90053 14.3182 6.71468 14.2412 6.57765 14.1042C6.44062 13.9671 6.36364 13.7813 6.36364 13.5875V6.4125C6.36364 6.21871 6.44062 6.03286 6.57765 5.89583C6.71468 5.7588 6.90053 5.68182 7.09432 5.68182H7.57841C7.7722 5.68182 7.95805 5.7588 8.09508 5.89583C8.23211 6.03286 8.30909 6.21871 8.30909 6.4125V13.5875ZM13.6364 13.5875C13.6364 13.7813 13.5594 13.9671 13.4224 14.1042C13.2853 14.2412 13.0995 14.3182 12.9057 14.3182H12.4216C12.2278 14.3182 12.042 14.2412 11.9049 14.1042C11.7679 13.9671 11.6909 13.7813 11.6909 13.5875V6.4125C11.6909 6.21871 11.7679 6.03286 11.9049 5.89583C12.042 5.7588 12.2278 5.68182 12.4216 5.68182H12.9057C13.0995 5.68182 13.2853 5.7588 13.4224 5.89583C13.5594 6.03286 13.6364 6.21871 13.6364 6.4125V13.5875Z"
          style={{ fill: "#516BEB" }}
        />
      </svg> */}
      <svg
        // version="1.1"
        // id="Layer_1"
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        // x="0px"
        // y="0px"
        // viewBox="0 0 22 22"
        // style="enable-background:new 0 0 22 22;"
        // xml:space="preserve"
        width="20"
        height="20"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "#516BEB" }}
          d="M11.1,1C8.4,1,5.9,2,4,3.9C2.1,5.8,1.1,8.3,1.1,11c0,2.7,1.1,5.2,2.9,7.1c1.9,1.9,4.4,2.9,7.1,2.9
		c2.7,0,5.2-1.1,7.1-2.9c1.9-1.9,2.9-4.4,2.9-7.1c0-2.7-1.1-5.2-2.9-7.1C16.3,2,13.7,1,11.1,1L11.1,1z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M9.3,14.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2H8.1c-0.2,0-0.4-0.1-0.5-0.2
			c-0.1-0.1-0.2-0.3-0.2-0.5V7.4c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2h0.5c0.2,0,0.4,0.1,0.5,0.2C9.2,7,9.3,7.2,9.3,7.4
			V14.6z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M14.6,14.6c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.5,0.2h-0.5c-0.2,0-0.4-0.1-0.5-0.2
			c-0.1-0.1-0.2-0.3-0.2-0.5V7.4c0-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2h0.5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5
			V14.6z"
        />
      </svg>
    </>
  );
}

export default WorkflowHold;
