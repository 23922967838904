import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { useIntl } from "react-intl";
import moment from "moment";
import CurrencyFormate from "../../../components_v2/CurrencyFormate";
import { Typography } from "../../../components_v2";
import _ from "lodash";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { AllocatedInputBox } from "../../../components/AllocatedInputBox";
import { EDIT } from "../../../constants";

const Header = ({ label = "" }) => {
  const intl = useIntl();

  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

export default function AdjustmentTable(props) {
  const { watch, control, invoiceData } = props;
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  const { data } = useSelector((e) => e.customerOverviewDrawer);

  const { amount } = watch();

  const columns = useMemo(
    () => [
      {
        header: () => <Header label={"invoice_number"} />,
        accessorKey: "invoice_number",
      },
      {
        header: () => <Header label={"invoice_date"} />,
        accessorKey: "invoice_date",
        cell: (props) => {
          const value = props?.getValue();
          return (
            <Typography type="p" subType="regular" className="mx-table-header">
              {value
                ? moment
                    .utc(value)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)
                : "-"}
            </Typography>
          );
        },
      },
      {
        header: () => <Header label={"due_date"} />,
        accessorKey: "invoice_due_date",
        cell: (props) => {
          const value = props?.getValue();
          return (
            <Typography type="p" subType="regular" className="mx-table-header">
              {value
                ? moment
                    .utc(value)
                    .tz(currentDefaultFormatDetails.time_zone)
                    .format(currentDefaultFormatDetails.date_format)
                : "-"}
            </Typography>
          );
        },
      },
      {
        header: () => <Header label={"status"} />,
        accessorKey: "status",
        cell: (props) => {
          const StatusType = ({ type }) => {
            switch (type) {
              case "overdue":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "left",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FD372A",
                      }}
                    ></div>
                    <Typography>Overdue</Typography>
                  </div>
                );
              case "due":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "left",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FBBD04",
                      }}
                    ></div>
                    <Typography>Due</Typography>
                  </div>
                );
              case "paid":
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "left",
                      flex: "1",
                    }}
                  >
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50px",
                        backgroundColor: "#FE7B72",
                      }}
                    ></div>
                    <Typography>Paid</Typography>
                  </div>
                );
              default:
                return null;
            }
          };

          return <StatusType type={props && props.getValue()} />;
        },
      },
      {
        header: () => <Header label={"due_amount"} />,
        accessorKey: "not_paid_amount",
        cell: (props) => {
          let allocatedAmount =
            watch(`invoices[${props.row.index}].allocatedAmount`) || 0;

          let dueAmount =
            data && data.drawerType === EDIT
              ? Number(_.get(props, "row.original.not_paid_amount.value", 0)) -
                Number(_.get(data, "content.portal_payment_amount.value", 0))
              : _.get(props, "row.original.not_paid_amount.value", 0) || 0;

          let remainingAmount = Number(dueAmount) + Number(allocatedAmount);

          return (
            <div className="dashboard__currency__wraper --cus__currency">
              <Typography
                type="p"
                subType="regular"
                className="mx-table-value"
                style={{ textAlign: "right", paddingRight: "5px" }}
              >
                <sup className="mx-text-currency">
                  {_.get(props, "row.original.not_paid_amount.currency", "")}
                </sup>
                {CurrencyFormate(remainingAmount)}
              </Typography>
            </div>
          );
        },
      },
      {
        header: () => <Header label={"adjusted_amount"} />,
        accessorKey: "allocated_amt",
        cell: (props) => {
          let allocatedAmount =
            watch(`invoices[${props.row.index}].allocatedAmount`) || 0;

          let dueAmount =
            _.get(props, "row.original.not_paid_amount.value", 0) || 0;
          let linkedAmount = _.get(
            props,
            "row.original.linked_amount.value",
            0
          );

          if (!linkedAmount && isNaN(linkedAmount)) {
            linkedAmount = 0;
          }

          let remainingAmount =
            Number(dueAmount) + Number(linkedAmount) - Number(allocatedAmount);

          // setIsDueAmount(remainingAmount);

          return (
            <Controller
              name={`invoices[${props.row.index}].allocatedAmount`}
              control={control}
              render={({ field }) => {
                return (
                  <div>
                    <AllocatedInputBox
                      type="number"
                      clearable={false}
                      {...field}
                      fullWidth
                      name={field.name}
                      size={"mini"}
                      value={field.value}
                      onFocus={(e) => {
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        );
                      }}
                    />
                    {/* {remainingAmount < 0 ? (
                      <div style={{ color: "red", fontSize: "11px" }}>
                        Greater than Due Amount
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                );
              }}
            />
          );
        },
      },
    ],
    [amount]
  );

  const { invData = [] } = useMemo(() => {
    return { invData: [invoiceData?.data?.data.docs] };
  }, [invoiceData.isFetching, invoiceData.isLoading]);

  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    data: invData,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    // getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className="co-invoice-section" style={{ marginTop: "10px" }}>
      <div className="co_altc_payment-table_wrapper">
        <div className="co_altc_payment_table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="co-table_body">
          {table.getRowModel().rows.map((row, i) => {
            return (
              <div key={row.id} className="co-table_body-group">
                {row.getVisibleCells().map((cell) => (
                  <div
                    key={cell.id}
                    style={{ width: cell.column.getSize() }}
                    className="co-table_body-cell"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
