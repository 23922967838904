import React, { useContext, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { HeadingXSmall, LabelMedium, ParagraphMedium } from "baseui/typography";
import { useSelector } from "react-redux";
import "moment-timezone";
import CustomFields from "../../../components/CustomFields/CustomFields";

const MoreInfo = (props) => {
  const { currentDefaultFormatDetails, customCustomer } =
    useContext(MaxyfiContext);

  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );
  const [isCard, setIsCard] = useState(false);

  return (
    <div
      style={{
        padding: "20px",
        overflow: "scroll",
        height: "calc(100vh - 285px)",
      }}
    >
      <div style={{ display: "flex", gap: "2px" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "19px" }}>
          <ParagraphMedium>Consumer Insurance</ParagraphMedium>
          <ParagraphMedium>:</ParagraphMedium>
        </div>
        <ParagraphMedium $style={{ fontWeight: 700 }}>
          {customerDetails?.insurance}
        </ParagraphMedium>
      </div>
      <div style={{ display: "flex", gap: "2px" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "21px" }}>
          <ParagraphMedium>Consumer Employer</ParagraphMedium>
          <ParagraphMedium>:</ParagraphMedium>
        </div>
        <ParagraphMedium $style={{ fontWeight: 700 }}>
          {customerDetails?.employer}
        </ParagraphMedium>
      </div>
      <div style={{ display: "flex", gap: "2px" }}>
        <ParagraphMedium>Consumer Date of Birth :</ParagraphMedium>

        <ParagraphMedium $style={{ fontWeight: 700 }}>
          {customerDetails?.dob
            ? moment
                .utc(customerDetails?.dob)
                ?.tz(currentDefaultFormatDetails?.time_zone)
                ?.format(currentDefaultFormatDetails?.date_format)
            : ""}
        </ParagraphMedium>
      </div>
      <div style={{ display: "flex", gap: "2px" }}>
        <ParagraphMedium>Consumer ID :</ParagraphMedium>

        <ParagraphMedium $style={{ fontWeight: 700 }}>
          {customerDetails?.customer_id}
        </ParagraphMedium>
      </div>
      <div style={{ display: "flex", gap: "2px" }}>
        <ParagraphMedium>Create File Id :</ParagraphMedium>

        <ParagraphMedium $style={{ fontWeight: 700 }}>
          {customerDetails?.created_file_id}
        </ParagraphMedium>
      </div>
      <div style={{ display: "flex", gap: "2px" }}>
        <ParagraphMedium>Update File Id :</ParagraphMedium>

        <ParagraphMedium $style={{ fontWeight: 700 }}>
          {customerDetails?.updated_file_id}
        </ParagraphMedium>
      </div>
      <CustomFields
        customFields={customerDetails}
        fieldLevel={customCustomer}
      />
    </div>
  );
};

export default MoreInfo;
