import { type } from "@testing-library/user-event/dist/type";
import { NEW } from "../../constants";

export const SET_REPORT_VIEW_ONLY = "SET_REPORT_VIEW_ONLY";
export const CUSTOMIZE_COLUMN_MODAL = "CUSTOMIZE_COLUMN_MODAL";
export const DOWNLOAD_SETTING_OPEN = "DOWNLOAD_SETTING_OPEN";
export const GET_REPORT_SINGLE_DETAILS = "GET_REPORT_SINGLE_DETAILS";
export const SET_REPORT_GRID_AND_VIEW = "SET_REPORT_GRID_AND_VIEW";
export const SET_REPORT_VIEW = "SET_REPORT_VIEW";
export const GET_REPORT_GRID_STRUCTURE = "GET_REPORT_GRID_STRUCTURE";
export const UPDATE_REPORT_TABLE_DRAFT_STATE =
  "UPDATE_REPORT_TABLE_DRAFT_STATE";
export const REPORT_IS_MODAL = "REPORT_IS_MODAL";
export const COLUMN_REPORT_WIDTH = "COLUMN_REPORT_WIDTH";
export const SET_REPORT_SORT = "SET_REPORT_SORT";
export const SET_REPORT_PAGE_INDEX = "SET_REPORT_PAGE_INDEX";
export const SET_REPORT_PAGE_SIZE = "SET_REPORT_PAGE_SIZE";
export const TABLE_REPORT_COLUMN_ORDER = "TABLE_REPORT_COLUMN_ORDER";
export const UPDATE_IS_HIDDEN_REPORT = "UPDATE_IS_HIDDEN_REPORT";
export const SET_REPORT_TABLE_DATA = "SET_REPORT_TABLE_DATA";
export const UPDATE_REPORT_TABLE_DATA = "UPDATE_REPORT_TABLE_DATA";
export const SET_REPORT_DETAILS = "SET_REPORT_DETAILS";
export const SET_REPORT_ACTION_TYPE = "SET_REPORT_ACTION_TYPE";
export const GET_CUSTOMIZE_COLUMNS_HEADERS = "GET_CUSTOMIZE_COLUMNS_HEADERS";
export const GET_EXACT_GRID_STRUCTURE = "GET_EXACT_GRID_STRUCTURE";
export const REPORT_COLUMN_WIDTH = "REPORT_COLUMN_WIDTH";
export const SET_REPORT_INITIAL_STATE = "SET_REPORT_INITIAL_STATE";
export const setStrategyViewOnly = (viewOnly = false) => {
  return {
    type: SET_REPORT_VIEW_ONLY,
    payload: viewOnly,
  };
};
export const customizeColumnModal = (data) => {
  return {
    type: CUSTOMIZE_COLUMN_MODAL,
    payload: data,
  };
};

export const downloadSettingOpen = (data) => {
  return {
    type: DOWNLOAD_SETTING_OPEN,
    payload: data,
  };
};
export const setReportHeaderOrder = (tabHead) => {
  return {
    type: TABLE_REPORT_COLUMN_ORDER,
    payload: tabHead,
  };
};
export const getSingleReportDetails = (data) => {
  return {
    type: GET_REPORT_SINGLE_DETAILS,
    payload: data,
  };
};

export const setReportViewOnly = (viewOnly = false) => {
  return {
    type: SET_REPORT_VIEW_ONLY,
    payload: viewOnly,
  };
};

export const setReportSummaryGridAndView = (viewData) => {
  return {
    type: SET_REPORT_GRID_AND_VIEW,
    payload: viewData,
  };
};

export const setReportSummaryTableView = (viewData) => {
  return {
    type: SET_REPORT_VIEW,
    payload: viewData,
  };
};

export const getReportSummaryGridStructure = (gridStructure) => {
  return {
    type: GET_REPORT_GRID_STRUCTURE,
    payload: gridStructure,
  };
};

export const getExactgridStructure = (data) => {
  return {
    type: GET_EXACT_GRID_STRUCTURE,
    payload: data,
  };
};

export const updateTableReportSummaryDraftState = (tableState) => {
  return {
    type: UPDATE_REPORT_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const modalReportIsOpen = (modalState) => {
  return {
    type: REPORT_IS_MODAL,
    payload: modalState,
  };
};

export const updateIsHiddenReport = (data) => {
  return {
    type: UPDATE_IS_HIDDEN_REPORT,
    payload: data,
  };
};
export const setCustomizeColumnHeaders = (data) => {
  return {
    type: GET_CUSTOMIZE_COLUMNS_HEADERS,
    payload: data,
  };
};

export const setTableReportWidth = (width) => {
  return {
    type: COLUMN_REPORT_WIDTH,
    payload: width,
  };
};

export const setReportSummarySort = (sort) => {
  return {
    type: SET_REPORT_SORT,
    payload: sort,
  };
};

export const setReportSummaryPageIndex = (pageIndex) => {
  return {
    type: SET_REPORT_PAGE_INDEX,
    payload: pageIndex,
  };
};

export const setReportSummaryPageSize = (pageSize) => {
  return {
    type: SET_REPORT_PAGE_SIZE,
    payload: pageSize,
  };
};

export const setReportTableData = (data) => {
  return {
    type: SET_REPORT_TABLE_DATA,
    payload: data,
  };
};

export const updateReportTableData = (data) => {
  return {
    type: UPDATE_REPORT_TABLE_DATA,
    payload: data,
  };
};

export const setGeneralDetailsReport = (data) => {
  return {
    type: SET_REPORT_DETAILS,
    payload: data,
  };
};
export const setReportActionType = (type = NEW) => {
  return {
    type: SET_REPORT_ACTION_TYPE,
    payload: type,
  };
};
export const setReportIntialState = () => {
  return {
    type: SET_REPORT_INITIAL_STATE,
  };
};
// export const setReportTableWidth = (width) => {
//   return {
//     type: REPORT_COLUMN_WIDTH,
//     payload: width,
//   };
// };
