import React from "react";

const PortalPayment = ({ width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 9V8H5C4.449 8 4 8.449 4 9V11H11.211C11.072 10.389 11 9.731 11 9Z"
        fill="#516BEB"
      />
      <path
        d="M19.959 13C19.277 14.051 18.237 14.945 16.747 15.801C16.518 15.932 16.26 16 16 16C15.74 16 15.482 15.932 15.256 15.803C13.766 14.945 12.726 14.05 12.043 13H4V18C4 18.552 4.449 19 5 19H19C19.552 19 20 18.552 20 18V13H19.959ZM8.5 16H6.5C6.224 16 6 15.776 6 15.5C6 15.224 6.224 15 6.5 15H8.5C8.776 15 9 15.224 9 15.5C9 15.776 8.776 16 8.5 16Z"
        fill="#516BEB"
      />
      <path
        d="M19.697 6.5405L16.197 5.0405C16.07 4.9865 15.929 4.9865 15.802 5.0405L12.302 6.5405C12.119 6.6185 12 6.7995 12 6.9995V8.9995C12 11.7505 13.017 13.3585 15.751 14.9335C15.828 14.9775 15.914 14.9995 16 14.9995C16.086 14.9995 16.172 14.9775 16.249 14.9335C18.983 13.3625 20 11.7545 20 8.9995V6.9995C20 6.7995 19.881 6.6185 19.697 6.5405ZM17.891 8.8125L15.891 11.3125C15.795 11.4305 15.651 11.4995 15.5 11.4995C15.493 11.4995 15.485 11.4995 15.479 11.4995C15.32 11.4925 15.173 11.4105 15.084 11.2775L14.084 9.7775C13.931 9.5475 13.993 9.2375 14.223 9.0845C14.451 8.9325 14.762 8.9925 14.916 9.2235L15.537 10.1545L17.109 8.1885C17.282 7.9735 17.597 7.9395 17.812 8.1105C18.028 8.2815 18.062 8.5965 17.891 8.8125Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default PortalPayment;
