import React, { useContext, useEffect, useState, useMemo } from "react";

import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  ColumnDef,
  flexRender,
  Row,
} from "@tanstack/react-table";
import { Input } from "baseui/input";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";

import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  getGridStructure,
  customerInvoiceOverview,
  getReviewPromiseDatas,
  updatePromiseDate,
  deletePaymentPlan,
  postPopUpData,
  getCommunicationTemplateList,
  deleteInstallemt,
} from "../../../services";
import StatusType from "../../../components/TableCell/StatusType/StatusType";
import { KIND, TextButton } from "../../../components/TextButton";
import { IconButton, SIZE } from "../../../components/IconButton";
import { LabelLarge, ParagraphMedium, ParagraphSmall } from "baseui/typography";
import DownArrow from "../../../utils/DownArrow";
import RecurringSvg from "../../../assets/img/svg/RecurringSvg";
import CurrencyFormate from "../../../components_v2/CurrencyFormate";
import {
  COMMUNICATION_TEMPLATE,
  CO_INVOICES_,
  CUSTOMER_OVERVIEW_,
} from "../../../constants";
import columnMapper_V2 from "../../../utils/mapper_v2";
import CustomFieldsTab from "../../../components/CustomFields/CustomFieldsTab";
import TypesOfInvoice from "../../../components/TypesOfInvoice/TypesOfInvoice";
import { useDebouncedCallback } from "use-debounce";
import {
  PAYMENT_CARD_DRAWER,
  REVIEW_PROMISE,
  setDrawerState,
  subscriptionsDrawer,
} from "../../../redux/actions";
import { useDispatch } from "react-redux";

import { Icon, Typography } from "../../../components_v2";
import InvLevelCustomFieldsTab from "../CustomFields/InvLevelCustomFieldsTab";
import TitleHead from "../../../components_v2/TitleHead";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextBox } from "../../../components/TextBox";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../../components/TextArea";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Modal } from "baseui/modal";
import _ from "lodash";
import { useSelector } from "react-redux";
import { SelectBox } from "../../../components/SelectBox";
import setSelectValues from "../../../utils/setSelectValues";
import getSelectValues from "../../../utils/getSelectValues";
import queryClient from "../../../providers/queryClient";
import { StatefulPopover } from "baseui/popover";
import ActionReminders from "../../../components_v2/ActionReminders";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";

const UpdatePromiseStatus = ({
  formModal,
  formData,
  resetEditDetailsModal,
}) => {
  const { customerId } = useParams();

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const { promise_date, promise_amount, _id, type } = formData;

  const {
    currentOrganization,
    referenceData,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  let isValidPhone = _.get(customerData, "data.doc.contacts", []).some(
    (e) => e?.is_valid_phone === true
  );

  let isEmailValid = _.get(customerData, "data.doc.contacts", []).some(
    (e) => e?.is_valid_email === true
  );

  let temType = referenceData["template_type"];

  let mappinTypeValue = temType
    .filter((i) => i.id !== "letter")
    .map((e) => {
      if (e.id === "sms" && !currentDefaultFormatDetails.is_sms_configured) {
        return {
          ...e,
          disabled: true,
        };
      }

      if (e.id === "sms" && !isValidPhone) {
        return {
          ...e,
          disabled: true,
        };
      }

      if (e.id === "whatsapp" && !isValidPhone) {
        return {
          ...e,
          disabled: true,
        };
      }

      if (e.id === "email" && !isEmailValid) {
        return {
          ...e,
          disabled: true,
        };
      }

      return e;
    });

  const schema = Yup.object({
    received_amount: Yup.string().test(
      "maxDigitsAfterDecimal",
      "Max 2 decimals only allowed",
      (value) => /^\d+(\.\d{1,2})?$/.test(value)
    ),
  });

  const postOrganizationSetting = useMutation(
    (data) =>
      postPopUpData({
        data,
        organization: currentOrganization,
        promiseId: customerId,
        popId: _id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        resetEditDetailsModal();
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
      },
    }
  );

  const intl = useIntl();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm(
    {
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    },
    {
      shouldUnregister: true,
      defaultValues: {
        notify_customer_via: [],
        notify_template: [],
      },
    }
  );

  let { notify_customer_via, notify_template } = watch();

  const communicationTemplateData = useQuery(
    [
      `${COMMUNICATION_TEMPLATE}-REVIEW_PROMISE-${currentOrganization}`,
      {
        filters: {
          disable: false,
          tags: [
            "PROMISE",
            "CALL_BACK",
            "CONTACT_UNSUCCESSFUL",
            "PAYMENT_PLAN",
          ],
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters, type } = queryKey[1];
      return await getCommunicationTemplateList({
        organization: currentOrganization,
        clientCode: [currentOrganization],
        filters,
        type,
      });
    }
  );

  const comTemplate = useMemo(() => {
    if (_.get(communicationTemplateData, "data.data.docs", "")) {
      let templates = _.get(
        communicationTemplateData,
        "data.data.docs",
        []
      ).map((e) => ({
        id: e._id,
        label: `${e.name} (${
          e.language ? e.language.toLocaleUpperCase() : "en".toLocaleUpperCase()
        })`,
        tags: e.tags || [],
        type: e.type,
      }));
      return templates;
    }
  }, [communicationTemplateData?.data?.data?.docs?.length]);

  useEffect(() => {
    if (formData) {
      setValue("payment_status", setSelectValues(formData.status));
      setValue(
        "received_amount",
        formData &&
          formData.promise_amount &&
          formData.promise_amount?.value &&
          !isNaN(formData.promise_amount?.value)
          ? Number(formData.promise_amount?.value).toFixed(2)
          : Number(0).toFixed(2)
      );
    } else {
      setValue("payment_status", "");
      setValue("received_amount", "");
    }
  }, [formData]);

  useEffect(() => {
    if (notify_template && notify_template?.length === 0) {
      setError("notify_template", {
        message: "Required",
      });
    } else {
      clearErrors("notify_template");
    }
  }, [notify_customer_via, notify_template]);

  const onSubmit = async (data) => {
    let values = {
      // ...data,
      payment_status: getSelectValues(data.payment_status),
      ...(data?.notify_customer_via && data?.notify_customer_via?.length > 0
        ? {
            notify_customer_via: getSelectValues(data.notify_customer_via),
          }
        : {}),
      ...(data?.notify_template && data?.notify_template?.length > 0
        ? {
            notify_template: getSelectValues(data.notify_template),
          }
        : {}),
      // notify_template: getSelectValues(data.notify_template),
      received_amount: data.received_amount,
    };
    //
    await postOrganizationSetting.mutateAsync({ ...values });
  };

  return (
    <>
      <Modal
        onClose={resetEditDetailsModal}
        isOpen={formModal}
        autoFocus={false}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "30px",
              width: "325px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              // boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {/* Heading */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* <OnHoldModalSvg /> */}
              <LabelLarge>
                {intl.formatMessage({
                  id: `update_${type}_status`,
                })}
              </LabelLarge>
            </div>

            {/* Section One */}
            <div style={{ paddingTop: "15px " }}>
              <div>
                <div style={{ width: "" }}>
                  <Controller
                    defaultValues={[]}
                    name="payment_status"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        requiredAstric={true}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "promise_status",
                        })}
                        placeholder={intl.formatMessage({
                          id: "promise_status",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={referenceData["promise_status"]}
                      />
                    )}
                  />
                </div>
              </div>
              {/* Section Two */}
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Controller
                defaultValue={""}
                name="received_amount"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <TextBox
                      {...field}
                      fullWidth
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "received_amount",
                      })}
                      placeholder={intl.formatMessage({
                        id: "received_amount",
                      })}
                      value={field.value}
                    />
                  </>
                )}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <div style={{ paddingBottom: "15px" }}>Notify Customer</div>

              <div style={{ width: "130px" }}>
                <Controller
                  name="notify_customer_via"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      // requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "type_review",
                      })}
                      placeholder={intl.formatMessage({
                        id: "type_review",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                        if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                          setValue(`notify_template`, []);
                        }
                      }}
                      options={mappinTypeValue}
                    />
                  )}
                />
              </div>
            </div>
            {notify_customer_via && notify_customer_via?.length > 0 ? (
              <>
                <div>
                  <Controller
                    name="notify_template"
                    control={control}
                    // rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        // requiredAstric={true}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "choose_template",
                        })}
                        placeholder={intl.formatMessage({
                          id: "choose_template",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          comTemplate
                            ?.filter((e) =>
                              e.tags.includes(
                                type === "PROMISE_TO_PAY" ? "PROMISE" : type
                              )
                            )
                            .filter((k) => {
                              if (
                                notify_customer_via &&
                                notify_customer_via[0] &&
                                notify_customer_via[0]?.id === k?.type
                              ) {
                                return true;
                              }
                              return false;
                            }) || []
                        }
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              style={{
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div style={{ width: "126px" }}>
                <TextButton
                  type="button"
                  onClick={() => {
                    resetEditDetailsModal();
                    reset();
                  }}
                  fullWidth
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "126px" }}>
                <TextButton
                  type="submit"
                  fullWidth
                  size={SIZE.compact}
                  disabled={
                    (notify_customer_via &&
                      notify_customer_via.length > 0 &&
                      notify_template &&
                      notify_template.length === 0) ||
                    isSubmitting
                  }
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

export const UpdatePromiseDetails = ({
  formModal,
  formData,
  resetEditDetailsModal,
}) => {
  const { promise_date, promise_amount, _id, type } = formData;

  const schema = Yup.object({
    promise_date: Yup.date().required(),
  });
  const intl = useIntl();

  const { customerId } = useParams();

  const { currentOrganization, referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const timeZone = currentDefaultFormatDetails?.time_zone;

  const updatePromiseDetails = useMutation(
    (data) =>
      updatePromiseDate({
        organization: currentOrganization,
        customerId: customerId,
        data,
        promiseId: _id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          } else {
            errorData = data.message;
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        resetEditDetailsModal();
      },
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      promise_date: new Date(),
      comment: "",
      ...(promise_date
        ? { promise_date: moment.utc(promise_date).tz(timeZone).toDate() }
        : {}),
    },
  });

  const onSubmit = async ({ promise_date, comment = "" }) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      promise_date: moment
        .tz(
          `${promise_date.getFullYear()}-${
            promise_date.getMonth() + 1
          }-${promise_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .utc()
        .valueOf(),
      comment,
      mention_users: uniqueMentionedUsers,
    };

    await updatePromiseDetails.mutateAsync({ ...values });
  };

  return (
    <>
      <Modal
        onClose={resetEditDetailsModal}
        isOpen={formModal}
        autoFocus={false}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "30px",
              width: "325px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <LabelLarge>
                {intl.formatMessage({
                  id: `update_${type}_details`,
                })}
              </LabelLarge>
            </div>
            <div style={{ paddingTop: "15px" }}>
              <TextBox
                type="number"
                size={SIZE.mini}
                label={intl.formatMessage({
                  id: "promise_amount",
                })}
                value={
                  promise_amount && promise_amount.value
                    ? promise_amount.value
                    : 0
                }
                disabled
              />
            </div>
            <div style={{}}>
              <Controller
                name="promise_date"
                control={control}
                rules={{ required: "Required" }}
                fo
                render={({ field }) => (
                  <DateRangeSelect
                    {...field}
                    name={field.name}
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    requiredAstric={true}
                    label={intl.formatMessage({
                      id: "promise_date",
                    })}
                    value={field.value}
                    onChange={(e) => field.onChange(e.date)}
                    minDate={new Date()}
                  />
                )}
              />
            </div>

            <ActionReminders
              watch={watch}
              control={control}
              errors={errors}
              setValue={setValue}
              is_calendar={false}
              is_incrementor={false}
              is_hold={false}
              is_followup={false}
              is_confirmation={false}
              is_valid_from_contact={false}
            />

            <div
              style={{
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div style={{ width: "126px" }}>
                <TextButton
                  type="button"
                  onClick={() => {
                    resetEditDetailsModal();
                    reset();
                  }}
                  fullWidth
                  kind={KIND.tertiary}
                  size={SIZE.compact}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "126px" }}>
                <TextButton
                  type="submit"
                  fullWidth
                  size={SIZE.compact}
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

export default function ReviewPromise() {
  const initalEditDetailModal = {
    formModal: false,
    formData: null,
    type: null,
  };
  const [editDetailModal, setEditDetailModal] = React.useState(
    initalEditDetailModal
  );
  const [value, setValue] = React.useState("Installment");
  const { currentOrganization } = useContext(MaxyfiContext);
  const { customerId } = useParams();
  let dispatch = useDispatch();

  const gridStructure = useQuery(
    [`GRID_STRUCTURE_REVIEW_PROMISE`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "co_action_review_promise_to_pay_v2",
      });
    },
    {
      cacheTime: 86400000,
    }
  );

  const reviewPromise = useQuery(
    [`${REVIEW_PROMISE}${customerId}`],
    async () => {
      return await getReviewPromiseDatas({
        organization: currentOrganization,
        promiseId: customerId,
        pageIndex: 0,
        pageSize: 0,
      });
    },
    {
      enabled: gridStructure.isSuccess,
    }
  );

  const deletePaymentPlanMutation = useMutation(
    (id) =>
      deletePaymentPlan({
        organization: currentOrganization,
        customerId: customerId,
        promiseId: id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          } else {
            errorData = data.message;
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${REVIEW_PROMISE}${customerId}`);
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
      },
    }
  );

  const deleteInstallmentMutation = useMutation(
    (id) =>
      deleteInstallemt({
        organization: currentOrganization,
        customerId: customerId,
        promiseId: id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          } else {
            errorData = data.message;
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${REVIEW_PROMISE}${customerId}`);
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
      },
    }
  );

  const setEditDetailModalData = (formData, type) => {
    setEditDetailModal({
      formModal: true,
      formData,
      type,
    });
  };

  const resetEditDetailsModal = () => {
    setEditDetailModal({
      formModal: false,
      formData: null,
      type: null,
    });
    reviewPromise.refetch();
  };

  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);

  const data = useMemo(() => {
    const data =
      reviewPromise.data &&
      reviewPromise.data.data &&
      reviewPromise.data.data.doc &&
      Array.isArray(reviewPromise.data.data.doc)
        ? reviewPromise.data.data.doc
        : [];

    for (let item of data) {
      if (
        item.status === "IN_PROGRESS" &&
        item.iopp === true &&
        item.type === "PAYMENT_PLAN"
      ) {
        item.is_first_in_progress = true;
        break;
      }
    }
    return data;
  }, [
    reviewPromise.isLoading,
    reviewPromise.isFetched,
    reviewPromise.isRefetching,
  ]);

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  //   const renderSubComponent = ({ row }) => {
  //     return <InvExpandCard {...row} />;
  //   };

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  return (
    <div className="co-review-promise">
      {/* SECTION HEADER */}
      <div className="co-invoice-section_header">
        <TitleHead title="Review Promise" icon="review_promise" />
      </div>
      {/* SECTION HEADER */}

      <div className="co-invoice-table_wrapper">
        {/* TABLE HEADER */}
        <div className="co-rp-table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div
              key={headerGroup.id}
              className="co-review-promise-table_header-group"
            >
              {headerGroup.headers.map((header, inx) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* TABLE HEADER */}

        {/* TABLE BODY */}
        <div className="co-rp-table_body">
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row) => {
            return (
              <>
                <div
                  key={row.id}
                  className="co-review-promise-inv-table_body-group"
                >
                  {row.getVisibleCells().map((cell, inx) => {
                    return (
                      <div
                        key={cell.id}
                        style={{ width: cell.column.getSize() }}
                        className="co-table_body-cell co-rp-modifi"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                        {_.get(cell, "column.columnDef.literal_id", "") ===
                          "review_promise_type" &&
                        data[row.id] &&
                        data[row.id].iopp ? (
                          <div style={{ paddingLeft: "5px" }}>
                            <Icon icon="recurring" size="14" />
                          </div>
                        ) : (
                          <></>
                        )}
                        {(_.get(cell, "column.id", "") === "promise_date" ||
                          _.get(cell, "column.id", "") === "status") &&
                        data[row.id] &&
                        !data[row.id].iopp ? (
                          <div style={{ paddingLeft: "5px" }}>
                            <Icon
                              icon="edit"
                              size="14"
                              isPressable
                              onClick={() => {
                                if (_.get(cell, "column.id", "") === "status") {
                                  setEditDetailModalData(
                                    data[row.id],
                                    "UPDATE_STATUS"
                                  );
                                } else {
                                  setEditDetailModalData(
                                    data[row.id],
                                    "UPDATE_DATE"
                                  );
                                }
                                // dispatch(reviewTablePopUp(true));
                                // dispatch(
                                //   reviewPromiseAction(tableData[row.id])
                                // );
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                  {data &&
                  data[row.id] &&
                  data[row.id].promise_amount &&
                  data[row.id].promise_amount.value &&
                  data[row.id].status === "IN_PROGRESS" &&
                  !data[row.id].iopp ? (
                    <div className="co-rp-action-button">
                      <Icon
                        isPressable
                        icon="card"
                        onClick={() => {
                          const { _id, promise_amount } = data[row.id];
                          setDrawer(PAYMENT_CARD_DRAWER, {
                            amount: promise_amount.value,
                            currency: promise_amount.currency,
                            installment_id: _id,
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {data &&
                  data[row.id] &&
                  data[row.id].promise_amount &&
                  data[row.id].promise_amount.value &&
                  data[row.id].status === "IN_PROGRESS" &&
                  data[row.id].iopp ? (
                    <StatefulPopover
                      showArrow
                      triggerType="click"
                      placement="left"
                      content={({ close }) => {
                        return (
                          <div
                            style={{
                              width: "383px",
                              backgroundColor: "#FFFFFF",
                              border: "1px solid #CDCED9",
                              borderRadius: "10px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "20px 10px",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              type="p"
                              subType="secondary"
                              className="text-secondary"
                            >
                              Would you like to cancel?
                            </Typography>
                            <RadioGroup
                              value={value}
                              onChange={(e) => setValue(e.currentTarget.value)}
                              name="number"
                              align={ALIGN.horizontal}
                            >
                              <Radio value="Installment">
                                <Typography>Installment</Typography>
                              </Radio>
                              <Radio value="Complete">
                                <Typography>Complete Payment Plan</Typography>
                              </Radio>
                            </RadioGroup>
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                backgroundColor: "#FFF2F2",
                                padding: "5px",
                                marginBottom: "5px",
                              }}
                            >
                              <Icon icon="alert" color={"#FD372A"} size={24} />
                              {value === "Installment" ? (
                                <Typography type="p">
                                  This will skip the Installment selected.
                                </Typography>
                              ) : (
                                <Typography type="p">
                                  This will cancel the Payment Plan (All
                                  Installments)
                                </Typography>
                              )}
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "flex-end",
                                justifyContent: "center",
                              }}
                            >
                              <TextButton
                                size="mini"
                                kind="tertiary"
                                onClick={() => {
                                  close();
                                }}
                              >
                                Cancel
                              </TextButton>
                              <TextButton
                                size="mini"
                                kind="secondary"
                                onClick={async () => {
                                  const { payment_plan_id, _id } = data[row.id];

                                  if (value === "Installment") {
                                    deleteInstallmentMutation.mutateAsync(_id);
                                    close();
                                  } else {
                                    await deletePaymentPlanMutation.mutateAsync(
                                      payment_plan_id
                                    );
                                    close();
                                  }
                                }}
                              >
                                Confirm
                              </TextButton>
                            </div>
                          </div>
                        );
                      }}
                    >
                      {data[row.id].is_first_in_progress && (
                        <div className="co-rp-action-button">
                          <Icon isPressable icon="remove" size={18} />
                        </div>
                      )}
                    </StatefulPopover>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <div>
                  {row.getIsExpanded() && (
                    <div>
                      <div colSpan={row.getVisibleCells().length}>
                        {renderSubComponent({ row })}
                      </div>
                    </div>
                  )}
                </div> */}
              </>
            );
          })}
          {/* TABLE ROW */}
        </div>
        {/* TABLE BODY */}
        {editDetailModal &&
        editDetailModal.formModal &&
        editDetailModal.type === "UPDATE_DATE" ? (
          <UpdatePromiseDetails
            {...editDetailModal}
            setEditDetailModalData={setEditDetailModalData}
            resetEditDetailsModal={resetEditDetailsModal}
          />
        ) : (
          <></>
        )}

        {editDetailModal &&
        editDetailModal.formModal &&
        editDetailModal.type === "UPDATE_STATUS" ? (
          <UpdatePromiseStatus
            {...editDetailModal}
            setEditDetailModalData={setEditDetailModalData}
            resetEditDetailsModal={resetEditDetailsModal}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
