import React from "react";

export default function GenericAction({ size = 22, color = "#FD372A" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <path
        d="M10.6684 16.6477L14.17 9.81581H11.4737V5.31585L7.84819 12.1842H10.6684V16.6477ZM11.0016 20C9.7568 20 8.58677 19.7638 7.49148 19.2914C6.39618 18.8189 5.44342 18.1778 4.63322 17.368C3.82299 16.5581 3.18157 15.6058 2.70894 14.511C2.23631 13.4162 2 12.2464 2 11.0016C2 9.7568 2.23621 8.58677 2.70863 7.49148C3.18106 6.39618 3.82219 5.44343 4.63203 4.63322C5.44189 3.823 6.39423 3.18157 7.48904 2.70894C8.58384 2.23631 9.75363 2 10.9984 2C12.2432 2 13.4132 2.23621 14.5085 2.70863C15.6038 3.18106 16.5566 3.82219 17.3668 4.63203C18.177 5.44189 18.8184 6.39423 19.2911 7.48904C19.7637 8.58384 20 9.75363 20 10.9984C20 12.2432 19.7638 13.4132 19.2914 14.5085C18.8189 15.6038 18.1778 16.5566 17.368 17.3668C16.5581 18.177 15.6058 18.8184 14.511 19.2911C13.4162 19.7637 12.2464 20 11.0016 20Z"
        fill={color}
      />
    </svg>
  );
}
