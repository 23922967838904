import React from "react";

const Letter = ({ width = 18, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.17 0H2C0.9 0 0 1 0 2.22222V17.7778C0 19 0.9 20 2 20H16C17.1 20 18 19 18 17.7778V7.58889C18 7 17.79 6.43333 17.41 6.02222L12.58 0.655555C12.21 0.233333 11.7 0 11.17 0ZM5 13.3333H13C13.55 13.3333 14 13.8333 14 14.4444C14 15.0556 13.55 15.5556 13 15.5556H5C4.45 15.5556 4 15.0556 4 14.4444C4 13.8333 4.45 13.3333 5 13.3333ZM5 8.88889H13C13.55 8.88889 14 9.38889 14 10C14 10.6111 13.55 11.1111 13 11.1111H5C4.45 11.1111 4 10.6111 4 10C4 9.38889 4.45 8.88889 5 8.88889ZM5 4.44444H10C10.55 4.44444 11 4.94444 11 5.55556C11 6.16667 10.55 6.66667 10 6.66667H5C4.45 6.66667 4 6.16667 4 5.55556C4 4.94444 4.45 4.44444 5 4.44444Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Letter;
