import React from "react";
import { CAPTURE, EDIT } from "../../../../constants";
import FieldVisitEditForm from "./FieldVisitEditForm";
import FieldVisitCapture from "./FieldVisitCapture";

const FieldVisitFormTypes = ({ type, ...rest }) => {
  switch (type) {
    case EDIT:
      return <FieldVisitEditForm {...rest} />;
    case CAPTURE:
      return <FieldVisitCapture {...rest} />;
    default:
      return <></>;
  }
};

export default FieldVisitFormTypes;
