export const GET_USER_TABLE_DATA = "GET_USER_TABLE_DATA";
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";
export const EDIT_MODAL = "EDIT_MODAL";
export const REFETCHUSER = "REFETCHUSER";
export const VIEW_SESSION_INFO_MODAL = "VIEW_SESSION_INFO_MODAL";
export const SESSION_INFO_MODAL_CLOSE = "SESSION_INFO_MODAL_CLOSE";
export const INITIAL_USER_STATE = "INITIAL_USER_STATE";
export const USER_FILTER = "USER_FILTER";

export const setUserFilter = (data) => {
  return {
    type: USER_FILTER,
    payload: data,
  };
};

export const setInitialUserState = () => {
  return {
    type: INITIAL_USER_STATE,
  };
};

export const setUserTableData = (userData) => {
  return {
    type: GET_USER_TABLE_DATA,
    payload: userData,
  };
};

export const modelOpen = () => {
  return {
    type: MODAL_OPEN,
  };
};

export const modelClose = () => {
  return {
    type: MODAL_CLOSE,
  };
};

export const editUserModal = (userData) => {
  return {
    type: EDIT_MODAL,
    payload: userData,
  };
};

export const refetchUser = () => {
  return {
    type: REFETCHUSER,
  };
};

export const viewSessionInfoModal = (userData) => {
  return {
    type: VIEW_SESSION_INFO_MODAL,
    payload: userData,
  };
};

export const sessionInfoModalClose = () => {
  return {
    type: SESSION_INFO_MODAL_CLOSE,
  };
};
