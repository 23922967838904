import React, { useState, useContext } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import InvoiceSummaryTable from "../../containers/InvoiceSummary/InvoiceSummaryTable";
import { useSelector, useDispatch } from "react-redux";
import { setNeedsActionFilterInv } from "../../redux/invoiceSummary/action";
import DownloadInvoiceSummary from "../../components/DownloadInvoice/DownloadInvoice";
import {
  checkMaintenanceFn,
  invoiceSummaryData,
} from "../../services/invoiceSummary";
import { CHECK_MAINTENANCE, INVOICE_OVERVIEW } from "../../constants";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { DOWNLOAD_INVOICE_LIST } from "../../providers/RBACProvider/permissionList";
import moment from "moment";
import CheckMaintenanceTable from "../../containers/CheckMaintenance/CheckMaintenanceTable";
import { IconButton, KIND } from "../../components/IconButton";
import { Icon } from "../../components_v2";
import { SIZE, TextButton } from "../../components/TextButton";
import BatchSummaryDrawer from "../../containers/CheckMaintenance/BatchSummary";
import _ from "lodash";
import CheckMaintenanceDownload from "../../containers/CheckMaintenance/CheckMaintenanceDownload";

function CheckMaintenance() {
  const { tableCurrentState } = useSelector((state) => state.checkMaintenance);

  let {
    currentOrganization,
    currentDefaultFormatDetails,
    appAccessRest,
    referenceData,
  } = useContext(MaxyfiContext);
  let globalFilter = tableCurrentState.globalFilters;
  const [queryFilter, setQueryFilter] = useState({});

  let localPageSize = localStorage.getItem("invoice_summary_page_size");

  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [isCheckSearch, setIsCheckSearch] = useState({
    search_value: "",
    search_key: referenceData?.["check_filter_type"]?.[0]
      ? [referenceData?.["check_filter_type"]?.[0]]
      : [],
    search_debonce: "",
  });

  const { gridStructure, gridAndViewLoaded = false } = useSelector(
    (s) => s.checkMaintenance
  );

  let reduxData = useSelector((s) => s.checkMaintenance.tableState);
  let hiddenColData = useSelector(
    (state) => state.checkMaintenance.tableDraftView.table
  );
  let currentTableState = useSelector(
    (s) => s.checkMaintenance.tableCurrentState
  );

  let removingExpanderSelection = reduxData.columns.filter(
    (i) => i.id != "expander" && i.id != "selection"
  );

  let allColumns = removingExpanderSelection.map((i) => i);

  let hiddenColumns = hiddenColData
    .filter((i) => !i.is_hidden)
    .sort((a, b) => a.order - b.order)
    .map((i) => i.col_id);

  let filteredColumns = hiddenColumns
    .map((e) => {
      let column = allColumns.find((col) => col.path === e);

      if (column && column.path) {
        return column;
      }
    })
    .filter((e) => e);

  let customerFilter = tableCurrentState.filters;

  const NeedsActionFilterData = useSelector(
    (s) => s.checkMaintenance.tableCurrentState.needs_action
  );

  // API is passing in useQuery
  let { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `${CHECK_MAINTENANCE}-${currentOrganization}`,
      {
        pageIndex: tableCurrentState.pageIndex + 1,
        pageSize: localPageSize ? localPageSize : tableCurrentState.pageSize,
        sortBy:
          tableCurrentState.sort && tableCurrentState.sort[0]
            ? tableCurrentState.sort[0].desc
              ? "-" + tableCurrentState.sort[0].id
              : tableCurrentState.sort[0].id
            : "-_id",
        customerFilter,
        globalFilter,
        filters: {
          ...(isCheckSearch?.search_key && isCheckSearch?.search_key.length > 0
            ? {
                filter_type: _.get(isCheckSearch, "search_key[0].id"),
                filter_value: isCheckSearch.search_debonce,
              }
            : {}),
        },
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, customerFilter } =
        queryKey[1];
      let newFilter = {
        combinator: "and",
        rules: [],
        ...customerFilter,
      };

      const GridStructure = gridStructure.table?.reduce((prev, curr) => {
        if (curr.nested) {
          return [...prev, ...(curr?.nested ? curr?.nested : [])];
        }
        return [...prev, curr];
      }, []);

      const findDateObj = GridStructure.filter((dq) => dq.type === "DATE").map(
        (dq) => dq.accessor
      );

      const dynamicDate = findDateObj.map((dy) => dy + "_dynamic");

      if (newFilter.rules.length > 0) {
        newFilter.rules = newFilter.rules.map((fd) => {
          if (findDateObj.includes(fd.field)) {
            let [dateFrom, dateTo] = fd.value.split(",");
            if (dateFrom && dateTo) {
              let newDateFrom = "";
              let newDateTo = "";

              if (dateFrom && moment(dateFrom).isValid()) {
                newDateFrom = moment(dateFrom)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              if (dateTo && moment(dateTo).isValid()) {
                newDateTo = moment(dateTo)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              return { ...fd, value: `${newDateFrom},${newDateTo}` };
            } else {
              if (dateFrom && moment(dateFrom).isValid()) {
                let newDateFrom =
                  fd.operator === ">"
                    ? moment
                        .tz(dateFrom, currentDefaultFormatDetails.time_zone)
                        .endOf("day")
                        .valueOf()
                    : moment
                        .tz(dateFrom, currentDefaultFormatDetails.time_zone)
                        .startOf("day")
                        .valueOf();
                // moment(dateFrom)
                // .tz(currentDefaultFormatDetails.time_zone)
                // .startOf("day")
                // .valueOf();
                return { ...fd, value: newDateFrom };
              }
            }
          }

          if (dynamicDate.includes(fd.field)) {
            let numberRegex = /\d/gi;
            let symbelRegex = /[-+]/g;
            let [dateFrom, dateTo] = fd.value.split(",");

            if (dateFrom && dateTo) {
              let joinAlpa = dateFrom
                ? dateFrom?.match(/[A-Z]/gi)?.join("")
                : [];
              let daysFrom = dateFrom?.match(numberRegex);
              let joinFromDate = daysFrom ? daysFrom?.join("") : [];
              let actionFrom = dateFrom?.match(symbelRegex);
              let daysTo = dateTo?.match(numberRegex);
              let joinToDate = daysTo?.join("");
              let actionTo = dateTo?.match(symbelRegex);
              let replaceField = fd?.field?.replace("_dynamic", "");
              let convertDateFrom;

              if (joinFromDate) {
                convertDateFrom =
                  actionFrom && actionFrom[0] === "-"
                    ? moment
                        .tz(currentDefaultFormatDetails.time_zone)
                        .subtract(joinFromDate, "day")
                        .startOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails.time_zone)
                        .add(joinFromDate, "day")
                        .startOf("day")
                        .valueOf();
              } else if (!joinFromDate && joinAlpa === "Today") {
                convertDateFrom = moment
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              let convertDateTo;

              if (joinToDate) {
                convertDateTo =
                  actionFrom && actionFrom[0] === "-" && fd.operator === ">"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .subtract(joinFromDate, "day")
                        .endOf("day")
                        .valueOf()
                    : actionFrom && actionFrom[0] === "-"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .subtract(joinFromDate, "day")
                        .startOf("day")
                        .valueOf()
                    : fd.operator === ">"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .add(joinFromDate, "day")
                        .endOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .add(joinFromDate, "day")
                        .startOf("day")
                        .valueOf();
              } else if (!joinToDate && joinAlpa === "Today") {
                convertDateTo =
                  fd.operator === ">"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .endOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .startOf("day")
                        .valueOf();
              }

              return {
                ...fd,
                field: replaceField,
                value: `${convertDateFrom},${convertDateTo}`,
              };
            } else {
              let joinAlpa = dateFrom
                ? dateFrom?.match(/[A-Z]/gi)?.join("")
                : [];
              let daysFrom = dateFrom?.match(numberRegex);
              let joinFromDate = daysFrom ? daysFrom?.join("") : [];
              let actionFrom = dateFrom?.match(symbelRegex);
              let replaceField = fd?.field.replace("_dynamic", "");

              let convertDate;
              if (joinFromDate) {
                convertDate =
                  actionFrom && actionFrom[0] === "-"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .subtract(joinFromDate, "day")
                        .startOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .add(joinFromDate, "day")
                        .startOf("day")
                        .valueOf();
              } else if (!joinFromDate && joinAlpa === "Today") {
                convertDate = moment
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              return { ...fd, field: replaceField, value: convertDate };
            }
          }

          return fd;
        });
      }

      return await checkMaintenanceFn({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
        customerFilter: newFilter,
      });
    },
    {
      enabled: gridAndViewLoaded,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Layout>
      <div className="content-container --layout_relative">
        <div className="content-header">
          <div className="content-header__title">
            <PageTitle id="check_maintenance" />
          </div>
          <div className="content-header__actions">
            <BatchSummaryDrawer />
            <RBACWrapper type="PERMISSION" role={DOWNLOAD_INVOICE_LIST}>
              <CheckMaintenanceDownload
                queryFilter={{ ...queryFilter, ...appAccessRest }}
                querySortBy={
                  currentTableState.sort && currentTableState.sort[0]
                    ? currentTableState.sort[0].desc
                      ? "-" + currentTableState.sort[0].id
                      : currentTableState.sort[0].id
                    : "-_id"
                }
                filteredColumns={filteredColumns}
                fileName={"Client Summary"}
                defaultCurrency={
                  currentDefaultFormatDetails &&
                  currentDefaultFormatDetails.default_currency
                }
                gridStructure={gridStructure}
              />
            </RBACWrapper>
          </div>
        </div>
        <div className="content-body">
          <CheckMaintenanceTable
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            service={invoiceSummaryData}
            isFetched={isFetched}
            isError={isError}
            data={data}
            setQueryPageIndex={setQueryPageIndex}
            queryPageSize={queryPageSize}
            setQueryPageSize={setQueryPageSize}
            needsActionFilterDataLength={NeedsActionFilterData}
            needsActionFilter={setNeedsActionFilterInv}
            isLoading={isLoading}
            isCheckSearch={isCheckSearch}
            setIsCheckSearch={setIsCheckSearch}
          />
        </div>
      </div>
    </Layout>
  );
}

export default CheckMaintenance;
