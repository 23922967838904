import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Drawer } from "baseui/drawer";
import Cancel from "../../../assets/img/svg/Template/Cancel";
import { LabelLarge } from "baseui/typography";
import { TextButton, SIZE, KIND } from "../../../components/TextButton";
import Plus from "../../../assets/img/svg/Upload/Plus";
import { subscriptionsDrawer } from "../../../redux/actions";
import SubscriptionForm from "./SubscriptionForm";
import SubscriptionCard from "./SubscriptionCard";
import { getSubscription } from "../../../services";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { ADD_RECURRING_INVOICE } from "../../../providers/RBACProvider/permissionList";

const SubscriptionDrawer = ({ getParams, isV2 = false }) => {
  const { subscriptionsDrawerIsOpen } = useSelector(
    (s) => s.customerOverviewReducer
  );
  const [isAddNew, setIsAddNew] = useState(false);
  const [refetchCheckIn, setRefetchCheckIn] = useState(0);
  const [addSearchParams, setAddSearchParams] = useSearchParams();

  const [editingData, setEditingData] = useState({ type: "", data: [] });

  const { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  let subScription = [
    {
      name: "Personal Trainer",
      amount: "INR 3,000.00",
      amountType: "Fixed Amount - Recurring",
      billType: "Monthly Billed",
      accountName: "Demo",
    },
  ];

  const { data, isFetched, isError } = useQuery(
    [`SUBSCRIPTION-${currentOrganization}-${getParams}-${refetchCheckIn}`],
    async ({ queryKey }) => {
      return await getSubscription({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Drawer
        isOpen={subscriptionsDrawerIsOpen}
        autoFocus
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              marginTop: "0px",
              padding: "15px",
              overflowY: "hidden",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
        onClose={() => {
          dispatch(subscriptionsDrawer(false));
          setIsAddNew(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
          }}
        >
          {/* ONE STARTS*/}
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "6px" }}>
              <LabelLarge>Subscriptions</LabelLarge>
              <div></div>
            </div>

            <div style={{ display: "flex" }}>
              <RBACWrapper role={ADD_RECURRING_INVOICE} type="PERMISSION">
                <div className="drawer_add_btn">
                  <TextButton
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    onClick={() => {
                      if (isV2) {
                        navigate(
                          `/customers?customer=${getParams}&invoice=recurring-form&type=ADD-REC`
                        );
                      } else {
                        setAddSearchParams({
                          customer: `${getParams}`,
                          invoice: `recurring-form`,
                          type: "ADD-REC",
                        });
                      }
                    }}
                  >
                    <Plus />
                    Add
                  </TextButton>
                </div>
              </RBACWrapper>

              <div className="drawer_close_btn">
                <TextButton
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  onClick={() => {
                    dispatch(subscriptionsDrawer(false));
                    //   setIsAddNew(false);
                  }}
                >
                  <Cancel />
                  Close
                </TextButton>
              </div>
            </div>
          </div>
          {/* ONE ENDS */}
          {/* FORM STARTS */}
          {isAddNew ? (
            <>
              <SubscriptionForm
                setIsAddNew={setIsAddNew}
                setRefetchCheckIn={setRefetchCheckIn}
                refetchCheckIn={refetchCheckIn}
                editingData={editingData}
              />
            </>
          ) : (
            <></>
          )}
          {/* FORM ENDS */}

          {/* SUBSCRIPTION CARD STARTS */}
          {/* CARD STARTS */}
          <div
            style={{
              height: "100%",
              overflow: "scroll",
            }}
          >
            {data &&
              data.data &&
              data.data.docs.map((i) => {
                return (
                  <SubscriptionCard
                    setIsAddNew={setIsAddNew}
                    i={i}
                    setEditingData={setEditingData}
                    setRefetchCheckIn={setRefetchCheckIn}
                    refetchCheckIn={refetchCheckIn}
                    getParams={getParams}
                  />
                );
              })}
          </div>
          {/* SUBSCRIPTION CARDS ENDS */}
          <div style={{ height: "40px" }}></div>
        </div>
      </Drawer>
    </>
  );
};

export default SubscriptionDrawer;
