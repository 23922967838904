import React from "react";

export default function Clock({ size = 22, color = "#FD372A" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <path
        d="M11.6869 10.7254V6.89366C11.6869 6.70954 11.6204 6.55105 11.4875 6.41819C11.3546 6.28533 11.196 6.21891 11.0118 6.21891C10.824 6.21891 10.6639 6.28533 10.5314 6.41819C10.3989 6.55105 10.3327 6.70954 10.3327 6.89366V10.9367C10.3327 11.0428 10.3526 11.1455 10.3923 11.245C10.432 11.3444 10.4932 11.4352 10.576 11.5175L13.8666 14.8215C13.9957 14.9549 14.1527 15.0232 14.3376 15.0263C14.5225 15.0294 14.685 14.9608 14.8252 14.8206C14.9624 14.6835 15.0309 14.524 15.0309 14.3422C15.0309 14.1604 14.9627 13.9998 14.8263 13.8602L11.6869 10.7254ZM11.0022 20C9.75758 20 8.58745 19.7635 7.49183 19.2904C6.39621 18.8173 5.44317 18.1753 4.63271 17.3643C3.82225 16.5533 3.18085 15.6011 2.70851 14.5075C2.23617 13.414 2 12.2456 2 11.0022C2 9.75758 2.23654 8.58745 2.70962 7.49183C3.18269 6.39621 3.82472 5.44317 4.63571 4.63271C5.44669 3.82225 6.39895 3.18085 7.49248 2.70851C8.58602 2.23617 9.75445 2 10.9978 2C12.2424 2 13.4126 2.23654 14.5082 2.70962C15.6038 3.18269 16.5568 3.82472 17.3673 4.63571C18.1777 5.44669 18.8191 6.39895 19.2915 7.49248C19.7638 8.58602 20 9.75445 20 10.9978C20 12.2424 19.7635 13.4126 19.2904 14.5082C18.8173 15.6038 18.1753 16.5568 17.3643 17.3673C16.5533 18.1777 15.6011 18.8191 14.5075 19.2915C13.414 19.7638 12.2456 20 11.0022 20ZM11 18.6458C13.1146 18.6458 14.9175 17.9002 16.4089 16.4089C17.9002 14.9175 18.6458 13.1146 18.6458 11C18.6458 8.88541 17.9002 7.08246 16.4089 5.59115C14.9175 4.09982 13.1146 3.35416 11 3.35416C8.88541 3.35416 7.08246 4.09982 5.59115 5.59115C4.09982 7.08246 3.35415 8.88541 3.35415 11C3.35415 13.1146 4.09982 14.9175 5.59115 16.4089C7.08246 17.9002 8.88541 18.6458 11 18.6458Z"
        fill={color}
      />
    </svg>
  );
}
