import React, { useContext } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  assignMulInvoicePauseAction,
  assignMulPauseAction,
} from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../TextArea";
import moment from "moment";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";

const PauseActionFormInv = ({
  selectedCustomer = [],
  handleModal,
  toggleAllRowsSelected,
  isAllRowsSelected,
  queryFilter,
  selectedInvoice = [],
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    referenceData,
    refetch,
    space,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;
  const { filters, globalFilters } = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState
  );

  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.invoiceSummaryReducer
  );

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_customer: false,
    },
  });

  let { hold_reason } = watch();

  const assignMultiCheckInCustomer = useMutation(
    (relMang) =>
      assignMulPauseAction({
        ...relMang,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        if (hold_reason && hold_reason[0] && hold_reason[0].isCreatable) {
          refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        handleModal();
      },
    }
  );

  const assignMultiInvPause = useMutation(
    (relMang) =>
      assignMulInvoicePauseAction({
        ...relMang,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        if (hold_reason && hold_reason[0] && hold_reason[0].isCreatable) {
          refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        handleModal();
      },
    }
  );

  const onSubmit = async (data) => {
    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      // customerQueryFilter: queryFilter && queryFilter.display_name,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let values = {
      ...data,
      hold_reason: getSelectValues(data.hold_reason),
      ...(data.hold_action_till
        ? {
            hold_action_till: moment
              .tz(
                `${data.hold_action_till.getFullYear()}-${
                  data.hold_action_till.getMonth() + 1
                }-${data.hold_action_till.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : {
            ...(selectedInvoice && selectedInvoice.length > 0
              ? { invoices: selectedInvoice }
              : { customerId: selectedCustomer }),
          }),
    };

    if (selectedInvoice && selectedInvoice.length > 0) {
      await assignMultiInvPause.mutateAsync(values);
    } else {
      await assignMultiCheckInCustomer.mutateAsync(values);
    }
  };

  const intl = useIntl();

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge style={{ display: "flex", paddingBottom: "5px" }}>
          Pause Action
        </ParagraphLarge>
        <div style={{ width: "100%" }}>
          <Controller
            control={control}
            name="hold_reason"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                creatable={creatablePermission ? true : false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "pause_reason",
                })}
                placeholder={intl.formatMessage({
                  id: "pause_reason",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["hold_action_reason"]}
              />
            )}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Controller
            name="hold_action_till"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                // disabled={isDisabled}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "pause_action_on_this_customer_till",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                size={SIZE.mini}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
                // minDate={new Date()}
                // range
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="comment"
            render={({ field }) => (
              <TextArea
                {...field}
                name={field.name}
                label={intl.formatMessage({
                  id: "user_comments",
                })}
                placeholder={intl.formatMessage({
                  id: "user_comments",
                })}
                size={SIZE.mini}
                value={field.value}
              />
            )}
          />
        </div>
        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default PauseActionFormInv;
