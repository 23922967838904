import React, { useContext } from "react";
import { SIZE, TextButton } from "../../components/TextButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { clientSummaryDownload } from "../../services/customerSummary";
import { useSelector } from "react-redux";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import _ from "lodash";
import { checkMaintenanceDownloadFn } from "../../services/invoiceSummary";
import { Icon } from "../../components_v2";
import { useIntl } from "react-intl";

const CheckMaintenanceDownload = ({ queryFilter, ...props }) => {
  const intl = useIntl();
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const businessGlobalFilters = useSelector(
    (s) => s.checkMaintenance.tableCurrentState.globalFilters
  );

  const businessFilters = useSelector(
    (s) => s.checkMaintenance.tableCurrentState.filters
  );

  let filteredColumns = props?.filteredColumns;

  let gridStructureData = props?.gridStructure?.table;

  let finalDatas = filteredColumns?.reduce((prev, curr) => {
    let gridColumn = gridStructureData.find((i) => i.accessor === curr.id);
    if (gridColumn && gridColumn.nested && Array.isArray(gridColumn.nested)) {
      return [...prev, ...gridColumn.nested];
    }
    return [...prev, curr];
  }, []);

  const businessFilter = dynamicQueryFilter({
    gridStructure: _.get(props, "gridStructure.table", []),
    // fieldName: "business_unit_name",
    customerQueryFilter: queryFilter && queryFilter.display_name,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: businessFilters,
  });

  const checkMaintenanceList = async () => {
    let columns = finalDatas.map((e) => {
      return {
        title: `${intl.formatMessage({
          id: e.literal_id,
        })} ${
          e.type === "AMOUNT"
            ? `(${currentDefaultFormatDetails.default_currency})`
            : ""
        }`,
        id: e.literal_id,
        accessor: e.id,
        type: e.type,
        subType: e.repSubType,
        ...(e.rdName ? { rd_name: e.rdName } : {}),
      };
    });

    let value = {
      columns,
      filter: { ...businessFilter },
      report_type: "CHK_LST_DWN",
    };

    await checkMaintenanceDownloadFn({
      organization: currentOrganization,
      query: value,
      filters: {
        ...businessGlobalFilters,
      },
    });
  };

  return (
    <TextButton
      size={SIZE.mini}
      onClick={async () => {
        await checkMaintenanceList();
      }}
    >
      <Icon icon="download" color="#ffffff" />
    </TextButton>
  );
};

export default CheckMaintenanceDownload;
