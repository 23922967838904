const Lock = ({ color = "#fff" }) => {
  return (
    <>
      <svg
        width="13"
        height="18"
        viewBox="0 0 13 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.375 5.92536H10.5625V4.27774C10.5625 2.00402 8.7425 0.158691 6.5 0.158691C4.2575 0.158691 2.4375 2.00402 2.4375 4.27774V5.92536H1.625C0.73125 5.92536 0 6.66679 0 7.57298V15.8111C0 16.7173 0.73125 17.4587 1.625 17.4587H11.375C12.2688 17.4587 13 16.7173 13 15.8111V7.57298C13 6.66679 12.2688 5.92536 11.375 5.92536ZM4.0625 4.27774C4.0625 2.91022 5.15125 1.80631 6.5 1.80631C7.84875 1.80631 8.9375 2.91022 8.9375 4.27774V5.92536H4.0625V4.27774ZM10.5625 15.8111H2.4375C1.99062 15.8111 1.625 15.4404 1.625 14.9873V8.39679C1.625 7.94369 1.99062 7.57298 2.4375 7.57298H10.5625C11.0094 7.57298 11.375 7.94369 11.375 8.39679V14.9873C11.375 15.4404 11.0094 15.8111 10.5625 15.8111ZM6.5 13.3396C7.39375 13.3396 8.125 12.5982 8.125 11.692C8.125 10.7858 7.39375 10.0444 6.5 10.0444C5.60625 10.0444 4.875 10.7858 4.875 11.692C4.875 12.5982 5.60625 13.3396 6.5 13.3396Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default Lock;
