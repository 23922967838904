import React from "react";

export default function AlertIcon({ color = "#FD372A", size = 22 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3058 18.6249C3.15807 18.6249 3.0247 18.5881 2.9057 18.5145C2.7867 18.4409 2.69431 18.3458 2.62852 18.2291C2.55702 18.1133 2.51888 17.9877 2.51408 17.8523C2.50928 17.7169 2.54695 17.5809 2.62708 17.4444L10.3264 4.17803C10.4036 4.04445 10.5017 3.94623 10.6207 3.88336C10.7396 3.8205 10.8658 3.78906 10.9992 3.78906C11.1326 3.78906 11.2588 3.8205 11.3778 3.88336C11.4968 3.94623 11.5963 4.04445 11.6765 4.17803L19.3758 17.4444C19.453 17.5809 19.4892 17.7169 19.4844 17.8523C19.4796 17.9877 19.4429 18.1133 19.3743 18.2291C19.3056 18.3458 19.2117 18.4409 19.0928 18.5145C18.9738 18.5881 18.8404 18.6249 18.6926 18.6249H3.3058ZM4.17093 17.3424H17.8275L10.9992 5.57386L4.17093 17.3424ZM10.9992 16.231C11.1996 16.231 11.3669 16.1638 11.5012 16.0296C11.6354 15.8953 11.7026 15.728 11.7026 15.5276C11.7026 15.3273 11.6354 15.1599 11.5012 15.0257C11.3669 14.8914 11.1996 14.8243 10.9992 14.8243C10.7989 14.8243 10.6315 14.8914 10.4973 15.0257C10.363 15.1599 10.2959 15.3273 10.2959 15.5276C10.2959 15.728 10.363 15.8953 10.4973 16.0296C10.6315 16.1638 10.7989 16.231 10.9992 16.231ZM11.011 13.9261C11.1859 13.9261 11.3361 13.8633 11.4615 13.7377C11.587 13.6121 11.6497 13.4604 11.6497 13.2827V10.0373C11.6497 9.86251 11.5869 9.7123 11.4612 9.5867C11.3355 9.4611 11.1853 9.39831 11.0104 9.39831C10.8325 9.39831 10.6809 9.4611 10.5554 9.5867C10.43 9.7123 10.3673 9.86251 10.3673 10.0373V13.2827C10.3673 13.4604 10.4301 13.6121 10.5558 13.7377C10.6814 13.8633 10.8331 13.9261 11.011 13.9261Z"
        fill={color}
      />
    </svg>
  );
}
