import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;

export const createHolidayBlackout = async ({
  organization = null,
  ...values
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/holiday-maintenance`,
    values
  );
};

export const getHolidayBlackoutList = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/holiday-maintenance`, {
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },
  });
};

export const getHolidayBlackout = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/holiday-maintenance/${id}`
  );
};

export const getHolidayBlackoutAudit = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/holiday-maintenance/${id}/audits`
  );
};

export const updateHolidayBlackout = async ({
  organization,
  id,
  ...values
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/holiday-maintenance/${id}`,
    values
  );
};

export const enableHolidayBlackout = async ({ organization, id }) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/holiday-maintenance/${id}`
  );
};

export const getHolidayBlackoutHolidayDate = async ({
  organization = null,
  customerId,
  filters,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/customer/${customerId}/holiday-status`,
    {
      params: {
        ...filters
      },
    }
  );
};
