import React, { useMemo } from "react";
import Calendar from "./Calendar/Calendar";
import { ParagraphLarge } from "baseui/typography";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import moment from "moment";
import { useState } from "react";
import { getNotificationCalendar } from "../../services";
import { useQuery, useInfiniteQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationCalendarInCompleteOnly,
  setNotificationCalendarDate,
} from "../../redux/notification/action";
import useFormat from "../../hooks/useFormat";

const CalenderTab = (props) => {
  const dispatch = useDispatch();
  const { currentDefaultFormatDetails, appAccessRest, currentOrganization } =
    useContext(MaxyfiContext);
  const { calendar } = useSelector((s) => s.notification);
  const timeZone = currentDefaultFormatDetails.time_zone;
  const [calendarRefetch, setCalendarRefetch] = useState(0);
  const format = useFormat();

  const ref = React.useRef(null);

  const calendarData = useInfiniteQuery(
    [
      `CALENDAR_${calendar.date}_${currentOrganization}_${calendarRefetch}`,
      {
        filters: {
          ...appAccessRest,
          ...(calendar.date && !calendar.date.date
            ? {
                ...calendar.date,
                date: moment.tz(timeZone).startOf("day").valueOf(),
              }
            : calendar.date),
          ...(calendar.incompleteOnly ? { status: "NOT_COMPLETED" } : {}),
        },
      },
    ],
    async ({ pageParam = 1, queryKey }) => {
      let { filters } = queryKey[1];
      return await getNotificationCalendar({
        organization: currentOrganization,
        filters,
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        if (allPages.length < maxPages) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const onScroll = async (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (
      !calendarData.isFetchingNextPage &&
      scrollHeight - scrollTop <= clientHeight * 1.5
    ) {
      // fetching = true;
      if (calendarData.hasNextPage)
        await calendarData.fetchNextPage({ cancelRefetch: false });
      // fetching = false;
    }
  };

  useEffect(() => {
    const dev = ref.current;
    if (ref.current) {
      dev.addEventListener("scroll", onScroll);
    }
    return () => {
      ref?.current?.removeEventListener("scroll", onScroll);
    };
  }, [ref, ref.current, onScroll]);

  const { allTime } = useMemo(() => {
    let allTime;
    if (calendarData && calendarData.data) {
      let allData = [];

      calendarData &&
        calendarData.data &&
        calendarData.data.pages.map((e) => {
          allData.push(...(e && e.data && e.data.docs));
        });

      allTime = allData
        .map((e) => {
          const localTime = format.rd({ name: "time", id: e.time });
          const parsedTime = localTime
            ? moment(localTime, "h:mm A")
            : moment("8:00 AM", "h:mm A");

          const utcTimestamp = parsedTime.utc().valueOf();
          return {
            ...e,
            timeToUtc: utcTimestamp,
          };
        })
        .sort((a, b) => {
          if (a.timeToUtc === null) return 1;
          if (b.timeToUtc === null) return -1;
          return a.timeToUtc - b.timeToUtc;
        })
        .sort((a, b) => {
          if (a.date === null) return 1;
          if (b.date === null) return -1;
          return b.date - a.date;
        });
    }
    return { allTime };
  }, [calendarData.isFetched, calendarData.isLoading, calendarData.isFetching]);

  const today = moment.tz(timeZone).startOf("day");
  const futureDates = [];
  for (let i = 0; i < 7; i++) {
    const newToday = today.clone();
    futureDates.push(newToday.add(i + 1, "day"));
  }

  let shiftedData = [...futureDates];
  let dayData = shiftedData
    ?.map((date) => date.format("dddd"))
    ?.map((day) => day.charAt(0));
  let lateDate = null;
  let currentDay = "";
  let day = "";

  return (
    <>
      <div
        style={{
          display: "flex",
          // paddingLeft: "163px",
          justifyContent: "space-between",
          // paddingRight: "64px",
          // gap: "22.9px",
        }}
      >
        <>
          <div style={{ width: "126px" }}></div>
          {dayData.map((e, i) => (
            <div
              style={{
                userSelect: "none",
              }}
              onClick={() => {
                dispatch(
                  setNotificationCalendarDate({
                    date_range_type: "DATE",
                    date: moment.utc(e).tz(timeZone).startOf("D").valueOf(),
                    idx: i + 2,
                  })
                );
              }}
              className={
                calendar.date.idx === i + 2
                  ? "calendar-filter-day-isActive"
                  : ""
              }
            >
              {e}
            </div>
          ))}
          <div style={{ width: "28px" }}></div>
        </>
      </div>
      <div
        style={{
          paddingTop: "0px",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingBottom: "5px",
          display: "flex",
          // gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            userSelect: "none",
            // boxShadow: " 0px 0px 2px 1px #33386025",
          }}
          className={
            calendar.date.idx === 0
              ? "calendar-filter"
              : "calendar-filter-isUnActive"
          }
          onClick={() => {
            dispatch(
              setNotificationCalendarDate({
                is_overdue: true,
                date_range_type: "OVER_DUE",
                idx: 0,
              })
            );
          }}
        >
          Overdue
        </div>
        <div
          style={{
            cursor: "pointer",
            userSelect: "none",
            // boxShadow: " 0px 0px 2px 1px #33386025",
          }}
          className={
            calendar.date.idx === 1
              ? "calendar-filter"
              : "calendar-filter-isUnActive"
          }
          onClick={() => {
            dispatch(
              setNotificationCalendarDate({
                date_range_type: "DATE",
                date: moment.tz(timeZone).startOf("day").valueOf(),
                idx: 1,
              })
            );
          }}
        >
          Today
        </div>
        {shiftedData &&
          shiftedData.map((e, i) => {
            return (
              <div
                style={{
                  userSelect: "none",
                }}
                onClick={() => {
                  dispatch(
                    setNotificationCalendarDate({
                      date_range_type: "DATE",
                      date: e.startOf("D").valueOf(),
                      idx: i + 2,
                    })
                  );
                }}
                className={
                  calendar.date.idx === i + 2
                    ? "calendar-filter-circle-isActive"
                    : "calendar-filter-circle"
                }
              >
                {e.startOf("D").get("D")}
              </div>
            );
          })}
        <div
          style={{
            userSelect: "none",
          }}
          className={
            calendar.date.idx === 9
              ? "calendar-filter-circle-isActive"
              : "calendar-filter-circle"
          }
          onClick={() => {
            dispatch(
              setNotificationCalendarDate({
                date_range_type: "FUTURE",
                date: moment.tz(timeZone).add(8, "d").startOf("D").valueOf(),
                idx: 9,
              })
            );
          }}
        >
          {`${">"}`}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // padding: "10px 10px",
          alignItems: "center",
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: "10px",
          paddingBottom: "5px",
        }}
      >
        <div>
          <Checkbox
            checked={calendar.incompleteOnly}
            checkmarkType={STYLE_TYPE.toggle_round}
            onChange={(e) => {
              dispatch(setNotificationCalendarInCompleteOnly(e.target.checked));
            }}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{
              Checkmark: {
                style: ({ $theme, $checked }) => ({
                  height: "18px",
                  width: "18px",
                  backgroundColor: $checked ? "#516BEB" : null,
                  borderRadius: $theme.borders.radius200,
                  borderBottomColor: $checked
                    ? $theme.colors.primary
                    : `#CDCED9`,
                  borderTopColor: $checked ? $theme.colors.primary : `#CDCED9`,
                  borderRightColor: $checked
                    ? $theme.colors.primary
                    : `#CDCED9`,
                  borderLeftColor: $checked ? $theme.colors.primary : `#CDCED9`,
                  borderBottomWidth: "0.5px",
                  borderTopWidth: "0.5px",
                  borderRightWidth: "0.5px",
                  borderLeftWidth: "0.5px",
                  borderStyle: "solid",
                  backgroundImage: $checked
                    ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                    : "none",
                  backgroundSize: "auto",
                }),
              },
              Label: {
                style: ({ $theme }) => ({
                  paddingLeft: "3px",
                }),
              },
            }}
          >
            <ParagraphLarge>Incomplete Only</ParagraphLarge>
          </Checkbox>
        </div>
        <div></div>
      </div>
      <div
        style={{
          overflow: "scroll",
          height: "calc(100vh - 170px)",
          paddingBottom: "3px",
        }}
        ref={ref}
      >
        {allTime && allTime.length > 0 ? (
          allTime.map((e) => {
            let isDateTag = false;

            let today = new Date();
            let dateValue = today.valueOf();

            let formattedDate = moment
              .utc(e.date)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails?.date_format);

            let todayVal = moment
              .utc(dateValue)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails?.date_format);

            if (formattedDate === todayVal) {
              currentDay = "Today";
            } else {
              currentDay = moment
                .utc(e.date)
                ?.tz(timeZone)
                ?.format("MMMM DD, ");
              day = moment.utc(e.date)?.tz(timeZone)?.format("dddd");
            }

            if (formattedDate !== lateDate) {
              isDateTag = true;
              lateDate = formattedDate;
            }

            return (
              <>
                {isDateTag && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ParagraphLarge
                      style={{
                        fontWeight: "500",
                        padding: "5px 5px",
                      }}
                    >
                      {currentDay}
                    </ParagraphLarge>
                    <ParagraphLarge
                      style={{ color: "#787878", fontSize: "14px" }}
                    >
                      {currentDay === "Today" ? "" : day}
                    </ParagraphLarge>
                  </div>
                )}
                <Calendar
                  data={e}
                  close={props}
                  calendarRefetch={calendarRefetch}
                  setCalendarRefetch={setCalendarRefetch}
                />
              </>
            );
          })
        ) : (
          <></>
        )}

        <div style={{ height: "10px" }}></div>
      </div>
    </>
  );
};

export default CalenderTab;
