// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;
const AUTH = process.env.REACT_APP_HOST_AUTH;
const HOSTSPACE = process.env.REACT_APP_HOST;

export const usersData = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
}) => {
  return await axiosWithAuth.get(process.env.REACT_APP_HOST + "auth", {
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },
  });
};

export const getUsersData = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
  customerFilter = {},
}) => {
  return await axiosWithAuth.post(`${HOSTSPACE}user`, customerFilter, {
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },
  });
};

export const addUsersData = async ({ organization = null, data }) => {
  return await axiosWithAuth.post(`${HOSTSPACE}user/add`, { ...data });
};

export const editUsersData = async ({
  organization = null,
  data,
  customerId,
}) => {
  return await axiosWithAuth.put(`${HOSTSPACE}user/${customerId}`, {
    ...data,
  });
};

export const disableUsersData = async ({
  organization = null,
  data,
  userId,
}) => {
  return await axiosWithAuth.patch(`${HOSTSPACE}user/${userId}`);
};

export const resetUsersData = async ({ organization = null, data, userId }) => {
  return await axiosWithAuth.patch(`${HOST}user/${userId}/reset-2FA`);
};

export const twoFAUsersData = async ({ organization = null, data, userId }) => {
  return await axiosWithAuth.patch(`${HOST}user/${userId}/enable-2FA`);
};

// ${organization}

export const deleteUsersData = async ({
  organization = null,
  data,
  userId,
}) => {
  return await axiosWithAuth.delete(`${HOSTSPACE}user/${userId}`);
};

export const getSessionInfoData = async ({
  organization = null,
  data,
  userId,
}) => {
  return await axiosWithAuth.get(`${HOSTSPACE}session-info/${userId}`);
};

export const getAuditData = async ({ organization = null, data, userId }) => {
  return await axiosWithAuth.get(`${HOSTSPACE}user/${userId}/audits`);
};

export const logOutUser = async () => {
  return await axiosWithAuth.post(`${AUTH}api/auth/logout/`);
};

export const defaultOrganizationValues = async ({ countryId }) => {
  return await axiosWithAuth.get(
    `${HOSTSPACE}default-fields?country=${countryId}`
  );
};

export const notificationCount = async ({ org_id, filters = {} }) => {
  return await axiosWithAuth
    .get(`${HOSTSPACE}organization/${org_id}/notification-count`, {
      params: {
        ...filters,
      },
    })
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};

export const notificationStatus = async ({ org_id, filters = {} }) => {
  return await axiosWithAuth
    .get(`${HOSTSPACE}organization/${org_id}/notification-status`, {
      params: {
        ...filters,
      },
    })
    .catch((err) => {
      if (err.response) {
        //Need to handle error
      }

      if (err.request) {
        //Need to handle error
      }
    });
};

export const getBusinessUnitRd = async ({ countryId }) => {
  return await axiosWithAuth.get(`${HOSTSPACE}organization/business-unit-rd`);
};

export const getRoleMaintenance = async () => {
  return await axiosWithAuth.get(`${HOSTSPACE}role`);
};
