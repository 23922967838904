function Delete( {color="#ADADAD"}) {
  return (
    <>
      <svg
        width="15"
        height="15"
        viewBox="0 0 13 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.916667 14.6667C0.916667 15.675 1.74167 16.5 2.75 16.5H10.0833C11.0917 16.5 11.9167 15.675 11.9167 14.6667V5.5C11.9167 4.49167 11.0917 3.66667 10.0833 3.66667H2.75C1.74167 3.66667 0.916667 4.49167 0.916667 5.5V14.6667ZM3.66667 5.5H9.16667C9.67083 5.5 10.0833 5.9125 10.0833 6.41667V13.75C10.0833 14.2542 9.67083 14.6667 9.16667 14.6667H3.66667C3.1625 14.6667 2.75 14.2542 2.75 13.75V6.41667C2.75 5.9125 3.1625 5.5 3.66667 5.5ZM9.625 0.916667L8.97417 0.265833C8.80917 0.100833 8.57083 0 8.3325 0H4.50083C4.2625 0 4.02417 0.100833 3.85917 0.265833L3.20833 0.916667H0.916667C0.4125 0.916667 0 1.32917 0 1.83333C0 2.3375 0.4125 2.75 0.916667 2.75H11.9167C12.4208 2.75 12.8333 2.3375 12.8333 1.83333C12.8333 1.32917 12.4208 0.916667 11.9167 0.916667H9.625Z"
          fill={color}
        />
      </svg>
    </>
  );
}

export default Delete;
