function AlertTag() {
  return (
    <>
      {/* <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.8898 18H16.1102C17.5644 18 18.4708 16.2325 17.7438 14.8249L10.6336 1.05571C9.90648 -0.351904 8.09352 -0.351904 7.36645 1.05571L0.256242 14.8249C-0.47083 16.2325 0.43565 18 1.8898 18ZM9 10.5915C8.48066 10.5915 8.05575 10.1152 8.05575 9.53315V7.41643C8.05575 6.83434 8.48066 6.35808 9 6.35808C9.51934 6.35808 9.94425 6.83434 9.94425 7.41643V9.53315C9.94425 10.1152 9.51934 10.5915 9 10.5915ZM9.94425 14.8249H8.05575V12.7082H9.94425V14.8249Z"
          fill="#ADADAD"
        />
      </svg> */}

      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.17982 19H17.8202C19.1128 19 19.9185 17.4289 19.2722 16.1777L12.952 3.93841C12.3058 2.6872 10.6942 2.6872 10.048 3.93841L3.72777 16.1777C3.08148 17.4289 3.88724 19 5.17982 19ZM11.5 12.4147C11.0384 12.4147 10.6607 11.9913 10.6607 11.4739V9.59239C10.6607 9.07497 11.0384 8.65162 11.5 8.65162C11.9616 8.65162 12.3393 9.07497 12.3393 9.59239V11.4739C12.3393 11.9913 11.9616 12.4147 11.5 12.4147ZM12.3393 16.1777H10.6607V14.2962H12.3393V16.1777Z"
          fill="#ADADAD"
        />
      </svg>
    </>
  );
}

export default AlertTag;
