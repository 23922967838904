import React from "react";

const Send = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.725 22.8063C4.43333 22.9229 4.15625 22.8986 3.89375 22.7334C3.63125 22.5681 3.5 22.3299 3.5 22.0188V16.8563C3.5 16.6424 3.56319 16.4577 3.68958 16.3021C3.81597 16.1466 3.97639 16.0493 4.17083 16.0104L12.3083 13.9979L4.17083 11.9271C3.97639 11.8882 3.81597 11.791 3.68958 11.6354C3.56319 11.4799 3.5 11.2952 3.5 11.0813V5.9771C3.5 5.66599 3.63125 5.4278 3.89375 5.26252C4.15625 5.09724 4.43333 5.07293 4.725 5.1896L23.7417 13.1813C24.0917 13.3368 24.2667 13.609 24.2667 13.9979C24.2667 14.3868 24.0917 14.659 23.7417 14.8146L4.725 22.8063Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Send;
