import React, { useContext, useState, useEffect } from "react";
import {
  HeadingXLarge,
  ParagraphLarge,
  LabelLarge,
  ParagraphSmall,
} from "baseui/typography";
import Email from "../../../../../assets/img/svg/CustomerTemplate/Email";
import Info from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import InfoTag from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import InfoPortal from "../../../../../assets/img/svg/InfoPortal";
import AllActionPopOver from "../AllActionPopOver";
import { isOpenModal } from "../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../components/TextBox";
import { TextButton, SIZE, KIND } from "../../../../../components/TextButton";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { TextArea } from "../../../../../components/TextArea";
import Sms from "../../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import { SelectBox } from "../../../../../components/SelectBox";
import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  COMMUNICATION_TEMPLATE,
  FIELD_VISIT,
  PAY,
} from "../../../../../constants";
import {
  getCustomerContactDetail,
  executeCustomerAction,
  executeAdhocCustomerAction,
  getOrganization,
  makeCall,
} from "../../../../../services";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import getSelectValues from "../../../../../utils/getSelectValues";
import Call from "../../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Call";
import { H2 } from "baseui/typography";
import { FormattedMessage } from "react-intl";
import { Label1, LabelMedium } from "baseui/typography";
import AlertInfo from "../../../../../assets/img/svg/AlertInfo";
import { IconButton } from "../../../../../components/IconButton";
import FullScreen from "../../../../../assets/img/svg/FullScreen";
import setSelectValues from "../../../../../utils/setSelectValues";
import { StatefulPopover } from "baseui/popover";
import {
  CALLBACKLATER,
  DISPUTE,
  MEETINGREQUEST,
  PROMISE,
  UNSUCCESSFUL,
  PLANNED,
} from "../../../../../constants";
import GetCallAction from "../GetCallAction";
import Dispute from "../../../../../assets/img/svg/Dispute";
import PromiseToPay from "../../../../../assets/img/svg/PromiseToPay";
import MeetingRequest from "../../../../../assets/img/svg/MeetingRequest";
import CallBackLater from "../../../../../assets/img/svg/CallBackLater";
import UnSuccessful from "../../../../../assets/img/svg/UnSuccessful";
import PromiseToPayCall from "../../../../../assets/img/svg/PromiseToPayCall";
import CloudCall from "../../../../../assets/img/cloudcall.png";
import { refetchActions } from "../../../../../redux/actions";
import Planned from "../../../../../assets/img/svg/Planned";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import "moment-timezone";
import AddPaymentSvg from "../../../../../assets/img/svg/AddPaymentSvg";
import PaymentModal from "../PaymentModal";
import TripleDot from "../../../../../assets/img/svg/TripleDot";
import { Button } from "baseui/button";
import { instantPaymentDrawer } from "../../../../../redux/customerOverview/callAction/action";
import { StatefulTooltip } from "baseui/tooltip";
import { ArrowDown } from "baseui/icon";
import DownArrow from "../../../../../utils/DownArrow";
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import UpArrow from "../../../../../assets/img/svg/UpArrow";
import CheckBoxBaseweb from "../../../../../components/CheckBoxBaseweb";
import Location from "../../../../../assets/img/svg/Information/Location";

const CallButtonIcon = ({ type }) => {
  switch (type) {
    case DISPUTE:
      return <Dispute />;
    case PROMISE:
      return <PromiseToPayCall />;
    case MEETINGREQUEST:
      return <MeetingRequest />;
    case UNSUCCESSFUL:
      return <UnSuccessful height={16} width={17} />;
    case CALLBACKLATER:
      return <CallBackLater height={16} width={16} />;
    case PLANNED:
      return <Planned height={18} width={18} />;
    case PAY:
      return <AddPaymentSvg />;
    case FIELD_VISIT:
      return <Location width={20} height={20} />;
    default:
      return null;
  }
};

const ManualCall = () => {
  const {
    referenceData,
    currentOrganization,
    userInfo,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  let userPhoneNumberInfo = userInfo && userInfo.phone && userInfo.phone.value;

  const [callType, setCallType] = useState(
    currentDefaultFormatDetails.is_web_call_configured ? true : false
  );

  const { total_inv_amount, total_outstanding_invoice_amount } = useSelector(
    (state) => state.customerOverviewReducer.customerDetails
  );

  const refetchAction = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  const [settingData, setSettingData] = useState(null);

  // for payment plan
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );
  const [installments, setInstallments] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [isOpenAgentInfo, setIsOpenAgentInfo] = useState(false);

  function agentInfoOpen() {
    setIsOpenAgentInfo(!isOpenAgentInfo);
  }

  // const [isPaymentModal, setIsPaymentModal] = useState(true);
  const [isPaymentLink, setIsPaymentLink] = useState("");

  const adhocUserInstruction = (
    <ParagraphSmall>
      - Greet and give a summary of the Outstanding Amount and duration overdue.
      <br />
      - Understand the reason why the payment is getting delayed.
      <br />
      - Appraise if needed the impact it can create like discontinued service.
      <br />
      - Negotiate for Promise To Pay by Date and if not arrange a follow-up Call
      or Meeting Request or capture the Dispute.
      <br />
      Note: Enable and send Confirmation, Follow-up messages from the call
      action for better recovery.
    </ParagraphSmall>
  );

  const adhocUserInstructionHtml = `
    <p style={{ color: "#adadad" }}>
      - Greet and give a summary of the Outstanding Amount and duration overdue.
      <br />
      - Understand the reason why the payment is getting delayed.
      <br />
      - Appraise if needed the impact it can create like discontinued service.
      <br />
      - Negotiate for Promise To Pay by Date and if not arrange a follow-up Call
      or Meeting Request or capture the Dispute.
      <br />
      Note: Enable and send Confirmation, Follow-up messages from the call
      action for better recovery.
    </p>`;

  const { id, customerId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = customerId;

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_send_action_follow_up: false,
      is_send_confirmation_message: false,
    },
  });

  let formatDetailes = space.organizations.find(
    (e) => e.id == currentOrganization
  );
  let promiseAmountChangingValue = watch(["promise_amount", "promise_type"]);

  let [promise_amount, promise_type] = promiseAmountChangingValue;

  useEffect(() => {
    if (
      promise_type &&
      promise_type[0] &&
      promise_type[0].id === "full_amount"
    ) {
      // setValue(
      //   "promise_amount.value",
      //   Number(total_outstanding_invoice_amount?.value).toFixed(2)
      // );

      clearErrors("promise_amount.value");
    }
  }, [promise_amount, promise_type]);

  // const [callOut, setCallOut] = useState("PROMISE_TO_PAY");
  const [callOut, setCallOut] = useState(null);
  const [isCompute, setIsCompute] = useState(false);

  useEffect(() => {
    if (callOut === "PAYMENT_PLAN") {
      setIsCompute(true);
    }
  }, [callOut]);

  const [callRefDetails, setCallRefDetails] = useState();
  const callBackActin = watch(["is_send_call", "is_send_call_to"]);
  const actioWatch = watch([
    "is_send_confirmation_message",
    "is_send_action_follow_up",
    "is_send_klq",
    "is_send_meet_1rq",
    "promise_type",
    "recipients",
  ]);
  let recipients = watch("recipients");
  const { is_debit_first_instalment } = watch();
  const entityName = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_entity
  );

  let recipientsId = recipients && recipients[0] && recipients[0]?.id;

  let currentRecipientNumber = recipients && recipients[0] && recipients[0].id;

  const invoiceCurrency = useSelector(
    (s) =>
      s &&
      s.customerOverviewReducer &&
      s.customerOverviewReducer.customerDetails &&
      s.customerOverviewReducer.customerDetails?.invoice_currencies
  );

  useEffect(() => {
    // setValue("action_follow_up_template_id", "");
    // setValue("action_follow_up_type", "");
    // setValue("confirmation_message_type", "");
    // setValue("confirmation_message_template_id", "");
    setValue("is_hold_workflow", false);
    if (callOut === "PROMISE_TO_PAY") {
      setValue("promise_type", [{ id: "full_amount" }]);
      if (
        total_outstanding_invoice_amount &&
        total_outstanding_invoice_amount.value
      ) {
        setValue(
          "promise_amount.value",
          Number(total_outstanding_invoice_amount?.value).toFixed(2)
        );
        setValue(
          "planned_payment_amount",
          Number(total_outstanding_invoice_amount?.value).toFixed(2)
        );
      }

      if (
        total_outstanding_invoice_amount &&
        total_outstanding_invoice_amount.currency
      ) {
        setValue("promise_amount.currency", [{ id: invoiceCurrency?.[0] }]);
      }

      if (
        settingData &&
        settingData.default_configuration &&
        settingData.default_configuration.promise_to_pay
      ) {
        let fieldKey = Object.keys(
          settingData.default_configuration.promise_to_pay
        );

        fieldKey.map((field) => {
          if (
            field == "action_follow_up_template_id" ||
            field == "action_follow_up_type" ||
            field == "confirmation_message_type" ||
            field == "confirmation_message_template_id"
          ) {
            setValue(
              field,
              setSelectValues(
                settingData.default_configuration.promise_to_pay[field]
              )
            );
          } else {
            setValue(
              field,
              settingData.default_configuration.promise_to_pay[field]
            );
          }
        });
      }
    } else if (callOut === "CALL_BACK_LATER") {
      if (
        settingData &&
        settingData.default_configuration &&
        settingData.default_configuration.call_back_later
      ) {
        let fieldKey = Object.keys(
          settingData.default_configuration.call_back_later
        );

        fieldKey.map((field) => {
          if (
            field == "action_follow_up_template_id" ||
            field == "action_follow_up_type" ||
            field == "confirmation_message_type" ||
            field == "confirmation_message_template_id"
          ) {
            setValue(
              field,
              setSelectValues(
                settingData.default_configuration.call_back_later[field]
                  ? settingData.default_configuration.call_back_later[field]
                  : " "
              )
            );
          } else {
            setValue(
              field,
              settingData.default_configuration.call_back_later[field]
            );
          }
        });
      }
    } else if (callOut === "UNSUCCESSFUL") {
      if (
        settingData &&
        settingData.default_configuration &&
        settingData.default_configuration.call_unsuccessful
      ) {
        let fieldKey = Object.keys(
          settingData.default_configuration.call_unsuccessful
        );

        fieldKey.map((field) => {
          if (
            field == "action_follow_up_template_id" ||
            field == "action_follow_up_type" ||
            field == "confirmation_message_type" ||
            field == "confirmation_message_template_id"
          ) {
            setValue(
              field,
              setSelectValues(
                settingData.default_configuration.call_unsuccessful[field]
              )
            );
          } else {
            setValue(
              field,
              settingData.default_configuration.call_unsuccessful[field]
            );
          }
        });
      }
    }
    // else if (callOut ==="Payment_Plan"){
    //   setValue("promise_type", [{ id: "full_amount" }]);
    //   if (
    //     total_outstanding_invoice_amount &&
    //     total_outstanding_invoice_amount.value
    //   ) {
    //     setValue(
    //       "promise_amount.value",
    //       Number(total_outstanding_invoice_amount?.value).toFixed(2)
    //     );
    //   }

    //   if (
    //     total_outstanding_invoice_amount &&
    //     total_outstanding_invoice_amount.currency
    //   ) {
    //     setValue("promise_amount.currency", [
    //       { id: `${total_outstanding_invoice_amount.currency}` },
    //     ]);
    //   }

    //   if (
    //     settingData &&
    //     settingData.default_configuration &&
    //     settingData.default_configuration.promise_to_pay
    //   ) {
    //     let fieldKey = Object.keys(
    //       settingData.default_configuration.promise_to_pay
    //     );

    //     fieldKey.map((field) => {
    //       if (
    //         field == "action_follow_up_template_id" ||
    //         field == "action_follow_up_type" ||
    //         field == "confirmation_message_type" ||
    //         field == "confirmation_message_template_id"
    //       ) {
    //         setValue(
    //           field,
    //           setSelectValues(
    //             settingData.default_configuration.promise_to_pay[field]
    //           )
    //         );
    //       } else {
    //         setValue(
    //           field,
    //           settingData.default_configuration.promise_to_pay[field]
    //         );
    //       }
    //     });
    //   } }
  }, [callOut]);

  const customerContacts = useQuery(
    `customer-contacts-${getParams}-${refetchAction}`,
    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const queryClient = useQueryClient();

  // useEffect(() => {
  //   let validEmail =
  //     customerContacts &&
  //     customerContacts.data &&
  //     customerContacts.data.data &&
  //     customerContacts.data.data.docs &&
  //     Array.isArray(customerContacts.data.data.docs)
  //       ? customerContacts.data.data.docs?.reduce((prev, curr) => {
  //           let { phone, landline, email, address, ...rest } = curr;
  //           prev =
  //             email &&
  //             email?.some(({ status, email_status, ...nRest }) => {
  //               return email_status !== "VALID";
  //             });

  //           return prev;
  //         }, [])
  //       : [];
  //
  //   return validEmail;
  // }, []);

  const executeActionMutation = useMutation(
    (formValues) =>
      executeAdhocCustomerAction({
        ...formValues,
        id: getParams,
        entity: "customer",
        organization: currentOrganization,
        call_detail: callRefDetails,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        const { payment_plan_list, is_debit_first_instalment } = variables;

        if (is_debit_first_instalment) {
          dispatch(
            instantPaymentDrawer({
              modal: true,
              ...(payment_plan_list &&
              payment_plan_list[0] &&
              payment_plan_list[0].promise_amount &&
              payment_plan_list[0].promise_amount.value > 0
                ? {
                    amount: payment_plan_list[0].promise_amount.value,
                    currency: payment_plan_list[0].promise_amount.currency,
                  }
                : {}),
            })
          );
        }

        if (data && data.data && data.data.doc) {
          window.open(
            data &&
              data.data &&
              data.data.doc &&
              data.data.doc.payment_initiate_url
          );
          dispatch(refetchActions());
          reset();
          dispatch(isOpenModal(false));
        } else {
          dispatch(refetchActions());
          reset();
          dispatch(isOpenModal(false));
        }
      },
    }
  );

  const makingCall = useMutation(
    (data) =>
      makeCall({
        organization: currentOrganization,
        customerId: getParams,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (data && data.data && data.data.doc && data.data.doc.redirect_url) {
          window.open(
            data.data.doc.redirect_url,
            "popUpWindow",
            "height=500,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
          );
        }
      },
    }
  );

  const onSubmit = async (data) => {
    let recipients = [];
    if (data && data.recipients) {
      data &&
        data?.recipients?.map((e) => {
          recipients?.push({
            id: e?.id,
            recipient_id: e?.recipient_id,
          });
        });
    }

    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let values = {
      action_type: "call",
      // recipients: getSelectValues(data.recipients, true),
      recipients: recipients,
      content: adhocUserInstructionHtml,
      comment: data.comment ? data.comment : "",
      call_outcome: callOut,
    };

    let paymentPlan = {
      action_type: "call",
      // recipients: getSelectValues(data.recipients, true),
      recipients: recipients,
      comment: data.comment ? data.comment : "",
      call_outcome: callOut,
    };

    if (callOut === "PAYMENT") {
      let {
        payment_amount: { value, currency },
        choose_payment,
        is_save_card = false,
        payment_methods,
      } = data;

      if (value > total_outstanding_invoice_amount?.value) {
        return setError("payment_amount.value", {
          message: "Payment Amount is Greater than Outstanding Amount",
        });
      } else if (value <= 0) {
        return setError("payment_amount.value", {
          message: "Enter Valide Amount",
        });
      }

      values = {
        action_type: "call",
        recipients: recipients,
        comment: data.comment ? data.comment : "",
        call_outcome: callOut,
        payment_method: getSelectValues(payment_methods),
        payment_type: getSelectValues(choose_payment),
        payment_amount: { currency: currency?.[0]?.id, value },
        is_save_card: is_save_card,
        is_inbound_call: data.is_inbound_call || false,
        is_increment_follow_up: data.is_increment_follow_up || false,
      };
    }

    if (callOut === "PROMISE_TO_PAY") {
      let {
        promise_type,
        promise_amount: { value, currency },
        promise_date,
        is_hold_workflow = false,
        is_send_confirmation_message = false,
        confirmation_message_type,
        confirmation_message_template_id,
        is_send_action_follow_up = false,
        action_follow_up_type,
        action_follow_up_template_id,
        is_enable_calendar_alert = false,
        calendar_alert_time,
        is_debit_first_instalment = false,
      } = data;

      // let promiseAmt
      if (
        promise_type[0].id === "partial_amount" &&
        value > total_outstanding_invoice_amount.value
      ) {
        return setError("promise_amount.value", {
          type: "custom",
          message: "Amount greater than due amount",
        });
      } else {
        values.promise_amount = promise_amount;
      }

      values = {
        action_type: "call",
        // recipients: getSelectValues(data.recipients, true),
        recipients: recipients,
        content: adhocUserInstructionHtml,
        comment: data.comment ? data.comment : "",
        call_outcome: callOut,
        promise_type: getSelectValues(promise_type),
        ...(promise_type &&
        promise_type[0] &&
        promise_type[0]?.id === "no_amount"
          ? {}
          : { promise_amount: { currency: currency?.[0]?.id, value } }),

        promise_date: moment
          .tz(
            `${promise_date.getFullYear()}-${
              promise_date.getMonth() + 1
            }-${promise_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        ...(is_enable_calendar_alert
          ? { calendar_alert_time: getSelectValues(calendar_alert_time) }
          : {}),
        is_inbound_call: data.is_inbound_call || false,
        is_increment_follow_up: data.is_increment_follow_up || false,
        is_enable_calendar_alert,
        is_hold_workflow,
        is_send_confirmation_message,
        is_send_action_follow_up,
        ...(is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                confirmation_message_template_id
              ),
            }
          : {}),
        ...(is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(action_follow_up_type),
              action_follow_up_template_id: getSelectValues(
                action_follow_up_template_id
              ),
            }
          : {}),
      };
    }

    if (callOut === "PAYMENT_PLAN") {
      let {
        is_hold_workflow = false,
        is_send_confirmation_message = false,
        confirmation_message_type,
        confirmation_message_template_id,
        is_send_action_follow_up = false,
        action_follow_up_type,
        action_follow_up_template_id,
        comment,
        start_date,
        residual_amount_type,
        amount,
        instalation_amount,
        frequency,
        payment_method,
        content,
        is_enable_calendar_alert = false,
        calendar_alert_time,
        is_debit_first_instalment = false,
      } = data;

      values = {
        ...paymentPlan,
        frequency: getSelectValues(frequency),
        ...(is_enable_calendar_alert
          ? { calendar_alert_time: getSelectValues(calendar_alert_time) }
          : {}),
        is_enable_calendar_alert,
        is_debit_first_instalment,
        instalation_amount: {
          currency: customerDetails.default_currency,
          value: instalation_amount,
        },
        is_inbound_call: data.is_inbound_call || false,
        is_increment_follow_up: data.is_increment_follow_up || false,
        method: getSelectValues(payment_method),
        payment_plan_list: installments.map((e) => {
          return {
            ...e,
            promise_amount: {
              ...e?.promise_amount,
              value: +e?.promise_amount?.value?.toFixed(2),
            },
          };
        }),
        amount: { currency: customerDetails.default_currency, value: amount },
        residual_amount_type: getSelectValues(residual_amount_type),

        start_date: moment
          .tz(
            `${start_date.getFullYear()}-${
              start_date.getMonth() + 1
            }-${start_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        is_hold_workflow,

        is_send_confirmation_message,
        is_send_action_follow_up,
        ...(is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                confirmation_message_template_id
              ),
            }
          : {}),
        ...(is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(action_follow_up_type),
              action_follow_up_template_id: getSelectValues(
                action_follow_up_template_id
              ),
            }
          : {}),
        // is_debit_first_instalment: true,
        // // calendar_alert_time: "9_15_AM",

        // is_enable_calendar_alert: true,
      };
    }

    if (callOut === "CALL_BACK_LATER") {
      let {
        callback_date,
        callback_time,
        is_hold_workflow = false,
        is_send_confirmation_message = false,
        confirmation_message_type,
        confirmation_message_template_id,
        is_send_action_follow_up = false,
        action_follow_up_type,
        action_follow_up_template_id,
      } = data;

      values = {
        ...values,
        callback_time: getSelectValues(callback_time),
        // callback_time: moment(callback_time).utc().valueOf(),
        callback_date: moment
          .tz(
            `${callback_date.getFullYear()}-${
              callback_date.getMonth() + 1
            }-${callback_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        is_inbound_call: data.is_inbound_call || false,
        is_increment_follow_up: data.is_increment_follow_up || false,
        is_hold_workflow,
        is_send_confirmation_message,
        is_send_action_follow_up,
        ...(is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                confirmation_message_template_id
              ),
            }
          : {}),
        ...(is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(action_follow_up_type),
              action_follow_up_template_id: getSelectValues(
                action_follow_up_template_id
              ),
            }
          : {}),
      };
    }

    if (callOut === "RECORD_DISPUTE") {
      let {
        dispute_details,
        dispute_type,
        dispute_status,
        dispute_review_date,
        is_hold_workflow = false,
      } = data;

      values = {
        ...values,
        dispute_details,
        is_inbound_call: data.is_inbound_call || false,
        is_increment_follow_up: data.is_increment_follow_up || false,
        dispute_type: getSelectValues(dispute_type),
        dispute_status: getSelectValues(dispute_status),
        dispute_review_date: moment
          .tz(
            `${dispute_review_date.getFullYear()}-${
              dispute_review_date.getMonth() + 1
            }-${dispute_review_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        is_hold_workflow,
      };
    }

    if (callOut === "UNSUCCESSFUL") {
      let {
        followup_date,
        call_unsuccessful_reason,
        is_hold_workflow = false,
        is_send_confirmation_message = false,
        confirmation_message_type,
        confirmation_message_template_id,
      } = data;

      values = {
        ...values,
        call_unsuccessful_reason: getSelectValues(call_unsuccessful_reason),
        ...(followup_date
          ? {
              followup_date: moment
                .tz(
                  `${followup_date.getFullYear()}-${
                    followup_date.getMonth() + 1
                  }-${followup_date.getDate()}`,
                  "YYYY-MM-DD",
                  timeZone
                )
                .utc()
                .valueOf(),
            }
          : {}),
        is_inbound_call: data.is_inbound_call || false,
        is_increment_follow_up: data.is_increment_follow_up || false,
        is_hold_workflow,
        is_send_confirmation_message,
        ...(is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                confirmation_message_template_id
              ),
            }
          : {}),
      };
    }

    if (callOut === "FIELD_VISIT") {
      values = {
        action_type: "call",
        recipients: recipients,
        content: "",
        comment: data.comment,
        call_outcome: callOut,
        visit_date: moment
          .tz(
            `${data.return_visit_date.getFullYear()}-${
              data.return_visit_date.getMonth() + 1
            }-${data.return_visit_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        visit_time: getSelectValues(data.return_visit_time),

        ...(data.location && data.location[0] && data.location[0].id === "other"
          ? { custom_location: data.custom_location }
          : {}),

        visit_address: getSelectValues(data.location),

        is_hold_workflow: data.is_hold_workflow || false,
        is_send_confirmation_message: data.is_send_confirmation_message,
        is_send_action_follow_up: data.is_send_action_follow_up,
        ...(data.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        ...(data.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data.action_follow_up_template_id
              ),
            }
          : {}),
      };
    }

    await executeActionMutation.mutateAsync(values);
  };

  useEffect(async () => {
    await getOrganization({ organization: currentOrganization }).then((res) => {
      setSettingData(res.data.doc || {});
    });
    //TODO: PRAVEEN FAILURE ERROR HANDLING
  }, []);

  let loading = customerContacts && customerContacts?.isLoading;

  useEffect(() => {
    if (
      customerContacts &&
      customerContacts.data &&
      customerContacts.data.data &&
      customerContacts.data.data.docs &&
      Array.isArray(customerContacts.data.data.docs)
    ) {
      let hasPrimaryContact = false;
      let primaryContacts = customerContacts.data.data.docs.reduce(
        (prev, curr) => {
          let { phone, landline, email, address, ...rest } = curr;
          let nestedContacts = [...phone, ...(landline ? landline : [])]
            .map(({ status, call_status, ...nRest }) => {
              if (
                entityName === "INVOICE" &&
                curr.primary_invoices &&
                Array.isArray(curr.primary_invoices) &&
                curr.primary_invoices.includes(id)
              ) {
                hasPrimaryContact = true;
              }

              return {
                id: nRest?._id,
                recipient_id: curr?._id,
                call_status,
                status,
                designation: curr.designation,
                primary_invoices: curr.primary_invoices,
              };
            })
            .filter(
              (e) =>
                (e.status === "VALID" || e.status === "LEAD") &&
                e.call_status === "VALID" &&
                ((!hasPrimaryContact && e.designation === "primary_contact") ||
                  (hasPrimaryContact && e.primary_invoices.includes(id)))
            );

          return [...prev, ...nestedContacts];
        },
        []
      );

      setValue(
        "recipients",
        primaryContacts && primaryContacts[0] ? [primaryContacts[0]] : []
      );
    }
  }, [customerContacts.isLoading]);

  const [isCancel, setIsCancel] = useState(false);
  let PP = customerDetails?.promise_to_pay?.some(
    (e) => e.status == "IN_PROGRESS"
  );
  let in_progress = customerDetails?.promise_to_pay?.find(
    (e) => e.status == "IN_PROGRESS"
  );

  useEffect(() => {
    // if (PP.status == 'IN_PROGRESS') {
    //   setIsCancel(true);
    // }
    if (PP) {
      setIsCancel(true);
    }
  }, [customerDetails?.promise_to_pay]);

  return (
    <form
      style={{
        gridColumn: "2/5",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <div
          // onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "grid",
            // gridTemplateColumns: "repeat(10,50fr)",
            gridTemplateRows: "repeat(12,10fr)",
            height: "100%",
          }}
        >
          <div
            style={
              {
                // padding: "15px 35px",
                // height: "1000%",
                // overflow: "scroll",
              }
            }
          >
            {/* Section 1 */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "5px",
                alignItems: "center",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Call color={"#333860"} height={16} width={16} />
                <HeadingXLarge $style={{ fontSize: "20px" }}>
                  Call
                </HeadingXLarge>
              </div>
              <Controller
                defaultValue={false}
                name={`is_inbound_call`}
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    checked={field.value}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <ParagraphSmall
                      style={{ color: "#333860", marginTop: "3px" }}
                    >
                      Inbound Call
                    </ParagraphSmall>
                  </CheckBoxBaseweb>
                )}
              />
            </div>
          </div>
          <div
            style={{
              // padding: "0px 35px",
              height: "1000%",
              overflow: "scroll",
            }}
          >
            {/* <div className="cp-action-title--content ">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <H2>
                  <FormattedMessage id="capture_call_details">
                    capture_call_details
                  </FormattedMessage>
                </H2>

                <StatefulTooltip
                  accessibilityType={"tooltip"}
                  content={adhocUserInstruction}
                >
                  <i className="mx-icon-info" />
                </StatefulTooltip>
              </div>
            </div> */}
            {/* <div className="cp-action-instruction">
              <Label1 style={{ color: "#adadad" }}>
                Instruction to User --
              </Label1>
              <div style={{ height: "120px", overflow: "scroll" }}>
                {adhocUserInstruction}
              </div>
            </div> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#F0F5FF",
                padding: "10px",
                borderRadius: "5px",
                marginBottom: "15px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ParagraphSmall $style={{ fontSize: "12px" }}>
                  <span style={{ fontWeight: "bold" }}>Alert:</span> Read
                  Instructions before taking Action
                </ParagraphSmall>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ParagraphSmall
                      $style={{ color: "#516beb", fontSize: "11px" }}
                    >
                      Caller Instruction
                    </ParagraphSmall>
                    <DownArrow color={"#516BEB"} />
                  </div>
                  <div
                    style={{
                      height: "5px",
                      width: "5px",
                      borderRadius: "50px",
                      background: "#ADADAD",
                      marginRight: "8px",
                      marginLeft: "5px",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => agentInfoOpen()}
                  >
                    <ParagraphSmall
                      $style={{ color: "#516beb", fontSize: "11px" }}
                    >
                      Agent Info
                    </ParagraphSmall>
                    {isOpenAgentInfo ? (
                      <UpArrow color={"#516BEB"} />
                    ) : (
                      <DownArrow color={"#516BEB"} />
                    )}
                  </div>
                </div>
              </div>
              {isOpenAgentInfo && (
                <ul
                  style={{
                    fontSize: "12px",
                    padding: "0px 15px 5px 15px",
                    height: "200px",
                    overflow: "scroll",
                  }}
                >
                  <li style={{ fontSize: "12px" }}>
                    Debt collectors are required to provide a "Mini-Miranda"
                    warning, informing the debtor that the communication is an
                    attempt to collect a debt.
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    Cease and Desist Communication: Debtors have the right to
                    request that a debt collector cease communication. If a
                    debtor notifies a debt collector in writing that they refuse
                    to pay the debt or want the collector to stop contacting
                    them, the collector must comply, except to inform the debtor
                    that certain actions may be taken.
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    Validation of Debt: Upon the debtor's request, the debt
                    collector must provide validation of the debt, including
                    information about the amount owed and the name of the
                    original creditor.
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    Debt Settlement and Negotiation: Debtors have the right to
                    negotiate and settle debts with creditors. It's important to
                    be aware of the terms and conditions of any settlement
                    agreement and to get the agreement in writing.
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    Protections Against Wage Garnishment: California has state
                    laws that protect a certain percentage of a debtor's income
                    from wage garnishment. Some income sources, like Social
                    Security, are exempt from garnishment.
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    Protection Against Unfair Business Practices: California has
                    consumer protection laws that prohibit unfair and deceptive
                    business practices. Debtors may have legal recourse if they
                    believe they have been treated unfairly.
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    Credit Reporting Accuracy: Debtors have the right to dispute
                    inaccurate information on their credit reports. Credit
                    reporting agencies must investigate and correct any
                    inaccuracies.
                  </li>
                </ul>
              )}
            </div>

            <div className="cp-action-reciever-content">
              <div className="cp-action-select">
                <Controller
                  name="recipients"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.mini}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      requiredAstric={true}
                      label={intl.formatMessage({
                        id: "reciever",
                      })}
                      placeholder={intl.formatMessage({
                        id: "reciever",
                      })}
                      clearable={false}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={
                        customerContacts &&
                        customerContacts.data &&
                        customerContacts.data.data &&
                        customerContacts.data.data.docs &&
                        Array.isArray(customerContacts.data.data.docs)
                          ? customerContacts.data.data.docs.reduce(
                              (prev, curr) => {
                                let {
                                  phone,
                                  landline,
                                  email,
                                  address,
                                  ...rest
                                } = curr;
                                let nestedContacts = [
                                  ...phone,
                                  ...(landline ? landline : []),
                                ]
                                  .map(({ status, call_status, ...nRest }) => {
                                    let designation =
                                      referenceData &&
                                      referenceData["recipient_type"]
                                        ? referenceData["recipient_type"].find(
                                            (rf) => rf?.id === curr?.designation
                                          )
                                        : "";

                                    return {
                                      label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
                                      id: nRest?._id,
                                      recipient_id: curr?._id,
                                      call_status,
                                      status,
                                    };
                                  })
                                  .filter(
                                    (e) =>
                                      (e.status === "VALID" ||
                                        e.status === "LEAD") &&
                                      e.call_status === "VALID"
                                  );

                                return [...prev, ...nestedContacts];
                              },
                              []
                            )
                          : []
                      }
                    />
                  )}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                {currentDefaultFormatDetails.is_cloud_telephony_configured &&
                currentDefaultFormatDetails.cloud_telephony_phone_numbers &&
                Array.isArray(
                  currentDefaultFormatDetails.cloud_telephony_phone_numbers
                ) &&
                currentDefaultFormatDetails.cloud_telephony_phone_numbers
                  .length ? (
                  <>
                    {currentDefaultFormatDetails.is_web_call_configured ? (
                      <div
                        style={{
                          width: "100px",
                          paddingTop: "2px",
                        }}
                      >
                        <Checkbox
                          checked={callType}
                          onChange={() => setCallType(!callType)}
                        >
                          Web Call
                        </Checkbox>
                      </div>
                    ) : (
                      <></>
                    )}
                    {currentDefaultFormatDetails.cloud_telephony_phone_numbers
                      .slice(0, 1)
                      .map((e) => (
                        <div>
                          <TextButton
                            size={SIZE.mini}
                            kind={KIND.primary}
                            type="button"
                            disabled={
                              !userPhoneNumberInfo ||
                              !currentRecipientNumber ||
                              makingCall.isLoading
                            }
                            isLoading={makingCall.isLoading}
                            onClick={async () => {
                              await makingCall
                                .mutateAsync({
                                  recipient: {
                                    id: recipients?.[0]?.id,
                                    recipient_id: recipients?.[0]?.recipient_id,
                                  },
                                  source_number: e,
                                  call_type: callType ? "WEB_CALL" : "CALL",
                                })
                                .then((res) =>
                                  setCallRefDetails({
                                    ref:
                                      res && res.data && res.data.doc.call_ref,
                                    provider:
                                      res && res.data && res.data.doc.provider,
                                  })
                                );
                            }}
                          >
                            {e}
                          </TextButton>
                        </div>
                      ))}

                    {currentDefaultFormatDetails.cloud_telephony_phone_numbers
                      .length > 1 ? (
                      <>
                        <StatefulPopover
                          overrides={{
                            Body: {
                              style: ({ $theme }) => ({
                                borderRadius: "5px",
                                overflow: "hidden",
                                backgroundColor: "#ffffff",
                              }),
                            },
                          }}
                          placement="bottomRight"
                          content={({ close }) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "10px",

                                backgroundColor: "#ffffff",
                              }}
                            >
                              {currentDefaultFormatDetails.cloud_telephony_phone_numbers
                                .slice(
                                  1,
                                  currentDefaultFormatDetails
                                    .cloud_telephony_phone_numbers.length
                                )
                                .map((e, i) => (
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      paddingTop: i === 0 ? "0px" : "5px",
                                      paddingBottom:
                                        i + 2 ===
                                        currentDefaultFormatDetails
                                          .cloud_telephony_phone_numbers.length
                                          ? "0px"
                                          : "5px",
                                      borderBottom:
                                        i + 2 ===
                                        currentDefaultFormatDetails
                                          .cloud_telephony_phone_numbers.length
                                          ? "none"
                                          : "1px solid #e3e3e3",
                                    }}
                                    onClick={async () => {
                                      close();
                                      await makingCall
                                        .mutateAsync({
                                          recipient: {
                                            id: recipients?.[0]?.id,
                                            recipient_id:
                                              recipients?.[0]?.recipient_id,
                                          },
                                          source_number: e,
                                          call_type: callType
                                            ? "WEB_CALL"
                                            : "CALL",
                                        })
                                        .then((res) =>
                                          setCallRefDetails({
                                            ref:
                                              res &&
                                              res.data &&
                                              res.data.doc.call_ref,
                                            provider:
                                              res &&
                                              res.data &&
                                              res.data.doc.provider,
                                          })
                                        );
                                    }}
                                  >
                                    {e}
                                  </div>
                                ))}
                            </div>
                          )}
                          returnFocus
                          autoFocus
                        >
                          <div
                            disabled={
                              !userPhoneNumberInfo ||
                              !currentRecipientNumber ||
                              makingCall.isLoading
                            }
                          >
                            <Button
                              type="button"
                              size={SIZE.mini}
                              kind={KIND.secondary}
                              disabled={
                                !userPhoneNumberInfo ||
                                !currentRecipientNumber ||
                                makingCall.isLoading
                              }
                            >
                              <TripleDot />
                            </Button>
                          </div>
                        </StatefulPopover>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="cp-action-outcome">
              <Label1 style={{ marginBottom: "10px", fontSize: "13px" }}>
                <FormattedMessage id="call_outcome">
                  call_outcome
                </FormattedMessage>
              </Label1>
              <div className="cp-action-outcome-content">
                {[
                  {
                    title: "PTP",
                    outcomeType: "PROMISE_TO_PAY",
                    type: PROMISE,
                  },
                  {
                    title: "CBL",
                    outcomeType: "CALL_BACK_LATER",
                    type: CALLBACKLATER,
                  },
                  // {
                  //   outcomeType: "MEETING_REQUEST",
                  //   type: MEETINGREQUEST,
                  // },
                  {
                    title: "DIS",
                    outcomeType: "RECORD_DISPUTE",
                    type: DISPUTE,
                  },
                  {
                    title: "CL-Fail",
                    outcomeType: "UNSUCCESSFUL",
                    type: UNSUCCESSFUL,
                  },
                  {
                    title: "PPL",
                    outcomeType: "PAYMENT_PLAN",
                    type: PLANNED,
                  },
                  {
                    title: "Pay",
                    outcomeType: "PAYMENT",
                    type: PAY,
                  },
                  {
                    title: "FV",
                    outcomeType: "FIELD_VISIT",
                    type: FIELD_VISIT,
                  },
                ].map((e) => (
                  <>
                    <div
                      onClick={() => setCallOut(e.outcomeType)}
                      className={
                        callOut == e.outcomeType &&
                        e.outcomeType === "UNSUCCESSFUL"
                          ? "cp-action-call-outcome--active-red"
                          : callOut === e.outcomeType
                          ? "cp-action-call-outcome--active"
                          : "cp-action-call-outcome"
                      }
                    >
                      <CallButtonIcon type={e.type} />{" "}
                      {/* <img src={e.image} style={{ marginRight: "5px" }} alt="" /> */}
                      <LabelMedium $style={{ marginLeft: "5px" }}>
                        <FormattedMessage id={e.title}>
                          {/* {e.outcomeType} */}
                        </FormattedMessage>
                      </LabelMedium>
                    </div>
                  </>
                ))}
              </div>
            </div>

            <GetCallAction
              actioWatch={actioWatch}
              total_outstanding_invoice_amount={
                total_outstanding_invoice_amount
              }
              getParams={getParams}
              setIsCancel={setIsCancel}
              isCancel={isCancel}
              watch={watch}
              callOutcome={callOut}
              control={control}
              errors={errors}
              intl={intl}
              Controller={Controller}
              register={register}
              callBackActin={callBackActin}
              setValue={setValue}
              setError={setError}
              settingData={settingData}
              installments={installments}
              setInstallments={setInstallments}
              setDisable={setDisable}
              setIsCompute={setIsCompute}
              isCompute={isCompute}
              clearErrors={clearErrors}
              contactsData={
                customerContacts &&
                customerContacts.data &&
                customerContacts.data.data &&
                customerContacts.data.data.docs
              }
              recipients={recipients && recipients[0]}
              // currency={data?.promise_amount?.currency}
            />
            {/* <PaymentModal
              isPaymentLink={isPaymentLink}
              isPaymentModal={isPaymentModal}
              setIsPaymentModal={setIsPaymentModal}
            /> */}
          </div>
          <div
            style={{
              // background: "#F9F9F9",
              // // width: "100%",
              // height: "70px",
              // padding: "15px",
              // borderTop: "1px solid #CDCED9",
              gridRow: "12/13",
            }}
          >
            {PP && isCancel == false ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ textAlign: "start" }}>
                  <LabelMedium $style={{ color: "#FD372A" }}>
                    {in_progress.type === "PROMISE_TO_PAY"
                      ? "The existing Promise to Pay will be Cancelled, By clicking on Save"
                      : "The existing payment plan will be Cancelled, By clicking on Save"}
                  </LabelMedium>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => {
                          dispatch(isOpenModal(false));
                        }}
                      >
                        Cancel
                      </TextButton>
                    </div>{" "}
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        type="submit"
                        isLoading={executeActionMutation.isLoading}
                        disabled={
                          callOut == "PAYMENT_PLAN"
                            ? isCompute
                            : executeActionMutation.isLoading ||
                              !callOut ||
                              disabled
                        }
                      >
                        Save
                      </TextButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Controller
                  defaultValue={false}
                  name={`is_increment_follow_up`}
                  control={control}
                  render={({ field }) => (
                    <CheckBoxBaseweb
                      {...field}
                      checked={field.value}
                      checkmarkType={STYLE_TYPE.toggle_round}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      <ParagraphSmall
                        style={{ color: "#333860", marginTop: "3px" }}
                      >
                        Increment Count
                      </ParagraphSmall>
                    </CheckBoxBaseweb>
                  )}
                />
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => {
                        dispatch(isOpenModal(false));
                      }}
                    >
                      Cancel
                    </TextButton>
                  </div>{" "}
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      type="submit"
                      isLoading={executeActionMutation.isLoading}
                      disabled={
                        callOut == "PAYMENT_PLAN"
                          ? isCompute
                          : executeActionMutation.isLoading ||
                            !callOut ||
                            disabled
                      }
                    >
                      Save
                    </TextButton>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ManualCall;
