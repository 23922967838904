import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { FormattedNumber } from "react-intl";
import { Icon, Typography } from "../../../components_v2";
import moment from "moment";
import { KIND, TextButton } from "../../../components/TextButton";

const PromiseStatusTypes = ({
  type,
  promiseStatus,
  setIsPromiseExist,
  setAlert,
  setAlertPromise,
  groupedPaymentCustomer,
  groupedPromise,
  consumerGroupPlanText,
  consumerGroupPromiseText,
}) => {
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  switch (type) {
    case "IN_PROGRESS":
      return (
        <>
          <>
            <Typography className="co_action_exist">
              * The consumer have below existing Promise To Pay
            </Typography>
            {setAlert && groupedPaymentCustomer && consumerGroupPlanText && (
              <Typography
                className="co_action_exist"
                style={{ textAlign: "end" }}
              >
                * New Group will be created for the selected records.
              </Typography>
            )}
            {setAlertPromise && groupedPromise && consumerGroupPromiseText && (
              <Typography
                className="co_action_exist"
                style={{ textAlign: "end" }}
              >
                * New Group will be created for the selected records.
              </Typography>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#F0F5FF",
                padding: "6px 10px",
                borderRadius: "5px",
              }}
            >
              <div className="paymentplan_view-btn">
                <Typography>
                  Promise to pay&nbsp;
                  {promiseStatus &&
                    promiseStatus.promise_amount &&
                    promiseStatus.promise_amount.currency}
                  &nbsp;
                  {/* {promiseStatus?.promise_amount?numberFormat.format(promiseStatus?.promise_amount?.value):''} */}
                  <FormattedNumber
                    value={
                      promiseStatus &&
                      promiseStatus.promise_amount &&
                      promiseStatus.promise_amount.value
                        ? promiseStatus?.promise_amount?.value?.toFixed(2)
                        : 0
                    }
                    style="currency"
                  />
                  &nbsp; by &nbsp;
                  {moment(promiseStatus && promiseStatus.promise_date).format(
                    currentDefaultFormatDetails.date_format
                  )}
                </Typography>
              </div>
              <div className="monthly_plan">
                <div>
                  <Icon icon="chevron_down" />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "end",
              }}
              onClick={() => setIsPromiseExist(false)}
            >
              <TextButton
                kind={KIND.tertiary}
                size={"mini"}
                onClick={() => setIsPromiseExist(false)}
              >
                <Icon icon="plus" color="#516BEB" size={18} />
                <Typography style={{ color: "#516BEB" }}>
                  Cancel and Create new
                </Typography>
              </TextButton>
            </div>
          </>
        </>
      );

    // case "PROMISE_TO_PAY":
    //   return (
    //     <>
    //       <>
    //         <Typography style={{ textAlign: "end" }}>
    //           * The customer have below existing Promise To Pay
    //         </Typography>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "row",
    //             justifyContent: "space-between",
    //             alignItems: "center",
    //             background: "#F0F5FF",
    //             padding: "6px 10px",
    //             borderRadius: "5px",
    //           }}
    //         >
    //           <div className="paymentplan_view-btn">
    //             <Typography>
    //               Promise to pay&nbsp;
    //               {promiseStatus &&
    //                 promiseStatus.promise_amount &&
    //                 promiseStatus.promise_amount.currency}
    //               &nbsp;
    //               {/* {promiseStatus?.promise_amount?numberFormat.format(promiseStatus?.promise_amount?.value):''} */}
    //               <FormattedNumber
    //                 value={
    //                   promiseStatus &&
    //                   promiseStatus.promise_amount &&
    //                   promiseStatus.promise_amount.value
    //                 }
    //                 style="currency"
    //               />
    //               &nbsp; by &nbsp;
    //               {moment(promiseStatus && promiseStatus.promise_date).format(
    //                 currentDefaultFormatDetails.date_format
    //               )}
    //             </Typography>
    //           </div>
    //           <div className="monthly_plan">
    //             <div>
    //               <Icon icon="chevron_down" />
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           style={{
    //             display: "flex",
    //             gap: "5px",
    //             alignItems: "center",
    //             justifyContent: "end",
    //           }}
    //           onClick={() => setIsPromiseExist(false)}
    //         >
    //           <Icon icon="plus" />
    //           <Typography $style={{ color: "#516BEB" }}>
    //             Cancel and Create new
    //           </Typography>
    //         </div>
    //       </>
    //     </>
    //   );

    default:
      return null;
  }
};

export default PromiseStatusTypes;
