import React, { useState } from "react";
import { Select } from "baseui/select";
import { FormControl } from "baseui/form-control";
import UpArrow from "../../assets/img/svg/UpArrow";
import DownArrow from "../../utils/DownArrow";

export const SelectBox = (props) => {
  const {
    error = "",
    children,
    value = "",
    label = "",
    requiredAstric = false,
    noResultMessage = "",
    customOverride = {},
  } = props;

  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <FormControl
      error={error}
      overrides={{
        Caption: {
          style: ({ $theme }) => ({
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "12px",
            textAlign: "left",
          }),
        },
      }}
    >
      <div className="mx-input">
        <label
          htmlFor={props.name}
          className={`mx-input__label mx-input__label--select  ${
            isFocused ? "mx-input__label--active text-primary" : ""
          } ${
            !isFocused && value?.length > 0 ? "mx-input__label--active" : ""
          } ${error ? "text-error" : ""}`}
        >
          {label}{" "}
          {requiredAstric ? (
            <>
              <span style={{ color: "red" }}>*</span>
            </>
          ) : (
            <></>
          )}
        </label>
        <Select
          id={props.name}
          autoComplete="off"
          autoFill="off"
          maxDropdownHeight="250px"
          noResultsMsg={`${noResultMessage ? noResultMessage : "No Result"}`}
          overrides={{
            Tag: {
              props: {
                overrides: {
                  Text: {
                    style: ({ $theme }) => ({
                      maxWidth: "500px",
                    }),
                  },
                },
              },
            },
            Placeholder: {
              style: ({ $theme, $isFocused }) => ({
                color: $isFocused ? "#cbd5e0" : "transparent",
                transition: "color 0.4s ease",
              }),
            },
            ValueContainer: {
              style: ({ $theme, $isFocused }) => ({
                ...(customOverride && customOverride.ValueContainer
                  ? { ...customOverride.ValueContainer }
                  : {}),
              }),
            },
            ControlContainer: {
              style: ({ $theme, $isFocused, $error }) => ({
                borderRadius: "5px",
                backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                borderRadius: "3px",
                borderColor: "#CDCED9",
                borderWidth: "0.5px",
                borderStyle: "solid",
                paddingRight: "0px",
                backgroundColor: "#ffffff",
                borderRadius: "5px",
                borderWidth: "1px",
                borderColor: $isFocused
                  ? $error
                    ? "#FD372A"
                    : "#516BEB"
                  : $error
                  ? "#FD372A"
                  : "#CDCED9",
              }),
            },
          }}
          {...props}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
            onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
            onBlur();
          }}
        />
      </div>
    </FormControl>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
