import React, { useContext, useEffect, useState } from "react";
import {
  Label1,
  Label2,
  Paragraph1,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import { TextButton, SIZE } from "../TextButton";
import { IconButton, KIND } from "../IconButton";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../SelectBox";
import { useIntl } from "react-intl";
import * as ModalButton from "../TextButton";
import {
  getWorkflowlist,
  assignWorkflow,
  getWorkflow,
  getOrganization,
  getCustomerContactDetail,
  assignMulCusWfRel,
  addKpiData,
} from "../../services";
import getSelectValues from "../../utils/getSelectValues";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment-timezone";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { Typography } from "../../components_v2";
import { TextBox } from "../TextBox";
import queryClient from "../../providers/queryClient";
import { KPI_MAINTENANCE } from "../../constants";

const AddKpiForm = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  customerRefetch,
  isAllRowsSelected,
  queryFilter,
}) => {
  const intl = useIntl();
  let maxyfiContextValue = useContext(MaxyfiContext);

  let {
    currentOrganization,
    referenceData,
    refetch,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_users: false,
    },
  });

  const postKpiData = useMutation(
    (values) =>
      addKpiData({
        values,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
          toggleAllRowsSelected(false);
          handleModal();
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        customerRefetch();
        handleModal();
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      users: selectedCustomer,
      is_all_users: data.is_all_customer ? data.is_all_customer : false,
      pcat: data.pcat,
      camt: data.camt,
    };
    await postKpiData.mutateAsync(values);
  };

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography
          type="h2"
          style={{
            padding: "15px",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          Set The Amount
        </Typography>

        <Controller
          name="pcat"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              fullWidth
              name={field.name}
              size={SIZE.compact}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "payment_amount",
              })}
              placeholder={intl.formatMessage({
                id: "payment_amount",
              })}
              value={field.value}
            />
          )}
        />
        <Controller
          name="camt"
          control={control}
          render={({ field }) => (
            <TextBox
              {...field}
              fullWidth
              name={field.name}
              size={SIZE.compact}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "commission_amount",
              })}
              placeholder={intl.formatMessage({
                id: "commission_amount",
              })}
              value={field.value}
            />
          )}
        />

        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching KPI records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Submit
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddKpiForm;
