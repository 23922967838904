import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _, { cond, size } from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";

import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  ACT_PROMISE_TO_PAY,
  ACT_PAYMENT_PLAN,
  CO_INVOICES_,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import InstallmentList from "./InstallmentList";
import { useDispatch, useSelector } from "react-redux";
import { PAYMENT_CARD_DRAWER, setDrawerState } from "../../../../redux/actions";
import PromiseStatusTypes from "../PromiseStatusTypes";
import { Icon, Typography } from "../../../../components_v2";
import useFormat from "../../../../hooks/useFormat";
import { getHolidayBlackoutHolidayDate } from "../../../../services";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { ParagraphMedium } from "baseui/typography";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";

const PaymentPlanForm = (props) => {
  const {
    isInvoice,
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    isIncrementor = true,
    selectedInvoices = {},
    totalAmount = 0,
    is_inbound_call = false,
  } = props;
  let dispatch = useDispatch();
  const { customerId } = useParams();
  const intl = useIntl();
  const format = useFormat();
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let { validationField } = useSelector((e) => e.customerOverviewDrawer);

  const [isCompute, setIsCompute] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [card, setCard] = useState({
    isCard: false,
    cardId: "add_card",
    paymentMethodId: null,
  });

  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const cards = useMemo(() => {
    let cards = _.get(customerData, "data.doc.transaction_cards", []);
    return cards && Array.isArray(cards)
      ? [...cards, { id: "add_card" }]
      : [{ id: "add_card" }];
  }, [customerData]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      residual_amount_type: [{ id: "LAST_INSTALMENT" }],
      method: [{ id: "PORTAL" }],
      frequency: [{ id: "WEEKLY", label: "Weekly" }],
      start_date: new Date(),
    },
  });

  let {
    instalation_amount,
    amount,
    frequency,
    start_date,
    residual_amount_type,
    is_enable_calendar_alert,
    is_debit_first_instalment,
  } = watch();

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: { date: installments.map((e) => e.promise_date) },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: installments && installments.length > 0 ? true : false,
    }
  );

  const { outstanding_amount } = useMemo(() => {
    const outstanding_amount = Number(
      _.get(customerData, "data.doc.total_outstanding_invoice_amount.value", 0)
    ).toFixed(2);

    setValue("amount", outstanding_amount);

    return { outstanding_amount };
  }, [customerData?.data]);

  useEffect(() => {
    if (totalAmount > 0) {
      setValue("amount", totalAmount);
    } else {
      setValue("amount", outstanding_amount);
    }
  }, [residual_amount_type, totalAmount]);

  useEffect(() => {
    if (amount <= 0) {
      setError("amount", {
        message: "Required",
      });
    } else if (Number(amount) > Number(outstanding_amount)) {
      setError("amount", {
        message: "Amount Should Not greater than O/S amount",
      });
    } else if (Number(amount) < Number(instalation_amount)) {
      setError("amount", {
        message: "Amount Should Not greater than In amount",
      });
    } else {
      clearErrors("amount");
    }
  }, [amount, instalation_amount]);

  useEffect(() => {
    if (Number(instalation_amount) <= 0) {
      setError("instalation_amount", {
        message: "Required",
      });
    } else if (
      _.get(validationField, "minPayment") &&
      _.get(validationField, "minPayment") > Number(instalation_amount)
    ) {
      setError("instalation_amount", {
        message: `*Minimum Installment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else if (Number(amount) < Number(instalation_amount)) {
      setError("instalation_amount", {
        message: "Installment Amount more than the total Amount",
      });
    } else {
      clearErrors("instalation_amount");
    }

    if (Number(amount) < _.get(customerData, "data.doc.minPayment")) {
      setError("amount", {
        message: `*Minimum Payment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    }
  }, [instalation_amount, amount]);

  const calculateTenure = () => {
    const totalAmount = parseFloat(amount) || 0;
    const installmentAmount = parseFloat(instalation_amount) || 0;
    const startDate = start_date ? new Date(start_date) : "";
    const frequencyId =
      frequency && frequency?.[0] && frequency?.[0]?.id && frequency?.[0]?.id
        ? frequency &&
          frequency?.[0] &&
          frequency?.[0]?.id &&
          frequency?.[0]?.id
        : "WEEKLY";
    const residualAmountOption =
      residual_amount_type &&
      residual_amount_type[0] &&
      residual_amount_type[0].id;
    const quotient = installmentAmount / totalAmount;
    const findReminder = (installmentAmount - totalAmount) * quotient;

    const numOfInstallments = totalAmount / installmentAmount;
    const residualPerc = numOfInstallments - parseInt(numOfInstallments);
    const reminder = installmentAmount * residualPerc;

    const freqMoment = {
      WEEKLY: {
        type: "day",
        count: 7,
      },
      FORTNIGHTLY: {
        type: "day",
        count: 14,
      },
      MONTHLY: {
        type: "month",
        count: 1,
      },
      QUARTERLY: {
        type: "month",
        count: 3,
      },
    };

    let countInc = 0;
    const installments = Array.from({
      length: parseInt(numOfInstallments),
    }).map((inst, idx) => {
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${start_date ? start_date.getFullYear() : ""}-${
            start_date ? start_date.getMonth() + 1 : ""
          }-${start_date ? start_date.getDate() : ""}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails?.time_zone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      countInc += count;

      return {
        promise_date: installmentDate,
        promise_amount: {
          value:
            installmentAmount +
            (residualAmountOption === "FIRST_INSTALMENT" && idx === 0
              ? reminder
              : 0) +
            (residualAmountOption === "LAST_INSTALMENT" &&
            idx === parseInt(numOfInstallments) - 1
              ? reminder
              : 0),
          currency: _.get(customerData, "data.doc.default_currency", ""),
        },
      };
    });

    if (reminder != 0 && residualAmountOption === "KEEP_LAST_INSTALMENT") {
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${start_date.getFullYear()}-${
            start_date.getMonth() + 1
          }-${start_date.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails?.time_zone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      installments.push({
        promise_date: installmentDate,
        promise_amount: {
          value: reminder,
          currency: _.get(customerData, "data.doc.default_currency", ""),
        },
      });
    }

    // old code is removed for calculation on 20-12-2023
    if (installments.length > 0) {
      setInstallments(installments);
    }
  };

  const [startDate] = watch(["start_date"]);

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: (data, variables, context) => {
        const { payment_plan_list, is_debit_first_instalment } = variables;
        if (is_debit_first_instalment) {
          if (_.get(data, "data.payment_initiate_url", "")) {
            onSuccess();
            window.open(_.get(data, "data.payment_initiate_url", ""));
          } else if (_.get(data, "data.payment_status_fetch_url", "")) {
            onSuccess();
            window.open(_.get(data, "data.payment_status_fetch_url", ""));
          }
          onCancel();
          // setDrawer(PAYMENT_CARD_DRAWER, {
          //   ...(payment_plan_list &&
          //   payment_plan_list[0] &&
          //   payment_plan_list[0].promise_amount &&
          //   payment_plan_list[0].promise_amount.value > 0
          //     ? {
          //         amount: payment_plan_list[0].promise_amount.value,
          //         currency: payment_plan_list[0].promise_amount.currency,
          //       }
          //     : {}),
          // });
        } else {
          onSuccess();
        }
      },
    }
  );

  const onSubmit = async (data) => {
    const {
      promise_type,
      promise_date,
      promise_amount,
      promise_currency,
      amount,
      frequency,
      residual_amount_type,
      start_date,
      method,
      instalation_amount,
      is_debit_first_instalment = false,
      payment_method,
      is_save_card = false,
    } = data;

    if (isInvoice.length < 1) {
      return;
    }
    if (
      promise_type &&
      promise_type[0] &&
      promise_type[0].id === "partial_amount" &&
      promise_amount > outstanding_amount
    ) {
      return setError("promise_amount", {
        type: "custom",
        message: "Amount greater than due amount",
      });
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...selectedInvoices,
      ...formValues,
      // ...data,
      // ...(isIncrementor ? { content: "-" } : {}),
      //TODO
      ...(card.cardId !== "add_card" ? { card_id: card.cardId } : {}),
      ...(card.cardId === "add_card"
        ? {
            payment_method: getSelectValues(payment_method, false),
            is_save_card: is_save_card,
          }
        : {}),

      frequency: getSelectValues(frequency),
      promise_type: getSelectValues(promise_type),
      start_date: moment
        .tz(
          `${start_date?.getFullYear()}-${
            start_date?.getMonth() + 1
          }-${start_date?.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails.time_zone
        )
        .utc()
        .valueOf(),
      ...(promise_type && promise_type[0] && promise_type[0].id !== "no_amount"
        ? {
            promise_amount: {
              value: promise_amount,
              currency: getSelectValues(promise_currency),
            },
          }
        : {}),
      ...getActionReminder(data, {
        is_consent_enabled: false,
        is_incrementor: isIncrementor,
      }),
      is_debit_first_instalment,
      mention_users: uniqueMentionedUsers,
      amount: {
        currency: _.get(customerData, "data.doc.default_currency", ""),
        value: amount,
      },
      payment_plan_list: installments,
      residual_amount_type: getSelectValues(residual_amount_type),
      method: getSelectValues(method),
      instalation_amount: {
        currency: _.get(customerData, "data.doc.default_currency", ""),
        value: instalation_amount,
      },
    };

    await serviceMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "5px", marginTop: "10px" }}>
        <Controller
          name="amount"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              hidden={true}
              type="number"
              name={field.name}
              size="mini"
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "planned_payment_amount",
              })}
              placeholder={intl.formatMessage({
                id: "planned_payment_amount",
              })}
              value={field.value}
              onChange={(e) => {
                setIsEdit(false);
                setInstallments([]);
                if (setIsCompute) {
                  setIsCompute(true);
                }
                field.onChange(e.target.value);
              }}
            />
          )}
        />
        <Controller
          name="instalation_amount"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              type="number"
              size="mini"
              name={field.name}
              requiredAstric={true}
              label={intl.formatMessage({
                id: "planned_installation_amount",
              })}
              placeholder={intl.formatMessage({
                id: "planned_installation_amount",
              })}
              value={field.value}
              onChange={(e) => {
                setInstallments([]);
                if (setIsCompute) {
                  setIsCompute(true);
                }
                field.onChange(e.target.value);
              }}
              error={errors[field.name] && errors[field.name].message}
            />
          )}
        />
        <Controller
          name="start_date"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <>
              <DateRangeSelect
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                size="mini"
                label={intl.formatMessage({
                  id: "planned_start_date",
                })}
                placeholder={intl.formatMessage({
                  id: "planned_start_date",
                })}
                value={field.value}
                minDate={new Date()}
                onChange={(e) => {
                  setInstallments([]);
                  if (setIsCompute) {
                    setIsCompute(true);
                  }
                  field.onChange(e.date);
                }}
              />
            </>
          )}
        />
        <Controller
          name="frequency"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              requiredAstric={true}
              size="mini"
              error={errors[field.name] && errors[field.name].message}
              clearable={false}
              label={intl.formatMessage({
                id: "planned_frequency",
              })}
              value={field.value}
              onChange={(e) => {
                setInstallments([]);
                if (setIsCompute) {
                  setIsCompute(true);
                }
                field.onChange(e.value);
              }}
              options={referenceData["payment_plan_frequency"] || []}
            />
          )}
        />
      </div>
      <div style={{ display: "flex", gap: "5px" }}>
        <div style={{ width: "235px" }}>
          <Controller
            name="residual_amount_type"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                size="mini"
                error={errors[field.name] && errors[field.name].message}
                clearable={false}
                label={intl.formatMessage({
                  id: "residual_amount",
                })}
                value={field.value}
                onChange={(e) => {
                  setInstallments([]);
                  if (setIsCompute) {
                    setIsCompute(true);
                  }
                  field.onChange(e.value);
                }}
                options={referenceData["payment_plan_residual"] || []}
              />
            )}
          />
        </div>
        <div style={{ width: "235px" }}>
          <Controller
            name="method"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                size="mini"
                error={errors[field.name] && errors[field.name].message}
                clearable={false}
                label={intl.formatMessage({
                  id: "method",
                })}
                value={field.value}
                onChange={(e) => {
                  setInstallments([]);
                  if (setIsCompute) {
                    setIsCompute(true);
                  }
                  field.onChange(e.value);
                }}
                options={referenceData["payment_plan_method"] || []}
              />
            )}
          />
        </div>
        <div>
          <TextButton
            kind="primary"
            size="mini"
            type="button"
            disabled={!instalation_amount?.toString()?.length > 0 || !errors}
            onClick={() => {
              // setIsCheck(false);
              if (setIsCompute) {
                setIsCompute(false);
              }
              calculateTenure();
              setIsEdit(false);
            }}
          >
            Click to Compute
          </TextButton>
        </div>
      </div>
      {Array.isArray(installments) && installments.length > 0 && (
        <InstallmentList
          installments={installments}
          frequency={_.get(frequency, "[0].label", "-")}
          planned_payment_amount={amount}
          setInstallments={setInstallments}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section" style={{ marginTop: "10px" }}>
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              One or more schedule falls on a Holiday -{" "}
              {_.get(data, "data.doc.holidays", []).map((val, i) => {
                return (
                  <b>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                    {_.get(data, "data.doc.holidays", []).length - 1 === i
                      ? "."
                      : ","}{" "}
                  </b>
                );
              })}
            </Typography>
          </div>
        </>
      )}

      {is_debit_first_instalment && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: " 10px 0px 10px 0px",
          }}
        >
          <Typography type="p" className="text-secondary" subType="regular">
            Saved Cards
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              flexWrap: "wrap",
            }}
          >
            {cards.map((e, i) => (
              <>
                {e.id === "add_card" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "2px",
                      padding: "4px",
                      width: "195px",
                      border: "1px solid #EEEEEE",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setCard({
                        isCard: true,
                        cardId: e.id,
                        paymentMethodId: e.payment_config_id,
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <div className="add_saved_pay_cards">
                        <Icon icon="plus" color="#516beb" />
                      </div>

                      <Typography type="p" className="regular" subType="medium">
                        Add New Card
                      </Typography>
                    </div>
                    <Radio checked={card && card.cardId === e.id}></Radio>
                  </div>
                ) : (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "2px",
                      padding: "4px",
                      width: "195px",
                      border: "1px solid #EEEEEE",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setCard({
                        isCard: true,
                        cardId: e.id,
                        paymentMethodId: e.payment_config_id,
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography type="p" className="text-primary">
                        {format.rd({
                          name: "online_payment_method",
                          id: e?.payment_config_id,
                        })}
                      </Typography>
                      <div style={{ display: "flex", gap: "3px" }}>
                        <Typography
                          type="p"
                          className="regular"
                          subType="regular"
                        >
                          Card Ends with
                        </Typography>
                        <Typography
                          type="p"
                          className="regular"
                          subType="medium"
                        >
                          {e?.last4}
                        </Typography>
                      </div>
                    </div>
                    <Radio checked={card && card.cardId === e.id}></Radio>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}

      {is_debit_first_instalment && card.cardId === "add_card" && (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ width: "235px" }}>
            <Controller
              name="payment_method"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  size="mini"
                  error={errors[field.name] && errors[field.name].message}
                  clearable={false}
                  label={intl.formatMessage({
                    id: "payment_method",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    setInstallments([]);
                    if (setIsCompute) {
                      setIsCompute(true);
                    }
                    field.onChange(e.value);
                  }}
                  options={referenceData["online_payment_method"] || []}
                />
              )}
            />
          </div>
          <Controller
            defaultValues={false}
            name="is_save_card"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                Save Card Information for Future Purpose
              </CheckBoxBaseweb>
            )}
          />
        </div>
      )}

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={
          formValues.is_inbound_call ? true : false || is_inbound_call
        }
        outcome={ACT_PAYMENT_PLAN}
        consentDate={startDate}
        callValidationDuration={_.get(
          customerData,
          "data.doc.mini_miranda.call_validation_duration"
        )}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_incrementor={props.isIncrementor}
        is_consent_enabled={props.isConsent}
        is_first_installment={true}
        is_valid_from_contact={
          formValues.action_type === "FIELD_VISIT" ||
          formValues.action_type === "ACTION_CAPTURE"
            ? true
            : false
        }
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading || isCompute}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

const PaymentPlan = (props) => {
  const { customerId } = useParams();
  const format = useFormat();
  // const { state, setState } = props;
  const [text, setAlert] = useState(false);
  const [isInvoice, setIsInvoice] = useState([]);
  const [currentInvoice, setCurrentInvoice] = useState([]);
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const [isPromiseExist, setIsPromiseExist] = useState(false);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);

  const invOption = useMemo(() => {
    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
        label: `${format.rd({
          id: _.get(e, "business_unit", ""),
          name: "business_unit_list",
        })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
          e,
          "invoice_number",
          ""
        )}${_.get(e, "invoice_number", "") ? " / " : ""}
      ${_.get(e, "client_reference", "")}${
          _.get(e, "client_reference", "") ? " / " : ""
        }
      ${moment
        .utc(e.invoice_date)
        .tz(currentDefaultFormatDetails.time_zone)
        .format(currentDefaultFormatDetails.date_format)}${
          _.get(not_paid_amount, "currency", "") ? " / " : ""
        }${_.get(not_paid_amount, "currency", "")}${
          _.get(not_paid_amount, "value", "") ? " " : ""
        }${_.get(not_paid_amount, "value", "")}`,
      }));
    setIsInvoice([...invIds]);
    setCurrentInvoice([...invIds]);
    return invIds;
  }, [invoicesData]);

  const consumerGroupingText = _.get(invoicesData, "data.docs", []).some(
    (obj) => obj.hasOwnProperty("pgid")
  );

  const promiseStatus = useMemo(() => {
    let matchingIds = [];
    for (
      let i = 0;
      i < _.get(customerData, "data.doc.promise_to_pay", []).length;
      i++
    ) {
      let invArray =
        customerData && customerData?.data?.doc?.promise_to_pay?.[i];
      for (let j = 0; j < invArray?.inv?.length; j++) {
        if (isInvoice.some((item) => item.id === invArray?.inv?.[j])) {
          if (invArray && invArray.status === "IN_PROGRESS") {
            matchingIds.push(invArray);
          }
        }
      }
    }
    return matchingIds;
  }, [isInvoice]);

  useEffect(() => {
    if (promiseStatus.length > 0) {
      setIsPromiseExist(true);
    }
  }, [isInvoice]);

  return (
    <div style={{ marginTop: "15px" }}>
      <div style={{ width: "350px" }}>
        <SelectBox
          requiredAstric={true}
          value={isInvoice}
          getValueLabel={({ option }) => {
            const { label } = option;
            const value = label ? label.split("/") : [];
            return <>{value && value[1] ? value[1] : value[0]}</>;
          }}
          onChange={(e) => {
            setIsInvoice(e.value);
            setAlert(e.value.length < currentInvoice.length);
          }}
          multi
          options={invOption}
          size={SIZE.mini}
        />
        {isInvoice?.length < 1 && (
          <span style={{ color: "red" }}>Invioces is required</span>
        )}
      </div>
      {!isPromiseExist &&
        text &&
        _.get(customerData, "data.doc.gid") &&
        consumerGroupingText && (
          <Typography
            className="co_action_exist"
            style={{ textAlign: "start" }}
          >
            * New Group will be created for the selected records.
          </Typography>
        )}

      {isPromiseExist ? (
        <PromiseStatusTypes
          setIsPromiseExist={setIsPromiseExist}
          consumerGroupPlanText={consumerGroupingText}
          groupedPaymentCustomer={_.get(customerData, "data.doc.pgid")}
          setAlert={text}
          type={_.get(promiseStatus, "[0].status", "")}
          promiseStatus={_.get(promiseStatus, "[0]", {})}
        />
      ) : (
        <PaymentPlanForm
          isInvoice={isInvoice}
          selectedInvoices={{ invoices: isInvoice?.map(({ id }) => id) }}
          totalAmount={isInvoice
            .reduce((sum, item) => +sum + +item?.outstanding?.value, 0)
            .toFixed(2)}
          {...props}
        />
      )}
    </div>
  );
};

export default PaymentPlan;
