import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import NoteContainer from "./Notes/NoteContainer";
import {
  ADD_CUSTOMER_NOTES,
  DELETE_CUSTOMER_NOTES,
  EDIT_CUSTOMER_NOTES,
} from "../../providers/RBACProvider/permissionList";
import { editDairyNote, postDairyNote } from "../../services";

const NoteCard = () => {
  return <></>;
};

const NoteList = ({ consumerId }, rest) => {
  const { customerId } = useParams();
  const queryClient = useQueryClient();

  return (
    <div style={{ width: "420px" }}>
      <NoteContainer
        {...rest}
        resourceName="customerId"
        resourceId={consumerId ? consumerId : customerId}
        entity={"customer"}
        createDiaryNoteService={postDairyNote}
        editDairyNote={editDairyNote}
        addNotePermission={ADD_CUSTOMER_NOTES}
        editNotePermission={EDIT_CUSTOMER_NOTES}
        deleteNotePermission={DELETE_CUSTOMER_NOTES}
      />
    </div>
  );
};

export default NoteList;
