import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

const Department = (props) => {
  let { referenceData } = useContext(MaxyfiContext);
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  const userDepartment = referenceData?.user_department?.find(
    ({ id }) => id == value
  );

  return (
    <div style={{ textAlign: alignment, width: "100%" }}>
      {userDepartment?.label}
    </div>
  );
};

export default Department;
