import React from "react";

const WarningAlert = () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2.23152L19.6903 16.7657H2.30975L11 2.23152ZM0.313185 15.6485C-0.575459 17.1344 0.532461 19 2.30975 19H19.6903C21.4675 19 22.5755 17.1344 21.6868 15.6485L12.9966 1.11436C12.1079 -0.371454 9.89208 -0.371454 9.00344 1.11436L0.313185 15.6485ZM9.84592 7.82846V10.0628C9.84592 10.6772 10.3653 11.1799 11 11.1799C11.6347 11.1799 12.1541 10.6772 12.1541 10.0628V7.82846C12.1541 7.21402 11.6347 6.7113 11 6.7113C10.3653 6.7113 9.84592 7.21402 9.84592 7.82846ZM9.84592 13.4142H12.1541V15.6485H9.84592V13.4142Z"
        fill="#FD372A"
      />
    </svg>
  );
};

export default WarningAlert;
