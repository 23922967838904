import axiosWithAuth from "../providers/AxiosInterceptor";

// export const createHolidayBlackout = async ({
//   organization = null,
//   ...values
// }) => {
//   return await axiosWithAuth.post(
//     `${process.env.REACT_APP_HOST}${organization}/holiday-maintenance`,
//     values
//   );
// };

export const getRequestInformationList = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/request-information`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },
    }
  );
};

export const getRequestInformationDashboard = async ({
  organization = null,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/request-information/dashboard`,
    {
      params: {
        ...filters,
      },
    }
  );
};

// export const getHolidayBlackout = async ({ organization = null, id }) => {
//   return await axiosWithAuth.get(
//     `${process.env.REACT_APP_HOST}${organization}/holiday-maintenance/${id}`
//   );
// };

// export const getHolidayBlackoutAudit = async ({ organization = null, id }) => {
//   return await axiosWithAuth.get(
//     `${process.env.REACT_APP_HOST}${organization}/holiday-maintenance/${id}/audits`
//   );
// };

// export const updateHolidayBlackout = async ({
//   organization,
//   id,
//   ...values
// }) => {
//   return await axiosWithAuth.put(
//     `${process.env.REACT_APP_HOST}${organization}/holiday-maintenance/${id}`,
//     values
//   );
// };

// export const enableHolidayBlackout = async ({ organization, id }) => {
//   return await axiosWithAuth.patch(
//     `${process.env.REACT_APP_HOST}${organization}/holiday-maintenance/${id}`
//   );
// };
