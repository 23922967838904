function ProgressCircle() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <style type="text/css">
	.st0{fill:#FFFFFF;stroke:#ADADAD;stroke-width:3;}
</style> */}
        <circle
          style={{ fill: "#ffffff", stroke: "#ADADAD", strokeWidth: "3" }}
          cx="5"
          cy="5"
          r="3.5"
        />
      </svg>
    </>
  );
}

export default ProgressCircle;
