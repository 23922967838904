import React, { useState, useEffect } from "react";
import { getOrganization } from "../../../../services";
import { useIntl, FormattedMessage, FormattedDate } from "react-intl";
import { H2 } from "baseui/typography";
import { Label1 } from "baseui/typography";
import { ParagraphMedium } from "baseui/typography";
import { TextBox } from "../../../../components/TextBox";
import ReactQuill from "react-quill";
import { TextBoxTag } from "../../../../components/TextBoxTag/TextBoxTag";
import { SelectBox } from "../../../../components/SelectBox";
import AlertInfo from "../../../../assets/img/svg/AlertInfo";
import { IconButton } from "../../../../components/IconButton";
import FullScreen from "../../../../assets/img/svg/FullScreen";
import { TextArea } from "../../../../components/TextArea";
import { useQuery } from "react-query";
import { getCommunicationTemplateList } from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useContext } from "react";
import UploaderFile from "./AdhocActionPopup/EmailAttachments/UploaderFile";
import { useSelector } from "react-redux";
import moment from "moment";
import setSelectValues from "../../../../utils/setSelectValues";
import { set } from "lodash";
import EmailAttachment from "../../../../components/EmailAttachment/EmailAttachment";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  createDocument,
  scanningAttachment,
} from "../../../../services/documentSystem";
import { useDispatch } from "react-redux";
import {
  documentDriveQuickAction,
  emailAttachmentId,
  emailAttachmentStatus,
  emailFinalAttachmentData,
  unSelectedDocumentData,
} from "../../../../redux/customerOverview/DMSystem/action";
import { updateEmailAttachmentFromDesktop } from "../../../../redux/customerOverview/DMSystem/action";

const EmailForm = ({
  Controller,
  control,
  errors,
  hideWorkflowArea,
  setError,
  clearErrors,
  setValue,
  contacts,
  contactsData,
  recipientsData,
  isAdhocAction = false,
  currentActioDate,
  emailAttached,
  getParams,
  entity,
  removeAttchFile,
  updatingDeskUpload,
  emailOptions,
  setAttachment,
  data,
  watch,
}) => {
  const {
    referenceData,
    currentOrganization,
    userInfo,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  let { recipients } = watch();
  const { emailAttachment } = useSelector((s) => s.customerDocumentReducer);

  let template;

  emailAttachment.map((e) => {});

  const smsGateWayDatas = currentDefaultFormatDetails?.email?.value;
  const intl = useIntl();

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let dayDiff = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.diff(moment.tz(timeZone), "days", true);

  let dayDifference = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  dayDiff = Math.ceil(dayDiff);

  let Block = ReactQuill.Quill.import("blots/block");

  Block.tagName = "DIV";
  ReactQuill.Quill.register(Block, true);

  let Embed = ReactQuill.Quill.import("blots/embed");
  class MxTable extends Embed {
    static create(paramValue) {
      let node = super.create();
      node.innerHTML = paramValue;
      return node;
    }

    static value(node) {
      return node.innerHTML;
    }
  }

  MxTable.blotName = "table";
  MxTable.tagName = "mx-table";
  ReactQuill.Quill.register(MxTable);

  useEffect(() => {
    let setData =
      contactsData && Array.isArray(contactsData)
        ? contactsData.reduce((prev, curr) => {
            let { phone, landline, email, address, ...rest } = curr;
            let nestedContacts = email
              .map(({ status, email_status, ...nRest }) => {
                return {
                  id: nRest?._id,
                  recipient_id: curr?._id,
                  email_status,
                  status,
                };
              })
              .filter(
                (e) =>
                  (e.status === "VALID" || e.status === "LEAD") &&
                  e.email_status === "VALID"
              );

            return [...prev, ...nestedContacts];
          }, [])
        : [];

    setValue("recipients", setData);
  }, [contactsData]);

  return (
    <div style={{ padding: "0px 20px 0px 20px", height: "33vh" }}>
      <div className="cp-action-title--content  ">
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <H2>
            <FormattedMessage id="review_and_send_email">
              review_and_send_email
            </FormattedMessage>
          </H2>

          {dayDiff < 1 ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  border: "1.5px solid #FD372A",
                  borderRadius: "5px",
                  backgroundColor: "#FAF0EF",
                }}
              >
                <ParagraphMedium
                  style={{ color: "#FD372A", padding: "5px 10px" }}
                >
                  Future Action - Scheduled for <span>{dayDifference}</span>
                </ParagraphMedium>
              </div>
            </>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton type="button" onClick={() => hideWorkflowArea()}>
            <FullScreen />
          </IconButton>
        </div>
      </div>

      <div className="cp-action-select" style={{ paddingRight: "0px" }}>
        <Controller
          name="recipients"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <>
              <SelectBox
                {...field}
                name={field.name}
                noResultMessage={`${"Email ID is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
                multi
                backspaceRemoves={false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "to",
                })}
                placeholder={intl.formatMessage({
                  id: "to",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={
                  contacts && Array.isArray(contacts)
                    ? contacts?.reduce((prev, curr) => {
                        let { phone, landline, email, address, ...rest } = curr;
                        let nestedContacts =
                          email &&
                          email
                            ?.map(({ status, email_status, ...nRest }) => {
                              let designation =
                                referenceData && referenceData["recipient_type"]
                                  ? referenceData["recipient_type"].find(
                                      (rf) => rf?.id === curr?.designation
                                    )
                                  : "";

                              return {
                                label: `${curr?.first_name} - ${designation?.label} - ${nRest?.value}`,
                                id: nRest?._id,
                                recipient_id: curr?._id,
                                email_status,
                                status,
                              };
                            })
                            .filter(
                              (e) =>
                                (e.status === "VALID" || e.status === "LEAD") &&
                                e.email_status === "VALID"
                            );

                        return [...prev, ...nestedContacts];
                      }, [])
                    : []
                }
              />
            </>
          )}
        />
      </div>
      <div className="cp-action-select" style={{ paddingRight: "0px" }}>
        <Controller
          name="cc"
          control={control}
          render={({ field }) => (
            <>
              <TextBoxTag
                // disabled={disableContent}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "cc1",
                })}
                placeholder={intl.formatMessage({
                  id: "cc",
                })}
                value={field.value}
                validation={true}
                // caption={settingContactDetails}
                caption={`${`${`Business Email `}${smsGateWayDatas}${" will be kept in CC. "}${`You can review the Business Email in Organization Settings - Contact Details`}`}`}
                validationFunc={(value) => {
                  let rg = new RegExp(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                  );
                  return rg.test(value);
                }}
                setError={() =>
                  setError(field.name, {
                    type: String,
                    message: "Invalid Email ID",
                  })
                }
                clearErrors={() => {
                  clearErrors(field.name, {
                    message: "",
                  });
                }}
              />
            </>
          )}
        />
      </div>
      <div className="cp-action-select" style={{ paddingRight: "0px" }}>
        <Controller
          defaultValues={[]}
          name="bcc"
          control={control}
          render={({ field }) => (
            <>
              <TextBoxTag
                // disabled={disableContent}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "bcc1",
                })}
                placeholder={intl.formatMessage({
                  id: "bcc",
                })}
                value={field.value}
                validation={true}
                validationFunc={(value) => {
                  let rg = new RegExp(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                  );
                  return rg.test(value);
                }}
                setError={() =>
                  setError(field.name, {
                    type: String,
                    message: "Email is Not Valid",
                  })
                }
                clearErrors={() => {
                  clearErrors(field.name, {
                    message: "",
                  });
                }}
              />
            </>
          )}
        />
      </div>
      <div className="cp-action-select" style={{ paddingRight: "0px" }}>
        <Controller
          name="subject"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "subject",
              })}
              placeholder={intl.formatMessage({
                id: "subject",
              })}
              value={field.value}
            />
          )}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className="cp-quill cp-quill--action-view">
            <Controller
              name="content"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => {
                return (
                  <ReactQuill
                    theme="snow"
                    value={field.value}
                    onChange={(content) => {
                      field.onChange(content);
                    }}
                    placeholder={intl.formatMessage({
                      id: "content",
                    })}
                    formats={[
                      "header",
                      "font",
                      "background",
                      "color",
                      "code",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "script",
                      "align",
                      "direction",
                      "link",
                      "image",
                      "code-block",
                      "formula",
                      "audio",
                      "table",
                    ]}
                    style={{ height: "fit-content" }}
                  />
                );
              }}
            />
          </div>
          {emailAttached?.map((attachment, index) => {
            let { tempId } = attachment;
            return (
              <EmailAttachment
                key={tempId}
                {...attachment}
                id={getParams}
                currentOrganization={currentOrganization}
                entity={entity}
                removeAttchFile={removeAttchFile}
                updatingDeskUpload={updatingDeskUpload}
                recipientId={recipients && recipients[0] && recipients[0].id}
                // updatingDeskUpload={updateEmailAttachmentFromDesktop}
              />
            );
          })}
        </div>
      </div>
      <div
        className="cp-action-select"
        style={{ paddingRight: "0px", paddingTop: "20px" }}
      >
        <Controller
          defaultValues={""}
          name="comment"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "user_comments",
              })}
              placeholder={intl.formatMessage({
                id: "user_comments",
              })}
              value={field.value}
            />
          )}
        />
      </div>
    </div>
  );
};

export default EmailForm;
