import React from "react";

const View = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0003 5.49935C14.4745 5.49935 17.5728 7.45185 19.0853 10.541C17.5728 13.6302 14.4745 15.5827 11.0003 15.5827C7.52616 15.5827 4.42783 13.6302 2.91533 10.541C4.42783 7.45185 7.52616 5.49935 11.0003 5.49935ZM11.0003 3.66602C6.41699 3.66602 2.50283 6.51685 0.916992 10.541C2.50283 14.5652 6.41699 17.416 11.0003 17.416C15.5837 17.416 19.4978 14.5652 21.0837 10.541C19.4978 6.51685 15.5837 3.66602 11.0003 3.66602ZM11.0003 8.24935C12.2653 8.24935 13.292 9.27602 13.292 10.541C13.292 11.806 12.2653 12.8327 11.0003 12.8327C9.73533 12.8327 8.70866 11.806 8.70866 10.541C8.70866 9.27602 9.73533 8.24935 11.0003 8.24935ZM11.0003 6.41602C8.72699 6.41602 6.87533 8.26768 6.87533 10.541C6.87533 12.8143 8.72699 14.666 11.0003 14.666C13.2737 14.666 15.1253 12.8143 15.1253 10.541C15.1253 8.26768 13.2737 6.41602 11.0003 6.41602Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default View;
