export default function CSVUpload({ size = 22, color = "#F8F8FB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5462 3.63562H11.2048C11.0788 3.63562 10.9512 3.60616 10.8391 3.54971L8.25924 2.26019C7.91968 2.09 7.54002 2 7.15955 2H5.0911C2.83527 1.99918 1 3.83445 1 6.09028V15.9089C1 18.1647 2.83527 20 5.0911 20H7.54575C7.99741 20 8.36397 19.6343 8.36397 19.1818C8.36397 18.7293 7.99741 18.3636 7.54575 18.3636H5.0911C3.73776 18.3636 2.63644 17.2622 2.63644 15.9089V8.54493H19.0008V15.9089C19.0008 17.2622 17.8995 18.3636 16.5462 18.3636H14.0915C13.6398 18.3636 13.2733 18.7293 13.2733 19.1818C13.2733 19.6343 13.6398 20 14.0915 20H16.5462C18.802 20 20.6373 18.1647 20.6373 15.9089V7.72671C20.6373 5.47089 18.802 3.63562 16.5462 3.63562ZM2.63644 6.09028C2.63644 4.73694 3.73776 3.63562 5.0911 3.63562H7.15955C7.28556 3.63562 7.41156 3.66508 7.5253 3.72235L10.1076 5.01268C10.4472 5.18205 10.826 5.27124 11.2048 5.27124H16.5462C17.6115 5.27124 18.5115 5.95773 18.8503 6.90768H2.63644V6.09028ZM12.6948 14.1874L11.6368 13.1294V19.1818C11.6368 19.6343 11.2703 20 10.8186 20C10.367 20 10.0004 19.6343 10.0004 19.1818V13.1294L8.94245 14.1874C8.7829 14.3469 8.57344 14.4271 8.36397 14.4271C8.15451 14.4271 7.94504 14.3469 7.78549 14.1874C7.46557 13.8674 7.46557 13.3503 7.78549 13.0304L9.10528 11.7106C10.02 10.795 11.6164 10.795 12.532 11.7106L13.8518 13.0304C14.1717 13.3503 14.1717 13.8674 13.8518 14.1874C13.5318 14.5073 13.0147 14.5073 12.6948 14.1874Z"
        fill={color}
      />
    </svg>
  );
}
