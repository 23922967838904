import React, { useContext, useState, useEffect } from "react";
import { SIZE, SelectBox } from "../../../../components/SelectBox";
import { KIND, TextButton } from "../../../../components/TextButton";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { customerInvoiceOverview } from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Controller, useForm } from "react-hook-form";
import { TextArea } from "../../../../components/TextArea";
import {
  createDocument,
  getSubCategory,
  updateDocument,
} from "../../../../services/documentSystem";
import { DocumentType } from "../../../../components/FileUpload/FileUpload";
import { LabelSmall } from "baseui/typography";
import Cancel from "../../../../assets/img/svg/Template/Cancel";
import { useIntl } from "react-intl";
import DocumentUploadPoper from "./DocumentUploadPoper";
import setSelectValues from "../../../../utils/setSelectValues";
import { useDispatch, useSelector } from "react-redux";
import { EDIT, LEGAL_ACTION_INFO_, NEW } from "../../../../constants";
import queryClient from "../../../../providers/queryClient";
import { ADD_LIST_VALUES } from "../../../../providers/RBACProvider/permissionList";
import { docInitial } from "../../../../redux/customerOverview/DMSystem/action";
import ActionReminders from "../../../../components_v2/ActionReminders";
import _ from "lodash";
const DocumentUploadPage = ({
  setOpenDoc,
  refetch,
  result,
  isSectionActive,
}) => {
  let { currentOrganization, referenceData, space, resourceId } =
    useContext(MaxyfiContext);
  const [checked, setChecked] = React.useState(false);
  const { customerId } = useParams();

  let { drawerType, documentdetails } = useSelector(
    (e) => e.customerDocumentReducer
  );

  let dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_share_portal:
        _.get(documentdetails, "is_share_portal", "") !== undefined
          ? _.get(documentdetails, "is_share_portal", "")
          : false,
      remarks:
        _.get(documentdetails, "remarks", "") !== undefined
          ? _.get(documentdetails, "remarks", "")
          : "",
    },
  });

  useEffect(() => {
    if (result && result[isSectionActive] && result[isSectionActive]?.pat) {
      setValue("lty", [{ id: result[isSectionActive]?.pat }]);
    }
  }, [result, isSectionActive]);

  useEffect(() => {
    if (drawerType === EDIT && documentdetails) {
      setValue("category", setSelectValues(documentdetails.category));
      setValue(
        "invoice_number",
        setSelectValues(documentdetails.invoice_number)
      );
      setValue("sub_category", setSelectValues(documentdetails.sub_category));
      setValue("comment", _.get(documentdetails, "remarks", ""));
      setValue("is_share_portal", documentdetails.is_share_portal);
      setValue("lty", [{ id: documentdetails.lty }]);
      setChecked(documentdetails.is_share_portal);
    }
  }, [documentdetails]);

  let categoryField = watch(["category"]);

  const createDocumentMutation = useMutation(
    (data) =>
      createDocument({
        data,
        organization: currentOrganization,
        customerId: customerId,
        entity: "customer",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          // toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
        reset();
        setOpenDoc(false);
        dispatch(docInitial());
        queryClient.refetchQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
      },
    }
  );

  const updateDocumentMutation = useMutation(
    (data) =>
      updateDocument({
        data,
        organization: currentOrganization,
        customerId: customerId,
        documentId: documentdetails._id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        refetch();
        setOpenDoc(false);
        dispatch(docInitial());
        // dispatch(typeToInitial());
        // subCategory.refetch();
      },
    }
  );

  const [files, setFiles] = useState([]);
  let intl = useIntl();
  let splitType = files && files[0] && files[0].type.split("/");
  const subCategory = useQuery(
    [`sub-category-${resourceId}`],
    async () => {
      return await getSubCategory({
        organization: currentOrganization,
        // customerId: resourceId,
      });
    },
    {
      refetchOnMount: true,
      // refetchOnWindowFocus: true,
    }
  );
  let subCategoryData =
    subCategory.data && subCategory.data.data && subCategory.data.data.docs;

  const { data, isFetched, isError } = useQuery(
    [
      `CUSTOMER_INVOICE_OVERVIEW-${customerId}-invoices`,
      {
        pageIndex: 1,
        pageSize: 0,
        filters: [],
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      filters = {
        ...filters,
      };

      return await customerInvoiceOverview({
        pageIndex,
        sortBy,
        pageSize,
        filters,
        organization: currentOrganization,
        customer_id: customerId,
        // customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const onSubmit = async (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let { invoice_number, category, sub_category, comment, lty } = data;

    let formData = new FormData();
    let value;
    if (drawerType === EDIT) {
      if (
        categoryField &&
        categoryField[0] &&
        categoryField[0][0] &&
        categoryField[0][0].id === "INVOICE"
      ) {
        value = {
          category: category[0].id,
          sub_category: sub_category[0].id,
          invoice_number: invoice_number[0].id,
          remarks: comment,
          is_share_portal: checked,
          mention_users: uniqueMentionedUsers,
          lty: lty[0].id,
        };
      } else {
        value = {
          category: category[0].id,
          sub_category: sub_category[0].id,
          remarks: comment,
          is_share_portal: checked,
          mention_users: uniqueMentionedUsers,
          lty: lty[0].id,
        };
      }
    }
    if (
      categoryField &&
      categoryField[0] &&
      categoryField[0][0] &&
      categoryField[0][0].id === "INVOICE"
    ) {
      if (files && files[0] && files[0].id) {
        formData.append(
          "template_attachment",
          files && files[0] && files[0].id
        );
      } else {
        formData.append("file", files && files[0]);
      }

      formData.append("category", category[0].id);
      formData.append("invoice_number", invoice_number[0].id);
      formData.append("sub_category", sub_category[0].id);
      formData.append("remarks", comment);
      formData.append("lty", lty[0].id);
      formData.append("is_share_portal", checked);
      uniqueMentionedUsers.forEach((value) => {
        formData.append("mention_users[]", value);
      });
    } else {
      if (files && files[0] && files[0].id) {
        formData.append(
          "template_attachment",
          files && files[0] && files[0].id
        );
      } else {
        formData.append("file", files && files[0]);
      }
      formData.append("category", category[0].id);
      formData.append("sub_category", sub_category[0].id);
      formData.append("remarks", comment);
      formData.append("lty", lty[0].id);
      formData.append("is_share_portal", checked);
      uniqueMentionedUsers.forEach((value) => {
        formData.append("mention_users[]", value);
      });
    }
    if (drawerType === EDIT) {
      await updateDocumentMutation.mutateAsync(value);
    } else {
      await createDocumentMutation.mutateAsync(formData);
    }
  };

  let invoiceData =
    data &&
    data.data &&
    data.data.docs.map((e) => {
      return {
        label: e.invoice_number,
        id: e.invoice_number,
      };
    });

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      <div className="document-legal-upload-container">
        {" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="document-legal-upload-sub-container">
            <DocumentUploadPoper
              setFiles={setFiles}
              files={files}
              backgroundColour={files.length > 0 ? "#EEEEEE" : "#FBFCFF"}
              iconColor={files.length > 0 ? "#CDCED9" : "#516BEB"}
              iconBg={files.length > 0 ? "#ADADAD" : "#E5EBFE"}
              currentOrganization={currentOrganization}
            />

            {/* <FileUpload setFiles={setFiles} files={files} /> */}
            {files && files.length > 0 && (
              <div className="downloaded-invoices-doc">
                <DocumentType
                  type={
                    files && files[0] && files[0].id
                      ? splitType.toString()
                      : files && files.length > 0
                      ? splitType[1]
                      : ""
                  }
                />
                <LabelSmall
                  $style={{
                    color: "#516beb",
                    maxWidth: "160px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {files && files[0] && files[0].path}
                </LabelSmall>

                <div
                  style={{ flex: 1, textAlign: "end" }}
                  className="file_upload_close_btn"
                  onClick={() => {
                    setFiles([]);
                  }}
                >
                  <Cancel />
                </div>
              </div>
            )}

            <div className="category-section-download">
              <div style={{ width: "48%" }}>
                <Controller
                  control={control}
                  name="lty"
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      //   creatable={creatablePermission ? true : false}
                      error={errors[field.name] && errors[field.name].message}
                      label={"Select"}
                      placeholder={"Select"}
                      size={SIZE.mini}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["legal_action_fields_list"]?.sort(
                        (a, b) => a.label.localeCompare(b.label)
                      )}
                    />
                  )}
                />
              </div>

              <div style={{ width: "48%" }}>
                <Controller
                  control={control}
                  name="category"
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "category",
                      })}
                      placeholder={intl.formatMessage({
                        id: "category",
                      })}
                      size={SIZE.mini}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["document_category"]}
                    />
                  )}
                />
              </div>
              <div style={{ width: "48%" }}>
                <Controller
                  control={control}
                  name="sub_category"
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      creatable={creatablePermission ? true : false}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "sub_category",
                      })}
                      placeholder={intl.formatMessage({
                        id: "sub_category",
                      })}
                      size={SIZE.mini}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={
                        subCategoryData &&
                        subCategoryData.map((i) => {
                          return {
                            label: i.label,
                            id: i.id,
                          };
                        })
                      }
                    />
                  )}
                />
              </div>
            </div>

            {categoryField &&
              categoryField[0] &&
              categoryField[0][0] &&
              categoryField[0][0].id == "INVOICE" && (
                <Controller
                  control={control}
                  name="invoice_number"
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "invoice",
                      })}
                      placeholder={intl.formatMessage({
                        id: "invoice",
                      })}
                      size={SIZE.mini}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={invoiceData}
                    />
                  )}
                />
              )}

            <div>
              <Controller
                control={control}
                name="is_share_portal"
                render={({ field }) => (
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.right}
                    overrides={{
                      Checkmark: {
                        style: ({ $theme, $checked }) => ({
                          border: "0.1px solid #CDCED9",
                          borderRadius: "5px",
                          backgroundColor: $checked ? "#516BEB" : "#FFFFFF",
                        }),
                        Label: {
                          style: ({ $theme }) => ({
                            paddingLeft: "3px",
                            fontWeight: 400,
                          }),
                        },
                      },
                    }}
                  >
                    Share via consumer portal
                  </Checkbox>
                )}
              />
            </div>

            <ActionReminders
              watch={watch}
              control={control}
              errors={errors}
              setValue={setValue}
              is_calendar={false}
              is_incrementor={false}
              is_hold={false}
              is_followup={false}
              is_confirmation={false}
              is_valid_from_contact={false}
            />
            <div className="debt-legal-invoices_buttons">
              <TextButton
                size="mini"
                kind={KIND.tertiary}
                onClick={() => {
                  setOpenDoc(false);
                  reset();
                  dispatch(docInitial());
                }}
              >
                Cancel
              </TextButton>
              <TextButton
                size="mini"
                kind={KIND.primary}
                loading={isSubmitting}
              >
                Upload
              </TextButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DocumentUploadPage;
