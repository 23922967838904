function tableStateDraftMapper({
  tableColumns = [],
  isExpanded = false,
  isSelection = false,
  isExpandedHeader = false,
}) {
  tableColumns = tableColumns.slice(
    isSelection ? 1 : 0,
    tableColumns.length - (isExpanded || isExpandedHeader ? 1 : 0)
  );

  let draftColumns = tableColumns.map((col, index) => {
    let columnData = {
      col_id: col.id,
      is_hidden: !col.isVisible,
      order: index,
      width:
        typeof col.width === "number"
          ? Number(col.width).toFixed(0)
          : col.width,
    };

    if (col.filterValue) {
      columnData.filter = { value: col.filterValue };
    }

    if (col.isSorted) {
      columnData.sort = col.isSortedDesc ? `-${col.id}` : col.id;
    }

    return columnData;
  });

  return draftColumns;
}

export default tableStateDraftMapper;
