import React, { useContext } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { assignCredit, assignMulCusStatus } from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { TextArea } from "../TextArea";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import moment from "moment";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";

const CreditReportModel = ({ handleModal, queryFilter }) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    users,
    referenceData,
    restrictions,
    space,
    refetch,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;
  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );

  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      // is_all_customer: false,
    },
  });

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  let { customer_status } = watch();

  const assignCreditBureaus = useMutation(
    (relMang) =>
      assignCredit({
        ...relMang,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        handleModal();
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      crdt_bur: getSelectValues(data && data.crdt_bur, true),
    };
    await assignCreditBureaus.mutateAsync(values);
  };

  const intl = useIntl();
  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);
  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge $style={{ marginBottom: "20px" }}>
          Select the Credit Bureau
        </ParagraphLarge>
        <div style={{ width: "100%" }}>
          <Controller
            control={control}
            name="crdt_bur"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                creatable={creatablePermission ? true : false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "select_bureaus",
                })}
                placeholder={intl.formatMessage({
                  id: "select_bureaus",
                })}
                multi
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["credit_reporting_bureaus"].sort(
                  (a, b) => a?.id.localeCompare(b?.id)
                )}
              />
            )}
          />
        </div>
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Generate
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreditReportModel;
