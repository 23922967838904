import React, { useContext } from "react";
import { DATE, DEFINED, REFERENCE } from "../../constants";
import _ from "lodash";
import { CardRow } from "./CustomerOverview";
import moment from "moment";
import useFormat from "../../hooks/useFormat";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

const HeaderActionConfig = ({
  type,
  customer,
  lastActionDaysLeftMessage,
  ...rest
}) => {
  const format = useFormat();
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  switch (type) {
    case DATE:
      return (
        <CardRow
          label={rest?.lb}
          value={
            _.get(customer, `data.${rest?.acc}`, "")
              ? moment
                  .utc(_.get(customer, `data.${rest?.acc}`, ""))
                  ?.tz(currentDefaultFormatDetails?.time_zone)
                  ?.format(currentDefaultFormatDetails?.date_format)
              : ""
          }
        />
      );
    case REFERENCE:
      return (
        <CardRow
          label={rest?.lb}
          value={format.rd({
            name: rest?.rd,
            id: _.get(customer, `data.${rest?.acc}`, ""),
          })}
        />
      );
    case DEFINED:
      return (
        <CardRow
          label={`${rest?.lb} ${
            _.get(customer, `data.${rest?.acc}`)
              ? lastActionDaysLeftMessage
              : ""
          }`}
          value={_.get(customer, `data.${rest?.acc}`, "")}
        />
      );
    default:
      return (
        <CardRow
          label={rest?.lb}
          value={_.get(customer, `data.${rest?.acc}`)}
        />
      );
  }
};

export default HeaderActionConfig;
