import React from "react";

const CallBackLater = ({ height = 22, width = 22 }) => {
  return (
    // <svg

    // >
    //   <path
    //     d="M19 11C19 13.1369 18.1695 15.1458 16.6614 16.6569C15.1534 18.1678 13.1483 19 11.0156 19C9.37536 19 7.79935 18.5053 6.4579 17.5694C5.56421 16.9458 4.81614 16.1575 4.24756 15.2481V16.9375H3V13.1875H6.74269V14.4375H5.21374C6.41115 16.4796 8.58746 17.75 11.0156 17.75C14.7303 17.75 17.7524 14.722 17.7524 11H19ZM10.9532 4.25C13.3814 4.25 15.5577 5.52044 16.7551 7.5625H15.2573V8.8125H18.9688V5.0625H17.7212V6.75191C17.1527 5.84247 16.4046 5.05412 15.5109 4.43062C14.1695 3.49472 12.5935 3 10.9532 3C8.82172 3 6.82175 3.83263 5.32168 5.34444C3.82455 6.85338 3 8.86188 3 11H4.24756C4.24756 7.27803 7.25569 4.25 10.9532 4.25ZM8.59389 7.40625L7.78743 8.20756C7.42695 8.56869 7.38883 9.07009 7.55339 9.57856C7.83821 10.4588 8.13765 11.1499 9.50941 12.5241C10.8811 13.8982 11.6079 14.1207 12.4619 14.4734C12.6584 14.5546 12.8441 14.5937 13.0182 14.5937C13.3275 14.5937 13.5998 14.47 13.8305 14.2389L14.6335 13.4312L12.5704 11.361L11.5065 12.4032C11.2245 12.1965 10.905 11.9194 10.5088 11.5226C10.1081 11.1211 9.80268 10.7802 9.57204 10.4761L10.6048 9.43278L8.59389 7.40625Z"
    //     fill="#516BEB"
    //   />
    // </svg>

    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      // {...props}
      //    xmlns="http://www.w3.org/2000/svg"
      // version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      // 	 viewBox="0 0 22 22" style="enable-background:new 0 0 22 22;" xml:space="preserve"
    >
      <g>
        <path
          // style={{ fill: "#ADADAD" }}
          fill="#516BEB"
          d="M19,14.6l-2.8-0.3c-0.7-0.1-1.3,0.2-1.8,0.6l-2,2C9.2,15.4,6.6,12.8,5,9.7l2.1-2.1c0.5-0.5,0.7-1.1,0.6-1.8
		L7.4,3C7.2,1.8,6.3,1,5.2,1H3.2C2,1,0.9,2,1,3.3C1.6,12.8,9.2,20.4,18.7,21c1.3,0.1,2.3-1,2.3-2.2v-1.9C21,15.7,20.2,14.8,19,14.6
		L19,14.6z"
        />
        <g>
          <path
            style={{
              fill: "#ADADAD",
              stroke: "#ADADAD",
              strokeWidth: "0.25",
              strokeMiterlimit: "10",
            }}
            d="M17.9,7c0,1-0.4,2-1.1,2.8C16,10.5,15,10.9,14,10.9c-0.8,0-1.6-0.2-2.2-0.7c-0.4-0.3-0.8-0.7-1.1-1.1v0.8
			h-0.6V8.1h1.8v0.6h-0.7c0.6,1,1.6,1.6,2.8,1.6c1.8,0,3.3-1.5,3.3-3.3H17.9z"
          />
          <path
            // class="st1"

            style={{
              fill: "#ADADAD",
              stroke: "#ADADAD",
              strokeWidth: "0.25",
              strokeMiterlimit: "10",
            }}
            d="M13.9,3.7c1.2,0,2.3,0.6,2.8,1.6h-0.7v0.6h1.8V4.1h-0.6v0.8c-0.3-0.4-0.6-0.8-1.1-1.1
			c-0.7-0.5-1.4-0.7-2.2-0.7c-1,0-2,0.4-2.8,1.1C10.5,5,10.1,6,10.1,7h0.6C10.7,5.2,12.1,3.7,13.9,3.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CallBackLater;
