export const SET_NOTIFICATION_TAB = "SET_NOTIFICATION_TAB";

export const SET_NOTIFICATION_INFO_UNREAD_ONLY =
  "SET_NOTIFICATION_INFO_UNREAD_ONLY";
export const SET_NOTIFICATION_INFO_TYPE = "SET_NOTIFICATION_INFO_TYPE";

export const SET_NOTIFICATION_CALENDAR_INCOMPLETE_ONLY =
  "SET_NOTIFICATION_CALENDAR_INCOMPLETE_ONLY";
export const SET_NOTIFICATION_CALENDAR_DATE = "SET_NOTIFICATION_CALENDAR_DATE";

export const setNotificationTab = (payload) => {
  return {
    type: SET_NOTIFICATION_TAB,
    payload,
  };
};

export const setNotificationInfoUnreadOnly = (payload) => {
  return {
    type: SET_NOTIFICATION_INFO_UNREAD_ONLY,
    payload,
  };
};

export const setNotificationInfoType = (payload) => {
  return {
    type: SET_NOTIFICATION_INFO_TYPE,
    payload,
  };
};

export const setNotificationCalendarInCompleteOnly = (payload) => {
  return {
    type: SET_NOTIFICATION_CALENDAR_INCOMPLETE_ONLY,
    payload,
  };
};

export const setNotificationCalendarDate = (payload) => {
  return {
    type: SET_NOTIFICATION_CALENDAR_DATE,
    payload,
  };
};
