import React from "react";
import { useIntl } from "react-intl";

import Typography from "./Typography";

const TabelHead = ({ literal_id }) => {
  const intl = useIntl();

  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: literal_id })}
    </Typography>
  );
};

export default TabelHead;
