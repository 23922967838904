import {
  LETTER_STATUS_DRAWER,
  LETTER_STATUS_DOCUMENT_FROM_DESKTOP,
  LETTER_STATUS_UPDATE_DOCUMENT_FROM_DESKTOP,
  LETTER_STATUS_UNSELECTED_DOCUMENT,
  LETTER_AUDIT_DRAWER_STATUS,
  ADD_LETTER_STATUS_ATTACHMENT_FROM_DESKTOP,
  LETTER_ACTION_EDIT,
  REFETCH_LETTER_STATUS,
  LETTER_STATUS_DATA_QUICK_ACTION,
} from "./action";

const initialState = {
  letterDrawerStatus: false,
  selectedDocumentLetterStatusData: [],
  letterAuditStatus: false,
  letterEditFormData: {},
  refetchLetterStatusActions: 0,
};

const letterStatusReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFETCH_LETTER_STATUS: {
      const { refetchLetterStatusActions } = state;
      return {
        ...state,
        refetchLetterStatusActions: refetchLetterStatusActions + 1,
      };
    }

    case ADD_LETTER_STATUS_ATTACHMENT_FROM_DESKTOP: {
      // let fileBaseStructure = {
      //   tempId: null,
      //   name: "",
      //   type: "",
      //   isNewDoc: true,
      //   scanStatus: "FILE_SCAN_YTS",
      //   fileRef: null,
      // };

      // const currentFiles = state.selectedDocumentLetterStatusData;
      //
      // let currentFileLength = currentFiles.length;

      // let files = payload.map((file) => {
      //   currentFileLength++;

      //   return {
      //     ...fileBaseStructure,
      //     ...file,
      //     tempId: `ID-${currentFileLength + 1}`,
      //   };
      // });
      // let data = { ...payload };
      //

      return {
        ...state,
        selectedDocumentLetterStatusData: [...payload],
      };
    }

    case LETTER_AUDIT_DRAWER_STATUS:
      return {
        ...state,
        letterAuditStatus: payload,
      };
    case LETTER_STATUS_DRAWER:
      return {
        ...state,
        letterDrawerStatus: payload,
      };

    case LETTER_STATUS_DOCUMENT_FROM_DESKTOP: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.selectedDocumentLetterStatusData;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;

        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        selectedDocumentLetterStatusData: [...currentFiles, ...files],
      };
    }

    case LETTER_STATUS_UPDATE_DOCUMENT_FROM_DESKTOP: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.selectedDocumentLetterStatusData.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let selectedDocumentLetterStatusData =
          state.selectedDocumentLetterStatusData;
        let currentFile = selectedDocumentLetterStatusData[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        selectedDocumentLetterStatusData[fileIndex] = currentFile;

        return {
          ...state,
          selectedDocumentLetterStatusData: selectedDocumentLetterStatusData,
        };
      }

      return state;
    }

    case LETTER_STATUS_UNSELECTED_DOCUMENT: {
      // let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.selectedDocumentLetterStatusData.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        selectedDocumentLetterStatusData: filteredData,
      };
    }

    case LETTER_STATUS_DATA_QUICK_ACTION:
      let attachQuickActionData = [...state.letterAttachedData];
      attachQuickActionData.push(...payload);

      return {
        ...state,
        selectedDocumentLetterStatusData: attachQuickActionData,
      };

    case LETTER_ACTION_EDIT: {
      return {
        ...state,
        letterEditFormData: payload,
      };
    }

    default:
      return initialState;
  }
};

export default letterStatusReducer;
