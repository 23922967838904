import React from "react";

const BackArrowCustomer = () => {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6224 0.313349C10.1206 -0.10445 9.31152 -0.10445 8.80972 0.313349L0.299546 7.39888C-0.0998486 7.73141 -0.0998486 8.26858 0.299546 8.60112L8.80972 15.6866C9.31152 16.1044 10.1206 16.1044 10.6224 15.6866C11.1242 15.2689 11.1242 14.5953 10.6224 14.1775L3.20796 7.99574L10.6326 1.81402C11.1242 1.40474 11.1242 0.722622 10.6224 0.313349Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default BackArrowCustomer;
