import React from "react";

function InActiveDelete({ size = 22, fill }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="edit-bin"
          d="M5.49992 17.4167C5.49992 18.425 6.32492 19.25 7.33325 19.25H14.6666C15.6749 19.25 16.4999 18.425 16.4999 17.4167V8.25C16.4999 7.24167 15.6749 6.41667 14.6666 6.41667H7.33325C6.32492 6.41667 5.49992 7.24167 5.49992 8.25V17.4167ZM8.24992 8.25H13.7499C14.2541 8.25 14.6666 8.6625 14.6666 9.16667V16.5C14.6666 17.0042 14.2541 17.4167 13.7499 17.4167H8.24992C7.74575 17.4167 7.33325 17.0042 7.33325 16.5V9.16667C7.33325 8.6625 7.74575 8.25 8.24992 8.25ZM14.2083 3.66667L13.5574 3.01583C13.3924 2.85083 13.1541 2.75 12.9158 2.75H9.08409C8.84575 2.75 8.60742 2.85083 8.44242 3.01583L7.79159 3.66667H5.49992C4.99575 3.66667 4.58325 4.07917 4.58325 4.58333C4.58325 5.0875 4.99575 5.5 5.49992 5.5H16.4999C17.0041 5.5 17.4166 5.0875 17.4166 4.58333C17.4166 4.07917 17.0041 3.66667 16.4999 3.66667H14.2083Z"
          // fill="#CDCED9"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default InActiveDelete;
