import React from "react";

const Skipped = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.31667 9.075L6.125 5.68333C6.59167 5.35 6.59167 4.65 6.125 4.325L1.31667 0.925C0.758333 0.541667 0 0.933333 0 1.60833V8.39167C0 9.06667 0.758333 9.45833 1.31667 9.075ZM8.33333 0.833333V9.16667C8.33333 9.625 8.70833 10 9.16667 10C9.625 10 10 9.625 10 9.16667V0.833333C10 0.375 9.625 0 9.16667 0C8.70833 0 8.33333 0.375 8.33333 0.833333Z"
        fill="#969696"
      />
    </svg>
  );
};

export default Skipped;
