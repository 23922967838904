import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { SelectBox } from "../../../../../../components/SelectBox";
import { TextArea } from "../../../../../../components/TextArea";
import { SIZE } from "../../../../../../components/TextButton";
import { Controller } from "react-hook-form";
import ReturnVisit from "./ReturnVisit/ReturnVisit";
import PromiseForm from "../../PromiseForm";
import CallBack from "../../CallBack";
import Dispute from "../../Dispute";
import FieldVisitPayment from "./ReturnVisit/FieldVisitPayment";
import DateRangeSelect from "../../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { LabelLarge } from "baseui/typography";
import setSelectValues from "../../../../../../utils/setSelectValues";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import CheckBoxBaseweb from "../../../../../../components/CheckBoxBaseweb";
import { getCommunicationTemplateList } from "../../../../../../services";
import { getOrganization } from "../../../../../../services";
import {
  CALLBACKLATER,
  DISPUTE,
  FIELD_VISIT,
  MEETINGREQUEST,
  PAY,
  PLANNED,
  PROMISE,
  UNSUCCESSFUL,
  WORKFLOW,
} from "../../../../../../constants";
import PromiseToPayCall from "../../../../../../assets/img/svg/PromiseToPayCall";
import MeetingRequest from "../../../../../../assets/img/svg/MeetingRequest";
import UnSuccessful from "../../../../../../assets/img/svg/UnSuccessful";
import CallBackLater from "../../../../../../assets/img/svg/CallBackLater";
import Planned from "../../../../../../assets/img/svg/Planned";
import AddPaymentSvg from "../../../../../../assets/img/svg/AddPaymentSvg";
import * as DisputeSvg from "../../../../../../assets/img/svg/Dispute";
import PaymentPlan from "../../PaymentPlan";
import { useSelector } from "react-redux";
import moment from "moment";

const swtchingType = [
  { type: "PROMISE_TO_PAY", label: "PTP" },
  { type: "CALL_BACK_LATER", label: "CBL" },
  { type: "RECORD_DISPUTE", label: "DIS" },
  { type: "PAYMENT", label: "Pay" },
  { type: "RETURN_VISIT", label: "RV" },
  { type: "PAYMENT_PLAN", label: "PPL" },
];

const CallButtonIcon = ({ type }) => {
  switch (type) {
    case DISPUTE:
      return <DisputeSvg />;
    case PROMISE:
      return <PromiseToPayCall />;
    case MEETINGREQUEST:
      return <MeetingRequest />;
    case UNSUCCESSFUL:
      return <UnSuccessful />;
    case CALLBACKLATER:
      return <CallBackLater />;
    case PLANNED:
      return <Planned />;
    case PAY:
      return <AddPaymentSvg />;
    default:
      return null;
  }
};
// { type, errors, control, watch }
const SwitchingType = (props) => {
  const { total_inv_amount, total_outstanding_invoice_amount } = useSelector(
    (state) => state.customerOverviewReducer.customerDetails
  );

  const { orgDtl } = useSelector((df) => df.customerOverviewReducer);

  if (props.type === "PROMISE_TO_PAY") {
    return (
      <div style={{ paddingTop: "25px" }}>
        <PromiseForm
          {...props}
          compoFrom={true}
          formAction={FIELD_VISIT}
          defaultConfiguration={
            orgDtl?.default_configuration?.field_visit_promise_to_pay
          }
        />
      </div>
    );
  }
  if (props.type === "CALL_BACK_LATER") {
    return (
      <div style={{ paddingTop: "25px" }}>
        <CallBack
          {...props}
          compoFrom={true}
          formAction={FIELD_VISIT}
          defaultConfiguration={
            orgDtl?.default_configuration?.field_visit_call_back_later
          }
        />
      </div>
    );
  }
  if (props.type === "RECORD_DISPUTE") {
    return (
      <div style={{ paddingTop: "25px" }}>
        <Dispute
          {...props}
          compoFrom={true}
          formAction={FIELD_VISIT}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      </div>
    );
  }
  if (props.type === "PAYMENT") {
    return (
      <div style={{ marginTop: "30px" }}>
        <FieldVisitPayment
          {...props}
          compoFrom={true}
          formAction={FIELD_VISIT}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      </div>
    );
  }
  if (props.type === "RETURN_VISIT") {
    return (
      <div>
        <ReturnVisit
          errors={props.errors}
          control={props.control}
          watch={props.watch}
          compoFrom={true}
          setValue={props.setValue}
          entity="FIELD_VISIT"
          formAction={FIELD_VISIT}
          defaultConfiguration={
            orgDtl?.default_configuration?.return_field_visit
          }
        />
      </div>
    );
  }
  if (props.type === "PAYMENT_PLAN") {
    return (
      <div style={{ marginTop: "30px" }}>
        <PaymentPlan
          {...props}
          compoFrom={true}
          formAction={FIELD_VISIT}
          total_outstanding_invoice_amount={total_outstanding_invoice_amount}
          disabled={props.disabled}
          setDisable={props.setDisable}
          installments={props.installments}
          setInstallments={props.setInstallments}
          defaultConfiguration={
            orgDtl?.default_configuration?.field_visit_payment_plan
          }
        />
        ;
      </div>
    );
  }
  return null;
};

const FieldVisitForm = ({
  watch,
  setValue,
  control,
  errors,
  setError,
  setCome,
  outCome,
  setFieldVisit,
  actionFrom = "",
  clearErrors,
  disabled,
  setDisable,
  installments,
  setInstallments,
  currentActioDate,
  fieldVisitStatus,
  setIsCompute,
  isCompute,
  // onSubmit,
}) => {
  let intl = useIntl();
  const [success, setSuccess] = useState(false);
  let [followUpTemplates, setFollowUpTemplates] = useState([]);
  let [confirmationTemplates, setConfirmationTemplates] = useState([]);
  const [settingData, setSettingData] = useState(null);
  const [today, setToday] = useState(false);
  const [tomorrow, setTomorrow] = useState(false);
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);

  let { visit_person, is_send_confirmation_message, is_send_action_follow_up } =
    watch();

  let [confirmationMessageType, followUpType] = watch([
    "confirmation_message_type",
    "action_follow_up_type",
  ]);

  let type = referenceData["template_type"];

  let mappinTypeValue = type.map((e) => {
    if (e.id == "email") {
      return { ...e };
    } else if (e.id === "sms") {
      return { ...e };
    } else if (e.id === "sms" && !settingData?.sms_details?.is_configured) {
      return { ...e, disabled: true };
    }
    return e;
  });

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags,
        type,
      },
      organization: currentOrganization,
      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(async () => {
    await getOrganization({ organization: currentOrganization }).then((res) => {
      setSettingData(res.data.doc || {});
    });
    //TODO: PRAVEEN FAILURE ERROR HANDLING
  }, []);

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: ["VISIT_UNSUCESSFULL"],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  useEffect(() => {
    if (followUpType && followUpType[0] && followUpType[0].id) {
      setFollowUpTemplates([]);
      getTemplates({
        tags: ["VISIT_UNSUCESSFULL"],
        type: followUpType[0].id,
        setter: setFollowUpTemplates,
      });
    }
  }, [followUpType]);

  useEffect(() => {
    if (!success) {
      setValue("visit_person", setSelectValues("customer"));
    } else {
      setValue("reason", []);
    }
  }, [success]);

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div>
            <div
              style={{
                height: "100%",
                overflow: "scroll",
                padding: "0px 35px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div>
                {/*  FIELD VISIT STATUS STARTS */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "37px",
                    }}
                  >
                    <LabelLarge style={{ fontSize: "14px", fontWeight: "500" }}>
                      Field Visit Status
                    </LabelLarge>
                    <div style={{ display: "flex", gap: "20px" }}>
                      <div
                        className={
                          fieldVisitStatus === "SUCCESSFULL"
                            ? "unsuccessful"
                            : "successful"
                        }
                        style={{
                          width: "150px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setSuccess(false);
                          setFieldVisit("SUCCESSFULL");
                          // setCome("SUCCESSFULL");
                        }}
                      >
                        Successful
                      </div>
                      <div
                        className={
                          fieldVisitStatus === "UNSUCCESSFULL"
                            ? "unsuccessful"
                            : "successful"
                        }
                        style={{
                          width: "150px",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          setSuccess(true);
                          setFieldVisit("UNSUCCESSFULL");
                          // setCome("UNSUCCESSFULL");
                          // setValue("visit_person", "");
                        }}
                      >
                        Unsuccessful
                      </div>
                    </div>
                  </div>
                  {fieldVisitStatus === "SUCCESSFULL" && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // gap: "50px",
                        }}
                      >
                        <LabelLarge
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            width: "142px",
                          }}
                        >
                          Whom you met ? <span style={{ color: "red" }}>*</span>
                        </LabelLarge>
                        <div style={{ width: "238px", marginTop: "14px" }}>
                          <Controller
                            name="visit_person"
                            control={control}
                            rules={{ required: "Required" }}
                            render={({ field }) => (
                              <SelectBox
                                size={SIZE.compact}
                                {...field}
                                name={field.name}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                creatable
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={
                                  referenceData["visit_meeting_person_list"]
                                }
                              />
                            )}
                          />
                        </div>

                        {/* AFTER SELECTING HAVE YOU MET ? STARTS*/}
                        {/* AFTER SELECTING HAVE YOU MET ? ENDS*/}
                      </div>
                    </div>
                  )}
                  {fieldVisitStatus === "UNSUCCESSFULL" && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // gap: "50px",
                        }}
                      >
                        <LabelLarge
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            width: "142px",
                            // paddingBottom: "10px",
                          }}
                        >
                          Reason <span style={{ color: "red" }}>*</span>
                        </LabelLarge>
                        <div style={{ width: "238px", marginTop: "14px" }}>
                          <Controller
                            name="reason"
                            control={control}
                            // rules={{ required: "Required" }}
                            render={({ field }) => (
                              <SelectBox
                                size={SIZE.compact}
                                {...field}
                                name={field.name}
                                creatable
                                // requiredAstric={true}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={
                                  referenceData["visit_unsuccess_reason_list"]
                                }
                              />
                            )}
                          />
                        </div>

                        {/* AFTER SELECTING HAVE YOU MET ? STARTS*/}
                        {/* AFTER SELECTING HAVE YOU MET ? ENDS*/}
                      </div>
                    </div>
                  )}
                </div>

                {/*  FIELD VISIT STATUS ENDS */}
              </div>

              {/* FOR SUCCESS SCREEN STARTS*/}

              <div>
                {fieldVisitStatus === "SUCCESSFULL" && (
                  <>
                    {visit_person && visit_person.length > 0 ? (
                      <>
                        <LabelLarge
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            paddingTop: "5px",
                          }}
                        >
                          Capture Your Outcomes
                        </LabelLarge>
                        <div
                          style={{
                            display: "flex",
                            paddingTop: "15px",
                            gap: "15px",
                          }}
                        >
                          {[
                            {
                              type: "PROMISE_TO_PAY",
                              label: "PTP",
                              fieldAccess:
                                actionFrom === WORKFLOW
                                  ? currentActioDate &&
                                    currentActioDate.is_promise_to_pay
                                  : true,
                            },
                            {
                              type: "CALL_BACK_LATER",
                              label: "CBL",
                              fieldAccess:
                                actionFrom === WORKFLOW
                                  ? currentActioDate &&
                                    currentActioDate.is_callback_later
                                  : true,
                            },
                            {
                              type: "RECORD_DISPUTE",
                              label: "DIS",
                              fieldAccess:
                                actionFrom === WORKFLOW
                                  ? currentActioDate &&
                                    currentActioDate.is_dispute
                                  : true,
                            },
                            {
                              type: "PAYMENT",
                              label: "Pay",
                              fieldAccess:
                                actionFrom === WORKFLOW
                                  ? currentActioDate &&
                                    currentActioDate.is_payment
                                  : true,
                            },
                            {
                              type: "RETURN_VISIT",
                              label: "RV",
                              fieldAccess:
                                actionFrom === WORKFLOW
                                  ? currentActioDate &&
                                    currentActioDate.is_field_visit
                                  : true,
                            },
                            {
                              type: "PAYMENT_PLAN",
                              label: "PPL",
                              fieldAccess:
                                actionFrom === WORKFLOW
                                  ? currentActioDate &&
                                    currentActioDate.is_payment_plan
                                  : true,
                            },
                          ].map((e) => {
                            return (
                              <>
                                {e.fieldAccess && (
                                  <div
                                    className={
                                      outCome !== e.type
                                        ? "outCome"
                                        : "activeOutCome"
                                    }
                                    onClick={() => {
                                      setCome(e.type);
                                    }}
                                  >
                                    {e.label}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>

                        <SwitchingType
                          actioWatch={watch([
                            "is_send_confirmation_message",
                            "is_send_action_follow_up",
                            "is_send_klq",
                            "is_send_meet_1rq",
                            "promise_type",
                            "recipients",
                          ])}
                          watch={watch}
                          control={control}
                          errors={errors}
                          intl={intl}
                          Controller={Controller}
                          type={outCome}
                          // type="PROMISE_TO_PAY"
                          setValue={setValue}
                          setError={setError}
                          clearErrors={clearErrors}
                          disabled={disabled}
                          setDisable={setDisable}
                          installments={installments}
                          setInstallments={setInstallments}
                          setIsCompute={setIsCompute}
                          isCompute={isCompute}
                          // rest={rest}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
                {fieldVisitStatus === "UNSUCCESSFULL" && (
                  <>
                    <div
                      className="cp-action-promise-amount_input"
                      style={{ gap: "42px" }}
                    >
                      <div>
                        <LabelLarge>
                          <FormattedMessage id="follow_up">
                            follow_up
                          </FormattedMessage>{" "}
                          :
                        </LabelLarge>
                      </div>

                      <div
                        onClick={() => {
                          setValue(
                            "follow_up_date",
                            moment().startOf("day").toDate()
                          );
                          setToday(true);
                          setTomorrow(false);
                        }}
                      >
                        <div
                          className={
                            today === true
                              ? "cp-action-call-unsuccessful--active"
                              : "cp-action-call-unsuccessful"
                          }
                        >
                          <div>Today</div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          setTomorrow(true);
                          setToday(false);
                          setValue(
                            "follow_up_date",
                            moment().add(1, "days").startOf("day").toDate()
                          );
                        }}
                      >
                        <div
                          className={
                            tomorrow === true
                              ? "cp-action-call-unsuccessful--active"
                              : "cp-action-call-unsuccessful"
                          }
                        >
                          <div>Tomorrow</div>
                        </div>
                      </div>

                      <div style={{ width: "156px", paddingTop: "10px" }}>
                        <Controller
                          name="follow_up_date"
                          control={control}
                          render={({ field }) => (
                            <DateRangeSelect
                              {...field}
                              // disabled={true}
                              name={field.name}
                              clearable={true}
                              size={SIZE.compact}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "select_date",
                              })}
                              placeholder={intl.formatMessage({
                                id: " ",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.date);
                              }}
                              minDate={new Date()}
                            />
                          )}
                        />
                      </div>

                      <div style={{ paddingTop: "10px", width: "120px" }}>
                        <Controller
                          name="follow_up_time"
                          control={control}
                          // rules={{ required: "Required" }}
                          render={({ field }) => (
                            <SelectBox
                              size={SIZE.compact}
                              {...field}
                              name={field.name}
                              // requiredAstric={true}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "follow_up_time",
                              })}
                              placeholder={intl.formatMessage({
                                id: "follow_up_time",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              options={referenceData["time"]}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div>
                      <Controller
                        defaultValues={false}
                        name="is_hold_workflow"
                        control={control}
                        render={({ field }) => (
                          <CheckBoxBaseweb
                            {...field}
                            // disabled={true}
                            checked={field.value}
                            labelPlacement={LABEL_PLACEMENT.right}
                          >
                            <FormattedMessage
                              // id="is_hold_workflow"
                              id="hold_workflow_till_field_visit_date"
                            >
                              hold_workflow_till_field_visit_date
                              {/* is_hold_workflow */}
                            </FormattedMessage>
                          </CheckBoxBaseweb>
                        )}
                      />
                    </div>

                    <div className="cp-action-message-confirmation">
                      <div className="cp-message-confirmation-checkbox">
                        <Controller
                          defaultValues={false}
                          name="is_send_confirmation_message"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              labelPlacement={LABEL_PLACEMENT.right}
                              // disabled={!EmailUser && !PhoneUser}
                            >
                              <FormattedMessage id="send_confirmation_message">
                                send_confirmation_message
                              </FormattedMessage>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      {is_send_confirmation_message ? (
                        <>
                          <div className="cp-message-confirmation-type">
                            <Controller
                              defaultValues={[]}
                              name="confirmation_message_type"
                              control={control}
                              // rules={{ required: "Required" }}
                              render={({ field }) => (
                                <SelectBox
                                  {...field}
                                  clearable={false}
                                  name={field.name}
                                  fullWidth
                                  size={SIZE.compact}
                                  error={
                                    errors[field.name] &&
                                    errors[field.name].message
                                  }
                                  label={intl.formatMessage({
                                    id: "comm_type",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "comm_type",
                                  })}
                                  value={field.value}
                                  onChange={(e) => {
                                    if (
                                      field?.value?.[0]?.id != e?.value?.[0]?.id
                                    ) {
                                      setValue(
                                        "confirmation_message_template_id",
                                        []
                                      );
                                    }
                                    field.onChange(e.value);
                                  }}
                                  options={mappinTypeValue}
                                />
                              )}
                            />
                          </div>
                          {/* TODO: TEMPLATE TYPE */}
                          <div className="cp-action-promise-pay-con">
                            <Controller
                              name="confirmation_message_template_id"
                              control={control}
                              rules={{ required: "Required" }}
                              render={({ field }) => (
                                <SelectBox
                                  {...field}
                                  clearable={false}
                                  name={field.name}
                                  fullWidth
                                  size={SIZE.compact}
                                  error={
                                    errors[field.name] &&
                                    errors[field.name].message
                                  }
                                  label={intl.formatMessage({
                                    id: "field_visit_confirmation",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "field_visit_confirmation",
                                  })}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                  }}
                                  options={confirmationTemplates || []}
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="cp-action-message-confirmation">
                      <div className="cp-message-confirmation-checkbox">
                        <Controller
                          defaultValues={false}
                          name="is_send_action_follow_up"
                          control={control}
                          render={({ field }) => (
                            <CheckBoxBaseweb
                              {...field}
                              checked={field.value}
                              labelPlacement={LABEL_PLACEMENT.right}
                              // disabled={!EmailUser && !PhoneUser}
                            >
                              <FormattedMessage id="send_field_visit_promise_follow_up_message">
                                {/* Send Field Visit Follow up Message */}
                                send_field_visit_promise_follow_up_message
                              </FormattedMessage>
                            </CheckBoxBaseweb>
                          )}
                        />
                      </div>
                      {is_send_action_follow_up ? (
                        <>
                          <div className="cp-message-confirmation-type">
                            <Controller
                              name="action_follow_up_type"
                              control={control}
                              rules={{ required: "Required" }}
                              render={({ field }) => (
                                <SelectBox
                                  {...field}
                                  clearable={false}
                                  name={field.name}
                                  fullWidth
                                  size={SIZE.compact}
                                  error={
                                    errors[field.name] &&
                                    errors[field.name].message
                                  }
                                  label={intl.formatMessage({
                                    id: "comm_type",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "comm_type",
                                  })}
                                  value={field.value}
                                  onChange={(e) => {
                                    if (
                                      field?.value?.[0]?.id != e?.value?.[0]?.id
                                    ) {
                                      setValue(
                                        "action_follow_up_template_id",
                                        []
                                      );
                                    }
                                    field.onChange(e.value);
                                  }}
                                  options={mappinTypeValue}
                                />
                              )}
                            />
                          </div>
                          <div className="cp-action-promise-pay-con">
                            <Controller
                              defaultValues={[]}
                              name="action_follow_up_template_id"
                              control={control}
                              rules={{ required: "Required" }}
                              render={({ field }) => (
                                <SelectBox
                                  {...field}
                                  clearable={false}
                                  name={field.name}
                                  fullWidth
                                  size={SIZE.compact}
                                  error={
                                    errors[field.name] &&
                                    errors[field.name].message
                                  }
                                  label={intl.formatMessage({
                                    id: "field_visit_follow_up_message",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "field_visit_follow_up_message",
                                  })}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                  }}
                                  options={followUpTemplates || []}
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      style={{
                        paddingTop: "20px",
                      }}
                    >
                      <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                          <TextArea
                            // disabled={viewOnly}
                            size={SIZE.default}
                            {...field}
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "comments",
                            })}
                            placeholder={intl.formatMessage({
                              id: "comments",
                            })}
                            value={field.value}
                          />
                        )}
                      />
                    </div>
                  </>
                )}
              </div>

              {/* FOR SUCCESS SCREEN ENDS*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FieldVisitForm;
