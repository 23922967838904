import {
  OPEN_SWITCH_ORGANIZATION_MODAL,
  CLOSE_SWITCH_ORGANIZATION_MODAL,
} from "./action";

const initialState = {
  isOpen: false,
};

export const switchOrganizationReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case OPEN_SWITCH_ORGANIZATION_MODAL: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case CLOSE_SWITCH_ORGANIZATION_MODAL: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
