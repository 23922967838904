import { FETCH_SELECT_VIEW_NAME } from "./action";

const initialState = {
  name: "",
};

export const fetchNestedReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SELECT_VIEW_NAME:
      return { ...initialState, name: payload };
    default:
      return { initialState };
  }
};
