import React, { useRef, useContext, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  HeadingMedium,
  HeadingXSmall,
  LabelMedium,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import {
  Chart,
  Bar,
  Doughnut,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import { LabelLarge } from "baseui/typography";
import { useIntl } from "react-intl";
import {
  setCustomerSummaryFilter,
  setDashBoardCustomActionFilter,
  setDashBoardDashboardCustomerActionStage,
} from "../../redux/customerSummary/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { object } from "yup/lib/locale";
import { Select } from "baseui/select";
import millify from "millify";
import { NumericFormat } from "react-number-format";
import { getOrganizationDashboard } from "../../services/organization";
import { useQuery } from "react-query";

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function DougnutChart() {
  // let doughData = { action_on_hold: 0, AUTO: 5, UNREACHABLE: 8 };

  let {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    referenceData,
  } = useContext(MaxyfiContext);
  const [is_width, setIs_width] = useState();

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `AGENT_DASHBOARD_${currentOrganization}_CUSTOMER_ACTION_STAGE`,
      {
        filters: {
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "CUSTOM_CUSTOMER_ACTION_STAGE",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 1800000,
    }
  );

  const [value, setValue] = React.useState([
    { label: "Consumer Count", id: "count" },
  ]);
  let doughNutData =
    value && value[0] && value[0].id === "count"
      ? (data &&
          data.data &&
          data.data.doc &&
          data.data.doc.customer_custom_stage) ||
        []
      : (data &&
          data.data &&
          data.data.doc &&
          data.data.doc.customer_custom_stage_amount) ||
        [];

  const navigate = useNavigate();
  let dispatch = useDispatch();

  let rdCustomerStage =
    referenceData && referenceData["customer_stage"]
      ? referenceData["customer_stage"]
      : [];
  let rdCustomerStatusList =
    referenceData && referenceData["customer_status_list"]
      ? referenceData["customer_status_list"]
      : [];

  let combinedRdData = [...rdCustomerStatusList];

  const CustomerStageFilterRd = referenceData["customer_stage_and_status"]
    ? referenceData["customer_stage_and_status"]
    : [];

  const intl = useIntl();

  // let secondKey = Object.keys(data?.customer_stage);
  let secondKey = Object.keys(doughNutData);

  let filteringValue = combinedRdData.filter((i) => secondKey.includes(i.id));
  const filteringLabel = [
    ...new Set(
      filteringValue.map((item) => {
        return { id: item.id, label: item.label };
      })
    ),
  ];

  let secondLabel = filteringLabel.map(
    (e) =>
      intl.formatMessage({
        id: e.label,
      }) +
      " " +
      `(${
        value && value[0] && value[0].id === "count"
          ? doughNutData[e.id]
          : millify(doughNutData[e.id])
      })`
  );

  let chartValue = filteringValue.map((e) => {
    return doughNutData[e.id];
  });

  let getLabelData = filteringLabel.map((e) => e.id);

  //

  // let labels = Object.keys(doughNutData).map(
  //   (key) =>
  //     intl.formatMessage({
  //       id: key,
  //     }) +
  //     " " +
  //     `(${
  //       value && value[0] && value[0].id === "count"
  //         ? doughNutData[key]
  //         : millify(doughNutData[key])
  //     })`
  // );

  let dataOne = {
    labels: [...secondLabel],
    // labels: ["secondLabel","HOMe","Items","sam","Vignesh","Raj","Partha","Ab","Azar","Imbran","sanjay","samvel","priya","Adhi","Asma","hinita","Naruto","Kaviya","Pavithra","Cathy","hari","harini","Farzana","kanaga","T9300 D","t94400 A","T93000 -a","T3300-B","T222-0","44","66 ghf","T-0fgs","Tgg990","T-7879"],
    datasets: [
      {
        label: "",
        // data: Object.values(chartValue),
        // data: [20,20,40,55,33,2,2,31,321,43,54,65,767,87,4,6,23,22,55,44,446,66,7,78,6],
        data: chartValue,
        backgroundColor: [
          "#26727B",
          "#48B24F",
          "#D21F75",
          "#E57438",
          "#569D79",
          "#E4B031",
          "#58595B",
          "#50AED3",
          "#3B3689",
          "#65338D",
          "#591a54",
          "#011a6d",
          "#5a1740",
          "#b42c20",
          "#810703",
          "#106146",
          "#090741",
          "#441225",
          "#8a1f5d",
          "#535a53",
          "#a52313",
          "#402107",
          "#821628",
          "#6e1f92",
          "#5e1267",
          "#305960",
          "#275642",
          "#412952",
          "#411e15",
          "#3c090d",
        ],
      },
    ],
  };

  const chartRef = useRef(null);
  const onClick = (event) => {
    let filteringSelectedCustomerActionStage =
      getLabelData[getElementAtEvent(chartRef.current, event)[0].index];

    let dataFilter = {
      combinator: "and",
      rules: [
        {
          id: "5655d1f5-f446-41b6-bce8-35e9f1ec0bd0",
          field: "customer_status",
          operator: "=",
          valueSource: "value",
          value: filteringSelectedCustomerActionStage,
        },
      ],
    };

    let data = {
      path: "CUSTOMER",
      filters: {
        ...dataFilter,
      },
      formFilters: {
        ...dataFilter,
      },
    };

    if (filteringSelectedCustomerActionStage) {
      dispatch(setDashBoardCustomActionFilter(data));
      navigate(`/customers`);
    }
  };

  let subbar = { height: dataOne.labels.length > 2 ? "330px" : "100px" };
  if (dataOne.labels.length > 7) {
    const Height = 300 + (dataOne.labels.length - 7) * 49;
    subbar = { height: `${Height}px` };
  }
  return (
    <div
      style={{
        backgroundColor: "#fff",
        flex: "1",
        boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.20)",
      }}
    >
      <div
        className="db-action__table-head"
        style={{ justifyContent: "space-between", padding: "0px 20px" }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <LabelLarge>Consumer Status</LabelLarge>
        </div>

        <div style={{ width: "160px" }}>
          <Select
            size={SIZE.compact}
            clearable={false}
            backspaceRemoves={false}
            overrides={{
              Tag: {
                props: {
                  overrides: {
                    Text: {
                      style: ({ $theme }) => ({
                        maxWidth: "500px",
                      }),
                    },
                  },
                },
              },
              Placeholder: {
                style: ({ $theme, $isFocused }) => ({
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                }),
              },

              ControlContainer: {
                style: ({ $theme, $isFocused, $error }) => ({
                  borderRadius: "5px",
                  backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                  borderRadius: "3px",
                  borderColor: "#CDCED9",
                  borderWidth: "0.5px",
                  borderStyle: "solid",
                  paddingRight: "0px",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  borderWidth: "1px",
                  borderColor: $isFocused
                    ? $error
                      ? "#FD372A"
                      : "#516BEB"
                    : $error
                    ? "#FD372A"
                    : "#CDCED9",
                }),
              },
            }}
            options={[
              { label: "Consumer Count", id: "count" },
              { label: "Outstanding Amount", id: "amount" },
            ]}
            value={value}
            placeholder="Select color"
            onChange={(params) => setValue(params.value)}
          />
        </div>
      </div>
      <div
        style={{
          flex: "1",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "75%",
          padding: "10px",
        }}
      >
        <div style={{ flex: "1" }}>
          <div
            style={{
              height: dataOne.labels.length > 2 ? "270px" : "auto",
              padding: "0px 9px",
              marginTop: "10px",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            <div style={{ height: `${subbar.height}` }}>
              <Bar
                ref={chartRef}
                onClick={onClick}
                options={{
                  indexAxis: "y",
                  responsive: true,
                  maintainAspectRatio: false,

                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false,
                      text: "Chart.js Horizontal Bar Chart",
                    },
                  },
                  layout: {
                    padding: {
                      right: 7,
                    },
                  },
                  scales: {
                    x: {
                      display: false,
                      beginAtZero: true,
                      grid: {
                        drawTick: false,
                        drawBorder: false,
                      },
                      ticks: {
                        display: false,
                      },
                      stacked: true,
                      // position: "top",
                      // afterFit: (ctx) => {
                      //
                      //   ctx.height = 260;
                      // },
                      beforeFit: (ctx) => {
                        ctx.height = 260;
                      },
                    },
                    // x2: {
                    //   position: "stick",
                    // },
                    y: {
                      stacked: true,
                      beginAtZero: true,
                      beforeFit: (cty) => {},
                      afterFit: (ctx) => {
                        setIs_width(() => ctx?.chart?.chartArea.left);
                      },
                    },
                    xAxes: [
                      {
                        stacked: true,
                        gridLines: {
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: "probability",
                        },
                        stacked: true,

                        type: "linear",
                      },
                    ],
                  },
                  // onClick: onClick,
                }}
                data={dataOne}
                id="chart"
              />
            </div>
          </div>
          <div
            style={{
              height: "80px",
              padding: "0px 9px",
              maxHeight: "300px",
            }}
          >
            <Bar
              options={{
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                    // position: "top",
                  },
                  title: {
                    display: false,
                    text: "Chart.js Horizontal Bar Chart",
                  },
                },
                scales: {
                  x: {
                    stacked: true,
                    // position: "top",
                    afterFit: (ctx) => {
                      //
                      ctx.height = 110.4;
                    },
                  },

                  y: {
                    stacked: true,
                    beginAtZero: true,
                    afterFit: (ctx) => {
                      //
                      ctx.width = is_width || 60;
                    },
                    grid: {
                      drawTick: false,
                    },
                  },
                  xAxes: [
                    {
                      stacked: true,
                      gridLines: {
                        display: false,
                        // labels:false
                      },
                    },
                  ],
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "probability",
                      },
                      stacked: true,

                      type: "linear",
                    },
                  ],
                },
                // onClick: onClick,
              }}
              data={{
                datasets: [{ data: chartValue }],
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DougnutChart;
