import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  Row,
} from "@tanstack/react-table";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  getGridStructure,
  getCheckSummery,
  checkActionFn,
  getCheckSummeryDownload,
  getReportDownload,
} from "../../services";
import { KIND, TextButton } from "../../components/TextButton";
import columnMapper_V2 from "../../utils/mapper_v2";
import { Icon, Typography } from "../../components_v2";
import TitleHead from "../../components_v2/TitleHead";
import { toast } from "react-toastify";
import _ from "lodash";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Drawer, SIZE } from "baseui/drawer";
import { ModalContext } from "../../providers/ModalProvider";
import work from "../../assets/img/workqueue.png";
import GenerateNACHA from "./GenerateNACHA";
import queryClient from "../../providers/queryClient";
import { IconButton } from "../../components/IconButton";
import { useIntl } from "react-intl";
import {
  GENERATE_NACHA_FILE,
  UPDATE_BATCH_STATUS,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";

const BatchSummary = () => {
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const ref = useRef(null);
  const intl = useIntl();

  const { handleModal } = useContext(ModalContext);

  const gridStructure = useQuery(
    [`GRID_STRUCTURE_REVIEW_PROMISE`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "batch_summary",
      });
    },
    {
      cacheTime: 86400000,
    }
  );

  const reviewPromise = useInfiniteQuery(
    // `${CUSTOMER_OVERVIEW}-TIMELINE-${getParams}`,
    [`BATCH_SUMMERY-${currentOrganization}`],
    async ({ queryKey, pageParam = 1 }) => {
      return await getCheckSummery({
        page: pageParam,
        organization: currentOrganization,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        if (allPages.length < maxPages) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: gridStructure.isSuccess,
    }
  );

  const onScroll = async (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (
      !reviewPromise.isFetchingNextPage &&
      scrollHeight - scrollTop <= clientHeight * 1.5
    ) {
      if (reviewPromise.hasNextPage)
        await reviewPromise.fetchNextPage({ cancelRefetch: false });
    }
  };

  useEffect(() => {
    const dev = ref.current;
    if (ref.current) {
      dev.addEventListener("scroll", onScroll);
    }
    return () => {
      ref?.current?.removeEventListener("scroll", onScroll);
    };
  }, [ref, ref.current, onScroll]);

  const checkActionData = useMutation(
    (data) =>
      checkActionFn({
        ...data,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`BATCH_SUMMERY-${currentOrganization}`);
      },
    }
  );

  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);

  const data = useMemo(() => {
    let allData = [];
    if (reviewPromise && reviewPromise.data) {
      reviewPromise &&
        reviewPromise.data &&
        reviewPromise.data.pages.map((e) => {
          allData.push(...(e && e.data && e.data.docs));
        });
    }
    return allData;
  }, [
    reviewPromise.isLoading,
    reviewPromise.isFetched,
    reviewPromise.isRefetching,
  ]);

  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const checkActionDataDownload = useMutation(
    (data) =>
      getReportDownload({
        ...data,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`BATCH_SUMMERY-${currentOrganization}`);
      },
    }
  );

  return (
    <div className="co-review-promise --bach_container">
      {/* SECTION HEADER */}
      <div className="co-invoice-section_header --bach_head_wraper">
        <TitleHead title="Batch Summary" icon="batch" />
        <div style={{ marginBottom: "5px" }}>
          <Icon icon="dot" size={5} color="#787878" />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <IconButton
            kind={KIND.tertiary}
            size="mini"
            startEnhancer={() => (
              <Icon icon="download_outline" color="#516beb" size="18" />
            )}
            onClick={() => {
              let columnStr = columns.map((e) => {
                return {
                  title: `${intl.formatMessage({
                    id: e.literal_id,
                  })} ${
                    e.type === "AMOUNT"
                      ? `(${currentDefaultFormatDetails.default_currency})`
                      : ""
                  }`,
                  id: e.literal_id,
                  accessor: e.id,
                  type: e.type,
                  subType: e.repSubType,
                  ...(e.rdName ? { rd_name: e.rdName } : {}),
                };
              });

              let value = {
                report_type: "CHK_BAT_DWN",
                columns: columnStr,
              };
              checkActionDataDownload.mutateAsync(value);
            }}
          >
            <Typography style={{ color: "#516beb" }}>Download</Typography>
          </IconButton>
        </div>
      </div>
      {/* SECTION HEADER */}

      <div className="co-invoice-table_wrapper">
        {/* TABLE HEADER */}
        <div className="co-rp-table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div
              key={headerGroup.id}
              className="co-review-promise-table_header-group"
            >
              {headerGroup.headers.map((header, inx) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* TABLE HEADER */}

        {/* TABLE BODY */}
        <div className="co-rp-table_body" ref={ref}>
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row) => {
            return (
              <div className="--batch_options">
                <div
                  key={row.id}
                  className="co-review-promise-inv-table_body-group "
                >
                  {row.getVisibleCells().map((cell, inx) => {
                    return (
                      <div
                        key={cell.id}
                        style={{ width: cell.column.getSize() }}
                        className="co-table_body-cell co-rp-modifi"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    );
                  })}
                </div>
                <>
                  {row.original.prc_sts === "CCL" ||
                  row.original.prc_sts === "CMP" ? (
                    <></>
                  ) : (
                    <>
                      <StatefulPopover
                        overrides={{
                          Body: {
                            style: ({ $theme }) => ({
                              borderRadius: "5px",
                              overflow: "hidden",
                              // width: "131px",
                              background: "#ffffff",
                              zIndex: "1",
                            }),
                          },
                          Inner: {
                            style: ({ $theme }) => ({
                              backgroundColor: "#ffffff",
                              padding: "10px",
                            }),
                          },
                        }}
                        content={({ close }) => (
                          <div className="batch_action">
                            <RBACWrapper
                              role={GENERATE_NACHA_FILE}
                              type="PERMISSION"
                            >
                              <Typography
                                onClick={() => {
                                  handleModal({
                                    title: "",
                                    content: "",
                                    successCallback: () => {},
                                    formContent: ({ handleModal }) => {
                                      return (
                                        <>
                                          <GenerateNACHA
                                            handleModal={handleModal}
                                            batchId={row?.original?._id}
                                          />
                                        </>
                                      );
                                    },
                                    cancelCallback: () => {},
                                    isChildren: true,
                                    image: work,
                                    buttonText: "",
                                    closeButtonText: "",
                                    isCloseAble: false,
                                  });
                                  close();
                                }}
                              >
                                Generate NACHA File
                              </Typography>
                            </RBACWrapper>

                            {row?.original?.prc_sts === "CMP" ? (
                              <></>
                            ) : (
                              <RBACWrapper
                                role={UPDATE_BATCH_STATUS}
                                type="PERMISSION"
                              >
                                <Typography
                                  onClick={() => {
                                    handleModal({
                                      title: "",
                                      content: `Batch # ${_.get(
                                        row,
                                        "original.bat_num",
                                        ""
                                      )} will be cancelled, The Check will be made available for new Batch processing`,
                                      successCallback: async () => {
                                        await checkActionData.mutateAsync({
                                          action_type: "CANCELED",
                                          batchId: row?.original?._id,
                                        });
                                        queryClient.refetchQueries(
                                          `BATCH_SUMMERY-${currentOrganization}`
                                        );
                                        // close();
                                      },
                                      formContent: () => {
                                        return <></>;
                                      },
                                      cancelCallback: () => {},
                                      isChildren: false,
                                      image: work, // assuming you have an image variable named Warning
                                      successButtonType: "error",
                                      buttonText: "Cancel",
                                      closeButtonText: "Close",
                                      isCloseAble: false,
                                    });
                                  }}
                                >
                                  Cancel
                                </Typography>
                              </RBACWrapper>
                            )}

                            {row?.original?.prc_sts === "GEN" && (
                              <RBACWrapper
                                role={UPDATE_BATCH_STATUS}
                                type="PERMISSION"
                              >
                                <Typography
                                  onClick={async () => {
                                    await checkActionData.mutateAsync({
                                      action_type: "COMPLETED",
                                      batchId: row?.original?._id,
                                    });
                                    close();
                                  }}
                                >
                                  Complete
                                </Typography>
                              </RBACWrapper>
                            )}
                          </div>
                        )}
                        triggerType={TRIGGER_TYPE.hover}
                        returnFocus
                        autoFocus
                      >
                        <div className="check_sm_option">
                          <Icon icon="triple_dot" isPressable />
                        </div>
                      </StatefulPopover>
                    </>
                  )}
                </>
              </div>
            );
          })}
          {/* TABLE ROW */}
        </div>
        {/* TABLE BODY */}
      </div>
    </div>
  );
};

const BatchSummaryDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  function open() {
    setIsOpen(!isOpen);
  }
  return (
    <>
      <TextButton kind={KIND.secondary} size="mini" onClick={() => open()}>
        <Icon icon="batch" size={18} color="#516beb" />
      </TextButton>
      <Drawer
        isOpen={isOpen}
        size={SIZE.auto}
        autoFocus
        onClose={() => open()}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "5px",
              marginRight: "5px",
              marginBottom: "15px",
              marginTop: "0px",
              padding: "15px",
              // overflowY: "hidden",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
        }}
      >
        <BatchSummary />
      </Drawer>
    </>
  );
};

export default BatchSummaryDrawer;
