import React from "react";
import { StyledSvg } from "baseui/icon";

function CaptureHold({ size = "22px" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M16.5 21.9996H10.4959C9.50586 21.9996 8.5342 21.5871 7.8467 20.8721L1.15503 13.8963L3.05253 12.2188C3.62086 11.7146 4.45503 11.6138 5.1242 11.9713L7.33336 13.1446V4.39046C7.33336 3.12546 8.36003 2.09879 9.62503 2.09879C9.78086 2.09879 9.9367 2.11712 10.0925 2.14462C10.175 0.952956 11.165 0.00878906 12.375 0.00878906C13.1634 0.00878906 13.8509 0.402956 14.2634 1.00796C14.5292 0.897956 14.8225 0.842956 15.125 0.842956C16.39 0.842956 17.4167 1.86962 17.4167 3.13462V3.39129C17.5634 3.36379 17.7192 3.34546 17.875 3.34546C19.14 3.34546 20.1667 4.37212 20.1667 5.63712V18.333C20.1667 20.3588 18.5259 21.9996 16.5 21.9996ZM3.79503 14.0063L9.1667 19.598C9.51503 19.9555 9.9917 20.1663 10.4867 20.1663H16.5C17.5084 20.1663 18.3334 19.3413 18.3334 18.333V5.63712C18.3334 5.38046 18.1317 5.17879 17.875 5.17879C17.6184 5.17879 17.4167 5.38046 17.4167 5.63712V10.9996H15.5834V3.13462C15.5834 2.87796 15.3817 2.67629 15.125 2.67629C14.8684 2.67629 14.6667 2.87796 14.6667 3.13462V10.9996H12.8334V2.30046C12.8334 2.04379 12.6317 1.84212 12.375 1.84212C12.1184 1.84212 11.9167 2.04379 11.9167 2.30046V10.9996H10.0834V4.39046C10.0834 4.13379 9.8817 3.93212 9.62503 3.93212C9.36836 3.93212 9.1667 4.14296 9.1667 4.39046V16.188L4.26253 13.5938L3.79503 14.0063Z"
        fill="#333860"
      />
    </svg>
  );
}

export default CaptureHold;
