import React from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useContext } from "react";
import { NumericFormat } from "react-number-format";
import { LabelLarge, LabelXSmall } from "baseui/typography";

function NumberFormtRB(props) {
  let { currencyGroupStyle, currencySeparator, currencyDecimalSeparator } =
    useContext(MaxyfiContext);
  return (
    <div style={{ display: "flex", gap: "3px" }}>
      {/* <div $style={{ fontWeight: "400", color: "#08083A" }}>
        {props.currecy}
      </div> */}
      <NumericFormat
        displayType="text"
        value={props?.value}
        thousandsGroupStyle={currencyGroupStyle}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={currencySeparator}
        decimalSeparator={currencyDecimalSeparator}
        renderText={(value) => value}
      />
    </div>
  );
}

export default NumberFormtRB;
