import React, { useContext, useEffect, useMemo, useState } from "react";
import { SIZE, SelectBox } from "../../../../components/SelectBox";
import { Typography } from "../../../../components_v2";
import queryClient from "../../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_, LEGAL_ACTION_INFO_ } from "../../../../constants";
import { useParams } from "react-router-dom";
import _ from "lodash";
import useFormat from "../../../../hooks/useFormat";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useQuery } from "react-query";
import { getAuditList } from "../../../../services";
const AuditAction = () => {
  const { customerId } = useParams();
  const defaultOption = { id: "all", label: "All" }; // Default option representing "All"
  const [selectAudit, setSelectedAudit] = useState([
    { id: "all", label: "All" },
  ]); // Initialize selectedAudit state
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);
  // const FilterOption =referenceData["legal_audit_filter_type"].push({id:"all",label:All})
  const legalAuditList = useQuery(
    [`${LEGAL_ACTION_INFO_}${customerId}`, selectAudit], // Include selectAudit in query key
    async () => {
      return await getAuditList({
        customerId: customerId,
        organization: currentOrganization,
        type: _.get(selectAudit, "id", "all"), // Pass the selectedAudit directly
      });
    }
  );
  const handleSelectChange = (e) => {
    const selectedOption = referenceData["legal_audit_filter_type"].find(
      (option) => option.id === e.value[0].id
    );
    setSelectedAudit(selectedOption); // Update selectedAudit state on selection change
  };

  if (legalAuditList.isLoading) {
    return <h1>.....Loading</h1>;
  }
  return (
    <div style={{ paddingTop: "25px" }}>
      <div style={{ width: "150px" }}>
        <SelectBox
          placeholder={""}
          value={selectAudit}
          onChange={(e) => {
            handleSelectChange(e); // Call handleSelectChange to update state and trigger API call
          }}
          clearable={false}
          options={referenceData["legal_audit_filter_type"]}
          size={SIZE.mini}
        />
      </div>

      <AuditSectionCard
        cardData={_.get(legalAuditList, "data.data.docs", [])}
      />
    </div>
  );
};

export default AuditAction;

const AuditSectionCard = ({ cardData }) => {
  const { customerId } = useParams();
  const { legalConfig } = useContext(MaxyfiContext);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const [defendantList, setDefendantList] = useState([]);

  const fetchInfo = () => {
    const defendantIds = _.get(legalInfo, "data.doc.def", []);

    setDefendantList(defendantIds);
  };

  useEffect(() => {
    fetchInfo();
  }, []);
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );

  const contacts = useMemo(() => {
    return _.get(customerData, "data.doc.contacts", []).map((contact) => {
      const {
        _id,
        first_name = "",
        last_name = "",
        relation,
        address = [],
      } = contact;

      const validAddress =
        address && Array.isArray(address)
          ? address.filter((e) => e.address_status === "VALID")
          : [];
      const firstAddress =
        validAddress && validAddress[0] ? validAddress[0] : null;

      const name = `${first_name ? _.capitalize(first_name) : ""}${
        last_name ? ` ${_.capitalize(last_name)}` : ""
      }`;

      return {
        name,
      };
    });
  }, [customerData?.data, defendantList]);

  const format = useFormat();
  const fieldsData = useMemo(() => {
    return cardData.map((e) => {
      const section = legalConfig.find((item) => item.pat === e.sec);
      const idefCheck = legalConfig.find((item) => item.isdef === true);
      let customFields = [];
      if (section && section?.fld && Array.isArray(section?.fld)) {
        Object.keys(_.get(e, "ada", {})).map((fl) => {
          const field = section.fld.find((fld) => _.get(fld, "pat", "") === fl);

          customFields.push({
            label:
              field === undefined && idefCheck
                ? "Defendents"
                : _.get(field, "nm", ""),
            val:
              field === undefined && idefCheck && _.get(e, "ada.defs", [])
                ? contacts.map((customer) => customer.name).join(", ")
                : _.get(field, "dtp", "") === "AMOUNT"
                ? format.currency({ amount: _.get(e, `ada.${fl}.value`, 0) })
                : _.get(field, "dtp", "") === "DATE" &&
                  _.get(e, `ada.${fl}`) !== null
                ? format.date({ value: _.get(e, `ada.${fl}`) })
                : _.get(e, `ada.${fl}`),
          });
        });
      }

      return {
        section: _.get(section, "pat")
          ? _.get(section, "na")
          : _.get(e, "sec", ""),
        audit_on: format.date({ value: _.get(e, "aat") }),
        added_debts: _.get(e, "ada.add", []),
        deleted_debts: _.get(e, "ada.rem", []),
        audit_by: format.rd({ id: _.get(e, "aby", ""), name: "users" }),
        additional_fields: customFields,
      };
    });
  }, [cardData.length]);

  return (
    <>
      {fieldsData.map((item) => (
        <div className="audit-selection-card-container">
          <div className="audit-selection_content">
            <Typography type="p" className="text-secondary" subType="regular">
              Section:
            </Typography>
            <Typography
              type="p"
              className="regular"
              style={{ color: "#333860" }}
            >
              {item.section}
            </Typography>
          </div>
          <div className="audit-selection_content">
            <Typography type="p" className="text-secondary" subType="regular">
              Audit By:
            </Typography>
            <Typography
              type="p"
              className="regular"
              style={{ color: "#333860" }}
            >
              {item.audit_by}
              {/* {format.rd({ id: _.get(item, "aby", ""), name: "users" })} */}
            </Typography>
          </div>
          <div className="audit-selection_content" subType="regular">
            <Typography type="p" className="text-secondary" subType="regular">
              Audit on:
            </Typography>
            <Typography
              type="p"
              className="regular"
              style={{ color: "#333860" }}
            >
              {item.audit_on}
              {/* {format.date({ value: _.get(item, "aat") })} */}
            </Typography>
          </div>
          {item.additional_fields.length > 0 &&
            item.additional_fields.map((field) => (
              <div className="audit-selection_content">
                <Typography
                  type="p"
                  className="text-secondary"
                  subType="regular"
                >
                  {`${field.label}:`}
                </Typography>
                <Typography
                  type="p"
                  className="regular"
                  style={{ color: "#333860" }}
                >
                  {field.val}
                </Typography>
              </div>
            ))}

          {_.get(item, "added_debts", []).map((addedItem) => (
            <div className="audit-selection_content">
              <Typography type="p" className="text-secondary" subType="regular">
                Debt Added:
              </Typography>
              <Typography
                type="p"
                className="regular"
                style={{ color: "#333860" }}
              >
                {addedItem}
              </Typography>
            </div>
          ))}
          {_.get(item, "deleted_debts", []).map((removedItem) => (
            <div className="audit-selection_content">
              <Typography type="p" className="text-secondary" subType="regular">
                Debt Removed:
              </Typography>
              <Typography
                type="p"
                className="regular"
                style={{ color: "#333860" }}
              >
                {removedItem}
              </Typography>
            </div>
          ))}
          {fieldsData.length > 1 && (
            <div style={{ border: "1px solid #EEEEEE" }}></div>
          )}
        </div>
      ))}
    </>
  );
};
