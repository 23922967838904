import React from "react";
import FaildArrowAdhoc from "../../../assets/img/svg/FaildArrowAdhoc";
import SuccessArrowAdhoc from "../../../assets/img/svg/SuccessArrowAdhoc";
import Sum from "../../../assets/img/svg/Upload/Sum";

const StatsIndicator = (props) => {
  const statsData = props.row.original.records;
  return statsData ? (
    <div style={{ textAlign: `${props.alignment}`, width: "100%" }}>
      <Sum /> T{statsData.total_records} <SuccessArrowAdhoc /> S
      {statsData.success_records} <FaildArrowAdhoc /> F
      {statsData.failure_records}
    </div>
  ) : (
    "-"
  );
};

export default StatsIndicator;
