import React, { useContext, useState, useEffect } from "react";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { TextButton, SIZE } from "../TextButton";
import AssignDataSvg from "../../assets/img/svg/AssignDataSvg";
import AssignDataDownArrow from "../../assets/img/svg/AssignDataDownArrow";
import UploadDataModal from "../UploadDataModal/UploadDataModal";
import { customerData } from "../../services/customerSummary";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { getWorkflowlist } from "../../services";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import csvDownload from "json-to-csv-export";
import _ from "lodash";
import { useNumericFormat } from "react-number-format";
import { useQuery } from "react-query";
import { WORKFLOW_MAINTENANCE } from "../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_CHARGES,
  ADD_INVOICE,
  ADD_INVOICE_ITEM,
  ADD_RECURRING_INVOICE,
} from "../../providers/RBACProvider/permissionList";

const ITEMS = [
  { label: "Add Invoice", id: "dct" },
  { label: "Recurring Invoice", id: "dwl" },
];

const AddRecurringInv = ({ getParams, keyValue, hello }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const [addSearchParams, setAddSearchParams] = useSearchParams();

  // Workflow Data Ends

  return (
    <>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomRight}
        overrides={{
          Body: {
            style: ({ $theme }) => ({
              borderRadius: "5px",
              overflow: "hidden",
              width: "131px",
              background: "#ffffff",
              zIndex: "1",
            }),
          },
          Inner: {
            style: ({ $theme }) => ({
              backgroundColor: "#ffffff",
              padding: "10px",
            }),
          },
        }}
        content={({ close }) => (
          // <StatefulMenu
          //   items={ITEMS}
          //   overrides={{
          //     List: { style: { height: "90px", width: "230px" } },
          //   }}
          // />
          <div>
            <RBACWrapper role={ADD_CHARGES} type="PERMISSION">
              <div
                style={{
                  padding: "5px 0px",
                  borderBottom: "0.5px solid #ceded9",
                  width: "100%",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAddSearchParams({
                    customer: `${getParams}`,
                    invoice: `charge-form`,
                    type: "ADD-CHARGE",
                  });
                }}
              >
                <FormattedMessage id="add_charge"></FormattedMessage>
              </div>
            </RBACWrapper>
            <RBACWrapper role={ADD_INVOICE} type="PERMISSION">
              <div
                style={{
                  padding: "5px 0px",
                  borderBottom: "0.5px solid #ceded9",
                  width: "100%",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAddSearchParams({
                    customer: `${getParams}`,
                    invoice: `invoice-form`,
                    type: "ADD-INV",
                  });
                }}
              >
                <FormattedMessage id="Invoice"></FormattedMessage>
              </div>
            </RBACWrapper>
            <RBACWrapper role={ADD_RECURRING_INVOICE} type="PERMISSION">
              <div
                style={{
                  padding: "5px 0px",
                  width: "100%",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAddSearchParams({
                    customer: `${getParams}`,
                    invoice: `recurring-form`,
                    type: "ADD-REC",
                  });
                }}
              >
                <FormattedMessage id="Recurring"></FormattedMessage>
              </div>
            </RBACWrapper>
          </div>
        )}
      >
        <div style={{ width: "130px" }}>
          <TextButton
            size={SIZE.mini}
            endEnhancer={
              <i className="mx-icon-arrow_drop_down_FILL0_wght400_GRAD0_opsz48-2 mx-icon-arrow_drop_down" />
              // <AssignDataDownArrow />
            }
            fullWidth
            style={{ fontWeight: "500", fontSize: "14px" }}
            // onClick={() => {
            //   setAddSearchParams({
            //     customer: `${getParams}`,
            //     invoice: `invoice-form`,
            //   });
            // }}
          >
            <FormattedMessage id="add_invoice">add_invoice</FormattedMessage>
          </TextButton>
        </div>
      </StatefulPopover>
    </>
  );
};

export default AddRecurringInv;
