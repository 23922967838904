import React, { useContext, useEffect, useState } from "react";
import { TextButton } from "../../../../components/TextButton";
import Dispute from "../../../../assets/img/svg/Dispute";
import Sms from "../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import Next from "../../../../assets/img/svg/CustomerOverViewIcon/Next";
import Call from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Call";
import Email from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Email";
import NextActionForward from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import LegalLetter from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/LegalLetter";
import Hold from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Hold";
import Payment from "../../../../assets/img/svg/CustomerOverViewIcon/Payment";
import ReviewPromise from "../../../../assets/img/svg/CustomerOverViewIcon/ReviewPromise";
// import Assign from "../../../../assets/img/assign-workflow"
// D:\maxyfi_bs_fe\src\assets\img\Assign-workflow.png
import Assign from "../../../../assets/img/assign-workflow.png";
import PlusOne from "../../../../assets/img/svg/Upload/Plus";
// D:\maxyfi_bs_fe\src\assets\img\svg\Upload\Plus.js
// D:\maxyfi_bs_fe\src\assets\img\svg\CustomerOverViewIcon\PlusOver.js
// D:\maxyfi_bs_fe\src\assets\img\svg\CustomerOverViewIcon\PlusOver.js
// D:\maxyfi_bs_fe\src\assets\img\svg\CreateWorkFlow\Plus.js
// D:\maxyfi_bs_fe\src\assets\img\svg\CustomerOverViewIcon\PlusContact.js
import {
  getActionData,
  workflowAddModal,
  holdActionModal,
} from "../../../../redux/actions";
import DisputeAction from "./AdhocActionPopup/Dispute";
import { isOpenModal } from "../../../../redux/actions";
import LetterAction from "./AdhocActionPopup/LetterAction";
import ManualCall from "./AdhocActionPopup/ManualCall";
import NextAction from "./AdhocActionPopup/NextAction";
import LetterStatus from "./AdhocActionPopup/LetterStatus/LetterStatus";
import {
  SMS,
  NEXT,
  DISPUTE,
  CALL,
  EMAIL,
  LETTER,
  HOLD,
  PAYMENTS,
  REVIEW,
  WHATSAPP,
} from "../../../../constants";
import { HeadingXSmall, LabelLarge } from "baseui/typography";
import { SIZE } from "baseui/input";
import { useDispatch, useSelector } from "react-redux";
import { setLogger } from "react-query";
import EmailAction from "./AdhocActionPopup/EmailAction";
import PaymentsAction from "./AdhocActionPopup/Payments";
import ReviewPromiseAction from "./AdhocActionPopup/ReviewPromiseAction";
import SmsAction from "./AdhocActionPopup/SmsAction";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import WhatsApp from "../../../../assets/img/svg/Template/WhatsApp";
import WhatsAppAction from "./AdhocActionPopup/WhatsAppAction";
import {
  documentDriveInvEmailAttachment,
  unSelectedInvoiceDocumentData,
  updateEmailInvoiceAttachmentFromDesktop,
} from "../../../../redux/invoiceOverview/DMSystemINV/action";
import {
  documentDriveQuickAction,
  unSelectedDocumentData,
  updateEmailAttachmentFromDesktop,
} from "../../../../redux/customerOverview/DMSystem/action";
import {
  removeAttachmentData,
  updateLetterAttachmentFromDesktop,
} from "../../../../redux/customerOverview/letter/action";
import { ASSIGN_WORKFLOW } from "../../../../providers/RBACProvider/permissionList";
import {
  documentDriveQuickActionInv,
  removeAttachmentQuickAction,
  updateQuickActionEmailInvoiceAttachmentFromDesktop,
} from "../../../../redux/invoiceOverview/invoiceQA/action";
import FieldVisit from "./AdhocActionPopup/FieldVisit/FieldVisit";

const SwitchSvg = ({
  type,
  smsGateWayIsConfgured,
  isDisable,
  isWorkflow,
  getParams,
  whatsAppIsConfigured,
}) => {
  switch (type) {
    case WHATSAPP:
      return (
        <div className={!whatsAppIsConfigured && "disable-circle"}>
          <WhatsApp />
        </div>
      );
    case DISPUTE:
      return (
        <div>
          <Dispute />
        </div>
      );
    case SMS:
      return (
        <div
          className={
            smsGateWayIsConfgured === false
              ? // || isDisable
                "disable-circle"
              : ""
          }
        >
          <Sms />
        </div>
      );
    case NEXT:
      return (
        <div
        //  className={isDisable || isWorkflow ? "disable-circle" : ""}
        >
          <NextActionForward />
        </div>
      );
    case CALL:
      return (
        <div
        // className={isDisable ? "disable-circle" : ""}
        >
          <Call />
        </div>
      );
    case EMAIL:
      return (
        <div
        // className={isDisable ? "disable-circle" : ""}
        >
          <Email />
        </div>
      );
    case LETTER:
      return <LegalLetter />;
    case HOLD:
      return <Hold />;
    case PAYMENTS:
      return <Payment />;
    case REVIEW:
      return <ReviewPromise />;
    default:
      return null;
  }
};

const SwitchScreen = ({ type, getParams, id, entity, tags, invoiceId }) => {
  const { letterAttachment } = useSelector((s) => s.letterReducer);

  if (type === "sms") {
    return (
      <SmsAction getParams={getParams} id={id} entity={entity} tags={tags} />
    );
  }

  if (type === "next") {
    return <NextAction />;
  }

  if (type === "call") {
    return <ManualCall />;
  }

  if (type === "email") {
    return (
      <EmailAction
        getParams={getParams}
        id={id}
        entity={entity}
        tags={tags}
        removeAttchFile={
          entity === "invoices"
            ? removeAttachmentQuickAction
            : unSelectedDocumentData
        }
        updatingDeskUpload={
          entity === "invoices"
            ? updateQuickActionEmailInvoiceAttachmentFromDesktop
            : updateEmailAttachmentFromDesktop
        }
        setAttachment={
          entity === "invoices"
            ? documentDriveQuickActionInv
            : documentDriveQuickAction
        }
        // updatingDeskUpload={}
        // updatingDeskUpload={updateEmailInvoiceAttachmentFromDesktop}
      />
    );
  }

  if (type === "payments") {
    return <PaymentsAction />;
  }

  if (type === "review") {
    return <ReviewPromiseAction />;
  }

  if (type === "dispute") {
    return (
      <DisputeAction
        getParams={getParams}
        entity={entity}
        invoiceId={invoiceId}
      />
    );
  }
  if (type === "whatsapp") {
    return (
      <WhatsAppAction
        getParams={getParams}
        id={id}
        entity={entity}
        tags={tags}
      />
    );
  }

  if (type === "letter") {
    return (
      <LetterAction
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
        removeAttchFile={removeAttachmentData}
        updatingDeskUpload={updateLetterAttachmentFromDesktop}
        letterAttachmentsData={letterAttachment}
      />
    );
  }
  if (type === "letter_status") {
    return (
      <LetterStatus
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
      />
    );
  }
  if (type === "field_visit") {
    return <FieldVisit />;
  }
  return null;
};

const AssignWorkflow = ({
  mostUsedData,
  getParams,
  id,
  entity,
  tags,
  invoiceId,
}) => {
  const {
    restrictions,
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    space,
  } = useContext(MaxyfiContext);

  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const switchQuickActionScreen = useSelector(
    (s) => s.customerOverviewReducer.type
  );

  const [isCount, setIsCount] = useState(true);

  const workFlowCount = space?.workflow_asigned_customer;

  useEffect(() => {
    if (workFlowCount >= restrictions.MAXAUTOCUST) {
      setIsCount(false);
    }
  }, []);

  const dispatch = useDispatch();

  const isModalOpen = useSelector((s) => s.customerOverviewReducer.modalIsOpen);

  const smsGateWayIsConfgured = currentDefaultFormatDetails?.is_sms_configured;
  const whatsAppIsConfigured =
    currentDefaultFormatDetails?.is_whatsapp_configured;
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  let quickActionEnabel = customerDetails?.is_quick_action;

  const entityName = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_entity
  );

  return (
    <>
      {isModalOpen === true ? (
        <SwitchScreen
          type={switchQuickActionScreen}
          getParams={getParams}
          id={id}
          entity={entity}
          tags={tags}
          invoiceId={invoiceId}
        />
      ) : (
        // <div style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "200px",
              }}
            >
              <img
                src={Assign}
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </div>

            {/* <Assign/> */}
          </div>
          {entityName === "INVOICE" ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "10px",
                  paddingBottom: "",
                }}
              >
                {/* DOUBT_ */}
                <div style={{ width: "190px" }}>
                  <RBACWrapper
                    role={ASSIGN_WORKFLOW}
                    type="PERMISSION"
                    // type="LICENSE"
                    enabled={isCount}
                    // placement="bottom"
                    // role={"asign_workflow"}
                  >
                    <TextButton
                      fullWidth
                      size={SIZE.compact}
                      onClick={() => {
                        dispatch(workflowAddModal(true));
                      }}
                      disabled={quickActionEnabel ? false : true}
                    >
                      <PlusOne />
                      Assign Workflow
                    </TextButton>
                  </RBACWrapper>
                </div>
              </div>
            </>
          )}

          <div style={{ padding: "30px 40px", paddingTop: "10px" }}>
            <div>
              <LabelLarge $style={{ color: "#333860" }}>
                Quick Actions
              </LabelLarge>
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                paddingTop: "10px",
                justifyContent: "space-between",
              }}
            >
              {mostUsedData.map(
                (i) =>
                  i.is_active && (
                    <div>
                      <div
                        title={
                          smsGateWayIsConfgured === false && i.type === "sms"
                            ? " SMS Gateway Configuration in Organization Settings - Pending"
                            : ""
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          // backgroundColor: "#FFFFFF",
                          // border: "0.5px solid #CDCED9",
                          borderRadius: "5px",
                          // boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={
                          (smsGateWayIsConfgured === false &&
                            i.type === "sms") ||
                          (!whatsAppIsConfigured && i.type === "whatsapp")
                            ? //   ||
                              // i.is_disable ||
                              // i.is_workflow
                              "svgQuickActionDisable"
                            : "svgQuickActionEnableBox"
                        }
                        // onClick={()=>{
                        //   dispatch(getActionData(i.type)),
                        //   dispatch(isOpenModal(true))
                        // }}
                        onClick={() => {
                          // dispatch(emptyState());

                          if (i.type === HOLD) {
                            dispatch(holdActionModal(true));
                          } else if (
                            i.type === "sms" &&
                            smsGateWayIsConfgured === false
                            // ||
                            // (i.type === "whatsapp" && !whatsAppIsConfigured)
                            //    ||
                            // i.is_disable ||
                            // i.is_workflow
                          ) {
                          } else {
                            dispatch(getActionData(i.type));
                            dispatch(isOpenModal(true));
                          }
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            // border: "2px solid #EEEEEE",
                            // background: "#EAEEFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // background: "#e3e3e3",
                          }}
                          className={
                            (smsGateWayIsConfgured === false &&
                              i.type === "sms") ||
                            (i.type === "whatsapp" && !whatsAppIsConfigured)
                              ? //    ||
                                // i.is_disable ||
                                // i.is_workflow
                                "circ-clr svgQuickActionDisable"
                              : i.type === "whatsapp"
                              ? "circ-clr svgQuickActionWhatsAppEnable"
                              : "circ-clr svgQuickActionEnable"
                          }
                        >
                          <SwitchSvg
                            type={i.type}
                            smsGateWayIsConfgured={smsGateWayIsConfgured}
                            isDisable={i.is_disable}
                            isWorkflow={i.is_workflow}
                            getParams={getParams}
                            whatsAppIsConfigured={whatsAppIsConfigured}
                          />
                        </div>
                        <HeadingXSmall>{i.actionName}</HeadingXSmall>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        // </div>
      )}
    </>
  );
};

export default AssignWorkflow;
