import React from "react";

const SuccessfullTick = ({ size=22 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.25 19.5C15.8063 19.5 19.5 15.8063 19.5 11.25C19.5 6.69365 15.8063 3 11.25 3C6.69365 3 3 6.69365 3 11.25C3 15.8063 6.69365 19.5 11.25 19.5ZM15.2883 8.84068C15.4765 8.63542 15.4626 8.3165 15.2573 8.12835C15.0521 7.9402 14.7332 7.95407 14.545 8.15932L9.92369 13.2008L7.99359 10.4986C7.83175 10.272 7.51687 10.2196 7.29029 10.3814C7.06371 10.5433 7.01123 10.8581 7.17308 11.0847L9.13605 13.8329C9.49164 14.3307 10.2154 14.3748 10.6288 13.9238L15.2883 8.84068Z"
        fill="#0FB158"
      />
    </svg>
  );
};

export default SuccessfullTick;
