import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import WhatsApp from "../../assets/img/svg/Template/WhatsApp";
import "../../assets/scss/components/notification.scss";
import {
  H2,
  HeadingSmall,
  HeadingXSmall,
  LabelMedium,
  LabelXSmall,
  LabelLarge,
  ParagraphMedium,
  ParagraphLarge,
  HeadingXLarge,
  Label1,
  ParagraphSmall,
} from "baseui/typography";
import { Avatar } from "baseui/avatar";
import Smiley from "../../assets/img/whatsapp/Smiley";
import Attachment_fill from "../../assets/img/whatsapp/Attachment_fill";
import Send from "../../assets/img/whatsapp/Send";
import DoubleTik from "../../assets/img/whatsapp/doubleTik";
import InputEmoji from "react-input-emoji";
import Call from "../../assets/img/svg/CpCard/Call";
import Weblink from "../../assets/img/whatsapp/Weblink";
import { useNavigate } from "react-router-dom";
import {
  executeAdhocCustomerAction,
  getCustomerContactDetail,
  getWhatsappChats,
  markAsRead,
} from "../../services";
import { useMutation, useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { CUSTOMER_OVERVIEW } from "../../constants";
import { IconButton, KIND } from "../IconButton";
import Picker from "emoji-picker-react";
import { WhatsAppInput } from "../WhatsAppInput/WhatsAppInput";
import { toast } from "react-toastify";
import moment from "moment";
import { FormattedTime } from "react-intl";
import AlertInfo from "../../assets/img/svg/AlertInfo";
import SingleTick from "../../assets/img/svg/SingleTick";
import DeliverTick from "../../assets/img/svg/DeliverTick";
import _ from "lodash";
const DeliveredStatusIcon = ({ type }) => {
  switch (type) {
    case "FAILURE":
      return (
        <div className="whatsapp_info_alert">
          <AlertInfo />
        </div>
      );
    case "UNREAD":
      return (
        <>
          <SingleTick />
        </>
      );
    case "READ":
      return (
        <>
          <DeliverTick />
        </>
      );
    case "SUCCESS":
      return (
        <>
          <SingleTick />
        </>
      );
    case "IN_PROGRESS":
      return (
        <>
          <SingleTick />
        </>
      );
    default:
      return null;
  }
};

function WhatsappNotification(props) {
  // const value = props?.dataMock;
  // const data = props?.dataMock?.data;

  let { whatsappListRefetch, setIsOpen, isRefetch } = props;

  let {
    dateFormat,
    space,
    currentDefaultFormatDetails,
    currentOrganization,
    referenceData,
  } = useContext(MaxyfiContext);

  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  const { data, refetch } = useQuery(
    `${CUSTOMER_OVERVIEW}-WHATSAPP-CHAT-${
      props && props.data && props.data.customer_id
    }`,
    async () => {
      return await getWhatsappChats({
        organization: currentOrganization,
        customerId: props.data.customer_id,
        phoneCode: props.data.phone_code?.replace("+", ""),
        phoneNumber: props.data.phone_number,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const navigate = useNavigate();
  // const CallAction = () => {
  //   return (
  //     <>
  //       <div className="whatsapp_contact-us -notification">
  //         <Call />
  //         <LabelMedium style={{ fontWeight: "400", color: "#516BEB" }}>
  //           {value.buttons[1].text}
  //         </LabelMedium>
  //       </div>

  //       <div
  //         className="whatsapp_website_url -notification"
  //         onClick={() => navigate(value.buttons[0].url)}
  //       >
  //         <Weblink />
  //         <LabelMedium style={{ fontWeight: "400", color: "#516BEB" }}>
  //           {value.buttons[0].text}
  //         </LabelMedium>
  //       </div>
  //     </>
  //   );
  // };
  // const QuickReply = () => {
  //   return (
  //     value &&
  //     value.buttons.map((e) => (
  //       <div className="whatsapp_Quick -notification">
  //         <LabelMedium style={{ fontWeight: "400", color: "#516BEB" }}>
  //           {e.text}
  //         </LabelMedium>
  //       </div>
  //     ))
  //   );
  // };
  // const MainPage = () => {
  //   return (

  //   );
  // };

  const messageList = useMemo(() => {
    let list = [];
    if (data && data.data) {
      let { docs = {} } = data.data;
      let { messages = [] } = docs;
      let newMessages = [...messages];
      list = newMessages.reverse();
      // list = messages;
    }
    return list;
  }, [data]);

  const customerContacts = useQuery(
    `customer-contacts-${props?.data?.customer_id}`,
    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: props?.data?.customer_id,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  // let dataVall = customerContacts.data.data.docs.map((e) =>
  //   e.phone.find(({ value }) => value === data?.data?.docs?.phone?.value)
  // );

  let dataVall =
    customerContacts &&
    customerContacts.data &&
    customerContacts.data.data &&
    customerContacts.data.data.docs.reduce((prv, crr) => {
      return [
        ...prv,
        ...crr.phone.map((ph) => {
          let { _id, ...res } = ph;
          return { _id: _id, ...res };
        }),
      ];
    }, []);

  let recipients = [];

  if (
    _.get(customerContacts, "data.data.docs", []) &&
    _.get(customerContacts, "data.data.docs", [])[0]?.phone
  ) {
    dataVall.map((e) => {
      recipients?.push({
        id: dataVall[0]._id,

        recipient_id:
          _.get(customerContacts, "data.data.docs", []) &&
          _.get(customerContacts, "data.data.docs", [])[0]._id,
      });
    });
  }

  const executeActionMutation = useMutation(
    (formValues) =>
      executeAdhocCustomerAction({
        ...formValues,
        id: props?.data?.customer_id,
        organization: currentOrganization,
        entity: "customer",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        refetch();
        setInputStr("");
        whatsappListRefetch();
      },
    }
  );

  const markAsReadWhatsapp = useMutation(
    (formValues) =>
      markAsRead({
        ...formValues,
        customerId: props?.data?.customer_id,
        organization: currentOrganization,
        // entity: "customer",
        phoneCode: props?.data?.phone_code,
        phoneNumber: props?.data?.phone_number,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // if (
        //   entityName === "INVOICE"
        //     ? dispatch(refetchInvoiceActions())
        //     : dispatch(refetchActions())
        // );
        // if (
        //   entity === "invoices"
        //     ? dispatch(refetchInvoiceDocumentData())
        //     : dispatch(refetchDocumentData())
        // )
        // reset();
        // dispatch(isOpenModal(false));
        refetch();
        whatsappListRefetch();
        isRefetch();
      },
    }
  );

  const onSubmit = async (data) => {
    let value = {
      message_type: "text",
      content: data,
      action_type: "whatsapp",
      recipients: recipients,
    };
    if (data) {
      await executeActionMutation.mutateAsync(value);
    }
  };

  const [text, setText] = useState("");
  function handleOnEnter(text) {}

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  const timeZone = orgRef?.time_zone;

  let lateDate = null;

  // let today = new Date();
  // let dateValue = today.valueOf();
  // let dateOf = moment
  //   .utc(dateValue)
  //   .tz(timeZone)
  //   .format(currentDefaultFormatDetails?.date_format);

  let unReadMsg = null;
  let unReadTag = false;

  let chatContainer = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }, 700);
  }, [props?.data?.customer_id]);

  return (
    <div
      style={{ width: "calc(100% - 57px)", height: "100%", overflow: "hidden" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "9px",
          marginLeft: "14px",
        }}
      >
        <WhatsApp />
        <HeadingXLarge>WhatsApp</HeadingXLarge>
      </div>

      {/* {value.map((values, index) => {
        

        return ( */}
      <div
        key={0}
        style={{
          margin: "0px 10px",
          // width: "calc (100% - 190px)",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "9px",
            padding: "13px",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F0F2F5",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "9px" }}>
            <>
              <Avatar
                name={props?.data?.name}
                size="scale950"
                // src="https://avatars.dicebear.com/api/human/yard.svg?width=285&mood=happy"
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#4BC4ED",
                      borderRadius: "50%",
                    }),
                  },
                  Avatar: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#4BC4ED",
                      borderRadius: "50%",
                    }),
                  },
                  Initials: {
                    style: ({ $theme }) => ({
                      backgroundColor: "#4BC4ED",
                      borderRadius: "50%",
                    }),
                  },
                }}
              />
              <div>
                <ParagraphMedium
                  onClick={() => {
                    navigate(`/customers/${props?.data?.customer_id}`);
                    setIsOpen();
                  }}
                  $style={{
                    fontSize: "16px",
                    fontWeight: " 500",
                    lineHeight: "normal",
                    color: "#516beb",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {/* Azar */}
                  {props?.data?.name}
                </ParagraphMedium>
                <HeadingXSmall
                  style={{
                    color: "gray",
                    lineHeight: "normal",
                    marginTop: "4px",
                  }}
                >
                  {props.data.phone_code} {props.data.phone_number}
                </HeadingXSmall>
              </div>
            </>
          </div>
          {_.get(data, "data.docs.unread_message_count", 0) > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "end",
                cursor: "pointer",
              }}
              onClick={() => markAsReadWhatsapp.mutateAsync()}
            >
              <DoubleTik />
              <HeadingXSmall style={{ color: "#516BEB" }}>
                Mark as read
              </HeadingXSmall>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 115px)",
          }}
        >
          <div className="notification_text-part" ref={chatContainer}>
            {/* <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                marginTop: "9px",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  width: "50px",
                  textAlign: "center",
                  // display: "flex",
                  // justifyContent: "center",
                  padding: "3px 2px",
                  borderRadius: ".25rem",
                }}
              >
                <LabelXSmall
                  style={{
                    color: "rgb(107 114 128)",
                    fontWeight: "400",
                  }}
                >
                  Today
                </LabelXSmall>
              </div>
            </div> */}
            {/* c */}
            <>
              {messageList.map((e) => {
                let isDateTag = false;
                let formattedDate = moment
                  .utc(e.make_at)
                  ?.tz(timeZone)
                  ?.format(currentDefaultFormatDetails?.date_format);
                if (formattedDate != lateDate) {
                  isDateTag = true;
                  lateDate = formattedDate;
                }

                let unReadStatus = e.status;

                let showReadStatus = false;
                if (!unReadTag && unReadStatus === "UNREAD") {
                  showReadStatus = true;
                  unReadTag = true;
                }
                return (
                  <>
                    {isDateTag && (
                      <div
                        style={{
                          display: "flex",
                          flex: "1",
                          justifyContent: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            backgroundColor: "#FEFEFE",
                            color: "#787878",
                            padding: "4px 8px",
                            borderRadius: "5px",
                            margin: "8px 3px",
                          }}
                        >
                          {
                            // moment
                            //   .utc(lateDate)
                            //   .tz(timeZone)
                            //   .format(currentDefaultFormatDetails?.date_format)
                            lateDate
                          }
                        </p>
                      </div>
                    )}
                    {showReadStatus && (
                      <p
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#FEFEFE",
                          color: "#787878",
                          padding: "4px 8px",
                          borderRadius: "5px",
                          margin: "8px 3px",
                          textAlign: "center",
                        }}
                      >
                        {data?.data?.docs?.unread_message_count} Unread Messages
                      </p>
                    )}
                    <div className={e.is_sender && "--whatsapp_msg"}>
                      <div
                        className={`notification ${
                          e.is_sender == true
                            ? "---chat-box-replay"
                            : "---chat-box-sender"
                        }`}
                      >
                        {e && e.content && e.content.header && (
                          <ParagraphMedium
                            $style={{
                              fontWeight: "500",
                              padding: "4px 0px",
                              lineHeight: " 1.5rem",
                              fontSize: "16px",
                            }}
                          >
                            {e &&
                              e.content &&
                              e.content.header &&
                              e.content.header.replace(/(<([^>]+)>)/gi, "")}
                          </ParagraphMedium>
                        )}

                        {e && e.content && e.content.body && (
                          <ParagraphMedium
                            style={{
                              lineHeight: "1.7rem",
                              fontVariantLigatures: "none",
                            }}
                          >
                            {e &&
                              e.content &&
                              e.content.body &&
                              e.content.body.replace(/(<([^>]+)>)/gi, "")}
                            {/* {values.msg} */}
                          </ParagraphMedium>
                        )}
                        {e && e.content && e.content.footer && (
                          <ParagraphSmall
                            $style={{
                              lineHeight: "1.4rem",
                              marginTop: "4px",
                              fontSize: "14px",
                              color: " rgb(107 114 128)",
                            }}
                          >
                            {e && e.content && e.content.footer}
                          </ParagraphSmall>
                        )}

                        <div className="timeline_chat_time_status">
                          <HeadingXSmall>
                            <FormattedTime value={new Date(e.make_at)} />
                          </HeadingXSmall>
                          {e && e.is_sender && (
                            <DeliveredStatusIcon type={e.status} />
                          )}
                        </div>
                      </div>
                      {e &&
                        e.content &&
                        e.content.buttons &&
                        e.content.buttons[0] &&
                        e.content.buttons[0].type == "CALL_TO_ACTION" &&
                        e.content.buttons &&
                        e.content.buttons?.map((e) => (
                          <>
                            {e.type == "URL" && (
                              <div
                                className={`whatsapp_website_url ${
                                  e.is_sender && "-notification"
                                }`}
                              >
                                <Weblink />
                                <LabelMedium
                                  style={{
                                    fontWeight: "400",
                                    color: "#516BEB",
                                  }}
                                >
                                  {e.text}
                                </LabelMedium>
                              </div>
                            )}
                            {e.type == "PHONE_NUMBER" && (
                              <div
                                className={`whatsapp_contact-us ${
                                  e.is_sender && "-notification"
                                } `}
                              >
                                <Call />
                                <LabelMedium
                                  style={{
                                    fontWeight: "400",
                                    color: "#516BEB",
                                  }}
                                >
                                  {e.text}
                                </LabelMedium>
                              </div>
                            )}
                          </>
                        ))}
                      {e &&
                        e.content &&
                        e.content.buttons &&
                        e.content.buttons[0] &&
                        e.content.buttons[0].type == "QUICK_REPLY" &&
                        e.content.buttons &&
                        e.content.buttons?.map((e) => (
                          <div className="whatsapp_Quick -notification">
                            <LabelMedium
                              style={{ fontWeight: "400", color: "#516BEB" }}
                            >
                              {e.text}
                            </LabelMedium>
                          </div>
                        ))}
                    </div>
                  </>
                );
                // data.i;
              })}
            </>

            {showPicker && (
              <Picker
                pickerStyle={{
                  width: "100%",
                  height: " 220px",
                  transform: " translate(0px, 0px)",
                }}
                onEmojiClick={onEmojiClick}
              />
            )}
          </div>
          {/* Footer */}
          {!data?.data?.docs?.is_conversation_expired && (
            <div className="whatsapp_footer-part whatsapp_emoji_wraper --timeline__footer">
              <div>
                <IconButton
                  kind={KIND.tertiary}
                  size="mini"
                  onClick={() => setShowPicker((val) => !val)}
                >
                  <Smiley />
                </IconButton>
              </div>
              <div style={{ flex: "1" }}>
                <WhatsAppInput
                  value={inputStr}
                  onChange={(e) => {
                    setInputStr(e.target.value);
                  }}
                  onKeyPress={(res) => {
                    if (!res.nativeEvent.shiftKey && res.key == "Enter") {
                      onSubmit(inputStr);
                    }
                  }}
                />
              </div>

              <div>
                <IconButton
                  kind={KIND.tertiary}
                  size="mini"
                  onClick={() => {
                    onSubmit(inputStr);
                  }}
                >
                  <Send />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* );
      })} */}
    </div>
  );
}

export default WhatsappNotification;
const date = new Date();
const showTime = date.getHours() + ":" + date.getMinutes();
