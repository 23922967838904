import React, { useContext, useState, useEffect } from "react";
import { useIntl, FormattedMessage, FormattedNumber } from "react-intl";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import {
  H2,
  HeadingSmall,
  HeadingXSmall,
  LabelMedium,
  LabelXSmall,
  ParagraphMedium,
} from "baseui/typography";
import { Label1 } from "baseui/typography";
import { TextArea } from "../../../../components/TextArea";
import { SelectBox } from "../../../../components/SelectBox";
import AlertInfo from "../../../../assets/img/svg/AlertInfo";
import FullScreen from "../../../../assets/img/svg/FullScreen";
import { IconButton } from "../../../../components/IconButton";
// import { StatefulTooltip, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import { useQuery } from "react-query";
import { COMMUNICATION_TEMPLATE } from "../../../../constants";
import { getCommunicationTemplateList } from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import ActionCostInfo from "../../../../components/ActionCostInfo.js";
import moment from "moment";
import { useSelector } from "react-redux";
import { FormattedDate } from "react-intl";
import { getOrganization } from "../../../../services";
import ContactNoCode from "../../../../assets/img/svg/ContactNoCode";
import setSelectValues from "../../../../utils/setSelectValues";

const SmsForm = ({
  Controller,
  control,
  errors,
  hideWorkflowArea,
  contacts = [],
  cost = {},
  isAdhocAction = false,
  recipientsData,
  setValue,
  currentActioDate,
  contactsData,
  getParams,
}) => {
  const [contactOptions, setContactOptions] = useState([]);

  const {
    referenceData,
    currentOrganization,
    userInfo,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const [settingData, setSettingData] = useState(null);

  const workflowDatas = useSelector(
    (s) => s.customerOverviewReducer.onLoadCustomerActions
  );

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let dayDiff = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.diff(moment.tz(timeZone), "days", true);

  let dayDifference = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  const intl = useIntl();

  const smsGateWayDatas = currentDefaultFormatDetails?.is_sms_configured;

  useEffect(() => {
    let setSmsData =
      contactsData && Array.isArray(contactsData)
        ? contactsData.reduce((prev, curr) => {
            let { phone, landline, email, address, ...rest } = curr;
            let nestedContacts = phone
              .map(({ status, sms_status, ...nRest }) => {
                return {
                  id: nRest?._id,
                  recipient_id: curr?._id,
                  sms_status,
                  status,
                };
              })
              .filter(
                (e) =>
                  (e.status === "VALID" || e.status === "LEAD") &&
                  e.sms_status === "VALID"
              );

            return [...prev, ...nestedContacts];
          }, [])
        : [];

    setValue("recipients", setSmsData);
  }, [contactsData]);

  return (
    <div style={{ padding: "0px 20px 0px 20px" }}>
      <div className="cp-action-title--content">
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <H2>
            <FormattedMessage id="review_and_send_sms">
              review_and_send_sms
            </FormattedMessage>
          </H2>

          {smsGateWayDatas === false ? (
            <>
              <StatefulTooltip
                content={() => (
                  <div>
                    SMS Gateway Configuration in Organization Settings - Pending
                  </div>
                )}
                placement={PLACEMENT.right}
                returnFocus
                autoFocus
              >
                <div>
                  <ContactNoCode />
                </div>
              </StatefulTooltip>
            </>
          ) : (
            <></>
          )}

          {dayDiff < 1 ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  border: "1.5px solid #FD372A",
                  borderRadius: "5px",
                  backgroundColor: "#FAF0EF",
                }}
              >
                <ParagraphMedium
                  style={{ color: "#FD372A", padding: "5px 10px" }}
                >
                  Future Action - Scheduled for <span>{dayDifference}</span>
                </ParagraphMedium>
              </div>
            </>
          )}
        </div>
        {cost && cost.cost && cost.cost.value ? (
          <ActionCostInfo {...cost} />
        ) : (
          <></>
        )}
      </div>
      <div className="cp-action-select">
        <Controller
          name="recipients"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <>
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                backspaceRemoves={false}
                noResultMessage={`${"Phone Number is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
                multi
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "recipients",
                })}
                placeholder={intl.formatMessage({
                  id: "recipients",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                tagStyle={false}
                // options={contacts}
                options={
                  contacts && Array.isArray(contacts)
                    ? contacts.reduce((prev, curr) => {
                        let { phone, landline, email, address, ...rest } = curr;
                        let nestedContacts = phone
                          ?.map(({ status, sms_status, ...nRest }) => {
                            let designation =
                              referenceData && referenceData["recipient_type"]
                                ? referenceData["recipient_type"].find(
                                    (rf) => rf?.id === curr?.designation
                                  )
                                : "";

                            return {
                              label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
                              id: nRest?._id,
                              recipient_id: curr?._id,
                              sms_status,
                              status,
                            };
                          })
                          .filter(
                            (e) =>
                              (e.status === "VALID" || e.status === "LEAD") &&
                              e.sms_status === "VALID"
                          );

                        return [...prev, ...nestedContacts];
                      }, [])
                    : []
                }
              />
            </>
          )}
        />
      </div>
      <div className="cp-action-select">
        <Controller
          defaultValues={""}
          name="content"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              name={field.name}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "sms_content",
              })}
              placeholder={intl.formatMessage({
                id: "sms_content",
              })}
              value={field.value}
            />
          )}
        />
      </div>
      <div className="cp-action-select">
        <Controller
          defaultValues={""}
          name="comment"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "user_comments",
              })}
              placeholder={intl.formatMessage({
                id: "user_comments",
              })}
              value={field.value}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SmsForm;
