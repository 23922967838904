import _ from "lodash";
import React, { useContext } from "react";
import { Paragraph2, ParagraphSmall, HeadingXSmall } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import moment from "moment";
import "moment-timezone";

export const PaymentSourceStatus = ({
  value,
  createdAtDate,
  createdAtName,
}) => {
  switch (value) {
    case "accountbook":
      return <div style={{ width: "100%" }}>Account Book</div>;
    case "portal":
      return <div style={{ width: "100%" }}>Portal</div>;
    case "INSTANT":
      return <div style={{ width: "100%" }}>Instant</div>;
    default:
      return (
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <ParagraphSmall>
            {createdAtName && createdAtName?.displayName}
          </ParagraphSmall>
          <HeadingXSmall style={{ lineHeight: "16px", color: "#787878" }}>
            {createdAtDate}
          </HeadingXSmall>
        </div>
      );
  }
};

const PaymentSource = (props) => {
  let {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    users,
  } = useContext(MaxyfiContext);
  let {
    value,
    column,
    alignment,
    accessor,
    suffix,
    row: { original },
    ...rest
  } = props;
  value = _.get(original, accessor, "");

  let createdAt = original && original.created_at;
  let createdBy = original && original.created_by;

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let createdAtDate = createdAt
    ? moment
        .utc(createdAt)
        ?.tz(timeZone)
        ?.format(
          currentDefaultFormatDetails &&
            currentDefaultFormatDetails?.date_format
        )
    : "-";

  let createdAtName = users.find(({ id }) => {
    return id == createdBy;
  });

  return (
    <PaymentSourceStatus
      value={value}
      createdAtDate={createdAtDate}
      createdAtName={createdAtName}
    />
  );
};

export default PaymentSource;
