import React, { useContext } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  assignMulCusVoiceMail,
  assignMulNextAction,
  assignMulPauseAction,
  getCustomerContactDetail,
  getCustomerVoiceFields,
} from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../TextArea";
import moment from "moment";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { Typography } from "../../components_v2";
import { TextBox } from "../TextBox";

const VoiceMailForm = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  isAllRowsSelected,
  queryFilter,
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    referenceData,
    refetch,
    space,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;
  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );

  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_customer: false,
    },
  });

  let isAllCustomerWatcher = watch("is_all_customer");
  let { hold_reason } = watch();

  const assignMultiNextAction = useMutation(
    (relMang) =>
      assignMulCusVoiceMail({
        ...relMang,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        handleModal();
      },
    }
  );
  let newFilter = dynamicQueryFilter({
    gridStructure: gridStructure,
    // customerQueryFilter: queryFilter && queryFilter.display_name,
    timeZone: currentDefaultFormatDetails.time_zone,
    customerFilter: filters,
  });
  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    let values = {
      ...data,
      ...(data.reschedule_date
        ? {
            reschedule_date: moment
              .tz(
                `${data.reschedule_date.getFullYear()}-${
                  data.reschedule_date.getMonth() + 1
                }-${data.reschedule_date.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customerId: selectedCustomer }),
    };

    await assignMultiNextAction.mutateAsync(values);
  };

  const { isLoading, data, isFetching } = useQuery(
    `customer-voice-mail-creatableFields`,
    async () => {
      if (selectedCustomer.length > 0) {
        return await getCustomerVoiceFields({
          organization: currentOrganization,

          query: newFilter ? newFilter : {},
          filters: {
            ...globalFilters,
            ...appAccessRest,
            needs_actions: [...NeedsActionFilter],
            stage: [...FilteredCustomerStage],
            search_value: queryFilter && queryFilter.display_name,
          },

          customerId: selectedCustomer,
          is_all_customer: isAllCustomerWatcher,
        });
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const doc = ["phone_number", "message_id"];

  const intl = useIntl();

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          className="voicemail-model-form_container"
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <Typography type="h4">
            The Voicemail Download File will be sent over Email
          </Typography>
          {doc.length > 0 &&
            doc.map((item) => {
              return (
                <Controller
                  control={control}
                  name={item}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      name={field.name}
                      label={intl.formatMessage({
                        id: `${item}`,
                      })}
                      placeholder={intl.formatMessage({
                        id: `${item}`,
                      })}
                      size={SIZE.mini}
                      value={field.value}
                    />
                  )}
                />
              );
            })}
        </div>

        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Download for all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default VoiceMailForm;
