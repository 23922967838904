import React from "react";

const DragDrop = (props) => {
  return (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="1.345"
        cy="6.69516"
        rx="1.56509"
        ry="1.345"
        transform="rotate(-90 1.345 6.69516)"
        fill="#D6D6D6"
      />
      <ellipse
        cx="1.345"
        cy="1.56479"
        rx="1.56509"
        ry="1.345"
        transform="rotate(-90 1.345 1.56479)"
        fill="#D6D6D6"
      />
      <ellipse
        cx="6.03494"
        cy="6.69516"
        rx="1.56509"
        ry="1.345"
        transform="rotate(-90 6.03494 6.69516)"
        fill="#D6D6D6"
      />
      <ellipse
        cx="6.03494"
        cy="1.56479"
        rx="1.56509"
        ry="1.345"
        transform="rotate(-90 6.03494 1.56479)"
        fill="#D6D6D6"
      />
      <ellipse
        cx="10.7249"
        cy="6.69516"
        rx="1.56509"
        ry="1.345"
        transform="rotate(-90 10.7249 6.69516)"
        fill="#D6D6D6"
      />
      <ellipse
        cx="10.7249"
        cy="1.56479"
        rx="1.56509"
        ry="1.345"
        transform="rotate(-90 10.7249 1.56479)"
        fill="#D6D6D6"
      />
    </svg>
  );
};

export default DragDrop;
