import React, { useState, useEffect, useContext, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { StatefulPopover } from "baseui/popover";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { Icon, Typography } from "../../../../components_v2";
import { SelectBox } from "../../../../components/SelectBox";
import { TextButton } from "../../../../components/TextButton";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { Checkbox } from "baseui/checkbox";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import {
  ACT_PROMISE_TO_PAY,
  ACT_PAYMENT_PLAN,
  ACT_AUTO_PAYMENT_PLAN,
  ACT_CALL_BACK_LATER,
  ACT_UNSUCCESSFUL,
  ACT_PAYMENT,
  ACT_RECORD_DISPUTE,
  ACT_FIELD_VISIT,
  ACT_NOTES,
} from "../../../../constants";
import PromiseToPay from "../Forms/PromiseToPay";
import CallBackLater from "../Forms/CallBackLater";
import CallUnsuccessful from "../Forms/CallUnsuccessful";
import PaymentPlan from "../Forms/PaymentPlan";
import FieldVisit from "../Forms/FieldVisit";
import Dispute from "../Forms/Dispute";
import Pay from "../Forms/Pay";
import Note from "../Forms/Note";
import AutoPaymentPlan from "../Forms/AutoPaymentPlan";
import { executeCustomerAction, makeCall } from "../../../../services";
import { CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../../../../constants";
import queryClient from "../../../../providers/queryClient";

import { executeAdhocCustomerAction } from "../../../../services";
import { setDrawerState } from "../../../../redux/actions";
import AgentActionAlert from "../../../../components_v2/AgentActionAlert";
import ActionHeader from "../ActionHeader";

const CallOutcome = (props) => {
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const {
    type,
    formValues = {},
    isWorkflow,
    setState = () => {},
    state = {},
    ...rest
  } = props;

  const setDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    setDrawer();
  };

  switch (type) {
    case ACT_CALL_BACK_LATER: {
      return (
        <CallBackLater
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_CALL_BACK_LATER }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
        />
      );
    }

    case ACT_RECORD_DISPUTE: {
      return (
        <Dispute
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_RECORD_DISPUTE }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
        />
      );
    }

    case ACT_PAYMENT: {
      return (
        <Pay
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_PAYMENT }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          setState={setState}
          state={state}
        />
      );
    }

    case ACT_PROMISE_TO_PAY: {
      return (
        <PromiseToPay
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_PROMISE_TO_PAY }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          setState={setState}
          state={state}
        />
      );
    }

    case ACT_PAYMENT_PLAN: {
      return (
        <PaymentPlan
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_PAYMENT_PLAN }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
          setState={setState}
          state={state}
        />
      );
    }

    case ACT_AUTO_PAYMENT_PLAN: {
      return (
        <AutoPaymentPlan
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_AUTO_PAYMENT_PLAN }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
        />
      );
    }

    case ACT_UNSUCCESSFUL: {
      return (
        <CallUnsuccessful
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_UNSUCCESSFUL }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
        />
      );
    }

    case ACT_FIELD_VISIT: {
      return (
        <FieldVisit
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_FIELD_VISIT }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
        />
      );
    }

    case ACT_NOTES: {
      return (
        <Note
          {...rest}
          formValues={{ ...formValues, call_outcome: ACT_NOTES }}
          service={
            !isWorkflow ? executeAdhocCustomerAction : executeCustomerAction
          }
          onCancel={() => {
            setDrawer();
          }}
          onSuccess={() => {
            onSuccessRefetch();
          }}
        />
      );
    }
    default:
      return null;
  }
};

const CloudCallButton = ({ id, recipient_id, disabled = false }) => {
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const { customerId } = useParams();

  const [callType, setCallType] = useState(
    currentDefaultFormatDetails.is_web_call_configured ? true : false
  );

  const { isLoading, mutateAsync } = useMutation(
    (data) =>
      makeCall({
        organization: currentOrganization,
        customerId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (data && data.data && data.data.doc && data.data.doc.redirect_url) {
          window.open(
            data.data.doc.redirect_url,
            "popUpWindow",
            "height=500,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
          );
        }
      },
    }
  );

  if (
    currentDefaultFormatDetails.is_cloud_telephony_configured &&
    currentDefaultFormatDetails.cloud_telephony_phone_numbers &&
    Array.isArray(currentDefaultFormatDetails.cloud_telephony_phone_numbers) &&
    currentDefaultFormatDetails.cloud_telephony_phone_numbers.length
  ) {
    const [phoneNumber, ...otherPhoneNumbers] =
      currentDefaultFormatDetails.cloud_telephony_phone_numbers;

    const onClick = async (fromNumber) => {
      await mutateAsync({
        recipient: {
          id,
          recipient_id: recipient_id,
        },
        source_number: fromNumber,
        call_type: callType ? "WEB_CALL" : "CALL",
      });
    };

    return (
      <div
        style={
          currentDefaultFormatDetails.is_web_call_configured
            ? { width: "200px", display: "flex" }
            : {}
        }
      >
        {currentDefaultFormatDetails.is_web_call_configured ? (
          <div
            style={{
              width: "100px",
              paddingTop: "2px",
            }}
          >
            <Checkbox
              checked={callType}
              onChange={() => setCallType(!callType)}
            >
              Web Call
            </Checkbox>
          </div>
        ) : (
          <></>
        )}
        <div>
          <TextButton
            size="mini"
            type="button"
            disabled={isLoading || disabled}
            isLoading={isLoading}
            onClick={() => onClick(phoneNumber)}
          >
            {phoneNumber}
          </TextButton>
        </div>
        {otherPhoneNumbers.length > 0 ? (
          <>
            <StatefulPopover
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    borderRadius: "5px",
                    overflow: "hidden",
                    backgroundColor: "#ffffff",
                  }),
                },
              }}
              placement="bottomRight"
              content={({ close }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",

                    backgroundColor: "#ffffff",
                  }}
                >
                  {otherPhoneNumbers.map((e, i) => (
                    <div
                      className="co-cloud-call__phonenumber"
                      onClick={() => {
                        close();
                        onClick(e);
                      }}
                    >
                      {e}
                    </div>
                  ))}
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div disabled={isLoading || disabled}>
                <Icon icon="more" />
              </div>
            </StatefulPopover>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return <></>;
};

// const callOutcomeList = [
//   { label: "PTP", icon: "review_promise", type: ACT_PROMISE_TO_PAY },
//   { label: "CBL", icon: "callback_later", type: ACT_CALL_BACK_LATER },
//   { label: "DIS", icon: "dispute", type: ACT_RECORD_DISPUTE },
//   { label: "APL", icon: "card", type: ACT_AUTO_PAYMENT_PLAN },
//   { label: "PPL", icon: "payment_plan", type: ACT_PAYMENT_PLAN },
//   { label: "FV", icon: "field_visit", type: ACT_FIELD_VISIT },
//   { label: "Pay", icon: "pay", type: ACT_PAYMENT },
//   { label: "Note", icon: "note", type: ACT_NOTES },
//   { label: "CL-Fail", icon: "call_unsuccessfull", type: ACT_UNSUCCESSFUL },
// ];

const callContent =
  "Greet and give a summary of the Outstanding Amount and duration overdue.\nUnderstand the reason why the payment is getting delayed.\nAppraise if needed the impact it can create like discontinued service.\nNegotiate for Promise To Pay by Date and if not arrange a follow-up Call or Meeting Request or capture the Dispute.\nNote: Enable and send Confirmation, Follow-up messages from the call action for better recovery.";

const CallAction = () => {
  const intl = useIntl();
  const { customerId } = useParams();
  const { referenceData } = useContext(MaxyfiContext);

  const drawerData = useSelector((state) => state.customerOverviewDrawer);
  const { data } = drawerData;

  const callOutcomeList = [
    {
      label: "PTP",
      icon: "review_promise",
      type: ACT_PROMISE_TO_PAY,
      isEnabled:
        data && data.activeAction
          ? data?.activeAction?.is_promise_to_pay
          : true,
    },
    {
      label: "CBL",
      icon: "callback_later",
      type: ACT_CALL_BACK_LATER,
      isEnabled:
        data && data.activeAction
          ? data?.activeAction?.is_callback_later
          : true,
    },
    {
      label: "DIS",
      icon: "dispute",
      type: ACT_RECORD_DISPUTE,
      isEnabled:
        data && data.activeAction ? data?.activeAction?.is_dispute : true,
    },
    {
      label: "APL",
      icon: "card",
      type: ACT_AUTO_PAYMENT_PLAN,
      isEnabled:
        data && data.activeAction ? data?.activeAction?.is_payment_plan : true,
    },
    {
      label: "PPL",
      icon: "payment_plan",
      type: ACT_PAYMENT_PLAN,
      isEnabled:
        data && data.activeAction ? data?.activeAction?.is_payment_plan : true,
    },
    {
      label: "FV",
      icon: "field_visit",
      type: ACT_FIELD_VISIT,
      isEnabled:
        data && data.activeAction ? data?.activeAction?.is_field_visit : true,
    },
    {
      label: "Pay",
      icon: "pay",
      type: ACT_PAYMENT,
      isEnabled:
        data && data.activeAction ? data?.activeAction?.is_payment : true,
    },
    {
      label: "Note",
      icon: "note",
      type: ACT_NOTES,
      isEnabled: true,
    },
    {
      label: "CL-Fail",
      icon: "alert",
      type: ACT_UNSUCCESSFUL,
      isEnabled: true,
    },
  ];

  const [state, setState] = useState({
    recipient: [],
    outcome: null,
    is_inbound_call: false,
    is_invoices: [],
  });

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const contacts = useMemo(() => {
    const contacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = [...phone, ...(landline ? landline : [])]
          .map(({ status, call_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              call_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.call_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      },
      []
    );

    if (data && data.isWorkflow) {
      if (data.recipientTypes && Array.isArray(data.recipientTypes)) {
        const selectedContacts = contacts.filter((e) =>
          data.recipientTypes.includes(e.designation)
        );

        setState({ ...state, recipient: selectedContacts.slice(0, 1) });
      }
    } else {
      const selectedContacts = contacts.filter((e) =>
        ["primary_contact"].includes(e.designation)
      );

      setState({ ...state, recipient: selectedContacts.slice(0, 1) });
    }

    if (
      _.get(customerData, "data.doc.call_rpc_count") >=
      _.get(customerData, "data.doc.mini_miranda.max_call_rpc")
    ) {
      setState((pre) => ({ ...pre, is_inbound_call: true }));
    }

    if (
      _.get(customerData, "data.doc.consent_call_till", "") &&
      _.get(customerData, "data.doc.consent_call_till", "") > Date.now()
    ) {
      setState((pre) => ({ ...pre, is_inbound_call: false }));
    }

    return contacts;
  }, [customerData?.data, customerId]);

  return (
    <div style={{ width: "785px" }}>
      <ActionHeader
        name="Manual Call"
        icon="call"
        is_inbound_call={state.is_inbound_call}
        setState={(val) => {
          setState({ ...state, is_inbound_call: val });
        }}
        inbound={true}
      />

      <AgentActionAlert
        isCallAction={true}
        customCallInstruction={
          _.get(drawerData, "data.isWorkflow", false)
            ? _.get(drawerData, "data.content", "dede")
            : callContent
        }
      />
      <div style={{ display: "flex", gap: "10px" }}>
        <SelectBox
          size="mini"
          requiredAstric={true}
          label={intl.formatMessage({
            id: "reciever",
          })}
          placeholder={intl.formatMessage({
            id: "reciever",
          })}
          clearable={false}
          value={state.recipient}
          onChange={(e) => {
            setState({ ...state, recipient: e.value });
          }}
          options={contacts}
        />
        {!state.is_inbound_call ? (
          <CloudCallButton
            id={state.recipient && state.recipient[0] && state.recipient[0].id}
            recipient_id={
              state.recipient &&
              state.recipient[0] &&
              state.recipient[0].recipient_id
            }
            disabled={!(state.recipient && state.recipient[0])}
          />
        ) : (
          <></>
        )}
      </div>

      <div style={{ paddingBottom: "5px" }}>
        <Typography type="p" style={{ color: "#ADADAD" }}>
          Call Outcome
        </Typography>
      </div>
      <div className="co-call-outcome-container">
        {callOutcomeList.map(
          (e) =>
            e &&
            e.isEnabled && (
              <div
                key={e.type}
                className={`co-call-outcome-btn ${
                  state.outcome === ACT_UNSUCCESSFUL &&
                  e.type === ACT_UNSUCCESSFUL
                    ? "co-call-outcome-btn-unsucessful--active"
                    : state.outcome === e.type
                    ? "co-call-outcome-btn--active"
                    : ""
                } ${
                  state.recipient && state.recipient[0]
                    ? ""
                    : "co-call-outcome-btn--disabled"
                }`}
                onClick={() => {
                  setState({ ...state, outcome: e.type });
                }}
                disabled={!state.outcome}
              >
                <Icon icon={e.icon} />
                <Typography>{e.label}</Typography>
              </div>
            )
        )}
      </div>
      {state.outcome ? (
        <CallOutcome
          type={state.outcome}
          formValues={{
            recipients: state?.recipient?.map(({ id, recipient_id }) => ({
              id,
              recipient_id,
            })),
            action_type: "call",
            is_inbound_call: state.is_inbound_call,
            is_increment_follow_up: true,
          }}
          recipient_id={
            state.recipient && state.recipient[0] && state.recipient[0].id
              ? state.recipient[0].id
              : null
          }
          isWorkflow={_.get(drawerData, "data.isWorkflow", false)}
          contacts={_.get(customerData, "data.doc.contacts", [])}
          setState={setState}
          state={state}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CallAction;
