import {
  DOCUMENT_DRIVE_DATA_INVOICE,
  INVOICE_DRIVE_OPEN,
  DOCUMENT_INVOICE_DRAWER_CLOSE,
  UNSELECTED_INVOICE_DOCUMENT_DATA,
  ADD_EMAIL_ATTACHMENT_INVOICE_FROM_DESKTOP,
  UPDATE_EMAIL_INVOICE_ATTACHMENT_FROM_DESKTOP,
  INVOICE_DOCUMENT_DRAWER_OPEN,
  ADD_INVOICE_DOCUMENT_FORM,
  EDIT_DOCUMENT_INVOICE_FORM,
  INVOICE_TYPE_FORM_INITIAL,
  REFETCH_INVOICE_DOCUMENT_DATA,
  DOCUMENT_DRIVE_DATA_INVOICE_SET_EMAIL_ATTACHMENT,
} from "./action";
import { NEW, EDIT, EMAIL } from "../../../constants";

const initialState = {
  emailAttachmentInvoice: [],
  drawerInvoiceState: false,
  drawerInvoiceType: "",
  documentInvoicedetails: [],
  refetchInvoiceDocument: 0,
};

const invoiceDocumentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFETCH_INVOICE_DOCUMENT_DATA: {
      const { refetchInvoiceDocument } = state;
      return {
        ...state,
        refetchInvoiceDocument: refetchInvoiceDocument + 1,
      };
    }
    case INVOICE_TYPE_FORM_INITIAL:
      return {
        ...state,
        drawerInvoiceType: initialState.drawerInvoiceType,
        emailAttachmentInvoice: initialState.emailAttachmentInvoice,
      };
    case ADD_INVOICE_DOCUMENT_FORM: {
      return {
        ...state,
        drawerInvoiceType: NEW,
      };
    }
    case EDIT_DOCUMENT_INVOICE_FORM: {
      return {
        ...state,
        drawerInvoiceType: EDIT,
        documentInvoicedetails: payload,
      };
    }
    case INVOICE_DRIVE_OPEN:
      return {
        ...state,
        drawerInvoiceState: payload,
        drawerInvoiceType: EMAIL,
      };
    case DOCUMENT_DRIVE_DATA_INVOICE:
      return {
        ...state,
        emailAttachmentInvoice: [...state.emailAttachmentInvoice, ...payload],
      };

    case DOCUMENT_DRIVE_DATA_INVOICE_SET_EMAIL_ATTACHMENT:
      return {
        ...state,
        emailAttachmentInvoice: [...state.emailAttachmentInvoice, ...payload],
      };

    case UNSELECTED_INVOICE_DOCUMENT_DATA: {
      let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.emailAttachmentInvoice.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        emailAttachmentInvoice: filteredData,
      };
    }

    case ADD_EMAIL_ATTACHMENT_INVOICE_FROM_DESKTOP: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.emailAttachmentInvoice;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;

        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        emailAttachmentInvoice: [...currentFiles, ...files],
      };
    }

    case UPDATE_EMAIL_INVOICE_ATTACHMENT_FROM_DESKTOP: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.emailAttachmentInvoice.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let emailAttachmentInvoice = state.emailAttachmentInvoice;
        let currentFile = emailAttachmentInvoice[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        emailAttachmentInvoice[fileIndex] = currentFile;

        return { ...state, emailAttachmentInvoice: emailAttachmentInvoice };
      }

      return state;
    }

    case INVOICE_DOCUMENT_DRAWER_OPEN:
      return {
        ...state,
        drawerInvoiceState: payload,
      };

    case DOCUMENT_INVOICE_DRAWER_CLOSE:
      return {
        ...state,
        drawerInvoiceState: payload,
        drawerInvoiceType: "",
      };

    default:
      return initialState;
  }
};
export default invoiceDocumentReducer;
