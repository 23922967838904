import { useEffect, useState } from "react";
import moment from "moment";

import { Icon, Typography } from "../../../components_v2";
import { FormattedTime } from "react-intl";

export const LocalTime = ({ timezone }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(
      () => setTime(moment.tz(timezone ? timezone : "").toDate()),
      1000
    );

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
    <>
      <Icon icon="clock" size={18} />
      <Typography type="p" subType="regular">
        <FormattedTime
          value={time}
          hour="numeric"
          minute="numeric"
          second="numeric"
        />{" "}
        <span className="text-secondary">(Local Time)</span>
      </Typography>
    </>
  );
};
export const ReviewGroupTime = ({ timezone }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(
      () => setTime(moment.tz(timezone ? timezone : "").toDate()),
      1000
    );

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
    <div className="review-localTime">
      <Icon icon="clock" size={18} />
      <Typography type="p" subType="regular">
        <FormattedTime
          value={time}
          hour="numeric"
          minute="numeric"
          second="numeric"
        />{" "}
        <span className="text-secondary">(Local Time)</span>
      </Typography>
    </div>
  );
};
