const SmsBounce = () => {
  return (
    <>
      <svg
        width="27"
        height="27"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <rect width="22" height="22" fill="white" /> */}
        <path
          d="M11 6.86426V8.76469"
          stroke="#516BEB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11 12.5744L11.01 12.5645"
          stroke="#516BEB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2 19.4287V4.90043C2 3.85085 2.94518 3 4.11111 3H18.8889C20.0549 3 21 3.85085 21 4.90043V14.4026C21 15.4522 20.0549 16.303 18.8889 16.303H7.23687C6.59555 16.303 5.98901 16.5655 5.58837 17.0162L3.12788 19.7849C2.75389 20.2057 2 19.9677 2 19.4287Z"
          stroke="#516BEB"
          stroke-width="1.5"
        />
      </svg>
    </>
  );
};

export default SmsBounce;
