import {
  RQ_INF_ATTACHMENTS,
  RQ_INF_REMOVE_ATTACHMENT,
  RQ_INF_UPDATE_ATTACHMENT,
  RQ_INF_CHART,
  RQ_INF_RESET,
} from "./action";

const initialState = {
  attachments: [],
  chart: true,
};

const requestInformationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RQ_INF_ATTACHMENTS: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles =
        state.attachments && Array.isArray(state.attachments)
          ? state.attachments
          : [];

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;
        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        attachments: [...currentFiles, ...files],
      };
    }

    case RQ_INF_REMOVE_ATTACHMENT: {
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.attachments.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }

      return {
        ...state,
        attachments: filteredData,
      };
    }

    case RQ_INF_UPDATE_ATTACHMENT: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.attachments.findIndex((fl) => fl.tempId === tempId);

      if (fileIndex >= 0) {
        let emailAttachment = state.attachments;
        let currentFile = emailAttachment[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        emailAttachment[fileIndex] = currentFile;

        return { ...state, attachments: emailAttachment };
      }

      return state;
    }

    case RQ_INF_CHART: {
      return { ...state, chart: payload };
    }

    case RQ_INF_RESET: {
      return { ...initialState, chart: state.chart };
    }

    default: {
      return state;
    }
  }
};

export default requestInformationReducer;
