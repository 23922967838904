import React, { useState, useEffect, useMemo, useContext } from "react";
import { Tag } from "baseui/tag";
import { useSelector } from "react-redux";
import { Input } from "baseui/input";
import { Search } from "baseui/icon";
import { IconButton } from "../../../components/IconButton";
import {
  HeadingXSmall,
  Label1,
  Label2,
  ParagraphMedium,
} from "baseui/typography";
import { useNavigate } from "react-router-dom";
import { TextArea } from "../../../components/TextArea";
import {
  ADD_RECURRING_INVOICE,
  DELETE_INVOICE,
  EDIT_CHARGES,
  EDIT_INVOICE,
  EDIT_RECURRING_INVOICE,
} from "../../../providers/RBACProvider/permissionList";
// Table
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import PaginationWraper from "../../../components/Pagination/Pagination";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import {
  customerInvoiceOverview,
  deleteCharges,
  putInvoiceEdit,
} from "../../../services/customerOverview";
import { deletePayment, getGridStructure } from "../../../services";
// svg
import Plus from "../../../assets/img/svg/Upload/Plus";
import ChipFilter from "../../../components/ChipFilter";
import { useParams } from "react-router-dom";
import columnMapper from "../../../utils/mapper";

import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

import { useDebouncedCallback } from "use-debounce";
import PlusContact from "../../../assets/img/svg/CustomerOverViewIcon/PlusContact";
import * as InvIconButon from "../../../components/IconButton";
import PlusOne from "../../../assets/img/svg/Plus";
import AddInvoice from "./AddInvoice";
import {
  addInvoiceOpenModal,
  refetchActions,
  subscriptionsDrawer,
} from "../../../redux/actions";
import AddRecurringInv from "../../../components/AddRecurringInv/AddRecurringInv";
import RecurringSvg from "../../../assets/img/svg/RecurringSvg";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { ADD_INVOICE } from "../../../providers/RBACProvider/permissionList";
import { Modal } from "baseui/modal";
import { TextButton, SIZE, KIND } from "../../../components/TextButton";
import { Controller, useForm } from "react-hook-form";
import { TextBox } from "../../../components/TextBox";
import { useIntl } from "react-intl";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { ParagraphSmall } from "baseui/typography";
import moment from "moment";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { CollectionAgencies } from "../../../constants/IndustrialSector";
import _ from "lodash";
import { useNumericFormat } from "react-number-format";
import CustomFieldsTab from "../../../components/CustomFields/CustomFieldsTab";
import { ModalContext } from "../../../providers/ModalProvider";
import warningImage from "../../../assets/img/warning.png";

const InvExpandCard = ({ original }) => {
  const { customInvoice, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  return (
    <div
      style={{
        background: "#ffffff",
        padding: "10px",
        flex: "1",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <CustomFieldsTab
        customFields={original}
        fieldLevel={customInvoice}
        width={"100%"}
        wrapWidth={"280px"}
      />
      <div
        style={{
          width: "300px",
        }}
      >
        <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
          Patient Name:
        </ParagraphMedium>
        <ParagraphMedium $style={{ color: "#333860" }}>
          {original?.patient_name ? original?.patient_name : "-"}
        </ParagraphMedium>
      </div>
      <div
        style={{
          width: "300px",
        }}
      >
        <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
          Employer:
        </ParagraphMedium>
        <ParagraphMedium $style={{ color: "#333860" }}>
          {original?.employer ? original?.employer : "-"}
        </ParagraphMedium>
      </div>
      <div
        style={{
          width: "300px",
        }}
      >
        <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
          Location of Service:
        </ParagraphMedium>
        <ParagraphMedium $style={{ color: "#333860" }}>
          {original?.service_location ? original?.service_location : "-"}
        </ParagraphMedium>
      </div>
      <div
        style={{
          width: "300px",
        }}
      >
        <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
          Insurance Info:
        </ParagraphMedium>
        <ParagraphMedium $style={{ color: "#333860" }}>
          {original?.insurance ? original?.insurance : "-"}
        </ParagraphMedium>
      </div>

      <div
        style={{
          width: "300px",
        }}
      >
        <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
          Client Reference:
        </ParagraphMedium>
        <ParagraphMedium $style={{ color: "#333860" }}>
          {original?.client_reference ? original?.client_reference : "-"}
        </ParagraphMedium>
      </div>
      <div
        style={{
          width: "300px",
        }}
      >
        <ParagraphMedium $style={{ color: "#ADADAD", fontSize: "12px" }}>
          Invoice Description:
        </ParagraphMedium>
        <ParagraphMedium $style={{ color: "#333860" }}>
          {original?.invoice_description ? original?.invoice_description : "-"}
        </ParagraphMedium>
      </div>
    </div>
  );
};

const InvoiceEditModal = ({
  invoiceEditModal,
  setInvoiceEditModal,
  invoiceEditData,
  setInvoiceEditData,
}) => {
  let dispatch = useDispatch();
  let intl = useIntl();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_cancelled: false,
    },
  });

  let { not_paid_amount, invoice_total_amount } = watch();

  let { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  useEffect(() => {
    if (
      currentDefaultFormatDetails &&
      !currentDefaultFormatDetails.is_compute_due_amount &&
      not_paid_amount > invoice_total_amount
    ) {
      setError("not_paid_amount", {
        type: "custom",
        message: "Due Amount is Less than Invoice Amount.",
      });
    } else {
      clearErrors("not_paid_amount");
    }
  }, [not_paid_amount, invoice_total_amount]);

  const editInvoiceData = useMutation(
    (data) =>
      putInvoiceEdit({
        organization: currentOrganization,
        invoiceId: invoiceEditData?._id,
        // letterId: letterId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        setInvoiceEditModal(false);
        setTimeout(() => {
          dispatch(refetchActions());

          // setFileScanCount(fileScanCount + 1);
        }, 2000);
      },
    }
  );

  useEffect(() => {
    if (invoiceEditData) {
      setValue(
        "invoice_number",
        invoiceEditData && invoiceEditData?.invoice_number
      );
      setValue(
        "invoice_date",
        invoiceEditData && invoiceEditData?.invoice_date
      );
      setValue(
        "invoice_total_amount",
        invoiceEditData && invoiceEditData?.invoice_total_amount?.value
      );
      setValue(
        "not_paid_amount",
        invoiceEditData &&
          invoiceEditData.not_paid_amount &&
          invoiceEditData.not_paid_amount.value > 0
          ? Number(invoiceEditData.not_paid_amount.value)
          : 0
      );
      setValue(
        "invoice_due_date",
        invoiceEditData && invoiceEditData?.invoice_due_date
      );
      if (invoiceEditData && invoiceEditData.status === "cancelled") {
        setValue("is_cancelled", true);
      } else {
        setValue("is_cancelled", false);
      }
    }
  }, [invoiceEditData]);

  const onSubmit = async (data) => {
    let values = {
      invoice_number: data.invoice_number,
      invoice_date: moment(data.invoice_date).startOf("day").utc().valueOf(),
      invoice_due_date: moment(data.invoice_due_date)
        .startOf("day")
        .utc()
        .valueOf(),
      invoice_total_amount: {
        value: Number(data.invoice_total_amount),
        currency: invoiceEditData?.invoice_total_amount?.currency,
      },
      ...(!currentDefaultFormatDetails.is_compute_due_amount
        ? {
            not_paid_amount: {
              value: Number(data.not_paid_amount),
              currency: invoiceEditData?.not_paid_amount?.currency,
            },
          }
        : {}),

      is_cancelled: data.is_cancelled ? data.is_cancelled : false,
      user_comments: data.comments,
    };
    await editInvoiceData.mutateAsync(values);
  };

  return (
    <Modal
      isOpen={invoiceEditModal}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            // padding: "50px 32px",
            // height: "500px",
            width: "600px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      <form
        style={{
          padding: "15px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Label1
            style={{ display: "flex", alignItems: "left", padding: "10px" }}
          >
            Invoice Details Update
          </Label1>
          <div
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              // width: "500px",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                }}
              >
                <div style={{ width: "250px" }}>
                  <Controller
                    name={`invoice_number`}
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          requiredAstric={true}
                          //   disabled={isDisable}
                          fullWidth
                          name={field.name}
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "invoice_number",
                          })}
                          placeholder={intl.formatMessage({
                            id: "invoice_number",
                          })}
                          value={field.value}
                          disabled={
                            currentDefaultFormatDetails.accounting_book ===
                            "ROTESSA"
                              ? true
                              : false
                          }
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <Controller
                    name="invoice_date"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <DateRangeSelect
                        {...field}
                        name={field.name}
                        size={SIZE.compact}
                        requiredAstric={true}
                        // disabled={isDisabled}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "invoice_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        disabled={
                          currentDefaultFormatDetails.accounting_book ===
                          "ROTESSA"
                            ? true
                            : false
                        }
                        onChange={(e) => field.onChange(e.date)}
                        // minDate={new Date()}
                        range
                      />
                    )}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                }}
              >
                <div style={{ width: "250px" }}>
                  <Controller
                    name={`invoice_total_amount`}
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          //   disabled={isDisable}
                          requiredAstric={true}
                          type="number"
                          fullWidth
                          name={field.name}
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "invoice_amount",
                          })}
                          placeholder={intl.formatMessage({
                            id: "invoice_amount",
                          })}
                          value={field.value}
                          disabled={
                            currentDefaultFormatDetails.accounting_book ===
                            "ROTESSA"
                              ? true
                              : false
                          }
                          onKeyDown={(e) => {
                            if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                              e.preventDefault();
                            }
                          }}
                        />
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "250px" }}>
                  <Controller
                    name="invoice_due_date"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <DateRangeSelect
                        {...field}
                        name={field.name}
                        requiredAstric={true}
                        size={SIZE.compact}
                        // disabled={isDisabled}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "invoice_due_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        disabled={
                          currentDefaultFormatDetails.accounting_book ===
                          "ROTESSA"
                            ? true
                            : false
                        }
                        onChange={(e) => field.onChange(e.date)}
                        // minDate={new Date()}
                        range
                      />
                    )}
                  />
                </div>
              </div>
              {!currentDefaultFormatDetails.is_compute_due_amount && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 10px",
                  }}
                >
                  <div style={{ width: "250px" }}>
                    <Controller
                      name={`not_paid_amount`}
                      control={control}
                      rules={{ required: "Required" }}
                      shouldUnregister
                      render={({ field }) => (
                        <>
                          <TextBox
                            {...field}
                            //   disabled={isDisable}
                            requiredAstric={true}
                            type="number"
                            fullWidth
                            name={field.name}
                            size={SIZE.compact}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            label={intl.formatMessage({
                              id: "due_amount",
                            })}
                            placeholder={intl.formatMessage({
                              id: "due_amount",
                            })}
                            value={field.value}
                            disabled={
                              currentDefaultFormatDetails.accounting_book ===
                              "ROTESSA"
                                ? false
                                : true
                            }
                            onKeyDown={(e) => {
                              if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </>
                      )}
                    />
                  </div>
                  <div style={{ width: "250px" }}>
                    {/* <Controller
                    name="invoice_due_date"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <DateRangeSelect
                        {...field}
                        name={field.name}
                        requiredAstric={true}
                        size={SIZE.compact}
                        // disabled={isDisabled}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "invoice_due_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        disabled={
                          currentDefaultFormatDetails.accounting_book ===
                          "ROTESSA"
                            ? true
                            : false
                        }
                        onChange={(e) => field.onChange(e.date)}
                        // minDate={new Date()}
                        range
                      />
                    )}
                  /> */}
                  </div>
                </div>
              )}

              <div style={{ padding: "0px 10px" }}>
                <Controller
                  name={`is_cancelled`}
                  control={control}
                  render={({ field }) => (
                    <CheckBoxBaseweb {...field} checked={field.value}>
                      <ParagraphSmall style={{ color: "#333860" }}>
                        {/* {i.id} */}
                        {/* <FormattedMessage id={i.id}>{i.id}</FormattedMessage> */}
                        Mark as Cancelled
                      </ParagraphSmall>
                    </CheckBoxBaseweb>
                  )}
                />
              </div>
              <div style={{ padding: "0px 10px", paddingTop: "15px" }}>
                <Controller
                  control={control}
                  name="comments"
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      name={field.name}
                      label={intl.formatMessage({
                        id: "comments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "comments",
                      })}
                      size={SIZE.mini}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "end", gap: "5px" }}>
              <div>
                <TextButton
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  type="button"
                  onClick={() => {
                    setInvoiceEditModal(false);
                    reset();
                    // setInvoiceEditData({});
                  }}
                >
                  Cancel
                </TextButton>
              </div>
              <div>
                <TextButton
                  size={SIZE.compact}
                  kind={KIND.primary}
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

const Invoice = ({ getParams, setSelectedInvoiceId }) => {
  const [invoiceEditModal, setInvoiceEditModal] = useState(false);
  const navigate = useNavigate();
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  const [invoiceEditData, setInvoiceEditData] = useState({});

  let {
    currentOrganization,
    currentDefaultFormatDetails,
    space,
    appAccessRest,
  } = useContext(MaxyfiContext);

  let invoicePermissionData =
    space && space.role_permissions?.includes(ADD_INVOICE);

  let recurringPermissionData =
    space && space?.role_permissions?.includes(ADD_RECURRING_INVOICE);

  // const { id } = useParams();
  let dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [columndata, setColumnData] = useState([]);
  // Table
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("-_id");
  const [queryFilter, setQueryFilter] = useState({});
  const [width, setWidth] = useState();
  const [globalFilter, setGlobalFilter] = useState("");

  const intl = useIntl();

  let reduxData = useSelector((state) => state.customerSummary.tableState);
  let { refetchActions } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const debounceFilter = useDebouncedCallback((filter) => {
    setQueryFilter(filter);
  }, 1000);

  let ref = React.useRef();

  // API is passing in useQuery
  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `CUSTOMER_INVOICE_OVERVIEW-${getParams}-${refetchActions}`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: querySortBy,
        filters: queryFilter,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      filters = {
        ...filters,
        // ...appAccessRest,
      };

      return await customerInvoiceOverview({
        pageIndex,
        sortBy,
        pageSize,
        filters,
        organization: currentOrganization,
        customer_id: getParams,
        // customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  // res.data.doc.payment_audit.sort((a, b) => b.updated_on - a.updated_o,
  // data,

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "customer_overview",
      });
      let filterDueDays;
      if (
        currentDefaultFormatDetails.industry_sector == CollectionAgencies &&
        userListVal.data.doc.table.length > 0
      ) {
        filterDueDays = userListVal?.data?.doc?.table.filter(
          (e) => e.literal_id !== "due_days"
        );
      } else {
        filterDueDays = userListVal?.data?.doc?.table.filter(
          (e) => e.literal_id !== "days_since_created"
        );
      }
      setColumnData(filterDueDays);
    };
    userListApi();
  }, [refetchActions]);

  // useEffect(() => {
  //   if (
  //     currentDefaultFormatDetails.industry_sector == CollectionAgencies &&
  //     columndata.length > 0
  //   ) {

  //   }
  // }, [columndata]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columndata,
      isExpanded: false,
      isInvExpand: true,
      isExpandedHeader: false,
      isSelection: false,
      width: width - 200,
    });
    return columns;
  }, [columndata]);

  const tabledata = useMemo(
    () =>
      isFetched && !isError && data && data.data && data.data.docs
        ? data.data.docs
        : [],
    [isFetched, data]
  );

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current?.offsetWidth);
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters },
    prepareRow,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount:
        isFetched && !isError && data?.data ? data?.data?.totalPages : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const currentColOrder = React.useRef();

  // useEffect(() => {
  //   setHiddenColumns(reduxData.hiddenColumns);
  // }, [reduxData, reduxData.hiddenColumns]);

  function SubRows({ row, rowProps, visibleColumns, data, loading }) {
    // if (loading) {
    //   return (
    //     <div className="trow">
    //       <div className="tdbody" colSpan={visibleColumns.length - 1}>
    //         Loading...
    //       </div>
    //     </div>
    //   );
    // }

    // error handling here :)
    return (
      <>
        <div className="trow" {...rowProps} key={`${rowProps.key}-expanded-`}>
          <InvExpandCard {...row} />
        </div>
      </>
    );
  }

  function SubRowAsync({ row, rowProps, visibleColumns }) {
    const [loading, setLoading] = React.useState(true);
    const [data] = React.useState([]);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    return (
      <SubRows
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
        data={data}
        loading={loading}
      />
    );
  }

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, visibleColumns }) => (
      <SubRowAsync
        row={row}
        rowProps={rowProps}
        visibleColumns={visibleColumns}
      />
    ),
    []
  );

  useEffect(() => {
    if (pageIndex == queryPageIndex) {
      gotoPage(0);
    }
    setQueryPageIndex(pageIndex);
    setQueryPageSize(pageSize);
    setQuerySortBy(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "-_id"
    );
  }, [pageIndex, pageSize, sortBy, filters, getParams]);

  const updateQueryFilter = (activeFilters) => {
    if (activeFilters.length > 0) {
      setQueryFilter({
        status: [...activeFilters],
      });
    } else {
      setQueryFilter({});
    }
  };

  const { handleModal } = useContext(ModalContext);

  const deleteChargesInv = useMutation(
    (data) => deleteCharges({ ...data, organization: currentOrganization }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
      },
    }
  );

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <div className="cp-tab__filters">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ChipFilter
              filters={[
                { label: "Due", value: "due" },
                { label: "Overdue", value: "overdue" },
              ]}
              setFilter={updateQueryFilter}
            />
          </div>
          <div className="cp-tab__search-filter --inv_add_btn">
            {(currentDefaultFormatDetails.accounting_book === "CSV" ||
              currentDefaultFormatDetails.accounting_book === "ROTESSA") && (
              <>
                <div
                // className="inv_icon"
                >
                  <IconButton
                    kind={KIND.primary}
                    size={SIZE.compact}
                    onClick={() => {
                      //
                      // dispatch(addInvoiceOpenModal(true));
                      dispatch(subscriptionsDrawer(true));
                      // navigate("/invoice-form");
                    }}
                  >
                    <RecurringSvg />

                    {/* <i className="inv_icon" /> */}
                  </IconButton>
                </div>
                {/* <RBACWrapper role={ADD_INVOICE} type="PERMISSION"> */}
                {invoicePermissionData || recurringPermissionData ? (
                  <>
                    <AddRecurringInv
                      keyValue={"customers"}
                      getParams={getParams}
                    />
                  </>
                ) : (
                  <></>
                )}
                {/* </RBACWrapper> */}
              </>
            )}

            <Input
              overrides={{
                Root: {
                  style: () => ({
                    borderLeftColor: "#ceced9",
                    borderRightColor: "#cecde9",
                    borderTopColor: "#cecde9",
                    borderBottomColor: "#cecde9",

                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                    borderTopWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                    // marginRight: "20px",
                    // marginTop: "20px",
                    // paddingRight: "20px",
                    width: "182px",
                    background: "white",
                    height: "36px",
                  }),
                },
                InputContainer: {
                  style: () => ({
                    background: "white",
                  }),
                },
                EndEnhancer: {
                  style: ({ $theme }) => ({
                    padding: "0px",
                    background: "white",
                  }),
                },
              }}
              size={SIZE.compact}
              endEnhancer={<Search />}
              placeholder={intl.formatMessage({ id: "Search_Invoices" })}
              onChange={(val) => {
                let value = val.target.value;
                if (value && value !== "") {
                  debounceFilter({
                    ...queryFilter,
                    invoice_number: val.target.value,
                  });
                  setGlobalFilter(value);
                } else {
                  let updatedFilter = { ...queryFilter };
                  delete updatedFilter.invoice_number;
                  debounceFilter(updatedFilter);
                  setGlobalFilter("");
                }
              }}
            />
          </div>
        </div>

        {/* Table Starts */}

        <>
          {/* <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table> */}
          <div
            className="cp-tab__table-wrapper cp__invoice__table--modifear"
            // style={{
            //   //VIGNESH CSS TEMP
            //   height: "100%",
            // }}
            ref={ref}
            {...getTableProps()}
          >
            <div className="table-main__head-row">
              {headerGroups.map((headerGroup) => (
                <>
                  <div
                    className="table-main__head-container"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    <div className="table-main__head-row access_nth_child">
                      {headerGroup.headers.map((column, index) => (
                        <div
                          className="table-main__head-cell"
                          {...column.getHeaderProps()}
                        >
                          <Label2
                            style={{
                              userSelect: "none",
                              width: "100%",
                            }}
                          >
                            {column.render("Header")}
                          </Label2>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            </div>
            <div className="--cp__invoice__body" {...getTableBodyProps()}>
              {!isFetched || isLoading ? (
                <Loader />
              ) : page && page.length === 0 ? (
                <p
                  style={{
                    paddingTop: "10px",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                >
                  No Records Found
                </p>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps();

                  return (
                    <React.Fragment key={rowProps.key}>
                      <div
                        className="cp-tab__table-body-content --inv_summery_tabel_body "
                        {...row.getRowProps()}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <div
                              // className="cp-tab__table-body-cell"
                              style={{ width: "92px" }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}

                        {row &&
                          row.original &&
                          row.original.is_system_generated && (
                            <>
                              <div className="inv_edit_btn_Wraper">
                                <RBACWrapper
                                  // role={EDIT_RECURRING_INVOICE}
                                  role={
                                    row &&
                                    row.original &&
                                    row.original.type === "CHARGE"
                                      ? EDIT_CHARGES
                                      : EDIT_INVOICE
                                  }
                                  type="PERMISSION"
                                >
                                  <IconButton
                                    size="mini"
                                    kind={InvIconButon.KIND.tertiary}
                                    onClick={() => {
                                      setAddSearchParams({
                                        customer: `${getParams}`,
                                        invoice: `invoice-form`,
                                        inv: `${
                                          row &&
                                          row.original &&
                                          row.original._id
                                        }`,
                                        type:
                                          row &&
                                          row.original &&
                                          row.original.type === "CHARGE"
                                            ? "EDIT-CHARGE"
                                            : "EDIT-INV",
                                      });
                                      // setSelectedInvoiceId(
                                      //   row && row.original && row.original._id
                                      // );
                                    }}
                                  >
                                    <i className="mx-icon-Vector-7 font_size_icon color_gr" />
                                  </IconButton>
                                </RBACWrapper>
                              </div>
                            </>
                          )}

                        {(currentDefaultFormatDetails &&
                          currentDefaultFormatDetails?.accounting_book ===
                            "CSV" &&
                          !row?.original?.is_system_generated) ||
                        (currentDefaultFormatDetails.accounting_book ===
                          "ROTESSA" &&
                          !row?.original?.is_system_generated) ? (
                          <>
                            <div className="inv_edit_btn_Wraper">
                              <RBACWrapper
                                role={EDIT_INVOICE}
                                type="PERMISSION"
                              >
                                <IconButton
                                  size="mini"
                                  kind={InvIconButon.KIND.tertiary}
                                  onClick={() => {
                                    setInvoiceEditModal(true);
                                    setInvoiceEditData(row && row.original);
                                  }}
                                >
                                  {" "}
                                  <i className="mx-icon-Vector-7 font_size_icon color_gr" />
                                </IconButton>
                              </RBACWrapper>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {/* (currentDefaultFormatDetails.accounting_book ===
                          "ROTESSA" &&
                          !row?.original?.is_system_generated) */}
                        {currentDefaultFormatDetails &&
                        currentDefaultFormatDetails?.accounting_book ===
                          "CSV" &&
                        row?.original?.is_system_generated &&
                        row?.original?.type === "CHARGE" ? (
                          <>
                            <div className="inv_edit_btn_Wraper --inv_delete_modifi">
                              <RBACWrapper
                                role={DELETE_INVOICE}
                                type="PERMISSION"
                              >
                                <IconButton
                                  size="mini"
                                  kind={InvIconButon.KIND.tertiary}
                                  onClick={() => {
                                    handleModal({
                                      // title: "",
                                      content:
                                        "This will Delete the Invoice Permanently, Would you like to Continue",
                                      successCallback: () => {
                                        deleteChargesInv.mutate({
                                          invoiceId: row?.original?._id,
                                        });
                                      },
                                      formContent: ({ handleModal }) => {
                                        return <></>;
                                      },
                                      cancelCallback: () => {},
                                      isChildren: false,
                                      image: warningImage,
                                      buttonText: "Delete",
                                      closeButtonText: "Cancel",
                                      isCloseAble: false,
                                    });
                                  }}
                                >
                                  <i class="mx-icon-delete_FILL0_wght400_GRAD0_opsz48-2 ft_sz_22 hover_color_dlt" />
                                </IconButton>
                              </RBACWrapper>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      {row.isExpanded &&
                        renderRowSubComponent({
                          row,
                          rowProps,
                          visibleColumns,
                        })}
                    </React.Fragment>
                  );
                })
              )}
            </div>
          </div>
        </>
      </div>

      {/* Table Ends */}

      <div className="cp-tab__table-pagination">
        <PaginationWraper
          overrides={{
            Root: {
              style: () => ({
                backgroundColor: "#000",
              }),
            },
          }}
          pageSize={pageSize}
          pageCount={pageCount}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
        />
      </div>
      <InvoiceEditModal
        invoiceEditModal={invoiceEditModal}
        setInvoiceEditModal={setInvoiceEditModal}
        invoiceEditData={invoiceEditData}
        setInvoiceEditData={setInvoiceEditData}
      />
    </div>
  );
};

export default Invoice;
