const LocationFL = () => {
  return (
    <>
      <>
        <svg
          width="20"
          height="20"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3343 27.7639C18.1597 27.7639 17.9949 27.7448 17.8397 27.7065C17.6846 27.6682 17.5432 27.5981 17.4157 27.496C14.2261 25.0209 11.8594 22.6415 10.3156 20.3577C8.77188 18.074 8 15.8094 8 13.5639C8 11.8235 8.31258 10.2975 8.93774 8.98606C9.5629 7.6746 10.3731 6.57233 11.3682 5.67925C12.3634 4.78617 13.4731 4.11635 14.6974 3.66981C15.9217 3.22327 17.1337 3 18.3335 3C19.5333 3 20.7456 3.22327 21.9704 3.66981C23.1952 4.11635 24.3052 4.78617 25.3003 5.67925C26.2955 6.57233 27.1056 7.6746 27.7308 8.98606C28.3559 10.2975 28.6685 11.8235 28.6685 13.5639C28.6685 15.8094 27.8966 18.074 26.3529 20.3577C24.8091 22.6415 22.4424 25.0209 19.2529 27.496C19.1253 27.5981 18.9822 27.6682 18.8237 27.7065C18.6652 27.7448 18.5021 27.7639 18.3343 27.7639ZM18.3377 16.0135C19.0754 16.0135 19.7058 15.7508 20.2289 15.2255C20.752 14.7001 21.0135 14.0686 21.0135 13.3308C21.0135 12.5931 20.7508 11.9627 20.2255 11.4396C19.7001 10.9165 19.0686 10.655 18.3308 10.655C17.5931 10.655 16.9627 10.9177 16.4396 11.443C15.9165 11.9684 15.655 12.5999 15.655 13.3377C15.655 14.0754 15.9177 14.7058 16.443 15.2289C16.9684 15.752 17.5999 16.0135 18.3377 16.0135ZM9.14825 33.62C8.82291 33.62 8.5502 33.5093 8.33012 33.288C8.11004 33.0666 8 32.7923 8 32.4651C8 32.1378 8.11004 31.8657 8.33012 31.6488C8.5502 31.4319 8.82291 31.3235 9.14825 31.3235H27.5203C27.8456 31.3235 28.1183 31.4342 28.3384 31.6555C28.5585 31.8769 28.6685 32.1512 28.6685 32.4784C28.6685 32.8057 28.5585 33.0778 28.3384 33.2947C28.1183 33.5116 27.8456 33.62 27.5203 33.62H9.14825Z"
            fill="#516BEB"
          />
        </svg>
      </>
    </>
  );
};

export default LocationFL;
