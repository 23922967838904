import React from 'react'

const MinusOutline = ({size=18,color="#516BEB"}) => {
  return (
<svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.6875 9.56246C4.52813 9.56246 4.39453 9.50854 4.28672 9.40069C4.17891 9.29282 4.125 9.15917 4.125 8.99974C4.125 8.84029 4.17891 8.70672 4.28672 8.59903C4.39453 8.49134 4.52813 8.4375 4.6875 8.4375H13.3125C13.4718 8.4375 13.6054 8.49142 13.7132 8.59927C13.8211 8.70714 13.875 8.84079 13.875 9.00022C13.875 9.15967 13.8211 9.29324 13.7132 9.40093C13.6054 9.50862 13.4718 9.56246 13.3125 9.56246H4.6875Z" fill={color}/>
</svg>

  )
}

export default MinusOutline