import React, { useContext } from "react";
import { useQuery } from "react-query";
import { getOrganizationDashboard } from "../../services/organization";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { LabelLarge, ParagraphMedium } from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDashBoardNeedActionFilterInvoice } from "../../redux/invoiceSummary/action";
import { setDashBoardNeedActionFilter } from "../../redux/customerSummary/action";

const NeedsAction = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  let { currentOrganization, appAccessRest } = useContext(MaxyfiContext);

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `AGENT_DASHBOARD_${currentOrganization}_NEEDS_ACTION`,
      {
        filters: {
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "NEEDS_ACTION",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 1800000,
    }
  );

  let needsAction =
    data &&
    data.data &&
    data.data.doc &&
    data.data.doc.needs_action &&
    Object.keys(data.data.doc.needs_action).reduce((preVal, currVal) => {
      let totalValue = [...preVal];
      if (data.data.doc.needs_action[currVal].customers) {
        let type = data.data.doc.needs_action[currVal];
        totalValue.push({ type, actionKey: currVal, resource: "customers" });
      }
      if (data.data.doc.needs_action[currVal].invoices) {
        let type = data.data.doc.needs_action[currVal];
        totalValue.push({ type, actionKey: currVal, resource: "invoices" });
      }
      return totalValue;
    }, []);

  return (
    <div className="db-action__table">
      <div className="db-action__table-head">
        <div className="db-action__head-border"></div>
        <LabelLarge $style={{ marginLeft: "20px" }}>
          Needs Action{" "}
          {/* <span style={{ fontSize: "13px", color: "#516beb" }}>
                (32 inv / $ 32,000.00)
              </span> */}
        </LabelLarge>
      </div>

      <div className="db-action__table-body-container">
        {data &&
          data.data &&
          data.data.doc &&
          data.data.doc.needs_action &&
          needsAction.map((actionKey) => {
            const resource = actionKey.resource;
            const count =
              actionKey.resource == "customers"
                ? actionKey.type.count
                : actionKey.type.inv_count;

            if (count <= 0) {
              return <></>;
            }

            return (
              <div
                className="db-action__table-body"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (resource == "invoices") {
                    navigate(`/invoices`);
                    dispatch(
                      setDashBoardNeedActionFilterInvoice({
                        key: actionKey.actionKey,
                        path: "INVOICE",
                      })
                    );
                  } else {
                    navigate(`/customers`);
                    dispatch(
                      setDashBoardNeedActionFilter({
                        key: actionKey.actionKey,
                        path: "CUSTOMER",
                      })
                    );
                  }

                  // dispatch(
                  //   setCustomerSummaryFilter({
                  //     filters: {},
                  //     formFilters: [],
                  //   })
                  // );
                }}
              >
                <ParagraphMedium className="db-action__table-body-cell db-action__table-body-cell__name">
                  <FormattedMessage id={actionKey.actionKey}>
                    {actionKey.actionKey}
                  </FormattedMessage>
                </ParagraphMedium>
                <ParagraphMedium className="db-action__table-body-cell">
                  <FormattedMessage id={actionKey.actionKey}>
                    {actionKey.actionKey}
                  </FormattedMessage>{" "}
                  {actionKey.type.type
                    ? actionKey.type.type === "DUE"
                      ? "Due "
                      : "Due & Overdue "
                    : ""}
                  for {`${count} ${intl.formatMessage({ id: resource })}`}
                </ParagraphMedium>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NeedsAction;
