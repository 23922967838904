import {
  ADD_PREDEFINED_ADHOC_UPLOAD_DATA,
  PREDEFINED_DEFAULT_DATA,
  PREDEFINED_INITIALSTATE_STATE,
  SET_AU_FILTER,
  ADHOC_FILTER,
  PREDEFINED_VALIDATION_ADHOC_FILTER,
  PREDEFINED_BUSINESS_UNIT_DATA,
  UPDATE_MAPPING_FIELDS,
} from "./action";
import moment from "moment";

const initialState = {
  mappingFieldList: [],
  defaultSystemFields: [],
  defaultFields: [],
  requiredFields: [],
  systemFields: [],
  requiredDefaultFields: [],
  requiredFormatFields: [],
  adhocRefetchActions: 0,
  validDefaultFields: [],
  tableCurrentState: {
    filters: {},
    formFilters: [
      {
        id: [],
      },
    ],
  },
  adhocFilter: false,
  defaultRequiredFields: [],
  fieldMessage: [],
  businessUnitRd: [],
};

const predefinedUploadReducer = (
  state = { ...initialState },
  { type, payload }
) => {
  switch (type) {
    case ADHOC_FILTER: {
      return {
        ...state,
        adhocFilter: payload,
      };
    }

    case UPDATE_MAPPING_FIELDS: {
      if (
        payload?.mappingFieldList &&
        Array.isArray(payload.mappingFieldList)
      ) {
        return {
          ...state,
          mappingFieldList: payload.mappingFieldList,
        };
      }
    }

    case ADD_PREDEFINED_ADHOC_UPLOAD_DATA:
      if (payload.defaultFields) {
        payload.requiredDefaultFields = [...payload.defaultFields];
      }
      return {
        ...state,
        ...payload,
      };

    case PREDEFINED_DEFAULT_DATA: {
      let newValidDefaultFields = [...state.defaultFields];
      let newReqFields = [...state.fieldMessage];

      newValidDefaultFields[payload.index].default.value = payload.defaultData;

      // if (
      //   state.defaultFields[payload.index].id == "invoice_date" &&
      //   !payload.defaultData.startsWith("{{") &&
      //   !payload.defaultData.endsWith("}}")
      // ) {
      //   let isDateVaied = moment(
      //     payload.defaultData,
      //     state.organizationDtl.date_format,
      //     true
      //   ).isValid();

      //   if (!isDateVaied) {
      //     newReqFields.push({
      //       id: "invoice_date",
      //       message: `Invoice Date is not as per the format ${state.organizationDtl.date_format}`,
      //     });
      //   } else {
      //     newReqFields = newReqFields.filter((e) => e.id != "invoice_date");
      //   }
      // }

      // if (
      //   state.defaultFields[payload.index].id == "invoice_due_date" &&
      //   !payload.defaultData.startsWith("{{") &&
      //   !payload.defaultData.endsWith("}}")
      // ) {
      //   let isDateVaied = moment(
      //     payload.defaultData,
      //     state.organizationDtl.date_format,
      //     true
      //   ).isValid();

      //   if (!isDateVaied) {
      //     newReqFields.push({
      //       id: "invoice_due_date",
      //       message: `Due Date entered is not as per the format ${state.organizationDtl.date_format}`,
      //     });
      //   } else {
      //     newReqFields = newReqFields.filter((e) => e.id != "invoice_due_date");
      //   }
      // }

      // let findInvDateFields = newValidDefaultFields.find(
      //   (e) => e.id == "invoice_date"
      // );

      // let findDueDateFields = newValidDefaultFields.find(
      //   (e) => e.id == "invoice_due_date"
      // );

      // if (
      //   findInvDateFields &&
      //   findInvDateFields.id &&
      //   !findInvDateFields.default.value.startsWith("{{") &&
      //   !findInvDateFields.default.value.endsWith("}}") &&
      //   findDueDateFields &&
      //   findDueDateFields.id &&
      //   !findDueDateFields.default.value.startsWith("{{") &&
      //   !findDueDateFields.default.value.endsWith("}}") &&
      //   newReqFields.filter((nr) =>
      //     ["invoice_date", "invoice_due_date"].includes(nr.id)
      //   ).length === 0
      // ) {
      //   let invDate = moment(
      //     findInvDateFields.default.value,
      //     state.organizationDtl.date_format
      //   );
      //   let dueDate = moment(
      //     findDueDateFields.default.value,
      //     state.organizationDtl.date_format
      //   );

      //   if (invDate.diff(dueDate, "days") > 0) {
      //     newReqFields.push({
      //       id: "invoice_date_gr",
      //       message: `Invoice Date has to be lesser than or equal to Due Date`,
      //     });
      //   } else {
      //     newReqFields = newReqFields.filter(
      //       (e) => e.id != "invoice_due_date_gr"
      //     );
      //   }

      //   if (dueDate.diff(invDate, "days") < 0) {
      //     newReqFields.push({
      //       id: "invoice_due_date_gr",
      //       message: `Due Date has to be greater than or equal to Invoice Date`,
      //     });
      //   } else {
      //     newReqFields = newReqFields.filter((e) => e.id != "invoice_date_gr");
      //   }
      // } else {
      //   newReqFields = newReqFields.filter(
      //     (e) => e.id != "invoice_due_date_gr" || e.id != "invoice_date_gr"
      //   );
      // }

      return {
        ...state,
        defaultFields: [...state.defaultFields],
        fieldMessage: [...newReqFields],
      };

      break;
    }

    case PREDEFINED_INITIALSTATE_STATE: {
      return {
        ...state,
        ...initialState,
        requiredFormatFields: [],
      };
    }

    case SET_AU_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case PREDEFINED_VALIDATION_ADHOC_FILTER: {
      // for default fields start
      let defaultFields = [];

      state.defaultFields.map((e) => {
        let findMappedFields = state.mappingFieldList.find(
          ({ system_field }) => system_field == e.id
        );

        if (
          !findMappedFields ||
          !findMappedFields.system_field ||
          e.id === "phone_code"
        ) {
          // if (e.id != "currency") {
          defaultFields.push(e);
          // }
        }
      });

      let customerCurrency = state.mappingFieldList.find(
        ({ system_field }) => system_field == "customer_currency"
      );

      // if (!customerCurrency?.upload_field) {
      //   let deleteCustomerCurrency = state.defaultFields?.find(
      //     (e) => e.id == "currency"
      //   );
      //   defaultFields.push({
      //     ...deleteCustomerCurrency,
      //     is_input: true,
      //     default: {
      //       ...deleteCustomerCurrency.default,
      //       reference: "currency",
      //       value: deleteCustomerCurrency?.default?.value
      //         ? deleteCustomerCurrency?.default?.value
      //         : state?.organizationDtl?.default_currency,
      //     },
      //   });
      // }

      let newDefaultFields = defaultFields.map((e) => {
        if (e.id === "currency") {
          //check if currency is mapped
          return {
            ...e,
            default: {
              ...e.default,
              value: e?.default?.value
                ? e?.default?.value
                : state?.organizationDtl?.default_currency,
            },
          };
        }

        if (e.id === "phone_code") {
          //check if currency is mapped
          return {
            ...e,
            default: {
              ...e.default,
              value: e?.default?.value
                ? e?.default?.value
                : state?.organizationDtl?.phone?.code,
            },
          };
        }

        if (e.id === "payment_currency") {
          //check if currency is mapped
          return {
            ...e,
            default: {
              ...e.default,
              value: e?.default?.value
                ? e?.default?.value
                : state?.organizationDtl?.default_currency,
            },
          };
        }

        if (e.id === "contact_type") {
          return {
            ...e,
            default: {
              ...e.default,
              value: "primary_contact",
            },
          };
        }

        if (e.id === "invoice_date") {
          //check if due date id mapped
          let dueDate = defaultFields.find(
            ({ id }) => id === "invoice_due_date"
          );
          if (!dueDate) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "{{Due_Date}}",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "{{Today}}",
                tags: ["{{Today}}"],
              },
              is_create: true,
            };
          }
        }

        if (e.id === "business_location") {
          //check if due date id mapped
          let businessLocation = defaultFields.find(
            ({ id }) => id === "business_location"
          );
          if (!businessLocation) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "ALL",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "ALL",
              },
              is_create: true,
            };
          }
        }

        if (e.id === "business_unit") {
          //check if due date id mapped
          let businessUnit = defaultFields.find(
            ({ id }) => id === "business_unit"
          );
          let findBusinessUnit =
            state && state.businessUnitRd.find(({ label }) => label == "ALL");
          if (!businessUnit) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : findBusinessUnit?.id,
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : findBusinessUnit?.id,
              },
              is_create: true,
            };
          }
        }

        if (e.id === "invoice_due_date") {
          //check if inv due date id mapped
          let invDate = defaultFields.find(({ id }) => id === "invoice_date");
          if (!invDate) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "{{Invoice_Date}}",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "{{Today}}",
                tags: ["{{Today}}"],
              },
              is_create: true,
            };
          }
        }

        if (e.id === "due_amount") {
          //check if inv total amount id mapped
          let dueAmount = state.mappingFieldList.find(
            ({ system_field }) => system_field === "invoice_total_amount"
          );
          if (dueAmount) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "{{Invoice_Amount}}",
              },
              is_field_disabled: true,
            };
          }
        }

        if (e.id === "customer_name") {
          //check if inv total amount id mapped
          let customerName = state.mappingFieldList.find(
            ({ system_field }) => system_field === "contact_first_name"
          );
          if (customerName) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value
                  ? e.default.value
                  : "{{First_name+Last_name}}",
              },
            };
          }
        }

        if (e.id === "invoice_total_amount") {
          //check if due amount id mapped
          let invTotalAmount = state.mappingFieldList.find(
            ({ system_field }) => system_field === "due_amount"
          );
          if (invTotalAmount) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value ? e.default.value : "{{Due_Amount}}",
              },
              is_field_disabled: true,
            };
          }
        }

        if (e.id === "invoice_number") {
          //check if customer id mapped
          let customerId = state.mappingFieldList.find(
            ({ system_field }) => system_field === "customer_id"
          );
          if (customerId) {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value
                  ? e.default.value
                  : "{{Invoice_Num_Auto}}",
              },
              is_create: true,
            };
          } else {
            return {
              ...e,
              default: {
                ...e.default,
                value: e.default.value
                  ? e.default.value
                  : "{{Invoice_Num_Auto}}",
                tags: ["{{Invoice_Num_Auto}}", "{{Invoice_Num_From_CustName}}"],
              },
              is_create: true,
            };
          }
        }

        return e;
      });

      return {
        ...state,
        defaultFields: [...newDefaultFields],
      };
    }

    case PREDEFINED_BUSINESS_UNIT_DATA: {
      return {
        ...state,
        businessUnitRd: [...payload],
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default predefinedUploadReducer;
