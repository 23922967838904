import React, { useEffect } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { isOpenModal } from "../../redux/actions";

function RevenueBillingLink(props) {
  // cell: {
  //   row: { original },
  // },
  const location = useLocation();

  let dispatch = useDispatch();
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;

  value = _.get(original, accessor, "");

  const navigate = useNavigate();

  const [visible, setVisible] = React.useState(false);
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  // const showActiveUser = addSearchParams.get("customers") === `${original._id}`;

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return (
    <>
      {visible ? (
        // <RBACWrapper
        //   type="SCALE"
        //   enabled={original.is_active}
        //   placement="bottom"
        // >
        <StatefulTooltip accessibilityType={"tooltip"} content={value}>
          <Paragraph2
            ref={ref}
            onClick={() => {
              navigate(`/customers/${original?.customer_id}`);
              dispatch(isOpenModal(false));
            }}
            $style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
              width: "100%",
              textAlign: alignment,
              margin: "5px",
              color: "#516beb",
              textDecoration: "underline",
            }}
          >
            <div className="elip" ref={ref}>
              {value}
            </div>
          </Paragraph2>
        </StatefulTooltip>
      ) : (
        // </RBACWrapper>
        // <StatefulTooltip accessibilityType={"tooltip"} content={value}>
        // <RBACWrapper
        //   type="SCALE"
        //   enabled={original.is_active}
        //   placement="bottom"
        // >
        <Paragraph2
          ref={ref}
          onClick={() => {
            navigate(`/customers/${original?.customer_id}`);
            dispatch(isOpenModal(false));
          }}
          $style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
            width: "100%",
            textAlign: alignment,
            margin: "5px",
            color: "#516beb",
            textDecoration: "underline",
          }}
        >
          {value}
        </Paragraph2>
        // </RBACWrapper>
        // </StatefulTooltip>
      )}
    </>
  );
}
export default RevenueBillingLink;
