import React from "react";

const OverdueSvg = () => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8838 1.40625C12.7792 1.40625 8.84264 3.03682 5.94022 5.93924C3.03779 8.84167 1.40723 12.7782 1.40723 16.8829C1.40723 17.256 1.55546 17.6139 1.81932 17.8777C2.08317 18.1416 2.44104 18.2898 2.81419 18.2898C3.18734 18.2898 3.54521 18.1416 3.80906 17.8777C4.07292 17.6139 4.22115 17.256 4.22115 16.8829C4.23425 14.3266 5.02073 11.8341 6.47717 9.73328C7.9336 7.63245 9.99178 6.02161 12.381 5.11263C14.7703 4.20365 17.3787 4.03909 19.8632 4.64059C22.3477 5.24209 24.592 6.58149 26.301 8.48263C28.0099 10.3838 29.1034 12.7576 29.4376 15.292C29.7719 17.8263 29.3312 20.4025 28.1737 22.6817C27.0161 24.9609 25.1958 26.8364 22.9522 28.0615C20.7086 29.2866 18.1467 29.804 15.6035 29.5455C15.4186 29.5281 15.2321 29.5475 15.0547 29.6026C14.8773 29.6576 14.7126 29.7472 14.57 29.8663C14.4274 29.9853 14.3098 30.1314 14.224 30.2961C14.1382 30.4608 14.0858 30.6408 14.0699 30.8259C14.0525 31.0108 14.0719 31.1973 14.1269 31.3747C14.182 31.552 14.2716 31.7168 14.3906 31.8593C14.5097 32.0019 14.6557 32.1195 14.8204 32.2053C14.9851 32.2912 15.1652 32.3436 15.3502 32.3595C15.8567 32.3595 16.3632 32.4298 16.8838 32.4298C20.9978 32.4298 24.9433 30.7955 27.8523 27.8865C30.7613 24.9775 32.3956 21.032 32.3956 16.918C32.3956 12.804 30.7613 8.85857 27.8523 5.94954C24.9433 3.04052 20.9978 1.40625 16.8838 1.40625V1.40625Z"
        fill="#FD372A"
      />
      <path
        d="M16.8835 7.03516C16.5104 7.03516 16.1525 7.18339 15.8887 7.44725C15.6248 7.7111 15.4766 8.06897 15.4766 8.44212V16.8839C15.4766 17.2571 15.6248 17.6149 15.8887 17.8788C16.1525 18.1426 16.5104 18.2909 16.8835 18.2909H22.5114C22.8845 18.2909 23.2424 18.1426 23.5063 17.8788C23.7701 17.6149 23.9183 17.2571 23.9183 16.8839C23.9183 16.5108 23.7701 16.1529 23.5063 15.889C23.2424 15.6252 22.8845 15.4769 22.5114 15.4769H18.2905V8.44212C18.2905 8.06897 18.1423 7.7111 17.8784 7.44725C17.6145 7.18339 17.2567 7.03516 16.8835 7.03516Z"
        fill="#FD372A"
      />
      <path
        d="M9.84908 23.9176V18.2898C9.84908 17.9166 9.70085 17.5588 9.43699 17.2949C9.17314 17.031 8.81527 16.8828 8.44212 16.8828C8.06897 16.8828 7.7111 17.031 7.44725 17.2949C7.18339 17.5588 7.03516 17.9166 7.03516 18.2898V23.9176C7.03516 24.2908 7.18339 24.6486 7.44725 24.9125C7.7111 25.1764 8.06897 25.3246 8.44212 25.3246C8.81527 25.3246 9.17314 25.1764 9.43699 24.9125C9.70085 24.6486 9.84908 24.2908 9.84908 23.9176Z"
        fill="#FD372A"
      />
      <path
        d="M8.44248 32.3576C9.60804 32.3576 10.5529 31.4127 10.5529 30.2472C10.5529 29.0816 9.60804 28.1367 8.44248 28.1367C7.27691 28.1367 6.33203 29.0816 6.33203 30.2472C6.33203 31.4127 7.27691 32.3576 8.44248 32.3576Z"
        fill="#FD372A"
      />
    </svg>
  );
};

export default OverdueSvg;
