import { HeadingXLarge, ParagraphLarge, LabelLarge } from "baseui/typography";
import Email from "../../../../../assets/img/svg/CustomerTemplate/Email";
import Info from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import InfoTag from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import InfoPortal from "../../../../../assets/img/svg/InfoPortal";
import AllActionPopOver from "../AllActionPopOver";
import { isOpenModal, refetchActions } from "../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../components/TextBox";
import { TextButton, SIZE, KIND } from "../../../../../components/TextButton";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { TextArea } from "../../../../../components/TextArea";
import Sms from "../../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import { SelectBox } from "../../../../../components/SelectBox";
import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import React, { useContext, useState, useEffect } from "react";
import { COMMUNICATION_TEMPLATE, NEW } from "../../../../../constants";
import { useQuery } from "react-query";
import { ParagraphSmall } from "baseui/typography";
import CheckBoxBaseweb from "../../../../../components/CheckBoxBaseweb";
import moment from "moment";
import "moment-timezone";
import {
  getCommunicationTemplateList,
  getHolidayBlackoutHolidayDate,
  postDisputeData,
} from "../../../../../services";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { useSelector } from "react-redux";
import getSelectValues from "../../../../../utils/getSelectValues";
import Dispute from "../../../../../assets/img/svg/Dispute";
import DateRangeSelect from "../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import SelectDisputePopUp from "./SelectDisputePopUp/SelectDisputePopUp";
import DisputeTable from "../DisputeTable";
import { selectedInvoice } from "../../../../../redux/customerOverview/dispute/action";
import {
  changedDisputeData,
  emptyState,
  getDisputeDatas,
  newDisputeData,
  resetDisputeState,
} from "../../../../../redux/customerOverview/dispute/action";
import { useParams, useSearchParams } from "react-router-dom";
import { getDisputeData } from "../../../../../services";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { putDisputeData } from "../../../../../services";
import setSelectValues from "../../../../../utils/setSelectValues";
import DisputeAdd from "../../../../../assets/img/svg/DisputeAdd";
import Loader from "../../../../../components/Loader";
import { customerInvoiceOverview } from "../../../../../services";
import { ADD_LIST_VALUES } from "../../../../../providers/RBACProvider/permissionList";
import { Icon, Typography } from "../../../../../components_v2";
import _ from "lodash";
const DisputeAction = ({ getParams, id, entity, invoiceId }) => {
  const [disData, setDisData] = useState({});

  //
  const {
    referenceData,
    currentOrganization,
    userInfo,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  // const [getSearchParams, setGetSearchParams] = useSearchParams();
  // const getParams = getSearchParams.get("customers");

  const [settingData, setSettingData] = useState(null);
  const intl = useIntl();
  const disputeTypeRedux = useSelector(
    (s) => s.customerDisputeReducer.disputeType
  );
  const dispatch = useDispatch();
  const [disputeData, setDisputeData] = useState(false);

  const [newDispute, setNewDispute] = useState(false);
  const [loading, setLoading] = useState(false);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);
  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});

  // useEffect(() => {
  //   const resetDisputeReduxState = () => {
  //     dispatch(resetDisputeState());
  //   };

  //   return resetDisputeReduxState;
  // }, []);

  // const [newDisputeData, setNewDisputeData] = useState("");
  //

  // const dummy = invoiceFullData.filter((e) =>
  //   disputeInvoiceSelectedRowData.includes(e._id)
  // );
  //
  // const { id } = useParams();s

  // const { data, isFetched, isError } = useQuery(
  //   [
  //     `INVOICE_ROW_DISPUTE_DATA${id}`,
  //     {
  //       pageIndex: queryPageIndex,
  //       pageSize: queryPageSize,
  //       sortBy: querySortBy,
  //       filters: queryFilter,
  //     },
  //   ],
  //   async ({ queryKey }) => {
  //     let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
  //     filters = {
  //       ...filters,
  //       customer_id: id,
  //     };
  //     return await customerInvoiceOverview({
  //       pageIndex,
  //       sortBy,
  //       pageSize,
  //       filters,
  //       organization: currentOrganization,
  //     });
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  // const invoiceFullData = (data && data.data.docs) || [];
  //

  // useEffect(() => {
  //   dispatch(selectedInvoice(invoiceFullData));
  // }, []);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let { review_date } = watch();

  const disputeInputValues = useSelector(
    (s) => s.customerDisputeReducer.disputeData
  );

  const { inv_cus_id } = useSelector((s) => s.invoiceOverviewReducer);

  const currentTableData = useSelector(
    (s) => s.customerDisputeReducer.currentDisputeData
  );

  const disputeTypeValue = useSelector(
    (s) => s.customerDisputeReducer.disputeType
  );

  let disputeType = watch("disputes");

  // dispatch(changedDisputeData(disputeType));
  disputeInputValues?.sort((a, b) => {
    return b.id.localeCompare(a.id);
  });

  // const { data, isFetched, isError, isLoading } = useQuery(
  //   [
  //     COMMUNICATION_TEMPLATE,
  //     {
  //       filters: { type: "sms" },
  //     },
  //   ],
  //   async ({ queryKey }) => {
  //     let { page, page_size, sortBy, filters, type } = queryKey[1];
  //     return await getCommunicationTemplateList({
  //       organization: currentOrganization,
  //       disputeId: id,
  //       // clientCode: userInfo.organization_list.map((org) => org.id),
  //       filters,
  //       type,
  //     }).data.doc;
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  //

  // const getDisputes = async () => {
  //   setLoading(true);
  //   const disputeDatas = (
  //     await getDisputeData({
  //       organization: currentOrganization,
  //       disputeId: getParams,
  //     })
  //   ).data.doc;
  //   // setDisputeData(disputeDatas);
  //   dispatch(getDisputeDatas(disputeDatas));
  //   setLoading(false);
  // };

  const blackoutHolidayData = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: review_date
            ? [
                moment(review_date)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: inv_cus_id,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: review_date ? true : false,
    }
  );

  const { isLoading, data, isFetching, refetch } = useQuery(
    `${entity}-DISPUTE-${getParams}`,
    async () => {
      return await getDisputeData({
        organization: currentOrganization,
        disputeId: getParams,
        id: id,
        invoiceId: invoiceId,
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // setDisData(data&&data.data&&data.data.doc)

  useEffect(async () => {
    //TODO: PRAVEEN FAILURE ERROR HANDLING
    // getDisputes();
    if (data && data.data && data.data.doc) {
      dispatch(getDisputeDatas(data && data.data && data.data.doc));
    } else {
      dispatch(getDisputeDatas([]));
    }
  }, [data, isLoading]);

  const currentDisputeValues = useSelector(
    (s) => s.customerDisputeReducer.currentDisputeData
  );

  useEffect(() => {
    if (
      // !data&&
      currentDisputeValues &&
      currentDisputeValues.id
      // S &&
      // disputeInputValues != []
    ) {
      setValue("disputes", [
        {
          label: `${currentDisputeValues?.id} ${
            referenceData["dispute_type"] &&
            referenceData["dispute_type"].find(
              (e) => e.id === currentDisputeValues?.type
            )?.label
          }`,
          id: currentDisputeValues?.id,
        },
      ]);
      setValue("details", currentDisputeValues?.details);

      setValue("type", setSelectValues(currentDisputeValues?.type));

      setValue(
        "review_date",
        currentDisputeValues?.review_date
          ? new Date(currentDisputeValues?.review_date)
          : null
      );
      setValue("status", setSelectValues(currentDisputeValues?.status));

      setValue("comment", currentDisputeValues?.comment);
    } else {
      if (currentDisputeValues && !currentDisputeValues?.invoices) {
        setValue("disputes", []);
        setValue("details", "");
        setValue("type", setSelectValues(""));
        setValue("review_date", null);
        setValue("status", setSelectValues([]));
        setValue("comment", "");
      }
    }
  }, [currentDisputeValues]);

  const clearValue = () => {
    setValue("details", "");
    setValue("disputes", []);
    setValue("status", []);
    setValue("type", []);
    setValue("review_date", "");
    setValue("comment", "");
  };

  const putDisputeValues = useMutation(
    (data) =>
      putDisputeData({
        organization: currentOrganization,
        customerId: getParams,
        data,
        disputeId: currentDisputeValues && currentDisputeValues._id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        // setNewDispute(false);
        refetch();
        dispatch(isOpenModal(false));
      },
    }
  );

  const postDisputeValues = useMutation(
    (data) =>
      postDisputeData({
        organization: currentOrganization,
        customerId: getParams,
        data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        setNewDispute(false);
        refetch();
        // getDisputes();
        dispatch(isOpenModal(false));
        dispatch(refetchActions());
      },
    }
  );

  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const timeZone = orgRef?.time_zone;

    if (disputeTypeValue === NEW || disputeInputValues?.length == 0) {
      let { disputes, invoices, ...rest } = data;
      let values = {
        ...rest,
        details: data.details,
        ...(data?.review_date
          ? {
              review_date: moment
                .tz(
                  `${data?.review_date?.getFullYear()}-${
                    data?.review_date?.getMonth() + 1
                  }-${data?.review_date?.getDate()}`,
                  "YYYY-MM-DD",
                  timeZone
                )
                .utc()
                .valueOf(),
            }
          : {}),
        status: data.status.id,
        comment: data.comment,
        type: getSelectValues(data.type),
        status: getSelectValues(data.status),
        invoices:
          !currentDisputeValues.invoices ||
          currentDisputeValues?.invoices?.length === 0
            ? []
            : currentDisputeValues?.invoices,
      };

      await postDisputeValues.mutateAsync({ ...values });
    } else {
      let { disputes, review_date, ...rest } = data;

      let values = {
        ...rest,
        details: data.details,
        ...(data?.review_date
          ? {
              review_date: moment
                .tz(
                  `${data?.review_date?.getFullYear()}-${
                    data?.review_date?.getMonth() + 1
                  }-${data?.review_date?.getDate()}`,
                  "YYYY-MM-DD",
                  timeZone
                )
                .utc()
                .valueOf(),
            }
          : {}),

        type: getSelectValues(data.type),
        status: getSelectValues(data.status),
        invoices: currentTableData?.invoices,
        // invoices:
        //   !currentDisputeValues.invoices ||
        //   currentDisputeValues?.invoices?.length === 0
        //     ? []
        //     : currentDisputeValues?.invoices,
        // invoices: invoiceTableData,
        comment: data.comment,
      };

      await putDisputeValues.mutateAsync({ ...values });
    }

    // await putDisputeValues.mutateAsync(datas);
  };

  if (loading) {
    return <Loader />;
  }

  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <form
      style={{
        // gridColumn: "2/5",
        // width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        <div
          // onSubmit={handleSubmit(onSubmit)}
          style={{
            // display: "grid",
            // gridTemplateColumns: "repeat(10,50fr)",
            // gridTemplateRows: "repeat(12,10fr)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ padding: "15px 35px" }}>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ textDecoration: "underline", color: "#516BEB" }}
                onClick={() => {
                  dispatch(emptyState());
                  dispatch(isOpenModal(false));
                }}
              >
                <ParagraphLarge
                  style={{
                    color: "#516BEB",
                    cursor: "pointer",
                    gap: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <LeftSmallArrow />
                  </div>
                  Switch Back
                </ParagraphLarge>
              </div>
              <div style={{ width: "150px" }}>
                <AllActionPopOver />
              </div>
            </div> */}
            {/* Section 1 */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingTop: "5px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <Dispute />
                <HeadingXLarge>Dispute</HeadingXLarge>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {/* <LabelLarge style={{ color: "#ADADAD" }}>$26.00</LabelLarge> */}
                {/* <InfoPortal /> */}
              </div>
            </div>
          </div>
          {/* <form
        style={{ padding: "15px 35px", height: "100%", overflow: "scroll" }}
      > */}
          <div
            style={{
              // height: "100%",
              height: "39vh",
              overflow: "scroll",
              // padding: "15px 35px",
            }}
          >
            <div style={{ padding: "15px 35px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* disputeInputValues?.length > 0 ? */}
                {
                  disputeTypeRedux === NEW ? (
                    <></>
                  ) : disputeInputValues?.length === 0 ? (
                    <></>
                  ) : (
                    <>
                      <div
                      // style={{ width: "270px" }}
                      >
                        <Controller
                          defaultValues={[]}
                          name="disputes"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              size={SIZE.default}
                              {...field}
                              name={field.name}
                              error={
                                errors[field.name] && errors[field.name].message
                              }
                              label={intl.formatMessage({
                                id: "disputes",
                              })}
                              placeholder={intl.formatMessage({
                                id: "disputes",
                              })}
                              requiredAstric={true}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);

                                dispatch(changedDisputeData(e.value[0].id));
                              }}
                              options={
                                disputeInputValues &&
                                disputeInputValues.length &&
                                disputeInputValues.map((i) => {
                                  return {
                                    label: `${i?.id} ${
                                      referenceData["dispute_type"] &&
                                      referenceData["dispute_type"]?.find(
                                        (e) => e?.id === i?.type
                                      )?.label
                                    }`,
                                    id: i._id,
                                  };
                                })
                              }
                            />
                          )}
                        />
                      </div>
                    </>
                  )
                  //  : (
                  //   <div></div>
                  // )
                }

                {disputeTypeRedux !== NEW ? (
                  <div
                    style={{
                      color: "#516BEB",
                      display: "flex",
                      gap: "3px",
                      // textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      clearValue();
                      dispatch(newDisputeData(NEW));
                    }}
                  >
                    <span>
                      <DisputeAdd />
                    </span>{" "}
                    Add Another Dispute
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ paddingTop: "10px" }}>
                <Controller
                  defaultValues={""}
                  name="details"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <TextArea
                      size={SIZE.default}
                      requiredAstric={true}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={
                        disputeTypeRedux === NEW ? (
                          <>
                            {intl.formatMessage({
                              id: "capture_details",
                            })}
                          </>
                        ) : (
                          <>
                            {intl.formatMessage({
                              id: "details",
                            })}
                          </>
                        )
                      }
                      placeholder={intl.formatMessage({
                        id: "details",
                      })}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                pattingTop: "15px",
                paddingLeft: "35px",
                paddingRight: "35px",
                gap: "10px",
              }}
            >
              <div style={{ width: "270px" }}>
                <Controller
                  defaultValues={[]}
                  name="type"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.default}
                      requiredAstric={true}
                      {...field}
                      creatable={creatablePermission ? true : false}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "dispute_type",
                      })}
                      placeholder={intl.formatMessage({
                        id: "dispute_type",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["dispute_type"]}
                    />
                  )}
                />
              </div>
              <div
              //  style={{ width: "250px" }}
              >
                <Controller
                  name="review_date"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <DateRangeSelect
                      requiredAstric={true}
                      clearable={false}
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "review_date",
                      })}
                      placeholder={intl.formatMessage({
                        id: " ",
                      })}
                      value={field.value}
                      onChange={(e) => field.onChange(e.date)}
                      minDate={new Date()}
                    />
                  )}
                />
              </div>
              <div style={{ width: "270px" }}>
                <Controller
                  defaultValues={[]}
                  name="status"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      size={SIZE.default}
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "dispute_status",
                      })}
                      placeholder={intl.formatMessage({
                        id: "dispute_status",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["dispute_status"]}
                    />
                  )}
                />
              </div>
            </div>
            <div style={{ padding: "0px 35px" }}>
              {_.get(blackoutHolidayData, "data.data.doc.is_holiday") && (
                <>
                  <div className="alert-holiday-section">
                    <Icon icon="alert_circle" color="#516BEB" />
                    <Typography
                      type="p"
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      The Date Selected{" "}
                      {_.get(
                        blackoutHolidayData,
                        "data.data.doc.holidays",
                        []
                      ).map((val) => {
                        return (
                          <>
                            {moment(val).format(
                              currentDefaultFormatDetails.date_format
                            )}
                          </>
                        );
                      })}{" "}
                      is a <b>“Holiday”</b>
                    </Typography>
                  </div>
                </>
              )}
            </div>
            {/* <div style={{ width: "180px" }}>
          <TextButton size={SIZE.default} fullWidth>
            Select Dispute Invoice
          </TextButton>
        </div> */}
            <div
              style={{
                paddingLeft: "35px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <SelectDisputePopUp getParams={getParams} />
            </div>
            <div
              style={{
                paddingTop: "15px",
                paddingLeft: "35px",
                paddingRight: "35px",
              }}
            >
              <DisputeTable getParams={getParams} />
            </div>

            {disputeTypeValue === NEW || disputeInputValues?.length == 0 ? (
              <>
                <div
                  style={{
                    padding: "15px 35px",
                  }}
                >
                  <Controller
                    defaultValues={false}
                    name="is_hold_workflow"
                    control={control}
                    render={({ field }) => (
                      <CheckBoxBaseweb {...field} checked={field.value}>
                        <ParagraphSmall style={{ color: "#333860" }}>
                          Pause Workflow till Review Date
                        </ParagraphSmall>
                      </CheckBoxBaseweb>
                    )}
                  />
                </div>
              </>
            ) : (
              <></>
            )}

            <div
              style={{
                paddingTop: "15px",
                paddingLeft: "35px",
                paddingRight: "35px",
              }}
            >
              <Controller
                defaultValues={""}
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextArea
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "user_comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "user_comments",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>

          <div
            style={{
              background: "#F9F9F9",
              // width: "100%",
              height: "70px",
              padding: "15px",
              borderTop: "1px solid #CDCED9",
              // gridRow: "12/13",
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {disputeTypeRedux === NEW ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => {
                        dispatch(emptyState());
                        dispatch(isOpenModal(false));
                      }}
                    >
                      Cancel
                    </TextButton>
                  </div>{" "}
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save
                    </TextButton>
                  </div>
                </div>
              ) : disputeInputValues?.length > 0 ? (
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => {
                        dispatch(emptyState());
                        dispatch(isOpenModal(false));
                      }}
                    >
                      Cancel
                    </TextButton>
                  </div>{" "}
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={SIZE.compact}
                      fullWidth
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save
                    </TextButton>
                  </div>
                </div>
              ) : (
                <div style={{ width: "125px", height: "36px" }}>
                  <TextButton
                    size={SIZE.compact}
                    fullWidth
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Capture
                  </TextButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DisputeAction;
