import React from "react";

const CallSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2364 14.2748L15.6912 13.9842C15.08 13.914 14.4788 14.1245 14.0479 14.5553L12.2042 16.3991C9.3685 14.9561 7.04383 12.6415 5.60092 9.79573L7.45465 7.94199C7.88552 7.51112 8.09594 6.90991 8.0258 6.29868L7.73522 3.77358C7.61497 2.76154 6.76326 2 5.7412 2H4.00771C2.87544 2 1.93354 2.9419 2.00368 4.07419C2.53475 12.6315 9.37853 19.4652 17.9257 19.9963C19.058 20.0665 19.9999 19.1246 19.9999 17.9923V16.2588C20.0099 15.2467 19.2484 14.395 18.2364 14.2748V14.2748Z"
        fill="#4B4F72"
      />
    </svg>
  );
};

export default CallSvg;
