export default function UnPin({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_414_4154)">
        <path
          d="M9.57583 2.59053C10.1421 2.02427 11.0824 1.98936 11.6972 2.59053L15.4095 6.30284C15.9952 6.88863 15.9952 7.83838 15.4095 8.42417C14.8432 8.99043 13.9029 9.03201 13.2881 8.42417L13.023 8.15887L10.8094 10.3725C11.4772 12.1799 11.0301 14.4103 9.57583 15.8488C9.41885 16.0041 9.17724 15.9839 9.0455 15.8488L5.86338 12.6666L3.47704 15.0533C3.33059 15.1997 3.09315 15.1997 2.94671 15.0533C2.81152 14.9181 2.78777 14.6832 2.94671 14.523L5.33313 12.1363L2.15121 8.95449C2.01603 8.81931 1.99002 8.58536 2.15121 8.42417C3.57009 7.00529 5.72522 6.49745 7.62713 7.19024L9.84076 4.97662L9.57583 4.71185C8.97139 4.1043 9.00957 3.15679 9.57583 2.59053ZM11.1668 3.12086C10.8739 2.82797 10.3991 2.82797 10.1062 3.12086C9.82868 3.39834 9.80234 3.8687 10.1062 4.18152L10.6365 4.71185C10.7717 4.84703 10.7907 5.08751 10.6365 5.24218L7.98594 7.8929C7.87892 7.99993 7.71805 8.03213 7.57808 7.97454C6.10293 7.3676 4.32032 7.54259 2.95763 8.70037L9.29926 15.042C10.4015 13.7667 10.6365 11.8472 10.0256 10.4222C9.95471 10.2569 10.0256 10.0954 10.1062 10.0144L12.7578 7.36352C12.893 7.22832 13.1254 7.2057 13.2881 7.36352L13.8185 7.89384C14.1114 8.18673 14.5862 8.18673 14.8791 7.89384C15.1566 7.61636 15.1863 7.148 14.8791 6.83317L11.1668 3.12086Z"
          fill={color}
        />
        <path
          d="M9.53677 2.59053C10.103 2.02427 11.0434 1.98936 11.6581 2.59053L15.3704 6.30284C15.9562 6.88863 15.9562 7.83838 15.3704 8.42417C14.8041 8.99043 13.8638 9.03201 13.2491 8.42417L12.9839 8.15887L10.7703 10.3725C11.4381 12.1799 10.991 14.4103 9.53677 15.8488C9.37979 16.0041 9.13817 15.9839 9.00644 15.8488L5.82431 12.6666L3.43797 15.0533C3.29153 15.1997 3.05409 15.1997 2.90764 15.0533C2.77246 14.9181 2.74871 14.6832 2.90764 14.523L5.29406 12.1363L2.11215 8.95449C1.97697 8.81931 1.95095 8.58536 2.11215 8.42417C3.53102 7.00529 5.68616 6.49745 7.58807 7.19024L9.80169 4.97662L9.53677 4.71185C8.93233 4.1043 8.97051 3.15679 9.53677 2.59053Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_414_4154">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
