import React from "react";

const ChevronLiftCircle = ({ size = 22, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11.5" r="10.5" fill="white" stroke="#CDCED9" />
      <path
        d="M8.72491 11.4996C8.72491 11.4163 8.73845 11.3392 8.76552 11.2684C8.79261 11.1975 8.83948 11.1288 8.90615 11.0621L12.5312 7.4371C12.652 7.31627 12.7978 7.25586 12.9687 7.25586C13.1395 7.25586 13.2853 7.31627 13.4061 7.4371C13.527 7.55793 13.5874 7.70585 13.5874 7.88085C13.5874 8.05585 13.527 8.20377 13.4061 8.32459L10.2311 11.4996L13.4061 14.6746C13.527 14.7954 13.5874 14.9413 13.5874 15.1121C13.5874 15.2829 13.527 15.4288 13.4061 15.5496C13.2853 15.6704 13.1374 15.7309 12.9624 15.7309C12.7874 15.7309 12.6395 15.6704 12.5187 15.5496L8.90615 11.9371C8.83948 11.8704 8.79261 11.8017 8.76552 11.7308C8.73845 11.66 8.72491 11.5829 8.72491 11.4996Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronLiftCircle;
