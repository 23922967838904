function ActionEdit({ width = 18, height = 18 }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5625 8.31458L14.9354 6.6875L15.6 6.02292C15.7222 5.90069 15.8826 5.83958 16.0812 5.83958C16.2799 5.83958 16.4403 5.90069 16.5625 6.02292L17.2271 6.6875C17.3493 6.80972 17.4104 6.97014 17.4104 7.16875C17.4104 7.36736 17.3493 7.52778 17.2271 7.65L16.5625 8.31458ZM9 14.25V12.6229L13.95 7.67292L15.5771 9.3L10.6271 14.25H9ZM0.75 9.4375V8.0625H7.625V9.4375H0.75ZM0.75 5.65625V4.28125H11.5208V5.65625H0.75ZM0.75 1.875V0.5H11.5208V1.875H0.75Z"
          fill="#ADADAD"
        />
      </svg>
    </>
  );
}

export default ActionEdit;
