import React, { useContext, useEffect, useState } from "react";
import { Select, SIZE, StyledValueContainer } from "baseui/select";
import { Tag, VARIANT } from "baseui/tag";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { FormControl } from "baseui/form-control";

const SimpleText = (props) => {
  return (
    <StyledValueContainer>
      <>
        {props.data &&
        props.data[0] &&
        props.data[0].props &&
        props.data[0].props.$isFocused ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              overflow: "scroll",
              height: "50px",
              paddingTop: "5px",
            }}
          >
            {props.data &&
              props.data.map((e) => (
                <Tag
                  variant={VARIANT.solid}
                  onActionClick={e.props.removeValue}
                >
                  {e.props.value.label}
                </Tag>
              ))}
          </div>
        ) : (
          <>
            {props.data ? (
              <Tag
                variant={VARIANT.solid}
                onActionClick={props.data[0].props.removeValue}
              >
                {props.data[0].props.value.label}
              </Tag>
            ) : null}
            {props.data && props.data.length > 1 ? (
              <Tag
                variant={VARIANT.solid}
                closeable={false}
                // onClick={(e) => {
                //   e.preventDefault();
                //   props.showAllValues(true);
                // }}
              >{`${props.data.length - 1} + `}</Tag>
            ) : null}
          </>
        )}
      </>
    </StyledValueContainer>
  );
};

export const MultiSelect = (props) => {
  //   const options = referenceData[rdName];

  const {
    error = "",
    children,
    value = "",
    label = "",
    requiredAstric = false,
    height = "",
    marginTop = "",
  } = props;

  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };
  let { currentOrganization, referenceData } = useContext(MaxyfiContext);

  const [selectedValues, setSelectedValue] = React.useState([]);
  const [isAllValueOpen, showAllValues] = React.useState(false);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);

  return (
    <>
      <FormControl
        {...props}
        error={error}
        overrides={{
          Caption: {
            style: ({ $theme }) => ({
              marginTop: "0px",
              marginBottom: "0px",
              marginLeft: "12px",
              textAlign: "left",
            }),
          },
        }}
      >
        <div className="mx-input">
          <label
            htmlFor={props.name}
            className={`mx-input__label mx-input__label--select  ${
              isFocused ? "mx-input__label--active text-primary" : ""
            } ${
              !isFocused && value.length > 0 ? "mx-input__label--active" : ""
            } ${error ? "text-error" : ""}`}
          >
            {label}{" "}
            {requiredAstric ? (
              <>
                <span style={{ color: "red" }}>*</span>
              </>
            ) : (
              <></>
            )}
          </label>
          <Select
            clearable={false}
            autoComplete="off"
            autoFill="off"
            maxDropdownHeight="250px"
            filterOutSelected={false}
            overrides={{
              Placeholder: {
                style: ({ $theme, $isFocused }) => ({
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                }),
              },

              ControlContainer: {
                style: ({ $theme, $isFocused, $error }) => ({
                  height: `${height ? height : "45px"}`,
                  // height: "45px",
                  borderRadius: "5px",
                  backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                  borderRadius: "3px",
                  borderColor: "#CDCED9",
                  borderWidth: "0.5px",
                  borderStyle: "solid",
                  paddingRight: "0px",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  borderWidth: "1px",
                  borderColor: $isFocused
                    ? $error
                      ? "#FD372A"
                      : "#516BEB"
                    : $error
                    ? "#FD372A"
                    : "#CDCED9",
                }),
              },
              Input: {
                style: () => ({
                  height: "45px",
                }),
              },
              Placeholder: {
                style: () => ({
                  // paddingTop: "6px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }),
              },
              Dropdown: {
                style: ({ $theme }) => ({
                  // outline: `${$theme.colors.warning200} solid`,
                  // backgroundColor: $theme.colors.warning200,
                  marginTop: marginTop,
                }),
              },

              ValueContainer: (props) => {
                let children = [
                  <SimpleText
                    data={props.children[0]}
                    showAllValues={showAllValues}
                    isAllValueOpen={isAllValueOpen}
                  />,
                  [...props.children.slice(1, props.children.length)],
                ];
                return children;
              },
            }}
            {...props}
            // size={SIZE.default}
            options={props.options}
            onChange={({ value }) => {
              let labels = value.map((e) => e.id);
              //   setFilter(labels);
              setSelectedValue(value);
              props.onChange(value);
            }}
            value={selectedValues}
            multi
          />
        </div>
      </FormControl>
    </>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
