export default function SMSStatus({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M6.02933 8.13319C6.2098 8.13319 6.36313 8.07042 6.48933 7.94486C6.61553 7.81933 6.67863 7.66633 6.67863 7.48587C6.67863 7.3054 6.61585 7.15207 6.4903 7.02587C6.36476 6.89967 6.21176 6.83657 6.0313 6.83657C5.85084 6.83657 5.69751 6.89934 5.57131 7.02488C5.44511 7.15043 5.38201 7.30343 5.38201 7.4839C5.38201 7.66436 5.44478 7.81769 5.57031 7.94389C5.69586 8.07009 5.84887 8.13319 6.02933 8.13319ZM9.00734 8.13319C9.18611 8.13319 9.3392 8.07042 9.4666 7.94486C9.59401 7.81933 9.65772 7.66633 9.65772 7.48587C9.65772 7.3054 9.59446 7.15207 9.46795 7.02587C9.34144 6.89967 9.18843 6.83657 9.00893 6.83657C8.82945 6.83657 8.6766 6.89934 8.55038 7.02488C8.42418 7.15043 8.36108 7.30343 8.36108 7.4839C8.36108 7.66436 8.4241 7.81769 8.55014 7.94389C8.67616 8.07009 8.82856 8.13319 9.00734 8.13319ZM11.9688 8.13319C12.1492 8.13319 12.3025 8.07042 12.4287 7.94486C12.5549 7.81933 12.618 7.66633 12.618 7.48587C12.618 7.3054 12.5553 7.15207 12.4297 7.02587C12.3042 6.89967 12.1512 6.83657 11.9707 6.83657C11.7903 6.83657 11.6369 6.89934 11.5107 7.02488C11.3845 7.15043 11.3214 7.30343 11.3214 7.4839C11.3214 7.66436 11.3842 7.81769 11.5097 7.94389C11.6353 8.07009 11.7883 8.13319 11.9688 8.13319ZM4.58806 12.9887L3.11832 14.4598C2.91495 14.6632 2.68213 14.7107 2.41988 14.6022C2.15762 14.4937 2.02649 14.2963 2.02649 14.0098V3.29014C2.02649 2.9355 2.15096 2.63371 2.39991 2.38476C2.64886 2.13582 2.95055 2.01135 3.30496 2.01135H14.6951C15.0495 2.01135 15.3512 2.13582 15.6001 2.38476C15.8491 2.63371 15.9736 2.93544 15.9736 3.28995V11.7101C15.9736 12.0646 15.8491 12.3663 15.6001 12.6153C15.3512 12.8642 15.0495 12.9887 14.695 12.9887H4.58806ZM4.15028 11.9394H14.6935C14.7512 11.9394 14.8041 11.9154 14.8522 11.8673C14.9003 11.8192 14.9243 11.7664 14.9243 11.7087V3.29138C14.9243 3.23369 14.9003 3.1808 14.8522 3.13272C14.8041 3.08464 14.7512 3.0606 14.6935 3.0606H3.30653C3.24883 3.0606 3.19595 3.08464 3.14787 3.13272C3.09978 3.1808 3.07574 3.23369 3.07574 3.29138V13.0082L4.15028 11.9394Z"
        fill={color}
      />
    </svg>
  );
}
