import React, { useContext, useEffect, useMemo, useState } from "react";
import { Icon, RDText, Typography } from "../../../../components_v2";
import { Controller, useForm } from "react-hook-form";
import { SIZE, TextBox } from "../../../../components/TextBox";
import { useIntl } from "react-intl";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useParams } from "react-router-dom";
import { useCustomerQuery } from "../../../../queries/useCustomer";
import _ from "lodash";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { SelectBox } from "../../../../components/SelectBox";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { useMutation, useQuery } from "react-query";
import {
  getMatchingConsumers,
  postBankruptchyData,
  editBankruptcyData,
  addAttorneyBankruptcy,
  postBankruptcyMaintanance,
  updateAttorney,
} from "../../../../services";
import getSelectValues from "../../../../utils/getSelectValues";
import moment from "moment";
import { KIND, TextButton } from "../../../../components/TextButton";
import setSelectValues from "../../../../utils/setSelectValues";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { useDebouncedCallback } from "use-debounce";
import queryClient from "../../../../providers/queryClient";
import { toast } from "react-toastify";
import { currencyList } from "../../../../utils_v2/CurrencyToSymbol";
import { BANKRUCPTCY_MAINTANANCE } from "../../../../constants";

const AttorneyCardForm = ({
  setAttorneyCard,
  attorneyCard,
  isActive,
  atref,
  isEdit,
}) => {
  let intl = useIntl();
  let { referenceData, currentOrganization, refetch } =
    useContext(MaxyfiContext);
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(attorneyCard?.type === "EDIT"
        ? {
            atrn: _.get(attorneyCard, "data.atrn"),
            firn: _.get(attorneyCard, "data.firn"),
            ph: {
              coc: setSelectValues(_.get(attorneyCard, "data.ph.coc")),
              val: _.get(attorneyCard, "data.ph.val"),
            },
            em: {
              val: _.get(attorneyCard, "data.em.val"),
            },
            addr: {
              li1: _.get(attorneyCard, "data.addr.li1"),
              li2: _.get(attorneyCard, "data.addr.li2"),
              cit: _.get(attorneyCard, "data.addr.cit"),
              cou: setSelectValues(_.get(attorneyCard, "data.addr.cou")),
              sta: setSelectValues(_.get(attorneyCard, "data.addr.sta")),
            },
          }
        : {}),
    },
  });

  const editAttoneryData = useMutation(
    (values) =>
      updateAttorney({
        ...values,
        organization: currentOrganization,
        counselId: _.get(attorneyCard, "data._id", ""),
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        refetch({
          setLoader: false,
          currentOrganization: currentOrganization,
        });
        setAttorneyCard({
          type: "LIST",
          data: data,
          action: "edit",
        });
      },
    }
  );

  const addAttoneryData = useMutation(
    (values) =>
      addAttorneyBankruptcy({
        ...values,
        type: "BANKRUPTCY_ATTORNEY",
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        refetch({
          setLoader: false,
          currentOrganization: currentOrganization,
        });
        setAttorneyCard({
          type: "LIST",
          data: data,
          action: "submit",
        });
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      atrn: data.atrn,
      ...(data.firn ? { firn: data.firn } : {}),
      ...(data.ph
        ? {
            ph: {
              coc: getSelectValues(data && data.ph && data.ph.coc, false),
              val: data && data.ph && data.ph.val,
            },
          }
        : {}),
      ...(data.addr
        ? {
            addr: {
              ...(data.addr.li1 ? { li1: data.addr.li1 } : {}),
              ...(data.addr.li2 ? { li2: data.addr.li2 } : {}),
              ...(data.addr.cit ? { cit: data.addr.cit } : {}),
              ...(data.addr.sta ? { sta: getSelectValues(data.addr.sta) } : {}),
              ...(data.addr.cou ? { cou: getSelectValues(data.addr.cou) } : {}),
              ...(data.addr.zip ? { zip: data.addr.zip } : {}),
            },
          }
        : {}),
      ...(data.em
        ? {
            em: {
              val: data.em.val,
            },
          }
        : {}),
    };
    if (attorneyCard?.type === "EDIT") {
      await editAttoneryData.mutateAsync({
        ...values,
      });
    } else {
      await addAttoneryData.mutateAsync({
        ...values,
      });
    }
  };

  return (
    <>
      <form>
        <div className="form-container-style">
          <Controller
            name={`atrn`}
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <>
                <TextBox
                  name={field.name}
                  size={SIZE.compact}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "attorney_name",
                  })}
                  placeholder={intl.formatMessage({
                    id: "attorney_name",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                />
              </>
            )}
          />

          <Controller
            name={`firn`}
            control={control}
            render={({ field }) => (
              <>
                <TextBox
                  name={field.name}
                  size={SIZE.compact}
                  label={intl.formatMessage({
                    id: "attorney_firm",
                  })}
                  placeholder={intl.formatMessage({
                    id: "attorney_firm",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                />
              </>
            )}
          />
        </div>
        <div className="form-ph-sec">
          <Controller
            name={`ph.coc`}
            control={control}
            render={({ field }) => (
              <div style={{ width: "70px" }}>
                <SelectBox
                  {...field}
                  clearable={false}
                  name={field.name}
                  size={SIZE.compact}
                  label={intl.formatMessage({
                    id: "code",
                  })}
                  placeholder={intl.formatMessage({
                    id: "code",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={
                    referenceData
                      ? referenceData && referenceData["contact_phone_code"]
                      : []
                  }
                />
              </div>
            )}
          />
          <Controller
            name={`ph.val`}
            control={control}
            render={({ field }) => (
              <>
                <div style={{ width: "221px" }}>
                  <TextBox
                    name={field.name}
                    size={SIZE.compact}
                    label={intl.formatMessage({
                      id: "attroney_phone",
                    })}
                    placeholder={intl.formatMessage({
                      id: "attroney_phone",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          />
          <div style={{ width: "300px" }}>
            <Controller
              name={`em.val`}
              control={control}
              render={({ field }) => (
                <>
                  <TextBox
                    name={field.name}
                    size={SIZE.compact}
                    label={intl.formatMessage({
                      id: "attroney_email",
                    })}
                    placeholder={intl.formatMessage({
                      id: "attroney_email",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>
        <Controller
          name={`addr.li1`}
          control={control}
          render={({ field }) => (
            <>
              <TextBox
                name={field.name}
                size={SIZE.compact}
                label={intl.formatMessage({
                  id: "address_line_1",
                })}
                placeholder={intl.formatMessage({
                  id: "address_line_1",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              />
            </>
          )}
        />
        <Controller
          name={`addr.li2`}
          control={control}
          render={({ field }) => (
            <>
              <TextBox
                name={field.name}
                size={SIZE.compact}
                label={intl.formatMessage({
                  id: "address_line_2",
                })}
                placeholder={intl.formatMessage({
                  id: "address_line_2",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              />
            </>
          )}
        />
        <div className="form-container-style">
          <Controller
            name={`addr.cit`}
            control={control}
            render={({ field }) => (
              <>
                <div style={{ width: "145px" }}>
                  <TextBox
                    name={field.name}
                    size={SIZE.compact}
                    label={intl.formatMessage({
                      id: "city",
                    })}
                    placeholder={intl.formatMessage({
                      id: "city",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          />
          <Controller
            name={`addr.sta`}
            control={control}
            render={({ field }) => (
              <>
                <div style={{ width: "145px" }}>
                  <SelectBox
                    name={field.name}
                    size={SIZE.compact}
                    creatable
                    label={intl.formatMessage({
                      id: "state",
                    })}
                    placeholder={intl.formatMessage({
                      id: "state",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      referenceData
                        ? referenceData && referenceData["state_list"]
                        : []
                    }
                  />
                </div>
              </>
            )}
          />
          <Controller
            name={`addr.cou`}
            control={control}
            render={({ field }) => (
              <>
                <div style={{ width: "145px" }}>
                  <SelectBox
                    name={field.name}
                    size={SIZE.compact}
                    label={intl.formatMessage({
                      id: "country",
                    })}
                    placeholder={intl.formatMessage({
                      id: "country",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      referenceData
                        ? referenceData && referenceData["country"]
                        : []
                    }
                  />
                </div>
              </>
            )}
          />
          <Controller
            name={`addr.zip`}
            control={control}
            render={({ field }) => (
              <>
                <div style={{ width: "145px" }}>
                  <TextBox
                    name={field.name}
                    size={SIZE.compact}
                    label={intl.formatMessage({
                      id: "zip_code",
                    })}
                    placeholder={intl.formatMessage({
                      id: "zip_code",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          />
        </div>

        <div className="bank-save-btn">
          <TextButton
            type="submit"
            size={SIZE.mini}
            kind={KIND.primary}
            isLoading={isSubmitting}
            style={{
              padding: "5px 10px",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </TextButton>
        </div>
      </form>
    </>
  );
};

const AttroneyInfoCard = ({ setAttorneyCard, attorneyCard, setValue }) => {
  let { currentDefaultFormatDetails, referenceData } =
    useContext(MaxyfiContext);

  const cardData = useMemo(() => {
    let attorneyCardData;
    if (attorneyCard.action === "submit") {
      attorneyCardData = _.get(currentDefaultFormatDetails, "atrl", []);
      attorneyCardData = attorneyCardData[attorneyCardData.length - 1];
    } else if (attorneyCard.action === "edit") {
      attorneyCardData = _.get(currentDefaultFormatDetails, "atrl", [])?.find(
        (e) => e._id === _.get(attorneyCard, "data.data.attorney_id")
      );
    } else {
      attorneyCardData = _.get(currentDefaultFormatDetails, "atrl", [])?.find(
        (e) => e._id === attorneyCard?.data[0]?.id
      );
    }
    return attorneyCardData;
  }, [
    currentDefaultFormatDetails,
    currentDefaultFormatDetails?.atrl.length,
    attorneyCard,
  ]);

  const country =
    referenceData && referenceData["country"] && _.get(cardData, "addr.cou", "")
      ? referenceData["country"].find(
          (e) => e.id === _.get(cardData, "addr.cou", "")
        )
      : null;

  return (
    <div className="added-counsel-card-container">
      <div className="added-counsel-card_header">
        <div className="counsel-card">
          <Typography type="h4"> {_.get(cardData, "atrn", "")} </Typography>
          <Icon icon="dot" color="#D3D3D7" size={5}></Icon>
          <Typography type="p" subType="regular" className="text-secondary">
            {_.get(cardData, "firn", "")}
          </Typography>
        </div>
        <div className="counsel-card-details">
          <Icon
            icon="edit_outline"
            color="#ADADAD"
            size={16}
            isPressable
            onClick={() => {
              setAttorneyCard({
                type: "EDIT",
                data: cardData,
              });
            }}
          />
          <StatefulPopover
            triggerType={TRIGGER_TYPE.click}
            content={({ close }) => (
              <div className="co_groping_delete_popover">
                <div className="co_groping_delete_popover_body">
                  <Icon icon="alert" color="#FD372A" />
                  <Typography type="p">
                    This will remove Bankurptcy Attorney Card?
                  </Typography>
                </div>
                <div className="co_groping_delete_popover_footer">
                  <TextButton
                    size="mini"
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => close()}
                  >
                    <Typography type="h5" style={{ color: "#FD372A" }}>
                      Cancel
                    </Typography>
                  </TextButton>
                  <TextButton
                    size="mini"
                    kind={KIND.secondary}
                    onClick={() => {
                      setAttorneyCard({
                        type: "DELETE",
                        data: "",
                      });
                      setValue("atref", []);
                    }}
                    type="error"
                  >
                    Confirm
                  </TextButton>
                </div>
              </div>
            )}
          >
            <div style={{ cursor: "pointer" }}>
              {" "}
              <Icon
                icon="delete_outline"
                color="#ADADAD"
                size={16}
                isPressable
              />
            </div>
          </StatefulPopover>
        </div>
      </div>
      <Typography type="p" subType="regular" className="regular">
        {_.get(cardData, "ph.coc", "")} {_.get(cardData, "ph.val", "")}
      </Typography>
      <Typography type="p" subType="regular" className="regular">
        {_.get(cardData, "em.val", "")}{" "}
      </Typography>
      <Typography type="p" subType="regular" className="regular">
        {" "}
        {_.get(cardData, "addr.li1", "")} {_.get(cardData, "addr.li2", "")}{" "}
        {_.get(cardData, "addr.cit", "")}{" "}
        <RDText rdName="state_list" id={_.get(cardData, "addr.sta", "")} />{" "}
        {_.get(country, "id", "")} {_.get(cardData, "addr.zip", "")}
      </Typography>
    </div>
  );
};

function BankruptcyForm({
  resourceId,
  defaultValues,
  isEdit,
  setState,
  setResource,
  setIsRequest,
  closeDrawer,
}) {
  let intl = useIntl();
  let {
    space,
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const { customerId } = useParams();
  const [isCustomer, setIsCustomer] = useState([]);
  const [addAttorneyInfo, setAddAttorneyInfo] = useState(false);
  const [attorneyCard, setAttorneyCard] = useState({});

  const customer = useCustomerQuery({
    customerId,
    organizationId: currentOrganization,
    options: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: customerId ? true : false,
    },
  });

  let filterData =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails?.csm?.filter((e) => e.enb === true);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
    defaultValues: {
      ...(isEdit ? { ...defaultValues } : {}),
    },
  });

  useEffect(() => {
    if (
      defaultValues &&
      defaultValues.atref &&
      defaultValues.atref.length > 0
    ) {
      setValue("atref", [{ id: defaultValues?.atref[0]?.id }]);
      setAttorneyCard({
        type: "LIST",
        data: defaultValues?.atref,
      });
    }
  }, [defaultValues]);

  const addBankruptcyMaintain = useMutation(
    (values) =>
      postBankruptcyMaintanance({
        values,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        setIsRequest(false);
        queryClient.refetchQueries(
          `${BANKRUCPTCY_MAINTANANCE}_${currentOrganization}`
        );
      },
    }
  );

  const addBankruptcyData = useMutation(
    (values) =>
      postBankruptchyData({
        values,
        organization: currentOrganization,
        customerId: customerId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        if (customerId) {
          setState({ type: "LIST" });
        } else {
          closeDrawer();
        }
      },
    }
  );

  const editBankruptcy = useMutation(
    (values) =>
      editBankruptcyData({
        values,
        resourceId: resourceId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        if (customerId) {
          setState({ type: "LIST" });
        } else {
          setIsRequest(false);
        }
        queryClient.refetchQueries(
          `customer-${customerId}-request-${resourceId}`
        );
      },
    }
  );

  const onSubmit = async (data) => {
    const orgRef =
      referenceData &&
      referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );

    const timeZone = orgRef?.time_zone;

    let values = {
      ssn: !isEdit ? data.ssn : undefined,
      ...(data.dob
        ? {
            dob: moment
              .tz(
                `${data.dob.getFullYear()}-${
                  data.dob.getMonth() + 1
                }-${data.dob.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.fnam ? { fnam: data.fnam } : {}),
      ...(data.lnam ? { lnam: data.lnam } : {}),
      ...(data.adln1 ? { adln1: data.adln1 } : {}),
      ...(data.adln2 ? { adln2: data.adln2 } : {}),
      ...(data.city ? { city: data.city } : {}),
      ...(data.state
        ? {
            state: getSelectValues(data.state),
          }
        : {}),
      ...(data.ctry
        ? {
            ctry: getSelectValues(data.ctry),
          }
        : {}),
      ...(data.zpcd ? { zpcd: data.zpcd } : {}),
      ...(data.bkno ? { bkno: data.bkno } : {}),
      ...(data.bkchp
        ? {
            bkchp: getSelectValues(data.bkchp),
          }
        : {}),

      ...(data.dscotna ? { dscotna: data.dscotna } : {}),
      ...(data.adocot
        ? {
            adocot: data.adocot,
          }
        : {}),
      ...(data.judna ? { judna: data.judna } : {}),
      ...(data.bkamt ? { bkamt: data.bkamt } : {}),
      ...(data.fildt
        ? {
            fildt: moment
              .tz(
                `${data.fildt.getFullYear()}-${
                  data.fildt.getMonth() + 1
                }-${data.fildt.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.notidt
        ? {
            notidt: moment
              .tz(
                `${data.notidt.getFullYear()}-${
                  data.notidt.getMonth() + 1
                }-${data.notidt.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.herdt
        ? {
            herdt: moment
              .tz(
                `${data.herdt.getFullYear()}-${
                  data.herdt.getMonth() + 1
                }-${data.herdt.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.dimidt
        ? {
            dimidt: moment
              .tz(
                `${data.dimidt.getFullYear()}-${
                  data.dimidt.getMonth() + 1
                }-${data.dimidt.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),

      ...(data.dichdt
        ? {
            dichdt: moment
              .tz(
                `${data.dichdt.getFullYear()}-${
                  data.dichdt.getMonth() + 1
                }-${data.dichdt.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.bkdt
        ? {
            bkdt: moment
              .tz(
                `${data.bkdt.getFullYear()}-${
                  data.bkdt.getMonth() + 1
                }-${data.bkdt.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),

      ...(data.faildt
        ? {
            faildt: moment
              .tz(
                `${data.faildt.getFullYear()}-${
                  data.faildt.getMonth() + 1
                }-${data.faildt.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data.reaff ? { reaff: data.reaff } : {}),
      ...(data.conv ? { conv: data.conv } : {}),
      ...(data.docno ? { docno: data.docno } : {}),
      ...(data.stofi ? { stofi: getSelectValues(data.stofi) } : {}),
      ...(data.atref ? { atref: getSelectValues(data.atref) } : {}),
      ...(data.trstn ? { trstn: data.trstn } : {}),
      ...(data.trstfr ? { trstfr: data.trstfr } : {}),
      ...(data.trstph
        ? {
            ...(data.trstph.coc || data.trstph.val
              ? {
                  trstph: {
                    ...(data.trstph.coc
                      ? { coc: getSelectValues(data.trstph.coc) }
                      : {}),
                    ...(data.trstph.val ? { val: data.trstph.val } : {}),
                  },
                }
              : {}),
          }
        : {}),
      ...(data.trstaddr
        ? {
            ...(data.trstaddr.li1 ||
            data.trstaddr.li2 ||
            data.trstaddr.cit ||
            data.trstaddr.sta ||
            data.trstaddr.cou ||
            data.trstaddr.zip
              ? {
                  trstaddr: {
                    ...(data.trstaddr.li1 ? { li1: data.trstaddr.li1 } : {}),
                    ...(data.trstaddr.li2 ? { li2: data.trstaddr.li2 } : {}),
                    ...(data.trstaddr.cit ? { cit: data.trstaddr.cit } : {}),
                    ...(data.trstaddr.sta
                      ? { sta: getSelectValues(data.trstaddr.sta) }
                      : {}),
                    ...(data.trstaddr.cou
                      ? { cou: getSelectValues(data.trstaddr.cou) }
                      : {}),
                    ...(data.trstaddr.zip ? { zip: data.trstaddr.zip } : {}),
                  },
                }
              : {}),
          }
        : {}),
      ...(data.trstem
        ? {
            ...(data.trstem.val
              ? {
                  trstem: {
                    ...(data.trstem.val ? { val: data.trstem.val } : {}),
                  },
                }
              : {}),
          }
        : {}),
      ...(data.upcst
        ? {
            upcst: getSelectValues(data.upcst),
          }
        : {}),
      iupcst: data.iupcst,
      iupmtcst: data.iupmtcst,
    };

    if (isEdit) {
      await editBankruptcy.mutateAsync(values);
    } else {
      if (customerId) {
        await addBankruptcyData.mutateAsync(values);
      } else {
        await addBankruptcyMaintain.mutateAsync(values);
      }
    }
  };

  const cardsData = queryClient.getQueryData(
    `GET_CUSTOMER_BANKRUPTCY_${currentOrganization}`
  );

  let { atref, iupcst, ssn, iupmtcst } = watch();

  const [debouncedValue, setDebouncedValue] = useState("");
  const debounceFilter = useDebouncedCallback((filter) => {
    setDebouncedValue(filter);
  }, 1000);

  let filterVal = {
    ssn: debouncedValue != "" ? debouncedValue : null,
  };

  const gettingSsnRecords = useQuery(
    [
      `MATCHING_CONSUMERS_${currentOrganization}`,
      {
        filters: {
          ...filterVal,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getMatchingConsumers({
        organization: currentOrganization,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: ssn && debouncedValue ? true : false,
    }
  );

  let attorneyInfo =
    space && space?.organizations.find(({ id }) => id === currentOrganization);

  useEffect(() => {
    if (Array.isArray(isCustomer) && isCustomer.length > 0) {
      isCustomer &&
        isCustomer.length > 0 &&
        isCustomer.map((item) => {
          setValue("ssn", _.get(item, "custom_field.ssn")?.toString());
          debounceFilter(_.get(item, "custom_field.ssn"));
          setValue(
            "dob",
            _.get(item, "custom_field.dob")
              ? new Date(_.get(item, "custom_field.dob"))
              : null
          );
          setValue("fnam", item.first_name);
          setValue("lnam", item.last_name);
          item &&
            item?.address.map((i) => {
              setValue("adln1", i?.line_1);
              setValue("adln2", i?.line_2);
              setValue("city", i?.city);
              setValue("state", setSelectValues(i?.state));
              setValue("ctry", setSelectValues(i?.country));
              setValue("zpcd", i?.zip_code);
            });
        });
    } else {
      if (!isEdit && customerId) {
        setValue("ssn", "");
        setValue("dob", "");
        setValue("fnam", "");
        setValue("lnam", "");
        setValue("adln1", "");
        setValue("adln2", "");
        setValue("city", "");
        setValue("state", "");
        setValue("ctry", "");
        setValue("zpcd", "");
      }
    }
  }, [isCustomer]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bankruptcy-drawer-container">
          <div>
            <Typography type="p" style={{ margin: "10px 0" }}>
              Consumer Information
            </Typography>
            <div className="border-line">
              {customerId && _.get(customer, "data.contacts", []).length > 0 ? (
                <>
                  {" "}
                  {customerId && !isEdit && (
                    <>
                      <div className="choose-card-contain">
                        <div className="choose-card-contain-sec">
                          <Typography type="p" className="choose-typo">
                            {" "}
                            Choose Contact
                          </Typography>
                        </div>
                        <div style={{ width: "200px" }}>
                          <SelectBox
                            size={SIZE.compact}
                            label={intl.formatMessage({
                              id: "select",
                            })}
                            placeholder={intl.formatMessage({
                              id: "select",
                            })}
                            value={isCustomer}
                            onChange={(e) => {
                              setIsCustomer(e.value);
                            }}
                            options={_.get(customer, "data.contacts", []).map(
                              (con) => {
                                return {
                                  label: `${
                                    con.first_name ? con.first_name : ""
                                  } ${con.last_name ? con.last_name : ""} `,
                                  id: con._id,
                                  ...con,
                                };
                              }
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}

              <div className="form-container-style">
                <>
                  <Controller
                    name={`ssn`}
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          size={SIZE.compact}
                          disabled={isEdit}
                          label={intl.formatMessage({
                            id: "ssn",
                          })}
                          placeholder={intl.formatMessage({
                            id: "ssn",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            let value = e.target.value;

                            debounceFilter(e.target.value);
                            field.onChange(e.target.value);
                          }}
                        />
                      </>
                    )}
                  />

                  <Controller
                    name={`dob`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <DateRangeSelect
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "dob",
                          })}
                          placeholder={intl.formatMessage({
                            id: "dob",
                          })}
                          value={field.value}
                          onChange={(e) => field.onChange(e.date)}
                          minDate={new Date("1900-01-01T00:00:00.000Z")}
                        />
                      </>
                    )}
                  />
                </>
                <Controller
                  name={`fnam`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "first_name",
                        })}
                        placeholder={intl.formatMessage({
                          id: "first_name",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div style={{ width: "200px" }}>
                <Controller
                  name={`lnam`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "last_name",
                        })}
                        placeholder={intl.formatMessage({
                          id: "last_name",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <Controller
                name={`adln1`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextBox
                      name={field.name}
                      size={SIZE.compact}
                      label={intl.formatMessage({
                        id: "address_line_1",
                      })}
                      placeholder={intl.formatMessage({
                        id: "address_line_1",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  </>
                )}
              />

              <Controller
                name={`adln2`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextBox
                      name={field.name}
                      size={SIZE.compact}
                      label={intl.formatMessage({
                        id: "address_line_2",
                      })}
                      placeholder={intl.formatMessage({
                        id: "address_line_2",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  </>
                )}
              />
              <div className="form-container-style">
                <Controller
                  name={`city`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div>
                        <TextBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "city",
                          })}
                          placeholder={intl.formatMessage({
                            id: "city",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
                <Controller
                  name={`state`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "200px" }}>
                        <SelectBox
                          name={field.name}
                          size={SIZE.compact}
                          creatable
                          label={intl.formatMessage({
                            id: "state",
                          })}
                          placeholder={intl.formatMessage({
                            id: "state",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            referenceData
                              ? referenceData && referenceData["state_list"]
                              : []
                          }
                        />
                      </div>
                    </>
                  )}
                />

                <Controller
                  name={`ctry`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "200px" }}>
                        <SelectBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "country",
                          })}
                          placeholder={intl.formatMessage({
                            id: "country",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            referenceData
                              ? referenceData && referenceData["country"]
                              : []
                          }
                        />
                      </div>
                    </>
                  )}
                />
              </div>
              <div style={{ width: "200px" }}>
                <Controller
                  name={`zpcd`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "zip_code",
                        })}
                        placeholder={intl.formatMessage({
                          id: "zip_code",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div>
            <Typography type="p" style={{ margin: "10px 0" }}>
              Bankruptcy Case Details
            </Typography>
            <div className="border-line">
              <div className="form-container-style">
                <Controller
                  name={`bkno`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "bankruptcy",
                        })}
                        placeholder={intl.formatMessage({
                          id: "bankruptcy",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`bkchp`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <SelectBox
                        name={field.name}
                        size={SIZE.compact}
                        creatable
                        label={intl.formatMessage({
                          id: "chapter",
                        })}
                        placeholder={intl.formatMessage({
                          id: "chapter",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          referenceData
                            ? referenceData &&
                              referenceData["bankruptcy_chapter_list"]
                            : []
                        }
                      />
                    </>
                  )}
                />
                <Controller
                  name={`dscotna`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "dist_court_name",
                        })}
                        placeholder={intl.formatMessage({
                          id: "dist_court_name",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <Controller
                name={`adocot`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextBox
                      name={field.name}
                      size={SIZE.compact}
                      label={intl.formatMessage({
                        id: "address_court",
                      })}
                      placeholder={intl.formatMessage({
                        id: "address_court",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  </>
                )}
              />

              <div className="form-container-style">
                <Controller
                  name={`judna`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "judge_name",
                        })}
                        placeholder={intl.formatMessage({
                          id: "judge_name",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`bkamt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "amount",
                        })}
                        placeholder={intl.formatMessage({
                          id: "amount",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`fildt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <DateRangeSelect
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "date_field",
                        })}
                        placeholder={intl.formatMessage({
                          id: "date_field",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                      />
                    </>
                  )}
                />
              </div>

              <div className="form-container-style">
                <Controller
                  name={`notidt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <DateRangeSelect
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "notify_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: "notify_date",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`herdt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <DateRangeSelect
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "hearing_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: "hearing_date",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`dimidt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <DateRangeSelect
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "dismiss_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: "dismiss_date",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                      />
                    </>
                  )}
                />
              </div>

              <div className="form-container-style">
                <Controller
                  name={`dichdt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <DateRangeSelect
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "discharge_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: "discharge_date",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`bkdt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <DateRangeSelect
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "bankruptcy_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: "bankruptcy_date",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`faildt`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <DateRangeSelect
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "failed_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: "failed_date",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                      />
                    </>
                  )}
                />
              </div>
              <Controller
                name={`reaff`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextBox
                      name={field.name}
                      size={SIZE.compact}
                      label={intl.formatMessage({
                        id: "reaffirmation",
                      })}
                      value={field.value}
                      overrides={{
                        Root: {
                          style: ({ $theme }) => ({
                            borderRadius: "3px",
                            borderColor: "#CDCED9",
                            borderWidth: "0.5px",
                            borderStyle: "solid",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                            // height: "50px",
                          }),
                        },
                        Input: {
                          style: ({ $isFocused }) => ({
                            backgroundColor: "#ffffff",
                            //  height: "40px",
                            "::placeholder": {
                              paddingTop: "5px",
                              marginTop: "5px",
                            },
                          }),
                        },

                        InputContainer: {
                          style: ({ $theme }) => ({
                            height: "40px",
                            "::placeholder": {
                              paddingTop: "5px",
                              marginTop: "5px",
                            },
                          }),
                        },
                      }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  </>
                )}
              />

              <Controller
                name={`conv`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextBox
                      name={field.name}
                      size={SIZE.compact}
                      label={intl.formatMessage({
                        id: "converted",
                      })}
                      overrides={{
                        Root: {
                          style: ({ $theme }) => ({
                            borderRadius: "3px",
                            borderColor: "#CDCED9",
                            borderWidth: "0.5px",
                            borderStyle: "solid",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          }),
                        },
                        Input: {
                          style: ({ $isFocused }) => ({
                            backgroundColor: "#ffffff",
                            "::placeholder": {
                              paddingTop: "5px",
                              marginTop: "5px",
                            },
                          }),
                        },
                        InputContainer: {
                          style: ({ $theme }) => ({
                            height: "40px",
                            "::placeholder": {
                              paddingTop: "5px",
                              marginTop: "5px",
                            },
                          }),
                        },
                      }}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  </>
                )}
              />
              <div className="form-ph-sec">
                <Controller
                  name={`docno`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "200px" }}>
                        <TextBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "docker_number",
                          })}
                          placeholder={intl.formatMessage({
                            id: "docker_number",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )}
                />

                <Controller
                  name={`stofi`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "200px" }}>
                        <SelectBox
                          name={field.name}
                          size={SIZE.compact}
                          creatable
                          label={intl.formatMessage({
                            id: "status_of_filling",
                          })}
                          placeholder={intl.formatMessage({
                            id: "status_of_filling",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            referenceData
                              ? referenceData &&
                                referenceData["bankruptcy_status_list"]
                              : []
                          }
                        />
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div>
            <Typography type="p" style={{ margin: "10px 0" }}>
              Attorney Information
            </Typography>
            <div className="border-line">
              <div className="choose-card-contain">
                <div className="choose-card-contain-sec">
                  <Typography type="p" className="choose-typo">
                    Choose Attorney
                  </Typography>
                </div>

                <div style={{ width: "200px" }}>
                  <Controller
                    name={`atref`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <SelectBox
                          name={field.name}
                          size={SIZE.compact}
                          clearable={false}
                          label={intl.formatMessage({
                            id: "attorney_info",
                          })}
                          placeholder={intl.formatMessage({
                            id: "attorney_info",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                            setAttorneyCard({
                              type: "LIST",
                              data: e.value,
                            });
                          }}
                          options={
                            attorneyInfo &&
                            Array.isArray(attorneyInfo.atrl) &&
                            attorneyInfo.atrl.map((i) => {
                              return {
                                id: i._id,
                                label: i.atrn,
                              };
                            })
                          }
                        />
                      </>
                    )}
                  />
                </div>
                <div className="attorney-card-conatainer">
                  <div className="add-attorney-btn">
                    <TextButton
                      type="button"
                      size={SIZE.mini}
                      kind={KIND.tertiary}
                      style={{ color: "#516BEB" }}
                      onClick={() => {
                        if (atref && atref.length > 0) {
                          setValue("atref", []);
                        }
                        setAddAttorneyInfo(!addAttorneyInfo);
                        setAttorneyCard({
                          type: "ADD",
                        });
                      }}
                    >
                      <Icon icon="plus" color={"#516BEB"} />
                      Add New Attorney
                    </TextButton>
                  </div>
                </div>
              </div>

              {attorneyCard && attorneyCard.type === "LIST" ? (
                <>
                  <AttroneyInfoCard
                    setAttorneyCard={setAttorneyCard}
                    attorneyCard={attorneyCard}
                    setValue={setValue}
                  />
                </>
              ) : (
                <>
                  {addAttorneyInfo ? (
                    <>
                      <AttorneyCardForm
                        setAttorneyCard={setAttorneyCard}
                        attorneyCard={attorneyCard}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {attorneyCard && attorneyCard.type === "EDIT" && (
                <>
                  <AttorneyCardForm
                    setAttorneyCard={setAttorneyCard}
                    attorneyCard={attorneyCard}
                  />
                </>
              )}
            </div>
          </div>
          <div>
            <Typography type="p" style={{ margin: "10px 0" }}>
              Case Administration
            </Typography>
            <div className="border-line">
              <div className="form-container-style">
                <Controller
                  name={`trstn`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "trustee_name",
                        })}
                        placeholder={intl.formatMessage({
                          id: "trustee_name",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />

                <Controller
                  name={`trstfr`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <TextBox
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "trustee_firm",
                        })}
                        placeholder={intl.formatMessage({
                          id: "trustee_firm",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div className="form-ph-sec">
                <Controller
                  name={`trstph.coc`}
                  control={control}
                  render={({ field }) => (
                    <div style={{ width: "70px" }}>
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "code",
                        })}
                        placeholder={intl.formatMessage({
                          id: "code",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={
                          referenceData
                            ? referenceData &&
                              referenceData["contact_phone_code"]
                            : []
                        }
                      />
                    </div>
                  )}
                />
                <Controller
                  name={`trstph.val`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "225px" }}>
                        <TextBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "attroney_phone",
                          })}
                          placeholder={intl.formatMessage({
                            id: "attroney_phone",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
                .
                <div style={{ width: "300px" }}>
                  <Controller
                    name={`trstem.val`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "trustee_email",
                          })}
                          placeholder={intl.formatMessage({
                            id: "trustee_email",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              <Controller
                name={`trstaddr.li1`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextBox
                      name={field.name}
                      size={SIZE.compact}
                      label={intl.formatMessage({
                        id: "address_line_1",
                      })}
                      placeholder={intl.formatMessage({
                        id: "address_line_1",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  </>
                )}
              />

              <Controller
                name={`trstaddr.li2`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextBox
                      name={field.name}
                      size={SIZE.compact}
                      label={intl.formatMessage({
                        id: "address_line_2",
                      })}
                      placeholder={intl.formatMessage({
                        id: "address_line_2",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  </>
                )}
              />
              <div className="form-container-style">
                <Controller
                  name={`trstaddr.cit`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "145px" }}>
                        <TextBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "city",
                          })}
                          placeholder={intl.formatMessage({
                            id: "city",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
                <Controller
                  name={`trstaddr.sta`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "145px" }}>
                        <SelectBox
                          name={field.name}
                          size={SIZE.compact}
                          creatable
                          label={intl.formatMessage({
                            id: "state",
                          })}
                          placeholder={intl.formatMessage({
                            id: "state",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            referenceData
                              ? referenceData && referenceData["state_list"]
                              : []
                          }
                        />
                      </div>
                    </>
                  )}
                />
                <Controller
                  name={`trstaddr.cou`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "145px" }}>
                        <SelectBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "country",
                          })}
                          placeholder={intl.formatMessage({
                            id: "country",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            referenceData
                              ? referenceData && referenceData["country"]
                              : []
                          }
                        />
                      </div>
                    </>
                  )}
                />
                <Controller
                  name={`trstaddr.zip`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <div style={{ width: "145px" }}>
                        <TextBox
                          name={field.name}
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "zip_code",
                          })}
                          placeholder={intl.formatMessage({
                            id: "zip_code",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
          <div>
            <Typography type="p" style={{ margin: "10px 0" }}>
              Update Consumer Status
            </Typography>
            <div>
              <div className="form-ph-sec">
                <div style={{ marginTop: "5px" }}>
                  <Controller
                    name={`iupcst`}
                    defaultValue={false}
                    control={control}
                    render={({ field }) => (
                      <>
                        <CheckBoxBaseweb {...field} checked={field.value}>
                          <Typography type="p" className="status-form">
                            Update Consumer to the Status
                          </Typography>
                        </CheckBoxBaseweb>
                      </>
                    )}
                  />
                </div>
                <div style={{ width: "200px" }}>
                  <Controller
                    name={`upcst`}
                    control={control}
                    rules={{ required: iupcst ? "Required" : "" }}
                    render={({ field }) => (
                      <>
                        <SelectBox
                          name={field.name}
                          size={SIZE.compact}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          creatable
                          label={intl.formatMessage({
                            id: "status",
                          })}
                          placeholder={intl.formatMessage({
                            id: "status",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={
                            iupcst
                              ? Array.isArray(filterData) &&
                                filterData.map((e) => {
                                  return {
                                    id: e.nam,
                                    label: e.nam,
                                  };
                                })
                              : []
                          }
                        />
                      </>
                    )}
                  />
                </div>
              </div>

              {iupcst && (
                <>
                  <div>
                    {customerId && (
                      <>
                        <div style={{ marginTop: "5px" }}>
                          <Controller
                            name={`iupmtcst`}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <>
                                <CheckBoxBaseweb
                                  {...field}
                                  checked={field.value}
                                >
                                  <Typography type="p" className="status-form">
                                    Update Status for Below matching Consumer
                                    Records
                                  </Typography>
                                </CheckBoxBaseweb>
                              </>
                            )}
                          />
                        </div>
                      </>
                    )}
                    <div
                      className={`${
                        _.get(gettingSsnRecords, "data.data.docs", []).length >
                          0 && iupmtcst
                          ? "bank-ssn-details"
                          : ""
                      }`}
                    >
                      {iupmtcst || !customerId ? (
                        <>
                          {_.get(gettingSsnRecords, "data.data.docs", []).map(
                            (item) => {
                              if (item._id === customerId) {
                                return null;
                              }
                              return (
                                <>
                                  <Typography type="p">
                                    <span>
                                      <span style={{ fontWeight: "400" }}>
                                        {item.display_name}
                                      </span>
                                      -
                                      <RDText
                                        id={item?.business_unit}
                                        rdName={"business_unit_list"}
                                      />{" "}
                                      -
                                      <span style={{ fontWeight: "400" }}>
                                        {
                                          currencyList[
                                            item
                                              .cs_total_outstanding_invoice_amount
                                              .currency
                                          ]
                                        }
                                        {
                                          item
                                            .cs_total_outstanding_invoice_amount
                                            .value
                                        }
                                      </span>
                                    </span>{" "}
                                    <br />
                                  </Typography>
                                </>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            {errors && errors["ssn"] ? (
              <>
                <span style={{ color: "#FD372A" }}>SSN is required</span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="bankruptcy-drawer-container-footer">
          <div className="ban-form-btn">
            <TextButton
              type="button"
              size={SIZE.mini}
              kind={KIND.tertiary}
              onClick={() => {
                if (
                  _.get(cardsData, "data.docs", []).length === 0 &&
                  customerId
                ) {
                  closeDrawer();
                } else if (customerId) {
                  setState({
                    type: "LIST",
                  });
                } else {
                  setIsRequest(false);
                }
              }}
            >
              Cancel
            </TextButton>
            <TextButton
              type="submit"
              size={SIZE.mini}
              kind={KIND.primary}
              isLoading={isSubmitting}
              style={{
                padding: "5px 10px",
              }}
            >
              Save
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
}

export default BankruptcyForm;
