export const GET_DISPUTE_DATA = "GET_DISPUTE_DATA";
export const CHANGED_DISPUTE = "CHANGED_DISPUTE";
export const NEW_DISPUTE = "NEW_DISPUTE";
export const SELECTED_INVOICE = "SELECTED_INVOICE";
export const SWITCH_BACK_EMPTY_STATE = "SWITCH_BACK_EMPTY_STATE";
export const DELETE_ROW_INVOICE = "DELETE_ROW_INVOICE";
export const RESET_DISPUTE_STATE = "RESET_DISPUTE_STATE";
export const SELECT_DISPUTE_INVOICE_ISOPEN = "SELECT_DISPUTE_INVOICE_ISOPEN";

export const isOpenSelectedDispute = (data) => {
  return {
    type: SELECT_DISPUTE_INVOICE_ISOPEN,
    payload: data,
  };
};

export const deleteRowData = (data) => {
  return {
    type: DELETE_ROW_INVOICE,
    payload: data,
  };
};

export const resetDisputeState = () => {
  return {
    type: RESET_DISPUTE_STATE,
  };
};

export const getDisputeDatas = (data) => {
  return {
    type: GET_DISPUTE_DATA,
    payload: data,
  };
};

export const changedDisputeData = (data) => {
  return {
    type: CHANGED_DISPUTE,
    payload: data,
  };
};

export const newDisputeData = (data) => {
  return {
    type: NEW_DISPUTE,
    payload: data,
  };
};

export const selectedInvoice = (data) => {
  return {
    type: SELECTED_INVOICE,
    payload: data,
  };
};

export const emptyState = () => {
  return {
    type: SWITCH_BACK_EMPTY_STATE,
    // payload: data,
  };
};
