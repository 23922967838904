import React, { useContext } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { LabelLarge, ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiProvider } from "../../providers/MaxyfiProvider";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  assignCallUnsuccessful,
  assignMulCusRel,
  assignMultiCheckIn,
  getCheckLocation,
} from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../TextArea";
import { TimePickerAction } from "../TimePicker/TimePicker";
import { getInvoiceList } from "../../services/invoiceItem";
import moment from "moment";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";

const CallUnsuccessBulkAssign = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  isAllRowsSelected,
  queryFilter,
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    users,
    referenceData,
    restrictions,
    space,
    appAccessRest,
    refetch,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_customer: false,
      is_hold_workflow: false,
    },
  });

  let { call_unsuccessful_reason, follow_up_call } = watch();

  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );
  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );
  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const assignMultiCallUnsuccessfulCustomer = useMutation(
    (relMang) =>
      assignCallUnsuccessful({
        ...relMang,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        if (
          call_unsuccessful_reason &&
          call_unsuccessful_reason[0] &&
          call_unsuccessful_reason[0].isCreatable
        ) {
          refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        handleModal();
      },
    }
  );

  const onSubmit = async (data) => {
    let {
      remarks,
      call_unsuccessful_reason,
      is_hold_workflow,
      follow_up_call,
    } = data;

    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      // customerQueryFilter: queryFilter && queryFilter.display_name,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    let settingDate;
    if (follow_up_call?.[0]?.id == "TODAY") {
      settingDate = moment(settingDate).utc().valueOf();
    } else if (follow_up_call?.[0]?.id == "TOMORROW") {
      settingDate = moment().add(1, "days").utc().valueOf();
    }

    let values = {
      ...(follow_up_call?.[0]?.id == "NO_FOLLOW_UP"
        ? {}
        : { followup_date: settingDate }),
      call_unsuccessful_reason: getSelectValues(call_unsuccessful_reason),
      is_hold_workflow: is_hold_workflow,
      ...(remarks ? { comment: remarks } : {}),
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customers: selectedCustomer }),
      is_all_customer: data.is_all_customer,
    };

    await assignMultiCallUnsuccessfulCustomer.mutateAsync(values);
  };
  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);
  const intl = useIntl();

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge style={{ display: "flex", paddingBottom: "8px" }}>
          Update Call Status as Unsuccessful
        </ParagraphLarge>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "52%" }}>
            <Controller
              control={control}
              name="call_unsuccessful_reason"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  creatable={creatablePermission ? true : false}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "unsuccessful_reason",
                  })}
                  placeholder={intl.formatMessage({
                    id: "unsuccessful_reason",
                  })}
                  size={SIZE.mini}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["call_unsuccessful_reason"]}
                />
              )}
            />
          </div>
          <div style={{ width: "45%" }}>
            <Controller
              control={control}
              name="follow_up_call"
              defaultValue={[{ label: "No Follow-up", id: "NO_FOLLOW_UP" }]}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  clearable={false}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "follow_up_call",
                  })}
                  placeholder={intl.formatMessage({
                    id: "follow_up_call",
                  })}
                  size={SIZE.mini}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["call_unsuccessfull_followup_days"]}
                />
              )}
            />
          </div>
        </div>
        {follow_up_call &&
          follow_up_call[0] &&
          follow_up_call[0].id !== "NO_FOLLOW_UP" && (
            <div style={{ width: "100%", marginBottom: "15px" }}>
              <Controller
                name="is_hold_workflow"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb {...field} checked={field.value}>
                    <ParagraphSmall style={{ color: "#333860" }}>
                      Pause Workflow till follow-up date
                    </ParagraphSmall>
                  </CheckBoxBaseweb>
                )}
              />
            </div>
          )}

        <div>
          <Controller
            control={control}
            name="remarks"
            render={({ field }) => (
              <TextArea
                {...field}
                name={field.name}
                label={intl.formatMessage({
                  id: "comments",
                })}
                placeholder={intl.formatMessage({
                  id: "comments",
                })}
                size={SIZE.mini}
                value={field.value}
              />
            )}
          />
        </div>
        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default CallUnsuccessBulkAssign;
