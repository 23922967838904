function SkipTrace({ width = 22, height = 22, color = "#516BEB" }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M17.875 9.625C17.875 5.07602 14.174 1.375 9.625 1.375C5.07602 1.375 1.375 5.07602 1.375 9.625C1.375 14.174 5.07602 17.875 9.625 17.875C14.174 17.875 17.875 14.174 17.875 9.625ZM9.625 16.5C5.83401 16.5 2.75 13.416 2.75 9.625C2.75 5.83401 5.83401 2.75 9.625 2.75C13.416 2.75 16.5 5.83401 16.5 9.625C16.5 13.416 13.416 16.5 9.625 16.5Z"
          fill={color}
        />
        <path
          d="M19.9998 16.9617L17.6768 14.6387C17.5282 14.4901 17.3307 14.4345 17.1378 14.4532C16.445 15.527 15.5304 16.4416 14.4566 17.1344C14.438 17.3273 14.4935 17.5248 14.6421 17.6734L16.9651 19.9964C17.3703 20.4015 17.9091 20.6248 18.4824 20.6248C19.0555 20.6248 19.5943 20.4015 20.0001 19.9964C20.8363 19.1595 20.8363 17.7982 19.9998 16.9617Z"
          fill={color}
        />
        <path
          d="M15.1242 6.86963L9.96875 9.16095V15.125L14.7081 13.0067C14.9612 12.8986 15.125 12.6499 15.125 12.375V6.87496C15.125 6.87311 15.1242 6.87148 15.1242 6.86963Z"
          fill={color}
        />
        <path
          d="M9.89357 4.18089C9.72169 4.10637 9.52431 4.10637 9.35243 4.18089C9.35243 4.18089 4.50364 6.26702 4.48438 6.27755L9.623 8.56136L14.7616 6.27755C14.7424 6.26702 9.89357 4.18089 9.89357 4.18089Z"
          fill={color}
        />
        <path
          d="M4.125 6.87496V12.375C4.125 12.6499 4.28882 12.8986 4.54193 13.0067L9.28125 15.125V9.16095L4.1258 6.86963C4.1258 6.87148 4.125 6.87311 4.125 6.87496Z"
          fill={color}
        />
      </svg>
    </>
  );
}

export default SkipTrace;
