import React, { useState } from "react";
import { Textarea } from "baseui/textarea";
import { FormControl } from "baseui/form-control";

export const TextArea = (props) => {
  const {
    error = "",
    children,
    value = "",
    label = "",
    height = "",
    paddingTextArea = "",
    requiredAstric = false,
  } = props;

  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <FormControl
      error={error}
      overrides={{
        Caption: {
          style: ({ $theme }) => ({
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "12px",
            textAlign: "left",
          }),
        },
      }}
    >
      <div className="mx-input">
        <label
          htmlFor={props.name}
          className={`ta-input__label ${
            isFocused ? "ta-input__label--active text-primary" : ""
          } ${
            !isFocused && value?.length > 0 ? "ta-input__label--active" : ""
          } ${error ? "text-error" : ""}`}
        >
          {label}{" "}
          {requiredAstric ? (
            <>
              <span style={{ color: "red" }}>*</span>
            </>
          ) : (
            <></>
          )}
        </label>
        <Textarea
          id={props.name}
          autoComplete="off"
          overrides={{
            Root: {
              style: ({ $isFocused, $error }) => ({
                borderRadius: "5px",
                borderWidth: "1px",
                borderColor: $isFocused
                  ? $error
                    ? "#FD372A"
                    : "#516BEB"
                  : $error
                  ? "#FD372A"
                  : "#CDCED9",
                padding: paddingTextArea ? paddingTextArea : "",
                backgroundColor: "#ffffff",
                height: height ? height : "",
              }),
            },
            Input: {
              style: ({ $isFocused }) => ({
                backgroundColor: "#ffffff",
                "::placeholder": {
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                },
              }),
            },
          }}
          {...props}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
            onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
            onBlur();
          }}
        >
          {children}
        </Textarea>
      </div>
    </FormControl>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
