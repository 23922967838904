import React, { useRef, useContext, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { LabelLarge } from "baseui/typography";
import { setDashBoardDashboardCustomerActionStage } from "../../redux/customerSummary/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Select } from "baseui/select";
import millify from "millify";
import { getOrganizationDashboard } from "../../services/organization";
import { useQuery } from "react-query";
import { useIntl } from "react-intl";
import PromiseStatsChart from "./PromiseStatsChart";
import DisputeStatsChart from "./DisputeStatsChart";
import CloseIcon from "../../assets/img/svg/Close";
import ActionHold from "./ActionHold";

/* Need to change this component */

// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatusChartType = ({ isPophover, setIsPophover }) => {
  switch (isPophover?.type) {
    case "promise_in_place":
      return <PromiseStatsChart setIsPophover={setIsPophover} />;

    case "action_on_hold":
      return <ActionHold setIsPophover={setIsPophover} />;

    case "active_disputes":
      return <DisputeStatsChart setIsPophover={setIsPophover} />;

    default:
      return null;
  }
};

export function DougnutCustomerActionStage() {
  let { currentOrganization, appAccessRest, referenceData } =
    useContext(MaxyfiContext);

  const [isPophover, setIsPophover] = useState({ isMount: false, type: "" });

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `AGENT_DASHBOARD_${currentOrganization}_CUSTOM_ACTION_STAGE`,
      {
        filters: {
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "CUSTOMER_ACTION_STAGE",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 1800000,
    }
  );

  const [value, setValue] = React.useState([
    { label: "Consumer Count", id: "count" },
  ]);

  let doughNutData =
    value && value[0] && value[0].id === "count"
      ? (data && data.data && data.data.doc && data.data.doc.customer_stage) ||
        []
      : (data &&
          data.data &&
          data.data.doc &&
          data.data.doc.customer_stage_amount) ||
        [];

  const navigate = useNavigate();
  let dispatch = useDispatch();

  const CustomerStageFilterRd = referenceData?.customer_stage_filter;
  const intl = useIntl();

  let labels = Object.keys(doughNutData).map(
    (key) =>
      intl.formatMessage({
        id: key,
      }) +
      " " +
      `(${
        value && value[0] && value[0].id === "count"
          ? doughNutData[key]
          : millify(doughNutData[key])
      })`
  );

  let dataOne = {
    labels: [...labels],
    datasets: [
      {
        label: "",
        data: Object.values(doughNutData),
        backgroundColor: [
          "#26727B",
          "#58595B",
          "#3B3689",
          "#609966",
          "#48B24F",
          "#2F89FC",
          "#ff1a1a",
          "#D21F75",
          "#E57438",
          "#569D79",
          "#E4B031",
          "#AFAFAF",
          "#50AED3",
          "#65338D",
          "#591a54",
          "#011a6d",
          "#5a1740",
          "#b42c20",
          "#810703",
          "#106146",
          "#090741",
          "#441225",
          "#8a1f5d",
          "#535a53",
          "#a52313",
          "#402107",
          "#821628",
          "#6e1f92",
          "#5e1267",
          "#305960",
          "#275642",
          "#412952",
          "#411e15",
          "#3c090d",
        ],
      },
    ],
  };

  const chartRef = useRef();
  const onClick = (event) => {
    let Datas = Object.keys(doughNutData);
    let filteringSelectedCustomerActionStage = CustomerStageFilterRd.filter(
      (i) =>
        i && i?.id
          ? i?.id ===
            Datas[
              getElementAtEvent(chartRef.current, event) &&
                getElementAtEvent(chartRef.current, event)[0] &&
                getElementAtEvent(chartRef.current, event)[0].index
            ]
          : []
    );

    // if (
    //   (filteringSelectedCustomerActionStage &&
    //     filteringSelectedCustomerActionStage[0] &&
    //     filteringSelectedCustomerActionStage[0].id == "promise_in_place") ||
    //   (filteringSelectedCustomerActionStage &&
    //     filteringSelectedCustomerActionStage[0] &&
    //     filteringSelectedCustomerActionStage[0].id == "action_on_hold") ||
    //   (filteringSelectedCustomerActionStage &&
    //     filteringSelectedCustomerActionStage[0] &&
    //     filteringSelectedCustomerActionStage[0].id == "active_disputes")
    // ) {
    //   setIsPophover({
    //     isMount: true,
    //     type:
    //       filteringSelectedCustomerActionStage &&
    //       filteringSelectedCustomerActionStage[0] &&
    //       filteringSelectedCustomerActionStage[0].id,
    //   });
    // } else
    if (
      filteringSelectedCustomerActionStage &&
      filteringSelectedCustomerActionStage[0] &&
      filteringSelectedCustomerActionStage[0].id
    ) {
      dispatch(
        setDashBoardDashboardCustomerActionStage({
          key:
            filteringSelectedCustomerActionStage &&
            filteringSelectedCustomerActionStage[0] &&
            filteringSelectedCustomerActionStage[0].id,
          path: "CUSTOMER",
        })
      );
      navigate(`/customers`);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        flex: "1",
        boxShadow: "0px 8px 14px 0px rgba(149, 157, 165, 0.20)",
        borderRadius: "5px",
        border: "1px solid #cdced9",
      }}
    >
      <div
        className="db-action__table-head"
        style={{ justifyContent: "space-between", padding: "0px 20px" }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <LabelLarge>Consumer Action Stage</LabelLarge>
        </div>

        <div style={{ width: "160px" }}>
          <Select
            size={SIZE.compact}
            clearable={false}
            backspaceRemoves={false}
            overrides={{
              Tag: {
                props: {
                  overrides: {
                    Text: {
                      style: ({ $theme }) => ({
                        maxWidth: "500px",
                      }),
                    },
                  },
                },
              },
              Placeholder: {
                style: ({ $theme, $isFocused }) => ({
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                }),
              },

              // DropdownListItem: {

              // },

              // DropdownOption: {

              // },
              // Dropdown: {
              //   style: ({ $theme }) => ({
              //     maxHeight: "370px",
              //   }),
              // },

              // expand arrow need to change

              // IconsContainer: (props) => {
              //   return (
              //     <div className="filter__select">
              //       {props.$isOpen ? <UpArrow /> : <DownArrow />}
              //     </div>
              //   );
              // },
              ControlContainer: {
                style: ({ $theme, $isFocused, $error }) => ({
                  borderRadius: "5px",
                  backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                  borderRadius: "3px",
                  borderColor: "#CDCED9",
                  borderWidth: "0.5px",
                  borderStyle: "solid",
                  paddingRight: "0px",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  borderWidth: "1px",
                  borderColor: $isFocused
                    ? $error
                      ? "#FD372A"
                      : "#516BEB"
                    : $error
                    ? "#FD372A"
                    : "#CDCED9",
                }),
              },
            }}
            options={[
              { label: "Consumer Count", id: "count" },
              { label: "Outstanding Amount", id: "amount" },
            ]}
            value={value}
            placeholder="Select color"
            onChange={(params) => setValue(params.value)}
          />
        </div>
      </div>
      <div
        style={{
          flex: "1",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "75%",
          padding: "10px",
          position: "relative",
        }}
      >
        <Doughnut
          ref={chartRef}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: "right",
              },
            },
          }}
          data={dataOne}
          onClick={onClick}
        />
        {isPophover.isMount && (
          <div>
            <div
              style={{
                position: "absolute",
                // zIndex: "",
                top: "-80%",
                left: "50%",
                width: "100%",
                boxShadow: "",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    border: "1px solid #cdced9",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setIsPophover(false);
                    }}
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    type="button"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "5px",
                  border: "1px solid #cdced9",
                }}
              >
                <StatusChartType
                  isPophover={isPophover}
                  setIsPophover={setIsPophover}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DougnutCustomerActionStage;
