import React from "react";

export default function ContactUpdate({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5227 9.92496C11.3717 9.8811 11.2234 9.8424 11.0776 9.8037C10.3357 9.61793 9.58859 9.52247 8.83367 9.52247C8.07876 9.52247 7.32905 9.61277 6.58194 9.79338C5.83484 9.97398 5.05389 10.2449 4.2391 10.6061C3.87466 10.7738 3.5779 11.0215 3.34622 11.3466C3.11454 11.6717 3 12.0639 3 12.5205V13.1346C3 13.4365 3.10673 13.6919 3.31759 13.9035C3.53104 14.115 3.78876 14.2182 4.09333 14.2182H10.0103C9.90617 13.8673 9.8489 13.4958 9.8489 13.1088C9.8489 11.7955 10.5101 10.6293 11.5227 9.92496ZM10.8043 3.75855C10.294 3.25285 9.63805 3 8.83367 3C8.0319 3 7.3759 3.25285 6.86569 3.75855C6.35547 4.26424 6.10036 4.91443 6.10036 5.71167C6.10036 6.50634 6.35547 7.15653 6.86569 7.66222C7.3759 8.16792 8.0319 8.42077 8.83367 8.42077C9.63544 8.42077 10.2914 8.16792 10.8043 7.66222C11.3145 7.15653 11.5696 6.50634 11.5696 5.71167C11.5696 4.91443 11.3145 4.26424 10.8043 3.75855ZM11.9575 13.0624C12.0329 12.9772 11.9705 12.8456 11.8585 12.8456H11.5175C11.5149 12.8121 11.5149 12.7785 11.5149 12.745C11.5149 11.9039 12.2074 11.2176 13.056 11.2176C13.4647 11.2176 13.8369 11.375 14.1129 11.633L14.5945 11.0757C14.1727 10.6938 13.6313 10.4848 13.0586 10.4848C12.4495 10.4848 11.8768 10.7196 11.4472 11.1453C11.0177 11.5711 10.7808 12.1387 10.7808 12.7424C10.7808 12.776 10.7808 12.8095 10.7834 12.843H10.4971C10.3825 12.843 10.3227 12.9746 10.3982 13.0598L11.0464 13.7977L11.1817 13.9525L11.6711 13.3978L11.9575 13.0624ZM15.7164 12.6315L15.2322 12.0819L14.9303 11.7388L14.5033 12.229L14.1493 12.6315C14.0738 12.7166 14.1363 12.8482 14.2482 12.8482H14.5892C14.5346 13.6403 13.8656 14.2673 13.0534 14.2673C12.6994 14.2673 12.3714 14.1486 12.111 13.9473L11.6295 14.5046C12.0329 14.8246 12.5301 15 13.0534 15C13.6625 15 14.2352 14.7652 14.6647 14.3395C15.0708 13.937 15.3025 13.4132 15.3311 12.8482H15.6175C15.732 12.8508 15.7919 12.7166 15.7164 12.6315Z"
        fill={color}
      />
    </svg>
  );
}
