import React from "react";
import { SIZE, SelectBox } from "../../../../components/SelectBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "../../../../components_v2";
import moment from "moment";
import { useContext } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useState } from "react";
import ActionReminders from "../../../../components_v2/ActionReminders";
import {
  ACT_FIELD_VISIT,
  ACT_PROMISE_TO_PAY,
  FIELD_UNSUCCESSFUL,
  NEW,
} from "../../../../constants";
import { KIND, TextButton } from "../../../../components/TextButton";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import getSelectValues from "../../../../utils/getSelectValues";

const FieldVisitUnsuccessForm = (props) => {
  const intl = useIntl();
  const { customerId } = useParams();
  const {
    service,
    formValues = {},
    onSuccess = () => {},
    onCancel = () => {},
  } = props;
  const { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);
  const [today, setToday] = useState(false);
  const [tomorrow, setTomorrow] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    //   {
    //     "reason": "yetg",
    //     "field_visit_id": "65b5e132733954fc7656fd19",
    //     "visit_status": "UNSUCCESSFULL",
    //     "action_type": "FIELD_VISIT",
    //     "follow_up_time": "9_30_AM",
    //     "follow_up_date": 1706639400000,
    //     "is_hold_workflow": false,
    //     "is_send_confirmation_message": false,
    //     "is_send_action_follow_up": false,
    //     "comment": "test one "
    // }
    const { comment, follow_up_date, follow_up_time } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...formValues,
      ...(follow_up_date
        ? {
            follow_up_date: moment
              .tz(
                `${follow_up_date.getFullYear()}-${
                  follow_up_date.getMonth() + 1
                }-${follow_up_date.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      follow_up_time: getSelectValues(follow_up_time),
      comment,
      ...getActionReminder(data, {
        is_calendar: false,
        is_consent_enabled: false,
        is_incrementor: false,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Typography>
          <FormattedMessage id="follow_up">follow_up</FormattedMessage> :
        </Typography>
      </div>
      <div className="cp-action-promise-amount_input">
        <div
          onClick={() => {
            setValue("follow_up_date", moment().startOf("day").toDate());
            setToday(true);
            setTomorrow(false);
          }}
        >
          <div
            className={
              today === true
                ? "cp-action-call-unsuccessful--active"
                : "cp-action-call-unsuccessful"
            }
          >
            <div>Today</div>
          </div>
        </div>
        <div
          onClick={() => {
            setTomorrow(true);
            setToday(false);
            setValue(
              "follow_up_date",
              moment().add(1, "days").startOf("day").toDate()
            );
          }}
        >
          <div
            className={
              tomorrow === true
                ? "cp-action-call-unsuccessful--active"
                : "cp-action-call-unsuccessful"
            }
          >
            <div>Tomorrow</div>
          </div>
        </div>

        <div style={{ width: "156px", paddingTop: "10px" }}>
          <Controller
            name="follow_up_date"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                // disabled={true}
                name={field.name}
                clearable={true}
                size={SIZE.compact}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "select_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.date);
                }}
                minDate={new Date()}
              />
            )}
          />
        </div>

        <div style={{ paddingTop: "10px", width: "120px" }}>
          <Controller
            name="follow_up_time"
            control={control}
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.compact}
                {...field}
                name={field.name}
                // requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "follow_up_time",
                })}
                placeholder={intl.formatMessage({
                  id: "follow_up_time",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["time"]}
              />
            )}
          />
        </div>
      </div>
      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        is_calendar={false}
        actionType={formValues.action_type}
        outcome={FIELD_UNSUCCESSFUL}
        is_consent_enabled={false}
        is_incrementor={false}
        is_consent={false}
        is_valid_from_contact={true}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // paddingTop: "25px",
        }}
      >
        <div
          style={{
            width: "80px",
            paddingRight: "10px",
          }}
        >
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              props.setState({ type: NEW });
            }}
          >
            Cancel
          </TextButton>
        </div>
        <div style={{ width: "80px" }}>
          <TextButton
            kind={KIND.primary}
            size={SIZE.mini}
            isLoading={isSubmitting}
            fullWidth
            type="submit"
          >
            Save
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default FieldVisitUnsuccessForm;
