import React, { useContext } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { assignSkipTrace, assignWorkQueue } from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import moment from "moment";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { TextBox } from "../TextBox";
import { useEffect } from "react";
import setSelectValues from "../../utils/setSelectValues";
import { Typography } from "../../components_v2";
import _ from "lodash";
import queryClient from "../../providers/queryClient";
import { BUSINESS_UNIT_MAINTENANCE, CUSTOMER_OVERVIEW_ } from "../../constants";
const WorkQueueForm = ({
  workQueue,
  customerId,
  unAssignType,
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected = () => {},
  customerRefetch,
  isAllRowsSelected,
  queryFilter,
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    referenceData,
    space,
    appAccessRest,
    refetch,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;
  const intl = useIntl();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      typ: workQueue ? [{ id: "AWQ", label: "Assign Work Queue" }] : [], // Initialize type_queue with an empty string
      wq: workQueue ? setSelectValues(workQueue) : [], // Initialize work_queue with an empty string
    },
  });
  const typeQueueValue = watch("typ");

  let { call_unsuccessful_reason, follow_up_call } = watch();

  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );
  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );
  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  const assignWorkQueueCustomer = useMutation(
    (relMang) =>
      assignWorkQueue({
        ...relMang,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);

        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        if (
          call_unsuccessful_reason &&
          call_unsuccessful_reason[0] &&
          call_unsuccessful_reason[0].isCreatable
        ) {
          refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        handleModal();
      },
    }
  );

  const onSubmit = async (data) => {
    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      // customerQueryFilter: queryFilter && queryFilter.display_name,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    let { typ, wq } = data;
    let values = {
      ...(typ ? { typ: getSelectValues(typ) } : {}),
      ...(typ ? { wq: getSelectValues(wq) } : {}),
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customers: customerId ? [customerId] : selectedCustomer }),
      ...(data.is_all_customer
        ? { is_all_customer: data.is_all_customer }
        : { is_all_customer: false }),
    };

    await assignWorkQueueCustomer.mutateAsync(values);
  };
  const addValuePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      <form
        style={{
          display: "flex",
          flexDirection: "column",

          gap: "10px",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography type="p" className="regular" su>
          Would you like to{" "}
        </Typography>
        <Controller
          control={control}
          name="typ"
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              //   label={intl.formatMessage({
              //     id: "select",
              //   })}
              placeholder={intl.formatMessage({
                id: "select",
              })}
              size={SIZE.mini}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={referenceData["bulk_assign_work_queue_type"]}
            />
          )}
        />
        {typeQueueValue && _.get(typeQueueValue[0], "id", "") === "AWQ" && (
          <>
            <Typography type="p" className="regular">
              Select the Work Queue
            </Typography>
            <Controller
              control={control}
              name="wq"
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  creatable={addValuePermission ? true : false}
                  name={field.name}
                  requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "select",
                  })}
                  placeholder={intl.formatMessage({
                    id: "select",
                  })}
                  size={SIZE.mini}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["work_queue_list"]}
                />
              )}
            />
          </>
        )}

        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall
                  style={{ color: "#333860", textAlign: "center" }}
                >
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default WorkQueueForm;
