import { useKeycloak } from "@react-keycloak/web";

const AuthProvider = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  if (keycloak.authenticated) {
    return children;
  } else {
    keycloak.login();
    return null;
  }
};

export default AuthProvider;
