import React from "react";

const BackArrow = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0444 9.67822L19.0709 9.68398H7.69532L11.2714 6.1305C11.4465 5.95701 11.5426 5.72199 11.5426 5.47533C11.5426 5.22866 11.4465 4.99529 11.2714 4.82139L10.715 4.26941C10.54 4.09593 10.3068 4 10.0582 4C9.8094 4 9.5761 4.09524 9.40112 4.26873L3.27104 10.3461C3.09537 10.5203 2.99931 10.7523 3 10.9991C2.99931 11.2473 3.09537 11.4794 3.27104 11.6533L9.40112 17.7313C9.5761 17.9046 9.80926 18 10.0582 18C10.3068 18 10.54 17.9045 10.715 17.7313L11.2714 17.1793C11.4465 17.0061 11.5426 16.7748 11.5426 16.5281C11.5426 16.2816 11.4465 16.0624 11.2714 15.8891L7.65496 12.3158H19.0571C19.5695 12.3158 20 11.8779 20 11.3702V10.5895C20 10.0818 19.5568 9.67822 19.0444 9.67822Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default BackArrow;
