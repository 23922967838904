import { Modal, SIZE } from "baseui/modal";
import { Label1, Paragraph2 } from "baseui/typography";
import React, { useContext, useState, useEffect } from "react";
import DeletePdf from "../../../../../../assets/img/svg/DeletePdf";
import UploadCustomerInvoice from "../../../../../../assets/img/svg/UploadCustomerIvoice";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
// import UploadFile from "../../assets/img/svg/UploadFile";
import UploadFile from "../../../../../../assets/img/svg/UploadFile";
// import { TextButton } from "../../components/TextButton";
import { TextButton } from "../../../../../../components/TextButton";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
// import { MaxyfiContext } from "../../providers/MaxyfiProvider";
// import { uploadAttachment } from "../../services";
import { uploadAttachment } from "../../../../../../services";
import { useSelector, useDispatch } from "react-redux";
// import { uploadEmailAttachment } from "../../redux/createWorkFlow/actions";
import { uploadEmailAttachment } from "../../../../../../redux/createWorkFlow/actions";

import { addCOEmailAttachment } from "../../../../../../redux/actions";

const SystemAttachment = ({ isModalOpen, close, addAction }) => {
  const dispatch = useDispatch();
  const { currentOrganization, userInfo, referenceData } =
    useContext(MaxyfiContext);

  //   const attachments = useSelector(
  //     (e) => e.createWorkFlowReducer.currentAction.attachments
  //   );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/pdf": [".pdf"],
    },
  });
  const [acceptedFileValues, setAcceptedFileValue] = useState();

  useEffect(() => {
    setAcceptedFileValue(acceptedFiles);
  }, [acceptedFiles]);

  const files = acceptedFiles.map((file) => (
    <div
      key={file.path}
      style={{
        height: "40px",
        border: "0.5px solid #CDCED9",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        borderRadius: "5px",
        width: "200px",
        gap: "5px",
        justifyContent: "space-between",
        padding: "7px",
      }}
    >
      <div style={{}}>
        <UploadCustomerInvoice />
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {file.path}
      </div>
      {/* - {file.size} bytes */}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setAcceptedFileValue([]);
        }}
      >
        <DeletePdf />
      </div>
    </div>
  ));

  const sendletter = useMutation(
    (letterFile) =>
      uploadAttachment({
        letterFile,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
          setIsSubmitting(false);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        const fileUpload = {
          type: "UPLOAD_FILE",
          name: files[0].key,
          ref: data.data.doc.key,
        };
        close();
        acceptedFiles.splice(0, 1);
        // setIsSubmitting(true);

        // TODO handle the condtion of duplicate file

        dispatch(addAction(fileUpload));

        // const duplicateAttachment = attachments.find(
        //   ({ type, name }) => type === "UPLOAD_FILE" && name === fileUpload.name
        // );
        // props.refetch();
      },
    }
  );

  async function sendLetter() {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("file", acceptedFileValues[0]);

    await sendletter.mutateAsync(formData);
  }

  return (
    <Modal
      onClose={() => {
        close();
        acceptedFiles.splice(0, 1);
      }}
      isOpen={isModalOpen}
      closeable={false}
      size={SIZE.auto}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            // padding: "50px 32px",
            // height: "500px",
            // width: "660px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      {/* <AdhocModalTypes close={close} /> */}
      <div style={{ width: "500px", margin: "40px 40px" }}>
        <Label1 style={{ paddingBottom: "24px" }}>
          Upload Consumer - Invoice File
        </Label1>
        <section className="container-adhoc">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <UploadFile />
            <p>
              <b>Click to upload</b> a files(s) or drag and drop
            </p>
          </div>
          <aside>
            <Label1>{files}</Label1>.
          </aside>
        </section>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        ></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <TextButton
              size={SIZE.compact}
              disabled={isSubmitting}
              type="button"
              onClick={() => {
                close();
                acceptedFiles.splice(0, 1);
              }}
              $style={{
                // marginTop: "20px",
                width: "126px",
                height: "36px",
                borderRadius: "5px",
                background: "#fff",
                color: "#333860",
                ":hover": {
                  backgroundColor: "#fff",
                  color: "#333860",
                },
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div>
            <TextButton
              $style={{
                // marginTop: "20px",
                width: "126px",
                height: "36px",
                borderRadius: "5px",
                background: "#516BEB",

                boxShadow: "0px 4px 4px 0px #516beb25",
                ":hover": {
                  boxShadow: "inset 0px 4px 4px 0px #00000025",
                  backgroundColor: "#0348C0",
                },
              }}
              size={SIZE.compact}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                sendLetter();
              }}
            >
              Submit
            </TextButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SystemAttachment;
