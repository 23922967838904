import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { LabelLarge, LabelMedium, LabelSmall } from "baseui/typography";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { IconButton } from "../../components/IconButton";
import {
  downloadAging,
  getOrganizationDashboard,
} from "../../services/organization";
import { SIZE, Select } from "baseui/select";
// import BarChart from "./BarChart";
import { DOWNLOAD_AGING_SUMMARY } from "../../providers/RBACProvider/permissionList";
import Download from "../../assets/img/svg/Download";
import moment from "moment";
import DougnutChart from "./DougnutChart";
import DatepickerBs from "../../components/Datepicker/DatepickerBs";
import { type } from "@testing-library/user-event/dist/type";
import { KIND } from "../../components/TextButton";
import Cancel from "../../assets/img/svg/Template/Cancel";
import { useEffect } from "react";
import DisputeHorizntalBar from "./DisputeHorizntalBar";
import CloseIcon from "../../assets/img/svg/Close";
const DisputeStatsChart = ({ setIsPophover }) => {
  // API Start
  let {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);
  const [isDate, setIsDate] = useState([]);
  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `dashboard-disputer-action-${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
          start_date: isDate[0],
          end_date: isDate[1],
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "DISPUTE_STATS",
        filters,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
  // API End
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState([{ label: "Status", id: "status" }]);
  const [isSelect, setIsSelect] = React.useState([]);
  const [isActive, setIsActive] = useState("WTD");
  const [count, setCount] = useState(0);

  const wtd = (x = 0) => {
    let fliterDate = [];
    return (fliterDate = [
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .startOf("D")
        .subtract(x, "days")
        .utc()
        .valueOf(),
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .endOf("day")
        .utc()
        .valueOf(),
    ]);
  };
  const td = (x = 0) => {
    let arrayOfDate = [];
    arrayOfDate = [
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .startOf("D")
        .subtract(x, "months")
        .utc()
        .valueOf(),
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .endOf("day")
        .utc()
        .valueOf(),
    ];
    return arrayOfDate;
  };

  let WTD = wtd(7);
  let MTD = td(1);
  let QTD = td(3);
  let cd = () => {
    let startDate = moment.utc(isSelect && isSelect[0]).valueOf();
    let endDate = moment.utc(isSelect && isSelect[1]).valueOf();

    let filterDate = [startDate, endDate];
    return filterDate;
  };
  let CD = cd();
  let quickSelectOptions = [
    {
      id: "WTD",
      beginDate: WTD,
      isCustom: false,
    },
    {
      id: "MTD",
      beginDate: MTD,
      isCustom: false,
    },
    {
      id: "QTD",
      beginDate: QTD,
      isCustom: false,
    },

    {
      id: "Custom Date",
      beginDate: CD,
      isCustom: false,
    },
  ];

  useEffect(() => {
    if (isDate.length == 0) {
      setIsDate(WTD);
    }
  });
  useEffect(() => {
    if (count > 0 && isSelect) {
      let startDate =
        value && value[0]
          ? moment(value && value[0])
              .tz(currentDefaultFormatDetails.time_zone)
              .startOf("day")
              .utc()
              .valueOf()
          : "";
      let endDate =
        value && value[1]
          ? moment(value && value[1])
              .tz(currentDefaultFormatDetails.time_zone)
              .endOf("day")
              .utc()
              .valueOf()
          : "";

      let filterDate = [startDate, endDate];
      setIsDate(filterDate);
    }
  }, [count]);
  let labels = [];
  let In_Progress = [];
  let In_Open = [];
  let In_Close = [];

  let labelKey = [];

  data &&
    data?.data &&
    data.data?.doc &&
    data?.data?.doc?.customers.map((e) => {
      if (e.status !== null && e.status !== "") {
        labelKey.push({ ...e });
      }
    });
  labelKey.sort((a, b) => {
    let fa = a.type,
      fb = b.type;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  if (labelKey !== undefined) {
    for (let data of labelKey) {
      if (!labels.includes(data.type)) {
        labels.push(data.type);
      }
    }
    for (let labe of labels) {
      let Datas = labelKey.filter((ele) => ele.type == labe);
      let Opening = Datas.find((ele) => ele.status == "OPEN");
      let Closeing = Datas.find((ele) => ele.status == "CLOSED");
      let Progressing = Datas.find((ele) => ele.status == "IN_PROGRESS");

      if (value && value[0]?.id == "status") {
        In_Open.push(Opening?.count || 0);
        In_Close.push(Closeing?.count || 0);
        In_Progress.push(Progressing?.count || 0);
      } else if (value && value[0].id == "amount") {
        In_Open.push(Opening?.amount || 0);
        In_Close.push(Closeing?.amount || 0);
        In_Progress.push(Progressing?.amount || 0);
      }
    }
  }
  let label = [];
  // DEMO

  //

  return (
    <>
      <div className="db-action__table">
        <div
          className="db-action__table-head"
          style={{ justifyContent: "space-between", padding: "0px 20px" }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <LabelLarge>Dispute Stats</LabelLarge>
            </div>

            <div
              style={{
                display: "flex",
                gap: "1px",
              }}
            >
              {isActive == "Custom Date" ? (
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <DatepickerBs
                    range
                    size={SIZE.mini}
                    value={isSelect}
                    setValue={setIsSelect}
                    placeholder="Custom Date"
                    setIsActive={setIsActive}
                    setCount={setCount}
                    count={count}
                    maxDate={new Date()}
                  />
                  <IconButton
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    onClick={() => {
                      setIsActive("WTD");
                    }}
                  >
                    <Cancel />
                  </IconButton>
                </div>
              ) : (
                quickSelectOptions.map((e) => {
                  return (
                    <>
                      <div
                        onClick={() => {
                          setIsDate(e.beginDate);
                          setIsActive(e.id);
                        }}
                        className={
                          isActive == e.id ? "filter_active" : "report_filter"
                        }
                      >
                        <LabelSmall>{e.id}</LabelSmall>
                      </div>
                    </>
                  );
                })
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "right",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              width: "190px",
              textAlign: "right",
              height: "30px",
              padding: "5px 19px 0px 0px ",
            }}
          >
            <Select
              size={SIZE.compact}
              clearable={false}
              backspaceRemoves={false}
              overrides={{
                Tag: {
                  props: {
                    overrides: {
                      Text: {
                        style: ({ $theme }) => ({
                          maxWidth: "500px",
                        }),
                      },
                    },
                  },
                },
                Placeholder: {
                  style: ({ $theme, $isFocused }) => ({
                    color: $isFocused ? "#cbd5e0" : "transparent",
                    transition: "color 0.4s ease",
                  }),
                },
                ControlContainer: {
                  style: ({ $theme, $isFocused, $error }) => ({
                    borderRadius: "5px",
                    backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                    borderRadius: "3px",
                    borderColor: "#CDCED9",
                    borderWidth: "0.5px",
                    borderStyle: "solid",
                    paddingRight: "0px",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    borderWidth: "1px",
                    borderColor: $isFocused
                      ? $error
                        ? "#FD372A"
                        : "#516BEB"
                      : $error
                      ? "#FD372A"
                      : "#CDCED9",
                  }),
                },
              }}
              options={[
                { label: "Status", id: "status" },
                { label: "Amount", id: "amount" },
              ]}
              value={value}
              placeholder="Select color"
              onChange={(params) => setValue(params.value)}
            />
          </div>
        </div>

        <div>
          <DisputeHorizntalBar value={value} isDate={isDate} />
        </div>
      </div>
    </>
  );
};

export default DisputeStatsChart;
