function SkipEmail() {
  return (
    <>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.3426 0H1.92734C0.867044 0 0.0121886 0.927179 0.0121886 2.05988L0 14.4215C0 15.5542 0.867044 16.4814 1.92734 16.4814H10.206C10.0393 15.2624 10.1628 14.021 10.5661 12.8593C10.9695 11.6977 11.6413 10.649 12.5262 9.79962C13.4111 8.95021 14.4839 8.32435 15.6562 7.97354C16.8285 7.62274 18.0669 7.55701 19.2693 7.78176V2.05988C19.2693 0.927179 18.4023 0 17.3426 0ZM16.9572 4.37899L10.1457 8.93315C9.99406 9.0336 9.8165 9.08713 9.63497 9.08713C9.45343 9.08713 9.27587 9.0336 9.12426 8.93315L2.31269 4.37899C2.19335 4.29626 2.09588 4.18548 2.02872 4.05626C1.96157 3.92704 1.92677 3.78329 1.92734 3.63748C1.92734 2.94735 2.63072 2.53515 3.17973 2.89598L9.63497 7.21074L16.0902 2.89598C16.6392 2.53515 17.3426 2.94735 17.3426 3.63748C17.3432 3.78329 17.3084 3.92704 17.2412 4.05626C17.1741 4.18548 17.0766 4.29626 16.9572 4.37899V4.37899Z"
          fill="#ADADAD"
        />
        <path
          d="M14.237 17.3824L17.429 15.1176C17.5008 15.0661 17.5594 14.9981 17.5999 14.9192C17.6403 14.8404 17.6614 14.7529 17.6614 14.6642C17.6614 14.5754 17.6403 14.488 17.5999 14.4091C17.5594 14.3303 17.5008 14.2623 17.429 14.2108L14.237 11.9401C14.1538 11.8818 14.0564 11.8474 13.9551 11.8408C13.8539 11.8341 13.7528 11.8555 13.6628 11.9025C13.5728 11.9496 13.4973 12.0205 13.4444 12.1076C13.3916 12.1947 13.3634 12.2947 13.363 12.3967V16.9258C13.3634 17.0278 13.3916 17.1278 13.4444 17.215C13.4973 17.3021 13.5728 17.373 13.6628 17.42C13.7528 17.467 13.8539 17.4884 13.9551 17.4818C14.0564 17.4751 14.1538 17.4408 14.237 17.3824ZM18.8938 11.8794V17.4437C18.8938 17.5913 18.952 17.7328 19.0558 17.8371C19.1595 17.9415 19.3002 18.0001 19.4468 18.0001C19.5935 18.0001 19.7342 17.9415 19.8379 17.8371C19.9416 17.7328 19.9999 17.5913 19.9999 17.4437V11.8794C19.9999 11.7319 19.9416 11.5903 19.8379 11.486C19.7342 11.3816 19.5935 11.323 19.4468 11.323C19.3002 11.323 19.1595 11.3816 19.0558 11.486C18.952 11.5903 18.8938 11.7319 18.8938 11.8794V11.8794Z"
          fill="#FD372A"
        />
      </svg>
    </>
  );
}

export default SkipEmail;
