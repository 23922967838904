function SkippedWorkflow() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle style={{ fill: "#ADADAD" }} cx="11.1" cy="11" r="10" />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M8.1,13.9l3.7-2.6c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.2
		L8.1,7.7C8,7.6,7.9,7.6,7.8,7.6c-0.1,0-0.2,0-0.3,0.1C7.3,7.7,7.2,7.8,7.2,7.9C7.1,8,7.1,8.1,7.1,8.2v5.2c0,0.1,0,0.2,0.1,0.3
		c0.1,0.1,0.1,0.2,0.3,0.2C7.5,14,7.6,14,7.8,14C7.9,14,8,13.9,8.1,13.9z M13.4,7.6v6.3c0,0.2,0.1,0.3,0.2,0.4
		c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4V7.6c0-0.2-0.1-0.3-0.2-0.4C14.4,7,14.2,7,14.1,7
		c-0.2,0-0.3,0.1-0.4,0.2C13.5,7.3,13.4,7.4,13.4,7.6z"
        />
      </svg>
    </>
  );
}

export default SkippedWorkflow;
