const QuillFrame = (props) => {
  let { content } = props;
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        border: "0.5px solid #cdced9",
        borderRadius: "5px",
      }}
    >
      <iframe
        style={{
          height: "100%",
          width: "100%",
          padding: "10px 0px 10px 10px",
        }}
        className="scroll"
        srcDoc={content}
        // src="demo_iframe_srcdoc.htm"
      ></iframe>
    </div>
  );
};

export default QuillFrame;
