import React from "react";
import CardLayout from "../Card";
import { StyledBody, StyledAction } from "baseui/card";
import ReactCircle from "../ReactCircle/ReactCircle";
import SuccessArrow from "../../../assets/img/svg/SuccessArrow";
import SuccessSheild from "../../../assets/img/svg/SuccessSheild";
import FaildSheild from "../../../assets/img/svg/FaildSheild";
import { Paragraph2 } from "baseui/typography";

const Paichart = () => {
  return (
    <CardLayout>
      <div>
        <Paragraph2
          style={{
            fontSize: "10px",
            lineHeight: "12px",
            textAlign: "center",
            fontWeight: "700",
            marginTop: "-6px",
            color: "#666666",
          }}
        >
          Last Contact Success
        </Paragraph2>
      </div>
      <div>
        <div style={{ padding: "10px" }}>
          <ReactCircle percentage={60} />
        </div>
        <Paragraph2
          style={{
            fontSize: "10px",
            lineHeight: "12px",
            textAlign: "center",
            fontWeight: "700",
            marginBottom: "5px",
            color: "#666",
          }}
        >
          Last Status
        </Paragraph2>
        <div className="card-head">
          <SuccessSheild />
          <SuccessSheild />
          <FaildSheild />
        </div>
      </div>
    </CardLayout>
  );
};

export default Paichart;
