import React, { useState } from "react";
import { ParagraphSmall, HeadingXLarge } from "baseui/typography";
import SideArrow from "../../../assets/img/svg/SideArrow";
import { useForm } from "react-hook-form";

import { useDispatch } from "react-redux";
import {
  linkedIsModal,
  creditNoteData,
} from "../../../redux/CreditNote/action";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";

const LinkCreditNote = (props) => {
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  const dispatch = useDispatch();
  value = _.get(original, accessor, "");

  let { invoices } = original;

  let invData = invoices.map((e) => e.invoice_number);
  invData.splice(0, 1);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        flex: "1",
        justifyContent: alignment,
        alignItems: "center",
      }}
    >
      <ParagraphSmall
        $style={{
          // color: "#516BEB",
          // textDecoration: "underline",
          textAlign: "end",
          width: "100%",
        }}
      >
        {invoices && invoices[0] && invoices[0].invoice_number}
      </ParagraphSmall>
      <StatefulTooltip
        content={() => (
          <div padding={"20px"}>
            {invData.map((e) => {
              return (
                <ParagraphSmall $style={{ color: "#ffffff" }}>
                  {e}
                </ParagraphSmall>
              );
            })}
          </div>
        )}
        returnFocus
        autoFocus
      >
        {invoices.length > 1 ? (
          <div style={{ paddingLeft: "5px" }}>
            <div
              style={{
                border: "1px solid #333860",
                width: "25px",
                height: "17px",
                borderRadius: "5px",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#333860",
                  fontSize: "13px",
                  fontWeight: "bold",
                }}
              >
                +{invoices.length - 1}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </StatefulTooltip>
    </div>
    // <div
    //   onClick={() => {
    //     dispatch(linkedIsModal());
    //     dispatch(creditNoteData(original));
    //   }}
    // >
    //   {invoices && invoices.length > 0 ? (
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <ParagraphSmall
    //         style={{ textDecoration: "underline", cursor: "pointer" }}
    //       >
    //         More Invoices
    //       </ParagraphSmall>

    //       <div style={{ paddingLeft: "5px" }}>
    //         <div
    //           style={{
    //             border: "1px solid #333860",
    //             width: "25px",
    //             height: "15px",
    //             borderRadius: "5px",
    //             // display: "flex",
    //             // alignItems: "center",
    //             // justifyContent: "center",
    //           }}
    //         >
    //           <div
    //             style={{
    //               color: "#333860",
    //               fontSize: "13px",
    //               fontWeight: "bold",
    //             }}
    //           >
    //             +{invoices.length}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ) : (
    //     <div
    //       style={{
    //         display: "flex",
    //         gap: "5px",
    //         alignItems: "center",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <ParagraphSmall
    //         style={{
    //           color: "#516BEB",
    //           textDecoration: "underline",
    //         }}
    //       >
    //         Link Invoice
    //       </ParagraphSmall>
    //       <SideArrow />
    //     </div>
    //   )}
    // </div>
  );
};

export default LinkCreditNote;
