import React from "react";

const ContactSvg = () => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 0.333984H1.5C0.675 0.333984 0.00749999 1.08398 0.00749999 2.00065L0 12.0007C0 12.9173 0.675 13.6673 1.5 13.6673H13.5C14.325 13.6673 15 12.9173 15 12.0007V2.00065C15 1.08398 14.325 0.333984 13.5 0.333984ZM13.2 3.87565L7.8975 7.55898C7.6575 7.72565 7.3425 7.72565 7.1025 7.55898L1.8 3.87565C1.6125 3.74232 1.5 3.51732 1.5 3.27565C1.5 2.71732 2.0475 2.38398 2.475 2.67565L7.5 6.16732L12.525 2.67565C12.9525 2.38398 13.5 2.71732 13.5 3.27565C13.5 3.51732 13.3875 3.74232 13.2 3.87565Z"
        fill="#4B4F72"
      />
    </svg>
  );
};

export default ContactSvg;
