export default function ChevronLeft({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M7.1376 9.24374C7.1376 9.16041 7.15114 9.08332 7.17821 9.0125C7.2053 8.94166 7.25217 8.87291 7.31884 8.80625L10.9438 5.18124C11.0647 5.06041 11.2105 5 11.3813 5C11.5522 5 11.698 5.06041 11.8188 5.18124C11.9397 5.30208 12.0001 5.44999 12.0001 5.62499C12.0001 5.79999 11.9397 5.94791 11.8188 6.06873L8.64382 9.24374L11.8188 12.4188C11.9397 12.5396 12.0001 12.6854 12.0001 12.8563C12.0001 13.0271 11.9397 13.1729 11.8188 13.2937C11.698 13.4146 11.5501 13.475 11.3751 13.475C11.2001 13.475 11.0522 13.4146 10.9313 13.2937L7.31884 9.68124C7.25217 9.61457 7.2053 9.54582 7.17821 9.47499C7.15114 9.40416 7.1376 9.32708 7.1376 9.24374Z"
        fill={color}
      />
    </svg>
  );
}
