import React, { useContext, useMemo } from "react";
import { Typography } from "../../../components_v2";
import ItemizationForm from "./ItemizationForm";
import { customerInvoiceOverview } from "../../../services";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useSelector } from "react-redux";

const ItemizationDataLayer = ({ closeDrawer }) => {
  const customerData = useSelector((e) => e.customerOverviewDrawer);
  return (
    <div style={{ width: "450px" }}>
      <Typography type="h3">
        {_.get(customerData, "data.actionType") === "EDIT"
          ? "Edit Itemization"
          : "Add New with Itemization"}
      </Typography>
      <ItemizationForm closeDrawer={closeDrawer} customerData={customerData} />
    </div>
  );
};

export default ItemizationDataLayer;
