import React, { useContext, useState } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";
import { TextBox } from "../../TextBox";
import { tokenize } from "excel-formula-tokenizer";
import { Parser } from "hot-formula-parser";
import {
  formulaDispachFn,
  updateFormulaField,
} from "../../../redux/adhocUploadData/action";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { useEffect } from "react";
import { formulas } from "../../../utils/formulaObj";

function UploadFileColumns(props) {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  const [isFormulaUpdate, setIsFormulaUpdate] = useState("");
  const { mappingFieldList } = useSelector((e) => e.adhocDataReducer);
  let validatingData = mappingFieldList.reduce((acc, cur) => {
    acc[cur.index] = cur.first_row_data;
    return acc;
  }, {});
  const debounceFilter = useDebouncedCallback((filter) => {
    excelFormulaToValue({
      formulaString: filter,
      data: validatingData,
    });
  }, 1000);

  useEffect(() => {
    if (value && row?.original?.is_formula) {
      setIsFormulaUpdate(value);
    }
  }, [value, mappingFieldList]);

  value = _.get(row.original, accessor, "");

  const dispatch = useDispatch();

  const [visible, setVisible] = React.useState(false);
  let referenceData = useContext(MaxyfiContext);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  const excelFormulaToValue = ({ formulaString, data }) => {
    try {
      const tokens = tokenize(formulaString);

      let parser = new Parser();

      let isVariable = [];
      for (const { value, type, subtype } of tokens) {
        if (type == "operand" && subtype == "range") {
          isVariable.push(value);
          parser.setVariable(value, data[value]);
        }
      }

      for (const formulaName of Object.keys(formulas)) {
        parser.setFunction(formulaName, formulas[formulaName]);
      }

      // parser.setFunction('VALUE', function (params) {
      //   let removeChr = params && params[0] ? String(params[0]).split(",").join("").replace(/[^0-9-^.]/g, "") : 0
      //   return Number(removeChr).toFixed(2)
      // });

      let { result, error } = parser.parse(formulaString);

      let fields = {
        upload_field: isFormulaUpdate ? isFormulaUpdate : value,
        first_row_data: result || result === 0 ? result : "Enter valid Formula",
        format: null,
        system_field: null,
        isDelete: true,
        is_formula: true,
        rowIndex: row.index,
        isValid: result || result === 0 ? true : false,
        variableUsed: [...isVariable],
      };

      if ((isFormulaUpdate.length > 1 && !error) || error) {
        dispatch(
          updateFormulaField({
            formulaFields: fields,
          })
        );
      }

      return parser.parse(formulaString);
    } catch (error) {
      throw error;
    }
  };

  return visible ? (
    <>
      {!row?.original?.is_formula ? (
        <StatefulTooltip
          accessibilityType={"tooltip"}
          content={
            <div style={{ minWidth: "auto", maxWidth: "200px" }}>{value}</div>
          }
        >
          <Paragraph2
            ref={ref}
            $style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              textAlign: alignment,
              // margin: "5px",
              lineHeight: "18px",
            }}
          >
            <div
              className="elip"
              ref={ref}
              style={{
                color: `${!suffix ? "" : "#757575"}`,
                lineHeight: "18px",
              }}
            >
              {value === 0 ? (
                "-"
              ) : (
                <>
                  {value} {!suffix ? "" : suffix}
                </>
              )}
            </div>
          </Paragraph2>
        </StatefulTooltip>
      ) : (
        <div
          style={{ width: "250px", paddingLeft: "45px", paddingTop: "17px" }}
        >
          <TextBox
            value={isFormulaUpdate}
            size="mini"
            onChange={(e) => {
              setIsFormulaUpdate(e.target.value);
              debounceFilter(e.target.value);
            }}
          // onBlur={(e) => {
          //   excelFormulaToValue({
          //     formulaString: isFormulaUpdate,
          //     data: validatingData,
          //   });
          // }}
          />
        </div>
      )}
    </>
  ) : (
    <>
      {!row?.original?.is_formula ? (
        <Paragraph2
          ref={ref}
          $style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            textAlign: alignment,
            lineHeight: "18px",
            // margin: "5px",
          }}
          style={{ color: `${!suffix ? "" : "#757575"}` }}
        >
          {value === 0 ? (
            "-"
          ) : (
            <>
              {value} {!suffix ? "" : suffix}
            </>
          )}
        </Paragraph2>
      ) : (
        <div
          style={{ width: "250px", paddingLeft: "45px", paddingTop: "17px" }}
        >
          <TextBox
            value={isFormulaUpdate}
            size="mini"
            onChange={(e) => {
              setIsFormulaUpdate(e.target.value);
              debounceFilter(e.target.value);
            }}
          />
        </div>
      )}
    </>
  );
}
export default UploadFileColumns;
