// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;

export const getGridStructure = async ({ menu, organization }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/grid_structure/${menu}`
  );
};
