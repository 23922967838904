import React from "react";

export default function CallRecording({ size = 22, color = "#516BEB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7057 6.65376C12.4241 6.65376 13.0064 6.07139 13.0064 5.353C13.0064 4.63461 12.4241 4.05225 11.7057 4.05225C10.9873 4.05225 10.4049 4.63461 10.4049 5.353C10.4049 6.07139 10.9873 6.65376 11.7057 6.65376Z"
        fill={color}
      />
      <path
        d="M11.7084 1C9.30813 1 7.35538 2.95275 7.35538 5.35303C7.35538 7.7531 9.30813 9.70585 11.7084 9.70585C14.1087 9.70585 16.0612 7.7531 16.0612 5.35303C16.0612 2.95275 14.1087 1 11.7084 1ZM11.7084 8.45237C9.99932 8.45237 8.60885 7.06191 8.60885 5.35303C8.60885 3.64394 9.99932 2.25348 11.7084 2.25348C13.4175 2.25348 14.8077 3.64394 14.8077 5.35303C14.808 7.06211 13.4175 8.45237 11.7084 8.45237Z"
        fill={color}
      />
      <path
        d="M13.5974 11.9105L11.7795 11.703C11.3429 11.6529 10.9134 11.8032 10.6057 12.1109L9.28874 13.4279C7.26323 12.3972 5.60274 10.7439 4.57209 8.71122L5.89619 7.38711C6.20395 7.07935 6.35426 6.64991 6.30415 6.21331L6.09659 4.40967C6.01071 3.68678 5.40234 3.14282 4.6723 3.14282H3.43409C2.62531 3.14282 1.95253 3.81561 2.00263 4.62439C2.38197 10.7367 7.27039 15.618 13.3755 15.9974C14.1843 16.0475 14.8571 15.3747 14.8571 14.5659V13.3277C14.8643 12.6048 14.3203 11.9964 13.5974 11.9105Z"
        fill={color}
      />
    </svg>
  );
}
