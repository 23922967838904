import React, { useState, useContext, useEffect } from "react";
import { IconButton, KIND, SIZE } from "../../../../components/IconButton";
import { TextButton } from "../../../../components/TextButton";
import LeftArrow from "../../../../assets/img/svg/CustomerOverViewIcon/LeftArrow";
import RightArrow from "../../../../assets/img/svg/CustomerOverViewIcon/RightArrow";
import SkipEmail from "../../../../assets/img/svg/CustomerOverViewIcon/SkipEmail";
import ActiveMsg from "../../../../assets/img/svg/CustomerOverViewIcon/ActiveMsg";
import Danger from "../../../../assets/img/svg/CustomerOverViewIcon/Danger";
import Clock from "../../../../assets/img/svg/CustomerOverViewIcon/Clock";
import ProgressCircle from "../../../../assets/img/svg/CustomerOverViewIcon/ProgressCircle";
import CaptureHold from "../../../../assets/img/svg/NestedRibbons/CaptureHold";
import ReleaseOnHold from "../../../../assets/img/svg/NestedRibbons/ReleaseOnHold";
import Next from "../../../../assets/img/svg/CustomerOverViewIcon/Next";
import { ModalContext } from "../../../../providers/ModalProvider";
import EditSvg from "../../../../assets/img/svg/EditSvg";
import Call from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Call";
import OnHoldModal from "../Actions/OnHoldModal";
import WorkflowHold from "../../../../assets/img/svg/CustomerOverViewIcon/WorkflowHold";
import SkippedWorkflow from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/SkippedWorkflow";
import DisputeAction from "./AdhocActionPopup/Dispute";
import Files from "../../../../assets/img/svg/Files";
import ClearFile from "../../../../assets/img/svg/ClearFile";
// import HoldAction from "../../../../assets/img/hold.svg";
import WarningImage from "../../../../assets/img/warning.png";
import Hold from "../../../../assets/img/hold.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { getOrganization } from "../../../../services";
import { getCustomerContactDetail } from "../../../../services";
import { useDropzone } from "react-dropzone";
import {
  documentDrive,
  emailAttachedFile,
  addEmailAttachmentFromDesktop,
  refetchDocumentData,
  unSelectedDocumentData,
  updateEmailAttachmentFromDesktop,
  documentDriveQuickAction,
  typeToInitial,
} from "../../../../redux/customerOverview/DMSystem/action";
import * as Yup from "yup";
import {
  organizationDtl,
  removeCOEmailAttachment,
  removeEmailAttachedFile,
  smsGateway,
  unAssignWorkflow,
} from "../../../../redux/customerOverview/action";
import { holdActionModal } from "../../../../redux/actions";
import { skipActionModal } from "../../../../redux/actions";
import { LabelMedium, LabelSmall, LabelXSmall } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import Edit from "../../../../assets/img/svg/Template/Edit";
import { StatefulPopover } from "baseui/popover";
import { ParagraphMedium } from "baseui/typography";
import Attachment from "../../../../assets/img/svg/Attachment";
import TemplateAttachment from "./AdhocActionPopup/EmailAttachments/TemplateAttachment";
import SystemAttachment from "./AdhocActionPopup/EmailAttachments/SystemAttachment";
import { addCOEmailAttachment } from "../../../../redux/customerOverview/action";
import {
  SMS,
  LETTER,
  EMAIL,
  SMSACTIVE,
  CALLUNSUCCESSFUL,
  REVIEWPROMISE,
  CALLBACKLATER,
  MEETINGREQUEST,
  PROMISETOPAY,
  DISPUTE,
  ACTIVE,
  SKIPPED,
  IN_QUEUE,
  EXECUTED,
  CALL,
  UNSUCCESSFUL,
  NEXTACTIONTRIGGER,
  ON_HOLD,
  WHATSAPP,
  FIELD_VISIT,
  WORKFLOW,
} from "../../../../constants";
import SkipMsg from "../../../../assets/img/svg/CustomerOverViewIcon/SkipMsg";
import SmsIcon from "../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import InActiveLetter from "../../../../assets/img/svg/CustomerOverViewIcon/InActiveLetter";
import { H2, Label1, LabelLarge, ParagraphLarge } from "baseui/typography";
import { FormattedMessage, FormattedDate } from "react-intl";
import { SelectBox } from "../../../../components/SelectBox";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../components/TextBox";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { TextArea } from "../../../../components/TextArea";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import {
  getCustomerActions,
  executeCustomerAction,
  assignWorkflow,
} from "../../../../services";
import warningImage from "../../../../assets/img/warning.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import AllActionPopOver from "./AllActionPopOver";
import ActiveEmail from "../../../../assets/img/svg/CustomerOverViewIcon/ActiveEmail";
import unSuccessful from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/unSuccessful";
import { toast } from "react-toastify";
import SmsForm from "./SmsForm";
import EmailForm from "./EmailForm";
import LetterForm from "./LetterForm";
import CallForm from "./CallForm";
import { Button } from "baseui/button";
import PromiseToPay from "../../../../assets/img/svg/PromiseToPay";
import MeetingRequest from "../../../../assets/img/svg/MeetingRequest";
import CallBackLater from "../../../../assets/img/svg/CallBackLater";
import { releaseActionModal } from "../../../../redux/actions";
import { uploadEmailAttachment } from "../../../../redux/createWorkFlow/actions";
import Executed from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Executed";
import SkipLetter from "../../../../assets/img/svg/CustomerOverViewIcon/SkipLetter";
import Dispute from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Dispute";
import NextActionForward from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import Loader from "../../../../components/Loader";
import getSelectValues from "../../../../utils/getSelectValues";
import MettingRequest from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/MettingRequest";
import ReviewPromise from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/ReviewPromise";
import CallUnsuccessfull from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/CallUnsuccessfull";
import OnSkipModal from "./OnSkipModal";
import CallSkipped from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/CallSkipped";
import moment from "moment";
import {
  isOpenModal,
  workflowAddModal,
} from "../../../../redux/customerOverview/action";
// import NextActionForward from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
import EmailAction from "./AdhocActionPopup/EmailAction";
import LetterAction from "./AdhocActionPopup/LetterAction";
import SmsAction from "./AdhocActionPopup/SmsAction";
import NextAction from "./AdhocActionPopup/NextAction";
import ManualCall from "./AdhocActionPopup/ManualCall";
import ReviewPromiseAction from "./AdhocActionPopup/ReviewPromiseAction";
import PaymentsAction from "./AdhocActionPopup/Payments";
import ActionEdit from "../../../../assets/img/svg/ActionEdit";
import ActionRemove from "../../../../assets/img/svg/ActionRemove";
import AssignWorkflow from "./AssignWorkflow";
import CallBackLaterSkipped from "../../../../assets/img/svg/CallBackLaterSkipped";
import { utcTimstampToLocalDate } from "../../../../utils/utcTimstampToLocalDate";
import { refetchActions } from "../../../../redux/customerOverview/action";
// Future Action OnSave
import "moment-timezone";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { invoiceEntity } from "../../../../redux/invoiceOverview/action";
import setSelectValues from "../../../../utils/setSelectValues";
import WhatsAppAction from "./AdhocActionPopup/WhatsAppAction";
import {
  customerDriveOpen,
  documentDrawerClose,
} from "../../../../redux/customerOverview/DMSystem/action";
import WhastAppForm from "./WhastAppForm";
import WhatsApp from "../../../../assets/img/svg/Template/WhatsApp";
import LetterStatus from "./AdhocActionPopup/LetterStatus/LetterStatus";
import {
  removeAttachmentData,
  updateLetterAttachmentFromDesktop,
} from "../../../../redux/customerOverview/letter/action";
import { CUSTOMER_WF_SKIP } from "../../../../providers/RBACProvider/permissionList";
import LetterWorkflowAction from "./AdhocActionPopup/LetterWorkflowAction";
import { instantPaymentDrawer } from "../../../../redux/customerOverview/callAction/action";
import FieldVisit from "./AdhocActionPopup/FieldVisit/FieldVisit";
import FieldVisitForm from "./AdhocActionPopup/FieldVisit/FieldVisitForm";
import FieldVisitSvg from "../../../../assets/img/svg/FieldVisitSvg";

const WorkflowTimeline = (props) => {
  let {
    id,
    action_name,
    action_type,
    isSkipped,
    skippedOn,
    planned_on,
    action_status,
    workFlow,
    executed_on,
    is_adhoc_action,
    actionInMessage,
    isAlert,
    is_automatic_action,
    time,
  } = props;

  const { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  let timePlanned = time
    ? referenceData["full_day_time_format"].find(({ id }) => id == time)
    : null;

  const timeZone = orgRef?.time_zone;

  let plannedOn = moment
    .utc(planned_on)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);
  let executedOn = moment
    .utc(executed_on)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  // var today = new Date().toISOString().split("T")[0];

  // var start = moment(plannedDate.toString(), "YYYY-MM-DD");
  // var end = moment(today.toString(), "YYYY-MM-DD");

  //Difference in number of days
  // var inDays = moment.duration(start.diff(end)).asDays();

  const workFlowData = workFlow.status;

  // var a =  moment([])

  let ActionType;
  switch (`${action_type}_${action_status}`) {
    case `${SMS}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <SmsIcon />
        </div>
      );
      break;
    case `${EMAIL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <ActiveEmail />
        </div>
      );
      break;

    case `${LETTER}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <InActiveLetter />
        </div>
      );
      break;
    case `${DISPUTE}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <Dispute />
        </div>
      );
      break;
    case `${UNSUCCESSFUL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <unSuccessful />
        </div>
      );
      break;
    case `${MEETINGREQUEST}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <MettingRequest />
          {/* <div style={{ color: "#ffffff" }}>Hello</div> */}
        </div>
      );
      break;
    case `${CALL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <Call />
        </div>
      );
      break;
    case `${CALLBACKLATER}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <CallBackLater />
        </div>
      );
      break;
    case `${PROMISETOPAY}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <PromiseToPay />
        </div>
      );
      break;
    case `${REVIEWPROMISE}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <ReviewPromise />
        </div>
      );
      break;
    case `${NEXTACTIONTRIGGER}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <NextActionForward />
        </div>
      );
      break;
    case `${CALLUNSUCCESSFUL}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <CallUnsuccessfull />
        </div>
      );
      break;
    case `${WHATSAPP}_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <WhatsApp />
        </div>
      );
      break;

    case `FIELD_VISIT_${ACTIVE}`:
      ActionType = () => (
        <div className="action-active">
          <FieldVisitSvg />
        </div>
      );
      break;

    case `${SMS}_${IN_QUEUE}`:
      ActionType = () => <SmsIcon />;
      break;
    case `${EMAIL}_${IN_QUEUE}`:
      ActionType = () => <ActiveEmail />;
      break;

    case `${LETTER}_${IN_QUEUE}`:
      ActionType = () => <InActiveLetter />;
      break;
    case `${CALL}_${IN_QUEUE}`:
      ActionType = () => <Call />;
      break;
    case `${DISPUTE}_${IN_QUEUE}`:
      ActionType = () => <Dispute />;
      break;
    case `${CALL}_${IN_QUEUE}`:
      ActionType = () => <Call />;
      break;
    case `${UNSUCCESSFUL}_${IN_QUEUE}`:
      ActionType = () => <unSuccessful />;
      break;
    case `${MEETINGREQUEST}_${IN_QUEUE}`:
      ActionType = () => <MettingRequest />;
      break;
    case `${CALLBACKLATER}_${IN_QUEUE}`:
      ActionType = () => <CallBackLater />;
      break;
    case `${PROMISETOPAY}_${IN_QUEUE}`:
      ActionType = () => <PromiseToPay />;
      break;
    case `${REVIEWPROMISE}_${IN_QUEUE}`:
      ActionType = () => <ReviewPromise />;
      break;
    case `${NEXTACTIONTRIGGER}_${IN_QUEUE}`:
      ActionType = () => <NextActionForward />;
      break;
    case `${CALLUNSUCCESSFUL}_${IN_QUEUE}`:
      ActionType = () => <CallUnsuccessfull />;
      break;
    case `FIELD_VISIT_${IN_QUEUE}`:
      ActionType = () => <FieldVisitSvg />;
      break;
    case `${WHATSAPP}_${IN_QUEUE}`:
      ActionType = () => <WhatsApp />;
      break;
    case `${EMAIL}_${SKIPPED}`:
      ActionType = () => <SkipEmail />;
      break;
    case `${SMS}_${SKIPPED}`:
      ActionType = () => <SkipMsg />;
      break;
    case `${LETTER}_${SKIPPED}`:
      ActionType = () => <SkipEmail />;
      break;
    case `${CALL}_${SKIPPED}`:
      ActionType = () => <CallSkipped />;
      break;
    case `${PROMISETOPAY}_${SKIPPED}`:
      ActionType = () => <PromiseToPay />;
      break;
    case `${CALLBACKLATER}_${SKIPPED}`:
      ActionType = () => <CallBackLaterSkipped />;
      break;

    case `FIELD_VISIT_${SKIPPED}`:
      ActionType = () => (
        <div className="action-executed">
          <FieldVisitSvg />
        </div>
      );
      break;
    case `${WHATSAPP}_${SKIPPED}`:
      ActionType = () => <WhatsApp />;
      break;
    case `${EMAIL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <ActiveEmail />
        </div>
      );
      break;
    case `${SMS}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <SmsIcon />
        </div>
      );
      break;
    case `${LETTER}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <InActiveLetter />
        </div>
      );
      break;

    case `${DISPUTE}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <Dispute />
        </div>
      );
      break;

    case `${EMAIL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <ActiveEmail />
        </div>
      );

      break;
    case `${UNSUCCESSFUL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <unSuccessful />
        </div>
      );
      break;
    case `${MEETINGREQUEST}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <MettingRequest />
        </div>
      );
      break;
    case `${CALL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <Call />
        </div>
      );
      break;
    case `${CALLBACKLATER}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <CallBackLater />
        </div>
      );
      break;
    case `${PROMISETOPAY}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <PromiseToPay />
        </div>
      );
      break;
    case `${REVIEWPROMISE}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <ReviewPromise />
        </div>
      );
      break;
    case `${NEXTACTIONTRIGGER}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <NextActionForward />
        </div>
      );
      break;
    case `${CALLUNSUCCESSFUL}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <CallUnsuccessfull />
        </div>
      );
      break;

    case `${WHATSAPP}_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <WhatsApp />
        </div>
      );
      break;

    case `FIELD_VISIT_${EXECUTED}`:
      ActionType = () => (
        <div className="action-executed">
          <FieldVisitSvg color="#fff" />
        </div>
      );
      break;

    default:
      ActionType = () => <></>;
  }

  let ActionStatus;
  switch (action_status) {
    case ACTIVE:
      ActionStatus = (props) => (
        <div className="circle">
          <div className="">{props.children}</div>
          {is_automatic_action && <span className="work_flow_auto">AUTO</span>}
        </div>
      );
      break;

    case IN_QUEUE:
      ActionStatus = (props) => (
        <div
          style={{
            position: "relative",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "2px solid #E5EAFF",
            background: "#E5EAFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div> {props.children}</div>
          {is_automatic_action && (
            <span className="work_flow_auto --auto_in_queue">AUTO</span>
          )}
        </div>
      );
      break;

    case SKIPPED:
      ActionStatus = (props) => (
        <div
          style={{
            position: "relative",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "2px solid #EEEEEE",
            background: "#EEEEEE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>{props.children}</div>
          {is_automatic_action && (
            <span className="work_flow_auto --auto_executed">AUTO</span>
          )}
        </div>
      );
      break;

    case EXECUTED:
      ActionStatus = (props) => (
        <div
          style={{
            position: "relative",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "2px solid #EEEEEE",
            background: "#EEEEEE",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="workflow_executed"
        >
          <div>{props.children}</div>
          {is_automatic_action && (
            <span className="work_flow_auto --auto_executed">AUTO</span>
          )}
        </div>
      );
      break;

    default:
      ActionStatus = () => <></>;
  }

  let ActionLine;
  switch (action_status) {
    case ACTIVE:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #516BEB",
            background: "#516BEB",
            borderRadius: "5px",
          }}
        >
          <div> {props.children} </div>
        </div>
      );
      break;

    case IN_QUEUE:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #E3E3E3",
            background: "#e3e3e3",
            borderRadius: "5px",
          }}
        >
          {props.children}
        </div>
      );
      break;

    case SKIPPED:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #E3E3E3",
            background: "#e3e3e3",
            borderRadius: "5px",
          }}
        >
          {props.children}
        </div>
      );
      break;
    case EXECUTED:
      ActionLine = (props) => (
        <div
          style={{
            width: "100%",
            height: "0px",
            border: "3px solid #E3E3E3",
            background: "#e3e3e3",
            borderRadius: "5px",
          }}
        >
          {props.children}
        </div>
      );
      break;

    default:
      ActionLine = () => <></>;
  }

  let ActionSvg;
  switch (action_status) {
    case ACTIVE:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            top: "0%",
            right: "50%",
            transform: "translate(60%,-70%)",
            maxHeight: "10px",
          }}
        >
          {workFlowData === "ON_HOLD" ? (
            <>
              <WorkflowHold />
            </>
          ) : (
            <>
              <Clock />
            </>
          )}
        </div>
      );
      break;

    case IN_QUEUE:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            top: "38%",
            right: "45%",
            transform: "translate(50%,-50%)",
            maxHeight: "10px",
          }}
        >
          {" "}
          <ProgressCircle />{" "}
        </div>
      );
      break;
    case EXECUTED:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "52%",
            transform: "translate(50%,-50%)",
            maxHeight: "10px",
          }}
        >
          <Executed />
        </div>
      );
      break;
    case SKIPPED:
      ActionSvg = () => (
        <div
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "52%",
            transform: "translate(50%,-50%)",
            maxHeight: "10px",
          }}
        >
          <SkippedWorkflow />
        </div>
      );
      break;

    default:
      ActionSvg = () => <></>;
  }

  return (
    <>
      <div
        key={id}
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          {action_status === ACTIVE ? (
            <div>
              <span>{actionInMessage}</span>
            </div>
          ) : action_status === IN_QUEUE ? (
            <div>
              <span>{actionInMessage}</span>
            </div>
          ) : (
            <div style={{ color: "#ffffff" }}>
              <span>1</span>
            </div>
          )}
          <div
            style={{
              display: "grid",
              position: "relative",
              gridColumn: "1/4",
              gridRow: "3/4",
              margin: "10px 0px",
            }}
          >
            <ActionLine />
            <div>
              <ActionSvg />
            </div>
          </div>
          {action_status === ACTIVE ? (
            <div>{time ? timePlanned && timePlanned.label : plannedOn}</div>
          ) : action_status === IN_QUEUE ? (
            <div>
              <span>{timePlanned && timePlanned.label}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div>
          <>
            <div
              style={{
                gridColumn: "4/6",
                gridRow: "2/4",
                display: "flex",
                justifyContent: "center",
                userSelect: "none",
              }}
            >
              <ActionStatus>
                <ActionType />
              </ActionStatus>
            </div>
            <div
              style={{
                gridColumn: "4/6",
                gridRow: "4/5",
                paddingTop: "9px",
                color: "#333860",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              {action_name}
            </div>
            <div style={{ paddingTop: "5px" }}>
              {action_status === "EXECUTED" && executed_on ? (
                <>{executedOn}</>
              ) : action_status === "SKIPPED" ? (
                <>{executedOn}</>
              ) : (
                <div style={{ color: "#ffffff" }}>
                  <span>1</span>
                </div>
              )}
              {/* {is_adhoc_action === true ? (
                <LabelXSmall style={{ color: "#ADADAD" }}>
                  Customer Action
                </LabelXSmall>
              ) : (
                <></>
              )} */}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const SwitchingAction = ({ type, getParams }) => {
  //

  const { letterAttachment } = useSelector((s) => s.letterReducer);

  if (type === "sms") {
    return (
      <SmsAction
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
      />
    );
  }

  if (type === "next") {
    return <NextAction />;
  }

  if (type === "call") {
    return <ManualCall />;
  }

  if (type === "email") {
    return (
      <EmailAction
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
        removeAttchFile={unSelectedDocumentData}
        updatingDeskUpload={updateEmailAttachmentFromDesktop}
        setAttachment={documentDriveQuickAction}
      />
    );
  }

  if (type === "payments") {
    return <PaymentsAction />;
  }

  if (type === "review") {
    return <ReviewPromiseAction />;
  }

  if (type === "dispute") {
    return <DisputeAction getParams={getParams} text={"customer"} />;
  }
  if (type === "whatsapp") {
    return (
      <WhatsAppAction
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
      />
    );
  }
  if (type === "letter") {
    return (
      <LetterAction
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
        removeAttchFile={removeAttachmentData}
        updatingDeskUpload={updateLetterAttachmentFromDesktop}
        letterAttachmentsData={letterAttachment}
      />
    );
  }
  if (type === "letter_status") {
    return (
      <LetterStatus
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
      />
    );
  }
  if (type === "field_visit") {
    return <FieldVisit />;
  }
  //   case "letter":
  //     {
  //       SwitchingAction = () => <LetterAction />;
  //     }
  //     break;
  return null;
};

/* Displays the form (inputs) below the workflow screen to 
capture the action outcome / make changes to action */

const WorkflowAction = (props) => {
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [emailOptions, setEmailOptions] = useState([]);
  const [smsOptions, setSmsOptions] = useState([]);
  const [callOptions, setCallOptions] = useState([]);
  const [whatsAppOptions, setWhatsAppOptions] = useState([]);
  const { emailAttachment } = useSelector((s) => s.customerDocumentReducer);

  const refetchActionsWf = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  let dispatch = useDispatch();

  let scanFinalStatus =
    emailAttachment && emailAttachment.some((e) => e.scanStatus !== "SUCCESS");

  const getParams = getSearchParams.get("customers");
  let {
    customerActions = [],
    control,
    errors,
    actioWatch,
    callBackActin,
    hideWorkflowArea,
    data,
    setValue,
    callOutcome,
    setCallOutcome,
    setError,
    clearErrors,
    watch,
    setCallRefDetails,
    settingData,
    setInstallments,
    installments,
    disabled,
    setDisable,
    isCompute,
    setIsCompute,
    isCancel,
    setIsCancel,
    fieldVisitStatus,
    setFieldVisit,
    outCome,
    setCome,
  } = props;

  let { customer_actions, contacts, recipients, templateContent, workflow } =
    data;

  useEffect(() => {
    if (data && data?.template_attachments) {
      let emailAttachedData =
        data &&
        data?.template_attachments.map((e) => {
          return {
            ...e,
            fileRef: e.id,
            isTemplate: e.type === "TEMPLATE" ? true : false,
            scanStatus: "SUCCESS",
          };
        });
      dispatch(documentDriveQuickAction(emailAttachedData));
    }
  }, [refetchActionsWf, data?.template_attachments, templateContent?.subject]);

  // const contactData = useQuery(
  //   `customer-contacts-${getParams}`,
  //   async () => {
  //     return await getCustomerContactDetail({
  //       organization: currentOrganization,
  //       customerId: getParams,
  //     });
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  let contactsData = data && data?.contacts;

  useEffect(() => {
    if (contactsData && contactsData.length > 0) {
      let smsOptions = contactsData.reduce((prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = phone
          .map((e) => ({
            ...e,
            id: e._id,
            ...rest,
          }))
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.sms_status === "VALID"
          );
        return nestedContacts;
      });
      setSmsOptions(smsOptions);
    }
  }, [contactsData]);

  let recipientsData = data && data?.recipients;

  let currentAction = customer_actions.find((e) => e.action_status === ACTIVE);
  let type =
    currentAction && currentAction.action_type ? currentAction.action_type : "";
  let isAdhocAction = currentAction ? currentAction.is_adhoc_action : false;
  const { total_outstanding_invoice_amount } = useSelector(
    (state) => state.customerOverviewReducer.customerDetails
  );
  let contactsOptionsEmail = contacts
    .map((e) => {
      let designation = referenceData["recipient_type"].find(
        (rf) => rf.id === e.designation
      );

      if (designation && e.email && e.email[0] && e.email[0].value) {
        let label = designation.label || "";

        return {
          // id: e._id,
          // label: `${e.first_name || ""} - ${label}`,
          id: e._id,
          // label: `${e.first_name || ""} - ${label}`,
          label: `${e.first_name || ""} - ${label} - ${
            e?.email[0]?.value || ""
          }`,
        };
      }

      return null;
    })
    .filter((con) => con);

  useEffect(() => {
    let allEmail = [];
    if (contacts && contacts.lenght > 0) {
      let emailFilterOptions = contactsData.reduce((prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = email
          .map((e) => ({
            ...e,
            id: e._id,
            ...rest,
          }))
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.email_status === "VALID"
          );
        return nestedContacts;
      });
      setEmailOptions(emailFilterOptions);
    }
  }, [contacts]);

  // useEffect(() => {
  //   let allPhoneNumber = [];
  //   if (contacts && Array.isArray(contacts)) {
  //     for (let i of contacts?.filter(
  //       (e) =>
  //         e?.designation === "primary_contact" ||
  //         e?.designation === "escalation_contact"
  //     )) {
  //
  //       for (let j of i?.phone?.filter(
  //         (e) => e?.status === "VALID" || e?.status === "LEAD"
  //       )) {
  //         allPhoneNumber.push({
  //           phone_id: j?._id,
  //           value: j?.value,
  //           recipient_id: i?._id,
  //           country_code: j?.country_code,
  //           name: i?.first_name,
  //           type: i?.designation,
  //         });
  //       }
  //     }
  //   }
  //   // if(allPhoneNumber && Array.isArray(allPhoneNumber)){
  //   //   allPhoneNumber.map
  //   // }
  //   setSmsOptions(allPhoneNumber);

  //
  // }, [contacts, getParams]);

  useEffect(() => {
    let allPhoneNumber = [];
    let allLandLink = [];
    if (contacts && contacts.lenght > 0 && Array.isArray(contacts)) {
      for (let i of contacts?.filter(
        (e) =>
          e?.designation === "primary_contact" ||
          e?.designation === "escalation_contact"
      )) {
        for (let j of i?.phone?.filter(
          (e) => e?.status === "VALID" || e?.status === "LEAD"
        )) {
          allPhoneNumber.push({
            phone_id: j?._id,
            value: j?.value,
            recipient_id: i?._id,
            country_code: j?.country_code,
            name: i?.first_name,
            type: i?.designation,
            basicType: "Phone",
          });
        }

        if (i && i?.landline) {
          for (let j of i?.landline?.filter(
            (e) => e?.status === "VALID" || e?.status === "LEAD"
          )) {
            allLandLink.push({
              phone_id: j?._id,
              value: j?.value,
              recipient_id: i?._id,
              country_code: j?.country_code,
              name: i?.first_name,
              type: i?.designation,
              basicType: "LandLine",
            });
          }
        }
      }
    }

    // if(allPhoneNumber && Array.isArray(allPhoneNumber)){
    //   allPhoneNumber.map
    // }
    // setContactOptions(allPhoneNumber);
    let combinedPhone = [...allPhoneNumber, ...allLandLink];

    setCallOptions(combinedPhone);
  }, [contacts]);

  useEffect(() => {
    let allWhatsAppNumber = [];
    if (contacts && Array.isArray(contacts)) {
      for (let i of contacts?.filter(
        (e) => e?.designation === "primary_contact" || "escalation_contact"
      )) {
        for (let j of i.phone.filter(
          (e) => e?.status === "VALID" || e?.status == "LEAD"
        )) {
          allWhatsAppNumber.push({
            whatsApp_id: j?._id,
            value: j?.value,
            recipient_id: i?._id,
            country_code: j?.country_code,
            name: i?.first_name,
            type: i?.designation,
          });
        }
      }
    }
    // if(allPhoneNumber && Array.isArray(allPhoneNumber)){
    //   allPhoneNumber.map
    // }
    setWhatsAppOptions(allWhatsAppNumber);
  }, [contacts]);

  let contactsOptionsWhatsapp = contacts
    .map((e) => {
      let designation = referenceData["recipient_type"].find(
        (rf) => rf.id === e.designation
      );

      if (designation && e.phone && e.phone[0] && e.phone[0].value) {
        let label = designation.label || "";

        return {
          // id: e._id,
          // label: `${e.first_name || ""} - ${label}`,
          id: e._id,
          // label: `${e.first_name || ""} - ${label}`,
          label: `${e.first_name || ""} - ${label} - ${
            e?.phone[0]?.value || ""
          }`,
        };
      }

      return null;
    })
    .filter((con) => con);

  useEffect(() => {
    setValue("content", (templateContent && templateContent.value) || "");

    if (type === EMAIL) {
      setValue("subject", templateContent && templateContent.subject);
    }

    if (type === CALL) {
      if (
        total_outstanding_invoice_amount &&
        total_outstanding_invoice_amount.value
      ) {
        setValue(
          "promise_amount",
          Number(total_outstanding_invoice_amount.value).toFixed(2)
        );
      }
    }
  }, []);

  const workflowDatas = useSelector(
    (s) => s.customerOverviewReducer.onLoadCustomerActions
  );

  const fieldVisitActive = useSelector(
    (s) => s.customerOverviewReducer.customerActions
  );

  let filteringActionStatus = workflowDatas.filter(
    (i) => i.action_status === "ACTIVE"
  );

  let currentActioDate =
    filteringActionStatus &&
    filteringActionStatus[0] &&
    filteringActionStatus[0].planned_on;

  let findActiveFieldVisit =
    data &&
    Array.isArray(data.customer_actions) &&
    data.customer_actions.find((e) => e.action_status === "ACTIVE");

  //

  switch (type) {
    case SMS:
      return (
        <SmsForm
          Controller={Controller}
          control={control}
          errors={errors}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          cost={{}}
          isAdhocAction={isAdhocAction}
          recipientsData={recipientsData}
          setValue={setValue}
          currentActioDate={currentActioDate}
          contactsData={recipients}
          getParams={getParams}
        />
      );

    case EMAIL:
      return (
        <EmailForm
          Controller={Controller}
          control={control}
          errors={errors}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          isAdhocAction={isAdhocAction}
          setError={setError}
          clearErrors={clearErrors}
          contactsData={recipients}
          recipientsData={recipientsData}
          setValue={setValue}
          currentActioDate={currentActioDate}
          emailAttached={emailAttachment}
          getParams={getParams}
          removeAttchFile={unSelectedDocumentData}
          updatingDeskUpload={updateEmailAttachmentFromDesktop}
          setAttachment={documentDriveQuickAction}
          data={data}
          watch={watch}
        />
      );

    case CALL:
      return (
        <CallForm
          currentAction={currentAction}
          actioWatch={actioWatch}
          Controller={Controller}
          watch={watch}
          control={control}
          errors={errors}
          callBackActin={callBackActin}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          contactsData={recipients}
          templateContent={(templateContent && templateContent.value) || ""}
          callOutcome={callOutcome}
          setCallOutcome={setCallOutcome}
          setValue={setValue}
          isAdhocAction={isAdhocAction}
          setCallRefDetails={setCallRefDetails}
          settingData={settingData}
          setInstallments={setInstallments}
          installments={installments}
          disabled={disabled}
          setDisable={setDisable}
          clearErrors={clearErrors}
          setError={setError}
          setIsCompute={setIsCompute}
          isCompute={isCompute}
          isCancel={isCancel}
          setIsCancel={setIsCancel}
          actionFrom={WORKFLOW}
        />
      );

    case WHATSAPP:
      return (
        <WhastAppForm
          currentAction={currentAction}
          actioWatch={actioWatch}
          Controller={Controller}
          watch={watch}
          control={control}
          errors={errors}
          callBackActin={callBackActin}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          templateContent={templateContent || ""}
          callOutcome={callOutcome}
          setCallOutcome={setCallOutcome}
          setValue={setValue}
          isAdhocAction={isAdhocAction}
          setCallRefDetails={setCallRefDetails}
          settingData={settingData}
          recipientsData={recipientsData}
          getParams={getParams}
          currentActioDate={currentActioDate}
          contactsData={recipients}
          actionFrom={WORKFLOW}
        />
      );

    case LETTER:
      return (
        <LetterWorkflowAction
          Controller={Controller}
          control={control}
          errors={errors}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          isAdhocAction={isAdhocAction}
          setError={setError}
          clearErrors={clearErrors}
          contactsData={recipients}
          recipientsData={recipientsData}
          setValue={setValue}
          currentActioDate={currentActioDate}
          emailAttached={emailAttachment}
          getParams={getParams}
          removeAttchFile={unSelectedDocumentData}
          updatingDeskUpload={updateEmailAttachmentFromDesktop}
          watch={watch}
          actionFrom={WORKFLOW}
        />
      );

    case "FIELD_VISIT":
      return (
        <FieldVisitForm
          Controller={Controller}
          control={control}
          errors={errors}
          hideWorkflowArea={hideWorkflowArea}
          contacts={contacts || []}
          isAdhocAction={isAdhocAction}
          setError={setError}
          clearErrors={clearErrors}
          contactsData={recipients}
          recipientsData={recipientsData}
          setValue={setValue}
          currentActioDate={findActiveFieldVisit}
          emailAttached={emailAttachment}
          getParams={getParams}
          removeAttchFile={unSelectedDocumentData}
          updatingDeskUpload={updateEmailAttachmentFromDesktop}
          watch={watch}
          setCome={setCome}
          outCome={outCome}
          setFieldVisit={setFieldVisit}
          actionFrom={WORKFLOW}
          fieldVisitStatus={fieldVisitStatus}
          // setCome={setIsCompute}
          // outCome={isCompute}
          // setFieldVisit={setFieldVisit}
          // actionFrom={WORKFLOW}
          // fieldVisitStatus={fieldVisitStatus}
          setIsCompute={setIsCompute}
          isCompute={isCompute}
          setInstallments={setInstallments}
          installments={installments}
          disabled={disabled}
          setDisable={setDisable}
        />
      );

    default:
      return () => <></>;
  }
};

/*
Actions tab - Customer Overview Parent Component
*/

const Actions = ({ getParams }) => {
  const [isTemplateModalOpen, setTemplateModalOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [settingData, setSettingData] = useState(null);
  const [callRefDetails, setCallRefDetails] = useState();
  const [installments, setInstallments] = useState([]);

  const [addedAttachment, setAddedAttachment] = useState([]);
  const [callOutcome, setCallOutcome] = useState(null);
  const [fieldVisitStatus, setFieldVisit] = useState("");
  const [outCome, setCome] = useState("");
  const [isFieldOutcome, setIsFieldOutcome] = useState("SUCCESSFULL");

  const [disabled, setDisable] = useState(false);
  const { handleModal } = useContext(ModalContext);
  const {
    referenceData,
    currentOrganization,
    restrictions,
    currentDefaultFormatDetails,
    space,
  } = useContext(MaxyfiContext);
  // const [settingData, setSettingData] = useState(null);

  // const { emailAttachment } = useSelector((s) => s.customerDocumentReducer);
  //

  const { isLoading, isError, data, refetch, isFetching } = useQuery(
    [`customer-actions-${getParams}`],
    async () => {
      return await getCustomerActions({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  const { total_outstanding_invoice_amount } = useSelector(
    (state) => state.customerOverviewReducer.customerDetails
  );

  const { customerDetails, customerConatactDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const workflowDatas = useSelector(
    (s) => s.customerOverviewReducer.onLoadCustomerActions
  );

  let filteringActionStatus = workflowDatas.filter(
    (i) => i.action_status === "ACTIVE"
  );

  let currentActioDate =
    filteringActionStatus &&
    filteringActionStatus[0] &&
    filteringActionStatus[0].planned_on;

  let today = moment().endOf("d").utc().valueOf();

  let dayDifference = today >= currentActioDate;

  const { id } = useParams();
  /*
    Added to refetch actions hold action update
  */
  const refetchActionsWf = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  useEffect(() => {
    refetch();
    setCallOutcome("");
  }, [refetchActionsWf]);

  let currentAction = data?.data.doc.customer_actions?.find(
    (e) => e.action_status === ACTIVE
  );

  let HoldDatas = data && data.data.doc && data.data.doc.on_hold;

  let holdDateFormate = HoldDatas?.content?.hold_action_till
    ? moment
        .utc(HoldDatas?.content?.hold_action_till)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format)
    : "-";

  let type =
    currentAction && currentAction.action_type ? currentAction.action_type : "";

  let status =
    currentAction && currentAction.is_automatic_action
      ? currentAction.is_automatic_action
      : false;

  const dispatch = useDispatch();

  let workflowData = data?.data.doc.workflow;

  const { viewOnly } = useSelector((state) => state.communicationTemplate);

  const [hideWorkflow, setHideWorkflow] = useState(true);

  function hideWorkflowArea() {
    setHideWorkflow(!hideWorkflow);
  }

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        dispatch(addEmailAttachmentFromDesktop(arrObj));
      },
    });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      cc: [],
      bcc: [],
      promise_type: [],
      promise_amount: {
        currency: [
          {
            id: customerDetails?.invoice_currencies?.[0],
            label: customerDetails?.invoice_currencies?.[0],
          },
        ],
      },
    },
    reValidateMode: "onChange",
    // resolver: yupResolver(schema),
  });

  const [isCompute, setIsCompute] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  let paymentPlan =
    customerDetails &&
    customerDetails?.promise_to_pay &&
    customerDetails?.promise_to_pay.some((e) => e.status == "IN_PROGRESS");
  let inProgress =
    customerDetails &&
    customerDetails?.promise_to_pay &&
    customerDetails?.promise_to_pay.find((e) => e.status == "IN_PROGRESS");

  useEffect(() => {
    if (paymentPlan) {
      setIsCancel(true);
    }
  }, [customerDetails?.promise_to_pay]);

  useEffect(() => {
    if (callOutcome === "PAYMENT_PLAN") {
      setIsCompute(true);
    }
  }, [callOutcome]);

  let promiseAmountChangingValue = watch(["promise_amount", "promise_type"]);

  let formValues = watch();

  let paymentStatus = "success";

  //
  let onlyChangedValue = formValues?.invoices?.filter(
    (i) => i.allocatedAmount != undefined
  );
  let changedKeyValues = onlyChangedValue?.map((i) => {
    return { invoice_id: i._id, amount: Number(i.allocatedAmount) };
  });

  useEffect(() => {
    let EmailUser =
      customerConatactDetails &&
      Array.isArray(customerConatactDetails) &&
      customerConatactDetails.some((e) => e.is_valid_email === true);
    let PhoneUser =
      customerConatactDetails &&
      Array.isArray(customerConatactDetails) &&
      customerConatactDetails.some((e) => e.is_valid_phone === true);
    setValue("action_follow_up_template_id", "");
    setValue("action_follow_up_type", "");
    setValue("confirmation_message_type", "");
    setValue("confirmation_message_template_id", "");
    setValue("is_hold_workflow", false);
    if (callOutcome === "PROMISE_TO_PAY") {
      setValue("promise_type", [{ id: "full_amount" }]);
      if (
        settingData &&
        settingData.default_configuration &&
        settingData.default_configuration.promise_to_pay
      ) {
        let fieldKey = Object.keys(
          settingData.default_configuration.promise_to_pay
        );

        fieldKey.map((field) => {
          if (
            field == "action_follow_up_template_id" ||
            field == "action_follow_up_type" ||
            field == "confirmation_message_type" ||
            field == "confirmation_message_template_id"
          ) {
            setValue(
              field,
              setSelectValues(
                settingData.default_configuration.promise_to_pay[field]
              )
            );
          } else {
            setValue(
              field,
              settingData.default_configuration.promise_to_pay[field]
            );
          }
        });
      }
    } else if (callOutcome === "CALL_BACK_LATER") {
      if (
        settingData &&
        settingData.default_configuration &&
        settingData.default_configuration.call_back_later
      ) {
        let fieldKey = Object.keys(
          settingData.default_configuration.call_back_later
        );

        fieldKey.map((field) => {
          if (
            field == "action_follow_up_template_id" ||
            field == "action_follow_up_type" ||
            field == "confirmation_message_type" ||
            field == "confirmation_message_template_id"
          ) {
            setValue(
              field,
              setSelectValues(
                settingData.default_configuration.call_back_later[field]
                  ? settingData.default_configuration.call_back_later[field]
                  : " "
              )
            );
          } else {
            setValue(
              field,
              settingData.default_configuration.call_back_later[field]
            );
          }
        });
      }
    } else if (callOutcome === "UNSUCCESSFUL") {
      if (
        settingData &&
        settingData.default_configuration &&
        settingData.default_configuration.call_unsuccessful
      ) {
        let fieldKey = Object.keys(
          settingData.default_configuration.call_unsuccessful
        );

        fieldKey.map((field) => {
          if (
            field == "action_follow_up_template_id" ||
            field == "action_follow_up_type" ||
            field == "confirmation_message_type" ||
            field == "confirmation_message_template_id"
          ) {
            setValue(
              field,
              setSelectValues(
                settingData.default_configuration.call_unsuccessful[field]
              )
            );
          } else {
            setValue(
              field,
              settingData.default_configuration.call_unsuccessful[field]
            );
          }
        });
      }
    } else if (callOutcome === "PAYMENT_PLAN") {
      const paymentPlanConfig =
        settingData?.default_configuration?.user_captured_payment_plan
          ?.messages;

      setValue(
        "is_hold_workflow",
        settingData?.default_configuration?.user_captured_payment_plan
          ?.is_hold_workflow
      );

      paymentPlanConfig?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (callOutcome === "FIELD_VISIT") {
      const paymentPlanConfig =
        settingData?.default_configuration?.call_field_visit?.messages;

      setValue(
        "is_hold_workflow",
        settingData?.default_configuration?.call_field_visit?.is_hold_workflow
      );

      paymentPlanConfig?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    }
  }, [callOutcome]);

  useEffect(() => {
    let EmailUser =
      customerConatactDetails &&
      Array.isArray(customerConatactDetails) &&
      customerConatactDetails.some((e) => e.is_valid_email === true);
    let PhoneUser =
      customerConatactDetails &&
      Array.isArray(customerConatactDetails) &&
      customerConatactDetails.some((e) => e.is_valid_phone === true);

    setValue("action_follow_up_template_id", []);
    setValue("action_follow_up_type", []);
    setValue("confirmation_message_type", []);
    setValue("confirmation_message_template_id", []);
    setValue("is_send_confirmation_message", false);
    setValue("is_send_action_follow_up", false);

    if (outCome === "RETURN_VISIT") {
      const paymentPlanConfig =
        settingData?.default_configuration?.return_field_visit?.messages;

      setValue(
        "is_hold_workflow",
        settingData?.default_configuration?.return_field_visit?.is_hold_workflow
      );

      paymentPlanConfig?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "PROMISE_TO_PAY") {
      const paymentPlanConfig =
        settingData?.default_configuration?.field_visit_promise_to_pay
          ?.messages;

      setValue(
        "is_hold_workflow",
        settingData?.default_configuration?.field_visit_promise_to_pay
          ?.is_hold_workflow
      );

      paymentPlanConfig?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "CALL_BACK_LATER") {
      const paymentPlanConfig =
        settingData?.default_configuration?.field_visit_call_back_later
          ?.messages;

      setValue(
        "is_hold_workflow",
        settingData?.default_configuration?.field_visit_call_back_later
          ?.is_hold_workflow
      );

      paymentPlanConfig?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "UNSUCCESSFULL") {
      const paymentPlanConfig =
        settingData?.default_configuration?.field_visit_promise_to_pay
          ?.messages;

      setValue(
        "is_hold_workflow",
        settingData?.default_configuration?.field_visit_promise_to_pay
          ?.is_hold_workflow
      );

      paymentPlanConfig?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "PAYMENT_PLAN") {
      const paymentPlanConfig =
        settingData?.default_configuration?.field_visit_payment_plan?.messages;

      setValue(
        "is_hold_workflow",
        settingData?.default_configuration?.field_visit_payment_plan
          ?.is_hold_workflow
      );

      paymentPlanConfig?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    }
  }, [outCome]);

  useEffect(() => {
    if (
      (callOutcome === "PROMISE_TO_PAY" &&
        promiseAmountChangingValue[1][0]?.id === "partial_amount" &&
        promiseAmountChangingValue[0]?.value >
          total_outstanding_invoice_amount?.value) ||
      (outCome === "PROMISE_TO_PAY" &&
        promiseAmountChangingValue[1][0]?.id === "partial_amount" &&
        promiseAmountChangingValue[0]?.value >
          total_outstanding_invoice_amount?.value)
    ) {
      setError("promise_amount.value", {
        type: "custom",
        message: "Amount is Greater than Total Amount",
      });
    } else if (
      (callOutcome === "PROMISE_TO_PAY" &&
        promiseAmountChangingValue[1][0]?.id === "partial_amount" &&
        promiseAmountChangingValue[0]?.value <=
          total_outstanding_invoice_amount?.value) ||
      (outCome === "PROMISE_TO_PAY" &&
        promiseAmountChangingValue[1][0]?.id === "partial_amount" &&
        promiseAmountChangingValue[0]?.value <=
          total_outstanding_invoice_amount?.value)
    ) {
      clearErrors("promise_amount");
    }
    if (
      (callOutcome === "PROMISE_TO_PAY" &&
        promiseAmountChangingValue[1][0]?.id === "full_amount") ||
      (outCome === "PROMISE_TO_PAY" &&
        promiseAmountChangingValue[1][0]?.id === "full_amount")
    ) {
      setValue(
        "promise_amount.value",
        Number(total_outstanding_invoice_amount?.value).toFixed(2)
      );
      clearErrors("promise_amount.value");
    }

    if (
      customerDetails &&
      customerDetails?.invoice_currencies &&
      customerDetails?.invoice_currencies?.[0]
    ) {
      setValue("promise_amount.currency", [
        { id: `${customerDetails?.invoice_currencies?.[0]}` },
      ]);
      clearErrors("promise_amount.currency");
    }
  }, [
    // callOutcome,
    promiseAmountChangingValue[0]?.value,
    promiseAmountChangingValue[1][0]?.id,
  ]);

  // need to check this cleanup
  // useEffect(() => {
  //   return () => {
  //     reset();
  //   };
  // }, []);

  const smsGateWayDatas = currentDefaultFormatDetails?.is_sms_configured;
  const { emailAttachment } = useSelector((s) => s.customerDocumentReducer);
  const emailAttachments =
    emailAttachment &&
    emailAttachment.map((e) => {
      return e.fileRef;
    });

  let scanFinalStatus =
    emailAttachment && emailAttachment.some((e) => e.scanStatus !== "SUCCESS");

  const refetchAction = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );

  let reason = referenceData["hold_action_reason"];
  let filteringValue = reason.filter(
    (i) => i.id === HoldDatas?.content?.hold_reason
  );

  let template = [];
  let emailAttached = [];

  const emailcustomerAttachments =
    emailAttachment &&
    emailAttachment.map((e) => {
      if (e.type == "TEMPLATE") {
        template.push(e.fileRef);
      } else {
        emailAttached.push(e.fileRef);
      }
    });

  const onSubmit = async (data) => {
    let {
      followup_date,
      promise_amount,
      invoice_follow_ups,
      template_id,
      ...restValue
    } = data;

    // let recipientValue = data?.recipients.map((e) => {
    //   return { id: e?.id, recipient_id: e?.recipient_id };
    // });
    let newValue = {
      ...restValue,
      recipients:
        data &&
        data?.recipients &&
        data?.recipients?.map((e) => {
          return { id: e?.id, recipient_id: e?.recipient_id };
        }),
      // attachments: attachments,
      // attachments: emailAttachments,
      ...(emailAttached.length > 0 ? { attachments: emailAttached } : {}),
      // attachments: emailAttached,
      ...(template.length > 0 ? { template_attachments: template } : {}),
    };

    if (type === "email" && data.cc) {
      newValue = { ...newValue, cc: data.cc };
    }

    if (type === "email" && data.bcc) {
      newValue = { ...newValue, bcc: data.bcc };
    }

    if (data.promise_date || fieldVisitStatus === "SUCCESSFULL") {
      newValue = {
        ...(fieldVisitStatus === "SUCCESSFULL"
          ? {
              visit_outcome: outCome,
              visit_person: data?.visit_person?.[0]?.id,
              visit_status: "SUCCESSFULL",
              action_type: "FIELD_VISIT",
            }
          : {
              ...newValue,
              call_outcome: callOutcome,
              call_detail: callRefDetails,
            }),
        promise_date: moment
          .tz(
            `${data?.promise_date?.getFullYear()}-${
              data?.promise_date?.getMonth() + 1
            }-${data?.promise_date?.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        promise_type: getSelectValues(data.promise_type),
        ...(data?.promise_type?.[0]?.id === "no_amount"
          ? {}
          : {
              promise_amount: {
                value: promise_amount.value,
                currency: getSelectValues(promise_amount.currency),
              },
            }),
        ...(data.is_enable_calendar_alert
          ? { calendar_alert_time: getSelectValues(data.calendar_alert_time) }
          : {}),
        is_enable_calendar_alert: data.is_enable_calendar_alert,
      };
    }

    if (data.callback_date || fieldVisitStatus === "SUCCESSFULL") {
      newValue = {
        ...(fieldVisitStatus === "SUCCESSFULL"
          ? {
              visit_outcome: outCome,
              visit_person: data?.visit_person?.[0]?.id,
              visit_status: "SUCCESSFULL",
              action_type: "FIELD_VISIT",
            }
          : {
              ...newValue,
              call_outcome: callOutcome,
              call_detail: callRefDetails,
            }),
        callback_date: moment
          .tz(
            `${data?.callback_date?.getFullYear()}-${
              data?.callback_date?.getMonth() + 1
            }-${data?.callback_date?.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        callback_time: getSelectValues(data.callback_time),
      };
    }

    if (data.dispute_review_date || fieldVisitStatus === "SUCCESSFULL") {
      newValue = {
        ...newValue,
        ...(fieldVisitStatus === "SUCCESSFULL"
          ? {
              visit_outcome: outCome,
              visit_person: data?.visit_person?.[0]?.id,
              visit_status: "SUCCESSFULL",
              action_type: "FIELD_VISIT",
              comment: data.comment,
              dispute_details: data.dispute_details,
              is_hold_workflow: data.is_hold_workflow
                ? data.is_hold_workflow
                : false,
            }
          : {
              ...newValue,
              call_outcome: callOutcome,
              call_detail: callRefDetails,
            }),
        dispute_review_date: moment
          .tz(
            `${data?.dispute_review_date?.getFullYear()}-${
              data?.dispute_review_date?.getMonth() + 1
            }-${data?.dispute_review_date?.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        dispute_type: getSelectValues(data.dispute_type),
      };
    }

    if (data.action_follow_up_type || data.action_follow_up_template_id) {
      newValue = {
        ...newValue,
        action_follow_up_type: getSelectValues(data.action_follow_up_type),
        action_follow_up_template_id: getSelectValues(
          data.action_follow_up_template_id
        ),
      };
    }

    if (callOutcome === "UNSUCCESSFUL") {
      newValue = {
        ...newValue,
        call_unsuccessful_reason: getSelectValues(
          data.call_unsuccessful_reason
        ),
        call_outcome: callOutcome,
        call_detail: callRefDetails,
      };
    }
    if (callOutcome === "PAYMENT_PLAN" || fieldVisitStatus === "SUCCESSFULL") {
      let {
        is_hold_workflow = false,
        is_send_confirmation_message = false,
        is_send_action_follow_up = false,
        confirmation_message_type,
        confirmation_message_template_id,
        action_follow_up_type,
        action_follow_up_template_id,
        comment,
        start_date,
        residual_amount_type,
        amount,
        instalation_amount,
        frequency,
        method,
        content,
        is_enable_calendar_alert = false,
        calendar_alert_time,
        is_debit_first_instalment = false,
      } = data;

      newValue = {
        ...newValue,
        ...(fieldVisitStatus === "SUCCESSFULL"
          ? {
              visit_outcome: outCome,
              visit_person: data?.visit_person?.[0]?.id,
              visit_status: "SUCCESSFULL",
              action_type: "FIELD_VISIT",
              comment: data.comment,
              dispute_details: data.dispute_details,
              is_hold_workflow: data.is_hold_workflow
                ? data.is_hold_workflow
                : false,
            }
          : {
              ...newValue,
              call_outcome: callOutcome,
              call_detail: callRefDetails,
            }),
        frequency: getSelectValues(frequency),
        instalation_amount: {
          currency: customerDetails.default_currency,
          value: instalation_amount,
        },
        // method: getSelectValues(method),
        ...(is_enable_calendar_alert
          ? { calendar_alert_time: getSelectValues(calendar_alert_time) }
          : {}),
        is_enable_calendar_alert,
        is_debit_first_instalment,
        payment_plan_list: installments,
        amount: { currency: customerDetails.default_currency, value: amount },
        residual_amount_type: getSelectValues(residual_amount_type),

        start_date: moment
          .tz(
            `${start_date?.getFullYear()}-${
              start_date?.getMonth() + 1
            }-${start_date?.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        is_hold_workflow,
        call_outcome: callOutcome,
        is_send_confirmation_message,
        is_send_action_follow_up,
        ...(is_send_confirmation_message
          ? {}
          : {
              confirmation_message_type: getSelectValues(
                confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                confirmation_message_template_id
              ),
            }),
        ...(is_send_action_follow_up
          ? {}
          : {
              action_follow_up_type: getSelectValues(action_follow_up_type),
              action_follow_up_template_id: getSelectValues(
                action_follow_up_template_id
              ),
            }),
      };
    }

    if (outCome === "PAYMENT") {
      newValue = {
        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        // content: "",
        payment: {
          date: moment
            .tz(
              `${data?.date?.getFullYear()}-${
                data?.date?.getMonth() + 1
              }-${data?.date?.getDate()}`,
              "YYYY-MM-DD",
              timeZone
            )
            .utc()
            .valueOf(),
          amount: {
            value: data.payment_amount,
            currency: getSelectValues(data.cur),
          },

          payment_currency: getSelectValues(data.cur),
          method: getSelectValues(data.payment_method),
          reference: data.payment_reference,
          status: getSelectValues(data.payment_status),
          ...(data.expected_payment_date &&
          data.expected_payment_date?.getFullYear()
            ? {
                expected_date: moment
                  .tz(
                    `${data?.expected_payment_date?.getFullYear()}-${
                      data?.expected_payment_date?.getMonth() + 1
                    }-${data?.expected_payment_date?.getDate()}`,
                    "YYYY-MM-DD",
                    timeZone
                  )
                  .utc()
                  .valueOf(),
              }
            : { expected_date: 1 }),
          comment: data.comment,
          ...(data.status && data.status[0] && data.status[0].id != "success"
            ? {}
            : { allocated_to: changedKeyValues }),
        },

        // amount: {
        //   value: data.payment_amount,
        //   currency: getSelectValues(data.cur),
        // },
      };
    }

    if (outCome === "RETURN_VISIT") {
      newValue = {
        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        return_visit_time: getSelectValues(data?.return_visit_time),
        return_visit_contact: getSelectValues(data?.return_visit_contact),

        return_visit_date: moment
          .tz(
            `${data?.return_visit_date.getFullYear()}-${
              data?.return_visit_date.getMonth() + 1
            }-${data?.return_visit_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        custom_location: "",
        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        is_send_confirmation_message: data?.is_send_confirmation_message
          ? data?.is_send_confirmation_message
          : false,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up
          ? data?.is_send_action_follow_up
          : false,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    if (callOutcome === "FIELD_VISIT") {
      newValue = {
        ...newValue,
        // visit_person: getSelectValues(data?.visit_person),
        // visit_outcome: outCome,
        // visit_status: fieldVisitStatus,
        action_type: "call",
        call_outcome: callOutcome,
        visit_date: moment
          .tz(
            `${data?.return_visit_date.getFullYear()}-${
              data?.return_visit_date.getMonth() + 1
            }-${data?.return_visit_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        visit_time: getSelectValues(data?.return_visit_time),
        visit_address: getSelectValues(data?.location),
        custom_location: data.custom_location,

        // return_visit_time: getSelectValues(data?.return_visit_time),
        // return_visit_contact: getSelectValues(data?.return_visit_contact),
        // location:getSelectValues(data?.location),
        // return_visit_date: moment
        //   .tz(
        //     `${data?.return_visit_date.getFullYear()}-${
        //       data?.return_visit_date.getMonth() + 1
        //     }-${data?.return_visit_date.getDate()}`,
        //     "YYYY-MM-DD",
        //     timeZone
        //   )
        //   .utc()
        //   .valueOf(),
        // custom_location: "",
        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        is_send_confirmation_message: data?.is_send_confirmation_message
          ? data?.is_send_confirmation_message
          : false,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up
          ? data?.is_send_action_follow_up
          : false,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    if (data.invoice_follow_ups) {
      newValue = {
        ...newValue,
        action_type: "letter",
        ...(data.letter_generated === "system_generator"
          ? { is_system_generated: true }
          : { is_system_generated: false }),

        // ...(data.letter_generated === "system_generator"
        //   ? {
        //       ...(data?.template_id?.lenght > 0
        //         ? {
        //             template_id:
        //               data.template_id &&
        //               data.template_id[0] &&
        //               data.template_id[0].id,
        //           }
        //         : {}),
        //     }
        //   : {}),

        ...(data.letter_generated === "system_generator"
          ? {
              letter_name: template_id && template_id[0] && template_id[0].name,
              ...(template_id
                ? {
                    template_id:
                      template_id && template_id[0] && template_id[0].id,
                  }
                : {}),
            }
          : {
              letter_name:
                data.invoice_follow_ups &&
                data.invoice_follow_ups[0] &&
                data.invoice_follow_ups[0].label,
            }),
        // recipient: [
        //   {
        //     id: data?.recipients?.[0]?.id,
        //     recipient_id: data?.recipients?.[0]?.recipient_id,
        //   },
        // ],
        recipients:
          data &&
          data?.recipients &&
          data?.recipients?.map((e) => {
            return { id: e?.id, recipient_id: e?.recipient_id };
          }),
        // attachments: attachments,
        // attachments: emailAttachments,
        attachments: emailAttached,
        template_attachments: template,
        sub_category: getSelectValues(data.sub_category),
        date_of_communication: moment
          .tz(data.date_of_communication, timeZone)
          .startOf("D")
          .utc()
          .valueOf(),
        delivery_status: getSelectValues(data.delivery_status),
        delivery_method: getSelectValues(data.delivery_method),
        tracking_url: data.tracking_url,
        comments: data.comments,
      };
    }

    if (callOutcome === "PAYMENT") {
      let {
        payment_amount: { value, currency },
        choose_payment,
        is_save_card = false,
        payment_methods,
      } = data;

      if (value > total_outstanding_invoice_amount?.value) {
        return setError("payment_amount.value", {
          message: "Payment Amount is Greater than Outstanding Amount",
        });
      } else if (value <= 0) {
        return setError("payment_amount.value", {
          message: "Enter Valide Amount",
        });
      }

      newValue = {
        action_type: "call",
        recipients: data?.recipients.map((e) => {
          return { id: e?.id, recipient_id: e?.recipient_id };
        }),
        comment: data.comment ? data.comment : "",
        call_outcome: callOutcome,
        payment_method: getSelectValues(payment_methods),
        payment_type: getSelectValues(choose_payment),
        payment_amount: { currency: currency?.[0]?.id, value },
        is_save_card: is_save_card,
      };
    }
    if (followup_date) {
      newValue = {
        ...newValue,
        followup_date: moment
          .tz(
            `${followup_date?.getFullYear()}-${
              followup_date?.getMonth() + 1
            }-${followup_date?.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
      };
    }

    if (outCome === "UNSUCCESSFULL") {
      newValue = {
        reason: getSelectValues(data.reason),
        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        follow_up_time: getSelectValues(data?.follow_up_time),
        ...(data?.follow_up_date
          ? {
              follow_up_date: moment
                .tz(
                  `${data?.follow_up_date?.getFullYear()}-${
                    data?.follow_up_date?.getMonth() + 1
                  }-${data?.follow_up_date?.getDate()}`,
                  "YYYY-MM-DD",
                  timeZone
                )
                .utc()
                .valueOf(),
            }
          : {}),

        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        is_send_confirmation_message: data?.is_send_confirmation_message
          ? data?.is_send_confirmation_message
          : false,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up
          ? data?.is_send_action_follow_up
          : false,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    if (
      data.confirmation_message_type ||
      data.confirmation_message_template_id
    ) {
      newValue = {
        ...newValue,
        confirmation_message_type: getSelectValues(
          data.confirmation_message_type
        ),
        confirmation_message_template_id: getSelectValues(
          data.confirmation_message_template_id
        ),
      };
    }

    if (dayDifference === true) {
      handleModal({
        title: "This is A Future Action",
        content: "Do you still want to continue",
        successCallback: async () => {
          await executeCustomerAction({
            organization: currentOrganization,
            customerId: getParams,
            call_detail: callRefDetails,
            ...newValue,
          }).then((res) => {
            if (
              res &&
              res.data &&
              res.data.doc &&
              res.data.doc.payment_initiate_url
            ) {
              window.open(
                res &&
                  res.data &&
                  res.data.doc &&
                  res.data.doc.payment_initiate_url
              );
              dispatch(refetchActions());
              reset();
              dispatch(isOpenModal(false));
            }
          });

          const {
            promise_amount,
            payment_plan_list,
            is_debit_first_instalment,
          } = newValue;

          if (is_debit_first_instalment) {
            dispatch(
              instantPaymentDrawer({
                modal: true,
                ...(payment_plan_list &&
                payment_plan_list[0] &&
                payment_plan_list[0].promise_amount &&
                payment_plan_list[0].promise_amount.value > 0
                  ? {
                      amount: payment_plan_list[0].promise_amount.value,
                      currency: payment_plan_list[0].promise_amount.currency,
                    }
                  : {}),
              })
            );
          }

          dispatch(refetchActions());
          refetch();
          reset();
          setCallOutcome("");
          dispatch(refetchDocumentData());
        },
        image: warningImage,
        isChildren: false,
        formContent: () => {
          return <></>;
        },
        buttonText: "Confirm",
        closeButtonText: "Cancel",
        isCloseAble: false,
      });
    } else {
      await executeCustomerAction({
        organization: currentOrganization,
        customerId: getParams,
        call_detail: callRefDetails,
        ...newValue,
      }).then((res) => {
        if (
          res &&
          res.data &&
          res.data.doc &&
          res.data.doc.payment_initiate_url
        ) {
          window.open(
            res && res.data && res.data.doc && res.data.doc.payment_initiate_url
          );
          setFieldVisit("");
          dispatch(refetchActions());
          reset();
          dispatch(isOpenModal(false));
        }

        const { promise_amount, payment_plan_list, is_debit_first_instalment } =
          newValue;

        if (is_debit_first_instalment) {
          dispatch(
            instantPaymentDrawer({
              modal: true,
              ...(payment_plan_list &&
              payment_plan_list[0] &&
              payment_plan_list[0].promise_amount &&
              payment_plan_list[0].promise_amount.value > 0
                ? {
                    amount: payment_plan_list[0].promise_amount.value,
                    currency: payment_plan_list[0].promise_amount.currency,
                  }
                : {}),
            })
          );
        }

        if (
          res &&
          res.data &&
          res.data.doc &&
          res.data.doc.payment_initiate_url
        ) {
          window.open(
            res && res.data && res.data.doc && res.data.doc.payment_initiate_url
          );
          dispatch(refetchActions());
          reset();
          dispatch(isOpenModal(false));
        }
      });

      const { promise_amount, payment_plan_list, is_debit_first_instalment } =
        newValue;

      if (is_debit_first_instalment) {
        dispatch(
          instantPaymentDrawer({
            modal: true,
            ...(payment_plan_list &&
            payment_plan_list[0] &&
            payment_plan_list[0].promise_amount &&
            payment_plan_list[0].promise_amount.value > 0
              ? {
                  amount: payment_plan_list[0].promise_amount.value,
                  currency: payment_plan_list[0].promise_amount.currency,
                }
              : {}),
          })
        );
      }
      setFieldVisit("");
      dispatch(typeToInitial());
      dispatch(refetchActions());
      refetch();
      reset();
      setCallOutcome("");
      dispatch(refetchDocumentData());
    }
    // dispatch(refetchActions());
  };

  const actioWatch = watch([
    "is_send_confirmation_message",
    "is_send_action_follow_up",
    "is_send_klq",
    "is_send_meet_1rq",
    "promise_type",
  ]);

  const callBackActin = watch(["is_send_call", "is_send_call_to"]);
  const intl = useIntl();
  const visibleActionCount = 4;

  const [active, setActive] = useState([]);
  const [all, setAll] = useState([]);

  const [isCount, setIsCount] = useState(true);

  const reftch = useSelector((e) => e.customerOverviewReducer.refetchActions);

  useEffect(async () => {
    await getOrganization({ organization: currentOrganization }).then((res) => {
      setSettingData(res.data.doc || {});
      dispatch(organizationDtl({ orgDtl: res.data.doc }));
    });
    //TODO: PRAVEEN FAILURE ERROR HANDLING
  }, [reftch]);

  useEffect(() => {
    if (
      settingData &&
      settingData?.workflow_asigned_customer >= restrictions.MAXAUTOCUST
    ) {
      setIsCount(false);
    }
  }, []);

  useEffect(() => {
    if (
      data &&
      data.data &&
      data.data.doc &&
      data.data.doc.templateContent &&
      data.data.doc.templateContent.attachments
    ) {
      dispatch(addCOEmailAttachment(data.data.doc.templateContent.attachments));
    }

    if (data && data.data && data.data.doc && data.data.doc.customer_actions) {
      // New
      const activeActionIndex = data?.data.doc.customer_actions.findIndex(
        (i) => i.action_status === "ACTIVE"
      );

      let customerActions = data.data.doc.customer_actions || [];

      let startIndex = customerActions[activeActionIndex - 2]
        ? activeActionIndex
        : 0;
      let endIndex = customerActions[startIndex + 2]
        ? startIndex + 4
        : customerActions.length;
      if (endIndex === customerActions.length) {
        startIndex = customerActions.length - 4;
      }

      let inQueueAccumulationDays = 0;
      customerActions = customerActions.map((act, i) => {
        let { planned_on, action_status, action_lead_time } = act;
        let actionData = {
          actionInMessage: "",
          isAlert: false,
        };

        if (planned_on && action_status === "ACTIVE") {
          let dayDiff = moment
            .utc(planned_on)
            ?.tz(timeZone)
            ?.diff(moment.tz(timeZone), "days", true);

          dayDiff = Math.ceil(dayDiff);

          if (dayDiff == 0) {
            actionData.actionInMessage = "Due Today";
            actionData.isAlert = true;
          } else if (dayDiff > 0) {
            actionData.actionInMessage = `In ${dayDiff} Day${
              dayDiff > 1 ? "s" : ""
            }`;
          } else {
            actionData.actionInMessage = "Overdue";
            actionData.isAlert = true;
          }

          if (activeActionIndex === i) {
            inQueueAccumulationDays += dayDiff > 0 ? dayDiff : 0;
          }
        } else if (action_status === "IN_QUEUE") {
          let inDays = inQueueAccumulationDays + action_lead_time;
          inQueueAccumulationDays += action_lead_time;
          actionData.actionInMessage = `In ${inDays} Day${
            inDays > 1 ? "s" : ""
          }`;
        }

        return {
          ...act,
          ...actionData,
        };
      });

      setAll(customerActions);
      setActive(customerActions.slice(startIndex, endIndex));
    }
  }, [data, refetchAction]);

  const next = () => {
    const newActiveElement =
      all[all.findIndex((e) => e._id === active[3]._id) + 1];
    let newActive = [...active.splice(1, visibleActionCount), newActiveElement];

    setActive(newActive);
  };

  const previous = () => {
    const newActiveElement =
      all[all.findIndex((e) => e._id === active[0]._id) - 1];
    let newActive = [
      newActiveElement,
      ...active.splice(0, visibleActionCount - 1),
    ];
    setActive(newActive);
  };

  const quillToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // header dropdown
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ align: [] }], // text align
    ["blockquote", "code-block"], // blocks
    [{ list: "ordered" }, { list: "bullet" }], // lists
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction
    [{ color: [] }, { background: [] }], // dropdown with defaults
    ["clean"], // remove formatting
  ];

  const TypesData = useSelector((state) => state.customerOverviewReducer.type);

  const [back, setBack] = useState(false);
  const isModalOpens = useSelector(
    (s) => s.customerOverviewReducer.modalIsOpen
  );

  if (isLoading || isSubmitting || isFetching) {
    return <Loader />;
  }

  // Attachment
  function Modalclose() {
    setIsModalOpen(false);
    setTemplateModalOpen(false);
  }
  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  // const { customerDetails } = useSelector(
  //   (state) => state.customerOverviewReducer
  // );

  let quickActionEnabel = customerDetails?.is_quick_action;

  let workflowAsigned =
    customerDetails?.workflow && customerDetails?.workflow.id
      ? customerDetails?.workflow.id
      : null;

  let wf = data?.data?.doc?.workflow;

  const mostUsedData = [
    {
      id: "0",
      actionName: "Manual Call",
      type: "call",
      content: "Call Follow-up & capture discussion outcome",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "",
      actionName: "WhatsApp",
      type: "whatsapp",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "3",
      actionName: "Next Action",
      type: "next",
      content: "Capture your planned Next action",
      is_active: true,
      is_disable: !quickActionEnabel,
      is_workflow: workflowAsigned,
    },
    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      is_active: true,
    },
    {
      id: "5",
      actionName: "Letter",
      type: "letter",
      content: "Generate and Send Physical letters",
    },
    // {
    //   id: "6",
    //   actionName: "Hold Action",
    //   type: "hold",
    //   content: "Hold Actions and Customer",
    // },
    {
      id: "7",
      actionName: "Payments",
      type: "payments",
      content: "Capture Payments made by the Customer",
      is_active: findingUser?.accounting_book === "maxyfi",
    },
    {
      id: "8",
      actionName: "Review Promise",
      type: "review",
      content: "Review the promise provided by the Customer",
      is_active: true,
    },
    {
      id: "10",
      actionName: "Letter",
      type: "letter",
      content: "Proceed your letter Action on your Own",
    },
    {
      id: "11",
      actionName: "Letter Status",
      type: "letter_status",
      content: "Change your status of updated Letter",
    },
  ];

  const mostUsedWorkflowData = [
    {
      id: "0",
      actionName: "Manual Call",
      type: "call",
      content: "Call Follow-up & capture discussion outcome",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "1",
      actionName: "SMS",
      type: "sms",
      content: "SMS Reminders with/without an actionable link",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "2",
      actionName: "Email",
      type: "email",
      content: "Email follow-up with Customer",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "",
      actionName: "WhatsApp",
      type: "whatsapp",
      is_active: true,
      is_disable: !quickActionEnabel,
    },
    {
      id: "4",
      actionName: "Dispute",
      type: "dispute",
      content: "Review and Update Dispute Status.",
      is_active: true,
    },
    {
      id: "5",
      actionName: "Letter",
      type: "letter",
      content: "Generate and Send Physical letters",
    },
    // {
    //   id: "6",
    //   actionName: "Hold Action",
    //   type: "hold",
    //   content: "Hold Actions and Customer",
    // },
    {
      id: "7",
      actionName: "Payments",
      type: "payments",
      content: "Capture Payments made by the Customer",
      is_active: findingUser?.accounting_book === "maxyfi",
    },
    {
      id: "8",
      actionName: "Review Promise",
      type: "review",
      content: "Review the promise provided by the Customer",
      is_active: true,
    },
    {
      id: "10",
      actionName: "Letter",
      type: "letter",
      content: "Proceed your letter Action on your Own",
    },
    {
      id: "11",
      actionName: "Letter Status",
      type: "letter_status",
      content: "Change your status of updated Letter",
    },
  ];

  if (all.length == 0 || !data || !data.data || isError) {
    return (
      <AssignWorkflow
        mostUsedData={!wf ? mostUsedData : mostUsedWorkflowData}
        getParams={getParams}
        id={getParams}
        entity={"customer"}
        tags={"CUSTOMER"}
      />
    );
  }

  if (isModalOpens === true) {
    return <SwitchingAction type={TypesData} getParams={getParams} />;
  }
  const resumePermission =
    space && space?.role_permissions?.includes("customer_resume");

  const workFlowAccess =
    space && space?.role_permissions?.includes("customer_wf_action");

  return (
    <>
      <form
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* workflow start */}

        <div
          style={{
            display: "flex",
            padding: "0px 20px",
            flexDirection: "column",
          }}
        >
          {/* Actions Tab - Header Ends */}

          <>
            {/* Actions Tab - Timeline Starts */}
            {/* Actions Tab - Header Starts */}
            {/* TODO: MOVE ABOVE COMPONENT BELOW FORM */}

            {/* TODO: MOVE ABOVE COMPONENT BEFORE FORM */}
            {hideWorkflow ? (
              <>
                {workFlowAccess && (
                  <div
                    style={{
                      display: "flex",
                      width: "100% ",
                      overflow: "hidden",
                      padding: "0px",
                      flexDirection: "row",
                    }}
                  >
                    {(active[0] && active[0]._id) === (all[0] && all[0]._id) ? (
                      <></>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                          onClick={() => {
                            previous();
                          }}
                        >
                          <LeftArrow />
                        </div>
                      </>
                    )}

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "20px 0px 0px 0px",
                        margin: "0px 20px",
                      }}
                    >
                      {active.map((act, i) => {
                        return (
                          <WorkflowTimeline
                            {...act}
                            index={i}
                            workFlow={workflowData}
                          />
                        );
                      })}
                    </div>
                    {(active[active.length - 1] &&
                      active[active.length - 1]._id) ===
                    (all[all.length - 1] && all[all.length - 1]._id) ? (
                      <></>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                          onClick={() => {
                            next();
                          }}
                        >
                          <RightArrow />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </>
        </div>

        {/* workflow end */}

        {/* Displays the form (inputs) below the workflow screen to 
      capture the action outcome / make changes to action */}
        {data &&
        data.data &&
        data.data.doc &&
        data.data.doc.workflow.status === "ON_HOLD" ? (
          <>
            {workFlowAccess && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "50px",
                }}
              >
                {/* Picture Section */}
                <div>
                  <img src={Hold} alt="" />
                </div>
                {/* Content Section */}
                <div>
                  <LabelLarge>
                    <span
                      style={{
                        color: "#333860",
                        textAlign: "center",
                        paddingLeft: "55px",
                      }}
                    >
                      Consumer Actions is
                    </span>{" "}
                    <span style={{ color: "#516BEB", paddingLeft: "" }}>
                      Paused
                    </span>
                  </LabelLarge>
                  <div style={{ padding: "15px 0px" }}>
                    <div style={{ display: "flex" }}>
                      <ParagraphMedium style={{ color: "#ADADAD" }}>
                        Pause Till Date:
                      </ParagraphMedium>
                      <ParagraphMedium
                        style={{ paddingLeft: "15px", color: "#333860" }}
                      >
                        {/* 27-02-2022 */}
                        {holdDateFormate}
                      </ParagraphMedium>
                    </div>
                    <div
                      style={{
                        display: "flex",

                        paddingTop: "10px",
                      }}
                    >
                      <ParagraphMedium style={{ color: "#ADADAD" }}>
                        Reason:
                      </ParagraphMedium>
                      <ParagraphMedium
                        style={{ paddingLeft: "50px", color: "#333860" }}
                      >
                        {filteringValue &&
                          filteringValue[0] &&
                          filteringValue[0].label}
                        {/* {HoldDatas?.content?.hold_reason} */}
                      </ParagraphMedium>
                    </div>{" "}
                    <div
                      style={{
                        display: "flex",

                        paddingTop: "10px",
                      }}
                    >
                      <ParagraphMedium style={{ color: "#ADADAD" }}>
                        Remarks:
                      </ParagraphMedium>
                      <ParagraphMedium
                        style={{
                          paddingLeft: "44px",
                          color: "#333860",
                          width: "400px",
                        }}
                      >
                        {HoldDatas?.comment}
                      </ParagraphMedium>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <div
                      style={{ width: "150px" }}
                      title={
                        resumePermission
                          ? ""
                          : "Resume access unavailable, contact admin"
                      }
                    >
                      <TextButton
                        size={SIZE.compact}
                        kind={KIND.secondary}
                        style={{ width: "100%" }}
                        type="button"
                        startEnhancer={() => <ReleaseOnHold size="18px" />}
                        onClick={() => dispatch(releaseActionModal(true))}
                        disabled={!resumePermission}
                      >
                        Release Hold
                      </TextButton>
                    </div>
                    <ParagraphMedium style={{ paddingLeft: "10px" }}>
                      to Continue take Action
                    </ParagraphMedium>
                  </div>
                </div>
              </div>
            )}

            <div></div>
          </>
        ) : (
          <>
            <div className="cp-action-switch-container-review ">
              <WorkflowAction
                customerActions={all}
                actioWatch={actioWatch}
                watch={watch}
                control={control}
                errors={errors}
                intl={intl}
                viewOnly={viewOnly}
                dispatch={dispatch}
                quillToolbar={quillToolbar}
                callBackActin={callBackActin}
                hideWorkflowArea={hideWorkflowArea}
                setValue={setValue}
                data={data && data.data && data.data.doc ? data.data.doc : {}}
                callOutcome={callOutcome}
                setCallOutcome={setCallOutcome}
                setError={setError}
                clearErrors={clearErrors}
                setCallRefDetails={setCallRefDetails}
                installments={installments}
                setInstallments={setInstallments}
                disabled={disabled}
                setDisable={setDisable}
                setIsCompute={setIsCompute}
                isCompute={isCompute}
                isCancel={isCancel}
                setIsCancel={setIsCancel}
                settingData={settingData}
                fieldVisitStatus={fieldVisitStatus}
                setFieldVisit={setFieldVisit}
                outCome={outCome}
                setCome={setCome}
                actionFrom={""}
              />
            </div>
            <div className="cp-action-switch-footer">
              {
                (type = "follow_up_call" ? (
                  <>
                    {type === "email" || type === "letter" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <StatefulPopover
                          content={({ close }) => (
                            <div className="cp_email_attachement">
                              {/* [{filename:"From Desktop",}] */}
                              <ParagraphMedium
                                $style={{}}
                                onClick={() => {
                                  open();
                                  close();
                                }}
                              >
                                From Desktop
                              </ParagraphMedium>
                              <ParagraphMedium
                                onClick={() => {
                                  dispatch(customerDriveOpen(true));
                                  close();
                                }}
                              >
                                From Consumer Drive
                              </ParagraphMedium>
                            </div>
                          )}
                          returnFocus
                          autoFocus
                        >
                          <Button
                            type="button"
                            kind={KIND.secondary}
                            size={SIZE.mini}
                            overrides={{
                              BaseButton: {
                                style: ({ $theme }) => ({
                                  border: "1px solid #CDCED9",
                                  borderRadius: "5px",
                                }),
                              },
                            }}
                          >
                            <LabelSmall>Add Attachments</LabelSmall>
                          </Button>
                        </StatefulPopover>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div style={{ textAlign: "start", flex: "1" }}>
                            <LabelMedium $style={{ color: "#FD372A" }}>
                              {!isCancel &&
                              inProgress?.type === "PROMISE_TO_PAY" ? (
                                "The existing Promise to Pay will be Cancelled, By clicking on Save"
                              ) : !isCancel &&
                                inProgress?.type === "PAYMENT_PLAN" ? (
                                "The existing payment plan will be Cancelled, By clicking on Save"
                              ) : (
                                <></>
                              )}
                            </LabelMedium>
                          </div>
                          <RBACWrapper
                            role={CUSTOMER_WF_SKIP}
                            type={"PERMISSION"}
                          >
                            <div className="cp-action-btn">
                              <TextButton
                                type="button"
                                size={SIZE.compact}
                                fullWidth
                                kind={KIND.secondary}
                                disabled={scanFinalStatus}
                                startEnhancer={() => <Next size="18px" />}
                                onClick={() => dispatch(skipActionModal(true))}
                              >
                                Skip Action
                              </TextButton>
                            </div>
                          </RBACWrapper>

                          <div className="cp-action-btn">
                            <TextButton
                              type="button"
                              size={SIZE.compact}
                              fullWidth
                              disabled={isSubmitting || scanFinalStatus}
                              onClick={async () => {
                                const result = await trigger();
                                if (result) {
                                  const values = getValues();
                                  onSubmit(values);
                                }
                              }}
                            >
                              Send
                            </TextButton>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div style={{ textAlign: "start", flex: "1" }}>
                            <LabelMedium $style={{ color: "#FD372A" }}>
                              {!isCancel &&
                              inProgress?.type === "PROMISE_TO_PAY" ? (
                                "The existing Promise to Pay will be Cancelled, By clicking on Save"
                              ) : !isCancel &&
                                inProgress?.type === "PAYMENT_PLAN" ? (
                                "The existing payment plan will be Cancelled, By clicking on Save"
                              ) : (
                                <></>
                              )}
                            </LabelMedium>
                          </div>
                          <RBACWrapper
                            role={CUSTOMER_WF_SKIP}
                            type={"PERMISSION"}
                          >
                            <div className="cp-action-btn">
                              <TextButton
                                type="button"
                                size={SIZE.compact}
                                fullWidth
                                kind={KIND.secondary}
                                startEnhancer={() => <Next size="18px" />}
                                onClick={() => dispatch(skipActionModal(true))}
                              >
                                Skip Action
                              </TextButton>
                            </div>
                          </RBACWrapper>
                          <div className="cp-action-btn">
                            <TextButton
                              size={SIZE.compact}
                              fullWidth
                              disabled={
                                type === "sms"
                                  ? isSubmitting || smsGateWayDatas === false
                                  : (type === CALL &&
                                      callOutcome === "PAYMENT_PLAN") ||
                                    (type === "FIELD_VISIT" &&
                                      outCome === "PAYMENT_PLAN")
                                  ? isCompute
                                  : (type === CALL && !callOutcome) ||
                                    (type === "FIELD_VISIT" && !outCome)
                                  ? true
                                  : false
                                // promiseAmountChangingValue[0]?.value >
                                //   total_outstanding_invoice_amount?.value ||
                                // !callOutcome ||
                                // disabled
                              }
                            >
                              Save
                            </TextButton>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="cp-action-btn">
                      <TextButton
                        type="submit"
                        size={SIZE.mini}
                        fullWidth
                        disabled={isSubmitting}
                      >
                        Send
                      </TextButton>
                    </div>
                  </>
                ))
              }
            </div>
          </>
        )}
      </form>
      {/* <TemplateAttachment
        isTemplateModalOpen={isTemplateModalOpen}
        close={Modalclose}
      /> */}
      <SystemAttachment
        isModalOpen={isModalOpen}
        addAction={addCOEmailAttachment}
        removeAction={addCOEmailAttachment}
        close={Modalclose}
      />
    </>
  );
};

export default Actions;
