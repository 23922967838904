import React, { useContext, useState } from "react";
import { Icon } from "../../components_v2";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { ParagraphLarge } from "baseui/typography";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useDispatch } from "react-redux";

const ClientGroupAAR = ({
  clientGroupWithDetails,
  pageIndex,
  isState,
  indx,
  ...e
}) => {
  const [isDropDown, setIsDropDown] = useState(false);

  let dispatch = useDispatch();

  function collapse() {
    setIsDropDown(!isDropDown);
  }

  const { setFilterType, appAccessRest, clientGrp } = useContext(MaxyfiContext);

  let groupIdValue = Object.keys(clientGrp);

  return (
    <>
      <div className="search-client-list">
        {e?.label && (
          <>
            <CheckBoxBaseweb
              checked={groupIdValue.includes(e?.id)}
              onChange={() => {
                if (
                  clientGroupWithDetails &&
                  clientGroupWithDetails.length >= 1
                ) {
                  setFilterType({
                    master_filter_type: "BUSINESS_UNIT",
                    master_filter_value: e.clnts.map((se) => se.id),
                    master_client_group_value: e.id,
                  });

                  // if (
                  //   !appAccessRest?.master_client_group_value?.includes(e?.id)
                  // ) {
                  //   clientGroupWithDetails.splice(indx, 1);
                  //   clientGroupWithDetails.unshift({
                  //     ...e,
                  //   });
                  // }

                  // if (
                  //   appAccessRest?.master_client_group_value?.includes(e?.id)
                  // ) {
                  //   clientGroupWithDetails.splice(indx, 1);
                  //   clientGroupWithDetails.push({
                  //     ...e,
                  //   });
                  // }

                  if (pageIndex && !isState) {
                    dispatch(pageIndex(0));
                  } else if (pageIndex && isState) {
                    pageIndex(0);
                  }
                }
              }}
            ></CheckBoxBaseweb>
          </>
        )}
        {isDropDown ? (
          <Icon icon="chevron_down" isPressable onClick={() => collapse()} />
        ) : (
          <Icon icon="chevron_right" isPressable onClick={() => collapse()} />
        )}

        <ParagraphLarge
          title={e?.label}
          key={e?.id}
          style={{
            fontWeight: appAccessRest?.master_filter_value.includes(e?.id)
              ? "700"
              : "",
          }}
        >
          {e?.label}
        </ParagraphLarge>
      </div>
      {isDropDown && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "25px",
          }}
        >
          {e.clnts.map((se) => {
            return (
              <div className="search-client-list">
                {se?.label && (
                  <>
                    <CheckBoxBaseweb
                      checked={clientGrp?.[e.id]?.includes(se?.id)}
                      disabled={true}
                    ></CheckBoxBaseweb>
                  </>
                )}
                <ParagraphLarge
                  title={se?.label}
                  key={se?.id}
                  style={{
                    fontWeight: appAccessRest?.master_filter_value.includes(
                      se?.id
                    )
                      ? "700"
                      : "",
                  }}
                >
                  {se?.label}
                </ParagraphLarge>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ClientGroupAAR;
