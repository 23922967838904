import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Typography } from "../../../../components_v2";
import moment from "moment";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import _ from "lodash";
import {
  GRID_STRUCTURE_LEGAL_STATUS,
  LEGAL_ACTION_INFO_,
} from "../../../../constants";
import { useQuery } from "react-query";
import { getGridStructure } from "../../../../services";
import columnMapper_V2 from "../../../../utils/mapper_v2";
import queryClient from "../../../../providers/queryClient";
const Header = ({ label = "" }) => {
  const intl = useIntl();
  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

const LegalStatus = (props) => {
  let ref = React.useRef();
  const { customerId } = useParams();
  const [width, setWidth] = useState(0);

  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );
  //grid Api
  const gridStructure = useQuery(
    [`${GRID_STRUCTURE_LEGAL_STATUS}`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "legal_status_table",
      });
    },
    {
      cacheTime: 86400000,
    }
  );
  const legalStatuslist = useMemo(() => {
    const legalStatuslist = _.get(legalInfo, "data.doc.sta", []);

    return legalStatuslist;
  }, [legalInfo?.data]);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  const [expanded, setExpanded] = React.useState({});
  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);

  const data = useMemo(() => {
    const data = legalStatuslist;
    return data;
  }, [legalStatuslist.length]);

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flex: "1 0 0px",
        overflow: "hidden",
        flexDirection: "column",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="co_altc_payment-table_wrapper">
        <div className="co_altc_payment_table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* Table Body */}
        <div className="co-table_body">
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row) => (
            <div key={row.id} className="co-table_body-group">
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                  className="co-table_body-cell"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
            </div>
          ))}
          {/* TABLE ROW */}
        </div>
      </div>
    </div>
  );
};
export default LegalStatus;
