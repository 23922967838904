import { StatefulTooltip } from "baseui/tooltip";
import { Paragraph2, ParagraphSmall } from "baseui/typography";
import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

export const CustomerCate = ({ type, alignment }) => {
  switch (type) {
    case "DEFAULTED_PAYER":
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: `${alignment}`,
            gap: "5px",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <div
            style={{
              textAlign: `${alignment}`,
              color: "#333860",
              //   background: "#FFEBEA",
              //   padding: "5px",
              //   borderRadius: "5px",
            }}
          >
            Defaulter
          </div>
        </div>
      );

    case "DELINQUENT_PAYER":
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: `${alignment}`,

            gap: "5px",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <div
            style={{
              textAlign: `${alignment}`,
              color: "#333860",

              //   color: "#FD372A",
              //   background: "#FFEBEA",
              //   padding: "5px",
              //   borderRadius: "5px",
            }}
          >
            Delinquent
          </div>
        </div>
      );

    case "LATE_PAYER":
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: `${alignment}`,

            gap: "5px",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FBBD04",
            }}
          ></div>
          <div
            style={{
              textAlign: `${alignment}`,
              //   color: "#FD372A",
              color: "#333860",
              //   background: "#FFEBEA",
              //   padding: "5px",
              //   borderRadius: "5px",
            }}
          >
            Late
          </div>
        </div>
      );

    case "EARLY_PAYER":
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: `${alignment}`,

            gap: "5px",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#4CAF50",
            }}
          ></div>
          <div
            style={{
              textAlign: `${alignment}`,
              color: "#333860",
            }}
          >
            Early
          </div>
        </div>
      );

    case "ON_TIME_PAYER":
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: `${alignment}`,

            gap: "5px",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#4CAF50",
            }}
          ></div>
          <div
            style={{
              textAlign: `${alignment}`,
              color: "#333860",
            }}
          >
            Timely
          </div>
        </div>
      );

    default:
      return null;
  }
};

const CustomerCategorization = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  let maxyfiContextValue = useContext(MaxyfiContext);

  const { referenceData } = maxyfiContextValue;
  //
  return <CustomerCate type={value} alignment={alignment} />;
};

export default CustomerCategorization;
