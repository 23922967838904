import React, { useContext } from "react";
import { Select, SIZE, StyledValueContainer } from "baseui/select";
import { Tag, VARIANT } from "baseui/tag";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

const SimpleText = (props) => {
  return (
    <StyledValueContainer>
      <>
        {props.data &&
        props.data[0] &&
        props.data[0].props &&
        props.data[0].props.$isFocused ? (
          <>
            {props.data &&
              props.data.map((e) => (
                <Tag
                  variant={VARIANT.solid}
                  onActionClick={e.props.removeValue}
                >
                  {e.props.value.label}
                </Tag>
              ))}
          </>
        ) : (
          <>
            {props.data ? (
              <Tag
                variant={VARIANT.solid}
                onActionClick={props.data[0].props.removeValue}
              >
                {props.data[0].props.value.label}
              </Tag>
            ) : null}
            {props.data && props.data.length > 1 ? (
              <Tag
                variant={VARIANT.solid}
                closeable={false}
                // onClick={(e) => {
                //   e.preventDefault();
                //   props.showAllValues(true);
                // }}
              >{`${props.data.length - 1} + `}</Tag>
            ) : null}
          </>
        )}
      </>
    </StyledValueContainer>
  );
};

const MultiSelectFilter = (props) => {
  const {
    column: { filterValue, setFilter, rdName },
  } = props;
  let maxyfiContextValue = useContext(MaxyfiContext);

  const { referenceData } = maxyfiContextValue;
  const options = referenceData[rdName];

  const [selectedValues, setSelectedValue] = React.useState([]);
  const [isAllValueOpen, showAllValues] = React.useState(false);
  const [isFocused, setFocus] = React.useState(false);

  return (
    <>
      <Select
        overrides={{
          Root: {
            style: ({ $isFocused }) => ({
              borderRadius: "3px",
              height: "32px",
              backgroundColor: $isFocused ? "#516BEB10" : "#F5F6FA",
            }),
          },
          ControlContainer: {
            style: ({ $isFocused }) => ({
              padding: "0px",
              borderTopRightRadius: "3px",
              borderTopLeftRadius: "3px",
              borderBottomLeftRadius: "3px",
              borderBottomRightRadius: "3px",
              height: "32px",
              backgroundColor: $isFocused ? "#516BEB10" : "#F5F6FA",
              borderColor: "#CDCED9",
              borderWidth: "0.5px",
              borderStyle: "solid",
            }),
          },
          Input: {
            style: () => ({
              padding: "0px",
              height: "32px",
            }),
          },
          IconsContainer: {
            style: () => ({
              paddingRight: "2px",
            }),
          },
          Placeholder: {
            style: () => ({
              paddingTop: "6px",
              width: "100%",
            }),
          },
          ValueContainer: (props) => {
            let children = [
              <SimpleText
                data={props.children[0]}
                showAllValues={showAllValues}
                isAllValueOpen={isAllValueOpen}
              />,
              [...props.children.slice(1, props.children.length)],
            ];
            return children;
          },
        }}
        // onBlur={() => {
        //   if (isFocused) {
        //     showAllValues(false);
        //     setFocus(false);
        //   }
        // }}
        // onFocus={() => {
        //   setFocus(true);
        // }}
        size={SIZE.compact}
        options={options}
        onChange={({ value }) => {
          let labels = value.map((e) => e.id);
          //
          setFilter(labels);
          setSelectedValue(value);
        }}
        value={selectedValues}
        multi
      />
    </>
  );
};

export default MultiSelectFilter;
