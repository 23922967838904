import React, { useEffect, useState } from "react";
import NotesComp from "./NotesComp";
import Loader from "../../components/Loader";
import StickyNotes from "./StickyNotes";
import _ from "lodash";

const NotesContainer = (props) => {
  const { customer = {}, setDrawer = () => {} } = props;
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (_.get(customer, "data.stkynt", []).length > 0) {
      setIsSticky(true);
    }
  }, [customer.isLoading, customer.isFetching]);

  if (customer.isLoading) {
    return <Loader />;
  }

  if (isSticky) {
    return (
      <StickyNotes
        customer={customer}
        isSticky={isSticky}
        setIsSticky={setIsSticky}
      />
    );
  }

  if (!isSticky) {
    return (
      <NotesComp
        setDrawer={setDrawer}
        customer={customer}
        isSticky={isSticky}
        setIsSticky={setIsSticky}
      />
    );
  }
};

export default NotesContainer;
