import React, { useEffect, useContext, useState } from "react";

import { Button } from "baseui/button";
import { HeadingXLarge } from "baseui/typography";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, SIZE, ROLE } from "baseui/modal";
import { TextButton, KIND } from "../../../components/TextButton";
import { TextBox } from "../../../components/TextBox";
import { SelectBox } from "../../../components/SelectBox";
import { TextArea } from "../../../components/TextArea";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import moment from "moment";
import { useMutation } from "react-query";
import {
  createPaymentDetailsCustomerOverview,
  updatePaymentDetailsCustomerOverview,
  deletePaymentDetailsCustomerOverview,
} from "../../../services/customerOverview";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import getSelectValues from "../../../utils/getSelectValues";
import { useDispatch, useSelector } from "react-redux";
import {
  addPaymentModal,
  closePaymentModal,
} from "../../../redux/customerOverview/payment/action";
import { EDIT, VIEW } from "../../../constants";
import setSelectValues from "../../../utils/setSelectValues";
import { closeContactModal } from "../../../redux/customerOverview/action";
import { IconButton } from "../../../components/IconButton";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

const AddPayment = (props) => {
  const { id } = useParams();
  const intl = useIntl();
  const isModalOpen = useSelector((s) => s.customerPaymentReducer.isOpen);
  const defaultPaymentForm = useSelector(
    (s) => s.customerPaymentReducer.defaultPaymentData
  );
  const actionType = useSelector((e) => e.customerPaymentReducer.actionType);
  const dispatch = useDispatch();
  let { currentOrganization } = useContext(MaxyfiContext);

  const [tableModal, setTableModal] = useState(false);
  const [second, setSecond] = useState(false);
  // const [isOpen, setIsOpen] = React.useState(false);
  // function close() {
  //   setIsOpen(false);
  // }

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: defaultPaymentForm,
  });

  const isDisabled = actionType === VIEW ? true : false;

  useEffect(() => {
    if (actionType === EDIT || actionType === VIEW) {
      if (defaultPaymentForm && defaultPaymentForm.date) {
        setValue("date", new Date(defaultPaymentForm?.date));
      }
      setValue("amount.value", defaultPaymentForm?.amount?.value);
      setValue("method", setSelectValues(defaultPaymentForm?.method));
      setValue("refrence", defaultPaymentForm?.refrence);
      setValue("status", setSelectValues(defaultPaymentForm?.status));
      if (defaultPaymentForm?.expected_date) {
        setValue("expected_date", defaultPaymentForm?.expected_date);
      }
      setValue("created_by", defaultPaymentForm?.created_by);
      setValue("comment", defaultPaymentForm?.comment);
    }
  }, [defaultPaymentForm]);

  const backClick = () => {
    if (defaultPaymentForm.date) {
      setValue("date", new Date(defaultPaymentForm.date));
    }
    setValue("amount.value", defaultPaymentForm.amount.value);
    setValue("method", setSelectValues(defaultPaymentForm.method));
    setValue("refrence", defaultPaymentForm.refrence);
    setValue("status", setSelectValues(defaultPaymentForm.status));
    if (defaultPaymentForm.expected_date) {
      setValue("expected_date", defaultPaymentForm.expected_date);
    }
    setValue("created_by", defaultPaymentForm.created_by);
    setValue("comment", defaultPaymentForm.comment);
  };

  const updatePaymentData = useMutation(
    (paymentData) =>
      updatePaymentDetailsCustomerOverview({
        ...paymentData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        reset();
        dispatch(closePaymentModal());
      },
    }
  );

  const createPayment = useMutation(
    (paymentData) =>
      createPaymentDetailsCustomerOverview({
        ...paymentData,
        organization: currentOrganization,
      }),
    {
      onError: (error) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        // error to backend
      },
      onSuccess: (data) => {
        reset();
        setSecond(true);
      },
    }
  );

  const deletePaymentData = useMutation(
    (paymentData) =>
      deletePaymentDetailsCustomerOverview({
        ...paymentData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {},
    }
  );

  const onSubmit = async (data) => {
    let values = {
      ...data,
      date: moment(data.date).utc().valueOf(),
      expected_date: moment(data.expected_date).utc().valueOf(),
      status: getSelectValues(data.status),
      method: getSelectValues(data.method),
    };
    let contactId = defaultPaymentForm._id;

    if (actionType === EDIT) {
      await updatePaymentData.mutateAsync({
        ...values,
        data: values,
        customerId: id,
        contactId,
      });
    } else {
      await createPayment.mutateAsync({ ...values, customerId: id });
    }
    setTableModal(true);
  };
  // let contactId = defaultPaymentForm._id;
  return (
    <>
      <div style={{ display: "flex", width: "100%", gap: "5px" }}>
        {/* <TextButton
          kind={KIND.primary}
          onClick={() => dispatch(addPaymentModal())}
          fullWidth
        >
          Add Payment
        </TextButton> */}
      </div>
      {tableModal != true ? (
        <>
          <Modal
            onClose={() => {
              reset();
              dispatch(closePaymentModal());
            }}
            closeable
            isOpen={isModalOpen}
            animate
            autoFocus
            size={SIZE.auto}
            role={ROLE.dialog}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  width: "675px",
                  backgroundColor: "#fff",
                  border: "1px solid #CDCED9",
                  borderRadius: "5px",
                  textAlign: "center",
                  padding: "30px 40px",
                }}
              >
                <HeadingXLarge $style={{ color: "#516BEB" }}>
                  <FormattedMessage id="add_payments">
                    add_payments
                  </FormattedMessage>
                </HeadingXLarge>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Controller
                    name="amount.value"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          fullWidth
                          disabled={isDisabled}
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "payment_amount",
                          })}
                          placeholder={intl.formatMessage({
                            id: "payment_amount",
                          })}
                          value={field.value}
                        />
                      </>
                    )}
                  />
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <DateRangeSelect
                        size={SIZE.compact}
                        {...field}
                        disabled={isDisabled}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "expect_payment_status_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: " ",
                        })}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                        // selected={field.value}
                      />
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Controller
                    name="method"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        name={field.name}
                        disabled={isDisabled}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "payment_method",
                        })}
                        placeholder={intl.formatMessage({
                          id: "payment_method",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={[
                          { label: "email", id: "email" },
                          { label: "SMS", id: "SMS" },
                          { label: "LETTER", id: "LETTER" },
                        ]}
                      />
                    )}
                  />
                  <Controller
                    name="refrence"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <>
                        <TextBox
                          {...field}
                          // type="number"
                          fullWidth
                          disabled={isDisabled}
                          name={field.name}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "payment_reference",
                          })}
                          placeholder={intl.formatMessage({
                            id: "phone",
                          })}
                          value={field.value}
                        />
                      </>
                    )}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    marginTop: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <Controller
                    name="status"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        disabled={isDisabled}
                        name={field.name}
                        error={errors[field.name] && errors[field.name].message}
                        label={intl.formatMessage({
                          id: "payment_status",
                        })}
                        placeholder={intl.formatMessage({
                          id: "payment_status",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={[
                          { label: "email", id: "email" },
                          { label: "SMS", id: "SMS" },
                          { label: "LETTER", id: "LETTER" },
                        ]}
                      />
                    )}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      width: "590px",
                    }}
                  >
                    <Controller
                      name="expected_date"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <DateRangeSelect
                          size={SIZE.compact}
                          {...field}
                          name={field.name}
                          disabled={isDisabled}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "expect_payment_status_date",
                          })}
                          placeholder={intl.formatMessage({
                            id: " ",
                          })}
                          value={field.value}
                          onChange={(e) => field.onChange(e.date)}
                          // selected={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                ></div>
                <Controller
                  name="comment"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      multi
                      name={field.name}
                      disabled={isDisabled}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "user_notes",
                      })}
                      placeholder={intl.formatMessage({
                        id: "user_notes",
                      })}
                      value={field.value}
                    />
                  )}
                />
                <div
                  style={{
                    // marginTop: "20px",
                    paddingTop: "10px",
                    gap: "40px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "125px" }}>
                    <TextButton
                      type="button"
                      onClick={() => {
                        reset();
                        dispatch(closePaymentModal());
                      }}
                      fullWidth
                      kind={KIND.tertiary}
                      size={SIZE.compact}
                    >
                      CANCEL
                    </TextButton>
                  </div>
                  <div style={{ width: "125px" }}>
                    <TextButton
                      type="submit"
                      kind={KIND.primary}
                      size={SIZE.compact}
                      fullWidth
                    >
                      CAPTURE
                    </TextButton>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </>
      ) : (
        <>
          <Modal
            // onClose={close}
            isOpen={second}
          >
            <TextButton
              kind="tertiary"
              onClick={() => {
                setTableModal(false);
                setValue("amount.value", defaultPaymentForm.amount.value);
              }}
            >
              Back
            </TextButton>
            <TextButton
            // onClick={close}
            >
              Okay
            </TextButton>
          </Modal>
        </>
      )}
    </>
  );
};

export default AddPayment;
