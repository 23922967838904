import React from "react";

function InfoIconFilled({ color = "#FD372A", size = 22 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9996 15.354C11.1945 15.354 11.3578 15.2881 11.4893 15.1562C11.621 15.0245 11.6868 14.8613 11.6868 14.6665V10.7707C11.6868 10.5759 11.6209 10.4126 11.4891 10.2807C11.3572 10.149 11.1939 10.0832 10.9991 10.0832C10.8042 10.0832 10.6409 10.149 10.5094 10.2807C10.3777 10.4126 10.3118 10.5759 10.3118 10.7707V14.6665C10.3118 14.8613 10.3778 15.0245 10.5096 15.1562C10.6415 15.2881 10.8048 15.354 10.9996 15.354ZM10.9993 8.51429C11.2091 8.51429 11.385 8.44333 11.5269 8.3014C11.6688 8.15947 11.7398 7.98362 11.7398 7.77386C11.7398 7.56409 11.6688 7.38825 11.5269 7.24632C11.385 7.10454 11.2091 7.03365 10.9993 7.03365C10.7896 7.03365 10.6137 7.10454 10.4718 7.24632C10.3299 7.38825 10.2589 7.56409 10.2589 7.77386C10.2589 7.98362 10.3299 8.15947 10.4718 8.3014C10.6137 8.44333 10.7896 8.51429 10.9993 8.51429ZM11.001 19.7082C9.79645 19.7082 8.66429 19.4796 7.60447 19.0225C6.54465 18.5654 5.62279 17.945 4.83889 17.1614C4.05499 16.3778 3.43433 15.4564 2.97691 14.397C2.51965 13.3376 2.29102 12.2058 2.29102 11.0014C2.29102 9.79694 2.51957 8.66478 2.97668 7.60496C3.43379 6.54514 4.05415 5.62328 4.83774 4.83938C5.62134 4.05548 6.54282 3.43482 7.60218 2.9774C8.66154 2.52014 9.7934 2.2915 10.9977 2.2915C12.2022 2.2915 13.3344 2.52006 14.3942 2.97717C15.454 3.43428 16.3759 4.05464 17.1598 4.83823C17.9437 5.62183 18.5644 6.54331 19.0218 7.60267C19.479 8.66203 19.7077 9.79389 19.7077 10.9982C19.7077 12.2027 19.4791 13.3349 19.022 14.3947C18.5649 15.4545 17.9445 16.3764 17.161 17.1603C16.3774 17.9442 15.4559 18.5649 14.3965 19.0223C13.3372 19.4795 12.2053 19.7082 11.001 19.7082Z"
        fill={color}
      />
    </svg>
  );
}

export default InfoIconFilled;
