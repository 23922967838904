import { LabelXSmall } from "baseui/typography";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RightArrowAd from "../../assets/img/svg/RightArrowAd";
import { getSettingData } from "../../redux/setting/actions";
import { useLocation } from "react-router-dom";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";

const BookDemo = () => {
  const bookDemo = process.env.REACT_APP_BOOK_A_DEMO;
  const navigator = useNavigate();
  const dispatch = useDispatch();

  let { pathname } = useLocation();

  const { space } = useContext(MaxyfiContext);

  return (
    <div className="start__pack">
      <>
        {pathname === "/pricing" && space.app_message.centermessage === true ? (
          <></>
        ) : space.app_message.centermessage === true ? (
          <div
            className="layout__start__wraper"
            onClick={() => {
              dispatch(getSettingData("freemium"));
              navigator("/settings");
              //   onClick={() => window.open(DEMOLINK)}
            }}
          >
            {/* <LabelXSmall
              $style={{
                color: "#516beb",
                paddingRight: "5px",
                borderRight: "0.5px solid #cdced9",
              }}
            >
              To Use Premium Features and Enhanced Limits
            </LabelXSmall> */}
            <div className="upgrade__btn">
              <LabelXSmall $style={{ color: "#516beb", paddingLeft: "5px" }}>
                {space.app_message.MessagetoDisp}
              </LabelXSmall>
              <RightArrowAd />
            </div>
          </div>
        ) : (
          <></>
        )}

        <a
          href={space.app_message.bookdemourl}
          target="_blank"
          className="book__demo__tag"
        >
          <LabelXSmall
            $style={{
              color: "#ffffff",
              padding: "5px",
              cursor: "pointer",
              letterSpacing: "5%",
            }}
          >
            BOOK A DEMO
          </LabelXSmall>
        </a>
      </>
    </div>
  );
};

export default BookDemo;
