export default function Invoice({ size = 22, color = "#F8F8FB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.751 7.99887H7.75103C7.33682 7.99887 7.00103 8.33465 7.00103 8.74886C7.00103 9.16307 7.33682 9.49886 7.75103 9.49886H13.751C14.1652 9.49886 14.501 9.16307 14.501 8.74886C14.501 8.33465 14.1652 7.99887 13.751 7.99887Z"
        fill={color}
      />
      <path
        d="M7.75103 10.9999H12.251C12.6652 10.9999 13.001 11.3357 13.001 11.7499C13.001 12.1641 12.6652 12.4999 12.251 12.4999H7.75103C7.33682 12.4999 7.00103 12.1641 7.00103 11.7499C7.00103 11.3357 7.33682 10.9999 7.75103 10.9999Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7499 2H7.74996C6.75578 2.00119 5.80265 2.39666 5.09965 3.09965C4.39666 3.80265 4.00119 4.75577 4 5.74996V19.2498C4.00011 19.3862 4.03741 19.52 4.10791 19.6368C4.17841 19.7536 4.27942 19.849 4.40007 19.9126C4.52073 19.9762 4.65646 20.0058 4.79265 19.998C4.92884 19.9903 5.06034 19.9455 5.17299 19.8686L6.75247 18.7893L8.33195 19.8686C8.45675 19.954 8.60446 19.9997 8.7557 19.9997C8.90694 19.9997 9.05466 19.954 9.17945 19.8686L10.7544 18.7893L12.3294 19.8686C12.4543 19.9542 12.6021 20 12.7535 20C12.9049 20 13.0528 19.9542 13.1777 19.8686L14.7526 18.7901L16.3276 19.8678C16.4402 19.9445 16.5716 19.9891 16.7076 19.9968C16.8436 20.0045 16.9791 19.975 17.0996 19.9114C17.2201 19.8479 17.3211 19.7527 17.3916 19.6362C17.4621 19.5196 17.4995 19.386 17.4999 19.2498V5.74996C17.4987 4.75577 17.1032 3.80265 16.4002 3.09965C15.6972 2.39666 14.7441 2.00119 13.7499 2ZM15.9999 17.8248L15.1749 17.2608C15.0499 17.1751 14.9019 17.1291 14.7504 17.1291C14.5988 17.1291 14.4508 17.1751 14.3259 17.2608L12.7509 18.3401L11.1759 17.2608C11.0511 17.1752 10.9032 17.1294 10.7518 17.1294C10.6004 17.1294 10.4526 17.1752 10.3277 17.2608L8.7527 18.3401L7.17772 17.2608C7.053 17.1755 6.90544 17.1299 6.75435 17.1299C6.60326 17.1299 6.45569 17.1755 6.33098 17.2608L5.49998 17.8248V5.74996C5.49998 5.15323 5.73703 4.58094 6.15899 4.15899C6.58094 3.73703 7.15323 3.49998 7.74996 3.49998H13.7499C14.3466 3.49998 14.9189 3.73703 15.3409 4.15899C15.7628 4.58094 15.9999 5.15323 15.9999 5.74996V17.8248Z"
        fill={color}
      />
    </svg>
  );
}
