import React, { useState } from "react";
import { Select } from "baseui/select";
import { FormControl } from "baseui/form-control";
import { TimePicker } from "baseui/timepicker";

export const TimePickerAction = (props) => {
  const { error = "", children, value = null, label = "" } = props;

  const [isFocused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  return (
    <FormControl
      error={error}
      overrides={{
        Caption: {
          style: ({ $theme }) => ({
            marginTop: "0px",
            marginBottom: "0px",
            marginLeft: "12px",
            textAlign: "left",
          }),
        },
      }}
    >
      <div className="mx-input">
        <label
          htmlFor={props.name}
          className={`mx-input__label mx-input__label--select  ${
            isFocused ? "mx-input__label--active text-primary" : ""
          } mx-input__label--active ${error ? "text-error" : ""}`}
        >
          {label}
        </label>
        <TimePicker
          id={props.name}
          autoComplete="off"
          autoFill="off"
          overrides={{
            Select: {
              props: {
                id: props.name,
                overrides: {
                  Root: {
                    style: ({ $theme }) => ({
                      backgroundColor: $theme.colors.warning200,
                      borderRadius: "5px",
                    }),
                  },
                  ControlContainer: {
                    style: ({ $theme, $isFocused, $error }) => ({
                      backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                      backgroundColor: "#ffffff",

                      borderRadius: "5px",
                      borderWidth: "1px",
                      borderColor: $isFocused
                        ? $error
                          ? "#FD372A"
                          : "#516BEB"
                        : $error
                        ? "#FD372A"
                        : "#CDCED9",
                    }),
                  },
                  Placeholder: {
                    style: ({ $theme, $isFocused }) => ({
                      color: $isFocused ? "#cbd5e0" : "transparent",
                      transition: "color 0.4s ease",
                    }),
                  },
                },
              },
            },
          }}
          {...props}
          onFocus={() => {
            if (props.onFocus) {
              props.onFocus();
            }
            onFocus();
          }}
          onBlur={() => {
            if (props.onBlur) {
              props.onBlur();
            }
            onBlur();
          }}
        />
      </div>
    </FormControl>
  );
};

export const inputOverrides = {
  Root: {
    style: () => ({
      borderRadius: "5px",
      borderWidth: "1px",
      paddingTop: "1px",
      paddingBottom: "1px",
      paddingRight: "0px",
    }),
  },
};
