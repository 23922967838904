import { Checkbox } from "baseui/checkbox";

const CheckBoxBaseweb = ({ children, ref, ...rest }) => {
  return (
    <Checkbox
      inputRef={ref}
      overrides={{
        Checkmark: {
          style: ({ $theme, $checked }) => ({
            height: "18px",
            width: "18px",
            backgroundColor: $checked ? "#516BEB" : null,
            borderRadius: $theme.borders.radius200,
            borderBottomColor: $checked ? $theme.colors.primary : `#CDCED9`,
            borderTopColor: $checked ? $theme.colors.primary : `#CDCED9`,
            borderRightColor: $checked ? $theme.colors.primary : `#CDCED9`,
            borderLeftColor: $checked ? $theme.colors.primary : `#CDCED9`,
            borderBottomWidth: "0.5px",
            borderTopWidth: "0.5px",
            borderRightWidth: "0.5px",
            borderLeftWidth: "0.5px",
            borderStyle: "solid",
            backgroundImage: $checked
              ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
              : "none",
            backgroundSize: "auto",
          }),
        },
        Label: {
          style: ({ $theme }) => ({
            paddingLeft: "3px",
            justifyContent: "space-between",
            ...(rest.error ? { color: "#FD372A" } : {}),
          }),
        },
        // Root: {
        //   style: () => ({
        //     justifyContent: "space-between",
        //   }),
        // },
      }}
      {...rest}
    >
      {children}
    </Checkbox>
  );
};

export default CheckBoxBaseweb;
