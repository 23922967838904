import { HeadingXSmall, LabelLarge, ParagraphMedium } from "baseui/typography";
import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setGlobalFilter } from "../../redux/customerSummary/action";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { getOrganizationDashboard } from "../../services/organization";

const TopDebtors = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  let {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    referenceData,
  } = useContext(MaxyfiContext);

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `AGENT_DASHBOARD_${currentOrganization}_TOP_DEBTORS`,
      {
        filters: {
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "TOPDEBTOR",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 1800000,
    }
  );
  return (
    <div
      className="--modifier_table"
      style={{ boxShadow: " 0px 8px 24px 0px rgba(149, 157, 165, 0.20)" }}
    >
      <div className="db-action__table-head">
        <LabelLarge $style={{ marginLeft: "20px" }}>Top Debtors</LabelLarge>
      </div>

      <div style={{ height: "370px", overflow: "scroll" }}>
        {data &&
          data.data &&
          data.data.doc &&
          data.data.doc.top_debtors &&
          data.data.doc.top_debtors.map((e) => (
            <div
              className="db-action__table-body"
              style={{ padding: "10px", cursor: "pointer" }}
              onClick={() => {
                dispatch(
                  setGlobalFilter({
                    search_value: e.display_name,
                    search_type: "CUSTOMER_NAME",
                  })
                );
                navigate(`/customers/${e?.pgmc ? e?.pgmc : e?._id}`, {
                  replace: true,
                });
              }}
            >
              <div style={{ flex: 1 }}>
                <ParagraphMedium>{e.display_name}</ParagraphMedium>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ParagraphMedium>
                  {e.cus_max_delay ? e.cus_max_delay : ""}
                </ParagraphMedium>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div className="dashboard__currency__wraper">
                  <div className="dashboard__currency">
                    <HeadingXSmall $style={{ fontSize: "10px" }}>
                      {e.total_outstanding_invoice_amount
                        ? e.total_outstanding_invoice_amount.currency
                        : "USD"}
                    </HeadingXSmall>
                  </div>
                  <NumericFormat
                    displayType="text"
                    value={
                      e.total_outstanding_invoice_amount
                        ? e.total_outstanding_invoice_amount.value
                        : 0
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => (
                      <ParagraphMedium>{value}</ParagraphMedium>
                    )}
                  />
                  {/* <FormattedNumber
                        value={
                          e.total_outstanding_invoice_amount
                            ? Number(
                                e.total_outstanding_invoice_amount.value
                              ).toFixed(2)
                            : 0
                        }
                        style="currency"
                        currency={
                          e.total_outstanding_invoice_amount
                            ? e.total_outstanding_invoice_amount.currency
                            : "USD"
                        }
                      /> */}
                </div>
                <HeadingXSmall
                  $style={{
                    color: "#ADADAD",
                    lineHeight: "10px",
                    textAlign: "right",
                  }}
                >
                  {e.total_outstanding_invoice_count} Invoices
                </HeadingXSmall>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TopDebtors;
