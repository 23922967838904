export const PAYMENT_ADD_ADHOC_UPLOAD_DATA = "PAYMENT_ADD_ADHOC_UPLOAD_DATA";
export const PAYMENT_UPDATE_MAP_FIELDS = "PAYMENT_UPDATE_MAP_FIELDS";
export const PAYMENT_UPDATE_FORMAT_DATA = "PAYMENT_UPDATE_FORMAT_DATA";
export const PAYMENT_UPDATE_DEFAULT_DATA = "PAYMENT_UPDATE_DEFAULT_DATA";
export const PAYMENT_UPDATE_SYSTEM_FIELDS = "PAYMENT_UPDATE_SYSTEM_FIELDS";
export const PAYMENT_UPDATE_TEMPLATE_DATA_LIST =
  "PAYMENT_UPDATE_TEMPLATE_DATA_LIST";
export const PAYMENT_INITIALSTATE_STATE = "PAYMENT_INITIALSTATE_STATE";
export const PAYMENT_ADHOC_REFETCH = "PAYMENT_ADHOC_REFETCH";
export const PAYMENT_SET_AU_FILTER = "PAYMENT_SET_AU_FILTER";
export const PAYMENT_ADHOC_FILTER = "PAYMENT_ADHOC_FILTER";
export const PAYMENT_VALIDATION_ADHOC_FILTER =
  "PAYMENT_VALIDATION_ADHOC_FILTER";
export const PAYMENT_MAP_BASED_ON = "PAYMENT_MAP_BASED_ON";
export const PAYMENT_BUSINESS_UNIT_DATA = "PAYMENT_BUSINESS_UNIT_DATA";
export const PAYMENT_UPDATE_BASED_ON = "PAYMENT_UPDATE_BASED_ON";
export const DELETE_PAYMENT_FORMULA_FIELDS = "DELETE_PAYMENT_FORMULA_FIELDS";
export const UPDATE_PAYMENT_FORMULA_FIELDS = "UPDATE_PAYMENT_FORMULA_FIELDS";
export const PAYMENT_FORMULA_FIELDS = "PAYMENT_FORMULA_FIELDS";

export const setPaymentAdhocFilter = (data) => {
  return {
    type: PAYMENT_ADHOC_FILTER,
    payload: data,
  };
};

export const setPaymentAdhocUploadFilter = (data) => {
  return {
    type: PAYMENT_SET_AU_FILTER,
    payload: data,
  };
};

export const setPaymentInitialAdhocState = () => {
  return {
    type: PAYMENT_INITIALSTATE_STATE,
  };
};

export const setPaymentAdhocUploadData = (payload) => {
  return {
    type: PAYMENT_ADD_ADHOC_UPLOAD_DATA,
    payload,
  };
};

export const updatePaymentSystemField = ({ index, systemField, type }) => {
  return {
    type: PAYMENT_UPDATE_SYSTEM_FIELDS,
    payload: { index, systemField, type },
  };
};

export const updatePaymentFormatData = ({ index, formatData }) => {
  return {
    type: PAYMENT_UPDATE_FORMAT_DATA,
    payload: { index, formatData },
  };
};

export const updatePaymentDefaultData = ({ index, defaultData, referance }) => {
  return {
    type: PAYMENT_UPDATE_DEFAULT_DATA,
    payload: { index, defaultData, referance },
  };
};

export const updatePaymentTemplateDataList = (templateData) => {
  return {
    type: PAYMENT_UPDATE_TEMPLATE_DATA_LIST,
    payload: { ...templateData },
  };
};

export const adhocPaymentRefetchTable = () => {
  return {
    type: PAYMENT_ADHOC_REFETCH,
  };
};

export const paymentValidationFields = () => {
  return {
    type: PAYMENT_VALIDATION_ADHOC_FILTER,
  };
};

export const mapBasedOnPaymentFn = (mapData) => {
  return {
    type: PAYMENT_MAP_BASED_ON,
    payload: mapData,
  };
};

export const updateBasedOnPaymentFn = (mapData) => {
  return {
    type: PAYMENT_UPDATE_BASED_ON,
    payload: mapData,
  };
};

export const businessUnitPaymentData = ({
  rdBussinessUnit,
  paymentStatus,
  paymentMethod,
  orgDefaultData,
}) => {
  return {
    type: PAYMENT_BUSINESS_UNIT_DATA,
    payload: { rdBussinessUnit, paymentStatus, paymentMethod, orgDefaultData },
  };
};

export const formulaDispachFnPayment = (payload) => {
  return {
    type: PAYMENT_FORMULA_FIELDS,
    payload,
  };
};

export const deleteFormulaFieldPayment = (payload) => {
  return {
    type: DELETE_PAYMENT_FORMULA_FIELDS,
    payload,
  };
};

export const updateFormulaFieldPayment = (payload) => {
  return {
    type: UPDATE_PAYMENT_FORMULA_FIELDS,
    payload,
  };
};
