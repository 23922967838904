import React from "react";

export default function CustomerPortal({ size = 22, color = "#0FB158" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_505_204926)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.2918 16.1774C16.4703 16.4316 16.4081 16.7826 16.1538 16.9604C15.8996 17.1389 15.5486 17.0766 15.3708 16.8224L14.1566 15.0884C13.9781 14.8341 14.0403 14.4831 14.2946 14.3046C14.5488 14.1269 14.8998 14.1884 15.0776 14.4434L16.2918 16.1774Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.6014 12.9682C16.9172 13.1504 17.0957 13.5014 17.0559 13.8637C17.0162 14.2252 16.7664 14.5297 16.4192 14.6392L14.8689 15.1282L13.8797 16.4174C13.6584 16.7062 13.2864 16.8367 12.9332 16.7504C12.5792 16.6634 12.3099 16.3762 12.2469 16.0177L11.4984 11.7652C11.4362 11.4134 11.5839 11.0579 11.8757 10.8532C12.1682 10.6492 12.5529 10.6319 12.8619 10.8104L16.6014 12.9682Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.9375 5.0625V3.375C0.9375 2.02875 2.02875 0.9375 3.375 0.9375H14.625C15.2715 0.9375 15.8918 1.194 16.3485 1.6515C16.806 2.10825 17.0625 2.7285 17.0625 3.375V5.0625H0.9375ZM17.0625 6.1875V12.3705C17.0347 12.3525 17.007 12.3352 16.9777 12.3187L13.2375 10.161C12.6765 9.837 11.9782 9.86775 11.4472 10.239C10.9162 10.611 10.6485 11.2568 10.761 11.895L11.5095 16.1475C11.5725 16.5045 11.7472 16.8217 11.9977 17.0625H3.375C2.02875 17.0625 0.9375 15.9712 0.9375 14.625V6.1875H17.0625ZM3.9375 2.625C3.627 2.625 3.375 2.877 3.375 3.1875C3.375 3.498 3.627 3.75 3.9375 3.75C4.248 3.75 4.5 3.498 4.5 3.1875C4.5 2.877 4.248 2.625 3.9375 2.625ZM5.8125 2.625C5.502 2.625 5.25 2.877 5.25 3.1875C5.25 3.498 5.502 3.75 5.8125 3.75C6.123 3.75 6.375 3.498 6.375 3.1875C6.375 2.877 6.123 2.625 5.8125 2.625ZM7.6875 2.625C7.377 2.625 7.125 2.877 7.125 3.1875C7.125 3.498 7.377 3.75 7.6875 3.75C7.998 3.75 8.25 3.498 8.25 3.1875C8.25 2.877 7.998 2.625 7.6875 2.625Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_505_204926">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
