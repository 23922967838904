import React from 'react'
const RelationManagerSVG=({width='18',height='18'})=>{
    return(
        <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00384 7.44908C10.6063 7.44908 11.91 6.1454 11.91 4.5429C11.91 2.9404 10.6063 1.63672 9.00384 1.63672C7.40134 1.63672 6.09766 2.9404 6.09766 4.5429C6.09766 6.1454 7.40134 7.44908 9.00384 7.44908Z" fill="#333860"/>
<path d="M12.9019 8.85746C12.9006 8.85707 12.8982 8.85617 12.897 8.85588C12.5186 8.76345 11.3599 8.60135 11.3599 8.60135C11.2291 8.56108 11.0893 8.63036 11.0422 8.75943L9.29804 13.545C9.19743 13.8211 8.80698 13.8211 8.70637 13.545L6.96221 8.75943C6.92422 8.6552 6.82584 8.58984 6.72042 8.58984C6.69543 8.58984 5.50401 8.7569 5.10736 8.85553C4.0235 9.12503 3.33984 10.1909 3.33984 11.3016V15.2835C3.33984 15.8802 3.82361 16.364 4.42034 16.364H13.5831C14.1798 16.364 14.6636 15.8802 14.6636 15.2835V11.2884C14.6635 10.1824 13.9531 9.20095 12.9019 8.85746Z" fill="#333860"/>
<path d="M9.66103 8.51048C9.59141 8.43462 9.48965 8.39648 9.38671 8.39648H8.61886C8.51592 8.39648 8.41412 8.43467 8.34455 8.51048C8.23679 8.62791 8.22112 8.79754 8.29764 8.93009L8.70812 9.54888L8.51597 11.1699L8.89436 12.1765C8.93126 12.2777 9.07441 12.2777 9.11131 12.1765L9.4897 11.1699L9.29755 9.54888L9.70803 8.93009C9.78445 8.79754 9.76878 8.62791 9.66103 8.51048Z" fill="#333860"/>
</svg>

    )
}
export default RelationManagerSVG