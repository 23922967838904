import React from "react";

const TripleDot = ({ size = 18, color = "#ADADAD" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99995 14.6034C8.69684 14.6034 8.43862 14.4967 8.22528 14.2834C8.01194 14.07 7.90527 13.8118 7.90527 13.5087C7.90527 13.2056 8.01194 12.9473 8.22528 12.734C8.43862 12.5207 8.69684 12.414 8.99995 12.414C9.30307 12.414 9.56129 12.5207 9.77463 12.734C9.98797 12.9473 10.0946 13.2056 10.0946 13.5087C10.0946 13.8118 9.98797 14.07 9.77463 14.2834C9.56129 14.4967 9.30307 14.6034 8.99995 14.6034ZM8.99995 10.0947C8.69684 10.0947 8.43862 9.98807 8.22528 9.77473C8.01194 9.56139 7.90527 9.30317 7.90527 9.00005C7.90527 8.69694 8.01194 8.43872 8.22528 8.22538C8.43862 8.01204 8.69684 7.90537 8.99995 7.90537C9.30307 7.90537 9.56129 8.01204 9.77463 8.22538C9.98797 8.43872 10.0946 8.69694 10.0946 9.00005C10.0946 9.30317 9.98797 9.56139 9.77463 9.77473C9.56129 9.98807 9.30307 10.0947 8.99995 10.0947ZM8.99995 5.58609C8.69684 5.58609 8.43862 5.47943 8.22528 5.2661C8.01194 5.05277 7.90527 4.79453 7.90527 4.49141C7.90527 4.1883 8.01194 3.93007 8.22528 3.71674C8.43862 3.5034 8.69684 3.39673 8.99995 3.39673C9.30307 3.39673 9.56129 3.5034 9.77463 3.71674C9.98797 3.93007 10.0946 4.1883 10.0946 4.49141C10.0946 4.79453 9.98797 5.05277 9.77463 5.2661C9.56129 5.47943 9.30307 5.58609 8.99995 5.58609Z"
        fill={color}
      />
    </svg>
  );
};

export default TripleDot;
