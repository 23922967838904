import React from "react";
import CardLayout from "../Card";
import { StyledBody, StyledAction } from "baseui/card";
import SuccessArrow from "../../../assets/img/svg/SuccessArrow";
import SuccessSheild from "../../../assets/img/svg/SuccessSheild";
import FaildSheild from "../../../assets/img/svg/FaildSheild";
import { Paragraph2 } from "baseui/typography";

const LastPromise = () => {
  return (
    <div>
      <CardLayout>
        <StyledBody>
          <Paragraph2
            style={{
              fontSize: "10px",
              lineHeight: "12px",
              textAlign: "center",
              fontWeight: "700",
              marginTop: "45px",
              color: "#666666",
            }}
          >
            Last Promise status{" "}
          </Paragraph2>

          <div className="card-head">
            <SuccessSheild />
            <SuccessSheild />
            <FaildSheild />
          </div>
        </StyledBody>
      </CardLayout>
    </div>
  );
};

export default LastPromise;
