import React from "react";

const TermsIconSvg = ({ size = "15", color = "#787878" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75023 11.0625C7.90968 11.0625 8.04324 11.0086 8.15093 10.9007C8.25862 10.7929 8.31246 10.6594 8.31246 10.5V7.31246C8.31246 7.1531 8.25854 7.01951 8.15069 6.9117C8.04283 6.80389 7.90917 6.74998 7.74974 6.74998C7.59029 6.74998 7.45672 6.80389 7.34903 6.9117C7.24134 7.01951 7.1875 7.1531 7.1875 7.31246V10.5C7.1875 10.6594 7.24143 10.7929 7.34928 10.9007C7.45714 11.0086 7.59079 11.0625 7.75023 11.0625ZM7.74998 5.46634C7.92162 5.46634 8.06549 5.40829 8.18159 5.29219C8.29769 5.17609 8.35574 5.03222 8.35574 4.86058C8.35574 4.68896 8.29769 4.54509 8.18159 4.42898C8.06549 4.31288 7.92162 4.25483 7.74998 4.25483C7.57834 4.25483 7.43447 4.31288 7.31838 4.42898C7.20227 4.54509 7.14423 4.68896 7.14423 4.86058C7.14423 5.03222 7.20227 5.17609 7.31838 5.29219C7.43447 5.40829 7.57834 5.46634 7.74998 5.46634ZM7.75124 14.625C6.76579 14.625 5.83951 14.438 4.97241 14.064C4.1053 13.69 3.35104 13.1824 2.70963 12.5413C2.0682 11.9001 1.56041 11.1462 1.18624 10.2795C0.812081 9.41277 0.625 8.48669 0.625 7.50124C0.625 6.51579 0.812 5.58951 1.186 4.72241C1.56 3.8553 2.06756 3.10104 2.70869 2.45963C3.34983 1.8182 4.10376 1.31041 4.97048 0.936245C5.83719 0.562082 6.76328 0.375 7.74873 0.375C8.73418 0.375 9.66045 0.562001 10.5276 0.936001C11.3947 1.31 12.1489 1.81756 12.7903 2.45869C13.4318 3.09983 13.9396 3.85376 14.3137 4.72048C14.6879 5.58719 14.875 6.51328 14.875 7.49873C14.875 8.48418 14.688 9.41045 14.314 10.2775C13.94 11.1447 13.4324 11.8989 12.7913 12.5403C12.1501 13.1818 11.3962 13.6896 10.5295 14.0637C9.66277 14.4379 8.73669 14.625 7.75124 14.625ZM7.74998 13.5C9.42498 13.5 10.8437 12.9187 12.0062 11.7562C13.1687 10.5937 13.75 9.17498 13.75 7.49998C13.75 5.82498 13.1687 4.40623 12.0062 3.24373C10.8437 2.08123 9.42498 1.49998 7.74998 1.49998C6.07498 1.49998 4.65623 2.08123 3.49373 3.24373C2.33123 4.40623 1.74998 5.82498 1.74998 7.49998C1.74998 9.17498 2.33123 10.5937 3.49373 11.7562C4.65623 12.9187 6.07498 13.5 7.74998 13.5Z"
        fill={color}
      />
    </svg>
  );
};

export default TermsIconSvg;
