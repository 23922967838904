import { useMutation, useQueryClient } from "react-query";
import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";
import { BUSINESS_UNIT_MAINTENANCE } from "../constants";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const getRevenueBillingList = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/revenue-billing`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },

      // headers: {
      //   appCode: APP_CODE,
      // },
    }
  );
};

export const getRevenueBillingListDownload = async ({
  organization = null,
  values,
}) => {
  return await axiosWithAuth
    .get(`${HOST}organization/${organization}/revenue-billing/download`, {
      params: {
        ...values,
      },
      headers: {
        appCode: APP_CODE,
      },
    })
    .then((blob) => {
      download({ fileName: "Revenue List.csv", fileData: blob.data });
    });
};

export const getRevenueDashboardDownload = async ({
  organization = null,
  values,
}) => {
  return await axiosWithAuth
    .get(`${HOST}organization/${organization}/revenue-dashboard/download`, {
      params: {
        ...values,
      },
      headers: {
        appCode: APP_CODE,
      },
    })
    .then((blob) => {
      download({ fileName: "Revenue Dashboard.csv", fileData: blob.data });
    });
};

export const getKpiMaintenanceList = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/kpi-maintenance`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },

      // headers: {
      //   appCode: APP_CODE,
      // },
    }
  );
};

export const getReportDownloadList = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/generated-report`, {
    params: {
      page: pageIndex + 1,
      // sort: sortBy,
      page_size: pageSize,
      // ...filters,
    },
  });
};

export const getKpiMaintenanceAudit = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/kpi-maintenance/audits/${id}`,
    {
      // headers: {
      //   appCode: APP_CODE,
      // },
    }
  );
};

export const getPerformanceList = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/user-performance-dashboard/ALL`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: 0,

        ...filters,
      },

      // headers: {
      //   appCode: APP_CODE,
      // },
    }
  );
};

export const getPerformanceStats = async ({
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/user-performance-dashboard/STATS`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const getPerformancePosition = async ({
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/user-performance-dashboard/POSITION`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const revenueBillFn = async ({
  organization,
  businessUnitId,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/revenue-billing/${businessUnitId}/generate-billing-summary`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const UserevenueBill = (props) => {
  const client = useQueryClient();
  return useMutation(revenueBillFn, {
    onSuccess: (data) => {
      client.invalidateQueries(`${BUSINESS_UNIT_MAINTENANCE}`);
    },
    onError: (err) => {},
  });
};

export const revenueAndBillingFinelFn = async ({
  organization,
  businessUnitId,
  ...rest
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/revenue-billing/${businessUnitId}/generate-invoice`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const UseRevenueAndBillingFinelFn = (props) => {
  const client = useQueryClient();
  return useMutation(revenueAndBillingFinelFn, {
    onSuccess: (data) => {
      client.invalidateQueries(`${BUSINESS_UNIT_MAINTENANCE}`);
    },
    onError: (err) => {},
  });
};
