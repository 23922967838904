import React from "react";
import { ParagraphMedium } from "baseui/typography";
import _ from "lodash";

const BoolenType = ({ value, ...props }) => {
  return (
    <ParagraphMedium
      $style={{
        color: "#333860",
        marginLeft: "5px",
        lineHeight: "19px",
      }}
    >
      {_.get(props, "row.original.islstacrm") == true
        ? "Yes"
        : _.get(props, "row.original.islstacrm") == false
        ? "No"
        : "-"}
    </ParagraphMedium>
  );
};

export default BoolenType;
