import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from "baseui/modal";
import { KIND, TextButton } from "../../components/TextButton";
import * as ModalTextButton from "../../components/TextButton";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelLarge, ParagraphMedium } from "baseui/typography";

const CsvModal = ({ isModal, setIsModal }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const HELP_CENTER = process.env.REACT_APP_USER_MANUAL;
  return (
    <Modal
      onClose={() => setIsModal(false)}
      closeable
      isOpen={isModal}
      animate
      autoFocus
      size={SIZE.auto}
      role={ROLE.dialog}
    >
      <div style={{ width: "700px" }}>
        <ModalHeader>
          <LabelLarge>
            Start with Data Import and AR Automation Journey
          </LabelLarge>
        </ModalHeader>

        <ModalBody>
          <iframe
            height="315"
            src="https://www.youtube.com/embed/5O1eWmkJvaY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{ width: "100%" }}
          />
          <ParagraphMedium>
            Uploading your Invoicing data into Maxyfi is easy. Just follow the
            steps mentioned in the above video or follow the steps from the{" "}
            <span
              style={{
                color: "#516ebe",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                let destination = location.pathname.split("/");
                let checkingDestination =
                  destination[1] === "" ? "dashboard" : destination[1];
                openInNewTab(`${HELP_CENTER}${checkingDestination}`);
              }}
            >
              User manual.
            </span>
          </ParagraphMedium>
        </ModalBody>
        <div
          style={{
            display: "flex",
            gap: "20x",
            justifyContent: "center",
            paddingBottom: "15px",
          }}
        >
          <TextButton
            kind={KIND.tertiary}
            size={ModalTextButton.SIZE.compact}
            onClick={() => {
              setIsModal(false);
            }}
          >
            Close
          </TextButton>
          <TextButton
            kind={KIND.primary}
            size={ModalTextButton.SIZE.compact}
            onClick={() => {
              navigate("/uploads");
            }}
          >
            Take me to Adhoc Upload
          </TextButton>
        </div>
      </div>
    </Modal>
  );
};

export default CsvModal;
