import React from "react";

const CreditReport = ({ size = 22, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.12257 6.91427L7.59235 10.3758C8.37995 9.74721 9.33652 9.33871 10.3901 9.22045V4.32143C7.99073 4.46168 5.8122 5.41482 4.12257 6.91427ZM3.25999 7.7748C1.62978 9.60295 0.589355 12.0022 0.589355 14.6358C0.589355 14.9722 0.86219 15.2444 1.19936 15.2444H4.90005C5.23722 15.2444 5.51005 14.9722 5.51005 14.6358C5.51005 13.3452 5.97842 12.1733 6.72978 11.2363L3.25999 7.7748ZM14.983 9.82222C15.2099 9.67544 15.5078 9.69861 15.7133 9.87748C15.917 10.0557 15.9784 10.347 15.864 10.5918L13.2125 16.2707C12.8146 17.1259 11.9461 17.6786 11.0001 17.6786C9.65438 17.6786 8.56007 16.5869 8.56007 15.2444C8.56007 14.4088 8.98183 13.6416 9.68895 13.1912L14.983 9.82222ZM18.7401 7.7748L16.9472 9.56343C17.173 10.0442 17.2027 10.607 16.9696 11.1058L16.1675 12.8237C16.374 13.3896 16.4901 13.9979 16.4901 14.6358C16.4901 14.9722 16.7629 15.2444 17.1001 15.2444H20.8008C21.138 15.2444 21.4108 14.9722 21.4108 14.6358C21.4108 12.0022 20.3704 9.60295 18.7401 7.7748ZM11.6101 9.22045V4.32143C14.0094 4.46168 16.188 5.41482 17.8776 6.91427L16.0841 8.70343C15.4443 8.40103 14.8299 8.47077 14.3271 8.79581L13.0722 9.59447C12.613 9.40707 12.1237 9.2781 11.6101 9.22045Z"
        fill={color}
      />
    </svg>
  );
};

export default CreditReport;
