import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useDispatch, useSelector } from "react-redux";
import ActionHeader from "./ActionHeader";
import { Icon, Typography } from "../../../components_v2";
import { KIND, TextButton } from "../../../components/TextButton";
import * as TextBoxSize from "../../../components/TextBox";
import { useMutation, useQuery } from "react-query";
import {
  getGroupingList,
  groupSuggestion,
  removeSugesstGroup,
} from "../../../services/grouping";
import _ from "lodash";
import { ReviewGroupTime } from "./LocalTimeZone";
import { toast } from "react-toastify";
import queryClient from "../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../../constants";
import { ModalContext } from "../../../providers/ModalProvider";
import warningImage from "../../../assets/img/warning.png";
import { setDrawerState } from "../../../redux/actions";
import { useNumericFormat } from "react-number-format";
import Loader from "../../../components/Loader";
import useFormat from "../../../hooks/useFormat";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { MERGE_CONSUMER_RECORDS } from "../../../providers/RBACProvider/permissionList";
const ReviewGroupAction = () => {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { handleModal } = useContext(ModalContext);
  const { currentOrganization } = useContext(MaxyfiContext);
  //Get Grouping

  const useCurrencyFormat = (amount) => {
    const { currencyDecimalSeparator, currencyGroupStyle, currencySeparator } =
      useContext(MaxyfiContext);

    let numberFormat = useNumericFormat({
      thousandsGroupStyle: currencyGroupStyle,
      decimalScale: 2,
      fixedDecimalScale: true,
      thousandSeparator: currencySeparator,
      decimalSeparator: currencyDecimalSeparator,
    });
    let value = numberFormat.format(amount ? `${amount}` : "0");

    return value;
  };
  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const reviewGroupinglist = useQuery(
    [`grouping-action-${customerId}`],
    async () => {
      return await getGroupingList({
        organization: currentOrganization,
        customerId: customerId,
      });
    }
  );
  const { isLoading, data } = reviewGroupinglist;

  const groupSuggest = useMutation(
    (groupingId) =>
      groupSuggestion({
        organization: currentOrganization,
        customerId,
        suggestionId: groupingId,
      }),
    {
      onError: (error) => {
        if (error.response) {
          const { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          } else {
            errorData = data.error || "An unknown error occurred";
          }
          toast.error(errorData);
        } else {
          toast.error("An unexpected error occurred");
        }
      },
      onSuccess: (data) => {
        closeDrawer();
        queryClient.refetchQueries([`grouping-action-${customerId}`]);
        queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
      },
    }
  );

  const onGroupSubmit = async (suggestId) => {
    await groupSuggest.mutateAsync(suggestId);
  };

  const removeGroupData = useMutation(
    (suggestionId) =>
      removeSugesstGroup({
        organization: currentOrganization,
        customerId,
        suggestionId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";
          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        queryClient.refetchQueries([`grouping-action-${customerId}`]);
        queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
      },
    }
  );

  const ReviewGroupScreen = ({
    suggestionId,
    customerName,
    daySince,
    backgroundColor,
    border,
    contactSuggest,
    borderRadius,
    businessUnit,
    customField,
    contact,
    timezone,
    invoiceCount,
    invoicesAmount,
    invoicesCurrency,
    suggestInfo,
  }) => {
    const format = useFormat();
    const totalInvoice = useCurrencyFormat(invoicesAmount);
    let suggestInfoText;

    switch (suggestInfo) {
      case "SBFLA":
        suggestInfoText = "Suggested based on First, Last Name & Address";
        break;
      case "SBFLP":
        suggestInfoText = "Suggested based on First, Last Name & Phone";
        break;
      case "SBFLE":
        suggestInfoText = "Suggested based on First, Last Name & Email";
        break;
      case "SBSD":
        suggestInfoText = "Suggested based on SSN & DOB";
        break;
      default:
        suggestInfoText = false;
    }

    return (
      <>
        <div
          className="review-card-container"
          style={{
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            border: border,
          }}
        >
          <div className="review-sub-container">
            <div className="review-title">
              <Typography type="h3"> {customerName}</Typography>
              <div className="review-header_invoices">
                <Typography type="p" className="text-secondary">
                  {invoiceCount} invoices
                </Typography>
                <Icon icon="dot" size={4} />
                <Typography type="h3">
                  {invoicesCurrency} {totalInvoice}
                </Typography>
              </div>
            </div>
            <div className="review-contact-details">
              <div className="review-name-dsc">
                <div className="review-customer_name">
                  <Icon icon="user_outLine" />
                  {_.get(contact, "nam", []).map((name) => {
                    return (
                      <>
                        <Typography
                          type="p"
                          className="regular"
                          subType="regular"
                          style={{ textAlign: "center" }}
                        >
                          {_.get(name, "val", "-")}
                        </Typography>
                        {name?.imt && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: "#EBFFF4",
                              justifyContent: "center",
                              gap: "1px",
                            }}
                          >
                            <Icon size="17" icon="check_in" color="#0FB158" />
                            {_.get(name, "prc", "") && (
                              <Typography
                                type="p"
                                subType="regular"
                                style={{ paddingTop: "2px" }}
                              >
                                {_.get(name, "prc", "")}
                              </Typography>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                <Typography
                  type="p"
                  className="text-secondary"
                  style={{ textAlign: "end", flex: "1" }}
                >
                  DCS:{daySince}
                </Typography>
              </div>
              <div className="review-mobile">
                <Icon icon="call_outline" />
                {_.get(contact, "phn", []).length > 0
                  ? _.get(contact, "phn", []).map((phone, index) => {
                      return (
                        <div
                          key={index}
                          style={{ display: "flex", gap: "2px" }}
                        >
                          <Typography
                            type="p"
                            className="regular"
                            subType="regular"
                          >
                            {_.get(phone, "val", "-")}{" "}
                          </Typography>
                          {phone.imt && (
                            <Icon size="17" icon="check_in" color="#0FB158" />
                          )}
                        </div>
                      );
                    })
                  : "- "}
              </div>
              <div className="review-email">
                <Icon icon="email_outline" />
                {_.get(contact, "eml", []).length > 0
                  ? _.get(contact, "eml", []).map((email, index) => {
                      return (
                        <div key={index} style={{ display: "flex" }}>
                          {" "}
                          <Typography
                            type="p"
                            className="regular"
                            subType="regular"
                          >
                            {_.get(email, "val", "-")}{" "}
                          </Typography>
                          {email.imt && (
                            <Icon size="17" icon="check_in" color="#0FB158" />
                          )}{" "}
                        </div>
                      );
                    })
                  : " - "}
              </div>
              <div className="review-mobile">
                <Icon icon="location_outline" />
                {_.get(contact, "adr", []).length > 0
                  ? _.get(contact, "adr", []).map((address) => {
                      return (
                        <Typography
                          type="p"
                          className="regular"
                          subType="regular"
                          style={{ width: "fit-content" }}
                        >
                          {_.get(address, "val", "-")}
                        </Typography>
                      );
                    })
                  : " - "}
              </div>
              <div className="review-mobile">
                <Typography type="p" className="regular">
                  SSN
                </Typography>

                <div
                  style={{ display: "flex", gap: "2px", alignItems: "center" }}
                >
                  {_.get(customField, "ssn.val", "") ? (
                    <Typography type="p" className="regular" subType="regular">
                      {_.get(customField, "ssn.val", "")}{" "}
                    </Typography>
                  ) : _.get(customField, "ssn", []).length > 0 ? (
                    _.get(customField, "ssn", []).map((to) => (
                      <Typography
                        type="p"
                        className="regular"
                        subType="regular"
                      >
                        {_.get(to, "val", "-")}{" "}
                      </Typography>
                    ))
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="review-address">
                <Typography type="p" className="regular">
                  DOB
                </Typography>

                <div style={{ display: "flex", gap: "2px" }}>
                  <Typography type="p" className="regular" subType="regular">
                    {_.get(customField, "dob.val", "")
                      ? _.get(customField, "dob.val", "")
                        ? format.date(_.get(customField, "dob.val", ""))
                        : "-"
                      : _.get(customField, "dob", []).length > 0
                      ? _.get(customField, "dob", [])
                          .map((to) =>
                            _.get(to, "val")
                              ? format.date(_.get(to, "val", 0))
                              : "-"
                          )
                          .toString()
                      : "-"}
                  </Typography>

                  {_.get(customField, "dob.val", "") ? (
                    <></>
                  ) : _.get(customField, "dob", []).length > 0 ? (
                    _.get(customField, "dob", []).some(
                      (obj) => obj.imt === true
                    ) && <Icon size="17" icon="check_in" color="#0FB158" />
                  ) : (
                    <></>
                  )}
                </div>
                <Typography type="h5" style={{ textAlign: "end", flex: "1" }}>
                  Primary
                </Typography>
              </div>
              <div className="business-unit">
                <Icon icon="suite_case" />
                {businessUnit ? (
                  <Typography type="p" className="regular" subType="regular">
                    {format.rd({
                      id: businessUnit,
                      name: "business_unit_list",
                    })}
                  </Typography>
                ) : (
                  " - "
                )}
                <ReviewGroupTime timezone={timezone} />
              </div>
            </div>
          </div>
          {contactSuggest && (
            <>
              <div className="review-consumer-suggest_line"></div>
              <div className="review-group-sugesst_footer">
                <div className="review-group-suggestion_content">
                  <Icon
                    icon="terms_outline"
                    size="15px"
                    style={{ alignSelf: "center" }}
                  ></Icon>
                  <Typography
                    type="p"
                    className="text-secondary"
                    style={{ alignSelf: "center" }}
                  >
                    {suggestInfoText}
                  </Typography>
                  <div className="review-group_buttons">
                    <RBACWrapper
                      role={MERGE_CONSUMER_RECORDS}
                      type="PERMISSION"
                    >
                      <TextButton
                        style={{ backgroundColour: "#E4F0FC" }}
                        type="button"
                        kind={KIND.secondary}
                        onClick={() => {
                          handleModal({
                            title: "You are about to remove a Suggest ",
                            content:
                              "This will remove your suggest from Grouping. Are you sure?",
                            successCallback: () => {
                              removeGroupData.mutate(suggestionId);
                            },
                            formContent: ({ handleModal }) => {
                              return <></>;
                            },
                            cancelCallback: () => {},
                            isChildren: false,
                            image: warningImage,
                            buttonText: "Remove",
                            closeButtonText: "Cancel",
                            isCloseAble: true,
                          });
                        }}
                        size={TextBoxSize.SIZE.mini}
                      >
                        Cancel
                      </TextButton>
                    </RBACWrapper>

                    <RBACWrapper
                      role={MERGE_CONSUMER_RECORDS}
                      type="PERMISSION"
                    >
                      <TextButton
                        type="button"
                        size={"mini"}
                        onClick={() => {
                          onGroupSubmit(suggestionId);
                        }}
                      >
                        Group Now
                      </TextButton>
                    </RBACWrapper>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ width: "650px" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <ReviewGroupScreen
              customField={_.get(data, "data.doc.ccd.cf", {})}
              customerName={_.get(data, "data.doc.ccd.customer_name", "")}
              contact={_.get(data, "data.doc.ccd.cnt", {})}
              daySince={_.get(data, "data.doc.ccd.days_since_created", "")}
              businessUnit={_.get(data, "data.doc.ccd.business_unit", "")}
              timezone={_.get(data, "data.doc.ccd.timezone", "")}
              invoiceCount={_.get(
                data,
                "data.doc.ccd.total_outstanding_invoice_count",
                ""
              )}
              invoicesAmount={_.get(
                data,
                "data.doc.ccd.total_outstanding_invoice_amount.value",
                ""
              )}
              invoicesCurrency={_.get(
                data,
                "data.doc.ccd.total_outstanding_invoice_amount.currency",
                ""
              )}
              border={"0px"}
              borderRadius={"0"}
              backgroundColor={"#F9FBFF"}
              contactSuggest={false}
            />
            <div className="review-line-separtor"></div>
            <Typography type="h2">
              {" "}
              Review and Confirm below possible grouping (
              {_.get(data, "data.doc.suggestions", []).length})
            </Typography>
            {_.get(data, "data.doc.suggestions", []) ? (
              _.get(data, "data.doc.suggestions", []).map((suggest) => {
                return (
                  <ReviewGroupScreen
                    backgroundColor={"#ffff"}
                    border={"1px solid #cdced9"}
                    borderRadius={"5px"}
                    suggestionId={_.get(suggest, "_id", "")}
                    customerName={_.get(suggest, "customer_name", "-")}
                    businessUnit={_.get(suggest, "business_unit", "-")}
                    customField={_.get(suggest, "data.cf", {})}
                    contact={suggest.data.cnt}
                    daySince={_.get(suggest, "days_since_created", 0)}
                    timezone={_.get(suggest, "timezone")}
                    invoiceCount={_.get(
                      suggest,
                      "total_outstanding_invoice_count",
                      0
                    )}
                    suggestInfo={suggest?.info}
                    invoicesAmount={_.get(
                      suggest,
                      "total_outstanding_invoice_amount.value",
                      0
                    )}
                    invoicesCurrency={_.get(
                      suggest,
                      "total_outstanding_invoice_amount.currency",
                      ""
                    )}
                    contactSuggest={true}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewGroupAction;
