import React from "react";

const CopySvg = ({ size = 19, color = "#ADADAD" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.98081 13.25C7.60194 13.25 7.28125 13.1188 7.01875 12.8563C6.75625 12.5938 6.625 12.2731 6.625 11.8942V3.35581C6.625 2.97694 6.75625 2.65625 7.01875 2.39375C7.28125 2.13125 7.60194 2 7.98081 2H14.2692C14.6481 2 14.9688 2.13125 15.2313 2.39375C15.4938 2.65625 15.625 2.97694 15.625 3.35581V11.8942C15.625 12.2731 15.4938 12.5938 15.2313 12.8563C14.9688 13.1188 14.6481 13.25 14.2692 13.25H7.98081ZM7.98081 12.125H14.2692C14.3269 12.125 14.3798 12.1009 14.4278 12.0528C14.4759 12.0048 14.5 11.9519 14.5 11.8942V3.35581C14.5 3.29806 14.4759 3.24519 14.4278 3.19719C14.3798 3.14906 14.3269 3.125 14.2692 3.125H7.98081C7.92306 3.125 7.87019 3.14906 7.82219 3.19719C7.77406 3.24519 7.75 3.29806 7.75 3.35581V11.8942C7.75 11.9519 7.77406 12.0048 7.82219 12.0528C7.87019 12.1009 7.92306 12.125 7.98081 12.125ZM5.35581 15.875C4.97694 15.875 4.65625 15.7438 4.39375 15.4813C4.13125 15.2188 4 14.8981 4 14.5192V5.41831C4 5.25869 4.05387 5.125 4.16162 5.01725C4.26925 4.90962 4.40288 4.85581 4.5625 4.85581C4.72213 4.85581 4.85581 4.90962 4.96356 5.01725C5.07119 5.125 5.125 5.25869 5.125 5.41831V14.5192C5.125 14.5769 5.14906 14.6298 5.19719 14.6778C5.24519 14.7259 5.29806 14.75 5.35581 14.75H12.2067C12.3663 14.75 12.5 14.8038 12.6078 14.9114C12.7154 15.0192 12.7692 15.1529 12.7692 15.3125C12.7692 15.4721 12.7154 15.6058 12.6078 15.7134C12.5 15.8211 12.3663 15.875 12.2067 15.875H5.35581Z"
        fill={color}
      />
    </svg>
  );
};

export default CopySvg;
