export default function FieldVisit({ size = 22, color = "#000000" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.21875 13.1094C9.1475 13.1094 9.08021 13.1016 9.01687 13.0859C8.95354 13.0703 8.89583 13.0417 8.84375 13C7.54167 11.9896 6.57552 11.0182 5.94531 10.0859C5.3151 9.15365 5 8.22917 5 7.3125C5 6.602 5.1276 5.97906 5.38281 5.44369C5.63802 4.90831 5.96875 4.45833 6.375 4.09375C6.78125 3.72917 7.23427 3.45573 7.73406 3.27344C8.23385 3.09115 8.72865 3 9.21844 3C9.70823 3 10.2031 3.09115 10.7031 3.27344C11.2031 3.45573 11.6562 3.72917 12.0625 4.09375C12.4688 4.45833 12.7995 4.90831 13.0547 5.44369C13.3099 5.97906 13.4375 6.602 13.4375 7.3125C13.4375 8.22917 13.1224 9.15365 12.4922 10.0859C11.862 11.0182 10.8958 11.9896 9.59375 13C9.54167 13.0417 9.48327 13.0703 9.41856 13.0859C9.35386 13.1016 9.28726 13.1094 9.21875 13.1094ZM9.22014 8.3125C9.5213 8.3125 9.77865 8.20527 9.99219 7.9908C10.2057 7.77634 10.3125 7.51853 10.3125 7.21736C10.3125 6.9162 10.2053 6.65885 9.9908 6.44531C9.77634 6.23177 9.51853 6.125 9.21736 6.125C8.9162 6.125 8.65885 6.23223 8.44531 6.4467C8.23177 6.66116 8.125 6.91897 8.125 7.22014C8.125 7.5213 8.23223 7.77865 8.4467 7.99219C8.66116 8.20573 8.91897 8.3125 9.22014 8.3125ZM5.46875 15.5C5.33594 15.5 5.22461 15.4548 5.13477 15.3645C5.04492 15.2741 5 15.1621 5 15.0285C5 14.8949 5.04492 14.7839 5.13477 14.6953C5.22461 14.6068 5.33594 14.5625 5.46875 14.5625H12.9688C13.1016 14.5625 13.2129 14.6077 13.3027 14.698C13.3926 14.7884 13.4375 14.9004 13.4375 15.034C13.4375 15.1676 13.3926 15.2786 13.3027 15.3672C13.2129 15.4557 13.1016 15.5 12.9688 15.5H5.46875Z"
        fill={color}
      />
    </svg>
  );
}
