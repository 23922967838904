import Keycloak from "keycloak-js";

const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const KEYCLOAK_REALM = process.env.REACT_APP_REALM;

const keycloak = new Keycloak({
  clientId: `${KEYCLOAK_CLIENT_ID}`,
  url: `${KEYCLOAK_URL}`,
  realm: `${KEYCLOAK_REALM}`,
});

export default keycloak;
