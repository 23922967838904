import React from "react";

const UpdatedClock = ({ size = 18, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7745 4.12495V5.24995C14.0995 4.19995 13.1245 3.44995 11.9245 2.92495C10.4245 2.24995 8.77453 2.09995 7.19953 2.54995C5.62453 2.99995 4.27453 3.89995 3.37453 5.24995C2.47453 6.59995 2.09953 8.24995 2.32453 9.82495C2.54953 11.4 3.29953 12.9 4.49953 13.95C5.69953 15 7.27453 15.675 8.92453 15.75C10.5745 15.825 12.1495 15.3 13.4245 14.25C14.5495 13.35 15.3745 12.075 15.7495 10.65C15.8245 10.425 15.6745 10.2 15.3745 10.125C15.1495 10.05 14.9245 10.2 14.8495 10.5C14.5495 11.7 13.7995 12.75 12.8245 13.575C11.6995 14.475 10.3495 14.925 8.92453 14.85C7.49953 14.775 6.14953 14.25 5.09953 13.35C4.04953 12.375 3.37453 11.1 3.22453 9.74995C2.99953 8.32495 3.37453 6.89995 4.12453 5.77495C4.94953 4.57495 6.07453 3.74995 7.49953 3.37495C8.84953 2.99995 10.3495 3.07495 11.6245 3.67495C12.6745 4.12495 13.4995 4.87495 14.0995 5.77495L12.9745 5.62495C12.7495 5.62495 12.5245 5.77495 12.5245 5.99995C12.4495 6.22495 12.6745 6.44995 12.8995 6.44995L15.6745 6.89995V4.12495C15.6745 3.89995 15.5245 3.67495 15.2245 3.67495C14.9995 3.67495 14.7745 3.89995 14.7745 4.12495ZM9.44953 5.24995C9.44953 5.02495 9.29953 4.79995 8.99953 4.79995C8.69953 4.79995 8.54953 5.02495 8.54953 5.24995V8.77495C8.54953 9.07495 8.69953 9.37495 8.99953 9.44995L12.5245 11.25C12.7495 11.325 12.9745 11.25 13.0495 11.1C13.1245 10.875 13.0495 10.65 12.8995 10.575L9.37453 8.84995V5.24995H9.44953Z"
        fill={color}
      />
    </svg>
  );
};

export default UpdatedClock;
