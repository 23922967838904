function Plus() {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.2751 14.0135H14.0135V22.2751C14.0135 23.1839 13.27 23.9275 12.3612 23.9275C11.4524 23.9275 10.7089 23.1839 10.7089 22.2751V14.0135H2.44725C1.53847 14.0135 0.794922 13.27 0.794922 12.3612C0.794922 11.4524 1.53847 10.7089 2.44725 10.7089H10.7089V2.44725C10.7089 1.53847 11.4524 0.794922 12.3612 0.794922C13.27 0.794922 14.0135 1.53847 14.0135 2.44725V10.7089H22.2751C23.1839 10.7089 23.9275 11.4524 23.9275 12.3612C23.9275 13.27 23.1839 14.0135 22.2751 14.0135Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default Plus;
