export default function LetterStatus({ size = 22, color = "#000000" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 2.25H13.4925C14.325 2.25 14.9925 2.9175 14.9925 3.7425V14.2575C14.9925 15.0825 14.3175 15.75 13.4925 15.75H4.5C3.6675 15.75 3 15.0825 3 14.2575V3.7425C3 2.9175 3.675 2.25 4.5 2.25ZM11.16 5.16V5.085C11.16 4.875 10.995 4.71 10.785 4.71H4.995C4.785 4.71 4.62 4.875 4.62 5.085V5.16C4.62 5.37 4.785 5.535 4.995 5.535H10.785C10.995 5.535 11.16 5.37 11.16 5.16ZM9.435 7.515V7.44C9.435 7.23 9.27 7.065 9.06 7.065H4.9875C4.7775 7.065 4.6125 7.23 4.6125 7.44V7.515C4.6125 7.725 4.7775 7.89 4.9875 7.89H9.06C9.27 7.89 9.435 7.725 9.435 7.515ZM11.76 10.635C11.805 10.59 11.865 10.5675 11.925 10.5675C11.985 10.5675 12.045 10.59 12.09 10.635C12.135 10.68 12.1575 10.74 12.1575 10.8075C12.1575 10.875 12.135 10.9275 12.09 10.9725L10.3275 12.735C10.2675 12.795 10.2 12.8175 10.125 12.8175C10.05 12.8175 9.9825 12.7875 9.9225 12.735L9.0525 11.865C9.0075 11.82 8.985 11.76 8.985 11.7C8.985 11.64 9.0075 11.58 9.0525 11.535C9.0975 11.49 9.1575 11.4675 9.225 11.4675C9.2925 11.4675 9.345 11.49 9.39 11.535L10.125 12.27L11.76 10.635ZM11.76 14.3625C12.1275 14.205 12.4425 13.9875 12.72 13.7175C12.99 13.4475 13.2075 13.125 13.365 12.7575C13.5225 12.39 13.605 12 13.605 11.58C13.605 11.16 13.5225 10.77 13.365 10.4025C13.2075 10.035 12.99 9.72 12.72 9.4425C12.45 9.1725 12.1275 8.955 11.76 8.7975C11.3925 8.64 11.0025 8.5575 10.5825 8.5575C10.1625 8.5575 9.7725 8.64 9.405 8.7975C9.0375 8.955 8.7225 9.1725 8.445 9.4425C8.175 9.7125 7.9575 10.035 7.8 10.4025C7.6425 10.77 7.56 11.16 7.56 11.58C7.56 12 7.6425 12.39 7.8 12.7575C7.9575 13.125 8.175 13.44 8.445 13.7175C8.715 13.9875 9.0375 14.205 9.405 14.3625C9.7725 14.52 10.1625 14.6025 10.5825 14.6025C11.0025 14.6025 11.3925 14.52 11.76 14.3625Z"
        fill={color}
      />
    </svg>
  );
}
