import { ParagraphMedium } from "baseui/typography";
import React, { useContext } from "react";
import _ from "lodash";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

export const StatusType = ({ type, alignment }) => {
  switch (type) {
    case "OPEN":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment ? alignment : "center",
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#FD372A",
            }}
          ></div>
          <ParagraphMedium>Open</ParagraphMedium>
        </div>
      );

    case "SUBMITTED":
    case "IN_PROGRESS":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment ? alignment : "center",
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: type === "IN_PROGRESS" ? "#FBBD04" : "#7d7c7c",
            }}
          ></div>
          <ParagraphMedium>
            {type === "IN_PROGRESS" ? "In Progress" : "Submitted"}
          </ParagraphMedium>
        </div>
      );

    case "CLOSED":
      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: alignment ? alignment : "center",
            flex: "1",
          }}
        >
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "50px",
              backgroundColor: "#14ae5c",
            }}
          ></div>
          <ParagraphMedium>Closed</ParagraphMedium>
        </div>
      );

    default:
      return null;
  }
};

const ReqInfStatus = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  return <StatusType type={value} alignment={alignment} />;
};

export default ReqInfStatus;
