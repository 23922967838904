import { type } from "@testing-library/user-event/dist/type";

export const CUSTOMER_CONTACT_ADD_ADHOC_UPLOAD_DATA =
  "CUSTOMER_CONTACT_ADD_ADHOC_UPLOAD_DATA";
export const CUSTOMER_CONTACT_UPDATE_MAP_FIELDS =
  "CUSTOMER_CONTACT_UPDATE_MAP_FIELDS";
export const CUSTOMER_CONTACT_UPDATE_FORMAT_DATA =
  "CUSTOMER_CONTACT_UPDATE_FORMAT_DATA";
export const CUSTOMER_CONTACT_UPDATE_DEFAULT_DATA =
  "CUSTOMER_CONTACT_UPDATE_DEFAULT_DATA";
export const CUSTOMER_CONTACT_UPDATE_SYSTEM_FIELDS =
  "CUSTOMER_CONTACT_UPDATE_SYSTEM_FIELDS";
export const CUSTOMER_CONTACT_UPDATE_TEMPLATE_DATA_LIST =
  "CUSTOMER_CONTACT_UPDATE_TEMPLATE_DATA_LIST";
export const CUSTOMER_CONTACT_INITIALSTATE_STATE =
  "CUSTOMER_CONTACT_INITIALSTATE_STATE";
export const CUSTOMER_CONTACT_ADHOC_REFETCH = "CUSTOMER_CONTACT_ADHOC_REFETCH";
export const CUSTOMER_CONTACT_SET_AU_FILTER = "CUSTOMER_CONTACT_SET_AU_FILTER";
export const CUSTOMER_CONTACT_ADHOC_FILTER = "CUSTOMER_CONTACT_ADHOC_FILTER";
export const CUSTOMER_CONTACT_VALIDATION_ADHOC_FILTER =
  "CUSTOMER_CONTACT_VALIDATION_ADHOC_FILTER";
export const CUSTOMER_CONTACT_MAP_BASED_ON = "CUSTOMER_CONTACT_MAP_BASED_ON";
export const CUSTOMER_CONTACT_BUSINESS_UNIT_DATA =
  "CUSTOMER_CONTACT_BUSINESS_UNIT_DATA";
export const CUSTOMER_CONTACT_FORMULA_FIELDS =
  "CUSTOMER_CONTACT_FORMULA_FIELDS";
export const CUSTOMER_CONTACT_DELETE_FORMULA_FIELDS =
  "CUSTOMER_CONTACT_DELETE_FORMULA_FIELDS";
export const CUSTOMER_CONTACT_UPDATE_FORMULA_FIELDS =
  "CUSTOMER_CONTACT_UPDATE_FORMULA_FIELDS";
export const CUSTOMER_CONTACT_ORG_DTL = "CUSTOMER_CONTACT_ORG_DTL";
export const CUSTOMER_CONTACT_BUSINESS_LOACTION_DATA =
  "CUSTOMER_CONTACT_BUSINESS_LOACTION_DATA";
  
  export const CONTACT_UPDATE_CONTACT_INDEX ="CONTACT_UPDATE_CONTACT_INDEX";

export const setAdhocFilterCustomerConatct = (data) => {
  return {
    type: CUSTOMER_CONTACT_ADHOC_FILTER,
    payload: data,
  };
};

export const setAdhocUploadFilterCustomerConatct = (data) => {
  return {
    type: CUSTOMER_CONTACT_SET_AU_FILTER,
    payload: data,
  };
};

export const setInitialAdhocStateCustomerConatct = () => {
  return {
    type: CUSTOMER_CONTACT_INITIALSTATE_STATE,
  };
};

export const contactUpdateContactIndex = ({ index, contactIndex , module }) => {
  return {
    type : CONTACT_UPDATE_CONTACT_INDEX,
    payload : { index, contactIndex , module }
  }
}
export const setAdhocUploadDataCustomerConatct = (payload) => {
  return {
    type: CUSTOMER_CONTACT_ADD_ADHOC_UPLOAD_DATA,
    payload,
  };
};

export const updateSystemFieldCustomerConatct = ({
  index,
  systemField,
  type,
  module
}) => {
  return {
    type: CUSTOMER_CONTACT_UPDATE_SYSTEM_FIELDS,
    payload: { index, systemField, type  , module},
  };
};

export const updateFormatDataCustomerConatct = ({ index, formatData }) => {
  return {
    type: CUSTOMER_CONTACT_UPDATE_FORMAT_DATA,
    payload: { index, formatData },
  };
};

export const updateDefaultDataCustomerConatct = ({
  index,
  defaultData,
  referance,
}) => {
  return {
    type: CUSTOMER_CONTACT_UPDATE_DEFAULT_DATA,
    payload: { index, defaultData, referance },
  };
};

export const updateTemplateDataListCustomerConatct = (templateData) => {
  return {
    type: CUSTOMER_CONTACT_UPDATE_TEMPLATE_DATA_LIST,
    payload: { ...templateData },
  };
};

export const adhocRefetchTableCustomerConatct = () => {
  return {
    type: CUSTOMER_CONTACT_ADHOC_REFETCH,
  };
};

export const validationFieldsCustomerConatct = () => {
  return {
    type: CUSTOMER_CONTACT_VALIDATION_ADHOC_FILTER,
  };
};

export const mapBasedOnFnCustomerConatct = (mapData) => {
  return {
    type: CUSTOMER_CONTACT_MAP_BASED_ON,
    payload: mapData,
  };
};

export const businessUnitDataCustomerConatct = ({ rdBussinessUnit }) => {
  return {
    type: CUSTOMER_CONTACT_BUSINESS_UNIT_DATA,
    payload: rdBussinessUnit,
  };
};

export const formulaDispachFnCustomerConatct = (payload) => {
  return {
    type: CUSTOMER_CONTACT_FORMULA_FIELDS,
    payload,
  };
};

export const deleteFormulaFieldCustomerConatct = (payload) => {
  return {
    type: CUSTOMER_CONTACT_DELETE_FORMULA_FIELDS,
    payload,
  };
};

export const updateFormulaFieldCustomerConatct = (payload) => {
  return {
    type: CUSTOMER_CONTACT_UPDATE_FORMULA_FIELDS,
    payload,
  };
};

export const customerDtl = ({ orgDtl }) => {
  return {
    type: CUSTOMER_CONTACT_ORG_DTL,
    payload: orgDtl,
  };
};

export const businessLocationDataCustomerConatct = ({ orgDtl }) => {
  return {
    type: CUSTOMER_CONTACT_BUSINESS_LOACTION_DATA,
    payload: orgDtl,
  };
};
