import React from "react";
import { StyledSvg } from "baseui/icon";

function RelationManager({ size = "22px" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M4.58333 13.7497V10.9997H7.33333V9.16634H4.58333V6.41634H2.75V9.16634H0V10.9997H2.75V13.7497H4.58333ZM11 12.6038C8.855 12.6038 4.58333 13.6763 4.58333 15.8122V17.4163H17.4167V15.8122C17.4167 13.6763 13.145 12.6038 11 12.6038ZM6.72833 15.583C7.49833 15.0513 9.35917 14.4372 11 14.4372C12.6408 14.4372 14.5017 15.0513 15.2717 15.583H6.72833ZM11 10.9997C12.7692 10.9997 14.2083 9.56051 14.2083 7.79134C14.2083 6.02217 12.7692 4.58301 11 4.58301C9.23083 4.58301 7.79167 6.02217 7.79167 7.79134C7.79167 9.56051 9.23083 10.9997 11 10.9997ZM11 6.41634C11.7608 6.41634 12.375 7.03051 12.375 7.79134C12.375 8.55218 11.7608 9.16634 11 9.16634C10.2392 9.16634 9.625 8.55218 9.625 7.79134C9.625 7.03051 10.2392 6.41634 11 6.41634ZM15.5833 10.9997C17.3525 10.9997 18.7917 9.56051 18.7917 7.79134C18.7917 6.02217 17.3525 4.58301 15.5833 4.58301C15.3633 4.58301 15.1433 4.60134 14.9325 4.64717C15.6292 5.50884 16.0417 6.59967 16.0417 7.79134C16.0417 8.98301 15.6108 10.0647 14.9142 10.9263C15.1342 10.9722 15.3542 10.9997 15.5833 10.9997ZM17.71 12.8513C18.6267 13.5938 19.25 14.5655 19.25 15.8122V17.4163H22V15.8122C22 14.263 19.7633 13.2822 17.71 12.8513Z"
        fill="#333860"
      />
    </svg>
  );
}

export default RelationManager;
