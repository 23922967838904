const DairyNoteDanger = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 10.3555H2.62109V11.6445H0V10.3555Z" fill="#FD372A" />
      <path d="M19.3789 10.3555H22V11.6445H19.3789V10.3555Z" fill="#FD372A" />
      <path
        d="M16.4688 4.61969L18.2916 2.79688L19.203 3.70828L17.3802 5.5311L16.4688 4.61969Z"
        // fill="black"
        fill="#FD372A"
      />
      <path
        d="M4.61969 5.53085L2.79688 3.70804L3.70828 2.79663L5.5311 4.61945L4.61969 5.53085Z"
        fill="#FD372A"
      />
      <path d="M10.3555 0H11.6445V2.62109H10.3555V0Z" fill="#FD372A" />
      <path
        d="M5.19922 19.4219C4.48721 19.4219 3.91016 19.9989 3.91016 20.7109V22H18.0898V20.7109C18.0898 19.9989 17.5128 19.4219 16.8008 19.4219H5.19922Z"
        fill="#FD372A"
      />
      <path
        d="M5.19922 11V18.1328H16.8008V11C16.8008 7.80135 14.1987 5.19922 11 5.19922C7.80135 5.19922 5.19922 7.80135 5.19922 11ZM10.3555 9.06641H11.6445V12.9336H10.3555V9.06641ZM10.3555 14.2227H11.6445V15.5117H10.3555V14.2227Z"
        fill="#FD372A"
      />
    </svg>
  );
};

export default DairyNoteDanger;
