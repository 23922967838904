import { size } from "lodash";
import React from "react";

const Card = ({ color = "#333860", size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.03914 17.7083C3.60596 17.7083 3.23724 17.5561 2.93297 17.2519C2.6287 16.9476 2.47656 16.5788 2.47656 16.1455V5.85424C2.47656 5.42094 2.6287 5.05216 2.93297 4.74789C3.23724 4.44363 3.60596 4.2915 4.03914 4.2915H17.9604C18.3936 4.2915 18.7623 4.44363 19.0666 4.74789C19.3709 5.05216 19.523 5.42094 19.523 5.85424V16.1455C19.523 16.5788 19.3709 16.9476 19.0666 17.2519C18.7623 17.5561 18.3936 17.7083 17.9604 17.7083H4.03914ZM3.75898 10.5741H18.2406V7.79597H3.75898V10.5741Z"
        fill={color}
      />
    </svg>
  );
};

export default Card;
