function Dispute() {
  return (
    <>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.883 17.2054H9.71308C10.1987 17.2054 10.596 17.6092 10.596 18.1026C10.596 18.5962 10.1987 19 9.71308 19H0.883C0.397354 19 0 18.5962 0 18.1026C0 17.6092 0.397354 17.2054 0.883 17.2054ZM3.74393 5.60275L6.24283 3.06329L17.4834 14.4864C18.1722 15.1863 18.1722 16.3259 17.4834 17.0258C16.7947 17.7257 15.6734 17.7257 14.9846 17.0258L3.74393 5.60275ZM11.2406 0.523826L13.7396 3.06329C14.4283 3.76321 14.4283 4.90283 13.7396 5.60275L12.4857 6.87697L7.4967 1.79805L8.74174 0.532799C9.43045 -0.176097 10.5519 -0.176097 11.2406 0.523826ZM2.4989 6.86799L7.4967 11.9469L6.25167 13.2122C5.56293 13.9121 4.44151 13.9121 3.75276 13.2122L1.25386 10.6727C0.565119 9.97276 0.565119 8.83316 1.25386 8.13324L2.4989 6.86799Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default Dispute;
