import React, { useContext, useState, useMemo, useEffect, useRef } from "react";
import { Drawer, ANCHOR } from "baseui/drawer";
import Send from "../../assets/img/whatsapp/Send";
import Attachment_fill from "../../assets/img/whatsapp/Attachment_fill";
import Smiley from "../../assets/img/whatsapp/Smiley";
import {
  HeadingXSmall,
  LabelMedium,
  LabelXSmall,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import { Avatar } from "baseui/avatar";
import WhatsApp from "../../assets/img/svg/Template/WhatsApp";
import { SIZE, TextBox } from "../TextBox";
import { IconButton, KIND } from "../IconButton";
import InputEmoji from "react-input-emoji";
import Picker from "emoji-picker-react";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";
import { TextArea } from "../TextArea";
import { WhatsAppInput } from "../WhatsAppInput/WhatsAppInput";
import Weblink from "../../assets/img/whatsapp/Weblink";
import Call from "../../assets/img/svg/CpCard/Call";
import { CUSTOMER_OVERVIEW, CUSTOMER_OVERVIEW_ } from "../../constants";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import {
  executeAdhocCustomerAction,
  getCustomerContactDetail,
  getWhatsappChats,
  markAsRead,
} from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useDispatch, useSelector } from "react-redux";
import { whatsappDrawerClose } from "../../redux/customerOverview/timeline/action";
import DeliverTick from "../../assets/img/svg/DeliverTick";
import { toast } from "react-toastify";
import moment from "moment";
import { FormattedTime } from "react-intl";
import AlertInfo from "../../assets/img/svg/AlertInfo";
import SingleTick from "../../assets/img/svg/SingleTick";
import { useParams } from "react-router-dom";
import _ from "lodash";
import queryClient from "../../providers/queryClient";

const DeliveredStatusIcon = ({ type }) => {
  switch (type) {
    case "FAILURE":
      return (
        <div className="whatsapp_info_alert">
          <AlertInfo />
        </div>
      );
    case "UNREAD":
      return (
        <>
          <SingleTick />
        </>
      );
    case "READ":
      return (
        <>
          <DeliverTick />
        </>
      );
    case "SUCCESS":
      return (
        <>
          <SingleTick />
        </>
      );
    default:
      return null;
  }
};

const WhatsAppChatV2 = ({ entity = "customer" }) => {
  const { customerId } = useParams();
  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setInputStr((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  let whatsappData = useSelector((e) => e.customerOverviewDrawer);

  const { phone_code, phone_number } = useMemo(() => {
    let regCode;
    let splictPhoneNumber;
    if (whatsappData && whatsappData?.data && whatsappData?.data?.phoneNumber) {
      splictPhoneNumber = whatsappData?.data?.phoneNumber?.split(" ");
      regCode = splictPhoneNumber?.[0]?.replace("+", "");
    }
    return {
      phone_code: regCode,
      phone_number: splictPhoneNumber?.[1],
    };
  }, [whatsappData.data.phoneNumber]);

  const { isLoading, data, isFetching, refetch } = useQuery(
    `${CUSTOMER_OVERVIEW}-WHATSAPP-CHAT-${whatsappData.data.phoneNumber}`,
    async () => {
      return await getWhatsappChats({
        organization: currentOrganization,
        customerId: customerId,
        phoneCode: phone_code,
        phoneNumber: phone_number,
        entity,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      enabled: phone_number && phone_code ? true : false,
    }
  );

  const messageList = useMemo(() => {
    let list = [];
    if (data && data.data) {
      let { docs = {} } = data.data;
      let { messages = [] } = docs;
      let newMessages = [...messages];
      list = newMessages.reverse();
    }
    return list;
  }, [data]);

  const { contacts, selectedContact } = useMemo(() => {
    const contacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = [...phone, ...(landline ? landline : [])]
          .map(({ status, call_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              value: nRest?.value,
              call_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.call_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      },
      []
    );

    let findContact = contacts?.find(
      ({ value }) => value == data?.data?.docs?.phone?.value
    );

    return { contacts: contacts, selectedContact: findContact };
  }, [customerData?.data, customerId, data]);

  const executeActionMutation = useMutation(
    (formValues) =>
      executeAdhocCustomerAction({
        ...formValues,
        id: customerId,
        organization: currentOrganization,
        entity: "customer",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
        setInputStr("");
      },
    }
  );

  const markAsReadWhatsapp = useMutation(
    (formValues) =>
      markAsRead({
        ...formValues,
        customerId: customerId,
        organization: currentOrganization,
        phoneCode: `${"+" + phone_code}`,
        phoneNumber: phone_number,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        refetch();
      },
    }
  );

  const onSubmit = async (data) => {
    let value = {
      message_type: "text",
      content: data,
      action_type: "whatsapp",
      recipients: [
        {
          recipient_id: selectedContact?.recipient_id,
          id: selectedContact?.id,
        },
      ],
    };
    if (data) {
      await executeActionMutation.mutateAsync(value);
    }
  };

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  const timeZone = orgRef?.time_zone;
  let lateDate = null;
  let unReadTag = false;
  let chatContainer = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }, 500);
  }, []);

  return (
    <div style={{ width: "400px" }}>
      <div className="whatsapp_background --timeline_bg">
        <div style={{ display: "flex", padding: "15px", gap: "10px" }}>
          <div>
            <WhatsApp />
          </div>
          <div>
            <ParagraphSmall
              $style={{
                lineHeight: "1.4rem",
                marginTop: "4px",
                fontSize: "14px",
                color: "#4CAF50",
              }}
            >
              Connect via WhatsApp
            </ParagraphSmall>
          </div>
        </div>
        <div className=" whatsapp_header-part --timeline_chat_now_avatar">
          <div className="whatsapp_profile">
            <div className="whatsapp_profile_pic">
              <Avatar
                name={_.get(customerData, "data.doc.display_name", "")}
                size="scale950"
                // src="https://avatars.dicebear.com/api/human/yard.svg?width=285&mood=happy"
              />
            </div>

            <ParagraphMedium
              $style={{
                fontSize: "1.125rem",
                fontWeight: "700",
                lineheight: "1.75rem",
              }}
            >
              {_.get(customerData, "data.doc.display_name", "")}
            </ParagraphMedium>
          </div>
          {data?.data?.docs?.unread_message_count > 0 && (
            <div style={{ marginRight: "10px" }}>
              <ParagraphMedium
                $style={{ color: "#516beb", cursor: "pointer" }}
                onClick={() => markAsReadWhatsapp.mutateAsync()}
              >
                <DeliverTick /> Mark as Read
              </ParagraphMedium>
            </div>
          )}
        </div>

        <div className="whatsapp_body_area">
          <div
            className="whatsapp_mainSheet --timeline_chat_body"
            ref={chatContainer}
          >
            {messageList.map((e) => {
              let isDateTag = false;
              let formattedDate = moment
                .utc(e.make_at)
                ?.tz(timeZone)
                ?.format(currentDefaultFormatDetails?.date_format);
              if (formattedDate != lateDate) {
                isDateTag = true;
                lateDate = formattedDate;
              }

              let unReadStatus = e.status;

              let showReadStatus = false;
              if (!unReadTag && unReadStatus === "UNREAD") {
                showReadStatus = true;
                unReadTag = true;
              }
              return (
                <>
                  {isDateTag && (
                    <div
                      style={{
                        display: "flex",
                        flex: "1",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          backgroundColor: "#FEFEFE",
                          color: "#787878",
                          padding: "4px 8px",
                          borderRadius: "5px",
                          margin: "8px 3px",
                        }}
                      >
                        {lateDate}
                      </p>
                    </div>
                  )}
                  {showReadStatus && (
                    <p
                      style={{
                        fontSize: "14px",
                        backgroundColor: "#FEFEFE",
                        color: "#787878",
                        padding: "4px 8px",
                        borderRadius: "5px",
                        margin: "8px 3px",
                        textAlign: "center",
                      }}
                    >
                      {data?.data?.docs?.unread_message_count} Unread Messages
                    </p>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: e && e.is_sender ? "end" : "flex-start",
                    }}
                  >
                    <div
                      className={`whatsappPreview_chat-box  ${
                        e && e.is_sender
                          ? "--timeline_chat_box_right"
                          : "--timeline_chat_box_left"
                      }`}
                      key={e}
                    >
                      {e && e.content && e.content.header && (
                        <ParagraphMedium
                          $style={{
                            fontWeight: "500",
                            padding: "4px 0px",
                            lineHeight: " 1.5rem",
                            fontSize: "16px",
                          }}
                        >
                          {e &&
                            e.content &&
                            e.content.header &&
                            e.content.header.replace(/(<([^>]+)>)/gi, "")}
                        </ParagraphMedium>
                      )}

                      {e && e.content && e.content.body && (
                        <ParagraphSmall
                          $style={{
                            lineHeight: "1.4rem",
                            marginTop: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {e &&
                            e.content &&
                            e.content.body &&
                            e.content.body.replace(/(<([^>]+)>)/gi, "")}
                        </ParagraphSmall>
                      )}

                      {e && e.content && e.content.footer && (
                        <ParagraphSmall
                          $style={{
                            lineHeight: "1.4rem",
                            marginTop: "4px",
                            fontSize: "14px",
                            color: " rgb(107 114 128)",
                          }}
                        >
                          {e && e.content && e.content.footer}
                        </ParagraphSmall>
                      )}

                      <div className="timeline_chat_time_status">
                        <HeadingXSmall>
                          <FormattedTime value={new Date(e.make_at)} />
                        </HeadingXSmall>
                        {e && e.is_sender && (
                          <DeliveredStatusIcon type={e.status} />
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        float: "right",
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      {e &&
                        e.content &&
                        e.content.buttons &&
                        e.content.buttons[0] &&
                        e.content.buttons[0].type == "CALL_TO_ACTION" &&
                        e.content.buttons &&
                        e.content.buttons?.map((e) => (
                          <>
                            {e.type == "URL" && (
                              <div className="timeline_whatsapp_url">
                                <Weblink />
                                <LabelMedium
                                  style={{
                                    fontWeight: "400",
                                    color: "#516BEB",
                                  }}
                                >
                                  {e.text}
                                </LabelMedium>
                              </div>
                            )}
                            {e.type == "PHONE_NUMBER" && (
                              <div className="timeline_whatsapp_url">
                                <Call />
                                <LabelMedium
                                  style={{
                                    fontWeight: "400",
                                    color: "#516BEB",
                                  }}
                                >
                                  {e.text}
                                </LabelMedium>
                              </div>
                            )}
                          </>
                        ))}
                      {e &&
                        e.content &&
                        e.content.buttons &&
                        e.content.buttons[0] &&
                        e.content.buttons[0].type == "QUICK_REPLY" &&
                        e.content.buttons &&
                        e.content.buttons?.map((e) => (
                          <div className="timeline_whatsapp_quick_text">
                            <LabelMedium
                              style={{ fontWeight: "400", color: "#516BEB" }}
                            >
                              {e.text}
                            </LabelMedium>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          {/* Footer */}
          {data &&
            data.data &&
            data.data.docs &&
            !data.data.docs.is_conversation_expired && (
              <div className="whatsapp_footer-part">
                <div className="whatsapp_emoji_wraper --timeline__footer">
                  <div>
                    <IconButton
                      kind={KIND.tertiary}
                      size="mini"
                      onClick={() => setShowPicker((val) => !val)}
                    >
                      <Smiley />
                    </IconButton>
                  </div>
                  <div style={{ flex: "1" }}>
                    <WhatsAppInput
                      value={inputStr}
                      onChange={(e) => setInputStr(e.target.value)}
                      onKeyPress={(res) => {
                        if (!res.nativeEvent.shiftKey && res.key == "Enter") {
                          onSubmit(inputStr);
                        }
                      }}
                    />
                  </div>

                  <div>
                    <IconButton
                      kind={KIND.tertiary}
                      size="mini"
                      onClick={() => {
                        onSubmit(inputStr);
                      }}
                    >
                      <Send />
                    </IconButton>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      {showPicker && (
        <Picker
          pickerStyle={{
            width: "100%",
            height: " 220px",
            transform: " translate(0px, 435px)",
          }}
          onEmojiClick={onEmojiClick}
        />
      )}
    </div>
  );
};

export default WhatsAppChatV2;
