export default function SMS({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M14.25 3H4.25C3.5625 3 3.00625 3.5625 3.00625 4.25L3 15.5L5.5 13H14.25C14.9375 13 15.5 12.4375 15.5 11.75V4.25C15.5 3.5625 14.9375 3 14.25 3ZM6.125 7.375H12.375C12.7188 7.375 13 7.65625 13 8C13 8.34375 12.7188 8.625 12.375 8.625H6.125C5.78125 8.625 5.5 8.34375 5.5 8C5.5 7.65625 5.78125 7.375 6.125 7.375ZM9.875 10.5H6.125C5.78125 10.5 5.5 10.2188 5.5 9.875C5.5 9.53125 5.78125 9.25 6.125 9.25H9.875C10.2188 9.25 10.5 9.53125 10.5 9.875C10.5 10.2188 10.2188 10.5 9.875 10.5ZM12.375 6.75H6.125C5.78125 6.75 5.5 6.46875 5.5 6.125C5.5 5.78125 5.78125 5.5 6.125 5.5H12.375C12.7188 5.5 13 5.78125 13 6.125C13 6.46875 12.7188 6.75 12.375 6.75Z"
        fill={color}
      />
    </svg>
  );
}
