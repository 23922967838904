// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const APP_CODE = process.env.REACT_APP_APP_CODE;
const HOST = process.env.REACT_APP_HOST;

export const addCreditNote = async ({ organization = null, data }) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/credit-notes/add`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const addCreditNoteCustomerOption = async ({
  organization = null,
  ...filters
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/customer`, {
    headers: {
      appCode: APP_CODE,
    },
    params: {
      page: 1,
      page_size: 10,
      ...filters,
    },
  });
};

export const linkedInvoiceModalData = async ({
  organization = null,
  // params,
  filters,
  paymentId,
  sortBy,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoices/payment-credit-note`,
    {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        sort: "-invoice_due_date",
        paymentId,
        ...filters,
      },
    }
  );
};

export const updateCreditNote = async ({
  creditId,
  organization = null,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/credit-notes/${creditId}`,
    data,
    // { ...data },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getCreditNote = async ({ organization = null }) => {
  return await (
    await axiosWithAuth.get(
      `${process.env.REACT_APP_HOST}${organization}/credit-notes`
    )
  ).data.docs;
};

export const creditNoteDatas = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
  customerFilter = {},
}) => {
  return await axiosWithAuth.post(
    HOST + organization + "/credit-notes",
    customerFilter,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },
    }
  );
};

// Used in Credit Linked Invoices Modal

export const updateLinkedCreditNoteModal = async ({ creditId, ...data }) => {
  return await axiosWithAuth.put(
    `${process.env.REACT_APP_HOST}credit-notes/${creditId}`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getLinkedCreditNoteModal = async ({
  creditId,
  organization = null,
  ...data
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/credit-notes/${creditId}/invoices`,
    data,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
