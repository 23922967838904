// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;
const HOSTSPACE = process.env.REACT_APP_HOST;

export const createView = async ({
  clientCode,
  organization = null,
  ...templateData
}) => {
  return await axiosWithAuth.post(`${HOST}${organization}/view`, templateData, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getUserPersonalization = async () => {
  return await axiosWithAuth.get(`${HOST}user-personlization`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getView = async ({ organization = null, viewId = null }) => {
  return await axiosWithAuth.get(`${HOST}${organization}/view/${viewId}`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

// export const copyCommunicationTemplate = async ({
//   clientCode,
//   templateId,
//   ...templateData
// }) => {
//   return await axios.post(`${HOST}templates/copy/${templateId}`, templateData, {
//     headers: {
//       appCode: APP_CODE,
//     },
//     params: {
//       clientCode,
//     },
//   });
// };

// export const getCommunicationTemplateList = async ({
//   clientCode = [],
//   page = 1,
//   page_size = 10,
//   sort = "-_id",
//   filters,
//   type,
// }) => {
//   return await axios.get(`${HOST}templates`, {
//     headers: {
//       appCode: APP_CODE,
//     },
//     params: {
//       clientCode,
//       page,
//       page_size,
//       sort,
//       ...filters,
//     },
//   });
// };

// export const getCommunicationTemplate = async ({ templateId, clientCode }) => {
//   return await axios.get(`${HOST}templates/${templateId}`, {
//     headers: {
//       appCode: APP_CODE,
//     },
//     params: {
//       clientCode,
//     },
//   });
// };

// export const updateCommunicationTemplate = async ({
//   templateId,
//   clientCode,
//   data,
// }) => {
//   return await axios.put(`${HOST}templates/${templateId}`, data, {
//     headers: {
//       appCode: APP_CODE,
//     },
//     params: {
//       clientCode,
//     },
//   });
// };

// export const deleteCommunicationTemplate = async (templateId) => {
//   return await axios.delete(`${HOST}templates`, {
//     headers: {
//       appCode: APP_CODE,
//     },
//   });
// };

// export const getTemplateFormatters = async ({ clientCode }) => {
//   return await axios.get(`${HOST}templates/formatters`, {
//     headers: {
//       appCode: APP_CODE,
//     },
//     params: {
//       clientCode,
//     },
//   });
// };

// export const getTemplateVariables = async ({ clientCode }) => {
//   //Need to change route to fetch template variables
//   return await axios.get(`${HOST}templates/formatters`, {
//     headers: {
//       appCode: APP_CODE,
//     },
//     params: {
//       clientCode,
//     },
//   });
// };
export const collection_dashboard = async ({
  organization = null,
  orgId = null,
  entity = null,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/debt-collection-dashboard/${entity}`,
    {
      params: {
        ...filters,
      },
    }
  );
};