export const LETTER_STATUS_DRAWER = "LETTER_STATUS_DRAWER";
export const LETTER_STATUS_DOCUMENT_FROM_DESKTOP =
  "LETTER_STATUS_DOCUMENT_FROM_DESKTOP";
export const LETTER_STATUS_UPDATE_DOCUMENT_FROM_DESKTOP =
  "LETTER_STATUS_UPDATE_DOCUMENT_FROM_DESKTOP";
export const LETTER_STATUS_UNSELECTED_DOCUMENT =
  "LETTER_STATUS_UNSELECTED_DOCUMENT";
export const LETTER_AUDIT_DRAWER_STATUS = "LETTER_AUDIT_DRAWER_STATUS";
export const LETTER_ACTION_EDIT = "LETTER_ACTION_EDIT";
export const ADD_LETTER_STATUS_ATTACHMENT_FROM_DESKTOP =
  "ADD_LETTER_STATUS_ATTACHMENT_FROM_DESKTOP";
export const REFETCH_LETTER_STATUS = "REFETCH_LETTER_STATUS";
export const LETTER_STATUS_DATA_QUICK_ACTION =
  "LETTER_STATUS_DATA_QUICK_ACTION";

export const refetchLetterStatus = () => {
  return {
    type: REFETCH_LETTER_STATUS,
  };
};

export const addLetterStatusEmailAttachment = (data) => {
  return {
    type: ADD_LETTER_STATUS_ATTACHMENT_FROM_DESKTOP,
    payload: data,
  };
};

export const letterActionEditForm = (data) => {
  return {
    type: LETTER_ACTION_EDIT,
    payload: data,
  };
};

export const letterAuditDrawer = (data) => {
  return {
    type: LETTER_AUDIT_DRAWER_STATUS,
    payload: data,
  };
};

export const letterStatusDrawer = (data) => {
  return {
    type: LETTER_STATUS_DRAWER,
    payload: data,
  };
};

export const addingLetterStatusDeskDocument = (data) => {
  return {
    type: LETTER_STATUS_DOCUMENT_FROM_DESKTOP,
    payload: data,
  };
};

export const updatingLetterStatusDeskDocument = (data) => {
  return {
    type: LETTER_STATUS_UPDATE_DOCUMENT_FROM_DESKTOP,
    payload: data,
  };
};

export const unSelectingLetterStatusDeskDocument = (data) => {
  return {
    type: LETTER_STATUS_UNSELECTED_DOCUMENT,
    payload: data,
  };
};

export const documentLetterSetAction = (data) => {
  return {
    type: LETTER_STATUS_DATA_QUICK_ACTION,
    payload: data,
  };
};
