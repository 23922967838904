import * as React from "react";
import { Drawer, SIZE, ANCHOR } from "baseui/drawer";
import {
  LabelLarge,
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { TextBox, SIZE as size } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import {
  KIND,
  TextButton,
  SIZE as btn_size,
} from "../../../../components/TextButton";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import {
  getCustomerContactDetail,
  getReviewPromiseDatas,
} from "../../../../services";
import { useContext } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useSearchParams } from "react-router-dom";
import Alert from "../../../../assets/img/svg/Alert";
import PtpKept from "../../../../assets/img/svg/PtpKept";
import PpKept from "../../../../assets/img/svg/PpKept";
import PpBroken from "../../../../assets/img/svg/PpBroken";
import PpProgress from "../../../../assets/img/svg/PpProgress";

const PaymentPlanDrawer = (props) => {
  let intl = useIntl();
  let {
    setIsOpen,
    isOpen,
    isResidualAmount,
    planned_payment_amount,
    setIsResidualAmount,
    installments,
    setInstallments,
    planDrawer,
    value,
    setIsId,
    PaymentID,
  } = props;
  const { control, errors, watch, settingData, setValue, handleSubmit } =
    useForm();
  const { schedule_payment, schedule_paymenting } = watch();
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const getParams = getSearchParams.get("customers");
  const [isTotalAmount, setIsTotalAmount] = useState(0);
  // Loop function End
  useEffect(() => {
    if (installments) {
      installments.map((element, i) => {
        setValue(
          `schedule_payment.[${i}].planned_date`,
          new Date(element && element.promise_date)
        );
        setValue(
          `schedule_payment.[${i}].planned_amount`,
          Number(element?.promise_amount?.value).toFixed(2)
        );
      });
    }
  }, [installments]);
  let schedule_payments = [{ payment_date: "" }];

  useEffect(() => {
    if (schedule_payment?.length > 0) {
      let total = schedule_payment?.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue?.planned_amount);
      }, 0);
      setIsTotalAmount(total.toFixed(2));
    }
  }, [schedule_payment?.map((e) => e?.planned_amount)]);

  const onSubmits = (data) => {
    let upgrade = [];
    data.schedule_payment.forEach((item) => {
      let { planned_amount, planned_date } = item;
      let promise_date = new Date(planned_date).valueOf();

      let promise_amount = {
        currency: installments?.[0]?.promise_amount?.currency,
        value: Number(planned_amount),
      };
      upgrade.push({ promise_date, promise_amount });
    });

    if (isTotalAmount == planned_payment_amount) {
      setIsOpen(false);
      setInstallments(upgrade);
    }
  };
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );
  const PaymentPlanListItem =
    customerDetails &&
    customerDetails?.promise_to_pay?.filter((elem) => {
      if (elem?.payment_plan_id !== undefined) {
        return (
          elem?.payment_plan_id ===
          customerDetails?.payment_plan?.payment_plan_id
        );
      }
    });

  const { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);
  // if (PaymentID !== undefined) {
  const { data } = useQuery(
    `timeLine_Payment_Plan-${PaymentID}`,

    async () => {
      return await getReviewPromiseDatas({
        organization: currentOrganization,
        promiseId: getParams,
        payment_plan_id: PaymentID,
        entity: "promise-to-pay",
      });
    },
    { refetchOnWindowFocus: true, refetchOnMount: true }
  );

  useEffect(() => {
    data &&
      data?.data &&
      data?.data?.doc.map((element, i) => {
        setValue(
          `schedule_paymenting.[${i}].date`,
          new Date(element && element.promise_date)
        );
        setValue(
          `schedule_paymenting.[${i}].planned`,
          Number(
            element && element?.promise_amount && element?.promise_amount?.value
          ).toFixed(2)
        );
      });
  }, [data]);

  const Status = ({ status }) => {
    switch (status) {
      case "IN_PROGRESS":
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div style={{ marginTop: "5px" }}>
              <PpProgress />
            </div>
            <ParagraphSmall $style={{ color: "#333860" }}>
              In-Progress
            </ParagraphSmall>
          </div>
        );
      case "KEPT":
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <PpKept />
            <ParagraphSmall $style={{ color: "#333860" }}>Kept</ParagraphSmall>
          </div>
        );
      case "CANCELED":
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <PpBroken color="#FBBD04" />
            <ParagraphSmall $style={{ color: "#333860" }}>
              Cancelled
            </ParagraphSmall>
          </div>
        );
      case "PARTIAL":
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div style={{ marginTop: "5px" }}>
              <Alert width={14} height={17} color={"#FBBD04"} />
            </div>
            <ParagraphSmall $style={{ color: "#333860" }}>
              Partial
            </ParagraphSmall>
          </div>
        );
      case "BROKEN":
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <PpBroken />
            <ParagraphSmall $style={{ color: "#333860" }}>
              Broken
            </ParagraphSmall>
          </div>
        );
    }
  };
  const HandlerClose = () => {
    setIsOpen(false);
  };
  let dataValidates =
    schedule_payment &&
    schedule_payment.map((item, index) => {
      let currTime = moment(item?.planned_date).utc().valueOf() / 1000;
      let nextTime =
        moment(schedule_payment[index + 1]?.planned_date)
          .utc()
          .valueOf() / 1000;

      if (
        Math.floor(nextTime) < Math.floor(currTime) &&
        schedule_payment[index + 1]?.planned_date
      ) {
        return "less(or)greater";
      } else if (
        Math.floor(nextTime) == Math.floor(currTime) &&
        schedule_payment[index + 1]?.planned_date
      ) {
        return "equal";
      } else {
        return "true";
      }
    });

  let Amount =
    schedule_payment &&
    schedule_payment.some((item, i) => {
      if (item.planned_amount == "") {
        return true;
      }
    });

  return (
    <Drawer
      isOpen={isOpen}
      autoFocus
      onClose={HandlerClose}
      size={SIZE.auto}
      anchor={ANCHOR.right}
      overrides={{
        DrawerContainer: {
          style: ({ $theme }) => ({
            width: planDrawer == "TimeLine" ? "39%" : "35%",
          }),
        },
        DrawerBody: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            width: "100%",
            marginLeft: "10px",
            marginTop: "10px",
            marginBottom: "0px",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "row",
          }),
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div className="payment_plan_header">
          <LabelLarge>Schedule of Payment Plan</LabelLarge>
        </div>
        {planDrawer == "TimeLine" ? (
          <>
            <div style={{ height: "90%", overflowY: "scroll", padding: "3%" }}>
              {data &&
                data?.data &&
                data?.data?.doc
                  ?.sort((a, b) => a.promise_date - b.promise_date)
                  .map((ele, i) => {
                    return (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "11px",
                            width: "100%",
                            height: "8%",
                          }}
                        >
                          <div
                            className="--drawer_payment_amount_changes"
                            style={{ marginTop: "16px" }}
                          >
                            <Controller
                              name={`schedule_paymenting.[${i}].planned`}
                              control={control}
                              // rules={{ required: "Required" }}
                              render={({ field }) => (
                                <TextBox
                                  {...field}
                                  hidden={true}
                                  size={size.mini}
                                  type="number"
                                  name={field.name}
                                  label={`${intl.formatMessage({
                                    id: `installment_amount`,
                                  })} ${i + 1}`}
                                  disabled={true}
                                  value={field.value}
                                />
                              )}
                            />
                          </div>
                          <div style={{ marginTop: "16px" }}>
                            <Controller
                              name={`schedule_paymenting.[${i}].date`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <DateRangeSelect
                                    {...field}
                                    name={field.name}
                                    size={size.mini}
                                    label={intl.formatMessage({
                                      id: "installment_date",
                                    })}
                                    disabled={true}
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.date)}
                                    minDate={new Date()}
                                    backspaceRemoves={false}
                                  />
                                </>
                              )}
                            />
                          </div>
                          <div style={{ width: "27%" }}>
                            <Status status={ele?.status} key={i} />
                          </div>
                        </div>
                      </>
                    );
                  })}
            </div>
          </>
        ) : (
          <form
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
            // onSubmit={handleSubmit(onSubmits)}
          >
            <div style={{ height: "90%", overflowY: "scroll", padding: "3%" }}>
              {installments.map((ele, i) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "11px",
                        width: "100%",
                        height: "8%",
                      }}
                    >
                      {/* <div style={{ width: "22%" }}>
                        <LabelMedium $style={{ color: "#ADADAD" }}>
                          Installment {i + 1}:
                        </LabelMedium>
                      </div> */}
                      <div
                        className="--drawer_payment_amount_changes"
                        style={{ marginTop: "16px" }}
                      >
                        <Controller
                          name={`schedule_payment.[${i}].planned_amount`}
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <TextBox
                              {...field}
                              hidden={true}
                              size={size.mini}
                              type="number"
                              name={field.name}
                              requiredAstric={true}
                              label={intl.formatMessage(
                                {
                                  id: i + 1,
                                  defaultMessage: "Installment Amount {name}",
                                },
                                {
                                  name: i + 1,
                                }
                              )}
                              placeholder={intl.formatMessage({
                                id: "planned_installment_amount",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div style={{ marginTop: "16px" }}>
                        <Controller
                          name={`schedule_payment.[${i}].planned_date`}
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <>
                              <DateRangeSelect
                                {...field}
                                name={field.name}
                                requiredAstric={true}
                                size={size.mini}
                                label={intl.formatMessage({
                                  id: "installment_date",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "installment_date",
                                })}
                                value={field.value}
                                onChange={(e) => field.onChange(e.date)}
                                minDate={new Date()}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {!(isTotalAmount == planned_payment_amount) ? (
                  <div>
                    <ParagraphMedium $style={{ color: "red" }}>
                      Sum of Installment Amount does not match with Payment
                      Amount
                    </ParagraphMedium>
                  </div>
                ) : dataValidates && dataValidates?.includes("equal") ? (
                  <div>
                    <ParagraphMedium $style={{ color: "red" }}>
                      2 Installments cannot be the same installment date
                    </ParagraphMedium>
                  </div>
                ) : dataValidates &&
                  dataValidates?.includes("less(or)greater") ? (
                  <div>
                    <ParagraphMedium $style={{ color: "red" }}>
                      Installment date should be greater than next installment
                      date
                    </ParagraphMedium>
                  </div>
                ) : Amount ? (
                  <div>
                    <ParagraphMedium $style={{ color: "red" }}>
                      Invalid Installment Amount
                    </ParagraphMedium>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={btn_size.compact}
                      fullWidth
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </TextButton>
                  </div>{" "}
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={btn_size.compact}
                      fullWidth
                      type="button"
                      onClick={handleSubmit(onSubmits)}
                      disabled={
                        !(isTotalAmount == planned_payment_amount) ||
                        (schedule_payment &&
                          schedule_payment?.some(
                            (ele) => !ele?.planned_date
                          )) ||
                        !dataValidates?.every((ele) => ele == "true") ||
                        Amount
                      }
                    >
                      Save
                    </TextButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </Drawer>
  );
};

export default PaymentPlanDrawer;
