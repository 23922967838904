export const DOCUMENT_DRIVE_QUICK_ACTION_INVOICE =
  "DOCUMENT_DRIVE_QUICK_ACTION_INVOICE";
export const ADD_EMAIL_ATTACHMENT_QUICK_ACTION_INVOICE_FROM_DESKTOP =
  "ADD_EMAIL_ATTACHMENT_QUICK_ACTION_INVOICE_FROM_DESKTOP";
export const UPDATE_EMAIL_QUICK_ACTION_INVOICE_ATTACHMENT_FROM_DESKTOP =
  "UPDATE_EMAIL_QUICK_ACTION_INVOICE_ATTACHMENT_FROM_DESKTOP";
export const REMOVE_EMAIL_ATTACHMENT_INVOICE_QUICK_ACTION =
  "REMOVE_EMAIL_ATTACHMENT_INVOICE_QUICK_ACTION";
export const ADD_INVOICE_DOCUMENT_FORM_QUICK_ACTION =
  "ADD_INVOICE_DOCUMENT_FORM_QUICK_ACTION";
export const DOCUMENT_DRIVE_DATA_INVOICE_FROM_QUICK_ACTION =
  "DOCUMENT_DRIVE_DATA_INVOICE_FROM_QUICK_ACTION";
export const INVOICE_DRIVE_OPEN_QUICK_QCTION =
  "INVOICE_DRIVE_OPEN_QUICK_QCTION";
export const INVOICE_QUICK_ACTION_INITIAL = "INVOICE_QUICK_ACTION_INITIAL";

export const removeAttachmentQuickAction = (payload) => {
  return {
    type: REMOVE_EMAIL_ATTACHMENT_INVOICE_QUICK_ACTION,
    payload,
  };
};

export const updateQuickActionEmailInvoiceAttachmentFromDesktop = (payload) => {
  return {
    type: UPDATE_EMAIL_QUICK_ACTION_INVOICE_ATTACHMENT_FROM_DESKTOP,
    payload,
  };
};

export const documentDriveQuickActionInv = (data) => {
  return {
    type: DOCUMENT_DRIVE_QUICK_ACTION_INVOICE,
    payload: data,
  };
};

export const addEmailAttachmentQuickActionFromDesktop = (payload) => {
  return {
    type: ADD_EMAIL_ATTACHMENT_QUICK_ACTION_INVOICE_FROM_DESKTOP,
    payload,
  };
};

export const invDriveOpenQA = (data) => {
  return {
    type: INVOICE_DRIVE_OPEN_QUICK_QCTION,
    payload: data,
  };
};

export const initialQAReducer = () => {
  return {
    type: INVOICE_QUICK_ACTION_INITIAL,
  };
};
