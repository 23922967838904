import React, { useContext } from "react";
import _ from "lodash";
import {
  MaxyfiProvider,
  MaxyfiContext,
} from "../../../providers/MaxyfiProvider";
import moment from "moment";

function DateStatus(props) {
  let { value, column, alignment, accessor, row, ...rest } = props;

  value = _.get(row.original, accessor, null);
  const {
    referenceData,
    currentOrganization,
    dateFormat,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let date = value
    ? moment
        ?.utc(value)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format)
    : "-";

  let dayDiff = moment
    ?.utc(value)
    ?.tz(timeZone)
    ?.diff(moment.tz(timeZone), "days", true);
  dayDiff = Math.ceil(dayDiff);

  return (
    <div
      style={{
        color: `${
          dayDiff == 0 ? "#B79C15" : dayDiff > 0 ? "#757575" : "#FD372A"
        }`,
      }}
    >
      {date}
    </div>
  );
}

export default DateStatus;
