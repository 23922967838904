import React, { useContext } from "react";
import { TextButton, SIZE, KIND } from "../TextButton";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { LabelLarge, ParagraphLarge, ParagraphSmall } from "baseui/typography";
import getSelectValues from "../../utils/getSelectValues";
import { SelectBox } from "../SelectBox";
import { MaxyfiProvider } from "../../providers/MaxyfiProvider";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  assignMulCusRel,
  assignMultiCheckIn,
  getCheckLocation,
} from "../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../TextArea";
import { TimePickerAction } from "../TimePicker/TimePicker";
import { getInvoiceList } from "../../services/invoiceItem";
import moment from "moment";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { useSelector } from "react-redux";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";

const CheckIn = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  isAllRowsSelected,
  queryFilter,
}) => {
  let {
    currentOrganization,
    users,
    referenceData,
    restrictions,
    space,
    appAccessRest,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const { filters, globalFilters } = useSelector(
    (s) => s.customerSummary.tableCurrentState
  );
  const { tableCurrentState, gridStructure } = useSelector(
    (state) => state.customerSummary
  );
  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };
  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];
  let rm = users.filter((i) => i.active);

  let findingUser = rm?.filter((i) =>
    i.organization?.includes(currentOrganization)
  );

  const assignMultiCheckInCustomer = useMutation(
    (relMang) =>
      assignMultiCheckIn({
        ...relMang,

        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
          toggleAllRowsSelected(false);
        }
        handleModal();
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        handleModal();
      },
    }
  );

  const locationData = useQuery(
    [`CHECK_IN_LOCATION ${currentOrganization}`],
    async ({ queryKey }) => {
      return await getCheckLocation({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `INVOICE_ITEM${currentOrganization}`,
      {
        sortBy: "name",
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      return await getInvoiceList({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  let locationDatas =
    locationData &&
    locationData.data &&
    locationData.data.data &&
    locationData.data.data.docs;

  const filterData =
    data &&
    data.data &&
    data.data.docs?.filter(({ pricing_type }) => pricing_type == "USAGE_BASED");

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_customer: false,
    },
  });
  const onSubmit = async (data) => {
    let newFilter = dynamicQueryFilter({
      gridStructure: gridStructure,
      // customerQueryFilter: queryFilter && queryFilter.display_name,
      timeZone: currentDefaultFormatDetails.time_zone,
      customerFilter: filters,
    });

    let { recurring_invoice_item_id, remarks } = data;
    // SPLITING HRS AND MIN STARTS
    let gettingHour = moment(data.checkin_time).get("hour");
    let gettingMinutes = moment(data.checkin_time).get("minutes");
    let settingDate = moment(data.checkin_date)
      .set({ hour: gettingHour, minutes: gettingMinutes })
      .toDate();
    // SPLITING HRS AND MIN ENDS
    delete data.checkin_date;
    delete data.checkin_time;
    //

    let location = getSelectValues(data.location);

    let values = {
      recurring_invoice_item_id: getSelectValues(recurring_invoice_item_id),
      ...(location ? { location } : {}),
      checkin_at: moment(settingDate).utc().valueOf(),
      ...(remarks ? { remarks: remarks } : {}),
      ...(data.is_all_customer
        ? {
            query: newFilter ? newFilter : {},
            filters: {
              ...globalFilters,
              ...appAccessRest,
              needs_actions: [...NeedsActionFilter],
              stage: [...FilteredCustomerStage],
              search_value: queryFilter && queryFilter.display_name,
            },
          }
        : { customerId: selectedCustomer }),
      is_all_customer: data.is_all_customer,
    };

    await assignMultiCheckInCustomer.mutateAsync(values);
  };
  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);
  const intl = useIntl();

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ParagraphLarge style={{ display: "flex", paddingBottom: "5px" }}>
          Check In
        </ParagraphLarge>
        <div style={{ width: "100%" }}>
          <Controller
            control={control}
            name="recurring_invoice_item_id"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "item",
                })}
                placeholder={intl.formatMessage({
                  id: "item",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={filterData
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map(({ name, _id }) => {
                    return { label: name, id: _id };
                  })}
              />
            )}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "45%" }}>
            <Controller
              name="check_in_date"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <DateRangeSelect
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  // disabled={isDisabled}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "checkin_date",
                  })}
                  placeholder={intl.formatMessage({
                    id: " ",
                  })}
                  value={field.value}
                  size={SIZE.mini}
                  onChange={(e) => field.onChange(e.date)}
                  // minDate={new Date()}
                  range
                />
              )}
            />
          </div>
          <div style={{ width: "45%" }}>
            <Controller
              control={control}
              name="checkin_time"
              render={({ field }) => (
                <TimePickerAction
                  {...field}
                  name={field.name}
                  requiredAstric={true}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "checkin_time",
                  })}
                  placeholder={intl.formatMessage({
                    id: "checkin_time",
                  })}
                  size={SIZE.mini}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <Controller
            control={control}
            name="location"
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                creatable={creatablePermission ? true : false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "location",
                })}
                placeholder={intl.formatMessage({
                  id: "location",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={
                  locationData &&
                  locationData.data &&
                  locationData.data.data &&
                  locationData.data.data.docs
                }
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="remarks"
            render={({ field }) => (
              <TextArea
                {...field}
                name={field.name}
                label={intl.formatMessage({
                  id: "remarks",
                })}
                placeholder={intl.formatMessage({
                  id: "remarks",
                })}
                size={SIZE.mini}
                value={field.value}
              />
            )}
          />
        </div>
        {isAllRowsSelected && (
          <Controller
            name="is_all_customer"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb {...field} checked={field.value}>
                <ParagraphSmall style={{ color: "#333860" }}>
                  Update all the matching consumer records
                </ParagraphSmall>
              </CheckBoxBaseweb>
            )}
          />
        )}
        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting}
              //   disabled={isSubmitting || smsConfigBtn}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default CheckIn;
