import { ParagraphMedium } from "baseui/typography";
import React, { useContext, useState } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";
// import { SelectBox } from "../../SelectBox";
import { useMutation } from "react-query";
import { patchPaymentTableSelect } from "../../../services";
import getSelectValues from "../../../utils/getSelectValues";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Select } from "baseui/select";

import { refetchPaymentActions } from "../../../redux/customerOverview/payment/action";

const PaymentStatus = (props) => {
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);
  let dispatch = useDispatch();

  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  value = _.get(original, accessor, "");
  //

  const findPaymentStatus = referenceData?.payment_status?.find(
    ({ id }) => id === value
  );
  const [selectValue, setSelectValue] = useState([
    { id: "initiated", label: "Initiated" },
  ]);

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("customers");

  // const PaymentstatusChanging = useMutation((data) =>
  //   patchPaymentTableSelect({
  //     data,
  //     organization: currentOrganization,
  //     customerId: getParams,
  //     // paymentId,
  //   })
  // );

  const PaymentstatusChanging = useMutation(
    (data) =>
      patchPaymentTableSelect({
        data: data.value,
        organization: currentOrganization,
        customerId: getParams,
        paymentId: data.paymentId,
        syncStatus: "payment_status",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // reset();
        dispatch(refetchPaymentActions());
        // dispatch(reviewTablePopUp(false));
        // dispatch(refetchActions());
      },
    }
  );
  const onChanging = async (props) => {
    await PaymentstatusChanging.mutateAsync({
      paymentId: props.paymentId,
      value: props.value,
    });
  };

  return (
    <div style={{ textAlign: { alignment }, width: "100%" }}>
      {findPaymentStatus && findPaymentStatus?.id === "initiated" ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "80px" }}>
            <Select
              overrides={{
                ValueContainer: {
                  style: ({ $theme }) => ({
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }),
                },
                IconsContainer: {
                  style: () => ({
                    paddingRight: "5px",
                  }),
                },

                ControlContainer: {
                  style: ({ $theme, $isFocused, $error }) => ({
                    backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                    borderWidth: "0px",
                    borderStyle: "solid",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                  }),
                },
              }}
              value={selectValue}
              clearable={false}
              fullWidth
              disabled={
                (original && original.source == "INSTANT") ||
                original.source == "portal"
                  ? true
                  : false
              }
              options={referenceData?.payment_status}
              onChange={(e) => {
                let values = getSelectValues([e.option]);
                let paymentId = original._id;
                setSelectValue([e.option]);
                onChanging({ value: values, paymentId: paymentId });
              }}
            />
          </div>
        </div>
      ) : (
        <ParagraphMedium
          style={{
            color: `${
              findPaymentStatus && findPaymentStatus?.id === "success"
                ? "#0FB158"
                : findPaymentStatus && findPaymentStatus?.id === "failed"
                ? "#FD372A"
                : ""
            }`,
          }}
        >
          {findPaymentStatus && findPaymentStatus?.label
            ? findPaymentStatus?.label
            : " "}
        </ParagraphMedium>
      )}
    </div>
  );
};

export default PaymentStatus;
