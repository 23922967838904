import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SelectBox, SIZE } from "../../components/SelectBox";
import { useIntl } from "react-intl";
import { FileUpload } from "../../components/FileUpload/FileUpload";
import { TextArea } from "../../components/TextArea";
import { KIND, TextButton } from "../../components/TextButton";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_DOCUMENTS,
  ADD_LIST_VALUES,
} from "../../providers/RBACProvider/permissionList";
import { invoiceDrawerClose } from "../../redux/invoiceOverview/DMSystemINV/action";
import {
  documentDrawerClose,
  initialState,
  typeToInitial,
} from "../../redux/customerOverview/DMSystem/action";
import getSelectValues from "../../utils/getSelectValues";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import DocFileType from "../../assets/img/svg/DocFileType";
import { DOC_QUICK_VIEW_LIST, EDIT, NEW } from "../../constants";
import setSelectValues from "../../utils/setSelectValues";
import { getSubCategory, updateDocument } from "../../services/documentSystem";
import { customerInvoiceOverview } from "../../services";
import { invoiceTypeForm } from "../../redux/invoiceOverview/DMSystemINV/action";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import ActionReminders from "../../components_v2/ActionReminders";
import _ from "lodash";
import queryClient from "../../providers/queryClient";
const AddDMSystem = ({ createDocument, resourceId, refetch, entity }) => {
  let intl = useIntl();
  let dispatch = useDispatch();

  let { drawerType, documentdetails } = useSelector(
    (e) => e.customerDocumentReducer
  );
  let { drawerInvoiceType, documentInvoicedetails } = useSelector(
    (s) => s.invoiceDocumentReducer
  );

  let docDetails =
    entity === "invoices" ? documentInvoicedetails : documentdetails;

  let type = entity === "invoices" ? drawerInvoiceType : drawerType;
  const {
    currentOrganization,
    referenceData,
    space,
    defaultData,
    appAccessRest,
  } = useContext(MaxyfiContext);

  const [files, setFiles] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      remarks: "",
    },
  });
  const [checked, setChecked] = React.useState(false);
  let categoryField = watch(["category"]);
  useEffect(() => {
    if (type === EDIT && docDetails) {
      setValue("category", setSelectValues(docDetails.category));
      setValue("invoice_number", setSelectValues(docDetails.invoice_number));
      setValue("sub_category", setSelectValues(docDetails.sub_category));
      setValue("comment", _.get(docDetails, "remarks", ""));
      setValue("is_share_portal", docDetails.is_share_portal);
      setChecked(docDetails.is_share_portal);
    }
  }, [docDetails]);

  const subCategory = useQuery(
    [`sub-category-${resourceId}`],
    async () => {
      return await getSubCategory({
        organization: currentOrganization,
        // customerId: resourceId,
      });
    },
    {
      refetchOnMount: true,
      // refetchOnWindowFocus: true,
    }
  );
  let subCategoryData =
    subCategory.data && subCategory.data.data && subCategory.data.data.docs;

  const { data, isFetched, isError } = useQuery(
    [
      `CUSTOMER_INVOICE_OVERVIEW-${resourceId}-${entity}`,
      {
        pageIndex: 1,
        pageSize: 0,
        filters: [],
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      filters = {
        ...filters,
      };

      return await customerInvoiceOverview({
        pageIndex,
        sortBy,
        pageSize,
        filters,
        organization: currentOrganization,
        customer_id: resourceId,
        // customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  let invoiceData =
    data &&
    data.data &&
    data.data.docs.map((e) => {
      return {
        label: e.invoice_number,
        id: e.invoice_number,
      };
    });

  const createDocumentMutation = useMutation(
    (data) =>
      createDocument({
        data,
        organization: currentOrganization,
        customerId: resourceId,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        refetch();
        reset();
        setFiles([]);
        queryClient.invalidateQueries(`${DOC_QUICK_VIEW_LIST}-${resourceId}`);
        // dispatch(typeToInitial());
        if (entity === "invoices") {
          dispatch(invoiceTypeForm());
        } else {
          dispatch(typeToInitial());
        }
        subCategory.refetch();
      },
    }
  );

  const updateDocumentMutation = useMutation(
    (data) =>
      updateDocument({
        data,
        organization: currentOrganization,
        customerId: resourceId,
        documentId: docDetails._id,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        refetch();
        queryClient.invalidateQueries(`${DOC_QUICK_VIEW_LIST}-${resourceId}`);
        dispatch(typeToInitial());
        subCategory.refetch();
      },
    }
  );

  const onSubmit = async (data) => {
    let { invoice_number, category, sub_category, remarks, comment } = data;

    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let formData = new FormData();
    let value;
    if (type === EDIT) {
      if (
        categoryField &&
        categoryField[0] &&
        categoryField[0][0] &&
        categoryField[0][0].id === "INVOICE"
      ) {
        value = {
          category: category[0].id,
          sub_category: sub_category[0].id,
          invoice_number: invoice_number[0].id,
          remarks: comment ? comment : "",
          is_share_portal: checked,
          mention_users: uniqueMentionedUsers,
        };
      } else {
        value = {
          category: category[0].id,
          sub_category: sub_category[0].id,
          remarks: comment ? comment : "",
          is_share_portal: checked,
          mention_users: uniqueMentionedUsers,
        };
      }
    }

    if (
      categoryField &&
      categoryField[0] &&
      categoryField[0][0] &&
      categoryField[0][0].id === "INVOICE"
    ) {
      formData.append("file", files && files[0]);
      formData.append("category", category[0].id);
      formData.append("invoice_number", invoice_number[0].id);
      formData.append("sub_category", sub_category[0].id);
      formData.append("remarks", comment ? comment : "");
      formData.append("is_share_portal", checked);
      uniqueMentionedUsers.forEach((value) => {
        formData.append("mention_users[]", value);
      });
    } else {
      formData.append("file", files && files[0]);
      formData.append("category", category[0].id);
      formData.append("sub_category", sub_category[0].id);
      formData.append("remarks", comment ? comment : "");
      formData.append("is_share_portal", checked);
      uniqueMentionedUsers.forEach((value) => {
        formData.append("mention_users[]", value);
      });
    }

    //

    if (type === EDIT) {
      await updateDocumentMutation.mutateAsync(value);
    } else {
      await createDocumentMutation.mutateAsync(formData);
    }
  };

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <form style={{ marginBottom: "15px" }} onSubmit={handleSubmit(onSubmit)}>
      {type != EDIT && <FileUpload setFiles={setFiles} files={files} />}

      <div className="dm_select_container">
        <div style={{ width: "48%" }}>
          <Controller
            control={control}
            name="category"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "category",
                })}
                placeholder={intl.formatMessage({
                  id: "category",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["document_category"]}
              />
            )}
          />
        </div>
        <div style={{ width: "48%" }}>
          {categoryField &&
            categoryField[0] &&
            categoryField[0][0] &&
            categoryField[0][0].id == "INVOICE" && (
              <Controller
                control={control}
                name="invoice_number"
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "invoice",
                    })}
                    placeholder={intl.formatMessage({
                      id: "invoice",
                    })}
                    size={SIZE.mini}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={invoiceData}
                    // options={[
                    //   { label: "INVOICE", id: "6398254bd254066155ad9de2" },
                    // ]}
                    // options={referenceData["notes_category"].sort((a, b) =>
                    //   a.label.localeCompare(b.label)
                    // )}
                  />
                )}
              />
            )}
        </div>
      </div>
      <div style={{ width: "48%" }}>
        <Controller
          control={control}
          name="sub_category"
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              requiredAstric={true}
              creatable={creatablePermission ? true : false}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "sub_category",
              })}
              placeholder={intl.formatMessage({
                id: "sub_category",
              })}
              size={SIZE.mini}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                subCategoryData &&
                subCategoryData.map((i) => {
                  return {
                    label: i.label,
                    id: i.id,
                  };
                })
              }
              // options={referenceData["notes_category"].sort((a, b) =>
              //   a.label.localeCompare(b.label)
              // )}
            />
          )}
        />
      </div>
      <ActionReminders
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        is_calendar={false}
        is_incrementor={false}
        is_hold={false}
        is_followup={false}
        is_confirmation={false}
        is_valid_from_contact={false}
      />

      <Controller
        control={control}
        name="is_share_portal"
        render={({ field }) => (
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{
              Checkmark: {
                style: ({ $theme, $checked }) => ({
                  border: "0.1px solid #CDCED9",
                  borderRadius: "5px",
                  backgroundColor: $checked ? "#516BEB" : "#FFFFFF",
                }),
                Label: {
                  style: ({ $theme }) => ({
                    paddingLeft: "3px",
                    fontWeight: "normal",
                  }),
                },
              },
            }}
          >
            Share via consumer portal
          </Checkbox>
        )}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // paddingTop: "25px",
        }}
      >
        <div
          style={{
            width: "80px",
            paddingRight: "10px",
          }}
        >
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              // dispatch(initialState());
              // if (entity === "invoices") {
              //   dispatch(invoiceTypeForm());
              // } else {
              //   dispatch(typeToInitial());
              // }
              if (entity === "invoices") {
                dispatch(invoiceDrawerClose());
                // setSelectedData([]);
              } else {
                dispatch(documentDrawerClose(false));
                // setSelectedData([]);
              }
            }}
          >
            Cancel
          </TextButton>
        </div>
        <div style={{ width: "80px" }}>
          <TextButton
            kind={KIND.primary}
            size={SIZE.mini}
            isLoading={isSubmitting}
            disabled={isSubmitting || type === EDIT ? "" : files.length === 0}
            fullWidth
            type="submit"
          >
            Capture
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default AddDMSystem;
