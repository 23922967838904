import { Search } from "baseui/icon";
import { HeadingXLarge, LabelSmall, ParagraphMedium } from "baseui/typography";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextBox, SIZE, inputOverrides } from "../../components/TextBox";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import {
  getCustomerSummaryGridStructure,
  setCustomerSummaryTableView,
  setGlobalFilter,
  setCustomerSummaryFilter,
  setCustomerSummaryPageIndex,
  setNeedsActionFilter,
  setCustomerStageFilter,
} from "../../redux/customerSummary/action";
import AllFilters from "../../components/AllFilters/AllFilters";
import BackArrowCustomer from "../../assets/img/svg/BackArrowCustomer";
import { useSearchParams } from "react-router-dom";
import millify from "millify";
import setTableGridStructure from "../../utils/setTableGridStructure";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import moment from "moment";
import "moment-timezone";
import DisputeSvg from "../../assets/img/svg/CusOverviewActionsIcon/DisputeSvg";
import PromiseToPayN from "../../assets/img/svg/CusOverviewActionsIcon/PromiseToPayN";
import EmailSvgN from "../../assets/img/svg/CusOverviewActionsIcon/EmailSvgN";
import CallSvg from "../../assets/img/svg/CusOverviewActionsIcon/CallSvg";
import ReviewPromiseSvg from "../../assets/img/svg/CusOverviewActionsIcon/ReviewPromiseSvg";
import SmsSvgN from "../../assets/img/svg/CusOverviewActionsIcon/SmsSvgN";
import WhatsAppIcon from "../../assets/img/svg/CusOverviewActionsIcon/WhatsAppIcon";
import { tabMotion } from "../../redux/actions";
import { StatefulPopover } from "baseui/popover";
import { Input } from "baseui/input";
import { CUSTOMER_OVERVIEW } from "../../constants";
import { useInfiniteQuery, useQuery } from "react-query";
import { customerData } from "../../services/customerSummary";
import { useDebouncedCallback } from "use-debounce";

const ActionTypes = ({ type }) => {
  switch (type) {
    case "call":
      return <CallSvg />;

    case "sms":
      return <SmsSvgN />;

    case "dispute":
      return <DisputeSvg />;

    case "email":
      return <EmailSvgN />;

    case "promise":
      return <PromiseToPayN />;

    case "review_promise":
      return <ReviewPromiseSvg />;

    case "review_dispute":
      return <DisputeSvg />;

    case "whatsapp":
      return <WhatsAppIcon />;

    default:
      return null;
  }
};

const CustomerListTable = ({
  tableData,
  setQueryFilter,
  getParams,
  queryFilter,
}) => {
  let intl = useIntl();
  let dispatch = useDispatch();
  const ref = useRef(null);
  const [customerAllData, setCustomerData] = useState([]);
  const [isSearchData, setIsSearchData] = useState(queryFilter);

  const debounceFilter = useDebouncedCallback((value) => {
    setQueryFilter({ display_name: value });
    // dispatch(setGlobalFilter(value));
  }, 1000);

  let {
    viewName,
    tableCurrentState,
    gridAndViewLoaded = false,
  } = useSelector((state) => state.customerSummary);
  const [filterSearch, setFilterSearch] = useState(
    tableCurrentState?.globalFilters?.search_value
  );
  const [isSearchPopover, setIsSearchPopover] = useState(false);

  // FILTERS STARTS

  // Customer Filter
  const filterReduxData = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );

  // Customer Stage Filter
  // const customerStageFilters = useSelector(
  //   (s) => s.customerSummary.tableCurrentState.customer_stage
  // );

  // Needs Action Filter

  const NeedsActionData = useSelector(
    (s) => s.customerSummary.tableCurrentState.needs_action
  );

  // FILTERS ENDS

  // TO FIND LENGTH STARTS
  const CustomFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.formFilters
  );

  let globalFilter = tableCurrentState.globalFilters;

  const NeedsActionFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.needs_action
  );

  // let needsActionFilter = {
  //   need_actions: tableCurrentState.needs_action,
  // };

  // let NeedsActionFilters = Object.keys(tableCurrentState.needs_action).filter(
  //   (i) => needsActionFilter.need_actions[i]
  // );

  const CustomerStageFilter = useSelector(
    (s) => s.customerSummary.tableCurrentState.customer_stage
  );

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  // let FilteredCustomerStage = Object.keys(customerStageFilters.stage).filter(
  //   (i) => customerStageFilters.stage[i]
  // );

  // TO FIND LENGTH ENDS

  // GRID STRUCTURE FOR CUSTOMER

  const gridStructureValues = useSelector(
    (s) => s.customerSummary.gridStructure.table
  );
  //

  // GRID STRUCTURE FOR CUSTOMER

  let {
    currentOrganization,
    userPersonalization,
    referenceData,
    currentDefaultFormatDetails,
    appAccessRest,
  } = useContext(MaxyfiContext);

  let filetedList;

  if (appAccessRest.master_filter_type == "BUSINESS_UNIT") {
    // resetForm();
    filetedList = Object.fromEntries(
      Object.entries(
        tableCurrentState?.filters ? tableCurrentState?.filters : {}
      ).filter(([key, value]) => key !== "business_unit")
    );
  } else if (
    appAccessRest.master_filter_type == "TEAM" ||
    appAccessRest.master_filter_type == "SELF"
  ) {
    // resetForm();
    filetedList = Object.fromEntries(
      Object.entries(
        tableCurrentState?.filters ? tableCurrentState?.filters : {}
      ).filter(([key, value]) => key !== "rel_manager")
    );
  } else {
    filetedList = tableCurrentState?.filters;
  }

  useEffect(() => {
    const menu = "customer_summary";

    if (!viewName) {
      setTableGridStructure({
        menu,
        currentOrganization,
        setGridStructureAction: getCustomerSummaryGridStructure,
        setViewAction: setCustomerSummaryTableView,
        userPersonalization,
        dispatch,
      });
    }
  }, []);

  const [addSearchParams, setAddSearchParams] = useSearchParams();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  return (
    <>
      <div className="cp__customerm__tabel-container">
        <div
          className="cp__back_btn_wraper"
          onClick={() => setAddSearchParams({})}
        >
          <div>
            <BackArrowCustomer />
          </div>
          <HeadingXLarge $style={{ color: "#516beb" }}>
            <FormattedMessage id="customers">customers</FormattedMessage>{" "}
          </HeadingXLarge>
        </div>

        <div
          style={{
            display: "flex",
            gap: "5px",
            marginLeft: "5px",
            marginBottom: "5px",
          }}
        >
          <Input
            autoComplete="off"
            autoFill="off"
            size={SIZE.compact}
            placeholder={intl.formatMessage({
              id: "search",
            })}
            clearable
            // value={viewName}
            endEnhancer={<Search size="18px" />}
            overrides={{ ...inputOverrides }}
            value={isSearchData.display_name}
            onChange={(val) => {
              let value = val.target.value;
              setIsSearchData(value);
              debounceFilter(value);
            }}
          />
          <AllFilters
            setQueryFilter={setQueryFilter}
            //  setPageIndex={gotoPage}
            filterReduxData={filterReduxData}
            stageFilter={CustomerStageFilter}
            NeedsAction={NeedsActionData}
            name={"Custom"}
            title={"custom"}
            gridStructureValues={gridStructureValues}
            mainFilter={(data) => dispatch(setCustomerSummaryFilter(data))}
            needsActionFilter={setNeedsActionFilter}
            customerStageFilter={setCustomerStageFilter}
            pageIndex={setCustomerSummaryPageIndex}
            mainFilterDataLength={CustomFilter}
            needsActionFilterDataLength={NeedsActionFilter}
            customerStageFilterDataLength={CustomerStageFilter}
            id={"customer"}
          />
        </div>

        <div className="cp__customer__list-wraper" ref={ref}>
          {tableData && Array.isArray(tableData) ? (
            tableData?.map((e) => (
              <div
                className={`cp__customer__container ${
                  e._id === getParams ? "custmer__active" : " "
                }`}
                onClick={() => {
                  // dispatch(tabMotion(0));
                  setAddSearchParams({ customers: `${e._id}` });
                }}
              >
                <div style={{ flex: "1" }}>
                  <ParagraphMedium
                    $style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "180px",
                      overflow: "hidden",
                    }}
                  >
                    {e.display_name}
                  </ParagraphMedium>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "3px",
                    }}
                  >
                    <span className="cp_currency__style">
                      {e.total_inv_amount.currency}{" "}
                    </span>
                    <LabelSmall
                      $style={{
                        fontWeight: "700",
                        textAlign: "right",
                        lineHeight: "17px",
                      }}
                    >
                      {millify(
                        e &&
                          e.total_outstanding_invoice_amount &&
                          e.total_outstanding_invoice_amount?.value
                          ? e.total_outstanding_invoice_amount?.value
                          : 0,
                        {}
                      )}
                      {/* {e.total_inv_amount.value ? e.total_inv_amount.value : 0} */}
                    </LabelSmall>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                    className="cp-left__customer__list"
                  >
                    <ActionTypes type={e.active_next_action_type} />

                    {e.active_next_action_date
                      ? moment
                          .utc(e.active_next_action_date)
                          .tz(currentDefaultFormatDetails.time_zone)
                          .format(currentDefaultFormatDetails.date_format)
                      : " "}
                  </div>
                  {/* <HeadingXSmall>{e.next_action_name}</HeadingXSmall> */}
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomerListTable;
