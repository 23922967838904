function Call({ color = "#516BEB", width = 22, height = 22 }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0996 12.767L14.5545 12.4764C13.9433 12.4062 13.3421 12.6167 12.9112 13.0475L11.0675 14.8912C8.23179 13.4483 5.90711 11.1337 4.4642 8.28792L6.31793 6.43418C6.7488 6.00331 6.95922 5.4021 6.88908 4.79086L6.5985 2.26577C6.47826 1.25372 5.62654 0.492188 4.60449 0.492188H2.871C1.73872 0.492188 0.796821 1.43409 0.866962 2.56637C1.39803 11.1236 8.24181 17.9574 16.789 18.4885C17.9213 18.5586 18.8632 17.6167 18.8632 16.4845V14.751C18.8732 13.7389 18.1117 12.8872 17.0996 12.767V12.767Z"
          fill={color}
        />
      </svg>
    </>
  );
}

export default Call;
