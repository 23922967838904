import React from "react";

function InvoiceUpdate({ color = "#333860", size = 22 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2037 3.32115C13.0137 3.2336 12.7898 3.26767 12.6344 3.40772L11.8143 4.13383C11.792 4.15369 11.7583 4.15357 11.7361 4.1336L10.6203 3.13562C10.4185 2.95479 10.113 2.95479 9.9112 3.13562L8.7964 4.13268C8.77397 4.15271 8.74008 4.15271 8.71765 4.13268L7.60273 3.13562C7.40092 2.95482 7.09541 2.95482 6.8936 3.13562L5.77765 4.1336C5.75531 4.15357 5.72157 4.15366 5.69911 4.1338L4.8783 3.40772C4.66054 3.21407 4.32701 3.2336 4.13336 3.45136C4.04652 3.54901 3.99901 3.67541 4.00002 3.80607V14.6939C3.99807 14.9858 4.23308 15.224 4.52495 15.2259C4.65569 15.2268 4.78209 15.1792 4.87978 15.0923L5.69985 14.3662C5.72216 14.3463 5.75581 14.3464 5.778 14.3664L6.89381 15.3644C7.09562 15.5452 7.40113 15.5452 7.60294 15.3644L8.71786 14.3673C8.74029 14.3473 8.77418 14.3473 8.7966 14.3673L9.91153 15.3644C10.1134 15.5451 10.4188 15.5451 10.6207 15.3644L11.7367 14.3664C11.759 14.3464 11.7928 14.3463 11.8152 14.3662L12.636 15.0923C12.8539 15.2858 13.1874 15.2661 13.3809 15.0483C13.4676 14.9507 13.5151 14.8245 13.5141 14.6939V3.80607C13.5169 3.59691 13.3948 3.40618 13.2037 3.32115ZM5.95623 7.24081H8.89869C9.02923 7.24081 9.13506 7.34665 9.13506 7.47719C9.13506 7.60772 9.02923 7.71356 8.89869 7.71356H5.95623C5.82569 7.71356 5.71985 7.60772 5.71985 7.47719C5.71985 7.34665 5.82569 7.24081 5.95623 7.24081ZM11.5579 11.2592H5.95623C5.82569 11.2592 5.71985 11.1534 5.71985 11.0228C5.71985 10.8923 5.82569 10.7864 5.95623 10.7864H11.5579C11.6884 10.7864 11.7943 10.8923 11.7943 11.0228C11.7943 11.1534 11.6884 11.2592 11.5579 11.2592ZM11.5579 9.48638H5.95623C5.82569 9.48638 5.71985 9.38054 5.71985 9.25C5.71985 9.11946 5.82569 9.01362 5.95623 9.01362H11.5579C11.6884 9.01362 11.7943 9.11946 11.7943 9.25C11.7943 9.38054 11.6884 9.48638 11.5579 9.48638Z"
        fill={color}
      />
    </svg>
  );
}

export default InvoiceUpdate;
