export const LoanBroker = "INSE225";
export const CollectionAgencies = "INSE297";
export const FarmingRanchingForestry = "INSE001";
export const Farming = "INSE002";
export const Horticulture = "INSE003";
export const RanchingFisheries = "INSE004";
export const Ranching = "INSE005";
export const Fisheries = "INSE006";
export const ForestryLogging = "INSE007";
export const OilGasMining = "INSE008";
export const OilGas = "INSE009";
export const OilExtraction = "INSE010";
export const NaturalGasExtraction = "INSE011";
export const Mining = "INSE012";
export const CoalMining = "INSE013";
export const MetalOreMining = "INSE014";
export const NonmetallicMineralMining = "INSE015";
export const Utilities = "INSE016";
export const ElectricPowerTransmission = "INSE017";
export const ElectricPowerGeneration = "INSE018";
export const HydroelectricPowerGeneration = "INSE019";
export const FossilFuelElectricPowerGeneration = "INSE020";
export const NuclearElectricPowerGeneration = "INSE021";
export const SolarElectricPowerGeneration = "INSE022";
export const WindElectricPowerGeneration = "INSE023";
export const GeothermalElectricPowerGeneration = "INSE024";
export const BiomassElectricPowerGeneration = "INSE025";
export const NaturalGasDistribution = "INSE026";
export const WaterWasteSteamAirConditioningServices = "INSE027";
export const WaterSupplyIrrigationSystems = "INSE028";
export const SteamAirConditioningSupply = "INSE029";
export const WasteCollection = "INSE030";
export const WasteTreatmentDisposal = "INSE031";
export const Construction = "INSE032";
export const BuildingConstruction = "INSE033";
export const ResidentialBuildingConstruction = "INSE034";
export const NonresidentialBuildingConstruction = "INSE035";
export const CivilEngineering = "INSE036";
export const UtilitySystemConstruction = "INSE037";
export const SubdivisionLand = "INSE038";
export const HighwayStreetBridgeConstruction = "INSE039";
export const SpecialtyTradeContractors = "INSE040";
export const BuildingStructureExteriorContractors = "INSE041";
export const BuildingEquipmentContractors = "INSE042";
export const BuildingFinishingContractors = "INSE043";
export const Manufacturing = "INSE044";
export const FoodBeverageManufacturing = "INSE045";
export const  AnimalFeedManufacturing = "INSE046";
export const SugarConfectioneryProductManufacturing = "INSE047";
export const FruitVegetablePreservesManufacturing = "INSE048";
export const DairyProductManufacturing = "INSE049";
export const MeatProductsManufacturing = "INSE050";
export const SeafoodProductManufacturing = "INSE051";
export const BakedGoodsManufacturing = "INSE052";
export const BeverageManufacturing = "INSE053";
export const Wineries = "INSE054";
export const Breweries = "INSE055";
export const Distilleries = "INSE056";
export const TobaccoManufacturing = "INSE057";
export const TextileManufacturing = "INSE058";
export const ApparelManufacturing = "INSE059";
export const FashionAccessoriesManufacturing = "INSE060";
export const LeatherProductManufacturing = "INSE061";
export const FootwearManufacturing = "INSE062";
export const WomenHandbagManufacturing = "INSE063";
export const WoodProductManufacturing = "INSE064";
export const PaperForestProductManufacturing = "INSE065";
export const PrintingServices = "INSE066";
export const OilCoalProductManufacturing = "INSE067";
export const ChemicalManufacturing = "INSE068";
export const ChemicalRawMaterialsManufacturing = "INSE069";
export const ArtificialRubberSyntheticFiberManufacturing = "INSE070";
export const AgriculturalChemicalManufacturing = "INSE071";
export const PharmaceuticalManufacturing = "INSE072";
export const PaintCoatingAdhesiveManufacturing = "INSE073";
export const SoapCleaningProductManufacturing = "INSE074";
export const PersonalCareProductManufacturing = "INSE075";
export const PlasticsRubberProductManufacturing = "INSE076";
export const PlasticsManufacturing = "INSE077";
export const PackagingContainersManufacturing = "INSE078";
export const RubberProductsManufacturing = "INSE079";
export const GlassCeramicsConcreteManufacturing = "INSE080";

