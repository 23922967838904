import React from "react";
import { Select } from "baseui/select";

const NotesSelect = (props) => {
  return (
    <Select
      {...props}
      maxDropdownHeight="250px"
      overrides={{
        Tag: {
          props: {
            overrides: {
              Text: {
                style: ({ $theme }) => ({
                  maxWidth: "500px",
                }),
              },
            },
          },
        },
        Placeholder: {
          style: ({ $theme, $isFocused }) => ({
            color: $isFocused ? "#cbd5e0" : "transparent",
            transition: "color 0.4s ease",
          }),
        },
        // ValueContainer: {
        //   style: ({ $theme, $isFocused }) => ({
        //     ...(customOverride && customOverride.ValueContainer
        //       ? { ...customOverride.ValueContainer }
        //       : {}),
        //   }),
        // },
        ControlContainer: {
          style: ({ $theme, $isFocused, $error }) => ({
            // borderRadius: "5px",
            backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
            // borderRadius: "3px",
            borderColor: "#CDCED900",
            borderWidth: "0px",
            // borderStyle: "solid",
            paddingRight: "0px",
            backgroundColor: "#ffffff",
            // borderRadius: "5px",
            borderWidth: "1px",
            borderRightWidth: "1px",
            borderLeftWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            borderColor: $isFocused
              ? $error
                ? "#FD372A"
                : "#516BEB"
              : $error
              ? "#FD372A"
              : "#CDCED9",
          }),
        },
      }}
    />
  );
};

export default NotesSelect;
