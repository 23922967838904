import React from "react";

export default function Notes({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_481_58899)">
        <path
          d="M6.51565 5.86458C6.22815 5.86458 5.99481 5.63125 5.99481 5.34375V3.52083C5.99481 3.23333 6.22815 3 6.51565 3C6.80315 3 7.03648 3.23333 7.03648 3.52083V5.34375C7.03648 5.63125 6.80315 5.86458 6.51565 5.86458Z"
          fill={color}
        />
        <path
          d="M8.98958 5.86458C8.70208 5.86458 8.46875 5.63125 8.46875 5.34375V3.52083C8.46875 3.23333 8.70208 3 8.98958 3C9.27708 3 9.51042 3.23333 9.51042 3.52083V5.34375C9.51042 5.63125 9.27708 5.86458 8.98958 5.86458Z"
          fill={color}
        />
        <path
          d="M11.4635 5.86458C11.176 5.86458 10.9427 5.63125 10.9427 5.34375V3.52083C10.9427 3.23333 11.176 3 11.4635 3C11.751 3 11.9844 3.23333 11.9844 3.52083V5.34375C11.9844 5.63125 11.751 5.86458 11.4635 5.86458Z"
          fill={color}
        />
        <path
          d="M12.5052 4.17188H5.47398C4.68387 4.17188 4.04169 4.81406 4.04169 5.60417V14.0677C4.04169 14.8578 4.68387 15.5 5.47398 15.5H12.5052C13.2953 15.5 13.9375 14.8578 13.9375 14.0677V5.60417C13.9375 4.81406 13.2953 4.17188 12.5052 4.17188ZM6.64585 7.16667H9.25002C9.53752 7.16667 9.77085 7.4 9.77085 7.6875C9.77085 7.975 9.53752 8.20833 9.25002 8.20833H6.64585C6.35835 8.20833 6.12502 7.975 6.12502 7.6875C6.12502 7.4 6.35835 7.16667 6.64585 7.16667ZM11.8542 12.375H6.64585C6.35835 12.375 6.12502 12.1417 6.12502 11.8542C6.12502 11.5667 6.35835 11.3333 6.64585 11.3333H11.8542C12.1417 11.3333 12.375 11.5667 12.375 11.8542C12.375 12.1417 12.1417 12.375 11.8542 12.375ZM11.8542 10.2917H6.64585C6.35835 10.2917 6.12502 10.0583 6.12502 9.77083C6.12502 9.48333 6.35835 9.25 6.64585 9.25H11.8542C12.1417 9.25 12.375 9.48333 12.375 9.77083C12.375 10.0583 12.1417 10.2917 11.8542 10.2917Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_481_58899">
          <rect
            width="12.5"
            height="12.5"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
