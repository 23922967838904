import React from "react";

const Pin = ({ size = 22 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8259 2.57094C12.5642 1.83262 13.7903 1.7871 14.5918 2.57094L19.4321 7.41128C20.1959 8.17506 20.1959 9.4134 19.4321 10.1772C18.6938 10.9155 17.4678 10.9697 16.6662 10.1772L16.3205 9.83127L13.4342 12.7175C14.305 15.0741 13.722 17.9822 11.8259 19.8579C11.6212 20.0603 11.3062 20.034 11.1344 19.8579L6.98534 15.7087L3.87388 18.8206C3.68294 19.0116 3.37335 19.0116 3.18241 18.8206C3.00615 18.6444 2.97518 18.3381 3.18241 18.1292L6.29397 15.0173L2.14519 10.8687C1.96893 10.6924 1.93501 10.3874 2.14519 10.1772C3.99521 8.32717 6.80521 7.66502 9.28503 8.56832L12.1713 5.68206L11.8259 5.33685C11.0378 4.54468 11.0875 3.30927 11.8259 2.57094ZM13.9003 3.26242C13.5184 2.88053 12.8992 2.88053 12.5173 3.26242C12.1556 3.62421 12.1212 4.23749 12.5173 4.64537L13.2088 5.33685C13.3851 5.5131 13.4099 5.82665 13.2088 6.02832L9.75287 9.48449C9.61333 9.62404 9.40358 9.66602 9.22108 9.59093C7.29768 8.79957 4.97341 9.02774 3.19665 10.5373L11.4653 18.8059C12.9024 17.1431 13.2088 14.6404 12.4123 12.7823C12.3199 12.5668 12.4123 12.3562 12.5173 12.2506L15.9747 8.79425C16.151 8.61798 16.4541 8.58848 16.6662 8.79425L17.3577 9.48571C17.7396 9.8676 18.3587 9.8676 18.7406 9.48571C19.1024 9.12392 19.1412 8.51324 18.7406 8.10276L13.9003 3.26242Z"
        fill="#333860"
      />
    </svg>
  );
};

export default Pin;
