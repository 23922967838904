import React, { useContext, useEffect, useState } from "react";
import { Icon, Typography } from "../../../../components_v2";
import BankruptcyForm from "./BankruptcyForm";
import { useQuery, useQueryClient } from "react-query";
import {
  getBankruptcyInformationSingle,
  getCustomerBankruptcy,
} from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import BankruptcyCard from "./BankruptcyCard";
import setSelectValues from "../../../../utils/setSelectValues";
import Loader from "../../../../components/Loader";

const BankruptcyCardList = ({ setAdd, setResource, customerId, setState }) => {
  let { currentOrganization, users, referenceData } = useContext(MaxyfiContext);

  const { isLoading, data, isFetching, refetch } = useQuery(
    [`GET_CUSTOMER_BANKRUPTCY_${currentOrganization}`],
    async ({ queryKey }) => {
      return await getCustomerBankruptcy({
        organization: currentOrganization,
        customerId: customerId,
      });
    },
    {
      onSuccess: (data) => {
        if (
          data &&
          data.data &&
          data.data.docs &&
          Array.isArray(data.data.docs)
        ) {
          if (data.data.docs.length > 0) {
            setAdd(false);
          } else {
            setAdd();
          }
        }
      },
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      {data && data.data && data.data.docs && Array.isArray(data.data.docs) ? (
        data.data.docs.map((e) => {
          return (
            <BankruptcyCard
              {...e}
              isEdit={false}
              setIsEdit={() => {}}
              setResource={setResource}
              setState={setState}
              userName={users.find(({ id }) => id === e.crby)}
              refetch={refetch}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

const BankruptcyInfo = ({
  id,
  customerId,
  setIsRequest,
  setState,
  closeDrawer,
}) => {
  let { currentOrganization, users, currentDefaultFormatDetails, space } =
    useContext(MaxyfiContext);

  const { isLoading, data, isFetching } = useQuery(
    `customer-${customerId}-request-${id}`,
    async () => {
      return await getBankruptcyInformationSingle({
        organization: currentOrganization,
        customerId,
        resourceId: id,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: id ? true : false,
    }
  );

  if (isLoading || isFetching) {
    return <Loader />;
  }

  if (data && data.data && data.data.doc) {
    const {
      ssn,
      dob,
      fnam,
      lnam,
      adln1,
      adln2,
      city,
      state,
      ctry,
      zpcd,
      bkno,
      bkchp,
      dscotna,
      adocot,
      judna,
      bkamt,
      fildt,
      notidt,
      herdt,
      dimidt,
      bkdt,
      dichdt,
      faildt,
      reaff,
      conv,
      stofi,
      atref,
      atna,
      atfirm,
      atph,
      ataddr,
      atem,
      trstn,
      trstfr,
      trstph,
      trstaddr,
      trstem,
      iupcst,
      upcst,
      iupmtcst,
      docno,
    } = data.data.doc;

    const defaultValues = {
      ssn: ssn,
      ...(dob
        ? {
            dob: moment
              .utc(dob)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      fnam: fnam,
      lnam: lnam,
      adln1: adln1,
      adln2: adln2,
      city: city,
      state: setSelectValues(state),
      ctry: setSelectValues(ctry),
      zpcd: zpcd,
      bkno: bkno,
      bkchp: setSelectValues(bkchp),
      dscotna: dscotna,
      adocot: adocot,
      judna: judna,
      bkamt: bkamt,
      docno: docno,
      ...(fildt
        ? {
            fildt: moment
              .utc(fildt)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      ...(notidt
        ? {
            notidt: moment
              .utc(notidt)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      ...(herdt
        ? {
            herdt: moment
              .utc(notidt)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      ...(dimidt
        ? {
            dimidt: moment
              .utc(dimidt)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      ...(bkdt
        ? {
            bkdt: moment
              .utc(bkdt)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      ...(dichdt
        ? {
            dichdt: moment
              .utc(dichdt)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      ...(faildt
        ? {
            faildt: moment
              .utc(faildt)
              .tz(currentDefaultFormatDetails.time_zone)
              .toDate(),
          }
        : {}),
      reaff: reaff,
      conv: conv,
      stofi: setSelectValues(stofi),
      atref: setSelectValues(atref),
      atna: atna,
      atfirm: atfirm,
      atph: {
        coc: setSelectValues(atph && atph.coc),
        val: atph && atph.val,
      },
      ataddr: {
        li1: ataddr && ataddr.li1,
        li2: ataddr && ataddr.li2,
        cit: ataddr && ataddr.cit,
        sta: setSelectValues(ataddr && ataddr.sta),
        cou: setSelectValues(ataddr && ataddr.cou),
        zip: ataddr && ataddr.zip,
      },
      atem: {
        val: atem && atem.val,
      },
      trstn: trstn,
      trstfr: trstfr,
      trstph: {
        coc: setSelectValues(trstph && trstph.coc),
        val: trstph && trstph.val,
      },
      trstaddr: {
        li1: trstaddr && trstaddr.li1,
        li2: trstaddr && trstaddr.li2,
        cit: trstaddr && trstaddr.cit,
        sta: setSelectValues(trstaddr && trstaddr.sta),
        cou: setSelectValues(trstaddr && trstaddr.cou),
        zip: trstaddr && trstaddr.zip,
      },
      trstem: {
        val: trstem && trstem.val,
      },
      iupcst: iupcst,
      upcst: setSelectValues(upcst),
      iupmtcst: iupmtcst,
    };

    return (
      <BankruptcyForm
        resourceId={id}
        defaultValues={defaultValues}
        isEdit
        setState={setState}
        setIsRequest={setIsRequest}
        closeDrawer={closeDrawer}
      />
    );
  }
};
const LIST = "LIST";
const ADD = "ADD";
const EDIT = "EDIT";
const VIEW = "VIEW";

const initialState = {
  type: LIST,
  resourceId: null,
  resourceName: "",
};

const BankruptcyDrawer = ({
  closeDrawer,
  resourceId,
  setIsRequest,
  active,
  isrequest,
}) => {
  const queryClient = useQueryClient();
  let { currentOrganization } = useContext(MaxyfiContext);
  const { customerId } = useParams();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!customerId) {
      setAdd(true);
    }
  }, []);

  const setAdd = (isAdd = true) => {
    setState({ ...state, type: isAdd ? ADD : LIST });
  };

  const setResource = (id, isEdit = false) => {
    setState({
      ...state,
      type: isEdit ? EDIT : VIEW,
      resourceId: id,
    });
  };

  useEffect(() => {
    if (isrequest && isrequest.resourceId) {
      setResource(isrequest.resourceId, true);
    }
  }, [isrequest]);

  const cardsData = queryClient.getQueryData(
    `GET_CUSTOMER_BANKRUPTCY_${currentOrganization}`
  );

  return (
    <div className="bankruptcy-drawer-container">
      <div className="bankruptcy-drawer-container-header">
        {_.get(cardsData, "data.docs", []).length > 0 &&
        state.type === ADD &&
        customerId ? (
          <>
            <div
              className="bankruptcy-drawer-container-header"
              onClick={() => {
                setState({
                  type: LIST,
                });
              }}
            >
              <Icon icon="back_arrow" size={22} isPressable />
              <Typography type="h2">
                {" "}
                <FormattedMessage id="add_bankruptcy">
                  {" "}
                  add_bankruptcy
                </FormattedMessage>
              </Typography>
            </div>
          </>
        ) : customerId ? (
          <>
            <Icon icon="bankruptcy" size={22} />
            <Typography type="h2">
              {" "}
              <FormattedMessage id="bankruptcy_information">
                {" "}
                bankruptcy_information
              </FormattedMessage>
            </Typography>
          </>
        ) : (
          <>
            <Typography type="h2">
              {" "}
              <FormattedMessage id="bankruptcy_form">
                {" "}
                bankruptcy_form
              </FormattedMessage>
            </Typography>
          </>
        )}
      </div>
      {state.type === LIST && customerId ? (
        <>
          <BankruptcyCardList
            customerId={customerId}
            setAdd={setAdd}
            setResource={setResource}
            setState={setState}
          />
        </>
      ) : (
        <></>
      )}

      {state.type === ADD ? (
        <>
          <BankruptcyForm
            setAdd={setAdd}
            setResource={setResource}
            setState={setState}
            setIsRequest={setIsRequest}
            customerId={customerId}
            closeDrawer={closeDrawer}
          />
        </>
      ) : (
        <></>
      )}
      {state.type === EDIT ? (
        <>
          {" "}
          <BankruptcyInfo
            id={state.resourceId}
            isEdit={state.type === EDIT ? true : false}
            setState={setState}
            customerId={customerId}
            setIsRequest={setIsRequest}
            closeDrawer={closeDrawer}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BankruptcyDrawer;
