function DairyNoteAlertWarning() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.14966 30H26.8503C29.2739 30 30.7847 27.0542 29.5729 24.7082L17.7226 1.75952C16.5108 -0.586506 13.4892 -0.586506 12.2774 1.75952L0.427071 24.7082C-0.784717 27.0542 0.726083 30 3.14966 30ZM15 17.6525C14.1344 17.6525 13.4262 16.8587 13.4262 15.8886V12.3607C13.4262 11.3906 14.1344 10.5968 15 10.5968C15.8656 10.5968 16.5737 11.3906 16.5737 12.3607V15.8886C16.5737 16.8587 15.8656 17.6525 15 17.6525ZM16.5737 24.7082H13.4262V21.1804H16.5737V24.7082Z"
          fill="#FBBD04"
        />
      </svg>
    </>
  );
}

export default DairyNoteAlertWarning;
