import React from "react";

const Dashboard = ({ size = 22, color = "#F8F8FB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.99982 2H7.24969C8.04529 2 8.80831 2.31605 9.37088 2.87863C9.93346 3.4412 10.2495 4.20422 10.2495 4.99982V7.24969C10.2495 8.04529 9.93346 8.80831 9.37088 9.37088C8.80831 9.93346 8.04529 10.2495 7.24969 10.2495H4.99982C4.20422 10.2495 3.4412 9.93346 2.87863 9.37088C2.31605 8.80831 2 8.04529 2 7.24969V4.99982C2 4.20422 2.31605 3.4412 2.87863 2.87863C3.4412 2.31605 4.20422 2 4.99982 2ZM8.31029 8.31029C8.59157 8.029 8.7496 7.64749 8.7496 7.24969V4.99982C8.7496 4.60202 8.59157 4.22051 8.31029 3.93923C8.029 3.65794 7.64749 3.49991 7.24969 3.49991H4.99982C4.60202 3.49991 4.22051 3.65794 3.93923 3.93923C3.65794 4.22051 3.49991 4.60202 3.49991 4.99982V7.24969C3.49991 7.64749 3.65794 8.029 3.93923 8.31029C4.22051 8.59157 4.60202 8.7496 4.99982 8.7496H7.24969C7.64749 8.7496 8.029 8.59157 8.31029 8.31029Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7503 2H17.0002C17.7958 2 18.5588 2.31605 19.1214 2.87863C19.6839 3.4412 20 4.20422 20 4.99982V7.24969C20 8.04529 19.6839 8.80831 19.1214 9.37088C18.5588 9.93346 17.7958 10.2495 17.0002 10.2495H14.7503C13.9547 10.2495 13.1917 9.93346 12.6291 9.37088C12.0665 8.80831 11.7505 8.04529 11.7505 7.24969V4.99982C11.7505 4.20422 12.0665 3.4412 12.6291 2.87863C13.1917 2.31605 13.9547 2 14.7503 2ZM18.0608 8.31029C18.3421 8.029 18.5001 7.64749 18.5001 7.24969V4.99982C18.5001 4.60202 18.3421 4.22051 18.0608 3.93923C17.7795 3.65794 17.398 3.49991 17.0002 3.49991H14.7503C14.3525 3.49991 13.971 3.65794 13.6897 3.93923C13.4084 4.22051 13.2504 4.60202 13.2504 4.99982V7.24969C13.2504 7.64749 13.4084 8.029 13.6897 8.31029C13.971 8.59157 14.3525 8.7496 14.7503 8.7496H17.0002C17.398 8.7496 17.7795 8.59157 18.0608 8.31029Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.24969 11.7505H4.99982C4.20422 11.7505 3.4412 12.0665 2.87863 12.6291C2.31605 13.1917 2 13.9547 2 14.7503V17.0002C2 17.7958 2.31605 18.5588 2.87863 19.1214C3.4412 19.6839 4.20422 20 4.99982 20H7.24969C8.04529 20 8.80831 19.6839 9.37088 19.1214C9.93346 18.5588 10.2495 17.7958 10.2495 17.0002V14.7503C10.2495 13.9547 9.93346 13.1917 9.37088 12.6291C8.80831 12.0665 8.04529 11.7505 7.24969 11.7505ZM8.7496 17.0002C8.7496 17.398 8.59157 17.7795 8.31029 18.0608C8.029 18.3421 7.64749 18.5001 7.24969 18.5001H4.99982C4.60202 18.5001 4.22051 18.3421 3.93923 18.0608C3.65794 17.7795 3.49991 17.398 3.49991 17.0002V14.7503C3.49991 14.3525 3.65794 13.971 3.93923 13.6897C4.22051 13.4084 4.60202 13.2504 4.99982 13.2504H7.24969C7.64749 13.2504 8.029 13.4084 8.31029 13.6897C8.59157 13.971 8.7496 14.3525 8.7496 14.7503V17.0002Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7503 11.7505H17.0002C17.7958 11.7505 18.5588 12.0665 19.1214 12.6291C19.6839 13.1917 20 13.9547 20 14.7503V17.0002C20 17.7958 19.6839 18.5588 19.1214 19.1214C18.5588 19.6839 17.7958 20 17.0002 20H14.7503C13.9547 20 13.1917 19.6839 12.6291 19.1214C12.0665 18.5588 11.7505 17.7958 11.7505 17.0002V14.7503C11.7505 13.9547 12.0665 13.1917 12.6291 12.6291C13.1917 12.0665 13.9547 11.7505 14.7503 11.7505ZM18.0608 18.0608C18.3421 17.7795 18.5001 17.398 18.5001 17.0002V14.7503C18.5001 14.3525 18.3421 13.971 18.0608 13.6897C17.7795 13.4084 17.398 13.2504 17.0002 13.2504H14.7503C14.3525 13.2504 13.971 13.4084 13.6897 13.6897C13.4084 13.971 13.2504 14.3525 13.2504 14.7503V17.0002C13.2504 17.398 13.4084 17.7795 13.6897 18.0608C13.971 18.3421 14.3525 18.5001 14.7503 18.5001H17.0002C17.398 18.5001 17.7795 18.3421 18.0608 18.0608Z"
        fill={color}
      />
    </svg>
  );
};

export default Dashboard;
