import React from "react";
const PpProgress = ({ color = "#ADADAD", width = 15, height = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16249 7.25001V4.21249C8.16249 4.03333 8.10312 3.88437 7.98437 3.76562C7.86562 3.64687 7.71666 3.58749 7.5375 3.58749C7.35834 3.58749 7.20938 3.64687 7.09063 3.76562C6.97188 3.88437 6.91251 4.03333 6.91251 4.21249V7.49376C6.91251 7.57708 6.92709 7.65521 6.95625 7.72813C6.98543 7.80104 7.02918 7.87083 7.0875 7.93749L9.7125 10.625C9.83334 10.7542 9.98542 10.8167 10.1688 10.8125C10.3521 10.8083 10.5042 10.7458 10.625 10.625C10.7458 10.5042 10.8062 10.3542 10.8062 10.175C10.8062 9.99584 10.7458 9.84584 10.625 9.72501L8.16249 7.25001ZM7.5 15C6.47084 15 5.50001 14.8031 4.58751 14.4094C3.67501 14.0156 2.87917 13.4792 2.19999 12.8C1.52083 12.1208 0.984375 11.325 0.590625 10.4125C0.196875 9.49999 0 8.52916 0 7.5C0 6.47084 0.196875 5.50001 0.590625 4.58751C0.984375 3.67501 1.52083 2.87917 2.19999 2.19999C2.87917 1.52083 3.67501 0.984375 4.58751 0.590625C5.50001 0.196875 6.47084 0 7.5 0C8.52916 0 9.49999 0.196875 10.4125 0.590625C11.325 0.984375 12.1208 1.52083 12.8 2.19999C13.4792 2.87917 14.0156 3.67501 14.4094 4.58751C14.8031 5.50001 15 6.47084 15 7.5C15 8.52916 14.8031 9.49999 14.4094 10.4125C14.0156 11.325 13.4792 12.1208 12.8 12.8C12.1208 13.4792 11.325 14.0156 10.4125 14.4094C9.49999 14.8031 8.52916 15 7.5 15Z"
        fill={color}
      />
    </svg>
  );
};
export default PpProgress;
