export default function Profile({ size = 22, color = "#F8F8FB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2C6.03725 2 2 6.03725 2 11C2 15.9628 6.03725 20 11 20C15.9628 20 20 15.9628 20 11C20 6.03725 15.9628 2 11 2ZM8 17.873V17.75C8 16.0955 9.3455 14.75 11 14.75C12.6545 14.75 14 16.0955 14 17.75V17.873C13.0805 18.2757 12.0665 18.5 11 18.5C9.9335 18.5 8.9195 18.2757 8 17.873ZM15.4437 17.0383C15.1017 14.894 13.2395 13.25 11 13.25C8.7605 13.25 6.899 14.894 6.55625 17.0383C4.70375 15.671 3.5 13.4735 3.5 11C3.5 6.8645 6.8645 3.5 11 3.5C15.1355 3.5 18.5 6.8645 18.5 11C18.5 13.4735 17.2962 15.671 15.4437 17.0383ZM11 5.75C9.3455 5.75 8 7.0955 8 8.75C8 10.4045 9.3455 11.75 11 11.75C12.6545 11.75 14 10.4045 14 8.75C14 7.0955 12.6545 5.75 11 5.75ZM11 10.25C10.1727 10.25 9.5 9.57725 9.5 8.75C9.5 7.92275 10.1727 7.25 11 7.25C11.8273 7.25 12.5 7.92275 12.5 8.75C12.5 9.57725 11.8273 10.25 11 10.25Z"
        fill={color}
      />
    </svg>
  );
}
