import React from "react";

export default function Comment({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15.5V7.49184C1 5.01526 3.01526 3 5.49184 3H12.8736C15.3507 3 17.3665 5.01526 17.3665 7.49184V8.87312C17.3665 11.3497 15.3512 13.365 12.8746 13.365H5.59598L1 15.5ZM5.49184 4.00142C3.56723 4.00142 2.00142 5.56723 2.00142 7.49184V13.9304L5.3753 12.363H12.8746C14.7993 12.363 16.3651 10.7972 16.3651 8.87258V7.4913C16.3651 5.56669 14.7987 4.00089 12.8736 4.00089L5.49184 4.00142ZM5.82205 7.41576C6.34812 7.41576 6.77438 7.84202 6.77438 8.36809C6.77438 8.89416 6.34812 9.32041 5.82205 9.32041C5.29598 9.32041 4.86973 8.89416 4.86973 8.36809C4.86973 7.84202 5.29598 7.41576 5.82205 7.41576ZM12.5439 7.41576C13.07 7.41576 13.4962 7.84202 13.4962 8.36809C13.4962 8.89416 13.07 9.32041 12.5439 9.32041C12.0178 9.32041 11.5916 8.89416 11.5916 8.36809C11.5916 7.84202 12.0178 7.41576 12.5439 7.41576ZM9.18244 7.41576C9.70851 7.41576 10.1348 7.84202 10.1348 8.36809C10.1348 8.89416 9.70851 9.32041 9.18244 9.32041C8.65636 9.32041 8.23011 8.89416 8.23011 8.36809C8.23011 7.84202 8.65636 7.41576 9.18244 7.41576Z"
        fill={color}
      />
    </svg>
  );
}
