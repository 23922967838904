import * as React from "react";
import { useStyletron } from "baseui";
import { Input, StyledInput } from "baseui/input";
import { Tag, VARIANT as TAG_VARIANT } from "baseui/tag";
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import AlertInfo from "../../assets/img/svg/AlertInfo";
import FullScreen from "../../assets/img/svg/FullScreen";
import { IconButton } from "../../components/IconButton";
import {
  H2,
  HeadingSmall,
  HeadingXSmall,
  LabelMedium,
  LabelXSmall,
  Label1,
} from "baseui/typography";
import { useIntl, FormattedMessage, FormattedNumber } from "react-intl";

export default function ActionCostInfo(props) {
  let cost = {
    cost: {
      value: 0,
      currency: "USD",
    },
    quantity: 1,
    recipientCount: 1,
    totalCost: {
      value: 0,
      currency: "USD",
    },
    ...props,
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Label1 style={{ color: "#adadad", marginRight: "10px" }}>
        <FormattedNumber
          value={cost.cost.value}
          style="currency"
          currency={cost.cost.currency}
        />
      </Label1>
      <div style={{ marginRight: "10px" }}>
        <StatefulPopover
          showArrow
          placement={PLACEMENT.bottomRight}
          content={() => (
            <div
              style={{
                width: "200px",
                height: "160px",
                padding: "10px",
                backgroundColor: "#FBFBFB",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "10px",
                }}
              >
                <LabelMedium>Price Details</LabelMedium>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "0.5px solid #D6D6D6",
                }}
              >
                <HeadingXSmall>Message Cost</HeadingXSmall>
                <HeadingXSmall>
                  ({cost.quantity} x {cost.cost.value})
                </HeadingXSmall>
                <LabelXSmall>
                  <FormattedNumber
                    value={cost.cost.value}
                    style="currency"
                    currency={cost.cost.currency}
                  />
                </LabelXSmall>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "0.5px solid #D6D6D6",
                }}
              >
                <HeadingXSmall>Number of Recipients</HeadingXSmall>
                <LabelXSmall>{cost.recipientCount}</LabelXSmall>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <HeadingXSmall>Total Cost</HeadingXSmall>
                <LabelXSmall style={{ color: "#0FB158" }}>
                  <FormattedNumber
                    value={cost.totalCost.value}
                    style="currency"
                    currency={cost.totalCost.currency}
                  />
                </LabelXSmall>
              </div>
            </div>
          )}
          triggerType={TRIGGER_TYPE.hover}
          returnFocus
          autoFocus
        >
          <div>
            {" "}
            <AlertInfo />
          </div>
        </StatefulPopover>
      </div>
    </div>
  );
}
