function CallSkipped() {
  return (
    <>
      <svg
        // version="1.1"
        // id="Layer_1"
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        // x="0px"
        // y="0px"
        // viewBox="0 0 22 22"
        // style="enable-background:new 0 0 22 22;"
        // xml:space="preserve"
        width="25"
        height="25"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            style={{ fill: "#ADADAD" }}
            d="M17,16.7v0.6c0,1-0.8,1.9-1.9,1.8C7.4,18.6,1.3,12.5,0.8,4.8c-0.1-1,0.8-1.9,1.8-1.9h1.6
		c0.9,0,1.7,0.7,1.8,1.6l0.3,2.3c0.1,0.5-0.1,1.1-0.5,1.5L4,9.9c1.3,2.6,3.4,4.6,5.9,5.9l1.7-1.7c0.4-0.4,0.9-0.6,1.5-0.5l0.3,0
		C13.7,15.4,15.2,16.7,17,16.7z"
          />
          <path
            style={{ fill: "#FD372A" }}
            d="M16.1,15.2l2.8-2c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.1-0.2-0.2
		l-2.8-2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3v4c0,0.1,0,0.2,0.1,0.3
		c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1C15.9,15.3,16,15.2,16.1,15.2z M20.2,10.3v4.9c0,0.1,0.1,0.3,0.1,0.3
		c0.1,0.1,0.2,0.1,0.3,0.1s0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-4.9c0-0.1-0.1-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1
		s-0.3,0.1-0.3,0.1C20.3,10.1,20.2,10.2,20.2,10.3L20.2,10.3z"
          />
        </g>
      </svg>
    </>
  );
}

export default CallSkipped;
