import React, { useContext } from "react";
import PromiseForm from "./PromiseForm";
import CallBack from "./CallBack";
import MeetingRequestForm from "./MeetingRequestForm";
import Dispute from "./Dispute";
import UnsuccessfulForm from "./UnsuccessfulForm";
import PaymentPlan from "./PaymentPlan";
import PayAction from "./PayAction";
import ReturnVisit from "./AdhocActionPopup/FieldVisit/ReturnVisit/ReturnVisit";
import { useState } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { getOrganization } from "../../../../services";
import { useSelector } from "react-redux";

const GetCallAction = (props) => {
  let { orgDtl } = useSelector((e) => e.customerOverviewReducer);

  switch (props.callOutcome) {
    case "PROMISE_TO_PAY":
      return (
        <PromiseForm
          {...props}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      );
    case "CALL_BACK_LATER":
      return (
        <CallBack
          {...props}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      );
    case "MEETING_REQUEST":
      return (
        <MeetingRequestForm
          {...props}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      );
    case "RECORD_DISPUTE":
      return (
        <Dispute
          {...props}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      );
    case "UNSUCCESSFUL":
      return (
        <UnsuccessfulForm
          {...props}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      );
    case "PAYMENT_PLAN":
      return (
        <PaymentPlan
          {...props}
          defaultConfiguration={
            orgDtl?.default_configuration?.user_captured_payment_plan
          }
        />
      );
    case "PAYMENT":
      return (
        <PayAction
          {...props}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      );
    case "FIELD_VISIT":
      return (
        <ReturnVisit
          {...props}
          errors={props.errors}
          control={props.control}
          watch={props.watch}
          customerContactsData={props.customerContactsData}
          setValue={props.setValue}
          entity="CALL"
          recipients={props.recipients}
          defaultConfiguration={orgDtl?.default_configuration?.call_field_visit}
        />
      );
    default:
      return null;
  }
};

export default GetCallAction;
