import React, { useContext, useMemo, useState } from "react";
import InActiveDelete from "../../assets/img/svg/NestedColumnIcons/InActiveDelete";
import Pin from "../../assets/img/svg/Pin";
import UnPin from "../../assets/img/svg/UnPin";
import Pinned from "../../assets/img/svg/Pinned";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  defaultViewData,
  deleteViewData,
  pinViewData,
  getViewData,
} from "../../services/customerSummary";
import { useRBAC, permission } from "../../providers/RBACProvider";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import { ModalContext } from "../../providers/ModalProvider";
import warningImage from "../../assets/img/warning.png";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { DELETE_VIEWS } from "../../providers/RBACProvider/permissionList";
import { Search } from "baseui/icon";
import { TextBox } from "../TextBox";

const ViewComp = (props) => {
  const queryClient = useQueryClient();

  const {
    name,
    _id,
    setView,
    menu,
    table,
    colOrder,
    pinned_users,
    refetchPinnedViews,
    description,
    default_users,
    pinnedView,
    count,
  } = props;

  const { handleModal } = useContext(ModalContext);
  const { isAllowed } = useRBAC();
  let { currentOrganization, userPersonalization, userInfo, space } =
    useContext(MaxyfiContext);

  let is_default = false;

  if (
    userPersonalization &&
    userPersonalization.default_view &&
    userPersonalization.default_view[menu] &&
    userPersonalization.default_view[menu] === _id
  ) {
    is_default = true;
  }

  const deleteViewList = useMutation(
    (deleteview) =>
      deleteViewData({ organization: currentOrganization, ...deleteview }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${menu}-views`);
      },
    }
  );

  const pinViewList = useMutation(
    (viewDetails) =>
      pinViewData({ organization: currentOrganization, ...viewDetails }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${menu}-views`);
      },
    }
  );

  const defaultViewList = useMutation(
    (defaultview) =>
      defaultViewData({ organization: currentOrganization, ...defaultview }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${menu}-views`);
      },
    }
  );
  return (
    <div>
      <div className="view-container">
        <div
          style={{
            flex: 1,
            display: "flex",
            cursor: "pointer",
            height: "100%",
            alignItems: "center",
            // justifyContent: "space-between",
          }}
          onClick={() => {
            let { setView, ...rest } = props;
            setView(rest);
            colOrder(table && table.map((i) => ["selection", ...i.col_id]));

            if (count != 0) {
              pinnedView.shift();
            }
            pinnedView.unshift({
              ...rest,
            });
          }}
        >
          <div style={{ paddingRight: "3px" }} className="pinned-icon">
            {pinned_users &&
            Array.isArray(pinned_users) &&
            pinned_users.includes(userInfo?._id) ? (
              <Pinned size={16} />
            ) : (
              <></>
            )}
          </div>
          <p className="view_title">{name}</p>
        </div>

        <div className="view_actions">
          {is_default ? (
            <p className="view__mark-as-default">Default</p>
          ) : (
            <>
              <p
                className="view__mark-as-default"
                onClick={() => {
                  defaultViewList.mutateAsync({ viewId: _id, menu });
                }}
              >
                Make Default
              </p>
            </>
          )}

          <div
            className="view__action-button"
            onClick={() => {
              pinViewList.mutateAsync({ viewId: _id, menu });
            }}
          >
            {pinned_users &&
            Array.isArray(pinned_users) &&
            pinned_users.includes(userInfo?._id) ? (
              <UnPin size={16} />
            ) : (
              <Pin fill="#CDCED9" size={16} />
            )}
          </div>

          {isAllowed(permission.DELETE_VIEW) ? (
            <RBACWrapper role={DELETE_VIEWS} type={"PERMISSION"}>
              <div
                className="view__action-button view__action-button--delete"
                onClick={() => {
                  handleModal({
                    title: "You are about to delete a View",
                    content:
                      "This will Permanently  delete your view. Are you sure?",
                    successCallback: () => {
                      deleteViewList.mutateAsync({ viewId: _id });
                    },
                    isChildren: false,
                    image: warningImage,
                    buttonText: "Delete",
                    closeButtonText: "Cancel",
                    formContent: () => {
                      return <></>;
                    },
                    cancelCallback: () => {},
                    isCloseAble: false,
                  });
                }}
              >
                <InActiveDelete fill="#CDCED9" size={16} />
              </div>
            </RBACWrapper>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

const ViewList = ({
  menu,
  setView,
  colOrder,
  refetchPinnedViews,
  pinnedViewsState,
  pinnedView,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [count, setCount] = useState(0);

  let { currentOrganization, userPersonalization } = useContext(MaxyfiContext);
  const { data } = useQuery(`${menu}-views`, async () => {
    return await getViewData({ menu, organization: currentOrganization });
  });

  let viewNameList = useMemo(() => {
    let resultView = {
      default: [],
      pinned: [],
      remaining_views: [],
    };
    if (pinnedViewsState) {
      pinnedView &&
        Array.isArray(pinnedView) &&
        pinnedView.map((e, i) => {
          if (
            (searchValue && e && e.name.toLowerCase().includes(searchValue)) ||
            !searchValue
          ) {
            if (i >= 4) {
              resultView.pinned.push({
                ...e,
              });
            }
          }
        });
    } else {
      data &&
        data?.data &&
        data?.data?.docs
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          })
          .map((view) => {
            let is_default = false;
            if (
              userPersonalization &&
              userPersonalization.default_view &&
              userPersonalization.default_view[menu] &&
              userPersonalization.default_view[menu] === view._id
            ) {
              is_default = true;
            }

            if (
              (searchValue &&
                view &&
                view?.name
                  ?.toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              !searchValue
            ) {
              if (view && is_default) {
                resultView.default.push({
                  ...view,
                });
              } else if (
                view &&
                view.pinned_users &&
                view.pinned_users.length > 0 &&
                view.pinned_users.includes(userPersonalization.user_id)
              ) {
                resultView.pinned.push({
                  ...view,
                });
              } else {
                resultView.remaining_views.push({
                  ...view,
                });
              }
            }
          });
    }

    return resultView;
  }, [data?.data, searchValue]);

  return (
    <div>
      <div style={{ margin: "10px 10px 0px 10px" }}>
        <TextBox
          value={searchValue}
          autoFocus
          placeholder="Search Views..."
          label="Search Views..."
          endEnhancer={<Search size="18px" />}
          onChange={(val) => {
            let searchViews = val.target.value;
            setSearchValue(searchViews);
          }}
          size="mini"
        />
      </div>
      <div className="scroll-icn">
        {viewNameList &&
          Object.keys(viewNameList).map((title) => {
            if (
              viewNameList &&
              viewNameList[title] &&
              viewNameList[title].length === 0
            ) {
              return <></>;
            }

            return (
              <>
                {!searchValue && (
                  <>
                    <p
                      style={{ textTransform: "capitalize", color: "#ADADAD" }}
                    >
                      {title.replace("_", " ")}
                    </p>
                  </>
                )}
                <div
                  onClick={() => {
                    setCount(count + 1);
                  }}
                >
                  {viewNameList &&
                    viewNameList[title] &&
                    viewNameList[title].map((item) => (
                      <ViewComp
                        setView={setView}
                        menu={menu}
                        colOrder={colOrder}
                        refetchPinnedViews={refetchPinnedViews}
                        {...item}
                        pinnedViewsState={pinnedViewsState}
                        pinnedView={pinnedView}
                        count={count}
                      />
                    ))}
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default ViewList;
