import React from "react";

const NotesTimelineSvg = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13001_86362)">
        <path
          d="M6.18754 5.04167C5.68154 5.04167 5.27087 4.631 5.27087 4.125V0.916667C5.27087 0.410667 5.68154 0 6.18754 0C6.69354 0 7.10421 0.410667 7.10421 0.916667V4.125C7.10421 4.631 6.69354 5.04167 6.18754 5.04167Z"
          fill="#516beb"
        />
        <path
          d="M10.5417 5.04167C10.0357 5.04167 9.625 4.631 9.625 4.125V0.916667C9.625 0.410667 10.0357 0 10.5417 0C11.0477 0 11.4583 0.410667 11.4583 0.916667V4.125C11.4583 4.631 11.0477 5.04167 10.5417 5.04167Z"
          fill="#516beb"
        />
        <path
          d="M14.8958 5.04167C14.3898 5.04167 13.9791 4.631 13.9791 4.125V0.916667C13.9791 0.410667 14.3898 0 14.8958 0C15.4018 0 15.8125 0.410667 15.8125 0.916667V4.125C15.8125 4.631 15.4018 5.04167 14.8958 5.04167Z"
          fill="#516beb"
        />
        <path
          d="M16.7292 2.0625H4.35421C2.96362 2.0625 1.83337 3.19275 1.83337 4.58333V19.4792C1.83337 20.8698 2.96362 22 4.35421 22H16.7292C18.1198 22 19.25 20.8698 19.25 19.4792V4.58333C19.25 3.19275 18.1198 2.0625 16.7292 2.0625ZM6.41671 7.33333H11C11.506 7.33333 11.9167 7.744 11.9167 8.25C11.9167 8.756 11.506 9.16667 11 9.16667H6.41671C5.91071 9.16667 5.50004 8.756 5.50004 8.25C5.50004 7.744 5.91071 7.33333 6.41671 7.33333ZM15.5834 16.5H6.41671C5.91071 16.5 5.50004 16.0893 5.50004 15.5833C5.50004 15.0773 5.91071 14.6667 6.41671 14.6667H15.5834C16.0894 14.6667 16.5 15.0773 16.5 15.5833C16.5 16.0893 16.0894 16.5 15.5834 16.5ZM15.5834 12.8333H6.41671C5.91071 12.8333 5.50004 12.4227 5.50004 11.9167C5.50004 11.4107 5.91071 11 6.41671 11H15.5834C16.0894 11 16.5 11.4107 16.5 11.9167C16.5 12.4227 16.0894 12.8333 15.5834 12.8333Z"
          fill="#516beb"
        />
      </g>
      <defs>
        <clipPath id="clip0_13001_86362">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotesTimelineSvg;
