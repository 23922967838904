import React from "react";

const InfoSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="info_FILL1_wght200_GRAD-25_opsz24 1">
        <path
          id="Vector"
          d="M9.00613 12.3172C9.10098 12.3172 9.1823 12.2831 9.25008 12.2151C9.31787 12.1471 9.35177 12.0645 9.35177 11.9674V8.60394C9.35177 8.51235 9.31752 8.43237 9.24903 8.36398C9.18055 8.29558 9.10044 8.26138 9.00872 8.26138C8.91145 8.26138 8.82893 8.29558 8.76114 8.36398C8.69335 8.43237 8.65946 8.51235 8.65946 8.60394V11.9674C8.65946 12.0645 8.69352 12.1471 8.76165 12.2151C8.82977 12.2831 8.91127 12.3172 9.00613 12.3172ZM8.99788 7.22869C9.12708 7.22869 9.23279 7.18824 9.315 7.10735C9.39721 7.02645 9.43832 6.9214 9.43832 6.7922C9.43832 6.663 9.39787 6.5573 9.31697 6.47508C9.23607 6.39287 9.13102 6.35177 9.00182 6.35177C8.87262 6.35177 8.76691 6.39222 8.6847 6.47312C8.60248 6.554 8.56138 6.65905 8.56138 6.78825C8.56138 6.91745 8.60183 7.02315 8.68273 7.10537C8.76363 7.18758 8.86868 7.22869 8.99788 7.22869ZM9.00328 15.6345C8.08582 15.6345 7.2229 15.4602 6.41452 15.1116C5.60615 14.763 4.90297 14.29 4.305 13.6924C3.70702 13.0948 3.23423 12.393 2.88663 11.587C2.53903 10.781 2.36523 9.91973 2.36523 9.00328C2.36523 8.08582 2.53952 7.2229 2.8881 6.41452C3.23667 5.60615 3.70973 4.90297 4.30728 4.305C4.90485 3.70702 5.60665 3.23423 6.41268 2.88663C7.21872 2.53903 8.07997 2.36523 8.99642 2.36523C9.91388 2.36523 10.7768 2.53952 11.5852 2.8881C12.3935 3.23667 13.0967 3.70973 13.6947 4.30728C14.2927 4.90485 14.7655 5.60665 15.1131 6.41268C15.4607 7.21872 15.6345 8.07997 15.6345 8.99642C15.6345 9.91388 15.4602 10.7768 15.1116 11.5852C14.763 12.3935 14.29 13.0967 13.6924 13.6947C13.0948 14.2927 12.393 14.7655 11.587 15.1131C10.781 15.4607 9.91973 15.6345 9.00328 15.6345Z"
          fill="#ADADAD"
        />
      </g>
    </svg>
  );
};

export default InfoSvg;
