import React, { useContext } from "react";
import { Button, KIND } from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { IconButton, SIZE } from "../../../../components/IconButton";
import CaptureHold from "../../../../assets/img/svg/NestedRibbons/CaptureHold";
import { HeadingXLarge, ParagraphLarge } from "baseui/typography";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { SelectBox } from "../../../../components/SelectBox";
import { TextArea } from "../../../../components/TextArea";
import { useForm, Controller, set } from "react-hook-form";
import { useIntl } from "react-intl";
import getSelectValues from "../../../../utils/getSelectValues";
import { TextButton } from "../../../../components/TextButton";
import moment from "moment";
import OnSkipModalSvg from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/OnSkipModal";
import Next from "../../../../assets/img/svg/CustomerOverViewIcon/Next";
import { useParams, useSearchParams } from "react-router-dom";
import { SKIPPED } from "../../../../constants";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useSelector, useDispatch } from "react-redux";

import { updateCustomerActionWorkflowStatus } from "../../../../services";
import { skipActionModal } from "../../../../redux/actions";
import { refetchActions } from "../../../../redux/actions";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { refetchInvoiceActions } from "../../../../redux/invoiceOverview/action";

const OnSkipModal = ({ entity, getParams }) => {
  const { referenceData, userInfo, currentOrganization } =
    useContext(MaxyfiContext);
  // const [getSearchParams, setGetSearchParams] = useSearchParams();
  // const getParams = getSearchParams.get("customers")

  const entityName = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_entity
  );

  const dispatch = useDispatch();
  // const { id } = useParams();
  // const [isOpen, setIsOpen] = React.useState(false);
  // function close() {
  //   setIsOpen(false);
  // }
  const actionModal = useSelector(
    (s) => s.customerOverviewReducer.skipActionIsModal
  );
  const intl = useIntl();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    shouldUnregister: true,
    defaultValues: {},
  });

  const skipActionMutation = useMutation(
    (skipActionData) =>
      updateCustomerActionWorkflowStatus({
        ...skipActionData,
        id: getParams,
        entity: entity,
        organization: currentOrganization,
        actionStatus: SKIPPED,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        dispatch(skipActionModal(false));
        if (entityName === "INVOICE") {
          dispatch(refetchInvoiceActions());
        } else {
          dispatch(refetchActions());
        }
        reset();
        // close();
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      ...data,
    };

    await skipActionMutation.mutateAsync(values);
    dispatch(refetchActions());
  };

  return (
    <>
      <Modal
        onClose={actionModal}
        isOpen={actionModal}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              textAlign: "center",
            }),
          },
          Close: {
            style: ({ $theme }) => ({
              display: "none",
            }),
          },
          Dialog: {
            style: ({ $theme }) => ({
              padding: "30px",
              width: "600px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              // boxShadow: "0px 4px 4px 0px #33386050 ",
            }),
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {/* Heading */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <OnSkipModalSvg />
              <HeadingXLarge> Skip Action</HeadingXLarge>
            </div>

            {/* Section One */}
            {/* <ParagraphLarge style={{ color: "#333860", display: "flex" }}>
              Reason to Skip
            </ParagraphLarge> */}
            <div style={{ paddingTop: "5px" }}>
              <Controller
                name="comment"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <TextArea
                      {...field}
                      // type="number"
                      requiredAstric={true}
                      fullWidth
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "reason_to_skip",
                      })}
                      placeholder={intl.formatMessage({
                        id: "reason_to_skip",
                      })}
                      value={field.value}
                    />
                  </>
                )}
              />
            </div>
            <div
              style={{
                paddingTop: "5px",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <div style={{ width: "126px" }}>
                <TextButton
                  type="button"
                  size={SIZE.compact}
                  onClick={() => dispatch(skipActionModal(false))}
                  fullWidth
                  kind={KIND.tertiary}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ width: "126px" }}>
                <TextButton
                  type="submit"
                  size={SIZE.compact}
                  fullWidth
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </TextButton>
              </div>
            </div>
          </>
        </form>
      </Modal>
    </>
  );
};

export default OnSkipModal;
