export default function Plus({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M8.23248 9.52837H4.55875C4.40262 9.52837 4.27174 9.47445 4.16612 9.3666C4.0605 9.25874 4.00769 9.12509 4.00769 8.96565C4.00769 8.8062 4.0605 8.67263 4.16612 8.56494C4.27174 8.45726 4.40262 8.40341 4.55875 8.40341H8.23248V4.65341C8.23248 4.49404 8.28531 4.36044 8.39096 4.25263C8.49663 4.14482 8.62756 4.09091 8.78376 4.09091C8.93996 4.09091 9.07082 4.14482 9.17631 4.25263C9.28181 4.36044 9.33456 4.49404 9.33456 4.65341V8.40341H13.0083C13.1644 8.40341 13.2953 8.45734 13.4009 8.56519C13.5065 8.67305 13.5593 8.8067 13.5593 8.96614C13.5593 9.12559 13.5065 9.25916 13.4009 9.36684C13.2953 9.47453 13.1644 9.52837 13.0083 9.52837H9.33456V13.2784C9.33456 13.4378 9.28173 13.5713 9.17608 13.6792C9.07041 13.787 8.93947 13.8409 8.78328 13.8409C8.62707 13.8409 8.49622 13.787 8.39072 13.6792C8.28523 13.5713 8.23248 13.4378 8.23248 13.2784V9.52837Z"
        fill={color}
      />
    </svg>
  );
}
