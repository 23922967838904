export default function Payment({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_414_4155)">
        <g clip-path="url(#clip1_414_4155)">
          <g clip-path="url(#clip2_414_4155)">
            <path
              d="M15.409 6.30349L11.6965 2.59099C11.0812 1.98982 10.1426 2.02498 9.57656 2.59099C9.01054 3.15701 8.97187 4.10271 9.57656 4.71091L9.84023 4.97459L8.92265 5.89568L9.45351 6.42654L10.6383 5.24177C10.793 5.08709 10.7719 4.84802 10.6383 4.71091L10.1074 4.18006C9.80507 3.86716 9.82968 3.39607 10.1074 3.11834C10.3992 2.82654 10.8738 2.82654 11.1691 3.11834L14.8816 6.83084C15.1875 7.14724 15.1594 7.61482 14.8816 7.89256C14.5898 8.18435 14.1152 8.18435 13.8199 7.89256L13.2891 7.36521C13.1273 7.20701 12.8953 7.23162 12.7582 7.36521L11.5734 8.54998L12.1043 9.08084L13.0219 8.15974L13.2855 8.42341C13.9008 9.03162 14.8394 8.98943 15.4055 8.42341C15.9961 7.83982 15.9961 6.88709 15.409 6.30349ZM10.8105 10.3711L10.8738 10.3078L10.9055 10.2761L10.3711 9.7488L10.1039 10.016C10.023 10.0969 9.95273 10.2586 10.023 10.4238C10.6348 11.8476 10.3992 13.7672 9.29531 15.0433L2.95663 8.70115C4.3207 7.54451 6.10312 7.36873 7.57617 7.97693C7.71679 8.03318 7.87851 8.00154 7.98398 7.89607L8.25117 7.62888L7.72031 7.09802L7.62538 7.19295C5.72343 6.50037 3.56835 7.00662 2.14804 8.42693C1.98632 8.58865 2.01445 8.82068 2.14804 8.95779L5.32968 12.1394L2.94257 14.5265C2.78437 14.6883 2.80546 14.9203 2.94257 15.0574C3.09023 15.2051 3.32578 15.2051 3.47343 15.0574L5.86054 12.6703L9.04218 15.8519C9.17578 15.9855 9.41484 16.0066 9.57304 15.8519C11.0285 14.4105 11.4785 12.1816 10.8105 10.3711ZM14.2312 12.6703L13.9641 12.9375C13.8445 13.057 13.6512 13.057 13.5316 12.9375L4.77421 4.17654C4.65468 4.05701 4.65468 3.86365 4.77421 3.74412L5.0414 3.47693C5.16093 3.3574 5.35429 3.3574 5.47382 3.47693L14.2348 12.2379C14.3508 12.3574 14.3508 12.5508 14.2312 12.6703Z"
              fill={color}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_414_4155">
          <rect width={size} height={size} fill="white" />
        </clipPath>
        <clipPath id="clip1_414_4155">
          <rect width={size} height={size} fill="white" />
        </clipPath>
        <clipPath id="clip2_414_4155">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
