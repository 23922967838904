import React from "react";

const ClearFile = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8225 8.18354C14.5859 7.94691 14.2036 7.94691 13.967 8.18354L11 11.1445L8.03299 8.17747C7.79636 7.94084 7.41411 7.94084 7.17747 8.17747C6.94084 8.41411 6.94084 8.79636 7.17747 9.03299L10.1445 12L7.17747 14.967C6.94084 15.2036 6.94084 15.5859 7.17747 15.8225C7.41411 16.0592 7.79636 16.0592 8.03299 15.8225L11 12.8555L13.967 15.8225C14.2036 16.0592 14.5859 16.0592 14.8225 15.8225C15.0592 15.5859 15.0592 15.2036 14.8225 14.967L11.8555 12L14.8225 9.03299C15.0531 8.80243 15.0531 8.41411 14.8225 8.18354V8.18354Z"
        fill="#333860"
      />
    </svg>
  );
};

export default ClearFile;
