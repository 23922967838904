import React from "react";

function SuccessSheild() {
  return (
    <div>
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5275 0.793252L1.44417 2.60742C1.02417 2.79409 0.75 3.21409 0.75 3.67492V6.41658C0.75 9.65409 2.99 12.6816 6 13.4166C9.01 12.6816 11.25 9.65409 11.25 6.41658V3.67492C11.25 3.21409 10.9758 2.79409 10.5558 2.60742L6.4725 0.793252C6.175 0.659085 5.825 0.659085 5.5275 0.793252ZM4.41917 9.50242L2.90833 7.99158C2.68083 7.76408 2.68083 7.39658 2.90833 7.16909C3.13583 6.94159 3.50333 6.94159 3.73083 7.16909L4.83333 8.26575L8.26333 4.83575C8.49083 4.60825 8.85833 4.60825 9.08583 4.83575C9.31333 5.06325 9.31333 5.43075 9.08583 5.65825L5.24167 9.50242C5.02 9.72992 4.64667 9.72992 4.41917 9.50242Z"
          fill="#0FB158"
        />
      </svg>
    </div>
  );
}

export default SuccessSheild;
