import React from "react";

const ChevronRightCircle = ({ size = 22, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11.5" r="10.5" fill="white" stroke="#CDCED9" />
      <path
        d="M13.2751 11.5004C13.2751 11.5837 13.2616 11.6608 13.2345 11.7316C13.2074 11.8025 13.1605 11.8712 13.0939 11.9379L9.46884 15.5629C9.34802 15.6837 9.20219 15.7441 9.03135 15.7441C8.86051 15.7441 8.71468 15.6837 8.59385 15.5629C8.47302 15.4421 8.4126 15.2941 8.4126 15.1191C8.4126 14.9441 8.47302 14.7962 8.59385 14.6754L11.7689 11.5004L8.59385 8.32538C8.47302 8.20456 8.4126 8.05873 8.4126 7.88789C8.4126 7.71706 8.47302 7.57123 8.59385 7.4504C8.71468 7.32956 8.86259 7.26914 9.03759 7.26914C9.21259 7.26914 9.36051 7.32956 9.48135 7.4504L13.0939 11.0629C13.1605 11.1296 13.2074 11.1983 13.2345 11.2692C13.2616 11.34 13.2751 11.4171 13.2751 11.5004Z"
        fill={color}
      />
    </svg>
  );
};

export default ChevronRightCircle;
