import React from "react";
import Icon from "./Icon";
import Typography from "./Typography";

const TitleHead = ({ title = "", icon = "" }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        marginBottom: "10px",
      }}
    >
      {icon && <Icon icon={icon} />}
      {title && (
        <Typography type="h2" subType="medium">
          {title}
        </Typography>
      )}
    </div>
  );
};

export default TitleHead;
