import React from "react";

const payCardOutline = ({ size = 16, color = "333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.125 1.73081V10.2692C15.125 10.6481 14.9938 10.9688 14.7313 11.2313C14.4688 11.4938 14.1481 11.625 13.7692 11.625H2.23081C1.85194 11.625 1.53125 11.4938 1.26875 11.2313C1.00625 10.9688 0.875 10.6481 0.875 10.2692V1.73081C0.875 1.35194 1.00625 1.03125 1.26875 0.76875C1.53125 0.50625 1.85194 0.375 2.23081 0.375H13.7692C14.1481 0.375 14.4688 0.50625 14.7313 0.76875C14.9938 1.03125 15.125 1.35194 15.125 1.73081ZM2 3.303H14V1.73081C14 1.67306 13.9759 1.62019 13.9278 1.57219C13.8798 1.52406 13.8269 1.5 13.7692 1.5H2.23081C2.17306 1.5 2.12019 1.52406 2.07219 1.57219C2.02406 1.62019 2 1.67306 2 1.73081V3.303ZM2 5.697V10.2692C2 10.3269 2.02406 10.3798 2.07219 10.4278C2.12019 10.4759 2.17306 10.5 2.23081 10.5H13.7692C13.8269 10.5 13.8798 10.4759 13.9278 10.4278C13.9759 10.3798 14 10.3269 14 10.2692V5.697H2Z"
        fill={color}
      />
    </svg>
  );
};

export default payCardOutline;
