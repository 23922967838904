import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SelectBox, SIZE } from "../../../components/SelectBox";
import { useIntl } from "react-intl";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../../components/TextArea";
import { TextButton, KIND } from "../../../components/TextButton";
import moment from "moment";
import getSelectValues from "../../../utils/getSelectValues";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import {
  getCheckLocation,
  getRecurringInvoiceItem,
  postCheckIn,
  putCheckIn,
} from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import { TextBox } from "../../../components/TextBox";
import { TimePickerAction } from "../../../components/TimePicker/TimePicker";
import setSelectValues from "../../../utils/setSelectValues";
import { useQuery } from "react-query";
import { ADD_LIST_VALUES } from "../../../providers/RBACProvider/permissionList";

const CheckInForm = ({
  setIsAddNew,
  editingData,
  refetchCheckIn,
  setRefetchCheckIn,
  getItems,
  setEditingData,
}) => {
  let editingDatas = editingData && editingData.data && editingData.data[0];

  let intl = useIntl();
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);
  const getParams = getSearchParams.get("customers");

  const { data, isFetched, isError } = useQuery(
    [`CHECK_IN_LOCATION-${getParams}`],
    async ({ queryKey }) => {
      return await getCheckLocation({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  let LocationData = data && data.data && data.data.docs;

  const postCheckInDatas = useMutation(
    (data) =>
      postCheckIn({
        organization: currentOrganization,
        customerId: getParams,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();

        setIsAddNew(false);
        setRefetchCheckIn(refetchCheckIn + 1);
        setEditingData({});
      },
    }
  );
  const putCheckInDatas = useMutation(
    (data) =>
      putCheckIn({
        organization: currentOrganization,
        customerId: getParams,
        checkinId: editingDatas._id,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();

        setIsAddNew(false);
        setRefetchCheckIn(refetchCheckIn + 1);
      },
    }
  );
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let { checkin_date, checkin_time } = watch();

  const onSubmit = async (data) => {
    let { recurring_invoice_item_id, remarks } = data;
    // SPLITING HRS AND MIN STARTS
    let gettingHour = moment(data.checkin_time).get("hour");
    let gettingMinutes = moment(data.checkin_time).get("minutes");
    let settingDate = moment(data.checkin_date)
      .set({ hour: gettingHour, minutes: gettingMinutes })
      .toDate();
    // SPLITING HRS AND MIN ENDS
    delete data.checkin_date;
    delete data.checkin_time;
    //

    let location = getSelectValues(data.location);

    let values = {
      ...(editingData?.type == "EDIT"
        ? {}
        : {
            recurring_invoice_item_id: getSelectValues(
              recurring_invoice_item_id
            ),
          }),
      ...(location ? { location } : {}),
      checkin_at: moment(settingDate).utc().valueOf(),
      ...(remarks ? { remarks: remarks } : {}),
    };

    if (editingData?.type === "EDIT") {
      await putCheckInDatas.mutateAsync(values);
    } else {
      await postCheckInDatas.mutateAsync(values);
    }
  };

  useEffect(() => {
    // let selectFields = ["subscription_id", "checkin_time"];
    if (editingData && editingData.type === "EDIT" && editingDatas) {
      setValue(
        "recurring_invoice_item_id",
        setSelectValues(editingDatas.recurring_invoice_item_id)
      );
      setValue("checkin_date", editingDatas.checkin_at);
      setValue("checkin_time", editingDatas.checkin_at);
      setValue("location", setSelectValues(editingDatas.location));
      setValue("remarks", editingDatas.remarks);
    } else {
      reset();
      setValue("remarks", "");
    }
  }, [editingData]);
  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <form style={{ paddingBottom: "15px" }} onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "130px" }}>
          <Controller
            control={control}
            name="recurring_invoice_item_id"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "item",
                })}
                placeholder={intl.formatMessage({
                  id: "item",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                disabled={editingData?.type == "EDIT" ? true : false}
                options={
                  getItems &&
                  getItems.data &&
                  getItems.data.data &&
                  getItems.data.data.docs
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ name, _id }) => {
                      return { label: name, id: _id };
                    })
                }
              />
            )}
          />
        </div>
        <div style={{ width: "130px" }}>
          <Controller
            name="checkin_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                requiredAstric={true}
                // disabled={isDisabled}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "checkin_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                size={SIZE.mini}
                onChange={(e) => field.onChange(e.date)}
              />
            )}
          />
        </div>
        <div style={{ width: "130px" }}>
          <Controller
            control={control}
            name="checkin_time"
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <TimePickerAction
                {...field}
                name={field.name}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "checkin_time",
                })}
                placeholder={intl.formatMessage({
                  id: "checkin_time",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </div>
        {/* <div style={{ width: "130px" }}>
          <Controller
            control={control}
            name="time"
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "time",
                })}
                placeholder={intl.formatMessage({
                  id: "time",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
              />
            )}
          />
        </div> */}
      </div>
      <div style={{ width: "285px" }}>
        <Controller
          control={control}
          name="location"
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              creatable={creatablePermission ? true : false}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "location",
              })}
              placeholder={intl.formatMessage({
                id: "location",
              })}
              size={SIZE.mini}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
              }}
              options={
                LocationData &&
                LocationData?.sort((a, b) =>
                  a.label.localeCompare(b.label)
                ).map((e) => {
                  return { id: e.id, label: e.label };
                })
                // .sort((a, b) => a.name.localeCompare(b.name))
                // ?.map((e) => {
                //   return { id: e._id, label: e.name };
                // })
                // }
              }
            />
          )}
        />
      </div>
      <div>
        <Controller
          control={control}
          name="remarks"
          render={({ field }) => (
            <TextArea
              {...field}
              name={field.name}
              label={intl.formatMessage({
                id: "remarks",
              })}
              placeholder={intl.formatMessage({
                id: "remarks",
              })}
              size={SIZE.mini}
              value={field.value}
            />
          )}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // paddingTop: "25px",
        }}
      >
        <div
          style={{
            width: "80px",
            paddingRight: "10px",
          }}
        >
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              reset();
              setEditingData({});
              setIsAddNew(false);
            }}
          >
            Cancel
          </TextButton>
        </div>
        <div style={{ width: "80px" }}>
          <TextButton
            kind={KIND.primary}
            size={SIZE.mini}
            isLoading={isSubmitting}
            fullWidth
            type="submit"
          >
            Capture
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default CheckInForm;
