export const RQ_INF_ATTACHMENTS = "RQ_INF_ATTACHMENTS";
export const RQ_INF_REMOVE_ATTACHMENT = "RQ_INF_REMOVE_ATTACHMENT";
export const RQ_INF_UPDATE_ATTACHMENT = "RQ_INF_UPDATE_ATTACHMENT";
export const RQ_INF_CHART = "RQ_INF_CHART";
export const RQ_INF_RESET = "RQ_INF_RESET";

export const requestInformationAttachment = (data) => {
  return {
    type: RQ_INF_ATTACHMENTS,
    payload: data,
  };
};

export const requestInformationRemoveAttachment = (data) => {
  return {
    type: RQ_INF_REMOVE_ATTACHMENT,
    payload: data,
  };
};

export const requestInformationUpdateAttachment = (data) => {
  return {
    type: RQ_INF_UPDATE_ATTACHMENT,
    payload: data,
  };
};

export const requestInformationChart = (data) => {
  return {
    type: RQ_INF_CHART,
    payload: data,
  };
};

export const requestInformationReset = (data) => {
  return {
    type: RQ_INF_RESET,
    payload: data,
  };
};
