import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import "moment-timezone";
import { useNumericFormat } from "react-number-format";
import { useContext } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { HeadingXSmall, LabelMedium, ParagraphMedium } from "baseui/typography";

const CustomFields = ({ customFields = {}, fieldLevel = [] }) => {
  const {
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  let result = {};
  for (let obj of fieldLevel) {
    let section = obj.section;
    if (result.hasOwnProperty(section)) {
      result[section].push({ ...obj });
    } else {
      result[section] = [{ ...obj }];
    }
  }

  return (
    <>
      {Object.keys(result)?.map((cus, i) => {
        return (
          <>
            <LabelMedium $style={{ marginTop: "15px", marginBottom: "5px" }}>
              {cus}
            </LabelMedium>
            <div
              style={{
                display: "flex",
                gap: "15px",
                flexWrap: "wrap",
                alignItems: "flex-start",
              }}
            >
              {result &&
                result[cus].map((e) => {
                  let value = _.get(customFields, `custom_field.${e.path}`, "");

                  return (
                    !e.is_hidden && (
                      <div style={{ width: "280px" }}>
                        <HeadingXSmall
                          $style={{ color: "#ADADAD", lineHeight: "16px" }}
                        >
                          {e.name}
                        </HeadingXSmall>
                        <ParagraphMedium
                          $style={{ color: "#333860", lineHeight: "17px" }}
                        >
                          {e.data_type === "AMOUNT" ? (
                            numberFormat.format(
                              value.value
                                ? `${value.currency} ${value.value}`
                                : "-"
                            )
                          ) : e.data_type === "DATE" ? (
                            value ? (
                              moment
                                .utc(value)
                                ?.tz(currentDefaultFormatDetails.time_zone)
                                ?.format(
                                  currentDefaultFormatDetails?.date_format
                                )
                            ) : (
                              "-"
                            )
                          ) : e.data_type == "HYPERLINK" ? (
                            value ? (
                              <a
                                href={value}
                                target="_blank"
                                style={{ textDecoration: "underline" }}
                              >
                                {value}
                              </a>
                            ) : (
                              "-"
                            )
                          ) : e.data_type == "TEXT" ? (
                            value ? (
                              value
                            ) : (
                              "-"
                            )
                          ) : e.data_type == "LONG_TEXT" ? (
                            value ? (
                              value
                            ) : (
                              "-"
                            )
                          ) : e.data_type == "NUMBER" ? (
                            value ? (
                              value
                            ) : (
                              "-"
                            )
                          ) : (
                            "-"
                          )}
                        </ParagraphMedium>
                      </div>
                    )
                  );
                })}
            </div>
          </>
        );
      })}
    </>
  );
};

export default CustomFields;
