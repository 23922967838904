import { ParagraphMedium } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";

export const CreditLimitStatusType = ({
  creditLimitStatus,
  creditLimitPercentage,
  column,
}) => {
  switch (creditLimitStatus) {
    case "BREACHED":
      return (
        <>
          {column.width < 100 ? (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={`${creditLimitPercentage}%`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    height: "8px",
                    width: "9.5px",
                    borderRadius: "50px",
                    backgroundColor: "#FD372A",
                  }}
                ></div>
                <div
                  style={{ color: "#757575", display: "flex", gap: "2px" }}
                  className={`${column.width < 100 ? "elip" : ""}`}
                >
                  <div style={{ color: "#757575" }}>Breached</div>
                  <div className={`${column.width < 100 ? "elip" : ""}`}>
                    {creditLimitPercentage}%
                  </div>
                </div>
              </div>
            </StatefulTooltip>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "50px",
                    backgroundColor: "#FD372A",
                  }}
                ></div>
                <div
                  style={{ color: "#757575", display: "flex", gap: "2px" }}
                  className={`${column.width < 100 ? "elip" : ""}`}
                >
                  <div style={{ color: "#757575" }}>Breached</div>
                  <div className={`${column.width < 100 ? "elip" : ""}`}>
                    {creditLimitPercentage}%
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    case "WARNING":
      return (
        <>
          {column.width < 100 ? (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={`${creditLimitPercentage}%`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "50px",
                    backgroundColor: "#FBBD04",
                  }}
                ></div>
                <div
                  style={{ color: "#757575", display: "flex", gap: "2px" }}
                  className={`${column.width < 100 ? "elip" : ""}`}
                >
                  <div style={{ color: "#757575" }}>Warning</div>
                  <div className={`${column.width < 100 ? "elip" : ""}`}>
                    {creditLimitPercentage}%
                  </div>
                </div>
              </div>
            </StatefulTooltip>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "50px",
                    backgroundColor: "#FBBD04",
                  }}
                ></div>
                <div
                  style={{ color: "#757575", display: "flex", gap: "2px" }}
                  className={`${column.width < 100 ? "elip" : ""}`}
                >
                  <div style={{ color: "#757575" }}>Warning</div>
                  <div className={`${column.width < 100 ? "elip" : ""}`}>
                    {creditLimitPercentage}%
                  </div>
                </div>
              </div>
            </>
          )}

          {/* <ParagraphMedium style={{ color: "#757575" }}>
            Warning {creditLimitPercentage}%
          </ParagraphMedium> */}
        </>
      );
    case "GOOD":
      return (
        <>
          {/* <ParagraphMedium style={{ color: "#757575" }}>
            Good
            {creditLimitPercentage}%
          </ParagraphMedium> */}

          {column.width < 100 ? (
            <StatefulTooltip
              accessibilityType={"tooltip"}
              content={`${creditLimitPercentage}%`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "50px",
                    backgroundColor: "#0FB158",
                  }}
                ></div>
                <div
                  style={{ color: "#757575", display: "flex", gap: "2px" }}
                  className={`${column.width < 100 ? "elip" : ""}`}
                >
                  <div style={{ color: "#757575" }}>Good</div>
                  <div className={`${column.width < 100 ? "elip" : ""}`}>
                    {creditLimitPercentage}%
                  </div>
                </div>
              </div>
            </StatefulTooltip>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    height: "8px",
                    width: "8px",
                    borderRadius: "50px",
                    backgroundColor: "#0FB158",
                  }}
                ></div>
                <div
                  style={{ color: "#757575", display: "flex", gap: "2px" }}
                  className={`${column.width < 100 ? "elip" : ""}`}
                >
                  <div style={{ color: "#757575" }}>Good</div>
                  <div className={`${column.width < 100 ? "elip" : ""}`}>
                    {creditLimitPercentage}%
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    default:
      return null;
  }
};

const CreditLimitStatus = (props) => {
  let {
    row: { original },
    column,
  } = props;

  let creditLimitStatus = original && original.credit_limit_status;
  let creditLimitPercentage = original && original.credit_limit_breach_perc;

  return (
    <CreditLimitStatusType
      creditLimitStatus={creditLimitStatus}
      creditLimitPercentage={creditLimitPercentage}
      column={column}
    />
  );
};

export default CreditLimitStatus;
