import React from "react";

const PortalTLSvg = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.2349 18.123C18.412 18.3753 18.3503 18.7236 18.098 18.8999C17.8457 19.077 17.4974 19.0153 17.3211 18.763L16.1162 17.0425C15.9391 16.7902 16.0009 16.4419 16.2532 16.2648C16.5054 16.0884 16.8537 16.1495 17.0301 16.4025L18.2349 18.123Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5436 14.9349C18.8569 15.1157 19.034 15.464 18.9946 15.8234C18.9552 16.1821 18.7073 16.4842 18.3628 16.5929L16.8246 17.0781L15.843 18.3573C15.6235 18.6438 15.2544 18.7733 14.9039 18.6877C14.5526 18.6014 14.2855 18.3164 14.2229 17.9607L13.4803 13.7412C13.4185 13.3922 13.5651 13.0394 13.8546 12.8363C14.1448 12.6339 14.5266 12.6168 14.8332 12.7939L18.5436 14.9349Z"
        fill="#516beb"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 7.09296V5.41857C3 4.08277 4.08277 3 5.41857 3H16.5812C17.2227 3 17.8381 3.25451 18.2913 3.70845C18.7453 4.16166 18.9998 4.77709 18.9998 5.41857V7.09296H3ZM18.9998 8.20922V14.3442C18.9722 14.3263 18.9447 14.3092 18.9157 14.2929L15.2045 12.1519C14.6478 11.8304 13.955 11.8609 13.4281 12.2293C12.9012 12.5984 12.6356 13.2391 12.7472 13.8724L13.4899 18.0919C13.5524 18.4461 13.7258 18.7609 13.9743 18.9998H5.41857C4.08277 18.9998 3 17.917 3 16.5812V8.20922H18.9998ZM5.9767 4.67439C5.66861 4.67439 5.41857 4.92444 5.41857 5.23252C5.41857 5.54061 5.66861 5.79066 5.9767 5.79066C6.28479 5.79066 6.53483 5.54061 6.53483 5.23252C6.53483 4.92444 6.28479 4.67439 5.9767 4.67439ZM7.83714 4.67439C7.52905 4.67439 7.27901 4.92444 7.27901 5.23252C7.27901 5.54061 7.52905 5.79066 7.83714 5.79066C8.14523 5.79066 8.39527 5.54061 8.39527 5.23252C8.39527 4.92444 8.14523 4.67439 7.83714 4.67439ZM9.69757 4.67439C9.38949 4.67439 9.13944 4.92444 9.13944 5.23252C9.13944 5.54061 9.38949 5.79066 9.69757 5.79066C10.0057 5.79066 10.2557 5.54061 10.2557 5.23252C10.2557 4.92444 10.0057 4.67439 9.69757 4.67439Z"
        fill="#516beb"
      />
    </svg>
  );
};

export default PortalTLSvg;
