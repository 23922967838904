import React, { useContext } from "react";
import Download from "../../assets/img/svg/Download";
import { useRBAC, permission } from "../../providers/RBACProvider";
import { downloadAdhocTemplateData } from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { TextButton, SIZE, KIND } from "../../components/TextButton";

function DownloadOverview() {
  const { isAllowed } = useRBAC();
  let { currentOrganization, referenceData, users } = useContext(MaxyfiContext);

  return (
    <>
      {isAllowed(permission.DOWNLOAD_VIEW_DATA) ? (
        <>
          <TextButton
            style={{ width: "38px", height: "34px" }}
            size={SIZE.mini}
            kind={KIND.secondary}
            onClick={() =>
              downloadAdhocTemplateData({ organization: currentOrganization })
            }
          >
            <Download color="#516BEB" />
          </TextButton>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default DownloadOverview;
