import {
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import warningImage from "../../../assets/img/warning.png";
import {
  gettingId,
  editDataForm,
} from "../../../redux/customerDairyNote/action";
import { ModalContext } from "../../../providers/ModalProvider";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { toast } from "react-toastify";
import { Icon, RDText, Typography } from "../../../components_v2";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { useMutation } from "react-query";
import { editDairyNote, markAsCompleteDairyNote } from "../../../services";
import { MARK_COMPLETE_CUSTOMER_NOTES } from "../../../providers/RBACProvider/permissionList";
import _ from "lodash";

const NoteCard = (props) => {
  let {
    data,
    findFullWidth,
    editNotePermission,
    deleteNotePermission,
    entity,
  } = props;

  let contentVal = "";

  if (data.notes.length > 150) {
    contentVal = data.notes?.replace(/(<([^>]+)>)/gi, "").slice(0, 150);
  }

  const { id } = useParams();

  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);
  let {
    referenceData,
    currentOrganization,
    users,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const categoryFilter = referenceData["notes_category"].find(
    ({ id }) => id === data.category
  );

  const legalFields = referenceData["legal_action_fields"].find(
    ({ id }) => id === data.lty
  );

  //  Card Collapse
  const [expand, setExpand] = React.useState(false);
  const expanToggle = () => {
    setExpand(!expand);
  };

  // Switch Component for Switching the SVG

  const SwitchSvg = ({ type, dateSvg }) => {
    switch (type) {
      case "INFORMATIONAL":
        return <Icon icon="flag" color="#516beb" size={18} />;

      case "CRITICAL":
        return <Icon icon="flag" color="#FD372A" size={18} />;

      case "ALERT":
        return <Icon icon="flag" color="#FBBD04" size={18} />;

      default:
        return null;
    }
  };

  // let dateFormate = currentDefaultFormatDetails.date_format;

  //   Switch Component Text
  const SwitchStatus = ({ type, dateData, data }) => {
    //
    //
    let isDate = dateData ? true : false;
    // const localDate = moment
    //   .utc(dateData)
    //   .format(currentDefaultFormatDetails.date_format);
    let localDate =
      isDate &&
      utcTimstampToLocalDate(dateData, currentDefaultFormatDetails.date_format);

    switch (type) {
      case "INFORMATIONAL":
        return (
          <div
            className="notes_category_alert_info"
            style={{
              gap: legalFields && legalFields.id === data ? "5px" : "1px",
            }}
          >
            <SwitchSvg type={type} />
            <LabelMedium
              $style={{
                fontWeight: "400",
                color: "#787878",
              }}
            >
              {legalFields?.label}
            </LabelMedium>
            {legalFields && legalFields.id === data ? (
              <>
                <Icon icon="dot" color="#D3D3D7" size={6} />
              </>
            ) : (
              <></>
            )}
            <LabelMedium
              $style={{
                fontWeight: "400",
                color: "#787878",
              }}
            >
              {categoryFilter?.label}
            </LabelMedium>
            {/* <ParagraphSmall>Informational</ParagraphSmall> */}
          </div>
        );

      case "ALERT":
        return (
          <div className="notes_category_alert_alert">
            {" "}
            <SwitchSvg type={type} />
            <LabelMedium
              $style={{
                fontWeight: "400",
                color: "#787878",
              }}
            >
              {legalFields?.label}
            </LabelMedium>
            {legalFields?.label && (
              <>
                <Icon icon="dot" color="#D3D3D7" size={6} />
              </>
            )}
            <LabelMedium
              $style={{
                fontWeight: "500",
                color: "#787878",
              }}
            >
              {categoryFilter?.label}
            </LabelMedium>
            {/* <ParagraphSmall>Alert</ParagraphSmall> */}
          </div>
        );

      case "CRITICAL":
        return (
          <div className="notes_category_alert_critical">
            {" "}
            <SwitchSvg type={type} />
            <LabelMedium
              $style={{
                fontWeight: "400",
                color: "#787878",
              }}
            >
              {legalFields?.label}
            </LabelMedium>
            {legalFields?.label && (
              <>
                <Icon icon="dot" color="#D3D3D7" size={6} />
              </>
            )}
            <LabelMedium
              $style={{
                fontWeight: "400",
                color: "#787878",
              }}
            >
              {categoryFilter?.label}
            </LabelMedium>
            {/* <ParagraphSmall>Critical</ParagraphSmall> */}
          </div>
        );

      default:
        return <div>-</div>;
    }
  };

  let noteDate = data.modified_at ? data.modified_at : data.created_at;

  let noteLocalDate =
    noteDate &&
    utcTimstampToLocalDate(noteDate, currentDefaultFormatDetails.date_format);

  let noteUser = data.modified_by ? data.modified_by : data.created_by;
  let noteUserRef = users.find((e) => e.id === noteUser);

  let noteComp = _.get(data, "status_updated_by");
  let noteCompUserRef = users.find((e) => e.id === noteComp);

  const noteCompDate = moment
    .tz(data.status_updated_at, currentDefaultFormatDetails.time_zone)
    .format(currentDefaultFormatDetails.date_format);

  const localDate = moment
    .tz(data.follow_up_date, currentDefaultFormatDetails.time_zone)
    .format(currentDefaultFormatDetails.date_format);

  const updateDiaryNoteMutation = useMutation(
    (data) =>
      markAsCompleteDairyNote({
        ...data,
      }),
    {
      onSuccess: () => {
        props.refetch();
      },
    }
  );

  return (
    <>
      <div className="dairy-ovr dairy_notes_card_wraper">
        <div
          style={{
            display: "flex",
          }}
        >
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* Right Side BOX DIV First */}
              <div style={{ padding: "0px 12px 0px 12px" }}>
                <div className="notes_card_head_wraper">
                  <SwitchStatus
                    type={data.type}
                    dateData={data.follow_up_date}
                    data={data.lty}
                  />
                  <div className="notes_card_icon_date_container">
                    <HeadingXSmall
                      $style={{ color: "#3787878", lineHeight: "20px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                        }}
                      >
                        <div>
                          <Icon icon="user_outLine" color="#ADADAD" size={18} />
                        </div>
                        <div>
                          <ParagraphMedium
                            $style={{
                              color: "#ADADAD",
                              lineHeight: "14.4px",
                              fontWeight: "400",
                            }}
                          >
                            {noteUserRef && noteUserRef.displayName
                              ? noteUserRef.displayName
                              : data?.created_by_name}
                            {","}
                          </ParagraphMedium>
                        </div>
                        <div>
                          <ParagraphMedium
                            $style={{
                              color: "#ADADAD",
                              lineHeight: "14.4px",
                              fontWeight: "400",
                            }}
                          >
                            {moment(noteLocalDate).format(
                              currentDefaultFormatDetails.date_format
                            )}
                          </ParagraphMedium>
                        </div>
                      </div>
                      {/* {data.follow_up_date ? "on " : ""}
                      {data.follow_up_date ? localDate : <></>} */}
                    </HeadingXSmall>
                    <div className="notes-card-btn">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {/* <div>
                          <p>hee</p>
                        </div> */}
                        <RBACWrapper
                          role={editNotePermission}
                          type={"PERMISSION"}
                        >
                          <Icon
                            icon="edit_outline"
                            color="#adadad"
                            size={18}
                            isPressable
                            onClick={() => {
                              if (findFullWidth && findFullWidth.current) {
                                findFullWidth.current.scrollTop = 50;
                              }
                              dispatch(editDataForm(data));
                              dispatch(gettingId(data._id));
                            }}
                          />
                        </RBACWrapper>

                        <RBACWrapper
                          role={deleteNotePermission}
                          type={"PERMISSION"}
                        >
                          <Icon
                            icon="delete_outline"
                            color="#adadad"
                            size={18}
                            isPressable
                            onClick={() => {
                              dispatch(gettingId(data._id));
                              handleModal({
                                title: "You are about to delete a Dairy Note",
                                content:
                                  "This will delete your Diary Note. Are you sure?",
                                successCallback: () => {
                                  props.deleteDairyData(
                                    {
                                      customerId: props.getClientId,
                                      _id: data._id,
                                      organization: currentOrganization,
                                      entity,
                                    },
                                    {
                                      onError: (error, variables, context) => {
                                        if (error.response) {
                                          let { data } = error.response;
                                          let errorData = data.message;
                                          toast.error(errorData);
                                        }
                                      },
                                      onSuccess: (data, variables, context) => {
                                        props.refetch();
                                      },
                                    }
                                  );
                                },
                                formContent: ({ handleModal }) => {
                                  return <></>;
                                },
                                cancelCallback: () => {},
                                isChildren: false,
                                image: warningImage,
                                buttonText: "Delete",
                                closeButtonText: "Cancel",
                                isCloseAble: true,
                              });
                            }}
                          />
                        </RBACWrapper>
                      </div>
                    </div>
                  </div>
                </div>
                {expand ? (
                  <div>
                    <ParagraphMedium className="on-expand">
                      {data.notes?.replace(/(<([^>]+)>)/gi, "")}

                      <span
                        style={{ color: "#516beb", cursor: "pointer" }}
                        onClick={() => expanToggle()}
                      >
                        {" "}
                        see less
                      </span>
                    </ParagraphMedium>
                  </div>
                ) : (
                  <>
                    <ParagraphMedium
                      className="word-space"
                      $style={{
                        wordBreak: "break-word",
                        color: "#333860",
                      }}
                    >
                      {data.notes.length > 150
                        ? contentVal
                        : data.notes?.replace(/(<([^>]+)>)/gi, "")}

                      <span
                        style={{ color: "#516beb", cursor: "pointer" }}
                        onClick={() => expanToggle()}
                      >
                        {data.notes?.replace(/(<([^>]+)>)/gi, "").length >
                          150 && " see more"}
                      </span>
                    </ParagraphMedium>
                  </>
                )}
              </div>

              {/* Right Side BOX DIV Second */}
              {/* {data && data.follow_up_date && ( */}
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop:
                      data && data.follow_up_date ? "1px solid #EEEEEE" : "",
                    // backgroundColor: "#FAFAFA",
                    padding: "3px 12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <ParagraphMedium
                      $style={{
                        color: "#ADADAD",
                        lineHeight: "15.6px",
                        fontWeight: "400",
                      }}
                    >
                      {data && data.follow_up_date && (
                        <>
                          {data.follow_up_date ? "Follow-up on " : ""}
                          {data.follow_up_date ? localDate : <></>}
                        </>
                      )}
                    </ParagraphMedium>
                    {/* <ParagraphMedium
                    $style={{
                      color: "#ADADAD",
                      lineHeight: "16px",
                    }}
                  >
                    Created By:
                  </ParagraphMedium>
                  <ParagraphMedium
                    $style={{
                      color: "#333860",
                      lineHeight: "16px",
                    }}
                  >
                    {noteUserRef && noteUserRef.displayName
                      ? noteUserRef.displayName
                      : data?.created_by_name}
                  </ParagraphMedium>
                  <ParagraphMedium
                    $style={{
                      color: "#333860",
                      lineHeight: "16px",
                    }}
                  >
                    {moment(noteLocalDate).format(
                      currentDefaultFormatDetails.date_format
                    )} */}
                    {/* </ParagraphMedium> */}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RBACWrapper
                      role={MARK_COMPLETE_CUSTOMER_NOTES}
                      type="PERMISSION"
                    >
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                          }}
                        >
                          {data && data.follow_up_date && (
                            <>
                              <ParagraphMedium
                                $style={{
                                  color: "#333860",
                                  lineHeight: "16px",
                                }}
                              >
                                Mark as Complete
                              </ParagraphMedium>
                              <CheckBoxBaseweb
                                checked={data.is_completed}
                                onChange={(e) => {
                                  updateDiaryNoteMutation.mutateAsync({
                                    action: "COMPLETE_NOTE",
                                    id: props.getClientId,
                                    noteId: data._id,
                                    organization: currentOrganization,
                                    entity,
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      </>
                    </RBACWrapper>
                  </div>
                </div>
              </>
              {/* )} */}

              <div style={{ backgroundColor: "#FAFAFA", textAlign: "center" }}>
                {data?.status_updated_by ? (
                  <div style={{ padding: "5px 10px" }}>
                    <Typography
                      subType="regular"
                      style={{
                        color: "#ADADAD",
                        lineHeight: "14.4px",
                      }}
                    >
                      Marked as Complete by{" "}
                      <RDText id={data?.status_updated_by} rdName={"users"} />{" "}
                      on {noteCompDate}
                    </Typography>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default NoteCard;
