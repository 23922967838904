import React from "react";

export default function RequestInformation({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0007 3C6.58788 3 3.00068 6.5904 3.00068 11C3.00068 12.4688 3.40066 13.8992 4.16228 15.1504L3.01668 18.5808C2.9783 18.6928 3.00708 18.8208 3.09348 18.9072C3.15429 18.968 3.23748 19 3.32067 19C3.35269 19 3.38788 18.9968 3.41988 18.984L6.85349 17.8416C8.10147 18.6 9.53187 19 11.0007 19C15.4103 19 19.0007 15.4128 19.0007 11C19.0007 6.5904 15.4103 3 11.0007 3ZM12.0183 15.7616C12.0183 15.9376 11.8775 16.0816 11.6983 16.0816H10.3031C10.1239 16.0816 9.98307 15.9376 9.98307 15.7616V14.3632C9.98307 14.1872 10.1239 14.0432 10.3031 14.0432H11.6983C11.8775 14.0432 12.0183 14.1872 12.0183 14.3632V15.7616ZM12.1463 11.8736C12.0823 11.896 12.0183 11.9888 12.0183 12.1168V12.6736C12.0183 12.8528 11.8775 12.9936 11.6983 12.9936H10.3031C10.1239 12.9936 9.98307 12.8528 9.98307 12.6736V12.1168C9.98307 11.176 10.5463 10.3184 11.3879 9.9792C11.8711 9.78719 12.1335 9.29118 12.0247 8.76958C11.9415 8.37919 11.6279 8.06558 11.2375 7.98239C10.9079 7.91518 10.5911 7.98877 10.3415 8.19358C10.0919 8.392 9.95107 8.68958 9.95107 9.00638C9.95107 9.1824 9.81029 9.32637 9.63109 9.32637H8.2359C8.05669 9.32637 7.91588 9.1824 7.91588 9.00638C7.91588 8.0752 8.32868 7.20159 9.05508 6.61278C9.77829 6.0208 10.7255 5.79359 11.6535 5.98877C12.8247 6.23198 13.7751 7.1824 14.0183 8.35359C14.3287 9.83518 13.5415 11.3136 12.1463 11.8736Z"
        fill={color}
      />
    </svg>
  );
}
