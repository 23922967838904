import React from "react";

const DownloadSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.0013 11.5495C7.90964 11.5495 7.82561 11.5342 7.74922 11.5036C7.67283 11.4731 7.59644 11.4196 7.52005 11.3432L4.05964 7.88281C3.92214 7.74531 3.85721 7.58108 3.86484 7.3901C3.87248 7.19913 3.94505 7.0349 4.08255 6.8974C4.22005 6.7599 4.38429 6.69115 4.57526 6.69115C4.76623 6.69115 4.93047 6.7599 5.06797 6.8974L7.3138 9.16615V1.35156C7.3138 1.15295 7.37873 0.988715 7.50859 0.858854C7.63845 0.728993 7.80269 0.664062 8.0013 0.664062C8.19991 0.664062 8.36415 0.728993 8.49401 0.858854C8.62387 0.988715 8.6888 1.15295 8.6888 1.35156V9.16615L10.9576 6.8974C11.0951 6.7599 11.2593 6.69115 11.4503 6.69115C11.6412 6.69115 11.8055 6.7599 11.943 6.8974C12.0805 7.0349 12.1492 7.19913 12.1492 7.3901C12.1492 7.58108 12.0805 7.74531 11.943 7.88281L8.48255 11.3432C8.40616 11.4196 8.32977 11.4731 8.25339 11.5036C8.177 11.5342 8.09297 11.5495 8.0013 11.5495ZM2.04297 15.3307C1.6763 15.3307 1.35547 15.1932 1.08047 14.9182C0.805469 14.6432 0.667969 14.3224 0.667969 13.9557V11.3661C0.667969 11.1675 0.732899 11.0033 0.862761 10.8734C0.992622 10.7436 1.15686 10.6786 1.35547 10.6786C1.55408 10.6786 1.71832 10.7436 1.84818 10.8734C1.97804 11.0033 2.04297 11.1675 2.04297 11.3661V13.9557H13.9596V11.3661C13.9596 11.1675 14.0246 11.0033 14.1544 10.8734C14.2843 10.7436 14.4485 10.6786 14.6471 10.6786C14.8457 10.6786 15.01 10.7436 15.1398 10.8734C15.2697 11.0033 15.3346 11.1675 15.3346 11.3661V13.9557C15.3346 14.3224 15.1971 14.6432 14.9221 14.9182C14.6471 15.1932 14.3263 15.3307 13.9596 15.3307H2.04297Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default DownloadSvg;
