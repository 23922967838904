import React, { useContext, useRef, useMemo, useState, useEffect } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Icon, Typography } from "../../components_v2";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useIntl } from "react-intl";
import {
  postDairyNote,
  getDairyNotes,
  useDeleteDairyNote,
  editDairyNote,
  getLegalNotes,
} from "../../services";
import { CO_TIMELINE_, LEGAL_ACTION_INFO_ } from "../../constants";
import NotesSelect from "../../components_v2/NotesSelect";
import { TextButton, KIND, SIZE } from "../../components/TextButton";
import { IconButton } from "../.././components/IconButton";
import {
  changeActionType,
  refetchDiaryNotes,
} from "../../redux/customerDairyNote/action";
import { SelectBox } from "../../components/SelectBox";
import Plus from "../../assets/img/svg/Upload/Plus";
import NoteCard from "./Notes/NoteCard";
import { DatePicker } from "baseui/datepicker";
import {
  DELETE_CUSTOMER_NOTES,
  EDIT_CUSTOMER_NOTES,
} from "../../providers/RBACProvider/permissionList";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import setSelectValues from "../../utils/setSelectValues";
import { NOTE_LIST_DRAWER, setDrawerState } from "../../redux/actions";

export default function Note({ result, isSectionActive, state }) {
  const editorRef = useRef(null);
  const queryClient = useQueryClient();
  const { customerId } = useParams();
  const BlockEmbed = ReactQuill.Quill.import("blots/embed");

  let dispatch = useDispatch();

  let intl = useIntl();
  const [noteSelectedValues, setNoteSelectedValues] = useState([
    { label: "All", id: "all" },
  ]);
  const [addNoteForm, setAddNoteForm] = useState(false);

  let typeOption = [
    {
      label: <Icon icon="flag" color="#516beb" size={18} />,
      id: "INFORMATIONAL",
    },
    { label: <Icon icon="flag" color="#FBBD04" size={18} />, id: "ALERT" },
    { label: <Icon icon="flag" color="#FD372A" size={18} />, id: "CRITICAL" },
  ];

  const {
    referenceData,
    currentOrganization,
    users,
    userInfo,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  let maxyfiContextValue = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      type: [{ id: "INFORMATIONAL" }],
      category: [{ id: "general", label: "General" }],
      lty: [{ id: "cmpt" }],
    },
  });

  let { category, is_enable_alert_and_follow_up } = watch();

  const actionValueType = useSelector(
    (s) => s.customerDairyNoteReducer.actionType
  );

  const reduxFormData = useSelector((s) => s.customerDairyNoteReducer.formData);
  const noteId = useSelector((s) => s.customerDairyNoteReducer.noteId);

  useEffect(() => {
    if (actionValueType === "EDIT") {
      setAddNoteForm(true);
      setValue("notes", reduxFormData && reduxFormData.notes);
      setValue("category", setSelectValues(reduxFormData.category));
      setValue("lty", [{ id: reduxFormData && reduxFormData.lty }]);
      setValue("type", [{ id: reduxFormData && reduxFormData.type }]);
      if (reduxFormData && reduxFormData.follow_up_date) {
        setValue(
          "follow_up_date",
          moment
            .tz(
              `${new Date(reduxFormData && reduxFormData.follow_up_date)}`,
              currentDefaultFormatDetails.time_zone
            )
            .utc()
            .valueOf()
        );
      }
    }
  }, [reduxFormData]);

  useEffect(() => {
    if (result && result[isSectionActive] && result[isSectionActive]?.pat) {
      setValue("lty", [{ id: result[isSectionActive]?.pat }]);
      setNoteSelectedValues([{ id: result[isSectionActive]?.pat }]);
    }
  }, [isSectionActive]);

  const { data, refetch } = useQuery(
    [
      `customer-notes-${customerId}`,
      {
        filter: {
          lty:
            noteSelectedValues &&
            noteSelectedValues[0] &&
            noteSelectedValues[0].id,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filter } = queryKey[1];
      return await getDairyNotes({
        organization: currentOrganization,
        customerId: customerId,
        entity: "customer",
        filter,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data && data.data && data.data.docs && data.data.docs.length === 0) {
      setAddNoteForm(true);
    }
  }, []);

  const addNote = useMutation(
    (values) =>
      postDairyNote({
        organization: currentOrganization,
        customerId,
        ...values,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (category && category[0] && category[0].isCreatable) {
          maxyfiContextValue.refetch({
            ...maxyfiContextValue,
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        refetch();
        dispatch(changeActionType());
        setAddNoteForm(!addNoteForm);
        queryClient.refetchQueries(`${CO_TIMELINE_}${customerId}`);
        queryClient.refetchQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
      },
    }
  );

  const updateLegalNote = useMutation(
    (data) =>
      editDairyNote({
        ...data,
        organization: currentOrganization,
        entity: "customer",
        id: customerId,
        noteId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        dispatch(changeActionType());
        reset();
        refetch();
        setAddNoteForm(!addNoteForm);
      },
    }
  );

  const onSubmit = async () => {
    //

    const data = getValues();

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.notes, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let value = {
      notes: data.notes,
      category:
        actionValueType === "EDIT"
          ? data && data.category && data.category[0] && data.category[0].id
          : data &&
            data.category &&
            data.category.map((val) => val.id).toString(),
      follow_up_date:
        data && data.follow_up_date && data.follow_up_date.date
          ? moment(data.follow_up_date.date).utc().valueOf()
          : data.follow_up_date
          ? moment(data.follow_up_date).utc().valueOf()
          : undefined,
      lty: data && data.lty && data.lty.map((val) => val.id).toString(),
      is_enable_alert_and_follow_up: false,
      mention_users: uniqueMentionedUsers,
      type:
        actionValueType === "EDIT"
          ? data && data.type && data.type[0] && data.type[0].id
          : data && data.type && data.type.map((val) => val.id).toString(),
    };

    if (actionValueType === "EDIT") {
      await updateLegalNote.mutateAsync({
        ...value,
      });
    } else {
      await addNote.mutateAsync({
        ...value,
      });
    }
  };
  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // TemplateMarker.blotName = "TemplateMarker";
  TemplateMarker.tagName = "span";

  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: 0,
      clipboard: {
        matchVisual: false,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        // blotName: "TemplateMarker",
        mentionDenotationChars: ["@"],
        dataAttributes: [
          "id",
          "value",
          "denotationChar",
          "link",
          "target",
          "disabled",
          "programmaticInsert",
        ],

        source: (searchTerm, renderList, mentionChar) => {
          const renderListSize = 5;

          let finalUsers = users
            ?.filter(
              (i) => i.organization?.includes(currentOrganization) && i.active
            )
            .filter((e) => e.id !== userInfo._id);

          let atValues = finalUsers
            ? finalUsers.map((i) => {
                return { value: i.displayName, id: i.id };
              })
            : [];

          let values;
          if (mentionChar === "@") {
            values = atValues;
          }

          if (searchTerm.length === 0) {
            renderList(values.slice(0, renderListSize), searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                values[i].value
                  .toLowerCase()
                  .indexOf(searchTerm.toLowerCase()) >= 0
              )
                matches.push(values[i]);

            renderList(matches.slice(0, renderListSize), searchTerm);
          }
        },
      },
    }),
    []
  );

  const { mutateAsync } = useDeleteDairyNote();

  let dateFormat =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails?.date_format &&
    currentDefaultFormatDetails?.date_format.replace(/dd|yyyy/gi, function (x) {
      return x.toLowerCase();
    });

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  const legalInfoNotes = useMemo(() => {
    referenceData &&
      referenceData["legal_action_fields"] &&
      referenceData["legal_action_fields"]?.map((item) => {
        if (item?.label === state.filter) {
          setNoteSelectedValues([item]);
        }
      });
  }, [state]);

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  return (
    <div>
      {addNoteForm ? (
        <>
          <div style={{ marginRight: "13px", marginTop: "15px" }}>
            <form className="co_note_container">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "70px" }}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <NotesSelect
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        clearable={false}
                        options={typeOption}
                        size={"mini"}
                      />
                    )}
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <Controller
                    name="lty"
                    control={control}
                    render={({ field }) => (
                      <NotesSelect
                        label="Select"
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        clearable={false}
                        options={
                          referenceData &&
                          referenceData["legal_action_fields_list"]?.sort(
                            (a, b) => a.label.localeCompare(b.label)
                          )
                        }
                        size={"mini"}
                      />
                    )}
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <Controller
                    name="category"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <NotesSelect
                        {...field}
                        value={field.value}
                        creatable={creatablePermission ? true : false}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        clearable={false}
                        options={
                          referenceData && referenceData["notes_category"]
                        }
                        size={"mini"}
                      />
                    )}
                  />
                </div>
                <div style={{ flex: "1" }}>
                  <Controller
                    name="follow_up_date"
                    control={control}
                    render={({ field }) => (
                      // <div style={{ marginBottom: "0px" }}>
                      <DatePicker
                        overrides={{
                          Input: {
                            props: {
                              overrides: {
                                Root: {
                                  style: () => ({
                                    marginLeft: "0",
                                    marginTop: "0",
                                    border: "none",
                                  }),
                                },
                                Input: {
                                  style: () => ({
                                    outline: `0px`,
                                    backgroundColor: "#ffffff",
                                    "::placeholder": {
                                      color: "#333860",
                                      fontSze: "12px",
                                      lineHeight: "14.4px",
                                    },
                                  }),
                                },
                              },
                            },
                          },
                        }}
                        placeholder="Follow-up"
                        size={"mini"}
                        value={field.value}
                        onChange={(e) => field.onChange(e.date)}
                        formatString={dateFormat}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="legal-notes-section">
                <Controller
                  name="notes"
                  rules={{ required: "Required" }}
                  control={control}
                  render={({ field }) => (
                    <ReactQuill
                      {...field}
                      name={field.name}
                      id={"editor"}
                      ref={editorRef}
                      className="note-ql-container"
                      theme="snow"
                      value={field.value}
                      modules={modules}
                      style={{ height: "134px" }}
                      placeholder="Type Notes"
                    />
                  )}
                />

                <div className="co-quick-notes_actions co-quick-notes_actions--enlarge">
                  <Icon
                    icon="enlarge"
                    size={18}
                    isPressable
                    onClick={() => {
                      setDrawer(NOTE_LIST_DRAWER);
                    }}
                  />
                </div>

                {/* <div> */}
                <div className="legal-notes-remove">
                  <Icon
                    icon="remove"
                    size={18}
                    isPressable
                    color="#FD372A"
                    onClick={() => {
                      setAddNoteForm(false);
                      reset();
                    }}
                  />
                </div>

                <div className="legal-notes-actions">
                  <Icon
                    icon="tick"
                    size={18}
                    isPressable
                    color="#0FB158"
                    onClick={async () => {
                      await onSubmit();
                    }}
                  />
                </div>
              </div>
              {/* </div> */}
            </form>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "129px" }}>
              <SelectBox
                placeholder="Select"
                value={noteSelectedValues}
                onChange={(e) => {
                  setNoteSelectedValues(e.value);
                }}
                clearable={false}
                options={
                  referenceData &&
                  referenceData["legal_action_fields"] &&
                  referenceData["legal_action_fields"].sort((a, b) =>
                    a.label.localeCompare(b.label)
                  )
                }
                size={"mini"}
              />
            </div>
            <div className="drawer_add_btn">
              <TextButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => {
                  dispatch(changeActionType());
                  setAddNoteForm(!addNoteForm);
                }}
              >
                <Plus size={18} />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "14.4px",
                  }}
                >
                  Add Note
                </span>
              </TextButton>
            </div>
          </div>
        </>
      )}

      <div
        style={{
          marginTop: addNoteForm ? "10px" : "",
          overflow: "hidden",
          paddingRight: "12px",
        }}
      >
        {data &&
          data.data &&
          data.data.docs &&
          data.data.docs.map((data) => (
            <NoteCard
              data={data}
              refetch={refetch}
              deleteDairyData={mutateAsync}
              getClientId={customerId}
              findFullWidth={editorRef}
              entity={"customer"}
              editNotePermission={EDIT_CUSTOMER_NOTES}
              deleteNotePermission={DELETE_CUSTOMER_NOTES}
            />
          ))}
      </div>
    </div>
  );
}
