import React, { useContext } from "react";
import BookDemo from "../components/BookDemoTopNav/BookDemo";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import SideBar from "./SideBar";
import SideBar_V2 from "./SideBar_V2/SideBar_V2";

function Layout({ sideBar = true, children }) {
  const { space } = useContext(MaxyfiContext);

  return (
    <>
      {space.app_message && <BookDemo />}

      <div
        className={`layout-container ${
          space.app_message && "--layout_modifier"
        }`}
      >
        {sideBar && <SideBar_V2 />} {children}
      </div>
    </>
  );
}

export default Layout;
