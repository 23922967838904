import { NEW } from "../../constants";

export const SET_GENERAL_DETAILS = "SET_GENERAL_DETAILS";
export const SET_TEMPLATE_LANGUAGE = "SET_TEMPLATE_LANGUAGE";
export const SET_TEMPLATE_CONTENT = "SET_TEMPLATE_CONTENT";
export const RESET_TEMPLATE = "RESET_TEMPLATE";
export const SET_TEMPLATE_VIEW_ONLY = "SET_TEMPLATE_VIEW_ONLY";
export const SET_ACTION_TYPE = "SET_ACTION_TYPE";
export const IS_MODAL = "IS_MODAL";
export const AT_DATA = "AT_DATA";
export const UPDATE_EVENT_TAG = "UPDATE_EVENT_TAG";
export const ADD_TEMPLATE_ATTACHMENT = "ADD_TEMPLATE_ATTACHMENT";
export const REMOVE_TEMPLATE_ATTACHMENT = "REMOVE_TEMPLATE_ATTACHMENT";
export const REFETCH_TEMPLATE = "REFETCH_TEMPLATE";
export const CURRENT_SELECTED_LANGUAGE = "CURRENT_SELECTED_LANGUAGE";
export const COMMUNTICATION_EMAIL_ATTACHMENT_FILE =
  "COMMUNTICATION_EMAIL_ATTACHMENT_FILE";
export const COMMUNTICATION_EMAIL_ATTACHMENT_FILE_REMOVE =
  "COMMUNTICATION_EMAIL_ATTACHMENT_FILE_REMOVE";
export const COMMUNTICATION_TEMPLATE_UNSELECTED_DOCUMENT_DATA =
  "COMMUNTICATION_TEMPLATE_UNSELECTED_DOCUMENT_DATA";
export const COMMUNTICATION_TEMPLATE_DOCUMENT_DRIVE_DATA =
  "COMMUNTICATION_TEMPLATE_DOCUMENT_DRIVE_DATA";
export const COMMUNTICATION_TEMPLATE_ADD_EMAIL_ATTACHMENT_FROM_DESKTOP =
  "COMMUNTICATION_TEMPLATE_ADD_EMAIL_ATTACHMENT_FROM_DESKTOP";
export const COMMUNTICATION_TEMPLATE_UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP =
  "COMMUNTICATION_TEMPLATE_UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP";

// for email attachment start here

export const updateEmailAttachmentFromDesktopTemplate = (payload) => {
  return {
    type: COMMUNTICATION_TEMPLATE_UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP,
    payload,
  };
};

export const unSelectedDocumentDataTemplate = (data) => {
  return {
    type: COMMUNTICATION_TEMPLATE_UNSELECTED_DOCUMENT_DATA,
    payload: data,
  };
};

export const documentDriveTemplate = (data) => {
  return {
    type: COMMUNTICATION_TEMPLATE_DOCUMENT_DRIVE_DATA,
    payload: data,
  };
};

export const addEmailAttachmentFromDesktopTemplate = (payload) => {
  return {
    type: COMMUNTICATION_TEMPLATE_ADD_EMAIL_ATTACHMENT_FROM_DESKTOP,
    payload,
  };
};

// for email attachment end here

export const currentSelectedLang = (data) => {
  return {
    type: CURRENT_SELECTED_LANGUAGE,
    payload: data,
  };
};

export const reftechTemplate = () => {
  return {
    type: REFETCH_TEMPLATE,
  };
};
export const atDatas = (atData) => {
  return {
    type: AT_DATA,
    payload: atData,
  };
};

export const updateEventTags = (eventTags) => {
  return {
    type: UPDATE_EVENT_TAG,
    payload: eventTags,
  };
};

export const addTemplateAttachment = (attachment) => {
  return {
    type: ADD_TEMPLATE_ATTACHMENT,
    payload: attachment,
  };
};

export const removeTemplateAttachment = (attachment) => {
  return {
    type: REMOVE_TEMPLATE_ATTACHMENT,
    payload: attachment,
  };
};

export const modalIsOpen = (modalState) => {
  return {
    type: IS_MODAL,
    payload: modalState,
  };
};

export const setGeneralDetails = (generalDetails) => {
  return {
    type: SET_GENERAL_DETAILS,
    payload: generalDetails,
  };
};

export const setTemplateLanguage = (language) => {
  return {
    type: SET_TEMPLATE_LANGUAGE,
    payload: language,
  };
};

export const setTemplateContent = (content) => {
  return {
    type: SET_TEMPLATE_CONTENT,
    payload: content,
  };
};

export const resetTemplate = () => {
  return {
    type: RESET_TEMPLATE,
    payload: null,
  };
};

export const setTemplateViewOnly = (viewOnly = false) => {
  return {
    type: SET_TEMPLATE_VIEW_ONLY,
    payload: viewOnly,
  };
};

export const setActionType = (type = NEW) => {
  return {
    type: SET_ACTION_TYPE,
    payload: type,
  };
};
export const communticationEmailAttachmentFile = (payload) => {
  return {
    type: COMMUNTICATION_EMAIL_ATTACHMENT_FILE,
    payload,
  };
};
export const EmailAttachmentRemove = (payload) => {
  return {
    type: COMMUNTICATION_EMAIL_ATTACHMENT_FILE_REMOVE,
    payload,
  };
};
