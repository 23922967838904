import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { Icon, Typography } from "../../components_v2";
import { markAsCompleteDairyNote } from "../../services";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import queryClient from "../../providers/queryClient";
import { CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../../constants";
import useFormat from "./../../hooks/useFormat";

const COAlertNote = (props) => {
  const { customerId } = useParams();
  const { currentOrganization, referenceData } = useContext(MaxyfiContext);
  const dismissNoteMutation = useMutation(
    (data) => markAsCompleteDairyNote(data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
        queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
      },
    }
  );
  const format = useFormat();

  const noteType = {
    ALERT: "info",
    CRITICAL: "error",
    INFORMATIONAL: "primary",
  };

  const type = _.get(props, "type");

  return (
    <div className="co-alert-card">
      <div className="co-alert-card_header">
        <div className="co-alert-card_header_info">
          <Icon
            icon="flag"
            className={
              noteType[type] ? `mx-icon--path-fill-${noteType[type]}` : ""
            }
            size={16}
          />
          <Typography
            className={noteType[type] ? `text-${noteType[type]}` : ""}
          >
            {_.capitalize(type)}
          </Typography>
          <Icon icon="dot" size={6} color="#ADADAD" isPressable />
          <Typography>
            {format.rd({
              id: _.get(props, "category", "-"),
              name: "notes_category",
            })}
          </Typography>
        </div>
        <Icon
          icon="remove"
          size={16}
          isPressable
          onClick={async () => {
            await dismissNoteMutation.mutateAsync({
              action: "DISMISS_NOTE",
              id: customerId,
              noteId: props._id,
              organization: currentOrganization,
            });
          }}
        />
      </div>
      <div className="co-alert-card_content">
        {_.get(props, "notes", "").replace(/(<([^>]+)>)/gi, "")}
      </div>
    </div>
  );
};

export default COAlertNote;
