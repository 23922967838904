export default function SkipAction({ size = 22, color = "#FD372A" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <path
        d="M17.7922 18.4778V4.12648C17.7922 3.81963 17.9003 3.55516 18.1166 3.3331C18.333 3.11103 18.593 3 18.8966 3C19.2002 3 19.46 3.11123 19.676 3.3337C19.892 3.55615 20 3.82152 20 4.1298V18.4778C20 18.792 19.8916 19.0602 19.6749 19.2823C19.4582 19.5044 19.1997 19.6154 18.8993 19.6154C18.5932 19.6154 18.3321 19.5044 18.1161 19.2823C17.9001 19.0602 17.7922 18.792 17.7922 18.4778ZM2 17.016V5.59522C2 5.18034 2.13505 4.84279 2.40515 4.58255C2.67528 4.32229 2.99041 4.19216 3.35055 4.19216C3.47699 4.19216 3.60294 4.20904 3.7284 4.24281C3.85384 4.27657 3.97472 4.33554 4.09106 4.41971L12.4367 10.1594C12.6412 10.2985 12.7931 10.4682 12.8923 10.6687C12.9914 10.8691 13.041 11.0826 13.041 11.3092C13.041 11.5357 12.9914 11.7487 12.8923 11.9482C12.7931 12.1477 12.6412 12.3168 12.4367 12.4557L4.09106 18.1879C3.97472 18.2773 3.85384 18.3375 3.7284 18.3687C3.60294 18.3998 3.47699 18.4154 3.35055 18.4154C2.99041 18.4154 2.67528 18.2864 2.40515 18.0285C2.13505 17.7706 2 17.4331 2 17.016Z"
        fill={color}
      />
    </svg>
  );
}
