import React from "react";

export default function NextAction({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2539 8.9089L3.52802 3.56688C3.20169 3.4025 2.81219 3.42032 2.50124 3.60899C2.18868 3.79686 2 4.1321 2 4.49164V15.1789C2 15.5376 2.18868 15.8721 2.50124 16.0607C2.66967 16.1628 2.86078 16.2146 3.05431 16.2146C3.21708 16.2146 3.37903 16.1773 3.52883 16.1028L10.2547 10.7592C10.5786 10.4547 10.8345 10.2264 10.8345 9.83365C10.8345 9.44091 10.6167 9.22957 10.2539 8.9089Z"
        fill={color}
      />
      <path
        d="M16.7315 8.9089L10.0056 3.56688C9.67929 3.4025 9.28898 3.42032 8.97804 3.60899C8.66547 3.79686 8.47679 4.1321 8.47679 4.49164V15.1789C8.47679 15.5376 8.66547 15.8721 8.97804 16.0607C9.14647 16.1628 9.33757 16.2146 9.5311 16.2146C9.69387 16.2146 9.85582 16.1773 10.0056 16.1028L16.7315 10.7592C17.0554 10.4547 17.3113 10.2264 17.3113 9.83365C17.3113 9.44091 17.0943 9.22957 16.7315 8.9089Z"
        fill={color}
      />
    </svg>
  );
}
