const Comment = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="22" height="22" fill="white" />
        <path
          d="M3.02894 19.0217L3.0104 8.77613C3.00467 5.60761 4.97853 3.02572 7.40997 3.02132L14.6571 3.0082C17.0891 3.0038 19.0728 5.57853 19.0786 8.74705L19.0818 10.5143C19.0875 13.6828 17.1136 16.2647 14.6822 16.2691L7.5362 16.282L3.02894 19.0217ZM7.41229 4.30253C5.52275 4.30595 3.98912 6.31202 3.99357 8.77435L4.00848 17.0118L7.31723 15.0005L14.6799 14.9872C16.5694 14.9837 18.1031 12.9777 18.0986 10.5153L18.0954 8.74814C18.0909 6.2858 16.5495 4.28531 14.6595 4.28873L7.41229 4.30253ZM7.74438 8.67023C8.26087 8.6693 8.68034 9.21389 8.68155 9.88694C8.68277 10.56 8.26528 11.1061 7.74879 11.107C7.23231 11.108 6.81284 10.5634 6.81162 9.89032C6.8104 9.21727 7.2279 8.67117 7.74438 8.67023ZM14.3437 8.65829C14.8602 8.65735 15.2797 9.20194 15.2809 9.875C15.2821 10.548 14.8646 11.0942 14.3481 11.0951C13.8316 11.096 13.4122 10.5514 13.4109 9.87838C13.4097 9.20533 13.8272 8.65922 14.3437 8.65829ZM11.0435 8.66426C11.56 8.66333 11.9795 9.20792 11.9807 9.88097C11.9819 10.554 11.5644 11.1001 11.0479 11.1011C10.5314 11.102 10.112 10.5574 10.1108 9.88435C10.1095 9.2113 10.527 8.6652 11.0435 8.66426Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export default Comment;
