import React, { useContext, useEffect, useState } from "react";
import {
  Label1,
  Label2,
  Paragraph1,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { TextArea } from "../../../../../../components/TextArea";
import { refetchActions } from "../../../../../../redux/actions";
import { deleteFieldVisit } from "../../../../../../services";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import {
  KIND,
  SIZE,
  TextButton,
} from "../../../../../../components/TextButton";
import queryClient from "../../../../../../providers/queryClient";
import { GETTING_FIELD_VISIT_ } from "../../../../../../constants";

const FieldVisitCancel = ({ getParams, handleModal, fieldId }) => {
  let dispatch = useDispatch();
  let intl = useIntl();

  let maxyfiContextValue = useContext(MaxyfiContext);

  let { currentOrganization } = maxyfiContextValue;

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const fieldVisitDelete = useMutation(
    (data) =>
      deleteFieldVisit({
        organization: currentOrganization,
        customerId: getParams,
        fieldVisitId: fieldId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        reset();
        dispatch(refetchActions());
        handleModal();
        queryClient.refetchQueries([`${GETTING_FIELD_VISIT_}-${getParams}`]);
      },
    }
  );

  const onSubmit = async (data) => {
    let value = { ...data };
    await fieldVisitDelete.mutateAsync({
      value,
    });
  };

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <TextArea
              size={SIZE.compact}
              {...field}
              name={field.name}
              label={intl.formatMessage({
                id: "reason",
              })}
              placeholder={intl.formatMessage({
                id: "reason",
              })}
              value={field.value}
            />
          )}
        />

        <div
          style={{
            // marginTop: "15px",
            textAlign: "center",
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
            // paddingBottom: "20px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.compact}
              kind={KIND.tertiary}
              // onClick={close}
              onClick={() => {
                // dispatch(workflowAddModal(false));
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.compact}
              kind={KIND.primary}
              isLoading={isSubmitting || fieldVisitDelete.isLoading}
              disabled={isSubmitting || fieldVisitDelete.isLoading}
            >
              Apply
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default FieldVisitCancel;
