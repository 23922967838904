import React, { useState } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import ReportDeliverySettingsTable from "./ReportDeliverySettingsTable";
import AddReportModal from "./AddReportModal";
import { NEW } from "../../constants";

const ReportDeliverySettings = () => {
  const [isOpen, setIsOpen] = useState({ isOpen: false, data: {}, type: NEW });
  return (
    <>
      <Layout>
        <div className="content-container">
          <div className="content-header">
            <div className="content-header__title">
              <PageTitle id="report_delivery_settings" />
            </div>
            <div className="content-header__actions">
              {/* <RBACWrapper role={ADD_HOLIDAY_BLACKLIST} type="PERMISSION"> */}
              <AddReportModal isOpen={isOpen} setIsOpen={setIsOpen} />
              {/* </RBACWrapper> */}
            </div>
          </div>
          <div className="content-body">
            <ReportDeliverySettingsTable
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ReportDeliverySettings;
