import React, { useContext, useEffect, useRef, useState } from "react";
import { Tag } from "baseui/tag";
import TagTick from "../../../assets/img/svg/CustomerOverViewIcon/TagTick";
import IconButton from "../../../components/IconButton/IconButton";
import TripleDot from "../../../assets/img/svg/TripleDot";
import Descending from "../../../assets/img/svg/Descending";
import { SIZE } from "baseui/input";
import { KIND } from "baseui/button";
import Letter from "../../../assets/img/svg/CustomerTemplate/Letter";
import { Label1, Label2, Paragraph2 } from "baseui/typography";
import DeliverTick from "../../../assets/img/svg/DeliverTick";
import ProfileIcon from "../../../assets/img/svg/ProfileIcon";
import {
  CUSTOMER_OVERVIEW,
  LETTER,
  EMAIL,
  SMS,
  INVOICE_OVERVIEW,
} from "../../../constants";
import CollapseUpArrow from "../../../assets/img/svg/CollapseUpArrow";
import CollapseDownArrow from "../../../assets/img/svg/CollapseDownArrow";
import { getCustomerOverviewTimelineList } from "../../../services/customerOverview";
import { useInfiniteQuery, useQuery } from "react-query";
import TemplateIcon from "../../communicationTemplate/TemplateIcon";
import Warning from "../../../assets/img/svg/Warning";
import TimelineBot from "../../../assets/img/timelinebot.png";
import Seen from "../../../assets/img/svg/Template/Seen";
import TagFile from "../../../assets/img/svg/TagFile";

import { StyledLink } from "baseui/link";
import InvoiceTimelineCard from "./InvoiceTimelineCard";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import ChipFilter from "../../../components/ChipFilter";
import TimelineMap from "../../CustomerOverView/COTabContainers/TimelineMap";
import { useParams, useSearchParams } from "react-router-dom";
import TimelineCreateAction from "../../CustomerOverView/COTabContainers/TimelineCreateAction";
import Loader from "../../../components/Loader";
// import TimelineMap from "./TimelineMap";
import Ascending from "../../../assets/img/svg/Ascending";
// import Descending from "../../../assets/img/svg/Descending";
import { useSelector } from "react-redux";
import NineDots from "../../../assets/img/svg/CustomerOverViewIcon/NineDots";
import ThreeLine from "../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/ThreeLine";
import TimelineTableMap from "../../CustomerOverView/COTabContainers/TimelineTableMap";

const InvoiceTimeline = ({ getParams, entity, customerId }) => {
  const timelineContainer = useRef(null);
  let { currentOrganization } = useContext(MaxyfiContext);
  // const { id } = useParams();
  // const [getSearchParams, setGetSearchParams] = useSearchParams();
  const ref = useRef(null);
  const [timelineData, setTimeLineData] = useState([]);
  const [tableView, setTableView] = useState(false);

  // const getParams = getSearchParams.get("inv");
  let [querySort, setQuerySort] = useState("-executed_on");
  let [queryFilter, setQueryFilter] = useState({});

  // const { data, isFetched, isLoading } = useQuery(
  //   [
  //     `${INVOICE_OVERVIEW}-${getParams}`,
  //     {
  //       refetchCount: refetchInvoiceActions,
  //       sort: querySort,
  //       filters: queryFilter,
  //     },
  //   ],
  //   async ({ queryKey }) => {
  //     let { page, page_size, type, sort, filters } = queryKey[1];
  //     return await getCustomerOverviewTimelineList({
  //       page,
  //       page_size,
  //       type,
  //       sort,
  //       organization: currentOrganization,
  //       id: getParams,
  //       entity: entity,
  //       ...filters,
  //     });
  //   },
  //   {
  //     refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const invoiceTimeLineList = useInfiniteQuery(
    // `${CUSTOMER_OVERVIEW}-TIMELINE-${getParams}`,
    [
      `TIMELINE_${INVOICE_OVERVIEW}_${getParams}`,
      {
        // refetchCount: refetchInvoiceActions,
        sort: querySort,
        filters: queryFilter,
      },
    ],
    async ({ queryKey, pageParam = 1 }) => {
      // let { filters, pageParam = 1 } = queryKey[1];
      let { pageIndex, sort, pageSize, filters, page } = queryKey[1];
      //

      return await getCustomerOverviewTimelineList({
        page: pageParam,
        organization: currentOrganization,
        id: getParams,
        sort,
        entity: entity,
        ...filters,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        if (allPages.length < maxPages) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );

  const onScroll = async (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (
      !invoiceTimeLineList.isFetchingNextPage &&
      scrollHeight - scrollTop <= clientHeight * 1.5
    ) {
      if (invoiceTimeLineList.hasNextPage)
        await invoiceTimeLineList.fetchNextPage({ cancelRefetch: false });
    }
  };

  useEffect(() => {
    const dev = ref.current;
    if (ref.current) {
      dev.addEventListener("scroll", onScroll);
    }
  }, [ref, ref.current, onScroll]);

  const updateQueryFilter = (activeFilters) => {
    if (activeFilters.length > 0) {
      setQueryFilter({
        type: [...activeFilters],
      });
    } else {
      setQueryFilter({});
    }
  };

  useEffect(() => {
    if (invoiceTimeLineList && invoiceTimeLineList.data) {
      let allData = [];

      invoiceTimeLineList &&
        invoiceTimeLineList.data &&
        invoiceTimeLineList.data.pages.map((e) => {
          allData.push(...(e && e.data && e.data.docs));
        });

      setTimeLineData(allData);
    }
  }, [invoiceTimeLineList && invoiceTimeLineList.data]);

  // if (data && data.data && data.data.docs && data.data.docs.length === 0) {
  //   return <TimelineCreateAction />;
  // }

  return (
    <div style={{ height: "100%" }}>
      <div className="cp-timeline-content cp-timeline-content--no-margin">
        <div className="cp-timeline__filters">
          <ChipFilter
            filters={[
              { label: "SMS", value: "sms" },
              { label: "Email", value: "email" },
              { label: "Letter", value: "letter" },
              { label: "Call", value: "call" },
              { label: "Invoice Update", value: "INVOICE_UPDATE" },
              { label: "Payment", value: "payment" },
            ]}
            setFilter={updateQueryFilter}
          />
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              paddingRight: "10px",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            {tableView ? (
              <div
                onClick={() => {
                  setTableView(false);
                }}
                style={{ height: "30px", width: "30px" }}
              >
                <IconButton kind={KIND.primary} size={SIZE.compact} fullWidth>
                  <ThreeLine />
                </IconButton>
              </div>
            ) : (
              <div
                onClick={() => {
                  setTableView(true);
                }}
                style={{ height: "30px", width: "30px" }}
              >
                <IconButton kind={KIND.primary} size={SIZE.compact} fullWidth>
                  <NineDots />
                </IconButton>
              </div>
            )}
          </div>

          {querySort !== "-executed_on" ? (
            <IconButton
              kind={KIND.primary}
              size={SIZE.compact}
              onClick={() => setQuerySort("-executed_on")}
            >
              <Ascending />
            </IconButton>
          ) : (
            <IconButton
              kind={KIND.primary}
              size={SIZE.compact}
              onClick={() => setQuerySort("executed_on")}
            >
              <Descending />
            </IconButton>
          )}
        </div>
      </div>

      {/* {!isLoading ? (
        <>
          {data &&
          isFetched &&
          data.data &&
          data?.data?.docs &&
          data.data.docs.length !== 0 ? (
            <div
              ref={timelineContainer}
              className="cp-timeline-container__status"
              // style={{ overflow: "scroll" }}
            >
              <TimelineMap
                data={data.data.docs || []}
                getParams={getParams}
                customerId={customerId}
              />
            </div>
          ) : (
            <div
              ref={timelineContainer}
              className="cp-timeline-container__status"
              // style={{ overflow: "scroll" }}
            >
              <TimelineCreateAction />
            </div>
          )}
          <div className="dummy-height__overflow-bottom"></div>
        </>
      ) : (
        <Loader />
      )} */}

      {!invoiceTimeLineList.isLoading ? (
        <>
          {/* {invoiceTimeLineList.data &&
            Array.isArray(invoiceTimeLineList.data.pages) &&
            invoiceTimeLineList.data.pages.map((e) => {
              

              if (
                e &&
                e.data &&
                e.data.docs &&
                Array.isArray(e.data.docs) &&
                e.data.docs.length !== 0
              ) {
                
                return (
                  <div ref={ref} className="cp-timeline-container__status">
                    <TimelineMap
                      data={(e && e.data && e.data.docs) || []}
                      getParams={getParams}
                      customerId={customerId}
                    />
                  </div>
                );
              } else {
                return (
                  <div>
                    <TimelineCreateAction />
                  </div>
                );
              }
            })} */}
          {timelineData && timelineData?.length > 0 ? (
            <div ref={ref} className="cp-timeline-container__status">
              {tableView ? (
                <TimelineMap
                  data={timelineData || []}
                  getParams={getParams}
                  customerId={customerId}
                  entity="invoices"
                />
              ) : (
                <>
                  {timelineData.map((e) => {
                    return (
                      <div
                        ref={ref}
                        style={{
                          display: "flex",
                          flex: "1",
                        }}
                      >
                        <TimelineTableMap
                          data={e}
                          key={e.type}
                          getParams={getParams}
                        />
                      </div>
                    );
                  })}
                </>
              )}
              {/* <TimelineMap
                data={timelineData || []}
                getParams={getParams}
                customerId={customerId}
              /> */}
            </div>
          ) : (
            <>
              <div>
                <TimelineCreateAction />
              </div>
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default InvoiceTimeline;
