import {
  NOTES_ADD_ADHOC_UPLOAD_DATA,
  NOTES_UPDATE_MAP_FIELDS,
  NOTES_UPDATE_FORMAT_DATA,
  NOTES_UPDATE_DEFAULT_DATA,
  NOTES_UPDATE_SYSTEM_FIELDS,
  NOTES_UPDATE_TEMPLATE_DATA_LIST,
  NOTES_INITIALSTATE_STATE,
  NOTES_ADHOC_REFETCH,
  NOTES_SET_AU_FILTER,
  NOTES_ADHOC_FILTER,
  NOTES_VALIDATION_ADHOC_FILTER,
  NOTES_MAP_BASED_ON,
  NOTES_BUSINESS_UNIT_DATA,
} from "./action";

import moment from "moment";

const initialState = {
  mappingFieldList: [],
  defaultSystemFields: [],
  defaultFields: [],
  requiredFields: [],
  systemFields: [],
  requiredDefaultFields: [],
  requiredFormatFields: [],
  adhocRefetchActions: 0,
  validDefaultFields: [],
  tableCurrentState: {
    filters: {},
    formFilters: [
      {
        id: [],
      },
    ],
  },
  adhocFilter: false,
  defaultRequiredFields: [],
  fieldMessage: [],
  mapBasedOn: [],
  businessUnitRd: [],
};

const adhocDataReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case NOTES_ADHOC_FILTER: {
      return {
        ...state,
        adhocFilter: payload,
      };
    }
    case NOTES_ADD_ADHOC_UPLOAD_DATA:
      /* getting the mapping system fields value */
      if (payload.systemFields) {
        const requiredFields = [];
        payload.systemFields.map(({ required, system_column, id }) => {
          if (required == true) {
            requiredFields.push({ system_column, id, isMapped: false });
          }
        });
        payload.requiredFields = [...requiredFields];

        payload.defaultSystemFields = payload.systemFields;
      }
      /* set value of unmapped default fieds  */
      if (payload.defaultFields) {
        payload.requiredDefaultFields = [...payload.defaultFields];
      }

      return {
        ...state,
        ...payload,
      };

    // case UPDATE_MAP_FIELDS:

    //   state.systemColumn[payload.index].mapped_field = payload.mapField;
    //   state.systemColumn[payload.index].data =
    //     state.firstRowData[payload.mapField];
    //   const systemColumn = [...state.systemColumn];
    //   return {
    //     ...state,
    //     systemColumn: [...systemColumn],
    //   };

    case NOTES_UPDATE_FORMAT_DATA:
      /* formate value  */
      if (!state.mappingFieldList[payload.index].format) {
        const formatSelectIndex = state.requiredFormatFields.findIndex(
          ({ id, isSelected }) =>
            id == state.mappingFieldList[payload.index].system_field &&
            isSelected == false
        );
        state.requiredFormatFields[formatSelectIndex].isSelected = true;
      }

      state.mappingFieldList[payload.index].format = payload.formatData;

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
      };

    case NOTES_UPDATE_SYSTEM_FIELDS: {
      const prevSystemField =
        state.mappingFieldList[payload.index].system_field;
      if (prevSystemField === payload.systemField) {
        return {
          ...state,
        };
      }

      let newRequiredFields = [...state.requiredFields];

      // if (payload.type == "clear") {
      let checkDueReqFields = state.requiredFields.find(
        ({ id }) => id == "due_amount"
      );
      let checkInvDueReqFields = state.requiredFields.find(
        ({ id }) => id == "invoice_total_amount"
      );

      let customerName = state.requiredFields.find(
        ({ id }) => id == "customer_name"
      );
      let contactFirstName = state.requiredFields.find(
        ({ id }) => id == "first_name"
      );

      if (
        !customerName &&
        contactFirstName &&
        state.mappingFieldList[payload.index].system_field == "first_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "first_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "customer_name"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "First Name",
            id: "first_name",
            isMapped: false,
          });
        }
      }

      if (
        !contactFirstName &&
        customerName &&
        state.mappingFieldList[payload.index].system_field == "customer_name"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "customer_name"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "first_name"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "First Name",
            id: "first_name",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Customer Name",
            id: "customer_name",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "First Name",
            id: "first_name",
            isMapped: false,
          });
        }
      }

      if (
        !checkDueReqFields &&
        checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field ==
          "invoice_total_amount"
      ) {
        let findIndexOfInvAmt = state.requiredFields.findIndex(
          ({ id }) => id === "invoice_total_amount"
        );

        state.requiredFields.splice(findIndexOfInvAmt, 1);

        let findMapDueAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "due_amount"
        );

        if (findMapDueAmt) {
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
        }
      }

      if (
        checkDueReqFields &&
        !checkInvDueReqFields &&
        state.mappingFieldList[payload.index].system_field == "due_amount"
      ) {
        let findDueAmt = state.requiredFields.findIndex(
          ({ id }) => id === "due_amount"
        );

        state.requiredFields.splice(findDueAmt, 1);

        let findMapInvAmt = state.mappingFieldList.find(
          ({ system_field }) => system_field == "invoice_total_amount"
        );

        if (findMapInvAmt) {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: true,
          });
        } else {
          state.requiredFields.push({
            system_column: "Invoice Amount",
            id: "invoice_total_amount",
            isMapped: false,
          });
          state.requiredFields.push({
            system_column: "Due Amount",
            id: "due_amount",
            isMapped: false,
          });
        }
      }
      // }

      if (prevSystemField) {
        const preSystemFieldIndex = state.systemFields.findIndex(
          (sys) => prevSystemField == sys.id
        );
        const preSystemFieldConfig = state.systemFields[preSystemFieldIndex];
        if (preSystemFieldConfig && preSystemFieldConfig.required) {
          // state.requiredFields.push(preSystemFieldConfig);

          let requiredFieldIndex = state.requiredFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFields[requiredFieldIndex].isMapped = false;
          }
        }

        if (preSystemFieldConfig && preSystemFieldConfig.format) {
          let requiredFieldIndex = state.requiredFormatFields.findIndex(
            ({ id }) => id == prevSystemField
          );

          if (requiredFieldIndex != -1) {
            state.requiredFormatFields.splice(requiredFieldIndex, 1);
          }
        }

        state.systemFields[preSystemFieldIndex].isSelected = false;
      }

      state.mappingFieldList[payload.index].system_field = payload.systemField;
      state.mappingFieldList[payload.index].format = null;

      const requiredFieldIndex = state.requiredFields.findIndex((req) => {
        return req.id == payload.systemField;
      });
      if (requiredFieldIndex != -1) {
        state.requiredFields[requiredFieldIndex].isMapped = true;
      }

      const selectSystemField =
        state &&
        state.systemFields &&
        state.systemFields.findIndex(
          (select) => select.id == payload.systemField
        );

      state.systemFields[selectSystemField].isSelected = true;

      if (state.mappingFieldList[payload.index].system_field === "due_amount") {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "invoice_total_amount" || isMapped
        );
      }

      if (
        state.mappingFieldList[payload.index].system_field === "customer_name"
      ) {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "first_name" || isMapped
        );
      }

      if (state.mappingFieldList[payload.index].system_field === "first_name") {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "customer_name" || isMapped
        );
      }

      if (
        state.mappingFieldList[payload.index].system_field ===
        "invoice_total_amount"
      ) {
        newRequiredFields = state.requiredFields.filter(
          ({ id, isMapped }) => id != "due_amount" || isMapped
        );
      }
      // }

      if (state.systemFields[selectSystemField].format?.reference) {
        const { system_column, id } = state.systemFields[selectSystemField];
        const findRequiredFormatFields = state.requiredFormatFields.find(
          (e) => e.id === id
        );
        {
          state.requiredFormatFields.push({
            system_column,
            id,
            isSelected: false,
          });
        }
      }

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        requiredFields: [...newRequiredFields],
        requiredFormatFields: [...state.requiredFormatFields],
      };
    }

    case NOTES_UPDATE_DEFAULT_DATA: {
      // if (!state.validDefaultFields[payload.index].default.value) {
      //   const requiredDefaultFieldsIndex =
      //     state.requiredDefaultFields.findIndex(
      //       ({ id }) => id === state.validDefaultFields[payload.index].id
      //     );
      //   state.requiredDefaultFields.splice(requiredDefaultFieldsIndex, 1);
      // }

      let newValidDefaultFields = [...state.validDefaultFields];
      let newReqFields = [...state.fieldMessage];

      newValidDefaultFields[payload.index].default.value = payload.defaultData;

      if (
        state.validDefaultFields[payload.index].id == "invoice_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_date",
            message: `Invoice Date is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date");
        }
      }

      if (
        state.validDefaultFields[payload.index].id == "invoice_due_date" &&
        !payload.defaultData.startsWith("{{") &&
        !payload.defaultData.endsWith("}}")
      ) {
        let isDateVaied = moment(
          payload.defaultData,
          state.organizationDtl.date_format,
          true
        ).isValid();

        if (!isDateVaied) {
          newReqFields.push({
            id: "invoice_due_date",
            message: `Due Date entered is not as per the format ${state.organizationDtl.date_format}`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_due_date");
        }
      }

      let findInvDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_date"
      );

      let findDueDateFields = newValidDefaultFields.find(
        (e) => e.id == "invoice_due_date"
      );

      if (
        findInvDateFields &&
        findInvDateFields.id &&
        !findInvDateFields.default.value.startsWith("{{") &&
        !findInvDateFields.default.value.endsWith("}}") &&
        findDueDateFields &&
        findDueDateFields.id &&
        !findDueDateFields.default.value.startsWith("{{") &&
        !findDueDateFields.default.value.endsWith("}}") &&
        newReqFields.filter((nr) =>
          ["invoice_date", "invoice_due_date"].includes(nr.id)
        ).length === 0
      ) {
        let invDate = moment(
          findInvDateFields.default.value,
          state.organizationDtl.date_format
        );
        let dueDate = moment(
          findDueDateFields.default.value,
          state.organizationDtl.date_format
        );

        if (invDate.diff(dueDate, "days") > 0) {
          newReqFields.push({
            id: "invoice_date_gr",
            message: `Invoice Date has to be lesser than or equal to Due Date`,
          });
        } else {
          newReqFields = newReqFields.filter(
            (e) => e.id != "invoice_due_date_gr"
          );
        }

        if (dueDate.diff(invDate, "days") < 0) {
          newReqFields.push({
            id: "invoice_due_date_gr",
            message: `Due Date has to be greater than or equal to Invoice Date`,
          });
        } else {
          newReqFields = newReqFields.filter((e) => e.id != "invoice_date_gr");
        }
      } else {
        newReqFields = newReqFields.filter(
          (e) => e.id != "invoice_due_date_gr" || e.id != "invoice_date_gr"
        );
      }

      return {
        ...state,
        validDefaultFields: [...state.validDefaultFields],
        fieldMessage: [...newReqFields],
      };

      break;
    }

    case NOTES_UPDATE_TEMPLATE_DATA_LIST:
      let newRequiredFields = state.requiredFields;
      let newFormatFields = [];
      state.mappingFieldList = state.mappingFieldList.map((e) => ({
        ...e,
        format: null,
        system_field: null,
      }));

      payload.mappingFields.map((e) => {
        const initialMappingFieldIndex = state.mappingFieldList.findIndex(
          ({ upload_field }) => upload_field == e.upload_field
        );
        const systemFieldIndex = state.systemFields.findIndex(
          ({ id }) => e.system_field === id
        );
        let isUploadField = state.mappingFieldList.find(
          ({ upload_field }) => upload_field == e.upload_field
        );
        if (systemFieldIndex !== -1 && isUploadField) {
          state.systemFields[systemFieldIndex].isSelected = true;
        }
        const selectTick = state.systemFields.findIndex(
          ({ id }) => id === e.system_field
        );

        // if (e.system_field == "invoice_total_amount") {
        //   newRequiredFields = state.requiredFields.filter(
        //     ({ id, isMapped }) => id != "due_amount"
        //   );
        // }

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.required
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          const requiredFieldIndex = newRequiredFields.findIndex(
            (e) => e.id == id
          );

          if (requiredFieldIndex != -1) {
            newRequiredFields[requiredFieldIndex].isMapped = true;
          }

          // newRequiredFields.push({ system_column, id, isMapped: true });
        }

        if (
          initialMappingFieldIndex != -1 &&
          state.systemFields[selectTick]?.format &&
          isUploadField
        ) {
          const { system_column, id } = state.systemFields[selectTick];
          newFormatFields.push({ system_column, id, isSelected: true });
        }

        if (state.systemFields[selectTick] && isUploadField) {
          state.systemFields[selectTick].isSelected = true;
          state.mappingFieldList[initialMappingFieldIndex] = {
            ...state.mappingFieldList[initialMappingFieldIndex],
            format: e.format,
            system_field: e.system_field,
          };
        }

        if (e.system_field == "first_name") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "customer_name" || isMapped
          );
        } else if (e.system_field == "customer_name") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "first_name" || isMapped
          );
        }

        if (e.system_field == "invoice_total_amount") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "due_amount" || isMapped
          );
        } else if (e.system_field == "due_amount") {
          newRequiredFields = newRequiredFields.filter(
            ({ id, isMapped }) => id != "invoice_total_amount" || isMapped
          );
        }
      });

      payload.defaultFields.map((e) => {
        const initialDefaultFieldIndex = state.defaultFields.findIndex(
          ({ id }) => id == e.id
        );
        if (
          state &&
          state.defaultFields &&
          state.defaultFields[initialDefaultFieldIndex] &&
          state.defaultFields[initialDefaultFieldIndex].defaultstate &&
          state.defaultFields[initialDefaultFieldIndex].default.value
        ) {
          state.defaultFields[initialDefaultFieldIndex].default.value = e.value;
        }
      });

      return {
        ...state,
        mappingFieldList: [...state.mappingFieldList],
        systemFields: [...state.systemFields],
        requiredFields: [...newRequiredFields],
        requiredFormatFields: [...newFormatFields],
      };

    case NOTES_ADHOC_REFETCH: {
      const { adhocRefetchActions } = state;
      return {
        ...state,
        adhocRefetchActions: adhocRefetchActions + 1,
      };
    }

    case NOTES_SET_AU_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case NOTES_VALIDATION_ADHOC_FILTER: {
      let defaultFields = [];

      state.defaultFields.map((e) => {
        let findMappedFields = state.mappingFieldList.find(
          ({ system_field }) => system_field == e.id
        );

        if (!findMappedFields || !findMappedFields.system_field) {
          defaultFields.push(e);
        }
      });

      let newDefaultFields = defaultFields.reduce((prev, curr) => {
        if (curr.id === "business_unit") {
          let businessUnit = state.systemFields.find(
            ({ id }) => id === "business_unit"
          );

          let mapBasedOn = state.mapBasedOn.find(
            ({ id }) => id === "business_unit"
          );

          let findBusinessUnit =
            state && state.businessUnitRd.find(({ label }) => label == "ALL");

          if (businessUnit && !businessUnit.isMapped && mapBasedOn) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: findBusinessUnit?.id },
                is_create: true,
              },
            ];
          }

          return prev;
        }

        if (curr.id === "customer_name") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "customer_name"
          );

          let mapBasedOn = state.mapBasedOn.find(
            ({ id }) => id === "customer_name"
          );

          if (sysField && !sysField.isMapped && mapBasedOn) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "{{First_name+Last_name}}" },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "notes_category") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "notes_category"
          );

          if (sysField && !sysField.isSelected) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "general" },
                is_create: true,
              },
            ];
          }

          return prev;
        }

        if (curr.id === "type") {
          let sysField = state.systemFields.find(({ id }) => id === "type");

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "INFORMATIONAL" },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "created_by") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "created_by"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "system" },
              },
            ];
          }

          return prev;
        }

        if (curr.id === "created_at") {
          let sysField = state.systemFields.find(
            ({ id }) => id === "created_at"
          );

          if (sysField && !sysField.isMapped) {
            return [
              ...prev,
              {
                ...curr,
                default: { ...curr.default, value: "{{Today}}" },
              },
            ];
          }

          return prev;
        }

        return [...prev, curr];
      }, []);

      return {
        ...state,
        validDefaultFields: [...newDefaultFields],
      };
    }

    case NOTES_MAP_BASED_ON: {
      const mapBasedData = payload?.map((e) => e?.id);
      const requiredFields = state.systemFields.reduce(
        (prev, { required, system_column, id, isSelected }) => {
          if (required && id !== "business_unit") {
            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          if (mapBasedData?.includes(id) && id !== "business_unit") {
            if (id === "customer_name") {
              return [
                ...prev,
                {
                  system_column: "First Name",
                  id: "first_name",
                  isMapped: false,
                },
                {
                  system_column,
                  id,
                  isMapped: isSelected ? isSelected : false,
                },
              ];
            }

            return [
              ...prev,
              {
                system_column,
                id,
                isMapped: isSelected ? isSelected : false,
              },
            ];
          }

          return prev;
        },
        []
      );

      return {
        ...state,
        mapBasedOn: [...payload],
        requiredFields: [...requiredFields],
      };
    }

    case NOTES_INITIALSTATE_STATE: {
      return {
        ...state,
        ...initialState,
        requiredFormatFields: [],
        mapBasedOn: [],
      };
    }

    case NOTES_BUSINESS_UNIT_DATA: {
      return {
        ...state,
        businessUnitRd: [...payload],
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default adhocDataReducer;
