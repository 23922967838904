import { ParagraphMedium } from "baseui/typography";
import React from "react";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";

const AllocatedInvNumber = (props) => {
  let { value, column, alignment, accessor, row, ...rest } = props;
  value = _.get(row.original, accessor, 0);

  let data = value?.map((i) => {
    return i.invoice_number;
  });

  let tooltipData = [...data];
  tooltipData.shift();

  return (
    <div
      style={{
        display: "flex",
        // gap: "10px"
        gap: "5px",
        width: "100%",
        justifyContent: alignment,
      }}
    >
      <ParagraphMedium>{value[0]?.invoice_number}</ParagraphMedium>
      {value.length > 1 ? (
        <StatefulTooltip
          accessibilityType={"tooltip"}
          content={
            <div style={{ maxWidth: "320px" }}>{`${tooltipData
              .join(", ")
              .toString()}${"."}`}</div>
          }

          // content={props?.value?.map(
          //   (e) => `${e?.invoice_number.join(", ").toString()}${"."}`
          // )}
          // content={`${tootltipData.join(", ").toString()}${"."}`}
        >
          <div
            style={{
              padding: "3px 5px",
              height: "22px",
              border: "1px solid #516beb",
              borderRadius: "5px",
            }}
          >
            {value.length - 1}+
          </div>
        </StatefulTooltip>
      ) : (
        ""
      )}
    </div>
  );
};

export default AllocatedInvNumber;
