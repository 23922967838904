function SideArrow() {
  return (
    <>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.25302 0.708333C3.25302 1.105 3.57177 1.41667 3.96135 1.41667H7.92094L0.207187 9.13042C-0.0690625 9.40667 -0.0690625 9.85292 0.207187 10.1292C0.483437 10.4054 0.929688 10.4054 1.20594 10.1292L8.91969 2.41542V6.375C8.91969 6.76458 9.23844 7.08333 9.62802 7.08333C10.0176 7.08333 10.3364 6.76458 10.3364 6.375V0.708333C10.3364 0.31875 10.0176 0 9.62802 0H3.96135C3.57177 0 3.25302 0.31875 3.25302 0.708333Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default SideArrow;
