const PtpPartial = () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72943 18.9996H20.2594C21.0294 18.9996 21.5094 18.1696 21.1294 17.4996L11.8594 1.49957C11.4694 0.82957 10.5094 0.82957 10.1294 1.49957L0.859434 17.4996C0.479434 18.1696 0.959434 18.9996 1.72943 18.9996ZM11.9994 15.9996H9.99943V13.9996H11.9994V15.9996ZM10.9994 11.9996C10.4494 11.9996 9.99943 11.5496 9.99943 10.9996V8.99957C9.99943 8.44957 10.4494 7.99957 10.9994 7.99957C11.5494 7.99957 11.9994 8.44957 11.9994 8.99957V10.9996C11.9994 11.5496 11.5494 11.9996 10.9994 11.9996Z"
        fill="#FBBD04"
      />
    </svg>
  );
};
export default PtpPartial;
