import axios from "axios";
import { useEffect, useContext, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-toastify";

const axiosWithAuth = axios.create({
  // baseURL:  "https://example.com"
});

const AxiosInterceptor = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const requestSuccInterceptor = (config) => {
      config.headers.authorization = `Bearer ${keycloak.token}`;
      return config;
    };

    const requestErrInterceptor = (error) => {
      return Promise.reject(error);
    };

    const responseSuccInterceptor = (response) => {
      if (
        response &&
        response.config &&
        response.config.method &&
        ["post", "put", "patch", "delete"].includes(response.config.method)
      ) {
        const params = response.config.params;
        const hasPaginationOrSort =
          params && (params.page || params.page_size || params.sort);

        if (!hasPaginationOrSort && response.data && response.data.message) {
          toast.success(response.data.message);
        }
      }

      return response;
    };

    const responseErrInterceptor = async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await keycloak.updateToken(-1);

        let config = error.config;
        config.headers.authorization = `Bearer ${keycloak.token}`;

        return axios(config);
      }
      return Promise.reject(error);
    };

    if (initialized) {
      axiosWithAuth.interceptors.response.eject(requestInterceptor);
      axiosWithAuth.interceptors.response.eject(responseInterceptor);
    }

    const requestInterceptor = axiosWithAuth.interceptors.request.use(
      requestSuccInterceptor,
      requestErrInterceptor
    );

    const responseInterceptor = axiosWithAuth.interceptors.response.use(
      responseSuccInterceptor,
      responseErrInterceptor
    );

    setInitialized(true);

    return () => {
      axiosWithAuth.interceptors.request.eject(requestInterceptor);
      axiosWithAuth.interceptors.response.eject(responseInterceptor);
    };
  }, [keycloak.token]);

  return initialized ? children : null;
};

export default axiosWithAuth;
export { AxiosInterceptor };
