import React from "react";

const ViewIconOutline = ({ size = 18, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00036 11.6827C9.85017 11.6827 10.572 11.3852 11.1657 10.7903C11.7595 10.1955 12.0563 9.4731 12.0563 8.62328C12.0563 7.77346 11.7589 7.05168 11.164 6.45793C10.5691 5.86418 9.84677 5.56731 8.99695 5.56731C8.14714 5.56731 7.42536 5.86474 6.83161 6.45962C6.23786 7.05451 5.94098 7.77686 5.94098 8.62669C5.94098 9.4765 6.23842 10.1983 6.83329 10.792C7.42818 11.3858 8.15054 11.6827 9.00036 11.6827ZM8.99866 10.65C8.43616 10.65 7.95803 10.4531 7.56428 10.0594C7.17053 9.66561 6.97366 9.18748 6.97366 8.62498C6.97366 8.06248 7.17053 7.58436 7.56428 7.19061C7.95803 6.79686 8.43616 6.59998 8.99866 6.59998C9.56116 6.59998 10.0393 6.79686 10.433 7.19061C10.8268 7.58436 11.0237 8.06248 11.0237 8.62498C11.0237 9.18748 10.8268 9.66561 10.433 10.0594C10.0393 10.4531 9.56116 10.65 8.99866 10.65ZM8.99866 13.875C7.41982 13.875 5.97584 13.4538 4.66671 12.6115C3.35757 11.7692 2.31263 10.661 1.53186 9.28699C1.46936 9.1793 1.42369 9.07077 1.39484 8.96139C1.36599 8.85201 1.35156 8.73974 1.35156 8.62461C1.35156 8.50947 1.36599 8.39734 1.39484 8.28821C1.42369 8.17908 1.46936 8.07066 1.53186 7.96298C2.31263 6.58894 3.35757 5.48077 4.66671 4.63847C5.97584 3.79616 7.41982 3.375 8.99866 3.375C10.5775 3.375 12.0215 3.79616 13.3306 4.63847C14.6397 5.48077 15.6847 6.58894 16.4654 7.96298C16.5279 8.07066 16.5736 8.17919 16.6025 8.28857C16.6313 8.39796 16.6457 8.51022 16.6457 8.62536C16.6457 8.74049 16.6313 8.85263 16.6025 8.96175C16.5736 9.07089 16.5279 9.1793 16.4654 9.28699C15.6847 10.661 14.6397 11.7692 13.3306 12.6115C12.0215 13.4538 10.5775 13.875 8.99866 13.875ZM8.99866 12.75C10.4112 12.75 11.708 12.3781 12.8893 11.6344C14.0705 10.8906 14.9737 9.88748 15.5987 8.62498C14.9737 7.36248 14.0705 6.35936 12.8893 5.61561C11.708 4.87186 10.4112 4.49998 8.99866 4.49998C7.58616 4.49998 6.28928 4.87186 5.10803 5.61561C3.92678 6.35936 3.02366 7.36248 2.39866 8.62498C3.02366 9.88748 3.92678 10.8906 5.10803 11.6344C6.28928 12.3781 7.58616 12.75 8.99866 12.75Z"
        fill={color}
      />
    </svg>
  );
};

export default ViewIconOutline;
