import React, { useEffect, useState, useRef } from "react";
import { StyledList, StyledListItem } from "baseui/menu";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import Edit from "../../assets/img/svg/EditSvg";
import useHover from "../../utils/HoverSvg/useHover";
import "../../../src/assets/css/table.css";
import { Label2 } from "baseui/typography";
import { IconButton, SIZE } from "../IconButton";
import { FormattedMessage } from "react-intl";
import TableHeaderAdder from "../../assets/img/svg/TableHeaderAdder";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { result } from "lodash";
import DragDropHorizontal from "../../assets/img/svg/DragDropHorizontal";
import { setTableHeaderOrder } from "../../redux/customerSummary/action";
import { useDispatch } from "react-redux";
import DragDropVertical from "../../assets/img/svg/DragDropVertical";
import ColumnCheckToogle from "./ColumnCheckToggle";
import CustomizedPlus from "../../assets/img/svg/CustomizedPlus";

const ColumnToogle = ({
  allColumns,
  setColumnOrder,
  colOrder,
  disableView,
}) => {
  let dispatch = useDispatch();

  const [hoverRef, setisHovered] = useState(false);

  // document.addEventListener("mousedown", (event) => {
  //   if (!concernedElement.contains(event.target)) {
  //     setisHovered(false);

  //   }
  // });

  return (
    <>
      <div className="click-text">
        <IconButton
          type="button"
          size={SIZE.mini}
          onClick={() => {
            if (!disableView) {
              setisHovered(!hoverRef);
            }
          }}
        >
          {/* <TableHeaderAdder /> */}
          <CustomizedPlus />
        </IconButton>

        <div
          style={{
            position: "absolute",
            zIndex: "100",
            right: "2%",
            paddingTop: "10px",
          }}
        >
          {hoverRef === true ? (
            <ColumnCheckToogle
              allColumns={allColumns}
              setisHovered={setisHovered}
              hoverRef={hoverRef}
              setColumnOrder={setColumnOrder}
              colOrder={colOrder}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ColumnToogle;
