import axiosWithAuth from "../providers/AxiosInterceptor";

export const getGroupingList = async ({
  organization = null,
  customerId = null,
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/customer/${customerId}/grouping-suggestion`
  );
};

export const groupSuggestion = async ({
  organization = null,
  customerId = null,
  suggestionId = null,
}) => {
  return await axiosWithAuth.post(
    `${process.env.REACT_APP_HOST}${organization}/customer/${customerId}/grouping-suggestion/${suggestionId}`,
    {}
  );
};

export const removeSugesstGroup =async ({
  organization = null,
  customerId = null,
  suggestionId = null,
})=>{
  return await axiosWithAuth.delete(
    `${process.env.REACT_APP_HOST}${organization}/customer/${customerId}/grouping-suggestion/${suggestionId}`
  );
}
