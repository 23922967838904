import { Paragraph2 } from "baseui/typography";
import React from "react";
import { useDispatch } from "react-redux";
import { editUserModal, modelOpen } from "../../../redux/users/actions";

function UserName({ alignment, ...props }) {
  const dispatch = useDispatch();

  return (
    <div>
      <Paragraph2
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
          width: "100%",
          margin: "5px",
          color: "#516beb",
          textAlign: alignment,
          textDecoration: "underline",
        }}
        onClick={() => dispatch(editUserModal(props.row.original._id))}
      >
        {props.value}
      </Paragraph2>
    </div>
  );
}

export default UserName;
