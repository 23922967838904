import React from "react";
import { Icon, Typography } from "../../../components_v2";
import TitleHead from "../../../components_v2/TitleHead";
import { useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import queryClient from "../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../../constants";
import _ from "lodash";
import { SelectBox } from "../../../components/SelectBox";
import { useMemo } from "react";
import LegalLetterForm from "./LegalLetterForm";
import AgentActionAlert from "../../../components_v2/AgentActionAlert";
import ActionHeader from "../Actions/ActionHeader";
import { useSelector } from "react-redux";
import {
  executeAdhocCustomerAction,
  executeCustomerAction,
} from "../../../services";
import {
  letterDocumentDrive,
  removeAttachmentData,
  updateLetterAttachmentFromDesktop,
} from "../../../redux/customerOverview/letter/action";

const AGENT_INFO = "AGENT_INFO";
const CALLER_INST = "CALLER_INST";
const callContent =
  "Greet and give a summary of the Outstanding Amount and duration overdue.\nUnderstand the reason why the payment is getting delayed.\nAppraise if needed the impact it can create like discontinued service.\nNegotiate for Promise To Pay by Date and if not arrange a follow-up Call or Meeting Request or capture the Dispute.\nNote: Enable and send Confirmation, Follow-up messages from the call action for better recovery.";
const CallInstructionAccordion = () => {
  const { customerId } = useParams();
  const [state, setState] = useState(null);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const agentInstruction = _.get(
    customerData,
    "data.doc.mini_miranda.agent_instruction",
    ""
  );

  return (
    <div className="co-action-alert">
      <div className="co-action-alert-header">
        <Typography>
          Alert:{" "}
          <span className="mx-p--regular">
            {" "}
            Read Instruction before taking Action
          </span>
        </Typography>
        <div className="co-action-alert-actions">
          <div
            className="co-action-alert-btn"
            onClick={() => {
              setState(CALLER_INST);

              if (state === CALLER_INST) {
                setState(null);
              }
            }}
          >
            <Typography>Caller Instruction</Typography>
            <Icon
              icon={state === CALLER_INST ? "chevron_up" : "chevron_down"}
              color="#516BEB"
            />
          </div>
          {agentInstruction && agentInstruction.length > 0 ? (
            <div
              className="co-action-alert-btn"
              onClick={() => {
                setState(AGENT_INFO);

                if (state === AGENT_INFO) {
                  setState(null);
                }
              }}
            >
              <Typography>Agent Info</Typography>
              <Icon
                icon={state === AGENT_INFO ? "chevron_up" : "chevron_down"}
                color="#516BEB"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {state ? (
        <div className="co-action-alert-content">
          {state === AGENT_INFO ? agentInstruction : ""}
          {state === CALLER_INST ? callContent : ""}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const LegalLetterDataLayer = () => {
  const intl = useIntl();
  const { customerId } = useParams();
  const { referenceData } = useContext(MaxyfiContext);

  const [state, setState] = useState({
    recipient: [],
    outcome: null,
    is_inbound_call: false,
  });

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const { letterAttachment } = useSelector((s) => s.letterReducer);

  const drawerData = useSelector((state) => state.customerOverviewDrawer);

  const contactsOptions = useMemo(() => {
    const contacts = _.get(customerData, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = [...address]
          .map(({ status, address_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${curr?.first_name ? curr?.first_name : ""} - ${
                nRest.line_1
              }, ${nRest.line_2 ? nRest.line_2 : ""}, ${nRest.country}, ${
                nRest.city
              }-${nRest.zip_code} ${designation?.label}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              address_status,
              status,
              designation: curr?.designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.address_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      },
      []
    );

    const { data } = drawerData;

    if (data && data.isWorkflow) {
      if (data.recipientTypes && Array.isArray(data.recipientTypes)) {
        const selectedContacts = contacts.filter((e) =>
          data.recipientTypes.includes(e.designation)
        );

        setState({ ...state, recipient: selectedContacts.slice(0, 1) });
      }
    } else {
      const selectedContacts = contacts.filter((e) =>
        ["primary_contact"].includes(e.designation)
      );

      setState({ ...state, recipient: selectedContacts.slice(0, 1) });
    }
    return contacts;
  }, [customerData?.data, customerId]);

  return (
    <div style={{ width: "650px" }}>
      <ActionHeader name={"Legal Letter"} icon="letter" />
      {/* <TitleHead icon="letter" title="Legal Letter" /> */}
      <AgentActionAlert />
      <SelectBox
        size="mini"
        requiredAstric={true}
        label={intl.formatMessage({
          id: "reciever",
        })}
        placeholder={intl.formatMessage({
          id: "reciever",
        })}
        clearable={false}
        value={state.recipient}
        onChange={(e) => {
          setState({ ...state, recipient: e.value });
        }}
        options={contactsOptions}
      />
      {state.recipient && state.recipient[0] && state.recipient[0].id ? (
        <LegalLetterForm
          recipient_id={
            state.recipient && state.recipient[0] && state.recipient[0].id
              ? state.recipient[0].id
              : null
          }
          formValues={{
            recipients: state.recipient.map(({ id, recipient_id }) => ({
              id,
              recipient_id,
            })),
          }}
          customerId={customerId}
          entity={"customer"}
          tags={["PROMISE", "CALL_BACK", "PAYMENT_PLAN", "FIELD_VISIT"]}
          master_tag="CUSTOMER"
          isWorkflow={_.get(drawerData, "data.isWorkflow", false)}
          service={
            _.get(drawerData, "data.isWorkflow", false)
              ? executeCustomerAction
              : executeAdhocCustomerAction
          }
          removeAttchFile={removeAttachmentData}
          updatingDeskUpload={updateLetterAttachmentFromDesktop}
          letterAttachmentsData={letterAttachment}
          doucumentAttachment={letterDocumentDrive}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default LegalLetterDataLayer;
