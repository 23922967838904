import { ParagraphMedium } from "baseui/typography";
import React from "react";

const OutstandingDays = (props) => {
  switch (props.row.original.customertype) {
    case "overdue":
      return (
        <ParagraphMedium $style={{ color: "#FD372A" }}>
          3 Days OverDue
        </ParagraphMedium>
      );
    case "review":
      return (
        <ParagraphMedium $style={{ color: "#FBBD04" }}>Review</ParagraphMedium>
      );
    case "duetoday":
      return (
        <ParagraphMedium $style={{ color: "#FD372A" }}>
          {" "}
          Due Today
        </ParagraphMedium>
      );
    case "duein":
      return (
        <ParagraphMedium $style={{ color: "#0FB158" }}>
          {" "}
          Due in 3 Days
        </ParagraphMedium>
      );
    default:
      return null;
  }
};

export default OutstandingDays;
