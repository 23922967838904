import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { SelectBox, SIZE } from "../../../components/SelectBox";
import { useIntl } from "react-intl";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../../components/TextArea";
import { TextButton, KIND } from "../../../components/TextButton";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { ParagraphSmall } from "baseui/typography";
import Plus from "../../../assets/img/svg/Upload/Plus";
import { IconButton } from "../../../components/IconButton";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { getFeeSubscription, postSubscription } from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import { useState } from "react";
import getSelectValues from "../../../utils/getSelectValues";
import setSelectValues from "../../../utils/setSelectValues";
import moment from "moment";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const SubscriptionForm = ({
  setIsAddNew,
  setRefetchCheckIn,
  refetchCheckIn,
  editingData,
}) => {
  let editingDatas = editingData && editingData.data && editingData.data[0];

  let intl = useIntl();
  const [data, setData] = useState([]);
  const [totalMonths, setTotalMonths] = useState(12);
  const [overAllMonths, setOverAllMonths] = useState([]);

  const { currentOrganization, referenceData } = useContext(MaxyfiContext);
  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("customers");

  const postSubscriptionData = useMutation(
    (data) =>
      postSubscription({
        organization: currentOrganization,
        customerId: getParams,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();

        setIsAddNew(false);
        setRefetchCheckIn(refetchCheckIn + 1);
      },
    }
  );
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let { start_date, fee_subscription_id } = watch();

  useEffect(() => {
    let months = [];
    if (start_date) {
      let toDate = moment(start_date);
      Array.from({
        length:
          fee_subscription_id &&
          fee_subscription_id[0].frequency === "QUARTERLY"
            ? 4
            : 12,
      }).map((e, i) => {
        months.push({
          label: `${toDate.add(1, "month").format("MMM")} ${toDate.get(
            "year"
          )}`,
          id: toDate.get("month"),
          session: toDate.get("month") + 1,
          year: toDate.get("year"),
        });
      });
    }
    setOverAllMonths(months);
  }, [start_date]);

  useEffect(async () => {
    await getFeeSubscription({ organization: currentOrganization }).then(
      (res) => {
        setData(res.data && res.data && res.data.docs);
        // setSettingData(res.data.doc || {});
      }
    );
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skip",
  });

  const onSubmit = async (data) => {
    let skipDuration = [];
    if (start_date) {
      for (let i of data.skip) {
        let year = i && i[0] && i[0].year;
        let session = i && i[0] && i[0].session;
        skipDuration.push({ year: year, session: session });
      }
    }

    delete data.skip;
    delete data.start_date;
    delete data.end_date;
    // delete data.fee_subsciption_id;

    let values = {
      ...data,
      fee_subscription_id: getSelectValues(data.fee_subscription_id),
      start_time: moment(data.start_date).utc().valueOf(),
      end_time: moment(data.end_date).utc().valueOf(),
      skip_durations: skipDuration,
    };

    await postSubscriptionData.mutateAsync(values);
  };

  let selectedData = [
    {
      session: 3,
      year: 2023,
      _id: "63ef6478fabe37f30e7107c1",
    },
    {
      session: 5,
      year: 2023,
      _id: "63ef6478fabe37f30e7107c2",
    },
  ];

  // useEffect(() => {
  //   if (editingDatas) {
  //
  //     setValue("fee_subscription_type", editingDatas.fee_subscription_type);
  //     setValue(
  //       "fee_subscription_id",
  //       setSelectValues(editingDatas.fee_subscription_id)
  //     );
  //     setValue("start_date", editingDatas.start_time);
  //     setValue("end_date", editingDatas.end_time);

  //     if (selectedData) {
  //       selectedData.map((e, i) => {
  //
  //         setValue(`skip[${i}]`, setSelectValues(e.year));
  //       });
  //     }
  //   }
  // }, [editingData]);

  return (
    <form style={{ paddingBottom: "15px" }} onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "12%", paddingBottom: "10px" }}>
        <div style={{ display: "flex" }}>
          <Controller
            name="fee_subscription_type"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                name={field.name}
                value={field.value}
                align={ALIGN.horizontal}
              >
                <Radio value="FIXED">Fixed Fees</Radio>
                <Radio value="USAGE_BASED">Usage Based</Radio>
                <Radio value="ADHOC">Adhoc</Radio>
              </RadioGroup>
            )}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "130px" }}>
          <Controller
            control={control}
            name="fee_subscription_id"
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "item",
                })}
                placeholder={intl.formatMessage({
                  id: "item",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={data.map((i) => {
                  return { label: i.name, id: i._id, frequency: i.frequency };
                })}
              />
            )}
          />
        </div>
        <div style={{ width: "130px" }}>
          <Controller
            name="start_date"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                // disabled={isDisabled}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "start_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                size={SIZE.mini}
                onChange={(e) => field.onChange(e.date)}
                // minDate={new Date()}
                range
              />
            )}
          />
        </div>
        <div style={{ width: "130px" }}>
          <Controller
            name="end_date"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                // disabled={isDisabled}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "end_date",
                })}
                // placeholder={intl.formatMessage({
                //   id: " ",
                // })}
                value={field.value}
                size={SIZE.mini}
                onChange={(e) => field.onChange(e.date)}
                // minDate={new Date()}
                range
              />
            )}
          />
        </div>
      </div>
      <div>
        <TextButton
          kind={KIND.secondary}
          size={SIZE.mini}
          type="button"
          onClick={() => {
            append({ skip: "", type: "" });
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <Plus fill={"#516beb"} />
            <div>Skip Duration</div>
          </div>
        </TextButton>
      </div>
      <div
        style={{
          paddingTop: "10px",
          minHeight: "0px",
          maxHeight: "210px",
          overflow: "scroll",
        }}
      >
        {fields.map((e, i) => (
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <div style={{ width: "130px" }}>
              <Controller
                name={`skip[${i}]`}
                // name={`select`}
                control={control}
                // shouldUnregister={true}
                render={({ field }) => {
                  return (
                    <SelectBox
                      {...field}
                      name={field.name}
                      // disabled={isDisabled}
                      value={watch(field.name)}
                      label={intl.formatMessage({
                        id: "select_filter",
                      })}
                      placeholder={intl.formatMessage({
                        id: "select_filter",
                      })}
                      size={SIZE.mini}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={overAllMonths}
                    />
                  );
                }}
              />
            </div>
            <div>
              <IconButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => remove(i)}
              >
                <Delete />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // paddingTop: "25px",
        }}
      >
        <div
          style={{
            width: "80px",
            paddingRight: "10px",
          }}
        >
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              setIsAddNew(false);
            }}
          >
            Cancel
          </TextButton>
        </div>
        <div style={{ width: "80px" }}>
          <TextButton
            kind={KIND.primary}
            size={SIZE.mini}
            isLoading={isSubmitting}
            fullWidth
            type="submit"
          >
            Capture
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default SubscriptionForm;
