import tableGetFilter from "../components/TableFilters";
import headerType_V2 from "../components/HeaderTypes/HeaderSort_V2";
import tableCellTypeV2 from "../components/TableCell/index_v2";
import { Icon } from "../components_v2";

const columnMapper_V2 = ({
  columns = [],
  isExpanded = true,
  isExpandedHeader = true,
  isSelection = true,
  viewSelect = [],
  isRowDeletedVar,
  isEdit = true,
  isChecked = false,
  editAction = () => {},
  width = 0,
  isCurrency = false,
  isInvExpand = false,
  isV2 = false,
}) => {
  let newColumns = [];
  let hiddenColumns = [];
  let filters = [];
  let sort = null;

  const [reservedWidth, reservedColumns] = columns.reduce(
    (prev, curr) => {
      if (curr && curr.width_percentage && curr.width_percentage > 0) {
        prev[1] = prev[1] + 1;
        prev[0] = prev[0] + ((width - 80) / 100) * curr.width_percentage;
        return prev;
      }

      return prev;
    },
    [0, 0]
  );

  let columnWidth =
    (width - reservedWidth) / (columns.length - reservedColumns);

  for (let column of columns) {
    let Filter = tableGetFilter(column.type);
    let cell;
    if (column.nested && Array.isArray(column.nested)) {
      cell = (props) => (
        <div
          className="table_nest_container"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {column.nested.map((nst) => {
            let InnerCell = tableCellTypeV2({
              filterType: nst.cell_type,
              alignment: nst.alignment,
              accessorKey: nst.alignment,
              accessor: nst.accessor,
              suffix: nst.suffix,
              isCurrency: isCurrency,
              rdName: nst.rd_name,
              color: nst.color,
            });

            return <InnerCell {...props} />;
          })}
        </div>
      );
    } else {
      cell = tableCellTypeV2({
        filterType: column.cell_type,
        alignment: column.alignment,
        accessor: column.accessor,
        accessorKey: column.accessor,
        rdName: column.rd_name,
      });
    }

    let newCol = {
      id: column.accessor,
      meta: {
        width:
          column.width_percentage && column.width_percentage > 0
            ? ((width - 80) / 100) * column.width_percentage
            : column.width,
      },
      accessorKey: column.accessor,
      col_id: column.col_id,
      cell_type: column.cell_type,
      literal_id: column.literal_id,
      header: headerType_V2({
        headerFilterType: column.header_type,
        // alignment: alignment(column.cell_type),
        alignment: column.header_alignment,
        cellType: column.cell_type,
        isCurrency: isCurrency,
        literal_id: column.literal_id,
        // sortBy: column.sort_by,
      }),
      // header: TabelHead({
      //   literal_id: column.literal_id,
      // }),
      cell,
      Filter,
      width:
        column.width_percentage && column.width_percentage > 0
          ? ((width - 80) / 100) * column.width_percentage
          : columnWidth,
      // width: 40,
      // defaultCanSort: true,
      type: column.rep_type,
      repSubType: column.rep_sub_type,
      canSort: column.sort_by,
      disableFilters: !column.filter_by,
      order: column.order,
      rdName: column.rd_name,
      // accessor: column.accessor,
      path: column.accessor,
    };

    let viewCol = viewSelect.find((e) => e.col_id === newCol.path);

    if (viewCol) {
      let { order, width } = viewCol;
      newCol = { ...newCol, order, width };

      if (viewCol.is_hidden) {
        hiddenColumns.push(newCol.id);
      }

      if (viewCol.sort) {
        sort = viewCol.sort;
      }

      if (viewCol.filter) {
        filters.push({ id: newCol.id, value: viewCol.filter.value });
      }

      if (viewCol.nested && Array.isArray(viewCol.nested)) {
        viewCol.nested.map((i) => {
          filters.push({ id: i.col_id, value: i.filter.value });
        });
      }
    } else {
      if (column.is_hidden) {
        hiddenColumns.push(newCol.id);
      }
    }

    newColumns.push(newCol);
  }
  newColumns = newColumns.sort((a, b) => a.order - b.order);

  if (isInvExpand) {
    newColumns.unshift({
      header: () => null,
      id: "expander",
      disableDND: true,
      disableResizing: true,
      width: 24,
      cell: isInvExpand
        ? ({ row }) => (
            <>
              {row.getCanExpand() ? (
                <>
                  {row.getIsExpanded() ? (
                    <Icon
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: "pointer" },
                      }}
                      icon={"chevron_down"}
                      size={24}
                      isPressable
                    />
                  ) : (
                    <Icon
                      {...{
                        onClick: row.getToggleExpandedHandler(),
                        style: { cursor: "pointer" },
                      }}
                      icon={"chevron_right"}
                      size={24}
                      isPressable
                    />
                  )}
                </>
              ) : (
                <>G</>
              )}
            </>
          )
        : () => null,
    });
  }

  return { columns: newColumns, hiddenColumns, filters, sort };
};
export default columnMapper_V2;
