function SingleTick() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.0216 14.2948L5.31084 11.7899C5.00879 11.5107 4.5286 11.5107 4.22654 11.7899C3.92449 12.069 3.92449 12.5127 4.22654 12.7918L7.4717 15.7907C7.77376 16.0698 8.26169 16.0698 8.56375 15.7907L16.7735 8.21133C17.0755 7.93221 17.0755 7.48847 16.7735 7.20934C16.4714 6.93022 15.9912 6.93022 15.6892 7.20934L8.0216 14.2948Z"
          //   fill="#ADADAD"
          style={{ fill: "#ADADAD" }}
        />
      </svg>
    </>
  );
}

export default SingleTick;
