import { HeadingXLarge, ParagraphLarge, LabelLarge } from "baseui/typography";
import Email from "../../../../../assets/img/svg/CustomerTemplate/Email";
import Info from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Info";
import InfoTag from "../../../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InfoTag";
import InfoPortal from "../../../../../assets/img/svg/InfoPortal";
import AllActionPopOver from "../AllActionPopOver";
import { isOpenModal } from "../../../../../redux/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { TextBox } from "../../../../../components/TextBox";
import { TextButton, SIZE } from "../../../../../components/TextButton";
import { useForm, Controller, useFieldArray, get } from "react-hook-form";
import { TextArea } from "../../../../../components/TextArea";
import Sms from "../../../../../assets/img/svg/CustomerOverViewIcon/Sms";
import { SelectBox } from "../../../../../components/SelectBox";
import DateRangeSelect from "../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import NextActionForward from "../../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/NextActionForward";
// import CheckBox from "../../../../../components/CheckBox/CheckBox";
import { Checkbox } from "baseui/checkbox";
import LeftSmallArrow from "../../../../../assets/img/svg/LeftSmallArrow";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import React, { useContext } from "react";
import { COMMUNICATION_TEMPLATE } from "../../../../../constants";
import { useQuery } from "react-query";
import { getCommunicationTemplateList } from "../../../../../services";
import ReviewPromiseToPay from "../../../../../assets/img/svg/ReviewPromiseToPay";
import ReviewPromiseTable from "../ReviewPromiseTable";
// import ReviewPromiseToPayTable from "../ReviewPromiseToPayTable";

const ReviewPromiseAction = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { referenceData } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  return (
    //   Container
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ padding: "10px 10px 0px 10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ textDecoration: "underline", color: "#516BEB" }}
            onClick={() => {
              dispatch(isOpenModal(false));
            }}
          >
            <ParagraphLarge
              style={{
                color: "#516BEB",
                cursor: "pointer",
                gap: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LeftSmallArrow /> Back
            </ParagraphLarge>
          </div>
        </div>
        {/* Section 1 */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <ReviewPromiseToPay />
            <HeadingXLarge>Review Promise</HeadingXLarge>
          </div>
        </div>
      </div>
      <ReviewPromiseTable />
    </div>
  );
};

export default ReviewPromiseAction;
