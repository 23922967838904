import { HeadingXLarge } from "baseui/typography";
import React from "react";

const WebAccessDeniedView = () => {
  return (
    <div className="app_access">
      <HeadingXLarge>
        You have access only through the mobile app. Kindly login using the
        mobile app.
      </HeadingXLarge>
    </div>
  );
};

export default WebAccessDeniedView;
