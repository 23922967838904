import React from "react";

function FaildSheild() {
  return (
    <div>
      <svg
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.44417 2.60742C1.02417 2.79409 0.75 3.21409 0.75 3.67492V6.41658C0.75 9.65409 2.99 12.6816 6 13.4166C9.01 12.6816 11.25 9.65409 11.25 6.41658V3.67492C11.25 3.21409 10.9758 2.79409 10.5558 2.60742L6.4725 0.793252C6.16917 0.659085 5.825 0.659085 5.5275 0.793252L1.44417 2.60742ZM6 4.08325C6.32083 4.08325 6.58333 4.34575 6.58333 4.66659C6.58333 4.98742 6.32083 5.24992 6 5.24992C5.67917 5.24992 5.41667 4.98742 5.41667 4.66659C5.41667 4.34575 5.67917 4.08325 6 4.08325ZM6 6.41658C6.32083 6.41658 6.58333 6.67909 6.58333 6.99992V9.33325C6.58333 9.65409 6.32083 9.91658 6 9.91658C5.67917 9.91658 5.41667 9.65409 5.41667 9.33325V6.99992C5.41667 6.67909 5.67917 6.41658 6 6.41658Z"
          fill="#FD372A"
        />
      </svg>
    </div>
  );
}

export default FaildSheild;
