import React from "react";
import { Card } from "baseui/card";

const CardLayout = ({ children, style }) => {
  return (
    <Card
      overrides={{
        Root: {
          style: {
            width: "102px",
            height: "142px",
            marginRight: "10px",
            marginLeft: "10px",
            borderRadius: "3px",
            ...style,
          },
        },
      }}
    >
      {children}
    </Card>
  );
};

export default CardLayout;
