import React, { useState } from "react";
import setSelectValues from "../../../utils/setSelectValues";
import { useSelector, useDispatch } from "react-redux";
import {
  updateMapFields,
  updateSystemField,
} from "../../../redux/adhocUploadData/action";
import { CellRefSelectBox } from "../../CellRefSelectBox";
import TagTick from "../../../assets/img/svg/CustomerOverViewIcon/TagTick";
import _ from "lodash";
import { Icon } from "../../../components_v2";

const SelectSystemField = (props) => {
  //
  let { value, column, alignment, accessor, row, ...rest } = props;

  const dispatch = useDispatch();
  const { headers, systemFields } = useSelector((e) => e.adhocDataReducer);

  const uploadFieldOptions = systemFields.map((systemColumn) => {
    return {
      label:
        _.get(systemColumn, "id", "") === "do_not_load" &&
        systemColumn?.isSelected ? (
          <div className={systemColumn?.isSelected ? "adhoc_dnl" : ""}>
            {systemColumn.system_column}{" "}
            {/* {systemColumn.required && (
              <span style={{ color: "#fd372a" }}>* </span>
            )} */}
            {systemColumn?.isSelected && (
              <Icon icon="dnd" color="#787878" size={15} />
            )}
          </div>
        ) : (
          <>
            {systemColumn.system_column}{" "}
            {systemColumn.required && (
              <span style={{ color: "#fd372a" }}>* </span>
            )}
            {systemColumn?.isSelected && <TagTick />}
          </>
        ),
      // label: systemColumn.system_column,
      category: systemColumn.system_column,
      id: systemColumn.id,
      module: systemColumn.module,
    };
  });
  value = _.get(row.original, accessor, "");

  const filterOptions = (uploadFieldOptions, filterValue) => {
    return uploadFieldOptions.filter((option) =>
      option.category.toLowerCase().includes(filterValue.toLowerCase())
    );
  };

  return (
    <CellRefSelectBox
      // clearable={false}
      placeholder=""
      value={setSelectValues(value)}
      onChange={(params) => {
        dispatch(
          updateSystemField({
            index: row.index,
            systemField: params?.value[0]?.id,
            module: params?.value[0]?.module,
            type: params?.type,
          })
        );
      }}
      filterOptions={filterOptions}
      options={uploadFieldOptions}
    />
  );
};

export default SelectSystemField;
