import React from 'react'

const ReplayAll = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.50934 8.24998L6.54901 11.2897C6.6543 11.3949 6.70562 11.5169 6.70297 11.6556C6.70033 11.7943 6.64709 11.9175 6.54324 12.0252C6.43315 12.1339 6.30899 12.1886 6.17077 12.1893C6.03256 12.19 5.9084 12.1353 5.7983 12.0252L2.47306 8.69998C2.40913 8.63603 2.36117 8.56668 2.32919 8.49193C2.29723 8.41717 2.28125 8.33652 2.28125 8.24998C2.28125 8.16345 2.29723 8.0828 2.32919 8.00804C2.36117 7.93329 2.40913 7.86273 2.47306 7.79638L5.7983 4.47115C5.89974 4.36971 6.02173 4.31778 6.16428 4.31537C6.30683 4.31297 6.43315 4.3649 6.54324 4.47115C6.64709 4.58123 6.69901 4.70635 6.69901 4.8465C6.69901 4.98664 6.64516 5.11175 6.53746 5.22184L3.50934 8.24998ZM7.23262 8.77281L9.74587 11.2897C9.85116 11.3949 9.90404 11.5179 9.90451 11.6585C9.90499 11.7991 9.85138 11.9245 9.74369 12.0346C9.63361 12.137 9.50885 12.1886 9.36942 12.1893C9.23001 12.19 9.10646 12.1353 8.99876 12.0252L5.67354 8.69998C5.60719 8.63603 5.55862 8.56668 5.52785 8.49193C5.49709 8.41717 5.48171 8.33652 5.48171 8.24998C5.48171 8.16345 5.49709 8.0828 5.52785 8.00804C5.55862 7.93329 5.60719 7.86273 5.67354 7.79638L9.00453 4.46537C9.10597 4.36394 9.227 4.31298 9.36762 4.3125C9.50825 4.31202 9.63361 4.36297 9.74369 4.46537C9.85138 4.57547 9.90522 4.70059 9.90522 4.84073C9.90522 4.98087 9.85138 5.10478 9.74369 5.21247L7.23262 7.72356H12.4891C13.456 7.72356 14.2788 8.06454 14.9576 8.7465C15.6365 9.42848 15.9759 10.2498 15.9759 11.2103V13.1971C15.9759 13.3428 15.9246 13.4669 15.8219 13.5695C15.7193 13.6722 15.5964 13.7235 15.4531 13.7235C15.3074 13.7235 15.1833 13.6722 15.0806 13.5695C14.978 13.4669 14.9267 13.3428 14.9267 13.1971V11.2103C14.9267 10.5372 14.6887 9.96272 14.2127 9.48675C13.7367 9.01079 13.1622 8.77281 12.4891 8.77281H7.23262Z" fill="#787878"/>
    </svg>
    
  )
}

export default ReplayAll