import React, { useEffect } from "react";
import { Pagination, SIZE } from "baseui/pagination";
import { Select } from "baseui/select";
import { Paragraph2 } from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import setSelectValues from "../../utils/setSelectValues";

const PaginationWraper = ({
  pageSize = 10,
  pageCount = 1,
  pageIndex = 0,
  gotoPage,
  setPageSize,
  makeTypeButton = false,
  localKey = "",
}) => {
  const options = [
    { label: 10, id: "10" },
    { label: 20, id: "20" },
    { label: 30, id: "30" },
    { label: 50, id: "50" },
  ];

  let localPageSize;

  if (localKey) {
    localPageSize = localStorage.getItem(localKey);
  }

  let initialPageSize = options.filter((e) => e.label === pageSize);

  const intl = useIntl();
  const [value, setValue] = React.useState(
    localPageSize ? setSelectValues(localPageSize) : initialPageSize
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "5px",
        paddingBottom: "5px",
        // height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Paragraph2 style={{ width: "95px" }}>
          <FormattedMessage id="rows_per_page">rows_per_page</FormattedMessage>:
        </Paragraph2>
        <Select
          size={SIZE.mini}
          options={options}
          value={value}
          placeholder="select"
          clearable={false}
          searchable={false}
          onChange={(params) => {
            setValue(params.value);
            setPageSize(params.value[0].label);
            if (localKey) {
              localStorage.setItem(localKey, params.value[0].label);
            }
          }}
          overrides={{
            Root: {
              style: () => ({
                width: "50px",
              }),
            },
            ControlContainer: {
              style: ({ $theme }) => ({
                padding: "0px",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                border: `0.5px solid ${$theme.colors.inputBorder}`,
              }),
            },
            Dropdown: {
              style: () => ({
                borderRadius: "10px",
              }),
            },
            DropdownListItem: {
              style: () => ({
                borderBottom: "0.5px solid #D6D6D640",
              }),
            },
            IconsContainer: {
              style: () => ({
                paddingRight: "2px",
              }),
            },
            ValueContainer: {
              style: () => ({
                paddingLeft: "5px",
              }),
            },
          }}
        />
      </div>

      <div>
        <Pagination
          numPages={pageCount}
          size={SIZE.mini}
          currentPage={pageIndex + 1}
          onPageChange={({ nextPage }) => {
            gotoPage(nextPage - 1);
            // setCurrentPage(Math.min(Math.max(nextPage, 1), 20));
          }}
          labels={{
            prevButton: intl.formatMessage({ id: "prev" }),
            nextButton: intl.formatMessage({ id: "next" }),
          }}
          overrides={{
            NextButton: {
              ...(makeTypeButton
                ? {
                    props: {
                      type: "button", // Explicitly set the type to "button"
                    },
                  }
                : {}),
              style: ({ $theme }) => ({
                color: `${$theme.colors.contentPrimary}`,
                fontWeight: "400",
              }),
            },
            PrevButton: {
              ...(makeTypeButton
                ? {
                    props: {
                      type: "button", // Explicitly set the type to "button"
                    },
                  }
                : {}),

              style: ({ $theme }) => ({
                color: `${$theme.colors.contentPrimary}`,
                fontWeight: "400",
              }),
            },
            MaxLabel: {
              style: ({ $theme }) => ({
                color: `${$theme.colors.contentPrimary}`,
                fontWeight: "400",
              }),
            },
            Select: {
              props: {
                overrides: {
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      padding: "0px",
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      borderBottomRightRadius: "5px",
                      border: `0.5px solid ${$theme.colors.inputBorder}`,
                      height: "30px",
                      width: "52px",
                    }),
                  },
                  Dropdown: {
                    style: () => ({
                      borderRadius: "10px",
                    }),
                  },
                  DropdownListItem: {
                    style: () => ({
                      borderBottom: "0.5px solid #D6D6D640",
                    }),
                  },
                  IconsContainer: {
                    style: () => ({
                      paddingRight: "2px",
                    }),
                  },
                  ValueContainer: {
                    style: () => ({
                      paddingLeft: "5px",
                    }),
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default PaginationWraper;
