import React from "react";
import { StyledSvg } from "baseui/icon";

const Download = ({ size = "22px", color = "#ffffff" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M10.0431 4.91667V15.1558L5.56979 10.6825C5.21229 10.325 4.62562 10.325 4.26812 10.6825C3.91063 11.04 3.91063 11.6175 4.26812 11.975L10.309 18.0158C10.6665 18.3733 11.244 18.3733 11.6015 18.0158L17.6423 11.975C17.9998 11.6175 17.9998 11.04 17.6423 10.6825C17.2848 10.325 16.7073 10.325 16.3498 10.6825L11.8765 15.1558V4.91667C11.8765 4.4125 11.464 4 10.9598 4C10.4556 4 10.0431 4.4125 10.0431 4.91667Z"
        // fill="#323232"
        fill={color}
      />
    </svg>
  );
};

export default Download;
