import React from "react";

const EditOutline = ({ size = 18, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.0962 7.88069L6.55573 14.4212C6.51324 14.4637 6.46145 14.4957 6.40445 14.5147L3.51007 15.4795C3.20752 15.5803 2.91968 15.2925 3.02053 14.9899L3.98532 12.0956C4.00432 12.0386 4.03633 11.9868 4.07882 11.9443L10.6193 5.40378L13.0962 7.88069ZM10.6193 6.6079L4.7584 12.4688L4.122 14.378L6.03119 13.7416L11.8921 7.88069L10.6193 6.6079ZM13.509 7.46788L11.0321 4.99097L12.4097 3.61335C12.5609 3.46222 12.8059 3.46222 12.9571 3.61335L14.8866 5.54294C15.0378 5.69408 15.0378 5.93913 14.8866 6.09027L13.509 7.46788ZM13.509 6.26376L13.9562 5.8166L12.6834 4.54381L12.2362 4.99097L13.509 6.26376ZM8.2125 14.75C8.2125 14.5222 8.39718 14.3375 8.625 14.3375H14.25C14.4778 14.3375 14.6625 14.5222 14.6625 14.75C14.6625 14.9778 14.4778 15.1625 14.25 15.1625H8.625C8.39718 15.1625 8.2125 14.9778 8.2125 14.75Z"
        fill={color}
      />
    </svg>
  );
};

export default EditOutline;
