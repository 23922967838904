import React, { useState } from "react";
import { Tag } from "baseui/tag";
import IconButton from "../../../components/IconButton/IconButton";
import { KIND } from "baseui/button";
import { useForm } from "react-hook-form";
import { Avatar } from "baseui/avatar";
import {
  HeadingXSmall,
  Label1,
  Label2,
  Label3,
  LabelLarge,
  LabelMedium,
  Paragraph2,
  ParagraphLarge,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import DeliverTick from "../../../assets/img/svg/DeliverTick";
import Warning from "../../../assets/img/svg/Warning";
import { StyledLink } from "baseui/link";
import TimelineBot from "../../../assets/img/timelinebot.png";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import moment from "moment";
import "moment-timezone";
import PromiseToPay from "../../../assets/img/svg/PromiseToPay";
import ReactQuill from "react-quill";

import {
  LETTER,
  SMS,
  EMAIL,
  INVOICE,
  INVOICE_UPDATE,
  NOTE,
  BOT,
  CALLBACKLATER,
  PROMISETOPAY,
  MEETINGREQUEST,
  CALLUNSUCCESSFUL,
  HOLDACTION,
  DISPUTE,
  REVIEWPROMISE,
  NEXTACTION,
  SKIPPEDTIMELINE,
  CALL_DISPUTE,
  CALL_CALL_BACK_LATER,
  CALL_UNSUCCESSFUL,
  RELEASED,
  CALL,
  REPLY,
  REPLY_ALL,
  FORWARD,
  WHATSAPP,
  RM,
  WORKFLOW,
  BUSINESS_UNIT,
  CUSTOMERT_STATUS,
  NOTES,
  CONTACTS,
  CUSTOMERPORTAL,
  PORTAL_PAYMENT,
  APP_PAYMENT,
  PAYMENT_PLAN,
  PAYMENT,
  CALL_RECORDING,
  PORTAL_PAYMENT_PLAN,
  CUSTOMER_NAME_UPDATE,
  CUSTOMER_OVERVIEW,
  INVOICE_OVERVIEW,
  SKIP_TRACE,
} from "../../../constants";
import TemplateIcon from "../../communicationTemplate/TemplateIcon";
import CollapseUpArrow from "../../../assets/img/svg/CollapseUpArrow";
import CollapseDownArrow from "../../../assets/img/svg/CollapseDownArrow";
import SingleTick from "../../../assets/img/svg/SingleTick";
import { TextArea } from "../../../components/TextArea";
import { TextBox } from "../../../components/TextBox";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { TextButton, SIZE } from "../../../components/TextButton";
import { FormattedMessage } from "react-intl";
import Alert from "../../../assets/img/svg/Alert";
import { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";
import PtpPartial from "../../../assets/img/svg/PtpPartial";
import PtpKept from "../../../assets/img/svg/PtpKept";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { INBOUND_EMAIL } from "../../../providers/RBACProvider/permissionList";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import { markEmailAsReadCustomerTimeline } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import {
  drawerOpen,
  emailDataToDrawer,
  promiseTopayDataToDrawer,
  refetchTimeline,
  tabMotion,
} from "../../../redux/actions";
import { NumericFormat, useNumericFormat } from "react-number-format";
import Reply from "../../../assets/img/svg/Reply";
import ReplyAll from "../../../assets/img/svg/ReplyAll";
import InfoIcon from "../../../assets/img/svg/InfoIcon";
import Forward from "../../../assets/img/svg/Forward";
import QuillFrame from "../../../components/QuillIframe/QuillIframe";
import DocumentCard from "../../../components/DocumentCard/DocumentCard";
import {
  timelineChatRefetch,
  timelineWhatsappDrawer,
} from "../../../redux/customerOverview/timeline/action";
import AlertInfo from "../../../assets/img/svg/AlertInfo";
import { StatefulPopover } from "baseui/popover";
import PaymentPlanDrawer from "./Actions/PaymentPlanDrawer";
import PortalPayment from "../../../assets/img/svg/PortalPayment";

import { useQueryClient } from "react-query";
import { StatefulTooltip } from "baseui/tooltip";
import { getSubCategory } from "../../../services/documentSystem";

const DeliveryStatus = ({ type }) => {
  const commonStyles = {
    fontSize: "12px",
    padding: "5px",
    borderBottomLeftRadius: "5px",
    borderTopRightRadius: "5px",
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: 0,
    background: "#FFFBE980",
    border: "1px solid #FFD93D",
    color: "#FFD93D",
    display: "flex",
    justifyContent: "center",
    gap: 3,
  };

  switch (type) {
    case "PARTIALLY_DELIVERED_AND_READ":
      return (
        <div
          style={{
            ...commonStyles,
          }}
        >
          <FormattedMessage id="Partially_Delivered_and_Read">
            Partially_Delivered_and_Read
          </FormattedMessage>
          <InfoIcon color="#FFD93D" size={15} />
        </div>
      );

    case "PARTIALLY_DELIVERED":
      return (
        <div
          style={{
            ...commonStyles,
          }}
        >
          <FormattedMessage id=" Partially_Delivered">
            Partially_Delivered
          </FormattedMessage>
          <InfoIcon color="#FFD93D" size={15} />
        </div>
      );

    case "BOUNCED":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#FFEBEB",
            border: "1px solid #E90E0E",
            color: "#E90E0E",
          }}
        >
          <FormattedMessage id="Bounced">Bounced</FormattedMessage>
          <InfoIcon color="#E90E0E" size={15} />
        </div>
      );

    case "DELIVERED":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#F0FFF1",
            border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          <FormattedMessage id="Delivered">Delivered</FormattedMessage>
          <InfoIcon color="#48B24F" size={15} />
        </div>
      );

    case "READ":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#F0FFF1",
            border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          <FormattedMessage id="Read">Read</FormattedMessage>
          <InfoIcon color="#48B24F" size={15} />
        </div>
      );

    case "SENT":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#F0FFF1",
            border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          <FormattedMessage id="Sent">Sent</FormattedMessage>
          <InfoIcon color="#48B24F" size={15} />
        </div>
      );

    default:
      return null;
  }
};

const FieldVisit = ({ data, type }) => {
  const dispatch = useDispatch();

  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const [expand, setExpand] = React.useState(false);

  const orgRef = referenceData.organizations.find(
    ({ id }) => id === currentOrganization
  );

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  const timeZone = orgRef.time_zone;
  const [isDrawer, setIsDrawer] = useState(false);
  const [isId, setIsId] = useState();
  const handlerDrawers = () => {
    if (data && data?.content && data?.content?.payment_plan_id !== undefined) {
      setIsDrawer(!isDrawer);
      setIsId(data.content.payment_plan_id);
    }
  };
  let intl = useIntl();
  switch (type) {
    case "RETURN_VISIT":
      let returnVisit = data;

      let returnVisitContent = returnVisit?.content;
      let returnVisitLocation = returnVisitContent?.visit_address;

      let rdReturnTime = referenceData && referenceData["time"];

      let returnVisitedTime = rdReturnTime?.find(
        (e) => e?.id === returnVisitContent?.return_visit_time
      );

      let returnVisitedDate = moment
        .utc(returnVisitContent?.return_visit_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);

      // let filteringReturnUsers = users?.filter(
      //   (i) => i.id === returnVisit.executed_by
      // );

      const executedOnDateFormate = moment
        .utc(returnVisit?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let users = referenceData["users"];

      let filteringCallUsers = users?.find(
        (i) => i.id === returnVisit.executed_by
      );
      let returnlabel = referenceData["visit_meeting_person_list"];
      let returnPerson = returnlabel.find(
        (e) => e?.id === returnVisit?.content?.visit_person
      );

      return (
        <>
          <>
            <div className={"cp-timeline-contant__status-view"}>
              <div className="cp-status-view__icon">
                <TemplateIcon type={"FIELD_VISIT"} />
                {/* RETURN VISIT */}
              </div>
              <div className="cp-status-view__discretion">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    // ...(is_receiver ? { position: "relative" } : {}),
                  }}
                >
                  <div className="cp-discretion__text-content">
                    <div className="cp-text-content__text-deliver">
                      <div
                        $style={{
                          marginRight: "12px",
                          display: "flex",
                          flexDirection: "row",
                          gap: "3px",
                        }}
                      >
                        <LabelLarge
                          $style={{
                            color: "#333860",
                            paddingRight: "2px",
                            maxWidth: "calc(100% - 30px)",
                            whiteSpace: "nowrap",
                            // width: 50px;
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Field Visit - Return Visit on {returnVisitedDate}{" "}
                          {returnVisitedTime?.label}
                          {returnVisitContent.custom_location !== "" ? (
                            <>{returnVisitContent?.custom_location}</>
                          ) : (
                            <>
                              {returnVisitLocation?.line_1}{" "}
                              {/* {location?.line_2} {location?.city}{" "}
                              {location?.state} {location?.city}{" "}
                              {location?.zip_code} {location?.country} */}
                            </>
                          )}
                        </LabelLarge>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "3px",
                        alignItems: "baseline",
                      }}
                    >
                      <Paragraph2 $style={{ color: "#ADADAD", width: "100px" }}>
                        Location:{" "}
                      </Paragraph2>

                      <div>
                        {returnVisitContent.custom_location !== "" ? (
                          <>
                            At other Location -{" "}
                            {returnVisitContent?.custom_location}
                          </>
                        ) : (
                          <>
                            <Paragraph2 style={{ color: "#787878" }}>
                              {returnVisitLocation?.line_1}{" "}
                              {returnVisitLocation?.line_1}{" "}
                              {returnVisitLocation?.line_2}{" "}
                              {returnVisitLocation?.city}{" "}
                              {returnVisitLocation?.state}{" "}
                              {returnVisitLocation?.city}{" "}
                              {returnVisitLocation?.zip_code}{" "}
                              {returnVisitLocation?.country}
                            </Paragraph2>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "3px",
                        alignItems: "baseline",
                      }}
                    >
                      <Paragraph2 $style={{ color: "#ADADAD", width: "100px" }}>
                        Whom You Met :{" "}
                      </Paragraph2>

                      <div>
                        <Paragraph2>{returnPerson?.label}</Paragraph2>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "3px",
                        alignItems: "baseline",
                      }}
                    >
                      <Paragraph2 $style={{ color: "#ADADAD", width: "100px" }}>
                        Old Date :{" "}
                      </Paragraph2>

                      <div>
                        <Paragraph2>
                          {executedOnDateFormate}
                          {/* {moment.utc(rdReason?.content?.visit_date)} */}
                        </Paragraph2>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "3px",
                        alignItems: "baseline",
                      }}
                    >
                      <Paragraph2 $style={{ color: "#ADADAD", width: "100px" }}>
                        New Date :{" "}
                      </Paragraph2>

                      <div>
                        <Paragraph2>
                          {returnVisitedDate}
                          {/* {moment.utc(rdReason?.content?.visit_date)} */}
                        </Paragraph2>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "3px",
                        alignItems: "baseline",
                      }}
                    >
                      <Paragraph2 $style={{ color: "#ADADAD", width: "100px" }}>
                        New Time :{" "}
                      </Paragraph2>

                      <div>
                        <Paragraph2>{returnVisitedTime?.label}</Paragraph2>
                      </div>
                    </div>
                    <Paragraph2 $style={{ color: "#ADADAD" }}>
                      Comments{" "}
                      <span
                        style={{
                          color: "#787878",
                        }}
                      >
                        {returnVisit.comment}
                      </span>
                    </Paragraph2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className="cp-discretion__profile">
                  <ProfileIcon />
                </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="cp-status-view__day">
            <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
          </div> */}
              <div
                className="cp-status-view__day"
                style={{
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                }}
              >
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {/* {executedOnDateFormate}{" "} */}
                  {/* {filteringReturnUsers} */}
                  {filteringCallUsers?.label}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {/* {executedOnDateFormate}{" "} */}
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={new Date(returnVisit?.executed_on)} />
                </Label2>
              </div>
            </div>
          </>
        </>
      );

    case "PROMISE_TO_PAY":
      let promiseToPayFl = data;

      let promiseToPayFlContent = promiseToPayFl?.content;
      let promiseToPayFlLocation = promiseToPayFlContent?.visit_address;
      let ppRdTime = referenceData && referenceData["time"];

      let promiseToPayTime = ppRdTime?.find(
        (e) => e?.id === promiseToPayFlContent?.visit_time
      );

      let promiseToPayDate = moment
        .utc(promiseToPayFlContent?.promise_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      //
      // let filteringP2PUsers = users?.filter(
      //   (i) => i.id === promiseToPayFl.executed_by
      // );

      let promiseStatusRd = referenceData["promise_status"];

      let promiseStatus = promiseToPayFlContent?.promise_status;

      const StatusFinding = promiseStatusRd?.find(
        (i) => i.id === promiseStatus
      );

      const executedOnDateFormateP2P = moment
        .utc(promiseToPayFl?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let usersp2P = referenceData["users"];

      let filteringp2PCallUsers = usersp2P?.find(
        (i) => i.id === promiseToPayFl.executed_by
      );

      const executedPromiseFormate = moment
        .utc(promiseToPayFl?.promise_date)
        .format(currentDefaultFormatDetails.date_format);
      let rdpaymentLabel = referenceData["visit_meeting_person_list"];
      let person = rdpaymentLabel.find(
        (e) => e?.id === promiseToPayFl?.content?.visit_person
      );

      return (
        <div className={"cp-timeline-contant__status-view"}>
          <div className="cp-status-view__icon">
            {/* <TemplateIcon type={EMAIL} /> */}
            <PromiseToPay />
            {/* P2P */}
          </div>
          <div className="cp-status-view__discretion">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                // ...(is_receiver ? { position: "relative" } : {}),
              }}
            >
              <div className="cp-discretion__text-content">
                <div className="cp-text-content__text-deliver">
                  <LabelLarge
                    $style={{ color: "#333860", paddingRight: "2px" }}
                  >
                    Field Visit - Promise To Pay by {promiseToPayDate}{" "}
                  </LabelLarge>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "3px",
                    alignItems: "baseline",
                  }}
                >
                  <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                    Location :
                  </Paragraph2>

                  <div>
                    {promiseToPayFlContent?.custom_location !== "" ? (
                      <>
                        At other Location -{" "}
                        {promiseToPayFlContent?.custom_location}
                      </>
                    ) : (
                      <>
                        <Paragraph2>
                          {promiseToPayFlLocation?.line_1}{" "}
                          {promiseToPayFlLocation?.line_1}{" "}
                          {promiseToPayFlLocation?.line_2}{" "}
                          {promiseToPayFlLocation?.city}{" "}
                          {promiseToPayFlLocation?.state}{" "}
                          {promiseToPayFlLocation?.city}{" "}
                          {promiseToPayFlLocation?.zip_code}{" "}
                          {promiseToPayFlLocation?.country}
                        </Paragraph2>
                      </>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "3px",
                    alignItems: "baseline",
                  }}
                >
                  <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                    Whom You Met :
                  </Paragraph2>

                  <div>
                    <Paragraph2>{person?.label}</Paragraph2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "3px",
                    alignItems: "baseline",
                  }}
                >
                  <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                    Promise Type :
                  </Paragraph2>

                  <div>
                    <Paragraph2>
                      {promiseToPayFl?.content?.promise_type ? (
                        <FormattedMessage
                          id={promiseToPayFl?.content?.promise_type}
                        />
                      ) : (
                        "--"
                      )}
                    </Paragraph2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "3px",
                    alignItems: "baseline",
                  }}
                >
                  <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                    Promise Amount :
                  </Paragraph2>

                  <div>
                    <Paragraph2>
                      <span style={{ fontSize: "11px", color: "#787878" }}>
                        {promiseToPayFl?.content?.promise_amount?.currency}
                      </span>
                      &nbsp;
                      {promiseToPayFl?.content?.promise_amount?.value}
                    </Paragraph2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "3px",
                    alignItems: "baseline",
                  }}
                >
                  <Paragraph2 $style={{ color: "#ADADAD", width: "100px" }}>
                    Promise Date :
                  </Paragraph2>

                  <div>
                    <Paragraph2>{promiseToPayDate}</Paragraph2>
                  </div>
                </div>

                <div>
                  {promiseToPayFl?.content?.is_hold_workflow ? (
                    <Paragraph2>
                      Workflow Paused till Promise To Pay End Date
                    </Paragraph2>
                  ) : (
                    ""
                  )}
                  {promiseToPayFl?.content?.is_send_action_follow_up ? (
                    <Paragraph2>
                      Promise To Pay Reminder Message Sent
                    </Paragraph2>
                  ) : (
                    ""
                  )}
                  {promiseToPayFl?.content?.is_send_confirmation_message ? (
                    <Paragraph2>Confirmation Message Sent</Paragraph2>
                  ) : (
                    ""
                  )}
                </div>

                <Paragraph2 $style={{ color: "#ADADAD" }}>
                  Comment :&nbsp;
                  <span
                    style={{
                      color: "#787878",
                    }}
                  >
                    {promiseToPayFl.comment ? promiseToPayFl.comment : " -- "}
                  </span>
                </Paragraph2>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <div className="cp-discretion__profile">
                <ProfileIcon />
              </div> */}
              </div>
            </div>
          </div>
          {/* <div className="cp-status-view__day">
          <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
        </div> */}
          <div
            className="cp-status-view__day"
            style={{
              gap: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <Label2 $style={{ color: "#C4C4C4" }}>
              {/* {executedOnDateFormate}{" "} */}
              {/* {filteringP2PUsers} */}
              {filteringp2PCallUsers?.label}
            </Label2>
            <Label2 $style={{ color: "#C4C4C4" }}>
              {executedOnDateFormateP2P}{" "}
              <FormattedTime value={new Date(promiseToPayFl.executed_on)} />
            </Label2>
          </div>
        </div>
      );

    case "CALL_BACK_LATER":
      let callBackLaterFl = data;

      let callBackLaterFlContent = callBackLaterFl?.content;
      let callBackLaterFlLocation = callBackLaterFlContent?.visit_address;

      let callBackLaterFlRdTime = referenceData && referenceData["time"];

      let callBackLaterFlTime = callBackLaterFlRdTime?.find(
        (e) => e?.id === callBackLaterFlContent?.callback_time
      );

      let callBackLaterFlDate = moment
        .utc(callBackLaterFlContent.callback_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      //

      let userscBL = referenceData["users"];

      let filteringcBLCallUsers = userscBL?.find(
        (i) => i.id === callBackLaterFl?.executed_by
      );

      const executedDateFormate = moment
        .utc(callBackLaterFl.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let slicedCallVal = callBackLaterFlContent?.visit_time?.replace("_", ":");
      let slicedCallFinalVal = slicedCallVal?.replace("_", " ");
      let callBackLabel = referenceData["visit_meeting_person_list"];
      let callbackperson = callBackLabel.find(
        (e) => e?.id === callBackLaterFl?.content?.visit_person
      );
      return (
        <>
          <div className={"cp-timeline-contant__status-view"}>
            <div className="cp-status-view__icon">
              {/* <TemplateIcon type={EMAIL} /> */}
              {/* C_B_L */}
              <TemplateIcon type={CALLBACKLATER} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // ...(is_receiver ? { position: "relative" } : {}),
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <LabelLarge
                      $style={{ color: "#333860", paddingRight: "2px" }}
                    >
                      Field Visit - Call Back Later by {callBackLaterFlDate}{" "}
                    </LabelLarge>{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                      Location:{" "}
                    </Paragraph2>

                    <div>
                      {callBackLaterFlContent?.custom_location !== "" ? (
                        <Paragraph2>
                          At other Location -{" "}
                          {callBackLaterFlContent?.custom_location}
                        </Paragraph2>
                      ) : (
                        <>
                          <Paragraph2>
                            {callBackLaterFlLocation?.line_1}{" "}
                            {callBackLaterFlLocation?.line_1}{" "}
                            {callBackLaterFlLocation?.line_2}{" "}
                            {callBackLaterFlLocation?.city}{" "}
                            {callBackLaterFlLocation?.state}{" "}
                            {callBackLaterFlLocation?.city}{" "}
                            {callBackLaterFlLocation?.zip_code}{" "}
                            {callBackLaterFlLocation?.country}
                          </Paragraph2>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                      Whom You Met :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>{callbackperson.label}</Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                      Call Back Date :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>{callBackLaterFlDate}</Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                      Call Back Time :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>{callBackLaterFlTime?.label}</Paragraph2>
                    </div>
                  </div>
                  {callBackLaterFlContent?.is_hold_workflow ? (
                    <>
                      <Paragraph2>
                        Workflow is paused till Call Back Date
                      </Paragraph2>
                    </>
                  ) : (
                    <></>
                  )}{" "}
                  {callBackLaterFlContent?.confirmation_message_template_id ? (
                    <>
                      <Paragraph2>Confirmation Message Sent</Paragraph2>
                    </>
                  ) : (
                    <></>
                  )}{" "}
                  {callBackLaterFlContent?.action_follow_up_template_id ? (
                    <>
                      <Paragraph2>Call Back Reminder Message Sent</Paragraph2>
                    </>
                  ) : (
                    <></>
                  )}
                  <Paragraph2 $style={{ color: "#ADADAD" }}>
                    Comments{" "}
                    <span
                      style={{
                        color: "#787878",
                      }}
                    >
                      {callBackLaterFl?.comment}
                    </span>
                  </Paragraph2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                  <ProfileIcon />
                </div> */}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
            <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
          </div> */}
            <div
              className="cp-status-view__day"
              style={{
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {filteringcBLCallUsers?.label}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedDateFormate}{" "}
                <FormattedTime value={new Date(callBackLaterFl?.executed_on)} />
              </Label2>
            </div>
          </div>
        </>
      );

    case "RECORD_DISPUTE":
      let disputeDataFL = data;

      let disputeDataFLContent = disputeDataFL?.content;
      let disputeDataFLLocation = disputeDataFLContent?.visit_address;

      let disputeDataFLrdReturnTime = referenceData && referenceData["time"];

      let disputeDataFLVisitedTime = disputeDataFLrdReturnTime?.find(
        (e) => e?.id === disputeDataFLContent?.visit_time
      );
      let usersRd = referenceData["users"];

      let disputeDataFLVisitedDate = moment
        .utc(disputeDataFLContent?.dispute_review_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);
      //

      let filteringRecordUsers = usersRd?.find(
        (i) => i.id === disputeDataFL.executed_by
      );
      //

      const executedRDOnDateFormate = moment
        .utc(disputeDataFL.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const executeDisputeDate = moment
        .utc(disputeDataFLContent.dispute_review_date)
        .format(currentDefaultFormatDetails.date_format);
      let dislabel = referenceData["visit_meeting_person_list"];
      let visitPerson = dislabel.find(
        (e) => e?.id === disputeDataFL?.content?.visit_person
      );

      return (
        <>
          <div className={"cp-timeline-contant__status-view"}>
            <div className="cp-status-view__icon">
              {/* <TemplateIcon type={EMAIL} /> */}
              <TemplateIcon type={DISPUTE} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // ...(is_receiver ? { position: "relative" } : {}),
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <LabelLarge
                      $style={{ color: "#333860", paddingRight: "2px" }}
                    >
                      Field Visit - Dispute{" "}
                      {disputeDataFL?.content?.dispute_type}
                    </LabelLarge>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "143px" }}>
                      Location :{" "}
                    </Paragraph2>

                    <div>
                      {disputeDataFL.custom_location === "" ||
                      !disputeDataFL.custom_location ? (
                        <Paragraph2>
                          {disputeDataFLLocation?.line_1}{" "}
                          {disputeDataFLLocation?.line_1}{" "}
                          {disputeDataFLLocation?.line_2}{" "}
                          {disputeDataFLLocation?.city}{" "}
                          {disputeDataFLLocation?.state}{" "}
                          {disputeDataFLLocation?.city}{" "}
                          {disputeDataFLLocation?.zip_code}{" "}
                          {disputeDataFLLocation?.country}
                        </Paragraph2>
                      ) : (
                        <>
                          <Paragraph2>
                            At other Location - {disputeDataFL.custom_location}
                          </Paragraph2>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "143px" }}>
                      Whom You Met :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>{visitPerson?.label}</Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "143px" }}>
                      Dispute Detailed Text :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>
                        {disputeDataFL?.content?.dispute_details}
                      </Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "143px" }}>
                      Dispute Category :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>
                        {disputeDataFL?.content?.dispute_type}
                      </Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "143px" }}>
                      Dispute Review Date :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>{disputeDataFLVisitedDate}</Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "143px" }}>
                      Dispute Status :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>
                        {data?.content?.dispute_status === "CLOSED"
                          ? "Dispute - Closed"
                          : data?.content?.dispute_status === "OPEN"
                          ? "Dispute - Open"
                          : "Dispute - InProgress"}
                      </Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "143px" }}>
                      Linked Invoice No# :{" "}
                    </Paragraph2>

                    <div>
                      <Paragraph2>
                        {disputeDataFL?.content?.dispute_invoices.lenght > 0
                          ? disputeDataFL?.content?.dispute_invoices
                          : "--"}
                      </Paragraph2>
                    </div>
                  </div>
                  <div>
                    {disputeDataFL?.content?.is_hold_workflow ? (
                      <Paragraph2>Workflow Paused till Review Date</Paragraph2>
                    ) : (
                      ""
                    )}
                  </div>
                  <Paragraph2 $style={{ color: "#ADADAD" }}>
                    Comments{" "}
                    <span
                      style={{
                        color: "#787878",
                      }}
                    >
                      {disputeDataFL.comment}
                    </span>
                  </Paragraph2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
              <ProfileIcon />
            </div> */}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
        <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
      </div> */}
            <div
              className="cp-status-view__day"
              style={{
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {filteringRecordUsers.label}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedRDOnDateFormate}{" "}
                <FormattedTime value={new Date(disputeDataFL.executed_on)} />
              </Label2>
            </div>
          </div>
        </>
      );

    case "PAYMENT":
      let paymentFL = data;
      let paymentContent = paymentFL.content;

      let paymentFlLocation = paymentContent?.visit_address;

      let returnPayVisitedDate = moment
        .utc(paymentContent?.visit_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);

      let slicedVal = paymentContent?.visit_time?.replace("_", ":");
      let slicedFinalVal = slicedVal?.replace("_", " ");

      const executedOnDate = moment
        .utc(paymentFL?.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let usersPay = referenceData["users"];

      let filteringCallPayUsers = usersPay?.find(
        (i) => i.id === paymentFL?.executed_by
      );
      let labelMethod = referenceData["payment_method"];
      let method = paymentFL?.content?.portal_payment_method
        ? labelMethod.find(
            (i) => i.id == paymentFL?.content?.portal_payment_method
          )
        : [];
      let payPerson = referenceData["visit_meeting_person_list"];
      let paymentPerson = paymentFL?.content?.visit_person
        ? payPerson.find((e) => e?.id === paymentFL?.content?.visit_person)
        : [];
      let payInvoice = paymentFL?.content?.portal_payment_invoice_id;

      return (
        <>
          <div className={"cp-timeline-contant__status-view"}>
            <div className="cp-status-view__icon">
              {/* <TemplateIcon type={EMAIL} /> */}
              <TemplateIcon type={"FIELD_VISIT"} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // ...(is_receiver ? { position: "relative" } : {}),
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <LabelLarge
                      $style={{
                        color: "#333860",
                        paddingRight: "2px",
                        maxWidth: "calc(100% - 30px)",
                        whiteSpace: "nowrap",
                        // width: 50px;
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Field Visit - Payment -{" "}
                      {paymentFL.content?.portal_payment_amount?.currency}
                      &nbsp;
                      {paymentFL.content?.portal_payment_amount?.value}{" "}
                      {method && method.label ? method.label : "-"}
                    </LabelLarge>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                      Location :
                    </Paragraph2>
                    <div>
                      <div>
                        {paymentContent.custom_location !== "" ||
                        !paymentContent.custom_location ? (
                          <>
                            <Paragraph2>
                              {paymentFlLocation?.line_1}{" "}
                              {paymentFlLocation?.line_1}{" "}
                              {paymentFlLocation?.line_2}{" "}
                              {paymentFlLocation?.city}{" "}
                              {paymentFlLocation?.state}{" "}
                              {paymentFlLocation?.city}{" "}
                              {paymentFlLocation?.zip_code}{" "}
                              {paymentFlLocation?.country}
                            </Paragraph2>
                          </>
                        ) : (
                          <>
                            <Paragraph2>
                              At other Location -{" "}
                              {paymentContent.custom_location}
                            </Paragraph2>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105px" }}>
                      Whom You Met :
                    </Paragraph2>
                    <div>
                      <Paragraph2>
                        {paymentPerson && paymentPerson?.label
                          ? paymentPerson?.label
                          : ""}
                      </Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "115px" }}>
                      Payment Amount :
                    </Paragraph2>
                    <div>
                      <Paragraph2>
                        {" "}
                        {paymentFL.content?.portal_payment_amount?.currency}
                        &nbsp;
                        {paymentFL.content?.portal_payment_amount?.value}{" "}
                      </Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "115px" }}>
                      Payment Date :
                    </Paragraph2>
                    <div>
                      <Paragraph2> {returnPayVisitedDate}</Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "115px" }}>
                      Payment method :
                    </Paragraph2>
                    <div>
                      <Paragraph2>{method.label}</Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "149px" }}>
                      Payment Reference :
                    </Paragraph2>
                    <div>
                      <Paragraph2>
                        {paymentFL?.content?.portal_payment_reference}
                      </Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105x" }}>
                      Invoice# :
                    </Paragraph2>
                    <div>
                      <Paragraph2>
                        {payInvoice.length > 0 ? [...payInvoice] : " -- "}
                      </Paragraph2>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "105x" }}>
                      Comment :
                    </Paragraph2>
                    <div>
                      <Paragraph2>
                        {paymentFL?.comment ? paymentFL?.comment : "--"}
                      </Paragraph2>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
              <ProfileIcon />
            </div> */}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
        <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
      </div> */}
            <div
              className="cp-status-view__day"
              style={{
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {filteringCallPayUsers?.label}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {executedOnDate}{" "}
                <FormattedTime value={new Date(paymentFL?.executed_on)} />
              </Label2>
            </div>
          </div>
        </>
      );

    case "UNSUCCESSFULL":
      let unsuccessFL = data;

      let unsuccessFullContent = unsuccessFL.content;
      let usersUn = referenceData["users"];

      let filteringUnsuccCallUsers = usersUn?.find(
        (i) => i.id === unsuccessFL?.executed_by
      );

      let rdTime = referenceData && referenceData["time"];

      let visitedunTime = rdTime?.find(
        (e) => e?.id === unsuccessFullContent?.visit_time
      );

      let followUpTime = rdTime?.find(
        (e) => e?.id === unsuccessFullContent?.follow_up_time
      );

      let visitedunDate = moment
        .utc(unsuccessFullContent?.follow_up_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);

      const executedUnsuccOnDateFormate = moment
        .utc(unsuccessFL?.executed_on)
        .format(currentDefaultFormatDetails?.date_format);

      let rdReason = referenceData["visit_unsuccess_reason_list"];

      let reason = rdReason.find((e) => e.id === unsuccessFullContent?.reason);

      let returnunVisitLocation = unsuccessFullContent?.visit_address;

      return (
        <>
          <div className={"cp-timeline-contant__status-view"}>
            <div className="cp-status-view__icon">
              {/* <TemplateIcon type={EMAIL} /> */}
              <TemplateIcon type={"FIELD_VISIT"} />

              {/* UNSUCCESSFUL_FL */}
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // ...(is_receiver ? { position: "relative" } : {}),
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {/* {type ? <TypeName /> : ""} */}
                      <span
                        className="cp-discretion__text-content__weight"
                        style={{ display: "flex", gap: "5px" }}
                      >
                        {/* {name ? name : ""} */}
                        {/* Field Visit */}
                        <LabelLarge
                          $style={{ color: "#516BEB", paddingRight: "2px" }}
                        >
                          Field Visit Unsuccessful
                        </LabelLarge>
                      </span>{" "}
                    </Label1>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD", width: "100px" }}>
                      Visit Location:{" "}
                    </Paragraph2>

                    <div>
                      {unsuccessFullContent.custom_location !== "" ||
                      !unsuccessFullContent.custom_location ? (
                        <>
                          <span style={{ color: "#787878" }}>
                            At Location - {returnunVisitLocation?.line_1}{" "}
                            {returnunVisitLocation?.line_1}{" "}
                            {returnunVisitLocation?.line_2}{" "}
                            {returnunVisitLocation?.city}{" "}
                            {returnunVisitLocation?.state}{" "}
                            {returnunVisitLocation?.city}{" "}
                            {returnunVisitLocation?.zip_code}{" "}
                            {returnunVisitLocation?.country}
                          </span>
                        </>
                      ) : (
                        <span style={{ color: "#787878" }}>
                          At other Location -{" "}
                          {unsuccessFullContent.custom_location}
                        </span>
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD" }}>
                      Outcome
                    </Paragraph2>
                    <div>
                      <div style={{ color: "#787878" }}>Unsuccessfull</div>
                      <div style={{ color: "#787878" }}>
                        Reason - {reason?.label}
                      </div>
                      <div style={{ color: "#787878" }}>
                        Follow up Call again :{visitedunDate}{" "}
                        {followUpTime ? followUpTime?.label : ""}
                        {/* {unsuccessFL.visitedLocation} */}
                      </div>
                    </div>
                  </div>

                  <Paragraph2 $style={{ color: "#ADADAD" }}>
                    Comments{" "}
                    <span
                      style={{
                        color: "#787878",
                      }}
                    >
                      {unsuccessFL.comment}
                    </span>
                  </Paragraph2>

                  {unsuccessFullContent.is_hold_workflow ? (
                    <>
                      <Paragraph2 $style={{ color: "#ADADAD" }}>
                        Workflow is paused till Call Date{" "}
                      </Paragraph2>
                    </>
                  ) : (
                    <></>
                  )}

                  <Paragraph2 $style={{ color: "#ADADAD" }}>
                    Email Configuration message is opted -{" "}
                    <span
                      style={{
                        color: "#787878",
                      }}
                    >
                      {unsuccessFullContent.confirmation_message_template_id}
                    </span>
                  </Paragraph2>

                  <Paragraph2 $style={{ color: "#ADADAD" }}>
                    Email Follow-up message is opted -{" "}
                    <span
                      style={{
                        color: "#787878",
                      }}
                    >
                      {unsuccessFullContent.action_follow_up_template_id}
                    </span>
                  </Paragraph2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
              <ProfileIcon />
            </div> */}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
        <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
      </div> */}
            <div
              className="cp-status-view__day"
              style={{
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}

                {filteringUnsuccCallUsers.label}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {executedUnsuccOnDateFormate}{" "}
                <FormattedTime value={new Date(unsuccessFL.executed_on)} />
              </Label2>
            </div>
          </div>
        </>
      );

    case "PAYMENT_PLAN": {
      let paymentPlan = data;
      const disputeValueDate = moment
        ?.utc(data.executed_on)
        ?.format(currentDefaultFormatDetails.date_format);
      let disputeUsers = referenceData["users"];
      let filteringDisputeUsers = disputeUsers?.filter(
        (i) => i.id === data.executed_by
      );

      const Status = (data) => {
        // useEffect(()=>{

        switch (data?.data) {
          case "IN_PROGRESS":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "Blue" }}
              >
                In Progress
              </span>
            );
          case "CANCELED":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "red " }}
              >
                CANCELED
              </span>
            );
          case "KEPT":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "green" }}
              >
                {data.data}
              </span>
            );
          case "BROKEN":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "red " }}
              >
                BROKEN
              </span>
            );

          case "PARTIAL":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "#FBBD04" }}
              >
                {data.data}
              </span>
            );
          default:
            return null;
        }
        // },[data])
      };
      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={"FIELD_VISIT"} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{ color: "#516beb", marginRight: "10px" }}
                      style={{ display: "flex" }}
                    >
                      FV -&nbsp;
                      <span
                        className="cp-discretion__text-content__weight"
                        style={{ color: "#333860", fontWeight: "500" }}
                      >
                        Payment Plan From{" "}
                        {moment(
                          paymentPlan?.content?.payment_plan_start_date
                        ).format(currentDefaultFormatDetails.date_format)}
                        &nbsp;
                        <Status data={paymentPlan?.content?.promise_status} />
                      </span>
                      {/* {data?.} */}
                    </Label1>
                  </div>
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      {paymentPlan?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>
                              {paymentPlan.comment}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Total Payment Amount :</LabelMedium>
                        <ParagraphMedium>
                          {paymentPlan.content.payment_plan_amount?.currency}
                        </ParagraphMedium>
                        <ParagraphMedium>
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              paymentPlan.content.payment_plan_amount?.value
                                ? Number(
                                    paymentPlan.content.payment_plan_amount
                                      ?.value
                                  )
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => <> {value}</>}
                          />
                          {/* {data.content.payment_plan_amount?.value} */}
                        </ParagraphMedium>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Installment Payment Amount :</LabelMedium>
                        <ParagraphMedium>
                          {
                            paymentPlan.content.payment_plan_instalation_amount
                              ?.currency
                          }
                        </ParagraphMedium>
                        <ParagraphMedium>
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              paymentPlan.content
                                .payment_plan_instalation_amount?.value
                                ? Number(
                                    paymentPlan.content
                                      .payment_plan_instalation_amount?.value
                                  )
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => <> {value}</>}
                          />
                          {/* {data.content.payment_plan_amount?.value} */}
                        </ParagraphMedium>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Planned Start Date:</LabelMedium>
                        <ParagraphMedium>
                          {moment(
                            paymentPlan.content.payment_plan_start_date
                          ).format(currentDefaultFormatDetails.date_format)}
                        </ParagraphMedium>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Planned Frequency:</LabelMedium>
                        <ParagraphMedium>
                          {paymentPlan.content.payment_plan_frequency}
                        </ParagraphMedium>
                      </div>
                      <div style={{ width: "78%" }}>
                        <ParagraphMedium>
                          {paymentPlan.content.payment_plan_frequency
                            ? paymentPlan.content.payment_plan_frequency
                            : "-"}
                          &nbsp;Payment Plan with Installment amount{" "}
                          <b>
                            {paymentPlan?.content
                              ?.payment_plan_instalation_amount?.currency
                              ? paymentPlan?.content
                                  ?.payment_plan_instalation_amount?.currency
                              : "-"}{" "}
                            &nbsp;
                            {paymentPlan?.content
                              ?.payment_plan_instalation_amount?.value ? (
                              <NumericFormat
                                displayType="text"
                                value={
                                  paymentPlan?.content
                                    ?.payment_plan_instalation_amount?.value
                                    ? Number(
                                        paymentPlan?.content
                                          ?.payment_plan_instalation_amount
                                          ?.value
                                      )
                                    : 0
                                }
                                thousandsGroupStyle={currencyGroupStyle}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={currencySeparator}
                                decimalSeparator={currencyDecimalSeparator}
                                renderText={(value) => <>{value}</>}
                              />
                            ) : (
                              "-"
                            )}
                          </b>
                          &nbsp;starting from{" "}
                          <b>
                            {moment(
                              paymentPlan?.content?.payment_plan_start_date
                            ).format(
                              currentDefaultFormatDetails.date_format
                            )}{" "}
                          </b>
                          till{" "}
                          <b>
                            {moment(
                              paymentPlan?.content?.payment_plan_end_date
                            ).format(currentDefaultFormatDetails.date_format)}
                          </b>{" "}
                          <span
                            onClick={handlerDrawers}
                            style={{
                              color: "#516BEB",
                              borderBottom: "1px solid #516beb",
                              cursor: "pointer",
                            }}
                          >
                            View payment Plans
                          </span>
                        </ParagraphMedium>
                      </div>
                      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={paymentPlan.content?.is_hold_workflow}
                        >
                          <FormattedMessage id="hold_workflow_till_promise_date">
                            hold_workflow_till_promise_date
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                      <div className="cp-expand__confir-check">
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={
                            paymentPlan.content?.is_enable_calendar_alert
                          }
                        >
                          <FormattedMessage id="enable_calendar_alert">
                            enable_calendar_alert
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                      {paymentPlan.content?.is_enable_calendar_alert != true ? (
                        <></>
                      ) : (
                        <>
                          <div className="cp-expand__confirm-input">
                            <div className="cp-expand__type-input">
                              <TextBox
                                label={intl.formatMessage({
                                  id: "time",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "time",
                                })}
                                // value={
                                //   promiseToPay.content
                                //     ?.confirmation_message_type
                                // }
                                value={
                                  paymentPlan.content?.calendar_alert_time
                                    ? paymentPlan.content?.calendar_alert_time
                                        .split("_")
                                        .join(" ")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={
                            paymentPlan.content?.is_send_confirmation_message
                          }
                        >
                          <FormattedMessage id="send_confirmation_message">
                            send_confirmation_message
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                      <div>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={
                            paymentPlan.content?.is_send_action_follow_up
                          }
                        >
                          <FormattedMessage id="send_action_follow">
                            send_confirmation_message
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                    </div>
                  </>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                </div>
              </div>
            </div>
            <PaymentPlanDrawer
              isOpen={isDrawer}
              planDrawer={"TimeLine"}
              setIsOpen={setIsDrawer}
              PaymentID={isId}
              setIsId={setIsId}
              value={paymentPlan}
            />
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {disputeValueDate} <FormattedTime value={data.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    default:
      return null;
  }
};

const CallOutcome = ({ data, callOutcome }) => {
  let intl = useIntl();
  const dispatch = useDispatch();

  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const [expand, setExpand] = React.useState(false);
  const [isDrawer, setIsDrawer] = useState(false);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  const [isId, setIsId] = useState();
  const handlerDrawer = () => {
    if (data && data?.content && data?.content?.payment_plan_id !== undefined) {
      setIsDrawer(!isDrawer);
      setIsId(data.content.payment_plan_id);
    }
  };

  const timeZone = orgRef?.time_zone;

  const expanToggle = () => {
    setExpand(!expand);
  };

  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  switch (callOutcome) {
    case PROMISETOPAY: {
      const promiseToPay = data;

      const promiseToPayRecording =
        data &&
        data.content &&
        data.content.call_detail &&
        data.content.call_detail.recording_url;

      let PromimseToPayUsers = referenceData["users"];
      let filteringPromiseToPay = PromimseToPayUsers?.filter(
        (i) => i.id === promiseToPay?.executed_by
      );
      const userName = users.find(({ id }) => id === promiseToPay.executed_by);

      let dataStatus = referenceData["template_type"];

      const typeOfNotes = dataStatus?.filter(
        (i) => i.id === promiseToPay.content?.confirmation_message_type
      );

      const PromiseFollowUpStatus = dataStatus?.filter(
        (i) => i.id === promiseToPay.content?.action_follow_up_type
      );

      let promiseStatusRd = referenceData["promise_status"];

      let promiseStatus = promiseToPay?.content?.promise_status;

      const StatusFinding = promiseStatusRd?.filter(
        (i) => i.id === promiseStatus
      );

      let promiseType = referenceData["promise_type"];

      const findingStatus = promiseType?.find(
        (i) => i.id === promiseToPay.content.promise_type
      );
      const promiseSlicingValue = promiseToPay.comment?.slice(0, 200);

      //ReceiverPromiseDate

      const ReceiverName = promiseToPay.content.to?.map((i) => i.name);
      const ReceiverValue = promiseToPay.content.to?.map((i) => i.value);
      const ReceiverDes = promiseToPay.content.to?.map((i) => i.designation);
      let recipientPromiseType = referenceData["recipient_type"];
      let recipientsPromise = recipientPromiseType?.filter(
        (i) => i.id === ReceiverDes[0]
      );

      const combinedValue = `${ReceiverName} ${ReceiverValue} ${
        recipientsPromise && recipientsPromise[0] && recipientsPromise[0]?.label
      }`;

      const timezoneBasedDate = moment
        .utc(promiseToPay.content.promise_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const PromiseDate = intl.formatDate(timezoneBasedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      const executedOnDate = moment
        .utc(promiseToPay.executed_on)
        .format(currentDefaultFormatDetails.date_format);
      const attachments = promiseToPay.content.attachments.map((i) => i);
      // let splitTypes = attachments && attachments.name.split(".")
      //

      return (
        <>
          <div
            className={
              "cp-timeline-contant__status-view" +
              (data.is_receiver ? "__ft-r" : "")
            }
          >
            <div className="cp-status-view__icon">
              {/* {attachments.length !== 0 ?
                <PaymentProof className="svg-primary-fill" /> : */}

              <PromiseToPay className="svg-primary-fill" />
              {/*  } */}
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {/* {type ? <TypeName /> : " "} -{" "} */}
                      <span className="cp-discretion__text-content__weight">
                        <LabelLarge style={{ color: "#516BEB" }}>
                          {data.is_receiver ? "Portal:" : "Call:"}{" "}
                          {promiseToPay.name ? promiseToPay.name : " "}
                        </LabelLarge>
                      </span>{" "}
                      <>
                        <span className="cp-discretion__text-content__weight">
                          by {timezoneBasedDate}
                        </span>
                        -{" "}
                        <span className="cp-discretion__text-content__weight">
                          {StatusFinding &&
                            StatusFinding[0] &&
                            StatusFinding[0]?.label}
                        </span>
                      </>
                    </Label1>

                    {promiseStatus === "IN_PROGRESS" ? (
                      <div className="cp-discretion__icon-alert svg path">
                        <Alert />
                      </div>
                    ) : promiseStatus === "PARTIAL" ? (
                      <div>
                        <PtpPartial />
                      </div>
                    ) : promiseStatus === "BROKEN" ? (
                      <div>
                        <Alert />
                      </div>
                    ) : promiseStatus === "CANCELED" ? (
                      <div className="max_alert_svg">
                        <Alert />
                      </div>
                    ) : (
                      <div>
                        <PtpKept />
                      </div>
                    )}

                    <div>{/* <DeliveredStatusSend /> */}</div>
                  </div>

                  {attachments.length !== 0
                    ? promiseToPay.content.attachments.map((i) => {
                        let splitType = i && i.name.split(".");

                        return (
                          <div style={{ width: "350px", padding: "10px 0px" }}>
                            <DocumentCard
                              res={{
                                file_name: i.name,
                                mime_type: splitType[1],
                                _id: i.ref,
                                // type: splitType[1],
                                // file_,
                                customerId: promiseToPay.customerId,
                                tab: "timeline",
                              }}
                            />
                          </div>
                        );
                      })
                    : ""}
                  <div className="cp-expand__form-input">
                    <>
                      <TextBox
                        label={intl.formatMessage({
                          id: "receiver",
                        })}
                        placeholder={intl.formatMessage({
                          id: "receiver",
                        })}
                        value={
                          ReceiverName &&
                          ReceiverValue &&
                          recipientsPromise &&
                          recipientsPromise[0] &&
                          recipientsPromise[0]?.label
                            ? `${ReceiverName} ${ReceiverValue} ${
                                recipientsPromise &&
                                recipientsPromise[0] &&
                                recipientsPromise[0]?.label
                              }`
                            : ""
                        }
                      />
                    </>

                    <div className="cp-expand__type">
                      <TextBox
                        label={intl.formatMessage({
                          id: "promise_type",
                        })}
                        placeholder={intl.formatMessage({
                          id: "promise_type",
                        })}
                        value={findingStatus?.label}
                        // value={promiseToPay.promise_type}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <ParagraphMedium
                          $style={{
                            color: "#adadad",
                            lineHeight: "8px",
                            marginBottom: "3px",
                          }}
                        >
                          <FormattedMessage id="Promise_Amount">
                            Promise_Amount
                          </FormattedMessage>
                        </ParagraphMedium>
                        <NumericFormat
                          // type="text"
                          displayType="text"
                          value={
                            promiseToPay &&
                            promiseToPay.content &&
                            promiseToPay.content?.promise_amount &&
                            promiseToPay.content?.promise_amount.value
                              ? Number(
                                  promiseToPay.content?.promise_amount.value
                                )
                              : 0
                          }
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(value) => (
                            <ParagraphMedium>
                              <sup style={{ fontSize: "9px" }}>
                                {promiseToPay &&
                                promiseToPay.content &&
                                promiseToPay.content?.promise_amount &&
                                promiseToPay.content?.promise_amount.currency
                                  ? promiseToPay.content?.promise_amount
                                      .currency
                                  : "-"}
                              </sup>{" "}
                              {value}
                            </ParagraphMedium>
                          )}
                        />
                      </div>
                      {/* <TextBox
    label={intl.formatMessage({
      id: "promise_amount",
    })}
    placeholder={intl.formatMessage({
      id: "promise_amount",
    })}
    // value={}
    value={`${"$"}${
      promiseToPay.content?.promise_amount.value
    }`}
  /> */}
                      <TextBox
                        label={intl.formatMessage({
                          id: "promise_date",
                        })}
                        placeholder={intl.formatMessage({
                          id: "promise_date",
                        })}
                        // value={PromiseDate}
                        value={timezoneBasedDate}
                        // value={field.value}
                      />
                    </div>
                    <CheckBoxBaseweb
                      labelPlacement={LABEL_PLACEMENT.right}
                      checked={promiseToPay.content?.is_hold_workflow}
                    >
                      <FormattedMessage id="hold_workflow_till_promise_date">
                        hold_workflow_till_promise_date
                      </FormattedMessage>
                    </CheckBoxBaseweb>

                    <div className="cp-expand__confir-check">
                      <CheckBoxBaseweb
                        labelPlacement={LABEL_PLACEMENT.right}
                        checked={promiseToPay.content?.is_enable_calendar_alert}
                      >
                        <FormattedMessage id="enable_calendar_alert">
                          enable_calendar_alert
                        </FormattedMessage>
                      </CheckBoxBaseweb>
                    </div>
                    {promiseToPay.content?.is_enable_calendar_alert != true ? (
                      <></>
                    ) : (
                      <>
                        <div className="cp-expand__confirm-input">
                          <div className="cp-expand__type-input">
                            <TextBox
                              label={intl.formatMessage({
                                id: "time",
                              })}
                              placeholder={intl.formatMessage({
                                id: "time",
                              })}
                              // value={
                              //   promiseToPay.content
                              //     ?.confirmation_message_type
                              // }
                              value={
                                promiseToPay.content?.calendar_alert_time
                                  ? promiseToPay.content?.calendar_alert_time
                                      .split("_")
                                      .join(" ")
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="cp-expand__confir-check">
                      <CheckBoxBaseweb
                        labelPlacement={LABEL_PLACEMENT.right}
                        checked={
                          promiseToPay.content?.is_send_confirmation_message
                        }
                      >
                        <FormattedMessage id="send_confirmation_message">
                          send_confirmation_message
                        </FormattedMessage>
                      </CheckBoxBaseweb>
                    </div>
                    {promiseToPay.content?.is_send_confirmation_message !=
                    true ? (
                      <></>
                    ) : (
                      <>
                        <div className="cp-expand__confirm-input">
                          <div className="cp-expand__type-input">
                            <TextBox
                              label={intl.formatMessage({
                                id: "message_medium",
                              })}
                              placeholder={intl.formatMessage({
                                id: "message_medium",
                              })}
                              // value={
                              //   promiseToPay.content
                              //     ?.confirmation_message_type
                              // }
                              value={
                                typeOfNotes &&
                                typeOfNotes[0] &&
                                typeOfNotes[0]?.label
                              }
                            />
                          </div>
                          <div className="cp-expand__promise-confirm">
                            <TextBox
                              label={intl.formatMessage({
                                id: "promise_to_pay_confirmation",
                              })}
                              placeholder={intl.formatMessage({
                                id: "promise_to_pay_confirmation",
                              })}
                              value={
                                promiseToPay.content
                                  ?.confirmation_message_template_id
                              }

                              // value={field.value}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="cp-expand__confir-check">
                      <CheckBoxBaseweb
                        labelPlacement={LABEL_PLACEMENT.right}
                        checked={promiseToPay?.content.is_send_action_follow_up}
                      >
                        <FormattedMessage id="send_promise_follow_up_message">
                          send_promise_follow_up_message
                        </FormattedMessage>
                      </CheckBoxBaseweb>
                    </div>
                    {promiseToPay?.content.is_send_action_follow_up != true ? (
                      <></>
                    ) : (
                      <>
                        <div className="cp-expand__confirm-input">
                          <div className="cp-expand__type-input">
                            <TextBox
                              label={intl.formatMessage({
                                id: "message_medium",
                              })}
                              placeholder={intl.formatMessage({
                                id: "message_medium",
                              })}
                              // value={
                              //   promiseToPay.content?.action_follow_up_type
                              // }
                              value={
                                PromiseFollowUpStatus &&
                                PromiseFollowUpStatus[0] &&
                                PromiseFollowUpStatus[0]?.label
                              }
                              // value={field.value}
                            />
                          </div>
                          <div className="cp-expand__promise-confirm">
                            <TextBox
                              label={intl.formatMessage({
                                id: "promise_to_follow_up_message",
                              })}
                              placeholder={intl.formatMessage({
                                id: "promise_to_follow_up_message",
                              })}
                              // value={""}
                              value={
                                promiseToPay.content
                                  ?.action_follow_up_template_id
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ paddingTop: "5px" }}>
                    <LabelMedium
                      $style={{
                        color: "#ADADAD",
                      }}
                    >
                      <FormattedMessage id="User_Comments">
                        User_Comments
                      </FormattedMessage>
                    </LabelMedium>
                    <Paragraph2>{promiseToPay.comment}</Paragraph2>
                  </div>
                </div>
              </div>
              {/* Bottom */}
              <div style={{ width: "100%", textAlign: "end" }}>
                <IconButton
                  kind={KIND.tertiary}
                  onClick={() => {
                    dispatch(drawerOpen());
                    dispatch(
                      promiseTopayDataToDrawer({
                        drawerType: "DISPUTE_PROMISE",
                        emailType: FORWARD,
                        emailData: promiseToPay,
                        isReceiver: data.is_receiver,
                        promiseDate: timezoneBasedDate,
                        customerName: customerDetails.display_name,
                        rmanager: orgRef?.label,
                        promiseStatus: StatusFinding && StatusFinding[0]?.label,
                        promiseSubject: `${
                          customerDetails.display_name
                            ? customerDetails.display_name
                            : "-"
                        } : Promised Payment of ${
                          promiseToPay.content.promise_amount.value
                            ? numberFormat.format(
                                `${promiseToPay.content.promise_amount.value}`
                              )
                            : "-"
                        } ${
                          promiseToPay.content.promise_amount.currency
                            ? promiseToPay.content.promise_amount.currency
                            : "-"
                        }  by ${timezoneBasedDate ? timezoneBasedDate : "-"}`,

                        promiseBody: `
        Hi,
        <br />
        <br />Find below the details of the Promise provided by ${
          customerDetails.display_name ? customerDetails.display_name : "-"
        }<br />Promised Amount: ${
                          promiseToPay.content.promise_amount.value
                            ? numberFormat.format(
                                `${promiseToPay.content.promise_amount.value}`
                              )
                            : "-"
                        } ${
                          promiseToPay.content.promise_amount.currency
                            ? promiseToPay.content.promise_amount.currency
                            : "-"
                        }<br />Promised To Pay by Date: ${
                          timezoneBasedDate ? timezoneBasedDate : "-"
                        }<br />Promise Status : ${
                          StatusFinding[0]?.label
                            ? StatusFinding[0]?.label
                            : "-"
                        }<br />Promise Comments : ${
                          promiseToPay.comment ? promiseToPay.comment : "-"
                        }<br /><br />Regards <br/>${userInfo.display_name}`,
                      })
                    );
                  }}
                >
                  <Forward />
                </IconButton>
              </div>
            </div>
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {data.is_receiver
                    ? "Consumer via Portal"
                    : filteringPromiseToPay &&
                      filteringPromiseToPay[0] &&
                      filteringPromiseToPay[0]?.label}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDate}{" "}
                  <FormattedTime value={promiseToPay.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }
    case CALL_UNSUCCESSFUL: {
      const unsuccessfulVal = data;
      const callRecordUnsuccessful =
        data &&
        data.content &&
        data.content.call_detail &&
        data.content.call_detail.recording_url;

      let CallUnsuccessfulUsers = referenceData["users"];
      let filteringCallUnsuccessfulUsers = CallUnsuccessfulUsers?.filter(
        (i) => i.id === unsuccessfulVal.executed_by
      );
      let callUnsuccess = referenceData["call_unsuccessful_reason"];
      let CallUnsuccessfulReason = callUnsuccess?.filter(
        (i) => i.id === unsuccessfulVal.content.call_unsuccessful_reason
      );
      let filterCall = callUnsuccess?.filter(
        (i) => i.id === unsuccessfulVal.content.call_unsuccessful_reason
      );

      const timezoneBasedDate = moment
        .utc(unsuccessfulVal.content.followup_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const followUpDate = intl.formatDate(timezoneBasedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      const unSuccesfulExDate = moment
        .utc(unsuccessfulVal.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      // To (Receiver)
      const toValue = unsuccessfulVal.content.to?.map((i) => i.value);
      const toname = unsuccessfulVal.content.to?.map((i) => i.name);
      const toDesignation = unsuccessfulVal.content.to?.map(
        (i) => i.designation
      );
      let recipientType = referenceData["recipient_type"];

      let recipients = recipientType?.filter((i) => i.id === toDesignation[0]);

      const callslicingValue = unsuccessfulVal.comment?.slice(0, 200);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={CALLUNSUCCESSFUL} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {/* {type ? <TypeName /> : " "} - {filterCall[0].label} */}
                      <FormattedMessage id="Call_Unsuccessful">
                        Call_Unsuccessful
                      </FormattedMessage>{" "}
                      {CallUnsuccessfulReason &&
                        CallUnsuccessfulReason[0] &&
                        CallUnsuccessfulReason[0].label}{" "}
                      {/* <span className="cp-discretion__text-content__weight">
                        on{" "}
                        <FormattedDate
                          value={unsuccessfulVal.content.followup_date}
                        />
                      </span>{" "} */}
                    </Label1>
                  </div>

                  {expand ? (
                    <>
                      {unsuccessfulVal?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              <FormattedMessage id="User_Comments">
                                User_Comments
                              </FormattedMessage>
                            </LabelMedium>
                            <ParagraphMedium>
                              {unsuccessfulVal.comment}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}

                      <div className="cp-expand__form-input">
                        <TextBox
                          label={intl.formatMessage({
                            id: "receiver",
                          })}
                          placeholder={intl.formatMessage({
                            id: "receiver",
                          })}
                          value={
                            toValue && toname && recipients[0]?.label
                              ? `${toValue} ${toname} ${
                                  recipients &&
                                  recipients[0] &&
                                  recipients[0]?.label
                                }`
                              : ""
                          }
                        />
                        <div className="cp-expand__type --width-modified">
                          <TextBox
                            label={intl.formatMessage({
                              id: "unsucessful_reason",
                            })}
                            placeholder={intl.formatMessage({
                              id: "unsucessful_reason",
                            })}
                            value={
                              filterCall &&
                              filterCall[0] &&
                              filterCall[0]?.label
                            }
                          />
                          {!unsuccessfulVal.content.followup_date === true ? (
                            <></>
                          ) : (
                            <>
                              <TextBox
                                label={intl.formatMessage({
                                  id: "followup_date",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "followup_date",
                                })}
                                value={timezoneBasedDate}
                              />
                            </>
                          )}
                        </div>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={unsuccessfulVal.content?.is_hold_workflow}
                        >
                          <FormattedMessage id="hold_workflow_till_follow_up_date">
                            {/* hold_workflow_till_meeting_date */}
                            hold_workflow_till_follow_up_date
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                    </>
                  ) : (
                    <>
                      {unsuccessfulVal.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <LabelMedium
                            $style={{
                              color: "#ADADAD",
                            }}
                          >
                            User Comments
                          </LabelMedium>
                          <Paragraph2>{callslicingValue}</Paragraph2>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      filteringCallUnsuccessfulUsers &&
                      filteringCallUnsuccessfulUsers[0] &&
                      filteringCallUnsuccessfulUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringCallUnsuccessfulUsers &&
                        filteringCallUnsuccessfulUsers[0] &&
                        filteringCallUnsuccessfulUsers[0]?.label
                      }
                    />
                  </div>
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                Monday, 10 Jan, 2022
              </Label2>
            </div> */}
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {unSuccesfulExDate}{" "}
                <FormattedTime value={unsuccessfulVal.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    case CALL_CALL_BACK_LATER: {
      const callBackLater = data;

      const callBackLaterRecording =
        data &&
        data.content &&
        data.content.call_detail &&
        data.content.call_detail.recording_url;

      const callBackLaterValue = callBackLater?.comment;

      const callBackslicedValue = callBackLaterValue?.slice(0, 200);

      let CallBackLatterusers = referenceData["users"];
      let filteringCallBackLaterUsers = CallBackLatterusers?.filter(
        (i) => i.id === callBackLater.executed_by
      );

      let callBackTime =
        referenceData["time"] &&
        referenceData["time"].find(
          (e) => e.id === callBackLater.content.callback_time
        );

      let recipientCallType = referenceData["recipient_type"];
      const callBackLaterToVal = callBackLater.content.to?.map((i) => i.value);
      const callBackLaterToName = callBackLater.content.to?.map((i) => i.name);
      const callBackLaterToDesignation = callBackLater.content.to?.map(
        (i) => i.designation
      );
      let recipientsCallType = recipientCallType?.filter(
        (i) => i.id === callBackLaterToDesignation[0]
      );
      // Date and Time

      const timezoneBasedDate = moment
        .utc(callBackLater.content.callback_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const callBackLaterDate = moment
        .utc(callBackLater.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const MettingDate = intl.formatDate(timezoneBasedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      const MettingTime = intl.formatTime(callBackLater.content.callback_time, {
        value: "numeric",
        format: "string",
      });

      let sendConfiramtionMessage = referenceData["template_type"];
      const callBackLaterMessage = sendConfiramtionMessage?.filter(
        (i) => i.id === callBackLater.content?.confirmation_message_type
      );

      const PromisetoPay = sendConfiramtionMessage?.filter(
        (i) => i.id === callBackLater.content?.action_follow_up_type
      );

      return (
        <>
          <div
            className={
              "cp-timeline-contant__status-view" +
              (data.is_receiver ? "__ft-r" : "")
            }
          >
            <div className="cp-status-view__icon">
              <TemplateIcon type={CALLBACKLATER} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1 $style={{ color: "#516beb", marginRight: "12px" }}>
                      {data.is_receiver ? (
                        <FormattedMessage id="portal">portal</FormattedMessage>
                      ) : (
                        <FormattedMessage id="call">call</FormattedMessage>
                      )}
                      :{" "}
                      <FormattedMessage id="Call_Back_Later">
                        Call_Back_Later
                      </FormattedMessage>{" "}
                      <span className="cp-discretion__text-content__weight">
                        on {timezoneBasedDate} <span>at</span>{" "}
                        {callBackTime && callBackTime.label
                          ? callBackTime.label
                          : ""}{" "}
                      </span>{" "}
                    </Label1>
                  </div>

                  {/* {expand ? (
                    <div style={{ paddingTop: "5px" }}>
                      <Paragraph2>{callBackLaterValue}</Paragraph2>
                    </div>
                  ) : (
                    <div className="cp-expand__form-input">
                      <Paragraph2>{callBackslicedValue}</Paragraph2>
                    </div>
                  )} */}

                  {expand ? (
                    <>
                      {callBackLaterValue?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              <FormattedMessage id="User_Comments">
                                User_Comments
                              </FormattedMessage>
                            </LabelMedium>

                            <Paragraph2>{callBackLaterValue}</Paragraph2>
                          </div>
                        </>
                      )}

                      <div className="cp-expand__form-input">
                        <TextBox
                          label={intl.formatMessage({
                            id: "receiver",
                          })}
                          placeholder={intl.formatMessage({
                            id: "receiver",
                          })}
                          value={
                            callBackLaterToVal &&
                            callBackLaterToName &&
                            recipientsCallType?.[0]?.label
                              ? `${callBackLaterToVal} ${callBackLaterToName} ${
                                  recipientsCallType &&
                                  recipientsCallType[0] &&
                                  recipientsCallType[0]?.label
                                }`
                              : ""
                          }

                          // value={field.value}
                        />
                        <div className="cp-expand__type">
                          <TextBox
                            label={intl.formatMessage({
                              id: "call_back_date",
                            })}
                            placeholder={intl.formatMessage({
                              id: "call_back_date",
                            })}
                            value={timezoneBasedDate}
                          />
                          <TextBox
                            label={intl.formatMessage({
                              id: "call_back_time",
                            })}
                            placeholder={intl.formatMessage({
                              id: "call_back_time",
                            })}
                            value={
                              callBackTime && callBackTime.label
                                ? callBackTime.label
                                : ""
                            }
                          />
                        </div>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={callBackLater.content?.is_hold_workflow}
                        >
                          <FormattedMessage id="hold_workflow_till_promise_date">
                            hold_workflow_till_promise_date
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                        <div className="cp-expand__confir-check">
                          <CheckBoxBaseweb
                            labelPlacement={LABEL_PLACEMENT.right}
                            checked={
                              callBackLater.content
                                ?.is_send_confirmation_message
                            }
                          >
                            <FormattedMessage id="send_confirmation_message">
                              send_confirmation_message
                            </FormattedMessage>
                          </CheckBoxBaseweb>
                        </div>
                        {callBackLater.content?.is_send_confirmation_message !=
                        true ? (
                          <></>
                        ) : (
                          <>
                            <div className="cp-expand__confirm-input">
                              <div className="cp-expand__type-input">
                                <TextBox
                                  label={intl.formatMessage({
                                    id: "message_medium",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "message_medium",
                                  })}
                                  // value={field.value}
                                  // value={
                                  //   callBackLater.content
                                  //     ?.confirmation_message_type
                                  // }
                                  value={
                                    callBackLaterMessage &&
                                    callBackLaterMessage[0] &&
                                    callBackLaterMessage[0].label
                                  }
                                />
                              </div>
                              <div className="cp-expand__promise-confirm">
                                <TextBox
                                  // label={intl.formatMessage({
                                  //   id: "promise_to_pay_confirmation",
                                  // })}
                                  // placeholder={intl.formatMessage({
                                  //   id: "promise_to_pay_confirmation",
                                  // })}
                                  // value={field.value}
                                  value={
                                    callBackLater.content
                                      ?.confirmation_message_template_id
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="cp-expand__confir-check">
                          <CheckBoxBaseweb
                            labelPlacement={LABEL_PLACEMENT.right}
                            checked={
                              callBackLater.content?.is_send_action_follow_up
                            }
                          >
                            <FormattedMessage id="send_promise_follow_up_message">
                              send_promise_follow_up_message
                            </FormattedMessage>
                          </CheckBoxBaseweb>
                        </div>

                        {callBackLater.content?.is_send_action_follow_up !=
                        true ? (
                          <></>
                        ) : (
                          <>
                            <div className="cp-expand__confirm-input">
                              <div className="cp-expand__type-input">
                                <TextBox
                                  // label={intl.formatMessage({
                                  //   id: "sms",
                                  // })}
                                  // placeholder={intl.formatMessage({
                                  //   id: "sms",
                                  // })}
                                  // value={
                                  //   callBackLater.content?.action_follow_up_type
                                  // }

                                  label={intl.formatMessage({
                                    id: "message_medium",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "message_medium",
                                  })}
                                  value={
                                    PromisetoPay &&
                                    PromisetoPay[0] &&
                                    PromisetoPay[0].label
                                  }
                                />
                              </div>
                              <div className="cp-expand__promise-confirm">
                                <TextBox
                                  // label={intl.formatMessage({
                                  //   id: "promise_to_follow_up_message",
                                  // })}
                                  // placeholder={intl.formatMessage({
                                  //   id: "promise_to_follow_up_message",
                                  // })}

                                  // value={field.value}
                                  value={
                                    callBackLater.content
                                      ?.action_follow_up_template_id
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}

                        <div className="cp-expand__rec-btn">
                          {/* <div>
                          <TextButton size={SIZE.compact}>
                            Recordings
                          </TextButton>
                        </div> */}
                          {/* <LabelMedium $style={{ color: "#ADADAD" }}>
                          $ 26.00
                        </LabelMedium> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {callBackLaterValue?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className="cp-expand__form-input">
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              <FormattedMessage id="User_Comments">
                                User_Comments
                              </FormattedMessage>
                            </LabelMedium>

                            <Paragraph2>{callBackslicedValue}</Paragraph2>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      data.is_receiver
                        ? "Consumer via Portal"
                        : filteringCallBackLaterUsers &&
                          filteringCallBackLaterUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        data.is_receiver
                          ? "CP"
                          : filteringCallBackLaterUsers &&
                            filteringCallBackLaterUsers[0]?.label
                      }
                    />
                  </div>
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                Monday, 10 Jan, 2022
              </Label2>
            </div> */}
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {callBackLaterDate}{" "}
                <FormattedTime value={callBackLater.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    case CALL_DISPUTE: {
      const disputeVal = data;

      const callDisputeRecording =
        data &&
        data.content &&
        data.content.call_detail &&
        data.content.call_detail.recording_url;

      let disputeUsers = referenceData["users"];
      let filteringDisputeUsers = disputeUsers?.filter(
        (i) => i.id === disputeVal.executed_by
      );

      const timezoneBasedDate = moment
        .utc(disputeVal.content.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const disputeValueDate = moment
        ?.utc(disputeVal.executed_on)
        ?.format(currentDefaultFormatDetails.date_format);

      const disputeRaisedOn = moment
        .utc(disputeVal.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const disputeReviewDate = intl.formatDate(timezoneBasedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      let disputeValType = referenceData["recipient_type"];
      const disputeValLaterToVal = disputeVal.content.to?.map((i) => i.value);
      const disputeValLaterToName = disputeVal.content.to?.map((i) => i.name);
      const disputeValLaterToDesignation = disputeVal.content.to?.map(
        (i) => i.designation
      );
      let disputeValCallType = disputeValType?.filter(
        (i) => i.id === disputeValLaterToDesignation[0]
      );

      let refType = referenceData["dispute_type"];

      let disputeStatus = referenceData["dispute_status"];

      const findingStatus =
        disputeVal && disputeVal.content && disputeVal.content.dispute_status
          ? disputeStatus?.find(
              (i) => i.id === disputeVal?.content.dispute_status
            )
          : [];

      const disputeComment = disputeVal.comment;
      const disputeCommentValue = disputeVal.comment?.slice(0, 200);

      const findingValue = refType.find(
        (i) => i.id === disputeVal.content?.dispute_type
      );

      const disputeInvoices = disputeVal.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={DISPUTE} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{ color: "#516beb", marginRight: "12px" }}
                      style={{ display: "flex" }}
                    >
                      Call: Dispute{disputeVal.is_adhoc_action ? " (QA)" : ""}
                      {" - "}
                      {/* <span className="cp-discretion__text-content__weight">
                        {disputeVal.content.dispute_status === "CLOSED" ? (
                          <>
                            {findingValue && findingValue.label} Status Closed
                          </>
                        ) : (
                          <>
                            {findingValue && findingValue.label} Review on{" "}
                            {
                              <FormattedDate
                                value={disputeVal?.content?.dispute_review_date}
                              />
                            }{" "}
                          </>
                        )}
                      </span> */}
                      <span className="cp-discretion__text-content__weight">
                        {disputeVal.content.dispute_status === "CLOSED" ? (
                          <div
                            className="cp-discretion__text-content__weight"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            {findingValue && findingValue.label} - Status Closed
                            <PtpKept />
                          </div>
                        ) : disputeVal.content.dispute_status === "OPEN" ? (
                          <>
                            {/* {findingValue && findingValue.label} - Review on{" "}
                            {
                              <FormattedDate
                                value={disputeVal.content.dispute_review_date}
                              />
                            }{" "} */}
                            <div
                              className="cp-discretion__text-content__weight"
                              style={{ display: "flex", gap: "5px" }}
                            >
                              {findingValue && findingValue.label} - Status Open
                              <Alert />
                            </div>
                          </>
                        ) : (
                          <div
                            className="cp-discretion__text-content__weight"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            {findingValue && findingValue.label} - In Progress
                            {/* {findingValue && findingValue.label} - Review on{" "} */}
                            {/* {
                              <FormattedDate
                                value={disputeVal.content.dispute_review_date}
                              />
                            }{" "} */}
                            <PtpPartial />
                          </div>
                        )}
                      </span>
                    </Label1>

                    {/* <Label2 $style={{ color: "#C4C4C4" }}>
                      <FormattedDate
                        value={disputeVal.executed_on}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                      />
                    </Label2> */}
                  </div>
                  {/* <Paragraph2>{props.comment}</Paragraph2> */}
                  {expand ? (
                    <>
                      <div style={{ paddingTop: "5px" }}>
                        {disputeVal?.comment?.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            <div style={{ paddingTop: "5px" }}>
                              <LabelMedium
                                $style={{
                                  color: "#ADADAD",
                                }}
                              >
                                User Comments
                              </LabelMedium>
                              <ParagraphMedium>
                                {disputeComment}
                              </ParagraphMedium>
                            </div>
                          </>
                        )}

                        <div className="cp-expand__form-input">
                          <TextBox
                            label={intl.formatMessage({
                              id: "receiver",
                            })}
                            placeholder={intl.formatMessage({
                              id: "receiver",
                            })}
                            value={`${disputeValLaterToVal} ${disputeValLaterToName} ${disputeValCallType[0]?.label}`}

                            // value={field.value}
                          />
                        </div>
                        {/* <Paragraph2>{holdAction.comment}</Paragraph2> */}
                      </div>

                      <div className="cp-expand__form-input">
                        {/* <TextBox
                          label={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          placeholder={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          value={disputeVal.content.dispute_details}
                        /> */}
                        <TextArea
                          label={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          placeholder={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          value={disputeVal.content?.dispute_details}
                        />

                        <div className="cp-expand__type">
                          <TextBox
                            label={intl.formatMessage({
                              id: "dispute_category",
                            })}
                            placeholder={intl.formatMessage({
                              id: "dispute_category",
                            })}
                            value={
                              findingValue && findingValue.label
                                ? findingValue.label
                                : ""
                            }
                          />
                          <TextBox
                            label={intl.formatMessage({
                              id: "dispute_review_date",
                            })}
                            placeholder={intl.formatMessage({
                              id: "dispute_review_date",
                            })}
                            value={timezoneBasedDate}
                          />
                          <TextBox
                            label={intl.formatMessage({
                              id: "dispute_status",
                            })}
                            placeholder={intl.formatMessage({
                              id: "dispute_status",
                            })}
                            value={findingStatus && findingStatus.label}
                          />
                        </div>

                        <>
                          <TextBox
                            label={intl.formatMessage({
                              id: "summary_view_of_invoice_download",
                            })}
                            placeholder={intl.formatMessage({
                              id: "summary_view_of_invoice_download",
                            })}
                            value={disputeInvoices}
                          />
                        </>

                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={disputeVal.content?.is_hold_workflow}
                        >
                          <FormattedMessage id="hold_workflow_till_review_date">
                            hold_workflow_till_review_date
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                    </>
                  ) : (
                    <>
                      {disputeVal?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>
                              {disputeCommentValue}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}

                      {/* <Paragraph2>{holdAction.comment}</Paragraph2> */}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      filteringDisputeUsers &&
                      filteringDisputeUsers[0] &&
                      filteringDisputeUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringDisputeUsers &&
                        filteringDisputeUsers[0] &&
                        filteringDisputeUsers[0]?.label
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", textAlign: "end" }}>
                {expand ? (
                  <>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => {
                        dispatch(drawerOpen());
                        dispatch(
                          promiseTopayDataToDrawer({
                            drawerType: "DISPUTE_PROMISE",
                            emailType: FORWARD,
                            // emailData: disputeVal,
                            // isReceiver: is_receiver,
                            // promiseDate: timezoneBasedDate,
                            // customerName: customerDetails.display_name,
                            // invNumber: disputeInvoices,
                            // dispudeStatus: findingStatus.label,
                            // rmanager: orgRef.label,
                            promiseSubject: `${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            } : Dispute : ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            } Raised on ${disputeRaisedOn}`,

                            promiseBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            }<br />Dispute Type: ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            }<br />Dispute Details: ${
                              disputeVal.content.dispute_details
                                ? disputeVal.content.dispute_details
                                : "-"
                            }<br />Disputed Invoice Numbers: ${
                              disputeInvoices ? disputeInvoices : "-"
                            }<br /><br />Dispute Status : ${
                              findingStatus.label ? findingStatus.label : "-"
                            }<br />Dispute Review Date : ${
                              timezoneBasedDate ? timezoneBasedDate : "-"
                            }<br />User Comments :  ${
                              disputeVal.comment ? disputeVal.comment : "-"
                            }<br /><br />Regards<br />${
                              userInfo && userInfo.display_name
                                ? userInfo.display_name
                                : "-"
                            }`,
                          })
                        );
                      }}
                    >
                      <Forward />
                    </IconButton>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => expanToggle()}
                    >
                      <CollapseUpArrow />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => {
                        dispatch(drawerOpen());
                        dispatch(
                          promiseTopayDataToDrawer({
                            drawerType: "DISPUTE_PROMISE",
                            emailType: FORWARD,
                            // emailData: disputeVal,
                            // isReceiver: is_receiver,
                            // promiseDate: timezoneBasedDate,
                            // customerName: customerDetails.display_name,
                            // invNumber: disputeInvoices,
                            // dispudeStatus: findingStatus.label,
                            // rmanager: orgRef.label,
                            promiseSubject: `${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            } : Dispute : ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            } Raised on ${disputeRaisedOn}`,

                            promiseBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            }<br />Dispute Type: ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            }<br />Dispute Details: ${
                              disputeVal.content.dispute_details
                                ? disputeVal.content.dispute_details
                                : "-"
                            }<br />Disputed Invoice Numbers: ${
                              disputeInvoices ? disputeInvoices : "-"
                            }<br /><br />Dispute Status : ${
                              findingStatus.label ? findingStatus.label : "-"
                            }<br />Dispute Review Date : ${
                              timezoneBasedDate ? timezoneBasedDate : "-"
                            }<br />User Comments :  ${
                              disputeVal.comment ? disputeVal.comment : "-"
                            }<br /><br />Regards<br />${
                              userInfo && userInfo.display_name
                                ? userInfo.display_name
                                : "-"
                            }`,
                          })
                        );
                      }}
                    >
                      <Forward />
                    </IconButton>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => expanToggle()}
                    >
                      <CollapseDownArrow />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            {/* <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                Monday, 10 Jan, 2022
              </Label2>
            </div> */}
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {disputeValueDate}{" "}
                <FormattedTime value={disputeVal.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }
    case PAYMENT_PLAN: {
      const disputeValueDate = moment
        ?.utc(data.executed_on)
        ?.format(currentDefaultFormatDetails.date_format);
      let disputeUsers = referenceData["users"];
      let filteringDisputeUsers = disputeUsers?.filter(
        (i) => i.id === data.executed_by
      );

      const Status = (data) => {
        // useEffect(()=>{

        switch (data?.data) {
          case "IN_PROGRESS":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "Blue" }}
              >
                In Progress
              </span>
            );
          case "CANCELED":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "red " }}
              >
                CANCELED
              </span>
            );
          case "KEPT":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "green" }}
              >
                {data.data}
              </span>
            );
          case "BROKEN":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "red " }}
              >
                BROKEN
              </span>
            );

          case "PARTIAL":
            return (
              <span
                className="cp-discretion__text-content__weight"
                style={{ color: "#FBBD04" }}
              >
                {data.data}
              </span>
            );
          default:
            return null;
        }
        // },[data])
      };
      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <PromiseToPay className="svg-primary-fill" />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{ color: "#516beb", marginRight: "10px" }}
                      style={{ display: "flex" }}
                    >
                      Call - &nbsp;
                      <span
                        className="cp-discretion__text-content__weight"
                        style={{ color: "#333860", fontWeight: "500" }}
                      >
                        Payment Plan From{" "}
                        {moment(data?.content?.payment_plan_start_date).format(
                          "DD-MM-YYYY"
                        )}
                        &nbsp;
                        <Status data={data?.content?.promise_status} />
                      </span>
                      {/* {data?.} */}
                    </Label1>
                  </div>
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      {data?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>{data.comment}</ParagraphMedium>
                          </div>
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Total Payment Amount :</LabelMedium>
                        <ParagraphMedium>
                          {data.content.payment_plan_amount?.currency}
                        </ParagraphMedium>
                        <ParagraphMedium>
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              data.content.payment_plan_amount?.value
                                ? Number(
                                    data.content.payment_plan_amount?.value
                                  )
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => <> {value}</>}
                          />
                          {/* {data.content.payment_plan_amount?.value} */}
                        </ParagraphMedium>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Installment Payment Amount :</LabelMedium>
                        <ParagraphMedium>
                          {
                            data.content.payment_plan_instalation_amount
                              ?.currency
                          }
                        </ParagraphMedium>
                        <ParagraphMedium>
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              data.content.payment_plan_instalation_amount
                                ?.value
                                ? Number(
                                    data.content.payment_plan_instalation_amount
                                      ?.value
                                  )
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => <> {value}</>}
                          />
                          {/* {data.content.payment_plan_amount?.value} */}
                        </ParagraphMedium>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Planned Start Date:</LabelMedium>
                        <ParagraphMedium>
                          {moment(data.content.payment_plan_start_date).format(
                            "DD-MM-YYYY"
                          )}
                        </ParagraphMedium>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <LabelMedium>Planned Frequency:</LabelMedium>
                        <ParagraphMedium>
                          {data.content.payment_plan_frequency}
                        </ParagraphMedium>
                      </div>
                      <div style={{ width: "78%" }}>
                        <ParagraphMedium>
                          {data.content.payment_plan_frequency
                            ? data.content.payment_plan_frequency
                            : "-"}
                          &nbsp;Payment Plan with Installment amount{" "}
                          <b>
                            {data?.content?.payment_plan_instalation_amount
                              ?.currency
                              ? data?.content?.payment_plan_instalation_amount
                                  ?.currency
                              : "-"}{" "}
                            &nbsp;
                            {data?.content?.payment_plan_instalation_amount
                              ?.value ? (
                              <NumericFormat
                                displayType="text"
                                value={
                                  data?.content?.payment_plan_instalation_amount
                                    ?.value
                                    ? Number(
                                        data?.content
                                          ?.payment_plan_instalation_amount
                                          ?.value
                                      )
                                    : 0
                                }
                                thousandsGroupStyle={currencyGroupStyle}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={currencySeparator}
                                decimalSeparator={currencyDecimalSeparator}
                                renderText={(value) => <>{value}</>}
                              />
                            ) : (
                              "-"
                            )}
                          </b>
                          &nbsp;starting from{" "}
                          <b>
                            {moment(
                              data?.content?.payment_plan_start_date
                            ).format("Do-MMM-yyyy")}{" "}
                          </b>
                          till{" "}
                          <b>
                            {moment(
                              data?.content?.payment_plan_end_date
                            ).format("Do-MMM-yyyy")}
                          </b>{" "}
                          <span
                            onClick={handlerDrawer}
                            style={{
                              color: "#516BEB",
                              borderBottom: "1px solid #516beb",
                              cursor: "pointer",
                            }}
                          >
                            View payment Plans
                          </span>
                        </ParagraphMedium>
                      </div>
                      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={data.content?.is_hold_workflow}
                        >
                          <FormattedMessage id="hold_workflow_till_promise_date">
                            hold_workflow_till_promise_date
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                      <div className="cp-expand__confir-check">
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={data.content?.is_enable_calendar_alert}
                        >
                          <FormattedMessage id="enable_calendar_alert">
                            enable_calendar_alert
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                      {data.content?.is_enable_calendar_alert != true ? (
                        <></>
                      ) : (
                        <>
                          <div className="cp-expand__confirm-input">
                            <div className="cp-expand__type-input">
                              <TextBox
                                label={intl.formatMessage({
                                  id: "time",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "time",
                                })}
                                // value={
                                //   promiseToPay.content
                                //     ?.confirmation_message_type
                                // }
                                value={
                                  data.content?.calendar_alert_time
                                    ? data.content?.calendar_alert_time
                                        .split("_")
                                        .join(" ")
                                    : ""
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}
                      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={data.content?.is_send_confirmation_message}
                        >
                          <FormattedMessage id="send_confirmation_message">
                            send_confirmation_message
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                      <div>
                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={data.content?.is_send_action_follow_up}
                        >
                          <FormattedMessage id="send_action_follow">
                            send_confirmation_message
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                      </div>
                    </div>
                  </>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      filteringDisputeUsers &&
                      filteringDisputeUsers[0] &&
                      filteringDisputeUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringDisputeUsers &&
                        filteringDisputeUsers[0] &&
                        filteringDisputeUsers[0]?.label
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <PaymentPlanDrawer
              isOpen={isDrawer}
              planDrawer={"TimeLine"}
              setIsOpen={setIsDrawer}
              PaymentID={isId}
              setIsId={setIsId}
              value={data}
            />
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {disputeValueDate} <FormattedTime value={data.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }
    case PAYMENT: {
      const disputeValueDate = moment
        ?.utc(data.executed_on)
        ?.format(currentDefaultFormatDetails.date_format);
      let disputeUsers = referenceData["users"];
      let filteringDisputeUsers = disputeUsers?.filter(
        (i) => i.id === data.executed_by
      );

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <PortalPayment className="svg-primary-fill" />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{ color: "#516beb", marginRight: "10px" }}
                      style={{ display: "flex" }}
                    >
                      Call: Instant Payment -&nbsp;
                      <span className="-paymentCall">
                        {data.content.portal_payment_amount?.currency}
                        <NumericFormat
                          // type="text"
                          displayType="text"
                          value={
                            data.content.portal_payment_amount?.value
                              ? Number(
                                  data.content.portal_payment_amount?.value
                                )
                              : 0
                          }
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(value) => <> {value}</>}
                        />
                        &nbsp;via{" "}
                        {data &&
                        data.content &&
                        data.content.portal_payment_gateway_name
                          ? data.content.portal_payment_gateway_name
                          : "-"}
                      </span>
                      &nbsp;
                      <span
                        className={` ${
                          data.action_taken == "Success"
                            ? "Successed"
                            : "Normalize"
                        }`}
                      >
                        {data.action_taken}
                      </span>
                      {/* {data?.} */}
                    </Label1>
                  </div>
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      <div className="call_payment">
                        <div className="call_payment_header">
                          <LabelMedium>Receiver :</LabelMedium>
                        </div>
                        <ParagraphMedium>
                          {data && data?.content?.to[0]?.name}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Payment Amount :</LabelMedium>
                        <ParagraphMedium>
                          {data.content.portal_payment_amount?.currency}
                        </ParagraphMedium>
                        <ParagraphMedium>
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              data.content.portal_payment_amount?.value
                                ? Number(
                                    data.content.portal_payment_amount?.value
                                  )
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => <> {value}</>}
                          />
                          {/* {data.content.payment_plan_amount?.value} */}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Payment Date:</LabelMedium>
                        <ParagraphMedium>
                          {moment(data.executed_on).format("DD-MM-YYYY")}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Payment method:</LabelMedium>
                        <ParagraphMedium>
                          {data &&
                          data.content &&
                          data.content.portal_payment_gateway_name
                            ? data.content.portal_payment_gateway_name
                            : "-"}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Payment Reference:</LabelMedium>
                        <ParagraphMedium>
                          {data &&
                          data.content &&
                          data.content.portal_payment_reference
                            ? data.content.portal_payment_reference
                            : "-"}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Invoice#:</LabelMedium>
                        <ParagraphMedium>
                          {data?.content?.portal_payment_invoice_number}
                        </ParagraphMedium>
                      </div>
                      {data?.comment == "" ? (
                        <>
                          {" "}
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>{data?.comment}</ParagraphMedium>
                          </div>
                        </>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>{data?.comment}</ParagraphMedium>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      filteringDisputeUsers &&
                      filteringDisputeUsers[0] &&
                      filteringDisputeUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringDisputeUsers &&
                        filteringDisputeUsers[0] &&
                        filteringDisputeUsers[0]?.label
                      }
                    />
                  </div>
                </div>
              </div>
              {/* <div style={{ width: "100%", textAlign: "end" }}>
                {expand ? (
                  <>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => expanToggle()}
                    >
                      <CollapseUpArrow />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => expanToggle()}
                    >
                      <CollapseDownArrow />
                    </IconButton>
                  </>
                )}
              </div> */}
            </div>
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {disputeValueDate} <FormattedTime value={data.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    case "PROTALPAYMENT": {
      const disputeValueDate = moment
        ?.utc(data.executed_on)
        ?.format(currentDefaultFormatDetails.date_format);
      let disputeUsers = referenceData["users"];
      let filteringDisputeUsers = disputeUsers?.filter(
        (i) => i.id === data.executed_by
      );

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <PortalPayment className="svg-primary-fill" />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div className="cp-discretion__text-content">
                    <div className="cp-text-content__text-deliver">
                      <Label1
                        $style={{ color: "#516beb", marginRight: "10px" }}
                        style={{ display: "flex" }}
                      >
                        Call: Instant Payment -&nbsp;
                        <span className="-paymentCall">
                          {data.content.portal_payment_amount?.currency}
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              data.content.portal_payment_amount?.value
                                ? Number(
                                    data.content.portal_payment_amount?.value
                                  )
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => <> {value}</>}
                          />
                          &nbsp;via{" "}
                          {data &&
                          data.content &&
                          data.content.portal_payment_gateway_name
                            ? data.content.portal_payment_gateway_name
                            : "-"}
                        </span>
                        &nbsp;
                        <span
                          className={` ${
                            data.action_taken == "Success"
                              ? "Successed"
                              : "Normalize"
                          }`}
                        >
                          {data.action_taken}
                        </span>
                        {/* {data?.} */}
                      </Label1>
                    </div>
                    <>
                      <div style={{ paddingTop: "5px" }}>
                        <div className="call_payment">
                          <div className="call_payment_header">
                            <LabelMedium>Receiver :</LabelMedium>
                          </div>
                          <ParagraphMedium>
                            {data && data?.content?.to[0]?.name}
                          </ParagraphMedium>
                        </div>
                        <div className="call_payment">
                          <LabelMedium>Payment Amount :</LabelMedium>
                          <ParagraphMedium>
                            {data.content.portal_payment_amount?.currency}
                          </ParagraphMedium>
                          <ParagraphMedium>
                            <NumericFormat
                              // type="text"
                              displayType="text"
                              value={
                                data.content.portal_payment_amount?.value
                                  ? Number(
                                      data.content.portal_payment_amount?.value
                                    )
                                  : 0
                              }
                              thousandsGroupStyle={currencyGroupStyle}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={currencySeparator}
                              decimalSeparator={currencyDecimalSeparator}
                              renderText={(value) => <> {value}</>}
                            />
                            {/* {data.content.payment_plan_amount?.value} */}
                          </ParagraphMedium>
                        </div>
                        <div className="call_payment">
                          <LabelMedium>Payment Date:</LabelMedium>
                          <ParagraphMedium>
                            {moment(data.executed_on).format("DD-MM-YYYY")}
                          </ParagraphMedium>
                        </div>
                        <div className="call_payment">
                          <LabelMedium>Payment method:</LabelMedium>
                          <ParagraphMedium>
                            {data &&
                            data.content &&
                            data.content.portal_payment_gateway_name
                              ? data.content.portal_payment_gateway_name
                              : "-"}
                          </ParagraphMedium>
                        </div>
                        <div className="call_payment">
                          <LabelMedium>Payment Reference:</LabelMedium>
                          <ParagraphMedium>
                            {data &&
                            data.content &&
                            data.content.portal_payment_reference
                              ? data.content.portal_payment_reference
                              : "-"}
                          </ParagraphMedium>
                        </div>
                        <div className="call_payment">
                          <LabelMedium>Invoice#:</LabelMedium>
                          <ParagraphMedium>
                            {data?.content?.portal_payment_invoice_number}
                          </ParagraphMedium>
                        </div>
                        {data?.comment == "" ? (
                          <>
                            {" "}
                            <div style={{ paddingTop: "5px" }}>
                              <LabelMedium
                                $style={{
                                  color: "#ADADAD",
                                }}
                              >
                                User Comments
                              </LabelMedium>
                              <ParagraphMedium>{data?.comment}</ParagraphMedium>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ paddingTop: "5px" }}>
                              <LabelMedium
                                $style={{
                                  color: "#ADADAD",
                                }}
                              >
                                User Comments
                              </LabelMedium>
                              <ParagraphMedium>{data?.comment}</ParagraphMedium>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                    <div
                      title={
                        filteringDisputeUsers &&
                        filteringDisputeUsers[0] &&
                        filteringDisputeUsers[0]?.label
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Avatar
                        name={
                          filteringDisputeUsers &&
                          filteringDisputeUsers[0] &&
                          filteringDisputeUsers[0]?.label
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    // title={props.is_receiver ? "Consumer via Portal" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar name={"Consumer"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {disputeValueDate} <FormattedTime value={data.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    case "FIELD_VISIT": {
      const fieldVisit = data;

      let fieldContent = fieldVisit && fieldVisit.content;

      let location = fieldContent?.visit_address;

      let rdTime = referenceData && referenceData["time"];

      let visitedTime = rdTime?.find((e) => e?.id === fieldContent?.visit_time);

      let visitedDate = moment
        .utc(fieldContent.visit_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);

      const executedOnDateFormate = moment
        .utc(fieldVisit.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      let users = referenceData["users"];

      let filteringCallUsers = users?.find(
        (i) => i.id === fieldVisit.executed_by
      );
      return (
        <>
          <div className={"cp-timeline-contant__status-view"}>
            <div className="cp-status-view__icon">
              {/* <TemplateIcon type={EMAIL} /> */}
              <TemplateIcon type={"FIELD_VISIT"} />
              {/* FIELD VISIT */}
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // ...(is_receiver ? { position: "relative" } : {}),
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <LabelLarge
                      $style={{
                        color: "#333860",
                        paddingRight: "2px",
                        maxWidth: "calc(100% - 30px)",
                        whiteSpace: "nowrap",
                        // width: 50px;
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Call - Field Visit on {visitedDate} {visitedTime?.label}
                    </LabelLarge>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "28px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "70px" }}>
                      <Paragraph2 $style={{ color: "#ADADAD" }}>
                        Recipients :{" "}
                      </Paragraph2>
                    </div>
                    <Paragraph2>
                      {fieldContent?.contact_name}{" "}
                      {fieldContent?.to?.[0]?.value}{" "}
                      {intl.formatMessage({
                        id: fieldContent?.to?.[0]?.designation,
                      })}
                    </Paragraph2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{ width: "90px" }}>
                      <Paragraph2 $style={{ color: "#ADADAD" }}>
                        Location :
                      </Paragraph2>
                    </div>
                    <div>
                      <div style={{ color: "#787878", paddingBottom: "3px" }}>
                        {fieldVisit.visitedOn}
                      </div>
                      {fieldContent?.custom_location !== "" ? (
                        <Paragraph2 $style={{ paddingBottom: "3px" }}>
                          {fieldContent?.custom_location}
                        </Paragraph2>
                      ) : (
                        <Paragraph2>
                          {location?.line_1} {location?.line_2} {location?.city}{" "}
                          {location?.state} {location?.city}{" "}
                          {location?.zip_code} {location?.country}
                        </Paragraph2>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{ width: "93px" }}>
                      <Paragraph2 $style={{ color: "#ADADAD" }}>
                        Field Visit Date :
                      </Paragraph2>
                    </div>
                    <Paragraph2>
                      {moment
                        .utc(fieldContent?.visit_date)
                        ?.tz(timeZone)
                        ?.format(currentDefaultFormatDetails.date_format)}
                    </Paragraph2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{ width: "95px" }}>
                      <Paragraph2 $style={{ color: "#ADADAD" }}>
                        Field Visit Time :
                      </Paragraph2>
                    </div>
                    <Paragraph2>
                      {visitedTime && visitedTime.label
                        ? visitedTime.label
                        : "-"}
                    </Paragraph2>
                  </div>
                  <div>
                    {fieldVisit?.content?.is_hold_workflow ? (
                      <Paragraph2>
                        Workflow Paused till Field Visit Date
                      </Paragraph2>
                    ) : (
                      ""
                    )}
                    {fieldVisit?.content?.is_send_action_follow_up ? (
                      <Paragraph2>Field Visit Reminder Message Sent</Paragraph2>
                    ) : (
                      ""
                    )}
                    {fieldVisit?.content?.is_send_confirmation_message ? (
                      <Paragraph2>Confirmation Message Sent</Paragraph2>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "107px" }}>
                      <Paragraph2 $style={{ color: "#ADADAD" }}>
                        Comments :{" "}
                      </Paragraph2>
                    </div>
                    <Paragraph2
                      style={{
                        color: "#787878",
                      }}
                    >
                      {fieldVisit?.comment ? fieldVisit?.comment : "--"}
                    </Paragraph2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                  <ProfileIcon />
                </div> */}

                  {/* <div
                    title={fieldVisit.user_name}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar name={fieldVisit.user_name} />
                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
            <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
          </div> */}
            <div
              className="cp-status-view__day"
              style={{
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {/* {filteringReturnUsers} */}
                {filteringCallUsers?.label}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {executedOnDateFormate}{" "}
                <FormattedTime value={new Date(fieldVisit.executed_on)} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    default:
      return null;
  }
};

const LetterCardStatus = ({ type }) => {
  switch (type) {
    case "TRANSIT":
      return (
        <div
          style={{
            color: "#FBBD04",
            background: "#FFF8E4",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Transit
        </div>
      );
    case "RETURNED":
      return (
        <div
          style={{
            color: "#FD372A",
            background: "#FFE7E5",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Returned
        </div>
      );

    case "DELIVERED":
      return (
        <div
          style={{
            color: "#0FB158",
            background: "#F1FFF7",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Delivered
        </div>
      );

    case "FAILED":
      return (
        <div
          style={{
            color: "#FD372A",
            background: "#FFE7E5",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Failed
        </div>
      );

    default:
      return null;
  }
};

const TimelineCard = (props) => {
  let intl = useIntl();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const [isDrawer, setIsDrawer] = useState(false);

  const [isId, setIsId] = useState();

  const handlerDrawer = () => {
    if (
      props &&
      props?.content &&
      props?.content?.payment_plan_id !== undefined
    ) {
      setIsDrawer(!isDrawer);
      setIsId(props.content.payment_plan_id);
    }
  };

  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const { currencyDecimalSeparator, currencyGroupStyle, currencySeparator } =
    useContext(MaxyfiContext);
  const executedOnDateFormate = moment(props.executed_on).format(
    currentDefaultFormatDetails.date_format
  );

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const readOnDate = moment
    .utc(props.read_on)
    .format(currentDefaultFormatDetails.date_format);

  const contentMeetingData = moment
    .utc(props.content?.meeting_date)
    .format(currentDefaultFormatDetails.date_format);

  const timeZone = orgRef?.time_zone;

  const [expand, setExpand] = React.useState(false);

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams =
    props?.entity == "invoices"
      ? getSearchParams.get("inv")
      : getSearchParams.get("customers");

  const expanToggle = () => {
    setExpand(!expand);
  };

  let DeliveredStatusSend;
  if (props.is_send === true) {
    DeliveredStatusSend = () => <SingleTick />;
  } else if (props.is_delivered === true) {
    DeliveredStatusSend = () => <DeliverTick />;
  } else if (props.is_seen === true) {
    DeliveredStatusSend = () => "isSeen";
  } else {
    DeliveredStatusSend = () => <></>;
  }

  const DeliveredStatusIcon = ({ type }) => {
    switch (type) {
      case "FAILURE":
        return (
          <div className="whatsapp_info_alert">
            <AlertInfo />
          </div>
        );
      case "UNREAD":
        return (
          <>
            <SingleTick />
          </>
        );
      case "READ":
        return (
          <>
            <DeliverTick />
          </>
        );
      case "SUCCESS":
        return (
          <>
            <SingleTick />
          </>
        );
      default:
        return null;
    }
  };

  let { type, name, description, body, time, content, is_receiver, entity } =
    props;

  let TypeName;

  const markAsReadMutation = useMutation(
    (timelineId) =>
      markEmailAsReadCustomerTimeline({
        customerId: getParams,
        timelineId,
        organization: currentOrganization,
        entity: entity,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        dispatch(refetchTimeline());
        queryClient.invalidateQueries({
          predicate: (query) => {
            if (
              query &&
              query.queryKey[0] &&
              query.queryKey[0].startsWith("TIMELINE_")
            ) {
              return true;
            }
            return false;
          },
        });
      },
    }
  );
  const subCategory = useQuery(
    [`sub-category-`],
    async () => {
      return await getSubCategory({
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  switch (type) {
    case "whatsapp":
      TypeName = () => (
        <Label1
          $style={{
            color: "#4CAF50",
          }}
        >
          WhatsApp:
        </Label1>
      );
      break;
    case "sms":
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          SMS
        </Label1>
      );
      break;
    case "letter":
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Letter
        </Label1>
      );
      break;
    case "email":
      TypeName = () => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {is_receiver && (
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50px",
                backgroundColor: "#516beb",
              }}
            ></div>
          )}
          <Label1
            $style={{
              color: "#516beb",
            }}
          >
            Email
          </Label1>
        </div>
      );
      break;

    case "promise_to_pay":
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Call: Promise to Pay
        </Label1>
      );
      break;

    case "payment":
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Promise to Pay
        </Label1>
      );
      break;

    case "meeting_request":
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Metting Request
        </Label1>
      );
      break;
    case "unsuccessful":
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Call Unsuccessful
        </Label1>
      );
      break;

    case "NEXT_ACTION":
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Next Action
        </Label1>
      );
      break;

    case "hold_action":
      TypeName = () => (
        <Label1
          $style={{
            color: "#FD372A",
          }}
        >
          Hold Action Till
        </Label1>
      );
      break;

    case "SKIP_TRACE":
      TypeName = () => (
        <Label1
          $style={{
            color: "#333860",
          }}
        >
          Skip Trace
        </Label1>
      );
      break;
    case RM:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          RM
        </Label1>
      );
      break;

    case WORKFLOW:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Workflow
        </Label1>
      );
      break;

    case BUSINESS_UNIT:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Client
        </Label1>
      );
      break;

    case CUSTOMERT_STATUS:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Consumer Status
        </Label1>
      );
      break;

    case NOTES:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Notes
        </Label1>
      );
      break;

    case CONTACTS:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Contact
        </Label1>
      );
      break;

    case CUSTOMERPORTAL:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Portal
        </Label1>
      );
      break;

    case PORTAL_PAYMENT:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Portal Payment
        </Label1>
      );
      break;
    case APP_PAYMENT:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Payment
        </Label1>
      );
      break;

    case CALL_RECORDING:
      TypeName = () => (
        <Label1
          $style={{
            color: "#516beb",
          }}
        >
          Call Recording
        </Label1>
      );
      break;

    case CUSTOMER_NAME_UPDATE:
      TypeName = () => (
        <Label1
          $style={{
            color: "#333860",
          }}
        >
          Consumer Name Changed to
        </Label1>
      );
      break;

    case "EDIT_FIELD_VISIT":
      TypeName = () => (
        <Label1
          $style={{
            color: "#333860",
          }}
        >
          Field Visit Edited -
        </Label1>
      );
      break;

    default:
      TypeName = () => <></>;
      break;
  }

  switch (type) {
    case BOT:
      return (
        <div className="cp-timeline-contant__bot-status-view">
          <div className="cp-status-view__bot-icon">
            <TemplateIcon type={LETTER} />
          </div>
          <div className="cp-status-view__bot-discretion">
            <div className="cp-discretion__bot-alert-ic">
              <Warning />
            </div>
            <div className="cp-discretion__bot-text-content">
              <Label1>
                Letter -{" "}
                <span className="cp-discretion__bot-text-content__weight">
                  Legal Letter
                </span>{" "}
                <span className="cp-discretion__bot-text-content__sz-cl">
                  (Due 3 Days)
                </span>
              </Label1>
              <Paragraph2>
                Max Bot Alert - Legal Letter 2 as per workflow “std worflow” was
                to be sent on 10 - 02 -2022
              </Paragraph2>
              <StyledLink href="#">Send “Legal letter 2” now</StyledLink>
            </div>
            <div>
              <img alt="" src={TimelineBot} />
            </div>
          </div>
          <div className="cp-status-view__day">
            <Label2 $style={{ color: "#C4C4C4" }}>Today</Label2>
          </div>
        </div>
      );

    case LETTER:
      // Comment Value
      const letterValue = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === letterValue.executed_by
      );

      let letterMethod = referenceData["letter_delivery_method_list"];

      // let letterUsers = referenceData["users"];

      // let filteringLetterUsers = letterUsers.filter(
      //   (i) => i.id === letterValue.executed_by
      // );

      // Contant Value
      const LetterslicingValue = letterValue.comment.slice(0, 200);

      // Contact Value
      // const contactSlice = content.to.slice(0, 2);
      // const contactSliceTwo = content.to.slice(0, content.to.length);
      //
      let letterContent = props.content;
      let letterContentTo = props.content.to && props.content.to[0];

      let filteringLetterUsers = letterMethod?.filter(
        (i) => i.id === letterContent.delivery_method
      );

      const executedOnDateFormateletter = moment
        .utc(props.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      const dateOfCommunication = moment
        .utc(letterContent.date_of_communication)
        .format(currentDefaultFormatDetails.date_format);

      return (
        <div className="cp-timeline-contant__status-view">
          <div className="cp-status-view__icon">
            <TemplateIcon type={LETTER} />
          </div>
          <div className={`cp-status-view__discretion`}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="cp-discretion__text-content">
                <div className="cp-text-content__text-deliver">
                  <Label1
                    $style={{
                      color: "#516beb",
                      marginRight: "12px",
                      display: "flex",
                      gap: "3px",
                    }}
                  >
                    {type ? <TypeName /> : " "} -{" "}
                    <span className="cp-discretion__text-content__weight">
                      {name ? name : " "}
                    </span>{" "}
                    {" - "}
                    <LetterCardStatus type={props?.content?.delivery_status} />
                  </Label1>
                  <DeliveredStatusSend />
                </div>
                <Paragraph2>{description ? description : ""}</Paragraph2>
                <div style={{ paddingTop: "5px" }}>
                  <div
                    $style={{
                      color: "#ADADAD",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "5px",
                      }}
                    >
                      <ParagraphMedium
                        $style={{
                          color: "#ADADAD",
                        }}
                      >
                        Recepient:
                      </ParagraphMedium>
                      <ParagraphMedium
                        style={{
                          color: "#787878",
                          //  display: "flex"
                        }}
                      >
                        {letterContent && letterContent.to ? (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>
                              {letterContentTo?.name
                                ? letterContentTo?.name
                                : ""}
                              -{" "}
                              {letterContentTo?.line_1
                                ? letterContentTo?.line_1
                                : ""}{" "}
                              {letterContentTo?.line_2
                                ? letterContentTo?.line_2
                                : ""}{" "}
                              {letterContentTo?.country
                                ? letterContentTo?.country
                                : ""}{" "}
                              {letterContentTo?.city
                                ? letterContentTo?.city
                                : ""}{" "}
                              {letterContentTo?.zip_code}
                              {""}
                            </div>
                            <div>
                              {letterContentTo && letterContentTo?.value}{" "}
                              &#8226; {dateOfCommunication} &#8226;{" "}
                              {filteringLetterUsers &&
                                filteringLetterUsers[0] &&
                                filteringLetterUsers[0]?.label}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </ParagraphMedium>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "5px",
                      }}
                    >
                      <ParagraphMedium
                        $style={{
                          color: "#ADADAD",
                        }}
                      >
                        Tracking URL :
                      </ParagraphMedium>
                      {letterContent?.tracking_url ? (
                        <>
                          <a
                            style={{
                              color: "#516beb",
                              cursor: "pointer",
                            }}
                            href={`${letterContent?.tracking_url}`}
                            target="_blank"
                          >
                            {letterContent?.tracking_url}
                          </a>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    {letterContent.attachments?.map((i) => {
                      let splitType = i && i.name.split(".");

                      return (
                        <div style={{ width: "350px" }}>
                          <DocumentCard
                            res={{
                              file_name: i.name,
                              mime_type: splitType[1],
                              _id: i.ref,
                              // type: splitType[1],
                              // file_,
                              customerId: props.customerId,
                              tab: "timeline",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {/* <ParagraphMedium>{LetterslicingValue}</ParagraphMedium> */}
                </div>
                {/* <Paragraph2>{LetterslicingValue}</Paragraph2> */}

                {/* <LabelMedium
                  $style={{
                    color: "#ADADAD",
                  }}
                >
                  User Comments
                </LabelMedium> */}
                {props?.comment && props.comment.length > 1 ? (
                  <>
                    <div
                      style={{
                        background: "#D8DFFF55",
                        width: "100%",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        // height: "100px",
                        color: "#333860",
                      }}
                    >
                      <ParagraphMedium>{props.comment}</ParagraphMedium>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <div className="cp-discretion__profile">
                  <ProfileIcon />
                </div> */}
                <div
                // title={filteringLetterUsers[0].label}
                // style={{ cursor: "pointer" }}
                >
                  {/* <Avatar name={filteringLetterUsers[0].label} /> */}
                </div>
                {/* <div onClick={() => expanToggle()}>
                  {expand ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                   
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <div className="cp-status-view__day">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {filteringLetterUser &&
                  filteringLetterUser[0] &&
                  filteringLetterUser[0]?.label}{" "}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* <FormattedDate
                value={new Date(1459832991883)}
                year="numeric"
                month="long"
                day="2-digit"
              />{" "} */}
                {executedOnDateFormateletter}{" "}
                <FormattedTime value={new Date(props.executed_on)} />
              </Label2>
            </div>
          </div>
        </div>
      );

    case "UPDATE_PROMISE_DATE":
    case "UPDATE_PAYMENT_PLAN_DATE": {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const fromDate = moment
        .utc(props?.content?.planned_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      const toDate = moment
        .utc(props?.content?.revised_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <PromiseToPay className="svg-primary-fill" />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <>
                    <div className="cp-text-content__text-deliver">
                      <Label1
                        $style={{
                          color: "#333860",
                          marginRight: "12px",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        {props.type === "UPDATE_PAYMENT_PLAN_DATE"
                          ? "Payment Plan"
                          : "Promise"}{" "}
                        Date Edited
                      </Label1>
                    </div>
                    <div style={{ paddingTop: "5px" }}>
                      {
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <ParagraphMedium $style={{ color: "#ADADAD" }}>
                              {" "}
                              Planned Date:{" "}
                            </ParagraphMedium>
                            <ParagraphMedium $style={{ color: "#333860" }}>
                              {fromDate}
                            </ParagraphMedium>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <ParagraphMedium $style={{ color: "#ADADAD" }}>
                              Revised Date:
                            </ParagraphMedium>
                            <ParagraphMedium $style={{ color: "#333860" }}>
                              {toDate}
                            </ParagraphMedium>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <ParagraphMedium $style={{ color: "#ADADAD" }}>
                              Comment:
                            </ParagraphMedium>
                            <ParagraphMedium $style={{ color: "#333860" }}>
                              {props && props.comment}
                            </ParagraphMedium>
                          </div>
                        </>
                      }
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {" "}
                  {filteringLetterUser &&
                  filteringLetterUser[0] &&
                  filteringLetterUser[0]?.label
                    ? filteringLetterUser[0]?.label
                    : ""}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {timezoneBasedDate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case WHATSAPP:
      const whatsappData = props;

      let users = referenceData["users"];
      let filteringUsers = users?.filter(
        (i) => i.id === whatsappData.executed_by
      );

      // let findNumber = props.content.to.find(
      //   ({ value }) => value == isWhatsappData.value
      // );

      //
      return (
        <div
          className={
            "cp-timeline-contant__status-view" + (is_receiver ? "__ft-r" : "")
          }
        >
          <div
            style={{
              backgroundColor: "#DBEADC",
              borderRadius: "25px",
              height: "45px",
              padding: "12px",
            }}
          >
            <TemplateIcon type={WHATSAPP} />
          </div>
          <div
            className="cp-status-view__discretion --whatsapp_hover"
            onClick={() => {
              dispatch(
                timelineWhatsappDrawer({
                  phoneNumber: props?.content?.to?.[0].value,
                })
              );
              dispatch(timelineChatRefetch());
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="cp-discretion__text-content">
                <div className="cp-text-content__text-deliver">
                  <Label1
                    $style={{
                      // color: "#516beb",
                      marginRight: "12px",
                      display: "flex",
                      gap: "3px",
                      fontWeight: "500",
                    }}
                  >
                    {/* <span
                      className="cp-discretion__text-content__weight"
                      style={{ color: "#333860",fontWeight:"500" }}
                    > */}
                    {props.content.to.map((to) => `${to.value}`).toString()}
                    {/* </span>{" "} */}
                  </Label1>
                  {/* <DeliveredStatus />
                   */}
                  <DeliveredStatusIcon type={props?.content?.to?.[0]?.status} />
                  {/* <DeliveredStatusSeen /> */}
                </div>
                {/* <div className="timeline_body_elips"> */}
                <ParagraphSmall style={{ color: "#787878", paddingTop: "5px" }}>
                  {whatsappData?.content?.body
                    ? whatsappData?.content?.body
                    : ""}
                </ParagraphSmall>
                {whatsappData?.content?.buttons?.[0]?.type ==
                "CALL_TO_ACTION" ? (
                  <>
                    <ParagraphLarge
                      $style={{
                        color: "#516beb",
                        textDecoration: "underline",
                      }}
                    >
                      {whatsappData?.content?.buttons?.[1]?.url}{" "}
                    </ParagraphLarge>
                    <ParagraphLarge $style={{ color: "#516beb" }}>
                      {whatsappData.content?.buttons?.[0]?.phone_number?.code &&
                        "+"}
                      {whatsappData.content?.buttons?.[0]?.phone_number?.code}{" "}
                      {whatsappData.content?.buttons?.[0]?.phone_number?.value}
                    </ParagraphLarge>
                  </>
                ) : whatsappData.content?.buttons?.[0]?.type ==
                  "QUICK_REPLY" ? (
                  whatsappData.content?.buttons?.map((qic) => (
                    <ParagraphLarge $style={{ color: "#516beb" }}>
                      {qic.text}{" "}
                    </ParagraphLarge>
                  ))
                ) : (
                  ""
                )}
                {/* </div> */}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {!is_receiver ? (
                  <div
                    title={
                      filteringUsers &&
                      filteringUsers[0] &&
                      filteringUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={filteringUsers && filteringUsers[0] && [0]?.label}
                    />
                  </div>
                ) : (
                  <div style={{ height: "40px" }}></div>
                )}

                <div>
                  <ParagraphSmall style={{ width: "60px", color: "#516BEB" }}>
                    {/* Chat now */}
                  </ParagraphSmall>
                </div>
              </div>
            </div>
          </div>
          <div className="cp-status-view__day" style={{ gap: "3px" }}>
            <Label2 $style={{ color: "#C4C4C4" }}>
              {executedOnDateFormate}{" "}
              <FormattedTime value={new Date(props.executed_on)} />
            </Label2>
          </div>
        </div>
      );

    case SMS:
      // Comment Value
      const commentValue = props;

      let SmsUsers = referenceData["users"];
      let filteringSmsUsers = SmsUsers?.filter(
        (i) => i.id === commentValue.executed_by
      );

      let recepients =
        commentValue && commentValue?.content?.to?.map((e) => e?.status);

      let receientsFailed = recepients?.includes("FAILURE");

      // Contant Value
      const slicingValue = commentValue.comment?.slice(0, 200);

      // Contact Value
      const contactSlice = content?.to?.slice(0, 2);
      const contactSliceTwo = content?.to?.slice(0, content?.to?.length);

      const ExecuteDateSms = intl.formatDate(props.executed_on, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      // const RecipientValue = commentValue.content?.to?.map((i) => {
      //   return `${i.name}${" - "}${i.value}`;
      // });
      // const RecipientValue = commentValue.content?.to
      //   ?.map((i) => `${i.name}${" - "}${i.value}`.toString())
      //   .toString()
      //   .replaceAll(",", ", ");
      //
      return (
        <div
          //  className="cp-timeline-contant__status-view"
          className={
            "cp-timeline-contant__status-view" + (is_receiver ? "__ft-r" : "")
          }
        >
          <div className="cp-status-view__icon">
            <TemplateIcon type={SMS} />
          </div>
          <div
            className="cp-status-view__discretion"
            style={{ padding: "0px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                className="cp-discretion__text-content"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "20px",
                }}
              >
                <div className="cp-text-content__text-deliver">
                  <Label1
                    $style={{
                      color: "#516beb",
                      marginRight: "12px",
                      display: "flex",
                      gap: "3px",
                    }}
                  >
                    {type ? <TypeName /> : " "}
                    {props.is_adhoc_action ? " (QA) " : ""}
                    {is_receiver ? "" : "-"}{" "}
                    <span className="cp-discretion__text-content__weight">
                      {is_receiver ? "Reply" : name}
                      {/* {name ? name : ""} */}
                    </span>{" "}
                  </Label1>
                  {/* <DeliveredStatus />
                   */}
                  {is_receiver ? (
                    <></>
                  ) : (
                    <>
                      <DeliveredStatusSend />
                    </>
                  )}
                  {/* <DeliveredStatusSeen /> */}
                </div>

                {/* <Paragraph2>{description ? description : ""}</Paragraph2> */}
                {/* {expand && slicingValue.length >= 200 ? (
                  <>
                    <Paragraph2>{content.body}</Paragraph2>
                    <div>HelloOne</div>
                  </>
                ) : contentLength < 200 ? (
                  <>
                    <div style={{ lineHeight: "" }}>{content.body}</div>
                    <div>HelloTWo</div>
                  </>
                ) : (
                  <>
                    <div style={{ lineHeight: "24px" }}>{slicingValue}</div>
                    <div>HelloThree</div>
                  </>
                )} */}

                <div style={{ paddingTop: "10px" }}>
                  {" "}
                  <Paragraph2
                    $style={{ color: "#ADADAD", display: "flex", gap: "3px" }}
                  >
                    Recipients
                  </Paragraph2>
                  {/* <Paragraph2>{RecipientValue}</Paragraph2> */}
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {commentValue.content?.to.map((e, i) => (
                      <Paragraph2
                        style={{
                          paddingRight: "2px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {e.name} {e.value}{" "}
                        {e.status === "FAILURE" ? (
                          //  <div

                          //                   style={{
                          //                     padding: "1px",
                          //                     border: "0.5px solid red",
                          //                     // borderRadius: "5px",
                          //                     color: "red",
                          //                     background: "#ffdfdf",
                          //                     // width: "70px",
                          //                     textAlign: "center",
                          //                     borderTopRightRadius: "5px",
                          //                     borderBottomLeftRadius: "5px",
                          //                     // height:"10px"
                          //                   }}
                          //                   >Failed</div>
                          // <div>Failed</div>
                          <Tag
                            closeable={false}
                            // kind={KIND.negative}
                            overrides={{
                              Text: {
                                style: ({ $theme }) => ({ color: "white" }),
                              },
                              Root: {
                                style: ({ $theme }) => ({
                                  background: "red",
                                  borderTopColor: "#ffffff",
                                  borderLeftColor: "#ffffff",
                                  borderRightColor: "#ffffff",
                                  borderBlockColor: "#ffffff",
                                  height: "18px",
                                  width: "56px",
                                }),
                              },
                            }}
                          >
                            Failed
                          </Tag>
                        ) : (
                          //  <div style={{paddingLeft:"3px"}}> Success</div>)

                          //                  <Tag closeable={false}
                          //     overrides={{
                          //       Text: {
                          //         style: ({ $theme }) => ({ color: "white" })
                          //       },
                          //       Root: {
                          //         style: ({ $theme }) => ({
                          //   background:"green",
                          //   borderTopColor:"#ffffff",
                          //   borderLeftColor:"#ffffff",
                          // borderRightColor:"#ffffff",
                          // borderBlockColor:"#ffffff" ,
                          // height:"18px",
                          // width:"55px"
                          //         })
                          //       }
                          //     }}
                          // >Success</Tag>

                          <></>
                        )}
                        {commentValue.content?.to?.length - 1 === i ? (
                          <span>.</span>
                        ) : (
                          <span>,</span>
                        )}
                      </Paragraph2>
                    ))}
                  </div>
                  {/* <TextBox
                    size={SIZE.compact}
                    label={intl.formatMessage({
                      id: "recipients",
                    })}
                    placeholder={intl.formatMessage({
                      id: "recipients",
                    })}
                    value={RecipientValue}
                  /> */}
                </div>
                <div>
                  <Paragraph2
                    $style={{ color: "#ADADAD", display: "flex", gap: "3px" }}
                  >
                    Content
                  </Paragraph2>
                  <Paragraph2>{content.body}</Paragraph2>
                  {is_receiver && commentValue?.is_updated_contact_invalid ? (
                    <div
                      style={{
                        backgroundColor:
                          commentValue &&
                          commentValue.update_contact_status_type === "VALID"
                            ? "#4CAF5050"
                            : "#ffefee",
                        color:
                          commentValue &&
                          commentValue.update_contact_status_type === "VALID"
                            ? "#4CAF50"
                            : "#FD372A",
                        fontSize: "13px",
                        padding: "2px 5px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      SMS Status Changed to{" "}
                      {commentValue && commentValue.update_contact_status_type
                        ? commentValue.update_contact_status_type
                        : "-"}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {commentValue?.comment?.length === 0 ? (
                  <></>
                ) : (
                  <>
                    <div style={{ paddingTop: "" }}>
                      <LabelMedium
                        $style={{
                          color: "#ADADAD",
                        }}
                      >
                        User Comments
                      </LabelMedium>

                      <ParagraphMedium
                        $style={{
                          color: "#333860",
                        }}
                      >
                        {props.comment}
                      </ParagraphMedium>
                    </div>
                  </>
                )}
                {/* <TextArea
                  label={intl.formatMessage({
                    id: "content_body",
                  })}
                  placeholder={intl.formatMessage({
                    id: "content_body",
                  })}
                  value={content.body}
                /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <div className="cp-discretion__profile">
                  <ProfileIcon />
                </div> */}
                <div
                  title={
                    filteringSmsUsers &&
                    filteringSmsUsers[0] &&
                    filteringSmsUsers[0]?.label
                  }
                  style={{ cursor: "pointer" }}
                >
                  {/* <Avatar
                    name={
                      filteringSmsUsers &&
                      filteringSmsUsers[0] &&
                      filteringSmsUsers[0]?.label
                    }
                  /> */}
                  {/* {receientsFailed ? (
                    <div
                      style={{
                        padding: "3px",
                        border: "0.5px solid red",
                        // borderRadius: "5px",
                        color: "red",
                        background: "#ffdfdf",
                        width: "70px",
                        textAlign: "center",
                        borderTopRightRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      }}
                    >
                      Failed
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: "3px",
                        border: "0.5px solid #48B24F",
                        color: "#008000",
                        // borderRadius: "5px",
                        background: "#F0FFF1",
                        width: "70px",
                        textAlign: "center",
                        borderTopRightRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      }}
                    >
                      Delivered
                    </div>
                  )} */}
                </div>
                {/* <div onClick={() => expanToggle()}>
                  {expand ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                     
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                    
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
          <div
            //  className="cp-status-view__day"
            style={{
              gap: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <Label2 $style={{ color: "#C4C4C4" }}>
              {filteringSmsUsers &&
                filteringSmsUsers[0] &&
                filteringSmsUsers[0]?.label}
            </Label2>
            <Label2 $style={{ color: "#C4C4C4" }}>
              {executedOnDateFormate}
              {/* <FormattedDate value={props.executed_on} /> */}{" "}
              <FormattedTime value={new Date(props.executed_on)} />
            </Label2>
          </div>
        </div>
      );

    case EMAIL:
      // Comment Value
      const emailCommentValue = props;

      let EmailUsers = referenceData["users"];
      let filteringEmailUsers = EmailUsers?.filter(
        (i) => i.id === emailCommentValue.executed_by
      );

      let filteringReadEmailUsers = EmailUsers?.find(
        (i) => i.id === emailCommentValue.read_by
      );

      // Content Value
      const emailSlicingValue = emailCommentValue.comment?.slice(0, 200);

      const contentValue = content;
      let CcDatas = contentValue.cc
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");
      let BCcDatas = contentValue.bcc
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      const contactSliceEmail = content.to?.slice(0, 2);
      const contactSliceEmailMore = content.to?.slice(0, content.to.length);
      // const [expandEmail, setExpandEmail] = React.useState(false);
      let Block = ReactQuill.Quill.import("blots/block");
      Block.tagName = "DIV";
      ReactQuill.Quill.register(Block, true);

      let Embed = ReactQuill.Quill.import("blots/embed");
      class MxTable extends Embed {
        static create(paramValue) {
          let node = super.create();
          node.innerHTML = paramValue;
          return node;
        }

        static value(node) {
          return node.innerHTML;
        }
      }

      MxTable.blotName = "table";
      MxTable.tagName = "mx-table";
      ReactQuill.Quill.register(MxTable);

      // Before Expand Attachment

      let attachmentVal =
        contentValue?.attachments.length > 0
          ? [contentValue.attachments[0]]
          : [];
      // ? contentValue.attachments[0]
      // : {};

      let deliveredEmail = emailCommentValue?.content?.delivered_recipients
        ? emailCommentValue?.content?.delivered_recipients
        : [{}];

      let failedEmail = emailCommentValue?.content?.failed_recipients
        ? emailCommentValue?.content?.failed_recipients
        : [{}];

      let EmailTableData = [...deliveredEmail, ...failedEmail];

      // let emailDeliveredData = [...emailCommentValue?.content?.delivered_recipients,...emailCommentValue?.content?.failed_recipients]

      let openedEmailData = emailCommentValue?.content?.open_status;

      return (
        <div
          className={
            "cp-timeline-contant__status-view" + (is_receiver ? "__ft-r" : "")
          }
        >
          <div className="cp-status-view__icon">
            <TemplateIcon type={EMAIL} />
          </div>
          <div
            className="cp-status-view__discretion"
            style={{ position: "relative" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                ...(is_receiver ? { position: "relative" } : {}),
              }}
            >
              {is_receiver && !props.is_read ? (
                <div className="cp-timeline_mark-as-read">
                  <TextButton
                    size={SIZE.mini}
                    kind="secondary"
                    isLoading={markAsReadMutation.isLoading}
                    disabled={markAsReadMutation.isLoading}
                    onClick={async () => {
                      await markAsReadMutation.mutate(props._id);
                    }}
                  >
                    Mark as Read
                  </TextButton>
                </div>
              ) : null}
              <div className="cp-discretion__text-content">
                <div className="cp-text-content__text-deliver">
                  <Label1
                    $style={{
                      color: "#516beb",
                      marginRight: "12px",
                      display: "flex",
                      gap: "3px",
                    }}
                  >
                    {type ? <TypeName /> : ""}
                    {props.is_adhoc_action ? " (QA) " : ""} -{" "}
                    <span className="cp-discretion__text-content__weight">
                      {name ? name : ""}
                    </span>{" "}
                  </Label1>
                  {emailCommentValue?.deliverablity_status ? (
                    ""
                  ) : (
                    <>
                      <DeliveredStatusSend />
                    </>
                  )}
                </div>
                <Paragraph2>{description ? description : " "}</Paragraph2>

                <div>
                  <Paragraph2
                    $style={{ color: "#ADADAD", display: "flex", gap: "3px" }}
                  >
                    {is_receiver ? "From:" : "Recipients:"}{" "}
                  </Paragraph2>
                  <Paragraph2>
                    {props.content.to.map((to) => (
                      <span>{to.value.toString()} </span>
                    ))}
                  </Paragraph2>
                </div>
                <Paragraph2>
                  <span style={{ color: "#ADADAD" }}>Sub:</span>{" "}
                  <div style={{ lineBreak: "anywhere" }}>
                    {contentValue.subject}
                  </div>
                </Paragraph2>

                {expand ? (
                  <>
                    {emailCommentValue.comment?.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ paddingTop: "5px" }}>
                          <LabelMedium
                            $style={{
                              color: "#ADADAD",
                            }}
                          >
                            User Comments
                          </LabelMedium>

                          <ParagraphMedium
                            $style={{
                              color: "#333860",
                            }}
                          >
                            {props.comment}
                          </ParagraphMedium>
                        </div>
                      </>
                    )}

                    <div className="cp-discretion-expand-content">
                      <div>
                        <>
                          <TextBox
                            size={SIZE.compact}
                            label={intl.formatMessage({
                              id: is_receiver ? "from" : "recipients",
                            })}
                            placeholder={intl.formatMessage({
                              id: is_receiver ? "from" : "recipients",
                            })}
                            value={
                              props?.content?.to?.lenght > 1
                                ? props.content.to
                                    .map((to) => `${to.value}`)
                                    .toString()
                                : ""
                            }
                          />
                        </>
                      </div>
                      <>
                        <TextBox
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "cc_e",
                          })}
                          placeholder={intl.formatMessage({
                            id: "cc_e",
                          })}
                          value={CcDatas}
                        />
                      </>
                      <>
                        <TextBox
                          size={SIZE.compact}
                          label={intl.formatMessage({
                            id: "bcc_e",
                          })}
                          placeholder={intl.formatMessage({
                            id: "bcc_e",
                          })}
                          value={BCcDatas}
                        />
                      </>

                      <TextBox
                        size={SIZE.compact}
                        label={intl.formatMessage({
                          id: "sb",
                        })}
                        placeholder={intl.formatMessage({
                          id: "sb",
                        })}
                        value={contentValue.subject}
                      />
                      {!is_receiver ? (
                        <div className="cp-quill">
                          {/* <Controller
                            name="content"
                            control={control}
                            render={({ field }) => (
                              <ReactQuill
                                theme="snow"
                                // value={content.body}
                                modules={{
                                  toolbar: 0,
                                }}
                                onChange={(content) => {
                                  field.onChange(content);
                                }}
                              />
                            )}
                          /> */}
                          {/* <iframe
                            srcDoc={content.body}
                            // src="demo_iframe_srcdoc.htm"
                          ></iframe> */}
                          <QuillFrame content={content.body} />
                        </div>
                      ) : (
                        <RBACWrapper type="FEATURE_BLUR" role={INBOUND_EMAIL}>
                          <div className="cp-quill">
                            {/* <Controller
                              name="content"
                              control={control}
                              render={({ field }) => (
                                <ReactQuill
                                  theme="snow"
                                  // value={content.body}
                                  modules={{
                                    toolbar: 0,
                                  }}
                                  onChange={(content) => {
                                    field.onChange(content);
                                  }}
                                />
                              )}
                            /> */}
                            <QuillFrame content={content.body} />
                          </div>
                        </RBACWrapper>
                      )}
                      <div style={{ paddingTop: "10px" }}>
                        {contentValue.attachments?.map((i) => {
                          let splitType = i && i.name.split(".");

                          return (
                            <div style={{ width: "350px" }}>
                              <DocumentCard
                                res={{
                                  file_name: i.name,
                                  mime_type: splitType[1],
                                  _id: i.ref,
                                  // type: splitType[1],
                                  // file_,
                                  customerId: emailCommentValue.customerId,
                                  tab: "timeline",
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        {is_receiver && props.is_read ? (
                          <Paragraph2>
                            Email read by{" "}
                            {filteringReadEmailUsers && filteringReadEmailUsers
                              ? filteringReadEmailUsers?.label
                              : ""}{" "}
                            on {readOnDate}{" "}
                            <FormattedTime value={props.read_on} />
                          </Paragraph2>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {emailCommentValue?.comment?.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        <div style={{ paddingTop: "" }}>
                          <LabelMedium
                            $style={{
                              color: "#ADADAD",
                            }}
                          >
                            User Comments
                          </LabelMedium>

                          <ParagraphMedium
                            $style={{
                              color: "#333860",
                            }}
                          >
                            {emailSlicingValue}
                          </ParagraphMedium>
                        </div>
                      </>
                    )}

                    {/* <Paragraph2>{emailSlicingValue}</Paragraph2> */}
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  gap: "5px",
                  flexDirection: "column",
                }}
              >
                <StatefulPopover
                  content={({ close }) => {
                    return (
                      <div
                        //  style={{ paddingTop: "10px", paddingBottom: "20px" }}
                        style={{
                          width: "600px",
                          borderRadius: "5px",
                          height: "250px",
                          backgroundColor: "#ffffff",
                          padding: "15px",
                          overflow: "scroll",
                        }}
                      >
                        <div
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                          }}
                        >
                          <Label3>Email Delivery Status :</Label3>
                        </div>
                        <table>
                          <tr style={{ backgroundColor: "#FFFFFF" }}>
                            <th>Email ID</th>
                            <th>Status</th>
                            <th>Time</th>
                            <th>Reason</th>
                          </tr>

                          {[...(EmailTableData ? EmailTableData : {})].map(
                            (e, i) => {
                              // let date = e?.delivered_on?.replace("e", "");

                              //
                              let deliveredDate = moment
                                .utc(e?.delivered_on)
                                ?.tz(timeZone)
                                ?.format(
                                  currentDefaultFormatDetails.date_format
                                );

                              let reasonList = [
                                {
                                  reasonId: "550",
                                  label: "Mailbox Does Not Exist",
                                },
                                {
                                  reasonId: "5.1.1",
                                  label: "Mailbox Does Not Exist",
                                },
                                {
                                  reasonId: "552",
                                  label: "Message Too Large",
                                },
                                {
                                  reasonId: "5.3.4",
                                  label: "Message Too Large",
                                },
                                {
                                  reasonId: "5.2.2",
                                  label: "Mailbox Full",
                                },
                                {
                                  reasonId: "500",
                                  label: "Message Content Rejected",
                                },
                                {
                                  reasonId: "5.6.1",
                                  label: "Message Content Rejected",
                                },
                                {
                                  reasonId: "554",
                                  label: "Unknown Failure",
                                },
                                {
                                  reasonId: "5.0.0",
                                  label: "Unkown Failure",
                                },
                                {
                                  reasonId: "450",
                                  label: "Temporary Failure",
                                },
                                {
                                  reasonId: "4.0.0",
                                  label: "Temporary Failure",
                                },
                                // { reasonId: "5.3.0", label: "Temporary Failure" },
                              ];

                              let findingReason = reasonList?.find(
                                (i) => i?.reasonId === e?.reason
                              );

                              return (
                                <tr>
                                  {/* // PRODUCT */}
                                  <td>
                                    {/* <div style={{ width: "120px", marginTop: "15px" }}> */}
                                    {e?.value ? e?.value : ""}
                                    {/* </div> */}
                                  </td>
                                  {/* // DESCRIPTION */}
                                  <td>
                                    <div style={{ width: "" }}>
                                      {e?.reason ? (
                                        <div style={{ color: "#FD372A" }}>
                                          Failed
                                        </div>
                                      ) : (
                                        <div style={{ color: "#4CAF50" }}>
                                          Delivered
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                  {/* // COST */}
                                  <td>
                                    <div style={{ width: "" }}>
                                      {e?.delivered_on ? (
                                        <>
                                          {deliveredDate}{" "}
                                          <FormattedTime
                                            value={e?.delivered_on}
                                          />
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                  {/* // QUANTITY */}
                                  <td>
                                    <div style={{ width: "" }}>
                                      {e?.reason
                                        ? findingReason?.label
                                          ? findingReason?.label
                                          : "Unknown Error"
                                        : ""}
                                      {/* {findingReason && e?.reason? findingReason?.label:"Unknown Error Occurred"} */}
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </table>

                        <div
                          style={{
                            paddingBottom: "10px",
                            paddingTop: "10px",
                          }}
                        >
                          <Label3>Email Read At :</Label3>
                        </div>
                        <table>
                          <tr style={{ backgroundColor: "#FFFFFF" }}>
                            <th>Read Time</th>
                            <th>Read from IP Address</th>
                          </tr>

                          {[...(openedEmailData ? openedEmailData : {})].map(
                            (e, i) => {
                              // let date = e?.delivered_on?.replace("e", "");

                              //
                              let deliveredDate = moment
                                .utc(e.open_at)
                                ?.tz(timeZone)
                                ?.format(
                                  currentDefaultFormatDetails.date_format
                                );

                              return (
                                <tr>
                                  {/* // PRODUCT */}
                                  <td>
                                    {/* <div style={{ width: "120px", marginTop: "15px" }}> */}
                                    {deliveredDate}{" "}
                                    <FormattedTime value={e?.open_at} />
                                    {/* </div> */}
                                  </td>
                                  {/* // DESCRIPTION */}
                                  <td>
                                    <div style={{ width: "" }}>
                                      {e?.ip_address ? e.ip_address : ""}
                                    </div>
                                  </td>
                                  {/* // COST */}
                                </tr>
                              );
                            }
                          )}
                        </table>
                      </div>
                    );
                  }}
                >
                  <div>
                    <DeliveryStatus
                      type={emailCommentValue?.deliverablity_status}
                    />
                  </div>
                </StatefulPopover>
              </div>
            </div>
            <div style={{ width: "100%", textAlign: "right" }}>
              {expand ? (
                <div>
                  <IconButton
                    kind={KIND.tertiary}
                    onClick={() => {
                      dispatch(drawerOpen());
                      dispatch(
                        emailDataToDrawer({
                          emailType: REPLY,
                          emailData: contentValue,
                          isReceiver: is_receiver,
                        })
                      );
                    }}
                  >
                    <Reply />
                  </IconButton>
                  <IconButton
                    kind={KIND.tertiary}
                    onClick={() => {
                      dispatch(drawerOpen());
                      dispatch(
                        emailDataToDrawer({
                          emailType: REPLY_ALL,
                          emailData: contentValue,
                          isReceiver: is_receiver,
                        })
                      );
                    }}
                  >
                    <ReplyAll />{" "}
                  </IconButton>
                  <IconButton
                    kind={KIND.tertiary}
                    onClick={() => {
                      dispatch(drawerOpen());
                      dispatch(
                        emailDataToDrawer({
                          emailType: FORWARD,
                          emailData: contentValue,
                          isReceiver: is_receiver,
                        })
                      );
                    }}
                  >
                    <Forward />
                  </IconButton>
                  <IconButton
                    kind={KIND.tertiary}
                    onClick={() => expanToggle()}
                  >
                    <CollapseUpArrow />
                  </IconButton>
                </div>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    {attachmentVal?.map((e, i) => {
                      let splitType =
                        e && e.name && e.name && e.name.split(".");

                      return (
                        <div style={{ width: "260px" }}>
                          <DocumentCard
                            res={{
                              file_name: e.name,
                              mime_type: splitType[1],
                              _id: i.ref,
                              // type: splitType[1],
                              // file_,
                              // customerId: emailCommentValue.customerId,
                              tab: "timeline",
                              // isDownload: true,
                              isDownload: true,
                            }}
                          />
                        </div>
                      );
                    })}
                    {contentValue.attachments.length === 1 ||
                    contentValue.attachments.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        <div
                          style={{
                            width: "35px",
                            border: "0.5px solid #CDCED9",
                            borderRadius: "5px",
                            color: "#407BFF",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => expanToggle()}
                        >
                          +{contentValue.attachments.length - 1}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <div>
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={() => {
                          dispatch(drawerOpen());
                          dispatch(
                            emailDataToDrawer({
                              emailType: REPLY,
                              emailData: contentValue,
                              isReceiver: is_receiver,
                            })
                          );
                        }}
                      >
                        <Reply />
                      </IconButton>
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={() => {
                          dispatch(drawerOpen());
                          dispatch(
                            emailDataToDrawer({
                              emailType: REPLY_ALL,
                              emailData: contentValue,
                              isReceiver: is_receiver,
                            })
                          );
                        }}
                      >
                        <ReplyAll />
                      </IconButton>
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={() => {
                          dispatch(drawerOpen());
                          dispatch(
                            emailDataToDrawer({
                              emailType: FORWARD,
                              emailData: contentValue,
                              isReceiver: is_receiver,
                            })
                          );
                        }}
                      >
                        <Forward />
                      </IconButton>
                      <IconButton
                        kind={KIND.tertiary}
                        onClick={() => expanToggle()}
                      >
                        <CollapseDownArrow />
                      </IconButton>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="cp-status-view__day">
            <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
          </div> */}
          <div className="cp-status-view__day" style={{ gap: "5px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {filteringEmailUsers &&
                  filteringEmailUsers[0] &&
                  filteringEmailUsers[0]?.label}{" "}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedOnDateFormate}{" "}
                <FormattedTime value={new Date(props.executed_on)} />
              </Label2>
            </div>
          </div>
        </div>
      );
    case INVOICE_UPDATE:
      let invoiceData = props;

      let usersInvoice = referenceData["users"];

      let filteringInvoiceUpate = usersInvoice?.filter(
        (i) => i.id === invoiceData.executed_by
      );

      const invoiceDate = moment
        .utc(invoiceData?.content?.invoice_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const invoiceDueDate = moment
        .utc(invoiceData?.content?.due_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const executedInvoiceDate = moment
        .utc(props.executed_on)
        .format(currentDefaultFormatDetails.date_format);

      return (
        <div className="cp-timeline-contant__status-view">
          <div className="cp-status-view__icon">
            <TemplateIcon type={INVOICE} />
          </div>
          <div className="cp-status-view__discretion">
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="cp-discretion__text-content">
                <div className="cp-text-content__text-deliver">
                  <Label1 $style={{ color: "#516beb", marginRight: "12px" }}>
                    Invoice Updated -
                  </Label1>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#516beb",
                    }}
                  >
                    {invoiceData?.content?.invoice_number}
                  </span>{" "}
                  {invoiceData?.content?.is_cancelled ? (
                    <div style={{ paddingLeft: "5px", color: "red" }}>
                      ( Cancelled )
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div>{is_cancelled}</div> */}
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <div>
                      <Paragraph2
                        $style={{ color: "#ADADAD", whiteSpace: "nowrap" }}
                      >
                        Invoice Number:
                      </Paragraph2>

                      <Paragraph2
                        $style={{ color: "#ADADAD", whiteSpace: "nowrap" }}
                      >
                        Invoice Amount:
                      </Paragraph2>
                    </div>

                    <div>
                      <Paragraph2>
                        {invoiceData?.content?.invoice_number
                          ? invoiceData?.content?.invoice_number
                          : "--"}
                      </Paragraph2>

                      <Paragraph2>
                        {invoiceData?.content?.amount?.value ? (
                          <>
                            {invoiceData?.content?.amount?.currency}{" "}
                            {invoiceData?.content?.amount?.value}
                          </>
                        ) : (
                          <>--</>
                        )}
                      </Paragraph2>
                    </div>
                  </div>

                  <div style={{ display: "flex", gap: "5px" }}>
                    <div>
                      <Paragraph2
                        $style={{ color: "#ADADAD", whiteSpace: "nowrap" }}
                      >
                        Invoice Date:
                      </Paragraph2>

                      <Paragraph2
                        $style={{ color: "#ADADAD", whiteSpace: "nowrap" }}
                      >
                        Due Date:
                      </Paragraph2>
                    </div>

                    <div>
                      <Paragraph2>{invoiceDate}</Paragraph2>

                      <Paragraph2>{invoiceDueDate}</Paragraph2>
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <Paragraph2
                    $style={{ color: "#ADADAD", whiteSpace: "nowrap" }}
                  >
                    Comments:
                  </Paragraph2>
                  <div>{invoiceData?.comment}</div>
                </div>

                {/* <div style={{display:"flex"}}>



                <div className="cp-discretion-expand__invoice-view-field">

                  
                    <div className="cp-expand__invoice-lable">
                      <Paragraph2 $style={{ color: "#ADADAD", whiteSpace:"nowrap"}}>
                        Invoice Number: 
                      </Paragraph2>
                    </div>
                    <div className="cp-expand__cont">
                      <Paragraph2>INV-398632434</Paragraph2>
                    </div>
                  </div>

                  <div className="cp-discretion-expand__invoice-view-field">
                    <div className="cp-expand__invoice-lable">
                      <Paragraph2 $style={{ color: "#ADADAD" }}>
                        Invoice Date: 
                      </Paragraph2>
                    </div>
                    <div className="cp-expand__cont">
                      <Paragraph2>31 - 02 - 2022</Paragraph2>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="cp-status-view__day">
            <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
          </div> */}
          <div className="cp-status-view__day">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {filteringInvoiceUpate &&
                  filteringInvoiceUpate[0] &&
                  filteringInvoiceUpate[0]?.label}{" "}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* <FormattedDate
                value={new Date(1459832991883)}
                year="numeric"
                month="long"
                day="2-digit"
              />{" "} */}
                {executedInvoiceDate}{" "}
                <FormattedTime value={new Date(props.executed_on)} />
              </Label2>
            </div>
          </div>
        </div>
      );
    case NOTE:
      return (
        <div className="cp-timeline-contant__status-view">
          <div className="cp-status-view__icon">
            <TemplateIcon type={NOTE} />
          </div>
          <div className="cp-status-view__discretion">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="cp-discretion__text-content">
                <div className="cp-text-content__text-deliver">
                  <Label1 $style={{ color: "#516beb", marginRight: "12px" }}>
                    NOTES -{" "}
                    <span className="cp-discretion__text-content__weight">
                      About some considerations has been discussed
                    </span>{" "}
                  </Label1>
                  <DeliverTick />
                </div>
                <Paragraph2>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis hendrerit dolor magna eget est lorem ipsum. Amet dictum
                  sit amet just donec enim diam. Ut morbi tincidunt aug
                </Paragraph2>
                {expand ? (
                  <Paragraph2>
                    Legal notice 1 was issued to this consumer as he have not
                    paid for any of the bills for the last 90 days has he not
                    responding to any email and SMS shared earlier
                  </Paragraph2>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <div className="cp-discretion__profile">
                  <ProfileIcon />
                </div> */}

                <div
                  // title={filteringUsers[0].label}
                  style={{ cursor: "pointer" }}
                >
                  <Avatar
                  // name={filteringUsers[0].label}
                  />
                </div>
                <div onClick={() => expanToggle()}>
                  {expand ? (
                    <IconButton kind={KIND.tertiary}>
                      <CollapseUpArrow />
                    </IconButton>
                  ) : (
                    <IconButton kind={KIND.tertiary}>
                      <CollapseDownArrow />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="cp-status-view__day">
            <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
          </div>
        </div>
      );

    case MEETINGREQUEST:
      const mettingValue = props;

      let MettingRequestusers = referenceData["users"];
      let filteringMettingRequestUsers = MettingRequestusers?.filter(
        (i) => i.id === mettingValue.executed_by
      );

      const MettingDate = intl.formatDate(mettingValue.content.meeting_date, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      const MettingTime = intl.formatTime(mettingValue.content.meeting_time, {
        value: "numeric",
        format: "string",
      });

      const mettingSlicingValue = mettingValue.comment?.slice(0, 200);
      // Receiver

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={MEETINGREQUEST} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "} -{" "}
                      <span className="cp-discretion__text-content__weight">
                        on {contentMeetingData}
                      </span>{" "}
                    </Label1>
                  </div>

                  {expand ? (
                    <>
                      <div style={{ paddingTop: "" }}>
                        <LabelMedium
                          $style={{
                            color: "#ADADAD",
                          }}
                        >
                          User Comments
                        </LabelMedium>

                        <ParagraphMedium
                          $style={{
                            color: "#333860",
                          }}
                        >
                          {props.comment}
                        </ParagraphMedium>
                      </div>

                      <div className="cp-expand__form-input">
                        {mettingValue.content?.to?.map((i) => (
                          <>
                            <TextBox
                              label={intl.formatMessage({
                                id: "receiver",
                              })}
                              placeholder={intl.formatMessage({
                                id: "receiver",
                              })}
                              value={
                                i.name && i.value ? `${i.name} ${i.value} ` : ""
                              }
                            />
                          </>
                        ))}

                        <div className="cp-expand__type">
                          <TextBox
                            label={intl.formatMessage({
                              id: "meeting_venue",
                            })}
                            placeholder={intl.formatMessage({
                              id: "meeting_venue",
                            })}
                            value={mettingValue.content?.meeting_venue}
                          />
                          <TextBox
                            label={intl.formatMessage({
                              id: "meeting_date",
                            })}
                            placeholder={intl.formatMessage({
                              id: "meeting_date",
                            })}
                            value={MettingDate}
                          />
                          <TextBox
                            label={intl.formatMessage({
                              id: "meeting_time",
                            })}
                            placeholder={intl.formatMessage({
                              id: "meeting_time",
                            })}
                            value={MettingTime}
                          />
                        </div>

                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={mettingValue.content?.is_hold_workflow}
                        >
                          <FormattedMessage id="hold_workflow_till_promise_date">
                            hold_workflow_till_promise_date
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                        <div className="cp-expand__confir-check">
                          <CheckBoxBaseweb
                            labelPlacement={LABEL_PLACEMENT.right}
                            checked={
                              mettingValue.content?.is_send_confirmation_message
                            }
                          >
                            <FormattedMessage id="send_confirmation_message">
                              send_confirmation_message
                            </FormattedMessage>
                          </CheckBoxBaseweb>
                        </div>
                        <div className="cp-expand__confirm-input">
                          <div className="cp-expand__type-input">
                            <TextBox
                              label={intl.formatMessage({
                                id: "email",
                              })}
                              placeholder={intl.formatMessage({
                                id: "email",
                              })}
                              // value={field.value}
                            />
                          </div>
                          <div className="cp-expand__promise-confirm">
                            <TextBox
                              label={intl.formatMessage({
                                id: "promise_to_pay_confirmation",
                              })}
                              placeholder={intl.formatMessage({
                                id: "promise_to_pay_confirmation",
                              })}
                              // value={field.value}
                            />
                          </div>
                        </div>
                        <div className="cp-expand__confir-check">
                          <CheckBoxBaseweb
                            labelPlacement={LABEL_PLACEMENT.right}
                            checked={
                              mettingValue.content.is_send_confirmation_message
                            }
                          >
                            <FormattedMessage id="send_promise_follow_up_message">
                              send_promise_follow_up_message
                            </FormattedMessage>
                          </CheckBoxBaseweb>
                        </div>
                        <div className="cp-expand__confirm-input">
                          <div className="cp-expand__type-input">
                            <TextBox
                              label={intl.formatMessage({
                                id: "sms",
                              })}
                              placeholder={intl.formatMessage({
                                id: "sms",
                              })}
                              // value={field.value}
                            />
                          </div>
                          <div className="cp-expand__promise-confirm">
                            <TextBox
                              label={intl.formatMessage({
                                id: "promise_to_follow_up_message",
                              })}
                              placeholder={intl.formatMessage({
                                id: "promise_to_follow_up_message",
                              })}
                              // value={field.value}
                            />
                          </div>
                        </div>
                        {/* <div className="cp-expand__rec-btn">
                          <div>
                            <TextButton size={SIZE.compact}>
                              Recordings
                            </TextButton>
                          </div>
                          <LabelMedium $style={{ color: "#ADADAD" }}>
                          $ 26.00
                        </LabelMedium>
                        </div> */}
                      </div>
                    </>
                  ) : (
                    <div style={{ paddingTop: "" }}>
                      <LabelMedium
                        $style={{
                          color: "#ADADAD",
                        }}
                      >
                        User Comments
                      </LabelMedium>

                      <ParagraphMedium
                        $style={{
                          color: "#333860",
                        }}
                      >
                        {mettingSlicingValue}
                      </ParagraphMedium>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      filteringMettingRequestUsers &&
                      filteringMettingRequestUsers[0] &&
                      filteringMettingRequestUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringMettingRequestUsers &&
                        filteringMettingRequestUsers[0] &&
                        filteringMettingRequestUsers[0]?.label
                      }
                    />
                  </div>
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                Monday, 10 Jan, 2022
              </Label2>
            </div> */}
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedOnDateFormate}
              </Label2>
            </div>
          </div>
        </>
      );

    case HOLDACTION: {
      const holdAction = props;

      let reason = referenceData["hold_action_reason"];

      let users = referenceData["users"];
      let filteringUsers = users?.filter(
        (i) => i.id === holdAction.executed_by
      );
      let filteringValue = reason?.filter(
        (i) => i.id === holdAction.content.hold_reason
      );

      const holdslicingValue = holdAction?.comment?.slice(0, 200);

      const timezoneBasedDate = holdAction.content.hold_action_till
        ? moment
            .utc(holdAction.content.hold_action_till)
            ?.tz(timeZone)
            ?.format(currentDefaultFormatDetails.date_format)
        : "-";

      const HoldActionTill = intl.formatDate(timezoneBasedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div
              className="cp-status-view__icon"
              style={{ backgroundColor: "#F0D2D0" }}
            >
              <TemplateIcon type={HOLDACTION} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#FD372A",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {timezoneBasedDate}
                      </span>{" "}
                    </Label1>
                  </div>
                  {expand ? (
                    <>
                      {holdAction?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>
                              {holdAction.comment}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}

                      <div className="cp-expand__form-input">
                        <div style={{ display: "flex", gap: "40px" }}>
                          <div style={{ width: "160px" }}>
                            <TextBox
                              label={intl.formatMessage({
                                id: "hold_till_date",
                              })}
                              placeholder={intl.formatMessage({
                                id: "hold_till_date",
                              })}
                              value={timezoneBasedDate}
                            />
                          </div>
                          <div style={{ width: "160px" }}>
                            <TextBox
                              label={intl.formatMessage({
                                id: "hold_reason",
                              })}
                              placeholder={intl.formatMessage({
                                id: "hold_reason",
                              })}
                              value={
                                filteringValue &&
                                filteringValue[0] &&
                                filteringValue[0].label
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {holdAction?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>
                              {holdslicingValue}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile"> */}
                  {/* <ProfileIcon /> */}
                  <div
                    title={
                      filteringUsers &&
                      filteringUsers[0] &&
                      filteringUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringUsers &&
                        filteringUsers[0] &&
                        filteringUsers[0]?.label
                      }
                    />
                  </div>
                  {/* </div> */}
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                Monday, 10 Jan, 2022
              </Label2>
            </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringUsers &&
                    filteringUsers[0] &&
                    filteringUsers[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case SKIPPEDTIMELINE:
      let skippedTimelineDatas = props;
      let skippedTimelineUsers = referenceData["users"];
      let filteringskippedTimelineUsers = skippedTimelineUsers?.filter(
        (i) => i.id === skippedTimelineDatas.executed_by
      );
      const skippedValue = props.comment;

      const slicingValueSkipped = skippedValue?.slice(0, 200);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div
              className="cp-status-view__icon"
              style={{ backgroundColor: "#F0D2D0" }}
            >
              <TemplateIcon type={SKIPPEDTIMELINE} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1 $style={{ color: "#FD372A", marginRight: "12px" }}>
                      {props.name}{" "}
                      <span className="cp-discretion__text-content__weight">
                        on {executedOnDateFormate}
                      </span>{" "}
                    </Label1>
                  </div>

                  {expand ? (
                    <div className="cp-expand__form-input">
                      <Paragraph2>{slicingValueSkipped}</Paragraph2>
                    </div>
                  ) : (
                    <div style={{ paddingTop: "5px" }}>
                      <Paragraph2>{skippedValue}</Paragraph2>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}

                  <div
                    title={
                      filteringskippedTimelineUsers &&
                      filteringskippedTimelineUsers[0] &&
                      filteringskippedTimelineUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringskippedTimelineUsers &&
                        filteringskippedTimelineUsers[0] &&
                        filteringskippedTimelineUsers[0]?.label
                      }
                    />
                  </div>
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                Monday, 10 Jan, 2022
              </Label2>
            </div> */}
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedOnDateFormate}{" "}
                <FormattedTime value={props.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );

    case "SKIP_TRACE": {
      const relManager = props;
      let usersRd = referenceData["users"];

      let provider =
        props && props.content && props.content?.skip_trace_provider_id
          ? referenceData["skip_trace_provider"].find(
              (e) => e.id === props.content?.skip_trace_provider_id
            )
          : [];
      let filteringLetterUser =
        relManager && relManager.executed_by
          ? usersRd?.filter((i) => i.id === relManager.executed_by)
          : [];

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={"SKIP_TRACE"} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#333860",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "} Initiated on{" "}
                      {moment
                        .utc(props?.executed_on)
                        .format(currentDefaultFormatDetails.date_format)}
                      &nbsp;-&nbsp;
                      <FormattedMessage
                        id={
                          props &&
                          props?.content &&
                          props?.content?.skip_trace_status
                        }
                      ></FormattedMessage>
                    </Label1>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "110px" }}>
                        <LabelMedium
                          style={{ color: "#ADADAD", fontWeight: 400 }}
                        >
                          Provider :
                        </LabelMedium>
                      </div>
                      <LabelMedium
                        style={{ color: "#333860", fontWeight: 400 }}
                      >
                        {provider && provider && provider.label}
                      </LabelMedium>
                    </div>
                    <LabelMedium
                      style={{
                        color: "#333860",
                        fontWeight: 500,
                        padding: "5px 0px",
                      }}
                    >
                      Request Field Set
                    </LabelMedium>
                    {props &&
                      props?.content &&
                      props.content?.skip_trace_request_fields &&
                      props.content?.skip_trace_request_fields.map(
                        (ele, index) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {ele?.value ? (
                                <>
                                  <div style={{ width: "110px" }}>
                                    <LabelMedium
                                      style={{
                                        color: "#ADADAD",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {ele.label} :
                                    </LabelMedium>
                                  </div>
                                  <LabelMedium
                                    style={{
                                      color: "#333860",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {ele.value}
                                  </LabelMedium>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }
                      )}

                    <LabelMedium
                      style={{
                        color: "#333860",
                        fontWeight: 500,
                        padding: "5px 0px",
                      }}
                    >
                      Response Field Set
                    </LabelMedium>
                    {/* Phone Lead */}

                    {props.content &&
                    props.content?.skip_trace_response_fields &&
                    props.content?.skip_trace_response_fields?.phone_lead
                      .length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "110px" }}>
                          <LabelMedium
                            style={{ color: "#ADADAD", fontWeight: 400 }}
                          >
                            Phone Lead :
                          </LabelMedium>
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          {props.content.skip_trace_response_fields?.phone_lead.map(
                            (ele, index) => {
                              return (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      gap: 5,
                                    }}
                                  >
                                    <div
                                      style={{
                                        marginLeft: 5,
                                        width: 5,
                                        height: 5,
                                        borderRadius: 50,
                                        backgroundColor: "#333860",
                                      }}
                                    ></div>
                                    &nbsp;
                                    <LabelMedium
                                      style={{
                                        color: "#333860",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {ele.value}
                                    </LabelMedium>
                                    &nbsp;-&nbsp;
                                    <LabelMedium
                                      style={{
                                        color: `${
                                          ele.status == "NEW"
                                            ? "#333860"
                                            : "#FD372A"
                                        }`,
                                        fontWeight: 400,
                                      }}
                                    >
                                      <FormattedMessage id={ele.status}>
                                        {ele.status}
                                      </FormattedMessage>
                                    </LabelMedium>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Email Lead */}

                    {props.content &&
                    props.content?.skip_trace_response_fields &&
                    props.content?.skip_trace_response_fields?.email_lead
                      .length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "110px" }}>
                          <LabelMedium
                            style={{ color: "#ADADAD", fontWeight: 400 }}
                          >
                            Email Lead :
                          </LabelMedium>
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          {props.content.skip_trace_response_fields &&
                            props.content.skip_trace_response_fields
                              ?.email_lead &&
                            props.content.skip_trace_response_fields?.email_lead.map(
                              (ele, index) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 5,
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: 5,
                                          width: 5,
                                          height: 5,
                                          borderRadius: 50,
                                          backgroundColor: "#333860",
                                        }}
                                      ></div>
                                      &nbsp;
                                      <LabelMedium
                                        style={{
                                          color: "#333860",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {ele.value}
                                      </LabelMedium>
                                      &nbsp;-&nbsp;
                                      <LabelMedium
                                        style={{
                                          color: `${
                                            ele.status == "NEW"
                                              ? "#333860"
                                              : "#FD372A"
                                          }`,
                                          fontWeight: 400,
                                        }}
                                      >
                                        <FormattedMessage
                                          style={{
                                            color: "#FD372A",
                                          }}
                                          id={ele.status}
                                        ></FormattedMessage>
                                      </LabelMedium>
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* Address  Lead */}

                    {props.content &&
                    props.content?.skip_trace_response_fields &&
                    props.content?.skip_trace_response_fields?.address_lead
                      .length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ width: "110px" }}>
                          <LabelMedium
                            style={{ color: "#ADADAD", fontWeight: 400 }}
                          >
                            Address Lead :
                          </LabelMedium>
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          {props.content.skip_trace_response_fields &&
                            props.content.skip_trace_response_fields
                              ?.email_lead &&
                            props.content.skip_trace_response_fields?.address_lead.map(
                              (ele, index) => {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 5,
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginLeft: 5,
                                          width: 5,
                                          height: 5,
                                          borderRadius: 50,
                                          backgroundColor: "#333860",
                                        }}
                                      ></div>
                                      &nbsp;
                                      <LabelMedium
                                        style={{
                                          color: "#333860",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {ele.value}
                                      </LabelMedium>
                                      &nbsp;-&nbsp;
                                      <LabelMedium
                                        style={{
                                          color: `${
                                            ele.status == "NEW"
                                              ? "#333860"
                                              : "#FD372A"
                                          }`,
                                          fontWeight: 400,
                                        }}
                                      >
                                        <FormattedMessage
                                          style={{
                                            color: `${
                                              ele.status == "NEW"
                                                ? "#333860"
                                                : "#FD372A"
                                            }`,
                                          }}
                                          id={ele.status}
                                        ></FormattedMessage>
                                      </LabelMedium>
                                      {/* <LabelMedium
                                      style={{
                                        color: `${
                                          ele.status == "NEW"
                                            ? "#333860"
                                            : "#FD372A"
                                        }`,
                                        fontWeight: 400,
                                      }}
                                    >
                                      {ele.status}
                                    </LabelMedium> */}
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringLetterUser &&
                    filteringLetterUser[0] &&
                    filteringLetterUser[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }
    case RM: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={RM} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props && props.name}{" "}
                        {props && props.is_assigned ? "Assigned" : "Unassigned"}
                      </span>{" "}
                    </Label1>
                  </div>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringLetterUser &&
                    filteringLetterUser[0] &&
                    filteringLetterUser[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case BUSINESS_UNIT: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={BUSINESS_UNIT} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props && props.name}
                      </span>{" "}
                    </Label1>
                  </div>
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      <ParagraphMedium>
                        Business Location -{" "}
                        {props &&
                          props.content &&
                          props.content.business_unit_location}
                      </ParagraphMedium>
                    </div>
                  </>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringLetterUser &&
                    filteringLetterUser[0] &&
                    filteringLetterUser[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case WORKFLOW: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.workflow_start_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={WORKFLOW} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props && props.name}{" "}
                        {props && props.is_assigned ? "Assigned" : "Unassigned"}
                      </span>{" "}
                    </Label1>
                  </div>
                  {props?.content && (
                    <>
                      <div style={{ paddingTop: "5px" }}>
                        <ParagraphMedium>
                          Start Date - {timezoneBasedDate}
                        </ParagraphMedium>
                        <ParagraphMedium>
                          Start Stage - {props?.content?.workflow_start_stage}
                        </ParagraphMedium>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringLetterUser &&
                    filteringLetterUser[0] &&
                    filteringLetterUser[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case CUSTOMERT_STATUS: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={CUSTOMERT_STATUS} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props && props.name}{" "}
                      </span>{" "}
                    </Label1>
                  </div>

                  <>
                    <div style={{ paddingTop: "5px" }}>
                      <ParagraphMedium>
                        consumer Comments - {props?.comment}
                      </ParagraphMedium>
                    </div>
                  </>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringLetterUser &&
                    filteringLetterUser[0] &&
                    filteringLetterUser[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case NOTES: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.notes_follow_up)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={NOTES} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props && props.name} {props && props.action_taken}
                      </span>{" "}
                    </Label1>
                  </div>
                  <div>
                    <Paragraph2
                      $style={{ color: "#ADADAD", display: "flex", gap: "3px" }}
                    >
                      {props && props.comment}{" "}
                    </Paragraph2>
                  </div>

                  <div style={{ paddingTop: "5px" }}>
                    {props?.content?.notes_category && (
                      <Paragraph2
                        $style={{
                          color: "#ADADAD",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Category -{" "}
                        {props &&
                          props?.content &&
                          props.content.notes_category}{" "}
                      </Paragraph2>
                    )}
                    {props?.content?.notes_follow_up && (
                      <Paragraph2
                        $style={{
                          color: "#ADADAD",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Follow-up on - {timezoneBasedDate}{" "}
                      </Paragraph2>
                    )}
                    {props?.content?.notes_type && (
                      <Paragraph2
                        $style={{
                          color: "#ADADAD",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Type -{" "}
                        {props && props.content && props.content.notes_type}{" "}
                      </Paragraph2>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringLetterUser &&
                  filteringLetterUser[0] &&
                  filteringLetterUser[0]?.label
                    ? filteringLetterUser[0]?.label
                    : props.executed_by}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case CONTACTS: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.notes_follow_up)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={CONTACTS} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props && props.name} {props && props.action_taken}
                      </span>{" "}
                    </Label1>
                  </div>

                  <div style={{ paddingTop: "5px" }}>
                    {props?.content?.contact_phone &&
                      props?.content?.contact_phone.map((ph) => (
                        <Paragraph2
                          $style={{
                            color: "#ADADAD",
                            display: "flex",
                            gap: "3px",
                          }}
                        >
                          {ph.type} - {ph.value} {ph.status}
                        </Paragraph2>
                      ))}
                    {props?.content?.contact_email &&
                      props?.content?.contact_email.map((em) => (
                        <Paragraph2
                          $style={{
                            color: "#ADADAD",
                            display: "flex",
                            gap: "3px",
                          }}
                        >
                          {em.type} - {em.value} {em.status}
                        </Paragraph2>
                      ))}
                    {props?.content?.address &&
                      props?.content?.address.map((ad) => (
                        <Paragraph2
                          $style={{
                            color: "#ADADAD",
                            display: "flex",
                            gap: "3px",
                          }}
                        >
                          {ad}
                        </Paragraph2>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringLetterUser &&
                    filteringLetterUser[0] &&
                    filteringLetterUser[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case CUSTOMERPORTAL: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.notes_follow_up)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={CUSTOMERPORTAL} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props && props.name} {executedOnDateFormate}
                      </span>{" "}
                    </Label1>
                    {/* <PtpPartial /> */}
                  </div>
                  {/* <div>
                    <Paragraph2
                      $style={{ color: "#ADADAD", display: "flex", gap: "3px" }}
                    >
                      {props && props.comment}{" "}
                    </Paragraph2>
                  </div> */}
                  {props &&
                    props.content &&
                    props.content.portal_actions &&
                    props.content.portal_actions.length >= 1 && (
                      <Paragraph2
                        $style={{
                          color: "#ADADAD",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Actions: {props.content.portal_actions.join(", ")}
                      </Paragraph2>
                    )}

                  <div style={{ paddingTop: "5px" }}>
                    {props?.content?.portal_outstanding_page && (
                      <Paragraph2
                        $style={{
                          color: "#ADADAD",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Outstanding Page -{" "}
                        {props?.content?.portal_outstanding_page.map((tm) => (
                          <>
                            <FormattedTime value={tm} />,{" "}
                          </>
                        ))}{" "}
                      </Paragraph2>
                    )}
                    {props?.content?.portal_payment_page && (
                      <Paragraph2
                        $style={{
                          color: "#ADADAD",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        Payment Page -{" "}
                        {props?.content?.portal_payment_page.map((tm) => (
                          <>
                            <FormattedTime value={tm} />,{" "}
                          </>
                        ))}{" "}
                      </Paragraph2>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {props && props.executed_by}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case DISPUTE: {
      const disputeVal = props;

      let disputeUsers = referenceData["users"];
      let filteringDisputeUsers = disputeUsers?.filter(
        (i) => i.id === disputeVal.executed_by
      );

      const userName = disputeUsers?.find(
        ({ id }) => id === disputeVal.executed_by
      );

      const disputeRaisedOn = moment
        .utc(disputeVal.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const timezoneBasedDate = moment
        .utc(disputeVal.content.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const disputeReviewDate = intl.formatDate(timezoneBasedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      let refType = referenceData["dispute_type"];

      let disputeStatus = referenceData["dispute_status"];

      const findingStatus = disputeStatus?.find(
        (i) => i.id === disputeVal.content.dispute_status
      );

      const disputeComment = props?.comment;

      const disputeCommentValue = disputeVal.comment?.slice(0, 200);

      const findingValue = refType?.find(
        (i) => i.id === disputeVal.content.dispute_type
      );

      const disputeInvoices = disputeVal.content.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={DISPUTE} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    {/* <Label1 $style={{ color: "#516beb", marginRight: "12px" }}>
                      Dispute
                    </Label1> */}

                    <Label1
                      $style={{ color: "#516beb", marginRight: "12px" }}
                      style={{ display: "flex" }}
                    >
                      Dispute{disputeVal.is_adhoc_action ? " (QA)" : ""}
                      {" - "}{" "}
                      <span className="cp-discretion__text-content__weight">
                        {disputeVal.content.dispute_status === "CLOSED" ? (
                          <div
                            className="cp-discretion__text-content__weight"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            {findingValue && findingValue.label} - Status Closed
                            <PtpKept />
                          </div>
                        ) : disputeVal.content.dispute_status === "OPEN" ? (
                          <>
                            {/* {findingValue && findingValue.label} - Review on{" "}
                            {
                              <FormattedDate
                                value={disputeVal.content.dispute_review_date}
                              />
                            }{" "} */}
                            <div
                              className="cp-discretion__text-content__weight"
                              style={{ display: "flex", gap: "5px" }}
                            >
                              {findingValue && findingValue.label} - Status Open
                              <Alert />
                            </div>
                          </>
                        ) : (
                          <div
                            className="cp-discretion__text-content__weight"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            {findingValue && findingValue.label} - In Progress{" "}
                            {/* {findingValue && findingValue.label} - Review on{" "}
                            {
                              <FormattedDate
                                value={disputeVal.content.dispute_review_date}
                              />
                            }{" "} */}
                            <PtpPartial />
                          </div>
                        )}
                      </span>
                    </Label1>

                    {/* <Label2 $style={{ color: "#C4C4C4" }}>
                      <FormattedDate
                        value={props.executed_on}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                      />
                    </Label2> */}
                  </div>
                  {/* <Paragraph2>{props.comment}</Paragraph2> */}
                  {expand ? (
                    <>
                      {disputeComment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>{disputeComment}</ParagraphMedium>
                          </div>
                        </>
                      )}

                      {/* <Paragraph2>{holdAction.comment}</Paragraph2> */}

                      <div
                        className="cp-expand__form-input"
                        style={{ paddingTop: "5px" }}
                      >
                        {/* <TextBox
                          label={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          placeholder={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          value={disputeVal.content.dispute_details}
                        /> */}
                        <TextArea
                          label={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          placeholder={intl.formatMessage({
                            id: "dispute_detailed_text",
                          })}
                          value={disputeVal.content.dispute_details}
                        />

                        <div className="cp-expand__type">
                          <TextBox
                            label={intl.formatMessage({
                              id: "dispute_category",
                            })}
                            placeholder={intl.formatMessage({
                              id: "dispute_category",
                            })}
                            value={
                              findingValue && findingValue.label
                                ? findingValue.label
                                : ""
                            }
                          />
                          <TextBox
                            label={intl.formatMessage({
                              id: "dispute_review_date",
                            })}
                            placeholder={intl.formatMessage({
                              id: "dispute_review_date",
                            })}
                            value={timezoneBasedDate}
                          />
                          <TextBox
                            label={intl.formatMessage({
                              id: "dispute_status",
                            })}
                            placeholder={intl.formatMessage({
                              id: "dispute_status",
                            })}
                            value={findingStatus.label}
                          />
                        </div>

                        <>
                          <TextBox
                            label={intl.formatMessage({
                              id: "summary_view_of_invoice_download",
                            })}
                            placeholder={intl.formatMessage({
                              id: "summary_view_of_invoice_download",
                            })}
                            value={disputeInvoices}
                          />
                        </>

                        <CheckBoxBaseweb
                          labelPlacement={LABEL_PLACEMENT.right}
                          checked={disputeVal.content.is_hold_workflow}
                        >
                          <FormattedMessage id="hold_workflow_till_review_date">
                            hold_workflow_till_review_date
                          </FormattedMessage>
                        </CheckBoxBaseweb>
                        {/* <div className="cp-expand__rec-btn"> */}
                        {/* <div>
                            <TextButton size={SIZE.compact}>
                              Recordings
                            </TextButton>
                          </div> */}
                        {/* <LabelMedium $style={{ color: "#ADADAD" }}>
                          $ 26.00
                        </LabelMedium> */}
                        {/* </div> */}
                      </div>
                    </>
                  ) : (
                    <>
                      {disputeComment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>
                              {disputeCommentValue}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}

                      {/* <Paragraph2>{holdAction.comment}</Paragraph2> */}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      filteringDisputeUsers &&
                      filteringDisputeUsers[0] &&
                      filteringDisputeUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringDisputeUsers &&
                        filteringDisputeUsers[0] &&
                        filteringDisputeUsers[0]?.label
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", textAlign: "end" }}>
                {expand ? (
                  <>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => {
                        dispatch(drawerOpen());
                        dispatch(
                          promiseTopayDataToDrawer({
                            drawerType: "DISPUTE_PROMISE",
                            emailType: FORWARD,
                            // emailData: disputeVal,
                            // isReceiver: is_receiver,
                            // promiseDate: timezoneBasedDate,
                            // customerName: customerDetails.display_name,
                            // invNumber: disputeInvoices,
                            // dispudeStatus: findingStatus.label,
                            // rmanager: orgRef.label,
                            promiseSubject: `${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            } : Dispute : ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            } Raised on ${disputeRaisedOn}`,

                            promiseBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            }<br />Dispute Type: ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            }<br />Dispute Details: ${
                              disputeVal.content.dispute_details
                                ? disputeVal.content.dispute_details
                                : "-"
                            }<br />Disputed Invoice Numbers: ${
                              disputeInvoices ? disputeInvoices : "-"
                            }<br /><br />Dispute Status : ${
                              findingStatus.label ? findingStatus.label : "-"
                            }<br />Dispute Review Date : ${
                              timezoneBasedDate ? timezoneBasedDate : "-"
                            }<br />User Comments :  ${
                              disputeVal.comment ? disputeVal.comment : "-"
                            }<br /><br />Regards<br />${
                              userInfo && userInfo.display_name
                                ? userInfo.display_name
                                : "-"
                            }`,
                          })
                        );
                      }}
                    >
                      <Forward />
                    </IconButton>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => expanToggle()}
                    >
                      <CollapseUpArrow />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => {
                        dispatch(drawerOpen());
                        dispatch(
                          promiseTopayDataToDrawer({
                            drawerType: "DISPUTE_PROMISE",
                            emailType: FORWARD,
                            // emailData: disputeVal,
                            // isReceiver: is_receiver,
                            // promiseDate: timezoneBasedDate,
                            // customerName: customerDetails.display_name,
                            // invNumber: disputeInvoices,
                            // dispudeStatus: findingStatus.label,
                            // rmanager: orgRef.label,
                            promiseSubject: `${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            } : Dispute : ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            } Raised on ${disputeRaisedOn}`,

                            promiseBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
                              customerDetails.display_name
                                ? customerDetails.display_name
                                : "-"
                            }<br />Dispute Type: ${
                              findingValue && findingValue.label
                                ? findingValue.label
                                : "-"
                            }<br />Dispute Details: ${
                              disputeVal.content.dispute_details
                                ? disputeVal.content.dispute_details
                                : "-"
                            }<br />Disputed Invoice Numbers: ${
                              disputeInvoices ? disputeInvoices : "-"
                            }<br /><br />Dispute Status : ${
                              findingStatus.label ? findingStatus.label : "-"
                            }<br />Dispute Review Date : ${
                              timezoneBasedDate ? timezoneBasedDate : "-"
                            }<br />User Comments :  ${
                              disputeVal.comment ? disputeVal.comment : "-"
                            }<br /><br />Regards<br />${
                              userInfo && userInfo.display_name
                                ? userInfo.display_name
                                : "-"
                            }`,
                          })
                        );
                      }}
                    >
                      <Forward />
                    </IconButton>
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => expanToggle()}
                    >
                      <CollapseDownArrow />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            {/* <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                Monday, 10 Jan, 2022
              </Label2>
            </div> */}
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedOnDateFormate}{" "}
                <FormattedTime value={props.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    case NEXTACTION: {
      const timezoneBasedDate = moment
        .utc(props.content.planned_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const nextActionDate = intl.formatDate(timezoneBasedDate, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      const executedUser = referenceData["users"]?.filter(
        (i) => i.id === props.executed_by
      );
      const nextActionComment = props?.comment;
      const nextActionCommentSliced = props.comment?.slice(0, 200);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={NEXTACTION} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                      }}
                    >
                      {type ? <TypeName /> : ""}
                      {`: ${_.startCase(props?.content?.next_action_type)}

                      `}{" "}
                      <span className="cp-discretion__text-content__weight">
                        <span
                          className="cp-discretion__text-content__weight"
                          style={{ padding: "0px 5px" }}
                        >
                          on
                        </span>{" "}
                        {timezoneBasedDate}
                      </span>
                    </Label1>
                  </div>

                  {expand ? (
                    <>
                      {" "}
                      {props?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>
                              {nextActionComment}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}
                      <div className="cp-expand__form-input">
                        <TextBox
                          label={intl.formatMessage({
                            id: "next_action_name",
                          })}
                          placeholder={intl.formatMessage({
                            id: "next_action_name",
                          })}
                          value={_.startCase(props.content?.next_action_type)}
                        />

                        <TextBox
                          label={intl.formatMessage({
                            id: "next_action_date",
                          })}
                          placeholder={intl.formatMessage({
                            id: "dispute_detailed_date",
                          })}
                          value={timezoneBasedDate}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {props?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>
                              {nextActionCommentSliced}
                            </ParagraphMedium>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    title={
                      executedUser && executedUser[0] && executedUser[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        executedUser &&
                        executedUser[0] &&
                        executedUser[0]?.label
                      }
                    />
                  </div>
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedOnDateFormate}{" "}
                <FormattedTime value={props.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    case REVIEWPROMISE:
      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={REVIEWPROMISE} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1 $style={{ color: "#516beb", marginRight: "12px" }}>
                      Review Promise to Pay
                    </Label1>
                  </div>
                  <Paragraph2>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis hendrerit dolor magna eget est lorem ipsum.
                    Amet dictum sit amet just donec enim diam. Ut morbi
                    tincidunt aug
                  </Paragraph2>
                  {expand ? (
                    <div className="cp-expand__form-input">
                      <div className="cp-expand__type">
                        <TextBox
                          label={intl.formatMessage({
                            id: "promise_amount",
                          })}
                          placeholder={intl.formatMessage({
                            id: "promise_amount",
                          })}
                          // value={field.value}
                        />
                        <TextBox
                          label={intl.formatMessage({
                            id: "promise_date",
                          })}
                          placeholder={intl.formatMessage({
                            id: "promise_date",
                          })}
                          // value={field.value}
                        />
                        <TextBox
                          label={intl.formatMessage({
                            id: "promise_type",
                          })}
                          placeholder={intl.formatMessage({
                            id: "promise_type",
                          })}
                          // value={field.value}
                        />
                      </div>
                      <div className="cp-expand__type --width-modified">
                        <TextBox
                          label={intl.formatMessage({
                            id: "captured_by",
                          })}
                          placeholder={intl.formatMessage({
                            id: "captured_by",
                          })}
                          // value={field.value}
                        />
                        <TextBox
                          label={intl.formatMessage({
                            id: "captured_date",
                          })}
                          placeholder={intl.formatMessage({
                            id: "captured_date",
                          })}
                          // value={field.value}
                        />
                      </div>
                      <CheckBoxBaseweb labelPlacement={LABEL_PLACEMENT.right}>
                        <FormattedMessage id="hold_workflow_till_promise_date">
                          hold_workflow_till_promise_date
                        </FormattedMessage>
                      </CheckBoxBaseweb>
                      <div className="cp-expand__rec-btn">
                        {/* <div>
                        <TextButton size={SIZE.compact}>Recordings</TextButton>
                      </div> */}
                        {/* <LabelMedium $style={{ color: "#ADADAD" }}>
                          $ 26.00
                        </LabelMedium> */}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  <div
                    // title={filteringUsers[0].label}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                    // name={filteringUsers[0].label}
                    />
                  </div>
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedOnDateFormate}
              </Label2>
            </div>
          </div>
        </>
      );

    case "payment": {
      let callOutcome = props.content?.call_outcome;
      return <CallOutcome data={props} callOutcome={callOutcome} />;
    }

    case CALL:
      let call = props;
      let callOutcome = props.content?.call_outcome;
      return <CallOutcome data={props} callOutcome={callOutcome} />;

    case "FIELD_VISIT": {
      let fieldType =
        props.content?.visit_status === "UNSUCCESSFULL"
          ? "UNSUCCESSFULL"
          : props.content?.visit_outcome;
      return <FieldVisit data={props} type={fieldType} />;
    }

    case "CANCELED_FIELD_VISIT":
      let unsuccessFL = props;

      let unsuccessFullContent = unsuccessFL.content;
      let usersUn = referenceData["users"];

      let filteringUnsuccCallUsers = usersUn?.find(
        (i) => i.id === unsuccessFL?.executed_by
      );

      let rdTime = referenceData && referenceData["time"];

      let visitedunTime = rdTime?.find(
        (e) => e?.id === unsuccessFullContent?.visit_time
      );

      let followUpTime = rdTime?.find(
        (e) => e?.id === unsuccessFullContent?.follow_up_time
      );

      let visitedunDate = moment
        .utc(unsuccessFullContent?.follow_up_date)
        .tz(timeZone)
        .format(currentDefaultFormatDetails.date_format);

      const executedUnsuccOnDateFormate = moment
        .utc(unsuccessFL?.executed_on)
        .format(currentDefaultFormatDetails?.date_format);

      let rdReason = referenceData["visit_unsuccess_reason_list"];

      return (
        <>
          <div className={"cp-timeline-contant__status-view"}>
            <div className="cp-status-view__icon_field_visit">
              {/* <TemplateIcon type={EMAIL} /> */}
              <TemplateIcon type={"FIELD_VISIT"} color="#FD372A" />

              {/* UNSUCCESSFUL_FL */}
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // ...(is_receiver ? { position: "relative" } : {}),
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {/* {type ? <TypeName /> : ""} */}
                      <span
                        className="cp-discretion__text-content__weight"
                        style={{ display: "flex", gap: "5px" }}
                      >
                        {/* {name ? name : ""} */}
                        {/* Field Visit */}
                        <LabelLarge
                          $style={{ color: "#516BEB", paddingRight: "2px" }}
                        >
                          Field Visit Cancelled
                        </LabelLarge>
                      </span>{" "}
                    </Label1>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD" }}>
                      Location:
                    </Paragraph2>
                    <div>
                      {unsuccessFullContent &&
                      unsuccessFullContent?.flat_address !== "" ? (
                        <span style={{ color: "#787878" }}>
                          {unsuccessFullContent?.flat_address}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "baseline",
                    }}
                  >
                    <Paragraph2 $style={{ color: "#ADADAD" }}>Date:</Paragraph2>
                    <span style={{ color: "#787878" }}>
                      {props && props?.content && props?.content?.visit_date
                        ? moment(props?.content?.visit_date)
                            .tz(currentDefaultFormatDetails.time_zone)
                            .format(currentDefaultFormatDetails.date_format)
                        : "-"}
                    </span>
                  </div>

                  {/* <Paragraph2 $style={{ color: "#ADADAD" }}>
                    Time:&nbsp;
                    <span
                      style={{
                        color: "#787878",
                      }}
                    >
                      {moment.utc().format("DD MM YYYY")}
                    </span>
                  </Paragraph2> */}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
        <Label2 $style={{ color: "#C4C4C4" }}>Monday, 10 Jan, 2022</Label2>
      </div> */}
            <div
              className="cp-status-view__day"
              style={{
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}

                {filteringUnsuccCallUsers?.label}
              </Label2>
              <Label2 $style={{ color: "#C4C4C4" }}>
                {/* {executedOnDateFormate}{" "} */}
                {executedUnsuccOnDateFormate}{" "}
                <FormattedTime value={new Date(unsuccessFL.executed_on)} />
              </Label2>
            </div>
          </div>
        </>
      );

    case RELEASED:
      let releaseDatas = props;
      let releaseDatasusers = referenceData["users"];
      let filteringReleasedDatasUsers = releaseDatasusers?.filter(
        (i) => i.id === releaseDatas.executed_by
      );
      const skippedValueRelease = props.comment;
      //
      const slicingValueSkippedRelease = skippedValueRelease?.slice(0, 200);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={RELEASED} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1 $style={{ color: "#516BEB", marginRight: "12px" }}>
                      Release Action
                      {/* {props.name}{" "} */}
                      {/* <span className="cp-discretion__text-content__weight">
                        The Action was release on{" "}
                        <FormattedDate
                          value={props.executed_on}
                          year="numeric"
                          month="2-digit"
                          day="2-digit"
                        />
                      </span>{" "} */}
                    </Label1>
                  </div>

                  {expand ? (
                    <>
                      {releaseDatas?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <div style={{ paddingTop: "5px" }}>
                          <LabelMedium
                            $style={{
                              color: "#ADADAD",
                            }}
                          >
                            User Comments
                          </LabelMedium>
                          <div>
                            <Paragraph2>{skippedValueRelease}</Paragraph2>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={{ paddingTop: "5px" }}>
                      {releaseDatas?.comment?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <LabelMedium
                            $style={{
                              color: "#ADADAD",
                            }}
                          >
                            User Comments
                          </LabelMedium>
                          <div>
                            <Paragraph2>
                              {slicingValueSkippedRelease}
                            </Paragraph2>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="cp-discretion__profile">
                    <ProfileIcon />
                  </div> */}
                  {/* <div
                    title={
                      filteringReleasedDatasUsers &&
                      filteringReleasedDatasUsers[0] &&
                      filteringReleasedDatasUsers[0]?.label
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      name={
                        filteringReleasedDatasUsers &&
                        filteringReleasedDatasUsers[0] &&
                        filteringReleasedDatasUsers[0]?.label
                      }
                    />
                  </div> */}
                  <div onClick={() => expanToggle()}>
                    {expand ? (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseUpArrow />
                      </IconButton>
                    ) : (
                      <IconButton kind={KIND.tertiary}>
                        <CollapseDownArrow />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringReleasedDatasUsers &&
                    filteringReleasedDatasUsers[0] &&
                    filteringReleasedDatasUsers[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );

    case PORTAL_PAYMENT: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.notes_follow_up)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={PORTAL_PAYMENT} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props &&
                          props?.content?.portal_payment_amount?.currency}{" "}
                        {props && props?.content?.portal_payment_amount?.value}{" "}
                        -{" "}
                      </span>{" "}
                      <span
                        className="cp-discretion__text-content__weight"
                        style={{
                          color:
                            props && props.action_taken == "Success"
                              ? "#248f3c"
                              : "red",
                        }}
                      >
                        {props && props.action_taken}
                      </span>
                    </Label1>
                  </div>
                  {/* <div>
                      <Paragraph2
                        $style={{ color: "#ADADAD", display: "flex", gap: "3px" }}
                      >
                        {props && props.comment}{" "}
                      </Paragraph2>
                    </div> */}

                  <div style={{ paddingTop: "5px" }}>
                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Payment Date:{" "}
                      {moment
                        .utc(props?.content?.portal_payment_date)
                        .tz(timeZone)
                        .format(currentDefaultFormatDetails.date_format)}{" "}
                    </Paragraph2>

                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Payment method:{" "}
                      {props && props?.content?.portal_payment_method}{" "}
                    </Paragraph2>

                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Payment Reference -{" "}
                      {props?.content?.portal_payment_reference}{" "}
                    </Paragraph2>

                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Invoice# -{" "}
                      {props?.content?.portal_payment_invoice_number.toString()}
                    </Paragraph2>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                  <Label2 $style={{ color: "#C4C4C4" }}>
                    Monday, 10 Jan, 2022
                  </Label2>
                </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>Customer</Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case PAYMENT: {
      const disputeValueDate = moment
        ?.utc(props.executed_on)
        ?.format(currentDefaultFormatDetails.date_format);
      let disputeUsers = referenceData["users"];
      let filteringDisputeUsers = disputeUsers?.filter(
        (i) => i.id === props.executed_by
      );

      const linkedTransactionDate = moment
        ?.utc(props?.content?.linked_transaction_date)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <PortalPayment className="svg-primary-fill" />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{ color: "#516beb", marginRight: "10px" }}
                      style={{ display: "flex" }}
                    >
                      Payment: &nbsp;
                      <span className="-paymentCall">
                        {props.content.portal_payment_amount?.currency}
                        <NumericFormat
                          // type="text"
                          displayType="text"
                          value={
                            props.content.portal_payment_amount?.value
                              ? Number(
                                  props.content.portal_payment_amount?.value
                                )
                              : 0
                          }
                          thousandsGroupStyle={currencyGroupStyle}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={currencySeparator}
                          decimalSeparator={currencyDecimalSeparator}
                          renderText={(value) => <> {value}</>}
                        />
                      </span>
                      &nbsp;
                      <span
                        className={` ${
                          props.action_taken == "Success"
                            ? "Successed"
                            : "Normalize"
                        }`}
                      >
                        {props.action_taken}
                      </span>
                      {/* {data?.} */}
                    </Label1>
                  </div>
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      <div className="call_payment">
                        <ParagraphMedium>
                          <NumericFormat
                            // type="text"
                            displayType="text"
                            value={
                              props.content.portal_payment_amount?.value
                                ? Number(
                                    props.content.portal_payment_amount?.value
                                  )
                                : 0
                            }
                            thousandsGroupStyle={currencyGroupStyle}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={currencySeparator}
                            decimalSeparator={currencyDecimalSeparator}
                            renderText={(value) => <> {value}</>}
                          />
                          {/* {props.content.payment_plan_amount?.value} */}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Payment Date:</LabelMedium>
                        <ParagraphMedium>
                          {moment(props.executed_on).format("DD-MM-YYYY")}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Payment Method:</LabelMedium>
                        <ParagraphMedium>
                          {props &&
                          props.content &&
                          props.content.portal_payment_gateway_name
                            ? props.content.portal_payment_gateway_name
                            : "-"}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Payment Reference:</LabelMedium>
                        <ParagraphMedium>
                          {props &&
                          props.content &&
                          props.content.portal_payment_reference
                            ? props.content.portal_payment_reference
                            : "-"}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Invoice #:</LabelMedium>
                        <ParagraphMedium>
                          {props?.content?.portal_payment_invoice_number &&
                          Array.isArray(
                            props?.content?.portal_payment_invoice_number
                          )
                            ? props?.content?.portal_payment_invoice_number.toString()
                            : ""}
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <LabelMedium>Linked Transaction:</LabelMedium>
                        <ParagraphMedium>
                          {props?.content?.linked_transaction
                            ? props?.content?.linked_transaction
                            : ""}
                          {" ("}
                          {props?.content?.linked_transaction_date
                            ? linkedTransactionDate
                            : ""}
                          {")"}
                        </ParagraphMedium>
                      </div>
                      {props?.comment == "" ? (
                        <>
                          {" "}
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>{props?.comment}</ParagraphMedium>
                          </div>
                        </>
                      ) : (
                        <>
                          <div style={{ paddingTop: "5px" }}>
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              User Comments
                            </LabelMedium>
                            <ParagraphMedium>{props?.comment}</ParagraphMedium>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {" "}
                  {filteringDisputeUsers &&
                  filteringDisputeUsers[0] &&
                  filteringDisputeUsers[0]?.label
                    ? filteringDisputeUsers[0]?.label
                    : ""}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {disputeValueDate} <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }

    case APP_PAYMENT: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.notes_follow_up)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={PORTAL_PAYMENT} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}:{" "}
                      <span className="cp-discretion__text-content__weight">
                        {props &&
                          props?.content?.portal_payment_amount?.currency}{" "}
                        {props && props?.content?.portal_payment_amount?.value}{" "}
                        -{" "}
                      </span>{" "}
                      <span
                        className="cp-discretion__text-content__weight"
                        style={{
                          color:
                            props && props.action_taken == "Success"
                              ? "#248f3c"
                              : "red",
                        }}
                      >
                        {props && props.action_taken}
                      </span>
                    </Label1>
                  </div>
                  {/* <div>
                      <Paragraph2
                        $style={{ color: "#ADADAD", display: "flex", gap: "3px" }}
                      >
                        {props && props.comment}{" "}
                      </Paragraph2>
                    </div> */}

                  <div style={{ paddingTop: "5px" }}>
                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Payment Date:{" "}
                      {moment
                        .utc(props?.content?.portal_payment_date)
                        .tz(timeZone)
                        .format(currentDefaultFormatDetails.date_format)}{" "}
                    </Paragraph2>

                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Payment method:{" "}
                      {props && props?.content?.portal_payment_method}{" "}
                    </Paragraph2>

                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Payment Reference -{" "}
                      {props?.content?.portal_payment_reference}{" "}
                    </Paragraph2>

                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Invoice# -{" "}
                      {props?.content?.portal_payment_invoice_number.toString()}
                    </Paragraph2>
                    <Paragraph2
                      $style={{
                        color: "#ADADAD",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      Comments - {props?.comment}
                    </Paragraph2>
                  </div>
                </div>
              </div>
            </div>

            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {" "}
                  {filteringLetterUser &&
                  filteringLetterUser[0] &&
                  filteringLetterUser[0]?.label
                    ? filteringLetterUser[0]?.label
                    : ""}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }
    case CALL_RECORDING: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.notes_follow_up)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={CALL_RECORDING} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}{" "}
                      <span style={{ color: "#333860", fontSize: "16px" }}>
                        with{" "}
                        {props?.content?.to?.[0]?.name
                          ? props?.content?.to?.[0]?.name
                          : ""}{" "}
                        {`(${
                          props?.content?.to?.[0]?.value
                            ? props?.content?.to?.[0]?.value
                            : ""
                        })`}
                      </span>
                    </Label1>
                  </div>
                  {props?.content?.call_detail?.from_number ? (
                    <div style={{ paddingTop: "5px" }}>
                      <Paragraph2
                        $style={{
                          color: "#ADADAD",
                          display: "flex",
                          gap: "3px",
                        }}
                      >
                        From Number: {props?.content?.call_detail?.from_number}{" "}
                      </Paragraph2>
                    </div>
                  ) : (
                    <></>
                  )}
                  {props?.content?.call_detail?.recording_url ? (
                    <div style={{ paddingTop: "5px" }}>
                      <audio controls controlsList="nodownload noplaybackrate">
                        <source
                          src={props?.content?.call_detail?.recording_url}
                        />
                      </audio>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                  <Label2 $style={{ color: "#C4C4C4" }}>
                    Monday, 10 Jan, 2022
                  </Label2>
                </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {" "}
                  {filteringLetterUser &&
                  filteringLetterUser[0] &&
                  filteringLetterUser[0]?.label
                    ? filteringLetterUser[0]?.label
                    : ""}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }
    case PORTAL_PAYMENT_PLAN:
      // let disputeUsers = referenceData["users"];

      // const Status = (data) => {
      //
      //   // useEffect(()=>{

      //   switch (data?.data) {
      //     case "IN_PROGRESS":
      //       return (
      //         <span
      //           className="cp-discretion__text-content__weight"
      //           style={{ color: "Blue" }}
      //         >
      //           In Progress
      //         </span>
      //       );
      //     case "CANCELED":
      //       return (
      //         <span
      //           className="cp-discretion__text-content__weight"
      //           style={{ color: "red " }}
      //         >
      //           CANCELED
      //         </span>
      //       );
      //     case "KEPT":
      //       return (
      //         <span
      //           className="cp-discretion__text-content__weight"
      //           style={{ color: "green" }}
      //         >
      //           {data.data}
      //         </span>
      //       );
      //     case "BROKEN":
      //       return (
      //         <span
      //           className="cp-discretion__text-content__weight"
      //           style={{ color: "red " }}
      //         >
      //           BROKEN
      //         </span>
      //       );

      //     case "PARTIAL":
      //       return (
      //         <span
      //           className="cp-discretion__text-content__weight"
      //           style={{ color: "#FBBD04" }}
      //         >
      //           {data.data}
      //         </span>
      //       );
      //     default:
      //       return null;
      //   }
      //   // },[data])
      // };
      return (
        <>
          <div
            className={
              "cp-timeline-contant__status-view" +
              (props.is_receiver ? "__ft-r" : "")
            }
          >
            <div className="cp-status-view__icon">
              <PromiseToPay className="svg-primary-fill" />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {/* <span className="cp-discretion__text-content__weight">
                       */}
                      Portal -
                      <LabelLarge style={{ color: "#333860" }}>
                        Consumer Submitted Payment Plan -{" "}
                        <FormattedMessage id={props.content.promise_status}>
                          {props.content.promise_status}
                        </FormattedMessage>
                        &nbsp;
                      </LabelLarge>
                    </Label1>

                    {props.content.promise_status === "IN_PROGRESS" ? (
                      <div className="cp-discretion__icon-alert svg path">
                        <Alert />
                      </div>
                    ) : props.content.promise_status === "PARTIAL" ? (
                      <div>
                        <PtpPartial />
                      </div>
                    ) : props.content.promise_status === "BROKEN" ? (
                      <div>
                        <Alert />
                      </div>
                    ) : props.content.promise_status === "CANCELED" ? (
                      <div className="max_alert_svg">
                        <Alert />
                      </div>
                    ) : (
                      <div>
                        <PtpKept />
                      </div>
                    )}
                  </div>
                  <>
                    <div style={{ paddingTop: "5px" }}>
                      {
                        <>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <LabelMedium
                              $style={{
                                color: "#ADADAD",
                              }}
                            >
                              <FormattedMessage id="User_Comments">
                                Comments
                              </FormattedMessage>
                            </LabelMedium>
                            <ParagraphMedium>
                              {props.comment ? props.comment : "--"}
                            </ParagraphMedium>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <LabelMedium>Total Payment Amount :</LabelMedium>
                            <ParagraphMedium>
                              {props.content.payment_plan_amount?.currency}
                            </ParagraphMedium>
                            <ParagraphMedium>
                              <NumericFormat
                                // type="text"
                                displayType="text"
                                value={
                                  props.content.payment_plan_amount?.value
                                    ? Number(
                                        props.content.payment_plan_amount?.value
                                      )
                                    : 0
                                }
                                thousandsGroupStyle={currencyGroupStyle}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={currencySeparator}
                                decimalSeparator={currencyDecimalSeparator}
                                renderText={(value) => <> {value}</>}
                              />
                            </ParagraphMedium>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <LabelMedium>
                              Installment Payment Amount:
                            </LabelMedium>
                            <ParagraphMedium>
                              {props &&
                                props.content &&
                                props.content.payment_plan_instalation_amount &&
                                props.content.payment_plan_instalation_amount
                                  ?.currency}
                            </ParagraphMedium>
                            <ParagraphMedium>
                              <NumericFormat
                                displayType="text"
                                value={
                                  props.content.payment_plan_instalation_amount
                                    ?.value
                                    ? Number(
                                        props &&
                                          props.content &&
                                          props.content
                                            .payment_plan_instalation_amount &&
                                          props.content
                                            .payment_plan_instalation_amount
                                            ?.value
                                      )
                                    : 0
                                }
                                thousandsGroupStyle={currencyGroupStyle}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={currencySeparator}
                                decimalSeparator={currencyDecimalSeparator}
                                renderText={(value) => <> {value}</>}
                              />
                            </ParagraphMedium>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <LabelMedium>Planned Start Date:</LabelMedium>
                            <ParagraphMedium>
                              {props &&
                              props.content &&
                              props.content.payment_plan_start_date
                                ? moment(
                                    props &&
                                      props.content &&
                                      props.content.payment_plan_start_date
                                  ).format(
                                    currentDefaultFormatDetails?.date_format
                                  )
                                : "--"}
                            </ParagraphMedium>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <LabelMedium>Planned Frequency:</LabelMedium>
                            <ParagraphMedium>
                              {props &&
                                props.content &&
                                props.content.payment_plan_frequency}
                            </ParagraphMedium>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <LabelMedium>Payment Method:</LabelMedium>
                            <ParagraphMedium>
                              {props &&
                                props.content &&
                                props.content.payment_plan_method}
                            </ParagraphMedium>
                          </div>
                          <ParagraphMedium>
                            {props.content.payment_plan_frequency} Payment Plan
                            with Installment Amount{" "}
                            {
                              props.content.payment_plan_instalation_amount
                                ?.currency
                            }
                            <NumericFormat
                              displayType="text"
                              value={
                                props.content.payment_plan_instalation_amount
                                  ?.value
                                  ? Number(
                                      props.content
                                        .payment_plan_instalation_amount?.value
                                    )
                                  : 0
                              }
                              thousandsGroupStyle={currencyGroupStyle}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={currencySeparator}
                              decimalSeparator={currencyDecimalSeparator}
                              renderText={(value) => <> {value}</>}
                            />{" "}
                            Starting From{" "}
                            {props &&
                            props.content &&
                            props.content.payment_plan_start_date
                              ? moment(
                                  props &&
                                    props.content &&
                                    props.content.payment_plan_start_date
                                ).format(
                                  currentDefaultFormatDetails?.date_format
                                )
                              : ""}{" "}
                            till{" "}
                            {props &&
                            props.content &&
                            props.content.payment_plan_end_date
                              ? moment(
                                  props &&
                                    props.content &&
                                    props.content.payment_plan_end_date
                                ).format(
                                  currentDefaultFormatDetails?.date_format
                                )
                              : ""}{" "}
                            <span
                              onClick={handlerDrawer}
                              style={{
                                color: "#516BEB",
                                borderBottom: "1px solid #516beb",
                                cursor: "pointer",
                              }}
                            >
                              View payment Plans
                            </span>
                          </ParagraphMedium>
                          <div
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <CheckBoxBaseweb
                              labelPlacement={LABEL_PLACEMENT.right}
                              checked={
                                props &&
                                props.content &&
                                props.content?.is_hold_workflow
                              }
                            >
                              <FormattedMessage id="hold_workflow_till_promise_date">
                                hold_workflow_till_promise_date
                              </FormattedMessage>
                            </CheckBoxBaseweb>
                          </div>
                          <div
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                          >
                            <CheckBoxBaseweb
                              labelPlacement={LABEL_PLACEMENT.right}
                              checked={
                                props &&
                                props.content &&
                                props.content?.is_send_confirmation_message
                              }
                            >
                              <FormattedMessage id="send_confirmation_message">
                                send_confirmation_message
                              </FormattedMessage>
                            </CheckBoxBaseweb>
                          </div>
                          <div>
                            <CheckBoxBaseweb
                              labelPlacement={LABEL_PLACEMENT.right}
                              checked={
                                props &&
                                props.content &&
                                props.content?.is_send_action_follow_up
                              }
                            >
                              <FormattedMessage id="send_action_follow">
                                send_confirmation_message
                              </FormattedMessage>
                            </CheckBoxBaseweb>
                          </div>
                        </>
                      }
                    </div>
                  </>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    title={props.is_receiver ? "Consumer via Portal" : ""}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar name={props.is_receiver ? "Consumer" : ""} />
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", textAlign: "end" }}>
                <PaymentPlanDrawer
                  isOpen={isDrawer}
                  planDrawer={"TimeLine"}
                  setIsOpen={setIsDrawer}
                  PaymentID={isId}
                  setIsId={setIsId}
                  value={props}
                />
                {/* {
                  expand ? (
                    <>


                      <IconButton
                        kind={KIND.tertiary}
                        onClick={() => expanToggle()}
                      >
                        <CollapseUpArrow />
                      </IconButton>
                    </>
                  ) : (
                    <>

                      <IconButton
                        kind={KIND.tertiary}
                        onClick={() => expanToggle()}
                      >
                        <CollapseDownArrow />
                      </IconButton>
                    </>
                  )} */}
              </div>
            </div>

            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                <FormattedTime value={props?.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );

    case CUSTOMER_NAME_UPDATE: {
      const relManager = props;

      let usersRd = referenceData["users"];

      let filteringLetterUser = usersRd?.filter(
        (i) => i.id === relManager.executed_by
      );

      const timezoneBasedDate = moment
        .utc(props?.content?.notes_follow_up)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={CUSTOMER_NAME_UPDATE} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      {type ? <TypeName /> : " "}{" "}
                      <span style={{ color: "#516beb", fontSize: "16px" }}>
                        {props?.content?.customer_new_name
                          ? props?.content?.customer_new_name
                          : ""}{" "}
                      </span>
                    </Label1>
                  </div>
                  <div className="call_payment">
                    <LabelMedium>Old Name:</LabelMedium>
                    <ParagraphMedium>
                      {props?.content?.customer_old_name
                        ? props?.content?.customer_old_name
                        : ""}{" "}
                    </ParagraphMedium>
                  </div>
                  <div className="call_payment">
                    <LabelMedium>New Name:</LabelMedium>
                    <ParagraphMedium>
                      {props?.content?.customer_new_name
                        ? props?.content?.customer_new_name
                        : ""}{" "}
                    </ParagraphMedium>
                  </div>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {" "}
                  {filteringLetterUser &&
                  filteringLetterUser[0] &&
                  filteringLetterUser[0]?.label
                    ? filteringLetterUser[0]?.label
                    : ""}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );
    }
    case "RESCHEDULE_NEXT_ACTION_DATE":
      const relManager = props;
      let usersnextRd = referenceData["users"];

      let filteringnextActionUser = usersnextRd?.filter(
        (i) => i.id === relManager.executed_by
      );
      const timezoneBasedDate = moment
        .utc(props?.content?.workflow_start_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={"NEXTACTIONRESCHEDULA"} />
            </div>
            <div className="cp-status-view__discretion">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#516beb",
                        marginRight: "12px",
                        display: "flex",
                        gap: "3px",
                      }}
                    >
                      WF Reschedule: rescheduled Date&nbsp;{" "}
                      {moment
                        .utc(props?.content?.reschedule_date)
                        ?.tz(timeZone)
                        ?.format(currentDefaultFormatDetails.date_format)}
                    </Label1>
                  </div>
                  {props?.comment && (
                    <>
                      <div style={{ paddingTop: "5px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Paragraph2
                            $style={{ color: "#ADADAD", whiteSpace: "nowrap" }}
                          >
                            Comments:
                          </Paragraph2>
                          <div>{props?.comment}</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="cp-status-view__day">
                <Label2 $style={{ color: "#C4C4C4" }}>
                  Monday, 10 Jan, 2022
                </Label2>
              </div> */}
            <div className="cp-status-view__day">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {filteringnextActionUser &&
                    filteringnextActionUser[0] &&
                    filteringnextActionUser[0]?.label}{" "}
                </Label2>
                <Label2 $style={{ color: "#C4C4C4" }}>
                  {executedOnDateFormate}{" "}
                  <FormattedTime value={props.executed_on} />
                </Label2>
              </div>
            </div>
          </div>
        </>
      );

    case "CLARIFICATION_REQUEST_INFO":
    case "DOCUMENT_REQUEST_INFO": {
      let raisedDate = moment(props && props.executed_on)?.format(
        currentDefaultFormatDetails.date_format
      );

      const req_inf = props;
      let usersRd = referenceData["users"];

      const assigned_to = req_inf?.content?.assigned_to;
      const assign_type = req_inf?.content?.assign_type;
      let assignedTo =
        assign_type === "BUSINESS_UNIT" && referenceData["business_unit_list"]
          ? referenceData["business_unit_list"].find(
              (e) => e.id === assigned_to
            )
          : referenceData["users"].find((e) => e && e.id === assigned_to);

      let createdBy = usersRd.find((e) => e.id === req_inf?.executed_by);
      let clarificationCategory =
        req_inf?.content?.category == "CLARIFICATION"
          ? referenceData &&
            referenceData["request_information_sub_category_list"]?.find(
              (e) =>
                req_inf &&
                req_inf?.content &&
                req_inf?.content?.req_info_type &&
                req_inf?.content?.req_info_type === e.id
            )
          : subCategory &&
            subCategory.data &&
            subCategory.data.data &&
            subCategory.data.data.docs
          ? subCategory.data.data.docs.find(
              (e) =>
                req_inf?.content &&
                req_inf?.content?.req_info_type &&
                req_inf?.content?.req_info_type === e.id
            )
          : [];

      const statusColor = {
        CLOSED: "#0FB158",
        SUBMITTED: "#7D7C7C",
        IN_PROGRESS: "#FBBD04",
        OPEN: "#FD372A",
      };

      return (
        <>
          <div className="cp-timeline-contant__status-view">
            <div className="cp-status-view__icon">
              <TemplateIcon type={"REQUESTINFORMATION"} />
            </div>
            <div className="cp-status-view__discretion_dispute">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  padding: "10px 25px",
                }}
              >
                <div className="cp-discretion__text-content">
                  <div className="cp-text-content__text-deliver">
                    <Label1
                      $style={{
                        color: "#333860",
                        marginRight: "12px",
                        display: "flex",
                      }}
                    >
                      <FormattedMessage id={req_inf && req_inf?.type} />{" "}
                      Requested (
                      {clarificationCategory && clarificationCategory?.label})
                      {" - "}{" "}
                      <Label1
                        $style={{
                          color: statusColor[req_inf?.content?.req_info_status]
                            ? statusColor[req_inf?.content?.req_info_status]
                            : "#FBBD04",
                        }}
                      >
                        {_.capitalize(
                          _.replace(req_inf?.content?.req_info_status, "_", " ")
                        )}
                      </Label1>
                    </Label1>
                  </div>
                  <div>
                    <div className="call_payment">
                      <div className="dispute_timeexpend_card">
                        <ParagraphMedium $style={{ color: "#ADADAD" }}>
                          Category:
                        </ParagraphMedium>
                      </div>
                      <ParagraphMedium $style={{ color: "#333860" }}>
                        <FormattedMessage id={req_inf?.content?.category} />
                      </ParagraphMedium>
                    </div>
                    <div className="call_payment">
                      <ParagraphMedium $style={{ color: "#ADADAD" }}>
                        Sub-Category:
                      </ParagraphMedium>
                      <ParagraphMedium $style={{ color: "#333860" }}>
                        {clarificationCategory && clarificationCategory.label
                          ? clarificationCategory.label
                          : ""}
                      </ParagraphMedium>
                    </div>
                    <div className="call_payment">
                      <div className="dispute_timeexpend_card">
                        <ParagraphMedium $style={{ color: "#ADADAD" }}>
                          Invoice:
                        </ParagraphMedium>
                      </div>
                      <ParagraphMedium $style={{ color: "#333860" }}>
                        {req_inf &&
                        req_inf.content &&
                        req_inf.content.req_info_invoices &&
                        Array.isArray(req_inf.content.req_info_invoices)
                          ? req_inf.content.req_info_invoices.toString()
                          : ""}
                      </ParagraphMedium>
                    </div>
                    <div className="req_info">
                      <div className="dispute_timeexpend_card">
                        <ParagraphMedium $style={{ color: "#ADADAD" }}>
                          Details:
                        </ParagraphMedium>
                      </div>
                      <ParagraphMedium $style={{ color: "#333860" }}>
                        {req_inf?.content?.req_info_details}
                      </ParagraphMedium>
                    </div>

                    <div className="call_payment">
                      <div className="dispute_timeexpend_card">
                        <ParagraphMedium $style={{ color: "#ADADAD" }}>
                          Status:
                        </ParagraphMedium>
                      </div>
                      <div className="call_payment">
                        <div
                          className="request_status"
                          style={{
                            background: statusColor[
                              req_inf?.content?.req_info_status
                            ]
                              ? statusColor[req_inf?.content?.req_info_status]
                              : "#FBBD04",
                          }}
                        ></div>
                        <ParagraphMedium>
                          {_.capitalize(
                            _.replace(
                              req_inf?.content?.req_info_status,
                              "_",
                              " "
                            )
                          )}
                        </ParagraphMedium>
                      </div>
                    </div>
                    <div className="call_payment">
                      <div className="dispute_timeexpend_card">
                        <ParagraphMedium $style={{ color: "#ADADAD" }}>
                          Assigned To:
                        </ParagraphMedium>
                      </div>
                      <ParagraphMedium $style={{ color: "#333860" }}>
                        {assignedTo && assignedTo?.label}
                      </ParagraphMedium>
                    </div>
                    <div className="call_payment">
                      <div className="dispute_timeexpend_card">
                        <ParagraphMedium $style={{ color: "#ADADAD" }}>
                          Raised Date:
                        </ParagraphMedium>
                      </div>
                      <ParagraphMedium $style={{ color: "#333860" }}>
                        {raisedDate && raisedDate ? raisedDate : ""}
                      </ParagraphMedium>
                    </div>
                  </div>
                </div>
              </div>
              {/* {req_inf?.audit.length > 0 ? (
                req_inf?.audit.map((value, index) => {
                  let assingto = referenceData["users"].find(
                    (e) => e.id === value?.data?.assigned_to
                  );
                  return (
                    <div className="request_top_border">
                      <div className="--dispute_header">
                        <div style={{ width: "260px", marginTop: "5px" }}>
                          {value?.data?.attachments.length > 0 ? (
                            value?.data?.attachments.map((item) => {
                              let splitType = item && item.name.split(".");
                              return (
                                <DocumentCard
                                  res={{
                                    file_name: item.name,
                                    mime_type: splitType[1],
                                    _id: item.ref,
                                    // type: splitType[1],
                                    // file_,
                                    customerId: req_inf.customerId,
                                    tab: "timeline",
                                    // isDownload: true,
                                  }}
                                />
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>

                        <ParagraphMedium>
                          Find the attachment the performa invoice and the
                          payment Proof
                        </ParagraphMedium>
                        <div className="request_header">
                          <div className="request_head_line">
                            <div className="dispute_timeexpend_card">
                              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                                Invoice:
                              </ParagraphMedium>
                            </div>

                            <ParagraphMedium>
                              Inv -{" "}
                              {req_inf &&
                              req_inf.content &&
                              req_inf.content.req_info_invoices &&
                              req_inf.content.req_info_invoices[0]
                                ? req_inf.content.req_info_invoices[0]
                                : ""}
                              {req_inf &&
                              req_inf.content &&
                              req_inf.content.req_info_invoices &&
                              req_inf.content.req_info_invoices.length > 1 ? (
                                <StatefulTooltip
                                  content={() => (
                                    <ParagraphMedium
                                      $style={{ color: "#333860" }}
                                    >
                                      {req_inf.content.req_info_invoices.map(
                                        (e) => (
                                          <ParagraphMedium>
                                            Inv-{e}
                                          </ParagraphMedium>
                                        )
                                      )}
                                    </ParagraphMedium>
                                  )}
                                  returnFocus
                                  autoFocus
                                  overrides={{
                                    Inner: {
                                      style: ({ $theme }) => ({
                                        backgroundColor: "#fff",
                                      }),
                                    },
                                  }}
                                >
                                  <span className="count_inv">
                                    +{req_inf.content.req_info_invoices.length}
                                  </span>
                                </StatefulTooltip>
                              ) : (
                                ""
                              )}
                            </ParagraphMedium>
                          </div>
                          <div className="request_head_line">
                            <div className="dispute_timeexpend_card">
                              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                                Status:
                              </ParagraphMedium>
                            </div>
                            <div>
                              {/* <div className="request_head_line">
                                <div
                                  className="dot_dipute"
                                  style={{ background: "#FD372A" }}
                                ></div>

                                <ParagraphMedium>Open</ParagraphMedium>
                              </div>{" "} */}
              {/* {value?.data?.req_info_status === "CLOSED" ? (
                                <div className="request_head_line">
                                  <div
                                    className="dot_dipute"
                                    style={{ background: "#48b24f" }}
                                  ></div>

                                  <ParagraphMedium>Close</ParagraphMedium>
                                </div>
                              ) : value?.data?.req_info_status === "OPEN" ? (
                                <div className="request_head_line">
                                  <div
                                    className="dot_dipute"
                                    style={{ background: "#FD372A" }}
                                  ></div>

                                  <ParagraphMedium>Open</ParagraphMedium>
                                </div>
                              ) : (
                                <div className="request_head_line">
                                  <div
                                    className="dot_dipute"
                                    style={{ background: "#FBBD04" }}
                                  ></div>

                                  <ParagraphMedium>
                                    In Progress{" "}
                                  </ParagraphMedium>
                                </div>
                              )} */}
              {/* </div>
                          </div>
                        </div>
                        <div className="request_header">
                          <div className="request_head_line">
                            <div className="dispute_timeexpend_card">
                              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                                Assigned To:
                              </ParagraphMedium>
                            </div>
                            <ParagraphMedium>{assingto?.label}</ParagraphMedium>
                          </div>
                          <div className="request_head_line">
                            <div className="dispute_timeexpend_card">
                              <ParagraphMedium $style={{ color: "#ADADAD" }}>
                                Review Date :
                              </ParagraphMedium>
                            </div>
                            <div>
                              <ParagraphMedium>
                                {moment
                                  .utc(value?.data?.req_info_review_date)
                                  ?.tz(timeZone)
                                  ?.format(
                                    currentDefaultFormatDetails.date_format
                                  )}
                              </ParagraphMedium>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : ( 
                <></>
              )} */}
              <div className="dispute_captured_footer">
                <div className="request_head_line">
                  <ParagraphMedium $style={{ color: "#ADADAD" }}>
                    Captured By :
                  </ParagraphMedium>
                  <LabelMedium style={{ color: "#787878" }}>
                    {createdBy && createdBy.label ? createdBy.label : ""}
                  </LabelMedium>
                  {/* <div className="request_dot_clarifi"></div>
                  <ParagraphMedium $style={{ color: "#787878" }}>
                    -
                  </ParagraphMedium> */}
                </div>
                <div className="footer_replay-btn">
                  <div style={{ padding: "0px 5px", cursor: "pointer" }}>
                    {/* <HeadingXSmall $style={{ color: "#516BEB" }}>
                      View More
                    </HeadingXSmall> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="cp-status-view__day">
              <Label2 $style={{ color: "#C4C4C4" }}>
                {executedOnDateFormate}{" "}
                <FormattedTime value={props.executed_on} />
              </Label2>
            </div>
          </div>
        </>
      );
    }

    default:
      return null;
  }
};

export default TimelineCard;
