export default function AddressStatus({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.72215 11.232C4.79583 11.4775 4.65646 11.7364 4.41086 11.8101C3.87487 11.9709 3.47494 12.1531 3.21937 12.3344C2.95174 12.5242 2.92858 12.6472 2.92858 12.6786C2.92858 12.7099 2.95175 12.8329 3.21937 13.0227C3.47494 13.204 3.87487 13.3863 4.41086 13.5471C5.47692 13.8669 6.9775 14.0714 8.65479 14.0714C10.3321 14.0714 11.8327 13.8669 12.8988 13.5471C13.4347 13.3863 13.8346 13.204 14.0902 13.0227C14.3579 12.8329 14.381 12.7099 14.381 12.6786C14.381 12.6472 14.3579 12.5242 14.0902 12.3344C13.8346 12.1531 13.4347 11.9709 12.8987 11.8101C12.6531 11.7364 12.5138 11.4775 12.5874 11.232C12.6611 10.9864 12.9199 10.847 13.1656 10.9206C13.7498 11.0959 14.2565 11.3139 14.6274 11.577C14.9863 11.8315 15.3096 12.1971 15.3096 12.6786C15.3096 13.16 14.9863 13.5256 14.6274 13.7802C14.2565 14.0432 13.7498 14.2612 13.1656 14.4365C11.9911 14.7888 10.3964 15 8.65479 15C6.91316 15 5.31849 14.7888 4.14403 14.4365C3.55976 14.2612 3.05311 14.0432 2.68216 13.7802C2.32328 13.5256 2 13.16 2 12.6786C2 12.1971 2.32329 11.8315 2.68216 11.577C3.05311 11.3139 3.55976 11.0959 4.14403 10.9206C4.38963 10.847 4.64847 10.9864 4.72215 11.232Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.16669 6.38963C4.16669 3.95452 6.187 2 8.6548 2C11.1226 2 13.1429 3.95452 13.1429 6.38963C13.1429 8.10093 12.0229 9.63296 11 10.6879C10.4782 11.226 9.95809 11.6641 9.56895 11.9673C9.37402 12.1193 9.21102 12.2382 9.09588 12.3196C9.03824 12.3604 8.99256 12.3919 8.96074 12.4135C8.94483 12.4243 8.93239 12.4326 8.92366 12.4384L8.91332 12.4452L8.91035 12.4472L8.90936 12.4478C8.90923 12.4479 8.9088 12.4482 8.6548 12.0596C8.40081 12.4482 8.40068 12.4481 8.40056 12.4481L8.39926 12.4472L8.39629 12.4452L8.38595 12.4384C8.37722 12.4326 8.36478 12.4243 8.34887 12.4135C8.31705 12.3919 8.27136 12.3604 8.21373 12.3196C8.09859 12.2382 7.93559 12.1193 7.74065 11.9673C7.35155 11.6641 6.83139 11.226 6.30957 10.6879C5.28667 9.63296 4.16669 8.10093 4.16669 6.38963ZM8.6548 12.0596L8.40056 12.4481C8.55489 12.5489 8.75447 12.5491 8.9088 12.4482L8.6548 12.0596ZM8.6548 11.4934C8.74878 11.4252 8.86522 11.3386 8.99807 11.235C9.36343 10.9502 9.84926 10.5408 10.3334 10.0415C11.3224 9.02146 12.2143 7.71854 12.2143 6.38963C12.2143 4.48892 10.6316 2.92858 8.6548 2.92858C6.67801 2.92858 5.09526 4.48892 5.09526 6.38963C5.09526 7.71854 5.98719 9.02146 6.97621 10.0415C7.46035 10.5408 7.94618 10.9502 8.31154 11.235C8.44439 11.3386 8.56083 11.4252 8.6548 11.4934Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.64288 6.48811C6.64288 5.37697 7.54366 4.4762 8.6548 4.4762C9.76593 4.4762 10.6667 5.37697 10.6667 6.48811C10.6667 7.59924 9.76593 8.50002 8.6548 8.50002C7.54366 8.50002 6.64288 7.59924 6.64288 6.48811ZM8.6548 5.40477C8.05649 5.40477 7.57146 5.8898 7.57146 6.48811C7.57146 7.08642 8.05649 7.57145 8.6548 7.57145C9.25311 7.57145 9.73814 7.08642 9.73814 6.48811C9.73814 5.8898 9.25311 5.40477 8.6548 5.40477Z"
        fill={color}
      />
    </svg>
  );
}
