import ColumnToogle from "../components/ColumnToggle/ColumnToggle";
import tableGetFilter from "../components/TableFilters";
import tableCellFilter from "../components/TableCell";
import CheckBox from "../components/CheckBox/CheckBox";
import DownArrow from "./DownArrow";
import UpArrow from "./UpArrow";
import { IconButton, SIZE, KIND } from "../components/IconButton";
import headerType from "../components/HeaderTypes";
import tableCellTypeV2 from "../components/TableCell/index_v2";
import TabelHead from "../components_v2/TabelHead";
import _ from "lodash";
const columnMapper = ({
  columns = [],
  isExpanded = true,
  isExpandedHeader = true,
  isSelection = true,
  viewSelect = [],
  isRowDeletedVar,
  isEdit = true,
  isChecked = false,
  editAction = () => {},
  width = 0,
  isCurrency = false,
  isInvExpand = false,
  isV2 = false,
}) => {
  let newColumns = [];
  let hiddenColumns = [];
  let filters = [];
  let sort = null;

  const [reservedWidth, reservedColumns] = columns.reduce(
    (prev, curr) => {
      if (curr && curr.width_percentage && curr.width_percentage > 0) {
        prev[1] = prev[1] + 1;
        prev[0] = prev[0] + ((width - 80) / 100) * curr.width_percentage;
        return prev;
      }

      return prev;
    },
    [0, 0]
  );

  let columnWidth =
    (width - reservedWidth) / (columns.length - reservedColumns);

  for (let column of columns) {
    let Filter = tableGetFilter(column.type);
    let Cell;
    if (column.nested && Array.isArray(column.nested)) {
      Cell = (props) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {column.nested.map((nst) => {
            let InnerCell = tableCellFilter({
              filterType: nst.cell_type,
              alignment: nst.alignment,
              accessor: nst.accessor,
              suffix: nst.suffix,
              isCurrency: isCurrency,
              rdName: nst.rd_name,
              color: nst.color,
            });

            return <InnerCell {...props} />;
          })}
        </div>
      );
    } else {
      Cell = tableCellFilter({
        filterType: column.cell_type,
        alignment: column.alignment,
        accessor: column.accessor,
      });
    }

    let newCol = {
      id: column.accessor,
      col_id: column.col_id,
      cell_type: column.cell_type,
      literal_id: column.literal_id,
      Header: headerType({
        headerFilterType: column.header_type,
        // alignment: alignment(column.cell_type),
        alignment: column.header_alignment,
        cellType: column.cell_type,
        isCurrency: isCurrency,
        // sortBy: column.sort_by,
      }),
      // header: TabelHead({
      //   literal_id: column.literal_id,
      // }),
      Cell,
      Filter,
      width:
        column.width_percentage && column.width_percentage > 0
          ? ((width - 80) / 100) * column.width_percentage
          : columnWidth,
      // width: 40,
      // defaultCanSort: true,
      canSort: column.sort_by,
      disableFilters: !column.filter_by,
      order: column.order,
      rdName: column.rd_name,
      type: column.rep_type,
      repSubType: column.rep_sub_type,
      // accessor: column.accessor,
      path: column.accessor,
    };

    let viewCol = viewSelect.find((e) => e.col_id === newCol.path);

    if (viewCol) {
      let { order, width } = viewCol;
      newCol = { ...newCol, order, width };

      if (viewCol.is_hidden) {
        hiddenColumns.push(newCol.id);
      }

      if (viewCol.sort) {
        sort = viewCol.sort;
      }

      if (viewCol.filter) {
        filters.push({ id: newCol.id, value: viewCol.filter.value });
      }

      if (viewCol.nested && Array.isArray(viewCol.nested)) {
        viewCol.nested.map((i) => {
          filters.push({ id: i.col_id, value: _.get(i, "filter.value", "") });
        });
      }
    } else {
      if (column.is_hidden) {
        hiddenColumns.push(newCol.id);
      }
    }

    newColumns.push(newCol);
  }
  newColumns = newColumns.sort((a, b) => a.order - b.order);

  if (isInvExpand || isExpandedHeader) {
    newColumns.unshift({
      Header: isExpandedHeader
        ? (props) => {
            return (
              <div
              // style={{
              //   padddingLeft: "40px",
              //   backgroundColor: "#000",
              //   marginRight: "30px",
              // }}
              >
                <ColumnToogle
                  style={{ backgroundColor: "" }}
                  allColumns={props.allColumns}
                />
              </div>
            );
          }
        : () => null,
      id: "expander",
      width: 50,
      // width: 40 ? 0 : 40,
      disableDND: true,
      disableResizing: true,
      Cell: isInvExpand
        ? ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <IconButton size={SIZE.compact} kind={KIND.tertiary}>
                  <UpArrow />
                </IconButton>
              ) : (
                <IconButton size={SIZE.compact} kind={KIND.tertiary}>
                  <DownArrow />
                </IconButton>
              )}
            </span>
          )
        : () => null,
    });
  }

  if (isExpanded || isExpandedHeader) {
    newColumns.push({
      Header: isExpandedHeader
        ? (props) => {
            return (
              <div
              // style={{
              //   padddingLeft: "40px",
              //   backgroundColor: "#000",
              //   marginRight: "30px",
              // }}
              >
                <ColumnToogle
                  style={{ backgroundColor: "" }}
                  allColumns={props.allColumns}
                />
              </div>
            );
          }
        : () => null,
      id: "expander",
      width: 50,
      // width: 40 ? 0 : 40,
      disableDND: true,
      disableResizing: true,
      Cell: isExpanded
        ? ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <IconButton size={SIZE.compact} kind={KIND.tertiary}>
                  <UpArrow />
                </IconButton>
              ) : (
                <IconButton size={SIZE.compact} kind={KIND.tertiary}>
                  <DownArrow />
                </IconButton>
              )}
            </span>
          )
        : () => null,
    });
  }

  if (isSelection) {
    newColumns.unshift({
      id: "selection",
      disableResizing: true,
      width: 40,
      disableDND: true,

      Header: ({
        getToggleAllRowsSelectedProps,
        isAllPageRowsSelected,
        page,
        toggleRowSelected,
      }) => {
        const modifiedOnChange = (event) => {
          page.forEach((row) => {
            if (
              !isRowDeletedVar ||
              (isRowDeletedVar && row.original[isRowDeletedVar])
            ) {
              toggleRowSelected(row.id, event.currentTarget.checked);
            }
          });
        };

        let selectableRowsInCurrentPage = 0;
        let selectedRowsInCurrentPage = 0;
        page.forEach((row) => {
          if (row.isSelected) {
            selectedRowsInCurrentPage++;
          }

          if (
            (isRowDeletedVar && row.original[isRowDeletedVar]) ||
            !isRowDeletedVar
          ) {
            selectableRowsInCurrentPage += 1;
          }
        });

        const disabled = selectableRowsInCurrentPage === 0;

        const checked =
          (isAllPageRowsSelected ||
            selectableRowsInCurrentPage === selectedRowsInCurrentPage) &&
          !disabled;

        return (
          <CheckBox
            {...getToggleAllRowsSelectedProps()}
            onChange={modifiedOnChange}
            checked={checked}
            disabled={disabled}
          />
        );
      },

      Cell: ({ row }) => (
        <CheckBox
          {...row.getToggleRowSelectedProps()}
          disabled={
            row.original
              ? isRowDeletedVar && !row.original[isRowDeletedVar]
              : false
          }
        />
      ),
    });
  }

  return { columns: newColumns, hiddenColumns, filters, sort };
};
export default columnMapper;
