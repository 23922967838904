import React from 'react'

const PlayIcon = ({size=22,color="#333860"}) => {
  return (
<svg width={size}height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.1582 14.862V7.13789C8.1582 6.9074 8.23307 6.72604 8.38281 6.59383C8.53253 6.46161 8.70721 6.39551 8.90684 6.39551C8.96924 6.39551 9.03466 6.4055 9.1031 6.42548C9.17155 6.44547 9.23787 6.47445 9.30207 6.51243L15.2411 10.3918C15.3516 10.4672 15.4383 10.5571 15.5012 10.6615C15.564 10.7658 15.5955 10.8799 15.5955 11.0036C15.5955 11.1274 15.564 11.2415 15.5012 11.3458C15.4383 11.4502 15.3516 11.5376 15.2411 11.6081L9.30172 15.4903C9.23733 15.5283 9.16971 15.5569 9.09886 15.5759C9.02804 15.5949 8.96042 15.6044 8.89603 15.6044C8.6989 15.6044 8.52665 15.5383 8.37928 15.4061C8.2319 15.2739 8.1582 15.0925 8.1582 14.862Z" fill={color}/>
</svg>

  )
}

export default PlayIcon