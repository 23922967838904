import React, { useEffect, useState, useRef } from "react";
import { StyledList, StyledListItem } from "baseui/menu";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import Edit from "../../assets/img/svg/EditSvg";
import useHover from "../../utils/HoverSvg/useHover";
import "../../../src/assets/css/table.css";
import { Label2 } from "baseui/typography";
import { IconButton, SIZE } from "../IconButton";
import { FormattedMessage } from "react-intl";
import TableHeaderAdder from "../../assets/img/svg/TableHeaderAdder";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { result } from "lodash";
import DragDropHorizontal from "../../assets/img/svg/DragDropHorizontal";
import { setTableHeaderOrder } from "../../redux/customerSummary/action";
import { useDispatch } from "react-redux";
import DragDropVertical from "../../assets/img/svg/DragDropVertical";

const data = [
  {
    id: "item-1",
    content: "Item-1",
  },
  {
    id: "item-2",
    content: "Item-2",
  },
  {
    id: "item-3",
    content: "Item-3",
  },
  {
    id: "item-4",
    content: "Item-4",
  },
  {
    id: "item-5",
    content: "Item-5",
  },
  {
    id: "item-6",
    content: "Item-6",
  },
  {
    id: "item-7",
    content: "Item-7",
  },
  {
    id: "item-8",
    content: "Item-8",
  },
  {
    id: "item-9",
    content: "Item-9",
  },
];

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // background: "green",
  // padding: 20,
  // margin: "0 0 8px 0",
  // color: isDraggingOver ? "green" : "blue",
  // background: "blue",
  // color: "black",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // userSelect: "none",
  // padding: 20,
  // margin: "0 0 8px 0",
  // background: isDragging ? "lightgreen" : "grey",
  // color: "black",
  // background: "red",
  // color: "black",

  ...draggableStyle,
});

const ColumnCheckToogle = ({
  allColumns,
  setisHovered,

  hoverRef,
  setColumnOrder,
}) => {
  const togglePopup = (event) => {
    const concernedElement = document.querySelector(".click-text");
    if (!concernedElement.contains(event.target)) {
      setisHovered(false);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener("mousedown", togglePopup);
  }, []);

  useEffect(() => {
    const setInitialState = () => {
      document.removeEventListener("mousedown", togglePopup);
    };

    return setInitialState;
  }, []);

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#ffffff" : "#ffffff",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#516BEB" : "",
    // padding: grid,
    width: 250,
    // height: "100%",
    // zIndex: 100,
  });

  return (
    <div
      style={{
        borderRadius: "15px",
        boxShadow: "0px 13px 27px -5px #32325D25,0px 8px 16px -8px #00000030",
        //
      }}
    >
      <div style={{ background: "#516beb", borderRadius: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
            paddingBottom: "15px",
            // borderBottom: "0.5px solid #D6D6D650",
            // marginBottom: "5px",
            background: "#ffffff",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            border: "0.5px solid #CDCED9",
          }}
        >
          <Label2
            style={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#516BEB",
            }}
          >
            Edit Columns
          </Label2>
        </div>
        <div
          style={{
            overflowY: "scroll",
            height: "300px",
            // borderRadius: "10px",
            background: "#ffffff",
            // background: "#516BEB",
            // borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            // borderTopRightRadius: "5px",
            border: "0.5px solid #CDCED9",
          }}
        >
          <DragDropContext
            onDragEnd={(dragUpdateObj, b) => {
              //   currentColOrder.current = allColumns.map((i) => i.id);
              //   const colOrder = [...currentColOrder.current];
              //   const sIndex = dragUpdateObj.source.index;
              //   const dIndex =
              //     dragUpdateObj.destination && dragUpdateObj.destination.index;

              //   if (typeof sIndex === "number" && typeof dIndex === "number") {
              //     let f = colOrder.slice(sIndex, dIndex);
              //     let e = colOrder.slice(dIndex, colOrder.length);
              //     // colOrder.splice(sIndex, 1);
              //     // colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);

              //     setColumnOrder(colOrder);
              //     // dispatch(setTableHeaderOrder(colOrder));
              //   }
              const colOrder = allColumns
                ?.filter((i) => i.id !== "selection")
                .map((k) => k.id);
              const sIndex = dragUpdateObj.source.index;
              const dIndex =
                dragUpdateObj.destination && dragUpdateObj.destination.index;
              // setColumnOrder(colOrder);
              // dispatch(setTableHeaderOrder(colOrder));
              if (typeof sIndex === "number" && typeof dIndex === "number") {
                colOrder.splice(sIndex, 1);
                colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                setColumnOrder(["selection", ...colOrder]);
              }

              // setColumnOrder([
              //   "selection",
              //   "default_currency",
              //   "due_inv_count",
              //   "customer_name",
              //   "due_inv_amount",
              //   "overdue_inv_count",
              //   "overdue_inv_amount",
              //   "total_outstanding_invoice_count",
              //   "total_outstanding_invoice_amount",
              //   "cus_max_delay",
              //   "rel_manager",
              //   "workflow.name",
              //   "last_action_date",
              //   "last_action_name",
              //   "last_action_comment",
              //   "next_action_date",
              //   "next_action_name",
              //   "max_payment_delay",
              //   "avg_payment_delay",
              //   "last_response_date",
              //   "days_after_last_response",
              // ]);
            }}
          >
            <Droppable droppableId="12345">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {allColumns
                    .filter((i) => i.id !== "selection")
                    ?.map((i, index) => (
                      <Draggable key={i.id} draggableId={i.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "7.5px",
                                  gap: "10px",
                                }}
                              >
                                <div {...provided.dragHandleProps}>
                                  <DragDropVertical />
                                </div>
                                <CheckBoxBaseweb
                                  overrides={{
                                    Root: {
                                      style: ({ $theme }) => ({
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-between",
                                      }),
                                    },

                                    Checkmark: {
                                      style: ({ $theme, $checked }) => ({
                                        height: "18px",
                                        width: "18px",
                                        backgroundColor: $checked
                                          ? "#516BEB"
                                          : null,
                                        borderRadius: $theme.borders.radius200,
                                        borderBottomColor: $checked
                                          ? $theme.colors.primary
                                          : `#CDCED9`,
                                        borderTopColor: $checked
                                          ? $theme.colors.primary
                                          : `#CDCED9`,
                                        borderRightColor: $checked
                                          ? $theme.colors.primary
                                          : `#CDCED9`,
                                        borderLeftColor: $checked
                                          ? $theme.colors.primary
                                          : `#CDCED9`,
                                        borderBottomWidth: "0.5px",
                                        borderTopWidth: "0.5px",
                                        borderRightWidth: "0.5px",
                                        borderLeftWidth: "0.5px",
                                        borderStyle: "solid",
                                        backgroundImage: $checked
                                          ? "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDExIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIxLjQxNDIxIiB5MT0iNCIgeDI9IjQiIHkyPSI2LjU4NTc5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIHgxPSI0IiB5MT0iNi41ODU3OSIgeDI9IjkuNTg1NzkiIHkyPSIxIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=)"
                                          : "none",
                                        backgroundSize: "auto",
                                      }),
                                    },
                                  }}
                                  labelPlacement={LABEL_PLACEMENT.left}
                                  {...i?.getToggleHiddenProps()}
                                >
                                  <FormattedMessage id={i.literal_id}>
                                    {i.literal_id}
                                  </FormattedMessage>
                                </CheckBoxBaseweb>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      {/* <div
        style={{
          overflowY: "scroll",
          height: "300px",
          borderRadius: "10px",
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map(
                  (item, index) => (
                    
                    (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="card"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {item.content}
                          </div>
                        )}
                      </Draggable>
                    )
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div> */}
    </div>
  );
};

export default ColumnCheckToogle;
