import React, { useContext, useEffect, useState } from "react";
import { Modal } from "baseui/modal";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { TextArea } from "../TextArea";
import workflowModal from "../../assets/img/workflow-modal.png";
import { SelectBox } from "../SelectBox";
import { ParagraphLarge } from "baseui/typography";
import { TextButton, KIND, SIZE } from "../TextButton";
import getSelectValues from "../../utils/getSelectValues";
import setSelectValues from "../../utils/setSelectValues";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { refetchActions } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  BUSINESS_UNIT_MAINTENANCE,
  CO_INVOICES_,
  CUSTOMER_OVERVIEW,
} from "../../constants";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";

const BusinessStatus = ({
  setBusinessStatus,
  businessStatus,
  service,
  currentOrganization,
  getParams,
  businessData,
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);
  let dispatch = useDispatch();
  const queryClient = useQueryClient();
  let { users, referenceData, restrictions, space, rdRefetch, refetch } =
    maxyfiContextValue;

  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const intl = useIntl();

  function close() {
    setBusinessStatus(false);
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let { business_unit, business_location } = watch();

  const putBusinessStatusService = useMutation(
    (data) =>
      // {
      //
      // }
      service({
        organization: currentOrganization,
        customerId: businessData && businessData.customerId,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();

        setBusinessStatus(!businessStatus);
        // dispatch(refetchActions());
        queryClient.invalidateQueries(
          `${CUSTOMER_OVERVIEW}-${currentOrganization}`
        );
        queryClient.refetchQueries(`${CO_INVOICES_}${getParams}`);
        if (
          business_unit &&
          business_unit[0] &&
          business_unit[0]?.isCreatable
        ) {
          refetch({
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }

        if (
          business_location &&
          business_location[0] &&
          business_location[0]?.isCreatable
        ) {
          refetch({
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      business_unit: getSelectValues(data.business_unit),
      business_location: getSelectValues(data.business_location),
    };
    await putBusinessStatusService.mutateAsync(values);
  };

  useEffect(() => {
    if (businessData) {
      setValue("business_unit", setSelectValues(businessData?.businessUnit));
      setValue(
        "business_location",
        setSelectValues(businessData?.businessLocation)
      );
    }
  }, [businessData]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      <Modal
        onClose={close}
        isOpen={businessStatus}
        // size={SIZE.auto}
        closeable={false}
        overrides={{
          Dialog: {
            style: ({ $theme }) => ({
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              width: "340px",
            }),
          },
        }}
      >
        <div style={{ width: "" }}>
          <div
            style={{
              backgroundColor: "#516BEB30",
              textAlign: "center",
              borderRadius: "10px 10px 50px 50px",
              height: "182px",
              paddingTop: "20px",
            }}
          >
            <img src={workflowModal} alt="" />
          </div>
          <form
            style={{
              textAlign: "center",
              marginTop: "15px",
              padding: "10px 20px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Select Client
            </ParagraphLarge>
            <Controller
              name="business_unit"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  clearable={false}
                  creatable={creatablePermission ? true : false}
                  // disabled={isDisabled}
                  // creatable
                  backspaceRemoves={false}
                  disabled={
                    customerDetails && customerDetails.is_client ? true : false
                  }
                  requiredAstric={true}
                  //   onInputChange={(evt) => {
                  // fetchWorkflow({ name: evt.target.value });
                  //   }}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "business_unit",
                  })}
                  placeholder={intl.formatMessage({
                    id: "business_unit",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={
                    referenceData && referenceData["business_unit_list"]
                      ? referenceData["business_unit_list"]
                      : []
                  }
                />
              )}
            />

            <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Select Client Location
            </ParagraphLarge>
            <div>
              <Controller
                name="business_location"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    clearable={false}
                    creatable={creatablePermission ? true : false}
                    // disabled={isDisabled}
                    requiredAstric={true}
                    //   onInputChange={(evt) => {
                    // fetchWorkflow({ name: evt.target.value });
                    //   }}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "business_location",
                    })}
                    placeholder={intl.formatMessage({
                      id: "business_location",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["business_location_list"].sort(
                      (a, b) => a?.id.localeCompare(b?.id)
                    )}

                    //   options={workflowList.map((e) => ({
                    //     id: e._id,
                    //     label: e.name,
                    //   }))}
                  />
                )}
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                textAlign: "center",
                // marginBottom: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px 20px",
              }}
            >
              <div style={{ height: "", width: "96px" }}>
                <TextButton
                  type="button"
                  fullWidth
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  // onClick={close}
                  onClick={() => {
                    reset();
                    close();
                    // dispatch(workflowAddModal(false));
                  }}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ height: "", width: "96px" }}>
                <TextButton
                  type="submit"
                  fullWidth
                  size={SIZE.compact}
                  kind={KIND.primary}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Apply
                </TextButton>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default BusinessStatus;
