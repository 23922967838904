export default function Reschedule({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.40909 3C6.72289 3 6.97727 3.25439 6.97727 3.56818V4.13636H10.3864V3.56818C10.3864 3.25439 10.6407 3 10.9545 3C11.2684 3 11.5227 3.25439 11.5227 3.56818V4.13636H12.6591C13.6005 4.13636 14.3636 4.89952 14.3636 5.84091V9.81818V13.7955C14.3636 14.7369 13.6005 15.5 12.6591 15.5H8.68182H4.70455C3.76315 15.5 3 14.7369 3 13.7955V5.84091C3 4.89952 3.76315 4.13636 4.70455 4.13636H5.84091V3.56818C5.84091 3.25439 6.0953 3 6.40909 3ZM10.3864 5.27273V5.84091C10.3864 6.1547 10.6407 6.40909 10.9545 6.40909C11.2684 6.40909 11.5227 6.1547 11.5227 5.84091V5.27273H12.6591C12.9729 5.27273 13.2273 5.52711 13.2273 5.84091V7.54545H4.13636V5.84091C4.13636 5.52711 4.39075 5.27273 4.70455 5.27273H5.84091V5.84091C5.84091 6.1547 6.0953 6.40909 6.40909 6.40909C6.72289 6.40909 6.97727 6.1547 6.97727 5.84091V5.27273H10.3864ZM9.81807 8.68182C9.96341 8.68182 10.109 8.73733 10.2199 8.84824L10.7881 9.41642C11.01 9.6383 11.01 9.99807 10.7881 10.2199C10.5663 10.4418 10.2065 10.4418 9.9846 10.2199L9.41642 9.65176C9.19455 9.42989 9.19455 9.07011 9.41642 8.84824C9.52733 8.73733 9.67273 8.68182 9.81807 8.68182ZM9.18699 9.88233C8.96511 9.66045 8.60534 9.66045 8.38347 9.88233L6.57551 11.6903C6.46895 11.7968 6.40909 11.9414 6.40909 12.092V12.6602C6.40909 12.974 6.66348 13.2284 6.97727 13.2284H7.54545C7.69614 13.2284 7.84068 13.1685 7.94722 13.062L9.75517 11.254C9.97705 11.0322 9.97705 10.6724 9.75517 10.4505L9.18699 9.88233Z"
        fill={color}
      />
    </svg>
  );
}
