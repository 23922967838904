import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { GET_STATS_DASHBOARD_ } from "../../../constants";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useQuery } from "react-query";
import {
  getPerformanceList,
  getPerformanceStats,
} from "../../../services/revenueBilling";
// import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function LineChartPerformance({
  isPerformance,
  setIsPerformance,
  value,
}) {
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const { data, isFetched, isError, isLoading, isFetching } = useQuery(
    [
      `${GET_STATS_DASHBOARD_}_${currentOrganization}`,
      {
        filters: {
          ...(value.startDate && value.endDate
            ? {
                month: [
                  moment
                    .tz(value.startDate, currentDefaultFormatDetails.time_zone)
                    .startOf("month")
                    .utc()
                    .valueOf(),
                  moment
                    .tz(value.endDate, currentDefaultFormatDetails.time_zone)
                    .endOf("month")
                    .utc()
                    .valueOf(),
                ],
              }
            : {}),
          ...isPerformance,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getPerformanceStats({
        organization: currentOrganization,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const { labelKey, count } = useMemo(() => {
    let labelKey = [];
    let count = [];
    if (data && data.data && data.data.data) {
      data.data.data?.map((e) => {
        labelKey.push(
          e?.mns
            ? moment
                .tz(e?.mns, currentDefaultFormatDetails.time_zone)
                .format("MMM")
                .valueOf()
            : null
        );

        count.push(e.count ? e.count : 0);
      });
    }
    return { labelKey, count };
  }, [isLoading, isFetched, isFetching]);

  return (
    <div style={{ height: "250px", paddingTop: "9px" }}>
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: {
              title: {
                display: false,
              },
            },
            yAxes: {
              title: {
                display: false,
              },
            },
          },
          plugins: {
            datalabels: {
              display: true,
              color: "#fff",
            },
            legend: {
              display: false,
            },

            title: {
              display: false,
            },
          },
        }}
        data={{
          labels: [...labelKey],
          datasets: [
            {
              data: [...count],
              borderRadius: 9,
              borderColor: "rgba(81, 107, 235, 1)",
              backgtoundColor: "rgba(81, 107, 235, 1)",
              fill: false,
            },
          ],
        }}
      />
    </div>
  );
}
