import React from "react";

function CallRecordingSvg({ width = "22", height = "22" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1521 8.52285C15.1162 8.52285 15.8979 7.74123 15.8979 6.77705C15.8979 5.81287 15.1162 5.03125 14.1521 5.03125C13.1879 5.03125 12.4062 5.81287 12.4062 6.77705C12.4062 7.74123 13.1879 8.52285 14.1521 8.52285Z"
        fill="#516BEB"
      />
      <path
        d="M14.1549 0.93457C10.9334 0.93457 8.3125 3.55544 8.3125 6.77695C8.3125 9.9982 10.9334 12.6191 14.1549 12.6191C17.3764 12.6191 19.997 9.9982 19.997 6.77695C19.997 3.55544 17.3764 0.93457 14.1549 0.93457ZM14.1549 10.9367C11.861 10.9367 9.99485 9.07052 9.99485 6.77695C9.99485 4.48312 11.861 2.61692 14.1549 2.61692C16.4487 2.61692 18.3146 4.48312 18.3146 6.77695C18.3149 9.07079 16.4487 10.9367 14.1549 10.9367Z"
        fill="#516BEB"
      />
      <path
        d="M16.6904 15.5781L14.2505 15.2995C13.6645 15.2323 13.0881 15.434 12.6751 15.847L10.9075 17.6146C8.18901 16.2313 5.96039 14.0123 4.57712 11.2841L6.35424 9.50699C6.76731 9.09393 6.96903 8.51756 6.90179 7.93158L6.62321 5.51083C6.50794 4.54061 5.69142 3.81055 4.7116 3.81055H3.04975C1.96426 3.81055 1.06129 4.71352 1.12853 5.79902C1.63765 14.0027 8.19861 20.5541 16.3926 21.0632C17.4781 21.1304 18.3811 20.2274 18.3811 19.142V17.4801C18.3907 16.5099 17.6606 15.6933 16.6904 15.5781V15.5781Z"
        fill="#516BEB"
      />
    </svg>
  );
}

export default CallRecordingSvg;
