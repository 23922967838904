import React from "react";

function NextActionIcon({ size = 22, color = "#516BEB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.33398 0.916504C7.84024 0.916504 8.25065 1.32691 8.25065 1.83317V2.74984H13.7507V1.83317C13.7507 1.32691 14.161 0.916504 14.6673 0.916504C15.1736 0.916504 15.584 1.32691 15.584 1.83317V2.74984H17.4173C18.9361 2.74984 20.1673 3.98106 20.1673 5.49984V11.9165V18.3332C20.1673 19.852 18.9361 21.0832 17.4173 21.0832H11.0007H4.58398C3.06521 21.0832 1.83398 19.852 1.83398 18.3332V5.49984C1.83398 3.98106 3.06521 2.74984 4.58398 2.74984H6.41732V1.83317C6.41732 1.32691 6.82773 0.916504 7.33398 0.916504ZM13.7507 4.58317V5.49984C13.7507 6.00609 14.161 6.4165 14.6673 6.4165C15.1736 6.4165 15.584 6.00609 15.584 5.49984V4.58317H17.4173C17.9236 4.58317 18.334 4.99358 18.334 5.49984V8.24984H3.66732V5.49984C3.66732 4.99358 4.07773 4.58317 4.58398 4.58317H6.41732V5.49984C6.41732 6.00609 6.82773 6.4165 7.33398 6.4165C7.84024 6.4165 8.25065 6.00609 8.25065 5.49984V4.58317H13.7507ZM12.8338 10.0832C13.0683 10.0832 13.3032 10.1727 13.4822 10.3517L14.3988 11.2683C14.7568 11.6263 14.7568 12.2067 14.3988 12.5647C14.0409 12.9226 13.4604 12.9226 13.1025 12.5647L12.1858 11.648C11.8279 11.2901 11.8279 10.7096 12.1858 10.3517C12.3647 10.1727 12.5993 10.0832 12.8338 10.0832ZM11.8157 12.02C11.4577 11.662 10.8773 11.662 10.5193 12.02L7.60247 14.9368C7.43056 15.1087 7.33398 15.3419 7.33398 15.585V16.5017C7.33398 17.0079 7.74439 17.4183 8.25065 17.4183H9.16732C9.41042 17.4183 9.64362 17.3217 9.81549 17.1498L12.7323 14.233C13.0903 13.8751 13.0903 13.2946 12.7323 12.9367L11.8157 12.02Z"
        fill={color}
      />
    </svg>
  );
}

export default NextActionIcon;
