import React from "react";
import CardLayout from "../Card";
import { StyledBody, StyledAction } from "baseui/card";
import ReactCircle from "../ReactCircle/ReactCircle";
import { Paragraph2 } from "baseui/typography";

const PromiseKept = () => {
  return (
    <CardLayout>
      <Paragraph2
        style={{
          fontSize: "10px",
          lineHeight: "12px",
          textAlign: "center",
          fontWeight: "700",
          color: "#666666",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        Promise Kept
      </Paragraph2>
      <div style={{ padding: "3px" }}>
        <ReactCircle percentage={60} />
      </div>
    </CardLayout>
  );
};

export default PromiseKept;
