import React, { useContext, useEffect, useState } from "react";
import { Modal } from "baseui/modal";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TextArea } from "../TextArea";
import workflowModal from "../../assets/img/workflow-modal.png";
import { SelectBox } from "../SelectBox";
import { ParagraphLarge } from "baseui/typography";
import { TextButton, KIND, SIZE } from "../TextButton";
import getSelectValues from "../../utils/getSelectValues";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import setSelectValues from "../../utils/setSelectValues";
import { refetchActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { CUSTOMER_OVERVIEW } from "../../constants";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";

const CustomerStatusModal = ({
  setCustomerStatus,
  customerStatus,
  service,
  currentOrganization,
  getParams,
  customerStatusData,
}) => {
  const queryClient = useQueryClient();

  let maxyfiContextValue = useContext(MaxyfiContext);
  let dispatch = useDispatch();

  let {
    users,
    referenceData,
    restrictions,
    space,
    rdRefetch,
    refetch,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const intl = useIntl();

  function close() {
    setCustomerStatus(false);
  }
  //   const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let { customer_status } = watch();

  const putCustomerStatusService = useMutation(
    (data) =>
      service({
        organization: currentOrganization,
        customerId: getParams,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (
          customer_status &&
          customer_status[0] &&
          customer_status[0]?.isCreatable
        ) {
          refetch({
            setLoader: false,
            currentOrganization: currentOrganization,
          });
        }
        reset();
        dispatch(refetchActions());
        setCustomerStatus(!customerStatus);
        queryClient.invalidateQueries(
          `${CUSTOMER_OVERVIEW}-${currentOrganization}`
        );
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      customer_status: getSelectValues(data.customer_status),
      customer_comment: data.comments,
    };

    await putCustomerStatusService.mutateAsync(values);
  };

  let customerStatusList = referenceData["customer_status_list"];

  useEffect(() => {
    if (customerStatusData) {
      let customerStatus = customerStatusData?.customerStatus?.split("(");
      let customerTwoStatus = customerStatus && customerStatus[1]?.split(")");
      //

      let toLower =
        customerTwoStatus &&
        customerTwoStatus[0] &&
        customerTwoStatus[0]?.toLowerCase();

      if (
        customerTwoStatus &&
        customerTwoStatus[0] &&
        customerTwoStatus[0]?.toLowerCase() === "auto"
      ) {
        let rdAutoValue = customerStatusList?.filter(
          (e) => e?.id === toLower
          // customerTwoStatus[0] &&
          // customerTwoStatus[0]?.toLowerCase()
        );

        setValue("customer_status", rdAutoValue);
      } else {
        setValue(
          "customer_status",
          setSelectValues(customerStatusData.customerStatus)
        );
      }

      setValue("comments", customerStatusData.customerStatusComment);
    }
  }, [customerStatusData]);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  let filterData = currentDefaultFormatDetails?.csm?.filter(
    (e) => e.enb === true
  );
  return (
    <>
      <Modal
        onClose={close}
        isOpen={customerStatus}
        // size={SIZE.auto}
        closeable={false}
        overrides={{
          Dialog: {
            style: ({ $theme }) => ({
              borderBottomLeftRadius: "5px",
              borderBottomRightRadius: "5px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              width: "340px",
            }),
          },
        }}
      >
        <div style={{ width: "" }}>
          <div
            style={{
              backgroundColor: "#516BEB30",
              textAlign: "center",
              borderRadius: "10px 10px 50px 50px",
              height: "182px",
              paddingTop: "20px",
            }}
          >
            <img src={workflowModal} alt="" />
          </div>
          <form
            style={{
              textAlign: "center",
              marginTop: "15px",
              padding: "10px 20px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Select the Consumer Status
            </ParagraphLarge>
            <Controller
              name="customer_status"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  name={field.name}
                  creatable={creatablePermission ? true : false}
                  clearable={false}
                  // disabled={isDisabled}
                  requiredAstric={true}
                  //   onInputChange={(evt) => {
                  // fetchWorkflow({ name: evt.target.value });
                  //   }}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "customer_status",
                  })}
                  placeholder={intl.formatMessage({
                    id: "customer_status",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={filterData.map((e) => {
                    return {
                      id: e.nam,
                      label: e.nam,
                    };
                  })}
                  //   options={workflowList.map((e) => ({
                  //     id: e._id,
                  //     label: e.name,
                  //   }))}
                />
              )}
            />

            <ParagraphLarge $style={{ marginBottom: "20px" }}>
              Consumer Comments
            </ParagraphLarge>
            <div>
              <Controller
                control={control}
                name="comments"
                render={({ field }) => (
                  <TextArea
                    {...field}
                    name={field.name}
                    label={intl.formatMessage({
                      id: "comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comments",
                    })}
                    size={SIZE.mini}
                    value={field.value}
                  />
                )}
              />
            </div>

            <div
              style={{
                marginTop: "15px",
                textAlign: "center",
                // marginBottom: "25px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px 20px",
              }}
            >
              <div style={{ height: "", width: "96px" }}>
                <TextButton
                  type="button"
                  fullWidth
                  size={SIZE.compact}
                  kind={KIND.tertiary}
                  // onClick={close}
                  onClick={() => {
                    reset();
                    close();
                    // dispatch(workflowAddModal(false));
                  }}
                >
                  Cancel
                </TextButton>
              </div>
              <div style={{ height: "", width: "96px" }}>
                <TextButton
                  type="submit"
                  fullWidth
                  size={SIZE.compact}
                  kind={KIND.primary}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Apply
                </TextButton>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CustomerStatusModal;
