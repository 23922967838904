import React, { useState } from "react";
import CollapseDownArrow from "../../../assets/img/svg/CollapseDownArrow";
import CollapseUpArrow from "../../../assets/img/svg/CollapseUpArrow";
import {
  Label1,
  Paragraph2,
  LabelMedium,
  ParagraphMedium,
  LabelXSmall,
} from "baseui/typography";
import { IconButton, KIND } from "../../../components/IconButton";
import Warning from "../../../assets/img/svg/Warning";
import DairyNoteAlertWarning from "../../../assets/img/svg/CustomerOverViewIcon/DairyNoteAlertWarning";
import DairyNoteInfo from "../../../assets/img/svg/DairyNoteInfo";
import DairyNoteDanger from "../../../assets/img/svg/DairyNoteDanger";
import { useDispatch, useSelector } from "react-redux";
import { expandClick } from "../../../redux/actions";
import { getCustomerDetails } from "../../../services";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import DairyNotesAlertCard from "./Actions/DairyNoteAlertCard";

const DairyNotesAlert = ({
  alertPopUpDatas,
  editDairyNote,
  entity,
  getParams,
  dismissExit,
}) => {
  const collapseButton = useSelector(
    (s) => s.customerOverviewReducer.noteAlert
  );

  return (
    <>
      {alertPopUpDatas
        ?.slice(
          0,
          collapseButton
            ? // downArrow
              alertPopUpDatas.length
            : 1
        )
        .map((e, i) => {
          return (
            <DairyNotesAlertCard
              e={e}
              i={i}
              editDairyNote={editDairyNote}
              getParams={getParams}
              entity={entity}
              alertPopUpDatas={alertPopUpDatas}
              dismissExit={dismissExit}
            />
          );
        })}
    </>
  );
};
export default DairyNotesAlert;
