import {
  SET_NOTIFICATION_TAB,
  SET_NOTIFICATION_INFO_UNREAD_ONLY,
  SET_NOTIFICATION_INFO_TYPE,
  SET_NOTIFICATION_CALENDAR_INCOMPLETE_ONLY,
  SET_NOTIFICATION_CALENDAR_DATE,
} from "./action";

const initialState = {
  activeKey: "0",
  information: {
    unreadOnly: true,
    type: [{ label: "All", id: "ALL" }],
  },
  calendar: {
    incompleteOnly: true,
    date: {
      date_range_type: "DATE",
      date: null,
      idx: 1,
    },
  },
};

const notification = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATION_TAB: {
      return {
        ...state,
        activeKey: payload,
      };
    }

    case SET_NOTIFICATION_INFO_UNREAD_ONLY: {
      return {
        ...state,
        information: {
          ...state.information,
          unreadOnly: payload,
        },
      };
    }

    case SET_NOTIFICATION_INFO_TYPE: {
      return {
        ...state,
        information: {
          ...state.information,
          type: payload,
        },
      };
    }

    case SET_NOTIFICATION_CALENDAR_INCOMPLETE_ONLY: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          incompleteOnly: payload,
        },
      };
    }

    case SET_NOTIFICATION_CALENDAR_DATE: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          date: payload,
        },
      };
    }

    default:
      return { ...initialState };
  }
};

export default notification;
