import React from "react";
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ReactCircle = ({ percentage = 0 }) => {
  return (
    <div style={{ position: "unset" }}>
      <CircleChild>
        <CircularProgressbarWithChildren
          value={percentage}
          styles={buildStyles({
            rotation: 0.4 + (1 - percentage / 100) / 2,
            pathColor: "#516BEB",
            trailColor: "#A8B5F5",
          })}
          strokeWidth={20}
        >
          <div style={{ fontSize: 10 }}>
            <strong>{`${percentage}%`}</strong>
          </div>
        </CircularProgressbarWithChildren>
      </CircleChild>
    </div>
  );
};

const CircleChild = (props) => {
  return (
    <div>
      <div>{props.children}</div>
    </div>
  );
};

export default ReactCircle;
