import React from "react";

const SearchOutline = ({ size = 18, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3271 10.5354C9.59271 11.14 8.65203 11.5031 7.62655 11.5031C5.27848 11.5031 3.375 9.59961 3.375 7.25155C3.375 4.90348 5.27848 3 7.62655 3C9.97461 3 11.8781 4.90348 11.8781 7.25155C11.8781 8.27703 11.515 9.21771 10.9104 9.95207L15.2131 14.2547C15.3742 14.4158 15.3742 14.677 15.2131 14.8381C15.052 14.9992 14.7908 14.9992 14.6297 14.8381L10.3271 10.5354ZM11.0531 7.25155C11.0531 9.14398 9.51898 10.6781 7.62655 10.6781C5.73412 10.6781 4.2 9.14398 4.2 7.25155C4.2 5.35912 5.73412 3.825 7.62655 3.825C9.51898 3.825 11.0531 5.35912 11.0531 7.25155Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchOutline;
