function Sms({ color = "#516BEB", width = 22, height = 22 }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2 0H1.8C0.81 0 0.00899999 0.81 0.00899999 1.8L0 18L3.6 14.4H16.2C17.19 14.4 18 13.59 18 12.6V1.8C18 0.81 17.19 0 16.2 0ZM4.5 6.3H13.5C13.995 6.3 14.4 6.705 14.4 7.2C14.4 7.695 13.995 8.1 13.5 8.1H4.5C4.005 8.1 3.6 7.695 3.6 7.2C3.6 6.705 4.005 6.3 4.5 6.3ZM9.9 10.8H4.5C4.005 10.8 3.6 10.395 3.6 9.9C3.6 9.405 4.005 9 4.5 9H9.9C10.395 9 10.8 9.405 10.8 9.9C10.8 10.395 10.395 10.8 9.9 10.8ZM13.5 5.4H4.5C4.005 5.4 3.6 4.995 3.6 4.5C3.6 4.005 4.005 3.6 4.5 3.6H13.5C13.995 3.6 14.4 4.005 14.4 4.5C14.4 4.995 13.995 5.4 13.5 5.4Z"
          fill={color}
        />
      </svg>
    </>
  );
}

export default Sms;
