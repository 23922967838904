import { SYNC_IS_ACTIVE, SYNC_INITIAL_SYNC } from "./action";

const initialState = {
  isActive: false,
};

export const organizationListReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case SYNC_IS_ACTIVE:
      return { ...state, isActive: payload };

    case SYNC_INITIAL_SYNC:
      return {
        ...state,
        ...initialState,
      };

    default:
      return { ...initialState };
  }
};
