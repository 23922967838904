import React, { useContext, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextBox } from "../../../../components/TextBox";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { SelectBox } from "../../../../components/SelectBox";
import { EMAIL, LETTER, SMS } from "../../../../constants";
import { TextArea } from "../../../../components/TextArea";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { SIZE } from "baseui/input";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TimePicker } from "baseui/timepicker";
import { Controller } from "react-hook-form";
import { TimePickerAction } from "../../../../components/TimePicker/TimePicker";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getCustomerContactDetail,
  getCommunicationTemplateList,
} from "../../../../services";
import moment from "moment";
import setSelectValues from "../../../../utils/setSelectValues";
import { useSelector } from "react-redux";

const CallBack = (props) => {
  let { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  let intl = useIntl();
  let { id } = useParams();

  let {
    actioWatch,
    control,
    errors,
    callBackActin,
    watch,
    settingData,
    setValue,
    defaultConfiguration,
  } = props;

  useEffect(() => {
    setValue("callback_date", new Date(moment().add(1, "days")));
  }, []);

  const { customerConatactDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  // Finding Email Value Length and Phone Code

  let [confirmationTemplates, setConfirmationTemplates] = useState([]);
  let [followUpTemplates, setFollowUpTemplates] = useState([]);

  let [is_send_confirmation_message, is_send_action_follow_up] = actioWatch;

  let EmailUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_email === true);
  let PhoneUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_phone === true);

  let type = referenceData["template_type"];

  let mappinTypeValue =
    referenceData && referenceData["template_type"]
      ? referenceData["template_type"]
          .filter(({ id }) => id != "letter")
          .map((e) => {
            if (e.id == "email" && !EmailUser) {
              return { ...e, disabled: true };
            } else if (e.id === "sms" && !PhoneUser) {
              return { ...e, disabled: true };
            } else if (
              e.id === "sms" &&
              !currentDefaultFormatDetails.is_sms_configured
            ) {
              return { ...e, disabled: true };
            } else if (
              e.id === "whatsapp" &&
              !currentDefaultFormatDetails?.is_whatsapp_configured
            ) {
              return { ...e, disabled: true };
            }
            return e;
          })
      : [];

  let [confirmationMessageType, followUpType] = watch([
    "confirmation_message_type",
    "action_follow_up_type",
  ]);

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags,
        type,
      },
      organization: currentOrganization,
      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: ["CALL_BACK"],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  useEffect(() => {
    if (followUpType && followUpType[0] && followUpType[0].id) {
      setFollowUpTemplates([]);
      getTemplates({
        tags: ["CALL_BACK"],
        type: followUpType[0].id,
        setter: setFollowUpTemplates,
      });
    }
  }, [followUpType]);

  // useEffect(() => {
  //   let EmailUser =
  //     customerConatactDetails &&
  //     Array.isArray(customerConatactDetails) &&
  //     customerConatactDetails.some((e) => e.is_valid_email === true);
  //   let PhoneUser =
  //     customerConatactDetails &&
  //     Array.isArray(customerConatactDetails) &&
  //     customerConatactDetails.some((e) => e.is_valid_phone === true);

  //   const paymentPlanConfig = defaultConfiguration?.messages;

  //   setValue("is_hold_workflow", defaultConfiguration.is_hold_workflow);

  //   paymentPlanConfig.reverse().map((e) => {
  //     if (
  //       (e.type === "email" && EmailUser) ||
  //       (e.type === "sms" && PhoneUser) ||
  //       (e.type === "whatsapp" && PhoneUser)
  //     ) {
  //
  //       if (e.confirmation_template.length > 0) {
  //         setValue("is_send_confirmation_message", true);
  //         setValue("confirmation_message_type", setSelectValues(e.type));
  //         setValue(
  //           "confirmation_message_template_id",
  //           setSelectValues(e.confirmation_template)
  //         );
  //       }
  //       if (e.follow_up_template.length > 0) {
  //         setValue("is_send_action_follow_up", true);
  //         setValue("action_follow_up_type", setSelectValues(e.type));
  //         setValue(
  //           "action_follow_up_template_id",
  //           setSelectValues(e.follow_up_template)
  //         );
  //       }
  //     }
  //   });
  // }, [customerConatactDetails]);

  return (
    <>
      <div className="cp-action-message-confirmation">
        <div className="cp-message-confirmation-type cal--select">
          <Controller
            name="callback_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                size={SIZE.mini}
                label={intl.formatMessage({
                  id: "call_back_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        <div className="cp-action-callback">
          <Controller
            name="callback_time"
            control={control}
            render={({ field }) => (
              <SelectBox
                clearable={false}
                style={{ marginTop: "0px" }}
                {...field}
                name={field.name}
                size={SIZE.mini}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "call_back_time",
                })}
                placeholder={intl.formatMessage({
                  id: "call_back_time",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["time"]}
              />
            )}
          />

          {/* <TimePickerAction /> */}
        </div>
      </div>
      <div style={{ paddingTop: "5px" }}>
        <Controller
          defaultValues={false}
          name="is_hold_workflow"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb
              {...field}
              // disabled={true}
              checked={field.value}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <FormattedMessage id="hold_workflow_till_call_back_date">
                hold_workflow_till_call_back_date
              </FormattedMessage>
            </CheckBoxBaseweb>
          )}
        />
      </div>

      <div className="cp-message-confirmation-checkbox">
        <Controller
          defaultValues={false}
          name="is_send_confirmation_message"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb
              {...field}
              checked={field.value}
              labelPlacement={LABEL_PLACEMENT.right}
              // disabled={!EmailUser && !PhoneUser}
            >
              <FormattedMessage id="send_confirmation_message">
                send_confirmation_message
              </FormattedMessage>
            </CheckBoxBaseweb>
          )}
        />
      </div>

      {is_send_confirmation_message ? (
        <div className="cp-action-message-confirmation">
          <>
            <div className="cp-message-confirmation-type">
              <Controller
                defaultValues={[]}
                name="confirmation_message_type"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comm_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comm_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                        setValue("confirmation_message_template_id", []);
                      }
                      field.onChange(e.value);
                    }}
                    // options={
                    //   referenceData["template_type"].filter(
                    //     (e) => e.id !== LETTER
                    //   ) || []
                    // }
                    options={mappinTypeValue}
                  />
                )}
              />
            </div>
            {/* TODO: TEMPLATE TYPE */}
            <div className="cp-action-promise-pay-con">
              <Controller
                name="confirmation_message_template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "callback_confirmation",
                    })}
                    placeholder={intl.formatMessage({
                      id: "callback_confirmation",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={confirmationTemplates || []}
                  />
                )}
              />
            </div>
          </>
        </div>
      ) : (
        ""
      )}

      <div className="cp-message-confirmation-checkbox">
        <Controller
          defaultValues={false}
          name="is_send_action_follow_up"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb
              {...field}
              checked={field.value}
              labelPlacement={LABEL_PLACEMENT.right}
              // disabled={!EmailUser && !PhoneUser}
            >
              <FormattedMessage id="send_callback_follow_up_message">
                send_callback_follow_up_message
              </FormattedMessage>
            </CheckBoxBaseweb>
          )}
        />
      </div>

      {is_send_action_follow_up ? (
        <div className="cp-action-message-confirmation">
          <>
            <div className="cp-message-confirmation-type">
              <Controller
                name="action_follow_up_type"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comm_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comm_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                        setValue("action_follow_up_template_id", []);
                      }
                      field.onChange(e.value);
                    }}
                    // options={
                    //   referenceData["template_type"].filter(
                    //     (e) => e.id !== LETTER
                    //   ) || []
                    // }
                    options={mappinTypeValue}
                  />
                )}
              />
            </div>
            <div className="cp-action-promise-pay-con">
              <Controller
                defaultValues={[]}
                name="action_follow_up_template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "callback_follow_up_message",
                    })}
                    placeholder={intl.formatMessage({
                      id: "callback_follow_up_message",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={followUpTemplates || []}
                  />
                )}
              />
            </div>
          </>
        </div>
      ) : (
        ""
      )}
      <div className="cp-action-promise_test-area">
        <Controller
          name="comment"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              clearable={false}
              error={errors[field.name] && errors[field.name].message}
              name={field.name}
              label={intl.formatMessage({
                id: "user_comments",
              })}
              placeholder={intl.formatMessage({
                id: "user_comments",
              })}
              value={field.value}
            />
          )}
        />
      </div>
    </>
  );
};

export default CallBack;
