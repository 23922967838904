import React, { useContext, useState } from "react";
import { Typography } from "../../../../components_v2";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { FormattedMessage, useIntl } from "react-intl";
import { alloweSpecialCharacter } from "../../../../utils/regexConfig";
import { TextBox } from "../../../../components/TextBox";
import * as TextBoxSize from "./../../../../components/TextBox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { SelectBox } from "../../../../components/SelectBox";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { KIND, TextButton } from "../../../../components/TextButton";
import getSelectValues from "../../../../utils/getSelectValues";
import setSelectValues from "../../../../utils/setSelectValues";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { addAttorney, updateAttorney } from "../../../../services";
import _ from "lodash";

const ADD = "ADD";
const EDIT = "EDIT";

const CounselForm = ({
  isOpposingCounsel,
  handleResetForm,
  type = ADD,
  defaultValues,
}) => {
  const intl = useIntl();

  let { currentOrganization, referenceData, refetch, defaultData } =
    useContext(MaxyfiContext);

  const schema = Yup.object().shape({
    attorney_name: Yup.string()
      .required("Attorney Name is Required")
      .max(100, "Character Length can't be more than 100")
      .matches(
        alloweSpecialCharacter,
        "Invalid input - Only Alphabets ' , . - and _ allowed."
      ),
    firm_name: Yup.string()
      .nullable()
      .max(100, "Character Length can't be more than 100")
      .matches(alloweSpecialCharacter, {
        message: "Invalid input - Only Alphabets ' , . - and _ allowed.",
        excludeEmptyString: true,
      }),
    email: Yup.string().email("Invalid email format").nullable(),
    phone_number: Yup.string()
      .nullable()
      .optional()
      .test("is-ten-digits", "Phone number must be 10 digits", (value) => {
        if (value && value.trim().length > 0) {
          return /^\d{10}$/.test(value.trim());
        }
        return true; // Return true if the field is empty or undefined
      }),
  });

  const createCouncel = useMutation(
    (data) =>
      type === EDIT && defaultValues && defaultValues._id
        ? updateAttorney({
            ...data,
            organization: currentOrganization,
            counselId: defaultValues._id,
          })
        : addAttorney({
            ...data,
            organization: currentOrganization,
          }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        reset();
        handleResetForm();
        refetch({ currentOrganization });
      },
    }
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setvalue,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      ...(type === EDIT && defaultValues
        ? {
            attorney_name: _.get(defaultValues, "atrn", ""),
            firm_name: _.get(defaultValues, "firn", ""),
            country_code: setSelectValues(_.get(defaultValues, "ph.coc", [])),
            phone_number: _.get(defaultValues, "ph.val", ""),
            address_line1: _.get(defaultValues, "addr.li1", ""),
            address_line2: _.get(defaultValues, "addr.li2", ""),
            city: _.get(defaultValues, "addr.cit", ""),
            state: setSelectValues(_.get(defaultValues, "addr.sta", [])),
            country: setSelectValues(_.get(defaultValues, "addr.cou", [])),
            zip_code: _.get(defaultValues, "addr.zip", ""),
            email: _.get(defaultValues, "em.val", ""),
          }
        : { country_code: setSelectValues(defaultData?.phone_code) }),
    },
  });

  const onSubmit = async (data) => {
    const {
      attorney_name = "",
      firm_name,
      country_code = [],
      phone_number,
      address_line1,
      address_line2,
      city,
      state = [],
      country = [],
      zip_code,
      email,
    } = data;

    let values = {
      atrn: _.get(data, "attorney_name", ""),
      ...(firm_name ? { firn: firm_name } : {}),
      ...(phone_number
        ? {
            ph: {
              coc: getSelectValues(country_code, false),
              val: phone_number,
            },
          }
        : {}),
      ...(address_line1 ||
      address_line2 ||
      city ||
      (state && state[0]) ||
      (country && country[0]) ||
      zip_code
        ? {
            addr: {
              ...(address_line1 ? { li1: address_line1 } : {}),
              ...(address_line2 ? { li2: address_line2 } : {}),
              ...(city ? { cit: city } : {}),
              ...(state && state[0] ? { sta: getSelectValues(state) } : {}),
              ...(country && country[0]
                ? { cou: getSelectValues(country) }
                : {}),
              ...(zip_code ? { zip: zip_code } : {}),
            },
          }
        : {}),
      ...(email
        ? {
            em: {
              val: email,
            },
          }
        : {}),
    };

    await createCouncel.mutateAsync({
      ...values,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="counsel-form-container">
        <Typography type="h3" style={{ padding: "0 0 10px" }}>
          {isOpposingCounsel ? "Opposing Counsel" : "Our Counsel"}
        </Typography>
        <div className="row gap-10">
          <Controller
            name="attorney_name"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <TextBox
                {...field}
                requiredAstric={true}
                size={TextBoxSize.SIZE.mini}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "attorney_name",
                })}
                placeholder={intl.formatMessage({
                  id: "attorney_name",
                })}
                value={field.value}
              />
            )}
          />
          <Controller
            name="firm_name"
            control={control}
            render={({ field }) => (
              <TextBox
                {...field}
                size={TextBoxSize.SIZE.mini}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "firm_name",
                })}
                placeholder={intl.formatMessage({
                  id: "firm_name",
                })}
                value={field.value}
              />
            )}
          />
        </div>
        <div className="row gap-10">
          <div className="row gap-10">
            <div style={{ flex: 1 }}>
              <Controller
                name="country_code"
                control={control}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    size={TextBoxSize.SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "code",
                    })}
                    placeholder={intl.formatMessage({
                      id: "code",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      referenceData &&
                      Array.isArray(referenceData["phone_code"])
                        ? referenceData["phone_code"]
                        : []
                    }
                  />
                )}
              />
            </div>
            <div style={{ flex: 2 }}>
              <Controller
                name="phone_number"
                control={control}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    size={TextBoxSize.SIZE.mini}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "phone",
                    })}
                    placeholder={intl.formatMessage({
                      id: "phone",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextBox
                  {...field}
                  size={TextBoxSize.SIZE.mini}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "email",
                  })}
                  placeholder={intl.formatMessage({
                    id: "email",
                  })}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <Controller
          name="address_line1"
          control={control}
          render={({ field }) => (
            <TextBox
              {...field}
              name={field.name}
              size={TextBoxSize.SIZE.mini}
              label={intl.formatMessage({
                id: "address_line_1",
              })}
              placeholder={intl.formatMessage({
                id: "address_line_1",
              })}
              value={field.value}
            />
          )}
        />
        <Controller
          name="address_line2"
          control={control}
          render={({ field }) => (
            <TextBox
              {...field}
              name={field.name}
              size={TextBoxSize.SIZE.mini}
              label={intl.formatMessage({
                id: "address_line_2",
              })}
              placeholder={intl.formatMessage({
                id: "address_line_2",
              })}
              value={field.value}
            />
          )}
        />
        <div className="row gap-10">
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextBox
                {...field}
                name={field.name}
                size={TextBoxSize.SIZE.mini}
                label={intl.formatMessage({
                  id: "city",
                })}
                placeholder={intl.formatMessage({
                  id: "city",
                })}
                value={field.value}
              />
            )}
          />
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <>
                <SelectBox
                  creatable
                  {...field}
                  name={field.name}
                  size={TextBoxSize.SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "state",
                  })}
                  placeholder={intl.formatMessage({
                    id: "state",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={
                    referenceData && Array.isArray(referenceData["state_list"])
                      ? referenceData["state_list"]
                      : []
                  }
                />
              </>
            )}
          />
        </div>
        <div className="row gap-10">
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <>
                <SelectBox
                  {...field}
                  name={field.name}
                  size={TextBoxSize.SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "country",
                  })}
                  placeholder={intl.formatMessage({
                    id: "country",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={
                    referenceData && Array.isArray(referenceData["country"])
                      ? referenceData["country"]
                      : []
                  }
                />
              </>
            )}
          />
          <Controller
            name="zip_code"
            control={control}
            render={({ field }) => (
              <TextBox
                {...field}
                name={field.name}
                size={TextBoxSize.SIZE.mini}
                type="number"
                label={intl.formatMessage({
                  id: "zip_code",
                })}
                placeholder={intl.formatMessage({
                  id: "zip_code",
                })}
                value={field.value}
              />
            )}
          />
        </div>
        <div style={{ textAlign: "end" }}>
          <TextButton
            kind={KIND.tertiary}
            size={TextBoxSize.SIZE.mini}
            type="button"
            onClick={() => handleResetForm(false)}
          >
            Cancel
          </TextButton>

          <TextButton
            type="submit"
            kind={KIND.primary}
            size={TextBoxSize.SIZE.mini}
            // disabled={isDisable || isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default CounselForm;
