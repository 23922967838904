import React from "react";

const DocDownArSvg = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00258 8.60953C6.15536 8.48731 6.32341 8.42237 6.50675 8.41474C6.69008 8.4071 6.8505 8.47203 6.988 8.60953L11.0213 12.6429L15.0776 8.58661C15.1998 8.46439 15.364 8.4071 15.5703 8.41474C15.7765 8.42238 15.9408 8.48731 16.063 8.60953C16.2158 8.76231 16.2883 8.92654 16.2807 9.10224C16.2731 9.27793 16.2005 9.43453 16.063 9.57203L11.5026 14.1324C11.4262 14.2088 11.3498 14.2623 11.2734 14.2929C11.197 14.3234 11.113 14.3387 11.0213 14.3387C10.9297 14.3387 10.8456 14.3234 10.7692 14.2929C10.6929 14.2623 10.6165 14.2088 10.5401 14.1324L6.00258 9.59494C5.86508 9.45744 5.80015 9.29703 5.80779 9.11369C5.81543 8.93036 5.88036 8.76231 6.00258 8.60953Z"
        fill="#333860"
      />
    </svg>
  );
};

export default DocDownArSvg;
