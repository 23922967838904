import { getGridStructure, getView } from "../services";

let fieldType = {
  AMOUNT: "CURRENCY",
  DATE: "DATE",
};

async function setTableGridStructure({
  menu = null,
  currentOrganization = null,
  setGridStructureAction = null,
  setViewAction = null,
  setGridAndViewAction = null,
  userPersonalization = {},
  dispatch = null,
  isSetGridAndView = false,
  customFields = [],
}) {
  await getGridStructure({
    organization: currentOrganization,
    menu,
  }).then(async (gridStructureRes) => {
    let gridStructureData = gridStructureRes.data.doc;
    let {
      default_view,
      pinned_view = [],
      ...gridStructure
    } = gridStructureData;

    if (customFields && Array.isArray(customFields)) {
      const dataType = {
        AMOUNT: "CURRENCY",
        DATE: "DATEORGTZ",
        HYPERLINK: "ALPHANUMERIC",
        TEXT: "ALPHANUMERIC",
        LONG_TEXT: "ALPHANUMERIC",
        NUMBER: "ALPHANUMERIC",
      };

      let customFieldStructure = customFields.map((field, idx) => {
        const { path, name, data_type } = field;

        return {
          accessor: `custom_field.${path}`,
          alignment: "left",
          cell_type: dataType[data_type] ? dataType[data_type] : "ALPHANUMERIC",
          col_id: "col_cf_" + idx,
          filter_by: true,
          header_alignment: "left",
          is_hidden: true,
          literal_id: name,
          order:
            gridStructure &&
            gridStructure.table &&
            Array.isArray(gridStructure.table)
              ? gridStructure.table.length + idx + 1
              : idx + 1,
          sort_by: false,
          type:
            fieldType && fieldType[data_type] ? fieldType[data_type] : "STRING",
          width: 150,
        };
      });

      gridStructure.table = [...gridStructure.table, ...customFieldStructure];
    }

    if (!isSetGridAndView && default_view && gridStructure) {
      dispatch(setGridStructureAction(gridStructure));

      if (default_view) {
        dispatch(setViewAction(default_view));
      }
    } else {
      dispatch(
        setGridAndViewAction({
          grid_structure: gridStructure,
          default_view,
          pinned_view,
        })
      );
    }
  });
}

export default setTableGridStructure;
