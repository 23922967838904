import React from "react";

const AlertCircleSvg = ({ color = "#FD372A", size = 22 }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 11.548C8.17162 11.548 8.31549 11.49 8.43159 11.3739C8.54769 11.2578 8.60574 11.1139 8.60574 10.9423C8.60574 10.7706 8.54769 10.6268 8.43159 10.5107C8.31549 10.3946 8.17162 10.3365 7.99998 10.3365C7.82834 10.3365 7.68447 10.3946 7.56838 10.5107C7.45227 10.6268 7.39423 10.7706 7.39423 10.9423C7.39423 11.1139 7.45227 11.2578 7.56838 11.3739C7.68447 11.49 7.82834 11.548 7.99998 11.548ZM8.00023 8.80768C8.15968 8.80768 8.29324 8.75377 8.40093 8.64596C8.50862 8.53816 8.56246 8.40457 8.56246 8.24519V4.87016C8.56246 4.71079 8.50854 4.57721 8.40069 4.46939C8.29283 4.36158 8.15917 4.30768 7.99974 4.30768C7.84029 4.30768 7.70672 4.36158 7.59903 4.46939C7.49134 4.57721 7.4375 4.71079 7.4375 4.87016V8.24519C7.4375 8.40457 7.49143 8.53816 7.59928 8.64596C7.70714 8.75377 7.84079 8.80768 8.00023 8.80768ZM8.00124 15.125C7.01579 15.125 6.08951 14.938 5.22241 14.564C4.3553 14.19 3.60104 13.6824 2.95963 13.0413C2.3182 12.4001 1.81041 11.6462 1.43624 10.7795C1.06208 9.91277 0.875 8.98669 0.875 8.00124C0.875 7.01579 1.062 6.08951 1.436 5.22241C1.81 4.3553 2.31756 3.60104 2.95869 2.95963C3.59983 2.3182 4.35376 1.81041 5.22048 1.43624C6.08719 1.06208 7.01328 0.875 7.99873 0.875C8.98418 0.875 9.91045 1.062 10.7776 1.436C11.6447 1.81 12.3989 2.31756 13.0403 2.95869C13.6818 3.59983 14.1896 4.35376 14.5637 5.22048C14.9379 6.08719 15.125 7.01328 15.125 7.99873C15.125 8.98418 14.938 9.91045 14.564 10.7775C14.19 11.6447 13.6824 12.3989 13.0413 13.0403C12.4001 13.6818 11.6462 14.1896 10.7795 14.5637C9.91277 14.9379 8.98669 15.125 8.00124 15.125ZM7.99998 14C9.67498 14 11.0937 13.4187 12.2562 12.2562C13.4187 11.0937 14 9.67498 14 7.99998C14 6.32498 13.4187 4.90623 12.2562 3.74373C11.0937 2.58123 9.67498 1.99998 7.99998 1.99998C6.32498 1.99998 4.90623 2.58123 3.74373 3.74373C2.58123 4.90623 1.99998 6.32498 1.99998 7.99998C1.99998 9.67498 2.58123 11.0937 3.74373 12.2562C4.90623 13.4187 6.32498 14 7.99998 14Z"
        fill={color}
      />
    </svg>
  );
};

export default AlertCircleSvg;
