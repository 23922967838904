import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  MaxyfiContext,
  MaxyfiProvider,
} from "../../../providers/MaxyfiProvider";
import { Icon, RDText, Typography } from "../../../components_v2";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import TitleHead from "../../../components_v2/TitleHead";
import queryClient from "../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  DATE_ORG,
  GET_GROUPING_LIST_DATA,
} from "../../../constants";
import _ from "lodash";
import NumberFormtRB from "../../../containers/RevenueBillingContainer/RevenueBillingTabel/NumberFormtRB";
import { useMutation } from "react-query";
import {
  deleteClientGroupingFn,
  removerGroupingFn,
  updateGroupingFn,
} from "../../../services";
import { toast } from "react-toastify";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { KIND, TextButton } from "../../../components/TextButton";
import DateComponent from "../../../components_v2/DateComponent";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  MAINTAIN_PROCESS_GROUP,
  UNMERGE_CONSUMER_RECORDS,
} from "../../../providers/RBACProvider/permissionList";
import Loader from "../../../components/Loader";

const ProgressItem = ({
  item,
  checkedItems,
  setCheckedItems,
  deleteClient,
}) => {
  const handleProgressCheckboxChange = (id) => {
    if (checkedItems?.groupId === id) {
      setCheckedItems({
        ...checkedItems,
        groupId: "",
      });
    } else {
      setCheckedItems({
        ...checkedItems,
        groupId: id,
      });
    }
  };

  const handleConsumerCheckboxChange = (clientId, pgid) => {
    if (checkedItems?.individual?.includes(clientId)) {
      let filterExist = checkedItems?.individual?.filter((e) => e !== clientId);
      setCheckedItems({
        ...checkedItems,
        individual: [...filterExist],
        groupIdRef: "",
      });
    } else {
      setCheckedItems({
        ...checkedItems,
        individual: [...checkedItems.individual, clientId],
        groupIdRef: pgid,
      });
    }
  };

  return (
    <div>
      <ProgressName
        data={item}
        onProgressCheckboxChange={handleProgressCheckboxChange}
        checkedItems={checkedItems}
      />
      {_.get(item, "customers", []).map((customer) => (
        <ConsumerList
          key={customer._id}
          reference={customer.refernece}
          {...customer}
          checked={checkedItems[customer.id]}
          checkedItems={checkedItems}
          groupId={item?._id}
          onChange={() => handleConsumerCheckboxChange(customer._id, item._id)}
          deleteClient={() =>
            deleteClient.mutateAsync({ clientId: customer?._id })
          }
        />
      ))}
      <hr className="co_pr_grouping_bottom_line" />
    </div>
  );
};

const ConsumerList = ({
  cilent_reference,
  invoice,
  checked,
  onChange,
  business_unit,
  total_outstanding_invoice_amount,
  days_since_created,
  checkedItems,
  _id,
  groupId,
  deleteClient,
  ipgm,
  customer_auto_status,
}) => {
  let status = ["payment_plan", "active_disputes", "promise_in_place"];
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  return (
    <div
      className="co_process_group_body_wraper"
      style={{
        paddingLeft: `${
          _.get(currentDefaultFormatDetails, "cgs.ipg") ? "" : "12px"
        }`,
      }}
    >
      {_.get(currentDefaultFormatDetails, "cgs.ipg") ? (
        ""
      ) : (
        <div style={{ width: "28px", height: "18px", padding: "5px" }}></div>
      )}
      {currentDefaultFormatDetails &&
        currentDefaultFormatDetails?.cgs &&
        currentDefaultFormatDetails?.cgs?.ipg && (
          <div
            style={{
              borderRight: "1px solid #cdced9",
              padding: "7px 2px 0px 5px",
            }}
          >
            <CheckBoxBaseweb
              checked={
                checkedItems?.individual?.includes(_id) ||
                checkedItems.groupId === groupId
              }
              onChange={onChange}
              disabled={
                checkedItems.groupId === groupId ||
                status.includes(customer_auto_status)
              }
            />
          </div>
        )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          flex: "1",
          borderRight: "1px solid #cdced9",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            alignItems: "center",
          }}
        >
          <Typography type="p">
            <RDText id={business_unit} rdName={"business_unit_list"} />{" "}
          </Typography>
          {ipgm && <Icon icon="master_group" color="#516beb" size="18" />}
        </div>

        <Typography type="p" className="text-secondary">
          {_.get(invoice, "client_reference", "")}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          borderRight: "1px solid #cdced9",
        }}
      >
        <Typography type="p">{_.get(invoice, "invoice_number", "")}</Typography>
        <Typography type="p" className="text-secondary">
          <DateComponent
            type={DATE_ORG}
            value={_.get(invoice, "invoice_date", "")}
          />
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
        }}
      >
        <Typography
          type="p"
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          {_.get(total_outstanding_invoice_amount, "currency", "")}{" "}
          <NumberFormtRB
            value={_.get(total_outstanding_invoice_amount, "value", "")}
          />{" "}
        </Typography>
        <Typography type="p" className="text-secondary">
          DSC: {days_since_created}
        </Typography>
        <StatefulPopover
          triggerType={TRIGGER_TYPE.hover}
          content={({ close }) => (
            <div className="co_groping_delete_popover">
              <div className="co_groping_delete_popover_body">
                <Icon icon="alert" color="#FD372A" />
                <Typography type="p">
                  The record will be removed from this consumer, Do you want to
                  continue?
                </Typography>
              </div>
              <div className="co_groping_delete_popover_footer">
                <TextButton
                  size="mini"
                  kind={KIND.tertiary}
                  onClick={() => close()}
                >
                  <Typography type="h5" style={{ color: "#FD372A" }}>
                    Cancel
                  </Typography>
                </TextButton>
                <TextButton
                  size="mini"
                  kind={KIND.secondary}
                  onClick={deleteClient}
                  type="error"
                >
                  Delete
                </TextButton>
              </div>
            </div>
          )}
          returnFocus
          autoFocus
        >
          <div className="co_remove_group">
            <RBACWrapper role={UNMERGE_CONSUMER_RECORDS} type="PERMISSION">
              <Icon
                icon="delete"
                isPressable
                size="16"
                // onClick={() => { })}
              />
            </RBACWrapper>
          </div>
        </StatefulPopover>
      </div>
    </div>
  );
};

const GroupingMain = () => {
  const { customerId } = useParams();
  let maxyfiContextValue = useContext(MaxyfiContext);

  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const customerData = queryClient.getQueryData(
    `${GET_GROUPING_LIST_DATA}-${customerId}`
  );

  console.log(customerData, "customerData_____");
  const [checkedItems, setCheckedItems] = useState({
    individual: [],
    groupId: "",
  });

  const updateRemoverGrouping = useMutation(
    () =>
      removerGroupingFn({
        organization: currentOrganization,
        clientId:
          checkedItems && checkedItems.individual && checkedItems.individual[0],
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
        queryClient.refetchQueries([`${GET_GROUPING_LIST_DATA}-${customerId}`]);
        setCheckedItems({
          individual: [],
          groupId: "",
        });
      },
    }
  );

  const updateGrouping = useMutation(
    () =>
      updateGroupingFn({
        organization: currentOrganization,
        ...(checkedItems?.groupId?.length > 0
          ? { process_group_id: checkedItems.groupId }
          : {}),
        clientId:
          checkedItems && checkedItems.individual && checkedItems.individual[0],
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
        queryClient.refetchQueries([`${GET_GROUPING_LIST_DATA}-${customerId}`]);
        setCheckedItems({
          individual: [],
          groupId: "",
        });
      },
    }
  );

  const deleteClient = useMutation(
    (data) =>
      deleteClientGroupingFn({
        ...data,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
        queryClient.refetchQueries([`${GET_GROUPING_LIST_DATA}-${customerId}`]);
        setCheckedItems({
          individual: [],
          groupId: "",
        });
      },
    }
  );

  if (updateGrouping.isLoading || updateRemoverGrouping.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "95vh",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {/* Header */}
      <div
        style={{
          display: "flex",
          border: "1px solid #CDCED9",
          borderBottom: "0px",
          padding: "5px",
          gap: "10px",
        }}
      >
        {checkedItems.individual.length > 0 &&
        checkedItems.groupId.length > 0 ? (
          <>
            <RBACWrapper role={MAINTAIN_PROCESS_GROUP} type="PERMISSION">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  backgroundColor: "#E4F0FC",
                  borderRadius: "5px",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={() => updateGrouping.mutateAsync()}
              >
                <Icon icon="plus" color="#516beb" />
                <Typography
                  type="h4"
                  subType="small"
                  style={{ color: "#561beb" }}
                >
                  Add to Process Group
                </Typography>
              </div>
            </RBACWrapper>
          </>
        ) : checkedItems.individual.length > 0 ? (
          <>
            {" "}
            <RBACWrapper role={MAINTAIN_PROCESS_GROUP} type="PERMISSION">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  backgroundColor: "#E4F0FC",
                  borderRadius: "5px",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={() => updateRemoverGrouping.mutateAsync()}
              >
                <Icon icon="minus_outline" color="#516beb" />
                <Typography
                  type="h4"
                  subType="small"
                  style={{ color: "#561beb" }}
                >
                  Remove from Process Group
                </Typography>
              </div>
            </RBACWrapper>
          </>
        ) : (
          <>
            {" "}
            <div style={{ width: "28px", height: "18px" }}></div>
            <Typography type="p" style={{ flex: "1" }}>
              {" "}
              Client Name & Ref ID
            </Typography>
            <Typography type="p" style={{ flex: "1" }}>
              {" "}
              Invoice Name & Date
            </Typography>
            <Typography type="p" style={{ flex: "1" }}>
              {" "}
              Due Amount
            </Typography>
          </>
        )}
      </div>

      {_.get(customerData, "data.docs", []).map((item) => (
        <ProgressItem
          key={item._id}
          item={item}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          deleteClient={deleteClient}
        />
      ))}
    </div>
  );
};

const ProgressName = ({ data, onProgressCheckboxChange, checkedItems }) => {
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  let { _id, workflow_name, customers } = data;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "10px",
        border: "1px solid #CDCED9",
        borderBottom: "0px",
        padding: "5px",
        backgroundColor: "#FAFAFA",
      }}
    >
      {_.get(currentDefaultFormatDetails, "cgs.ipg") ? (
        ""
      ) : (
        <div style={{ width: "28px", height: "18px" }}></div>
      )}
      {currentDefaultFormatDetails &&
        currentDefaultFormatDetails?.cgs &&
        currentDefaultFormatDetails?.cgs?.ipg && (
          <CheckBoxBaseweb
            checked={checkedItems.groupId === _id || false}
            onChange={() => {
              onProgressCheckboxChange(_id);
            }}
            disabled={
              // checkedItems.individual.length > 0
              checkedItems && checkedItems?.groupIdRef === _id
            }
          />
        )}

      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Typography type="p" className="regular">
          {workflow_name ? workflow_name : " "}
        </Typography>{" "}
        {workflow_name ? "  -  " : ""}
        <Typography type="p" className="regular">
          <RDText
            rdName={"customer_stage"}
            id={_.get(customers, "[0].customer_auto_status", "")}
          />
        </Typography>
      </div>
    </div>
  );
};

const EditGroupAction = () => {
  return (
    <>
      {" "}
      <div style={{ width: "650px" }}>
        <TitleHead title="Process Grouping" />
        <GroupingMain />
      </div>
    </>
  );
};

export default EditGroupAction;
