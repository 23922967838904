import React from "react";

const RecurringSvg = ({ width = "22", height = "22", color = "#333860" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0482 4.04034H13.3686L12.4898 4.92063C12.2622 5.14868 12.2625 5.51803 12.4905 5.74578C12.7181 5.97292 13.0876 5.97337 13.3155 5.74502L15.187 3.87032C15.4178 3.63924 15.4148 3.25956 15.1737 3.03257L13.3155 1.17125C13.0879 0.943197 12.7186 0.942893 12.4905 1.17049C12.2625 1.39823 12.2622 1.76758 12.4898 1.99563L13.3661 2.8735C10.7224 2.8735 11.052 2.87334 11.0232 2.87334C4.85549 2.87334 0.940494 9.58587 4.03323 14.9813C4.19336 15.2607 4.54965 15.3576 4.82951 15.1973C5.10906 15.037 5.20569 14.6806 5.04555 14.4011C2.38385 9.7577 5.79093 4.0139 11.0482 4.04034Z"
        fill={color}
      />
      <path
        d="M18.0292 6.93248C17.8691 6.65308 17.5125 6.5563 17.2329 6.71644C16.9534 6.87672 16.8568 7.23331 17.017 7.51287C19.6585 12.121 16.3247 17.8736 11.0314 17.8736H8.69389L9.57266 16.9933C9.80041 16.7652 9.80011 16.3959 9.57205 16.1681C9.344 15.9406 8.97451 15.9409 8.74691 16.1689L6.87541 18.0436C6.6466 18.2727 6.64538 18.653 6.88954 18.8821L8.74691 20.7427C8.97451 20.9706 9.34385 20.9712 9.57205 20.7434C9.80011 20.5157 9.80041 20.1463 9.57281 19.9183L8.69647 19.0404H11.0387C17.2095 19.0403 21.1213 12.3268 18.0292 6.93248Z"
        fill={color}
      />
      <path
        d="M10.448 9.20673H12.7817C13.1038 9.20673 13.3651 8.9454 13.3651 8.62331C13.3651 8.30106 13.1038 8.03989 12.7817 8.03989H11.6149V7.45647C11.6149 7.13422 11.3535 6.87305 11.0314 6.87305C10.7092 6.87305 10.448 7.13422 10.448 7.45647V8.03989C9.48279 8.03989 8.69775 8.82507 8.69775 9.79015C8.69775 10.7552 9.48279 11.5404 10.448 11.5404H11.6147C11.9365 11.5404 12.1983 11.802 12.1983 12.1238C12.1983 12.4456 11.938 12.7074 11.6147 12.7074C11.0118 12.7074 11.0314 12.7072 11.0314 12.7072C11.0305 12.7072 11.0296 12.7074 11.0287 12.7074C11.0061 12.7074 9.26811 12.7072 9.28117 12.7072C8.95893 12.7072 8.69775 12.9684 8.69775 13.2905C8.6976 13.6128 8.95877 13.8739 9.28102 13.8741H10.448V14.4575C10.448 14.7796 10.7092 15.0409 11.0314 15.0409C11.3535 15.0409 11.6149 14.7796 11.6149 14.4575V13.8742C12.5821 13.8742 13.3651 13.0916 13.3651 12.1238C13.3651 11.1588 12.5798 10.3736 11.6147 10.3736H10.448C10.1262 10.3736 9.86459 10.1118 9.86459 9.79015C9.86459 9.46835 10.1262 9.20673 10.448 9.20673Z"
        fill={color}
      />
    </svg>
  );
};

export default RecurringSvg;
