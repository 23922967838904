import React from "react";
import { Drawer } from "baseui/drawer";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import CustomerPortal from "./COTabContainers/CustomerPortal";
import SettingIcon from "../../assets/img/svg/SettingIcon";

function CustomerPortalSettings({ getParams }) {
  const [isOpen, setIsOpen] = React.useState(false);

  let Part = window.location.pathname;
  Part = Part.substring(0, Part.lastIndexOf("/"));

  return (
    <>
      <IconButton
        kind={KIND.tertiary}
        size={SIZE.mini}
        onClick={() => setIsOpen(true)}
      >
        {Part == "/workflows" ? (
          <SettingIcon />
        ) : (
          <i className="mx-icon-settings-2 settings_font_size" />
        )}
      </IconButton>
      <Drawer
        isOpen={isOpen}
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerBody: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
            }),
          },
        }}
        autoFocus
        onClose={() => setIsOpen(false)}
      >
        <CustomerPortal getParams={getParams} setIsOpen={setIsOpen} />
      </Drawer>
    </>
  );
}

export default CustomerPortalSettings;
