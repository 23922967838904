const MentionInfo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_991_16809)">
        <path
          d="M34.9476 16.6801C33.8917 3.22134 17.2115 -2.94399 7.71518 6.6652C-4.80477 19.6983 9.77845 40.0079 26.1217 32.3052C26.4382 32.1421 26.6779 31.8609 26.7888 31.5223C26.8998 31.1838 26.8731 30.8151 26.7145 30.4961C26.556 30.1771 26.2783 29.9334 25.9416 29.8177C25.6049 29.7019 25.2361 29.7235 24.9152 29.8777C16.7293 34.0926 6.39719 28.2606 5.78579 19.0557C5.0254 11.0456 12.0882 3.9699 20.0971 4.73384C22.289 4.9135 24.402 5.6352 26.2464 6.83412C28.0907 8.03305 29.6086 9.67165 30.6637 11.6028C31.7188 13.5339 32.278 15.697 32.2912 17.8979C32.3044 20.0988 31.7712 22.2685 30.7393 24.2121C30.5868 24.4798 30.3746 24.7085 30.1192 24.8807C29.8639 25.0528 29.5722 25.1635 29.2671 25.2044C28.9619 25.2452 28.6514 25.215 28.3599 25.1161C28.0683 25.0172 27.8035 24.8522 27.586 24.6341C27.359 24.4079 27.1791 24.139 27.0566 23.8428C26.9341 23.5466 26.8715 23.2291 26.8723 22.9086V11.4683C26.8683 11.1115 26.7238 10.7707 26.4702 10.5198C26.2166 10.2689 25.8744 10.1282 25.5179 10.1282C25.1613 10.1282 24.8191 10.2689 24.5655 10.5198C24.3119 10.7707 24.1675 11.1115 24.1634 11.4684V12.0417C19.1909 7.62571 11.1082 11.3045 11.1609 17.9746C11.1111 24.6965 19.2941 28.3545 24.246 23.8342C24.9587 28.2426 30.962 29.5226 33.1297 25.4872C34.5639 22.7856 35.1948 19.7294 34.9476 16.6801ZM19.0166 23.1251C17.652 23.1236 16.3438 22.5804 15.3789 21.6148C14.414 20.6492 13.8713 19.34 13.8697 17.9744C14.1474 11.1433 23.8868 11.1453 24.1634 17.9745C24.1619 19.34 23.6191 20.6492 22.6542 21.6148C21.6893 22.5804 20.3811 23.1236 19.0166 23.1251Z"
          fill="#516BEB"
        />
      </g>
      <defs>
        <clipPath id="clip0_991_16809">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MentionInfo;
