import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CheckBoxBaseweb from "../../CheckBoxBaseweb";
import { ParagraphSmall } from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import { TextButton, KIND, SIZE } from "../../TextButton";
import { useDispatch, useSelector } from "react-redux";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  setCustomerSummaryFilter,
  setNeedsActionFilter,
  setCustomerStageFilter,
} from "../../../redux/customerSummary/action";
import { useLocation, useParams } from "react-router-dom";
const NeedsActionFilters = (props) => {
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  let { referenceData } = useContext(MaxyfiContext);
  //

  const [isValue, setIsValue] = useState([]);

  useEffect(() => {
    if (props.name == "Invoice") {
      let inVal = [
        { id: "SMS_FOLLOWUP", label: "SMS Follow-Up" },
        { id: "EMAIL_FOLLOWUP", label: "Email Follow - Up" },
        { id: "ON_HOLD", label: "On-Hold Review" },
        { id: "UNREAD_EMAILS", label: "Unread Emails" },
      ];
      let filterValue = inVal.filter(
        (i) =>
          !referenceData?.needs_action_filter?.includes((j) => i.id == j.id)
      );
      // let filterValue = referenceData.needs_action_filter.filter((e, i) => {
      //   return inVal.indexOf(i) > -1;
      // });
      setIsValue(filterValue);
      // NeedsActionFiltersRd.push(filterValue);
    } else {
      setIsValue(referenceData?.needs_action_filter);
    }
  }, [props.name]);

  let { control, watch, setValue, resetForm } = props;

  return (
    <>
      {isValue
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((i) => (
          <div style={{ paddingTop: "2px" }}>
            {
              <Controller
                name={`needsAction.${i.id}`}
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb {...field} checked={field.value}>
                    <ParagraphSmall style={{ color: "#333860" }}>
                      {/* {i.id} */}
                      <FormattedMessage id={i.id}>{i.id}</FormattedMessage>
                    </ParagraphSmall>
                  </CheckBoxBaseweb>
                )}
              />
            }
          </div>
        ))}

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: "10px",
          borderTop: "0.5px solid #CDCED9",
          paddingTop: "10px",
          marginTop: "10px",
        }}
      >
        <div style={{ width: "100px" }}>
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            fullWidth
            onClick={() => {
              // NeedsActionFilters.forEach((e) => {
              //   setValue(e.id, false);
              // });
              // dispatch(setCustomerSummaryFilter({ formFilters: [{ id: [] }] }));
              // dispatch(setNeedsActionFilter({}));
              // dispatch(setCustomerStageFilter({}));
              resetForm();
            }}
          >
            <FormattedMessage id="clear_filters">
              clear_filters
            </FormattedMessage>
          </TextButton>
        </div>
        <div style={{ width: "100px" }}>
          <TextButton
            type="submit"
            kind={KIND.primary}
            size={SIZE.mini}
            fullWidth
          >
            <FormattedMessage id="apply">apply</FormattedMessage>
          </TextButton>
        </div>
      </div>
    </>
  );
};

export default NeedsActionFilters;
