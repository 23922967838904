import React, { useState, useContext } from "react";
import { IconButton } from "../../../components/IconButton";
import { KIND, SIZE, TextButton } from "../../../components/TextButton";
import DocDownArSvg from "../../../assets/img/svg/DocDownArSvg";
import DocRigntSvg from "../../../assets/img/svg/DocRigntSvg";
import DairyNoteEdit from "../../../assets/img/svg/CustomerOverViewIcon/DairyNoteEdit";
import InvoiceUser from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InvoiceUser";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import {
  LabelSmall,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import moment from "moment";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { STYLE_TYPE } from "baseui/checkbox";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { EDIT } from "../../../constants";
import { updateSubscriptionStatus } from "../../../services";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { subscriptionsDrawer } from "../../../redux/actions";
import { useDispatch } from "react-redux";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  EDIT_RECURRING_INVOICE,
  ENABLE_DISABLE_RECURRING_INVOICE,
} from "../../../providers/RBACProvider/permissionList";

const SubscriptionCard = ({
  setIsAddNew,
  i,
  setEditingData,
  refetchCheckIn,
  setRefetchCheckIn,
  getParams,
}) => {
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  let navigate = useNavigate();

  let dispatch = useDispatch();

  const [isExpand, setIsExpand] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  // const getParams = getSearchParams.get("customers");
  let {
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    referenceData,
  } = useContext(MaxyfiContext);

  const orgRef = referenceData.organizations.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef.time_zone;

  let findFreq = i.frequency
    ? referenceData["invoice_frequency"].find(({ id }) => id == i.frequency)
    : {};

  const activeFeeSub = useMutation(
    (data) =>
      updateSubscriptionStatus({
        ...data,
        organization: currentOrganization,
        customerId: getParams,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        setRefetchCheckIn(refetchCheckIn + 1);
      },
    }
  );
  return (
    <>
      <div
        className={
          isExpand ? "ci_card_container --ci_card_pt" : "ci_card_container"
        }
      >
        <div
          // className="dms_card_head"
          style={{ margin: "0px 10px" }}
        >
          <div
            className="dms_card_doc_type_content"
            style={{ alignItems: "flex-start" }}
          >
            {/* <div>
              <IconButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => epandCard()}
              >
                {isExpand ? <DocDownArSvg /> : <DocRigntSvg />}
              </IconButton>
            </div> */}
            {/* DATA and LOCATION */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", gap: "2px" }}>
                  <ParagraphMedium
                    style={{
                      color: "#516beb",
                    }}
                  >
                    {i.name} -
                  </ParagraphMedium>
                  <LabelSmall $style={{ marginTop: "5px" }}>
                    {i && i.total_amount && i.total_amount.currency}
                  </LabelSmall>
                  <NumericFormat
                    // type="text"
                    displayType="text"
                    value={
                      i && i.total_amount && i.total_amount.value
                        ? Number(i.total_amount.value)
                        : "-"
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(value) => (
                      <LabelSmall $style={{ marginTop: "5px" }}>
                        {value}
                      </LabelSmall>
                    )}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <RBACWrapper role={EDIT_RECURRING_INVOICE} type="PERMISSION">
                    <IconButton
                      kind={KIND.tertiary}
                      onClick={() => {
                        //
                        navigate(
                          `/customers?customer=${getParams}&invoice=recurring-form&subscription=${i._id}&type=EDIT-SUB`
                        );
                        // setAddSearchParams({
                        //   customer: `${getParams}`,
                        //   invoice: `recurring-form`,
                        //   subscription: `${i._id}`,
                        //   type: "EDIT-SUB",
                        // });
                        // na
                        dispatch(subscriptionsDrawer(false));
                      }}
                    >
                      <i className="mx-icon-Vector-7 font_size_icon color_gr" />
                    </IconButton>
                  </RBACWrapper>
                  {/* {i.is_deleted ? (
                    <div></div>
                  ) : ( */}
                  <>
                    <RBACWrapper
                      role={ENABLE_DISABLE_RECURRING_INVOICE}
                      type="PERMISSION"
                    >
                      <div>
                        <CheckBoxBaseweb
                          checked={!i.is_disabled}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={() => {
                            // setIsSelect(!isSelect);
                            activeFeeSub.mutateAsync({
                              recurringInvoiceId: i._id,
                            });
                          }}
                        ></CheckBoxBaseweb>
                      </div>
                    </RBACWrapper>
                  </>
                  {/* )} */}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  paddingBottom: `${isExpand ? "10px" : "0px"}`,
                }}
              >
                {!i.is_deleted ? (
                  <>
                    <div>
                      {/* 23213 */}
                      {/* {i.amountType} */}
                      {findFreq?.label}
                    </div>
                    <div>-</div>
                    <div>
                      Starting{" "}
                      {moment
                        .utc(i.start_date)
                        ?.tz(timeZone)

                        .format(currentDefaultFormatDetails.date_format)}{" "}
                      {i?.end_date
                        ? `Until ${moment
                            .utc(i.end_date)
                            ?.tz(timeZone)

                            ?.format(currentDefaultFormatDetails.date_format)}`
                        : ""}
                      {/* {i.billType} */}
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      Skipped since{" "}
                      {moment
                        .utc(i.start_date)
                        .format(currentDefaultFormatDetails.date_format)}{" "}
                    </div>
                  </>
                )}
              </div>
              {/* IS_EXPAND DATAS STARTS*/}
            </div>
            {/* DATA and LOCATION */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionCard;
