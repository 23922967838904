export default function Enlarge({ size = 22, color = "#000000" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M3.26314 14.5377C3.08237 14.5377 2.93085 14.4765 2.80856 14.3542C2.68627 14.2319 2.62512 14.0804 2.62512 13.8996V9.77294C2.62512 9.62295 2.67588 9.49722 2.7774 9.39575C2.87892 9.29428 3.00471 9.24354 3.15476 9.24354C3.30484 9.24354 3.43055 9.29428 3.5319 9.39575C3.63325 9.49722 3.68393 9.62295 3.68393 9.77294V12.7418L12.8292 3.59647H9.8604C9.71041 3.59647 9.58468 3.54571 9.48321 3.44419C9.38174 3.34268 9.33101 3.2169 9.33101 3.06683C9.33101 2.91677 9.38174 2.79106 9.48321 2.68971C9.58468 2.58834 9.71041 2.53766 9.8604 2.53766H13.9871C14.1679 2.53766 14.3194 2.59881 14.4417 2.7211C14.564 2.84338 14.6251 2.99491 14.6251 3.17567V7.30238C14.6251 7.45237 14.5744 7.5781 14.4728 7.67957C14.3713 7.78104 14.2455 7.83177 14.0955 7.83177C13.9454 7.83177 13.8197 7.78104 13.7183 7.67957C13.617 7.5781 13.5663 7.45237 13.5663 7.30238V4.33355L4.42102 13.4788H7.38984C7.53983 13.4788 7.66556 13.5296 7.76703 13.6311C7.8685 13.7326 7.91924 13.8584 7.91924 14.0085C7.91924 14.1586 7.8685 14.2843 7.76703 14.3856C7.66556 14.487 7.53983 14.5377 7.38984 14.5377H3.26314Z"
        fill={color}
      />
    </svg>
  );
}
