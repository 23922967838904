import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

import { SIZE, TextButton, KIND } from "../../components/TextButton";
import { SelectBox } from "../../components/SelectBox";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../components/TextArea";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  updateCustomerActionWorkflowStatus,
  useCustomerUpdateAction,
} from "../../services";
import {
  ON_HOLD,
  CUSTOMER_OVERVIEW_,
  CO_TIMELINE_,
  SKIPPED,
} from "../../constants";
import { Icon, Typography } from "../../components_v2";
import getSelectValues from "../../utils/getSelectValues";
import moment from "moment";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import queryClient from "../../providers/queryClient";

const SkipAction = ({ handleModal, customerId, entity }) => {
  const intl = useIntl();

  let maxyfiContextValue = useContext(MaxyfiContext);
  let { currentOrganization, referenceData, refetch, space } =
    maxyfiContextValue;

  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const { mutateAsync, isLoading } = useCustomerUpdateAction();

  const onSubmit = async (data) => {
    let values = {
      ...data,
      id: customerId,
      organization: currentOrganization,
      actionStatus: SKIPPED,
      entity: entity,
      customerId: customerId,
    };

    await mutateAsync(
      {
        ...values,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
          });
          queryClient.invalidateQueries({
            queryKey: [`${CO_TIMELINE_}${customerId}`],
          });

          reset();
          handleModal();
        },
        onError: (error) => {
          if (error.response) {
            let { data } = error.response;
            let errorData = data.message;
            toast.error(errorData);
          }
        },
      }
    );
  };

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "5px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Icon icon="skip_action" size={16} />
          <Typography type="h2" subType="medium">
            Skip Action
          </Typography>
        </div>

        <div>
          <Controller
            control={control}
            name="comment"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <TextArea
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "user_comments",
                })}
                placeholder={intl.formatMessage({
                  id: "user_comments",
                })}
                requiredAstric={true}
                size={SIZE.mini}
                value={field.value}
              />
            )}
          />
        </div>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            padding: "5px 25px 15px 25px",
            alignItems: "center",
            gap: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.mini}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ width: "100px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.mini}
              kind={KIND.primary}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default SkipAction;
