import React from "react";
import _ from "lodash";
import { useNumericFormat } from "react-number-format";
import { useContext } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useState } from "react";
import { HeadingXSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";

const CustomFieldsTab = ({
  customFields = {},
  fieldLevel = [],
  width = "",
  wrapWidth = "",
}) => {
  const {
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  let result = {};
  for (let obj of fieldLevel) {
    let section = obj.section;
    if (result.hasOwnProperty(section)) {
      result[section].push({ ...obj });
    } else {
      result[section] = [{ ...obj }];
    }
  }

  let getObjectKey = Object.keys(result);

  const [isSectionActive, setIsSectionActive] = useState(
    getObjectKey && getObjectKey[0]
  );

  return (
    <div style={{ width: width }}>
      <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
        {Object.keys(result)?.map((inv, i) => {
          return (
            <div>
              <div
                className={`inv_custom_tab_mx ${
                  isSectionActive === inv && "--custom_inv_active"
                }`}
                onClick={() => setIsSectionActive(inv)}
              >
                <ParagraphMedium>{inv}</ParagraphMedium>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
          alignItems: "flex-start",
          marginBottom: "10px",
        }}
      >
        {result &&
          result[isSectionActive] &&
          result[isSectionActive].map((e) => {
            let value = _.get(customFields, `custom_field.${e.path}`, "");

            return (
              !e.is_hidden && (
                <div style={{ width: wrapWidth }}>
                  <HeadingXSmall
                    $style={{ color: "#ADADAD", lineHeight: "16px" }}
                  >
                    {e.name}
                  </HeadingXSmall>
                  <ParagraphMedium
                    $style={{ color: "#333860", lineHeight: "17px" }}
                  >
                    {e.data_type === "AMOUNT" ? (
                      numberFormat.format(
                        value?.value ? `${value.currency} ${value.value}` : "-"
                      )
                    ) : e.data_type === "DATE" ? (
                      value ? (
                        moment
                          .utc(value)
                          ?.tz(currentDefaultFormatDetails.time_zone)
                          ?.format(currentDefaultFormatDetails?.date_format)
                      ) : (
                        "-"
                      )
                    ) : e.data_type == "HYPERLINK" ? (
                      value ? (
                        <a
                          href={value}
                          target="_blank"
                          style={{ textDecoration: "underline" }}
                        >
                          {value}
                        </a>
                      ) : (
                        "-"
                      )
                    ) : e.data_type == "TEXT" ? (
                      value ? (
                        value
                      ) : (
                        "-"
                      )
                    ) : e.data_type == "LONG_TEXT" ? (
                      value ? (
                        value
                      ) : (
                        "-"
                      )
                    ) : e.data_type == "NUMBER" ? (
                      value ? (
                        value
                      ) : (
                        "-"
                      )
                    ) : (
                      "-"
                    )}
                  </ParagraphMedium>
                </div>
              )
            );
          })}
      </div>
    </div>
  );
};

export default CustomFieldsTab;
