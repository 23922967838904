import React from "react";

const CollapseDownArrow = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7071 8.70711C15.0976 8.31658 15.0976 7.68342 14.7071 7.29289C14.3166 6.90237 13.6834 6.90237 13.2929 7.29289L14.7071 8.70711ZM10 12L9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071L10 12ZM6.70711 7.29289C6.31658 6.90237 5.68342 6.90237 5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711L6.70711 7.29289ZM13.2929 7.29289L9.29289 11.2929L10.7071 12.7071L14.7071 8.70711L13.2929 7.29289ZM10.7071 11.2929L6.70711 7.29289L5.29289 8.70711L9.29289 12.7071L10.7071 11.2929Z"
        fill="#333860"
      />
    </svg>
  );
};

export default CollapseDownArrow;
