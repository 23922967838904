import React from "react";

const PauseSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.132 11.0669C6.29808 11.0669 6.43541 11.0126 6.54399 10.9041C6.65258 10.7955 6.70687 10.6581 6.70687 10.4921V5.49065C6.70687 5.33735 6.65258 5.20641 6.54399 5.09782C6.43541 4.98923 6.29808 4.93494 6.132 4.93494C5.96592 4.93494 5.82859 4.98923 5.72001 5.09782C5.61142 5.20641 5.55713 5.34374 5.55713 5.50981V10.5112C5.55713 10.6645 5.61142 10.7955 5.72001 10.9041C5.82859 11.0126 5.96592 11.0669 6.132 11.0669ZM9.198 11.0669C9.36407 11.0669 9.50141 11.0126 9.60999 10.9041C9.71858 10.7955 9.77287 10.6581 9.77287 10.4921V5.49065C9.77287 5.33735 9.71858 5.20641 9.60999 5.09782C9.50141 4.98923 9.36407 4.93494 9.198 4.93494C9.03193 4.93494 8.89459 4.98923 8.78601 5.09782C8.67742 5.20641 8.62313 5.34374 8.62313 5.50981V10.5112C8.62313 10.6645 8.67742 10.7955 8.78601 10.9041C8.89459 11.0126 9.03193 11.0669 9.198 11.0669ZM7.665 15.6659C6.61745 15.6659 5.62739 15.4647 4.69481 15.0623C3.76224 14.6599 2.94783 14.1106 2.25159 13.4143C1.55536 12.7181 1.00603 11.9037 0.603619 10.9711C0.201206 10.0386 0 9.04849 0 8.00094C0 6.95339 0.201206 5.96333 0.603619 5.03075C1.00603 4.09818 1.55536 3.28377 2.25159 2.58753C2.94783 1.89129 3.76224 1.34197 4.69481 0.939556C5.62739 0.537144 6.61745 0.335938 7.665 0.335938C8.71255 0.335938 9.70261 0.537144 10.6352 0.939556C11.5678 1.34197 12.3822 1.89129 13.0784 2.58753C13.7746 3.28377 14.324 4.09818 14.7264 5.03075C15.1288 5.96333 15.33 6.95339 15.33 8.00094C15.33 9.04849 15.1288 10.0386 14.7264 10.9711C14.324 11.9037 13.7746 12.7181 13.0784 13.4143C12.3822 14.1106 11.5678 14.6599 10.6352 15.0623C9.70261 15.4647 8.71255 15.6659 7.665 15.6659Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default PauseSvg;
