export const GET_CS_GRID_STRUCTURE = "CS_GRID_STRUCTURE";
export const UPDATE_CS_TABLE_DRAFT_STATE = "UPDATE_CS_TABLE_DRAFT_STATE";
export const SET_CS_VIEW = "SET_CS_VIEW";
export const NESTED_CS_ACTION_TYPE = "NESTED_CS_ACTION_TYPE";
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";
export const RESET_CUSTOMER_NAVLINK = "RESET_CUSTOMER_NAVLINK";
export const SET_CS_GRID_AND_VIEW = "SET_CS_GRID_AND_VIEW";
export const SET_CS_SORT = "SET_CS_SORT";
export const SET_CS_PAGE_INDEX = "SET_CS_PAGE_INDEX";
export const SET_CS_PAGE_SIZE = "SET_CS_PAGE_SIZE";
export const SET_CS_FILTER = "SET_CS_FILTER";
export const SET_GLOBAL_FILTER = "SET_GLOBAL_FILTER";
export const NEEDS_ACTION_FILTERS = "NEEDS_ACTION_FILTERS";
export const CUSTOMER_STAGE_FILTERS = "CUSTOMER_STAGE_FILTERS";
export const DASHBOARD_NEED_ACTION_FILTER = "DASHBOARD_NEED_ACTION_FILTER";
export const DASHBOARD_CUSTOMER_ACTION_STAGE =
  "DASHBOARD_CUSTOMER_ACTION_STAGE";
export const TABLE_COLUMN_ORDER = "TABLE_COLUMN_ORDER";
export const COLUMN_WIDTH = "COLUMN_WIDTH";
export const SET_CS_PINNED_VIEW = "SET_CS_PINNED_VIEW";
export const DASHBOARD_CUSTOM_ACTION_FILTER = "DASHBOARD_CUSTOM_ACTION_FILTER";

export const setCustomerPinnedView = (payload = []) => {
  return {
    type: SET_CS_PINNED_VIEW,
    payload,
  };
};

export const setTableWidth = (width) => {
  return {
    type: COLUMN_WIDTH,
    payload: width,
  };
};

export const setNavlinkReset = () => {
  return {
    type: RESET_CUSTOMER_NAVLINK,
  };
};

export const setTableHeaderOrder = (tabHead) => {
  return {
    type: TABLE_COLUMN_ORDER,
    payload: tabHead,
  };
};

export const setDashBoardDashboardCustomerActionStage = (customerStage) => {
  return {
    type: DASHBOARD_CUSTOMER_ACTION_STAGE,
    payload: customerStage,
  };
};

export const setDashBoardNeedActionFilter = (dashFilter) => {
  return {
    type: DASHBOARD_NEED_ACTION_FILTER,
    payload: dashFilter,
  };
};

export const setDashBoardCustomActionFilter = (dashFilter) => {
  return {
    type: DASHBOARD_CUSTOM_ACTION_FILTER,
    payload: dashFilter,
  };
};

export const setCustomerStageFilter = (cusStage) => {
  return {
    type: CUSTOMER_STAGE_FILTERS,
    payload: cusStage,
  };
};

export const setNeedsActionFilter = (selectedNeedsFilter) => {
  return {
    type: NEEDS_ACTION_FILTERS,
    payload: selectedNeedsFilter,
  };
};

export const setGlobalFilter = (cusName) => {
  return {
    type: SET_GLOBAL_FILTER,
    payload: cusName,
  };
};

export const setCustomerSummaryFilter = (filterData) => {
  return {
    type: SET_CS_FILTER,
    payload: filterData,
  };
};

export const getInitialCustomerSummary = () => {
  return {
    type: RESET_INITIAL_STATE,
  };
};

export const getNestedActionType = (typeData) => {
  return {
    type: NESTED_CS_ACTION_TYPE,
    payload: typeData,
  };
};

export const getCustomerSummaryGridStructure = (gridStructure) => {
  return {
    type: GET_CS_GRID_STRUCTURE,
    payload: gridStructure,
  };
};

//TODO: AB REMOVE
export const updateTableDraftState = (tableState) => {
  return {
    type: UPDATE_CS_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const updateTableCustomerSummaryDraftState = (tableState) => {
  return {
    type: UPDATE_CS_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

//TODO: AB REMOVE
export const setTableView = (viewData) => {
  return {
    type: SET_CS_VIEW,
    payload: viewData,
  };
};

export const setCustomerSummaryTableView = (viewData) => {
  return {
    type: SET_CS_VIEW,
    payload: viewData,
  };
};

export const setCustomerSummaryGridAndView = (viewData) => {
  return {
    type: SET_CS_GRID_AND_VIEW,
    payload: viewData,
  };
};

export const setCustomerSummarySort = (sort) => {
  return {
    type: SET_CS_SORT,
    payload: sort,
  };
};

export const setCustomerSummaryPageIndex = (pageIndex) => {
  return {
    type: SET_CS_PAGE_INDEX,
    payload: pageIndex,
  };
};

export const setCustomerSummaryPageSize = (pageSize) => {
  return {
    type: SET_CS_PAGE_SIZE,
    payload: pageSize,
  };
};
