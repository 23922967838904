import { NEW } from "../../constants";
// Action Types
export const UPDATE_WF = "UPDATE_WF";
export const UPDATE_ACTIONS = "UPDATE_ACTIONS";
export const SAVE_ACTIONS = "SAVE_ACTIONS";
export const DELETE_ACTION = "DELETE_ACTION";
export const DELETE_INVOICE_ACTION = "DELETE_INVOICE_ACTION";

export const ADD_NEW_ACTION = "ADD_NEW_ACTION";
export const SET_CURRENT_ACTION = "SET_CURRENT_ACTION";
export const SET_INVOICE_CURRENT_ACTION = "SET_INVOICE_CURRENT_ACTION";

export const SAVE_CURRENT_ACTION = "SAVE_CURRENT_ACTION";
export const CHANGE_DATE_ACTION = "CHANGE_DATE_ACTION";
export const FORM_UPDATE_ACTION = "FORM_UPDATE_ACTION";

export const CURRENT_TAB_ACTION = "CURRENT_TAB_ACTION";
export const CHANGE_DUE_DATE = "CHANGE_DUE_DATE";

export const SET_WORKFLOW_VIEW_ONLY = "SET_WORKFLOW_VIEW_ONLY";
export const SET_WORKFLOW_TYPE = "SET_WORKFLOW_TYPE";
export const INITIAL_STATE_ON_CANCEL = "INITIAL_STATE_ON_CANCEL";

export const DRAG_AND_DROP = "DRAG_AND_DROP";
export const DRAG_AND_DROP_INVOICE = "DRAG_AND_DROP_INVOICE";

export const INITIAL_VALUE_STATE = "INITIAL_VALUE_STATE";
export const WORKFLOW_INITIAL_STATE = "WORKFLOW_INITIAL_STATE";

export const IS_MODAL = "IS_MODAL";

export const IS_LOADER_MODAL = "IS_LOADER_MODAL";

export const UPDATE_ATTACHMENTS = "UPDATE_ATTACHMENTS";
export const REMOVE_ATTACHED_FILES = "REMOVE_ATTACHED_FILES";
export const INITIALSTATE_STATE = "INITIALSTATE_STATE";
export const RESET_WORKFLOW = "RESET_WORKFLOW";
export const SELECTED_DAYS_AFTER = "SELECTED_DAYS_AFTER";
export const DAYS_FROM = "DAYS_FROM";
export const INVOICE_DUE_DAYS_AFTER = "INVOICE_DUE_DAYS_AFTER";
export const INVOICE_ISSUED_DAYS_AFTER = "INVOICE_ISSUED_DAYS_AFTER";
export const INVOICE_FLOW = "INVOICE_FLOW";
export const SAVE_INVOICE_CURRENT_ACTION = "SAVE_INVOICE_CURRENT_ACTION";
export const SET_INVOICE_GENERAL_DETAILS = "SET_INVOICE_GENERAL_DETAILS";
export const CHANGE_TIME_ACTION = "CHANGE_TIME_ACTION";
export const CHANGE_INVOICE_TIME_ACTION = "CHANGE_INVOICE_TIME_ACTION";
export const SAVE_PORTAL_ACTION = "SAVE_PORTAL_ACTION";
export const OPEN_PORTAL_DRAWER = "OPEN_PORTAL_DRAWER";

// Action

export const setInvoiceGeneralDetails = (data) => {
  return {
    type: SET_INVOICE_GENERAL_DETAILS,
    payload: data,
  };
};

export const invoiceFlow = (data) => {
  return {
    type: INVOICE_FLOW,
    payload: data,
  };
};

export const invoiceIssuedDaysAfter = (data) => {
  return {
    type: INVOICE_ISSUED_DAYS_AFTER,
    payload: data,
  };
};

export const invoiceDueDaysAfter = (data) => {
  return {
    type: INVOICE_DUE_DAYS_AFTER,
    payload: data,
  };
};

export const daysFrom = (data) => {
  return {
    type: DAYS_FROM,
    payload: data,
  };
};

export const selectedDaysAfter = (data) => {
  return {
    type: SELECTED_DAYS_AFTER,
    payload: data,
  };
};

export const resetWorkflow = () => {
  return {
    type: RESET_WORKFLOW,
  };
};

export const loaderModal = (modalState) => {
  return {
    type: IS_LOADER_MODAL,
    payload: modalState,
  };
};

export const modalOpen = (stateData) => {
  return {
    type: IS_MODAL,
    payload: stateData,
  };
};

export const workflowInitialState = (data) => {
  return {
    type: WORKFLOW_INITIAL_STATE,
    payload: data,
  };
};

export const initialValueState = (value) => {
  return {
    type: INITIAL_VALUE_STATE,
    payload: value,
  };
};

export const dragDropAction = (dragdrop) => {
  return {
    type: DRAG_AND_DROP,
    payload: dragdrop,
  };
};
export const dragDropInvoiceAction = (dragdrop) => {
  return {
    type: DRAG_AND_DROP_INVOICE,
    payload: dragdrop,
  };
};

export const initialStateOnCancel = (stateData) => {
  return {
    type: INITIAL_STATE_ON_CANCEL,
    payload: stateData,
  };
};

export const currentTabAction = (currentTab) => {
  return {
    type: CURRENT_TAB_ACTION,
    payload: currentTab,
  };
};

export const formUpdation = (formData) => {
  return {
    type: FORM_UPDATE_ACTION,
    payload: formData,
  };
};

export const actionWorkFlow = (data) => {
  return {
    type: UPDATE_ACTIONS,
    payload: data,
  };
};

export const updateAction = (data) => {
  return {
    type: UPDATE_WF,
    payload: data,
  };
};

export const saveWorkFlow = (index, ref, data) => {
  return {
    type: SAVE_ACTIONS,
    payload: { index, ref, data },
  };
};

export const deleteAction = (data) => {
  return {
    type: DELETE_ACTION,
    payload: data,
  };
};
export const deleteInvoiceAction = (id) => {
  return {
    type: DELETE_INVOICE_ACTION,
    payload: id,
  };
};

export const addNewAction = () => {
  return {
    type: ADD_NEW_ACTION,
    payload: null,
  };
};

export const setCurrentAction = (actionData) => {
  return {
    type: SET_CURRENT_ACTION,
    payload: actionData,
  };
};
export const setInvoiceCurrentAction = (index) => {
  return {
    type: SET_INVOICE_CURRENT_ACTION,
    payload: index,
  };
};

export const saveCurrentAction = (currentData) => {
  return {
    type: SAVE_CURRENT_ACTION,
    payload: currentData,
  };
};

export const savePortalAction = (portalData) => {
  return {
    type: SAVE_PORTAL_ACTION,
    payload: portalData,
  };
};

export const saveInvoiceCurrentAction = (invCurrData) => {
  return {
    type: SAVE_INVOICE_CURRENT_ACTION,
    payload: invCurrData,
  };
};

export const changeDateAction = (dateChange) => {
  return {
    type: CHANGE_DATE_ACTION,
    payload: dateChange,
  };
};

export const changeTimeAction = (dateChange) => {
  return {
    type: CHANGE_TIME_ACTION,
    payload: dateChange,
  };
};

export const changeInvTimeAction = (dateChange) => {
  return {
    type: CHANGE_INVOICE_TIME_ACTION,
    payload: dateChange,
  };
};

export const changeDueDate = (dueDate) => {
  return {
    type: CHANGE_DUE_DATE,
    payload: dueDate,
  };
};

export const setWorkflowViewOnly = (view = false) => {
  return {
    type: SET_WORKFLOW_VIEW_ONLY,
    payload: view,
  };
};

export const setWorkflowType = (type = NEW) => {
  return {
    type: SET_WORKFLOW_TYPE,
    payload: type,
  };
};

export const uploadEmailAttachment = (uploadTemplate) => {
  return {
    type: UPDATE_ATTACHMENTS,
    payload: uploadTemplate,
  };
};

export const removeAttachedFile = (removeFile) => {
  return {
    type: REMOVE_ATTACHED_FILES,
    payload: removeFile,
  };
};

export const adhocInitialState = () => {
  return {
    type: INITIALSTATE_STATE,
  };
};

export const portalDrawerAction = (action) => {
  return {
    type: OPEN_PORTAL_DRAWER,
    payload: action,
  };
};
