import React from "react";

function Premium() {
  return (
    <>
      <svg
        enable-background="new 0 0 32 32"
        height="12"
        viewBox="0 0 32 32"
        width="12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Layer_1">
          <g>
            <g fill="#ffb743">
              <path d="m2.8373 20.9773c-.6083-3.954-1.2166-7.9079-1.8249-11.8619-.1349-.8765.8624-1.4743 1.5718-.9422 1.8952 1.4214 3.7903 2.8427 5.6855 4.2641.624.468 1.513.3157 1.9456-.3333l4.7333-7.1c.5002-.7503 1.6026-.7503 2.1028 0l4.7333 7.1c.4326.649 1.3216.8012 1.9456.3333 1.8952-1.4214 3.7903-2.8427 5.6855-4.2641.7094-.5321 1.7067.0657 1.5719.9422-.6083 3.954-1.2166 7.9079-1.8249 11.8619z" />
              <path d="m27.7902 27.5586h-23.5804c-.758 0-1.3725-.6145-1.3725-1.3725v-3.015h26.3255v3.015c-.0001.758-.6146 1.3725-1.3726 1.3725z" />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

export default Premium;
