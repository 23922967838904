export const STICKY_GET_CS_GRID_STRUCTURE = "STICKY_GET_CS_GRID_STRUCTURE";
export const STICKY_UPDATE_CS_TABLE_DRAFT_STATE =
  "STICKY_UPDATE_CS_TABLE_DRAFT_STATE";
export const STICKY_SET_CS_VIEW = "STICKY_SET_CS_VIEW";
export const STICKY_NESTED_CS_ACTION_TYPE = "STICKY_NESTED_CS_ACTION_TYPE";
export const STICKY_RESET_INITIAL_STATE = "STICKY_RESET_INITIAL_STATE";
export const STICKY_RESET_CUSTOMER_NAVLINK = "STICKY_RESET_CUSTOMER_NAVLINK";
export const SET_CS_GRID_AND_VIEW_STICKY = "SET_CS_GRID_AND_VIEW_STICKY";
export const STICKY_SET_CS_SORT = "STICKY_SET_CS_SORT";
export const STICKY_SET_CS_PAGE_INDEX = "STICKY_SET_CS_PAGE_INDEX";
export const STICKY_SET_CS_PAGE_SIZE = "STICKY_SET_CS_PAGE_SIZE";
export const STICKY_SET_CS_FILTER = "STICKY_SET_CS_FILTER";
export const STICKY_SET_GLOBAL_FILTER = "STICKY_SET_GLOBAL_FILTER";
export const STICKY_NEEDS_ACTION_FILTERS = "STICKY_NEEDS_ACTION_FILTERS";
export const STICKY_CUSTOMER_STAGE_FILTERS = "STICKY_CUSTOMER_STAGE_FILTERS";
export const STICKY_DASHBOARD_NEED_ACTION_FILTER =
  "STICKY_DASHBOARD_NEED_ACTION_FILTER";

export const TABLE_COLUMN_ORDER = "TABLE_COLUMN_ORDER";
export const COLUMN_WIDTH = "COLUMN_WIDTH";

export const setTableWidth = (width) => {
  return {
    type: COLUMN_WIDTH,
    payload: width,
  };
};

export const setNavlinkReset = () => {
  return {
    type: STICKY_RESET_CUSTOMER_NAVLINK,
  };
};

export const setTableHeaderOrder = (tabHead) => {
  return {
    type: TABLE_COLUMN_ORDER,
    payload: tabHead,
  };
};

export const setStickyStageFilter = (cusStage) => {
  return {
    type: STICKY_CUSTOMER_STAGE_FILTERS,
    payload: cusStage,
  };
};

export const setStickyStatesMaintenanceFilter = (filterData) => {
  return {
    type: STICKY_SET_CS_FILTER,
    payload: filterData,
  };
};

export const getInitialCustomerSummary = () => {
  return {
    type: STICKY_RESET_INITIAL_STATE,
  };
};

export const getStickyStatesMaintenanceGridStructure = (gridStructure) => {
  return {
    type: STICKY_GET_CS_GRID_STRUCTURE,
    payload: gridStructure,
  };
};

//TODO: AB REMOVE
export const updateTableDraftState = (tableState) => {
  return {
    type: STICKY_UPDATE_CS_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

export const updateTableStickyStatusDraftState = (tableState) => {
  return {
    type: STICKY_UPDATE_CS_TABLE_DRAFT_STATE,
    payload: tableState,
  };
};

//TODO: AB REMOVE
export const setTableView = (viewData) => {
  return {
    type: STICKY_SET_CS_VIEW,
    payload: viewData,
  };
};

export const setStickyStatusTableView = (viewData) => {
  return {
    type: STICKY_SET_CS_VIEW,
    payload: viewData,
  };
};

export const setStickyStatesMaintenanceGridAndView = (viewData) => {
  return {
    type: SET_CS_GRID_AND_VIEW_STICKY,
    payload: viewData,
  };
};

export const setStickyStatusSort = (sort) => {
  return {
    type: STICKY_SET_CS_SORT,
    payload: sort,
  };
};

export const setStickyStatesMaintenancePageIndex = (pageIndex) => {
  return {
    type: STICKY_SET_CS_PAGE_INDEX,
    payload: pageIndex,
  };
};

export const setStickyStatusPageSize = (pageSize) => {
  return {
    type: STICKY_SET_CS_PAGE_SIZE,
    payload: pageSize,
  };
};
