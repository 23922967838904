import { useContext, useState } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { Typography } from "../../components_v2";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_CREDIT_REPORT,
  ADD_DOCUMENTS,
} from "../../providers/RBACProvider/permissionList";
import AddDMSystem from "../../containers/CustomerOverView/AddDMSystem";
import AddCreditReport from "../../containers/CustomerOverView/AddCreditReport";

const CreditReportsDrawer = ({ resourceId, entity, documentDrawertype }) => {
  // const [isSelect, setIsSelect] = useState(false);

  const [sortName, setSort] = useState("");

  const { currentOrganization, referenceData, defaultData } =
    useContext(MaxyfiContext);

  return (
    <div>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh",
            width: "450px",
          }}
        >
          {/* ONE STARTS*/}
          <div
            style={{
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "6px" }}>
              <Typography type="h4">Configure Credit Reports </Typography>
              <div></div>
            </div>
          </div>

          {/* <RBACWrapper role={ADD_CREDIT_REPORT} type={"PERMISSION"}> */}
          <AddCreditReport />
          {/* </RBACWrapper> */}

          {/* TWO STARTS */}

          <div
            style={{
              // height: "calc(100% - 85px)",
              // paddingBottom: "5%",
              height: "100%",
              overflow: "scroll",
            }}
          >
            {/* {isFetching ? <Loader /> : <></>} */}
          </div>
          {/* TWO ENDS */}

          <div style={{ height: "40px" }}></div>
        </div>
      </>
    </div>
  );
};

export default CreditReportsDrawer;
