import React from "react";

const MasterGroupSvg = ({ size = 22, color = "#000000" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none">
      <path
        d="M11 13.6308L13.4832 15.5109C13.6545 15.6312 13.8272 15.6351 14.0015 15.5228C14.1758 15.4104 14.2296 15.2528 14.1628 15.05L13.2227 11.9401L15.6483 10.2152C15.8263 10.0825 15.8804 9.91721 15.8106 9.71922C15.7408 9.52126 15.6017 9.42227 15.3934 9.42227H12.4247L11.4136 6.15569C11.341 5.95284 11.2033 5.85142 11.0003 5.85142C10.7973 5.85142 10.6593 5.95284 10.5864 6.15569L9.5753 9.42227H6.60664C6.39831 9.42227 6.25923 9.52126 6.18939 9.71922C6.11955 9.91721 6.17365 10.0825 6.35168 10.2152L8.77732 11.9401L7.83725 15.05C7.77044 15.2528 7.82419 15.4104 7.99849 15.5228C8.17278 15.6351 8.34554 15.6312 8.5168 15.5109L11 13.6308ZM11.0016 20C9.7568 20 8.58677 19.7638 7.49148 19.2914C6.39618 18.8189 5.44342 18.1778 4.63322 17.368C3.82299 16.5581 3.18157 15.6058 2.70894 14.511C2.23631 13.4162 2 12.2464 2 11.0016C2 9.7568 2.23621 8.58677 2.70863 7.49148C3.18106 6.39618 3.82219 5.44343 4.63203 4.63322C5.44189 3.823 6.39423 3.18157 7.48904 2.70894C8.58384 2.23631 9.75363 2 10.9984 2C12.2432 2 13.4132 2.23621 14.5085 2.70863C15.6038 3.18106 16.5566 3.82219 17.3668 4.63203C18.177 5.44189 18.8184 6.39423 19.2911 7.48904C19.7637 8.58384 20 9.75363 20 10.9984C20 12.2432 19.7638 13.4132 19.2914 14.5085C18.8189 15.6038 18.1778 16.5566 17.368 17.3668C16.5581 18.177 15.6058 18.8184 14.511 19.2911C13.4162 19.7637 12.2464 20 11.0016 20ZM11 18.579C13.1 18.579 14.8882 17.8408 16.3645 16.3645C17.8408 14.8882 18.579 13.1 18.579 11C18.579 8.89999 17.8408 7.11183 16.3645 5.63551C14.8882 4.15919 13.1 3.42103 11 3.42103C8.89999 3.42103 7.11183 4.15919 5.63551 5.63551C4.15919 7.11183 3.42103 8.89999 3.42103 11C3.42103 13.1 4.15919 14.8882 5.63551 16.3645C7.11183 17.8408 8.89999 18.579 11 18.579Z"
        fill={color}
      />
    </svg>
  );
};

export default MasterGroupSvg;
