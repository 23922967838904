import React from "react";

function User({ width = 13, height = 13 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 1.625C7.39375 1.625 8.125 2.35625 8.125 3.25C8.125 4.14375 7.39375 4.875 6.5 4.875C5.60625 4.875 4.875 4.14375 4.875 3.25C4.875 2.35625 5.60625 1.625 6.5 1.625ZM6.5 8.9375C8.69375 8.9375 11.2125 9.98563 11.375 10.5625V11.375H1.625V10.5706C1.7875 9.98563 4.30625 8.9375 6.5 8.9375ZM6.5 0C4.70437 0 3.25 1.45438 3.25 3.25C3.25 5.04563 4.70437 6.5 6.5 6.5C8.29562 6.5 9.75 5.04563 9.75 3.25C9.75 1.45438 8.29562 0 6.5 0ZM6.5 7.3125C4.33063 7.3125 0 8.40125 0 10.5625V13H13V10.5625C13 8.40125 8.66938 7.3125 6.5 7.3125Z"
        fill="#333860"
      />
    </svg>
  );
}

export default User;
