import React from "react";

const SmsSvgN = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3164 2H3.70182C2.76582 2 2.00851 2.81 2.00851 3.8L2 20L5.40364 16.4001H17.3164C18.2524 16.4001 19.0182 15.5901 19.0182 14.6V3.8C19.0182 2.81 18.2524 2 17.3164 2ZM6.25455 8.30002H14.7637C15.2317 8.30002 15.6146 8.70502 15.6146 9.20002C15.6146 9.69502 15.2317 10.1 14.7637 10.1H6.25455C5.78655 10.1 5.40364 9.69502 5.40364 9.20002C5.40364 8.70502 5.78655 8.30002 6.25455 8.30002ZM11.36 12.8H6.25455C5.78655 12.8 5.40364 12.395 5.40364 11.9C5.40364 11.405 5.78655 11 6.25455 11H11.36C11.828 11 12.2109 11.405 12.2109 11.9C12.2109 12.395 11.828 12.8 11.36 12.8ZM14.7637 7.40001H6.25455C5.78655 7.40001 5.40364 6.99501 5.40364 6.50001C5.40364 6.00501 5.78655 5.60001 6.25455 5.60001H14.7637C15.2317 5.60001 15.6146 6.00501 15.6146 6.50001C15.6146 6.99501 15.2317 7.40001 14.7637 7.40001Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default SmsSvgN;
