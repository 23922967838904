import React from "react";

const TickCircle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6021 14.1666L8.29013 11.8497C8.15261 11.7154 7.98834 11.6467 7.79733 11.6435C7.60631 11.6403 7.43997 11.7095 7.2983 11.8512C7.15343 11.996 7.081 12.1624 7.081 12.3502C7.081 12.538 7.15197 12.7013 7.2939 12.84L10.0022 15.5482C10.1716 15.7239 10.3709 15.8117 10.6003 15.8117C10.8297 15.8117 11.0319 15.7239 11.2069 15.5482L16.6896 10.0656C16.8249 9.93032 16.8941 9.76718 16.8973 9.57615C16.9005 9.38513 16.8313 9.21719 16.6896 9.07232C16.5448 8.93066 16.3784 8.85982 16.1906 8.85982C16.0028 8.85982 15.8391 8.92956 15.6997 9.06902L10.6021 14.1666ZM12.0035 21.2982C10.7176 21.2982 9.50873 21.0539 8.37683 20.5651C7.24492 20.0764 6.26032 19.4131 5.42302 18.5752C4.58572 17.7374 3.92308 16.7536 3.4351 15.6239C2.94712 14.4941 2.70312 13.287 2.70312 12.0025C2.70312 10.7166 2.9475 9.50775 3.43625 8.37585C3.92498 7.24395 4.58827 6.25935 5.42612 5.42205C6.26396 4.58475 7.24775 3.92211 8.3775 3.43412C9.50725 2.94614 10.7144 2.70215 11.9989 2.70215C13.2847 2.70215 14.4936 2.94652 15.6255 3.43527C16.7574 3.92401 17.742 4.5873 18.5793 5.42515C19.4166 6.26298 20.0793 7.24677 20.5673 8.37652C21.0552 9.50627 21.2992 10.7134 21.2992 11.9979C21.2992 13.2838 21.0548 14.4926 20.5661 15.6245C20.0774 16.7564 19.4141 17.741 18.5762 18.5783C17.7384 19.4156 16.7546 20.0783 15.6249 20.5663C14.4951 21.0543 13.288 21.2982 12.0035 21.2982Z"
        fill="#0FB158"
      />
    </svg>
  );
};

export default TickCircle;
