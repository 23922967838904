import React from "react";

export default function CallBack({ size = 22, color = "#516BEB" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M14.3 11.5L12.6 11.3C12.2 11.2 11.8 11.4 11.5 11.7L10.3 12.9C8.30003 12 6.70003 10.3 5.70003 8.4L7.00003 7.1C7.10003 6.8 7.30003 6.4 7.20003 6L7.00003 4.2C6.90003 3.5 6.30003 3 5.60003 3H4.40003C3.60003 3 2.90003 3.6 3.00003 4.4C3.40003 10.3 8.10003 15.1 14.1 15.5C14.9 15.6 15.5 14.9 15.5 14.1V12.9C15.5 12.2 15 11.6 14.3 11.5ZM13.6 6.7C13.6 7.3 13.4 7.9 12.9 8.4C12.4 8.8 11.8 9.1 11.2 9.1C10.7 9.1 10.2 9 9.80003 8.7C9.50003 8.6 9.20003 8.3 9.10003 8.1V8.6H8.70003V7.4H9.80003V7.8H9.40003C9.80003 8.4 10.4 8.8 11.1 8.8C12.2 8.8 13.2 7.9 13.2 6.7H13.6ZM11.1 4.7C11.8 4.7 12.5 5.1 12.8 5.7H12.4V6.1H13.5V4.9H13.1V5.4C12.9 5.2 12.7 4.9 12.4 4.7C12 4.4 11.5 4.3 11 4.3C10.4 4.3 9.80003 4.5 9.30003 5C8.90003 5.5 8.70003 6.1 8.70003 6.7H9.10003C9.10003 5.6 9.90003 4.7 11.1 4.7Z"
        fill={color}
      />
    </svg>
  );
}
