import React from "react";

export default function RelManager({ size = "18", color = "#333860" }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99998 8.76923C8.27596 8.76923 7.65745 8.51274 7.14446 7.99977C6.63149 7.48679 6.375 6.86829 6.375 6.14427C6.375 5.42023 6.63149 4.80173 7.14446 4.28875C7.65745 3.77578 8.27596 3.51929 8.99998 3.51929C9.72401 3.51929 10.3425 3.77578 10.8555 4.28875C11.3685 4.80173 11.625 5.42023 11.625 6.14427C11.625 6.86829 11.3685 7.48679 10.8555 7.99977C10.3425 8.51274 9.72401 8.76923 8.99998 8.76923ZM3.375 13.3414V12.8135C3.375 12.4462 3.47476 12.106 3.67429 11.7931C3.8738 11.4801 4.14038 11.2395 4.47403 11.0712C5.21538 10.7077 5.96322 10.4351 6.71754 10.2534C7.47187 10.0717 8.23268 9.98082 8.99998 9.98082C9.76728 9.98082 10.5281 10.0717 11.2824 10.2534C12.0367 10.4351 12.7846 10.7077 13.5259 11.0712C13.8596 11.2395 14.1262 11.4801 14.3257 11.7931C14.5252 12.106 14.625 12.4462 14.625 12.8135V13.3414C14.625 13.6577 14.5141 13.9267 14.2925 14.1483C14.0709 14.3699 13.8019 14.4808 13.4856 14.4808H4.5144C4.19806 14.4808 3.92908 14.3699 3.70746 14.1483C3.48582 13.9267 3.375 13.6577 3.375 13.3414Z"
        fill={color}
      />
    </svg>
  );
}
