import React from "react";
import Layout from "../../layouts";
import Dashboard from "../../containers/Dashboard/Dashboard";
import RoleAccessSelect from "../../components/RoleAccessSelect/RoleAccessSelect";
import DashboardTitle from "../../components/DashboardTitle";

const DashboardView = () => {
  return (
    <Layout>
      <div className="content-container --layout_relative">
        <RoleAccessSelect />
        <div className="content-header">
          <div className="content-header__title">
            <DashboardTitle title="dashboard" queryKey="AGENT_DASHBOARD_" />
          </div>
        </div>
        <div className="content-body">
          <Dashboard />
        </div>
      </div>
    </Layout>
  );
};

export default DashboardView;
