import React from "react";

const Sync = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.49397 12.2043C2.4941 12.2043 2.49424 12.2043 2.49437 12.2043L3.81145 12.2033C4.08422 12.2031 4.30516 11.9818 4.30496 11.7091C4.30377 10.0693 4.83153 8.5123 5.83112 7.20655C7.24297 5.36218 9.39053 4.30436 11.723 4.30436C13.3619 4.30436 14.9159 4.83093 16.2173 5.8271C16.6486 6.1573 17.0358 6.52614 17.378 6.93042L14.9458 6.50105C14.817 6.47827 14.6841 6.50771 14.5767 6.58278C14.4694 6.65792 14.3964 6.77257 14.3736 6.90158L14.1446 8.19857C14.0972 8.46719 14.2765 8.72336 14.5451 8.77078L20.3817 9.8012C20.4103 9.80627 20.4389 9.8087 20.4676 9.8087C20.5682 9.8087 20.6672 9.77795 20.7508 9.71947C20.8581 9.64433 20.9312 9.52968 20.954 9.40067L21.9844 3.56403C22.0318 3.29541 21.8525 3.03924 21.5839 2.99183L20.2869 2.76285C20.0177 2.71524 19.7621 2.89476 19.7147 3.16338L19.281 5.61973C18.7945 5.01638 18.2359 4.46972 17.6183 3.99696C15.9117 2.69054 13.8737 2 11.7246 2C8.66752 2 5.85233 3.38708 4.00091 5.8055C2.69042 7.51738 1.99855 9.55938 2 11.7108C2.00007 11.8417 2.05222 11.9673 2.14488 12.0599C2.23754 12.1524 2.36306 12.2043 2.49397 12.2043Z"
        fill="#516BEB"
      />
      <path
        d="M21.8568 12.0184C21.7642 11.926 21.6386 11.874 21.5078 11.874C21.5076 11.874 21.5075 11.874 21.5074 11.874L20.1903 11.875C19.9175 11.8752 19.6966 12.0965 19.6968 12.3692C19.698 14.009 19.1702 15.566 18.1706 16.8717C16.7587 18.7161 14.6112 19.7739 12.2787 19.7739C10.6398 19.7739 9.08581 19.2474 7.78446 18.2512C7.35319 17.921 6.96597 17.5521 6.62379 17.1479L9.05591 17.5772C9.18505 17.6001 9.31768 17.5707 9.42495 17.4955C9.5323 17.4204 9.60533 17.3057 9.62811 17.1767L9.85702 15.8797C9.90444 15.6111 9.72512 15.3549 9.4565 15.3075L3.61992 14.2771C3.49111 14.2544 3.35822 14.2837 3.25088 14.3588C3.14354 14.434 3.0705 14.5486 3.04772 14.6776L2.01737 20.5143C1.96995 20.7829 2.14927 21.039 2.41789 21.0865L3.71489 21.3154C3.74393 21.3206 3.77284 21.3231 3.80135 21.3231C4.03645 21.3231 4.24481 21.1545 4.28716 20.9149L4.72081 18.4586C5.20727 19.0619 5.76591 19.6086 6.38349 20.0813C8.08996 21.3877 10.1279 22.0783 12.2772 22.0783C15.3343 22.0783 18.1494 20.6912 20.0008 18.2728C21.3113 16.5609 22.0031 14.5189 22.0017 12.3675C22.0016 12.2366 21.9495 12.111 21.8568 12.0184Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Sync;
