import React, { useContext, useEffect, useState } from "react";
import { MaxyfiContext } from "../MaxyfiProvider";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { HeadingXSmall, LabelMedium } from "baseui/typography";
import { TextButton, KIND } from "../../components/TextButton";
import { SIZE } from "baseui/input";
import Lock from "../../assets/img/svg/Lock";
import Premium from "../../assets/img/svg/Premium";
import Exclamatory from "../../assets/img/svg/Exclamatory";
import { useDispatch } from "react-redux";
import { getSettingData } from "../../redux/setting/actions";

import { useNavigate } from "react-router-dom";
const RBACWrapper = ({
  children,
  placement,
  role = null,
  type = "FEATURE",
  enabled = false,
}) => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { featureRoleList, roleList, space } = useContext(MaxyfiContext);
  const [isPermissions, setIsPermission] = useState([]);

  const content = {
    FEATURE: {
      header: "Feature Not Included!",
      caption: "Upgrade your Subscription to use/enable this feature",
    },
    LICENSE: {
      header: "Package Limit Reached!",
      caption: "Upgrade your Subscription to add/link more records",
    },
    SCALE: {
      header: "Package Limit Reached!",
      caption: "Upgrade your Subscription to review Consumer records.",
    },
  };

  let isPremiumFeature;
  let isAllowed;
  let rolePermissions;
  // let combinedPermission = ;

  // useEffect(() => {
  //   if (roleList && space.role_permissions) {
  //     // setIsPermission([...roleList, ...(space && space.role_permissions)]);
  //     setIsPermission([...roleList]);
  //   }
  // }, [roleList, space.role_permissions]);

  //

  if (type === "FEATURE" || type === "FEATURE_BLUR") {
    isPremiumFeature = !featureRoleList?.includes(role);
    isAllowed = roleList?.includes(role);
  }

  if (type === "PERMISSION") {
    isAllowed = space?.role_permissions?.includes(role);
  }

  if (type === "LICENSE" || type === "SCALE") {
    isPremiumFeature = !enabled;
    isAllowed = true;
  }

  // if (type === "FEATURE_BLUR" && isAllowed && isPremiumFeature) {
  if (type === "FEATURE_BLUR" && isAllowed && isPremiumFeature) {
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
            zIndex: 2,
          }}
        >
          <LabelMedium
            style={{
              height: "38px",
              fontWeight: "bold",
              gap: "5px",
              textAlign: "center",
            }}
          >
            OOPS! View Email replies feature is available only in Grow and Scale
            Package. Upgrade Now
          </LabelMedium>
          <div style={{ width: "170px", paddingTop: "10px" }}>
            <TextButton
              fullWidth
              kind={KIND.secondary}
              size={SIZE.compact}
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
              onClick={() => {
                // dispatch(getSettingData("freemium"));
                // navigator("/settings");
              }}
              disabled={true}
            >
              {/* <Lock /> */}
              Contact Sales
            </TextButton>
          </div>
        </div>
        <div style={{ filter: "blur(5px)" }}>{children}</div>
      </div>
    );
  }

  if (isAllowed && isPremiumFeature) {
    return (
      <>
        <StatefulPopover
          isOpen
          showArrow
          content={
            <div
              style={{
                width: "200px",
                borderTopLeftRadius: "5px",
              }}
            >
              <LabelMedium
                style={{
                  height: "38px",
                  backgroundColor: "#F5F6FA",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                <Exclamatory /> {content[type].header}
              </LabelMedium>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  height: "136px",
                  padding: "0px 15px",
                }}
              >
                <HeadingXSmall
                  style={{
                    color: "#ADADAD",
                    lineHeight: "18px",
                    // paddingTop: "10px",
                    // paddingBottom: "6px",
                    padding: "5px 0px 5px 0px",
                  }}
                >
                  {content[type].caption}
                </HeadingXSmall>
                {/* <HeadingXSmall
                  style={{
                    color: "#516BEB",
                    textDecoration: "underline",
                    paddingBottom: "5px",
                    borderBottom: "0.5px solid #EBEBEB",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      process.env.REACT_APP_PRICING_COMPARISON,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  See Feature Comparison
                </HeadingXSmall> */}
                <div style={{ width: "170px", paddingTop: "10px" }}>
                  <TextButton
                    fullWidth
                    kind={KIND.secondary}
                    size={SIZE.compact}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      // dispatch(getSettingData("freemium"));
                      // navigator("/settings");
                    }}
                    disabled={true}
                  >
                    {/* <Lock /> */}
                    Contact Sales
                  </TextButton>
                </div>
              </div>
            </div>
          }
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                borderRadius: "15px",
              }),
            },
            Inner: {
              style: ({ $theme }) => ({
                borderRadius: "15px",
              }),
            },
          }}
          placement={placement}
          triggerType={TRIGGER_TYPE.onClick}
        >
          <div>
            <div
              style={{
                pointerEvents: "none",
              }}
            >
              {children}
            </div>
          </div>
        </StatefulPopover>
      </>
    );
  }

  if (isAllowed) {
    return children;
  }

  return <></>;
};

export default RBACWrapper;
