import React from "react";
const PpBroken = ({ color = "#FD372A", width = 16, height = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.24999 9.65001C0.924988 9.65001 0.635406 9.52292 0.381244 9.26874C0.127081 9.01458 0 8.725 0 8.4V6.87501C0 6.75417 0.0135437 6.61667 0.0406312 6.46251C0.0677062 6.30833 0.108331 6.17083 0.162506 6.04999L2.3625 0.9375C2.475 0.679163 2.66042 0.458331 2.91876 0.275006C3.17708 0.0916686 3.44999 0 3.73749 0H10.825C11.1709 0 11.4657 0.121875 11.7094 0.365625C11.9531 0.609375 12.075 0.904163 12.075 1.24999V9.14376C12.075 9.30208 12.0427 9.45729 11.9782 9.60938C11.9136 9.76145 11.825 9.89374 11.7125 10.0062L7.76878 14.0813C7.58962 14.2563 7.37608 14.3625 7.12815 14.4C6.88024 14.4375 6.64586 14.3938 6.42503 14.2688C6.2042 14.1438 6.04066 13.9688 5.93441 13.7438C5.82816 13.5188 5.80212 13.2875 5.85628 13.05L6.61253 9.65001H1.24999ZM14.5375 0C14.8667 0 15.151 0.119794 15.3906 0.359381C15.6302 0.598956 15.75 0.883325 15.75 1.21249V8.4375C15.75 8.76666 15.6302 9.05104 15.3906 9.29063C15.151 9.53021 14.8667 9.65001 14.5375 9.65001C14.2084 9.65001 13.924 9.53021 13.6844 9.29063C13.4448 9.05104 13.325 8.76666 13.325 8.4375V1.21249C13.325 0.883325 13.4448 0.598956 13.6844 0.359381C13.924 0.119794 14.2084 0 14.5375 0Z"
        fill={color}
      />
    </svg>
  );
};
export default PpBroken;
