import React, { useState, useContext, useEffect } from "react";
import DougnutChart from "./DougnutChart";
import CsvModal from "./CsvModal";
import TopSection from "./TopSection";
import AgingSummary from "./AgingSummary";
import NeedsAction from "./NeedsAction";
import TopDebtors from "./TopDebtors";
import DougnutCustomerActionStage from "./DougnutCustomerActionStage";

const Dashboard = () => {
  const [isModal, setIsModal] = useState(false);

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <CsvModal setIsModal={setIsModal} isModal={isModal} />
      <TopSection setIsModal={setIsModal} />
      <div
        style={{
          display: "flex",
          gap: "20px",
          marginTop: "20px",
          height: "450px",
        }}
      >
        <AgingSummary />
        <NeedsAction />
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          height: "450px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <DougnutChart />

        <TopDebtors />
      </div>
      <div
        style={{
          display: "flex",
          gap: "20px",
          height: "400px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <DougnutCustomerActionStage />
        <div style={{ display: "flex", flex: "1" }}></div>
      </div>
    </div>
  );
};

export default Dashboard;
