import { StatefulPopover } from "baseui/popover";
import React, { useContext, useMemo } from "react";
import { Icon, Typography } from "../../../components_v2";
import { SIZE, SelectBox } from "../../../components/SelectBox";
import { KIND, TextButton } from "../../../components/TextButton";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useMutation } from "react-query";
import { addLegalStatus } from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import getSelectValues from "../../../utils/getSelectValues";
import { useParams } from "react-router-dom";
import queryClient from "../../../providers/queryClient";
import { LEGAL_ACTION_INFO_ } from "../../../constants";
export const HearingPopperEdit = () => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  const intl = useIntl();
  let maxyfiContextValue = useContext(MaxyfiContext);
  let { currentOrganization, referenceData, refetch } =
    useContext(MaxyfiContext);
  const { customerId } = useParams();
  const submitStatus = useMutation(
    (contactData) =>
      addLegalStatus({
        ...contactData,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries([`${LEGAL_ACTION_INFO_}${customerId}`]);
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
        reset();
      },
    }
  );

  const onSubmit = async (data) => {
    if (data) {
      await submitStatus.mutateAsync({
        status: getSelectValues(data.status, false),
      });
    }
  };

  return (
    <StatefulPopover
      overrides={{
        Arrow: {
          style: ({ $theme }) => ({
            outline: `##CDCED9 solid`,
            backgroundColor: "#16164B",
          }),
        },
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: "#FFFFFF",
          }),
        },
      }}
      content={({ close }) => (
        <form
          onSubmit={handleSubmit((data) => {
            onSubmit(data);
            close();
          })}
        >
          <div
            style={{
              width: "232px",
              padding: "10px",
              height: "130px",
              gap: "7px",
              border: "1px solid #CDCED9",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFFFFFF",
            }}
          >
            <Typography type="p" className="text-secondary">
              Change Status To:
            </Typography>
            <Controller
              name="status"
              control={control}
              // rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  // disabled={isDisable}
                  creatable
                  clearable={false}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  size={SIZE.mini}
                  label={intl.formatMessage({
                    id: `status`,
                  })}
                  placeholder={intl.formatMessage({
                    id: "status",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["legal_status_list"].sort((a, b) =>
                    a?.id.localeCompare(b?.id)
                  )}
                />
              )}
            />
            <div style={{ border: "1px solid #EEEEEEEE" }}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <TextButton
                size="mini"
                type="button"
                kind={KIND.tertiary}
                onClick={() => {
                  close();
                }}
              >
                Cancel
              </TextButton>
              <TextButton size="mini" kind={KIND.primary}>
                save
              </TextButton>
            </div>
          </div>
        </form>
      )}
      returnFocus
      autoFocus
    >
      <div style={{ marginBottom: "3px" }}>
        <Icon icon="edit_outline" color="#ADADAD" size={14} />
      </div>
    </StatefulPopover>
  );
};
