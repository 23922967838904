import React, { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { SelectBox } from "../../components/SelectBox";
import { useIntl } from "react-intl";
import SmsContactSvg from "../../assets/img/svg/SmsContactSvg";
import DeleteIcon from "../../assets/img/svg/DeleteIcon";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { TextBox } from "../../components/TextBox";
import { Icon, Typography } from "../../components_v2";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { EDIT_CONTACT } from "../../redux/actions";
import { TextArea } from "../../components/TextArea";
import _ from "lodash";
import setSelectValues from "../../utils/setSelectValues";
import getSelectValues from "../../utils/getSelectValues";
import useFormat from "../../hooks/useFormat";
const ConatctEmailFields = ({
  errors,
  i,
  e,
  actionType,
  setValue,
  findFullWidth,
  control,
  isDisable,
  remove,
  fields,
}) => {
  const { referenceData, defaultData } = useContext(MaxyfiContext);
  const [isSMSField, setIsSMSField] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  function close() {
    setIsSMSField(!isSMSField);
  }
  const [submitWhatsup, setSubmitStatus] = useState(false);
  const [isEmailStatus, setSubmitEmailStatus] = useState(e?.email_status);

  const handleEmailStatusChange = (field, status) => {
    const newValue = [
      { id: status.toUpperCase(), label: status.toUpperCase() },
    ];
    setSubmitEmailStatus(newValue);
    field.onChange(newValue);
  };

  const handleEmailSave = (field) => {
    setSubmitStatus(true);
  };

  //delete email comment
  const handleDeleteComment = (val) => {
    setValue(val, "");
  };
  const format = useFormat();
  const [textCommentOpen, setTextCommentOpen] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);
  const intl = useIntl();
  return (
    <>
      <div style={{ display: "flex", gap: "10px" }} key={e.id}>
        <div style={{ width: "300px" }}>
          <Controller
            name={`email[${i}].type`}
            control={control}
            defaultValue={[{ id: "PERSONAL_EMAIL", label: "Personal" }]}
            render={({ field }) => (
              <SelectBox
                {...field}
                disabled={isDisable}
                name={field.name}
                clearable={false}
                size={SIZE.mini}
                error={
                  errors &&
                  errors["email"] &&
                  errors["email"][i] &&
                  errors["email"][i].type &&
                  errors["email"][i].type.message
                }
                label={intl.formatMessage({
                  id: "type_contact",
                })}
                placeholder={intl.formatMessage({
                  id: "type_contact",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["contact_email_type"]}
              />
            )}
          />
        </div>
        <Controller
          name={`email[${i}].value`}
          control={control}
          rules={{
            pattern: {
              message: "enter valid email id",
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            },
          }}
          render={({ field }) => (
            <>
              <TextBox
                // type="email"
                {...field}
                disabled={isDisable}
                fullWidth
                name={field.name}
                size={SIZE.mini}
                error={
                  errors &&
                  errors["email"] &&
                  errors["email"][i] &&
                  errors["email"][i].value &&
                  errors["email"][i].value.message
                }
                label={intl.formatMessage({
                  id: "email",
                })}
                placeholder={intl.formatMessage({
                  id: "enter_email",
                })}
                value={field.value}
              />
            </>
          )}
        />
        <div style={{ width: "180px" }}>
          <Controller
            name={`email[${i}].status`}
            control={control}
            defaultValue={[{ id: "VALID", label: "Valid" }]}
            render={({ field }) => (
              <SelectBox
                {...field}
                disabled={
                  e && e.status && e?.status[0] && e?.status[0].id === "DNC"
                    ? true
                    : false
                }
                clearable={false}
                name={field.name}
                size={SIZE.mini}
                error={
                  errors &&
                  errors["email"] &&
                  errors["email"][i] &&
                  errors["email"][i].status &&
                  errors["email"][i].status.message
                }
                label={intl.formatMessage({
                  id: `status`,
                })}
                placeholder={intl.formatMessage({
                  id: "status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["contact_status"]}
              />
            )}
          />
        </div>
        <div style={{ display: "flex", marginTop: "3px", gap: "5px" }}>
          <StatefulPopover
            content={({ close }) => (
              <Controller
                name={`email[${i}].email_status`}
                control={control}
                defaultValue={[{ id: "VALID", label: "Valid" }]}
                render={({ field }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      padding: "10px",
                    }}
                  >
                    <Typography type="p" className="regular" subType="regular">
                      Email Status
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      {["VALID", "INVALID", "DNC"].map((status) => (
                        <div
                          key={status}
                          style={{
                            border: `1px solid ${
                              getSelectValues(isEmailStatus) === status
                                ? "#516BEB"
                                : "#CDCED9"
                            }`,
                            pointerEvents:
                              getSelectValues(
                                _.get(e, "email_status", []),
                                false
                              ) === "DNC"
                                ? "none"
                                : "auto",
                            padding: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleEmailStatusChange(field, status)}
                        >
                          <Typography
                            type="p"
                            className="regular"
                            style={{
                              color: `${
                                getSelectValues(isEmailStatus) === status
                                  ? "#516BEB"
                                  : ""
                              }`,
                            }}
                          >
                            {status === "DNC" ? status : _.capitalize(status)}
                          </Typography>
                        </div>
                      ))}
                    </div>
                    <div style={{ border: "1px solid #EEEEEE" }}> </div>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <TextButton
                        size="mini"
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => {
                          setSubmitEmailStatus(e?.email_status);
                          close();
                        }}
                      >
                        Cancel
                      </TextButton>
                      <TextButton
                        size="mini"
                        disabled={
                          getSelectValues(
                            _.get(e, "email_status", ""),
                            false
                          ) === "DNC"
                            ? true
                            : false
                        }
                        kind={KIND.primary}
                        type="button"
                        onClick={() => {
                          handleEmailSave(field);
                          close();
                        }}
                      >
                        Save
                      </TextButton>
                    </div>
                  </div>
                )}
              />
            )}
            returnFocus
            autoFocus
            overrides={{
              Arrow: {
                style: ({ $theme }) => ({
                  outline: `#CDCED9 solid`,
                  backgroundColor: "#16164B",
                }),
              },
              Inner: {
                style: ({ $theme }) => ({
                  width: "158px",
                  height: "112px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                }),
              },
            }}
            placement={PLACEMENT.bottomLeft}
          >
            <div style={{ cursor: "pointer" }}>
              {e.email_status && (
                <SmsContactSvg
                  color={
                    getSelectValues(isEmailStatus) === "VALID"
                      ? "#12b112"
                      : "#FD372A"
                  }
                />
              )}
            </div>
          </StatefulPopover>

          <StatefulPopover
            content={({ close }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                {e.comment ? (
                  <></>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                      margin: "4px 0px",
                    }}
                  >
                    <div style={{ paddingLeft: "5px" }}>
                      <Icon icon="contact_chat_icon" />
                    </div>

                    <Typography
                      type="p"
                      subType="medium"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCommentOpen(true);
                        close();
                      }}
                    >
                      {" "}
                      Add Comment
                    </Typography>
                  </div>
                )}

                {fields.length > 1 && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      margin: "4px 0px",
                      ":hover": {
                        background: "#ffdfdc",
                      },
                    }}
                    onClick={() => remove(i)}
                  >
                    <div style={{ paddingLeft: "5px" }}>
                      <Icon icon="delete_outline" isPressable color="#FD372A" />
                    </div>

                    <Typography
                      type="p"
                      subType="medium"
                      style={{ color: "#FD372A" }}
                    >
                      Delete
                    </Typography>
                  </div>
                )}
              </div>
            )}
            returnFocus
            autoFocus
            overrides={{
              Arrow: {
                style: ({ $theme }) => ({
                  outline: `#CDCED9 solid`,
                  backgroundColor: "#16164B",
                }),
              },
              Inner: {
                style: ({ $theme }) => ({
                  width: "120px",
                  // height: "70px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                }),
              },
            }}
            placement={PLACEMENT.leftBottom}
            triggerType={TRIGGER_TYPE.hover}
          >
            <Icon icon="triple_dot" isPressable />
          </StatefulPopover>
        </div>
      </div>
      {actionType === "EDIT_CONTACT" ? (
        <>
          {deleteComment ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "flex-start",
                }}
              >
                {e.comment && !textCommentOpen && <Icon icon="comment_sep" />}
                {!e.comment && commentOpen && !textCommentOpen && (
                  <Icon icon="comment_sep" />
                )}
                {e.comment && !textCommentOpen && (
                  <>
                    {!textCommentOpen && (
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "#F0F5FF",
                          flexDirection: "column",
                          width: "497px",
                          padding: "13px",
                          borderRadius: "6px",
                          gap: "8px",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography type="p" className="regular">
                          {_.get(e, "comment", "")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                gap: "6px",
                              }}
                              onClick={() => {
                                setTextCommentOpen(true);
                              }}
                            >
                              <Icon
                                icon="edit_outline"
                                color="#787878"
                                size={20}
                                isPressable
                              />
                              <Typography type="p" className="text-secondary">
                                Edit
                              </Typography>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDeleteComment(true);

                                handleDeleteComment(`email[${i}].comment`);
                              }}
                            >
                              <Icon icon="dot" color="#D9D9D9" size={6} />
                              <Icon
                                icon="delete_outline"
                                color="#787878"
                                isPressable
                              />
                              <Typography type="p" className="text-secondary">
                                Remove
                              </Typography>
                            </div>
                          </div>
                          {_.get(e, "aby", "") ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "2px",
                                alignItems: "center",
                              }}
                            >
                              <Icon icon="user_outLine" color="#787878" />
                              <Typography type="p" className="text-secondary">
                                {format.rd({
                                  id: _.get(e, "aby", ""),
                                  name: "users",
                                })}{" "}
                                , {format.date({ value: _.get(e, "aat", "") })}
                              </Typography>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}

                {!e.comment && commentOpen && !textCommentOpen && (
                  <Controller
                    name={`email[${i}].comment`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextArea
                          onFocus={false}
                          {...field}
                          name={field.name}
                          // disabled={}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "Comments",
                          })}
                          placeholder={intl.formatMessage({
                            id: "Email Comments",
                          })}
                          size={SIZE.compact}
                          value={field.value}
                          // disabled={pageType == VIEW ? true : false}
                          // maxLength={200}
                        />
                      </>
                    )}
                  />
                )}
                {textCommentOpen ? (
                  <Controller
                    name={`email[${i}].comment`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextArea
                          onFocus={false}
                          {...field}
                          name={field.name}
                          // disabled={}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "Comments",
                          })}
                          placeholder={intl.formatMessage({
                            id: "Email Comments",
                          })}
                          size={SIZE.compact}
                          value={field.value}
                          // disabled={pageType == VIEW ? true : false}
                          // maxLength={200}
                        />
                      </>
                    )}
                  />
                ) : (
                  <></>
                )}
              </div>

              {textCommentOpen && (
                <div
                  className="comment_cancel_button_container"
                  onClick={() => {
                    setTextCommentOpen(false);
                    setValue(`email[${i}].comment`, e?.comment);
                  }}
                >
                  <Icon icon="crosss" isPressable />
                  <Typography
                    type="p"
                    className="text-secondary"
                    subType="medium"
                  >
                    {" "}
                    Cancel
                  </Typography>
                </div>
              )}
              {!e.comment && commentOpen && !textCommentOpen && (
                <div
                  className="comment_cancel_button_container"
                  onClick={() => {
                    setTextCommentOpen(false);
                    setCommentOpen(false);
                    setValue(`email[${i}].comment`, "");
                  }}
                >
                  <Icon icon="crosss" isPressable />
                  <Typography
                    type="p"
                    className="text-secondary"
                    subType="medium"
                  >
                    {" "}
                    Cancel
                  </Typography>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {commentOpen && (
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "flex-start",
              }}
            >
              <Icon icon="comment_sep" />
              <Controller
                name={`email[${i}].comment`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextArea
                      onFocus={false}
                      {...field}
                      name={field.name}
                      // disabled={}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "Comments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "Status Comments",
                      })}
                      size={SIZE.compact}
                      value={field.value}
                      // disabled={pageType == VIEW ? true : false}
                      // maxLength={200}
                    />
                  </>
                )}
              />
            </div>
          )}
          {commentOpen && (
            <div
              className="comment_cancel_button_container"
              onClick={() => {
                setTextCommentOpen(false);
                setValue(`email[${i}].comment`, "");
              }}
            >
              <Icon icon="crosss" isPressable />
              <Typography type="p" className="text-secondary" subType="medium">
                {" "}
                Cancel
              </Typography>
            </div>
          )}
        </>
      )}
      {isSMSField && (
        <div style={{ width: "180px" }}>
          <Controller
            name={`email[${i}].email_status`}
            control={control}
            defaultValue={[{ id: "VALID", label: "Valid" }]}
            render={({ field }) => (
              <SelectBox
                {...field}
                disabled={
                  e &&
                  e.email_status &&
                  e?.email_status[0] &&
                  e?.email_status[0].id === "DNC"
                    ? true
                    : false
                }
                clearable={false}
                name={field.name}
                size={SIZE.mini}
                error={
                  errors &&
                  errors["email"] &&
                  errors["email"][i] &&
                  errors["email"][i].status &&
                  errors["email"][i].status.message
                }
                label={intl.formatMessage({
                  id: `email_status`,
                })}
                placeholder={intl.formatMessage({
                  id: "email_status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["contact_comm_status"]}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default ConatctEmailFields;
