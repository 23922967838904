import React from "react";

const RelManager = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8061 11.1523C12.0634 11.1523 13.1522 10.7014 14.0418 9.81163C14.9314 8.92202 15.3824 7.83353 15.3824 6.57603C15.3824 5.31895 14.9314 4.23032 14.0417 3.34042C13.1519 2.45096 12.0633 2 10.8061 2C9.54854 2 8.46006 2.45096 7.57045 3.34057C6.68084 4.23018 6.22974 5.31881 6.22974 6.57603C6.22974 7.83353 6.68084 8.92217 7.5706 9.81177C8.46035 10.7012 9.54898 11.1523 10.8061 11.1523Z"
        fill="#516beb"
      />
      <path
        d="M18.8133 16.612C18.7876 16.2418 18.7357 15.8379 18.6594 15.4114C18.5822 14.9818 18.4829 14.5756 18.3641 14.2044C18.2413 13.8207 18.0743 13.4418 17.8679 13.0786C17.6536 12.7017 17.402 12.3736 17.1196 12.1035C16.8243 11.821 16.4628 11.5938 16.0448 11.4281C15.6281 11.2633 15.1665 11.1798 14.6726 11.1798C14.4786 11.1798 14.2911 11.2594 13.9288 11.4953C13.7059 11.6407 13.4451 11.8088 13.154 11.9948C12.9051 12.1534 12.5679 12.302 12.1515 12.4365C11.7452 12.5679 11.3326 12.6346 10.9254 12.6346C10.5182 12.6346 10.1058 12.5679 9.69908 12.4365C9.28305 12.3021 8.94588 12.1535 8.69728 11.9949C8.40895 11.8107 8.14803 11.6425 7.92175 11.4951C7.55994 11.2593 7.37222 11.1797 7.17826 11.1797C6.68425 11.1797 6.2227 11.2633 5.80624 11.4283C5.38847 11.5937 5.0268 11.8208 4.73123 12.1036C4.449 12.3738 4.19721 12.7019 3.98325 13.0786C3.77697 13.4418 3.60998 13.8205 3.48706 14.2045C3.36834 14.5758 3.26904 14.9818 3.19192 15.4114C3.11553 15.8373 3.06364 16.2413 3.03798 16.6124C3.01276 16.976 3 17.3533 3 17.7344C3 18.7262 3.31528 19.5291 3.93701 20.1213C4.55105 20.7056 5.36354 21.002 6.35157 21.002H15.5001C16.4882 21.002 17.3004 20.7057 17.9146 20.1213C18.5364 19.5295 18.8517 18.7265 18.8517 17.7342C18.8516 17.3514 18.8387 16.9738 18.8133 16.612Z"
        fill="#516beb"
      />
    </svg>
  );
};

export default RelManager;
