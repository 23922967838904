import React from "react";

const UnPin = ({ size = 22, fill = "#516BEB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9538 20C8.46001 20 6.34427 19.1247 4.60656 17.3742C2.86885 15.6236 2 13.5052 2 11.019C2 8.53281 2.86885 6.40808 4.60656 4.64484C6.34427 2.88161 8.46194 2 10.9596 2C12.4822 2 13.8389 2.31231 15.0298 2.93694C16.2207 3.56157 17.2557 4.43128 18.1347 5.54607V2.92424C18.1347 2.66872 18.2183 2.45081 18.3857 2.2705C18.5531 2.09017 18.7733 2 19.0462 2C19.3093 2 19.534 2.09017 19.7204 2.2705C19.9068 2.45081 20 2.66872 20 2.92424V8.3926C20 8.71128 19.8894 8.97688 19.6682 9.1894C19.447 9.40191 19.1827 9.50817 18.8753 9.50817H13.4203C13.1703 9.50817 12.957 9.41586 12.7805 9.23126C12.6039 9.04663 12.5157 8.8234 12.5157 8.56155C12.5157 8.30396 12.6054 8.08733 12.7848 7.91165C12.9642 7.73596 13.181 7.64812 13.4351 7.64812H16.9709C16.2678 6.60784 15.4242 5.77962 14.4404 5.16346C13.4565 4.54728 12.2929 4.23919 10.9496 4.23919C9.06224 4.23919 7.46436 4.89424 6.15593 6.20436C4.84748 7.51445 4.19325 9.1183 4.19325 11.0159C4.19325 12.9228 4.84748 14.5295 6.15593 15.8358C7.46436 17.1422 9.06224 17.7954 10.9496 17.7954C12.1809 17.7954 13.3327 17.4827 14.4049 16.8575C15.4772 16.2322 16.3054 15.3624 16.8895 14.2482C17.0585 13.9869 17.28 13.8055 17.554 13.7041C17.828 13.6028 18.1072 13.6062 18.3916 13.7146C18.6789 13.8129 18.8759 13.9964 18.9825 14.2649C19.0891 14.5334 19.0797 14.7925 18.9544 15.0422C18.1962 16.5673 17.099 17.7748 15.6628 18.6649C14.2266 19.555 12.657 20 10.9538 20Z"
        fill={fill}
      />
    </svg>
  );
};

export default UnPin;
