import React from 'react'

const Replay = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.78794 8.77281L7.30117 11.2897C7.40646 11.3949 7.45934 11.5179 7.45983 11.6585C7.46031 11.7991 7.4067 11.9245 7.29901 12.0346C7.18893 12.137 7.06417 12.1886 6.92474 12.1893C6.78532 12.19 6.66176 12.1353 6.55408 12.0252L3.22884 8.69998C3.09711 8.57066 3.03125 8.42066 3.03125 8.24998C3.03125 8.07931 3.09711 7.92811 3.22884 7.79638L6.55985 4.46537C6.66129 4.36394 6.78232 4.31298 6.92294 4.3125C7.06357 4.31202 7.18893 4.36297 7.29901 4.46537C7.4067 4.57547 7.46054 4.70059 7.46054 4.84073C7.46054 4.98087 7.4067 5.10478 7.29901 5.21247L4.78794 7.72356H11.7355C12.7024 7.72356 13.5252 8.06454 14.204 8.7465C14.8829 9.42848 15.2223 10.2498 15.2223 11.2103V13.1971C15.2223 13.3428 15.171 13.4669 15.0683 13.5695C14.9657 13.6722 14.8428 13.7235 14.6995 13.7235C14.5538 13.7235 14.4297 13.6722 14.327 13.5695C14.2244 13.4669 14.1731 13.3428 14.1731 13.1971V11.2103C14.1731 10.5372 13.9351 9.96272 13.4591 9.48675C12.9831 9.01079 12.4086 8.77281 11.7355 8.77281H4.78794Z" fill="#787878"/>
    </svg>
  )
}

export default Replay