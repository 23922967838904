import React from "react";

const DoubleTik = () => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.36694 7.82221C8.52376 7.82221 8.6798 7.75713 8.791 7.62939L13.6324 2.08072C13.8367 1.84672 13.8126 1.49127 13.5783 1.28726C13.3451 1.08318 12.9897 1.10652 12.7848 1.34137L7.94343 6.89004C7.73907 7.12404 7.76326 7.47948 7.99754 7.6835C8.10408 7.77659 8.23565 7.82221 8.36694 7.82221Z"
        fill="#516BEB"
      />
      <path
        d="M17.3182 0.802576C17.0847 0.598782 16.7293 0.622407 16.5247 0.856689L8.60905 9.92835L4.78304 5.68074C4.57457 5.44978 4.21919 5.43111 3.98845 5.63929C3.75771 5.84719 3.73904 6.20291 3.94699 6.43388L6.1795 8.91236L5.29502 9.92756L1.46986 5.68136C1.26139 5.4504 0.906006 5.43173 0.675268 5.63991C0.444531 5.84809 0.425856 6.20353 0.633812 6.43449L4.88417 11.1523C4.99099 11.2706 5.14292 11.3383 5.30222 11.3383H5.30689C5.46782 11.3369 5.62054 11.2666 5.72629 11.1452L6.93797 9.75437L8.19769 11.1529C8.30451 11.2712 8.45644 11.3388 8.61574 11.3388H8.62013C8.78106 11.3375 8.93378 11.2674 9.03953 11.146L17.3724 1.59615C17.5766 1.36204 17.5525 1.00693 17.3182 0.802576Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default DoubleTik;
