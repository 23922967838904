import React, { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

function Role(props) {
  let { referenceData } = useContext(MaxyfiContext);
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  const userRole = referenceData?.user_role?.find(({ id }) => id == value);

  return (
    <div style={{ textAlign: alignment, width: "100%" }}>{userRole?.label}</div>
  );
}

export default Role;
