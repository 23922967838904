import {
  DOCUMENT_DRAWER_CLOSE,
  DOCUMENT_DRAWER_OPEN,
  CUSTOMER_DRIVE_OPEN,
  ADD_DOCUMENT_FORM,
  INITIALSTATE,
  DOCUMENT_DRIVE_DATA,
  EDIT_DOCUMENT_FORM,
  TYPE_FORM_INITIAL,
  EMAIL_ATTACHED_FILE_ALL_DATA,
  EMAIL_ATTACHMENT_STATUS,
  EMAIL_ATTACHMENT_ID,
  EMAIL_FINAL_ATTACHMENT_DATA,
  ADD_EMAIL_ATTACHMENT_FROM_DESKTOP,
  UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP,
  UNSELECTED_DOCUMENT_DATA,
  REFETCH_DOCUMENT_DATA,
  DOCUMENT_DRIVE_DATA_QUICK_ACTION,
  FORM_INITIAL,
  CREDIT_DRAWER_CLOSE,
} from "./action";
import { NEW, EDIT, EMAIL } from "../../../constants";

const initialState = {
  drawerType: "",
  drawerState: false,
  emailAttachment: [],
  emailSelectedAttachment: [],
  emailAttachmentStatus: "",
  emailAttachmentId: "no",
  emailFinalData: [],
  documentdetails: [],
  refetchDocument: 0,
};

const customerDocumentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case REFETCH_DOCUMENT_DATA: {
      const { refetchDocument } = state;
      return {
        ...state,
        refetchDocument: refetchDocument + 1,
      };
    }
    case EMAIL_ATTACHMENT_ID: {
      return {
        ...state,
        emailAttachmentId: payload,
      };
    }
    case EMAIL_ATTACHMENT_STATUS: {
      return {
        ...state,
        emailAttachmentStatus: payload,
      };
    }
    case EMAIL_ATTACHED_FILE_ALL_DATA: {
      let data = [payload];

      return {
        ...state,
        emailSelectedAttachment: data,
      };
    }

    case DOCUMENT_DRAWER_OPEN:
      return {
        ...state,
        drawerState: payload,
      };

    case DOCUMENT_DRAWER_CLOSE:
      return {
        ...state,
        drawerState: payload,
        drawerType: "",
      };
    case CUSTOMER_DRIVE_OPEN:
      return {
        ...state,
        drawerState: payload,
        drawerType: EMAIL,
      };

    case ADD_DOCUMENT_FORM:
      return {
        ...state,
        drawerType: NEW,
      };

    case DOCUMENT_DRIVE_DATA:
      let attachData = [...state.emailAttachment];
      attachData.push(...payload);

      return {
        ...state,
        emailAttachment: attachData,
      };

    case DOCUMENT_DRIVE_DATA_QUICK_ACTION:
      let attachQuickActionData = [...state.emailAttachment];
      attachQuickActionData.push(...payload);

      return {
        ...state,
        emailAttachment: attachQuickActionData,
      };

    case UNSELECTED_DOCUMENT_DATA: {
      let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.emailAttachment.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        emailAttachment: filteredData,
      };
    }

    case ADD_EMAIL_ATTACHMENT_FROM_DESKTOP: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.emailAttachment;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;
        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        emailAttachment: [...currentFiles, ...files],
      };
    }

    case UPDATE_EMAIL_ATTACHMENT_FROM_DESKTOP: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.emailAttachment.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let emailAttachment = state.emailAttachment;
        let currentFile = emailAttachment[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        emailAttachment[fileIndex] = currentFile;

        return { ...state, emailAttachment: emailAttachment };
      }

      return state;
    }

    // case EMAIL_FINAL_ATTACHMENT_DATA: {
    //   let newState = { ...state };

    //   // SPLITING ATTACHMENT TYPE
    //   let splitingSelectedFileType =
    //     newState.emailSelectedAttachment &&
    //     newState.emailSelectedAttachment[0].type.split("/");
    //

    //   // EMAIL ATTACHMENT STRUCTURED DATA
    //   let emailAttachmentStructuredData = {
    //     fileName:
    //       newState.emailSelectedAttachment &&
    //       newState.emailSelectedAttachment[0].name,
    //     type: splitingSelectedFileType[1],
    //     file_ref: newState.emailAttachmentId,
    //     scanStatus: newState.emailAttachmentStatus,
    //   };

    //   // PUSHING CURRENT DATA AND OLD EMAIL DATAS
    //   let finalData = [...state.emailFinalData];
    //   finalData.push(emailAttachmentStructuredData);

    //   // WANT TO FIND OLD DATA AND CURRENT DATA IS SAME
    //   const finalFilteredData = [];
    //   {
    //     if (finalData && finalData.length > 0) {
    //       finalData.filter((e, i) => {
    //         if (e.file_ref === emailAttachmentStructuredData.file_ref) {
    //           let fd = finalData[finalData.length - 1];
    //
    //           finalFilteredData.push(fd);
    //         }
    //       });
    //     }
    //     //  else {
    //     //
    //     //   finalData.push(emailAttachmentStructuredData);
    //     // }
    //   }

    //   return {
    //     ...state,
    //     emailFinalData: finalData,
    //   };
    // }
    // case INITIALSTATE:
    //   return {
    //     ...initialState,
    //   };

    case EDIT_DOCUMENT_FORM:
      return {
        ...state,
        drawerType: EDIT,
        documentdetails: payload,
      };

    case TYPE_FORM_INITIAL:
      return {
        ...state,
        drawerType: initialState.drawerType,
        emailAttachment: initialState.emailAttachment,
      };

    case FORM_INITIAL:
      return initialState;

    default:
      return initialState;
  }
};

export default customerDocumentReducer;
