import React from "react";
import { TextButton, KIND, SIZE } from "../../../../components/TextButton";
import { useForm, Controller } from "react-hook-form";
import getSelectValues from "../../../../utils/getSelectValues";
import { LabelLarge, ParagraphLarge } from "baseui/typography";
import { SelectBox } from "../../../../components/SelectBox";
import { useIntl } from "react-intl";

const SetWorkFlow = ({ setState, toggleAllRowsSelected }) => {
  const intl = useIntl();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = (data) => {
    let values = {
      ...data,
      choose_workflow: getSelectValues(data.choose_workflow),
    };
    toggleAllRowsSelected(false);
    // setNextState(true);
  };
  return (
    <>
      <form
        style={{
          boxShadow: "0px 2px 4px 2px #d6d6d6",
          background: "#FFFFFF",
          border: "0.5px solid #CDCED925",

          borderRadius: "5px",
          width: "205px",
          height: "200px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <LabelLarge
          style={{
            paddingTop: "16px",
            textAlign: "center",
            color: "#516BEB",
          }}
        >
          Assign Workflow Action
        </LabelLarge>

        <div
          style={{
            paddingTop: "25px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Controller
            defaultValues={[]}
            name="choose_workflow"
            control={control}
            render={({ field }) => (
              <SelectBox
                size={SIZE.default}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "choose_workflow",
                })}
                placeholder={intl.formatMessage({
                  id: "choose_workflow",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={[
                  { label: "Org 1 (Dummy)", id: "org_1" },
                  { label: "Org 2 (Dummy)", id: "org_2" },
                ]}
              />
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 10px",
            paddingTop: "10px",
          }}
        >
          <div style={{ width: "80px" }}>
            <TextButton
              fullWidth
              onClick={() => {
                setState(false);
              }}
              size={SIZE.compact}
            >
              Back
            </TextButton>
          </div>
          <div style={{ width: "80px" }}>
            <TextButton type="submit" fullWidth size={SIZE.compact}>
              Submit
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default SetWorkFlow;
