import React, { useContext, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { LabelLarge } from "baseui/typography";

import { useQuery } from "react-query";
import { useEffect } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { getCustomerAnalysisDashboard } from "../../../services/organization";
import { GET_USER_POSITION_DASHBOARD_ } from "../../../constants";
import { getPerformancePosition } from "../../../services/revenueBilling";
import useFormat from "../../../hooks/useFormat";
import _ from "lodash";
import moment from "moment";
const BarchartPerformance = ({ isPerformance, setIsPerformance, value }) => {
  const {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    users,
  } = useContext(MaxyfiContext);

  const { user_id, ...rest } = isPerformance;

  const format = useFormat();

  const { data, isFetched, isError, isLoading, isFetching } = useQuery(
    [
      `${GET_USER_POSITION_DASHBOARD_}${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
          ...isPerformance,
          ...(value.startDate && value.endDate
            ? {
                month: [
                  moment
                    .tz(value.startDate, currentDefaultFormatDetails.time_zone)
                    .startOf("month")
                    .utc()
                    .valueOf(),
                  moment
                    .tz(value.endDate, currentDefaultFormatDetails.time_zone)
                    .endOf("month")
                    .utc()
                    .valueOf(),
                ],
              }
            : {}),
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getPerformancePosition({
        organization: currentOrganization,
        // entity: "LAST_ACTION_BY_RM",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 1800000,
    }
  );

  // let subbar = {
  //   height: data && data.data && data.data.doc.length > 2 ? "330px" : "100px",
  // };
  // if (data && data.data && data.data.doc.length > 7) {
  //   const Height = 300 + (data && data.data && data.data.doc.length - 7) * 49;
  //   subbar = { height: `${Height}px` };
  // }

  // let totalCount = [];
  // let newObject = [];
  // let LabelSmall = [];

  // data &&
  //   data.data &&
  //   data.data.doc.map((a) => {
  //     let findUser = a?._id ? users?.find(({ id }) => id == a?._id) : [];

  //     newObject.push({
  //       count: a.count,
  //       label: findUser?.displayName,
  //     });
  //   });

  // newObject.sort(function (a, b) {
  //   return b.count - a.count;
  // });
  // newObject.map((e) => {
  //   if (e.label !== undefined && e.label !== "" && e.label !== null) {
  //     totalCount.push(e.count);
  //     LabelSmall.push(e.label);
  //   }
  // });

  // // function getRandomColor() {
  // var letters = "0123456789ABCDEF";
  // let color = "#";
  // for (var i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }

  // }

  const { labelKey, count, diffColor } = useMemo(() => {
    let labelKey = [];
    let count = [];
    let diffColor = [];
    if (data && data.data && data.data.data) {
      data.data.data?.map((e) => {
        labelKey.push(
          e?.usi
            ? format.rd({
                id: _.get(e, "usi", ""),
                name: "users",
              })
            : ""
        );

        count.push(e.count ? e.count : "");

        diffColor.push(e.usi === isPerformance.user_id ? "#36A2EB" : "#4BC0C0");
      });
    }
    return { labelKey, count, diffColor };
  }, [isLoading, isFetched, isFetching]);

  return (
    <div style={{ height: "auto" }}>
      <Bar
        options={{
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
              position: "top",
            },
            title: {
              display: false,
              text: "Chart.js Horizontal Bar Chart",
            },
          },
        }}
        data={{
          labels: [...labelKey],
          datasets: [
            {
              data: [...count],
              barThickness: 20,
              borderColor: "#ffffff",
              borderWidth: 0,
              backgroundColor: [...diffColor],
            },
          ],
        }}
        id="chart"
      />
    </div>
  );
};

export default BarchartPerformance;
