export const ADD_CONTACT_MODAL = "ADD_CONTACT_MODAL";
export const CLOSE_CONTACT_MODAL = "CLOSE_CONTACT_MODAL";
export const ADD_EDIT_MODAL = "ADD_EDIT_MODAL";
export const CLOSE_EDIT_MODAL = "CLOSE_EDIT_MODAL";
export const ADD_FIELDS = "ADD_FIELDS";
export const GET_ACTION_DATA = "GET_ACTION_DATA";
export const EDIT_CONTACT_MODAL = "EDIT_CONTACT_MODAL";
export const VIEW_CONTACT_MODAL = "VIEW_CONTACT_MODAL";
export const GET_CUSTOMER_DETAILS = "GET_CUSTOMER_DETAILS";
export const REMOVE_FIELD = "REMOVE_FIELD";
export const MODAL_ISOPEN = "MODAL_ISOPEN";
// Hold Modal
export const HOLD_ACTION_ISOPEN = "HOLD_ACTION_ISOPEN";
// Skip Modal
export const SKIP_ACTION_MODAL = "SKIP_ACTION_MODAL";
// Release Modal
export const RELEASE_ACTION_MODAL = "RELEASE_ACTION_MODAL";
//Review Promise Table Button Modal
export const REVIEW_PROMISE_TABLE_BUTTON = "REVIEW_PROMISE_TABLE_BUTTON";
export const REVIEW_PROMISE_TABLE_POP_UP = "REVIEW_PROMISE_TABLE_POP_UP";
//
export const WORKFLOW_ADD_MODAL = "WORKFLOW_ADD_MODAL";

export const REFETCH_ACTIONS = "REFETCH_ACTIONS";
export const REFETCH_TIMELINE = "REFETCH_TIMELINE";

export const UPDATE_ATTACHMENTS = "UPDATE_ATTACHMENTS";

export const REMOVE_ATTACHED_FILES = "REMOVE_ATTACHED_FILES";

export const CO_ADD_ATTACHED_FILES = "CO_ADD_ATTACHED_FILES";
export const CO_REMOVE_ATTACHED_FILES = "CO_REMOVE_ATTACHED_FILES";

export const UNASSIGN_WORKFLOW = "UNASSIGN_WORKFLOW";

export const CO_ADHOC_ADD_ATTACHED_FILES = "CO_ADHOC_ADD_ATTACHED_FILES";
export const CO_ADHOC_REMOVE_ATTACHED_FILES = "CO_ADHOC_REMOVE_ATTACHED_FILES";
export const DISPUTE_DATA = "DISPUTE_DATA";

// Tabs Motion

export const TAB_MOTION = "TAB_MOTION";

export const RESET_STATE = "RESET_STATE";
// Removing Tag Data on Delete

export const REMOVE_TAG = "REMOVE_TAG";

// Dairy Note Alert

export const NOTE_ALERT = "NOTE_ALERT";

export const ACTIVE_WORKFLOW_DATA = "ACTIVE_WORKFLOW_DATA";

export const CUSTOMER_ACTION_DATAS = "CUSTOMER_ACTION_DATAS";

export const SMS_GATEWAY_DATA = "SMS_GATEWAY_DATA";

export const DRAWER_OPEN = "DRAWER_OPEN";
export const DRAWER_CLOSE = "DRAWER_CLOSE";
export const EMAIL_DATA_TO_DRAWER = "EMAIL_DATA_TO_DRAWER";
export const EMAIL_DRAWER_INITIAL = "EMAIL_DRAWER_INITIAL";
export const CUSTOMER_CONTACT_DATA = "CUSTOMER_CONTACT_DATA";
export const PROMISE_TO_PAY_DATA = "PROMISE_TO_PAY_DATA";
export const ADD_INVOICE_OPEN_MODAL = "ADD_INVOICE_OPEN_MODAL";
export const SUBSCRIPTIONS_DRAWER = "SUBSCRIPTIONS_DRAWER";
export const ORGANATION_DTL = "ORGANATION_DTL";
export const SMS_DATA_TO_DRAWER = "SMS_DATA_TO_DRAWER";

export const subscriptionsDrawer = (isOpen) => {
  return {
    type: SUBSCRIPTIONS_DRAWER,
    payload: isOpen,
  };
};

export const addInvoiceOpenModal = (isOpen) => {
  return {
    type: ADD_INVOICE_OPEN_MODAL,
    payload: isOpen,
  };
};

export const smsGateway = (smsData) => {
  return {
    type: SMS_GATEWAY_DATA,
    payload: smsData,
  };
};

export const unAssignWorkflow = () => {
  return {
    type: UNASSIGN_WORKFLOW,
  };
};

export const customerActionDatas = (data) => {
  return {
    type: CUSTOMER_ACTION_DATAS,
    payload: data,
  };
};

export const activeWorkflowData = (data) => {
  return {
    type: ACTIVE_WORKFLOW_DATA,
    payload: data,
  };
};

export const addCOEmailAttachment = (data) => {
  return {
    type: CO_ADD_ATTACHED_FILES,
    payload: data,
  };
};

export const removeCOEmailAttachment = (data) => {
  return {
    type: CO_REMOVE_ATTACHED_FILES,
    payload: data,
  };
};

export const addCOAdhocEmailAttachment = (data) => {
  return {
    type: CO_ADD_ATTACHED_FILES,
    payload: data,
  };
};

export const removeCOAdhocEmailAttachment = (data) => {
  return {
    type: CO_REMOVE_ATTACHED_FILES,
    payload: data,
  };
};

export const expandClick = (data) => {
  return {
    type: NOTE_ALERT,
    payload: data,
  };
};

export const tagDelete = (tag) => {
  return {
    type: REMOVE_TAG,
    payload: tag,
  };
};

export const tabMotion = (tab) => {
  return {
    type: TAB_MOTION,
    payload: tab,
  };
};

export const resetCustomerOverviewState = () => {
  return {
    type: RESET_STATE,
  };
};

export const uploadEmailFile = (uploadTemplate) => {
  return {
    type: UPDATE_ATTACHMENTS,
    payload: uploadTemplate,
  };
};

export const removeEmailAttachedFile = (removeFile) => {
  return {
    type: REMOVE_ATTACHED_FILES,
    payload: removeFile,
  };
};

export const reviewTablePopUp = (data) => {
  return {
    type: REVIEW_PROMISE_TABLE_POP_UP,
    payload: data,
  };
};
export const workflowAddModal = (data) => {
  return {
    type: WORKFLOW_ADD_MODAL,
    payload: data,
  };
};
export const reviewPromiseAction = (data) => {
  return {
    type: REVIEW_PROMISE_TABLE_BUTTON,
    payload: data,
  };
};

export const releaseActionModal = (data) => {
  return {
    type: RELEASE_ACTION_MODAL,
    payload: data,
  };
};

export const skipActionModal = (data) => {
  return {
    type: SKIP_ACTION_MODAL,
    payload: data,
  };
};

export const refetchActions = () => {
  return {
    type: REFETCH_ACTIONS,
  };
};

export const refetchTimeline = () => {
  return {
    type: REFETCH_TIMELINE,
  };
};

export const getCustomerDetailsAction = (customerDetails) => {
  return {
    type: GET_CUSTOMER_DETAILS,
    payload: customerDetails,
  };
};

export const holdActionModal = (data) => {
  return {
    type: HOLD_ACTION_ISOPEN,
    payload: data,
  };
};

export const isOpenModal = (data) => {
  return {
    type: MODAL_ISOPEN,
    payload: data,
  };
};

export const addContactModal = () => {
  return {
    type: ADD_CONTACT_MODAL,
  };
};
export const editeContactModal = () => {
  return {
    type: EDIT_CONTACT_MODAL,
  };
};
export const closeContactModal = () => {
  return {
    type: CLOSE_CONTACT_MODAL,
  };
};

export const editContactModal = (contactData) => {
  return {
    type: ADD_EDIT_MODAL,
    payload: contactData,
  };
};
export const addField = (field) => {
  return {
    type: ADD_FIELDS,
    payload: field,
  };
};

export const getActionData = (data) => {
  return {
    type: GET_ACTION_DATA,
    payload: data,
  };
};

export const viewContactData = (contactData) => {
  return {
    type: VIEW_CONTACT_MODAL,
    payload: contactData,
  };
};

export const removeField = (field, index) => {
  return {
    type: REMOVE_FIELD,
    payload: {
      field,
      index,
    },
  };
};

export const drawerOpen = () => {
  return {
    type: DRAWER_OPEN,
  };
};

export const drawerClose = () => {
  return {
    type: DRAWER_CLOSE,
  };
};

export const emailDataToDrawer = ({ emailType, emailData, isReceiver }) => {
  return {
    type: EMAIL_DATA_TO_DRAWER,
    payload: { emailType, emailData, isReceiver },
  };
};

export const smsDataToDrawer = (payload) => {
  return {
    type: SMS_DATA_TO_DRAWER,
  };
};

export const promiseTopayDataToDrawer = ({
  emailType,
  emailData,
  isReceiver,
  promiseDate,
  customerName,
  rmanager,
  promiseStatus,
  promiseSubject,
  promiseBody,
  drawerType,
}) => {
  return {
    type: PROMISE_TO_PAY_DATA,
    payload: {
      emailType,
      emailData,
      isReceiver,
      promiseDate,
      customerName,
      rmanager,
      promiseStatus,
      promiseSubject,
      promiseBody,
      drawerType,
    },
  };
};

export const disputeDataToDrawer = ({
  emailType,
  emailData,
  isReceiver,
  promiseDate,
  customerName,
  dispudeStatus,
  invNumber,
  rmanager,
}) => {
  return {
    type: DISPUTE_DATA,
    payload: {
      emailType,
      emailData,
      isReceiver,
      promiseDate,
      customerName,
      dispudeStatus,
      invNumber,
      rmanager,
    },
  };
};

export const emailInitinalState = ({ emailType, emailData, isReceiver }) => {
  return {
    type: EMAIL_DRAWER_INITIAL,
    payload: { emailType, emailData, isReceiver },
  };
};

export const customerConatctData = ({ conatctData }) => {
  return {
    type: CUSTOMER_CONTACT_DATA,
    payload: { conatctData },
  };
};

export const organizationDtl = ({ orgDtl }) => {
  return {
    type: ORGANATION_DTL,
    payload: { orgDtl },
  };
};
