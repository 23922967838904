import React from "react";
import DocFileType from "../../assets/img/svg/DocFileType";
import { Typography } from "../../components_v2";
import useFormat from "../../hooks/useFormat";
import { DMS_DRAWER } from "../../redux/actions";

const DocumentType = ({ type }) => {
  switch (type) {
    case "pdf":
      return (
        <div className="file_wraper_icon">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "jpg":
    case "jpeg":
    case "png":
    case "bmp":
      return (
        <div className="file_wraper_icon --doc_purple">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "csv":
    case "xls":
    case "xlsx":
      return (
        <div className="file_wraper_icon --doc_green">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "doc":
    case "docx":
      return (
        <div className="file_wraper_icon --doc_blue">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
          >
            {type.toUpperCase()}
          </p>
        </div>
      );

    case "txt":
    case "plain":
      return (
        <div className="file_wraper_icon --doc_grey">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type.length >= 4 && "--doc_font_size"
            }`}
            style={{ paddingLeft: "2px" }}
          >
            {type === "plain" ? "TXT" : type.toUpperCase()}
            {/* {type.toUpperCase()} */}
          </p>
        </div>
      );

    default:
      return (
        <div className="file_wraper_icon --doc_yellow">
          <DocFileType />
          <p
            className={`file_text_wraper ${
              type?.length >= 4 && "--doc_font_size"
            }`}
          >
            {type?.toUpperCase()}
          </p>
        </div>
      );
  }
};

const DocQuickView = (props) => {
  const format = useFormat();
  return (
    <>
      <div
        className="doc_quick_view_container"
        onClick={() => props?.open(DMS_DRAWER)}
      >
        <DocumentType
          type={props && props.mime_type && props.mime_type.split("/")[1]}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography className="doc_file_name">{props?.file_name}</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              type={"p"}
              subType={"small"}
              className={"text-secondary doc_cus_name"}
            >
              {format.rd({ name: "users", id: props?.created_by })?.length > 0
                ? format.rd({ name: "users", id: props?.created_by })
                : "Portal"}
            </Typography>
            <Typography
              type={"p"}
              subType={"small"}
              className={"text-secondary"}
            >
              {format.date({ isOrg: true, value: props?.created_at }).length > 0
                ? `/${format.date({ isOrg: true, value: props?.created_at })}`
                : "Portal"}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocQuickView;
