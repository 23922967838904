import React from "react";

const SMS = ({ width = 22, height = 22, color = "#787878" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01217 10.0143C7.2719 10.0143 7.4896 9.92641 7.6653 9.75072C7.84099 9.57502 7.92884 9.35731 7.92884 9.09759C7.92884 8.83787 7.84099 8.62016 7.6653 8.44447C7.4896 8.26877 7.2719 8.18093 7.01217 8.18093C6.75245 8.18093 6.53474 8.26877 6.35905 8.44447C6.18336 8.62016 6.09551 8.83787 6.09551 9.09759C6.09551 9.35731 6.18336 9.57502 6.35905 9.75072C6.53474 9.92641 6.75245 10.0143 7.01217 10.0143ZM11.0684 10.0143C11.3281 10.0143 11.5459 9.92641 11.7215 9.75072C11.8972 9.57502 11.9851 9.35731 11.9851 9.09759C11.9851 8.83787 11.8972 8.62016 11.7215 8.44447C11.5459 8.26877 11.3281 8.18093 11.0684 8.18093C10.8087 8.18093 10.591 8.26877 10.4153 8.44447C10.2396 8.62016 10.1518 8.83787 10.1518 9.09759C10.1518 9.35731 10.2396 9.57502 10.4153 9.75072C10.591 9.92641 10.8087 10.0143 11.0684 10.0143ZM14.9643 10.0143C15.224 10.0143 15.4417 9.92641 15.6174 9.75072C15.7931 9.57502 15.8809 9.35731 15.8809 9.09759C15.8809 8.83787 15.7931 8.62016 15.6174 8.44447C15.4417 8.26877 15.224 8.18093 14.9643 8.18093C14.7045 8.18093 14.4868 8.26877 14.3111 8.44447C14.1354 8.62016 14.0476 8.83787 14.0476 9.09759C14.0476 9.35731 14.1354 9.57502 14.3111 9.75072C14.4868 9.92641 14.7045 10.0143 14.9643 10.0143ZM5.49967 16.4997L3.00176 18.9976C2.78787 19.2115 2.53961 19.2599 2.25697 19.143C1.97433 19.026 1.83301 18.8171 1.83301 18.5163V3.20801C1.83301 2.84134 1.97051 2.52051 2.24551 2.24551C2.52051 1.97051 2.84134 1.83301 3.20801 1.83301H18.7913C19.158 1.83301 19.4788 1.97051 19.7538 2.24551C20.0288 2.52051 20.1663 2.84134 20.1663 3.20801V15.1247C20.1663 15.4913 20.0288 15.8122 19.7538 16.0872C19.4788 16.3622 19.158 16.4997 18.7913 16.4997H5.49967ZM3.20801 15.1247H18.7913V3.20801H3.20801V15.1247Z"
        fill={color}
      />
    </svg>
  );
};
export default SMS;
