import React, { useContext } from "react";
import ToolTip from "../ToolTip/ToolTip";
import { FormattedNumber } from "react-intl";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { NumericFormat } from "react-number-format";
import _ from "lodash";

function Currency(props) {
  let { value, column, alignment, accessor, row, isCurrency, ...rest } = props;

  value = _.get(row.original, accessor, 0);

  const [visible, setVisible] = React.useState(false);
  let {
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        value ? (
          <NumericFormat
            displayType="text"
            value={_.get(value, "value", "")}
            thousandsGroupStyle={currencyGroupStyle}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={currencySeparator}
            decimalSeparator={currencyDecimalSeparator}
            renderText={(values) => (
              <div style={{ display: "flex", justifyContent: alignment }}>
                {isCurrency ? (
                  <></>
                ) : (
                  <div
                    style={{
                      fontSize: "8px",
                      lineHeight: "10px",
                      paddingRight: "3px",
                    }}
                  >
                    {_.get(value, "currency", "")}
                  </div>
                )}{" "}
                <Paragraph2
                  ref={ref}
                  $style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "100%",
                    textAlign: alignment,
                    color: "#ffffff",
                    lineHeight: "18px",
                  }}
                >
                  {values}
                </Paragraph2>
              </div>
            )}
          />
        ) : (
          <></>
        )
      }
    >
      <div className="elip" style={{ width: "100%" }} ref={ref}>
        {value ? (
          <NumericFormat
            displayType="text"
            value={
              value && typeof value === "object"
                ? _.get(value, "value", "")
                : value
            }
            thousandsGroupStyle={currencyGroupStyle}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={currencySeparator}
            decimalSeparator={currencyDecimalSeparator}
            renderText={(values) => (
              <div style={{ display: "flex", justifyContent: alignment }}>
                {isCurrency ? (
                  <></>
                ) : (
                  <div
                    style={{
                      fontSize: "8px",
                      lineHeight: "10px",
                      paddingRight: "3px",
                    }}
                  >
                    {_.get(value, "currency", "")}
                  </div>
                )}{" "}
                <Paragraph2
                  $style={{
                    textAlign: alignment,
                    // margin: "5px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "18px",
                  }}
                >
                  {values}
                </Paragraph2>
              </div>
            )}
          />
        ) : (
          <></>
        )}{" "}
      </div>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: alignment,
        // margin: "5px",
        lineHeight: "18px",
      }}
    >
      {value ? (
        <NumericFormat
          displayType="text"
          value={_.get(value, "value", "")}
          thousandsGroupStyle={currencyGroupStyle}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={currencySeparator}
          decimalSeparator={currencyDecimalSeparator}
          renderText={(values) => (
            <div style={{ display: "flex", justifyContent: alignment }}>
              {isCurrency ? (
                <></>
              ) : (
                <div
                  style={{
                    fontSize: "8px",
                    lineHeight: "10px",
                    paddingRight: "3px",
                  }}
                >
                  {_.get(value, "currency", "")}
                </div>
              )}{" "}
              <Paragraph2
                $style={{
                  textAlign: alignment,
                  // margin: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  lineHeight: "18px",
                }}
                style={{ lineHeight: "18px" }}
              >
                {values}
              </Paragraph2>
            </div>
          )}
        />
      ) : (
        <>-</>
      )}
    </Paragraph2>
  );
}
export default Currency;
