const UploadCustomerInvoice = () => {
  return (
    <>
      <svg
        width="13"
        height="9"
        viewBox="0 0 13 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.01328 7.25884L1.32095 4.7803C1.01835 4.50173 0.529543 4.50173 0.226947 4.7803C-0.0756491 5.05887 -0.0756491 5.50886 0.226947 5.78743L3.47016 8.77311C3.77275 9.05168 4.26156 9.05168 4.56416 8.77311L12.7731 1.21606C13.0756 0.937489 13.0756 0.487494 12.7731 0.208926C12.4705 -0.069642 11.9816 -0.069642 11.679 0.208926L4.01328 7.25884Z"
          fill="#0FB158"
        />
      </svg>
    </>
  );
};

export default UploadCustomerInvoice;
