import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from "baseui/checkbox";
import React, { useContext } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { refetchUser } from "../../../redux/users/actions";
import { disableUsersData } from "../../../services/users";
import { useDispatch } from "react-redux";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import { ENABLE_DISABLE_USER } from "../../../providers/RBACProvider/permissionList";

const UserStatus = (props) => {
  let dispatch = useDispatch();
  let { currentOrganization, currencyFormat, userInfo } =
    useContext(MaxyfiContext);
  const disableUser = useMutation(
    (data) =>
      disableUsersData({ organization: currentOrganization, userId: data }),

    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context, error) => {
        dispatch(refetchUser());
      },
    }
  );

  const currentUser = userInfo._id === props.row.original._id;
  return (
    <div style={{ display: "flex", justifyContent: "right", flex: "1" }}>
      <RBACWrapper role={ENABLE_DISABLE_USER} type="PERMISSION">
        <Checkbox
          checked={props.row.original.is_active}
          checkmarkType={STYLE_TYPE.toggle_round}
          onChange={(e) => disableUser.mutateAsync(props.row.original._id)}
          labelPlacement={LABEL_PLACEMENT.right}
          disabled={currentUser ? true : false}
        />
      </RBACWrapper>
    </div>
  );
};

export default UserStatus;
