import React from "react";

const DeleteIcon = ({ width = 14, height = 15, color = "#FD372A" }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.85547 14.5498C2.47109 14.5498 2.14688 14.4178 1.88281 14.1537C1.61875 13.8896 1.48672 13.5654 1.48672 13.1811V2.3998H1.28047C1.09609 2.3998 0.9375 2.33193 0.804688 2.19618C0.671875 2.06044 0.605469 1.89794 0.605469 1.70868C0.605469 1.51943 0.6718 1.35918 0.804463 1.22793C0.937113 1.09668 1.09578 1.03105 1.28047 1.03105H4.50547C4.50547 0.843555 4.56437 0.69043 4.68219 0.57168C4.8 0.45293 4.95359 0.393555 5.14297 0.393555H8.87422C9.06359 0.393555 9.21719 0.452461 9.335 0.570273C9.45281 0.688086 9.51172 0.84168 9.51172 1.03105H12.7367C12.9267 1.03105 13.0898 1.09777 13.2261 1.23121C13.3623 1.36466 13.4305 1.52601 13.4305 1.71526C13.4305 1.90452 13.3623 2.06593 13.2261 2.19948C13.0898 2.33303 12.9267 2.3998 12.7367 2.3998H12.5305V13.1811C12.5305 13.5654 12.3984 13.8896 12.1344 14.1537C11.8703 14.4178 11.5461 14.5498 11.1617 14.5498H2.85547ZM11.1617 2.3998H2.85547V13.1811H11.1617V2.3998ZM4.69297 11.5498H6.06172V3.99355H4.69297V11.5498ZM7.97422 11.5498H9.34297V3.99355H7.97422V11.5498Z"
          fill={color}
        />
      </svg>
    </>
  );
};
export default DeleteIcon;
