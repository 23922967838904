import React from "react";

const UnSuccessful = ({ height = 22, width = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.80975 22H20.1903C21.9675 22 23.0755 19.8398 22.1868 18.1194L13.4966 1.29031C12.6079 -0.430104 10.3921 -0.430104 9.50344 1.29031L0.813185 18.1194C-0.0754593 19.8398 1.03246 22 2.80975 22ZM11.5 12.9452C10.8653 12.9452 10.3459 12.3631 10.3459 11.6516V9.06453C10.3459 8.35308 10.8653 7.77098 11.5 7.77098C12.1347 7.77098 12.6541 8.35308 12.6541 9.06453V11.6516C12.6541 12.3631 12.1347 12.9452 11.5 12.9452ZM12.6541 18.1194H10.3459V15.5323H12.6541V18.1194Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default UnSuccessful;
