import React from "react";

const InvoiceSvg = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6688 5.70205C19.8796 5.8917 20 6.16194 20 6.44551V18.993C20.001 19.1243 19.9733 19.2545 19.9184 19.3762C19.8635 19.4979 19.7825 19.6087 19.68 19.7022C19.5776 19.7957 19.4557 19.8701 19.3213 19.9212C19.1868 19.9723 19.0426 19.9991 18.8966 20H1.10333C0.810903 20 0.530434 19.8955 0.323551 19.7095C0.116668 19.5235 0.000294491 19.2712 0 19.008V0.992C0 0.455 0.498888 0 1.11333 0H12.9464C13.1933 0 13.4316 0.0913805 13.6152 0.25654L19.6688 5.70205ZM17.7778 8C17.7778 7.44771 17.33 7 16.7778 7H13.2222C12.6699 7 12.2222 6.55228 12.2222 6V3C12.2222 2.44772 11.7745 2 11.2222 2H3.22222C2.66993 2 2.22222 2.44772 2.22222 3V17C2.22222 17.5523 2.66993 18 3.22222 18H16.7778C17.33 18 17.7778 17.5523 17.7778 17V8ZM5.55555 6C5.55555 5.44772 6.00326 5 6.55555 5H7.88888C8.44116 5 8.88888 5.44772 8.88888 6C8.88888 6.55228 8.44116 7 7.88888 7H6.55555C6.00326 7 5.55555 6.55228 5.55555 6ZM5.55555 10C5.55555 9.44771 6.00326 9 6.55555 9H13.4444C13.9967 9 14.4444 9.44771 14.4444 10C14.4444 10.5523 13.9967 11 13.4444 11H6.55555C6.00326 11 5.55555 10.5523 5.55555 10ZM5.55555 14C5.55555 13.4477 6.00326 13 6.55555 13H13.4444C13.9967 13 14.4444 13.4477 14.4444 14C14.4444 14.5523 13.9967 15 13.4444 15H6.55555C6.00326 15 5.55555 14.5523 5.55555 14Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default InvoiceSvg;
