import React from "react";

const RightArrowAd = () => {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.11328 10.4874C0.991155 10.3348 0.926275 10.1668 0.918642 9.98366C0.911009 9.80047 0.97589 9.64018 1.11328 9.50278L5.14346 5.4726L1.09038 1.41953C0.968256 1.2974 0.911009 1.13329 0.918642 0.927203C0.926275 0.721114 0.991155 0.557007 1.11328 0.43488C1.26594 0.282222 1.43005 0.209709 1.6056 0.217342C1.78116 0.224975 1.93764 0.297487 2.07503 0.43488L6.63188 4.99173C6.70821 5.06806 6.76164 5.14439 6.79217 5.22072C6.8227 5.29705 6.83797 5.38101 6.83797 5.4726C6.83797 5.5642 6.8227 5.64816 6.79217 5.72449C6.76164 5.80082 6.70821 5.87715 6.63188 5.95348L2.09793 10.4874C1.96054 10.6248 1.80024 10.6897 1.61705 10.6821C1.43386 10.6744 1.26594 10.6096 1.11328 10.4874V10.4874Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default RightArrowAd;
