import React from "react";

const CancelCircle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0012 12.9732L15.0743 16.0463C15.2044 16.1765 15.365 16.2431 15.556 16.2463C15.747 16.2495 15.9108 16.1829 16.0473 16.0463C16.1839 15.9098 16.2521 15.7476 16.2521 15.5598C16.2521 15.372 16.1839 15.2098 16.0473 15.0733L12.9742 12.0002L16.0473 8.9271C16.1774 8.79698 16.2441 8.63641 16.2473 8.4454C16.2505 8.25436 16.1839 8.09058 16.0473 7.95405C15.9108 7.81751 15.7486 7.74925 15.5608 7.74925C15.373 7.74925 15.2108 7.81751 15.0743 7.95405L12.0012 11.0271L8.92807 7.95405C8.79796 7.82393 8.63739 7.75726 8.44638 7.75405C8.25534 7.75085 8.09156 7.81751 7.95502 7.95405C7.81849 8.09058 7.75023 8.25276 7.75023 8.44057C7.75023 8.62839 7.81849 8.79056 7.95502 8.9271L11.0281 12.0002L7.95502 15.0733C7.82491 15.2034 7.75824 15.364 7.75502 15.555C7.75182 15.746 7.81849 15.9098 7.95502 16.0463C8.09156 16.1829 8.25373 16.2511 8.44155 16.2511C8.62937 16.2511 8.79154 16.1829 8.92807 16.0463L12.0012 12.9732ZM12.0035 21.2982C10.7176 21.2982 9.50873 21.0539 8.37683 20.5651C7.24492 20.0764 6.26032 19.4131 5.42302 18.5752C4.58572 17.7374 3.92308 16.7536 3.4351 15.6239C2.94712 14.4941 2.70312 13.287 2.70312 12.0025C2.70312 10.7166 2.9475 9.50775 3.43625 8.37585C3.92498 7.24395 4.58827 6.25935 5.42612 5.42205C6.26396 4.58475 7.24775 3.92211 8.3775 3.43412C9.50725 2.94614 10.7144 2.70215 11.9989 2.70215C13.2847 2.70215 14.4936 2.94652 15.6255 3.43527C16.7574 3.92401 17.742 4.5873 18.5793 5.42515C19.4166 6.26298 20.0793 7.24677 20.5673 8.37652C21.0552 9.50627 21.2992 10.7134 21.2992 11.9979C21.2992 13.2838 21.0548 14.4926 20.5661 15.6245C20.0774 16.7564 19.4141 17.741 18.5762 18.5783C17.7384 19.4156 16.7546 20.0783 15.6249 20.5663C14.4951 21.0543 13.288 21.2982 12.0035 21.2982Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default CancelCircle;
