import React, { useContext, useRef, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import _ from "lodash";
import { Icon, Typography } from "../../components_v2";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { deleteStickyDairyNote, postStickyDairyNote } from "../../services";
import { CO_TIMELINE_, CUSTOMER_OVERVIEW_ } from "../../constants";
import useFormat from "../../hooks/useFormat";
import { DELETE_OTHER_USER_STICKY_NOTES } from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { TextBox } from "../../components/TextBox";

export default function StickyNotes({ customer, setIsSticky }) {
  const editorRef = useRef(null);
  const queryClient = useQueryClient();
  const { customerId } = useParams();
  const format = useFormat();
  const BlockEmbed = ReactQuill.Quill.import("blots/embed");

  const { currentOrganization, users, userInfo } = useContext(MaxyfiContext);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  const addStickyNote = useMutation(
    (values) =>
      postStickyDairyNote({
        organization: currentOrganization,
        customerId,
        ...values,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        setValue("notes", "");
        queryClient.refetchQueries(`${CO_TIMELINE_}${customerId}`);
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
      },
    }
  );

  const deleteStickyNote = useMutation(
    (values) =>
      deleteStickyDairyNote({
        organization: currentOrganization,
        customerId,
        ...values,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${CO_TIMELINE_}${customerId}`);
        queryClient.refetchQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
      },
    }
  );

  const onSubmit = async () => {
    const data = getValues();

    let mentionUsers = [];
    const testElements = document.getElementsByClassName("quill-bolt");

    for (let mention of testElements) {
      if (mention && mention.dataset && mention.dataset.userId) {
        mentionUsers.push(mention.dataset.userId);
      }
    }

    let stickyValue = {
      nt: _.get(data, "notes", "").replace(/<[^>]*>/g, ""),
    };

    await addStickyNote.mutateAsync({
      ...stickyValue,
    });
  };
  class TemplateMarker extends BlockEmbed {
    static create(value) {
      let node = super.create(value);

      node.setAttribute("class", "quill-bolt");
      node.setAttribute("data-title", value.value);
      node.setAttribute("data-user-id", value.id);

      node.innerText = `@${value.value}`;

      return node;
    }

    static value(node) {
      return {
        id: node.getAttribute("data-user-id"),
        value: node.getAttribute("data-title"),
      };
    }
  }

  // Set the tag name for TemplateMarker
  TemplateMarker.tagName = "span";

  // Register the TemplateMarker with Quill
  ReactQuill.Quill.register({ "formats/TemplateMarker": TemplateMarker });

  const modules = useMemo(
    () => ({
      toolbar: false,
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  return (
    <form className="co_sticky_note_wraper">
      {_.get(customer, "data.stkynt", []).length > 0 && (
        <div
          style={{
            minHeight: "auto",
            maxHeight: "70px",
            overflow: "scroll",
            backgroundColor: "#ffffff",
          }}
        >
          {_.get(customer, "data.stkynt", [])
            .reverse()
            .map((sy, i) => {
              return (
                <div className="delete_wraper">
                  <Typography
                    style={{
                      fontWeight: "400",
                      borderBottom: "1px solid #cdced9",
                      padding: "5px 5px",
                      position: "relative",
                    }}
                  >
                    {sy?.nt?.replace(/(<([^>]+)>)/gi, "")}{" "}
                    <span
                      style={{
                        color: "#ADADAD",
                        fontSize: "11px",
                        fontWeight: "400",
                      }}
                    >
                      - {format.rd({ name: "users", id: sy.cby })}{" "}
                      {format.date({ value: sy.cat })}
                    </span>
                  </Typography>
                  <RBACWrapper
                    role={DELETE_OTHER_USER_STICKY_NOTES}
                    type="PERMISSION"
                  >
                    <div className="sticky_delete_btn">
                      <Icon
                        icon="delete"
                        isPressable
                        color="#FD372A"
                        onClick={async () =>
                          await deleteStickyNote.mutateAsync({
                            stickyId: sy._id,
                          })
                        }
                      />
                    </div>
                  </RBACWrapper>
                </div>
              );
            })}
        </div>
      )}

      <div
        className={`legal-notes-section ${
          _.get(customer, "data.stkynt", []).length > 0
            ? "--sticky_editor_container"
            : "--modifi_sticky_wraper"
        } `}
      >
        <Controller
          name="notes"
          rules={{ required: "Required" }}
          control={control}
          render={({ field }) => (
            <ReactQuill
              {...field}
              name={field.name}
              id={"editor"}
              ref={editorRef}
              className="note-ql-container"
              theme="snow"
              value={field.value}
              modules={modules}
              style={{ height: "100%" }}
              placeholder="Capture sticky notes"
            />
          )}
        />
      </div>
      <div className={"co_sticky_note_action_icn"}>
        <Icon
          icon={"note_outline"}
          size={18}
          isPressable
          onClick={() => {
            reset();
            setIsSticky(false);
          }}
        />
      </div>

      <div className={"co_sticky-notes-actions-sub"}>
        <Icon
          icon="tick"
          size={18}
          isPressable
          color="#0FB158"
          onClick={async () => {
            await onSubmit();
          }}
        />
      </div>
      {/* </div> */}
    </form>
  );
}
