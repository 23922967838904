import react from "react";
import { LabelLarge, LabelMedium, ParagraphMedium } from "baseui/typography";
import { Controller, useWatch } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { TextBox, SIZE as size } from "../../../../components/TextBox";
import { SelectBox } from "../../../../components/SelectBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import ViewIcon from "../../../../assets/img/svg/ViewIcon";
import { Button, KIND, SIZE } from "baseui/button";
import { TextButton } from "../../../../components/TextButton";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { useContext } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { useState } from "react";
import { TextArea } from "../../../../components/TextArea";
import PaymentPlanDrawer from "./PaymentPlanDrawer";
import moment from "moment";
import { useEffect } from "react";
import { usersData } from "../../../../services/users";
import DownArrow from "../../../../utils/DownArrow";
import { IconButton } from "../../../../components/IconButton";
import EditPencil from "../../../../utils/EditPencil";
import { useSelector } from "react-redux";
import setSelectValues from "../../../../utils/setSelectValues";
import { useNumericFormat } from "react-number-format";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getCommunicationTemplateList,
  getCustomerContactDetail,
} from "../../../../services";
import PlusOne from "../../../../assets/img/svg/Plus";
import RecurringSvg from "../../../../assets/img/svg/RecurringSvg";
import _ from "lodash";

const PaymentPlan = (call) => {
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [isCheck, setIsCheck] = useState(false);

  const getParams = getSearchParams.get("customers");

  let [confirmationTemplates, setConfirmationTemplates] = useState([]);
  let [followUpTemplates, setFollowUpTemplates] = useState([]);

  const reduxData = useSelector(
    (s) => s.customerOverviewReducer.customerDetails?.total_inv_amount?.value
  );

  const invoiceCurrency = useSelector(
    (s) =>
      s &&
      s.customerOverviewReducer &&
      s.customerOverviewReducer.customerDetails &&
      s.customerOverviewReducer.customerDetails?.invoice_currencies
  );

  const { customerConatactDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  let currencyList = invoiceCurrency.map((e) => {
    return { id: e, label: e };
  });

  let intl = useIntl();
  let {
    control,
    errors,
    watch,
    settingData,
    contactsData,
    setValue,
    register,
    setInstallments = () => {},
    installments,
    setDisable = () => {},
    setIsCancel,
    isCancel,
    setIsCompute,
    isCompute,
    clearErrors,
    callOutcome,
    recipients,
    defaultConfiguration,
  } = call;
  let { actioWatch, total_outstanding_invoice_amount, setError } = call;
  let [is_send_confirmation_message, is_send_action_follow_up] = actioWatch;

  const [isOpen, setIsOpen] = useState(false);
  let {
    instalation_amount,
    amount,
    frequency,
    start_date,
    residual_amount_type,
    is_enable_calendar_alert,
  } = watch();

  const [isDay, setIsDay] = useState([]);
  const [isResidualAmount, setIsResidualAmount] = useState([]);

  useEffect(() => {
    if (amount <= 0) {
      setError("amount", {
        message: "Required",
      });
    } else if (amount > total_outstanding_invoice_amount?.value) {
      setError("amount", {
        message: "Amount Should Not greater than O/S amount",
      });
    } else {
      clearErrors("amount");
    }
  }, [amount]);

  useEffect(() => {
    if (instalation_amount <= 0) {
      setError("instalation_amount", {
        message: "Required",
      });
    } else if (Number(amount) < Number(instalation_amount)) {
      setError("instalation_amount", {
        message: "Installment Amount more than the total Amount",
      });
    } else {
      clearErrors("instalation_amount");
    }
  }, [instalation_amount]);

  const calculateTenure = () => {
    const totalAmount = parseFloat(amount) || 0;
    const installmentAmount = parseFloat(instalation_amount) || 0;
    const startDate = start_date ? new Date(start_date) : "";
    const frequencyId =
      frequency && frequency?.[0] && frequency?.[0]?.id && frequency?.[0]?.id
        ? frequency &&
          frequency?.[0] &&
          frequency?.[0]?.id &&
          frequency?.[0]?.id
        : "WEEKLY";
    const residualAmountOption =
      residual_amount_type &&
      residual_amount_type[0] &&
      residual_amount_type[0].id;
    const quotient = installmentAmount / totalAmount;
    const findReminder = (installmentAmount - totalAmount) * quotient;

    const numOfInstallments = totalAmount / installmentAmount;
    const residualPerc = numOfInstallments - parseInt(numOfInstallments);
    const reminder = installmentAmount * residualPerc;

    const freqMoment = {
      WEEKLY: {
        type: "day",
        count: 7,
      },
      FORTNIGHTLY: {
        type: "day",
        count: 14,
      },
      MONTHLY: {
        type: "month",
        count: 1,
      },
      QUARTERLY: {
        type: "month",
        count: 3,
      },
    };

    let countInc = 0;
    const installments = Array.from({
      length: parseInt(numOfInstallments),
    }).map((inst, idx) => {
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${start_date ? start_date.getFullYear() : ""}-${
            start_date ? start_date.getMonth() + 1 : ""
          }-${start_date ? start_date.getDate() : ""}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails?.time_zone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      countInc += count;

      return {
        promise_date: installmentDate,
        promise_amount: {
          value:
            installmentAmount +
            (residualAmountOption === "FIRST_INSTALMENT" && idx === 0
              ? reminder
              : 0) +
            (residualAmountOption === "LAST_INSTALMENT" &&
            idx === parseInt(numOfInstallments) - 1
              ? reminder
              : 0),
          currency: customerDetails?.default_currency,
        },
      };
    });

    if (reminder != 0 && residualAmountOption === "KEEP_LAST_INSTALMENT") {
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${start_date.getFullYear()}-${
            start_date.getMonth() + 1
          }-${start_date.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails?.time_zone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      installments.push({
        promise_date: installmentDate,
        promise_amount: {
          value: reminder,
          currency: customerDetails?.default_currency,
        },
      });
    }

    // old code is removed for calculation on 20-12-2023
    if (installments.length > 0) {
      setInstallments(installments);
    }
  };

  // let filters = [
  //   { id: "weekly", label: "Weekly" },
  //   { id: "monthly", label: "Monthly" },
  //   { id: "yearly", label: "Year" },
  // ];

  // let residualAmount = [
  //   { id: "add_to_first_installment", label: "First Installment" },
  //   { id: "add_to_last_installment", label: "Last Installment" },
  //   { id: "keep_as_last_installment", label: "Keep as Last Installment" },
  // ];

  useEffect(() => {
    setValue("residual_amount_type", setSelectValues("LAST_INSTALMENT"));
    setValue("payment_method", setSelectValues("PORTAL"));
    setValue("frequency", [{ id: "WEEKLY", label: "Weekly" }]);
    setValue(
      "start_date",
      moment.tz(currentDefaultFormatDetails.time_zone).toDate()
    );
    // setValue("method", setSelectValues("PORTAL"));
    setValue(
      "amount",
      Number(total_outstanding_invoice_amount?.value).toFixed(2)
    );
  }, []);

  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  let EmailUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_email === true);
  let PhoneUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_phone === true);

  let emailValue = EmailUser === !EmailUser ? 0 : 1;

  let [confirmationMessageType, followUpType] = watch([
    "confirmation_message_type",
    "action_follow_up_type",
  ]);

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags,
        type,
      },
      organization: currentOrganization,

      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res?.data?.docs?.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: ["PAYMENT_PLAN"],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  useEffect(() => {
    if (followUpType && followUpType[0] && followUpType[0].id) {
      setFollowUpTemplates([]);
      getTemplates({
        tags: ["PAYMENT_PLAN"],
        type: followUpType[0].id,
        setter: setFollowUpTemplates,
      });
    }
  }, [followUpType]);

  let mappinTypeValue =
    referenceData && referenceData["template_type"]
      ? referenceData["template_type"]
          .filter(({ id }) => id != "letter")
          .map((e) => {
            if (e.id == "email" && !EmailUser) {
              return { ...e, disabled: true };
            } else if (e.id === "sms" && !PhoneUser) {
              return { ...e, disabled: true };
            } else if (
              e.id === "sms" &&
              !currentDefaultFormatDetails.is_sms_configured
            ) {
              return { ...e, disabled: true };
            } else if (
              e.id === "whatsapp" &&
              !currentDefaultFormatDetails?.is_whatsapp_configured
            ) {
              return { ...e, disabled: true };
            }
            return e;
          })
      : [];

  useEffect(() => {
    if (instalation_amount > parseFloat(amount)) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [instalation_amount]);

  const handlercancle = () => {
    setIsCancel(!isCancel);
  };

  const PaymentPlanListItem = customerDetails?.promise_to_pay?.filter(
    (elem) => {
      if (
        elem.payment_plan_id !== undefined &&
        elem.payment_plan_id.length !== 0
      ) {
        return (
          elem.payment_plan_id ===
          customerDetails?.payment_plan?.payment_plan_id
        );
      }
    }
  );
  let PP = customerDetails?.promise_to_pay.find(
    (e) => e.status == "IN_PROGRESS"
  );
  let P2P = customerDetails.promise_to_pay.some(
    (e) => e.type === "PROMISE_TO_PAY"
  );
  let PPA = customerDetails.promise_to_pay.some(
    (e) => e.type === "PAYMENT_PLAN"
  );

  useEffect(() => {
    let findCurrentRecipient =
      recipients && recipients.recipient_id
        ? contactsData?.find((fr) => fr._id === recipients.recipient_id)
        : [];
    const paymentPlanConfig = defaultConfiguration?.messages;

    setValue("is_hold_workflow", defaultConfiguration?.is_hold_workflow);
    paymentPlanConfig &&
      Array.isArray(paymentPlanConfig) &&
      paymentPlanConfig?.reverse()?.map((e) => {
        if (
          (e.type === "email" &&
            findCurrentRecipient &&
            findCurrentRecipient.is_valid_email) ||
          (e.type === "sms" &&
            findCurrentRecipient &&
            findCurrentRecipient.is_valid_sms) ||
          (e.type === "whatsapp" &&
            findCurrentRecipient &&
            findCurrentRecipient.is_valid_whatsapp)
        ) {
          if (e?.confirmation_template?.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e?.follow_up_template?.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
  }, [callOutcome, recipients?.recipient_id]);

  return (
    <>
      {isCancel && PP?.type == "PROMISE_TO_PAY" ? (
        <>
          <div style={{ textAlign: "end" }}>
            <LabelMedium $style={{ color: "#FD372A" }}>
              * The consumer have below existing Promise To Pay
            </LabelMedium>
          </div>
          <div
            style={{
              background: "#F0F5FF",
              padding: "6px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="paymentplan_view-btn">
                <ParagraphMedium>
                  Promise to pay&nbsp;
                  {PP && PP.promise_amount && PP.promise_amount.currency}&nbsp;
                  {/* {PP?.promise_amount?numberFormat.format(PP?.promise_amount?.value):''} */}
                  <FormattedNumber
                    value={PP && PP.promise_amount && PP.promise_amount.value}
                    style="currency"
                  />
                  &nbsp; by &nbsp;
                  {moment(PP && PP.promise_date).format(
                    currentDefaultFormatDetails.date_format
                  )}
                </ParagraphMedium>
              </div>
              <div className="monthly_plan">
                <div className={isCheck ? "isuparrow" : "monthlyupdate"}>
                  <IconButton
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => {
                      setIsCheck(!isCheck);
                    }}
                  >
                    <DownArrow />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "end" }}>
            <IconButton
              size={SIZE.mini}
              kind={KIND.tertiary}
              type="button"
              onClick={handlercancle}
            >
              <PlusOne color={"#516BEB"} width={"12px"} />
              &nbsp;
              <LabelMedium $style={{ color: "#516BEB" }}>
                Cancel and Create new
              </LabelMedium>
            </IconButton>
          </div>
        </>
      ) : isCancel && PP?.type == "PAYMENT_PLAN" ? (
        <>
          <div style={{ textAlign: "end" }}>
            <LabelMedium $style={{ color: "#FD372A" }}>
              * The consumer has below existing Payment Plan
            </LabelMedium>
          </div>
          <div
            style={{
              background: "#F0F5FF",
              padding: "6px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="paymentplan_view-btn">
                <ParagraphMedium>
                  {customerDetails?.payment_plan?.frequency} Payment plan with
                  installment amount of{" "}
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_amount &&
                  PaymentPlanListItem?.[0]?.promise_amount?.currency
                    ? PaymentPlanListItem?.[0]?.promise_amount?.currency
                    : "-"}{" "}
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_amount &&
                  PaymentPlanListItem?.[0]?.promise_amount.value
                    ? numberFormat.format(
                        PaymentPlanListItem &&
                          PaymentPlanListItem?.[0] &&
                          PaymentPlanListItem?.[0]?.promise_amount &&
                          PaymentPlanListItem?.[0]?.promise_amount.value
                          ? PaymentPlanListItem?.[0]?.promise_amount.value.toFixed(
                              2
                            )
                          : 0
                      )
                    : 0}
                  &nbsp;starting&nbsp;
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_date
                    ? moment(PaymentPlanListItem?.[0]?.promise_date).format(
                        currentDefaultFormatDetails.date_format
                      )
                    : "-"}
                  &nbsp; from till{" "}
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_date
                    ? moment(
                        PaymentPlanListItem?.[PaymentPlanListItem?.length - 1]
                          ?.promise_date
                      ).format(currentDefaultFormatDetails.date_format)
                    : "-"}
                </ParagraphMedium>
              </div>
              <div className="monthly_plan">
                <div className={isCheck ? "isuparrow" : "monthlyupdate"}>
                  <IconButton
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => {
                      setIsCheck(!isCheck);
                    }}
                  >
                    <DownArrow />
                  </IconButton>
                </div>
              </div>
            </div>
            {isCheck ? (
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    maxHeight: "200px",
                    overflow: "scroll",
                  }}
                >
                  {PaymentPlanListItem?.map((data, i) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        <LabelMedium>{i + 1}.</LabelMedium>
                        <LabelMedium>
                          {invoiceCurrency && invoiceCurrency[0]}
                        </LabelMedium>
                        <LabelMedium>
                          {numberFormat.format(
                            `${
                              data &&
                              data?.promise_amount &&
                              data?.promise_amount?.value
                            }`
                          )}
                        </LabelMedium>
                        <ParagraphMedium>
                          on{" "}
                          {/* {moment(data?.promise_date).format(
                        currentDefaultFormatDetails.date_format
                      )} */}
                        </ParagraphMedium>
                        <LabelMedium>
                          {moment(data?.promise_date).format(
                            currentDefaultFormatDetails.date_format
                          )}
                        </LabelMedium>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ textAlign: "end" }}>
            <IconButton
              size={SIZE.mini}
              kind={KIND.tertiary}
              type="button"
              onClick={handlercancle}
            >
              <PlusOne color={"#516BEB"} width={"12px"} />
              &nbsp;
              <LabelMedium $style={{ color: "#516BEB" }}>
                Cancel and Create new
              </LabelMedium>
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <div className="--mainpaymentplans">
            <div className="paymentplan_input">
              <Controller
                name="amount"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    hidden={true}
                    type="number"
                    name={field.name}
                    size={size.mini}
                    requiredAstric={true}
                    error={errors[field.name] && errors[field.name].message}
                    register="planned_payment_amount"
                    label={intl.formatMessage({
                      id: "planned_payment_amount",
                    })}
                    placeholder={intl.formatMessage({
                      id: "planned_payment_amount",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (setIsCompute) {
                        setIsCompute(true);
                      }
                      field.onChange(e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="paymentplan_input">
              <Controller
                name="instalation_amount"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    hidden={true}
                    type="number"
                    size={size.mini}
                    name={field.name}
                    requiredAstric={true}
                    label={intl.formatMessage({
                      id: "planned_installation_amount",
                    })}
                    placeholder={intl.formatMessage({
                      id: "planned_installation_amount",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (setIsCompute) {
                        setIsCompute(true);
                      }
                      field.onChange(e.target.value);
                    }}
                    error={errors[field.name] && errors[field.name].message}
                  />
                )}
              />
            </div>
          </div>

          <div className="--mainpaymentplans">
            <div className="paymentplan_input">
              <Controller
                name="start_date"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <>
                    <DateRangeSelect
                      {...field}
                      name={field.name}
                      error={errors[field.name] && errors[field.name].message}
                      requiredAstric={true}
                      size={size.mini}
                      label={intl.formatMessage({
                        id: "planned_start_date",
                      })}
                      placeholder={intl.formatMessage({
                        id: "planned_start_date",
                      })}
                      value={field.value}
                      minDate={new Date()}
                      onChange={(e) => {
                        if (setIsCompute) {
                          setIsCompute(true);
                        }
                        field.onChange(e.date);
                      }}
                    />
                  </>
                )}
              />
            </div>
            <div className="paymentplan_input">
              <Controller
                name="frequency"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    size={size.mini}
                    error={errors[field.name] && errors[field.name].message}
                    clearable={false}
                    label={intl.formatMessage({
                      id: "planned_frequency",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      if (setIsCompute) {
                        setIsCompute(true);
                      }
                      field.onChange(e.value);
                    }}
                    options={referenceData["payment_plan_frequency"]}
                  />
                )}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <div style={{ width: "235px" }}>
                <Controller
                  name="residual_amount_type"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      size={size.mini}
                      error={errors[field.name] && errors[field.name].message}
                      clearable={false}
                      label={intl.formatMessage({
                        id: "residual_amount",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        if (setIsCompute) {
                          setIsCompute(true);
                        }
                        field.onChange(e.value);
                      }}
                      options={referenceData["payment_plan_residual"]}
                    />
                  )}
                />
              </div>
              <div style={{ width: "235px" }}>
                <Controller
                  name="payment_method"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      name={field.name}
                      requiredAstric={true}
                      size={size.mini}
                      error={errors[field.name] && errors[field.name].message}
                      clearable={false}
                      label={intl.formatMessage({
                        id: "payment_method",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        if (setIsCompute) {
                          setIsCompute(true);
                        }
                        field.onChange(e.value);
                      }}
                      options={referenceData["payment_plan_method"]}
                    />
                  )}
                />
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <TextButton
                  kind={KIND.primary}
                  size={size.mini}
                  type="button"
                  disabled={
                    instalation_amount?.toString()?.length > 0 ? false : true
                  }
                  onClick={() => {
                    setIsCheck(false);
                    if (setIsCompute) {
                      setIsCompute(false);
                    }
                    calculateTenure();
                  }}
                >
                  Click to Compute
                </TextButton>
              </div>
            </div>
          </div>
          <div>
            {/* {parseFloat(amount) >= instalation_amount && !isCompute ? ( */}
            <>
              <div
                style={{
                  background: "#F0F5FF",
                  padding: "6px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="paymentplan_view-btn">
                    <ParagraphMedium>
                      {frequency?.[0]?.label} Payment plan with installment
                      amount of{" "}
                      {installments?.[0]?.promise_amount?.currency
                        ? installments?.[0]?.promise_amount?.currency
                        : ""}{" "}
                      {numberFormat.format(
                        `${
                          installments?.[0]?.promise_amount?.value
                            ? installments?.[0]?.promise_amount?.value
                            : 0
                        }`
                      )}
                      &nbsp;starting&nbsp;
                      {installments?.length !== 0
                        ? moment(installments?.[0]?.promise_date).format(
                            currentDefaultFormatDetails.date_format
                          )
                        : ""}{" "}
                      from till{" "}
                      {moment(
                        installments?.[installments?.length - 1]?.promise_date
                      ).format(currentDefaultFormatDetails.date_format)}
                    </ParagraphMedium>
                  </div>
                  <div className="monthly_plan">
                    <div className="monthlyupdate">
                      {/* <TextButton>Click</TextButton> */}

                      <IconButton
                        size={SIZE.mini}
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => setIsOpen(true)}
                      >
                        <EditPencil />
                      </IconButton>
                    </div>
                    <div className={isCheck ? "isuparrow" : "monthlyupdate"}>
                      <IconButton
                        size={SIZE.mini}
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => {
                          setIsCheck(!isCheck);
                        }}
                      >
                        <DownArrow />
                      </IconButton>
                    </div>
                  </div>
                </div>

                {isCheck ? (
                  <div
                    style={{
                      maxHeight: "200px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        maxHeight: "200px",
                        overflow: "scroll",
                      }}
                    >
                      {Array.isArray(installments) &&
                        installments.map((data, i) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "5px",
                              }}
                            >
                              <LabelMedium>{i + 1}.</LabelMedium>
                              <LabelMedium>{invoiceCurrency[0]}</LabelMedium>
                              <LabelMedium>
                                {numberFormat.format(
                                  `${data?.promise_amount?.value}`
                                )}
                              </LabelMedium>
                              <ParagraphMedium>
                                on {moment(data?.promise_date).format("dddd")}
                              </ParagraphMedium>
                              <LabelMedium>
                                {moment(data?.promise_date).format(
                                  currentDefaultFormatDetails.date_format
                                )}
                              </LabelMedium>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
            {/* // ) : (
            //   ""
            // )} */}
          </div>
          {isOpen && (
            <PaymentPlanDrawer
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              getParams={getParams}
              call={call}
              planned_payment_amount={Number(amount)}
              isResidualAmount={isResidualAmount}
              installments={installments}
              setInstallments={setInstallments}
              setIsResidualAmount={setIsResidualAmount}
              setIsDay={setIsDay}
            />
          )}

          <div>
            <div style={{ marginTop: "10px" }}>
              <Controller
                defaultValues={false}
                name="is_debit_first_instalment"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    checked={field.value}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <FormattedMessage id="debit_first_installment_now">
                      Debit First Installment now
                    </FormattedMessage>
                  </CheckBoxBaseweb>
                )}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <Controller
                defaultValues={false}
                name="is_hold_workflow"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    checked={field.value}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <FormattedMessage id="hold_workflow_till_planned_date">
                      hold_workflow_till_promise_date
                    </FormattedMessage>
                  </CheckBoxBaseweb>
                )}
              />
            </div>

            <div className="cp-action-message-confirmation">
              <div className="cp-message-confirmation-checkbox">
                <Controller
                  defaultValues={false}
                  name="is_enable_calendar_alert"
                  control={control}
                  render={({ field }) => (
                    <CheckBoxBaseweb
                      {...field}
                      checked={field.value}
                      labelPlacement={LABEL_PLACEMENT.right}
                    >
                      <FormattedMessage id="enable_calendar_alert">
                        Enable Calendar Alert
                      </FormattedMessage>
                    </CheckBoxBaseweb>
                  )}
                />
              </div>
              {is_enable_calendar_alert ? (
                <>
                  <div
                    className="cp-message-confirmation-type"
                    style={{ width: "120px" }}
                  >
                    <Controller
                      defaultValues={[]}
                      name="calendar_alert_time"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          size={SIZE.compact}
                          fullWidth
                          error={
                            call.errors[field.name] &&
                            call.errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "time",
                          })}
                          placeholder={intl.formatMessage({
                            id: "time",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            // if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                            //   setValue("confirmation_message_template_id", []);
                            // }
                            field.onChange(e.value);
                          }}
                          options={referenceData["time"]}
                        />
                      )}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            <div className="cp-message-confirmation-checkbox">
              <Controller
                defaultValues={false}
                name="is_send_confirmation_message"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    checked={field.value}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <FormattedMessage id="send_confirmation_message">
                      send_confirmation_message
                    </FormattedMessage>
                  </CheckBoxBaseweb>
                )}
              />
            </div>

            {is_send_confirmation_message ? (
              <div className="cp-action-message-confirmation">
                <>
                  <div className="cp-message-confirmation-type">
                    <Controller
                      defaultValues={[]}
                      name="confirmation_message_type"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          size={SIZE.compact}
                          fullWidth
                          error={
                            call.errors[field.name] &&
                            call.errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "comm_type",
                          })}
                          placeholder={intl.formatMessage({
                            id: "comm_type",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                              setValue("confirmation_message_template_id", []);
                            }
                            field.onChange(e.value);
                          }}
                          options={mappinTypeValue}
                        />
                      )}
                    />
                  </div>
                  {/* TODO: TEMPLATE TYPE */}
                  <div className="cp-action-promise-pay-con">
                    <Controller
                      name="confirmation_message_template_id"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          size={SIZE.compact}
                          fullWidth
                          error={
                            call.errors[field.name] &&
                            call.errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "payment_plan_confirmation",
                          })}
                          placeholder={intl.formatMessage({
                            id: "payment_plan_confirmation",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={confirmationTemplates || []}
                        />
                      )}
                    />
                  </div>
                </>
              </div>
            ) : (
              ""
            )}

            <div className="cp-message-confirmation-checkbox">
              <Controller
                defaultValues={false}
                name="is_send_action_follow_up"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    checked={field.value}
                    labelPlacement={LABEL_PLACEMENT.right}
                    // disabled={!EmailUser === true && !PhoneUser === true}
                  >
                    <FormattedMessage id="send_payment_plan_reminder_message">
                      send_payment_plan_reminder_message
                    </FormattedMessage>
                  </CheckBoxBaseweb>
                )}
              />
            </div>

            {is_send_action_follow_up ? (
              <div className="cp-action-message-confirmation">
                <>
                  <div className="cp-message-confirmation-type">
                    <Controller
                      name="action_follow_up_type"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          fullWidth
                          size={SIZE.compact}
                          error={
                            call.errors[field.name] &&
                            call.errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "comm_type",
                          })}
                          placeholder={intl.formatMessage({
                            id: "comm_type",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                              setValue("action_follow_up_template_id", []);
                            }
                            field.onChange(e.value);
                          }}
                          // options={
                          //   referenceData["template_type"].filter(
                          //     (e) => e.id !== LETTER
                          //   ) || []
                          // }
                          options={mappinTypeValue}
                        />
                      )}
                    />
                  </div>
                  <div className="cp-action-promise-pay-con">
                    <Controller
                      defaultValues={[]}
                      name="action_follow_up_template_id"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          clearable={false}
                          name={field.name}
                          fullWidth
                          size={SIZE.compact}
                          error={
                            call.errors[field.name] &&
                            call.errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "send_payment_plan_follow_up_message",
                          })}
                          placeholder={intl.formatMessage({
                            id: "send_payment_plan_follow_up_message",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={followUpTemplates || []}
                        />
                      )}
                    />
                  </div>
                </>
              </div>
            ) : (
              ""
            )}

            <div className="cp-action-promise_test-area">
              <Controller
                name="comment"
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    clearable={false}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "user_comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "user_comments",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default PaymentPlan;
