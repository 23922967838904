import { Paragraph2 } from "baseui/typography";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";

function ObjectString(props) {
  let { referenceData, currentOrganization, userInfo } =
    useContext(MaxyfiContext);
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;

  value = _.get(original, accessor, "");

  const userRole = referenceData?.user_role?.find(({ id }) => id == value.role);

  return (
    <div>
      {Object.keys(_.get(original, "audit_data")).map((key) => {
        let data = value[key];

        if (key === "organization") {
          const orgFind = currentOrganization
            ? referenceData?.organizations?.find(
                ({ id }) => id === currentOrganization
              )
            : null;
          data = orgFind && orgFind?.label.toString();
        }
        if (key === "role") {
          data = userRole && userRole ? userRole?.label.toString() : "-";
        }

        return (
          <Paragraph2
            $style={{
              width: "100%",
              margin: "5px",
              textAlign: "left",
            }}
          >
            <span style={{ fontWeight: "500" }}>
              <FormattedMessage id={key}>{key}</FormattedMessage>
            </span>
            {`: ${data}`}
          </Paragraph2>
        );
      })}
    </div>
  );
}

export default ObjectString;
