import React, { useState, useEffect, useMemo, useContext } from "react";
import { useMutation } from "react-query";
import EditeSvgN from "../../../assets/img/svg/EditeSvgN";
import { ParagraphMedium } from "baseui/typography";
import { IconButton, SIZE, KIND } from "../../IconButton";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { editPaymentModal } from "../../../redux/customerOverview/payment/action";
import axios from "axios";
import { toast } from "react-toastify";
import View from "../../../assets/img/svg/View";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import { deletePaymentDetailsCustomerOverview } from "../../../services";
import { viewPaymentModal } from "../../../redux/customerOverview/payment/action";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

import axiosWithAuth from "../../../providers/AxiosInterceptor";
import _ from "lodash";

const PaymentButton = (props) => {
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;
  const [tableData, setTableData] = useState([]);
  const [visible, setVisible] = React.useState(false);
  let { currentOrganization } = useContext(MaxyfiContext);

  const { id } = useParams();
  value = _.get(original, accessor, "");

  const deletePaymentData = useMutation(
    (paymentData) =>
      deletePaymentDetailsCustomerOverview({
        ...paymentData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    axiosWithAuth
      .get(
        `${process.env.REACT_APP_HOST}${currentOrganization}/customer/${id}/payments`
      )
      .then((res) => {
        setTableData(res.data.docs);
      })
      .catch((err) => {});
  }, []);
  const data = useMemo(() => tableData, [tableData]);
  const dispatch = useDispatch();

  return (
    <>
      <StatefulTooltip accessibilityType={"tooltip"} content={value}>
        <ParagraphMedium
          $style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
            width: "100%",
            textAlign: alignment,
            margin: "5px",
          }}
        >
          <div className="elip">{value}</div>
        </ParagraphMedium>
      </StatefulTooltip>

      {/* <div className="action-button">
        <div style={{ marginRight: "", width: "40px" }}>
          <IconButton
            fullWidth
            kind={KIND.tertiary}
            size={SIZE.compact}
            onClick={() => {
              dispatch(editPaymentModal(data[0]));
            }}
          >
            <EditeSvgN />
          </IconButton>
        </div>
        <div style={{ marginRight: "", width: "40px" }}>
          <IconButton
            fullWidth
            kind={KIND.tertiary}
            size={SIZE.compact}
            onClick={() => dispatch(viewPaymentModal(data[0]))}
          >
            <View />
          </IconButton>
        </div>
        <div style={{ marginRight: "", width: "40px" }}>
          <IconButton
            fullWidth
            kind={KIND.tertiary}
            size={SIZE.compact}
            onClick={() =>
              deletePaymentData.mutateAsync({
                contactId: data[0]._id,
                customerId: id,
              })
            }
            className="nested-view-content-icon-payment"
          >
            <Delete />
          </IconButton>
        </div> 
      </div>*/}
    </>
  );
};

export default PaymentButton;
