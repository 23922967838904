import React from "react";
import LetterIcon from "../../../assets/img/svg/CustomerTemplate/Letter";
import EmailIcon from "../../../assets/img/svg/CustomerTemplate/Email";
import MessageIcon from "../../../assets/img/svg/Template/Message";
import {
  EMAIL,
  SMS,
  LETTER,
  INVOICE,
  NOTE,
  PROMISE,
  CALLBACKLATER,
  MEETINGREQUEST,
  HOLDACTION,
  CALLUNSUCCESSFUL,
  DISPUTE,
  NEXTACTION,
  REVIEWPROMISE,
  SKIPPEDTIMELINE,
  RELEASED,
  WHATSAPP,
  RM,
  WORKFLOW,
  BUSINESS_UNIT,
  CUSTOMERT_STATUS,
  NOTES,
  CONTACTS,
  CUSTOMERPORTAL,
  PORTAL_PAYMENT,
  APP_PAYMENT,
  CALL_RECORDING,
  CUSTOMER_NAME_UPDATE,
  SKIP_TRACE,
} from "../../../constants";
import InvoiceSvg from "../../../assets/img/svg/InvoiceSvg";
import Notesvg from "../../../assets/img/svg/Notesvg";
import PromiseToPay from "../../../assets/img/svg/PromiseToPay";
import CallBackLater from "../../../assets/img/svg/CallBackLater";
import MeetingRequest from "../../../assets/img/svg/MeetingRequest";
// import HoldAction from "../../assets/img/svg/HoldAction";
import CallUnsuccessful from "../../../assets/img/svg/CallUnsuccessful";
import Dispute from "../../../assets/img/svg/Dispute";
import NextAction from "../../../assets/img/svg/NextAction";
import ReviewPromiseToPay from "../../../assets/img/svg/ReviewPromiseToPay";
import HoldActionTimeLineCard from "../../../assets/img/svg/HoldActionTimeLineCard";
import SkippedTimeLineCard from "../../../assets/img/svg/SkippedTimeLineCard";
import { faPray } from "@fortawesome/free-solid-svg-icons";
import ReleaseTimeLineCard from "../../../assets/img/svg/ReleaseTimeLineCard";
import WhatsApp from "../../../assets/img/svg/Template/WhatsApp";
import RelManager from "../../../assets/img/svg/RelManager";
import Workflow from "../../../assets/img/svg/Workflow";
import BusinessUnit from "../../../assets/img/svg/BusinessUnit";
import CustomerStatus from "../../../assets/img/svg/CustomerStatus";
import NotesTimelineSvg from "../../../assets/img/svg/NotesTimelineSvg";
import ContactTimelineSvg from "../../../assets/img/svg/ContactTimelineSvg";
import PortalTLSvg from "../../../assets/img/svg/PortalTLSvg";
import PortalPayment from "../../../assets/img/svg/PortalPayment";
import CallRecordingSvg from "../../../assets/img/svg/CallRecordingSvg";
import CustomerNameSvg from "../../../assets/img/svg/CustomerNameSvg";
import SkipTrace from "../../../assets/img/svg/SkipTrace";
import LocationSvg from "../../../assets/img/svg/LocationSvg";
import NextActionIcon from "../../../assets/img/svg/NextActionIcon";
import RequestInformation from "../../../assets/img/svg/RequestInformation";

const TimelineIcon = ({ type, ...rest }) => {
  switch (type) {
    case WHATSAPP:
      return <WhatsApp />;
    case SMS:
      return <MessageIcon width={15} height={15} />;

    case LETTER:
      return <LetterIcon width={15} height={15} />;

    case EMAIL:
      return <EmailIcon width={15} height={15} />;

    case INVOICE:
      return <InvoiceSvg width={15} height={15} />;

    case NOTE:
      return <Notesvg width={15} height={15} />;

    case WHATSAPP:
      return <p>w</p>;

    case PROMISE:
      return <PromiseToPay width={15} height={15} />;

    case CALLBACKLATER:
      return <CallBackLater width={15} height={15} />;

    case MEETINGREQUEST:
      return <MeetingRequest width={15} height={15} />;

    case HOLDACTION:
      return <HoldActionTimeLineCard width={15} height={15} />;

    case CALLUNSUCCESSFUL:
      return <CallUnsuccessful width={15} height={15} />;

    case DISPUTE:
      return <Dispute width={15} height={15} color="#333860" />;

    case NEXTACTION:
      return <NextAction width={17} height={17} />;

    case REVIEWPROMISE:
      return <ReviewPromiseToPay width={15} height={15} />;

    case SKIPPEDTIMELINE:
      return <SkippedTimeLineCard width={15} height={15} />;

    case RELEASED:
      return <ReleaseTimeLineCard width={15} height={15} />;

    case "SKIPT_TRACE":
      return <SkipTrace width={15} height={15} />;

    case RM:
      return <RelManager width={15} height={15} />;

    case CUSTOMERT_STATUS:
      return <CustomerStatus width={15} height={15} />;

    case BUSINESS_UNIT:
      return <BusinessUnit width={18} height={18} />;

    case WORKFLOW:
      return <Workflow width={15} height={15} />;

    case NOTES:
      return <NotesTimelineSvg width={15} height={15} />;

    case CONTACTS:
      return <ContactTimelineSvg width={15} height={15} />;

    case CUSTOMERPORTAL:
      return <PortalTLSvg width={15} height={15} />;

    case PORTAL_PAYMENT:
      return <PortalPayment width={20} height={20} />;

    case APP_PAYMENT:
      return <PortalPayment width={20} height={20} />;

    case CALL_RECORDING:
      return <CallRecordingSvg width={20} height={20} />;

    case CUSTOMER_NAME_UPDATE:
      return <CustomerNameSvg size={16} />;
    case "FIELD_VISIT":
      return <LocationSvg size={16} />;
    case "NEXTACTIONRESCHEDULA":
      return <NextActionIcon size={16} />;
    case "REQUESTINFORMATION":
      return <RequestInformation size={16} />;
    default:
      return null;
  }
};

export default TimelineIcon;
