import React from "react";
import {
  NEXTACTIONTRIGGER,
  ASSIGNRELATION,
  SETWORK,
  SUSPENDWORKFLOW,
  CAPTUREONHOLD,
  RELEASEONHOLD,
  TAGS,
} from "../../../constants";
import { useSelector } from "react-redux";
import { TextButton } from "../../../components/TextButton";
import NexActionTrigger from "./SwitchingActionType/NextActionTrigger";
import AssignRelationManager from "./SwitchingActionType/AssignRelationManager";
import SetWorkFlow from "./SwitchingActionType/SetWorkflow";
import SuspendWorkFlow from "./SwitchingActionType/SuspendWorkflow";
import CaptureOnHold from "./SwitchingActionType/CaptureOnHold";
import ReleaseOnHold from "./SwitchingActionType/ReleaseOnHold";
import Tags from "./SwitchingActionType/Tags";
const SwitchAction = ({ setState, setNextState, toggleAllRowsSelected }) => {
  const selectType = useSelector((s) => s.customerSummary.actionType);

  switch (selectType) {
    case NEXTACTIONTRIGGER:
      return (
        <>
          <NexActionTrigger setState={setState} setNextState={setNextState} />
        </>
      );
    case ASSIGNRELATION:
      return (
        <>
          <AssignRelationManager
            setState={setState}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        </>
      );
    case SETWORK:
      return (
        <>
          <SetWorkFlow
            setState={setState}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        </>
      );
    case SUSPENDWORKFLOW:
      return (
        <>
          <SuspendWorkFlow
            setState={setState}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        </>
      );
    case CAPTUREONHOLD:
      return (
        <>
          <CaptureOnHold
            setState={setState}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        </>
      );
    case RELEASEONHOLD:
      return (
        <>
          <ReleaseOnHold
            setState={setState}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        </>
      );
    case TAGS:
      return (
        <>
          <Tags
            setState={setState}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        </>
      );

    default:
      return null;
  }
};

export default SwitchAction;
