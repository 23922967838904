import {
  GET_USER_TABLE_DATA,
  MODAL_OPEN,
  MODAL_CLOSE,
  EDIT_MODAL,
  REFETCHUSER,
  VIEW_SESSION_INFO_MODAL,
  SESSION_INFO_MODAL_CLOSE,
  INITIAL_USER_STATE,
  USER_FILTER,
} from "./actions";
import { EDIT, NEW, VIEW } from "../../constants";

const initialQueryBuilder = {
  combinator: "and",
  rules: [],
};

const initialState = {
  isOpen: false,
  editData: {},
  userData: [],
  type: "",
  isFetched: 1,
  isSessionModalOpen: false,
  tableCurrentState: {
    filters: {},
    formFilters: initialQueryBuilder,
  },
  viewData: {},
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INITIAL_USER_STATE: {
      return { ...state, ...initialState };
    }
    case MODAL_OPEN:
      return {
        ...state,
        isOpen: true,
        type: NEW,
      };

    case MODAL_CLOSE:
      return {
        ...state,
        isOpen: false,
        editData: {},
      };

    case EDIT_MODAL:
      const EditUser = state.userData.find(({ _id }) => _id === payload);

      return {
        ...state,
        isOpen: true,
        type: EDIT,
        editData: EditUser,
      };
    case GET_USER_TABLE_DATA:
      return {
        ...state,
        userData: payload,
      };
    case REFETCHUSER:
      return {
        ...state,
        isFetched: state.isFetched + 1,
      };
    case VIEW_SESSION_INFO_MODAL:
      // const ViewUser = state.userData.find(({ _id }) => _id === payload);
      return {
        ...state,
        isSessionModalOpen: true,
        type: VIEW,
        editData: payload,
      };
    case SESSION_INFO_MODAL_CLOSE:
      return {
        ...state,
        isSessionModalOpen: false,
        type: VIEW,
        editData: { ...initialState },
      };
    case USER_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }
    default:
      return { ...state };
  }
};

export default userReducer;
