import React, { useState } from "react";
import { Input, SIZE } from "baseui/input";
import { TextBox } from "../../TextBox";
import { FilterInputBox } from "../../FilterInputBox";
import { useIntl } from "react-intl";

//PENDING
//Need to add throttling

const MinMaxFilter = ({ column: { filterValue = [], setFilter } }) => {
  const intl = useIntl();

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <FilterInputBox
        value={filterValue[0] || ""}
        type="number"
        size={SIZE.compact}
        placeholder={intl.formatMessage({ id: "min" })}
        onChange={(e) => {
          const val = e.target.value;
          // setMin(e.target.value.length);
          // setFilter((old = []) => [val && parseInt(val, 10), old[1]]);
          setFilter((old = []) => {
            let newVal = [];

            if (val && parseInt(val, 10)) {
              newVal[0] = parseInt(val);
            }
            if (val?.length === 0) {
              newVal[0] = parseInt(0);
            }
            if (old[1]) {
              newVal[1] = parseInt(old[1]);
            }

            return newVal;
          });
        }}
      />
      <span style={{ width: "20px" }}></span>
      <FilterInputBox
        type="number"
        size={SIZE.compact}
        placeholder={intl.formatMessage({ id: "max" })}
        value={filterValue[1] || ""}
        onChange={(e) => {
          const val = e.target.value;

          // setFilter((old = []) => [val && parseInt(val, 10), old[1]]);
          setFilter((old = []) => {
            let newVal = [];

            if (old[0]) {
              newVal[0] = parseInt(old[0]);
            }
            if (old[0] === undefined || old[0] === 0) {
              newVal[0] = parseInt(0);
            }

            if (val && parseInt(val, 10)) {
              newVal[1] = parseInt(val);
            }

            return newVal;
          });
        }}
      />
    </div>
  );
};

export default MinMaxFilter;
