import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { addDebtLegal, getGridStructure } from "../../../../services";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { Icon, RDText, Typography } from "../../../../components_v2";
import { useIntl } from "react-intl";
import CurrencyFormate from "../../../../components_v2/CurrencyFormate";
import moment from "moment";
import _ from "lodash";
import { KIND, TextButton } from "../../../../components/TextButton";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Controller, useForm } from "react-hook-form";
import { StatefulTooltip } from "baseui/tooltip";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import columnMapper from "../../../../utils/mapper";
import columnMapper_V2 from "../../../../utils/mapper_v2";
import { toast } from "react-toastify";
import queryClient from "../../../../providers/queryClient";
import {
  LEGAL_ACTION_INFO_,
  LEGAL_DEBT_INVOICES_,
} from "../../../../constants";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { ADD_REMOVED_DEBTS_LEGAL } from "../../../../providers/RBACProvider/permissionList";

const Header = ({ label = "" }) => {
  const intl = useIntl();
  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

const DebtListTable = ({
  selectedInvoices,
  onDeleteInvoice,
  enableSave,
  setDeleteInvoice,
  isEditableDebDpt,
  reset,
}) => {
  const { customerId } = useParams();
  const [width, setWidth] = useState(0);
  let ref = React.useRef();

  const gridStructure = useQuery(
    [`GRID_STRUCTURE_DEBTS`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "debt_list_table",
      });
    },
    {
      cacheTime: 86400000,
    }
  );
  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
      // dispatch(setTableWidth(Math.round(ref.current?.offsetWidth)));
    }
  }, [ref]);

  const addDebts = useMutation(
    (debtData) => {
      addDebtLegal({
        organization: currentOrganization,
        customerId: customerId,
        ...debtData,
      });
    },
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(`${LEGAL_DEBT_INVOICES_}${customerId}`);
        queryClient.invalidateQueries(`${LEGAL_ACTION_INFO_}${customerId}`);
        reset();
      },
    }
  );

  const [expanded, setExpanded] = React.useState({});

  const handleDebtSumbit = async () => {
    const id_list = selectedInvoices.map((invoice) => invoice._id);
    try {
      await addDebts.mutateAsync({
        id_list: id_list,
      });
      // Handle the result if needed
    } catch (error) {
      console.error("Error occurred while adding debts:", error);
    }
  };

  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);

  const data = useMemo(() => {
    const data = selectedInvoices;
    return data;
  }, [selectedInvoices.length]);
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const handleDeleteInvoice = (invoiceId) => {
    setDeleteInvoice(invoiceId);
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flex: "1 0 0px",
        overflow: "hidden",
        flexDirection: "column",
        backgroundColor: "#ffffff",
      }}
    >
      <div className="co_altc_payment-table_wrapper">
        <div className="co_altc_payment_table_header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div
              key={headerGroup.id}
              className="co-table_header-group"
              style={{ padding: "8px" }}
            >
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* Table Body */}
        <div className="co-table_body" style={{ padding: "5px" }}>
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row) => (
            <div key={row.id} className="co-table_body-group">
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  style={{
                    width: cell.column.getSize(),
                  }}
                  className="co-table_body-cell"
                >
                  {cell.column.id === "invoice_number" &&
                  _.get(row, "original.is_sol_breached", false) ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                      <StatefulTooltip
                        placement={PLACEMENT.bottom}
                        showArrow
                        overrides={{
                          Arrow: {
                            style: ({ $theme }) => ({
                              outline: `#16164B solid`,
                              backgroundColor: "#16164B",
                            }),
                          },
                          Inner: {
                            style: ({ $theme }) => ({
                              outline: `#16164B solid`,
                              backgroundColor: "#16164B",
                            }),
                          },
                        }}
                        content={() => (
                          <div style={{ backgroundColor: "#16164B" }}>
                            <Typography
                              type="p"
                              subType="regular"
                              style={{ color: "white" }}
                            >
                              SOL ({_.get(row, "original.month_diff", 0)}{" "}
                              months) Exceeded
                            </Typography>
                          </div>
                        )}
                        returnFocus
                        autoFocus
                      >
                        <Icon icon="alert" color="#FD372A" />
                      </StatefulTooltip>
                    </div>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </div>
              ))}

              {row && row.original && row.original.not_paid_amount && (
                <>
                  <RBACWrapper role={ADD_REMOVED_DEBTS_LEGAL} type="PERMISSION">
                    {isEditableDebDpt &&
                    isEditableDebDpt?.idde &&
                    _.get(isEditableDebDpt, "Complaint", false) &&
                    _.get(isEditableDebDpt, "Supplement", false) ? (
                      <></>
                    ) : (
                      <StatefulPopover
                        triggerType={TRIGGER_TYPE.click}
                        content={({ close }) => (
                          <div className="co_groping_delete_popover">
                            <div className="co_groping_delete_popover_body">
                              <Icon icon="alert" color="#FD372A" />
                              <Typography type="p">
                                This will remove the Debts from the Legal, Do
                                you want to continue?
                              </Typography>
                            </div>
                            <div className="co_groping_delete_popover_footer">
                              <TextButton
                                size="mini"
                                kind={KIND.tertiary}
                                type="button"
                                onClick={() => close()}
                              >
                                <Typography
                                  type="h5"
                                  style={{ color: "#FD372A" }}
                                >
                                  Cancel
                                </Typography>
                              </TextButton>
                              <TextButton
                                size="mini"
                                kind={KIND.secondary}
                                onClick={() => {
                                  handleDeleteInvoice(
                                    _.get(row, "original._id", "")
                                  );
                                  close();
                                }}
                                type="error"
                              >
                                Confirm
                              </TextButton>
                            </div>
                          </div>
                        )}
                      >
                        <div style={{ cursor: "pointer" }}>
                          <Icon
                            icon="delete_outline"
                            color="#ADADAD"
                            isPressable
                          />
                        </div>
                      </StatefulPopover>
                    )}
                  </RBACWrapper>
                </>
              )}
            </div>
          ))}
          {/* TABLE ROW */}
        </div>
      </div>
      {enableSave && (
        <div
          className="debt-legal-invoices_buttons"
          style={{ paddingTop: "10px" }}
        >
          <TextButton size="mini" kind={KIND.tertiary} onClick={() => reset()}>
            Cancel
          </TextButton>
          <TextButton
            size="mini"
            kind={KIND.primary}
            onClick={handleDebtSumbit}
          >
            Save
          </TextButton>
        </div>
      )}
    </div>
  );
};

export default DebtListTable;
