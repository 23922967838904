export default function Letter({ size = 22, color = "#333860" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 2.25H13.4925C14.325 2.25 14.9925 2.9175 14.9925 3.7425V14.2575C14.9925 15.0825 14.3175 15.75 13.4925 15.75H4.5C3.6675 15.75 3 15.0825 3 14.2575V3.7425C3 2.9175 3.675 2.25 4.5 2.25ZM11.16 5.16V5.085C11.16 4.875 10.995 4.71 10.785 4.71H4.995C4.785 4.71 4.62 4.875 4.62 5.085V5.16C4.62 5.37 4.785 5.535 4.995 5.535H10.785C10.995 5.535 11.16 5.37 11.16 5.16ZM9.435 7.515V7.44C9.435 7.23 9.27 7.065 9.06 7.065H4.9875C4.7775 7.065 4.6125 7.23 4.6125 7.44V7.515C4.6125 7.725 4.7775 7.89 4.9875 7.89H9.06C9.27 7.89 9.435 7.725 9.435 7.515Z"
        fill={color}
      />
    </svg>
  );
}
