import React from "react";
import _ from "lodash";
import { LabelSmall, Paragraph2 } from "baseui/typography";

const TextToCamelCase = (props) => {
  let natureOfTax;
  if (
    props &&
    props.row &&
    props.row.original &&
    props.row.original.nature_of_tax
  ) {
    natureOfTax = props.row.original.nature_of_tax
      .replace(/_/g, " ")
      .toUpperCase();
    natureOfTax = _.startCase(_.toLower(natureOfTax));
  }
  return (
    <>
      <Paragraph2 $style={{ color: "#333860" }}>
        {natureOfTax ? natureOfTax : <></>}
      </Paragraph2>
    </>
  );
};

export default TextToCamelCase;
