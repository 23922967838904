import React from "react";

const Message = ({ width = 20, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // {...props}
    >
      <path
        d="M18 0H2C0.9 0 0.00999999 0.951923 0.00999999 2.11538L0 21.1538L4 16.9231H18C19.1 16.9231 20 15.9712 20 14.8077V2.11538C20 0.951923 19.1 0 18 0ZM5 7.40385H15C15.55 7.40385 16 7.87981 16 8.46154C16 9.04327 15.55 9.51923 15 9.51923H5C4.45 9.51923 4 9.04327 4 8.46154C4 7.87981 4.45 7.40385 5 7.40385ZM11 12.6923H5C4.45 12.6923 4 12.2163 4 11.6346C4 11.0529 4.45 10.5769 5 10.5769H11C11.55 10.5769 12 11.0529 12 11.6346C12 12.2163 11.55 12.6923 11 12.6923ZM15 6.34615H5C4.45 6.34615 4 5.87019 4 5.28846C4 4.70673 4.45 4.23077 5 4.23077H15C15.55 4.23077 16 4.70673 16 5.28846C16 5.87019 15.55 6.34615 15 6.34615Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Message;
