function InfoIcon({ color = "#000000", size = 10, ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M11 10.55V15.05M11 6.95894L11.009 6.94894M11 20C15.9705 20 20 15.9705 20 11C20 6.02944 15.9705 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9705 6.02944 20 11 20Z"
        stroke={color}
        stroke-width={size / 10}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default InfoIcon;
