import React from "react";
import { useSelector } from "react-redux";
import SmsSvgN from "../../../assets/img/svg/CusOverviewActionsIcon/SmsSvgN";
import SMS from "../../../assets/img/svg/SMS";
import SmsContactSvg from "../../../assets/img/svg/SmsContactSvg";
import ValidCallSvg from "../../../assets/img/svg/ValidCallSvg";
import ValidWhatsappSvg from "../../../assets/img/svg/ValidWhatsappSvg";

const PrimaryContactStatus = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <ValidCallSvg
          color={row.original.is_valid_primary_call ? "#12b112" : "#FD372A"}
          width={18}
          height={18}
        />
        <SMS
          color={row.original.is_valid_primary_sms ? "#12b112" : "#FD372A"}
          width={18}
          height={16}
        />
        <ValidWhatsappSvg
          color={row.original.is_valid_primary_whatsapp ? "#12b112" : "#FD372A"}
          width={18}
          height={18}
        />
        <SmsContactSvg
          color={row.original.is_valid_primary_email ? "#12b112" : "#FD372A"}
          width={18}
          height={18}
        />
      </div>
    </>
  );
};
export default PrimaryContactStatus;
