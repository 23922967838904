export default function AnalyticsRepo({ size = 22, color = "#F8F8FB" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75 18.5H19.25C19.4489 18.5 19.6397 18.579 19.7803 18.7197C19.921 18.8603 20 19.0511 20 19.25C20 19.4489 19.921 19.6397 19.7803 19.7803C19.6397 19.921 19.4489 20 19.25 20H5.75C4.7558 19.9988 3.80267 19.6033 3.09966 18.9003C2.39666 18.1973 2.00119 17.2442 2 16.25V2.75C2 2.55109 2.07902 2.36032 2.21967 2.21967C2.36032 2.07902 2.55109 2 2.75 2C2.94891 2 3.13968 2.07902 3.28033 2.21967C3.42098 2.36032 3.5 2.55109 3.5 2.75V16.25C3.5 16.8467 3.73705 17.419 4.15901 17.841C4.58097 18.2629 5.15326 18.5 5.75 18.5Z"
        fill={color}
      />
      <path
        d="M7.02926 16.7793C6.88861 16.9199 6.69785 16.9989 6.49893 16.9989C6.30002 16.9989 6.10926 16.9199 5.9686 16.7793C5.82795 16.6386 5.74893 16.4478 5.74893 16.2489V10.9989C5.74893 10.8 5.82795 10.6093 5.9686 10.4686C6.10926 10.328 6.30002 10.2489 6.49893 10.2489C6.69785 10.2489 6.88861 10.328 7.02926 10.4686C7.16992 10.6093 7.24893 10.8 7.24893 10.9989V16.2489C7.24893 16.4478 7.16992 16.6386 7.02926 16.7793Z"
        fill={color}
      />
      <path
        d="M9.50107 9.5V16.25C9.50107 16.4489 9.58008 16.6397 9.72074 16.7803C9.86139 16.921 10.0522 17 10.2511 17C10.45 17 10.6407 16.921 10.7814 16.7803C10.922 16.6397 11.0011 16.4489 11.0011 16.25V9.5C11.0011 9.30109 10.922 9.11032 10.7814 8.96967C10.6407 8.82902 10.45 8.75 10.2511 8.75C10.0522 8.75 9.86139 8.82902 9.72074 8.96967C9.58008 9.11032 9.50107 9.30109 9.50107 9.5Z"
        fill={color}
      />
      <path
        d="M13.25 16.25V11.75C13.25 11.5511 13.329 11.3603 13.4697 11.2197C13.6103 11.079 13.8011 11 14 11C14.1989 11 14.3897 11.079 14.5303 11.2197C14.671 11.3603 14.75 11.5511 14.75 11.75V16.25C14.75 16.4489 14.671 16.6397 14.5303 16.7803C14.3897 16.921 14.1989 17 14 17C13.8011 17 13.6103 16.921 13.4697 16.7803C13.329 16.6397 13.25 16.4489 13.25 16.25Z"
        fill={color}
      />
      <path
        d="M16.9989 8.74893V16.2489C16.9989 16.4478 17.078 16.6386 17.2186 16.7793C17.3593 16.9199 17.55 16.9989 17.7489 16.9989C17.9478 16.9989 18.1386 16.9199 18.2793 16.7793C18.4199 16.6386 18.4989 16.4478 18.4989 16.2489V8.74893C18.4989 8.55002 18.4199 8.35926 18.2793 8.2186C18.1386 8.07795 17.9478 7.99893 17.7489 7.99893C17.55 7.99893 17.3593 8.07795 17.2186 8.2186C17.078 8.35926 16.9989 8.55002 16.9989 8.74893Z"
        fill={color}
      />
      <path
        d="M7.03222 8.53062C6.8916 8.67128 6.70087 8.75033 6.50197 8.75037C6.35366 8.75034 6.20868 8.70634 6.08538 8.62392C5.96207 8.54151 5.86597 8.42439 5.80921 8.28736C5.75246 8.15034 5.73761 7.99956 5.76653 7.8541C5.79546 7.70863 5.86686 7.57501 5.97172 7.47012L8.66122 4.78062C9.08316 4.35881 9.65535 4.12185 10.252 4.12185C10.8486 4.12185 11.4208 4.35881 11.8427 4.78062L13.4717 6.40887C13.6124 6.54948 13.8031 6.62846 14.002 6.62846C14.2008 6.62846 14.3916 6.54948 14.5322 6.40887L18.7217 2.22012C18.8632 2.0835 19.0526 2.00791 19.2493 2.00962C19.4459 2.01133 19.634 2.0902 19.7731 2.22926C19.9121 2.36831 19.991 2.55642 19.9927 2.75307C19.9944 2.94972 19.9188 3.13917 19.7822 3.28062L15.5927 7.47012C15.1708 7.89193 14.5986 8.12889 14.002 8.12889C13.4054 8.12889 12.8332 7.89193 12.4112 7.47012L10.7822 5.84112C10.6393 5.70494 10.4494 5.62898 10.252 5.62898C10.0545 5.62898 9.86467 5.70494 9.72172 5.84112L7.03222 8.53062Z"
        fill={color}
      />
    </svg>
  );
}
