import React from "react";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";

const FilterInputBox = ({ children, ...props }) => {
  return (
    // <FormControl>
    <Input
      onKeyDown={(e) => {
        if (e.key == "ArrowUp" || e.key == "ArrowDown") {
          e.preventDefault();
        }
      }}
      error={props.error}
      overrides={{
        Root: {
          style: ({ $theme, $isFocused, $error }) => ({
            borderRadius: "5px",
            borderWidth: "1px",
            borderColor: $isFocused
              ? $error
                ? "#FD372A"
                : "#516BEB"
              : $error
              ? "#FD372A"
              : "#CDCED9",
            backgroundColor: "#ffffff",
            paddingRight: "0px",
          }),
        },
        Input: {
          style: ({ $theme, $isFocused }) => ({
            // paddingLeft: "10px",
            textAlign: "right",
            paddingRight: "5px",
            backgroundColor: $isFocused ? "#516BEB10" : "#F5F6FA",
          }),
        },
        ClearIconContainer: {
          style: ({ $theme, $isFocused }) => ({
            paddingRight: "0",
            paddingLeft: "0",
            backgroundColor: $isFocused ? "#516BEB10" : "#F5F6FA",
          }),
        },
      }}
      clearable
      {...props}
    >
      {children}
    </Input>
    // </FormControl>
  );
};

export default FilterInputBox;
