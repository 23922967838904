import { Paragraph2 } from "baseui/typography";
import React, { useContext } from "react";
import {
  FormattedDate,
  FormattedTime,
  FormattedTimeParts,
  useIntl,
} from "react-intl";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import moment from "moment";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _ from "lodash";
import { StatefulTooltip } from "baseui/tooltip";

const DateTime = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;

  let intl = useIntl();

  const [visible, setVisible] = React.useState(false);
  let referenceData = useContext(MaxyfiContext);

  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  let { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  value = _.get(row.original, accessor, null);

  let date = utcTimstampToLocalDate(
    value,
    currentDefaultFormatDetails.date_format
  );

  let finelDate = value
    ? moment
        ?.utc(value)
        ?.tz(currentDefaultFormatDetails.time_zone)
        ?.format(currentDefaultFormatDetails.date_format)
    : "-";

  // moment.utc(resultValue).format(currentDefaultFormatDetails.date_format) +
  //   " " +
  //   intl.formatTime(resultValue);

  // dateLocal = moment(dateLocal);

  return visible ? (
    <StatefulTooltip
      accessibilityType={"tooltip"}
      content={
        <div style={{ minWidth: "auto", maxWidth: "200px" }}>
          {" "}
          {value ? finelDate : <>-</>} {value ? intl.formatTime(value) : <>-</>}
        </div>
      }
    >
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          // margin: "5px",
          lineHeight: "18px",
        }}
      >
        <div className="elip" ref={ref} style={{ lineHeight: "18px" }}>
          {value === 0 ? (
            ""
          ) : (
            <>
              {value ? finelDate : <>-</>}{" "}
              {value ? intl.formatTime(value) : <>-</>}
            </>
          )}
        </div>
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: alignment,
        lineHeight: "18px",
        // margin: "5px",
      }}
    >
      {value === 0 ? (
        ""
      ) : (
        <>
          {value ? finelDate : <>-</>} {value ? intl.formatTime(value) : <>-</>}
        </>
      )}
    </Paragraph2>
  );
};

export default DateTime;
