import React from "react";

const NewUserOutline = ({ size = 18, color = "#787878" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.015 3.825C10.2525 3.825 11.2575 4.7475 11.2575 5.88C11.2575 7.0125 10.2525 7.935 9.015 7.935C7.7775 7.935 6.7725 7.0125 6.7725 5.88C6.7725 4.7475 7.7775 3.825 9.015 3.825ZM9.015 3C7.32 3 5.9475 4.29 5.9475 5.88C5.9475 7.47 7.32 8.76 9.015 8.76C10.71 8.76 12.0825 7.47 12.0825 5.88C12.0825 4.29 10.71 3 9.015 3ZM14.685 10.5825C14.805 10.5825 14.865 10.6425 14.88 10.665C14.925 10.7175 14.9175 10.755 14.91 10.77C14.505 13.005 13.1475 15.675 9.015 15.675C8.7975 15.675 3.6675 15.615 3.0675 10.755C3.0675 10.725 3.075 10.695 3.105 10.665C3.15 10.6125 3.225 10.5825 3.3075 10.5825H14.6925M14.6925 9.7575H3.3C2.67 9.7575 2.175 10.275 2.2425 10.86C2.9475 16.4925 9.0075 16.5 9.0075 16.5C14.04 16.5 15.3675 12.8475 15.7125 10.92C15.825 10.3125 15.33 9.7575 14.67 9.7575H14.6925Z"
        fill={color}
      />
    </svg>
  );
};

export default NewUserOutline;
