import { Drawer } from "baseui/drawer";
import { HeadingXLarge, LabelSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";

const FieldVisitAudit = ({ isOpen, setIsOpen, audit }) => {
  let intl = useIntl();
  let { currentOrganization, referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  return (
    <>
      <Drawer
        isOpen={isOpen}
        overrides={{
          DrawerContainer: {
            style: ({ $theme }) => ({
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              marginTop: "0px",
              width: "440px",
            }),
          },
        }}
        autoFocus
        onClose={() => setIsOpen(false)}
      >
        <HeadingXLarge>Audit</HeadingXLarge>

        {isOpen &&
          audit?.map((apIn) => {
            let { data, ...rest } = apIn;
            let ap = { ...rest, ...data };

            let normalizedObject = Object.keys(ap).reduce((prev, curr) => {
              let result = { ...prev };

              if (Array.isArray(ap[curr])) {
                let innerObject = {};

                ap[curr].map((cr) => {
                  if (cr.label && cr.value) {
                    innerObject[cr.label] = cr.value;
                  }
                });

                result = { ...prev, ...innerObject };

                return result;
              } else if (typeof ap[curr] === "object") {
                result = { ...prev, ...ap[curr] };
              } else {
                result[curr] = ap[curr];
              }

              return result;
            }, {});

            return (
              <>
                {Object.keys(normalizedObject).map((e, i) => {
                  let resultLabel = "";
                  let resultValue = "";

                  let referenceKeys = [
                    { key: "audit_by", rdName: "users" },
                    { key: "payment_mode", rdName: "payment_mode" },
                    {
                      key: "pricing_type",
                      rdName: "invoice_item_pricing_type",
                    },
                    { key: "visit_time", rdName: "time" },
                    { key: "status", rdName: "field_visit_status" },
                  ];

                  let exculdeKeys = ["_id", "logo"];
                  let dateKeys = ["audit_at"];
                  let date = ["visit_date"];
                  let enableKey = ["is_disabled"];
                  let natureOfTax = ["nature_of_tax"];

                  if (exculdeKeys.includes(e)) {
                    return null;
                  }

                  if (Array.isArray(normalizedObject[e])) {
                    return null;
                  }

                  if (referenceKeys.find((rk) => rk.key === e)) {
                    let refKey = referenceKeys.find((rk) => rk.key === e);
                    let rdData = referenceData[refKey.rdName]
                      ? referenceData[refKey.rdName].find(
                          (rd) => rd.id === normalizedObject[e]
                        )
                      : null;

                    if (rdData && rdData.id) {
                      resultLabel = e;
                      resultValue = rdData.label;
                    }
                  } else {
                    resultLabel = e;
                    resultValue = normalizedObject[e];
                  }

                  if (dateKeys.includes(e)) {
                    resultValue =
                      moment
                        .utc(resultValue)
                        .format(currentDefaultFormatDetails.date_format) +
                      " " +
                      intl.formatTime(resultValue ? resultValue : "");
                  }
                  if (date.includes(e)) {
                    resultValue = moment
                      .utc(resultValue)
                      .format(currentDefaultFormatDetails.date_format);
                    // +
                    // " " +
                    // intl.formatTime(resultValue);
                  }

                  if (enableKey.includes(e)) {
                    resultValue = resultValue ? "Disabled" : "Enabled";
                  }

                  //   if (natureOfTax.includes(e)) {
                  //     let natureTax =
                  //       invNatureOfTax &&
                  //       invNatureOfTax.data &&
                  //       invNatureOfTax.data.docs.find(
                  //         ({ id }) => id === resultValue
                  //       );
                  //     resultValue = natureTax.label;
                  //   }
                  return (
                    <div>
                      <div className="payment_audit_dtl">
                        <ParagraphMedium
                          $style={{ flex: "1", color: "#ADADAD" }}
                        >
                          <FormattedMessage
                            id={resultLabel ? resultLabel : "address"}
                          >
                            {resultLabel ? resultLabel : "address"}
                          </FormattedMessage>
                        </ParagraphMedium>
                        <LabelSmall
                          $style={{
                            flex: "2",
                          }}
                        >
                          {resultValue}
                        </LabelSmall>
                      </div>
                    </div>
                  );
                })}{" "}
                <hr style={{ marginTop: "10px" }} />{" "}
              </>
            );
          })}
      </Drawer>
    </>
  );
};

export default FieldVisitAudit;
