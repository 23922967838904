import React, { useContext } from "react";
import ToolTip from "../ToolTip/ToolTip";
import { FormattedDate } from "react-intl";
import { Paragraph2 } from "baseui/typography";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import moment from "moment";
import "moment-timezone";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { StatefulTooltip } from "baseui/tooltip";
import _ from "lodash";

function Date(props) {
  let { dateFormat, space, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  let { value, column, alignment, accessor, row, ...rest } = props;

  value = _.get(row.original, accessor, "");

  const [visible, setVisible] = React.useState(false);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  let date = value
    ? moment
        ?.utc(value)
        ?.format(
          currentDefaultFormatDetails &&
            currentDefaultFormatDetails?.date_format
        )
    : "-";

  return visible ? (
    <StatefulTooltip accessibilityType={"tooltip"} content={date}>
      <Paragraph2
        ref={ref}
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          margin: "5px",
        }}
      >
        <div className="elip" ref={ref}>
          {date ? date : "-"}
        </div>

        {/* <FormattedDate
        value={date}
        year="numeric"
        month="2-digit"
        day="2-digit"
      /> */}
      </Paragraph2>
    </StatefulTooltip>
  ) : (
    <Paragraph2
      ref={ref}
      $style={{
        width: "100%",
        textAlign: alignment,
        margin: "5px",
        whiteSpace: "nowrap",
      }}
    >
      {date ? date : "-"}

      {/* <FormattedDate
        value={date}
        year="numeric"
        month="2-digit"
        day="2-digit"
      /> */}
    </Paragraph2>
  );
}
export default Date;
