import React from "react";
import TimelineCard from "./TimelineCard";

const TimelineMap = ({ data, getParams, customerId, entity }) => {
  return (
    <div>
      {data.map((tim) => (
        <TimelineCard
          {...tim}
          key={tim._id}
          getParams={getParams}
          customerId={customerId}
          entity={entity}
        />
      ))}
    </div>
  );
};

export default TimelineMap;
