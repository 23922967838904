import React from "react";

function BusinessUnit({ color = "#333860", size = 22 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5921 11.5714V10.1428H9.9079V11.5714H8.5921ZM7.93421 5.49998H10.5658V4.2912C10.5658 4.23625 10.5447 4.18588 10.5025 4.1401C10.4604 4.0943 10.414 4.0714 10.3633 4.0714H8.13666C8.08604 4.0714 8.03965 4.0943 7.99746 4.1401C7.95529 4.18588 7.93421 4.23625 7.93421 4.2912V5.49998ZM4.18928 15.5C3.85695 15.5 3.57566 15.375 3.3454 15.125C3.11513 14.875 3 14.5696 3 14.2088V11.3928H7.60527V11.9972C7.60527 12.1813 7.662 12.3349 7.77546 12.4581C7.8889 12.5813 8.03039 12.6428 8.19991 12.6428H10.3001C10.4696 12.6428 10.6111 12.5813 10.7245 12.4581C10.838 12.3349 10.8947 12.1813 10.8947 11.9972V11.3928H15.5V14.2088C15.5 14.5696 15.3849 14.875 15.1546 15.125C14.9243 15.375 14.643 15.5 14.3107 15.5H4.18928ZM3 10.3214V6.7912C3 6.43039 3.11513 6.12498 3.3454 5.87498C3.57566 5.62498 3.85695 5.49998 4.18928 5.49998H6.94738V4.2912C6.94738 3.9304 7.06251 3.625 7.29277 3.375C7.52304 3.125 7.80433 3 8.13666 3H10.3633C10.6957 3 10.977 3.125 11.2072 3.375C11.4375 3.625 11.5526 3.9304 11.5526 4.2912V5.49998H14.3107C14.643 5.49998 14.9243 5.62498 15.1546 5.87498C15.3849 6.12498 15.5 6.43039 15.5 6.7912V10.3214H10.8947V9.71704C10.8947 9.53298 10.838 9.37936 10.7245 9.2562C10.6111 9.13302 10.4696 9.07143 10.3001 9.07143H8.19991C8.03039 9.07143 7.8889 9.13302 7.77546 9.2562C7.662 9.37936 7.60527 9.53298 7.60527 9.71704V10.3214H3Z"
        fill={color}
      />
    </svg>
  );
}

export default BusinessUnit;
