import React from "react";

const EditContact = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.51696 13.7986H10.4648C11.247 13.7986 11.8826 13.163 11.8826 12.3809V8.30797C11.8826 8.05385 11.6767 7.8486 11.4232 7.8486C11.1696 7.8486 10.9638 8.05437 10.9638 8.30797V12.3809C10.9638 12.6558 10.7398 12.8798 10.4648 12.8798H3.51696C3.24203 12.8798 3.01797 12.6558 3.01797 12.3809V5.43297C3.01797 5.15804 3.24203 4.93398 3.51696 4.93398H7.58984C7.84345 4.93398 8.04922 4.72821 8.04922 4.47461C8.04922 4.22101 7.84345 4.01523 7.58984 4.01523H3.51696C2.73482 4.01523 2.09922 4.65083 2.09922 5.43297V12.3809C2.09922 13.163 2.73482 13.7986 3.51696 13.7986Z"
        fill="#ADADAD"
        stroke="#ADADAD"
        stroke-width="0.2"
      />
      <path
        d="M8.18648 9.6065C8.275 9.58884 8.35721 9.54544 8.42135 9.4808C8.4214 9.48075 8.42144 9.4807 8.42149 9.48066L13.3833 4.5183L8.16692 9.50843M8.18648 9.6065L8.16692 9.50843M8.18648 9.6065L8.18653 9.60649L8.16692 9.50843M8.18648 9.6065L6.49315 9.94515L6.47305 9.84719M8.16692 9.50843L6.47305 9.84719M6.47305 9.84719L6.49266 9.94525L6.49293 9.94519L6.47305 9.84719ZM6.4162 7.47598L6.4162 7.47599L13.3834 4.51824C13.6513 4.25074 13.7989 3.8947 13.7989 3.51601C13.7989 3.13732 13.6513 2.7812 13.3839 2.51379L13.3839 2.51376C12.8307 1.96106 11.9317 1.96101 11.379 2.51379L6.4162 7.47598ZM6.07744 9.81972C6.0775 9.81978 6.07756 9.81984 6.07763 9.8199L6.14815 9.74901L6.07721 9.81949C6.07729 9.81957 6.07737 9.81965 6.07744 9.81972ZM6.29064 7.7108L5.95192 9.40492L6.38873 7.73025L6.29067 7.71064L6.29066 7.71072C6.29065 7.71075 6.29065 7.71078 6.29064 7.7108ZM7.86984 8.73301L6.98809 8.90945L7.16417 8.02731L12.0282 3.16326C12.0283 3.16322 12.0283 3.16318 12.0283 3.16314C12.2237 2.96865 12.5403 2.96898 12.7339 3.16299C12.8284 3.25789 12.8801 3.38259 12.8801 3.51601C12.8801 3.64936 12.8285 3.77394 12.734 3.86895C12.734 3.86899 12.7339 3.86903 12.7339 3.86907L7.86984 8.73301Z"
        fill="#ADADAD"
        stroke="#ADADAD"
        stroke-width="0.2"
      />
      <path
        d="M12.3823 5.2309C12.2903 5.2309 12.1983 5.1959 12.1284 5.12544L10.7733 3.76989C10.6329 3.62951 10.6329 3.40192 10.7733 3.26154C10.9136 3.12115 11.1412 3.12115 11.2817 3.26154L12.6367 4.61709C12.7771 4.75747 12.7771 4.98506 12.6367 5.12544C12.5663 5.19546 12.4743 5.2309 12.3823 5.2309Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default EditContact;
