import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ClipBoard from "../../assets/img/svg/ClipBoard";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import CustomerPortalSettings from "./CustomerPortalSettings";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { CUSTOMER_PORTAL_SETTINGS } from "../../providers/RBACProvider/permissionList";

const CustomerPortalLink = ({ getParams }) => {
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );
  // const NEW_WINDOW =  {customerDetails customerDetails.customer_portal_url
  //   ? customerDetails.customer_portal_url
  //   : ""}

  const openInNewTab = (url) => {
    // localStorage.setItem("url", url);
    window.open(url, "_blank", "noopener,noreferrer");
  };
  //
  const notify = () => toast.success("Linked Copied Successfully");
  return (
    <div
      style={{
        padding: "0px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        // gap: "10px",
        justifyContent: "end",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{}}
          className="cp__customer_portal"
          onClick={() =>
            openInNewTab(
              `${
                customerDetails && customerDetails.customer_portal_url
                  ? customerDetails.customer_portal_url +
                    "?is_internal_call=true"
                  : ""
              }`
            )
          }
        >
          {customerDetails && customerDetails.customer_portal_url
            ? "Consumer Portal Link"
            : ""}
        </div>
        <CopyToClipboard
          text={
            customerDetails && customerDetails.customer_portal_url
              ? customerDetails.customer_portal_url + "?is_internal_call=true"
              : ""
          }
          // onCopy={() => setCopied(true)}
        >
          <IconButton
            kind={KIND.tertiary}
            size={SIZE.mini}
            onClick={(e) => {
              e.preventDefault();
              notify();
            }}
          >
            <ClipBoard />
          </IconButton>
        </CopyToClipboard>
        <RBACWrapper role={CUSTOMER_PORTAL_SETTINGS} type="PERMISSION">
          <CustomerPortalSettings getParams={getParams} />
        </RBACWrapper>
      </div>
    </div>
  );
};

export default CustomerPortalLink;
