import {
  ADD_MODAL,
  EDIT_MODAL,
  CLOSE_MODAL,
  SET_CREDIT_NOTES,
  GET_CREDITNOTE_GRID_STRUCTURE,
  UPDATE_CREDITNOTE_TABLE_DRAFT_STATE,
  SET_CREDITNOTE_VIEW,
  LINKED_INVOICE_MODAL_DATA,
  GET_TABLE_DATA,
  CREDIT_NOTE_DATA,
  LINKED_ISMODAL,
  LINKED_CLOSE_MODAL,
  REMOVE_CREDIT_ATTACHED_FILES,
  REFETCH_CREDITS,
  INITIALSTATE_CREDIT_STATE,
  CHANGING_ALLOCATED_AMOUNT,
  CHANGING_DUE_AMOUNT,
  LINKED_AMOUNT,
  CREDIT_NOTE_WIDTH,
  CREDIT_NOTE_GRID_AND_VIEW,
  CREDIT_NOTE_FILTER,
  NAV_LINK_CREDIT_NOTE_RESET,
} from "./action";
import { CREDIT_UPDATE_ATTACHMENTS } from "./action";
import { NEW, EDIT } from "../../constants";
import columnMapper from "../../utils/mapper";
import { PAYMENT_ACTION } from "../customerOverview/payment/action";
import tableStateDraftMapper from "../../utils/tableStateDraftMapper";

const initialQueryBuilder = {
  combinator: "and",
  rules: [],
};

const initialState = {
  isModal: false,
  editData: {},
  width: 0,
  type: "",
  creditNotes: [],
  // Table
  creditNotesTableState: { hiddenColumns: [], columns: [], sort: null },
  tableData: [],
  linkedInvoiceData: [],
  creditNoteData: [],
  linkIsModal: false,
  creditNoteId: null,

  gridStructure: [],
  tableState: {
    hiddenColumns: [],
    columns: [],
    sort: null,
  },
  tableCurrentState: {
    filters: {},
    formFilters: initialQueryBuilder,
  },
  // columns: col_id, is_hidden, sort, order, width, filter
  tableDraftView: {
    table: [],
    filters: {},
    formFilters: initialQueryBuilder,
  },
  viewName: null,
  attachments: [],
  view: false,
  refetchActions: 0,
  due_amount: 0,
  allocated_amount: 0,
  linkedAmount: 0,
};

/*** TABLE SETTINGS ***/
const isExpanded = false;
const isExpandedHeader = false;
const isSelection = false;

export const creditNoteReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LINKED_AMOUNT: {
      return {
        ...state,
        linkedAmount: payload,
      };
    }
    case CREDIT_NOTE_WIDTH: {
      return {
        ...state,
        width: payload,
      };
    }
    case CHANGING_ALLOCATED_AMOUNT: {
      return {
        ...state,
        allocated_amount: payload,
      };
    }

    case CHANGING_DUE_AMOUNT: {
      return {
        ...state,
        due_amount: payload,
      };
    }

    case INITIALSTATE_CREDIT_STATE: {
      return initialState;
    }

    case REFETCH_CREDITS: {
      const { refetchActions } = state;
      return {
        ...state,
        refetchActions: refetchActions + 1,
      };
    }

    case LINKED_CLOSE_MODAL: {
      return {
        ...state,
        linkIsModal: false,
        creditNoteData: [],
      };
    }

    case LINKED_ISMODAL: {
      return {
        ...state,
        linkIsModal: true,
      };
    }

    case CREDIT_NOTE_DATA: {
      return {
        ...state,
        creditNoteData: payload,
      };
    }

    case LINKED_INVOICE_MODAL_DATA: {
      return {
        ...state,
        linkedInvoiceData: payload,
      };
    }

    case GET_CREDITNOTE_GRID_STRUCTURE: {
      let gridStructure = { ...payload }; // {menu, table[], widget[]}

      let { hiddenColumns = [], columns = [] } = columnMapper({
        columns: gridStructure.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        width: state.width - 500,
      });

      return {
        ...state,
        gridStructure,
        tableState: { hiddenColumns, columns },
      };
    }

    case SET_CREDITNOTE_VIEW: {
      let viewData = { ...payload };
      let newState = { ...state };

      newState.tableState = columnMapper({
        columns: state.gridStructure.table,
        viewSelect: viewData.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        width: state.width - 80,
      });
      newState.viewName = viewData.name;
      newState.tableDraftView = initialState.tableDraftView;
      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");
        newState.tableCurrentState.sort = [
          { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
        ];
      }
      if (newState.tableState.filters) {
        newState.tableCurrentState.formFilters = viewData.filter;
        newState.tableCurrentState.filters = viewData.filter;
      }

      return {
        ...newState,
        // viewName: viewData.name,
        // tableState: { hiddenColumns, columns, sort, filters },
        // tableDraftView: initialState.tableDraftView,
      };
    }

    case CREDIT_NOTE_GRID_AND_VIEW: {
      let newState = { ...state };

      let { grid_structure, default_view, pinned_view = [] } = payload;
      newState.gridStructure = grid_structure;
      newState.pinnedView = pinned_view;

      newState.tableState = columnMapper({
        columns: grid_structure?.table,
        viewSelect: default_view?.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        width: newState.width - 80,
      });
      newState.viewName = default_view?.name;

      newState.tableDraftView = initialState.tableDraftView;

      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");
        newState.tableCurrentState.sort = [
          { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
        ];
      }
      if (newState.tableState.filters) {
        newState.tableCurrentState.formFilters = default_view.filter;
        newState.tableCurrentState.filters = default_view.filter;
      }

      return {
        ...newState,
      };
    }

    case UPDATE_CREDITNOTE_TABLE_DRAFT_STATE: {
      let draftColumnData = tableStateDraftMapper({
        tableColumns: payload,
      });

      let newState = { ...state };

      newState.tableDraftView = { table: draftColumnData };

      if (
        state.tableCurrentState.sort &&
        state.tableCurrentState.sort[0] &&
        payload.sort[0] &&
        payload.sort[0].id !== state.tableCurrentState.sort[0].id &&
        payload.sort[0].desc !== state.tableCurrentState.sort[0].desc
      ) {
        newState.tableCurrentState = {
          ...newState.tableCurrentState,
          formFilters: newState.tableCurrentState.formFilters,
          filters: newState.tableCurrentState.filters,
          sort: payload.sort,
        };
      }

      return {
        ...newState,
      };
    }

    case GET_TABLE_DATA: {
      return {
        ...state,
        tableData: payload,
      };
    }
    case ADD_MODAL: {
      return {
        ...state,
        isModal: true,
        type: NEW,
      };
    }
    case EDIT_MODAL: {
      return {
        ...state,
        isModal: true,
        editData: payload,
        type: EDIT,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        isModal: false,
        editData: {},
        attachments: [],
      };
    }
    case SET_CREDIT_NOTES: {
      return {
        ...state,
        creditNotes: payload,
      };
    }

    // case CREDIT_UPDATE_ATTACHMENTS: {
    //
    //   return {
    //     ...state,
    //     attachments: [...state.attachments, payload],
    //   };
    // }
    case CREDIT_UPDATE_ATTACHMENTS: {
      return {
        ...state,
        attachments: [...state.attachments, payload],
      };
    }
    case REMOVE_CREDIT_ATTACHED_FILES: {
      state.attachments.splice(payload, 1);
      return {
        ...state,
        attachments: [...state.attachments],
      };
    }
    case CREDIT_NOTE_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }
    case NAV_LINK_CREDIT_NOTE_RESET: {
      return {
        ...state,
        viewName: null,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: {},
          formFilters: initialQueryBuilder,
        },
      };
    }

    default:
      return state;
  }
};
