import React from "react";

function ContactStatus({ size = 22, color = "#333860" }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83445 7.89599C9.09598 7.89599 10.1187 6.79999 10.1187 5.448C10.1187 4.09601 9.09598 3 7.83445 3C6.57291 3 5.55023 4.09601 5.55023 5.448C5.55023 6.79999 6.57291 7.89599 7.83445 7.89599Z"
        fill="#333860"
      />
      <path
        d="M11.6357 9.95638C11.1316 9.22684 10.3247 8.75481 9.44102 8.68723L8.21225 10.2322C8.03071 10.4727 7.63821 10.4726 7.4567 10.2322L6.22149 8.67914C4.70282 8.79674 3.50192 10.0898 3.50114 11.6622L3.49994 13.8298H4.59903V12.6329H5.56444V13.8298H9.72278C9.66482 13.5794 9.63562 13.3222 9.63562 13.0599C9.6356 11.6997 10.433 10.4982 11.6357 9.95638Z"
        fill="#333860"
      />
      <path
        d="M13.0395 10.6198C11.6949 10.6198 10.601 11.7144 10.601 13.0599C10.601 14.4054 11.6949 15.5 13.0395 15.5C14.3841 15.5 15.478 14.4054 15.478 13.0599C15.478 11.7144 14.3841 10.6198 13.0395 10.6198ZM13.4888 14.7838H12.6045V12.7446H13.4888V14.7838ZM13.0395 12.446C12.7326 12.446 12.4838 12.1975 12.4838 11.891C12.4838 11.5844 12.7326 11.3359 13.0395 11.3359C13.3464 11.3359 13.5952 11.5844 13.5952 11.891C13.5952 12.1975 13.3464 12.446 13.0395 12.446Z"
        fill="#333860"
      />
    </svg>
  );
}

export default ContactStatus;
