import { LabelLarge, ParagraphMedium } from "baseui/typography";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import PlusOne from "../../assets/img/svg/Plus";
import { IconButton, KIND } from "../../components/IconButton";
import Loader from "../../components/Loader";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { addContactModal, customerConatctData } from "../../redux/actions";
import { getCustomerContactDetail } from "../../services";
import AddContactDetailsModal from "./AddContactDetailsModal";
import ContactDetailsCard from "./ContactDetailsCard";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { ADD_CONTACTS } from "../../providers/RBACProvider/permissionList";

const ContactContainer = ({ getParams, entity }) => {
  const { id } = useParams();
  let { currentOrganization } = useContext(MaxyfiContext);
  const [primaryContact, setPrimaryContact] = useState(1);
  const isModalOpen = useSelector((e) => e.customerOverviewReducer.isOpen);
  const { refetchActions } = useSelector((s) => s.customerOverviewReducer);
  const alertPopUpDatas = useSelector(
    (s) => s.customerOverviewReducer.customerDetails.alert_notes
  );

  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getInvParams = getSearchParams.get("inv");

  let dispatch = useDispatch();

  const { isLoading, data, refetch, isFetching } = useQuery(
    `customer-contacts-${getParams}_${refetchActions}`,
    async () => {
      return await getCustomerContactDetail({
        organization: currentOrganization,
        customerId: getParams,

        // invoiceId: entity === "INVOICE" ? getInvParams : "",
        ...(entity === "INVOICE" ? { invoiceId: getInvParams } : ""),
      });
    },

    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data && data.data && data.data.docs) {
      dispatch(customerConatctData({ conatctData: data.data.docs }));
    }
  }, [data?.data?.docs]);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  return (
    <div className="customer-profile__card-due-content">
      <div className="cp-card__tags-headers cp-card__tag-align cp-tag-card-border">
        <div className="cp-card__tag-holder">
          <div className="cp-card__tags-icn">
            <ParagraphMedium>Contact details</ParagraphMedium>
          </div>
          <div className="cp-total-tag">
            <ParagraphMedium $style={{ color: "#516BEB" }}>
              {data && data.data && data.data.docs && data.data.docs.length
                ? data.data.docs.length
                : 0}
            </ParagraphMedium>
          </div>
        </div>
        <div>
          <div className="cp-contact-details__add-btn">
            <RBACWrapper role={ADD_CONTACTS} type={"PERMISSION"}>
              <IconButton
                kind={KIND.primary}
                size={"mini"}
                style={{
                  width: "28px",
                  height: "28px",
                  borderRadius: "5px",
                }}
                onClick={() => dispatch(addContactModal())}
              >
                <PlusOne />
              </IconButton>
            </RBACWrapper>
          </div>
          {isModalOpen ? (
            <AddContactDetailsModal refetch={refetch} customerId={getParams} />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          flex: "1",
          display: "flex",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "min-content",
          }}
        >
          <ContactDetailsCard
            data={data && data.data && data.data.docs ? data.data.docs : []}
            refetch={refetch}
            customerId={getParams}
            entity={entity}
            setPrimaryContact={setPrimaryContact}
            primaryContact={primaryContact}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactContainer;
