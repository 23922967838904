import React, { useContext, useState, useEffect } from "react";
import { getOrganization } from "../../../../../../services";
import {
  useForm,
  Controller,
  useFieldArray,
  get,
  useWatch,
} from "react-hook-form";
import {
  TextButton,
  SIZE,
  KIND,
} from "../../../../../../components/TextButton";
import { isOpenModal } from "../../../../../../redux/actions";
import Location from "../../../../../../assets/img/svg/Information/Location";
import Loader from "../../../../../../components/Loader";
import {
  HeadingXLarge,
  LabelMedium,
  ParagraphMedium,
  ParagraphLarge,
  LabelLarge,
} from "baseui/typography";
import { SelectBox } from "../../../../../../components/SelectBox";
import { useIntl, FormattedMessage, FormattedTime } from "react-intl";
import ReturnVisit from "./ReturnVisit/ReturnVisit";
import PromiseForm from "../../PromiseForm";
import CallBack from "../../CallBack";
import Dispute from "../../Dispute";
import PaymentsAction from "../Payments";
import FieldVisitPaayment from "./ReturnVisit/FieldVisitPayment";
import DateRangeSelect from "../../../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import moment from "moment";
import { TextArea } from "../../../../../../components/TextArea";
import { useDispatch } from "react-redux";
import { IconButton } from "../../../../../../components/IconButton";
import FieldVisitDrawer from "./FieldVisitDrawer";
import FieldVisitForm from "./FieldVisitForm";
import LocationFL from "../../../../../../assets/img/svg/LocationFL";
import { values } from "lodash";
import getSelectValues from "../../../../../../utils/getSelectValues";
import { MaxyfiContext } from "../../../../../../providers/MaxyfiProvider";
import setSelectValues from "../../../../../../utils/setSelectValues";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { getFieldVisit, putCardFieldVisit } from "../../../../../../services";
import { useParams, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import FieldVisitCard from "./FieldVisitCard";
import FieldVisitAudit from "./FieldVisitAudit";
import { toast } from "react-toastify";
import { refetchActions } from "../../../../../../redux/actions";
import { ModalContext } from "../../../../../../providers/ModalProvider";
import Warning from "../../../../../../assets/img/warning.png";
import { instantPaymentDrawer } from "../../../../../../redux/customerOverview/callAction/action";

const FieldVisit = () => {
  let intl = useIntl();
  let dispatch = useDispatch();
  // const { customerDetails } = useSelector((s) => s.customerOverviewReducer);

  const customerOverviewRefetch = useSelector(
    (s) => s.customerOverviewReducer.refetchActions
  );
  const { handleModal } = useContext(ModalContext);

  const [success, setSuccess] = useState(false);
  const [outCome, setCome] = useState("");
  const [today, setToday] = useState(false);
  const [tomorrow, setTomorrow] = useState(false);
  const [capture, setCapture] = useState(false);
  const [fieldVisitStatus, setFieldVisit] = useState("SUCCESSFULL");
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [settingData, setSettingData] = useState(null);

  const [installments, setInstallments] = useState([]);
  const [disabled, setDisable] = useState(false);
  const [isCompute, setIsCompute] = useState(false);

  useEffect(() => {
    if (outCome === "PAYMENT_PLAN") {
      setIsCompute(true);
    }
  }, [outCome]);

  const [audit, setAudit] = useState(false);

  const [fieldVisitData, setFieldVisitData] = useState([]);

  const [fieldVisitEditData, setFieldVisitEditData] = useState({});
  const [fieldVisitDrawer, setFieldVisitDrawer] = useState(false);

  const { total_outstanding_invoice_amount, default_currency } = useSelector(
    (state) => state.customerOverviewReducer.customerDetails
  );
  const refetchAction = useSelector(
    (state) => state.customerOverviewReducer.refetchActions
  );

  //

  const getParams = getSearchParams.get("customers");

  const {
    referenceData,
    currentOrganization,
    restrictions,
    currentDefaultFormatDetails,
    users,
  } = useContext(MaxyfiContext);

  const [captureData, setCaptureData] = useState([]);

  const orgRef = referenceData.organizations.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef.time_zone;

  const schema = Yup.object({
    // FOR PAYMENT

    payment_amount:
      outCome === "PAYMENT" &&
      Yup.number()
        .test(
          "maxDigitsAfterDecimal",
          "number field must have 2 digits after decimal or less",
          (payment_amount) => /^\d+(\.\d{1,2})?$/.test(payment_amount)
        )
        .transform((value) => (isNaN(value) ? undefined : value)),

    date:
      outCome === "PAYMENT" && Yup.date().required("Payment Date is Required"),
    // payment_status: Yup.array().required("Status is Required"),
    payment_status:
      outCome === "PAYMENT" &&
      Yup.array().min(1, "Required").required("Status is Required"),

    cur:
      outCome === "PAYMENT" &&
      Yup.array().min(1, "Required").required("Currency is Required"),

    // FOR PROMISE TO PAY
    promise_amount:
      outCome === "PROMISE_TO_PAY" &&
      Yup.object({
        value: Yup.number().typeError("Required").required("Required"),
        currency: Yup.array().min(1, "Required").required("Required"),
      }),
    promise_date:
      outCome === "PROMISE_TO_PAY" && Yup.date().required("Required"),

    promise_date:
      outCome === "PROMISE_TO_PAY" && Yup.date().required("Required"),

    confirmation_message_type:
      outCome === "CALL_BACK_LATER" ||
      "PROMISE_TO_PAY" ||
      "RETURN_VISIT" ||
      "UNSUCCESSFUL"
        ? Yup.array().when("is_send_confirmation_message", {
            is: (value) => {
              return value;
            },
            then: (schema) =>
              schema.ensure().min(1, "Required").required("Required"),
            // .nullable()
            otherwise: (schema) => schema.optional().nullable(),
          })
        : "",

    confirmation_message_template_id:
      outCome === "PROMISE_TO_PAY" ||
      "CALL_BACK_LATER" ||
      "RETURN_VISIT" ||
      "UNSUCCESSFUL"
        ? Yup.array().when("is_send_confirmation_message", {
            is: (value) => {
              return value;
            },
            then: (schema) =>
              schema.ensure().min(1, "Required").required("Required"),
            // .nullable()
            otherwise: (schema) => schema.optional().nullable(),
          })
        : "",

    action_follow_up_type:
      outCome === "PROMISE_TO_PAY" ||
      "CALL_BACK_LATER" ||
      "RETURN_VISIT" ||
      "UNSUCCESSFUL"
        ? Yup.array().when("is_send_action_follow_up", {
            is: (value) => {
              return value;
            },
            then: (schema) =>
              schema.ensure().min(1, "Required").required("Required"),
            // .nullable()
            otherwise: (schema) => schema.optional().nullable(),
          })
        : "",

    action_follow_up_template_id:
      outCome === "PROMISE_TO_PAY" ||
      "CALL_BACK_LATER" ||
      "RETURN_VISIT" ||
      "UNSUCCESSFUL"
        ? Yup.array().when("is_send_action_follow_up", {
            is: (value) => {
              return value;
            },
            then: (schema) =>
              schema.ensure().min(1, "Required").required("Required"),
            // .nullable()
            otherwise: (schema) => schema.optional().nullable(),
          })
        : "",

    // is_send_confirmation_message: Yup.object().shape({
    //   confirmation_message_template_id: Yup.array().when(
    //     "is_send_confirmation_message",
    //     {
    //       is: (value) => {
    //         return value;
    //       },
    //       then: (schema) =>
    //         schema.ensure().min(1, "Required").required("Required").nullable(),
    //       otherwise: (schema) => schema.optional().nullable(),
    //     }
    //   ),
    // }),

    // FOR CALL BACK LATER

    callback_date:
      outCome === "CALL_BACK_LATER" && Yup.date().required("Required"),
    callback_time:
      outCome === "CALL_BACK_LATER" &&
      Yup.array().min(1, "Required").required("Required"),

    // confirmation_message_type:
    //   outCome === "CALL_BACK_LATER" &&
    //   Yup.array().when("is_send_confirmation_message", {
    //     is: (value) => {
    //
    //       return value;
    //     },
    //     then: (schema) =>
    //       schema.ensure().min(1, "Required").required("Required"),
    //     // .nullable()
    //     otherwise: (schema) => schema.optional().nullable(),
    //   }),

    // confirmation_message_template_id:
    //   outCome === "CALL_BACK_LATER" &&
    //   Yup.array().when("is_send_confirmation_message", {
    //     is: (value) => {
    //
    //       return value;
    //     },
    //     then: (schema) =>
    //       schema.ensure().min(1, "Required").required("Required"),
    //     // .nullable()
    //     otherwise: (schema) => schema.optional().nullable(),
    //   }),

    // FOR DISPUTE

    dispute_details: outCome === "DISPUTE" && Yup.string().required("Required"),

    dispute_type:
      outCome === "DISPUTE" &&
      Yup.array().min(1, "Required").required("Required"),

    dispute_review_date:
      outCome === "DISPUTE" && Yup.date().required("Required"),
    // FOR RETURN VISIT
    return_visit_date:
      outCome === "RETURN_VISIT" && Yup.date().required("Required"),
    return_visit_time:
      outCome === "RETURN_VISIT" &&
      Yup.array().min(1, "Required").required("Required"),

    // promise_amount: Yup.number().when("promise_type", {
    //   is: (val) => {
    //     return val && val[0] && val[0]?.id === "partial_amount" ? true : false;
    //   },
    //   then: (schema) =>
    //     Yup.number().max(
    //       total_outstanding_invoice_amount.value,
    //       "Greater than Total Amount1"
    //     ),
    //   otherwise: (schema) => schema.optional(),
    // }),
    reason:
      outCome === "UNSUCCESSFULL" &&
      Yup.array().min(1, "Required").required("Required"),
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  let promiseAmountChangingValue = watch(["promise_amount", "promise_type"]);
  let [promise_amount, promise_type] = promiseAmountChangingValue;

  useEffect(async () => {
    await getOrganization({ organization: currentOrganization }).then((res) => {
      setSettingData(res.data.doc || {});
    });
    //TODO: PRAVEEN FAILURE ERROR HANDLING
  }, []);

  useEffect(() => {
    if (outCome === "PROMISE_TO_PAY") {
      setValue("is_send_confirmation_message", false);
      setValue("is_send_action_follow_up", false);
      setValue("promise_type", [{ id: "full_amount" }]);
      if (
        total_outstanding_invoice_amount &&
        total_outstanding_invoice_amount.value
      ) {
        setValue(
          "promise_amount.value",
          Number(total_outstanding_invoice_amount?.value).toFixed(2)
        );
      }

      if (
        total_outstanding_invoice_amount &&
        total_outstanding_invoice_amount.currency
      ) {
        setValue("promise_amount.currency", [
          { id: `${total_outstanding_invoice_amount.currency}` },
        ]);
      }
    }
  }, [outCome]);
  //   {
  //   // reValidateMode: "onChange",
  //   resolver: yupResolver(schema),
  //   defaultValues: {
  //     // payment_status: setSelectValues(defaultValuesStatus[0].id),
  //     cur: setSelectValues(customerDetails.default_currency),
  //   },
  // }

  // let {"met"} = useWatch();
  //
  let formValues = watch();

  let paymentStatus = "success";

  //
  let onlyChangedValue = formValues?.invoices?.filter(
    (i) => i.allocatedAmount != undefined
  );
  let changedKeyValues = onlyChangedValue?.map((i) => {
    return { invoice_id: i._id, amount: Number(i.allocatedAmount) };
  });

  const gettingFieldVisit = useQuery(
    [
      `GETTING_FIELD_VISIT_${currentOrganization}_${customerOverviewRefetch}_${getParams}_${refetchAction}`,
    ],
    async ({ queryKey }) => {
      return await getFieldVisit({
        organization: currentOrganization,
        customerId: getParams,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (gettingFieldVisit) {
      setFieldVisitData(
        gettingFieldVisit &&
          gettingFieldVisit?.data &&
          gettingFieldVisit?.data?.data &&
          gettingFieldVisit?.data?.data?.docs
      );
    }
  }, [gettingFieldVisit, customerOverviewRefetch]);

  // useEffect(() => {
  //   if (gettingFieldVisit) {
  //     let user = "";
  //     users.filter((e) => {
  //       if (e.id === data?.created_by) {
  //         user = e.displayName;
  //       }
  //     });
  //     // setUserName(user);
  //   }
  // }, [gettingFieldVisit]);

  const putCardData = useMutation(
    (data) =>
      putCardFieldVisit({
        organization: currentOrganization,
        customerId: getParams,
        // checkinId: editingDatas._id,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        const { payment_plan_list, is_debit_first_instalment } = variables;

        if (is_debit_first_instalment) {
          dispatch(
            instantPaymentDrawer({
              modal: true,
              ...(payment_plan_list &&
              payment_plan_list[0] &&
              payment_plan_list[0].promise_amount &&
              payment_plan_list[0].promise_amount.value > 0
                ? {
                    amount: payment_plan_list[0].promise_amount.value,
                    currency: payment_plan_list[0].promise_amount.currency,
                  }
                : {}),
            })
          );
        }
        reset();
        setCapture(false);
        dispatch(refetchActions());
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {};
    if (outCome === "PROMISE_TO_PAY") {
      values = {
        field_visit_id: captureData && captureData[0]._id,
        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        ...(data.is_enable_calendar_alert
          ? { calendar_alert_time: getSelectValues(data?.calendar_alert_time) }
          : {}),
        is_enable_calendar_alert: data.is_enable_calendar_alert || false,
        // content: "",
        promise_amount: {
          currency: getSelectValues(data && data?.promise_amount?.currency),
          value: data?.promise_amount?.value,
        },
        promise_type: getSelectValues(data?.promise_type),
        promise_date: moment
          .tz(
            `${data?.promise_date.getFullYear()}-${
              data?.promise_date.getMonth() + 1
            }-${data?.promise_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        is_send_confirmation_message: data?.is_send_confirmation_message
          ? data?.is_send_confirmation_message
          : false,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up
          ? data?.is_send_action_follow_up
          : false,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    if (outCome === "RECORD_DISPUTE") {
      values = {
        field_visit_id: captureData && captureData[0]._id,

        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: "RECORD_DISPUTE",
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        dispute_details: data.dispute_details,
        dispute_review_date: moment
          .tz(
            `${data.dispute_review_date.getFullYear()}-${
              data.dispute_review_date.getMonth() + 1
            }-${data.dispute_review_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        dispute_type: getSelectValues(data.dispute_type),
        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        comment: data?.comment,
      };
    }

    if (outCome === "CALL_BACK_LATER") {
      values = {
        field_visit_id: captureData && captureData[0]._id,

        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        // content: "",

        callback_time: getSelectValues(data?.callback_time),
        callback_date: moment
          .tz(
            `${data?.callback_date.getFullYear()}-${
              data?.callback_date.getMonth() + 1
            }-${data?.callback_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        is_send_confirmation_message: data?.is_send_confirmation_message
          ? data?.is_send_confirmation_message
          : false,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up
          ? data?.is_send_action_follow_up
          : false,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    if (outCome === "PAYMENT") {
      values = {
        field_visit_id: captureData && captureData[0]._id,

        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        // content: "",
        payment: {
          date: moment
            .tz(
              `${data.date.getFullYear()}-${
                data.date.getMonth() + 1
              }-${data.date.getDate()}`,
              "YYYY-MM-DD",
              timeZone
            )
            .utc()
            .valueOf(),
          amount: {
            value: data.payment_amount,
            currency: getSelectValues(data.cur),
          },

          payment_currency: getSelectValues(data.cur),
          method: getSelectValues(data.payment_method),
          reference: data.payment_reference,
          status: getSelectValues(data.payment_status),
          ...(data.expected_payment_date &&
          data.expected_payment_date?.getFullYear()
            ? {
                expected_date: moment
                  .tz(
                    `${data.expected_payment_date?.getFullYear()}-${
                      data.expected_payment_date?.getMonth() + 1
                    }-${data.expected_payment_date?.getDate()}`,
                    "YYYY-MM-DD",
                    timeZone
                  )
                  .utc()
                  .valueOf(),
              }
            : { expected_date: 1 }),
          comment: data.comment,
          ...(data.status && data.status[0] && data.status[0].id != "success"
            ? {}
            : { allocated_to: changedKeyValues }),
        },

        // amount: {
        //   value: data.payment_amount,
        //   currency: getSelectValues(data.cur),
        // },
      };
    }

    let paymentPlan = {
      action_type: "FIELD_VISIT",
      comment: data.comment ? data.comment : "",
      visit_outcome: outCome,
    };

    if (outCome === "PAYMENT_PLAN") {
      let {
        is_hold_workflow = false,
        is_send_confirmation_message = false,
        confirmation_message_type,
        confirmation_message_template_id,
        is_send_action_follow_up = false,
        action_follow_up_type,
        action_follow_up_template_id,
        comment,
        start_date,
        residual_amount_type,
        amount,
        instalation_amount,
        frequency,
        payment_method,
        content,
        is_enable_calendar_alert = false,
        calendar_alert_time,
        is_debit_first_instalment = false,
      } = data;

      values = {
        field_visit_id: captureData && captureData[0]._id,

        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        comment: data.comment,
        frequency: getSelectValues(frequency),
        ...(is_enable_calendar_alert
          ? { calendar_alert_time: getSelectValues(calendar_alert_time) }
          : {}),
        is_enable_calendar_alert,
        is_debit_first_instalment,
        instalation_amount: {
          currency: default_currency,
          value: instalation_amount,
        },
        method: getSelectValues(payment_method),
        payment_plan_list: installments,
        amount: { currency: default_currency, value: amount },
        residual_amount_type: getSelectValues(residual_amount_type),

        start_date: moment
          .tz(
            `${start_date.getFullYear()}-${
              start_date.getMonth() + 1
            }-${start_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        is_hold_workflow,

        is_send_confirmation_message,
        is_send_action_follow_up,
        ...(is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                confirmation_message_template_id
              ),
            }
          : {}),
        ...(is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(action_follow_up_type),
              action_follow_up_template_id: getSelectValues(
                action_follow_up_template_id
              ),
            }
          : {}),
        // is_debit_first_instalment: true,
        // // calendar_alert_time: "9_15_AM",

        // is_enable_calendar_alert: true,
      };
    }

    if (outCome === "RETURN_VISIT") {
      values = {
        field_visit_id: captureData && captureData[0]._id,

        visit_person: getSelectValues(data?.visit_person),
        visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        // content: "",
        // promise_amount: {
        //   currency: getSelectValues(data && data?.promise_amount?.currency),
        //   values: data?.promise_amount?.value,
        // },
        return_visit_time: getSelectValues(data?.return_visit_time),
        return_visit_contact: getSelectValues(data?.location),
        // return_visit_contact:"",
        return_visit_date: moment
          .tz(
            `${data?.return_visit_date.getFullYear()}-${
              data?.return_visit_date.getMonth() + 1
            }-${data?.return_visit_date.getDate()}`,
            "YYYY-MM-DD",
            timeZone
          )
          .utc()
          .valueOf(),
        custom_location: data.custom_location,
        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        is_send_confirmation_message: data?.is_send_confirmation_message
          ? data?.is_send_confirmation_message
          : false,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up
          ? data?.is_send_action_follow_up
          : false,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    if (fieldVisitStatus === "UNSUCCESSFULL") {
      values = {
        reason: getSelectValues(data.reason),
        field_visit_id: captureData && captureData[0]._id,

        // visit_person: getSelectValues(data?.visit_person),
        // visit_outcome: outCome,
        visit_status: fieldVisitStatus,
        action_type: "FIELD_VISIT",
        // content: "",
        follow_up_time: getSelectValues(data?.follow_up_time),
        ...(data?.follow_up_date
          ? {
              follow_up_date: moment
                .tz(
                  `${data?.follow_up_date?.getFullYear()}-${
                    data?.follow_up_date?.getMonth() + 1
                  }-${data?.follow_up_date?.getDate()}`,
                  "YYYY-MM-DD",
                  timeZone
                )
                .utc()
                .valueOf(),
            }
          : {}),

        is_hold_workflow: data?.is_hold_workflow
          ? data?.is_hold_workflow
          : false,
        is_send_confirmation_message: data?.is_send_confirmation_message
          ? data?.is_send_confirmation_message
          : false,
        ...(data?.is_send_confirmation_message
          ? {
              confirmation_message_type: getSelectValues(
                data.confirmation_message_type
              ),
              confirmation_message_template_id: getSelectValues(
                data.confirmation_message_template_id
              ),
            }
          : {}),
        is_send_action_follow_up: data?.is_send_action_follow_up
          ? data?.is_send_action_follow_up
          : false,
        ...(data?.is_send_action_follow_up
          ? {
              action_follow_up_type: getSelectValues(
                data?.action_follow_up_type
              ),
              action_follow_up_template_id: getSelectValues(
                data?.action_follow_up_template_id
              ),
            }
          : {}),
        comment: data?.comment,
      };
    }

    await putCardData.mutateAsync(values);
  };

  let cardData = [
    {
      id: 1,
      fieldVisitDate: "4/5/2023",
      time: "1:00",
      location: "Kumar - Apex Chamber 4th Floor Chennai",
      user: "05/6/2023",
      userName: "Raju",
    },
    {
      id: 2,
      fieldVisitDate: "4/5/2023",
      time: "2:00",
      location: "PK - Apex Chamber 4th Floor Chennai",
      user: "02/6/2023",
    },
    {
      id: 3,
      fieldVisitDate: "4/5/2023",
      time: "3:00",
      location: "PK - Apex Chamber 4th Floor Chennai",
      user: "03/6/2023",
    },
    {
      id: 4,
      fieldVisitDate: "6/5/2023",
      time: "4:00",
      location: "PK - Apex Chamber 4th Floor Chennai",
      user: "05/6/2023",
    },
    {
      id: 5,
      fieldVisitDate: "4/5/2023",
      time: "5:00",
      location: "PK - Apex Chamber 4th Floor Chennai",
      user: "05/6/2023",
    },
    {
      id: 6,
      fieldVisitDate: "4/5/2023",
      time: "6:00",
      location: "PK - Apex Chamber 4th Floor Chennai",
      user: "05/6/2023",
    },
    {
      id: 7,
      fieldVisitDate: "4/5/2023",
      time: "7:00",
      location: "PK - Apex Chamber 4th Floor Chennai",
      user: "05/6/2023",
    },
  ];

  const { customerDetails, customerConatactDetails, orgDtl } = useSelector(
    (state) => state.customerOverviewReducer
  );

  useEffect(() => {
    let EmailUser =
      customerConatactDetails &&
      Array.isArray(customerConatactDetails) &&
      customerConatactDetails.some((e) => e.is_valid_email === true);
    let PhoneUser =
      customerConatactDetails &&
      Array.isArray(customerConatactDetails) &&
      customerConatactDetails.some((e) => e.is_valid_phone === true);

    setValue("action_follow_up_template_id", []);
    setValue("action_follow_up_type", []);
    setValue("confirmation_message_type", []);
    setValue("confirmation_message_template_id", []);
    setValue("is_send_confirmation_message", false);
    setValue("is_send_action_follow_up", false);

    if (outCome === "RETURN_VISIT") {
      const paymentPlanConfig =
        orgDtl?.default_configuration?.return_field_visit?.messages;

      setValue(
        "is_hold_workflow",
        orgDtl?.default_configuration?.return_field_visit?.is_hold_workflow
      );

      paymentPlanConfig?.reverse()?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "PROMISE_TO_PAY") {
      const paymentPlanConfig =
        orgDtl?.default_configuration?.field_visit_promise_to_pay?.messages;

      setValue(
        "is_hold_workflow",
        orgDtl?.default_configuration?.field_visit_promise_to_pay
          ?.is_hold_workflow
      );

      paymentPlanConfig?.reverse()?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "CALL_BACK_LATER") {
      const paymentPlanConfig =
        orgDtl?.default_configuration?.field_visit_call_back_later?.messages;

      setValue(
        "is_hold_workflow",
        orgDtl?.default_configuration?.field_visit_call_back_later
          ?.is_hold_workflow
      );

      paymentPlanConfig?.reverse()?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "UNSUCCESSFULL") {
      const paymentPlanConfig =
        orgDtl?.default_configuration?.field_visit_promise_to_pay?.messages;

      setValue(
        "is_hold_workflow",
        orgDtl?.default_configuration?.field_visit_promise_to_pay
          ?.is_hold_workflow
      );

      paymentPlanConfig?.reverse()?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    } else if (outCome === "PAYMENT_PLAN") {
      const paymentPlanConfig =
        orgDtl?.default_configuration?.field_visit_payment_plan?.messages;

      setValue(
        "is_hold_workflow",
        orgDtl?.default_configuration?.field_visit_payment_plan
          ?.is_hold_workflow
      );

      paymentPlanConfig?.reverse()?.map((e) => {
        if (
          (e.type === "email" && EmailUser) ||
          (e.type === "sms" && PhoneUser) ||
          (e.type === "whatsapp" && PhoneUser)
        ) {
          if (e.confirmation_template.length > 0) {
            setValue("is_send_confirmation_message", true);
            setValue("confirmation_message_type", setSelectValues(e.type));
            setValue(
              "confirmation_message_template_id",
              setSelectValues(e.confirmation_template)
            );
          }
          if (e.follow_up_template.length > 0) {
            setValue("is_send_action_follow_up", true);
            setValue("action_follow_up_type", setSelectValues(e.type));
            setValue(
              "action_follow_up_template_id",
              setSelectValues(e.follow_up_template)
            );
          }
        }
      });
    }
  }, [outCome]);

  if (gettingFieldVisit && gettingFieldVisit.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <FieldVisitDrawer
        state={fieldVisitDrawer}
        setState={setFieldVisitDrawer}
        editData={fieldVisitEditData}
      />
      <form
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
        onSubmit={handleSubmit(onSubmit)}

        // onSubmit={}
      >
        <div style={{ padding: "15px 35px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {/* <Email /> */}
              <HeadingXLarge>Field Visit</HeadingXLarge>
            </div>
          </div>
        </div>

        {gettingFieldVisit &&
        !gettingFieldVisit.isLoading &&
        fieldVisitData &&
        fieldVisitData.length === 0 ? (
          <ParagraphLarge style={{ textAlign: "center" }}>
            There are no planned field visit
          </ParagraphLarge>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                height: "100%",
                overflow: "scroll",
              }}
            >
              {/* CARD DATA */}

              <div>
                <div
                // style={{
                //   width: "100%",
                //   overflow: "scroll",
                //   height: "100%",
                // }}
                >
                  {/* FIELD VISIT CARD STARTS */}

                  {[
                    ...(!capture
                      ? fieldVisitData
                        ? fieldVisitData
                        : []
                      : captureData),
                  ]
                    // fieldVisitData?
                    ?.map((e, i) => (
                      <>
                        <FieldVisitCard
                          data={e}
                          setCapture={setCapture}
                          setCaptureData={setCaptureData}
                          setFieldVisitDrawer={setFieldVisitDrawer}
                          fieldVisitDrawer={fieldVisitDrawer}
                          getParams={getParams}
                          setFieldVisitEditData={setFieldVisitEditData}
                        />
                      </>
                    ))}

                  {/* FIELD VISIT CARD ENDS */}
                </div>
              </div>
              {capture ? (
                <div style={{ paddingTop: "15px" }}>
                  <FieldVisitForm
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    setError={setError}
                    setCome={setCome}
                    outCome={outCome}
                    setFieldVisit={setFieldVisit}
                    clearErrors={clearErrors}
                    disabled={disabled}
                    setDisable={setDisable}
                    installments={installments}
                    setInstallments={setInstallments}
                    fieldVisitStatus={fieldVisitStatus}
                    setIsCompute={setIsCompute}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        )}

        <div
          style={{
            background: "#F9F9F9",
            width: "100%",
            height: "70px",
            padding: "15px",
            borderTop: "1px solid #CDCED9",
            // gridRow: "12/13",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div></div>

            {/* BUTTON */}
            <div
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                {capture ? (
                  <>
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => {
                          // dispatch(isOpenModal(false));
                          setCapture(false);
                        }}

                        // onClick={() => {
                        //   handleModal({
                        //     title: "Would you like to Cancel Field Visit",
                        //     content:
                        //       "on click Yes, The Field Visit status will be marked as Cancelled",
                        //     successCallback: () => {
                        //       dispatch(isOpenModal(false));
                        //       // fieldVisitDelete.mutateAsync({
                        //       //   fieldVisitId: data._id,
                        //       // });
                        //     },
                        //     image: Warning,
                        //     buttonText: "Yes",
                        //     closeButtonText: "Cancel",
                        //   });
                        // }}
                      >
                        Cancel
                      </TextButton>
                    </div>{" "}
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        type="submit"
                        // type="button"
                        // type={fieldVisitDrawer === true ? "button" : "submit"}
                        isLoading={putCardData.isLoading}
                        // disabled={isSubmitting}
                        disabled={
                          outCome == "PAYMENT_PLAN" &&
                          fieldVisitStatus === "SUCCESSFULL"
                            ? isCompute
                            : putCardData.isLoading || disabled
                        }
                      >
                        Send
                      </TextButton>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ width: "125px", height: "36px" }}>
                      <TextButton
                        size={SIZE.compact}
                        fullWidth
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => {
                          dispatch(isOpenModal(false));
                        }}
                      >
                        Back
                      </TextButton>
                    </div>{" "}
                  </>
                )}

                {/* <div style={{ width: "125px", height: "36px" }}>
                  <TextButton size={SIZE.compact} fullWidth type="button">
                    Send
                  </TextButton>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default FieldVisit;
