import React from "react";

export default function WhatsApp({
  size = 22,
  color = "#516BEB",
  stoke = "#FAFAFA",
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00175 2H8.99825C5.13863 2 2 5.1395 2 9C2 10.5312 2.4935 11.9505 3.33263 13.1029L2.46025 15.7034L5.15088 14.8433C6.25775 15.5765 7.57812 16 9.00175 16C12.8614 16 16 12.8596 16 9C16 5.14038 12.8614 2 9.00175 2Z"
        fill={color}
      />
      <path
        d="M13.0716 11.8844C12.9027 12.3613 12.2324 12.7568 11.6978 12.8723C11.3321 12.9502 10.8543 13.0123 9.24606 12.3455C7.18894 11.4933 5.86419 9.40292 5.76094 9.2673C5.66206 9.13167 4.92969 8.16042 4.92969 7.15592C4.92969 6.15142 5.43981 5.6623 5.64544 5.4523C5.81431 5.27992 6.09344 5.20117 6.36119 5.20117C6.44781 5.20117 6.52569 5.20555 6.59569 5.20905C6.80131 5.2178 6.90456 5.23005 7.04019 5.55467C7.20906 5.96155 7.62031 6.96605 7.66931 7.0693C7.71919 7.17255 7.76906 7.31255 7.69906 7.44817C7.63344 7.58817 7.57569 7.6503 7.47244 7.7693C7.36919 7.8883 7.27119 7.9793 7.16794 8.10705C7.07344 8.21817 6.96669 8.33717 7.08569 8.5428C7.20469 8.74405 7.61594 9.41517 8.22144 9.95417C9.00281 10.6498 9.63631 10.872 9.86294 10.9665C10.0318 11.0365 10.2331 11.0199 10.3564 10.8887C10.5131 10.7198 10.7064 10.4398 10.9033 10.1642C11.0433 9.96642 11.2201 9.94192 11.4056 10.0119C11.5946 10.0775 12.5947 10.5719 12.8003 10.6743C13.0059 10.7775 13.1416 10.8265 13.1914 10.9132C13.2404 10.9998 13.2404 11.4067 13.0716 11.8844Z"
        fill={stoke}
      />
    </svg>
  );
}
