import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { LabelLarge, LabelMedium, ParagraphMedium } from "baseui/typography";
import { TextBox } from "../../../../components/TextBox";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { SelectBox } from "../../../../components/SelectBox";
import { EMAIL, FIELD_VISIT, LETTER, SMS } from "../../../../constants";
import { TextArea } from "../../../../components/TextArea";
import DateRangeFilterThree from "../../../../components/TableFilters/DateRangeFilter/DateRangeFilter";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { Controller, useWatch } from "react-hook-form";
import { getCommunicationTemplateList } from "../../../../services";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getCustomerContactDetail } from "../../../../services";
import { useParams, useSearchParams } from "react-router-dom";
import { combineReducers } from "redux";
import Email from "../../../../assets/img/svg/CustomerOverViewIcon/AllActionPop.js/Email";
import { useNumericFormat } from "react-number-format";
import moment from "moment";
import DownArrow from "../../../../utils/DownArrow";
import { IconButton } from "../../../../components/IconButton";
import { Button, KIND, SIZE } from "baseui/button";
import PlusOne from "../../../../assets/img/svg/Plus";
import setSelectValues from "../../../../utils/setSelectValues";
const PromiseForm = (call) => {
  let {
    referenceData,
    currentOrganization,
    userInfo,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencySeparator,
    currencyGroupStyle,
  } = useContext(MaxyfiContext);
  let {
    actioWatch,
    setIsCancel,
    isCancel,
    callOutcome,
    control,
    errors,
    watch,
    settingData,
    setValue,
    formAction = "",
    defaultConfiguration,
  } = call;
  const { customerDetails, customerConatactDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );
  let intl = useIntl();

  let [confirmationTemplates, setConfirmationTemplates] = useState([]);
  let [followUpTemplates, setFollowUpTemplates] = useState([]);

  const invoiceCurrency = useSelector(
    (s) =>
      s &&
      s.customerOverviewReducer &&
      s.customerOverviewReducer.customerDetails &&
      s.customerOverviewReducer.customerDetails?.invoice_currencies
  );

  let currencyList = invoiceCurrency?.map((e) => {
    return { id: e, label: e };
  });

  let [is_send_confirmation_message, is_send_action_follow_up] = actioWatch;

  let promiseType = actioWatch[4];

  // Finding Email Value Length and Phone Code
  const { promise_type, is_enable_calendar_alert } = watch();

  let EmailUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_email === true);
  let PhoneUser =
    customerConatactDetails &&
    Array.isArray(customerConatactDetails) &&
    customerConatactDetails.some((e) => e.is_valid_phone === true);

  let emailValue = EmailUser === !EmailUser ? 0 : 1;

  let [confirmationMessageType, followUpType] = watch([
    "confirmation_message_type",
    "action_follow_up_type",
  ]);

  const getTemplates = async ({ tags = [], type, setter }) => {
    return await getCommunicationTemplateList({
      filters: {
        tags,
        type,
      },
      organization: currentOrganization,

      clientCode: [currentOrganization],
    })
      .then((res) => {
        if (res && res.data && res.data.docs) {
          let templates = res.data.docs.map((e) => ({
            id: e._id,
            label: `${e.name} (${
              e.language
                ? e.language.toLocaleUpperCase()
                : "en".toLocaleUpperCase()
            })`,
            disabled: e.disable,
          }));
          setter(templates);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      confirmationMessageType &&
      confirmationMessageType[0] &&
      confirmationMessageType[0].id
    ) {
      setConfirmationTemplates([]);
      getTemplates({
        tags: ["PROMISE"],
        type: confirmationMessageType[0].id,
        setter: setConfirmationTemplates,
      });
    }
  }, [confirmationMessageType]);

  useEffect(() => {
    if (followUpType && followUpType[0] && followUpType[0].id) {
      setFollowUpTemplates([]);
      getTemplates({
        tags: ["PROMISE"],
        type: followUpType[0].id,
        setter: setFollowUpTemplates,
      });
    }
  }, [followUpType]);

  let mappinTypeValue =
    referenceData && referenceData["template_type"]
      ? referenceData["template_type"]
          .filter(({ id }) => id != "letter")
          .map((e) => {
            if (e.id == "email" && !EmailUser) {
              return { ...e, disabled: true };
            } else if (e.id === "sms" && !PhoneUser) {
              return { ...e, disabled: true };
            } else if (
              e.id === "sms" &&
              !currentDefaultFormatDetails.is_sms_configured
            ) {
              return { ...e, disabled: true };
            } else if (
              e.id === "whatsapp" &&
              !currentDefaultFormatDetails?.is_whatsapp_configured
            ) {
              return { ...e, disabled: true };
            }
            return e;
          })
      : [];
  const [isCheck, setIsCheck] = useState(false);
  const handlercancle = () => {
    setIsCancel(!isCancel);
  };
  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });
  const PaymentPlanListItem = customerDetails?.promise_to_pay?.filter(
    (elem) => {
      if (
        elem.payment_plan_id !== undefined &&
        elem.payment_plan_id.length !== 0
      ) {
        return (
          elem.payment_plan_id ===
          customerDetails?.payment_plan?.payment_plan_id
        );
      }
    }
  );
  let PP = customerDetails?.promise_to_pay.find(
    (e) => e.status == "IN_PROGRESS"
  );

  // useEffect(() => {
  //   let EmailUser = customerConatactDetails;
  //   Array.isArray(customerConatactDetails) &&
  //     customerConatactDetails.some((e) => e.is_valid_email === true);
  //   let PhoneUser = customerConatactDetails;
  //   Array.isArray(customerConatactDetails) &&
  //     customerConatactDetails.some((e) => e.is_valid_phone === true);

  //   const paymentPlanConfig = defaultConfiguration?.messages;

  //   setValue("is_hold_workflow", defaultConfiguration?.is_hold_workflow);

  //   paymentPlanConfig.reverse().map((e) => {
  //     if (
  //       (e.type === "email" && EmailUser) ||
  //       (e.type === "sms" && PhoneUser) ||
  //       (e.type === "whatsapp" && PhoneUser)
  //     ) {
  //
  //       if (e.confirmation_template.length > 0) {
  //         setValue("is_send_confirmation_message", true);
  //         setValue("confirmation_message_type", setSelectValues(e.type));
  //         setValue(
  //           "confirmation_message_template_id",
  //           setSelectValues(e.confirmation_template)
  //         );
  //       }
  //       if (e.follow_up_template.length > 0) {
  //         setValue("is_send_action_follow_up", true);
  //         setValue("action_follow_up_type", setSelectValues(e.type));
  //         setValue(
  //           "action_follow_up_template_id",
  //           setSelectValues(e.follow_up_template)
  //         );
  //       }
  //     }
  //   });
  // }, [customerConatactDetails]);

  return (
    <>
      {isCancel && PP && PP.type == "PROMISE_TO_PAY" ? (
        <>
          <div style={{ textAlign: "end" }}>
            <LabelMedium $style={{ color: "#FD372A" }}>
              * The Consumer have below existing Promise To Pay
            </LabelMedium>
          </div>
          <div
            style={{
              background: "#F0F5FF",
              padding: "6px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="paymentplan_view-btn">
                <ParagraphMedium>
                  Promise to pay&nbsp;
                  {PP && PP?.promise_amount && PP?.promise_amount.currency}
                  &nbsp;
                  <FormattedNumber
                    value={PP && PP?.promise_amount && PP?.promise_amount.value}
                    style="currency"
                  />
                  &nbsp; by &nbsp;
                  {moment(PP && PP?.promise_date).format(
                    currentDefaultFormatDetails.date_format
                  )}
                </ParagraphMedium>
              </div>
              <div className="monthly_plan">
                <div className={isCheck ? "isuparrow" : "monthlyupdate"}>
                  <IconButton
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => {
                      setIsCheck(!isCheck);
                    }}
                  >
                    <DownArrow />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "end" }}>
            <IconButton
              size={SIZE.mini}
              kind={KIND.tertiary}
              type="button"
              onClick={handlercancle}
            >
              <PlusOne color={"#516BEB"} width={"12px"} />
              &nbsp;
              <LabelMedium $style={{ color: "#516BEB" }}>
                Cancel and Create new
              </LabelMedium>
            </IconButton>
          </div>
        </>
      ) : isCancel && PP && PP.type == "PAYMENT_PLAN" ? (
        <>
          <div style={{ textAlign: "end" }}>
            <LabelMedium $style={{ color: "#FD372A" }}>
              * The Consumer has below existing Payment Plan
            </LabelMedium>
          </div>
          <div
            style={{
              background: "#F0F5FF",
              padding: "6px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="paymentplan_view-btn">
                <ParagraphMedium>
                  {customerDetails?.payment_plan?.frequency} Payment plan with
                  installment amount of{" "}
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_amount &&
                  PaymentPlanListItem?.[0]?.promise_amount?.currency
                    ? PaymentPlanListItem?.[0]?.promise_amount?.currency
                    : "-"}{" "}
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_amount &&
                  PaymentPlanListItem?.[0]?.promise_amount.value
                    ? numberFormat.format(
                        PaymentPlanListItem &&
                          PaymentPlanListItem?.[0] &&
                          PaymentPlanListItem?.[0]?.promise_amount &&
                          PaymentPlanListItem?.[0]?.promise_amount.value
                          ? PaymentPlanListItem?.[0]?.promise_amount.value.toFixed(
                              2
                            )
                          : 0
                      )
                    : 0}
                  &nbsp;starting&nbsp;
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_date
                    ? moment(PaymentPlanListItem?.[0]?.promise_date).format(
                        currentDefaultFormatDetails.date_format
                      )
                    : "-"}
                  &nbsp; from till{" "}
                  {PaymentPlanListItem &&
                  PaymentPlanListItem?.[0] &&
                  PaymentPlanListItem?.[0]?.promise_date
                    ? moment(
                        PaymentPlanListItem?.[PaymentPlanListItem?.length - 1]
                          ?.promise_date
                      ).format(currentDefaultFormatDetails.date_format)
                    : "-"}
                </ParagraphMedium>
              </div>
              <div className="monthly_plan">
                <div className={isCheck ? "isuparrow" : "monthlyupdate"}>
                  <IconButton
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    type="button"
                    onClick={() => {
                      setIsCheck(!isCheck);
                    }}
                  >
                    <DownArrow />
                  </IconButton>
                </div>
              </div>
            </div>
            {isCheck ? (
              <div
                style={{
                  maxHeight: "200px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    maxHeight: "200px",
                    overflow: "scroll",
                  }}
                >
                  {PaymentPlanListItem.map((data, i) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        <LabelMedium>{i + 1}.</LabelMedium>
                        <LabelMedium>{invoiceCurrency[0]}</LabelMedium>
                        <LabelMedium>
                          {numberFormat.format(
                            `${data?.promise_amount?.value}`
                          )}
                        </LabelMedium>
                        <ParagraphMedium>
                          on{" "}
                          {/* {moment(data?.promise_date).format(
                        currentDefaultFormatDetails.date_format
                      )} */}
                        </ParagraphMedium>
                        <LabelMedium>
                          {moment(data?.promise_date).format(
                            currentDefaultFormatDetails.date_format
                          )}
                        </LabelMedium>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ textAlign: "end" }}>
            <IconButton
              size={SIZE.mini}
              kind={KIND.tertiary}
              type="button"
              onClick={handlercancle}
            >
              <PlusOne color={"#516BEB"} width={"12px"} />
              &nbsp;
              <LabelMedium $style={{ color: "#516BEB" }}>
                Cancel and Create new
              </LabelMedium>
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <div className="cp-action-promise-amount_input">
            <div className="action-promise-amount--input">
              <Controller
                name="promise_type"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    clearable={false}
                    size={SIZE.mini}
                    label={intl.formatMessage({
                      id: "promise_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "promise_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["promise_type"] || []}
                  />
                )}
              />
            </div>
            {promise_type &&
              promise_type[0] &&
              promise_type[0].id !== "no_amount" && (
                <>
                  <div className="action-promise-amount--input">
                    <Controller
                      name="promise_amount.currency"
                      control={control}
                      // defaultValue={[
                      //   {
                      //     id: customerDetails?.invoice_currencies?.[0],
                      //     label: customerDetails?.invoice_currencies?.[0],
                      //   },
                      // ]}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          name={field.name}
                          error={
                            errors?.promise_amount?.currency?.message
                            // call.errors[field.name] && call.errors[field.name].message
                          }
                          clearable={false}
                          size={SIZE.mini}
                          label={intl.formatMessage({
                            id: "cur",
                          })}
                          placeholder={intl.formatMessage({
                            id: "cur",
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={currencyList || []}
                          requiredAstric={true}
                        />
                      )}
                    />
                  </div>
                  <div className="action-promise-amount--input">
                    <Controller
                      name="promise_amount.value"
                      control={control}
                      rules={{ required: "Required" }}
                      render={({ field }) => (
                        <TextBox
                          {...field}
                          hidden={true}
                          type="number"
                          name={field.name}
                          // onChange={() => {
                          //   setError(
                          //     "promise_amount",
                          //     { type: "focus" },
                          //     { shouldFocus: true }
                          //   );
                          // }}
                          size={SIZE.mini}
                          requiredAstric={true}
                          error={
                            call.errors.promise_amount &&
                            call.errors.promise_amount.value &&
                            call.errors.promise_amount.value.message
                          }
                          label={intl.formatMessage({
                            id: "promise_amount",
                          })}
                          placeholder={
                            promiseType &&
                            promiseType[0] &&
                            promiseType[0].id === "full_amount"
                              ? ""
                              : intl.formatMessage({
                                  id: "promise_amount",
                                })
                          }
                          value={field.value}
                          disabled={
                            promiseType &&
                            promiseType[0] &&
                            promiseType[0].id === "full_amount"
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </div>
                </>
              )}

            <div className="action-promise-amount--input">
              <Controller
                name="promise_date"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <DateRangeSelect
                    {...field}
                    name={field.name}
                    size={SIZE.mini}
                    error={
                      call.errors[field.name] && call.errors[field.name].message
                    }
                    requiredAstric={true}
                    label={intl.formatMessage({
                      id: "promise_date",
                    })}
                    // placeholder={intl.formatMessage({
                    //   id: `${currentDefaultFormatDetails.date_format}`,
                    // })}
                    value={field.value}
                    onChange={(e) => field.onChange(e.date)}
                    minDate={new Date()}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Controller
              defaultValues={false}
              name="is_hold_workflow"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="hold_workflow_till_promise_date">
                    hold_workflow_till_promise_date
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
          </div>

          <div className="cp-action-message-confirmation">
            <div className="cp-message-confirmation-checkbox">
              <Controller
                defaultValues={false}
                name="is_enable_calendar_alert"
                control={control}
                render={({ field }) => (
                  <CheckBoxBaseweb
                    {...field}
                    checked={field.value}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    <FormattedMessage id="enable_calendar_alert">
                      Enable Calendar Alert
                    </FormattedMessage>
                  </CheckBoxBaseweb>
                )}
              />
            </div>
            {is_enable_calendar_alert ? (
              <>
                <div
                  className="cp-message-confirmation-type"
                  style={{ width: "120px" }}
                >
                  <Controller
                    defaultValues={[]}
                    name="calendar_alert_time"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size={SIZE.compact}
                        fullWidth
                        error={
                          call.errors[field.name] &&
                          call.errors[field.name].message
                        }
                        label={intl.formatMessage({
                          id: "time",
                        })}
                        placeholder={intl.formatMessage({
                          id: "time",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          // if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                          //   setValue("confirmation_message_template_id", []);
                          // }
                          field.onChange(e.value);
                        }}
                        options={referenceData["time"]}
                      />
                    )}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div className="cp-message-confirmation-checkbox">
            <Controller
              defaultValues={false}
              name="is_send_confirmation_message"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                  // disabled={!EmailUser && !PhoneUser}
                >
                  <FormattedMessage id="send_confirmation_message">
                    send_confirmation_message
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
          </div>

          {is_send_confirmation_message ? (
            <div className="cp-action-message-confirmation">
              <>
                <div className="cp-message-confirmation-type">
                  <Controller
                    defaultValues={[]}
                    name="confirmation_message_type"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size={SIZE.mini}
                        fullWidth
                        error={
                          call.errors[field.name] &&
                          call.errors[field.name].message
                        }
                        label={intl.formatMessage({
                          id: "comm_type",
                        })}
                        placeholder={intl.formatMessage({
                          id: "comm_type",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                            setValue("confirmation_message_template_id", []);
                          }
                          field.onChange(e.value);
                        }}
                        // options={
                        //   referenceData["template_type"].filter(
                        //     (e) => e.id !== LETTER
                        //   ) || []
                        // }
                        options={mappinTypeValue}
                      />
                    )}
                  />
                </div>
                {/* TODO: TEMPLATE TYPE */}
                <div className="cp-action-promise-pay-con">
                  <Controller
                    name="confirmation_message_template_id"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        size={SIZE.mini}
                        fullWidth
                        error={
                          call.errors[field.name] &&
                          call.errors[field.name].message
                        }
                        label={intl.formatMessage({
                          id: "promise_to_pay_confirmation",
                        })}
                        placeholder={intl.formatMessage({
                          id: "promise_to_pay_confirmation",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={confirmationTemplates || []}
                      />
                    )}
                  />
                </div>
              </>
            </div>
          ) : (
            ""
          )}

          <div className="cp-message-confirmation-checkbox">
            <Controller
              defaultValues={false}
              name="is_send_action_follow_up"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                  // disabled={!EmailUser === true && !PhoneUser === true}
                >
                  <FormattedMessage id="send_promise_follow_up_message">
                    send_promise_follow_up_message
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
          </div>

          {is_send_action_follow_up ? (
            <div className="cp-action-message-confirmation">
              <>
                <div className="cp-message-confirmation-type">
                  <Controller
                    name="action_follow_up_type"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        fullWidth
                        size={SIZE.mini}
                        error={
                          call.errors[field.name] &&
                          call.errors[field.name].message
                        }
                        label={intl.formatMessage({
                          id: "comm_type",
                        })}
                        placeholder={intl.formatMessage({
                          id: "comm_type",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          if (field?.value?.[0]?.id != e?.value?.[0]?.id) {
                            setValue("action_follow_up_template_id", []);
                          }
                          field.onChange(e.value);
                        }}
                        // options={
                        //   referenceData["template_type"].filter(
                        //     (e) => e.id !== LETTER
                        //   ) || []
                        // }
                        options={mappinTypeValue}
                      />
                    )}
                  />
                </div>
                <div className="cp-action-promise-pay-con">
                  <Controller
                    defaultValues={[]}
                    name="action_follow_up_template_id"
                    control={control}
                    rules={{ required: "Required" }}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        clearable={false}
                        name={field.name}
                        fullWidth
                        size={SIZE.mini}
                        error={
                          call.errors[field.name] &&
                          call.errors[field.name].message
                        }
                        label={intl.formatMessage({
                          id: "promise_to_follow_up_message",
                        })}
                        placeholder={intl.formatMessage({
                          id: "promise_to_follow_up_message",
                        })}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        options={followUpTemplates || []}
                      />
                    )}
                  />
                </div>
              </>
            </div>
          ) : (
            ""
          )}

          <div className="cp-action-promise_test-area">
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <TextArea
                  {...field}
                  clearable={false}
                  name={field.name}
                  size={SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "user_comments",
                  })}
                  placeholder={intl.formatMessage({
                    id: "user_comments",
                  })}
                  value={field.value}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PromiseForm;
