import { useMemo } from "react";
import { useQuery } from "react-query";
import _ from "lodash";

import { CUSTOMER_OVERVIEW_ } from "../constants";
import { getCustomerDetails } from "../services";

export const useCustomerQuery = ({
  customerId,
  organizationId,
  options = {},
}) => {
  const queryInfo = useQuery(
    [`${CUSTOMER_OVERVIEW_}${customerId}`],
    async () => {
      return await getCustomerDetails({
        organization: organizationId,
        customerId,
      });
    },
    {
      ...options,
    }
  );

  return {
    ...queryInfo,
    data: useMemo(
      () => _.get(queryInfo, "data.data.doc", null),
      [queryInfo.data]
    ),
  };
};
