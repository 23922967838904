import { useContext } from "react";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { Icon, Typography } from "../../../components_v2";
import { FormattedNumber } from "react-intl";
import moment from "moment";
import { KIND, TextButton } from "../../../components/TextButton";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import useFormat from "../../../hooks/useFormat";
import _ from "lodash";
import { useMutation } from "react-query";
import queryClient from "../../../providers/queryClient";
import { deleteCallback } from "../../../services";
import { useParams } from "react-router-dom";
const CallBackStatusTypes = ({
  textType,
  setCallBackStatus,
  alertCallBack,
  monthCallBackData,
}) => {
  const { currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);
  const { id, customerId } = useParams();
  const getParams = customerId;
  const format = useFormat();
  const deleteCallBackList = useMutation(
    () =>
      deleteCallback({
        organization: currentOrganization,
        customerId: getParams,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(
          `GET_CALL_BACK_LIST_${currentOrganization}_${customerId}`
        );
      },
    }
  );
  let timeSolt = _.get(monthCallBackData, "callback_time", null);
  let slicedVal = timeSolt?.replace("_", ":");
  let slicedFinalVal = slicedVal?.replace("_", " ");
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          paddingTop: "25px",
        }}
      >
        <Typography className="co_action_exist">{alertCallBack}</Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#F0F5FF",
            padding: "6px 10px",
            borderRadius: "5px",
          }}
        >
          <div className="paymentplan_view-btn">
            <Typography>{textType}</Typography>
          </div>
          <div className="monthly_plan">
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography
                type="p"
                className="text-primary"
                onClick={() => {
                  setCallBackStatus(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Reschedule
              </Typography>
              <Icon icon="dot" size={6} color="#ADADAD" />

              <StatefulPopover
                placement={PLACEMENT.leftTop}
                triggerType={TRIGGER_TYPE.click}
                content={({ close }) => (
                  <div className="co_groping_delete_callback_popover">
                    <div className="co_groping_delete_call_back_popover_body">
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Icon icon="alert" color="#FD372A" />
                        <Typography type="h4">Are You Sure?</Typography>
                      </div>

                      <Typography
                        type="p"
                        className="text-secondary"
                        subtype="regular"
                        style={{ textAlign: "center" }}
                      >
                        This action will remove the callback scheduled for{" "}
                        {format.dateTime({
                          value: _.get(
                            monthCallBackData,
                            "callback_date",
                            null
                          ),
                          month: true,
                          includeTime: true,
                        })}{" "}
                        {slicedFinalVal ? "at" : ""}{" "}
                        {slicedFinalVal ? slicedFinalVal : ""}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px ",
                      }}
                    >
                      <TextButton
                        size="mini"
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => close()}
                      >
                        <Typography type="h5" style={{ color: "#FD372A" }}>
                          Cancel
                        </Typography>
                      </TextButton>
                      <TextButton
                        size="mini"
                        kind={KIND.secondary}
                        onClick={async () => {
                          // let deletedCardId = item?._id;

                          deleteCallBackList.mutateAsync();

                          // await handleDeleteCard({ deletedCardId });
                          //   handleRemoveDefandant();
                          close();
                        }}
                        type="error"
                      >
                        Confirm
                      </TextButton>
                    </div>
                  </div>
                )}
              >
                <div style={{ cursor: "pointer" }}>
                  <Typography type="p" className="text-error">
                    Delete
                  </Typography>
                </div>
              </StatefulPopover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallBackStatusTypes;
