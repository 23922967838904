import React from "react";

const Tick = ({ size = "22", color = "#ffffff" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66193 14.3917L16.9807 6.07292C17.1192 5.93542 17.2837 5.86667 17.4741 5.86667C17.6646 5.86667 17.8286 5.93582 17.9661 6.07413C18.1036 6.21243 18.1723 6.37666 18.1723 6.56684C18.1723 6.757 18.1036 6.92084 17.9661 7.05834L9.14318 15.8813C9.00568 16.0188 8.84526 16.0875 8.66193 16.0875C8.4786 16.0875 8.31818 16.0188 8.18068 15.8813L4.00985 11.7104C3.87235 11.5719 3.80742 11.4074 3.81506 11.217C3.8227 11.0265 3.89567 10.8625 4.03398 10.725C4.17227 10.5875 4.33651 10.5188 4.52669 10.5188C4.71685 10.5188 4.88068 10.5875 5.01818 10.725L8.66193 14.3917Z"
        fill={color}
      />
    </svg>
  );
};

export default Tick;
