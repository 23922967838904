import React from "react";
import { H5 } from "baseui/typography";
import { FormattedMessage } from "react-intl";

function PageTitle({ id }) {
  return (
    <H5 $style={{ fontSize: "22px", fontWeight: "bold" }}>
      <FormattedMessage id={id}>{id}</FormattedMessage>
    </H5>
  );
}

export default PageTitle;
