import React from "react";

const Seen = ({ color = "#ADADAD" }) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 1.69231C10.0841 1.69231 12.3886 3.49462 13.5136 6.34615C12.3886 9.19769 10.0841 11 7.5 11C4.91591 11 2.61136 9.19769 1.48636 6.34615C2.61136 3.49462 4.91591 1.69231 7.5 1.69231ZM7.5 0C4.09091 0 1.17955 2.63154 0 6.34615C1.17955 10.0608 4.09091 12.6923 7.5 12.6923C10.9091 12.6923 13.8205 10.0608 15 6.34615C13.8205 2.63154 10.9091 0 7.5 0ZM7.5 4.23077C8.44091 4.23077 9.20455 5.17846 9.20455 6.34615C9.20455 7.51385 8.44091 8.46154 7.5 8.46154C6.55909 8.46154 5.79545 7.51385 5.79545 6.34615C5.79545 5.17846 6.55909 4.23077 7.5 4.23077ZM7.5 2.53846C5.80909 2.53846 4.43182 4.24769 4.43182 6.34615C4.43182 8.44462 5.80909 10.1538 7.5 10.1538C9.19091 10.1538 10.5682 8.44462 10.5682 6.34615C10.5682 4.24769 9.19091 2.53846 7.5 2.53846Z"
        fill={color}
      />
    </svg>
  );
};

export default Seen;
