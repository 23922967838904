import React from "react";

function EscalationUser(props) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 8.30013C9.35917 8.30013 11 6.6593 11 4.63346C11 2.60763 9.35917 0.966797 7.33333 0.966797C5.3075 0.966797 3.66667 2.60763 3.66667 4.63346C3.66667 6.6593 5.3075 8.30013 7.33333 8.30013ZM7.33333 10.1335C4.88583 10.1335 0 11.3618 0 13.8001V15.6335H14.6667V13.8001C14.6667 11.3618 9.78083 10.1335 7.33333 10.1335Z"
        fill="red"
      />
    </svg>
  );
}

export default EscalationUser;
