import { useContext } from "react";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { HeadingXLarge, ParagraphLarge } from "baseui/typography";
import { TextButton, KIND, SIZE } from "../../../components/TextButton";
import { useDispatch, useSelector } from "react-redux";
import { isOpenModal } from "../../../redux/actions";
import TitleHead from "../../../components_v2/TitleHead";
import { getLetters } from "../../../services";
import LetterStatusCard_V2 from "./LetterStatusCard_V2";
import { useParams } from "react-router-dom";
import { LETTER_STATUS_ } from "../../../constants";
import _ from "lodash";

const LetterStatus = () => {
  let dispatch = useDispatch();
  const { customerId } = useParams();
  const { currentOrganization } = useContext(MaxyfiContext);
  const { refetchLetterStatusActions } = useSelector(
    (s) => s.letterStatusReducer
  );

  const { data, refetch } = useQuery(
    `${LETTER_STATUS_}${customerId}`,
    async () => {
      return await getLetters({
        organization: currentOrganization,
        customerId: customerId,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "600px",
      }}
    >
      <TitleHead title="Letter Status" icon="letter_status" />
      <div>
        {_.get(data, "data.docs", []).length === 0 ? (
          <ParagraphLarge style={{ display: "flex", justifyContent: "center" }}>
            No Records
          </ParagraphLarge>
        ) : (
          <>
            {_.get(data, "data.docs", []).map((e) => {
              return <LetterStatusCard_V2 data={e} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default LetterStatus;
