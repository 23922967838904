import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { updateFormatData } from "../../../redux/adhocUploadData/action";
import setSelectValues from "../../../utils/setSelectValues";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { CellRefSelectBox } from "../../CellRefSelectBox";
import _ from "lodash";
import { updateFormatData } from "../../../redux/CustomerAndInvoiceAdhoc/action";
import { updateFormatDataCustomerConatct } from "../../../redux/CustomerContactUpload/action";

const ContactFormatField = (props) => {
  const { referenceData } = useContext(MaxyfiContext);
  const { systemFields } = useSelector((e) => e.customerContactUploadReducer);
  const systemFieldConfig = systemFields.find((systemField) => {
    return systemField.id == props.row.original.system_field;
  });
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;

  value = _.get(row.original, accessor, "");

  const dispatch = useDispatch();
  return systemFieldConfig?.format?.reference ? (
    <CellRefSelectBox
      clearable={false}
      placeholder="Select"
      value={setSelectValues(value)}
      onChange={(params) => {
        dispatch(
          updateFormatDataCustomerConatct({
            index: props.row.index,
            formatData: params.value[0].id,
          })
        );
      }}
      //   options={uploadFieldOptions}
      options={referenceData[systemFieldConfig.format.reference]}
    />
  ) : (
    "-"
  );
};

export default ContactFormatField;
