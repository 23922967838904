import React, { useContext, useEffect, useMemo, useState } from "react";
import { SIZE, TextBox } from "../../../components/TextBox";
import { Controller, useForm } from "react-hook-form";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { useIntl } from "react-intl";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { Typography } from "../../../components_v2";
import { TextArea } from "../../../components/TextArea";
import { KIND, TextButton } from "../../../components/TextButton";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import ItemizationCustomField from "../CustomFields/ItemizationCustomField";
import moment from "moment";
import getSelectValues from "../../../utils/getSelectValues";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  postItemizationCommmissionData,
  postItemizationData,
  putItemizationData,
} from "../../../services";
import { useDebouncedCallback } from "use-debounce";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { CO_INVOICES_ } from "../../../constants";

const ItemizationForm = ({ closeDrawer, customerData }) => {
  const intl = useIntl();
  const { customerId } = useParams();
  const queryClient = useQueryClient();
  const [isSubmit, setSubmit] = useState(false);
  const {
    currentDefaultFormatDetails,
    referenceData,
    currentOrganization,
    customInvoice,
  } = useContext(MaxyfiContext);

  const commissionType = useMemo(() => {
    let type;

    if (_.get(customerData, "data.actionType") === "EDIT") {
      type =
        referenceData &&
        referenceData["business_unit_list"].find(
          (e) => e.id === _.get(customerData, "data.business_unit", "")
        );
    } else {
      type =
        referenceData &&
        referenceData["business_unit_list"].find(
          (e) => e.id === _.get(customerData, "data.data.doc.business_unit", "")
        );
    }
    return type;
  }, [customerData]);

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(_.get(customerData, "data.actionType") === "EDIT"
        ? {
            ...(_.get(customerData, "data.invoice_number")
              ? {
                  invoice_number: _.get(
                    customerData,
                    "data.invoice_number",
                    ""
                  ),
                }
              : {}),
            ...(_.get(customerData, "data.client_reference")
              ? {
                  client_reference: _.get(
                    customerData,
                    "data.client_reference"
                  ),
                }
              : {}),
            ...(_.get(customerData, "data.itmzn_date")
              ? { itmzn_date: new Date(_.get(customerData, "data.itmzn_date")) }
              : {}),
            ...(_.get(customerData, "data.invoice_date")
              ? {
                  invoice_date: new Date(
                    _.get(customerData, "data.invoice_date")
                  ),
                }
              : {}),
            ...(_.get(customerData, "data.invoice_due_date")
              ? {
                  invoice_due_date: new Date(
                    _.get(customerData, "data.invoice_due_date")
                  ),
                }
              : {}),
            ...(_.get(customerData, "data.invoice_description")
              ? {
                  invoice_description: _.get(
                    customerData,
                    "data.invoice_description"
                  ),
                }
              : {}),
            ...(_.get(customerData, "data.is_itmzn")
              ? {
                  is_itmzn: _.get(customerData, "data.is_itmzn"),
                }
              : {}),
            ...(_.get(customerData, "data.pp_amt")
              ? {
                  pp_amt: _.get(customerData, "data.pp_amt"),
                }
              : {}),
            ...(_.get(customerData, "data.pa_amt")
              ? {
                  pa_amt: _.get(customerData, "data.pa_amt"),
                }
              : {}),
            ...(_.get(customerData, "data.invoice_total_amount.value")
              ? {
                  inv_amnt: _.get(
                    customerData,
                    "data.invoice_total_amount.value"
                  ),
                }
              : {}),
            ...(_.get(customerData, "data.custom_field")
              ? { capture_add_details: true }
              : {}),

            ...(_.get(customerData, "data.is_itmzn_err", false)
              ? { is_rectify_error: true }
              : {}),

            ...(_.get(customerData, "data.orgiamt") ||
            _.get(customerData, "data.cc_amt") ||
            _.get(customerData, "data.ci_amt")
              ? {
                  without_com: {
                    orgiamt: _.get(customerData, "data.orgiamt"),
                    cc_amt: _.get(customerData, "data.cc_amt"),
                    ci_amt: _.get(customerData, "data.ci_amt"),
                  },
                  with_com: {
                    ...(_.get(customerData, "data.com.p")
                      ? {
                          orgiamt: (
                            _.get(customerData, "data.orgiamt") +
                            (_.get(customerData, "data.orgiamt") *
                              _.get(customerData, "data.com.p")) /
                              100
                          )?.toFixed(2),
                        }
                      : { orgiamt: _.get(customerData, "data.orgiamt") }),
                    ...(_.get(customerData, "data.com.cc")
                      ? {
                          cc_amt: (
                            _.get(customerData, "data.cc_amt") +
                            (_.get(customerData, "data.cc_amt") *
                              _.get(customerData, "data.com.cc")) /
                              100
                          )?.toFixed(2),
                        }
                      : { cc_amt: _.get(customerData, "data.cc_amt") }),
                    ...(_.get(customerData, "data.com.ci")
                      ? {
                          ci_amt: (
                            _.get(customerData, "data.ci_amt") +
                            (_.get(customerData, "data.ci_amt") *
                              _.get(customerData, "data.com.ci")) /
                              100
                          )?.toFixed(2),
                        }
                      : { ci_amt: _.get(customerData, "data.ci_amt") }),
                  },
                }
              : {}),
          }
        : {
            is_itmzn: true,
          }),
    },
  });

  const { is_itmzn, capture_add_details } = watch();

  let commissionTypeS = {
    WITH_COMMISSION: "with_com",
    WITHOUT_COMMISSION: "without_com",
  };

  let typeKey = {
    PRINCIPLE: "orgiamt",
    CHARGE: "cc_amt",
    INTREST: "ci_amt",
  };

  let [
    withoutPrincipalAmout,
    withoutClientCharges,
    withoutClientInterest,
    withPrincipalAmount,
    withClientCharges,
    withClientInterest,
  ] = watch([
    "without_com.orgiamt",
    "without_com.cc_amt",
    "without_com.ci_amt",
    "with_com.orgiamt",
    "with_com.cc_amt",
    "with_com.ci_amt",
  ]);

  const collectionFeeAmount = useMemo(() => {
    let totalAmount =
      withPrincipalAmount -
        withoutPrincipalAmout +
        (withClientCharges - withoutClientCharges) +
        (withClientInterest - withoutClientInterest) || 0;
    return totalAmount;
  }, [
    withPrincipalAmount,
    withoutPrincipalAmout,
    withoutClientCharges,
    withClientCharges,
    withClientInterest,
    withoutClientInterest,
  ]);

  useEffect(() => {
    if (collectionFeeAmount) {
      setValue("comamt", collectionFeeAmount?.toFixed(2));
    }
  }, [collectionFeeAmount]);

  const debounceFilter = useDebouncedCallback((value) => {
    checkAmountData.mutateAsync({ value });
  }, 1000);

  const checkAmountData = useMutation(
    (value) =>
      postItemizationCommmissionData({
        ...value,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (data && data.data && data.data.doc) {
          setValue(
            `${commissionTypeS[variables.value.to]}.${
              typeKey[variables.value.type]
            }`,
            data?.data?.doc?.toFixed(2)
          );
        }
      },
    }
  );

  const editItemizationData = useMutation(
    (values) =>
      putItemizationData({
        ...values,
        organization: currentOrganization,
        invoiceId: customerData.data.invoiceId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${CO_INVOICES_}${customerId}`);
        closeDrawer();
      },
    }
  );

  const addItemizationData = useMutation(
    (values) =>
      postItemizationData({
        ...values,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(`${CO_INVOICES_}${customerId}`);
        closeDrawer();
      },
    }
  );

  const onSubmit = async (data) => {
    const orgRef =
      referenceData &&
      referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
    const timeZone = orgRef?.time_zone;

    let value = {};
    if (capture_add_details) {
      for (let i = 0; i < customInvoice.length; i++) {
        let path = customInvoice?.[i]?.path;
        let dataType = customInvoice?.[i]?.data_type;
        let isVisible = customInvoice?.[i]?.is_hidden;
        let keys = path.split(".");
        let current = data;

        for (let j = 0; j < keys.length; j++) {
          if (current.hasOwnProperty(keys[j])) {
            current = current[keys[j]];
          }
        }
        if (!isVisible) {
          if (dataType === "DATE") {
            if (current) {
              value[path] = moment
                .tz(
                  `${current?.getFullYear()}-${
                    current?.getMonth() + 1
                  }-${current?.getDate()}`,
                  "YYYY-MM-DD",
                  currentDefaultFormatDetails.time_zone
                )
                .utc()
                .valueOf();
            }
          } else if (dataType === "AMOUNT") {
            if (current.value) {
              value[path] = current;
            }
          } else if (
            dataType === "TEXT" &&
            customInvoice?.[i]?.options?.length > 0
          ) {
            value[path] = getSelectValues(current);
          } else if (dataType === "NUMBER") {
            if (current && current.toString().length > 0) {
              value[path] = current;
            }
          } else {
            value[path] = current;
          }
        }
      }
    }

    let values = {
      customer_id: customerId,
      ...(data && data.invoice_number
        ? { invoice_number: data.invoice_number }
        : {}),
      ...(data && data.client_reference
        ? { client_reference: data.client_reference }
        : {}),
      ...(data && data.itmzn_date
        ? {
            itmzn_date: moment
              .tz(
                `${data.itmzn_date.getFullYear()}-${
                  data.itmzn_date.getMonth() + 1
                }-${data.itmzn_date.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data && data.invoice_date
        ? {
            invoice_date: moment
              .tz(
                `${data.invoice_date.getFullYear()}-${
                  data.invoice_date.getMonth() + 1
                }-${data.invoice_date.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data && data.invoice_due_date
        ? {
            invoice_due_date: moment
              .tz(
                `${data.invoice_due_date.getFullYear()}-${
                  data.invoice_due_date.getMonth() + 1
                }-${data.invoice_due_date.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      ...(data && data.invoice_description
        ? { invoice_description: data.invoice_description }
        : {}),
      is_itmzn: data.is_itmzn,
      ...(is_itmzn
        ? {
            data: {
              ...(!commissionType && commissionType?.commission_method === "DC"
                ? {
                    ...(data && data.with_com
                      ? { with_com: data.with_com }
                      : {}),
                  }
                : {}),

              ...(data && data.without_com
                ? { without_com: data.without_com }
                : {}),
              ...(!commissionType && commissionType?.commission_method === "DC"
                ? { ...(data && data.comamt ? { comamt: data.comamt } : {}) }
                : {}),

              ...(data && data.pp_amt ? { pp_amt: data.pp_amt } : {}),
              ...(data && data.pa_amt ? { pa_amt: data.pa_amt } : {}),
              ...(data && data.is_rectify_error
                ? { is_rectify_error: data.is_rectify_error }
                : {}),
            },
          }
        : {
            data: {
              ...(data && data.inv_amnt
                ? { invoice_amount: data.inv_amnt }
                : {}),
            },
          }),
      ...(value && capture_add_details ? { custom_field: { ...value } } : {}),
    };

    if (_.get(customerData, "data.actionType", "") === "EDIT") {
      await editItemizationData.mutateAsync({ values });
    } else {
      await addItemizationData.mutateAsync({ values });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="itemization-form-container">
        <Controller
          name="invoice_number"
          control={control}
          render={({ field }) => (
            <TextBox
              {...field}
              clearable={false}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              name={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              placeholder={intl.formatMessage({
                id: "our_reference",
              })}
              label={intl.formatMessage({
                id: "our_reference",
              })}
            />
          )}
        />
        <Controller
          name="client_reference"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              clearable={false}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              name={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              placeholder={intl.formatMessage({
                id: "client_reference",
              })}
              label={intl.formatMessage({
                id: "client_reference",
              })}
            />
          )}
        />
      </div>

      <div className="itemization-form-section">
        <Controller
          control={control}
          name="invoice_date"
          rules={{ required: "Required" }}
          render={({ field }) => (
            <DateRangeSelect
              size={SIZE.mini}
              {...field}
              requiredAstric={true}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "inv_date",
              })}
              placeholder={intl.formatMessage({
                id: " ",
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
            />
          )}
        />

        <Controller
          control={control}
          name="invoice_due_date"
          // rules={{ required: "Required" }}
          render={({ field }) => (
            // <div className="itemization-form-width">
            <DateRangeSelect
              size={SIZE.mini}
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "due_date",
              })}
              placeholder={intl.formatMessage({
                id: " ",
              })}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
            />
            // </div>
          )}
        />
      </div>
      <div className="itemization-form-section">
        <Controller
          control={control}
          name="itmzn_date"
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <div className="itemization-form-width">
              <DateRangeSelect
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "itemization_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.date);
                  if (!(_.get(customerData, "data.actionType") === "EDIT")) {
                    setValue("invoice_date", e.date);
                    setValue("invoice_due_date", e.date);
                  }
                }}
              />
            </div>
          )}
        />
      </div>

      <Controller
        name="invoice_description"
        control={control}
        render={({ field }) => (
          <TextArea
            {...field}
            clearable={false}
            size={SIZE.mini}
            name={field.name}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "des",
            })}
            label={intl.formatMessage({
              id: "des",
            })}
          />
        )}
      />

      <Controller
        name="is_itmzn"
        control={control}
        defaultValue={false}
        render={({ field }) => (
          <CheckBoxBaseweb {...field} checked={field.value}>
            <Typography type="p" className="itemization-check-box">
              Capture Itemization Details
            </Typography>
          </CheckBoxBaseweb>
        )}
      />

      {commissionType &&
      commissionType?.commission_method === "DC" &&
      is_itmzn ? (
        <>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <div className="commission-type-container">
              <Controller
                name={"without_com.orgiamt"}
                control={control}
                render={({ field }) => (
                  <div className="itemization-form-width">
                    <TextBox
                      {...field}
                      clearable={false}
                      size={SIZE.mini}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "principle_amt_woc",
                      })}
                      label={intl.formatMessage({
                        id: "principle_amt_woc",
                      })}
                    />
                  </div>
                )}
              />
              <Controller
                name={"without_com.cc_amt"}
                control={control}
                render={({ field }) => (
                  <div className="itemization-form-width">
                    <TextBox
                      {...field}
                      clearable={false}
                      size={SIZE.mini}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "client_woc_charges",
                      })}
                      label={intl.formatMessage({
                        id: "client_woc_charges",
                      })}
                    />
                  </div>
                )}
              />
            </div>
            <div className="commission-type-container">
              <Controller
                name={"without_com.ci_amt"}
                control={control}
                render={({ field }) => (
                  <div className="itemization-form-width">
                    <TextBox
                      {...field}
                      clearable={false}
                      size={SIZE.mini}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "clien_interest_woc",
                      })}
                      label={intl.formatMessage({
                        id: "clien_interest_woc",
                      })}
                    />
                  </div>
                )}
              />
              <Controller
                name={"pp_amt"}
                control={control}
                render={({ field }) => (
                  <div className="itemization-form-width">
                    <TextBox
                      {...field}
                      clearable={false}
                      size={SIZE.mini}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "prior_payment",
                      })}
                      label={intl.formatMessage({
                        id: "prior_payment",
                      })}
                    />
                  </div>
                )}
              />
            </div>
            <div className="commission-type-container">
              <Controller
                name={"pa_amt"}
                control={control}
                render={({ field }) => (
                  <div className="itemization-form-width">
                    <TextBox
                      {...field}
                      clearable={false}
                      size={SIZE.mini}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "prior_adjustment",
                      })}
                      label={intl.formatMessage({
                        id: "prior_adjustment",
                      })}
                    />
                  </div>
                )}
              />
            </div>

            {!getValues("without_com.orgiamt") &&
              !getValues("without_com.cc_amt") &&
              !getValues("without_com.ci_amt") &&
              isSubmit && (
                <Typography
                  type="p"
                  className="text-error"
                  style={{ textAlign: "center", fontWeight: "400" }}
                >
                  Itemization data is blank
                </Typography>
              )}
          </div>
        </>
      ) : (
        <>
          {is_itmzn ? (
            <>
              {" "}
              <div className="without-with-commission-container">
                <div className="itemization-form-width">
                  <Typography type="p" className="comission-typo">
                    Without Commission
                  </Typography>
                  <Controller
                    name={"without_com.orgiamt"}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          debounceFilter({
                            amount: e.target.value,
                            type: "PRINCIPLE",
                            to: "WITH_COMMISSION",
                          });
                        }}
                        placeholder={intl.formatMessage({
                          id: "principle_amt_woc",
                        })}
                        label={intl.formatMessage({
                          id: "principle_amt_woc",
                        })}
                      />
                    )}
                  />

                  <Controller
                    name={"without_com.cc_amt"}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          debounceFilter({
                            amount: e.target.value,
                            type: "CHARGE",
                            to: "WITH_COMMISSION",
                          });
                        }}
                        placeholder={intl.formatMessage({
                          id: "client_woc_charges",
                        })}
                        label={intl.formatMessage({
                          id: "client_woc_charges",
                        })}
                      />
                    )}
                  />
                  <Controller
                    name={"without_com.ci_amt"}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          debounceFilter({
                            amount: e.target.value,
                            type: "INTREST",
                            to: "WITH_COMMISSION",
                          });
                        }}
                        placeholder={intl.formatMessage({
                          id: "clien_interest_woc",
                        })}
                        label={intl.formatMessage({
                          id: "clien_interest_woc",
                        })}
                      />
                    )}
                  />
                </div>
                <div className="itemization-form-width">
                  <Typography type="p" className="comission-typo">
                    With Commission
                  </Typography>
                  <Controller
                    name={"with_com.orgiamt"}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          debounceFilter({
                            amount: e.target.value,
                            type: "PRINCIPLE",
                            to: "WITHOUT_COMMISSION",
                          });
                        }}
                        placeholder={intl.formatMessage({
                          id: "principal_amout_wc",
                        })}
                        label={intl.formatMessage({
                          id: "principal_amout_wc",
                        })}
                      />
                    )}
                  />

                  <Controller
                    name={"with_com.cc_amt"}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          debounceFilter({
                            amount: e.target.value,
                            type: "CHARGE",
                            to: "WITHOUT_COMMISSION",
                          });
                        }}
                        placeholder={intl.formatMessage({
                          id: "client_wc_chargesClient",
                        })}
                        label={intl.formatMessage({
                          id: "client_wc_chargesClient",
                        })}
                      />
                    )}
                  />

                  <Controller
                    name={"with_com.ci_amt"}
                    control={control}
                    render={({ field }) => (
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          debounceFilter({
                            amount: e.target.value,
                            type: "INTREST",
                            to: "WITHOUT_COMMISSION",
                          });
                        }}
                        placeholder={intl.formatMessage({
                          id: "clien_interest_wc",
                        })}
                        label={intl.formatMessage({
                          id: "clien_interest_wc",
                        })}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="itemization-form-section">
                <Controller
                  name="pp_amt"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      clearable={false}
                      size={SIZE.mini}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "prior_payment",
                      })}
                      label={intl.formatMessage({
                        id: "prior_payment",
                      })}
                    />
                  )}
                />
                <Controller
                  name="pa_amt"
                  control={control}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      clearable={false}
                      size={SIZE.mini}
                      name={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      placeholder={intl.formatMessage({
                        id: "prior_adjustment",
                      })}
                      label={intl.formatMessage({
                        id: "prior_adjustment",
                      })}
                    />
                  )}
                />
              </div>
              <div className="itemization-form-section">
                <Controller
                  name="comamt"
                  control={control}
                  render={({ field }) => (
                    <div className="itemization-form-width">
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        disabled={true}
                        name={field.name}
                        value={field.value}
                        // placeholder={intl.formatMessage({
                        //   id: "collection_fees",
                        // })}
                        label={intl.formatMessage({
                          id: "collection_fees",
                        })}
                      />
                    </div>
                  )}
                />
              </div>
              {!getValues("without_com.orgiamt") &&
                !getValues("without_com.cc_amt") &&
                !getValues("without_com.ci_amt") &&
                isSubmit && (
                  <Typography
                    type="p"
                    className="text-error"
                    style={{ textAlign: "center", fontWeight: "400" }}
                  >
                    Itemization data is blank
                  </Typography>
                )}
            </>
          ) : (
            <>
              <div className="itemization-form-container">
                <Controller
                  name="inv_amnt"
                  control={control}
                  rules={{ required: !is_itmzn ? "Required" : "" }}
                  render={({ field }) => (
                    <div className="itemization-form-width">
                      <TextBox
                        {...field}
                        clearable={false}
                        size={SIZE.mini}
                        error={errors[field.name] && errors[field.name].message}
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        placeholder={intl.formatMessage({
                          id: "inv_amnt",
                        })}
                        label={intl.formatMessage({
                          id: "inv_amnt",
                        })}
                      />
                    </div>
                  )}
                />
              </div>
            </>
          )}
        </>
      )}

      {_.get(customerData, "data.is_itmzn_err", false) && (
        <Controller
          name="is_rectify_error"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb {...field} checked={field.value}>
              <Typography type="p" className="itemization-check-box">
                Itemization Error Rectified
              </Typography>
            </CheckBoxBaseweb>
          )}
        />
      )}

      <hr style={{ marginTop: "10px", marginBottom: "10px" }} />

      <Controller
        name="capture_add_details"
        control={control}
        render={({ field }) => (
          <CheckBoxBaseweb {...field} checked={field.value}>
            <Typography type="p" className="itemization-check-box">
              Capture Additional Details
            </Typography>
          </CheckBoxBaseweb>
        )}
      />

      {capture_add_details ? (
        <>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <ItemizationCustomField
              control={control}
              setValue={setValue}
              customFields={customerData}
            />
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="itemization-footer-section">
        <div>
          <TextButton
            type="button"
            kind={KIND.tertiary}
            size={SIZE.mini}
            onClick={() => {
              reset();
            }}
          >
            Cancel
          </TextButton>
        </div>
        <div>
          <TextButton
            type="submit"
            kind={KIND.primary}
            size={SIZE.mini}
            isLoading={isSubmitting}
            onClick={() => {
              setSubmit(true);
            }}
          >
            Save
          </TextButton>
        </div>
      </div>
    </form>
  );
};

export default ItemizationForm;
