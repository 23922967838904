import React, { useContext } from "react";
import { KIND, SIZE, TextButton } from "../../../../components/TextButton";
import { Icon, RDText, Typography } from "../../../../components_v2";
import _ from "lodash";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import moment from "moment";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { deleteBankruptcy } from "../../../../services";
import { useMutation } from "react-query";
import { ADD_BANKRUPTCY } from "../../../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";

const BankruptcyCard = (props) => {
  const { currentDefaultFormatDetails, currentOrganization, refetch } =
    useContext(MaxyfiContext);

  const deleteBankruptcyData = useMutation(
    (resourceId) =>
      deleteBankruptcy({
        resourceId: resourceId,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
        }
      },
      onSuccess: (data, variables, context) => {
        props.refetch();
        refetch({
          setLoader: false,
          currentOrganization: currentOrganization,
        });
      },
    }
  );

  let filedDate = _.get(props, "fildt", "");

  return (
    <div>
      <RBACWrapper role={ADD_BANKRUPTCY} type="PERMISSION">
        <div className="bankruptcy-conatainer">
          <TextButton
            kind={KIND.tertiary}
            size={SIZE.mini}
            type="button"
            style={{
              color: "#516BEB",
            }}
            onClick={() => {
              props.setState({
                type: "ADD",
              });
            }}
          >
            <Icon icon="plus" color="#516BEB" />
            Add Bankruptcy
          </TextButton>
        </div>
      </RBACWrapper>

      <div className="bankruptcy-card">
        <div className="added-counsel-card_header">
          <div className="bank-card-name">
            <Typography type="h4">
              {" "}
              {_.get(props, "fnam", "")} {_.get(props, "lnam", "")}
            </Typography>
            <Icon icon="dot" color="#D3D3D7" size={5}></Icon>
            <Typography type="p" subType="regular">
              {_.get(props, "ssn", "")}
            </Typography>
          </div>
          <div className="bank-icon-container">
            <Icon
              icon="edit_outline"
              color="#ADADAD"
              size={16}
              isPressable
              onClick={() => {
                props.setResource(props._id, true);
              }}
            />
            <StatefulPopover
              triggerType={TRIGGER_TYPE.click}
              content={({ close }) => (
                <div className="co_groping_delete_popover">
                  <div className="co_groping_delete_popover_body">
                    <Icon icon="alert" color="#FD372A" />
                    <Typography type="p">
                      This will remove the Bankruptcy information, Do you want
                      to continue?
                    </Typography>
                  </div>
                  <div className="co_groping_delete_popover_footer">
                    <TextButton
                      size="mini"
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => close()}
                    >
                      <Typography type="h5" style={{ color: "#FD372A" }}>
                        Cancel
                      </Typography>
                    </TextButton>
                    <TextButton
                      size="mini"
                      kind={KIND.secondary}
                      onClick={async () => {
                        await deleteBankruptcyData.mutateAsync(props._id);
                      }}
                      type="error"
                    >
                      Confirm
                    </TextButton>
                  </div>
                </div>
              )}
            >
              <div style={{ cursor: "pointer" }}>
                {" "}
                <Icon
                  icon="delete_outline"
                  color="#ADADAD"
                  size={16}
                  isPressable
                />
              </div>
            </StatefulPopover>
          </div>
        </div>
        <Typography
          type="p"
          subType="regular"
          className="text-secondary"
          style={{ marginTop: "5px" }}
        >
          Bankruptcy#:{" "}
          <span style={{ color: "#333860" }}> {_.get(props, "bkno", "")}</span>
        </Typography>
        <Typography
          type="p"
          subType="regular"
          className="text-secondary"
          style={{ marginTop: "5px" }}
        >
          Chapter:{" "}
          <span style={{ color: "#333860" }}>{_.get(props, "bkchp", "")}</span>
        </Typography>
        <Typography
          type="p"
          subType="regular"
          className="text-secondary"
          style={{ marginTop: "5px" }}
        >
          {" "}
          Court:{" "}
          <span style={{ color: "#333860" }}>
            {_.get(props, "dscotna", "")}
          </span>
        </Typography>
        <Typography
          type="p"
          subType="regular"
          className="text-secondary"
          style={{ marginTop: "5px" }}
        >
          {" "}
          Docket#:{" "}
          <span style={{ color: "#333860" }}>{_.get(props, "docno", "")}</span>
        </Typography>
        <div className="bank-card-part">
          <div>
            <Typography
              type="p"
              subType="regular"
              className="text-secondary"
              style={{ marginTop: "5px" }}
            >
              {" "}
              Date Filed:{" "}
              <span style={{ color: "#333860" }}>
                {filedDate ? (
                  moment(filedDate).format(
                    currentDefaultFormatDetails.date_format
                  )
                ) : (
                  <></>
                )}
              </span>
            </Typography>
            <Typography
              type="p"
              subType="regular"
              className="text-secondary"
              style={{ marginTop: "5px" }}
            >
              {" "}
              State:{" "}
              <span style={{ color: "#333860" }}>
                <RDText id={_.get(props, "state", "")} rdName={"state_list"} />
              </span>
            </Typography>
          </div>
          <div>
            {" "}
            <Typography
              type="p"
              subType="regular"
              className="text-secondary"
              style={{
                textAlign: "end",
              }}
            >
              {" "}
              Created by:
            </Typography>
            <Typography
              type="p"
              subType="regular"
              className="regular"
              style={{ marginTop: "5px" }}
            >
              {" "}
              {_.get(props, "userName.displayName", "").trim()}
              {", "}
              {moment(_.get(props, "crat", "")).format(
                currentDefaultFormatDetails.date_format
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankruptcyCard;
