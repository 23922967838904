import React from "react";

const BackArrow = ({ size = 22, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.77692 11.6391L11.4599 16.322C11.5886 16.4537 11.6532 16.6054 11.6538 16.7773C11.6544 16.9492 11.5889 17.1009 11.4572 17.2325C11.3227 17.3653 11.1702 17.4322 10.9998 17.433C10.8294 17.4339 10.6784 17.3671 10.5468 17.2325L4.86432 11.5501C4.78324 11.4719 4.72389 11.3872 4.68628 11.2958C4.64868 11.2044 4.62988 11.1059 4.62988 11.0001C4.62988 10.8943 4.64868 10.7957 4.68628 10.7044C4.72389 10.613 4.78324 10.5268 4.86432 10.4457L10.5468 4.76324C10.6707 4.63926 10.8198 4.5758 10.9941 4.57285C11.1683 4.56992 11.3227 4.63338 11.4572 4.76324C11.5889 4.89779 11.6547 5.05072 11.6547 5.22201C11.6547 5.39329 11.5889 5.54621 11.4572 5.68076L6.77692 10.3567H17.0507C17.2258 10.3567 17.376 10.4194 17.5015 10.5448C17.6269 10.6703 17.6897 10.822 17.6897 11.0001C17.6897 11.1781 17.6269 11.3291 17.5015 11.4531C17.376 11.5771 17.2258 11.6391 17.0507 11.6391H6.77692Z"
        fill={color}
      />
    </svg>
  );
};

export default BackArrow;
