import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { LabelLarge, LabelMedium, ParagraphSmall } from "baseui/typography";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { IconButton } from "../../components/IconButton";
import {
  downloadAging,
  getOrganizationDashboard,
} from "../../services/organization";
import { SIZE, Select } from "baseui/select";
import BarChart from "./BarChart";
import { DOWNLOAD_AGING_SUMMARY } from "../../providers/RBACProvider/permissionList";
import Download from "../../assets/img/svg/Download";
import _ from "lodash";
import { CollectionAgencies } from "../../constants/IndustrialSector";
import { useMemo } from "react";

let backgroundColor = ["#FFEF82", "#FEB139", "#FDCAC6", "#FF7B72", "#FD372A"];

const AgingSummary = () => {
  let {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState([
    { label: "Consumer Level", id: "customer" },
  ]);

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `AGENT_DASHBOARD_${currentOrganization}_AGING_SUMMARY`,
      {
        filters: {
          ...appAccessRest,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "AGEING_SUMMARY",
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 0,
      cacheTime: 1800000,
    }
  );

  let barValues = useMemo(() => {
    if (
      _.get(data, "data.doc.max_delay_ageing_summary", []) &&
      value &&
      value[0] &&
      value[0].id === "customer"
    ) {
      let maxDelayValue = _.get(
        data,
        "data.doc.max_delay_ageing_summary",
        []
      ).map(({ value }) => value);
      return maxDelayValue;
    } else {
      let ageingSummaryValue = _.get(
        data,
        "data.doc.ageing_summary_amount",
        []
      ).map(({ value }) => value);
      return ageingSummaryValue;
    }
  }, [data, value?.[0]?.id]);

  let trendsPercentage = useMemo(() => {
    if (
      _.get(data, "data.doc.max_delay_ageing_summary", []) &&
      value &&
      value[0] &&
      value[0].id === "customer"
    ) {
      let maxDelayPercentage = _.get(
        data,
        "data.doc.max_delay_ageing_summary",
        []
      ).map(({ percentage }) => percentage);
      return maxDelayPercentage;
    } else {
      let ageingSummaryPercentage = _.get(
        data,
        "data.doc.ageing_summary_amount",
        []
      ).map(({ percentage }) => percentage);
      return ageingSummaryPercentage;
    }
  }, [data, value?.[0]?.id]);

  return (
    <div className="db-action__table">
      <div className="db-action__table-head">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flex: "1",
            alignItems: "center",
            padding: "0px 20px",
          }}
        >
          <div
          // style={{ display: "flex", alignItems: "center", gap: "15px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <LabelLarge>Aging Summary</LabelLarge>
              <RBACWrapper type="PERMISSION" role={DOWNLOAD_AGING_SUMMARY}>
                <IconButton
                  isLoading={loading}
                  onClick={async () => {
                    setLoading(true);
                    await downloadAging({
                      organization: currentOrganization,
                    });
                    setLoading(false);
                  }}
                >
                  <Download />
                </IconButton>
              </RBACWrapper>
            </div>
            {data && data.data && data.data.doc && data.data.doc.dso ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LabelMedium $style={{ color: "#516beb" }}>
                  {data.data.doc.dso} Days Average DSO
                </LabelMedium>
              </div>
            ) : (
              ""
            )}
          </div>

          <div style={{ width: "160px" }}>
            <Select
              size={SIZE.compact}
              clearable={false}
              backspaceRemoves={false}
              overrides={{
                Tag: {
                  props: {
                    overrides: {
                      Text: {
                        style: ({ $theme }) => ({
                          maxWidth: "500px",
                        }),
                      },
                    },
                  },
                },
                Placeholder: {
                  style: ({ $theme, $isFocused }) => ({
                    color: $isFocused ? "#cbd5e0" : "transparent",
                    transition: "color 0.4s ease",
                  }),
                },

                // DropdownListItem: {

                // },

                // DropdownOption: {

                // },
                // Dropdown: {
                //   style: ({ $theme }) => ({
                //     maxHeight: "370px",
                //   }),
                // },

                // expand arrow need to change

                // IconsContainer: (props) => {
                //   return (
                //     <div className="filter__select">
                //       {props.$isOpen ? <UpArrow /> : <DownArrow />}
                //     </div>
                //   );
                // },
                ControlContainer: {
                  style: ({ $theme, $isFocused, $error }) => ({
                    borderRadius: "5px",
                    backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                    borderRadius: "3px",
                    borderColor: "#CDCED9",
                    borderWidth: "0.5px",
                    borderStyle: "solid",
                    paddingRight: "0px",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    borderWidth: "1px",
                    borderColor: $isFocused
                      ? $error
                        ? "#FD372A"
                        : "#516BEB"
                      : $error
                      ? "#FD372A"
                      : "#CDCED9",
                  }),
                },
              }}
              options={[
                { label: "Consumer Level", id: "customer" },
                { label: "Invoice Level", id: "invoice" },
              ]}
              value={value}
              placeholder="Select color"
              onChange={(params) => setValue(params.value)}
            />
          </div>
        </div>
      </div>

      <div>
        <BarChart
          selectValue={value && value[0] && value[0].id}
          data={
            barValues
            // value && value[0] && value[0].id === "customer"
            //   ? _.get(data, "data.doc.max_delay_ageing_summary", [])
            //   : _.get(data, "data.doc.ageing_summary_amount", [])
          }
        />
      </div>
      {currentDefaultFormatDetails?.industry_sector !== CollectionAgencies &&
      appAccessRest.master_filter_type === "BUSINESS_UNIT" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "55px",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "9px",
          }}
        >
          {trendsPercentage.map((e, i) => {
            return (
              <div key={i} style={{ display: "flex", gap: "5px" }}>
                <div
                  style={{
                    backgroundColor: backgroundColor[i],
                    width: "20px",
                    height: "20px",
                    borderRadius: "5px",
                  }}
                ></div>
                <ParagraphSmall>{e ? `${e}%` : "-"}</ParagraphSmall>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AgingSummary;
