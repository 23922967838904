function Hold() {
  return (
    <>
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9142 18H7.86114C7.02797 18 6.21022 17.6624 5.63163 17.0771L0 11.3672L1.59691 9.99416C2.07522 9.58149 2.77724 9.49896 3.3404 9.79158L5.19961 10.752V3.58649C5.19961 2.55106 6.06364 1.71071 7.12825 1.71071C7.2594 1.71071 7.39055 1.72572 7.5217 1.74823C7.59113 0.772822 8.4243 0 9.44262 0C10.1061 0 10.6847 0.322634 11.0318 0.817841C11.2555 0.727803 11.5024 0.682784 11.757 0.682784C12.8216 0.682784 13.6856 1.52313 13.6856 2.55857V2.76865C13.8091 2.74614 13.9402 2.73114 14.0714 2.73114C15.136 2.73114 16 3.57149 16 4.60692V14.9987C16 16.6569 14.6191 18 12.9142 18ZM2.22179 11.4573L6.74253 16.0342C7.03568 16.3268 7.43684 16.4994 7.85342 16.4994H12.9142C13.7628 16.4994 14.4571 15.8241 14.4571 14.9987V4.60692C14.4571 4.39683 14.2874 4.23176 14.0714 4.23176C13.8554 4.23176 13.6856 4.39683 13.6856 4.60692V8.99625H12.1427V2.55857C12.1427 2.34848 11.973 2.18341 11.757 2.18341C11.541 2.18341 11.3713 2.34848 11.3713 2.55857V8.99625H9.82835V1.87578C9.82835 1.66569 9.65863 1.50063 9.44262 1.50063C9.22661 1.50063 9.05689 1.66569 9.05689 1.87578V8.99625H7.51398V3.58649C7.51398 3.37641 7.34426 3.21134 7.12825 3.21134C6.91225 3.21134 6.74253 3.38391 6.74253 3.58649V13.243L2.61524 11.1196L2.22179 11.4573Z"
          fill="#516beb"
        />
      </svg>
    </>
  );
}

export default Hold;
