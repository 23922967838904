import { Paragraph2 } from "baseui/typography";
import React, { useContext } from "react";
import { viewSessionInfoModal } from "../../../redux/users/actions";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

const UserSessionInfo = (props) => {
  let { currentOrganization, space } = useContext(MaxyfiContext);

  const dispatch = useDispatch();
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");
  const viewAudit =
    space && space?.role_permissions?.includes("view_user_audit");

  return (
    <Paragraph2
      //   ref={ref}
      onClick={() => {
        !viewAudit ? <></> : dispatch(viewSessionInfoModal(props.row.original));
      }}
      $style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
        width: "100%",
        textAlign: props.alignment,
        margin: "5px",
        color: "#516beb",
        textDecoration: "underline",
      }}
    >
      {value}
    </Paragraph2>
  );
};

export default UserSessionInfo;
