import {
  ADDING_INVOICE_DRAWER_OPEN,
  ADDING_INVOICE_DRAWER_TYPE,
  ADDING_INVOICE_SELECTED_DOCUMENT_DATA,
  ADDING_DOCUMENT_FROM_DESKTOP,
  UPDATING_ADDING_INVOICE_ATTACHMENT_FROM_DESKTOP,
  UNSELECTED_DOCUMENT_ADDING_INVOICE,
  EDITING_DOCUMENT_ADDING_INVOICE,
} from "./action";

const initialState = {
  addingInvoiceDrawerState: false,
  addingInvoiceType: "",
  selectedDocumentData: [],
  // invoiceAddingDocumentType: "EDITING_INVOICE",
  // emailAttachment
};

const invoiceAddingDocumentReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case EDITING_DOCUMENT_ADDING_INVOICE: {
      return {
        ...state,
        // invoiceAddingDocumentType: "",
        selectedDocumentData: [...state.selectedDocumentData, payload],
      };
    }

    case ADDING_INVOICE_DRAWER_OPEN: {
      return {
        ...state,
        addingInvoiceDrawerState: payload,
      };
    }
    case ADDING_INVOICE_DRAWER_TYPE: {
      return {
        ...state,
        addingInvoiceType: payload,
      };
    }
    case ADDING_INVOICE_SELECTED_DOCUMENT_DATA: {
      let attachData = [...state.selectedDocumentData];
      attachData.push(...payload);
      return {
        ...state,
        selectedDocumentData: attachData,
      };
    }

    case ADDING_DOCUMENT_FROM_DESKTOP: {
      let fileBaseStructure = {
        tempId: null,
        name: "",
        type: "",
        isNewDoc: true,
        scanStatus: "FILE_SCAN_YTS",
        fileRef: null,
      };

      const currentFiles = state.selectedDocumentData;

      let currentFileLength = currentFiles.length;

      let files = payload.map((file) => {
        currentFileLength++;

        return {
          ...fileBaseStructure,
          ...file,
          tempId: `ID-${currentFileLength + 1}`,
        };
      });

      return {
        ...state,
        selectedDocumentData: [...currentFiles, ...files],
      };
    }
    case UPDATING_ADDING_INVOICE_ATTACHMENT_FROM_DESKTOP: {
      let { tempId, ...updateObj } = payload;

      let fileIndex = state.selectedDocumentData.findIndex(
        (fl) => fl.tempId === tempId
      );

      if (fileIndex >= 0) {
        let selectedDocumentData = state.selectedDocumentData;
        let currentFile = selectedDocumentData[fileIndex];
        currentFile = { ...currentFile, ...updateObj };
        selectedDocumentData[fileIndex] = currentFile;

        return { ...state, selectedDocumentData: selectedDocumentData };
      }

      return state;
    }

    case UNSELECTED_DOCUMENT_ADDING_INVOICE: {
      // let attachData = { ...state };
      let filteredData = [];
      let unSelectedData = [...payload];

      if (unSelectedData && unSelectedData.length > 0) {
        let filteringUnselected = state.selectedDocumentData.filter(
          (e) => !payload.some((i) => e.fileRef === i.fileRef)
        );

        filteredData.push(...filteringUnselected);
      }
      return {
        ...state,
        selectedDocumentData: filteredData,
      };
    }

    default:
      return initialState;
  }
};
export default invoiceAddingDocumentReducer;
