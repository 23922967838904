function LeftArrow() {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8 11C19.8 15.851 15.851 19.8 11 19.8C6.149 19.8 2.2 15.851 2.2 11C2.2 6.149 6.149 2.2 11 2.2C15.851 2.2 19.8 6.149 19.8 11ZM22 11C22 4.928 17.072 -2.1541e-07 11 -4.80825e-07C4.928 -7.46241e-07 -6.92247e-07 4.928 -9.57662e-07 11C-1.22308e-06 17.072 4.928 22 11 22C17.072 22 22 17.072 22 11ZM11 12.1L14.3 12.1C14.905 12.1 15.4 11.605 15.4 11C15.4 10.395 14.905 9.9 14.3 9.9L11 9.9L11 7.931C11 7.436 10.406 7.194 10.065 7.546L6.996 10.615C6.776 10.835 6.776 11.176 6.996 11.396L10.065 14.465C10.406 14.806 11 14.564 11 14.08L11 12.1V12.1Z"
          fill="#ADADAD"
        />
      </svg>
    </>
  );
}

export default LeftArrow;
