const DeletePdf = () => {
  return (
    <>
      <svg
        width="9"
        height="9"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.3558 0.14883C6.16354 -0.0430499 5.85296 -0.0430499 5.66069 0.14883L3.25 2.54979L0.839306 0.14391C0.647042 -0.04797 0.336462 -0.04797 0.144198 0.14391C-0.048066 0.33579 -0.048066 0.645751 0.144198 0.837631L2.55489 3.24351L0.144198 5.64939C-0.048066 5.84128 -0.048066 6.15124 0.144198 6.34312C0.336462 6.535 0.647042 6.535 0.839306 6.34312L3.25 3.93723L5.66069 6.34312C5.85296 6.535 6.16354 6.535 6.3558 6.34312C6.54807 6.15124 6.54807 5.84128 6.3558 5.64939L3.94511 3.24351L6.3558 0.837631C6.54314 0.650671 6.54314 0.33579 6.3558 0.14883Z"
          fill="#ADADAD"
        />
      </svg>
    </>
  );
};
export default DeletePdf;
