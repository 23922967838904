import React from "react";
import queryClient from "../../../../providers/queryClient";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setDrawerState } from "../../../../redux/actions";
import AddPayment from "../../Payment/AddPayment";
import { NEW } from "../../../../constants";

const FieldVisitPaymentDataLayer = (props) => {
  let { service = () => {}, onSuccess = () => {}, ...rest } = props;

  return (
    <>
      <AddPayment
        type={NEW}
        onSuccess={() => {
          onSuccess();
        }}
        services={service}
        {...rest}
      />
    </>
  );
};

export default FieldVisitPaymentDataLayer;
