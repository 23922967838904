import enLang from "../lang/enteries/en-US";
import frLang from "../lang/enteries/fr-FR";
import arLang from "../lang/enteries/ar-US";

const AppLocale = {
  // want to wright fallback there was any lang
  en: enLang,
  fr: frLang,
  ar: arLang,
};

export default AppLocale;
