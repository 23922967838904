import React from "react";

const SepartorContact = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.513184 1C0.513227 20.5 0.512865 20.5 20.0135 20.5"
        stroke="#EEEEEE"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default SepartorContact;
