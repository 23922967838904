import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TextBox } from "../../../../components/TextBox";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import { SelectBox } from "../../../../components/SelectBox";
import { EMAIL, LETTER, SMS } from "../../../../constants";
import { TextArea } from "../../../../components/TextArea";
import { SIZE } from "baseui/input";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TimePicker } from "baseui/timepicker";
import { TimePickerAction } from "../../../../components/TimePicker/TimePicker";
import { Controller } from "react-hook-form";

const MeetingRequestForm = (call) => {
  let { referenceData } = useContext(MaxyfiContext);
  let intl = useIntl();
  let { actioWatch, control, errors } = call;
  let [is_send_confirmation_message, is_send_action_follow_up] = actioWatch;

  return (
    <>
      <div style={{ width: "600px" }}>
        <Controller
          defaultValues={""}
          name="meeting_venue"
          control={call.control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              clearable={false}
              name={field.name}
              requiredAstric={true}
              error={call.errors[field.name] && call.errors[field.name].message}
              label={intl.formatMessage({
                id: "meeting_venue",
              })}
              placeholder={intl.formatMessage({
                id: "meeting_venue",
              })}
              value={field.value}
            />
          )}
        />
      </div>
      <div className="cp-action-message-confirmation">
        <div className="cp-message-confirmation-type cal--select">
          <Controller
            name="meeting_date"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                label={intl.formatMessage({
                  id: "meeting_date",
                })}
                placeholder={intl.formatMessage({
                  id: " ",
                })}
                value={field.value}
                onChange={(e) => field.onChange(e.date)}
              />
            )}
          />
        </div>
        <div className="cp-action-callback">
          <Controller
            defaultValues={""}
            name="callback_time"
            control={control}
            rules={{ required: "Required" }} //TODO: Change Input Design
            render={({ field }) => (
              <TimePickerAction
                {...field}
                clearable={false}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "call_back_time",
                })}
                placeholder={intl.formatMessage({
                  id: "call_back_time",
                })}
                value={
                  field.value
                  //
                }
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </div>
      </div>
      <div style={{ paddingTop: "30px" }}>
        <Controller
          defaultValues={false}
          name="is_hold_workflow"
          control={control}
          render={({ field }) => (
            <CheckBoxBaseweb
              {...field}
              checked={field.value}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <FormattedMessage id="hold_workflow_till_meeting_date">
                hold_workflow_till_meeting_date
              </FormattedMessage>
            </CheckBoxBaseweb>
          )}
        />
      </div>
      <div className="cp-action-message-confirmation">
        <div className="cp-message-confirmation-checkbox">
          <Controller
            defaultValues={false}
            name="is_send_confirmation_message"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                <FormattedMessage id="send_confirmation_message">
                  send_confirmation_message
                </FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
        {is_send_confirmation_message ? (
          <>
            <div className="cp-message-confirmation-type">
              <Controller
                defaultValues={[]}
                name="confirmation_message_type"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comm_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comm_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      referenceData["template_type"].filter(
                        (e) => e.id !== LETTER
                      ) || []
                    }
                  />
                )}
              />
            </div>
            {/* TODO: TEMPLATE TYPE */}
            <div className="cp-action-promise-pay-con">
              <Controller
                name="confirmation_message_template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "meeting_confirmation",
                    })}
                    placeholder={intl.formatMessage({
                      id: "meeting_confirmation",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["template_type"] || []}
                  />
                )}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="cp-action-message-confirmation">
        <div className="cp-message-confirmation-checkbox">
          <Controller
            defaultValues={false}
            name="is_send_action_follow_up"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                <FormattedMessage id="send_meeting_follow_up_message">
                  send_meeting_follow_up_message
                </FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
        {is_send_action_follow_up ? (
          <>
            <div className="cp-message-confirmation-type">
              <Controller
                name="action_follow_up_type"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "comm_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "comm_type",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={
                      referenceData["template_type"].filter(
                        (e) => e.id !== LETTER
                      ) || []
                    }
                  />
                )}
              />
            </div>
            <div className="cp-action-promise-pay-con">
              <Controller
                defaultValues={[]}
                name="action_follow_up_template_id"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "meeting_follow_up_message",
                    })}
                    placeholder={intl.formatMessage({
                      id: "meeting_follow_up_message",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["template_type"] || []}
                  />
                )}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="cp-action-promise_test-area">
        <Controller
          name="user_comments"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextArea
              {...field}
              clearable={false}
              error={errors[field.name] && errors[field.name].message}
              name={field.name}
              label={intl.formatMessage({
                id: "user_comments",
              })}
              placeholder={intl.formatMessage({
                id: "user_comments",
              })}
              value={field.value}
            />
          )}
        />
      </div>
    </>
  );
};

export default MeetingRequestForm;
