import React from "react";

function Tags({ size = "22px" }) {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" preserveAspectRatio>
      <path
        d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7V17C3 18.1 3.9 18.99 5 18.99L16 19C16.67 19 17.27 18.67 17.63 18.16L22 12L17.63 5.84ZM16 17H5V7H16L19.55 12L16 17Z"
        fill="#333860"
      />
    </svg>
  );
}

export default Tags;
