import React from "react";

const NoteOutline = ({ size = "22", color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.11938 6.75C4.11938 6.52218 4.30947 6.3375 4.54397 6.3375H9.94785C10.1824 6.3375 10.3724 6.52218 10.3724 6.75C10.3724 6.97782 10.1824 7.1625 9.94785 7.1625H4.54397C4.30947 7.1625 4.11938 6.97782 4.11938 6.75ZM4.11938 9C4.11938 8.77218 4.30947 8.5875 4.54397 8.5875H8.40389C8.63838 8.5875 8.82848 8.77218 8.82848 9C8.82848 9.22782 8.63838 9.4125 8.40389 9.4125H4.54397C4.30947 9.4125 4.11938 9.22782 4.11938 9ZM3.84918 13.875V4.125C3.84918 3.95931 3.98743 3.825 4.15798 3.825H11.1058C11.2764 3.825 11.4146 3.95931 11.4146 4.125V8.7L12.2638 7.875V4.125C12.2638 3.50368 11.7454 3 11.1058 3H4.15798C3.51844 3 3 3.50368 3 4.125V13.875C3 14.4963 3.51844 15 4.15798 15H11.1058C11.7454 15 12.2638 14.4963 12.2638 13.875V11.625L11.4146 12.45V13.875C11.4146 14.0407 11.2764 14.175 11.1058 14.175H4.15798C3.98743 14.175 3.84918 14.0407 3.84918 13.875ZM12.6498 7.875L9.94785 10.5L9.43722 11.9883C9.33361 12.2903 9.63999 12.5724 9.94485 12.4558L11.8778 11.7162L14.1938 9.375L14.7513 8.83336C15.0829 8.51118 15.0829 7.98882 14.7513 7.66664L14.4082 7.33336C14.0766 7.01118 13.5389 7.01118 13.2073 7.33336L12.6498 7.875ZM12.95 8.75004L10.6901 10.9456L10.5564 11.3351L11.3941 11.0146L13.2989 9.089L12.95 8.75004ZM13.8935 8.49996L13.5505 8.16668L13.8078 7.91673L14.1508 8.25L13.8935 8.49996Z"
        fill={color}
      />
    </svg>
  );
};

export default NoteOutline;
