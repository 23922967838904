import React from "react";

const Batch = ({ size = 22, color = "#516BEB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5792_162456)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.0228 6.62642L11.037 3.06517C10.8436 2.97828 10.6227 2.97828 10.4283 3.06517L2.44287 6.62642C2.18221 6.74247 2.01007 6.99793 2.0004 7.28347C1.99108 7.56844 2.14365 7.8347 2.39572 7.96879L10.3811 12.2274C10.4912 12.2865 10.6119 12.3154 10.7329 12.3154C10.8545 12.3154 10.9751 12.2865 11.0854 12.2274L19.0711 7.96879C19.3229 7.8347 19.4757 7.56844 19.4665 7.28347C19.4556 6.99793 19.2839 6.74247 19.0228 6.62642ZM18.3671 9.81405L10.7329 13.8865L3.10003 9.81405C2.73461 9.61907 2.28214 9.7574 2.08847 10.1221C1.89344 10.4867 2.03177 10.9391 2.39617 11.1335L10.3815 15.3933C10.4916 15.452 10.6123 15.4812 10.7333 15.4812C10.855 15.4812 10.9755 15.4524 11.0858 15.3933L19.0715 11.1335C19.4351 10.9391 19.5732 10.4867 19.3793 10.1221C19.1842 9.75735 18.7314 9.61902 18.3671 9.81405ZM10.7329 16.8051L18.3671 12.734C18.732 12.5388 19.1842 12.6774 19.3793 13.0416C19.5732 13.4063 19.4351 13.859 19.0715 14.0532L11.0858 18.3116C10.9755 18.3711 10.855 18.3999 10.7333 18.3999C10.6123 18.3999 10.4916 18.3707 10.3815 18.3116L2.39617 14.0532C2.03177 13.859 1.89344 13.4062 2.08847 13.0416C2.28214 12.6774 2.73461 12.5389 3.10003 12.734L10.7329 16.8051Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5792_162456">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Batch;
