// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const HOST = process.env.REACT_APP_HOST;

export const invoiceSummaryData = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize = 20,
  filters = {},
  customerFilter = {},
}) => {
  return await axiosWithAuth.post(
    `${HOST}${organization}/invoices`,
    customerFilter,
    {
      params: {
        page: pageIndex,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },
    }
  );
};

export const checkMaintenanceFn = async ({
  organization = null,
  pageIndex = 1,
  sortBy = "-_id",
  pageSize = 20,
  filters = {},
  customerFilter = {},
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/check-maintenance`,
    customerFilter,
    {
      params: {
        page: pageIndex,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },
    }
  );
};

export const checkMaintenanceDownloadFn = async ({
  organization = null,
  filters = {},
  query = {},
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/download-generated`,
    query,
    {
      params: {
        ...filters,
      },
    }
  );
};
