import React, { useState, useContext } from "react";
import { IconButton } from "../../../components/IconButton";
import { KIND, SIZE, TextButton } from "../../../components/TextButton";
import DocDownArSvg from "../../../assets/img/svg/DocDownArSvg";
import DocRigntSvg from "../../../assets/img/svg/DocRigntSvg";
import DairyNoteEdit from "../../../assets/img/svg/CustomerOverViewIcon/DairyNoteEdit";
import TickSuccess from "../../../assets/img/svg/CustomerOverViewIcon/Tick";
import InvoiceUser from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/InvoiceUser";
import Delete from "../../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import {
  LabelSmall,
  HeadingXSmall,
  LabelMedium,
  ParagraphMedium,
} from "baseui/typography";
import moment from "moment";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { FormattedTime } from "react-intl";
import { EDIT } from "../../../constants";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { ModalContext } from "../../../providers/ModalProvider";
import { deleteCheckIn, markCheckInAsBilled } from "../../../services";
import WarningImage from "../../../assets/img/warning.png";
import { NumericFormat } from "react-number-format";
import CheckInInvSvg from "../../../assets/img/svg/CheckInInvSvg";

const CheckInCard = ({
  setIsAddNew,
  i,
  setEditingData,
  setRefetchCheckIn,
  refetchCheckIn,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  let {
    currentDefaultFormatDetails,
    currentOrganization,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    users,
  } = useContext(MaxyfiContext);
  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("customers");
  const { handleModal } = useContext(ModalContext);

  function epandCard() {
    setIsExpand(!isExpand);
  }

  let noteUserRef = i.created_by
    ? users.find((e) => e.id === i.created_by)
    : {};

  let auditUser = i.mark_as_billed_by
    ? users.find((e) => e.id == i.mark_as_billed_by)
    : {};

  const deleteCheck = useMutation(
    (checkInData) =>
      deleteCheckIn({
        ...checkInData,
        organization: currentOrganization,
        customerId: getParams,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        setRefetchCheckIn(refetchCheckIn + 1);
        setEditingData({ type: "", data: [] });
      },
    }
  );

  const markAsBilled = useMutation(
    (checkInData) =>
      markCheckInAsBilled({
        ...checkInData,
        organization: currentOrganization,
        customerId: getParams,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        setRefetchCheckIn(refetchCheckIn + 1);
      },
    }
  );

  return (
    <>
      <div
        className={
          isExpand ? "ci_card_container --ci_card_pt" : "ci_card_container"
        }
      >
        <div
          // className="dms_card_head"
          style={{ margin: "0px 10px" }}
        >
          <div
            className="dms_card_doc_type_content"
            style={{ alignItems: "flex-start" }}
          >
            <div>
              <IconButton
                kind={KIND.tertiary}
                size={SIZE.mini}
                onClick={() => epandCard()}
              >
                {isExpand ? <DocDownArSvg /> : <DocRigntSvg />}
              </IconButton>
            </div>
            {/* DATA and LOCATION */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <LabelSmall
                      style={{ lineHeight: "-1px", color: "#516beb" }}
                    >
                      {/* {i.name} -{" "} */}
                      {i && i.item_name ? i.item_name : ""}{" "}
                    </LabelSmall>
                    {i && i.amount && (
                      <NumericFormat
                        displayType="text"
                        value={i && i.amount && i.amount.value}
                        thousandsGroupStyle={currencyGroupStyle}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={currencySeparator}
                        decimalSeparator={currencyDecimalSeparator}
                        renderText={(value) => (
                          <LabelSmall
                            style={{ lineHeight: "-1px", color: "#516beb" }}
                          >
                            - {i && i.amount && i.amount.currency} {value}
                          </LabelSmall>
                        )}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      paddingBottom: `${isExpand ? "10px" : "0px"}`,
                    }}
                  >
                    <div>
                      {/* 12/2 */}
                      {/* {i.checkin_at} */}
                      {moment
                        .utc(i.checkin_at)
                        .format(currentDefaultFormatDetails.date_format)}{" "}
                      <FormattedTime value={new Date(i.checkin_at)} />
                      {i.location && i.location.length > 0
                        ? ` / ${i.location}`
                        : null}
                    </div>
                    <div></div>
                    <div>
                      {/* chennai */}
                      {/* {i.location} */}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    i?.is_invoice_generated ? "approved_green" : "approved_gray"
                  }
                >
                  {!i.is_invoice_generated ? (
                    <IconButton
                      title="mark as invoice generated"
                      kind={KIND.tertiary}
                      size={SIZE.mini}
                      onClick={() => {
                        handleModal({
                          title:
                            "You are about to mark this Check-in as Billed",
                          content:
                            "This will be excluded from the Invoice. Are you sure?",
                          successCallback: () => {
                            markAsBilled.mutateAsync({
                              checkinId: i._id,
                            });
                          },
                          image: WarningImage,
                          buttonText: "Mark as Billed",
                          closeButtonText: "Cancel",
                        });
                      }}
                    >
                      <CheckInInvSvg />
                    </IconButton>
                  ) : (
                    <div style={{ padding: "0px 9px" }}>
                      <CheckInInvSvg />
                    </div>
                  )}
                </div>
              </div>
              {/* IS_EXPAND DATAS STARTS*/}
              {isExpand ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "3px",
                      paddingTop: "10px",
                      borderTop: "0.5px solid #cddee9",
                    }}
                  >
                    {i.invoice_generate_at && (
                      <>
                        <HeadingXSmall style={{ lineHeight: "20px" }}>
                          Billed on:{" "}
                        </HeadingXSmall>
                        <LabelMedium>
                          {moment
                            .utc(i.invoice_generate_at)
                            .format(currentDefaultFormatDetails.date_format)}
                        </LabelMedium>

                        <LabelSmall
                          style={{ color: "#516beb", lineHeight: "20px" }}
                        >
                          {i.invoice_number}
                        </LabelSmall>
                      </>
                    )}
                    {i.mark_as_billed_by && i.mark_as_billed_at && (
                      <>
                        <HeadingXSmall style={{ lineHeight: "20px" }}>
                          Marked as Invoiced by {auditUser?.displayName} on{" "}
                          {moment
                            .utc(i.invoice_generate_at)
                            .format(currentDefaultFormatDetails.date_format)}
                        </HeadingXSmall>
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "3px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <div>
                        <InvoiceUser />
                      </div>
                      <div>
                        <ParagraphMedium $style={{ lineHeight: "17px" }}>
                          {noteUserRef?.displayName}
                        </ParagraphMedium>
                        <HeadingXSmall
                          $style={{
                            fontSize: "10px",
                            color: "#ADADAD",
                            lineHeight: "16px",
                          }}
                        >
                          {moment
                            .utc(i.created_at)
                            .format(currentDefaultFormatDetails.date_format)}
                          {/* {i.createdOn} */}
                        </HeadingXSmall>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {!i.is_invoice_generated ? (
                        <>
                          {/* <IconButton
                            kind={KIND.tertiary}
                            size={SIZE.mini}
                            onClick={() => {
                              handleModal({
                                title:
                                  "You are about to mark check-in as Billed",
                                content:
                                  "This will mark your check-in as billed. Are you sure?",
                                successCallback: () => {
                                  markAsBilled.mutateAsync({
                                    checkinId: i._id,
                                  });
                                },
                                image: WarningImage,
                                buttonText: "Mark as Billed",
                                closeButtonText: "Cancel",
                              });
                            }}
                          >
                            <TickSuccess />
                          </IconButton> */}
                          <IconButton
                            kind={KIND.tertiary}
                            size={SIZE.mini}
                            onClick={() => {
                              setIsAddNew(true);
                              setEditingData({ type: EDIT, data: [i] });
                            }}
                          >
                            <DairyNoteEdit />
                          </IconButton>
                          <IconButton
                            kind={KIND.tertiary}
                            size={SIZE.mini}
                            onClick={() => {
                              handleModal({
                                title: "You are about to delete a Checkin Data",
                                content:
                                  "This will delete your Checkin Details. Are you sure?",
                                successCallback: () => {
                                  deleteCheck.mutateAsync({
                                    checkinId: i._id,
                                  });
                                },
                                image: WarningImage,
                                buttonText: "Delete",
                                closeButtonText: "Cancel",
                              });
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* IS_EXPAND DATAS ENDS*/}
            </div>
            {/* DATA and LOCATION */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckInCard;
