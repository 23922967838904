export const LETTER_ATTACHMENT = "LETTER_ATTACHMENT";
export const REMOVE_LETTER_ATTACHMENT = "REMOVE_LETTER_ATTACHMENT";
export const LETTER_CUSTOMER_DRIVE_OPEN = "LETTER_CUSTOMER_DRIVE_OPEN";
export const UPDATE_LETTER_ATTACHMENT_FROM_DESKTOP =
  "UPDATE_LETTER_ATTACHMENT_FROM_DESKTOP";
export const ADD_LETTER_ATTACHMENT_FROM_DESKTOP =
  "ADD_LETTER_ATTACHMENT_FROM_DESKTOP";
export const ONSUCCESS_LETTER_STATE = "ONSUCCESS_LETTER_STATE";
export const DOCUMENT_LETTER_DRIVE_DATA = "DOCUMENT_LETTER_DRIVE_DATA";

export const LETTER_DOCUMENT_DRAWER_STATE = "LETTER_DOCUMENT_DRAWER_STATE";
export const LETTER_DOCUMENT_DRAWER_STATE_CLOSE =
  "LETTER_DOCUMENT_DRAWER_STATE_CLOSE";

export const LETTER_UNSELECTED_DOCUMENT_DATA =
  "LETTER_UNSELECTED_DOCUMENT_DATA";

export const unSelectedLetterDocumentData = (data) => {
  return {
    type: LETTER_UNSELECTED_DOCUMENT_DATA,
    payload: data,
  };
};

export const letterDocumentDrawerStateClose = (data) => {
  return {
    type: LETTER_DOCUMENT_DRAWER_STATE_CLOSE,
    payload: data,
  };
};

export const letterDocumentDrawerState = (data) => {
  return {
    type: LETTER_DOCUMENT_DRAWER_STATE,
    payload: data,
  };
};

export const letterDocumentDrive = (data) => {
  return {
    type: DOCUMENT_LETTER_DRIVE_DATA,
    payload: data,
  };
};

export const onSuccessLetterData = (data) => {
  return {
    type: ONSUCCESS_LETTER_STATE,
    payload: data,
  };
};

export const addLetterEmailAttachment = (data) => {
  return {
    type: ADD_LETTER_ATTACHMENT_FROM_DESKTOP,
    payload: data,
  };
};

export const removeAttachmentData = (data) => {
  return {
    type: REMOVE_LETTER_ATTACHMENT,
    payload: data,
  };
};

export const updateLetterAttachmentFromDesktop = (payload) => {
  return {
    type: UPDATE_LETTER_ATTACHMENT_FROM_DESKTOP,
    payload,
  };
};

export const letterCustomerDriveOpen = (data) => {
  return {
    type: LETTER_CUSTOMER_DRIVE_OPEN,
    payload: data,
  };
};
