import React, { useContext, useEffect, useState } from "react";
import {
  Label1,
  Label2,
  Paragraph1,
  ParagraphLarge,
  ParagraphSmall,
} from "baseui/typography";
import { TextButton, SIZE } from "../TextButton";
import { IconButton, KIND } from "../IconButton";
import { Controller, useForm } from "react-hook-form";
import { SelectBox } from "../SelectBox";
import { useIntl } from "react-intl";
import * as ModalButton from "../TextButton";
import {
  getWorkflowlist,
  assignWorkflow,
  getWorkflow,
  getOrganization,
  getCustomerContactDetail,
  assignMulCusWfRel,
  removeKpiData,
} from "../../services";
import getSelectValues from "../../utils/getSelectValues";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import "moment-timezone";
import DateRangeSelect from "../TableFilters/DateRangeFilter/DateRangeSelect";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import CheckBoxBaseweb from "../CheckBoxBaseweb";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import { Typography } from "../../components_v2";

const RemoveKpiForm = ({
  selectedCustomer,
  handleModal,
  toggleAllRowsSelected,
  customerRefetch,
  isAllRowsSelected,
  queryFilter,
}) => {
  let maxyfiContextValue = useContext(MaxyfiContext);

  let {
    currentOrganization,
    referenceData,
    refetch,
    appAccessRest,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      is_all_users: false,
    },
  });

  const removeKpi = useMutation(
    (values) =>
      removeKpiData({
        values,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
          toggleAllRowsSelected(false);
          // customerRefetch();
          handleModal();
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        toggleAllRowsSelected(false);
        reset();
        handleModal();
        customerRefetch();
        refetch({
          ...maxyfiContextValue,
          setLoader: false,
          currentOrganization: currentOrganization,
        });
      },
    }
  );

  const onSubmit = async (data) => {
    let values = {
      users: selectedCustomer,
      is_all_users: false,
    };
    await removeKpi.mutateAsync(values);
  };

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          padding: "0px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography type="h2">Are you sure want to remove the KPI?</Typography>

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "15px 25px",
          }}
        >
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              type="button"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ height: "36px", width: "125px" }}>
            <TextButton
              $style={{
                width: "126px",
                height: "36px",
                borderRadius: "5px",
                border: "1px solid #FD372A",
                color: "#FD372A",
              }}
              type="submit"
              fullWidth
              size={ModalButton.SIZE.compact}
              kind={KIND.secondary}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Remove
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default RemoveKpiForm;
