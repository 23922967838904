import React, { useState } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import ReportDownloadTable from "../../containers/ReportDownload/ReportDownloadTable";
import DashboardTitle from "../../components/DashboardTitle";

const ReportDownload = () => {
  const [addItemModal, setAddItemModal] = useState(false);
  const [refetchingList, setRefetchingList] = useState(1);

  return (
    <Layout>
      <>
        <div className="content-container">
          <div className="content-header">
            <div className="content-header__title">
              <DashboardTitle
                title="report_download"
                queryKey="REPORT_DOWNLOAD_"
              />
            </div>
          </div>
          <div className="content-body">
            <ReportDownloadTable
              addItemModal={addItemModal}
              setAddItemModal={setAddItemModal}
              // setEditingInvoiceItem={setEditingInvoiceItem}
              refetchingList={refetchingList}
              setRefetchingList={setRefetchingList}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};

export default ReportDownload;
