import React, { useContext, useMemo, useState } from "react";
import { Icon, Typography } from "../../../../components_v2";
import { KIND, TextButton } from "../../../../components/TextButton";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import RBACWrapper from "../../../../providers/RBACProvider/RBACWrapper";
import { ADD_REMOVED_DEFENDANTS_LEGAL } from "../../../../providers/RBACProvider/permissionList";
import { CUSTOMER_OVERVIEW_ } from "../../../../constants";
import _ from "lodash";
import { useParams } from "react-router-dom";
import queryClient from "../../../../providers/queryClient";
import useFormat from "../../../../hooks/useFormat";

const DefendantCard = ({ id, handleRemoveDefandant, isEditableDebDpt }) => {
  const { customerId } = useParams();
  const format = useFormat();
  const { referenceData, currentDefaultFormatDetails, customContact } =
    useContext(MaxyfiContext);

  const [expand, setExpand] = useState(false);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const contact = useMemo(() => {
    const contact = _.get(customerData, "data.doc.contacts", []).find(
      (e) => e._id === id
    );

    if (contact) {
      const { first_name = "", last_name = "", relation } = contact;

      const name = `${first_name ? _.capitalize(first_name) : ""}${
        last_name ? ` ${_.capitalize(last_name)}` : ""
      }`;

      return {
        ...contact,
        name,
        relation: relation ? _.capitalize(relation) : null,
        phone: _.get(contact, "phone", []).filter(
          (e) =>
            e.status === "VALID" &&
            (e.call_status === "VALID" || e.sms_status === "VALID")
        ),
        landline: _.get(contact, "landline", []).filter(
          (e) => e.status === "VALID" && e.call_status === "VALID"
        ),
        email: _.get(contact, "email", []).filter(
          (e) => e.status === "VALID" && e.email_status === "VALID"
        ),
        address: _.get(contact, "address", []).filter(
          (e) => e.status === "VALID" && e.address_status === "VALID"
        ),
        contact_custom_fields: _.get(
          currentDefaultFormatDetails,
          "defcf",
          []
        ).reduce((prev, curr) => {
          const customField =
            customContact && Array.isArray(customContact)
              ? customContact.find((e) => e.path === curr)
              : null;

          if (customField) {
            return [...prev, customField];
          }

          return prev;
        }, []),
      };
    }

    return null;
  }, []);

  if (!contact) {
    return null;
  }

  return (
    <div className="defendant-card-container">
      <div className="defendant-card_header">
        <Typography type="h3">{contact.first_name}</Typography>

        <Typography
          type="p"
          subType="regular"
          className="mx-chip mx-chip--error"
        >
          Defendant
        </Typography>
        <div
          style={{
            flex: "1 1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <RBACWrapper role={ADD_REMOVED_DEFENDANTS_LEGAL} type="PERMISSION">
            {isEditableDebDpt &&
            isEditableDebDpt?.idde &&
            _.get(isEditableDebDpt, "Complaint", false) &&
            _.get(isEditableDebDpt, "Supplement", false) ? (
              <></>
            ) : (
              <StatefulPopover
                triggerType={TRIGGER_TYPE.click}
                content={({ close }) => (
                  <div className="co_groping_delete_popover">
                    <div className="co_groping_delete_popover_body">
                      <Icon icon="alert" color="#FD372A" />
                      <Typography type="p">
                        This will remove the Defendants from the Legal, Do you
                        want to continue?
                      </Typography>
                    </div>
                    <div className="co_groping_delete_popover_footer">
                      <TextButton
                        size="mini"
                        kind={KIND.tertiary}
                        type="button"
                        onClick={() => close()}
                      >
                        <Typography type="h5" style={{ color: "#FD372A" }}>
                          Cancel
                        </Typography>
                      </TextButton>
                      <TextButton
                        size="mini"
                        kind={KIND.secondary}
                        onClick={async () => {
                          // let deletedCardId = item?._id;
                          // await handleDeleteCard({ deletedCardId });
                          handleRemoveDefandant();
                          close();
                        }}
                        type="error"
                      >
                        Confirm
                      </TextButton>
                    </div>
                  </div>
                )}
              >
                <div style={{ cursor: "pointer" }}>
                  <Icon icon="delete_outline" />
                </div>
              </StatefulPopover>
            )}
          </RBACWrapper>
        </div>
      </div>
      <Typography type="p" subType="regular">
        {contact.relation}
      </Typography>
      <div className="row gap-5 align-items-center">
        {contact.phone &&
        Array.isArray(contact.phone) &&
        contact.phone.length > 0 ? (
          <>
            <Icon icon="manual_call_outLine" color="#787878" />
            <Typography type="p" subType="regular">
              {contact.phone.map((num, idx) => {
                return (
                  <>
                    {num?.country_code} {num?.value}
                    {contact.phone.length - 1 != idx ? ", " : ""}
                  </>
                );
              })}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="row gap-5 align-items-center">
        {contact.landline &&
        Array.isArray(contact.landline) &&
        contact.landline.length > 0 ? (
          <>
            <Icon icon="landline_outline" color="#787878" />
            <Typography type="p" subType="regular">
              {contact.landline.map((num, idx) => {
                return (
                  <>
                    {num?.country_code} {num?.value}
                    {contact.landline.length - 1 != idx ? ", " : ""}
                  </>
                );
              })}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="row gap-5 align-items-center">
        {contact.email &&
        Array.isArray(contact.email) &&
        contact.email.length > 0 ? (
          <>
            <Icon icon="email_outline" color="#787878" />
            <Typography type="p" subType="regular">
              {contact.email.map((num, idx) => {
                return (
                  <>
                    {num?.value}
                    {contact.email.length - 1 != idx ? ", " : ""}
                  </>
                );
              })}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="row gap-5 align-items-center">
        {contact.address &&
        Array.isArray(contact.address) &&
        contact.address.length > 0 ? (
          <>
            <Icon icon="location_outline" color="#787878" />
            <Typography type="p" subType="regular">
              {contact.address.map((add, idx) => {
                const country =
                  referenceData && referenceData["country"] && add.country
                    ? referenceData["country"].find((e) => e.id === add.country)
                    : null;

                return (
                  <>
                    {add && add.line_1} {add && add.line_2} {add && add.city}{" "}
                    {add && add.state} {country && country.label}
                    {contact.address.length - 1 != idx ? ", " : ""}
                  </>
                );
              })}
            </Typography>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        {contact.contact_custom_fields.length > 0 ? (
          <Typography
            type="p"
            subType="regular"
            onClick={async () => {
              setExpand(!expand);
            }}
            className="text-primary defendant-expand"
          >
            View {expand ? "Less" : "More"}
          </Typography>
        ) : (
          <></>
        )}
      </div>
      {expand ? (
        <>
          <div
            style={{
              border: "1px solid #EEEEEE",
              width: "410px",
              alignItems: "center",
              textAlign: "center",
            }}
          ></div>
          {contact.contact_custom_fields.map((e) => {
            let value = _.get(contact, `custom_field.${e.path}`, "");

            if (e.data_type === "AMOUNT") {
              value = value ? format.currency({ amount: value }) : "";
            }

            if (e.data_type === "DATE") {
              value = value ? format.date({ isOrg: true, value }) : "";
            }

            return (
              <div className="row gap-5 align-items-center">
                <Typography subType="regular" className="text-secondary">
                  {e.name}:
                </Typography>
                <Typography subType="regular">{value}</Typography>
              </div>
            );
          })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DefendantCard;
