import React, { useRef, useContext, useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  HeadingMedium,
  HeadingXSmall,
  LabelMedium,
  LabelSmall,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import {
  Chart,
  Bar,
  Doughnut,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import { LabelLarge } from "baseui/typography";
import { useIntl } from "react-intl";
import { setDashBoardDashboardCustomerActionStage } from "../../redux/customerSummary/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { object } from "yup/lib/locale";
import { Select } from "baseui/select";
import millify from "millify";
import { NumericFormat } from "react-number-format";
import { getOrganizationDashboard } from "../../services/organization";
import { useQuery } from "react-query";
import moment from "moment";
import DatepickerBs from "../../components/Datepicker/DatepickerBs";
import Cancel from "../../assets/img/svg/Template/Cancel";
import CloseIcon from "../../assets/img/svg/Close";
ChartJS.register(ArcElement, Tooltip, Legend);

const ActionHold = ({ setIsPophover }) => {
  // let doughData = { action_on_hold: 0, AUTO: 5, UNREACHABLE: 8 };

  let {
    currentOrganization,
    appAccessRest,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    referenceData,
  } = useContext(MaxyfiContext);
  const [isDate, setIsDate] = useState([]);

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `dashboard-action-hold-${currentOrganization}`,
      {
        filters: {
          ...appAccessRest,
          start_date: isDate[0],
          end_date: isDate[1],
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getOrganizationDashboard({
        organization: currentOrganization,
        entity: "ON_HOLD_REASON",
        filters,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const [value, setValue] = React.useState([
    { label: "Consumer Count", id: "count" },
  ]);
  let doughNutData =
    value && value[0] && value[0].id === "count"
      ? data &&
        data?.data &&
        data?.data?.doc &&
        data?.data?.doc?.hold_reason_count
      : [];

  const navigate = useNavigate();
  let dispatch = useDispatch();

  let rdCustomerStage =
    referenceData && referenceData["customer_stage"]
      ? referenceData["customer_stage"]
      : [];
  let rdCustomerStatusList =
    referenceData && referenceData["customer_status_list"]
      ? referenceData["customer_status_list"]
      : [];

  let combinedRdData = [...rdCustomerStage, ...rdCustomerStatusList];

  const CustomerStageFilterRd = referenceData?.customer_stage_filter;

  const intl = useIntl();

  let newItems = [];
  let Labels = [];
  let Count = [];
  doughNutData &&
    doughNutData.map((e) => {
      if (e._id !== null && e._id !== "") {
        newItems.push(e);
      }
    });

  newItems.sort((a, b) => {
    let fa = a._id,
      fb = b._id;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  newItems.map((e) => {
    Labels.push(e._id);
    Count.push(e.count);
  });

  let dataOne = {
    labels: Labels,
    datasets: [
      {
        label: "",
        data: Count,
        // data: ["chartValue", "HELLO"],
        backgroundColor: [
          "#26727B",
          "#48B24F",
          "#D21F75",
          "#E57438",
          "#569D79",
          "#E4B031",
          "#58595B",
          "#50AED3",
          "#3B3689",
          "#65338D",
          "#591a54",
          "#011a6d",
          "#5a1740",
          "#b42c20",
          "#810703",
          "#106146",
          "#090741",
          "#441225",
          "#8a1f5d",
          "#535a53",
          "#a52313",
          "#402107",
          "#821628",
          "#6e1f92",
          "#5e1267",
          "#305960",
          "#275642",
          "#412952",
          "#411e15",
          "#3c090d",
        ],
      },
    ],
  };

  const chartRef = useRef();
  const onClick = (event) => {
    dispatch(setDashBoardDashboardCustomerActionStage("action_on_hold"));
    navigate(`/customers`);
  };

  const [loading, setLoading] = useState(false);
  const [isSelect, setIsSelect] = React.useState([]);
  const [count, setCount] = useState(0);
  const vale = moment.utc(isDate[1]).format("dddd-mmmm-yyyy");
  const [isActive, setIsActive] = useState("WTD");

  // date filter start here

  const wtd = (x = 0) => {
    let fliterDate = [];
    return (fliterDate = [
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .startOf("D")
        .subtract(x, "days")
        .utc()
        .valueOf(),
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .endOf("day")
        .utc()
        .valueOf(),
    ]);
  };
  const td = (x = 0) => {
    let arrayOfDate = [];
    arrayOfDate = [
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .startOf("D")
        .subtract(x, "months")
        .utc()
        .valueOf(),
      moment
        .tz(currentDefaultFormatDetails.time_zone)
        .endOf("day")
        .utc()
        .valueOf(),
    ];

    return arrayOfDate;
  };

  let WTD = wtd(7);
  let MTD = td(1);
  let QTD = td(3);

  // date filter end here

  let quickSelectOptions = [
    {
      id: "WTD",
      beginDate: WTD,
      isCustom: false,
    },
    {
      id: "MTD",
      beginDate: MTD,
      isCustom: false,
    },
    {
      id: "QTD",
      beginDate: QTD,
      isCustom: false,
    },

    {
      id: "Custom Date",
      beginDate: true,
      isCustom: false,
    },
  ];
  // API Start
  useEffect(() => {
    if (isDate.length == 0) {
      setIsDate(WTD);
    }
  });
  useEffect(() => {
    if (count > 0 && isSelect) {
      let startDate =
        value && value[0]
          ? moment(value && value[0])
              .tz(currentDefaultFormatDetails.time_zone)
              .startOf("day")
              .utc()
              .valueOf()
          : "";
      let endDate =
        value && value[1]
          ? moment(value && value[1])
              .tz(currentDefaultFormatDetails.time_zone)
              .endOf("day")
              .utc()
              .valueOf()
          : "";

      let filterDate = [startDate, endDate];

      setIsDate(filterDate);
    }
  }, [count]);
  return (
    <div className="db-action__table">
      <div className="db-action__table-head" style={{ padding: "0px 20px" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <LabelLarge>Hold Reason Count</LabelLarge>
          </div>

          <div
            style={{
              display: "flex",
              gap: "1px",
            }}
          >
            {isActive == "Custom Date" ? (
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <DatepickerBs
                  range
                  size={SIZE.mini}
                  value={isSelect}
                  setValue={setIsSelect}
                  placeholder="Custom Date"
                  setIsActive={setIsActive}
                  setCount={setCount}
                  count={count}
                  maxDate={new Date()}
                />
                <IconButton
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  onClick={() => {
                    setIsActive("WTD");
                    setIsDate(WTD);
                  }}
                >
                  <Cancel />
                </IconButton>
              </div>
            ) : (
              quickSelectOptions.map((e) => {
                return (
                  <>
                    <div
                      onClick={() => {
                        setIsDate(e.beginDate);
                        setIsActive(e.id);
                      }}
                      className={
                        isActive == e.id ? "filter_active" : "report_filter"
                      }
                    >
                      <LabelSmall>{e.id}</LabelSmall>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "right",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          padding: "8px 19px",
        }}
      >
        <div style={{ width: "160px", textAlign: "right" }}>
          <Select
            size={SIZE.compact}
            clearable={false}
            backspaceRemoves={false}
            overrides={{
              Tag: {
                props: {
                  overrides: {
                    Text: {
                      style: ({ $theme }) => ({
                        maxWidth: "500px",
                      }),
                    },
                  },
                },
              },
              Placeholder: {
                style: ({ $theme, $isFocused }) => ({
                  color: $isFocused ? "#cbd5e0" : "transparent",
                  transition: "color 0.4s ease",
                }),
              },
              ControlContainer: {
                style: ({ $theme, $isFocused, $error }) => ({
                  borderRadius: "5px",
                  backgroundColor: $isFocused ? "#F5F6FA" : "#ffffff",
                  borderRadius: "3px",
                  borderColor: "#CDCED9",
                  borderWidth: "0.5px",
                  borderStyle: "solid",
                  paddingRight: "0px",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  borderWidth: "1px",
                  borderColor: $isFocused
                    ? $error
                      ? "#FD372A"
                      : "#516BEB"
                    : $error
                    ? "#FD372A"
                    : "#CDCED9",
                }),
              },
            }}
            options={[
              { label: "Consumer Count", id: "count" },
              { label: "Outstanding Amount", id: "amount" },
            ]}
            value={value}
            placeholder="Select color"
            onChange={(params) => setValue(params.value)}
          />
        </div>
      </div>
      <div
        style={{
          flex: "1",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "75%",
          height: "315px",
          padding: "10px",
        }}
      >
        <Doughnut
          ref={chartRef}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              legend: {
                position: "right",
              },
            },
          }}
          data={dataOne}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default ActionHold;
