export const SYNC_IS_ACTIVE = "SYNC_IS_ACTIVE";
export const SYNC_INITIAL_SYNC = "SYNC_INITIAL_SYNC";

export const getSycnActive = (isActive) => {
  return {
    type: SYNC_IS_ACTIVE,
    payload: isActive,
  };
};

export const setInitialSync = () => {
  return {
    type: SYNC_INITIAL_SYNC,
  };
};
