import React, { useState, useContext, useEffect } from "react";
import { LabelMedium, ParagraphLarge, ParagraphSmall } from "baseui/typography";
import { IconButton, SIZE, KIND } from "../../IconButton";
import { ParagraphMedium } from "baseui/typography";
import CallBackInfo from "../../../assets/img/svg/Information/CallBackInfo";
import Location from "../../../assets/img/svg/Information/Location";
import moment from "moment";
import { FormattedTime, useIntl } from "react-intl";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { patchCalendar } from "../../../services";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import SingleTick from "../../../assets/img/svg/SingleTick";
import Cancel from "../../../assets/img/svg/Template/Cancel";
import { useDispatch } from "react-redux";
import useFormat from "../../../hooks/useFormat";

const SwitchingCalendarInformation = ({ type, data, user }) => {
  const { referenceData, currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  const format = useFormat();

  switch (type) {
    case "PROMISE_TO_PAY":
    case "PAYMENT_PLAN": {
      let timeSolt = data && data?.time;
      let slicedVal = timeSolt?.replace("_", ":");
      let slicedFinalVal = slicedVal?.replace("_", " ");
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.status === "COMPLETED"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <CallBackInfo />
            </div>
            <div style={{ flex: "1" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {slicedFinalVal ? slicedFinalVal : ""}
                </ParagraphMedium>

                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  <span
                    style={{
                      color: `${
                        data && data?.status === "COMPLETED"
                          ? "#adadad"
                          : "#787878"
                      }`,
                    }}
                  >
                    RM:
                  </span>{" "}
                  {data?.current_rel_manager
                    ? format.rd({
                        name: "users",
                        id: data?.current_rel_manager,
                      })
                    : "-"}
                </ParagraphMedium>
              </div>

              <div style={{ display: "flex", gap: "5px" }}>
                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {type === "PROMISE_TO_PAY" ? "Promise" : "Payment Plan"}{" "}
                  Reminder via{" "}
                  {data && data.from === "FIELD_VISIT"
                    ? "Field Visit"
                    : data && data.from === "ACTION_CAPTURE"
                    ? "Action Capture"
                    : "Call"}
                </ParagraphMedium>
              </div>

              {data && data.comment && data.comment.length > 0 ? (
                <div
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {" "}
                  <span
                    style={{
                      color: `${
                        data && data?.status === "COMPLETED"
                          ? "#adadad"
                          : "#787878"
                      }`,
                    }}
                  >
                    User Comments:
                  </span>{" "}
                  {data.comment}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",

              color: "#787878",
            }}
            className={`${
              data && data?.status === "COMPLETED"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {data && data?.created_by ? `${user}` : "Customer"}@
            <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    }
    case "CALL_BACK_LATER":
      let timeSolt = data && data?.time;
      let slicedVal = timeSolt?.replace("_", ":");
      let slicedFinalVal = slicedVal?.replace("_", " ");
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.status === "COMPLETED"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <CallBackInfo />
            </div>
            <div style={{ flex: "1" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {slicedFinalVal ? slicedFinalVal : ""}
                </ParagraphMedium>

                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  <span
                    style={{
                      color: `${
                        data && data?.status === "COMPLETED"
                          ? "#adadad"
                          : "#787878"
                      }`,
                    }}
                  >
                    RM:
                  </span>{" "}
                  {data?.current_rel_manager
                    ? format.rd({
                        name: "users",
                        id: data?.current_rel_manager,
                      })
                    : "-"}
                </ParagraphMedium>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {data && data.created_by
                    ? `Call Back Captured by ${user}`
                    : "Consumer Requested Call Back"}
                </ParagraphMedium>
              </div>

              <div
                className={`${
                  data && data?.status === "COMPLETED"
                    ? "information-card-unread"
                    : "information-texts"
                }`}
              >
                {" "}
                <span
                  style={{
                    color: `${
                      data && data?.status === "COMPLETED"
                        ? "#adadad"
                        : "#787878"
                    }`,
                  }}
                >
                  {data && data.created_by
                    ? "User Comments:"
                    : "Consumer Comments:"}
                </span>{" "}
                {data.comment}
              </div>
            </div>
          </div>

          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",

              color: "#787878",
            }}
            className={`${
              data && data?.status === "COMPLETED"
                ? "information-card-unread"
                : "information-texts"
            }`}
          >
            {data && data?.created_by ? `${user}` : "Customer"}@
            <span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    case "FIELD_VISIT":
      let findTime = data.time
        ? referenceData["time"].find((e) => e.id === data.time)
        : [];
      return (
        <>
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              className={`${
                data && data?.status === "COMPLETED"
                  ? "info-read"
                  : "info-unread"
              }`}
            >
              <Location />
            </div>
            <div style={{ flex: "1" }}>
              {/* Time */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  {moment
                    .utc(data && data?.date)
                    ?.tz(timeZone)
                    ?.format(currentDefaultFormatDetails.date_format)}{" "}
                  {findTime && findTime.label ? findTime.label : []}
                </ParagraphMedium>

                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  className={`${
                    data && data?.status === "COMPLETED"
                      ? "information-card-unread"
                      : "information-texts"
                  }`}
                >
                  <span
                    style={{
                      color: `${
                        data && data?.status === "COMPLETED"
                          ? "#adadad"
                          : "#787878"
                      }`,
                    }}
                  >
                    RM:
                  </span>{" "}
                  {data?.current_rel_manager
                    ? format.rd({
                        name: "users",
                        id: data?.current_rel_manager,
                      })
                    : "-"}
                </ParagraphMedium>
              </div>

              <div style={{ display: "flex" }}>
                <ParagraphMedium
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {data.header}
                </ParagraphMedium>
                <div> Field Visit Captured by{user}</div>
              </div>
              {data.comment && (
                <ParagraphSmall>
                  {" "}
                  <span style={{ color: "#787878" }}>Comment: </span>{" "}
                  {data.comment}
                </ParagraphSmall>
              )}
            </div>
          </div>

          <ParagraphMedium
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#787878",
            }}
          >
            {user} @<span style={{ paddingRight: "2px" }}></span>
            {moment
              .utc(data && data?.created_at)
              ?.tz(timeZone)
              ?.format(currentDefaultFormatDetails?.date_format)}
            , <FormattedTime value={new Date(data && data?.created_at)} />
          </ParagraphMedium>
        </>
      );
    default:
      return null;
  }
};

const Calendar = ({
  data,
  close,
  isLoading,
  calendarRefetch,
  setCalendarRefetch,
}) => {
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    users,
  } = useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let lateDate = null;

  let isDateTag = false;

  let formattedDate = moment
    .utc(data?.date)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails?.date_format);

  if (formattedDate !== lateDate) {
    isDateTag = true;
    lateDate = formattedDate;
  }

  const updateCalendar = useMutation(
    (data) =>
      patchCalendar({
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data?.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        setCalendarRefetch(calendarRefetch + 1);
      },
    }
  );
  useEffect(() => {
    if (data) {
      let user = "";
      users.filter((e) => {
        if (e.id === data?.created_by) {
          user = e.displayName;
        }
      });
      setUserName(user);
    }
  }, [data]);

  return (
    <>
      {/* CARD STARTS*/}
      <div
        style={{
          padding: "10px 15px",
          background: `${
            data && data?.status === "COMPLETED" ? "#FCFCFC" : "#F5F7FD"
          }`,
          borderTop: "1px solid #eeeeee",
          cursor: "pointer",
        }}
        className="calendar-card"
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ParagraphLarge
            $style={{
              fontWeight: "500",
              width: "90%",
            }}
            className={`${
              data && data?.status === "COMPLETED"
                ? "information-card-unread"
                : ""
            }`}
            onClick={() => {
              navigate(`/customers/${data?.customer_id}`);
              close.close();
            }}
          >
            {data?.customer_name}
          </ParagraphLarge>
          <div className="calendar-btn">
            <IconButton
              size={SIZE.mini}
              kind={KIND.tertiary}
              onClick={async () => {
                let markAsRead = {
                  event_id: data && data?._id,
                  customer_id: data && data?.customer_id,
                };
                await updateCalendar.mutateAsync({ ...markAsRead });
              }}
            >
              {data && data?.status === "COMPLETED" ? (
                <Cancel />
              ) : (
                <i className="mx-icon-Group-16860" />
              )}
            </IconButton>
          </div>
        </div>

        <div
          onClick={() => {
            navigate(`/customers/${data?.customer_id}`);
            close.close();
          }}
        >
          <SwitchingCalendarInformation
            type={data?.type}
            data={data}
            user={userName}
          />
        </div>
      </div>
      {/* CARD ENDS*/}
    </>
  );
};

export default Calendar;
