import React, { useEffect } from "react";
import { Paragraph2 } from "baseui/typography";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  isOpenModal,
  minimizeAllAction,
  minimizeDeleteAction,
  minimizeSMSActionData,
} from "../../redux/actions";
import { Icon, Typography } from "../../components_v2";
import formatRecentTime from "../../utils_v2/FormatTimeInDays";
import useFormat from "../../hooks/useFormat";

function CustomerHyperlink(props) {
  // cell: {
  //   row: { original },
  // },
  const location = useLocation();
  const format = useFormat();
  let dispatch = useDispatch();
  let {
    value,
    column,
    alignment,
    accessor,
    row: { original },
    ...rest
  } = props;

  const { is_active = true } = original;

  value = _.get(original, accessor, "");

  const navigate = useNavigate();

  const [visible, setVisible] = React.useState(false);
  const [addSearchParams, setAddSearchParams] = useSearchParams();
  // const showActiveUser = addSearchParams.get("customers") === `${original._id}`;

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  return (
    <>
      {visible ? (
        <RBACWrapper type="SCALE" enabled={is_active} placement="bottom">
          <StatefulTooltip accessibilityType={"tooltip"} content={value}>
            <Paragraph2
              ref={ref}
              onClick={() => {
                navigate(
                  `/customers/${
                    original?.pgmc ? original?.pgmc : original?._id
                  }`
                );
                dispatch(minimizeAllAction());
                // if (location.pathname == "/customers") {
                //   setAddSearchParams({ customers: `${original?._id}` });
                //   dispatch(isOpenModal(false));
                // } else {
                //   navigate(`/customers?customers=${original?._id}`);
                // }
              }}
              $style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
                width: "100%",
                textAlign: alignment,
                margin: "5px",
                color: "#516beb",
                textDecoration: "underline",
                width: "min-content",
              }}
            >
              <div className="elip" ref={ref}>
                {value}
              </div>
            </Paragraph2>
          </StatefulTooltip>
          {_.get(original, "is_upd_wrn", false) &&
            _.get(original, "updated_at", null) && (
              <StatefulTooltip
                placement={PLACEMENT.bottom}
                showArrow
                overrides={{
                  Arrow: {
                    style: ({ $theme }) => ({
                      outline: `#16164B solid`,
                      backgroundColor: "#16164B",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      outline: `#16164B solid`,
                      backgroundColor: "#16164B",
                    }),
                  },
                }}
                content={() => (
                  <div style={{ backgroundColor: "#16164B" }}>
                    <Typography
                      type="p"
                      subType="regular"
                      style={{ color: "white" }}
                    >
                      Consumer details Updated{" "}
                      <span
                        style={{
                          color: "white",
                          fontWeight: "500",
                        }}
                      >
                        {formatRecentTime(_.get(original, "updated_at", null))}{" "}
                        {_.get(original, "updated_by", "") ? "by" : ""}{" "}
                        {_.get(original, "is_adc_upd", false) &&
                        _.get(original, "updated_by", "")
                          ? `Upload (${format.rd({
                              id: _.get(original, "updated_by", ""),
                              name: "users",
                            })})`
                          : format.rd({
                              id: _.get(original, "updated_by", ""),
                              name: "users",
                            })}
                      </span>
                    </Typography>
                  </div>
                )}
                returnFocus
                autoFocus
              >
                <Icon icon="updated_clock" size={18} color="#787878" />
              </StatefulTooltip>
            )}
          {_.get(original, "ipgm", false) && (
            <Icon
              icon="master_group"
              size={18}
              className="mx-icon--path-fill-primary"
            />
          )}
        </RBACWrapper>
      ) : (
        <RBACWrapper type="SCALE" enabled={is_active} placement="bottom">
          <Paragraph2
            ref={ref}
            onClick={() => {
              navigate(
                `/customers/${original?.pgmc ? original?.pgmc : original?._id}`
              );
              dispatch(minimizeAllAction());
              // if (location.pathname == "/customers") {
              //   setAddSearchParams({ customers: `${original?._id}` });
              //   dispatch(isOpenModal(false));
              // } else {
              //   navigate(`/customers?customers=${original?._id}`);
              // }
            }}
            $style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              cursor: "pointer",
              width: "100%",
              textAlign: alignment,
              margin: "5px",
              color: "#516beb",
              textDecoration: "underline",
              width: "min-content",
            }}
          >
            {value}
          </Paragraph2>
          {_.get(original, "is_upd_wrn", false) &&
            _.get(original, "updated_at", null) && (
              <StatefulTooltip
                placement={PLACEMENT.bottom}
                showArrow
                overrides={{
                  Arrow: {
                    style: ({ $theme }) => ({
                      outline: `#16164B solid`,
                      backgroundColor: "#16164B",
                    }),
                  },
                  Inner: {
                    style: ({ $theme }) => ({
                      outline: `#16164B solid`,
                      backgroundColor: "#16164B",
                    }),
                  },
                }}
                content={() => (
                  <div style={{ backgroundColor: "#16164B" }}>
                    <Typography
                      type="p"
                      subType="regular"
                      style={{ color: "white" }}
                    >
                      Consumer details Updated{" "}
                      <span
                        style={{
                          color: "white",
                          fontWeight: "500",
                        }}
                      >
                        {formatRecentTime(_.get(original, "updated_at", null))}{" "}
                        {_.get(original, "updated_by", "") ? "by" : ""}{" "}
                        {_.get(original, "is_adc_upd", false) &&
                        _.get(original, "updated_by", "")
                          ? `Upload (${format.rd({
                              id: _.get(original, "updated_by", ""),
                              name: "users",
                            })})`
                          : format.rd({
                              id: _.get(original, "updated_by", ""),
                              name: "users",
                            })}
                      </span>
                    </Typography>
                  </div>
                )}
                returnFocus
                autoFocus
              >
                <Icon icon="updated_clock" size={18} color="#787878" />
              </StatefulTooltip>
            )}
          {_.get(original, "ipgm", "") && (
            // <StatefulTooltip
            //   placement={PLACEMENT.bottom}
            //   showArrow
            //   overrides={{
            //     Arrow: {
            //       style: ({ $theme }) => ({
            //         outline: `#16164B solid`,
            //         backgroundColor: "#16164B",
            //       }),
            //     },
            //     Inner: {
            //       style: ({ $theme }) => ({
            //         outline: `#16164B solid`,
            //         backgroundColor: "#16164B",
            //       }),
            //     },
            //   }}
            //   content={() => (
            //     <div style={{ backgroundColor: "#16164B" }}>
            //       <Typography
            //         type="p"
            //         subType="regular"
            //         style={{ color: "white" }}
            //       >
            //         Consumer details Updated{" "}
            //         <span
            //           style={{
            //             color: "white",
            //             fontWeight: "500",
            //           }}
            //         >
            //           {formatRecentTime(_.get(original, "updated_at", null))}{" "}
            //           {_.get(original, "updated_by", "") ? "by" : ""}{" "}
            //           {_.get(original, "is_adc_upd", false) &&
            //           _.get(original, "updated_by", "")
            //             ? `Upload (${format.rd({
            //                 id: _.get(original, "updated_by", ""),
            //                 name: "users",
            //               })})`
            //             : format.rd({
            //                 id: _.get(original, "updated_by", ""),
            //                 name: "users",
            //               })}
            //         </span>
            //       </Typography>
            //     </div>
            //   )}
            //   returnFocus
            //   autoFocus
            // >
            <Icon
              icon="master_group"
              size={18}
              className="mx-icon--path-fill-primary"
            />
            // </StatefulTooltip>
          )}
        </RBACWrapper>
      )}
    </>
  );
}
export default CustomerHyperlink;
