export default function formatRecentTime(value) {
  if (value === null) {
    return "";
  } else {
    const now = new Date();
    const date = new Date(value);
    const diffMs = now - date;
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    if (diffMinutes < 1) {
      return "Just now";
    } else if (diffMinutes < 60) {
      return `${diffMinutes} min ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hr${diffHours > 1 ? "s" : ""} ago`;
    } else if (diffDays < 2) {
      return `Yesterday at ${date.toLocaleTimeString("en-US", options)}`;
    } else if (diffDays < 30 && now.getMonth() === date.getMonth()) {
      const dayOfWeek = date.toLocaleDateString("en-US", {
        weekday: "short",
      });
      return `${dayOfWeek} at ${date.toLocaleTimeString("en-US", options)}`;
    } else {
      const monthDay = date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });
      return monthDay;
    }
  }
}
