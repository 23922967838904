import React from "react";

const WorkflowHeigh = ({ size = 18, color = "#333860" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7502 6.54035L4.56077 14.7298C4.45692 14.8336 4.32639 14.8867 4.16919 14.8891C4.01198 14.8915 3.87905 14.8384 3.7704 14.7298C3.66175 14.6211 3.60742 14.4894 3.60742 14.3346C3.60742 14.1798 3.66175 14.0481 3.7704 13.9394L11.9598 5.74998H7.31267C7.1533 5.74998 7.01972 5.69605 6.9119 5.58819C6.80409 5.48034 6.75018 5.34669 6.75018 5.18724C6.75018 5.0278 6.80409 4.89424 6.9119 4.78655C7.01972 4.67885 7.1533 4.625 7.31267 4.625H13.1973C13.3893 4.625 13.5503 4.68997 13.6803 4.81991C13.8102 4.94983 13.8752 5.11083 13.8752 5.30289V11.1875C13.8752 11.3469 13.8212 11.4805 13.7134 11.5883C13.6055 11.6961 13.4719 11.75 13.3124 11.75C13.153 11.75 13.0194 11.6961 12.9117 11.5883C12.804 11.4805 12.7502 11.3469 12.7502 11.1875V6.54035Z"
        fill={color}
      />
    </svg>
  );
};

export default WorkflowHeigh;
