export const INSTANT_PAYMENT_DRAWER = "INSTANT_PAYMENT_DRAWER";
export const UPDATE_INSTANT_PAYMENT_AMOUNT = "UPDATE_INSTANT_PAYMENT_AMOUNT";

export const instantPaymentDrawer = (data) => {
  return {
    type: INSTANT_PAYMENT_DRAWER,
    payload: data,
  };
};

export const updateInstantPaymentAmount = (data) => {
  return {
    type: UPDATE_INSTANT_PAYMENT_AMOUNT,
    payload: data,
  };
};
