import { NEW } from "../../constants";

export const REFETCH_STRATEGY = "REFETCH_STRATEGY";
export const RESET_STRATEGY = "RESET_STRATEGY";
export const SET_STRATEGY_DETAILS = "SET_STRATEGY_DETAILS";
export const SET_STRATEGY_ACTION_TYPE = "SET_STRATEGY_ACTION_TYPE";
export const SET_STRATEGY_VIEW_ONLY = "SET_STRATEGY_VIEW_ONLY";
export const UPDATE_ST = "UPDATE_ST";
export const UPDATE_SRATEGY_ACTIONS = "UPDATE_SRATEGY_ACTIONS";
export const SAVE_STRATEGY_CURRENT_ACTION = "SAVE_STRATEGY_CURRENT_ACTION";
export const DRAG_AND_DROP_STRATEGY_ACTION = "DRAG_AND_DROP_STRATEGY_ACTION";
export const STRATEGY_INITIAL_STATE = "STRATEGY_INITIAL_STATE";
export const ADD_STRATEGY_NEW_ACTION = "ADD_STRATEGY_NEW_ACTION";
export const DELETE_STRATEGY_ACTION = "DELETE_STRATEGY_ACTION";
export const SET_STRATEGY_CURRENT_ACTION = "SET_STRATEGY_CURRENT_ACTION";
export const SET_STRATEGY_LIST = "SET_STRATEGY_LIST";
export const STRATEGY_FORM_UPDATE_ACTION = "STRATEGY_FORM_UPDATE_ACTION";

export const reftechStrategyMainteinance = () => {
  return {
    type: REFETCH_STRATEGY,
  };
};

export const resetStrategy = () => {
  return {
    type: RESET_STRATEGY,
    payload: null,
  };
};

export const setStrategyGeneralDetails = (generalDetails) => {
  return {
    type: SET_STRATEGY_DETAILS,
    payload: generalDetails,
  };
};

export const setStrategyActionType = (type = NEW) => {
  return {
    type: SET_STRATEGY_ACTION_TYPE,
    payload: type,
  };
};

export const setStrategyViewOnly = (viewOnly = false) => {
  return {
    type: SET_STRATEGY_VIEW_ONLY,
    payload: viewOnly,
  };
};

export const actionStrategy = (data) => {
  return {
    type: UPDATE_SRATEGY_ACTIONS,
    payload: data,
  };
};

export const setStrategylist = (data) => {
  return {
    type: SET_STRATEGY_LIST,
    payload: data,
  };
};

export const saveStrategyCurrentAction = (currentData) => {
  return {
    type: SAVE_STRATEGY_CURRENT_ACTION,
    payload: currentData,
  };
};

export const dragDropStrategyAction = (dragdrop) => {
  return {
    type: DRAG_AND_DROP_STRATEGY_ACTION,
    payload: dragdrop,
  };
};
export const strategyInitialState = (data) => {
  return {
    type: STRATEGY_INITIAL_STATE,
    payload: data,
  };
};
export const addStrategyNewAction = () => {
  return {
    type: ADD_STRATEGY_NEW_ACTION,
    payload: null,
  };
};

export const deleteStrategyAction = (data) => {
  return {
    type: DELETE_STRATEGY_ACTION,
    payload: data,
  };
};
export const setStrategyCurrentAction = (actionData) => {
  return {
    type: SET_STRATEGY_CURRENT_ACTION,
    payload: actionData,
  };
};

export const strategyFormUpdation = (formData) => {
  return {
    type: STRATEGY_FORM_UPDATE_ACTION,
    payload: formData,
  };
};
