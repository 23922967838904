import React from "react";

const SMSOutlineSvg = ({ size = 22, color = "#516BEB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49967 12.8589H12.6726V11.4839H5.49967V12.8589ZM5.49967 9.87969H16.4997V8.50469H5.49967V9.87969ZM5.49967 6.90052H16.4997V5.52552H5.49967V6.90052ZM1.83301 20.1693V3.21094C1.83301 2.85955 1.97051 2.54253 2.24551 2.2599C2.52051 1.97726 2.84134 1.83594 3.20801 1.83594H18.7913C19.1427 1.83594 19.4597 1.97726 19.7424 2.2599C20.025 2.54253 20.1663 2.85955 20.1663 3.21094V15.1276C20.1663 15.479 20.025 15.796 19.7424 16.0786C19.4597 16.3613 19.1427 16.5026 18.7913 16.5026H5.49967L1.83301 20.1693ZM3.20801 16.8464L4.92676 15.1276H18.7913V3.21094H3.20801V16.8464Z"
        fill={color}
      />
    </svg>
  );
};

export default SMSOutlineSvg;
