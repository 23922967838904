import React, { useState, useMemo, useEffect, useContext } from "react";
import ArrowLeft from "../../assets/img/svg/CustomerOverViewIcon/ArrowLeft";
import "../../assets/css/customer-overview.css";
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { useParams } from "react-router-dom";
import InvoiceTimeline from "./InvoiceTimeline/InvoiceTimeline";
import InvoiceActions from "./InvoiceAction/InvoiceAction";
import InvoiceNotes from "./InvoiceNote/InvoiceNote";
import InvoicePayments from "./InvoicePayments/InvoicePayments";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { invoiceTabMotion } from "../../redux/invoiceOverview/action";
import { useSelector, useDispatch } from "react-redux";
import { isOpenModal } from "../../redux/actions";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { INVOICE_WF_ACTION } from "../../providers/RBACProvider/permissionList";
import InvoiceMore from "./InvoiceMore/InvoiceMore";

// Table
const InvoiceOverviewTab = ({ getInvoiceData, getParams, invData }) => {
  // const [activeKey, setActiveKey] = React.useState("1");
  const dispatch = useDispatch();
  const { invoiceDetails } = useSelector((s) => s.invoiceOverviewReducer);
  let { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);

  let findingUser = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  const tabStatus = useSelector(
    (s) => s.invoiceOverviewReducer.invoice_tab_motion
  );

  // useEffect(() => {
  //   if (findingUser.accounting_book === "CSV") {
  //     setActiveKey("1");
  //   } else {
  //     setActiveKey("0");
  //   }
  // }, [findingUser]);

  const invoiceWorkflowActions =
    space && space?.role_permissions?.includes("invoice_wf_action");

  // Table
  return (
    // {/* <div className="customer-profile-tab"> */}
    <Tabs
      fill={FILL.fixed}
      overrides={{
        Root: {
          style: ({ $isActive }) => ({
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }),
        },
        TabBorder: {
          style: ({ $isActive }) => ({
            height: "2px",
          }),
        },
        TabList: {
          style: ({ $theme }) => ({
            height: "50px",
          }),
        },
        TabHighlight: {
          style: ({}) => ({
            borderRadius: "3px",
            backgroundColor: "#516BEB",
          }),
        },
      }}
      // activeKey={tabStatus}
      // onChange={({ activeKey }) => {
      //   // setActiveKey(activeKey);
      //   dispatch(invoiceTabMotion(2))
      // }}

      activeKey={tabStatus}
      onChange={({ activeKey }) => {
        dispatch(isOpenModal(false));
        dispatch(invoiceTabMotion(activeKey));
      }}
      activateOnFocus
    >
      <Tab
        title="Timeline"
        overrides={{
          TabPanel: {
            style: () => ({
              height: "100%",
              borderTop: "2.5px solid #E2E2E2",
            }),
          },
        }}
      >
        <InvoiceTimeline
          getParams={getParams}
          entity={"invoices"}
          customerId={invoiceDetails.customer_id}
        />
      </Tab>
      {/* <RBACWrapper role={INVOICE_WF_ACTION} type="PERMISSION"> */}
      <Tab
        title="Workflow Action"
        overrides={{
          Root: {
            style: () => ({}),
          },
          TabPanel: {
            style: () => ({
              paddingTop: "0px",
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              // height: "100%",
              overflow: "hidden",
            }),
          },
        }}
        disabled={!invoiceWorkflowActions}
      >
        <InvoiceActions
          getInvoiceData={getInvoiceData}
          getParams={getParams}
          invData={invData}
        />
      </Tab>
      {/* </RBACWrapper> */}

      <Tab
        title="Payments"
        overrides={{
          Root: {
            style: () => ({}),
          },
          TabPanel: {
            style: () => ({
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              // height: "100%",
              overflow: "hidden",
            }),
          },
        }}
      >
        <InvoicePayments getParams={getParams} />
      </Tab>
      {/* More Start */}
      <Tab
        title="More Info"
        overrides={{
          Root: {
            style: () => ({}),
          },
          TabPanel: {
            style: () => ({
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              // height: "100%",
              overflow: "hidden",
            }),
          },
        }}
      >
        <InvoiceMore getParams={getParams} />
      </Tab>
      {/* More End */}

      {/* <Tab
        title="Notes"
        overrides={{
          Root: {
            style: () => ({}),
          },
          TabPanel: {
            style: () => ({
              paddingBottom: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              // height: "100%",
              overflow: "hidden",
            }),
          },
        }}
      >
        <InvoiceNotes />
      </Tab> */}
    </Tabs>
  );
};

export default InvoiceOverviewTab;
