import React from "react";

const EmailSvgN = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.833008H2C1.175 0.833008 0.5075 1.58301 0.5075 2.49967L0.5 12.4997C0.5 13.4163 1.175 14.1663 2 14.1663H14C14.825 14.1663 15.5 13.4163 15.5 12.4997V2.49967C15.5 1.58301 14.825 0.833008 14 0.833008ZM13.7 4.37467L8.3975 8.05801C8.1575 8.22467 7.8425 8.22467 7.6025 8.05801L2.3 4.37467C2.1125 4.24134 2 4.01634 2 3.77467C2 3.21634 2.5475 2.88301 2.975 3.17467L8 6.66634L13.025 3.17467C13.4525 2.88301 14 3.21634 14 3.77467C14 4.01634 13.8875 4.24134 13.7 4.37467Z"
        fill="#4B4F72"
      />
    </svg>
  );
};

export default EmailSvgN;
