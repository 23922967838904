function NineDots() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16ZM8 16C6.8954 16 6 15.1046 6 14C6 12.8954 6.8954 12 8 12C9.1046 12 10 12.8954 10 14C10 15.1046 9.1046 16 8 16ZM2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16ZM14 10C12.8954 10 12 9.1046 12 8C12 6.8954 12.8954 6 14 6C15.1046 6 16 6.8954 16 8C16 8.5304 15.7893 9.0391 15.4142 9.4142C15.0391 9.7893 14.5304 10 14 10ZM8 10C6.8954 10 6 9.1046 6 8C6 6.8954 6.8954 6 8 6C9.1046 6 10 6.8954 10 8C10 8.5304 9.7893 9.0391 9.4142 9.4142C9.0391 9.7893 8.5304 10 8 10ZM2 10C0.89543 10 0 9.1046 0 8C0 6.8954 0.89543 6 2 6C3.10457 6 4 6.8954 4 8C4 8.5304 3.78929 9.0391 3.41421 9.4142C3.03914 9.7893 2.53043 10 2 10ZM14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4ZM8 4C6.8954 4 6 3.10457 6 2C6 0.89543 6.8954 0 8 0C9.1046 0 10 0.89543 10 2C10 2.53043 9.7893 3.03914 9.4142 3.41421C9.0391 3.78929 8.5304 4 8 4ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default NineDots;
