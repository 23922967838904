function DisputeAdd() {
  return (
    <>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 2.75C5.1975 2.75 4.95 2.9975 4.95 3.3V4.95H3.3C2.9975 4.95 2.75 5.1975 2.75 5.5C2.75 5.8025 2.9975 6.05 3.3 6.05H4.95V7.7C4.95 8.0025 5.1975 8.25 5.5 8.25C5.8025 8.25 6.05 8.0025 6.05 7.7V6.05H7.7C8.0025 6.05 8.25 5.8025 8.25 5.5C8.25 5.1975 8.0025 4.95 7.7 4.95H6.05V3.3C6.05 2.9975 5.8025 2.75 5.5 2.75ZM5.5 0C2.464 0 0 2.464 0 5.5C0 8.536 2.464 11 5.5 11C8.536 11 11 8.536 11 5.5C11 2.464 8.536 0 5.5 0ZM5.5 9.9C3.0745 9.9 1.1 7.9255 1.1 5.5C1.1 3.0745 3.0745 1.1 5.5 1.1C7.9255 1.1 9.9 3.0745 9.9 5.5C9.9 7.9255 7.9255 9.9 5.5 9.9Z"
          fill="#516BEB"
        />
      </svg>
    </>
  );
}

export default DisputeAdd;
