const dbName = "myDatabase";
const storeName = "referenceStore";
const expiryTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const rdCount = process.env.REACT_APP_STORE_COUNT;

export const openIndexedDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    };

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject("Failed to open IndexedDB");
    };
  });
};

// Get data from IndexedDB by key
export const getFromIndexedDB = (db, key) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readonly");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(key);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = () => {
      reject("Failed to retrieve data from IndexedDB");
    };
  });
};

// Store data in IndexedDB with key
export const saveToIndexedDB = (db, key, data) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const objectStore = transaction.objectStore(storeName);
    const timestampedData = {
      data,
      timestamp: Date.now(), // Save the current timestamp along with the data
      rdCount: rdCount,
    };
    const request = objectStore.put(timestampedData, key);
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = () => {
      reject("Failed to save data to IndexedDB");
    };
  });
};

export const clearFromIndexedDB = (db, key) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([storeName], "readwrite");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.delete(key);
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = () => {
      reject("Failed to clear data from IndexedDB");
    };
  });
};

export const isDataExpired = (timestamp) => {
  return Date.now() - timestamp > expiryTime;
};

export const isRefDataRefeatch = (count) => {
  return count !== rdCount;
};

export const getTimeToResetData = async ({ dbKey = "null" }) => {
  try {
    const db = await openIndexedDB();
    let data = await getFromIndexedDB(db, dbKey);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const resetIndexedDB = async ({ dbKey = "null" }) => {
  try {
    const db = await openIndexedDB();
    await clearFromIndexedDB(db, dbKey);
  } catch (error) {
    console.error(error);
  }
};
