import React from "react";

const Notesvg = ({ width = 20, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7778 2.18182H13.1333C12.6667 0.916364 11.4444 0 10 0C8.55556 0 7.33333 0.916364 6.86667 2.18182H2.22222C1 2.18182 0 3.16364 0 4.36364V21.8182C0 23.0182 1 24 2.22222 24H17.7778C19 24 20 23.0182 20 21.8182V4.36364C20 3.16364 19 2.18182 17.7778 2.18182ZM10 2.18182C10.6111 2.18182 11.1111 2.67273 11.1111 3.27273C11.1111 3.87273 10.6111 4.36364 10 4.36364C9.38889 4.36364 8.88889 3.87273 8.88889 3.27273C8.88889 2.67273 9.38889 2.18182 10 2.18182ZM17.7778 21.8182H2.22222V4.36364H4.44444V7.63636H15.5556V4.36364H17.7778V21.8182Z"
        fill="#516BEB"
      />
    </svg>
  );
};

export default Notesvg;
