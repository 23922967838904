import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  H2,
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import { Label1 } from "baseui/typography";
import { TextBox } from "../../../../components/TextBox";
import { Tag, KIND } from "baseui/tag";
import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { ParagraphLarge } from "baseui/typography";
import { TextButton } from "../../../../components/TextButton";
import GetCallAction from "./GetCallAction";
import moment from "moment";
import { useSelector } from "react-redux";
import { useContext } from "react";
import { StatefulPopover } from "baseui/popover";
import { FormattedDate } from "react-intl";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import {
  CALLBACKLATER,
  DISPUTE,
  MEETINGREQUEST,
  PROMISE,
  UNSUCCESSFUL,
  PLANNED,
  PAY,
  FIELD_VISIT,
} from "../../../../constants";
import CloudCall from "../../../../assets/img/cloudcall.png";
import Dispute from "../../../../assets/img/svg/Dispute";
import PromiseToPay from "../../../../assets/img/svg/PromiseToPay";
import MeetingRequest from "../../../../assets/img/svg/MeetingRequest";
import CallBackLater from "../../../../assets/img/svg/CallBackLater";
import UnSuccessful from "../../../../assets/img/svg/UnSuccessful";
import AlertInfo from "../../../../assets/img/svg/AlertInfo";
import FullScreen from "../../../../assets/img/svg/FullScreen";
import { IconButton } from "../../../../components/IconButton";
import PromiseToPayCall from "../../../../assets/img/svg/PromiseToPayCall";
import { TextArea } from "../../../../components/TextArea";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { makeCall } from "../../../../services";
import { useMutation } from "react-query";
import Planned from "../../../../assets/img/svg/Planned";
import TripleDot from "../../../../assets/img/svg/TripleDot";
import { Button } from "baseui/button";
import AddPaymentSvg from "../../../../assets/img/svg/AddPaymentSvg";
import { StatefulTooltip } from "baseui/tooltip";
import { Checkbox } from "baseui/checkbox";

const CallButtonIcon = ({ type }) => {
  switch (type) {
    case DISPUTE:
      return <Dispute />;
    case PROMISE:
      return <PromiseToPayCall />;
    case MEETINGREQUEST:
      return <MeetingRequest />;
    case UNSUCCESSFUL:
      return <UnSuccessful />;
    case CALLBACKLATER:
      return <CallBackLater />;
    case PLANNED:
      return <Planned />;
    case PAY:
      return <AddPaymentSvg />;
    default:
      return null;
  }
};

const CallForm = ({
  Controller,
  control,
  errors,
  callBackActin,
  hideWorkflowArea,
  contacts,
  templateContent,
  callOutcome,
  setCallOutcome,
  setValue,
  watch,
  currentAction,
  setCallRefDetails,
  settingData,
  recipientsData,
  contactsData,
  setInstallments,
  installments,
  disabled,
  setDisable,
  clearErrors,
  setError,
  isCompute,
  setIsCompute,
  isCancel,
  setIsCancel,
  actionFrom,
}) => {
  const workflowDatas = useSelector(
    (s) => s.customerOverviewReducer.onLoadCustomerActions
  );
  const { total_inv_amount, total_outstanding_invoice_amount } = useSelector(
    (state) => state.customerOverviewReducer.customerDetails
  );
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    userInfo,
    space,
  } = useContext(MaxyfiContext);
  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const [callType, setCallType] = useState(
    currentDefaultFormatDetails.is_web_call_configured ? true : false
  );

  const getParams = getSearchParams.get("customers");

  let userPhoneNumberInfo = userInfo && userInfo.phone && userInfo.phone.value;

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let filteringActionStatus = workflowDatas.filter(
    (i) => i.action_status === "ACTIVE"
  );

  const invoiceCurrency = useSelector(
    (s) =>
      s &&
      s.customerOverviewReducer &&
      s.customerOverviewReducer.customerDetails &&
      s.customerOverviewReducer.customerDetails?.invoice_currencies
  );

  let currentActioDate =
    filteringActionStatus &&
    filteringActionStatus[0] &&
    filteringActionStatus[0].planned_on;

  let dayDiff = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.diff(moment.tz(timeZone), "days", true);

  dayDiff = Math.ceil(dayDiff);

  let today = moment().endOf("d").utc().valueOf();

  let dayDifference = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  const intl = useIntl();
  const actioWatch = watch([
    "is_send_confirmation_message",
    "is_send_action_follow_up",
    "is_send_klq",
    "is_send_meet_1rq",
    "promise_type",
    "recipients",
  ]);

  const recipients = watch("recipients");
  let recipientsId = recipients && recipients[0] && recipients[0].id;

  const makingCall = useMutation(
    (data) =>
      makeCall({
        organization: currentOrganization,
        customerId: getParams,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (data && data.data && data.data.doc && data.data.doc.redirect_url) {
          window.open(
            data.data.doc.redirect_url,
            "popUpWindow",
            "height=500,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
          );
        }
      },
    }
  );

  let currentRecipientNumber = recipients && recipients[0] && recipients[0].id;

  useEffect(() => {
    if (contacts && Array.isArray(contacts)) {
      let validRecipients = contacts.reduce((prev, curr) => {
        let { phone, landline, email, address, ...rest } = curr;
        let nestedContacts = [...phone, ...(landline ? landline : [])]
          .map(({ status, call_status, ...nRest }) => {
            return {
              id: nRest?._id,
              recipient_id: curr?._id,
              call_status,
              status,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.call_status === "VALID"
          );

        return [...prev, ...nestedContacts];
      }, []);

      setValue(
        "recipients",
        validRecipients && validRecipients[0] ? [validRecipients[0]] : []
      );
    }
  }, [recipientsData]);

  // const [isCancel, setIsCancel] = useState(false);

  let PP = customerDetails?.promise_to_pay.some(
    (e) => e.status == "IN_PROGRESS"
  );

  useEffect(() => {
    if (PP) {
      setIsCancel(true);
    }
  }, [customerDetails?.promise_to_pay]);

  return (
    <div style={{ padding: "0px 20px 0px 40px", height: "33vh" }}>
      <div className="cp-action-title--content ">
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <H2>
            <FormattedMessage id="capture_call_details">
              capture_call_details
            </FormattedMessage>
          </H2>
          {dayDiff < 1 ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  border: "1.5px solid #FD372A",
                  borderRadius: "5px",
                  backgroundColor: "#FAF0EF",
                }}
              >
                <ParagraphMedium
                  style={{ color: "#FD372A", padding: "5px 10px" }}
                >
                  Future Action - Scheduled for <span>{dayDifference}</span>
                </ParagraphMedium>
              </div>
            </>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <StatefulTooltip
            accessibilityType={"tooltip"}
            content={
              <ParagraphSmall width="250px">{templateContent}</ParagraphSmall>
            }
          >
            <i className="mx-icon-info" />
          </StatefulTooltip>
          <IconButton type="button" onClick={() => hideWorkflowArea()}>
            <FullScreen />
          </IconButton>
        </div>
      </div>

      <div className="cp-action-reciever-content">
        <div className="cp-action-select" style={{ paddingRight: "20px" }}>
          <Controller
            name="recipients"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                size={SIZE.mini}
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                requiredAstric={true}
                label={intl.formatMessage({
                  id: "reciever",
                })}
                placeholder={intl.formatMessage({
                  id: "reciever_place",
                })}
                clearable={false}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={
                  contacts && Array.isArray(contacts)
                    ? contacts.reduce((prev, curr) => {
                        let { phone, landline, email, address, ...rest } = curr;
                        let nestedContacts = [
                          ...phone,
                          ...(landline ? landline : []),
                        ]
                          .map(({ status, call_status, ...nRest }) => {
                            let designation =
                              referenceData && referenceData["recipient_type"]
                                ? referenceData["recipient_type"].find(
                                    (rf) => rf?.id === curr?.designation
                                  )
                                : "";

                            return {
                              label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
                              id: nRest?._id,
                              recipient_id: curr?._id,
                              call_status,
                              status,
                            };
                          })
                          .filter(
                            (e) =>
                              (e.status === "VALID" || e.status === "LEAD") &&
                              e.call_status === "VALID"
                          );

                        return [...prev, ...nestedContacts];
                      }, [])
                    : []
                }
              />
            )}
          />
        </div>
        <div style={{ display: "flex", gap: "5px" }}>
          {currentDefaultFormatDetails.is_cloud_telephony_configured &&
          currentDefaultFormatDetails.cloud_telephony_phone_numbers &&
          Array.isArray(
            currentDefaultFormatDetails.cloud_telephony_phone_numbers
          ) &&
          currentDefaultFormatDetails.cloud_telephony_phone_numbers.length ? (
            <>
              {currentDefaultFormatDetails.is_web_call_configured ? (
                <div
                  style={{
                    width: "100px",
                    paddingTop: "2px",
                  }}
                >
                  <Checkbox
                    checked={callType}
                    onChange={() => setCallType(!callType)}
                  >
                    Web Call
                  </Checkbox>
                </div>
              ) : (
                <></>
              )}
              {currentDefaultFormatDetails.cloud_telephony_phone_numbers
                .slice(0, 1)
                .map((e) => (
                  <div>
                    <TextButton
                      size={SIZE.mini}
                      kind={KIND.primary}
                      type="button"
                      disabled={
                        !userPhoneNumberInfo ||
                        !currentRecipientNumber ||
                        makingCall.isLoading
                      }
                      isLoading={makingCall.isLoading}
                      onClick={async () => {
                        await makingCall
                          .mutateAsync({
                            recipient: {
                              id: recipients?.[0]?.id,
                              recipient_id: recipients?.[0]?.recipient_id,
                            },
                            source_number: e,
                            call_type: callType ? "WEB_CALL" : "CALL",
                          })
                          .then((res) =>
                            setCallRefDetails({
                              ref: res && res.data && res.data.doc.call_ref,
                              provider:
                                res && res.data && res.data.doc.provider,
                            })
                          );
                      }}
                    >
                      {e}
                    </TextButton>
                  </div>
                ))}

              {currentDefaultFormatDetails.cloud_telephony_phone_numbers
                .length > 1 ? (
                <>
                  <StatefulPopover
                    overrides={{
                      Body: {
                        style: ({ $theme }) => ({
                          borderRadius: "5px",
                          overflow: "hidden",
                          backgroundColor: "#ffffff",
                        }),
                      },
                    }}
                    placement="bottomRight"
                    content={({ close }) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px",

                          backgroundColor: "#ffffff",
                        }}
                      >
                        {currentDefaultFormatDetails.cloud_telephony_phone_numbers
                          .slice(
                            1,
                            currentDefaultFormatDetails
                              .cloud_telephony_phone_numbers.length
                          )
                          .map((e, i) => (
                            <div
                              style={{
                                cursor: "pointer",
                                paddingTop: i === 0 ? "0px" : "5px",
                                paddingBottom:
                                  i + 2 ===
                                  currentDefaultFormatDetails
                                    .cloud_telephony_phone_numbers.length
                                    ? "0px"
                                    : "5px",
                                borderBottom:
                                  i + 2 ===
                                  currentDefaultFormatDetails
                                    .cloud_telephony_phone_numbers.length
                                    ? "none"
                                    : "1px solid #e3e3e3",
                              }}
                              onClick={async () => {
                                close();
                                await makingCall
                                  .mutateAsync({
                                    recipient: {
                                      id: recipients?.[0]?.id,
                                      recipient_id:
                                        recipients?.[0]?.recipient_id,
                                    },
                                    source_number: e,
                                    call_type: callType ? "WEB_CALL" : "CALL",
                                  })
                                  .then((res) =>
                                    setCallRefDetails({
                                      ref:
                                        res &&
                                        res.data &&
                                        res.data.doc.call_ref,
                                      provider:
                                        res &&
                                        res.data &&
                                        res.data.doc.provider,
                                    })
                                  );
                              }}
                            >
                              {e}
                            </div>
                          ))}
                      </div>
                    )}
                    returnFocus
                    autoFocus
                  >
                    <div
                      disabled={
                        !userPhoneNumberInfo ||
                        !currentRecipientNumber ||
                        makingCall.isLoading
                      }
                    >
                      <Button
                        type="button"
                        size={SIZE.mini}
                        kind="secondary"
                        disabled={
                          !userPhoneNumberInfo ||
                          !currentRecipientNumber ||
                          makingCall.isLoading
                        }
                      >
                        <TripleDot />
                      </Button>
                    </div>
                  </StatefulPopover>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="cp-action-outcome">
        <Label1 style={{ marginBottom: "10px" }}>
          <FormattedMessage id="call_outcome">call_outcome</FormattedMessage>
        </Label1>
        <div className="cp-action-outcome-content">
          {[
            {
              title: "PTP",
              outcomeType: "PROMISE_TO_PAY",
              type: PROMISE,
              isEnabled: currentAction ? currentAction.is_promise_to_pay : true,
            },
            {
              title: "CBL",
              outcomeType: "CALL_BACK_LATER",
              type: CALLBACKLATER,
              isEnabled: currentAction ? currentAction.is_callback_later : true,
            },
            // {
            //   outcomeType: "MEETING_REQUEST",
            //   type: MEETINGREQUEST,
            // },
            {
              title: "DIS",
              outcomeType: "RECORD_DISPUTE",
              type: DISPUTE,
              isEnabled: currentAction ? currentAction.is_dispute : true,
            },
            {
              title: "CL-Fail",
              outcomeType: "UNSUCCESSFUL",
              type: UNSUCCESSFUL,
              isEnabled: true,
            },
            {
              title: "PPL",
              outcomeType: "PAYMENT_PLAN",
              type: PLANNED,
              isEnabled: currentAction ? currentAction.is_payment_plan : true,
            },
            {
              title: "FV",
              outcomeType: "FIELD_VISIT",
              type: FIELD_VISIT,
              isEnabled: currentAction ? currentAction.is_field_visit : true,
            },
            {
              title: "Pay",
              outcomeType: "PAYMENT",
              type: PAY,
              isEnabled: currentAction ? currentAction.is_payment : true,
            },
          ]
            .filter((e) => e.isEnabled)
            .map((e) => (
              <>
                <div
                  onClick={() => setCallOutcome(e.outcomeType)}
                  className={
                    callOutcome == e.outcomeType &&
                    e.outcomeType === "UNSUCCESSFUL"
                      ? "cp-action-call-outcome--active-red"
                      : callOutcome === e.outcomeType
                      ? "cp-action-call-outcome--active"
                      : "cp-action-call-outcome"
                  }
                >
                  <CallButtonIcon type={e.type} />{" "}
                  {/* <img src={e.image} style={{ marginRight: "5px" }} alt="" /> */}
                  <LabelMedium $style={{ marginLeft: "5px" }}>
                    <FormattedMessage id={e.title}>{e.title}</FormattedMessage>
                  </LabelMedium>
                </div>
              </>
            ))}
        </div>
      </div>

      <GetCallAction
        setIsCancel={setIsCancel}
        isCancel={isCancel}
        actioWatch={actioWatch}
        watch={watch}
        callOutcome={callOutcome}
        control={control}
        errors={errors}
        intl={intl}
        Controller={Controller}
        callBackActin={callBackActin}
        setValue={setValue}
        settingData={settingData}
        total_outstanding_invoice_amount={total_outstanding_invoice_amount}
        setInstallments={setInstallments}
        setDisable={setDisable}
        installments={installments}
        disabled={disabled}
        clearErrors={clearErrors}
        setError={setError}
        setIsCompute={setIsCompute}
        isCompute={isCompute}
        contactsData={contacts}
        recipients={recipients && recipients[0]}
        actionFrom={actionFrom}
      />
    </div>
  );
};

export default CallForm;
