function HoldActionTimeLineCard({ height = 20, width = 18 }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5284 20H8.84378C7.90646 20 6.9865 19.6248 6.33558 18.9746L0 12.6303L1.79653 11.1046C2.33462 10.6461 3.1244 10.5544 3.75796 10.8795L5.84957 11.9466V3.98499C5.84957 2.83451 6.8216 1.90079 8.01929 1.90079C8.16683 1.90079 8.31437 1.91747 8.46191 1.94248C8.54002 0.858691 9.47734 0 10.623 0C11.3693 0 12.0203 0.358483 12.4108 0.908712C12.6625 0.80867 12.9402 0.758649 13.2266 0.758649C14.4243 0.758649 15.3963 1.69237 15.3963 2.84285V3.07628C15.5352 3.05127 15.6827 3.0346 15.8303 3.0346C17.028 3.0346 18 3.96832 18 5.1188V16.6653C18 18.5077 16.4465 20 14.5284 20ZM2.49952 12.7303L7.58534 17.8158C7.91514 18.1409 8.36644 18.3326 8.8351 18.3326H14.5284C15.4831 18.3326 16.2642 17.5823 16.2642 16.6653V5.1188C16.2642 4.88537 16.0733 4.70196 15.8303 4.70196C15.5873 4.70196 15.3963 4.88537 15.3963 5.1188V9.99583H13.6606V2.84285C13.6606 2.60942 13.4696 2.42601 13.2266 2.42601C12.9836 2.42601 12.7927 2.60942 12.7927 2.84285V9.99583H11.0569V2.0842C11.0569 1.85077 10.866 1.66736 10.623 1.66736C10.3799 1.66736 10.189 1.85077 10.189 2.0842V9.99583H8.45323V3.98499C8.45323 3.75156 8.26229 3.56815 8.01929 3.56815C7.77628 3.56815 7.58534 3.7599 7.58534 3.98499V14.7145L2.94214 12.3551L2.49952 12.7303Z"
          fill="#FD372A"
        />
      </svg>
    </>
  );
}

export default HoldActionTimeLineCard;
