import React from "react";
import { Button, KIND } from "baseui/button";

function TextButton({
  children,
  kind = KIND.primary,
  fullWidth = false,
  type = "",
  ...props
}) {
  return (
    <Button
      className={`icon-button ${kind}`}
      $style={(styleProps) => {
        const { $kind, $theme, $disabled } = styleProps;

        switch ($kind) {
          case KIND.primary:
            return {
              // color: $theme.colors.buttonPrimarfill,
              borderRadius: "5px",
              boxShadow: $disabled ? "" : "0px 4px 4px 0px #516BEB25",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                boxShadow: $disabled ? "" : "inset 0px 4px 4px 0px #00000025",
                color: $theme.colors.buttonPrimarHover,
              },
            };

          case KIND.secondary:
            return {
              color: type === "error" ? "#FD372A" : $theme.colors.primary,
              borderRadius: "5px",
              border:
                type === "error" ? "1px solid #FD372A" : "1px solid #516BEB",
              boxShadow: $disabled ? "" : "0px 4px 4px 0px #eeeeee25",
              backgroundColor: "#ffffff00",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                // boxShadow: "inset 0px 4px 4px 0px #00000025",
                backgroundColor: $theme.colors.buttonSecondaryHover,
              },
            };
          case KIND.tertiary:
            return {
              color: $theme.colors.buttonTertiaryText,
              borderRadius: "5px",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                // boxShadow: "inset 0px 4px 4px 0px #00000025",
                // backgroundColor: "#516BEB",
                background: $theme.colors.buttonTertiaryHover,
                color: $theme.colors.buttonTertiaryTextHover,
              },
            };
          case KIND.minimal:
            return {
              color: type === "error" ? "#FD372A" : "#ADADAD",
              borderRadius: "5px",
              border:
                type === "error" ? "1px solid #FD372A" : "1px solid #CDCED9",
              // boxShadow: $disabled ? "" : "0px 4px 4px 0px #eeeeee25",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                background: "none",
                border: "1px solid #516BEB",
                color: "#516BEB",
                // background: $theme.colors.buttonTertiaryHover,
                // color: $theme.colors.buttonTertiaryTextHover,
              },
            };
          case KIND.errors:
            return {
              color: $theme.colors.buttonTertiaryText,
              borderRadius: "5px",
              width: fullWidth ? "100%" : "auto",
              ":hover": {
                background: "#FFDFDC",
                // color: $theme.colors.buttonTertiaryTextHover,
              },
            };
          default:
            return {
              backgroundColor: "#eeeeee",
              borderRadius: "5px",
              boxShadow: $disabled ? "" : "0px 4px 4px 0px #eeeeee25",
              ":hover": {
                boxShadow: $disabled ? "" : "inset 0px 4px 4px 0px #00000025",
                backgroundColor: "#516BEB",
              },
            };
        }
      }}
      {...props}
      kind={kind}
      fullWidth={fullWidth}
      type={type}
    >
      {children}
    </Button>
  );
}

export default TextButton;
