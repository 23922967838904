const AllFiltersIcon = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.08333 11.5H9.91667C10.4208 11.5 10.8333 11.0875 10.8333 10.5833C10.8333 10.0792 10.4208 9.66667 9.91667 9.66667H8.08333C7.57917 9.66667 7.16667 10.0792 7.16667 10.5833C7.16667 11.0875 7.57917 11.5 8.08333 11.5ZM0.75 1.41667C0.75 1.92083 1.1625 2.33333 1.66667 2.33333H16.3333C16.8375 2.33333 17.25 1.92083 17.25 1.41667C17.25 0.9125 16.8375 0.5 16.3333 0.5H1.66667C1.1625 0.5 0.75 0.9125 0.75 1.41667ZM4.41667 6.91667H13.5833C14.0875 6.91667 14.5 6.50417 14.5 6C14.5 5.49583 14.0875 5.08333 13.5833 5.08333H4.41667C3.9125 5.08333 3.5 5.49583 3.5 6C3.5 6.50417 3.9125 6.91667 4.41667 6.91667Z"
        fill="#333860"
      />
    </svg>
  );
};

export default AllFiltersIcon;
