import React from "react";
import { ParagraphMedium } from "baseui/typography";
import ActionRequired from "../../../assets/img/svg/ActionRequiredTick";
import _ from "lodash";
import { Icon } from "../../../components_v2";

const StatusIndicator = (props) => {
  let { value, column, alignment, accessor, suffix, row, ...rest } = props;
  value = _.get(row.original, accessor, "");

  if (value === "DATA_UPLOADED") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon icon="dot" size={8} color="#0FB158" />
        <ParagraphMedium $style={{ color: "#333860" }}>
          Data Uploaded
        </ParagraphMedium>
      </div>
    );
  } else if (value === "VERIFY_DELETION") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon icon="dot" size={8} color="#FD372A" />
        <ParagraphMedium $style={{ color: "#333860" }}>
          Verify Deletion
        </ParagraphMedium>
      </div>
    );
  } else if (value === "DATA_DELETED") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon icon="dot" size={8} color="#FD372A" />
        <ParagraphMedium $style={{ color: "#333860" }}>
          Data Deleted
        </ParagraphMedium>
      </div>
    );
  } else if (value === "FAILED_VIRUS_SCAN") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            height: "7px",
            width: "7px",
            borderRadius: "50%",
            backgroundColor: "#FD372A",
          }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          Failed - Virus Scan
        </ParagraphMedium>
      </div>
    );
  } else if (value === "UPLOAD_CANCELLED") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            height: "7px",
            width: "7px",
            borderRadius: "50%",
            backgroundColor: "#FBBD04",
          }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          Upload Cancelled
        </ParagraphMedium>
      </div>
    );
  } else if (value === "ACTION_REQUIRED") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div className="animate-circle"></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          Action Required
        </ParagraphMedium>
      </div>
    );
  } else if (value === "IN_PROGRESS") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            height: "7px",
            width: "7px",
            borderRadius: "50%",
            backgroundColor: "#FBBD04",
          }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          In Progress
        </ParagraphMedium>
      </div>
    );
  } else if (
    row &&
    row.original &&
    row.original.is_client_upload &&
    value === "SUCCESS_VIRUS_SCAN"
  ) {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          className="animate-circle"
          // style={{
          //   height: "7px",
          //   width: "7px",
          //   borderRadius: "50%",
          //   backgroundColor: "#0FB158",
          // }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          File Mapping Pending
        </ParagraphMedium>
      </div>
    );
  } else if (value === "ABORTED") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            height: "7px",
            width: "7px",
            borderRadius: "50%",
            backgroundColor: "#FD372A",
          }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>Aborted</ParagraphMedium>
      </div>
    );
  } else if (value === "VALIDATION_FAILED") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            height: "7px",
            width: "7px",
            borderRadius: "50%",
            backgroundColor: "#FD372A",
          }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          Validation Failed
        </ParagraphMedium>
      </div>
    );
  } else if (value === "UPLOAD_INPROGRESS") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            height: "7px",
            width: "7px",
            borderRadius: "50%",
            backgroundColor: "#FBBD04",
          }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          Upload In Progress
        </ParagraphMedium>
      </div>
    );
  } else if (value === "UPLOAD_FAILED") {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <div
          style={{
            height: "7px",
            width: "7px",
            borderRadius: "50%",
            backgroundColor: "#FD372A",
          }}
        ></div>{" "}
        <ParagraphMedium $style={{ color: "#333860" }}>
          Upload Failed
        </ParagraphMedium>
      </div>
    );
  } else {
    return null;
  }
};

export default StatusIndicator;
