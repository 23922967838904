const SmsInfo = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="32"
        viewBox="0 96 960 960"
        width="32"
      >
        <path d="M306 533q17 0 28.5-11.5T346 493q0-17-11.5-28.5T306 453q-17 0-28.5 11.5T266 493q0 17 11.5 28.5T306 533Zm177 0q17 0 28.5-11.5T523 493q0-17-11.5-28.5T483 453q-17 0-28.5 11.5T443 493q0 17 11.5 28.5T483 533Zm170 0q17 0 28.5-11.5T693 493q0-17-11.5-28.5T653 453q-17 0-28.5 11.5T613 493q0 17 11.5 28.5T653 533ZM80 904V236q0-23 18-41.5t42-18.5h680q23 0 41.5 18.5T880 236v520q0 23-18.5 41.5T820 816H240L131 925q-14 14-32.5 6.344T80 904Zm60-73 75-75h605V236H140v595Zm0-595v595-595Z" />
      </svg>
    </>
  );
};

export default SmsInfo;
