import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import { ParagraphMedium, LabelSmall } from "baseui/typography";
import TitleHead from "../../../components_v2/TitleHead";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";

const LetterAudit_V2 = () => {
  let intl = useIntl();

  const { data } = useSelector((s) => s.customerOverviewDrawer);

  const { referenceData, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  return (
    <div style={{ width: "500px" }}>
      <TitleHead title="Audit" />

      {data &&
        data?.audit &&
        data?.audit?.map((apIn) => {
          let { data, ...rest } = apIn;
          let ap = { ...rest, ...data };

          let normalizedObject = Object.keys(ap).reduce((prev, curr) => {
            let result = { ...prev };

            if (Array.isArray(ap[curr])) {
              let innerObject = {};

              ap[curr].map((cr) => {
                if (cr.label && cr.value) {
                  innerObject[cr.label] = cr.value;
                }
              });

              result = { ...prev, ...innerObject };

              return result;
            } else if (typeof ap[curr] === "object") {
              result = { ...prev, ...ap[curr] };
            } else {
              result[curr] = ap[curr];
            }

            return result;
          }, {});

          return (
            <>
              {Object.keys(normalizedObject).map((e, i) => {
                let resultLabel = "";
                let resultValue = "";

                let referenceKeys = [
                  { key: "audit_by", rdName: "users" },
                  {
                    key: "delivery_status",
                    rdName: "letter_delivery_status",
                  },
                  { key: "payment_mode", rdName: "payment_mode" },
                  {
                    key: "delivery_method",
                    rdName: "letter_delivery_method_list",
                  },
                ];

                let exculdeKeys = ["_id", "logo"];
                let dateKeys = ["audit_on"];

                if (exculdeKeys.includes(e)) {
                  return null;
                }

                if (Array.isArray(normalizedObject[e])) {
                  return null;
                }

                if (referenceKeys.find((rk) => rk.key === e)) {
                  let refKey = referenceKeys.find((rk) => rk.key === e);
                  let rdData = referenceData[refKey.rdName]
                    ? referenceData[refKey.rdName].find(
                        (rd) => rd.id === normalizedObject[e]
                      )
                    : null;

                  if (rdData && rdData.id) {
                    resultLabel = e;
                    resultValue = rdData.label;
                  }
                } else {
                  resultLabel = e;
                  resultValue = normalizedObject[e];
                }

                if (dateKeys.includes(e)) {
                  resultValue =
                    moment
                      .utc(resultValue)
                      .format(currentDefaultFormatDetails.date_format) +
                    " " +
                    intl.formatTime(resultValue);
                }

                return (
                  <div>
                    <div className="payment_audit_dtl">
                      <ParagraphMedium $style={{ flex: "1", color: "#ADADAD" }}>
                        {resultLabel && (
                          <FormattedMessage id={resultLabel}>
                            {resultLabel}
                          </FormattedMessage>
                        )}
                      </ParagraphMedium>
                      <LabelSmall
                        $style={{
                          flex: "2",
                        }}
                      >
                        {resultValue}
                      </LabelSmall>
                    </div>
                  </div>
                );
              })}{" "}
              <hr style={{ marginTop: "10px" }} />{" "}
            </>
          );
        })}
    </div>
  );
};

export default LetterAudit_V2;
