import React from "react";

const Delete = ({ size = 18, color = "#000000" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.7108 14.8628C5.32566 14.8628 4.99649 14.7413 4.72327 14.4983C4.45005 14.2553 4.31343 13.9626 4.31343 13.6201V4.49614H4.0859C3.92714 4.49614 3.79167 4.44623 3.6795 4.34642C3.56732 4.24661 3.51123 4.12665 3.51123 3.98656C3.51123 3.84648 3.56732 3.72662 3.6795 3.62696C3.79167 3.52732 3.92714 3.47749 4.0859 3.47749H7.07979V3.46839C7.07979 3.30363 7.14697 3.16151 7.28133 3.04202C7.41568 2.92254 7.57681 2.86279 7.76471 2.86279H11.2617C11.4469 2.86279 11.6068 2.92254 11.7411 3.04202C11.8755 3.16151 11.9426 3.30363 11.9426 3.46839V3.47749H14.9422C15.0972 3.47749 15.2308 3.52749 15.343 3.62747C15.4551 3.72745 15.5112 3.84673 15.5112 3.98531C15.5112 4.12656 15.455 4.24702 15.3427 4.34667C15.2303 4.44632 15.0962 4.49614 14.9405 4.49614H14.709V13.6201C14.709 13.9626 14.5724 14.2553 14.2992 14.4983C14.026 14.7413 13.6968 14.8628 13.3117 14.8628H5.7108ZM13.5636 4.49614H5.45885V13.6201C5.45885 13.6854 5.48247 13.7391 5.52971 13.7811C5.57696 13.8231 5.63732 13.8441 5.7108 13.8441H13.3117C13.3851 13.8441 13.4455 13.8231 13.4927 13.7811C13.54 13.7391 13.5636 13.6854 13.5636 13.6201V4.49614ZM8.03306 12.4082C8.18944 12.4082 8.32393 12.3589 8.43655 12.2603C8.54918 12.1617 8.6055 12.0418 8.6055 11.9006V6.43967C8.6055 6.29847 8.54928 6.17799 8.43684 6.07823C8.32441 5.97846 8.19029 5.92858 8.03447 5.92858C7.87563 5.92858 7.74019 5.97846 7.62815 6.07823C7.51609 6.17799 7.46006 6.29847 7.46006 6.43967V11.9006C7.46006 12.0418 7.51646 12.1617 7.62927 12.2603C7.74208 12.3589 7.87668 12.4082 8.03306 12.4082ZM10.99 12.4082C11.1463 12.4082 11.2808 12.3589 11.3935 12.2603C11.5061 12.1617 11.5624 12.0418 11.5624 11.9006V6.43967C11.5624 6.29847 11.5062 6.17799 11.3937 6.07823C11.2813 5.97846 11.1472 5.92858 10.9914 5.92858C10.8325 5.92858 10.6971 5.97846 10.585 6.07823C10.473 6.17799 10.417 6.29847 10.417 6.43967V11.9006C10.417 12.0418 10.4734 12.1617 10.5862 12.2603C10.699 12.3589 10.8336 12.4082 10.99 12.4082Z"
        fill={color}
      />
    </svg>
  );
};

export default Delete;
