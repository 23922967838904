import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _, { cond } from "lodash";

import DateComponent from "../../../components_v2/DateComponent";
import { Typography, Icon } from "../../../components_v2";
import {
  ACTIVE,
  CUSTOMER_OVERVIEW_,
  DATE_ORG,
  IN_QUEUE,
} from "../../../constants";
import { FormattedMessage } from "react-intl";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { useParams } from "react-router-dom";
import queryClient from "../../../providers/queryClient";
import moment from "moment";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import SkipAction from "../SkipAction";
import { ModalContext } from "../../../providers/ModalProvider";
import { setDrawerState } from "../../../redux/actions";

const status = {
  SKIPPED: "skip_action",
  EXECUTED: "tick",
};

const ActionHeader = ({
  name = "",
  icon = "",
  is_inbound_call = false,
  inbound = false,
  setState = () => {},
}) => {
  const { customerId } = useParams();
  const { handleModal } = useContext(ModalContext);
  let dispatch = useDispatch();

  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const [expand, setExpand] = useState(false);
  const [all, setAll] = useState([]);
  const drawerData = useSelector((state) => state.customerOverviewDrawer);

  useEffect(() => {
    if (drawerData && drawerData.data && drawerData.data.actions) {
      const activeActionIndex = drawerData.data.actions.findIndex(
        (i) => i.action_status === "ACTIVE"
      );

      let customerActions = drawerData.data.actions || [];

      let startIndex = customerActions[activeActionIndex - 2]
        ? activeActionIndex
        : 0;
      let endIndex = customerActions[startIndex + 2]
        ? startIndex + 4
        : customerActions.length;
      if (endIndex === customerActions.length) {
        startIndex = customerActions.length - 4;
      }

      let inQueueAccumulationDays = 0;
      customerActions = customerActions.map((act, i) => {
        let { planned_on, action_status, action_lead_time } = act;
        let actionData = {
          actionInMessage: "",
          isAlert: false,
        };

        if (planned_on && action_status === "ACTIVE") {
          let dayDiff = moment
            .utc(planned_on)
            ?.tz(currentDefaultFormatDetails.time_zone)
            ?.diff(
              moment.tz(currentDefaultFormatDetails.time_zone),
              "days",
              true
            );

          dayDiff = Math.ceil(dayDiff);

          if (dayDiff == 0) {
            actionData.actionInMessage = "Due Today";
            actionData.isAlert = true;
          } else if (dayDiff > 0) {
            actionData.actionInMessage = `In ${dayDiff} Day${
              dayDiff > 1 ? "s" : ""
            }`;
          } else {
            actionData.actionInMessage = "Overdue";
            actionData.isAlert = true;
          }

          if (activeActionIndex === i) {
            inQueueAccumulationDays += dayDiff > 0 ? dayDiff : 0;
          }
        } else if (action_status === "IN_QUEUE") {
          let inDays = inQueueAccumulationDays + action_lead_time;
          inQueueAccumulationDays += action_lead_time;
          actionData.actionInMessage = `In ${inDays} Day${
            inDays > 1 ? "s" : ""
          }`;
        }

        return {
          ...act,
          ...actionData,
        };
      });

      setAll(customerActions);
    }
  }, [drawerData]);

  const ActionIcon = ({ actionType }) => {
    switch (actionType) {
      case "email":
        return (
          <Icon icon="email" size={16} className="mx-icon--path-fill-primary" />
        );
      case "call":
        return (
          <Icon icon="call" size={16} className="mx-icon--path-fill-primary" />
        );
      case "sms":
        return (
          <Icon icon="sms" size={16} className="mx-icon--path-fill-primary" />
        );
      case "letter":
        return (
          <Icon
            icon="letter"
            size={16}
            className="mx-icon--path-fill-primary"
          />
        );
      case "whatsapp":
        return (
          <Icon
            icon="whatsapp"
            size={16}
            color={"#516beb"}
            // className="mx-icon--path-fill-primary"
          />
        );
      default:
        return (
          <Icon icon="email" size={16} className="mx-icon--path-fill-primary" />
        );
    }
  };

  return (
    <>
      <div className="co-drawer-header">
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <div
            style={{
              display: "flex",
              maxWidth: "270px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {drawerData && drawerData.data && drawerData.data.isWorkflow ? (
              <></>
            ) : (
              <Icon icon={icon} size={24} />
            )}
            <Typography
              type="h2"
              subType="medium"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {drawerData && drawerData.data && drawerData.data.isWorkflow
                ? _.get(drawerData, "data.name", "")
                : name}{" "}
            </Typography>
          </div>
          <span style={{ color: "red" }}>
            {_.get(customerData, "data.doc.consent_call_till") &&
            _.get(customerData, "data.doc.call_rpc_count") >=
              _.get(customerData, "data.doc.mini_miranda.max_call_rpc")
              ? ""
              : _.get(customerData, "data.doc.call_rpc_count") >=
                  _.get(customerData, "data.doc.mini_miranda.max_call_rpc") &&
                name === "Manual Call"
              ? "Max Call Reached - Only Inbound Allowed"
              : ""}
          </span>
        </div>
        {drawerData && drawerData.data && drawerData.data.isWorkflow ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "270px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <div style={{ display: "flex", gap: "5px" }}>
              <ActionIcon
                actionType={_.get(
                  drawerData,
                  "data.activeAction.action_type",
                  ""
                )}
              />
              <Typography
                type="h4"
                subType="medium"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                className="text-primary"
              >
                {_.get(drawerData, "data.activeAction.action_name")}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography
                type="h4"
                subType="regular"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                className="text-secondary"
              >
                on{" "}
                <DateComponent
                  type={DATE_ORG}
                  value={_.get(drawerData, "data.activeAction.planned_on", 0)}
                />{" "}
                {_.get(
                  drawerData,
                  "data.activeAction.is_automatic_action",
                  false
                ) ? (
                  "(Auto)"
                ) : (
                  <></>
                )}
              </Typography>
              <Icon
                icon="chevron_down"
                size={18}
                isPressable
                onClick={() => {
                  setExpand(!expand);
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {inbound &&
        (!drawerData || !drawerData.data || !drawerData.data.isWorkflow) ? (
          <CheckBoxBaseweb
            checked={is_inbound_call}
            checkmarkType="toggle_round"
            labelPlacement="right"
            onChange={(e) => setState(e.target.checked)}
            disabled={
              _.get(customerData, "data.doc.consent_call_till")
                ? false
                : _.get(customerData, "data.doc.call_rpc_count") >=
                  _.get(customerData, "data.doc.mini_miranda.max_call_rpc")
                ? true
                : false
            }
          >
            <FormattedMessage id="inbound_call">inbound_call</FormattedMessage>
          </CheckBoxBaseweb>
        ) : (
          <></>
        )}
      </div>

      {expand ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#FFFFFF",
            marginBottom: "10px",
            padding: "10px",
            borderRadius: "5px",
            gap: "10px",
            border: "1px solid #cdced9",
          }}
        >
          {all.map((e) => {
            return (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    width: "230px",
                  }}
                >
                  {e.action_status === ACTIVE ? (
                    <div className="wf_active">
                      <Icon
                        icon={e && e.action_type && e.action_type.toLowerCase()}
                        size={18}
                        color={
                          e &&
                          e.action_type &&
                          e.action_type.toLowerCase() === "whatsapp"
                            ? "#516BEB"
                            : "#FFFFFF"
                        }
                      />
                    </div>
                  ) : (
                    <Icon
                      icon={e && e.action_type && e.action_type.toLowerCase()}
                      size={18}
                      {...(e.executed_on ? { color: "#787878" } : {})}
                    />
                  )}

                  <Typography
                    type="h4"
                    subType="regular"
                    className={
                      e.executed_on
                        ? "text-secondary"
                        : e.action_status === ACTIVE
                        ? "text-primary"
                        : ""
                    }
                  >
                    {e.action_name ? e.action_name : ""}
                  </Typography>
                </div>

                {e.executed_on ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "150px",
                    }}
                  >
                    <Icon
                      icon="dot"
                      size={6}
                      {...(e.executed_on ? { color: "#787878" } : {})}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        type="h4"
                        subType="regular"
                        className={e.executed_on ? "text-secondary" : ""}
                      >
                        <DateComponent type={DATE_ORG} value={e.executed_on} />
                      </Typography>

                      {e.is_automatic_action ? (
                        <Icon
                          icon="auto"
                          size={18}
                          color={e.executed_on ? "#787878" : ""}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {e.action_status === ACTIVE || e.action_status === IN_QUEUE ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "150px",
                    }}
                  >
                    <Icon
                      icon="dot"
                      size={6}
                      {...(e.executed_on
                        ? { color: "#787878" }
                        : e.action_status === ACTIVE
                        ? { color: "#516beb" }
                        : {})}
                    />
                    <Typography
                      type="h4"
                      subType="regular"
                      className={
                        e.executed_on
                          ? "text-secondary"
                          : e.action_status === ACTIVE
                          ? "text-primary"
                          : ""
                      }
                    >
                      {e.actionInMessage}
                    </Typography>
                    {e.is_automatic_action ? (
                      <Icon
                        icon="auto"
                        size={18}
                        color={
                          e.executed_on
                            ? "#787878"
                            : e.action_status === ACTIVE
                            ? "#516beb"
                            : "#333860"
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                {e.action_status === ACTIVE ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      handleModal({
                        formContent: ({ handleModal }) => {
                          return (
                            <SkipAction
                              handleModal={handleModal}
                              customerId={customerId}
                              entity={"customer"}
                            />
                          );
                        },
                        cancelCallback: () => {},
                        isChildren: true,
                      });
                      closeDrawer();
                    }}
                  >
                    <Icon
                      icon="dot"
                      size={6}
                      {...(e.action_status === ACTIVE
                        ? { color: "#FD372A" }
                        : {})}
                    />
                    <Icon
                      isPressable
                      icon="skip_action"
                      size={16}
                      color="#FD372A"
                    />
                    <Typography className="text-error">Skip Action</Typography>
                  </div>
                ) : (
                  <></>
                )}

                {e.executed_on ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      flexDirection: "row",
                    }}
                  >
                    <Icon
                      icon="dot"
                      size={6}
                      {...(e.executed_on ? { color: "#787878" } : {})}
                    />

                    <Icon
                      icon={
                        status[e.action_status] ? status[e.action_status] : ""
                      }
                      size={14}
                      color="#787878"
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActionHeader;

{
  /* {e.planned_on || e.executed_on ? (
                  <Icon
                  icon="dot"
                  size={6}
                  {...(e.executed_on ? { color: "#787878" } : {})}
                  />
                  ) : (
                    <></>
                  )} */
}
{
  /* {e.planned_on && !e.executed_on ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "150px",
                    }}
                  >
                    <>
                      <Icon
                        icon="dot"
                        size={6}
                        {...(e.executed_on ? { color: "#787878" } : {})}
                      />
                      <Typography
                        type="h4"
                        subType="regular"
                        className={e.executed_on ? "text-secondary" : ""}
                      >
                        <DateComponent type={DATE_ORG} value={e.planned_on} />
                      </Typography>
                      {e.is_automatic_action ? (
                        <Icon
                          icon="auto"
                          size={18}
                          color={e.executed_on ? "#787878" : ""}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                ) : (
                  <></>
                )} */
}
