import React, { useState, useEffect, useContext } from "react";
import { Modal, SIZE } from "baseui/modal";
import { Label1, LabelSmall, Paragraph2 } from "baseui/typography";
import { useDropzone } from "react-dropzone";
import { TextButton } from "../TextButton";
import UploadFile from "../../assets/img/svg/UploadFile";
import UploadCustomerInvoice from "../../assets/img/svg/UploadCustomerIvoice";
import DeletePdf from "../../assets/img/svg/DeletePdf";
import { confirmAdhocData, uploadCustomerAdhocData } from "../../services";
import { useMutation, useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { SelectBox } from "../SelectBox";
import getSelectValues from "../../utils/getSelectValues";
import _ from "lodash";
const UploadDataModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsCreditOpen,
  isCreditOpen,
}) => {
  const [uploadId, setUploadId] = useState("");
  const [selectedBureaus, setSelectedBureaus] = useState([]);
  const [acceptedFileValues, setAcceptedFileValue] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const intl = useIntl();
  let { currentOrganization, referenceData, users } = useContext(MaxyfiContext);
  const formData = new FormData();
  const confirmUploadAdhocFile = useMutation(
    (action) =>
      uploadCustomerAdhocData({
        action,
        organization: currentOrganization,
        // uploadId,
        formData,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;

          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        // dispatch(setInitialAdhocState());
        // setCancelLoading(false);
        // close();
        setSelectedBureaus([]);
        setIsModalOpen(false);
        setIsCreditOpen(false);
        setAcceptedFileValue((acceptedFileValues[0] = []));
        // props.refetch();
      },
    }
  );

  const handleSelectChange = (selectedOption) => {
    setSelectedBureaus(_.get(selectedOption, "value", []));
  };

  const fileSize = 2000000;

  function fileSizeValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 2MB`,
      };
    }
  }

  let { acceptedFiles, getRootProps, getInputProps, fileRejections } =
    useDropzone({
      accept: {
        "text/csv": [".csv"],
      },
      validator: fileSizeValidation,
    });
  useEffect(() => {
    setAcceptedFileValue(acceptedFiles);
  }, [acceptedFiles]);

  const fileSizeRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <ul>
        {errors.map((e) => {
          if (e.code === "file-invalid-type") {
            return (
              <LabelSmall key={e.code} $style={{ color: "#FD372A" }}>
                File type must be .csv
              </LabelSmall>
            );
          }

          return (
            <LabelSmall key={e.code} $style={{ color: "#FD372A" }}>
              {e.message}
            </LabelSmall>
          );
        })}
      </ul>
    );
  });

  const files = acceptedFileValues?.map((file, i) => (
    <>
      <>
        <div
          key={file.path}
          style={{
            height: "40px",
            border: "0.5px solid #CDCED9",
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
            borderRadius: "5px",
            width: "200px",
            gap: "5px",
            justifyContent: "space-between",
            padding: "7px",
          }}
        >
          <div style={{}}>
            <UploadCustomerInvoice />
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {file.path}
          </div>
          {/* - {file.size} bytes */}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              // setDeletePdf(true);
              acceptedFiles.splice(i, 1);
              setAcceptedFileValue([]);
            }}
          >
            <DeletePdf />
          </div>
        </div>
      </>
    </>
  ));

  // const formData = new FormData();
  // formData.append("file", acceptedFileValues && acceptedFileValues[0]);
  // formData.append("type", "WORKFLOW_RM");

  const handleSubmit = async () => {
    formData.append("file", acceptedFileValues[0]);
    formData.append("type", isCreditOpen ? "CRD_REP_RET" : "WORKFLOW_RM");

    if (isCreditOpen) {
      formData.append("crdt_bur", getSelectValues(selectedBureaus));
    }

    setIsUploading(true); // Set uploading state to true when submit button is clicked
    try {
      await confirmUploadAdhocFile.mutateAsync(formData);
    } catch (error) {
      console.error("Upload failed", error);
    } finally {
      setIsUploading(false); // Reset uploading state after upload is completed or failed
    }
  };

  return (
    <Modal
      onClose={() => {
        // setIsModalOpen(false);

        setIsCreditOpen(false);

        // acceptedFiles.splice(0, 1);
      }}
      isOpen={isModalOpen || isCreditOpen}
      size={SIZE.auto}
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            // padding: "50px 32px",
            // height: "500px",
            // width: "660px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
    >
      {/* <AdhocModalTypes close={close} /> */}
      <div style={{ width: "500px", margin: "40px 40px" }}>
        {isCreditOpen ? (
          <Label1 style={{ paddingBottom: "24px" }}>Upload Return File</Label1>
        ) : (
          <Label1 style={{ paddingBottom: "24px" }}>
            Upload Workflow / User Assignment
          </Label1>
        )}

        {/* <Paragraph2 style={{ paddingBottom: "30px" }}>
          Use the
          <span
            style={{
              color: "#516beb",
              borderBottom: "1px solid #516beb",
              padding: "0px 3px",
            }}
          >
            Template
          </span>
          for upload data preparation. Refer
          <span
            style={{
              color: "#516beb",
              borderBottom: "1px solid #516beb",
              padding: "0px 3px",
            }}
          >
            FAQ
          </span>
          for the upload process
        </Paragraph2> */}
        <section className="container-adhoc">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <UploadFile />
            <p>
              <b>Click to upload</b> a files(s) or drag and drop
            </p>
          </div>
          <aside>
            <Label1>{files}</Label1>.<div>{fileSizeRejectionItems}</div>
          </aside>
        </section>

        {isCreditOpen && (
          <SelectBox
            name="crdt_bur"
            requiredAstric={true}
            clearable={false}
            backspaceRemoves={false}
            label={intl.formatMessage({ id: "Select Source Format" })}
            placeholder={intl.formatMessage({ id: "Select Source Format" })}
            options={referenceData["credit_report_upload_type"].sort((a, b) =>
              a?.id.localeCompare(b?.id)
            )}
            size={SIZE.default}
            value={selectedBureaus}
            onChange={handleSelectChange}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "30px",
          }}
        ></div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <TextButton
              size={SIZE.compact}
              type="button"
              onClick={() => {
                setIsModalOpen(false);
                setIsCreditOpen(false);
                acceptedFiles.splice(0, 1);
              }}
              $style={{
                // marginTop: "20px",
                width: "126px",
                height: "36px",
                borderRadius: "5px",
                background: "#fff",
                color: "#333860",
                ":hover": {
                  backgroundColor: "#fff",
                  color: "#333860",
                },
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div>
            <TextButton
              $style={{
                // marginTop: "20px",
                width: "126px",
                height: "36px",
                borderRadius: "5px",
                background: "#516BEB",

                boxShadow: "0px 4px 4px 0px #516beb25",
                ":hover": {
                  boxShadow: "inset 0px 4px 4px 0px #00000025",
                  backgroundColor: "#0348C0",
                },
              }}
              size={SIZE.compact}
              // isLoading={isSubmitting}
              // disabled={isSubmitting}
              type="submit"
              // onClick={() => {
              //   sendLetter();
              // }}
              onClick={handleSubmit}
              // disabled={
              //   isUploading
              //     ? true
              //     : acceptedFileValues?.length > 0
              //     ? false
              //     : true
              // }
              disabled={
                isUploading ||
                acceptedFileValues.length === 0 ||
                (isCreditOpen && selectedBureaus.length === 0)
              }
            >
              Submit
            </TextButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadDataModal;
