import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import columnMapper_V2 from "../../../utils/mapper_v2";
import TitleHead from "../../../components_v2/TitleHead";
import { Icon, Typography } from "../../../components_v2";
import { KIND, SIZE, TextButton } from "../../../components/TextButton";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import InputTypes from "../CustomFields/InputTypes";
import { Controller, useForm, useWatch } from "react-hook-form";
import _, { lastIndexOf } from "lodash";
import {
  LEGAL_COUNSEL,
  LEGAL_DEBTS,
  LEGAL_DEFENDANTS,
  LEGAL_DOCS,
  LEGAL_MOTIONS,
  LEGAL_NOTES,
  LEGAL_STATUS,
  LEGAL_TIMELINE,
  LEGAL_ACTION_INFO_,
  LEGAL_TIMESHEET,
  LEGAL_AUDIT,
  CUSTOMER_OVERVIEW_,
} from "../../../constants";
import Note from "../Notes";
import DebtCountContainer from "../Actions/DebtsLegalAction/DebtCountContainer";
import DebtListTable from "../Actions/DebtsLegalAction/DebtListTable";
import DefendantList from "../Actions/DefendantsLegal/DefendantList";
import ListingCouncil from "../Actions/CounselAction/ListingCouncil";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import Motion from "../Actions/MotionAction/Motion";
import DocumentUploadPage from "../Actions/DocumentLegalAction/DocumentUploadPage";
import DocumentListPage from "../Actions/DocumentLegalAction/DocumentListPage";
import { HearingPopperEdit } from "./LegalhearingStagePoper";
import LegalTimeSheet from "../Actions/LegalTimeSheet/LegalTimeSheet";
import LegalStatus from "../Actions/StatusAction/LegalStatus";
import TimerComponent from "./TimerComponent";
import {
  getLegalInfo,
  postLegalInfomation,
  updateLegalInfoMark,
} from "../../../services";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import moment from "moment";
import getSelectValues from "../../../utils/getSelectValues";
import setSelectValues from "../../../utils/setSelectValues";
import AuditAction from "../Actions/AuditAction/AuditAction";
import queryClient from "../../../providers/queryClient";
import useFormat from "../../../hooks/useFormat";
import { useDispatch, useSelector } from "react-redux";
import { TextBox } from "../../../components/TextBox";
import { useIntl } from "react-intl";
import { Input } from "baseui/input";
import { refetchDiaryNotes } from "../../../redux/customerDairyNote/action";
import { getDairyNotes } from "../../../services";
import { getDocumentData } from "../../../services/documentSystem";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  ADD_CHARGES,
  ADD_INVOICE,
  ADD_INVOICE_ITEM,
  ADD_RECURRING_INVOICE,
  CAPTURE_SECTION_LEGAL,
  STATUS_UPDATE_LEGAL,
} from "../../../providers/RBACProvider/permissionList";
import { StatefulTooltip } from "baseui/tooltip";
import { MultiSelect } from "../../../components/MultiSelect/MultiSelect";
import { SelectBox } from "../../../components/SelectBox";

const legalAction = [
  { label: "Notes", action: LEGAL_NOTES, is_hidden: false },
  { label: "Docs", action: LEGAL_DOCS, is_hidden: false },
  { label: "Motions", action: LEGAL_MOTIONS, is_hidden: false },
  { label: "Debts", action: LEGAL_DEBTS, is_hidden: false },
  { label: "Defendants", action: LEGAL_DEFENDANTS, is_hidden: false },
  { label: "Counsel", action: LEGAL_COUNSEL, is_hidden: false },
  { label: "Status", action: LEGAL_STATUS, is_hidden: true },
  { label: "Time Sheet", action: LEGAL_TIMESHEET, is_hidden: true },
  { label: "Audit", action: LEGAL_AUDIT, is_hidden: true },
];

const LegalActionType = ({
  type,
  result,
  isSectionActive,
  state,
  isEditableDebDpt,
}) => {
  switch (type) {
    case LEGAL_NOTES:
      return (
        <Note result={result} isSectionActive={isSectionActive} state={state} />
      );
    case LEGAL_DOCS:
      return (
        <DocumentListPage
          result={result}
          isSectionActive={isSectionActive}
          state={state}
        />
      );
    case LEGAL_DEBTS:
      return <DebtCountContainer isEditableDebDpt={isEditableDebDpt} />;
    case LEGAL_DEFENDANTS:
      return <DefendantList isEditableDebDpt={isEditableDebDpt} />;
    case LEGAL_MOTIONS:
      return <Motion />;
    case LEGAL_COUNSEL:
      return <ListingCouncil />;
    case LEGAL_STATUS: // Handle Status component
      return <LegalStatus />;
    case LEGAL_AUDIT: // Handle Audit component
      return <AuditAction />;
    case LEGAL_TIMESHEET:
      return <LegalTimeSheet />;
    default:
      return null;
  }
};

const Header = ({ label = "" }) => {
  const intl = useIntl();

  return (
    <Typography type="h4" subType="medium" className="mx-table-header">
      {intl.formatMessage({ id: label })}
    </Typography>
  );
};

const JudgmentEditAmountTable = ({
  setIsEditing,
  setJudgementTable,
  legalActionInfo,
}) => {
  const [Judgment, setJudgement] = useState({
    jad: [
      { typ: "Debt", sh: 0.0, aw: 0.0 },
      { typ: "Interest", sh: 0.0, aw: 0.0 },
      { typ: "Attroney Fees", sh: 0.0, aw: 0.0 },
      { typ: "Court Fees", sh: 0.0, aw: 0.0 },
      { typ: "Other Fees", sh: 0.0, aw: 0.0 },
    ],
  });

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...Judgment,
    },
  });

  useEffect(() => {
    let value = _.get(legalActionInfo, "data.data.doc", "");
    value &&
      value["jad"] &&
      value["jad"].map((e, i) => {
        setValue(`jad[${i}].typ`, e.typ);
        setValue(`jad[${i}].sh`, e.sh);
        setValue(`jad[${i}].aw`, e.aw);
      });
  }, []);

  const watchFields = watch();

  const columns = useMemo(
    () => [
      {
        header: () => {
          return (
            <>
              <Typography type="h4" subType="medium">
                Type
              </Typography>
            </>
          );
        },
        accessorKey: "typ",
        cell: (props) => {
          return (
            <Controller
              name={`jad[${props.row.index}].typ`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value}
                  name={field.name}
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  overrides={{
                    Input: {
                      style: ({ $theme }) => ({
                        border: "none",
                        backgroundColor:
                          props.row.index % 2 !== 0 ? "#F9FBFF" : "#fffff",
                        fontSize: "13px",
                        fontWeight: "400",
                        color: "#333860",
                        lineHeight: "17px",
                      }),
                    },
                    Root: {
                      style: ({ $theme }) => ({
                        border: "none",
                        backgroundColor: "#fffff",
                      }),
                    },
                    InputContainer: {
                      style: ({ $theme }) => ({
                        border: "none",
                        backgroundColor: "#fffff",
                      }),
                    },
                  }}
                />
              )}
            />
          );
        },
      },
      {
        header: () => {
          return (
            <>
              <Typography
                type="h4"
                subType="medium"
                style={{ textAlign: "end" }}
              >
                Sought
              </Typography>
            </>
          );
        },
        accessorKey: "sh",
        cell: (props) => {
          return (
            <Controller
              name={`jad[${props.row.index}].sh`}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value}
                  name={field.name}
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  overrides={{
                    Input: {
                      style: ({ $theme }) => ({
                        border: "none",
                        backgroundColor:
                          props.row.index % 2 !== 0 ? "#F9FBFF" : "#fffff",
                        textAlign: "end",
                      }),
                    },
                    Root: {
                      style: ({ $theme }) => ({
                        border: "none",
                        backgroundColor: "#fffff",
                      }),
                    },
                    InputContainer: {
                      style: ({ $theme }) => ({
                        border: "none",
                        backgroundColor: "#fffff",
                      }),
                    },
                  }}
                />
              )}
            />
          );
        },
      },
      {
        header: () => {
          return (
            <>
              <Typography
                type="h4"
                subType="medium"
                style={{ textAlign: "end" }}
              >
                Awarded
              </Typography>
            </>
          );
        },
        accessorKey: "aw",
        cell: (props) => {
          const value = props?.getValue();
          return (
            <Controller
              name={`jad[${props.row.index}].aw`}
              control={control}
              render={({ field }) => (
                <div style={{ border: "none" }}>
                  <Input
                    {...field}
                    value={field.value}
                    name={field.name}
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    overrides={{
                      Input: {
                        style: ({ $theme }) => ({
                          border: "none",
                          backgroundColor:
                            props.row.index % 2 !== 0 ? "#F9FBFF" : "#fffff",
                          textAlign: "end",
                        }),
                      },
                      Root: {
                        style: ({ $theme }) => ({
                          border: "none",
                          backgroundColor: "#fffff",
                        }),
                      },
                      InputContainer: {
                        style: ({ $theme }) => ({
                          border: "none",
                          backgroundColor: "#fffff",
                        }),
                      },
                    }}
                  />
                </div>
              )}
            />
          );
        },
      },
    ],
    []
  );

  let data = [
    {
      typ: "Debt",
      sh: "",
      aw: "",
    },
    {
      typ: "Interest",
      sh: "",
      aw: "",
    },
    {
      typ: "Attroney Fees",
      sh: "",
      aw: "",
    },
    {
      typ: "Court Fees",
      sh: "",
      aw: "",
    },
    {
      typ: "Other Fees",
      sh: "",
      aw: "",
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const handleGetAllFormData = () => {
    const allFormData = getValues();
    setJudgementTable(allFormData);
  };

  return (
    <div className="judge-ment-table">
      <div className="judge-ment-table-wrapper">
        {/* TABLE HEADER */}
        <div className="judgement-table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="judgemet-table_header-group">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                  // className="co-table_header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {/* TABLE HEADER */}
        {/* TABLE BODY */}
        <div className="judge-table-body">
          {/* TABLE ROW */}
          {/* {table.getRowModel().rows.map((row, i) => {
            if (
              _.get(row, "original.pgid") ===
              _.get(customerData, "data.doc.pgid")
            )
              return (
                <div key={row.id} className="co-table_body-group">
                  {row.getVisibleCells().map((cell) => (
                    <div
                      key={cell.id}
                      style={{ width: cell.column.getSize() }}
                      className="co-table_body-cell"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  ))}
                </div>
              );
          })} */}

          {table.getRowModel().rows.map((row, i) => {
            return (
              <div
                key={row.id}
                className="judge-ment_table-group"
                style={{
                  background: i % 2 !== 0 ? "#F9FBFF" : "white",
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <div
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                    }}
                    className="co-table_body-cell"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                ))}
              </div>
            );
          })}
          {/* TABLE ROW */}
        </div>
        {/* TABLE BODY */}

        <div style={{ backgroundColor: "#FAFAFA" }}>
          <div
            style={{
              paddingRight: "16px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TextButton
              type="button"
              kind={KIND.tertiary}
              onClick={() => {
                handleGetAllFormData();
              }}
              size="mini"
              startEnhancer={() => (
                <Icon icon={"tick"} size={18} color="#0FB158" />
              )}
            >
              Done
            </TextButton>
            {/* <div
              style={{
                display: "flex",
                gap: "5px",
                padding: "10px 0",
                cursor: "pointer",
              }}
             
            >
              <Icon icon={"tick"} size={18} color="#0FB158" />
              <Typography
                type="h4"
                style={{
                  color: "#0FB158",
                }}
                isLoading={isSubmitting}
              >
                {" "}
                done
              </Typography>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const LegalInfoTab = ({
  result,
  isSectionActive,
  setIsSectionActive,
  children,
  isSubmitting,
  setValue,
  legalActionInfo,
  reset,
  setIsFieldId,
  handleAudit,
  updateInfoMark,
  isEditing,
  setIsEditing,
  setJudgementTable,
  setDynamicLegal,
  setlegalInfoTap,
  setState,
  state,
  control,
}) => {
  const { customerId } = useParams();
  const { referenceData } = useContext(MaxyfiContext);
  const notesLength = useMemo(() => {
    let notesLength = _.get(legalActionInfo, "data.data.doc.li", "");
    let infoPath = result[isSectionActive]?.pat;
    return (
      notesLength &&
      notesLength[infoPath] &&
      notesLength[infoPath]?.nt &&
      notesLength[infoPath]?.nt?.length
    );
  }, [legalActionInfo]);

  const docsLength = useMemo(() => {
    let docLength = _.get(legalActionInfo, "data.data.doc.li", "");
    let infoPath = result[isSectionActive]?.pat;
    return (
      docLength &&
      docLength[infoPath] &&
      docLength[infoPath]?.doc &&
      docLength[infoPath]?.doc?.length
    );
  }, [legalActionInfo]);

  useEffect(() => {
    let informationDetails = _.get(legalActionInfo, "data.data.doc.li", "");

    let infoPath = result[isSectionActive]?.pat;

    let updatedInformation = Array.isArray(informationDetails[infoPath]?.dt);

    if (updatedInformation) {
      if (informationDetails && informationDetails[infoPath]) {
        result &&
          result[isSectionActive] &&
          result[isSectionActive].fld &&
          result[isSectionActive].fld.map((e) => {
            let path = e.pat;
            let dataType = e.dtp;
            let lastArray = result[isSectionActive].fieldData;

            let storeDeff = [];

            result?.[isSectionActive]?.fieldData?.defs?.map((e) => {
              let findDiff = contacts?.find((fd) => fd.id == e);
              storeDeff.push({ id: findDiff.id });
            });

            if (storeDeff && storeDeff.length > 0) {
              setValue("defs", storeDeff || []);
            }

            if (dataType === "DATE" && lastArray && lastArray[path]) {
              setValue(path, new Date(lastArray?.[path]) || null);
            } else if (dataType === "TEXT" && lastArray && lastArray[path]) {
              setValue(path, lastArray?.[path]);
            } else if (
              dataType === "AMOUNT" &&
              lastArray &&
              lastArray[path] &&
              lastArray[path].value
            ) {
              setValue(`${path}.value`, lastArray[path]?.value);
              setValue(`${path}.currency`, lastArray[path]?.currency);
            } else if (dataType === "NUMBER" && lastArray && lastArray[path]) {
              setValue(path, lastArray[path]);
            } else {
              setValue(path, lastArray && lastArray[path]);
            }
          });
      }
    } else {
      if (informationDetails && informationDetails[infoPath]) {
        result &&
          result[isSectionActive] &&
          result[isSectionActive].fld &&
          result[isSectionActive].fld.map((e) => {
            let path = e.pat;
            let dataType = e.dtp;

            // console

            // // if (informationDetails &&
            // //   informationDetails[infoPath] &&
            // //   informationDetails?.dt?.[path]) {
            // //   setValue("defs", storeDeff || []);
            // // }

            if (
              informationDetails &&
              informationDetails[infoPath] &&
              informationDetails[infoPath]?.dt?.hasOwnProperty(path)
            ) {
              if (dataType === "DATE") {
                if (
                  informationDetails &&
                  informationDetails[infoPath] &&
                  informationDetails[infoPath]?.dt?.[path]
                ) {
                  setValue(
                    path,
                    new Date(informationDetails[infoPath]?.dt?.[path]) || null
                  );
                }
              } else if (
                dataType === "TEXT" &&
                path &&
                informationDetails &&
                informationDetails[infoPath] &&
                informationDetails[infoPath]?.dt?.[path]
              ) {
                setValue(path, informationDetails[infoPath]?.dt?.[path]);
              } else if (
                dataType === "AMOUNT" &&
                path &&
                informationDetails &&
                informationDetails[infoPath] &&
                informationDetails?.dt?.[path]
              ) {
                setValue(
                  `${path}.value`,
                  informationDetails[infoPath]?.dt?.[path]?.value
                );
                setValue(
                  `${path}.currency`,
                  informationDetails[infoPath]?.dt?.[path]?.currency
                );
              } else if (
                dataType === "NUMBER" &&
                path &&
                informationDetails &&
                informationDetails[infoPath] &&
                informationDetails?.dt?.[path]
              ) {
                setValue(path, informationDetails[infoPath]?.dt?.[path]);
              } else {
                setValue(path, informationDetails[infoPath]?.dt?.[path]);
              }
            }
          });
      }
    }
  }, [legalActionInfo, isSectionActive]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const legalInfo = queryClient.getQueryData(
    `${LEGAL_ACTION_INFO_}${customerId}`
  );

  const contacts = useMemo(() => {
    // const search = new RegExp(searchList, "i");
    const defendantIds = [..._.get(legalInfo, "data.doc.def", [])];

    return _.get(customerData, "data.doc.contacts", []).map((contact) => {
      const {
        _id,
        first_name = "",
        last_name = "",
        relation,
        address = [],
      } = contact;

      const validAddress =
        address && Array.isArray(address)
          ? address.filter((e) => e.address_status === "VALID")
          : [];
      const firstAddress =
        validAddress && validAddress[0] ? validAddress[0] : null;

      const country =
        referenceData &&
        referenceData["country"] &&
        firstAddress &&
        firstAddress.country
          ? referenceData["country"].find((e) => e.id === firstAddress.country)
          : null;

      const name = `${first_name ? _.capitalize(first_name) : ""}${
        last_name ? ` ${_.capitalize(last_name)}` : ""
      }`;

      return {
        name,
        label: name,
        id: _id,
      };
    });
  }, [customerData?.data]);

  return (
    <>
      {Object.keys(result)?.map((inv, i) => {
        let splitKey = inv.split("/");

        let storeDeff = [];

        result?.[inv]?.fieldData?.defs?.map((e) => {
          let findDiff = contacts?.find((fd) => fd.id == e);
          storeDeff.push(findDiff.name);
        });

        return (
          <div
            className={isSectionActive === inv && "legal_info_tab_container"}
          >
            <div
              className={`legal_info_tab ${
                isSectionActive === inv && "--modifi_legal_info_tab"
              }`}
              onClick={() => {
                setlegalInfoTap(true);
                setIsSectionActive(inv);
                setIsFieldId(
                  result &&
                    result[inv] &&
                    result[inv].fieldData &&
                    result[inv].fieldData._id
                    ? result[inv].fieldData._id
                    : "new"
                );
                if (isSectionActive !== inv) {
                  reset();
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography type="h4">{splitKey[0]} </Typography>
                  {result &&
                  result[inv] &&
                  result[inv].fieldData &&
                  result[inv].fieldData.ic &&
                  result[inv].fieldData.ic ? (
                    <>
                      <Typography
                        subType="regular"
                        className="mx-chip mx-chip--success"
                      >
                        Completed
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}

                  {splitKey && splitKey[1] ? (
                    <div className="multi_select_access">
                      {isSectionActive === inv ? (
                        <Controller
                          name="defs"
                          control={control}
                          render={({ field }) => (
                            <MultiSelect
                              {...field}
                              size={SIZE.mini}
                              value={field.value}
                              name={field.name}
                              options={contacts}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                              height={"30px"}
                            />
                          )}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <Typography>
                            {storeDeff && storeDeff[0] ? storeDeff[0] : ""}
                          </Typography>

                          {storeDeff.length > 1 ? (
                            <Typography className="mx-chip">
                              {" "}
                              +{storeDeff.length - 1}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {isSectionActive === inv ? (
                  <StatefulPopover
                    placement={PLACEMENT.bottom}
                    overrides={{
                      Arrow: {
                        style: ({ $theme }) => ({
                          backgroundColor: "#16164B",
                        }),
                      },
                      Inner: {
                        style: ({ $theme }) => ({
                          backgroundColor: "#FFFFFF",
                        }),
                      },
                      Body: {
                        style: ({ $theme }) => ({
                          padding: "5px",
                          // width: "139px",
                          // height: "64px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "5px",
                        }),
                      },
                    }}
                    content={({ close }) => (
                      <div className="legal-info-poper">
                        <Typography
                          type="p"
                          style={{ padding: "10px", borderRadius: "3px" }}
                          className="regular"
                          onClick={() => {
                            updateInfoMark();
                            close();
                          }}
                        >
                          {result &&
                          result[inv] &&
                          result[inv].fieldData &&
                          result[inv].fieldData.ic
                            ? "Mark as Incomplete"
                            : "Mark as Complete"}
                        </Typography>
                        <Typography
                          type="p"
                          style={{ padding: "10px", borderRadius: "3px" }}
                          className="regular"
                          onClick={() => {
                            handleAudit({
                              label: "Audit",
                              action: LEGAL_AUDIT,
                              is_hidden: true,
                            });

                            close();
                          }}
                        >
                          Audit
                        </Typography>
                      </div>
                    )}
                    returnFocus
                    autoFocus
                  >
                    {/* backgroundColor:"#F0F5FF" ,width:'28px',borderRadius:'5px'*/}
                    <div>
                      <Icon icon="triple_dot" />
                    </div>
                  </StatefulPopover>
                ) : (
                  <Icon icon="chevron_down" isPressable />
                )}
              </div>
            </div>
            {isSectionActive === inv ? children : <></>}
            {isSectionActive === inv && inv === "Judgement" && isEditing && (
              <JudgmentEditAmountTable
                setIsEditing={setIsEditing}
                setJudgementTable={setJudgementTable}
                legalActionInfo={legalActionInfo}
              />
            )}
            {isSectionActive === inv && (
              <div className="legal_info_footer">
                <div className="legal_info_footer_content">
                  <Typography
                    onClick={() => {
                      setState({ ...state, action: LEGAL_NOTES, filter: inv });
                    }}
                  >
                    {notesLength ? notesLength : 0} {"  "}Notes
                  </Typography>
                  <div className="dot_legal_action"></div>
                  <Typography
                    onClick={() => {
                      setState({ ...state, action: LEGAL_DOCS, filter: inv });
                    }}
                  >
                    {docsLength ? docsLength : 0} {"  "} Documents
                  </Typography>
                </div>
                <div>
                  <RBACWrapper role={CAPTURE_SECTION_LEGAL} type="PERMISSION">
                    <TextButton size="mini" isLoading={isSubmitting}>
                      Save
                    </TextButton>
                  </RBACWrapper>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

const LegalActionDrawer = () => {
  const { customerId } = useParams();
  const format = useFormat();
  const {
    customInvoice,
    currentDefaultFormatDetails,
    currentOrganization,
    customContact,
    legalConfig,
    referenceData,
  } = useContext(MaxyfiContext);

  const [result, setIsResult] = useState({});
  const [isFieldId, setIsFieldId] = useState("new");
  const [isSectionActive, setIsSectionActive] = useState("");
  const [tabActive, setTabActive] = useState({
    time_sheet: false,
    status: false,
    audit: false,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [judgementTable, setJudgementTable] = useState();
  const [legalInfoTap, setlegalInfoTap] = useState(false);
  const [dynamicLegal, setDynamicLegal] = useState(legalAction);
  const [state, setState] = useState({
    action: LEGAL_NOTES,
    filter: "",
  });
  const handleMoreActionsClick = (item) => {
    const updatedActions = legalAction.map((actionItem) => {
      if (actionItem.label === item.label) {
        return { ...actionItem, is_hidden: false };
      } else if (actionItem.label === "Counsel") {
        return { ...actionItem, is_hidden: true };
      } else {
        return actionItem;
      }
    });
    setDynamicLegal(updatedActions);
    setState({ ...state, action: item.action });
  };

  const legalActionInfo = useQuery(
    [`${LEGAL_ACTION_INFO_}${customerId}`],
    async () => {
      return await getLegalInfo({
        customerId: customerId,
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const nextMotionInfo = useMemo(() => {
    const motions = _.get(legalActionInfo, "data.data.doc.mot", []);
    const checkDeletedMotion = motions.filter((item) => item.idl === false);
    const filterMotion = checkDeletedMotion.filter((motion) => {
      const hrd = moment(motion.hrd); // Convert hrd to moment object
      const today = moment(); // Get today's date

      // Check if hrd is today or in the future
      return hrd.isSameOrAfter(today, "day");
    });

    return filterMotion;
  }, [legalActionInfo]);

  let { data } = useSelector((e) => e.customerOverviewDrawer);

  const { isMulti } = useMemo(() => {
    if (data && data.isLegalAudit) {
      handleMoreActionsClick({
        label: "Audit",
        action: "LEGAL_AUDIT",
        is_hidden: true,
      });
    }

    let result = {};
    let isMulti = [];
    for (let obj of legalConfig) {
      let section = obj.na;

      if (obj.ism) {
        isMulti.push(obj.na);
      }

      let data = _.get(
        legalActionInfo,
        "data.data.doc.li." + obj.pat + ".dt",
        ""
      );

      // if (obj?.isd) {
      //   setIsSectionActive(obj.na);
      // }

      if (obj.ism) {
        if (data?.length) {
          data.map((dt, i) => {
            result[section + (i ? ` ${i}` : "") + (obj.isdef ? `/def` : "")] = {
              ...obj,
              fieldData: dt,
            };
          });
        } else {
          result[section + (obj.isdef ? `/def` : "")] = { ...obj };
        }
      } else {
        result[section + (obj.isdef ? `/def` : "")] = {
          ...obj,
          fieldData: data,
        };
      }
    }
    setIsResult(result);

    return { isMulti, result };
  }, [legalActionInfo.isLoading, legalActionInfo.isFetching]);

  useEffect(() => {
    const filteredTabActive = Object.fromEntries(
      Object.entries(result).filter(([key, value]) => {
        return !value.fieldData || !value.fieldData.ic;
      })
    );
    const tabName =
      Object.keys(filteredTabActive).length > 0
        ? Object.keys(filteredTabActive)[0]
        : "";
    setIsSectionActive(tabName);
  }, [result, legalActionInfo.isLoading]);

  const isEditableDebDpt = useMemo(() => {
    let isEditDebDpt = {};
    if (legalConfig && Object.keys(legalConfig).length > 0) {
      for (let key of legalConfig) {
        if ("idde" in key) {
          isEditDebDpt.idde = key.idde === true ? true : false;
          break;
        }
      }
    }
    const checkCompliant = _.get(
      legalActionInfo,
      "data.data.doc.li.cmpt.dt.ic",
      false
    );
    const checkSupplemts = _.get(
      legalActionInfo,
      "data.data.doc.li.spt.dt",
      []
    );
    const hasICKeyAndTrue = checkSupplemts.every((supplement) => {
      // Check if the current supplement object has the "ic" key and its value is true
      return _.has(supplement, "ic") && supplement.ic === true;
    });

    return {
      ...isEditDebDpt,
      Complaint: checkCompliant,
      Supplement: hasICKeyAndTrue,
    };
    //make these condition here
  }, [legalActionInfo.isLoading, legalActionInfo.isFetching]);

  // let getObjectKey = Object.keys(result);

  const ourCouncelData = useMemo(() => {
    if (_.get(currentDefaultFormatDetails, "atrl", []).length) {
      const councilCardData = _.get(
        currentDefaultFormatDetails,
        "atrl",
        []
      ).filter(
        (item) =>
          item._id === _.get(legalActionInfo, "data.data.doc.atn.oatn", "")
      );
      return councilCardData[0];
    }
  }, [currentDefaultFormatDetails, legalActionInfo]);

  const ourOpposingData = useMemo(() => {
    if (_.get(currentDefaultFormatDetails, "atrl", []).length) {
      const councilCardData = _.get(
        currentDefaultFormatDetails,
        "atrl",
        []
      ).filter(
        (item) =>
          item._id === _.get(legalActionInfo, "data.data.doc.atn.opatn", "")
      );
      return councilCardData[0];
    }
  }, [currentDefaultFormatDetails, legalActionInfo]);

  // useEffect(() => {
  //   if (legalActionInfo && !legalInfoTap) {
  //     let initailTabOpen = _.get(legalActionInfo, "data.data.doc.li", "");
  //     let keys = Object.keys(result);
  //     keys &&
  //       keys.map((i) => {
  //         if (
  //           initailTabOpen &&
  //           initailTabOpen[result[i]?.pat] &&
  //           initailTabOpen[result[i]?.pat]?.dt
  //         ) {
  //           setIsSectionActive(result[i]?.na);
  //         }
  //       });
  //   }
  // }, [legalActionInfo, legalInfoTap]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  // const watchFields = watch();

  const postLegalInformation = useMutation(
    (data) =>
      postLegalInfomation({
        customerId: customerId,
        organization: currentOrganization,
        data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        // reset();
        setJudgementTable();
        queryClient.refetchQueries([`${LEGAL_ACTION_INFO_}${customerId}`]);
        // setIsFieldId(

        // );
      },
    }
  );

  const onSubmit = async (data) => {
    let informationDetails = _.get(legalActionInfo, "data.data.doc.li", "");

    let apiTabkey = result[isSectionActive].pat;
    let legalInfoData = {};
    result &&
      result[isSectionActive] &&
      result[isSectionActive].fld &&
      result[isSectionActive].fld.map((e) => {
        let path = e.pat;
        let dataType = e.dtp;
        let keys = path.split(".");
        let current = data;
        for (let j = 0; j < keys.length; j++) {
          if (current.hasOwnProperty(keys[j])) {
            current = current[keys[j]];
          }
        }
        if (dataType === "DATE") {
          if (current) {
            legalInfoData[path] = moment
              .tz(
                `${current?.getFullYear()}-${
                  current?.getMonth() + 1
                }-${current?.getDate()}`,
                "YYYY-MM-DD",
                currentDefaultFormatDetails.time_zone
              )
              .utc()
              .valueOf();
          }
        } else if (dataType === "AMOUNT") {
          if (current.value) {
            legalInfoData[path] = current;
          }
        } else if (dataType === "TEXT") {
          legalInfoData[path] = current;
        } else {
          if (current) {
            legalInfoData[path] = current;
          }
        }
      });

    if (judgementTable) {
      legalInfoData = { ...legalInfoData, ...judgementTable };
    }

    if (data && data.defs && data.defs.length > 0) {
      let defId = data.defs.map((e) => e.id);
      legalInfoData = { ...legalInfoData, ...{ defs: defId } };
    }

    await postLegalInformation.mutateAsync({
      data: legalInfoData,
      apiTabkey: apiTabkey,
      apiNew: isFieldId,
    });
  };
  const updateInfomark = useMutation(
    (data) =>
      updateLegalInfoMark({
        customerId: customerId,
        organization: currentOrganization,
        data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
        }
      },
      onSuccess: (data, variables, context) => {
        // reset();
        setJudgementTable();
        queryClient.refetchQueries([`${LEGAL_ACTION_INFO_}${customerId}`]);
      },
    }
  );

  function addSupplement(val) {
    let supplementCount = Object.keys(result).filter((key) =>
      key.startsWith(val)
    ).length;
    let newSupplementKey = `${val} ${supplementCount + 0}`;

    if (result[val]) {
      let { fieldData, ...rest } = result[val];
      setIsResult({
        ...result,
        [newSupplementKey]: {
          ...rest,
        },
      });
    } else {
      let { fieldData, ...rest } = result[val + "/def"];
      setIsResult({
        ...result,
        [newSupplementKey + `/def`]: {
          ...rest,
        },
      });
    }
  }

  const handleUpdateInfoMark = async (data) => {
    let apiTabkey = result[isSectionActive].pat;

    await updateInfomark.mutateAsync({
      // data: legalInfoData,
      apiTabkey: apiTabkey,
      apiNew: isFieldId,
    });
  };

  const handleLegalActionClick = (action) => {
    setState({ ...state, action });
  };

  return (
    <>
      {legalActionInfo?.isLoading ? (
        <Loader />
      ) : (
        <div style={{ width: "900px" }}>
          {/* header Section start here */}
          <div className="co_legal_action_header">
            <div className="co_legal_action_title_container">
              <TitleHead title="Legal Action" icon="legal_action" />
              <div className="header-legal-action-content">
                <div
                  className="dot_legal_action"
                  style={{ marginBottom: "3px" }}
                ></div>
                <Typography
                  type="p"
                  className="regular"
                  style={{ cursor: "pointer", marginBottom: "3px" }}
                  onClick={() => {
                    setState({ ...state, action: LEGAL_DEBTS });
                  }}
                >
                  {_.get(legalActionInfo, "data.data.doc.tdep.currency", 0)}{" "}
                  {format.currency({
                    amount: _.get(
                      legalActionInfo,
                      "data.data.doc.tdep.value",
                      0
                    ),
                  })}
                </Typography>
                <div
                  className="dot_legal_action"
                  style={{ marginBottom: "3px" }}
                ></div>
                <Typography
                  type="p"
                  className="regular"
                  style={{ cursor: "pointer", marginBottom: "3px" }}
                  onClick={() => {
                    setState({ ...state, action: LEGAL_DEFENDANTS });
                  }}
                >
                  {_.get(legalActionInfo, "data.data.doc.def.length", 0)}{" "}
                  Defendants
                </Typography>
                <div
                  className="dot_legal_action"
                  style={{ marginBottom: "3px" }}
                ></div>
                <Typography style={{ cursor: "pointer", marginBottom: "3px" }}>
                  {_.get(legalActionInfo, "data.data.doc.lgst", "")}
                </Typography>
                <RBACWrapper role={STATUS_UPDATE_LEGAL} type="PERMISSION">
                  <HearingPopperEdit />
                </RBACWrapper>
              </div>
            </div>
            <div style={{ paddingRight: "15px" }}>
              <TimerComponent
                initialStartTime={_.get(
                  legalActionInfo,
                  "data.data.doc.timesheet_start",
                  ""
                )}
              />
            </div>
          </div>
          {/* header Section end here */}

          <div className="co_legal_action_body_container">
            <div className="legal_action_custom_container">
              {ourCouncelData || ourOpposingData ? (
                <div className="legal_action_counsel_container">
                  <div
                    onClick={() => {
                      setState({ ...state, action: LEGAL_COUNSEL });
                    }}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {ourCouncelData ? (
                      <Typography
                        type="p"
                        className="regular"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "370px",
                        }}
                      >
                        <span style={{ color: "#787878" }}>Our Counsel</span>
                        {`- ${_.get(ourCouncelData, "atrn", "")}` +
                          (_.get(ourCouncelData, "atrn") ? "-" : "") +
                          `${_.get(ourCouncelData, "firn", "")}` +
                          (_.get(ourCouncelData, "firn") ? "-" : "") +
                          `${_.get(ourCouncelData, "addr.li1", "")}` +
                          (_.get(ourCouncelData, "addr.li1") ? ", " : "") +
                          `${_.get(ourCouncelData, "addr.li2", "")}` +
                          (_.get(ourCouncelData, "addr.li2") ? ", " : "") +
                          `${_.get(ourCouncelData, "addr.cit", "")}` +
                          (_.get(ourCouncelData, "addr.cit") ? ", " : "") +
                          `${_.get(ourCouncelData, "addr.sta", "")}` +
                          (_.get(ourCouncelData, "addr.sta") ? ", " : "") +
                          `${_.get(ourCouncelData, "addr.cou", "")}` +
                          (_.get(ourCouncelData, "addr.cou") ? ", " : "") +
                          `${_.get(ourCouncelData, "addr.zip", "")}` +
                          (_.get(ourCouncelData, "addr.zip") ? ", " : "") +
                          `${_.get(ourCouncelData, "em.val", "")}` +
                          (_.get(ourCouncelData, "em.val") ? " " : "") +
                          `${_.get(ourCouncelData, "ph.coc", "")}` +
                          (_.get(ourCouncelData, "ph.coc") ? " " : "") +
                          `${_.get(ourCouncelData, "ph.val", "")}`}
                      </Typography>
                    ) : (
                      <div
                        onClick={() => {
                          setState({ ...state, action: LEGAL_COUNSEL });
                        }}
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Icon icon="plus" color="#516BEB" size={16} />

                        <Typography className="text-primary" subType="regular">
                          Add Our Counsel & Opposing Counsel
                        </Typography>
                      </div>
                    )}
                    {ourOpposingData ? (
                      <Typography
                        type="p"
                        className="regular"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "370px",
                        }}
                      >
                        <span style={{ color: "#787878" }}>
                          Opposing Counsel
                        </span>
                        {`- ${_.get(ourOpposingData, "atrn", "")}` +
                          (_.get(ourOpposingData, "atrn") ? "-" : "") +
                          `${_.get(ourOpposingData, "firn", "")}` +
                          (_.get(ourOpposingData, "firn") ? "-" : "") +
                          `${_.get(ourOpposingData, "addr.li1", "")}` +
                          (_.get(ourOpposingData, "addr.li1") ? ", " : "") +
                          `${_.get(ourOpposingData, "addr.li2", "")}` +
                          (_.get(ourOpposingData, "addr.li2") ? ", " : "") +
                          `${_.get(ourOpposingData, "addr.cit", "")}` +
                          (_.get(ourOpposingData, "addr.cit") ? ", " : "") +
                          `${_.get(ourOpposingData, "addr.sta", "")}` +
                          (_.get(ourOpposingData, "addr.sta") ? ", " : "") +
                          `${_.get(ourOpposingData, "addr.cou", "")}` +
                          (_.get(ourOpposingData, "addr.cou") ? ", " : "") +
                          `${_.get(ourOpposingData, "addr.zip", "")}` +
                          (_.get(ourOpposingData, "addr.zip") ? ", " : "") +
                          `${_.get(ourOpposingData, "em.val", "")}` +
                          (_.get(ourOpposingData, "em.val") ? " " : "") +
                          `${_.get(ourOpposingData, "ph.coc", "")}` +
                          (_.get(ourOpposingData, "ph.coc") ? " " : "") +
                          `${_.get(ourOpposingData, "ph.val", "")}`}
                      </Typography>
                    ) : (
                      <div
                        onClick={() => {
                          setState({ ...state, action: LEGAL_COUNSEL });
                        }}
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Icon icon="plus" color="#516BEB" size={16} />

                        <Typography className="text-primary" subType="regular">
                          Add Our Counsel & Opposing Counsel
                        </Typography>
                      </div>
                    )}
                  </div>

                  <Icon
                    icon="view_icon"
                    size={16}
                    onClick={() => {
                      setState({ ...state, action: LEGAL_COUNSEL });
                    }}
                    style={{
                      flex: "1 1",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  />
                </div>
              ) : (
                <div
                  className="legal_action_counsel_container"
                  onClick={() => {
                    setState({ ...state, action: LEGAL_COUNSEL });
                  }}
                >
                  <div className="counsel_icon_container">
                    <Icon icon="plus" color="#516beb" />
                  </div>
                  <Typography className="text-primary" subType="regular">
                    Add Our Counsel & Opposing Counsel
                  </Typography>
                  <Icon icon="workflow_heigh" color="#516beb" />
                </div>
              )}

              <div className="legal_info_title_container">
                <Typography type="h3" subType="medium">
                  Legal Information
                </Typography>
                <StatefulPopover
                  placement={PLACEMENT.bottomRight}
                  overrides={{
                    Arrow: {
                      style: ({ $theme }) => ({
                        outline: `##CDCED9 solid`,
                        backgroundColor: "#16164B",
                      }),
                    },
                    Inner: {
                      style: ({ $theme }) => ({
                        display: "flex",
                        borderRadius: "6PX",
                        // width: "167px",
                        // height: "127px",
                        backgroundColor: "#FFFFFF",
                      }),
                    },
                  }}
                  content={({ close }) => (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {isMulti.map((e) => (
                        <div className="legal-info-plus_icon">
                          <Typography
                            type="p"
                            className="regular"
                            onClick={() => {
                              addSupplement(e);
                              close();
                            }}
                          >
                            {e} Information
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                  returnFocus
                  autoFocus
                >
                  {/* backgroundColor:"#F0F5FF" ,width:'28px',borderRadius:'5px'*/}
                  <div style={{ cursor: "pointer" }}>
                    <Icon icon="plus" />
                  </div>
                </StatefulPopover>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <LegalInfoTab
                  handleAudit={handleMoreActionsClick}
                  isSectionActive={isSectionActive}
                  result={result}
                  setIsSectionActive={setIsSectionActive}
                  isSubmitting={isSubmitting}
                  control={control}
                  setValue={setValue}
                  legalActionInfo={legalActionInfo}
                  reset={reset}
                  setIsFieldId={setIsFieldId}
                  setlegalInfo={() =>
                    setState({ ...state, action: LEGAL_AUDIT })
                  }
                  setState={setState}
                  state={state}
                  updateInfoMark={handleUpdateInfoMark}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  setJudgementTable={setJudgementTable}
                  setDynamicLegal={setDynamicLegal}
                  setlegalInfoTap={setlegalInfoTap}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      padding: "10px",
                    }}
                  >
                    {result &&
                      result[isSectionActive] &&
                      result[isSectionActive].fld &&
                      result[isSectionActive].fld.map((e) => {
                        return (
                          !e.is_hidden && (
                            <div style={{ width: "205px" }}>
                              <InputTypes
                                name={e.nm}
                                control={control}
                                setValue={setValue}
                                reSet={reset}
                                currentDefaultFormatDetails={
                                  currentDefaultFormatDetails
                                }
                                type={e.dtp}
                                path={e.pat}
                                options={e.opt}
                                setIsEditing={setIsEditing}
                              />
                            </div>
                          )
                        );
                      })}
                  </div>
                </LegalInfoTab>
              </form>
            </div>
            <div
              className="legal_info_action_container"
              style={{ width: "350px" }}
            >
              <div className="co-call-outcome-container">
                {dynamicLegal.map((e) => (
                  <>
                    {!e?.is_hidden && (
                      <div
                        key={e.label}
                        className={`co-call-outcome-btn ${
                          state.action === e.action
                            ? "co-call-outcome-btn--active"
                            : ""
                        }`}
                        onClick={() => handleLegalActionClick(e.action)}
                        disabled={!state.action}
                      >
                        {e.label === "Motions" &&
                        nextMotionInfo &&
                        nextMotionInfo.length > 0 ? (
                          <StatefulTooltip
                            triggerType="hover"
                            placement={PLACEMENT.bottom}
                            showArrow
                            overrides={{
                              Arrow: {
                                style: ({ $theme }) => ({
                                  outline: `#16164B solid`,
                                  backgroundColor: "#16164B",
                                }),
                              },
                              Inner: {
                                style: ({ $theme }) => ({
                                  outline: `#16164B solid`,
                                  backgroundColor: "#16164B",
                                }),
                              },
                            }}
                            content={() => (
                              <div
                                style={{
                                  backgroundColor: "#16164B",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                {nextMotionInfo.map((item) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "4px",
                                    }}
                                  >
                                    <Typography
                                      type="p"
                                      subType="regular"
                                      style={{ color: "white" }}
                                    >
                                      {format.date({
                                        value: _.get(item, "hrd", ""),
                                      })}
                                    </Typography>
                                    {item.typ ? "-" : ""}
                                    <Typography
                                      type="p"
                                      subType="regular"
                                      style={{ color: "white" }}
                                    >
                                      {item.typ}
                                    </Typography>
                                  </div>
                                ))}
                              </div>
                            )}
                            returnFocus
                            autoFocus
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <Typography>{e.label}</Typography>
                              <div className="motion-count-ripple">
                                {nextMotionInfo?.length}{" "}
                              </div>
                            </div>
                          </StatefulTooltip>
                        ) : (
                          <Typography>{e.label}</Typography>
                        )}
                      </div>
                    )}
                  </>
                ))}
                <StatefulPopover
                  placement={PLACEMENT.bottom}
                  overrides={{
                    Arrow: {
                      style: ({ $theme }) => ({
                        outline: `#CDCED9 solid`,
                        backgroundColor: "#16164B",
                      }),
                    },
                    Inner: {
                      style: ({ $theme }) => ({
                        // width: "125px",
                        padding: "5px",
                        // height: "90px",
                        backgroundColor: "#FFFFFF",
                      }),
                    },
                    Body: {
                      style: ({ $theme }) => ({
                        // width: "100px",
                      }),
                    },
                  }}
                  content={({ close }) => (
                    <div className="legal-moreAction-popper-container">
                      {dynamicLegal.map((item) => (
                        <>
                          {item.is_hidden && (
                            <Typography
                              key={item.label}
                              type="p"
                              style={{ padding: "5px", borderRadius: "3px" }}
                              className="regular"
                              onClick={() => {
                                handleMoreActionsClick(item);
                                close();
                              }}
                            >
                              {item.label}
                            </Typography>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                  returnFocus
                  autoFocus
                >
                  {/* backgroundColor:"#F0F5FF" ,width:'28px',borderRadius:'5px'*/}
                  <div>
                    <Icon icon="triple_dot" isPressable />
                  </div>
                </StatefulPopover>
              </div>

              <LegalActionType
                isEditableDebDpt={isEditableDebDpt}
                type={state.action}
                result={result}
                isSectionActive={isSectionActive}
                state={state}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LegalActionDrawer;
