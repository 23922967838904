import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";

import { SIZE, TextButton, KIND } from "../../components/TextButton";
import { SelectBox } from "../../components/SelectBox";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../components/TextArea";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  getHolidayBlackoutHolidayDate,
  updateCustomerActionWorkflowStatus,
  useCustomerUpdateAction,
} from "../../services";
import { ON_HOLD, CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../../constants";
import { Icon, Typography } from "../../components_v2";
import getSelectValues from "../../utils/getSelectValues";
import moment from "moment";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";
import queryClient from "../../providers/queryClient";
import ActionReminders from "../../components_v2/ActionReminders";
import _ from "lodash";

const PauseAction = ({ handleModal, customerId, entity }) => {
  const intl = useIntl();

  let maxyfiContextValue = useContext(MaxyfiContext);
  let {
    currentOrganization,
    referenceData,
    refetch,
    space,
    currentDefaultFormatDetails,
  } = maxyfiContextValue;

  const creatablePermission =
    space && space.role_permissions?.includes(ADD_LIST_VALUES);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });

  let { hold_reason, hold_action_till } = watch();

  const { mutateAsync, isLoading } = useCustomerUpdateAction();

  const { data, isFetched, isError } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: {
          date: hold_action_till
            ? [
                moment(hold_action_till)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf(),
              ]
            : null,
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: hold_action_till ? true : false,
    }
  );

  const onSubmit = async (data) => {
    const orgRef = referenceData?.organizations?.find(
      ({ id }) => id === currentOrganization
    );

    const parser = new DOMParser();
    const doc = parser.parseFromString(data && data?.comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    const timeZone = orgRef?.time_zone;

    let values = {
      ...data,
      hold_reason: getSelectValues(data.hold_reason),
      ...(data.hold_action_till
        ? {
            hold_action_till: moment
              .tz(
                `${data.hold_action_till.getFullYear()}-${
                  data.hold_action_till.getMonth() + 1
                }-${data.hold_action_till.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {}),
      mention_users: uniqueMentionedUsers,
    };
    values = {
      ...values,
      id: customerId,
      organization: currentOrganization,
      actionStatus: ON_HOLD,
      entity: entity,
      customerId: customerId,
    };
    // await holdActionMutation.mutateAsync(values);
    await mutateAsync(
      {
        ...values,
      },
      {
        onSuccess: (data) => {
          if (hold_reason && hold_reason[0] && hold_reason[0].isCreatable) {
            refetch({
              ...maxyfiContextValue,
              setLoader: false,
              currentOrganization: currentOrganization,
            });
          }

          queryClient.invalidateQueries({
            queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
          });
          queryClient.invalidateQueries({
            queryKey: [`${CO_TIMELINE_}${customerId}`],
          });

          reset();
          handleModal();
        },
        onError: (error) => {
          if (error.response) {
            let { data } = error.response;
            let errorData = data.message;
            toast.error(errorData);
          }
        },
      }
    );
  };

  return (
    <>
      <form
        style={{
          textAlign: "center",
          marginTop: "15px",
          // padding: "10px 20px",
          padding: "5px 35px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
          }}
        >
          <Icon icon="hold_action" />
          <Typography type="h2" subType="medium">
            Pause Action
          </Typography>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "12px",
          }}
        >
          <Controller
            control={control}
            name="hold_reason"
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                requiredAstric={true}
                creatable={creatablePermission ? true : false}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "pause_reason",
                })}
                placeholder={intl.formatMessage({
                  id: "pause_reason",
                })}
                size={SIZE.mini}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["hold_action_reason"]}
              />
            )}
          />
          <Controller
            name="hold_action_till"
            control={control}
            render={({ field }) => (
              <DateRangeSelect
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "date",
                })}
                value={field.value}
                size={"mini"}
                onChange={(e) => field.onChange(e.date)}
                minDate={new Date()}
              />
            )}
          />
        </div>
        {_.get(data, "data.doc.is_holiday") && (
          <>
            <div className="alert-holiday-section">
              <Icon icon="alert_circle" color="#516BEB" />
              <Typography
                type="p"
                style={{
                  fontWeight: "400",
                }}
              >
                The Date Selected{" "}
                {_.get(data, "data.doc.holidays", []).map((val) => {
                  return (
                    <>
                      {moment(val).format(
                        currentDefaultFormatDetails.date_format
                      )}
                    </>
                  );
                })}{" "}
                is a <b>“Holiday”</b>
              </Typography>
            </div>
          </>
        )}
        <ActionReminders
          watch={watch}
          control={control}
          errors={errors}
          setValue={setValue}
          is_calendar={false}
          is_incrementor={false}
          is_hold={false}
          is_followup={false}
          is_confirmation={false}
          is_valid_from_contact={false}
        />

        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            padding: "5px 25px 15px 25px",
            alignItems: "center",
            gap: "15px",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100px" }}>
            <TextButton
              type="button"
              fullWidth
              size={SIZE.mini}
              kind={KIND.tertiary}
              onClick={() => {
                handleModal();
              }}
            >
              Cancel
            </TextButton>
          </div>
          <div style={{ width: "100px" }}>
            <TextButton
              type="submit"
              fullWidth
              size={SIZE.mini}
              kind={KIND.primary}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Save
            </TextButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default PauseAction;
