import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  H2,
  HeadingXLarge,
  LabelLarge,
  ParagraphLarge,
  ParagraphMedium,
} from "baseui/typography";
import { FormattedMessage, useIntl } from "react-intl";
import WhatsApp from "../../../../assets/img/svg/Template/WhatsApp";
import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { KIND, TextButton } from "../../../../components/TextButton";
import moment from "moment";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { IconButton } from "../../../../components/IconButton";
import FullScreen from "../../../../assets/img/svg/FullScreen";
import setSelectValues from "../../../../utils/setSelectValues";

const WhastAppForm = ({
  Controller,
  control,
  errors,
  hideWorkflowArea,
  contacts = [],
  templateContent,
  setValue,
  currentActioDate,
  contactsData,
  data,
}) => {
  let dispatch = useDispatch();
  const intl = useIntl();

  const [preview, setPreview] = useState([]);

  const {
    referenceData,
    currentOrganization,
    userInfo,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);

  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );

  const timeZone = orgRef?.time_zone;

  let dayDiff = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.diff(moment.tz(timeZone), "days", true);

  let dayDifference = moment
    .utc(currentActioDate)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);

  dayDiff = Math.ceil(dayDiff);

  useEffect(() => {
    let setData =
      contactsData && Array.isArray(contactsData)
        ? contactsData.reduce((prev, curr) => {
            let { phone, landline, email, address, ...rest } = curr;
            let nestedContacts = phone
              .map(({ status, whatsapp_status, ...nRest }) => {
                return {
                  id: nRest?._id,
                  recipient_id: curr?._id,
                  whatsapp_status,
                  status,
                };
              })
              .filter(
                (e) =>
                  (e.status === "VALID" || e.status === "LEAD") &&
                  e.whatsapp_status === "VALID"
              );

            return [...prev, ...nestedContacts];
          }, [])
        : [];

    setValue("recipients", setData && setData[0] ? [setData[0]] : []);
  }, [contactsData?.length, contacts]);

  return (
    <>
      <div
        style={{
          gridColumn: "2/5",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
        // onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              // display: "grid",
              // gridTemplateRows: "repeat(12,10fr)",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div style={{ padding: "15px 35px" }}>
              <div className="cp-action-title--content  ">
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <H2>
                    <FormattedMessage id="review_and_send_whatsapp">
                      review_and_send_email
                    </FormattedMessage>
                  </H2>

                  {dayDiff < 1 ? (
                    <></>
                  ) : (
                    <>
                      <div
                        style={{
                          border: "1.5px solid #FD372A",
                          borderRadius: "5px",
                          backgroundColor: "#FAF0EF",
                        }}
                      >
                        <ParagraphMedium
                          style={{ color: "#FD372A", padding: "5px 10px" }}
                        >
                          Future Action - Scheduled for{" "}
                          <span>{dayDifference}</span>
                        </ParagraphMedium>
                      </div>
                    </>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton type="button" onClick={() => hideWorkflowArea()}>
                    <FullScreen />
                  </IconButton>
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "0px 35px",
                // height: "39vh",
                overflow: "scroll",
                paddingTop: "5px",
              }}
            >
              <Controller
                name="recipients"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    size={SIZE.default}
                    {...field}
                    name={field.name}
                    requiredAstric={true}
                    // noResultMessage={`${"Phone Number is not available for the consumer contact."}${" Use the Edit / Add option at consumer contact to capture Email ID"}`}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "primary_contact",
                    })}
                    placeholder={intl.formatMessage({
                      id: "primary_contact",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    // options={contacts}
                    options={
                      contactsData && Array.isArray(contactsData)
                        ? contactsData?.reduce((prev, curr) => {
                            let { phone, landline, email, address, ...rest } =
                              curr;
                            let nestedContacts = phone
                              ?.map(({ status, whatsapp_status, ...nRest }) => {
                                let designation =
                                  referenceData &&
                                  referenceData["recipient_type"]
                                    ? referenceData["recipient_type"].find(
                                        (rf) => rf?.id === curr?.designation
                                      )
                                    : "";

                                return {
                                  label: `${curr?.first_name} - ${designation?.label} - ${nRest?.country_code} ${nRest?.value}`,
                                  id: nRest?._id,
                                  recipient_id: curr?._id,
                                  whatsapp_status,
                                  status,
                                };
                              })
                              .filter(
                                (e) =>
                                  (e.status === "VALID" ||
                                    e.status === "LEAD") &&
                                  e.whatsapp_status === "VALID"
                              );

                            return [...prev, ...nestedContacts];
                          }, [])
                        : []
                    }
                  />
                )}
              />

              {templateContent && (
                <div
                  style={{
                    border: "1px solid #ceced9",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  <LabelLarge $style={{ marginBottom: "5px" }}>
                    {templateContent.subject ? templateContent.subject : ""}
                  </LabelLarge>

                  <ParagraphLarge $style={{ marginBottom: "5px" }}>
                    {templateContent.value ? templateContent.value : ""}
                  </ParagraphLarge>

                  {templateContent?.button_type == "call_to_action" ? (
                    <>
                      <ParagraphLarge
                        $style={{
                          color: "#516beb",
                          textDecoration: "underline",
                        }}
                      >
                        {templateContent?.buttons?.[1]?.url}{" "}
                      </ParagraphLarge>
                      <ParagraphLarge $style={{ color: "#516beb" }}>
                        {templateContent?.buttons?.[0]?.phone_number?.code &&
                          "+"}
                        {templateContent?.buttons?.[0]?.phone_number?.code}{" "}
                        {templateContent?.buttons?.[0]?.phone_number?.value}
                      </ParagraphLarge>
                    </>
                  ) : templateContent?.button_type == "quick_reply" ? (
                    templateContent?.buttons?.map((qic) => (
                      <ParagraphLarge $style={{ color: "#516beb" }}>
                        {qic.text}{" "}
                      </ParagraphLarge>
                    ))
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WhastAppForm;
