import columnMapper from "../../utils/mapper";
import tableStateDraftMapper from "../../utils/tableStateDraftMapper";
import {
  ADD_BUSINESS_CONTACT,
  ADD_ADMIN_CONTACT,
  BUSINESS_CONTACT_TYPE,
  BUSINESS_ADMIN_CONTACT_TYPE,
  BUSINESS_UNIT_REFETCH,
  SET_CL_SORT,
  SET_CL_GRID_AND_VIEW,
  SET_CL_VIEW,
  GET_CL_GRID_STRUCTURE,
  SET_CL_PAGE_INDEX,
  TABLE_COLUMN_ORDER_CLIENT,
  SET_CL_FILTER,
  CLIENT_STAGE_FILTERS,
  COLUMN_CLIENT_WIDTH,
  UPDATE_CL_TABLE_DRAFT_STATE,
  SET_CL_PINNED_VIEW,
  SET_CL_PAGE_SIZE,
} from "./action";

const initialQueryBuilder = {
  combinator: "and",
  rules: [],
};
const initialState = {
  gridStructure: [],
  width: 0,
  pinnedView: [],

  tableState: { hiddenColumns: [], columns: [], sort: null, filters: [] },
  tableCurrentState: {
    sort: null,
    pageIndex: 0,
    pageSize: 10,
    filters: {},
    globalFilters: "",
    formFilters: initialQueryBuilder,
    needs_action: {},
    customer_stage: {},
  },
  // columns: col_id, is_hidden, sort, order, width, filter
  tableDraftView: {
    table: [],
    filters: {},
    formFilters: initialQueryBuilder,
  },
  viewName: null,
  columnsData: [],
  currentTableData: { columns: [], hiddenColumns: [] },
  actionType: "",
  gridAndViewLoaded: false,
  tableOrder: [],
  path: "",

  businessMaintenanceBusinessContactData: [
    {
      first_name: "",
      last_name: "",
      email: "",
      phone: {
        code: "",
        value: "",
      },

      address: {
        line_1: "",
        line_2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
      },
    },
  ],
  businessMaintenanceAdminContactData: [
    {
      first_name: "",
      last_name: "",
      email: "",
      phone: {
        code: "",
        value: "",
      },

      address: {
        line_1: "",
        line_2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
      },
    },
  ],
};
/*** TABLE SETTINGS ***/
const isExpanded = false;
const isExpandedHeader = false;
const isSelection = true;
const isChecked = true;
const isCurrency = true;

export const businessMaintenanceReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ADD_BUSINESS_CONTACT: {
      return {
        ...state,
        businessMaintenanceBusinessContactData: [payload],
      };
    }
    case GET_CL_GRID_STRUCTURE: {
      let gridStructure = { ...payload }; // {menu, table[], widget[]}

      let { hiddenColumns = [], columns = [] } = columnMapper({
        columns: gridStructure.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        // isRowDeletedVar: "is_active",
        isCurrency,
        width: state.width - 200,
      });

      return {
        ...state,
        gridStructure,
        tableState: { hiddenColumns, columns },
      };
    }
    case ADD_ADMIN_CONTACT: {
      return {
        ...state,
        businessMaintenanceAdminContactData: [payload],
      };
    }
    case SET_CL_PINNED_VIEW: {
      return {
        ...state,
        pinnedView: payload,
      };
    }
    case CLIENT_STAGE_FILTERS: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          customer_stage: payload,
        },
      };
    }
    case COLUMN_CLIENT_WIDTH: {
      return { ...state, width: payload };
    }
    case SET_CL_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case SET_CL_SORT: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          sort: payload,
        },
      };
    }
    case SET_CL_VIEW: {
      let newState = { ...state };
      let viewData = { ...payload };

      newState.tableState = columnMapper({
        columns: state.gridStructure.table,
        viewSelect: viewData.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        // isRowDeletedVar: "is_active",
        isCurrency,
        width: state.width - 200,
      });

      newState.viewName = viewData.name;

      newState.tableDraftView = initialState.tableDraftView;

      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");

        newState.tableCurrentState.sort = [
          { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
        ];
      }

      if (newState.tableState.filters) {
        const currentFilter = newState.tableState.filters;

        newState.tableCurrentState.formFilters = viewData.filter;
        newState.tableCurrentState.filters = viewData.filter;
        newState.tableCurrentState.needs_action = viewData.needs_action;
        newState.tableCurrentState.customer_stage = viewData.customer_stage;
      }
      return {
        ...newState,
      };
    }
    case TABLE_COLUMN_ORDER_CLIENT: {
      return {
        ...state,
        tableOrder: payload,
      };
    }
    case UPDATE_CL_TABLE_DRAFT_STATE: {
      let draftColumnData = tableStateDraftMapper({
        tableColumns: payload,
        isSelection,
        isExpanded,
        isExpandedHeader,
        isChecked,
      });

      let newState = { ...state };

      const onlyData = newState.tableState.filters;

      newState.tableDraftView = { table: draftColumnData };

      if (
        state.tableCurrentState.sort &&
        state.tableCurrentState.sort[0] &&
        payload.sort[0] &&
        payload.sort[0].id !== state.tableCurrentState.sort[0].id &&
        payload.sort[0].desc !== state.tableCurrentState.sort[0].desc &&
        !state.path
      ) {
        newState.tableCurrentState = {
          ...newState.tableCurrentState,
          needs_action: newState.tableCurrentState.needs_action,
          customer_stage: newState.tableCurrentState.customer_stage,
          formFilters: newState.tableCurrentState.formFilters,
          filters: newState.tableCurrentState.filters,
          sort: payload.sort,
        };
      }

      return {
        ...newState,
      };
    }

    case SET_CL_GRID_AND_VIEW: {
      let newState = { ...state };
      let { grid_structure, default_view, pinned_view = [] } = payload;
      //

      newState.gridStructure = grid_structure;
      newState.pinnedView = pinned_view;

      newState.tableState = columnMapper({
        columns: grid_structure?.table,
        viewSelect: default_view?.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        isChecked,
        // isRowDeletedVar: "is_active",
        isCurrency,
        width: newState.width - 200,
      });

      newState.viewName = default_view?.name;

      newState.tableDraftView = initialState.tableDraftView;

      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");
        newState.tableCurrentState = {
          ...newState.tableCurrentState,
          sort: [
            { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
          ],
          pageIndex: 0,
          pageSize: 10,
        };
      }

      // if (!state.path) {
      //   newState.tableCurrentState.formFilters = default_view.filter;
      //   newState.tableCurrentState.filters = default_view.filter;
      //   newState.tableCurrentState.needs_action = default_view.needs_action;
      //   newState.tableCurrentState.customer_stage = default_view.customer_stage;
      // }

      return {
        ...newState,
      };
    }

    case BUSINESS_CONTACT_TYPE: {
      return {
        ...state,
        businessContactTypeValue: payload,
      };
    }

    case BUSINESS_ADMIN_CONTACT_TYPE: {
      return {
        ...state,
        adminContactTypeValue: payload,
      };
    }
    case BUSINESS_UNIT_REFETCH: {
      return {
        ...state,
        refetchCount: state.refetchCount + 1,
      };
    }

    case SET_CL_PAGE_INDEX: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          pageIndex: payload,
        },
      };
    }

    case SET_CL_PAGE_SIZE: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          pageSize: payload,
        },
      };
    }

    default:
      return state;
  }
};
