function SkipMsg() {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3856 15.7137L15.2573 14.3938C15.2994 14.3637 15.3337 14.3241 15.3574 14.2782C15.381 14.2322 15.3934 14.1813 15.3934 14.1296C15.3934 14.0779 15.381 14.0269 15.3574 13.981C15.3337 13.935 15.2994 13.8954 15.2573 13.8654L13.3856 12.5448C13.3368 12.5107 13.2797 12.4906 13.2203 12.4867C13.1609 12.4828 13.1016 12.4952 13.0488 12.5226C12.996 12.55 12.9517 12.5913 12.9207 12.6421C12.8897 12.6929 12.8733 12.7512 12.873 12.8107V15.4505C12.8738 15.5098 12.8906 15.5677 12.9218 15.6181C12.953 15.6685 12.9973 15.7094 13.05 15.7365C13.1028 15.7636 13.1619 15.7757 13.221 15.7717C13.2801 15.7677 13.337 15.7476 13.3856 15.7137V15.7137ZM16.1163 12.5062V15.7495C16.1163 15.8355 16.1505 15.9181 16.2114 15.9789C16.2722 16.0398 16.3548 16.074 16.4408 16.074C16.5269 16.074 16.6094 16.0398 16.6702 15.9789C16.7311 15.9181 16.7653 15.8355 16.7653 15.7495V12.5076C16.7653 12.4215 16.7311 12.339 16.6702 12.2781C16.6094 12.2173 16.5269 12.1831 16.4408 12.1831C16.3548 12.1831 16.2722 12.2173 16.2114 12.2781C16.1505 12.339 16.1163 12.4215 16.1163 12.5076V12.5062Z"
          fill="#FD372A"
        />
        <path
          d="M15.0038 6H7.00027C6.44916 6 6.00482 6.47602 6.00482 7.05814L6 16.58L7.99778 14.4637H11.0689C11.2641 13.4203 11.8183 12.4781 12.6354 11.8004C13.4525 11.1227 14.481 10.7523 15.5425 10.7533C15.6967 10.7532 15.8507 10.761 16.0041 10.7768V7.05676C16.0041 6.47602 15.5542 6 15.0038 6ZM11.5029 12.3481H8.50205C8.3664 12.3412 8.2386 12.2824 8.14504 12.184C8.05147 12.0855 7.9993 11.9549 7.9993 11.8191C7.9993 11.6832 8.05147 11.5526 8.14504 11.4541C8.2386 11.3557 8.3664 11.2969 8.50205 11.29H11.5029C11.6385 11.2969 11.7663 11.3557 11.8599 11.4541C11.9534 11.5526 12.0056 11.6832 12.0056 11.8191C12.0056 11.9549 11.9534 12.0855 11.8599 12.184C11.7663 12.2824 11.6385 12.3412 11.5029 12.3481V12.3481ZM13.5006 10.7637H8.50205C8.3664 10.7568 8.2386 10.698 8.14504 10.5995C8.05147 10.5011 7.9993 10.3704 7.9993 10.2346C7.9993 10.0988 8.05147 9.96815 8.14504 9.86969C8.2386 9.77123 8.3664 9.71247 8.50205 9.70555H13.5034C13.639 9.71247 13.7669 9.77123 13.8604 9.86969C13.954 9.96815 14.0061 10.0988 14.0061 10.2346C14.0061 10.3704 13.954 10.5011 13.8604 10.5995C13.7669 10.698 13.639 10.7568 13.5034 10.7637H13.5006ZM13.5006 9.17923H8.50205C8.36754 9.17082 8.24129 9.11145 8.14901 9.01322C8.05673 8.91499 8.00537 8.78528 8.00537 8.65051C8.00537 8.51573 8.05673 8.38602 8.14901 8.28779C8.24129 8.18956 8.36754 8.1302 8.50205 8.12178H13.5034C13.6379 8.1302 13.7642 8.18956 13.8564 8.28779C13.9487 8.38602 14.0001 8.51573 14.0001 8.65051C14.0001 8.78528 13.9487 8.91499 13.8564 9.01322C13.7642 9.11145 13.6379 9.17082 13.5034 9.17923H13.5006Z"
          fill="#ADADAD"
        />
      </svg>
    </>
  );
}

export default SkipMsg;
