import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ParagraphLarge } from "baseui/typography";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { SelectBox } from "../components/SelectBox";
import { TextArea } from "../components/TextArea";
import { MaxyfiContext } from "../providers/MaxyfiProvider";
import { ADD_LIST_VALUES } from "../providers/RBACProvider/permissionList";
import { CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../constants";
import setSelectValues from "../utils/setSelectValues";
import getSelectValues from "../utils/getSelectValues";
import { KIND, SIZE, TextButton } from "../components/TextButton";
import { putCustomerStatus, useRelmManger } from "../services";

const AssignRelationManager = ({ handleModal, customerId, relManager }) => {
  const queryClient = useQueryClient();
  let maxyfiContextValue = useContext(MaxyfiContext);
  let { currentOrganization, referenceData, space, refetch, users } =
    maxyfiContextValue;
  const intl = useIntl();
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      relManager: [],
    },
  });

  let { customer_status } = watch();

  const { mutateAsync, isLoading } = useRelmManger();

  const onSubmit = async (data) => {
    let values = {
      relManager: getSelectValues(data.relManager),
    };

    await mutateAsync(
      { values, organization: currentOrganization, customerId: customerId },
      {
        onError: (error) => {
          if (error.response) {
            let { data } = error.response;
            toast.error(data.message);
          }
        },
        onSuccess: (data) => {
          if (
            customer_status &&
            customer_status[0] &&
            customer_status[0]?.isCreatable
          ) {
            refetch({
              setLoader: false,
              currentOrganization: currentOrganization,
            });
          }
          reset();
          handleModal();
          queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
          queryClient.invalidateQueries(`${CO_TIMELINE_}${customerId}`);
        },
      }
    );
  };

  useEffect(() => {
    if (relManager && relManager.length > 0) {
      setValue("relManager", setSelectValues(relManager) || []);
    }
  }, []);

  const relManagerOption = useMemo(() => {
    return users
      ?.filter(
        (i) => i?.organization?.includes(currentOrganization) && i?.active
      )
      .map((i) => {
        return { label: i.displayName, id: i.id };
      });
  }, []);

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);
  return (
    <>
      <div style={{ width: "" }}>
        <form
          style={{
            textAlign: "center",
            marginTop: "15px",
            padding: "10px 20px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ParagraphLarge $style={{ marginBottom: "20px" }}>
            Select the Relation Manager
          </ParagraphLarge>
          <Controller
            name="relManager"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                creatable={creatablePermission}
                backspaceRemoves={false}
                requiredAstric={true}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "relation_manager",
                })}
                placeholder={intl.formatMessage({
                  id: "relation_manager",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={relManagerOption || []}
              />
            )}
          />

          <div
            style={{
              marginTop: "15px",
              textAlign: "center",
              // marginBottom: "25px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="button"
                fullWidth
                size={SIZE.compact}
                kind={KIND.tertiary}
                // onClick={close}
                onClick={() => {
                  handleModal();
                  reset();
                }}
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ height: "", width: "96px" }}>
              <TextButton
                type="submit"
                fullWidth
                size={SIZE.compact}
                kind={KIND.primary}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Save
              </TextButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AssignRelationManager;
