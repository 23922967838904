//Action Types
export const GET_ALL_SETTING_DATA = "GET_ALL_SETTING_DATA";
export const GET_SETTING_DATA = "GET_SETTING_DATA";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_FAILED = "GET_SETTING_FAILED";
export const REFETCH_SETTING = "REFETCH_SETTING";
export const INITIAL_SETTING_DATA = "INITIAL_SETTING_DATA";

// Action Creator

export const setInitialSetting = () => {
  return {
    type: INITIAL_SETTING_DATA,
  };
};

export const refetchSettingData = () => {
  return {
    type: REFETCH_SETTING,
  };
};

export const getSettingData = (data) => {
  return {
    type: GET_SETTING_DATA,
    payload: data,
  };
};

export const getAllSettingData = (data) => {
  return {
    type: GET_ALL_SETTING_DATA,
    payload: data,
  };
};
