export const download = ({
  fileName,
  fileData,
  fileOptions = {},
  viewOnly = false,
}) => {
  const url = window.URL.createObjectURL(
    new Blob([fileData], { ...fileOptions })
  );

  if (viewOnly) {
    window.open(url);
  } else {
    const link = document.createElement("a");

    link.href = url;

    link.setAttribute("download", fileName);

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  }
};
